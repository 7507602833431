// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX =
  /^([a-zA-Z0-9_\-\.\+\\,]+)@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])+$/;
export const email = v => EMAIL_REGEX.test(v);

export const ALPHA_REGEX = /^([a-zA-Z])+$/;
export const alpha = v => ALPHA_REGEX.test(v);

export const NAME_REGEX = /^([a-zA-Z \\\-'])*$/;
export const name = v => NAME_REGEX.test(v);

const PHONE_REGEX = /^([0-9]{10})$/;
export const phone = v => !v || v.length === 0 || PHONE_REGEX.test(v);

const ZIP_REGEX = /^[0-9]{5}$/;
export const zip = v => !v || v.length === 0 || ZIP_REGEX.test(v);

const PASSWORD_REGEX = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])/;
export const password = v => PASSWORD_REGEX.test(v);

// eslint-disable-next-line no-useless-escape
// /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
export const URL_REGEX =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
export const urlValidation = v => URL_REGEX.test(v);

const COLOR_REGEX = /^(?:[0-9a-fA-F]{3}){1,2}$/;
export const colorValidation = v => COLOR_REGEX.test(v);

export const maxLengthValidation = (maxLength, v) => (maxLength ? v.slice(0, maxLength) : v);

export const minLengthValidation = (minLength, value) => minLength && value < minLength;

export const ALPHANUMERIC_REGEX = /^[A-Za-z0-9\s]*$/;
export const alphanumericValidation = v => ALPHANUMERIC_REGEX.test(v);

export const ALPHANUMERIC_SPECIAL_CHARACTERS_REGEX = /^[A-Za-z0-9`(-)./_#'@&*+",:;?!-\s]*$/;
export const alphanummericSpecialCharactersValidation = v => ALPHANUMERIC_SPECIAL_CHARACTERS_REGEX.test(v);

export const TIME_AMPM_REGEX = /^(1[0-2]|0?[1-9]):[0-5][0-9]$/;
export const timeAMPM = v => TIME_AMPM_REGEX.test(v);

const DATE_TEXT_REGEX = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20|21)\d\d$/;
export const dateValidation = v => DATE_TEXT_REGEX.test(v);

export const ALPHA_NUMBER_REGEX = /^[a-zA-Z0-9~@#$^*()_+=['"&/\]{}|\\,.?:; -!]*$/;
export const text = v => ALPHA_NUMBER_REGEX.test(v);

const STATE_ZIP_CODE_REGEX = /^([a-zA-Z]{2})([\s]*)([0-9]{5})$/;
export const stateZipCodeValidation = v => STATE_ZIP_CODE_REGEX.test(v);

const STATE_REGEX = /^([a-zA-Z]{2})$/;
export const stateValidation = v => STATE_REGEX.test(v);

const ZIP_CODE_REGEX = /^([0-9]{5})$/;
export const zipCodeValidation = v => ZIP_CODE_REGEX.test(v);

export const promotionCodeValidation = v => {
  if (!v) return true;
  return !(v.length < 2 || v.length > 50);
};

const PROMOTION_CODE_LIMIT_REGEX = /^([1-9]|[1-9][0-9]|1[0-4][0-9]|150)$/;
export const promotionCodeLimitValidation = v => {
  if (!v) return true;
  return PROMOTION_CODE_LIMIT_REGEX.test(v);
};
