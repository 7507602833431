import { TICKET_ACCOUNT } from '@common/constants';
import { alphanummericSpecialCharactersValidation } from '../../../../../../commons/validations';
import { isEmpty } from '../../../../../../utils/objectUtils';
import { DISTRIBUTION_CHANNEL } from '../../../../../../api/model/request/ProductRequest';

const requiredMaxCapacity = ({ value, control }) => {
  const unlimitedTicket = control.get('unlimitedTicket');
  if (unlimitedTicket === undefined) return null;
  if (!unlimitedTicket.value && isEmpty(value)) {
    return 'Total tickets available for this event is required.';
  }
  return null;
};

const rangeMaxCapacity = ({ value, control }) => {
  const unlimitedTicket = control.get('unlimitedTicket');
  if (unlimitedTicket === undefined) return null;
  if (!unlimitedTicket.value && (value <= 0 || value > 9999999)) {
    return 'Event capacity must be between 1 and 9999999.';
  }
  return null;
};

const underEventSales = ({ value, control }) => {
  const unlimitedTicket = control.get('unlimitedTicket');
  if (unlimitedTicket === undefined || unlimitedTicket.value) return null;
  if (
    !isEmpty(control.extraData) &&
    control.extraData.mode === 'edit' &&
    !isEmpty(control.extraData.eventController) &&
    !isEmpty(control.extraData.eventController.eventSalesInfo)
  ) {
    const { eventTotalSaleQuantity } = control.extraData.eventController.eventSalesInfo;
    if (eventTotalSaleQuantity > 0 && value < eventTotalSaleQuantity) {
      return 'The entered value should be greater or equal to the number of tickets has been sold.';
    }
  }
  return null;
};

export const requiredTicketName = ({ value }) => {
  if (isEmpty(value) || `${value}`.trim() === '') {
    return 'Ticket name is required.';
  }
  return null;
};

export const invalidTicketName = ({ value }) => {
  const { length: strLength } = `${value}`.trim();
  if (!alphanummericSpecialCharactersValidation(value) || strLength < 2 || strLength > 40) {
    return `This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (\`()./_-#'@&*+",:;?!) in between 2-40 characters long.`;
  }
  return null;
};

const requiredPrice = ({ value }) => (isEmpty(value) ? 'Price is required.' : null);

const rangePrice = ({ value }) =>
  value < TICKET_ACCOUNT.MIN_PRICE_ACCOUNT_TICKET || value > TICKET_ACCOUNT.MAX_ADMIN_TICKET_FEE
    ? TICKET_ACCOUNT.ERRORS.MIN_MAX_TICKET_PRICE
    : null;

const rangeLimit = ({ value }) =>
  !isEmpty(value) && (value <= 0 || value > 9999999) ? 'Allotment must be between 1 and 9999999.' : null;

const underTicketSales = ({ name, value, control }) => {
  const unlimitedTicket = control.get('unlimitedTicket');
  if (unlimitedTicket === undefined || unlimitedTicket.value) return null;
  if (
    !isEmpty(control.extraData) &&
    control.extraData.mode === 'edit' &&
    !isEmpty(control.extraData.eventController) &&
    typeof control.extraData.eventController.getProductSalesInfo === 'function'
  ) {
    const field = control.get(name);
    if (field === undefined) return null;

    const { isEndEvent, getProductSalesInfo } = control.extraData.eventController ?? {};
    const { canEditSeason } = control.extraData.seasonController ?? {};

    const found = getProductSalesInfo(`${field.itemKey}`);
    if (found) {
      if (!canEditSeason || isEndEvent) return null;
      if (found.saleQuantity > 0 && value !== null && value !== undefined && value < found.saleQuantity) {
        return 'The entered value should be greater or equal to the number of tickets has been sold.';
      }
    }
  }
  return null;
};

const requiredSeatsIoCategoryForReservedSeating = ({ value, control }) => {
  const reservedSeating = control.get('reservedSeating');
  if (reservedSeating !== true) return null;

  if (
    (reservedSeating === true && isNil(value)) ||
    (reservedSeating === true && typeof value === 'string' && value === '')
  )
    return 'Seats Io category is required.';

  return null;
};

const resolverUnlimitedTicket = ({ field, action, formControl }) => {
  if (action.type === 'change' && field.value) {
    const resetFields = ['maxCapacity'];
    const products = formControl.get('products');
    if (!isEmpty(products)) {
      products.value.forEach(product => {
        resetFields.push(product.limit.name);
      });
    }
    formControl.reset(resetFields, true);
  }
};

const checkSoldBOToValidate = validateFn => formState => {
  const { name, control } = formState;
  if (
    !isEmpty(control.extraData) &&
    control.extraData.mode === 'edit' &&
    !isEmpty(control.extraData.eventController) &&
    typeof control.extraData.eventController.getProductSalesInfo === 'function'
  ) {
    const fieldIndex = name.lastIndexOf('.');
    const groupKey = name.substring(0, fieldIndex);
    const goFanCreated = control.get(`${groupKey}.created`)?.value ?? false;
    const boxOfficeTicket = control.get(`${groupKey}.boxOfficeTicket`)?.value ?? {};
    if (!goFanCreated && !isEmpty(boxOfficeTicket) && !!boxOfficeTicket?.created) {
      const { getProductSalesInfo } = control.extraData.eventController ?? {};
      const { saleQuantity } = getProductSalesInfo(boxOfficeTicket?.id) ?? {};
      if (saleQuantity > 0) {
        return null;
      }
    }
  }
  return validateFn(formState);
};

const formFieldSchema = {
  description: {
    name: 'description',
    type: 'string'
  },
  displayOnTicket: {
    name: 'displayOnTicket',
    type: 'boolean'
  },
  enabled: {
    name: 'enabled',
    type: 'boolean'
  },
  fieldType: {
    name: 'fieldType',
    type: 'string'
  },
  id: {
    name: 'id',
    type: 'number'
  },
  name: {
    name: 'name',
    type: 'string'
  },
  options: {
    name: 'options',
    type: 'array',
    childKey: 'id',
    child: {
      id: {
        name: 'id',
        type: 'number'
      },
      value: {
        name: 'value',
        type: 'string'
      }
    }
  },
  required: {
    name: 'required',
    type: 'boolean'
  }
};

const formSchema = {
  unlimitedTicket: {
    name: 'unlimitedTicket',
    type: 'boolean',
    validator: [],
    resolver: [resolverUnlimitedTicket]
  },
  maxCapacity: {
    name: 'maxCapacity',
    type: 'number',
    validator: [requiredMaxCapacity, rangeMaxCapacity, underEventSales]
  },
  products: {
    name: 'products',
    type: 'array',
    validator: [],
    childKey: 'id',
    child: {
      id: {
        name: 'id',
        type: 'number',
        validator: []
      },
      accountId: {
        name: 'accountId',
        type: 'string'
      },
      accountProductId: {
        name: 'accountProductId',
        type: 'string'
      },
      productCreatedAt: {
        name: 'productCreatedAt',
        type: 'number',
        validator: []
      },
      distributionChannel: {
        name: 'distributionChannel',
        type: 'string',
        validator: []
      },
      groupId: {
        name: 'groupId',
        type: 'string',
        validator: []
      },
      name: {
        name: 'name',
        type: 'string',
        validator: [checkSoldBOToValidate(requiredTicketName), checkSoldBOToValidate(invalidTicketName)]
      },
      enabled: {
        name: 'enabled',
        type: 'boolean',
        validator: []
      },
      created: {
        name: 'created',
        type: 'boolean',
        validator: []
      },
      productType: {
        name: 'productType',
        type: 'string',
        validator: []
      },
      limit: {
        name: 'limit',
        type: 'number',
        validator: [rangeLimit, underTicketSales]
      },
      price: {
        name: 'price',
        type: 'number',
        validator: [checkSoldBOToValidate(requiredPrice), checkSoldBOToValidate(rangePrice)]
      },
      generateLink: {
        name: 'generateLink',
        type: 'boolean',
        validator: []
      },
      hiddenFees: {
        name: 'hiddenFees',
        type: 'boolean',
        validator: []
      },
      encodedString: {
        name: 'encodedString',
        type: 'string',
        validator: []
      },
      customColor: {
        name: 'customColor',
        type: 'string',
        validator: []
      },
      boxOfficeTicket: {
        name: 'boxOfficeTicket',
        type: 'object',
        validator: []
      },
      reservedSeating: {
        name: 'reservedSeating',
        type: 'boolean'
      },
      seatsIoCategory: {
        name: 'seatsIoCategory',
        type: 'string',
        validator: [requiredSeatsIoCategoryForReservedSeating]
      },
      rateId: {
        name: 'rateId',
        type: 'number'
      },
      fee: {
        name: 'fee',
        type: 'number'
      },
      ticketLimitPerOrder: {
        name: 'ticketLimitPerOrder',
        type: 'number'
      },
      formFields: {
        name: 'formFields',
        type: 'array',
        childKey: 'id',
        child: formFieldSchema
      },
      salesEndDate: {
        name: 'salesEndDate',
        type: 'string'
      },
      salesEndTime: {
        name: 'salesEndTime',
        type: 'string'
      },
      salesStartDate: {
        name: 'salesStartDate',
        type: 'string'
      },
      salesStartTime: {
        name: 'salesStartTime',
        type: 'string'
      },
      packCount: {
        name: 'packCount',
        type: 'number'
      }
    }
  },
  seasonTickets: {
    name: 'seasonTickets',
    type: 'array',
    validator: [],
    childKey: 'id',
    child: {
      id: {
        name: 'id',
        type: 'number',
        validator: []
      },
      accountId: {
        name: 'accountId',
        type: 'string'
      },
      productCreatedAt: {
        name: 'productCreatedAt',
        type: 'number',
        validator: []
      },
      distributionChannel: {
        name: 'distributionChannel',
        type: 'string',
        validator: []
      },
      groupId: {
        name: 'groupId',
        type: 'string',
        validator: []
      },
      name: {
        name: 'name',
        type: 'string',
        validator: [requiredTicketName, invalidTicketName]
      },
      enabled: {
        name: 'enabled',
        type: 'boolean',
        validator: []
      },
      created: {
        name: 'created',
        type: 'boolean',
        validator: []
      },
      productType: {
        name: 'productType',
        type: 'string',
        validator: []
      },
      limit: {
        name: 'limit',
        type: 'number',
        validator: [rangeLimit, underTicketSales]
      },
      price: {
        name: 'price',
        type: 'number',
        validator: [requiredPrice, rangePrice]
      },
      generateLink: {
        name: 'generateLink',
        type: 'boolean',
        validator: []
      },
      hiddenFees: {
        name: 'hiddenFees',
        type: 'boolean',
        validator: []
      },
      encodedString: {
        name: 'encodedString',
        type: 'string',
        validator: []
      },
      customColor: {
        name: 'customColor',
        type: 'string',
        validator: []
      },
      reservedSeating: {
        name: 'reservedSeating',
        type: 'boolean'
      },
      seatsIoCategory: {
        name: 'seatsIoCategory',
        type: 'string',
        validator: [requiredSeatsIoCategoryForReservedSeating]
      },
      formFields: {
        name: 'formFields',
        type: 'array',
        childKey: 'id',
        child: formFieldSchema
      },
      rateId: {
        name: 'rateId',
        type: 'number'
      },
      fee: {
        name: 'fee',
        type: 'number'
      },
      salesEndDate: {
        name: 'salesEndDate',
        type: 'string'
      },
      salesEndTime: {
        name: 'salesEndTime',
        type: 'string'
      },
      salesStartDate: {
        name: 'salesStartDate',
        type: 'string'
      },
      salesStartTime: {
        name: 'salesStartTime',
        type: 'string'
      },
      ticketLimitPerOrder: {
        name: 'ticketLimitPerOrder',
        type: 'number'
      }
    }
  }
};

export const productSchema = ({
  id = new Date().getTime(),
  productType = 'TICKET',
  accountId = undefined,
  accountProductId = '',
  name = '',
  created = false,
  enabled = true,
  price = undefined,
  limit = undefined,
  productCreatedAt = new Date().getTime(),
  distributionChannel = DISTRIBUTION_CHANNEL.GOFAN,
  groupId = null,
  hiddenFees = false,
  generateLink = false,
  encodedString = undefined,
  customColor,
  boxOfficeTicket = {},
  reservedSeating = false,
  seatsIoCategory = undefined,
  rateId = null,
  fee = null,
  salesEndDate = undefined,
  salesEndTime = undefined,
  salesStartDate = undefined,
  salesStartTime = undefined,
  ticketLimitPerOrder = undefined,
  formFields = [],
  packCount = undefined
}) => ({
  id,
  accountId,
  accountProductId,
  name,
  created,
  enabled,
  productType,
  limit,
  price,
  productCreatedAt,
  distributionChannel,
  groupId,
  hiddenFees,
  generateLink,
  encodedString,
  customColor,
  boxOfficeTicket,
  reservedSeating,
  seatsIoCategory,
  rateId,
  fee,
  salesEndDate,
  salesEndTime,
  salesStartDate,
  salesStartTime,
  ticketLimitPerOrder,
  formFields,
  packCount
});
export default formSchema;
