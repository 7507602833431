export interface AccountMetricsQuery {
  schoolId: string[];
  periodStart: string;
  periodEnd: string;
  activity?: string[];
  activities?: string[];
  dataset?: string;
}

export interface SalesMetrics {
  refund_amount: number;
  gross_to_account: number;
  ticket_gross_count: number;
  ticket_refund_count: number;
  ticket_redemption_count: number;
  unique_orders: number;
  net_to_account: number;
  ticket_net_count: number;
}

export interface AccountMetricsDTO {
  byActivityName: {
    [key: string]: SalesMetrics;
  };
  byDayAndActivityName: {
    [key: string]: SalesMetrics;
  };
  totals: SalesMetrics;
}

export const METRIC_TICKET_SALES = 'net_to_account';
export const METRIC_TICKET_SOLD = 'ticket_net_count';
