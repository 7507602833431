import {
  EventIntegrationActionType,
  ON_REFRESH_PARTNER_EVENT,
  ON_REFRESH_PARTNER_EVENT_COMPLETED,
  ON_UPDATE_PARTNER_EVENT_STATUS,
  ON_REFRESH_PARTNER_SEASON,
  ON_REFRESH_PARTNER_SEASON_COMPLETED,
  ON_SET_EVENT_ERROR,
  ON_SET_PREV_PARTNER_EVENT,
  ON_UPDATE_PARTNER_EVENT,
  ON_CREATE_PARTNER_EVENT,
  ON_CANCEL_PARTNER_EVENT,
  ON_DELETE_PARTNER_EVENT,
  ON_UPDATE_PARTNER_EVENTS,
  ON_IGNORE_PARTNER_EVENT,
  ON_IGNORE_PARTNER_EVENT_COMPLETED,
  ON_CANCELLED_TASK_IGNORE_PARTNER_EVENT,
  ON_RESTORE_PARTNER_EVENT,
  ON_RESTORE_PARTNER_EVENT_COMPLETED,
  ON_CANCELLED_TASK_RESTORE_PARTNER_EVENT,
  ON_DECLINE_PARTNER_EVENT,
  ON_DECLINE_PARTNER_EVENT_COMPLETED,
  ON_CANCELLED_TASK_DECLINE_PARTNER_EVENT,
  ON_PUBLISH_ALL_EVENTS,
  ON_PUBLISH_ALL_EVENTS_COMPLETED,
  ON_PUBLISH_PARTNER_EVENT,
  ON_PUBLISH_PARTNER_EVENT_COMPLETED,
  ON_CANCELLED_TASK_PUBLISH_PARTNER_EVENT,
  ON_IGNORE_PARTNER_SEASON,
  ON_IGNORE_PARTNER_SEASON_COMPLETED,
  ON_CANCELLED_TASK_IGNORE_PARTNER_SEASON,
  ON_CANCELLED_TASK_RESTORE_PARTNER_SEASON,
  ON_RESTORE_PARTNER_SEASON,
  ON_RESTORE_PARTNER_SEASON_COMPLETED,
  ON_PUBLISH_ALL_SEASONS,
  ON_PUBLISH_ALL_SEASONS_COMPLETED,
  ON_PUBLISH_PARTNER_SEASON,
  ON_PUBLISH_PARTNER_SEASON_COMPLETED,
  ON_CANCELLED_TASK_PUBLISH_PARTNER_SEASON
} from '@modules/event-integrations_V2/middleware/actionTypes';

export const onRefreshPartnerEvent = (payload: any): EventIntegrationActionType => ({
  type: ON_REFRESH_PARTNER_EVENT,
  payload
});

export const onRefreshPartnerEventCompleted = (payload: any): EventIntegrationActionType => ({
  type: ON_REFRESH_PARTNER_EVENT_COMPLETED,
  payload
});

export const onUpdatePartnerEventStatus = (payload: any): EventIntegrationActionType => ({
  type: ON_UPDATE_PARTNER_EVENT_STATUS,
  payload
});

export const onRefreshPartnerSeason = (payload: any): EventIntegrationActionType => ({
  type: ON_REFRESH_PARTNER_SEASON,
  payload
});

export const onRefreshPartnerSeasonCompleted = (payload: any): EventIntegrationActionType => ({
  type: ON_REFRESH_PARTNER_SEASON_COMPLETED,
  payload
});

export const onSetEventError = (payload: any): EventIntegrationActionType => ({
  type: ON_SET_EVENT_ERROR,
  payload
});

export const onSetPrevPartnerEvent = (payload: any): EventIntegrationActionType => ({
  type: ON_SET_PREV_PARTNER_EVENT,
  payload
});

export const onUpdatePartnerEvent = (payload: any): EventIntegrationActionType => ({
  type: ON_UPDATE_PARTNER_EVENT,
  payload
});

export const onCreatePartnerEvent = (payload: any): EventIntegrationActionType => ({
  type: ON_CREATE_PARTNER_EVENT,
  payload
});

export const onCancelPartnerEvent = (payload: any): EventIntegrationActionType => ({
  type: ON_CANCEL_PARTNER_EVENT,
  payload
});

export const onDeletePartnerEvent = (payload: any): EventIntegrationActionType => ({
  type: ON_DELETE_PARTNER_EVENT,
  payload
});

export const onUpdatePartnerEvents = (payload: any): EventIntegrationActionType => ({
  type: ON_UPDATE_PARTNER_EVENTS,
  payload
});

////////////////////////////////////////////////

export const onIgnorePartnerEvent = (payload: any): EventIntegrationActionType => ({
  type: ON_IGNORE_PARTNER_EVENT,
  payload
});

export const onIgnorePartnerEventCompleted = (payload: any): EventIntegrationActionType => ({
  type: ON_IGNORE_PARTNER_EVENT_COMPLETED,
  payload
});

export const onCancelledTaskIgnorePartnerEvent = (payload: any): EventIntegrationActionType => ({
  type: ON_CANCELLED_TASK_IGNORE_PARTNER_EVENT,
  payload
});

export const onRestorePartnerEvent = (payload: any): EventIntegrationActionType => ({
  type: ON_RESTORE_PARTNER_EVENT,
  payload
});

export const onRestorePartnerEventCompleted = (payload: any): EventIntegrationActionType => ({
  type: ON_RESTORE_PARTNER_EVENT_COMPLETED,
  payload
});

export const onCancelledTaskRestorePartnerEvent = (payload: any): EventIntegrationActionType => ({
  type: ON_CANCELLED_TASK_RESTORE_PARTNER_EVENT,
  payload
});

export const onDeclinePartnerEvent = (payload: any): EventIntegrationActionType => ({
  type: ON_DECLINE_PARTNER_EVENT,
  payload
});

export const onDeclinePartnerEventCompleted = (payload: any): EventIntegrationActionType => ({
  type: ON_DECLINE_PARTNER_EVENT_COMPLETED,
  payload
});

export const onCancelledTaskDeclinePartnerEvent = (payload: any): EventIntegrationActionType => ({
  type: ON_CANCELLED_TASK_DECLINE_PARTNER_EVENT,
  payload
});

export const onPublishPartnerEvent = (payload: any): EventIntegrationActionType => ({
  type: ON_PUBLISH_PARTNER_EVENT,
  payload
});

export const onPublishPartnerEventCompleted = (payload: any): EventIntegrationActionType => ({
  type: ON_PUBLISH_PARTNER_EVENT_COMPLETED,
  payload
});

export const onCancelledTaskPublishPartnerEvent = (payload: any): EventIntegrationActionType => ({
  type: ON_CANCELLED_TASK_PUBLISH_PARTNER_EVENT,
  payload
});

export const onPublishAllEvents = (payload: any): EventIntegrationActionType => ({
  type: ON_PUBLISH_ALL_EVENTS,
  payload
});

export const onPublishAllEventsCompleted = (payload?: any): EventIntegrationActionType => ({
  type: ON_PUBLISH_ALL_EVENTS_COMPLETED,
  payload
});

////////////////////////////////////////////////

export const onIgnorePartnerSeason = (payload: any): EventIntegrationActionType => ({
  type: ON_IGNORE_PARTNER_SEASON,
  payload
});

export const onIgnorePartnerSeasonCompleted = (payload: any): EventIntegrationActionType => ({
  type: ON_IGNORE_PARTNER_SEASON_COMPLETED,
  payload
});

export const onCancelledTaskIgnorePartnerSeason = (payload: any): EventIntegrationActionType => ({
  type: ON_CANCELLED_TASK_IGNORE_PARTNER_SEASON,
  payload
});

export const onRestorePartnerSeason = (payload: any): EventIntegrationActionType => ({
  type: ON_RESTORE_PARTNER_SEASON,
  payload
});

export const onRestorePartnerSeasonCompleted = (payload: any): EventIntegrationActionType => ({
  type: ON_RESTORE_PARTNER_SEASON_COMPLETED,
  payload
});

export const onCancelledTaskRestorePartnerSeason = (payload: any): EventIntegrationActionType => ({
  type: ON_CANCELLED_TASK_RESTORE_PARTNER_SEASON,
  payload
});

export const onPublishPartnerSeason = (payload: any): EventIntegrationActionType => ({
  type: ON_PUBLISH_PARTNER_SEASON,
  payload
});

export const onPublishPartnerSeasonCompleted = (payload: any): EventIntegrationActionType => ({
  type: ON_PUBLISH_PARTNER_SEASON_COMPLETED,
  payload
});

export const onCancelledTaskPublishPartnerSeason = (payload: any): EventIntegrationActionType => ({
  type: ON_CANCELLED_TASK_PUBLISH_PARTNER_SEASON,
  payload
});

export const onPublishAllSeasons = (payload: any): EventIntegrationActionType => ({
  type: ON_PUBLISH_ALL_SEASONS,
  payload
});

export const onPublishAllSeasonsCompleted = (payload?: any): EventIntegrationActionType => ({
  type: ON_PUBLISH_ALL_SEASONS_COMPLETED,
  payload
});
