/* eslint-disable camelcase */
import { useState, useEffect, useRef, useMemo, useContext, useCallback } from 'react';
import MultiSelectWithAllOpt from '@common/carbon-ui/customized/MultiSelectWithAllOpt';
import { Dropdown, SkeletonPlaceholder } from 'carbon-components-react';
import { ComboChart } from '@carbon/charts-react';
import type { ComboChartOptions } from '@carbon/charts/interfaces';
import { ScaleTypes, TickRotations } from '@carbon/charts/interfaces';
import { formatDateTime } from '@app/utils/dateUtils';
import EventService from '@events/services/events.service';
import './ticket-sales-by-week.scss';
import eventsRepository from '@app/modules/events/repositories/events.repository';
import { uniqueId, isNil } from 'lodash';
import moment from 'moment';
import { isEmpty } from '@app/utils/objectUtils';
import type { AccountDTO } from '@gofan/api/accounts';
import { RootContext } from '@app/RootContext';
import { checkInternalUser } from '@app/api/services/UserService';
import useTimeoutEffect from '@app/utils/hooks/useTimeoutEffect';
import { isTestEnv } from '@app/utils/testUtils';
import NoData from '../no-data/no-data';
import { isArrayHasIdsEqual } from '../../utils/utils';
import { bubbleUpCommonSports } from '../ticket-sales-by-activity/ByWeekMonth/ticket-sales-by-activity.utils';
import { LIMIT_ACCOUNTS_DISPLAY } from '../../constants/constants';
import { AccountService } from '@gofan/api/accounts';

type DropdownItemType = {
  id: string;
  label: string;
};

interface Props {
  accounts: AccountDTO[];
}

const listMonday = EventService.getListMondayOfYear(
  {
    year: 2021,
    month: 7,
    day: 1
  },
  true
);

interface SumItem {
  net_to_account: number;
  ticket_net_count: number;
}

const mapToChartData = (periodStart: number | string) => (response: any) => {
  const { byDayAndActivityName } = response.data;
  let resultChartData: any[] = [];
  let date = moment(periodStart, 'YYYYMMDD').format('YYYY-MM-DD');
  for (let i = 0; i < 7; i++) {
    let sumItem: SumItem = {
      net_to_account: 0,
      ticket_net_count: 0
    };
    const activitiesInDay = byDayAndActivityName[moment(date, 'YYYY-MM-DD').format('YYYYMMDD')];
    if (activitiesInDay) {
      sumItem = Object.values(activitiesInDay).reduce(
        (result: SumItem, current: any) => ({
          net_to_account: result.net_to_account + (current.net_to_account || 0),
          ticket_net_count: result.ticket_net_count + (current.ticket_net_count || 0)
        }),
        {
          net_to_account: 0,
          ticket_net_count: 0
        }
      );
    }

    resultChartData = [
      ...resultChartData,
      {
        group: 'Ticket sales',
        date,
        salesValue: sumItem.net_to_account
      },
      { group: 'Tickets sold', date, soldValue: sumItem.ticket_net_count }
    ];
    date = moment(date, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD');
  }
  return resultChartData;
};

const checkAllSalesValueIsZero = (chartData: any[]) =>
  chartData.filter(item => item.group === 'Ticket sales').every(item => item.salesValue === 0);

const checkAllSoldValueIsZero = (chartData: any[]) =>
  chartData.filter(item => item.group === 'Tickets sold').every(item => item.soldValue === 0);

export default function TicketSalesByWeek({ accounts }: Props) {
  const [weekDate, setWeekDate] = useState<DropdownItemType>();
  const { currentUser } = useContext(RootContext);
  const timerGetChartRef = useRef<any>(null);
  const timerGetActivitiesRef = useRef<any>(null);
  // Activity
  const [activities, setActivities] = useState<any[]>([]);
  const accountsRef = useRef<AccountDTO[]>([]);
  // selectedActivities is used for showNoData
  // When selection offs (handleOpenActivitiesSelect executes with params false)
  // or click icon x on select
  // or getChartData executes
  // we will execute setSelectedActivities
  const [selectedActivities, setSelectedActivities] = useState<any[]>([]);
  // setSelectedActivitiesTemp only executes when handleChangeActivity & getChartData execute
  const [selectedActivitiesTemp, setSelectedActivitiesTemp] = useState<any[]>([]);
  const [selectedActivitiesTempToCompare, setSelectedActivitiesTempToCompare] = useState<any[]>([]);
  const [isOpenActivitiesSelect, setIsOpenActivitiesSelect] = useState(false);

  // School
  const [selectedAccounts, setSelectedAccounts] = useState<AccountDTO[]>([]);
  const [selectedAccountsTemp, setSelectedAccountsTemp] = useState<AccountDTO[]>([]);
  const [isOpenAccountsSelect, setIsOpenAccountsSelect] = useState(false);
  const isUnmounted = useRef<boolean>(false);

  const [chartData, setChartData] = useState<any[]>([]);
  const [keyMultiselectActivity, setKeyMultiselectActivity] = useState(uniqueId());
  const [keyMultiselectSchools, setKeyMultiselectSchools] = useState(uniqueId());
  const [loading, setLoading] = useState(true);
  const TicketSalesByWeekRef = useRef<HTMLElement>(null);
  const ComboChartRef = useRef();
  const showNoData = isEmpty(selectedActivities) || isEmpty(selectedAccounts) || isEmpty(activities);

  const getChartData = useCallback(
    (params: { schoolId: string[]; weekDate: DropdownItemType; activity?: string[] }) => {
      clearTimeout(timerGetChartRef.current);
      timerGetChartRef.current = setTimeout(() => {
        setLoading(true);
        const periodStart = moment(params.weekDate.id, 'MM/DD/YYYY').format('YYYYMMDD');
        const periodEnd = moment(params.weekDate.id, 'MM/DD/YYYY').add(6, 'day').format('YYYYMMDD');
        EventService.getAccountMetrics({ periodStart, periodEnd, schoolId: params.schoolId, activity: params.activity })
          .then(mapToChartData(periodStart))
          .then(res => {
            if (isUnmounted.current) {
              return;
            }
            setChartData(res);
          })
          .catch(err => {
            console.error('Daily ticket sales by week', err);
          })
          .finally(() => {
            setLoading(false);
          });
      }, 300);
    },
    []
  );

  const options: ComboChartOptions = useMemo(
    () => ({
      points: {
        enabled: false
      },
      axes: {
        left: {
          title: 'Ticket sales',
          mapsTo: 'salesValue',
          domain: checkAllSalesValueIsZero(chartData) ? [0, 10] : undefined,
          ticks: {
            formatter: (value: number) => (value % 1 === 0 ? `$${value}` : ''),
            number: 4
          }
        },
        right: {
          title: 'Tickets sold',
          mapsTo: 'soldValue',
          domain: checkAllSoldValueIsZero(chartData) ? [0, 10] : undefined,
          ticks: {
            formatter: (value: number) => (value % 1 === 0 ? value : ''),
            number: 4
          },
          // @ts-ignore
          correspondingDatasets: ['Tickets sold']
        },
        bottom: {
          title: 'Time',
          scaleType: ScaleTypes.TIME,
          mapsTo: 'date',
          ticks: {
            formatter: (value: any) =>
              `${formatDateTime({
                date: value
              }).toDate('DD MMM')}`,
            rotation: TickRotations.ALWAYS
          }
        }
      },
      comboChartTypes: [
        {
          type: 'area',
          options: {},
          correspondingDatasets: ['Ticket sales']
        },
        {
          type: 'line',
          correspondingDatasets: ['Tickets sold']
        }
      ],
      timeScale: {
        addSpaceOnEdges: 0
      },
      color: {
        scale: {
          'Tickets sold': '#000000',
          'Ticket sales': '#733cca'
        }
      },
      toolbar: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      height: '380px',
      width: '100%'
    }),
    [chartData]
  );

  const isHideAccountSelect = !checkInternalUser(currentUser.role) && accounts.length === 1;
  const optionAccounts = selectedAccountsTemp.length === LIMIT_ACCOUNTS_DISPLAY ? selectedAccountsTemp : accounts;

  const getActivities = useCallback((schoolId: string[]) => {
    clearTimeout(timerGetActivitiesRef.current);
    setLoading(true);
    timerGetActivitiesRef.current = setTimeout(
      () =>
        EventService.getAccountMetrics({
          periodStart: 20210701,
          periodEnd: 20220630,
          schoolId
        })
          .then(res => {
            if (isUnmounted.current) {
              return;
            }
            const activityName = Object.keys(res.data.byActivityName).map((activity, index) => ({
              id: index,
              name: activity,
              status: 'Active'
            }));
            const sortedActivityName = bubbleUpCommonSports(activityName);
            setActivities(sortedActivityName);
            setSelectedActivities(sortedActivityName);
            setSelectedActivitiesTemp(sortedActivityName);
            return sortedActivityName;
          })
          .catch(err => {
            console.error('Daily ticket sales by week', err);
          })
          .finally(() => {
            setLoading(false);
          }),
      300
    );
  }, []);

  useEffect(() => {
    const [listChange, newSelectedAccounts] = AccountService.getListAccountChanges<AccountDTO>(
      accountsRef.current,
      accounts,
      selectedAccounts
    );
    if (listChange.length === 0) {
      return;
    }

    const schoolId = newSelectedAccounts.map(item => item.id);
    setWeekDate(listMonday[0]);
    setSelectedAccounts(newSelectedAccounts);
    setSelectedAccountsTemp(newSelectedAccounts);
    setKeyMultiselectSchools(uniqueId());
    getActivities(schoolId);
    getChartData({
      weekDate: listMonday[0],
      schoolId
    });

    accountsRef.current = accounts;
  }, [accounts]);

  useTimeoutEffect(
    () => {
      setKeyMultiselectActivity(uniqueId());
    },
    [activities],
    100
  );

  const handleChangeWeekOf = (e: any) => {
    setWeekDate(e.selectedItem);
    getChartData({
      weekDate: e.selectedItem,
      schoolId: selectedAccounts.map(item => item.id),
      activity: selectedActivitiesTemp.map(activity => activity.name)
    });
  };

  const renderItem = (item: any) => (
    <div className='item-select'>
      <span className='item-select-text'>{item.name}</span>
    </div>
  );

  const handleChangeActivity = (event: { selectedItems: any[] }) => {
    const { selectedItems } = event;
    setSelectedActivitiesTemp(selectedItems);
    // For case click x icon on select to remove all
    if (!isOpenActivitiesSelect) {
      setSelectedActivities(selectedItems);
      setChartData([]);
    }
  };

  const handleOpenActivitiesSelect = (isOpen: boolean) => {
    setIsOpenActivitiesSelect(isOpen);
    if (isOpen) {
      setSelectedActivitiesTempToCompare(selectedActivitiesTemp);
    } else if (!isArrayHasIdsEqual(selectedActivitiesTempToCompare, selectedActivitiesTemp)) {
      setSelectedActivities(selectedActivitiesTemp);
      getChartData({
        weekDate,
        schoolId: selectedAccounts.map(item => item.id),
        activity: selectedActivitiesTemp.map((activity: any) => activity.name)
      });
    }
  };

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  useEffect(() => {
    const handleResize = () => {
      const colElement = TicketSalesByWeekRef.current?.parentElement;
      const height = colElement?.offsetHeight || 0;
      colElement?.parentElement?.parentElement?.style.setProperty('--minHeight', `${height}px`);
    };
    const chartElement = ComboChartRef?.current?.chartRef;

    if (!isNil(chartElement?.current)) {
      chartElement?.current.querySelectorAll<HTMLElement>('.axis-title').forEach(item => {
        if (item.textContent === 'Time') {
          item.style.display = 'none';
        }
      });
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div className='ticket-sales-by-week' ref={TicketSalesByWeekRef}>
      <h3 className='ticket-sales-by-week-title'>Daily ticket sales by week</h3>
      {!isHideAccountSelect && (
        <div className='ticket-sales-by-week-row'>
          <div className='ticket-sales-by-week-col-8'>
            <MultiSelectWithAllOpt
              id='select-school-by-week'
              itemToString={(item: any) => item?.name || ''}
              items={optionAccounts}
              initialSelectedItems={selectedAccountsTemp}
              label='schools selected'
              light={false}
              onChange={_onChangeAccounts}
              open={isOpenAccountsSelect}
              key={keyMultiselectSchools}
              onMenuChange={_onMenuAccountsChange}
              titleText='Schools'
              disabled={isEmpty(accounts) || loading}
            />
          </div>
        </div>
      )}
      <div className='ticket-sales-by-week-row'>
        <div className='ticket-sales-by-week-col'>
          <MultiSelectWithAllOpt
            items={activities}
            label='activities selected'
            itemToElement={renderItem}
            itemToString={(item: any) => item.name}
            initialSelectedItems={selectedActivitiesTemp}
            onChange={handleChangeActivity}
            onMenuChange={handleOpenActivitiesSelect}
            titleText='Activity'
            id='activity-selection'
            key={keyMultiselectActivity}
            disabled={isEmpty(activities) || loading}
            sortItems={(items: any) => [...items]}
          />
        </div>
        <div className='ticket-sales-by-week-col'>
          <Dropdown
            light={false}
            id='for-week-of'
            label=''
            titleText='For week of'
            items={listMonday}
            itemToString={item => (item ? item.label : '')}
            onChange={handleChangeWeekOf}
            selectedItem={weekDate}
            disabled={isEmpty(activities) || loading}
          />
        </div>
      </div>
      {showNoData && <NoData />}
      {!showNoData && (
        <>
          {loading && <SkeletonPlaceholder style={{ width: '100%', height: 380 }} />}
          {!loading && !isEmpty(chartData) && !isTestEnv && (
            <ComboChart data={chartData} options={options} ref={ComboChartRef} />
          )}
        </>
      )}
    </div>
  );

  async function _onChangeAccounts({ selectedItems }: { selectedItems: any[] }) {
    // Sliced if click All Checkbox
    const selectedItemsSliced = selectedItems.slice(0, LIMIT_ACCOUNTS_DISPLAY);
    setSelectedAccountsTemp(selectedItemsSliced);
    // Force re-render MultiSelect when selected items length > limit
    if (selectedItems.length > LIMIT_ACCOUNTS_DISPLAY) {
      setKeyMultiselectSchools(uniqueId());
    }
    // For case click x icon on select to remove all
    if (!isOpenAccountsSelect) {
      _resetAllField();
    }
  }
  async function _onMenuAccountsChange(isOpen: boolean) {
    if (!isArrayHasIdsEqual(selectedAccounts, selectedAccountsTemp)) {
      setSelectedAccounts(selectedAccountsTemp);
      if (isEmpty(selectedAccountsTemp)) {
        _resetAllField();
        return;
      }

      const schoolId = selectedAccountsTemp.map(item => item.id);
      const activitiesRes = await getActivities(schoolId);
      await getChartData({
        weekDate,
        schoolId,
        activity: activitiesRes?.map(activity => activity.name)
      });
    }
    setIsOpenAccountsSelect(isOpen);
  }

  function _resetAllField() {
    setActivities([]);
    setSelectedActivities([]);
    setSelectedActivitiesTemp([]);
    setSelectedAccounts([]);
    setSelectedAccountsTemp([]);
    setChartData([]);
  }
}
