// @flow
import React from 'react';
import { groupBy, pick } from 'lodash';

import HistoryDAO from '../dao/HistoryDAO';

const fetchUserHistory = searchRequest => HistoryDAO.fetchUserHistory(searchRequest);

const fetchAccountHistory = searchRequest => HistoryDAO.fetchAccountHistory(searchRequest);

const fetchEventHistory = searchRequest => HistoryDAO.fetchEventHistory(searchRequest);

const HISTORY_CHANGE_ACTION = {
  ADDED: 'ADDED',
  UPDATED: 'UPDATED',
  DELETED: 'DELETED'
};

const generateHistoryLogDetails = ({ data = {}, showDeleteByFields = [], filterByFields = [] }) => {
  let fields = groupBy(data.changes, 'field');
  if (filterByFields.length > 0) {
    fields = pick(fields, filterByFields);
  }
  const fieldStr = [];
  const size = Object.keys(fields).length - 1;
  const logKey = `${data.updatedBy}${data.updatedAt}`;

  Object.keys(fields).forEach((key, index) => {
    const changeActions = fields[key];
    const fieldName = `${key}`.toLowerCase();
    const showDeleteAction = showDeleteByFields.some(field => fieldName.includes(`${field}`.toLowerCase()));

    const logs = [];
    changeActions.forEach(change => {
      const action = `${change.action}`.toUpperCase();
      if (action !== HISTORY_CHANGE_ACTION.DELETED) {
        logs.push({
          key: `${change.value}_${action}`,
          item: <span>{change.value}</span>
        });
      } else if (showDeleteAction && action === HISTORY_CHANGE_ACTION.DELETED) {
        logs.push({
          key: `${change.value}_${action}`,
          item: <span className='delete-log'>{change.value}</span>
        });
      }
    });

    fieldStr.push(
      <span className='history-logs' key={`${logKey}${key}`}>
        {key === 'CREATED' ? '' : `${key} = `}
        {logs.map((log, logIndex) => (
          <React.Fragment key={log.key}>
            {log.item}
            {logs.length - 1 === logIndex ? '' : ', '}
          </React.Fragment>
        ))}
        {size === index ? '' : '; '}
      </span>
    );
  });
  return fieldStr;
};

export default {
  fetchUserHistory,
  fetchAccountHistory,
  fetchEventHistory,
  generateHistoryLogDetails
};
