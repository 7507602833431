import { isEmpty, get } from 'lodash';
import { RostersRepository } from './rosters.repository';
import { formatUSPhoneNumber, getSchoolYearId, inchesToFeetString } from '@gofan/utils';

import { StudentSetupFormDataSchema, StaffSetupFormDataSchema } from '@gofan/api/rosters';
import { UserService, getAvatarUrl } from '@gofan/api/users';
import type { TeamResponseDTO, UserDTO, TeamInfo } from '@gofan/api';

import type {
  RosterDTO,
  StaffRole,
  RosterSetupData,
  StudentSetupFormData,
  StaffSetupFormData,
  StaffDTO,
  CreateRosterRequestDTO,
  CreateRosterResponseDTO,
  UpdateRosterRequestDTO,
  StaffCreateDTO
} from './rosters.model';

export class RosterService {
  static normalizeError = error => {
    const customError = error;
    if (error.response?.status === 401) {
      customError.message = `User is not authorized to get this school's roster.`;
      return Promise.reject(customError);
    }
    return Promise.reject(error);
  };

  static getRosterById(teamId: string): Promise<RosterDTO> {
    return RostersRepository.getRosterById(teamId).catch(this.normalizeError);
  }

  static getRoster(params: { teamId: string; schoolYearId: number; schoolId?: string }): Promise<RosterDTO[]> {
    return RostersRepository.getRoster(params).catch(this.normalizeError);
  }

  static createRoster(
    createRoster: CreateRosterRequestDTO,
    params?: { schoolId?: string; teamId?: string }
  ): Promise<CreateRosterResponseDTO> {
    return RostersRepository.createRoster(createRoster, params);
  }

  static createUpdateRoster(
    roster: UpdateRosterRequestDTO,
    params?: { schoolId?: string; teamId?: string }
  ): Promise<CreateRosterResponseDTO> {
    return roster.id ? RostersRepository.updateRoster(roster, params) : RostersRepository.createRoster(roster, params);
  }

  static getRosterSetupData({
    team,
    accountId,
    schoolYearId,
    defaultValue,
    mode
  }: {
    team: TeamResponseDTO;
    accountId: string;
    schoolYearId: number;
    mode: 'edit' | 'create';
    defaultValue: {
      students: StudentSetupFormData[];
      staff: StaffSetupFormData[];
    };
  }): Promise<RosterSetupData> {
    if (!team || !schoolYearId) return Promise.reject(Error('Invalid team or school year id'));
    return new Promise((resolve, reject) => {
      const { id: teamId } = team;
      const defaultData = { teamId, ...defaultValue, id: teamId, accountId: '' };

      RostersRepository.getRoster({ teamId, schoolYearId, schoolId: accountId }).then((rosters: RosterDTO[]) => {
        const roster = get(rosters, '0');
        if (mode === 'create' && isEmpty(roster)) {
          resolve(defaultData);
          return;
        }
        if (mode === 'create' && !isEmpty(roster)) {
          reject(Error('Roster already exists'));
          return;
        }
        if (mode === 'edit' && isEmpty(roster)) {
          reject(Error('Roster is not existed. Please create roster first', { cause: 404 }));
          return;
        }
        const { players, coachStaff } = roster;
        Promise.allSettled((coachStaff || []).map(item => UserService.getUserByEmail(item.email))).then(results => {
          const students: RosterSetupData['students'] = players.map(({ photoUrl: avatar, heightInInches, ...item }) => {
            const data = {
              ...item,
              height: heightInInches ? inchesToFeetString(heightInInches) : '',
              formId: item.id,
              avatar
            };
            try {
              StudentSetupFormDataSchema.validateSync(data);
              return data;
            } catch (err) {
              return {
                ...data,
                shouldCheckError: true,
                formState: {
                  isValid: false
                }
              };
            }
          });

          const staff: RosterSetupData['staff'] = [];
          results.forEach((result, index) => {
            let data;
            if (result.status === 'fulfilled') {
              const userInfo = result.value;
              const coach = coachStaff[index];
              data = {
                ...coach,
                formId: coach.id,
                avatar: userInfo.avatar ?? '',
                phoneNumber: formatUSPhoneNumber(userInfo.phoneNumber ?? ''),
                userInfo
              };
            } else if (result.status === 'rejected') {
              data = {
                ...coachStaff[index],
                formId: coachStaff[index].id,
                avatar: '',
                phoneNumber: '',
                userInfo: undefined
              };
            }
            try {
              StaffSetupFormDataSchema.validateSync(data);
            } catch (err) {
              data = {
                ...data,
                shouldCheckError: true,
                formState: {
                  isValid: false
                }
              };
            }
            staff.push(data);
          });
          resolve({
            roster,
            teamId: roster.teamId,
            students: isEmpty(players) ? defaultValue.students : students,
            staff: isEmpty(roster.coachStaff) ? defaultValue.staff : staff
          });
        });
      });
    });
  }

  static getRostersByAccountId(accountId: string): Promise<RosterDTO[]> {
    return RostersRepository.getRosterByAccountId(accountId);
  }

  static async updateRosterStaff(
    team: TeamInfo,
    users: UserDTO[],
    schoolId: string,
    action: 'upsert' | 'remove',
    schoolYearId = getSchoolYearId()
  ) {
    const { teamId } = team;
    const rosters: RosterDTO[] = await RostersRepository.getRoster({
      teamId,
      schoolId,
      schoolYearId
    });
    const roster = rosters?.[0];

    if (action === 'remove' && roster) {
      return RostersRepository.updateRoster(
        {
          ...roster,
          ...team,
          // coachStaff: roster.coachStaff.filter(st => !users.map(u => u.email).includes(st.email))
          coachStaff: roster.coachStaff.map(item =>
            users.find(({ email }) => email === item.email) ? { ...item, inactive: true } : item
          )
        },
        { schoolId, teamId }
      );
    }

    let newStaff: StaffCreateDTO[] = users.map(user => ({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: formatUSPhoneNumber(user.phoneNumber ?? ''),
      avatar: getAvatarUrl(user.avatar ?? '', user.email),
      staffRole: user.role as StaffRole,
      eligible: true
    }));

    // if roster does not exist, create new roster in SAVE mode
    if (isEmpty(roster)) {
      const createRosterData: CreateRosterRequestDTO = {
        ...team,
        coachStaff: newStaff,
        players: [],
        schoolId,
        isRosterVisible: true,
        schoolYear: schoolYearId ?? getSchoolYearId()
        // published: false
      };
      return RostersRepository.createRoster(createRosterData, { schoolId, teamId });
    }

    // find previous staff which is not in current staff list
    const updatedStaffEmails = newStaff.map(st => st.email);
    const prevStaff = roster.coachStaff.filter(st => !updatedStaffEmails.includes(st.email)) as StaffDTO[];
    // update current visible of staff
    newStaff = newStaff.map(item => {
      const prevStaff = roster.coachStaff.find(
        st => st.email === item.email && st.staffRole === item.staffRole
      ) as StaffDTO;
      if (prevStaff) {
        return { ...prevStaff, ...item };
      }
      return item;
    });
    return RostersRepository.updateRoster(
      { ...roster, ...team, coachStaff: [...prevStaff, ...newStaff] },
      { schoolId, teamId }
    );
  }

  static async getRosterDetails(params: {
    teamId: string;
    schoolYearId: number;
    schoolId: string;
  }): Promise<RosterDTO> {
    return new Promise((resolve, reject) => {
      RostersRepository.getRoster(params).then((rosters: RosterDTO[]) => {
        if (isEmpty(rosters[0])) {
          reject(Error('Roster is not existed'));
          return;
        }
        const { coachStaff = [] } = rosters[0];
        Promise.allSettled(coachStaff.map(staff => UserService.getUserByEmail(staff.email))).then(results => {
          results.forEach((staff, index) => {
            if (staff.status === 'fulfilled') {
              coachStaff[index] = {
                ...coachStaff[index],
                phoneNumber: String(staff.value.phoneNumber)
              };
            }
          });
          resolve(rosters[0]);
        });
      });
    });
  }
}
