import { ZENDESK_SANDBOX, ZENDESK_PRODUCTION } from './zendesk.constant';

// -------------------------------------------------------------------- //
// Environment Configs
const dev = {
  s3: {
    BASE_PATH: 'uploads/school',
    BASE_AVATAR_PATH: 'uploads/users/avatar',
    BASE_SPONSOR_PATH: 'uploads/sponsor',
    TEMPLATE_SEASON_PATH: 'uploads/templates/Schedule+Upload+Template.csv',
    NEW_TEMPLATE_SEASON_PATH: 'uploads/templates/Multi-Season+Schedule+Upload.csv',
    TEMPLATE_SEASON_ONE_PER_SPORT_PATH: 'uploads/templates/Multi-Season+Schedule+Upload+-+one+season+per+sport.csv',
    TEMPLATE_SEASON_SPERATE_EACH_TEAM_PATH:
      'uploads/templates/Multi-Season+Schedule+Upload+-+seperate+season+for+each+team.csv',
    TEMPLATE_ACCESS_CODE_EVENT_LEVEL: 'uploads/access_code/event-access-code-template.xlsx',
    TEMPLATE_ACCESS_CODE_TICKET_LEVEL: 'uploads/access_code/ticket-access-code-template.xlsx',
    TEMPLATE_PROMOTION_CODE_EVENT_LEVEL: 'uploads/promotion/event-promotion-code-template.xlsx',
    TEMPLATE_PROMOTION_CODE_TICKET_LEVEL: 'uploads/promotion/ticket-promotion-code-template.xlsx',
    TEMPLATE_SEASON_RENEWAL_CAMPAIGN: 'uploads/season-renewal/season-ticket-renewal-fan-template.csv',
    TEMPLATE_TOURNAMENT_EVENT: 'uploads/tournament/tournament-event-template.xlsx',
    BUCKET_PROMO_CODE: 'bulkuploaddev',
    BUCKET_BULK_UPLOAD_EVENT: 'bulkupeventsloadqa2',
    URL: 's3.amazonaws.com',
    BUCKET: 'dev-gofan-assets'
  },
  apiGateway: {
    URL: 'https://api.dev1.gofan.co/'
  },
  apiGatewayMicroservices: {
    URL: 'https://apigateway.dev1.gofan.co/orderservice'
  },
  apiPartnerEvent: {
    URL: 'https://apigateway.qa2.gofan.co/partnerIntegrations/'
  },
  apiGatewayEventService: {
    URL: 'https://dev.apigateway.gofan.co/eventservice'
  },
  apiProgramService: {
    URL: 'https://k8s-dashbirds-program-service-dev1.playonshareddev.playonsports.com/v1'
  },
  apiFlatFile: {
    URL: 'https://dev.apigateway.gofan.co/flatfile/',
    FLATFILE_API_KEY: 'pk_88982bbbe2254aadafa18e74e6c27b41',
    FLATFILE_ENVIRONMENT_ID: 'us_env_zyre1LeN',
    ORG_INFO: {
      id: '34414',
      name: 'GoFan'
    },
    UPLOAD_SEASON: {
      MULTIPLE_SPORTS: {
        EMBED_ID: '20e415cb-6735-48c7-9eda-fd0714d1e94a',
        PORTAL_KEY: 'M0XvKi3I7y8pdEZPayz0jwDUhP1jhfzwuzoo2i43B4nInNMObETQ5Cder7AJnk1y'
      },
      ONE_SEASON_PER_SPORT: {
        EMBED_ID: 'bca7d6ec-253a-41a8-b275-8d6aae4aee0a',
        PORTAL_KEY: 'pMMztLgko3COHZ9OMQsLeuYzwazhtwOCfKehD2uibHwmXgB36rF3ROCJDNoWX5al'
      },
      SEPARATE_SEASONS: {
        EMBED_ID: '33d73438-ab96-40b2-baee-c250457c3879',
        PORTAL_KEY: 'jUJk2F0Qy6v8neXzP0UEn4hjBniPV9jJXP6ZQvmdhJELKX7Yvy7IiSKwNEn4Zc1J'
      }
    },
    uploadEvent: {
      EMBED_ID: '46c75419-7593-40c0-b060-69fd0f1f458f',
      PORTAL_KEY: 'Q0r803vRiGMtzJrs77TQmz74Yl9ZYgh9bWkquSoN1VDpRDZeJiyinGQp4pjr2EUZ'
    },
    uploadEditEvent: {
      EMBED_ID: '2ade3a0d-e27f-46f7-b4f3-31de30b45e16',
      PORTAL_KEY: 'j5v5i0Ohoorg5qsPbkUYlGj8mfJ51Qd2DE9dkED0CLwUmxhV9ez7J2UgSuyCuO7A'
    },
    uploadSeasonRenewalCampaign: {
      EMBED_ID: '1988541e-411c-49bb-9cf1-bc48895f7cae',
      PORTAL_KEY: 'VrhHGpILCQ5mGekoIJC0B5kP9Qiznii5JRWKPx7czdAZIGlnBxuTHynYKwYWvJnS'
    },
    uploadTournamentEvents: {
      EMBED_ID: '21a92ba3-8050-47e9-8ee0-fccce2ecf4b1',
      PORTAL_KEY: 'XxTZTz1Fmqw37NVigxMfisHYHblQeoTX0oXSVNbwef5kF00KoFEItr4OIBX85OWN'
    }
  },
  apiUnity: {
    URL: 'https://search-api.qa.nfhsnetwork.com/'
  },
  apiSponsorship: {
    URL: 'https://k8s-dashbirds-sponsorship-service-qa1.playonsharedqa.playonsports.com/'
  },
  launchDarklyConfig: {
    clientSideID: '652ebe7daecf6a1263cb19f1'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyAz4rWizunZA3z6h5AnTXy9zA5PayEKd6s',
    authDomain: 'gofan-dev-dcb9e.firebaseapp.com',
    databaseURL: 'https://gofan-dev-dcb9e-default-rtdb.firebaseio.com',
    projectId: 'gofan-dev-dcb9e',
    storageBucket: 'gofan-dev-dcb9e.appspot.com',
    messagingSenderId: '603487872798'
  },
  IGNORE_HEALTH_CHECK_FOR_REDIS: true,
  HOSTNAME: 'https://hq.dev1.gofan.co',
  GOFAN_URL: 'https://dev1.gofan.co/',
  PAYMENT_CYCLE_LEARN_MORE_URL:
    'http://schoolsupport.gofan.co/hc/en-us/articles/4408034547223-When-do-we-get-paid-for-our-event-sales-',
  SEASON_PAYMENT_CYCLE_LEARN_MORE_URL:
    'https://gofanschool.zendesk.com/hc/en-us/articles/7430838218253-When-do-we-get-paid-for-our-event-sales-',
  TIMEOUT_WHEN_INACTIVE: 3600000,
  TIMEOUT_TO_START_IDLE: 30000,
  TIMEOUT_NOTIFICATION: 5000,
  ACCOUNT_CACHE_EXPIRES_TIME: 300,
  BETA_READINESS_MODE: true,
  MAX_UPLOADED_FILE_SIZE: 500e6,
  TIMEOUT_UPLOAD_FILE: 180000,
  DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST: 1000,
  JOBS_CHECKING_STATUS_INTERVAL: 2000,
  JOBS_CHECKING_REPORT_STATUS_INTERVAL: 5000,
  MOBILE_PASS_FORM_ID: 3041,
  HUDDLE_MICROSERVICE_API_MODE: true,
  TEMP_HIDE_PAYMENT_REPORT_FEATURE: false,
  TEMP_HIDE_EVENT_INSIGHT_FEATURE: false,
  RATE_ID1: 1,
  RATE_ID5: 5,
  RATE_ID8: 8,
  SEATS_IO_PUBLIC_WORKSPACE_KEY: 'a73a8506-a782-46b0-a5c3-30eeb25d85d3',
  GOOGLE_API_KEY: 'AIzaSyDsdPVvuHaiqPw_wYWvGIuLv6lkDSKEyYE',
  ZENDESK_TICKET: ZENDESK_SANDBOX,
  PLAY_ON_ENDPOINT: 'https://freeze-frame.playonshareddev.playonsports.com',
  PLAYON_SITE_SETUP_GRAPHQL: 'https://kickoff.playonshareddev.playonsports.com/graphql'
};

const dev2 = {
  s3: {
    BASE_PATH: 'uploads/school',
    BASE_AVATAR_PATH: 'uploads/users/avatar',
    BASE_SPONSOR_PATH: 'uploads/sponsor',
    TEMPLATE_SEASON_PATH: 'uploads/templates/Schedule+Upload+Template.csv',
    NEW_TEMPLATE_SEASON_PATH: 'uploads/templates/Multi-Season+Schedule+Upload.csv',
    TEMPLATE_SEASON_ONE_PER_SPORT_PATH: 'uploads/templates/Multi-Season+Schedule+Upload+-+one+season+per+sport.csv',
    TEMPLATE_SEASON_SPERATE_EACH_TEAM_PATH:
      'uploads/templates/Multi-Season+Schedule+Upload+-+seperate+season+for+each+team.csv',
    TEMPLATE_ACCESS_CODE_EVENT_LEVEL: 'uploads/access_code/event-access-code-template.xlsx',
    TEMPLATE_ACCESS_CODE_TICKET_LEVEL: 'uploads/access_code/ticket-access-code-template.xlsx',
    TEMPLATE_PROMOTION_CODE_EVENT_LEVEL: 'uploads/promotion/event-promotion-code-template.xlsx',
    TEMPLATE_PROMOTION_CODE_TICKET_LEVEL: 'uploads/promotion/ticket-promotion-code-template.xlsx',
    TEMPLATE_SEASON_RENEWAL_CAMPAIGN: 'uploads/season-renewal/season-ticket-renewal-fan-template.csv',
    TEMPLATE_TOURNAMENT_EVENT: 'uploads/tournament/tournament-event-template.xlsx',
    BUCKET_PROMO_CODE: 'bulkuploadqa2',
    BUCKET_BULK_UPLOAD_EVENT: 'bulkupeventsloadqa2',
    URL: 's3.amazonaws.com',
    BUCKET: 'dev2-gofan-assets'
  },
  apiGateway: {
    URL: 'https://api.dev2.gofan.co/'
  },
  apiGatewayMicroservices: {
    URL: 'https://apigateway.dev2.gofan.co/orderservice'
  },
  apiPartnerEvent: {
    URL: 'https://apigateway.dev2.gofan.co/partnerIntegrations/'
  },
  apiGatewayEventService: {
    URL: 'https://apigateway.dev2.gofan.co/eventservice'
  },
  apiProgramService: {
    URL: 'https://k8s-dashbirds-program-service-dev1.playonshareddev.playonsports.com/v1'
  },
  apiFlatFile: {
    URL: 'https://dev.apigateway.gofan.co/flatfile/',
    FLATFILE_API_KEY: 'pk_88982bbbe2254aadafa18e74e6c27b41',
    FLATFILE_ENVIRONMENT_ID: 'us_env_zyre1LeN',
    ORG_INFO: {
      id: '34414',
      name: 'GoFan'
    },
    UPLOAD_SEASON: {
      MULTIPLE_SPORTS: {
        EMBED_ID: 'd802974c-f130-467a-b8cc-951996dee8b1',
        PORTAL_KEY: 'ALp7kBNcKK1GsFWNAw8Kr9AZZsWpHu9im8ArfnYFOAaowumMPZm1xm0ym5aKY6pc'
      },
      ONE_SEASON_PER_SPORT: {
        EMBED_ID: 'bf7456cb-0785-4755-8959-21b59f6d6df8',
        PORTAL_KEY: 'rqhjKmX9J24tIcJsiQoyiIQTgucmj2YVZJ4ikEVu5JRbu5f5lE8OlV4qg7KD78bP'
      },
      SEPARATE_SEASONS: {
        EMBED_ID: 'ce2d5cae-3d3c-44d3-85e1-ba760681d5f8',
        PORTAL_KEY: '1YJOuqj0YqzF0sfKkimMoolHlsY21ivo9COf1sVCQIthUXAhkHJokAaQue8lkofS'
      }
    },
    uploadEvent: {
      EMBED_ID: '46c75419-7593-40c0-b060-69fd0f1f458f',
      PORTAL_KEY: 'Q0r803vRiGMtzJrs77TQmz74Yl9ZYgh9bWkquSoN1VDpRDZeJiyinGQp4pjr2EUZ'
    },
    uploadEditEvent: {
      EMBED_ID: '2ade3a0d-e27f-46f7-b4f3-31de30b45e16',
      PORTAL_KEY: 'j5v5i0Ohoorg5qsPbkUYlGj8mfJ51Qd2DE9dkED0CLwUmxhV9ez7J2UgSuyCuO7A'
    },
    uploadSeasonRenewalCampaign: {
      EMBED_ID: '1988541e-411c-49bb-9cf1-bc48895f7cae',
      PORTAL_KEY: 'VrhHGpILCQ5mGekoIJC0B5kP9Qiznii5JRWKPx7czdAZIGlnBxuTHynYKwYWvJnS'
    },
    uploadTournamentEvents: {
      EMBED_ID: '21a92ba3-8050-47e9-8ee0-fccce2ecf4b1',
      PORTAL_KEY: 'XxTZTz1Fmqw37NVigxMfisHYHblQeoTX0oXSVNbwef5kF00KoFEItr4OIBX85OWN'
    }
  },
  apiUnity: {
    URL: 'https://search-api.qa.nfhsnetwork.com/'
  },
  apiSponsorship: {
    URL: 'https://k8s-dashbirds-sponsorship-service-qa1.playonsharedqa.playonsports.com/'
  },
  launchDarklyConfig: {
    clientSideID: '652ebe7daecf6a1263cb19f1'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyBaUkT8NlKtAM2LNmDaVlqfAayhdl5-xf4',
    authDomain: 'gofan-development-4c60c.firebaseapp.com',
    databaseURL: 'https://gofan-development-4c60c.firebaseio.com',
    projectId: 'gofan-development-4c60c',
    storageBucket: 'gofan-development-4c60c.appspot.com',
    messagingSenderId: '39651054752'
  },
  IGNORE_HEALTH_CHECK_FOR_REDIS: true,
  HOSTNAME: 'https://hq.dev2.gofan.co',
  GOFAN_URL: 'https://dev2.gofan.co/',
  PAYMENT_CYCLE_LEARN_MORE_URL:
    'http://schoolsupport.gofan.co/hc/en-us/articles/4408034547223-When-do-we-get-paid-for-our-event-sales-',
  SEASON_PAYMENT_CYCLE_LEARN_MORE_URL:
    'https://gofanschool.zendesk.com/hc/en-us/articles/7430838218253-When-do-we-get-paid-for-our-event-sales-',
  TIMEOUT_WHEN_INACTIVE: 3600000,
  TIMEOUT_TO_START_IDLE: 30000,
  TIMEOUT_NOTIFICATION: 5000,
  ACCOUNT_CACHE_EXPIRES_TIME: 300,
  BETA_READINESS_MODE: true,
  MAX_UPLOADED_FILE_SIZE: 500e6,
  TIMEOUT_UPLOAD_FILE: 180000,
  DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST: 1000,
  JOBS_CHECKING_STATUS_INTERVAL: 2000,
  JOBS_CHECKING_REPORT_STATUS_INTERVAL: 5000,
  MOBILE_PASS_FORM_ID: 3041,
  HUDDLE_MICROSERVICE_API_MODE: true,
  TEMP_HIDE_PAYMENT_REPORT_FEATURE: false,
  TEMP_HIDE_EVENT_INSIGHT_FEATURE: false,
  RATE_ID1: 1,
  RATE_ID5: 5,
  RATE_ID8: 8,
  SEATS_IO_PUBLIC_WORKSPACE_KEY: 'a73a8506-a782-46b0-a5c3-30eeb25d85d3',
  GOOGLE_API_KEY: 'AIzaSyDsdPVvuHaiqPw_wYWvGIuLv6lkDSKEyYE',
  ZENDESK_TICKET: ZENDESK_SANDBOX,
  PLAY_ON_ENDPOINT: 'https://freeze-frame.playonshareddev.playonsports.com',
  PLAYON_SITE_SETUP_GRAPHQL: 'https://kickoff.playonshareddev.playonsports.com/graphql'
};

const dev3 = {
  s3: {
    BASE_PATH: 'uploads/school',
    BASE_AVATAR_PATH: 'uploads/users/avatar',
    BASE_SPONSOR_PATH: 'uploads/sponsor',
    TEMPLATE_SEASON_PATH: 'uploads/templates/Schedule+Upload+Template.csv',
    TEMPLATE_SEASON_RENEWAL_CAMPAIGN: 'uploads/season-renewal/season-ticket-renewal-fan-template.csv',
    TEMPLATE_TOURNAMENT_EVENT: 'uploads/tournament/tournament-event-template.xlsx',
    BUCKET_PROMO_CODE: 'bulkuploadqa2',
    BUCKET_BULK_UPLOAD_EVENT: 'bulkupeventsloadqa2',
    URL: 'https://s3.amazonaws.com',
    BUCKET: 'dev3-gofan-assets'
  },
  apiGateway: {
    URL: 'https://api.dev3.gofan.co/'
  },
  apiGatewayMicroservices: {
    URL: 'https://apigateway.dev3.gofan.co/orderservice'
  },
  apiPartnerEvent: {
    URL: 'https://apigateway.dev2.gofan.co/partnerIntegrations/'
  },
  apiGatewayEventService: {
    URL: 'https://apigateway.dev2.gofan.co/eventservice'
  },
  apiProgramService: {
    URL: 'https://k8s-dashbirds-program-service-dev1.playonshareddev.playonsports.com/v1'
  },
  apiFlatFile: {
    URL: 'https://dev.apigateway.gofan.co/flatfile/',
    FLATFILE_API_KEY: 'pk_88982bbbe2254aadafa18e74e6c27b41',
    FLATFILE_ENVIRONMENT_ID: 'us_env_zyre1LeN',
    ORG_INFO: {
      id: '34414',
      name: 'GoFan'
    },
    UPLOAD_SEASON: {
      MULTIPLE_SPORTS: {
        EMBED_ID: 'd802974c-f130-467a-b8cc-951996dee8b1',
        PORTAL_KEY: 'ALp7kBNcKK1GsFWNAw8Kr9AZZsWpHu9im8ArfnYFOAaowumMPZm1xm0ym5aKY6pc'
      },
      ONE_SEASON_PER_SPORT: {
        EMBED_ID: 'bf7456cb-0785-4755-8959-21b59f6d6df8',
        PORTAL_KEY: 'rqhjKmX9J24tIcJsiQoyiIQTgucmj2YVZJ4ikEVu5JRbu5f5lE8OlV4qg7KD78bP'
      },
      SEPARATE_SEASONS: {
        EMBED_ID: 'ce2d5cae-3d3c-44d3-85e1-ba760681d5f8',
        PORTAL_KEY: '1YJOuqj0YqzF0sfKkimMoolHlsY21ivo9COf1sVCQIthUXAhkHJokAaQue8lkofS'
      }
    },
    uploadEvent: {
      EMBED_ID: '46c75419-7593-40c0-b060-69fd0f1f458f',
      PORTAL_KEY: 'Q0r803vRiGMtzJrs77TQmz74Yl9ZYgh9bWkquSoN1VDpRDZeJiyinGQp4pjr2EUZ'
    },
    uploadEditEvent: {
      EMBED_ID: '2ade3a0d-e27f-46f7-b4f3-31de30b45e16',
      PORTAL_KEY: 'j5v5i0Ohoorg5qsPbkUYlGj8mfJ51Qd2DE9dkED0CLwUmxhV9ez7J2UgSuyCuO7A'
    },
    uploadSeasonRenewalCampaign: {
      EMBED_ID: '1988541e-411c-49bb-9cf1-bc48895f7cae',
      PORTAL_KEY: 'VrhHGpILCQ5mGekoIJC0B5kP9Qiznii5JRWKPx7czdAZIGlnBxuTHynYKwYWvJnS'
    },
    uploadTournamentEvents: {
      EMBED_ID: '21a92ba3-8050-47e9-8ee0-fccce2ecf4b1',
      PORTAL_KEY: 'XxTZTz1Fmqw37NVigxMfisHYHblQeoTX0oXSVNbwef5kF00KoFEItr4OIBX85OWN'
    }
  },
  apiUnity: {
    URL: 'https://search-api.qa.nfhsnetwork.com/'
  },
  apiSponsorship: {
    URL: 'https://k8s-dashbirds-sponsorship-service-qa1.playonsharedqa.playonsports.com/'
  },
  launchDarklyConfig: {
    clientSideID: '652ebe7daecf6a1263cb19f1'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyBaUkT8NlKtAM2LNmDaVlqfAayhdl5-xf4',
    authDomain: 'gofan-development-4c60c.firebaseapp.com',
    databaseURL: 'https://gofan-development-4c60c.firebaseio.com',
    projectId: 'gofan-development-4c60c',
    storageBucket: 'gofan-development-4c60c.appspot.com',
    messagingSenderId: '39651054752'
  },
  IGNORE_HEALTH_CHECK_FOR_REDIS: true,
  HOSTNAME: 'https://hq.dev3.gofan.co',
  GOFAN_URL: 'https://dev3.gofan.co/',
  PAYMENT_CYCLE_LEARN_MORE_URL:
    'http://schoolsupport.gofan.co/hc/en-us/articles/4408034547223-When-do-we-get-paid-for-our-event-sales-',
  SEASON_PAYMENT_CYCLE_LEARN_MORE_URL:
    'https://gofanschool.zendesk.com/hc/en-us/articles/7430838218253-When-do-we-get-paid-for-our-event-sales-',
  TIMEOUT_WHEN_INACTIVE: 3600000,
  TIMEOUT_TO_START_IDLE: 30000,
  TIMEOUT_NOTIFICATION: 5000,
  ACCOUNT_CACHE_EXPIRES_TIME: 300,
  BETA_READINESS_MODE: true,
  MAX_UPLOADED_FILE_SIZE: 500e6,
  TIMEOUT_UPLOAD_FILE: 180000,
  DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST: 1000,
  JOBS_CHECKING_STATUS_INTERVAL: 2000,
  JOBS_CHECKING_REPORT_STATUS_INTERVAL: 5000,
  MOBILE_PASS_FORM_ID: 3041,
  HUDDLE_MICROSERVICE_API_MODE: true,
  TEMP_HIDE_PAYMENT_REPORT_FEATURE: false,
  TEMP_HIDE_EVENT_INSIGHT_FEATURE: false,
  RATE_ID1: 1,
  RATE_ID5: 5,
  RATE_ID8: 8,
  SEATS_IO_PUBLIC_WORKSPACE_KEY: 'a73a8506-a782-46b0-a5c3-30eeb25d85d3',
  GOOGLE_API_KEY: 'AIzaSyDsdPVvuHaiqPw_wYWvGIuLv6lkDSKEyYE',
  ZENDESK_TICKET: ZENDESK_SANDBOX,
  PLAY_ON_ENDPOINT: 'https://freeze-frame.playonshareddev.playonsports.com',
  PLAYON_SITE_SETUP_GRAPHQL: 'https://kickoff.playonshareddev.playonsports.com/graphql'
};

const dev4 = {
  s3: {
    BASE_PATH: 'uploads/school',
    BASE_AVATAR_PATH: 'uploads/users/avatar',
    BASE_SPONSOR_PATH: 'uploads/sponsor',
    TEMPLATE_SEASON_PATH: 'uploads/templates/Schedule+Upload+Template.csv',
    TEMPLATE_SEASON_RENEWAL_CAMPAIGN: 'uploads/season-renewal/season-ticket-renewal-fan-template.csv',
    TEMPLATE_TOURNAMENT_EVENT: 'uploads/tournament/tournament-event-template.xlsx',
    BUCKET_PROMO_CODE: 'bulkuploadqa2',
    BUCKET_BULK_UPLOAD_EVENT: 'bulkupeventsloadqa2',
    URL: 's3.amazonaws.com',
    BUCKET: 'dev2-gofan-assets'
  },
  apiGateway: {
    URL: 'https://api.dev2.gofan.co/'
  },
  apiGatewayMicroservices: {
    URL: 'https://apigateway.dev2.gofan.co/orderservice'
  },
  apiPartnerEvent: {
    URL: 'https://apigateway.dev2.gofan.co/partnerIntegrations/'
  },
  apiGatewayEventService: {
    URL: 'https://apigateway.dev2.gofan.co/eventservice'
  },
  apiProgramService: {
    URL: 'https://k8s-dashbirds-program-service-dev1.playonshareddev.playonsports.com/v1'
  },
  apiFlatFile: {
    URL: 'https://dev.apigateway.gofan.co/flatfile/',
    FLATFILE_API_KEY: 'pk_88982bbbe2254aadafa18e74e6c27b41',
    FLATFILE_ENVIRONMENT_ID: 'us_env_zyre1LeN',
    ORG_INFO: {
      id: '34414',
      name: 'GoFan'
    },
    UPLOAD_SEASON: {
      MULTIPLE_SPORTS: {
        EMBED_ID: 'd802974c-f130-467a-b8cc-951996dee8b1',
        PORTAL_KEY: 'ALp7kBNcKK1GsFWNAw8Kr9AZZsWpHu9im8ArfnYFOAaowumMPZm1xm0ym5aKY6pc'
      },
      ONE_SEASON_PER_SPORT: {
        EMBED_ID: 'bf7456cb-0785-4755-8959-21b59f6d6df8',
        PORTAL_KEY: 'rqhjKmX9J24tIcJsiQoyiIQTgucmj2YVZJ4ikEVu5JRbu5f5lE8OlV4qg7KD78bP'
      },
      SEPARATE_SEASONS: {
        EMBED_ID: 'ce2d5cae-3d3c-44d3-85e1-ba760681d5f8',
        PORTAL_KEY: '1YJOuqj0YqzF0sfKkimMoolHlsY21ivo9COf1sVCQIthUXAhkHJokAaQue8lkofS'
      }
    },
    uploadEvent: {
      EMBED_ID: '46c75419-7593-40c0-b060-69fd0f1f458f',
      PORTAL_KEY: 'Q0r803vRiGMtzJrs77TQmz74Yl9ZYgh9bWkquSoN1VDpRDZeJiyinGQp4pjr2EUZ'
    },
    uploadEditEvent: {
      EMBED_ID: '2ade3a0d-e27f-46f7-b4f3-31de30b45e16',
      PORTAL_KEY: 'j5v5i0Ohoorg5qsPbkUYlGj8mfJ51Qd2DE9dkED0CLwUmxhV9ez7J2UgSuyCuO7A'
    },
    uploadSeasonRenewalCampaign: {
      EMBED_ID: '1988541e-411c-49bb-9cf1-bc48895f7cae',
      PORTAL_KEY: 'VrhHGpILCQ5mGekoIJC0B5kP9Qiznii5JRWKPx7czdAZIGlnBxuTHynYKwYWvJnS'
    },
    uploadTournamentEvents: {
      EMBED_ID: '21a92ba3-8050-47e9-8ee0-fccce2ecf4b1',
      PORTAL_KEY: 'XxTZTz1Fmqw37NVigxMfisHYHblQeoTX0oXSVNbwef5kF00KoFEItr4OIBX85OWN'
    }
  },
  apiUnity: {
    URL: 'https://search-api.qa.nfhsnetwork.com/'
  },
  apiSponsorship: {
    URL: 'https://k8s-dashbirds-sponsorship-service-qa1.playonsharedqa.playonsports.com/'
  },
  launchDarklyConfig: {
    clientSideID: '652ebe7daecf6a1263cb19f1'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyBaUkT8NlKtAM2LNmDaVlqfAayhdl5-xf4',
    authDomain: 'gofan-development-4c60c.firebaseapp.com',
    databaseURL: 'https://gofan-development-4c60c.firebaseio.com',
    projectId: 'gofan-development-4c60c',
    storageBucket: 'gofan-development-4c60c.appspot.com',
    messagingSenderId: '39651054752'
  },
  IGNORE_HEALTH_CHECK_FOR_REDIS: true,
  HOSTNAME: 'https://hq.dev4.gofan.co',
  GOFAN_URL: 'https://dev4.gofan.co/',
  PAYMENT_CYCLE_LEARN_MORE_URL:
    'http://schoolsupport.gofan.co/hc/en-us/articles/4408034547223-When-do-we-get-paid-for-our-event-sales-',
  SEASON_PAYMENT_CYCLE_LEARN_MORE_URL:
    'https://gofanschool.zendesk.com/hc/en-us/articles/7430838218253-When-do-we-get-paid-for-our-event-sales-',
  TIMEOUT_WHEN_INACTIVE: 3600000,
  TIMEOUT_TO_START_IDLE: 30000,
  TIMEOUT_NOTIFICATION: 5000,
  ACCOUNT_CACHE_EXPIRES_TIME: 300,
  BETA_READINESS_MODE: true,
  MAX_UPLOADED_FILE_SIZE: 500e6,
  TIMEOUT_UPLOAD_FILE: 180000,
  DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST: 1000,
  JOBS_CHECKING_STATUS_INTERVAL: 2000,
  JOBS_CHECKING_REPORT_STATUS_INTERVAL: 5000,
  MOBILE_PASS_FORM_ID: 3041,
  HUDDLE_MICROSERVICE_API_MODE: true,
  TEMP_HIDE_PAYMENT_REPORT_FEATURE: false,
  TEMP_HIDE_EVENT_INSIGHT_FEATURE: false,
  RATE_ID1: 1,
  RATE_ID5: 5,
  RATE_ID8: 8,
  SEATS_IO_PUBLIC_WORKSPACE_KEY: 'a73a8506-a782-46b0-a5c3-30eeb25d85d3',
  GOOGLE_API_KEY: 'AIzaSyDsdPVvuHaiqPw_wYWvGIuLv6lkDSKEyYE',
  ZENDESK_TICKET: ZENDESK_SANDBOX,
  PLAY_ON_ENDPOINT: 'https://freeze-frame.playonshareddev.playonsports.com',
  PLAYON_SITE_SETUP_GRAPHQL: 'https://kickoff.playonshareddev.playonsports.com/graphql'
};

const qa = {
  s3: {
    BASE_PATH: 'uploads/school',
    BASE_AVATAR_PATH: 'uploads/users/avatar',
    BASE_SPONSOR_PATH: 'uploads/sponsor',
    TEMPLATE_SEASON_PATH: 'uploads/templates/Schedule+Upload+Template.csv',
    NEW_TEMPLATE_SEASON_PATH: 'uploads/templates/Multi-Season+Schedule+Upload.csv',
    TEMPLATE_SEASON_ONE_PER_SPORT_PATH: 'uploads/templates/Multi-Season+Schedule+Upload+-+one+season+per+sport.csv',
    TEMPLATE_SEASON_SPERATE_EACH_TEAM_PATH:
      'uploads/templates/Multi-Season+Schedule+Upload+-+seperate+season+for+each+team.csv',
    TEMPLATE_ACCESS_CODE_EVENT_LEVEL: 'uploads/access_code/event-access-code-template.xlsx',
    TEMPLATE_ACCESS_CODE_TICKET_LEVEL: 'uploads/access_code/ticket-access-code-template.xlsx',
    TEMPLATE_PROMOTION_CODE_EVENT_LEVEL: 'uploads/promotion/event-promotion-code-template.xlsx',
    TEMPLATE_PROMOTION_CODE_TICKET_LEVEL: 'uploads/promotion/ticket-promotion-code-template.xlsx',
    TEMPLATE_SEASON_RENEWAL_CAMPAIGN: 'uploads/season-renewal/season-ticket-renewal-fan-template.csv',
    TEMPLATE_TOURNAMENT_EVENT: 'uploads/tournament/tournament-event-template.xlsx',
    BUCKET_PROMO_CODE: 'bulkuploadqa',
    BUCKET_BULK_UPLOAD_EVENT: 'bulkupeventsloadqa2',
    URL: 's3.amazonaws.com',
    BUCKET: 'qa-gofan-assets'
  },
  apiGateway: {
    URL: 'https://api.qa.gofan.co/'
  },
  apiGatewayMicroservices: {
    URL: 'https://qa.apigateway.gofan.co/orderservice'
  },
  apiPartnerEvent: {
    URL: 'https://apigateway.qa2.gofan.co/partnerIntegrations/'
  },
  apiGatewayEventService: {
    URL: 'https://qa.apigateway.gofan.co/eventservice'
  },
  apiProgramService: {
    URL: 'https://k8s-dashbirds-program-service-qa1.playonsharedqa.playonsports.com/v1'
  },
  apiFlatFile: {
    URL: 'https://dev.apigateway.gofan.co/flatfile/',
    FLATFILE_API_KEY: 'pk_e709266841ff431dba18213b6733e5f7',
    FLATFILE_ENVIRONMENT_ID: 'us_env_etPnGw4C',
    ORG_INFO: {
      id: '34414',
      name: 'GoFan'
    },
    UPLOAD_SEASON: {
      MULTIPLE_SPORTS: {
        EMBED_ID: '20e415cb-6735-48c7-9eda-fd0714d1e94a',
        PORTAL_KEY: 'M0XvKi3I7y8pdEZPayz0jwDUhP1jhfzwuzoo2i43B4nInNMObETQ5Cder7AJnk1y'
      },
      ONE_SEASON_PER_SPORT: {
        EMBED_ID: 'bca7d6ec-253a-41a8-b275-8d6aae4aee0a',
        PORTAL_KEY: 'pMMztLgko3COHZ9OMQsLeuYzwazhtwOCfKehD2uibHwmXgB36rF3ROCJDNoWX5al'
      },
      SEPARATE_SEASONS: {
        EMBED_ID: '33d73438-ab96-40b2-baee-c250457c3879',
        PORTAL_KEY: 'jUJk2F0Qy6v8neXzP0UEn4hjBniPV9jJXP6ZQvmdhJELKX7Yvy7IiSKwNEn4Zc1J'
      }
    },
    uploadEvent: {
      EMBED_ID: '46c75419-7593-40c0-b060-69fd0f1f458f',
      PORTAL_KEY: 'Q0r803vRiGMtzJrs77TQmz74Yl9ZYgh9bWkquSoN1VDpRDZeJiyinGQp4pjr2EUZ'
    },
    uploadEditEvent: {
      EMBED_ID: '2ade3a0d-e27f-46f7-b4f3-31de30b45e16',
      PORTAL_KEY: 'j5v5i0Ohoorg5qsPbkUYlGj8mfJ51Qd2DE9dkED0CLwUmxhV9ez7J2UgSuyCuO7A'
    },
    uploadSeasonRenewalCampaign: {
      EMBED_ID: '1988541e-411c-49bb-9cf1-bc48895f7cae',
      PORTAL_KEY: 'VrhHGpILCQ5mGekoIJC0B5kP9Qiznii5JRWKPx7czdAZIGlnBxuTHynYKwYWvJnS'
    },
    uploadTournamentEvents: {
      EMBED_ID: '21a92ba3-8050-47e9-8ee0-fccce2ecf4b1',
      PORTAL_KEY: 'XxTZTz1Fmqw37NVigxMfisHYHblQeoTX0oXSVNbwef5kF00KoFEItr4OIBX85OWN'
    }
  },
  apiUnity: {
    URL: 'https://search-api.qa.nfhsnetwork.com/'
  },
  apiSponsorship: {
    URL: 'https://k8s-dashbirds-sponsorship-service-qa1.playonsharedqa.playonsports.com/'
  },
  launchDarklyConfig: {
    clientSideID: '652ebe4e3478fd12da8db750'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDLCnYltQZpJ_qCLlyh_udxw0I3Xrw0zSA',
    authDomain: 'gofan-qa-95e16.firebaseapp.com',
    databaseURL: 'https://gofan-qa-95e16-default-rtdb.firebaseio.com',
    projectId: 'gofan-qa-95e16',
    storageBucket: 'gofan-qa-95e16.appspot.com',
    messagingSenderId: '163509521118'
  },
  IGNORE_HEALTH_CHECK_FOR_REDIS: true,
  HOSTNAME: 'https://qa.hq.gofan.co',
  GOFAN_URL: 'https://qa1.gofan.co/',
  PAYMENT_CYCLE_LEARN_MORE_URL:
    'http://schoolsupport.gofan.co/hc/en-us/articles/4408034547223-When-do-we-get-paid-for-our-event-sales-',
  SEASON_PAYMENT_CYCLE_LEARN_MORE_URL:
    'https://gofanschool.zendesk.com/hc/en-us/articles/7430838218253-When-do-we-get-paid-for-our-event-sales-',
  TIMEOUT_WHEN_INACTIVE: 3600000,
  TIMEOUT_TO_START_IDLE: 30000,
  TIMEOUT_NOTIFICATION: 5000,
  ACCOUNT_CACHE_EXPIRES_TIME: 300,
  BETA_READINESS_MODE: true,
  MAX_UPLOADED_FILE_SIZE: 500e6,
  TIMEOUT_UPLOAD_FILE: 180000,
  DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST: 1000,
  JOBS_CHECKING_STATUS_INTERVAL: 2000,
  JOBS_CHECKING_REPORT_STATUS_INTERVAL: 5000,
  MOBILE_PASS_FORM_ID: 3041,
  HUDDLE_MICROSERVICE_API_MODE: true,
  TEMP_HIDE_PAYMENT_REPORT_FEATURE: false,
  TEMP_HIDE_EVENT_INSIGHT_FEATURE: false,
  RATE_ID1: 1,
  RATE_ID5: 5,
  RATE_ID8: 8,
  SEATS_IO_PUBLIC_WORKSPACE_KEY: 'a73a8506-a782-46b0-a5c3-30eeb25d85d3',
  GOOGLE_API_KEY: 'AIzaSyDsdPVvuHaiqPw_wYWvGIuLv6lkDSKEyYE',
  ZENDESK_TICKET: ZENDESK_SANDBOX,
  PLAY_ON_ENDPOINT: 'https://freeze-frame.playonsharedqa.playonsports.com',
  PLAYON_SITE_SETUP_GRAPHQL: 'https://kickoff.playonshareddev.playonsports.com/graphql'
};

const qa2 = {
  s3: {
    BASE_PATH: 'uploads/school',
    BASE_AVATAR_PATH: 'uploads/users/avatar',
    BASE_SPONSOR_PATH: 'uploads/sponsor',
    TEMPLATE_SEASON_PATH: 'uploads/templates/Schedule+Upload+Template.csv',
    NEW_TEMPLATE_SEASON_PATH: 'uploads/templates/Multi-Season+Schedule+Upload.csv',
    TEMPLATE_SEASON_ONE_PER_SPORT_PATH: 'uploads/templates/Multi-Season+Schedule+Upload+-+one+season+per+sport.csv',
    TEMPLATE_SEASON_SPERATE_EACH_TEAM_PATH:
      'uploads/templates/Multi-Season+Schedule+Upload+-+seperate+season+for+each+team.csv',
    TEMPLATE_ACCESS_CODE_EVENT_LEVEL: 'uploads/access_code/event-access-code-template.xlsx',
    TEMPLATE_ACCESS_CODE_TICKET_LEVEL: 'uploads/access_code/ticket-access-code-template.xlsx',
    TEMPLATE_PROMOTION_CODE_EVENT_LEVEL: 'uploads/promotion/event-promotion-code-template.xlsx',
    TEMPLATE_PROMOTION_CODE_TICKET_LEVEL: 'uploads/promotion/ticket-promotion-code-template.xlsx',
    TEMPLATE_SEASON_RENEWAL_CAMPAIGN: 'uploads/season-renewal/season-ticket-renewal-fan-template.csv',
    TEMPLATE_TOURNAMENT_EVENT: 'uploads/tournament/tournament-event-template.xlsx',
    BUCKET_PROMO_CODE: 'bulkuploadqa2',
    BUCKET_BULK_UPLOAD_EVENT: 'bulkupeventsloadqa2',
    URL: 's3.amazonaws.com',
    BUCKET: 'qa2-gofan-assets'
  },
  apiGateway: {
    URL: 'https://api.qa2.gofan.co/'
  },
  apiGatewayMicroservices: {
    URL: 'https://apigateway.qa2.gofan.co/orderservice'
  },
  apiPartnerEvent: {
    URL: 'https://apigateway.qa2.gofan.co/partnerIntegrations/'
  },
  apiGatewayEventService: {
    URL: 'https://apigateway.qa2.gofan.co/eventservice'
  },
  apiProgramService: {
    URL: 'https://k8s-dashbirds-program-service-dev1.playonshareddev.playonsports.com/v1'
  },
  apiFlatFile: {
    URL: 'https://dev.apigateway.gofan.co/flatfile/',
    FLATFILE_API_KEY: 'pk_e709266841ff431dba18213b6733e5f7',
    FLATFILE_ENVIRONMENT_ID: 'us_env_etPnGw4C',
    ORG_INFO: {
      id: '34414',
      name: 'GoFan'
    },
    UPLOAD_SEASON: {
      MULTIPLE_SPORTS: {
        EMBED_ID: '20e415cb-6735-48c7-9eda-fd0714d1e94a',
        PORTAL_KEY: 'M0XvKi3I7y8pdEZPayz0jwDUhP1jhfzwuzoo2i43B4nInNMObETQ5Cder7AJnk1y'
      },
      ONE_SEASON_PER_SPORT: {
        EMBED_ID: 'bca7d6ec-253a-41a8-b275-8d6aae4aee0a',
        PORTAL_KEY: 'pMMztLgko3COHZ9OMQsLeuYzwazhtwOCfKehD2uibHwmXgB36rF3ROCJDNoWX5al'
      },
      SEPARATE_SEASONS: {
        EMBED_ID: '33d73438-ab96-40b2-baee-c250457c3879',
        PORTAL_KEY: 'jUJk2F0Qy6v8neXzP0UEn4hjBniPV9jJXP6ZQvmdhJELKX7Yvy7IiSKwNEn4Zc1J'
      }
    },
    uploadEvent: {
      EMBED_ID: '46c75419-7593-40c0-b060-69fd0f1f458f',
      PORTAL_KEY: 'Q0r803vRiGMtzJrs77TQmz74Yl9ZYgh9bWkquSoN1VDpRDZeJiyinGQp4pjr2EUZ'
    },
    uploadEditEvent: {
      EMBED_ID: '2ade3a0d-e27f-46f7-b4f3-31de30b45e16',
      PORTAL_KEY: 'j5v5i0Ohoorg5qsPbkUYlGj8mfJ51Qd2DE9dkED0CLwUmxhV9ez7J2UgSuyCuO7A'
    },
    uploadSeasonRenewalCampaign: {
      EMBED_ID: '1988541e-411c-49bb-9cf1-bc48895f7cae',
      PORTAL_KEY: 'VrhHGpILCQ5mGekoIJC0B5kP9Qiznii5JRWKPx7czdAZIGlnBxuTHynYKwYWvJnS'
    },
    uploadTournamentEvents: {
      EMBED_ID: '21a92ba3-8050-47e9-8ee0-fccce2ecf4b1',
      PORTAL_KEY: 'XxTZTz1Fmqw37NVigxMfisHYHblQeoTX0oXSVNbwef5kF00KoFEItr4OIBX85OWN'
    }
  },
  apiUnity: {
    URL: 'https://search-api.qa.nfhsnetwork.com/'
  },
  apiSponsorship: {
    URL: 'https://k8s-dashbirds-sponsorship-service-qa1.playonsharedqa.playonsports.com/'
  },
  launchDarklyConfig: {
    clientSideID: '65776dfd01b4d810830fdb7e'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCi3OgbcJo8mxIjzdaJ3-ihDrTM9d8vmVU',
    authDomain: 'gofan-qa.firebaseapp.com',
    databaseURL: 'https://gofan-qa.firebaseio.com',
    projectId: 'gofan-qa',
    storageBucket: 'gofan-qa.appspot.com',
    messagingSenderId: '140399511961'
  },
  IGNORE_HEALTH_CHECK_FOR_REDIS: true,
  HOSTNAME: 'https://hq.qa2.gofan.co',
  GOFAN_URL: 'https://qa2.gofan.co/',
  PAYMENT_CYCLE_LEARN_MORE_URL:
    'http://schoolsupport.gofan.co/hc/en-us/articles/4408034547223-When-do-we-get-paid-for-our-event-sales-',
  SEASON_PAYMENT_CYCLE_LEARN_MORE_URL:
    'https://gofanschool.zendesk.com/hc/en-us/articles/7430838218253-When-do-we-get-paid-for-our-event-sales-',
  TIMEOUT_WHEN_INACTIVE: 3600000,
  TIMEOUT_TO_START_IDLE: 30000,
  TIMEOUT_NOTIFICATION: 5000,
  ACCOUNT_CACHE_EXPIRES_TIME: 300,
  BETA_READINESS_MODE: true,
  MAX_UPLOADED_FILE_SIZE: 500e6,
  TIMEOUT_UPLOAD_FILE: 180000,
  DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST: 1000,
  JOBS_CHECKING_STATUS_INTERVAL: 2000,
  JOBS_CHECKING_REPORT_STATUS_INTERVAL: 5000,
  MOBILE_PASS_FORM_ID: 3041,
  HUDDLE_MICROSERVICE_API_MODE: true,
  TEMP_HIDE_PAYMENT_REPORT_FEATURE: false,
  TEMP_HIDE_EVENT_INSIGHT_FEATURE: false,
  RATE_ID1: 1,
  RATE_ID5: 5,
  RATE_ID8: 8,
  SEATS_IO_PUBLIC_WORKSPACE_KEY: 'a73a8506-a782-46b0-a5c3-30eeb25d85d3',
  GOOGLE_API_KEY: 'AIzaSyDsdPVvuHaiqPw_wYWvGIuLv6lkDSKEyYE',
  ZENDESK_TICKET: ZENDESK_SANDBOX,
  PLAY_ON_ENDPOINT: 'https://freeze-frame.playonsharedqa.playonsports.com',
  PLAYON_SITE_SETUP_GRAPHQL: 'https://kickoff.playonshareddev.playonsports.com/graphql'
};

const qa3 = {
  s3: {
    BASE_PATH: 'uploads/school',
    BASE_AVATAR_PATH: 'uploads/users/avatar',
    BASE_SPONSOR_PATH: 'uploads/sponsor',
    TEMPLATE_SEASON_PATH: 'uploads/templates/Schedule+Upload+Template.csv',
    TEMPLATE_SEASON_RENEWAL_CAMPAIGN: 'uploads/season-renewal/season-ticket-renewal-fan-template.csv',
    TEMPLATE_TOURNAMENT_EVENT: 'uploads/tournament/tournament-event-template.xlsx',
    URL: 's3.amazonaws.com',
    BUCKET_PROMO_CODE: 'bulkuploadqa2',
    BUCKET_BULK_UPLOAD_EVENT: 'bulkupeventsloadqa2',
    BUCKET: 'qa3-gofan-assets'
  },
  apiGateway: {
    URL: 'https://api.qa3.gofan.co/'
  },
  apiGatewayMicroservices: {
    URL: 'https://apigateway.qa3.gofan.co/orderservice'
  },
  apiPartnerEvent: {
    URL: 'https://apigateway.dev2.gofan.co/partnerIntegrations/'
  },
  apiGatewayEventService: {
    URL: 'https://apigateway.qa2.gofan.co/eventservice'
  },
  apiProgramService: {
    URL: 'https://k8s-dashbirds-program-service-qa3.playonsharedqa.playonsports.com/v1'
  },
  apiFlatFile: {
    URL: 'https://dev.apigateway.gofan.co/flatfile/',
    FLATFILE_API_KEY: 'pk_e709266841ff431dba18213b6733e5f7',
    FLATFILE_ENVIRONMENT_ID: 'us_env_etPnGw4C',
    ORG_INFO: {
      id: '34414',
      name: 'GoFan'
    },
    UPLOAD_SEASON: {
      MULTIPLE_SPORTS: {
        EMBED_ID: '20e415cb-6735-48c7-9eda-fd0714d1e94a',
        PORTAL_KEY: 'M0XvKi3I7y8pdEZPayz0jwDUhP1jhfzwuzoo2i43B4nInNMObETQ5Cder7AJnk1y'
      },
      ONE_SEASON_PER_SPORT: {
        EMBED_ID: 'bca7d6ec-253a-41a8-b275-8d6aae4aee0a',
        PORTAL_KEY: 'pMMztLgko3COHZ9OMQsLeuYzwazhtwOCfKehD2uibHwmXgB36rF3ROCJDNoWX5al'
      },
      SEPARATE_SEASONS: {
        EMBED_ID: '33d73438-ab96-40b2-baee-c250457c3879',
        PORTAL_KEY: 'jUJk2F0Qy6v8neXzP0UEn4hjBniPV9jJXP6ZQvmdhJELKX7Yvy7IiSKwNEn4Zc1J'
      }
    },
    uploadEvent: {
      EMBED_ID: '46c75419-7593-40c0-b060-69fd0f1f458f',
      PORTAL_KEY: 'Q0r803vRiGMtzJrs77TQmz74Yl9ZYgh9bWkquSoN1VDpRDZeJiyinGQp4pjr2EUZ'
    },
    uploadEditEvent: {
      EMBED_ID: '2ade3a0d-e27f-46f7-b4f3-31de30b45e16',
      PORTAL_KEY: 'j5v5i0Ohoorg5qsPbkUYlGj8mfJ51Qd2DE9dkED0CLwUmxhV9ez7J2UgSuyCuO7A'
    },
    uploadSeasonRenewalCampaign: {
      EMBED_ID: '1988541e-411c-49bb-9cf1-bc48895f7cae',
      PORTAL_KEY: 'VrhHGpILCQ5mGekoIJC0B5kP9Qiznii5JRWKPx7czdAZIGlnBxuTHynYKwYWvJnS'
    },
    uploadTournamentEvents: {
      EMBED_ID: '21a92ba3-8050-47e9-8ee0-fccce2ecf4b1',
      PORTAL_KEY: 'XxTZTz1Fmqw37NVigxMfisHYHblQeoTX0oXSVNbwef5kF00KoFEItr4OIBX85OWN'
    }
  },
  apiUnity: {
    URL: 'https://search-api.qa.nfhsnetwork.com/'
  },
  apiSponsorship: {
    URL: 'https://k8s-dashbirds-sponsorship-service-qa3.playonsharedqa.playonsports.com/'
  },
  launchDarklyConfig: {
    clientSideID: '65776e132087a61098cc48ea'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCi3OgbcJo8mxIjzdaJ3-ihDrTM9d8vmVU',
    authDomain: 'gofan-qa.firebaseapp.com',
    databaseURL: 'https://gofan-qa.firebaseio.com',
    projectId: 'gofan-qa',
    storageBucket: 'gofan-qa.appspot.com',
    messagingSenderId: '140399511961'
  },
  IGNORE_HEALTH_CHECK_FOR_REDIS: true,
  HOSTNAME: 'https://hq.qa3.gofan.co',
  GOFAN_URL: 'https://qa3.gofan.co/',
  PAYMENT_CYCLE_LEARN_MORE_URL:
    'http://schoolsupport.gofan.co/hc/en-us/articles/4408034547223-When-do-we-get-paid-for-our-event-sales-',
  SEASON_PAYMENT_CYCLE_LEARN_MORE_URL:
    'https://gofanschool.zendesk.com/hc/en-us/articles/7430838218253-When-do-we-get-paid-for-our-event-sales-',
  TIMEOUT_WHEN_INACTIVE: 3600000,
  TIMEOUT_TO_START_IDLE: 30000,
  TIMEOUT_NOTIFICATION: 5000,
  ACCOUNT_CACHE_EXPIRES_TIME: 300,
  BETA_READINESS_MODE: true,
  MAX_UPLOADED_FILE_SIZE: 500e6,
  TIMEOUT_UPLOAD_FILE: 180000,
  DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST: 1000,
  JOBS_CHECKING_STATUS_INTERVAL: 2000,
  JOBS_CHECKING_REPORT_STATUS_INTERVAL: 5000,
  MOBILE_PASS_FORM_ID: 3041,
  HUDDLE_MICROSERVICE_API_MODE: true,
  TEMP_HIDE_PAYMENT_REPORT_FEATURE: false,
  TEMP_HIDE_EVENT_INSIGHT_FEATURE: false,
  RATE_ID1: 1,
  RATE_ID5: 5,
  RATE_ID8: 8,
  SEATS_IO_PUBLIC_WORKSPACE_KEY: 'a73a8506-a782-46b0-a5c3-30eeb25d85d3',
  GOOGLE_API_KEY: 'AIzaSyDsdPVvuHaiqPw_wYWvGIuLv6lkDSKEyYE',
  ZENDESK_TICKET: ZENDESK_SANDBOX,
  PLAY_ON_ENDPOINT: 'https://freeze-frame.playonsharedqa.playonsports.com',
  PLAYON_SITE_SETUP_GRAPHQL: 'https://kickoff.playonshareddev.playonsports.com/graphql'
};

const qa4 = {
  s3: {
    BASE_PATH: 'uploads/school',
    BASE_AVATAR_PATH: 'uploads/users/avatar',
    BASE_SPONSOR_PATH: 'uploads/sponsor',
    TEMPLATE_SEASON_PATH: 'uploads/templates/Schedule+Upload+Template.csv',
    TEMPLATE_SEASON_RENEWAL_CAMPAIGN: 'uploads/season-renewal/season-ticket-renewal-fan-template.csv',
    TEMPLATE_TOURNAMENT_EVENT: 'uploads/tournament/tournament-event-template.xlsx',
    BUCKET_PROMO_CODE: 'bulkuploadqa2',
    BUCKET_BULK_UPLOAD_EVENT: 'bulkupeventsloadqa2',
    URL: 's3.amazonaws.com',
    BUCKET: 'qa2-gofan-assets'
  },
  apiGateway: {
    URL: 'https://api.qa2.gofan.co/'
  },
  apiGatewayMicroservices: {
    URL: 'https://apigateway.qa2.gofan.co/orderservice'
  },
  apiPartnerEvent: {
    URL: 'https://apigateway.qa2.gofan.co/partnerIntegrations/'
  },
  apiGatewayEventService: {
    URL: 'https://apigateway.qa2.gofan.co/eventservice'
  },
  apiProgramService: {
    URL: 'https://k8s-dashbirds-program-service-dev1.playonshareddev.playonsports.com/v1'
  },
  apiFlatFile: {
    URL: 'https://dev.apigateway.gofan.co/flatfile/',
    FLATFILE_API_KEY: 'pk_e709266841ff431dba18213b6733e5f7',
    FLATFILE_ENVIRONMENT_ID: 'us_env_etPnGw4C',
    ORG_INFO: {
      id: '34414',
      name: 'GoFan'
    },
    UPLOAD_SEASON: {
      MULTIPLE_SPORTS: {
        EMBED_ID: '20e415cb-6735-48c7-9eda-fd0714d1e94a',
        PORTAL_KEY: 'M0XvKi3I7y8pdEZPayz0jwDUhP1jhfzwuzoo2i43B4nInNMObETQ5Cder7AJnk1y'
      },
      ONE_SEASON_PER_SPORT: {
        EMBED_ID: 'bca7d6ec-253a-41a8-b275-8d6aae4aee0a',
        PORTAL_KEY: 'pMMztLgko3COHZ9OMQsLeuYzwazhtwOCfKehD2uibHwmXgB36rF3ROCJDNoWX5al'
      },
      SEPARATE_SEASONS: {
        EMBED_ID: '33d73438-ab96-40b2-baee-c250457c3879',
        PORTAL_KEY: 'jUJk2F0Qy6v8neXzP0UEn4hjBniPV9jJXP6ZQvmdhJELKX7Yvy7IiSKwNEn4Zc1J'
      }
    },
    uploadEvent: {
      EMBED_ID: '46c75419-7593-40c0-b060-69fd0f1f458f',
      PORTAL_KEY: 'Q0r803vRiGMtzJrs77TQmz74Yl9ZYgh9bWkquSoN1VDpRDZeJiyinGQp4pjr2EUZ'
    },
    uploadEditEvent: {
      EMBED_ID: '2ade3a0d-e27f-46f7-b4f3-31de30b45e16',
      PORTAL_KEY: 'j5v5i0Ohoorg5qsPbkUYlGj8mfJ51Qd2DE9dkED0CLwUmxhV9ez7J2UgSuyCuO7A'
    },
    uploadSeasonRenewalCampaign: {
      EMBED_ID: '1988541e-411c-49bb-9cf1-bc48895f7cae',
      PORTAL_KEY: 'VrhHGpILCQ5mGekoIJC0B5kP9Qiznii5JRWKPx7czdAZIGlnBxuTHynYKwYWvJnS'
    },
    uploadTournamentEvents: {
      EMBED_ID: '21a92ba3-8050-47e9-8ee0-fccce2ecf4b1',
      PORTAL_KEY: 'XxTZTz1Fmqw37NVigxMfisHYHblQeoTX0oXSVNbwef5kF00KoFEItr4OIBX85OWN'
    }
  },
  apiUnity: {
    URL: 'https://search-api.qa.nfhsnetwork.com/'
  },
  apiSponsorship: {
    URL: 'https://k8s-dashbirds-sponsorship-service-qa1.playonsharedqa.playonsports.com/'
  },
  launchDarklyConfig: {
    clientSideID: '65776e18d165091020fb58d4'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCi3OgbcJo8mxIjzdaJ3-ihDrTM9d8vmVU',
    authDomain: 'gofan-qa.firebaseapp.com',
    databaseURL: 'https://gofan-qa.firebaseio.com',
    projectId: 'gofan-qa',
    storageBucket: 'gofan-qa.appspot.com',
    messagingSenderId: '140399511961'
  },
  IGNORE_HEALTH_CHECK_FOR_REDIS: true,
  HOSTNAME: 'https://hq.qa4.gofan.co',
  GOFAN_URL: 'https://qa4.gofan.co/',
  PAYMENT_CYCLE_LEARN_MORE_URL:
    'http://schoolsupport.gofan.co/hc/en-us/articles/4408034547223-When-do-we-get-paid-for-our-event-sales-',
  SEASON_PAYMENT_CYCLE_LEARN_MORE_URL:
    'https://gofanschool.zendesk.com/hc/en-us/articles/7430838218253-When-do-we-get-paid-for-our-event-sales-',
  TIMEOUT_WHEN_INACTIVE: 3600000,
  TIMEOUT_TO_START_IDLE: 30000,
  TIMEOUT_NOTIFICATION: 5000,
  ACCOUNT_CACHE_EXPIRES_TIME: 300,
  BETA_READINESS_MODE: true,
  MAX_UPLOADED_FILE_SIZE: 500e6,
  TIMEOUT_UPLOAD_FILE: 180000,
  DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST: 1000,
  JOBS_CHECKING_STATUS_INTERVAL: 2000,
  JOBS_CHECKING_REPORT_STATUS_INTERVAL: 5000,
  MOBILE_PASS_FORM_ID: 3041,
  HUDDLE_MICROSERVICE_API_MODE: true,
  TEMP_HIDE_PAYMENT_REPORT_FEATURE: false,
  TEMP_HIDE_EVENT_INSIGHT_FEATURE: false,
  RATE_ID1: 1,
  RATE_ID5: 5,
  RATE_ID8: 8,
  SEATS_IO_PUBLIC_WORKSPACE_KEY: 'a73a8506-a782-46b0-a5c3-30eeb25d85d3',
  GOOGLE_API_KEY: 'AIzaSyDsdPVvuHaiqPw_wYWvGIuLv6lkDSKEyYE',
  ZENDESK_TICKET: ZENDESK_SANDBOX,
  PLAY_ON_ENDPOINT: 'https://freeze-frame.playonsharedqa.playonsports.com',
  PLAYON_SITE_SETUP_GRAPHQL: 'https://kickoff.playonshareddev.playonsports.com/graphql'
};

const stage = {
  s3: {
    BASE_PATH: 'uploads/school',
    BASE_AVATAR_PATH: 'uploads/users/avatar',
    BASE_SPONSOR_PATH: 'uploads/sponsor',
    TEMPLATE_SEASON_PATH: 'uploads/templates/Schedule+Upload+Template.csv',
    NEW_TEMPLATE_SEASON_PATH: 'uploads/templates/Multi-Season+Schedule+Upload.csv',
    TEMPLATE_SEASON_ONE_PER_SPORT_PATH: 'uploads/templates/Multi-Season+Schedule+Upload+-+one+season+per+sport.csv',
    TEMPLATE_SEASON_SPERATE_EACH_TEAM_PATH:
      'uploads/templates/Multi-Season+Schedule+Upload+-+seperate+season+for+each+team.csv',
    TEMPLATE_ACCESS_CODE_EVENT_LEVEL: 'uploads/access_code/event-access-code-template.xlsx',
    TEMPLATE_ACCESS_CODE_TICKET_LEVEL: 'uploads/access_code/ticket-access-code-template.xlsx',
    TEMPLATE_PROMOTION_CODE_EVENT_LEVEL: 'uploads/promotion/event-promotion-code-template.xlsx',
    TEMPLATE_PROMOTION_CODE_TICKET_LEVEL: 'uploads/promotion/ticket-promotion-code-template.xlsx',
    TEMPLATE_SEASON_RENEWAL_CAMPAIGN: 'uploads/season-renewal/season-ticket-renewal-fan-template.csv',
    TEMPLATE_TOURNAMENT_EVENT: 'uploads/tournament/tournament-event-template.xlsx',
    BUCKET_PROMO_CODE: 'bulkuploadstaging',
    BUCKET_BULK_UPLOAD_EVENT: 'bulkupeventsloadstage',
    URL: 's3.amazonaws.com',
    BUCKET: 'staging-gofan-assets'
  },
  apiGateway: {
    URL: 'https://api.stage.gofan.co/'
  },
  apiGatewayMicroservices: {
    URL: 'https://staging.apigateway.gofan.co/orderservice'
  },
  apiPartnerEvent: {
    URL: 'https://staging.apigateway.gofan.co/partnerIntegrations/'
  },
  apiGatewayEventService: {
    URL: 'https://staging.apigateway.gofan.co/eventservice'
  },
  apiProgramService: {
    URL: 'https://k8s-dashbirds-program-service-staging.playonsharedstage.playonsports.com/v1'
  },
  apiFlatFile: {
    URL: 'https://dev.apigateway.gofan.co/flatfile/',
    FLATFILE_API_KEY: 'pk_50d4f6f127d94215a44643b9e00baf7c',
    FLATFILE_ENVIRONMENT_ID: 'us_env_6AzggOjT',
    ORG_INFO: {
      id: '34414',
      name: 'GoFan'
    },
    UPLOAD_SEASON: {
      MULTIPLE_SPORTS: {
        EMBED_ID: '20e415cb-6735-48c7-9eda-fd0714d1e94a',
        PORTAL_KEY: 'M0XvKi3I7y8pdEZPayz0jwDUhP1jhfzwuzoo2i43B4nInNMObETQ5Cder7AJnk1y'
      },
      ONE_SEASON_PER_SPORT: {
        EMBED_ID: 'bca7d6ec-253a-41a8-b275-8d6aae4aee0a',
        PORTAL_KEY: 'pMMztLgko3COHZ9OMQsLeuYzwazhtwOCfKehD2uibHwmXgB36rF3ROCJDNoWX5al'
      },
      SEPARATE_SEASONS: {
        EMBED_ID: '33d73438-ab96-40b2-baee-c250457c3879',
        PORTAL_KEY: 'jUJk2F0Qy6v8neXzP0UEn4hjBniPV9jJXP6ZQvmdhJELKX7Yvy7IiSKwNEn4Zc1J'
      }
    },
    uploadEvent: {
      EMBED_ID: '46c75419-7593-40c0-b060-69fd0f1f458f',
      PORTAL_KEY: 'Q0r803vRiGMtzJrs77TQmz74Yl9ZYgh9bWkquSoN1VDpRDZeJiyinGQp4pjr2EUZ'
    },
    uploadEditEvent: {
      EMBED_ID: '2ade3a0d-e27f-46f7-b4f3-31de30b45e16',
      PORTAL_KEY: 'j5v5i0Ohoorg5qsPbkUYlGj8mfJ51Qd2DE9dkED0CLwUmxhV9ez7J2UgSuyCuO7A'
    },
    uploadSeasonRenewalCampaign: {
      EMBED_ID: '1988541e-411c-49bb-9cf1-bc48895f7cae',
      PORTAL_KEY: 'VrhHGpILCQ5mGekoIJC0B5kP9Qiznii5JRWKPx7czdAZIGlnBxuTHynYKwYWvJnS'
    },
    uploadTournamentEvents: {
      EMBED_ID: '21a92ba3-8050-47e9-8ee0-fccce2ecf4b1',
      PORTAL_KEY: 'XxTZTz1Fmqw37NVigxMfisHYHblQeoTX0oXSVNbwef5kF00KoFEItr4OIBX85OWN'
    }
  },
  apiUnity: {
    URL: 'https://search-api.stage.nfhsnetwork.com/'
  },
  apiSponsorship: {
    URL: 'https://k8s-dashbirds-sponsorship-service-staging.playonsharedstage.playonsports.com'
  },
  launchDarklyConfig: {
    clientSideID: '65245bbf8d6522137071c4f0'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyAIuKnQOhoSRcUitM5cW3TA6xrMhDzu-4A',
    authDomain: 'huddle-tix-staging.firebaseapp.com',
    databaseURL: 'https://huddle-tix-staging.firebaseio.com',
    projectId: 'huddle-tix-staging',
    storageBucket: 'huddle-tix-staging.appspot.com',
    messagingSenderId: '122028499816'
  },
  IGNORE_HEALTH_CHECK_FOR_REDIS: false,
  HOSTNAME: 'https://staging.hq.gofan.co',
  GOFAN_URL: 'https://staging.gofan.co/',
  PAYMENT_CYCLE_LEARN_MORE_URL:
    'http://schoolsupport.gofan.co/hc/en-us/articles/4408034547223-When-do-we-get-paid-for-our-event-sales-',
  SEASON_PAYMENT_CYCLE_LEARN_MORE_URL:
    'https://gofanschool.zendesk.com/hc/en-us/articles/7430838218253-When-do-we-get-paid-for-our-event-sales-',
  TIMEOUT_WHEN_INACTIVE: 3600000,
  TIMEOUT_TO_START_IDLE: 30000,
  TIMEOUT_NOTIFICATION: 5000,
  ACCOUNT_CACHE_EXPIRES_TIME: 300,
  BETA_READINESS_MODE: true,
  MAX_UPLOADED_FILE_SIZE: 500e6,
  TIMEOUT_UPLOAD_FILE: 180000,
  DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST: 1000,
  JOBS_CHECKING_STATUS_INTERVAL: 2000,
  JOBS_CHECKING_REPORT_STATUS_INTERVAL: 5000,
  MOBILE_PASS_FORM_ID: 3041,
  HUDDLE_MICROSERVICE_API_MODE: true,
  TEMP_HIDE_PAYMENT_REPORT_FEATURE: false,
  TEMP_HIDE_EVENT_INSIGHT_FEATURE: false,
  RATE_ID1: 1,
  RATE_ID5: 5,
  RATE_ID8: 8,
  SEATS_IO_PUBLIC_WORKSPACE_KEY: 'c293b8a0-1272-4168-b630-47fb45db7711',
  GOOGLE_API_KEY: 'AIzaSyDsdPVvuHaiqPw_wYWvGIuLv6lkDSKEyYE',
  ZENDESK_TICKET: ZENDESK_SANDBOX,
  PLAY_ON_ENDPOINT: 'https://freeze-frame.playonsharedstage.playonsports.com',
  PLAYON_SITE_SETUP_GRAPHQL: 'https://kickoff.playonshareddev.playonsports.com/graphql'
};

const perf = {
  s3: {
    BASE_PATH: 'uploads/school',
    BASE_AVATAR_PATH: 'uploads/users/avatar',
    BASE_SPONSOR_PATH: 'uploads/sponsor',
    TEMPLATE_SEASON_PATH: 'uploads/templates/Schedule+Upload+Template.csv',
    NEW_TEMPLATE_SEASON_PATH: 'uploads/templates/Multi-Season+Schedule+Upload.csv',
    TEMPLATE_SEASON_ONE_PER_SPORT_PATH: 'uploads/templates/Multi-Season+Schedule+Upload+-+one+season+per+sport.csv',
    TEMPLATE_SEASON_SPERATE_EACH_TEAM_PATH:
      'uploads/templates/Multi-Season+Schedule+Upload+-+seperate+season+for+each+team.csv',
    TEMPLATE_ACCESS_CODE_EVENT_LEVEL: 'uploads/access_code/event-access-code-template.xlsx',
    TEMPLATE_ACCESS_CODE_TICKET_LEVEL: 'uploads/access_code/ticket-access-code-template.xlsx',
    TEMPLATE_PROMOTION_CODE_EVENT_LEVEL: 'uploads/promotion/event-promotion-code-template.xlsx',
    TEMPLATE_PROMOTION_CODE_TICKET_LEVEL: 'uploads/promotion/ticket-promotion-code-template.xlsx',
    TEMPLATE_SEASON_RENEWAL_CAMPAIGN: 'uploads/season-renewal/season-ticket-renewal-fan-template.csv',
    TEMPLATE_TOURNAMENT_EVENT: 'uploads/tournament/tournament-event-template.xlsx',
    BUCKET_PROMO_CODE: 'bulkuploadstaging',
    BUCKET_BULK_UPLOAD_EVENT: 'bulkupeventsloadstage',
    URL: 's3.amazonaws.com',
    BUCKET: 'perf-gofan-assets'
  },
  apiGateway: {
    URL: 'https://api.perf.gofan.co/'
  },
  apiGatewayMicroservices: {
    URL: 'https://apigateway.perf.gofan.co/orderservice'
  },
  apiProgramService: {
    URL: 'https://k8s-dashbirds-program-service-perf.playonsharedperf.playonsports.com/v1'
  },
  apiPartnerEvent: {
    URL: 'https://apigateway.perf.gofan.co/partnerIntegrations/'
  },
  apiFlatFile: {
    URL: 'https://dev.apigateway.gofan.co/flatfile/',
    FLATFILE_API_KEY: 'pk_50d4f6f127d94215a44643b9e00baf7c',
    FLATFILE_ENVIRONMENT_ID: 'us_env_6AzggOjT',
    ORG_INFO: {
      id: '34414',
      name: 'GoFan'
    },
    UPLOAD_SEASON: {
      MULTIPLE_SPORTS: {
        EMBED_ID: '20e415cb-6735-48c7-9eda-fd0714d1e94a',
        PORTAL_KEY: 'M0XvKi3I7y8pdEZPayz0jwDUhP1jhfzwuzoo2i43B4nInNMObETQ5Cder7AJnk1y'
      },
      ONE_SEASON_PER_SPORT: {
        EMBED_ID: 'bca7d6ec-253a-41a8-b275-8d6aae4aee0a',
        PORTAL_KEY: 'pMMztLgko3COHZ9OMQsLeuYzwazhtwOCfKehD2uibHwmXgB36rF3ROCJDNoWX5al'
      },
      SEPARATE_SEASONS: {
        EMBED_ID: '33d73438-ab96-40b2-baee-c250457c3879',
        PORTAL_KEY: 'jUJk2F0Qy6v8neXzP0UEn4hjBniPV9jJXP6ZQvmdhJELKX7Yvy7IiSKwNEn4Zc1J'
      }
    },
    uploadEvent: {
      EMBED_ID: '46c75419-7593-40c0-b060-69fd0f1f458f',
      PORTAL_KEY: 'Q0r803vRiGMtzJrs77TQmz74Yl9ZYgh9bWkquSoN1VDpRDZeJiyinGQp4pjr2EUZ'
    },
    uploadEditEvent: {
      EMBED_ID: '2ade3a0d-e27f-46f7-b4f3-31de30b45e16',
      PORTAL_KEY: 'j5v5i0Ohoorg5qsPbkUYlGj8mfJ51Qd2DE9dkED0CLwUmxhV9ez7J2UgSuyCuO7A'
    },
    uploadSeasonRenewalCampaign: {
      EMBED_ID: '1988541e-411c-49bb-9cf1-bc48895f7cae',
      PORTAL_KEY: 'VrhHGpILCQ5mGekoIJC0B5kP9Qiznii5JRWKPx7czdAZIGlnBxuTHynYKwYWvJnS'
    },
    uploadTournamentEvents: {
      EMBED_ID: '21a92ba3-8050-47e9-8ee0-fccce2ecf4b1',
      PORTAL_KEY: 'XxTZTz1Fmqw37NVigxMfisHYHblQeoTX0oXSVNbwef5kF00KoFEItr4OIBX85OWN'
    }
  },
  apiUnity: {
    URL: 'https://search-api.stage.nfhsnetwork.com/'
  },
  apiSponsorship: {
    URL: 'https://k8s-dashbirds-sponsorship-service-perf.playonsharedperf.playonsports.com'
  },
  launchDarklyConfig: {
    clientSideID: '652ebff6aecf6a1263cb1c13'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyAIuKnQOhoSRcUitM5cW3TA6xrMhDzu-4A',
    authDomain: 'huddle-tix-staging.firebaseapp.com',
    databaseURL: 'https://huddle-tix-staging.firebaseio.com',
    projectId: 'huddle-tix-staging',
    storageBucket: 'huddle-tix-staging.appspot.com',
    messagingSenderId: '122028499816'
  },
  IGNORE_HEALTH_CHECK_FOR_REDIS: false,
  HOSTNAME: 'https://hq.perf.gofan.co',
  GOFAN_URL: 'https://perf.gofan.co/',
  TIMEOUT_WHEN_INACTIVE: 3600000,
  TIMEOUT_TO_START_IDLE: 30000,
  TIMEOUT_NOTIFICATION: 5000,
  ACCOUNT_CACHE_EXPIRES_TIME: 300,
  BETA_READINESS_MODE: true,
  MAX_UPLOADED_FILE_SIZE: 500e6,
  TIMEOUT_UPLOAD_FILE: 180000,
  DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST: 1000,
  JOBS_CHECKING_STATUS_INTERVAL: 2000,
  JOBS_CHECKING_REPORT_STATUS_INTERVAL: 5000,
  MOBILE_PASS_FORM_ID: 3041,
  HUDDLE_MICROSERVICE_API_MODE: true,
  TEMP_HIDE_PAYMENT_REPORT_FEATURE: false,
  TEMP_HIDE_EVENT_INSIGHT_FEATURE: false,
  RATE_ID1: 1,
  RATE_ID5: 5,
  RATE_ID8: 8,
  SEATS_IO_PUBLIC_WORKSPACE_KEY: 'd498ec38-78ab-4258-acc6-75c678cbbd09',
  GOOGLE_API_KEY: 'AIzaSyDsdPVvuHaiqPw_wYWvGIuLv6lkDSKEyYE',
  ZENDESK_TICKET: ZENDESK_SANDBOX,
  PLAY_ON_ENDPOINT: 'https://freeze-frame.playonsharedperf.playonsports.com',
  PLAYON_SITE_SETUP_GRAPHQL: 'https://kickoff.playonshareddev.playonsports.com/graphql'
};

const beta = {
  s3: {
    BASE_PATH: 'uploads/school',
    BASE_AVATAR_PATH: 'uploads/users/avatar',
    BASE_SPONSOR_PATH: 'uploads/sponsor',
    TEMPLATE_SEASON_PATH: 'uploads/templates/Schedule+Upload+Template.csv',
    NEW_TEMPLATE_SEASON_PATH: 'uploads/templates/Multi-Season+Schedule+Upload.csv',
    TEMPLATE_SEASON_ONE_PER_SPORT_PATH: 'uploads/templates/Multi-Season+Schedule+Upload+-+one+season+per+sport.csv',
    TEMPLATE_SEASON_SPERATE_EACH_TEAM_PATH:
      'uploads/templates/Multi-Season+Schedule+Upload+-+seperate+season+for+each+team.csv',
    TEMPLATE_ACCESS_CODE_EVENT_LEVEL: 'uploads/access_code/event-access-code-template.xlsx',
    TEMPLATE_ACCESS_CODE_TICKET_LEVEL: 'uploads/access_code/ticket-access-code-template.xlsx',
    TEMPLATE_PROMOTION_CODE_EVENT_LEVEL: 'uploads/promotion/event-promotion-code-template.xlsx',
    TEMPLATE_PROMOTION_CODE_TICKET_LEVEL: 'uploads/promotion/ticket-promotion-code-template.xlsx',
    TEMPLATE_SEASON_RENEWAL_CAMPAIGN: 'uploads/season-renewal/season-ticket-renewal-fan-template.csv',
    TEMPLATE_TOURNAMENT_EVENT: 'uploads/tournament/tournament-event-template.xlsx',
    BUCKET_PROMO_CODE: 'bulkuploadaccesscodes',
    BUCKET_BULK_UPLOAD_EVENT: 'bulkupeventsloadprod',
    URL: 's3.amazonaws.com',
    BUCKET: 'production-gofan-assets'
  },
  apiGateway: {
    URL: 'https://api.gofan.co/'
  },
  apiGatewayMicroservices: {
    URL: 'https://apigateway.gofan.co/orderservice'
  },
  apiPartnerEvent: {
    URL: 'https://apigateway.gofan.co/partnerIntegrations/'
  },
  apiGatewayEventService: {
    URL: 'https://apigateway.gofan.co/eventservice'
  },
  apiProgramService: {
    URL: 'https://k8s-dashbirds-program-service-staging.playonsharedstage.playonsports.com/v1'
  },
  apiFlatFile: {
    URL: 'https://apigateway.gofan.co/flatfile/',
    FLATFILE_API_KEY: 'pk_cf4c9e169eb24cec928cf7b2da7ef9e0',
    FLATFILE_ENVIRONMENT_ID: 'us_env_xwgxa4Mz',
    ORG_INFO: {
      id: '34414',
      name: 'GoFan'
    },
    UPLOAD_SEASON: {
      MULTIPLE_SPORTS: {
        EMBED_ID: '9b9c8251-0d12-41cc-8253-63f67dfdc9ce',
        PORTAL_KEY: 'QWiFMfojY2YKext474DrHIl2CnpVqdmG88noAxzkzmaIQwXv6GMSHYF4w5hDXdiz'
      },
      ONE_SEASON_PER_SPORT: {
        EMBED_ID: '29513754-1d8c-41b9-9e0c-3be71666c48b',
        PORTAL_KEY: 'myCGizI64pmpuYiL6MRNGefbkc9ic3iYFpwiz4eWAUq4Xn8mGfc34jhLaqWav81k'
      },
      SEPARATE_SEASONS: {
        EMBED_ID: '441a0a3f-c1c3-4ee7-abaa-249fa0787ade',
        PORTAL_KEY: 'mn6Vyg7z6lA7DBpO8y6ieF9kfaqP2y0SmQyI2wfNfCeSP306dRmbOdcbo9rukUBc'
      }
    },
    uploadEvent: {
      EMBED_ID: '633f785f-59e4-4216-9010-d969155410c4',
      PORTAL_KEY: 'Or57jtInh37l8KQR8PaV9xCVKxPXDZql5w6odZSMaLlj5IULweZXQtxh2ShEDgpa'
    },
    uploadEditEvent: {
      EMBED_ID: '72dbbcfd-cfe6-41c7-8f65-c6730ac2f498',
      PORTAL_KEY: 'VppOZ1tYPsgeqo5hYzMzYJvd6B4oT4v3cElxdP5Ns2W2V9maQ2eLNGRxSekpCEbA'
    },
    uploadSeasonRenewalCampaign: {
      EMBED_ID: '9cf87ef4-c485-4919-8595-c630f2a8421d',
      PORTAL_KEY: 'FXxo1W1atJoLYcoX3ZLLm4j8bZcG8T68fFL8WBycZjXUDUrrvb4T4ebL5RbsBn0w'
    },
    uploadTournamentEvents: {
      EMBED_ID: '1bc7d7b8-6f3e-4e50-9d08-77e8aedd0e46',
      PORTAL_KEY: 'VjeMWiEvkLc6rTAnkNU7MTkoonGw4hNfw5D9ZFUIOHBngNCQ0hoo1lYlZe1Hk0DI'
    }
  },
  apiUnity: {
    URL: 'https://search-api.nfhsnetwork.com/'
  },
  apiSponsorship: {
    URL: 'https://k8s-dashbirds-sponsorship-service-prod.playonsharedprod.playonsports.com'
  },
  launchDarklyConfig: {
    clientSideID: '652ebfe6103781127f626709'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyA5mVUHRuey0bEAw9dHRtVBWNqIjgvoJCw',
    authDomain: 'gofan.firebaseapp.com',
    databaseURL: 'https://gofan.firebaseio.com',
    projectId: 'firebase-gofan',
    storageBucket: 'firebase-gofan.appspot.com',
    messagingSenderId: '73036130056'
  },
  IGNORE_HEALTH_CHECK_FOR_REDIS: false,
  HOSTNAME: 'https://beta.hq.gofan.co',
  GOFAN_URL: 'https://gofan.co/',
  PAYMENT_CYCLE_LEARN_MORE_URL:
    'http://schoolsupport.gofan.co/hc/en-us/articles/4408034547223-When-do-we-get-paid-for-our-event-sales-',
  SEASON_PAYMENT_CYCLE_LEARN_MORE_URL:
    'https://gofanschool.zendesk.com/hc/en-us/articles/7430838218253-When-do-we-get-paid-for-our-event-sales-',
  TIMEOUT_WHEN_INACTIVE: 3600000,
  TIMEOUT_TO_START_IDLE: 30000,
  TIMEOUT_NOTIFICATION: 5000,
  ACCOUNT_CACHE_EXPIRES_TIME: 300,
  BETA_READINESS_MODE: true,
  MAX_UPLOADED_FILE_SIZE: 500e6,
  TIMEOUT_UPLOAD_FILE: 180000,
  DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST: 1000,
  JOBS_CHECKING_STATUS_INTERVAL: 2000,
  JOBS_CHECKING_REPORT_STATUS_INTERVAL: 5000,
  MOBILE_PASS_FORM_ID: 3041,
  HUDDLE_MICROSERVICE_API_MODE: true,
  TEMP_HIDE_PAYMENT_REPORT_FEATURE: false,
  TEMP_HIDE_EVENT_INSIGHT_FEATURE: false,
  RATE_ID1: 1,
  RATE_ID5: 5,
  RATE_ID8: 8,
  SEATS_IO_PUBLIC_WORKSPACE_KEY: '743f583e-e179-45c8-a17b-09341f0420ac',
  GOOGLE_API_KEY: 'AIzaSyDsdPVvuHaiqPw_wYWvGIuLv6lkDSKEyYE',
  ZENDESK_TICKET: ZENDESK_SANDBOX,
  PLAY_ON_ENDPOINT: 'https://freeze-frame.playonsharedprod.playonsports.com',
  PLAYON_SITE_SETUP_GRAPHQL: 'https://kickoff.playonshareddev.playonsports.com/graphql'
};

const prod = {
  s3: {
    BASE_PATH: 'uploads/school',
    BASE_AVATAR_PATH: 'uploads/users/avatar',
    BASE_SPONSOR_PATH: 'uploads/sponsor',
    TEMPLATE_SEASON_PATH: 'uploads/templates/Schedule+Upload+Template.csv',
    NEW_TEMPLATE_SEASON_PATH: 'uploads/templates/Multi-Season+Schedule+Upload.csv',
    TEMPLATE_SEASON_ONE_PER_SPORT_PATH: 'uploads/templates/Multi-Season+Schedule+Upload+-+one+season+per+sport.csv',
    TEMPLATE_SEASON_SPERATE_EACH_TEAM_PATH:
      'uploads/templates/Multi-Season+Schedule+Upload+-+seperate+season+for+each+team.csv',
    TEMPLATE_ACCESS_CODE_EVENT_LEVEL: 'uploads/access_code/event-access-code-template.xlsx',
    TEMPLATE_ACCESS_CODE_TICKET_LEVEL: 'uploads/access_code/ticket-access-code-template.xlsx',
    TEMPLATE_PROMOTION_CODE_EVENT_LEVEL: 'uploads/promotion/event-promotion-code-template.xlsx',
    TEMPLATE_PROMOTION_CODE_TICKET_LEVEL: 'uploads/promotion/ticket-promotion-code-template.xlsx',
    TEMPLATE_SEASON_RENEWAL_CAMPAIGN: 'uploads/season-renewal/season-ticket-renewal-fan-template.csv',
    TEMPLATE_TOURNAMENT_EVENT: 'uploads/tournament/tournament-event-template.xlsx',
    BUCKET_PROMO_CODE: 'bulkuploadaccesscodes',
    BUCKET_BULK_UPLOAD_EVENT: 'bulkupeventsloadprod',
    URL: 's3.amazonaws.com',
    BUCKET: 'production-gofan-assets'
  },
  apiGateway: {
    URL: 'https://api.gofan.co/'
  },
  apiGatewayMicroservices: {
    URL: 'https://apigateway.gofan.co/orderservice'
  },
  apiProgramService: {
    URL: 'https://k8s-dashbirds-program-service-prod.playonsharedprod.playonsports.com/v1'
  },
  apiPartnerEvent: {
    URL: 'https://apigateway.gofan.co/partnerIntegrations/'
  },
  apiGatewayEventService: {
    URL: 'https://apigateway.gofan.co/eventservice'
  },
  apiFlatFile: {
    URL: 'https://apigateway.gofan.co/flatfile/',
    FLATFILE_API_KEY: 'pk_cf4c9e169eb24cec928cf7b2da7ef9e0',
    FLATFILE_ENVIRONMENT_ID: 'us_env_xwgxa4Mz',
    ORG_INFO: {
      id: '34414',
      name: 'GoFan'
    },
    UPLOAD_SEASON: {
      MULTIPLE_SPORTS: {
        EMBED_ID: '9b9c8251-0d12-41cc-8253-63f67dfdc9ce',
        PORTAL_KEY: 'QWiFMfojY2YKext474DrHIl2CnpVqdmG88noAxzkzmaIQwXv6GMSHYF4w5hDXdiz'
      },
      ONE_SEASON_PER_SPORT: {
        EMBED_ID: '29513754-1d8c-41b9-9e0c-3be71666c48b',
        PORTAL_KEY: 'myCGizI64pmpuYiL6MRNGefbkc9ic3iYFpwiz4eWAUq4Xn8mGfc34jhLaqWav81k'
      },
      SEPARATE_SEASONS: {
        EMBED_ID: '441a0a3f-c1c3-4ee7-abaa-249fa0787ade',
        PORTAL_KEY: 'mn6Vyg7z6lA7DBpO8y6ieF9kfaqP2y0SmQyI2wfNfCeSP306dRmbOdcbo9rukUBc'
      }
    },
    uploadEvent: {
      EMBED_ID: '633f785f-59e4-4216-9010-d969155410c4',
      PORTAL_KEY: 'Or57jtInh37l8KQR8PaV9xCVKxPXDZql5w6odZSMaLlj5IULweZXQtxh2ShEDgpa'
    },
    uploadEditEvent: {
      EMBED_ID: '72dbbcfd-cfe6-41c7-8f65-c6730ac2f498',
      PORTAL_KEY: 'VppOZ1tYPsgeqo5hYzMzYJvd6B4oT4v3cElxdP5Ns2W2V9maQ2eLNGRxSekpCEbA'
    },
    uploadSeasonRenewalCampaign: {
      EMBED_ID: '9cf87ef4-c485-4919-8595-c630f2a8421d',
      PORTAL_KEY: 'FXxo1W1atJoLYcoX3ZLLm4j8bZcG8T68fFL8WBycZjXUDUrrvb4T4ebL5RbsBn0w'
    },
    uploadTournamentEvents: {
      EMBED_ID: '1bc7d7b8-6f3e-4e50-9d08-77e8aedd0e46',
      PORTAL_KEY: 'VjeMWiEvkLc6rTAnkNU7MTkoonGw4hNfw5D9ZFUIOHBngNCQ0hoo1lYlZe1Hk0DI'
    }
  },
  apiUnity: {
    URL: 'https://search-api.nfhsnetwork.com/'
  },
  apiSponsorship: {
    URL: 'https://k8s-dashbirds-sponsorship-service-prod.playonsharedprod.playonsports.com'
  },
  launchDarklyConfig: {
    clientSideID: '65245b3a4131f213001df1e1'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyA5mVUHRuey0bEAw9dHRtVBWNqIjgvoJCw',
    authDomain: 'gofan.firebaseapp.com',
    databaseURL: 'https://gofan.firebaseio.com',
    projectId: 'firebase-gofan',
    storageBucket: 'firebase-gofan.appspot.com',
    messagingSenderId: '73036130056'
  },
  IGNORE_HEALTH_CHECK_FOR_REDIS: false,
  HOSTNAME: 'https://hq.gofan.co',
  GOFAN_URL: 'https://gofan.co/',
  PAYMENT_CYCLE_LEARN_MORE_URL:
    'http://schoolsupport.gofan.co/hc/en-us/articles/4408034547223-When-do-we-get-paid-for-our-event-sales-',
  SEASON_PAYMENT_CYCLE_LEARN_MORE_URL:
    'https://gofanschool.zendesk.com/hc/en-us/articles/7430838218253-When-do-we-get-paid-for-our-event-sales-',
  TIMEOUT_WHEN_INACTIVE: 3600000,
  TIMEOUT_TO_START_IDLE: 30000,
  TIMEOUT_NOTIFICATION: 5000,
  ACCOUNT_CACHE_EXPIRES_TIME: 300,
  BETA_READINESS_MODE: true,
  MAX_UPLOADED_FILE_SIZE: 500e6,
  TIMEOUT_UPLOAD_FILE: 180000,
  DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST: 1000,
  JOBS_CHECKING_STATUS_INTERVAL: 2000,
  JOBS_CHECKING_REPORT_STATUS_INTERVAL: 5000,
  MOBILE_PASS_FORM_ID: 3041,
  HUDDLE_MICROSERVICE_API_MODE: true,
  TEMP_HIDE_PAYMENT_REPORT_FEATURE: false,
  TEMP_HIDE_EVENT_INSIGHT_FEATURE: false,
  RATE_ID1: 1,
  RATE_ID5: 5,
  RATE_ID8: 8,
  SEATS_IO_PUBLIC_WORKSPACE_KEY: '743f583e-e179-45c8-a17b-09341f0420ac',
  GOOGLE_API_KEY: 'AIzaSyDsdPVvuHaiqPw_wYWvGIuLv6lkDSKEyYE',
  ZENDESK_TICKET: ZENDESK_PRODUCTION,
  PLAY_ON_ENDPOINT: 'https://freeze-frame.playonsharedprod.playonsports.com',
  PLAYON_SITE_SETUP_GRAPHQL: 'https://kickoff.playonshareddev.playonsports.com/graphql'
};

// -------------------------------------------------------------------- //
// Read window hostname to determine environment
// -------------------------------------------------------------------- //
export const getConfig = () => {
  let hostname;

  if (typeof window !== 'undefined') hostname = window && window.location && window.location.hostname;
  else if (typeof process.env.NEXT_PUBLIC_API_ENV === 'string') hostname = process.env.NEXT_PUBLIC_API_ENV;

  if (/^local/.test(hostname)) {
    return stage;
  }
  if (/(dev1)/.test(hostname)) {
    return stage;
  }
  if (/(dev2)/.test(hostname)) {
    return dev2;
  }
  if (/(dev3)/.test(hostname)) {
    return dev3;
  }
  if (/(dev4)/.test(hostname)) {
    return dev4;
  }
  if (/(dev5)/.test(hostname)) {
    return dev;
  }
  if (/^development/.test(hostname) || /(dev)/.test(hostname)) {
    return dev;
  }
  if (/(qa2)/.test(hostname)) {
    return qa2;
  }
  if (/(qa3)/.test(hostname)) {
    return qa3;
  }
  if (/(qa4)/.test(hostname)) {
    return qa4;
  }
  if (/(qa5)/.test(hostname)) {
    return qa;
  }
  if (/(qa)/.test(hostname)) {
    return qa;
  }
  if (/^perf/.test(hostname)) {
    return perf;
  }
  if (/^stag/.test(hostname)) {
    return stage;
  }
  if (/^prod/.test(hostname)) {
    return prod;
  }
  if (/^beta/.test(hostname)) {
    return beta;
  }
  if (/^hq/.test(hostname) || /(prod)/.test(hostname)) {
    return prod;
  }
  return prod;
};

export const config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...getConfig()
};
