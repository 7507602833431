import { DEFAULT_SORT_OPTION } from '../EventInformationV2/components/TicketsLayout/constants';

export const SEASON_PROCESS_STATUS = {
  SCHEDULED_PROCESS: 0,
  EVENT_CREATED_PROCESS: 1,
  EVENT_RESOLVE_DUPLICATE: 2,
  EVENT_DETAILS_PROCESS: 3,
  REVIEW_PROCESS: 4
};

export const MIN_CHARACTERS = 3;

export const SEASON_PROCESS_MAPPING = {
  0: 0,
  1: 1,
  2: 1,
  3: 2,
  4: 2
};

export const SEASON_PROCESS_DATA = [
  {
    label: 'Schedule setup',
    description: 'Schedule setup',
    secondaryLabel: '',
    current: true
  },
  {
    label: 'Add events',
    description: 'Add events',
    secondaryLabel: ''
  },
  {
    label: 'Settings / publish',
    description: 'Settings / publish',
    secondaryLabel: ''
  }
];

export const LIST_VENUE_STATE = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
  'DC',
  'AS',
  'GU',
  'MP',
  'PR',
  'VI'
];

export const CREATE_SEASON_TYPES = {
  DOWNLOAD_AND_USE_TEMPLATE: 'download-and-use-template',
  ENTER_MANUALLY: 'enter-manually',
  CREATE_FROM_ARBITER: 'create-from-arbiter'
};

export const SEASON_ELEMENT_STATES = {
  valid: false,
  value: '',
  savedValue: '',
  error: '',
  dirty: false,
  touched: false,
  focused: false,
  editable: true
};

export const SEASON_MESSAGES = {
  REQUIRED_FIELDS: {
    SPORT: {
      EMPTY: 'Select the sport is required.'
    },
    GENDER: {
      EMPTY: 'Gender is required.'
    },
    LEVEL: {
      EMPTY: 'Level is required.'
    },
    ROLE: {
      EMPTY: 'Role is required.'
    },
    SEASON_DATE: {
      EMPTY: 'Season date is required.',
      INVALID: 'Enter a valid season date.',
      AFTER_END_DATE: 'Season date must come before End date.'
    },
    SEASON_TIME: {
      EMPTY: 'Season time is required.',
      INVALID: 'Enter a valid event time.',
      AFTER_END_TIME: 'Event time must come before End time.'
    },
    START_DATE: {
      EMPTY: 'Event date is required.',
      INVALID: 'Enter a valid event date.'
    },
    START_TIME: {
      EMPTY: 'Start time is required.',
      INVALID: 'Enter a valid start time.'
    },
    END_DATE: {
      EMPTY: 'End date is required.',
      INVALID: 'Enter a valid end date.',
      BEFORE_EVENT_DATE: 'End date must come after Event date.'
    },
    END_TIME: {
      EMPTY: 'End time is required.',
      INVALID: 'Enter a valid end time.',
      BEFORE_EVENT_TIME: 'End time must come after Event time.'
    },
    SEASON_NAME: {
      EMPTY: 'Season name is required.',
      SPECIAL_CHARACTERS: `This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (\`()./_-#'@&*+",:;?!) in between 3-100 characters long.`
    },
    HOURS: {
      EMPTY: 'Hours before event starts is required.',
      INVALID: 'Enter a valid number.'
    },
    VENUE: {
      NAME: {
        EMPTY: 'Name is required.'
      },
      CITY: {
        EMPTY: 'City is required.'
      },
      ADDRESS: {
        EMPTY: 'Address is required.'
      },
      STATE: {
        EMPTY: 'State is required.'
      },
      ZIP_CODE: {
        EMPTY: 'ZipCode is required.',
        INVALID: 'Enter a valid zip code.'
      }
    },
    SCHOOLS: {
      HOME_AND_FINANCIAL_MISSING: 'Home school and financial school are required to continue.'
    }
  },
  START_DATE_TIME: {
    EVENT_HAS_SOLD: {
      TITLE: 'Confirm change of event date/time',
      CONTENT:
        'Changing your event’s date/time will update all tickets already purchased for the event. Additionally, any reports you have already received will reflect the old date. Are you sure you want to change this event’s date/time?'
    },
    EVENT_IS_END_DATE: {
      TITLE: 'Confirm change of event date/time',
      CONTENT:
        'By changing your event’s date/time, any report you have already received will reflect the old date. Are you sure you want to change this event’s date/time?'
    }
  }
};

export default {
  SEASON_PROCESS_STATUS,
  SEASON_PROCESS_DATA,
  SEASON_ELEMENT_STATES,
  SEASON_MESSAGES
};

export const TIME_ELEMENTS = {
  value: '',
  touched: false,
  valid: false,
  error: '',
  hour: 0,
  minute: 0,
  type: 'AM',
  focused: false
};

export const VENUE_NAME_LIMIT_VALIDATION = {
  MIN: 3,
  MAX: 50
};

export const SCHOOL_CREATED_TYPES = {
  IN_SYSTEM: 'in_system',
  OUT_SYSTEM: 'out_system'
};

export const VENUE_CITY_MAX = 20;

export const BUYER_PURCHASE_LIMIT_VALIDATION = {
  MIN: 1,
  MAX: 150
};

export const DEFAULT_BUYER_PURCHASE_LIMIT = 30;

export const ERROR_MESSAGE = {
  OPPONENT_REQUIRED: 'Opponent is required',
  SCHOOL_REQUIRED: 'School is required',
  SPECIAL_CHARACTERS: `This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (\`()./_-#'@&*+",:;?!) in between 3-50 characters long.`,
  CITY_REQUIRED: 'City is required',
  STATE_REQUIRED: 'State is required',
  EVENT_DATE_REQUIRED: 'Event date is required.',
  EVENT_DATE_INVALID: 'Enter a valid event date.',
  START_TIME_REQUIRED: 'Start time is required.',
  START_TIME_INVALID: 'Enter a valid start time.'
};

export const EVENT_TEMPLATE = {
  id: new Date().getTime(),
  school: {
    ...SEASON_ELEMENT_STATES,
    value: undefined,
    valid: false
  },
  venueName: {
    ...SEASON_ELEMENT_STATES,
    value: '',
    valid: false
  },
  venueCity: {
    ...SEASON_ELEMENT_STATES,
    value: '',
    valid: false
  },
  venueState: {
    ...SEASON_ELEMENT_STATES,
    value: '',
    valid: false
  },
  venueLocation: {
    ...SEASON_ELEMENT_STATES,
    value: '',
    valid: false
  },
  venueAddress: {
    ...SEASON_ELEMENT_STATES,
    value: '',
    valid: false
  },
  venueZip: {
    ...SEASON_ELEMENT_STATES,
    value: '',
    valid: false
  },
  eventDate: {
    ...SEASON_ELEMENT_STATES,
    value: undefined,
    valid: false
  },
  startTime: {
    ...TIME_ELEMENTS,
    value: '07:00',
    valid: true,
    hour: 7,
    minute: 0,
    second: 1,
    type: 'PM'
  },
  showAddSchool: false,
  showSearchSchool: true,
  manual: false,
  selected: false,
  enableDatePicker: false,
  eventType: {
    value: '',
    valid: true
  },
  eventName: {
    value: '',
    valid: true
  },
  allDayEvent: {
    value: false,
    valid: true
  },
  eventVisibility: {
    archived: {
      value: false,
      valid: true
    },
    publishDate: {
      ...SEASON_ELEMENT_STATES,
      value: '',
      valid: true
    },
    publishTime: {
      ...TIME_ELEMENTS,
      value: '',
      valid: true
    }
  },
  gateOpeningTime: {
    ticketValidBefore: {
      value: 4,
      valid: true
    },
    ticketValidAnytime: {
      value: false,
      valid: true
    }
  },
  eventAlert: {
    value: '',
    valid: true
  },
  ticketAvailability: {
    value: [],
    valid: true
  },
  buyerPurchaseLimit: {
    value: DEFAULT_BUYER_PURCHASE_LIMIT,
    valid: true
  },
  eventDateAndTime: {
    value: '',
    valid: true
  },
  additionalForm: {
    savedValue: [],
    value: []
  },
  schoolType: SCHOOL_CREATED_TYPES.IN_SYSTEM,
  valid: false,
  showNotifyTime: false
};
export const FILE_EXTENSIONS = ['csv', 'xls', 'xlsx'];
export const TIME_HIDE_BUILD_SEASON_MODAL = 3000;

export const EVENT_CREATED_TEMPLATE = {
  addToSchoolSection: {
    data: {
      ...EVENT_TEMPLATE
    },
    section: {
      ...EVENT_TEMPLATE
    }
  },
  eventsSection: {
    data: [],
    section: [],
    valid: true,
    numberOfEvents: 1
  },
  settings: {
    isApplySettingTime: false,
    isShowNotifyApplyTime: false,
    startTime: {
      ...EVENT_TEMPLATE.startTime
    }
  }
};

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc'
};

export const DATE_PICKER_STRINGS = {
  AM: 'AM',
  PM: 'PM'
};

export const SEARCH_PARAMS = {
  pageSize: 100,
  pageIndex: 0,
  accountId: '',
  sortBy: [DEFAULT_SORT_OPTION],
  expands: ['product-type', 'levels', 'activity']
};

export const REGX_TIME_WITHOUT_COLON = /^[0-9]{3,4}$/;
export const REGX_TIME_WITH_COLON = /^[0-9]{1,2}:[0-9]{1,2}$/;

export const DATE_PICKER_ERRORS_STRINGS = {
  EMPTY: 'EMPTY',
  INCORRET_FORMAT: 'INCORRET_FORMAT',
  OUT_OF_RANGE: 'OUT_OF_RANGE'
};

export const PENDO_SEASON_PAGE_NOTES = {
  SEASON_SETTINGS_PAGE: 'page=season-settings',
  ADD_SEASON_EVENTS_PAGE: 'page=add-season-events',
  EVENT_INFO_PAGE: 'page=event-info'
};
export const IMPORT_EVENTS_TYPES = {
  ARBITER: 'ARBITER',
  MANUAL: 'MANUAL',
  UPLOAD: 'UPLOAD'
};

export const RESOLVE_DUPLICATED_TYPES = {
  KEPT_BOTH: 'KEPT_BOTH',
  KEPT_BOTH_GF_EVENT: 'KEPT_BOTH_GF_EVENT',
  KEPT_BOTH_PARTNER_EVENT: 'KEPT_BOTH_PARTNER_EVENT',
  ONLY_KEPT_GF_EVENT: 'ONLY_KEPT_GF_EVENT',
  ONLY_USED_PARTNER_EVENT: 'ONLY_USED_PARTNER_EVENT',
  USED_EVENT: 'USED_EVENT',
  KEEP_EVENT: 'KEEP_EVENT'
};

export const GENDER_REFER = {
  boy: 'Boys',
  girl: 'Girls',
  either: 'Coed'
};

export const EVENT_NAME_NONE = 'none';
