import isEmpty from 'lodash/isEmpty';

import { AppUtils } from '@gofan/utils';

import defaultSchoolIcon from '@assets/images/gofan-icon.png';

import type { AccountDTO } from '@gofan/api';

import './EditorTitle.scss';

interface EditorTitleProps {
  account?: AccountDTO;
  title: string;
}

export const EditorTitle = ({ account, title }: EditorTitleProps) => {
  const accountIcon = isEmpty(account?.logo)
    ? defaultSchoolIcon
    : AppUtils.getSchoolImage(account?.id as string, 'logo', account?.logo as string);

  return (
    <div className='editor-title'>
      <img className='gs--margin-right__sp5' alt='Account Icon' src={accountIcon} />

      <div className='gs--productive-heading-05-semibold gs--font-family-sf'>{title}</div>
    </div>
  );
};
