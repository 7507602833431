/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { Button, ComposedModal, ModalHeader, ModalBody, ModalFooter } from 'carbon-components-react';
import { StyledSubmitButton, StyledInlineLoading } from './styles';

const DetailModal = styled.div``;

export default ({
  open,
  modalHeaderLabel,
  modalHeaderTitle,
  description,
  onRequestSubmit,
  onRequestClose,
  submitLabel,
  closeLabel,
  deleteLabel = null,
  isLoading = false,
  primaryDisabled = false
}) => (
  <ComposedModal
    open={open}
    onClose={() => {
      onRequestClose();
    }}
  >
    <ModalHeader label={modalHeaderLabel} title={modalHeaderTitle} />
    <ModalBody>
      <DetailModal className='gs--body-long-01 gs--text-01'>
        {description && Array.isArray(description)
          ? description.map(item => <p style={{ margin: '4px 0' }}>{item}</p>)
          : description}
      </DetailModal>
    </ModalBody>
    <ModalFooter>
      {closeLabel && (
        <Button className='bx--btn--secondary' onClick={onRequestClose}>
          {closeLabel}
        </Button>
      )}
      <StyledSubmitButton
        disabled={primaryDisabled}
        className={deleteLabel ? 'bx--btn--danger' : 'bx--btn--primary'}
        closeLabel={closeLabel}
        onClick={onRequestSubmit}
      >
        {isLoading ? (
          <StyledInlineLoading status='active' description={deleteLabel ?? submitLabel} />
        ) : (
          deleteLabel ?? submitLabel
        )}
      </StyledSubmitButton>
    </ModalFooter>
  </ComposedModal>
);
