import React from 'react';
import { Document, Page, Text, Image, View, StyleSheet } from '@react-pdf/renderer';
import { GoFanLogo } from './pdf-template-en.component';

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    color: '#323232'
  },
  headerSection: {
    display: 'flex'
  },
  sectionBorderTop: {
    borderTop: '2pt solid #000000'
  },
  schoolImage: {
    width: 'auto',
    height: 89,
    alignSelf: 'center',
    marginTop: 25,
    marginBottom: 15
  },
  qrCodeImage: {
    width: 175,
    height: 175,
    alignSelf: 'center',
    marginTop: 20,
    marginBottom: 25
  },
  buyTickets: {
    fontFamily: 'Rubik',
    fontWeight: 'bold',
    fontSize: 46,
    letterSpacing: 2,
    alignSelf: 'center',
    lineHeight: 0.9,
    marginTop: 20
  },
  online: {
    fontFamily: 'Rubik',
    fontSize: 109,
    letterSpacing: 16,
    alignSelf: 'center',
    fontWeight: 'heavy',
    lineHeight: 1,
    marginBottom: 20
  },
  middleTextTop: {
    fontFamily: 'Rubik',
    marginTop: 21,
    fontSize: 18,
    alignSelf: 'center',
    lineHeight: 1.15,
    letterSpacing: 2
  },
  middleText: {
    fontFamily: 'Rubik',
    fontSize: 18,
    alignSelf: 'center',
    lineHeight: 1.25,
    letterSpacing: 2
  },
  middleTextBottom: {
    fontFamily: 'Rubik',
    fontSize: 18,
    alignSelf: 'center',
    lineHeight: 1.25,
    maxWidth: 400,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  textBold: {
    fontWeight: 'heavy'
  },
  logo: {
    width: 262,
    alignSelf: 'center',
    marginTop: 16
  }
});

interface PDFTemplateEnComponentProps {
  qrCode: string;
  schoolLogo: string;
  schoolName?: string;
  eventName?: string;
  eventStartDateTime?: string;
  isFundraiser?: boolean;
}

const PDFTemplateEsComponent = ({
  qrCode = '',
  schoolLogo = '',
  schoolName,
  eventName = '',
  eventStartDateTime = '',
  isFundraiser = false
}: PDFTemplateEnComponentProps) => (
  <Document>
    <Page size='A4' style={styles.page}>
      <View style={styles.headerSection}>
        <Image style={styles.schoolImage} src={schoolLogo} />
      </View>
      <View style={styles.sectionBorderTop}>
        <Text style={styles.buyTickets}>{isFundraiser ? 'DONAR' : 'COMPRA TUS ENTRADAS'}</Text>
        <Text style={styles.online}>ONLINE</Text>
      </View>
      <View style={styles.sectionBorderTop}>
        <Text style={styles.middleTextTop}>USA EL CÓDIGO QR ABAJO, O</Text>
        <Text style={styles.middleText}>VISITA GOFAN.CO Y BUSCA</Text>
        {schoolName ? <Text style={styles.middleTextBottom}>{schoolName}</Text> : <Text>&nbsp;</Text>}
        <Image src={qrCode} style={styles.qrCodeImage} />
        {eventName ? <Text style={styles.middleTextBottom}>{eventName}</Text> : <Text>&nbsp;</Text>}
        {eventStartDateTime ? <Text style={styles.middleTextBottom}>{eventStartDateTime}</Text> : <Text>&nbsp;</Text>}
        <GoFanLogo />
      </View>
    </Page>
  </Document>
);

export default PDFTemplateEsComponent;
