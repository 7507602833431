import moment from 'moment';
import { DATE_PICKER_FORMAT } from '@utils/dateUtils';

export const EVENT_PROCESS_STATUS = {
  REQUIRED_PROCESS: 0,
  OPTIONAL_PROCESS: 1,
  TICKETS_PROCESS: 2,
  REVIEW_PROCESS: 3
};

export const MAPPING_LABEL_ADDITIONAL_FIELD = {
  'first-name': 'First Name',
  'last-name': 'Last Name',
  'phone-number': 'Phone Number',
  'student-id': 'Student ID',
  'date-first-name': 'Date First Name',
  'date-last-name': 'Date Last Name',
  'date-student-id': 'Date Student ID',
  email: 'Email'
};

export const EVENT_PROCESS_DATA = [
  {
    label: 'Required',
    description: 'Required',
    secondaryLabel: '',
    current: true
  },
  {
    label: 'Optional',
    description: 'Optional',
    secondaryLabel: ''
  },
  {
    label: 'Tickets',
    description: 'Tickets',
    secondaryLabel: ''
  },
  {
    label: 'Review / Add',
    description: 'Review / Add',
    secondaryLabel: ''
  }
];

export const PASS_PROCESS_DATA = [
  {
    label: 'Required',
    description: 'Required',
    secondaryLabel: '',
    current: true
  },
  {
    label: 'Optional',
    description: 'Optional',
    secondaryLabel: ''
  },
  {
    label: 'Passes',
    description: 'Passes',
    secondaryLabel: ''
  },
  {
    label: 'Review / Add',
    description: 'Review / Add',
    secondaryLabel: ''
  }
];

const OPTIONAL_MESSAGES = {
  REQUIRED_FIELDS: {
    PUBLISH_DATE: {
      EMPTY: 'Publish date is required.',
      INVALID: 'Enter a valid publish date.'
    },
    PUBLISH_TIME: {
      EMPTY: 'Publish time is required.',
      INVALID: 'Enter a valid publish time.'
    },
    START_DATE: {
      EMPTY: 'Start date is required.',
      INVALID: 'Enter a valid start date.',
      RULE: 'Start date must come before End date.'
    },
    START_TIME: {
      EMPTY: 'Start time is required.',
      INVALID: 'Enter a valid start time.',
      RULE: 'Start time must come before End time'
    },
    END_DATE: {
      EMPTY: 'End date is required.',
      INVALID: 'End a valid start date.',
      RULE: 'End date must come before End date.'
    },
    END_TIME: {
      EMPTY: 'End time is required.',
      INVALID: 'Enter a valid End time.',
      RULE: 'End time must come after Start time'
    }
  }
};

const OPTIONAL_SECTIONS = {
  SECTION_EVENT_VISABILITY: {
    SECTION_TITLE: 'Event visability',
    LABELS: {
      HIDE_THIS_EVENT: 'Hide this event from schools GoFan page',
      AUTOMATICALLY_PUBLISH: `Automatically publish this event to the school's GoFan page on:`,
      PUBLISH_DATE: 'Publish date'
    }
  },
  SECTION_EVENT_ON_SALE: {
    SECTION_TITLE: 'Event on sale',
    LABELS: {
      TICKETS_GO_ON_SALE: 'Tickets go on sale immediately when this event is added',
      TICKETS_SALE_BEGIN: 'Ticket sales begin:',
      START_DATE: 'Start date',
      START_TIME: 'Start time',
      SET_END_TIME: 'Set an end date and time',
      END_DATE: 'End date',
      END_TIME: 'End time'
    }
  },
  SECTION_EVENT_ALERT: {
    SECTION_TITLE: 'Event alert',
    LABELS: {
      ADD_AN_ALERT: 'Add an alert or special information to the top of your event page',
      ALERT_CONTENT: 'Alert content',
      ALERT_CONTENT_PLACEHOLDER: 'Example: Important COVID-19 guidelines for this game'
    }
  },
  SECTION_CUSTOM_DESCRIPTIONS: {
    SECTION_TITLE: 'Custom descriptions',
    LABELS: {
      CREATE_A_THEME: 'Create a theme for this event',
      EVENT_THEME: 'Event theme',
      EVENT_THEME_PLACEHOLDER: 'Example: Homecoming, Senior Night',
      ADD_CUSTOM_NAME: 'Add a custom name for your sport',
      CUSTOME_SPORT_NAME: 'Custom sport name',
      CUSTOME_SPORT_NAME_PLACEHOLDER: 'Example: 3A Football',
      ADD_DESCRIPTION: 'Add a description for this event',
      EVENT_DESCRIPTION: 'Add a description for this event',
      EVENT_DESCRIPTION_PLACEHOLDER: 'Example: 3A Football'
    }
  },
  SECTION_PIN_TO_TOP: {
    SECTION_TITLE: 'Pin to top',
    LABELS: {
      PIN_THIS_EVENT: 'Pin this event to the top of your GoFan page'
    }
  },
  SECTION_BUYER_PURCHASE_LIMIT: {
    SECTION_TITLE: 'Buyer purchase limit',
    LABELS: {
      SET_MAXIMUM_NUMBER: 'Set the maximum number of tickers a customer can purchase per order'
    }
  }
};

const EVENT_ELEMENT_STATES = {
  valid: true,
  data: '',
  error: '',
  dirty: false
};

export const USER_MAX_DATE = moment().add(500, 'days').format(DATE_PICKER_FORMAT);
export const ADMIN_MAX_DATE = moment().add(730, 'days').format(DATE_PICKER_FORMAT);

export default {
  EVENT_ELEMENT_STATES,
  OPTIONAL_SECTIONS,
  OPTIONAL_MESSAGES,
  PROCESS_DATA: {
    Athletic: EVENT_PROCESS_DATA,
    'Performing Arts': EVENT_PROCESS_DATA,
    Registration: EVENT_PROCESS_DATA,
    'School Dance': EVENT_PROCESS_DATA,
    Other: EVENT_PROCESS_DATA,
    'Mobile Pass': PASS_PROCESS_DATA
  },
  USER_MAX_DATE,
  ADMIN_MAX_DATE
};
