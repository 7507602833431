import get from 'lodash/get';
import type { StaffRole } from './rosters.model';

const STAFF_ROLE_LABELS = {
  coach: 'Head Coach',
  assistant: 'Assistant Coach',
  trainer: 'Trainer'
};

export const getStaffRoleLabel = (role: StaffRole) => get(STAFF_ROLE_LABELS, role, 'Head Coach');
