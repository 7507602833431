export const preventDecimalNumberInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const pressedKey = event.key;
  const isCtrlKey = event.ctrlKey || event.metaKey;
  // Allow digits (0-9), backspace, Delete, Ctrl+A, and the decimal point
  if (
    /[0-9]/.test(pressedKey) || // Digits 0-9
    pressedKey === 'Backspace' || // Backspace
    pressedKey === 'Delete' || // Delete
    pressedKey === 'Tab' || // Tab
    (isCtrlKey && pressedKey === 'a') || // Ctrl+A
    (pressedKey === '.' && !event.currentTarget.value.includes('.')) // Decimal point (.)
  ) {
    return true;
  }

  event.preventDefault();
  return false;
};

// a helper func to prevent the user from entering anything other than numbers
// and an option to limit the field to max digits, if nothing is passed there are no character limit restrictions
export const keyDownNumbersOnly = (e: React.KeyboardEvent<HTMLInputElement>, max = 0) => {
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab']; // Allowed key codes
  if (e.key === 'v' && (e.metaKey || e.ctrlKey)) {
    // Allow paste action using Ctrl+V or Command+V
    return;
  }

  if (!allowedKeys.includes(e.key) && (e.key.length !== 1 || !/\d/.test(e.key))) {
    e.preventDefault(); // Prevent input if not a number or navigation key
  }

  // Limit the field to maxDigits digits if max is not 0
  if (max > 0) {
    const { value } = e.target as HTMLInputElement;
    if (value.length >= max && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  }
};

export const formatPhoneNumber = (value: string) => {
  // Remove all non-digit characters
  const cleaned = value.replace(/\D/g, '');

  // Limit the number of characters to 10 digits
  const limited = cleaned.substring(0, 10);

  // Define the phone number parts
  const match = limited.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

  // Format the parts into the desired format
  if (match) {
    const part1 = match[1] ? `(${match[1]}` : '';
    const part2 = match[2] ? `) ${match[2]}` : '';
    const part3 = match[3] ? `-${match[3]}` : '';

    return `${part1}${part2}${part3}`;
  }

  return value;
};

export const preventClick = (e: React.MouseEvent | React.KeyboardEvent) => {
  e.preventDefault();
  e.stopPropagation();
};
