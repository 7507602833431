export const RESERVED_SEATING_SWITCHER = {
  SEAT_VIEW: 'Seat-View',
  LIST_VIEW: 'List-View',
  RENEWALS_VIEW: 'Renewals-View'
};

export const RESERVED_SEATING_SWITCHER_INDEX = {
  [RESERVED_SEATING_SWITCHER.SEAT_VIEW]: 0,
  [RESERVED_SEATING_SWITCHER.LIST_VIEW]: 1,
  [RESERVED_SEATING_SWITCHER.RENEWALS_VIEW]: 2
};

export const LEGEND = {
  AVAILBLE: { color: 'rgb(90, 110, 34)', label: 'Available', rgba: 'rgba(90, 110, 34, 1)' },
  BOOKED: { color: 'rgb(90, 110, 34)', label: 'Booked', rgba: 'rgba(90, 110, 34, 0.5)' },
  HELD: { color: 'rgb(198, 198, 198)', label: 'Held', rgba: 'rgba(198, 198, 198, 1)' },
  UNAVAILABLE: { color: 'rgb(6, 6, 12)', label: 'Unavailable', rgba: 'rgba(6, 6, 12, 0.5)' },
  BLOCKED: { color: 'rgb(6, 6, 12)', label: 'Blocked', rgba: 'rgba(6, 6, 12, 1)' }
};

export const MODALS = {
  BOOK: 'book',
  UNBOOK: 'unbook',
  MOVE: 'move',
  BLOCK: 'block',
  UNBLOCK: 'unblock'
};

export const SEARCH_PARAMS = {
  id: undefined,
  name: undefined,
  accountIds: undefined,
  activitiesIds: undefined,
  opponentAccountId: undefined,
  financialAccountIds: undefined,
  startDate: undefined,
  specificDate: undefined,
  endDate: undefined,
  includeArchived: true,
  pageIndex: 0,
  pageSize: 25,
  sortBy: [{ desc: false, id: 'startDateTime' }]
};

export const FILTER_PARAMS = {
  startDate: undefined,
  endDate: undefined,
  specDate: undefined,
  activities: [],
  filterSpecificDate: false,
  endingAfterNow: true
};

export const FILTER_DATE_TYPE = {
  enableAll: 0,
  enableSpec: 1,
  enableDateRange: 2
};

export const PAGE_SIZES = [
  { key: 25, value: 25, text: '25' },
  { key: 50, value: 50, text: '50' },
  { key: 100, value: 100, text: '100' }
];

export const SEASON_RENEWAL_CAMPAIGN = {
  LABEL: 'Campaign',
  TITLE: 'Renewal Campaign',
  DESCRIPTION: 'You do not have a renewal campaign currently running',
  CREATE_CAMPAIGN_BTN: 'Create a renewal campaign',
  DOWNLOAD_CAMPAIGN_BTN: 'Download campaign',
  DOWNLOAD_CAMPAIGN_ERROR: 'Unable to export campaign.',
  SELECT_SEASON_RENEWAL: 'Select season renewal campaign',
  VIEW_ALL: 'View All',
  OVERFLOW_MENU: {
    EDIT_CAMPAIGN: 'Edit campaign',
    EDIT_RENEWAL_OFFER: 'Edit renewal offer',
    DELETE_RENEWAL_OFFER: 'Delete renewal offer'
  },
  EDIT_CAMPAIGN_MODAL: {
    TITLE: 'Edit Renewal Campaign',
    DATE_LABEL_TEXT: 'Fans can purchase tickets until this date:',
    RENEWAL_END_DATE: 'Renewal end date',
    DAYS_LABEL_TEXT: 'Send my fans an email if they haven’t renewed',
    DAYS_HELPER_TEXT: 'days(s) before season renewal campaign ends',
    CANCEL_BTN: 'Cancel',
    UPDATE_BTN: 'Update',
    UPDATE_LOADING: 'Updating',
    UPDATE_MESSAGE_SUCCESS: 'You have successfully made edits to the renewal campaign',
    UPDATE_MESSAGE_FAILURE: 'Failure to update the renewal campaign'
  }
};

export const RENEWAL_OFFER_MODAL = {
  TITLE: 'Edit renewal offer',
  NAME_DESCRIPTION: 'You may update the fans first or last name',
  SEATS_DESCRIPTION: '{name} currently has the following seats:',
  NAME_MAPPING: {
    firstName: 'First name',
    lastName: 'Last name'
  },
  ERROR_MESSAGES: {
    NAME: {
      REQUIRED: '{name} is required.',
      INVALID: '{name} should not contain special characters or numbers.'
    }
  }
};

export const ITEM_ALL_CAMPAIGN = {
  id: 'all',
  label: 'All Campaigns'
};

export default {
  RESERVED_SEATING_SWITCHER,
  RESERVED_SEATING_SWITCHER_INDEX,
  LEGEND,
  MODALS
};
