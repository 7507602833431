import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import type { OrderDTO, EventSearchParams } from '@gofan/api';

export const getStorageItem = (name: string) => {
  const storageItem = localStorage.getItem(name) ?? '';
  return storageItem.startsWith('{') || storageItem.startsWith('[') ? JSON.parse(storageItem) : null;
};

export const setStorageItem = (name, data) => {
  if (!isEmpty(data) || typeof data === 'boolean') {
    localStorage.setItem(name, JSON.stringify(data));
  } else {
    removeStorageItem(name);
  }
};

export const setObjectStorageItem = (name, key, data) => {
  const cache = getStorageItem(name);
  setStorageItem(name, {
    ...cache,
    [key]:
      data === undefined
        ? undefined
        : {
            cachedAt: dayjs().unix(),
            value: data
          }
  });
};

export const removeStorageItem = (name: string) => {
  localStorage.removeItem(name);
};

export const setStorageAuthenticated = authenticated => {
  setStorageItem('authenticated', authenticated);
};

export const getStorageAuthenticated = () => {
  const authenticated = localStorage.getItem('authenticated');
  return authenticated === 'true';
};

export const getStorageAuthBody = () => {
  const authBody = localStorage.getItem('authBody') ?? '';
  return authBody.startsWith('{') ? JSON.parse(authBody) : null;
};

export const setStorageAuthBody = authBody => {
  setStorageItem('authBody', authBody);
};

export const removeStorageAuthBody = () => {
  removeStorageItem('authBody');
};

export const clearStorage = () => {
  messageBroadcast({ command: 'clear' });
  localStorage.clear();
};

export const messageBroadcast = message => {
  localStorage.setItem('message', JSON.stringify(message));
  localStorage.removeItem('message');
};

export const getStorageCurrentUser = () => {
  const currentUser = localStorage.getItem('currentUser') ?? '';
  return currentUser.startsWith('{') ? JSON.parse(currentUser) : null;
};

export const setStorageCurrentUser = currentUser => {
  setStorageItem('currentUser', currentUser);
};

export const removeStorageCurrentUser = () => {
  removeStorageItem('currentUser');
};

export const setStorageLandingActiveAccounts = accounts => {
  setStorageItem('landingActiveAccounts', accounts);
};

export const getStorageLandingActiveAccounts = (): string[] => getStorageItem('landingActiveAccounts');

export const removeStorageLandingActiveAccounts = () => {
  removeStorageItem('landingActiveAccounts');
};

export const setStorageLandingSelectedAccounts = accounts => {
  setStorageItem('landingSelectedAccounts', accounts);
};

export const getStorageLandingSelectedAccounts = (): string[] => getStorageItem('landingSelectedAccounts');

export const removeStorageLandingSelectedAccounts = () => {
  removeStorageItem('landingSelectedAccounts');
};

export const setStorageEventSearchParams = (params?: EventSearchParams) => {
  setStorageItem('eventSearchParams', params);
};

export const getStorageEventSearchParams = () => getStorageItem('eventSearchParams');

export const getAuthTokenFromStorage = () => {
  const authBody = getStorageAuthBody();
  if (!authBody || !authBody.user || !authBody.user.stsTokenManager) {
    return null;
  }
  return authBody.user.stsTokenManager.accessToken;
};

export const getAuthExpirationTime = () => {
  const authBody = getStorageAuthBody();
  if (!authBody || !authBody.user || !authBody.user.stsTokenManager) {
    return null;
  }
  return authBody.user.stsTokenManager.expirationTime;
};

export const setStorageEventFilterParams = (filterParams?: any) => {
  setStorageItem('eventFilterParams', filterParams);
};

export const getStorageEventFilterParams = () => getStorageItem('eventFilterParams');

export const setStoragePendoAccounts = (pendoAccs?: any[]) => {
  setStorageItem('pendoAccs', pendoAccs);
};

export const getStoragePendoAccounts = () => getStorageItem('pendoAccs');

export const getRecentEmails = () => getStorageItem('recentEmails');

// eslint-disable-next-line consistent-return
export const addEmailToRecentEmails = (email: string) => {
  if (!getStorageItem('recentEmails')) {
    return setStorageItem('recentEmails', [email]);
  }

  const recentEmails: string[] = getStorageItem('recentEmails');
  if (recentEmails.indexOf(email) > -1) {
    recentEmails.splice(recentEmails.indexOf(email), 1);
  } else if (recentEmails.length === 5) {
    recentEmails.shift();
  }
  recentEmails.push(email);
  setStorageItem('recentEmails', recentEmails);
};

export const getRecentFanEmails = () => getStorageItem('recentFanEmails');

// eslint-disable-next-line consistent-return
export const addEmailToRecentFanEmails = ({ email, eventId }: { email: string; eventId?: string | number }) => {
  if (!getStorageItem('recentFanEmails')) {
    return setStorageItem('recentFanEmails', [{ email, eventId }]);
  }

  const recentFanEmails: { email: string; eventId?: string | number }[] = getStorageItem('recentFanEmails');
  const indexOfEmail = recentFanEmails.findIndex(item => item.email === email);
  if (indexOfEmail > -1) {
    recentFanEmails.splice(indexOfEmail, 1);
  } else if (recentFanEmails.length === 5) {
    recentFanEmails.shift();
  }
  recentFanEmails.push({ email, eventId });
  setStorageItem('recentFanEmails', recentFanEmails);
};

export const getRecentCompTickets = () => getStorageItem('recentCompTickets');

// eslint-disable-next-line consistent-return
export const addRecentCompTickets = (compTickets: Partial<OrderDTO>) => {
  if (!getStorageItem('recentCompTickets')) {
    return setStorageItem('recentCompTickets', [compTickets]);
  }

  const recentCompTickets: Partial<OrderDTO>[] = getStorageItem('recentCompTickets');
  recentCompTickets.push(compTickets);
  setStorageItem('recentCompTickets', recentCompTickets);
};

export const getStoragePathToRedirect = () => localStorage.getItem('pathToRedirect');

export const setStoragePathToRedirect = (path: any) => {
  localStorage.setItem('pathToRedirect', path);
};

export const removeStoragePathToRedirect = () => {
  removeStorageItem('pathToRedirect');
};

export const setTeamIdStorage = (teamId: string) => {
  localStorage.setItem('teamId', teamId);
};

export const getTeamIdStorage = () => localStorage.getItem('teamId');
