import { Activity, Collapse } from './types';

export const COLLAPSE_ACTIVITY = 'activity/COLLAPSE_ACTIVITY';

export const FETCH_ACTIVITIES = 'activity/FETCH_ACTIVITIES';
export const FETCH_ACTIVITIES_SUCCESSFUL =
  'activity/FETCH_ACTIVITIES_SUCCESSFUL';
export const FETCH_ACTIVITIES_COMPLETED = 'activity/FETCH_ACTIVITIES_COMPLETED';

export const CREATE_ACTIVITY = 'activity/CREATE_ACTIVITY';
export const CREATE_ACTIVITY_SUCCESSFUL = 'activity/CREATE_ACTIVITY_SUCCESSFUL';
export const CREATE_ACTIVITY_COMPLETED = 'activity/CREATE_ACTIVITY_COMPLETED';

export const UPDATE_ACTIVITY = 'activity/UPDATE_ACTIVITY';
export const UPDATE_ACTIVITY_SUCCESSFUL = 'activity/UPDATE_ACTIVITY_SUCCESSFUL';
export const UPDATE_ACTIVITY_COMPLETED = 'activity/UPDATE_ACTIVITY_COMPLETED';

export const DELETE_ACTIVITY = 'activity/DELETE_ACTIVITY';
export const DELETE_ACTIVITY_SUCCESSFUL = 'activity/DELETE_ACTIVITY_SUCCESSFUL';
export const DELETE_ACTIVITY_COMPLETED = 'activity/DELETE_ACTIVITY_COMPLETED';

interface FetchActivitiesAction {
  type: typeof FETCH_ACTIVITIES;
}

interface FetchActivitiesSuccessfulAction {
  type: typeof FETCH_ACTIVITIES_SUCCESSFUL;
  payload: {
    athleticActivities: Activity[];
    nonAthleticActivities: Activity[];
  };
}

interface FetchActivitiesCompletedAction {
  type: typeof FETCH_ACTIVITIES_COMPLETED;
}

interface CreateActivityAction {
  type: typeof CREATE_ACTIVITY;
  payload: {
    activity: Activity;
  };
}

interface CreateActivitySuccessfulAction {
  type: typeof CREATE_ACTIVITY_SUCCESSFUL;
  payload: {
    athleticActivities: [Activity];
    nonAthleticActivities: [Activity];
  };
}

interface CreateActivityCompletedAction {
  type: typeof CREATE_ACTIVITY_COMPLETED;
}

interface UpdateActivityAction {
  type: typeof UPDATE_ACTIVITY;
  payload: {
    activity: Activity;
  };
}

interface UpdateActivitySuccessAction {
  type: typeof UPDATE_ACTIVITY_SUCCESSFUL;
  payload: {
    athleticActivities: [Activity];
    nonAthleticActivities: [Activity];
  };
}

interface UpdateActivityCompletedAction {
  type: typeof UPDATE_ACTIVITY_COMPLETED;
}

interface DeleteActivityAction {
  type: typeof DELETE_ACTIVITY;
  payload: {
    activity: Activity;
  };
}

interface DeleteActivitySuccessfulAction {
  type: typeof DELETE_ACTIVITY_SUCCESSFUL;
  payload: {
    athleticActivities: [Activity];
    nonAthleticActivities: [Activity];
  };
}

interface DeleteActivityCompletedAction {
  type: typeof DELETE_ACTIVITY_COMPLETED;
}

interface CollapseActivityAction {
  type: typeof COLLAPSE_ACTIVITY;
  payload: {
    collapseParams: Collapse;
  };
}

export type ActivityActionTypes =
  | CollapseActivityAction
  | UpdateActivityAction
  | UpdateActivityCompletedAction
  | UpdateActivitySuccessAction
  | DeleteActivityAction
  | DeleteActivitySuccessfulAction
  | DeleteActivityCompletedAction
  | FetchActivitiesAction
  | FetchActivitiesSuccessfulAction
  | FetchActivitiesCompletedAction
  | CreateActivityAction
  | CreateActivitySuccessfulAction
  | CreateActivityCompletedAction;
