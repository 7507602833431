import { useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';

import { AccountQuery } from '@gofan/api/accounts';
import { useSearchSchoolConfig } from '@gofan/api/school-config';

import type { AccountDTO } from '@gofan/api/accounts';

interface UseDistrictSchoolConfigProps {
  districtAccount: AccountDTO;
  enabled?: boolean;
}

export const useDistrictSchoolConfig = ({ districtAccount }: UseDistrictSchoolConfigProps) => {
  const {
    data: underDistrictSchoolsData = [],
    isLoading: isSchoolsLoading,
    isFetched: isSchoolsFetched
  } = AccountQuery.getAccountSchoolsByDistrict({
    districtHuddleId: districtAccount?.id,
    queryOptions: {
      enabled: !!districtAccount?.id,
      refetchOnWindowFocus: false
    }
  });

  const { underDistrictSchools, underDistrictSchoolIds } = useMemo(() => {
    if (isEmpty(underDistrictSchoolsData) || !isSchoolsFetched || isSchoolsLoading) {
      return {
        underDistrictSchools: [],
        underDistrictSchoolIds: []
      };
    }

    const items = uniqBy(underDistrictSchoolsData, 'id');
    return {
      underDistrictSchools: items,
      underDistrictSchoolIds: items?.map(item => `${item.id}`)?.sort((a, b) => `${a}`?.localeCompare(`${b}`, 'en'))
    };
  }, [isSchoolsFetched, isSchoolsLoading, underDistrictSchoolsData]);

  const {
    data: schoolsConfig = [],
    isLoading: isSchoolsConfigLoading,
    isFetched: isSchoolsConfigFetched
  } = useSearchSchoolConfig({
    schoolIds: underDistrictSchoolIds,
    queryOptions: {
      enabled: !isEmpty(underDistrictSchoolIds) && !!districtAccount?.id && !isSchoolsLoading,
      refetchOnWindowFocus: false
    }
  });

  const isLoaded = useMemo(
    () => isSchoolsFetched && !isSchoolsLoading && isSchoolsConfigFetched && !isSchoolsConfigLoading,
    [isSchoolsFetched, isSchoolsLoading, isSchoolsConfigFetched, isSchoolsConfigLoading]
  );

  const isLoading = useMemo(
    () => isSchoolsLoading || isSchoolsConfigLoading,
    [isSchoolsLoading, isSchoolsConfigLoading]
  );

  return {
    isLoaded,
    isLoading,
    isSchoolsFetched,
    isSchoolsLoading,
    isSchoolsConfigFetched,
    isSchoolsConfigLoading,
    schoolsConfig,
    underDistrictSchools,
    underDistrictSchoolIds
  };
};
