import React, { useState, useEffect, useCallback, ReactElement } from 'react';
import { FormLabel, FileUploader, FileUploaderProps, FileUploaderButton, Tag } from 'carbon-components-react';
import { isEmpty } from 'lodash';
import { PHOTO_UPLOAD_TYPE } from '@accounts/constants/constants';
import { triggerOnValuesChange } from '../../basic-form.component';

export type BasicPhotoUploadConfig = {
  label: string | ReactElement;
  labelTitle: string | ReactElement;
  labelDescription: string | ReactElement;
  buttonLabel: string | ReactElement;
  filesAccept: string[];
  disabled: boolean;
};

export type BasicPhotoUploadProps = FileUploaderProps &
  BasicPhotoUploadConfig & {
    name: string;
    control: any;
    context: any;
    initialValue: string;
    defaultIcon: ReactElement;
    fieldValue: any;
    fieldError?: string;
    type?: string;
    getFieldRef?: () => void;
  };

const BasicPhotoUpload = ({
  name,
  label = '',
  labelTitle,
  labelDescription,
  buttonLabel = '',
  filesAccept,
  disabled = false,
  className,
  initialValue,
  fieldValue,
  fieldError,
  getFieldRef,
  control,
  context,
  defaultIcon,
  type = PHOTO_UPLOAD_TYPE.ADD_CONTACT,
  ...other
}: BasicPhotoUploadProps) => {
  const [selectedFileName, setFileName] = React.useState<string>('');
  const [imgUrl, setImgUrl] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<any>();

  useEffect(() => {
    setErrorMessage(null);
    if (fieldValue instanceof Blob) {
      setImgUrl(URL.createObjectURL(fieldValue));
      setFileName(fieldValue.name);
    }
  }, [fieldValue]);

  useEffect(() => {
    if (!isEmpty(selectedFileName)) {
      setErrorMessage(fieldError);
    }
  }, [fieldError]);

  const onDeleteFile = useCallback(() => {
    setFileName('');
    setImgUrl(null);
    triggerOnValuesChange({ [name]: null }, control.onChange);
  }, [selectedFileName]);

  if (type === PHOTO_UPLOAD_TYPE.ADD_CONTACT) {
    return (
      <>
        <FormLabel>{label}</FormLabel>
        <div className='photo-upload-container'>
          <div className='photo-preview'>
            {isEmpty(imgUrl) ? (
              <img className='photo-default' src={defaultIcon} />
            ) : (
              <img src={imgUrl} alt='preview img' />
            )}
          </div>
          <div className='photo-upload-file'>
            {disabled ? (
              <div>
                <div className='gs--productive-heading-01 gs--padding-bottom__sp3 title'>{labelTitle}</div>
                <div className='gs--padding-bottom__sp5 gs--body-short-01 description description'>
                  {labelDescription}
                </div>
                <FileUploaderButton disabled labelText={buttonLabel} className='gs--margin-bottom-sp4' />
              </div>
            ) : (
              <FileUploader
                {...other}
                {...control}
                id={`field-${name}`}
                className={className}
                labelTitle={labelTitle}
                multiple={false}
                filenameStatus='edit'
                labelDescription={labelDescription}
                buttonLabel={buttonLabel}
                accept={filesAccept}
                disabled
              />
            )}
            {!isEmpty(selectedFileName) && (
              <div className='file-upload-item--contact-detail'>
                <Tag
                  type={errorMessage && !isEmpty(errorMessage) && 'red'}
                  disabled={disabled}
                  filter
                  onClose={onDeleteFile}
                >
                  {selectedFileName}
                </Tag>
                <div className='error-message'>{errorMessage}</div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className='photo-upload-container'>
        <div className='photo-upload-file-gofan-page'>
          {disabled ? (
            <>
              <div className='gs--productive-heading-01 gs--padding-bottom__sp3 title'>{labelTitle}</div>
              <div className='gs--padding-bottom__sp5 gs--body-short-01 description description'>
                {labelDescription}
              </div>
              <FileUploaderButton disabled labelText={buttonLabel} className='gs--margin-bottom-sp4' />
            </>
          ) : (
            <FileUploader
              {...other}
              {...control}
              id={`field-${name}`}
              className={className}
              labelTitle={labelTitle}
              multiple={false}
              filenameStatus='edit'
              labelDescription={labelDescription}
              buttonLabel={buttonLabel}
              accept={filesAccept}
            />
          )}
          <div className='file-upload-item'>
            {!isEmpty(selectedFileName) && (
              <div className='file-upload-item'>
                <Tag
                  type={errorMessage && !isEmpty(errorMessage) && 'red'}
                  disabled={disabled}
                  filter
                  onClose={onDeleteFile}
                >
                  {selectedFileName}
                </Tag>
                <div className='error-message'>{errorMessage}</div>
              </div>
            )}
          </div>
        </div>

        <div className='container-preview'>
          <div className='gs--productive-heading-02 title gs--padding-bottom__sp3'>Uploaded image</div>
          <div className='photo-preview-gofan-page'>
            {isEmpty(imgUrl) ? (
              <img className='photo-default' src={defaultIcon} />
            ) : (
              <img src={imgUrl} alt='preview img' />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicPhotoUpload;
