import React, { useContext } from 'react';
import { Menu, Popup, Message, Grid, Dropdown } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Menu32, Close32, Help32 } from '@carbon/icons-react';
import styled from 'styled-components';
import SideMenu from '../../../pages/Root/components/SideMenu';
import { Icons, Button } from '@gofan/components';

import styles from './styles';
import { RootContext } from '../../../RootContext';
import logoSource from '../../../assets/images/logo.svg';
import { PAGES } from '../../../config/routes';
import { useFeatureFlags, FEATURE_FLAGS } from '@gofan/hooks/useFeatureFlags';

const myStyle = {
  display: 'flex',
  alignItems: 'center',
  color: '#fff',
  padding: '10px 0',
  position: 'fixed',
  width: '100%',
  top: 0,
  right: 0,
  height: '50px',
  background: '#323232',
  zIndex: 800
};

const MenuIconWrapper = styled.div`
  ${styles.menuIconWrapper};
`;

const LeftLogo = styled.img`
  ${styles.leftLogo};
`;

const AccountName = styled.div`
  ${styles.accountName};
`;

export default props => {
  const { authBody, clearAuthorization, currentUser, setCurrentUser } = useContext(RootContext);
  const handleLogout = () => clearAuthorization();
  const { history, messages, removeMessage } = props;
  const { [FEATURE_FLAGS.tempNavBarPendoLauncherDashbirds]: navBarPendoLauncherEnabled } = useFeatureFlags() || {};

  const menuPopJsx = (
    <Menu text vertical inverted>
      <Menu.Item name='profile' onClick={() => history.push('/profile')} />
      <Menu.Item
        name='change password'
        onClick={() => {
          history.push('/profile?changePassword=true');
          history.push('/forceResetCurrentRoute'); // cheating for force reload current route.
          history.goBack();
        }}
      />
      <Menu.Item name='logout' onClick={() => handleLogout()} />
    </Menu>
  );

  let displayName = 'User Name TBD';
  if (authBody && authBody.user) {
    displayName = authBody.user.displayName || authBody.user.email;
  }

  return (
    <>
      <div className='container-row-div' style={myStyle}>
        <MenuIconWrapper onClick={props.toggleSideMenu}>
          {props.isSideMenuExpanded ? <Close32 /> : <Menu32 />}
        </MenuIconWrapper>
        <div className='child-filler-div' />
        <Link to={PAGES.dashboard.root.path} style={{ display: 'flex', alignItems: 'center' }}>
          <LeftLogo src={logoSource} alt='GoFan HQ Logo' />
        </Link>
        <AccountName className='child-aligned padded' style={{ paddingRight: 0 }}>
          {import.meta.env.MODE === 'development' && (
            <Dropdown
              style={{ width: '100px', backgroundColor: 'white' }}
              options={[
                {
                  key: 'SUPER_ADMIN',
                  text: 'SUPER_ADMIN',
                  value: 'SUPER_ADMIN'
                },
                {
                  key: 'SYSTEM_FINANCE_ADMIN',
                  text: 'SYSTEM_FINANCE_ADMIN',
                  value: 'SYSTEM_FINANCE_ADMIN'
                },
                {
                  key: 'SYSTEM_ADMIN',
                  text: 'SYSTEM_ADMIN',
                  value: 'SYSTEM_ADMIN'
                },
                {
                  key: 'SYSTEM_USER',
                  text: 'SYSTEM_USER',
                  value: 'SYSTEM_USER'
                },
                {
                  key: 'LIMITED_SYSTEM_USER',
                  text: 'LIMITED_SYSTEM_USER',
                  value: 'LIMITED_SYSTEM_USER'
                },
                {
                  key: 'ACCOUNT_USER',
                  text: 'ACCOUNT_USER',
                  value: 'ACCOUNT_USER'
                }
              ]}
              selection
              onChange={(e, { value }) => {
                setCurrentUser({ ...currentUser, role: value });
              }}
              value={currentUser ? currentUser.role : null}
            />
          )}
          <span
            className='gs--font-family-sf'
            style={{ fontSize: '16px', margin: '7px' }}
          >{`Hi ${displayName}  `}</span>
          <Popup
            inverted
            trigger={
              <Button
                renderIcon={() => <Icons.UserAvatarFilled size={24} style={{ fill: '#00c9d2' }} />}
                hasIconOnly
                kind='ghost'
                size='md'
              />
            }
            content={menuPopJsx}
            on='click'
            position='top left'
          />
        </AccountName>
        {navBarPendoLauncherEnabled && (
          <MenuIconWrapper id='PendoResourceCenterLauncher'>
            <Help32 />
          </MenuIconWrapper>
        )}
        <div className='child-spacer-div' />
      </div>
      {messages && messages.length > 0 && (
        <div
          style={{
            position: 'fixed',
            top: '75px',
            zIndex: 10000,
            width: '100%',
            padding: '0px 10px'
          }}
        >
          <Grid>
            <Grid.Row>
              <div className='child-nogrow-div side-menu' />
              <div className='child-filler-div padded-message'>
                {messages.map(message => (
                  <Message
                    key={message.id}
                    positive={message.type === 'positive'}
                    warning={message.type === 'warning'}
                    negative={message.type === 'negative'}
                    content={message.content}
                    onDismiss={() => removeMessage(message.id)}
                  />
                ))}
              </div>
            </Grid.Row>
          </Grid>
        </div>
      )}

      <SideMenu hasIconMenu={false} currentUser={currentUser} {...props} />
    </>
  );
};
