export const STRINGS = {
  RATE_CONFIGURATION: 'Rate Configuration',
  ADD_RATE: 'Add rate',
  EDIT_RATE: 'Edit rate',
  SPECIFY_RATE_NAME: 'Specify the rate name',
  RATE_NAME: 'Rate name',
  TEXT_INPUT_PLACEHOLDER: 'Input text',
  RATE_VALUE: 'Rate value',
  RATE_VALUE_DESCRIPTION: 'Set a rate value and determine if you would only like cashless',
  RATE_AMOUNT: 'Rate $',
  RATE_PERCENT: 'Rate %',
  CASHLESS: 'Cashless',
  PRODUCT_TYPE: 'Product type',
  PRODUCT_TYPE_DESCRIPTION: 'Choose a product type associated with this rate',
  MULTI_SELECT_PLACEHOLDER: 'Choose an option',
  OPTIONS_SELECTED: 'Options selected',
  PRICE_PRODUCT_TYPE: 'Price for product type',
  PRICE_PRODUCT_TYPE_DESCRIPTION: 'Set the minimum and maximum price for this product',
  MINIMUM_PRICE: 'Minimum price',
  MAXIMUM_PRICE: 'Maximum price',
  DATE_RANGE: 'Date range',
  DATE_RANGE_DESCRIPTION: 'Set the rate between this time period',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  DATE_PICKER_PLACEHOLDER: 'mm/dd/yyyy',
  ALWAYS_APPLY_RATE: 'Always apply rate',
  APPLY_POST_SEASON_OR_REGULAR: 'Apply to post-season or regular seasoned events',
  POST_SEASON: 'Postseason events',
  REGULAR_EVENT: 'Regular Season events',
  APPLY_FOR_DISTRIBUTION_CHANNEL: 'Each rate can be applied to a distribution channel',
  GOFAN_TICKETS: 'GoFan tickets',
  BOXOFFICE_TICKETS: 'Box office tickets',
  OPTIONAL_SETTINGS: 'Optional settings',
  STATES_DESCRIPTION: 'A rate will be applied to specific state(s)',
  STATES: 'States',
  SPECIFIC_SCHOOLS_DESCRIPTION: 'A rate will be applied to specific school(s)',
  EXCEPTION_SCHOOLS_DESCRIPTION: 'Select the account(s) that a rate will not be applied',
  SEARCH_PLACEHOLDER: 'Search input text',
  SAVE: 'Save',
  SAVING: 'Saving',
  CANCEL: 'Cancel',
  EVENT_TICKETS_AND_SEASON_PACKAGES_DESCRIPTION: 'Select which event tickets or season packages the rate applies to',
  EVENT_TICKETS: 'Event tickets',
  SEASON_PACKAGES: 'Season packages',
  TICKET_TYPE_DESCRIPTION: 'Select the ticket type the rate applies to',
  GENERAL_ADMISSION: 'General admission',
  RESERVED_SEATING: 'Reserved seating'
};

export const ERROR_MESSAGES = {
  LABEL: {
    REQUIRED: 'Rate  name is required.',
    INVALID:
      'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) with a maximum of 32 characters long.'
  },
  RATE_AMOUNT: {
    REQUIRED: 'Rate ($) is required.'
  },
  RATE_PERCENT: {
    REQUIRED: 'Rate (%) is required.',
    INVALID: 'You can enter number in range (0-100).'
  },
  PRODUCT_TYPES: {
    REQUIRED: 'Product type is required.'
  },
  RESOURCE_TYPES: {
    REQUIRED: 'Must select Event tickets or season tickets to continue.'
  },
  TICKET_TYPES: {
    REQUIRED: 'Must select General admission or Reserved seating to continue.'
  },
  MIN_PRICE: {
    REQUIRED: 'Min price is required.',
    GREATER_MAX_PRICE: 'The entered value should be lower than the value of Max price field.'
  },
  MAX_PRICE: {
    REQUIRED: 'Max price is required.',
    LOWER_MIN_PRICE: 'The entered value should be greater than the value of Min price field.'
  },
  START_DATE: {
    REQUIRED: 'Start date is required.',
    INVALID: 'Start date is invalid.',
    AFTER_END_DATE: 'Start date must come before End date.'
  },
  END_DATE: {
    REQUIRED: 'End date is required.',
    INVALID: 'End date is invalid.',
    BEFORE_START_DATE: 'End date must come after Start date.'
  },
  POST_SEASON: {
    REQUIRED: 'Postseason is required.'
  },
  DISTRIBUTION_CHANNELS: {
    REQUIRED: 'Distribution channel is required.'
  },
  SPECIFIC_SCHOOLS: {
    ADDED_IN_TABLE: 'School already selected.',
    ADDED_IN_EXCEPTION_ACCOUNTS:
      'A school can only added in either School specifics or Account exception. Please review and retry'
  },
  EXCEPTION_ACCOUNTS: {
    ADDED_IN_TABLE: 'Account already selected',
    ADDED_IN_SPECIFIC_SCHOOLS:
      'A school can only added in either School specifics or Account exception. Please review and retry'
  }
};
