import { SeasonTicketSetting } from '@season-management/components/SeasonTicketModal/components/SeasonTicketSetting';
import { OptionTicketSetting } from '@season-management/components/SeasonTicketModal/components/OptionTicketSetting';
import { AddTicketTypeForm as TicketTypeSetting } from '@account-products/components/control-forms';

import { SEASON_TICKET_STEPS } from '@season-management/components/SeasonTicketModal/constants';
import { SeasonTicketTypeEnum } from '@season-management/components/SeasonTicketModal';

import type { SeasonTicketData } from '@season-management/components/SeasonTicketModal';
import { isSchoolEligibleToPayFee } from '@gofan/utils';

import './SeasonTicketStep.scss';

type SeasonTicketStepProps = {
  currentStep: number;
  mode: 'add' | 'update';
  type: SeasonTicketTypeEnum;
  data: SeasonTicketData;
  seasonTicket: any;
  boxOfficeCustomer?: boolean;
  seatsIoChartKey?: string;
  isAdminRole: boolean;
  onShowNotification?: (accountPaid: boolean) => void;
};

const SeasonTicketStep = ({
  currentStep,
  mode,
  type,
  data,
  boxOfficeCustomer,
  seatsIoChartKey,
  isAdminRole,
  seasonTicket,
  onShowNotification
}: SeasonTicketStepProps) => {
  const disableAttendeeFeeToggle = !isSchoolEligibleToPayFee(data.account ?? undefined);

  return (
    <div className='gf-season-ticket-step'>
      <div hidden={currentStep !== SEASON_TICKET_STEPS.seasonTicketSettings.index}>
        {type === SeasonTicketTypeEnum.SEASON_TICKET && (
          <SeasonTicketSetting
            mode={mode}
            data={data}
            seasonTicket={seasonTicket}
            onShowNotification={onShowNotification}
            seatsIoChartKey={seatsIoChartKey}
          />
        )}
        {type === SeasonTicketTypeEnum.TICKET_TYPE && (
          <TicketTypeSetting
            type={data.typeOfTicketForm as 'default' | 'ticket'}
            boxOfficeCustomer={boxOfficeCustomer}
            rates={data?.rates}
            config={{ ...data?.config, disabledAccountPaid: disableAttendeeFeeToggle }}
            onShowNotification={onShowNotification}
            seatsIoChartKey={seatsIoChartKey}
            isSeasonFlow
          />
        )}
      </div>
      <div hidden={currentStep !== SEASON_TICKET_STEPS.optionalTicketSettings.index}>
        <OptionTicketSetting type={type} data={data} isAdminRole={isAdminRole} />
      </div>
    </div>
  );
};

export { SeasonTicketStep };
