import { AccountRepository } from './account.repository';

import { normalizePhoneNumber } from '@gofan/utils/fan';
import { isEmptyString } from '@gofan/utils/strings';
import { AppUtils } from '@gofan/utils/app';
import { setObjectStorageItem } from '@gofan/utils/local-storage';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import xor from 'lodash/xor';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import {
  ACCOUNT_STATUS,
  ACCOUNT_PAYMENT_CYCLE,
  ACH_INFORMATION,
  DISTRICT_CONFERENCE_SCHOOL_TYPES,
  SCHOOL_GROUP_ITEMS
} from '@gofan/constants/accounts';
import { config } from '@gofan/constants/config';
import { PageRequest, SortingRequest } from '../shared/model/request';
import { hasEditFinancialRole, checkInternalUser, getActiveUserAccountContext } from '../users';

import type {
  SchoolData,
  AccountDTO,
  AccountSearchParams,
  AccountKeywordSearchParams,
  AccountPageResponseDTO,
  AccountContact,
  AccountHistorySearchDTO,
  AccountGroupedItems
} from './account.model';
import { STATUS_CODE_404 } from '@gofan/constants';
import type { HistoryDTO } from '../history/history.model';
import type { UserDTO } from '../users';
import type { SchoolConfig } from '../school-config';

export const ACCOUNTS_CACHE_NAME = 'accounts';

class AccountService {
  static getAccountById = (accountId: string, manualErrorHandling?: boolean): Promise<AccountDTO> =>
    AccountRepository.getAccountById(accountId, manualErrorHandling);

  static getAccountsByIds = (accountId: string[]): Promise<AccountDTO[]> =>
    AccountRepository.getAccountsByIds(accountId);

  static getListAccountByIds = (accountIds: string[] = []) =>
    Promise.all(accountIds?.map(accountId => this.getAccountById(accountId)));

  static getAllAccounts = (): Promise<AccountDTO[]> => AccountRepository.getAllAccounts();

  static getAccountsByKeyword = (params: AccountKeywordSearchParams): Promise<AccountDTO[]> =>
    AccountRepository.getAccountsByKeyword(params);

  static cancelGetAccountsByKeyword = () => AccountRepository.cancelGetAccountsByKeyword();

  static searchAccountByIds = (ids: string[]) => AccountRepository.searchAccountByIds(ids);

  static searchAccountByParams = (searchParams: AccountSearchParams): Promise<AccountPageResponseDTO> => {
    const pageQuery = new PageRequest({
      pageIndex: searchParams.page,
      pageSize: searchParams.pageSize
    }).toQueryString();
    const sortableQuery = isEmpty(searchParams.sortBy)
      ? ''
      : new SortingRequest({
          sortBy: [
            {
              id: searchParams.sortBy.header,
              desc: searchParams.sortBy.sortDirection === 'DESC'
            }
          ]
        }).toQueryString();
    const queryStr = `?ts=${new Date().getTime()}&skipCache=true&${pageQuery}${sortableQuery}`;

    return AccountRepository.searchAccountByParams(searchParams.body, queryStr);
  };

  static searchAccountByUser = (userAccountContexts: any[]): Promise<AccountDTO[]> => {
    if (isEmpty(userAccountContexts)) return Promise.resolve([]);
    return Promise.all(userAccountContexts.map(context => AccountRepository.getAccountById(context.accountId)));
  };

  static getAccountSchools = (stateAssociationHuddleId: string): Promise<AccountDTO> =>
    AccountRepository.getAccountSchools(stateAssociationHuddleId);

  static getAccountVenues = (stateAssociationHuddleId: string): Promise<AccountDTO> =>
    AccountRepository.getAccountVenues(stateAssociationHuddleId);

  static getAccountContacts = (stateAssociationHuddleId: string): Promise<AccountContact[]> =>
    AccountRepository.getAccountContacts(stateAssociationHuddleId);

  static updateAccountById = async (
    account: AccountDTO,
    // eslint-disable-next-line default-param-last
    ignoreEditLogo = true,
    file?: File
  ): Promise<AccountDTO | null> => {
    if (ignoreEditLogo) {
      return AccountRepository.updateAccountById(account);
    }

    let updatedAccount = { ...account };
    const oldAvatar = account?.logo ?? '';
    if (!isEmpty(oldAvatar) || file) {
      const uploadResult = await AccountRepository.uploadLogo(file, oldAvatar, account.id);
      if (!uploadResult || uploadResult.status !== 200) {
        return Promise.resolve(null);
      }

      updatedAccount = { ...account, logo: file?.name ?? '' };
    }

    return AccountRepository.updateAccountById(updatedAccount).then(res => {
      setObjectStorageItem(ACCOUNTS_CACHE_NAME, account.id, undefined);
      return res;
    });
  };

  static partialUpdateAccountById = async (
    accountId: string,
    account: Partial<AccountDTO>,
    // eslint-disable-next-line default-param-last
    ignoreEditLogo = true,
    file?: File
  ): Promise<AccountDTO | null> => {
    if (ignoreEditLogo) {
      return AccountRepository.partialUpdateAccountById(account, accountId);
    }

    let updatedAccount = { ...account };
    const oldAvatar = account?.logo ?? '';
    if (!isEmpty(oldAvatar) || file) {
      const uploadResult = await AccountRepository.uploadLogo(file, oldAvatar, account.id);
      if (!uploadResult || uploadResult.status !== 200) {
        return Promise.resolve(null);
      }

      updatedAccount = { ...account, logo: file?.name ?? '' };
    }

    return AccountRepository.partialUpdateAccountById(updatedAccount, accountId).then(res => {
      setObjectStorageItem(ACCOUNTS_CACHE_NAME, account.id, undefined);
      return res;
    });
  };

  static getAccountHistory = (params: AccountHistorySearchDTO): Promise<HistoryDTO[]> =>
    AccountRepository.getAccountHistory(params);

  static filterAccountBy = (accounts: AccountDTO[] = []) =>
    (accounts ?? []).filter(
      (account: AccountDTO) => `${account.status}`.toLowerCase() === `${ACCOUNT_STATUS.ACTIVE}`.toLowerCase()
    );

  // TODO - add types to params
  static getListAccountChanges = (rootArray, compare, selectedItems) => {
    const listChange = xor(rootArray, compare);
    const newSelectedItems = Array.from(selectedItems);

    listChange.forEach((item: any) => {
      const isAddedItem = includes(compare, item);

      if (isAddedItem) {
        if (newSelectedItems.length < 10) {
          newSelectedItems.push(item);
        }
      } else {
        const index = newSelectedItems.findIndex((selectedItem: any) => selectedItem.id === item.id);
        if (index !== -1) {
          newSelectedItems.splice(index, 1);
        }
      }
    });

    return [listChange, newSelectedItems];
  };

  static uploadImage = async (file: File, accountId: string) => {
    const uploadResult = await AccountRepository.uploadImage(file, accountId);
    if (!uploadResult || uploadResult.status !== 200) {
      return Promise.resolve(null);
    }
    return uploadResult;
  };

  static getLogoUrl = (imageName: string, accountId: string) => {
    if (!isEmpty(imageName)) {
      return `https://${config.s3.BUCKET}.${config.s3.URL}/${config.s3.BASE_PATH}/logo/${accountId}/${imageName}`;
    }

    return '';
  };

  static getUploadedImageUrl = (imageName: string, accountId: string) =>
    `https://${config.s3.BUCKET}.${config.s3.URL}/${config.s3.BASE_PATH}/images/${accountId}/${imageName}`;

  static getAccountPageUrl = (id: string) => `${config.GOFAN_URL}app/school/${id}`;

  static isDailySettlement = (account: AccountDTO | undefined) =>
    account?.state === 'WA' && !['section', 'state association'].includes(account?.gofanSchoolType?.toLowerCase());

  static isDailySettlementByCycle = (account: AccountDTO | undefined) =>
    account?.paymentCycle === ACCOUNT_PAYMENT_CYCLE.DAILY &&
    !['section', 'state association'].includes(account?.gofanSchoolType?.toLowerCase());

  static isDailyTransactionClose = (account: AccountDTO | undefined) =>
    account?.paymentCycle === ACCOUNT_PAYMENT_CYCLE.DAILY;

  static isAbleToEdit = (value: any, fieldName = '') => {
    const numberFields = ['extNumber', 'phoneNumber', 'reEnteringAccountNumber', 'routingNumber', 'taxId'];

    if (isEmptyString(value)) {
      return true;
    }

    // check validtion min = 9 for routingNumber
    if (fieldName === 'routingNumber' && `${value}`.length < 9) {
      return true;
    }

    // check format phone number
    if (fieldName === 'phoneNumber') {
      const phoneNumber = normalizePhoneNumber(value);
      return !/^(\d{3}).(\d{3}).(\d{4})$/.test(phoneNumber);
    }

    // check field should be numeric
    if (numberFields.includes(fieldName)) {
      return !/^[0-9]*$/.test(value);
    }

    return false;
  };

  static get4LastDigitsNumber = (value: string) => {
    if (!value) return '';
    const newValue = value.replace(/\D/g, '');

    return newValue.length > 4
      ? `${newValue.slice(newValue.length - 4)}${newValue.slice(0, newValue.length - 4)}`
      : newValue;
  };

  static getObjectACHInfo = (values: any) => {
    const info: any = {};

    ACH_INFORMATION.forEach(field => {
      if (field !== 'phoneNumber' && field !== 'extNumber') {
        info[field] = values[field];
      }
    });

    return {
      ...info,
      contactPhone: '0000000000' // hardcode this as the phoneNumber as it has been removed from the UI Form
    };
  };

  static getObjectCheckInfo = (values: any) => ({
    address: values.checkAddress,
    addressTwo: values.checkAddressTwo,
    city: values.checkCity,
    state: values.checkState,
    zipCode: values.checkZip,
    attn: values.checkAttention,
    taxId: values.taxId,
    taxEntityName: values.taxEntityName
  });

  // TODO: accountFinancial = AccountFinancialDTO | (financial apis not migrated to packages/api yet)
  static mapAddressFields = (account: AccountDTO, accountFinancial: any) =>
    !isEmpty(accountFinancial)
      ? {
          checkAddress: accountFinancial.address,
          checkAddressTwo: accountFinancial.addressTwo,
          checkCity: accountFinancial.city,
          checkState: accountFinancial.state,
          checkZip: accountFinancial.zipCode,
          address: accountFinancial.address,
          addressTwo: accountFinancial.addressTwo,
          city: accountFinancial.city,
          state: accountFinancial.state,
          zipCode: accountFinancial.zipCode
        }
      : {
          checkAddress: account.streetAddress,
          checkAddressTwo: account.streetAddressTwo,
          checkCity: account.city,
          checkState: account.state,
          checkZip: account.zip,
          address: account.streetAddress,
          addressTwo: account.streetAddressTwo,
          city: account.city,
          state: account.state,
          zipCode: account.zip
        };

  static getInitialValues = (account: AccountDTO | undefined, accountFinancial: any) => {
    if (!account) return {};

    return {
      ...accountFinancial,
      ...this.mapAddressFields(account, accountFinancial),
      transferType: accountFinancial?.transferType || 'ACH',
      routing: this.get4LastDigitsNumber(accountFinancial.routingNumber || ''),
      accounting: this.get4LastDigitsNumber(accountFinancial.accountingNumber || ''),
      reEntering: this.get4LastDigitsNumber(accountFinancial.accountingNumber || ''),
      reEnteringAccountNumber: accountFinancial.accountingNumber,
      taxIdMasked: (accountFinancial.taxId || '').slice(-2),
      paymentCycle: account.paymentCycle,
      ...AppUtils.getValueContactPhone(accountFinancial.contactPhone || ''),
      checkAttention: accountFinancial.transferType === 'Check' ? accountFinancial.attn : '',
      attn: accountFinancial.attn || ''
    };
  };

  static retrieveAddressInfo = (addressFields: any) => {
    let address = (
      `${(addressFields.checkAddress || '') && `${addressFields.checkAddress}, `}` +
      `${(addressFields.checkAddressTwo || '') && `${addressFields.checkAddressTwo}, `}` +
      `${(addressFields.checkCity || '') && `${addressFields.checkCity}, `}` +
      `${(addressFields.checkState || '') && `${addressFields.checkState} `}` +
      `${(addressFields.checkZip || '') && `${addressFields.checkZip}`}`
    ).trim();
    if (address.charAt(address.length - 1) === ',') {
      address = address.slice(0, -1);
    }
    return address;
  };

  static getAccountSchoolsByConference = ({
    conferenceId,
    conferenceLeagueC
  }: {
    conferenceId?: string;
    conferenceLeagueC?: string;
  }) =>
    new Promise<SchoolData[]>(resolve => {
      if (!conferenceId && !conferenceLeagueC) {
        resolve([]);
        return;
      }
      AccountService.searchAccountByParams({
        body: {
          ...(conferenceId && { districtHuddleId: conferenceId }),
          ...(conferenceLeagueC && { conferenceLeagueC })
        },
        pageSize: 999999
      })
        .then(res => {
          const { content: accounts } = res;
          const schools: SchoolData[] = accounts.map(({ id, name, logo, mascot, gofanPageEnabled, city, state }) => ({
            id,
            name,
            logo,
            mascot,
            gofanPageUrl: gofanPageEnabled ? AccountService.getAccountPageUrl(id) : '',
            nfhsUrl: '',
            conferenceLeagueC,
            city,
            state
          }));
          resolve(schools);
        })
        .catch(() => {
          resolve([]);
        });
    });

  static getAccountByConferenceLeagueC = async (conferenceLeagueC?: string): Promise<AccountDTO | undefined> => {
    if (!conferenceLeagueC) {
      return undefined;
    }

    const res = await AccountService.searchAccountByParams({
      body: {
        keyword: conferenceLeagueC
      },
      pageSize: 1
    });

    if (!res.content || res.content.length === 0) {
      return undefined;
    }
    return res.content[0];
  };

  static getAccountSchoolsByDistrict = ({ districtHuddleId }: { districtHuddleId?: string }) =>
    new Promise<AccountDTO[]>(resolve => {
      if (districtHuddleId) {
        AccountService.searchAccountByParams({
          body: { districtHuddleId },
          pageSize: 999999
        })
          .then(res => {
            const { content: accounts } = res;
            const schools = accounts.map(account => ({
              ...account,
              gofanPageUrl: account.gofanPageEnabled ? AccountService.getAccountPageUrl(account.id) : '',
              nfhsUrl: ''
            }));
            resolve(schools as AccountDTO[]);
          })
          .catch(() => {
            resolve([]);
          });
      } else resolve([]);
    });

  static getConferenceLeagueAccount = async (accountId: string): Promise<AccountDTO | undefined> => {
    const account = await AccountService.getAccountById(accountId);
    if (!account?.conferenceLeagueC) {
      return undefined;
    }

    return AccountService.getAccountByConferenceLeagueC(account.conferenceLeagueC);
  };

  static getAccountBySfId = (sfId: string, manualErrorHandling?: boolean): Promise<AccountDTO> =>
    AccountRepository.getAccountBySfId(sfId, manualErrorHandling).catch(error => {
      if (error.response.status === STATUS_CODE_404) {
        return {};
      }
      return error;
    });

  static getGroupedAccounts = ({
    initialSchools,
    associatedSchools,
    districtHuddleId
  }: {
    initialSchools: AccountDTO[];
    associatedSchools: AccountDTO[];
    districtHuddleId: string;
  }) => {
    const districtSchool = initialSchools.find(school => school.id === districtHuddleId);
    const groupedSchools: AccountGroupedItems[] = [];

    if (!isEmpty(districtSchool)) {
      groupedSchools.push(SCHOOL_GROUP_ITEMS.DISTRICT);
      groupedSchools.push(districtSchool);
    }

    if (!isEmpty(associatedSchools)) {
      groupedSchools.push(SCHOOL_GROUP_ITEMS.SCHOOL_UNDER_DISTRICT);
      groupedSchools.push(...associatedSchools.sort((a, b) => a.name?.localeCompare(b.name, 'en', { numeric: true })));
    }

    const otherSchools = initialSchools.filter(school => school.id !== districtHuddleId);
    if (!isEmpty(otherSchools)) {
      groupedSchools.push(SCHOOL_GROUP_ITEMS.OTHERS);
      groupedSchools.push(
        ...otherSchools
          .map(school => school as AccountDTO)
          .sort((a, b) => a?.name?.localeCompare(b.name, 'en', { numeric: true }))
      );
    }

    return uniqBy(groupedSchools, 'id');
  };

  static async fetchSelectableFinancialAccounts({
    initialFinancialAccounts,
    isInternalUser,
    currentUser,
    enableDistrictUnicorn
  }: {
    initialFinancialAccounts: AccountDTO[];
    isInternalUser: boolean;
    currentUser: UserDTO;
    enableDistrictUnicorn: boolean;
  }) {
    const foundDistrictAccount = initialFinancialAccounts.find(
      item => item.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT
    );

    if (
      !isInternalUser &&
      enableDistrictUnicorn &&
      foundDistrictAccount &&
      hasEditFinancialRole(foundDistrictAccount.id, currentUser)
    ) {
      const associatedSchools = await this.getAccountSchoolsByDistrict({
        districtHuddleId: foundDistrictAccount.id
      });

      return {
        selectableFinancialSchools: this.getGroupedAccounts({
          initialSchools: initialFinancialAccounts,
          associatedSchools,
          districtHuddleId: foundDistrictAccount.id
        }),
        districtAccount: foundDistrictAccount
      };
    }

    return { selectableFinancialSchools: initialFinancialAccounts };
  }

  static getSearchParamsByPermission({
    currentUser,
    schoolsConfig,
    districtAccount,
    accountIds,
    financialAccountIds
  }: {
    currentUser: UserDTO;
    districtAccount?: AccountDTO;
    schoolsConfig?: SchoolConfig[];
    accountIds: string[];
    financialAccountIds: string[];
  }) {
    const newAccIds: string[] = [];
    const newFinancialAccIds: string[] = [];

    if (checkInternalUser(currentUser?.role) || isEmpty(accountIds)) {
      return this.convertToSearchPayload({ accountIds, financialAccountIds });
    }

    const activeAccounts = uniqBy(getActiveUserAccountContext(currentUser), 'accountId');

    accountIds.some((accountId, index) => {
      const associatedAccount = activeAccounts.find(item => `${item.accountId}` === `${accountId}`);

      const isUserAccountContext = !isEmpty(associatedAccount);
      if (isUserAccountContext) {
        newAccIds.push(accountId);
        newFinancialAccIds.push(accountId);
        return false;
      }

      const foundConfig = schoolsConfig?.find(item => `${item.schoolId}` === `${accountId}`);
      if (isEmpty(foundConfig)) return false;

      if (foundConfig?.districtAllowSchoolUser && !isEmpty(districtAccount)) {
        newFinancialAccIds.push(districtAccount?.id);
      }

      if (foundConfig?.districtAllowDistrictUser) {
        newFinancialAccIds.push(accountId);
      }

      return accountIds.length - 1 === index;
    });

    return this.convertToSearchPayload({ accountIds: newAccIds, financialAccountIds: newFinancialAccIds });
  }

  static convertToSearchPayload(values: { accountIds?: string[]; financialAccountIds?: string[] }) {
    const accIds = uniq(values?.accountIds ?? [])?.sort((a, b) => `${a}`?.localeCompare(`${b}`, 'en'));
    const finAccIds = uniq(values?.financialAccountIds ?? [])?.sort((a, b) => `${a}`?.localeCompare(`${b}`, 'en'));
    return {
      accountIds: isEmpty(accIds) ? undefined : accIds,
      financialAccountIds: isEmpty(finAccIds) ? undefined : finAccIds
    };
  }

  static isDistrictSchool(account: AccountDTO) {
    return account.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT;
  }
}

export { AccountService };
