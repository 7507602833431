/* eslint-disable */
// import { throttle, debounce } from "throttle-debounce";
import throttle from './utils/throttle';
import debounce from './utils/debounce';
import _ from 'lodash';

//---------------------------------------------//
// Top Bar Part
//---------------------------------------------//
export class ThrottledSearch {
  debug = {
    queryChanges: 0,
    searchesInvoked: 0,
    cacheHits: 0,
    rejectedNotLatestQuery: 0
  };

  lastQuerySent = null;
  cache = [];
  cacheMaxSize = 30;

  constructor(
    searchPromiseGeneratorF,
    onResultsF,
    cacheEnabled = true,
    cacheMaxSize = 30
  ) {
    this.searchPromiseGeneratorF = searchPromiseGeneratorF;
    this.onResultsF = onResultsF;
    this.cacheEnabled = cacheEnabled;

    this.invokeSearchDebounced = debounce(500, this.invokeSearch);
    this.invokeSearchThrottled = throttle(500, this.invokeSearch);
  }

  //part 1...
  //setting the query ... will invoke a search
  setQuery(query) {
    this.debug.queryChanges++;

    //case 1 ...
    //check the cache...if its there return (and set the last query sent field)
    const cachedObject = this.cacheSearch(query);
    if (cachedObject) {
      this.debug.cacheHits++;
      this.lastQuerySent = query;
      this.returnResults(cachedObject.query, cachedObject.results);
    }

    //step 1....
    //invoke the query, with proper throttling
    const querySize = 7; //this.lastQuery.keyword.length || 0;
    if (querySize < 5) {
      this.invokeSearchThrottled(query, this.searchPromiseGeneratorF);
    } else {
      this.invokeSearchDebounced(query, this.searchPromiseGeneratorF);
    }
  }

  //part 2...
  //invoking the search
  invokeSearch(query, searchPromiseGeneratorF) {
    this.debug.searchesInvoked++;
    this.lastQuerySent = query;

    searchPromiseGeneratorF(query)
      .then(results => this.returnResults(query, results))
      .catch(error => this.returnError(query, error));
  }

  //part 3...
  //handling the results, and sending results to handlerF
  //add the results to the cache
  //make sure that this is the query we are actually waiting for (network latency)
  //log data out
  returnResults(query, results) {
    this.cachePush(query, results);

    const isTheMostRecentQuery = _.isEqual(query, this.lastQuerySent);
    if (!isTheMostRecentQuery) {
      this.debug.rejectedNotLatestQuery++;
      return;
    }

    this.onResultsF(results, query);
  }

  returnError(query, error) {
    this.onResultsF(null, query, error)
  }

  logDebug() {
    const message =
      `throttled search update :: query changes = ${this.debug.queryChanges}` +
      ` :: search calls = ${this.debug.searchesInvoked}}` +
      ` :: cache hits = ${this.debug.cacheHits}` +
      ` :: cache size = ${
        this.cacheEnabled ? this.cache.length : 'cache-disabled'
      }` +
      ` :: ignored results (response order issue) = ${
        this.debug.rejectedNotLatestQuery
      }`;
    console.log(message);
  }

  //--------------------------
  //Cache
  //--------------------------
  cacheSearch(query) {
    if (!this.cacheEnabled) return;
    return this.cache.find(cacheObject => _.isEqual(query, cacheObject.query));
  }
  cachePush(query, results) {
    if (!this.cacheEnabled) return;

    const cacheObject = { query: query, results: results };
    this.cacheFlush(query);
    this.cache.push(cacheObject);
  }
  cacheFlush(removeThisQueryAlways) {
    this.cache = this.cache.filter((cachedObject, index) => {
      if (index > this.cacheMaxSize) return false;
      if (
        removeThisQueryAlways &&
        _.isEqual(removeThisQueryAlways, cachedObject.query)
      )
        return false;
      return true;
    });
  }
  cacheRefresh() {
    this.cache = [];
  }
}
