export const PIXELLOT_ISSUES = [
  { text: 'Scoreboard not working', value: 'pixellot_issues_scoreboard' },
  { text: 'Camera not tracking', value: 'pixellot_issues_calibration' },
  { text: 'Camera view needs to be adjusted', value: 'pixellot_issues_zoom' },
  { text: 'Picture is out of focus', value: 'pixellot_issues_focus' },
  { text: 'Picture is foggy', value: 'pixellot_issues_condensation' },
  { text: " Broadcast isn't starting", value: 'pixellot_issues_delayed_broadcast' },
  { text: 'Pixellot unit is offline', value: 'pixellot_issues_vpu' },
  { text: 'Audio issue', value: 'pixellot_issues_audio' },
  { text: 'Other', value: 'pixellot_issue-question' }
];

export const MANUAL_BROADCAST_SOFTWARE = [
  { text: 'Producer', value: 'encoder_used_atem' },
  { text: 'Tricaster', value: 'encoder_used_tricaster' },
  { text: 'OBS', value: 'encoder_used_obs' },
  { text: 'LiveStudio', value: 'encoder_used_livestudio' },
  { text: 'Wirecast', value: 'encoder_used_wirecast' },
  { text: 'Other', value: 'encoder_used_other' }
];

export const STEAMING_TYPE_OF_ISSUE = [
  { text: 'Pixellot issue', value: 'issue_category_pixellot' },
  { text: 'Manual broadcast', value: 'issue_category_manual' },
  { text: 'VOD issue', value: 'issue_category_other_production_issue' },
  { text: 'Scheduling issue', value: 'issue_category_other_production_issue' },
  { text: 'Other', value: 'issue_category_other_production_issue' }
];

// the state of the form, it has 3 views/sections
export enum PendoSupportFormProgress {
  DEFAULT,
  STREAMING,
  TICKETING
}

export const TICKETING_TYPE_OF_ISSUE = [
  { text: 'Using HQ', value: 'hq__acct_mgmt' },
  { text: 'Ticketing event management', value: 'hq_event_mgmt' },
  { text: 'Troubleshoot a Box Office issue', value: 'bug__box_office' }
];

export enum ZENDESK_SUPPORT_FORM_FIELDS {
  SUPPORT_DIVISION = 'supportDivision',
  REQUEST_CONTACT_TYPE = 'requestContactType',
  GAME_ID = 'gameId',
  NEXT_EVENT_DATE = 'nextEventDate',
  SCHOOL_NAME = 'schoolName',
  SCHOOL_REQUESTOR_EMAIL = 'schoolRequestorEmail',
  NAME = 'name',
  REQUESTOR_EMAIL = 'requestorEmail',
  PHONE_NUMBER = 'phoneNumber',
  PREFERRED_CONTACT_METHOD = 'preferredContactMethod',
  UPDATE_REQUEST = 'updateRequest',
  URGENT_REQUEST = 'urgentRequest',
  ISSUE_DESCRIPTION = 'issueDescription',
  TYPE_OF_ISSUE_STREAMING = 'typeOfIssueStreaming',
  PIXELLOT_ISSUES = 'pixellotIssues',
  PIXELLOT_LOCATION = 'pixellotLocation',
  ENCODING_BROADCAST_SOFTWARE = 'encodingBroadcastSoftware',
  ATTACHMENTS = 'attachments',
  EVENT_IDS = 'eventIds',
  TYPE_OF_ISSUE_TICKETING = 'typeOfIssueTicketing',
  PLAYOFF_EVENT_TICKETING = 'playoffEventTicketing'
}

export enum REQUEST_CONTACT_TYPES {
  PLAYON = 'playon',
  SCHOOL = 'school'
}

export enum SUPPORT_DIVISION_TYPES {
  TICKETING = 'ticketing',
  STREAMING = 'streaming'
}
