import React from 'react';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

import { StyledFormInput } from './components/styledSemantic';

const Label = styled.label``;
// ---------------------------------------------//
// PageDef => Breadcrumbs
// ---------------------------------------------//
const numberPattern = /\d+/g;

const valueToNumbersOnlyString = raw => {
  if (!raw || raw.length === 0) return '';
  const numberParts = raw.match(numberPattern);
  if (!numberParts || numberParts.size === 0) return '';
  const numberString = numberParts.join('');
  return numberString.substring(0, Math.min(10, numberString.length));
};
const formatAsDisplayString = str => {
  if (str.length === 0) {
    return '';
  }
  if (str.length < 3) {
    return `(${str}`;
  }
  if (str.length === 3) {
    return `(${str}`;
  }
  if (str.length <= 6) {
    return `(${str.substring(0, 3)}) ${str.substring(3, str.length)}`;
  }
  return `(${str.substring(0, 3)}) ${str.substring(3, 6)}-${str.substring(
    6,
    Math.min(10, str.length)
  )}`;
};

export default ({
  value,
  onChange,
  name,
  label = 'Phone Number',
  readOnly = false,
  placeholder = '',
  required = false,
  error = false
}) => {
  const numbersOnlyString = valueToNumbersOnlyString(value);
  const displayString = formatAsDisplayString(numbersOnlyString);

  const onChangeTextH = event => {
    const formValueFormatted = event.target.value;
    const updatedValue = valueToNumbersOnlyString(formValueFormatted);
    onChange(updatedValue);
  };

  return (
    <Form.Field>
      <Label>{label}</Label>
      <StyledFormInput
        type='text'
        name={name}
        error={error}
        readOnly={readOnly}
        value={displayString}
        placeholder={placeholder}
        onChange={onChangeTextH}
        required={required}
      />
    </Form.Field>
  );
};
