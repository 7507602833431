import { VenueRepository } from './venue.repository';
import isEmpty from 'lodash/isEmpty';

import type { VenueDTO, VenueAssociation } from './venue.model';
import type { AccountVenue } from '../accounts/account.model';
import type { VenueTypes } from '@gofan/components';

class VenueService {
  static getVenuesById = (venueIds: Array<number | string>): Promise<VenueDTO[]> => {
    if (isEmpty(venueIds)) return Promise.resolve([]);
    return Promise.all(venueIds.map(venueId => VenueRepository.getVenueById(venueId)));
  };

  static createVenue = (venue: AccountVenue): Promise<AccountVenue> => VenueRepository.createVenue(venue);

  static updateVenue = (venue: AccountVenue) => VenueRepository.updateVenue(venue);

  static removeVenueById = (venueId: number | string) => VenueRepository.removeVenueById(venueId);

  static syncVenueChart = (venueId: number | string) => VenueRepository.syncVenueChart(venueId);

  static getVenueAssociation = (venueId: number | string): Promise<VenueAssociation> =>
    VenueRepository.getVenueAssociation(venueId);

  static getFilteredVenues = ({
    accountVenues,
    districtVenues,
    venueTypes
  }: {
    accountVenues: AccountVenue[];
    districtVenues: AccountVenue[];
    venueTypes: VenueTypes;
  }) => {
    if (!districtVenues?.length) {
      return accountVenues;
    }

    if (venueTypes?.district && venueTypes?.homeSchool) {
      return [
        { label: 'District Venues', disabled: true, isGroupLabel: true },
        ...districtVenues.sort((a, b) => `${a.name}`.localeCompare(`${b.name}`)),
        { label: 'Home School Venues', disabled: true, isGroupLabel: true },
        ...accountVenues.sort((a, b) => `${a.name}`.localeCompare(`${b.name}`))
      ];
    }

    if (venueTypes?.district) {
      return districtVenues.sort((a, b) => `${a.name}`.localeCompare(`${b.name}`));
    }

    return accountVenues.sort((a, b) => `${a.name}`.localeCompare(`${b.name}`));
  };
}

export { VenueService };
