import { css } from 'styled-components';
import common from '../../../assets/styles/common';

const DEFAULT_HEIGHT_TEXT_BOX = 48;

export default {
  formWrapper: css``,
  radioWrapper: css`
  label {
    padding-left: 30px !important;
    
    &:before {
      top: 5px !important;
      border-color: #3b86ff !important;
    }

    &:after {
      top: 5px !important;
      background-color: #3b86ff !important;
    }
  `,
  radio: {
    input: css`
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      margin: 0;
      opacity: 0;
    `,
    round: css`
      position: relative;
      width: 24px;
      height: 24px;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 100%;
        transform: translate(-50%, -50%);
      }

      &:before {
        width: 100%;
        height: 100%;
        border: 2px solid #3b86ff;
      }

      &:after {
        opacity: 0;
        width: 14px;
        height: 14px;
        background: #3b86ff;
        transition: opacity 0.2s ease-in;
      }

      ${({ checked }) =>
        checked &&
        `
          &:after {
            opacity: 1;
          }
        `}

      ${({ disabled }) =>
        disabled &&
        `
          &:before {
            border: 2px solid #cccccc;
          }
          &:after {
            background: #cccccc;
          }
        `}
    `,
    label: css`
      display: flex;
      padding-left: 10px;
      align-items: center;
      cursor: pointer;
      ${common.fieldValueRegular};

      ${({ boldText }) =>
        boldText &&
        css`
          ${common.fieldLabel};
        `}

      ${({ disabled }) =>
        disabled &&
        `
          color: #999999;
        `}
    `,
    radioWrapper: css`
      display: flex;
      cursor: pointer;
    `,
    wrapper: css`
      display: inline-block;
    `
  },
  static: {
    fieldLabelSafeGuard: css`
      ${common.fieldLabel};
    `,
    fieldValue: css`
      ${common.fieldValueRegular};
      font-size: 18px;
      margin: 0px 0px 20px 0px;
      ${props => props.style};
    `
  },
  dropdown: {
    wrapper: css`
      ${({ iconStyles }) =>
        iconStyles &&
        css`
          .ui.selection.dropdown > .dropdown.icon {
            ${iconStyles}
          }
        `}
      ${({ size }) =>
        size === 'small' &&
        css`
          .ui.selection.dropdown {
            font-size: 14px;
            padding: 0.4375em 16px;
            height: 36px;
          }
          .ui.multiple.dropdown {
            padding: 5px 16px;
            height: unset;
            min-height: 36px;
            a {
              margin: 0 4px;
              padding: 5px 10px;
            }
          }
        `};
    `,
    fieldLabel: css`
      ${common.fieldLabel};
    `
  },
  baseInput: {
    fieldLabel: css`
      ${common.fieldLabel};
    `,
    errorMessage: css`
      ${common.errorMessage};
      padding-top: 5px;
    `,
    optionalLabel: css`
      ${common.body};
    `
  },
  submitRow: css`
    margin: 16px auto;
  `,
  datePicker: css`
    height: ${({ customHeight }) =>
      customHeight
        ? `${customHeight}px`
        : `${DEFAULT_HEIGHT_TEXT_BOX}px`} !important;
  `,
  search: {
    formField: css`
      height: ${({ customHeight }) =>
        customHeight
          ? `${customHeight}px`
          : `${DEFAULT_HEIGHT_TEXT_BOX}px`} !important;
    `,
    errorMessage: css`
      ${common.errorMessage};
      margin-top: -11px;
    `
  }
};
