import type { LevelDTO } from '@gofan/api/levels';
import type { EventLandingActionTypes } from './actionTypes';
import {
  RESET_PUBLISH_STATE,
  PREPARE_DATA,
  PREPARE_DATA_COMPLETED,
  SEARCH_EVENTS,
  SEARCH_EVENTS_COMPLETED,
  UPDATE_EVENT_VISIBILITY,
  FETCH_ACTIVITIES_SUCCESSFUL,
  UPDATE_EVENT_VISIBILITY_COMPLETED,
  DELETE_EVENT,
  DELETE_EVENT_COMPLETED,
  FETCH_EVENT_SALES_INFO,
  FETCH_EVENT_SALES_INFO_COMPLETED,
  FETCH_ACCOUNTS_BY_IDS_COMPLETED,
  CLEAR_EVENT_TABLE,
  ON_REQUEST_TICKET_DISTRIBUTION_FOR_EVENTS,
  ON_REQUEST_TICKET_DISTRIBUTION_FOR_EVENTS_COMPLETED,
  CLEAR_DATA_CREATED_LINKS,
  FETCH_ALL_LEVELS_COMPLETED
} from './actionTypes';
import type { SearchEventParams } from './types';
import type { Activity } from '../Activities/types';

export const resetPublishState = (): EventLandingActionTypes => ({
  type: RESET_PUBLISH_STATE
});

export const prepareData = ({
  accountId = '',
  history = {},
  currentUser = {},
  searchParams = {},
  enableDistrictUnicorn = false
}): EventLandingActionTypes => ({
  type: PREPARE_DATA,
  payload: {
    history,
    accountId,
    currentUser,
    searchParams,
    enableDistrictUnicorn
  }
});

export const prepareDataCompleted = (error): EventLandingActionTypes => ({
  type: PREPARE_DATA_COMPLETED,
  error
});

export const searchEvents = (searchParams: SearchEventParams): EventLandingActionTypes => ({
  type: SEARCH_EVENTS,
  payload: {
    searchParams
  }
});

export const searchEventsCompleted = ({ error, searchResult = {} }): EventLandingActionTypes => ({
  type: SEARCH_EVENTS_COMPLETED,
  payload: {
    searchResult
  },
  error
});

export const updateEventVisibility = ({ event = {}, archived = false }): EventLandingActionTypes => ({
  type: UPDATE_EVENT_VISIBILITY,
  payload: {
    event,
    archived
  }
});

export const updateEventVisibilityCompleted = ({ error, events = [] }): EventLandingActionTypes => ({
  type: UPDATE_EVENT_VISIBILITY_COMPLETED,
  payload: {
    events
  },
  error
});

export const deleteEvent = ({ eventId = '', globalEventsId = '', searchParams = {} }): EventLandingActionTypes => ({
  type: DELETE_EVENT,
  payload: {
    eventId,
    globalEventsId,
    searchParams
  }
});

export const clearEventTable = () => ({
  type: CLEAR_EVENT_TABLE
});

export const deleteEventCompleted = ({ error = '' }): EventLandingActionTypes => ({
  type: DELETE_EVENT_COMPLETED,
  payload: {},
  error
});

export const fetchEventSalesInfo = ({ eventId = '' }): EventLandingActionTypes => ({
  type: FETCH_EVENT_SALES_INFO,
  payload: {
    eventId
  }
});

export const fetchEventSalesInfoCompleted = ({ error = '', events = [] }): EventLandingActionTypes => ({
  type: FETCH_EVENT_SALES_INFO_COMPLETED,
  payload: { events },
  error
});

export function fetchActivitiesSuccessful(
  athleticActivities: Activity[],
  nonAthleticActivities: Activity[]
): EventLandingActionTypes {
  return {
    type: FETCH_ACTIVITIES_SUCCESSFUL,
    payload: {
      athleticActivities,
      nonAthleticActivities
    }
  };
}

export const fetchAccountsByIdsCompleted = ({
  isDistrictUser = false,
  schoolConfig = undefined,
  districtAccount = undefined,
  selectionAccounts = [],
  associatedAccounts = [],
  editableAccounts = [],
  haveAccountActiveContext = false
}): EventLandingActionTypes => ({
  type: FETCH_ACCOUNTS_BY_IDS_COMPLETED,
  payload: {
    schoolConfig,
    isDistrictUser,
    districtAccount,
    selectionAccounts,
    associatedAccounts,
    editableAccounts,
    haveAccountActiveContext
  }
});

export const onRequestTicketDistributionForEvents = (events = [], searchParams = {}) => ({
  type: ON_REQUEST_TICKET_DISTRIBUTION_FOR_EVENTS,
  payload: {
    events,
    searchParams
  }
});

export const onRequestTicketDistributionForEventsCompleted = () => ({
  type: ON_REQUEST_TICKET_DISTRIBUTION_FOR_EVENTS_COMPLETED
});

export function fetchAllLevelsCompleted(levels: LevelDTO[]): EventLandingActionTypes {
  return {
    type: FETCH_ALL_LEVELS_COMPLETED,
    payload: {
      levels
    }
  };
}

export const clearDataCreatedLinks = () => ({
  type: CLEAR_DATA_CREATED_LINKS
});

export default {
  resetPublishState,
  prepareData,
  prepareDataCompleted,
  searchEvents,
  searchEventsCompleted,
  updateEventVisibility,
  deleteEvent,
  clearEventTable,
  deleteEventCompleted,
  fetchEventSalesInfo,
  fetchEventSalesInfoCompleted,
  updateEventVisibilityCompleted,
  fetchActivitiesSuccessful,
  fetchAccountsByIdsCompleted,
  onRequestTicketDistributionForEvents,
  onRequestTicketDistributionForEventsCompleted,
  fetchAllLevelsCompleted,
  clearDataCreatedLinks
};
