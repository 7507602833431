import React from 'react';
import BaseInput from './BaseInput';
import FormSafeguard from './FormSafeguard';
import { urlValidation } from '../../validations';
import errorMessage from '../../errorMessages';

export default ({
  label,
  formKey,
  required,
  overwritable,
  maxLength = null
}) => (
  <FormSafeguard formKey={formKey}>
    <BaseInput
      formKey={formKey}
      label={label}
      overwritable={overwritable}
      required={required}
      maxLength={maxLength}
      validation={urlValidation}
      errorMessage={errorMessage.URL_ERROR_MESSAGE}
    />
  </FormSafeguard>
);
