import { useRef, useMemo, useState, useEffect, useContext, useCallback } from 'react';
import { uniq, uniqBy, isEmpty, isEqual } from 'lodash';
import { Row, Grid, Column, SkeletonText, SkeletonPlaceholder } from 'carbon-components-react';

import { FEATURE_FLAGS, useFeatureFlags } from '@gofan/hooks/useFeatureFlags';
import { config, DISTRICT_CONFERENCE_SCHOOL_TYPES } from '@gofan/constants';
import { PAGES } from '@config/routes';
import { RootContext } from '@app/RootContext';
import { generateError } from '@utils/alertUtils';
import {
  getCachedDashboardSelectedAccounts,
  getCachedDashboardActiveAccounts,
  getCachedEventFilters,
  cacheDashboardSelectedAccounts,
  cacheDashboardActiveAccounts
} from '@events/utils/local-storage-cache.utils';

import {
  canEdit,
  canAccessEventByDistrictUser,
  isInternalUser as checkInternalUser,
  getActiveUserAccountContext,
  getEditedUserAccountContext,
  isEditableComissioner
} from '@gofan/api/users';

import { useDistrictSchoolConfig } from '@accounts/v2/hooks/useDistrictSchoolConfig';
import type { AccountDTO } from '@gofan/api/accounts';
import { AccountService } from '@gofan/api/accounts';
import { ActivityService, useActivities } from '@gofan/api/activities';
import type { ActivityDTO } from '@gofan/api/activities';
import { ACCOUNT_STATUS } from '@app/modules/accounts/constants/constants';
import { getSchoolImage } from '@app/pages/EventInformationV2/helpers';

import { PageTitle } from '@components/PageTitle';
import SectionInfo from '@accounts/components/section-info/section-info.component';

import type { DashboardModuleProps } from '@dashboard/dashboard.container';

import { STATES_OF_HIGH_RISK_MARKET } from '@modules/event-integrations_V2/constants/constants';

import type { LevelDTO } from '@gofan/api/levels';
import { LevelService, useLevels } from '@gofan/api/levels';
import { RateDTO, useMultipleRates } from '@gofan/api/rates';
import DashboardContent from './sections/dashboard-content/dashboard-content.component';
import EventMonitor from './sections/event-monitor/event-monitor.component';
import GoFanPage from './sections/gofan-page/gofan-page.component';
import QuickStart from './sections/quick-start/quick-start.component';
import SchoolSelection from './sections/school-selection/school-selection.component';

import SalesReports from './sections/sales-reports/sales-reports.component';

// 'GA7250'
export const DEFAULT_ACCOUNT = 'GA72550';
export const DEFAULT_ACCOUNT_NAME = 'GoFan';
export const DASHBOARD_TABLE_OFFSET = 240;

const getInititalAccounts = (user: any, isInternalUser: boolean): string[] => {
  if (isInternalUser) {
    const cachedAccounts = getCachedDashboardActiveAccounts() || [
      {
        id: DEFAULT_ACCOUNT,
        name: DEFAULT_ACCOUNT_NAME
      }
    ];
    const selectedAccountIds = cachedAccounts.map(({ id }) => id);
    cacheDashboardActiveAccounts(cachedAccounts);
    return selectedAccountIds;
  }
  const initialAccounts = uniq(
    getActiveUserAccountContext(user).map((context: any) => ({
      id: context.accountId,
      name: context.accountName
    }))
  );
  const initialAccountIds = initialAccounts.map(({ id }) => id);
  cacheDashboardActiveAccounts(initialAccounts);
  return initialAccountIds;
};

const getActiveAccounts = (accounts: AccountDTO[]) =>
  (accounts ?? [])?.filter(acc => acc.id === DEFAULT_ACCOUNT || acc.status === ACCOUNT_STATUS.ACTIVE);

const searchAccountsMapping = (accounts: AccountDTO[]) =>
  (accounts ?? [])
    .filter(item => item.id === DEFAULT_ACCOUNT || item.status === ACCOUNT_STATUS.ACTIVE)
    .map((item: AccountDTO) => ({
      ...item,
      icon: isEmpty(item.logo) ? '' : getSchoolImage(item.id, 'logo', item.logo)
    }));

const Dashboard = ({
  history,
  addNotification,
  showAccountInfo,
  updateSelectedSchools,
  setDataToSetupMultiSeason,
  resetSeasonManagementState
}: DashboardModuleProps) => {
  const {
    [FEATURE_FLAGS.tempDistrictConferenceUnicorn]: enableDistrictUnicorn,
    [FEATURE_FLAGS.enableConferenceCommissionerFeatures]: enableConferenceCommissionerFeatures
  } = useFeatureFlags();
  const { currentUser } = useContext(RootContext);
  const isUnmounted = useRef<boolean>(false);
  const [prepare, setPrepare] = useState<boolean>(false);
  const [isInitialData, setIsInitialData] = useState<boolean>(false);
  const [isAccountUser, setIsAccountUser] = useState<boolean>(false);
  const [isDistrictUser, setIsDistrictUser] = useState<boolean>(false);
  const [resizeDashboard, setResizeDashboard] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<AccountDTO[]>([]);
  const [selectionAccounts, setSelectionAccounts] = useState<any[]>();
  const [districtAccount, setDistrictAccount] = useState<AccountDTO>();
  const [activeAccountList, setActiveAccountList] = useState<AccountDTO[]>([]);
  const [bulkUploadData, setBulkUploadData] = useState<any>({});

  const {
    isLoaded: isDistrictSchoolConfigLoaded,
    schoolsConfig,
    underDistrictSchools
  } = useDistrictSchoolConfig({
    districtAccount: districtAccount as AccountDTO
  });

  const activitiesQuery = useActivities();
  const activities: ActivityDTO[] = useMemo(() => {
    const { athleticActivities = [], nonAthleticActivities = [] } = ActivityService.groupActivities(
      activitiesQuery.data ?? []
    );
    return ActivityService.bubbleUpCommonSports(
      [...athleticActivities, ...nonAthleticActivities].sort((a, b) => a.label.localeCompare(b.label))
    );
  }, [activitiesQuery.data]);

  const levelsQuery = useLevels();
  const levels: LevelDTO[] = useMemo(() => LevelService.filterAvailableLevels(levelsQuery.data), [levelsQuery.data]);

  const { queries: rateQueries, isLoading: ratesIsLoading } = useMultipleRates({
    rateIds: [config.RATE_ID1, config.RATE_ID5, config.RATE_ID8]
  });
  const rates =
    Array.isArray(rateQueries) && rateQueries.length > 0 ? rateQueries.map(rateQuery => rateQuery.data) : [];

  const isLoading = activitiesQuery.isLoading || levelsQuery.isLoading || ratesIsLoading;

  const isLoaded = useMemo(() => {
    if (!prepare || isLoading) return false;
    if (isDistrictUser) return isDistrictSchoolConfigLoaded;
    return true;
  }, [prepare, isLoading, isDistrictUser, isDistrictSchoolConfigLoaded]);

  let accountIds = accounts?.filter(acc => !STATES_OF_HIGH_RISK_MARKET.includes(acc?.state)).map(account => account.id);
  const aiaAccountIds = accounts
    ?.filter(acc => !STATES_OF_HIGH_RISK_MARKET.includes(acc?.state) || acc?.state === 'AZ')
    .map(account => account.id);

  // dragonfly events were never filtered by high risk market
  // so we include all accounts in the dragonfly events
  const dragonFlyAccountIds = accounts?.map(acc => acc.id);

  const canDisplayEventMonitor = !isEmpty(accountIds) || !isEmpty(aiaAccountIds) || !isEmpty(dragonFlyAccountIds);
  accountIds = accounts.map(ac => ac.id);

  const isInternalUser = useMemo(() => !isEmpty(currentUser) && checkInternalUser(currentUser), [currentUser]);

  const editedAccounts = useMemo(() => {
    if (isInternalUser) return accounts;
    const contexts = getEditedUserAccountContext(currentUser);
    return accounts.filter(
      acc =>
        contexts.find((context: any) => context.accountId === acc.id) ||
        canEdit(acc.id, currentUser) ||
        (enableConferenceCommissionerFeatures && isEditableComissioner(currentUser, acc.districtHuddleId)) ||
        (enableDistrictUnicorn &&
          canAccessEventByDistrictUser({
            currentUser,
            schoolsConfig,
            accountId: acc?.id
          }))
    );
  }, [currentUser, accounts, isInternalUser, schoolsConfig]);

  const venues = useMemo(() => {
    if (isEmpty(selectionAccounts) || !isLoaded) return [];
    const listVenues = selectionAccounts
      ?.map((acc: AccountDTO) => acc.venues ?? [])
      ?.flat()
      ?.filter(item => !isEmpty(item));
    return uniqBy(listVenues, 'id');
  }, [selectionAccounts]);

  const handleSetAccounts = useCallback(
    (accs: AccountDTO[] = []) => {
      const activeAccounts: AccountDTO[] = getActiveAccounts(accs);
      cacheDashboardSelectedAccounts(activeAccounts.map(({ id, name }) => ({ id, name })));
      updateSelectedSchools(activeAccounts);
      setAccounts(activeAccounts);
      setBulkUploadData({});
    },
    [accounts]
  );

  useEffect(() => {
    if (
      isInitialData ||
      !enableDistrictUnicorn ||
      isEmpty(districtAccount) ||
      isEmpty(underDistrictSchools) ||
      !isDistrictSchoolConfigLoaded
    ) {
      return;
    }

    const newList = AccountService.getGroupedAccounts({
      initialSchools: activeAccountList,
      associatedSchools: underDistrictSchools,
      districtHuddleId: districtAccount?.id
    });

    const newListIds = uniq(newList ?? [])
      .map(item => `${item.id}`)
      .toSorted((a, b) => a?.localeCompare(b, 'en'));
    const selectionAccountIds = uniq(selectionAccounts ?? [])
      .map(item => `${item.id}`)
      .toSorted((a, b) => a?.localeCompare(b, 'en'));

    if (!isEqual(newListIds, selectionAccountIds)) {
      setSelectionAccounts(newList);
      _onMappingSelectedAccounts(newList as AccountDTO[]);
      setIsInitialData(true);
    }
  }, [
    isInitialData,
    activeAccountList,
    selectionAccounts,
    districtAccount,
    underDistrictSchools,
    isDistrictUser,
    isDistrictSchoolConfigLoaded
  ]);

  useEffect(() => {
    showAccountInfo(undefined);
    const initialAccounts = getInititalAccounts(currentUser, isInternalUser);
    Promise.all(initialAccounts.map(accId => AccountService.getAccountById(accId, true)))
      .then((res: AccountDTO[]) => {
        if (isUnmounted.current) return;

        const activeAccounts = searchAccountsMapping(res);

        if (activeAccounts.length === 1 && !isInternalUser) {
          showAccountInfo(activeAccounts[0]);
        }

        const newActiveAccounts = _onMappingDistrictData(activeAccounts);

        _onMappingSelectedAccounts(newActiveAccounts);
      })
      .catch((err: any) => !isUnmounted.current && addNotification(generateError(err)))
      .finally(() => !isUnmounted.current && setPrepare(true));

    return () => {
      isUnmounted.current = true;
      showAccountInfo(undefined);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const height = document.getElementById('dashboard-sidebar')?.getBoundingClientRect()?.height ?? 0;
      document.body.style.setProperty('--heightTableDashboard', `${height - DASHBOARD_TABLE_OFFSET}px`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [prepare, accounts, resizeDashboard]);

  return (
    <Grid
      className='dashboard gs--padding-bottom__sp5 dashboard-custom'
      style={{ maxWidth: '100%', paddingBottom: 180 }}
    >
      <Row className='gs--page-title__row-padding'>
        <Column>
          <PageTitle title={PAGES.dashboard.root.title} />
        </Column>
      </Row>
      {isLoaded ? (
        <Row className='row-custom'>
          <Column md={2} lg={3} xlg={3} style={{ height: 'fit-content' }} id='dashboard-sidebar'>
            <Row>
              <Column className='gs--padding-top__sp5'>
                <SchoolSelection
                  accounts={accounts}
                  selectionAccounts={selectionAccounts as AccountDTO[]}
                  isInternalUser={isInternalUser}
                  isAccountUser={isAccountUser}
                  isDistrictUser={isDistrictUser}
                  setAccounts={handleSetAccounts}
                  setSeletionAccounts={_handleSetSelectionAccounts}
                  addNotification={addNotification}
                />
              </Column>
            </Row>
            {canDisplayEventMonitor && (
              <EventMonitor
                history={history}
                currentUser={currentUser}
                addNotification={addNotification}
                accountIds={accountIds}
                accounts={accounts}
                districtAccount={districtAccount}
                schoolsConfig={schoolsConfig}
              />
            )}
            <Row>
              <Column className='gs--padding-top__sp5'>
                <QuickStart
                  history={history}
                  accounts={editedAccounts}
                  isInternalUser={isInternalUser}
                  currentUser={currentUser}
                  activities={activities}
                  levels={levels}
                  setDataToSetupMultiSeason={setDataToSetupMultiSeason}
                  resetSeasonManagementState={resetSeasonManagementState}
                  selectionAccounts={selectionAccounts as AccountDTO[]}
                  setBulkUploadData={setBulkUploadData}
                  addNotification={addNotification}
                />
              </Column>
            </Row>
            {!config.TEMP_HIDE_PAYMENT_REPORT_FEATURE && (
              <Row>
                <Column className='gs--padding-top__sp5'>
                  <SalesReports
                    resizeDashboard={() => {
                      setResizeDashboard(prevResizeDashboard => !prevResizeDashboard);
                    }}
                    accounts={accounts}
                    addNotification={addNotification}
                  />
                </Column>
              </Row>
            )}
            <Row>
              <Column className='gs--padding-top__sp5'>
                <SectionInfo title=''>
                  <GoFanPage accounts={accounts} />
                </SectionInfo>
              </Column>
            </Row>
          </Column>
          <Column md={6} lg={9} xlg={9} className='gs--padding-top__sp5'>
            <Row>
              <Column>
                <DashboardContent
                  history={history}
                  accounts={accounts}
                  activities={activities}
                  levels={levels}
                  venues={venues as any[]}
                  schoolsConfig={schoolsConfig}
                  districtAccount={districtAccount}
                  rates={rates as RateDTO[]}
                  isInternalUser={isInternalUser}
                  addNotification={addNotification}
                  bulkUploadData={bulkUploadData}
                  setBulkUploadData={setBulkUploadData}
                />
              </Column>
            </Row>
          </Column>
        </Row>
      ) : (
        <Row>
          <Column className='page-loading'>
            <SkeletonText />
            <SkeletonPlaceholder />
          </Column>
        </Row>
      )}
    </Grid>
  );

  function _handleSetSelectionAccounts(listAccount: AccountDTO[] = []) {
    if (!isEmpty(listAccount)) {
      setSelectionAccounts(listAccount);
      if (isInternalUser) {
        const activeAccounts = getActiveAccounts(listAccount);
        cacheDashboardActiveAccounts(activeAccounts.map(({ id, name }) => ({ id, name })));
      }
    }
  }

  function _onMappingDistrictData(activeAccounts: AccountDTO[]) {
    const accList = uniqBy(activeAccounts, 'id');

    setActiveAccountList(accList);

    if (isInternalUser) {
      setSelectionAccounts(accList);
      return accList;
    }

    const gfDistrictAcc = accList?.find(
      acc => acc.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT
    );

    if (!enableDistrictUnicorn || isEmpty(gfDistrictAcc)) {
      setIsAccountUser(true);
      setSelectionAccounts(accList);
      return accList;
    }

    const newList = AccountService.getGroupedAccounts({
      initialSchools: accList,
      associatedSchools: [],
      districtHuddleId: gfDistrictAcc?.id
    });

    setIsDistrictUser(true);
    setSelectionAccounts(newList);
    setDistrictAccount(gfDistrictAcc);
    return newList as AccountDTO[];
  }

  function _onMappingSelectedAccounts(activeAccounts: AccountDTO[]) {
    const selectedAccountsCache = getCachedDashboardSelectedAccounts() || [];
    const selectedAccountIdsCache = selectedAccountsCache.map(({ id }) => id);
    const eventFiltersCache = getCachedEventFilters(isInternalUser ? 'dashboard' : 'shared') || {};
    const accountFilterIdsCache = (eventFiltersCache.accounts || []).map(({ id }) => id);
    const initialSelectedAccountIds = uniq([...selectedAccountIdsCache, ...accountFilterIdsCache]);
    const selectedSchools = isEmpty(initialSelectedAccountIds)
      ? activeAccounts?.filter(account => !account.disabled)
      : activeAccounts?.filter(account => initialSelectedAccountIds.includes(account.id) && !account.disabled);
    setAccounts(selectedSchools);
    updateSelectedSchools(selectedSchools);
  }
};

export default Dashboard;
