import { connect } from 'react-redux';
import Header from './Header';
import { removeNotification } from '../../actions';

const mapStateToProps = state => ({
  notifications: state.rootV2.notifications,
  account: state.rootV2.account
});

export default connect(
  mapStateToProps,
  { removeNotification }
)(Header);
