import { huddleAxiosInstanceProxy } from '../shared/axios';

import {
  SEASON_RENEWAL_FAN_EXPORT,
  CREATE_SEASON_RENEWAL,
  UPDATE_SEASON_RENEWAL_BY_ID,
  GET_SEASON_RENEWAL_SEATS,
  UPDATE_SEASON_RENEWAL_FAN,
  DELETE_SEASON_RENEWAL_FAN,
  GET_SEASON_RENEWALS
} from './season-renewal.endpoints';

import type { SeasonRenewalFanDTO } from '@gofan/api';
import type {
  SeasonRenewalFanExportRequest,
  SeasonRenewalFanExportResponse,
  SeasonRenewalDTO,
  SeasonRenewalUploadDTO,
  SeasonRenewalSeatsDTO
} from './season-renewal.model';

export class SeasonRenewalRepository {
  static getSeasonRenewalFanExportData = (
    seasonRenewalFanExportRequest: SeasonRenewalFanExportRequest
  ): Promise<SeasonRenewalFanExportResponse> =>
    huddleAxiosInstanceProxy.post<SeasonRenewalFanExportResponse, SeasonRenewalFanExportRequest>(
      SEASON_RENEWAL_FAN_EXPORT,
      seasonRenewalFanExportRequest,
      undefined,
      true
    );

  static createSeasonRenewal = (payload: SeasonRenewalUploadDTO) =>
    huddleAxiosInstanceProxy.post<SeasonRenewalDTO, SeasonRenewalUploadDTO>(CREATE_SEASON_RENEWAL, payload, undefined);

  static updateSeasonRenewal = (payload: SeasonRenewalDTO): Promise<SeasonRenewalDTO> =>
    huddleAxiosInstanceProxy.put<SeasonRenewalDTO>(
      `${UPDATE_SEASON_RENEWAL_BY_ID}`.replace('{id}', `${payload.id}`),
      payload,
      undefined,
      true
    );

  static getSeasonRenewalSeats = (seasonId: number) =>
    huddleAxiosInstanceProxy.get<SeasonRenewalSeatsDTO>(GET_SEASON_RENEWAL_SEATS, { params: { seasonId } }, true);

  static updateSeasonRenewalFan = (payload: SeasonRenewalFanDTO) =>
    huddleAxiosInstanceProxy.put<SeasonRenewalFanDTO>(
      UPDATE_SEASON_RENEWAL_FAN.replace('{id}', `${payload.id}`),
      payload,
      undefined,
      true
    );

  static deleteSeasonRenewalFan = (payload: SeasonRenewalFanDTO) =>
    huddleAxiosInstanceProxy.delete<SeasonRenewalFanDTO>(
      DELETE_SEASON_RENEWAL_FAN.replace('{id}', `${payload.id}`),
      undefined,
      true
    );

  static getSeasonRenewals = (seasonId: number) =>
    huddleAxiosInstanceProxy.get<SeasonRenewalDTO[]>(GET_SEASON_RENEWALS, { params: { seasonId } }, true);
}
