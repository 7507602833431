import { css } from 'styled-components';

export default {
  tableCell: css`
    min-width: 2.5rem;
    ${props =>
      props.ellipsis &&
      css`
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 15rem;

        > div {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        > a.bx--link {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `}

    &.bx--table-column-menu {
      width: 2.5rem;
      padding: 0;
    }
  `
};
