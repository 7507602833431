// @flow

class PaginationModel {
  constructor({ data, pageSize, pageIndex, pageCount, totalElements }) {
    this.raw = {
      data,
      pageSize,
      pageIndex,
      pageCount,
      totalElements
    };
  }

  get data() {
    return this.raw.data || [];
  }

  set data(content = []) {
    this.raw.data = content;
  }

  get pageIndex() {
    return this.raw.pageIndex || 0;
  }

  set pageIndex(index = 0) {
    this.raw.pageIndex = index;
  }

  get pageSize() {
    return this.raw.pageSize || 10;
  }

  set pageSize(size = 10) {
    this.raw.pageSize = size;
  }

  get pageCount() {
    return this.raw.pageCount || 1;
  }

  set pageCount(count = 1) {
    this.raw.pageCount = count;
  }

  get totalElements() {
    return this.raw.totalElements || 0;
  }

  set totalElements(totalElements = 0) {
    this.raw.totalElements = totalElements;
  }

  toJSON() {
    return {
      data: this.data,
      pageSize: this.pageSize,
      pageIndex: this.pageIndex,
      pageCount: this.pageCount,
      totalElements: this.totalElements
    };
  }
}

export default PaginationModel;
