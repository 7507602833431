import React from 'react';
import styled from 'styled-components';
import { Table } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import styles from './styles';
import { SPECIFIC_COLUMN } from '../../constants';

const NoResultsFound = styled.div`
  ${styles.noResultsFound};
`;

const getActionCellProps = (column = {}, hasActionButton = false) =>
  hasActionButton && column.id === SPECIFIC_COLUMN.ACTION_BUTTON.id ? { className: 'action-button-cell' } : {};

function TableBody({
  noResults,
  tableInstance,
  hasPagination,
  hasActionButton,
  hasGroupSelection,
  noResultsMessage,
  determineHideCell,
  determineBackgroundColorRow,
  renderSubComponent
}) {
  const { getTableBodyProps, page, rows, prepareRow, visibleColumns } = tableInstance;
  const data = hasPagination ? page : rows;
  return (
    <Table.Body {...getTableBodyProps()}>
      {data.map(row => {
        prepareRow(row);
        const rowProps = row.getRowProps();
        const originalValues = !isEmpty(row.original) ? row.original : {};
        const isGroupLabel = hasGroupSelection && originalValues.isGroupLabel;

        return (
          <React.Fragment key={rowProps.key}>
            <Table.Row style={{ backgroundColor: determineBackgroundColorRow(row) }} {...rowProps}>
              {isGroupLabel ? (
                <Table.Cell colSpan={16} className='group-label-cell'>
                  {originalValues.name}
                </Table.Cell>
              ) : (
                <>
                  {row.cells.map(cell => (
                    <Table.Cell
                      {...cell.getCellProps({ className: cell.column.className })}
                      {...getActionCellProps(cell.column, hasActionButton)}
                    >
                      {determineHideCell(cell, originalValues) ? '' : cell.render('Cell')}
                    </Table.Cell>
                  ))}
                </>
              )}
            </Table.Row>
            {row.isExpanded && renderSubComponent({ row, rowProps, visibleColumns })}
          </React.Fragment>
        );
      })}
      {noResults ? (
        <Table.Row>
          <Table.Cell colSpan={16} className='no-results-wrapper'>
            <NoResultsFound>{noResults || noResultsMessage}</NoResultsFound>
          </Table.Cell>
        </Table.Row>
      ) : null}
    </Table.Body>
  );
}

TableBody.defaultProps = {
  noResults: false,
  hasPagination: false,
  hasActionButton: false,
  hasGroupSelection: false,
  noResultsMessage: 'No results found.',
  determineHideCell: () => false,
  determineBackgroundColorRow: () => null,
  tableInstance: {
    rows: [],
    page: [],
    prepareRow: () => null,
    getTableBodyProps: () => null
  }
};

export default TableBody;
