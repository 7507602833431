import moment from 'moment-timezone';
import memoizeOne from 'memoize-one';
import { isEmpty, lowerCase } from 'lodash';

export const DATE_PICKER_FORMAT = 'MM/DD/YYYY';
export const DATE_PICKER_VALUE_FORMAT = 'MM-DD-YYYY';

export const DATE_WITH_TIME_ZONE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.sss';
export const DATE_FORMAT = 'MMM DD, YYYY';

export const EVENT_DATE_FORMAT = 'YYYY-MM-DD';
export const RATE_DATE_FORMAT = 'MM-DD-YYYY';
export const MONTH_YEAR_FORMAT = 'MMM YYYY';

export const EVENT_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.sss';
export const EVENT_DATE_FORMAT_TIMEZONE = 'YYYY-MM-DDTHH:mm:ss';

export const DATE_TIME_PICKER_FORMAT_DEFAULT = 'MM/DD/YYYY hh:mm a';
export const EVENT_REFUND_DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm a';

export const DATE_FORMAT_DEFAULT = 'YYYY-MM-DDTHH:mm';
export const TIME_FORMAT = 'hh:mm A';
export const SHORT_TIME_FORMAT = 'h:mm A';
export const PURCHASE_DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm A';
export const LONG_TIME_FORMAT = 'HH:mm';
export const LONG_TIME_FORMAT_WITH_SECOND = 'HH:mm:ss';
export const DATE_LONG_FORMAT = 'MMMM DD, YYYY';
export const UPDATED_AFTER_FORMAT = 'yyyy-MM-DD HH:mm:ss';
export const UPDATE_AFTER_FORMAT_TIMEZONE = 'yyyy-MM-DDTHH:mm:ssZZ';
export const FAN_DATE_FORMAT = 'MMM-D-YYYY';
export const DATE_DUPLICATE_EVENT = 'MMM-DD-YYYY';
export const FULLDATE_FORMAT = 'ddd, MMM DD, YYYY';
export const FULLDATE_FORMAT_WITH_TIME = 'ddd, MMM DD, YYYY hh:mm A';
export const TICKET_DATE_FORMAT = 'MMM DD, YYYY';
export const EVENT_DATE_FORMAT_WITH_TIMEZONE = 'YYYY-MM-DDTHH:mm:ssZZ';
export const ADJUSTMENT_DATE_FORMAT = 'ddd-MMM-DD-YYYY';
export const MATCH_SCORE_DATE_TIME_FORMAT = 'ddd MM-DD-YYYY hh:mm A';
export const MATCH_SCORE_CORRECTED_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss.ssssss';

export const ACCESS_CODE_DATETIME_FORMAT = 'M/D/YYYY H:mm';
export const TIME_ZONE_ENUM = {
  AMERICA_CHICAGO: 'America/Chicago',
  AMERICA_DENVER: 'America/Denver',
  AMERICA_LOS_ANGELES: 'America/Los_Angeles',
  AMERICA_NEW_YORK: 'America/New_York',
  AMERICA_PHOENIX: 'America/Phoenix',
  AMERICA_INDIANA: 'America/Indiana/Indianapolis',
  ALASKA: 'America/Juneau',
  HAWAII: 'Pacific/Honolulu'
};

export const TIME_ZONE_MAPPING = {
  [lowerCase(TIME_ZONE_ENUM.AMERICA_NEW_YORK)]: TIME_ZONE_ENUM.AMERICA_NEW_YORK,
  [lowerCase('AmericaNew_York')]: TIME_ZONE_ENUM.AMERICA_NEW_YORK,
  [lowerCase('Eastern Time (US & Canada)')]: TIME_ZONE_ENUM.AMERICA_NEW_YORK,
  [lowerCase('EST')]: TIME_ZONE_ENUM.AMERICA_NEW_YORK,
  [lowerCase('Eastern')]: TIME_ZONE_ENUM.AMERICA_NEW_YORK,

  [lowerCase(TIME_ZONE_ENUM.AMERICA_CHICAGO)]: TIME_ZONE_ENUM.AMERICA_CHICAGO,
  [lowerCase('AmericaChicago')]: TIME_ZONE_ENUM.AMERICA_CHICAGO,
  [lowerCase('Central Time (US & Canada)')]: TIME_ZONE_ENUM.AMERICA_CHICAGO,
  [lowerCase('Central')]: TIME_ZONE_ENUM.AMERICA_CHICAGO,

  [lowerCase(TIME_ZONE_ENUM.AMERICA_INDIANA)]: TIME_ZONE_ENUM.AMERICA_INDIANA,
  [lowerCase('Indiana (East)')]: TIME_ZONE_ENUM.AMERICA_INDIANA,

  [lowerCase(TIME_ZONE_ENUM.AMERICA_DENVER)]: TIME_ZONE_ENUM.AMERICA_DENVER,
  [lowerCase('AmericaDenver')]: TIME_ZONE_ENUM.AMERICA_DENVER,
  [lowerCase('Mountain Time (US & Canada)')]: TIME_ZONE_ENUM.AMERICA_DENVER,
  [lowerCase('Mountain')]: TIME_ZONE_ENUM.AMERICA_DENVER,

  [lowerCase(TIME_ZONE_ENUM.AMERICA_LOS_ANGELES)]: TIME_ZONE_ENUM.AMERICA_LOS_ANGELES,
  [lowerCase('AmericaLos_Angeles')]: TIME_ZONE_ENUM.AMERICA_LOS_ANGELES,
  [lowerCase('Pacific Time (US & Canada)')]: TIME_ZONE_ENUM.AMERICA_LOS_ANGELES,
  [lowerCase('Pacific')]: TIME_ZONE_ENUM.AMERICA_LOS_ANGELES,

  [lowerCase(TIME_ZONE_ENUM.AMERICA_PHOENIX)]: TIME_ZONE_ENUM.AMERICA_PHOENIX,
  [lowerCase('Arizona')]: TIME_ZONE_ENUM.AMERICA_PHOENIX,

  [lowerCase(TIME_ZONE_ENUM.ALASKA)]: TIME_ZONE_ENUM.ALASKA,
  [lowerCase('Alaska')]: TIME_ZONE_ENUM.ALASKA,

  [lowerCase(TIME_ZONE_ENUM.HAWAII)]: TIME_ZONE_ENUM.HAWAII,
  [lowerCase('Hawaii')]: TIME_ZONE_ENUM.HAWAII
};

export const getTimeZone = (timeZone = '') => TIME_ZONE_MAPPING[lowerCase(timeZone)] || TIME_ZONE_ENUM.AMERICA_NEW_YORK;

export const formatDate = ({ isUtc, date, defaultValue = '', format = DATE_FORMAT_DEFAULT }) => {
  if (date && moment.isDate(date)) {
    const dateObj = moment(date);
    return dateObj.isValid() ? dateObj.format(format) : defaultValue;
  }
  if (isEmpty(date)) return defaultValue;
  const mDate = isUtc ? moment(date).utc() : moment(date);
  return mDate.isValid() ? mDate.format(format) : defaultValue;
};

export const convertUTCToESTTime = (strInputTime, isWithAt) => {
  const inputDate = moment.utc(strInputTime).tz('America/New_York');
  const day = inputDate.format('DD');
  const monthInYear = inputDate.format('MMMM');
  const year = inputDate.format('YYYY');
  const time = inputDate.format('h:mm A');
  return `${monthInYear}, ${day}, ${year}${isWithAt ? ` at` : ''} ${time} EST`;
};

export const normalizeUTCTime = memoizeOne((strInputTime = '', isWithAt = false, toESTTime = false, dateTimeFormat) => {
  const inputDate = toESTTime ? moment.utc(strInputTime).tz('America/New_York') : moment.utc(strInputTime);
  const defaultDateTimeFormat = {
    year: 'YYYY',
    monthInYear: 'MMMM',
    day: 'DD',
    time: 'hh:mm A',
    tzLabel: '',
    ...dateTimeFormat
  };

  const day = inputDate.format(defaultDateTimeFormat.day);
  const monthInYear = inputDate.format(defaultDateTimeFormat.monthInYear);
  const year = inputDate.format(defaultDateTimeFormat.year);
  const date = `${monthInYear} ${day}, ${year}`;
  const time = `${inputDate.format(defaultDateTimeFormat.time)} ${toESTTime ? 'EST' : defaultDateTimeFormat.tzLabel}`;
  const dateTime = `${date} ${isWithAt ? ' at' : ''} ${time}`;
  return {
    dateTime,
    date,
    time
  };
});

export const formatDateTimeZone = ({ date, timeZone }) => {
  const formattedTimeZone = moment(date)
    .tz(TIME_ZONE_MAPPING[lowerCase(timeZone)] || TIME_ZONE_ENUM.AMERICA_NEW_YORK)
    .format('ZZ');
  const formattedDate = moment(date).format(EVENT_DATE_FORMAT_TIMEZONE);
  return `${formattedDate}${formattedTimeZone}`;
};

export const switchZone = (date, timeZone = TIME_ZONE_ENUM.AMERICA_NEW_YORK) => {
  if (timeZone === 'localZone') {
    return moment.parseZone(date).local(true).format(EVENT_DATE_FORMAT_WITH_TIMEZONE);
  }
  const formattedDate = moment.parseZone(date).local(true).format(EVENT_DATE_FORMAT_TIMEZONE);
  const nextZone = moment
    .tz(formattedDate, TIME_ZONE_MAPPING[lowerCase(timeZone)] || TIME_ZONE_ENUM.AMERICA_NEW_YORK)
    .format('ZZ');
  return `${formattedDate}${nextZone}`;
};

export const formatDateTime = ({ date, timeZone = TIME_ZONE_ENUM.AMERICA_NEW_YORK, parseZone = false }) => {
  const tz = timeZone || TIME_ZONE_ENUM.AMERICA_NEW_YORK;
  const parsedDate = parseZone ? moment(date).tz(getTimeZone(tz)) : moment(date);
  return {
    toTime: (format = TIME_FORMAT) => (parsedDate.isValid() ? parsedDate.format(format) : ''),
    toDate: (format = DATE_LONG_FORMAT) => (parsedDate.isValid() ? parsedDate.format(format) : ''),
    toDateTime: (dateFormat = DATE_LONG_FORMAT, timeFormat = TIME_FORMAT) =>
      parsedDate.isValid() ? `${parsedDate.format(dateFormat)} ${parsedDate.format(timeFormat)}` : '',
    toDateTimeWithAt: (dateFormat = DATE_LONG_FORMAT, timeFormat = TIME_FORMAT) =>
      parsedDate.isValid() ? `${parsedDate.format(dateFormat)} at ${parsedDate.format(timeFormat)}` : ''
  };
};

export const DateTimeOffset = date => moment(date).format();

export const convertDateWithTimeZone = (date, timeZone) => {
  const convertDate = moment(date).tz(TIME_ZONE_MAPPING[lowerCase(timeZone)] || TIME_ZONE_ENUM.AMERICA_NEW_YORK);
  return convertDate;
};

export const convertDateTimeZone = ({ date, timeZone = undefined, format = undefined }) =>
  moment(date)
    .tz(timeZone || TIME_ZONE_ENUM.AMERICA_NEW_YORK)
    .format(format || DATE_WITH_TIME_ZONE_FORMAT);

export const isSameDateTime = (date1, date2, timeZone = undefined, format) =>
  moment(date1)
    .tz(timeZone || TIME_ZONE_ENUM.AMERICA_NEW_YORK)
    .format(format) ===
  moment(date2)
    .tz(timeZone || TIME_ZONE_ENUM.AMERICA_NEW_YORK)
    .format(format);

export const convertDateTimeZoneToLocal = ({
  date,
  timeZone = TIME_ZONE_ENUM.AMERICA_NEW_YORK,
  format = DATE_WITH_TIME_ZONE_FORMAT
}) => moment(date).tz(timeZone).local().format(format);

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const addDaysWithTimezone = (date, days, tz = TIME_ZONE_ENUM.AMERICA_NEW_YORK) =>
  moment(date).tz(tz).add(days, 'days');
