export const GENDER_TYPES = {
  BOYS: 'Boys',
  GIRLS: 'Girls',
  COED: 'Coed'
};

export const UPPERCASE_GENDER_TYPES = {
  BOYS: 'BOYS',
  GIRLS: 'GIRLS',
  COED: 'COED'
};

export const GENDER_COLORS = {
  [GENDER_TYPES.BOYS]: '4d5358',
  [GENDER_TYPES.GIRLS]: '878d9675',
  [GENDER_TYPES.COED]: '00539a'
};

export const GENDER_LABELS = {
  [GENDER_TYPES.BOYS]: 'Boys',
  [GENDER_TYPES.GIRLS]: 'Girls',
  [GENDER_TYPES.COED]: 'Coed',
  BOYS_AND_GIRLS: 'Boys and Girls'
};
