import { Button } from '@gofan/components';
import { TrashCan16 } from '@carbon/icons-react';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';

import { getSchoolImage } from '@app/pages/EventInformationV2/helpers';

import type { AccountDTO } from '@gofan/api/accounts';

import defaultSchoolIcon from '@assets/images/gofan-icon.png';
import styles from './SchoolItem.module.scss';

interface SchoolItemProps {
  className?: string;
  school: AccountDTO;
  description?: string;
  disabled?: boolean;
  onRemove: (schoolId: string) => void;
}

export const SchoolItem = ({
  className = '',
  school,
  description = '',
  disabled = false,
  onRemove
}: SchoolItemProps) => {
  const schoolIcon = isEmpty(school?.logo) ? defaultSchoolIcon : getSchoolImage(school?.id, 'logo', school?.logo);
  return (
    <div key={school.id} className={classnames(styles['school-item'], className)}>
      <img className={styles['school-item__image']} alt='School Icon' src={schoolIcon} />
      <div className={`${styles['school-item__info']} gs--margin-left-sp4`}>
        <div className='gs--productive-heading-02 gs--font-family-sf'>{school.name}</div>
        {description && <div className='gs--body-short-01 gs--font-family-sf'>{description}</div>}
      </div>
      {!disabled && (
        <div>
          <Button
            renderIcon={TrashCan16}
            kind='danger--ghost'
            iconDescription='TrashCan'
            className={`${styles['school-item__btn-remove']} gs--body-short-01 gs--font-family-sf gs--text-error`}
            onClick={() => onRemove(school.id)}
          >
            Remove
          </Button>
        </div>
      )}
    </div>
  );
};
