import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import type { HistoryData, HistoryItemDTO } from '@gofan/api';

dayjs.extend(utc);
dayjs.extend(timezone);

export type LogField = {
  [key: string]: HistoryItemDTO[];
};

export const getHistoryLogDetails = ({ historyData }: { historyData: HistoryData | undefined }): LogField => {
  if (isEmpty(historyData)) return {};
  return groupBy(historyData.changes, 'field');
};
