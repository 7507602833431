import { useContext, useMemo } from 'react';

import { Button } from 'carbon-components-react';
import { ChartLineSmooth16, CheckmarkOutline16, Events16, TrashCan20 } from '@carbon/icons-react';

import { RootContext } from '@app/RootContext';

import { ENTERED_LABEL, ENTERED_VALUE } from '@season-management/constants/constant';
import {
  DEFAULT_PARTNER_FULL_NAME,
  FULL_NAME_INTEGRATION_PARTNERS
} from '@modules/event-integrations_V2/constants/constants';

import { getSchoolImage } from '@app/pages/EventInformationV2/helpers';
import isEmpty from 'lodash/isEmpty';
import invertColor from '@utils/colors';

import type { EventDTO } from '@events/models/event.model';
import type { LevelGenderDTO, SeasonDTO, SeasonInsightDTO } from '@seasons/models/season.model';
import type { AccountDTO } from '@gofan/api/accounts';
import { useDeleteSeason, DeleteSeasonModal } from '@seasons/components/delete-season-modal';

import defaultAccountIcon from '@assets/images/gofan-icon.png';

import { isOverEventEndDate } from '@app/api/services/EventService';
import { checkPermissionHandlingEvent } from '@app/api/services/UserService';
import { canEdit } from '@gofan/api/users';

import { useSeasonInsights } from '@seasons/hooks/useSeasonInsights';
import { SeasonTicketSaleNumericalChart } from '../season-ticket-sale-numerical-chart/season-ticket-sale-numerical-chart';

import './SeasonCard.scss';

export interface SeasonCardProps {
  schoolName: string;
  schoolLogo: string;
  schoolColor: string;
  schoolId: string;
  seasonName: string;
  seasonLevels: LevelGenderDTO[];
  sportIcon: string;
  eventCount: number;
  createdAt: string;
  createdBy: string;
  entered: keyof typeof ENTERED_LABEL;
  lastUpdatedAt: string;
  startDateTime: string;
  endDateTime: string;
  season: SeasonDTO;
  account?: AccountDTO;
  hasReservedSeating: boolean;
  partnerName: keyof typeof FULL_NAME_INTEGRATION_PARTNERS | null;
  isFromDashboard?: boolean;
  goToSeason: (shouldScrollToAddEvent?: boolean) => void;
  goToReservedSeating: () => void;
  goToManageAttendees: () => void;
  onCreateSeasonTicket: (data: any) => void;
  onAfterDeletedSeason?: (deletedSeason?: SeasonDTO) => void;
  handleOpenPanel?: (insight?: SeasonInsightDTO) => void;
  handleError?: (error: any) => void;
}

export const SeasonCard = (props: SeasonCardProps) => {
  const { season, account, handleError, handleOpenPanel } = props;
  const { accountId, financialAccountId } = season;
  const { seasonInsights } = useSeasonInsights({ season, handleError });
  const { currentUser } = useContext(RootContext);

  const primaryColor = props.schoolColor.toUpperCase() === 'TRANSPARENT' ? '#000000' : `#${props.schoolColor}`;
  const fontColor = invertColor(primaryColor, true);
  const isOverSeasonEndDate = isOverEventEndDate({ ...season });
  const seasonSalesInfo = season._embedded?.['season-sales-info'];
  const hasSeasonTicketPackage = !!season?.products?.length;

  const { modalData, deleteEvents, selectedDeleteEvents, canDeleteSeason, onInitModal, onDeleteSeason } =
    useDeleteSeason({
      addNotification: handleError,
      onAfterDeletedSeason: (deletedSeason?: SeasonDTO) => {
        if (typeof props.onAfterDeletedSeason === 'function') props.onAfterDeletedSeason(deletedSeason);
      }
    });

  const genders = useMemo<string[]>(() => {
    const gendersSet: Set<string> = new Set();

    props.seasonLevels.forEach((level: LevelGenderDTO) => {
      level.genders.forEach((gender: string) => {
        gendersSet.add(gender);
      });
    });

    let gendersArr = Array.from(gendersSet);
    if (gendersArr.length > 1) {
      gendersArr = gendersArr.map(gender => {
        const firstLetter = gender.match(/\b[\w]/g);
        return firstLetter ? firstLetter.join('').toUpperCase() : gender;
      });
    }

    return gendersArr;
  }, [props.seasonLevels]);

  const canCreateSeasonTicket: boolean = useMemo(
    () =>
      (canEdit(accountId, currentUser) || canEdit(financialAccountId ?? '', currentUser)) && !season?.products?.length,
    [accountId, financialAccountId, currentUser, season]
  );

  const canEditSeason: boolean = useMemo(() => {
    if (isOverSeasonEndDate) return false;
    if (seasonSalesInfo && seasonSalesInfo.seasonTotalSaleQuantity > 0) return false;
    return (
      checkPermissionHandlingEvent({ accountId, currentUser }) ||
      checkPermissionHandlingEvent({ accountId: financialAccountId, currentUser })
    );
  }, [accountId, financialAccountId, currentUser, isOverSeasonEndDate, seasonSalesInfo?.seasonTotalSaleQuantity]);

  return (
    <article className='season-card' style={{ width: props.isFromDashboard ? '43%' : '31%' }}>
      <header style={{ backgroundColor: primaryColor, color: fontColor }}>
        <img
          src={
            isEmpty(props.schoolLogo) ? defaultAccountIcon : getSchoolImage(props.schoolId, 'logo', props.schoolLogo)
          }
          alt=''
        />

        <h3 className='gs--font-family-san'>{props.schoolName}</h3>

        {canDeleteSeason(season) && (
          <>
            <div aria-hidden='true' onClick={() => onInitModal(season)} className='delete-season-button-icon'>
              <TrashCan20 />
            </div>
            <DeleteSeasonModal
              {...modalData}
              events={deleteEvents}
              selectedEvents={selectedDeleteEvents}
              onDelete={onDeleteSeason}
            />
          </>
        )}
      </header>

      <section>
        <img src={props.sportIcon} alt='Activity Icon' />

        <div className='season-name gs--margin-right__sp5'>
          <div className='gs--font-family-san gs--body-short-02-semibold gs--text-01 gs--margin-bottom-sp2'>
            {props.seasonName}
          </div>

          <div className='season-card__buttons'>
            <div>
              {canEditSeason && (
                <>
                  <Button onClick={() => props.goToSeason(true)} kind='ghost'>
                    Add more events
                  </Button>
                  <span className='season-card__button-border'>|</span>
                </>
              )}
              <Button onClick={() => props.goToSeason()} kind='ghost'>
                View season
              </Button>
              {props.hasReservedSeating && (
                <>
                  <span className='season-card__button-border'>|</span>
                  <Button onClick={() => props.goToReservedSeating()} kind='ghost'>
                    Reserved Seating
                  </Button>
                </>
              )}
            </div>
            {season?.products?.length > 0 && (
              <div className='season-checkmark gs--helper-text-01'>
                <CheckmarkOutline16 className='gs--margin-right__sp2' />
                <span>Season tickets created</span>
              </div>
            )}
          </div>
        </div>

        <div className='season-info'>
          {genders.length > 0 ? (
            genders.map((gender: string, index: number) => (
              <span key={index} className='season-gender gs--body-short-01-semibold gs--text-01'>
                {gender} {genders.length > 1 && index < 1 ? <span> / </span> : undefined}
              </span>
            ))
          ) : (
            <div className='season-gender gs--body-short-01-semibold gs--text-01'>COED</div>
          )}
          <div className='season-event gs--body-short-01-semibold gs--text-01'>
            <span className='gs--productive-heading-03-semibold gs--text-01 gs--margin-right__sp2'>
              {props.eventCount}
            </span>{' '}
            events
          </div>
        </div>
      </section>

      <div className='season-card__divider' />

      <section>
        <h3 className='gs--font-family-san'>Season ticket package sales</h3>
        <dl className='gs--font-family-san'>
          <dt>To date:</dt>
          <dd>
            <SeasonTicketSaleNumericalChart
              seasonInsights={seasonInsights}
              hasSeasonTicketPackage={hasSeasonTicketPackage}
              canCreateSeasonTicket={canCreateSeasonTicket}
              createSeasonTicketClassname={`${isOverSeasonEndDate ? 'season-card__hidden' : ''}`}
              handleCreateSeasonTicketPackage={() =>
                !isOverSeasonEndDate && props.onCreateSeasonTicket({ mode: 'add', seasonTicket: null, season, account })
              }
            />
          </dd>
        </dl>

        <div className='season-card__reporting-buttons'>
          <Button
            onClick={_handleOpenPanel}
            kind='primary'
            className='insight-btn'
            renderIcon={ChartLineSmooth16}
            size='small'
          >
            Insight
          </Button>

          <Button
            onClick={() => props.goToManageAttendees()}
            kind='primary'
            className='attendees-btn'
            renderIcon={Events16}
            size='small'
          >
            Attendees
          </Button>
        </div>
      </section>

      <div className='season-card__divider' />

      <section>
        <div className='gs--font-family-san'>
          <p>
            <strong>Created:</strong> {new Intl.DateTimeFormat().format(new Date(props.createdAt))}
          </p>
          <p>
            <strong>Created by: </strong> {props.createdBy}{' '}
          </p>
          <p>
            <strong>Last updated: </strong> {new Intl.DateTimeFormat().format(new Date(props.lastUpdatedAt))}{' '}
          </p>
          <p>
            <strong>Season dates:</strong> {new Intl.DateTimeFormat().format(new Date(props.startDateTime))} -{' '}
            {new Intl.DateTimeFormat().format(new Date(props.endDateTime))}
          </p>
          <p>
            <strong>Entered: </strong> {_getEnteredSeason()}
          </p>
        </div>
      </section>
    </article>
  );

  function _getEnteredSeason() {
    if (props.entered === ENTERED_VALUE.ARBITER_IMPORT) {
      return ENTERED_LABEL[props.entered]?.replace(
        '{partnerName}',
        props.partnerName ? FULL_NAME_INTEGRATION_PARTNERS[props.partnerName] : DEFAULT_PARTNER_FULL_NAME
      );
    }
    return ENTERED_LABEL[props.entered] || ENTERED_LABEL.MANUAL_INPUT;
  }

  function _handleOpenPanel(ev: React.MouseEvent<HTMLElement>) {
    if (typeof handleOpenPanel !== 'function') return;

    ev.preventDefault();
    handleOpenPanel(seasonInsights);
  }
};
