import React from 'react';

interface DisabledSwatchProps {
  color: string;
  isSelected: boolean;
}

const DisabledSwatch = ({ color, isSelected }: DisabledSwatchProps) => {
  const styles = {
    swatchDisabled: {
      background: color,
      position: 'relative',
      width: '2rem',
      height: '2rem',
      float: 'left',
      marginRight: '0.5rem',
      marginBottom: '0.5rem'
    },
    swatchDisabledSelected: {
      background: color,
      position: 'relative',
      width: '2rem',
      height: '2rem',
      float: 'left',
      marginRight: '0.5rem',
      marginBottom: '0.5rem',
      boxShadow: `0 0 0 3px ${color}20`
    }
  };

  return (
    <span>
      <div
        style={isSelected ? styles.swatchDisabledSelected : styles.swatchDisabled}
      />
    </span>
  );
};

export default DisabledSwatch;
