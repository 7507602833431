import React from 'react';
import { DEFAULT_HEIGHT_INPUT } from '../../../assets/styles/common';
import BaseInput from './BaseInput';
import FormSafeguard from './FormSafeguard';
import ViewOnlyGuard from './ViewOnlyGuard';

export default ({
  className,
  label,
  formKey,
  description = '',
  nullText = '',
  required = false,
  maxLength = null,
  validation = null,
  overwritable = false,
  errorMessage = '',
  hideOptionalLabel = false,
  showClearTextIcon = false,
  customHeight = DEFAULT_HEIGHT_INPUT,
  placeHolder = '',
  hideLabel = false,
  readOnly = false,
  validationRules = null,
  showErrorOnChange = true
}) => (
  <FormSafeguard formKey={formKey}>
    <ViewOnlyGuard nullText={nullText} label={label} formKey={formKey}>
      <BaseInput
        className={className}
        required={required}
        label={label}
        formKey={formKey}
        maxLength={maxLength}
        validation={validation}
        description={description}
        overwritable={overwritable}
        errorMessage={errorMessage}
        hideOptionalLabel={hideOptionalLabel}
        showClearTextIcon={showClearTextIcon}
        customHeight={customHeight}
        placeHolder={placeHolder}
        hideLabel={hideLabel}
        readOnly={readOnly}
        validationRules={validationRules}
        showErrorOnChange={showErrorOnChange}
      />
    </ViewOnlyGuard>
  </FormSafeguard>
);
