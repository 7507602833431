import dig from '../utils/dig';

/* Order of permissions:
Super Administrator

System Finance Admin

System Admin

System User

Limited System User
 */
export default (user, requestedPermission, onObject, permissionMaster) => {
  if (!user) {
    return false;
  }
  const permissions = permissionMaster[user.role];

  // requested permissions take the form of:
  // <action>.<resource.<?nested.<?nested>>>
  // to an arbitrary depth of nestedness.
  const [action, resource, ...fields] = requestedPermission.split('.');

  // specific action and specific resource
  if (dig(permissions, action, resource, ...fields) !== undefined) {
    // determine the rule type
    const rule = dig(permissions, action, resource, ...fields);

    // if the rule is a boolean, return the rule
    // e.g. { view: { account: true } }
    if (typeof rule === 'boolean') {
      return rule;
    }

    if (rule instanceof Function) {
      return rule({ currentUser: user, [resource]: onObject });
    }

    // if the rule is an object, it represents both:
    // 1. the nested attributes of the object
    // 2. the conditions for granting permission
    // e.g. {
    //  view: {
    //    account: {
    //      name: true,
    //       balance: {
    //         test: ({user, account}) => user.accountId === account.id
    //      }
    //    }
    //  }
    // }

    if (typeof rule === 'object') {
      // since we are checking the permissions of the object, we only care about the conditions
      const conditions = Object.keys(rule).filter(
        key => typeof rule[key] === 'function'
      );
      return conditions.every(condition =>
        rule[condition]({ currentUser: user, [resource]: onObject })
      );
    }
  }

  // specific action and general resource
  if (dig(permissions, action, 'all') !== undefined) {
    return dig(permissions, action, 'all');
  }

  // general "manage" action and specific resource
  if (dig(permissions, 'manage', resource) !== undefined) {
    return dig(permissions, 'manage', resource);
  }

  // general "manage" action and general "all" resource
  if (dig(permissions, 'manage', 'all') !== undefined) {
    return dig(permissions, 'manage', 'all');
  }

  // default, whitelisted permissions only
  return false;
};
