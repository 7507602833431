import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

import { huddleAxiosInstanceProxy } from '../shared/axios';
import {
  SEASON_STATUS_SEATS_URI,
  SEASON_UNAVAILABLE_SEATS_URI,
  RESERVED_SEATING_CHART_URI,
  RESERVED_SEATING_CHART_CATEGORIES_URI,
  RESERVED_SEAT_BLOCK_URI,
  RESERVED_SEAT_DATA_SYNC_URI,
  MOVE_RESERVED_SEATING_TICKETS_URI,
  UNBOOK_RESERVED_SEATING_TICKETS_URI
} from './reserved-seating.endpoints';
import type { ReservedSeatingDTO, BlockedReservedSeatingDTO } from './reserved-seating.model';

export interface UpdateReservedSeatingTicketsParams {
  purchasedTicketIds: number[];
  seatsIoLabel: string;
  productId: number;
  seasonId: number;
}

class ReservedSeatingRepository {
  static blockReservedSeating = (
    eventId: number,
    seasonId: number,
    seatsIoLabel: string
  ): Promise<BlockedReservedSeatingDTO> =>
    huddleAxiosInstanceProxy.post(RESERVED_SEAT_BLOCK_URI, { eventId, seasonId, seatsIoLabel });

  static syncReservedSeating = (): Promise<void> => huddleAxiosInstanceProxy.patch(RESERVED_SEAT_DATA_SYNC_URI, {});

  static getReservedSeatingChart = (chartKey: string) =>
    axios.get(RESERVED_SEATING_CHART_URI.replace('{chartKey}', chartKey));

  static getReservedSeatingChartCategories = (chartKey: string) =>
    axios.get(RESERVED_SEATING_CHART_CATEGORIES_URI.replace('{chartKey}', chartKey));

  static moveReservedSeatingTickets = (params: UpdateReservedSeatingTicketsParams) =>
    huddleAxiosInstanceProxy.patch(MOVE_RESERVED_SEATING_TICKETS_URI, params);

  static unbookReservedSeatingTickets = (params: UpdateReservedSeatingTicketsParams) =>
    huddleAxiosInstanceProxy.patch(UNBOOK_RESERVED_SEATING_TICKETS_URI, params);

  static getSeasonSeatsByStatus = (seasonId: string, seatStatus: string[]): Promise<ReservedSeatingDTO[]> => {
    let uri = `${SEASON_STATUS_SEATS_URI.replace('{seasonId}', seasonId)}`;

    if (!isEmpty(seatStatus)) {
      const statusQuery = (seatStatus ?? [])?.join('&reservationState=');
      uri = `${uri}${isEmpty(statusQuery) ? '' : `&reservationState=${statusQuery}`}`;
    }

    return huddleAxiosInstanceProxy.get(uri);
  };

  static getSeasonUnavailableSeats = (seasonId: string): Promise<ReservedSeatingDTO[]> =>
    huddleAxiosInstanceProxy.get(`${SEASON_UNAVAILABLE_SEATS_URI.replace('{seasonId}', seasonId)}`);
}

export { ReservedSeatingRepository };
