/* eslint-disable import/no-unresolved */
import { put, takeEvery, delay } from 'redux-saga/effects';
import { ADD_NOTIFICATION } from './actionTypes';
import { removeNotification } from './actions';

export function* addNotification(action) {
  const { notification, timeout } = action.payload;
  yield delay(timeout || 3000);
  yield put(removeNotification(notification.id));
}

export function* watchAddNotification() {
  yield takeEvery(ADD_NOTIFICATION, addNotification);
}

export default [watchAddNotification()];
