import type React from 'react';
import { useRef, useCallback, useEffect, useState } from 'react';
import { oc } from 'ts-optchain';
import { Reset32, SettingsAdjust32 } from '@carbon/icons-react';
import type {
  SortRowData,
  DataTableRow,
  DataTableCustomRenderProps
} from 'carbon-components-react/lib/components/DataTable/DataTable';
import type { DataTableCell, TableCarbonProps } from 'carbon-components-react';
import {
  Table,
  DataTable,
  TableContainer,
  Button,
  TableToolbar,
  TableToolbarMenu,
  TableToolbarAction,
  TableToolbarSearch,
  TableToolbarContent
} from 'carbon-components-react';
import BasicPagination from '@old-components/basic-pagination/basic-pagination.component';
import { isEmpty } from '@utils/objectUtils';

import type { SortBy } from './sorting';
import type { GridDataTableHeader } from './basic-table-header/basic-table-header.component';
import BasicTableHeader from './basic-table-header/basic-table-header.component';
import type { GridRowConfig, GridCellConfig } from './basic-table-body/basic-table-body.component';
import BasicTableBody from './basic-table-body/basic-table-body.component';

import TableToolbarBatchActions from './basic-table-batch-actions/basic-table-batch-actions.component';
// type ValueOf<T> = T[keyof T];

export type GridToolbarItemConfig = {
  label: string;
  icon?: React.ReactElement;
  disabled?: boolean;
  onClick?: () => void;
};

export type GridToolbarConfig = {
  items?: GridToolbarItemConfig[];
  hasSearch?: boolean;
  hasRefresh?: boolean;
  hasFilter?: boolean;
  searchPlaceHolder?: string;
  onSearchChanged?: (event: any) => void;
  onClickRefresh?: (event: any) => void;
  onClosedToolbar?: () => void;
  onClickedToolbar?: () => void;
  style?: any;
  batchActions?: {
    hasBatchActions: boolean;
    hasCancelButton?: boolean;
    hasRefresh?: boolean;
    hasFilter?: boolean;
    hasSearch?: boolean;
    totalSeclected: number;
    customToolBarItems: any[];
    onCancel?: () => void;
    onClickRefresh?: () => void;
    onSearchChanged?: (event: any) => void;
    filterLabel?: string;
  };
  primaryButton?: {
    label: string;
    disabled: boolean;
    icon?: React.ReactElement;
    onClick?: () => void;
  };
  editButton?: {
    label: string;
    disabled: boolean;
    onClick?: (events: any) => void;
  };
};

export type GridPaginationConfig = {
  pageSize: number;
  pageSizes?: any[];
  itemsPerPageText?: string | undefined;
  currentPage: number;
  totalElements: number;
};

export type GridSortConfig = {
  sortBy?: SortBy;
  // eslint-disable-next-line no-unused-vars
  sortRow?: (cellA: any, cellB: any, data: SortRowData) => number;
};

export type GridChangedValue = {
  sortBy: SortBy;
  pageSize: number;
  currentPage: number;
};

export type BasicTableConfig = {
  stickyHeader?: boolean;
  rowConfig: GridRowConfig;
  sorting?: GridSortConfig;
  toolbar?: GridToolbarConfig;
  pagination?: GridPaginationConfig;
  onGridChanged?: (v: GridChangedValue) => void;
};

export type BasicTableColumn<T> = {
  index: string;
  name: string;
  isSortable?: boolean;
  textEllipsis?: boolean;
  style?: any;
  onHeaderClicked?: (evt: React.MouseEvent<HTMLElement>) => void;
  render?: (v: ValueOf<T>, cell: DataTableCell) => React.ReactElement;
};

export type GridConfig = {
  headers: GridDataTableHeader[];
  cellConfig: {
    [k: string]: GridCellConfig;
  };
};

export type Id = string;
export type BasicTableProps = {
  data: any[];
  grid: BasicTableConfig;
  columns: BasicTableColumn<any>[];
  onRowClick?: (id: Id) => void;
  selectedRowIds?: Id[];
  excludedSelectRowIds?: Id[];
  onSelectRow?: (id: Id) => void;
  onSelectAll?: () => void;
  highlightOnClick?: boolean;
  tableProps?: TableCarbonProps;
};

const BasicTable = ({
  data,
  grid,
  columns,
  onRowClick,
  selectedRowIds,
  excludedSelectRowIds = [],
  onSelectRow,
  onSelectAll,
  highlightOnClick = true,
  tableProps
}: BasicTableProps) => {
  const [dataRows, setDataRow] = useState<DataTableRow[]>([]);
  const [gridConfig, setGridConfig] = useState<GridConfig>({});
  const [sortConfig, setSortConfig] = useState<GridSortConfig>({});
  const [paginationConfig, setPaginationConfig] = useState<GridPaginationConfig>({});
  const gridChangedValue = useRef<GridChangedValue>({});

  const noop = useCallback(() => null, []);

  const {
    style,
    hasSearch,
    hasRefresh,
    hasFilter = true,
    batchActions,
    onClickRefresh,
    onSearchChanged = noop,
    onClosedToolbar = noop,
    onClickedToolbar = noop,
    items: toolbarItems = [],
    searchPlaceHolder = 'Search'
  } = oc(grid).toolbar({});

  const { sortRow = () => 0 } = oc(grid).sorting({});

  useEffect(() => {
    if (!columns || (columns && columns.length === 0)) {
      return;
    }

    const headers: GridDataTableHeader[] = columns.map(col => ({
      key: col.index,
      header: col.name,
      style: col.style,
      isSortable: col.isSortable,
      textEllipsis: col.textEllipsis,
      onHeaderClicked: col.onHeaderClicked
    }));

    const cellConfig = columns.reduce(
      (acc, col) => ({
        ...acc,
        [col.index]: {
          render: col.render
        }
      }),
      {}
    );

    setGridConfig({ headers, cellConfig });
  }, [columns]);

  useEffect(() => {
    const transformedData: DataTableRow[] = data.map(dataItem => ({
      ...dataItem,
      id: `${dataItem[grid.rowConfig.id]}`
    }));
    setDataRow(transformedData);
  }, [grid, data]);

  useEffect(() => {
    if (!isEmpty(grid.pagination)) {
      gridChangedValue.current = {
        ...gridChangedValue.current,
        pageSize: grid.pagination.pageSize,
        currentPage: grid.pagination.currentPage
      };

      setPaginationConfig({
        pageSize: grid.pagination.pageSize,
        pageSizes: grid.pagination.pageSizes,
        currentPage: grid.pagination.currentPage,
        totalElements: grid.pagination.totalElements
      });
    }

    if (!isEmpty(grid.sorting)) {
      gridChangedValue.current = {
        ...gridChangedValue.current,
        sortBy: grid.sorting.sortBy
      };
      setSortConfig({ sortBy: grid.sorting.sortBy });
    }
  }, [grid]);

  const onPaginationChanged = useCallback(
    ({ page, pageSize }) => {
      gridChangedValue.current = {
        ...gridChangedValue.current,
        pageSize,
        currentPage: page - 1
      };
      if (grid.onGridChanged && typeof grid.onGridChanged === 'function') {
        grid.onGridChanged(gridChangedValue.current);
      }
    },
    [grid, gridChangedValue.current]
  );

  const onSortChanged = useCallback(
    ({ header, sortDirection }) => {
      gridChangedValue.current = {
        ...gridChangedValue.current,
        sortBy: { header, sortDirection }
      };
      if (grid.onGridChanged && typeof grid.onGridChanged === 'function') {
        grid.onGridChanged(gridChangedValue.current);
      }
    },
    [grid, gridChangedValue.current]
  );

  return (
    <div className='basic-table'>
      <div className='basic-table--content'>
        <DataTable
          rows={dataRows}
          headers={oc(gridConfig).headers([])}
          overflowMenuOnHover={false}
          sortRow={sortRow}
          isSortable={!isEmpty(sortConfig)}
          stickyHeader={oc(grid).stickyHeader(false)}
          {...tableProps}
        >
          {({
            rows,
            getHeaderProps,
            getTableProps,
            getToolbarProps,
            getTableContainerProps,
            getSelectionProps
          }: DataTableCustomRenderProps) => (
            <TableContainer {...getTableContainerProps()}>
              {!isEmpty(oc(grid).toolbar({})) && (
                <TableToolbar {...getToolbarProps()} aria-label='data table toolbar'>
                  {batchActions?.hasBatchActions ? (
                    <TableToolbarBatchActions
                      style={style}
                      batchActions={batchActions}
                      onClickedToolbar={onClickedToolbar}
                      searchPlaceHolder={searchPlaceHolder}
                    />
                  ) : (
                    <TableToolbarContent className='basic-table--toolbar' style={style}>
                      {hasSearch && (
                        <TableToolbarSearch placeHolderText={searchPlaceHolder} onChange={onSearchChanged} />
                      )}
                      {hasRefresh && <TableToolbarMenu renderIcon={Reset32} light onClick={onClickRefresh} />}
                      {hasFilter && (
                        <TableToolbarMenu
                          id='toolbar-filter-btn'
                          renderIcon={SettingsAdjust32}
                          light
                          onClose={onClosedToolbar}
                          onClick={onClickedToolbar}
                        >
                          {toolbarItems.map(toolbar => {
                            const toolbarActions: Partial<GridToolbarItemConfig> = {};

                            if (toolbar.onClick && typeof toolbar.onClick === 'function') {
                              toolbarActions.onClick = toolbar.onClick;
                            }
                            return (
                              <TableToolbarAction key={toolbar.label} {...toolbarActions}>
                                {toolbar.label}
                              </TableToolbarAction>
                            );
                          })}
                        </TableToolbarMenu>
                      )}
                      {!isEmpty(oc(grid).toolbar.primaryButton({})) && (
                        <Button
                          disabled={grid.toolbar.primaryButton.disabled}
                          onClick={grid.toolbar.primaryButton.onClick || noop}
                        >
                          {grid.toolbar.primaryButton.label} {grid.toolbar.primaryButton.icon}
                        </Button>
                      )}
                    </TableToolbarContent>
                  )}
                </TableToolbar>
              )}
              <Table {...getTableProps()}>
                <BasicTableHeader
                  sortConfig={sortConfig}
                  rowConfig={oc(grid).rowConfig({})}
                  headers={oc(gridConfig).headers([])}
                  getHeaderProps={getHeaderProps}
                  onSortChange={onSortChanged}
                  getSelectionProps={getSelectionProps}
                  selectedRowIds={selectedRowIds}
                  selectableRowCount={Math.max(rows.length - excludedSelectRowIds.length, 0)}
                  onSelectAll={onSelectAll}
                />
                <BasicTableBody
                  rows={rows}
                  rowConfig={oc(grid).rowConfig({})}
                  headers={oc(gridConfig).headers([])}
                  cellConfig={oc(gridConfig).cellConfig({})}
                  onRowClick={onRowClick}
                  onSelectRow={onSelectRow}
                  selectedRowIds={selectedRowIds}
                  getSelectionProps={getSelectionProps}
                  excludedSelectRowIds={excludedSelectRowIds}
                  highlightOnClick={highlightOnClick}
                />
              </Table>
            </TableContainer>
          )}
        </DataTable>
      </div>
      {!isEmpty(paginationConfig) && (
        <div className='basic-table--pagination'>
          <BasicPagination
            page={paginationConfig.currentPage + 1}
            pageSize={paginationConfig.pageSize}
            pageSizes={paginationConfig.pageSizes}
            totalItems={paginationConfig.totalElements}
            onPaginationChange={onPaginationChanged}
          />
        </div>
      )}
    </div>
  );
};

export default BasicTable;
