import { TIME_ZONE_ENUM, TIME_ZONE_MAPPING } from '../../utils/dateUtils';

// @flow
class EventRefund {
  static DEFAULT_TIME_ZONE = TIME_ZONE_ENUM.AMERICA_NEW_YORK;

  constructor({
    allDayEvent,
    endDate,
    eventDate,
    eventId,
    eventName,
    financialAccountId,
    financialAccountName,
    startDate,
    timeZone,
    totalRefundedAmount,
    totalRefundedTicket,
    totalTicketSold,
    totalTicketsRefundable
  }) {
    this.raw = {
      allDayEvent,
      endDate,
      eventDate,
      eventId,
      eventName,
      financialAccountId,
      financialAccountName,
      startDate,
      timeZone,
      totalRefundedAmount,
      totalRefundedTicket,
      totalTicketSold,
      totalTicketsRefundable
    };
  }

  get allDayEvent() {
    return this.raw.allDayEvent;
  }

  /**
   * endDate: UTC time
   * E.g: 2023-01-02T08:59:00+0000
   */
  get endDate() {
    return this.raw.endDate;
  }

  get eventDate() {
    return this.raw.eventDate;
  }

  get eventId() {
    return this.raw.eventId;
  }

  get eventName() {
    return this.raw.eventName;
  }

  get financialAccountId() {
    return this.raw.financialAccountId;
  }

  get financialAccountName() {
    return this.raw.financialAccountName;
  }

  /**
   * startDate: UTC time
   * E.g: 2023-01-02T04:59:00+0000
   */
  get startDate() {
    return this.raw.startDate;
  }

  /**
   * timeZone
   * E.g: Eastern Time (US & Canada)
   */
  get timeZone() {
    return (
      TIME_ZONE_MAPPING[this.raw.timeZone] || EventRefund.DEFAULT_TIME_ZONE
    );
  }

  get totalRefundedAmount() {
    return this.raw.totalRefundedAmount || 0;
  }

  get totalRefundedTicket() {
    return this.raw.totalRefundedTicket || 0;
  }

  get totalTicketSold() {
    return this.raw.totalTicketSold || 0;
  }

  get totalTicketsRefundable() {
    return this.raw.totalTicketsRefundable;
  }

  toJSON() {
    return {
      id: this.eventId,
      name: this.eventName,
      allDayEvent: this.allDayEvent,
      endDateTime: this.endDate,
      eventDateTime: this.eventDate,
      financialAccountId: this.financialAccountId,
      financialAccountName: this.financialAccountName,
      startDateTime: this.startDate,
      timeZone: this.timeZone,
      totalRefundedAmount: this.totalRefundedAmount,
      totalRefundedTicket: this.totalRefundedTicket,
      totalTicketSold: this.totalTicketSold,
      totalTicketsRefundable: this.totalTicketsRefundable
    };
  }
}

export default EventRefund;
