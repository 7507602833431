import React from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import styles from './styles';
import TabItem from '../TabItem';

const TabsWrapper = styled.div`
  ${styles.tabsWrapper};
`;

const Tabs = ({ tabs, currentTab, setCurrentTab }) => (
  <TabsWrapper>
    <Segment>
      <Grid columns={tabs.length}>
        {tabs
          .filter(tab => !isEmpty(tab))
          .map(tab => {
            const { name, disable, ...rest } = tab;
            return (
              <TabItem
                {...rest}
                key={name}
                name={name}
                active={currentTab === name}
                disable={disable}
                setCurrentTab={setCurrentTab}
              />
            );
          })}
      </Grid>
    </Segment>
  </TabsWrapper>
);

Tabs.defaultProps = {
  tabs: [],
  currentTab: '',
  setCurrentTab: () => null
};

export default Tabs;
