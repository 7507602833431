import ErrorDTO from '../dto/ErrorDTO';
import EventDTO from '../dto/EventDTO';
import EventV2DTO from '../dto/EventV2DTO';
import EventTypeDTO from '../dto/EventTypeDTO';
import PaginationDTO from '../dto/PaginationDTO';
import EventRefundDTO from '../dto/EventRefundDTO';
import EventSalesInfoDTO from '../dto/EventSalesInfoDTO';
import PageRequest from '../model/request/PageRequest';
import FieldsRequest from '../model/request/FieldsRequest';
import ExpandRequest from '../model/request/ExpandRequest';
import SortableRequest from '../model/request/SortableRequest';

import {
  GET_EVENT,
  GET_EVENT_BY_PARAMS,
  FETCH_EVENT,
  CREATE_EVENT,
  FETCH_EVENTS,
  CREATE_EVENT_TYPE,
  FETCH_EVENT_REFUND,
  UPDATE_EVENT,
  UPDATE_EVENTS,
  DELETE_EVENT,
  GET_EVENT_SALES_INFO,
  PLAYOFF_CALENDAR_SEARCH_URI,
  GET_TICKETS_BY_EVENT_ID,
  GET_EVENT_SEATS_BY_STATUS
} from '../api/api';
import axios from '../api/axios';
import { isEmpty } from '../../utils/objectUtils';
import SeatInfo from '../model/SeatInfo';

// Old version of the Event

const fetchEventsByAccountId = (searchRequest, pageRequest, fieldsRequest) => {
  const uri = `${FETCH_EVENTS}&${pageRequest.toQueryString()}${fieldsRequest.toQueryString()}`;
  return axios.huddleAxiosInstanceProxy
    .post(uri, searchRequest.toJSON())
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }

      return response.content.map(event => new EventDTO(event));
    })
    .catch(e => new ErrorDTO(e));
};

const fetchEventById = (eventId = '', fieldsRequest) => {
  const uri = `${FETCH_EVENT.replace('{eventId}', eventId)}${fieldsRequest.toQueryString()}`;
  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }

      return new EventDTO(response);
    })
    .catch(e => new ErrorDTO(e));
};

// New version of the Event
const getEvent = (eventId = '', expandRequest, fieldsRequest) => {
  const expandQuery = expandRequest.toQueryString();
  const fieldsQuery = fieldsRequest.toQueryString();
  const path = GET_EVENT.replace('{id}', eventId);
  const uri = `${path}?ts=${new Date().getTime()}${expandQuery}${fieldsQuery}`;
  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new EventV2DTO(response);
    })
    .catch(e => new ErrorDTO(e));
};

const getEventByParams = ({
  searchParams = {},
  pageRequest = new PageRequest({}),
  fieldsRequest = new FieldsRequest({}),
  expandRequest = new ExpandRequest(),
  sortableRequest = new SortableRequest({})
}) => {
  const pageQuery = pageRequest.toQueryString();
  const expandQuery = expandRequest.toQueryString();
  const fieldsQuery = fieldsRequest.toQueryString();
  const sortableQuery = sortableRequest.toQueryString();
  const queryStr = `?ts=${new Date().getTime()}&skipCache=true&${pageQuery}${sortableQuery}${expandQuery}${fieldsQuery}`;
  return axios.huddleAxiosInstanceProxy
    .post(`${GET_EVENT_BY_PARAMS}${queryStr}`, searchParams, null, true)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }

      const { content, pageable, totalPages, totalElements } = response;
      const { pageSize, pageNumber } = isEmpty(pageable) ? {} : pageable;
      return new PaginationDTO({
        pageSize,
        pageNumber,
        totalPages,
        totalElements,
        data: content.map(event => new EventV2DTO(event))
      });
    })
    .catch(e => new ErrorDTO(e));
};

const fetchEventRefund = (searchRequest, pageRequest, sortableRequest) => {
  const uri = `${FETCH_EVENT_REFUND}?${pageRequest.toQueryString()}${sortableRequest.toQueryString()}&skipCache=true`;
  return axios.huddleAxiosInstanceProxy
    .post(uri, searchRequest.toJSON(), null, true)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }

      const { content, pageable, totalPages } = response;
      const { pageSize, pageNumber } = isEmpty(pageable) ? {} : pageable;

      return new PaginationDTO({
        pageSize,
        pageNumber,
        totalPages,
        data: content.map(event => new EventRefundDTO(event))
      });
    })
    .catch(e => new ErrorDTO(e));
};

const createEvent = (request, checkDupe = false) => {
  const url = checkDupe ? `${CREATE_EVENT}?checkDupes=true` : CREATE_EVENT;
  return axios.huddleAxiosInstanceProxy
    .post(url, request.toJSON())
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new EventDTO(response);
    })
    .catch(e => new ErrorDTO(e));
};

const updateEvent = (request, checkDupe = false) => {
  let uri = `${UPDATE_EVENT.replace('{id}', request.id)}`;
  if (checkDupe) {
    uri = `${uri}/?checkDupes=true`;
  }
  return axios.huddleAxiosInstanceProxy
    .put(uri, request.toJSON())
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new EventDTO(response);
    })
    .catch(e => new ErrorDTO(e));
};

const updateEvents = request => {
  const uri = `${UPDATE_EVENTS}`;
  return axios.huddleAxiosInstanceProxy
    .patch(uri, request)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response.map(event => new EventDTO(event));
    })
    .catch(e => new ErrorDTO(e));
};

const deleteEvent = id => {
  const uri = `${DELETE_EVENT.replace('{id}', id)}`;
  return axios.huddleAxiosInstanceProxy
    .delete(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new EventDTO(response);
    })
    .catch(e => new ErrorDTO(e));
};

const createEventType = request =>
  axios.huddleAxiosInstanceProxy
    .post(CREATE_EVENT_TYPE, request.toJSON())
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new EventTypeDTO(response);
    })
    .catch(e => new ErrorDTO(e));

const getEventSalesInfo = id => {
  const uri = `${GET_EVENT_SALES_INFO.replace('{id}', id)}`;
  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      const salesInfo = new EventSalesInfoDTO(response);
      return salesInfo;
    })
    .catch(e => new ErrorDTO(e));
};

const searchPlayOffDataBySchoolId = schoolId => {
  const uri = PLAYOFF_CALENDAR_SEARCH_URI;
  return axios.huddleAxiosInstanceProxy
    .post(uri, {
      schoolId
    })
    .catch(e => new ErrorDTO(e));
};

const getTicketsByEventId = id => {
  const uri = `${GET_TICKETS_BY_EVENT_ID.replace('{id}', id)}`;
  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response.content.map(ticket => new SeatInfo({ ...ticket, seatStatus: 'BOOKED' }));
    })
    .catch(e => new ErrorDTO(e));
};

const getEventSeatsByStatus = (eventId, seatStatus) => {
  const uri = `${GET_EVENT_SEATS_BY_STATUS.replace('{eventId}', eventId).replace('{seatStatus}', seatStatus)}`;
  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response;
    })
    .catch(e => new ErrorDTO(e));
};

export default {
  getEvent,
  getEventByParams,
  createEvent,
  createEventType,
  fetchEventById,
  fetchEventsByAccountId,
  fetchEventRefund,
  updateEvent,
  updateEvents,
  deleteEvent,
  getEventSalesInfo,
  searchPlayOffDataBySchoolId,
  getTicketsByEventId,
  getEventSeatsByStatus
};
