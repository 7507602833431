import { useState, useEffect, useMemo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Row, Column, ModalBody, ModalHeader, ModalFooter, ComposedModal, Button } from 'carbon-components-react';
import { ActivityService } from '@gofan/api/activities';
import * as yup from 'yup';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { isDifference } from '@utils/objectUtils';

import type { AccountDTO } from '@gofan/api/accounts';
import type { ActivityDTO } from '@gofan/api/activities';
import type { UserDTO } from '@gofan/api/users';

import strings from '@dashboard/constants/strings';
import { SEASON_CREATION_METHODS, SEASONS_SETUP_PROGRESS } from '@dashboard/constants/constants';
import SeasonSetupSteps from '@dashboard/components/season-setup-steps/season-setup-steps.component';
import { ATHLETIC_ACTIVITIES_EXCLUDE } from '@app/pages/EventInformationV2/constants';
import { useDistrictData } from '@accounts/v2/hooks/useDistrictData';
import { checkIsEditableDistrictUser } from '@accounts/v2/utils';

export const sortAccounts = (accounts: AccountDTO[]) =>
  accounts ? [...accounts].sort((a, b) => a.name?.localeCompare(b.name, 'en', { numeric: true })) : [];

const customObjectBuilder = (name: string, message: string) =>
  yup
    .object()
    .nullable()
    .test(name, message, value => !isEmpty(value));

const schema = yup.object().shape({
  sports: yup.array().min(1, strings.SEASON_SETUP_MODAL.ERROR_MESSAGES.SPORT.REQUIRED),
  account: customObjectBuilder('account', strings.SEASON_SETUP_MODAL.ERROR_MESSAGES.SCHOOL.REQUIRED),
  financialAccount: customObjectBuilder(
    'financialAccount',
    strings.SEASON_SETUP_MODAL.ERROR_MESSAGES.SCHOOL_GET_PAID.REQUIRED
  ),
  creationMethod: yup.string().required(strings.SEASON_SETUP_MODAL.ERROR_MESSAGES.CREATION_METHOD.REQUIRED)
});

const getDefaultProcess = (isSingleSchoolAssociated: boolean, isFromMultiSeasonPage?: boolean) => {
  if (isFromMultiSeasonPage) {
    return SEASONS_SETUP_PROGRESS.CREATION_SEASON.SELECT_SPORTS_STEP.index;
  }
  return isSingleSchoolAssociated
    ? SEASONS_SETUP_PROGRESS.SINGLE_SCHOOL.SELECT_SPORTS_STEP.index
    : SEASONS_SETUP_PROGRESS.MULTIPLE_SCHOOL.SELECT_SPORTS_STEP.index;
};

export type SeasonsSetup = {
  account?: AccountDTO;
  financialAccount?: AccountDTO;
  creationMethod?: string;
  sports: ActivityDTO[];
};

export interface SeasonSetupModalProps {
  open: boolean;
  accounts: AccountDTO[];
  activities?: ActivityDTO[];
  onCloseModal: () => void;
  onClickedContinue: Function;
  selectionAccounts: AccountDTO[];
  currentUser: UserDTO;
  isInternalUser: boolean;
  isFromMultiSeasonPage?: boolean;
  selectedSports?: ActivityDTO[];
}

const SeasonSetupModal = ({
  open,
  accounts,
  activities,
  selectedSports,
  currentUser,
  isInternalUser,
  isFromMultiSeasonPage,
  onCloseModal,
  onClickedContinue,
  selectionAccounts
}: SeasonSetupModalProps) => {
  const associatedDistrictAccount = accounts?.find(account => checkIsEditableDistrictUser(account, currentUser));
  const isSingleSchoolAssociated =
    !isInternalUser && selectionAccounts.length === 1 && isEmpty(associatedDistrictAccount);
  const [currentStep, setCurrentStep] = useState<number>(
    getDefaultProcess(isSingleSchoolAssociated, isFromMultiSeasonPage)
  );
  const homeSchoolSelected = associatedDistrictAccount || (accounts ? accounts[0] : {});
  const methods = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      account: homeSchoolSelected,
      sports: selectedSports ?? [],
      financialAccount: homeSchoolSelected,
      creationMethod: SEASON_CREATION_METHODS.SINGLE_SPORT
    },
    resolver: yupResolver(schema)
  });
  const account = methods.watch('account') as AccountDTO;
  const { districtAccount, underDistrictSchools, schoolConfig, isInitialDistrictDataLoading } = useDistrictData({
    account,
    needFetchDistrictAccount: open,
    needFetchUnderDistrictSchools: open && !isEmpty(associatedDistrictAccount),
    needFetchSchoolConfig: open,
    keepPreviousUnderDistrictSchoolsData: true
  });

  useEffect(() => {
    if (!isEmpty(account) && !isInitialDistrictDataLoading) {
      if (!isEmpty(districtAccount) && !isEmpty(schoolConfig)) {
        const found = [account, districtAccount].find(item => item?.id === schoolConfig?.districtDefaultFinancialId);
        if (found) {
          methods.setValue('financialAccount', found, { shouldValidate: true });
        }
      } else {
        methods.setValue('financialAccount', account, { shouldValidate: true });
      }
    }
  }, [account, districtAccount, schoolConfig, isInitialDistrictDataLoading]);

  const sportSelectionKey = useMemo(() => new Date().getTime(), [currentStep, open]);

  const progressData = useMemo(() => {
    if (isFromMultiSeasonPage) {
      return SEASONS_SETUP_PROGRESS.CREATION_SEASON;
    }
    if (isSingleSchoolAssociated) {
      return SEASONS_SETUP_PROGRESS.SINGLE_SCHOOL;
    }
    return SEASONS_SETUP_PROGRESS.MULTIPLE_SCHOOL;
  }, [isSingleSchoolAssociated, isFromMultiSeasonPage]);

  const listActivities = useMemo(() => {
    if (!activities) return [];
    const { athleticActivities = [] } = ActivityService.groupActivities(activities);

    return athleticActivities.filter(activity => activity?.label?.toUpperCase() !== ATHLETIC_ACTIVITIES_EXCLUDE);
  }, [activities]);

  const handleClickedContinue = async () => {
    let triggerFields: any = [];
    if (currentStep === progressData?.SELECT_SPORTS_STEP?.index) {
      triggerFields = ['sports'];
    } else if (currentStep === progressData?.CHOOSE_CREATION_METHOD_STEP?.index) {
      triggerFields = ['creationMethod'];
    } else {
      triggerFields = ['account', 'financialAccount'];
    }

    const isValid = await methods.trigger(triggerFields, {
      shouldFocus: true
    });

    if (!isValid) return;

    if (isFromMultiSeasonPage) {
      const data = methods.getValues();
      onClickedContinue(data);
      onCloseModal();
      return;
    }

    setCurrentStep(currentStep + 1);
    if (currentStep === progressData?.CHOOSE_CREATION_METHOD_STEP?.index) {
      methods.handleSubmit(data => {
        onClickedContinue(data);
        onCloseModal();
      })();
    }
  };

  const handleClickPrevious = () => {
    setCurrentStep((prevStep: number) =>
      prevStep < progressData?.SELECT_SPORTS_STEP?.index ? currentStep : currentStep - 1
    );
  };

  const onKeyDownCloseModal = useCallback(event => {
    if (event.keyCode === 27) {
      onCloseModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDownCloseModal);
    return () => {
      document.removeEventListener('keydown', onKeyDownCloseModal);
    };
  }, [onKeyDownCloseModal]);

  useEffect(() => {
    const sports = methods.getValues('sports');
    if (isDifference(sports, selectedSports, ({ id }) => `${id}`)) {
      methods.reset({ sports: selectedSports ?? [] });
      methods.clearErrors();
    }
  }, [selectedSports, open]);

  return (
    <ComposedModal
      preventCloseOnClickOutside
      size='lg'
      open={open}
      onClose={onCloseModal}
      className='season-setup-modal'
    >
      <ModalHeader className='season-setup-modal__header'>
        <Row
          className={classNames(
            'gs--section-description-large',
            'gs--font-family-san',
            'gs--text-01',
            'gs--padding-top__sp5',
            'gs--padding-bottom__sp5'
          )}
        >
          <Column>{strings.SEASON_SETUP_MODAL.TITLE}</Column>
        </Row>
        <Row
          className={classNames({
            'season-setup-modal__header__one-school': isSingleSchoolAssociated
          })}
        />
      </ModalHeader>
      <ModalBody hasForm className='season-setup-modal__body'>
        <FormProvider {...methods}>
          <Row>
            <Column className='gs--padding-top__sp5'>
              <div className='gs--body-short-01 gs--text-01'>{strings.SEASON_SETUP_MODAL.STEPS[currentStep]}</div>
            </Column>
          </Row>

          <SeasonSetupSteps
            sportSelectionKey={sportSelectionKey}
            currentStep={currentStep}
            progressData={progressData}
            activities={listActivities}
            isInternalUser={isInternalUser}
            accounts={sortAccounts(accounts)}
            selectionAccounts={selectionAccounts}
            districtAccount={districtAccount}
            underDistrictSchools={underDistrictSchools}
          />
        </FormProvider>
      </ModalBody>
      <ModalFooter className='season-setup-modal__btn-action'>
        <Column
          onClick={onCloseModal}
          {...(isFromMultiSeasonPage ? {} : { lg: currentStep !== progressData?.SELECT_SPORTS_STEP?.index ? 4 : 6 })}
        >
          <Button className='season-setup-modal__btn-action--cancel gf--button cds--btn-text-centered' kind='secondary'>
            {strings.SEASON_SETUP_MODAL.CANCEL}
          </Button>
        </Column>
        {!isFromMultiSeasonPage && currentStep !== progressData?.SELECT_SPORTS_STEP?.index && (
          <Column onClick={handleClickPrevious} lg={4}>
            <Button
              disabled={currentStep === progressData?.SELECT_SPORTS_STEP?.index}
              kind='secondary'
              className='gf--button cds--btn-text-centered'
            >
              {strings.SEASON_SETUP_MODAL.PREVIOUS}
            </Button>
          </Column>
        )}
        <Column
          {...(isFromMultiSeasonPage ? {} : { lg: currentStep !== progressData?.SELECT_SPORTS_STEP?.index ? 4 : 6 })}
        >
          <Button onClick={handleClickedContinue} kind='primary' className='gf--button cds--btn-text-centered' data-testid={`button-continue-${currentStep}`}>
            {strings.SEASON_SETUP_MODAL.CONTINUE}
          </Button>
        </Column>
      </ModalFooter>
    </ComposedModal>
  );
};

export default SeasonSetupModal;
