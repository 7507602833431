import { all } from 'redux-saga/effects';
import root from './root/sagas';
import fanSupport from './pages/FanSupport/sagas';
import events from './pages/Events/sagas';
import eventReservedSeating from './pages/EventReservedSeating/sagas';
import rateInformation from './pages/RateInformation/sagas';
import accountList from './pages/AccountList/sagas';
import activity from './pages/Activities/sagas';
import level from './pages/Levels/sagas';
import products from './pages/Products/sagas';
import rates from './pages/Rates/sagas';
import userHistory from './pages/users/sagas';
import auth from './pages/Root/components/Auth/sagas';
import eventInformationV2 from './pages/EventInformationV2/sagas';
import eventDetail from './pages/EventDetail/sagas';
import eventLanding from './pages/EventLanding/sagas';
import rootV2 from './pages/Root/sagas';
import seasonSetup from './pages/SeasonSetup/sagas';
import seasonManagement from './modules/season-management/middleware/sagas';
import eventIntegrations_V2 from './modules/event-integrations_V2/middleware/sagas';
import seasonRenewal from './modules/season-renewal/middleware/sagas';

export default function* rootSaga() {
  yield all([
    ...root,
    ...events,
    ...fanSupport,
    ...accountList,
    ...eventReservedSeating,
    ...activity,
    ...level,
    ...rateInformation,
    ...products,
    ...rates,
    ...userHistory,
    ...auth,
    ...eventInformationV2,
    ...eventDetail,
    ...eventLanding,
    ...rootV2,
    ...seasonSetup,
    ...seasonManagement,
    ...eventIntegrations_V2,
    ...seasonRenewal
  ]);
}
