import { isEmpty } from '../../utils/objectUtils';
import ErrorEventDTO from '../dto/ErrorEventDTO';
import RowEventDTO from '../dto/RowEventDTO';
import ErrorEvent from './ErrorEvent';
import RowEvent from './RowEvent';

class UploadedEvent {
  constructor({ errors, event, rowNumber }) {
    this.raw = {
      errors,
      event,
      rowNumber
    };
  }

  get errors() {
    if (!isEmpty(this.raw.errors)) {
      return this.raw.errors.map(item =>
        item instanceof ErrorEventDTO
          ? new ErrorEvent(item.toJSON()).toJSON()
          : new ErrorEvent(item).toJSON()
      );
    }
    return [];
  }

  get event() {
    if (isEmpty(this.raw.event)) {
      return undefined;
    }

    return this.raw.event instanceof RowEventDTO
      ? new RowEvent(this.raw.event.toJSON()).toJSON()
      : new RowEvent(this.raw.event).toJSON();
  }

  get rowNumber() {
    return this.raw.rowNumber;
  }

  toJSON() {
    return {
      errors: this.errors,
      event: this.event,
      rowNumber: this.rowNumber
    };
  }
}

export default UploadedEvent;
