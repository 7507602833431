import { Controller, useFormContext } from 'react-hook-form';
import { Dropdown, TextInput, Toggle, Button, Icons, InlineLoading, DatePicker, TextArea } from '../../..';
import { PendoSupportFormProgress } from '../../PendoSupportForm';
import { useEffect, useState } from 'react';
import type { DropdownItem } from '@gofan/api';
import {
  ZENDESK_SUPPORT_FORM_FIELDS,
  MANUAL_BROADCAST_SOFTWARE,
  PIXELLOT_ISSUES,
  STEAMING_TYPE_OF_ISSUE
} from '@gofan/api';
import { AttachmentsField } from '../shared-fields/AttachmentsField';
import '../../PendoSupportForm.scss';

interface PendoSupportFormStreamingProps {
  onBack: (nextProgress: PendoSupportFormProgress) => void;
  isSubmitting: boolean;
}

export enum PendoSupportFormStreamingProgress {
  DEFAULT,
  PIXELLOT,
  MANUAL_BROADCAST
}

// this form is shared between HQ desktop and HQ Mobile, the margin classes are different for each
const PendoSupportFormStreaming = ({ onBack, isSubmitting = false }: PendoSupportFormStreamingProps) => {
  const [streamingProgress, setStreamingProgress] = useState<PendoSupportFormStreamingProgress>(
    PendoSupportFormStreamingProgress.DEFAULT
  );
  const { control, getValues } = useFormContext();

  useEffect(() => {
    _syncFormView();
  }, []);

  return (
    <>
      <div className='gf-sub-title'>Tell us more about your issue</div>
      <Controller
        name={ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_STREAMING}
        control={control}
        render={({ field, fieldState }) => (
          <Dropdown
            {...field}
            titleText='Select type of issue'
            className='gs--margin-bottom-sp5 gs--margin-top-sp5 gs--margin-bottom__sp5 gs--margin-top__sp5'
            id={ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_STREAMING}
            items={STEAMING_TYPE_OF_ISSUE}
            itemToString={(item: { text: string; value: string }) => (item ? item.text : '')}
            label='Choose an option'
            invalid={!!fieldState.error}
            invalidText='Required'
            selectedItem={field.value || ''}
            onChange={({ selectedItem }: { selectedItem: { text: string; value: string } }) => {
              let progress = PendoSupportFormStreamingProgress.DEFAULT;
              field.onChange(selectedItem);
              if (selectedItem.text === 'Pixellot issue') {
                progress = PendoSupportFormStreamingProgress.PIXELLOT;
              }
              if (selectedItem.text === 'Manual broadcast') {
                progress = PendoSupportFormStreamingProgress.MANUAL_BROADCAST;
              }
              setStreamingProgress(progress);
            }}
          />
        )}
      />
      {streamingProgress === PendoSupportFormStreamingProgress.MANUAL_BROADCAST && (
        <Controller
          name={ZENDESK_SUPPORT_FORM_FIELDS.ENCODING_BROADCAST_SOFTWARE}
          control={control}
          render={({ field, fieldState }) => (
            <Dropdown
              {...field}
              titleText='Which software is being used to broadcast the event?'
              className='gs--margin-bottom-sp5 gs--margin-bottom__sp5'
              id={ZENDESK_SUPPORT_FORM_FIELDS.ENCODING_BROADCAST_SOFTWARE}
              items={MANUAL_BROADCAST_SOFTWARE}
              itemToString={(item: { text: string; value: string }) => (item ? item.text : '')}
              label='Choose Software'
              invalid={!!fieldState.error}
              invalidText='Required'
              selectedItem={field.value}
              onChange={({ selectedItem }: { selectedItem: DropdownItem }) => {
                field.onChange(selectedItem);
              }}
            />
          )}
        />
      )}

      {streamingProgress === PendoSupportFormStreamingProgress.PIXELLOT && (
        <>
          <Controller
            name={ZENDESK_SUPPORT_FORM_FIELDS.PIXELLOT_LOCATION}
            control={control}
            render={({ field, fieldState }) => (
              <Dropdown
                {...field}
                titleText='Pixellot location'
                className='gs--margin-bottom-sp5 gs--margin-bottom__sp5'
                id={ZENDESK_SUPPORT_FORM_FIELDS.PIXELLOT_LOCATION}
                items={['Gym', 'Field', 'Pool', 'Softball', 'Baseball', 'Other']}
                label='Choose an option'
                invalid={!!fieldState.error}
                invalidText='Required'
                selectedItem={field.value || ''}
                onChange={({ selectedItem }: { selectedItem: string }) => {
                  field.onChange(selectedItem);
                }}
              />
            )}
          />

          <Controller
            name={ZENDESK_SUPPORT_FORM_FIELDS.PIXELLOT_ISSUES}
            control={control}
            render={({ field, fieldState }) => (
              <Dropdown
                {...field}
                titleText='Specify the issue'
                className='gs--margin-bottom-sp5 gs--margin-bottom__sp5'
                id={ZENDESK_SUPPORT_FORM_FIELDS.PIXELLOT_ISSUES}
                invalid={!!fieldState.error}
                invalidText='Required'
                items={PIXELLOT_ISSUES}
                label='Select Issue'
                itemToString={(item: DropdownItem) => (item ? item.text : '')}
                selectedItem={field.value}
                onChange={({ selectedItem }: { selectedItem: DropdownItem }) => {
                  field.onChange(selectedItem);
                }}
              />
            )}
          />
        </>
      )}

      <Controller
        name={ZENDESK_SUPPORT_FORM_FIELDS.GAME_ID}
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            id={ZENDESK_SUPPORT_FORM_FIELDS.GAME_ID}
            labelText='Game ID(s)'
            placeholder='Enter Game ID(s) if applicable '
            className='gs--margin-bottom-sp5 gs--margin-bottom__sp5'
            value={field.value}
            invalid={!!fieldState.error}
            invalidText={fieldState.error?.message}
            onChange={({ target: { value } }) => field.onChange(value)}
            onBlur={() => {
              const value = `${field.value ?? ''}`.trim();
              field.onChange(value);
              field.onBlur();
            }}
          />
        )}
      />
      <div className='gf-sub-title2 gs--margin-bottom-sp5 gs--margin-bottom__sp5'>
        When is your next event that you need this issue resolved by?
      </div>

      <Controller
        name={ZENDESK_SUPPORT_FORM_FIELDS.NEXT_EVENT_DATE}
        control={control}
        render={({ field, fieldState }) => (
          <DatePicker
            {...field}
            id={ZENDESK_SUPPORT_FORM_FIELDS.NEXT_EVENT_DATE}
            labelText='Enter Date'
            invalid={!!fieldState.error}
            invalidText={fieldState.error?.message}
            className='gs--margin-bottom-sp5 gs--margin-bottom__sp5'
            onChange={date => {
              field.onChange(date);
              document.getElementById(ZENDESK_SUPPORT_FORM_FIELDS.NEXT_EVENT_DATE)?.blur();
            }}
          />
        )}
      />

      <Controller
        name={ZENDESK_SUPPORT_FORM_FIELDS.ISSUE_DESCRIPTION}
        control={control}
        render={({ field, fieldState }) => (
          <TextArea
            {...field}
            id={ZENDESK_SUPPORT_FORM_FIELDS.ISSUE_DESCRIPTION}
            labelText='Description of Issue'
            placeholder='Please add description of issue'
            invalid={!!fieldState.error}
            invalidText={fieldState.error?.message}
            className='gs--margin-bottom-sp5 gs--margin-bottom__sp5'
          />
        )}
      />

      <div className='gf-sub-title2 gs--margin-bottom-sp1 gs--margin-bottom__sp1'>Attach any supporting files</div>
      <div className='cds--label gs--margin-bottom-sp5  gs--margin-bottom__sp5'>
        Max file size is 500kb. Supported file types are .jpg and .png.
      </div>

      <div className='gf-file_upload-container gs--margin-bottom-sp7 gs--margin-bottom__sp7'>
        <Icons.Upload className='gf-upload-icon' />
        <AttachmentsField name={ZENDESK_SUPPORT_FORM_FIELDS.ATTACHMENTS} />
      </div>

      <Controller
        name={ZENDESK_SUPPORT_FORM_FIELDS.URGENT_REQUEST}
        control={control}
        render={({ field }) => (
          <Toggle
            {...field}
            id={ZENDESK_SUPPORT_FORM_FIELDS.URGENT_REQUEST}
            className='gs--margin-bottom-sp5'
            labelA='No'
            labelB='Yes'
            labelText='This ticket has been flagged as urgent and needs a immediate response directed to the school'
            toggled={field.value}
            onToggle={toggled => field.onChange(toggled)}
          />
        )}
      />
      <div className='gf-button-wrapper gs--margin-bottom-sp5 gs--margin-top-sp6 gs--margin-bottom__sp5 gs--margin-top__sp6'>
        <Button
          type='button'
          kind='secondary'
          className='gs--margin-top-sp5 gs--margin-top__sp5'
          onClick={() => onBack(PendoSupportFormProgress.DEFAULT)}
        >
          Back
        </Button>
        <Button type='submit' className='gs--margin-top-sp5 gs--margin-top__sp5' disabled={isSubmitting}>
          {isSubmitting ? (
            <InlineLoading />
          ) : (
            <>
              Submit Request{' '}
              <Icons.SendAlt className='gs--margin-left-sp3 gs--margin-left__sp3' height='16' width='16' />
            </>
          )}
        </Button>
      </div>
    </>
  );

  function _syncFormView() {
    // if the user goes back to section 1, we need to sync the form view when they return
    const formValues = getValues();
    let progress = PendoSupportFormStreamingProgress.DEFAULT;
    if (formValues[ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_STREAMING].text === 'Pixellot issue') {
      progress = PendoSupportFormStreamingProgress.PIXELLOT;
    }
    if (formValues[ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_STREAMING].text === 'Manual broadcast') {
      progress = PendoSupportFormStreamingProgress.MANUAL_BROADCAST;
    }
    setStreamingProgress(progress);
  }
};

export { PendoSupportFormStreaming };
