import { css } from 'styled-components';

export default {
  tableContainer: css`
    .bx--tag {
      width: 4rem;
      margin: 0;
    }

    .bx--tag-clickable {
      cursor: pointer;
    }
    min-height: 610px;

    .bx--data-table tr td:nth-child(5) {
      max-width: 100px;
    }
  `,
  filterFormWrapper: css`
    position: relative;
    width: 100%;
    flex-direction: column;
    display: flex;
  `,
  filterFormContainer: css`
    position: absolute;
    z-index: 2;
    top: 50px;
    min-height: calc(100vh - 330px);
    right: 16px;
  `,
  selectAccountAssociatedWrapper: css`
    position: relative;
    flex-direction: column;
    display: flex;
  `,
  selectAccountAssociatedFormContainer: css`
    position: absolute;
    z-index: 2;
    top: 50px;
    max-width: 400px;
    right: 0px;
  `,
  insightContainer: css`
    top: 0;
    position: absolute;
    z-index: 2;
    min-height: calc(100vh - 330px);
    right: 16px;
  `
};
