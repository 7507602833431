import type { ReservedSeatingChartDTO } from '@gofan/api/reserved-seating';

import { config } from '@gofan/constants/config';

interface SeatsIoChartProps {
  seatsIoChart?: ReservedSeatingChartDTO;
}

const SeatsIoChart = ({ seatsIoChart }: SeatsIoChartProps) => (
  <a
    href={`https://app.seats.io/preview/eu/${config.SEATS_IO_PUBLIC_WORKSPACE_KEY}/${seatsIoChart?.key}`}
    target='_blank'
    rel='noopener noreferrer'
    title='Click to preview'
  >
    <img src={seatsIoChart?.publishedVersionThumbnailUrl ?? ''} alt='' />
  </a>
);

export { SeatsIoChart };
