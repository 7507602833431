import moment from 'moment-timezone';
import lowerCase from 'lodash/lowerCase';

import {
  TIME_ZONE_MAPPING,
  TIME_ZONE_ENUM,
  TIME_FORMAT_DEFAULT,
  DATE_FORMAT_DEFAULT_FULL_MONTH,
  DATE_FORMAT_DEFAULT_WITH_TIMEZONE,
  DATE_FORMAT_DEFAULT_WITH_FULL_TIME,
  DATE_FORMAT_DEFAULT
} from '@gofan/constants/date';
import isEmpty from 'lodash/isEmpty';

export const getTimeZone = (timeZone = '') => TIME_ZONE_MAPPING[lowerCase(timeZone)] || TIME_ZONE_ENUM.AMERICA_NEW_YORK;

class DateUtils {
  getTimeZone = (timeZone = '') => TIME_ZONE_MAPPING[lowerCase(timeZone)] || TIME_ZONE_ENUM.AMERICA_NEW_YORK;

  getStartOfDate = (date?: any) =>
    moment(date).set({
      hours: 0,
      minutes: 0,
      seconds: 1,
      milliseconds: 0
    });

  getEndOfDate = (date?: any) =>
    moment(date).set({
      hours: 23,
      minutes: 59,
      seconds: 59,
      milliseconds: 0
    });

  formatDate = ({ isUtc, date, defaultValue = '', format = DATE_FORMAT_DEFAULT }) => {
    if (date && moment.isDate(date)) {
      const dateObj = moment(date);
      return dateObj.isValid() ? dateObj.format(format) : defaultValue;
    }
    if (isEmpty(date)) return defaultValue;
    const mDate = isUtc ? moment(date).utc() : moment(date);
    return mDate.isValid() ? mDate.format(format) : defaultValue;
  };

  formatDateTime = ({ date, timeZone = TIME_ZONE_ENUM.AMERICA_NEW_YORK, parseZone = false }) => {
    const tz = timeZone || TIME_ZONE_ENUM.AMERICA_NEW_YORK;
    const parsedDate = parseZone ? moment(date).tz(tz) : moment(date);
    return {
      toTime: (format = TIME_FORMAT_DEFAULT) => (parsedDate.isValid() ? parsedDate.clone().format(format) : ''),
      toDate: (format = DATE_FORMAT_DEFAULT_FULL_MONTH) =>
        parsedDate.isValid() ? parsedDate.clone().format(format) : '',
      toDateTime: (dateFormat = DATE_FORMAT_DEFAULT_FULL_MONTH, timeFormat = TIME_FORMAT_DEFAULT) =>
        parsedDate.isValid() ? `${parsedDate.clone().format(dateFormat)} ${parsedDate.clone().format(timeFormat)}` : '',
      toDateTimeWithAt: (dateFormat = DATE_FORMAT_DEFAULT_FULL_MONTH, timeFormat = TIME_FORMAT_DEFAULT) =>
        parsedDate.isValid()
          ? `${parsedDate.clone().format(dateFormat)} at ${parsedDate.clone().format(timeFormat)}`
          : ''
    };
  };

  switchZone = (date: any, timeZone = TIME_ZONE_ENUM.AMERICA_NEW_YORK) => {
    if (timeZone === 'localZone') {
      return moment.parseZone(date).local(true).format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE);
    }
    const formattedDate = moment.parseZone(date).local(true).format(DATE_FORMAT_DEFAULT_WITH_FULL_TIME);
    const nextZone = moment
      .tz(formattedDate, TIME_ZONE_MAPPING[lowerCase(timeZone)] || TIME_ZONE_ENUM.AMERICA_NEW_YORK)
      .format('ZZ');
    return `${formattedDate}${nextZone}`;
  };

  getDateTimeUTC = (date: string) => moment.utc(date);

  getCurrentDateTime = (format: string = DATE_FORMAT_DEFAULT_WITH_TIMEZONE) => moment().format(format);

  convertDateWithTimeZone = (date: any, timeZone = TIME_ZONE_ENUM.AMERICA_NEW_YORK) => {
    const convertDate = moment(date).tz(TIME_ZONE_MAPPING[lowerCase(timeZone)] || TIME_ZONE_ENUM.AMERICA_NEW_YORK);
    return convertDate;
  };

  getMaxDateTime = (date: string, tz: string) => {
    const mDate = moment(date);
    return mDate.tz(tz).add(90, 'days');
  };

  getSchoolYearByDate = (date: string, timeZone: string = TIME_ZONE_ENUM.AMERICA_NEW_YORK) => {
    const mDate = moment.utc(date);
    const isAfterJune = mDate.isAfter(mDate.clone().month('JUN').endOf('month'));
    const startDate = isAfterJune
      ? mDate.clone().month('JUL').startOf('month')
      : mDate.clone().month('JUL').startOf('month').subtract(1, 'year');
    const endDate = isAfterJune
      ? mDate.clone().month('JUN').endOf('month').add(1, 'year')
      : mDate.clone().month('JUN').endOf('month');
    return {
      yearStart: this.switchZone(startDate, timeZone),
      yearEnd: this.switchZone(endDate, timeZone)
    };
  };
}

export default new DateUtils();
