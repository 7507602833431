import React, { useContext } from 'react';
import { isEmpty, trim } from 'lodash';
import { FastFormContext } from './FastFormBase';
import { StyledFormButton } from '../../components/styledSemantic';

const formSquisher = (values, fieldProps = {}) => {
  const finalForm = {};
  Object.keys(values).forEach(key => {
    if (!isEmpty(fieldProps[key]) && fieldProps[key].trimFormValue) {
      finalForm[key] = trim(values[key].value);
    } else {
      finalForm[key] = values[key].value;
    }
  });
  Object.keys(finalForm).forEach(key => {
    if (finalForm[key] === '') {
      finalForm[key] = null;
    }
  });
  return finalForm;
};

// used to make sure the lastSavedValue of each input element
// is up to date, nick showed me how. (thanks nick)
const updateLastSaveValues = (form, updateField) => {
  Object.keys(form).forEach(key => {
    updateField(key, {
      ...form[key],
      lastSavedValue: form[key].value,
      dirty: false
    });
  });
};

export default ({
  text = 'Save',
  fieldProps = {},
  preventUpdateLastSaveValues,
  submitAction = () => null,
  ...props
}) => {
  const { form, updateField } = useContext(FastFormContext);
  return (
    <StyledFormButton
      primary
      onClick={() => {
        submitAction(formSquisher(form, fieldProps), form, updateField);
        if (!preventUpdateLastSaveValues) {
          updateLastSaveValues(form, updateField);
        }
      }}
      className='medium-size'
      {...props}
    >
      {text}
    </StyledFormButton>
  );
};
