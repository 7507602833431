import { css } from 'styled-components';

export default {
  table: css`
    thead th.sorted {
      position: relative;
      &:after {
        position: absolute;
      }
    }

    .action-button-cell {
      width: 40px;
      height: 40px;
    }

    tbody tr td {
      line-height: 2.5rem;

      &.white-space-pre {
        white-space: pre;
      }
    }

    .group-label-cell {
      font-weight: bold;
      background-color: #f3f3f3;
    }

    ${({ noresults }) =>
      noresults &&
      css`
        tfoot.table-pagination {
          display: none;
        }

        td.no-results-wrapper {
          padding: 0;
        }
      `}
  `
};
