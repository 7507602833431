import { Tag as CarbonTag } from '@carbon/react';
import classNames from 'classnames';

import type { TagBaseProps as CarbonTagProps } from '@carbon/react';

import './Tag.scss';

type TagProps = CarbonTagProps;

export const Tag = (props: TagProps) => <CarbonTag {...props} className={classNames('gf--tag', props.className)} />;
