import memoizeOne from 'memoize-one';
import { isEmpty } from 'lodash';

import {
  AccountService,
  canAccessAccount,
  canEdit,
  checkInternalUser,
  isEditableComissioner,
  isViewableCommissioner
} from '@gofan/api';
import { DISTRICT_CONFERENCE_SCHOOL_TYPES, SCHOOL_TYPES } from '@gofan/constants';

import type { AccountDTO, SchoolConfig, UserDTO } from '@gofan/api';

export const checkIsDistrictUser = memoizeOne((account: AccountDTO | undefined, currentUser: UserDTO) => {
  if (isEmpty(currentUser) || isEmpty(account)) {
    return false;
  }

  const canAccessSchool = canAccessAccount(account.id, currentUser);
  const isDistrictSchool = account?.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT;

  return canAccessSchool && isDistrictSchool;
});

export const userIsDistrictUser = memoizeOne((account: AccountDTO | undefined, currentUser: UserDTO) => {
  if (isEmpty(currentUser) || isEmpty(account)) {
    return false;
  }

  const canAccessSchool = currentUser?.userAccountContexts?.some(
    context => context.accountId === account?.id && !context.inactive
  );

  const isDistrictSchool = account?.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT;

  return canAccessSchool && isDistrictSchool;
});

export const checkIsEditableDistrictUser = memoizeOne((account: AccountDTO | undefined, currentUser: UserDTO) => {
  if (isEmpty(currentUser) || isEmpty(account)) {
    return false;
  }
  const canEditSchool = canEdit(account.id, currentUser);
  const isDistrictSchool = account?.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT;
  return canEditSchool && isDistrictSchool;
});

export const checkIsAccountUserUnderDistrict = memoizeOne(
  (account: AccountDTO | undefined, districtAccount: AccountDTO | undefined, currentUser: UserDTO) => {
    if (isEmpty(currentUser) || isEmpty(account) || isEmpty(districtAccount)) {
      return false;
    }

    const canAccessSchool = canAccessAccount(account.id, currentUser);
    const canAccessDistrictSchool = canAccessAccount(districtAccount.id, currentUser);
    const isUnderDistrict = account?.id !== districtAccount?.id && account?.districtHuddleId === districtAccount?.sfId;

    return canAccessSchool && !canAccessDistrictSchool && isUnderDistrict;
  }
);

export const checkIsCommissioner = memoizeOne((account: AccountDTO | undefined, currentUser: UserDTO) => {
  if (isEmpty(currentUser) || isEmpty(account)) {
    return false;
  }

  const isCommissioner = isViewableCommissioner(currentUser, account.id);
  const isAthleticConference = account?.gofanSchoolType === SCHOOL_TYPES.ATHLETIC_CONFERENCE;

  return isCommissioner && isAthleticConference;
});

export const checkIsEditableCommissioner = memoizeOne((account: AccountDTO | undefined, currentUser: UserDTO) => {
  if (isEmpty(currentUser) || isEmpty(account)) {
    return false;
  }

  const isCommissionerEditor = isEditableComissioner(currentUser, account.id);
  const isAthleticConference = account?.gofanSchoolType === SCHOOL_TYPES.ATHLETIC_CONFERENCE;

  return isCommissionerEditor && isAthleticConference;
});

export const canViewSchoolList = memoizeOne((account: AccountDTO | undefined, currentUser: UserDTO) => {
  if (isEmpty(currentUser) || isEmpty(account)) {
    return false;
  }

  const isInternalUser = checkInternalUser(currentUser.role);
  const isCommissioner = checkIsCommissioner(account, currentUser);

  return isInternalUser || isCommissioner;
});

export const hasAccountOrConferenceAccountEditableCommissioner = (
  currentUser: UserDTO,
  account?: AccountDTO,
  conferenceAccount?: AccountDTO
) => {
  if (!currentUser) return false;
  if (checkInternalUser(currentUser.role)) return true;
  if (account && checkIsEditableCommissioner(account, currentUser)) return true;
  if (conferenceAccount && checkIsEditableCommissioner(conferenceAccount, currentUser)) return true;
  return false;
};

export const hasAccountOrConferenceAccountViewableCommissioner = (
  currentUser: UserDTO,
  account?: AccountDTO,
  conferenceAccount?: AccountDTO
) => {
  if (!currentUser) return false;
  if (checkInternalUser(currentUser.role)) return true;
  if (account && checkIsCommissioner(account, currentUser)) return true;
  if (conferenceAccount && checkIsCommissioner(conferenceAccount, currentUser)) return true;
  return false;
};

// Because this function is calling the API, be aware to use it to avoid calling it multiple times
export const hasAccountOrDistrictAccountCommissionerV2 = async (
  currentUser?: UserDTO,
  account?: AccountDTO,
  onlyCheckViewable = false
) => {
  if (!currentUser) return false;
  if (checkInternalUser(currentUser.role)) return true;
  if (account && onlyCheckViewable && checkIsCommissioner(account, currentUser)) return true;
  if (account && checkIsEditableCommissioner(account, currentUser)) return true;

  if (account?.conferenceLeagueC) {
    try {
      const conferenceAccount = await AccountService.getAccountByConferenceLeagueC(account?.conferenceLeagueC);
      if (conferenceAccount && onlyCheckViewable && checkIsCommissioner(conferenceAccount, currentUser)) return true;
      if (conferenceAccount && checkIsEditableCommissioner(conferenceAccount, currentUser)) return true;
    } catch (err) {
      return false;
    }
  }
  return false;
};

export const hasAccountIsEditableDistrictOrCommissioner = (currentUser?: UserDTO, account?: AccountDTO) => {
  if (!currentUser || !account || !Object.values(DISTRICT_CONFERENCE_SCHOOL_TYPES).includes(account.gofanSchoolType))
    return false;
  if (checkInternalUser(currentUser.role)) return true;
  if (checkIsEditableCommissioner(account, currentUser) || checkIsEditableDistrictUser(account, currentUser))
    return true;

  return false;
};

export const hasDistrictAccess = ({
  currentUser,
  districtAccount,
  schoolConfig
}: {
  currentUser: UserDTO;
  districtAccount?: AccountDTO;
  schoolConfig?: SchoolConfig;
}) => {
  if (isEmpty(currentUser) || isEmpty(districtAccount) || isEmpty(schoolConfig)) return false;

  if (checkInternalUser(currentUser.role)) return true;

  const isDistrictUser = checkIsDistrictUser(districtAccount, currentUser);
  const { districtAllowDistrictUser } = schoolConfig;
  return !isDistrictUser || districtAllowDistrictUser;
};

export const hasSchoolAccess = ({
  currentUser,
  homeAccount,
  financialAccount,
  schoolConfig
}: {
  currentUser: UserDTO;
  homeAccount?: AccountDTO;
  financialAccount?: AccountDTO;
  schoolConfig?: SchoolConfig;
}) => {
  if (isEmpty(currentUser) || isEmpty(homeAccount) || isEmpty(financialAccount) || isEmpty(schoolConfig)) return false;

  if (checkInternalUser(currentUser.role)) return true;

  const districtAccount =
    financialAccount.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT
      ? financialAccount
      : undefined;
  const isAccountUserUnderDistrict = checkIsAccountUserUnderDistrict(homeAccount, districtAccount, currentUser);
  const { districtAllowSchoolUser } = schoolConfig;
  return !isAccountUserUnderDistrict || districtAllowSchoolUser;
};
