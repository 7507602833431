import React from 'react';
import classNames from 'classnames';

export type EventType = {
  id: string;
  src: string;
  name: string;
  activityId?: number;
};

export type EventTypes = {
  [k: string]: EventType;
};

export interface SingleEventTypeProps {
  eventType: EventType;
  selected: boolean;
  disabled: boolean;
  onSelectedItem: (item: EventType) => void;
}

const SingleEventType = ({ eventType, selected, disabled, onSelectedItem }: SingleEventTypeProps) => (
  <div
    aria-hidden='true'
    className={classNames('single-event', {
      'single-event--selected': selected,
      'single-event--disabled': disabled
    })}
    onClick={() => !disabled && onSelectedItem(eventType)}
  >
    <img alt='' className='single-event--img' src={eventType.src} />
    <p className='gs--padding-bottom__sp5 single-event--title gs--body-short-02 gs--text-02'>{eventType.name}</p>
  </div>
);

export default SingleEventType;
