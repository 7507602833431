import React, { useEffect, useState, useContext } from 'react';
import { isEmpty } from 'lodash';
import { Form, Select } from 'semantic-ui-react';
import styled from 'styled-components';
import FormSafeguard from './FormSafeguard';
import { FastFormContext } from './FastFormBase';
import ViewOnlyGuard from './ViewOnlyGuard';
import styles from './styles';
import { StyledStatic } from '../../components/styledSemantic';

const StyledFieldLabel = styled.label`
  ${styles.dropdown.fieldLabel}
`;

const StyledOptionalLabel = styled.span`
  ${styles.baseInput.optionalLabel};
`;

const Wrapper = styled.div`
  ${styles.dropdown.wrapper};
`;

const StyledErrorMessage = styled.p`
  ${styles.baseInput.errorMessage};
`;

const labelProps = ({ label, required, hideLabel, optionalLabel }) =>
  hideLabel
    ? null
    : {
        children: (
          <StyledFieldLabel>
            {label}
            {!required && (
              <StyledOptionalLabel>{` ${optionalLabel}`}</StyledOptionalLabel>
            )}
          </StyledFieldLabel>
        )
      };

export default ({
  label,
  options,
  formKey,
  nullText = '',
  optionalLabel = '(optional)',
  required = false,
  overwritable = false,
  showPlaceHolder = false,
  multiple = false,
  hideLabel = false,
  size = '',
  iconStyles,
  placeHolder = '',
  readOnly = false,
  customDisplayValue = null,
  onChange = () => null,
  onBlur = () => null,
  defaultValue,
  ...props
}) => {
  const fastFormContext = useContext(FastFormContext);
  const formValue = fastFormContext.form[formKey];
  const [resolvedOptions, setOptions] = useState([]);
  const defaultOptions = [
    { key: 'no key', value: 'no value', text: 'no options provided' }
  ];

  useEffect(() => {
    fastFormContext.updateField(formKey, {
      ...formValue,
      inForm: true,
      required
    });
  }, []);

  useEffect(
    () => {
      defaultValue &&
        fastFormContext.updateField(formKey, {
          ...formValue,
          value: defaultValue.value
        });
    },
    [defaultValue]
  );

  useEffect(
    () => {
      if (options == null) {
        setOptions(defaultOptions);
      } else if (typeof options === 'object') {
        setOptions(options);
      } else if (typeof options === 'function') {
        setOptions(options());
      }
    },
    [options]
  );

  const placeHolderText = placeHolder || label || '';
  const className = [];
  if (overwritable) {
    className.push('highlighted');
  }
  if (readOnly) {
    className.push('disabled');
  }
  const onHandleChange = (e, { value }) => {
    let valid = true;
    let errors = [];
    if (
      ((value === null || value === '') && !multiple && required) ||
      (isEmpty(value) && multiple && required)
    ) {
      valid = false;
      errors = errors.concat(`${label} is required.`);

      fastFormContext.updateField(formKey, {
        ...formValue,
        value,
        touched: true,
        errors,
        valid
      });
    } else {
      fastFormContext.updateField(formKey, {
        ...formValue,
        value,
        touched: true,
        valid,
        errors
      });
    }
    onChange(value);
  };
  const onHandleBlur = () => {
    if (onBlur) {
      onBlur({ fastFormContext, formKey });
      return;
    }
    let { errors } = formValue;
    if (
      ((formValue.value === null || formValue.value === '') &&
        !multiple &&
        required) ||
      (isEmpty(formValue.value) && multiple && required)
    ) {
      errors = [`${label} is required.`];
    } else {
      errors = [];
    }
    fastFormContext.updateField(formKey, {
      ...formValue,
      errors
    });
  };
  return (
    <FormSafeguard formKey={formKey}>
      <ViewOnlyGuard nullText={nullText} label={label} formKey={formKey}>
        {readOnly ? (
          <StyledStatic
            label={hideLabel ? '' : label}
            value={
              customDisplayValue
                ? customDisplayValue(formValue.value)
                : formValue.value
            }
          />
        ) : (
          <Wrapper size={size} iconStyles={iconStyles}>
            <Form.Field
              {...props}
              fluid
              multiple={multiple}
              className={className.join(' ').trim()}
              required={required}
              value={formValue.value}
              selectOnBlur={false}
              selectOnNavigation={false}
              control={Select}
              label={labelProps({ label, required, hideLabel, optionalLabel })}
              options={resolvedOptions}
              placeholder={showPlaceHolder ? placeHolderText : ''}
              onBlur={onHandleBlur}
              onChange={onHandleChange}
            />
          </Wrapper>
        )}
        {(required || onBlur) &&
          formValue.errors &&
          formValue.errors.length > 0 &&
          formValue.errors.map(e => (
            <StyledErrorMessage key={e}>{e}</StyledErrorMessage>
          ))}
      </ViewOnlyGuard>
    </FormSafeguard>
  );
};
