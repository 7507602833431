export const SEARCH_FILTER = [''];

class SearchEventRefundRequest {
  constructor({ search, filter }) {
    this.raw = { search, filter };
  }

  get search() {
    return this.raw.search;
  }

  get filter() {
    return this.raw.filter;
  }

  toJSON() {
    return {
      search: this.search,
      filter: this.filter
    };
  }
}

export default SearchEventRefundRequest;
