import { useEffect, useRef } from 'react';
import { Column, Row, TextArea } from 'carbon-components-react';
import moment from 'moment-timezone';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { DatePicker } from '@app/commons/generic-components/date-picker/date-picker';
import { TimePicker } from '@app/commons/generic-components/time-picker/time-picker';

import {
  EVENT_TYPES,
  DATE_PICKER_FORMAT_DEFAULT,
  DATE_TIME_PICKER_FORMAT_DEFAULT,
  TIME_FORMAT_DEFAULT,
  FUNDRAISER
} from '@gofan/constants';
import { ADMIN_MAX_DATE, USER_MAX_DATE } from '@app/pages/EventInformationV2/constant';
import strings from '@events/strings';

import { checkInternalUser } from '@gofan/api/users';

import type { UserDTO } from '@gofan/api/users';
import type { EventDuplicationValues } from '../../utils';

import styles from './event-duplication-form.module.scss';

interface EventDuplicationFormProps {
  id: string | number;
  activityType: string;
  eventLabel: string;
  disabled?: boolean;
  data: {
    currentUser: UserDTO;
    initFormValues: EventDuplicationValues;
  };
}

export const EventDuplicationForm = ({ id, activityType, eventLabel, disabled, data }: EventDuplicationFormProps) => {
  const { currentUser, initFormValues } = data ?? {};
  const isFundraiser = activityType === FUNDRAISER;
  const dateTimeLabel = isFundraiser ? 'date' : 'date and time';

  const {
    control,
    setValue,
    trigger,
    formState: { errors }
  } = useFormContext();

  const name = useWatch({ control, name: 'name' });
  const startDate = useWatch({ control, name: 'startDate' });
  const startTime = useWatch({ control, name: 'startTime' });
  const endDate = useWatch({ control, name: 'endDate' });
  const endTime = useWatch({ control, name: 'endTime' });

  const mMinDateTime = moment(new Date());
  const minDate = mMinDateTime.clone().format(DATE_PICKER_FORMAT_DEFAULT);
  const maxStartDateTime = checkInternalUser(currentUser.role) ? ADMIN_MAX_DATE : USER_MAX_DATE;
  const mMaxDateTime = moment(maxStartDateTime, DATE_PICKER_FORMAT_DEFAULT);

  const prevStartDate = useRef<any>(initFormValues.startDate);
  const prevStartTime = useRef<any>(initFormValues.startTime);

  const setPrevStartDateTime = (startDateValue: any, startTimeValue: any) => {
    const mStartDateTime = moment(`${startDateValue} ${startTimeValue}`, [
      DATE_TIME_PICKER_FORMAT_DEFAULT,
      DATE_PICKER_FORMAT_DEFAULT
    ]);
    if (mStartDateTime.isValid()) {
      prevStartDate.current = startDateValue;
      prevStartTime.current = startTimeValue;
    }
  };

  const setEventEndDateTime = (startDateValue: any, startTimeValue: any) => {
    const mPreStartDateTime = moment(`${prevStartDate.current} ${prevStartTime.current}`, [
      DATE_TIME_PICKER_FORMAT_DEFAULT,
      DATE_PICKER_FORMAT_DEFAULT
    ]);

    const mStartDateTime = moment(`${startDateValue} ${startTimeValue}`, [
      DATE_TIME_PICKER_FORMAT_DEFAULT,
      DATE_PICKER_FORMAT_DEFAULT
    ]);

    if (mPreStartDateTime.isValid() && mStartDateTime.isValid()) {
      const unitsAdd = mStartDateTime.diff(mPreStartDateTime, 'seconds');
      const mCurEndDateTime = moment(`${endDate} ${endTime}`, [
        DATE_TIME_PICKER_FORMAT_DEFAULT,
        DATE_PICKER_FORMAT_DEFAULT
      ]);

      if (mCurEndDateTime.isValid()) {
        const addedEndDateTime = mCurEndDateTime.clone().add(unitsAdd, 'seconds');

        setValue('endTime', addedEndDateTime.format(TIME_FORMAT_DEFAULT));
        setValue('endDate', addedEndDateTime.format(DATE_PICKER_FORMAT_DEFAULT));
      }
    }
  };

  const onChangeStartDate = (date: any, fieldControl: any) => {
    fieldControl?.onChange(date);
    setEventEndDateTime(date, startTime);
    setPrevStartDateTime(date, startTime);
  };

  const onChangeStartDateTime = (time: any, fieldControl: any) => {
    fieldControl?.onChange(time);
    setEventEndDateTime(startDate, time);
    setPrevStartDateTime(startDate, time);
  };

  const onBlurDateTime = (fieldControl: any) => {
    fieldControl?.onBlur();
    trigger(['startDate', 'startTime']);
  };

  useEffect(() => {
    setPrevStartDateTime(startDate, startTime);
  }, [startDate, startTime]);

  return (
    <Row className='gs--margin-top-sp5'>
      <Column lg={7}>
        <div className='gs--padding-bottom__sp5'>
          <div className='gs--body-short-02-semibold gs--text-01 gs--margin-bottom-sp4'>
            {strings.EVENT_DUPLICATION.NEW_START_DATE_TIME.replace(/{eventType}/g, eventLabel).replace(
              /{dateTimeLabel}/g,
              dateTimeLabel
            )}
          </div>
          <div className={styles['datetime-container']}>
            <Controller
              name='startDate'
              control={control}
              render={({ field }) => (
                <div className={styles.expand}>
                  <input ref={field.ref} className={styles['hidden-item']} />
                  <DatePicker
                    id={`event-${id}-startDate`}
                    labelText={(EVENT_TYPES.CONFIG as any)[activityType].EDIT_EVENT_START_DATE}
                    minDate={minDate}
                    maxDate={maxStartDateTime}
                    value={field.value}
                    disabled={disabled}
                    invalid={!!errors?.startDate}
                    invalidText={errors?.startDate?.message}
                    onBlur={() => onBlurDateTime(field)}
                    onChange={date => onChangeStartDate(date, field)}
                  />
                </div>
              )}
            />
            {!isFundraiser && (
              <Controller
                name='startTime'
                control={control}
                render={({ field }) => (
                  <div className='gs--margin-left-sp5'>
                    <input ref={field.ref} className={styles['hidden-item']} />
                    <TimePicker
                      id={`event-${id}-startTime`}
                      labelText={(EVENT_TYPES.CONFIG as any)[activityType].EDIT_EVENT_START_TIME}
                      value={field.value}
                      disabled={disabled}
                      invalid={!!errors?.startTime}
                      invalidText={errors?.startTime?.message}
                      onBlur={() => onBlurDateTime(field)}
                      onChange={date => onChangeStartDateTime(date, field)}
                    />
                  </div>
                )}
              />
            )}
          </div>
        </div>
        <div className='gs--padding-bottom__sp5'>
          <Controller
            name='name'
            control={control}
            render={({ field }) => (
              <>
                <div className='gs--body-short-02-semibold gs--text-01'>
                  {strings.EVENT_DUPLICATION.NEW_EVENT_NAME.replace(/{eventType}/g, eventLabel)}
                </div>
                <div className={`${styles['text-counter']} gs--label-01 gs--text-01`}>{`${name?.length ?? 0}/100`}</div>
                <TextArea
                  id={`event-${id}-name`}
                  ref={field.ref}
                  rows={2}
                  labelText=''
                  placeholder={strings.EVENT_DUPLICATION.EVENT_NAME_PLACEHOLDER}
                  maxLength={100}
                  value={field.value}
                  invalid={!!errors?.name}
                  invalidText={errors?.name?.message}
                  onChange={({ target: { value } }) => field.onChange(value)}
                  onBlur={() => {
                    const value = `${field.value ?? ''}`.trim();
                    field.onChange(value);
                    field.onBlur();
                  }}
                />
              </>
            )}
          />
        </div>
      </Column>
    </Row>
  );
};
