/* Order of permissions:
Super Administrator
System Finance Admin
System Admin
System User
Limited System User
 */

import { USER_ROLE_ACCOUNT_USER, USER_ROLE_FAN } from '@gofan/api/users';
import { contactPermisson } from '../helpers';
import { USER_STATE_DISABLED } from '../../../api/model/user.model';

export default {
  view: {
    all: true,
    beta: {
      dashboard: false,
      sideMenu: false
    },
    fanSupport: true,
    activityTree: false,
    admin1: true,
    venues: false,
    account: {
      contact: {
        removable: true,
        permissions: {
          report: true,
          boxoffice: true,
          insider: false,
          financial: true,
          editor: {
            checkUserRole: ({ account: { user } }) => {
              if (!user || user.role === USER_ROLE_FAN) {
                return true;
              }
              return [USER_ROLE_ACCOUNT_USER].includes(user.role);
            }
          }
        }
      }
    }
  },
  create: {
    user: true,
    account: {
      contact: true,
      tickets: true
    },
    activity: false,
    rate: false,
    products: true
  },
  edit: {
    user: {
      editable: {
        checkUserRole: ({ currentUser, user }) =>
          currentUser.email === user.email || !['SUPER_ADMIN', 'SYSTEM_FINANCE_ADMIN'].includes(user.role)
      },
      communicationReporting: true
    },
    account: {
      all: true,
      tickets: true,
      accountItem: {
        editable: true
      },
      contact: {
        account_role: {
          // if there are multiple functions inside of this, ALL must evaluate to true for this to be true
          // if you can't edit a user, you cant do this either
          checkUserRole: ({ account }) => !['SUPER_ADMIN', 'SYSTEM_FINANCE_ADMIN'].includes(account.user.role),
          makeSureActive: ({ account }) => {
            if (account.account.inactive) {
              return false;
            }
            return true;
          },
          makeSureAccountRolesFit: ({ account }) => {
            const { type } = account;
            // you can edit everything but finance
            return !['FINANCE', 'INSIDER'].includes(type);
            // return type !== 'FINANCE';
          },
          canAssignAdminPermission: contactPermisson.canAssignAdmin
        },
        editable: {
          canEditActiveStatus: ({ currentUser, account }) => currentUser.email !== account.email,
          canEdit: ({ account }) => {
            const { contactUserStatus } = account || {};

            return `${contactUserStatus}`.toUpperCase() !== USER_STATE_DISABLED;
          }
        }
      },
      goFanPage: {
        editable: {
          canEditCustomName: true,
          canEditPageStatus: true,
          canEditSearchable: true
        }
      },
      gofanPage: {
        editable: true
      }
    },
    fanSupport: {
      refundable: true
    },
    activity: {
      editable: false
    },
    rate: false,
    products: true,
    event: {
      editable: true
    }
  },
  delete: {
    products: false
  },
  accounting: {
    manage: {
      all: true, // used to hide/show the side menu item
      auditOnly: true // used to only show O&R and Disputes
    }
  }
};
