import React from 'react';
import styled from 'styled-components';
import { InlineNotification } from 'carbon-components-react';
import ReactDOM from 'react-dom';

import styles from './styles';

const Wrapper = styled.div`
  ${styles.wrapper}
`;

const NotificationWrapper = styled.div`
  ${styles.notificationWrapper}
`;

function NotificationTooltip({
  kind,
  title,
  subtitle,
  caption,
  lowContrast,
  triggerOn,
  children
}) {
  const [isShown, setIsShown] = React.useState(false);
  const [coordinate, setCoordinate] = React.useState({
    xPosition: 0,
    yPosition: 0
  });

  const getMousePosition = React.useCallback(
    ({ pageX: xPosition, pageY: yPosition }) => {
      if (isShown && triggerOn) {
        setCoordinate({
          xPosition,
          yPosition
        });
      }
    },
    [isShown, triggerOn, coordinate, setCoordinate]
  );

  React.useEffect(
    () => {
      window.addEventListener('mousemove', getMousePosition);
      return () => {
        window.removeEventListener('mousemove', getMousePosition);
      };
    },
    [getMousePosition]
  );

  React.useEffect(
    () => {
      if (!triggerOn && isShown) {
        setIsShown(false);
      }
    },
    [triggerOn, isShown, setIsShown]
  );

  return (
    <>
      <Wrapper
        triggerOn={triggerOn}
        onMouseEnter={() => {
          if (triggerOn) {
            setIsShown(true);
          }
        }}
        onMouseLeave={() => {
          setIsShown(false);
        }}
      >
        {children}
      </Wrapper>
      {isShown && triggerOn ? (
        ReactDOM.createPortal(<NotificationWrapper
          style={{
            top: coordinate.yPosition - 50,
            left: coordinate.xPosition + 20
          }}
        >
          <InlineNotification
            kind={kind}
            lowContrast={lowContrast}
            title={title}
            caption={caption}
            subtitle={subtitle}
            onCloseButtonClick={() => setIsShown(false)}
          />
        </NotificationWrapper>, document.body)
      ) : null}
    </>
  );
}

NotificationTooltip.defaultProps = {
  kind: 'info',
  title: '',
  caption: '',
  subTitle: '',
  lowContrast: false,
  triggerOn: false,
  children: null
};

export default NotificationTooltip;
