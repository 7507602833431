import { Tooltip } from 'carbon-components-react';
import React from 'react';

export default ({
  light = true,
  size = 'small',
  color = 'primary-color',
  text = '',
  font = 'PLEXSANS-REGULAR',
  onChange = () => null,
  ...props
}) => (
  <React.Fragment>
    <Tooltip
      {...props}
      light={light}
      showIcon={false}
      open={false}
      triggerText={text}
      onChange={onChange}
      triggerClassName={`${font} ${size} ${color} no-focus`}
    />
  </React.Fragment>
);
