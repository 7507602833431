import { useState, useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import flatMap from 'lodash/flatMap';
import isEqual from 'lodash/isEqual';

import { FlexGrid, Row, Column } from '@gofan/components';

import { EventEditorProvider } from './EventEditor.context';
import { EditorTitle } from '@events/components/editor/EditorTitle';
import { EditorProgress } from '@events/components/editor/EditorProgress';
import { EditorSettings } from '@events/components/editor/EditorSettings';

import { EVENT_TYPES } from '@app/pages/EventInformationV2/constants';
import type { AccountDTO } from '@gofan/api';
import type { Progress, ProgressSection } from '@events/components/editor/EditorProgress';
import type { SettingSection } from '@events/components/editor/EditorSettings';

import './EventEditor.view.scss';

interface EventEditorProps {
  activityType: string;
  editMode: boolean;
  isPracticeSchedule?: boolean;
  account?: AccountDTO;
  eventProgresses: ProgressSection[];
  eventSettings: SettingSection[];
  defaultSection?: string;
  layout?: { [key: string]: number };
  setSeasonModalOpen?: (value: boolean) => void;
}

export const EventEditor = ({
  activityType,
  isPracticeSchedule = false,
  editMode,
  account,
  eventProgresses,
  eventSettings,
  defaultSection,
  layout,
  children,
  setSeasonModalOpen
}: React.PropsWithChildren<EventEditorProps>) => {
  const [progressSections, setProgressSections] = useState(eventProgresses);
  const [selectedProgressSection, setSelectedProgressSection] = useState(
    defaultSection ?? progressSections?.[0]?.title
  );
  const [settingSections, setSettingSections] = useState(eventSettings);
  const [selectedSettingSection, setSelectedSettingSection] = useState(0);

  const titleEventPage = useMemo(
    () =>
      editMode
        ? (EVENT_TYPES.CONFIG as any)?.[activityType]?.EDIT_LABEL
        : (EVENT_TYPES.CONFIG as any)?.[activityType]?.CREATE_LABEL,
    [activityType, editMode]
  );

  useEffect(() => {
    if (!isEqual(progressSections, eventProgresses)) {
      const currentProcesses = flatMap(progressSections, 'progresses');
      const updateEventProgresses = eventProgresses.map(section => ({
        ...section,
        progresses: section.progresses.map(progress => {
          const found = currentProcesses?.find(item => item.id === progress.id);
          if (!isEmpty(found)) {
            return { ...found, ...progress };
          }

          return { ...progress };
        })
      }));

      setProgressSections(updateEventProgresses);
    }
  }, [eventProgresses]);

  useEffect(() => {
    if (!isEqual(settingSections, eventSettings)) {
      setSettingSections(eventSettings);
    }
  }, [eventSettings]);

  return (
    <FlexGrid className='event-editor'>
      <Row>
        <Column>
          <EditorTitle account={account} title={isPracticeSchedule ? 'Create Practice Schedule' : titleEventPage} />
        </Column>
      </Row>
      <Row className='gs--margin-top-sp5'>
        <Column lg={layout?.left ?? 3}>
          <EditorProgress
            isPracticeSchedule={isPracticeSchedule}
            key={selectedProgressSection}
            sections={progressSections}
            selectedSection={selectedProgressSection}
            onOpenSection={_onOpenSection}
            onClickProgress={progress => _onUpdateProgresses([{ id: progress, current: true }])}
            setSeasonModalOpen={setSeasonModalOpen}
          />
        </Column>
        <Column lg={layout?.content ?? 9}>
          <EventEditorProvider
            progressSections={progressSections}
            setProgressSections={setProgressSections}
            selectedProgressSection={selectedProgressSection}
            setSelectedProgressSection={setSelectedProgressSection}
            onFocusSection={process => _onUpdateProgresses([{ id: process, current: true }])}
            onGenerateSettings={settings => _onUpdateSettings(settings)}
          >
            {children}
          </EventEditorProvider>
        </Column>
        <Column lg={layout?.right ?? 4}>
          <EditorSettings
            isPracticeSchedule={isPracticeSchedule}
            header={(EVENT_TYPES.CONFIG as any)[activityType]?.YOUR_SETTINGS}
            sections={settingSections}
            selectedTab={selectedSettingSection}
            setSelectedTab={setSelectedSettingSection}
          />
        </Column>
      </Row>
    </FlexGrid>
  );

  function _onOpenSection(section: string) {
    const progressSection = progressSections.find(item => item.title === section);
    if (!isEmpty(progressSection)) {
      setSelectedProgressSection(section);
      setSelectedSettingSection(progressSection.settingIndex);
      _onUpdateProgresses([{ id: progressSection.progresses?.[0]?.id, current: true }]);
    }
  }

  function _onUpdateProgresses(changes: Partial<Progress>[]) {
    setProgressSections(curSections =>
      curSections.map(section => ({
        ...section,
        progresses: section.progresses.map(progress => {
          const found = changes.find(item => item.id === progress.id);
          if (!isEmpty(found)) {
            return { ...progress, ...found };
          }
          if (changes.length === 1 && changes?.[0]?.current) {
            return { ...progress, current: false };
          }
          return { ...progress };
        })
      }))
    );
  }

  function _onUpdateSettings(changes: SettingSection[]) {
    setSettingSections(changes);
  }
};
