import { useEffect, useContext, useState, SetStateAction, Dispatch } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { Section } from '@events/components/editor/Section';
import { SponsorshipDetails } from '../SponsorshipDetails/SponsorshipDetails';
import { SponsorshipAssignment } from '../SponsorshipAssignment/SponsorshipAssignment';
import { SponsorshipSiteInfo } from '../SponsorshipSiteInfo/SponsorshipSiteInfo';
import { SponsorshipPreview } from '../SponsorshipPreview/SponsorshipPreview';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { SponsorshipService, SponsorshipSetupSchema } from '@gofan/api';

import { EventEditorContext } from '@events/views/EventEditor/EventEditor.context';

import './SponsorshipCreationForm.scss';
import { useParams } from 'react-router-dom';
import parseFileFromUrl from '@common/utils/parseFileUpload';
import { Spinner } from '@gofan/components';

const SponsorshipCreationForm = ({
  setSetupProgressFields
}: {
  setSetupProgressFields: Dispatch<SetStateAction<NonNullable<unknown>>>;
}) => {
  const { onFocusSection } = useContext(EventEditorContext);
  const [defaultValues, setDefaultValues] = useState<any>({
    sponsorshipName: '',
    sponsorshipType: '',
    emailAddress: '',
    phoneNumber: '',
    tagline: '',
    clickthroughLink: '',
    duration: '',
    schoolIds: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [sponsorshipAsset, setSponsorshipAsset] = useState<File | null>(null);
  const [sponsorshipAssetUrl, setSponsorshipAssetUrl] = useState<string>('');

  const methods = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues,
    resolver: yupResolver(SponsorshipSetupSchema)
  });

  const allFields = useWatch({ control: methods.control });
  const { sponsorId: sponsorIdFromPath } = useParams<{ sponsorId: string }>();

  useEffect(() => {
    setSetupProgressFields(allFields);
  }, [allFields]);

  useEffect(() => {
    const fetchSponsorshipData = async () => {
      if (sponsorIdFromPath) {
        try {
          const sponsorData = await SponsorshipService.getSponsorshipPage(sponsorIdFromPath);
          if (sponsorData) {
            const { sponsor, schoolIds } = sponsorData;
            const sponsorImageUrl = sponsorData?.sponsor?.asset;
            if (sponsorImageUrl) {
              setSponsorshipAssetUrl(sponsorImageUrl);
              const imageFile = await parseFileFromUrl(sponsorImageUrl);
              if (imageFile) {
                setSponsorshipAsset(imageFile);
              }
            }

            const initialValues = {
              sponsorshipName: sponsor?.name,
              sponsorshipType: sponsor?.type,
              emailAddress: sponsor?.contact?.email,
              phoneNumber: sponsor?.contact?.phone,
              tagline: sponsor?.tagline,
              clickthroughLink: sponsor?.clickThroughLink,
              schoolIds,
              sponsorshipAsset
            };
            methods.reset(initialValues); // Update form default values
            setDefaultValues(initialValues);
          }
        } catch (error) {
          if (error instanceof Error) {
            throw new Error(error.message || 'An error occurred while fetching the sponsorship.');
          } else {
            throw new Error('An error occurred while fetching the sponsorship.');
          }
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false); // In case sponsorIdFromPath is not provided, stop loading
      }
    };

    fetchSponsorshipData();
  }, [sponsorIdFromPath]);

  return isLoading ? (
    <Spinner withOverlay />
  ) : (
    <FormProvider {...methods}>
      <div className='gf--sponsorship-creation-form'>
        <Section id='sponsorship_details' title='Sponsorship Details' onFocusSection={onFocusSection}>
          <SponsorshipDetails />
        </Section>

        <Section id='sponsorship_siteInfo' title='Sponsorship site info' onFocusSection={onFocusSection}>
          <SponsorshipSiteInfo previousSponsorshipAsset={sponsorshipAsset} />
        </Section>

        <Section id='sponsorship_assignment' title='Sponsorship assignment' onFocusSection={onFocusSection}>
          <SponsorshipAssignment />
        </Section>

        <Section id='sponsorship_preview' title='Preview' onFocusSection={onFocusSection}>
          <SponsorshipPreview previousSponsorshipUrl={sponsorshipAssetUrl} />
        </Section>
      </div>
    </FormProvider>
  );
};

export { SponsorshipCreationForm };
