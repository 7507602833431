import { Icons } from '@gofan/components';

import './DonationSaleEmpty.scss';

export const DonationSaleEmpty = () => (
  <div className='gf-donation-sale-empty'>
    <div className='icon'>
      <Icons.Need size={50} />
    </div>
    <div className='description'>No donations yet, be sure to check back soon!</div>
  </div>
);
