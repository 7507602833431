export default {
  PARTNER_IMPORT_PROGRESS_DESC: {
    IMPORTING_EVENTS: 'Importing events to GoFan HQ...',
    CHECKING_DUPLICATE_EVENTS: 'Checking for duplicate events...'
  },
  PARTNER_INTEGRATION: {
    PUBLISH_ALL_SEASONS: 'Publish all seasons',
    UPDATE_ALL_SEASONS: 'Update all',
  },
  SUCCESSFULLY_EVENT_IGNORED: 'The event is ignored.',
  SUCCESSFULLY_EVENT_RESTORED: 'The event is restored.',
  SUCCESSFULLY_SEASON_IGNORED: 'The season is ignored.',
  SUCCESSFULLY_SEASON_RESTORED: 'The season is restored.',
  SUCCESSFULLY_SEASON_UPDATED: 'the update was successfully published.',
  SUCCESSFULLY_SEASON_PUBLISHED: 'the season was successfully published.',
  SUCCESSFULLY_SEASON_PUBLISHED_ALL: 'all seasons were successfully published.',
};
