import strings from '@dashboard/constants/strings';

export interface SeasonSetupProgressType {
  [schoolType: string]: {
    [step: string]: {
      index: number;
      label: string;
      description: string;
    };
  };
}

export const SEASONS_SETUP_PROGRESS: SeasonSetupProgressType = {
  SINGLE_SCHOOL: {
    SELECT_SPORTS_STEP: {
      index: 0,
      label: strings.SEASON_SETUP_MODAL.SELECT_SPORTS,
      description: strings.SEASON_SETUP_MODAL.SELECT_SPORTS
    },
    CHOOSE_CREATION_METHOD_STEP: {
      index: 1,
      label: strings.SEASON_SETUP_MODAL.CHOOSE_CREATION_METHOD,
      description: strings.SEASON_SETUP_MODAL.CHOOSE_CREATION_METHOD
    }
  },
  MULTIPLE_SCHOOL: {
    SELECT_SPORTS_STEP: {
      index: 0,
      label: strings.SEASON_SETUP_MODAL.SELECT_SPORTS,
      description: strings.SEASON_SETUP_MODAL.SELECT_SPORTS
    },
    ASSIGN_PAYMENT_SCHOOL_STEP: {
      index: 1,
      label: strings.SEASON_SETUP_MODAL.ASSIGN_PAYMENT_SCHOOL,
      description: strings.SEASON_SETUP_MODAL.ASSIGN_PAYMENT_SCHOOL
    },
    CHOOSE_CREATION_METHOD_STEP: {
      index: 2,
      label: strings.SEASON_SETUP_MODAL.CHOOSE_CREATION_METHOD,
      description: strings.SEASON_SETUP_MODAL.CHOOSE_CREATION_METHOD
    }
  },
  CREATION_SEASON: {
    SELECT_SPORTS_STEP: {
      index: 0,
      label: strings.SEASON_SETUP_MODAL.SELECT_SPORTS,
      description: strings.SEASON_SETUP_MODAL.SELECT_SPORTS
    }
  }
};

export const SEASON_CREATION_METHODS = {
  MULTI_SPORT: strings.SEASON_SETUP_MODAL.CREATION_METHODS.MULTI_SPORT,
  SINGLE_SPORT: strings.SEASON_SETUP_MODAL.CREATION_METHODS.SINGLE_SPORT
};

export const LIMIT_ACCOUNTS_DISPLAY = 10;
