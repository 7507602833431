import { TIME_PICKER_SELECT } from '@gofan/components';

import type { DateObject } from 'react-multi-date-picker';
import type { ProgressSection } from '@events/components/editor/EditorProgress';
import type { SettingSection } from '@events/components/editor/EditorSettings';
import type { PracticeScheduleFormValues } from '@practice-schedule/types';
import { TeamGenderEnum, type AccountVenue } from '@gofan/api';

export const SECTIONS = {
  ACTIVITY_LEVEL_SECTION: 'ACTIVITY_LEVEL_SECTION',
  VENUE_SECTION: 'VENUE_SECTION',
  LOCATION_SECTION: 'LOCATION_SECTION',
  SCHEDULES_SECTION: 'SCHEDULES_SECTION'
};

export const PROGRESS_LABELS = {
  ACTIVITY_LEVEL_SECTION: 'Activity level',
  VENUE_SECTION: 'Add venue',
  LOCATION_SECTION: 'Location details',
  SCHEDULES_SECTION: 'Add schedule'
};

export const CREATE_PRACTICE_SETTINGS_HEADER = 'Your practice settings';

export const PRACTICE_PROGRESSES: ProgressSection[] = [
  {
    title: 'Practice Settings',
    settingIndex: 0,
    settingLabel: 'Required',
    progresses: [
      {
        id: SECTIONS.ACTIVITY_LEVEL_SECTION,
        label: PROGRESS_LABELS.ACTIVITY_LEVEL_SECTION,
        current: true
      },
      {
        id: SECTIONS.VENUE_SECTION,
        label: PROGRESS_LABELS.VENUE_SECTION
      },
      {
        id: SECTIONS.SCHEDULES_SECTION,
        label: PROGRESS_LABELS.SCHEDULES_SECTION
      }
    ]
  }
];

export const PRACTICE_SETTINGS: SettingSection[] = [
  {
    title: 'Required',
    editorSettings: []
  }
];

export const practiceScheduleDefaultFormValues: PracticeScheduleFormValues = {
  activity: null,
  genderSpecific: true,
  genders: [],
  genderLevels: {
    [TeamGenderEnum.BOYS]: [],
    [TeamGenderEnum.GIRLS]: [],
    [TeamGenderEnum.COED]: []
  },
  venue: {} as AccountVenue,
  locationDetail: '',
  locationNote: '',
  schedules: []
};

export const scheduleSetupDefaultFormValues = {
  scheduleDates: [] as DateObject[],
  startTime: ` ${TIME_PICKER_SELECT.PM}`,
  endTime: ` ${TIME_PICKER_SELECT.PM}`,
  venue: {} as AccountVenue
};

export const SCHEDULES_QUICK_FILTER_TYPE = {
  LAST_7_DAYS: 'last7Days',
  UPCOMING_SCHEDULES: 'upcomingSchedules',
  TODAY_SCHEDULES: 'todaySchedule',
  CURRENT_SCHEDULES: 'currentSchedules'
};

export const FILTER_SCHEDULE_DEFAULT_FORM_VALUES = {
  timeRange: SCHEDULES_QUICK_FILTER_TYPE.CURRENT_SCHEDULES,
  teamLevels: [],
  venues: []
};

export const PRACTICE_SCHEDULES_FIELDS_TO_SECTIONS: { [key: string]: string } = {
  activity: SECTIONS.ACTIVITY_LEVEL_SECTION,
  genderSpecific: SECTIONS.ACTIVITY_LEVEL_SECTION,
  genders: SECTIONS.ACTIVITY_LEVEL_SECTION,
  genderLevels: SECTIONS.ACTIVITY_LEVEL_SECTION,
  venue: SECTIONS.VENUE_SECTION,
  locationDetail: SECTIONS.LOCATION_SECTION,
  locationNote: SECTIONS.LOCATION_SECTION,
  schedules: SECTIONS.SCHEDULES_SECTION
};

export const MAP_PROGRESS_LABELS_TO_SETTING_LABELS = {
  [PROGRESS_LABELS.ACTIVITY_LEVEL_SECTION]: 'Athletic',
  [PROGRESS_LABELS.VENUE_SECTION]: 'Venue',
  [PROGRESS_LABELS.LOCATION_SECTION]: 'Location Details',
  [PROGRESS_LABELS.SCHEDULES_SECTION]: 'Schedule'
};

export const CREATE_SCHEDULES_SETUP_LABELS = {
  FORM_TITLE: 'Schedule Setup',
  TABLE_TITLE: 'Practice Schedule',
  TABLE_SUBTITLE: 'Your are viewing your {0} practice schedule',
  SUBMIT_BUTTON_TITLE: 'Create Schedule'
};

export const EDIT_SCHEDULES_SETUP_LABELS = {
  FORM_TITLE: 'Edit Schedule Setup',
  TABLE_TITLE: 'Edit Schedule',
  TABLE_SUBTITLE: 'Your are editing your {0} practice schedule',
  SUBMIT_BUTTON_TITLE: 'Update Schedule',
  SCHEDULES_EDIT_ERROR: 'Can not update schedule(s) !',
  SCHEDULES_EDIT_DUPLICATED: 'The selected schedule(s) already exists !'
};

export const SCHEDULES_MESSAGES = {
  EDIT_FAIL: 'Can not update schedule(s) !',
  EDIT_DUPLICATED: 'The selected schedule(s) already exists !',
  CREATE_DUPLICATED: 'The schedule(s) already exists !',
  CREATE_SUCCESSFUL: 'Practices Created',
  EDIT_SUCCESSFUL: 'Practices Updated'
};

export const PRACTICE_MESSAGES = {
  PUBLISHED: 'Practice Published',
  PUBLISH_DUPLICATED: 'A Practice already exists !'
};

export const PRACTICE_DATE_FORMAT = 'YYYY/MM/DD';
