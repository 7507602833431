import React, { useState, useReducer, useEffect, useRef } from 'react';
import { Form, Dimmer, Loader } from 'semantic-ui-react';
import SubmitRow from './SubmitRow';
import FastFormDevErrors from './FastFormDevErrors';

import useIsMount from '../../../utils/hooks/useIsMount';
import { isEmpty, isDifference } from '../../../utils/objectUtils';

const formReducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload };
    case 'replace':
      return { ...action.payload };
    case 'remove': {
      const { keys } = action.payload;
      const newState = Object.keys(state).reduce(
        (res, cur) => (keys.includes(cur) ? res : { ...res, [cur]: { ...state[cur] } }),
        {}
      );
      return { ...newState };
    }
    default:
      return state;
  }
};

const checkDirtyValue = (value, lastSavedValue, fieldProps = {}) => {
  if (value instanceof Array && typeof fieldProps.differenceWith === 'function') {
    return isDifference(value, lastSavedValue, item => fieldProps.differenceWith(item));
  }

  if (typeof fieldProps.difference === 'function') {
    return fieldProps.difference(lastSavedValue, value);
  }

  return value !== lastSavedValue;
};

const determineDisabled = (meta = {}, dirtyOverrider) => {
  // if i'm invalid its just false. period. don't let them submit invalid data.
  if (!meta.allValid || !meta.requiredMet) {
    return true;
  }

  if (dirtyOverrider === true) {
    return false;
  }
  // if i'm dirty, AND there is no dirtyOverrider (or it returns false)
  // don't submit

  if (meta.anyDirty) {
    return false;
  }

  return true;
};

// the linter doesn't like this but this is genuinely the fastest way i can find to get the state publically available.
// and i don't understand react well enough yet to say why this is a bad idea. So. Time to learn.
// eslint-disable-next-line import/no-mutable-exports
export let fastFormState;

export const FastFormContext = React.createContext();

const formExpander = (values, createMode) => {
  const finalForm = {};

  Object.keys(values).forEach(key => {
    const value = values[key] === null || typeof values[key] === 'undefined' ? '' : values[key];

    finalForm[key] = {
      value,
      lastSavedValue: createMode ? '' : value, // used for memento pattern for form, ask nick if this should be values[key] or something saved
      dirty: createMode, // if the form is changed or not from lastSavedValue
      valid: true,
      touched: false,
      focused: false,
      inForm: false,
      required: false,
      errors: []
    };
  });
  fastFormState = finalForm;
  return finalForm;
};

export default ({
  children,
  submitText,
  cancelText,
  submitAction,
  cancelAction,
  formInitializer,
  createMode, // should we be intiially dirty, or clean?
  ignoreMissing,
  viewOnly,
  dirtyOverrider, // if this function returns true, then you can submit as long as things are valid
  hideCancleButton,
  preventUpdateLastSaveValues,
  fieldProps = {},
  customDetermineDisabled,
  customStylesSubmitRow = {},
  feedbackData = () => {}
}) => {
  const [formState, formDispatch] = useReducer(formReducer, {});
  const disableSubmitRef = useRef(true);
  const isMount = useIsMount();
  const [copyKeys, setCopyKeys] = useState([]);

  const [meta, setMeta] = useState({
    allValid: false,
    anyDirty: false, // at least one field must be dirty for this to trigger.
    anyTouched: false,
    allInForm: true,
    missingFields: false,
    requiredMet: false,
    loading: true,
    initiated: false,
    disableSubmit: disableSubmitRef.current
  });

  const buttonProps = {
    cancelAction,
    cancelText,
    submitAction,
    submitText,
    hideCancleButton,
    customStylesSubmitRow,
    preventUpdateLastSaveValues
  };

  const contextValue = {
    form: formState,
    viewOnly,
    meta,
    buttonProps,
    updateCopyKeys: keys => setCopyKeys(keys),
    updateMeta: newMeta => setMeta(newMeta),
    updateField: (key, value) => {
      const keys = key.split('.');
      let fieldData = {};
      if (keys.length === 3 && `${keys[1]}` === `${copyKeys[0]}`) {
        fieldData = formState[`${keys[0]}.${copyKeys[1]}.${keys[2]}`];
        if ((fieldData && fieldData.errors ? fieldData.errors : []).some(item => item.includes('is required.'))) {
          fieldData = {};
        }
      }
      formDispatch({
        type: 'update',
        payload: { [key]: { ...value, ...{ ...fieldData } } }
      });
    },
    removeFields: (keys = []) => !isEmpty(keys) && formDispatch({ type: 'remove', payload: { keys } }),
    focusField: (key, value) =>
      formDispatch({
        type: 'update',
        payload: { [key]: { ...value, focused: true } }
      }),
    blurField: (key, value) => {
      formDispatch({
        type: 'update',
        payload: {
          [key]: {
            ...value,
            focused: false,
            touched: true,
            dirty: value.value !== formState[key].lastSavedValue
          }
        }
      });
    }
  };

  useEffect(() => {
    let mounted = true;
    if (formInitializer) {
      try {
        formInitializer().then(res => {
          if (mounted) {
            feedbackData(res);
            formDispatch({
              type: 'replace',
              payload: formExpander(res, createMode)
            });
            setMeta({ ...meta, loading: false, initiated: true });
          }
        });
      } catch (error) {
        if (mounted) {
          if (typeof formInitializer === 'function') {
            const iForm = formInitializer();
            formDispatch({
              type: 'replace',
              payload: formExpander(iForm, createMode)
            });
          } else if (typeof formInitializer === 'object') {
            formDispatch({
              type: 'replace',
              payload: formExpander(formInitializer)
            });
          } else {
            formDispatch({ type: 'replace', payload: {} });
          }
          setMeta({ ...meta, loading: false, initiated: true });
        }
      }
    } else {
      setMeta({ ...meta, loading: false, initiated: false });
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(
    () => {
      if (isMount && meta.initiated) {
        Object.keys(formState).forEach(key => {
          const splitKey = key.split('.');
          const fieldProp = splitKey.length === 3 ? fieldProps[`${splitKey[0]}.${splitKey[2]}`] : fieldProps[key];

          formState[key] = {
            ...formState[key],
            dirty: checkDirtyValue(formState[key].value, formState[key].lastSavedValue, fieldProp)
          };
        });
        setMeta({
          ...meta,
          requiredMet: Object.keys(formState).every(key => !formState[key].required || !isEmpty(formState[key].value)),
          allInForm: Object.keys(formState).every(key => formState[key].inForm),
          missingFields: Object.keys(formState).filter(key => !formState[key].inForm),
          allValid: Object.keys(formState).every(key => formState[key].valid),
          anyDirty: Object.keys(formState)
            .map(key => formState[key].dirty)
            .includes(true)
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formState, meta.initiated]
  );

  useEffect(
    () => {
      if (isMount && meta.initiated) {
        const isDisabled = determineDisabled(meta, dirtyOverrider);
        let disableSubmit = isDisabled;

        if (customDetermineDisabled && typeof customDetermineDisabled === 'function') {
          disableSubmit = customDetermineDisabled({ formState, isDisabled });
        }

        if (disableSubmit !== disableSubmitRef.current) {
          disableSubmitRef.current = disableSubmit;
          setMeta({ ...meta, disableSubmit });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [meta, formState, dirtyOverrider]
  );

  return (
    <FastFormContext.Provider value={contextValue}>
      <Dimmer active={meta.loading} inverted>
        <Loader />
      </Dimmer>

      {!meta.loading && <Form>{children}</Form>}
      {!viewOnly && !meta.loading && (
        <React.Fragment>
          <SubmitRow
            {...buttonProps}
            fieldProps={fieldProps}
            hideCancleButton={hideCancleButton}
            submitDisabled={meta.disableSubmit}
          />
          <FastFormDevErrors meta={meta} ignoreMissing={ignoreMissing} />
        </React.Fragment>
      )}
    </FastFormContext.Provider>
  );
};
