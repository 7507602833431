// flow

import type { FanSupportActionTypes } from './actionTypes';
import {
  SET_CURRENT_TAB,
  RESET_STATE,
  SEARCH_TICKETS,
  SEARCH_TICKETS_SUCCESS,
  REFUND_TICKETS,
  REFUND_TICKETS_COMPLETED,
  SEARCH_EVENT_REFUND,
  SEARCH_EVENT_REFUND_COMPLETED,
  SET_EVENT_REFUND,
  REFUND_BY_EVENT,
  REFUND_BY_EVENT_COMPLETED,
  REFUND_BY_EVENT_IN_PROCCESS
} from './actionTypes';
import type { PurchasedTicket, EventRefund } from '../../api/types';
import type {
  SearchTicketResult,
  SearchTicketParams,
  SearchEventRefundResult,
  SearchEventRefundParams,
  AdditionalRefundInfo
} from './types';
import { FAN_SUPPORT_TABS } from './constants';

export const setCurrentTab = (tab: string = FAN_SUPPORT_TABS[0].name): FanSupportActionTypes => ({
  type: SET_CURRENT_TAB,
  payload: { tab }
});

export const resetState = (): FanSupportActionTypes => ({
  type: RESET_STATE
});

export const searchTickets = (searchParams: SearchTicketParams): FanSupportActionTypes => ({
  type: SEARCH_TICKETS,
  payload: {
    searchParams
  }
});

export const searchTicketSuccess = (searchResult: SearchTicketResult): FanSupportActionTypes => ({
  type: SEARCH_TICKETS_SUCCESS,
  payload: {
    searchResult
  }
});

export const refundTickets = (
  purchasedTickets: PurchasedTicket[],
  searchParams: SearchTicketParams,
  additionalRefundInfo: AdditionalRefundInfo
): FanSupportActionTypes => ({
  type: REFUND_TICKETS,
  payload: {
    purchasedTickets,
    searchParams,
    additionalRefundInfo
  }
});

export const refundTicketsCompleted = (): FanSupportActionTypes => ({
  type: REFUND_TICKETS_COMPLETED
});

export const searchEventRefund = (searchParams: SearchEventRefundParams): FanSupportActionTypes => ({
  type: SEARCH_EVENT_REFUND,
  payload: {
    searchParams
  }
});

export const searchEventRefundCompleted = (searchResult: SearchEventRefundResult): FanSupportActionTypes => ({
  type: SEARCH_EVENT_REFUND_COMPLETED,
  payload: {
    searchResult
  }
});

export const setEventRefund = (events: Array<EventRefund>): FanSupportActionTypes => ({
  type: SET_EVENT_REFUND,
  payload: {
    events
  }
});

export const refundByEvent = (
  event: EventRefund,
  additionalRefundInfo: AdditionalRefundInfo
): FanSupportActionTypes => ({
  type: REFUND_BY_EVENT,
  payload: {
    event,
    additionalRefundInfo
  }
});

export const refundByEventCompleted = (): FanSupportActionTypes => ({
  type: REFUND_BY_EVENT_COMPLETED
});

export const refundByEventInProccess = (eventId: string): FanSupportActionTypes => ({
  type: REFUND_BY_EVENT_IN_PROCCESS,
  payload: {
    eventId
  }
});
