import { isEmpty, upperFirst, uniq, uniqBy, omit } from 'lodash';

import { config } from '@gofan/constants';
import { TIME_ZONE_ENUM, TIME_ZONE_MAPPING } from '@utils/dateUtils';
import { UNEXPECTED_ERROR } from '@api/api/constants';
import { uploadNewSeasonTemplate } from '@app/api/services/SeasonService';
import ErrorDTO from '@api/dto/ErrorDTO';
import RowEvent from '@api/model/RowEvent';
import RowEventDTO from '@api/dto/RowEventDTO';
import UploadedEvent from '@api/model/UploadedEvent';

import type { AccountDTO } from '@gofan/api/accounts';
import { AccountService } from '@gofan/api/accounts';

import { LevelService } from '@gofan/api/levels';
import type { LevelDTO } from '@gofan/api/levels';

import type { ActivityDTO } from '@gofan/api/activities';
import { ActivityService } from '@gofan/api/activities';

import { AccountProductService } from '@gofan/api/account-products';
import type { AccountProductSearchDTO } from '@gofan/api/account-products';

import type { SeasonDTO } from '@seasons/models/season.model';
import seasonService from '@seasons/services/season.service';
import EventScheduleService from '@modules/event-integrations_V2/services/event-schedule.service';

import { RateService } from '@gofan/api/rates';

import { WorkbookType } from '@gofan/constants/flatfile';

import {
  GENDER_TYPE,
  CREATION_MODE,
  CREATION_PROCESS,
  UPLOAD_SEASON_FLOW,
  EXPAND_SEASON_FIELDS
} from '@season-management/constants/constant';
import { ARBITER_PARTNER_NAME, WAITING_STATUS } from '@modules/event-integrations_V2/constants/constants';
import SeasonCreationUtil from '@season-management/utils/season-creation.utils';
import SeasonCreationRepository from '@season-management/repositories/season-creation.repository';
import type { LevelsByGender, TeamOption } from '@season-management/middleware/models/model';
import type { ActivedSport, PreparedData } from '@season-management/middleware/types';
import type { RawSeason } from '@season-management/middleware/models/raw-season.model';
import type { SeasonWithEvent } from '@season-management/middleware/models/season-with-event.model';
import type { CreatingSeason } from '@season-management/middleware/models/creating-season.model';
import CreatingSeasonModel from '@season-management/middleware/models/creating-season.model';
import type { EventScheduleDTO } from '@modules/event-integrations_V2/models/event-schedule.model';
import type { RateDTO } from '@gofan/api/rates';

class SeasonCreationService {
  refreshSeasonBySport = async ({ creatingSeason }: { creatingSeason: CreatingSeason }) => {
    try {
      // const seasons: SeasonDTO[] = yield call(
      //   SeasonService.searchSeasonByIds,
      //   mockData.map(item => item.id),
      //   EXPAND_SEASON_FIELDS
      // );
      const seasons: SeasonDTO[] = await Promise.all(
        creatingSeason?.rawSeasons?.map((item: RawSeason) =>
          seasonService.getSeasonById(`${item.seasonId}`, EXPAND_SEASON_FIELDS)
        )
      );

      const updatedCreatingSeason = new CreatingSeasonModel({ ...creatingSeason, rawSeasons: [] })
        .setBySeasons(seasons)
        .toJSON();

      return updatedCreatingSeason;
    } catch (error) {
      return { ...creatingSeason };
    }
  };

  refreshCreatingSeasonList = async ({
    creatingSeasonList
  }: {
    creatingSeasonList: CreatingSeason[];
  }): Promise<CreatingSeason[]> => {
    const updatedCreatingSeasonList: CreatingSeason[] = await Promise.all(
      creatingSeasonList?.map((creatingSeason: CreatingSeason) => {
        if (creatingSeason.created) {
          return this.refreshSeasonBySport({ creatingSeason });
        }
        return { ...creatingSeason };
      })
    );
    return updatedCreatingSeasonList;
  };

  prepareDataForSeasonCreation = async ({
    accountId,
    storedSports,
    storedActivedSport,
    storedSeasonList
  }: {
    accountId?: string;
    storedSports: ActivityDTO[];
    storedActivedSport: ActivedSport;
    storedSeasonList: CreatingSeason[];
  }): Promise<PreparedData> => {
    const [account, activities, levels, updatedSeasonList, rates]: [
      AccountDTO,
      ActivityDTO[],
      LevelDTO[],
      CreatingSeason[],
      RateDTO[]
    ] = await Promise.all([
      AccountService.getAccountById(accountId as string, true),
      ActivityService.getAllActivities(),
      LevelService.getAllLevels(),
      this.refreshCreatingSeasonList({ creatingSeasonList: storedSeasonList }),
      Promise.all([
        RateService.getRateById(config.RATE_ID1),
        RateService.getRateById(config.RATE_ID5),
        RateService.getRateById(config.RATE_ID8)
      ])
    ]);
    const filteredLevels = LevelService.filterAvailableLevels(levels);

    const { athleticActivities = [] } = ActivityService.groupActivities(activities);

    const availableSports = isEmpty(storedSports)
      ? []
      : athleticActivities.filter(activity => storedSports.find(sport => `${activity.id}` === `${sport.id}`));

    let selectedSports: ActivityDTO[] = [];
    if (isEmpty(availableSports)) {
      selectedSports = athleticActivities ? [athleticActivities[0]] : [];
    } else {
      selectedSports = availableSports;
    }

    const foundActivedSport =
      !isEmpty(storedActivedSport) && selectedSports.find(sport => `${sport.id}` === `${storedActivedSport?.id}`);
    const activedSport = SeasonCreationUtil.initActivedSport({
      accountId: account?.id,
      sport: isEmpty(foundActivedSport) ? selectedSports[0] : foundActivedSport
    });

    const creatingSeasonList = SeasonCreationUtil.initCreatingSeasonList({
      activedSport,
      storedCreatingSeasonList: [...updatedSeasonList]
    });

    return {
      account,
      levels: filteredLevels,
      activities: athleticActivities,
      creatingSeasonList,
      selectedSports,
      activedSport,
      rates
    };
  };

  getAccountProducts = async (searchParams: AccountProductSearchDTO) => {
    const results = await AccountProductService.searchProducts(searchParams);
    return (results?.content ?? []).map(item => omit(item, 'fee'));
  };

  createMultiSeasons = (seasons: SeasonWithEvent[]) => SeasonCreationRepository.createMultiSeasons(seasons);

  updateMultiSeasons = (seasons: SeasonWithEvent[]) => SeasonCreationRepository.updateMultiSeasons(seasons);

  validateUploadedFile = async (params: {
    file: File;
    timeZone: string;
    creatingSeason: CreatingSeason | null;
    creationMethod?: string;
    selectedSports?: ActivityDTO[];
  }) => {
    const { creatingSeason, creationMethod, selectedSports } = params ?? {};
    const { creationMode, sport, genders, levelsByGender } = creatingSeason ?? {};
    const timeZone = TIME_ZONE_MAPPING[`${params?.timeZone}`.toLowerCase()] || TIME_ZONE_ENUM.AMERICA_NEW_YORK;
    const optionSports = sport?.label ? [sport?.label] : [];
    const teamOptions: TeamOption[] = [];
    const formData = new FormData();
    const uploadedTeams: any[] = [];
    let uploadFlow = null;

    formData.append('file', params?.file);

    if (creationMethod === CREATION_PROCESS.MULTI_SPORT_SETUP) {
      uploadFlow = UPLOAD_SEASON_FLOW.MULTI_SPORTS;

      teamOptions.push({
        sports: (selectedSports ?? []).map(item => item.label) || [],
        genders: [],
        levels: []
      });
    } else if (creationMode === CREATION_MODE.SPERATE_EACH_TEAM) {
      uploadFlow = UPLOAD_SEASON_FLOW.SPERATE_EACH_TEAM;

      (genders ?? []).forEach((gender: string) => {
        (levelsByGender?.[gender] ?? []).forEach((level: LevelDTO) => {
          teamOptions.push({
            sports: optionSports,
            genders: gender === GENDER_TYPE.COED ? [] : [upperFirst(`${gender}`.toLowerCase())],
            levels: [level.name]
          });
        });
      });
    } else if (creationMode === CREATION_MODE.ONE_PER_SPORT) {
      const optionGenders: string[] = [];
      let optionLevels: string[] = [];

      uploadFlow = UPLOAD_SEASON_FLOW.ONE_PER_SPORT;

      (genders ?? []).forEach((gender: string) => {
        if (gender !== GENDER_TYPE.COED) {
          optionGenders.push(upperFirst(`${gender}`.toLowerCase()));
        }
        optionLevels = uniq([...optionLevels, ...(levelsByGender?.[gender] ?? []).map(level => level.name)]);
      });

      teamOptions.push({
        sports: sport?.label ? [sport?.label] : [],
        genders: optionGenders,
        levels: optionLevels
      });
    }

    const response = await uploadNewSeasonTemplate(formData, timeZone, uploadFlow, teamOptions);
    const uploadedEvents: RowEvent[] = [];
    const uploadedEventErrors: any[] = [];

    if (response instanceof ErrorDTO) {
      const errorMeassge = response.getErrorMessage() ?? UNEXPECTED_ERROR;
      uploadedEventErrors.push({
        errorMessages: [`Your file failed to upload: ${errorMeassge}`.split('#REF')[0]]
      });
    } else if (response instanceof Array) {
      const events = response.map(item => new UploadedEvent(item.toJSON()).toJSON());
      const NotFoundOpponentAccount = 'Opponent school is not found';
      let tempUploadedTeams = {};

      events.some(item => {
        if (!isEmpty(uploadedEventErrors)) return true;
        if (
          isEmpty(item.errors) ||
          (item.errors?.length === 1 && (item.errors?.[0]?.description ?? '').includes(NotFoundOpponentAccount))
        ) {
          uploadedEvents.push(item.event as RowEvent);

          if (isEmpty(uploadedEventErrors) && creationMethod === CREATION_PROCESS.MULTI_SPORT_SETUP) {
            tempUploadedTeams = SeasonCreationUtil.groupLevelGenderByUploadMultiSport({
              uploadedTeams: tempUploadedTeams,
              rowEvent: item.event as RowEvent
            });
          }
        } else if (isEmpty(uploadedEventErrors)) {
          const uploadErrors = item.errors?.filter(
            (error: any) => !(error?.description ?? '').includes(NotFoundOpponentAccount)
          );
          if (!isEmpty(uploadErrors)) {
            uploadedEventErrors.push({
              ...item,
              errorMessages: [
                `Your file failed to upload: Row ${item.rowNumber + 1} ${uploadErrors?.[0]?.description ?? ''}`
              ]
            });
          }
        }
        return false;
      });

      if (isEmpty(uploadedEventErrors) && creationMethod === CREATION_PROCESS.MULTI_SPORT_SETUP) {
        Object.values(tempUploadedTeams).forEach((team: any) => {
          const teamGenders: string[] = team.genders ?? [];
          const teamLevelsByGender: any = team.levelsByGender ?? {};
          let finalGenders: string[] = [];
          let finalLevelsByGender: any = {};
          let hasConflictGender = false;

          if (teamGenders.length > 1 && teamGenders.includes(GENDER_TYPE.COED)) {
            hasConflictGender = true;
            finalGenders = teamGenders.filter(gender => `${gender}` !== GENDER_TYPE.COED);
          } else {
            finalGenders = [...teamGenders];
          }

          teamGenders.forEach(gender => {
            const teamLevels = teamLevelsByGender?.[gender] ?? [];
            const levelGenders = hasConflictGender && `${gender}` === GENDER_TYPE.COED ? [...finalGenders] : [gender];

            levelGenders.forEach(levelGender => {
              finalLevelsByGender = {
                ...finalLevelsByGender,
                [levelGender]: uniqBy([...(finalLevelsByGender?.[levelGender] ?? []), ...teamLevels], 'id')
              };
            });
          });

          uploadedTeams.push({
            sportId: team.sportId,
            genders: finalGenders,
            levelsByGender: finalLevelsByGender
          });
        });
      }
    }

    return {
      genders,
      creationMode,
      levelsByGender,
      uploadedTeams,
      uploadedEvents,
      uploadedEventErrors
    };
  };

  getDataImportFromArbiterMode = async (params: {
    accountId: string;
    genders: string[];
    levelsByGender: LevelsByGender;
    sportName: string;
  }) => {
    const { accountId, genders, levelsByGender, sportName } = params;
    const arbiterSeasons: EventScheduleDTO[] = await EventScheduleService.getEventScheduleSeason({
      partnerName: ARBITER_PARTNER_NAME,
      schoolIds: [accountId],
      sportNames: [sportName],
      status: [WAITING_STATUS],
      levelGenders: SeasonCreationUtil.getLevelGendersToCheckArbiter({
        genders,
        levelsByGender
      })
    });
    const numOfArbiterSeasons = SeasonCreationUtil.countNumOfArbiterSeasonByGender({
      arbiterSeasons,
      genders,
      levelsByGender
    });

    return {
      arbiterSeasons,
      numOfArbiterSeasons
    };
  };

  mappingSeasonUploadedData = ({
    payload,
    workbookType,
    creatingSeason
  }: {
    workbookType: WorkbookType;
    creatingSeason?: CreatingSeason;
    payload?: { records: any[]; batchId: string };
  }) => {
    const { records, batchId = '' } = payload ?? {};
    const { creationMode, genders, levelsByGender } = creatingSeason ?? {};
    const uploadedEvents: RowEvent[] = [];
    const uploadedEventErrors: any[] = [];
    const uploadedTeams: any[] = [];

    let tempUploadedTeams = {};

    const events: RowEvent[] =
      (records ?? [])?.map(record => {
        const event = new RowEventDTO({
          date: record?.date,
          time: record?.time,
          fullDate: record?.fullDate,
          startDateTime: record?.startDateTime,
          sport: record?.sportId,
          levels: record?.levelId,
          genders: record?.gender,
          opponentCity: record?.opponentCity,
          opponentState: record?.opponentState,
          opponentSchool: record?.opponentSchool,
          opponentAccount: record?.opponentAccount && JSON.parse(`${record?.opponentAccount ?? '{}'}`),
          venueName: record?.venueName,
          venueCity: record?.venueCity,
          venueState: record?.venueState,
          venueZip: record?.venueZipcode,
          venueAddress: record?.venueAddress,
          venueLocation: record?.venueLocation
        });
        return new RowEvent(event.toJSON());
      }) ?? [];

    events?.forEach(event => {
      uploadedEvents.push(event as RowEvent);

      if (workbookType === WorkbookType.SEASON_MULTIPLE_SPORTS) {
        tempUploadedTeams = SeasonCreationUtil.groupLevelGenderByUploadMultiSport({
          uploadedTeams: tempUploadedTeams,
          rowEvent: event as RowEvent
        });
      }
    });

    if (workbookType === WorkbookType.SEASON_MULTIPLE_SPORTS) {
      Object.values(tempUploadedTeams).forEach((team: any) => {
        const teamGenders: string[] = team.genders ?? [];
        const teamLevelsByGender: any = team.levelsByGender ?? {};
        let finalGenders: string[] = [];
        let finalLevelsByGender: any = {};
        let hasConflictGender = false;

        if (teamGenders.length > 1 && teamGenders.includes(GENDER_TYPE.COED)) {
          hasConflictGender = true;
          finalGenders = teamGenders.filter(gender => `${gender}` !== GENDER_TYPE.COED);
        } else {
          finalGenders = [...teamGenders];
        }

        teamGenders.forEach(gender => {
          const teamLevels = teamLevelsByGender?.[gender] ?? [];
          const levelGenders = hasConflictGender && `${gender}` === GENDER_TYPE.COED ? [...finalGenders] : [gender];

          levelGenders.forEach(levelGender => {
            finalLevelsByGender = {
              ...finalLevelsByGender,
              [levelGender]: uniqBy([...(finalLevelsByGender?.[levelGender] ?? []), ...teamLevels], 'id')
            };
          });
        });

        uploadedTeams.push({
          sportId: team.sportId,
          genders: finalGenders,
          levelsByGender: finalLevelsByGender
        });
      });
    }

    return {
      batchId,
      genders,
      creationMode,
      levelsByGender,
      uploadedTeams,
      uploadedEvents,
      uploadedEventErrors
    };
  };
}

export default new SeasonCreationService();
