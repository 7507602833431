import React from 'react';
import styled from 'styled-components';
import styles from './styles';

const Input = styled.input`
  ${styles.input};
`;

const Round = styled.div`
  ${styles.round};
`;

const Label = styled.label`
  ${styles.label};
`;

const RadioWrapper = styled.div`
  ${styles.radioWrapper};
`;

const Wrapper = styled.div`
  ${styles.wrapper};
`;

const Radio = ({
  name,
  label,
  checked,
  disabled,
  boldText,
  onClick,
  onChange
}) => (
  <Wrapper className='radio-wrapper'>
    <Input
      type='radio'
      id={name}
      name={name}
      checked={checked}
      disabled={disabled}
      aria-checked={checked}
      onChange={onChange}
    />
    <RadioWrapper onClick={() => !disabled && onClick(!checked)}>
      <Round disabled={disabled} checked={checked} />
      {label && (
        <Label disabled={disabled} boldText={boldText}>
          {label}
        </Label>
      )}
    </RadioWrapper>
  </Wrapper>
);

Radio.defaultProps = {
  name: '',
  label: '',
  checked: false,
  disabled: false,
  boldText: false,
  onClick: () => null,
  onChange: () => null
};

export default Radio;
