import type { VFC } from 'react';
import { useEffect, useState } from 'react';
import { DatePicker as CarbonDatePicker, DatePickerInput as CarbonDatePickerInput } from 'carbon-components-react';
import moment from 'moment';

import { DATE_PICKER_FORMAT_DEFAULT } from '@gofan/constants/date';

import styles from './date-picker.module.scss';

export const DATE_PICKER_PLACEHOLDER = 'mm/dd/yyyy';

interface DatePickerProps {
  id: string;
  ref?: any;
  labelText: string;
  value: string;
  minDate?: string;
  maxDate?: string;
  disabled?: boolean;
  invalid?: boolean;
  invalidText?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
}

export const DatePicker: VFC<DatePickerProps> = ({
  id,
  ref,
  labelText,
  value,
  minDate = '',
  maxDate = '',
  disabled,
  invalid,
  invalidText,
  onChange,
  onBlur
}) => {
  const [dateVal, setDate] = useState('');
  const [dateInput, setDateInput] = useState('');
  const [changed, setChanged] = useState(false);

  const isValidDate = (val: string): boolean => {
    const date = moment(val, DATE_PICKER_FORMAT_DEFAULT, true);
    return date.isValid();
  };

  const getDate = (val: string): string => (isValidDate(val) ? val : '');

  useEffect(() => {
    const date = getDate(value);
    setDate(date);
    setDateInput(date);
  }, [value]);

  useEffect(() => {
    if (changed) {
      setChanged(false);
      _onBlur();
    }
  }, [changed]);

  return (
    <div className={styles.container}>
      <CarbonDatePicker datePickerType='single' minDate={minDate} maxDate={maxDate} value={dateVal}>
        <CarbonDatePickerInput
          id={id}
          ref={ref}
          labelText={labelText}
          placeholder={DATE_PICKER_PLACEHOLDER}
          value={dateInput}
          disabled={disabled}
          invalid={invalid}
          invalidText={invalidText}
          onChange={event => {
            setDateInput(event?.target?.value);
          }}
          onBlur={event => {
            const date = getDate(event?.target?.value);
            setDateInput(date);
            _onChange(date);
            setChanged(true);
          }}
        />
      </CarbonDatePicker>
    </div>
  );

  function _onChange(date: string) {
    if (typeof onChange === 'function') onChange(date);
  }

  function _onBlur() {
    if (typeof onBlur === 'function') onBlur();
  }
};
