// @flow
import BaseDTO from './BaseDTO';

class ProductSalesInfoDTO extends BaseDTO {
  constructor({ productId, saleCapacity, saleQuantity, soldOut }) {
    super({ productId, saleCapacity, saleQuantity, soldOut });
  }

  get productId() {
    return this.raw.productId;
  }

  get saleCapacity() {
    return this.raw.saleCapacity;
  }

  get saleQuantity() {
    return this.raw.saleQuantity;
  }

  get soldOut() {
    return this.raw.soldOut;
  }
}

export default ProductSalesInfoDTO;
