export const STRINGS = {
  ERRORS_MESSAGE: {
    EVENT_DATE: {
      REQUIRED: 'Event date is required.',
      INVALID: 'Enter a valid event date.'
    },
    START_DATE: {
      REQUIRED: 'Start date is required.',
      INVALID: 'Enter a valid start date.',
      BEFORE_END_DATE: 'Start date must come before End date.'
    },
    START_TIME: {
      REQUIRED: 'Start time is required.',
      INVALID: 'Enter a valid start time.',
      BEFORE_END_TIME: 'Start time must come before End time'
    },
    END_DATE: {
      REQUIRED: 'End date is required.',
      INVALID: 'Enter a valid end date.',
      AFTER_START_DATE: 'End date must come after Event date.'
    },
    END_TIME: {
      REQUIRED: 'End time is required.',
      INVALID: 'Enter a valid end time.',
      AFTER_START_TIME: 'End time must come after Event time.'
    }
  }
};

export const EVENT_START_TIME_TYPE = {
  ALL_TEAMS: 'SAME_TIME',
  EACH_TEAM: 'DIFFERENCE_TIME'
};

export const TIME_PICKER_PATTERN = /(0[1-9]|1[012]):[0-5][0-9](\s)(AM|PM)/;
