export const parseString = (str, unTrimText, excValue) => {
  if (
    str === null ||
    str === undefined ||
    typeof num === 'object' ||
    typeof num === 'function'
  ) {
    return excValue;
  }

  return unTrimText ? `${str}` : `${str}`.trim();
};

export const parseNumber = (num, excValue) => {
  if (
    num === '' ||
    num === null ||
    num === undefined ||
    typeof num === 'boolean' ||
    typeof num === 'object' ||
    typeof num === 'function'
  ) {
    return excValue;
  }

  if (typeof num === 'number') {
    return num;
  }

  const parseNum = typeof num === 'string' ? num * 1 : Number(num);
  if (!Number.isFinite(parseNum) || Number.isNaN(parseNum)) {
    return excValue;
  }
  return parseNum;
};
