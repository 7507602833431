import type { AccountDTO } from '@gofan/api/accounts';
import type { Notification } from './types';
import type Account from '../../api/model/Account';

export const ADD_NOTIFICATION = 'root/ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'root/REMOVE_NOTIFICATION';

export const SHOW_ACCOUNT_INFO = 'root/SHOW_ACCOUNT_INFO';
export const REMOVE_ACCOUNT_INFO = 'root/REMOVE_ACCOUNT_INFO';
export const UPDATE_SELECTED_SCHOOLS = 'root/UPDATE_SELECTED_SCHOOLS';
export const ADD_ARBITER_FLOW_PATH = 'root/ADD_ARBITER_FLOW_PATH';
export const REMOVE_ARBITER_FLOW_PATH = 'root/REMOVE_ARBITER_FLOW_PATH';

interface AddNotificationAction {
  type: typeof ADD_NOTIFICATION;
  payload: {
    notification: Notification;
    timeout?: number;
  };
}

interface RemoveNotificationAction {
  type: typeof REMOVE_NOTIFICATION;
  payload: {
    id: number;
  };
}

interface ShowAccountInfo {
  type: typeof SHOW_ACCOUNT_INFO;
  payload: {
    account: Account | AccountDTO | undefined;
  };
}

interface RemoveAccountInfo {
  type: typeof REMOVE_ACCOUNT_INFO;
}

interface UpdateSelectedSchools {
  type: typeof UPDATE_SELECTED_SCHOOLS;
  payload: {
    accounts: Account[] | AccountDTO[] | undefined;
  };
}

interface AddPreviousPath {
  type: typeof ADD_ARBITER_FLOW_PATH;
  payload: {
    arbiterFlowPath: string;
  };
}

interface RemovePreviousPath {
  type: typeof REMOVE_ARBITER_FLOW_PATH;
}

export type RootActionTypes =
  | AddNotificationAction
  | RemoveNotificationAction
  | ShowAccountInfo
  | RemoveAccountInfo
  | UpdateSelectedSchools
  | AddPreviousPath
  | RemovePreviousPath;
