// @ts-expect-error: CheckboxGroup TypeScript typings not yet supported
import { CheckboxGroup as CarbonCheckboxGroup } from '@carbon/react';
import classNames from 'classnames';

import type { ReactNode } from 'react';

import './CheckboxGroup.scss';

export interface CheckboxGroupProps {
  children?: ReactNode;
  className?: string;
  helperText?: string;
  invalid?: boolean;
  invalidText?: ReactNode;
  legendId?: ReactNode;
  legendText?: ReactNode;
  orientation?: string;
  readOnly?: boolean;
  warn?: string;
  warnText?: ReactNode;
}

export const CheckboxGroup = (props: CheckboxGroupProps) => (
  <CarbonCheckboxGroup {...props} className={classNames('gf--checkbox-group', props.className)} />
);
