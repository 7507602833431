import { Suspense, lazy, useRef, useState, useContext, useEffect, useCallback } from 'react';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';

import { Auth } from '@auth/Auth.container';
import CheckEmailV2 from '../Auth/components/CheckEmailV2';
import { ConfirmModalProvider } from '@app/modules/seasons/components/duplicate-confirm';
import { Concessions } from '@app/modules/concessions/Concessions.container';
import CreateContainer from '@app/modules/create/Create.container';
import { Streaming } from '@streaming/Streaming.container';
import { Rates } from '@modules/rates/rates.container';
import { Sponsorships } from '@app/modules/sponsorships/Sponsorships.container';
import { PendoSupportForm } from '@gofan/components/PendoSupportForm';
import { FanEngagementRoutes } from '@hq/connect/src/modules/fan-engagement/fan-engagement.routes';

import Header from '../Header';
import BodyContainer from '../BodyContainer';
import AuthGuardedRoute from '../AuthGuardedRoute';
import UnAuthGuardedRoute from '../UnauthGuardedRoute';
import IdleTimer from 'react-idle-timer';
import { SkeletonPlaceholder } from '@gofan/components';

import { useFeatureFlags, useLDClient, FEATURE_FLAGS } from '@gofan/hooks';
import CacheService from '@app/api/services/CacheService';
import { config } from '@gofan/constants';
import { PAGES } from '../../../../config/routes';
import { errorMessages } from '../../../../commons';
import { RootContext } from '../../../../RootContext';

import '../../theme.scss';

const Accounts = lazy(() => import('@accounts/accounts.container'));
const Dashboard = lazy(() => import('@dashboard/dashboard.container'));
const Seasons = lazy(() => import('@seasons/seasons.container'));
const SeasonManagement = lazy(() => import('@season-management/SeasonManagement.container'));
const FanManagement = lazy(() => import('@fan-management/fan-management.container'));
const Financial = lazy(() => import('@financial/financial.container'));
const AccountingHub = lazy(() => import('@accounting-hub/accounting-hub.container'));
const EventIntegrations = lazy(() => import('@modules/event-integrations_V2/event-integrations.container'));
const BulkUploadEventsContainer = lazy(() => import('@modules/bulk-upload-events/bulk-upload-events.container'));
const SeasonRenewal = lazy(() => import('@modules/season-renewal/season-renewal.container'));
const Balances = lazy(() => import('@accounting-hub/views/balances/balances.container'));
const BulkEditEvents = lazy(() => import('@bulk-edit-events/bulk-edit-events.container'));
const PlayOnSite = lazy(() => import('@playon-site/playon-site.container'));
const PracticeSchedule = lazy(() => import('@practice-schedule/practice-schedule.container'));
const EventLanding = lazy(() => import('../../../EventLanding'));
const EventReservedSeating = lazy(() => import('../../../EventReservedSeating'));
const ViewEventContainer = lazy(() =>
  import('@events/pages/ViewEventContainer').then(module => ({
    default: module.ViewEventContainer
  }))
);
const CreateEventContainer = lazy(() =>
  import('@events/pages/CreateEventContainer').then(module => ({
    default: module.CreateEventContainer
  }))
);
const EditEventContainer = lazy(() =>
  import('@events/pages/EditEventContainer').then(module => ({
    default: module.EditEventContainer
  }))
);

const Wrapper = props => {
  const context = useContext(RootContext);
  const inactiveTimer = useRef(null);
  const location = useLocation();
  const ldClient = useLDClient();
  const [initialized, setInitialized] = useState(typeof props.featureFlag !== 'string');
  const history = useHistory();

  useEffect(() => {
    const initializeLaunchDarkly = async () => {
      await ldClient.waitForInitialization();
      setInitialized(true);
    };

    if (ldClient && !initialized) initializeLaunchDarkly();
  }, [initialized, ldClient]);

  const { [props.featureFlag]: featureFlagEnabled } = useFeatureFlags();

  useEffect(() => {
    if (initialized && typeof props.featureFlag === 'string' && !featureFlagEnabled) {
      history.replace(PAGES.error.error404.path);
    }
  }, [initialized, featureFlagEnabled]);

  useEffect(() => {
    if (context.sideNavIsExpanded) context.setSideNavIsExpanded(false);
  }, [location]);

  const onIdle = useCallback(() => {
    inactiveTimer.current = setTimeout(() => {
      context.clearAuthorization();
      context.setSessionState({
        message: errorMessages.TIMEOUT_SESSION_MESSAGE
      });
    }, config.TIMEOUT_WHEN_INACTIVE);
  }, []);

  const onActive = useCallback(() => {
    clearTimeout(inactiveTimer.current);
  }, []);

  const toggleSideNav = useCallback(() => {
    context.setSideNavIsExpanded(prevState => !prevState);
  }, []);

  const onGetLocationSuccess = useCallback(position => {
    const currentPosition = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    };
    CacheService.putDataToCache(
      CacheService.USER_COORDINATE_CACHE_NAME,
      CacheService.CURRENT_COORDINATE_CACHE_NAME,
      currentPosition
    );
  }, []);

  const onGetLocationError = useCallback(() => {
    CacheService.deleteCachedData(CacheService.POSITION_CACHE_NAME);
  }, []);

  useEffect(() => {
    clearTimeout(inactiveTimer.current);
    if (!navigator.geolocation) {
      return;
    }
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };

    window.navigator.geolocation.getCurrentPosition(onGetLocationSuccess, onGetLocationError, options);
    window.navigator.geolocation.watchPosition(onGetLocationSuccess, () => {}, options);
  }, []);

  return (
    initialized && (
      <>
        <IdleTimer crossTab onActive={onActive} onIdle={onIdle} timeout={config.TIMEOUT_TO_START_IDLE} />
        <PendoSupportForm currentUser={context.currentUser} />
        <Header history={props.history} toggleSideMenu={toggleSideNav} isSideMenuExpanded={context.sideNavIsExpanded} />
        <BodyContainer
          {...props}
          currentUser={context.currentUser}
          isFixedNav
          isSideMenuExpanded={context.sideNavIsExpanded}
          featureFlag={props.featureFlag}
        >
          <Suspense fallback={<SkeletonPlaceholder />}>{props.children}</Suspense>
        </BodyContainer>
      </>
    )
  );
};

const Routes = () => [
  config.BETA_READINESS_MODE ? (
    <UnAuthGuardedRoute
      key={PAGES.login.root.path}
      path={PAGES.login.root.path}
      render={props => <Auth {...props} />}
    />
  ) : null,
  <UnAuthGuardedRoute
    key={PAGES.checkEmail.root.path}
    path={PAGES.checkEmail.root.path}
    render={props => <CheckEmailV2 {...props} />}
  />,
  <AuthGuardedRoute
    key={PAGES.accountsV2.root.path}
    path={PAGES.accountsV2.root.path}
    render={props => (
      <Wrapper {...props}>
        <Accounts />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    exact
    key={PAGES.eventsV2.root.path}
    path={PAGES.eventsV2.root.path}
    render={props => (
      <Wrapper {...props}>
        <EventLanding {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    key={PAGES.eventsV2.add.path}
    path={PAGES.eventsV2.add.path}
    render={props => (
      <Wrapper {...props}>
        <CreateEventContainer {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    exact
    key={PAGES.eventsV2.view.path}
    path={PAGES.eventsV2.view.path}
    render={props => (
      <Wrapper {...props}>
        <ViewEventContainer {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    exact
    key={PAGES.eventsV2.edit.path}
    path={PAGES.eventsV2.edit.path}
    render={props => (
      <Wrapper {...props}>
        <EditEventContainer {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    exact
    key={PAGES.eventsV2.reservedSeating.path}
    path={PAGES.eventsV2.reservedSeating.path}
    render={props => (
      <Wrapper {...props}>
        <EventReservedSeating {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    exact
    key={PAGES.eventsV2.viewAllEvents.path}
    path={PAGES.eventsV2.viewAllEvents.path}
    render={props => (
      <Wrapper {...props}>
        <EventLanding {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    key={PAGES.concessions.root.path}
    path={PAGES.concessions.root.path}
    render={props => (
      <Wrapper {...props}>
        <Concessions {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    key={PAGES.playOnSite.root.path}
    path={PAGES.playOnSite.root.path}
    render={props => (
      <Wrapper {...props}>
        <PlayOnSite {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    key={PAGES.sponsorships.root.path}
    path={PAGES.sponsorships.root.path}
    render={props => (
      <Wrapper featureFlag={FEATURE_FLAGS.enableSponsorship} {...props}>
        <Sponsorships {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    key={PAGES.practiceSchedule.root.path}
    path={PAGES.practiceSchedule.root.path}
    render={props => (
      <Wrapper featureFlag={FEATURE_FLAGS.myAccountNewUiEnable} {...props}>
        <PracticeSchedule {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    key={PAGES.seasonManagement.root.path}
    path={PAGES.seasonManagement.root.path}
    render={props => (
      <Wrapper {...props}>
        <SeasonManagement {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    key={PAGES.season.viewAllSeasons.path}
    path={PAGES.season.viewAllSeasons.path}
    render={props => (
      <Wrapper {...props}>
        <ConfirmModalProvider>
          <Seasons {...props} />
        </ConfirmModalProvider>
      </Wrapper>
    )}
  />,
  // Seasons Reserved Seating
  <AuthGuardedRoute
    exact
    key={PAGES.season.reservedSeating.path}
    path={PAGES.season.reservedSeating.path}
    render={props => (
      <Wrapper {...props}>
        <EventReservedSeating isSeason {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    path={PAGES.financial.root.path}
    key={PAGES.financial.root.path}
    render={props => (
      <Wrapper {...props}>
        <Financial {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    path={PAGES.accountingHub.root.path}
    key={PAGES.accountingHub.root.path}
    render={props => (
      <Wrapper {...props}>
        <AccountingHub {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    key='dashboard'
    path={PAGES.dashboard.root.path}
    render={props => (
      <Wrapper {...props}>
        <Dashboard {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    exact
    key={PAGES.fans.root.path}
    path={PAGES.fans.root.path}
    render={props => (
      <Wrapper {...props}>
        <FanManagement {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    exact
    key={PAGES.eventIntegrations.root.path}
    path={PAGES.eventIntegrations.root.path}
    render={props => (
      <Wrapper {...props}>
        <ConfirmModalProvider>
          <EventIntegrations {...props} />
        </ConfirmModalProvider>
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    key={PAGES.seasonRenewal.root.path}
    path={PAGES.seasonRenewal.root.path}
    render={props => (
      <Wrapper {...props}>
        <SeasonRenewal {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    exact
    key={PAGES.eventsV2.bulkUploadEvents.path}
    path={PAGES.eventsV2.bulkUploadEvents.path}
    render={props => (
      <Wrapper {...props}>
        <BulkUploadEventsContainer {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    path={PAGES.balances.root.path}
    key={PAGES.balances.root.path}
    render={props => (
      <Wrapper {...props}>
        <Balances {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    key={PAGES.rates.root.path}
    path={PAGES.rates.root.path}
    render={props => (
      <Wrapper {...props}>
        <Rates {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    key={PAGES.eventsV2.bulkEditEvents.path}
    path={PAGES.eventsV2.bulkEditEvents.path}
    render={props => (
      <Wrapper {...props}>
        <BulkEditEvents {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    key={PAGES.streaming.root.path}
    path={PAGES.streaming.root.path}
    render={props => (
      <Wrapper {...props}>
        <Streaming {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    key={PAGES.create.root.path}
    path={PAGES.create.root.path}
    render={props => (
      <Wrapper featureFlag={FEATURE_FLAGS.tempCreatePageDashbirds} {...props}>
        <CreateContainer {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    key={PAGES.connect.root.path}
    path={PAGES.connect.root.path}
    render={props => (
      <Wrapper featureFlag={FEATURE_FLAGS.tempFanEngagementConnect} {...props}>
        <FanEngagementRoutes {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    exact
    key={PAGES.home.root.path}
    path={PAGES.home.root.path}
    render={() => <Redirect to={PAGES.dashboard.root.path} />}
  />,
  <Route key='forceResetCurrentRoute' path='/forceResetCurrentRoute' component={null} />
];

export default Routes;
