import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Pagination,
  OverflowMenu,
  OverflowMenuItem,
  Spinner,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  DataTableWrapper
} from '@gofan/components';
import { useContext, useEffect, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import type { RouteChildrenProps } from 'react-router-dom';
import { RootContext } from '@app/RootContext';
import { checkPermission } from '@app/commons';
import { useRateTableConfiguration } from '@gofan/api';
import { formatDate } from '@app/utils/dateUtils';
import { isEmpty, debounce } from 'lodash';
import { Edit16, Add32, Delete16 } from '@carbon/icons-react';
import DeleteRateModal from '../../components/DeleteRateModal/DeleteRateModal';
import './rates-table.view.scss';
import { HEADERS_RATE_TABLE } from '../../constants/constants';
import moment from 'moment';
import { PAGES } from '@app/config/routes';

type RatesTableProps = RouteChildrenProps;

export const RatesTable = ({ location }: RatesTableProps) => {
  const { currentUser } = useContext(RootContext);
  const history = useHistory();
  const { newRateId } = location.state ?? {};

  const {
    searchParams,
    setSearchParams,
    selectedRate,
    setSelectedRate,
    toggleDeleteModal,
    setToggleDeleteModal,
    ratesData,
    isLoadingRatesData,
    resetSearchParams
  } = useRateTableConfiguration({ newRateId });

  const tableData = useMemo(_getTableData, [ratesData]);

  useEffect(() => {
    const clearLocationState = () => {
      history.replace({ ...location, state: undefined });
    };
    window.addEventListener('beforeunload', clearLocationState);
    return () => {
      window.removeEventListener('beforeunload', clearLocationState);
    };
  }, []);

  const role = {
    add: checkPermission({
      ifAllowedTo: 'create.rate',
      on: currentUser
    }),
    edit: checkPermission({
      ifAllowedTo: 'edit.rate',
      on: currentUser
    }),
    view: checkPermission({ ifAllowedTo: 'view.rate', on: currentUser })
  };

  if (!role.view) {
    return <Redirect to={PAGES.dashboard.root.path} />;
  }
  return (
    <DataTableWrapper className='rates-table'>
      <DeleteRateModal rate={selectedRate} open={toggleDeleteModal} onCloseModal={_onCloseModal} />
      {isLoadingRatesData && <Spinner withOverlay className='loading' />}
      <div className='rates-table--header'>
        <div className='gs--productive-heading-05-semibold gs--text-01 rates-table--header__text'>
          Rate configuration
        </div>
        <TableToolbarContent className='rates-table--tool-bar'>
          <TableToolbarSearch
            onChange={debounce(evt => _handleSearchChange(evt), 500)}
            className='rates-table--tool-bar__search'
            placeholder='Search for a rate'
          />
          {role.add && (
            <Button onClick={_onAddRate} renderIcon={Add32}>
              Add Rate
            </Button>
          )}
        </TableToolbarContent>
      </div>
      <DataTable rows={tableData} headers={HEADERS_RATE_TABLE} sortRow={_customSort}>
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <Table className='table' {...getTableProps()}>
            <TableHead className='rates-table--head'>
              <TableRow>
                {headers.map(header => (
                  <TableHeader className={header.key} {...getHeaderProps({ header, isSortable: header.isSortable })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className='rates-table--body'>
              {rows.map(row => (
                <TableRow className='rates-table--row' key={row.id}>
                  {row.cells?.map(cell => {
                    const isShowButtonDelete =
                      cell.info.header === 'menu' && _checkShowButtonDelete(row.cells[6].value);

                    return cell.info.header !== 'menu' ? (
                      <TableCell className={cell.info.header} key={cell.id}>
                        {cell.value}
                      </TableCell>
                    ) : (
                      <TableCell className={cell.info.header}>
                        {role.edit && (
                          <OverflowMenu onClick={() => setSelectedRate(_getRateById(row.id))} flipped floatingMenu>
                            <OverflowMenuItem
                              onClick={_onEditRate}
                              itemText={
                                <>
                                  <Edit16 className='rates-table--row__icon edit' /> Edit Rate
                                </>
                              }
                            />
                            {isShowButtonDelete && (
                              <OverflowMenuItem
                                onClick={_onOpenDeleteModal}
                                itemText={
                                  <>
                                    <Delete16 className='rates-table--row__icon delete' /> Delete
                                  </>
                                }
                              />
                            )}
                          </OverflowMenu>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DataTable>

      <Pagination
        backwardText='Previous page'
        forwardText='Next page'
        itemsPerPageText=''
        page={searchParams.pageIndex + 1 || 0}
        pageNumberText='Page Number'
        pageSize={searchParams.pageSize || 0}
        pageSizes={[10, 20, 30, 40, 50]}
        onChange={_onChangePageInfo}
        totalItems={ratesData?.totalElements || 0}
      />
    </DataTableWrapper>
  );

  function _checkShowButtonDelete(endDate) {
    if (!endDate) {
      return false;
    }
    return moment(endDate, 'MM/DD/YYYY').isSameOrBefore(moment());
  }

  function _onAddRate() {
    history.push(PAGES.rates.add.path);
  }

  function _onEditRate() {
    history.push(PAGES.rates.edit.path.replace(':id', selectedRate?.id));
  }

  function _customSort(cellA, cellB, { sortDirection, sortStates, key: sortKey }) {
    setSearchParams({ ...searchParams, sortBy: [{ id: sortKey, desc: sortDirection === 'DESC' }] });
  }

  function _handleSearchChange(event: { target: HTMLInputElement }) {
    if (event.target.value.trim() === '') {
      resetSearchParams();
    }
    setSearchParams({ ...searchParams, pageIndex: 0, keyword: event.target.value });
  }

  function _onChangePageInfo(info) {
    setSearchParams({ ...searchParams, pageSize: info.pageSize, pageIndex: info.page - 1 });
  }
  function _onOpenDeleteModal() {
    setToggleDeleteModal(true);
  }
  function _onCloseModal() {
    setToggleDeleteModal(false);
  }

  function _getRateById(rateId) {
    if (!ratesData?.content) {
      return null;
    }
    return ratesData?.content.find(rate => rate.id === rateId);
  }

  function _getTableData() {
    if (!ratesData?.content) {
      return [];
    }

    const rates = ratesData?.content.map(rate => {
      const productTypes = isEmpty(rate?._embedded?.['product-types']) ? [] : rate._embedded?.['product-types'];
      return {
        ...rate,
        rateAmount: parseFloat(rate.rateAmount).toFixed(2),
        ratePercent: parseFloat(rate.ratePercent).toFixed(2),
        productTypeNames: (productTypes || []).map(item => item.name).join(', '),
        maxPrice: `${parseFloat(rate.minPrice).toFixed(2)} - ${parseFloat(rate.maxPrice).toFixed(2)}`,
        startDate: formatDate({ date: rate.startDate, format: 'MM/DD/YYYY', isUtc: false }),
        endDate: formatDate({ date: rate.endDate, format: 'MM/DD/YYYY', isUtc: false })
      };
    });

    return rates;
  }
};
