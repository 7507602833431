import * as React from 'react';
import { Grid, Header, Container, Form } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import FastForm from '../../../commons/FastForm';
import { getUser, putUser } from '../../../api';
import PasswordChangeModal from './PasswordChangeModal';
import { phoneTypeOptionsForSelect } from '../../../commons/utils/phoneTypeOptions';

const Wrapper = styled.div`
  background-color: white;
`;

interface Props {
  whoami: string;
  editing: boolean;
  changePassword: string;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  history: any;
}

export default ({
  whoami,
  editing,
  changePassword,
  setEditing,
  history
}: Props) => {
  const formInitializer = () =>
    new Promise(resolve => {
      getUser(whoami).then((res: models.UserDTO) => {
        const addl = {
          ...res,
        };
        return resolve(addl);
      });
    });
  const [modalOpen, setModalOpen] = React.useState(changePassword === 'true');
  const cancelAction = React.useCallback(() => {
    setEditing(false);
    window.location.reload();
  }, []);
  const submitAction = (values: models.UserDTO): void => {
    putUser(values)
      .then(() => {
        setEditing(false);
      })
      .catch(e => console.log(e));
  };
  return (
    <Wrapper>
      <PasswordChangeModal
        open={modalOpen}
        setModalOpen={setModalOpen}
        history={history}
        whoami={whoami}
      />
      <Container fluid style={{ padding: '15px' }}>
        <FastForm
          cancelAction={cancelAction}
          submitAction={submitAction}
          viewOnly={!editing}
          formInitializer={formInitializer}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as='h4'>User Information</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>
                <FastForm.Static label='Role' formKey='role' />
                <FastForm.Static label='Status' formKey='status' />
                <FastForm.Input label='First Name' formKey='firstName' />
                <FastForm.Input label='Last Name' formKey='lastName' />

                <FastForm.Input label='Title' formKey='title' />
                <FastForm.Static label='Email' formKey='email' />
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <FastForm.Phone
                        label='Phone Number'
                        formKey='phoneNumber'
                      />
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <FastForm.Dropdown
                        label='Type'
                        formKey='phoneType'
                        options={phoneTypeOptionsForSelect}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={4}>
                <FastForm.Static label='User Since' formKey='createdAt' />
                <Form.Field>
                  <label>Change Password</label>
                  <p style={{ fontSize: '1em', margin: '12px 0px 20px 0px' }}>
                    <Link to='/profile' onClick={() => setModalOpen(true)}>
                      Change Password
                    </Link>
                  </p>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </FastForm>
      </Container>
    </Wrapper>
  );
};
