import { useRef } from 'react';
import { Flatfile } from '@flatfile/sdk';
import moment from 'moment-timezone';

import { config } from '@gofan/constants';
import { getRefreshToken, getToken } from '@gofan/api';
import { isSafari } from '@app/utils/behaviorUtils';
import { generateError } from '@app/utils/alertUtils';
import EventService from '@events/services/events.service';

import type { IImportSessionEvents, IUser } from '@flatfile/sdk';
import type { UserDTO } from '@gofan/api/users';

export const useFlatFile = ({
  currentUser,
  addNotification
}: {
  currentUser: UserDTO;
  addNotification: (data: any) => void;
}) => {
  const USER_INFO = {
    id: currentUser?.email,
    email: currentUser?.email,
    name: `${currentUser?.firstName} ${currentUser?.lastName}`
  } as IUser;

  const currentBatchId = useRef('');

  const importEventDataFromUser = async (
    { embedId, portalKey, action }: { embedId: string; portalKey: string; action: 'create' | 'update' },
    doneFn?: (items: any) => void
  ) => {
    const authToken = await getToken();

    const accessToken = await EventService.getFlatfileToken({
      user: USER_INFO,
      org: config.apiFlatFile.ORG_INFO,
      embedId,
      privateKey: portalKey
    });

    Flatfile.requestDataFromUser({
      user: USER_INFO,
      org: config.apiFlatFile.ORG_INFO,
      token: accessToken,
      onInit: ({ session }) => {
        session.updateEnvironment({
          baseUrl: config.apiGateway.URL,
          token: authToken,
          timeZone: moment.tz.guess()
        });

        if (isSafari()) {
          const el = document.querySelector('.flatfile-sdk iframe') as HTMLIFrameElement;
          if (el) {
            el.style.height = '100%';
          }
        }
      },
      onData(_, next) {
        next();
      },
      onComplete(payload) {
        if (payload.batchId !== currentBatchId.current) {
          if (typeof doneFn === 'function') doneFn({ loading: true });
          currentBatchId.current = payload.batchId;
          _onUploadBulkEvents(payload, action);
        }
      },
      onError(error) {
        addNotification(generateError(error));
      }
    });

    const _onUploadBulkEvents = async (payload: IImportSessionEvents['complete'], bulkAction: 'create' | 'update') => {
      const refreshToken = await getRefreshToken();
      await EventService.bulkUploadEvents({
        batchId: payload.batchId,
        email: currentUser.email,
        authToken: authToken ?? '',
        refreshToken: refreshToken ?? '',
        uploadedAt: new Date().toISOString(),
        action: bulkAction
      });

      if (typeof doneFn === 'function') doneFn({ loading: false, batchId: payload.batchId, action });
    };
  };

  const importEventCreationDataFromUser = (doneFn?: (items: any) => void) =>
    importEventDataFromUser(
      {
        embedId: config.apiFlatFile.uploadEvent.EMBED_ID,
        portalKey: config.apiFlatFile.uploadEvent.PORTAL_KEY,
        action: 'create'
      },
      doneFn
    );

  const importEventEditingDataFromUser = (doneFn?: (items: any) => void) =>
    importEventDataFromUser(
      {
        embedId: config.apiFlatFile.uploadEditEvent.EMBED_ID,
        portalKey: config.apiFlatFile.uploadEditEvent.PORTAL_KEY,
        action: 'update'
      },
      doneFn
    );

  return {
    importEventDataFromUser,
    importEventCreationDataFromUser,
    importEventEditingDataFromUser
  };
};
