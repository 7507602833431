import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Settings32, ChevronDown32, ChevronUp32 } from '@carbon/icons-react';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';

import { checkPermission } from '@app/commons';

import { useMultipleAccounts } from '@gofan/api/accounts';
import { USER_ROLE_COACH, checkInternalUser, hasOtherRoleExcept, getActiveUserAccountContext } from '@gofan/api/users';
import { useFeatureFlags, FEATURE_FLAGS } from '@gofan/hooks/useFeatureFlags';

import { SIDE_MENU_ITEMS, SYSTEM_MENU_ITEMS, CACHE_TIME_IN_DAY } from './constants';
import styles from './styles';

const SideMenuContainer = styled.nav`
  ${styles.sideMenuV2}
`;

const SideMenuIcon = styled(Link)`
  ${styles.sideMenuIcon}
  background-color: ${props => (props.selected ? '#2c2c2c' : '#000')};
`;

const SideMenuItem = styled(Link)`
  ${styles.sideMenuItem}
  background-color: ${props => (props.selected ? '#2c2c2c' : '#000')};
`;

const SvgContainer = styled.div`
  ${styles.svgContainer}
`;

export default props => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!props.hasIconMenu && !props.isSideMenuExpanded) return <></>;

  const currentUser = !isEmpty(props.currentUser) ? props.currentUser : {};
  const isInternalUser = checkInternalUser(currentUser?.role);
  const hasOtherRoles = hasOtherRoleExcept(currentUser, [USER_ROLE_COACH]);

  const sideMenuItems = hasOtherRoles ? SIDE_MENU_ITEMS : SIDE_MENU_ITEMS.filter(item => item.id === 'accounts');
  const {
    [FEATURE_FLAGS.opTournamentsOptionDashbirds]: tournamentsOption,
    [FEATURE_FLAGS.tempDistrictConferenceUnicorn]: enableDistrictUnicorn
  } = useFeatureFlags();

  const activeAccountIds = useMemo(() => {
    if (tournamentsOption || (enableDistrictUnicorn && !isInternalUser)) {
      const accIds = getActiveUserAccountContext(currentUser).map(context => context.accountId);
      return uniq(accIds ?? [])?.sort((a, b) => `${a}`?.localeCompare(`${b}`, 'en'));
    }
    return [];
  }, [currentUser, isInternalUser, enableDistrictUnicorn, tournamentsOption]);

  const { data: accounts, isFetching } = useMultipleAccounts({
    accountIds: activeAccountIds,
    queryOptions: {
      enabled: !isEmpty(activeAccountIds),
      cacheTime: CACHE_TIME_IN_DAY,
      staleTime: CACHE_TIME_IN_DAY
    }
  });

  return (
    <SideMenuContainer isSideMenuExpanded={props.isSideMenuExpanded}>
      {sideMenuItems.map(item => {
        const pathname = item.path ? item.path : item.calculatePath(currentUser, { enableDistrictUnicorn, accounts });
        const selected = props.location.pathname?.startsWith(pathname);
        const Icon = item.icon;

        if (item.id === 'tournaments') {
          return tournamentsOption && !isFetching ? (
            <TournamentsMenuItem
              isSideMenuExpanded={props.isSideMenuExpanded}
              pathname={pathname}
              selected={selected}
              Icon={Icon}
              {...item}
              key={item.id}
              accounts={accounts}
            />
          ) : null;
        }

        if (item.id === 'seasons' && enableDistrictUnicorn && isFetching) {
          return null;
        }

        if (item.permissions && !checkPermission({ ifAllowedTo: item.permissions })) {
          return null;
        }

        return props.isSideMenuExpanded ? (
          <SideMenuItem to={pathname} selected={selected} key={item.id}>
            <SvgContainer>
              <Icon />
            </SvgContainer>
            <span>{item.text}</span>
          </SideMenuItem>
        ) : (
          <SideMenuIcon to={pathname} selected={selected} key={item.id}>
            <Icon />
            <span>{item.text}</span>
          </SideMenuIcon>
        );
      })}

      {checkPermission({ ifAllowedTo: 'view.systemSidebar' }) ? <SystemItems {...props} /> : null}
    </SideMenuContainer>
  );
};

const TournamentsMenuItem = ({ isSideMenuExpanded, pathname, selected, Icon, text, permissions, accounts }) => {
  const hasPermission = checkPermission({
    ifAllowedTo: permissions,
    on: { accounts: isEmpty(accounts) ? [] : accounts }
  });

  if (!hasPermission) return null;

  return isSideMenuExpanded ? (
    <SideMenuItem to={pathname} selected={selected}>
      <SvgContainer>
        <Icon />
      </SvgContainer>
      <span>{text}</span>
    </SideMenuItem>
  ) : (
    <SideMenuIcon to={pathname} selected={selected}>
      <Icon />
      <span>{text}</span>
    </SideMenuIcon>
  );
};

const SystemDropDownButton = styled.button`
  ${styles.sideMenuItem}
  padding-right: 24px;
`;

const SystemDropDownIconButton = styled.button`
  ${styles.sideMenuIcon}
  padding: 8px 8px 2px 8px;
`;

const SystemItems = props => {
  const [isExpanded, setIsExpanded] = useState(() => {
    let expand = false;

    SYSTEM_MENU_ITEMS.forEach(item => {
      if (!expand && props.location.pathname?.startsWith(item.path)) expand = true;
    });

    return expand;
  });

  return (
    <>
      {props.isSideMenuExpanded ? (
        <SystemDropDownButton onClick={() => setIsExpanded(prevState => !prevState)}>
          <SvgContainer>
            <Settings32 />
          </SvgContainer>

          <span style={{ marginRight: 'auto' }}>Systems</span>

          {isExpanded ? <ChevronUp32 /> : <ChevronDown32 />}
        </SystemDropDownButton>
      ) : (
        <SystemDropDownIconButton onClick={() => setIsExpanded(prevState => !prevState)}>
          <SvgContainer>
            <Settings32 />
          </SvgContainer>

          <span style={{ marginTop: '2px' }}>Systems</span>

          {isExpanded ? <ChevronUp32 /> : <ChevronDown32 />}
        </SystemDropDownIconButton>
      )}

      {isExpanded
        ? SYSTEM_MENU_ITEMS.map(item => {
            const selected = props.location.pathname?.startsWith(item.path);
            return (
              <SideMenuItem to={item.path} selected={selected} style={{ justifyContent: 'center' }} key={item.id}>
                <span style={{ fontSize: props.isSideMenuExpanded ? '16px' : '13px' }}>{item.text}</span>
              </SideMenuItem>
            );
          })
        : null}
    </>
  );
};
