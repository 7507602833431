import axios from '@api/api/axios';
import {
  EVENT_SCHEDULES_SEARCH,
  EVENT_MONITOR_INDICATOR,
  PATCH_GLOBAL_EVENT,
  GET_GLOBAL_EVENT_PARTNER_INSIGHT,
  GET_EVENT_PARTNER_SALES_INFO,
  IGNORE_SCHEDULE,
  RESTORE_SCHEDULE
} from '@api/api/api';
import type {
  EventScheduleDTO,
  EventScheduleSearchDTO,
  EventScheduleSeasonDTO,
  EventSchedulePartnerInsightDTO,
  EventPartnerSalesInfoDTO,
  EventIgnoreDTO,
  EventRestoreDTO
} from '@modules/event-integrations_V2/models/event-schedule.model';
import type { GlobalEventDTO, GlobalEventStatus } from '@seasons/models/season.model';

class EventScheduleRepository {
  searchEventByParams = (body: EventScheduleSearchDTO): Promise<EventScheduleSeasonDTO[]> =>
    axios.huddleAxiosInstanceProxy.post<EventScheduleSeasonDTO[]>(
      EVENT_SCHEDULES_SEARCH,
      body as any,
      axios.huddleAxiosInstanceProxy.partnerConfig,
      true
    );

  getEventMonitorIndicator = (
    eventScheduleSearch: EventScheduleSearchDTO,
    manualErrorHandling?: boolean
  ): Promise<boolean> =>
    axios.huddleAxiosInstanceProxy.post<boolean>(
      EVENT_MONITOR_INDICATOR,
      eventScheduleSearch as any,
      axios.huddleAxiosInstanceProxy.partnerConfig,
      manualErrorHandling
    );

  getEventScheduleSeason = (
    eventScheduleSearch: Partial<EventScheduleSearchDTO>,
    manualErrorHandling?: boolean
  ): Promise<EventScheduleDTO[]> =>
    axios.huddleAxiosInstanceProxy.post<EventScheduleDTO[], Partial<EventScheduleSearchDTO>>(
      EVENT_SCHEDULES_SEARCH,
      eventScheduleSearch,
      axios.huddleAxiosInstanceProxy.partnerConfig,
      manualErrorHandling
    );

  updateGlobalEvent = (id: string, payload: Partial<GlobalEventStatus>) =>
    axios.huddleAxiosInstanceProxy.patch<GlobalEventDTO, Partial<GlobalEventStatus>>(
      PATCH_GLOBAL_EVENT.replace('{id}', id),
      payload,
      axios.huddleAxiosInstanceProxy.partnerConfig
    );

  getGlobalEventPartnerInsight = (searchParams: EventScheduleSearchDTO, manualErrorHandling?: boolean) =>
    axios.huddleAxiosInstanceProxy.get<EventSchedulePartnerInsightDTO[]>(
      GET_GLOBAL_EVENT_PARTNER_INSIGHT,
      {
        ...axios.huddleAxiosInstanceProxy.partnerConfig,
        params: {
          ...searchParams,
          partnerNames: searchParams.partnerNames?.join(','),
          schoolIds: searchParams.schoolIds?.join(',')
        }
      },
      manualErrorHandling
    );

  getEventPartnerSalesInfo = (searchParams: EventScheduleSearchDTO, manualErrorHandling?: boolean) =>
    axios.huddleAxiosInstanceProxy.get<EventPartnerSalesInfoDTO[]>(
      GET_EVENT_PARTNER_SALES_INFO,
      {
        params: {
          ...searchParams,
          partnerNames: searchParams.partnerNames?.join(','),
          schoolIds: searchParams.schoolIds?.join(',')
        }
      },
      manualErrorHandling
    );

  ignoreSchedule = (payload: EventIgnoreDTO, manualErrorHandling?: boolean): Promise<boolean> =>
    axios.huddleAxiosInstanceProxy.put<any>(
      IGNORE_SCHEDULE,
      payload,
      axios.huddleAxiosInstanceProxy.partnerConfig,
      manualErrorHandling
    );

  restoreSchedule = (payload: EventRestoreDTO, manualErrorHandling?: boolean): Promise<boolean> =>
    axios.huddleAxiosInstanceProxy.put<any>(
      RESTORE_SCHEDULE,
      payload,
      axios.huddleAxiosInstanceProxy.partnerConfig,
      manualErrorHandling
    );
}

export default new EventScheduleRepository();
