import type { TeamDTO, TeamResponseDTO } from './teams.model';

export const mapTeamResponseToTeamDto = (team: TeamResponseDTO): TeamDTO => ({
  id: team.id,
  name: `${team.gender} ${team.levelName}`,
  ticketTypesValue: '3 ticket types',
  mappedToCameraValue: 'Default camera',
  activityId: team.activityId?.toString() as string,
  activity: team.activityName as string,
  gender: team.gender,
  level: team.levelName as string,
  levelId: team.levelId as number,
  season: team.seasonName as string
});
