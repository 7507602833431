import {
  initDateTimeValues,
  startDateSchemaBuilder,
  startTimeSchemaBuilder
} from '@app/commons/generic-components/date-time-form/utils';
import * as yup from 'yup';
import isEmpty from 'lodash/isEmpty';

import { SPECIAL_CHARACTERS_REGEX } from '@gofan/utils';
import STRINGS from '@events/strings';

import type { EventDTO } from '@gofan/api';
import type { EventDateTime } from '@app/commons/generic-components/date-time-form/utils';

export interface EventDuplicationValues extends EventDateTime {
  name: string;
}

export const initEventDuplicationValues = (event: EventDTO): EventDuplicationValues => ({
  name: event.name,
  ...initDateTimeValues(event)
});

export const eventNameSchemaBuilder = () =>
  yup
    .string()
    .test('invalidEventName', (name, testContext) => {
      const nameValue = (name ?? '').trim();
      let errorMessage = '';

      if (nameValue.length === 0) return true;

      if (nameValue.length < 3 || nameValue.length > 100) {
        errorMessage = STRINGS.ERRORS_MESSAGE.ALPHANUMERIC_SPECIAL_CHARACTERS;
      }

      return isEmpty(errorMessage)
        ? true
        : testContext.createError({
            path: testContext.path,
            message: errorMessage
          });
    })
    .matches(SPECIAL_CHARACTERS_REGEX, {
      message: STRINGS.ERRORS_MESSAGE.ALPHANUMERIC_SPECIAL_CHARACTERS,
      excludeEmptyStrings: true
    });

export const validateEventDuplication = yup.object().shape({
  startDate: startDateSchemaBuilder(),
  startTime: startTimeSchemaBuilder(),
  name: eventNameSchemaBuilder()
});
