import { Analytics32, Event32, User32, Money32, Trophy32, CrossTab32, Account32 } from '@carbon/icons-react';
import TournamentsSvg from '../../../../assets/images/tournaments-image.svg';

import { PAGES } from '../../../../config/routes';
import { getRootEventPath, getRootSeasonPath, getRootTournamentPath } from '../../../../api/services/UserService';

export const CACHE_TIME_IN_DAY = 86400000;

export const SIDE_MENU_ITEMS = [
  {
    id: 'dashboard',
    text: 'Dashboard',
    icon: Analytics32,
    path: PAGES.dashboard.root.path
  },
  {
    id: 'accounts',
    text: 'Accounts',
    icon: User32,
    path: PAGES.accountsV2.root.path
  },
  {
    id: 'refunds',
    text: 'Refunds',
    icon: Money32,
    path: PAGES.fanSupport.root.path,
    permissions: 'view.fan_support'
  },
  {
    id: 'seasons',
    text: 'Seasons',
    icon: Trophy32,
    calculatePath: (currentUser, options = {}) => getRootSeasonPath({ currentUser, ...options })
  },
  {
    id: 'tournaments',
    text: 'Tournaments',
    icon: TournamentsLogo,
    calculatePath: currentUser => getRootTournamentPath(currentUser),
    permissions: 'view.tournaments'
  },
  {
    id: 'events',
    text: 'Events',
    icon: Event32,
    calculatePath: (currentUser, options = {}) => getRootEventPath({ currentUser, ...options })
  },
  {
    id: 'financial',
    text: 'Financial',
    icon: CrossTab32,
    path: PAGES.financial.root.path
  },
  {
    id: 'accounting_hub',
    text: 'Accounting',
    icon: Account32,
    path: PAGES.accountingHub.root.path,
    permissions: 'accounting.manage.all'
  }
];

export const SYSTEM_MENU_ITEMS = [
  {
    id: 'activities',
    text: 'Activities',
    path: PAGES.activities.root.path
  },
  {
    id: 'levels',
    text: 'Levels',
    path: PAGES.levels.root.path
  },
  {
    id: 'products',
    text: 'Products',
    path: PAGES.products.root.path
  },
  {
    id: 'rates',
    text: 'Rates',
    path: PAGES.rates.root.path
  },
  {
    id: 'users',
    text: 'Users',
    path: PAGES.users.root.path
  }
];

function TournamentsLogo() {
  return <img src={TournamentsSvg} alt='Tournaments' />;
}

export default { SIDE_MENU_ITEMS, SYSTEM_MENU_ITEMS };
