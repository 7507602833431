export interface EventSeasonSummaryBody {
  eventSeasonId: number;
  eventEndDate: string;
}

// eslint-disable-next-line no-shadow
export enum EventSeasonSummaryTypesEnum {
  EVENT = 'Event',
  SEASON = 'Season'
}
