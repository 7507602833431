import MeterChart, { MeterChartProps } from '@app/commons/generic-components/meter-chart/meter-chart';
import { useState, VFC } from 'react';
import { isEmpty } from '@utils/objectUtils';

interface TicketSaleBySchoolData
  extends Required<Pick<MeterChartProps, 'value' | 'peak' | 'label' | 'fill' | 'description' | 'suffix'>> {
  sub?: Record<string, Omit<TicketSaleBySchoolData, 'sub'>>;
}

export type TicketSaleBySchoolChartData = Record<string, TicketSaleBySchoolData>;

interface TicketSaleBySchoolChartProps {
  data: TicketSaleBySchoolChartData;
}

const TicketSaleBySchoolChart: VFC<TicketSaleBySchoolChartProps> = ({ data }) => {
  const [expandStatus, setExpandStatus] = useState<Record<string, boolean>>({});

  const handleOnExpand = (key: string) => (isExpanding: boolean) => {
    setExpandStatus(prev => ({
      ...prev,
      [key]: !isExpanding
    }));
  };

  return (
    <div>
      {!isEmpty(data) &&
        Object.entries(data).map(([key, { sub, ...chartData }]) => (
          <MeterChart
            key={key}
            animate
            expandable={!!sub}
            onExpand={handleOnExpand(key)}
            isExpanded={expandStatus[key]}
            className='gs--margin-top-sp2 meter-chart-school'
            {...chartData}
          >
            {sub &&
              Object.entries(sub).map(([subKey, subData]) => (
                <div className='gs--padding-left__sp7 sub-meter'>
                  <MeterChart key={subKey} animate {...subData} style={{ maxWidth: '86%' }} />
                </div>
              ))}
          </MeterChart>
        ))}
    </div>
  );
};

export default TicketSaleBySchoolChart;
