// @ts-expect-error: MultiSelect TypeScript typings not yet supported
import { MultiSelect as CarbonMultiSelect } from '@carbon/react';
import classNames from 'classnames';

import type { MultiSelectProps as CarbonMultiSelectProps } from '@carbon/react/lib/components/MultiSelect/MultiSelect';

import './MultiSelect.scss';

export type MultiSelectProps = CarbonMultiSelectProps<any>;

export const MultiSelect = (props: MultiSelectProps) => (
  <CarbonMultiSelect {...props} className={classNames('gf--multi-select', props.className)} />
);
