import React from 'react';

const FORGOT_SOMETHING =
  "It is safe to leave them out, but this message is here just in case you've forgotten something.";

export default ({ meta, ignoreMissing }) => (
  <div>
    {!ignoreMissing &&
      !meta.loading &&
      !meta.allInForm &&
      meta.missingFields.length > 0 &&
      import.meta.env.MODE !== 'production' && (
        <div
          style={{
            border: '3px solid red',
            padding: 10,
            backgroundColor: '#fbb'
          }}
        >
          <h3>Fast Form Errors</h3>
          <h5>(only visible in dev)</h5>
          <p>
            {`It looks like there are ${meta.missingFields.length} fields in the DTO that aren't present in the form.`}
          </p>
          <p>{FORGOT_SOMETHING}</p>
          <ul>
            {meta.missingFields.map(f => (
              <li key={f} style={{ color: 'black' }}>
                {`Key "${f}" is missing from the form. Consider adding a hidden or read-only field. This may lead to unexpected results otherwise.`}
              </li>
            ))}
          </ul>
        </div>
      )}
  </div>
);
