import { css } from 'styled-components';
import common from '../../../../../assets/styles/common';

export default {
  warningWrapper: css`
    margin-bottom: 16px;
  `,

  contentWrapper: css`
    max-width: 600px;
    margin: 0 auto;
  `,

  buttonWrapper: css`
    max-width: 328px;
    margin: 24px auto 0;
  `,

  paragraph: css`
    ${common.fieldValueSemiBold};
  `,

  createPasswordSuccessfully: css`
    ${common.fieldValueSemiBold};
    text-align: center;
    margin-bottom: 46px;
  `,

  title: css`
    font-weight: 600 !important;
    font-size: 32px !important;
  `,

  headerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    color: '#fff',
    padding: '10px 0',
    position: 'fixed',
    width: '100%',
    top: 0,
    right: 0,
    height: '75px',
    background: '#000',
    zIndex: 800
  },
  customTextLink: css`
    float: right;
    margin-top: 0.25rem;
    font-size: 14px;
  `,
  welcomeText: css`
    margin-bottom: 37px;
  `,
  header: css`
    font-weight: 600;
    text-align: center;
    font-size: 18px;
  `,
  content: css`
    text-align: center;
    font-size: 14px;
  `,
  listItem: css`
    font-weight: 600;
    padding-left: 10px !important;

    &:before {
      font-size: 24px;
    }
  `,
  wrapForm: css`
    display: flex;
    justify-content: center;
  `,
  footer: css`
    margin-top: 2rem;
    margin-bottom: 23rem;
    font-size: 14px;
  `,
  form: css`
    width: 328px;
    text-align: left;
  `
};
