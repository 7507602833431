import React from 'react';
import styled from 'styled-components';
import styles from './styles';

const Input = styled.input`
  ${styles.radio.input};
`;

const Round = styled.div`
  ${styles.radio.round};
`;

const Label = styled.label`
  ${styles.radio.label};
`;

const Radio = styled.div`
  ${styles.radio.radioWrapper};
`;

const Wrapper = styled.div`
  ${styles.radio.wrapper};
`;

export default ({
  label,
  checked,
  onChange,
  boldText,
  disabled,
  name = 'name',
  value = 'value'
}) => (
  <Wrapper className='radio-wrapper'>
    <Input
      type='radio'
      id={name}
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      aria-checked={checked}
      onChange={onChange}
    />
    <Radio onClick={() => !disabled && onChange(!checked)}>
      <Round disabled={disabled} checked={checked} />
      <Label disabled={disabled} boldText={boldText}>
        {label}
      </Label>
    </Radio>
  </Wrapper>
);
