import { Pagination } from 'carbon-components-react';

export type PageSizes = {
  key: any;
  value: string;
  text: string;
};

export type BasicPaginationProps = {
  page: number;
  pageSize: number;
  totalItems: number;
  pageSizes?: PageSizes[];
  itemsPerPageText?: string | undefined;
  onPaginationChange: ({ page, pageSize }: { page: number; pageSize: number }) => void;
};

const DEFAULT_PAGINATION_SIZES = [
  { key: 25, value: '25', text: '25' },
  { key: 50, value: '50', text: '50' },
  { key: 100, value: '100', text: '100' }
];

const BasicPagination = ({
  page = 1,
  pageSize = 0,
  totalItems = 0,
  pageSizes = DEFAULT_PAGINATION_SIZES,
  itemsPerPageText,
  onPaginationChange = () => null
}: BasicPaginationProps) => (
  <Pagination
    page={page}
    pageSize={pageSize}
    pageSizes={pageSizes}
    totalItems={totalItems}
    forwardText='Next page'
    backwardText='Previous page'
    pageNumberText='Page Number'
    itemsPerPageText={itemsPerPageText === undefined ? 'Items per page:' : itemsPerPageText}
    onChange={onPaginationChange}
    className='gs--body-short-01 gs--text-01'
  />
);

export default BasicPagination;
