import { GET_SEASON_RENEWALS_BY_SEASON_ID } from '../api/api';
import axios from '../api/axios';
import ErrorDTO from '../dto/ErrorDTO';
import SeasonRenewalDTO from '../dto/SeasonRenewalDTO';

const getSeasonRenewalsBySeasonId = seasonId => {
    const uri = GET_SEASON_RENEWALS_BY_SEASON_ID.replace('{seasonId}', seasonId);
  
    return axios.huddleAxiosInstanceProxy
        .get(uri)
        .then(response => {
            const error = ErrorDTO.convertToError(response);
            if (error !== null) {
                return error;
            };
            if (response.length > 0) {
                return new SeasonRenewalDTO(response);
            } else {
                return [];
            }
        })
        .catch(e => new ErrorDTO(e));
}

export default {
    getSeasonRenewalsBySeasonId
};