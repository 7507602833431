import { useEffect, useRef, useState } from 'react';

import { generateError, generateSuccessMessage } from '@app/utils/alertUtils';
import EventService from '@events/services/events.service';

export interface UseEventRestoreProps {
  addNotification: Function;
  onCloseModal: () => void;
  onReloadEvent?: () => void;
}

export const useEventRestore = ({ addNotification, onCloseModal, onReloadEvent }: UseEventRestoreProps) => {
  const [updating, setUpdating] = useState(false);
  const isUnmounted = useRef<boolean>(false);

  const restoreEvent = (eventId: string | number) => {
    if (updating) return true;

    setUpdating(true);
    EventService.restoreEvent(eventId)
      .then(() => {
        if (isUnmounted.current) return;
        onCloseModal();
        addNotification(generateSuccessMessage('Your event is restored'));
        if (typeof onReloadEvent === 'function') onReloadEvent();
      })
      .catch(error => {
        if (isUnmounted.current) return;
        addNotification(generateError(error));
      })
      .finally(() => {
        if (isUnmounted.current) return;
        setUpdating(false);
      });
  };

  useEffect(() => () => {
    isUnmounted.current = true;
  }, []);

  return {
    updating,
    restoreEvent
  };
};
