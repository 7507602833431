import { useEffect } from 'react';
import { ComposedModal, ModalHeader, ModalBody, ModalFooter, Button } from 'carbon-components-react';
import classNames from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';

import { EventDuplicationForm } from '@events/components/event-duplication-modal/components/event-duplication-form';

import { getEventType } from '@app/pages/EventDetail/helpers';
import { generateDateTimeValues } from '@app/commons/generic-components/date-time-form/utils';
import { initEventDuplicationValues, validateEventDuplication } from '@events/components/event-duplication-modal/utils';
import { generateDefaultEventName, generateOpponentName } from '@app/api/services/EventService';
import { EventService } from '@gofan/api';
import { FUNDRAISER, PRODUCT_TYPE } from '@gofan/constants';

import type { UserDTO, AccountDTO, EventDTO, EventProduct } from '@gofan/api';
import type { EventDuplicationValues } from '@events/components/event-duplication-modal/utils';

import strings from '@events/strings';

interface EventDuplicationModalProps {
  id?: string;
  open: boolean;
  event: EventDTO;
  data: {
    currentUser: UserDTO;
    account?: AccountDTO;
    opponentAccount?: AccountDTO;
  };
  onSubmit: (event: Partial<EventDTO>) => void;
  onClose: () => void;
  isConcession?: boolean;
}

export const EventDuplicationModal = ({
  id = '',
  open,
  event,
  data,
  onSubmit,
  onClose
}: EventDuplicationModalProps) => {
  const activityType = getEventType(event);
  const eventLabel = EventService.getEventLabelByType(event);
  const isFundraiser = activityType === FUNDRAISER;
  const dateTimeLabel = isFundraiser ? 'date' : 'date / time';
  const { currentUser } = data ?? {};
  const initFormValues = initEventDuplicationValues(event);
  const defaultValues: EventDuplicationValues = {
    ...initFormValues,
    allDayEvent: false,
    eventStartDateTime: '',
    startDate: '',
    startTime: ''
  };

  const methods = useForm({
    mode: 'onBlur',
    defaultValues,
    context: { activityType },
    resolver: yupResolver(validateEventDuplication)
  });

  useEffect(() => {
    if (open) {
      methods.reset(defaultValues);
    }
  }, [open]);

  return (
    <ComposedModal
      size='md'
      open={open}
      onClose={_onClose}
      className='event-duplication-modal'
      preventCloseOnClickOutside
    >
      <ModalHeader className='event-duplication-modal__header gs--ui-01'>
        <div className={classNames('gs--productive-heading-03', 'gs--text-01')}>
          {strings.EVENT_DUPLICATION.TITLE.replace(/{eventType}/g, eventLabel)}
        </div>
      </ModalHeader>
      <ModalBody hasForm className='event-duplication-modal__body'>
        <div className={classNames('gs--body-short-01', 'gs--text-01', 'gs--margin-bottom-sp5')}>
          {strings.EVENT_DUPLICATION.DESCRIPTION.replace(/{eventType}/g, eventLabel).replace(
            /{dateTimeLabel}/g,
            dateTimeLabel
          )}
          <span className='event-duplication-modal__body__bold'>{strings.EVENT_DUPLICATION.BTN_DUPLICATE}</span>.
        </div>
        <div className='gs--margin-top-sp5'>
          <FormProvider {...methods}>
            <EventDuplicationForm
              id={`${id}${event.id}`}
              activityType={activityType}
              eventLabel={eventLabel}
              data={{ currentUser, initFormValues }}
            />
          </FormProvider>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button kind='secondary' onClick={_onClose}>
          {strings.EVENT_DUPLICATION.BTN_CANCEL}
        </Button>
        <Button kind='primary' onClick={methods.handleSubmit(_onSubmit)}>
          {strings.EVENT_DUPLICATION.BTN_DUPLICATE}
        </Button>
      </ModalFooter>
    </ComposedModal>
  );

  function _onClose() {
    if (typeof onClose === 'function') onClose();
  }

  function _onSubmit() {
    if (typeof onSubmit === 'function') {
      const { account, opponentAccount } = data ?? {};

      const eventName =
        methods.getValues()?.name || generateDefaultEventName(account, generateOpponentName(opponentAccount, true));

      let duplicatedEvent: Partial<EventDTO> = {
        id: event?.id,
        accountId: event?.accountId,
        opponentAccountId: event?.opponentAccountId,
        financialAccountId: event?.financialAccountId,
        taggedAccountIds: event?.taggedAccountIds ?? [],
        name: eventName,
        ...generateDateTimeValues(event, methods.getValues())
      };

      const isConcession = event?.products?.some(product => product.productType === PRODUCT_TYPE.CONCESSION);
      if (isConcession) {
        duplicatedEvent = {
          ...duplicatedEvent,
          products:
            (event?.products?.map(product => omit(product, ['id', 'accountId', 'eventId'])) as EventProduct[]) ?? [],
          genders: event?.genders ?? [],
          levels: event?.levels ?? [],
          activityId: event?.activityId,
          opponentAccountId: event?.opponentAccountId,
          financialAccountId: event?.financialAccountId,
          taggedAccountIds: event?.taggedAccountIds ?? [],
          venueAddress: event?.venueAddress,
          venueCity: event?.venueCity,
          venueId: event?.venueId,
          venueName: event?.venueName,
          venueLocation: event?.venueLocation,
          venueState: event?.venueState,
          venueZip: event?.venueZip,
          glCode: event?.glCode
        };
      }

      onSubmit(duplicatedEvent);
    }
  }
};
