import { useEffect, useMemo, useState } from 'react';

import { useAccount } from '@gofan/api/accounts';
import { EventEditor } from '@events/views/EventEditor';
import { SponsorshipCreationForm } from '../../components/SponsorshipCreationForm/SponsorshipCreationForm';

import isEmpty from 'lodash/isEmpty';
import { Button, Column, FlexGrid, Row, Spinner } from '@gofan/components';
import type { CreateSponsorshipPageDTO } from '@gofan/api/sponsorship';
import { SponsorshipService } from '@gofan/api/sponsorship/sponsorship.service';
import { addNotification } from '@gofan/utils';
import type { RouteChildrenProps } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import './SponsorshipCreation.scss';
import { FEATURE_FLAGS, useFeatureFlags } from '@gofan/hooks';

const SponsorshipCreation = (props: RouteChildrenProps) => {
  const [sponsorSettings, setSponsorSettings] = useState([
    {
      title: 'Your Sponsorship Settings',
      editorSettings: [
        {
          id: '',
          label: '',
          render: () => <div />
        }
      ]
    }
  ]);

  const { [FEATURE_FLAGS.tempSponsorSharedS3Bucket]: tempSponsorSharedS3Bucket } = useFeatureFlags();
  const [setupProgressFields, setSetupProgressFields] = useState({});
  const [returnedSponsorId, setSponsorId] = useState<string>('');
  const [isPublishing, setIsPublishing] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const {
    sponsorshipName,
    sponsorshipType,
    emailAddress,
    phoneNumber,
    tagline,
    clickthroughLink,
    duration,
    sponsorshipAsset,
    schoolIds
  } = useMemo(() => setupProgressFields, [setupProgressFields]);

  const url = window.location.search;
  const urlParams = new URLSearchParams(url);

  const accountId = urlParams.get('accountId') || '';
  const { sponsorId: sponsorIdFromPath } = useParams<{ sponsorId: string }>();

  const { data: account } = useAccount({
    accountId: accountId ?? '',
    queryOptions: { enabled: !isEmpty(accountId) }
  });

  const publishButtonDisabled = !sponsorshipName || (!sponsorshipAsset && !isEdit);

  const sponsorProgresses = [
    {
      title: 'Sponsor Settings',
      settingLabel: 'Sponsor Settings',
      settingIndex: 0,
      progresses: [
        { label: 'Sponsorship Details', id: 'sponsorship_details' },
        { label: 'Sponsorship site info', id: 'sponsorship_siteInfo' },
        {
          label: 'Sponsorship assignment',
          id: 'sponsorship_assignment'
        }
      ]
    }
  ];

  useEffect(() => {
    setIsEdit(sponsorIdFromPath !== undefined && sponsorIdFromPath !== '');
  }, [sponsorIdFromPath]);

  useEffect(() => {
    const settings = [
      {
        title: 'Your Sponsorship Settings',
        editorSettings: [
          {
            id: '',
            label: '',
            render: () => <div />
          }
        ]
      }
    ];

    if (typeof sponsorshipName === 'string' && sponsorshipName !== '') {
      settings[0].editorSettings.push({
        id: 'sponsorshipName',
        label: 'Sponsorship Name',
        render: () => <div>{sponsorshipName}</div>
      });
    }

    if (typeof sponsorshipType === 'string' && sponsorshipType !== '') {
      settings[0].editorSettings.push({
        id: 'sponsorshipType',
        label: 'Sponsorship Type',
        render: () => <div>{sponsorshipType}</div>
      });
    }

    if (typeof emailAddress === 'string' && emailAddress !== '') {
      settings[0].editorSettings.push({
        id: 'emailAddress',
        label: 'Email Address',
        render: () => <div>{emailAddress}</div>
      });
    }

    if (typeof phoneNumber === 'string' && phoneNumber !== '') {
      settings[0].editorSettings.push({
        id: 'phoneNumber',
        label: 'Phone Number',
        render: () => <div>{phoneNumber}</div>
      });
    }

    if (typeof tagline === 'string' && tagline !== '') {
      settings[0].editorSettings.push({
        id: 'tagline',
        label: 'Tagline',
        render: () => <div>{tagline}</div>
      });
    }

    if (typeof clickthroughLink === 'string' && clickthroughLink !== '') {
      settings[0].editorSettings.push({
        id: 'clickthroughLink',
        label: 'Clickthrough Link',
        render: () => <div>{clickthroughLink}</div>
      });
    }

    settings[0].editorSettings.push({
      id: 'duration',
      label: 'Duration',
      render: () => <div>1 year: 2024-2025</div>
    });

    if (schoolIds && schoolIds.length > 0) {
      settings[0].editorSettings.push({
        id: 'schoolIds',
        label: 'Assigned Schools',
        render: () => (
          <div>
            {schoolIds.map(schoolId => (
              <div key={schoolId}>{schoolId}</div>
            ))}
          </div>
        )
      });
    }

    setSponsorSettings(settings);
  }, [sponsorshipName, sponsorshipType, emailAddress, phoneNumber, tagline, clickthroughLink, duration, schoolIds]);

  return (
    <div className='gf-sponsorship-creation'>
      <div className='gf-streaming-event-editor'>
        <EventEditor
          activityType='SPONSORSHIP'
          account={account}
          editMode={isEdit}
          eventProgresses={sponsorProgresses}
          eventSettings={sponsorSettings}
        >
          <SponsorshipCreationForm setSetupProgressFields={setSetupProgressFields} />
        </EventEditor>

        <FlexGrid>
          <Row className='gs--margin-top-sp5'>
            <Column className='gf-sponsor-editor-actions'>
              <Button
                kind='secondary'
                className='cds--btn-centralize cds--btn-radius'
                onClick={() => {
                  props.history.goBack();
                }}
              >
                Close
              </Button>
              <Button
                kind='primary'
                className='cds--btn-centralize cds--btn-radius btn-publish-site'
                onClick={() => {
                  onPublish();
                }}
                disabled={publishButtonDisabled} // disable the publish button if the sponsorship name is empty
              >
                {isEdit ? 'Update Sponsor' : 'Publish Sponsor'}
              </Button>
            </Column>
          </Row>
        </FlexGrid>
        {isPublishing && <Spinner withOverlay />}
      </div>
    </div>
  );

  async function onPublish() {
    try {
      setIsPublishing(true);

      const sponsorship: CreateSponsorshipPageDTO = {
        sponsor: {
          id: returnedSponsorId || sponsorIdFromPath, // this logic ensures that the sponsor is created only once
          name: sponsorshipName,
          type: 'PlayOn',
          contact: {
            name: '',
            email: emailAddress,
            phone: phoneNumber
          },
          tagline,
          clickThroughLink: clickthroughLink,
          logoUrl: '',
          asset: '',
          customSchoolId: ''
        },
        schoolIds: schoolIds.filter((id): id is string => id !== null), // Filter out null values
        imageFile: sponsorshipAsset
      };

      if (isEdit) {
        await SponsorshipService.updateSponsorship(sponsorship, tempSponsorSharedS3Bucket);
      } else {
        const sponsorId = await SponsorshipService.createSponsorship(sponsorship, tempSponsorSharedS3Bucket);
        setSponsorId(sponsorId);
      }

      addNotification({
        type: 'success',
        message: `Your sponsorship was ${isEdit ? 'updated' : 'published'} successfully.`,
        options: { duration: 7000 }
      });
      props.history.push(`/sponsorships/list`);
    } catch (error: any) {
      addNotification({
        type: 'error',
        message: error.message || `An error occurred while ${isEdit ? 'updating' : 'creating'} the sponsorship.`,
        options: { duration: 7000 }
      });
      console.error('Error:', error);
    } finally {
      setIsPublishing(false);
    }
  }
};

export default SponsorshipCreation;
