// @flow
import { isEmpty } from 'lodash';
import BaseDTO from './BaseDTO';
import EmbeddedDTO from './EmbeddedDTO';

class AccountTicketDTO extends BaseDTO {
  constructor(
    {
      accountId,
      _embedded,
      accountPaid,
      activityId,
      fanPrice,
      fee,
      id,
      levelIds,
      payToSchool,
      productTypeId,
      status,
      ticketName,
      ticketPrice,
      customColor,
      groupId,
      distributionChannel
    },
    ticketRawData
  ) {
    super({
      ...ticketRawData,
      accountId,
      embedded: _embedded,
      accountPaid,
      activityId,
      fanPrice,
      fee,
      id,
      levelIds,
      payToSchool,
      productTypeId,
      status,
      ticketName,
      ticketPrice,
      customColor,
      groupId,
      distributionChannel
    });
  }

  get id() {
    return this.raw.id;
  }

  get embedded() {
    return isEmpty(this.raw.embedded) ? {} : new EmbeddedDTO(this.raw.embedded);
  }

  get accountId() {
    return this.raw.accountId;
  }

  get accountPaid() {
    return this.raw.accountPaid;
  }

  get activityId() {
    return this.raw.activityId;
  }

  get fanPrice() {
    return this.raw.fanPrice;
  }

  get fee() {
    return this.raw.fee;
  }

  get levelIds() {
    return this.raw.levelIds;
  }

  get payToSchool() {
    return this.raw.payToSchool;
  }

  get productTypeId() {
    return this.raw.productTypeId;
  }

  get status() {
    return this.raw.status;
  }

  get ticketName() {
    return this.raw.ticketName;
  }

  get ticketPrice() {
    return this.raw.ticketPrice;
  }

  get customColor() {
    return this.raw.customColor;
  }

  get groupId() {
    return this.raw.groupId;
  }

  get distributionChannel() {
    return this.raw.distributionChannel;
  }

  get ticketRawData() {
    return this.raw.ticketRawData;
  }

  toJSON() {
    return {
      ...this.ticketRawData,
      accountId: this.accountId,
      embedded: this.embedded,
      accountPaid: this.accountPaid,
      activityId: this.activityId,
      fanPrice: this.fanPrice,
      fee: this.fee,
      id: this.id,
      levelIds: this.levelIds,
      payToSchool: this.payToSchool,
      productTypeId: this.productTypeId,
      status: this.status,
      ticketName: this.ticketName,
      ticketPrice: this.ticketPrice,
      customColor: this.customColor,
      groupId: this.groupId,
      distributionChannel: this.distributionChannel
    };
  }
}

export default AccountTicketDTO;
