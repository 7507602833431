import { Controller, useFormContext } from 'react-hook-form';
import { Dropdown, ImageUploader, TextInput, ToggleTip } from '@gofan/components';
import sponsorshipPlacementExample from '@assets/images/sponsorship-placement.png';
import './SponsorshipSiteInfo.scss';

const SponsorshipSiteInfo = ({ previousSponsorshipAsset }: { previousSponsorshipAsset: File | null }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  return (
    <div className='gf--sponsorship-site-info'>
      <Controller
        control={control}
        name='tagline'
        render={({ field }) => (
          <div className='gf--sponsorship-site-info-section'>
            <TextInput
              id='tagline'
              {...field}
              labelText='Tagline'
              helperText='This will display underneath sponsorship placement'
              placeholder='35 character limit'
              maxLength={35}
              value={field.value}
              disabled={false}
              rows={3}
              invalid={errors?.tagline}
              invalidText={errors?.tagline?.message}
            />
          </div>
        )}
      />
      <Controller
        control={control}
        name='clickthroughLink'
        render={({ field }) => (
          <div className='gf--sponsorship-site-info-section'>
            <TextInput
              id='clickthroughLink'
              {...field}
              labelText='Clickthrough link'
              helperText='Fans that click your sponsor will be re-directed to your site'
              placeholder='https://sponosrship.gofan'
              maxLength={250}
              value={field.value}
              disabled={false}
              rows={3}
              invalid={errors?.clickthroughLink}
              invalidText={errors?.clickthroughLink?.message}
            />
          </div>
        )}
      />
      <p className='gs--productive-heading-02 gs--font-family-sf gs--margin-top-sp5'>Duration</p>
      <p className='gs--body-short-02 gs--font-family-sf gs--margin-top-sp5'>
        Add a duration you would like this sponsorship to run
      </p>
      <Controller
        control={control}
        name='duration'
        render={() => <Dropdown id='duration' titleText='Duration' label='2024-2025' disabled items={['']} />}
      />
      <p className='gs--productive-heading-02 gs--font-family-sf gs--margin-top-sp5'>Sponsorship asset</p>
      <p className='gs--body-short-02 gs--font-family-sf gs--margin-top-sp5'>
        Upload your image for your sponsorship placement
      </p>
      <div className='gf--sponsorship-site-info__recs'>
        <p>Recommendations:</p>
        <ul>
          <li>Use a white background</li>
          <li>If possible, avoid any extra graphics that aren't critical to your logo</li>
          <li>Ensure your logo remains horizontal and centered</li>
          <li>Use at least 12px or 9pts font.</li>
        </ul>
      </div>
      <Controller
        control={control}
        name='sponsorshipAsset'
        render={({ field }) => (
          <div className='gf--sponsorship-site-info__image-uploader'>
            <ToggleTip
              align='top-left'
              message={<img src={sponsorshipPlacementExample} alt='sponsorshipExample' style={{ width: '250px' }} />}
            >
              <div className='gf--sponsorship-site-info__sponsorship-example'>Example here</div>
            </ToggleTip>
            <div className='gf--sponsorship-site-info__uploader-text'>Placement Preview</div>
            <ImageUploader
              {...field}
              labelTitle=''
              labelDescription=''
              buttonLabel='Upload image'
              warningText=''
              fileUploaderName='sponsorship-asset-image'
              accept={['.jpg', '.jpeg', '.png']}
              dragAndDropLabel='Drag and drop image'
              value={[field?.value || previousSponsorshipAsset || ({} as File)]}
              onChange={(files: File[]) => {
                field.onChange(files?.[0]);
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export { SponsorshipSiteInfo };
