import { SeasonManagementState, ActivedSport } from '@season-management/middleware/types';
import { CreatingSeason } from '@season-management/middleware/models/creating-season.model';

interface State {
  seasonManagement: SeasonManagementState;
}

export const seasonManagementSelector = (state: State): SeasonManagementState => state.seasonManagement;

export const creatingSeasonSelector = (state: State): CreatingSeason | null =>
  seasonManagementSelector(state).creatingSeason;

export const multiSportConfigSelector = (state: State): any | null => seasonManagementSelector(state).multiSportConfig;

export const selectedSportSelector = (state: State): any | null => seasonManagementSelector(state).selectedSports;

export const activedSportSelector = (state: State): ActivedSport | null => seasonManagementSelector(state).activedSport;

export const financialAccountIdSelector = (state: State): string | null =>
  seasonManagementSelector(state).financialAccountId;

export const financialAccountSelector = (state: State): any | null => seasonManagementSelector(state).financialAccount;
