import { TextInput } from 'carbon-components-react';
import React from 'react';
import styled from 'styled-components';
import styles from './styles';
import StyledLabel from '../StyledLabel';

const LabelContainer = styled.div`
  ${styles.labelContainer};
`;

export default ({
  isPasswordInput = false,
  isEmailInput = false,
  light = true,
  value = '',
  onChangeText,
  maxCharacter,
  hideLabel = false,
  labelText,
  hideCharacter = true,
  getInputRef = () => null,
  ...props
}) => {
  const inputedValueRef = React.useRef(value);
  React.useEffect(() => {
    if (hideCharacter || value?.length <= maxCharacter) {
      inputedValueRef.current = value;
    }
  }, [value]);
  return (
    <>
      {isPasswordInput ? (
        <TextInput.PasswordInput
          value={value}
          onChange={e => {
            onChangeText && onChangeText(e);
          }}
          light={light}
          labelText={labelText}
          {...props}
        />
      ) : (
        <>
          {!isEmailInput && ((!hideLabel && labelText) || !hideCharacter) && (
            <LabelContainer>
              {!hideLabel ? <StyledLabel text={labelText} size='tiny' /> : <p />}
              {!hideCharacter && <StyledLabel text={`${value.length}/${maxCharacter}`} size='tiny' />}
            </LabelContainer>
          )}

          <TextInput
            value={value}
            maxLength={maxCharacter}
            onChange={e => {
              onChangeText && onChangeText(e);
            }}
            light={light}
            labelText={isEmailInput ? labelText : ''}
            ref={getInputRef}
            {...props}
          />
        </>
      )}
    </>
  );
};
