import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { RateService } from './rate.service';
import { RATES_QUERY_KEY, RATE_QUERY_KEY } from './rate.endpoints';

import type { RateDTO } from './rate.model';

export interface UseRateOptions {
  rateId: number;
  expands?: string[];
  queryOptions?: {
    cacheTime?: number;
    staleTime?: number;
    enabled?: boolean;
  };
}

export const useRate = ({ rateId, expands = [], queryOptions = {} }: UseRateOptions) => {
  const queryClient = useQueryClient();

  const query = useQuery<RateDTO, Error>({
    queryKey: [RATE_QUERY_KEY, `${rateId}`],
    queryFn: () => RateService.getRateById(rateId, expands),
    cacheTime: 24 * 60 * 60 * 1000, // = 1 day
    staleTime: 24 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    ...queryOptions
  });

  const updateRate = useMutation({
    mutationFn: (rate: Partial<RateDTO>) => RateService.updateRateById(rate?.id || '', rate),
    onSuccess: updatedRate => {
      queryClient.invalidateQueries({ queryKey: [RATE_QUERY_KEY, `${updatedRate.id}`] });
      queryClient.invalidateQueries({ queryKey: [RATES_QUERY_KEY] });
    }
  });

  const createRate = useMutation({
    mutationFn: (rate: Partial<RateDTO>) => RateService.createRate(rate),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [RATES_QUERY_KEY] });
    }
  });

  return { ...query, updateRate, createRate };
};
