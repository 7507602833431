import * as yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import { formatCurrency } from '@gofan/utils';

import { TICKET_ACCOUNT } from '@gofan/constants';
import { ALPHANUMERIC_SPECIAL_CHARACTERS_REGEX } from '@app/commons/validations';

import { DISTRIBUTION_CHANNEL } from '@api/model/request/ProductRequest';

export const STRINGS = {
  ERROR_MESSAGES: {
    TICKET_NAME: {
      REQUIRED: 'Ticket name is required.',
      INVALID:
        'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (`()./_-#\'@&*+",:;?!) in between 2-40 characters long.'
    },
    TICKET_PRICE: {
      REQUIRED: 'Ticket price is required.',
      INVALID: 'Ticket price must be between {min} and {max}.'
    },
    LIMIT: {
      INVALID: 'Allotment must be between 1 and 9999999.',
      UNDER_TICKET_SALES: 'The entered value should be greater or equal to the number of tickets has been sold.'
    },
    ADMIN_TICKET_PRICE: {
      REQUIRED: 'Ticket price is required.',
      INVALID: 'Ticket price must be between 0.00 and 10000.00.'
    }
  }
};

export const getDefaultGroupValues = (group: any) => ({
  ...group,
  created: !!group?.created,
  deleted: !!group?.deleted,
  levelIds: group?.levelIds ?? [],
  ticketIds: group?.ticketIds ?? [],
  activityIds: group?.activityIds ?? [],
  groupDistributionChannel: group?.groupDistributionChannel ?? [],

  ticketName: group?.ticketName ?? '',
  customColor: group?.customColor ?? '',
  accountPaid: !!group?.accountPaid,
  fee: group?.fee ?? '',
  fanPrice: group?.fanPrice ?? '',
  payToSchool: group?.payToSchool ?? '',
  ticketPrice: group?.ticketPrice ?? '',

  generateLink: group?.generateLink ?? false,
  limit: group?.limit ?? undefined,

  reservedSeating: group?.reservedSeating ?? false,
  seatsIoCategory: group?.seatsIoCategory ?? undefined,

  boxOfficeTicket: {
    ...group?.boxOfficeTicket,
    ticketName: group?.boxOfficeTicket?.ticketName ?? '',
    fee: group?.boxOfficeTicket?.fee ?? '',
    fanPrice: group?.boxOfficeTicket?.fanPrice ?? '',
    ticketPrice: group?.boxOfficeTicket?.ticketPrice ?? ''
  }
});

export const ticketNameSchemaBuilder = () =>
  yup
    .string()
    .required(STRINGS.ERROR_MESSAGES.TICKET_NAME.REQUIRED)
    .min(2, STRINGS.ERROR_MESSAGES.TICKET_NAME.INVALID)
    .max(40, STRINGS.ERROR_MESSAGES.TICKET_NAME.INVALID)
    .matches(ALPHANUMERIC_SPECIAL_CHARACTERS_REGEX, {
      message: STRINGS.ERROR_MESSAGES.TICKET_NAME.INVALID,
      excludeEmptyString: true
    });

export const ticketPriceSchemaBuilder = () =>
  yup
    .string()
    .required(STRINGS.ERROR_MESSAGES.TICKET_PRICE.REQUIRED)
    .test('invalid', '', (ticketPrice, testContext) => {
      const { isAdminRole, isInternalUser } = testContext.options?.context ?? {};
      let maxTicketValue = TICKET_ACCOUNT.MAX_PRICE_ACCOUNT_TICKET;
      if (isAdminRole) {
        maxTicketValue = TICKET_ACCOUNT.MAX_PRICE_SUPER_ADMIN;
      } else if (isInternalUser) {
        maxTicketValue = TICKET_ACCOUNT.MAX_PRICE_INTERNAL_ADMIN;
      }
      const errorMessage = STRINGS.ERROR_MESSAGES.TICKET_PRICE.INVALID.replace(
        '{min}',
        formatCurrency(TICKET_ACCOUNT.MIN_PRICE_ACCOUNT_TICKET)
      ).replace('{max}', formatCurrency(maxTicketValue));
      const ticketPriceValue = parseFloat(ticketPrice ?? '0');
      if (ticketPriceValue < TICKET_ACCOUNT.MIN_PRICE_ACCOUNT_TICKET || ticketPriceValue > maxTicketValue) {
        return testContext.createError({
          path: testContext.path,
          message: errorMessage
        });
      }

      return true;
    });

export const limitSchemaBuilder = () =>
  yup
    .string()
    .test('invalid', '', (limit, testContext) => {
      const { config } = testContext?.options?.context ?? {};
      const { unlimitedTicket = true } = config ?? {};
      if (unlimitedTicket) return true;
      if (isEmpty(limit)) return true;

      const limitValue = parseInt(limit ?? '0');
      if (limitValue < TICKET_ACCOUNT.MIN_EVENT_LIMIT || limitValue > TICKET_ACCOUNT.MAX_EVENT_LIMIT) {
        return testContext.createError({
          path: testContext.path,
          message: STRINGS.ERROR_MESSAGES.LIMIT.INVALID
        });
      }
      return true;
    })
    .test('underTicketSales', '', (limit, testContext) => {
      const { id } = testContext?.parent ?? {};
      const { config } = testContext?.options?.context ?? {};
      const { unlimitedTicket = true, isEndEvent, getProductSalesInfo } = config ?? {};
      if (unlimitedTicket) return true;
      if (isEmpty(limit)) return true;

      if (typeof getProductSalesInfo === 'function') {
        const limitValue = parseInt(limit ?? '0');
        const salesInfo = getProductSalesInfo(id);
        if (salesInfo) {
          if (isEndEvent) return true;
          if (salesInfo.saleQuantity > 0 && limitValue < salesInfo.saleQuantity) {
            return testContext.createError({
              path: testContext.path,
              message: STRINGS.ERROR_MESSAGES.LIMIT.UNDER_TICKET_SALES
            });
          }
        }
      }
      return true;
    });

export const boxOfficeTicketSchemaBuilder = () =>
  yup.object().when('groupDistributionChannel', {
    is: (value: string[]) => value?.includes(DISTRIBUTION_CHANNEL.BOXOFFICE),
    then: schema =>
      schema.shape({
        ticketName: ticketNameSchemaBuilder(),
        ticketPrice: ticketPriceSchemaBuilder()
      })
  });

export const AddTicketTypeSchema = yup.object().shape({
  ticketName: ticketNameSchemaBuilder(),
  ticketPrice: ticketPriceSchemaBuilder(),
  limit: limitSchemaBuilder(),
  boxOfficeTicket: boxOfficeTicketSchemaBuilder()
});
