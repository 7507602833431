import { useState, useEffect } from 'react';

import { AccountService } from '@gofan/api/accounts';
import { VenueService } from '@gofan/api/venues';
import isEmpty from 'lodash/isEmpty';

import type { AccountVenue } from '@gofan/api/accounts';
import { generateError, generateSuccessMessage } from '@app/utils/alertUtils';
import type { VenueInformationValue } from '@app/modules/season-management/pages/season-general-setup/season-venue-section/season-venue-section.component';
import type { VenueTypes } from '@gofan/components';

export interface UseVenuesParams {
  accountId: string;
  setNotification?: Function;
  shouldFetchVenues?: boolean;
  defaultDistrictVenues?: AccountVenue[];
  eventVenueId?: string;
}

export const useVenues = ({
  accountId,
  setNotification,
  shouldFetchVenues = true,
  defaultDistrictVenues = [],
  eventVenueId
}: UseVenuesParams) => {
  const [accountVenues, setAccountVenues] = useState<AccountVenue[]>([]);
  const [districtVenues, setDistrictVenues] = useState<AccountVenue[]>(defaultDistrictVenues);
  const [venues, setVenues] = useState<AccountVenue[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [isCreatingVenue, setIsCreatingVenue] = useState(false);
  const [venueTypes, setVenueTypes] = useState<VenueTypes>({});

  const _handleSetNotification = (params: any) => {
    if (typeof setNotification === 'function') setNotification(params);
  };

  const _parseUpdatedVenueValue = ({
    updatedVenue,
    prevVenues,
    id
  }: {
    updatedVenue: AccountVenue;
    prevVenues: AccountVenue[];
    id?: string;
  }) =>
    prevVenues
      .map(v => (`${v.id}` === `${id}` ? updatedVenue : v))
      .sort((a, b) => `${a.name}`.localeCompare(`${b.name}`));

  const updateOrCreateVenue = (values: VenueInformationValue, id?: string) => {
    const newVenue: AccountVenue = {
      accountId: !isEmpty(values.accountId) ? values.accountId : accountId,
      name: values.name,
      city: values.city,
      state: values.state,
      zip: values.zipCode,
      location: values.location || '',
      streetAddress: values.address || '',
      seatsIoChartKey: values.seatsIoChartKey || ''
    };
    setIsCreatingVenue(true);
    return (id ? VenueService.updateVenue({ ...newVenue, id }) : VenueService.createVenue(newVenue))
      .then(res => {
        setDistrictVenues(prevVenues =>
          _parseUpdatedVenueValue({
            updatedVenue: res,
            prevVenues,
            id
          })
        );
        setAccountVenues(prevVenues =>
          _parseUpdatedVenueValue({
            updatedVenue: res,
            prevVenues,
            id
          })
        );
        _handleSetNotification(generateSuccessMessage(`${newVenue?.name} was successfully updated`));
        setIsCreatingVenue(false);
        return res;
      })
      .catch(err => {
        _handleSetNotification(generateError(err));
        setIsCreatingVenue(false);
      })
      .finally(() => {
        setIsCreatingVenue(false);
      });
  };

  useEffect(() => {
    if (isEmpty(venueTypes)) {
      if (eventVenueId && defaultDistrictVenues.some(v => v?.id === eventVenueId)) {
        setVenueTypes({ district: true, homeSchool: false });
      } else {
        setVenueTypes({ district: false, homeSchool: true });
      }
    }
  }, [eventVenueId, defaultDistrictVenues]);

  useEffect(() => {
    setVenues(VenueService.getFilteredVenues({ accountVenues, districtVenues, venueTypes }));
  }, [venueTypes, districtVenues, accountVenues]);

  useEffect(() => {
    if (shouldFetchVenues && !isEmpty(accountId)) {
      _fetchVenues();
    }
  }, []);

  async function _fetchVenues() {
    setLoading(true);

    try {
      const data = await AccountService.getAccountById(accountId);
      if (data?.venues && data?.venues?.length > 0) {
        const sortedVenues = data?.venues?.toSorted((a, b) => `${a.name}`.localeCompare(`${b.name}`));
        setAccountVenues(sortedVenues);
      }
    } catch (error) {
      setError('Account venues were not found');
    } finally {
      setLoading(false);
    }
  }

  return {
    venues,
    updateOrCreateVenue,
    loadingVenues: loading,
    venuesError: error,
    isCreatingVenue,
    venueTypes,
    setVenueTypes
  };
};
