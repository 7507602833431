import { put, all, call, fork, cancelled, takeEvery } from 'redux-saga/effects';
import type { SagaIterator } from 'redux-saga';
import moment from 'moment';
import { omit, uniq, uniqBy, isEmpty } from 'lodash';

import { EVENT_DATE_FORMAT_WITH_TIMEZONE } from '@utils/dateUtils';
import { generateError, generateFailContent, generateSuccessMessage } from '@utils/alertUtils';
import { UNEXPECTED_ERROR } from '@api/api/constants';
import { addNotification } from '@app/pages/Root/actions';
import { DISTRIBUTION_CHANNEL } from '@app/api/model/request/ProductRequest';
import { mappingAccountVenue } from '@app/pages/EventInformationV2/components/RequiredLayout/components/VenueSection/VenueSection';

import strings from '@modules/event-integrations_V2/constants/strings';
import * as actions from '@modules/event-integrations_V2/middleware/actions';
import * as actionTypes from '@modules/event-integrations_V2/middleware/actionTypes';
import { takeMultitaskingChannel } from '@modules/event-integrations_V2/middleware/saga-helper';

import {
  CREATION_MODE,
  CREATION_PROCESS,
  PARTNER_IMPORT_MODE,
  EVENT_START_TIME_TYPE
} from '@season-management/constants/constant';
import {
  PUBLISH_TYPE,
  NORMAL_STATUS,
  IGNORED_STATUS,
  DELETED_STATUS,
  WAITING_STATUS,
  CANCELED_STATUS,
  PUBLISHED_STATUS,
  DUPLICATED_STATUS,
  INVALID_ACCOUNT_IDS
} from '@modules/event-integrations_V2/constants/constants';
import { RESOLVE_DUPLICATED_TYPES } from '@app/pages/SeasonSetup/constants';

import type { ActivityDTO } from '@gofan/api/activities';
import type { LevelDTO } from '@gofan/api/levels';
import type { EventDTO } from '@events/models/event.model';
import type { AccountDTO } from '@gofan/api/accounts';
import type { GlobalEventDTO, SeasonDTO } from '@seasons/models/season.model';
import type { RawEvent } from '@season-management/middleware/models/raw-event.model';
import type { RawSeason } from '@season-management/middleware/models/raw-season.model';
import MultiSeasonCreateRequest from '@season-management/middleware/models/season-create-request.model';
import type { CreatingSeason } from '@season-management/middleware/models/creating-season.model';
import CreatingSeasonModel from '@season-management/middleware/models/creating-season.model';
import { PartnerEventRequest } from '@modules/event-integrations_V2/models/partner-event-create-request.model';
import type { PublishPartnerEvent, ScheduleData } from '@modules/event-integrations_V2/hooks';

import EventService from '@events/services/events.service';
import { EventService as GoFanEventService } from '@gofan/api/events/event.service';
import SeasonService from '@seasons/services/season.service';
import SeasonCreationUtil from '@season-management/utils/season-creation.utils';
import SeasonCreationService from '@season-management/services/season-creation.service';
import EventScheduleService from '@modules/event-integrations_V2/services/event-schedule.service';
import { getScheduleAccountsByHomeSchool } from '../utils';

const commonError = generateFailContent(UNEXPECTED_ERROR);

export function getChangedGlobalEvent({ resolvedSchedule, venue }: any) {
  const newEvents: GlobalEventDTO[] = [];
  const updatedEvents: GlobalEventDTO[] = [];
  const ignoredEvents: GlobalEventDTO[] = [];
  const deletedEvents: GlobalEventDTO[] = [];
  const cancelledEvents: GlobalEventDTO[] = [];

  const defaultVenue = isEmpty(venue)
    ? {}
    : {
        venueAddress: venue?.address,
        venueCity: venue?.city,
        venueLocationName: venue?.location,
        venueName: venue?.name,
        venueState: venue?.state,
        venueZip: venue?.zipCode
      };

  (resolvedSchedule?.schedule?.events ?? [])
    .filter((event: any) => {
      const gofanEventStatusMsg = `${event?.gofanStatusMsg ?? ''}`.toLowerCase();
      return !gofanEventStatusMsg || (!!event?.gofanEventId && !!gofanEventStatusMsg && !isEmpty(event?.changes));
    })
    .forEach((event: any) => {
      const isIgnored = `${event?.gofanStatusMsg ?? ''}`.toLowerCase() === IGNORED_STATUS.toLowerCase();

      const newEvent = { ...event, ...defaultVenue };

      if (event.changedType === PUBLISH_TYPE.DELETE) {
        deletedEvents.push(newEvent);
      } else if (event.changedType === PUBLISH_TYPE.CANCEL) {
        cancelledEvents.push(newEvent);
      } else if (
        event.changedType === PUBLISH_TYPE.UPDATE ||
        event.changedType === PUBLISH_TYPE.AFTER_END_DATE_TIME ||
        event.changedType === PUBLISH_TYPE.HAS_SOLD_WITH_UPDATED_END_DATE_TIME
      ) {
        updatedEvents.push(newEvent);
      } else if (isIgnored) {
        ignoredEvents.push(newEvent);
      } else if (!event?.gofanStatusMsg || event.changedType === PUBLISH_TYPE.CREATE) {
        newEvents.push(newEvent);
      }
    });

  const publishedEvents: GlobalEventDTO[] = (resolvedSchedule?.schedule?.events ?? []).filter((event: any) => {
    const gofanEventStatusMsg = `${event?.gofanStatusMsg ?? ''}`.toLowerCase();
    return !!event?.gofanEventId && gofanEventStatusMsg === PUBLISHED_STATUS.toLowerCase() && isEmpty(event?.changes);
  });

  return {
    newEvents,
    updatedEvents,
    deletedEvents,
    cancelledEvents,
    publishedEvents
  };
}

export function* onCreatePartnerEvent(action: any): SagaIterator {
  const {
    eventId,
    scheduleId,
    partnerEvent,
    scheduleData,
    isInternalUser,
    resolvedSchedule,
    isShowNotification,
    needRefreshGlobalEvent
  } = action?.payload ?? {};
  let result = {};

  try {
    if (isEmpty(partnerEvent)) return;

    const currentDateTime = moment().format(EVENT_DATE_FORMAT_WITH_TIMEZONE);

    const createRequest = new PartnerEventRequest({
      event: partnerEvent,
      schedule: resolvedSchedule
    })
      .populate({ scheduleData, isInternalUser })
      .toJSON();

    const goFanResponse = yield call(EventService.createEvent, createRequest);

    if (isEmpty(goFanResponse)) {
      yield put(addNotification(commonError));
      yield put(actions.onSetEventError({ errorEventIds: [eventId] }));
      return;
    }

    const response = yield call(EventScheduleService.updateGlobalEvent, {
      id: partnerEvent?.id,
      gofanEventId: goFanResponse?.id,
      gofanSeasonId: goFanResponse?.seasonId,
      gofanStatusMsg: PUBLISHED_STATUS,
      gofanLastPublished: currentDateTime,
      gofanStatusUpdatedDt: currentDateTime
    });

    if (isEmpty(response)) {
      yield put(addNotification(commonError));
      yield put(actions.onSetEventError({ errorEventIds: [eventId] }));
      return;
    }

    result = {
      type: PUBLISH_TYPE.CREATE,
      eventId,
      scheduleId,
      newGoFanEvents: [goFanResponse],
      newGlobalEvents: [response]
    };

    if (needRefreshGlobalEvent) {
      yield put(actions.onRefreshPartnerEvent(result));
    }

    if (isShowNotification) {
      yield put(addNotification(generateSuccessMessage(strings.SUCCESSFULLY_SEASON_PUBLISHED)));
    }
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
    yield put(actions.onSetEventError({ errorEventIds: [eventId] }));
  }

  return result;
}

export function* onUpdatePartnerEvent(action: any): SagaIterator {
  const {
    eventId,
    scheduleId,
    scheduleData,
    partnerEvent,
    isShowNotification,
    needUpdateForGoFan,
    needRefreshGlobalEvent
  } = action?.payload ?? {};
  let result = {};

  try {
    if (isEmpty(partnerEvent)) return;

    const { endDateTime, opponentAccountId } = partnerEvent?.changedData ?? {};
    const endDateTimeChange = partnerEvent?.changes?.find(({ field }: any) => field === 'endDateTime');
    const awaySchoolChange = partnerEvent?.changes?.find(({ field }: any) => field === 'opponentAccountId');

    let goFanResponse = {};
    let changeOpponent = {};

    if (
      !!opponentAccountId &&
      !isEmpty(awaySchoolChange) &&
      (INVALID_ACCOUNT_IDS.includes(`${opponentAccountId}`.toLowerCase()) ||
        isEmpty((scheduleData?.awayAccounts ?? []).find((acc: AccountDTO) => `${acc.id}` === `${opponentAccountId}`)))
    ) {
      changeOpponent = { opponentAccountId: '' };
    }

    if (needUpdateForGoFan && !!partnerEvent?.gofanEventId) {
      goFanResponse = yield call(EventService.updatePartialEvent, {
        id: partnerEvent?.gofanEventId,
        ...partnerEvent?.changedData,
        ...changeOpponent
      });

      if (isEmpty(goFanResponse)) {
        yield put(addNotification(commonError));
        yield put(actions.onSetEventError({ errorEventIds: [eventId] }));
        return;
      }
    }

    const response = yield call(EventScheduleService.updateGlobalEvent, {
      id: partnerEvent?.id,
      gofanStatusMsg: PUBLISHED_STATUS,
      sourceHasPendingRequest: false,
      endEventDt: !endDateTimeChange && !!endDateTime ? endDateTime : undefined
    });

    if (isEmpty(response)) {
      yield put(addNotification(commonError));
      yield put(actions.onSetEventError({ errorEventIds: [eventId] }));
      return;
    }

    let newGoFan = {};
    if (!isEmpty(goFanResponse)) {
      try {
        const eventResponse = yield call(EventService.getEventById, partnerEvent?.gofanEventId, ['event-sales-info']);
        newGoFan = { ...eventResponse };
      } catch (err) {
        newGoFan = { ...goFanResponse };
      }
    }

    result = {
      type: PUBLISH_TYPE.UPDATE,
      eventId,
      scheduleId,
      newGoFanEvents: isEmpty(newGoFan) ? [] : [newGoFan],
      newGlobalEvents: [response]
    };

    if (needRefreshGlobalEvent) {
      yield put(actions.onRefreshPartnerEvent(result));
    }

    if (isShowNotification) {
      yield put(addNotification(generateSuccessMessage(strings.SUCCESSFULLY_SEASON_UPDATED)));
    }

    yield put(
      actions.onSetPrevPartnerEvent({
        prevGlobalEvents: [partnerEvent]
      })
    );
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
    yield put(actions.onSetEventError({ errorEventIds: [eventId] }));
  }

  return result;
}

export function* onCancelPartnerEvent(action: any): SagaIterator {
  const { eventId, scheduleId, partnerEvent, isShowNotification, needUpdateForGoFan, needRefreshGlobalEvent } =
    action?.payload ?? {};
  let result = {};

  try {
    if (isEmpty(partnerEvent)) return;

    const response = yield call(EventScheduleService.updateGlobalEvent, {
      id: partnerEvent?.id,
      gofanStatusMsg: CANCELED_STATUS
    });

    if (isEmpty(response)) {
      yield put(addNotification(commonError));
      yield put(actions.onSetEventError({ errorEventIds: [eventId] }));
      return;
    }

    let goFanResponse = {};

    if (needUpdateForGoFan && !!partnerEvent?.gofanEventId) {
      goFanResponse = yield call(EventService.cancelEvent, {
        eventId: partnerEvent?.gofanEventId as string,
        sendFansNotification: true
      });

      if (isEmpty(goFanResponse)) {
        yield put(addNotification(commonError));
        yield put(actions.onSetEventError({ errorEventIds: [eventId] }));
        return;
      }
    }

    result = {
      type: PUBLISH_TYPE.CANCEL,
      eventId,
      scheduleId,
      newGoFanEvents: [goFanResponse],
      newGlobalEvents: [response]
    };

    if (needRefreshGlobalEvent) {
      yield put(actions.onRefreshPartnerEvent(result));
    }

    if (isShowNotification) {
      yield put(addNotification(generateSuccessMessage(strings.SUCCESSFULLY_SEASON_UPDATED)));
    }

    yield put(
      actions.onSetPrevPartnerEvent({
        prevGlobalEvents: [partnerEvent]
      })
    );
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
    yield put(actions.onSetEventError({ errorEventIds: [eventId] }));
  }

  return result;
}

export function* onDeletePartnerEvent(action: any): SagaIterator {
  const { eventId, scheduleId, partnerEvent, isShowNotification, needUpdateForGoFan, needRefreshGlobalEvent } =
    action?.payload ?? {};
  let result = {};

  try {
    if (isEmpty(partnerEvent)) return;

    const response = yield call(EventScheduleService.updateGlobalEvent, {
      id: partnerEvent?.id,
      gofanStatusMsg: DELETED_STATUS
    });

    if (isEmpty(response)) {
      yield put(addNotification(commonError));
      yield put(actions.onSetEventError({ errorEventIds: [eventId] }));
      return;
    }

    let goFanResponse = {};

    if (needUpdateForGoFan && !!partnerEvent?.gofanEventId) {
      goFanResponse = yield call(EventService.deleteEventById, partnerEvent?.gofanEventId as string);

      if (isEmpty(goFanResponse)) {
        yield put(addNotification(commonError));
        yield put(actions.onSetEventError({ errorEventIds: [eventId] }));
        return;
      }
    }

    result = {
      type: PUBLISH_TYPE.DELETE,
      eventId,
      scheduleId,
      newGoFanEvents: [goFanResponse],
      newGlobalEvents: [response]
    };

    if (needRefreshGlobalEvent) {
      yield put(actions.onRefreshPartnerEvent(result));
    }

    if (isShowNotification) {
      yield put(addNotification(generateSuccessMessage(strings.SUCCESSFULLY_SEASON_UPDATED)));
    }

    yield put(
      actions.onSetPrevPartnerEvent({
        prevGlobalEvents: [partnerEvent]
      })
    );
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
    yield put(actions.onSetEventError({ errorEventIds: [eventId] }));
  }

  return result;
}

export function* onUpdatePartnerEvents(action: any): SagaIterator {
  const {
    scheduleData,
    isInternalUser,
    resolvedSchedule,
    newEvents,
    updatedEvents,
    deletedEvents,
    cancelledEvents,
    excludedEvents,
    isShowNotification,
    isShowTotalNotification,
    needRefreshGlobalEvent
  } = action?.payload ?? {};
  let results = [];

  try {
    const requests: any[] = [];
    const eventIds: any[] = [];

    newEvents?.forEach((event: GlobalEventDTO) => {
      eventIds.push(event?.id);
      requests.push(
        call(
          onCreatePartnerEvent,
          actions.onCreatePartnerEvent({
            scheduleData,
            isInternalUser,
            partnerEvent: event,
            eventId: event?.id,
            resolvedSchedule,
            scheduleId: resolvedSchedule?.schedule?.id,
            isShowNotification,
            needRefreshGlobalEvent,
            needUpdateForGoFan: !excludedEvents?.find(({ id }: any) => `${event?.id}` === `${id}`)
          })
        )
      );
    });

    updatedEvents?.forEach((event: GlobalEventDTO) => {
      eventIds.push(event?.id);
      requests.push(
        call(
          onUpdatePartnerEvent,
          actions.onUpdatePartnerEvent({
            scheduleData,
            partnerEvent: event,
            eventId: event?.id,
            resolvedSchedule,
            scheduleId: resolvedSchedule?.schedule?.id,
            isShowNotification,
            needRefreshGlobalEvent,
            needUpdateForGoFan: !excludedEvents?.find(({ id }: any) => `${event?.id}` === `${id}`)
          })
        )
      );
    });

    cancelledEvents?.forEach((event: GlobalEventDTO) => {
      eventIds.push(event?.id);
      requests.push(
        call(
          onCancelPartnerEvent,
          actions.onCancelPartnerEvent({
            partnerEvent: event,
            eventId: event?.id,
            resolvedSchedule,
            scheduleId: resolvedSchedule?.schedule?.id,
            isShowNotification,
            needRefreshGlobalEvent,
            needUpdateForGoFan: !excludedEvents?.find(({ id }: any) => `${event?.id}` === `${id}`)
          })
        )
      );
    });

    deletedEvents?.forEach((event: GlobalEventDTO) => {
      eventIds.push(event?.id);
      requests.push(
        call(
          onDeletePartnerEvent,
          actions.onDeletePartnerEvent({
            partnerEvent: event,
            eventId: event?.id,
            resolvedSchedule,
            scheduleId: resolvedSchedule?.schedule?.id,
            isShowNotification,
            needRefreshGlobalEvent,
            needUpdateForGoFan: !excludedEvents?.find(({ id }: any) => `${event?.id}` === `${id}`)
          })
        )
      );
    });

    yield put(actions.onSetEventError({ errorEventIds: eventIds, isClear: true }));

    results = yield all(requests);

    if (isShowTotalNotification) {
      let numOfEventCreated = 0;
      let numOfEventUpdated = 0;
      let message = strings.SUCCESSFULLY_SEASON_PUBLISHED_ALL;

      results?.forEach((item: any) => {
        if (item?.type === PUBLISH_TYPE.CREATE) {
          numOfEventCreated += 1;
        } else if (
          item?.type === PUBLISH_TYPE.UPDATE ||
          item?.type === PUBLISH_TYPE.CANCEL ||
          item?.type === PUBLISH_TYPE.DELETE
        ) {
          numOfEventUpdated += 1;
        }
      });

      if (numOfEventCreated === 0 && numOfEventUpdated === 0) {
        return results;
      }

      if (numOfEventCreated > 0 && numOfEventUpdated > 0) {
        message = `${numOfEventCreated} new and ${numOfEventUpdated} updates were successfully published.`;
      } else if (numOfEventCreated > 0) {
        message = `${numOfEventCreated} new were successfully published.`;
      } else if (numOfEventUpdated > 0) {
        message = `${numOfEventUpdated} updates were successfully published.`;
      }

      yield put(addNotification(generateSuccessMessage(message)));
    }
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
  }

  return results;
}

/// /////////////////////////////////////////////////////////////////////////////

export function* onUpdatePartnerEventStatus(action: any): SagaIterator {
  const { season, ignoredPartnerEvents, cancelledPartnerEvents, publishedPartnerEvents } = action?.payload ?? {};
  let results = {};

  try {
    const newSeasonId = season?.id ?? '';

    if (!newSeasonId) return results;

    const currentDateTime = moment().format(EVENT_DATE_FORMAT_WITH_TIMEZONE);
    const ignoredEventsInfo: GlobalEventDTO[] = [];
    const cancelledEventsInfo: GlobalEventDTO[] = [];
    const publishedEventsInfo: GlobalEventDTO[] = [];
    const duplicatedEventsInfo: GlobalEventDTO[] = [];
    let eventsBySeason: any[] = [];

    (season?.eventIds ?? []).forEach((eventId: any) => {
      if (eventId) {
        eventsBySeason.push({ eventId, seasonId: newSeasonId });
      }
    });

    eventsBySeason = uniqBy(eventsBySeason, (item: any) => `${item.seasonId}-${item.eventId}`);

    if (!isEmpty(eventsBySeason) && !isEmpty(publishedPartnerEvents)) {
      const events: EventDTO[] = yield call(
        EventService.searchByIds,
        eventsBySeason?.map(({ eventId }) => eventId)
      );

      (eventsBySeason ?? []).some(item => {
        const foundEvent = events.find((event: EventDTO) => `${event.id}` === `${item.eventId}`);

        if (isEmpty(foundEvent)) return false;

        const updateParams = {
          gofanEventId: `${item?.eventId}`,
          gofanSeasonId: `${newSeasonId}`,
          endEventDt: foundEvent?.endDateTime,
          startEventDt: foundEvent?.startDateTime,
          gofanLastPublished: currentDateTime,
          gofanStatusUpdatedDt: currentDateTime
        };

        const partnerEvent = (publishedPartnerEvents ?? [])?.find(
          (event: GlobalEventDTO) => `${event.id}` === `${foundEvent?.globalEventsId ?? ''}`
        );

        if (isEmpty(partnerEvent)) return false;

        if (partnerEvent?.resolveType === RESOLVE_DUPLICATED_TYPES.ONLY_KEPT_GF_EVENT) {
          duplicatedEventsInfo.push({
            ...updateParams,
            id: partnerEvent?.id,
            gofanStatusMsg: partnerEvent?.changedGoFanStatusMsg
              ? partnerEvent?.changedGoFanStatusMsg
              : DUPLICATED_STATUS
          });
        } else {
          publishedEventsInfo.push({
            ...updateParams,
            id: partnerEvent?.id,
            gofanStatusMsg: partnerEvent?.changedGoFanStatusMsg ? partnerEvent?.changedGoFanStatusMsg : PUBLISHED_STATUS
          });
        }

        return false;
      });
    }

    ignoredPartnerEvents?.forEach((ignoredEvent: GlobalEventDTO) => {
      ignoredEventsInfo.push({
        id: ignoredEvent?.id,
        gofanSeasonId: `${newSeasonId}`
      });
    });

    cancelledPartnerEvents?.forEach((cancelledEvent: GlobalEventDTO) => {
      cancelledEventsInfo.push({
        id: cancelledEvent?.id,
        gofanSeasonId: `${newSeasonId}`
      });
    });

    const requests: any[] = [];

    if (!isEmpty(ignoredEventsInfo)) {
      requests.push(call(EventScheduleService.updateGlobalEvents, ignoredEventsInfo));
    }

    if (!isEmpty(cancelledEventsInfo)) {
      requests.push(call(EventScheduleService.updateGlobalEvents, cancelledEventsInfo));
    }

    if (!isEmpty(publishedEventsInfo)) {
      requests.push(call(EventScheduleService.updateGlobalEvents, publishedEventsInfo));
    }

    if (!isEmpty(duplicatedEventsInfo)) {
      requests.push(call(EventScheduleService.updateGlobalEvents, duplicatedEventsInfo));
    }

    results = yield all(requests);
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
  }

  return results;
}

export function* onIgnorePartnerEvent(action: any): SagaIterator {
  const {
    eventId,
    scheduleId,
    resolvedEvent,
    resolvedSchedule,
    ignoredGlobalEvent,
    isForceIgnoreEvent,
    isShowNotification,
    needRefreshGlobalEvent
  } = action?.payload ?? {};
  const { partnerEvent } = resolvedEvent ?? {};

  let result = {};

  try {
    if (resolvedSchedule?.type === 'Away') {
      yield call(GoFanEventService.ignoreAwayEvent, eventId);
      yield put(actions.onIgnorePartnerEventCompleted({ eventId }));

      if (isShowNotification) {
        yield put(addNotification(generateSuccessMessage('Event is ignored.'), 5000));
      }
      return result;
    }

    if (isEmpty(partnerEvent)) {
      yield put(addNotification(commonError));
      return result;
    }

    yield put(actions.onSetEventError({ errorEventIds: [eventId], isClear: true }));

    let updatedEvent = {};

    if (partnerEvent?.sourceHasPendingRequest) {
      updatedEvent = { sourceHasPendingRequest: false };
    }

    if (partnerEvent?.sourceIsDeleted) {
      updatedEvent = { ...updatedEvent, sourceIsDeleted: false };
    }

    if (partnerEvent?.sourceStatus === CANCELED_STATUS) {
      updatedEvent = { ...updatedEvent, sourceStatus: NORMAL_STATUS };
    }

    const currentDateTime = moment().format(EVENT_DATE_FORMAT_WITH_TIMEZONE);

    const newGofanStatusMsg = isEmpty(updatedEvent) ? IGNORED_STATUS : undefined;

    const response = yield call(EventScheduleService.updateGlobalEvent, {
      ...updatedEvent,
      id: partnerEvent?.id,
      gofanStatusUpdatedDt: currentDateTime,
      gofanStatusMsg: isForceIgnoreEvent ? IGNORED_STATUS : newGofanStatusMsg
    });

    if (isEmpty(response)) {
      yield put(addNotification(commonError));
      yield put(actions.onSetEventError({ errorEventIds: [eventId] }));
      return result;
    }

    if (isShowNotification) {
      yield put(addNotification(generateSuccessMessage(strings.SUCCESSFULLY_EVENT_IGNORED)));
    }

    if (needRefreshGlobalEvent) {
      yield put(
        actions.onRefreshPartnerEvent({
          eventId,
          scheduleId,
          newGlobalEvents: [
            {
              ...response,
              gofanStatusUpdatedDt: partnerEvent?.gofanStatusUpdatedDt
            }
          ]
        })
      );
    }

    const newPrevGlobalEvent = isEmpty(ignoredGlobalEvent) ? { ...partnerEvent } : { ...ignoredGlobalEvent };

    yield put(
      actions.onIgnorePartnerEventCompleted({
        ...action?.payload,
        eventId,
        scheduleId,
        prevGlobalEvents: [newPrevGlobalEvent],
        ignoredGlobalEvent: newPrevGlobalEvent
      })
    );

    result = {
      eventId,
      scheduleId,
      partnerEvent,
      resolvedSchedule,
      ignoredGlobalEvent: { ...partnerEvent },
      newGlobalEvents: [
        {
          ...response,
          gofanStatusUpdatedDt: partnerEvent?.gofanStatusUpdatedDt
        }
      ]
    };
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
    yield put(actions.onSetEventError({ errorEventIds: [eventId] }));
  } finally {
    const isCancelled = yield cancelled();
    if (!isCancelled) {
      yield put(actions.onCancelledTaskIgnorePartnerEvent({ eventId }));
    }
  }

  return result;
}

export function* onRestorePartnerEvent(action: any): SagaIterator {
  const {
    eventId,
    scheduleId,
    resolvedEvent,
    resolvedSchedule,
    ignoredGlobalEvent,
    isShowNotification,
    needRefreshGlobalEvent
  } = action.payload ?? {};
  const { partnerEvent } = resolvedEvent ?? {};
  let result = {};

  try {
    if (isEmpty(partnerEvent)) {
      yield put(addNotification(commonError));
      return result;
    }

    yield put(actions.onSetEventError({ errorEventIds: [eventId], isClear: true }));

    const currentDateTime = moment().format(EVENT_DATE_FORMAT_WITH_TIMEZONE);

    const request: any = isEmpty(ignoredGlobalEvent)
      ? {
          id: partnerEvent?.id,
          gofanStatusUpdatedDt: currentDateTime,
          gofanStatusMsg: ''
        }
      : {
          ...omit(ignoredGlobalEvent, [
            'changes'
            // 'created',
            // 'partnerId',
            // 'partnerName',
            // 'globalEventId',
            // 'goFanEvent',
            // 'resolveType',
            // 'changedType',
            // 'changedData',
          ]),
          id: partnerEvent?.id,
          // handle data null
          gofanStatusUpdatedDt: currentDateTime,
          awaySchoolId: ignoredGlobalEvent?.awaySchoolId ?? '',
          gofanStatusMsg: ignoredGlobalEvent?.gofanStatusMsg ?? ''
        };

    const response = yield call(EventScheduleService.updateGlobalEvent, request);

    if (isEmpty(response)) {
      yield put(addNotification(commonError));
      yield put(actions.onSetEventError({ errorEventIds: [eventId] }));
      return result;
    }

    if (isShowNotification) {
      yield put(addNotification(generateSuccessMessage(strings.SUCCESSFULLY_EVENT_RESTORED)));
    }

    if (needRefreshGlobalEvent) {
      yield put(
        actions.onRefreshPartnerEvent({
          eventId,
          scheduleId,
          newGlobalEvents: [
            {
              ...response,
              changes: ignoredGlobalEvent?.changes ?? [],
              gofanStatusUpdatedDt: partnerEvent?.gofanStatusUpdatedDt
            }
          ]
        })
      );
    }

    yield put(
      actions.onRestorePartnerEventCompleted({
        ...action?.payload,
        eventId,
        scheduleId
      })
    );

    result = {
      eventId,
      scheduleId,
      partnerEvent,
      resolvedSchedule,
      ignoredGlobalEvent,
      newGlobalEvents: [
        {
          ...response,
          changes: ignoredGlobalEvent?.changes ?? [],
          gofanStatusUpdatedDt: partnerEvent?.gofanStatusUpdatedDt
        }
      ]
    };
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
    yield put(actions.onSetEventError({ errorEventIds: [eventId] }));
  } finally {
    const isCancelled = yield cancelled();
    if (!isCancelled) {
      yield put(actions.onCancelledTaskRestorePartnerEvent({ eventId }));
    }
  }

  return result;
}

export function* onDeclinePartnerEvent(action: any): SagaIterator<void> {
  const { eventId, isShowNotification, resolvedSchedule } = action.payload;

  try {
    if (resolvedSchedule?.type !== 'Away') return;

    yield call(GoFanEventService.declineAwayEvent, eventId);
    yield put(actions.onDeclinePartnerEventCompleted({ eventId }));

    if (isShowNotification) {
      yield put(addNotification(generateSuccessMessage('Event is declined'), 5000));
    }
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
  } finally {
    const isCancelled = yield cancelled();
    if (!isCancelled) {
      yield put(actions.onCancelledTaskDeclinePartnerEvent({ eventId }));
    }
  }
}

export function* onPublishPartnerEvent(action: any): SagaIterator<void> {
  const { eventId, scheduleData, isInternalUser, resolvedEvent, resolvedSchedule, isShowNotification } = action.payload;

  try {
    if (resolvedSchedule?.type === 'Away') {
      yield call(GoFanEventService.publishAwayEvent, eventId);
      yield put(actions.onPublishPartnerEventCompleted({ eventId }));

      if (isShowNotification) {
        yield put(addNotification(generateSuccessMessage('Event is published'), 5000));
      }
      return;
    }

    const { venue } = resolvedSchedule?.scheduleVenue ?? {};
    const { newEvents, updatedEvents, deletedEvents, cancelledEvents } = getChangedGlobalEvent({
      venue,
      resolvedSchedule: {
        ...resolvedSchedule,
        schedule: {
          ...resolvedSchedule?.schedule,
          events: [resolvedEvent?.partnerEvent]
        }
      }
    });

    yield call(
      onUpdatePartnerEvents,
      actions.onUpdatePartnerEvents({
        scheduleData,
        isInternalUser,
        resolvedSchedule,
        newEvents,
        updatedEvents,
        deletedEvents,
        cancelledEvents,
        needRefreshGlobalEvent: true,
        isShowNotification,
        isShowTotalNotification: false
      })
    );

    yield put(actions.onPublishPartnerEventCompleted({ eventId }));
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
  } finally {
    const isCancelled = yield cancelled();
    if (!isCancelled) {
      yield put(actions.onCancelledTaskPublishPartnerEvent({ eventId }));
    }
  }
}

export function* onPublishAllPartnerEvent(action: any): SagaIterator<void> {
  const { scheduleId, scheduleData, isInternalUser, resolvedEvents, resolvedSchedule, isShowNotification } =
    action.payload;

  try {
    const { venue } = resolvedSchedule?.scheduleVenue ?? {};

    const { newEvents, updatedEvents, deletedEvents, cancelledEvents } = getChangedGlobalEvent({
      venue,
      resolvedSchedule: {
        ...resolvedSchedule,
        schedule: {
          ...resolvedSchedule?.schedule,
          events: resolvedEvents?.map((item: any) => item?.partnerEvent)
        }
      }
    });

    yield call(
      onUpdatePartnerEvents,
      actions.onUpdatePartnerEvents({
        scheduleData,
        isInternalUser,
        resolvedSchedule,
        newEvents,
        updatedEvents,
        deletedEvents,
        cancelledEvents,
        needRefreshGlobalEvent: true,
        isShowNotification: false,
        isShowTotalNotification: isShowNotification
      })
    );

    yield put(actions.onPublishAllEventsCompleted({ scheduleId }));
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
  }
}

/// /////////////////////////////////////////////////////////////////////////////

export function* onIgnorePartnerSeason(action: any): SagaIterator<void> {
  const { scheduleId, isShowNotification, scheduleData, resolvedSchedule, ignoredGlobalEvents } = action?.payload ?? {};
  const { schedule } = resolvedSchedule ?? {};

  try {
    if (isEmpty(schedule?.events)) {
      yield put(addNotification(commonError));
      return;
    }

    const ignoringEvents: PublishPartnerEvent[] = [];

    (schedule?.events ?? [])?.forEach((partnerEvent: GlobalEventDTO) => {
      const goFanEvent = (scheduleData?.goFanEvents ?? []).find(
        (item: EventDTO) => `${item?.id}` === `${partnerEvent?.gofanEventId}`
      );

      ignoringEvents.push({
        goFanEvent,
        partnerEvent,
        partnerSchedule: resolvedSchedule
      });
    });

    const results = yield all(
      ignoringEvents?.map(resolvedEvent => {
        const partnerEventId = `${resolvedEvent?.partnerEvent?.id ?? ''}`;
        const gofanStatusMsg = `${resolvedEvent?.partnerEvent?.gofanStatusMsg ?? ''}`.toLowerCase();
        const isPublished = gofanStatusMsg === PUBLISHED_STATUS.toLowerCase();
        const isWaiting = !gofanStatusMsg || gofanStatusMsg === WAITING_STATUS.toLowerCase();

        const ignoredGlobalEvent = (ignoredGlobalEvents ?? [])?.find(
          ({ id }: GlobalEventDTO) => `${id}` === partnerEventId
        );

        if (
          isEmpty(ignoredGlobalEvent) &&
          (isWaiting || (isPublished && !isEmpty(resolvedEvent?.partnerEvent?.changes)))
        ) {
          return call(
            onIgnorePartnerEvent,
            actions.onIgnorePartnerEvent({
              isShowNotification: false,
              needRefreshGlobalEvent: true,
              scheduleData,
              resolvedSchedule,
              scheduleId,
              resolvedEvent,
              eventId: partnerEventId
            })
          );
        }

        return call(function* (): SagaIterator {
          return {
            eventId: partnerEventId,
            scheduleId,
            resolvedSchedule,
            partnerEvent: resolvedEvent?.partnerEvent
          };
        });
      })
    );

    if (isShowNotification) {
      yield put(addNotification(generateSuccessMessage(strings.SUCCESSFULLY_SEASON_IGNORED)));
    }

    yield put(
      actions.onIgnorePartnerSeasonCompleted({
        scheduleId,
        ignoredGlobalEvents: results,
        eventIds: (results ?? [])?.map((item: any) => item.eventId)
      })
    );
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
  } finally {
    const isCancelled = yield cancelled();
    if (!isCancelled) {
      yield put(actions.onCancelledTaskIgnorePartnerSeason({ scheduleId }));
    }
  }
}

export function* onRestorePartnerSeason(action: any): SagaIterator<void> {
  const { scheduleId, isShowNotification, scheduleData, resolvedSchedule, ignoredGlobalEvents } = action?.payload ?? {};
  const { schedule } = resolvedSchedule ?? {};

  try {
    if (isEmpty(schedule?.events)) {
      yield put(addNotification(commonError));
      return;
    }

    const restoringEvents: PublishPartnerEvent[] = [];

    (schedule?.events ?? [])?.forEach((partnerEvent: GlobalEventDTO) => {
      const goFanEvent = (scheduleData?.goFanEvents ?? []).find(
        (item: EventDTO) => `${item?.id}` === `${partnerEvent?.gofanEventId}`
      );

      restoringEvents.push({
        goFanEvent,
        partnerEvent,
        partnerSchedule: resolvedSchedule
      });
    });

    const results = yield all(
      restoringEvents?.map(resolvedEvent => {
        const partnerEventId = `${resolvedEvent?.partnerEvent?.id ?? ''}`;

        const ignoredGlobalEvent = (ignoredGlobalEvents ?? [])?.find(
          ({ id }: GlobalEventDTO) => `${id}` === partnerEventId
        );

        if (!isEmpty(ignoredGlobalEvent)) {
          return call(
            onRestorePartnerEvent,
            actions.onRestorePartnerEvent({
              isShowNotification: false,
              needRefreshGlobalEvent: true,
              scheduleData,
              resolvedSchedule,
              scheduleId,
              resolvedEvent,
              eventId: partnerEventId,
              ignoredGlobalEvent
            })
          );
        }

        return call(function* (): SagaIterator {
          return {
            eventId: partnerEventId,
            scheduleId,
            resolvedSchedule,
            partnerEvent: resolvedEvent?.partnerEvent
          };
        });
      })
    );

    if (isShowNotification) {
      yield put(addNotification(generateSuccessMessage(strings.SUCCESSFULLY_SEASON_RESTORED)));
    }

    yield put(
      actions.onRestorePartnerSeasonCompleted({
        scheduleId,
        restoredGlobalEvents: results,
        eventIds: (results ?? [])?.map((item: any) => item.eventId)
      })
    );
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
  } finally {
    const isCancelled = yield cancelled();
    if (!isCancelled) {
      yield put(actions.onCancelledTaskRestorePartnerSeason({ scheduleId }));
    }
  }
}

export function* onUpdatePartnerSeason(action: any): SagaIterator {
  const { scheduleId, isInternalUser, scheduleData, resolvedSchedule, isShowNotification, needRefreshPartnerSchedule } =
    action?.payload ?? {};
  let result = {};

  try {
    const { gofanSeasonId } = resolvedSchedule?.schedule ?? {};
    const { venue } = resolvedSchedule?.scheduleVenue ?? {};
    const { goFanSeasons } = scheduleData ?? {};
    const goFanSeason: SeasonDTO = (goFanSeasons ?? [])?.find(
      (item: SeasonDTO) => `${item?.id}` === `${gofanSeasonId}`
    );

    const { updatedEvents, deletedEvents, cancelledEvents } = getChangedGlobalEvent({
      venue,
      resolvedSchedule
    });

    const updateResults = yield call(
      onUpdatePartnerEvents,
      actions.onUpdatePartnerEvents({
        isInternalUser,
        scheduleData,
        resolvedSchedule,
        updatedEvents,
        deletedEvents,
        cancelledEvents,
        isShowNotification: false,
        isShowTotalNotification: isShowNotification,
        needRefreshGlobalEvent: !needRefreshPartnerSchedule
      })
    );

    const season = yield call(EventScheduleService.updateGoFanSeasonByEvents, {
      season: goFanSeason
    });

    result = {
      scheduleId,
      resolvedSchedule,
      result: updateResults,
      season
    };

    const isAllError = isEmpty(updateResults) || isEmpty(updateResults.filter((item: any) => !isEmpty(item)));

    if (needRefreshPartnerSchedule && !isAllError) {
      yield put(actions.onRefreshPartnerSeason({ scheduleIds: [scheduleId] }));
    }
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
  }

  return result;
}

export function* onCreatePartnerSeason(action: any): SagaIterator {
  const { scheduleId, scheduleData, isInternalUser, resolvedSchedule, isShowNotification, needRefreshPartnerSchedule } =
    action?.payload ?? {};
  let result = {};

  try {
    // [START_BUILD]
    const {
      scheduleLevel,
      scheduleStartTimes,
      seasonStartTimeSettings,
      startTimeType,
      scheduleVenue,
      accountTickets,
      archived,
      eventsArchived,
      publishDate,
      publishTime,
      alert
    } = resolvedSchedule ?? {};
    const schedule = { ...resolvedSchedule?.schedule, scheduleLevels: scheduleLevel?.levels };
    const { sportName } = schedule ?? {};
    const { venue } = scheduleVenue ?? {};
    const { levels, accounts, activities, awayAccounts }: ScheduleData = scheduleData ?? {};
    const partnerName = schedule?.sourceName ?? '';

    const {
      account: homeSchool,
      financialAccount,
      taggedAccounts
    } = getScheduleAccountsByHomeSchool(schedule?.homeSchoolId, scheduleData);

    const defaultVenue = isEmpty(venue) ? mappingAccountVenue(homeSchool?.venues?.[0]) : { ...venue };

    const eventVenue = isEmpty(defaultVenue)
      ? {}
      : {
          venueAddress: defaultVenue?.address,
          venueCity: defaultVenue?.city,
          venueLocationName: defaultVenue?.location,
          venueName: defaultVenue?.name,
          venueState: defaultVenue?.state,
          venueZip: defaultVenue?.zipCode
        };

    if (isEmpty(homeSchool) || isEmpty(eventVenue)) return;

    const opponentSchoolIds: string[] = uniq(
      (schedule?.events ?? [])
        ?.filter(
          (event: GlobalEventDTO) =>
            !!event?.awaySchoolId && !INVALID_ACCOUNT_IDS.includes(`${event?.awaySchoolId}`.toLowerCase())
        )
        ?.map((event: GlobalEventDTO) => event?.awaySchoolId ?? '')
    );

    let opponentSchools: AccountDTO[] = [...(awayAccounts ?? []), ...(accounts ?? [])]?.filter((acc: AccountDTO) =>
      opponentSchoolIds.includes(acc?.id)
    );

    const fetchAwayAccIds: any[] = opponentSchoolIds?.filter(accId => !opponentSchools?.find(acc => acc.id === accId));

    if (!isEmpty(fetchAwayAccIds)) {
      const schools = yield call(SeasonService.fetchArbiterSchools, fetchAwayAccIds);
      opponentSchools = [...opponentSchools, ...schools];
    }

    const activity =
      activities?.find(({ label }: ActivityDTO) => `${label}`.toLowerCase() === `${sportName}`.toLowerCase()) ??
      ({} as ActivityDTO);

    const { genders, levelsByGender } = SeasonCreationUtil.getGenderLevelValueForMultipleSchedule({
      levels: (levels ?? []) as LevelDTO[],
      schedules: [schedule]
    });

    const isBoxOfficeAccount = isInternalUser || homeSchool?.boxOfficeCustomer;

    const accountTicketTypes = (accountTickets ?? []).filter(
      (ticket: any) =>
        isBoxOfficeAccount || !ticket.distributionChannel || ticket.distributionChannel === DISTRIBUTION_CHANNEL.GOFAN
    );

    const ticketTypes = SeasonCreationUtil.filterAccountTicketTypesBy({
      activityId: activity?.id,
      genders,
      levelsByGender,
      accountTicketTypes,
      isBoxOfficeAccount
    });

    const groupedAccountTicketTypes = SeasonCreationUtil.groupAccountTicketTypesBy({
      sportId: activity?.id,
      genders,
      ticketTypes,
      levelsByGender,
      creationMode: CREATION_MODE.ONE_PER_SPORT
    });

    // [STEP_1][BUILD_RAW_EVENTS]

    const partnerEvents: GlobalEventDTO[] = [];
    const publishEvents: GlobalEventDTO[] = [];
    const newEvents: GlobalEventDTO[] = [];
    const updatedEvents: GlobalEventDTO[] = [];
    const deletedEvents: GlobalEventDTO[] = [];
    const cancelledEvents: GlobalEventDTO[] = [];
    const ignoredPartnerEvents: GlobalEventDTO[] = [];
    const cancelledPartnerEvents: GlobalEventDTO[] = [];

    (resolvedSchedule?.schedule?.events ?? []).some((scheduleEvent: GlobalEventDTO) => {
      const gofanEventStatusMsg = `${scheduleEvent?.gofanStatusMsg ?? ''}`.toLowerCase();
      const isIgnored = gofanEventStatusMsg === IGNORED_STATUS.toLowerCase();
      const isDeleted = gofanEventStatusMsg === DELETED_STATUS.toLowerCase();
      const isCancelled = gofanEventStatusMsg === CANCELED_STATUS.toLowerCase();
      const isPublished = gofanEventStatusMsg === PUBLISHED_STATUS.toLowerCase();

      let event = { ...scheduleEvent, ...eventVenue } as GlobalEventDTO;

      if (!gofanEventStatusMsg) {
        newEvents.push(event);
        partnerEvents.push(event);
        publishEvents.push(event);
        return false;
      }

      if (isIgnored) {
        ignoredPartnerEvents.push(event);
        return false;
      }

      if (isCancelled && !event?.gofanEventId) {
        cancelledPartnerEvents.push(event);
        return false;
      }

      if (isDeleted) return false;
      if (!!gofanEventStatusMsg && !event?.gofanEventId) return false;

      const goFanEvent = (scheduleData?.goFanEvents ?? []).find(
        (item: EventDTO) => `${item?.id}` === `${event?.gofanEventId}`
      );

      if (!isEmpty(goFanEvent)) {
        event = { ...event, goFanEvent } as GlobalEventDTO;
      } else if (isEmpty(event?.goFanEvent)) {
        return false;
      }

      if (!isEmpty(event?.changes)) {
        if (event.changedType === PUBLISH_TYPE.DELETE) {
          deletedEvents.push(event);
        } else if (event.changedType === PUBLISH_TYPE.CANCEL) {
          partnerEvents.push(event);
          publishEvents.push(event);
          cancelledEvents.push(event);
        } else if (
          event.changedType === PUBLISH_TYPE.UPDATE ||
          event.changedType === PUBLISH_TYPE.AFTER_END_DATE_TIME ||
          event.changedType === PUBLISH_TYPE.HAS_SOLD_WITH_UPDATED_END_DATE_TIME
        ) {
          partnerEvents.push(event);
          publishEvents.push(event);
          updatedEvents.push(event);
        }
      } else if (isPublished) {
        partnerEvents.push(event);
        publishEvents.push(event);
      } else if (isCancelled) {
        partnerEvents.push(event);
        publishEvents.push({
          ...event,
          changedGoFanStatusMsg: CANCELED_STATUS
        });
      }

      return false;
    });

    const creatingSeason: CreatingSeason = {
      id: activity?.id,
      created: schedule?.created,
      name: sportName,
      sport: activity,
      accountId: homeSchool?.id,
      archived,
      eventsArchived,
      publishDate,
      publishTime,
      alert,
      creationMode: CREATION_MODE.ONE_PER_SPORT,
      venue: defaultVenue,
      genders,
      levelsByGender,
      genderSpecific: true,
      creationProcess: CREATION_PROCESS.EVENT_SETUP,
      eventStartTimeType: startTimeType ?? EVENT_START_TIME_TYPE.ALL_TEAMS,
      seasonStartTimeSettings,
      accountTicketTypes,
      rawSeasons: [],
      modeImportData: PARTNER_IMPORT_MODE,
      fromPartnerSetting: {
        partnerName,
        partnerEvents,
        schedules: [schedule]
      }
    };

    const rawEvents: RawEvent[] = EventScheduleService.buildRawEventByPartner({
      venue: eventVenue,
      account: homeSchool,
      financialAccount,
      taggedAccounts,
      genders,
      levelsByGender,
      activity,
      partnerEvents,
      opponentSchools,
      archived: eventsArchived,
      alert,
      startTimes: scheduleStartTimes,
      startTimeType
    });

    // [STEP_2][BUILDING_RAW_SEASON]
    const rawSeason: RawSeason = EventScheduleService.buildRawSeasonByPartner({
      partnerName,
      activity,
      account: homeSchool,
      financialAccount,
      taggedAccounts,
      rawEvents,
      partnerSchedule: schedule,
      creatingSeason,
      groupedAccountTicketTypes
    });

    const newCreatingSeason = new CreatingSeasonModel({
      ...creatingSeason,
      rawSeasons: [rawSeason]
    })
      .simplify()
      .toJSON();

    // [STEP_3][PUBLISH]

    const createRequest = new MultiSeasonCreateRequest(undefined, {
      activity,
      account: homeSchool,
      creatingSeason: newCreatingSeason
    })
      .populate()
      .toJSON();

    let responses: SeasonDTO[] = [];
    responses = yield call(SeasonCreationService.createMultiSeasons, createRequest);

    if (isEmpty(responses)) {
      yield put(addNotification(commonError));
      return result;
    }

    // [STEP_4][UPDATE_GLOBAL_SEASON_STATUS]

    const publishResults = yield call(
      onUpdatePartnerEventStatus,
      actions.onUpdatePartnerEventStatus({
        resolvedSchedule,
        season: responses?.[0],
        ignoredPartnerEvents,
        cancelledPartnerEvents,
        publishedPartnerEvents: publishEvents
      })
    );

    const updateResults = yield call(
      onUpdatePartnerEvents,
      actions.onUpdatePartnerEvents({
        scheduleData,
        updatedEvents,
        deletedEvents,
        cancelledEvents,
        needRefreshGlobalEvent: false,
        isShowNotification: false,
        isShowTotalNotification: false
      })
    );

    const season = yield call(EventScheduleService.updateGoFanSeasonByEvents, {
      season: responses?.[0]
    });

    if (needRefreshPartnerSchedule) {
      yield put(actions.onRefreshPartnerSeason({ scheduleIds: [scheduleId] }));
    }

    if (isShowNotification) {
      yield put(addNotification(generateSuccessMessage(strings.SUCCESSFULLY_SEASON_PUBLISHED)));
    }

    result = {
      scheduleId,
      resolvedSchedule,
      result: [
        {
          partnerEvents,
          updateResults,
          publishResults,
          season
        }
      ]
    };
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
  }

  return result;
}

export function* onPublishPartnerSeason(action: any): SagaIterator {
  const { scheduleId, resolvedSchedule } = action.payload ?? {};
  let result = {};

  try {
    if (resolvedSchedule?.schedule?.created) {
      result = yield call(onUpdatePartnerSeason, action);
    } else {
      result = yield call(onCreatePartnerSeason, action);
    }

    yield put(actions.onPublishPartnerSeasonCompleted({ scheduleId }));
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
  } finally {
    const isCancelled = yield cancelled();
    if (!isCancelled) {
      yield put(actions.onCancelledTaskPublishPartnerSeason({ scheduleId }));
    }
  }

  return result;
}

export function* onPublishAllPartnerSeason(action: any): SagaIterator<void> {
  const {
    isPublishAll,
    scheduleData,
    isInternalUser,
    resolvedSchedules,
    isShowNotification,
    needRefreshPartnerSchedule
  } = action.payload;

  try {
    const requests: any[] = [];
    const scheduleIds: any[] = [];

    resolvedSchedules?.forEach((item: any) => {
      const scheduleId = `${item?.schedule?.id ?? ''}`;

      if (scheduleId) {
        scheduleIds.push(scheduleId);
        requests.push(
          call(
            onPublishPartnerSeason,
            actions.onPublishPartnerSeason({
              isPublishAll,
              isInternalUser,
              isShowNotification: false,
              needRefreshPartnerSchedule: false,
              scheduleData,
              scheduleId,
              resolvedSchedule: item
            })
          )
        );
      }
    });

    const results = yield all(requests);

    if (isEmpty(results) || isEmpty(results.filter((item: any) => !isEmpty(item)))) {
      yield put(actions.onPublishAllSeasonsCompleted());
      return;
    }

    if (needRefreshPartnerSchedule) {
      yield put(actions.onRefreshPartnerSeason({ scheduleIds }));
    }

    if (isShowNotification) {
      yield put(addNotification(generateSuccessMessage(strings.SUCCESSFULLY_SEASON_PUBLISHED_ALL)));
    }
    yield put(actions.onPublishAllSeasonsCompleted());
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
  }
}

// [WATCHERs]

export function* watchIgnorePartnerEvent(): SagaIterator<void> {
  yield fork(
    takeMultitaskingChannel,
    actionTypes.ON_IGNORE_PARTNER_EVENT,
    actionTypes.ON_CANCELLED_TASK_IGNORE_PARTNER_EVENT,
    onIgnorePartnerEvent,
    { selectorKey: 'eventId' }
  );
}

export function* watchRestorePartnerEvent(): SagaIterator<void> {
  yield fork(
    takeMultitaskingChannel,
    actionTypes.ON_RESTORE_PARTNER_EVENT,
    actionTypes.ON_CANCELLED_TASK_RESTORE_PARTNER_EVENT,
    onRestorePartnerEvent,
    { selectorKey: 'eventId' }
  );
}

export function* watchDeclinePartnerEvent(): SagaIterator<void> {
  yield fork(
    takeMultitaskingChannel,
    actionTypes.ON_DECLINE_PARTNER_EVENT,
    actionTypes.ON_CANCELLED_TASK_DECLINE_PARTNER_EVENT,
    onDeclinePartnerEvent,
    { selectorKey: 'eventId' }
  );
}

export function* watchPublishPartnerEvent(): SagaIterator<void> {
  yield fork(
    takeMultitaskingChannel,
    actionTypes.ON_PUBLISH_PARTNER_EVENT,
    actionTypes.ON_CANCELLED_TASK_PUBLISH_PARTNER_EVENT,
    onPublishPartnerEvent,
    { selectorKey: 'eventId' }
  );
}

export function* watchPublishAllPartnerEvent(): SagaIterator<void> {
  yield takeEvery(actionTypes.ON_PUBLISH_ALL_EVENTS, onPublishAllPartnerEvent);
}

export function* watchIgnorePartnerSeason(): SagaIterator<void> {
  yield fork(
    takeMultitaskingChannel,
    actionTypes.ON_IGNORE_PARTNER_SEASON,
    actionTypes.ON_CANCELLED_TASK_IGNORE_PARTNER_SEASON,
    onIgnorePartnerSeason,
    { selectorKey: 'scheduleId' }
  );
}

export function* watchRestorePartnerSeason(): SagaIterator<void> {
  yield fork(
    takeMultitaskingChannel,
    actionTypes.ON_RESTORE_PARTNER_SEASON,
    actionTypes.ON_CANCELLED_TASK_RESTORE_PARTNER_SEASON,
    onRestorePartnerSeason,
    { selectorKey: 'scheduleId' }
  );
}

export function* watchPublishPartnerSeason(): SagaIterator<void> {
  yield fork(
    takeMultitaskingChannel,
    actionTypes.ON_PUBLISH_PARTNER_SEASON,
    actionTypes.ON_CANCELLED_TASK_PUBLISH_PARTNER_SEASON,
    onPublishPartnerSeason,
    { selectorKey: 'scheduleId' }
  );
}

export function* watchPublishAllPartnerSeason(): SagaIterator<void> {
  yield takeEvery(actionTypes.ON_PUBLISH_ALL_SEASONS, onPublishAllPartnerSeason);
}

export default [
  watchIgnorePartnerEvent(),
  watchRestorePartnerEvent(),
  watchDeclinePartnerEvent(),
  watchPublishPartnerEvent(),
  watchPublishAllPartnerEvent(),
  watchIgnorePartnerSeason(),
  watchRestorePartnerSeason(),
  watchPublishPartnerSeason(),
  watchPublishAllPartnerSeason()
];
