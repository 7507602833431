// @flow
import { PAGES } from '../../../config/routes';
import { ErrorResolver, Context } from '../types';
import {
  FORBIDDEN,
  UNSECURITY_ERROR,
  MAINTENANCE_MODE,
  STATUS_CODE_401,
  STATUS_CODE_403,
  STATUS_CODE_404,
  STATUS_CODE_500,
  STATUS_CODE_502,
  STATUS_CODE_503
} from '../../../api/api/constants';
import LocalStorage from '../../../LocalStorage';
import { parseUrl } from '../../../commons/utils/parseQueryString';

const navigateToLogin = () => {
  LocalStorage.clearStorage();
  window.location.href = '/login?status=expire';
};

export default class APIErrorResolver implements ErrorResolver {
  static NAME = 'APIError';

  static BLACKLIST_STATUS_CODES = [
    STATUS_CODE_401,
    STATUS_CODE_403,
    STATUS_CODE_404,
    STATUS_CODE_500,
    STATUS_CODE_502,
    STATUS_CODE_503
  ];

  resolve = (context: Context) => {
    const { error, token } = context;
    if (
      error.response &&
      APIErrorResolver.BLACKLIST_STATUS_CODES.findIndex(statusCode => error.response.status === statusCode) === -1
    ) {
      return;
    }

    // handle case inactive after 30 minutes and API return error code 401
    if (error.response && error.response.status === STATUS_CODE_401 && !token) {
      navigateToLogin();
      return;
    }

    // handle case inactive after 30 minutes and API return status code 403
    if (error.response && error.response.status === STATUS_CODE_403 && !token) {
      navigateToLogin();
      return;
    }

    // handle case inactive after 30 minutes API Gateway refunding tickets and API return status code 403
    if (
      error.response &&
      error.response.status === STATUS_CODE_403 &&
      (error.response.data.message === UNSECURITY_ERROR || error.response.data.message === FORBIDDEN)
    ) {
      navigateToLogin();
      return;
    }

    let statusCode = error.response ? error.response.status : STATUS_CODE_500;
    const { config, request, response } = error;
    if (!response && request) {
      // handle case refunding tickets got the CORS issues, should redirect user to login page
      if (config.baseURL.includes('apigateway')) {
        // navigateToLogin();
        return;
      }

      statusCode = STATUS_CODE_403;
    }

    if (response && response.status === STATUS_CODE_404) {
      const { method, url, baseURL } = response.config;
      const endPoint = url.slice(baseURL.length);

      // check request case of basic user api on login page
      if (method === 'get' && /^users\/([\s\S]*?)\/basic$/.test(endPoint)) return;
    }

    let queryStr = '';
    if (error.mode === MAINTENANCE_MODE) {
      queryStr = `?maintenance=true`;
      statusCode = STATUS_CODE_503;

      const { pathname, search } = window.location;
      const { query } = parseUrl(search || '');

      if (pathname !== PAGES.error.error503.path || query.maintenance !== 'true') {
        window.location.href = `${PAGES.error.error503.path}${queryStr}`;
      }
    }
  };
}
