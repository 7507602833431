// @flow
import { isEmpty } from 'lodash';
import { put, call, delay, select, takeLatest } from 'redux-saga/effects';
import * as types from './actionTypes.ts';
import * as actionCreator from './actions.ts';
import * as rootActionCreator from '../../root/actions.ts';
import EventRefund from '../../api/model/EventRefund';
import EventRefundResponse from '../../api/model/EventRefundResponse';
import ErrorDTO from '../../api/dto/ErrorDTO';
import PaginationDTO from '../../api/dto/PaginationDTO';
import PurchasedTicket from '../../api/model/PurchasedTicket';
import PaginationModel from '../../api/model/PaginationModel';
import PageRequest from '../../api/model/request/PageRequest';
import SortableRequest from '../../api/model/request/SortableRequest';
import SearchTicketRequest from '../../api/model/request/SearchTicketsRequest';
import SearchEventRefundRequest from '../../api/model/request/SearchEventRefundRequest';
import {
  refundByEvent,
  searchPurchasedTickets,
  refundPurchasedTickets,
  refundRequestByTicket,
  refundRequestByEvent,
  refundRequestDeleteById,
  groupedTicketsByOrderAccessToken
} from '../../api/services/FanSupportService';
import { fetchEventRefund } from '../../api/services/EventService';
import { generateFailContent, generateSuccessContent } from '../../utils/alertUtils';
import { FAN_SUPPORT } from '../../config/strings';

export function* fetchPurchasedTickets(searchParams) {
  const pageRequest = new PageRequest({
    pageSize: searchParams.pageSize,
    pageIndex: searchParams.pageIndex
  });
  const searchRequest = new SearchTicketRequest({
    last4: searchParams.last4,
    email: searchParams.email,
    orderId: searchParams.orderId,
    eventId: searchParams.eventId,
    chargeId: searchParams.chargeId
  });

  const response = yield call(searchPurchasedTickets, searchRequest, pageRequest);

  if (response instanceof PaginationDTO) {
    const { data, pageSize, pageNumber, totalPages } = response.toJSON();
    return new PaginationModel({
      pageSize,
      pageIndex: pageNumber,
      pageCount: totalPages,
      data: data.map(ticket => new PurchasedTicket(ticket.toJSON()).toJSON())
    }).toJSON();
  }
  return new PaginationModel({}).toJSON();
}

export function* searchTicket(action) {
  const defaultResult = new PaginationModel({}).toJSON();
  try {
    const { searchParams } = action.payload;
    const searchResult = yield call(fetchPurchasedTickets, searchParams);
    yield put(actionCreator.searchTicketSuccess(searchResult));
  } catch (e) {
    yield put(actionCreator.searchTicketSuccess(defaultResult));
  }
}

export function* watchSearchTicketRequest() {
  yield takeLatest(types.SEARCH_TICKETS, searchTicket);
}

export function* retryFetchPurchasedTickets(refundedTickets, searchParams) {
  let searchResult = null;
  let counter = 0;
  let isAllRefunded = false;
  while (counter < 3 && !isAllRefunded) {
    searchResult = yield call(fetchPurchasedTickets, searchParams);
    const latestPurchasedTickets = searchResult.data;
    const allRefundedAccessTokens = refundedTickets.map(refundedTicket => refundedTicket.accessToken);
    isAllRefunded = latestPurchasedTickets
      .filter(latestTicket => allRefundedAccessTokens.includes(latestTicket.accessToken))
      .every(latestTicket => !isEmpty(latestTicket.refundedAt));
    counter += 1;
    yield delay(1000);
  }

  return searchResult;
}

export function* refundTickets(action) {
  const { purchasedTickets, searchParams, additionalRefundInfo } = action.payload;
  // we group the tickets b/c many purchasedTicketsAccessTokens can exist on one orderAccessToken
  // then we will send the api calls based off these groups
  const groupedTickets = groupedTicketsByOrderAccessToken(purchasedTickets, true);
  const results = [];
  let error;
  for (let i = 0; i < groupedTickets.length; i++) {
    let hasOrderServiceError = false;
    let refundRequestByTicketResponse;
    let refundPurchasedTicketResponse;
    // refund requests are called on the order service before API Gateway refund requests (refundPurchasedTickets and refundByEvent)
    // the BE needs to collect some data beforehand, the refundPurchasedTickets resolves the request synchronously
    // https://huddleinc.atlassian.net/browse/GBO-1528
    try {
      // send to data to order service first
      refundRequestByTicketResponse = yield call(refundRequestByTicket, groupedTickets[i], additionalRefundInfo);
    } catch (e) {
      console.log(e);
      hasOrderServiceError = true;
    }

    if (refundRequestByTicketResponse instanceof ErrorDTO || hasOrderServiceError) {
      return;
    }

    try {
      // call gateway api to process refund
      refundPurchasedTicketResponse = yield call(refundPurchasedTickets, groupedTickets[i]);
      if (refundPurchasedTicketResponse instanceof ErrorDTO) {
        error = refundPurchasedTicketResponse.data;
      } else {
        results.push(refundPurchasedTicketResponse);
      }
    } catch (e) {
      console.log(e);
      error = e;
    } finally {
      // if there is a failure for refundPurchasedTickets, we need to clean up the data sent to the order service
      if (!refundPurchasedTicketResponse || refundPurchasedTicketResponse instanceof ErrorDTO) {
        yield call(refundRequestDeleteById, refundRequestByTicketResponse[0]);
      }
    }
  }

  // if all refund requests to the gateway fail, throw error message
  if (results.length === 0) {
    yield put(actionCreator.refundTicketsCompleted());
    yield put(rootActionCreator.addMessage(generateFailContent(error)));
    return;
  }

  // added a delay cause the search below was not fetching right data
  // the last ticket refunded was not coming back as refunded
  yield delay(1000);

  const searchResult = yield call(retryFetchPurchasedTickets, results, searchParams);
  if (searchResult) {
    yield put(actionCreator.searchTicketSuccess(searchResult));
  }
  yield put(
    rootActionCreator.addMessage(
      generateSuccessContent(
        `${results.length} purchased ticket${results.length > 1 ? 's have' : ' has'} been refunded.`
      )
    )
  );
  yield put(actionCreator.refundTicketsCompleted());
}

export function* watchRefundTicketsRequest() {
  yield takeLatest(types.REFUND_TICKETS, refundTickets);
}

export function* searchEventRefund(searchParams = {}) {
  let searchResult = new PaginationModel({}).toJSON();
  if (isEmpty(searchParams.search)) {
    return searchResult;
  }
  const searchRequest = new SearchEventRefundRequest({
    filter: searchParams.filter,
    search: searchParams.search
  });
  const pageRequest = new PageRequest({
    pageSize: searchParams.pageSize,
    pageIndex: searchParams.pageIndex
  });
  const sortableRequest = new SortableRequest({
    sortBy: searchParams.sortBy
  });

  const response = yield call(fetchEventRefund, searchRequest, pageRequest, sortableRequest);

  if (response instanceof PaginationDTO) {
    const { data, pageSize, pageNumber, totalPages } = response.toJSON();
    searchResult = new PaginationModel({
      pageSize,
      pageIndex: pageNumber,
      pageCount: totalPages,
      data: data.map(event => new EventRefund(event.toJSON()).toJSON())
    }).toJSON();
  }
  return searchResult;
}

export function* handleSearchEventRefund(action) {
  let searchResult = new PaginationModel({}).toJSON();
  try {
    const { searchParams } = action.payload;
    searchResult = yield call(searchEventRefund, searchParams);
    yield put(actionCreator.searchEventRefundCompleted(searchResult));
  } catch (error) {
    yield put(actionCreator.searchEventRefundCompleted(searchResult));
  }
}

export function* watchSearchEventRefundRequest() {
  yield takeLatest(types.SEARCH_EVENT_REFUND, handleSearchEventRefund);
}

export function* handleRefundByEvent(action) {
  try {
    const { event, additionalRefundInfo } = action.payload;
    const eventRefundRequestResponse = yield call(refundRequestByEvent, event, additionalRefundInfo);

    if (eventRefundRequestResponse instanceof ErrorDTO) {
      yield put(rootActionCreator.addMessage(generateFailContent(eventRefundRequestResponse.data.statusText)));
      yield put(actionCreator.refundByEventCompleted());
      return;
    }

    const response = yield call(refundByEvent, event);

    if (response instanceof ErrorDTO) {
      // clean up data sent to order service
      yield call(refundRequestDeleteById, eventRefundRequestResponse[0]);
      yield put(rootActionCreator.addMessage(generateFailContent(response.getErrorMessage())));
      yield put(actionCreator.refundByEventCompleted());
      return;
    }

    let searchResult = new PaginationModel({}).toJSON();

    // added a delay cause the search below was not fetching updated event data
    // the last event refunded was not coming back as refunded and the user could still attempt to refund it again
    // yes 2000 is long, but 500 and 1000 did not work
    yield delay(2000);

    searchResult = yield call(searchEventRefund, {
      filter: '',
      sortBy: [],
      pageSize: 1,
      pageIndex: 0,
      search: `${event.id}`
    });

    if (!isEmpty(searchResult.data)) {
      const fanEvents = yield select(state => state.fanSupport.events);
      const newFanEvents = fanEvents.map(fanEvent => {
        if (`${fanEvent.id}` === `${event.id}`) {
          return searchResult.data[0];
        }
        return fanEvent;
      });
      yield put(actionCreator.setEventRefund(newFanEvents));
    }

    const eventRefundResponse = new EventRefundResponse({
      ...response.data,
      eventName: event.name
    });

    if (!isEmpty(eventRefundResponse.refundStatus)) {
      const { warning, content } = eventRefundResponse.refundStatus;
      if (warning) {
        yield call(refundRequestDeleteById, eventRefundRequestResponse[0]);
        yield put(actionCreator.refundByEventInProccess(event.id));
      } else {
        const refundContent = generateSuccessContent(
          `${content} NOTE: You may need to refresh the screen to see your updated refund.`
        );
        yield put(rootActionCreator.addMessage(refundContent));
      }
    }

    yield put(actionCreator.refundByEventCompleted());
  } catch (e) {
    yield put(actionCreator.refundByEventCompleted());
    yield put(rootActionCreator.addMessage(generateFailContent(FAN_SUPPORT.UNEXPECTED_ERROR)));
  }
}

export function* watchRefundByEventRequest() {
  yield takeLatest(types.REFUND_BY_EVENT, handleRefundByEvent);
}

export default [
  watchSearchTicketRequest(),
  watchRefundTicketsRequest(),
  watchRefundByEventRequest(),
  watchSearchEventRefundRequest()
];
