// @flow

import { sliceByKeys } from '../../utils/objectUtils';

class EventTypeDTO {
  constructor(props) {
    const { result, rest } = sliceByKeys({
      obj: props,
      keys: ['id', 'name', 'schoolId'],
      omit: ['_links']
    });
    this.raw = { ...result, restData: rest };
  }

  get restData() {
    return this.raw.restData;
  }

  get id() {
    return this.raw.id;
  }

  get name() {
    return this.raw.name;
  }

  get schoolId() {
    return this.raw.schoolId;
  }

  toJSON() {
    return {
      restData: this.restData,
      id: this.id,
      name: this.name,
      schoolId: this.schoolId
    };
  }
}

export default EventTypeDTO;
