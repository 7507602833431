import RateDAO from '../dao/RateDAO';
import { PAGES } from '../../config/routes';

export const fetchRates = ({ searchRatesRequest, pageRequest, sortableRequest }) =>
  RateDAO.fetchRates({
    searchRatesRequest,
    pageRequest,
    sortableRequest
  });
export const fetchRateById = (id, expands) => RateDAO.fetchRateById(id, expands);
export const createRate = rateRequest => RateDAO.createRate(rateRequest);
export const updateRate = rateRequest => RateDAO.updateRate(rateRequest);

export const navigateToRatesPage = history => {
  history.push(PAGES.rates.root.path);
};

export default { fetchRateById, createRate, updateRate, fetchRates };
