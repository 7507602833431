// eslint-disable-next-line import/prefer-default-export
const HEADER_HEIGHT = 75;

export const scrollToTop = () =>
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });

export const scrollNewElementIntoView = ({ id, newTop }: { id: string; newTop: number }) => {
  setTimeout(() => {
    const elementRect = document.getElementById(id)?.getBoundingClientRect();
    if (elementRect && elementRect.top <= HEADER_HEIGHT) {
      window.scroll({
        top: newTop || 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, 300);
};

export const scrollToElementWhenMount = ({ id }: { id: string }) => {
  setTimeout(() => {
    const elementRect = document.getElementById(id)?.getBoundingClientRect();
    const headerOffSet = 30;
    if (elementRect) {
      window.scroll({
        top: elementRect.top - (HEADER_HEIGHT + headerOffSet),
        left: 0,
        behavior: 'smooth'
      });
    }
  }, 300);
};

export const isSafari = () => {
  const sUsrAg = navigator.userAgent;
  return sUsrAg.indexOf('Safari') > -1 && sUsrAg.indexOf('Chrome') <= -1;
};
