// @flow
import { EventState } from './types';
import {
  EventActionTypes,
  RESET_STATE,
  SEARCH_EVENTS,
  SEARCH_EVENTS_SUCCESS,
  ROUTING_EVENT_PAGE,
  ROUTING_EVENT_PAGE_COMPLETED
} from './actionTypes';

export const initialState: EventState = {
  events: [],
  isRouted: false,
  isSearched: false,
  isSearching: false,
  account: undefined
};

export default (state = initialState, action: EventActionTypes) => {
  switch (action.type) {
    case RESET_STATE: {
      return { ...initialState };
    }
    case SEARCH_EVENTS: {
      return { ...state, isSearching: true, isSearched: false };
    }
    case SEARCH_EVENTS_SUCCESS: {
      const { searchResult } = action.payload;
      return { ...state, isSearching: false, isSearched: true, events: searchResult };
    }
    case ROUTING_EVENT_PAGE: {
      return { ...state, isRouted: false };
    }
    case ROUTING_EVENT_PAGE_COMPLETED: {
      const { account } = action.payload;
      return { ...state, account, isRouted: true };
    }
    default:
      return state;
  }
};
