import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { JSErrors } from '@newrelic/browser-agent/features/jserrors';

let hostname;
let options = { features: [JSErrors] };

if (typeof window !== 'undefined') hostname = window && window.location && window.location.hostname;

if (hostname === 'hq.gofan.co') {
  options = {
    ...options,
    init: {
      distributed_tracing: {
        enabled: true,
        cors_use_newrelic_header: true,
        cors_use_tracecontext_headers: false,
        allowed_origins: ['https://api.gofan.co', 'https://hq.gofan.co']
      },
      privacy: { cookies_enabled: true },
      spa: true
    },
    loader_config: {
      accountID: '2644888',
      trustKey: '2644888',
      agentID: '528387196',
      licenseKey: 'NRJS-acf68ff8af126432431',
      applicationID: '528387196'
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: 'NRJS-acf68ff8af126432431',
      applicationID: '528387196',
      sa: 1
    }
  };
}

if (hostname === 'staging.hq.gofan.co') {
  options = {
    ...options,
    init: {
      distributed_tracing: {
        enabled: true,
        cors_use_newrelic_header: true,
        cors_use_tracecontext_headers: false,
        allowed_origins: ['https://api.stage.gofan.co', 'https://staging.hq.gofan.co']
      },
      privacy: { cookies_enabled: true },
      spa: true
    },
    loader_config: {
      accountID: '2644888',
      trustKey: '2644888',
      agentID: '512960250',
      licenseKey: 'NRJS-acf68ff8af126432431',
      applicationID: '512960250'
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: 'NRJS-acf68ff8af126432431',
      applicationID: '512960250',
      sa: 1
    }
  };
}

// Uncomment to enable monitoring in qa1 environment
// if (hostname === 'qa.hq.gofan.co') {
//   options = {
//     ...options,
//     init: {
//       distributed_tracing: {
//         enabled: true,
//         cors_use_newrelic_header: true,
//         cors_use_tracecontext_headers: false,
//         allowed_origins: ['https://api.qa.gofan.co', 'https://qa.hq.gofan.co']
//       },
//       privacy: { cookies_enabled: true },
//       spa: true
//     },
//     loader_config: {
//       accountID: '2644888',
//       trustKey: '2644888',
//       agentID: '535651004',
//       licenseKey: 'NRJS-acf68ff8af126432431',
//       applicationID: '535651004'
//     },
//     info: {
//       beacon: 'bam.nr-data.net',
//       errorBeacon: 'bam.nr-data.net',
//       licenseKey: 'NRJS-acf68ff8af126432431',
//       applicationID: '535651004',
//       sa: 1
//     }
//   };
// }

// eslint-disable-next-line no-new
if (typeof options !== 'undefined') new BrowserAgent(options);
