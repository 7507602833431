// @flow

class ActivityDTO {
  constructor({ id, label, athletic, disabled, genderSpecific, gender }) {
    this.raw = {
      id,
      label,
      athletic,
      disabled,
      genderSpecific,
      gender
    };
  }

  get id() {
    return this.raw.id;
  }

  get label() {
    return this.raw.label;
  }

  get athletic() {
    return this.raw.athletic;
  }

  get disabled() {
    return this.raw.disabled;
  }

  get genderSpecific() {
    return this.raw.genderSpecific;
  }

  get gender() {
    return this.raw.gender;
  }

  toJSON() {
    return {
      id: this.id,
      label: this.label,
      athletic: this.athletic,
      disabled: this.disabled,
      genderSpecific: this.genderSpecific,
      gender: this.gender
    };
  }
}

export default ActivityDTO;
