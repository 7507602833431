import dayjs from 'dayjs';
import { Row } from 'carbon-components-react';
import { SeasonCard } from '@seasons/components/SeasonCard';
import { SeasonTicketModal, SeasonTicketTypeEnum } from '@season-management/components/SeasonTicketModal';
import icons from '@assets/images/activity-icons/activity-icons';

import { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import { RootContext } from '@app/RootContext';
import { switchZone, EVENT_DATE_FORMAT_WITH_TIMEZONE } from '@app/utils/dateUtils';
import { checkInternalUser } from '@api/services/UserService';
import { canEdit } from '@gofan/api/users';
import SeasonService from '@seasons/services/season.service';

import type { AccountDTO } from '@gofan/api/accounts';
import { addNotification } from '@gofan/utils/toast';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { PAGES } from '@config/routes';

import type { SeasonDTO } from '@seasons/models/season.model';
import type { ActivityDTO } from '@gofan/api/activities';
import type { RateDTO } from '@gofan/api/rates';

import { useSeasonInsightPanel } from '@seasons/hooks/useSeasonInsightPanel';
import { SeasonInsightsPanel } from '../season-insights-panel';
import { FEATURE_FLAGS, useFeatureFlags } from '@gofan/hooks';

type SeasonGridProps = {
  history: any;
  seasons: SeasonDTO[];
  activities: ActivityDTO[];
  isFromDashboard?: boolean;
  rates: RateDTO[];
  onSeasonsChanged?: (seasons: SeasonDTO[]) => void;
};

const SeasonGrid = ({ isFromDashboard, history, seasons, activities, rates, onSeasonsChanged }: SeasonGridProps) => {
  const { currentUser } = useContext(RootContext);
  const [openSeasonTicketModal, setOpenSeasonTicketModal] = useState<any>(null);

  const { openSeasonInsightPanel, selectedSeasonInsights, handleClosePanel, handleOpenPanel } = useSeasonInsightPanel();
  const { [FEATURE_FLAGS.viewSeason]: isViewSeason } = useFeatureFlags();

  const isUnmounted = useRef(false);

  const mappedActivities = useMemo(() => {
    if (activities && activities.length > 0) {
      const mappedObj = {};

      activities.forEach(activity => {
        mappedObj[activity.id] = activity;
      });

      return mappedObj;
    }

    return null;
  }, [activities]);

  const getSportIcon = useCallback(
    (season?: SeasonDTO) =>
      (mappedActivities && icons[mappedActivities[season?.activityId]?.label.toLowerCase()]) || icons.unlabeled,
    [mappedActivities]
  );

  const selectedSeason = useMemo(
    () => seasons.find(s => `${s.id}` === `${selectedSeasonInsights?.season_id}`),
    [seasons, selectedSeasonInsights?.season_id]
  );

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  if (isEmpty(seasons)) {
    return null;
  }

  return (
    <Row>
      {seasons.map((season: SeasonDTO) => (
        <SeasonCard
          season={season}
          handleError={_handleError}
          handleOpenPanel={handleOpenPanel}
          account={get(season, '_embedded.account') ?? null}
          schoolName={get(season, '_embedded.account.name') ?? ''}
          schoolLogo={get(season, '_embedded.account.logo') ?? ''}
          schoolColor={get(season, '_embedded.account.primaryColor') ?? '000000'}
          schoolId={get(season, '_embedded.account.id') ?? ''}
          seasonName={season.name}
          seasonLevels={season.levels || []}
          sportIcon={getSportIcon(season)}
          eventCount={season.eventIds.length}
          createdAt={season.createdAt}
          createdBy={season.createdBy}
          lastUpdatedAt={season.updatedAt}
          startDateTime={season.startDateTime}
          endDateTime={season.endDateTime}
          entered={season.entered}
          goToSeason={(shouldScrollToAddEvent = false) => {
            if (isViewSeason) {
              history.push(PAGES.season.view.calculatePath(season?.id), {
                formPage: isFromDashboard ? PAGES.dashboard.root.path : PAGES.season.root.path,
                shouldScrollToAddEvent
              });
            } else {
              history.push(PAGES.season.edit.calculatePath(season?.id, season?.accountId, season?.financialAccountId), {
                formPage: isFromDashboard ? PAGES.dashboard.root.path : PAGES.season.root.path,
                shouldScrollToAddEvent
              });
            }
          }}
          goToReservedSeating={() => history.push(PAGES.season.reservedSeating.calculatePath(season.id, 0))}
          goToManageAttendees={() => history.push(PAGES.fans.root.calculatePath(season.id, 'season'))}
          key={season.id}
          hasReservedSeating={
            season.products.find(product => product.reservedSeating === true) &&
            _checkEditorRole(get(season, '_embedded.account'))
          }
          partnerName={season.partnerName?.toLowerCase()}
          onAfterDeletedSeason={_onAfterDeletedSeason}
          onCreateSeasonTicket={_onCreateSeasonTicket}
          isFromDashboard={isFromDashboard}
        />
      ))}

      {openSeasonInsightPanel && (
        <SeasonInsightsPanel
          sportIcon={getSportIcon(selectedSeason)}
          seasonName={selectedSeason?.name}
          seasonInsights={selectedSeasonInsights}
          onClose={handleClosePanel}
          style={{ top: '113px', right: '16px' }}
        />
      )}
      <SeasonTicketModal
        key={JSON.stringify(openSeasonTicketModal)}
        open={!isEmpty(openSeasonTicketModal)}
        mode={openSeasonTicketModal?.mode}
        type={SeasonTicketTypeEnum.SEASON_TICKET}
        data={{
          account: openSeasonTicketModal?.account,
          rates,
          endDateTime: openSeasonTicketModal?.season?.endDateTime,
          ticketLimitPerOrderSeasonEvent: openSeasonTicketModal?.season?.ticketLimitPerOrderSeasonEvent
        }}
        seasonTicket={openSeasonTicketModal?.seasonTicket}
        onSubmit={_onSubmitSeasonTicket}
        onCloseModal={_onCloseSeasonTicket}
        venueId={openSeasonTicketModal?.season.venueId}
      />
    </Row>
  );

  function _onAfterDeletedSeason(deletedSeason?: SeasonDTO) {
    if (typeof onSeasonsChanged === 'function') {
      const newSeasons = (seasons ?? [])?.filter(season => `${season?.id}` !== `${deletedSeason?.id}`);
      onSeasonsChanged([...newSeasons]);
    }
  }

  function _onCreateSeasonTicket(data: any) {
    setOpenSeasonTicketModal(data);
  }

  function _onCloseSeasonTicket(data: any) {
    if (isEmpty(data)) {
      setOpenSeasonTicketModal(null);
    } else {
      setOpenSeasonTicketModal({ ...openSeasonTicketModal, ...data });
    }
  }

  function _onSubmitSeasonTicket({
    mode,
    values,
    defaultValues
  }: {
    mode: 'create' | 'update';
    values: any;
    defaultValues: any;
  }) {
    const index = seasons.findIndex(season => season.id === openSeasonTicketModal?.season?.id);

    if (index === -1) return;

    const season = seasons[index];
    const timeZone = get(season, '_embedded.account.timeZone');

    const body: any = {
      ...season,
      products: [...season.products, omit(values, ['id', 'created'])].map(product => {
        let salesStartDateTime;
        let salesEndDateTime;
        if (!isEmpty(product?.salesStartDate) && !isEmpty(product?.salesStartTime)) {
          const mSalesStartDateTime = dayjs(`${product?.salesStartDate} ${product?.salesStartTime}`);
          salesStartDateTime = switchZone(
            mSalesStartDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE),
            timeZone
          );
        }

        if (!isEmpty(product?.salesEndDate) && !isEmpty(product?.salesEndTime)) {
          const mSalesEndDateTime = dayjs(`${product?.salesEndDate} ${product?.salesEndTime}`);
          salesEndDateTime = switchZone(mSalesEndDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE), timeZone);
        }

        return {
          ...omit(product, ['salesStartDate', 'salesStartTime', 'salesEndDate', 'salesEndTime']),
          salesStartDateTime,
          salesEndDateTime
        };
      })
    };
    SeasonService.updateSeason(body)
      .then(res => {
        if (isUnmounted.current) return;
        if (typeof onSeasonsChanged === 'function') {
          const updatedSeasons = [...seasons];
          const updatedSeason = { ...season, ...res };
          updatedSeasons.splice(index, 1, updatedSeason);
          onSeasonsChanged(updatedSeasons);
        }
      })
      .catch(error => {
        if (isUnmounted.current) return;
        _handleError(error);
      });
  }

  function _handleError(error: any) {
    addNotification({
      type: 'error',
      message: error.message ?? 'Unknown error'
    });
  }

  function _checkEditorRole(account?: AccountDTO) {
    if (!account) return false;
    return checkInternalUser(currentUser.role) || canEdit(account?.id, currentUser);
  }
};

export default SeasonGrid;
