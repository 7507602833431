export const HEIGHT_INPUT = 36;

export const EVENTS_PAGE_SIZE = 200;

export const VENUE_REQUEST_FIELDS = [
  'id',
  'name',
  'label',
  'streetAddress',
  'state',
  'zip',
  'city'
];

export const EVENT_TYPE_REQUEST_FIELDS = ['name', 'reservedSeat'];

export const ACTIVITY_REQUEST_FIELDS = ['athletic', 'disabled', 'id', 'label'];

export const ACCOUNT_REQUEST_FIELDS = [
  'id',
  'name',
  'logo',
  'state',
  'city',
  'zip',
  'streetAddress',
  'mascot',
  'shortName'
];

export const HOST_ACCOUNT_REQUEST_FIELDS = [
  'id',
  'name',
  'logo',
  'state',
  'city',
  'zip',
  'streetAddress'
];

export const OPPONENT_ACCOUNT_REQUEST_FIELDS = [
  'id',
  'mascot',
  'shortName',
  'name'
];

export const LEVELS_REQUEST_FIELDS = ['id', 'name'];

export const EVENT_SALE_INFO_REQUEST_FIELDS = [
  'eventCapacity',
  'eventTotalSaleQuantity',
  'eventId',
  'eventSoldOut',
  'productSalesMap'
];

export const EVENTS_PAGE_REQUEST_FIELDS = [
  'name',
  'id',
  'genders',
  'startDateTime',
  'endDateTime',
  'allDayEvent',
  'accountId',
  'hostAccountId',
  'opponentAccountId',
  'financialAccountId',
  'seasonId',
  'activityId',
  'activityPath',
  'archived',
  'salesEndDateTime',
  'featuredAccountIds',
  'salesStartDateTime',
  'products.enabled',
  'products.distributionChannel',
  'products.id',
  'products.name',
  'products.fee',
  'products.price',
  'products.limit',
  'products.productType',
  'specialEventDescription',
  'eventTypeId',
  'alert',
  'maxCapacity',
  'theme',
  'timeZone'
];

export const ROLES_MAPPING = [
  'accountId',
  'hostAccountId',
  'financialAccountId'
];

export const ROW_HEIGHT = 36;
export const ACTIVITY_NONE_ID = -1;
export const ACTIVITY_NONE_LABEL = 'None';

export const SEARCH_ACCOUNTS_PARAMS = {
  keyword: '',
  pageIndex: 0,
  pageSize: 200,
  status: 'Active',
  sortBy: [{ desc: true, id: 'updatedAt' }]
};
