import isEmpty from 'lodash/isEmpty';

class SortingRequest {
  sortBy: any[];

  constructor({ sortBy = [] }: { sortBy: any[] }) {
    this.sortBy = sortBy;
  }

  toQueryString() {
    if (isEmpty(this.sortBy)) return '';
    return this.sortBy?.reduce((res, cur) => (cur.id ? `${res}&sort=${cur.id},${cur.desc ? 'desc' : 'asc'}` : res), '');
  }
}

export { SortingRequest };
