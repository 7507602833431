import type { AccountDTO } from '@gofan/api';
import { ACCOUNT_PAYMENT_CYCLE } from '@gofan/constants';

// Check if the school is eligible to pay the fee, meaning can the school pay for the fees instead of the attendee
// Currently only Schools in the state of Washington are eligible for daily payment cycle
// by law, schools in Washington are required to have the attendee pay for the fees
// the exception is if the school type is a State Association or a Section in Washington or the school is private
// private schools will be able to change the payment cycle to weekly or event based
// this func is used to disable the attendee fee toggle on event, seasons, concession and donation view/edit
export const isSchoolEligibleToPayFee = (account: AccountDTO | undefined): boolean => {
  if (!account) return false;
  if (account.paymentCycle === ACCOUNT_PAYMENT_CYCLE.DAILY) {
    return account.gofanSchoolType === 'State Association' || account.gofanSchoolType === 'Section';
  }

  return (
    account.paymentCycle === ACCOUNT_PAYMENT_CYCLE.WEEKLY || account.paymentCycle === ACCOUNT_PAYMENT_CYCLE.EVENT_BASED
  );
};
