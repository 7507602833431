import { ProductType } from './types';
import {
  ProductTypeActionTypes,
  FETCH_PRODUCT_TYPES,
  CREATE_PRODUCT_TYPE,
  UPDATE_PRODUCT_TYPE,
  DELETE_PRODUCT_TYPE,
  FETCH_PRODUCT_TYPES_COMPLETED,
  CREATE_PRODUCT_TYPE_COMPLETED,
  UPDATE_PRODUCT_TYPE_COMPLETED,
  DELETE_PRODUCT_TYPE_COMPLETED
} from './actionTypes';

export function fetchProductTypesCompleted(
  productTypes: ProductType[]
): ProductTypeActionTypes {
  return {
    type: FETCH_PRODUCT_TYPES_COMPLETED,
    payload: {
      productTypes
    }
  };
}

export function fetchAllProductTypes(): ProductTypeActionTypes {
  return {
    type: FETCH_PRODUCT_TYPES
  };
}

// TypeScript infers that this function is returning createProductType
export function createProductType(
  productType: ProductType
): ProductTypeActionTypes {
  return {
    type: CREATE_PRODUCT_TYPE,
    payload: {
      productType
    }
  };
}

// TypeScript infers that this function is returning createProductTypeSuccessful
export function createProductTypeCompleted(
  productTypes: ProductType[]
): ProductTypeActionTypes {
  return {
    type: CREATE_PRODUCT_TYPE_COMPLETED,
    payload: {
      productTypes
    }
  };
}

// TypeScript infers that this function is returning updateProductType
export function updateProductType(
  productType: ProductType
): ProductTypeActionTypes {
  return {
    type: UPDATE_PRODUCT_TYPE,
    payload: {
      productType
    }
  };
}

// TypeScript infers that this function is returning UpdateProductTypeSuccessful
export function updateProductTypeCompleted(
  productTypes: ProductType[]
): ProductTypeActionTypes {
  return {
    type: UPDATE_PRODUCT_TYPE_COMPLETED,
    payload: {
      productTypes
    }
  };
}

// TypeScript infers that this function is returning DeleteProductType
export function deleteProductType(
  productType: ProductType
): ProductTypeActionTypes {
  return {
    type: DELETE_PRODUCT_TYPE,
    payload: {
      productType
    }
  };
}

// TypeScript infers that this function is returning deleteProductTypeCompleted
export function deleteProductTypeCompleted(
  productTypes: ProductType[]
): ProductTypeActionTypes {
  return {
    type: DELETE_PRODUCT_TYPE_COMPLETED,
    payload: {
      productTypes
    }
  };
}
