class RowEventDTO {
  constructor({
    sport,
    levels,
    genders,
    opponentCity,
    date,
    fullDate,
    opponentAccount,
    opponentSchool,
    startDateTime,
    opponentState,
    time,
    venueName,
    venueCity,
    venueState,
    venueZip,
    venueAddress,
    venueLocation
  }) {
    this.raw = {
      sport,
      levels,
      genders,
      opponentCity,
      date,
      fullDate,
      opponentAccount,
      opponentSchool,
      startDateTime,
      opponentState,
      time,
      venueName,
      venueCity,
      venueState,
      venueZip,
      venueAddress,
      venueLocation
    };
  }

  get sport() {
    return this.raw.sport;
  }

  get levels() {
    return this.raw.levels;
  }

  get genders() {
    return this.raw.genders;
  }

  get opponentCity() {
    return this.raw.opponentCity;
  }

  get date() {
    return this.raw.date;
  }

  get fullDate() {
    return this.raw.fullDate;
  }

  get opponentAccount() {
    return this.raw.opponentAccount;
  }

  get opponentSchool() {
    return this.raw.opponentSchool;
  }

  get startDateTime() {
    return this.raw.startDateTime;
  }

  get opponentState() {
    return this.raw.opponentState;
  }

  get time() {
    return this.raw.time;
  }

  get venueName() {
    return this.raw.venueName;
  }

  get venueCity() {
    return this.raw.venueCity;
  }

  get venueState() {
    return this.raw.venueState;
  }

  get venueZip() {
    return this.raw.venueZip;
  }

  get venueAddress() {
    return this.raw.venueAddress;
  }

  get venueLocation() {
    return this.raw.venueLocation;
  }

  toJSON() {
    return {
      sport: this.sport,
      levels: this.levels,
      genders: this.genders,
      opponentCity: this.opponentCity,
      date: this.date,
      fullDate: this.fullDate,
      opponentAccount: this.opponentAccount,
      opponentSchool: this.opponentSchool,
      startDateTime: this.startDateTime,
      opponentState: this.opponentState,
      time: this.time,
      venueName: this.venueName,
      venueCity: this.venueCity,
      venueState: this.venueState,
      venueZip: this.venueZip,
      venueAddress: this.venueAddress,
      venueLocation: this.venueLocation
    };
  }
}

export default RowEventDTO;
