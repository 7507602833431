import isEmpty from 'lodash/isEmpty';
import memoizeOne from 'memoize-one';
import { oc } from 'ts-optchain';
import { stringToHex } from '@gofan/utils/strings';
import {
  USER_ROLE_SUPER_ADMIN,
  USER_ROLE_ACCOUNT_USER,
  USER_ROLE_EDITOR,
  USER_ROLE_NONE,
  USER_ROLES,
  INTERNAL_USER_ROLES,
  ADMIN_ROLES,
  USER_ROLE_SYSTEM_ADMIN,
  CONTACT_TYPE,
  CONTACT_FINANCE,
  CONTACT_OTHER,
  USER_ROLE_COMMISSIONER_EDITOR,
  USER_ROLE_COMMISSIONER_VIEWER,
  USER_ROLE_BOXOFFICE,
  USER_ROLE_FINANCE,
  USER_ROLE_REPORTS,
  USER_ROLE_COACH,
  CONTACT_COACH,
  EDIT_SCHOOL_FINANCIAL
} from './user.constant';
import { config } from '@gofan/constants/config';

import type { ContactType, UserDTO } from './user.model';
import type { AccountDTO, UserAccountContext } from '../accounts';
import type { EventDTO } from '../events';
import type { SchoolConfig } from '../school-config';

export const getActiveUserAccountContext = (user: any): any[] => {
  if (isEmpty(user) || isEmpty(user.userAccountContexts)) return [];
  return user.userAccountContexts.filter(context => !context.inactive);
};

export const getEditorAccountIdsContext = (user: UserDTO): string[] => {
  if (isEmpty(user) || isEmpty(user.userAccountContexts)) return [];
  const userAccountContexts = user.userAccountContexts.filter(
    context =>
      !context.inactive &&
      (context.accountRoles.includes(USER_ROLE_EDITOR) ||
        context.accountRoles.includes(USER_ROLE_COMMISSIONER_VIEWER) ||
        context.accountRoles.includes(USER_ROLE_COMMISSIONER_EDITOR))
  );

  return userAccountContexts.map(account => account.accountId);
};

export const getEditedUserAccountContext = memoizeOne((currentUser: any) => {
  if (isEmpty(currentUser) || isEmpty(currentUser.userAccountContexts)) return [];
  return currentUser.userAccountContexts.filter(
    (context: any) =>
      !context.inactive &&
      (context.accountRoles.includes(USER_ROLE_EDITOR) ||
        context.accountRoles.includes(USER_ROLE_COMMISSIONER_VIEWER) ||
        context.accountRoles.includes(USER_ROLE_COMMISSIONER_EDITOR))
  );
});

export const compareRoleAdminAndAccount = memoizeOne((currentUserAdmin: any, userContact: any): number => {
  const adminRoleIndex = USER_ROLES.indexOf(currentUserAdmin.role);
  const contactRoleIndex = USER_ROLES.indexOf(userContact.role);
  return adminRoleIndex - contactRoleIndex;
});

export const isUserFinancial = memoizeOne((accountId: string, currentUser: UserDTO): boolean =>
  currentUser.userAccountContexts.some(
    (context: UserAccountContext) =>
      context.accountId === accountId && !context.inactive && context.contactType === CONTACT_FINANCE
  )
);

export const isMainContact = memoizeOne((accountId: string, currentUser: any): boolean => {
  if (isEmpty(currentUser)) return false;
  if (checkInternalUser(currentUser.role)) return true;
  if (!accountId || isEmpty(currentUser.userAccountContexts)) return false;
  return currentUser.userAccountContexts.some(
    context => context.accountId === accountId && !context.inactive && context?.contactType === CONTACT_TYPE.MAIN
  );
});

export const isSchoolContact = memoizeOne((accountId: string, currentUser: UserDTO): boolean => {
  if (isEmpty(currentUser)) return false;
  if (checkInternalUser(currentUser.role)) return true;
  if (!accountId || isEmpty(currentUser.userAccountContexts)) return false;
  return currentUser.userAccountContexts.some(context => context.accountId === accountId && !context.inactive);
});

export const getAvatarUrl = (imageName: string, email: string): string => {
  const endCodedEmail = stringToHex(email).toUpperCase();
  if (!isEmpty(imageName)) {
    return `https://${config.s3.BUCKET}.${config.s3.URL}/${
      config.s3.BASE_AVATAR_PATH
    }/${endCodedEmail}/${imageName}?ts=${new Date().getTime()}`;
  }

  return '';
};

export const getFileNameFromAvatarUrl = (avatarUrl: string | null | undefined): string =>
  (avatarUrl ?? '').split('/').pop()?.split('?')[0] ?? '';

// ******* Permissions *******
export const isEditor = (currentUser: UserDTO, accountId: string) => {
  const userAccountContext = currentUser.userAccountContexts.find(context => context.accountId === accountId);
  if (!userAccountContext || userAccountContext.inactive) {
    return false;
  }
  return (
    userAccountContext.accountRoles.includes(USER_ROLE_EDITOR) ||
    userAccountContext.accountRoles.includes(USER_ROLE_COMMISSIONER_VIEWER) ||
    userAccountContext.accountRoles.includes(USER_ROLE_COMMISSIONER_EDITOR)
  );
};

export const isViewableCommissioner = (currentUser: UserDTO, accountId: string) => {
  const userAccountContext = currentUser.userAccountContexts.find(context => context.accountId === accountId);
  if (!userAccountContext || userAccountContext.inactive) {
    return false;
  }
  return userAccountContext.accountRoles.includes(USER_ROLE_COMMISSIONER_VIEWER);
};

export const isEditableComissioner = (currentUser: UserDTO, accountId: string) => {
  const userAccountContext = currentUser.userAccountContexts.find(context => context.accountId === accountId);
  if (!userAccountContext || userAccountContext.inactive) {
    return false;
  }
  return userAccountContext.accountRoles.includes(USER_ROLE_COMMISSIONER_EDITOR);
};

export const isCoach = (currentUser: UserDTO, accountId: string) => {
  const userAccountContext = currentUser.userAccountContexts.find(context => context.accountId === accountId);
  if (!userAccountContext || userAccountContext.inactive) {
    return false;
  }
  return userAccountContext.accountRoles.includes(USER_ROLE_COACH);
};

export const isContactCoach = (currentUser: UserDTO, accountId: string) => {
  const userAccountContext = currentUser.userAccountContexts.find(context => context.accountId === accountId);
  if (!userAccountContext || userAccountContext.inactive) {
    return false;
  }
  return userAccountContext.accountRoles.includes(USER_ROLE_COACH) && userAccountContext.contactType === CONTACT_COACH;
};

export const getCoachInfo = (currentUser: UserDTO) =>
  currentUser.userAccountContexts
    .filter(item => item.accountRoles.includes(USER_ROLE_COACH))
    .map(item => item.accountId);

export const hasOtherRoleExcept = (currentUser: UserDTO, exceptRole: string[]) =>
  isInternalUser(currentUser) ||
  !isEmpty(currentUser.userAccountContexts.filter(item => item.accountRoles.some(role => !exceptRole.includes(role))));

export const isInternalUser = (user: UserDTO) => INTERNAL_USER_ROLES.includes(user.role);
// ! TODO: remove checkInternalUser in favor of isInternalUser
export const checkInternalUser = (role = '') => INTERNAL_USER_ROLES.includes(role);
export const checkSuperAdminUser = (role = '') => USER_ROLE_SUPER_ADMIN === role;
export const checkAdminRole = (role: string) => ADMIN_ROLES.includes(role);

export const canEdit = memoizeOne((accountId: string, currentUser: UserDTO): boolean => {
  if (isEmpty(currentUser)) return false;
  if (checkInternalUser(currentUser.role)) return true;
  if (!accountId || isEmpty(currentUser.userAccountContexts)) return false;
  return isEditor(currentUser, accountId);
});

export const canAccessAccount = memoizeOne((accountId: string, currentUser: any): boolean => {
  if (isEmpty(currentUser)) return false;
  if (checkInternalUser(currentUser.role)) return true;
  if (!accountId || isEmpty(currentUser.userAccountContexts)) return false;
  return currentUser.userAccountContexts.some(context => context.accountId === accountId && !context.inactive);
});

export const hasViewEventPermission = (user: UserDTO, event?: EventDTO) => {
  const accountIds = user.userAccountContexts
    .filter(context => context.inactive === false)
    .map(context => context.accountId);
  return event && (accountIds.includes(event.accountId) || accountIds.includes(event.financialAccountId || ''));
};

export const hasEditorEventPermission = (
  currentUser: UserDTO,
  event?: EventDTO,
  isWithoutFinancial?: boolean,
  eventPart?: string
) => {
  if (
    event?.awayGame &&
    ['Ignored', 'Declined'].includes(event?.state ?? '') &&
    !['Ticket'].includes(eventPart ?? '')
  ) {
    return canEdit(event?.opponentAccountId ?? '', currentUser) || isCoach(currentUser, event?.opponentAccountId ?? '');
  }

  if (isWithoutFinancial) {
    return canEdit(event?.accountId ?? '', currentUser);
  }

  return canEdit(event?.accountId ?? '', currentUser) || canEdit(event?.financialAccountId ?? '', currentUser);
};

export const canEditAccountByDistrictUser = ({
  currentUser,
  accountId,
  districtAccount,
  schoolsConfig
}: {
  currentUser: UserDTO;
  accountId: string;
  districtAccount?: AccountDTO;
  schoolsConfig?: SchoolConfig[];
}) => {
  if (isEmpty(currentUser) || isEmpty(accountId)) return false;
  if (checkInternalUser(currentUser.role) || canEdit(accountId, currentUser)) return true;

  const foundConfig = schoolsConfig?.find(item => `${item.schoolId}` === `${accountId}`);

  if (isEmpty(districtAccount) || isEmpty(foundConfig)) return false;

  return foundConfig?.districtAllowDistrictUser && canEdit(districtAccount.id, currentUser);
};

export const canEditEventByDistrictUser = ({
  event,
  currentUser,
  accountId,
  schoolsConfig,
  districtAccount
}: {
  event: EventDTO;
  currentUser: UserDTO;
  accountId?: string;
  districtAccount?: AccountDTO;
  schoolsConfig?: SchoolConfig[];
}) => {
  if (isEmpty(currentUser) || isEmpty(event)) return false;
  if (
    checkInternalUser(currentUser?.role) ||
    canEdit(event?.accountId ?? '', currentUser) ||
    canEdit(event?.financialAccountId ?? '', currentUser)
  ) {
    return true;
  }

  // Check if account is under district
  const foundConfig = schoolsConfig?.find(item => `${item.schoolId}` === `${accountId}`);
  if (isEmpty(districtAccount) || isEmpty(foundConfig)) return false;

  if (foundConfig?.districtAllowSchoolUser && `${event.financialAccountId}` === `${districtAccount?.id}`) {
    return true;
  }

  return foundConfig?.districtAllowDistrictUser && `${event.financialAccountId}` === `${accountId}`;
};

export const canAccessEventByDistrictUser = ({
  accountId,
  currentUser,
  schoolsConfig
}: {
  accountId?: string;
  currentUser: UserDTO;
  schoolsConfig?: SchoolConfig[];
}) => {
  if (isEmpty(currentUser)) return false;
  if (checkInternalUser(currentUser?.role) || canAccessAccount(accountId ?? '', currentUser)) {
    return true;
  }

  const found = schoolsConfig?.find(item => `${item.schoolId}` === `${accountId}`);
  if (isEmpty(found)) return false;

  return found?.districtAllowDistrictUser || found?.districtAllowSchoolUser;
};

export const canEditFinancial = memoizeOne((accountId: string, currentUser: UserDTO): boolean => {
  const allowEditRoles = ADMIN_ROLES.filter(role => role !== USER_ROLE_SYSTEM_ADMIN);
  if (isEmpty(currentUser)) return false;
  if (allowEditRoles.includes(currentUser.role)) return true;
  return currentUser.userAccountContexts.some(
    (context: UserAccountContext) =>
      context.accountId === accountId && !context.inactive && context.contactType === CONTACT_FINANCE
  );
});

export const canViewFinancialByRole = (currentUser: UserDTO): boolean => {
  const allowEditRoles = ADMIN_ROLES.filter(role => role !== USER_ROLE_SYSTEM_ADMIN);
  if (isEmpty(currentUser)) return false;
  if (allowEditRoles.includes(currentUser.role)) return true;
  return false;
};

export const canViewFinancialByUserAccount = (currentUser: UserDTO, accountId: string): boolean => {
  const userAccountContext = currentUser.userAccountContexts.find(context => context.accountId === accountId);
  if (!userAccountContext || userAccountContext.inactive) {
    return false;
  }
  return (
    userAccountContext.accountRoles.includes(USER_ROLE_EDITOR) ||
    userAccountContext.accountRoles.includes(USER_ROLE_BOXOFFICE) ||
    userAccountContext.accountRoles.includes(USER_ROLE_FINANCE) ||
    userAccountContext.accountRoles.includes(USER_ROLE_REPORTS) ||
    userAccountContext.accountRoles.includes(USER_ROLE_COMMISSIONER_VIEWER) ||
    userAccountContext.accountRoles.includes(USER_ROLE_COMMISSIONER_EDITOR)
  );
};

export const canViewSchoolInfoByUserAccount = (currentUser: UserDTO, accountId: string): boolean => {
  const userAccountContext = currentUser.userAccountContexts.find(context => context.accountId === accountId);
  if (!userAccountContext || userAccountContext.inactive) {
    return false;
  }
  return (
    userAccountContext.accountRoles.includes(USER_ROLE_EDITOR) ||
    userAccountContext.accountRoles.includes(USER_ROLE_BOXOFFICE) ||
    userAccountContext.accountRoles.includes(USER_ROLE_FINANCE) ||
    userAccountContext.accountRoles.includes(USER_ROLE_REPORTS) ||
    userAccountContext.accountRoles.includes(USER_ROLE_COMMISSIONER_VIEWER) ||
    userAccountContext.accountRoles.includes(USER_ROLE_COMMISSIONER_EDITOR)
  );
};

export const canEditUserAccountPermission = (currentUserAdmin: any, userContact: any, accountId: string): boolean => {
  if (userContact.role === USER_ROLE_NONE) {
    return false;
  }

  if (canEdit(accountId, currentUserAdmin)) {
    return true;
  }

  return false;
};

export const canEditContact = memoizeOne((currentUserAdmin: any, userContact: any, accountId: string): boolean => {
  const result = compareRoleAdminAndAccount(currentUserAdmin, userContact);
  if (result === 0 && currentUserAdmin.role === USER_ROLE_ACCOUNT_USER) {
    return canEditUserAccountPermission(currentUserAdmin, userContact, accountId);
  }

  return result <= 0;
});

export const canEditContactWithAllowedCommissioner = memoizeOne(
  (currentUserAdmin: any, userContact: any, accountId: string, isCommissionerEditor: boolean): boolean => {
    const result = compareRoleAdminAndAccount(currentUserAdmin, userContact);
    if (result === 0 && currentUserAdmin.role === USER_ROLE_ACCOUNT_USER) {
      return canEditUserAccountPermission(currentUserAdmin, userContact, accountId) || isCommissionerEditor;
    }

    return result <= 0;
  }
);

export const hasViewFanManagementPermission = (user: UserDTO, accountIds: string[] = [], conferenceAccount = '') => {
  const allowedAccountIds = user.userAccountContexts
    .filter(context => context.inactive === false)
    .map(context => context.accountId);
  return (
    accountIds.some(accountId => allowedAccountIds.includes(accountId ?? '')) ||
    isEditableComissioner(user, conferenceAccount) ||
    isViewableCommissioner(user, conferenceAccount)
  );
};

// ******* User Contacts *******

export const addContactToUser = (contact: UserAccountContext, user: UserDTO): UserDTO => {
  let updatedUserAccountContexts: UserAccountContext[] = [];
  if (isEmpty(user.userAccountContexts)) {
    updatedUserAccountContexts = [contact];
  } else if (
    user.userAccountContexts
      .map((context: UserAccountContext) => context.accountId)
      .every((id: string) => id !== contact.accountId)
  ) {
    updatedUserAccountContexts = [...user.userAccountContexts, contact];
  } else {
    updatedUserAccountContexts = user.userAccountContexts.reduce(
      (acc: UserAccountContext[], cur: UserAccountContext) => {
        if (cur.accountId === contact.accountId) {
          acc.push(contact);
        } else {
          acc.push(cur);
        }
        return acc;
      },
      []
    );
  }
  return {
    ...user,
    userAccountContexts: updatedUserAccountContexts
  };
};

export const removeContactFromUser = (user: UserDTO, accountId: string) => {
  const newUserAccountContexts = oc(user)
    .userAccountContexts([])
    .filter((context: UserAccountContext) => context.accountId !== accountId);
  return {
    ...user,
    userAccountContexts: newUserAccountContexts
  };
};

export function getContactType({ userAccountContexts }: UserDTO, accountId: string): ContactType {
  return (userAccountContexts.find(({ accountId: accId }) => accId === accountId)?.contactType ||
    CONTACT_OTHER) as ContactType;
}

export const canEditDistrictConferenceSettings = (currentUser: UserDTO, accountId: string): boolean => {
  if (isEmpty(currentUser)) return false;
  if (checkInternalUser(currentUser.role)) return true;

  const userAccountContext = currentUser.userAccountContexts.find(context => context.accountId === accountId);
  if (!userAccountContext || userAccountContext.inactive) {
    return false;
  }
  return (
    userAccountContext.accountRoles.includes(USER_ROLE_EDITOR) ||
    userAccountContext.accountRoles.includes(USER_ROLE_COMMISSIONER_EDITOR)
  );
};

export const hasEditFinancialRole = (accountId: string, currentUser: UserDTO): boolean => {
  const userAccountContext = currentUser.userAccountContexts.find(context => context.accountId === accountId);
  if (!userAccountContext || userAccountContext.inactive) {
    return false;
  }
  return userAccountContext.accountRoles.includes(EDIT_SCHOOL_FINANCIAL);
};
