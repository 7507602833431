import React, { useContext } from 'react';
import { FastFormContext } from './FastFormBase';

export default ({ formKey, children }) => {
  const fastFormContext = useContext(FastFormContext);
  const formHasKey = !!fastFormContext.form[formKey];
  return (
    <React.Fragment>
      {formHasKey ? (
        <React.Fragment>{children}</React.Fragment>
      ) : (
        <p>
          {`FastForm doesnt know how to render the input with key "${formKey}". There might be an error in the
          form initializer.`}
        </p>
      )}
    </React.Fragment>
  );
};
