import { ActionableNotification } from '@gofan/components';

import { PAGES } from '@app/config/routes';

interface EventBulkUploadNotificationProps {
  history: any;
  bulkUploadData: any;
  onClose: () => void;
}

export const EventBulkUploadNotification = ({ history, bulkUploadData, onClose }: EventBulkUploadNotificationProps) => {
  const { batchId, fileName, action } = bulkUploadData ?? {};
  return batchId ? (
    <ActionableNotification
      kind='info'
      className='event-bulk-upload-notification'
      statusIconDescription='notification'
      lowContrast
      inline
      title='Upload Processing'
      subtitle='Your recent upload is still processing'
      actionButtonLabel='View status'
      onActionButtonClick={() => {
        history.push(PAGES.eventsV2.bulkUploadEvents.calculatePath(batchId), { fileName, action });
      }}
      onCloseButtonClick={onClose}
    />
  ) : null;
};
