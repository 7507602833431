import React from 'react';
import { Breadcrumb as SemanticBreadCrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

export default function Breadcrumb({
  breadcrumbs = [],
  match = { params: {}, breadcrumbName: '' }
}) {
  // eslint-disable-next-line no-nested-ternary
  const data = _.isArray(breadcrumbs)
    ? breadcrumbs
    : _.isObject(breadcrumbs)
    ? breadcrumbs[match.breadcrumbName || '']
    : [];

  if (_.isEmpty(data)) return null;

  return (
    <SemanticBreadCrumb>
      {data.map(({ title, path }, index) => {
        const text = (
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: _.template(title)(match) }}
          />
        );

        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {index > 0 && <SemanticBreadCrumb.Divider />}
            <SemanticBreadCrumb.Section>
              {path ? <Link to={_.template(path)(match)}>{text}</Link> : text}
            </SemanticBreadCrumb.Section>
          </React.Fragment>
        );
      })}
    </SemanticBreadCrumb>
  );
}
