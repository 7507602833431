import axiosInstance from './axios';

export const HEALTH_CHECK = '/actuator/health';
export const SEARCH_PURCHASED_TICKETS_ENDPOINT = 'tickets/search';
export const REFUND_PURCHASED_TICKETS_ENDPOINT = '/api/payments/refund';
export const REFUND_BY_EVENT = '/api/payments/events/{eventId}/refund_tickets';
export const CREATE_USER = '/users/';
export const UPDATE_USER = '/users/{email}';
export const SEARCH_USER = '/users/search';
export const GET_BASIC_USER_BY_EMAIL = '/users/{email}/basic';
export const GET_USER_BY_EMAIL_TOKEN = '/users/?email_token={email_token}';
export const RESEND_INVITATION = '/users/{email}/message/new-user-invite?channel=Admin';
export const USER_HISTORY_URI = '/users/history';
export const ACCOUNT_HISTORY_URI = '/accounts/{id}/history?dateRange={dateRange}';
export const EVENT_HISTORY_URI = '/events/{id}/history?dateRange={dateRange}';
export const FETCH_EVENTS =
  '/events/search?expand=venue&expand=account-host&expand=account-opponent&expand=account&expand=event-type&expand=levels&expand=activity&sort=startDateTime,asc&skipCache=true';
export const FETCH_EVENT =
  '/events/{eventId}/?expand=account-host&expand=account-opponent&expand=account&expand=event-type&expand=levels&expand=event-sales-info&expand=activity';
export const FETCH_EVENT_REFUND = '/events/search/forRefund';
export const GET_EVENT = '/events/{id}';
export const GET_EVENT_BY_PARAMS = '/events/search';
export const CREATE_EVENT = '/events/';
export const UPDATE_EVENT = '/events/{id}';
export const UPDATE_EVENTS = '/events/update';
export const DELETE_EVENT = '/events/{id}';
export const GET_EVENT_SALES_INFO = '/events/{id}/sales-info';
export const GET_TICKETS_BY_EVENT_ID = '/tickets/event/{id}';
export const GET_TICKETS_BY_SEASON_ID = '/api/tickets/season/{id}';
export const GET_EVENT_SEATS_BY_STATUS = '/reserved-seating/?eventId={eventId}';
export const GET_SEASON_SEATS_BY_STATUS = '/reserved-seating/?seasonId={seasonId}';
export const GET_EVENT_UNAVAILABLE_SEATS = '/reserved-seating/unavailable/?eventId={eventId}';
export const GET_SEASON_UNAVAILABLE_SEATS = '/reserved-seating/season/unavailable/?seasonId={seasonId}';
export const EVENT_DAILY_SALES_INFO = '/api/events/daily-sales-info';
export const SALES_ALLOTMENT = '/api/events/{eventId}/sales-allotment';
export const CREATE_EVENT_TYPE = '/event-types/';
export const FETCH_ACCOUNTS = '/accounts/search';
export const FETCH_ACCOUNT_BY_ID = '/accounts/{id}';
export const FETCH_ACCOUNTS_BY_IDS = '/accounts/searchByIds'; // TODO: not use anywhere consider remove
export const FETCH_ACCOUNTS_PRODUCT = '/account-product/search';
export const FETCH_ACCOUNTS_BY_KEYWORD = '/accounts/search?keyword={keyword}';
export const FETCH_ACCOUNT_VENUES = 'accounts/{id}/venues';
export const FETCH_ACCOUNT_CONTACTS = '/accounts/contact/{id}';
export const LEVEL_URI = '/levels/';
export const ACTIVITY_URI = '/activities/';
export const RATE_URI = '/rates/';
export const GET_RATE_BY_ID_URI = '/rates/{id}';
export const PRODUCT_TYPES_URI = '/product-types/';
export const ACCOUNT_TICKETS_URI = '/account-product/list';
export const ACCOUNT_TICKET_URI = '/account-product/';
export const VENUE_URI = '/venues/{id}';
export const VENUE_SEATS_URI = '/venues/{id}/seats';
export const UPLOAD_EVENT = '/events/upload';
export const GET_SEASON_BY_ID = '/seasons/{id}';
export const UPDATE_SEASON = '/seasons/{id}';
export const SEARCH_SEASON_BY_IDS_V2 = '/v2/seasons/searchByIds';
export const UPDATE_SEASON_WITH_EVENT = '/seasons/{id}/withEvents';
export const CREATE_SEASON = '/seasons/withEvents';
export const CREATE_LIST_SEASON_WITH_EVENTS = '/seasons/listSeasonWithEvents';
export const RESERVED_SEAT_STATUS = '/reserved-seating/';
export const SEASON_RESERVED_SEAT_STATUS = '/reserved-seating/season';
export const RESERVED_SEAT_BLOCK = '/reserved-seating/block';
export const RESERVED_SEAT_UNBLOCK = '/reserved-seating/unblock';
export const RESERVED_SEATING_CHART = '/api/seatsio/chart/{chartKey}';
export const TICKETS_PATCH_BOOKED_SEAT = '/tickets/{ticketId}/seat';
export const TICKETS_DELETE_BOOKED_SEAT = '/tickets/{ticketId}/seat';
export const ORDERS_COMP_TICKETS = '/orders/compticket';
export const SEARCH_SEASONS = '/seasons/search';
export const GET_USER_URI = '/users/{email}';
export const UPDATE_ACCOUNT_BY_ID = '/accounts/{accountId}';
export const FETCH_ACCOUNT_FINANCIAL_BY_ACCOUNT_ID = '/account-financial/?accountId={accountId}';
export const UPDATE_ACCOUNT_FINANCIAL_BY_ID = '/account-financial/{id}';
export const CREATE_ACCOUNT_FINANCIAL = '/account-financial/';
export const CREATE_COMPTICKET = '/orders/compticket';
export const CREATE_SEASON_COMPTICKET = '/orders/';
export const GET_TICKET_TYPES_BY_ID = '/tickets/types/{id}';
export const FETCH_PURCHASED_TICKET = '/tickets/fan/search';
export const FETCH_PURCHASED_SEASON_TICKET = '/tickets/season/search';
export const UNREDEEM_TICKET = '/orders/tickets/{accessToken}';
export const UPDATE_PURCHASED_TICKET = '/tickets/{accessToken}';
export const EXPORT_CSV = '/jobs/fan-tickets/export/';
export const EXPORT_SEASON_CSV = '/jobs/season-tickets/export/';
export const DOWNLOAD_CSV = '/s3bucket/download/?{fileName}';
export const PATCH_UNREDEEM_TICKET = '/tickets/unredeem';
export const UNREDEEM_EVENT = '/jobs/event/unredeem';
export const FETCH_JOB_BY_ID = '/jobs/{id}';
export const FINANCIAL_HISTORY_URI = '/account-financial/{id}/history?dateRange={dateRange}';
export const FETCH_GLOBAL_EVENTS = '/global-events/search';
export const EVENT_SCHEDULES_SEARCH = '/global-events/schedule/search';
export const EVENT_MONITOR_INDICATOR = '/global-events/schedule/available';
export const PATCH_GLOBAL_EVENT = '/global-events/{id}';
export const GET_GLOBAL_EVENT_PARTNER_INSIGHT = '/global-events/partner/insight';
export const GET_AWAY_EVENT_INSIGHT = '/events/away-game/insight';

export const IGNORE_SCHEDULE = '/global-events/schedule/ignore';
export const RESTORE_SCHEDULE = '/global-events/schedule/restore';
export const GET_EVENT_PARTNER_SALES_INFO = '/events/partner/sales-info';
export const CHECK_DUPLICATE_EVENT = '/events/check-duplicate';
export const FIND_ACCOUNT_BY_IDS = '/accounts/searchByIds'; // TODO: not use anywhere consider remove
export const GET_TICKET_SALES_INSIGHT_DATA = '/api/events/{eventId}/ticket-distribution';
export const GET_EVENT_TOTAL_TICKETS = 'events/{id}/total_tickets';
export const GET_TICKET_SALE_REPORT =
  '/api/events/{schoolId}/{periodStart}/{periodEnd}/{format}/{statementType}/sale-report';
export const PLAYOFF_CALENDAR_SEARCH_URI = '/playoff/search';
export const SEARCH_EVENTS_BY_IDS_V2 = '/v2/events/searchByIds';
export const GET_CRM_SCHOOL_BY_ID = '/crm/schools/{id}';
export const GET_SEATS_IO_WORKSPACE = '/accounts/{id}/keys';
export const GET_ACCOUNT_METRICS = '/api/account-metrics';
export const GET_PAYMENT_STATEMENT = '/api/financial/{financialSchoolId}/payment-statements';
export const GET_PAYMENT_STATEMENT_FILES_BY_ID = '/api/financial/payment-statements/{id}/files';
export const GET_PAYMENT_EVENTS = '/api/financial/payment-events/';
export const GET_PAYMENT_STATEMENT_FILES_BY_IDS = '/api/financial/payment-statements/files';
export const SEND_REFUND_REQUEST = '/api/financial/fan-refund';
export const GET_PAYMENT_EVENTS_FILES_BY_ID = '/api/financial/payment-events/{id}/files';
export const CANCEL_EVENT = '/events/cancel';
export const RESCHEDULE_EVENT = '/events/postpone ';
export const SEARCH_ADJUSTMENTS = '/api/adjustment/search';
export const CREATE_ADJUSTMENT = '/api/adjustment';
export const GET_ADJUSTMENT_BY_ID = '/api/adjustment/{id}';
export const APPROVE_ADJUSTMENT = '/api/adjustment/approve';
export const DELETE_ADJUSTMENT = '/api/adjustment/delete';
export const GET_PAYMENT_SEASONS = '/api/financial/payment-seasons/';
export const GET_PAYMENT_SEASONS_FILES_BY_ID = '/api/financial/payment-seasons/{id}/files';
export const SEARCH_NEGATIVE_CARRYFORWARD = '/api/negative-carry/search';
export const GET_NEGATIVE_CARRYFORWARD_DETAIL = '/api/negative-carry/details';
export const SETTLEMENT_ACH_CONVERT_CSV = '/api/settlement-engine/ach/convert-csv-file';
export const SETTLEMENT_CHECK_CONVERT_CSV = '/api/settlement-engine/check/convert-csv-file';
export const SEARCH_PAYOUT_REMITTANCES = '/api/payout-remittances/search';
export const CREATE_REISSUANCE_REQUEST = '/api/payout-remittances/reissuances';
export const CREATE_CANCELATION_REQUEST = '/api/payout-remittances/cancelations';
export const GET_SEASON_RENEWALS_BY_SEASON_ID = '/seasonRenewal?seasonId={seasonId}';
export const SEARCH_PURCHASED_SEASON_PASS_TICKETS = '/tickets/season-passes/search';
export const SEARCH_PAYOUT_SUMMARY = '/api/payouts/summary';
export const GET_FLATFILE_TOKEN = '/api/flatfile/token';
export const GET_UPLOAD_DATA = '/api/flatfile/uploaded/search';
export const BULK_UPLOAD_EVENTS = '/api/flatfile/bulk-upload/{bucket}';
export const STATUS_BULK_UPLOAD_EVENTS = '/api/flatfile/bulk-upload/{batchId}/status';
export const FALLOUT_GET_PNR = '/api/fallout/pnr';
export const GET_REJECTED_PAYOUTS = '/api/payouts/rejected';
export const UPDATE_PAYOUT = '/api/payouts/approval';
export const PENDING_PAYOUT_DETAILS = '/api/payouts/details';
export const PENDING_PAYOUT_APPROVAL_PROCESS = '/api/settlement-engine/pending-payouts/details';
export const DOWNLOAD_SETTLEMENT_REPORTS = '/api/reporting/summary/details';
export const PENDING_REISSUANCES_SEARCH = '/api/payout-remittances/reissuances/search';
export const PENDING_REISSUANCES_PROCESS = '/api/payout-remittances/reissuances/process';
export const CANCELATIONS_SEARCH = '/api/payout-remittances/cancelations/search';
export const CANCELATIONS_PROCESS = '/api/payout-remittances/cancelations/process';
export const GET_REJECTED_PAYOUTS_HISTORIES = '/api/payouts/historical/{payoutId}';
export const GET_SEASON_TICKET_INSIGHT = '/api/insights/events';
export const ORDER_REFUND_MISSING_CHARGES = '/api/order-refund/missing-charges';
export const ORDER_REFUND_MISSING_REFUNDS = '/api/order-refund/missing-refunds';
export const ORDER_REFUND_MISMATCHED_CHARGES = '/api/order-refund/mismatch-charges';
export const ORDER_REFUND_MISMATCHED_REFUNDS = '/api/order-refund/mismatch-refunds';
export const GET_BALANCES_NET = '/api/balances/net';
export const GET_BALANCES_PAID = '/api/balances/paid';
export const GET_BALANCES_UNPAID = '/api/balances/unpaid';
export const EVENT_SEASON_CONFIG_UPDATE = '/account-financial/event-season';
export const EVENT_SEASON_CONFIG_SEARCH = '/account-financial/event-season/search';
export const REFUND_REQUEST_ORDER_SERVICE = '/api/refund-requests';
export const REFUND_REQUEST_DELETE_ORDER_SERVICE = '/api/refund-requests/{refundRequestId}';
export const REISSUANCES_ACCOUNT_DETAILS = '/api/reissuances/account-details';

export const GET_TOURNAMENTS_BY_ACCOUNT_ID = '/api/tournaments/?accountId={accountId}';
export const GET_DIVISIONS_BY_HUDDLE_ID = '/api/divisions-classifications?huddleId.equals={huddleId}';
export const CREATE_DIVISION_CLASSIFICATION = '/api/divisions-classifications';
export const CREATE_TOURNAMENT = '/api/tournaments';
export const GET_TOURNAMENTS = '/api/tournaments?accountId.equals={accountId}';
export const GET_TOURNAMENT = '/api/tournaments/{tournamentId}';
export const GET_ALL_DIVISION_ROUNDS = '/api/tournaments/{tournamentId}/divisions/{divisionId}/rounds';

// -------------------------------------------------//
// API for Users
//
// -------------------------------------------------//
export const getUser = (emailId, manualErrorHandling) => {
  const email = encodeURIComponent(emailId);
  const uri = `users/${email}?ts=${new Date().getTime()}`;
  return axiosInstance.huddleAxiosInstanceProxy.get(uri, null, manualErrorHandling);
};

export const searchUser = email => axiosInstance.huddleAxiosInstanceProxy.post(SEARCH_USER, { email });

export const putUser = user => {
  const email = encodeURIComponent(user.email);
  const uri = `users/${email}`;
  return axiosInstance.huddleAxiosInstanceProxy.put(uri, user);
};
export const postUser = user => {
  const uri = 'users/';
  return axiosInstance.huddleAxiosInstanceProxy.post(uri, user);
};
// placeholder promise
export const searchUsers = (searchBody, sortBody, paginationBody) => {
  /* const newSearchBody = {
    ...searchBody,
    includePlaceholders: false //no fakes please (wait for server to be ready)
  }; */
  let queryPart = '';
  if (sortBody && sortBody.column) {
    queryPart += `?sort=${sortBody.column}`;
    queryPart = sortBody.direction === 'descending' ? `${queryPart},desc` : queryPart;
  }
  if (paginationBody && paginationBody.pageNumber) {
    queryPart = queryPart.length > 0 ? `${queryPart}&` : `${queryPart}?`;
    queryPart += `page=${paginationBody.pageNumber}`;
  }
  if (paginationBody && paginationBody.size) {
    queryPart = queryPart.length > 0 ? `${queryPart}&` : `${queryPart}?`;
    queryPart += `size=${paginationBody.size}`;
  }
  let uri = 'users/search';
  uri = queryPart.length > 0 ? uri + queryPart : uri;
  return axiosInstance.huddleAxiosInstanceProxy.post(uri, searchBody);
};
export const resendUserInvite = emailId => {
  const email = encodeURIComponent(emailId);
  const uri = `users/${email}/message/new-user-invite`;
  return axiosInstance.huddleAxiosInstanceProxy.post(uri, {});
};

// -------------------------------------------------//
// API for Accounts
//
// -------------------------------------------------//
export const getAccount = (id, fields = '') => {
  const uri = `accounts/${id}${fields}${fields === '' ? '?' : '&'}skipCache=true`;
  return axiosInstance.huddleAxiosInstanceProxy.get(uri);
};
export const putAccount = acct => {
  const uri = `accounts/${acct.id}`;
  return axiosInstance.huddleAxiosInstanceProxy.put(uri, acct);
};
export const postAccount = acct => {
  const uri = 'accounts/';
  return axiosInstance.huddleAxiosInstanceProxy.post(uri, acct);
};

export const quicksearchAccounts = (keyWords = '') => {
  const uri = `accounts/search?keyword=${keyWords}&latitude=34.0008&longitude=-84.01`;
  return axiosInstance.huddleAxiosInstanceProxy.get(uri);
};
export const searchAccounts = (searchBody, sortBody, paginationBody) => {
  let queryPart = '';
  if (sortBody && sortBody.column) {
    queryPart += `?sort=${sortBody.column}`;
    queryPart = sortBody.direction === 'descending' ? `${queryPart},desc` : queryPart;
  }
  if (paginationBody && paginationBody.pageNumber) {
    queryPart = queryPart.length > 0 ? `${queryPart}&` : `${queryPart}?`;
    queryPart += `page=${paginationBody.pageNumber}`;
  }
  if (paginationBody && paginationBody.size) {
    queryPart = queryPart.length > 0 ? `${queryPart}&` : `${queryPart}?`;
    queryPart += `size=${paginationBody.size}`;
  }
  let uri = 'accounts/search';
  uri = queryPart.length > 0 ? uri + queryPart : uri;
  return axiosInstance.huddleAxiosInstanceProxy.post(uri, searchBody);
};

export const getWorkspace = id => {
  const uri = `accounts/${id}/keys`;
  return axiosInstance.huddleAxiosInstanceProxy.get(uri);
};

// -------------------------------------------------//
// API for Account Roles
//
// -------------------------------------------------//
export const getAccountRolesForUser = email => {
  const uri = `account-roles/?email=${encodeURIComponent(email)}`;
  return axiosInstance.huddleAxiosInstanceProxy.get(uri);
};
export const deleteAccountRole = role => {
  const uri = `account-roles/?email=${role.userEmail}&accountId=${role.accountId}&role=${role.role}`;
  return axiosInstance.huddleAxiosInstanceProxy.delete(uri);
};
export const postAccountRole = role => {
  const uri = 'account-roles/';
  return axiosInstance.huddleAxiosInstanceProxy.post(uri, role);
};
export const getAccountRolesForAccount = accountId => {
  const uri = `account-roles/?account=${accountId}`;
  return axiosInstance.huddleAxiosInstanceProxy.get(uri);
};

// -------------------------------------------------//
// API for Account Roles
//
// -------------------------------------------------//

export const getActivityTree = () => {
  const uri = 'account-activities/';
  return axiosInstance.huddleAxiosInstanceProxy.get(uri);
};

export const postActivityTree = tree => {
  const uri = 'account-activities/';
  return axiosInstance.huddleAxiosInstanceProxy.post(uri, tree);
};
