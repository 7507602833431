import { Search } from '@carbon/react';

import { useEffect, useRef, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { AccountQuery, sortSchoolsByName } from '@gofan/api';

import { DISTRICT_CONFERENCE_SCHOOL_TYPES } from '@gofan/constants';
import type { SearchProps } from '@carbon/react';

import './SearchDropdown.scss';

export interface DistrictConfig {
  renderDistrict?: boolean;
  allowFetchAssociatedSchools?: boolean;
  defaultDistrictHuddleId?: string;
  isInternalAdmin?: boolean;
  allowSchoolNameAsSearchValue?: boolean;
}
interface SearchDropdownProps extends SearchProps {
  // TODO - fix any types
  items: any[];
  itemToString?: (item: any) => string;
  itemToElement?: (item: any) => React.ReactElement;
  loading?: boolean;
  invalid?: boolean;
  invalidText?: string;
  onSearch?: (keyword: string) => void;
  onSelect: (item: any) => void;
  districtConfig?: DistrictConfig;
}

export const SearchDropdown = ({
  items,
  itemToString,
  itemToElement,
  loading,
  invalid,
  invalidText,
  onSelect,
  onSearch,
  id = new Date().getTime().toString(),
  value,
  districtConfig: {
    renderDistrict = false,
    allowFetchAssociatedSchools = false,
    defaultDistrictHuddleId = '',
    isInternalAdmin = false,
    allowSchoolNameAsSearchValue = false
  } = {},
  ...props
}: SearchDropdownProps) => {
  const [open, setOpen] = useState(false);
  const searchInputRef = useRef<any>();
  const [districtHuddleId, setDistrictHuddleId] = useState<string>(defaultDistrictHuddleId);
  // Manage value of search component internally
  // to avoid the issue of the value will be delay(due to using debounce onSearch) to being updated when the user input search keyword.
  const [searchValue, setSearchValue] = useState<string>('');

  const { data: associatedSchools = [], isInitialLoading: schoolLoading } = AccountQuery.getAccountSchoolsByDistrict({
    districtHuddleId,
    queryOptions: {
      enabled: allowFetchAssociatedSchools && !isEmpty(districtHuddleId),
      refetchOnWindowFocus: false
    }
  });

  const debouncedOnSearch = useCallback(
    debounce((keyword: string) => {
      if (typeof onSearch === 'function') {
        onSearch(keyword);
      }
    }, 500),
    [onSearch]
  );

  useEffect(() => {
    document.addEventListener('click', _handleClickOutside, true);
    return () => {
      document.removeEventListener('click', _handleClickOutside, true);
    };
  }, []);
  const isLoading = schoolLoading || loading;

  return (
    <div id={id} className='search-dropdown-container'>
      <Search
        {...props}
        className={invalid ? 'cds--search--invalid' : ''}
        ref={searchInputRef}
        onClick={_onClick}
        onClear={_onClose}
        onChange={e => _onChange(e?.target?.value)}
        value={searchValue}
      />
      {invalidText && (
        <div className='search-dropdown-invalid-text cds--form-requirement gs--text-error'>{invalidText}</div>
      )}
      {open && (
        <div className='search-dropdown'>
          {isLoading && value && (
            <div className='dropdown-item-wrapper'>
              <div className='dropdown-item gs--body-short-01 gs--font-family-sf gs--text-01'>Searching...</div>
            </div>
          )}
          {!isLoading && _renderItems()}
        </div>
      )}
    </div>
  );

  function _generateChildren(item: any) {
    const renderString = (obj: any) => (typeof itemToString === 'function' ? itemToString(obj) : obj);
    return typeof itemToElement === 'function' ? itemToElement(item) : renderString(item);
  }

  function _onClose() {
    setOpen(false);
  }

  function _handleClickOutside(evt: any) {
    if (!document) return;
    const insightEle = document.getElementById(id);
    let targetElement = evt.target; // clicked element
    do {
      // clicked inside
      if (!document.contains(targetElement) || targetElement === insightEle) return;
      targetElement = targetElement.parentNode;
    } while (targetElement);
    // clicked outside
    _onClose();
  }

  function _onClick() {
    if (renderDistrict || items?.length) {
      setOpen(true);
    }
  }

  function _onChange(keyword: string) {
    setOpen(true);
    setSearchValue(keyword);
    debouncedOnSearch(keyword);
  }

  function _onSelect(item: any) {
    setOpen(false);
    if (
      renderDistrict &&
      isInternalAdmin &&
      item?.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT
    ) {
      setDistrictHuddleId(item?.id);
    }
    if (typeof onSelect === 'function') onSelect(item);
    if (item?.name && allowSchoolNameAsSearchValue) setSearchValue(item?.name);
  }

  function _renderItems() {
    const foundInSchoolsUnderDistrict = associatedSchools.filter(
      school => school.name.toLowerCase() === searchValue.toLowerCase()
    );
    if (!items?.length && !isEmpty(searchValue) && foundInSchoolsUnderDistrict.length === 0) {
      return (
        <div className='dropdown-item-wrapper'>
          <div className='dropdown-item gs--body-short-01 gs--font-family-sf gs--text--01 empty-item'>
            <div>No result found.</div>
            <div className='gs--text-02'>Try again using a different term.</div>
          </div>
        </div>
      );
    }
    if (renderDistrict) {
      let schoolsUnderDistrict: any[] = [];
      let otherSchools: any[] = [];
      // Show suggested list of schools under the district as default when user click into search input.
      if (isEmpty(searchValue)) {
        schoolsUnderDistrict = sortSchoolsByName(associatedSchools);
      } else {
        // When user input keyword, show the list of schools that match the keyword:
        // 1. the list of schools under the district label: 'Schools in Your Districts'
        // 2. the list of schools that match the keyword label: 'Search result'
        const schoolIds = new Set<string>(associatedSchools.map(school => school.id));
        schoolsUnderDistrict = sortSchoolsByName(items.filter(school => schoolIds.has(school.id)));
        otherSchools = sortSchoolsByName(items.filter(school => !schoolIds.has(school.id)));
        if (schoolsUnderDistrict.length === 0 && foundInSchoolsUnderDistrict.length > 0) {
          schoolsUnderDistrict = foundInSchoolsUnderDistrict;
        }
      }

      return (
        <>
          {schoolsUnderDistrict.length > 0 && (
            <div className='dropdown-item-wrapper'>
              <div className='dropdown-item gs--body-short-01 gs--font-family-sf gs--text-01 text-bold'>
                Schools in Your Districts
              </div>
            </div>
          )}
          {schoolsUnderDistrict.map(item => (
            <button onClick={() => _onSelect(item)} className='dropdown-item-wrapper' type='button' key={item.id}>
              <div className='dropdown-item gs--body-short-01 gs--font-family-sf gs-text-01'>
                {_generateChildren(item)}
              </div>
            </button>
          ))}
          {otherSchools.length > 0 && (
            <div className='dropdown-item-wrapper'>
              <div className='dropdown-item gs--body-short-01 gs--font-family-sf gs--text-01 text-bold'>
                Search result
              </div>
            </div>
          )}
          {otherSchools.map(item => (
            <button onClick={() => _onSelect(item)} className='dropdown-item-wrapper' type='button' key={item?.id}>
              <div className='dropdown-item gs--body-short-01 gs--font-family-sf gs-text-01'>
                {_generateChildren(item)}
              </div>
            </button>
          ))}
        </>
      );
    }
    return items?.map(item => (
      <button onClick={() => _onSelect(item)} className='dropdown-item-wrapper' type='button' key={item.id}>
        <div className='dropdown-item gs--body-short-01 gs--font-family-sf gs-text-01'>{_generateChildren(item)}</div>
      </button>
    ));
  }
};
