import AccountTicketDAO from '../dao/AccountTicketDAO';

export const fetchTickets = (
  searchTicketsRequest,
  expands,
  pageRequest,
  sortableRequest
) => AccountTicketDAO.fetchTickets(
  searchTicketsRequest,
  expands,
  pageRequest,
  sortableRequest
);

export const createAccountTickets = body => AccountTicketDAO.createAccountTickets(body);

export const editAccountTicket = body => AccountTicketDAO.editAccountTicket(body.id, body);

export const getAccountTicket = id => AccountTicketDAO.getAccountTicket(id);

export default {
  fetchTickets,
  getAccountTicket,
  createAccountTickets,
  editAccountTicket
};
