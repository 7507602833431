import { getUniqueId } from '@utils/objectUtils';

export const DistributionChannel = {
  GoFan: 'GoFan',
  BoxOffice: 'BoxOffice'
};

export const ACCOUNT_TICKET_TYPE_STATUS = {
  ACTIVE: 'Active',
  IN_ACTIVE: 'Inactive'
};

export const PRODUCT_TYPE = {
  TICKET: 'TICKET'
};

export interface RawProduct {
  accountId?: string;
  customColor?: string;
  distributionChannel?: string;
  groupId?: string;
  enabled?: boolean;
  encodedString?: string;
  eventId?: number;
  fee?: number;
  formId?: number;
  generateLink?: boolean;
  hiddenFeeBase?: number;
  hiddenFees?: boolean;
  id: number;
  limit?: number;
  name?: string;
  packCount?: number;
  price?: number | string;
  productType?: string;
  promotionLabel?: string;
  promotionRequired?: boolean;
  rateId?: number;
  redemptionLimit?: number;
  reservedSeating?: boolean;
  salesEndDateTime?: string;
  salesStartDateTime?: string;
  seasonId?: number;
  seatsIoCategory?: string;
  salesStartDate?: string;
  salesStartTime?: string;
  salesEndDate?: string;
  salesEndTime?: string;
  ticketLimitPerOrder?: number;

  // additional fields
  created?: boolean;
}

export default class RawProductModel {
  contents: RawProduct;

  defaultValues = {
    id: getUniqueId()
  };

  constructor(contents?: any) {
    this.contents = { ...contents } as RawProduct;
  }

  toJSON() {
    return { ...this.defaultValues, ...this.contents };
  }

  setByAccountTicketType(ticketType: any) {
    this.contents = {
      ...this.contents,
      distributionChannel: ticketType.distributionChannel ?? DistributionChannel.GoFan,
      groupId: ticketType.groupId,
      enabled: `${ticketType.status}`.toLowerCase() === ACCOUNT_TICKET_TYPE_STATUS.ACTIVE.toLowerCase(),
      productType: PRODUCT_TYPE.TICKET, // [TODO][Should mapping with AccountProductDTO.productTypeId]
      name: ticketType.ticketName,
      price: ticketType.ticketPrice,
      customColor: ticketType.customColor,
      hiddenFees: ticketType.accountPaid,
      reservedSeating: ticketType.reservedSeating,
      seatsIoCategory: ticketType.seatsIoCategory
    };

    return this;
  }

  setHiddenFees(hiddenFees: boolean) {
    this.contents = {
      ...this.contents,
      hiddenFees
    };
    return this;
  }

  setPackCount(ticketType: any) {
    if (ticketType?.distributionChannel === DistributionChannel.GoFan) {
      this.contents = {
        ...this.contents,
        packCount: ticketType?.packCount ?? null
      };
    }
    return this;
  }

  setOptionalSetting(ticketType: any) {
    this.contents = {
      ...this.contents,
      salesStartDate: ticketType.salesStartDate,
      salesStartTime: ticketType.salesStartTime,
      salesEndDate: ticketType.salesEndDate,
      salesEndTime: ticketType.salesEndTime,
      ticketLimitPerOrder: ticketType.ticketLimitPerOrder,
      fee: ticketType.fee
    };
    return this;
  }
}
