import { Rate } from '../../api/types';

export const RESET_STATE = '@rate/RESET_STATE';
export const SEARCH_ACCOUNTS = '@rate/SEARCH_ACCOUNTS';
export const SEARCH_ACCOUNTS_COMPLETED = '@rate/SEARCH_ACCOUNTS_COMPLETED';

export const GET_RATE_BY_ID = '@rate/GET_RATE_BY_ID';
export const GET_RATE_BY_ID_SUCCESSFUL = '@rate/GET_RATE_BY_ID_SUCCESSFUL';
export const GET_RATE_BY_ID_FAIL = '@rate/GET_RATE_BY_ID_FAIL';
export const GET_RATE_BY_ID_COMPLETED = '@rate/GET_RATE_BY_ID_COMPLETED';

export const UPDATE_RATE = '@rate/UPDATE_RATE';
export const UPDATE_RATE_SUCCESSFUL = '@rate/UPDATE_RATE_SUCCESSFUL';
export const UPDATE_RATE_FAIL = '@rate/UPDATE_RATE_FAIL';
export const UPDATE_RATE_COMPLETED = '@rate/UPDATE_RATE_COMPLETED';

export const CREATE_RATE = '@rate/CREATE_RATE';
export const CREATE_RATE_SUCCESSFUL = '@rate/CREATE_RATE_SUCCESSFUL';
export const CREATE_RATE_FAIL = '@rate/CREATE_RATE_FAIL';
export const CREATE_RATE_COMPLETED = '@rate/CREATE_RATE_COMPLETED';

export const FETCH_PRODUCT_TYPES = '@rate/FETCH_PRODUCT_TYPES';
export const FETCH_PRODUCT_TYPES_SUCCESSFUL = '@rate/FETCH_PRODUCT_TYPES_SUCCESSFUL';
export const FETCH_PRODUCT_TYPES_COMPLETED = '@rate/FETCH_PRODUCT_TYPES_COMPLETED';
export const FETCH_PRODUCT_TYPES_FAIL = '@rate/FETCH_PRODUCT_TYPES_FAIL';

export const INITIAL_DATA = '@rate/INITIAL_DATA';
export const INITIAL_DATA_COMPLETED = '@rate/INITIAL_DATA_COMPLETED';

interface ResetStateAction {
  type: typeof RESET_STATE;
}

interface SearchAccountsAction {
  type: typeof SEARCH_ACCOUNTS;
  payload: {
    keyword: string;
  };
}

interface SearchAccountsCompletedAction {
  type: typeof SEARCH_ACCOUNTS_COMPLETED;
  payload: {
    accounts: [];
  };
}

interface FetchRateByIdAction {
  type: typeof GET_RATE_BY_ID;
  payload: {
    id: string;
    expands: [];
    history: any;
  };
}

interface FetchRateByIdCompletedAction {
  type: typeof GET_RATE_BY_ID_COMPLETED;
  payload: {
    rate: Rate;
  };
}

interface FetchRateByIdSuccessfulAction {
  type: typeof GET_RATE_BY_ID_SUCCESSFUL;
  payload: {
    rate: Rate;
  };
}

interface FetchRateByIdFailAction {
  type: typeof GET_RATE_BY_ID_FAIL;
}

interface FetchProductTypesFailAction {
  type: typeof FETCH_PRODUCT_TYPES_FAIL;
}

interface FetchProductTypesCompletedAction {
  type: typeof FETCH_PRODUCT_TYPES_COMPLETED;
  payload: {
    productTypes: [];
  };
}
interface FetchProductTypesSuccessfulAction {
  type: typeof FETCH_PRODUCT_TYPES_SUCCESSFUL;
  payload: {
    productTypes: [];
  };
}
interface FetchProductTypesAction {
  type: typeof FETCH_PRODUCT_TYPES;
}
interface UpdateRateAction {
  type: typeof UPDATE_RATE;
  payload: {
    rate: Rate;
    history: {};
  };
}
interface UpdateRateCompletedAction {
  type: typeof UPDATE_RATE_COMPLETED;
  payload: {
    rate: Rate;
    history: {};
  };
}
interface UpdateRateSuccessfulAction {
  type: typeof UPDATE_RATE_SUCCESSFUL;
  payload: {
    rate: Rate;
  };
}
interface UpdateRateFailAction {
  type: typeof UPDATE_RATE_FAIL;
}

interface CreateRateAction {
  type: typeof CREATE_RATE;
  payload: {
    rate: Rate;
    history: {};
  };
}
interface CreateRateCompletedAction {
  type: typeof CREATE_RATE_COMPLETED;
  payload: {
    rate: Rate;
  };
}
interface CreateRateSuccessfulAction {
  type: typeof CREATE_RATE_SUCCESSFUL;
  payload: {
    rate: Rate;
  };
}
interface CreateRateFailAction {
  type: typeof CREATE_RATE_FAIL;
}
interface InitialDataAction {
  type: typeof INITIAL_DATA;
  payload: {
    id: string;
    expands: [];
    history: any;
    editMode: boolean;
  };
}
interface InitialDataCompletedAction {
  type: typeof INITIAL_DATA_COMPLETED;
  payload: {
    productTypes: [];
    rate: Rate;
  };
}

export type RateInformationActionTypes =
  | SearchAccountsAction
  | SearchAccountsCompletedAction
  | ResetStateAction
  | FetchRateByIdAction
  | FetchRateByIdCompletedAction
  | FetchRateByIdSuccessfulAction
  | FetchRateByIdFailAction
  | FetchProductTypesAction
  | FetchProductTypesSuccessfulAction
  | FetchProductTypesCompletedAction
  | FetchProductTypesFailAction
  | UpdateRateAction
  | UpdateRateSuccessfulAction
  | UpdateRateCompletedAction
  | UpdateRateFailAction
  | CreateRateAction
  | CreateRateSuccessfulAction
  | CreateRateCompletedAction
  | CreateRateFailAction
  | InitialDataAction
  | InitialDataCompletedAction;
