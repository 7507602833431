// @flow

class AccountDTO {
  constructor({
    id,
    name,
    customPageName,
    boxOfficeCustomer,
    cashless,
    gofanSchoolType,
    headerImage,
    logo,
    latitude,
    longitude,
    primaryColor,
    state,
    stateAssociationHuddleId,
    stateAssociationId,
    stateAssociationName,
    status,
    streetAddress,
    facebookUrl,
    twitterUrl,
    city,
    zip,
    mascot,
    shortName,
    scanEnabled,
    searchEnabled,
    timeZone,
    venueIds,
    seatsIoPublicWorkspaceKey,
    venues,
    paymentCycle,
    siteDomain,
    conferenceLeagueC,
    districtHuddleId,
    sfId
  }) {
    this.raw = {
      id,
      name,
      customPageName,
      boxOfficeCustomer,
      cashless,
      gofanSchoolType,
      headerImage,
      logo,
      latitude,
      longitude,
      primaryColor,
      facebookUrl,
      twitterUrl,
      zip,
      streetAddress,
      state,
      stateAssociationHuddleId,
      stateAssociationId,
      stateAssociationName,
      status,
      city,
      mascot,
      shortName,
      scanEnabled,
      searchEnabled,
      timeZone,
      venueIds,
      seatsIoPublicWorkspaceKey,
      venues,
      paymentCycle,
      siteDomain,
      conferenceLeagueC,
      districtHuddleId,
      sfId
    };
  }

  get id() {
    return this.raw.id;
  }

  get name() {
    return this.raw.name;
  }

  get customPageName() {
    return this.raw.customPageName;
  }

  get boxOfficeCustomer() {
    return this.raw.boxOfficeCustomer;
  }

  get cashless() {
    return this.raw.cashless;
  }

  get gofanSchoolType() {
    return this.raw.gofanSchoolType;
  }

  get headerImage() {
    return this.raw.headerImage;
  }

  get logo() {
    return this.raw.logo;
  }

  get latitude() {
    return this.raw.latitude;
  }

  get longitude() {
    return this.raw.longitude;
  }

  get primaryColor() {
    return this.raw.primaryColor;
  }

  get state() {
    return this.raw.state;
  }

  get stateAssociationHuddleId() {
    return this.raw.stateAssociationHuddleId;
  }

  get stateAssociationId() {
    return this.raw.stateAssociationId;
  }

  get stateAssociationName() {
    return this.raw.stateAssociationName;
  }

  get status() {
    return this.raw.status;
  }

  get streetAddress() {
    return this.raw.streetAddress;
  }

  get facebookUrl() {
    return this.raw.facebookUrl;
  }

  get twitterUrl() {
    return this.raw.twitterUrl;
  }

  get city() {
    return this.raw.city;
  }

  get zip() {
    return this.raw.zip;
  }

  get mascot() {
    return this.raw.mascot;
  }

  get shortName() {
    return this.raw.shortName;
  }

  get searchEnabled() {
    return this.raw.searchEnabled;
  }

  get scanEnabled() {
    return this.raw.scanEnabled;
  }

  get venueIds() {
    return this.raw.venueIds;
  }

  get venues() {
    return this.raw.venues;
  }

  get timeZone() {
    return this.raw.timeZone;
  }

  get paymentCycle() {
    return this.raw.paymentCycle;
  }

  get seatsIoPublicWorkspaceKey() {
    return this.raw.seatsIoPublicWorkspaceKey;
  }

  get siteDomain() {
    return this.raw.siteDomain;
  }

  get conferenceLeagueC() {
    return this.raw.conferenceLeagueC;
  }

  get districtHuddleId() {
    return this.raw.districtHuddleId;
  }

  get sfId() {
    return this.raw.sfId;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      customPageName: this.customPageName,
      boxOfficeCustomer: this.boxOfficeCustomer,
      cashless: this.cashless,
      gofanSchoolType: this.gofanSchoolType,
      headerImage: this.headerImage,
      logo: this.logo,
      latitude: this.latitude,
      longitude: this.longitude,
      primaryColor: this.primaryColor,
      paymentCycle: this.paymentCycle,
      state: this.state,
      stateAssociationHuddleId: this.stateAssociationHuddleId,
      stateAssociationId: this.stateAssociationId,
      stateAssociationName: this.stateAssociationName,
      status: this.status,
      scanEnabled: this.scanEnabled,
      streetAddress: this.streetAddress,
      facebookUrl: this.facebookUrl,
      twitterUrl: this.twitterUrl,
      city: this.city,
      zip: this.zip,
      mascot: this.mascot,
      shortName: this.shortName,
      searchEnabled: this.searchEnabled,
      venueIds: this.venueIds,
      seatsIoPublicWorkspaceKey: this.seatsIoPublicWorkspaceKey,
      venues: this.venues,
      timeZone: this.timeZone,
      siteDomain: this.siteDomain,
      conferenceLeagueC: this.conferenceLeagueC,
      districtHuddleId: this.districtHuddleId,
      sfId: this.sfId
    };
  }
}

export default AccountDTO;
