import BaseModel from '../BaseModel';

class SearchTicketRequest extends BaseModel {
  constructor({ chargeId, orderId, eventId, email, last4 }) {
    super({ chargeId, orderId, eventId, email, last4 });
  }

  get chargeId() {
    return this.raw.chargeId || undefined;
  }

  get orderId() {
    return Number.parseInt(this.raw.orderId, 10) || undefined;
  }

  get eventId() {
    return this.raw.eventId || undefined;
  }

  get email() {
    return this.raw.email || undefined;
  }

  get last4() {
    return this.raw.last4 || undefined;
  }

  toJSON() {
    return {
      chargeId: this.chargeId,
      orderId: this.orderId,
      eventId: this.eventId,
      email: this.email,
      last4: this.last4
    };
  }
}

export default SearchTicketRequest;
