// @flow
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import * as types from './actionTypes.ts';
import * as actionCreator from './actions.ts';
import Rate from '../../api/model/Rate';
import PaginationDTO from '../../api/dto/PaginationDTO';
import PageRequest from '../../api/model/request/PageRequest';
import PaginationModel from '../../api/model/PaginationModel';
import SortableRequest from '../../api/model/request/SortableRequest';
import SearchRatesRequest from '../../api/model/request/SearchRatesRequest';
import { fetchRates } from '../../api/services/RateService';

export function* searchRates({
  keyword,
  expands,
  pageSize,
  pageIndex,
  sortBy
}) {
  const pageRequest = new PageRequest({
    pageSize,
    pageIndex
  });
  const sortableRequest = new SortableRequest({
    sortBy
  });
  const searchRatesRequest = new SearchRatesRequest({
    keyword,
    expands
  });
  const response = yield call(fetchRates, {
    searchRatesRequest,
    pageRequest,
    sortableRequest
  });

  let searchResult = new PaginationModel({}).toJSON();
  if (response instanceof PaginationDTO) {
    const { data, pageSize: pSize, pageNumber, totalPages } = response.toJSON();
    searchResult = new PaginationModel({
      pageSize: pSize,
      pageIndex: pageNumber,
      pageCount: totalPages,
      data: data.map(rate => new Rate(rate.toJSON()).toJSON())
    }).toJSON();
  }

  return searchResult;
}

export function* handleSearchRates(action) {
  let searchResult = new PaginationModel({}).toJSON();
  try {
    const { searchParams } = action.payload;
    const { rate } = yield select();
    searchResult = yield call(searchRates, searchParams);
    if (rate.rateRecently && !isEmpty(rate.rateRecently)) {
      if (searchResult.data instanceof Array) {
        const index = searchResult.data.findIndex(
          r => r.id === rate.rateRecently.id
        );

        if (index !== -1) {
          searchResult.data.splice(index, 1);
        }
        searchResult.data = [rate.rateRecently, ...searchResult.data];
      }
    }

    yield put(actionCreator.searchRatesSuccess(searchResult));
  } catch (error) {
    yield put(actionCreator.searchRatesSuccess(searchResult));
  }
}

export function* watchSearchRatesRequest() {
  yield takeLatest(types.SEARCH_RATES, handleSearchRates);
}

export default [watchSearchRatesRequest()];
