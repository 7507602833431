// @flow

class EventRefundDTO {
  constructor({
    allDayEvent,
    endDate,
    eventDate,
    eventId,
    eventName,
    financialAccountId,
    financialAccountName,
    startDate,
    timeZone,
    totalRefundedAmount,
    totalRefundedTicket,
    totalTicketSold,
    totalTicketsRefundable
  }) {
    this.raw = {
      allDayEvent,
      endDate,
      eventDate,
      eventId,
      eventName,
      financialAccountId,
      financialAccountName,
      startDate,
      timeZone,
      totalRefundedAmount,
      totalRefundedTicket,
      totalTicketSold,
      totalTicketsRefundable
    };
  }

  get allDayEvent() {
    return this.raw.allDayEvent;
  }

  get endDate() {
    return this.raw.endDate;
  }

  get eventDate() {
    return this.raw.eventDate;
  }

  get eventId() {
    return this.raw.eventId;
  }

  get eventName() {
    return this.raw.eventName;
  }

  get financialAccountId() {
    return this.raw.financialAccountId;
  }

  get financialAccountName() {
    return this.raw.financialAccountName;
  }

  get startDate() {
    return this.raw.startDate;
  }

  get timeZone() {
    return this.raw.timeZone;
  }

  get totalRefundedAmount() {
    return this.raw.totalRefundedAmount;
  }

  get totalRefundedTicket() {
    return this.raw.totalRefundedTicket;
  }

  get totalTicketSold() {
    return this.raw.totalTicketSold;
  }

  get totalTicketsRefundable() {
    return this.raw.totalTicketsRefundable;
  }

  toJSON() {
    return {
      allDayEvent: this.allDayEvent,
      endDate: this.endDate,
      eventDate: this.eventDate,
      eventId: this.eventId,
      eventName: this.eventName,
      financialAccountId: this.financialAccountId,
      financialAccountName: this.financialAccountName,
      startDate: this.startDate,
      timeZone: this.timeZone,
      totalRefundedAmount: this.totalRefundedAmount,
      totalRefundedTicket: this.totalRefundedTicket,
      totalTicketSold: this.totalTicketSold,
      totalTicketsRefundable: this.totalTicketsRefundable
    };
  }
}

export default EventRefundDTO;
