import { css } from 'styled-components';
import { SOURCESANPRO_SEMIBOLD } from '../../../../assets/styles/common';

export default {
  activityIcon: css`
    position: absolute;
    color: #666;
    top: 50%;
    transform: translateY(-50%);

    &.caret {
      width: 20px;
      height: 25px;
      margin-left: -4px;
    }

    &.circle {
      font-size: 0.5em;
      width: 20px;
      height: 12px;
      margin-left: -4px;
    }
  `,
  activityLabel: css`
    display: flex;
    width: 100%;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 16px;
    word-break: break-word;
    z-index: 1;

    ${({ level }) => level === 0 && SOURCESANPRO_SEMIBOLD};
  `,
  activityWrapper: css`
    position: relative;
    cursor: pointer;
    margin-bottom: 16px;
    margin-left: ${({ level }) => (level ? `${level * 20}` : '0')}px;
  `,
  checkBoxWrapper: css`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  `
};
