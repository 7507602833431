import React, { useState, useMemo, useEffect } from 'react';
import { isEqual } from 'lodash';
import { DataTable, TableContainer, Table as CarbonTable, TableToolbarContent } from 'carbon-components-react';
import styled from 'styled-components';
import TableBody from '../TableBody';
import TableHeader from '../TableHeader';
import TableToolbar from '../TableToolbar';
import { getSortBy } from '../../sorting';
import { isEmpty } from '../../../../../../utils/objectUtils';
import styles from './styles';
import { Pagination } from '@gofan/components';

const TableWrapper = styled.div`
  ${styles.tableWrapper}
`;

function Table({
  rowData,
  schema,
  extraData,
  toolbar,
  hasToolbar,
  sortBy,
  hasSorting,
  overflowMenu,
  hasOverflowMenu,
  selectingRows,
  sortRow,
  onHeaderClick,
  onCellItemClick,
  selectedRowIds,
  onSelectRow,
  onSelectAll,
  selectableRowCount,
  allDisableSelectedRowIds,
  haveCheckbox,
  hasPagination
}) {
  const sortByRef = React.useRef(sortBy);
  const prevSortByRef = React.useRef(sortBy);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const tableSchema = useMemo(
    () => (isEmpty(schema) ? {} : schema.reduce((res, item) => ({ ...res, [item.key]: item }), {})),
    schema
  );

  useEffect(() => {
    if (!isEqual(sortBy, prevSortByRef.current)) {
      prevSortByRef.current = sortBy;
      if ((isEmpty(sortBy) && !isEmpty(sortByRef.current)) || !isEqual(sortBy, sortByRef.current)) {
        sortByRef.current = sortBy;
      }
    }
  }, [sortBy, sortByRef.current]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowData]);

  const handleHeaderClick = React.useCallback(
    ({ sortHeaderKey, sortDirection }) => {
      sortByRef.current = getSortBy(sortHeaderKey, sortDirection);
      onHeaderClick({ sortHeaderKey, sortDirection });
    },
    [onHeaderClick, sortByRef.current]
  );

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = size => {
    setItemsPerPage(size);
    setCurrentPage(1);
  };

  return (
    <TableWrapper className='gs--custom-table'>
      <DataTable rows={rowData} headers={schema} sortRow={sortRow} isSortable={hasSorting} overflowMenuOnHover={false}>
        {props => {
          const startIndex = (currentPage - 1) * itemsPerPage;
          const endIndex = startIndex + itemsPerPage;
          const currentRows = props.rows.slice(startIndex, endIndex);
          return (
            <TableContainer {...props.getTableContainerProps()}>
              {hasToolbar ? (
                <TableToolbarContent>
                  <TableToolbar toolbar={toolbar} rowData={rowData} getToolbarProps={props.getToolbarProps} />
                </TableToolbarContent>
              ) : null}

              <CarbonTable {...props.getTableProps()}>
                <TableHeader
                  sortBy={sortByRef.current}
                  headers={props.headers}
                  hasSorting={hasSorting}
                  hasOverflowMenu={hasOverflowMenu}
                  onHeaderClick={handleHeaderClick}
                  getHeaderProps={props.getHeaderProps}
                  onSelectAll={onSelectAll}
                  selectedRowIds={selectedRowIds}
                  selectableRowCount={selectableRowCount}
                  getSelectionProps={props.getSelectionProps}
                  haveCheckbox={haveCheckbox}
                />
                <TableBody
                  rows={hasPagination ? currentRows : props.rows}
                  rowData={rowData}
                  extraData={extraData}
                  tableSchema={tableSchema}
                  getRowProps={props.getRowProps}
                  selectingRows={selectingRows}
                  overflowMenu={overflowMenu}
                  hasOverflowMenu={hasOverflowMenu}
                  onCellItemClick={onCellItemClick}
                  getSelectionProps={props.getSelectionProps}
                  onSelectRow={onSelectRow}
                  selectedRowIds={selectedRowIds}
                  allDisableSelectedRowIds={allDisableSelectedRowIds}
                  haveCheckbox={haveCheckbox}
                />
              </CarbonTable>
              {hasPagination && rowData.length !== 0 && (
                <Pagination
                  totalItems={rowData.length}
                  pageSize={itemsPerPage}
                  currentPage={currentPage}
                  pageSizes={[10, 25, 50, 100]}
                  onChange={({ page, pageSize }) => {
                    handlePageChange(page);
                    if (pageSize !== itemsPerPage) {
                      handlePageSizeChange(pageSize);
                    }
                  }}
                  className='gf--sponsorship-assignment-table-pagination'
                />
              )}
            </TableContainer>
          );
        }}
      </DataTable>
    </TableWrapper>
  );
}

Table.defaultProps = {
  sortBy: {},
  schema: [],
  rowData: [],
  extraData: {},
  selectingRows: [],
  hasSorting: false,
  hasToolbar: false,
  hasPagination: false,
  hasOverflowMenu: false,
  sortRow: () => 0,
  toolbar: () => null,
  overflowMenu: () => null,
  onHeaderClick: () => null,
  onCellItemClick: () => null
};

export default Table;
