import { TextInput, TextInputProps } from 'carbon-components-react';
import { isEmpty } from 'lodash';

export type BasicTextInputConfig = {
  label: string;
};

export type BasicTextInputProps = TextInputProps &
  BasicTextInputConfig & {
    name: string;
    control: any;
    initialValue: string;
    fieldValue: string;
    fieldError?: string;
    getFieldRef?: () => void;
  };

const BasicTextInput = ({
  name,
  label = '',
  className,
  maxLength,
  initialValue,
  fieldValue,
  fieldError,
  getFieldRef,
  control,
  ...other
}: BasicTextInputProps) => (
  <TextInput
    {...other}
    {...control}
    id={`field-${name}`}
    ref={getFieldRef}
    className={className}
    labelText={label}
    maxLength={maxLength}
    defaultValue={initialValue}
    invalidText={fieldError}
    invalid={!isEmpty(fieldError)}
  />
);

export default BasicTextInput;
