import { InlineLoading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@gofan/components';
import classNames from 'classnames';
import { CREATE_PRACTICE_SETTINGS_HEADER } from '@practice-schedule/constants';
import { SECTIONS } from '@streaming/constants/editor.constant';

import './EditorSettings.scss';

export interface EditorSetting {
  id: string;
  label: string;
  render: () => React.ReactNode;
}

export interface SettingSection {
  title: string;
  editorSettings: EditorSetting[];
}

interface EditorSettingsProps {
  header: string;
  isPracticeSchedule: boolean;
  sections: SettingSection[];
  selectedTab: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}

export const EditorSettings = ({
  header,
  sections,
  isPracticeSchedule = false,
  selectedTab,
  setSelectedTab
}: EditorSettingsProps) => {
  const headerText = isPracticeSchedule
    ? CREATE_PRACTICE_SETTINGS_HEADER
    : `${sections.some(section => section?.editorSettings?.length) ? header : 'Your updates'}`;
  return (
    <div className='editor-settings'>
      <div
        className={classNames('gs--font-family-sf', {
          'gs--productive-heading-03': !isPracticeSchedule,
          'gs--productive-heading-03-semibold': isPracticeSchedule || !(sections.length > 1)
        })}
      >
        {headerText}
      </div>
      <div className='editor-settings__list gs--margin-top-sp5'>
        <Tabs selectedIndex={selectedTab} onChange={({ selectedIndex }) => setSelectedTab(selectedIndex)}>
          {!isPracticeSchedule && sections.length > 1 && (
            <TabList aria-label='editor-settings'>
              {sections.map(section => (
                <Tab key={section.title} className='gs--body-short-01 gs--font-family-sf'>
                  {section.title}
                </Tab>
              ))}
            </TabList>
          )}
          <TabPanels>
            {sections.map(section => (
              <TabPanel key={section.title} id={section.title}>
                {section.editorSettings.length > 0 ? (
                  section.editorSettings.map(setting => (
                    <div key={setting.id} className='editor-settings__item'>
                      {SECTIONS.EVENT_TYPE_SECTION !== setting.id && (
                        <div className='gs--productive-heading-01 gs--font-family-sf gs--margin-bottom-sp2'>
                          {setting.label}
                        </div>
                      )}
                      <div className='gs--body-short-01 gs--font-family-sf'>{setting.render()}</div>
                      <div className='editor-settings__item--divider' />
                    </div>
                  ))
                ) : (
                  <div className='editor-settings__empty'>
                    <div>
                      <InlineLoading
                        className='gs--font-family-sf'
                        status='active'
                        description={`Generating ${header?.toLowerCase()}`}
                      />
                    </div>
                  </div>
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};
