/* eslint-disable no-unused-vars */
import * as yup from 'yup';
import moment from 'moment';
import { get, isEmpty, mapValues, isNumber, isFinite } from 'lodash';

import type { AccountProductDTO } from '@gofan/api/account-products';
import { ALPHANUMERIC_SPECIAL_CHARACTERS_REGEX, timeAMPM } from '@common/validations';
import { REGX_TIME_WITH_COLON, REGX_TIME_WITHOUT_COLON } from '@app/pages/SeasonSetup/constants';
import { GL_CODE_REGEX } from '@gofan/utils';
import { GLCODE_CONSTANTS } from '@components/GLCode/constants';

import strings from '@season-management/constants/strings';
import SeasonCreationUtil from '@season-management/utils/season-creation.utils';
import {
  GENDER_TYPE,
  GENDER_LABEL,
  CREATION_MODE,
  IMPORT_DATA_MODE,
  EVENT_START_TIME_TYPE
} from '@season-management/constants/constant';
import {
  DATE_PICKER_FORMAT_DEFAULT,
  TIME_PICKER_PATTERN,
  DATE_TIME_PICKER_FORMAT_DEFAULT
} from '@gofan/constants/date';

import type { RawEvent } from '@season-management/middleware/models/raw-event.model';
import type { RawSeason } from '@season-management/middleware/models/raw-season.model';

export const SHORT_TIME_REGEX = /^(0?[1-9]|1[0-2]):([0-5]\d)\s?((?:A|P)\.?M\.?)$/;

export const checkNumber = (num: any) => isNumber(num) && isFinite(num);

export const someIsNotNumber = (num: any[]) => num?.some(n => !checkNumber(parseFloat(n ?? '0')));

export const checkValidTime = (value: any) => {
  if (isEmpty(value)) return false;
  const time = `${value}`.split(':') ?? [];
  const colonSymbolCounter = time.length - 1;

  if (colonSymbolCounter !== 1) return false;

  const hours = time?.[0]?.split('') ?? ['0'];
  const minutes = time?.[1]?.split('') ?? ['0'];
  return !someIsNotNumber(hours) && !someIsNotNumber(minutes);
};

export const validTime = (time: string) =>
  timeAMPM(time) || REGX_TIME_WITHOUT_COLON.test(time) || REGX_TIME_WITH_COLON.test(time);

export const checkDateAfter = (date?: any, checkedDate?: any) => {
  const mDate = moment(date);
  const mCheckedDate = moment(checkedDate);

  if (!mDate.isValid() || !mCheckedDate.isValid()) return false;
  return (
    mDate.isAfter(mCheckedDate, 'year') ||
    mDate.isAfter(mCheckedDate, 'month') ||
    mDate.isAfter(mCheckedDate, 'day') ||
    mDate.isAfter(mCheckedDate, 'hour') ||
    mDate.isAfter(mCheckedDate, 'minute')
  );
};

export const onPasteNumberInput = (e: React.ClipboardEvent<HTMLInputElement>, onChange?: Function) => {
  const clipboardValue = e?.clipboardData?.getData('text');
  const numOfEvent = parseFloat(clipboardValue);
  let preventValue = '';

  if (clipboardValue) {
    if (!checkNumber(numOfEvent) || numOfEvent <= 0) {
      preventValue = '1';
    } else if (numOfEvent > 100) {
      preventValue = '100';
    } else {
      preventValue = `${numOfEvent}`;
    }

    if (preventValue) {
      if (typeof onChange === 'function') onChange(preventValue);
      e?.preventDefault();
    }
  }
};

export const preventNumberInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const key = `${e.key}`.toLowerCase();
  const isAllowedKey =
    key === 'tab' || key === 'enter' || key === 'backspace' || key === 'arrowleft' || key === 'arrowright';
  const isMetaKey = e.metaKey || isAllowedKey;
  const isAllowedMetaKey = isAllowedKey || key === 'a' || key === 'c' || key === 'x' || key === 'v' || key === 'z';
  const isUnAllowedKey =
    e.key === '.' || e.key === ',' || e.key === '+' || e.key === '-' || !checkNumber(parseFloat(e.key));

  if ((isMetaKey && !isAllowedMetaKey) || (!isMetaKey && isUnAllowedKey)) {
    e.preventDefault();
  }
};

// --General setup step--
export const seasonNameSchemaBuilder = () =>
  yup
    .string()
    .required(strings.ERRORS_MESSAGE.SEASON_NAME.REQUIRED)
    .min(3, strings.ERRORS_MESSAGE.ALPHANUMERIC_SPECIAL_CHARACTERS)
    .max(100, strings.ERRORS_MESSAGE.ALPHANUMERIC_SPECIAL_CHARACTERS)
    .matches(ALPHANUMERIC_SPECIAL_CHARACTERS_REGEX, {
      message: strings.ERRORS_MESSAGE.ALPHANUMERIC_SPECIAL_CHARACTERS,
      excludeEmptyStrings: true
    });

export const levelsByGenderSchemaBuilder = () =>
  yup.object().when(['genders'], (...depValues: [string[]]) => {
    const [genders] = depValues;
    return yup.lazy(obj =>
      yup.object(
        mapValues(obj, (value, gender) =>
          yup.array().test('levels', '', (_, testContext) => {
            let errorMessage = strings.ERRORS_MESSAGE.TEAM.COED_LEVEL_REQUIRED;
            if (gender === GENDER_TYPE.BOYS) {
              errorMessage = strings.ERRORS_MESSAGE.TEAM.BOY_LEVEL_REQUIRED;
            } else if (gender === GENDER_TYPE.GIRLS) {
              errorMessage = strings.ERRORS_MESSAGE.TEAM.GIRL_LEVEL_REQUIRED;
            }

            return !genders.includes(gender) || !isEmpty(value)
              ? true
              : testContext.createError({
                  path: testContext.path,
                  message: errorMessage
                });
          })
        )
      )
    );
  });

export const accountTicketTypeSchemaBuilder = () =>
  yup.array().test('invalidAccountTicketTypes', 'ticketTypesByGroup is required', (value, testContext) => {
    const { id, genders, levelsByGender, creationMode } = testContext.parent;
    const ticketTypes = SeasonCreationUtil.filterAccountTicketTypesBy({
      genders,
      levelsByGender,
      accountTicketTypes: value as AccountProductDTO[]
    });
    const ticketTypesByGroup = SeasonCreationUtil.groupAccountTicketTypesBy({
      sportId: id,
      genders,
      ticketTypes,
      creationMode,
      levelsByGender
    });

    if (creationMode === CREATION_MODE.SPERATE_EACH_TEAM) {
      return ticketTypesByGroup.every((item: any) => !isEmpty(item.ticketTypes));
    }
    return ticketTypesByGroup.some((item: any) => !isEmpty(item.ticketTypes));
  });

export const manualSettingSchemaBuilder = () =>
  yup
    .object()
    .when(['id', 'modeImportData', 'eventStartTimeType', 'genders', 'levelsByGender'], (...depValues: any) => {
      const [id, modeImportData, eventStartTimeType, genders, levelsByGender] = depValues;
      return yup.object().shape({
        numOfEvent: yup
          .string()
          .nullable()
          .min(1, strings.ERRORS_MESSAGE.NUM_OF_EVENTS.REQUIRED)
          .test('invalidManualSettings.numOfEvent', strings.ERRORS_MESSAGE.NUM_OF_EVENTS.MIN, (value, testContext) => {
            if (modeImportData !== IMPORT_DATA_MODE.MANUAL) return true;
            if (value === '' || value === null || typeof value === 'undefined') {
              return testContext.createError({
                path: testContext.path,
                message: strings.ERRORS_MESSAGE.NUM_OF_EVENTS.REQUIRED
              });
            }
            const numOfEvent = parseFloat(value);
            return checkNumber(numOfEvent) && numOfEvent >= 1 && numOfEvent <= 100;
          }),
        startTime: yup.lazy(obj =>
          yup.object(
            mapValues(obj, (_, startTimeId) =>
              yup.object().test('invalidManualSettings.StartTime', '', (startTime, testContext) => {
                const match = SeasonCreationUtil.matchStartTimeId({
                  startTimeId,
                  sportId: id,
                  genders,
                  levelsByGender,
                  eventStartTimeType
                });
                if (!match || modeImportData !== IMPORT_DATA_MODE.MANUAL) return true;

                let errorMessage = '';
                if (isEmpty(startTime?.time)) {
                  if (eventStartTimeType === EVENT_START_TIME_TYPE.ALL_TEAMS) {
                    errorMessage = strings.ERRORS_MESSAGE.GENERAL_START_TIME.REQUIRED;
                  } else {
                    const splittedId = startTimeId.split('-');
                    const gender = splittedId?.[1] ?? '';
                    const levelId = splittedId?.[2] ?? '';
                    const foundLevel = levelsByGender?.[gender]?.find((level: any) => `${level.id}` === `${levelId}`);
                    errorMessage = strings.ERRORS_MESSAGE.GENERAL_START_TIME.EACH_TEAM_REQUIRED.replace(
                      '{gender}',
                      get(GENDER_LABEL, gender) ?? ''
                    ).replace('{levelName}', foundLevel?.name ?? '');
                  }
                }

                if (isEmpty(errorMessage) && !checkValidTime(startTime?.time)) {
                  errorMessage = strings.ERRORS_MESSAGE.GENERAL_START_TIME.INVALID;
                }

                return isEmpty(errorMessage)
                  ? true
                  : testContext.createError({
                      path: testContext.path,
                      message: errorMessage
                    });
              })
            )
          )
        )
      });
    });

export const fileUploadSettingSchemaBuilder = () =>
  yup.object().test('invalidFileUploadSetting', '', (fileUploadSetting, testContext) => {
    const { modeImportData } = testContext.parent;
    let errorMessage = '';

    if (modeImportData !== IMPORT_DATA_MODE.FILE_UPLOAD) return true;

    if (isEmpty(fileUploadSetting)) {
      errorMessage = strings.ERRORS_MESSAGE.GENERAL_FILE_UPLOAD_SETTING.REQUIRED;
    }

    if (!SeasonCreationUtil.checkFileUploadSetting({ ...testContext.parent, fileUploadSetting })) {
      errorMessage = strings.ERRORS_MESSAGE.GENERAL_FILE_UPLOAD_SETTING.INVALID;
    }

    return isEmpty(errorMessage)
      ? true
      : testContext.createError({
          path: testContext.path,
          message: errorMessage
        });
  });

// --Event setup step--
export const eventNameSchemaBuilder = () =>
  yup.string().when('withoutOpponentAccount', {
    is: true,
    then: schema =>
      schema
        .required(strings.ERRORS_MESSAGE.EVENT_NAME.REQUIRED)
        .min(3, strings.ERRORS_MESSAGE.ALPHANUMERIC_SPECIAL_CHARACTERS)
        .max(100, strings.ERRORS_MESSAGE.ALPHANUMERIC_SPECIAL_CHARACTERS)
        .matches(ALPHANUMERIC_SPECIAL_CHARACTERS_REGEX, {
          message: strings.ERRORS_MESSAGE.ALPHANUMERIC_SPECIAL_CHARACTERS,
          excludeEmptyStrings: true
        })
  });

export const accountOpponentSchemaBuilder = () =>
  yup
    .object()
    .nullable()
    .shape({})
    .when('withoutOpponentAccount', {
      is: false,
      then: schema => schema.required(strings.ERRORS_MESSAGE.SCHOOL_OPPONENT.REQUIRED)
    });

export const eventStartTimeSchemaBuilder = () =>
  yup.object().test('invalidEventStartTime', '', (value, testContext) => {
    const { defaultValue } = testContext.options.context ?? {};
    const { id: sportId, rawSeasons } = defaultValue ?? {};
    const { id: eventId, activityId, created, startDateTime } = testContext.parent;
    let errorMessage = '';

    if (isEmpty(value?.time)) {
      errorMessage = strings.ERRORS_MESSAGE.EVENT_START_TIME.REQUIRED;
    }

    if (isEmpty(errorMessage) && !checkValidTime(value?.time)) {
      errorMessage = strings.ERRORS_MESSAGE.EVENT_START_TIME.INVALID;
    }

    let foundEvent: RawEvent | undefined;
    if (created && `${activityId}` === `${sportId}`) {
      (rawSeasons ?? []).some((rawSeason: RawSeason) => {
        foundEvent = (rawSeason.rawEvents ?? []).find(event => `${event.id}` === `${eventId}`);
        return !isEmpty(foundEvent);
      });
    }

    if (isEmpty(errorMessage)) {
      const now = moment();
      const date = moment(startDateTime);
      if (
        isEmpty(errorMessage) &&
        date.isValid() &&
        (now.isAfter(date, 'year') ||
          now.isAfter(date, 'month') ||
          now.isAfter(date, 'day') ||
          now.isAfter(date, 'hour') ||
          now.isAfter(date, 'minute'))
      ) {
        const dafaultStartDateTime = moment(foundEvent?.startDateTime);

        if (
          !created ||
          isEmpty(foundEvent) ||
          !dafaultStartDateTime.isValid() ||
          dafaultStartDateTime.isAfter(date, 'year') ||
          dafaultStartDateTime.isAfter(date, 'month') ||
          dafaultStartDateTime.isAfter(date, 'day') ||
          dafaultStartDateTime.isAfter(date, 'hour') ||
          dafaultStartDateTime.isAfter(date, 'minute')
        ) {
          errorMessage = strings.ERRORS_MESSAGE.EVENT_START_TIME.INVALID;
        }
      }
    }

    return isEmpty(errorMessage)
      ? true
      : testContext.createError({
          path: testContext.path,
          message: errorMessage
        });
  });

export const eventStartDateTimeSchemaBuilder = () =>
  yup.string().test('invalidEventStartDateTime', '', (startDateTime, testContext) => {
    const { defaultValue } = testContext.options.context ?? {};
    const { id: sportId, rawSeasons } = defaultValue ?? {};
    const { id: eventId, activityId, startTime, created } = testContext.parent;
    let errorMessage = '';

    if (isEmpty(startDateTime)) {
      errorMessage = strings.ERRORS_MESSAGE.EVENT_START_DATE_TIME.REQUIRED;
    }

    let foundEvent: RawEvent | undefined;
    if (created && `${activityId}` === `${sportId}`) {
      (rawSeasons ?? []).some((rawSeason: RawSeason) => {
        foundEvent = (rawSeason.rawEvents ?? []).find(event => `${event.id}` === `${eventId}`);
        return !isEmpty(foundEvent);
      });
    }

    if (isEmpty(errorMessage) && !isEmpty(startTime) && checkValidTime(startTime?.time)) {
      const now = moment();
      const date = moment(startDateTime);

      if (!date.isValid()) {
        errorMessage = strings.ERRORS_MESSAGE.EVENT_START_DATE_TIME.INVALID;
      } else if (now.isAfter(date, 'year') || now.isAfter(date, 'month') || now.isAfter(date, 'day')) {
        const dafaultStartDateTime = moment(foundEvent?.startDateTime);

        if (
          !created ||
          isEmpty(foundEvent) ||
          !dafaultStartDateTime.isValid() ||
          dafaultStartDateTime.isAfter(date, 'year') ||
          dafaultStartDateTime.isAfter(date, 'month') ||
          dafaultStartDateTime.isAfter(date, 'day')
        ) {
          errorMessage = strings.ERRORS_MESSAGE.EVENT_START_DATE_TIME.INVALID;
        }
      }
    }

    return isEmpty(errorMessage)
      ? true
      : testContext.createError({
          path: testContext.path,
          message: errorMessage
        });
  });

export const rawEventsSchemaBuilder = () =>
  yup
    .array(
      yup.lazy(obj =>
        yup.object().shape(
          !obj.deleted
            ? {
                id: yup.string(),
                name: eventNameSchemaBuilder(),
                created: yup.boolean(),
                withoutOpponentAccount: yup.boolean(),
                opponentAccount: accountOpponentSchemaBuilder(),
                startTime: eventStartTimeSchemaBuilder(),
                startDateTime: eventStartDateTimeSchemaBuilder()
              }
            : {}
        )
      )
    )
    .test('invalidRawEvents', '', (rawEvents, testContext) => {
      const { id } = testContext.parent;
      return isEmpty(rawEvents)
        ? testContext.createError({
            path: testContext.path,
            message: `${id} - rawEvents is required`
          })
        : true;
    });

export const publishDateSchemaBuilder = () =>
  yup.string().when('archived', archived => {
    if (!archived) {
      return yup.string();
    }

    return yup.string().test('invalidPublishDate', (publishDate, testContext) => {
      const { publishTime } = testContext.parent ?? {};
      if (!publishDate && !publishTime) {
        return true;
      }

      if (!publishDate) {
        return testContext.createError({
          path: testContext.path,
          message: strings.ERRORS_MESSAGE.PUBLISH_DATE.REQUIRED
        });
      }

      const mPublishDate = moment(publishDate, DATE_PICKER_FORMAT_DEFAULT);
      if (!mPublishDate.isValid()) {
        return testContext.createError({
          path: testContext.path,
          message: strings.ERRORS_MESSAGE.PUBLISH_DATE.INVALID
        });
      }

      return true;
    });
  });

export const publishTimeSchemaBuilder = () =>
  yup.string().when('archived', archived => {
    if (!archived) {
      return yup.string();
    }

    return yup.string().test('invalidPublishTime', (publishTime, testContext) => {
      const { publishDate } = testContext.parent ?? {};
      if (!publishDate && !publishTime) {
        return true;
      }

      if (!publishTime) {
        return testContext.createError({
          path: testContext.path,
          message: strings.ERRORS_MESSAGE.PUBLISH_TIME.REQUIRED
        });
      }

      const publishDateTime = moment(`${publishDate} ${publishTime}`, DATE_TIME_PICKER_FORMAT_DEFAULT);
      if (!TIME_PICKER_PATTERN.test(publishTime) || !publishDateTime.isValid() || publishDateTime.isBefore(moment())) {
        return testContext.createError({
          path: testContext.path,
          message: strings.ERRORS_MESSAGE.PUBLISH_TIME.INVALID
        });
      }

      return true;
    });
  });

class SeasonCreationYup {
  SeasonGeneralSetupFormFields = [
    'id',
    'name',
    'creationMode',
    'genderSpecific',
    'genders',
    'levelsByGender',
    'accountTicketTypes',
    'modeImportData',
    'manualSetting',
    'eventStartTimeType',
    'fileUploadSetting',
    'venue',
    'seasonTickets',
    'archived',
    'alert',
    'glCode',
    'publishDate',
    'publishTime',
    'eventsArchived',
    'alert',
    'seasonalPeriod'
  ];

  SeasonEventSetupFormFields = ['id', 'rawSeasons'];

  SeasonEventSetupSchema = yup.object().shape({
    rawSeasons: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
          name: seasonNameSchemaBuilder(),
          rawEvents: rawEventsSchemaBuilder()
        })
      )
      .required()
  });

  SeasonGeneralSetupSchema = yup.object().shape({
    id: yup.string(),
    creationMode: yup.mixed().oneOf(Object.keys(CREATION_MODE)),
    genderSpecific: yup.boolean(),
    genders: yup
      .array(yup.string().oneOf(Object.keys(GENDER_TYPE)))
      .min(1, strings.ERRORS_MESSAGE.TEAM.GENDER_REQUIRED),
    levelsByGender: levelsByGenderSchemaBuilder(),
    accountTicketTypes: accountTicketTypeSchemaBuilder(),
    modeImportData: yup.string().required().oneOf(Object.keys(IMPORT_DATA_MODE)),
    seasonalPeriod: yup.string().required(),
    manualSetting: manualSettingSchemaBuilder(),
    fileUploadSetting: fileUploadSettingSchemaBuilder(),
    venue: yup
      .object()
      .shape({
        name: yup.string().required().min(1, 'name is required'),
        address: yup.string().required().min(1, 'address is required'),
        city: yup.string().required().min(1, 'city is required'),
        state: yup.string().required().min(1, 'state is required'),
        zipCode: yup.string().required().min(1, 'zipCode is required'),
        id: yup.number().nullable(),
        location: yup.string().nullable(),
        seatsIoChartKey: yup.string().nullable()
      })
      .test('invalidVenue', '', (value, valueContext) => {
        if (!isEmpty(value)) return true;
        return false;
      }),
    seasonTickets: yup.array().nullable().notRequired(),
    archived: yup.boolean(),
    publishDate: publishDateSchemaBuilder(),
    publishTime: publishTimeSchemaBuilder(),
    eventsArchived: yup.boolean(),
    alert: yup.string().matches(ALPHANUMERIC_SPECIAL_CHARACTERS_REGEX, {
      message: strings.ERRORS_MESSAGE.EVENT_VISIBILITY.ALPHANUMERIC_SPECIAL_CHARACTERS,
      excludeEmptyStrings: true
    }),
    glCode: yup.string().matches(GL_CODE_REGEX, {
      message: GLCODE_CONSTANTS.ERROR_MESSAGE_DEFAULT,
      excludeEmptyStrings: true
    })
  });

  SeasonMultiSportSchema = yup.object().shape({
    archived: yup.boolean(),
    publishDate: publishDateSchemaBuilder(),
    publishTime: publishTimeSchemaBuilder(),
    eventsArchived: yup.boolean(),
    alert: yup.string().matches(ALPHANUMERIC_SPECIAL_CHARACTERS_REGEX, {
      message: strings.ERRORS_MESSAGE.EVENT_VISIBILITY.ALPHANUMERIC_SPECIAL_CHARACTERS,
      excludeEmptyStrings: true
    })
  });

  validateSeasonGeneralSetup = async (values: any) => {
    let error;
    let data;

    try {
      data = await this.SeasonGeneralSetupSchema.validate(values, {
        abortEarly: false
      });
    } catch (validateError) {
      error = validateError;
    }

    return { error, data };
  };
}

export default new SeasonCreationYup();
