import type { RefObject } from 'react';
import { useState, useCallback, useEffect } from 'react';

interface UseDisclosureOptions<T extends HTMLElement> {
  initialValue?: boolean;
  containerRef?: RefObject<T>; // a reference to the object you are opening, so clicking outside of this element will trigger a close
}

const useDisclosure = <T extends HTMLElement>({ initialValue = false, containerRef }: UseDisclosureOptions<T>) => {
  const [isOpen, setOpen] = useState<boolean>(initialValue);

  const open = useCallback(() => setOpen(true), []);
  const close = useCallback(() => setOpen(false), []);
  const toggle = useCallback(() => setOpen(currentOpen => !currentOpen), []);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // Check if the click occurred outside the component's container (if provided)
      if (containerRef && containerRef.current && !containerRef.current.contains(event.target as Node)) {
        if (!isOpen) return;
        setOpen(false);
      }
    };

    if (isOpen && containerRef) {
      setTimeout(() => document.addEventListener('click', handleOutsideClick), 0);
    }

    if (!isOpen && containerRef) {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen, containerRef]);

  return { isOpen, open, close, toggle };
};

export { useDisclosure };
