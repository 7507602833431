import { useRef, useState, useCallback } from 'react';
import classNames from 'classnames';
import { Row, Column, Dropdown } from 'carbon-components-react';
import { isEmpty, isEqual, values } from 'lodash';
import { PAGES } from '@app/config/routes';
import type { ActivityDTO } from '@gofan/api/activities';

import type { Pill } from '@old-components/filter-pills/filter-pills.component';
import FilterPills from '@old-components/filter-pills/filter-pills.component';
import type { DispatchedEvent } from '@events/components/event-table/event-table.component';
import EventTable from '@events/components/event-table/event-table.component';
import UploadTable from '@events/components/upload-table/upload-table.component';
import { Tab, TabList, TabPanels, Tabs } from '@gofan/components';

import type { AccountDTO } from '@gofan/api/accounts';
import SectionInfo from '@accounts/components/section-info/section-info.component';
import DashboardSeasons from '@dashboard/components/dashboard-seasons/dashboard-seasons.component';
import { EVENT_TABLE_TABS, useEventTableFilter } from '@app/modules/dashboard/hooks';

import StyledButton from '@app/commons/carbon-ui/atoms/StyledButton';
import '@carbon/charts/styles.css';
import TicketSalesByActivity from '@app/modules/dashboard/components/ticket-sales-by-activity/ticket-sales-by-activity';
import TicketSalesByWeek from '@app/modules/dashboard/components/ticket-sales-by-week/ticket-sales-by-week';
import { EventInsightProvider } from '@app/modules/events/components/event-insight/event-insight.context';
import TicketSalesByActivityComponent from '@dashboard/components/ticket-sales-by-activity/ByWeekMonth/ticket-sales-by-activity.component';
import type { LevelDTO } from '@gofan/api/levels';
import type { RateDTO } from '@gofan/api/rates';
import type { VenueDTO } from '@gofan/api/venues';
import type { SchoolConfig } from '@gofan/api/school-config';
import { EventBulkUploadNotification } from '@app/modules/events/components/event-bulk-upload-notification/event-bulk-upload-notification.component';
import type { FormValues } from '@app/modules/events/components/event-filter-form';

export type ViewMode = {
  id: string;
  label: string;
  title: string;
  linkLabel: string;
};

export type ViewModes = {
  [k: string]: ViewMode;
};

export type ViewPills = {
  [k: string]: Pill[];
};

export type DashboardContentProps = {
  history: any;
  accounts: AccountDTO[];
  activities: ActivityDTO[];
  levels: LevelDTO[];
  venues: VenueDTO[];
  rates: RateDTO[];
  schoolsConfig?: SchoolConfig[];
  districtAccount?: AccountDTO;
  isInternalUser: boolean;
  addNotification: Function;
  bulkUploadData: any;
  setBulkUploadData: Function;
};

export type AccountFilterType = {
  id: string;
  name: string;
};

const ContentMode = {
  EVENT: 'EVENT',
  SEASON: 'SEASON',
  UPLOADS: 'UPLOADS',
  CONTACT: 'CONTACT'
};

const VIEW_MODE: ViewModes = {
  [ContentMode.EVENT]: {
    id: ContentMode.EVENT,
    label: 'Events',
    title: 'Our events',
    linkLabel: 'All events'
  },
  [ContentMode.SEASON]: {
    id: ContentMode.SEASON,
    label: 'Seasons',
    title: 'Seasons',
    linkLabel: 'All seasons'
  },
  [ContentMode.UPLOADS]: {
    id: ContentMode.UPLOADS,
    label: 'Uploads',
    title: 'Uploads',
    linkLabel: 'Uploads'
  }
  // TODO: Hide contact option
  // [ContentMode.CONTACT]: {
  //   id: ContentMode.CONTACT,
  //   label: 'Contact',
  //   title: 'Contact'
  // }
};

const VIEW_PILLS: ViewPills = {
  [ContentMode.EVENT]: [],
  [ContentMode.SEASON]: [],
  [ContentMode.UPLOADS]: [],
  [ContentMode.CONTACT]: []
};

const DashboardContent = ({
  history,
  accounts,
  activities,
  levels,
  rates,
  venues,
  schoolsConfig,
  districtAccount,
  isInternalUser,
  addNotification,
  bulkUploadData,
  setBulkUploadData
}: DashboardContentProps) => {
  const [forceKey, setForceKey] = useState<number>(1);
  const [viewMode, setViewMode] = useState<ViewMode>(VIEW_MODE[ContentMode.EVENT]);
  const sectionTitle = isEmpty(viewMode) ? '' : viewMode.title;
  const shouldShowTicketSaleSections = !isEmpty(accounts) && viewMode !== VIEW_MODE[ContentMode.UPLOADS];
  const pills = useRef<ViewPills>(VIEW_PILLS);
  const [dispatchedEvent, setDispatchedEvent] = useState<DispatchedEvent>();

  const onClickedFullView = useCallback(() => {
    if (viewMode.id === ContentMode.SEASON) {
      history.push(PAGES.season.viewAllSeasons.path, {
        activeAccountIds: accounts.map(account => account.id)
      });
    } else {
      history.push(PAGES.eventsV2.viewAllEvents.path);
    }
  }, [viewMode, accounts]);

  const onClosedPill = useCallback(
    (item: Pill) => {
      pills.current = {
        ...pills.current,
        [viewMode.id]: pills.current[viewMode.id].filter((pill: Pill) => pill.id !== item.id)
      };
      setForceKey(new Date().getTime());
    },
    [pills, viewMode, forceKey]
  );

  const onAddedPills = useCallback(
    (items: Pill[]) => {
      const currentPills = pills.current[viewMode.id];
      if (!isEqual(currentPills, items)) {
        pills.current = { ...pills.current, [viewMode.id]: items };
        setForceKey(new Date().getTime());
      }
    },
    [pills, viewMode, forceKey]
  );

  const {
    eventTableIndex,
    eventTable,
    onChangeEventTable,
    eventTableFilters,
    eventTablePills,
    onUpdateEventTableFilters,
    onAddEventPills,
    onCloseEventPill
  } = useEventTableFilter({ isInternalUser, setForceKey });

  return (
    <>
      <SectionInfo
        title={sectionTitle}
        className='dashboard-content'
        titleClassName='gs--productive-heading-04-semibold gs--text'
        headingClassName='dashboard-content--heading'
        customTitle={
          viewMode.id === ContentMode.EVENT ? (
            <FilterPills pills={eventTablePills[eventTable]} onClosePill={pill => onCloseEventPill(pill, eventTable)} />
          ) : (
            <FilterPills pills={pills.current[viewMode.id]} onClosePill={onClosedPill} />
          )
        }
        customControl={
          <div className='dropdown-view-mode width-200'>
            {!isInternalUser && (
              <StyledButton
                kind='ghost'
                onClick={!isEmpty(accounts) ? onClickedFullView : () => {}}
                className='show-full-view'
                disabled={isEmpty(accounts)}
              >
                {viewMode.linkLabel}
              </StyledButton>
            )}
            <Dropdown
              light={false}
              id='dropdown-view-mode'
              label=''
              titleText='View'
              selectedItem={viewMode}
              items={
                isInternalUser ? values(VIEW_MODE) : values(VIEW_MODE).filter(mode => mode.id !== ContentMode.UPLOADS)
              }
              onChange={({ selectedItem }: { selectedItem: ViewMode }) => setViewMode(selectedItem)}
            />
          </div>
        }
      >
        {!isEmpty(bulkUploadData) && (
          <EventBulkUploadNotification
            history={history}
            bulkUploadData={bulkUploadData}
            onClose={() => setBulkUploadData({})}
          />
        )}
        <Row>
          <Column className={classNames('dashboard-content--viewer', { hidden: viewMode.id !== ContentMode.EVENT })}>
            <EventInsightProvider>
              <Tabs selectedIndex={eventTableIndex} onChange={({ selectedIndex }) => onChangeEventTable(selectedIndex)}>
                <TabList aria-label='List of tabs'>
                  {EVENT_TABLE_TABS.map(tab => (
                    <Tab key={tab.id}>{tab.label}</Tab>
                  ))}
                </TabList>
                <TabPanels>
                  {EVENT_TABLE_TABS.map(tab => (
                    <div key={tab.id} hidden={eventTable !== tab.id}>
                      <EventTable
                        kind={tab.id}
                        history={history}
                        accounts={accounts}
                        schoolsConfig={schoolsConfig}
                        districtAccount={districtAccount}
                        activities={activities}
                        levels={levels}
                        venues={venues}
                        filteredPills={eventTablePills[tab.id]}
                        triggerOnChange={viewMode.id === ContentMode.EVENT}
                        addNotification={addNotification}
                        onAddPills={(pills: Pill[]) => onAddEventPills(pills, tab.id)}
                        updateEventTableFilters={(filters: FormValues) => onUpdateEventTableFilters(filters, tab.id)}
                        initialEventTableFilters={eventTableFilters[tab.id]}
                        dispatchedEvent={dispatchedEvent}
                        setDispatchedEvent={setDispatchedEvent}
                      />
                    </div>
                  ))}
                </TabPanels>
              </Tabs>
            </EventInsightProvider>
          </Column>
          <Column className={classNames('dashboard-content--viewer', { hidden: viewMode.id !== ContentMode.SEASON })}>
            {viewMode.id === ContentMode.SEASON && (
              <DashboardSeasons
                history={history}
                rates={rates}
                accounts={accounts}
                schoolsConfig={schoolsConfig}
                districtAccount={districtAccount}
                addNotification={addNotification}
              />
            )}
          </Column>
          <Column className={classNames('dashboard-content--viewer', { hidden: viewMode.id !== ContentMode.UPLOADS })}>
            <UploadTable
              triggerOnChange={viewMode.id === ContentMode.UPLOADS}
              filteredPills={pills.current[viewMode.id]}
              onAddPills={onAddedPills}
              addNotification={addNotification}
            />
          </Column>
          <Column className={classNames('dashboard-content--viewer', { hidden: viewMode.id !== ContentMode.CONTACT })}>
            Contact
          </Column>
        </Row>
      </SectionInfo>

      {shouldShowTicketSaleSections && (
        <>
          <div className='dashboard-chart gs--margin-bottom-sp5'>
            <TicketSalesByActivityComponent accounts={accounts} />
          </div>
          <div className='dashboard-row'>
            <div className='dashboard-col'>
              <div className='dashboard-chart'>
                <TicketSalesByWeek accounts={accounts} />
              </div>
            </div>
            <div className='dashboard-col'>
              <div className='dashboard-chart' style={{ height: 'var(--minHeight)' }}>
                <TicketSalesByActivity accounts={accounts} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DashboardContent;
