import type { AccountDTO } from '@gofan/api';
import { useAccounts } from '@gofan/api';
import { SearchDropdown } from '../../../SearchDropdown';
import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { AppUtils } from '@gofan/utils';

interface PendoSchoolSearchProps {
  onSchoolSelect: (selectedSchool: AccountDTO) => void;
}

const SchoolDropdownItem = (item: AccountDTO) => {
  // since this is shared by desktop and mobile, we check for nextjs and use the appropriate fallback logo
  const defaultImage =
    typeof window !== 'undefined' && window.__NEXT_DATA__
      ? '/images/gofan-icon.png'
      : '/src/assets/images/gofan-icon.png';
  const schoolLogo = isEmpty(item?.logo) ? defaultImage : AppUtils.getSchoolImage(item?.id, 'logo', item?.logo ?? '');

  return (
    <div className='gf-school-dropdown-item'>
      <img
        src={schoolLogo}
        alt='School Logo'
        onError={({ currentTarget }) => {
          const t = currentTarget as HTMLImageElement;
          t.onerror = null;
          t.src = defaultImage;
        }}
      />
      <div>
        <span>{item.name}</span>
        <span>
          {item.city}
          {item.city ? ',' : ''} {item.state}
        </span>
      </div>
    </div>
  );
};

export const PendoSchoolSearch = ({ onSchoolSelect }: PendoSchoolSearchProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>(searchValue); // Debounced value
  const { data: searchedSchools, isLoading: isSchoolSearching } = useAccounts({
    searchBy: 'keyword',
    keywordParams: { keyword: debouncedSearchValue ?? '' },
    queryOptions: { enabled: !!debouncedSearchValue }
  });

  useEffect(() => {
    if (!searchValue) {
      setDebouncedSearchValue(searchValue);
    }
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  return (
    <SearchDropdown
      id='pendoSchoolSearch'
      labelText='School search'
      placeholder='Find a school'
      loading={isSchoolSearching}
      disabled={false}
      itemToElement={SchoolDropdownItem}
      itemToString={item => item?.name}
      items={searchedSchools as []}
      value={searchValue}
      onSearch={_handleOnSearch}
      onSelect={onSchoolSelect}
    />
  );

  function _handleOnSearch(searchText: string) {
    setSearchValue(searchText);
  }
};
