import { useEffect, useState, useContext, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ComposedModal, ModalBody, ModalFooter, ModalHeader, InlineLoading } from 'carbon-components-react';

import cs from 'classnames';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import BasicButton from '@old-components/basic-button/basic-button.component';
import CustomProgressIndicator from '@common/generic-components/custom-progress/custom-progress-indicator.component';
import NotificationComponent from '@common/carbon-ui/atoms/Notification';
import { SeasonTicketStep } from './components/SeasonTicketStep';

import { RootContext } from '@app/RootContext';

import { VenueService, checkAdminRole, isInternalUser } from '@gofan/api';
import {
  SeasonTicketSchema,
  TicketTypeSchema,
  getDefaultSeasonTicketValues,
  getDefaultTicketTypeValues
} from '@season-management/components/SeasonTicketModal/utils';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  EVENT_TICKET_STEPS,
  SEASON_TICKET_STEPS,
  STRINGS as SEASON_TICKET_STRINGS,
  TRIGGER_FIELDS
} from '@season-management/components/SeasonTicketModal/constants';
import { ATTENDEE_FEE_MESSAGE } from '@app/config/strings';
import { DISTRIBUTION_CHANNEL } from '@api/model/request/ProductRequest';

import type { AccountDTO, ActivityDTO, LevelDTO, RateDTO, AccountProductDTO } from '@gofan/api';
import type { TicketTypeFormConfig } from '@account-products/components/control-forms';

import './SeasonTicketModal.scss';

export interface SeasonTicketData {
  account: AccountDTO | null;
  rates: RateDTO[];
  endDateTime?: string;
  isInternalUser?: boolean;
  typeOfTicketForm?: 'default' | 'ticket';
  accountProducts?: AccountProductDTO[];
  activities?: ActivityDTO[];
  levels?: LevelDTO[];
  sportLevels?: LevelDTO[];
  config?: Partial<TicketTypeFormConfig>;
  ticketLimitPerOrderSeasonEvent?: number;
}

export enum SeasonTicketTypeEnum {
  SEASON_TICKET = 'SEASON_TICKET',
  TICKET_TYPE = 'TICKET_TYPE'
}

export type SeasonTicketFormState = {
  name: string;
  price: string;
  hiddenFees: boolean;
  customColor: string;
  reservedSeating?: boolean;
  seatsIoCategory?: any;
  salesStartDate?: string;
  salesStartTime?: string;
  salesEndDate?: string;
  salesEndTime?: string;
  fee?: number;
  ticketLimitPerOrder?: number;
  packCount?: number;
  enabledPackCount?: boolean;
};

type SeasonTicketModalProps = {
  open: boolean;
  shouldOutSideLoading?: boolean;
  loading?: boolean;
  type: SeasonTicketTypeEnum;
  mode: 'add' | 'update';
  data: SeasonTicketData;
  seasonTicket?: any;
  venueId?: number;
  ticketType?: any;
  defaultTicketType?: any;
  isAddingAccountProduct?: boolean;
  addAccountProductSuccessMsg?: string;
  hasOptionalSettings?: boolean;
  onCloseModal: (modalData: any) => void;
  onSubmit: ({
    mode,
    values,
    defaultValues,
    shouldAddMoreData
  }: {
    mode: 'add' | 'update';
    values: any;
    defaultValues: any;
    shouldAddMoreData?: boolean;
  }) => void;
};

const SeasonTicketModal = ({
  open,
  shouldOutSideLoading,
  loading,
  type,
  mode,
  data,
  seasonTicket,
  venueId,
  ticketType,
  defaultTicketType,
  isAddingAccountProduct = false,
  addAccountProductSuccessMsg,
  hasOptionalSettings = true,
  onSubmit,
  onCloseModal
}: SeasonTicketModalProps) => {
  const isShowBoxOffice = defaultTicketType?.groupDistributionChannel?.includes(DISTRIBUTION_CHANNEL.BOXOFFICE);
  const boxOfficeCustomer = _getBoxOfficeCustomer();
  const strings: any = get(SEASON_TICKET_STRINGS, `${mode}.${type}`.toUpperCase());
  const { currentUser } = useContext(RootContext);
  const isAddMoreClicked = useRef<boolean>();
  const feeMessages = useRef<string[]>([]);
  const timerRef = useRef<any>(null);
  const isAdminRole = checkAdminRole(currentUser?.role ?? '');
  const defaultValues = _onGetDefaultValues();
  const methods = useForm<SeasonTicketFormState>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    context: {
      type,
      isAdminRole,
      isInternalUser: isInternalUser(currentUser),
      ticketLimitPerOrderSeasonEvent: data.ticketLimitPerOrderSeasonEvent
    },
    defaultValues,
    resolver: yupResolver(type === SeasonTicketTypeEnum.SEASON_TICKET ? SeasonTicketSchema : TicketTypeSchema)
  });
  const createTicketSteps = type === SeasonTicketTypeEnum.SEASON_TICKET ? SEASON_TICKET_STEPS : EVENT_TICKET_STEPS;
  const [currentStep, setCurrentStep] = useState<number>(createTicketSteps?.seasonTicketSettings?.index ?? 0);
  const [seatsIoChartKey, setSeatsIoChartKey] = useState<string>();
  const [feeNotification, setFeeNotification] = useState<any>();
  const [addSuccessMessage, setAddSuccessMessage] = useState<string>('');

  useEffect(() => {
    if (!isNil(venueId)) _fetchVenue(venueId);

    async function _fetchVenue(vId: number) {
      const res = await VenueService.getVenuesById([vId]);
      if (Array.isArray(res) && res.length > 0) {
        const chartKey = res[0].seatsIoChartKey;
        if (typeof chartKey === 'string' && chartKey.trim() !== '') setSeatsIoChartKey(chartKey);
      }
    }
  }, [venueId]);

  return (
    <ComposedModal
      className='gf-season-ticket-modal'
      size='lg'
      open={open}
      onClose={() => _onClose()}
      preventCloseOnClickOutside
    >
      <ModalHeader className='gf-season-ticket-modal-header'>
        <div className={cs('gs--productive-heading-03', 'gs--text-01', 'gs--padding-left__sp5')}>{strings?.TITLE}</div>
      </ModalHeader>
      <ModalBody hasForm className='gf-season-ticket-modal-body'>
        <div>
          {hasOptionalSettings && (
            <div className='gf-season-ticket-progress'>
              <CustomProgressIndicator
                progressData={createTicketSteps}
                currentIndex={currentStep}
                onChange={_onChangeProgress}
              />
            </div>
          )}
        </div>
        <FormProvider {...methods}>
          <SeasonTicketStep
            currentStep={currentStep}
            mode={mode}
            type={type}
            data={data}
            seasonTicket={seasonTicket}
            boxOfficeCustomer={boxOfficeCustomer}
            seatsIoChartKey={seatsIoChartKey}
            isAdminRole={isAdminRole}
            onShowNotification={_onShowNotification}
          />
        </FormProvider>
        {!isEmpty(feeNotification) && (
          <div className='gf-season-ticket-notification'>
            <NotificationComponent
              id='season-ticket-notification'
              onCloseButtonClick={() => setFeeNotification(undefined)}
              {...feeNotification}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter className='gf-season-ticket-modal-footer'>
        <div>
          <BasicButton
            style={{ minWidth: 150 }}
            kind='secondary'
            type='button'
            size='sm'
            text={SEASON_TICKET_STRINGS.CANCEL_BTN}
            textAlign='center'
            onClick={() => _onClose()}
          />
        </div>
        <div className='action-btn'>
          <BasicButton
            style={{ marginRight: '2rem', minWidth: isAddMoreClicked.current && loading ? '250px' : 'fit-content' }}
            kind='tertiary'
            type='button'
            size='sm'
            hasLoading
            loading={isAddMoreClicked.current && loading}
            disabled={isAddingAccountProduct}
            text={SEASON_TICKET_STRINGS.SAVE_AND_ADD_ANOTHER_TICKET_BTN}
            textAlign='center'
            onClick={_handClickSaveAndAddAnother}
          />
          <BasicButton
            style={{ minWidth: 180 }}
            kind='primary'
            type='button'
            size='sm'
            hasLoading
            loading={!isAddMoreClicked.current && loading}
            disabled={isAddingAccountProduct}
            text={_getNextButtonLabel()}
            textAlign='center'
            onClick={_handleClickNextButton}
          />
          {addSuccessMessage && !shouldOutSideLoading && (
            <InlineLoading
              className={cs('text-success', 'gs--helper-text-01')}
              status='finished'
              description={addSuccessMessage}
            />
          )}
          {addAccountProductSuccessMsg && (
            <InlineLoading
              className={cs('text-success', 'gs--helper-text-01')}
              status='finished'
              description={addAccountProductSuccessMsg}
            />
          )}
        </div>
      </ModalFooter>
    </ComposedModal>
  );

  function _onGetDefaultValues() {
    if (type === SeasonTicketTypeEnum.TICKET_TYPE) {
      return getDefaultTicketTypeValues({
        ...ticketType,
        ..._generateDefaultValues()
      });
    }

    return getDefaultSeasonTicketValues(seasonTicket, data);
  }
  function _generateDefaultValues() {
    if (mode === 'add') {
      return {
        id: `${new Date().getTime()}`,
        accountId: data?.account?.id,
        groupDistributionChannel:
          data.typeOfTicketForm === 'default' && boxOfficeCustomer
            ? [DISTRIBUTION_CHANNEL.GOFAN, DISTRIBUTION_CHANNEL.BOXOFFICE]
            : [DISTRIBUTION_CHANNEL.GOFAN],
        limit: ''
      };
    }

    if (data.typeOfTicketForm === 'ticket' && isShowBoxOffice) {
      return {
        groupDistributionChannel: [DISTRIBUTION_CHANNEL.GOFAN, DISTRIBUTION_CHANNEL.BOXOFFICE]
      };
    }

    return {};
  }

  function _getBoxOfficeCustomer() {
    return (
      !!data?.account?.boxOfficeCustomer ||
      data?.isInternalUser ||
      (mode === 'update' && defaultTicketType?.groupDistributionChannel?.includes(DISTRIBUTION_CHANNEL.BOXOFFICE))
    );
  }

  function _onShowNotification(accountPaid: boolean) {
    const kind = accountPaid ? 'warning' : 'info';
    const subtitle = accountPaid ? ATTENDEE_FEE_MESSAGE.SUBTITLE.FEE_CONFIRM : ATTENDEE_FEE_MESSAGE.SUBTITLE.FEE_INFO;

    if (!feeMessages.current?.includes(subtitle)) {
      setFeeNotification({
        key: new Date().getTime() + 1,
        kind,
        subtitle,
        timeout: 3000,
        title: ATTENDEE_FEE_MESSAGE.TITLE
      });
      feeMessages.current = [...feeMessages.current, subtitle];
    } else if (!isEmpty(feeNotification) && feeNotification?.subtitle !== subtitle) {
      setFeeNotification(undefined);
    }
  }

  function _handClickSaveAndAddAnother() {
    isAddMoreClicked.current = true;
    if (type === SeasonTicketTypeEnum.SEASON_TICKET) {
      return _onSaveAndAddAnotherSeasonTicket();
    }

    return _onSaveAndAddAnotherTicketType();
  }

  async function _handleClickNextButton() {
    const isValid = await methods.trigger(TRIGGER_FIELDS[type][currentStep], {
      shouldFocus: true
    });

    if (!isValid) return;
    isAddMoreClicked.current = false;
    if (!hasOptionalSettings) {
      methods.handleSubmit(values =>
        _onSubmitted(values, () => {
          if (!shouldOutSideLoading) {
            _onClose();
          }
        })
      )();
      return;
    }

    if (currentStep === SEASON_TICKET_STEPS.optionalTicketSettings.index) {
      methods.handleSubmit(values =>
        _onSubmitted(values, () => {
          if (!shouldOutSideLoading) {
            _onClose();
          }
        })
      )();
    } else {
      setCurrentStep(SEASON_TICKET_STEPS.optionalTicketSettings.index);
    }
  }

  async function _onChangeProgress(stepIndex: number) {
    methods.trigger(TRIGGER_FIELDS[type][currentStep]);
    const isValid = await methods.trigger(TRIGGER_FIELDS[type][currentStep], {
      shouldFocus: true
    });
    if (!isValid) return;
    setCurrentStep(stepIndex);
  }

  function _onClose(modalData: any = null) {
    if (typeof onCloseModal === 'function') {
      onCloseModal(modalData);
    }
  }

  function _onSubmitted(values: any, cb?: () => void) {
    if (typeof onSubmit !== 'function') return;
    onSubmit({
      mode,
      values,
      defaultValues,
      shouldAddMoreData: isAddMoreClicked.current
    });
    if (typeof cb !== 'function') return;
    cb();
  }

  function _onSaveAndAddAnotherSeasonTicket() {
    methods.handleSubmit(values =>
      _onSubmitted(values, () => {
        setAddSuccessMessage(SEASON_TICKET_STRINGS.TICKET_UPDATE_SUCCESS);
        _onClose({ mode: 'add', seasonTicket: null });
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          setAddSuccessMessage('');
          methods.reset(getDefaultSeasonTicketValues(null, data));
          setCurrentStep(SEASON_TICKET_STEPS.seasonTicketSettings.index);
        }, 2000);
      })
    )();
  }

  function _onSaveAndAddAnotherTicketType() {
    methods.handleSubmit(values =>
      _onSubmitted(values, () => {
        methods.reset(getDefaultTicketTypeValues(_generateDefaultValues()));
        setCurrentStep(SEASON_TICKET_STEPS.seasonTicketSettings.index);
        _onClose({ mode: 'add', type: data.typeOfTicketForm });
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          setAddSuccessMessage('');
        }, 2000);
        if (data.typeOfTicketForm === 'ticket') {
          setAddSuccessMessage(SEASON_TICKET_STRINGS.TICKET_UPDATE_SUCCESS);
          clearTimeout(timerRef.current);
          timerRef.current = setTimeout(() => {
            setAddSuccessMessage('');
          }, 2000);
        }
      })
    )();
  }

  function _getNextButtonLabel() {
    if (!hasOptionalSettings) return SEASON_TICKET_STRINGS.SAVE_AND_CLOSE_BTN;

    return currentStep === SEASON_TICKET_STEPS.seasonTicketSettings.index
      ? SEASON_TICKET_STRINGS.NEXT_BTN
      : SEASON_TICKET_STRINGS.SAVE_AND_CLOSE_BTN;
  }
};

export { SeasonTicketModal };
