import { css } from 'styled-components';

export default {
  itemWrapper: css`
    :hover,
    :focus,
    :active {
      text-decoration: none;
    }
  `,
  item: css`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    text-align: left;
    cursor: pointer;
    background-color: ${props => (props.isSelected ? '#dcdcdc' : '#ffffff')};
    border-bottom: 1px solid #cccccc;
  `,
  logoSection: css`
    margin: 16px;
  `,
  descriptionSection: css`
    flex: 1;
    overflow: hidden;
    margin: 8px 16px 8px 0;
  `,
  logo: css`
    width: 30px;
    height: 30px;
  `,
  title: css`
    color: #000000;
  `,
  subTitle: css`
    color: #999999;
  `,
  font: css`
    font-family: 'IBM Plex Sans';
    font-size: 14px;
  `,
  textWrapper: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `
};
