import {
  Tabs as CarbonTabs,
  TabList as CarbonTabList,
  Tab as CarbonTab,
  TabPanels as CarbonTabPanels,
  TabPanel as CarbonTabPanel
} from '@carbon/react';

import './Tabs.scss';

export const Tabs = CarbonTabs;
export const TabList = CarbonTabList;
export const Tab = CarbonTab;
export const TabPanels = CarbonTabPanels;
export const TabPanel = CarbonTabPanel;
