import { isEmpty } from 'lodash';

class ExpandRequest {
  expand: any[];

  constructor(expand: any[] = []) {
    this.expand = expand;
  }

  toQueryString() {
    if (isEmpty(this.expand)) return '';
    return `expand=${this.expand?.join(',')}`;
  }
}

export default ExpandRequest;
