import isEmpty from 'lodash/isEmpty';

import { getSchoolImage } from '@app/pages/EventInformationV2/helpers';
import type { AccountDTO } from '@gofan/api/accounts';

import defaultSchoolIcon from '@assets/images/gofan-icon.png';
import styles from './SchoolDropdownItem.module.scss';

export const SchoolDropdownItem = (school: AccountDTO) => {
  const schoolIcon = isEmpty(school?.logo) ? defaultSchoolIcon : getSchoolImage(school?.id, 'logo', school?.logo);

  return (
    <div className={styles['school-item']}>
      <img alt='Icon' src={schoolIcon} />
      <div className='gs--font-family-sf gs--margin-left-sp3'>{school.name}</div>
    </div>
  );
};
