import { RadioButton as CarbonRadioButton } from '@carbon/react';
import classNames from 'classnames';

import type { RadioButtonProps as CarbonRadioButtonProps } from '@carbon/react/lib/components/RadioButton/RadioButton';

import './RadioButton.scss';

type RadioButtonProps = CarbonRadioButtonProps;

export const RadioButton = (props: RadioButtonProps) => (
  <CarbonRadioButton
    {...props}
    data-checked={props.checked}
    className={classNames('gf--radio-button', props.className)}
  />
);
