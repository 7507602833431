import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import FormSafeguard from './FormSafeguard';
import { FastFormContext } from './FastFormBase';
import ViewOnlyGuard from './ViewOnlyGuard';
import styles from './styles';

const StyledFieldLabel = styled.label`
  ${styles.dropdown.fieldLabel}
`;

const StyledOptionalLabel = styled.span`
  ${styles.baseInput.optionalLabel};
`;

const WrapperStyledDatePicker = styled.div``;

const StyledDatePicker = styled(DatePicker)`
  ${styles.datePicker};
`;

const StyledErrorMessage = styled.p`
  ${styles.baseInput.errorMessage};
`;

export default ({
  label,
  formKey,
  nullText = '',
  optionalLabel = '(optional)',
  required = false,
  showTimeSelect = true,
  dateFormat = 'MM/dd/yyyy h:mm aa',
  startDateTime,
  validationRules = null,
  allowNull = false
}) => {
  const fastFormContext = useContext(FastFormContext);
  const formValue = fastFormContext.form[formKey];
  const currentDateTimeRef = React.useRef();

  const filterTime = time => {
    const startDate =
      !startDateTime || new Date() < new Date(startDateTime)
        ? new Date()
        : new Date(startDateTime);
    if (formValue && formValue.value) {
      const selectedDate = new Date(formValue.value);
      selectedDate.setHours(time.getHours());
      selectedDate.setMinutes(time.getMinutes());
      selectedDate.setSeconds(time.getSeconds());
      return selectedDate >= startDate;
    }
    return startDateTime ? time > new Date(startDateTime) : time > new Date();
  };

  const filterDate = date => {
    if (!startDateTime || new Date(startDateTime) >= new Date()) {
      return date >= new Date().setHours(0, 0, 0, 0);
    }
    return date >= new Date(startDateTime).setHours(0, 0, 0, 0);
  };

  const handleOnCloseDatePicker = () => {
    const formData = { ...formValue };

    if (!formValue.value && required) {
      formData.value = startDateTime
        ? new Date(startDateTime)
        : currentDateTimeRef.current;
    } else if (allowNull && !moment(formValue.value).isValid()) {
      formData.value = '';
    } else if (formValue.value && formValue.value.getFullYear() > 9999) {
      formData.value = startDateTime ? new Date(startDateTime) : new Date();
    }

    if (
      (!startDateTime && new Date(formData.value) < new Date()) ||
      (startDateTime &&
        new Date() <= new Date(startDateTime) &&
        new Date(formData.value) < new Date())
    ) {
      formData.value = new Date();
    } else if (
      startDateTime &&
      new Date() >= new Date(startDateTime) &&
      new Date(formData.value) < new Date(startDateTime)
    ) {
      formData.value = new Date(startDateTime);
    }

    if (required && formData.value) {
      formData.errors = (formData.errors || []).filter(
        error => error !== `${label} is required.`
      );
      formData.valid = formData.errors.length === 0;
    }

    if (validationRules && typeof validationRules === 'function') {
      const error = validationRules({
        fastFormContext,
        value: formData.value
      });
      let valid = true;
      let errors = [];
      if (error) {
        valid = false;
      }
      if (errors.findIndex(err => err === error) === -1) {
        errors = errors.concat(error);
      }
      formData.valid = valid;
      formData.errors = errors;
    }

    fastFormContext.updateField(formKey, formData);
  };

  useEffect(
    () => {
      if (formValue.value) {
        currentDateTimeRef.current = formValue.value;
      }
      return () => {
        currentDateTimeRef.current = new Date();
      };
    },
    [formValue.value]
  );

  const onHandleChange = value => {
    const isEmpty = value === null;
    let valid = true;
    let errors = [];
    const date = moment(value);

    if (required && (isEmpty || !date.isValid())) {
      valid = false;
      errors = errors.concat(`${label} is required.`);
    }
    if (validationRules && typeof validationRules === 'function') {
      const error = validationRules({ fastFormContext, value });
      if (error) {
        valid = false;
      }
      if (errors.findIndex(err => err === error) === -1) {
        errors = errors.concat(error);
      }
    }
    fastFormContext.updateField(formKey, {
      ...formValue,
      value,
      valid,
      errors
    });
  };
  return (
    <FormSafeguard formKey={formKey}>
      <ViewOnlyGuard nullText={nullText} label={label} formKey={formKey}>
        <WrapperStyledDatePicker>
          <StyledFieldLabel>
            {label}
            {!required ? (
              <StyledOptionalLabel>{` ${optionalLabel}`}</StyledOptionalLabel>
            ) : (
              ''
            )}
          </StyledFieldLabel>
          <StyledDatePicker
            className='react-datepicker__input-container react-datepicker-wrapper'
            customHeight={48}
            showTimeSelect={showTimeSelect}
            selected={formValue.value}
            onChange={onHandleChange}
            // dateFormat='MM/dd/yyyy h:mm aa'
            dateFormat={dateFormat}
            filterTime={filterTime}
            filterDate={filterDate}
            timeIntervals={5}
            onCalendarClose={handleOnCloseDatePicker}
            onBlur={handleOnCloseDatePicker}
          />
          {formValue.errors.length > 0 &&
            formValue.errors.map(e => (
              <StyledErrorMessage key={e}>{e}</StyledErrorMessage>
            ))}
        </WrapperStyledDatePicker>
      </ViewOnlyGuard>
    </FormSafeguard>
  );
};
