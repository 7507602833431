// @flow

import { isEmpty } from '../../utils/objectUtils';
import { FAN_SUPPORT } from '../../config/strings';

const REFUND_STATUS = {
  IN_PROCESS: 'was refunded or in process',
  IN_SCHEDULED: 'scheduled to be refunded'
};

class EventRefundResponse {
  constructor({ statusCode, body, eventName }) {
    this.raw = { statusCode, body, eventName };
  }

  get statusCode() {
    return this.raw.statusCode;
  }

  get body() {
    return this.raw.body;
  }

  get eventName() {
    return this.raw.eventName || '';
  }

  get refundStatus() {
    if (this.statusCode !== 200 || isEmpty(this.body) || !this.body.message) {
      return {};
    }

    let result = {};
    const message = `${this.body.message}`.toLowerCase();
    if (message.includes(REFUND_STATUS.IN_SCHEDULED)) {
      result = {
        warning: false,
        content: FAN_SUPPORT.EVENT_REFUND_IN_SCHEDULED.replace(
          '{eventName}',
          this.eventName
        )
      };
    } else if (message.includes(REFUND_STATUS.IN_PROCESS)) {
      result = {
        warning: true,
        content: FAN_SUPPORT.EVENT_REFUND_IN_PROGRESS
      };
    }
    return result;
  }
}

export default EventRefundResponse;
