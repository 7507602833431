import { huddleAxiosInstanceProxy } from '../shared/axios';
import {
  GET_ACCOUNT_FINANCIAL_BY_ACCOUNT_ID,
  GET_ACCOUNT_FINANCIAL_HISTORY,
  UPDATE_ACCOUNT_FINANCIAL_BY_ID,
  CREATE_ACCOUNT_FINANCIAL,
  UPDATE_ACCOUNT_FINANCIAL_BY_ID_V2,
  CREATE_ACCOUNT_FINANCIAL_V2
} from './account-financial.endpoints';

import type { AccountHistorySearchDTO } from '../accounts/account.model';
import type { AccountFinancialDTO } from './account-financial.model';
import type { HistoryDTO } from '../history/history.model';

class AccountFinancialRepository {
  static getAccountFinancialByAccountId = (
    accountId: string,
    manualErrorHandling?: boolean
  ): Promise<AccountFinancialDTO> =>
    huddleAxiosInstanceProxy.get<AccountFinancialDTO>(
      `${GET_ACCOUNT_FINANCIAL_BY_ACCOUNT_ID.replace('{accountId}', accountId)}`,
      undefined,
      manualErrorHandling
    );

  static getAccountFinancialHistory = (
    params: AccountHistorySearchDTO,
    manualErrorHandling: boolean
  ): Promise<HistoryDTO[]> =>
    huddleAxiosInstanceProxy.get(
      `${GET_ACCOUNT_FINANCIAL_HISTORY.replace('{id}', params.accountId).replace('{dateRange}', params.dateRange)}`,
      undefined,
      manualErrorHandling
    );

  static updateAccountFinancialById = (
    accountFinancial: AccountFinancialDTO,
    achResultReviewed?: boolean
  ): Promise<AccountFinancialDTO> =>
    huddleAxiosInstanceProxy.put<AccountFinancialDTO>(
      `${UPDATE_ACCOUNT_FINANCIAL_BY_ID.replace(
        '{id}',
        `${accountFinancial.id ?? ''}`
      )}?achResultReviewed=${!!achResultReviewed}`,
      accountFinancial
    );

  static patchAccountFinancialById = (
    accountFinancial: AccountFinancialDTO,
    achResultReviewed?: boolean,
    version: 1 | 2 = 1
  ): Promise<AccountFinancialDTO> => {
    const endpoint = version === 1 ? UPDATE_ACCOUNT_FINANCIAL_BY_ID : UPDATE_ACCOUNT_FINANCIAL_BY_ID_V2;
    return huddleAxiosInstanceProxy.patch<AccountFinancialDTO>(
      `${endpoint.replace('{id}', String(accountFinancial.id) ?? '')}?achResultReviewed=${!!achResultReviewed}`,
      accountFinancial
    );
  };

  static createAccountFinancial = (
    accountFinancial: AccountFinancialDTO,
    achResultReviewed?: boolean,
    version: 1 | 2 = 1
  ): Promise<AccountFinancialDTO> =>
    huddleAxiosInstanceProxy.post<AccountFinancialDTO>(
      `${
        version === 1 ? CREATE_ACCOUNT_FINANCIAL : CREATE_ACCOUNT_FINANCIAL_V2
      }?achResultReviewed=${!!achResultReviewed}`,
      accountFinancial
    );
}

export { AccountFinancialRepository };
