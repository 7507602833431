class ReservedEvent {
  constructor({
    id,
    description,
    startDateTime,
    endDateTime,
    venueId,
    seasonId,
    hostAccountId,
    hostAccount,
    name,
    products,
    timeZone,
    seatsIoPrivateWorkspaceKey,
    seatsIoPublicWorkspaceKey,
    accountId,
    financialAccountId,
    embedded,
    levels,
    genders
  }) {
    this.raw = {
      id,
      description,
      startDateTime,
      endDateTime,
      venueId,
      seasonId,
      hostAccountId,
      hostAccount,
      name,
      products,
      timeZone,
      seatsIoPrivateWorkspaceKey,
      seatsIoPublicWorkspaceKey,
      accountId,
      financialAccountId,
      _embedded: embedded,
      levels,
      genders
    };
  }

  get id() {
    return this.raw.id;
  }

  get description() {
    return this.raw.description;
  }

  get startDateTime() {
    return this.raw.startDateTime;
  }

  get endDateTime() {
    return this.raw.endDateTime;
  }

  get venueId() {
    return this.raw.venueId;
  }

  get seatsIoPrivateWorkspaceKey() {
    return this.raw.seatsIoPrivateWorkspaceKey;
  }

  get seatsIoPublicWorkspaceKey() {
    return this.raw.seatsIoPublicWorkspaceKey;
  }

  get seasonId() {
    return this.raw.seasonId;
  }

  get name() {
    return this.raw.name;
  }

  get timeZone() {
    return this.raw.timeZone;
  }

  get seats() {
    return this.raw.seats;
  }

  get products() {
    return this.raw.products;
  }

  get accountId() {
    return this.raw.accountId;
  }

  get financialAccountId() {
    return this.raw.financialAccountId;
  }

  get _embedded() {
    return this.raw._embedded;
  }

  get levels() {
    return this.raw.levels;
  }

  get genders() {
    return this.raw.genders;
  }

  toJSON() {
    return {
      id: this.id,
      description: this.description,
      startDateTime: this.startDateTime,
      endDateTime: this.endDateTime,
      venueId: this.venueId,
      seatsIoPrivateWorkspaceKey: this.seatsIoPrivateWorkspaceKey,
      seatsIoPublicWorkspaceKey: this.seatsIoPublicWorkspaceKey,
      seasonId: this.seasonId,
      products: this.products,
      name: this.name,
      timeZone: this.timeZone,
      accountId: this.accountId,
      financialAccountId: this.financialAccountId,
      _embedded: this._embedded.toJSON(),
      levels: this.levels,
      genders: this.genders
    };
  }
}

export default ReservedEvent;
