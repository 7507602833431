/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Column, Dropdown, Row, InlineLoading } from 'carbon-components-react';
import { Button } from '@gofan/components';
import { CheckmarkFilled16 } from '@carbon/icons-react';
import { saveAs } from 'file-saver';
import type { AccountDTO } from '@gofan/api/accounts';
import EventService from '@events/services/events.service';
import SectionInfo from '@accounts/components/section-info/section-info.component';
import { generateError } from '@app/utils/alertUtils';
import { isEmpty } from '@app/utils/objectUtils';
import { isSafari } from '@app/utils/behaviorUtils';
import { FinancialService } from '@gofan/api/financial';

import './sales-reports.component.scss';
import { useHistory } from 'react-router-dom';
import { PAGES } from '@app/config/routes';
import { SummaryReports } from '../summary-reports/summary-reports.component';

export type DropdownItemType = {
  id: string;
  label: string;
};

export type SalesReportsProps = {
  accounts: AccountDTO[];
  addNotification: Function;
  resizeDashboard: Function;
};

const summaryReportId = 'summary-report';
const REPORT_TYPE: DropdownItemType[] = [
  {
    id: 'weekly-sales',
    label: 'Weekly Payment Statement'
  },
  {
    id: summaryReportId,
    label: 'Summary Report'
  }
];

const SalesReports = ({ accounts, addNotification, resizeDashboard }: SalesReportsProps) => {
  const [weekDate, setWeekDate] = useState<DropdownItemType>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const saleReportRef = useRef<boolean>(false);
  const [totalTicketSales, setTotalTicketSales] = React.useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<AccountDTO>(accounts[0]);
  const [reportId, setReportId] = useState<string>('');
  const [selectedReportType, setSelectedReportType] = useState<DropdownItemType>(REPORT_TYPE[0]);
  const history = useHistory();
  const listMonday = useMemo(
    () =>
      EventService.getListMondayOfYear(
        {
          year: 2021,
          month: 9,
          day: 1
        },
        false
      ),
    []
  );
  const hasReports = !isEmpty(reportId);

  useEffect(() => {
    const currentDate = new Date();
    const newEndDate = new Date();
    if (currentDate.getDay() !== 0) {
      currentDate.setDate(currentDate.getDate() - 7);
      newEndDate.setDate(newEndDate.getDate() - 7);
    }
    setStartDate(new Date(listMonday[0].id));
    setWeekDate(listMonday[0]);
    newEndDate.setDate(newEndDate.getDate() - newEndDate.getDay() + 7);
    setEndDate(newEndDate);
    saleReportRef.current = true;
  }, []);

  useEffect(() => {
    if (!saleReportRef.current) return;
    if (selectedAccount?.id && startDate && endDate) {
      EventService.getSalesReportInfoAndStandardizeData({
        schoolId: selectedAccount.id,
        startDate,
        endDate
      })
        .then(({ reportId: resReport, ticketSales }) => {
          setReportId(resReport);
          setTotalTicketSales(ticketSales);
        })
        .catch(error => {
          addNotification(generateError(error));
        });
    }
    saleReportRef.current = false;
    setIsSuccess(false);
  }, [saleReportRef, selectedAccount, startDate, endDate]);

  useEffect(() => {
    if (!isEmpty(selectedAccount) || !accounts.find(account => account?.id === selectedAccount?.id)) {
      setSelectedAccount(accounts[0]);
      setIsSuccess(false);
      saleReportRef.current = true;
    }
  }, [accounts]);

  const handleChangeWeekOf = useCallback((e: any) => {
    setIsSuccess(false);
    const date = new Date(e.selectedItem.id);
    setWeekDate(e.selectedItem);
    setStartDate(date);
    const newEndDate = new Date(e.selectedItem.id);
    newEndDate.setDate(newEndDate.getDate() + 6);
    setEndDate(newEndDate);
    saleReportRef.current = true;
  }, []);

  const handleClickDownload = useCallback(() => {
    if (!loading) {
      if (!isEmpty(reportId)) {
        const safari = isSafari();
        if (safari && reportId.length > 1) {
          FinancialService.downloadPaymentStatementById(reportId).then(r => saveAs(r.data, `${reportId}.zip`));
        } else {
          setLoading(true);
          FinancialService.downloadPaymentStatementById(reportId).then(r => saveAs(r.data, `${reportId}.zip`));
          setLoading(false);
          setIsSuccess(true);
        }
      }
    }
  }, [reportId]);

  const handleChangeSelectedSchool = useCallback((e: any) => {
    setSelectedAccount(e.selectedItem);
    setIsSuccess(false);
    saleReportRef.current = true;
  }, []);

  return (
    <SectionInfo
      title='Sales/summary reports'
      className='sales-reports'
      titleClassName='gs--body-short-02-semibold'
      headingClassName='dashboard-content--heading'
    >
      <Row>
        <Column>
          {selectedReportType.id === 'weekly-sales' && (
            <div className='gs--label-02 gs--text-02 gs--padding-bottom__sp3 gf-subtitle'>
              Payment remittance is calculated for the previous week (Monday to Sunday).
            </div>
          )}
          <Dropdown
            light={false}
            id='dropdown-report-type'
            label=''
            titleText='Choose a report type'
            selectedItem={selectedReportType}
            itemToString={item => (item ? item.label : '')}
            // REPORT_TYPE.filter controls summary-reports LD feature flag
            items={REPORT_TYPE}
            onChange={e => setSelectedReportType(e.selectedItem as DropdownItemType)}
          />
        </Column>
      </Row>
      {accounts.length > 1 && (
        <Row>
          <Column className='gs--padding-top__sp3'>
            <Dropdown
              light={false}
              id='select-school'
              label=''
              titleText='Schools selected'
              selectedItem={selectedAccount}
              itemToString={(item: any) => item.name || ''}
              items={accounts}
              onChange={handleChangeSelectedSchool}
            />
          </Column>
        </Row>
      )}

      {selectedReportType.id === 'weekly-sales' ? (
        <>
          <Row>
            <Column className='gs--padding-top__sp3'>
              <Dropdown
                light={false}
                id='week-of'
                label=''
                titleText='For transaction period beginning:'
                selectedItem={weekDate}
                itemToString={item => (item ? item.label : '')}
                items={listMonday}
                onChange={handleChangeWeekOf}
              />
            </Column>
          </Row>
          {totalTicketSales ? (
            <>
              <div className='gs--productive-heading-03-semibold gs--text-01 gs--padding-top__sp3 text-center'>
                {totalTicketSales}
              </div>
              <div className='gs--caption-01 gs--text-01 gs--padding-top__sp3 text-center'>
                for this selected period
              </div>
            </>
          ) : (
            <div className='gs--padding-top__sp5 gs--padding-bottom__sp3'>
              {!hasReports ? (
                <div className='gs--label-02 gs--text-02'>There is no data to report for this period yet.</div>
              ) : (
                <div className='gs--label-02 gs--text-02'>
                  Report data is not available right now but you can still download the report
                </div>
              )}
            </div>
          )}
          <Row>
            <Column>
              <div className='download-button gs--padding-top__sp3'>
                <Button kind='primary' disabled={!hasReports} onClick={handleClickDownload}>
                  {loading ? (
                    <>
                      <div className='loading-left'>
                        <InlineLoading />
                      </div>
                      Downloading
                    </>
                  ) : (
                    <>Download report(s)</>
                  )}
                </Button>
                {isSuccess && (
                  <div className='text-center gs--padding-bottom__sp4'>
                    <CheckmarkFilled16 className='bx--checkmark' />
                    <div className='gs--label-01 gs--text-01 gs--padding-left__sp3'>Check your downloads folder</div>
                  </div>
                )}
                <Button type='button' kind='tertiary' onClick={() => history.push(PAGES.balances.root.path)}>
                  Explore financial performance
                </Button>
              </div>
            </Column>
          </Row>
        </>
      ) : (
        <SummaryReports selectedAccount={selectedAccount} />
      )}
    </SectionInfo>
  );
};

export default SalesReports;
