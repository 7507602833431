import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import reducer from './reducer';
import sagas from './sagas';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['seasonManagement', 'promotions', 'bulkEditEvents']
};

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);
const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(persistedReducer, composedEnhancers);
export const persistor = persistStore(store);
// Run root saga
sagaMiddleware.run(sagas);

export default store;
