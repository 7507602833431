/* eslint-disable no-shadow */
import { isEmpty, isEqual } from 'lodash';
import { getCachedData, putDataToCache } from '@app/api/services/CacheService';
import type {
  FormValues,
  AccountSelection
} from '@app/modules/events/components/event-filter-form/event-filter-form.component';

export enum TeamDashboardEventFilter {
  EVENTS = 'teamDashboardEvents',
  PRACTICES = 'teamDashboardPractices',
  GAMES = 'teamDashboardGames',
  SCORES = 'teamDashboardScores'
}

export type CachePageLocation = 'dashboard' | 'eventLanding' | 'shared' | TeamDashboardEventFilter;

export const cacheDashboardSelectedAccounts = (accounts: AccountSelection[]) =>
  putDataToCache('dashboard', 'selectedAccounts', accounts);
export const getCachedDashboardSelectedAccounts = (): AccountSelection[] =>
  getCachedData('dashboard', 'selectedAccounts');

export const cacheDashboardActiveAccounts = (accounts: AccountSelection[]) =>
  putDataToCache('dashboard', 'activeAccounts', accounts);
export const getCachedDashboardActiveAccounts = (): AccountSelection[] => getCachedData('dashboard', 'activeAccounts');

export const cacheEventFilters = (filters: FormValues, place: CachePageLocation, token = 'eventFilters') =>
  putDataToCache(token, place, filters);

export const getCachedEventFilters = (place: CachePageLocation, token = 'eventFilters'): FormValues =>
  getCachedData(token, place);

export const getCachedDashboardEventFilters = (isInternalUser: boolean, token: string) => {
  const eventFiltersCached = getCachedEventFilters(isInternalUser ? 'dashboard' : 'shared', token) || {};
  if (!isEmpty(eventFiltersCached)) {
    let selectedAccountIds = (getCachedDashboardSelectedAccounts() || []).map(({ id }) => id).sort();
    if (selectedAccountIds.length === 0) {
      selectedAccountIds = (getCachedDashboardActiveAccounts() || []).map(({ id }) => id).sort();
    }

    const accountIdsCache = (eventFiltersCached.accounts || [])
      .map(({ id }: { id: AccountSelection['id'] }) => id)
      .sort();

    if (isEqual(selectedAccountIds, accountIdsCache)) {
      eventFiltersCached.accounts = [];
    }
  }
  return eventFiltersCached;
};

export const getCachedTeamDashboardEventFilters = (place: CachePageLocation) => {
  const eventFiltersCached = getCachedEventFilters(place) || {};
  if (!isEmpty(eventFiltersCached)) {
    let selectedAccountIds = (getCachedDashboardSelectedAccounts() || []).map(({ id }) => id).sort();
    if (selectedAccountIds.length === 0) {
      selectedAccountIds = (getCachedDashboardActiveAccounts() || []).map(({ id }) => id).sort();
    }

    const accountIdsCache = (eventFiltersCached.accounts || [])
      .map(({ id }: { id: AccountSelection['id'] }) => id)
      .sort();

    if (isEqual(selectedAccountIds, accountIdsCache)) {
      eventFiltersCached.accounts = [];
    }
  }
  return eventFiltersCached;
};
