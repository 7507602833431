export interface PageTitleProps {
  title: string;
  customClass?: string;
}

const PageTitle = ({ title, customClass }: PageTitleProps) => (
  <h1 className={`gs--font-family-san gs--page-title gs--text ${customClass || ''}`}>{title}</h1>
);

export { PageTitle };
