import { RootState } from './types';
import { RootActionTypes, ADD_MESSAGE, REMOVE_MESSAGE } from './actionTypes';

export const initialState: RootState = {
  messages: []
};

export default (state = initialState, action: RootActionTypes): RootState => {
  switch (action.type) {
    case ADD_MESSAGE: {
      const { message } = action.payload;
      const exist = state.messages.find(msg => msg.id === message.id);
      return {
        messages: exist ? state.messages: [...state.messages, message]
      }
    }
    case REMOVE_MESSAGE:
      return {
        messages: state.messages.filter(message => message.id !== action.payload.id)
      }
    default:
      return state;
  }
};
