/* eslint-disable no-underscore-dangle */
import memoizeOne from 'memoize-one';
import moment from 'moment';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { split, get, omit, uniqWith, uniqueId, chain, isEqual, pick } from 'lodash';
import flatten from 'lodash/flatten';
import type { DateRange } from '@app/modules/history/types/history.model';
import type {
  SeasonImportDTO,
  SeasonDuration,
  SeasonDataSetting,
  SeasonDTO,
  SeasonProduct,
  PublishedSeasonInfo
} from '@seasons/models/season.model';

import type { SortBy } from '@app/commons/generic-components/basic-table/sorting';
import { sortStates } from '@app/commons/generic-components/basic-table/sorting';
import { switchZone, formatDateTime, EVENT_DATE_FORMAT_WITH_TIMEZONE, getTimeZone } from '@app/utils/dateUtils';
import { isDifference, isEmpty } from '@app/utils/objectUtils';
import { parseNumber } from '@app/utils/parseUtils';
import { MAX_CAPACITY, SEARCH_PARAMS, TYPE_FILTER_DATE, DISABLE_SETTINGS_FIELDS } from '@seasons/constants/constants';

import { EVENT_TEMPLATE, SEASON_ELEMENT_STATES } from '@app/pages/SeasonSetup/constants';
import { genderKeyMap } from '@app/pages/SeasonSetup/helpers';
import type { EventDTO } from '@app/modules/events/models/event.model';
import { ALL_GENDER_KEYS } from '@app/modules/levels/constants/constants';
import type { ActivityDTO } from '@gofan/api/activities';
import type { LevelDTO } from '@gofan/api/levels';
import type {
  SeasonDTO as GFSeasonDTO,
  FormFieldDTO,
  EventProduct as GFEventProduct,
  SeasonProduct as GFSeasonProduct
} from '@gofan/api';
import SeasonService from '@seasons/services/season.service';
import {
  getEndDateSeason,
  getStartDateSeason,
  buildAssociatedEvents,
  buildAssociatedEventRequest,
  buildLevels,
  buildEndDateTimeEvent
} from '@app/api/services/SeasonService';
import { isOverEventEndDate } from '@app/api/services/EventService';
import type PublishEventRequest from '@app/api/model/request/PublishEventRequest';
import { RateService } from '@gofan/api/rates';
import { AccountProductService } from '@gofan/api/account-products';
import { ARBITER_PARTNER_NAME } from '@modules/event-integrations_V2/constants/constants';
import { pluralizeNoun, pluralizeVerb } from '@app/utils/stringUtils';
import { DISTRIBUTION_CHANNEL } from '@app/api/model/request/ProductRequest';
import { config } from '@gofan/constants';
import type { EventProduct, ProductTypeAndPrice } from '@app/api/types';
import type { AccountProductDTO } from '@gofan/api/account-products';
import { GENDER_TYPE, DEFAULT_START_TIME, EVENT_START_TIME_TYPE } from '@season-management/constants/constant';
import SeasonCreationUtil from '@season-management/utils/season-creation.utils';
import { AccountService } from '@gofan/api/accounts';
import type { AccountDTO } from '@gofan/api/accounts';
import { checkPermissionHandlingEvent } from '@app/api/services/UserService';
import type { UserDTO } from '@gofan/api/users';
import EventScheduleService from '@app/modules/event-integrations_V2/services/event-schedule.service';
import { VenueService } from '@gofan/api/venues';
import { DateUtils } from '@gofan/utils/date';
import {
  TIME_FORMAT_DEFAULT,
  DATE_PICKER_FORMAT_DEFAULT,
  DATE_FORMAT_DEFAULT_WITH_TIMEZONE
} from '@gofan/constants/date';
import isNil from 'lodash/isNil';
import { EventService } from '@gofan/api/events';
import { EMPTY_NUMBER, EMPTY_STRING } from '@gofan/constants/event';
import { getUniqueId } from '@gofan/utils';

dayjs.extend(minMax);

export const getSortBy = ({ header, sortDirection }: SortBy) => {
  if (!sortDirection || sortDirection === sortStates.NONE) {
    if (isEmpty(SEARCH_PARAMS.sortBy) || `${SEARCH_PARAMS.sortBy.header}` === `${header}`) {
      return {};
    }
    return SEARCH_PARAMS.sortBy;
  }
  return { header, sortDirection };
};

export const getFilterDate = (type: string, date?: Date) => {
  if (!date) {
    return undefined;
  }
  if (type === TYPE_FILTER_DATE.START) {
    return `${moment(date).startOf('date').add(1, 'seconds').format(EVENT_DATE_FORMAT_WITH_TIMEZONE)}`;
  }
  if (type === TYPE_FILTER_DATE.END) {
    return `${moment(date).endOf('date').format(EVENT_DATE_FORMAT_WITH_TIMEZONE)}`;
  }

  return undefined;
};

export const calculateSeasonDurationBy = memoizeOne((currentDate: Date): SeasonDuration => {
  const currentYear: number = currentDate.getFullYear();
  const startDateOfCurrentYear = new Date(new Date(currentDate.getTime()).setMonth(6, 1));

  let seasonYear = currentYear;
  if (currentDate < startDateOfCurrentYear) {
    seasonYear -= 1;
  }

  const seasonStartTime: Date = new Date(
    currentDate.setFullYear(seasonYear, 6, 1) // July-01-currentYear
  );
  const seasonEndTime: Date = new Date(
    currentDate.setFullYear(seasonYear + 1, 5, 29) // June-30-currentYear+1
  );
  seasonStartTime.setUTCHours(0, 0, 0, 0);
  seasonEndTime.setUTCHours(23, 59, 59, 999);
  return {
    seasonStartTime,
    seasonEndTime
  };
});

export const calculateSeasonDurationOfCurrentDate = (): SeasonDuration => calculateSeasonDurationBy(new Date());

export const SeasonDateRangeFilters = {
  lastWeek: (season: SeasonImportDTO) => {
    if (!season.createdAt) {
      return false;
    }
    const createdAt = new Date(season.createdAt);
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
    lastWeek.setHours(0, 0, 0, 0);
    const seasonTime = calculateSeasonDurationOfCurrentDate();
    return createdAt >= lastWeek && createdAt >= seasonTime.seasonStartTime;
  },
  lastMonth: (season: SeasonImportDTO) => {
    if (!season.createdAt) {
      return false;
    }
    const createdAt = new Date(season.createdAt);
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    lastMonth.setHours(0, 0, 0, 0);
    const seasonTime = calculateSeasonDurationOfCurrentDate();
    return createdAt >= lastMonth && createdAt >= seasonTime.seasonStartTime;
  },
  all: (season: SeasonImportDTO) => {
    if (!season.createdAt) {
      return false;
    }
    const createdAt = new Date(season.createdAt);
    const seasonTime = calculateSeasonDurationOfCurrentDate();
    return createdAt >= seasonTime.seasonStartTime;
  }
};

export const selectSeasonImportByDateRange = (dateRange: DateRange) => (season: SeasonImportDTO) =>
  SeasonDateRangeFilters[dateRange](season);

export const getGroupLevelsByGender = ({ levels = {}, selectedGenders = [] }: any) => {
  const genders: string[] = [];
  let levelsByGender: any = {};
  [...selectedGenders].forEach(gender => {
    genderKeyMap.forEach((genderValue, genderKey) => {
      if (`${genderValue}`.toLowerCase() === `${gender}`.toLowerCase()) {
        genders.push(genderKey);
        levelsByGender = {
          ...levelsByGender,
          [genderKey]: isEmpty(levels?.[genderValue]) ? [] : levels?.[genderValue]
        };
      }
    });
  });
  return { genders, levelsByGender };
};

export const buildDefaultStartTimeTeams = ({
  startDateTime,
  allDayEvent = false,
  sameStartTime = { ...DEFAULT_START_TIME },
  startTimeTeams = {},
  startTimeType = EVENT_START_TIME_TYPE.ALL_TEAMS
}: any) => ({
  ...(isEmpty(startDateTime)
    ? {}
    : {
        startDateTime: {
          value: moment(startDateTime).format(EVENT_DATE_FORMAT_WITH_TIMEZONE),
          defaultValue: moment(startDateTime).format(EVENT_DATE_FORMAT_WITH_TIMEZONE)
        }
      }),
  allDayEvent: {
    value: allDayEvent,
    defaultValue: allDayEvent
  },
  startTimeType: {
    value: startTimeType,
    defaultValue: startTimeType
  },
  sameStartTime: {
    value: sameStartTime,
    defaultValue: sameStartTime
  },
  startTimeOptions: Object.keys(startTimeTeams ?? {}).reduce((res, startTimeId) => {
    const startTimeOption = startTimeTeams?.[startTimeId];
    return {
      ...res,
      [startTimeId]: {
        value: startTimeOption,
        defaultValue: startTimeOption
      }
    };
  }, {})
});

export const convertToEventSetting = ({
  season,
  event,
  levels = [],
  activities = [],
  isSeasonSetting = false
}: {
  event: EventDTO;
  season: SeasonDTO;
  levels: LevelDTO[];
  activities: ActivityDTO[];
  isSeasonSetting?: boolean;
}) => {
  const isUseDefault = isSeasonSetting && isEmpty(event);
  const timeZone = get(season, '_embedded.account.timeZone', '' as string);
  // [VENUE]
  const venueInfo = {
    venueId: {
      value: isSeasonSetting ? season.venueId : event.venueId,
      valid: true
    },
    venueName: {
      value: `${event.venueName ?? ''}`,
      valid: true
    },
    venueAddress: {
      value: `${event.venueAddress ?? ''}`,
      valid: true
    },
    venueCity: {
      value: `${event.venueCity ?? ''}`,
      valid: true
    },
    venueState: {
      value: `${event.venueState ?? ''}`,
      valid: true
    },
    venueZip: {
      value: `${event.venueZip ?? ''}`,
      valid: true
    },
    venueLocation: {
      value: `${event.venueLocation ?? ''}`,
      valid: true
    }
  };

  // [SECTION][school]
  const accountOpponent = get(event, '_embedded.account-opponent', {}) ?? {};
  const school = {
    ...SEASON_ELEMENT_STATES,
    valid: true,
    value: accountOpponent
  };

  // [SECTION][seasonBuyerPurchaseLimit]
  const seasonTicketLimitPerOrder = isSeasonSetting ? season.ticketLimitPerOrder : null;
  const seasonBuyerPurchaseLimit = {
    section: {
      ticketLimitPerOrder: {
        ...SEASON_ELEMENT_STATES,
        valid: true,
        value: seasonTicketLimitPerOrder
      }
    },
    valid: true,
    value: seasonTicketLimitPerOrder
  };

  // [SECTION][buyerPurchaseLimit]
  const ticketLimitPerOrder = isUseDefault ? 30 : event.ticketLimitPerOrder;
  const buyerPurchaseLimit = {
    section: {
      ticketLimitPerOrder: {
        ...SEASON_ELEMENT_STATES,
        valid: true,
        value: ticketLimitPerOrder
      }
    },
    valid: true,
    value: ticketLimitPerOrder
  };

  // [SECTION][eventName]
  const eventName = {
    section: {
      ...SEASON_ELEMENT_STATES,
      valid: true,
      eventId: event.id,
      value: event.name
    },
    valid: true,
    value: event.name
  };
  const seasonName = isSeasonSetting
    ? {
        section: {
          ...SEASON_ELEMENT_STATES,
          valid: true,
          eventId: event.id,
          value: season?.name ?? event.name ?? ''
        },
        valid: true,
        value: { value: season?.name ?? event.name ?? '' }
      }
    : {};

  // [SECTION][eventAlert]
  const eventAlert = {
    section: {
      alert: {
        ...SEASON_ELEMENT_STATES,
        valid: true,
        value: event.alert ?? ''
      },
      eventId: event.id
    },
    valid: true,
    value: event.alert ?? ''
  };

  // [SECTION][startTime][startDateAndTime]
  let eventDate = {};
  let eventDateAndTime = {};
  let endDateTime = null;
  let eventDuration = 0;
  if (isSeasonSetting) {
    eventDate = { ...EVENT_TEMPLATE.eventDateAndTime };
    eventDateAndTime = { ...EVENT_TEMPLATE.eventDateAndTime };
  } else {
    const eventDateMoment = formatDateTime({ date: event.startDateTime, timeZone: event.timeZone, parseZone: true });
    const eventStartDate = moment(switchZone(eventDateMoment.toDate(EVENT_DATE_FORMAT_WITH_TIMEZONE), 'localZone'));
    const endDateMoment = formatDateTime({ date: event.endDateTime, timeZone: event.timeZone, parseZone: true });
    endDateTime = moment(switchZone(endDateMoment.toDate(EVENT_DATE_FORMAT_WITH_TIMEZONE), 'localZone'));
    eventDuration = endDateTime.diff(eventStartDate);
    const startDateTime = eventDateMoment.toTime('hh:mm');
    const startDateElements = startDateTime ? split(startDateTime, ':') : ['0', '0'];
    const eventStartTime = {
      minDate: eventStartDate?.clone(),
      allDayEvent: { value: event.allDayEvent, valid: true },
      eventDate: {
        ...SEASON_ELEMENT_STATES,
        valid: true,
        value: eventStartDate?.clone()
      },
      eventTime: {
        ...SEASON_ELEMENT_STATES,
        valid: true,
        hour: parseInt(startDateElements[0], 10),
        minute: parseInt(startDateElements[1].length === 1 ? `0${startDateElements[1]}` : startDateElements[1], 10),
        second: 1,
        type: `${eventDateMoment.toTime('A')}`.toUpperCase(),
        value: startDateTime
      }
    };
    eventDate = {
      ...SEASON_ELEMENT_STATES,
      valid: true,
      value: eventStartDate?.clone()
    };
    eventDateAndTime = {
      valid: true,
      value: { ...eventStartTime },
      section: { ...eventStartTime }
    };
  }

  // [SECTION][SEASON_TIME_SETTING][startTime]
  const seasonStartTime = {
    allDayEvent: { value: event.allDayEvent, valid: true },
    startTime: isSeasonSetting
      ? { ...EVENT_TEMPLATE.startTime, ...event.seasonStartTime }
      : { ...EVENT_TEMPLATE.startTime }
  };
  const startTime = {
    valid: true,
    value: { ...seasonStartTime },
    section: { ...seasonStartTime }
  };

  // [SECTION][seasonVisibility]
  const seasonVisibilitySection = isSeasonSetting ? getVisibilitySection(season) : {};
  const seasonVisibility = {
    valid: true,
    value: { ...seasonVisibilitySection },
    section: { ...seasonVisibilitySection }
  };

  // [SECTION][eventVisibility]
  const eventVisibilitySection = getVisibilitySection(event);
  const eventVisibility = {
    valid: true,
    value: { ...eventVisibilitySection },
    section: { ...eventVisibilitySection }
  };

  // [SECTION][gateOpeningTime]
  const eventValidationStartsBefore = isUseDefault ? 4 * 60 : event.eventValidationStartsBefore;
  const eventEnableEventValidation = isUseDefault ? true : event.enableEventValidation;
  const ticketValidBefore = eventEnableEventValidation ? eventValidationStartsBefore : 0;
  const gateOpenSection = {
    ticketValidAnytime: { value: !eventEnableEventValidation, valid: true },
    ticketValidBefore: { value: (ticketValidBefore || 0) / 60, valid: true }
  };
  const gateOpeningTime = {
    valid: true,
    value: { ...gateOpenSection },
    section: { ...gateOpenSection }
  };

  // [SECTION][EventType]
  const embeddedLevels = get(event, '_embedded.levels', []) ?? [];
  const levelByGender = ((eventLevels = [], levelsInfo = []) => {
    const result: any = {};
    eventLevels.forEach(({ levelId, genders }: any) => {
      const foundedLevel = levelsInfo.find((item: any) => `${item.id}` === `${levelId}`);
      const level = foundedLevel
        ? {
            id: foundedLevel.id,
            text: foundedLevel.name,
            label: foundedLevel.name
          }
        : {};
      if (isEmpty(genders)) {
        if (isEmpty(result[ALL_GENDER_KEYS.EITHER.key])) {
          result[ALL_GENDER_KEYS.EITHER.key] = [level];
        } else {
          result[ALL_GENDER_KEYS.EITHER.key].push(level);
        }
      }
      genderKeyMap.forEach((genderKey, key) => {
        if (genders.includes(key)) {
          if (isEmpty(result[genderKey])) {
            result[genderKey] = [level];
          } else {
            result[genderKey].push(level);
          }
        }
      });
    });
    return result;
  })(event.levels, isSeasonSetting ? levels : embeddedLevels);
  const genders = get(event, 'genders', []) ?? [];
  const hasGenderBoy = genders.find((g: any) => g === ALL_GENDER_KEYS.BOYS.key);
  const hasGenderGirl = genders.find((g: any) => g === ALL_GENDER_KEYS.GIRLS.key);
  const isSelectedBoy = !isEmpty(levelByGender.boy) || hasGenderBoy;
  const isSelectedGirl = !isEmpty(levelByGender.girl) || hasGenderGirl;
  const isSelectedEither = !isEmpty(levelByGender.either) || !(isSelectedBoy || isSelectedGirl);
  const embeddedActivity = get(event, '_embedded.activity', {}) ?? {};
  const embeddedEventType = get(event, '_embedded.event-type', {}) ?? {};
  const activity = isSeasonSetting ? activities.find(a => a.id === event.activityId) : embeddedActivity;
  const activityGenderSpecific = !isEmpty(activity) ? activity.genderSpecific : false;
  const activityForBoysEditable = activityGenderSpecific
    ? `${activity?.gender}`.toUpperCase() === GENDER_TYPE.BOYS
    : true;
  const activityForGirlsEditable = activityGenderSpecific
    ? `${activity?.gender}`.toUpperCase() === GENDER_TYPE.GIRLS
    : true;
  const activitySetting = { ...SEASON_ELEMENT_STATES, valid: true, value: activity };
  const eventTypeSetting = { ...SEASON_ELEMENT_STATES, valid: true, value: embeddedEventType };
  const eventTypeSection = {
    activity: activitySetting,
    eventType: eventTypeSetting,
    levels: {
      boy: {
        valid: true,
        editable: true,
        hidden: !isSelectedBoy,
        value: !isEmpty(levelByGender.boy) ? levelByGender.boy : []
      },
      girl: {
        valid: true,
        editable: true,
        hidden: !isSelectedGirl,
        value: !isEmpty(levelByGender.girl) ? levelByGender.girl : []
      },
      either: {
        valid: true,
        editable: true,
        hidden: !isSelectedEither,
        value: !isEmpty(levelByGender.either) ? levelByGender.either : []
      }
    },
    genders: {
      ...SEASON_ELEMENT_STATES,
      boy: {
        hidden: false,
        editable: activityForBoysEditable,
        value: { selected: isSelectedBoy }
      },
      girl: {
        hidden: false,
        editable: activityForGirlsEditable,
        value: { selected: isSelectedGirl }
      },
      event: {
        hidden: false,
        editable: true,
        value: { selected: !isSelectedEither }
      },
      either: {
        hidden: false,
        editable: true,
        value: { selected: isSelectedEither }
      },
      valid: true
    }
  };
  const eventType = {
    valid: true,
    value: { ...eventTypeSection },
    section: { ...eventTypeSection }
  };

  // [SECTION][START_TIME_TEAMS]
  const selectedGenders = [
    ...(isSelectedBoy ? ['boy'] : []),
    ...(isSelectedGirl ? ['girl'] : []),
    ...(isSelectedEither ? ['either'] : [])
  ];
  const { genders: genderTypes, levelsByGender } = getGroupLevelsByGender({
    selectedGenders,
    levels: {
      boy: eventTypeSection?.levels?.boy?.value,
      girl: eventTypeSection?.levels?.girl?.value,
      either: eventTypeSection?.levels?.either?.value
    }
  });
  const startTimeType = event?.startTimeType ?? EVENT_START_TIME_TYPE.ALL_TEAMS;
  const startTimeOptions =
    isEmpty(event?.startTimeOptions) || startTimeType === EVENT_START_TIME_TYPE.ALL_TEAMS
      ? {}
      : event?.startTimeOptions;
  const eventStartTimeTeams = SeasonCreationUtil.convertStartTimeOptionsToTeams({
    sportId: `${event?.activityId}`,
    genders: genderTypes,
    levelsByGender,
    startTimeType,
    startTimeOptions
  });

  let startTimeTeams = {};

  if (isSeasonSetting) {
    startTimeTeams = buildDefaultStartTimeTeams({
      allDayEvent: !!event.allDayEvent,
      startTimeType,
      startTimeTeams: eventStartTimeTeams,
      sameStartTime: {
        time: event?.seasonStartTime?.time ?? DEFAULT_START_TIME.time,
        period: event?.seasonStartTime?.period ?? DEFAULT_START_TIME.period
      }
    });
  } else {
    const formattedStartDateTime = formatDateTime({
      date: event?.startDateTime,
      timeZone: event?.timeZone,
      parseZone: true
    });
    const eventStartDateTime = switchZone(formattedStartDateTime.toDate(EVENT_DATE_FORMAT_WITH_TIMEZONE), 'localZone');
    const mEventStartDateTime = moment(eventStartDateTime);

    startTimeTeams = buildDefaultStartTimeTeams({
      allDayEvent: !!event.allDayEvent,
      startTimeType,
      startTimeTeams: eventStartTimeTeams,
      startDateTime: mEventStartDateTime.clone(),
      sameStartTime: {
        time: mEventStartDateTime.clone().format('hh:mm'),
        period: mEventStartDateTime.clone().format('A')
      }
    });
  }

  // [SECTION][ticketAvailability]
  const eventMaxCapacity =
    event?.maxCapacity === MAX_CAPACITY || isEmpty(event?.maxCapacity) ? undefined : event?.maxCapacity;
  const mapFormFields = (data: EventProduct[], formFields: FormFieldDTO[]) =>
    data.map(item => ({ ...item, formFields: !isEmpty(formFields) ? formFields : item.formFields }));

  const products =
    (isSeasonSetting ? event?.products : mapFormFields(event?.products as EventProduct[], event.formFields)) ?? [];
  const seasonTickets =
    (isSeasonSetting ? mapFormFields(season?.products as EventProduct[], season.formFields) : []) ?? [];

  const { eventProductSoldIds, seasonProductSoldIds } = getDataSalesInfo(season);
  const productsValue = {
    maxCapacity: eventMaxCapacity,
    unlimitedTicket: isEmpty(eventMaxCapacity),
    products:
      groupAccountProducts(products as EventProduct[])?.map((p: any) => {
        const productLimit = p?.limit === MAX_CAPACITY || isEmpty(p?.limit) ? undefined : p?.limit;
        const now = uniqueId(new Date().getTime().toString());
        const [ticketOptionalSetting] = getTicketOptionalData({
          tickets: [p],
          timeZone,
          productSoldIds: eventProductSoldIds
        });

        return {
          ...ticketOptionalSetting,
          created: isSeasonSetting || p.created,
          limit: productLimit,
          id: p?.id ?? now,
          productCreatedAt: p?.productCreatedAt ?? now,
          boxOfficeTicket: !isEmpty(p.boxOfficeTicket)
            ? {
                ...p.boxOfficeTicket,
                created: isSeasonSetting || p.boxOfficeTicket.created,
                fee:
                  !eventProductSoldIds.includes(p?.boxOfficeTicket.Id) && p?.boxOfficeTicket?.hiddenFees
                    ? p?.boxOfficeTicket?.hiddenFeeBase
                    : p?.boxOfficeTicket?.fee
              }
            : {}
        };
      }) ?? [],
    seasonTickets: groupAccountProducts(seasonTickets as EventProduct[]).map(product => {
      const [ticketOptionalSetting] = getTicketOptionalData({
        tickets: [product],
        timeZone,
        productSoldIds: seasonProductSoldIds
      });

      return ticketOptionalSetting;
    })
  };

  const ticketAvailability = {
    valid: true,
    value: { ...productsValue },
    section: { values: { ...productsValue } }
  };

  // GLCODE/FINANCIAL SETTINGS
  const glCode = {
    valid: true,
    value: season.glCode
  };

  // [SECTION][ADDITIONAL_FORM]
  const formFields = event?.formFields ?? [];
  const additionalForm = {
    value: [...formFields],
    savedValue: [...formFields]
  };

  // [CHECK_ARBITER]
  const partnerId = event?.partnerId;
  const globalEventId = event?.globalEventsId;
  const partnerName = isSeasonSetting ? season?.partnerName : event?.partnerName;
  const isArbiter = partnerName === ARBITER_PARTNER_NAME;

  return {
    ...EVENT_TEMPLATE,
    ...venueInfo,
    id: event.id,
    startTimeTeams,
    isArbiter,
    partnerId,
    partnerName,
    globalEventId,
    created: true,
    school,
    seasonName,
    seasonBuyerPurchaseLimit,
    buyerPurchaseLimit,
    eventName,
    eventAlert,
    eventDate,
    eventDateAndTime,
    endDateTime: !isSeasonSetting ? endDateTime : null,
    eventDuration: !isSeasonSetting ? eventDuration : 0,
    shouldKeepEventDurationWhenEdit: !isSeasonSetting,
    initAllDayEvent: !isSeasonSetting ? event.allDayEvent : undefined,
    seasonVisibility,
    eventVisibility,
    gateOpeningTime,
    startTime,
    eventType,
    additionalForm,
    ticketAvailability,
    ticketDistribution: event.ticketDistribution,
    accountsTicket: event.accountsTicket,
    accountId: event.accountId,
    financialAccountId: event.financialAccountId,
    glCode,
    ...(isSeasonSetting ? {} : { originalExistingEvent: omit(event, ['_embedded']) })
  };
};

export const convertToSeasonSettings = ({
  season,
  levels = [],
  activities = []
}: {
  season: SeasonDTO;
  levels: LevelDTO[];
  activities: ActivityDTO[];
}) => {
  const seasonSettings = season.config?.seasonSettings ?? '';
  const settingEvents = (season?.events || []).map(event =>
    convertToEventSetting({ season, event, levels, activities })
  );
  const settings = convertToEventSetting({
    season,
    event: isEmpty(seasonSettings) ? {} : JSON.parse(seasonSettings),
    levels,
    activities,
    isSeasonSetting: true
  });

  return { settings, events: settingEvents };
};

export const convertToAccountLevels = (seasonData: SeasonDataSetting) => {
  const levelsData = seasonData?.settings?.eventType?.value?.levels ?? {};
  return Object.keys(levelsData).flatMap((gender: any) => {
    const data = levelsData[gender]?.value ?? [];
    return data.map((level: any) => ({
      gender: [gender],
      levelId: level.id,
      levelName: level.text
    }));
  });
};

export const getValidSeasonDateTime = (currentDate: string, newDate: string) => {
  const now = dayjs().utc();
  const mCurrentDate = dayjs(currentDate).utc();
  const mNewDate = dayjs(newDate).utc();

  if (mNewDate.isAfter(now) || mCurrentDate.isSame(mNewDate)) return newDate;
  return mCurrentDate.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE);
};

export const buildSeasonRequest = (values: SeasonDTO, updatedValues: SeasonDataSetting) => {
  const { seasonProductSoldIds, eventProductSoldIds } = getDataSalesInfo(values);
  const account = get(values, '_embedded.account', {} as AccountDTO);
  const seasonProducts: SeasonProduct[] = get(values, 'products', []);
  const events: EventDTO[] = get(values, '_embedded.season-events.content', []);
  const {
    seasonName,
    ticketAvailability,
    seasonVisibility,
    seasonBuyerPurchaseLimit,
    venueId,
    venueCity,
    venueState,
    venueAddress,
    venueLocation,
    venueName,
    venueZip
  } = updatedValues.settings;
  const { seasonTickets: seasonTicketsValue = [] } = ticketAvailability.value;
  const seasonTickets = seasonTicketsValue?.map((product: SeasonProduct) => {
    const foundProduct = (seasonProducts ?? [])?.find((p: SeasonProduct) => `${p.id}` === `${product.id}`);
    const formFields = (product.formFields ?? []).map((formField: FormFieldDTO) => ({
      ...formField,
      id: null,
      description: formField.description || null,
      options:
        formField.fieldType === 'DROPDOWN' && (formField?.options ?? []).length > 0
          ? formField.options?.map(({ value }) => value)
          : null
    }));

    let salesStartDateTime;
    let salesEndDateTime;
    if (!isEmpty(product?.salesStartDate) && !isEmpty(product?.salesStartTime)) {
      const mSalesStartDateTime = dayjs(`${product?.salesStartDate} ${product?.salesStartTime}`);
      salesStartDateTime = switchZone(
        mSalesStartDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE),
        account?.timeZone
      );
    }

    if (!isEmpty(product?.salesEndDate) && !isEmpty(product?.salesEndTime)) {
      const mSalesEndDateTime = dayjs(`${product?.salesEndDate} ${product?.salesEndTime}`);
      salesEndDateTime = switchZone(
        mSalesEndDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE),
        account?.timeZone
      );
    }

    let rateId: number | null = product.rateId ?? null;
    const productFee = foundProduct?.hiddenFees ? foundProduct?.hiddenFeeBase : foundProduct?.fee;
    if (!isEmpty(foundProduct) && !seasonProductSoldIds.includes(Number(product.id)) && productFee !== product.fee) {
      rateId = null;
    }

    return {
      ...omit(isEmpty(foundProduct) ? {} : foundProduct, ['_links', '_embedded']),
      ...omit(product, [
        'created',
        'productCreatedAt',
        'salesStartDate',
        'salesStartTime',
        'salesEndDate',
        'salesEndTime'
      ]),
      id: product.created ? product.id : undefined,
      formFields,
      rateId,
      salesStartDateTime,
      salesEndDateTime
    };
  });
  const seasonSettings = buildAssociatedEventRequest(updatedValues.settings, account, true);
  const updatedEvents = buildAssociatedEvents(updatedValues.events, account);
  const startDateTime = isEmpty(updatedEvents)
    ? values?.startDateTime
    : getValidSeasonDateTime(values?.startDateTime, getStartDateSeason(updatedEvents));
  const endDateTime = isEmpty(updatedEvents)
    ? values?.endDateTime
    : getValidSeasonDateTime(values?.endDateTime, getEndDateSeason(updatedEvents));

  const associatedEvents = updatedEvents.map((e: PublishEventRequest) => {
    // Apply partial rule (GSE-2410) for event.
    const updatedEvent: any = e.toJSON();
    const foundEvent = events.find(item => `${item.id}` === `${e.id}`);

    if (!foundEvent) return { ...updatedEvent, formFields: [], formId: null };

    const eventMaxCapacity =
      updatedEvent?.maxCapacity === MAX_CAPACITY ||
      updatedEvent?.maxCapacity === undefined ||
      updatedEvent?.maxCapacity === null
        ? MAX_CAPACITY
        : updatedEvent?.maxCapacity;
    const updatedEventProducts: any = updatedEvent.products.map((updateProduct: any) => {
      const foundProduct = foundEvent.products?.find((item: any) => `${item.id}` === `${updateProduct.id}`);
      if (!foundProduct) return { ...updateProduct };
      const productLimit =
        eventMaxCapacity === MAX_CAPACITY ||
        updateProduct?.limit === MAX_CAPACITY ||
        updateProduct?.limit === undefined ||
        updateProduct?.limit === null
          ? MAX_CAPACITY
          : updateProduct?.limit;

      const feeRateId = !eventProductSoldIds.includes(Number(updateProduct.id))
        ? EventService.populateFeeAndRateId(updateProduct, foundEvent, 'Edit event season', EMPTY_NUMBER)
        : { fee: foundProduct?.fee };

      return {
        ...omit(foundProduct, ['_links', '_embedded']),
        ...omit(updateProduct, ['created']),
        limit: productLimit,
        ...feeRateId,
        salesStartDateTime: updateProduct.salesStartDateTime ? updateProduct.salesStartDateTime : EMPTY_STRING,
        salesEndDateTime: updateProduct.salesEndDateTime ? updateProduct.salesEndDateTime : EMPTY_STRING,
        ticketLimitPerOrder: updateProduct.ticketLimitPerOrder ? updateProduct.ticketLimitPerOrder : EMPTY_NUMBER,
        packCount: updateProduct.packCount ? updateProduct.packCount : EMPTY_NUMBER
      };
    });

    return {
      ...omit(foundEvent, ['_links', '_embedded']),
      ...updatedEvent,
      products: updatedEventProducts,
      maxCapacity: eventMaxCapacity,
      formFields: [],
      formId: null,
      alert: e.raw.alert ? e.raw.alert : ''
    };
  });

  const timeZone = getTimeZone(account.timeZone);
  const { archived, publishDate } = seasonVisibility.value;
  let publishDateTime = null;
  if (archived.value) {
    const publishDateValue = moment.isMoment(publishDate.value) ? publishDate.value : moment(publishDate.value);
    publishDateTime = publishDateValue.isValid()
      ? switchZone(publishDateValue.format(EVENT_DATE_FORMAT_WITH_TIMEZONE), timeZone)
      : null;
  }
  const ticketLimitPerOrder = parseNumber(seasonBuyerPurchaseLimit.value);

  return {
    ...omit(values, ['_links', '_embedded']),
    config: { seasonSettings: JSON.stringify(seasonSettings.toJSON()) },
    events: associatedEvents,
    eventIds: associatedEvents.map((e: any) => e.id).filter((id: any) => !!id),
    archived: archived.value,
    publishDateTime,
    ticketLimitPerOrder,
    venueId: venueId?.value,
    venueState: venueState.value ?? '',
    venueCity: venueCity.value ?? '',
    venueAddress: venueAddress.value ?? '',
    venueLocation: venueLocation.value ?? '',
    venueName: venueName.value ?? '',
    venueZip: venueZip.value ?? '',
    name: seasonName.value.value,
    startDateTime,
    endDateTime,
    products: seasonTickets,
    formFields: [],
    formId: null,
    glCode: seasonSettings.glCode
  };
};

export const getRemovedGlobalEvents = (values: SeasonDTO, updatedValues: SeasonDataSetting) =>
  values?.events?.filter(
    originalEvent =>
      !updatedValues?.events?.some(event => `${event?.id}` === `${originalEvent?.id}`) && originalEvent?.globalEventsId
  );

export const getProductsUpdateForEvent = (event: any, data: any) => {
  const ticketAvailabilityUpdated: any = { ...data };
  const updateSection = ticketAvailabilityUpdated?.section || {};
  const updateProducts = ticketAvailabilityUpdated?.section?.values?.products || [];
  const existProducts = event?.ticketAvailability?.section?.values?.products || [];
  const products: any = [];
  updateProducts.forEach((product: any) => {
    if (existProducts.find((existProduct: any) => existProduct.id === product.id && !existProduct.isAddedFromEvent)) {
      products.push({ ...product, isAddedFromEvent: false });
    } else {
      products.push({ ...product, isAddedFromEvent: true });
    }
  });

  return {
    ...ticketAvailabilityUpdated,
    section: {
      ...updateSection,
      values: { ...ticketAvailabilityUpdated?.section?.values, products }
    }
  };
};

export const compareCreatedProduct = (product1: any, product2: any): boolean =>
  `${product1.id}` === `${product2.id}` ||
  (`${product1.name ?? ''}` === `${product2.name ?? ''}` &&
    `${product1.hiddenFees ?? false}` === `${product2.hiddenFees ?? false}` &&
    `${product1.price ?? ''}` === `${product2.price ?? ''}`);

export const cloneTicketTypeSettingForEvent = ({
  event,
  data,
  financialAccounts,
  isOverrideCreatedProduct = false
}: {
  event: any;
  data: any;
  financialAccounts: AccountDTO[];
  isOverrideCreatedProduct?: boolean;
}) => {
  const { created: eventCreated, financialAccountId } = event;
  const ignoreHiddenFees = AccountService.isDailyTransactionClose(
    financialAccounts.find(item => item.id === financialAccountId)
  );
  const getHiddenFees = (product: any) => (ignoreHiddenFees ? false : product.hiddenFees);
  const {
    maxCapacity: eventMaxCapacity,
    unlimitedTicket: eventUnlimitedTicket,
    products: eventProducts = []
  } = event.ticketAvailability?.section?.values ?? {};
  const {
    maxCapacity: settingMaxCapacity,
    unlimitedTicket: settingUnlimitedTicket,
    products: settingProducts = []
  } = data.section?.values ?? {};
  let maxCapacity;
  let unlimitedTicket;
  let products: any = [];
  if (eventCreated) {
    const updatedProducts: any = [];
    settingProducts.forEach((p: any) => {
      const foundProduct = eventProducts.find((item: any) => `${item.id}` === `${p.id}`);
      if ((!foundProduct && !p.created) || (foundProduct && !foundProduct.created)) {
        const boxOfficeTicket = !isEmpty(p.boxOfficeTicket)
          ? { ...p.boxOfficeTicket, hiddenFees: getHiddenFees(p.boxOfficeTicket) }
          : {};
        updatedProducts.push({ ...p, hiddenFees: getHiddenFees(p), boxOfficeTicket });
      }
    });
    eventProducts.forEach((p: any) => {
      const foundProduct = updatedProducts.find((item: any) => `${item.id}` === `${p.id}`);
      if (!foundProduct) {
        const foundSettingProduct = settingProducts.find((item: any) => compareCreatedProduct(p, item));
        if (p.created || p.isAddedFromEvent || foundSettingProduct) {
          const overrideFields = isOverrideCreatedProduct ? pick(foundSettingProduct, ['formFields']) : {};
          updatedProducts.push({
            ...p,
            ...overrideFields,
            boxOfficeTicket: !isEmpty(p.boxOfficeTicket) ? { ...p.boxOfficeTicket, ...overrideFields } : {}
          });
        }
      }
    });
    products = [...updatedProducts];
    maxCapacity = data.section?.fields?.maxCapacity?.touched ? settingMaxCapacity : eventMaxCapacity;
    unlimitedTicket = data.section?.fields?.unlimitedTicket?.touched ? settingUnlimitedTicket : eventUnlimitedTicket;
    if (unlimitedTicket) maxCapacity = undefined;
  } else {
    products = settingProducts.map((item: any) => {
      const boxOfficeTicket = !isEmpty(item.boxOfficeTicket)
        ? { ...item.boxOfficeTicket, created: false, hiddenFees: getHiddenFees(item.boxOfficeTicket) }
        : {};
      return { ...item, created: false, hiddenFees: getHiddenFees(item), boxOfficeTicket };
    });
    maxCapacity = settingMaxCapacity;
    unlimitedTicket = settingUnlimitedTicket;
  }

  return { products, maxCapacity, unlimitedTicket, seasonTickets: [] };
};

export const getEventController = (events: any = [], foundEventId: any) => {
  const foundEvent = events.find((e: any) => `${e.id}` === `${foundEventId}`);

  if (isEmpty(foundEvent)) return {};
  const eventSalesInfo = get(foundEvent, `_embedded.event-sales-info`, {});
  const getProductSalesInfo = (productId: string) => get(eventSalesInfo?.productSalesMap, `${productId}`, {});

  return {
    eventSalesInfo,
    event: foundEvent,
    isEndEvent: isOverEventEndDate(foundEvent),
    hasSold: eventSalesInfo?.eventTotalSaleQuantity > 0,
    getProductSalesInfo,
    hasTicketSold: (productId: string) => {
      const productSales = getProductSalesInfo(productId);
      return (productSales?.saleQuantity ?? 0) > 0;
    }
  };
};

export const isEqualTicket = (ticket1: AccountProductDTO, ticket2: AccountProductDTO) =>
  ticket1.ticketName === ticket2.ticketName &&
  ticket1.ticketPrice === ticket2.ticketPrice &&
  ticket1.levelIds.some(id => ticket2.levelIds.includes(id)) &&
  ticket1.activityId === ticket2.activityId &&
  ticket1.accountPaid === ticket2.accountPaid &&
  ((isEmpty(ticket1.customColor) && isEmpty(ticket2.customColor)) || ticket1.customColor === ticket2.customColor);

export const getUniqAccountProducts = (accountProducts: AccountProductDTO[]) =>
  uniqWith(accountProducts, isEqualTicket);

export const canEditForField = ({ event, seasonController, section, eventId }: any) => {
  const ableEdit = () => true;
  const unableEdit = () => false;

  if (!seasonController?.isCreated) return ableEdit;

  if (!seasonController?.canEditSeason) return unableEdit;

  if (!eventId && (isEmpty(event) || !event.created)) {
    if (seasonController?.isSeasonSetting) {
      return (field?: string) => !get(DISABLE_SETTINGS_FIELDS, `${section}.${field}`, false);
    }
    return ableEdit;
  }

  const { accountId, financialAccountId, canceled } = event?.originalExistingEvent ?? {};
  if (
    !checkSeasonEventPermission({
      viewMode: false,
      currentUser: seasonController?.currentUser,
      accountId,
      financialAccountId
    })
  )
    return unableEdit;

  if (canceled) return unableEdit;

  const eventController = getEventController(seasonController?.events ?? [], event?.id || eventId);
  if (isEmpty(eventController)) return ableEdit;

  if (eventController?.hasSold) {
    const disabledSections = ['EVENT_TYPE', 'EVENT_LIST'];
    if (disabledSections.includes(section)) return unableEdit;
  }

  if (eventController?.isEndEvent) {
    if (section === 'EVENT_DATE_TIME') return ableEdit;
    return unableEdit;
  }

  return ableEdit;
};

const updateAccountProducts = async (season: SeasonDTO, updatedValues: any) => {
  try {
    const { newTickets, isMakeDefaultTicket, seasonData } = updatedValues;

    if (isEmpty(newTickets) || !isMakeDefaultTicket) return true;

    const { account } = season?._embedded ?? {};
    const accountId = account?.id;
    const ratesRes = await Promise.all([
      RateService.getRateById(config.RATE_ID1),
      RateService.getRateById(config.RATE_ID5),
      RateService.getRateById(config.RATE_ID8)
    ]);

    const levelIds = seasonData?.settings?.eventType?.value?.genders
      .flatMap((gender: any) => seasonData?.settings?.eventType?.value?.levels[gender]?.value ?? [])
      .map((level: any) => level.id);

    const accountProducts = newTickets.map((ticket: any) => {
      const standardizeTicketPrice = ticket.price ?? 0;
      const rate = ratesRes.find(item => {
        if (standardizeTicketPrice === 0) {
          return item.id === config.RATE_ID8;
        }
        return standardizeTicketPrice <= 10 ? item.id === config.RATE_ID1 : item.id === config.RATE_ID5;
      });
      const rateAmount = rate && !isEmpty(rate.rateAmount) ? rate.rateAmount : 1;
      const ratePercent = rate && !isEmpty(rate.ratePercent) ? rate.ratePercent : 5;
      const fee = rateAmount + (standardizeTicketPrice * ratePercent) / 100;
      const fanPrice = +(ticket.hiddenFees ? standardizeTicketPrice : standardizeTicketPrice + fee).toFixed(2);
      const payToSchool = +(ticket.hiddenFees ? standardizeTicketPrice - fee : standardizeTicketPrice).toFixed(2);
      return {
        accountId,
        accountPaid: ticket.hiddenFees,
        activityId: season?.activityId,
        fanPrice,
        fee,
        levelIds,
        payToSchool,
        productTypeId: 1,
        status: 'Active',
        ticketName: ticket.name,
        ticketPrice: ticket.price,
        customColor: ticket.customColor
      };
    });

    await AccountProductService.createAccountTickets({ content: getUniqAccountProducts(accountProducts) });
    return true;
  } catch (error) {
    return false;
  }
};

export const handlePublishSeason = async (season: SeasonDTO, updatedValues: any) => {
  const { seasonData } = updatedValues;
  const seasonRequest = buildSeasonRequest(season, seasonData);
  const removedGlobalEvents = getRemovedGlobalEvents(season, seasonData);
  if (!isEmpty(removedGlobalEvents)) {
    const removeGlobalEvents = await Promise.all(
      [...(removedGlobalEvents || [])]?.map(event =>
        EventScheduleService.updateGlobalEvent({
          id: event?.globalEventsId || '',
          gofanSeasonId: ''
        })
      )
    );
  }

  const response = await SeasonService.updateSeasonWithEvent(seasonRequest);
  await updateAccountProducts(season, updatedValues);

  let isReservedSeating = false;
  const { products = [] } = seasonRequest;
  products.forEach(product => {
    if (product.reservedSeating === true && !isReservedSeating) isReservedSeating = true;
  });
  if (isReservedSeating && !isNil(seasonRequest.venueId)) await VenueService.syncVenueChart(seasonRequest.venueId);

  return response;
};

export const generateEventsPublishedText = (publishedSeasonInfo: PublishedSeasonInfo): string => {
  if (!publishedSeasonInfo) return 'Season published success';
  if (publishedSeasonInfo.isArbiterSeason) {
    const arbiterEventCount = publishedSeasonInfo.events
      .filter(event => !!event.isArbiter)
      .map(event => event.id).length;
    const goFanEventCount = publishedSeasonInfo.events.filter(event => !event.isArbiter).map(event => event.id).length;
    const hasNewGoFanEvent = publishedSeasonInfo.events.find(event => !event.isArbiter && !event.isCreated);
    const arbiterEventPublishedText =
      arbiterEventCount > 0
        ? `${pluralizeNoun('Arbiter event', arbiterEventCount, 's', true)} ${pluralizeVerb(
            arbiterEventCount,
            'is',
            'are'
          )} now published! `
        : '';
    const goFanEventUpdatedText =
      goFanEventCount > 0
        ? `${pluralizeNoun('GoFan event', goFanEventCount, 's', true)} ${pluralizeVerb(
            goFanEventCount,
            'is',
            'are'
          )} updated${hasNewGoFanEvent ? ' or added' : ''}`
        : '';
    return `${arbiterEventPublishedText}${goFanEventUpdatedText}`;
  }
  return `${pluralizeNoun('event', publishedSeasonInfo.events.length, 's', true)} ${pluralizeVerb(
    publishedSeasonInfo.events.length,
    'is',
    'are'
  )} now published!`;
};

export const checkChangedDataOfEvent = (origin: any, changed: any, cb: any = () => null, isSeasonSetting = false) => {
  // [seasonName]
  if (isSeasonSetting && origin?.seasonName?.value?.value !== changed?.seasonName?.value?.value) {
    cb('seasonName', origin?.seasonName?.value?.value, changed?.seasonName?.value?.value);
  }

  // [glCode]
  if (isSeasonSetting && origin?.glCode?.value !== changed?.glCode?.value) {
    cb('glCode', origin?.glCode?.value, changed?.glCode?.value);
  }

  // [eventName]
  if (!isSeasonSetting && origin?.eventName?.value !== changed?.eventName?.value) {
    cb('eventName', origin?.eventName?.value, changed?.eventName?.value);
  }

  // [eventAlert]
  if (origin?.eventAlert?.value !== changed?.eventAlert?.value) {
    cb('eventAlert', origin?.eventAlert?.value, changed?.eventAlert?.value);
  }

  // [additionalForm]
  if (
    isDifference(origin?.additionalForm?.value ?? [], changed?.additionalForm?.value ?? [], (item: any) => item.name)
  ) {
    cb('additionalForm', origin?.additionalForm?.value, changed?.additionalForm?.value);
  }

  // [seasonBuyerPurchaseLimit]
  if (isSeasonSetting) {
    if (
      parseNumber(origin?.seasonBuyerPurchaseLimit?.value) !== parseNumber(changed?.seasonBuyerPurchaseLimit?.value)
    ) {
      cb('seasonBuyerPurchaseLimit', origin?.seasonBuyerPurchaseLimit?.value, changed?.seasonBuyerPurchaseLimit?.value);
    }
  }

  // [buyerPurchaseLimit]
  if (parseNumber(origin?.buyerPurchaseLimit?.value) !== parseNumber(changed?.buyerPurchaseLimit?.value)) {
    cb('buyerPurchaseLimit', origin?.buyerPurchaseLimit?.value, changed?.buyerPurchaseLimit?.value);
  }

  // [venueId]
  if (origin?.venueId?.value !== changed?.venueId?.value) {
    cb('venueId', origin?.venueId?.value, changed?.venueId?.value);
  }

  // [venueAddress]
  if (origin?.venueAddress?.value !== changed?.venueAddress?.value) {
    cb('venueAddress', origin?.venueAddress?.value, changed?.venueAddress?.value);
  }
  // [venueCity]
  if (origin?.venueCity?.value !== changed?.venueCity?.value) {
    cb('venueCity', origin?.venueCity?.value, changed?.venueCity?.value);
  }
  // [venueLocation]
  if (origin?.venueLocation?.value != changed?.venueLocation?.value) {
    cb('venueLocation', origin?.venueLocation?.value, changed?.venueLocation?.value);
  }
  // [venueName]
  if (origin?.venueName?.value !== changed?.venueName?.value) {
    cb('venueName', origin?.venueName?.value, changed?.venueName?.value);
  }
  // [venueState]
  if (origin?.venueState?.value !== changed?.venueState?.value) {
    cb('venueState', origin?.venueState?.value, changed?.venueState?.value);
  }
  // [venueZip]
  if (origin?.venueZip?.value !== changed?.venueZip?.value) {
    cb('venueZip', origin?.venueZip?.value, changed?.venueZip?.value);
  }

  // [gateOpeningTime]
  // [gateOpeningTime.ticketValidAnytime]
  if (
    Boolean(origin?.gateOpeningTime?.value?.ticketValidAnytime?.value) !==
    Boolean(changed?.gateOpeningTime?.value?.ticketValidAnytime?.value)
  ) {
    cb(
      'gateOpeningTime.ticketValidAnytime',
      origin?.gateOpeningTime?.value?.ticketValidAnytime?.value,
      changed?.gateOpeningTime?.value?.ticketValidAnytime?.value
    );
  }
  // [gateOpeningTime.ticketValidAnytime]
  if (
    parseNumber(origin?.gateOpeningTime?.value?.ticketValidBefore?.value) !==
    parseNumber(changed?.gateOpeningTime?.value?.ticketValidBefore?.value)
  ) {
    cb(
      'gateOpeningTime.ticketValidBefore',
      origin?.gateOpeningTime?.value?.ticketValidBefore?.value,
      changed?.gateOpeningTime?.value?.ticketValidBefore?.value
    );
  }

  // [seasonVisibility]
  if (isSeasonSetting) {
    // [seasonVisibility.archived]
    if (
      Boolean(origin?.seasonVisibility?.value?.archived?.value) !==
      Boolean(changed?.seasonVisibility?.value?.archived?.value)
    ) {
      cb(
        'seasonVisibility.archived',
        origin?.seasonVisibility?.value?.archived?.value,
        changed?.seasonVisibility?.value?.archived?.value
      );
    }
    // [seasonVisibility.publishDate]
    const originPublishDate = moment(origin?.seasonVisibility?.value?.publishDate?.value);
    const changedPublishDate = moment(changed?.seasonVisibility?.value?.publishDate?.value);
    if (
      changed?.seasonVisibility?.value?.archived.value &&
      ((!originPublishDate.isValid() && changedPublishDate.isValid()) ||
        (originPublishDate.isValid() && !changedPublishDate.isValid()) ||
        originPublishDate.diff(changedPublishDate, 'minutes'))
    ) {
      cb('seasonVisibility.publishDate', originPublishDate, changedPublishDate);
    }
  }

  // [eventVisibility]
  // [eventVisibility.archived]
  if (
    Boolean(origin?.eventVisibility?.value?.archived?.value) !==
    Boolean(changed?.eventVisibility?.value?.archived?.value)
  ) {
    cb(
      'eventVisibility.archived',
      origin?.eventVisibility?.value?.archived?.value,
      changed?.eventVisibility?.value?.archived?.value
    );
  }
  // [eventVisibility.publishDate]
  const originPublishDate = moment(origin?.eventVisibility?.value?.publishDate?.value);
  const changedPublishDate = moment(changed?.eventVisibility?.value?.publishDate?.value);
  if (
    changed?.eventVisibility?.value?.archived.value &&
    ((!originPublishDate.isValid() && changedPublishDate.isValid()) ||
      (originPublishDate.isValid() && !changedPublishDate.isValid()) ||
      originPublishDate.diff(changedPublishDate, 'minutes'))
  ) {
    cb('eventVisibility.publishDate', originPublishDate, changedPublishDate);
  }

  // [startTime]
  // [startTime.allDayEvent]
  if (isSeasonSetting) {
    if (
      Boolean(origin?.startTime?.value?.allDayEvent?.value) !== Boolean(changed?.startTime?.section?.allDayEvent?.value)
    ) {
      cb(
        'startTime.allDayEvent',
        origin?.startTime?.value?.allDayEvent?.value,
        changed?.startTime?.value?.allDayEvent?.value
      );
    }
    // [startTime.startTime]
    const diffTime = (time1: any, time2: any) =>
      time1?.hour !== time2?.hour ||
      time1?.minute !== time2?.minute ||
      time1?.second !== time2?.second ||
      time1?.type !== time2?.type;
    if (diffTime(origin?.startTime?.value?.startTime, changed?.startTime?.section?.startTime)) {
      cb('startTime.startTime', origin?.startTime?.value?.startTime, changed?.startTime?.section?.startTime);
    }
  }

  // [eventDateAndTime]
  // [eventDateAndTime.allDayEvent]
  if (!isSeasonSetting) {
    if (
      Boolean(origin?.eventDateAndTime?.value?.allDayEvent?.value) !==
      Boolean(changed?.eventDateAndTime?.value?.allDayEvent?.value)
    ) {
      cb(
        'eventDateAndTime.allDayEvent',
        origin?.eventDateAndTime?.value?.allDayEvent?.value,
        changed?.eventDateAndTime?.value?.allDayEvent?.value
      );
    }
    // [eventDateAndTime.startDate]
    const originStartDate = moment(origin?.eventDateAndTime?.value?.eventDate?.value);
    const changedStartDate = moment(changed?.eventDateAndTime?.value?.eventDate?.value);
    if (
      (!originStartDate.isValid() && changedStartDate.isValid()) ||
      (originStartDate.isValid() && !changedStartDate.isValid()) ||
      originStartDate.diff(changedStartDate, 'minutes')
    ) {
      cb('eventDateAndTime.startDate', originStartDate, changedStartDate);
    }

    // [eventType]
    // [eventType.activity]
    if (origin?.eventType?.value?.activity?.value?.id !== changed?.eventType?.value?.activity?.value?.id) {
      cb(
        'eventType.activity',
        origin?.eventType?.value?.activity?.value?.id,
        changed?.eventType?.value?.activity?.value?.id
      );
    }
    // [eventType.eventType]
    if (origin?.eventType?.value?.eventType?.value?.id !== changed?.eventType?.value?.eventType?.value?.id) {
      cb(
        'eventType.eventType',
        origin?.eventType?.value?.eventType?.value?.id,
        changed?.eventType?.value?.eventType?.value?.id
      );
    }
    // [eventType.genders]
    const originGender = origin?.eventType?.value?.genders ?? {};
    const changedGender = changed?.eventType?.section?.genders ?? {};
    if (
      Boolean(originGender?.event?.value?.selected) !== Boolean(changedGender?.event?.value?.selected) ||
      Boolean(originGender?.boy?.value?.selected) !== Boolean(changedGender?.boy?.value?.selected) ||
      Boolean(originGender?.girl?.value?.selected) !== Boolean(changedGender?.girl?.value?.selected) ||
      Boolean(originGender?.either?.value?.selected) !== Boolean(changedGender?.either?.value?.selected)
    ) {
      cb('eventType.genders', originGender, changedGender);
    }
    // [eventType.levels]
    const diffLevels = (levels1: any, levels2: any) => isDifference(levels1, levels2, (item: any) => `${item.id}`);
    const originLevels = origin?.eventType?.value?.levels ?? {};
    const changedLevels = changed?.eventType?.section?.levels ?? {};
    if (
      (originGender?.boy?.value?.selected &&
        changedGender?.boy?.value?.selected &&
        diffLevels(originLevels?.boy?.value, changedLevels?.boy?.value)) ||
      (originGender?.girl?.value?.selected &&
        changedGender?.girl?.value?.selected &&
        diffLevels(originLevels?.girl?.value, changedLevels?.girl?.value)) ||
      (originGender?.either?.value?.selected &&
        changedGender?.either?.value?.selected &&
        diffLevels(originLevels?.either?.value, changedLevels?.either?.value))
    ) {
      cb('eventType.levels', originLevels, changedLevels);
    }
  }

  // [startTimeTeams]
  const changedStartTimeTeams = changed?.startTimeTeams ?? {};
  const originStartTimeTeams = origin?.startTimeTeams ?? {};
  const diffStartTime = (time1: any, time2: any) => time1?.time !== time2?.time || time1?.period !== time2?.period;
  const changedStartTimeOptions = Object.keys(changedStartTimeTeams?.startTimeOptions ?? {}).map(startTimeId => {
    const time = `${changedStartTimeTeams?.startTimeOptions?.[startTimeId]?.value?.time}`;
    const period = `${changedStartTimeTeams?.startTimeOptions?.[startTimeId]?.value?.period}`;
    return `${startTimeId} ${time} ${period}`;
  });
  const originStartTimeOptions = Object.keys(originStartTimeTeams?.startTimeOptions ?? {}).map(startTimeId => {
    const time = `${originStartTimeTeams?.startTimeOptions?.[startTimeId]?.value?.time}`;
    const period = `${originStartTimeTeams?.startTimeOptions?.[startTimeId]?.value?.period}`;
    return `${startTimeId} ${time} ${period}`;
  });
  if (
    Boolean(changedStartTimeTeams?.allDayEvent?.value) !== Boolean(originStartTimeTeams?.allDayEvent?.value) ||
    changedStartTimeTeams?.startTimeType?.value !== originStartTimeTeams?.startTimeType?.value ||
    moment(changedStartTimeTeams?.startDateTime?.value)
      .set('second', 0)
      .diff(moment(originStartTimeTeams?.startDateTime?.value).set('second', 0)) ||
    diffStartTime(changedStartTimeTeams?.sameStartTime?.value, originStartTimeTeams?.sameStartTime?.value) ||
    isDifference(originStartTimeOptions ?? [], changedStartTimeOptions ?? [], (startTime: any) => startTime)
  ) {
    cb('startTimeTeams', originStartTimeTeams, changedStartTimeTeams);
  }

  // [ticketAvailability]
  // [ticketAvailability.maxCapacity]
  if (origin?.ticketAvailability?.value?.maxCapacity !== changed?.ticketAvailability?.value?.maxCapacity) {
    cb(
      'ticketAvailability.maxCapacity',
      origin?.ticketAvailability?.value?.maxCapacity,
      changed?.ticketAvailability?.value?.maxCapacity
    );
  }
  // [ticketAvailability.products]
  const originProducts = origin?.ticketAvailability?.value?.products ?? [];
  const changedProducts = changed?.ticketAvailability?.value?.products ?? [];
  // [ticketAvailability.products.length]
  if (originProducts?.length !== changedProducts?.length) {
    cb('ticketAvailability.products.length', originProducts, changedProducts);
  }
  // [ticketAvailability.products]
  const updateProducts: any = [];
  const diffProduct = (product1: any, product2: any) =>
    // GoFan
    (product1?.name ?? '') !== (product2?.name ?? '') ||
    (product1?.limit ?? '') !== (product2?.limit ?? '') ||
    (product1?.price ?? '') !== (product2?.price ?? '') ||
    (product1?.enabled ?? '') !== (product2?.enabled ?? '') ||
    (product1?.generateLink ?? false) !== (product2?.generateLink ?? false) ||
    (product1?.customColor ?? '') !== (product2?.customColor ?? '') ||
    (product1?.hiddenFees ?? false) !== (product2?.hiddenFees ?? false) ||
    (product1?.reservedSeating ?? false) !== (product2?.reservedSeating ?? false) ||
    (product1?.seatsIoCategory ?? '') !== (product2?.seatsIoCategory ?? '') ||
    !isEqualFormFields(product1?.formFields ?? [], product2?.formFields ?? []) ||
    (product1?.salesStartDate ?? '') !== (product2?.salesStartDate ?? '') ||
    (product1?.salesStartTime ?? '') !== (product2?.salesStartTime ?? '') ||
    (product1?.salesEndDate ?? '') !== (product2?.salesEndDate ?? '') ||
    (product1?.salesEndTime ?? '') !== (product2?.salesEndTime ?? '') ||
    (product1?.fee ?? '') !== (product2?.fee ?? '') ||
    (product1?.ticketLimitPerOrder ?? '') !== (product2?.ticketLimitPerOrder ?? '') ||
    (product1?.packCount ?? '') !== (product2?.packCount ?? '') ||
    // BoxOffice
    (product1?.boxOfficeTicket?.name ?? '') !== (product2?.boxOfficeTicket?.name ?? '') ||
    (product1?.boxOfficeTicket?.price ?? '') !== (product2?.boxOfficeTicket?.price ?? '');
  changedProducts.forEach((item: any) => {
    const found = originProducts.find((p: any) => `${p.id}` === `${item.id}`);
    if (found) {
      updateProducts.push(item);
      if (diffProduct(item, found)) {
        cb(`ticketAvailability.products.${item.id}`, found, item);
      }
    } else {
      cb(`ticketAvailability.products.new.${item.id}`, item, item);
    }
  });
  originProducts.forEach((item: any) => {
    const found = updateProducts.find((p: any) => `${p.id}` === `${item.id}`);
    if (!found) {
      cb(`ticketAvailability.products.remove.${item.id}`, item, item);
    }
  });
  // [ticketAvailability.seasonTickets]
  const originSeasonTickets = origin?.ticketAvailability?.value?.seasonTickets ?? [];

  const changedSeasonTickets = changed?.ticketAvailability?.value?.seasonTickets ?? [];
  // [ticketAvailability.seasonTickets.length]
  if (originSeasonTickets?.length !== changedSeasonTickets?.length) {
    cb('ticketAvailability.seasonTickets.length', originSeasonTickets, changedSeasonTickets);
  }
  // [ticketAvailability.products]
  const updateSeasonTickets: any = [];
  const diffSeasonTicket = (product1: any, product2: any) =>
    (product1?.name ?? '') !== (product2?.name ?? '') ||
    (product1?.limit ?? '') !== (product2?.limit ?? '') ||
    (product1?.price ?? '') !== (product2?.price ?? '') ||
    (product1?.enabled ?? '') !== (product2?.enabled ?? '') ||
    (product1?.generateLink ?? false) !== (product2?.generateLink ?? false) ||
    (product1?.customColor ?? '') !== (product2?.customColor ?? '') ||
    (product1?.hiddenFees ?? false) !== (product2?.hiddenFees ?? false) ||
    (product1?.reservedSeating ?? false) !== (product2?.reservedSeating ?? false) ||
    (product1?.seatsIoCategory ?? '') !== (product2?.seatsIoCategory ?? '') ||
    (product1?.salesStartDate ?? '') !== (product2?.salesStartDate ?? '') ||
    (product1?.salesStartTime ?? '') !== (product2?.salesStartTime ?? '') ||
    (product1?.salesEndDate ?? '') !== (product2?.salesEndDate ?? '') ||
    (product1?.salesEndTime ?? '') !== (product2?.salesEndTime ?? '') ||
    (product1?.fee ?? '') !== (product2?.fee ?? '') ||
    (product1?.ticketLimitPerOrder ?? '') !== (product2?.ticketLimitPerOrder ?? '') ||
    (product1?.enabled ?? '') !== (product2?.enabled ?? '') ||
    !isEqualFormFields(product1?.formFields ?? [], product2?.formFields ?? []);
  changedSeasonTickets.forEach((item: any) => {
    const found = originSeasonTickets.find((p: any) => `${p.id}` === `${item.id}`);
    if (found) {
      updateSeasonTickets.push(item);
      if (diffSeasonTicket(item, found)) {
        cb(`ticketAvailability.seasonTickets.${item.id}`, found, item);
      }
    } else {
      cb(`ticketAvailability.seasonTickets.new.${item.id}`, item, item);
    }
  });
  originSeasonTickets.forEach((item: any) => {
    const found = updateSeasonTickets.find((p: any) => `${p.id}` === `${item.id}`);
    if (!found) {
      cb(`ticketAvailability.seasonTickets.remove.${item.id}`, item, item);
    }
  });
};

export const getChangedData = ({ origin, changed, timeZone = null }: any) => {
  const changedData = [];
  const originEvents = origin?.events ?? [];
  const changedEvents = changed?.events ?? [];
  const genChangedField = (name: any, value: any, changedValue: any) => ({ name, value, changedValue });

  // [CHECKBOX_MAKE_DEFAULT_TICKET]
  if (changed?.isMakeDefaultTicket) {
    changedData.push(genChangedField('makeDefaultTicket', false, true));
  }

  // [CHECKING_SEASON]
  checkChangedDataOfEvent(
    { ...origin?.settings, timeZone } ?? {},
    changed?.settings ?? {},
    (name: any, value: any, changedValue: any) => {
      changedData.push(genChangedField(`settings.${name}`, value, changedValue));
    },
    true
  );

  // [CHECKING_EVENT]
  // [NUMBER_OF_EVENTS]
  if (originEvents.length !== changedEvents.length) {
    changedData.push(genChangedField('events.length', originEvents.length, changedEvents.length));
  }
  const events: any = [];
  changedEvents.forEach((event: any) => {
    const originEvent = originEvents.find((o: any) => `${o.id}` === `${event.id}`);
    if (originEvent) {
      events.push(event);
      checkChangedDataOfEvent({ ...originEvent, timeZone }, event, (name: any, value: any, changedValue: any) => {
        changedData.push(genChangedField(`events.${event.id}.${name}`, value, changedValue));
      });
    } else {
      changedData.push(genChangedField(`events.new.${event.id}`, event, event));
    }
  });
  originEvents.forEach((event: any) => {
    const found = events.find((o: any) => `${o.id}` === `${event.id}`);
    if (!found) {
      changedData.push(genChangedField(`events.remove.${event.id}`, event, event));
    }
  });
  return changedData;
};

export const filterProductByTicketDistribution = (event: EventDTO) => (product: ProductTypeAndPrice) => {
  // eslint-disable-next-line no-shadow
  if (isEmpty(event)) return true;

  const { accountId, ticketDistribution, products } = event;
  const eventProduct = (products || []).find(item => item.id === product.id);
  if (eventProduct) {
    return ticketDistribution === true ? eventProduct.accountId === accountId : eventProduct.accountId === null;
  }
  return true;
};

export const getProductGroupKey = (product: EventProduct) => [product?.name, product?.price].join('-');

export const convertToProductGroup = (products: EventProduct[]) => {
  const goFanProduct = products.find(product => product.distributionChannel === DISTRIBUTION_CHANNEL.GOFAN);
  const boxOfficeProduct = products.find(product => product.distributionChannel === DISTRIBUTION_CHANNEL.BOXOFFICE);
  return {
    ...omit(goFanProduct, ['_embedded', '_links']),
    created: !isEmpty(goFanProduct),
    formFields: mapToFormFieldSchema(goFanProduct?.formFields),
    boxOfficeTicket: !isEmpty(boxOfficeProduct)
      ? {
          ...omit(boxOfficeProduct, ['_embedded', '_links']),
          created: !isEmpty(boxOfficeProduct),
          formFields: mapToFormFieldSchema(boxOfficeProduct?.formFields)
        }
      : {}
  };
};

export const convertToProductGroupArr = (products: EventProduct[]) => {
  let filteredProducts = [...products];
  let result: any[] = [];
  while (filteredProducts.length > 0) {
    const group = convertToProductGroup(filteredProducts);
    result = [...result, group];
    filteredProducts = filteredProducts.filter(p => p?.id !== group?.id && p?.id !== group?.boxOfficeTicket?.id);
  }
  return result;
};

export const groupAccountProducts = (products: EventProduct[]) => {
  let results: any[] = [];

  chain(products ?? [])
    .groupBy(({ groupId }) => groupId || null)
    .forEach((items, groupId) => {
      if (groupId === 'null') {
        const groups = chain(items)
          .groupBy(getProductGroupKey)
          .filter(nonIdGroups =>
            nonIdGroups.some(product => product.distributionChannel === DISTRIBUTION_CHANNEL.GOFAN)
          )
          .map(convertToProductGroupArr)
          .flatten()
          .value();
        results = [...results, ...groups];
      } else {
        const groups = convertToProductGroupArr(items);
        results = [...results, ...groups];
      }
    })
    .value();

  return results;
};

export const checkSeasonEventPermission = ({
  viewMode,
  currentUser,
  accountId,
  financialAccountId
}: {
  viewMode: boolean;
  currentUser: UserDTO;
  accountId: string;
  financialAccountId?: string;
}) =>
  checkPermissionHandlingEvent({ viewMode, accountId, currentUser }) ||
  checkPermissionHandlingEvent({ viewMode, accountId: financialAccountId, currentUser });

export const mapToFormFieldSchema = (formFields: FormFieldDTO[] = []) =>
  (formFields ?? []).map(formField => {
    const optionSchema = (formField.options ?? []).map((option, index) =>
      typeof option === 'string' ? { id: index, value: option } : option
    );
    return {
      ...formField,
      options: optionSchema
    };
  });

export const isEqualFormFields = (formFields1: FormFieldDTO[], formFields2: FormFieldDTO[]) =>
  isEqual(
    formFields1.map(item => ({ ...item, id: null, description: null })),
    formFields2.map(item => ({ ...item, id: null, description: null }))
  );

export const isEqualOptionalTicketSettings = (product1: any, product2: any) => {
  const mSalesStartDateTime1 = dayjs(`${product1?.salesStartDate} ${product1?.salesStartTime}`).format(
    EVENT_DATE_FORMAT_WITH_TIMEZONE
  );
  const mSalesStartDateTime2 = dayjs(`${product2?.salesStartDate} ${product2?.salesStartTime}`).format(
    EVENT_DATE_FORMAT_WITH_TIMEZONE
  );

  const mSalesEndDateTime1 = dayjs(`${product1?.salesEndDate} ${product1?.salesEndTime}`).format(
    EVENT_DATE_FORMAT_WITH_TIMEZONE
  );
  const mSalesEndDateTime2 = dayjs(`${product2?.salesEndDate} ${product2?.salesEndTime}`).format(
    EVENT_DATE_FORMAT_WITH_TIMEZONE
  );

  return mSalesStartDateTime1 === mSalesStartDateTime2 && mSalesEndDateTime1 === mSalesEndDateTime2;
};

export const generateHeaderData = ({ formState, account }: { formState: any; account: AccountDTO }) => {
  const name = formState.seasonName?.value?.value ?? formState.eventName?.value;
  const activity = formState.eventType?.section?.activity?.value;
  const genLevels = buildLevels(formState.eventType?.section);
  const genders: any[] = [];
  const newLevels: any[] = [];
  if (!isEmpty(genLevels)) {
    genLevels.forEach(lv => {
      if (isEmpty(lv.levelId)) {
        genders.push(...lv.genders);
      } else {
        newLevels.push(lv);
      }
    });
  }

  const opponentAccount = formState.school?.value;
  const allDayEvent = !!formState.startTimeTeams?.allDayEvent?.value;
  const timeZone = formState?.originalExistingEvent?.timeZone ?? account?.timeZone;
  const mStartDateTime = moment(formState.startTimeTeams?.startDateTime?.value);
  const eventStartDateTime = switchZone(mStartDateTime.format(EVENT_DATE_FORMAT_WITH_TIMEZONE), timeZone);
  const mEndDateTime = buildEndDateTimeEvent(
    mStartDateTime,
    allDayEvent,
    timeZone,
    formState.endDateTime,
    formState.eventDuration,
    formState.shouldKeepEventDurationWhenEdit,
    mStartDateTime.clone(),
    formState.initAllDayEvent
  );
  const eventEndDateTime = switchZone(mEndDateTime.format(EVENT_DATE_FORMAT_WITH_TIMEZONE), timeZone);

  return {
    name,
    activity,
    genders,
    levels: newLevels,
    account,
    opponentAccount,
    allDayEvent,
    timeZone,
    startDateTime: eventStartDateTime,
    endDateTime: eventEndDateTime
  };
};

export const getTicketOptionalData = ({
  tickets,
  timeZone,
  productSoldIds = []
}: {
  tickets: GFEventProduct[] | GFSeasonProduct[];
  timeZone: string;
  productSoldIds?: (string | number)[];
}) =>
  (tickets ?? []).map(ticket => {
    let salesStartDate;
    let salesStartTime;
    let salesEndDate;
    let salesEndTime;

    if (ticket?.salesStartDateTime) {
      const mSalesStartDateTime = DateUtils.formatDateTime({
        date: ticket?.salesStartDateTime,
        timeZone,
        parseZone: true
      });
      const ticketSalesStartDateTime = DateUtils.switchZone(
        mSalesStartDateTime.toDate(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
        'localZone'
      );
      const mTicketSalesStartDateTime = dayjs(ticketSalesStartDateTime);

      salesStartTime = mTicketSalesStartDateTime.clone().format(TIME_FORMAT_DEFAULT);
      salesStartDate = mTicketSalesStartDateTime.clone().format(DATE_PICKER_FORMAT_DEFAULT);
    }

    if (ticket?.salesEndDateTime) {
      const mSalesEndDateTime = DateUtils.formatDateTime({
        date: ticket?.salesEndDateTime,
        timeZone,
        parseZone: true
      });
      const ticketSalesEndDateTime = DateUtils.switchZone(
        mSalesEndDateTime.toDate(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
        'localZone'
      );
      const mTicketSalesEndDateTime = dayjs(ticketSalesEndDateTime);

      salesEndTime = mTicketSalesEndDateTime.clone().format(TIME_FORMAT_DEFAULT);
      salesEndDate = mTicketSalesEndDateTime.clone().format(DATE_PICKER_FORMAT_DEFAULT);
    }

    const fee =
      !productSoldIds.some(productId => `${productId}` === `${ticket?.id}`) && ticket?.hiddenFees
        ? ticket?.hiddenFeeBase
        : ticket?.fee;

    return {
      ...ticket,
      salesStartDate,
      salesStartTime,
      salesEndDate,
      salesEndTime,
      fee
    };
  });

export const getVisibilitySection = (data: SeasonDTO | EventDTO) => {
  let publishDateAndTime = {};
  if (data.archived) {
    const archived = { value: data.archived, valid: true };
    const publishDateMoment = formatDateTime({
      date: data.publishDateTime,
      timeZone: data.timeZone,
      parseZone: true
    });
    const publishDate = moment(switchZone(publishDateMoment.toDate(EVENT_DATE_FORMAT_WITH_TIMEZONE), 'localZone'));
    if (!isEmpty(data.publishDateTime) && publishDate.isValid()) {
      const publishTime = publishDateMoment.toTime('hh:mm');
      const publishDateElements = publishTime ? split(publishTime, ':') : ['0', '0'];
      publishDateAndTime = {
        archived,
        minDate: publishDate?.clone(),
        publishDate: {
          ...SEASON_ELEMENT_STATES,
          valid: true,
          value: publishDate?.clone()
        },
        publishTime: {
          ...SEASON_ELEMENT_STATES,
          valid: true,
          hour: parseInt(publishDateElements[0], 10),
          minute: parseInt(
            publishDateElements[1].length === 1 ? `0${publishDateElements[1]}` : publishDateElements[1],
            10
          ),
          second: 1,
          type: `${publishDateMoment.toTime('A')}`.toUpperCase(),
          value: publishTime
        }
      };
    } else {
      publishDateAndTime = { ...EVENT_TEMPLATE.eventVisibility, archived };
    }
  } else {
    publishDateAndTime = { ...EVENT_TEMPLATE.eventVisibility };
  }

  return publishDateAndTime;
};

export const getDataSalesInfo = (season: SeasonDTO) => {
  const seasonSalesInfo = season?._embedded?.['season-sales-info'];
  const eventSalesInfos = (season?.events ?? []).map(event => event?._embedded['event-sales-info']);

  const seasonProductSoldIds = Object.keys(seasonSalesInfo?.productSalesMap)
    .filter((productId: any) => seasonSalesInfo?.productSalesMap?.[productId]?.saleQuantity > 0)
    .map(productId => Number(productId));

  const eventProductSoldIds = (eventSalesInfos ?? []).map(eventSalesInfo =>
    Object.keys(eventSalesInfo?.productSalesMap)
      .filter((productId: any) => eventSalesInfo?.productSalesMap?.[productId]?.saleQuantity > 0)
      .map(productId => Number(productId))
  );

  return { seasonProductSoldIds, eventProductSoldIds: flatten(eventProductSoldIds) };
};

export const normalizeCollectionInfo = (products: (GFEventProduct | GFSeasonProduct)[], isEventSetting = false) =>
  products.map(product => ({
    ...product,
    id: product.id || `${getUniqueId()}`,
    groupId: isEventSetting ? `${getUniqueId()}` : product.groupId,
    created: true,
    formFields: (product.formFields ?? []).map(formField => ({
      ...formField,
      options: (formField?.options ?? []).map(option => ({
        id: getUniqueId(),
        value: option
      }))
    }))
  }));

export const getDateTimePartialUpdateSeason = (season: Partial<GFSeasonDTO>) => {
  const mSeasonStartDateTime = dayjs.min(
    [
      ...(season?.events ?? []),
      {
        startDateTime: season.startDateTime
      }
    ].map(({ startDateTime }) => dayjs(startDateTime))
  );

  const mSeasonEndDateTime = dayjs.max(
    [
      ...(season?.events ?? []),
      {
        endDateTime: season.endDateTime
      }
    ].map(({ endDateTime }) => dayjs(endDateTime))
  );

  const now = dayjs();
  let updatedStartDateTime;
  const updatedEndDateTime = DateUtils.switchZone(
    dayjs(mSeasonEndDateTime).clone().format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
    season?.timeZone
  );
  if (now.isBefore(mSeasonStartDateTime)) {
    updatedStartDateTime = DateUtils.switchZone(
      dayjs(mSeasonStartDateTime).clone().format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
      season?.timeZone
    );
  }

  return {
    startDateTime: updatedStartDateTime,
    endDateTime: updatedEndDateTime
  };
};

export default {
  getSortBy,
  getFilterDate
};
