// eslint-disable-next-line no-shadow
export enum GLCODE_TYPE {
  EVENT = 'event',
  CONCESSION = 'concession',
  SEASON = 'season',
  FUNDRAISER = 'fundraiser'
}

export const GLCODE_CONSTANTS = {
  [GLCODE_TYPE.EVENT]: {
    TOOLTIP_LABEL:
      'The GL code, if provided, will be included alongside the event ID in our payment reports to make it easier to record the proceeds. Please contact your Accounting department to determine the appropriate code for the event.',
    INPUT_DESCRIPTION: 'Map your general ledger code to your event (Optional)'
  },
  [GLCODE_TYPE.SEASON]: {
    TOOLTIP_LABEL:
      'The GL code, if provided, will be included alongside the season ID in our payment reports to make it easier to record the proceeds. Please contact your Accounting department to determine the appropriate code for the season.',
    INPUT_DESCRIPTION: 'Map your general ledger code to your season (Optional)'
  },
  [GLCODE_TYPE.CONCESSION]: {
    TOOLTIP_LABEL:
      'The GL code, if provided, will be included alongside the concession ID in our payment reports to make it easier to record the proceeds. Please contact your Accounting department to determine the appropriate code for the concession.',
    INPUT_DESCRIPTION: 'Map your general ledger code to your concession (Optional)'
  },
  [GLCODE_TYPE.FUNDRAISER]: {
    TOOLTIP_LABEL:
      'The GL code, if provided, will be included alongside the fundraiser ID in our payment reports to make it easier to record the proceeds. Please contact your Accounting department to determine the appropriate code for the fundraiser.',
    INPUT_DESCRIPTION: 'Map your general ledger code to your fundraiser (Optional)'
  },
  SECTION_TITLE: 'Financial settings',
  SECTION_SUBTITLE: 'GL Code',
  INPUT_SEASON_DESCRIPTION: 'Map your general ledger code to your season',
  INPUT_EVENT_DESCRIPTION: 'Map your general ledger code to your event',
  INPUT_PLACEHOLDER: 'Enter GL Code',
  ERROR_MESSAGE_DEFAULT: 'Only alphanumeric, period(.), dash(-), underscore(_), hash(#) are allowed.'
};
