import { useState } from 'react';

import type { SeasonInsightDTO } from '@seasons/models/season.model';

const useSeasonInsightPanel = () => {
  const [openSeasonInsightPanel, setOpenSeasonInsightPanel] = useState<boolean>(false);
  const [selectedSeasonInsights, setSelectedSeasonInsights] = useState<SeasonInsightDTO>();

  const handleClosePanel = () => setOpenSeasonInsightPanel(false);

  const handleOpenPanel = (seasonInsights?: SeasonInsightDTO) => {
    setOpenSeasonInsightPanel(true);
    setSelectedSeasonInsights(seasonInsights);
  };

  return { openSeasonInsightPanel, selectedSeasonInsights, handleClosePanel, handleOpenPanel };
};

export { useSeasonInsightPanel };
