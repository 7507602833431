// @flow

class HealthCheckDTO {
  constructor({ details, status }) {
    this.raw = { details, status };
  }

  get details() {
    return this.raw.details;
  }

  get status() {
    return this.raw.status;
  }

  toJSON() {
    return {
      status: this.status,
      details: this.details
    };
  }
}

export default HealthCheckDTO;
