import {
  ON_PREPARE_DATA,
  ON_PREPARE_DATA_COMPLETED,
  ON_CREATE_SEASON_RENEWAL_CAMPAIGN,
  ON_CREATE_SEASON_RENEWAL_CAMPAIGN_COMPLETED,
  ON_HANDLE_ERROR,
  ON_RESET_STATE,
  ON_STORE_SEASON_RENEWAL_CAMPAIGN,
  ON_STORE_SEASON_RENEWAL_ATTENDEES
} from './actionTypes';

import type {
  OnPrepareData,
  OnPrepareDataCompleted,
  OnCreateSeasonRenewalCampaign,
  OnCreateSeasonRenewalCampaignCompleted,
  OnHandleError,
  OnResetState,
  OnStoreSeasonRenewalCampaign,
  OnStoreSeasonRenewalAttendees
} from './actionTypes';

export const onPrepareData = (payload: OnPrepareData['payload']): OnPrepareData => ({
  type: ON_PREPARE_DATA,
  payload
});

export const onPrepareDataCompleted = (payload: OnPrepareDataCompleted['payload']): OnPrepareDataCompleted => ({
  type: ON_PREPARE_DATA_COMPLETED,
  payload
});

export const onCreateSeasonRenewalCampaign = (
  payload: OnCreateSeasonRenewalCampaign['payload']
): OnCreateSeasonRenewalCampaign => ({
  type: ON_CREATE_SEASON_RENEWAL_CAMPAIGN,
  payload
});

export const onCreateSeasonRenewalCampaignCompleted = (
  payload: OnCreateSeasonRenewalCampaignCompleted['payload']
): OnCreateSeasonRenewalCampaignCompleted => ({
  type: ON_CREATE_SEASON_RENEWAL_CAMPAIGN_COMPLETED,
  payload
});

export const onStoreSeasonRenewalAttendees = (
  payload: OnStoreSeasonRenewalAttendees['payload']
): OnStoreSeasonRenewalAttendees => ({
  type: ON_STORE_SEASON_RENEWAL_ATTENDEES,
  payload
});

export const onStoreSeasonRenewalCampaign = (
  payload: OnStoreSeasonRenewalCampaign['payload']
): OnStoreSeasonRenewalCampaign => ({
  type: ON_STORE_SEASON_RENEWAL_CAMPAIGN,
  payload
});

export const onHandleError = (): OnHandleError => ({
  type: ON_HANDLE_ERROR
});

export const onResetState = (): OnResetState => ({
  type: ON_RESET_STATE
});
