import { USER_ROLE_COMMISSIONER_EDITOR, USER_ROLE_COMMISSIONER_VIEWER } from '@gofan/api';
import {
  USER_ROLE_ADMIN,
  USER_ROLE_SUPER_ADMIN,
  USER_ROLE_ACCOUNT_USER,
  LOWER_LEVEL_ROLES,
  USER_ROLE_EDITOR
} from '../../../api/model/user.model';

const canAssignAdmin = ({ account, currentUser }) => {
  const { account: accountInfo, user: contact, type } = account || {};
  const adminActiveRole = [USER_ROLE_SUPER_ADMIN, USER_ROLE_ACCOUNT_USER];

  if (type !== USER_ROLE_ADMIN) return true;

  if (!adminActiveRole.includes(contact.role)) return false;

  if (currentUser.role === USER_ROLE_SUPER_ADMIN) return true;

  if (currentUser.email === contact.email || contact.role === USER_ROLE_SUPER_ADMIN) return false;

  if (currentUser.role === USER_ROLE_ACCOUNT_USER) {
    const context = (currentUser.userAccountContexts || []).find(item => item.accountId === accountInfo.accountId);

    return context && context.accountRoles.includes(USER_ROLE_ADMIN);
  }

  return true;
};

const checkAccountUserLowerRoles = ({ currentUser, account }, lowerLevelRoles = LOWER_LEVEL_ROLES) => {
  const { accountId } = account || {};
  const { userAccountContexts } = currentUser || {};

  const accountData = userAccountContexts.find(item => `${item.accountId}` === `${accountId}`);

  if (accountData) {
    return lowerLevelRoles.some(role => accountData.accountRoles.includes(role));
  }

  return false;
};

const checkAccountEditorRole = ({ currentUser, account }) => {
  const { accountId } = account || {};
  const { userAccountContexts } = currentUser || {};

  const accountData = userAccountContexts.find(item => `${item.accountId}` === `${accountId}`);

  if (accountData) {
    return (
      (accountData.accountRoles.includes(USER_ROLE_EDITOR) ||
        accountData.accountRoles.includes(USER_ROLE_COMMISSIONER_EDITOR) ||
        accountData.accountRoles.includes(USER_ROLE_COMMISSIONER_VIEWER)) &&
      !accountData.inactive
    );
  }

  return false;
};

export default {
  canAssignAdmin,
  checkAccountUserLowerRoles,
  checkAccountEditorRole
};
