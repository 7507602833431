import React from 'react';
import NumberFormat from 'react-number-format';
import BaseInput from './BaseInput';
import FormSafeguard from './FormSafeguard';
import ViewOnlyGuard from './ViewOnlyGuard';

import { StyledFormInput } from '../../components/styledSemantic';

export default ({
  formKey,
  label,
  nullText = '',
  viewType = 'text',
  formatViewType = '###-###-####',
  overwritable = false,
  readOnly = false,
  required = false,
  validationRules = () => null
}) => (
  <FormSafeguard formKey={formKey}>
    <ViewOnlyGuard
      label={label}
      formKey={formKey}
      nullText={nullText}
      renderView={phone =>
        phone === nullText ? (
          phone
        ) : (
          <NumberFormat
            value={phone}
            displayType={viewType}
            format={formatViewType}
          />
        )
      }
    >
      <BaseInput
        readOnly={readOnly}
        formKey={formKey}
        label={label}
        overwritable={overwritable}
        required={required}
        validation={input =>
          input === '' || input === null || /^[0-9]{10,11}$/.test(input)
        }
        validationRules={validationRules}
        renderInput={({ inputValue, onHandleChange, ...inputProps }) => (
          <NumberFormat
            {...inputProps}
            format={formatViewType}
            defaultValue={inputValue}
            customInput={StyledFormInput}
            onValueChange={({ value }) =>
              value !== inputValue && onHandleChange(value)
            }
          />
        )}
      />
    </ViewOnlyGuard>
  </FormSafeguard>
);
