import {
  TimePicker,
  TimePickerProps
} from 'carbon-components-react';
import { isEmpty } from 'lodash';

export type BasicTimePickerConfig = {
  label: string | JSX.Element;
  placeholder?: string;
  disabled?: boolean;
};

export type BasicTimePickerProps = TimePickerProps
& BasicTimePickerConfig
& {
  name: string;
  control: any;
  initialValue: string;
  fieldValue: string;
  fieldError?: string;
  getFieldRef?: () => void;
};

const BasicTimePicker = ({
  name,
  label = '',
  disabled,
  className,
  initialValue,
  fieldValue,
  fieldError,
  getFieldRef,
  control,
  ...other
}: BasicTimePickerProps) => (
  <div ref={getFieldRef}>
    <TimePicker
      {...other}
      {...control}
      id={`field-${name}`}
      invalidText={fieldError}
      invalid={!isEmpty(fieldError)}
      disabled={disabled}
      value={fieldValue}
      defaultValue={initialValue}
      labelText={label}
    />
  </div>
);

export default BasicTimePicker;
