// @flow
import { isEmpty, upperFirst } from 'lodash';

const ACCOUNT_STATUS = {
  ACTIVE: 'ACTIVE'
};

class Account {
  constructor({
    id,
    name,
    customPageName,
    boxOfficeCustomer,
    cashless,
    gofanSchoolType,
    headerImage,
    logo,
    latitude,
    longitude,
    primaryColor,
    paymentCycle,
    state,
    status,
    scanEnabled,
    streetAddress,
    facebookUrl,
    twitterUrl,
    city,
    zip,
    mascot,
    shortName,
    searchEnabled,
    timeZone,
    venueIds,
    venues,
    conferenceLeagueC,
    districtHuddleId,
    sfId
  }) {
    this.raw = {
      id,
      name,
      customPageName,
      boxOfficeCustomer,
      cashless,
      gofanSchoolType,
      headerImage,
      logo,
      latitude,
      longitude,
      primaryColor,
      paymentCycle,
      facebookUrl,
      twitterUrl,
      zip,
      streetAddress,
      state,
      status,
      city,
      mascot,
      shortName,
      scanEnabled,
      searchEnabled,
      timeZone,
      venueIds,
      venues,
      conferenceLeagueC,
      districtHuddleId,
      sfId
    };
  }

  get id() {
    return this.raw.id;
  }

  get name() {
    return this.raw.name;
  }

  get customPageName() {
    return this.raw.customPageName;
  }

  get boxOfficeCustomer() {
    return this.raw.boxOfficeCustomer;
  }

  get cashless() {
    return this.raw.cashless;
  }

  get headerImage() {
    return this.raw.headerImage;
  }

  get logo() {
    return this.raw.logo;
  }

  get latitude() {
    return this.raw.latitude;
  }

  get longitude() {
    return this.raw.longitude;
  }

  get primaryColor() {
    return this.raw.primaryColor;
  }

  get state() {
    return isEmpty(this.raw.state) ? '' : this.raw.state.toUpperCase();
  }

  get status() {
    return isEmpty(this.raw.status) ? '' : this.raw.status.toUpperCase();
  }

  get isActive() {
    return this.status === ACCOUNT_STATUS.ACTIVE;
  }

  get streetAddress() {
    return isEmpty(this.raw.streetAddress) ? '' : upperFirst(this.raw.streetAddress);
  }

  get facebookUrl() {
    return this.raw.facebookUrl;
  }

  get twitterUrl() {
    return this.raw.twitterUrl;
  }

  get scanEnabled() {
    return this.raw.scanEnabled;
  }

  get city() {
    return isEmpty(this.raw.city) ? '' : upperFirst(this.raw.city);
  }

  get zip() {
    return this.raw.zip;
  }

  get districtHuddleId() {
    return this.raw.districtHuddleId;
  }

  get sfId() {
    return this.raw.sfId;
  }

  set id(id) {
    this.raw.id = id;
  }

  set name(name) {
    this.raw.name = name;
  }

  set scanEnabled(scanEnabled) {
    this.raw.scanEnabled = scanEnabled;
  }

  set headerImage(headerImage) {
    this.raw.headerImage = headerImage;
  }

  set logo(logo) {
    this.raw.logo = logo;
  }

  set latitude(latitude) {
    this.raw.latitude = latitude;
  }

  set longitude(longitude) {
    this.raw.longitude = longitude;
  }

  set primaryColor(primaryColor) {
    this.raw.primaryColor = primaryColor;
  }

  set state(state) {
    this.raw.state = state;
  }

  set streetAddress(streetAddress) {
    this.raw.streetAddress = streetAddress;
  }

  set facebookUrl(facebookUrl) {
    this.raw.facebookUrl = facebookUrl;
  }

  set twitterUrl(twitterUrl) {
    this.raw.twitterUrl = twitterUrl;
  }

  set city(city) {
    this.raw.city = city;
  }

  set zip(zip) {
    this.raw.zip = zip;
  }

  set districtHuddleId(districtHuddleId) {
    this.raw.districtHuddleId = districtHuddleId;
  }

  set sfId(sfId) {
    this.raw.sfId = sfId;
  }

  get mascot() {
    return this.raw.mascot;
  }

  set mascot(mascot) {
    this.raw.mascot = mascot;
  }

  get shortName() {
    return this.raw.shortName;
  }

  set shortName(shortName) {
    this.raw.shortName = shortName;
  }

  get searchEnabled() {
    return this.raw.searchEnabled;
  }

  set searchEnabled(searchEnabled) {
    this.raw.searchEnabled = searchEnabled;
  }

  get timeZone() {
    return this.raw.timeZone;
  }

  get venueIds() {
    return this.raw.venueIds;
  }

  get venues() {
    return this.raw.venues;
  }

  get gofanSchoolType() {
    return this.raw.gofanSchoolType || '';
  }

  get paymentCycle() {
    return this.raw.paymentCycle;
  }

  set gofanSchoolType(gofanSchoolType) {
    this.raw.gofanSchoolType = gofanSchoolType;
  }

  get conferenceLeagueC() {
    return this.raw.conferenceLeagueC;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      customPageName: this.customPageName,
      boxOfficeCustomer: this.boxOfficeCustomer,
      cashless: this.cashless,
      gofanSchoolType: this.gofanSchoolType,
      headerImage: this.headerImage,
      logo: this.logo,
      latitude: this.latitude,
      longitude: this.longitude,
      primaryColor: this.primaryColor,
      paymentCycle: this.paymentCycle,
      state: this.state,
      status: this.status,
      streetAddress: this.streetAddress,
      facebookUrl: this.facebookUrl,
      twitterUrl: this.twitterUrl,
      city: this.city,
      zip: this.zip,
      mascot: this.mascot,
      shortName: this.shortName,
      scanEnabled: this.scanEnabled,
      searchEnabled: this.searchEnabled,
      isActive: this.isActive,
      timeZone: this.timeZone,
      venueIds: this.venueIds,
      venues: this.venues,
      conferenceLeagueC: this.conferenceLeagueC,
      districtHuddleId: this.districtHuddleId,
      sfId: this.sfId
    };
  }
}

export default Account;
