import { css } from 'styled-components';
import common from '../../../../assets/styles/common';

export default {
  input: css`
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;
  `,
  round: css`
    position: relative;
    width: 24px;
    height: 24px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 100%;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 100%;
      height: 100%;
      border: 2px solid #3b86ff;
    }

    &:after {
      opacity: 0;
      width: 14px;
      height: 14px;
      background: #3b86ff;
      transition: opacity 0.2s ease-in;
    }

    ${({ checked }) =>
      checked &&
      `
        cursor: default;
        &:after {
          opacity: 1;
        }
      `}

    ${({ disabled }) =>
      disabled &&
      `
        cursor: default;
        &:before {
          border: 2px solid #cccccc;
        }
        &:after {
          background: #cccccc;
        }
      `}
  `,
  label: css`
    display: flex;
    padding-left: 10px;
    align-items: center;
    cursor: pointer;
    ${common.fieldValueRegular};

    ${({ boldText }) =>
      boldText &&
      css`
        ${common.fieldLabel};
      `}

    ${({ disabled }) =>
      disabled &&
      `
        cursor: default;
        color: #999999;
      `}
  `,
  radioWrapper: css`
    display: flex;
    cursor: pointer;
  `,
  wrapper: css`
    display: inline-block;
  `
};
