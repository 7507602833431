export const HEALTH_CHECK = '/actuator/health';
export const SEARCH_PURCHASED_TICKETS_ENDPOINT = 'tickets/search';
export const REFUND_PURCHASED_TICKETS_ENDPOINT = '/api/payments/refund';
export const REFUND_BY_EVENT = '/api/payments/events/{eventId}/refund_tickets';
export const CREATE_USER = '/users/';
export const UPDATE_USER = '/users/{email}';
export const SEARCH_USER = '/users/search';
export const GET_BASIC_USER_BY_EMAIL = '/users/{email}/basic';
export const GET_USER_BY_EMAIL_TOKEN = '/users/?email_token={email_token}';
export const RESEND_INVITATION = '/users/{email}/message/new-user-invite?channel=Admin';
export const USER_HISTORY_URI = '/users/history';
export const ACCOUNT_HISTORY_URI = '/accounts/{id}/history?dateRange={dateRange}';
export const EVENT_HISTORY_URI = '/events/{id}/history?dateRange={dateRange}';
export const FETCH_EVENTS =
  '/events/search?expand=venue&expand=account-host&expand=account-opponent&expand=account&expand=event-type&expand=levels&expand=activity&sort=startDateTime,asc&skipCache=true';
export const FETCH_EVENT =
  '/events/{eventId}/?expand=account-host&expand=account-opponent&expand=account&expand=event-type&expand=levels&expand=event-sales-info&expand=activity';
export const FETCH_EVENT_REFUND = '/events/search/forRefund';
export const GET_EVENT = '/events/{id}';
export const GET_EVENT_BY_PARAMS = '/events/search';
export const CREATE_EVENT = '/events/';
export const UPDATE_EVENT = '/events/{id}';
export const UPDATE_EVENTS = '/events/update';
export const CANCEL_EVENT = '/events/cancel';
export const DELETE_EVENT = '/events/{id}';
export const GET_EVENT_SALES_INFO = '/events/{id}/sales-info';
export const GET_EVENT_COUNT_BY_TYPE = '/events/count-by-type';
export const PUBLISH_AWAY_EVENT = '/events/away-game/{id}/publish';
export const IGNORE_AWAY_EVENT = '/events/away-game/{id}/ignore';
export const DECLINE_AWAY_EVENT = '/events/away-game/{id}/decline';

export const GET_TICKETS_BY_EVENT_ID = '/tickets/event/{id}';
export const GET_TICKETS_BY_SEASON_ID = '/tickets/season/{id}';

export const GET_EVENT_SEATS_BY_STATUS = '/reserved-seating/?eventId={eventId}';
export const GET_SEASON_SEATS_BY_STATUS = '/reserved-seating/?seasonId={seasonId}';
export const GET_EVENT_UNAVAILABLE_SEATS = '/reserved-seating/unavailable/?eventId={eventId}';
export const GET_SEASON_UNAVAILABLE_SEATS = '/reserved-seating/season/unavailable/?seasonId={seasonId}';

export const EVENT_DAILY_SALES_INFO = '/api/events/daily-sales-info';
export const SALES_ALLOTMENT = '/api/events/{eventId}/sales-allotment';
export const CREATE_EVENT_TYPE = '/event-types/';
export const FETCH_ACCOUNTS = '/accounts/search';
export const FETCH_ACCOUNT_BY_ID = '/accounts/{id}';
export const FETCH_ACCOUNTS_BY_IDS = '/accounts/searchByIds';
export const FETCH_ACCOUNTS_PRODUCT = '/account-product/search';
export const FETCH_ACCOUNTS_BY_KEYWORD = '/accounts/search?keyword={keyword}';
export const LEVEL_URI = '/levels/';
export const ACTIVITY_URI = '/activities/';
export const RATE_URI = '/rates/';
export const GET_RATE_BY_ID_URI = '/rates/{id}';
export const PRODUCT_TYPES_URI = '/product-types/';
export const ACCOUNT_TICKETS_URI = '/account-product/list';
export const ACCOUNT_TICKET_URI = '/account-product/';
export const VENUE_URI = '/venues/{id}';
export const VENUE_SEATS_URI = '/venues/{id}/seats';
export const UPLOAD_EVENT = '/events/upload';
export const GET_SEASON_BY_ID = '/seasons/{id}';
export const UPDATE_SEASON = '/seasons/{id}';
export const SEARCH_SEASON_BY_IDS_V2 = '/v2/seasons/searchByIds';
export const UPDATE_SEASON_WITH_EVENT = '/seasons/{id}/withEvents';
export const CREATE_SEASON = '/seasons/withEvents';
export const CREATE_LIST_SEASON_WITH_EVENTS = '/seasons/listSeasonWithEvents';
export const RESERVED_SEAT_STATUS = '/reserved-seating/';
export const SEASON_RESERVED_SEAT_STATUS = '/reserved-seating/season';
export const RESERVED_SEAT_BLOCK = '/reserved-seating/block';
export const RESERVED_SEAT_UNBLOCK = '/reserved-seating/unblock';
export const TICKETS_PATCH_BOOKED_SEAT = '/tickets/{ticketId}/seat';
export const TICKETS_DELETE_BOOKED_SEAT = '/tickets/{ticketId}/seat';
export const ORDERS_COMP_TICKETS = '/orders/compticket';
export const SEARCH_SEASONS = '/seasons/search';
export const GET_USER_URI = '/users/{email}';
export const UPDATE_ACCOUNT_BY_ID = '/accounts/{accountId}';
export const FETCH_ACCOUNT_FINANCIAL_BY_ACCOUNT_ID = '/account-financial/?accountId={accountId}';
export const UPDATE_ACCOUNT_FINANCIAL_BY_ID = '/account-financial/{id}';
export const CREATE_ACCOUNT_FINANCIAL = '/account-financial/';
export const CREATE_COMPTICKET = '/orders/compticket';
export const GET_TICKET_TYPES_BY_ID = '/tickets/types/{id}';
export const FETCH_PURCHASED_TICKET = '/tickets/fan/search';
export const REDEEM_TICKET = '/orders/tickets/{accessToken}';
export const FETCH_PURCHASED_SEASON_TICKET = '/tickets/season/search';
export const PATCH_USE_TICKET = '/orders/tickets/{accessToken}';
export const UPDATE_PURCHASED_TICKET = '/tickets/{accessToken}';
export const EXPORT_CSV = '/jobs/fan-tickets/export/';
export const EXPORT_ACCESS_CODES_CSV = '/jobs/promotion/export/';
export const EXPORT_PROMOTION_CODES_CSV = '/jobs/promotion/export/';
export const EXPORT_SEASON_CSV = '/jobs/season-tickets/export/';
export const DOWNLOAD_CSV = '/s3bucket/download/?{fileName}';
export const PATCH_UNREDEEM_TICKET = '/tickets/unredeem';
export const UNREDEEM_EVENT = '/jobs/event/unredeem';
export const FETCH_JOB_BY_ID = '/jobs/{id}';
export const DOWNLOAD_UPLOAD_EVENTS = '/jobs/upload-events/export/';
export const FINANCIAL_HISTORY_URI = '/account-financial/{id}/history?dateRange={dateRange}';
export const FETCH_GLOBAL_EVENTS = '/global-events/search';
export const EVENT_SCHEDULES_SEARCH = '/global-events/schedule/search';
export const EVENT_MONITOR_INDICATOR = '/global-events/schedule/available';
export const PATCH_GLOBAL_EVENT = '/global-events/{id}';
export const GET_GLOBAL_EVENT_PARTNER_INSIGHT = '/global-events/partner/insight';
export const IGNORE_SCHEDULE = '/global-events/schedule/ignore';
export const RESTORE_SCHEDULE = '/global-events/schedule/restore';
export const GET_EVENT_PARTNER_SALES_INFO = '/events/partner/sales-info';
export const CHECK_DUPLICATE_EVENT = '/events/check-duplicate';
export const FIND_ACCOUNT_BY_IDS = '/accounts/searchByIds';
export const GET_TICKET_SALES_INSIGHT_DATA = '/api/events/{eventId}/ticket-distribution';
export const GET_EVENT_TOTAL_TICKETS = 'events/{id}/total_tickets';
export const GET_TICKET_SALE_REPORT =
  '/api/events/{schoolId}/{periodStart}/{periodEnd}/{format}/{statementType}/sale-report';
export const PLAYOFF_CALENDAR_SEARCH_URI = '/playoff/search';
export const SEARCH_EVENTS_BY_IDS_V2 = '/v2/events/searchByIds';
export const GET_CRM_SCHOOL_BY_ID = '/crm/schools/{id}';
export const GET_SEATS_IO_WORKSPACE = '/accounts/{id}/keys';
export const GET_ACCOUNT_METRICS = '/api/account-metrics';
export const GET_PAYMENT_STATEMENT = '/api/financial/{financialSchoolId}/payment-statements';
export const GET_PAYMENT_STATEMENT_FILES_BY_ID = '/api/financial/payment-statements/{id}/files';
export const GET_PAYMENT_EVENTS = '/api/financial/payment-events/';
export const GET_PAYMENT_STATEMENT_FILES_BY_IDS = '/api/financial/payment-statements/files';
export const SEND_REFUND_REQUEST = '/api/financial/fan-refund';
export const GET_PAYMENT_EVENTS_FILES_BY_ID = '/api/financial/payment-events/{id}/files';
export const GET_PURCHASED_TICKETS_BY_EMAIL = '/tickets/search/user-email';
export const GET_SEASON_INSIGHT_DATA = '/api/financial/insights/seasons/{id}';

export const RESCHEDULE_EVENT = '/events/postpone ';
export const SEARCH_ADJUSTMENTS = '/api/adjustment/search';
export const CREATE_ADJUSTMENT = '/api/adjustment';
export const GET_ADJUSTMENT_BY_ID = '/api/adjustment/{id}';
export const APPROVE_ADJUSTMENT = '/api/adjustment/approve';
export const DELETE_ADJUSTMENT = '/api/adjustment/delete';
export const GET_PAYMENT_SEASONS = '/api/financial/payment-seasons/';
export const GET_PAYMENT_SEASONS_FILES_BY_ID = '/api/financial/payment-seasons/{id}/files';
export const SEARCH_NEGATIVE_CARRYFORWARD = '/api/negative-carry/search';
export const GET_NEGATIVE_CARRYFORWARD_DETAIL = '/api/negative-carry/details';
export const SETTLEMENT_ACH_CONVERT_CSV = '/api/settlement-engine/ach/convert-csv-file';
export const SETTLEMENT_CHECK_CONVERT_CSV = '/api/settlement-engine/check/convert-csv-file';
export const SEARCH_PAYOUT_REMITTANCES = '/api/payout-remittances/search';
export const CREATE_REISSUANCE_REQUEST = '/api/payout-remittances/reissuances';
export const CREATE_CANCELATION_REQUEST = '/api/payout-remittances/cancelations';
export const GET_SEASON_RENEWALS_BY_SEASON_ID = '/seasonRenewal?seasonId={seasonId}';
export const CREATE_SEASON_RENEWAL = '/seasonRenewal/upload';
export const UPDATE_SEASON_RENEWAL_BY_ID = '/seasonRenewal/{id}';
export const GET_SEASON_RENEWAL_SEATS = '/seasonRenewal/seats';
export const UPDATE_SEASON_RENEWAL_FAN = '/season-renewal-fans/{id}';
export const DELETE_SEASON_RENEWAL_FAN = '/season-renewal-fans/{id}';
export const SEARCH_PURCHASED_SEASON_PASS_TICKETS = '/tickets/season-passes/search';
export const SEARCH_PAYOUT_SUMMARY = '/api/payouts/summary';
export const GET_FLATFILE_TOKEN = '/api/flatfile/token';
export const WEBHOOK_BULK_UPLOAD_EVENTS = '/webhook';
export const FALLOUT_GET_PNR = '/api/fallout/pnr';

export const CREATE_PROMOTION = '/promotions/'; // POST
export const GET_PROMOTION = '/promotions/'; // GET
export const GET_INSIGHT_PROMOTION = '/api/promotions/{id}/insight';
export const GET_JOBS_PROMOTIONS = '/jobs/promotion/{id}';
export const UPDATE_PROMOTIONS_OF_EVENT = '/jobs/promotion/update';
export const DELETE_PROMOTION = '/api/promotions/{id}'; // DELETE
export const UPDATE_PROMOTION = '/api/promotions/{id}'; // PUT
export const CHECK_DUPLICATE_PROMOTION = '/api/promotions/check-duplicate';
export const CREATE_BULK_PROMOTION_CODES = '/api/accessCodeBulkUpload/{bucket}';
export const GET_STATUS_PROMOTION_CODES = '/api/promotions/{id}/status';
export const BULK_DELETE_PROMOTIONS = '/jobs/promotion/bulk-delete/';
export const SEARCH_PROMOTION_LOCK = '/api/promotion-locks/search';
export const SEASON_RENEWAL_FAN_EXPORT = '/jobs/seasonRenewalFan/export/';
export const EVICT_PROMOTIONS_SEARCH_CACHE = '/api/promotions/evict-cache';
export const DELETE_PROMOTIONS_CACHE = '/api/promotions/{id}/cache';

export const GET_TICKET_FORM_BY_ID = '/api/forms/{id}';
export const UPDATE_FORM_RESPONSES_TICKET = '/api/form-responses/fields';
export const GET_TICKET_FORM_HISTORY = '/api/form-responses/fields-history';

export const EXPORT_EVENTS_CSV = '/jobs/events/export/';
export const EXPORT_PRACTICES_CSV = '/practice/export/';
export const BULK_UPLOAD_EVENTS = '/api/flatfile/bulk-upload/{bucket}';
export const STATUS_BULK_UPLOAD_EVENTS = '/api/flatfile/bulk-upload/{batchId}/status';

export const ENCRYPT_FORM_FIELD = '/api/form-field/encrypt';

export const TEST_CAMERA = '/events/test-camera';

export const SCHOOL_CONFIG_QUERY_KEY = 'school-config';
export const SCHOOL_CONFIG = '/school-config/';
export const SCHOOL_CONFIG_ID = '/school-config/{id}';
export const SCHOOL_CONFIG_BY_SCHOOL_ID = '/school-config/search/{schoolId}';
export const SCHOOL_CONFIG_BULK_UPDATE = '/school-config/bulk/update';
export const SCHOOL_CONFIG_BY_SCHOOL_IDS = '/school-config/searchBySchoolIds';
