// @flow
import { css } from 'styled-components';
import common, {
  LIGHT_BLUE,
  SOURCESANPRO_REGULAR
} from '../../../../assets/styles/common';

export default {
  wrapper: css`
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 200px;
    margin-top: -100px;
    margin-left: 36px;
  `,
  buttonWrapper: css`
    margin-top: 30px;
  `,
  headline: css`
    ${common.h3};
    font-size: 2.5em;
    color: black;
    letter-spacing: 2px;
  `,
  paragraph: css`
    ${SOURCESANPRO_REGULAR}
    margin-top: 15px;
    font-size: 16px;
    color: black;
  `,
  link: css`
    color: ${LIGHT_BLUE};
    &:hover,
    &:active {
      color: ${LIGHT_BLUE};
    }
  `,
  orderList: css`
    padding-left: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `
};
