export const ACCOUNT_TYPES = ['State Association', 'High School', 'Member School', 'Middle School', 'K - 12'];

export const ACCOUNT_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export const DEFAULT_ACCOUNT = 'GA72550';

export const DEFAULT_ACCOUNT_NAME = 'GoFan';

export const SCHOOL_PRIMARY_COLOR_DEFAULT = '6f6f6f';

export const CONTACT_STRINGS = {
  EDIT: 'Edit',
  ADD_NEW_CONTACT: 'Add new contact',
  CLOSE: 'Close',
  OTHER: 'Other',
  ACTIVE: 'Active',
  IN_ACTIVE: 'Inactive',
  DONT_HAVE_PERMISSON: 'You don’t have permission to view contacts.',
  DONT_HAVE_ADD_PERMISSION: 'You don’t have permission to add contacts.',
  DONT_HAVE_EDIT_PERMISSION: 'You don’t have permission to edit contacts.',
  CREATE_CONTACT: 'Create Contact',
  DESCRIPTION_UPLOAD_PHOTO: 'Max file size is 500kb. Supported file types are .jpg, .jpeg and .png.',
  SAVE: 'Save',
  DELETE: 'Delete contact'
};

export const CONTACT_PRIMARY = 'CONTACT_PRIMARY';
export const CONTACT_FINANCE = 'CONTACT_FINANCE';
export const CONTACT_OTHER = 'CONTACT_OTHER';

export const GOFAN_ROLE_OPTIONS = [
  {
    id: CONTACT_PRIMARY,
    text: 'Main contact'
  },
  {
    id: CONTACT_FINANCE,
    text: 'Financial contact'
  },
  {
    id: CONTACT_OTHER,
    text: 'Other'
  }
];

export const CONTACT_PILL_OPTION = {
  GOFAN_ROLE: 'GOFAN_ROLE',
  ACCOUNT_STATUS_ENABLE: 'ACCOUNT_STATUS_ENABLE',
  ACCOUNT_STATUS_PENDING: 'ACCOUNT_STATUS_PENDING'
};

export const FILTER_PARAMS = {
  gofanRoles: GOFAN_ROLE_OPTIONS,
  accountStatusEnable: true,
  accountStatusPending: true
};

export const GOFAN_SCHOOL_TYPES = [
  {
    id: 'high-school',
    text: 'High School'
  },
  {
    id: 'school-district',
    text: 'School District'
  },
  {
    id: 'booster-club',
    text: 'Booster Club'
  },
  {
    id: 'athletic-department',
    text: 'Athletic Department'
  },
  {
    id: 'company',
    text: 'Company'
  },
  {
    id: 'college',
    text: 'College'
  },
  {
    id: 'middle-school',
    text: 'Middle School'
  },
  {
    id: 'elementary-school',
    text: 'Elementary School'
  },
  {
    id: 'atheletic-conference',
    text: 'Athletic Conference'
  },
  {
    id: 'performing-arts',
    text: 'Performing Arts'
  },
  {
    id: 'state-association',
    text: 'State Association'
  },
  {
    id: 'special-event',
    text: 'Special Event'
  },
  {
    id: 'section',
    text: 'Section'
  },
  {
    id: 'K-12',
    text: 'K-12'
  }
  // Exists in prod db, but isn't needed as a filter
  // {
  //   id: 'subaccount',
  //   text: 'Subaccount'
  // }
];

export const SCHOOL_TYPES = {
  ATHLETIC_CONFERENCE: 'Athletic Conference'
};

export const DISTRICT_CONFERENCE_SCHOOL_TYPES = {
  ATHLETIC_CONFERENCE: 'Athletic Conference',
  SCHOOL_DISTRICT: 'School District'
};

export const FINANCIAL_ROLES = {
  DISTRICT_CONFERENCES: 'DISTRICT_CONFERENCES',
  HOME_SCHOOL: 'HOME_SCHOOL'
};

export const ACCOUNT_TICKETS_STRINGS = {
  DONT_HAVE_PERMISSON: 'You don’t have permission to view account tickets.'
};

export const FILE_SIZE_UPLOAD = 500000;
export const FILES_IMG_ACCEPT = ['.jpg', '.jpeg', '.png'];

export const PHOTO_UPLOAD_TYPE = {
  ADD_CONTACT: 'ADD_CONTACT',
  GOFAN_PAGE: 'GOFAN_PAGE'
};

export const EVENT_REPORT = {
  YES: 1,
  NO: 2
};

export const INITIAL_CONTACT_DETAILS_TEMPLATE = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  title: '',
  contactType: '',
  avatar: '',
  hasEditorPermission: false,
  hasBoxOfficePermission: false,
  hasFinancialPermission: false,
  hasInsiderPermission: false,
  hasReportPermission: false,
  athleticReport: [],
  nonAthleticReport: []
};

export const MODAL_ACTION = {
  DELETE: 'DELETE',
  SAVE_CHANGES: 'SAVE_CHANGES',
  CONFIRM_ADD_NEW_CONTACT: 'CONFIRM_ADD_CONTACT',
  NO_PERMISSION_ADD_NEW_CONTACT: 'NO_PERMISSION_ADD_NEW_CONTACT',
  CONFIRM_ADD_EXISTING_CONTACT: 'CONFIRM_ADD_EXISTING_CONTACT',
  NO_PERMISSION_ADD_EXISTING_CONTACT: 'NO_PERMISSION_ADD_EXISTING_CONTACT'
};

export const NOTIFICATION_STATUS = {
  DISPLAY: 'DISPLAY',
  NONE: 'NONE',
  HIDDEN: 'HIDDEN'
};

export const US_STATES = {
  WASHINGTON: 'WA'
};

export const STATES_OF_HIGH_RISK_MARKET = ['AL', 'AR', 'AZ', 'MS', 'MT', 'NM', 'TX'];

export const ACCOUNT_PAYMENT_CYCLE = {
  DAILY: 'daily_transaction_close',
  WEEKLY: 'weekly_transaction_close',
  EVENT_BASED: 'weekly_event_close'
};

export const PAYMENT_CYCLE_TERMS = {
  daily_transaction_close: 'Daily Settlement',
  weekly_transaction_close: 'Calendar-based',
  weekly_event_close: 'Event-based'
};

export const PAYMENT_SOURCE_TYPES = {
  ACH: 'ACH',
  CHECK: 'Check'
};

export const ACH_INFORMATION = [
  'name',
  'accountingNumber',
  'phoneNumber',
  'extNumber',
  'routingNumber',
  'taxId',
  'address',
  'addressTwo',
  'city',
  'state',
  'zipCode',
  'attn',
  'taxEntityName'
];

export const CHECK_ADDRESS_FIELDS = ['checkAddress', 'checkAddressTwo', 'checkCity', 'checkState', 'checkZip'];

export const CHECK_PAYABLE_INFO_FIELDS = [...CHECK_ADDRESS_FIELDS, 'checkAttention', 'taxId', 'taxEntityName'];

export const ALLOWED_GOFAN_SCHOOL_TYPES = ['State Association', 'School District', 'Section'];

export const GROUP_SCHOOL_TITLE = {
  SCHOOLS_IN_YOUR_DISTRICT: 'Schools in Your District',
  SCHOOLS_IN_YOUR_CONFERENCE: 'Schools in Your Conference',
  SEARCH_RESULT: 'Search result',
  DISTRICT: 'District',
  OTHERS: 'Others'
};

export const SCHOOL_GROUP_ITEMS = {
  DISTRICT: {
    id: 'school_district',
    name: 'District',
    isGroupLabel: true,
    disabled: true
  },
  SCHOOL_UNDER_DISTRICT: {
    id: 'school_under_district',
    name: 'Schools in Your Districts',
    isGroupLabel: true,
    disabled: true
  },
  OTHERS: {
    id: 'school_others',
    name: 'Others',
    isGroupLabel: true,
    disabled: true
  }
};

export default {
  CONTACT_STRINGS,
  FILTER_PARAMS,
  CONTACT_PILL_OPTION,
  GOFAN_ROLE_OPTIONS,
  GOFAN_SCHOOL_TYPES,
  ACCOUNT_TICKETS_STRINGS,
  INITIAL_CONTACT_DETAILS_TEMPLATE,
  MODAL_ACTION,
  NOTIFICATION_STATUS
};
