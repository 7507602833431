import { lazy, Suspense, useRef, useContext, useEffect, useCallback, useState } from 'react';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';

import CacheService from '@app/api/services/CacheService';
import AuthGuardedRoute from './components/AuthGuardedRoute';
import HeaderComponent from './components/Header';
import BodyContainerComponent from './components/BodyContainerComponent';
import Profile from '../pages/Profile/index.tsx';
import SystemError from '../pages/SystemError';
import { SkeletonPlaceholder } from '@gofan/components';
import { RootContext } from '../RootContext';
import { config } from '@gofan/constants';
import { errorMessages } from '../commons';
import { PAGES } from '../config/routes';
import { PendoSupportForm } from '@gofan/components/PendoSupportForm';
import { useFeatureFlags, FEATURE_FLAGS, useLDClient } from '@gofan/hooks';

import '../global.css';
import './root-style.css';
import 'semantic-ui-css/semantic.min.css';

const UnderConstruction = lazy(() => import('../commons/UnderConstruction'));
const FanSupport = lazy(() => import('../pages/FanSupport'));
const AccountList = lazy(() => import('../pages/AccountList'));
const UsersPage = lazy(() => import('../pages/users'));
const Activities = lazy(() => import('../pages/Activities'));
const Levels = lazy(() => import('../pages/Levels'));
const Products = lazy(() => import('../pages/Products'));
const Tournaments = lazy(() => import('../modules/tournaments/tournaments.container'));

const Wrapper = ({ featureFlag, ...props }) => {
  const context = useContext(RootContext);
  const inactiveTimer = useRef(null);
  const location = useLocation();
  const ldClient = useLDClient();
  const [initialized, setInitialized] = useState(typeof featureFlag !== 'string');
  const history = useHistory();

  useEffect(() => {
    const initializeLaunchDarkly = async () => {
      await ldClient.waitForInitialization();
      setInitialized(true);
    };

    if (ldClient && !initialized) initializeLaunchDarkly();
  }, [initialized, ldClient]);

  const { [featureFlag]: featureFlagEnabled } = useFeatureFlags();

  useEffect(() => {
    if (initialized && typeof featureFlag === 'string' && !featureFlagEnabled) {
      history.replace(PAGES.error.error404.path);
    }
  }, [initialized, featureFlagEnabled]);

  useEffect(() => {
    if (context.sideNavIsExpanded) context.setSideNavIsExpanded(false);
  }, [location]);

  const onIdle = useCallback(() => {
    inactiveTimer.current = setTimeout(() => {
      context.clearAuthorization();
      context.setSessionState({
        message: errorMessages.TIMEOUT_SESSION_MESSAGE
      });
    }, config.TIMEOUT_WHEN_INACTIVE);
  }, []);

  const onActive = useCallback(() => {
    clearTimeout(inactiveTimer.current);
  }, []);

  const toggleSideNav = useCallback(() => {
    context.setSideNavIsExpanded(prevState => !prevState);
  }, []);

  const onGetLocationSuccess = useCallback(position => {
    const currentPosition = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    };
    CacheService.putDataToCache(
      CacheService.USER_COORDINATE_CACHE_NAME,
      CacheService.CURRENT_COORDINATE_CACHE_NAME,
      currentPosition
    );
  }, []);

  const onGetLocationError = useCallback(() => {
    CacheService.deleteCachedData(CacheService.POSITION_CACHE_NAME);
  }, []);

  useEffect(() => {
    clearTimeout(inactiveTimer.current);
    if (!navigator.geolocation) {
      return;
    }
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };

    window.navigator.geolocation.getCurrentPosition(onGetLocationSuccess, onGetLocationError, options);
    window.navigator.geolocation.watchPosition(onGetLocationSuccess, () => {}, options);
  }, []);

  return (
    initialized && (
      <>
        <IdleTimer crossTab onActive={onActive} onIdle={onIdle} timeout={config.TIMEOUT_TO_START_IDLE} />
        <PendoSupportForm currentUser={context.currentUser} />
        <HeaderComponent
          history={props.history}
          location={props.location}
          toggleSideMenu={toggleSideNav}
          isSideMenuExpanded={context.sideNavIsExpanded}
        />
        <BodyContainerComponent
          {...props}
          currentUser={context.currentUser}
          isSideMenuExpanded={context.sideNavIsExpanded}
        >
          <Suspense fallback={<SkeletonPlaceholder />}>{props.children}</Suspense>
        </BodyContainerComponent>
      </>
    )
  );
};

const Routes = () => [
  <AuthGuardedRoute
    exact
    path={PAGES.profile.root.path}
    key={PAGES.profile.root.path}
    render={props => (
      <Wrapper {...props}>
        <Profile {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    path={PAGES.users.root.path}
    key={PAGES.users.root.path}
    render={props => (
      <Wrapper {...props}>
        <UsersPage {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    path={PAGES.activities.root.path}
    key={PAGES.activities.root.path}
    render={props => (
      <Wrapper {...props}>
        <Activities {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    path={PAGES.levels.root.path}
    key={PAGES.levels.root.path}
    render={props => (
      <Wrapper {...props}>
        <Levels {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    path={PAGES.fanSupport.root.path}
    key={PAGES.fanSupport.root.path}
    render={props => (
      <Wrapper {...props}>
        <FanSupport {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    path={PAGES.events.account.path}
    key={PAGES.events.account.path}
    render={props => (
      <Wrapper {...props}>
        <AccountList {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    path={PAGES.season.account.path}
    key={PAGES.season.account.path}
    render={props => (
      <Wrapper {...props}>
        <AccountList {...props} mode='season' />
      </Wrapper>
    )}
  />,

  <AuthGuardedRoute
    path={PAGES.tournaments.account.path}
    key={PAGES.tournaments.account.path}
    render={props => (
      <Wrapper {...props} featureFlag={FEATURE_FLAGS.opTournamentsOptionDashbirds}>
        <AccountList {...props} mode='tournament' />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    path={PAGES.tournaments.root.path}
    key={PAGES.tournaments.root.path}
    render={props => (
      <Wrapper {...props} featureFlag={FEATURE_FLAGS.opTournamentsOptionDashbirds}>
        <Tournaments {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    exact
    path={PAGES.reports.root.path}
    key={PAGES.reports.root.path}
    render={props => (
      <Wrapper {...props}>
        <UnderConstruction {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    exact
    path={PAGES.offers.root.path}
    key={PAGES.offers.root.path}
    render={props => (
      <Wrapper {...props}>
        <UnderConstruction {...props} />
      </Wrapper>
    )}
  />,
  <AuthGuardedRoute
    exact
    path={PAGES.products.root.path}
    key={PAGES.products.root.path}
    render={props => (
      <Wrapper {...props}>
        <Products {...props} />
      </Wrapper>
    )}
  />,
  <Route path={PAGES.error.root.path} key={PAGES.error.root.path} component={props => <SystemError {...props} />} />,
  <Redirect key={PAGES.error.error404.path} to={PAGES.error.error404.path} />
];
export default Routes;
