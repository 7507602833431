import React from 'react';
import { DatePicker, DatePickerInput, TimePicker, SelectItem, TimePickerSelect } from 'carbon-components-react';
import moment from 'moment';
import styled from 'styled-components';
import { split } from 'lodash';
import styles, { StyledContainer } from './styles';
import { isEmpty } from '../../../../utils/objectUtils';
import { timeAMPM, dateValidation } from '../../../validations';

const StyledDatePickerContainer = styled.div`
  ${styles.dateContainer};
`;
const StyledTimeContainer = styled.div`
  ${styles.timeContainer};
`;
const StyledTimePickerContainer = styled(TimePicker)`
  ${styles.timePickerContainer};
`;
const StyledTimePickerSelectContainer = styled(TimePickerSelect)`
  ${styles.timePickerSelectContainer};
`;
const DATE_PICKER_STRINGS = {
  AM: 'AM',
  PM: 'PM'
};
const DATE_PICKER_ERRORS_STRINGS = {
  EMPTY: 'EMPTY',
  INCORRET_FORMAT: 'INCORRET_FORMAT',
  OUT_OF_RANGE: 'OUT_OF_RANGE'
};
const timeInRange = ({ hour, minute, time24hr }) =>
  time24hr
    ? hour > 0 && hour <= 23 && minute >= 0 && minute < 60
    : hour > 0 && hour <= 12 && minute >= 0 && minute < 60;

const combineDate = ({ date, hour, minute, second, type, time24hr }) => {
  const newDate = moment.isMoment(date) ? date : moment(date);
  if (!newDate.isValid()) {
    return undefined;
  }
  let h = hour;

  if (!time24hr && type === DATE_PICKER_STRINGS.AM) {
    h = hour === 12 ? 0 : hour;
  } else if (!time24hr && type === DATE_PICKER_STRINGS.PM) {
    h = hour === 12 ? hour : hour + 12;
  }

  return newDate.clone().set('hour', h).set('minute', minute).set('second', second);
};

const DATE_PICKER_ELEMENTS = {
  TIME: {
    touched: false,
    valid: false,
    value: '',
    hour: 0,
    minute: 0,
    second: 0,
    type: 'PM',
    errorType: ''
  }
};

const REGX_TIME_WITHOUT_COLON = /^[0-9]{3,4}$/;
const REGX_TIME_WITH_COLON = /^[0-9]{1,2}:[0-9]{1,2}$/;
export default ({
  minDate = '01/01/1970',
  maxDate = '12/31/9999',
  // allowInput = true,
  type = 'single',
  showTime = true,
  date = '',
  disableDate = false,
  disableTime = false,
  placeholder = 'mm/dd/yyyy',
  labelDateText = '',
  labelTimeText = '',
  invalidDate = false,
  invalidDateText = '',
  invalidTime = false,
  invalidTimeText = '',
  onChange = e => {},
  onBlur = e => {},
  onClose = e => {},
  onTimeBlur = e => {},
  onAMPMChange = e => {},
  time24hr = false,
  time = '',
  timeType = 'PM',
  id = 'event-date-picker-input',
  defaultTime = null,
  isCompact = false,
  light = false,
  ...props
}) => {
  const dateRef = React.useRef(null);
  const timeRef = React.useRef({
    ...DATE_PICKER_ELEMENTS.TIME,
    value: time,
    type: timeType
  });
  const dateInputTextRef = React.useRef(null);
  const [selectedDate, setSelectedDate] = React.useState('');

  const [dateError, setDateError] = React.useState(invalidDate);
  const [timeError, setTimeError] = React.useState(invalidTime);
  const [datePickerKey, setDatePickerKey] = React.useState(moment.now());

  React.useEffect(() => {
    const d = moment.isMoment(date) ? date : moment(date);
    if (!d.isValid()) {
      dateRef.current = '';
      if (defaultTime !== null) {
        timeRef.current = defaultTime;
      }
      setSelectedDate('');
    } else {
      const timeElements = { ...DATE_PICKER_ELEMENTS.TIME };
      if (time24hr) {
        timeElements.value = d.format('HH:mm');
        timeElements.hour = d.format('HH');
      } else {
        timeElements.value = d.format('hh:mm');
        timeElements.hour = d.format('hh');
      }
      timeElements.type = d.format('A');
      timeElements.minute = d.format('mm');
      if (d.format('HH:mm:ss') !== '00:00:00') {
        timeElements.value = d.format(time24hr ? 'HH:mm' : 'hh:mm');
      } else {
        timeElements.value = '';
        timeElements.type = timeRef.current.type;
      }
      timeElements.value = time;
      timeElements.valid = timeAMPM(time);
      timeRef.current = timeElements;
      dateRef.current = d;
      setSelectedDate(d);
    }
    dateInputTextRef.current = '';
  }, [date, time, defaultTime]);

  React.useEffect(() => {
    setTimeError(invalidTime);
    setDateError(invalidDate);
  }, [invalidTime, invalidTimeText, invalidDate, invalidDateText, minDate, maxDate]);

  const autoFormatTime = React.useCallback(() => {
    let valid = false;
    let error = '';
    const { value } = timeRef.current;
    if (!isEmpty(value)) {
      valid = timeAMPM(value) || REGX_TIME_WITHOUT_COLON.test(value) || REGX_TIME_WITH_COLON.test(value);
      if (valid) {
        if (timeAMPM(value)) {
          const timeElements = split(value, ':');
          timeRef.current = {
            ...timeRef.current,
            value: `${timeElements[0].length === 1 ? `0${timeElements[0]}` : timeElements[0]}:${
              timeElements[1].length === 1 ? `${timeElements[1]}0` : timeElements[1]
            }`,
            hour: parseInt(timeElements[0], 10),
            minute: parseInt(timeElements[1], 10)
          };
        } else if (REGX_TIME_WITH_COLON.test(value)) {
          const timeElements = split(value, ':');
          timeRef.current = {
            ...timeRef.current,
            value: `${timeElements[0].length === 1 ? `0${timeElements[0]}` : timeElements[0]}:${
              timeElements[1].length === 1 ? `0${timeElements[1]}` : timeElements[1]
            }`,
            hour: parseInt(timeElements[0], 10),
            minute: parseInt(timeElements[1].length === 1 ? `0${timeElements[1]}` : timeElements[1], 10)
          };
        } else if (REGX_TIME_WITHOUT_COLON.test(value)) {
          timeRef.current = {
            ...timeRef.current,
            value: `${value.length === 3 ? `0${value.substr(0, 1)}` : value.substr(0, 2)}:${
              value.length === 3 ? `${value.substr(1, 2)}` : value.substr(2, 2)
            }`,
            hour: parseInt(value.length === 3 ? value.substr(0, 1) : value.substr(0, 2), 10),
            minute: parseInt(value.length === 3 ? value.substr(1, 2) : value.substr(2, 2), 10)
          };
        }
        if (
          timeInRange({
            hour: timeRef.current.hour,
            minute: timeRef.current.minute,
            time24hr
          })
        ) {
          valid = true;
        } else {
          valid = false;
          error = DATE_PICKER_ERRORS_STRINGS.OUT_OF_RANGE;
        }
      } else {
        error = DATE_PICKER_ERRORS_STRINGS.INCORRET_FORMAT;
      }
    } else {
      error = DATE_PICKER_ERRORS_STRINGS.EMPTY;
    }
    return { valid, error };
  }, [timeRef.current]);
  const onCallBack = React.useCallback(
    fnc => {
      // let valid = false;
      if (dateRef.current === '') {
        const { error, valid } = autoFormatTime();
        fnc({
          date: undefined,
          time: {
            value: '',
            hour: timeRef.current.hour,
            minute: timeRef.current.minute,
            second: timeRef.current.second,
            type: timeRef.current.type,
            ...timeRef.current,
            valid, // :false,
            errorType: error // DATE_PICKER_ERRORS_STRINGS.EMPTY
          }
        });
        return;
      }

      const newDate = moment.isMoment(dateRef.current) ? dateRef.current : moment(dateRef.current);
      // let error = '';
      if (newDate.isValid() || (!isEmpty(timeRef.current) && !isEmpty(timeRef.current.value))) {
        const { error, valid } = autoFormatTime();

        const d = newDate.isValid() ? newDate : undefined;
        fnc({
          date:
            valid && newDate.isValid()
              ? combineDate({
                  date: dateRef.current,
                  hour: timeRef.current.hour,
                  minute: timeRef.current.minute,
                  second: timeRef.current.second,
                  time24hr,
                  type: timeRef.current.type
                })
              : d,
          time: {
            ...timeRef.current,
            value: timeRef.current.value,
            hour: timeRef.current.hour,
            minute: timeRef.current.minute,
            type: timeRef.current.type,
            valid,
            errorType: error
          }
        });
      } else {
        fnc({
          date: combineDate({
            date: dateRef.current,
            hour: 0,
            minute: 0,
            second: timeRef.current.second,
            time24hr: true,
            type: timeRef.current.type
          }),
          time: {
            value: '',
            hour: timeRef.current.hour,
            minute: timeRef.current.minute,
            second: timeRef.current.second,
            type: timeRef.current.type,
            ...timeRef.current,
            valid: false,
            errorType: DATE_PICKER_ERRORS_STRINGS.EMPTY
          }
        });
      }
    },
    [dateRef.current]
  );

  const onDateBlur = React.useCallback(() => {
    onCallBack(onBlur);
  });

  const onDateChange = React.useCallback(
    dates => {
      if (!isEmpty(dates)) {
        const [firstDate] = dates;
        setSelectedDate(firstDate);
        dateRef.current = firstDate;
        onCallBack(onChange);
        dateInputTextRef.current = '';
      } else {
        dateRef.current = '';
        if (!isEmpty(dateInputTextRef.current) && dateValidation(dateInputTextRef.current)) {
          const dateText = dateInputTextRef.current.replace(/\./g, '/').replace(/-/g, '/');
          const dateInput = moment(dateText);
          const min = moment.isMoment(minDate) ? minDate : moment(minDate);
          const max = moment.isMoment(maxDate) ? maxDate : moment(maxDate);
          if (dateInput.isValid()) {
            if (minDate !== null && maxDate !== null && dateInput.isSameOrBefore(max) && dateInput.isSameOrAfter(min)) {
              dateRef.current = dateInput.toDate();
              setSelectedDate(dateInput.toDate());
            } else if (minDate === null && maxDate !== null && dateInput.isSameOrBefore(max)) {
              dateRef.current = dateInput.toDate();
              setSelectedDate(dateInput.toDate());
            } else if (minDate !== null && maxDate === null && dateInput.isSameOrAfter(min)) {
              dateRef.current = dateInput.toDate();
              setSelectedDate(dateInput.toDate());
            } else {
              dateRef.current = '';
              setSelectedDate('');
            }
          } else {
            dateRef.current = '';
            setSelectedDate('');
          }
        } else {
          setSelectedDate('');
        }
        onCallBack(onChange);
        setDatePickerKey(moment.now());
        dateInputTextRef.current = '';
      }
    },
    [selectedDate, dateRef.current, dateInputTextRef.current]
  );

  const onDateClose = React.useCallback(
    dates => {
      if (!isEmpty(dates) && isEmpty(dateInputTextRef.current)) {
        const [firstDate] = dates;
        setSelectedDate(firstDate);
        dateRef.current = firstDate;
        onCallBack(onClose);
        setDatePickerKey(moment.now());
        dateInputTextRef.current = '';
      } else {
        dateRef.current = '';
        if (!isEmpty(dateInputTextRef.current) && dateValidation(dateInputTextRef.current)) {
          const dateText = dateInputTextRef.current.replace(/\./g, '/').replace(/-/g, '/');
          const dateInput = moment(dateText);
          const min = moment.isMoment(minDate) ? minDate : moment(minDate);
          const max = moment.isMoment(maxDate) ? maxDate : moment(maxDate);
          if (dateInput.isValid()) {
            // dateRef.current = dateInput.toDate();
            // setSelectedDate(dateInput.toDate());
            if (minDate !== null && maxDate !== null && dateInput.isSameOrBefore(max) && dateInput.isSameOrAfter(min)) {
              dateRef.current = dateInput.toDate();
              setSelectedDate(dateInput.toDate());
            } else if (minDate === null && maxDate !== null && dateInput.isSameOrBefore(max)) {
              dateRef.current = dateInput.toDate();
              setSelectedDate(dateInput.toDate());
            } else if (minDate !== null && maxDate === null && dateInput.isSameOrAfter(min)) {
              dateRef.current = dateInput.toDate();
              setSelectedDate(dateInput.toDate());
            } else {
              dateRef.current = '';
              setSelectedDate('');
            }
          } else {
            dateRef.current = '';
            setSelectedDate('');
          }
        } else {
          setSelectedDate('');
        }
        onCallBack(onClose);
        setDatePickerKey(moment.now());
        dateInputTextRef.current = '';
      }
    },
    [selectedDate, dateInputTextRef.current, dateRef.current, setSelectedDate, onCallBack, setDatePickerKey]
  );

  const onTimeInputChange = React.useCallback(e => {
    setDatePickerKey(moment.now());
    if (!isEmpty(e.target)) {
      const { value } = e.target;
      if (!isEmpty(value)) {
        const isValid = timeAMPM(value);
        if (isValid) {
          const times = split(value, ':');
          timeRef.current = {
            ...timeRef.current,
            value,
            second: 1,
            hour: parseInt(times[0], 10),
            minute: parseInt(times[1], 10),
            touched: true
          };
        } else {
          timeRef.current = {
            ...timeRef.current,
            value,
            hour: 0,
            minute: 0,
            second: 1,
            touched: true
          };
        }
      } else {
        timeRef.current = {
          ...timeRef.current,
          second: 1,
          value,
          touched: true
        };
      }
    }
  });

  const onAMPMTimeChange = React.useCallback(e => {
    if (!isEmpty(e.currentTarget) && !isEmpty(e.currentTarget.value)) {
      timeRef.current = {
        ...timeRef.current,
        type: e.currentTarget.value,
        second: 1
      };
    }
    onCallBack(onAMPMChange);
  });

  const onTimeInputBlur = React.useCallback(() => {
    timeRef.current.touched = true;
    onCallBack(onTimeBlur);
  });

  const onDateInputChange = e => {
    if (!isEmpty(e.nativeEvent.target.value)) {
      dateInputTextRef.current = e.nativeEvent.target.value;
    }
  };

  return (
    <StyledContainer id={id} isCompact={isCompact}>
      <StyledDatePickerContainer>
        <DatePicker
          {...props}
          id={`${id}-date-picker`}
          dateFormat='m/d/Y'
          datePickerType={type}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disableDate}
          value={isEmpty(selectedDate) ? '' : selectedDate.format('MM/DD/YYYY')}
          onChange={onDateChange}
          onClose={onDateClose}
          onBlur={onDateBlur}
          date={selectedDate}
          key={datePickerKey}
          // allowInput={allowInput}
          light={light}
        >
          <DatePickerInput
            {...props}
            disabled={disableDate}
            placeholder={placeholder}
            labelText={labelDateText}
            id={`${id}-date-picker-single`}
            invalidText={invalidDateText}
            invalid={dateError || invalidDate}
            onChange={onDateInputChange}
            datePickerType={type}
          />
        </DatePicker>
      </StyledDatePickerContainer>
      {!showTime ? (
        <></>
      ) : (
        <StyledTimeContainer id={`${id}-time-picker`}>
          <StyledTimePickerContainer
            {...props}
            id={`${id}-time-picker-1`}
            labelText={labelTimeText}
            invalidText={invalidTimeText}
            invalid={timeError || invalidTime}
            onChange={onTimeInputChange}
            onBlur={onTimeInputBlur}
            value={timeRef.current.value}
            disabled={disableTime}
          >
            {time24hr ? (
              <></>
            ) : (
              <StyledTimePickerSelectContainer
                {...props}
                id={`${id}-time-picker-select-1`}
                onChange={onAMPMTimeChange}
                labelText={labelTimeText}
                value={timeRef.current.type}
                disabled={disableTime}
              >
                <SelectItem value={DATE_PICKER_STRINGS.AM} text={DATE_PICKER_STRINGS.AM} />
                <SelectItem value={DATE_PICKER_STRINGS.PM} text={DATE_PICKER_STRINGS.PM} />
              </StyledTimePickerSelectContainer>
            )}
          </StyledTimePickerContainer>
        </StyledTimeContainer>
      )}
    </StyledContainer>
  );
};
