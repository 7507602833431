import type { PageResponseDTO } from '../shared/model';

export enum ResourceType {
  EVENT = 'EVENT',
  SEASON = 'SEASON'
}

export enum RateStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}

export interface RateDTO {
  id: string;
  label: string;
  cashless: boolean;
  minPrice: number;
  maxPrice: number;
  postSeason: boolean;
  productTypeIds: number[];
  rateAmount: number;
  ratePercent: number;
  endDate: string | Date | null;
  startDate: string | Date | null;
  exceptions: string[];
  status: RateStatus;
  huddleIds: string[];
  states: string[];
  distributionChannels: ('GoFan' | 'BoxOffice')[];
  generalAdmission: boolean;
  reservedSeating: boolean;
  resourceTypes: ResourceType[];
  embedded: any[];
}

export interface RatesPageResponseDTO extends PageResponseDTO {
  content: RateDTO[];
}

export interface AccountRateSearchParams {
  productTypeIds: number | string;
  pageIndex: number;
  cashless: any;
  pageSize: number;
  exceptions: any[];
  sortBy: any[];
  keyword: string;
  expands: string[];
  status?: string[];
}
