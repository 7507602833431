import { oc } from 'ts-optchain';
import { Store, StoreValue } from 'rc-field-form/es/interface';

export type Normalize = (
  value: StoreValue,
  prevValue?: StoreValue,
  allValues?: Store
) => StoreValue;

export type NormalizeConifg = {
  normalize?: Normalize;
  initNormalize?: Normalize;
  getValueFromEvent ?: (target: any) => any;
};

export type NormalizeControls = {
  [k: string]: NormalizeConifg;
};

export const normalizePhoneNumber = (phoneNumber: string): string => {
  const phone = `${phoneNumber}`.replace(/\D/g, '');
  return phone
    ? [phone.slice(0, 3), phone.slice(3, 6), phone.slice(6, 11)].join('.')
    : '';
};

export const defaultNormalize = (value: StoreValue) => value;

export const NORMALIZE_CONTROLS: NormalizeControls = {
  Dropdown: {
    initNormalize: defaultNormalize,
    normalize: ({ selectedItem }) => selectedItem
  },
  PhoneInput: {
    normalize: normalizePhoneNumber
  },
  MultiSelect: {
    initNormalize: defaultNormalize,
    normalize: ({ selectedItems }) => selectedItems
  },
  MultiSelectWithAppOpt: {
    initNormalize: defaultNormalize,
    normalize: ({ selectedItems }) => selectedItems
  },
  PhotoUpload: {
    normalize: defaultNormalize,
    getValueFromEvent: ({ target }) => oc(target).files([])[0]
  },
  DatePicker: {
    normalize: defaultNormalize,
    getValueFromEvent: date => oc(date)()[0]
  }
};

export const getNormalize = (fieldType: string, config?: NormalizeConifg): NormalizeConifg => {
  const normalizeControl = NORMALIZE_CONTROLS[fieldType] || {};
  const { normalize, initNormalize, getValueFromEvent } = config || {};
  return {
    normalize: normalize || oc(normalizeControl).normalize(),
    initNormalize: initNormalize || oc(normalizeControl).initNormalize(),
    getValueFromEvent: getValueFromEvent || oc(normalizeControl).getValueFromEvent()
  };
};
