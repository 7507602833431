export enum WorkbookType {
  EVENTS_EDITING = 'EVENTS_EDITING',
  EVENTS_CREATION = 'EVENTS_CREATION',
  TOURNAMENTS = 'TOURNAMENTS',
  SEASON_RENEWAL = 'SEASON_RENEWAL',
  SEASON_MULTIPLE_SPORTS = 'SEASON_MULTIPLE_SPORTS',
  SEASON_ONE_PER_SPORT = 'SEASON_ONE_PER_SPORT',
  SEASON_SEPARATE_EACH_TEAM = 'SEASON_SEPARATE_EACH_TEAM'
}

export const UPLOAD_SEASON_FLOW = {
  MULTI_SPORTS: 'MultipleSports',
  ONE_PER_SPORT: 'OneSeasonPerSport',
  SEPARATE_EACH_TEAM: 'SeparateSeasons'
};
