import { get, isEqual, omit } from 'lodash';
import { canEdit } from '@gofan/api/users';
import { isEmpty } from '../../utils/objectUtils';
import { getAccountsInEvent, getRemovedAccountsInEvent } from '../../api/services/EventService';
import { DISTRIBUTION_CHANNEL, COMPLIMENTARY_TICKET, COMPLIMENTARY_PASS } from '../../api/model/request/ProductRequest';
import { EVENT_TYPES, ORIGINAL_ACCOUNT, DRAFT_ORIGINAL_ACCOUNT } from '../EventInformationV2/constants';
import { ACCOUNT_PAYMENT_CYCLE } from '@app/modules/accounts/constants/constants';
import { EVENT_PROCESS_STATUS } from '@pages/EventDetail/constants';
import { BULK_EDIT_EVENT_TYPES } from '@app/modules/bulk-edit-events/constants';
import { PRODUCT_TYPE } from '@gofan/constants';

export const generateSuccessMessage = (updatedFields = []) => {
  const title = 'Success!';
  let subtitle = 'Your event is successfully updated';
  if (!isEmpty(updatedFields)) {
    const unigUpdatedFields = new Set([...updatedFields]);
    const plural = unigUpdatedFields.size > 1 || unigUpdatedFields.has('schools') ? 'are' : 'is';
    subtitle = `${[...unigUpdatedFields].join(', ')} ${plural} successfully updated`;
    const upperFirstChar = `${subtitle.charAt(0)}`.toUpperCase();
    subtitle = `${upperFirstChar}${subtitle.slice(1)}`;
  }
  return {
    title,
    subtitle,
    isSuccess: true
  };
};

export const generateErrorMessage = error => {
  const title = 'Error!';
  if (!error) {
    return null;
  }

  return {
    title,
    subtitle: `${error}`,
    isSuccess: false
  };
};

export const checkDirtyForTicketType = ({ event = {}, formState = {}, internalFormState = {} }) => {
  const eventTicketDistribution = get(event, 'ticketDistribution', false);
  const ticketDistribution = get(internalFormState, 'values.ticketDistribution', false);

  if (eventTicketDistribution !== ticketDistribution) return true;
  if (formState.dirty) {
    const maxCapacity = get(formState, 'values.maxCapacity');
    const defaultMaxCapacity = get(formState, 'defaultValues.maxCapacity');

    // Beware of the case
    // defaultMaxCapacity = undefined and maxCapacity = null
    if (
      defaultMaxCapacity !== maxCapacity &&
      (![null, undefined].includes(maxCapacity) || ![null, undefined].includes(defaultMaxCapacity))
    )
      return true;

    const { accountId: originalId } = ORIGINAL_ACCOUNT;
    const { accountId: draftOriginalId } = DRAFT_ORIGINAL_ACCOUNT;
    const accountsTicket = get(formState, 'values.accountsTicket', []);
    const defaultAccountsTicket = get(formState, 'defaultValues.accountsTicket', []);

    if (ticketDistribution) {
      const accList = accountsTicket.filter(
        item => `${item.accountId}` !== originalId && `${item.accountId}` !== `${draftOriginalId}`
      );
      const defaultAccList = defaultAccountsTicket.filter(
        item => `${item.accountId}` !== originalId && `${item.accountId}` !== `${draftOriginalId}`
      );
      if (isEqual(accList, defaultAccList)) return false;
    } else {
      const original = accountsTicket.filter(item => `${item.accountId}` === originalId);
      const defaultOriginal = defaultAccountsTicket.filter(item => `${item.accountId}` === originalId);
      if (isEqual(original, defaultOriginal)) return false;
    }
  }
  return formState.dirty;
};

export const mapTicketTypeData = ({
  event = {},
  nextUpdatedEvent = {},
  ticketFormState = {},
  generatedTickets = [],
  currentProcess = -1
}) => {
  // Only apply for edit event in ticket distribution mode
  const isHavingTicketsFromOptionalStep = !isEmpty(generatedTickets);
  const isOverrideHiddenFees =
    nextUpdatedEvent?.financialAccount?.paymentCycle === ACCOUNT_PAYMENT_CYCLE.DAILY &&
    event?.financialAccount?.paymentCycle !== ACCOUNT_PAYMENT_CYCLE.DAILY;
  const ticketDistribution = isEmpty(ticketFormState.ticketFormState)
    ? event.ticketDistribution
    : get(ticketFormState, 'internalFormState.values.ticketDistribution', false);
  if (
    !isHavingTicketsFromOptionalStep &&
    !isOverrideHiddenFees &&
    (!ticketDistribution || isEmpty(nextUpdatedEvent) || isEmpty(event.accountsTicket))
  ) {
    return ticketFormState?.formValues;
  }

  const accountsInEvent = getAccountsInEvent(event);
  const removedAccountIds = getRemovedAccountsInEvent({
    accountsInEvent,
    nextUpdatedEvent
  });

  if (!isOverrideHiddenFees && isEmpty(removedAccountIds) && !isHavingTicketsFromOptionalStep) {
    return ticketFormState?.formValues;
  }

  let products = [];
  if (isEmpty(ticketFormState.formValues)) {
    // [CASE: [ONLY_EDIT_IN_REQUIRED_SCREEN]]
    products = (isHavingTicketsFromOptionalStep ? generatedTickets : event.products).reduce((res, product) => {
      const found = event?.products?.find(item => `${item.id}` === `${product.id}`);
      const newProduct = found ? { ...found, ...product } : { ...product };
      if (product.distributionChannel === DISTRIBUTION_CHANNEL.GOFAN) {
        const newTicket = {
          ...omit(newProduct, 'accountProduct'),
          created: isHavingTicketsFromOptionalStep ? newProduct.created : true,
          enabled: removedAccountIds.indexOf(`${product.accountId}`) !== -1 ? false : product.enabled
        };
        res.push(
          isHavingTicketsFromOptionalStep
            ? {
                ...newTicket,
                enabledTicketBoxOffice:
                  newProduct?.enabledTicketBoxOffice === true ? newProduct?.enabledTicketBoxOffice : false
              }
            : { ...newTicket }
        );
      } else {
        res.push({
          ...omit(newProduct, 'accountProduct'),
          created: isHavingTicketsFromOptionalStep ? newProduct.created : true
        });
      }
      return res;
    }, []);
  } else {
    // [CASE: [EDIT_IN_REQUIRED_SCREEN][HAS_TICKET_FORM]]
    const { formControl } = ticketFormState;
    const { products: updatedProducts = [] } = ticketFormState.formValues;
    const productTypes = currentProcess === EVENT_PROCESS_STATUS.TICKETS_PROCESS ? updatedProducts : generatedTickets;

    products = productTypes?.reduce((res, product) => {
      const found = event?.products?.find(item => `${item.id}` === `${product.id}`);
      const newProduct = found ? { ...found, ...product } : { ...product };
      let isEnabled = product.enabled;

      if (product.enabled && product.created && removedAccountIds.indexOf(`${product.accountId}`) !== -1) {
        const accountProducts = formControl.get(`accountsTicket.value.arr_${product.accountId}.products`);

        if (!isEmpty(accountProducts) && !isEmpty(accountProducts.value)) {
          accountProducts.value.forEach(item => {
            if (
              `${item.id.value}` === `${product.id}` &&
              item.distributionChannel.value === DISTRIBUTION_CHANNEL.GOFAN &&
              item.created.value &&
              !item.enabled.touched
            ) {
              isEnabled = false;
            }
          });
        }
      }

      let enabledTicketBoxOffice = newProduct?.enabledTicketBoxOffice;
      if (
        currentProcess !== EVENT_PROCESS_STATUS.TICKETS_PROCESS &&
        !newProduct?.created &&
        isEmpty(newProduct?.accountProduct) &&
        enabledTicketBoxOffice !== true
      ) {
        enabledTicketBoxOffice = false;
      }

      res.push({
        ...omit(newProduct, 'accountProduct'),
        enabled: isEnabled,
        enabledTicketBoxOffice
      });
      return res;
    }, []);
  }

  if (isOverrideHiddenFees) {
    return {
      ...ticketFormState.formValues,
      products: isOverrideHiddenFees ? products?.map(product => ({ ...product, hiddenFees: false })) : products,
      accountsTicket: !isEmpty(ticketFormState?.formValues?.accountsTicket)
        ? ticketFormState?.formValues?.accountsTicket
        : event?.accountsTicket,
      ticketDistribution: !isEmpty(ticketFormState?.formValues?.ticketDistribution)
        ? ticketFormState?.formValues?.ticketDistribution
        : event?.ticketDistribution
    };
  }
  return {
    ...ticketFormState?.formValues,
    products
  };
};

export const getBulkEditEventType = event => {
  const { redemptionWindow = null, products = [] } = event;

  const isMobilePass =
    (isEmpty(products) && redemptionWindow !== null) ||
    products.some(ticket => ticket.productType === PRODUCT_TYPE.MOBILEPASS);

  const isFundraiser = products.some(ticket =>
    [PRODUCT_TYPE.DONATION, PRODUCT_TYPE.DONATION_ONGOING].includes(ticket.productType ?? '')
  );

  const isConcession = products.some(ticket => ticket.productType === PRODUCT_TYPE.CONCESSION);

  if (isMobilePass) {
    return BULK_EDIT_EVENT_TYPES.MOBILE_PASS;
  }
  if (isFundraiser) {
    return BULK_EDIT_EVENT_TYPES.FUNDRAISER;
  }
  if (isConcession) {
    return BULK_EDIT_EVENT_TYPES.CONCESSION;
  }

  return BULK_EDIT_EVENT_TYPES.EVENT;
};

export const getEventType = event => {
  const { activity = get(event, '_embedded.activity', {}), redemptionWindow = null, products = [] } = event;
  if (isEmpty(event) || (isEmpty(activity) && redemptionWindow === null)) {
    return EVENT_TYPES.ATHLETIC;
  }
  const isMobilePass =
    (isEmpty(products) && redemptionWindow !== null) || products.some(ticket => ticket.productType === 'MOBILEPASS');

  const isFundraiser = activity?.label?.toLowerCase() === EVENT_TYPES.FUNDRAISER.toLowerCase();
  let eventType = EVENT_TYPES.ATHLETIC;
  if (isMobilePass) {
    eventType = EVENT_TYPES.MOBILE_PASS;
  } else if (EVENT_TYPES.CONFIG[EVENT_TYPES.PERFORMING_ART].ACTIVITIES.includes(activity.label)) {
    eventType = EVENT_TYPES.PERFORMING_ART;
  } else if (EVENT_TYPES.CONFIG[EVENT_TYPES.REGISTRATION].ACTIVITIES.includes(activity.label)) {
    eventType = EVENT_TYPES.REGISTRATION;
  } else if (EVENT_TYPES.CONFIG[EVENT_TYPES.SCHOOL_DANCE].ACTIVITIES.includes(activity.label)) {
    eventType = EVENT_TYPES.SCHOOL_DANCE;
  } else if (isFundraiser) {
    eventType = EVENT_TYPES.FUNDRAISER;
  } else if (!activity.athletic) {
    eventType = EVENT_TYPES.OTHER;
  }
  return eventType;
};

export const getEventTypeItemTable = event => {
  let eventType = EVENT_TYPES.ATHLETIC;
  if (isEmpty(event)) {
    return EVENT_TYPES.ATHLETIC;
  }
  const { redemptionWindow = null, products = [] } = event;
  const isMobilePass =
    (isEmpty(products) && redemptionWindow !== null) || products.some(ticket => ticket.productType === 'MOBILEPASS');

  if (isMobilePass) {
    eventType = EVENT_TYPES.MOBILE_PASS;
  }
  return eventType;
};

const getComplimentaryTickets = event =>
  event.products.filter(
    product => (product.name === COMPLIMENTARY_TICKET || product.name === COMPLIMENTARY_PASS) && product.accountId
  );

export const preserveComplimentaryTickets = (originalEvent, updatedEvent) => {
  const compTickets = getComplimentaryTickets(originalEvent);
  const { products } = updatedEvent;
  compTickets.forEach(compTicket => {
    const foundCompTicketIndex = products.findIndex(product => product.id === compTicket.id);
    if (foundCompTicketIndex >= 0) {
      products.splice(foundCompTicketIndex, 1, compTicket);
    } else {
      products.push(compTicket);
    }
  });
  updatedEvent.products = products;
  return updatedEvent;
};

export const canViewEventHistory = ({ event, currentUser, isEventDetail }) => {
  if (isEmpty(event) || isEmpty(currentUser) || !isEventDetail) return false;
  const { financialAccountId } = event;
  const allowed = canEdit(event.account.id, currentUser) || canEdit(financialAccountId, currentUser);
  return allowed;
};

export default {
  mapTicketTypeData,
  generateSuccessMessage,
  generateErrorMessage,
  getEventType,
  preserveComplimentaryTickets,
  canViewEventHistory
};
