import { Checkbox, CheckboxGroup, ClickAwayListener } from '..';
import './VenueFilter.scss';

export type VenueTypes = {
  district?: boolean;
  homeSchool?: boolean;
};

interface VenueFilterProps {
  venueTypes: VenueTypes;
  onClose: () => void;
  onChange: (venueTypes: VenueTypes) => void;
}

export const VenueFilter = ({ venueTypes, onClose, onChange }: VenueFilterProps) => (
  <ClickAwayListener clickHandler={onClose}>
    <div className='gf--venue-filter'>
      <div className='title'>Filter By</div>
      <CheckboxGroup>
        <Checkbox
          labelText='District venues'
          name='District venues'
          id='district'
          checked={venueTypes.district}
          onChange={(_, { checked }) => {
            onChange({ ...venueTypes, district: checked });
          }}
        />
        <Checkbox
          labelText='Home School venues'
          name='Home School venues'
          id='homeSchool'
          checked={venueTypes.homeSchool}
          onChange={(_, { checked }) => {
            onChange({ ...venueTypes, homeSchool: checked });
          }}
        />
      </CheckboxGroup>
    </div>
  </ClickAwayListener>
);
