import type { Activity, Account } from '../Activities/types';
import type { Level, Event, Venue } from '../../api/types';
import type { EventInformationV2ActionTypes } from './actionTypes';
import {
  RESET_STATE,
  FETCH_ACTIVITIES,
  FETCH_ACTIVITIES_SUCCESSFUL,
  FETCH_ACTIVITIES_COMPLETED,
  SEARCH_OPPONENT_ACCOUNTS,
  SEARCH_OPPONENT_ACCOUNTS_SUCCESS,
  FETCH_LEVELS,
  FETCH_LEVELS_COMPLETED,
  FETCH_LEVELS_SUCCESSFUL,
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_COMPLETED,
  FETCH_ACCOUNT_SUCCESSFUL,
  ON_PUBLISH_EVENT,
  ON_PUBLISH_EVENT_SUCCESS,
  ON_PUBLISH_EVENT_ERROR,
  UPDATE_REQUIRED_FIELDS,
  UPDATE_OPTIONAL_FIELDS,
  UPDATE_TICKETS_FIELDS,
  FETCH_EVENT_BY_ID,
  FETCH_EVENT_BY_ID_COMPLETED,
  FETCH_EVENT_BY_ID_SUCCESSFULL,
  SEARCH_ACCOUNT_TICKETS,
  SEARCH_ACCOUNT_TICKETS_COMPLETED,
  FETCH_VENUE_BY_ID_COMPLETED,
  FETCH_VENUE_BY_ID,
  FETCH_VENUE_BY_ID_SUCCESSFULL,
  EXECUTE_INITIAL_EVENT_PROCESS,
  EXECUTE_INITIAL_EVENT_PROCESS_COMPLETED
} from './actionTypes';

import type { SearchAccountTicketsParams, SearchAccountsTicketsResult } from './types';

export function resetState(): EventInformationV2ActionTypes {
  return {
    type: RESET_STATE
  };
}
// TypeScript infers that this function is returning FetchActivitiesAction
export function fetchActivities(): EventInformationV2ActionTypes {
  return {
    type: FETCH_ACTIVITIES
  };
}

// TypeScript infers that this function is returning FetchActivitiesAction
export function fetchActivitiesCompleted(): EventInformationV2ActionTypes {
  return {
    type: FETCH_ACTIVITIES_COMPLETED
  };
}

// TypeScript infers that this function is returning FetchActivitiesSuccessfulAction
export function fetchActivitiesSuccessful(
  athleticActivities: Activity[],
  nonAthleticActivities: Activity[]
): EventInformationV2ActionTypes {
  return {
    type: FETCH_ACTIVITIES_SUCCESSFUL,
    payload: {
      athleticActivities,
      nonAthleticActivities
    }
  };
}

export const searchOpponentAccounts = (keyword: string): EventInformationV2ActionTypes => ({
  type: SEARCH_OPPONENT_ACCOUNTS,
  payload: {
    keyword
  }
});

export const searchOpponentAccountsSuccess = (accounts: Array<Account>): EventInformationV2ActionTypes => ({
  type: SEARCH_OPPONENT_ACCOUNTS_SUCCESS,
  payload: {
    accounts
  }
});

export function fetchLevelsCompleted(): EventInformationV2ActionTypes {
  return {
    type: FETCH_LEVELS_COMPLETED
  };
}

export function fetchLevels(): EventInformationV2ActionTypes {
  return {
    type: FETCH_LEVELS
  };
}

// TypeScript infers that this function is returning FetchLevelsSuccessfulAction
export function fetchLevelsSuccessful(levels: Level[]): EventInformationV2ActionTypes {
  return {
    type: FETCH_LEVELS_SUCCESSFUL,
    payload: {
      levels
    }
  };
}

export function fetchAccountCompleted(): EventInformationV2ActionTypes {
  return {
    type: FETCH_ACCOUNT_COMPLETED
  };
}

export function fetchAccount(accountId: string): EventInformationV2ActionTypes {
  return {
    type: FETCH_ACCOUNT,
    payload: {
      accountId
    }
  };
}

// TypeScript infers that this function is returning FetchLevelsSuccessfulAction
export function fetchAccountSuccessful(account: Account): EventInformationV2ActionTypes {
  return {
    type: FETCH_ACCOUNT_SUCCESSFUL,
    payload: {
      account
    }
  };
}

export function updateRequiredFields(requiredFields: any): EventInformationV2ActionTypes {
  return {
    type: UPDATE_REQUIRED_FIELDS,
    payload: {
      requiredFields
    }
  };
}

export function updateOptionalFields(optionalFields: any): EventInformationV2ActionTypes {
  return {
    type: UPDATE_OPTIONAL_FIELDS,
    payload: {
      optionalFields
    }
  };
}

export function updateTicketsFields(
  ticketTypes: any,
  maxCapacity: number | boolean,
  totalTicketCheck: boolean
): EventInformationV2ActionTypes {
  return {
    type: UPDATE_TICKETS_FIELDS,
    payload: {
      maxCapacity,
      ticketTypes,
      totalTicketCheck
    }
  };
}

export function fetchEventById(eventId: string): EventInformationV2ActionTypes {
  return {
    type: FETCH_EVENT_BY_ID,
    payload: {
      eventId
    }
  };
}

export function fetchEventByIdSuccessfull(event: Event): EventInformationV2ActionTypes {
  return {
    type: FETCH_EVENT_BY_ID_SUCCESSFULL,
    payload: {
      event
    }
  };
}

// TypeScript infers that this function is returning FetchLevelsSuccessfulAction
export function fetchEventByIdCompleted(): EventInformationV2ActionTypes {
  return {
    type: FETCH_EVENT_BY_ID_COMPLETED
  };
}

export const searchAccountTickets = (searchParams: SearchAccountTicketsParams): EventInformationV2ActionTypes => ({
  type: SEARCH_ACCOUNT_TICKETS,
  payload: {
    searchParams
  }
});

export const searchAccountTicketsCompleted = (
  searchResult: SearchAccountsTicketsResult
): EventInformationV2ActionTypes => ({
  type: SEARCH_ACCOUNT_TICKETS_COMPLETED,
  payload: {
    searchResult
  }
});

export function fetchVenueById(venueId: string): EventInformationV2ActionTypes {
  return {
    type: FETCH_VENUE_BY_ID,
    payload: {
      venueId
    }
  };
}

export function fetchVenueByIdSuccessfull(venue: Venue): EventInformationV2ActionTypes {
  return {
    type: FETCH_VENUE_BY_ID_SUCCESSFULL,
    payload: {
      venue
    }
  };
}

// TypeScript infers that this function is returning FetchLevelsSuccessfulAction
export function fetchVenueByIdCompleted(): EventInformationV2ActionTypes {
  return {
    type: FETCH_VENUE_BY_ID_COMPLETED
  };
}

export const onPublishEvent = ({
  history = {},
  event = {},
  activityType = '',
  currentUser = {},
  requiredData = {},
  optionalData = {},
  ticketTypeData = {},
  streamingData = {},
  onCompleteFunction = () => {}
}): EventInformationV2ActionTypes => ({
  type: ON_PUBLISH_EVENT,
  payload: {
    event,
    activityType,
    history,
    currentUser,
    requiredData,
    optionalData,
    ticketTypeData,
    streamingData,
    onCompleteFunction
  }
});

export const onPublishEventSuccess = (): EventInformationV2ActionTypes => ({
  type: ON_PUBLISH_EVENT_SUCCESS
});

export const onPublishEventError = (error: any, response: any): EventInformationV2ActionTypes => ({
  type: ON_PUBLISH_EVENT_ERROR,
  error,
  response
});

export const executeInitialEventProcess = (history: Object, accountId: string): EventInformationV2ActionTypes => ({
  type: EXECUTE_INITIAL_EVENT_PROCESS,
  payload: {
    history,
    accountId
  }
});

export const executeInitialEventProcessCompleted = (): EventInformationV2ActionTypes => ({
  type: EXECUTE_INITIAL_EVENT_PROCESS_COMPLETED
});
