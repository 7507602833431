import { Controller, useFormContext } from 'react-hook-form';
import { Row, Column, NumberInput } from 'carbon-components-react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isEmpty from 'lodash/isEmpty';
import cs from 'classnames';
import { Toggle } from '@gofan/components';

import { DatePicker } from '@old-components/date-picker';
import { TimePicker } from '@old-components/time-picker';
import StyledNumberFormatInput from '@common/carbon-ui/atoms/StyledNumberFormatInput';

import { DATE_PICKER_FORMAT, getTimeZone } from '@utils/dateUtils';
import { parseNumber } from '@utils/parseUtils';
import { preventNumberInputKeyDown } from '@season-management/utils/season-creation-yup.utils';

import { STRINGS } from '@season-management/components/SeasonTicketModal/constants';

import type { SeasonTicketData } from '@season-management/components/SeasonTicketModal';
import { SeasonTicketTypeEnum } from '@season-management/components/SeasonTicketModal';

import './OptionTicketSetting.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

type OptionTicketSettingProps = {
  type: SeasonTicketTypeEnum;
  data: SeasonTicketData;
  isAdminRole: boolean;
};

const OptionTicketSetting = ({ type, data, isAdminRole }: OptionTicketSettingProps) => {
  const endDateTime = data?.endDateTime;
  const timeZone = getTimeZone(data?.account?.timeZone);
  const { control, watch, trigger, setValue } = useFormContext();
  const mMinDateTime = dayjs(new Date());
  const minDate = mMinDateTime.clone().format(DATE_PICKER_FORMAT);
  const mEndDateTime = dayjs(endDateTime).tz(timeZone);
  const maxDate = endDateTime ? mEndDateTime.clone().format(DATE_PICKER_FORMAT) : undefined;
  const disabledByHasSold = data?.config?.hasSold?.(watch('id'));
  const id = watch('id');
  const salesStartDate = watch('salesStartDate');
  const togglePackCount = watch('enabledPackCount');

  return (
    <Row className='gf-option-ticket-setting '>
      <Column>
        <Row>
          <Column>
            <div className={cs('gs--productive-heading-02', 'gs--text-01')}>
              {STRINGS.OPTIONAL_SEASON_TICKET_SETTINGS.TITLE[type]}
            </div>
            <div className={cs('gs--body-short-01', 'gs--text-01', 'gs--padding-top-sp3')}>
              {STRINGS.OPTIONAL_SEASON_TICKET_SETTINGS.SUBTITLE}
            </div>
          </Column>
        </Row>
        <Row className='gs--padding-top__sp5'>
          <Column lg={6}>
            <Row>
              <Controller
                name='salesStartDate'
                key={`sale-start-date-key-${id}`}
                control={control}
                render={({ field, fieldState }) => (
                  <Column lg={8}>
                    <DatePicker
                      id='sale-start-date'
                      ref={field.ref}
                      labelText={STRINGS.SALE_START_DATE}
                      minDate={minDate}
                      maxDate={maxDate}
                      invalid={!!fieldState.error}
                      invalidText={fieldState.error?.message}
                      value={field.value}
                      onChange={date => {
                        field.onChange(date);
                      }}
                      onBlur={() => {
                        if (
                          !dayjs(field.value).isValid() ||
                          dayjs(field.value).isBefore(minDate) ||
                          (maxDate && dayjs(field.value).isAfter(maxDate))
                        ) {
                          field.onChange('');
                        }
                        trigger(['salesStartTime', 'salesEndDate', 'salesEndTime']);
                        field.onBlur();
                      }}
                    />
                  </Column>
                )}
              />
              <Controller
                name='salesStartTime'
                key={`sales-start-time-key-${id}`}
                control={control}
                render={({ field, fieldState }) => (
                  <Column lg={4}>
                    <TimePicker
                      id='sales-start-time'
                      ref={field.ref}
                      labelText={STRINGS.SALE_START_TIME}
                      value={field.value}
                      invalid={!!fieldState.error}
                      invalidText={fieldState.error?.message}
                      onChange={time => field.onChange(time)}
                      onBlur={() => {
                        trigger(['salesStartDate', 'salesEndDate', 'salesEndTime']);
                        field.onBlur();
                      }}
                    />
                  </Column>
                )}
              />
            </Row>
          </Column>
        </Row>
        <Row className='gs--padding-top__sp5'>
          <Column lg={6}>
            <Row>
              <Controller
                name='salesEndDate'
                key={`sale-end-date-key-${id}`}
                control={control}
                render={({ field, fieldState }) => {
                  const minSalesEndDate = isEmpty(salesStartDate) ? minDate : salesStartDate;
                  return (
                    <Column lg={8}>
                      <DatePicker
                        id='sale-end-date'
                        ref={field.ref}
                        labelText={STRINGS.SALE_END_DATE}
                        minDate={minSalesEndDate}
                        maxDate={maxDate}
                        invalid={!!fieldState.error}
                        invalidText={fieldState.error?.message}
                        value={field.value}
                        onChange={date => field.onChange(date)}
                        onBlur={() => {
                          if (
                            !dayjs(field.value).isValid() ||
                            dayjs(field.value).isBefore(minSalesEndDate) ||
                            (maxDate && dayjs(field.value).isAfter(maxDate))
                          ) {
                            field.onChange('');
                          }
                          trigger(['salesStartDate', 'salesStartTime', 'salesEndTime']);
                          field.onBlur();
                        }}
                      />
                    </Column>
                  );
                }}
              />

              <Controller
                name='salesEndTime'
                key={`sales-end-time-key-${id}`}
                control={control}
                render={({ field, fieldState }) => (
                  <Column lg={4}>
                    <TimePicker
                      id='sales-end-time'
                      ref={field.ref}
                      labelText={STRINGS.SALE_END_TIME}
                      value={field.value}
                      invalid={!!fieldState.error}
                      invalidText={fieldState.error?.message}
                      onChange={time => field.onChange(time)}
                      onBlur={() => {
                        trigger(['salesStartDate', 'salesStartTime', 'salesEndDate']);
                        field.onBlur();
                      }}
                    />
                  </Column>
                )}
              />
            </Row>
          </Column>
        </Row>
        {isAdminRole && (
          <>
            <Row className='gs--padding-top-sp7'>
              <Column className={cs('gs--productive-heading-02', 'gs--text-01')}>{STRINGS.SET_THE_TICKET_FEE}</Column>
            </Row>
            <Row className='gs--padding-top__sp5'>
              <Column lg={3}>
                <Controller
                  name='fee'
                  control={control}
                  key={`ticket-fee-key-${id}`}
                  render={({ field, fieldState }) => (
                    <StyledNumberFormatInput
                      id='ticket-fee'
                      disabled={disabledByHasSold}
                      getInputRef={field.ref}
                      placeholder='$0.00'
                      labelText={STRINGS.TICKET_FEE}
                      light={false}
                      value={field.value}
                      onChange={({ value }: any) => {
                        field.onChange(parseNumber(value, null));
                        if (type === SeasonTicketTypeEnum.TICKET_TYPE) {
                          setValue('boxOfficeTicket.fee', parseNumber(value, null));
                        }
                      }}
                      onBlur={() => field.onBlur()}
                      invalid={!!fieldState.error}
                      invalidText={fieldState.error?.message}
                    />
                  )}
                />
              </Column>
            </Row>
          </>
        )}

        <Row className='gs--padding-top-sp7'>
          <Column className={cs('gs--productive-heading-02', 'gs--text-01')}>
            {STRINGS.SET_THE_MAXIMUM_NUMBER_TICKETS}
          </Column>
        </Row>
        <Row className='gs--padding-top__sp5'>
          <Column lg={3}>
            <Controller
              name='ticketLimitPerOrder'
              key={`ticket-limit-per-order-key-${id}`}
              control={control}
              render={({ field, fieldState }) => (
                <NumberInput
                  ref={field.ref}
                  id='ticket-limit-per-order'
                  label={STRINGS.TICKET_LIMIT_PER_ORDER}
                  allowEmpty
                  value={field.value}
                  invalid={!!fieldState.error}
                  invalidText={fieldState.error?.message}
                  onKeyDown={preventNumberInputKeyDown}
                  light={false}
                  onChange={(evt: any) => {
                    field.onChange(parseNumber(evt?.imaginaryTarget?.value, null));
                    field.onBlur();
                  }}
                  onBlur={() => field.onBlur()}
                />
              )}
            />
          </Column>
        </Row>
        {type === SeasonTicketTypeEnum.TICKET_TYPE && (
          <>
            <Row className='gs--padding-top-sp7'>
              <Column className={cs('gs--productive-heading-02', 'gs--text-01')}>
                {STRINGS.TICKET_PACK_COUNT_CAPTION}
              </Column>
            </Row>
            <Row className='gs--padding-top__sp5'>
              <Column lg={3}>
                <Controller
                  name='enabledPackCount'
                  key={`toggle-enabledPackCount-${id}`}
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      id='ticket-enabledPackCount'
                      toggled={field.value}
                      disabled={disabledByHasSold}
                      labelText={STRINGS.TICKET_PACK_COUNT_TOGGLE}
                      labelA='Off'
                      labelB='On'
                      onToggle={(toggled: any) => {
                        field.onChange(toggled);
                        trigger(['packCount']);
                      }}
                    />
                  )}
                />
              </Column>
            </Row>
            <Row>
              <Column className='gs--padding-top__sp3'>
                <Controller
                  name='packCount'
                  key={`number-packCount-${id}`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <NumberInput
                      ref={field.ref}
                      id='ticket-pack-count'
                      label={STRINGS.TICKET_PACK_COUNT_LABEL}
                      allowEmpty
                      value={field.value}
                      invalid={!!fieldState.error}
                      invalidText={fieldState.error?.message}
                      onKeyDown={preventNumberInputKeyDown}
                      light={false}
                      onChange={(evt: any) => {
                        field.onChange(parseNumber(evt?.imaginaryTarget?.value, null));
                        field.onBlur();
                      }}
                      onBlur={() => field.onBlur()}
                      disabled={!togglePackCount || disabledByHasSold}
                    />
                  )}
                />
              </Column>
            </Row>
          </>
        )}
      </Column>
    </Row>
  );
};

export { OptionTicketSetting };
