import { formatCurrency, formatNumber } from '@gofan/utils';
import type { VFC } from 'react';

interface TicketSaleNumericalChartProps {
  totalTicketsSold: number;
  totalTicketsSale: number;
  isTextAbove?: boolean;
  isFundraiser?: boolean;
}

const TicketSaleNumericalChart: VFC<TicketSaleNumericalChartProps> = ({
  totalTicketsSold,
  totalTicketsSale,
  isTextAbove,
  isFundraiser
}: TicketSaleNumericalChartProps) => (
  <div className='ticket-sale-numerical-chart gs--font-family-san'>
    <div className='ticket-sale-numerical-chart__ticket-sold'>
      {isTextAbove && <div>{isFundraiser ? 'Donations' : 'Tickets sold'}</div>}
      <div className='ticket-sale-numerical-chart__number-content'>{formatNumber(totalTicketsSold)}</div>
      {!isTextAbove && <div>Sold</div>}
    </div>
    <div className='ticket-sale-numerical-chart__ticket-sales'>
      {isTextAbove && <div>{isFundraiser ? 'Donation sales' : 'Ticket sales'}</div>}
      <div className='ticket-sale-numerical-chart__number-content'>{`${formatCurrency(totalTicketsSale)}`}</div>
      {!isTextAbove && <div>In sales</div>}
    </div>
  </div>
);

export default TicketSaleNumericalChart;
