import { ZENDESK_SUPPORT_FORM_FIELDS } from '../../api/zendesk/zendesk.constant';

// how does this work?
// zendesk uses custom fields to store additional information on the ticket
// these custom fields use a key value pair [id_number]: value
// The mappings below are used to map the form field names to the correct custom id in zendesk
const PROD_CUSTOM_FIELDS = {
  [ZENDESK_SUPPORT_FORM_FIELDS.GAME_ID]: 360043806871,
  [ZENDESK_SUPPORT_FORM_FIELDS.NEXT_EVENT_DATE]: 360043806891,
  [ZENDESK_SUPPORT_FORM_FIELDS.PREFERRED_CONTACT_METHOD]: 28645410254605,
  [ZENDESK_SUPPORT_FORM_FIELDS.ISSUE_DESCRIPTION]: 20330726,
  [ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_STREAMING]: 360043802792,
  [ZENDESK_SUPPORT_FORM_FIELDS.PIXELLOT_ISSUES]: 360043805431,
  [ZENDESK_SUPPORT_FORM_FIELDS.PIXELLOT_LOCATION]: 360041648332,
  [ZENDESK_SUPPORT_FORM_FIELDS.ENCODING_BROADCAST_SOFTWARE]: 360043806371,
  [ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_TICKETING]: 7181151552781,
  [ZENDESK_SUPPORT_FORM_FIELDS.EVENT_IDS]: 30566139281037,
  [ZENDESK_SUPPORT_FORM_FIELDS.PLAYOFF_EVENT_TICKETING]: 7181045722509,
  otherProductionIssue: 360043806651 // field does not exist on form, additional field maps issues for VOD Issue, Scheduling Issue, and Other
};

const SANDBOX_CUSTOM_FIELDS = {
  [ZENDESK_SUPPORT_FORM_FIELDS.GAME_ID]: 26006469269645,
  [ZENDESK_SUPPORT_FORM_FIELDS.NEXT_EVENT_DATE]: 26006452832013,
  [ZENDESK_SUPPORT_FORM_FIELDS.PREFERRED_CONTACT_METHOD]: 28645372305933,
  [ZENDESK_SUPPORT_FORM_FIELDS.ISSUE_DESCRIPTION]: 26006288237581,
  [ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_STREAMING]: 26006488941965,
  [ZENDESK_SUPPORT_FORM_FIELDS.PIXELLOT_ISSUES]: 26006452335501,
  [ZENDESK_SUPPORT_FORM_FIELDS.PIXELLOT_LOCATION]: 26006457886733,
  [ZENDESK_SUPPORT_FORM_FIELDS.ENCODING_BROADCAST_SOFTWARE]: 26006481085965,
  [ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_TICKETING]: 26006462064141,
  [ZENDESK_SUPPORT_FORM_FIELDS.EVENT_IDS]: 30566243149197,
  [ZENDESK_SUPPORT_FORM_FIELDS.PLAYOFF_EVENT_TICKETING]: 26006491664013,
  otherProductionIssue: 26006489628173 // field does not exist on form, additional field maps issues for VOD Issue, Scheduling Issue, and Other
};

export const ZENDESK_SANDBOX = {
  STREAMING: {
    group_id: 26006401994893,
    ticket_form_id: 26006679762445,
    brand_id: 26006412964749
  },
  TICKETING: {
    group_id: 26006401456269,
    ticket_form_id: 26006685232909,
    brand_id: 26006405591437
  },
  CUSTOM_FIELDS: SANDBOX_CUSTOM_FIELDS
};

export const ZENDESK_PRODUCTION = {
  STREAMING: {
    group_id: 360007666112,
    ticket_form_id: 360001755191,
    brand_id: 299894
  },
  TICKETING: {
    group_id: 7151215702669,
    ticket_form_id: 7181589553805,
    brand_id: 7258772092045
  },
  CUSTOM_FIELDS: PROD_CUSTOM_FIELDS
};
