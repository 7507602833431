import { put, call, take, fork, cancel } from 'redux-saga/effects';
import { channel, SagaIterator } from 'redux-saga';

export function* takeMultitaskingChannel(pattern: any, cancelPattern: any, actionFunc: any, config?: any): SagaIterator<void> {
  const { selectorKey } = config ?? {};
  const mapChannels: any = {};
  const channelMapKey = selectorKey ?? 'channelId';

  // [Watcher][ON_CLOSE_CHANNEL]
  yield fork(function* (): SagaIterator<void> {
    while (true) {
      const { payload: cancelPayload } = yield take(cancelPattern);
      const channelId = cancelPayload?.[channelMapKey];

      if (mapChannels[channelId]) {
        mapChannels[channelId]?.close();
        mapChannels[channelId] = null;
      }
    }
  });

  while (true) {
    const action = yield take(pattern);
    const channelId = action.payload?.[channelMapKey];

    if (!mapChannels[channelId]) {
      mapChannels[channelId] = yield call(channel);

      yield fork(function* (): SagaIterator<void> {
        let isCancelled = false;
  
        const channelAction = yield take(mapChannels[channelId]);
        const bgSyncTask = yield fork(actionFunc, channelAction);

        // [Watcher][ON_CANCEL_TASK]
        while (!isCancelled) {
          const { payload: cancelPayload } = yield take(cancelPattern);
          const cancelledChannelId = cancelPayload?.[channelMapKey];

          if (`${channelId}` === `${cancelledChannelId}`) {
            isCancelled = true;
            yield cancel(bgSyncTask);
          }
        }
      });
    }

    yield put(mapChannels[channelId], action);
  }
};
