const enum TeamSeasonEnum {
  ALL = 'All',
  SPRING = 'Spring',
  SUMMER = 'Summer',
  AUTUMN = 'Autumn',
  FALL = 'Fall',
  WINTER = 'Winter'
}

export const TEAM_SEASONS = [
  { name: TeamSeasonEnum.SPRING, label: TeamSeasonEnum.SPRING },
  { name: TeamSeasonEnum.SUMMER, label: TeamSeasonEnum.SUMMER },
  { name: TeamSeasonEnum.AUTUMN, label: TeamSeasonEnum.FALL },
  { name: TeamSeasonEnum.WINTER, label: TeamSeasonEnum.WINTER }
];

export const TEAM_MESSAGES = {
  CREATE_DUPLICATED: 'The team(s) already exists !',
  CREATE_SUCCESSFUL: 'Team(s) Created!'
};
