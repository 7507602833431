import type { SeasonDTO } from '@seasons/models/season.model';
import type { PurchasedTicketDTO, SeasonRenewalDTO, SeasonRenewalUploadDTO, SeasonRenewalUploadRow } from '@gofan/api';

export const ON_PREPARE_DATA = '@season-renewal/ON_PREPARE_DATA';
export const ON_PREPARE_DATA_COMPLETED = '@season-renewal/ON_PREPARE_DATA_COMPLETED';

export const ON_CREATE_SEASON_RENEWAL_CAMPAIGN = '@season-renewal/ON_CREATE_SEASON_RENEWAL_CAMPAIGN';
export const ON_CREATE_SEASON_RENEWAL_CAMPAIGN_COMPLETED =
  '@season-renewal/ON_CREATE_SEASON_RENEWAL_CAMPAIGN_COMPLETED';

export const ON_STORE_SEASON_RENEWAL_CAMPAIGN = '@season-renewal/ON_STORE_SEASON_RENEWAL_CAMPAIGN';
export const ON_STORE_SEASON_RENEWAL_ATTENDEES = '@season-renewal/ON_STORE_SEASON_RENEWAL_ATTENDEES';

export const ON_HANDLE_ERROR = '@season-renewal/ON_HANDLE_ERROR';
export const ON_RESET_STATE = '@season-renewal/ON_RESET_STATE';

export interface OnPrepareData {
  type: typeof ON_PREPARE_DATA;
  payload: {
    seasonId: string;
  };
}

export interface OnPrepareDataCompleted {
  type: typeof ON_PREPARE_DATA_COMPLETED;
  payload: {
    season: SeasonDTO;
  };
}

export interface OnCreateSeasonRenewalCampaign {
  type: typeof ON_CREATE_SEASON_RENEWAL_CAMPAIGN;
  payload: SeasonRenewalUploadDTO;
}

export interface OnCreateSeasonRenewalCampaignCompleted {
  type: typeof ON_CREATE_SEASON_RENEWAL_CAMPAIGN_COMPLETED;
  payload: SeasonRenewalDTO;
}

export interface OnStoreSeasonRenewalAttendees {
  type: typeof ON_STORE_SEASON_RENEWAL_ATTENDEES;
  payload: PurchasedTicketDTO[];
}

export interface OnStoreSeasonRenewalCampaign {
  type: typeof ON_STORE_SEASON_RENEWAL_CAMPAIGN;
  payload: {
    seasonRenewal?: SeasonRenewalDTO;
    importedSeasonRenewals?: SeasonRenewalUploadRow[];
  };
}

export interface OnHandleError {
  type: typeof ON_HANDLE_ERROR;
}

export interface OnResetState {
  type: typeof ON_RESET_STATE;
}

export type SeasonRenewalActionType =
  | OnPrepareData
  | OnPrepareDataCompleted
  | OnCreateSeasonRenewalCampaign
  | OnCreateSeasonRenewalCampaignCompleted
  | OnStoreSeasonRenewalAttendees
  | OnStoreSeasonRenewalCampaign
  | OnHandleError
  | OnResetState;
