import { huddleAxiosInstanceProxy } from '../shared/axios';
import { deleteObject, putObject } from '../shared/aws';
import isEmpty from 'lodash/isEmpty';
import {
  GET_ACCOUNTS,
  GET_ACCOUNT_BY_ID,
  GET_ACCOUNTS_BY_IDS_V2,
  GET_ACCOUNTS_BY_KEYWORD,
  GET_ACCOUNT_HISTORY,
  UPDATE_ACCOUNT_BY_ID,
  GET_ACCOUNT_VENUES,
  GET_ACCOUNT_CONTACTS,
  GET_ACCOUNT_SALESFORCE
} from './account.endpoints';
import { config } from '@gofan/constants/config';

import type {
  AccountDTO,
  AccountKeywordSearchParams,
  AccountPageResponseDTO,
  AccountHistorySearchDTO,
  AccountContact
} from './account.model';
import type { HistoryDTO } from '../history/history.model';
import type { CancelTokenSource } from 'axios';
import { getQueryString } from '@gofan/utils/object';

class AccountRepository {
  static sourceAccountsByKeyword: null | CancelTokenSource = null;

  static getAccountById = (accountId: string, manualErrorHandling?: boolean): Promise<AccountDTO> =>
    huddleAxiosInstanceProxy.get<AccountDTO>(
      `${GET_ACCOUNT_BY_ID.replace('{id}', accountId)}?skipCache=true`,
      undefined,
      manualErrorHandling
    );

  static getAccountsByIds = (accountIds: string[]): Promise<AccountDTO[]> =>
    huddleAxiosInstanceProxy.get(
      `${GET_ACCOUNTS_BY_IDS_V2}${accountIds.length > 0 ? getQueryString({ id: accountIds }) : '?id'}`
    );

  static searchAccountByIds = (ids: string[]): Promise<AccountDTO[]> =>
    huddleAxiosInstanceProxy.get(
      `${GET_ACCOUNTS_BY_IDS_V2}${ids.length > 0 ? getQueryString({ id: ids }) : '?id'}`,
      undefined,
      true
    );

  // TODO: body is type AccountSearchDTO - ts bug needs to be fixed to revert this "any" change
  static searchAccountByParams = (body: any, queryStr: string): Promise<AccountPageResponseDTO> =>
    huddleAxiosInstanceProxy.post<AccountPageResponseDTO>(`${GET_ACCOUNTS}${queryStr}`, body);

  static getAccountsByKeyword = (params: AccountKeywordSearchParams): Promise<AccountDTO[]> => {
    AccountRepository.sourceAccountsByKeyword = huddleAxiosInstanceProxy.CancelToken.source();
    const { keyword, coordinates, manualErrorHandling, notAllowElementarySchool } = params;
    const excludeSchoolTypes = ['Elementary School', 'Elementary School Board', 'Elementary Member School']
      .map(schoolType => encodeURIComponent(schoolType))
      .join(',');

    const coordinateQuery = !isEmpty(coordinates)
      ? `&latitude=${coordinates.latitude}&longitude=${coordinates.longitude}`
      : '';
    const excludeSchoolQuery = notAllowElementarySchool ? `&excludeSchoolTypes=${excludeSchoolTypes}` : '';

    return huddleAxiosInstanceProxy.get<AccountDTO[]>(
      `${GET_ACCOUNTS_BY_KEYWORD.replace(
        '{keyword}',
        encodeURIComponent(keyword)
      )}${excludeSchoolQuery}${coordinateQuery}`,
      undefined,
      manualErrorHandling
    );
  };

  static cancelGetAccountsByKeyword = () => {
    if (AccountRepository.sourceAccountsByKeyword) {
      AccountRepository.sourceAccountsByKeyword.cancel('Cancelled Request');
    }
  };

  static updateAccountById = (account: AccountDTO): Promise<AccountDTO> =>
    huddleAxiosInstanceProxy.put<AccountDTO>(
      `${UPDATE_ACCOUNT_BY_ID.replace('{accountId}', account.id)}`,
      account,
      undefined,
      true
    );

  static partialUpdateAccountById = (account: Partial<AccountDTO>, accountId: string): Promise<AccountDTO> =>
    huddleAxiosInstanceProxy.patch<AccountDTO, Partial<AccountDTO>>(
      `${UPDATE_ACCOUNT_BY_ID.replace('{accountId}', accountId)}`,
      account,
      undefined,
      true
    );

  static getAllAccounts = (): Promise<AccountDTO[]> => huddleAxiosInstanceProxy.get(`${GET_ACCOUNTS}`);

  static getAccountSchools = (stateAssociationHuddleId: string): Promise<AccountDTO> =>
    huddleAxiosInstanceProxy.post(`${GET_ACCOUNTS}?size=9999`, {
      stateAssociationHuddleId
    });

  static getAccountVenues = (stateAssociationHuddleId: string): Promise<AccountDTO> =>
    huddleAxiosInstanceProxy.get(`${GET_ACCOUNT_VENUES.replace('{id}', stateAssociationHuddleId)}`);

  static getAccountContacts = (stateAssociationHuddleId: string): Promise<AccountContact[]> =>
    huddleAxiosInstanceProxy.get(`${GET_ACCOUNT_CONTACTS.replace('{id}', stateAssociationHuddleId)}`);

  static getAccountHistory = (params: AccountHistorySearchDTO): Promise<HistoryDTO[]> =>
    huddleAxiosInstanceProxy.get(
      `${GET_ACCOUNT_HISTORY.replace('{id}', params.accountId).replace('{dateRange}', params.dateRange)}`
    );

  static uploadLogo = (file: File, oldAvatar: string, accountId: string) => {
    const keyBase = `${config.s3.BASE_PATH}/logo/${accountId}`;
    if (!isEmpty(oldAvatar)) {
      if (!file) {
        return deleteObject({
          Bucket: config.s3.BUCKET,
          Key: `${keyBase}/${oldAvatar}`
        });
      }
      if (file.name !== oldAvatar) {
        deleteObject({
          Bucket: config.s3.BUCKET,
          Key: `${keyBase}/${oldAvatar}`
        });
      }
    }

    if (file && file.name) {
      return putObject({
        Body: file,
        Bucket: config.s3.BUCKET,
        Key: `${keyBase}/${file.name}`
      });
    }
  };

  static uploadImage = (file: File, accountId: string) => {
    if (file && file.name) {
      const keyBase = `${config.s3.BASE_PATH}/images/${accountId}/${file.name}`;

      return putObject({
        Body: file,
        Bucket: config.s3.BUCKET,
        Key: keyBase
      });
    }
  };

  static getAccountBySfId = (sfId: string, manualErrorHandling?: boolean): Promise<AccountDTO> =>
    huddleAxiosInstanceProxy.get<AccountDTO>(
      `${GET_ACCOUNT_SALESFORCE.replace('{sfId}', sfId)}?skipCache=true`,
      undefined,
      manualErrorHandling
    );
}

export { AccountRepository };
