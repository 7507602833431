import type React from 'react';
import { isEmpty, uniqueId } from 'lodash';
import classnames from 'classnames';
import { Row, Column, Link } from 'carbon-components-react';

import type { BasicButtonProps } from '@old-components/basic-button/basic-button.component';
import BasicButton from '@old-components/basic-button/basic-button.component';

export type SectionInfoControlItem = {
  text: string;
  onClick?: () => void;
};

interface SectionInfoProps {
  title: string;
  divider?: boolean;
  subTitle?: string;
  className?: string;
  titleClassName?: string;
  headingClassName?: string;
  buttons?: BasicButtonProps[];
  controls?: SectionInfoControlItem[];
  customTitle?: React.ReactNode;
  customControl?: React.ReactNode;
  children?: React.ReactNode;
}

const SectionInfoComponent = ({
  title,
  divider,
  subTitle,
  buttons,
  controls,
  className,
  customTitle,
  customControl,
  titleClassName,
  headingClassName,
  children
}: SectionInfoProps) => (
  <Column
    className={classnames(
      'gs--padding-top__sp5',
      'gs--padding-bottom__sp5',
      'section-info',
      { 'has-divider': divider },
      { 'section-info--custom-title': !isEmpty(customTitle) },
      className
    )}
  >
    <Row>
      <Column>
        <div
          className={classnames(
            'section-info--heading',
            { 'gs--padding-bottom__sp5': !isEmpty(title) || !isEmpty(subTitle) },
            headingClassName
          )}
        >
          <div className={classnames('section-info--title')}>
            <div
              className={classnames(
                'gs-text-01',
                { 'gs--productive-heading-03-semibold': isEmpty(titleClassName) },
                titleClassName
              )}
            >
              {title}
            </div>
            {!isEmpty(subTitle) && (
              <div className={classnames('gs--body-short-01', 'gs-text-01', 'gs--padding-left__sp3')}>{subTitle}</div>
            )}
            {!isEmpty(customTitle) && customTitle}
          </div>
          {!isEmpty(buttons) && (
            <div className='section-info--buttons'>
              {buttons.map(button => (
                <div key={`${button.text}_${uniqueId()}`} className='button-item'>
                  <BasicButton {...button} />
                </div>
              ))}
            </div>
          )}
          {!isEmpty(controls) && (
            <div className='section-info--controls'>
              {controls.map((control: any) => (
                <div key={`${control.text}_${uniqueId()}`} className='card-control'>
                  <Link onClick={control.onClick || noop}>{control.text}</Link>
                </div>
              ))}
            </div>
          )}
          {!isEmpty(customControl) && customControl}
        </div>
      </Column>
      {divider && <div className='divider' />}
    </Row>
    {children}
  </Column>
);

export default SectionInfoComponent;
