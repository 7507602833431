import React from 'react';
import { Dropdown as CarbonDropdown } from '@carbon/react';
import classNames from 'classnames';
import './Dropdown.scss';

export { DropdownSkeleton } from '@carbon/react';

export const Dropdown = React.forwardRef<
  React.ElementRef<typeof CarbonDropdown>,
  React.ComponentPropsWithoutRef<typeof CarbonDropdown> & React.RefAttributes<HTMLButtonElement>
>(({ className, ...props }, ref) => (
  <CarbonDropdown ref={ref} className={classNames('gf--dropdown', className)} {...props} />
));
