import { AxiosProxy, huddleAxiosInstanceProxy } from '../shared/axios';
import { SPONSOR, SPONSORSHIP_DEALS, SPONSORSHIPS, GET_CALCULATED_SPONSORSHIPS } from './sponsorship.endpoints';
import type {
  SponsorDTO,
  SponsorshipDealDTO,
  CreateSponsorshipDealDTO,
  CalculatedSponsorsResponse,
  CreateDeleteSponsorResponse
} from './sponsorship.model';
import { config } from '@gofan/constants';
import isEmpty from 'lodash/isEmpty';
import { deleteObject, putObject, uploadFile, deleteFile } from '../shared/aws';

export class SponsorshipRepository {
  static createSponsor = (sponsor: SponsorDTO): Promise<CreateDeleteSponsorResponse> =>
    huddleAxiosInstanceProxy.post(SPONSOR, sponsor, AxiosProxy.AXIOS_SPONSORSHIP_CONFIG, true);

  static getSponsor = (sponsorId: string): Promise<SponsorDTO> =>
    huddleAxiosInstanceProxy.get(`${SPONSOR}/${sponsorId}`, AxiosProxy.AXIOS_SPONSORSHIP_CONFIG, true);

  static async deleteSponsor(sponsorId: string): Promise<CreateDeleteSponsorResponse> {
    try {
      const response = await huddleAxiosInstanceProxy.deleteWithReturnStatus(
        `${SPONSOR}/${sponsorId}`,
        AxiosProxy.AXIOS_SPONSORSHIP_CONFIG,
        true
      );

      const message = response.data.message || 'Deletion successful';
      const { status } = response;
      return { message, status, error: '', id: '' };
    } catch (error) {
      const status = error.response?.status || 500; // Default to 500 if no status is available
      const messageCopy = error.response?.data?.message || 'Deletion failed';

      // Return an error response
      return { message: messageCopy, status, error: messageCopy, id: '' };
    }
  }

  static createSponsorshipDeal = (sponsorshipDeal: CreateSponsorshipDealDTO): Promise<CreateDeleteSponsorResponse> =>
    huddleAxiosInstanceProxy.post(SPONSORSHIP_DEALS, sponsorshipDeal, AxiosProxy.AXIOS_SPONSORSHIP_CONFIG, true);

  static getSponsorships = (schoolIds: string[]): Promise<any> => {
    if (schoolIds.length === 0) {
      return Promise.resolve([]);
    }
    const url = `${SPONSORSHIPS}?schoolIds=${schoolIds.join(',')}&limit=1000&skipCache=true`;
    return huddleAxiosInstanceProxy.get(url, AxiosProxy.AXIOS_SPONSORSHIP_CONFIG, true);
  };

  static getListSponsorshipDeals = (sponsorId: string): Promise<SponsorshipDealDTO[]> =>
    huddleAxiosInstanceProxy.get(
      `${SPONSORSHIP_DEALS}?sponsorId=${sponsorId}`,
      AxiosProxy.AXIOS_SPONSORSHIP_CONFIG,
      true
    );

  static updateSponsor = (sponsor: SponsorDTO) =>
    huddleAxiosInstanceProxy.put(`${SPONSOR}/${sponsor.id}`, sponsor, AxiosProxy.AXIOS_SPONSORSHIP_CONFIG, true);

  // This method is not used in the current codebase
  static updateSponsorshipDeal = (sponsorshipDeal: SponsorshipDealDTO) =>
    huddleAxiosInstanceProxy.put(
      `${SPONSORSHIP_DEALS}/${sponsorshipDeal.id}`,
      sponsorshipDeal,
      AxiosProxy.AXIOS_SPONSORSHIP_CONFIG,
      true
    );

  static deleteSponsorshipDeal = (sponsorshipDealId: string) =>
    huddleAxiosInstanceProxy.delete(
      `${SPONSORSHIP_DEALS}/${sponsorshipDealId}`,
      AxiosProxy.AXIOS_SPONSORSHIP_CONFIG,
      true
    );

  static uploadSponsorAsset = (file: File, previousAssetName: string) => {
    const keyBase = `${config.s3.BASE_SPONSOR_PATH}`;
    if (!isEmpty(previousAssetName)) {
      if (!file) {
        return deleteObject({
          Bucket: config.s3.BUCKET,
          Key: `${keyBase}/${previousAssetName}`
        });
      }
      if (file.name !== previousAssetName) {
        deleteObject({
          Bucket: config.s3.BUCKET,
          Key: `${keyBase}/${previousAssetName}`
        });
      }
    }

    if (file && file.name) {
      return putObject({
        Body: file,
        Bucket: config.s3.BUCKET,
        Key: `${keyBase}/${file.name}`,
        ContentType: file.type
      });
    }
  };

  static uploadSponsorAssetSharedS3 = (file: File, fileName: string, previousAssetName?: string) => {
    const keyBase = `${config.s3.BASE_SPONSOR_PATH}`;

    if (previousAssetName && previousAssetName !== fileName) {
      deleteFile({
        path: `${keyBase}/${previousAssetName}`
      });
    }

    if (file) {
      return uploadFile({
        body: file,
        path: `${keyBase}/${fileName}`
      });
    }

    return '';
  };

  static listSponsors = (schoolId: string): Promise<CalculatedSponsorsResponse> => {
    const url =
      schoolId === '' || schoolId === undefined
        ? GET_CALCULATED_SPONSORSHIPS
        : `${GET_CALCULATED_SPONSORSHIPS}?schoolId=${schoolId}`;

    return huddleAxiosInstanceProxy.get(url, AxiosProxy.AXIOS_SPONSORSHIP_CONFIG, true);
  };
}
