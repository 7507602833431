import React from 'react';
import { SkeletonPlaceholder, SkeletonText } from 'carbon-components-react';
import { DonutChart } from '@events/components/donut-chart';
import { formatNumber } from '@events/components/ticket-sale-chart/helper';
import type { EventDTO } from '@events/models/event.model';
import { MAX_CAPACITY } from '@events/constants';
import NoData from '@dashboard/components/no-data/no-data';

interface InsightTicketUsedByEventProps {
  event: EventDTO;
  data: any;
  chartConfig: any;
  tickets: any;
  loading: boolean;
}

export const InsightSaleOfAllotments = ({
  event,
  data,
  chartConfig,
  tickets,
  loading
}: InsightTicketUsedByEventProps) => {
  const { showEmptyData, totalTicketAvailableByTypes } = data || {};
  if (showEmptyData && event?.maxCapacity && event?.maxCapacity !== MAX_CAPACITY) {
    return (
      <>
        {loading ? (
          <>
            <SkeletonText />
            <SkeletonPlaceholder style={{ width: '100%' }} />
          </>
        ) : (
          <>
            <div className='gs--productive-heading-02-semibold gs--text-01 gs--padding-top__sp5 gs--text-center'>
              Tickets remaining
            </div>
            <NoData />
          </>
        )}
      </>
    );
  }
  return (
    <>
      <div className='gs--productive-heading-02-semibold gs--text-01 gs--padding-top__sp5 gs--text-center'>
        Tickets remaining
      </div>
      {loading ? (
        <>
          <SkeletonText />
          <SkeletonPlaceholder style={{ width: '100%' }} />
        </>
      ) : (
        <>
          <div className='gs--label-01-semibold gs--text-01 gs--padding-top__sp3 gs--text-center'>
            Total tickets available{' '}
            {event.maxCapacity ? formatNumber(event.maxCapacity) : formatNumber(totalTicketAvailableByTypes)}
          </div>
          <div id='sale-of-allotments' className='event-insight__chart gs--padding-top-sp5'>
            <DonutChart
              id='sale-of-allotments'
              data={chartConfig.data}
              options={chartConfig.options}
              loading={loading}
              customDonutTitle
            />
          </div>
        </>
      )}
    </>
  );
};
export default InsightSaleOfAllotments;
