import { isEmpty } from '../../../utils/objectUtils';
import { parseNumber } from '../../../utils/parseUtils';

class PublishLevelRequest {
  constructor({ levelId, genders }) {
    this.raw = {
      levelId: parseNumber(levelId),
      genders: isEmpty(genders) ? [] : genders
    };
  }

  get levelId() {
    return this.raw.levelId;
  }

  get genders() {
    return this.raw.genders;
  }

  toJSON() {
    return {
      levelId: this.levelId,
      genders: this.genders
    };
  }
}

export default PublishLevelRequest;
