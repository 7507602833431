import { isEmpty } from 'lodash';
import { TextInput, TextInputProps } from 'carbon-components-react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

export type BasicNumberTextInputConfig = TextInputProps & NumberFormatProps & {
  label: string;
};

export type BasicNumberTextInputProps = BasicNumberTextInputConfig & {
  name: string;
  control: any;
  initialValue: string;
  fieldValue: string;
  fieldError?: string;
  getFieldRef?: () => void;
};

const BasicNumberTextInput = ({
  name,
  label = '',
  format,
  maxLength,
  decimalScale,
  initialValue,
  fieldValue,
  fieldError,
  getFieldRef,
  control,
  ...other
}: BasicNumberTextInputProps) => (
  <NumberFormat
    {...other}
    {...control}
    id={`field-${name}`}
    name={`field-${name}`}
    getInputRef={getFieldRef}
    customInput={TextInput}
    format={format}
    decimalScale={decimalScale}
    maxLength={maxLength}
    labelText={label}
    defaultValue={initialValue}
    invalidText={fieldError}
    invalid={!isEmpty(fieldError)}
  />
);

export default BasicNumberTextInput;
