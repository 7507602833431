import { RootContext } from '@app/RootContext';
import { useEffect, useState, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Row, Column, TextInput, Toggle, RadioButtonGroup, RadioButton } from 'carbon-components-react';
import NumberFormat from 'react-number-format';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { DISTRIBUTION_CHANNEL } from '@api/model/request/ProductRequest';

import ColorPilette from '@app/commons/color-pilette/color-pilette.component';
import { ReservedSeatingCategorySelect } from '@events/components/reserved-seating-category-select';
import { SeatsIoChart } from '@season-management/components/seats-io-chart';

import { calculateFee } from '@gofan/api/account-products';
import { ReservedSeatingService } from '@gofan/api/reserved-seating';
import { isInternalUser } from '@gofan/api/users';

import { STRINGS as SEASON_TICKET_STRINGS } from '@season-management/components/SeasonTicketModal/constants';

import Logo from '@assets/images/logo.png';

import type { SeasonTicketData } from '@season-management/components/SeasonTicketModal';
import type { ReservedSeatingChartDTO } from '@gofan/api/reserved-seating';

import './SeasonTicketSetting.scss';
import { isSchoolEligibleToPayFee } from '@gofan/utils';

interface SeasonTicketSettingProps {
  data: SeasonTicketData;
  seasonTicket: any;
  mode: 'add' | 'update';
  seatsIoChartKey?: string;
  onShowNotification?: (accountPaid: boolean) => void;
}

const SeasonTicketSetting = ({
  data,
  seasonTicket,
  mode,
  seatsIoChartKey,
  onShowNotification
}: SeasonTicketSettingProps) => {
  const {
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    trigger
  } = useFormContext<any>();

  const customColor = watch('customColor');
  const reservedSeating = watch('reservedSeating');
  const seatsIoCategory = watch('seatsIoCategory');

  const [seatsIoChart, setSeatsIoChart] = useState<ReservedSeatingChartDTO>();
  const disabledByHasSold = data?.config?.hasSold?.(watch('id'));
  const disableAttendeeFeeToggle = !isSchoolEligibleToPayFee(data.account ?? undefined);
  const { currentUser } = useContext(RootContext);
  const isBoxOfficeCustomer = isInternalUser(currentUser) || data?.account?.boxOfficeCustomer;

  useEffect(() => {
    if (typeof seatsIoChartKey === 'string') _fetchReservedSeatingChart(seatsIoChartKey);

    async function _fetchReservedSeatingChart(chartKey: string) {
      await ReservedSeatingService.getReservedSeatingChart(chartKey).then(res => {
        setSeatsIoChart(res.data);
      });
    }
  }, [seatsIoChartKey]);

  useEffect(() => {
    if (seasonTicket?.groupDistributionChannel?.includes(DISTRIBUTION_CHANNEL.BOXOFFICE)) {
      setValue('boxOfficeTicket.enabled', true);
    }
  }, [seasonTicket]);

  return (
    <Row className='gf-season-ticket-setting'>
      <Column>
        <Row>
          <Column>
            <div className='gs--margin-bottom-sp6'>
              <div className='gs--productive-heading-02 gs--text-01 gs--margin-bottom-sp4'>
                {SEASON_TICKET_STRINGS.SEASON_TICKET_TITLE}
              </div>
              <img className='gf-gofan-logo gs--margin-bottom-sp5' alt='GoFan Logo' src={Logo} />

              {!isNil(seatsIoChartKey) && (
                <Controller
                  name='reservedSeating'
                  control={control}
                  render={({ field }) => (
                    <RadioButtonGroup
                      defaultSelected={!field.value ? 'general-admission' : 'reserved-seating'}
                      name='radio-button-group'
                      valueSelected={!field.value ? 'general-admission' : 'reserved-seating'}
                      onChange={(value: string) => {
                        field.onChange(value === 'reserved-seating');
                        if (value !== 'reserved-seating') setValue('seatsIoCategory', undefined);
                      }}
                      className='gs--margin-bottom-sp6'
                    >
                      <RadioButton id='radio-ga' labelText='General Admission' value='general-admission' />
                      <RadioButton id='radio-rs' labelText='Reserved Seating' value='reserved-seating' />
                    </RadioButtonGroup>
                  )}
                />
              )}

              <Controller
                name='name'
                control={control}
                render={({ field }) => (
                  <TextInput
                    id='name'
                    style={
                      {
                        borderLeft: `${customColor ? `solid 6px #${customColor}` : 'none'}`
                      } as React.CSSProperties
                    }
                    ref={field.ref}
                    labelText={SEASON_TICKET_STRINGS.SEASON_TICKET_NAME}
                    placeholder={SEASON_TICKET_STRINGS.SEASON_TICKET_NAME_PLACEHOLDER}
                    value={field.value}
                    disabled={disabledByHasSold}
                    invalid={!!get(errors, 'name')}
                    invalidText={get(errors, 'name.message')}
                    onChange={({ target: { value } }) => {
                      field.onChange(value);
                      if (isBoxOfficeCustomer) {
                        setValue('boxOfficeTicket.ticketName', value);
                      }
                    }}
                    onBlur={() => {
                      const value = `${field.value ?? ''}`.trim();
                      field.onChange(value);
                      if (isBoxOfficeCustomer) {
                        setValue('boxOfficeTicket.ticketName', value);
                      }
                      field.onBlur();
                    }}
                  />
                )}
              />
              {!isNil(seatsIoChartKey) && reservedSeating === true && (
                <Controller
                  name='seatsIoCategory'
                  control={control}
                  render={({ field }) => (
                    <ReservedSeatingCategorySelect
                      chartKey={seatsIoChartKey}
                      value={field.value}
                      onChange={(val: string) => {
                        field.onChange(val);
                      }}
                      initialSelectedItem={seatsIoCategory}
                      // invalid={!isEmpty(formControl.getError(`${path}.seatsIoCategory`))}
                      // invalidText={formControl.getError(`${path}.seatsIoCategory`)}
                    />
                  )}
                />
              )}
            </div>
          </Column>
          <Column>
            {!isNil(seatsIoChart) && reservedSeating === true && (
              <div className='seats-io-chart-wrapper'>
                <SeatsIoChart seatsIoChart={seatsIoChart} />
              </div>
            )}
          </Column>
        </Row>
        <div className='gf-season-ticket-setting__divider gs--margin-bottom-sp5' />
        <Row>
          <Column lg={6}>
            <div className='gs--body-short-01-semibold gs--text-01 gs--margin-bottom-sp5'>
              {SEASON_TICKET_STRINGS.SETTINGS_AND_PRICES}
            </div>
            <div className='gs--margin-bottom-sp5'>
              <Row>
                {mode === 'update' ? (
                  <Column lg={4}>
                    <Controller
                      name='enabled'
                      control={control}
                      render={({ field }) => (
                        <>
                          <div className='gs--label-01 gs--text-02'>{SEASON_TICKET_STRINGS.CURRENT_STATUS}</div>
                          <Toggle
                            id='enabled'
                            ref={field.ref}
                            labelText=''
                            labelA={SEASON_TICKET_STRINGS.ON_SALE}
                            labelB={SEASON_TICKET_STRINGS.ON_SALE}
                            toggled={field.value}
                            onToggle={value => {
                              field.onChange(value);
                            }}
                          />
                        </>
                      )}
                    />
                  </Column>
                ) : null}

                <Column lg={6}>
                  <Controller
                    name='hiddenFees'
                    control={control}
                    render={({ field }) => (
                      <>
                        <div className='gs--label-01 gs--text-02'>{SEASON_TICKET_STRINGS.ATTENDEE_PAYS_FEE}</div>
                        <Toggle
                          id='hiddenFees'
                          ref={field.ref}
                          disabled={disabledByHasSold || disableAttendeeFeeToggle}
                          labelText=''
                          labelA={SEASON_TICKET_STRINGS.TOGGLE_OFF}
                          labelB={SEASON_TICKET_STRINGS.TOGGLE_ON}
                          toggled={!field.value}
                          onToggle={value => {
                            field.onChange(!value);
                            _onCalculateFee({
                              accountPaid: !value
                            });

                            _onShowNotification(!value);
                          }}
                        />
                      </>
                    )}
                  />
                </Column>
                {isBoxOfficeCustomer && (
                  <Column lg={5}>
                    <Controller
                      name='groupDistributionChannel'
                      control={control}
                      render={({ field }) => (
                        <>
                          <div className='gs--label-01 gs--text-02'>Create Box Office</div>
                          <Toggle
                            id='groupDistributionChannel'
                            ref={field.ref}
                            labelText=''
                            labelA={SEASON_TICKET_STRINGS.TOGGLE_OFF}
                            labelB={SEASON_TICKET_STRINGS.TOGGLE_ON}
                            defaultToggled={getValues('boxOfficeTicket.enabled')}
                            toggled={getValues('boxOfficeTicket.enabled')}
                            onToggle={value => {
                              if (value === true) {
                                field.onChange([DISTRIBUTION_CHANNEL.GOFAN, DISTRIBUTION_CHANNEL.BOXOFFICE]);
                              } else {
                                field.onChange([DISTRIBUTION_CHANNEL.GOFAN]);
                              }
                              const ticketName = getValues('name');
                              const ticketPrice = getValues('price');
                              setValue('boxOfficeTicket.enabled', value);
                              setValue('boxOfficeTicket.ticketName', ticketName);
                              setValue('boxOfficeTicket.ticketPrice', ticketPrice);
                            }}
                          />
                        </>
                      )}
                    />
                  </Column>
                )}
              </Row>
            </div>
            <div className='gs--margin-bottom-sp5'>
              <Row>
                <Column lg={4}>
                  <Controller
                    name='price'
                    control={control}
                    render={({ field }) => (
                      <NumberFormat
                        id='price'
                        getInputRef={field.ref}
                        customInput={TextInput}
                        labelText={SEASON_TICKET_STRINGS.TICKET_PRICE}
                        placeholder={SEASON_TICKET_STRINGS.CURRENCY_PLACEHOLDER}
                        prefix='$'
                        decimalScale={2}
                        thousandSeparator
                        fixedDecimalScale
                        allowNegative={false}
                        disabled={disabledByHasSold}
                        value={field.value}
                        invalid={!!get(errors, 'price')}
                        invalidText={get(errors, 'price.message')}
                        onValueChange={({ value }) => {
                          field.onChange(value);
                          if (isBoxOfficeCustomer) {
                            setValue('boxOfficeTicket.ticketPrice', value);
                          }
                        }}
                        onBlur={() => {
                          _onCalculateFee({
                            accountPaid: getValues('hiddenFees')
                          });
                          _onShowNotification(watch('hiddenFees'));
                          field.onBlur();
                        }}
                      />
                    )}
                  />
                </Column>

                <Column lg={4} className='gs--padding-left__sp0 gs--padding-right__sp0'>
                  <Controller
                    name='payToSchool'
                    control={control}
                    render={({ field }) => (
                      <NumberFormat
                        id='payToSchool'
                        getInputRef={field.ref}
                        customInput={TextInput}
                        labelText={SEASON_TICKET_STRINGS.PAY_TO_SCHOOL}
                        placeholder={SEASON_TICKET_STRINGS.CURRENCY_PLACEHOLDER}
                        prefix='$'
                        decimalScale={2}
                        thousandSeparator
                        fixedDecimalScale
                        disabled
                        value={field.value}
                      />
                    )}
                  />
                </Column>
                <Column lg={4}>
                  <Controller
                    name='fanPrice'
                    control={control}
                    render={({ field }) => (
                      <NumberFormat
                        id='fanPrice'
                        getInputRef={field.ref}
                        customInput={TextInput}
                        labelText={SEASON_TICKET_STRINGS.FAN_PRICE}
                        placeholder={SEASON_TICKET_STRINGS.CURRENCY_PLACEHOLDER}
                        prefix='$'
                        decimalScale={2}
                        thousandSeparator
                        fixedDecimalScale
                        disabled
                        value={field.value}
                      />
                    )}
                  />
                </Column>
              </Row>
            </div>
          </Column>
          <Column lg={6}>
            <div className='gs--margin-bottom-sp5'>
              <Controller
                name='customColor'
                control={control}
                render={({ field }) => (
                  <>
                    <div className='gs--body-short-01-semibold gs--text-01 gs--margin-bottom-sp6'>
                      {SEASON_TICKET_STRINGS.ASSIGN_COLOR}
                    </div>
                    <div className='gs--body-short-01 gs--text-01 gs--margin-bottom-sp5'>
                      {SEASON_TICKET_STRINGS.ASSIGN_COLOR_DESCRIPTION}
                    </div>
                    <ColorPilette
                      key='customColor'
                      visible
                      disabled={false}
                      defaultColor=''
                      color={field.value}
                      onChange={color => field.onChange(color)}
                    />
                    <input ref={field.ref} className='invisibility-input' />
                  </>
                )}
              />
            </div>
          </Column>
        </Row>
      </Column>
    </Row>
  );

  function _setFee({ accountPaid, ticketPrice }: { accountPaid: boolean; ticketPrice: any }) {
    if (disabledByHasSold) return;
    const result = calculateFee({
      rates: data.rates,
      ticketPrice,
      accountPaid
    });

    const fanPrice = parseFloat(`${result?.fanPrice ?? 0}`);
    const payToSchool = parseFloat(`${result?.payToSchool ?? 0}`);

    setValue('fanPrice', +fanPrice);
    setValue('payToSchool', +payToSchool);
  }

  function _onCalculateFee({ accountPaid }: { accountPaid: boolean }) {
    const ticketPrice = watch('price');
    _setFee({ ticketPrice, accountPaid });
  }

  function _onShowNotification(accountPaid?: boolean) {
    if (typeof onShowNotification === 'function') {
      onShowNotification(!!accountPaid);
    }
  }
};

export { SeasonTicketSetting };
