import React from 'react';
import { Form } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import styles from './styles';

const StyledWrapper = styled.div`
  ${styles.textFieldWrapper};
`;
const StyledInput = styled(Form.Input)`
  ${styles.inputValidation};
`;
const StyledErrorMessage = styled.div`
  ${styles.errorMessage};
`;

export default ({
  label,
  handleOnChange,
  handleOnBlur,
  error,
  value,
  customHeight,
  ...props
}) => {
  const isInvalidField = isEmpty(error);

  return (
    <StyledWrapper invalid={isInvalidField}>
      <StyledInput
        value={value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        label={label}
        customheight={customHeight}
        {...props}
      />
      {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
    </StyledWrapper>
  );
};
