// @flow

import { ErrorResolver, Context } from './types';
import APIErrorResolver from "./resolvers/APIErrorResolver";

export default class ErrorHandler {
  static instance: ErrorHandler;

  errorResolver: ErrorResolver;

  constructor() {
    this.errorResolver = new APIErrorResolver();
  }

  static getInstance = (): ErrorHandler => {
    if (!ErrorHandler.instance) {
      ErrorHandler.instance = new ErrorHandler();
    }
    return ErrorHandler.instance;
  };

  resolve = (context: Context) => {
    if (context.type === APIErrorResolver.NAME) {
      this.errorResolver.resolve(context);
    }
  }
}
