import { isEmpty } from '../../../utils/objectUtils';

export const SEARCH_EVENT_DEFAULT_DATE = '2019-04-24';

class SearchEventsRequest {
  constructor({
    searchTerm,
    id,
    name,
    endDate,
    startDate,
    seasonId,
    accountIds,
    activitiesIds,
    includeArchived,
    status,
    postSeason,
    athletic,
    opponentAccountId,
    financialAccountIds,
    specificDate,
    endingAfterEndDate,
    endingAfterNow,
    genders,
    levels,
    venueIds,
    searchOption,
    flatFileBatchId,
    flatFileEditBatchIds,
    eventTypes
  }) {
    this.raw = {
      searchTerm,
      id,
      name,
      endDate,
      startDate,
      seasonId,
      accountIds,
      activitiesIds,
      includeArchived,
      postSeason,
      athletic,
      status,
      opponentAccountId,
      financialAccountIds,
      specificDate,
      endingAfterEndDate,
      endingAfterNow,
      genders,
      levels,
      venueIds,
      searchOption,
      flatFileBatchId,
      flatFileEditBatchIds,
      eventTypes
    };
  }

  get searchTerm() {
    return this.raw.searchTerm;
  }

  get id() {
    return this.raw.id;
  }

  get name() {
    return this.raw.name;
  }

  get status() {
    return this.raw.status;
  }

  get seasonId() {
    return this.raw.seasonId;
  }

  get activitiesIds() {
    return isEmpty(this.raw.activitiesIds) ? undefined : this.raw.activitiesIds;
  }

  get venueIds() {
    return isEmpty(this.raw.venueIds) ? undefined : this.raw.venueIds;
  }

  get accountIds() {
    return this.raw.accountIds;
  }

  get opponentAccountId() {
    return this.raw.opponentAccountId;
  }

  get financialAccountIds() {
    return this.raw.financialAccountIds;
  }

  get endDate() {
    return this.raw.endDate;
  }

  get startDate() {
    return this.raw.startDate;
  }

  get includeArchived() {
    return this.raw.includeArchived;
  }

  get postSeason() {
    return this.raw.postSeason;
  }

  get athletic() {
    return this.raw.athletic;
  }

  get specificDate() {
    return this.raw.specificDate;
  }

  get endingAfterEndDate() {
    return this.raw.endingAfterEndDate;
  }

  get endingAfterNow() {
    return this.raw.endingAfterNow;
  }

  get genders() {
    return this.raw.genders;
  }

  get levels() {
    return this.raw.levels;
  }

  get searchOption() {
    return this.raw.searchOption;
  }

  get flatFileBatchId() {
    return this.raw.flatFileBatchId;
  }

  get flatFileEditBatchIds() {
    return this.raw.flatFileEditBatchIds;
  }

  get isExistFlatFileBatchId() {
    return !isEmpty(this.raw.flatFileBatchId) || !isEmpty(this.flatFileEditBatchIds);
  }

  get eventTypes() {
    return this.raw.eventTypes;
  }

  toJSON() {
    return {
      seasonId: this.seasonId,
      accountIds: this.isExistFlatFileBatchId ? undefined : this.accountIds,
      endDate: this.endDate,
      startDate: this.startDate,
      id: this.id,
      name: this.name,
      searchTerm: this.searchTerm,
      venueIds: this.venueIds,
      activitiesIds: this.activitiesIds,
      includeArchived: this.includeArchived,
      status: this.status,
      postSeason: this.postSeason,
      athletic: this.athletic,
      opponentAccountId: this.opponentAccountId,
      financialAccountIds: this.isExistFlatFileBatchId ? undefined : this.financialAccountIds,
      specificDate: this.specificDate,
      endingAfterEndDate: this.endingAfterEndDate,
      endingAfterNow: this.endingAfterNow,
      genders: this.genders,
      levels: this.levels,
      searchOption: this.searchOption,
      flatFileBatchId: this.flatFileBatchId,
      flatFileEditBatchIds: this.flatFileEditBatchIds,
      eventTypes: this.eventTypes
    };
  }
}

export default SearchEventsRequest;
