import React from 'react';
import { Button, TableToolbarContent, TableToolbarSearch } from 'carbon-components-react';
import classNames from 'classnames';
import ButtonToolTip from '@fan-management/components/button-with-tooltip/button-with-tooltip.component';
import { Reset16, SettingsAdjust16 } from '@carbon/icons-react';

interface TableToolbarBatchActionsProps {
  // eslint-disable-next-line react/require-default-props
  style?: any;
  batchActions: {
    hasBatchActions: boolean;
    hasCancelButton?: boolean;
    hasRefresh?: boolean;
    hasSearch?: boolean;
    totalSeclected: number;
    customToolBarItems: any[];
    onCancel?: () => void;
    onClickRefresh?: () => void;
    onSearchChanged?: (event: any) => void;
    filterLabel?: string;
  };
  // eslint-disable-next-line react/require-default-props
  // eslint-disable-next-line react/require-default-props
  onClickedToolbar?: () => void;
  // eslint-disable-next-line react/require-default-props
  searchPlaceHolder?: string;
}

const TableToolbarBatchActions = ({
  style,
  batchActions,
  onClickedToolbar,
  searchPlaceHolder
}: TableToolbarBatchActionsProps) => (
  <>
    {batchActions?.totalSeclected > 0 && (
      <div className='batch-actions--total-seclected' style={style}>
        {`${batchActions?.totalSeclected} ${batchActions?.totalSeclected > 1 ? 'items' : 'item'} selected`}
      </div>
    )}
    <TableToolbarContent
      style={style}
      className={classNames('basic-table--toolbar', {
        'batch-actions__search--hover': batchActions?.totalSeclected > 0
      })}
    >
      {batchActions?.hasSearch && (
        <TableToolbarSearch placeHolderText={searchPlaceHolder} onChange={batchActions?.onSearchChanged} />
      )}
      {batchActions?.hasRefresh && batchActions?.totalSeclected > 0 && (
        <ButtonToolTip
          tooltipText='Unredeem selected'
          onClick={batchActions?.onClickRefresh}
          renderIcon={Reset16}
          isActive={batchActions?.totalSeclected > 0}
        />
      )}
      {(batchActions?.customToolBarItems || []).length > 0 && batchActions?.customToolBarItems}
      <ButtonToolTip
        id='toolbar-filter-btn'
        onClick={onClickedToolbar}
        renderIcon={SettingsAdjust16}
        isActive={batchActions?.totalSeclected > 0}
        tooltipText={batchActions?.filterLabel || 'Filter'}
      />
      {batchActions?.totalSeclected > 0 && (
        <Button onClick={batchActions.onCancel}>
          <div className='batch-actions__cancel--border'>Cancel</div>
        </Button>
      )}
    </TableToolbarContent>
  </>
);
export default TableToolbarBatchActions;
