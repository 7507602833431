import classNames from 'classnames';

import isEmpty from 'lodash/isEmpty';

import activityIcons, { IconsType } from '@assets/images/activity-icons/activity-icons';

interface EventInfoProps {
  eventInfo: any;
}

const EventInfo = ({ eventInfo }: EventInfoProps) => {
  return (
    <>
      <div className='container__activity'>
        <img
          alt='Activity Logo'
          src={
            (activityIcons as IconsType)?.[eventInfo.activityLabel?.replace(/ /g, '').toLowerCase()] ||
            activityIcons.unlabeled
          }
        />
        <div
          className={classNames('gs--body-short-02', 'gs--text-01', 'gs--padding-left__sp4')}
        >{`${eventInfo.genderLabel} ${eventInfo.activityLabel}`}</div>
      </div>
      <div className='container__event-info'>
        <div className='container__event-info__prefix' hidden={isEmpty(eventInfo.prefix)}>
          {eventInfo.prefix}
        </div>
        {eventInfo.schoolIcon && <img alt='School Logo' src={eventInfo.schoolIcon} />}
        <div className='container__event-info__label'>{eventInfo.shortName}</div>
      </div>
      <div className='container__date'>{eventInfo.dateTime}</div>
    </>
  );
};

export { EventInfo };
