import { formatMoney } from '@utils/objectUtils';

export const DEFAULT_MOBILE_PASS_COLOR = 'ffffff';

export const fieldType = {
  email: '@fieldType/email',
  password: '@fieldType/password',
  confirmPassword: '@fieldType/confirmPassword'
};

export const TECHNICAL_GUIDELINES_URL =
  'http://www.huddletickets.com/s/GoFan-Customizing-Your-School-Page-Best-Practices.pdf';

export const BUYER_PURCHASE_LIMIT_VALIDATION = {
  MIN: 1,
  MAX: 150
};

export const COMP_TICKETS_LIMIT_VALIDATION = {
  MIN: 1,
  MAX: 150
};

export const COMP_MOBILE_PASS_LIMIT_VALIDATION = {
  MIN: 1,
  MAX: 150
};

export const COMP_SEASON_TICKETS_LIMIT_VALIDATION = {
  MIN: 1,
  MAX: 150
};

export const MIN_EVENT_LIMIT = 1;
export const MAX_EVENT_LIMIT = 9999999;
export const MIN_PRICE_ACCOUNT_TICKET = 0;
export const MAX_PRICE_ACCOUNT_TICKET = 50000;
export const MIN_TICKET_FEE = 0;
export const MAX_TICKET_FEE = 250;
export const MAX_ADMIN_TICKET_FEE = 50000;
export const MIN_TICKET_LIMIT_PER_ORDER = 1;
export const MAX_TICKET_LIMIT_PER_ORDER = 150;
export const MIN_PACK_COUNT = 2;
export const MAX_PACK_COUNT = 200;
export const MIN_REDEMPTION_LIMIT = 2;
export const MAX_REDEMPTION_LIMIT = 99999;
export const MAXIMUM_TICKET_NAME_CHARACTERS = 35;
export const TICKET_ACCOUNT = {
  MIN_PRICE_ACCOUNT_TICKET,
  MAX_PRICE_ACCOUNT_TICKET,
  MIN_EVENT_LIMIT,
  MAX_EVENT_LIMIT,
  MIN_TICKET_FEE,
  MAX_TICKET_FEE,
  MAX_ADMIN_TICKET_FEE,
  MIN_TICKET_LIMIT_PER_ORDER,
  MAX_TICKET_LIMIT_PER_ORDER,
  MIN_PACK_COUNT,
  MAX_PACK_COUNT,
  MIN_REDEMPTION_LIMIT,
  MAX_REDEMPTION_LIMIT,
  ERRORS: {
    MIN_MAX_TICKET_PRICE: `Price must be from ${formatMoney(MIN_PRICE_ACCOUNT_TICKET)} to ${formatMoney(
      MAX_ADMIN_TICKET_FEE
    )}.`,
    MIN_MAX_TICKET_FEE: `Fee must be from ${formatMoney(MIN_TICKET_FEE)} to ${formatMoney(MAX_TICKET_FEE)}.`,
    MIN_MAX_ADMIN_ROLE_TICKET_FEE: `Fee must be from ${formatMoney(MIN_TICKET_FEE)} to ${formatMoney(
      MAX_ADMIN_TICKET_FEE
    )}.`,
    MIN_MAX_PACK_COUNT: `Family Pass should be between ${MIN_PACK_COUNT} and ${MAX_PACK_COUNT}.`,
    MIN_MAX_REDEMPTION_LIMIT: `You can enter a number between ${MIN_REDEMPTION_LIMIT} and ${MAX_REDEMPTION_LIMIT}.`,
    TICKET_NAME_ERROR_MSG:
      'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (`()./_-#\'@&*+",:;?!) in between 2-40 characters long.'
  }
};
