import isEmpty from 'lodash/isEmpty';

import {
  ARBITER_PARTNER_NAME,
  DEFAULT_PARTNER_FULL_NAME,
  FULL_NAME_INTEGRATION_PARTNERS
} from '@modules/event-integrations_V2/constants/constants';

import type { ActivityDTO } from '@gofan/api/activities';
import type { LevelDTO } from '@gofan/api/levels';
import type { AccountDTO } from '@gofan/api/accounts';
import type { AccountProductDTO } from '@gofan/api/account-products';
import type { EventScheduleSeasonDTO } from '@modules/event-integrations_V2/models/event-schedule.model';
import type { ScheduleData } from '@modules/event-integrations_V2/hooks';

export interface FilterEventSchedulesDTO {
  levels: LevelDTO[];
  activities: ActivityDTO[];
  tickets: AccountProductDTO[];
  schedules: EventScheduleSeasonDTO[];
}

export const filterEventSchedulesToPublish = ({
  levels = [],
  activities = [],
  tickets = [],
  schedules = []
}: FilterEventSchedulesDTO) =>
  schedules.reduce((res: EventScheduleSeasonDTO[], schedule: EventScheduleSeasonDTO) => {
    const scheduleLevelId = levels?.find(
      (level: any) => `${level?.name}`.toLowerCase() === `${schedule?.eventLevel}`.toLowerCase()
    )?.id;
    const scheduleActivityId = activities?.find(
      (activity: any) => `${activity?.label}`.toLowerCase() === `${schedule?.sportName}`.toLowerCase()
    )?.id;
    const foundAccountTickets = tickets?.find(
      (ticket: AccountProductDTO) =>
        `${ticket.status}`.toLowerCase() === 'active' &&
        `${scheduleActivityId}` === `${ticket.activityId}` &&
        ticket.levelIds?.some((levelId: any) => `${levelId}` === `${scheduleLevelId}`)
    );
    if (foundAccountTickets) {
      res.push(schedule);
    }

    return res;
  }, []);

export type PartnerKey = keyof typeof FULL_NAME_INTEGRATION_PARTNERS;

export const isValidKey = (key: string): key is PartnerKey => key in FULL_NAME_INTEGRATION_PARTNERS;

export const getPartnerName = (key: string) =>
  isValidKey(key) ? FULL_NAME_INTEGRATION_PARTNERS[key] : DEFAULT_PARTNER_FULL_NAME;

export const getPartnerSportName = (key: string) => {
  let sportName = isValidKey(key) ? FULL_NAME_INTEGRATION_PARTNERS[key] : DEFAULT_PARTNER_FULL_NAME;
  if (key === ARBITER_PARTNER_NAME) {
    sportName = `${sportName}Sports`;
  }
  return sportName;
};

export const getScheduleAccountsByHomeSchool = (homeSchoolId: string, scheduleData: ScheduleData) => {
  const { accounts, districtAccounts, schoolConfigs } = scheduleData ?? {};
  const account = accounts?.find(account => `${account.id}` === `${homeSchoolId}`) as AccountDTO;
  let financialAccountId = homeSchoolId;
  let financialAccount = accounts?.find(account => `${account.id}` === `${financialAccountId}`) as AccountDTO;
  const districtAccount = districtAccounts?.find(
    districtAccount =>
      districtAccount.sfId && account?.districtHuddleId && `${districtAccount.sfId}` === `${account?.districtHuddleId}`
  );
  const schoolConfig = schoolConfigs?.find(schoolConfig => `${schoolConfig.schoolId}` === `${homeSchoolId}`);
  if (
    !isEmpty(districtAccount) &&
    !isEmpty(schoolConfig) &&
    schoolConfig?.districtDefaultFinancialId === districtAccount?.id
  ) {
    financialAccountId = schoolConfig?.districtDefaultFinancialId;
    financialAccount = districtAccounts?.find(account => `${account.id}` === `${financialAccountId}`) as AccountDTO;
  }
  const taggedAccounts =
    !isEmpty(districtAccount) && `${districtAccount?.id}` !== `${homeSchoolId}` ? [districtAccount] : [];
  return { account, financialAccount, taggedAccounts };
};
