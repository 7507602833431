import React from 'react';
import { isEmpty } from 'lodash';
import { Dropdown, DropdownProps } from 'carbon-components-react';

export type BasicDropdownConfig<ItemType = string> = DropdownProps & {
  items: ItemType[];
  label: string;
  titleText: string;
};

export type BasicDropdownProps<ItemType = string> = BasicDropdownConfig & {
  name: string;
  control: any;
  initialValue: ItemType;
  fieldValue: ItemType;
  fieldError?: string;
  getFieldRef?: () => void;
  itemToString?(item: ItemType): string;
}

const BasicDropdown = ({
  name,
  className,
  titleText,
  label = '',
  items = [],
  initialValue,
  fieldValue,
  fieldError,
  getFieldRef,
  itemToString,
  control,
  ...other
}: BasicDropdownProps) => (
  <Dropdown
    {...other}
    {...control}
    id={`field-${name}`}
    ref={getFieldRef}
    className={className}
    initialSelectedItem={initialValue}
    selectedItem={fieldValue}
    items={items}
    label={label}
    titleText={titleText}
    invalidText={fieldError}
    invalid={!isEmpty(fieldError)}
    itemToString={itemToString}
  />
);

export default BasicDropdown;
