import { ADD_BULK_PROMOTION_INFO, REMOVE_BULK_PROMOTION_INFO } from './actionTypes';
import type { PromotionsState } from './types';

export const initialState: PromotionsState = {
  bulkPromotions: []
};

const promotionsState = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_BULK_PROMOTION_INFO: {
      const { bulkPromotion } = action.payload;
      return {
        ...state,
        bulkPromotions: [...state.bulkPromotions, bulkPromotion]
      };
    }

    case REMOVE_BULK_PROMOTION_INFO: {
      const { eventId } = action.payload;
      return {
        ...state,
        bulkPromotions: state.bulkPromotions?.filter(promotionInfo => promotionInfo.eventId !== eventId)
      };
    }

    default:
      return { ...state };
  }
};

export default promotionsState;
