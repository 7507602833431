import React from 'react';
import { Table } from 'semantic-ui-react';

const getSortDirection = (isSorted, isSortedDesc) => {
  if (!isSorted) return null;
  return isSortedDesc ? 'descending' : 'ascending';
};

function TableHeader({ tableInstance, hasSorting }) {
  const { headerGroups } = tableInstance;
  return (
    <Table.Header>
      {headerGroups.map(headerGroup => (
        <Table.Row {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => {
            const { onClick, ...headerProps } = column.getHeaderProps(
              hasSorting ? column.getSortByToggleProps() : {}
            );
            return (
              <Table.HeaderCell
                {...headerProps}
                title=''
                onClick={column.preventClick ? undefined : onClick}
                sorted={getSortDirection(column.isSorted, column.isSortedDesc)}
              >
                {column.render('Header')}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      ))}
    </Table.Header>
  );
}

TableHeader.defaultProps = {
  hasSorting: false,
  tableInstance: {
    headerGroups: [],
    getHeaderGroupProps: () => null
  }
};

export default TableHeader;
