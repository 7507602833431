import moment from 'moment-timezone';
import * as yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

import {
  formatDateTime,
  getTimeZone,
  switchZone,
  EVENT_DATE_FORMAT,
  DATE_PICKER_FORMAT,
  DATE_TIME_PICKER_FORMAT_DEFAULT,
  EVENT_DATE_FORMAT_WITH_TIMEZONE,
  TIME_FORMAT
} from '@app/utils/dateUtils';

import type { EventDTO } from '@events/models/event.model';

import { FUNDRAISER } from '@gofan/constants';
import { EVENT_START_TIME_TYPE, STRINGS, TIME_PICKER_PATTERN } from '../constants';

export interface EventDateTime {
  allDayEvent: boolean;
  eventDate: string;
  eventStartDateTime: string;
  startDate: string;
  startTime: string;
  eventEndDateTime: string;
  endDate: string;
  endTime: string;
}

export const initDateTimeValues = (event: EventDTO): EventDateTime => {
  const formattedStartDateTime = formatDateTime({
    date: event?.startDateTime,
    timeZone: event?.timeZone,
    parseZone: true
  });
  const eventStartDateTime = switchZone(formattedStartDateTime.toDate(EVENT_DATE_FORMAT_WITH_TIMEZONE), 'localZone');
  const mEventStartDateTime = moment(eventStartDateTime);

  const formattedEndDateTime = formatDateTime({
    date: event?.endDateTime,
    timeZone: event?.timeZone,
    parseZone: true
  });
  const eventEndDateTime = switchZone(formattedEndDateTime.toDate(EVENT_DATE_FORMAT_WITH_TIMEZONE), 'localZone');
  const mEventEndDateTime = moment(eventEndDateTime);

  return {
    allDayEvent: event?.allDayEvent ?? false,
    eventDate: mEventStartDateTime.clone().format(DATE_PICKER_FORMAT),

    eventStartDateTime,
    startDate: mEventStartDateTime.clone().format(DATE_PICKER_FORMAT),
    startTime: mEventStartDateTime.clone().format(TIME_FORMAT),

    eventEndDateTime,
    endDate: mEventEndDateTime.clone().format(DATE_PICKER_FORMAT),
    endTime: mEventEndDateTime.clone().format(TIME_FORMAT)
  };
};

const getMinInStartTimeOptions = ({ startTimeOptions }: { startTimeOptions: { [key: string]: string } }) => {
  const now = moment().format(EVENT_DATE_FORMAT);
  let minStartTime: any = {};

  Object.keys(startTimeOptions ?? {}).some(startTimeId => {
    const startTimeOption = startTimeOptions?.[startTimeId];
    const curTime = moment(`${now} ${startTimeOption}`);

    if (!curTime.isValid()) return false;

    const minTime = moment(`${now} ${minStartTime?.startTime}`);
    if (
      isEmpty(minStartTime) ||
      !minTime.isValid() ||
      minTime.isAfter(curTime, 'hour') ||
      minTime.isAfter(curTime, 'minute')
    ) {
      minStartTime = {
        startTimeId,
        startTime: startTimeOption
      };
    }
    return false;
  });
  return minStartTime;
};

const updateTimeInStartTimeOptions = ({
  updateValue,
  startTimeOptions
}: {
  updateValue: string;
  startTimeOptions: { [key: string]: string };
}) => {
  if (isEmpty(startTimeOptions)) return {};

  const now = moment().format(EVENT_DATE_FORMAT);
  const mUpdateTime = moment(`${now} ${updateValue}`);

  const minStartTime = getMinInStartTimeOptions({ startTimeOptions });
  const mMinStartTime = moment(`${now} ${minStartTime?.startTime}`);

  let newStartTimeOptions = {};
  Object.keys(startTimeOptions ?? {}).forEach(startTimeId => {
    const mDateTime = moment(`${now} ${startTimeOptions?.[startTimeId]}`);
    const diffSeconds = mDateTime.diff(mMinStartTime.clone(), 'seconds');

    const mUpdateStartTime = mUpdateTime.clone().add(diffSeconds, 'seconds');
    const updateValue = mUpdateStartTime.clone().format(TIME_FORMAT);

    newStartTimeOptions = {
      ...newStartTimeOptions,
      [startTimeId]: updateValue
    };
  });

  return { ...newStartTimeOptions };
};

export const generateDateTimeValues = (
  event: EventDTO,
  values: EventDateTime
): {
  allDayEvent: boolean;
  startDateTime: string;
  endDateTime: string;
  startTimeOptions: { [key: string]: string };
} => {
  const mTimeZone = getTimeZone(event?.timeZone ?? '');
  const mStartDateTime = moment(`${values?.startDate} ${values?.startTime}`);
  const mEndDateTime = moment(`${values?.endDate} ${values?.endTime}`);
  const startDateTime = switchZone(mStartDateTime.format(EVENT_DATE_FORMAT_WITH_TIMEZONE), mTimeZone);
  const endDateTime = switchZone(mEndDateTime.format(EVENT_DATE_FORMAT_WITH_TIMEZONE), mTimeZone);

  const eventStartTimeType = event?.startTimeType ?? EVENT_START_TIME_TYPE.ALL_TEAMS;
  let eventStartTimeOptions = cloneDeep(event?.startTimeOptions ?? {});

  if (eventStartTimeType === EVENT_START_TIME_TYPE.EACH_TEAM) {
    eventStartTimeOptions = updateTimeInStartTimeOptions({
      updateValue: values?.startTime,
      startTimeOptions: cloneDeep(event?.startTimeOptions ?? {})
    });
  }

  return {
    allDayEvent: values.allDayEvent,
    startDateTime,
    endDateTime,
    startTimeOptions: eventStartTimeOptions
  };
};

export const getMinDateTime = (date: string) => {
  const currentDateTime = moment(new Date());
  const mDate = moment(date);
  if (!mDate.isValid()) return currentDateTime;
  return moment.min(moment(date), currentDateTime);
};

export const getMaxDateTime = (date: string) => {
  let mDate = moment(date);
  if (!mDate.isValid()) mDate = moment(new Date());
  return mDate.add(90, 'days');
};

export const validateTime = ({
  time = '',
  pattern = TIME_PICKER_PATTERN,
  invalidMessage = 'invalid.',
  requiredMessage = 'required.'
}) => {
  if (isEmpty(time)) return requiredMessage;

  const date = moment().format(DATE_PICKER_FORMAT);
  if (!pattern.test(time) || !moment(`${date} ${time}`, DATE_TIME_PICKER_FORMAT_DEFAULT).isValid()) {
    return invalidMessage;
  }
  return '';
};

export const validateDate = ({ date = '', invalidMessage = 'invalid.', requiredMessage = 'required.' }) => {
  if (isEmpty(date)) return requiredMessage;

  const mDate = moment(date, DATE_TIME_PICKER_FORMAT_DEFAULT);
  if (!mDate.isValid()) return invalidMessage;
  return '';
};

export const isStartAfterEndDateTime = ({ startDate, startTime, endDate, endTime, unit, allowSame }: any) => {
  const mStartDateTime = moment(`${startDate} ${startTime}`, DATE_TIME_PICKER_FORMAT_DEFAULT, true);
  const mEndDateTime = moment(`${endDate} ${endTime}`, DATE_TIME_PICKER_FORMAT_DEFAULT, true);

  if (mStartDateTime.isValid() && mEndDateTime.isValid()) {
    if (allowSame) {
      return mStartDateTime.isAfter(mEndDateTime, unit);
    }
    return mStartDateTime.isSameOrAfter(mEndDateTime, unit);
  }
  return false;
};

export const isStartBeforeMinDate = ({
  startDate,
  startTime,
  allDayEvent,
  eventStartDateTime,
  errorMessage = 'error'
}: any) => {
  if (!allDayEvent) {
    const mStartDateTime = moment(`${startDate} ${startTime}`, DATE_TIME_PICKER_FORMAT_DEFAULT, true);
    const mMinDate = getMinDateTime(eventStartDateTime);

    if (mStartDateTime.isBefore(mMinDate, 'minutes')) {
      return errorMessage;
    }
  }
  return '';
};

export const eventDateSchemaBuilder = () =>
  yup.string().test('invalidEventDate', '', (eventDate, testContext) => {
    const errorMessage = validateDate({
      date: eventDate,
      invalidMessage: STRINGS.ERRORS_MESSAGE.EVENT_DATE.INVALID,
      requiredMessage: STRINGS.ERRORS_MESSAGE.EVENT_DATE.REQUIRED
    });

    return isEmpty(errorMessage)
      ? true
      : testContext.createError({
          path: testContext.path,
          message: errorMessage
        });
  });

export const startDateSchemaBuilder = () =>
  yup.string().test('invalidStartDate', '', (startDate, testContext) => {
    const { startTime, endTime, endDate } = testContext?.parent ?? {};

    let errorMessage = validateDate({
      date: startDate,
      invalidMessage: STRINGS.ERRORS_MESSAGE.START_DATE.INVALID,
      requiredMessage: STRINGS.ERRORS_MESSAGE.START_DATE.REQUIRED
    });

    if (
      isEmpty(errorMessage) &&
      !validateTime({ time: startTime }) &&
      !validateTime({ time: endTime }) &&
      isStartAfterEndDateTime({
        startDate,
        startTime,
        endDate,
        endTime,
        unit: 'days',
        allowSame: true
      })
    ) {
      errorMessage = STRINGS.ERRORS_MESSAGE.START_DATE.BEFORE_END_DATE;
    }

    return isEmpty(errorMessage)
      ? true
      : testContext.createError({
          path: testContext.path,
          message: errorMessage
        });
  });

export const endDateSchemaBuilder = () =>
  yup.string().test('invalidEndDate', '', (endDate, testContext) => {
    const { startTime, endTime, startDate } = testContext?.parent ?? {};

    let errorMessage = validateDate({
      date: endDate,
      invalidMessage: STRINGS.ERRORS_MESSAGE.END_DATE.INVALID,
      requiredMessage: STRINGS.ERRORS_MESSAGE.END_DATE.REQUIRED
    });

    if (
      isEmpty(errorMessage) &&
      !validateTime({ time: startTime }) &&
      !validateTime({ time: endTime }) &&
      isStartAfterEndDateTime({
        startDate,
        startTime,
        endDate,
        endTime,
        unit: 'days',
        allowSame: true
      })
    ) {
      errorMessage = STRINGS.ERRORS_MESSAGE.END_DATE.AFTER_START_DATE;
    }

    return isEmpty(errorMessage)
      ? true
      : testContext.createError({
          path: testContext.path,
          message: errorMessage
        });
  });

export const startTimeSchemaBuilder = () =>
  yup.string().test('invalidStartTime', '', (startTime, testContext) => {
    const { activityType } = testContext.options?.context ?? {};
    const { startDate, endTime, endDate, allDayEvent, eventStartDateTime } = testContext?.parent ?? {};
    const isFundraiser = activityType === FUNDRAISER;

    if (isFundraiser) return true;

    let errorMessage = validateTime({
      time: startTime,
      invalidMessage: STRINGS.ERRORS_MESSAGE.START_TIME.INVALID,
      requiredMessage: STRINGS.ERRORS_MESSAGE.START_TIME.REQUIRED
    });

    if (
      isEmpty(errorMessage) &&
      !validateDate({ date: startDate }) &&
      !validateDate({ date: endDate }) &&
      !isStartAfterEndDateTime({
        startDate,
        startTime,
        endDate,
        endTime,
        unit: 'days',
        allowSame: true
      }) &&
      isStartAfterEndDateTime({
        startDate,
        startTime,
        endDate,
        endTime,
        unit: 'seconds'
      })
    ) {
      errorMessage = STRINGS.ERRORS_MESSAGE.START_TIME.BEFORE_END_TIME;
    }

    if (
      isEmpty(errorMessage) &&
      isStartBeforeMinDate({
        startDate,
        startTime,
        allDayEvent,
        eventStartDateTime
      })
    ) {
      errorMessage = STRINGS.ERRORS_MESSAGE.START_TIME.INVALID;
    }

    return isEmpty(errorMessage)
      ? true
      : testContext.createError({
          path: testContext.path,
          message: errorMessage
        });
  });

export const endTimeSchemaBuilder = () =>
  yup.string().test('invalidEndTime', '', (endTime, testContext) => {
    const { startDate, startTime, endDate } = testContext?.parent ?? {};

    let errorMessage = validateTime({
      time: endTime,
      invalidMessage: STRINGS.ERRORS_MESSAGE.END_TIME.INVALID,
      requiredMessage: STRINGS.ERRORS_MESSAGE.END_TIME.REQUIRED
    });

    if (
      isEmpty(errorMessage) &&
      !validateDate({ date: startDate }) &&
      !validateDate({ date: endDate }) &&
      !isStartAfterEndDateTime({
        startDate,
        startTime,
        endDate,
        endTime,
        unit: 'days',
        allowSame: true
      }) &&
      isStartAfterEndDateTime({
        startDate,
        startTime,
        endDate,
        endTime,
        unit: 'seconds'
      })
    ) {
      errorMessage = STRINGS.ERRORS_MESSAGE.END_TIME.AFTER_START_TIME;
    }

    return isEmpty(errorMessage)
      ? true
      : testContext.createError({
          path: testContext.path,
          message: errorMessage
        });
  });

export const validateDateTime = yup.object().shape({
  eventDate: eventDateSchemaBuilder(),
  startDate: startDateSchemaBuilder(),
  startTime: startTimeSchemaBuilder(),
  endDate: endDateSchemaBuilder(),
  endTime: endTimeSchemaBuilder()
});
