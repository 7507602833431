import moment from 'moment';
import { isEmpty, get, omit, pick, isNil, has } from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import {
  DATE_FORMAT,
  DATE_FORMAT_DEFAULT_WITH_TIME,
  DATE_FORMAT_DEFAULT_WITH_TIMEZONE,
  DATE_PICKER_FORMAT_DEFAULT,
  DATE_TIME_PICKER_FORMAT_DEFAULT
} from '@gofan/constants/date';
import { getUniqueId, stringToNumber } from '@gofan/utils';
import { DateUtils } from '@gofan/utils/date';
import { canEdit, isCoach, isInternalUser } from '../users';
import {
  ARBITER_PARTNER_NAME,
  BROADCAST_START_TIME_OPTION,
  EMPTY_NUMBER,
  EMPTY_STRING,
  EVENT_START_TIME_TYPE,
  EVENT_TYPES,
  FUNDRAISER,
  GENDER,
  VIEW_EVENT_STATUS,
  CONCESSION,
  MOBILE_PASS,
  START_DATE_LIMIT_DAYS
} from '@gofan/constants/event';
import {
  PRODUCT_TYPE,
  COMPLIMENTARY_PASS,
  COMPLIMENTARY_TICKET,
  DISTRIBUTION_CHANNEL,
  REQUIRED_FIELDS_OF_PRODUCT,
  OMIT_BOX_OFFICE_PRODUCT_FIELDS,
  ALLOWED_FIELDS_FOR_SOLD_PRODUCT,
  config,
  ADMIN_MAX_DATE,
  USER_MAX_DATE,
  PARTNER_LEVELS_MAPPING
} from '@gofan/constants';

import { ExpandRequest } from '../shared/model';
import { AccountService } from '../accounts/account.service';
import { EventRepository } from './event.repository';
import { UnityService } from '../unity';

import type {
  EventDTO,
  EventTicketSale,
  EventSearchParams,
  EventCountByType,
  EventPageResponseDTO,
  EventScheduleSearchDTO,
  EventCancelDTO,
  EventBulkUpload,
  TeamEventTicketSale,
  EventSalesInfoDTO,
  ProductSalesMap,
  EventRescheduleDTO,
  GlobalEventStatus
} from './event.model';
import type { EventProduct } from '@gofan/api/products';
import type { ActivityDTO } from '../activities';
import type { LevelDTO } from '../levels';
import type { UserDTO } from '../users';
import type { VenueDTO } from '../venues';
import type { GameDTO } from '../unity';
import type { AccountDTO } from '../accounts';

dayjs.extend(utc);
dayjs.extend(timezone);

export class EventService {
  static getEventById = (eventId: string | number, queryParams?: any): Promise<EventDTO> => {
    const expandQuery = new ExpandRequest(queryParams?.expands ?? []).toQueryString();
    const queryStr = `?ts=${new Date().getTime()}&skipCache=true&${expandQuery}`;
    return EventRepository.getEventById(eventId, queryStr);
  };

  static getExpandEventById = async (
    eventId: string | number,
    queryParams?: any,
    expand?: any[]
  ): Promise<EventDTO> => {
    const resEvent = await EventService.getEventById(eventId, queryParams);

    const newEvent = await EventService.getExpandEvent(resEvent, expand);
    return newEvent;
  };

  static getEventCountByType = async (searchParams: EventSearchParams): Promise<EventCountByType> => {
    const queryStr = `?ts=${new Date().getTime()}&skipCache=true`;
    const result = await EventRepository.getEventCountByType(searchParams?.body, queryStr);
    const totalElements = Object.values(result ?? {}).reduce((a, b) => a + b, 0);
    return {
      ...result,
      totalElements
    };
  };

  static searchEventByParams = (searchParams: EventSearchParams, options?: any): Promise<EventPageResponseDTO> => {
    const pageQuery = `page=${searchParams?.page ?? 0}&size=${searchParams?.pageSize ?? 1}`;
    const sortableQuery = !isEmpty(searchParams?.sortBy)
      ? `sort=${searchParams?.sortBy?.id ?? 'startDateTime'},${searchParams?.sortBy?.desc ? 'desc' : 'asc'}`
      : '';
    const expandQuery = new ExpandRequest(searchParams?.expands ?? []).toQueryString();

    const timestamp = options?.timestamp ?? new Date().getTime();
    const queryStr = `?ts=${timestamp}&skipCache=true&${pageQuery}&${sortableQuery}&${expandQuery}`;
    return EventRepository.searchEventByParams(searchParams?.body, queryStr);
  };

  static searchAllEvents = (searchParams: EventSearchParams): Promise<EventPageResponseDTO> => {
    const defaultEndDate = moment(new Date())
      .subtract(5, 'days')
      .set('hour', 0)
      .set('minutes', 0)
      .set('seconds', 1)
      .format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE);
    const newParams = {
      ...searchParams,
      body: {
        ...searchParams.body,
        endingAfterEndDate: defaultEndDate
      }
    };
    return this.searchEventByParams(newParams);
  };

  static searchTodayEvents = (searchParams: EventSearchParams): Promise<EventPageResponseDTO> => {
    const newParams = {
      ...searchParams,
      body: {
        ...searchParams.body,
        startDate: DateUtils.getStartOfDate().format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
        endDate: DateUtils.getEndOfDate().format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE)
      }
    };

    return this.searchEventByParams(newParams);
  };

  static getExpandEvent = async (event: EventDTO, expands?: any[]): Promise<EventDTO> => {
    const expandRequests: any[] = [];

    if (expands?.includes('awayAccount')) {
      const awayId = event?.opponentAccountId ?? event?.featuredAccountIds?.[0];
      if (awayId) {
        expandRequests.push(AccountService.getAccountById(awayId).then(awayAccount => ({ awayAccount })));
      }
    }

    const expandData = await Promise.all(expandRequests);

    return {
      ...event,
      _embedded: {
        ...get(event, '_embedded', {}),
        ...expandData?.reduce((res, curExpandData: any) => ({ ...res, ...curExpandData }), {})
      }
    };
  };

  static searchExpandEvents = async (
    searchParams: EventSearchParams,
    expands?: any[]
  ): Promise<EventPageResponseDTO> => {
    const resEvents: EventPageResponseDTO = await EventService.searchEventByParams(searchParams);

    const newContents = await Promise.all(
      (resEvents?.content ?? []).map(event => EventService.getExpandEvent(event, expands))
    );

    return {
      ...resEvents,
      content: newContents
    };
  };

  static ignoreAwayEvent = (eventId: string | number): Promise<EventDTO> => EventRepository.ignoreAwayEvent(eventId);

  static declineAwayEvent = (eventId: string | number): Promise<EventDTO> => EventRepository.declineAwayEvent(eventId);

  static publishAwayEvent = (eventId: string | number): Promise<EventDTO> => EventRepository.publishAwayEvent(eventId);

  static getEventMonitorIndicator = (
    eventScheduleSearch: EventScheduleSearchDTO,
    manualErrorHandling = true
  ): Promise<boolean> => EventRepository.getEventMonitorIndicator(eventScheduleSearch, manualErrorHandling);

  static getEventHistory = (id: string, dateRange?: 'lastWeek' | 'lastMonth' | 'all') =>
    EventRepository.getEventHistory(id, dateRange);

  static createEvent = (event: EventDTO) => EventRepository.createEvent(event);

  static updateEvent = (event: EventDTO): Promise<EventDTO> =>
    EventRepository.updateEvent(omit(event, ['_embedded', '_links']) as EventDTO);

  static partialUpdateEvent = (event: Partial<EventDTO>, options?: { checkDupes?: boolean }): Promise<EventDTO> =>
    EventRepository.partialUpdateEvent(omit(event, ['_embedded', '_links']) as EventDTO, options);

  static partialUpdateVisibility = (event: EventDTO): Promise<EventDTO> =>
    this.partialUpdateEvent(pick(event, ['id', 'accountId', 'financialAccountId', 'name', 'archived']) as EventDTO);

  static getTicketSaleInsightData = (eventId: number | string, options?: any): Promise<EventTicketSale> => {
    const timestamp = options?.timestamp ?? new Date().getTime();
    const queryStr = options?.noCache ? `?ts=${timestamp}` : '';
    return EventRepository.getTicketSaleInsightData(eventId, queryStr);
  };

  static getTeamTicketSaleInsightData = (eventType: string): Promise<TeamEventTicketSale> =>
    EventRepository.getTeamTicketSaleInsightData(eventType);

  static getEventSalesInfo = (eventId: number): Promise<EventSalesInfoDTO> =>
    EventRepository.getEventSalesInfo(eventId);

  static partialUpdateProducts = (event: EventDTO): Promise<EventDTO> =>
    this.partialUpdateEvent(pick(event, ['id', 'accountId', 'financialAccountId', 'name', 'products']) as EventDTO);

  static isOverEventStartDate = (event = {} as EventDTO): boolean => {
    const timeZone = DateUtils.getTimeZone(event?.timeZone);
    if (isEmpty(event)) return true;
    const now = DateUtils.formatDateTime({
      date: new Date(),
      parseZone: true,
      timeZone
    }).toDate(DATE_FORMAT_DEFAULT_WITH_TIME);
    const startDate = DateUtils.formatDateTime({
      date: event?.startDateTime,
      parseZone: true,
      timeZone
    }).toDate(DATE_FORMAT_DEFAULT_WITH_TIME);
    return now > startDate;
  };

  static isOverEventEndDate = (event = {} as EventDTO): boolean => {
    if (isEmpty(event)) return true;
    const timeZone = DateUtils.getTimeZone(event?.timeZone);
    const now = DateUtils.formatDateTime({
      date: new Date(),
      parseZone: true,
      timeZone
    }).toDate(DATE_FORMAT_DEFAULT_WITH_TIME);
    const endDate = DateUtils.formatDateTime({
      date: event?.endDateTime,
      parseZone: true,
      timeZone
    }).toDate(DATE_FORMAT_DEFAULT_WITH_TIME);
    return now > endDate;
  };

  static isOverFutureEventDate = (eventDate?: string, timeZone?: string): boolean => {
    if (isEmpty(eventDate)) return true;
    const now = DateUtils.convertDateWithTimeZone(new Date(), timeZone);
    const date = DateUtils.convertDateWithTimeZone(eventDate, timeZone);
    return date.add(90, 'days').isBefore(now);
  };

  static getEventType = (event: EventDTO) => {
    const { redemptionWindow = null, products = [] } = event ?? {};
    const activity: ActivityDTO = get(event, '_embedded.activity', {}) ?? {};
    if (isEmpty(event) || (isEmpty(activity) && redemptionWindow === null)) {
      return EVENT_TYPES.ATHLETIC;
    }
    const isMobilePass =
      (isEmpty(products) && redemptionWindow !== null) ||
      products?.some(ticket => ticket?.productType === 'MOBILEPASS');

    const isFundraiser = EventService.isFundraiser(event);

    let eventType = EVENT_TYPES.ATHLETIC;
    if (isMobilePass) {
      eventType = EVENT_TYPES.MOBILE_PASS;
    } else if (EVENT_TYPES.CONFIG[EVENT_TYPES.PERFORMING_ART].ACTIVITIES.includes(activity?.label)) {
      eventType = EVENT_TYPES.PERFORMING_ART;
    } else if (EVENT_TYPES.CONFIG[EVENT_TYPES.REGISTRATION].ACTIVITIES.includes(activity?.label)) {
      eventType = EVENT_TYPES.REGISTRATION;
    } else if (EVENT_TYPES.CONFIG[EVENT_TYPES.SCHOOL_DANCE].ACTIVITIES.includes(activity?.label)) {
      eventType = EVENT_TYPES.SCHOOL_DANCE;
    } else if (isFundraiser) {
      eventType = EVENT_TYPES.FUNDRAISER;
    } else if (!activity?.athletic) {
      eventType = EVENT_TYPES.OTHER;
    }
    return eventType;
  };

  static checkEventPast = (event: EventDTO) => {
    const now = moment();
    const evDate = moment.isMoment(event.endDateTime) ? event.endDateTime : moment(event.endDateTime);

    if (now.isAfter(evDate)) {
      return true;
    }

    return false;
  };

  static checkEventBeforeStart = (event: EventDTO) => {
    const {
      enableEventValidation,
      startDateTime,
      eventValidationStartsBefore,
      products = [],
      redemptionWindow = null
    } = event;
    const isMobilePass =
      (isEmpty(products) && redemptionWindow !== null) || products.some(ticket => ticket.productType === 'MOBILEPASS');
    const eventStartDate = moment(startDateTime);

    if (isMobilePass) {
      return eventStartDate.isValid() && eventStartDate.isBefore(moment.utc(), 'minutes');
    }

    if (enableEventValidation) {
      const next6Hours = moment.utc().add(eventValidationStartsBefore, 'minutes');
      return eventStartDate.isValid() && eventStartDate.isBefore(next6Hours, 'minutes');
    }

    return true;
  };

  static cancelEvent = (body: EventCancelDTO) => EventRepository.cancelEvent(body);

  static filterProductsByTicketDistribute = ({
    event,
    excludeDistributionChannels
  }: {
    event: EventDTO;
    excludeDistributionChannels?: any[];
  }) =>
    event?.products?.filter(ticket => {
      if (excludeDistributionChannels?.includes(ticket?.distributionChannel)) return false;
      if (event?.ticketDistribution) return !!ticket?.accountId;
      if (!event?.ticketDistribution) return !ticket?.accountId;
      return true;
    }) ?? [];

  static isFundraiser = (event: EventDTO) => {
    const activity: ActivityDTO = get(event, '_embedded.activity', get(event, 'embedded.activity', {})) ?? {};
    return activity?.label?.toLowerCase() === FUNDRAISER.toLowerCase();
  };

  static getEventTypeByProductType = (event: EventDTO) => {
    const { products = [], redemptionWindow = null } = event ?? {};

    // check event type product type for fundraiser/concession/mobile pass
    if (products?.some(ticket => ticket?.productType === PRODUCT_TYPE.MOBILEPASS)) {
      return MOBILE_PASS;
    }
    if (products?.some(ticket => ticket?.productType === PRODUCT_TYPE.CONCESSION)) {
      return CONCESSION;
    }
    if (
      products?.some(ticket =>
        [PRODUCT_TYPE.DONATION, PRODUCT_TYPE.DONATION_ONGOING].includes(`${ticket?.productType}`)
      )
    ) {
      return FUNDRAISER;
    }

    // Check event type by activity for the rests
    const activity: ActivityDTO = get(event, '_embedded.activity', {}) ?? {};
    if (isEmpty(event) || (isEmpty(activity) && redemptionWindow === null)) {
      return EVENT_TYPES.ATHLETIC;
    }
    let eventType = EVENT_TYPES.ATHLETIC;
    if (EVENT_TYPES.CONFIG[EVENT_TYPES.PERFORMING_ART].ACTIVITIES.includes(activity?.label)) {
      eventType = EVENT_TYPES.PERFORMING_ART;
    } else if (EVENT_TYPES.CONFIG[EVENT_TYPES.REGISTRATION].ACTIVITIES.includes(activity?.label)) {
      eventType = EVENT_TYPES.REGISTRATION;
    } else if (EVENT_TYPES.CONFIG[EVENT_TYPES.SCHOOL_DANCE].ACTIVITIES.includes(activity?.label)) {
      eventType = EVENT_TYPES.SCHOOL_DANCE;
    } else if (!activity?.athletic) {
      eventType = EVENT_TYPES.OTHER;
    }
    return eventType;
  };

  static getDonationType = (event: EventDTO) => {
    const { products = [] } = event;
    if (products.some(ticket => ticket.productType === PRODUCT_TYPE.DONATION_ONGOING)) {
      return PRODUCT_TYPE.DONATION_ONGOING;
    }

    return PRODUCT_TYPE.DONATION;
  };

  static getEventLabelByType = (event: EventDTO) => {
    const products = event?.products ?? [];
    if (
      products.some(
        product =>
          product.productType && [PRODUCT_TYPE.DONATION, PRODUCT_TYPE.DONATION_ONGOING].includes(product.productType)
      )
    ) {
      return EVENT_TYPES.FUNDRAISER.toLowerCase();
    }

    if (products.some(product => product.productType === PRODUCT_TYPE.CONCESSION)) {
      return PRODUCT_TYPE.CONCESSION.toLowerCase();
    }

    return 'event';
  };

  static bulkUploadEvents = (payload: EventBulkUpload) => EventRepository.bulkUploadEvents(payload);

  static getStatusBulkUploadEvents = (batchId: string) => EventRepository.getStatusBulkUploadEvents(batchId);

  static populateFeeAndRateId(
    product: EventProduct | any,
    event: EventDTO | any,
    view: 'Edit event' | 'Create event' | 'Edit event mobile' | 'Edit event season',
    emptyValue: null | -1 | undefined = null
  ) {
    if (view === 'Create event') {
      const isFeeUpdated = product?.fee && product?.fee > 0;
      return { fee: isFeeUpdated ? product?.fee : undefined, rateId: isFeeUpdated ? null : undefined };
    }
    if (view === 'Edit event') {
      let rateId = product?.rateId || get(product, 'restData.rateId');
      let feeValue = product?.fee || emptyValue;
      if (product.created) {
        const eventProduct = event?.products?.find(item => `${item.id}` === `${product.id}`);
        const eventProductFee = eventProduct?.hiddenFees
          ? get(eventProduct, 'restData.hiddenFeeBase', get(eventProduct, 'hiddenFeeBase'))
          : eventProduct?.fee;
        const isFeeChanged = eventProductFee !== product?.fee;
        const isPriceChanged = eventProduct?.price !== product?.price;

        // admin manual change fee to new value ==> set rateId = null
        if (!isEmpty(eventProduct) && isFeeChanged) {
          rateId = emptyValue;
        }

        // admin change price of custom fee ticket
        // ==> FE pass fee = emptyValue(null for normal case and -1 for partial update event) for api recaculate fee.
        if (rateId === null && !isFeeChanged && isPriceChanged) {
          feeValue = emptyValue;
        }
      } else {
        // In edit event mode and product.created === false(duplicate ticket or duplicate event fee will be re-caculated by api)
        // ==> FE pass fee = emptyValue
        feeValue = emptyValue;
        // Set rateId when create Ticket for turn ticket distribution from on to off
        const eventDProduct = event?.products?.find(item => `${item.id}` === `${product.clonedFormProductId}`);
        const eventDProductFee = eventDProduct?.hiddenFees
          ? get(eventDProduct, 'restData.hiddenFeeBase')
          : eventDProduct?.fee;

        // in case manual apply fee set rateId = null
        if (!isEmpty(eventDProduct) && eventDProductFee !== product?.fee) {
          feeValue = product?.fee;
          rateId = emptyValue;
        }
      }
      return { fee: feeValue, rateId };
    }
    if (view === 'Edit event mobile') {
      const ticketFee = !isEmpty(product?.fee) ? parseFloat(product?.fee) : emptyValue;
      const eventProduct = event?.products?.find((t: any) => `${t.id}` === `${product.id}`);
      const productFee = eventProduct?.hiddenFees ? eventProduct?.hiddenFeeBase : eventProduct?.fee;
      const rateId = productFee !== ticketFee ? emptyValue : undefined;
      return { fee: productFee !== ticketFee ? ticketFee : undefined, rateId };
    }
    if (view === 'Edit event season') {
      let rateId = product?.rateId ?? emptyValue;
      const foundProduct = event?.products?.find((item: any) => `${item.id}` === `${product.id}`);
      const productFee = foundProduct?.hiddenFees ? foundProduct?.hiddenFeeBase : foundProduct?.fee;
      if (!isEmpty(foundProduct) && productFee !== product?.fee) {
        rateId = emptyValue;
      }
      return { fee: product?.fee, rateId };
    }
    return {};
  }

  static getEventVenueAddress = (event?: EventDTO) =>
    [event?.venueAddress, event?.venueCity, event?.venueState, event?.venueZip].filter(p => !isEmpty(p)).join(', ');

  static getViewEventStatus = (event?: EventDTO) => {
    if (!event) return '';
    const currentDateTime = moment();
    if (currentDateTime.isBefore(moment(event.startDateTime))) {
      return VIEW_EVENT_STATUS.PREV;
    }

    if (currentDateTime.isAfter(moment(event.endDateTime))) {
      return VIEW_EVENT_STATUS.POST;
    }

    return VIEW_EVENT_STATUS.DURING;
  };

  static mappingEventTicket = (products: EventProduct[]) => {
    const goFanTickets = products.filter(
      product =>
        product.distributionChannel === DISTRIBUTION_CHANNEL.GOFAN &&
        product.name !== COMPLIMENTARY_TICKET &&
        product.name !== COMPLIMENTARY_PASS
    );
    const boxOfficeTickets = products.filter(
      product =>
        product.distributionChannel === DISTRIBUTION_CHANNEL.BOXOFFICE &&
        product.name !== COMPLIMENTARY_TICKET &&
        product.name !== COMPLIMENTARY_PASS
    );

    let filteredBoxOfficeTickets = [...boxOfficeTickets];
    const dataProducts: any[] = [];
    goFanTickets.forEach(product => {
      const boxOfficeTicket = filteredBoxOfficeTickets.find(({ groupId }) => groupId === product.groupId);

      const ticket = boxOfficeTicket
        ? {
            ...product,
            idBoxOffice: boxOfficeTicket.id,
            enabledTicketBoxOffice: boxOfficeTicket.enabled,
            nameBoxOffice: boxOfficeTicket.name,
            priceBoxOffice: boxOfficeTicket.price,
            productType: boxOfficeTicket.productType
          }
        : { ...product };
      dataProducts.push(ticket);
      filteredBoxOfficeTickets = filteredBoxOfficeTickets.filter(item => item.id !== boxOfficeTicket?.id);
    });

    if (filteredBoxOfficeTickets.length > 0) {
      filteredBoxOfficeTickets.forEach(product => {
        const overrideGoFanInfo = {
          accountId: product.accountId,
          name: product.name,
          price: product.price,
          enabled: product.enabled,
          hiddenFees: product.hiddenFees,
          productType: product.productType
        };
        dataProducts.push({
          ...overrideGoFanInfo,
          idBoxOffice: product.id,
          enabledTicketBoxOffice: product.enabled,
          nameBoxOffice: product.name,
          priceBoxOffice: product.price
        });
      });
    }
    return dataProducts;
  };

  static mappingCreateEventValues = (event: Partial<EventDTO>) => {
    const eventTimeZone = DateUtils.getTimeZone(event?.timeZone);
    const now = dayjs();
    let mStartDateTime = dayjs(event.startDateTime, DATE_TIME_PICKER_FORMAT_DEFAULT).set('seconds', 1);
    let mEndDateTime = dayjs(event.endDateTime, DATE_TIME_PICKER_FORMAT_DEFAULT).set('seconds', 1);
    if (event.allDayEvent) {
      mStartDateTime = mStartDateTime.clone().startOf('days').set('second', 1);
      mEndDateTime = mEndDateTime.clone().endOf('days').set('second', 1);
    }
    const mEventStartDateTime = mStartDateTime.isAfter(now)
      ? mStartDateTime
      : now.clone().endOf('hour').set('seconds', 1);
    const eventStartDateTime = DateUtils.switchZone(
      mEventStartDateTime.format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
      eventTimeZone
    );
    const mEventEndDateTime = mEndDateTime.isAfter(now)
      ? mEndDateTime
      : now.clone().add(4, 'hours').endOf('hour').set('seconds', 1);
    const eventEndDateTime = DateUtils.switchZone(
      mEventEndDateTime.format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
      eventTimeZone
    );

    let eventPublishDateTime: string | null = null;
    if (event.archived && !isEmpty(event.publishDateTime)) {
      const mPublishDateTime = dayjs(event.publishDateTime, DATE_TIME_PICKER_FORMAT_DEFAULT).set('seconds', 1);
      eventPublishDateTime = DateUtils.switchZone(
        mPublishDateTime.format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
        eventTimeZone
      );
    }

    return {
      ...omit(event, [
        'eventName',
        'account',
        'financialAccount',
        'opponentAccount',
        'taggedAccounts',
        'unlimitedTicket'
      ]),
      name: event.eventName,
      startDateTime: eventStartDateTime,
      endDateTime: eventEndDateTime,
      publishDateTime: eventPublishDateTime
    };
  };

  static mappingPatchEventValues = (event: Partial<EventDTO>, originEvent: EventDTO) => {
    const eventTimeZone = DateUtils.getTimeZone(event?.timeZone ?? originEvent?.timeZone);
    const name = event?.eventName;
    const now = moment().tz(eventTimeZone);
    let salesStartDateTime = has(event, 'salesStartDateTime') ? '' : undefined;
    if (!isEmpty(event?.salesStartDateTime)) {
      const mSalesStartDateTime = moment(event?.salesStartDateTime, DATE_TIME_PICKER_FORMAT_DEFAULT).set('seconds', 1);
      salesStartDateTime = DateUtils.switchZone(
        mSalesStartDateTime.format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
        eventTimeZone
      );
    }
    let salesEndDateTime = has(event, 'salesEndDateTime') ? '' : undefined;
    if (!isEmpty(event?.salesEndDateTime)) {
      const mSalesEndDateTime = moment(event?.salesEndDateTime, DATE_TIME_PICKER_FORMAT_DEFAULT).set('seconds', 1);
      salesEndDateTime = DateUtils.switchZone(
        mSalesEndDateTime.format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
        eventTimeZone
      );
    }

    const isFundraiser = this.getEventTypeByProductType(originEvent) === EVENT_TYPES.FUNDRAISER;
    let startDateTime;
    if (!isEmpty(event?.startDateTime)) {
      if (isFundraiser) {
        startDateTime = EventService.generateFundraiserDateTime({
          mode: 'edit',
          date: event.startDateTime ?? '',
          eventDate: originEvent?.startDateTime,
          timeZone: eventTimeZone,
          hours: 0,
          minutes: 1,
          seconds: 1,
          type: 'startDateTime'
        });
      } else {
        const formattedStartDateTime = DateUtils.formatDateTime({
          date: originEvent?.startDateTime,
          timeZone: eventTimeZone,
          parseZone: true
        });
        const eventStartDateTime = DateUtils.switchZone(
          formattedStartDateTime.toDate(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
          'localZone'
        );
        const mEventStartDateTime = moment(eventStartDateTime, DATE_FORMAT_DEFAULT_WITH_TIMEZONE).set('second', 1);

        const mStartDateTime = moment(event?.startDateTime, DATE_TIME_PICKER_FORMAT_DEFAULT).set('second', 1);

        if (event?.allDayEvent) {
          const mNewStartDateTime = mStartDateTime.clone().startOf('day').set('minute', 1).set('second', 1);

          const newStartDateUTC = mNewStartDateTime.clone().format(DATE_FORMAT);
          const eventStartDateUTC = mEventStartDateTime.clone().format(DATE_FORMAT);

          if (eventStartDateUTC !== newStartDateUTC) {
            const newStartDateTime = mStartDateTime.isAfter(now, 'day')
              ? mNewStartDateTime.clone().set('seconds', 1).format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE)
              : now.clone().endOf('hour').set('seconds', 1).format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE);

            startDateTime = DateUtils.switchZone(newStartDateTime, eventTimeZone);
          }
        } else {
          const newStartDateTimeUTC = mStartDateTime.clone().set('seconds', 1).format();
          const eventStartDateTimeUTC = mEventStartDateTime.clone().format();
          if (eventStartDateTimeUTC !== newStartDateTimeUTC) {
            startDateTime = DateUtils.switchZone(
              mStartDateTime.clone().set('seconds', 1).format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
              eventTimeZone
            );
          }
        }
      }
    }

    let endDateTime;
    if (!isEmpty(event?.endDateTime)) {
      if (isFundraiser) {
        endDateTime = EventService.generateFundraiserDateTime({
          mode: 'edit',
          date: event.endDateTime ?? '',
          eventDate: originEvent?.endDateTime,
          timeZone: eventTimeZone,
          hours: 23,
          minutes: 59,
          seconds: 1,
          type: 'endDateTime'
        });
      } else {
        const formattedEndDateTime = DateUtils.formatDateTime({
          date: originEvent?.endDateTime,
          timeZone: eventTimeZone,
          parseZone: true
        });
        const eventEndDateTime = DateUtils.switchZone(
          formattedEndDateTime.toDate(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
          'localZone'
        );
        const mEventEndDateTime = moment(eventEndDateTime, DATE_FORMAT_DEFAULT_WITH_TIMEZONE).set('second', 1);

        const mEndDateTime = moment(event?.endDateTime, DATE_TIME_PICKER_FORMAT_DEFAULT).set('seconds', 1);
        if (event?.allDayEvent) {
          const mNewEndDateTime = mEndDateTime.clone().endOf('day').set('second', 1);

          const newEndDateUTC = mNewEndDateTime.clone().format(DATE_FORMAT);
          const eventEndDateUTC = mEventEndDateTime.clone().format(DATE_FORMAT);

          if (eventEndDateUTC !== newEndDateUTC) {
            endDateTime = DateUtils.switchZone(
              mNewEndDateTime.clone().format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
              eventTimeZone
            );
          }
        } else {
          const newEndDateTimeUTC = mEndDateTime.clone().set('second', 1).format();
          const eventEndDateTimeUTC = mEventEndDateTime.clone().format();
          if (eventEndDateTimeUTC !== newEndDateTimeUTC) {
            endDateTime = DateUtils.switchZone(
              mEndDateTime.clone().set('second', 1).format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
              eventTimeZone
            );
          }
        }
      }
    }

    let publishDateTime;
    if (event?.archived && !isEmpty(event?.publishDateTime)) {
      const mPublishDateTime = dayjs(event.publishDateTime, DATE_TIME_PICKER_FORMAT_DEFAULT).set('seconds', 1);
      publishDateTime = DateUtils.switchZone(mPublishDateTime.format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE), eventTimeZone);
    }

    let eventIntegrationDetails;
    if (!isEmpty(event?.eventIntegrationDetails)) {
      eventIntegrationDetails =
        event?.eventIntegrationDetails?.map(item => {
          const vodBlackoutStartDate = item.vodBlackoutStartDate
            ? DateUtils.switchZone(
                dayjs(item.vodBlackoutStartDate).hour(0).minute(1).format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
                eventTimeZone
              )
            : null;
          const vodBlackoutEndDate = item.vodBlackoutEndDate
            ? DateUtils.switchZone(
                dayjs(item.vodBlackoutEndDate).hour(23).minute(59).format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
                eventTimeZone
              )
            : null;

          let levelName = item.level;
          if (Object.values(PARTNER_LEVELS_MAPPING).includes(`${item.level ?? ''}`.toLowerCase())) {
            const originEventIntegrationDetail = originEvent?.eventIntegrationDetails?.find(origin =>
              UnityService.comparePartnerTeam(origin, item as unknown as GameDTO)
            );
            levelName = originEventIntegrationDetail?.level ?? item.level;
          }

          return {
            ...item,
            level: levelName,
            vodBlackoutStartDate,
            vodBlackoutEndDate
          };
        }) ?? [];
    }

    const partialEvent = {
      ...omit({ ...(event ?? {}) }, [
        'eventName',
        'account',
        'opponentAccount',
        'financialAccount',
        'quickUpdateData',
        'isDistributed',
        'unlimitedTicket',
        'complimentTickets',
        'enabledOptionSetting'
      ]),
      formFields: [],
      name,
      salesStartDateTime,
      salesEndDateTime,
      startDateTime,
      endDateTime,
      publishDateTime,
      levels: !isNil(event?.levels) ? event?.levels.map(item => omit(item, 'levelName')) : undefined,
      eventIntegrationDetails
    };

    const partialEventProducts = EventService.mappingPatchProductValues({ event, originEvent });
    return { ...partialEvent, ...partialEventProducts };
  };

  static mappingPatchProductValues = ({
    event,
    originEvent,
    isSeasonTicket
  }: {
    event: Partial<EventDTO>;
    originEvent: EventDTO;
    isSeasonTicket?: boolean;
  }) => {
    let partialEvent = {};
    let newProducts = [...(event?.products ?? [])];
    // set maxCapacity = EMPTY_NUMBER when unlimitedTicket is true
    // set products limit = null when unLimetedTicket is true
    if (event?.unlimitedTicket) {
      partialEvent = {
        ...partialEvent,
        maxCapacity: EMPTY_NUMBER
      };
      newProducts = newProducts.map(product => ({ ...product, limit: null }));
    }

    // mapping products
    const productSalesMap: ProductSalesMap = get(
      originEvent,
      isSeasonTicket ? `_embedded.season-sales-info.productSalesMap` : `_embedded.event-sales-info.productSalesMap`,
      {}
    );

    if (!isEmpty(newProducts)) {
      if (!isEmpty(event?.quickUpdateData)) {
        const { boxOffice, onSale, hiddenFee } = event?.quickUpdateData ?? {};

        if (!isEmpty(hiddenFee)) {
          const { productId, boProductId, toggled } = hiddenFee ?? {};
          const gofanProduct = newProducts?.find(product => `${product?.id}` === `${productId}`);
          const boxOfficeProduct = newProducts?.find(product => `${product?.id}` === `${boProductId}`);
          const originGoFanProduct = (originEvent?.products ?? []).find(p => `${p.id}` === `${gofanProduct?.id}`);
          const originBoxOfficeProduct = (originEvent?.products ?? []).find(
            p => `${p.id}` === `${boxOfficeProduct?.id}`
          );

          newProducts = newProducts?.map(product => {
            let originProduct;

            if (`${product?.id}` === `${gofanProduct?.id}`) {
              originProduct = originGoFanProduct;
            } else if (`${product.id}` === `${boxOfficeProduct?.id}`) {
              originProduct = originBoxOfficeProduct;
            } else {
              return pick({ ...product }, REQUIRED_FIELDS_OF_PRODUCT) as EventProduct;
            }

            const feeValue =
              !this.hasTicketSold(originProduct, productSalesMap) && originProduct?.hiddenFees
                ? originProduct?.hiddenFeeBase
                : originProduct?.fee;
            let newTicket: EventProduct = { ...product, fee: feeValue, hiddenFees: toggled };

            let feeData = EventService.populateFeeAndRateId(
              { ...newTicket, created: !isEmpty(originProduct) },
              originEvent,
              'Edit event',
              EMPTY_NUMBER
            );

            if (!isEmpty(originProduct)) {
              const hasSold = EventService.hasTicketSold(originProduct, productSalesMap);
              feeData = hasSold ? {} : feeData;
              const ticket = { ...originProduct, ...newTicket, ...feeData };
              newTicket = hasSold ? pick(ticket, ALLOWED_FIELDS_FOR_SOLD_PRODUCT) : ticket;
            }

            return pick(newTicket, [...REQUIRED_FIELDS_OF_PRODUCT, 'hiddenFees', 'fee', 'rateId']) as EventProduct;
          });
        }

        if (!isEmpty(boxOffice)) {
          const { productId, boProductId, toggled } = boxOffice ?? {};
          const gofanProduct = newProducts?.find(product => `${product?.id}` === `${productId}`);
          const boxOfficeProduct = newProducts?.find(product => `${product?.id}` === `${boProductId}`);

          const newGroupId = gofanProduct?.groupId ? gofanProduct?.groupId : `${getUniqueId()}`;

          newProducts = newProducts?.map(product => {
            let newTicket: EventProduct = { ...product };

            if (`${product.id}` === `${gofanProduct?.id}`) {
              newTicket = { ...product, groupId: newGroupId };
            } else if (`${product.id}` === `${boxOfficeProduct?.id}`) {
              newTicket = { ...product, groupId: newGroupId, enabled: toggled };
            }

            return pick(newTicket, REQUIRED_FIELDS_OF_PRODUCT) as EventProduct;
          });

          if (isEmpty(boxOfficeProduct) && !isEmpty(gofanProduct)) {
            newProducts = [
              ...(newProducts ?? ([] as EventProduct[])),
              omit(
                {
                  ...gofanProduct,
                  distributionChannel: DISTRIBUTION_CHANNEL.BOXOFFICE,
                  groupId: newGroupId,
                  enabled: true
                },
                OMIT_BOX_OFFICE_PRODUCT_FIELDS
              ) as EventProduct
            ];
          }
        }

        if (!isEmpty(onSale)) {
          const { productId, toggled } = onSale ?? {};

          newProducts = newProducts?.map(product => {
            let newTicket: EventProduct = { ...product };

            if (`${product?.id}` === `${productId}`) {
              newTicket = { ...product, enabled: toggled };
            }

            return pick(newTicket, REQUIRED_FIELDS_OF_PRODUCT) as EventProduct;
          });
        }
      } else {
        newProducts = newProducts.reduce((acc, cur) => {
          const isSetDefaultFormId = cur.productType === PRODUCT_TYPE.MOBILEPASS && isEmpty(cur.formFields);
          const isConcession = cur?.productType === PRODUCT_TYPE.CONCESSION;
          const products = [...acc];
          let goFanProduct: any = this.mappingPatchEventProductValues(cur, originEvent);

          // created using for check gofan ticket created or new ticket.
          if (!cur?.created) {
            goFanProduct = omit(goFanProduct, ['id']);
          }

          // Concession only have box office ticket
          if (!isConcession) {
            // push gofan ticket to product arr.
            products.push({ ...goFanProduct, formId: isSetDefaultFormId ? config.MOBILE_PASS_FORM_ID : EMPTY_NUMBER });
          }

          // find origin box office ticket
          const originBOTicket = (originEvent?.products ?? []).find(
            product => `${product?.id}` === `${cur?.idBoxOffice}`
          );
          if (cur?.enabledTicketBoxOffice || originBOTicket) {
            const priceBO = stringToNumber(`${cur?.priceBoxOffice}`);
            const limit = stringToNumber(`${cur?.limit}`, EMPTY_NUMBER);

            let boxOfficeProduct = omit<EventProduct>(
              {
                ...goFanProduct,
                formId: isSetDefaultFormId ? config.MOBILE_PASS_FORM_ID : EMPTY_NUMBER,
                name: cur?.nameBoxOffice,
                price: priceBO,
                enabled: cur?.enabledTicketBoxOffice,
                distributionChannel: DISTRIBUTION_CHANNEL.BOXOFFICE,
                customColor: null,
                generateLink: null,
                limit
              },
              isConcession
                ? OMIT_BOX_OFFICE_PRODUCT_FIELDS.filter(field => field !== 'limit')
                : OMIT_BOX_OFFICE_PRODUCT_FIELDS
            );

            if (!isEmpty(originBOTicket)) {
              boxOfficeProduct = {
                ...boxOfficeProduct,
                id: cur?.idBoxOffice
              };
            }

            // push BO ticket to product arr
            products.push(boxOfficeProduct);
          }
          return products;
        }, [] as any[]);

        // re-assign origin product model
        newProducts = newProducts.map(newProduct => {
          const originProduct = (originEvent?.products ?? []).find(p => p.id === newProduct?.id);
          let feeRateId = EventService.populateFeeAndRateId(
            { ...newProduct, created: !isEmpty(originProduct) },
            originEvent,
            'Edit event',
            EMPTY_NUMBER
          );

          if (!isEmpty(originProduct)) {
            const hasSold = EventService.hasTicketSold(originProduct, productSalesMap);
            feeRateId = hasSold ? {} : feeRateId;
            const ticket = { ...originProduct, ...newProduct, ...feeRateId };
            return hasSold ? pick(ticket, ALLOWED_FIELDS_FOR_SOLD_PRODUCT) : ticket;
          }

          const emptyNumberFields = ['limit', 'fee', 'rateId', 'ticketLimitPerOrder', 'redemptionLimit', 'packCount'];
          const newProductForCreate = Object.keys({ ...newProduct, ...feeRateId }).reduce((acc, cur) => {
            const value = newProduct[cur];
            if (emptyNumberFields.includes(cur) && value === EMPTY_NUMBER) return { ...acc, [cur]: null };
            return { ...acc, [cur]: value };
          }, {} as any);
          return { ...newProductForCreate };
        });

        // add original compliment tickets to product array because ticket_overlay filter out compliment tickets
        const complimentTickets = (originEvent?.products ?? [])
          .filter(product => product?.name === COMPLIMENTARY_TICKET || product?.name === COMPLIMENTARY_PASS)
          .map(product => pick(product, REQUIRED_FIELDS_OF_PRODUCT) as EventProduct);

        if (!isEmpty(complimentTickets)) {
          newProducts = [...complimentTickets, ...newProducts];
        }
      }

      partialEvent = {
        ...partialEvent,
        products: newProducts
      };
    }

    return partialEvent;
  };

  static mappingPatchEventProductValues = (product: any, event: EventDTO) => {
    let salesStartDateTime = EMPTY_STRING;
    let salesEndDateTime = EMPTY_STRING;
    if (!isEmpty(product?.salesStartDate) && !isEmpty(product?.salesStartTime)) {
      const mSalesStartDateTime = moment(
        `${product?.salesStartDate} ${product?.salesStartTime}`.trim(),
        DATE_TIME_PICKER_FORMAT_DEFAULT
      ).set('seconds', 1);
      salesStartDateTime = DateUtils.switchZone(
        mSalesStartDateTime.format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
        DateUtils.getTimeZone(event.timeZone)
      );
    }

    if (!isEmpty(product?.salesEndDate) && !isEmpty(product?.salesEndTime)) {
      const mSalesEndDateTime = moment(
        `${product?.salesEndDate} ${product?.salesEndTime}`.trim(),
        DATE_TIME_PICKER_FORMAT_DEFAULT
      ).set('seconds', 1);
      salesEndDateTime = DateUtils.switchZone(
        mSalesEndDateTime.format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
        DateUtils.getTimeZone(event.timeZone)
      );
    }

    const ticketLimitPerOrder = stringToNumber(product?.ticketLimitPerOrder, EMPTY_NUMBER);

    const redemptionLimit = !product?.unlimitedPass
      ? stringToNumber(product?.redemptionLimit, EMPTY_NUMBER)
      : EMPTY_NUMBER;

    const packCount = product?.enabledPackCount ? stringToNumber(product?.packCount, EMPTY_NUMBER) : EMPTY_NUMBER;

    const price = stringToNumber(product?.price);
    const limit = stringToNumber(product?.limit, EMPTY_NUMBER);
    const fee = stringToNumber(product?.fee);
    const rateId = product?.rateId || get(product, 'restData.rateId');

    return {
      ...omit(product, [
        'created',
        'idBoxOffice',
        'nameBoxOffice',
        'priceBoxOffice',
        'enabledTicketBoxOffice',
        'enabledOptionSetting',
        'salesStartDate',
        'salesStartTime',
        'salesEndDate',
        'salesEndTime',
        'unlimitedPass',
        'enabledPackCount',
        'restData'
      ]),
      price,
      limit,
      fee,
      rateId,
      salesStartDateTime,
      salesEndDateTime,
      ticketLimitPerOrder,
      redemptionLimit,
      packCount
    };
  };

  static hasEventTicketSold = (salesInfo: EventSalesInfoDTO) => {
    if (!isEmpty(salesInfo)) {
      const { eventTotalSaleQuantity } = salesInfo;
      return eventTotalSaleQuantity > 0;
    }
    return false;
  };

  static mappingLevelName({ event, levelKey, levels }: { event?: EventDTO; levelKey: string; levels: LevelDTO[] }) {
    const arrLevel = levelKey?.split('-') ?? [];
    if (!isEmpty(event) && arrLevel.length >= 2) {
      return levels?.find((level: LevelDTO) => `${level.id}` === `${arrLevel[1]}`)?.name;
    }

    return '';
  }

  static getActivityTypes = ({ event, activityType }: { event?: EventDTO; activityType: string }) => {
    if (activityType === EVENT_TYPES.MOBILE_PASS) {
      return [EVENT_TYPES.MOBILE_PASS];
    }
    if (activityType === EVENT_TYPES.OTHER) {
      return [EVENT_TYPES.ATHLETIC, EVENT_TYPES.OTHER];
    }
    return !event?.ticketDistribution && event?.partnerName !== ARBITER_PARTNER_NAME && !event?.seasonId
      ? EVENT_TYPES.VALUES
      : [EVENT_TYPES.ATHLETIC];
  };

  static hasTicketSold = (product: EventProduct, productSalesMap: ProductSalesMap) => {
    if (isEmpty(product)) return false;
    const { saleQuantity = 0 } = productSalesMap?.[product?.id] ?? {};
    return saleQuantity > 0;
  };

  static getPaymentCycleText = (paymentCycle: string) => {
    switch (paymentCycle.toLowerCase()) {
      case 'weekly_transaction_close': {
        return 'Calendar-based';
      }

      case 'weekly_event_close': {
        return 'Event-based';
      }

      case 'daily_transaction_close': {
        return 'Daily Settlement';
      }

      default:
        return '';
    }
  };

  static canViewEventHistory = ({ event, currentUser }: { event?: EventDTO; currentUser?: UserDTO }): boolean => {
    if (isEmpty(event) || isEmpty(currentUser)) return false;
    if (event.awayGame) {
      return (
        canEdit(event.accountId, currentUser) ||
        canEdit(event?.financialAccountId ?? '', currentUser) ||
        canEdit(event?.opponentAccountId ?? '', currentUser)
      );
    }
    return (
      canEdit(event.accountId, currentUser) ||
      canEdit(event?.financialAccountId ?? '', currentUser) ||
      isCoach(currentUser, event.accountId)
    );
  };

  static getProductSalesInfo = (productId: number | string, event: EventDTO) => {
    const eventSalesInfo = get(event, '_embedded.event-sales-info', {}) as EventSalesInfoDTO;
    return eventSalesInfo?.productSalesMap?.[productId];
  };

  static getStartTimeOptionsByTeam = ({
    event,
    teamKey,
    isBroadcast,
    isNegative = false
  }: {
    event: EventDTO;
    teamKey: string;
    isBroadcast?: boolean;
    isNegative?: boolean;
  }) => {
    let startTimeOptions = {};
    const startTimeKey = teamKey;
    const broadCastKey = `${startTimeKey}-${BROADCAST_START_TIME_OPTION}`;
    const compareFn = (key: string) => (teamKey ? key === startTimeKey || key === broadCastKey : !isNegative);
    startTimeOptions = Object.entries(event?.startTimeOptions ?? {}).reduce((acc, [key, value]) => {
      if (isNegative ? !compareFn(key) : compareFn(key)) {
        if (
          isNil(isBroadcast) ||
          (isBroadcast ? key.includes(BROADCAST_START_TIME_OPTION) : !key.includes(BROADCAST_START_TIME_OPTION))
        ) {
          return { ...acc, [key]: value };
        }
      }
      return acc;
    }, {});

    return startTimeOptions;
  };

  static getSpecificTeam = ({
    event,
    teamKey,
    activities,
    levels
  }: {
    event: EventDTO;
    teamKey: string;
    activities: ActivityDTO[];
    levels: LevelDTO[];
  }) => {
    const arrLevel = teamKey?.split('-') ?? [];
    const activity = activities?.find(item => `${item.id}` === `${event.activityId}`);

    return {
      sportName: activity?.label ?? '',
      gender: arrLevel?.[0] ?? '',
      levelName: this.mappingLevelName({ event, levelKey: teamKey, levels }) ?? ''
    };
  };

  static getAvailableTeams = (event: EventDTO) => {
    const { levels = [] } = event;
    const availableTeams: string[] = [];
    levels?.forEach(genderLevels => {
      if (isEmpty(genderLevels?.genders)) {
        availableTeams.push(`${GENDER.COED}-${genderLevels?.levelId}`);
      } else {
        genderLevels?.genders?.forEach(gender => {
          availableTeams.push(`${gender}-${genderLevels?.levelId}`);
        });
      }
    });
    return availableTeams;
  };

  static getEventOpeningDateTime = (event?: EventDTO) => {
    const tz = DateUtils.getTimeZone(event?.timeZone);
    const mStartDateTime = dayjs(event?.startDateTime).utc().tz(tz);
    const openingDateTime = mStartDateTime
      .clone()
      .subtract((event?.eventValidationStartsBefore ?? 0) / 60, 'hours')
      .format();
    return openingDateTime;
  };

  static getUniqueLinkProductTicket = (encodedString: string) => `${config.GOFAN_URL}p/${encodedString}`;

  static getEmbeddedEventGoFanUrl = (event: EventDTO) =>
    `${config.GOFAN_URL}event/${event.id}?schoolId=${event.accountId}`;

  static rescheduleEvent = (payload: EventRescheduleDTO) => EventRepository.rescheduleEvent(payload);

  static deleteEventById = (eventId: string) => EventRepository.deleteEventById(eventId);

  static updateGlobalEvent = (payload: GlobalEventStatus) => EventRepository.updateGlobalEvent(payload.id, payload);

  static isOverCancelEventEndDate = (event?: EventDTO) => {
    if (isEmpty(event)) return true;
    const now = DateUtils.convertDateWithTimeZone(new Date(), event?.timeZone);
    const endDateTime = DateUtils.convertDateWithTimeZone(event?.endDateTime, event?.timeZone);
    return endDateTime.add(90, 'days').isBefore(now);
  };

  static mappingEditEventModalValues = ({
    values,
    type,
    params,
    event
  }: {
    values: any;
    type?: string;
    params?: { levelKey?: string; allLevelKey?: boolean };
    event: EventDTO;
  }) => {
    const timeZone = DateUtils.getTimeZone(event?.timeZone);
    const salesStartDateTime = `${values.salesStartDate} ${values.salesStartTime}`.trim();
    const salesEndDateTime = `${values.salesEndDate} ${values.salesEndTime}`.trim();
    const startDateTime = `${values.startDate} ${values.startTime}`.trim();
    let endDateTime = `${values.endDate} ${values.endTime}`.trim();
    const eventValidationStartsBefore = (values.eventValidationStartsBefore ?? 0) * 60;
    // Fundraiser ongoing will have duration object, we have to convert duration to endDateTime.
    const donationType = this.getDonationType(event);
    if (values?.duration?.value && donationType === PRODUCT_TYPE.DONATION_ONGOING) {
      endDateTime = moment
        .tz(startDateTime, DATE_TIME_PICKER_FORMAT_DEFAULT, timeZone)
        .add(values?.duration?.value, 'days')
        .format(DATE_TIME_PICKER_FORMAT_DEFAULT);
    }

    let { startTimeType, startTimeOptions } = event;
    if (type === 'startTimeOptions' && params?.levelKey) {
      startTimeType = values.startTimeType;
      const { startTime, broadcastStartTime, enabledBroadcast } = values.startTimeOptions ?? {};
      let updatedStartTimeOptions = {};
      if (!isEmpty(startTime)) {
        updatedStartTimeOptions = {
          ...updatedStartTimeOptions,
          [params.levelKey]: startTime
        };
      }
      if (enabledBroadcast && !isEmpty(broadcastStartTime)) {
        updatedStartTimeOptions = {
          ...updatedStartTimeOptions,
          [`${params.levelKey}-${BROADCAST_START_TIME_OPTION}`]: broadcastStartTime
        };
      }

      const remainStartTimeOptions = EventService.getStartTimeOptionsByTeam({
        event,
        teamKey: params.levelKey,
        isNegative: true
      });
      startTimeOptions = { ...remainStartTimeOptions, ...updatedStartTimeOptions };
    } else if (type === 'startTimeOptions' && params?.allLevelKey) {
      startTimeType = values.startTimeType;
      startTimeOptions = ((values.startTimeOptions ?? []) as any[]).reduce((acc, cur) => {
        const { levelKey, startTime, broadcastStartTime, enabledBroadcast } = cur;
        let updatedStartTimeOptions = { ...acc };
        if (!isEmpty(startTime)) {
          updatedStartTimeOptions = {
            ...updatedStartTimeOptions,
            [levelKey]: startTime
          };
        }
        if (enabledBroadcast && !isEmpty(broadcastStartTime)) {
          updatedStartTimeOptions = {
            ...updatedStartTimeOptions,
            [`${levelKey}-${BROADCAST_START_TIME_OPTION}`]: broadcastStartTime
          };
        }
        return updatedStartTimeOptions;
      }, {});
    }
    const availableTeams = EventService.getAvailableTeams({ ...event, levels: values.levels ?? [] });
    startTimeOptions = Object.entries(startTimeOptions ?? {}).reduce((acc, [key, value]) => {
      const hasTeam = availableTeams.some(teamKey => key.includes(teamKey));
      return hasTeam ? { ...acc, [key]: value } : { ...acc };
    }, {});
    if (startTimeType === EVENT_START_TIME_TYPE.EACH_TEAM && availableTeams.length <= 1) {
      startTimeType = EVENT_START_TIME_TYPE.ALL_TEAMS;
    }

    const {
      id: venueId,
      name: venueName,
      streetAddress: venueAddress,
      state: venueState,
      zip: venueZip,
      city: venueCity,
      location: venueLocation
    } = values.venue ?? ({} as VenueDTO);

    let activityId;
    if (!isEmpty(values.activity)) {
      activityId = values.activity.id;
    }

    const redemptionWindow =
      values?.redemptionWindow && !Number.isNaN(values?.redemptionWindow)
        ? (values?.redemptionWindow || 0) * 60
        : values?.redemptionWindow;

    const maxCapacity = values?.unlimitedTicket ? EMPTY_NUMBER : values?.maxCapacity;
    return {
      ...omit(values, [
        'salesStartDate',
        'salesStartTime',
        'salesEndDate',
        'salesEndTime',
        'startDate',
        'startTime',
        'endDate',
        'endTime',
        'venue',
        'gameStartTime',
        'activity',
        'unlimitedTicket',
        'duration'
      ]),
      salesStartDateTime,
      salesEndDateTime,
      startDateTime,
      endDateTime,
      venueAddress,
      venueCity,
      venueId,
      venueLocation,
      venueName,
      venueState,
      venueZip,
      eventValidationStartsBefore,
      startTimeType,
      startTimeOptions,
      activityId,
      redemptionWindow,
      maxCapacity
    };
  };

  static generateFundraiserDateTime = ({
    mode,
    date,
    eventDate,
    hours = 0,
    minutes = 0,
    seconds = 0,
    timeZone,
    type
  }: {
    mode?: any;
    date: string | Date;
    eventDate?: any;
    hours?: number;
    minutes?: number;
    seconds?: number;
    timeZone?: string;
    type?: string;
  }) => {
    const now = moment();
    const mDate = moment(date);
    let dateTime = mDate.clone().set('hours', hours).set('minutes', minutes).set('seconds', seconds);

    if (mDate.isSame(now, 'dates')) {
      dateTime = mDate
        .clone()
        .set('hours', Math.max(hours, now.hours()))
        .set('minutes', type === 'startDateTime' ? 59 : Math.max(minutes, now.minutes()))
        .set('seconds', type === 'startDateTime' ? 59 : Math.max(seconds, now.seconds()));
    }

    const tz = DateUtils.getTimeZone(timeZone);
    if (mode === 'edit' && !!eventDate) {
      const formattedEventDate = DateUtils.formatDateTime({
        date: eventDate,
        timeZone: tz,
        parseZone: true
      });
      const mEventDate = moment(
        DateUtils.switchZone(formattedEventDate.toDate(DATE_FORMAT_DEFAULT_WITH_TIMEZONE), 'localZone')
      );
      if (mDate.isSame(mEventDate, 'dates')) {
        dateTime = mDate
          .clone()
          .set('hours', mEventDate.hours())
          .set('minutes', mEventDate.minutes())
          .set('seconds', mEventDate.seconds());
      }
      return DateUtils.switchZone(dateTime.clone().format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE), tz);
    }
    return dateTime.clone().format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE);
  };

  static getAvailableTickets = (eventSaleInfo: EventSalesInfoDTO): number => {
    if (isEmpty(eventSaleInfo)) {
      return 0;
    }

    if (eventSaleInfo.eventCapacity) {
      if (eventSaleInfo.eventTotalSaleQuantity) {
        return eventSaleInfo.eventCapacity - eventSaleInfo.eventTotalSaleQuantity;
      }

      return eventSaleInfo.eventCapacity;
    }

    return 0;
  };

  static isPlayOnSiteEvent = (event: EventDTO, games: GameDTO[] = []) =>
    isEmpty(games) &&
    (event?.eventIntegrationDetails ?? []).every(item => !item.isBroadcast) &&
    isEmpty(event?.products);

  static getMinMaxEventDate = ({
    mode,
    timeZone,
    currentUser,
    event
  }: {
    mode: 'create' | 'edit';
    timeZone: string;
    currentUser: UserDTO;
    event?: EventDTO;
  }) => {
    const now = dayjs().tz(timeZone);
    const mStartDateTime = dayjs(event?.startDateTime).tz(timeZone);
    const mEndDateTime = dayjs(event?.endDateTime).tz(timeZone);
    const mPublishDateTime = dayjs(event?.publishDateTime).tz(timeZone);
    const maxDate = isInternalUser(currentUser) ? ADMIN_MAX_DATE : USER_MAX_DATE;

    const minStartDate =
      mode === 'edit' && mStartDateTime.isBefore(now, 'dates')
        ? mStartDateTime.format(DATE_PICKER_FORMAT_DEFAULT)
        : now.format(DATE_PICKER_FORMAT_DEFAULT);
    const minEndDate =
      mode === 'edit' && mEndDateTime.isBefore(now, 'dates')
        ? mEndDateTime.format(DATE_PICKER_FORMAT_DEFAULT)
        : now.format(DATE_PICKER_FORMAT_DEFAULT);
    const minPublishDate =
      mode === 'edit' && mPublishDateTime.isBefore(now, 'dates')
        ? mPublishDateTime.format(DATE_PICKER_FORMAT_DEFAULT)
        : now.format(DATE_PICKER_FORMAT_DEFAULT);

    const maxStartDate =
      mode === 'edit' ? mStartDateTime.add(START_DATE_LIMIT_DAYS, 'days').format(DATE_PICKER_FORMAT_DEFAULT) : maxDate;
    const maxEndDate =
      mode === 'edit' ? mEndDateTime.add(START_DATE_LIMIT_DAYS, 'days').format(DATE_PICKER_FORMAT_DEFAULT) : maxDate;

    return { minStartDate, minEndDate, minPublishDate, maxStartDate, maxEndDate };
  };

  static generateEventName = (account?: AccountDTO, opponentAccount?: AccountDTO) => {
    const accountName = isEmpty(account) ? '' : account.shortName || account.mascot || account.name || '';

    const opponentAccountName = isEmpty(opponentAccount)
      ? ''
      : opponentAccount.shortName || opponentAccount.mascot || opponentAccount.name || '';

    if (isEmpty(accountName) || isEmpty(opponentAccountName)) return '';

    return `${accountName} vs ${opponentAccountName}`.trim();
  };

  static isStreamingEvent = (event: EventDTO) =>
    (event?.eventIntegrationDetails ?? [])?.length > 0 &&
    (event?.eventIntegrationDetails ?? [])?.some(item => item.isBroadcast);

  static isStreamingOnlyEvent = (event: EventDTO) =>
    this.isStreamingEvent(event) && (event?.products ?? [])?.length === 0;
}
