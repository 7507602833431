export {
  withLDProvider,
  asyncWithLDProvider,
  useFlags as useFeatureFlags,
  useLDClient
} from 'launchdarkly-react-client-sdk';

export const FEATURE_FLAGS = {
  fundraiserDifferentiation: 'fundraiserDifferentiation',
  myAccountNewUiEnable: 'myAccountNewUiEnable',
  tempTestCameraHqMobileUnicorn: 'tempTestCameraHqMobileUnicorn',
  seasonCreation: 'seasonCreation',
  viewSeason: 'viewSeason',
  newFlatfileUnicorn: 'newFlatfileUnicorn',
  newSeasonFlatfile: 'tempNewSeasonFlatfileUnicorn',
  financialAuditPackage: 'financialAuditPackage',
  uniqueLinksOnReservedSeats: 'tempUniqueLinksOnReservedSeatsDashbirds',
  campRegistration: 'tempCampRegistrationDashbirds',
  tempAthleticSiteUnicorn: 'tempAthleticSiteUnicorn',
  hqMobileEnhancements: 'hqMobileEnhancements',
  tempAchManualVerificationXfin: 'tempAchManualVerificationXfin',
  streamingViewership: 'streamingViewership',
  tempAchGiactValidationXfin: 'tempAchGiactValidationXfin',
  tempRecentPaymentsChartXfin: 'tempRecentPaymentsChartXfin',
  tempPlayonSiteOnlyEventUnicorn: 'tempPlayonSiteOnlyEventUnicorn',
  tempCreatePageDashbirds: 'tempCreatePageDashbirds',
  tempDistrictConferenceUnicorn: 'tempDistrictConferenceUnicorn',
  tempDistrictNexusV2: 'tempDistrictNexusV2',
  tempPlayonSiteUnicorn: 'tempPlayonSiteUnicorn',
  tempPhotosAlbumsUnicorn: 'tempPhotosAlbumsUnicorn',
  tempMenuUnicorn: 'tempMenuUnicorn',
  tempPlayonSiteGoliveUnicorn: 'tempPlayonSiteGoliveUnicorn',
  tempSeasonCreationEventBroadcastSettingsDashbirds: 'tempSeasonCreationEventBroadcastSettingsDashbirds',
  tempFanEngagementConnect: 'tempFanEngagementConnect',

  tempAwayGamesxMeg: 'tempAwayGamesXMeg',
  enableConferenceCommissionerFeatures: 'enableConferenceCommissionerFeatures',
  enableTeamsFeatures: 'enableTeamsFeatures',
  enableConcessionChartOnTeamDashboard: 'enableConcessionChartOnTeamDashboard',
  enableFundraiserChartOnTeamDashboard: 'enableFundraiserChartOnTeamDashboard',
  enablePracticeSchedule: 'enablePracticeSchedule',
  enableRostersFeature: 'enableRostersFeature',
  enableDeleteRoster: 'enableDeleteRoster',
  enableShareRoster: 'enableShareRoster',
  enableDownloadRoster: 'enableDownloadRoster',
  enableTestCameraLastConnection: 'enableTestCameraLastConnection',
  enableTestCameraSoftwareVersion: 'enableTestCameraSoftwareVersion',
  enableSponsorship: 'enableSponsorship',
  tempSponsorSharedS3Bucket: 'tempSponsorSharedS3Bucket',
  opTournamentsOptionDashbirds: 'opTournamentsOptionDashbirds',
  tempScorexMeg: 'tempScoreXMeg',
  tempEventMonitorSettingXMeg: 'tempEventMonitorSettingXMeg',
  tempNavBarPendoLauncherDashbirds: 'tempNavBarPendoLauncherDashbirds',
  tempTagTeamAlbumNexus: 'tempTagTeamAlbumNexus',
  tempScoreSupportedSportsNex: 'tempScoreSupportedSportsNex'
};
