import { useRef, useEffect } from 'react';

interface ClickAwayProps {
  clickHandler: () => void;
  children: React.ReactNode;
}

export const ClickAwayListener = ({ clickHandler, children }: ClickAwayProps) => {
  const wrapperRef = useRef<any>(null);
  useEffect(() => {
    // Handle checking if click outside
    function handleClickOutside(event: any) {
      // If click is outside current ref
      // Or if click is inside of calendar select (The current 'appendTo' method from carbon is broken: https://github.com/carbon-design-system/carbon/issues/10800 This is a hack around...)
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !event.target.classList.contains('flatpickr-day') &&
        !event.target.classList.contains('flatpickr-months') &&
        !event.target.classList.contains('flatpickr-weekday') &&
        !event.target.classList.contains('flatpickr-next-month') &&
        !event.target.classList.contains('flatpickr-prev-month') &&
        !event.target.classList.contains('numInputWrapper') &&
        !event.target.classList.contains('cur-month') &&
        !event.target.classList.contains('arrowUp') &&
        !event.target.classList.contains('arrowDown') &&
        event.target.classList.length !== 0
      ) {
        // Run props.clickHandler
        clickHandler();
      }
    }

    // Bind event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, clickHandler]);

  return <div ref={wrapperRef}>{children}</div>;
};
