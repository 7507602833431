import { FlexGrid as CarbonFlexGrid, Row as CarbonRow, Column as CarbonColumn } from '@carbon/react';
import type { GridProps as CarbonGridProps } from '@carbon/react/lib/components/Grid/GridTypes';

import './FlexGrid.scss';

type FlexGridProps = CarbonGridProps<'div'>;

export const FlexGrid = (props: FlexGridProps) => <CarbonFlexGrid {...props} />;

export const Row = CarbonRow;

export const Column = CarbonColumn;
