import type { EventDetailActionTypes } from './actionTypes';
import {
  ON_PUBLISH_EVENT,
  ON_PUBLISH_EVENT_COMPLETED,
  PREPARE_DATA,
  PREPARE_DATA_COMPLETED,
  SEARCH_OPPONENT_ACCOUNTS,
  SEARCH_OPPONENT_ACCOUNTS_SUCCESS,
  SEARCH_ACCOUNT_PRODUCT_TYPES,
  SEARCH_ACCOUNT_PRODUCT_TYPES_COMPLETED,
  UPDATE_REQUIRED_FIELDS,
  UPDATE_OPTIONAL_FIELDS,
  RESET_PUBLISH_STATE,
  ON_CREATE_EVENT_SUCCESS,
  RESET_STATE,
  ON_SET_PERMISSION
} from './actionTypes';
import type { EventDetailState } from './types';

import { isEmpty } from '../../utils/objectUtils';

export const initialState: EventDetailState = {
  pageLoading: false,
  pageError: undefined,
  event: {},
  levels: [],
  athleticActivities: [],
  nonAthleticActivities: [],
  requiredFields: {},
  opponentAccounts: [],
  opponentSearchLoading: false,
  accountProductTypes: [],
  accountProductsSearching: false,
  account: null,
  histories: [],
  isPublishing: false,
  isPublished: false,
  updatedFields: [],
  isEventCreated: false,
  hasPermission: false
};

export default (state = initialState, action: EventDetailActionTypes) => {
  switch (action.type) {
    case PREPARE_DATA: {
      return {
        ...state,
        pageLoading: true,
        pageError: undefined
      };
    }

    case PREPARE_DATA_COMPLETED: {
      const { event, levels, athleticActivities, nonAthleticActivities } = action.payload;
      return {
        ...state,
        pageLoading: false,
        pageError: isEmpty(action.error) ? undefined : action.error,
        event: isEmpty(event) ? {} : event,
        levels: isEmpty(levels) ? [] : levels,
        athleticActivities: isEmpty(athleticActivities) ? [] : athleticActivities,
        nonAthleticActivities: isEmpty(nonAthleticActivities) ? [] : nonAthleticActivities
      };
    }
    case ON_PUBLISH_EVENT: {
      return {
        ...state,
        updatedFields: [],
        isPublishing: true,
        isPublished: false
      };
    }

    case ON_PUBLISH_EVENT_COMPLETED: {
      const { error, response } = action;
      const { updatedFields } = action.payload;
      return {
        ...state,
        updatedFields: isEmpty(updatedFields) ? [] : updatedFields,
        isPublished: isEmpty(error),
        isPublishing: false,
        pageError: error,
        responseBody: response
      };
    }

    case SEARCH_OPPONENT_ACCOUNTS: {
      return {
        ...state,
        opponentSearchLoading: true
      };
    }
    case SEARCH_OPPONENT_ACCOUNTS_SUCCESS: {
      const { accounts } = action.payload;
      return {
        ...state,
        opponentAccounts: accounts,
        opponentSearchLoading: false
      };
    }

    case SEARCH_ACCOUNT_PRODUCT_TYPES: {
      return {
        ...state,
        accountProductsSearching: true
      };
    }

    case SEARCH_ACCOUNT_PRODUCT_TYPES_COMPLETED: {
      const { searchResult } = action.payload;
      const { data } = searchResult;
      return {
        ...state,
        accountProductTypes: data,
        accountProductsSearching: false
      };
    }

    case UPDATE_REQUIRED_FIELDS: {
      const { requiredFields } = action.payload;
      return {
        ...state,
        requiredFields
      };
    }

    case UPDATE_OPTIONAL_FIELDS: {
      const { optionalFields } = action.payload;
      return {
        ...state,
        optionalFields
      };
    }

    case ON_CREATE_EVENT_SUCCESS: {
      return {
        ...state,
        isEventCreated: true
      };
    }

    case ON_SET_PERMISSION: {
      return {
        ...state,
        hasPermission: action.payload.hasPermission
      };
    }

    case RESET_PUBLISH_STATE: {
      return {
        ...state,
        updatedFields: [],
        isPublished: false,
        isEventCreated: false
      };
    }

    case RESET_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
