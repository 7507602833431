import { Level } from './types';

export const FETCH_LEVELS = 'level/FETCH_LEVELS'
export const FETCH_LEVELS_SUCCESSFUL = 'level/FETCH_LEVELS_SUCCESSFUL'

export const CREATE_LEVEL = 'level/CREATE_LEVEL'
export const CREATE_LEVEL_SUCCESSFUL = 'level/CREATE_LEVEL_SUCCESSFUL'

export const UPDATE_LEVEL = 'level/UPDATE_LEVEL'
export const UPDATE_LEVEL_SUCCESSFUL = 'level/UPDATE_LEVEL_SUCCESSFUL'

export const DELETE_LEVEL = 'level/DELETE_LEVEL'
export const DELETE_LEVEL_SUCCESSFUL = 'level/DELETE_LEVEL_SUCCESSFUL'

export const DELETE_LEVEL_COMPLETED = 'level/DELETE_LEVEL_COMPLETED'
export const CREATE_LEVEL_COMPLETED = 'level/CREATE_LEVEL_COMPLETED'
export const UPDATE_LEVEL_COMPLETED = 'level/UPDATE_LEVEL_COMPLETED'
export const FETCH_LEVELS_COMPLETED = 'level/FETCH_LEVELS_COMPLETED'

interface FetchLevelsAction {
  type: typeof FETCH_LEVELS
}

interface FetchLevelsSuccessfulAction {
  type: typeof FETCH_LEVELS_SUCCESSFUL
  payload: {
    levels: Level[]
  }
}

interface CreateLevelAction {
  type: typeof CREATE_LEVEL,
  payload: {
    level: Level
  }
}

interface CreateLevelsSuccessfulAction {
  type: typeof CREATE_LEVEL_SUCCESSFUL
  payload: {
    levels: Level[]
  }
}

interface UpdateLevelAction {
  type: typeof UPDATE_LEVEL
  payload: {
    level: Level
  }
}

interface UpdateLevelsSuccessfulAction {
  type: typeof UPDATE_LEVEL_SUCCESSFUL,
  payload: {
    levels: Level[]
  }
}

interface DeleteLevelAction {
  type: typeof DELETE_LEVEL
  payload: {
    level: Level
  }
}
interface DeleteLevelsSuccessfulAction {
  type: typeof DELETE_LEVEL_SUCCESSFUL,
  payload: {
    levels: Level[]
  }
}


interface CreateLevelCompletedAction {
  type: typeof CREATE_LEVEL_COMPLETED
}

interface UpdateLevelCompletedAction {
  type: typeof UPDATE_LEVEL_COMPLETED
}

interface FetchLevelCompletedAction {
  type: typeof FETCH_LEVELS_COMPLETED
}

interface DeleteLevelCompletedlAction {
  type: typeof DELETE_LEVEL_COMPLETED,
}

export type LevelActionTypes = 
  CreateLevelAction |
  CreateLevelsSuccessfulAction |
  CreateLevelCompletedAction |
  FetchLevelsAction |
  FetchLevelsSuccessfulAction |
  FetchLevelCompletedAction |
  UpdateLevelAction |
  UpdateLevelsSuccessfulAction |
  UpdateLevelCompletedAction |
  DeleteLevelAction |
  DeleteLevelsSuccessfulAction |
  DeleteLevelCompletedlAction