export const UNSECURITY_ERROR = 'User may only retrieve his own email';
export const ACCESS_DENIED = 'Access is denied';
export const FORBIDDEN = 'Forbidden';
export const UNEXPECTED_ERROR = 'Unexpected Error';
export const CANCELLED_REQUEST = 'Cancelled Request';

export const STATUS_CODE_400 = 400;
export const STATUS_CODE_401 = 401;
export const STATUS_CODE_403 = 403;
export const STATUS_CODE_404 = 404;
export const STATUS_CODE_500 = 500;
export const STATUS_CODE_502 = 502;
export const STATUS_CODE_503 = 503;

export const MAINTENANCE_MODE = 'MAINTENANCE_MODE';

export const SCORE_MODIFICATION_DENIED = 'Score modification is denied';
export const SCORE_MODIFICATION_ERROR = 'Score modification Unexpected Error';

