import { createContext, useCallback, useContext, useMemo } from 'react';
import { isEmpty, pick, uniq } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';

import { pluralizeNoun } from '@app/utils/stringUtils';
import { formatUSPhoneNumber, getContactName } from '@gofan/utils';

import {
  generateAddressInfo,
  getAccountLogo,
  getAccountPageUrl,
  getAccountPrimaryColor,
  getAccountSlogan,
  getAccountAssociationsLogo,
  isAccountActive,
  generateStreetAddress
} from '@accounts/v2/utils';

import {
  AccountQuery,
  UsersQuery,
  VenuesQuery,
  canEditFinancial,
  canEdit,
  canEditContact,
  getAvatarUrl,
  CONTACT_TYPE_OPTIONS,
  getContactType,
  isCoach,
  useAccountFinancial
} from '@gofan/api';

import { useSearchParams } from '@app/hooks/useSearchParams';
import type { AccountDTO } from '@gofan/api/accounts';
import { RootContext } from '@app/RootContext';

import { PAGES } from '@config/routes';
import { ALLOW_ATHLETIC_SITE_SCHOOL_TYPE } from '@accounts/constants/constants';

import type {
  AccountInfoCardProps,
  ContactsInfoCardProps,
  VenuesInfoCardProps,
  AccountFinancialCardProps,
  AssociationInfoCardProps,
  SchoolInfoCardProps
} from '../views/tab-contents';
import type { YourSitesCardProps } from '../views/tab-contents/account/YourSitesCard';

export type AccountLandingContextProps = {
  account: AccountDTO | undefined;
  isAccountLoading: boolean;
  isAccountError?: boolean;
  accountErrorMsg?: unknown;
  isCommissioner: boolean;
  isEditableCommissioner: boolean;
  isEditableDistrictFinancialContact?: boolean;
  isEditor: boolean;
  isViewMode: boolean;
  getAccountInfoCardProps: () => AccountInfoCardProps | undefined;
  getSchoolInfoCardProps: () => SchoolInfoCardProps | undefined;
  getYourSitesCardProps: () => YourSitesCardProps | undefined;
  getAccountFinancialCardProps: () => AccountFinancialCardProps | undefined;
  getContactsInfoCardProps: () => ContactsInfoCardProps;
  getVenuesInfoCardProps: () => VenuesInfoCardProps;
  getAssociationCardProps: () => AssociationInfoCardProps;
  rememberSchoolYear: (schoolYear: string) => void;
  reloadAccount?: () => void;
};

export const AccountLandingContext = createContext<AccountLandingContextProps>({} as AccountLandingContextProps);

export type AccountLandingProviderProps = {
  account: AccountDTO | null;
  isCommissioner: boolean;
  isEditableCommissioner: boolean;
  isEditableDistrictFinancialContact?: boolean;
  isReadableFinancialContact?: boolean;
  isEditor: boolean;
  isAccountLoading: boolean;
  children: React.ReactNode;
  activeTab: string;
  rememberSchoolYear: (schoolYear: string) => void;
  reloadAccount?: () => void;
};

export function AccountLandingProvider({
  account,
  isAccountLoading,
  isCommissioner,
  isEditableCommissioner,
  isEditableDistrictFinancialContact,
  isReadableFinancialContact = false,
  isEditor,
  children,
  activeTab,
  rememberSchoolYear,
  reloadAccount
}: Readonly<AccountLandingProviderProps>) {
  const { currentUser } = useContext(RootContext);
  const query = useSearchParams();
  const isViewMode = query.get('mode') === 'view';
  const { accountId } = useParams<{ accountId: string; mode?: string }>();
  const usersEmails = account?.userAccountContexts?.map(({ email }) => encodeURIComponent(email)) || [];

  const history = useHistory();

  const gotoEditYourSites = useCallback(() => {
    history.push(PAGES.accountsV2.athleticSiteEdit.calculatedPath(accountId), {
      formPage: PAGES.accountsV2.landing.path
    });
  }, [accountId, history]);

  const gotoAccountDetailsPage = useCallback(() => {
    history.push(PAGES.accountsV2.details.calculatedPath(accountId));
  }, [accountId, history]);

  const gotoGoFanPage = useCallback(() => {
    history.push(PAGES.accountsV2.gofanPage.calculatedPath(accountId));
  }, [accountId, history]);

  const gotoAccountFinancialPage = useCallback(() => {
    history.push(PAGES.accountsV2.financial.calculatedPath(accountId));
  }, [history, accountId]);

  const { data: accountFinancial } = useAccountFinancial({
    accountId,
    queryOptions: {
      enabled: !!accountId && isReadableFinancialContact
    }
  });

  const { data: accountUsers, isLoading: isUsersLoading } = UsersQuery.getUserByEmails({
    usersEmails,
    accountId,
    queryOptions: { enabled: !!account && activeTab === '#account' }
  });

  const { data: venues, isLoading: isVenuesLoading } = VenuesQuery.getVenuesByIds({
    ids: account?.venueIds || [],
    queryOptions: {
      enabled: !!account && activeTab === '#account'
    }
  });

  const { data: associations } = AccountQuery.getAccountAssociations({
    account: account || undefined,
    queryOptions: {
      enabled: !!account && activeTab === '#account'
    }
  });

  const getAccountInfoCardProps = useCallback((): AccountInfoCardProps | undefined => {
    if (account) {
      const numberOfContacts = account.userAccountContexts?.length || 0;
      return {
        logo: getAccountLogo(accountId, account.logo),
        slogan: getAccountSlogan(account.shortName, account.name),
        schoolType: account.gofanSchoolType,
        addressInfo: generateAddressInfo({
          state: account.state,
          city: account.city,
          zip: account.zip
        }),
        isActive: isAccountActive(account.status),
        isLoading: isAccountLoading,
        id: account.id,
        numberOfContacts,
        numberOfContactsText: pluralizeNoun('contact', numberOfContacts, 's', true),
        primaryColor: getAccountPrimaryColor(account.primaryColor),
        onEdit: (isEditor || isEditableCommissioner) && !isViewMode ? gotoAccountDetailsPage : undefined
      };
    }
    return undefined;
  }, [account, accountId, isAccountLoading, isEditor, isViewMode, isEditableCommissioner, gotoAccountDetailsPage]);

  const getSchoolInfoCardProps = useCallback((): SchoolInfoCardProps | undefined => {
    if (account) {
      return {
        gofanPageEnabled: account.gofanPageEnabled,
        isLogoUploaded: !isEmpty(account.logo),
        streetAddress: account.streetAddress,
        addressLine: generateAddressInfo(pick(account, ['streetAddress', 'city', 'state', 'zip'])),
        streetAddressTwo: account.streetAddressTwo,
        addressInfo: generateAddressInfo(account),
        county: account.county,
        telephone: formatUSPhoneNumber(account.telephone),
        goFanPageLink: getAccountPageUrl(account.id),
        onEdit: (isEditor || isEditableCommissioner) && !isViewMode ? gotoAccountDetailsPage : undefined,
        onEditGoFanPage: (isEditor || isEditableCommissioner) && !isViewMode ? gotoGoFanPage : undefined
      };
    }
    return undefined;
  }, [account, isEditor, isEditableCommissioner, isViewMode, gotoGoFanPage, gotoAccountDetailsPage]);

  const getYourSitesCardProps = useCallback((): YourSitesCardProps | undefined => {
    if (account) {
      const isEmptySite = !account.gofanPageEnabled && isEmpty(account.siteDomain);
      return {
        accountId: account.id,
        title: isEmptySite ? 'Drive Revenue for Your Programs' : 'PlayOn Sites',
        description: !isEmptySite
          ? 'Elevate your programs by building your brand, promoting your events and connecting with your community'
          : '',
        isEmptySite,
        logo: getAccountLogo(accountId, account.logo),
        isLogoUploaded: !isEmpty(account.logo),
        gofanPageEnabled: account.gofanPageEnabled,
        goFanPageLink: getAccountPageUrl(account.id),
        schoolName: account.name,
        primaryColor: account.primaryColor,
        secondaryColor: account.secondaryColor,
        isShowGoFanSite: account.gofanSchoolType === ALLOW_ATHLETIC_SITE_SCHOOL_TYPE && !isEmpty(account.siteDomain),
        siteDomain: account.siteDomain,
        schoolSlogan: account.siteSchoolSlogan,
        mascot: account.mascot,
        shortName: account.shortName,
        onEdit: (isEditor || isEditableCommissioner) && !isViewMode ? gotoEditYourSites : undefined,
        siteVisibility: account.siteVisibility
      };
    }
    return undefined;
  }, [account, isEditor, isViewMode, gotoEditYourSites]);

  const getContactsInfoCardProps = useCallback(
    (): ContactsInfoCardProps => ({
      users: (accountUsers || []).map(user => {
        const { email, title, avatar, firstName, lastName, coachInfo } = user;
        return {
          email,
          title,
          contactType: CONTACT_TYPE_OPTIONS[getContactType(user, accountId)],
          avatar: getAvatarUrl(avatar || '', email),
          name: getContactName(firstName, lastName),
          editable: (canEditContact(currentUser, user, accountId) || isEditableCommissioner) && !isViewMode,
          isCoach: isCoach(user, accountId),
          staffRoles: (uniq(coachInfo?.flatMap(item => item.teams.map(team => team.staffRole))) || []).join(', ')
        };
      }),
      isLoading: isUsersLoading,
      onViewContacts() {
        history.push(PAGES.accountsV2.contacts.calculatedPath(accountId));
      },
      onAddContact:
        (isEditor || isEditableCommissioner) && !isViewMode
          ? () => history.push(PAGES.accountsV2.contactsAdd.calculatedPath(accountId))
          : undefined,
      onEditUser:
        (isEditor || isEditableCommissioner) && !isViewMode
          ? (email: string) => history.push(PAGES.accountsV2.contactEdit.calculatedPath(accountId, email))
          : undefined
    }),
    [accountId, currentUser, history, isUsersLoading, accountUsers, isViewMode, isEditableCommissioner]
  );

  const getVenuesInfoCardProps = useCallback(
    (): VenuesInfoCardProps => ({
      venues: (venues || []).map(venue => ({
        id: String(venue.id),
        name: venue.name,
        streetAddress: generateStreetAddress(venue),
        venueAddress: generateAddressInfo({
          city: venue.city,
          state: venue.state,
          zip: venue.zip
        })
      })),
      onEdit: (isEditor || isEditableCommissioner) && !isViewMode ? gotoAccountDetailsPage : undefined,
      isLoading: isVenuesLoading
    }),
    [isEditor, venues, isVenuesLoading, isViewMode, gotoAccountDetailsPage, isEditableCommissioner]
  );

  const getAssociationCardProps = useCallback((): AssociationInfoCardProps => {
    const props = {
      isLoading: isAccountLoading || isVenuesLoading,
      onEdit:
        (canEdit(accountId, currentUser) || isEditableCommissioner) && !isViewMode
          ? () => {
              history.push(PAGES.accountsV2.details.calculatedPath(accountId));
            }
          : undefined
    };
    if (account && associations) {
      Object.assign(props, {
        association: {
          stateAssociationName: account.stateAssociationName,
          districtName: account.districtName,
          ...getAccountAssociationsLogo(account, associations)
        }
      });
    }
    return props;
  }, [
    accountId,
    history,
    account,
    isAccountLoading,
    isVenuesLoading,
    currentUser,
    associations,
    isViewMode,
    isEditableCommissioner
  ]);

  const getAccountFinancialCardProps = useCallback(() => {
    if (account?.id && accountFinancial) {
      return {
        transferType: accountFinancial?.transferType,
        paymentCycle: account?.paymentCycle,
        editable: (canEditFinancial(account.id, currentUser) || isEditableDistrictFinancialContact) && !isViewMode,
        onFinancialEditClicked: gotoAccountFinancialPage
      } as AccountFinancialCardProps;
    }
    return undefined;
  }, [
    account,
    accountFinancial,
    currentUser,
    isViewMode,
    gotoAccountFinancialPage,
    isEditableDistrictFinancialContact
  ]);

  const defaultContext = useMemo(
    () =>
      ({
        isCommissioner,
        isEditableCommissioner,
        isEditableDistrictFinancialContact,
        account,
        isAccountLoading,
        isEditor,
        isViewMode,
        getAccountInfoCardProps,
        getSchoolInfoCardProps,
        getYourSitesCardProps,
        getAccountFinancialCardProps,
        getContactsInfoCardProps,
        getVenuesInfoCardProps,
        getAssociationCardProps,
        rememberSchoolYear,
        reloadAccount
      } as AccountLandingContextProps),
    [
      isCommissioner,
      isEditableCommissioner,
      isEditableDistrictFinancialContact,
      isAccountLoading,
      account,
      isEditor,
      isViewMode,
      getAccountInfoCardProps,
      getSchoolInfoCardProps,
      getYourSitesCardProps,
      getAccountFinancialCardProps,
      getContactsInfoCardProps,
      getVenuesInfoCardProps,
      getAssociationCardProps,
      rememberSchoolYear,
      reloadAccount
    ]
  );

  return <AccountLandingContext.Provider value={defaultContext}>{children}</AccountLandingContext.Provider>;
}
