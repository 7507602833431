import { css } from 'styled-components';

export default {
  leftLogo: css`
    margin-right: 90px;
    height: 30px;
    width: 135px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `,

  menuIconWrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;

    &:hover {
      background-color: #2c2c2c;
      cursor: pointer;
    }

    svg {
      max-width: 20px;
    }
  `,
  accountName: css`
    .cds--btn--icon-only {
      padding-top: 6px;
    }
  `
};
