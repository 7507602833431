import moment from 'moment';
import dayjs from 'dayjs';
import { flatMap, omit, orderBy } from 'lodash';

import {
  CREATION_MODE,
  DEFAULT_START_TIME,
  ENTERED_VALUE,
  EVENT_START_TIME_TYPE
} from '@season-management/constants/constant';
import SeasonCreationUtil from '@season-management/utils/season-creation.utils';

import { MAX_CAPACITY } from '@seasons/constants/constants';

import { config } from '@gofan/constants';
import { getSchoolImage } from '../../pages/EventInformationV2/helpers';
import {
  EVENT_CREATED_TEMPLATE,
  EVENT_TEMPLATE,
  IMPORT_EVENTS_TYPES,
  SCHOOL_CREATED_TYPES
} from '../../pages/SeasonSetup/constants';
import {
  switchZone,
  getTimeZone,
  DATE_FORMAT_DEFAULT,
  DATE_WITH_TIME_ZONE_FORMAT,
  EVENT_DATE_FORMAT_WITH_TIMEZONE
} from '../../utils/dateUtils';
import { isDifference, isEmpty } from '../../utils/objectUtils';
import SeasonDAO from '../dao/SeasonDAO';
import { generateEventName, generateSpecialEventDescription, groupLevelById } from './EventService';
import PublishEventRequest from '../model/request/PublishEventRequest';
import EventConstants from '../../pages/EventInformationV2/constants';
import ReservedSeatingDAO from '../dao/ReservedSeatingDAO';
import ExpandRequest from '../model/request/ExpandRequest';

export const getSeasonById = (seasonId, params = {}) => {
  const expandRequest = new ExpandRequest(!isEmpty(params) && !isEmpty(params.expand) ? params.expand : []);
  return SeasonDAO.getSeasonById(seasonId, expandRequest);
};
export const getSeasonSeatsByStatus = ({ seasonId, seatStatus = null, updatedAt = null }) =>
  ReservedSeatingDAO.getSeasonSeatsByStatus(seasonId, seatStatus, updatedAt);
export const getTicketsBySeasonId = ({ seasonId, updatedAt = null, page = null }) =>
  ReservedSeatingDAO.getTicketsBySeasonId(seasonId, updatedAt, page);
export const updateSeasonSeatStatus = reservedSeatingDTO =>
  ReservedSeatingDAO.setSeasonReservedSeatStatus(reservedSeatingDTO);

export const generateVenueInformation = venue =>
  venue
    ? {
        venueName: {
          value: venue.name,
          valid: !isEmpty(venue.name)
        },
        venueAddress: {
          value: Array.of(venue.streetAddress, venue.streetAddressTwo).join(' '),
          valid: !isEmpty(venue.streetAddress) || !isEmpty(venue.streetAddressTwo)
        },
        venueCity: {
          value: venue.city,
          valid: !isEmpty(venue.city)
        },
        venueState: {
          value: venue.state,
          valid: !isEmpty(venue.state)
        },
        venueZip: {
          value: venue.zip,
          valid: !isEmpty(venue.zip)
        },
        venueLocation: {
          value: venue.location,
          valid: true
        }
      }
    : {};

export const generateSchoolInfo = arbiterSchool =>
  arbiterSchool
    ? {
        dirty: false,
        editable: true,
        error: '',
        focused: false,
        savedValue: '',
        touched: false,
        valid: true,
        venues: [
          {
            accountId: arbiterSchool.venues.accountId,
            chartId: arbiterSchool.venues.chartId,
            city: arbiterSchool.venues.city,
            id: arbiterSchool.venues.id,
            label: arbiterSchool.venues.label,
            latitude: arbiterSchool.venues.latitude,
            location: arbiterSchool.venues.location,
            longitude: arbiterSchool.venues.longitude,
            name: arbiterSchool.venues.name,
            seatsIoChartKey: arbiterSchool.venues.seatsIoChartKey,
            stadiumInfoUrl: arbiterSchool.venues.stadiumInfoUrl,
            state: arbiterSchool.venues.state,
            streetAddress: arbiterSchool.venues.streetAddress,
            streetAddressTwo: arbiterSchool.venues.streetAddressTwo,
            zip: arbiterSchool.venues.zip
          }
        ],
        value: {
          cashless: arbiterSchool.cashless,
          city: arbiterSchool.city,
          customPageName: arbiterSchool.customPageName,
          facebookUrl: arbiterSchool.facebookUrl,
          gofanSchoolType: arbiterSchool.gofanSchoolType,
          headerImage: arbiterSchool.headerImage,
          icon: isEmpty(arbiterSchool.logo) ? undefined : getSchoolImage(arbiterSchool.id, 'logo', arbiterSchool.logo),
          id: arbiterSchool.id,
          latitude: arbiterSchool.latitude,
          logo: arbiterSchool.logo,
          longitude: arbiterSchool.longitude,
          mascot: arbiterSchool.mascot,
          name: arbiterSchool.name,
          primaryColor: arbiterSchool.primaryColor,
          searchEnabled: arbiterSchool.searchEnabled,
          shortName: arbiterSchool.shortName,
          state: arbiterSchool.state,
          status: arbiterSchool.status,
          streetAddress: arbiterSchool.streetAddress,
          subTitle: `${arbiterSchool.city}${!isEmpty(arbiterSchool.city) && !isEmpty(arbiterSchool.state) ? ',' : ''} ${
            arbiterSchool.state
          }`,
          timeZone: arbiterSchool.timeZone,
          title: arbiterSchool.name,
          twitterUrl: arbiterSchool.twitterUrl
        }
      }
    : {};

export const uploadSeasonTemplate = (formData, timeZone) => SeasonDAO.uploadSeasonTemplate(formData, timeZone);

export const uploadNewSeasonTemplate = (formData, timeZone, uploadFlow, teamOptions) =>
  SeasonDAO.uploadNewSeasonTemplate(formData, timeZone, uploadFlow, teamOptions);

export const buildLevels = eventType => {
  const levelsSelected = [];
  if (eventType && eventType.levels && eventType.genders) {
    const { genders } = eventType;
    const gendersSelected = EventConstants.EVENT_SECTIONS.SECTION_EVENT_TYPE.GENDERS.filter(
      gender => !isEmpty(genders[gender]) && !isEmpty(genders[gender].value) && genders[gender].value.selected
    );
    if (!isEmpty(gendersSelected)) {
      gendersSelected.forEach(gender => {
        let gendersValue = [];
        if (gender === 'boy') {
          gendersValue = ['Boys'];
        } else if (gender === 'girl') {
          gendersValue = ['Girls'];
        }
        if (!isEmpty(eventType.levels[gender]) && !isEmpty(eventType.levels[gender].value)) {
          levelsSelected.push(
            ...eventType.levels[gender].value.flatMap(lv => ({
              levelId: lv.id,
              levelName: lv.label,
              genders: gendersValue
            }))
          );
        } else {
          levelsSelected.push({
            genders: gendersValue,
            levelId: null,
            levelName: null
          });
        }
      });
    }
    return levelsSelected;
  }
  return [];
};

export const buildEventItem = (id, eventItem, account) => {
  const { errors, event } = eventItem;
  const { city, opponentAccount, opponentSchool, startDateTime, state, time } = event;
  let schoolNotFoundName;
  const { id: opponentAccountId, logo, shortName, mascot, name } = opponentAccount || {};

  const venue = account.venues[0];
  const venueInformation = generateVenueInformation(venue);

  if (errors.length === 1 && errors[0].description.includes('Opponent school is not found')) {
    schoolNotFoundName = opponentSchool;
  }
  return {
    ...EVENT_TEMPLATE,
    id,
    eventName: {
      ...EVENT_TEMPLATE.eventName,
      valid: true,
      value: generateEventName(account, opponentAccount || { name: schoolNotFoundName })
    },
    school: {
      ...EVENT_TEMPLATE.school,
      valid: !isEmpty(opponentAccount),
      value: {
        id: opponentAccountId,
        name: name || schoolNotFoundName,
        shortName,
        mascot,
        logo,
        icon: getSchoolImage(opponentAccountId, 'logo', logo)
      }
    },
    venueName: {
      ...EVENT_TEMPLATE.venueName,
      ...venueInformation.venueName
    },
    venueCity: {
      ...EVENT_TEMPLATE.venueCity,
      ...venueInformation.venueCity
    },
    venueState: {
      ...EVENT_TEMPLATE.venueState,
      ...venueInformation.venueState
    },
    venueAddress: {
      ...EVENT_TEMPLATE.venueAddress,
      ...venueInformation.venueAddress
    },
    venueZip: {
      ...EVENT_TEMPLATE.venueZip,
      ...venueInformation.venueZip
    },
    venueLocation: {
      ...EVENT_TEMPLATE.venueLocation,
      ...venueInformation.location
    },
    eventDate: {
      ...EVENT_TEMPLATE.eventDate,
      valid: true,
      value: moment(startDateTime, DATE_FORMAT_DEFAULT)
    },
    startTime: {
      ...EVENT_TEMPLATE.startTime,
      valid: true,
      hour: moment(startDateTime, EVENT_DATE_FORMAT_WITH_TIMEZONE).hour(),
      minute: moment(startDateTime, EVENT_DATE_FORMAT_WITH_TIMEZONE).minute(),
      second: 1,
      value: moment(time.substring(0, time.length - 2).trim(), 'hh:mm').format('hh:mm'),
      type: time.substring(time.length - 2, time.length).trim()
    },
    schoolType: !isEmpty(opponentAccount) ? SCHOOL_CREATED_TYPES.IN_SYSTEM : SCHOOL_CREATED_TYPES.OUT_SYSTEM
  };
};

export const buildEventsData = (events, account) =>
  events.map((event, index) => buildEventItem(`${new Date().getTime() + index}`, event, account));

export const buildEventCreatedSection = (events, account, isManually, existingEventCreatedData) => {
  let eventsData = null;
  let numberOfEvents = 1;
  if (!isManually) {
    eventsData = buildEventsData(events, account);
    numberOfEvents = events.length;
  }

  return {
    ...EVENT_CREATED_TEMPLATE,
    eventsSection: {
      data: eventsData,
      section: eventsData,
      valid: true,
      numberOfEvents
    }
  };
};

export const getTemplateSeasonUploadUrl = () =>
  `https://${config.s3.BUCKET}.${config.s3.URL}/${config.s3.TEMPLATE_SEASON_PATH}`;

export const getNewTemplateSeasonUploadUrl = creationeMode => {
  if (creationeMode === CREATION_MODE.ONE_PER_SPORT) {
    return `https://${config.s3.BUCKET}.${config.s3.URL}/${config.s3.TEMPLATE_SEASON_ONE_PER_SPORT_PATH}`;
  }
  if (creationeMode === CREATION_MODE.SPERATE_EACH_TEAM) {
    return `https://${config.s3.BUCKET}.${config.s3.URL}/${config.s3.TEMPLATE_SEASON_SPERATE_EACH_TEAM_PATH}`;
  }
  return `https://${config.s3.BUCKET}.${config.s3.URL}/${config.s3.NEW_TEMPLATE_SEASON_PATH}`;
};

export const createSeason = request => SeasonDAO.createSeason(request);

export const buildStartDateTimeEvent = (eventDateTime, allDayEvent) => {
  if (allDayEvent) {
    return eventDateTime.clone().set('hours', 0).set('minutes', 1).set('seconds', 1);
  }
  return eventDateTime.clone().set('seconds', 1);
};

export const buildEndDateTimeEvent = (
  startDateTime,
  allDayEvent,
  timezone,
  existingEndDateTime,
  eventDuration,
  shouldKeepEventDurationWhenEdit,
  editedStartDateTime,
  initAllDayEvent
) => {
  if (allDayEvent) {
    return startDateTime.clone().set('hours', 23).set('minutes', 59).set('seconds', 1);
  }
  if (
    ((existingEndDateTime && eventDuration && existingEndDateTime.diff(editedStartDateTime) === eventDuration) ||
      shouldKeepEventDurationWhenEdit) &&
    !initAllDayEvent
  ) {
    return startDateTime.clone().add(eventDuration, 'milliseconds');
  }
  return startDateTime.clone().add(4, 'hours');
};

export const getFormFields = (additionalForm = {}, isSeasonSetting = false) => {
  const formFields = orderBy(additionalForm?.value ?? [], ['order'], ['asc']).map(item =>
    omit({ ...item }, ['id', 'text', 'order', 'label'])
  );

  if (isSeasonSetting) return formFields;

  const isDiff = isDifference(additionalForm?.savedValue ?? [], formFields, item => item.name);
  return isDiff ? formFields : null;
};

export const buildAssociatedEventRequest = (eventItem, account, isSeasonSetting) => {
  const {
    id,
    created,
    school,
    venueId,
    venueCity,
    venueState,
    venueAddress,
    venueLocation,
    venueName,
    venueZip,
    eventType,
    eventName,
    seasonName,
    eventVisibility,
    gateOpeningTime,
    eventAlert,
    ticketAvailability,
    buyerPurchaseLimit,
    schoolType,
    globalEventId,
    partnerName,
    partnerId,
    additionalForm,
    financialAccountId,
    glCode,
    startTimeTeams,
    eventDuration,
    initAllDayEvent,
    endDateTime: existingEndDateTime,
    shouldKeepEventDurationWhenEdit,
    originalExistingEvent,
    accountsTicket = []
  } = eventItem;
  const timeZone = getTimeZone(originalExistingEvent?.timeZone ?? account.timeZone);
  const { id: accountId } = account;
  const { archived, publishDate } = eventVisibility.value;
  const { ticketValidBefore, ticketValidAnytime } = gateOpeningTime.value;
  const { maxCapacity, products: productGroups } = ticketAvailability.value || {};
  const products = flatMap(productGroups, group => {
    const goFanProduct = omit(group, ['boxOfficeTicket']);
    const boxOfficeProduct = omit(group?.boxOfficeTicket, ['boxOfficeTicket']);
    return [goFanProduct, boxOfficeProduct];
  }).filter(item => !isEmpty(item));
  const { activity } = eventType.value;
  const { id: activityId, label: reportingLabel } = activity.value || {};
  const levels = buildLevels(eventType.section);
  const genders = [];
  const newLevels = [];
  if (!isEmpty(levels)) {
    levels.forEach(lv => {
      isEmpty(lv.levelId) ? genders.push(...lv.genders) : newLevels.push(lv);
    });
  }

  const formFields = getFormFields(additionalForm, isSeasonSetting);
  const seasonNameValue = seasonName?.value?.value ?? '';
  const eventRequestLevel = !isEmpty(newLevels) ? groupLevelById(newLevels) : [];

  let publishDateTime = null;
  if (archived.value) {
    const publishDateValue = moment.isMoment(publishDate.value) ? publishDate.value : moment(publishDate.value);
    publishDateTime = publishDateValue.isValid() ? publishDateValue.format(EVENT_DATE_FORMAT_WITH_TIMEZONE) : null;
  }
  const { disableQr, featured, postSeason, ticketDistribution } = originalExistingEvent || {};

  // [MAPPING_START_TIME_OPTIONS]
  const allDayEvent = !!startTimeTeams?.allDayEvent?.value;
  const sameStartTime = startTimeTeams?.sameStartTime?.value;
  const startDateTime = startTimeTeams?.startDateTime?.value;
  const startTimeType = startTimeTeams?.startTimeType?.value ?? EVENT_START_TIME_TYPE.ALL_TEAMS;
  const eventStartTimeTeams =
    isEmpty(startTimeTeams?.startTimeOptions) || startTimeType === EVENT_START_TIME_TYPE.ALL_TEAMS
      ? {}
      : startTimeTeams?.startTimeOptions;
  const startTimeOptions = SeasonCreationUtil.convertStartTimeTeamsToOptions({
    isFormField: true,
    startTimeType,
    startTimeTeams: eventStartTimeTeams
  });

  let eventStartTimeOptions = {
    allDayEvent,
    startTimeType
  };

  if (!isSeasonSetting) {
    const mStartDateTime = moment(startDateTime);
    const eventStartDateTime = switchZone(mStartDateTime.format(EVENT_DATE_FORMAT_WITH_TIMEZONE), timeZone);
    const mEndDateTime = buildEndDateTimeEvent(
      mStartDateTime,
      allDayEvent,
      timeZone,
      existingEndDateTime,
      eventDuration,
      shouldKeepEventDurationWhenEdit,
      mStartDateTime.clone(),
      initAllDayEvent
    );
    const eventEndDateTime = switchZone(mEndDateTime.format(EVENT_DATE_FORMAT_WITH_TIMEZONE), timeZone);

    eventStartTimeOptions = {
      ...eventStartTimeOptions,
      startDateTime: eventStartDateTime,
      endDateTime: eventEndDateTime,
      startTimeOptions: SeasonCreationUtil.replaceMinInStartTimeOptions({
        startTimeOptions,
        replaceValue: {
          time: mStartDateTime.clone().format('hh:mm'),
          period: mStartDateTime.clone().format('A')
        }
      })
    };
  } else {
    eventStartTimeOptions = {
      ...eventStartTimeOptions,
      startTimeOptions,
      seasonStartTime: {
        time: sameStartTime?.time ?? DEFAULT_START_TIME.time,
        period: sameStartTime?.period ?? DEFAULT_START_TIME.period
      }
    };
  }

  return new PublishEventRequest(
    {
      ...eventStartTimeOptions,
      originalExistingEvent: omit(originalExistingEvent, ['productSalesMap']),
      formFields,
      disableQr: disableQr ?? true,
      featured: !!featured,
      ticketDistribution,
      postSeason: !!postSeason,
      id: created ? id : undefined,
      accountId,
      accountsTicket,
      financialAccountId: financialAccountId ?? accountId,
      opponentAccountId:
        !isSeasonSetting && schoolType === SCHOOL_CREATED_TYPES.IN_SYSTEM ? school?.value?.id : undefined,
      timeZone,
      alert: eventAlert.value,
      venueId: venueId?.value,
      venueState: venueState.value ?? '',
      venueCity: venueCity.value ?? '',
      venueAddress: venueAddress.value ?? '',
      venueLocation: venueLocation.value ?? '',
      venueName: venueName.value ?? '',
      venueZip: venueZip.value ?? '',
      name: isSeasonSetting ? seasonNameValue : eventName.value,
      ticketLimitPerOrder: buyerPurchaseLimit.value,
      enableEventValidation: !ticketValidAnytime.value,
      eventValidationStartsBefore: ticketValidAnytime.value ? undefined : ticketValidBefore.value * 60,
      archived: archived.value,
      paymentCycle: originalExistingEvent?.paymentCycle ?? account?.paymentCycle,
      publishDateTime,
      activityId,
      reportingLabel,
      levels: eventRequestLevel,
      specialEventDescription: generateSpecialEventDescription({
        levels: eventRequestLevel
      }),
      genders,
      glCode: glCode.value ?? null,
      maxCapacity: maxCapacity ?? MAX_CAPACITY,
      products: products
        .filter(product => !isEmpty(product.name) && !isEmpty(product.price))
        .reduce((res, product) => {
          let salesStartDateTime;
          let salesEndDateTime;
          if (!isEmpty(product?.salesStartDate) && !isEmpty(product?.salesStartTime)) {
            const mSalesStartDateTime = dayjs(`${product?.salesStartDate} ${product?.salesStartTime}`);
            salesStartDateTime = switchZone(
              mSalesStartDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE),
              timeZone
            );
          }

          if (!isEmpty(product?.salesEndDate) && !isEmpty(product?.salesEndTime)) {
            const mSalesEndDateTime = dayjs(`${product?.salesEndDate} ${product?.salesEndTime}`);
            salesEndDateTime = switchZone(mSalesEndDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE), timeZone);
          }

          if (product.created) {
            res.push(
              omit(
                {
                  ...product,
                  salesStartDateTime,
                  salesEndDateTime
                },
                ['isAddedFromEvent']
              )
            );
          } else {
            res.push(
              omit(
                {
                  ...product,
                  id: undefined,
                  enabled: true,
                  accountId: ticketDistribution ? accountId : undefined,
                  salesStartDateTime,
                  salesEndDateTime
                },
                ['isAddedFromEvent']
              )
            );
          }

          return res;
        }, []),
      globalEventId,
      partnerName,
      partnerId,
      customSportName: eventType?.value?.activity?.value?.label ?? ''
    },
    true
  );
};

export const getStartDateSeason = eventsRequest =>
  eventsRequest
    .map(event => event.startDateTime)
    .sort((a, b) => moment(a, DATE_WITH_TIME_ZONE_FORMAT) - moment(b, DATE_WITH_TIME_ZONE_FORMAT))[0];

export const getEndDateSeason = eventsRequest =>
  eventsRequest
    .map(event => event.endDateTime)
    .sort((a, b) => moment(b, DATE_WITH_TIME_ZONE_FORMAT) - moment(a, DATE_WITH_TIME_ZONE_FORMAT))[0];

export const buildAssociatedEvents = (events, account) =>
  events.map(event => buildAssociatedEventRequest(event, account));

const getEnteredValue = importType => {
  if (importType === IMPORT_EVENTS_TYPES.UPLOAD) {
    return ENTERED_VALUE.FILE_UPLOAD;
  }
  if (importType === IMPORT_EVENTS_TYPES.MANUAL) {
    return ENTERED_VALUE.MANUAL_INPUT;
  }
  if (importType === IMPORT_EVENTS_TYPES.ARBITER) {
    return ENTERED_VALUE.ARBITER_IMPORT;
  }
  return undefined;
};

export const buildCreateSeasonRequest = (
  seasonData,
  account,
  partnerName = null,
  importType,
  financialAccountId = null
) => {
  const { events, settings } = seasonData;
  const { seasonName } = settings;
  const timeZone = getTimeZone(account.timeZone);
  const { id: accountId } = account;
  const { activity } = settings.eventType.section;
  const { id: activityId, label: reportingLabel } = activity.value;
  const levels = buildLevels(settings.eventType.section);

  const genders = [];
  const newLevels = [];
  if (!isEmpty(levels)) {
    levels.forEach(lv => {
      isEmpty(lv.levelId) ? genders.push(...lv.genders) : newLevels.push(lv);
    });
  }
  const associatedEvents = buildAssociatedEvents(events, account);
  const seasonSettings = buildAssociatedEventRequest(settings, account, true);
  const startDateTime = getStartDateSeason(associatedEvents);
  const endDateTime = getEndDateSeason(associatedEvents);

  return {
    accountId,
    name: seasonName.value.value,
    timeZone,
    startDateTime,
    endDateTime,
    activityId,
    reportingLabel,
    levels: !isEmpty(newLevels) ? groupLevelById(newLevels) : [],
    genders,
    events: associatedEvents,
    disableQr: true,
    archived: false,
    venueAddress: settings.venueAddress.value,
    venueCity: settings.venueCity.value,
    venueLocation: settings.venueLocation.value,
    venueName: settings.venueName.value,
    venueState: settings.venueState.value,
    venueZip: settings.venueZip.value,
    partnerName,
    entered: getEnteredValue(importType),
    config: { seasonSettings: JSON.stringify(seasonSettings.toJSON()) },
    financialAccountId: partnerName === 'arbiter' || isEmpty(financialAccountId) ? accountId : financialAccountId
  };
};

export const getSeasonSeatStatusByLabel = ({ seasonId, seatsIoLabel }) =>
  ReservedSeatingDAO.getSeasonSeatStatusByLabel(seasonId, seatsIoLabel);
export const getSeasonUnavailableSeats = seasonId => ReservedSeatingDAO.getSeasonUnavailableSeats(seasonId);

export default {
  getSeasonById,
  getSeasonSeatsByStatus,
  getTicketsBySeasonId,
  uploadSeasonTemplate,
  buildEventCreatedSection,
  getTemplateSeasonUploadUrl,
  createSeason,
  buildCreateSeasonRequest,
  getSeasonUnavailableSeats
};
