import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { EVENT_DATE_FORMAT } from '@gofan/constants/date';

import type {
  PaymentHistoryDTO,
  PaymentSearchDTO,
  PaymentEventQuery,
  PaymentSeasonQuery,
  EventPaymentHistoryDTO,
  SeasonPaymentHistoryDTO,
  PaymentHistoryRow,
  EventPaymentHistoryRow,
  SeasonPaymentHistoryRow,
  PastProjectedPaymentsDTO,
  PastProjectedPaymentsCarbonData,
  PastProjectedPaymentsChartData
} from './financial.model';
import dayjs from 'dayjs';

export const transformPaymentRow = (paymentResponse: PaymentHistoryDTO[]): PaymentHistoryRow[] =>
  paymentResponse.map((item: PaymentHistoryDTO) => ({
    id: item.uuid,
    transactionPeriod: item.payment_period,
    paymentCycle: item.payment_cycle,
    adviceNumber: item.reference_number,
    ticketSales: item.ticket_sales,
    refund: item.refunds,
    netPayment: item.total_payment,
    netTickets: item.tickets_sold,
    adjustments: item.adjustments,
    netSales: item.ticket_sales - Math.abs(item.refunds) + item.adjustments,
    financialSchoolName: item.financial_school_name,
    financialSchoolId: item.financial_school_id,
    financialSchoolType: item.financial_school_type,
    eventIds: item.event_ids
  }));

export const formatSearchParams = (searchParams: PaymentSearchDTO): PaymentSearchDTO => {
  let paymentPeriod;
  const paymentWeekStart = searchParams.paymentWeekStart
    ? moment(searchParams.paymentWeekStart).format('YYYYMMDD')
    : undefined;
  const paymentWeekEnd = searchParams.paymentWeekStart
    ? moment(searchParams.paymentWeekStart).add(6, 'days').format('YYYYMMDD')
    : undefined;

  if (paymentWeekStart && paymentWeekEnd) {
    paymentPeriod = `${paymentWeekStart}-${paymentWeekEnd}`;
  } else if (searchParams.paymentPeriod) {
    paymentPeriod = searchParams.paymentPeriod;
  } else {
    paymentPeriod = undefined;
  }
  const formattedSearchParams = {
    ...searchParams,
    paymentCycle: !isEmpty(searchParams.paymentCycle) ? searchParams.paymentCycle : undefined,
    paymentPeriod,
    eventId: !isEmpty(searchParams.eventId) ? searchParams.eventId : undefined
  };
  return formattedSearchParams;
};

export const formatPaymentEventQuery = (searchParams: PaymentEventQuery): PaymentEventQuery => {
  const formattedSearchParams = {
    ...searchParams,
    eventDate: searchParams.eventDate ? moment(searchParams.eventDate).format(EVENT_DATE_FORMAT) : undefined,
    paymentCycle: !isEmpty(searchParams.paymentCycle) ? searchParams.paymentCycle : undefined,
    eventId: !isEmpty(searchParams.eventId) ? searchParams.eventId : undefined
  };
  return formattedSearchParams;
};

export const formatPaymentSeasonQuery = (searchParams: PaymentSeasonQuery): PaymentSeasonQuery => {
  const formattedSearchParams = {
    ...searchParams,
    seasonDate: searchParams.seasonDate ? moment(searchParams.seasonDate).format(EVENT_DATE_FORMAT) : undefined,
    paymentCycle: !isEmpty(searchParams.paymentCycle) ? searchParams.paymentCycle : undefined,
    seasonId: !isEmpty(searchParams.seasonId) ? searchParams.seasonId : undefined
  };
  return formattedSearchParams;
};

export const transformToEventPaymentHistoryRow = (data: EventPaymentHistoryDTO[]): EventPaymentHistoryRow[] =>
  data.map((item: EventPaymentHistoryDTO) => ({
    financialSchoolId: item.financial_school_id,
    financialSchoolName: item.financial_school_name,
    paymentPeriod: item.payment_period,
    paymentCycle: item.payment_cycle,
    eventId: item.event_id,
    eventName: item.event_name,
    eventDate: item.event_date,
    eventType: item.event_type,
    eventGender: item.event_gender,
    eventLevel: item.event_level,
    eventDescription: item.event_description,
    eventRefunds: item.event_refunds,
    eventAdjustments: item.event_adjustments,
    eventNetSales: item.event_net_sales,
    eventAmountPaid: item.event_amount_paid,
    eventAmountPending: item.event_amount_pending,
    eventNetTicketsSold: item.event_net_tickets_sold,
    id: item.uuid,
    eventSummaryReportUri: item.event_summary_report_uri,
    adviceNum: item.advice_num
  }));

export const transformToSeasonPaymentHistoryRow = (data: SeasonPaymentHistoryDTO[]): SeasonPaymentHistoryRow[] =>
  data.map((item: SeasonPaymentHistoryDTO) => ({
    financialSchoolId: item.financial_school_id,
    financialSchoolName: item.financial_school_name,
    paymentPeriod: item.payment_period,
    paymentCycle: item.payment_cycle,
    seasonId: item.season_id,
    seasonName: item.season_name,
    seasonDate: item.season_date,
    seasonType: item.season_type,
    seasonGender: item.season_gender,
    seasonLevel: item.season_level,
    seasonDescription: item.season_description,
    seasonRefunds: item.season_refunds,
    seasonAdjustments: item.season_adjustments,
    seasonNetSales: item.season_net_sales,
    seasonAmountPaid: item.season_amount_paid,
    seasonAmountPending: item.season_amount_pending,
    seasonNetTicketsSold: item.season_net_tickets_sold,
    id: item.uuid,
    seasonSummaryReportUri: item.season_summary_report_uri,
    adviceNum: item.advice_num
  }));

export const transformPastProjectedPayments = (data: PastProjectedPaymentsDTO[]): PastProjectedPaymentsCarbonData => {
  const months = [
    { label: 'Jul', value: '07' },
    { label: 'Aug', value: '08' },
    { label: 'Sep', value: '09' },
    { label: 'Oct', value: '10' },
    { label: 'Nov', value: '11' },
    { label: 'Dec', value: '12' },
    { label: 'Jan', value: '01' },
    { label: 'Feb', value: '02' },
    { label: 'Mar', value: '03' },
    { label: 'Apr', value: '04' },
    { label: 'May', value: '05' },
    { label: 'Jun', value: '06' }
  ];
  const fiscalYearDateRanges = getFiscalYearDates();
  const results: PastProjectedPaymentsChartData[] = [];
  const dynamicGroups = ['Paid Prior Year', 'Paid Current Year'];

  // *note: payment_type 0 is past, 1 is future projection

  // build Paid Prior Year
  months.forEach(month => {
    const { end } = fiscalYearDateRanges.past;
    // push a default value of 0 in case no data is found
    results.push({
      group: dynamicGroups[0],
      key: month.label,
      value: 0
    });
    data.forEach(item => {
      // if outside the past range, skip
      if (item.month > end) return;
      const dataMonth = item.month.toString().slice(-2);

      if (dataMonth === month.value && item.payment_type === 0) {
        results[results.length - 1].value += item.amount ?? 0;
      }
    });
  });

  // build Paid Current Year
  months.forEach(month => {
    const { start, end } = fiscalYearDateRanges.current;
    // push a default value of 0 in case no data is found
    results.push({
      group: dynamicGroups[1],
      key: month.label,
      value: 0
    });
    data.forEach(item => {
      // if outside the current range, skip
      if (item.month < start || item.month > end) return;
      const dataMonth = item.month.toString().slice(-2);
      if (dataMonth === month.value && item.payment_type === 0) {
        results[results.length - 1].value += item.amount ?? 0;
      }
    });
  });

  // build Upcoming Payments
  months.forEach(month => {
    const { start, end } = fiscalYearDateRanges.current;
    const currentYearRange = `${start.toString().slice(2, 4)}-${end.toString().slice(2, 4)}`;
    // push a default value of 0 in case no data is found
    results.push({
      group: `Upcoming Payments ${currentYearRange}`,
      key: month.label,
      value: 0
    });

    if (dynamicGroups.length < 3) {
      dynamicGroups.push(`Upcoming Payments ${currentYearRange}`);
    }
    data.forEach(item => {
      // if outside the current range, skip
      if (item.month < start || item.month > end) return;
      const dataMonth = item.month.toString().slice(-2);
      if (dataMonth === month.value && item.payment_type === 1) {
        results[results.length - 1].value += item.amount ?? 0;
      }
    });
  });

  return { dynamicGroups, results };
};

export const getFiscalYearDates = () => {
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month() + 1;

  let currentFiscalYearStart;
  let currentFiscalYearEnd;
  let pastFiscalYearStart;
  let pastFiscalYearEnd;

  if (currentMonth >= 7) {
    // July or later
    currentFiscalYearStart = currentYear * 100 + 7;
    currentFiscalYearEnd = (currentYear + 1) * 100 + 6;
    pastFiscalYearStart = (currentYear - 1) * 100 + 7;
    pastFiscalYearEnd = currentYear * 100 + 6;
  } else {
    // January to June
    currentFiscalYearStart = (currentYear - 1) * 100 + 7;
    currentFiscalYearEnd = currentYear * 100 + 6;
    pastFiscalYearStart = (currentYear - 2) * 100 + 7;
    pastFiscalYearEnd = (currentYear - 1) * 100 + 6;
  }

  return {
    current: {
      start: currentFiscalYearStart,
      end: currentFiscalYearEnd
    },
    past: {
      start: pastFiscalYearStart,
      end: pastFiscalYearEnd
    }
  };
};
