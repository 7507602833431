import { useFormContext, Controller } from 'react-hook-form';
import { Dropdown, TextInput } from '@gofan/components';

import './SponsorshipDetails.scss';

const SponsorshipDetails = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  // Function to format phone number with dashes
  const formatPhoneNumber = value => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, ''); // Remove all non-digit characters
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  // Function to handle input change
  const handleInputChange = (e, onChange) => {
    const formattedValue = formatPhoneNumber(e.target.value);
    const rawValue = formattedValue.replace(/-/g, ''); // Remove dashes for storage
    onChange(rawValue); // Store unformatted value
    e.target.value = formattedValue; // Update input with formatted value
  };

  return (
    <div className='gf--sponsorship-details'>
      <div className='gf--sponsorship-details-section'>
        <div className='gf--sponsorship-details__component'>
          <Controller
            control={control}
            name='sponsorshipName'
            render={({ field }) => (
              <TextInput
                id='sponsorshipName'
                {...field}
                labelText='Sponsorship name'
                placeholder='Enter name'
                maxLength={250}
                value={field.value}
                disabled={false}
                rows={3}
                invalid={errors?.sponsorshipName}
                invalidText={errors?.sponsorshipName?.message}
              />
            )}
          />
        </div>

        <div className='gf--sponsorship-details__component'>
          <Controller
            control={control}
            name='sponsorshipType'
            render={() => (
              <Dropdown
                id='sponsorship_type'
                titleText='Sponsorship type'
                label='Digital Ticket Sponsor'
                disabled
                items={['']}
              />
            )}
          />
        </div>
      </div>
      <div className='gf--sponsorship-details-section'>
        <div className='gf--sponsorship-details__component'>
          <Controller
            control={control}
            name='emailAddress'
            render={({ field }) => (
              <TextInput
                id='emailAddress'
                {...field}
                labelText='Email address'
                placeholder='Enter email address'
                maxLength={250}
                value={field.value}
                disabled={false}
                rows={3}
                invalid={errors?.emailAddress}
                invalidText={errors?.emailAddress?.message}
              />
            )}
          />
        </div>

        <div className='gf--sponsorship-details__component'>
          <Controller
            control={control}
            name='phoneNumber'
            render={({ field }) => (
              <TextInput
                id='phoneNumber'
                {...field}
                labelText='Phone number'
                placeholder='000-000-0000'
                maxLength={12}
                onChange={e => handleInputChange(e, field.onChange)}
                value={formatPhoneNumber(field.value)}
                disabled={false}
                rows={3}
                invalid={errors?.phoneNumber}
                invalidText={errors?.phoneNumber?.message}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export { SponsorshipDetails };
