import {
  RateInformationActionTypes,
  RESET_STATE,
  SEARCH_ACCOUNTS,
  SEARCH_ACCOUNTS_COMPLETED,
  GET_RATE_BY_ID,
  GET_RATE_BY_ID_COMPLETED,
  GET_RATE_BY_ID_SUCCESSFUL,
  GET_RATE_BY_ID_FAIL,
  FETCH_PRODUCT_TYPES_SUCCESSFUL,
  FETCH_PRODUCT_TYPES_FAIL,
  FETCH_PRODUCT_TYPES,
  FETCH_PRODUCT_TYPES_COMPLETED,
  UPDATE_RATE,
  UPDATE_RATE_SUCCESSFUL,
  UPDATE_RATE_FAIL,
  UPDATE_RATE_COMPLETED,
  CREATE_RATE_SUCCESSFUL,
  CREATE_RATE_FAIL,
  CREATE_RATE_COMPLETED,
  CREATE_RATE,
  INITIAL_DATA_COMPLETED,
  INITIAL_DATA
} from './actionTypes';
import { Rate } from '../../api/types';

export const resetState = (): RateInformationActionTypes => ({
  type: RESET_STATE
});

export const initData = (editMode: boolean, id: string, expands: [], history: any): RateInformationActionTypes => ({
  type: INITIAL_DATA,
  payload: {
    editMode,
    id,
    expands,
    history
  }
});

export const initDataCompleted = (productTypes: [], rate: Rate): RateInformationActionTypes => ({
  type: INITIAL_DATA_COMPLETED,
  payload: {
    productTypes,
    rate
  }
});

export const searchAccounts = (keyword: string): RateInformationActionTypes => ({
  type: SEARCH_ACCOUNTS,
  payload: {
    keyword
  }
});
export const searchAccountsCompleted = (accounts: []): RateInformationActionTypes => ({
  type: SEARCH_ACCOUNTS_COMPLETED,
  payload: {
    accounts
  }
});
export const fetchRateById = (id: string, expands: [], history: any): RateInformationActionTypes => ({
  type: GET_RATE_BY_ID,
  payload: {
    id,
    expands,
    history
  }
});
export const fetchRateByIdCompleted = (rate: Rate): RateInformationActionTypes => ({
  type: GET_RATE_BY_ID_COMPLETED,
  payload: {
    rate
  }
});
export const fetchRateByIdSuccessful = (rate: Rate): RateInformationActionTypes => ({
  type: GET_RATE_BY_ID_SUCCESSFUL,
  payload: {
    rate
  }
});
export const fetchRateByIdFail = (): RateInformationActionTypes => ({
  type: GET_RATE_BY_ID_FAIL
});
export const fetchProductTypes = (): RateInformationActionTypes => ({
  type: FETCH_PRODUCT_TYPES
});
export const fetchProductTypesCompleted = (productTypes: []): RateInformationActionTypes => ({
  type: FETCH_PRODUCT_TYPES_COMPLETED,
  payload: {
    productTypes
  }
});
export const fetchProductTypesFail = (): RateInformationActionTypes => ({
  type: FETCH_PRODUCT_TYPES_FAIL
});
export const fetchProductTypesSuccessful = (productTypes: []): RateInformationActionTypes => ({
  type: FETCH_PRODUCT_TYPES_SUCCESSFUL,
  payload: {
    productTypes
  }
});

export const updateRate = (rate: Rate, history: any): RateInformationActionTypes => ({
  type: UPDATE_RATE,
  payload: {
    rate,
    history
  }
});
export const updateRateSuccessful = (rate: Rate): RateInformationActionTypes => ({
  type: UPDATE_RATE_SUCCESSFUL,
  payload: {
    rate
  }
});
export const updateRateCompleted = (rate: Rate, history: any): RateInformationActionTypes => ({
  type: UPDATE_RATE_COMPLETED,
  payload: {
    rate,
    history
  }
});
export const updateRateFail = (): RateInformationActionTypes => ({
  type: UPDATE_RATE_FAIL
});
export const createRate = (rate: Rate, history: any): RateInformationActionTypes => ({
  type: CREATE_RATE,
  payload: {
    rate,
    history
  }
});
export const createRateSuccessful = (rate: Rate): RateInformationActionTypes => ({
  type: CREATE_RATE_SUCCESSFUL,
  payload: {
    rate
  }
});
export const createRateCompleted = (rate: Rate): RateInformationActionTypes => ({
  type: CREATE_RATE_COMPLETED,
  payload: {
    rate
  }
});
export const createRateFail = (): RateInformationActionTypes => ({
  type: CREATE_RATE_FAIL
});
