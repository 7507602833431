import LoadingPage from './LoadingPage';
import { LOADING_CONTENT_TYPES } from '../constants';

export default function LoadingContent(props) {
  let component;

  switch (props.type) {
    case LOADING_CONTENT_TYPES.PAGE:
      component = <LoadingPage {...props} />;
      break;

    default:
      component = <h1>Loading...</h1>;
  }

  return component;
}
