import moment from 'moment';
import { uniqBy, isEmpty, xorBy } from 'lodash';

import { EVENT_DATE_FORMAT_WITH_TIMEZONE } from '@utils/dateUtils';

import type { SortBy } from '@old-components/basic-table/sorting';
import { sortStates } from '@old-components/basic-table/sorting';

import type { Pill } from '@old-components/filter-pills/filter-pills.component';
import type { AccountDTO, UserAccountContext } from '@gofan/api/accounts';
import type { ActivityDTO } from '@gofan/api/activities';
import type { AccountSelection, FormValues } from '@events/components/event-filter-form/event-filter-form.component';
import type { EventSearchParams } from '@events/services/events.service';
import {
  getCachedDashboardActiveAccounts,
  getCachedDashboardSelectedAccounts,
  getCachedEventFilters
} from '@events/utils/local-storage-cache.utils';
import { PAGES } from '@app/config/routes';
import type { VenueDTO } from '@gofan/api/venues';
import type { LevelDTO } from '@gofan/api/levels';
import { QUICK_FILTER_TYPE, EVENT_CATEGORIES_FILTERS } from '@events/constants';
import type { EventDTO } from '../models/event.model';
import { EVENT_TABLE } from '@dashboard/hooks';
import type { EventTable } from '@dashboard/hooks';

export type Gender = 'Boys' | 'Girls' | 'Coed';

export const defaultEndDate = moment(new Date())
  .subtract(5, 'days')
  .set('hour', 0)
  .set('minutes', 0)
  .set('seconds', 1)
  .format(EVENT_DATE_FORMAT_WITH_TIMEZONE);

export const SEARCH_PARAMS: EventSearchParams = {
  page: 0,
  pageSize: 25,
  body: { endingAfterEndDate: defaultEndDate },
  sortBy: { header: 'startDateTime', sortDirection: sortStates.ASC }
};

export const getSortBy = ({ header, sortDirection }: SortBy) => {
  if (!sortDirection || sortDirection === sortStates.NONE) {
    if (isEmpty(SEARCH_PARAMS.sortBy) || `${SEARCH_PARAMS.sortBy.header}` === `${header}`) {
      return {};
    }
    return SEARCH_PARAMS.sortBy;
  }
  return { header, sortDirection };
};

export type EventFilterPills = {
  [k: string]: Pill;
};

export const EVENT_FILTER_STATUS_OPTIONS = {
  visible: 'Visible',
  hidden: 'Hidden',
  canceled: 'Canceled'
};

export const EVENT_FILTER_PILLS: EventFilterPills = {
  todayEvent: { id: 'todayEvent', text: "Today's Event", type: 'timeRange' },
  upcomingEvent: { id: 'upcomingEvent', text: 'Upcoming events', type: 'timeRange' },
  last7Days: { id: 'last7Days', text: 'Last 7 days', type: 'timeRange' },
  allEvent: { id: 'allEvent', text: 'All events', type: 'timeRange' },
  away: { id: 'away', text: 'Away', type: 'away' },
  home: { id: 'home', text: 'Home', type: 'home' },
  boys: { id: 'boys', text: 'Boys', type: 'boys' },
  girls: { id: 'girls', text: 'Girls', type: 'girls' },
  coed: { id: 'coed', text: 'Coed', type: 'coed' },
  canceled: { id: 'canceled', text: 'Canceled', type: 'canceled' },
  hidden: { id: 'hidden', text: 'Hidden', type: 'hidden' },
  visible: { id: 'visible', text: 'Visible', type: 'visible' },
  dateRange: { id: 'dateRange', text: 'Date range', type: 'dateRange' },
  specificDate: { id: 'specificDate', text: 'Specific date', type: 'specificDate' },
  regularSeason: { id: 'regularSeason', text: 'Regular season', type: 'regularSeason' },
  postSeason: { id: 'postSeason', text: 'Post season', type: 'postSeason' },
  nonAthletic: { id: 'nonAthletic', text: 'Non-athletic', type: 'nonAthletic' }
};

export const mappingPills = (formValues: FormValues, isDashboard?: boolean) => {
  if (!formValues || isEmpty(formValues)) return [];
  const pills = [];
  const { accounts = [], activities = [], levels = [] } = formValues;

  if (formValues.timeRange && formValues.timeRange !== QUICK_FILTER_TYPE.ALL_EVENTS)
    pills.push(EVENT_FILTER_PILLS[formValues.timeRange]);
  if (formValues.searchOption?.home) pills.push(EVENT_FILTER_PILLS.home);
  if (formValues.searchOption?.away) pills.push(EVENT_FILTER_PILLS.away);
  if (formValues.genders?.boys) pills.push(EVENT_FILTER_PILLS.boys);
  if (formValues.genders?.coed) pills.push(EVENT_FILTER_PILLS.coed);
  if (formValues.genders?.girls) pills.push(EVENT_FILTER_PILLS.girls);
  if (formValues.visibility?.canceled) pills.push(EVENT_FILTER_PILLS.canceled);
  if (formValues.visibility?.hidden) pills.push(EVENT_FILTER_PILLS.hidden);
  if (formValues.visibility?.visible) pills.push(EVENT_FILTER_PILLS.visible);
  if (formValues.endDate || formValues.startDate) pills.push(EVENT_FILTER_PILLS.dateRange);
  if (formValues.specificDate) pills.push(EVENT_FILTER_PILLS.specificDate);
  if (formValues.eventTypes?.regularSeason) pills.push(EVENT_FILTER_PILLS.regularSeason);
  if (formValues.eventTypes?.postSeason) pills.push(EVENT_FILTER_PILLS.postSeason);
  if (formValues.eventTypes?.nonAthletic) pills.push(EVENT_FILTER_PILLS.nonAthletic);
  if ((formValues?.categories ?? []).length > 0) {
    (formValues?.categories ?? []).forEach((id: string) => {
      const categoryPill = Object.values(EVENT_CATEGORIES_FILTERS).find(obj => obj.id === id);
      if (categoryPill) {
        pills.push(categoryPill);
      }
    });
  }

  if ((formValues?.teamLevels ?? []).length > 0) {
    (formValues?.teamLevels ?? []).forEach(item =>
      pills.push({
        id: item,
        name: item,
        value: item,
        text: item,
        type: 'teams'
      })
    );
  }

  if (!isEmpty(formValues.venues)) {
    formValues?.venues?.forEach(item => {
      if (isDashboard) {
        pills.push({
          id: item.id,
          value: item.id,
          name: item.name,
          text: item.name,
          type: 'venues'
        });
      } else {
        pills.push({
          id: item,
          name: item,
          value: item,
          text: item,
          type: 'venues'
        });
      }
    });
  }

  accounts.forEach((item: AccountSelection) => pills.push({ type: 'accounts', id: item.id, text: item.name || '' }));

  activities.forEach((item: ActivityDTO) => pills.push({ type: 'activities', id: item.id, text: item.label || '' }));

  levels.forEach((item: LevelDTO) => pills.push({ type: 'levels', id: item.id, text: item.name || '' }));
  return pills;
};

export const getSearchParamsByFilterValues = (
  filterValues: FormValues,
  accounts: AccountDTO[] | AccountSelection[] = []
) => {
  const {
    visibility,
    eventTypes,
    endDate: filteredEndDate,
    startDate: filteredStartDate,
    specificDate: filteredSpecificDate,
    accounts: filteredAccounts = [],
    activities: filteredActivities = [],
    genders,
    levels = [],
    searchOption: filteredSearchOption,
    venues: filteredVenues = [],
    timeRange,
    categories
  } = filterValues;

  let accountIds = filteredAccounts.map(item => `${item.id}`);
  if (isEmpty(accountIds)) {
    accountIds = accounts.map(item => `${item.id}`);
  }

  const activitiesIds = filteredActivities.map(item => `${item.id}`);

  const venueIds = filteredVenues?.map(item => item.id);

  let endDate;
  let startDate;
  let specificDate;
  let endingAfterEndDate;
  let endingAfterNow;

  // timeRange
  if (timeRange === QUICK_FILTER_TYPE.TODAY_EVENTS) {
    startDate = moment()
      .set({
        hours: 0,
        minutes: 0,
        seconds: 1,
        milliseconds: 0
      })
      .format(EVENT_DATE_FORMAT_WITH_TIMEZONE);
    endDate = moment()
      .set({
        hours: 23,
        minutes: 59,
        seconds: 59,
        milliseconds: 999
      })
      .format(EVENT_DATE_FORMAT_WITH_TIMEZONE);
  } else if (timeRange === QUICK_FILTER_TYPE.UPCOMING_EVENTS) {
    endingAfterNow = true;
  } else if (timeRange === QUICK_FILTER_TYPE.LAST_7_DAYS) {
    endDate = moment()
      .set({
        hours: 23,
        minutes: 59,
        seconds: 59,
        milliseconds: 999
      })
      .format(EVENT_DATE_FORMAT_WITH_TIMEZONE);
    startDate = moment()
      .subtract(6, 'days')
      .set({
        hours: 0,
        minutes: 0,
        seconds: 1,
        milliseconds: 0
      })
      .format(EVENT_DATE_FORMAT_WITH_TIMEZONE);
  } else if (timeRange === QUICK_FILTER_TYPE.ALL_EVENTS) {
    endingAfterEndDate = defaultEndDate;
  }

  if (filteredSpecificDate) {
    specificDate = moment(filteredSpecificDate).format(EVENT_DATE_FORMAT_WITH_TIMEZONE);
  } else {
    startDate = filteredStartDate
      ? moment(filteredStartDate)
          .set('hour', 0)
          .set('minutes', 0)
          .set('seconds', 1)
          .format(EVENT_DATE_FORMAT_WITH_TIMEZONE)
      : startDate;
    endDate = filteredEndDate
      ? moment(filteredEndDate)
          .set('hour', 23)
          .set('minutes', 59)
          .set('second', 59)
          .format(EVENT_DATE_FORMAT_WITH_TIMEZONE)
      : endDate;
  }

  const status = [];
  if (visibility?.canceled) status.push(EVENT_FILTER_STATUS_OPTIONS.canceled);
  if (visibility?.hidden) status.push(EVENT_FILTER_STATUS_OPTIONS.hidden);
  if (visibility?.visible) status.push(EVENT_FILTER_STATUS_OPTIONS.visible);

  const athletic = eventTypes?.nonAthletic ? false : undefined;
  let postSeason;
  if (eventTypes?.regularSeason !== eventTypes?.postSeason) {
    postSeason = eventTypes?.postSeason;
  }

  const levelGender: {
    genders?: Gender[];
    levels?: {
      levelId?: string;
      genders?: Gender[];
    }[];
  } = {};
  const coed = genders?.coed;
  const boys = genders?.boys;
  const girls = genders?.girls;
  const genderValues: Gender[] = [];

  if (coed) {
    genderValues.push('Coed');
  } else {
    if (boys) {
      genderValues.push('Boys');
    }
    if (girls) {
      genderValues.push('Girls');
    }
  }

  if (isEmpty(levels) && (boys || girls || coed)) {
    levelGender.genders = genderValues;
  } else if (!isEmpty(levels) && !boys && !girls && !coed) {
    levelGender.levels = levels.map(level => ({
      levelId: level.id as string
    }));
  } else if (!isEmpty(levels) && (boys || girls || coed)) {
    levelGender.levels = levels.map(level => ({
      levelId: level.id as string,
      genders: genderValues
    }));
  }

  // home - away event
  let searchOption;
  if (filteredSearchOption?.home && !filteredSearchOption?.away) {
    searchOption = 'Home';
  } else if (!filteredSearchOption?.home && filteredSearchOption?.away) {
    searchOption = 'Away';
  } else if (filteredSearchOption?.home && filteredSearchOption?.away) {
    searchOption = 'All';
  }

  let financialAccountIds;
  if (!searchOption || (!filteredSearchOption?.away && searchOption !== 'Home')) {
    financialAccountIds = accountIds;
  }

  const searchParams = {
    status: isEmpty(status) ? undefined : status,
    endDate: specificDate ? undefined : endDate,
    startDate: specificDate ? undefined : startDate,
    specificDate,
    accountIds,
    financialAccountIds,
    venueIds: isEmpty(venueIds) ? undefined : venueIds,
    activitiesIds: isEmpty(activitiesIds) ? undefined : activitiesIds,
    endingAfterNow: endDate || startDate || specificDate ? undefined : endingAfterNow,
    endingAfterEndDate: endDate || startDate || specificDate ? undefined : endingAfterEndDate,
    athletic,
    postSeason,
    genders: levelGender.genders,
    levels: levelGender.levels,
    searchOption,
    eventTypes: isEmpty(categories) ? undefined : categories
  };

  return searchParams;
};

export const getFilterParamsByPills = ({
  accounts,
  diffPills,
  filterValues,
  isDashboard
}: {
  accounts: AccountDTO[];
  diffPills: Pill[];
  filterValues: FormValues;
  isDashboard?: boolean;
}) => {
  let updatedFilterValues = { ...filterValues };

  diffPills.forEach((pill: Pill) => {
    if (pill.type === 'accounts') {
      const { accounts: filteredAccounts = [] } = updatedFilterValues;
      updatedFilterValues = {
        ...updatedFilterValues,
        accounts: filteredAccounts.filter((item: AccountDTO | AccountSelection) => item.id !== pill.id)
      };
    } else if (pill.type === 'activities') {
      const { activities = [] } = updatedFilterValues;
      updatedFilterValues = {
        ...updatedFilterValues,
        activities: activities.filter((item: ActivityDTO) => item.id !== pill.id)
      };
    } else if (pill.type === 'venues' && isDashboard) {
      const { venues = [] } = updatedFilterValues;
      updatedFilterValues = {
        ...updatedFilterValues,
        venues: venues.filter((item: VenueDTO) => item.id !== pill.id)
      };
    } else if (pill.type === EVENT_FILTER_PILLS.dateRange.type) {
      updatedFilterValues = {
        ...updatedFilterValues,
        endDate: undefined,
        startDate: undefined
      };
    } else if (pill.type === EVENT_FILTER_PILLS.specificDate.type) {
      updatedFilterValues = {
        ...updatedFilterValues,
        specificDate: undefined
      };
    } else if (pill.type === EVENT_FILTER_PILLS.canceled.type) {
      updatedFilterValues = {
        ...updatedFilterValues,
        visibility: {
          ...updatedFilterValues?.visibility,
          canceled: undefined
        }
      };
    } else if (pill.type === EVENT_FILTER_PILLS.hidden.type) {
      updatedFilterValues = {
        ...updatedFilterValues,
        visibility: {
          ...updatedFilterValues?.visibility,
          hidden: undefined
        }
      };
    } else if (pill.type === EVENT_FILTER_PILLS.visible.type) {
      updatedFilterValues = {
        ...updatedFilterValues,
        visibility: {
          ...updatedFilterValues?.visibility,
          visible: undefined
        }
      };
    } else if (pill.type === EVENT_FILTER_PILLS.regularSeason.type) {
      updatedFilterValues = {
        ...updatedFilterValues,
        eventTypes: {
          ...updatedFilterValues?.eventTypes,
          regularSeason: undefined
        }
      };
    } else if (pill.type === EVENT_FILTER_PILLS.postSeason.type) {
      updatedFilterValues = {
        ...updatedFilterValues,
        eventTypes: {
          ...updatedFilterValues?.eventTypes,
          postSeason: undefined
        }
      };
    } else if (pill.type === EVENT_FILTER_PILLS.nonAthletic.type) {
      updatedFilterValues = {
        ...updatedFilterValues,
        eventTypes: {
          ...updatedFilterValues?.eventTypes,
          nonAthletic: undefined
        }
      };
    } else if (pill.type === EVENT_FILTER_PILLS.boys.type) {
      updatedFilterValues = {
        ...updatedFilterValues,
        genders: {
          ...updatedFilterValues?.genders,
          boys: undefined
        }
      };
    } else if (pill.type === EVENT_FILTER_PILLS.girls.type) {
      updatedFilterValues = {
        ...updatedFilterValues,
        genders: {
          ...updatedFilterValues?.genders,
          girls: undefined
        }
      };
    } else if (pill.type === EVENT_FILTER_PILLS.coed.type) {
      updatedFilterValues = {
        ...updatedFilterValues,
        genders: {
          ...updatedFilterValues?.genders,
          coed: undefined
        }
      };
    } else if (pill.type === 'levels') {
      const { levels = [] } = updatedFilterValues;
      updatedFilterValues = {
        ...updatedFilterValues,
        levels: levels.filter((item: LevelDTO) => item.id !== pill.id)
      };
    } else if (pill.type === EVENT_FILTER_PILLS.home.type) {
      updatedFilterValues = {
        ...updatedFilterValues,
        searchOption: {
          ...updatedFilterValues?.searchOption,
          home: undefined
        }
      };
    } else if (pill.type === EVENT_FILTER_PILLS.away.type) {
      updatedFilterValues = {
        ...updatedFilterValues,
        searchOption: {
          ...updatedFilterValues?.searchOption,
          away: undefined
        }
      };
    } else if (pill.type === 'timeRange') {
      updatedFilterValues = {
        ...updatedFilterValues,
        timeRange: undefined
      };
    } else if (pill.type === 'categories') {
      updatedFilterValues = {
        ...updatedFilterValues,
        categories: [...(updatedFilterValues?.categories ?? []).filter(p => !pill.value?.includes(p))]
      };
    } else if (pill.type === 'teams') {
      updatedFilterValues = {
        ...updatedFilterValues,
        teamLevels: [...(updatedFilterValues?.teamLevels ?? []).filter(p => pill.id !== p)]
      };
    } else if (pill.type === 'venues' && !isDashboard) {
      updatedFilterValues = {
        ...updatedFilterValues,
        venues: [...(updatedFilterValues?.venues ?? []).filter(p => pill.id !== p)]
      };
    }
  });

  const searchParams = getSearchParamsByFilterValues(updatedFilterValues, accounts);
  return { searchParams, filterValues: updatedFilterValues };
};

export const getFilterParamsByAccounts = ({
  accounts,
  nextAccounts,
  filterValues,
  isFilterApplied
}: {
  accounts: AccountDTO[];
  nextAccounts: AccountDTO[];
  filterValues: FormValues;
  isFilterApplied: boolean;
}) => {
  // before applied filter
  if (!isFilterApplied || isEmpty(nextAccounts)) {
    const accountIds = isEmpty(nextAccounts) ? [] : nextAccounts.map(acc => acc.id);
    return {
      needToResetForm: true,
      // filterValues: {},
      filterValues: filterValues || {},
      searchParams: {
        archived: undefined,
        endDate: undefined,
        startDate: undefined,
        specificDate: undefined,
        activitiesIds: undefined,
        venueIds: undefined,
        endingAfterEndDate: defaultEndDate,
        accountIds,
        financialAccountIds: accountIds
      }
    };
  }

  // After applied filter
  const addedAccounts: AccountDTO[] = [];
  const removedAccounts: AccountDTO[] = [];
  const { accounts: filteredAccounts = [] } = filterValues;

  uniqBy([...accounts, ...nextAccounts], 'id').forEach((acc: AccountDTO) => {
    const found = accounts.find(item => item.id === acc.id);
    const nextFound = nextAccounts.find(item => item.id === acc.id);
    if (!found && nextFound) {
      addedAccounts.push(acc);
    } else if (found && !nextFound && filteredAccounts.find(item => item.id === acc.id)) {
      removedAccounts.push(acc);
    }
  });

  const nextFilteredAccounts = xorBy(filteredAccounts, removedAccounts, 'id');
  const updatedFilterValues = { ...filterValues, accounts: nextFilteredAccounts };
  const searchParams = getSearchParamsByFilterValues(updatedFilterValues, nextAccounts);
  return {
    needToResetForm: false,
    searchParams,
    filterValues: updatedFilterValues
  };
};

export const getSearchParamsAndFilterParamsFromCache = (
  paramAccountId: string,
  userAccountContexts: UserAccountContext[],
  history: any,
  isInternalUser: boolean,
  enableDistrictUnicorn: boolean
) => {
  const filterCache = getCachedEventFilters(isInternalUser ? 'eventLanding' : 'shared') || {};
  const eventFiltersCache =
    filterCache?.timeRange || filterCache?.specificDate || filterCache?.startDate || filterCache?.endDate
      ? { categories: [], ...filterCache }
      : { categories: [], ...filterCache, timeRange: QUICK_FILTER_TYPE.ALL_EVENTS };
  const activeAccountsCache = getCachedDashboardActiveAccounts() || [];
  const selectedAccountsCache = getCachedDashboardSelectedAccounts() || {};
  const searchParams = getSearchParamsByFilterValues(eventFiltersCache);

  if (isInternalUser) {
    eventFiltersCache.accounts = [];
    searchParams.accountIds = [paramAccountId];
    searchParams.financialAccountIds = [paramAccountId];
  } else if (paramAccountId) {
    const accountByParam = userAccountContexts.find(uac => !uac.inactive && uac.accountId === paramAccountId);
    if (accountByParam || enableDistrictUnicorn) {
      eventFiltersCache.accounts = [];
      searchParams.accountIds = [paramAccountId];
      searchParams.financialAccountIds = [paramAccountId];
    } else {
      history.replace(PAGES.eventsV2.accounts.path);
    }
  } else {
    const activeAccounts = userAccountContexts.filter(uac => !uac.inactive);
    let accountIds;
    if (isEmpty(eventFiltersCache.accounts)) {
      if (activeAccountsCache && selectedAccountsCache && selectedAccountsCache.length < activeAccountsCache.length) {
        eventFiltersCache.accounts = selectedAccountsCache;
        accountIds = selectedAccountsCache.map(({ id }) => id);
        searchParams.accountIds = accountIds;
        searchParams.financialAccountIds = accountIds;
      } else {
        eventFiltersCache.accounts = [];
        accountIds = activeAccounts.map(({ accountId }) => accountId);
        searchParams.accountIds = accountIds;
        searchParams.financialAccountIds = accountIds;
      }
    } else if (eventFiltersCache.accounts!.length === activeAccountsCache.length) {
      eventFiltersCache.accounts = [];
    }
  }
  return {
    searchParams,
    filterParams: eventFiltersCache
  };
};

export const getEventName = ({
  event,
  accounts,
  awayAccount,
  shouldUseEventNameFormat
}: {
  event: EventDTO;
  accounts: AccountDTO[];
  awayAccount: AccountDTO;
  shouldUseEventNameFormat: boolean;
}) => {
  if (!isEmpty(awayAccount) && shouldUseEventNameFormat && !event?.awayGame) {
    const homeSchool = accounts?.find(
      (account: AccountDTO) =>
        `${account.id}` === `${event?.accountId}` || `${account.id}` === `${event?.financialAccountId}`
    );

    return `${!isEmpty(homeSchool) ? 'vs' : 'at'} ${awayAccount?.shortName ?? awayAccount?.name ?? ''}`;
  }

  return event?.name;
};

export const getDefaultSearchParams = (kind: EventTable) => {
  const searchParams = { ...SEARCH_PARAMS };
  if (kind === EVENT_TABLE.TICKETING) {
    searchParams.body = { ...searchParams.body, ticketingRelated: true };
  } else if (kind === EVENT_TABLE.STREAMING) {
    searchParams.body = { ...searchParams.body, streamingRelated: true };
  }
  return searchParams;
};
