import { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

// import type { RouteChildrenProps } from 'react-router-dom';

const ExampleView = lazy(() =>
  import('./views/Example.view').then(module => ({
    default: module.ExampleView
  }))
);

// props: RouteChildrenProps

export const FanEngagementRoutes = () => (
  <Switch>
    <Route
      path='/' // in HQ integration (top-level router) this is currently /connect
      render={() => (
        <Suspense fallback={<div />}>
          <ExampleView />
        </Suspense>
      )}
    />
  </Switch>
);
