// flow

import { AxiosRequestConfig } from 'axios';
import {
  AuthActionTypes,
  CHECK_INVITATION_EMAIL,
  CHECK_INVITATION_EMAIL_STATUS,
  CHECK_STATUS,
  GET_USER_BY_EMAIL_TOKEN,
  PENING_EMAIL,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  SIGN_IN,
  SIGN_IN_ERRORS,
  SIGN_IN_SUCCESS,
  GET_USER_INFORMATION
} from './actionTypes';

export const signIn = (
  email: String,
  password: String,
  signInCallBack: Function
): AuthActionTypes => ({
  type: SIGN_IN,
  payload: {
    email,
    password,
    signInCallBack
  }
});

export const signInSuccess = (): AuthActionTypes => ({
  type: SIGN_IN_SUCCESS
});

export const sendPasswordResetEmail = (
  email: String,
  forGotCallback: Function
): AuthActionTypes => ({
  type: RESET_PASSWORD,
  payload: {
    email,
    forGotCallback
  }
});

export const resetPasswordSuccess = (): AuthActionTypes => ({
  type: RESET_PASSWORD_SUCCESS
});

export const checkPendingEmail = (
  email: String,
  configuration: AxiosRequestConfig,
  manualErrorHandling: boolean
): AuthActionTypes => ({
  type: PENING_EMAIL,
  payload: {
    email,
    configuration,
    manualErrorHandling,
  }
});

export const checkStatus = (status: Boolean): AuthActionTypes => ({
  type: CHECK_STATUS,
  payload: { status }
});

export const resendInvitationEmail = (email: String): AuthActionTypes => ({
  type: CHECK_INVITATION_EMAIL,
  payload: { email }
});

export const checkInvitationEmailStatus = (
  status: Boolean
): AuthActionTypes => ({
  type: CHECK_INVITATION_EMAIL_STATUS,
  payload: { status }
});

export const signInErrors = (error: any): AuthActionTypes => ({
  type: SIGN_IN_ERRORS,
  payload: {
    error
  }
});

export const getUserByEmailTokenAction = (
  emailToken: String
): AuthActionTypes => ({
  type: GET_USER_BY_EMAIL_TOKEN,
  payload: {
    emailToken
  }
});

export const getUserInform = (user: any): AuthActionTypes => ({
  type: GET_USER_INFORMATION,
  payload: {
    user
  }
});
