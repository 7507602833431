import ErrorDTO from '../dto/ErrorDTO';
import LevelDTO from '../dto/LevelDTO';
import axios from '../api/axios';
import { LEVEL_URI } from '../api/api';

const fetchAllLevels = () =>
  axios.huddleAxiosInstanceProxy
    .get(LEVEL_URI)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response.map(level => new LevelDTO(level));
    })
    .catch(e => new ErrorDTO(e));

const createLevel = level =>
  axios.huddleAxiosInstanceProxy
    .post(LEVEL_URI, level)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new LevelDTO(response);
    })
    .catch(e => new ErrorDTO(e));

const updateLevel = activity =>
  axios.huddleAxiosInstanceProxy
    .put(`${LEVEL_URI}${activity.id}`, activity)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new LevelDTO(response);
    })
    .catch(e => new ErrorDTO(e));

const deleteLevel = id =>
  axios.huddleAxiosInstanceProxy
    .delete(`${LEVEL_URI}${id}`)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return true;
    })
    .catch(e => new ErrorDTO(e));

export default {
  fetchAllLevels,
  createLevel,
  updateLevel,
  deleteLevel
};
