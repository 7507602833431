import axios from 'axios';
import { GET_FLATFILE_TOKEN, GET_FLATILE_RECORDS } from './flatfile.endpoints';

export class FlatfileRepository {
  static getFlatfileToken = (payload: any): Promise<string> =>
    axios.post(`${GET_FLATFILE_TOKEN}`, payload).then(response => response.data);

  static getFlatfileRecords = (sheetId: string): Promise<string> =>
    axios.get(GET_FLATILE_RECORDS.replace('{sheetId}', sheetId), {}).then(response => response.data);
}
