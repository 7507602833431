// @flow
import { isEmpty, upperFirst } from 'lodash';
import Venue from './Venue';
import Ticket from './Ticket';
import Account from './Account';
import Activity from './Activity';
import { TIME_ZONE_ENUM } from '../../utils/dateUtils';
import * as EventService from '../services/EventService';
import Level from './Level';
import EventSalesInfo from './EventSalesInfo';

class Event {
  constructor({
    restData,
    id,
    embedded,
    accountId,
    activityId,
    activityPath,
    alert,
    allDayEvent,
    archived,
    description,
    disableQr,
    disabledForIndividualSale,
    emailMessageId,
    enableEventValidation,
    endDateTime,
    eventNotes,
    eventTypeId,
    theme,
    eventValidationStartsBefore,
    featured,
    featuredAccountIds,
    financialAccountId,
    formId,
    genders,
    headerImage,
    hostAccountId,
    levelIds,
    maxCapacity,
    name,
    opponentAccountId,
    partnerId,
    postSeason,
    products,
    redemptionWindow,
    salesEndDateTime,
    salesStartDateTime,
    seasonId,
    specialEventDescription,
    startDateTime,
    taggedAccountIds,
    termsAndConditions,
    ticketLimitPerOrder,
    timeZone,
    venueId
  }) {
    this.raw = {
      restData,
      id,
      embedded,
      accountId,
      activityId,
      activityPath,
      alert,
      allDayEvent,
      archived,
      description,
      disableQr,
      disabledForIndividualSale,
      emailMessageId,
      enableEventValidation,
      endDateTime,
      eventNotes,
      eventTypeId,
      eventValidationStartsBefore,
      featured,
      featuredAccountIds,
      financialAccountId,
      formId,
      genders,
      headerImage,
      hostAccountId,
      levelIds,
      maxCapacity,
      name,
      opponentAccountId,
      partnerId,
      postSeason,
      products,
      redemptionWindow,
      salesEndDateTime,
      salesStartDateTime,
      seasonId,
      specialEventDescription,
      startDateTime,
      taggedAccountIds,
      termsAndConditions,
      ticketLimitPerOrder,
      timeZone,
      theme,
      venueId
    };
  }

  get restData() {
    return this.raw.restData;
  }

  get id() {
    return this.raw.id;
  }

  get embedded() {
    return this.raw.embedded;
  }

  get accountId() {
    return this.raw.accountId;
  }

  get account() {
    return this.raw.embedded.account ? new Account(this.raw.embedded.account) : null;
  }

  get activity() {
    return this.raw.embedded.activity ? new Activity(this.raw.embedded.activity) : null;
  }

  get activityId() {
    return this.raw.activityId;
  }

  get activityPath() {
    return this.raw.activityPath;
  }

  get alert() {
    return this.raw.alert;
  }

  get allDayEvent() {
    return this.raw.allDayEvent;
  }

  get archived() {
    return this.raw.archived;
  }

  get description() {
    return this.raw.description;
  }

  get disableQr() {
    return this.raw.disableQr;
  }

  get disabledForIndividualSale() {
    return this.raw.disabledForIndividualSale;
  }

  get emailMessageId() {
    return this.raw.emailMessageId;
  }

  get enableEventValidation() {
    return this.raw.enableEventValidation;
  }

  /**
   * endDateTime: UTC time
   * E.g: 2019-10-19T00:00:00+0000
   */
  get endDateTime() {
    return this.raw.endDateTime;
  }

  get eventNotes() {
    return this.raw.eventNotes;
  }

  get eventTypeId() {
    return this.raw.eventTypeId;
  }

  get eventType() {
    return this.raw.embedded.eventType ? this.raw.embedded.eventType.name : '';
  }

  get eventValidationStartsBefore() {
    return this.raw.eventValidationStartsBefore;
  }

  get featured() {
    return this.raw.featured;
  }

  get featuredAccountIds() {
    return this.raw.featuredAccountIds;
  }

  get financialAccountId() {
    return this.raw.financialAccountId;
  }

  get formId() {
    return this.raw.formId;
  }

  get genders() {
    return isEmpty(this.raw.genders) ? [] : this.raw.genders;
  }

  get theme() {
    return this.raw.theme;
  }

  get gendersToString() {
    if (isEmpty(this.raw.genders)) return '';

    return this.raw.genders.map(gender => upperFirst(gender)).join(' & ');
  }

  get headerImage() {
    return this.raw.headerImage;
  }

  get hostAccountId() {
    return this.raw.hostAccountId;
  }

  get hostAccount() {
    const hostAccount = this.raw.embedded.hostAccount ? new Account(this.raw.embedded.hostAccount) : null;

    return this.hostAccountId ? this.account : hostAccount;
  }

  get levelIds() {
    return this.raw.levelIds;
  }

  get levels() {
    return isEmpty(this.raw.embedded.levels) ? [] : this.raw.embedded.levels.map(level => new Level(level).toJSON());
  }

  get levelsToString() {
    if (isEmpty(this.levels)) return '';

    return this.levels.map(({ name }) => upperFirst(name)).join('/');
  }

  get maxCapacity() {
    return this.raw.maxCapacity;
  }

  get name() {
    if (!isEmpty(this.raw.name)) {
      return this.raw.name;
    }

    return EventService.generateEventName(this.account, this.opponentAccount);
  }

  get opponentAccountId() {
    return this.raw.opponentAccountId;
  }

  get opponentAccount() {
    return this.raw.embedded.opponentAccount ? new Account(this.raw.embedded.opponentAccount) : null;
  }

  get partnerId() {
    return this.raw.partnerId;
  }

  get postSeason() {
    return this.raw.postSeason;
  }

  get tickets() {
    if (isEmpty(this.raw.products)) return [];
    return this.raw.products.map(
      product =>
        new Ticket(
          product,
          this.eventSalesInfo && this.eventSalesInfo.productSalesMap
            ? this.eventSalesInfo.productSalesMap[product.id]
            : {},
          this.timeZone
        )
    );
  }

  get redemptionWindow() {
    return this.raw.redemptionWindow;
  }

  get salesEndDateTime() {
    return this.raw.salesEndDateTime;
  }

  get salesStartDateTime() {
    return this.raw.salesStartDateTime;
  }

  get seasonId() {
    return this.raw.seasonId;
  }

  get specialEventDescription() {
    return this.raw.specialEventDescription;
  }

  /**
   * startDateTime: UTC time
   * E.g: 2019-10-19T00:00:00+0000
   */
  get startDateTime() {
    return this.raw.startDateTime;
  }

  get taggedAccountIds() {
    return this.raw.taggedAccountIds;
  }

  get termsAndConditions() {
    return this.raw.termsAndConditions;
  }

  get venueId() {
    return this.raw.venueId;
  }

  get venue() {
    return this.raw.embedded.venue ? new Venue(this.raw.embedded.venue) : null;
  }

  get ticketLimitPerOrder() {
    return this.raw.ticketLimitPerOrder;
  }

  get timeZone() {
    return this.raw.timeZone || TIME_ZONE_ENUM.AMERICA_NEW_YORK;
  }

  get eventLevel() {
    const eventLevel = [];
    if (this.gendersToString) eventLevel.push(this.gendersToString);
    if (this.levelsToString) eventLevel.push(this.levelsToString);

    return [eventLevel.join(' ')];
  }

  get eventDescription() {
    const desc = [];

    if (!isEmpty(this.activity)) desc.push(this.activity.label);
    if (this.specialEventDescription) desc.push(this.specialEventDescription);

    return desc.join(' - ');
  }

  get eventSalesInfo() {
    return this.raw.embedded.eventSalesInfo ? new EventSalesInfo(this.raw.embedded.eventSalesInfo).toJSON() : null;
  }

  get isSold() {
    return this.eventSalesInfo && this.eventSalesInfo.eventTotalSaleQuantity > 0;
  }

  get eventCapacity() {
    return this.eventSalesInfo ? this.eventSalesInfo.eventCapacity : 0;
  }

  get eventTotalSaleQuantity() {
    return this.eventSalesInfo ? this.eventSalesInfo.eventTotalSaleQuantity : 0;
  }

  toJSON() {
    return {
      restData: this.restData,
      id: this.id,
      accountId: this.accountId,
      activityId: this.activityId,
      activityPath: this.activityPath,
      alert: this.alert,
      allDayEvent: this.allDayEvent,
      archived: this.archived,
      description: this.description,
      disableQr: this.disableQr,
      disabledForIndividualSale: this.disabledForIndividualSale,
      emailMessageId: this.emailMessageId,
      enableEventValidation: this.enableEventValidation,
      endDateTime: this.endDateTime,
      eventNotes: this.eventNotes,
      eventTypeId: this.eventTypeId,
      theme: this.theme,
      eventValidationStartsBefore: this.eventValidationStartsBefore,
      featured: this.featured,
      featuredAccountIds: this.featuredAccountIds,
      financialAccountId: this.financialAccountId,
      formId: this.formId,
      genders: this.genders,
      headerImage: this.headerImage,
      hostAccountId: this.hostAccountId,
      levels: this.levels,
      levelIds: this.levelIds,
      maxCapacity: this.maxCapacity,
      name: this.name,
      opponentAccountId: this.opponentAccountId,
      partnerId: this.partnerId,
      postSeason: this.postSeason,
      redemptionWindow: this.redemptionWindow,
      salesEndDateTime: this.salesEndDateTime,
      salesStartDateTime: this.salesStartDateTime,
      seasonId: this.seasonId,
      specialEventDescription: this.specialEventDescription,
      startDateTime: this.startDateTime,
      taggedAccountIds: this.taggedAccountIds,
      termsAndConditions: this.termsAndConditions,
      venueId: this.venueId,
      ticketLimitPerOrder: this.ticketLimitPerOrder,
      timeZone: this.timeZone,
      eventDescription: this.eventDescription,
      tickets: this.tickets.map(ticket => ticket.toJSON()),
      venue: isEmpty(this.venue) ? {} : this.venue.toJSON(),
      activity: isEmpty(this.activity) ? {} : this.activity.toJSON(),
      account: isEmpty(this.account) ? {} : this.account.toJSON(),
      hostAccount: isEmpty(this.hostAccount) ? {} : this.hostAccount.toJSON(),
      opponentAccount: isEmpty(this.opponentAccount) ? {} : this.opponentAccount.toJSON(),
      eventType: this.eventType,
      eventCapacity: this.eventCapacity,
      isSold: this.isSold,
      eventTotalSaleQuantity: this.eventTotalSaleQuantity
    };
  }
}

export default Event;
