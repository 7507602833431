import isEmpty from 'lodash/isEmpty';
import { SeasonRenewalRepository } from './season-renewal.repository';
import { config, DATE_FORMAT_DEFAULT_WITH_TIME, CAMPAIGN_STATUS } from '@gofan/constants';
import { DateUtils } from '@gofan/utils';

import type {
  SeasonRenewalDTO,
  SeasonRenewalFanDTO,
  SeasonRenewalFanExportRequest,
  SeasonRenewalFanExportResponse,
  SeasonRenewalUploadDTO
} from './season-renewal.model';

export class SeasonRenewalService {
  static getSeasonRenewalFanExportData = (
    seasonRenewalFanExportRequest: SeasonRenewalFanExportRequest
  ): Promise<SeasonRenewalFanExportResponse> =>
    SeasonRenewalRepository.getSeasonRenewalFanExportData(seasonRenewalFanExportRequest);

  static getSeasonRenewalFanExportUrl = (fileName: string) =>
    `https://${config.s3.BUCKET}.${config.s3.URL}/${fileName}`;

  static downloadSeasonRenewalCampaignTemplate = () =>
    `https://${config.s3.BUCKET}.${config.s3.URL}/${config.s3.TEMPLATE_SEASON_RENEWAL_CAMPAIGN}`;

  static createSeasonRenewal = (payload: SeasonRenewalUploadDTO) =>
    SeasonRenewalRepository.createSeasonRenewal(payload);

  static updateSeasonRenewal = (payload: SeasonRenewalDTO) => SeasonRenewalRepository.updateSeasonRenewal(payload);

  static getSeasonRenewalSeats = (seasonId: number) => SeasonRenewalRepository.getSeasonRenewalSeats(seasonId);

  static updateSeasonRenewalFan = (payload: SeasonRenewalFanDTO) =>
    SeasonRenewalRepository.updateSeasonRenewalFan(payload);

  static deleteSeasonRenewalFan = (payload: SeasonRenewalFanDTO) =>
    SeasonRenewalRepository.deleteSeasonRenewalFan(payload);

  static getSeasonRenewals = (seasonId: number) => SeasonRenewalRepository.getSeasonRenewals(seasonId);

  static getCampaignStatus = (seasonRenewal?: SeasonRenewalDTO, timeZone?: string) => {
    if (isEmpty(seasonRenewal)) return CAMPAIGN_STATUS.ENDED;
    const now = DateUtils.formatDateTime({
      date: new Date(),
      parseZone: true,
      timeZone
    }).toDate(DATE_FORMAT_DEFAULT_WITH_TIME);
    const endDate = DateUtils.formatDateTime({
      date: seasonRenewal?.renewalEnd,
      parseZone: true,
      timeZone
    }).toDate(DATE_FORMAT_DEFAULT_WITH_TIME);
    const startDate = DateUtils.formatDateTime({
      date: seasonRenewal?.renewalStart,
      parseZone: true,
      timeZone
    }).toDate(DATE_FORMAT_DEFAULT_WITH_TIME);
    if (now > endDate) {
      return CAMPAIGN_STATUS.ENDED;
    }
    if (now > startDate && now <= endDate) {
      return CAMPAIGN_STATUS.LIVE;
    }
    return CAMPAIGN_STATUS.FUTURE;
  };
}
