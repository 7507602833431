import React, { useEffect, useState, useContext } from 'react';
import { Form, Input, Popup, Button } from 'semantic-ui-react';
import { GithubPicker } from 'react-color';
import styled from 'styled-components';

import { FastFormContext } from './FastFormBase';

const styles = {
  marginRight: '0px',
  borderRadius: 0,
  border: 'none'
};

const Label = styled.label``;

const ColorPickerButton = ({ onChange, innerValue }) => {
  const handleChange = ({ hex }) => onChange(hex.slice(1, 7).toUpperCase());
  return (
    <React.Fragment>
      <Popup
        trigger={<Button icon='pencil' style={styles} />}
        on='click'
        position='top right'
        content={() => (
          <GithubPicker
            color={innerValue}
            onChange={handleChange}
            triangle='hide'
          />
        )}
      />
    </React.Fragment>
  );
};

const validHexCode = string => {
  const regexString = /^(?:[0-9a-fA-F]{3}){1,2}$/;
  return regexString.test(string);
};

export default ({ formKey, label, overwritable }) => {
  const fastFormContext = useContext(FastFormContext);
  const formValue = fastFormContext.form[formKey];
  const [innerValue, setInnerValue] = useState(formValue.value);
  const borderColor = () => {
    if (formValue.valid === false && formValue.touched) {
      return '#f00';
    }
    if (formValue.focused) {
      return '#00f';
    }
    return '#ccc';
  };
  useEffect(() => {
    fastFormContext.updateField(formKey, { ...formValue, inForm: true });
  }, []);
  useEffect(
    () => {
      const valid = validHexCode(innerValue);
      let { errors } = formValue;
      if (!valid) {
        errors = ['Invalid Hex Code'];
      } else {
        errors = [];
      }
      fastFormContext.updateField(formKey, {
        ...formValue,
        value: innerValue,
        valid,
        errors
      });
    },
    [innerValue]
  );
  return (
    <Form.Field>
      <Label>{label}</Label>
      <Input
        type='text'
        style={{
          borderRadius: 0,
          border: `2px solid ${borderColor()}`,
          backgroundColor: overwritable ? '#fdfcea' : '#fff'
        }}
        value={innerValue}
      >
        <div
          style={{
            border: 'none',
            width: '40px',
            backgroundColor: `#${innerValue}`
          }}
        />
        <input
          style={{
            borderRadius: 0,
            border: 'none',
            backgroundColor: overwritable ? '#fdfcea' : '#fff'
          }}
          onChange={({ target: { value } }) => setInnerValue(value)}
          onFocus={() => fastFormContext.focusField(formKey, formValue)}
          onBlur={() => fastFormContext.blurField(formKey, formValue)}
        />
        <ColorPickerButton onChange={c => setInnerValue(c)} />
      </Input>
      {formValue.errors &&
        formValue.errors.length > 0 &&
        formValue.errors.map(e => <p>{e}</p>)}
    </Form.Field>
  );
};
