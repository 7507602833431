import { SelectableTile, Checkbox } from 'carbon-components-react';
import icons from '@assets/images/activity-icons/activity-icons';

import type { ActivityDTO } from '@gofan/api/activities';
import type { IconsType } from '@assets/images/activity-icons/activity-icons';

import './SportsSelection.scss';
import { TableToolbar, TableToolbarContent, TableToolbarSearch } from '@gofan/components';
import { useState } from 'react';

const SportsSelection = ({
  activities,
  selectedActivities,
  onUpdateActivities
}: {
  activities: ActivityDTO[];
  selectedActivities: ActivityDTO[];
  onUpdateActivities: (updated: ActivityDTO[]) => void;
}) => {
  const [filteredActivities, setFilteredActivities] = useState(activities);

  function handleTextChange(e: { target: HTMLInputElement }) {
    const searchText = e.target.value;
    const searchTextNoSpaces = searchText.replace(/\s+/g, '');
    if (searchTextNoSpaces.length >= 2) {
      setFilteredActivities(
        activities.filter(activity =>
          String(activity.label.replace(/\s+/g, ''))?.toLowerCase().includes(searchTextNoSpaces.toLowerCase())
        )
      );
    } else {
      setFilteredActivities(activities);
    }
  }

  const sportsTiles = filteredActivities.map((activity: ActivityDTO) => (
    <SelectableTile
      className='season-setup-modal__sport-tile'
      id={`${activity.id}`}
      key={activity.id}
      value={activity.id}
      light
      selected={
        (selectedActivities.length !== 0 && selectedActivities.length === activities.length) ||
        selectedActivities.findIndex((sport: ActivityDTO) => sport.label === activity.label) >= 0
      }
      onChange={() => {
        const updatedSelectedActivities = [...selectedActivities];
        const selectedSportIndex = updatedSelectedActivities.findIndex(
          (sport: ActivityDTO) => sport.label === activity.label
        );

        if (selectedSportIndex > -1) {
          updatedSelectedActivities.splice(selectedSportIndex, 1);
        } else {
          updatedSelectedActivities.push(activity);
        }

        onUpdateActivities(updatedSelectedActivities);
      }}
    >
      <div className='season-setup-modal__tile-content'>
        <Checkbox
          checked={
            (selectedActivities.length !== 0 && selectedActivities.length === activities.length) ||
            selectedActivities.findIndex((sport: ActivityDTO) => sport.label === activity.label) >= 0
          }
          id={activity.label}
          labelText=''
        />
        <div className='season-setup-modal__tile-sport-details'>
          <img
            src={(icons as IconsType)[`${activity.label}`.replace(/ /g, '').toLowerCase()] || icons?.unlabeled}
            alt={activity.label}
            className={`season-setup-modal__${activity.label}-activity-icon`}
          />
          <p>{activity.label}</p>
        </div>
      </div>
    </SelectableTile>
  ));

  return (
    <div className='season-setup-modal__tile-container'>
      <div className='gs--margin-bottom-sp5'>
        <TableToolbar>
          <TableToolbarContent>
            <TableToolbarSearch placeholder='Search for a team' onChange={e => handleTextChange(e)} />
          </TableToolbarContent>
        </TableToolbar>
      </div>
      {sportsTiles}
    </div>
  );
};

export default SportsSelection;
