import { EVENT_TYPES, PRODUCT_TYPE } from '@gofan/constants';

import type { FormState } from '@bulk-edit-events/utils';
import type { ProgressSection } from '@events/components/editor/EditorProgress';
import type { SettingSection } from '@app/modules/events/components/editor/EditorSettings';

export const BULK_EDIT_EVENT_TYPES = {
  EVENT: 'event',
  MOBILE_PASS: 'mobile-pass',
  FUNDRAISER: 'fundraiser',
  CONCESSION: 'concession'
};

export const BULK_EDIT_EVENT_ACTIVITY_TYPES = {
  [BULK_EDIT_EVENT_TYPES.EVENT]: EVENT_TYPES.ATHLETIC,
  [BULK_EDIT_EVENT_TYPES.MOBILE_PASS]: EVENT_TYPES.MOBILE_PASS,
  [BULK_EDIT_EVENT_TYPES.FUNDRAISER]: EVENT_TYPES.FUNDRAISER,
  [BULK_EDIT_EVENT_TYPES.CONCESSION]: EVENT_TYPES.CONCESSION
};

export const EVENT_TICKET_TYPE_LABELS = {
  [BULK_EDIT_EVENT_TYPES.CONCESSION]: 'Concession',
  [BULK_EDIT_EVENT_TYPES.EVENT]: 'Ticket',
  [BULK_EDIT_EVENT_TYPES.MOBILE_PASS]: 'Pass',
  [BULK_EDIT_EVENT_TYPES.FUNDRAISER]: 'Fundraiser'
};

export const EVENT_PRODUCT_TYPES = {
  [BULK_EDIT_EVENT_TYPES.CONCESSION]: PRODUCT_TYPE.CONCESSION,
  [BULK_EDIT_EVENT_TYPES.EVENT]: PRODUCT_TYPE.TICKET,
  [BULK_EDIT_EVENT_TYPES.MOBILE_PASS]: PRODUCT_TYPE.MOBILEPASS,
  [BULK_EDIT_EVENT_TYPES.FUNDRAISER]: PRODUCT_TYPE.DONATION
};

export const SECTIONS = {
  EVENT_TYPE_SECTION: 'EVENT_TYPE_SECTION',
  OPPONENT_SECTION: 'OPPONENT_SECTION',
  EVENT_NAME_SECTION: 'EVENT_NAME_SECTION',
  DATE_TIME_SECTION: 'DATE_TIME_SECTION',
  GATE_MANAGEMENT_SECTION: 'GATE_MANAGEMENT_SECTION',
  VENUE_SECTION: 'VENUE_SECTION',
  EVENT_TICKETS_SECTION: 'EVENT_TICKETS_SECTION',
  EVENT_VISIBILITY_SECTION: 'EVENT_VISIBILITY_SECTION',
  EVENT_ALERT_SECTION: 'EVENT_ALERT_SECTION',
  EVENT_DESCRIPTION_SECTION: 'EVENT_DESCRIPTION_SECTION',
  POST_SEASON_SECTION: 'POST_SEASON_SECTION',
  TICKET_REDEMPTION_WINDOW_SECTION: 'TICKET_REDEMPTION_WINDOW_SECTION',
  FUNDRAISER_GOAL: 'FUNDRAISER_GOAL',
  PIN_TO_TOP: 'PIN_TO_TOP'
};

export const EVENT_SETTINGS: { [key: string]: ProgressSection[] } = {
  [BULK_EDIT_EVENT_TYPES.EVENT]: [
    {
      title: 'Event Setting',
      settingIndex: 0,
      settingLabel: '',
      progresses: [
        { id: SECTIONS.EVENT_TYPE_SECTION, label: 'Event type' },
        { id: SECTIONS.OPPONENT_SECTION, label: 'Opponent' },
        { id: SECTIONS.EVENT_NAME_SECTION, label: 'Event name' },
        { id: SECTIONS.DATE_TIME_SECTION, label: 'Date and time' },
        { id: SECTIONS.GATE_MANAGEMENT_SECTION, label: 'Gate management' },
        { id: SECTIONS.VENUE_SECTION, label: 'Venue' },
        { id: SECTIONS.EVENT_TICKETS_SECTION, label: 'Tickets' },
        { id: SECTIONS.EVENT_VISIBILITY_SECTION, label: 'Event visibility' },
        { id: SECTIONS.EVENT_ALERT_SECTION, label: 'Event alert' },
        { id: SECTIONS.EVENT_DESCRIPTION_SECTION, label: 'Custom description' },
        { id: SECTIONS.POST_SEASON_SECTION, label: 'Post season' }
      ]
    }
  ],
  [BULK_EDIT_EVENT_TYPES.MOBILE_PASS]: [
    {
      title: 'Pass Setting',
      settingIndex: 0,
      settingLabel: '',
      progresses: [
        { id: SECTIONS.EVENT_TYPE_SECTION, label: 'Pass type' },
        { id: SECTIONS.OPPONENT_SECTION, label: 'Opponent' },
        { id: SECTIONS.DATE_TIME_SECTION, label: 'Date and time' },
        { id: SECTIONS.TICKET_REDEMPTION_WINDOW_SECTION, label: 'Pass redemption window' },
        { id: SECTIONS.VENUE_SECTION, label: 'Venue' },
        { id: SECTIONS.EVENT_TICKETS_SECTION, label: 'Passes' },
        { id: SECTIONS.EVENT_VISIBILITY_SECTION, label: 'Pass visibility' },
        { id: SECTIONS.EVENT_ALERT_SECTION, label: 'Pass alert' },
        { id: SECTIONS.EVENT_DESCRIPTION_SECTION, label: 'Custom description' }
      ]
    }
  ],
  [BULK_EDIT_EVENT_TYPES.FUNDRAISER]: [
    {
      title: 'Fundraiser Setting',
      settingIndex: 0,
      settingLabel: '',
      progresses: [
        { id: SECTIONS.EVENT_NAME_SECTION, label: 'Fundraiser name' },
        { id: SECTIONS.EVENT_DESCRIPTION_SECTION, label: 'Description' },
        { id: SECTIONS.FUNDRAISER_GOAL, label: 'Goal' },
        { id: SECTIONS.OPPONENT_SECTION, label: 'Schools' },
        { id: SECTIONS.DATE_TIME_SECTION, label: 'Duration' },
        { id: SECTIONS.EVENT_VISIBILITY_SECTION, label: 'Visibility' },
        { id: SECTIONS.VENUE_SECTION, label: 'Venue setup' },
        { id: SECTIONS.PIN_TO_TOP, label: 'Pin to top' },
        { id: SECTIONS.EVENT_TICKETS_SECTION, label: 'Donation levels' }
      ]
    }
  ],
  [BULK_EDIT_EVENT_TYPES.CONCESSION]: [
    {
      title: 'Concession Setting',
      settingIndex: 0,
      settingLabel: '',
      progresses: [
        { id: SECTIONS.EVENT_NAME_SECTION, label: 'Name' },
        { id: SECTIONS.EVENT_TYPE_SECTION, label: 'Concession Type' },
        { id: SECTIONS.OPPONENT_SECTION, label: 'Schools' },
        { id: SECTIONS.DATE_TIME_SECTION, label: 'Date and time' },
        { id: SECTIONS.VENUE_SECTION, label: 'Venue' },
        { id: SECTIONS.EVENT_TICKETS_SECTION, label: 'Concession items' }
      ]
    }
  ]
};

export const EVENT_UPDATES: SettingSection[] = [
  {
    title: 'Your updates',
    editorSettings: []
  }
];

export const NON_ATHLETIC_SPORT = [
  EVENT_TYPES.PERFORMING_ART,
  EVENT_TYPES.REGISTRATION,
  EVENT_TYPES.SCHOOL_DANCE,
  EVENT_TYPES.PROM
];

export const FIELDS_TO_SECTIONS: { [key in keyof FormState]: string } = {
  activity: SECTIONS.EVENT_TYPE_SECTION,
  genders: SECTIONS.EVENT_TYPE_SECTION,
  levels: SECTIONS.EVENT_TYPE_SECTION,
  opponentAccount: SECTIONS.OPPONENT_SECTION,
  taggedAccounts: SECTIONS.OPPONENT_SECTION,
  noSelectedRoleAccounts: SECTIONS.OPPONENT_SECTION,
  eventName: SECTIONS.EVENT_NAME_SECTION,
  startDate: SECTIONS.DATE_TIME_SECTION,
  startTime: SECTIONS.DATE_TIME_SECTION,
  endDate: SECTIONS.DATE_TIME_SECTION,
  endTime: SECTIONS.DATE_TIME_SECTION,
  allDayEvent: SECTIONS.DATE_TIME_SECTION,
  startTimeType: SECTIONS.DATE_TIME_SECTION,
  eventValidationStartsBefore: SECTIONS.GATE_MANAGEMENT_SECTION,
  enableEventValidation: SECTIONS.GATE_MANAGEMENT_SECTION,
  disableQr: SECTIONS.GATE_MANAGEMENT_SECTION,
  venueId: SECTIONS.VENUE_SECTION,
  venueName: SECTIONS.VENUE_SECTION,
  venueAddress: SECTIONS.VENUE_SECTION,
  venueCity: SECTIONS.VENUE_SECTION,
  venueState: SECTIONS.VENUE_SECTION,
  venueZip: SECTIONS.VENUE_SECTION,
  venueLocation: SECTIONS.VENUE_SECTION,
  products: SECTIONS.EVENT_TICKETS_SECTION,
  disabledAllTicketsForSale: SECTIONS.EVENT_TICKETS_SECTION,
  archived: SECTIONS.EVENT_VISIBILITY_SECTION,
  featuredAccountIds: SECTIONS.EVENT_VISIBILITY_SECTION,
  alert: SECTIONS.EVENT_ALERT_SECTION,
  theme: SECTIONS.EVENT_DESCRIPTION_SECTION,
  customSportName: SECTIONS.EVENT_DESCRIPTION_SECTION,
  description: SECTIONS.EVENT_DESCRIPTION_SECTION,
  postSeason: SECTIONS.POST_SEASON_SECTION,
  redemptionWindow: SECTIONS.TICKET_REDEMPTION_WINDOW_SECTION,
  goal: SECTIONS.FUNDRAISER_GOAL,
  account: SECTIONS.OPPONENT_SECTION,
  financialAccount: SECTIONS.OPPONENT_SECTION
};

export const OMIT_FIELD_STATE = ['noSelectedRoleAccounts'];

export const REDEMPTION_LIMIT = {
  MIN: 0,
  MAX: 1608
};

export const START_DATE_LIMIT_DAYS = 90;

export const GOAL_LIMIT = {
  MIN: 1,
  MAX: 1000000
};
