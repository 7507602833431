import { Route, Switch } from 'react-router-dom';
import { PageTitle } from '@app/components/PageTitle';

import { useState, useEffect, useMemo, useCallback, Suspense, lazy } from 'react';
import { useEvent, EventService } from '@gofan/api/events';

import OldEventService from '@events/services/events.service';
import { addNotification } from '@gofan/utils/toast';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { ConcessionEventSchema, ConcessionProductSchema } from '@gofan/api/concessions';
import { PAGES } from '@config/routes';

import type { EventDTO } from '@events/models/event.model';
import type { ConcessionEvent, ConcessionProducts } from '@gofan/api/concessions';
import type { RouteChildrenProps } from 'react-router-dom';

import './Concessions.scss';
import type { AccountDTO } from '@gofan/api';

const ConcessionEventEditor = lazy(() =>
  import('./views/ConcessionEventEditor').then(module => ({
    default: module.ConcessionEventEditor
  }))
);

const ConcessionProductEditor = lazy(() =>
  import('./views/ConcessionProductEditor').then(module => ({
    default: module.ConcessionProductEditor
  }))
);

const ConcessionDetails = lazy(() =>
  import('./views/ConcessionDetails').then(module => ({
    default: module.ConcessionDetails
  }))
);

const Concessions = (props: RouteChildrenProps) => {
  const [concession, setConcession] = useState<EventDTO | Partial<EventDTO>>();
  const [hasChanges, setHasChanges] = useState({ event: false, products: false });
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const [financialAccountChange, setFinancialAccountChange] = useState<AccountDTO>();

  const handleFinancialAccountChange = useCallback((financialAccount: AccountDTO) => {
    setFinancialAccountChange(financialAccount);
  }, []);

  const mode = useMemo(() => {
    if (props.location.pathname.includes('create')) return 'create';
    if (props.location.pathname.includes('edit')) return 'edit';
    return 'view';
  }, [props.location.pathname]);

  const {
    data: eventData,
    isLoading,
    invalidateEvent
  } = useEvent({
    eventId: new URL(window.location.href).pathname.split('/').filter(Boolean).pop() ?? '',
    queryOptions: { enabled: mode === 'edit' || mode === 'view' }
  });

  const onDefaultFormValuesChange = useCallback((type: 'event' | 'products') => {
    setHasChanges(prev => ({ ...prev, [type]: true }));
  }, []);

  useEffect(() => {
    let data = eventData as EventDTO;
    if (props.location && props.location.state && props.location.state?.duplicatedEvent?.products) {
      data = { ...data, products: props.location.state.duplicatedEvent.products };
      setHasChanges({ ...hasChanges, products: true });
    }
    if (isNil(concession)) {
      setConcession(data);
    }
  }, [concession, eventData, props.location]);

  return (
    <Switch>
      <Route
        exact
        key={PAGES.concessions.eventEditor.create.title}
        path={PAGES.concessions.eventEditor.create.path}
        render={routeProps => (
          <Suspense fallback={<div />}>
            <main className='gf-concessions-container'>
              <PageTitle title={PAGES.concessions.eventEditor.create.title} customClass='gf-concession-title' />

              <ConcessionEventEditor
                {...props}
                {...routeProps}
                event={routeProps.location.state?.duplicatedEvent}
                concession={
                  !isNil(concession)
                    ? (pick(concession, Object.keys(ConcessionEventSchema.fields)) as ConcessionEvent)
                    : undefined
                }
                onFinancialAccountChange={handleFinancialAccountChange}
                onNext={(values: ConcessionEvent) => {
                  setConcession(prev => {
                    if (!isNil(prev)) return { ...prev, ...values } as EventDTO;
                    return { ...values } as Partial<EventDTO>;
                  });
                  props.history.push(PAGES.concessions.productsEditor.create.path);
                }}
              />
            </main>
          </Suspense>
        )}
      />

      <Route
        exact
        key={PAGES.concessions.productsEditor.create.title}
        path={PAGES.concessions.productsEditor.create.path}
        render={routeProps => (
          <Suspense fallback={<div />}>
            <main className='gf-concessions-container'>
              <PageTitle title={PAGES.concessions.productsEditor.create.title} customClass='gf-concession-title' />
              <ConcessionProductEditor
                {...props}
                {...routeProps}
                products={
                  !isNil(concession?.products)
                    ? (pick(concession, Object.keys(ConcessionProductSchema.fields)) as ConcessionProducts)
                    : undefined
                }
                financialAccount={financialAccountChange}
                onPublish={({ products }: ConcessionProducts) => {
                  _onCreateConcession({ ...concession, products });
                }}
                hasChanges={hasChanges.products === true}
                onDefaultFormValuesChange={onDefaultFormValuesChange}
                isPublishing={isPublishing}
              />
            </main>
          </Suspense>
        )}
      />

      <Route
        exact
        key={PAGES.concessions.eventEditor.edit.title}
        path={PAGES.concessions.eventEditor.edit.path}
        render={routeProps => (
          <Suspense fallback={<div />}>
            <main className='gf-concessions-container'>
              <PageTitle title={PAGES.concessions.eventEditor.edit.title} customClass='gf-concession-title' />

              {!isLoading && !isNil(concession) && (
                <ConcessionEventEditor
                  {...props}
                  {...routeProps}
                  concession={pick(concession, Object.keys(ConcessionEventSchema.fields)) as ConcessionEvent}
                  event={eventData}
                  onNext={(values: ConcessionEvent) => {
                    setConcession(prev => ({ ...prev, ...values } as EventDTO));
                    props.history.push(PAGES.concessions.productsEditor.edit.calculatePath(concession.id));
                  }}
                  onFinancialAccountChange={handleFinancialAccountChange}
                  onPublish={(values: ConcessionEvent) => {
                    _onUpdateConcession({ ...concession, ...values } as EventDTO);
                  }}
                  hasChanges={hasChanges.event === true || hasChanges.products === true}
                  onDefaultFormValuesChange={onDefaultFormValuesChange}
                />
              )}
            </main>
          </Suspense>
        )}
      />

      <Route
        exact
        key={PAGES.concessions.productsEditor.edit.title}
        path={PAGES.concessions.productsEditor.edit.path}
        render={routeProps => (
          <Suspense fallback={<div />}>
            <main className='gf-concessions-container'>
              <PageTitle title={PAGES.concessions.productsEditor.edit.title} customClass='gf-concession-title' />

              {!isLoading && !isNil(concession) && (
                <ConcessionProductEditor
                  {...props}
                  {...routeProps}
                  products={pick(concession, Object.keys(ConcessionProductSchema.fields)) as ConcessionProducts}
                  onPublish={({ products }: ConcessionProducts) => {
                    const newProducts = products?.map(product => {
                      const existingProduct = concession.products?.find(p => p.id === product.id);
                      if (!isNil(existingProduct)) return { ...existingProduct, ...product };
                      return { ...product };
                    });

                    const concessionToPublish = { ...concession, products: newProducts };
                    _onUpdateConcession(concessionToPublish);
                  }}
                  isPublishing={isPublishing}
                  hasChanges={hasChanges.event === true || hasChanges.products === true}
                  onDefaultFormValuesChange={onDefaultFormValuesChange}
                  financialAccount={financialAccountChange}
                />
              )}
            </main>
          </Suspense>
        )}
      />

      <Route
        exact
        key={PAGES.concessions.view.title}
        path={PAGES.concessions.view.path}
        render={routeProps => (
          <Suspense fallback={<div />}>
            <main className='gf-concessions-container'>
              <PageTitle title={PAGES.concessions.view.title} customClass='gf-concession-title' />
              {!isLoading && !isNil(eventData) && <ConcessionDetails {...routeProps} concession={eventData} />}
            </main>
          </Suspense>
        )}
      />
    </Switch>
  );

  function _onCreateConcession(concessionToPublish: any) {
    setIsPublishing(true);

    const finalConcessionToPublish = omit(
      {
        ...concessionToPublish,
        archived: false,
        disableQr: true,
        products: (concessionToPublish?.products ?? []).map((product: any) =>
          omit(product, ['rateId', 'fee', 'hiddenFeeBase'])
        )
      },
      ['startDate', 'startTime', 'endDate', 'endTime', 'id']
    ) as EventDTO;

    OldEventService.createEvent(finalConcessionToPublish)
      .then((data: EventDTO) => {
        const concessionId = data?.id;
        setConcession(data);
        setHasChanges({ event: false, products: false });
        props.history.push(PAGES.concessions.view.calculatePath(concessionId));
      })
      .catch(() => {
        addNotification({
          type: 'error',
          message: `Your concession was not published successfully. Please try again later.`,
          options: { duration: 7000 }
        });
      })
      .finally(() => {
        setIsPublishing(false);
      });
  }

  function _onUpdateConcession(concessionToPublish: any) {
    setIsPublishing(true);

    const finalConcessionToPublish = omit(
      { ...concessionToPublish, disableQr: concessionToPublish.disableQr ?? true },
      ['startDate', 'startTime', 'endDate', 'endTime']
    ) as EventDTO;

    // you cannot pass an empty string to the api for glCode, if the user deletes their glCode it must be set to null
    finalConcessionToPublish.glCode = finalConcessionToPublish.glCode === '' ? null : finalConcessionToPublish.glCode;

    EventService.updateEvent(finalConcessionToPublish)
      .then(data => {
        invalidateEvent(data.id);
        setConcession(data as EventDTO);
        addNotification({
          type: 'success',
          message: `Your concession was updated successfully!`,
          options: { duration: 7000 }
        });

        setHasChanges({ event: false, products: false });

        document.documentElement.scrollIntoView({ behavior: 'smooth' });
      })
      .catch(err => {
        addNotification({
          type: 'error',
          message: `Your concession was not updated successfully. Please review changes made or try again later.`,
          options: { duration: 7000 }
        });
      })
      .finally(() => {
        setIsPublishing(false);
      });
  }
};

export { Concessions };
