/* eslint-disable no-promise-executor-return */
import { initializeApp, getApps } from 'firebase/app';
import {
  checkActionCode,
  confirmPasswordReset as fbConfirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword as fbSignInWithEmailAndPassword,
  signOut as fbSignOut,
  sendPasswordResetEmail as fbSendPasswordResetEmail,
  updatePassword
} from 'firebase/auth';

import { UserService, USER_STATE_ENABLED } from '../users';
import { setStorageAuthBody, removeStorageAuthBody } from '@gofan/utils/local-storage';
import { config } from '@gofan/constants/config';
import isEmpty from 'lodash/isEmpty';

import type { UserCredential } from 'firebase/auth';
import type { UserDTO } from '../users';

export const { firebaseConfig } = config;

let firebaseApp;

if (getApps().length < 1) {
  firebaseApp = initializeApp(firebaseConfig);
}

const auth = getAuth(firebaseApp);

export const getToken = () => {
  if (!isEmpty(auth.currentUser)) {
    return auth.currentUser?.getIdToken();
  }

  return new Promise<string | null>(resolve =>
    onAuthStateChanged(auth, user => (isEmpty(user) ? resolve(null) : user?.getIdToken().then(token => resolve(token))))
  );
};

export const getRefreshToken = () => {
  if (!isEmpty(auth.currentUser)) {
    return auth.currentUser.refreshToken;
  }

  return new Promise<string | null>(resolve =>
    onAuthStateChanged(auth, user => resolve(isEmpty(user) ? null : user?.refreshToken))
  );
};

export const signOut = () =>
  new Promise(resolve =>
    fbSignOut(auth)
      .then(() => resolve(true))
      .catch(error => resolve(error))
  );

export const signInWithEmailAndPassword = (email, password) =>
  new Promise((resolve, reject) =>
    fbSignInWithEmailAndPassword(auth, email, password)
      .then(user => {
        UserService.getUserByEmail(email)
          .then(userRes => {
            if (userRes.status === 'DISABLED' || userRes.status === 'DELETED') {
              reject(new Error('This account has been disabled or deleted. Please contact support for help.'));
            } else {
              resolve(user);
            }
          })
          .catch(() => {
            reject(new Error('An unknown error occurred.'));
          });
      })
      .catch(error => reject(error))
  );

export const sendPasswordResetEmail = email => {
  const actionCodeSettings = {
    url: `${config.HOSTNAME}/login?email=${email}`
  };
  return new Promise((res, rej) =>
    fbSendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(resp => res(resp))
      .catch(error => rej(error))
  );
};

const _checkActionCode = code =>
  checkActionCode(auth, code)
    .then(res => res)
    .catch(error => new Error(error));

export const parseFirebaseAction = params => {
  const {
    query: { oobCode }
  } = params;
  _checkActionCode(oobCode);
  return oobCode;
};

export const confirmPasswordReset = (oobCode, newPassword) => {
  fbConfirmPasswordReset(auth, oobCode, newPassword)
    .then(res => res)
    .catch(error => error);
};

export const createFirebaseAccount = (userInfo, password) =>
  new Promise((resolve, reject) =>
    createUserWithEmailAndPassword(auth, userInfo.email, password)
      .then(response => {
        setStorageAuthBody(response);
        return UserService.updateUser({ ...userInfo, status: USER_STATE_ENABLED });
      })
      .then((response: UserDTO) => {
        removeStorageAuthBody();
        resolve(response);
      })
      .catch(error => reject(error))
  );

export const changeMyPassword = (email, oldPassword, newPassword) =>
  new Promise((res, rej) =>
    signInWithEmailAndPassword(email, oldPassword)
      .then((fbUser: UserCredential) => {
        const { user } = fbUser;
        updatePassword(user, newPassword)
          .then(result => res(result))
          .catch(e => rej(e));
      })
      .catch(e => rej(e))
  );

export const signIn = (email, password) =>
  new Promise((resolve, reject) =>
    fbSignInWithEmailAndPassword(auth, email, password)
      .then((res: UserCredential) => {
        const { user } = res;
        UserService.getUserByEmail(user?.email ?? '')
          .then((gofanUser: UserDTO) => {
            resolve({ res, user: gofanUser });
          })
          .catch(error => reject(error));
      })
      .catch(error => reject(error))
  );
