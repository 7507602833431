export const UNSECURITY_ERROR = 'User may only retrieve his own email';
export const ACCESS_DENIED = 'Access is denied';
export const FORBIDDEN = 'Forbidden';
export const UNEXPECTED_ERROR = 'Unexpected Error';
export const CANCELLED_REQUEST = 'Cancelled Request';

export const STATUS_CODE_401 = 401;
export const STATUS_CODE_403 = 403;
export const STATUS_CODE_404 = 404;
export const STATUS_CODE_500 = 500;
export const STATUS_CODE_502 = 502;
export const STATUS_CODE_503 = 503;

export const MAINTENANCE_MODE = 'MAINTENANCE_MODE';

export const EXCLUDE_SCHOOL_TYPES = ['Elementary School', 'Elementary School Board', 'Elementary Member School'];

export const ACCOUNT_TYPES = ['State Association', 'High School', 'Member School', 'Middle School', 'K - 12'];

export const SCHOOL_TYPE_EXCEPTIONS = ['State Association', 'Section'];

export const END_DATE_CONSTRAINT = 'MaxEndDateConstraint';
export const END_DATE_ERROR = 'End date value is invalid, and must be within %d days of today';
export const END_DATE_DAY_RANGE_FOR_ADMIN_USER = 730;
export const END_DATE_DAY_RANGE_FOR_SCHOOL_USER = 500;
