import axios from '@api/api/axios';

import { CREATE_LIST_SEASON_WITH_EVENTS } from '@app/api';

import { SeasonWithEvent } from '@season-management/middleware/models/season-with-event.model';

class SeasonCreationRepository {
  createMultiSeasons = (seasons: SeasonWithEvent[]) =>
    axios.huddleAxiosInstanceProxy.post<any>(CREATE_LIST_SEASON_WITH_EVENTS, seasons);

  updateMultiSeasons = (seasons: SeasonWithEvent[]) =>
    axios.huddleAxiosInstanceProxy.patch<any>(CREATE_LIST_SEASON_WITH_EVENTS, seasons, {});
}

export default new SeasonCreationRepository();
