export const EXPAND_FIELDS = ['levels', 'activity', 'event-type', 'account', 'account-opponent'];

export const REQUEST_FIELDS = {
  embeddedFields: {
    activity: ['athletic', 'disabled', 'id', 'label']
  }
};

export const PAGE_SIZES = [
  { key: 25, value: 25, text: '25' },
  { key: 50, value: 50, text: '50' },
  { key: 100, value: 100, text: '100' }
];

export const SEARCH_PARAMS = {
  id: undefined,
  name: undefined,
  accountIds: undefined,
  activitiesIds: undefined,
  opponentAccountId: undefined,
  financialAccountIds: undefined,
  flatFileBatchId: undefined,
  flatFileEditBatchIds: undefined,
  startDate: undefined,
  specificDate: undefined,
  endDate: undefined,
  //  includeArchived: true,
  pageIndex: 0,
  pageSize: 25,
  sortBy: [{ desc: false, id: 'startDateTime' }]
};
