/* eslint-disable import/no-unresolved */
import { put, takeEvery, call, select } from 'redux-saga/effects';
import {
  FETCH_LEVELS,
  UPDATE_LEVEL,
  CREATE_LEVEL,
  DELETE_LEVEL
} from './actionTypes';
import {
  fetchLevels,
  fetchLevelsSuccessful,
  updateLevelSuccessful,
  createLevelSuccessful,
  deleteLevelSuccessful,
  fetchLevelsCompleted,
  updateLevelCompleted,
  createLevelCompleted,
  deleteLevelCompleted
} from './actions';
import { addMessage } from '../../root/actions';
import LevelService from '../../api/services/LevelService';
import ErrorDTO from '../../api/dto/ErrorDTO';
import Level from '../../api/model/Level';
import LevelDTO from '../../api/dto/LevelDTO';
import {
  generateFailContent,
  generateSuccessContent
} from '../../utils/alertUtils';
import { UNEXPECTED_ERROR } from '../../api/api/constants';
import { errorMessages } from '../../commons';

export function* fetchAllLevels() {
  try {
    const response = yield call(LevelService.fetchAllLevels);

    const levels = yield call(LevelService.filterAvailableLevels, response);

    yield put(fetchLevelsSuccessful(levels));

    if (response instanceof ErrorDTO) {
      const message = generateFailContent(response.getErrorMessage());
      yield put(addMessage(message));
    }
    yield put(fetchLevelsCompleted());
  } catch (error) {
    const message = generateFailContent(error.message);
    yield put(addMessage(message));
    yield put(fetchLevelsCompleted());
  }
}

export function* fetchLevelsSaga() {
  yield takeEvery(FETCH_LEVELS, fetchAllLevels);
}

export function* updateLevel(action) {
  try {
    const { level } = action.payload;
    const response = yield call(LevelService.updateLevel, level);
    if (response instanceof LevelDTO) {
      const levels = yield select(state => state.level.levels);
      const newLevels = [...levels];
      const newLevel = new Level(response).toJSON();
      newLevels[
        newLevels.findIndex(item => item.id === newLevel.id)
      ] = newLevel;
      newLevels.sort((a, b) => a.name.localeCompare(b.name));
      yield put(updateLevelSuccessful(newLevels));
      yield put(
        addMessage(generateSuccessContent(`${level.name} is updated.`))
      );
    } else {
      yield put(fetchLevels());

      const errorMessage =
        response instanceof ErrorDTO && response.data
          ? response.data.message
          : UNEXPECTED_ERROR;
      yield put(addMessage(generateFailContent(errorMessage)));
    }
  } catch (error) {
    const message = generateFailContent(error.message);
    yield put(addMessage(message));
    yield put(updateLevelCompleted());
  }
}

export function* updateLevelSaga() {
  yield takeEvery(UPDATE_LEVEL, updateLevel);
}

export function* createLevel(action) {
  try {
    const { level } = action.payload;
    const response = yield call(LevelService.createLevel, level);

    if (response instanceof LevelDTO) {
      const levels = yield select(state => state.level.levels);

      yield put(
        createLevelSuccessful(
          [...levels, new Level(response).toJSON()].sort((a, b) =>
            a.name.localeCompare(b.name)
          )
        )
      );
      yield put(addMessage(generateSuccessContent(`${level.name} is added.`)));
    } else {
      yield put(fetchLevels());

      const errorMessage =
        response instanceof ErrorDTO && response.data
          ? response.data.message
          : UNEXPECTED_ERROR;
      yield put(addMessage(generateFailContent(errorMessage)));
    }
  } catch (error) {
    const message = generateFailContent(error.message);
    yield put(addMessage(message));
    yield put(createLevelCompleted());
  }
}

export function* createLevelSaga() {
  yield takeEvery(CREATE_LEVEL, createLevel);
}

export function* deleteLevel(action) {
  try {
    const { level } = action.payload;
    const response = yield call(LevelService.deleteLevel, level.id);
    if (response === true) {
      const levels = yield select(state => state.level.levels);
      const newLevels = [...levels];
      newLevels.splice(newLevels.findIndex(item => item.id === level.id), 1);
      yield put(deleteLevelSuccessful(newLevels));
      yield put(
        addMessage(generateSuccessContent(`${level.name} is deleted.`))
      );
    } else {
      yield put(fetchLevels());

      const errorMessage =
        response instanceof ErrorDTO && response.data
          ? response.data.message
          : UNEXPECTED_ERROR;
      if (errorMessage.includes('associated')) {
        const message = `${level.name} ${
          errorMessages.ASSOCIATING_UPCOMING_EVENT_MESSAGE
        }`;
        yield put(addMessage(generateFailContent(message)));
      } else {
        yield put(addMessage(generateFailContent(errorMessage)));
      }
    }
  } catch (error) {
    const message = generateFailContent(error.message);
    yield put(addMessage(message));
    yield put(deleteLevelCompleted());
  }
}

export function* deleteLevelSaga() {
  yield takeEvery(DELETE_LEVEL, deleteLevel);
}

export default [
  fetchLevelsSaga(),
  updateLevelSaga(),
  createLevelSaga(),
  deleteLevelSaga()
];
