import { ProgressIndicator as CarbonProgressIndicator, ProgressStep as CarbonProgressStep } from '@carbon/react';
import classNames from 'classnames';

import './ProgressIndicator.scss';

interface ProgressIndicatorProps {
  children?: React.ReactNode;
  className?: string;
  currentIndex?: number;
  onChange?: (stepIndex: number) => void;
  spaceEqually?: boolean;
  vertical?: boolean;
}

export const ProgressIndicator = (props: ProgressIndicatorProps) => (
  <CarbonProgressIndicator {...props} className={classNames('gf--progress-indicator', props.className)}>
    {props.children}
  </CarbonProgressIndicator>
);

interface ProgressStepProps {
  className?: string;
  complete?: boolean;
  current?: boolean;
  description?: string;
  disabled?: boolean;
  index?: number;
  invalid?: boolean;
  label: string;
  onClick?: () => void;
  overflowTooltipProps?: object;
  secondaryLabel?: string;
  tooltipId?: string;
  translateWithId?: () => string;
}

export const ProgressStep = (props: ProgressStepProps) => <CarbonProgressStep {...props} />;
