import BaseModel from './BaseModel';
import { isEmpty } from '../../utils/objectUtils';
import Productype from './ProductType';
import Activity from './Activity';
import Level from './Level';

class AccountTicket extends BaseModel {
  constructor(
    {
      accountId,
      accountPaid,
      activityId,
      fanPrice,
      fee,
      id,
      levelIds,
      payToSchool,
      productTypeId,
      status,
      ticketName,
      ticketPrice,
      customColor,
      groupId,
      distributionChannel,
      embedded
    },
    ticketRawData = {}
  ) {
    super({
      ...ticketRawData,
      accountId,
      accountPaid,
      activityId,
      fanPrice,
      fee,
      id,
      levelIds,
      payToSchool,
      productTypeId,
      status,
      ticketName,
      ticketPrice,
      customColor,
      groupId,
      distributionChannel,
      embedded
    });
  }

  get id() {
    return this.raw.id;
  }

  get accountId() {
    return this.raw.accountId;
  }

  get accountPaid() {
    return this.raw.accountPaid;
  }

  get activityId() {
    return this.raw.activityId;
  }

  get fanPrice() {
    return this.raw.fanPrice;
  }

  get fee() {
    return this.raw.fee;
  }

  get levelIds() {
    return this.raw.levelIds;
  }

  get payToSchool() {
    return this.raw.payToSchool;
  }

  get productTypeId() {
    return this.raw.productTypeId;
  }

  get status() {
    return this.raw.status;
  }

  get ticketName() {
    return this.raw.ticketName;
  }

  get ticketPrice() {
    return this.raw.ticketPrice;
  }

  get customColor() {
    return this.raw.customColor;
  }

  get groupId() {
    return this.raw.groupId;
  }

  get distributionChannel() {
    return this.raw.distributionChannel;
  }

  get productType() {
    const { productType } = isEmpty(this.raw.embedded) ? {} : this.raw.embedded;
    return productType ? new Productype(productType) : null;
  }

  get activity() {
    const { activity } = isEmpty(this.raw.embedded) ? {} : this.raw.embedded;
    return activity ? new Activity(activity) : null;
  }

  get levels() {
    const { levels } = isEmpty(this.raw.embedded) ? {} : this.raw.embedded;
    return (levels || []).map(level => new Level(level));
  }

  get ticketRawData() {
    return this.raw.ticketRawData;
  }

  toJSON() {
    return {
      ...this.ticketRawData,
      accountId: this.accountId,
      accountPaid: this.accountPaid,
      activityId: this.activityId,
      fanPrice: this.fanPrice,
      fee: this.fee,
      id: this.id,
      levelIds: this.levelIds,
      payToSchool: this.payToSchool,
      productTypeId: this.productTypeId,
      status: this.status,
      ticketName: this.ticketName,
      ticketPrice: this.ticketPrice,
      customColor: this.customColor,
      groupId: this.groupId,
      distributionChannel: this.distributionChannel,
      productType: this.productType,
      activity: this.activity,
      levels: this.levels
    };
  }
}
export default AccountTicket;
