import classNames from 'classnames';
import { Button, InlineLoading, ComposedModal, ModalFooter, ModalHeader } from 'carbon-components-react';

import { useEventRestore } from '@app/modules/events/components/event-restore-modal/use-event-restore';
import strings from '@events/strings';
import type { EventDTO } from '@events/models/event.model';

export interface EventRestoreModalProps {
  event: EventDTO;
  open: boolean;
  onCloseModal: () => void;
  onReloadEvent: () => void;
  addNotification: Function;
  isConcession?: boolean;
}

export const EventRestoreModal = ({
  event,
  open,
  addNotification,
  onCloseModal,
  onReloadEvent,
  isConcession = false
}: EventRestoreModalProps) => {
  const { updating, restoreEvent } = useEventRestore({
    addNotification,
    onCloseModal,
    onReloadEvent
  });

  const handleCloseModal = () => !updating && onCloseModal();

  return (
    <ComposedModal size='md' open={open} onClose={handleCloseModal}>
      <ModalHeader className='gs--padding-bottom__sp5 gs--ui-01'>
        <div className={classNames('gs--productive-heading-03', 'gs--text-01')}>
          {!isConcession ? strings.EVENT_RESTORE.TITLE : 'Restore this concession'}
        </div>
        <div className={classNames('gs--body-long-01', 'gs--text-01', 'gs--padding-top__sp5')}>
          {strings.EVENT_RESTORE.DESCRIPTION}
        </div>
      </ModalHeader>
      <ModalFooter>
        <Button kind='secondary' onClick={handleCloseModal}>
          {strings.EVENT_RESTORE.CANCEL_BUTTON}
        </Button>

        <Button kind='primary' disabled={updating} onClick={() => restoreEvent(event?.id)}>
          {updating ? (
            <InlineLoading status='active' description={strings.EVENT_RESTORE.RESTORE_BUTTON} />
          ) : (
            <>{!isConcession ? strings.EVENT_RESTORE.RESTORE_BUTTON : 'Yes, restore this concession'}</>
          )}
        </Button>
      </ModalFooter>
    </ComposedModal>
  );
};
