export const TICKET_INITIAL = {
  fee: '',
  name: '',
  limit: '',
  price: '',
  enabled: true,
  updatedMode: 'new',
  productType: 'TICKET',
  distributionChannel: 'GoFan',
  isCreateUniqueLink: true
};

export default {
  TICKET_INITIAL
};

export const DEFAULT_SORT_OPTION = {
  desc: true,
  id: 'ticketPrice',
  key: 'ticketPrice',
  value: 'ticketPrice'
};

export const SEARCH_PARAMS = {
  pageSize: 1000,
  pageIndex: 0,
  accountId: '',
  sortBy: [DEFAULT_SORT_OPTION],
  expands: ['product-type', 'levels', 'activity']
};
