import map from 'lodash/map';

import { Swatch } from 'react-color/lib/components/common';
import DisabledSwatch from './disabled-swatch.component';

interface BlockSwatchesProps {
  onClick: (hexCode: any, e: any) => void;
  onSwatchHover?: () => void;
  colors: string[];
  value: string;
  disabled: boolean;
}

const BlockSwatches = ({ colors, value, onClick, onSwatchHover, disabled }: BlockSwatchesProps) => {
  const styles = {
    swatch: {
      width: '2rem',
      height: '2rem',
      float: 'left',
      marginRight: '0.5rem',
      marginBottom: '0.5rem'
    },
    swatchSelected: {
      width: '2rem',
      height: '2rem',
      float: 'left',
      marginRight: '0.5rem',
      marginBottom: '0.5rem',
      boxShadow: `0 0 0 3px #${value}20`
    }
  };

  return (
    <div className='block-swatches'>
      {map(colors, colorItem =>
        disabled ? (
          <DisabledSwatch
            color={colorItem}
            isSelected={colorItem.toUpperCase() === `#${value}`.toUpperCase()}
            key={colorItem}
          />
        ) : (
          <Swatch
            key={colorItem}
            color={colorItem}
            style={colorItem.toUpperCase() === `#${value}`.toUpperCase() ? styles.swatchSelected : styles.swatch}
            onClick={onClick}
            onHover={onSwatchHover}
          />
        )
      )}
      <div className='clear' />
    </div>
  );
};

export default BlockSwatches;
