// @flow
import { isEmpty } from 'lodash';
import UserAccountContextDTO from './UserAccountContextDTO';

class UserDTO {
  constructor({
    acceptedTerms,
    createdAt,
    dateOfBirth,
    doNotEmail,
    email,
    emailToken,
    favoriteAccountIds,
    firstName,
    lastName,
    optOutCommercialCorrespondence,
    optOutPiiSharing,
    phoneNumber,
    phoneNumber2,
    phoneType,
    phoneType2,
    placeholderAccount,
    role,
    status,
    title,
    updatedAt,
    userAccountContexts,
    zipCode,
    timeZone
  }) {
    this.raw = {
      acceptedTerms,
      createdAt,
      dateOfBirth,
      doNotEmail,
      email,
      emailToken,
      favoriteAccountIds,
      firstName,
      lastName,
      optOutCommercialCorrespondence,
      optOutPiiSharing,
      phoneNumber,
      phoneNumber2,
      phoneType,
      phoneType2,
      placeholderAccount,
      role,
      status,
      title,
      updatedAt,
      userAccountContexts,
      zipCode,
      timeZone
    };
  }

  get acceptedTerms() {
    return this.raw.acceptedTerms;
  }

  get doNotEmail() {
    return this.raw.doNotEmail;
  }

  get emailToken() {
    return this.raw.emailToken;
  }

  get optOutCommercialCorrespondence() {
    return this.raw.optOutCommercialCorrespondence;
  }

  get optOutPiiSharing() {
    return this.raw.optOutPiiSharing;
  }

  get phoneNumber2() {
    return this.raw.phoneNumber2;
  }

  get phoneType2() {
    return this.raw.phoneType2;
  }

  get placeholderAccount() {
    return this.raw.placeholderAccount;
  }

  get userAccountContexts() {
    if (isEmpty(this.raw.userAccountContexts)) return [];
    return this.raw.userAccountContexts.map(
      context => new UserAccountContextDTO(context)
    );
  }

  get favoriteAccountIds() {
    return this.raw.favoriteAccountIds;
  }

  get firstName() {
    return this.raw.firstName;
  }

  get lastName() {
    return this.raw.lastName;
  }

  get phoneNumber() {
    return this.raw.phoneNumber;
  }

  get phoneType() {
    return this.raw.phoneType;
  }

  get title() {
    return this.raw.title;
  }

  get createdAt() {
    return this.raw.createdAt;
  }

  get updatedAt() {
    return this.raw.updatedAt;
  }

  get role() {
    return this.raw.role;
  }

  get status() {
    return this.raw.status;
  }

  get dateOfBirth() {
    return this.raw.dateOfBirth;
  }

  get zipCode() {
    return this.raw.zipCode;
  }

  get email() {
    return this.raw.email;
  }

  get timeZone() {
    return this.raw.timeZone;
  }

  toJSON() {
    return {
      acceptedTerms: this.acceptedTerms,
      createdAt: this.createdAt,
      dateOfBirth: this.dateOfBirth,
      doNotEmail: this.doNotEmail,
      email: this.email,
      emailToken: this.emailToken,
      favoriteAccountIds: this.favoriteAccountIds,
      firstName: this.firstName,
      lastName: this.lastName,
      optOutCommercialCorrespondence: this.optOutCommercialCorrespondence,
      optOutPiiSharing: this.optOutPiiSharing,
      phoneNumber: this.phoneNumber,
      phoneNumber2: this.phoneNumber2,
      phoneType: this.phoneType,
      phoneType2: this.phoneType2,
      placeholderAccount: this.placeholderAccount,
      role: this.role,
      status: this.status,
      title: this.title,
      updatedAt: this.updatedAt,
      userAccountContexts: this.userAccountContexts.map(content =>
        content.toJSON()
      ),
      zipCode: this.zipCode,
      timeZone: this.timeZone
    };
  }
}

export default UserDTO;
