import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import memoizeOne from 'memoize-one';
import { oc } from 'ts-optchain';
import { CONTACT_FINANCE, CONTACT_PRIMARY, GOFAN_ROLE_OPTIONS, MODAL_ACTION } from '@accounts/constants/constants';
import { TEAM_SEASONS } from '../v2/constants';
import type { ActivityDTO } from '@gofan/api/activities';
import type { UserAccountContext } from '@gofan/api/accounts';
import { PAGES } from '@app/config/routes';
import { stateValidation, stateZipCodeValidation, zipCodeValidation } from '@app/commons/validations';
import type { HistoryItemDTO, HistoryDTO } from '@gofan/api/history';
import { checkInternalUser } from '@app/api/services/UserService';
import type { TeamResponseDTO, UserDTO } from '@gofan/api';

export const generateAbbreviateName = memoizeOne((firstName: string, lastName: string): string => {
  const result = [firstName, lastName]
    .reduce((text, res) => (isEmpty(res) ? text : `${text}${res.charAt(0)}`), '')
    .toUpperCase();
  return result;
});

export const getContactName = memoizeOne((lastName: string, firstName: string) =>
  [lastName, firstName].filter(text => !isEmpty(text)).join(' ')
);

export const mappingContactType = memoizeOne((id: string) => {
  const roles = GOFAN_ROLE_OPTIONS.find(role => role.id === id);
  if (roles) {
    return roles.text;
  }
  return 'Other';
});

export const mappingTeamName = memoizeOne((team: TeamResponseDTO) => {
  const season = TEAM_SEASONS.find(item => item.name === team.seasonName)?.label ?? team.seasonName;
  return `${team.gender} ${team.levelName} ${team.activityName} ${season}`;
});

export const transformListSelected = memoizeOne((conditions: number[], list: ActivityDTO[]) =>
  list.filter((activity: ActivityDTO) => conditions.includes(activity.id))
);

export const populateActivity = memoizeOne((accountActivities: number[], groupedActivities: any) => {
  const { athleticActivities, nonAthleticActivities } = groupedActivities;
  const athleticReport = transformListSelected(accountActivities, athleticActivities);
  const nonAthleticReport = transformListSelected(accountActivities, nonAthleticActivities);
  return { athleticReport, nonAthleticReport };
});

export const navigateToContactLanding = (history: any, accountId: string) => {
  if (history.length <= 2) {
    // case open new tab and paste the link
    history.push(PAGES.accountsV2.landing.calculatedPath(accountId));
  } else {
    history.goBack();
  }
};

export const navigateToAddContact = (history: any, accountId: string, state?: any) => {
  history.replace(PAGES.accountsV2.contactsAdd.calculatedPath(accountId), state);
  history.push('/forceResetCurrentRoute'); // cheating for force reload current route.
  history.goBack();
};

export const navigateToEditContact = (history: any, accountId: string, email: string) => {
  history.push(PAGES.accountsV2.contactEdit.calculatedPath(accountId, email));
  history.push('/forceResetCurrentRoute'); // cheating for force reload current route.
  history.goBack();
};

export const getCurrentContactInfo = memoizeOne(
  (account, user): UserAccountContext =>
    oc(account)
      .userAccountContexts([])
      .find((context: UserAccountContext) => context.email === oc(user).email(''))
);

export const generateModalConfig = (action: string, onYes: Function, onNo: Function, extraData?: any) => {
  let title;
  let content;
  let yesLabel;
  let deleteLabel;
  switch (action) {
    case MODAL_ACTION.DELETE:
      title = 'Delete this contact';
      content =
        'This user will lose all permissions and access to your GoFan HQ system. Are you sure you want to permanently delete this contact?';
      deleteLabel = 'Delete';
      break;
    case MODAL_ACTION.SAVE_CHANGES:
      title = 'Save your changes';
      content = "Before you add a new contact, let's save your changes to this contact.";
      yesLabel = 'Save';
      break;
    case MODAL_ACTION.CONFIRM_ADD_NEW_CONTACT:
    case MODAL_ACTION.NO_PERMISSION_ADD_NEW_CONTACT:
      title = 'Email address found';
      content = [
        `${oc(extraData).user.email('')} is already a user in the system.`,
        'Do you wish to continue and add this user as contact?',
        'Otherwise, use a different email address to add this contact.',
        'Clicking "Cancel" will clear the form.'
      ];
      yesLabel = 'Yes';
      break;
    case MODAL_ACTION.NO_PERMISSION_ADD_EXISTING_CONTACT:
      title = 'Contact found';
      content = `${oc(extraData).user.email(
        ''
      )} is already a contact for this account. Do you want to add another contact?`;

      yesLabel = 'Yes';
      break;
    case MODAL_ACTION.CONFIRM_ADD_EXISTING_CONTACT:
      title = 'Contact found';
      content = [
        `${oc(extraData).user.email('')} is already a contact for this account.`,
        'Select "Edit" if you wish to edit this contact.',
        'Otherwise, select "Cancel" to clear the form.'
      ];
      yesLabel = 'Edit';
      break;
    default:
      break;
  }
  return {
    title,
    content,
    deleteLabel,
    yesLabel,
    onYes,
    onNo
  };
};

export type AddressFields = {
  checkAddress?: string;
  checkAddressTwo?: string;
  checkCity?: string;
  checkState?: string;
  checkZip?: string;
};

export const getValueBankAddress = (bankAddress: string) => {
  let result: AddressFields = {
    checkAddress: '',
    checkAddressTwo: '',
    checkCity: '',
    checkState: '',
    checkZip: ''
  };
  const listAddress = bankAddress.split(', ');
  const lastItem = listAddress[listAddress.length - 1].trim();
  if (stateZipCodeValidation(lastItem)) {
    const items = lastItem.split(' ');
    result = {
      ...result,
      checkState: items[0].trim().toUpperCase(),
      checkZip: items[items.length - 1].trim()
    };
    listAddress.pop();
  } else if (stateValidation(lastItem)) {
    result = {
      ...result,
      checkState: lastItem.toUpperCase()
    };
    listAddress.pop();
  } else if (zipCodeValidation(lastItem)) {
    result = {
      ...result,
      checkZip: lastItem
    };
    listAddress.pop();
  }

  if (listAddress.length === 1) {
    result = { ...result, checkAddress: listAddress[0] };
  } else if (listAddress.length === 2) {
    result = {
      ...result,
      checkAddress: listAddress[0],
      checkCity: listAddress[1]
    };
  } else if (listAddress.length === 3) {
    result = {
      ...result,
      checkAddress: listAddress[0],
      checkAddressTwo: listAddress[1],
      checkCity: listAddress[2]
    };
  } else if (listAddress.length > 3) {
    const checkAddress = listAddress
      .splice(0, listAddress.length - 2)
      .reduce((str, item) => `${str}, ${item}`, '')
      .slice(2);

    result = {
      ...result,
      checkAddress,
      checkAddressTwo: listAddress[listAddress.length - 2],
      checkCity: listAddress[listAddress.length - 1]
    };
  }
  return result;
};

export const getValueContactPhone = (value = '') => {
  if (!value)
    return {
      phoneNumber: `${value}`,
      extNumber: ''
    };
  const newValue = value.replace(/\D/g, '');
  return newValue.length > 10
    ? {
        phoneNumber: newValue.slice(0, 10),
        extNumber: newValue.slice(10)
      }
    : {
        phoneNumber: newValue,
        extNumber: ''
      };
};

export const getLast4DigitsNumber = (value = '') => {
  const newValue = value.replace(/\D/g, '');
  return newValue.slice(newValue.length - 4) || newValue;
};

const ACHFields = ['ach_status', 'address', 'addressTwo', 'city', 'notes', 'payable', 'state', 'zipCode'];

export const normalizeFinancialHistoryData = (financialHistory: HistoryDTO[]) => {
  financialHistory.forEach(item => {
    const newContactPhone: HistoryItemDTO[] = [];
    item.changes.forEach(change => {
      if (change.field === 'contactPhone') {
        const newChanges = getValueContactPhone(change.value ?? '');
        Object.keys(newChanges).forEach(newChange => {
          newContactPhone.push({
            action: change.action,
            field: newChange,
            value: newChanges[newChange]
          });
        });
      }
      if (change.field === 'accountingNumber' || change.field === 'routingNumber') {
        change.value = isNil(change.value) ? `${change.value}` : `XXXXX${getLast4DigitsNumber(change.value)}`;
      }
      if (ACHFields.includes(change.field)) {
        change.field = `ACH.${change.field}`;
      }
      if (change.field === 'bankAddress') {
        change.field = `Check.${change.field}`;
      }
      if (change.field === 'name') {
        change.field = 'accountFinancial.name';
      }
    });
    if (!isEmpty(newContactPhone)) {
      item.changes = [...item.changes, ...newContactPhone];
      item.changes.filter(change => change.field !== 'contactPhone');
    }
  });
  return financialHistory;
};

export const getGofanRoleByUser = ({
  roles,
  editMode,
  contactType,
  currentUser,
  accountId
}: {
  roles: string[];
  editMode: boolean;
  contactType: string;
  currentUser: UserDTO;
  accountId: string;
}) => {
  const isInternalUser = checkInternalUser(currentUser.role);
  const userAccountContexts = currentUser.userAccountContexts || [];
  const isPrimaryContact = userAccountContexts.some(
    context =>
      `${context.accountId}` === `${accountId}` && !context.inactive && `${context.contactType}` === CONTACT_PRIMARY
  );
  let goFanRoles = isEmpty(roles) ? [] : roles;
  goFanRoles = !editMode || goFanRoles.includes(contactType) ? ['', ...goFanRoles] : goFanRoles;
  if (!isInternalUser && !isPrimaryContact) {
    if (contactType === CONTACT_FINANCE) {
      return [CONTACT_FINANCE];
    }
    return goFanRoles.filter(role => role !== CONTACT_FINANCE);
  }

  return goFanRoles;
};

export default {
  generateAbbreviateName,
  getContactName,
  mappingContactType,
  transformListSelected,
  populateActivity,
  navigateToContactLanding,
  getCurrentContactInfo,
  navigateToAddContact,
  navigateToEditContact,
  getValueBankAddress,
  getValueContactPhone,
  getLast4DigitsNumber,
  normalizeFinancialHistoryData
};
