import { isEmpty, trim } from 'lodash';
import BaseModel from '../BaseModel';
import { formatDate, EVENT_DATE_FORMAT } from '../../../utils/dateUtils';

class RateRequest extends BaseModel {
  constructor({
    id,
    label,
    cashless,
    minPrice,
    maxPrice,
    postSeason,
    startDate,
    productTypeIds,
    rateAmount,
    ratePercent,
    endDate,
    exceptions,
    status,
    huddleIds,
    states,
    distributionChannels
  }) {
    super({
      id,
      label,
      cashless,
      minPrice,
      maxPrice,
      postSeason,
      startDate,
      productTypeIds,
      rateAmount,
      ratePercent,
      endDate,
      exceptions,
      status,
      huddleIds,
      states,
      distributionChannels
    });
  }

  get id() {
    return this.raw.id;
  }

  get label() {
    return this.raw.label;
  }

  get cashless() {
    return this.raw.cashless;
  }

  get minPrice() {
    return this.raw.minPrice;
  }

  get maxPrice() {
    return this.raw.maxPrice;
  }

  get postSeason() {
    return this.raw.postSeason;
  }

  get startDate() {
    return isEmpty(trim(this.raw.startDate))
      ? null
      : formatDate({
          date: this.raw.startDate,
          format: EVENT_DATE_FORMAT
        });
  }

  get productTypeIds() {
    return this.raw.productTypeIds;
  }

  get productTypeNames() {
    return this.raw.productTypeNames;
  }

  get rateAmount() {
    return this.raw.rateAmount;
  }

  get ratePercent() {
    return this.raw.ratePercent;
  }

  get endDate() {
    return isEmpty(trim(this.raw.endDate))
      ? null
      : formatDate({
          date: this.raw.endDate,
          format: EVENT_DATE_FORMAT
        });
  }

  get exceptions() {
    return this.raw.exceptions;
  }

  get status() {
    return this.raw.status;
  }

  get huddleIds() {
    return this.raw.huddleIds || [];
  }

  get states() {
    return this.raw.states || [];
  }

  get distributionChannels() {
    return this.raw.distributionChannels;
  }

  toJSON() {
    return {
      id: this.id,
      label: this.label,
      cashless: this.cashless,
      minPrice: this.minPrice,
      maxPrice: this.maxPrice,
      postSeason: this.postSeason,
      startDate: this.startDate,
      productTypeIds: this.productTypeIds,
      rateAmount: this.rateAmount,
      ratePercent: this.ratePercent,
      endDate: this.endDate,
      exceptions: this.exceptions,
      status: this.status,
      huddleIds: this.huddleIds,
      states: this.states,
      distributionChannels: this.distributionChannels
    };
  }
}

export default RateRequest;
