// @flow

class SortableRequest {
  constructor({ sortBy }) {
    this.raw = {
      sortBy
    };
  }

  get sortBy() {
    return this.raw.sortBy || [];
  }

  toQueryString() {
    return this.raw.sortBy.reduce(
      (res, cur) =>
        cur.id ? `${res}&sort=${cur.id},${cur.desc ? 'desc' : 'asc'}` : res,
      ''
    );
  }
}

export default SortableRequest;
