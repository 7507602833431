import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query';
import { AccountService } from './account.service';
import { ACCOUNT_CONTACTS_QUERY_KEY } from './account.endpoints';
import type { AccountContact } from './account.model';

interface ExtendedUseQueryOptions<TData = unknown, TError = unknown> extends UseQueryOptions<TData, TError> {
  disableProviderErrorNotification?: boolean;
}

export interface UseAccountContactOptions {
  schoolId: string;
  queryOptions?: ExtendedUseQueryOptions<AccountContact[], Error>;
}

export const useAccountContacts = ({
  schoolId,
  queryOptions = { disableProviderErrorNotification: true }
}: UseAccountContactOptions) => {
  const queryKey = [ACCOUNT_CONTACTS_QUERY_KEY, schoolId];
  const queryFn = () => AccountService.getAccountContacts(schoolId);

  const accountContactsQuery = useQuery(queryKey, queryFn, queryOptions);

  return { ...accountContactsQuery };
};
