import type { PromotionsActionType } from './actionTypes';
import { REMOVE_BULK_PROMOTION_INFO, ADD_BULK_PROMOTION_INFO } from './actionTypes';
import type { BulkPromotionInfoDTO } from './types';

export function addBulkPromotionInfo(bulkPromotion: BulkPromotionInfoDTO): PromotionsActionType {
  return {
    type: ADD_BULK_PROMOTION_INFO,
    payload: {
      bulkPromotion
    }
  };
}

export function removeBulkPromotionInfo(eventId: string | number): PromotionsActionType {
  return {
    type: REMOVE_BULK_PROMOTION_INFO,
    payload: {
      eventId
    }
  };
}
