import { RQueryClientProvider } from '@gofan/api/RQueryClientProvider';
import { Toaster } from '@gofan/utils/toast';
import { Notification, ActionableNotification } from '../Notification';
import { InlineLoading } from '../loading';
import type { NotificationAction, Toast } from '@gofan/utils/toast';

import './QueryClientProvider.scss';

interface QueryClientProviderProps {
  // external for desktop HQ, internal for mobile HQ
  clientType?: 'external' | 'internal';
  children: React.ReactNode;
}

const QueryClientProvider = ({ clientType, children }: QueryClientProviderProps) => (
  <RQueryClientProvider clientType={clientType}>
    {children}

    <Toaster
      containerStyle={{
        top: clientType === 'external' ? 100 : 60
      }}
      containerClassName='gf--toaster-container'
    >
      {(t: Toast & NotificationAction) => {
        const kind = _returnNotificationKind(t.className);

        if (t.type === 'loading') {
          return (
            <ActionableNotification
              className='gf--toast-loading'
              kind={kind}
              title=''
              subtitle={<InlineLoading status='active' description={t.message} />}
              inline
              lowContrast
              role='status'
              onCloseButtonClick={() => null}
            />
          );
        }

        return !t.action ? (
          <Notification
            kind={kind}
            title=''
            subtitle={t.message as string}
            lowContrast
            role='status'
            onCloseButtonClick={() => null}
            hideCloseButton={(t.id ?? '').startsWith('hide-close-btn')}
          />
        ) : (
          <ActionableNotification
            kind={kind}
            title=''
            subtitle={t.message}
            inline
            lowContrast
            role='status'
            actionButtonLabel={t.action?.text}
            onActionButtonClick={t.action?.onClick}
            onCloseButtonClick={() => null}
            hideCloseButton={(t.id ?? '').startsWith('hide-close-btn')}
          />
        );
      }}
    </Toaster>
  </RQueryClientProvider>
);

function _returnNotificationKind(type) {
  switch (type) {
    case 'blank':
      return 'info';

    case 'loading':
      return 'info';

    case 'custom':
      return 'info';

    default:
      return type;
  }
}

export { QueryClientProvider };
