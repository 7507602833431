import * as yup from 'yup';
import { nameSchema, emailSchema, emailSchemaOptional, phoneNumberSchema, jerseyNumberSchema } from '@gofan/api/shared';
import type { StaffRole, Grade } from './rosters.model';

export const createStaffProfileSchemaBuilder = () =>
  yup.object().shape({
    email: emailSchema,
    firstName: nameSchema,
    lastName: nameSchema,
    staffRole: yup
      .mixed<StaffRole>()
      .test('is-not-empty', 'Please fill out role', value => typeof value === 'string' && value.trim().length > 0)
      .required('Please select a role'),
    phoneNumber: phoneNumberSchema,
    eligible: yup.boolean().required()
  });

export const createStudentProfileSchemaBuilder = ({
  testStudentHeight
}: {
  testStudentHeight: {
    errorMessage: string;
    test: (value: string) => boolean;
  };
}) =>
  yup.object().shape({
    email: emailSchemaOptional,
    firstName: nameSchema,
    lastName: nameSchema,
    grade: yup.string().required('Please fill out grade'),
    graduationYear: yup.string().required('Please fill out class'),
    position: yup.string().required('Please add a player position'),
    jerseyNumber: jerseyNumberSchema,
    height: yup.string().test('is-valid-height', testStudentHeight.errorMessage, testStudentHeight.test)
  });

export const RosterMemberSchema = yup.object().shape({
  id: yup.string().required(),
  email: emailSchemaOptional,
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phoneNumber: yup.string().required(),
  middleName: yup.string(),
  photoUrl: yup.string(),
  inactive: yup.boolean(),
  eligible: yup.boolean()
});

export const StudentDTOSchema = RosterMemberSchema.shape({
  grade: yup.mixed<Grade>().required(),
  graduationYear: yup.number().required(),
  jerseyNumber: yup.number().required(),
  position: yup.string().required(),
  heightInInches: yup.number(),
  weight: yup.number(),
  phoneNumber: yup.string(),
  email: emailSchemaOptional
});

export const StudentFormFieldsSchema = StudentDTOSchema.shape({
  height: yup.string(),
  weight: yup.string(),
  formId: yup.string().required(),
  avatar: yup.mixed()
}).omit(['photoUrl', 'heightInInches']);

export const StudentSetupFormDataSchema = StudentFormFieldsSchema.shape({});

export const StudentCreateSchema = StudentDTOSchema.shape({}).omit(['id']);

export const StudentUpdateSchema = StudentDTOSchema.shape({
  id: yup.string()
});

export const StaffDTOSchema = RosterMemberSchema.shape({
  staffRole: yup.mixed<StaffRole>().required(),
  hqUserId: yup.string().required()
}).omit(['photoUrl']);

export const StaffFormFieldsSchema = StaffDTOSchema.shape({
  formId: yup.string().required(),
  avatar: yup.mixed()
}).omit(['hqUserId']);

export const StaffCreateSchema = StaffDTOSchema.shape({}).omit(['id']);

export const StaffUpdateSchema = StaffDTOSchema.shape({
  id: yup.string()
});

export const StaffSetupFormDataSchema = StaffFormFieldsSchema.shape({
  userInfo: yup.object().required()
});
