import { PAGES } from '../../config/routes';

export const HEIGHT_INPUT = 36;

export const SEARCH_DEBOUNCE_TIME = 500;

export const FAN_SUPPORT_TAB_KEYS = {
  [PAGES.fanSupport.purchasedTickets.path]: 'Purchased Tickets',
  [PAGES.fanSupport.events.path]: 'Events',
  [PAGES.fanSupport.seasonPasses.path]: 'Purchased tickets (season passes)'
};

export const FAN_SUPPORT_TABS = [
  {
    to: PAGES.fanSupport.purchasedTickets.path,
    name: FAN_SUPPORT_TAB_KEYS[PAGES.fanSupport.purchasedTickets.path],
    width: 3
  },
  {
    to: PAGES.fanSupport.events.path,
    name: FAN_SUPPORT_TAB_KEYS[PAGES.fanSupport.events.path],
    width: 3
  },
  {
    to: PAGES.fanSupport.seasonPasses.path,
    name: FAN_SUPPORT_TAB_KEYS[PAGES.fanSupport.seasonPasses.path],
    width: 3
  }
];

export const DEFAULT_SORT_OPTION = {
  desc: true,
  id: 'startDate'
};

export const DEFAULT_FILTER_OPTION = {
  id: 'upcoming',
  key: 'upcoming',
  value: 'upcoming',
  text: 'Upcoming Events'
};

export const FILTER_OPTIONS = [
  {
    key: 'past',
    value: 'past',
    text: 'Past Events'
  },
  {
    key: 'upcoming',
    value: 'upcoming',
    text: 'Upcoming Events'
  }
];

export const SEARCH_PARAMS = {
  search: '',
  pageSize: 10,
  pageIndex: 0,
  filter: DEFAULT_FILTER_OPTION.value,
  sortBy: [DEFAULT_SORT_OPTION]
};
