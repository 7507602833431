import { ComboBox, DropdownSkeleton } from '..';

import { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import classNames from 'classnames';
import { AccountQuery, AccountService, sortSchoolsByName } from '@gofan/api';

import { DISTRICT_CONFERENCE_SCHOOL_TYPES } from '@gofan/constants';
import type { AccountDTO } from '@gofan/api';

import './SchoolComboBox.scss';

interface SchoolComboBoxProps {
  className?: string;
  invalid?: boolean;
  invalidText?: string;
  titleText?: string;
  initialSchools: AccountDTO[];
  selectedItem?: AccountDTO;
  defaultDistrictHuddleId?: string;
  allowFetchAssociatedSchools?: boolean;
  hasGroupLabel?: boolean;
  onChange?: (selectedSchool: AccountDTO) => void;
}

export const SchoolComboBox = ({
  className,
  invalid,
  invalidText = 'Select the school is required',
  titleText = 'Select the school',
  initialSchools = [],
  selectedItem,
  defaultDistrictHuddleId = '',
  allowFetchAssociatedSchools,
  hasGroupLabel,
  onChange
}: SchoolComboBoxProps) => {
  const [districtHuddleId, setDistrictHuddleId] = useState<string>(defaultDistrictHuddleId);
  const { data: associatedSchools = [], isInitialLoading: schoolLoading } = AccountQuery.getAccountSchoolsByDistrict({
    districtHuddleId,
    queryOptions: {
      enabled: allowFetchAssociatedSchools && !!districtHuddleId,
      refetchOnWindowFocus: false
    }
  });

  const schools = useMemo(() => {
    if (!isEmpty(associatedSchools)) {
      if (hasGroupLabel) {
        return AccountService.getGroupedAccounts({ initialSchools, associatedSchools, districtHuddleId });
      }
      return sortSchoolsByName(uniqBy([...initialSchools, ...associatedSchools], 'id'));
    }

    return sortSchoolsByName(initialSchools);
  }, [initialSchools, associatedSchools, districtHuddleId, hasGroupLabel]);

  return schoolLoading ? (
    <DropdownSkeleton />
  ) : (
    <ComboBox
      className={classNames('gf--school-combo-box', className)}
      id={`school-combobox-${Date.now()}`}
      titleText={titleText}
      items={schools}
      selectedItem={selectedItem}
      itemToString={(item: unknown) => (item as AccountDTO)?.name || ''}
      itemToElement={_schoolToElement}
      onChange={({ selectedItem }: { selectedItem: AccountDTO }) => {
        if (selectedItem?.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT) {
          setDistrictHuddleId(selectedItem.id);
        }
        onChange?.(selectedItem);
      }}
      invalid={!!invalid}
      invalidText={invalidText}
    />
  );

  function _schoolToElement(school: AccountDTO & { isGroupLabel?: boolean }) {
    return (
      <div
        key={school?.id}
        className={classNames({
          'group-school-label': school.isGroupLabel
        })}
      >
        {school.name}
      </div>
    );
  }
};
