export type FIELD_NAME =
  | 'date-first-name'
  | 'date-last-name'
  | 'date-student-id'
  | 'email'
  | 'full-name'
  | 'last-name'
  | 'phone-number'
  | 'student-id'
  | 'school-year'
  | string;

export const FIELD_LABEL_MAP: Record<FIELD_NAME, string> = {
  'date-first-name': 'Date first name: ',
  'date-last-name': 'Date last name: ',
  'date-student-id': 'Date student ID: ',
  'student-id': 'Student ID: ',
  'school-year': 'School Year: '
};

export const FIELD_IGNORE_LABEL: string[] = ['first-name', 'last-name', 'email', 'phone-number'];

export const IGNORE_FIELD_LABEL_MAP: Record<FIELD_NAME, string> = {
  'first-name': 'First name: ',
  'last-name': 'Last name: ',
  'full-name': 'Full name: ',
  email: 'Email: ',
  'phone-number': 'Phone Number: '
};

export const FIELD_ORDER: Record<FIELD_NAME, number> = {
  'full-name': 35,
  'phone-number': 30,
  email: 25,
  'student-id': 20,
  'date-first-name': 15,
  'date-last-name': 10,
  'date-student-id': 5
};

export const NUMBER_OF_RETRY_DOWNLOAD_REPORT = 2;
