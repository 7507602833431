import { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import { uniq, uniqBy, unionBy, isEmpty, isNil, sortBy, xor, compact } from 'lodash';

import { config, DISTRICT_CONFERENCE_SCHOOL_TYPES } from '@gofan/constants';
import { DATE_FORMAT_DEFAULT_WITH_TIMEZONE } from '@gofan/constants/date';
import { generateError } from '@utils/alertUtils';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useFeatureFlags, FEATURE_FLAGS } from '@gofan/hooks';
import {
  IGNORED_STATUS,
  UPDATED_STATUS,
  WAITING_STATUS,
  PUBLISHED_STATUS,
  SCHEDULE_SORT_INDEX,
  INTEGRATION_PARTNERS,
  INTERNAL_PARTNERS,
  ALL_SCHEDULE_FILTER_STATUS,
  STATES_OF_HIGH_RISK_MARKET,
  AWAY_SCHEDULE_FILTER_STATUS
} from '@modules/event-integrations_V2/constants/constants';

import type { ActivityDTO } from '@gofan/api/activities';
import type { RateDTO } from '@gofan/api/rates';
import type { EventDTO, EventPageResponseDTO, EventSearchDTO } from '@events/models/event.model';
import type { LevelDTO } from '@gofan/api/levels';
import type { GlobalEventDTO, SeasonDTO } from '@seasons/models/season.model';
import type { AccountDTO } from '@gofan/api/accounts';
import type { AccountTicketTypes, AccountProductDTO } from '@gofan/api/account-products';
import type {
  LevelGenders,
  PartnerInsight,
  EventScheduleSearchDTO,
  EventScheduleSeasonDTO
} from '@modules/event-integrations_V2/models/event-schedule.model';
import type { VenueInformationValue } from '@season-management/pages/season-general-setup/season-venue-section/season-venue-section.component';
import { SchoolConfigService, type SchoolConfig } from '@gofan/api/school-config';

import { getEditorAccountIdsContext, canEdit, canEditAccountByDistrictUser } from '@gofan/api/users';
import { RateService } from '@gofan/api/rates';
import { LevelService } from '@gofan/api/levels';
import { AccountService } from '@gofan/api/accounts';
import { ActivityService } from '@gofan/api/activities';
import SeasonCreationService from '@season-management/services/season-creation.service';
import EventScheduleService from '@modules/event-integrations_V2/services/event-schedule.service';
import { EventConfigService } from '../../services/event-config.service';

dayjs.extend(utc);

export type PartnerScheduleData = {
  partnerName: any;
  partnerSchedules: EventScheduleSeasonDTO[];
};

export type EventScheduleFilter = {
  sports?: string[];
  statuses?: string[];
  schools?: AccountDTO[];
};

export interface ScheduleLocationState {
  accounts?: any[];
  accountIds?: string[];
  selectedAccounts?: any[];

  partners?: string[];
  gofanEventId?: any;
  gofanSeasonId?: any;
  selectedPartner?: any;
  waiting?: boolean;
  published?: boolean;
  sportNames?: string[];
  genders?: string[];
  levels?: LevelDTO[];
  sports?: ActivityDTO[];
  levelGenders?: LevelGenders[];
}

export interface ScheduleName {
  name: string;
  scheduleId?: string;
}

export interface ScheduleLevel {
  accountId: any;
  scheduleId?: any;
  goFanSeasonId?: any;
  levels: LevelDTO[];
}

export interface ScheduleVenue {
  accountId: any;
  scheduleId?: any;
  goFanSeasonId?: any;
  isAccountLevel?: any;
  venue: VenueInformationValue;
}

export interface EventVisibility {
  archived: boolean;
  eventsArchived?: boolean;
  publishTime?: string;
  publishDate?: string;
  scheduleId?: string;
}

export interface EventAlert {
  alert: string;
  scheduleId?: string;
}

export interface EventStartTime {
  scheduleId: string;
  startTimes?: string[];
  startTimeType?: string;
}
export interface FormAccountTickets {
  accountId: string;
  scheduleId: string;
  tickets: AccountProductDTO[];
}

export interface PartnerSchedule {
  id: any;
  schedule: EventScheduleSeasonDTO;
  archived?: boolean;
  eventsArchived?: boolean;
  publishDate?: string;
  publishTime?: string;
  alert?: string;
  scheduleVenue?: ScheduleVenue;
  scheduleLevel?: ScheduleLevel;
  scheduleStartTimes?: any[]; // TODO: create type of start times
  goFanSeasonLevel?: ScheduleLevel;
  accountTickets?: AccountProductDTO[];
  eventStartTime?: string;
  startTimeOptions?: string[];
  startTimeType?: string;
  type?: string;
}

export interface ScheduleData {
  rates?: RateDTO[];
  levels?: LevelDTO[];
  accounts?: AccountDTO[];
  goFanEvents?: EventDTO[];
  activities?: ActivityDTO[];
  awayAccounts?: AccountDTO[];
  goFanSeasons?: SeasonDTO[];
  scheduleVenues?: ScheduleVenue[];
  accountsTicketTypes?: AccountTicketTypes[];
  districtAccounts?: AccountDTO[];
  underDistrictAccounts?: AccountDTO[];
  schoolConfigs?: SchoolConfig[];
}

export interface SchedulePageData {
  isPrepared: boolean;
  isFetchingScheduleData: boolean;
  fetchingPartnerNames: any[];
  isUnmounted: boolean;
  partnerNames: string[];
  partnerInsight: PartnerInsight;
  filterOptions: EventScheduleFilter;
  appliedFilters: EventScheduleFilter;
}

export interface PublishData {
  totalEvent: number;
  isDisabledPublishAll: boolean;
  partnerSchedules: PartnerSchedule[];
}

export interface PublishPartnerEvent {
  created?: boolean;
  goFanEvent?: EventDTO;
  partnerEvent: GlobalEventDTO;
  partnerSchedule: PartnerSchedule;
}

export interface RefreshEvent {
  eventId: any;
  scheduleId: any;
  goFanEvent?: EventDTO;
  globalEvent: GlobalEventDTO;
  newGoFanEvents?: EventDTO[];
}

export interface IgnoredGlobalSeason {
  eventIds: any[];
  scheduleId: any;
}

export const DEFAULT_ACCOUNT = 'GA72550';

export const useEventIntegration = ({
  location,
  currentUser,
  isInternalUser,
  ignoredGlobalSeasons,
  addNotification
}: {
  location: any;
  currentUser: any;
  isInternalUser: boolean;
  ignoredGlobalSeasons?: IgnoredGlobalSeason[];
  addNotification?: Function;
}) => {
  const {
    [FEATURE_FLAGS.tempAwayGamesxMeg]: awayGameEnabled,
    [FEATURE_FLAGS.tempDistrictConferenceUnicorn]: enableDistrictUnicorn,
    [FEATURE_FLAGS.tempEventMonitorSettingXMeg]: tempEventMonitorSettingXMeg
  } = useFeatureFlags();
  const queryParams = new URLSearchParams(location?.search);
  const queryAccountId = queryParams.get('accountId') ?? '';
  const queryPartnerName = queryParams.get('partnerName') ?? '';
  const locationState: ScheduleLocationState = location?.state ?? {};
  const stateGoFanEventId = locationState?.gofanEventId ?? null;
  const stateGoFanSeasonId = locationState?.gofanSeasonId ?? null;
  const statePartners = useRef<any[]>(_getStatePartner());
  const locationStateAccount = useRef<{
    allAccountIds: any[];
    stateAccountIds: any[];
    stateSelectedAccountIds: any[];
    aiaStateAccountIds: any[];
    aiaStateSelectedAccountIds: any[];
    dragonflyStateAccountIds: any[];
    dragonflyStateSelectedAccountIds: any[];
  }>(_getStateAccountIds());
  const {
    allAccountIds,
    stateAccountIds,
    stateSelectedAccountIds,
    aiaStateAccountIds,
    aiaStateSelectedAccountIds,
    dragonflyStateAccountIds,
    dragonflyStateSelectedAccountIds
  } = locationStateAccount.current ?? {};

  const isUnmounted = useRef<boolean>(false);
  const keyFetchInsight = useRef<any>();
  const keyFetchScheduleData = useRef<any>();
  const refreshScheduleIds = useRef<any[]>([]);
  const isInitialPageState = useRef<boolean>(true);
  const selectedAccountIds = useRef<any[]>(stateSelectedAccountIds);
  const aiaSelectedAccountIds = useRef<any[]>(aiaStateSelectedAccountIds);
  const dragonflySelectedAccountIds = useRef<any[]>(dragonflyStateSelectedAccountIds);

  const [isPrepared, setIsPrepared] = useState<boolean>(false);
  const [fetchingPartnerNames, setFetchingPartnerNames] = useState<any[]>([]);
  const [isFetchingScheduleData, setIsFetchingScheduleData] = useState<boolean>(false);
  const filterOptions = useRef<EventScheduleFilter>({ sports: [], schools: [], statuses: ALL_SCHEDULE_FILTER_STATUS });
  const [appliedFilters, setAppliedFilters] = useState<EventScheduleFilter>({ ...filterOptions.current });

  // Page data
  const [partnerNames, setPartnerNames] = useState<string[]>([]);
  const [partnerInsight, setPartnerInsight] = useState<PartnerInsight>({ insightData: [], salesInfoData: [] });
  const [selectedPartner, setSelectedPartner] = useState<string>(partnerNames?.[0] ?? '');
  const [selectedSchedule, setSelectedSchedule] = useState<EventScheduleSeasonDTO | null>(null);
  const [formScheduleLevels, setFormScheduleLevels] = useState<ScheduleLevel[]>([]);
  const [formScheduleVenues, setFormScheduleVenues] = useState<ScheduleVenue[]>([]);
  const [formScheduleAccountTicketTypes, setFormScheduleAccountTicketTypes] = useState<FormAccountTickets[]>([]);
  const [formScheduleEventVisibility, setFormScheduleEventVisibility] = useState<EventVisibility[]>([]);
  const [formScheduleEventAlert, setFormScheduleEventAlert] = useState<EventAlert[]>([]);
  const [formScheduleEventStartTimes, setFormScheduleEventStartTimes] = useState<EventStartTime[]>([]);

  // Static data
  const [rates, setRates] = useState<RateDTO[]>([]);
  const [levels, setLevels] = useState<LevelDTO[]>([]);
  const [accounts, setAccounts] = useState<AccountDTO[]>([]);
  const [goFanEvents, setGoFanEvents] = useState<EventDTO[]>([]);
  const [activities, setActivities] = useState<ActivityDTO[]>([]);
  const [goFanSeasons, setGoFanSeasons] = useState<SeasonDTO[]>([]);
  const [awayAccounts, setAwayAccounts] = useState<AccountDTO[]>([]);
  const [scheduleNames, setScheduleNames] = useState<ScheduleName[]>([]);
  const [scheduleLevels, setScheduleLevels] = useState<ScheduleLevel[]>([]);
  const [scheduleVenues, setScheduleVenues] = useState<ScheduleVenue[]>([]);
  const [accountsTicketTypes, setAccountsTicketTypes] = useState<AccountTicketTypes[]>([]);
  const [partnerScheduleData, setPartnerScheduleData] = useState<PartnerScheduleData[]>([]);
  const [districtAccounts, setDistrictAccounts] = useState<AccountDTO[]>([]);
  const [schoolConfigs, setSchoolConfigs] = useState<SchoolConfig[]>([]);
  const [preparedAccounts, setPreparedAccounts] = useState<boolean>(false);

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  useEffect(() => {
    if (!enableDistrictUnicorn) {
      setPreparedAccounts(true);
      return;
    }

    _fetchAccounts()
      .then(_fetchDistrictAccounts)
      .then(
        ({ districtAccounts: newDistrictAccounts, schoolConfigs: newSchoolConfigs, ...newLocationStateAccount }) => {
          if (isUnmounted.current) return;
          locationStateAccount.current = { ...locationStateAccount.current, ...newLocationStateAccount };
          setDistrictAccounts(newDistrictAccounts ?? []);
          setSchoolConfigs(newSchoolConfigs ?? []);
        }
      )
      .finally(() => {
        if (isUnmounted.current) return;
        setPreparedAccounts(true);
      });
  }, []);

  useEffect(() => {
    if (preparedAccounts) {
      _prepareData();
    }
  }, [preparedAccounts]);

  // [INTERNAL_FUNCs]
  function _addNotification(message: any) {
    if (typeof addNotification === 'function') addNotification(message);
  }

  function _getStatePartner() {
    let newStatePartners: any[] = [];

    if (queryPartnerName) {
      newStatePartners = [queryPartnerName];
    } else if (!isEmpty(locationState?.partners)) {
      newStatePartners = uniq(locationState?.partners);
    }

    if (!isEmpty(newStatePartners) && !newStatePartners.includes(INTERNAL_PARTNERS.hq)) {
      newStatePartners.push(INTERNAL_PARTNERS.hq);
    }

    if (isEmpty(newStatePartners)) {
      newStatePartners = [
        INTEGRATION_PARTNERS.arbiter,
        INTEGRATION_PARTNERS.aia,
        INTEGRATION_PARTNERS.nfhs,
        INTEGRATION_PARTNERS.dragonfly,
        INTERNAL_PARTNERS.hq
      ];
      if (!awayGameEnabled) {
        const filteredNewStatePartners = newStatePartners.filter(value => value !== INTERNAL_PARTNERS.hq);
        newStatePartners = filteredNewStatePartners;
      }
    }

    return newStatePartners;
  }

  function _getStateAccountIds({
    districtAccount,
    schoolConfigs
  }: {
    districtAccount?: AccountDTO;
    schoolConfigs?: SchoolConfig[];
  } = {}) {
    let newStateAccountIds: any[] = [];
    let newStateSelectedAccountIds: any[] = [];
    let aiaNewStateAccountIds: any[] = [];
    let aiaNewStateSelectedAccountIds: any[] = [];
    let dragonflyNewStateAccountIds: any[] = [];
    let dragonflyNewStateSelectedAccountIds: any[] = [];

    if (queryAccountId) {
      newStateAccountIds = [queryAccountId];
      newStateSelectedAccountIds = [queryAccountId];
      aiaNewStateAccountIds = [queryAccountId];
      aiaNewStateSelectedAccountIds = [queryAccountId];
      dragonflyNewStateAccountIds = [queryAccountId];
      dragonflyNewStateSelectedAccountIds = [queryAccountId];
    } else if (!isEmpty(locationState?.accountIds)) {
      const listAccIds = uniq(locationState?.accountIds);
      newStateAccountIds = listAccIds;
      newStateSelectedAccountIds = listAccIds;
      aiaNewStateAccountIds = listAccIds;
      aiaNewStateSelectedAccountIds = listAccIds;
      dragonflyNewStateAccountIds = listAccIds;
      dragonflyNewStateSelectedAccountIds = listAccIds;
    } else if (!isEmpty(locationState?.accounts)) {
      const newStateAccounts = (locationState?.accounts ?? []).filter(
        acc => !STATES_OF_HIGH_RISK_MARKET.includes(acc?.state)
      );

      const aiaNewStateAccounts = (locationState?.accounts ?? []).filter(
        acc => !STATES_OF_HIGH_RISK_MARKET.includes(acc?.state) || acc?.state === 'AZ'
      );

      const dragonflyNewStateAccounts = locationState?.accounts || [];

      newStateAccountIds = uniq(newStateAccounts?.map(({ id }) => id));
      newStateSelectedAccountIds = newStateAccountIds;
      aiaNewStateAccountIds = uniq(aiaNewStateAccounts?.map(({ id }) => id));
      aiaNewStateSelectedAccountIds = aiaNewStateAccountIds;
      dragonflyNewStateAccountIds = uniq(dragonflyNewStateAccounts?.map(({ id }) => id));
      dragonflyNewStateSelectedAccountIds = dragonflyNewStateAccountIds;
    }

    const listAccIds: any[] = uniq([
      ...(newStateAccountIds ?? []),
      ...(aiaNewStateAccountIds ?? []),
      ...(dragonflyNewStateAccountIds ?? [])
    ]);

    let newAllAccountIds: any[] = isInternalUser
      ? listAccIds
      : listAccIds.filter(
          accountId =>
            canEdit(accountId, currentUser) ||
            (enableDistrictUnicorn &&
              canEditAccountByDistrictUser({ currentUser, accountId, districtAccount, schoolsConfig: schoolConfigs }))
        );

    if (isEmpty(newAllAccountIds)) {
      if (isInternalUser) {
        newAllAccountIds = [DEFAULT_ACCOUNT];
      } else {
        const listAccountContext = getEditorAccountIdsContext(currentUser);
        newAllAccountIds = isEmpty(listAccountContext) ? [] : [listAccountContext?.[0]];
      }
    }

    if (isEmpty(newStateAccountIds)) {
      newStateAccountIds = newAllAccountIds;
    }

    if (isEmpty(aiaNewStateAccountIds)) {
      aiaNewStateAccountIds = newAllAccountIds;
    }

    if (isEmpty(dragonflyNewStateAccountIds)) {
      dragonflyNewStateAccountIds = newAllAccountIds;
    }

    if (!isEmpty(locationState?.selectedAccounts)) {
      const newStateSelectedAccounts = (locationState?.selectedAccounts ?? []).filter(
        acc => !STATES_OF_HIGH_RISK_MARKET.includes(acc?.state)
      );

      const aiaNewStateSelectedAccounts = (locationState?.selectedAccounts ?? []).filter(
        acc => !STATES_OF_HIGH_RISK_MARKET.includes(acc?.state) || acc?.state === 'AZ'
      );

      const dragonflyNewStateSelectedAccounts = locationState?.selectedAccounts || [];

      newStateSelectedAccountIds = uniq(newStateSelectedAccounts?.map(({ id }) => id));
      aiaNewStateSelectedAccountIds = uniq(aiaNewStateSelectedAccounts?.map(({ id }) => id));
      dragonflyNewStateSelectedAccountIds = uniq(dragonflyNewStateSelectedAccounts?.map(({ id }) => id));
    }

    if (isEmpty(newStateSelectedAccountIds)) {
      newStateSelectedAccountIds = newStateAccountIds;
    }

    if (isEmpty(aiaNewStateSelectedAccountIds)) {
      aiaNewStateSelectedAccountIds = aiaNewStateAccountIds;
    }

    if (isEmpty(dragonflyNewStateSelectedAccountIds)) {
      dragonflyNewStateSelectedAccountIds = dragonflyNewStateAccountIds;
    }

    return {
      allAccountIds: newAllAccountIds,
      stateAccountIds: newStateAccountIds,
      stateSelectedAccountIds: newStateSelectedAccountIds,
      aiaStateAccountIds: aiaNewStateAccountIds,
      aiaStateSelectedAccountIds: aiaNewStateSelectedAccountIds,
      dragonflyStateAccountIds: dragonflyNewStateAccountIds,
      dragonflyStateSelectedAccountIds: dragonflyNewStateSelectedAccountIds
    };
  }

  function _getAppliedAccountIds(partnerName: any) {
    let schoolIds: any[] = [];

    if (partnerName === INTEGRATION_PARTNERS.aia) {
      schoolIds = [...(aiaSelectedAccountIds.current ?? [])];
    } else if (partnerName === INTEGRATION_PARTNERS.dragonfly) {
      schoolIds = [...(dragonflySelectedAccountIds.current ?? [])];
    } else {
      schoolIds = [...(selectedAccountIds.current ?? [])];
    }

    return schoolIds;
  }

  function _getAllAccountIds({ partnerName, isAppliedFilter }: { partnerName: any; isAppliedFilter?: boolean }) {
    if (isAppliedFilter) {
      const schoolIds = _getAppliedAccountIds(partnerName);

      if (partnerName === INTEGRATION_PARTNERS.aia) {
        return (aiaStateAccountIds ?? [])?.filter(accId => (schoolIds ?? [])?.includes(accId)) ?? [];
      }

      if (partnerName === INTEGRATION_PARTNERS.dragonfly) {
        return (dragonflyStateAccountIds ?? [])?.filter(accId => (schoolIds ?? [])?.includes(accId)) ?? [];
      }

      return (stateAccountIds ?? [])?.filter(accId => (schoolIds ?? [])?.includes(accId)) ?? [];
    }

    if (partnerName === INTEGRATION_PARTNERS.aia) {
      return aiaStateAccountIds;
    }

    if (partnerName === INTEGRATION_PARTNERS.dragonfly) {
      return dragonflyStateAccountIds;
    }

    return stateAccountIds;
  }

  function _initPageState({
    accounts,
    partnerName,
    partnerScheduleData,
    needReselectSchedule
  }: {
    partnerName: string;
    accounts: AccountDTO[];
    needReselectSchedule?: boolean;
    partnerScheduleData: PartnerScheduleData[];
  }) {
    const filterSports: any[] = [];
    const filterStatuses: any[] = [];
    let goFanEventId = stateGoFanEventId;
    let goFanSeasonId = stateGoFanSeasonId;
    let selectedSeason: EventScheduleSeasonDTO | null = null;

    if (isUnmounted.current) return;

    if (isInitialPageState.current) {
      goFanEventId = null;
      goFanSeasonId = null;
    }

    const foundPartnerData = (partnerScheduleData ?? []).find(
      item => `${item.partnerName}`.toLowerCase() === `${partnerName}`.toLowerCase()
    );

    (foundPartnerData?.partnerSchedules ?? []).some(schedule => {
      if (!!schedule.sportName && !filterSports?.includes(schedule.sportName)) {
        filterSports.push(schedule.sportName);
      }

      if (!!schedule.status && !filterStatuses?.includes(schedule.status)) {
        filterStatuses.push(schedule.status);
      }

      if (
        !needReselectSchedule ||
        !!isInitialPageState.current ||
        !schedule.gofanSeasonId ||
        !isEmpty(selectedSeason)
      ) {
        return false;
      }

      if (!!goFanSeasonId && `${schedule.gofanSeasonId}` === `${goFanSeasonId}`) {
        selectedSeason = { ...schedule };
      } else if (
        !!goFanEventId &&
        (schedule.events ?? []).some(event => `${event?.gofanEventId}` === `${goFanEventId}`)
      ) {
        selectedSeason = { ...schedule };
      }

      return false;
    });

    const optionSchoolIds: any[] = _returnOptionSchoolIds();
    const appliedSchoolIds: any[] = _getAppliedAccountIds(partnerName);

    const schoolOptions = (accounts ?? [])?.filter(acc => (optionSchoolIds ?? [])?.includes(acc.id));
    const appliedSchoolOptions = (schoolOptions ?? [])?.filter(acc => (appliedSchoolIds ?? [])?.includes(acc.id));

    filterOptions.current = {
      sports: uniq(filterSports ?? []),
      schools: uniqBy(schoolOptions ?? [], 'id'),
      statuses: uniq([...ALL_SCHEDULE_FILTER_STATUS, ...filterStatuses])
    };

    if (partnerName === INTERNAL_PARTNERS.hq) {
      filterOptions.current.statuses = uniq([...AWAY_SCHEDULE_FILTER_STATUS, ...filterStatuses]);
    }

    const newAppliedFilters = {
      ...filterOptions.current,
      schools: uniqBy(appliedSchoolOptions ?? [], 'id')
    };

    setAppliedFilters(newAppliedFilters);

    if (needReselectSchedule && !isEmpty(selectedSeason)) {
      setSelectedSchedule(selectedSeason);
    }

    return {
      filterOptions,
      appliedFilters: newAppliedFilters,
      selectedSchedule: selectedSeason
    };

    function _returnOptionSchoolIds() {
      if (partnerName === INTEGRATION_PARTNERS.aia) return aiaStateAccountIds;
      if (partnerName === INTEGRATION_PARTNERS.dragonfly) return dragonflyStateAccountIds;
      return stateAccountIds;
    }
  }

  function _matchScheduleBySearchParam({
    schedule,
    searchParams
  }: {
    schedule: EventScheduleSeasonDTO;
    searchParams?: EventScheduleSearchDTO;
  }) {
    const matchGenders =
      typeof searchParams?.genders === 'undefined' || searchParams?.genders?.includes(schedule?.gender);
    const matchLevels =
      typeof searchParams?.levels === 'undefined' || searchParams?.levels?.includes(schedule?.eventLevel);
    const matchSportName =
      typeof searchParams?.sportNames === 'undefined' || searchParams?.sportNames?.includes(schedule?.sportName);
    const matchHomeSchoolId =
      typeof searchParams?.schoolIds === 'undefined' || searchParams?.schoolIds?.includes(schedule?.homeSchoolId);

    return matchGenders && matchLevels && matchSportName && matchHomeSchoolId;
  }

  function _setAwayEventData({
    responses,
    staticData,
    searchParamsByPartner
  }: {
    responses: EventDTO[];
    staticData: ScheduleData;
    searchParamsByPartner?: {
      [partnerName: string]: EventScheduleSearchDTO;
    };
  }) {
    if (isUnmounted.current) return;

    const newPartnerScheduleData: PartnerScheduleData[] = [];
    const partnerScheduleList: EventScheduleSeasonDTO[] = [];

    responses?.forEach((responseData: EventDTO) => {
      const awayEventSchedule = {
        id: responseData.id,
        // archived?: boolean,
        // creationMode?: string,
        // created: boolean,
        scheduleName: responseData.name,
        duplicatedEvents: [],
        events: [],
        // eventLevel: responseData.levels?.[0] ?? '',
        eventLevel: '',
        scheduleLevels: [],
        gender: responseData.levels[0]?.genders[0],
        gofanSeasonId: undefined,
        homeSchoolId: responseData.accountId,
        receivedDate: 'receivedDate',
        sourceName: 'hq',
        sportName: '',
        status: responseData.state,
        gofanLastPublished: 'gofanLastPublished',
        // Additional properties for event
        type: 'Away',
        startEventDt: responseData.startDateTime,
        endEventDt: responseData.endDateTime,
        timeZone: responseData.timeZone,
        opponentSchoolId: responseData.opponentAccountId,
        eventVenue: {
          name: responseData.venueName,
          address: responseData.venueAddress,
          city: responseData.venueCity,
          state: responseData.venueCity,
          zipCode: responseData.venueZip,
          location: responseData.venueLocation,
          id: undefined,
          seatsIoChartKey: undefined
        },
        products: responseData.products
      } as EventScheduleSeasonDTO;
      const eventActivity = staticData.activities
        ?.filter(activity => activity.id === responseData.activityId)
        .map(activity => activity.label) ?? [responseData.reportingLabel];
      awayEventSchedule.sportName = eventActivity[0] ?? '';
      const updatedLevels =
        responseData.levels?.map(
          level =>
            ({
              id: level.levelId,
              name: level.levelName,
              label: level.levelName,
              disabled: false
            } as LevelDTO)
        ) ?? [];
      awayEventSchedule.scheduleLevels = updatedLevels;
      partnerScheduleList.push(awayEventSchedule);
    });

    const hqNewPartnerScheduleData = {
      partnerName: 'hq',
      partnerSchedules: partnerScheduleList
    } as PartnerScheduleData;
    newPartnerScheduleData.push(hqNewPartnerScheduleData);

    setPartnerScheduleData(newPartnerScheduleData);

    return {
      partnerScheduleData: newPartnerScheduleData ?? []
    };
  }

  function _setScheduleData({
    responses,
    staticData,
    searchParamsByPartner
  }: {
    responses: any[];
    staticData: ScheduleData;
    searchParamsByPartner?: {
      [partnerName: string]: EventScheduleSearchDTO;
    };
  }) {
    if (isUnmounted.current) return;

    let newGoFanEvents: EventDTO[] = [];
    let newGoFanSeasons: SeasonDTO[] = [];
    let newAwayAccounts: AccountDTO[] = [];
    let newPartnerScheduleData: PartnerScheduleData[] = [...partnerScheduleData];

    responses?.some((responseData: any) => {
      newGoFanEvents = uniqBy([...newGoFanEvents, ...responseData?.goFanEvents], 'id');

      newGoFanSeasons = uniqBy([...newGoFanSeasons, ...responseData?.goFanSeasons], 'id');

      newAwayAccounts = uniqBy([...newAwayAccounts, ...responseData?.awayAccounts], 'id');

      const foundPartnerScheduleData = newPartnerScheduleData?.find(
        item => item.partnerName === responseData?.partnerName
      );

      if (
        isEmpty(newPartnerScheduleData) ||
        isEmpty(foundPartnerScheduleData) ||
        isEmpty(foundPartnerScheduleData?.partnerSchedules)
      ) {
        newPartnerScheduleData = [
          ...(newPartnerScheduleData ?? []),
          {
            partnerName: responseData?.partnerName,
            partnerSchedules: uniqBy([...(responseData?.partnerSchedules ?? [])], 'id') as EventScheduleSeasonDTO[]
          }
        ];

        return false;
      }

      const searchParams = searchParamsByPartner?.[responseData?.partnerName] ?? {};

      newPartnerScheduleData = (newPartnerScheduleData ?? []).map(item => {
        if (item.partnerName !== responseData?.partnerName) {
          return item;
        }

        const newPartnerSchedules: EventScheduleSeasonDTO[] = uniqBy([...(responseData?.partnerSchedules ?? [])], 'id');

        item?.partnerSchedules?.some((schedule: EventScheduleSeasonDTO) => {
          const isMatch = _matchScheduleBySearchParam({ schedule, searchParams });
          const foundPartnerSchedule = newPartnerSchedules?.find(item => item?.id === schedule?.id);

          if (!isMatch && isEmpty(foundPartnerSchedule)) {
            newPartnerSchedules.push(schedule);
            return false;
          }

          return false;
        });

        return { ...item, partnerSchedules: newPartnerSchedules };
      });

      return false;
    });

    let newVenues: ScheduleVenue[] = [];
    let newLevels: ScheduleLevel[] = [];

    newPartnerScheduleData?.forEach(item => {
      const venues = EventScheduleService.getScheduleVenues({
        schedules: item.partnerSchedules,
        goFanSeasons: newGoFanSeasons ?? [],
        accounts: staticData?.accounts ?? []
      });

      const scheduleLevels = EventScheduleService.getScheduleLevels({
        schedules: item.partnerSchedules,
        goFanSeasons: newGoFanSeasons ?? [],
        levels: staticData?.levels ?? []
      });

      newVenues = [...newVenues, ...venues];
      newLevels = [...newLevels, ...scheduleLevels];
    });

    setScheduleLevels(newLevels);
    setScheduleVenues(newVenues);
    setGoFanEvents(newGoFanEvents);
    setGoFanSeasons(newGoFanSeasons);
    setAwayAccounts(newAwayAccounts);
    setPartnerScheduleData(newPartnerScheduleData);

    return {
      scheduleVenues: newVenues ?? [],
      goFanEvents: newGoFanEvents ?? [],
      goFanSeasons: newGoFanSeasons ?? [],
      awayAccounts: newAwayAccounts ?? [],
      partnerScheduleData: newPartnerScheduleData ?? []
    };
  }

  function _prepareData() {
    selectedAccountIds.current = [...(stateSelectedAccountIds ?? [])];
    aiaSelectedAccountIds.current = [...(aiaStateSelectedAccountIds ?? [])];
    dragonflySelectedAccountIds.current = [...(dragonflyStateSelectedAccountIds ?? [])];

    _fetchStaticData()
      .then(_fetchInsightData)
      .then(_fetchPageData)
      .then((data: any) => {
        _initPageState({
          needReselectSchedule: true,
          accounts: data?.accounts ?? [],
          partnerName: data?.partnerName ?? '',
          partnerScheduleData: data?.partnerScheduleData ?? []
        });
      })
      .catch(error => {
        !isUnmounted.current && _addNotification(generateError(error));
      })
      .finally(() => {
        if (isUnmounted.current) return;
        isInitialPageState.current = true;
        setIsPrepared(true);
      });
  }

  async function _fetchAccounts() {
    if (isEmpty(stateAccountIds)) return [] as AccountDTO[];

    const accountResponse = await AccountService.getAccountsByIds(stateAccountIds).catch(() => [] as AccountDTO[]);

    return accountResponse;
  }

  async function _fetchDistrictAccounts(accounts: AccountDTO[]) {
    let districtAccounts =
      accounts?.filter(acc => acc.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT) ?? [];
    const underDistrictAccounts = accounts?.filter(acc => !isEmpty(acc.districtHuddleId)) ?? [];
    const districtSfIds = compact(uniq(districtAccounts.map(acc => acc.sfId)));
    const districtHuddleIds = compact(uniq(underDistrictAccounts.map(acc => acc.districtHuddleId)));
    const fetchingDistrictSfIds = compact(uniq(xor(districtHuddleIds, districtSfIds)));
    if (!isEmpty(fetchingDistrictSfIds)) {
      let fetchedDistrictAccounts = await Promise.all(
        fetchingDistrictSfIds.map(sfId => AccountService.getAccountBySfId(sfId).catch(() => null))
      ).then(compact);
      fetchedDistrictAccounts =
        fetchedDistrictAccounts?.filter(
          acc => acc.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT
        ) ?? [];
      districtAccounts = uniqBy([...districtAccounts, ...fetchedDistrictAccounts], 'id');
    }
    const schoolConfigs = await SchoolConfigService.searchSchoolConfigBySchoolIds(
      underDistrictAccounts.map(acc => acc.id)
    );

    return {
      districtAccounts,
      schoolConfigs,
      ..._getStateAccountIds({ districtAccount: districtAccounts[0], schoolConfigs })
    };
  }

  async function _fetchStaticData() {
    if (isEmpty(allAccountIds)) return {} as ScheduleData;

    const [levelResponse, activityResponse, rateResponse, accountResponse, accountsTicketTypeResponse] =
      await Promise.all([
        LevelService.getAllLevels().catch(() => [] as LevelDTO[]),
        ActivityService.getAllActivities().catch(() => [] as ActivityDTO[]),
        Promise.all([
          RateService.getRateById(config.RATE_ID1).catch(() => ({} as RateDTO)),
          RateService.getRateById(config.RATE_ID5).catch(() => ({} as RateDTO)),
          RateService.getRateById(config.RATE_ID8).catch(() => ({} as RateDTO))
        ]),
        Promise.all(
          allAccountIds?.map(accountId =>
            AccountService.getAccountById(accountId, true).catch(() => ({} as AccountDTO))
          )
        ),
        Promise.all(
          allAccountIds?.map(accountId =>
            SeasonCreationService.getAccountProducts({ accountId, activityIds: [] })
              .then(tickets => ({ schoolId: accountId, tickets }))
              .catch(() => ({ schoolId: accountId, tickets: [] } as AccountTicketTypes))
          )
        )
      ]);

    const activities = ActivityService.groupActivities(activityResponse);

    const newLevels = sortBy(
      (levelResponse ?? []).filter(level => !!level.name && !!`${level.name}`.trim()),
      'name'
    );

    if (isUnmounted.current) return {} as ScheduleData;

    setRates(rateResponse ?? []);
    setLevels(newLevels);
    setActivities(activities?.athleticActivities ?? []);
    setAccounts(accountResponse ?? []);
    setAccountsTicketTypes(accountsTicketTypeResponse ?? []);

    return {
      rates: rateResponse ?? [],
      levels: newLevels,
      activities: activityResponse ?? [],
      accounts: accountResponse ?? [],
      accountsTicketTypes: accountsTicketTypeResponse ?? []
    } as ScheduleData;
  }

  async function _fetchInsightData(staticData: ScheduleData) {
    if (isUnmounted.current) return;

    const schoolIds = (staticData?.accounts ?? [])
      ?.filter(acc => stateAccountIds?.includes(acc?.id) && !STATES_OF_HIGH_RISK_MARKET.includes(acc?.state))
      ?.map(acc => acc?.id);
    const selectedSchoolIds = (staticData?.accounts ?? [])
      ?.filter(acc => stateSelectedAccountIds?.includes(acc?.id) && !STATES_OF_HIGH_RISK_MARKET.includes(acc?.state))
      ?.map(acc => acc?.id);

    const aiaSchoolIds = (staticData?.accounts ?? [])
      ?.filter(
        acc =>
          aiaStateAccountIds?.includes(acc?.id) &&
          (!STATES_OF_HIGH_RISK_MARKET.includes(acc?.state) || acc?.state === 'AZ')
      )
      ?.map(acc => acc?.id);

    const aiaSelectedSchoolIds = (staticData?.accounts ?? [])
      ?.filter(
        acc =>
          aiaStateSelectedAccountIds?.includes(acc?.id) &&
          (!STATES_OF_HIGH_RISK_MARKET.includes(acc?.state) || acc?.state === 'AZ')
      )
      ?.map(acc => acc?.id);

    const dragonflySchoolIds = (staticData?.accounts ?? [])
      ?.filter(acc => dragonflyStateAccountIds?.includes(acc?.id))
      ?.map(acc => acc?.id);

    const dragonflySelectedSchoolIds = (staticData?.accounts ?? [])
      ?.filter(acc => dragonflyStateSelectedAccountIds?.includes(acc?.id))
      ?.map(acc => acc?.id);

    const response = await EventScheduleService.fetchPartnerInsightWithAiaAndDragonfly({
      partnerNames: statePartners.current ?? [],
      schoolIds,
      selectedSchoolIds,
      aiaSchoolIds,
      aiaSelectedSchoolIds,
      dragonflySchoolIds,
      dragonflySelectedSchoolIds,
      tempEventMonitorSettingXMeg
    });

    return { staticData, ...response } as any;
  }

  async function _fetchPageData({
    partnerNames: availablePartners,
    staticData,
    partnerInsightData
  }: {
    partnerNames: any[];
    staticData: ScheduleData;
    partnerInsightData: PartnerInsight;
  }) {
    if (isUnmounted.current || isEmpty(availablePartners)) return {};

    const partnerName = availablePartners?.includes(INTERNAL_PARTNERS.hq)
      ? INTERNAL_PARTNERS.hq
      : availablePartners?.[0] ?? '';

    setPartnerNames(availablePartners ?? []);
    setSelectedPartner(partnerName);
    setPartnerInsight(partnerInsightData);

    const data = await _fetchEventScheduleData({
      staticData,
      searchParams: {
        partnerName,
        schoolIds: _getAllAccountIds({ partnerName })
      }
    });

    return {
      ...data,
      ...staticData,
      selectedPartner: partnerName,
      partnerNames: availablePartners ?? [],
      partnerInsight: partnerInsightData
    };
  }

  async function _fetchEventScheduleData({
    staticData,
    searchParams
  }: {
    staticData: ScheduleData;
    searchParams: EventScheduleSearchDTO;
  }) {
    const { partnerName } = searchParams ?? {};

    if (partnerName === INTERNAL_PARTNERS.hq) {
      let accountIds = searchParams.schoolIds || [];

      if (tempEventMonitorSettingXMeg) {
        accountIds = await EventConfigService.getAccountIdsEnabledReceivedAwayGames(accountIds);
      }

      const awayEventSearchParam = {
        accountIds,
        awayGame: true,
        endingAfterNow: true,
        state: AWAY_SCHEDULE_FILTER_STATUS
      } as EventSearchDTO;
      const queryString = '?skipCache=true&size=2147483647';
      const awayEventScheduleResponse = await EventScheduleService.searchAwayEventSchedulesByParams(
        awayEventSearchParam,
        queryString
      ).catch(() => [] as EventPageResponseDTO);

      const data = _setAwayEventData({ staticData, responses: awayEventScheduleResponse.content });

      return {
        ...data,
        partnerName
      };
    }

    const response = await EventScheduleService.fetchEventScheduleData({
      staticData,
      searchParams
    });

    if (isUnmounted.current) return {};

    const data = _setScheduleData({ staticData, responses: [response] });

    return {
      ...data,
      partnerName
    };
  }

  async function _fetchPartnerInsightData({ schoolIds, partnerNames }: { schoolIds: any[]; partnerNames: any[] }) {
    const keyFetching = new Date().getTime();
    keyFetchInsight.current = keyFetching;

    if (isUnmounted.current) return {};

    setFetchingPartnerNames(uniq([...(fetchingPartnerNames ?? []), ...(partnerNames ?? [])]));

    const [partnerInsightResponse]: any = await Promise.all([
      EventScheduleService.fetchPartnerInsightWithAiaAndDragonfly({
        partnerNames,
        schoolIds: stateAccountIds,
        selectedSchoolIds: schoolIds,
        aiaSchoolIds: aiaStateAccountIds,
        aiaSelectedSchoolIds: schoolIds,
        dragonflySchoolIds: dragonflyStateAccountIds,
        dragonflySelectedSchoolIds: schoolIds,
        tempEventMonitorSettingXMeg
      })
    ]);

    if (keyFetchInsight.current !== keyFetching) return {};
    if (isUnmounted.current) return {};

    const { partnerInsightData: newPartnerInsightData } = partnerInsightResponse ?? {};

    const newInsightData = [
      ...(partnerInsight?.insightData ?? []).filter(
        item => !partnerNames?.includes(`${item.partnerName}`.toLowerCase())
      ),
      ...(newPartnerInsightData?.insightData ?? [])
    ];

    const newSalesInfoData = [
      ...(partnerInsight?.salesInfoData ?? []).filter(
        item => !partnerNames?.includes(`${item.partnerName}`.toLowerCase())
      ),
      ...(newPartnerInsightData?.salesInfoData ?? [])
    ];

    const newPartnerInsight: PartnerInsight = {
      insightData: newInsightData,
      salesInfoData: newSalesInfoData
    };

    setFetchingPartnerNames(fetchingPartnerNames?.filter(name => !partnerNames?.includes(name)));
    setPartnerInsight(newPartnerInsight);

    return {
      ...partnerInsightResponse,
      partnerInsight: newPartnerInsight
    };
  }

  function _onSelectSchedule(schedule: EventScheduleSeasonDTO | null) {
    setSelectedSchedule(schedule);
  }

  async function _onSelectPartner(name: string, forceSelect?: boolean) {
    try {
      const partner = name ?? '';
      const partnerName = `${partner}`.toLowerCase();

      if ((!partnerName || partnerName === `${selectedPartner}`.toLowerCase()) && !forceSelect) return;

      setIsFetchingScheduleData(true);
      setSelectedSchedule(null);
      filterOptions.current = { sports: [], schools: [], statuses: [] };
      setAppliedFilters({ ...filterOptions.current });
      setSelectedPartner(partnerName);

      const staticData = _getScheduleData();
      const allSchoolIds = _getAllAccountIds({ partnerName });
      const fetchSchoolIds = _getAppliedAccountIds(partnerName);

      const foundPartnerData = (partnerScheduleData ?? []).find(
        item => `${item.partnerName}`.toLowerCase() === `${partnerName}`.toLowerCase()
      );
      const hasPartnerData = !isEmpty(foundPartnerData?.partnerSchedules);

      const [newScheduleData]: any[] = await Promise.all([
        hasPartnerData && !forceSelect
          ? { partnerScheduleData: [...partnerScheduleData] }
          : _fetchEventScheduleData({
              staticData,
              searchParams: {
                partnerName: partner,
                schoolIds: allSchoolIds
              }
            }),
        _fetchPartnerInsightData({
          partnerNames: [partner],
          schoolIds: fetchSchoolIds
        })
      ]);

      if (isUnmounted.current) return;

      _initPageState({
        accounts,
        partnerName,
        partnerScheduleData: newScheduleData?.partnerScheduleData ?? []
      });
    } catch (error) {
      !isUnmounted.current && _addNotification(generateError(error));
    } finally {
      !isUnmounted.current && setIsFetchingScheduleData(false);
    }
  }

  async function _onApplyFilter({ type, selectedItems }: { type: string; selectedItems: any[] }) {
    try {
      setAppliedFilters({ ...appliedFilters, [type]: selectedItems });

      if (type !== 'schools') return;

      const schoolIds = (selectedItems ?? [])?.map(({ id }) => id) ?? ([] as any[]);

      if (selectedPartner === INTEGRATION_PARTNERS.aia) {
        aiaSelectedAccountIds.current = [...(schoolIds ?? [])];
      } else if (selectedPartner === INTEGRATION_PARTNERS.dragonfly) {
        dragonflySelectedAccountIds.current = [...(schoolIds ?? [])];
      } else {
        selectedAccountIds.current = [...(schoolIds ?? [])];
      }

      await _fetchPartnerInsightData({
        partnerNames: [selectedPartner],
        schoolIds: _getAllAccountIds({ isAppliedFilter: true, partnerName: selectedPartner })
      });
    } catch (error) {
      !isUnmounted.current && _addNotification(generateError(error));
    }
  }

  function _onSetScheduleNames({ name, id }: { name: string; id: string }) {
    const newScheduleNames = [...scheduleNames.filter(item => item.scheduleId !== id), { name, scheduleId: id }];

    setScheduleNames(newScheduleNames);
  }

  function _onSetFormScheduleLevel(levelValue: { scheduleLevel: ScheduleLevel; levels: LevelDTO[] }) {
    const { levels: newLevels, scheduleLevel } = levelValue ?? {};

    const newFormScheduleLevels: ScheduleLevel[] = [
      ...(formScheduleLevels ?? []).filter(item => `${item.scheduleId}` !== `${scheduleLevel?.scheduleId}`),
      { ...scheduleLevel, levels: newLevels }
    ];

    setFormScheduleLevels(newFormScheduleLevels ?? []);
  }

  function _onSetFormEventVisibility({
    archived,
    eventsArchived,
    publishTime,
    publishDate,
    scheduleId
  }: {
    archived: boolean;
    eventsArchived: boolean;
    publishTime?: string;
    publishDate?: string;
    scheduleId: string;
  }) {
    const newFormEventVisibilities = [
      ...formScheduleEventVisibility.filter(item => item.scheduleId !== scheduleId),
      { archived, eventsArchived, publishTime, publishDate, scheduleId }
    ];

    setFormScheduleEventVisibility(newFormEventVisibilities);
  }

  function _onSetFormEventAlert({ alert, scheduleId }: { alert: string; scheduleId: string }) {
    const newFormEventAlerts = [
      ...formScheduleEventAlert.filter(item => item.scheduleId !== scheduleId),
      { alert, scheduleId }
    ];

    setFormScheduleEventAlert(newFormEventAlerts);
  }

  function _onSetFormScheduleStartTimes({
    scheduleId,
    startTimes,
    startTimeType,
    seasonSettings
  }: {
    scheduleId: string;
    startTimes: any[];
    startTimeType?: string;
    seasonSettings?: string[];
  }) {
    const newFormEventStartTimes = [
      ...formScheduleEventStartTimes.filter(item => item.scheduleId !== scheduleId),
      { startTimeType, startTimes, scheduleId, seasonSettings }
    ].map(item => ({
      ...item,
      startTimes: (item.startTimes ?? []).map(ev => {
        const startTimeOptions: string[] = Object.values(ev.transformedStartTimeOptions ?? {}).filter(
          item => !isEmpty(item)
        );

        let eventStartDateTime;
        let eventEndDateTime;

        if (Array.isArray(startTimeOptions) && startTimeOptions.length === 1) {
          const eventTime = startTimeOptions[0].split(': ')[1];

          eventStartDateTime = dayjs(`${dayjs(ev.eventDate).format('YYYY-MM-DD')} ${eventTime}`)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ssZ');
          eventEndDateTime = dayjs(eventStartDateTime).utc().add(4, 'hours').format('YYYY-MM-DDTHH:mm:ssZ');

          return {
            ...ev,
            eventStartDateTime,
            eventEndDateTime,
            startTime: { time: eventTime.split(' ')[0], period: eventTime.split(' ')[1] }
          };
        }

        const earliestTime = Object.values(ev.transformedStartTimeOptions ?? {})
          .filter(item => !isEmpty(item))
          .reduce((prev: string, curr: string) => {
            const prevTime = prev.split(': ')[1];
            const currTime = curr.split(': ')[1];

            const prevTimestamp = dayjs(`${dayjs(ev.eventDate).format('YYYY-MM-DD')} ${prevTime}`).unix();
            const currTimestamp = dayjs(`${dayjs(ev.eventDate).format('YYYY-MM-DD')} ${currTime}`).unix();

            return prevTimestamp < currTimestamp ? prev : curr;
          });

        const earliestEventTime = earliestTime.split(': ')[1];
        eventStartDateTime = dayjs(`${dayjs(ev.eventDate).format('YYYY-MM-DD')} ${earliestEventTime}`)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ssZ');

        const latestTime = Object.values(ev.transformedStartTimeOptions ?? {})
          .filter(item => !isEmpty(item))
          .reduce((prev: string, curr: string) => {
            const prevTime = prev.split(': ')[1];
            const currTime = curr.split(': ')[1];

            const prevTimestamp = dayjs(`${dayjs(ev.eventDate).format('YYYY-MM-DD')} ${prevTime}`).unix();
            const currTimestamp = dayjs(`${dayjs(ev.eventDate).format('YYYY-MM-DD')} ${currTime}`).unix();

            return prevTimestamp > currTimestamp ? prev : curr;
          });

        const latestEventTime = latestTime.split(': ')[1];
        eventEndDateTime = dayjs(`${dayjs(ev.eventDate).format('YYYY-MM-DD')} ${latestEventTime}`)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ssZ');

        eventEndDateTime = dayjs(eventEndDateTime).utc().add(4, 'hours').format('YYYY-MM-DDTHH:mm:ssZ');

        return {
          ...ev,
          eventStartDateTime,
          eventEndDateTime,
          startTime: { time: earliestEventTime.split(' ')[0], period: earliestEventTime.split(' ')[1] }
        };
      })
    }));

    setFormScheduleEventStartTimes(newFormEventStartTimes);
  }

  function _onSetFormScheduleVenue({
    accountId,
    isAddVenueToAccount,
    venueValue
  }: {
    accountId: any[];
    isAddVenueToAccount?: boolean;
    venueValue: { scheduleVenue: ScheduleVenue; venue: VenueInformationValue };
  }) {
    const { venue, scheduleVenue } = venueValue ?? {};

    let newFormScheduleVenues: ScheduleVenue[] = [
      ...(formScheduleVenues ?? []).filter(item => `${item.scheduleId}` !== `${scheduleVenue?.scheduleId}`),
      { ...scheduleVenue, venue }
    ];

    if (isAddVenueToAccount) {
      newFormScheduleVenues = (newFormScheduleVenues ?? []).filter(
        item => !(item.isAccountLevel && `${item.accountId}` === `${accountId}`)
      );
      newFormScheduleVenues?.push({
        venue,
        accountId,
        isAccountLevel: true
      });
    }

    setFormScheduleVenues(newFormScheduleVenues ?? []);
  }

  function _onSetFormDefaultTicket({
    accountId,
    accountTickets
  }: {
    accountId: any;
    accountTickets: AccountProductDTO[];
  }) {
    const newFormTicketTypes: AccountTicketTypes[] = [];

    accountsTicketTypes?.forEach((accTicketTypes: AccountTicketTypes) => {
      if (`${accTicketTypes?.schoolId}` === `${accountId}`) {
        const newTickets = uniqBy([...(accTicketTypes.tickets ?? []), ...(accountTickets ?? [])], 'id');

        newFormTicketTypes.push({ ...accTicketTypes, tickets: newTickets });
      } else {
        newFormTicketTypes.push(accTicketTypes);
      }
    });

    setAccountsTicketTypes(newFormTicketTypes);
  }

  function _onSetFormAccountTicketTypes({
    accountId,
    scheduleId,
    tickets
  }: {
    accountId: string;
    scheduleId: string;
    tickets: AccountProductDTO[];
  }) {
    if (formScheduleAccountTicketTypes.length < 1) {
      setFormScheduleAccountTicketTypes([{ accountId, scheduleId, tickets }]);
      return;
    }

    if (isNil(formScheduleAccountTicketTypes.find(tTypes => tTypes.scheduleId === scheduleId))) {
      setFormScheduleAccountTicketTypes([...formScheduleAccountTicketTypes, { accountId, scheduleId, tickets }]);
      return;
    }

    const newTicketTypes = formScheduleAccountTicketTypes.map(tTypes => {
      if (tTypes.scheduleId === scheduleId) return { accountId, scheduleId, tickets };
      return tTypes;
    });

    setFormScheduleAccountTicketTypes(newTicketTypes);
  }

  function _onResetSelectedSchedule({ partnerSchedules }: { partnerSchedules?: PartnerSchedule[] }) {
    if (isEmpty(selectedSchedule)) return;
    if (isEmpty(partnerSchedules)) {
      setSelectedSchedule(null);
      return;
    }

    const curScheduleId = `${selectedSchedule?.id ?? ''}`;
    let resetScheduleId: any;
    let resetSchedule: EventScheduleSeasonDTO | null = null;

    (partnerSchedules ?? []).some(item => {
      const scheduleId = EventScheduleService.getScheduleId(item?.schedule);
      const nonGoFanScheduleId = EventScheduleService.getScheduleId({
        ...item?.schedule,
        gofanSeasonId: undefined
      });

      if (`${curScheduleId}` === `${scheduleId}`) {
        resetScheduleId = scheduleId;
        resetSchedule = { ...item?.schedule } as EventScheduleSeasonDTO;
        return true;
      }

      if (`${curScheduleId}` === `${nonGoFanScheduleId}`) {
        resetScheduleId = scheduleId;
        resetSchedule = { ...item?.schedule } as EventScheduleSeasonDTO;
      }

      return false;
    });

    if (isEmpty(resetSchedule)) {
      setSelectedSchedule(null);
    } else if (resetScheduleId !== curScheduleId) {
      setSelectedSchedule(resetSchedule);
    }
  }

  function _getRefreshScheduleStatus({
    schedule,
    globalEvents
  }: {
    globalEvents?: GlobalEventDTO[];
    schedule: EventScheduleSeasonDTO;
  }) {
    const isIgnored = (globalEvents ?? []).every(event => {
      const goFanEventStatusMsg = `${event?.gofanStatusMsg ?? ''}`.toUpperCase();
      return goFanEventStatusMsg === IGNORED_STATUS.toUpperCase();
    });

    if (isIgnored) return IGNORED_STATUS;
    if (!schedule?.gofanSeasonId) return WAITING_STATUS;

    const isUpdated = (globalEvents ?? []).some(event => !isEmpty(event?.changes));

    if (isUpdated) return UPDATED_STATUS;
    return PUBLISHED_STATUS;
  }

  async function _onRefreshScheduleData(
    {
      scheduleIds
    }: {
      scheduleIds: any[];
    },
    cb?: Function
  ) {
    try {
      const keyFetching = new Date().getTime();
      const refreshIds: any[] = [];

      scheduleIds?.forEach(scheduleId => {
        if (!refreshScheduleIds.current?.includes(scheduleId)) {
          refreshIds.push(scheduleId);
        }
      });

      if (isEmpty(refreshIds) || !isEmpty(refreshScheduleIds.current)) return;

      keyFetchScheduleData.current = keyFetching;

      refreshScheduleIds.current = uniq([...refreshScheduleIds.current, ...scheduleIds]);

      let refreshPartnerNames: any[] = [];
      let searchParamsByPartner: {
        [partnerName: string]: EventScheduleSearchDTO;
      } = {};

      partnerScheduleData?.forEach(item => {
        let paramGenders: any[] = [];
        let paramSportNames: any[] = [];
        let paramEventLevels: any[] = [];
        let paramHomeSchoolIds: any[] = [];

        item?.partnerSchedules?.some((schedule: EventScheduleSeasonDTO) => {
          if (!refreshIds?.includes(schedule?.id)) return false;

          refreshPartnerNames.push(item?.partnerName);
          paramGenders.push(schedule?.gender ?? '');
          paramSportNames.push(schedule?.sportName ?? '');
          paramEventLevels.push(schedule?.eventLevel ?? '');
          paramHomeSchoolIds.push(schedule?.homeSchoolId ?? '');

          return false;
        });

        paramGenders = uniq((paramGenders ?? [])?.filter(gender => !!gender) ?? []);
        paramSportNames = uniq((paramSportNames ?? [])?.filter(sport => !!sport) ?? []);
        paramEventLevels = uniq((paramEventLevels ?? [])?.filter(level => !!level) ?? []);
        paramHomeSchoolIds = uniq((paramHomeSchoolIds ?? [])?.filter(accId => !!accId) ?? []);

        const searchParams: EventScheduleSearchDTO = {
          partnerName: item?.partnerName ?? '',
          genders: isEmpty(paramGenders) ? undefined : paramGenders,
          levels: isEmpty(paramEventLevels) ? undefined : paramEventLevels,
          sportNames: isEmpty(paramSportNames) ? undefined : paramSportNames,
          schoolIds: isEmpty(paramHomeSchoolIds) ? undefined : paramHomeSchoolIds
        };

        searchParamsByPartner = {
          ...searchParamsByPartner,
          [item?.partnerName]: searchParams
        };
      });

      refreshPartnerNames = uniq(refreshPartnerNames);

      const staticData = _getScheduleData();
      const requests: any[] = [];
      let allSchoolIds: any[] = [];
      let fetchSchoolIds: any[] = [];

      (refreshPartnerNames ?? []).forEach(partnerName => {
        const schoolIds = _getAllAccountIds({ partnerName });
        const filterSchoolIds = _getAllAccountIds({ partnerName, isAppliedFilter: true });

        allSchoolIds = uniq([...allSchoolIds, ...schoolIds]);
        fetchSchoolIds = uniq([...fetchSchoolIds, ...filterSchoolIds]);

        let searchParams: EventScheduleSearchDTO = searchParamsByPartner?.[partnerName] ?? {};

        if (isEmpty(searchParams)) {
          searchParams = { partnerName, schoolIds };
          searchParamsByPartner = {
            ...searchParamsByPartner,
            [partnerName]: searchParams
          };
        }

        requests.push(EventScheduleService.fetchEventScheduleData({ staticData, searchParams }));
      });

      const [responses] = await Promise.all([
        Promise.all(requests),
        _fetchPartnerInsightData({
          schoolIds: fetchSchoolIds,
          partnerNames: refreshPartnerNames
        })
      ]);

      if (isUnmounted.current) return;
      if (keyFetchScheduleData.current !== keyFetching) return;

      refreshScheduleIds.current = refreshScheduleIds.current?.filter(id => !refreshIds?.includes(id));

      _setScheduleData({ staticData, responses, searchParamsByPartner });

      if (typeof cb === 'function') cb({ refreshIds } as any);
    } catch (error) {
      !isUnmounted.current && _addNotification(generateError(error));
    }
  }

  async function _onRefreshScheduleEventData(
    {
      refreshEvents
    }: {
      refreshEvents: RefreshEvent[];
    },
    cb?: Function
  ) {
    try {
      const refreshPartnerNames: any[] = [];
      let fetchSchoolIds: any[] = [];
      let newGoFanEvents: EventDTO[] = [...goFanEvents];

      let groupByPartner: {
        [k: string]: {
          [k: string]: RefreshEvent[];
        };
      } = {};

      (refreshEvents ?? []).forEach(event => {
        const scheduleId = `${event?.scheduleId ?? ''}`;
        const sourceName = `${event.globalEvent?.sourceName ?? ''}`;

        if (!isEmpty(event?.goFanEvent)) {
          newGoFanEvents = unionBy([event?.goFanEvent as EventDTO, ...newGoFanEvents], 'id');
        }

        if (!isEmpty(event?.newGoFanEvents)) {
          newGoFanEvents = unionBy([...(event?.newGoFanEvents ?? ([] as EventDTO[])), ...newGoFanEvents], 'id');
        }

        groupByPartner = {
          ...groupByPartner,
          [sourceName]: {
            ...(groupByPartner?.[sourceName] ?? {}),
            [scheduleId]: unionBy(
              [event, ...(groupByPartner?.[sourceName]?.[scheduleId] ?? [])],
              item => `${item?.scheduleId}-${item?.eventId}`
            )
          }
        };
      });

      const newPartnerScheduleData = (partnerScheduleData ?? []).map(partnerData => {
        const group = groupByPartner?.[partnerData?.partnerName] ?? {};

        if (isEmpty(group)) return partnerData;

        const newPartnerSchedules = (partnerData?.partnerSchedules ?? []).map(schedule => {
          const globalEvents = group?.[schedule?.id] ?? [];

          if (isEmpty(globalEvents)) return { ...schedule };

          const newEvents = (schedule?.events ?? []).map(event => {
            const found = globalEvents.find(item => `${item.eventId}` === `${event.id}`);

            if (isEmpty(found)) return { ...event };

            const changedEventData: any = EventScheduleService.getChangedDataOfGlobalEvent({
              goFanEvents: newGoFanEvents,
              globalEvent: found?.globalEvent as GlobalEventDTO
            });

            let newChangedData = { ...(changedEventData?.changedData ?? {}) };

            if (!isEmpty(changedEventData?.changedData) && !!changedEventData?.changedData?.opponentAccountId) {
              const changedOpponentAcc = (awayAccounts ?? []).find(
                (item: AccountDTO) => `${item.id}` === `${changedEventData?.changedData?.opponentAccountId}`
              );

              if (isEmpty(changedOpponentAcc)) {
                newChangedData = { ...newChangedData, opponentAccountId: '' };
              }
            }

            return {
              ...found?.globalEvent,
              created: !isEmpty(changedEventData?.goFanEvent),
              ...changedEventData,
              changedData: newChangedData,
              partnerId: found?.globalEvent?.sourceEventId ?? '',
              partnerName: found?.globalEvent?.sourceName ?? '',
              globalEventId: found?.globalEvent?.id ?? ''
            };
          }) as GlobalEventDTO[];

          return {
            ...schedule,
            events: newEvents,
            status: _getRefreshScheduleStatus({
              schedule,
              globalEvents: newEvents
            })
          };
        });

        const filterSchoolIds = _getAllAccountIds({ partnerName: partnerData?.partnerName, isAppliedFilter: true });

        fetchSchoolIds = uniq([...fetchSchoolIds, ...filterSchoolIds]);
        refreshPartnerNames.push(partnerData?.partnerName);

        return {
          ...partnerData,
          partnerSchedules: newPartnerSchedules
        };
      });

      if (isUnmounted.current) return;

      setGoFanEvents(newGoFanEvents);
      setPartnerScheduleData(newPartnerScheduleData);

      await _fetchPartnerInsightData({
        schoolIds: fetchSchoolIds,
        partnerNames: refreshPartnerNames
      });
    } catch (error) {
      !isUnmounted.current && _addNotification(generateError(error));
    } finally {
      if (typeof cb === 'function') cb({ refreshEvents } as any);
    }
  }

  // [MAPPING_DATA]

  function _getPageData(): SchedulePageData {
    return {
      isPrepared,
      isFetchingScheduleData,
      fetchingPartnerNames,
      isUnmounted: isUnmounted.current,
      partnerNames,
      partnerInsight,
      appliedFilters,
      filterOptions: filterOptions.current
    };
  }

  function _getScheduleData(): ScheduleData {
    return {
      rates,
      levels,
      accounts,
      activities,
      goFanEvents,
      awayAccounts,
      goFanSeasons,
      scheduleVenues,
      accountsTicketTypes,
      districtAccounts,
      schoolConfigs
    };
  }

  function _filterEventSchedules({ schedules }: { schedules: EventScheduleSeasonDTO[] }): EventScheduleSeasonDTO[] {
    const filteredSchedules = [...(schedules ?? [])];
    const filteredSchoolIds = (appliedFilters?.schools ?? []).map(({ id }) => id).filter(id => !!id);

    return filteredSchedules
      .filter(
        schedule =>
          `${selectedPartner}`.toLowerCase() === `${schedule?.sourceName}`.toLowerCase() &&
          (filteredSchoolIds.includes(schedule.homeSchoolId) ||
            (schedule.type === 'Away' && filteredSchoolIds.includes(schedule?.opponentSchoolId ?? ''))) &&
          (appliedFilters?.sports ?? []).includes(schedule.sportName) &&
          (appliedFilters?.statuses ?? []).includes(schedule.status)
      )
      .map(schedule => {
        const filteredEventsSchedule = {
          ...schedule,
          events: schedule.events.filter(
            event =>
              !moment(event.startEventDt).isSameOrBefore(moment()) &&
              !moment(event.endEventDt).isSameOrAfter(moment().add(180, 'days'))
          )
        };

        return filteredEventsSchedule;
      })
      .sort((a, b) => {
        const aIndex = SCHEDULE_SORT_INDEX[a.status?.toLowerCase()] ?? SCHEDULE_SORT_INDEX.default;
        const bIndex = SCHEDULE_SORT_INDEX[b.status?.toLowerCase()] ?? SCHEDULE_SORT_INDEX.default;

        if (aIndex === bIndex) {
          return moment(a.receivedDate).isAfter(moment(b.receivedDate)) ? -1 : 1;
        }
        return aIndex < bIndex ? -1 : 1;
      });
  }

  function _getScheduleLevel(schedule: EventScheduleSeasonDTO) {
    const goFanSeasonLevel = scheduleLevels?.find(item => `${item.scheduleId}` === `${schedule.id}`);
    let scheduleLevel = formScheduleLevels?.find(item => `${item.scheduleId}` === `${schedule.id}`);

    if (isEmpty(scheduleLevel?.levels)) {
      scheduleLevel = goFanSeasonLevel;
    }

    return { scheduleLevel, goFanSeasonLevel };
  }

  function _getScheduleVenue(schedule: EventScheduleSeasonDTO) {
    if (schedule.type) {
      return {
        accountId: schedule?.homeSchoolId,
        scheduleId: schedule?.id,
        goFanSeasonId: undefined,
        venue: schedule.eventVenue
      } as ScheduleVenue;
    }
    let scheduleVenue = formScheduleVenues?.find(item => `${item.scheduleId}` === `${schedule.id}`);

    if (isEmpty(scheduleVenue?.venue)) {
      scheduleVenue = scheduleVenues?.find(item => `${item.scheduleId}` === `${schedule.id}`);
    }

    if (isEmpty(scheduleVenue?.venue)) {
      const foundVenue = formScheduleVenues?.find(
        item => item.isAccountLevel && `${item.accountId}` === `${schedule.homeSchoolId}`
      );

      if (!isEmpty(foundVenue?.venue)) {
        scheduleVenue = {
          accountId: schedule?.homeSchoolId,
          scheduleId: schedule?.id,
          goFanSeasonId: schedule?.gofanSeasonId,
          venue: foundVenue?.venue
        } as ScheduleVenue;
      }
    }

    return scheduleVenue;
  }

  function _getScheduleName(schedule: EventScheduleSeasonDTO) {
    const scheduleName = scheduleNames.find(item => `${item.scheduleId}` === `${schedule.id}`);

    if (scheduleName) return scheduleName?.name;

    return schedule.scheduleName;
  }

  function _getAccountTicketTypes(schedule: EventScheduleSeasonDTO, scheduleLevel: ScheduleLevel): AccountProductDTO[] {
    const scheduleActivityId = activities?.find(
      (activity: any) => `${activity?.label}`.toLowerCase() === `${schedule?.sportName}`.toLowerCase()
    )?.id;

    let tickets = [];

    const updatedAccountTicketTypes = formScheduleAccountTicketTypes.find(tTypes => tTypes.scheduleId === schedule.id);
    if (!isNil(updatedAccountTicketTypes)) tickets = updatedAccountTicketTypes.tickets;
    else {
      tickets =
        accountsTicketTypes?.find((accountTickets: any) => `${accountTickets?.schoolId}` === `${schedule.homeSchoolId}`)
          ?.tickets ?? [];
    }

    const accountTickets = tickets?.filter(
      (ticket: AccountProductDTO) =>
        `${ticket.status}`.toLowerCase() === 'active' &&
        `${ticket.activityId}` === `${scheduleActivityId}` &&
        ticket.levelIds?.some(levelId => scheduleLevel?.levels?.some(level => `${levelId}` === `${level.id}`))
    );

    return accountTickets;
  }

  function _getScheduleStatus(schedule: EventScheduleSeasonDTO) {
    const newScheduleStatus = schedule?.status;

    const foundIgnoredSeason = (ignoredGlobalSeasons ?? [])?.find(item => schedule.id === item.scheduleId);

    if (!isEmpty(foundIgnoredSeason)) {
      const isAllIgnored = (schedule.events ?? []).every(event => {
        const gofanStatusMsg = `${event?.gofanStatusMsg ?? ''}`.toLowerCase();
        const isIgnored = gofanStatusMsg === IGNORED_STATUS.toLowerCase();

        return isIgnored || (foundIgnoredSeason?.eventIds ?? [])?.includes(event.id);
      });

      if (isAllIgnored) return IGNORED_STATUS;
    }

    return newScheduleStatus;
  }

  function _getPublishData(): PublishData {
    const partnerSchedules: PartnerSchedule[] = [];
    let totalEvent = 0;
    let isDisabledPublishAll = false;

    const foundData = partnerScheduleData?.find(
      item => `${item.partnerName}`.toLowerCase() === `${selectedPartner}`.toLowerCase()
    );

    const filteredEventSchedules: EventScheduleSeasonDTO[] = _filterEventSchedules({
      schedules: foundData?.partnerSchedules ?? []
    });

    (filteredEventSchedules ?? []).some(schedule => {
      const isWaiting = `${schedule?.status}`.toLowerCase() === WAITING_STATUS.toLowerCase();

      if (isEmpty(schedule.events) && !schedule.type) return false;

      const { goFanSeasonLevel, scheduleLevel } = _getScheduleLevel(schedule);

      const accountTickets = _getAccountTicketTypes(schedule, scheduleLevel as ScheduleLevel);

      const scheduleVenue = _getScheduleVenue(schedule);

      const scheduleName = _getScheduleName(schedule);

      const archivedData = formScheduleEventVisibility.find(item => item.scheduleId === schedule.id);

      const alert = formScheduleEventAlert.find(item => item.scheduleId === schedule.id)?.alert ?? undefined;

      const { startTimes, startTimeType, seasonSettings } =
        formScheduleEventStartTimes.find(item => item.scheduleId === schedule.id) ?? {};

      const uniqueEventIds = uniq((startTimes ?? []).map(item => item.eventId));

      const scheduleStartTimes = uniqueEventIds
        .map(eventId => (startTimes ?? []).find(item => item.eventId === eventId))
        .map(
          item =>
            item ?? {
              ...item,
              eventIntegrationDetails:
                (item.transformedEventIntegrationDetails ?? []).length > 0
                  ? item.transformedEventIntegrationDetails
                  : undefined
            }
        )
        .filter(item => !isEmpty(item));

      const isMissingDefaultTicket = isWaiting && isEmpty(accountTickets);

      if (!isDisabledPublishAll && (isEmpty(scheduleVenue?.venue) || isMissingDefaultTicket)) {
        isDisabledPublishAll = true;
      }

      if (schedule.events?.length > 0) {
        const futureEvents = (schedule.events ?? []).filter(
          event =>
            isEmpty(event.gofanStatusMsg) ||
            (event.gofanStatusMsg?.toLowerCase() === PUBLISHED_STATUS.toLowerCase() && !isEmpty(event.changes))
        );
        totalEvent += futureEvents.length;
      }

      const newScheduleStatus = _getScheduleStatus(schedule);

      partnerSchedules.push({
        id: schedule.id,
        archived: archivedData?.archived ?? false,
        eventsArchived: archivedData?.eventsArchived ?? false,
        publishDate: archivedData?.publishDate,
        publishTime: archivedData?.publishTime,
        alert,
        scheduleVenue,
        scheduleLevel,
        scheduleStartTimes,
        seasonStartTimeSettings: seasonSettings,
        startTimeType,
        accountTickets,
        goFanSeasonLevel,
        schedule: {
          ...schedule,
          scheduleName,
          status: newScheduleStatus
        },
        type: schedule.type
      });
      return false;
    });

    return {
      totalEvent,
      partnerSchedules,
      isDisabledPublishAll: isDisabledPublishAll || isEmpty(partnerSchedules)
    };
  }

  async function _onUpdatePartnerEvents({
    type,
    eventScheduleValues
  }: {
    type: 'add' | 'update';
    eventScheduleValues: any;
  }) {
    if (!selectedSchedule) return;

    if (
      eventScheduleValues.opponentSchool &&
      !eventScheduleValues.opponentTBD &&
      isNil(awayAccounts.find(acc => acc.id === eventScheduleValues.opponentSchool.id))
    ) {
      setAwayAccounts([...awayAccounts, eventScheduleValues.opponentSchool]);
    }

    let newPartnerScheduleData = [...partnerScheduleData];

    const startEventDt =
      eventScheduleValues.allDayEvent === true
        ? dayjs(eventScheduleValues.eventStartDate)
            .utc()
            .set('hour', 0)
            .set('minute', 1)
            .set('second', 0)
            .format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE)
        : dayjs(eventScheduleValues.eventStartDate).utc().format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE);

    const endEventDt =
      eventScheduleValues.allDayEvent === true
        ? dayjs(eventScheduleValues.eventStartDate)
            .utc()
            .set('hour', 23)
            .set('minute', 59)
            .set('second', 0)
            .format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE)
        : dayjs(eventScheduleValues.eventStartDate).utc().add(4, 'hours').format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE);

    if (type === 'add') {
      newPartnerScheduleData = newPartnerScheduleData.map(partnerData => {
        if (partnerData.partnerName === selectedSchedule.sourceName) {
          partnerData.partnerSchedules.map(pSchedule => {
            if (pSchedule.id === selectedSchedule.id) {
              const duplicatedEvent = selectedSchedule.events[selectedSchedule.events.length - 1];
              const newEventId = uuidv4().replaceAll('-', '');

              pSchedule.events.push({
                ...duplicatedEvent,
                gofanStatusMsg: null,
                gofanEventId: null,
                sourceEventId: undefined,
                id: newEventId,
                globalEventId: null,
                awaySchoolId: !eventScheduleValues.opponentTBD ? eventScheduleValues.opponentSchool.id : 'None',
                eventName: !eventScheduleValues.opponentTBD ? duplicatedEvent.eventName : 'None',
                startEventDt,
                endEventDt
              });
            }

            return pSchedule;
          });
        }

        return partnerData;
      });
    }

    if (type === 'update') {
      newPartnerScheduleData = newPartnerScheduleData.map(partnerData => {
        if (partnerData.partnerName === selectedSchedule.sourceName) {
          return {
            ...partnerData,
            partnerSchedules: partnerData.partnerSchedules.map(pSchedule => {
              if (pSchedule.id === selectedSchedule.id) {
                return {
                  ...pSchedule,
                  events: pSchedule.events.map(scheduleEvent => {
                    if (scheduleEvent.id === eventScheduleValues.selectedPartnerEvent?.id) {
                      return {
                        ...scheduleEvent,
                        // awaySchoolId: !eventScheduleValues.opponentTBD
                        //   ? eventScheduleValues.opponentSchool?.id
                        //   : 'None',
                        // eventName: !eventScheduleValues.opponentTBD ? scheduleEvent.eventName : 'None',
                        startEventDt,
                        endEventDt
                      };
                    }

                    return scheduleEvent;
                  })
                };
              }

              return pSchedule;
            })
          };
        }

        return partnerData;
      });
    }

    setPartnerScheduleData(newPartnerScheduleData);
  }

  return {
    pageData: _getPageData(),
    publishData: _getPublishData(),
    scheduleData: _getScheduleData(),
    selectedPartner,
    selectedSchedule,
    onApplyFilter: _onApplyFilter,
    onSelectPartner: _onSelectPartner,
    onSelectSchedule: _onSelectSchedule,
    onSetScheduleNames: _onSetScheduleNames,
    onSetFormScheduleLevel: _onSetFormScheduleLevel,
    onSetFormEventVisibility: _onSetFormEventVisibility,

    onSetFormEventAlert: _onSetFormEventAlert,

    formScheduleEventStartTimes,
    onSetFormScheduleStartTimes: _onSetFormScheduleStartTimes,

    onSetFormScheduleVenue: _onSetFormScheduleVenue,
    onSetFormDefaultTicket: _onSetFormDefaultTicket,
    onSetFormScheduleAccountTicketTypes: _onSetFormAccountTicketTypes,
    onResetSelectedSchedule: _onResetSelectedSchedule,
    onRefreshScheduleData: _onRefreshScheduleData,
    onRefreshScheduleEventData: _onRefreshScheduleEventData,
    onUpdatePartnerEvents: _onUpdatePartnerEvents
  };
};
