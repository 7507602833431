export const ALPHANUMERIC_REGEX = /^[A-Za-z0-9\s]*$/;

export const NUMERIC_REGEX = /^[0-9\s]*$/;

export const DECIMAL_REGEX = /^(\d*\.\d+|\d+\.\d*|\d+)$/;

export const SPECIAL_CHARACTERS_REGEX = /^[A-Za-z0-9`(-)./_#'@&*+",:;?!-\s]*$/;

export const TIME_PICKER_PATTERN = /(0[1-9]|1[012]):[0-5][0-9](\s)(AM|PM)/;

export const TIME_REGEX = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export const US_PHONE_NUMBER_REGEX = /(\d{3})-(\d{3})-(\d{4})/;

export const US_PHONE_NUMBER_REGEX2 = /^\(\d{3}\) \d{3}-\d{4}$/;

export const URL_REGEX = /^(http|https):\/\/[^ "]+$/;

export const FACEBOOK_URL_REGEX = /^(https?:\/\/)?(www\.)?(facebook\.com\/).+$/;

export const INSTAGRAM_URL_REGEX = /^(https?:\/\/)?(www\.)?(instagram\.com\/).+$/;

export const TWITTER_URL_REGEX = /^(https?:\/\/)?(www\.)?((twitter|x)\.com\/).+$/;

export const YOUTUBE_URL_REGEX = /^(https?:\/\/)?(www\.)?(youtube\.com\/).+$/;

export const TIKTOK_URL_REGEX = /^(https?:\/\/)?(www\.)?(tiktok\.com\/).+$/;

export const HEX_COLOR_REGEX = /^#?([a-f0-9]{6}|[a-f0-9]{3})$/;

export const GL_CODE_REGEX = /^[a-zA-Z0-9.\-_#]*$/; // alphanumeric, period, dash, underscore, hash

export const EMAIL_REGEX =
  /^([a-zA-Z0-9_\-\.\+\\,]+)@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])+$/;
