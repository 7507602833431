import {
  Button,
  Column,
  Row,
  ComposedModal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  InlineLoading,
  Checkbox
} from 'carbon-components-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'rc-field-form';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import classNames from 'classnames';

import BasicForm from '@app/commons/generic-components/basic-form/basic-form.component';
import FieldForm from '@old-components/basic-form/form-field/form-field.component';

import strings from '@season-management/constants/strings';
import { VENUE_MODAL_MODE } from '@season-management/constants/constant';

import type { AccountVenue } from '@gofan/api/accounts';
import type { VenueInformationValue } from '@modules/season-management/pages/season-general-setup/season-venue-section/season-venue-section.component';

import './season-venue-modal.component.scss';

const EDITABLE_FIELDS = ['name', 'address', 'city', 'state', 'zipCode', 'location'];

interface SeasonVenueModalProps {
  open: boolean;
  postSeason?: boolean;
  venue?: AccountVenue | VenueInformationValue;
  isCreatingVenue?: boolean;
  mode: VENUE_MODAL_MODE;
  checked?: boolean;
  setChecked?: (_: boolean) => void;
  onSubmit: (values: VenueInformationValue) => void;
  onClose: () => void;
  updateOrCreateVenue?: (newVenue: VenueInformationValue, id?: string) => Promise<any>;
}

const SeasonVenueModal = ({
  postSeason,
  venue,
  mode = VENUE_MODAL_MODE.ADD,
  isCreatingVenue = false,
  open,
  checked,
  setChecked,
  onClose,
  onSubmit,
  updateOrCreateVenue
}: SeasonVenueModalProps) => {
  const defaultValues = useRef({} as VenueInformationValue);
  const [form] = useForm<VenueInformationValue>();
  const formRef = useRef(form);
  const [disabled, setDisabled] = useState(mode === VENUE_MODAL_MODE.UPDATE);
  const requiredVenueFields = !postSeason;

  useEffect(() => {
    if (!isNil(venue)) {
      const { streetAddress, zip } = venue as AccountVenue;
      defaultValues.current = { zipCode: zip, address: streetAddress, ...venue } as VenueInformationValue;
      formRef.current.setFieldsValue(defaultValues.current);
    }
  }, [venue]);

  return (
    <ComposedModal
      size='md'
      open={open}
      onClose={_handleOnClose}
      className='season-venue-modal'
      preventCloseOnClickOutside
    >
      <ModalHeader>
        <Row
          className={classNames(
            'gs--section-description-large',
            'gs--font-family-san',
            'gs--text-01',
            'gs--padding-top__sp2',
            'gs--padding-bottom__sp5'
          )}
        >
          <Column>{strings.SEASON_GENERAL_SETUP.SEASON_VENUE[`MODAL_TITLE_${mode}`]}</Column>
        </Row>
      </ModalHeader>

      <ModalBody>
        <BasicForm
          name='seaon-venue-information'
          form={formRef.current}
          initialValues={venue || {}}
          onFinish={_handleConfirmModal}
          onValuesChange={values => {
            setDisabled(
              mode === VENUE_MODAL_MODE.UPDATE &&
                isEqual(pick(values, EDITABLE_FIELDS), pick(defaultValues.current, EDITABLE_FIELDS))
            );
          }}
          formConfig={{
            fields: [
              {
                name: 'name',
                rules: [
                  {
                    required: true,
                    message: 'Name is required',
                    validateTrigger: 'onSubmit'
                  }
                ],
                transform: value => value?.trim()
              },
              {
                name: 'address',
                rules: [
                  {
                    required: requiredVenueFields,
                    message: 'Address is required',
                    validateTrigger: 'onSubmit'
                  }
                ],
                transform: value => value?.trim() ?? ''
              },
              {
                name: 'state',
                rules: [
                  {
                    required: requiredVenueFields,
                    message: 'State is required',
                    validateTrigger: 'onSubmit'
                  }
                ],
                transform: value => value?.trim() ?? ''
              },
              {
                name: 'zipCode',
                rules: [
                  {
                    required: requiredVenueFields,
                    message: 'Number is required',
                    validateTrigger: 'onSubmit'
                  },
                  {
                    min: 5,
                    message: 'Zip code is invalid'
                  }
                ],
                transform: value => value?.trim() ?? ''
              },
              {
                name: 'city',
                rules: [
                  {
                    required: requiredVenueFields,
                    message: 'City is required',
                    validateTrigger: 'onSubmit'
                  }
                ],
                transform: value => value?.trim() ?? ''
              },
              {
                name: 'location',
                transform: value => value?.trim() ?? ''
              },
              {
                name: 'id',
                rules: [
                  {
                    required: false,
                    transform: value => value?.trim()
                  }
                ]
              },
              {
                name: 'seatsIoChartKey',
                rules: [
                  {
                    required: false,
                    transform: value => value?.trim()
                  }
                ]
              }
            ]
          }}
        >
          <Row>
            <Column lg={6} className='gs--margin-top-sp3'>
              <FieldForm.TextInput
                name='name'
                fieldConfig={{
                  label: 'Name',
                  maxLength: 50
                }}
              />
            </Column>
            <Column lg={6} className='gs--margin-top-sp3'>
              <FieldForm.TextInput
                name='address'
                fieldConfig={{
                  label: 'Address',
                  maxLength: 125
                }}
              />
            </Column>
            <Column lg={6} className='gs--margin-top-sp3'>
              <FieldForm.TextInput
                name='city'
                fieldConfig={{
                  label: 'City',
                  maxLength: 20
                }}
              />
            </Column>
            <Column lg={6} className='gs--margin-top-sp3'>
              <FieldForm.TextInput
                name='state'
                fieldConfig={{
                  label: 'State',
                  maxLength: 2
                }}
              />
            </Column>
            <Column lg={6} className='gs--margin-top-sp3'>
              <FieldForm.NumberTextInput
                name='zipCode'
                fieldConfig={{
                  label: 'Zip Code',
                  maxLength: 5
                }}
              />
            </Column>
            <Column lg={6} className='gs--margin-top-sp3'>
              <FieldForm.TextInput
                name='location'
                fieldConfig={{
                  label: 'Location Detail',
                  maxLength: 50,
                  placeholder: 'Example: Gymnasium A'
                }}
              />
            </Column>
            {/* Hide seatsIoChartKey from user, but allow the value to pass */}
            <Column lg={6} className='gs--margin-top-sp3' style={{ display: 'none' }}>
              <FieldForm.TextInput
                name='seatsIoChartKey'
                fieldConfig={{
                  label: 'Seating chart key',
                  maxLength: 50,
                  placeholder: 'Example: 000-000-000'
                }}
              />
            </Column>
            {isEmpty(venue) && (
              <Checkbox
                name='toggle-account-id'
                id='toggle-account-id'
                labelText='Add this venue to our account'
                onChange={setChecked}
                checked={checked}
              />
            )}
            <Column lg={6} style={{ display: 'none' }}>
              <FieldForm.TextInput
                name='accountId'
                fieldConfig={{
                  label: 'accountId'
                }}
              />
            </Column>
          </Row>
        </BasicForm>
      </ModalBody>

      {mode !== VENUE_MODAL_MODE.VIEW && (
        <ModalFooter>
          <Button onClick={_handleOnCancel} kind='secondary'>
            Cancel
          </Button>

          {isCreatingVenue ? (
            <Button>
              <InlineLoading status='active' description='Updating venue' />
            </Button>
          ) : (
            <Button disabled={disabled} onClick={formRef.current.submit}>
              {strings.SEASON_GENERAL_SETUP.SEASON_VENUE[`MODAL_CONFIRM_${mode}`]}
            </Button>
          )}
        </ModalFooter>
      )}
    </ComposedModal>
  );

  function _handleConfirmModal(values: VenueInformationValue) {
    if (mode === VENUE_MODAL_MODE.ADD) {
      _handleCreateOrUpdate(values);
    } else {
      const { id } = venue as AccountVenue;
      _handleCreateOrUpdate(values, id);
    }
  }

  function _handleOnCancel() {
    formRef.current.resetFields();
    onClose();
  }

  function _handleOnClose() {
    onClose();
  }

  function _handleCreateOrUpdate(values: VenueInformationValue, id?: string) {
    const payload = { id: id ? parseInt(id, 10) : undefined, ...values };
    const canCreateOrUpdate = payload.name && payload.address && payload.city && payload.state && payload.zipCode;
    if (typeof updateOrCreateVenue === 'function' && canCreateOrUpdate) {
      updateOrCreateVenue(values, id).then(res => {
        if (res?.id) {
          const newVenue = { ...payload, id: id || res.id };
          onSubmit(newVenue);
        } else {
          onSubmit(payload);
        }

        onClose();
      });
    } else {
      onSubmit(payload);
      onClose();
    }
  }
};

export default SeasonVenueModal;
