import React from 'react';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

import styles from './styles';

const StyledCheckBox = styled(Form.Checkbox)`
  ${styles.checkBox};
`;

export default ({ children, labelSize, ...props }) => (
  <StyledCheckBox {...props} labelsize={labelSize}>
    {children}
  </StyledCheckBox>
);
