import { connect } from 'react-redux';
import HeaderComponent from './HeaderComponent';
// eslint-disable-next-line import/no-unresolved
import { removeMessage } from '../../actions';

const mapStateToProps = state => ({
  messages: state.root.messages
});

export default connect(
  mapStateToProps,
  { removeMessage }
)(HeaderComponent);
