import {
  SeasonSetupActionTypes,
  ON_PUBLISH_SEASON,
  ON_PUBLISH_SEASON_COMPLETED,
  PREPARE_DATA,
  PREPARE_DATA_COMPLETED,
  SEARCH_OPPONENT_ACCOUNTS,
  SEARCH_OPPONENT_ACCOUNTS_SUCCESS,
  RESET_PUBLISH_STATE,
  UPLOAD_FILE_EVENT_FAIL,
  UPLOAD_FILE_EVENT_SUCCESS,
  CLEAR_UPLOAD_FILE_ERROR,
  BUILD_EVENT_CREATED_DATA_COMPLETED,
  SEARCH_ACCOUNT_TICKETS_COMPLETED,
  SEARCH_ACCOUNT_TICKETS,
  CLEAR_SEARCH_OPPONENT_ACCOUNTS,
  CREATE_SEASON_FROM_ARBITER_EVENTS,
  CLEAR_ARBITER_EVENTS,
  CHECK_SCHEDULES_ARBITER_EVENTS_AVAILABLE_COMPLETED,
  SEARCH_EVENT_SCHEDULES_COMPLETED,
  CHECK_DUPLICATE_EVENTS_COMPLETED,
  UPDATE_EVENT_CONFLICTS,
  UPDATE_IS_FROM_ARBITER,
  CLEAR_EVENT_SCHEDULES
} from './actionTypes';
import { SeasonSetupState } from './types';

import { isEmpty } from '../../utils/objectUtils';

export const initialState: SeasonSetupState = {
  pageLoading: false,
  pageError: undefined,
  levels: [],
  athleticActivities: [],
  nonAthleticActivities: [],
  opponentAccounts: {},
  opponentSearchLoading: false,
  isPublishing: false,
  isPublished: false,
  uploadFileEventError: '',
  events: [],
  eventsData: {},
  loading: false,
  isFromArbiter: false,
  arbiterEvents: [],
  rates: [],
  arbiterSchools: [],
  isArbiterSchedules: false,
  eventSchedules: [],
  eventConflicts: [],
  importEventsType: ''
};

export default (state = initialState, action: SeasonSetupActionTypes) => {
  switch (action.type) {
    case PREPARE_DATA: {
      return {
        ...state,
        pageLoading: true,
        pageError: undefined
      };
    }

    case PREPARE_DATA_COMPLETED: {
      const { levels, athleticActivities, nonAthleticActivities, account, rates } = action.payload;
      return {
        ...state,
        pageLoading: false,
        pageError: isEmpty(action.error) ? undefined : action.error,
        levels: isEmpty(levels) ? [] : levels,
        athleticActivities: isEmpty(athleticActivities) ? [] : athleticActivities,
        nonAthleticActivities: isEmpty(nonAthleticActivities) ? [] : nonAthleticActivities,
        rates: isEmpty(rates) ? [] : rates,
        account
      };
    }
    case ON_PUBLISH_SEASON: {
      return {
        ...state,
        isPublishing: true,
        isPublished: false
      };
    }

    case ON_PUBLISH_SEASON_COMPLETED: {
      const { error } = action;
      return {
        ...state,
        isPublished: isEmpty(error),
        isPublishing: false,
        pageError: error
      };
    }

    case SEARCH_OPPONENT_ACCOUNTS: {
      return {
        ...state,
        opponentSearchLoading: true
      };
    }

    case SEARCH_OPPONENT_ACCOUNTS_SUCCESS: {
      const { accounts, name = 'default' } = action.payload;
      const opponentAccounts = { ...state.opponentAccounts, [name]: accounts };
      return {
        ...state,
        opponentAccounts,
        opponentSearchLoading: false
      };
    }

    case RESET_PUBLISH_STATE: {
      return {
        ...state,
        isPublished: false
      };
    }

    case UPLOAD_FILE_EVENT_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        uploadFileEventError: error
      };
    }

    case CLEAR_UPLOAD_FILE_ERROR: {
      return {
        ...state,
        uploadFileEventError: ''
      };
    }

    case UPLOAD_FILE_EVENT_SUCCESS: {
      const { events } = action.payload;
      return {
        ...state,
        events,
        uploadFileEventError: ''
      };
    }

    case BUILD_EVENT_CREATED_DATA_COMPLETED: {
      const { eventsData } = action.payload;
      return {
        ...state,
        eventsData
      };
    }

    case SEARCH_ACCOUNT_TICKETS: {
      return {
        ...state,
        loading: true
      };
    }

    case SEARCH_ACCOUNT_TICKETS_COMPLETED: {
      const { searchResult } = action.payload;
      const { pageIndex, pageCount, data } = searchResult;
      return {
        ...state,
        pageIndex,
        pageCount,
        accountTickets: data,
        loading: false
      };
    }

    case CLEAR_SEARCH_OPPONENT_ACCOUNTS: {
      return {
        ...state,
        opponentSearchLoading: false,
        opponentAccounts: []
      };
    }

    case CREATE_SEASON_FROM_ARBITER_EVENTS: {
      const { arbiterEvents, eventScheduleSeason, arbiterSchools, needToResetEventConflict } = action.payload;
      return {
        ...state,
        arbiterEvents,
        eventScheduleSeason,
        arbiterSchools,
        ...(needToResetEventConflict ? { eventConflicts: [] } : {})
      } as SeasonSetupState;
    }

    case UPDATE_IS_FROM_ARBITER: {
      const { isFromArbiter } = action.payload;
      return {
        ...state,
        isFromArbiter
      } as SeasonSetupState;
    }

    case CLEAR_ARBITER_EVENTS: {
      return {
        ...state,
        arbiterEvents: [],
        eventSchedules: [],
        eventScheduleSeason: undefined,
        arbiterSchools: [],
        importEventsType: '',
        isFromArbiter: false
      } as SeasonSetupState;
    }

    case CLEAR_EVENT_SCHEDULES: {
      return {
        ...state,
        eventSchedules: []
      };
    }

    case CHECK_SCHEDULES_ARBITER_EVENTS_AVAILABLE_COMPLETED: {
      const result = action.payload;
      return {
        ...state,
        isArbiterSchedules: result
      };
    }

    case SEARCH_EVENT_SCHEDULES_COMPLETED: {
      const { searchResult } = action.payload;
      return {
        ...state,
        eventSchedules: searchResult
      };
    }

    case CHECK_DUPLICATE_EVENTS_COMPLETED: {
      const { eventConflicts, importEventsType, eventScheduleSeason, arbiterEvents } = action.payload;
      return {
        ...state,
        eventConflicts,
        importEventsType,
        eventScheduleSeason,
        arbiterEvents,
        eventSchedules: []
      };
    }

    case UPDATE_EVENT_CONFLICTS: {
      return {
        ...state,
        importEventsType: ''
      };
    }

    default: {
      return state;
    }
  }
};
