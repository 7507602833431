import React, { Fragment } from 'react';
import styled from 'styled-components';
import { uniq } from 'lodash';

import styles from './styles';
import ActivityList from '../ActivityList';
import { REPORTING_ACTIVITIES } from '../../../../config/strings';
import { MODE, ATHLETIC, NON_ATHLETIC } from '../../constants';
import checkPermission from '../../../FastPermissions/checkPermission';

const Label = styled.label`
  ${styles.styledLabel}
`;

const Description = styled.div`
  ${styles.description}
`;

const BoundingBox = styled.div`
  ${styles.boundingBox}
`;

const OptionalLabel = styled.span`
  ${styles.optionalLabel}
`;

const getActivityIds = activities => activities.map(({ id }) => id);

const getActivityList = (id, athleticActivities, nonAthleticActivities) => {
  if (id === ATHLETIC.id) return getActivityIds(athleticActivities);
  if (id === NON_ATHLETIC.id) return getActivityIds(nonAthleticActivities);
  return [id];
};

const getCollapseParams = ({ mode, collapse, accountId, contactEmail }) => {
  let athletic = false;
  let nonAthletic = false;

  if (mode === MODE.EDIT && `${collapse.accountId}` === `${accountId}`) {
    athletic = collapse.athletic[contactEmail];
    nonAthletic = collapse.nonAthletic[contactEmail];
  }

  return {
    accountId,
    contactEmail,
    athletic,
    nonAthletic
  };
};

const ReportingActivities = ({
  mode,
  label,
  onCheck,
  collapse,
  accountId,
  contactEmail,
  editable,
  description,
  ifAllowedTo,
  optionalLabel,
  fetchActivities,
  collapseActivity,
  accountActivities,
  athleticActivities,
  nonAthleticActivities
}) => {
  const [checkedIds, setCheckedIds] = React.useState(accountActivities);
  const [collapseParams, setCollapseParams] = React.useState(
    getCollapseParams({ mode, collapse, accountId, contactEmail })
  );

  const permitted =
    !ifAllowedTo ||
    checkPermission({
      ifAllowedTo,
      on: { accountId }
    });

  React.useEffect(
    () => {
      if (mode === MODE.EDIT && `${collapse.accountId}` !== `${accountId}`) {
        collapseActivity(collapseParams);
      }
      fetchActivities();
    },
    [fetchActivities]
  );

  const onHandleCheck = React.useCallback(
    (activityId, checked) => {
      const activityList = getActivityList(
        activityId,
        athleticActivities,
        nonAthleticActivities
      );
      let checkedList = [];

      if (checked) {
        checkedList = uniq([...checkedIds, ...activityList]);
      } else {
        const unCheckedIds = uniq(activityList);
        checkedList = uniq(checkedIds.filter(id => !unCheckedIds.includes(id)));
      }

      onCheck(checkedList);
      setCheckedIds(checkedList);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIds, athleticActivities, nonAthleticActivities]
  );

  const onHandleCollapse = params => {
    setCollapseParams(params);
    if (mode === MODE.EDIT) {
      collapseActivity(params);
    }
  };

  return (
    <Fragment>
      {label && (
        <Label>
          {label}
          {optionalLabel && (
            <OptionalLabel>{` ${optionalLabel}`}</OptionalLabel>
          )}
        </Label>
      )}
      {description && <Description>{description}</Description>}
      <BoundingBox>
        {permitted && (
          <Fragment>
            <ActivityList
              athletic
              editable={editable}
              collapse={collapseParams.athletic}
              activities={athleticActivities}
              checkedIds={checkedIds}
              onCheck={onHandleCheck}
              onCollapse={() =>
                onHandleCollapse({
                  ...collapseParams,
                  accountId,
                  contactEmail,
                  athletic: !collapseParams.athletic
                })
              }
            />
            <ActivityList
              editable={editable}
              collapse={collapseParams.nonAthletic}
              activities={nonAthleticActivities}
              checkedIds={checkedIds}
              onCheck={onHandleCheck}
              onCollapse={() =>
                onHandleCollapse({
                  ...collapseParams,
                  accountId,
                  contactEmail,
                  nonAthletic: !collapseParams.nonAthletic
                })
              }
            />
          </Fragment>
        )}
      </BoundingBox>
    </Fragment>
  );
};

ReportingActivities.defaultProps = {
  mode: MODE.VIEW,
  editable: false,
  collapse: {},
  accountId: '',
  contactEmail: '',
  ifAllowedTo: '',
  accountActivities: [],
  athleticActivities: [],
  nonAthleticActivities: [],
  label: REPORTING_ACTIVITIES.LABEL,
  description: REPORTING_ACTIVITIES.DESCRIPTION,
  optionalLabel: REPORTING_ACTIVITIES.OPTIONAL_LABEL,
  onCheck: () => null,
  fetchActivities: () => null,
  collapseActivity: () => null
};

export default ReportingActivities;
