import { huddleAxiosInstanceProxy } from '../shared/axios';
import {
  SCHOOL_CONFIG,
  SCHOOL_CONFIG_BULK_UPDATE,
  SCHOOL_CONFIG_BY_SCHOOL_ID,
  SCHOOL_CONFIG_ID,
  SCHOOL_CONFIG_BY_SCHOOL_IDS
} from '@gofan/constants';

import type { SchoolConfig } from './school-config.model';
import type { JobDTO } from '../fan-management';

export class SchoolConfigRepository {
  static getSchoolConfigBySchoolId = (schoolId: string): Promise<SchoolConfig> =>
    huddleAxiosInstanceProxy.get<SchoolConfig>(
      `${SCHOOL_CONFIG_BY_SCHOOL_ID.replace('{schoolId}', `${schoolId}`)}`,
      undefined,
      true
    );

  static createSchoolConfig = (body: SchoolConfig): Promise<SchoolConfig> =>
    huddleAxiosInstanceProxy.post<SchoolConfig>(SCHOOL_CONFIG, body, undefined, true);

  static updateSchoolConfig = (body: SchoolConfig): Promise<SchoolConfig> =>
    huddleAxiosInstanceProxy.put<SchoolConfig>(`${SCHOOL_CONFIG_ID}`.replace('{id}', body.id), body, undefined, true);

  static bulkUpdateSchoolConfig = (body: SchoolConfig): Promise<JobDTO> =>
    huddleAxiosInstanceProxy.post<JobDTO, SchoolConfig>(SCHOOL_CONFIG_BULK_UPDATE, body, undefined, true);

  static searchSchoolConfigBySchoolIds = (schoolIds: string[]): Promise<SchoolConfig[]> =>
    huddleAxiosInstanceProxy.post<SchoolConfig[], string[]>(SCHOOL_CONFIG_BY_SCHOOL_IDS, schoolIds, undefined, true);
}
