import React from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import styles from './styles';
import SubmitButton from './SubmitButton';
import CancelButton from './CancelButton';

const StyledSubmitRow = styled(Grid.Row)`
  ${styles.submitRow};
`;

export default ({
  cancelAction,
  cancelText,
  submitAction,
  submitText,
  fieldProps,
  submitDisabled,
  hideCancleButton,
  customStylesSubmitRow,
  preventUpdateLastSaveValues
}) => (
  <StyledSubmitRow style={customStylesSubmitRow}>
    <Grid.Column>
      <SubmitButton
        disabled={submitDisabled}
        text={submitText}
        fieldProps={fieldProps}
        submitAction={submitAction}
        preventUpdateLastSaveValues={preventUpdateLastSaveValues}
      />
      {!hideCancleButton && (
        <CancelButton text={cancelText} cancelAction={cancelAction} />
      )}
    </Grid.Column>
  </StyledSubmitRow>
);
