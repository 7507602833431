import { css } from 'styled-components';

export default {
  wrapper: css`
    ${props =>
      props.triggerOn &&
      css`
        position: relative;
        &::before {
          z-index: 1;
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      `}
  `,
  notificationWrapper: css`
    position: absolute;
    z-index: 9999;
    width: 350px;
    pointer-events: none;
  `
};
