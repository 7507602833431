import React from 'react';
import Input from './components/Input';
import Phone from './components/Phone';
import FastFormBase from './components/FastFormBase';
import Number from './components/Number';
import Static from './components/Static';
import Dropdown from './components/Dropdown';
import ReadOnly from './components/ReadOnly';
import Hidden from './components/Hidden';
import TextBox from './components/TextBox';
import CustomField from './components/CustomField';
import ColorPicker from './components/ColorPicker';
import URL from './components/URL';
import Password from './components/Password';
import CancelButton from './components/CancelButton';
import SubmitButton from './components/SubmitButton';
import Radio from './components/Radio';
import ColorInput from './components/ColorInput';
import DateTimePicker from './components/DateTimePicker';
import Search from './components/Search';
import PushCustomField from './components/PushCustomField';
import DecimalNumber from './components/DecimalNumber';
import EnableButton from './components/EnableButton';
import Radios from './components/Radios';

export default class FastFormWrapper extends React.Component {
  static Input = Input;

  static Phone = Phone;

  static Number = Number;

  static DecimalNumber = DecimalNumber;

  static Static = Static;

  static Dropdown = Dropdown;

  static DateTimePicker = DateTimePicker;

  static Search = Search;

  static ReadOnly = ReadOnly;

  static Hidden = Hidden;

  static TextBox = TextBox;

  static ColorPicker = ColorPicker;

  static URL = URL;

  static CustomField = CustomField;

  static PushCustomField = PushCustomField;

  static Password = Password;

  static SubmitButton = SubmitButton;

  static CancelButton = CancelButton;

  static Radio = Radio;

  static ColorInput = ColorInput;

  static EnableButton = EnableButton;

  static Radios = Radios;

  render() {
    const { children, ...props } = this.props;
    return <FastFormBase {...props}>{children}</FastFormBase>;
  }
}
