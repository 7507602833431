class OrderTicket {
  constructor({
    forms,
    productId,
    seatId
  }) {
    this.raw = {
      forms,
      productId,
      promos: [],
      quantity: 1,
      seats: [seatId]
    }
  }

  get forms() {
    return this.raw.forms;
  }
  get productId() {
    return this.raw.productId;
  }
  get promos() {
    return this.raw.promos;
  }
  get quantity() {
    return this.raw.quantity;
  }
  get seats() {
    return this.raw.seats;
  }

  toJSON() {
    return {
      forms: this.forms,
      productId: this.productId,
      promos: this.promos,
      quantity: this.quantity,
      seats: this.seats
    }
  }
}

export default OrderTicket;