import ErrorDTO from '../dto/ErrorDTO';
import UserDTO from '../dto/UserDTO';
import axios from '../api/axios';
import { UPDATE_USER, RESEND_INVITATION, GET_BASIC_USER_BY_EMAIL, GET_USER_BY_EMAIL_TOKEN } from '../api/api';

const getBasicUserByEmail = (email = '', configuration = null, manualErrorHandling) => {
  const uri = GET_BASIC_USER_BY_EMAIL.replace('{email}', email);
  return axios.huddleAxiosInstanceProxy
    .get(uri, configuration, manualErrorHandling)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new UserDTO(response);
    })
    .catch(e => new ErrorDTO(e));
};

const updateUser = (userUpdated = {}) => {
  const uri = UPDATE_USER.replace('{email}', userUpdated.email);
  return axios.huddleAxiosInstanceProxy
    .put(uri, userUpdated.toJSON())
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new UserDTO(response);
    })
    .catch(e => new ErrorDTO(e));
};

const resendInviation = (email = '') => {
  const uri = `${RESEND_INVITATION.replace('{email}', email)}`;
  return axios.huddleAxiosInstanceProxy
    .post(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return true;
    })
    .catch(e => new ErrorDTO(e));
};

const getUserByEmailToken = emailToken => {
  const uri = GET_USER_BY_EMAIL_TOKEN.replace('{email_token}', emailToken);
  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new UserDTO(response);
    })
    .catch(e => new ErrorDTO(e));
};

const getUserByEmail = (emailId, manualErrorHandling) => {
  const email = encodeURIComponent(emailId);
  const uri = `users/${email}?ts=${new Date().getTime()}`;
  return axios.huddleAxiosInstanceProxy
    .get(uri, null, manualErrorHandling)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new UserDTO(response);
    })
    .catch(e => new ErrorDTO(e));
};

export default {
  updateUser,
  resendInviation,
  getBasicUserByEmail,
  getUserByEmailToken,
  getUserByEmail
};
