import { saveAs } from 'file-saver';
import QRCode from 'qrcode';
import zip from 'jszip';
import { isEmpty } from '@app/utils/objectUtils';
import {
  FLYER_PDF_FILE_NAME,
  LANGUAGE_CODES,
  QR_CODE_CONFIG,
  QR_CODE_FILE_NAME,
  QR_CODE_IMAGE_CONFIG,
  QR_DOWNLOAD_TYPE,
  FLYER_ZIP_NAME
} from './constants';
import { generatePdfDocument, QRDownloadType, QROptions } from './qr-code.component';

export const generateQRCode = (qrCode: string, qrOptions: QROptions) =>
  QRCode.toDataURL(qrCode, { ...QR_CODE_CONFIG, ...qrOptions });

const handleSaveQRCodeAsImage = async (qrCode = '', fileName: string) => {
  const qrCodeImage = await generateQRCode(qrCode, QR_CODE_IMAGE_CONFIG);
  saveAs(qrCodeImage, isEmpty(fileName) ? QR_CODE_FILE_NAME : `${fileName}.png`);
};

export type DownloadQRCode = {
  qrCode: string;
  qrOptions?: QROptions;
  button: QRDownloadType;
};

const zipTargetFiles = (files: any[], fileName: string) => {
  const download = zip();
  files.forEach(item => {
    download.file(item.name, item.blob);
  });
  download.generateAsync({ type: 'blob' }).then((content: any) => {
    saveAs(content, fileName);
  });
};

export const handleDownloadQRCode = ({ qrCode, qrOptions, button }: DownloadQRCode) => {
  switch (button.type) {
    case QR_DOWNLOAD_TYPE.DOWNLOAD_IMAGE.type:
    case QR_DOWNLOAD_TYPE.DOWNLOAD_SCHOOL_QR_CODE.type:
    case QR_DOWNLOAD_TYPE.TICKET_QR_CODE.type: {
      handleSaveQRCodeAsImage(qrCode, button.fileName || '');
      break;
    }
    case QR_DOWNLOAD_TYPE.DOWNLOAD_FLYER.type:
    case QR_DOWNLOAD_TYPE.TICKET_FLYER.type: {
      const blobEN = generatePdfDocument(button, LANGUAGE_CODES.EN, qrCode, qrOptions || {});
      const blobES = generatePdfDocument(button, LANGUAGE_CODES.ES, qrCode, qrOptions || {});
      const filename = isEmpty(button.fileName) ? FLYER_ZIP_NAME : button.fileName;
      zipTargetFiles(
        [
          { name: `${filename}${FLYER_PDF_FILE_NAME.ES}`, blob: blobES },
          { name: `${filename}${FLYER_PDF_FILE_NAME.EN}`, blob: blobEN }
        ],
        `${filename}.zip`
      );
      break;
    }
    default: {
      break;
    }
  }
};
