import { useContext, useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import ErrorDTO from '@api/dto/ErrorDTO';
import SeasonRenewalDTO from '@api/dto/SeasonRenewalDTO';

import { RootContext } from '@app/RootContext';
import { UNEXPECTED_ERROR } from '@api/api/constants';
import { isOverEventEndDate } from '@api/services/EventService';
import { checkPermissionHandlingEvent } from '@api/services/UserService';
import { getSeasonRenewalsBySeasonId } from '@api/services/SeasonRenewalService';

import type { EventDTO } from '@events/models/event.model';
import type { SeasonDTO } from '@seasons/models/season.model';
import EventService from '@events/services/events.service';
import SeasonService from '@seasons/services/season.service';

import { DELETED_STATUS } from '@modules/event-integrations_V2/constants/constants';
import EventScheduleService from '@modules/event-integrations_V2/services/event-schedule.service';

import { generateError, generateFailContent } from '@utils/alertUtils';

export interface UseDeleteSeasonProps {
  overrideEditorPermission?: boolean;
  addNotification?: (error?: any, notification?: any) => void;
  onAfterDeletedSeason?: (deletedSeason?: SeasonDTO) => void;
}

const commonError = generateFailContent(UNEXPECTED_ERROR);

export const useDeleteSeason = ({
  overrideEditorPermission = false,
  addNotification,
  onAfterDeletedSeason
}: UseDeleteSeasonProps) => {
  const { currentUser } = useContext(RootContext);

  const isUnmounted = useRef<boolean>(false);
  const checking = useRef<boolean>(false);
  const modalKey = useRef<number>(new Date().getTime());
  const deletePermission = useRef<{
    [k: string]: boolean;
  }>();

  const [modalStep, setModalStep] = useState<number>(0);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const [checked, setChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [prepared, setPrepared] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  const [goFanSeason, setGoFanSeason] = useState<SeasonDTO>();
  const [seasonEvents, setSeasonEvents] = useState<EventDTO[]>([]);
  const [deleteEvents, setDeleteEvents] = useState<EventDTO[]>([]);
  const [selectedDeleteEvents, setSelectedDeleteEvents] = useState<EventDTO[]>([]);
  const [listSeasonRenewal, setListSeasonRenewal] = useState<{
    [k: string]: {
      campaigns: any[];
      seasonRenewalFans: any[];
    };
  }>();

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  function _addNotification(error: any) {
    if (typeof addNotification === 'function') {
      addNotification(error, generateError(error) ?? commonError);
    }
  }

  function _onToggleModal(open?: boolean) {
    if (!open) {
      setLoading(false);
      setDeleting(false);
      setModalStep(0);
      setSelectedDeleteEvents([]);
    }
    modalKey.current = new Date().getTime();
    setIsOpenModal(!!open);
  }

  function _onSetData({ season, events }: { season?: SeasonDTO; events?: EventDTO[] }) {
    setGoFanSeason(season);
    setSeasonEvents(events ?? []);
    setDeleteEvents(
      (events ?? []).filter(event => {
        const eventSalesInfo = get(event, `_embedded.event-sales-info`, {});
        return eventSalesInfo?.eventTotalSaleQuantity <= 0 && !isOverEventEndDate(event);
      })
    );
  }

  async function _onPrepareData(season?: SeasonDTO) {
    let goFanEvents: EventDTO[] = [];

    try {
      if (!isEmpty(season?.eventIds)) {
        goFanEvents = await EventService.searchByIds(
          season?.eventIds ?? [],
          ['event-sales-info'],
          [
            'id',
            'name',
            'timeZone',
            'endDateTime',
            'globalEventsId',
            '_embedded',
            '_embedded.event-sales-info.eventTotalSaleQuantity'
          ]
        );
      }
    } catch (error) {
      _addNotification(error);
    }

    _onSetData({ season, events: goFanEvents });
  }

  async function _onInitModal(season?: SeasonDTO) {
    setIsOpenModal(true);
    setLoading(true);

    if (
      !prepared ||
      `${season?.id}` !== `${goFanSeason?.id}` ||
      (season?.eventIds ?? []).length !== (goFanSeason?.eventIds ?? []).length
    ) {
      setPrepared(false);
      await _onPrepareData(season);
      setPrepared(true);
    }

    setLoading(false);
  }

  async function _hasRenewal(season?: SeasonDTO) {
    let result = false;

    if (!isEmpty(listSeasonRenewal?.[`${season?.id}`])) return true;

    try {
      const response = await getSeasonRenewalsBySeasonId(season?.id);

      if (response instanceof ErrorDTO) {
        _addNotification(response);
      } else if (response instanceof SeasonRenewalDTO) {
        const seasonRenewals = response?.toJSON();
        result = !isEmpty(seasonRenewals?.seasonRenewalFans ?? []);
        setListSeasonRenewal(prevList => ({
          ...prevList,
          [`${season?.id}`]: seasonRenewals
        }));
      }
    } catch (error) {
      _addNotification(error);
    }

    return result;
  }

  async function _checkDeleteSeasonPermission(season?: SeasonDTO) {
    const { accountId, financialAccountId } = season ?? {};
    const isOverSeasonEndDate = isOverEventEndDate({ ...season });
    const seasonSalesInfo = get(season, '_embedded.season-sales-info');

    if (isOverSeasonEndDate) return false;
    if (seasonSalesInfo && seasonSalesInfo?.seasonTotalSaleQuantity > 0) return false;
    if (
      !(
        checkPermissionHandlingEvent({ accountId, currentUser }) ||
        checkPermissionHandlingEvent({ accountId: financialAccountId, currentUser }) ||
        overrideEditorPermission
      )
    ) {
      return false;
    }

    const hasRenewal = await _hasRenewal(season);
    if (hasRenewal) return false;

    return true;
  }

  async function _checkDeletePermission(season?: SeasonDTO) {
    if (isEmpty(season) || typeof deletePermission?.current?.[`${season?.id}`] !== 'undefined') return;
    if (checking.current) return;

    checking.current = true;
    setChecked(false);

    const result = await _checkDeleteSeasonPermission(season);
    deletePermission.current = {
      ...deletePermission?.current,
      [`${season?.id}`]: result
    };

    checking.current = false;
    setChecked(true);
  }

  function _canDeleteSeason(season?: SeasonDTO) {
    _checkDeletePermission(season);
    return !!deletePermission.current?.[`${season?.id}`];
  }

  function _onSelectDeleteEvents(events: EventDTO[] = []) {
    setSelectedDeleteEvents(events);
  }

  async function _onDeleteSeason() {
    try {
      setDeleting(true);

      const deleteEventIds = selectedDeleteEvents?.map(event => event.id);
      const partnerEvents = seasonEvents?.filter(event => !!event.globalEventsId);
      const errors: any[] = [];

      await SeasonService.deleteSeason(goFanSeason as SeasonDTO, deleteEventIds);

      await Promise.all(
        (partnerEvents ?? []).map(event => {
          const isDelete = deleteEventIds.find(id => `${id}` === `${event.id}`);

          return EventScheduleService.updateGlobalEvent({
            id: `${event?.globalEventsId}`,
            gofanSeasonId: '',
            ...(isDelete ? { gofanStatusMsg: DELETED_STATUS } : {})
          }).catch(error => {
            errors.push(error);
            return error;
          });
        })
      );

      if (!isEmpty(errors)) _addNotification(errors?.[0]);

      setDeleting(false);

      if (typeof onAfterDeletedSeason === 'function') onAfterDeletedSeason(goFanSeason);
    } catch (error) {
      _addNotification(error);
    }

    _onToggleModal(false);
  }

  return {
    goFanSeason,
    seasonEvents,
    deleteEvents,
    selectedDeleteEvents,
    modalData: {
      step: modalStep,
      open: isOpenModal,
      checked,
      loading,
      prepared,
      deleting,
      modalKey: modalKey.current,
      onGoToStep: setModalStep,
      onToggleModal: _onToggleModal,
      onSelectDeleteEvents: _onSelectDeleteEvents
    },
    onSetData: _onSetData,
    onInitModal: _onInitModal,
    canDeleteSeason: _canDeleteSeason,
    onDeleteSeason: _onDeleteSeason
  };
};
