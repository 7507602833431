import React, { useContext } from 'react';
import { Form } from 'semantic-ui-react';
import FormSafeguard from './FormSafeguard';
import { FastFormContext } from './FastFormBase';
import ViewOnlyGuard from './ViewOnlyGuard';

export default ({ nullText, formKey, label }) => {
  const fastFormContext = useContext(FastFormContext);
  const formValue = fastFormContext.form[formKey];

  return (
    <FormSafeguard formKey={formKey}>
      <ViewOnlyGuard nullText={nullText} label={label} formKey={formKey}>
        <Form.TextArea
          onChange={({ target: { value } }) =>
            fastFormContext.updateField(formKey, { ...formValue, value })
          }
          onFocus={() => fastFormContext.focusField(formKey, formValue)}
          onBlur={() => fastFormContext.blurField(formKey, formValue)}
          label={label}
          value={formValue.value}
        />
      </ViewOnlyGuard>
    </FormSafeguard>
  );
};
