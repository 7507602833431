export const BROADCAST_STATUS = {
  SCHEDULED: 'scheduled',
  ON_AIR: 'on_air',
  OFF_AIR: 'off_air',
  COMPLETE: 'complete'
};

export const PARTNER_GENDER_MAPPING = {
  BOYS: '1',
  GIRLS: '2',
  COED: '3'
};

export const PARTNER_LEVELS_MAPPING = {
  froshmore: 'sophomore'
};

export const VIEW_GAME_STATUS = {
  PREV: 'PREV',
  DURING: 'DURING',
  POST: 'POST'
};

export const DEFAULT_BROADCAST_EVENT_VALUES = {
  broadcastStartTime: '',
  broadcastEndTime: undefined,
  // gender: string
  isBroadcast: true,
  streamOnly: true,
  // level: string
  pixellotKey: '',
  unListed: false,
  // vodBlackoutEndDate?: string
  // vodBlackoutStartDate?: string;
  vodUnlisted: true
};

export const EMPTY_BROADCAST_EVENT_VALUES = {
  broadcastStartTime: null,
  broadcastEndTime: null,
  isBroadcast: false,
  streamOnly: false,
  pixellotKey: null,
  unListed: null,
  vodBlackoutEndDate: null,
  vodBlackoutStartDate: null,
  vodUnlisted: null
};
