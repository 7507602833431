import { useEffect } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { config } from '@gofan/constants';
import { PAGES } from '@config/routes';

import { MAX_CAPACITY } from '@app/modules/seasons/constants/constants';
import EventsService from '@app/modules/events/services/events.service';

export const getSchoolImage = (schoolId, type, imageName) => {
  if (isEmpty(imageName)) return '';
  return `https://${config.s3.BUCKET}.${config.s3.URL}/${config.s3.BASE_PATH}/${type}/${schoolId}/${imageName}?cacheBlock=true`;
};

export const navigateToEventLandingPage = (history, accountId) =>
  history.push(PAGES.eventsV2.root.calculatePath(accountId));

export const usePendoSetUrl = (
  rules = [],
  allowUseEffect = true,
  options = {
    separator: '&',
    useBrowserUrlAfterLeaving: true
  }
) =>
  useEffect(() => {
    const { pendo } = window;
    if (allowUseEffect && pendo && pendo.location) {
      const href = pendo.location.getHref();
      const { separator, useBrowserUrlAfterLeaving } = options;
      const appendedRules = rules.join(separator);
      pendo.location.setUrl(`${href}${separator}${appendedRules}`);
      return () => {
        if (useBrowserUrlAfterLeaving) {
          pendo.location.useBrowserUrl();
        }
      };
    }
    return () => {};
  }, [allowUseEffect]);

export const isDisplayTicketAllotment = (event, limit) =>
  (EventsService.isFundraiser(event) || (event?.maxCapacity && event?.maxCapacity !== MAX_CAPACITY)) &&
  limit &&
  limit !== MAX_CAPACITY;

export const notifyForHiddenFees = ({
  productId,
  checkedAlert,
  setCheckedAlert,
  linkedAlert,
  setLinkedAlert,
  hiddenFees
}) => {
  if (linkedAlert.haveShown) setLinkedAlert({ ...linkedAlert, ticketTypeId: undefined });
  if (checkedAlert && !checkedAlert.haveShown) {
    // Hide Turn On notification
    if (hiddenFees) return;
    setCheckedAlert({ ticketTypeId: productId, haveShown: true });
  }
};

export const notifyIfBlurPrice = ({
  isCreated,
  price,
  hiddenFees,
  productId,
  checkedAlert,
  setCheckedAlert,
  linkedAlert,
  setLinkedAlert
}) => {
  if (isCreated || !price || !hiddenFees) return;
  notifyForHiddenFees({
    productId,
    checkedAlert,
    setCheckedAlert,
    linkedAlert,
    setLinkedAlert,
    hiddenFees
  });
};

export const getFormFieldTextByName = (fieldName = '', FORM_TEMPLATE = [], useFieldNameForNullField = true) => {
  const field = FORM_TEMPLATE.find(form => form.name === fieldName);
  if (field) return field.text;
  return useFieldNameForNullField ? fieldName : '';
};

export const getTicketDistribution = ({ event, ticketFormState }) => {
  const internalFormState = get(ticketFormState, 'internalFormState.values');
  return isEmpty(internalFormState)
    ? get(event, 'ticketDistribution', false)
    : get(internalFormState, 'ticketDistribution', false);
};

export const arrangePromoCodesToPublish = codes => {
  const accessCodes = codes.filter(item => item.required);
  const promoCodes = codes.filter(item => !item.required);

  if (isEmpty(accessCodes) || isEmpty(promoCodes)) {
    return codes;
  }

  const result = [];
  for (let i = 0; i < Math.max(accessCodes.length, promoCodes.length); i++) {
    if (i < accessCodes.length) result.push(accessCodes[i]);
    if (i < promoCodes.length) result.push(promoCodes[i]);
  }

  return result;
};

export default {
  getSchoolImage,
  usePendoSetUrl,
  isDisplayTicketAllotment,
  getFormFieldTextByName,
  arrangePromoCodesToPublish
};
