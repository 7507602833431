import React from 'react';
import { Document, Page, Text, Image, View, StyleSheet, Svg, Path } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    color: '#323232'
  },
  headerSection: {
    display: 'flex'
  },
  sectionBorderTop: {
    borderTop: '2pt solid #000000'
  },
  schoolImage: {
    width: 'auto',
    height: 89,
    alignSelf: 'center',
    marginTop: 25,
    marginBottom: 15
  },
  qrCodeImage: {
    width: 175,
    height: 175,
    alignSelf: 'center',
    marginTop: 20,
    marginBottom: 25
  },
  buyTickets: {
    fontFamily: 'Rubik',
    fontWeight: 'bold',
    fontSize: 67,
    letterSpacing: 9,
    alignSelf: 'center',
    lineHeight: 0.9,
    marginTop: 20
  },
  online: {
    fontFamily: 'Rubik',
    fontSize: 109,
    letterSpacing: 16,
    alignSelf: 'center',
    fontWeight: 'heavy',
    lineHeight: 1,
    marginBottom: 20
  },
  middleTextTop: {
    fontFamily: 'Rubik',
    marginTop: 21,
    fontSize: 18,
    alignSelf: 'center',
    lineHeight: 1.15,
    letterSpacing: 2
  },
  middleText: {
    fontFamily: 'Rubik',
    fontSize: 18,
    alignSelf: 'center',
    lineHeight: 1.25,
    letterSpacing: 2
  },
  middleTextBottom: {
    fontFamily: 'Rubik',
    fontSize: 18,
    alignSelf: 'center',
    lineHeight: 1.25,
    maxWidth: 400,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  textBold: {
    fontWeight: 'heavy'
  },
  logo: {
    width: 262,
    alignSelf: 'center',
    marginTop: 16
  }
});

interface PDFTemplateEnComponentProps {
  qrCode: string;
  schoolLogo: string;
  schoolName?: string;
  eventName?: string;
  eventStartDateTime?: string;
  isFundraiser?: boolean;
}

export const GoFanLogo = () => (
  <Svg style={styles.logo} viewBox='0 0 625.16 113.65'>
    <Path
      fill='#434b4b'
      d='M352.28,2.79h0l52.69,0c7.36,0,12,1.59,13.7,4.7L458,85.26l5.26,10.41V84l0-54.83V26.4h-12l0-23.31,42.23,0c6.8,0,11.49,2.7,14.34,8.25l.12.21.14.2,42.07,55.31,5,6.57V65.38l0-36.14V26.46h-13.6V3.14l59.15,0V26.49H586.82v2.79l-.06,81.59-28.29,0c-7,0-11.62-1.83-13.73-5.42l-.09-.14-.1-.14-44.34-58-5-6.53v8.22l0,35.71v2.79h13.6l0,23.5H416.12l0-23.91H427.3L425.54,83l-4.19-9.35L420.61,72h-1.8l-39.4,0h-1.8l-.74,1.63L372.62,83l-1.79,3.93h11.22v23.94H327.16l0-24h12.11l.8-1.39,32-55.24,2.43-4.19H369.7l-17.45,0h-63V49.78H292l33.14,0V65l-33.14,0h-2.78v2.78l0,16.48V87h13.6l-.09,23.86H244.27l0-23.9h13.6V84.18l0-55.32V26.07H244.33l0-23.27H352.28m62.17,56-1.8-3.94L402,31.59l-2.45-5.38L397,31.52,385.66,54.8l-2,4h4.45l21.95,0h4.34M352.28,0H241.56l0,28.85h13.61l0,55.32H241.5l0,29.48h64l.1-29.44H292V67.72l35.93,0V47l-35.92,0V28.88H369.7l-32,55.23H324.39l0,29.55h60.45l0-29.51h-9.69l4.25-9.34,39.4,0,4.2,9.35h-9.65l0,29.49h98.23l0-29.07H498l0-35.71,44.34,58q4,6.8,16.13,6.8l31.07,0,.06-84.38h13.95l0-28.89-64.72,0,0,28.88h13.6l0,36.14L510.34,10.07q-5-9.75-16.82-9.75l-45,0,0,28.88h12l0,54.83L421.12,6.22Q417.75,0,405,0L352.28,0Zm57.83,56H388.16L399.5,32.74,410.11,56Z'
    />
    <Path
      fill='#434b4b'
      d='M225.49,7.82Q217.89,0,201.93,0l-47,0q-15.45,0-22.36,6.81-10.66,7.64-10.67,26.43l0,42.94c14-14.12,33.85-23.22,54.34-27.68V29.36L223.37,65.2l-47.16,35.85V82.23c-19.25-1.85-38,1.83-52.42,12.22a20,20,0,0,0,2.27,4.8c2.72,4.12,4.46,6.19,5.23,6.19q6.83,8.07,24.25,8.08l45.62,0q9.61,0,15.88-2.26,5.42-2,11.34-8.71,6.48-7.11,6.5-22.31l0-46.95Q234.93,15.64,225.49,7.82Z'
    />
    <Path
      fill='#434b4b'
      d='M112.73,38.94V29.88q0-14.11-9.43-22.41Q95.81,0,81.35,0L31.77,0q-13,0-19.1,5.14Q0,12.85,0,32.68L0,80.62Q0,97,8.44,104.88q8,8.41,23.25,8.43l49.58,0c5.37,0,9.47-.52,12.27-1.58a33.52,33.52,0,0,0,11-7,26.89,26.89,0,0,0,7.9-17,36.56,36.56,0,0,0,.23-4.12l0-37.3-46.61,0,0,20.73h9.36V80.93c0,1.75-1.82,2.83-5.45,3.26l-26.53,0c-4.14-.86-6.22-2.28-6.22-4.25l0-46.61c0-3,2.06-4.51,6.18-4.5H69.65q4.72,0,5.83,4.47v5.62Z'
    />
    <Path
      fill='#434b4b'
      d='M625.16,7.79A7.81,7.81,0,1,1,617.38,0,7.78,7.78,0,0,1,625.16,7.79Zm-14.45,0a6.65,6.65,0,1,0,6.67-6.65A6.63,6.63,0,0,0,610.71,7.81Zm5.19,4.5h-1.45v-9H618a2.26,2.26,0,0,1,2.51,2.24,2.18,2.18,0,0,1-1.95,2.08v0c1.12.13,1.51.48,1.58,2.29a11.45,11.45,0,0,0,.35,2.34h-1.43a9.32,9.32,0,0,1-.37-3,1.18,1.18,0,0,0-1.35-1H615.9Zm0-5.17h1.87a1.29,1.29,0,0,0,1.31-1.45,1.25,1.25,0,0,0-1.31-1.37H615.9Z'
    />
  </Svg>
);

const PDFTemplateEnComponent = ({
  qrCode = '',
  schoolLogo = '',
  schoolName,
  eventName = '',
  eventStartDateTime = '',
  isFundraiser = false
}: PDFTemplateEnComponentProps) => (
  <Document>
    <Page size='A4' style={styles.page}>
      <View style={styles.headerSection}>
        <Image style={styles.schoolImage} src={schoolLogo} />
      </View>
      <View style={styles.sectionBorderTop}>
        <Text style={styles.buyTickets}>{isFundraiser ? 'DONATE' : 'BUY TICKETS'}</Text>
        <Text style={styles.online}>ONLINE</Text>
      </View>
      <View style={styles.sectionBorderTop}>
        <Text style={styles.middleTextTop}>USE THE QR CODE BELOW, OR</Text>
        <Text style={styles.middleText}>VISIT GOFAN.CO &amp; SEARCH</Text>
        {schoolName ? <Text style={styles.middleTextBottom}>{schoolName}</Text> : <Text>&nbsp;</Text>}
        <Image src={qrCode} style={styles.qrCodeImage} />
        {eventName ? <Text style={styles.middleTextBottom}>{eventName}</Text> : <Text>&nbsp;</Text>}
        {eventStartDateTime ? <Text style={styles.middleTextBottom}>{eventStartDateTime}</Text> : <Text>&nbsp;</Text>}
        <GoFanLogo />
      </View>
    </Page>
  </Document>
);

export default PDFTemplateEnComponent;
