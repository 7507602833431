import { css } from 'styled-components';

export default {
  content: css`
    top: 171px;
    left: 196px;
    width: 577px;
    height: 361px;
    text-align: left;
    letter-spacing: 0;
    opacity: 1;
  `,
  fontWeight: css`
    font-weight: bold;
  `,
  marginBottom: css`
    margin-bottom: 20px;
  `
};
