// @flow
class SearchAccountsRequest {
  constructor({ city, state, status, keyword, parentId, accountTypes, accountName }) {
    this.raw = {
      city,
      state,
      status,
      keyword,
      parentId,
      accountTypes,
      accountName
    };
  }

  get accountName() {
    return this.raw.accountName;
  }

  get accountTypes() {
    return this.raw.accountTypes;
  }

  get city() {
    return this.raw.city;
  }

  get parentId() {
    return this.raw.parentId;
  }

  get state() {
    return this.raw.state;
  }

  get status() {
    return this.raw.status;
  }

  get keyword() {
    return this.raw.keyword;
  }

  toJSON() {
    return {
      city: this.raw.city,
      keyword: this.keyword,
      state: this.raw.state,
      status: this.raw.status,
      parentId: this.raw.parentId,
      accountName: this.raw.accountName,
      accountTypes: this.raw.accountTypes
    };
  }
}

export default SearchAccountsRequest;
