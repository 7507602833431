import { SkeletonText } from 'carbon-components-react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import type { EventInsightDTO } from '@events/components/event-cancellations-modal/use-event-cancellations';
import strings from '@events/strings';

interface EventSalesInfoProps {
  ticketSaleInsight: EventInsightDTO;
  loading: boolean;
  error: any;
}

const ticketSaleInsightLabels: EventInsightDTO = {
  ticketSold: strings.EVENT_CANCELLATIONS.TICKETS_SOLD,
  ticketSales: strings.EVENT_CANCELLATIONS.TICKET_SALES,
  ticketUsed: strings.EVENT_CANCELLATIONS.TICKETS_USED,
  percentTicketUsed: strings.EVENT_CANCELLATIONS.PERCENT_OF_TICKETS_USED
};

const EventSalesInfo = ({ ticketSaleInsight, loading, error }: EventSalesInfoProps) => {
  return (
    <div className='container'>
      <div className='container__insight'>
        {Object.keys(ticketSaleInsightLabels).map(key => (
          <div key={key} className='container__insight__item'>
            <div className={classNames('gs--body-short-02', 'text-02')}>{ticketSaleInsightLabels[key]}</div>
            {loading ? (
              <div className='container__insight__item__loading'>
                <SkeletonText />
              </div>
            ) : (
              <div className={classNames('gs--productive-heading-03', 'text-01')}>{ticketSaleInsight[key]}</div>
            )}
          </div>
        ))}
      </div>
      {!isEmpty(error) && <div className='container__error'>{strings.EVENT_CANCELLATIONS.ERROR_INSIGHT}</div>}
    </div>
  );
};

export { EventSalesInfo };
