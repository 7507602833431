import { BrowserRouter as Router, Switch } from 'react-router-dom';
import RootContextWrapper from './RootContext';
import { QueryClientProvider } from '@gofan/components/QueryClientProvider';
import { ldContexts, withLDProvider } from '@gofan/hooks/useFeatureFlags';
import CarbonRoot from './pages/Root';
import SemanticRoot from './root/root';

import { config } from '@gofan/constants';

const AppRoot = () => (
  <RootContextWrapper>
    <QueryClientProvider>
      <Router>
        <Switch>
          {CarbonRoot()}
          {SemanticRoot()}
        </Switch>
      </Router>
    </QueryClientProvider>
  </RootContextWrapper>
);

const App = withLDProvider({
  clientSideID: config.launchDarklyConfig.clientSideID,
  options: {
    bootstrap: 'localStorage'
  },
  context: ldContexts()
})(AppRoot);

export { App, AppRoot };
