import { isEmpty, uniq, upperFirst, groupBy, capitalize } from 'lodash';

import { config } from '@gofan/constants/config';
import { GENDER_LABELS, GENDER_TYPES } from '@gofan/constants/level';
import { EVENT_TYPES } from '@gofan/constants/event';
import type { LevelDTO, LevelGenders } from '@gofan/api';

class AppUtils {
  getStaticImage = (uri: any) => {
    if (isEmpty(uri) || isEmpty(uri?.default?.src)) return '';
    return `${uri?.default?.src ?? ''}`.trim();
  };

  getGoFanSchoolPageUrl = (accountId: string) => `${config.GOFAN_URL}app/school/${accountId}`;

  getGoFanEventPageUrl = (eventId: number, accountId: string) =>
    `${config.GOFAN_URL}event/${eventId}?schoolId=${accountId}`;

  getGofanSeasonPageUrl = (seasonId: number) => `${config.GOFAN_URL}app/events/season/${seasonId}`;

  appendUrlWithUtm = (url: string, utmParams: string) =>
    url.includes('?') ? `${url}&${utmParams}` : `${url}?${utmParams}`;

  getSchoolImage = (schoolId: string | number, type: string, imageName: string, defaultImage = '') => {
    if (isEmpty(imageName)) return defaultImage;
    return `https://${config.s3.BUCKET}.${config.s3.URL}/${config.s3.BASE_PATH}/${type}/${schoolId}/${imageName}?cacheBlock=true`;
  };

  getSportIcon = (sportName: string, icons: any) => {
    const sportNamePath = sportName.replace(/ /g, '').toLowerCase();
    return icons[sportNamePath] ? icons[sportNamePath] : icons.unlabeled;
  };

  getEventType = (event: any) => {
    const { activity = {}, redemptionWindow = null, products = [] } = event ?? {};

    if (isEmpty(event) || (isEmpty(activity) && redemptionWindow === null)) {
      return EVENT_TYPES.ATHLETIC;
    }

    const isMobilePass =
      (isEmpty(products) && redemptionWindow !== null) || products.some(ticket => ticket.productType === 'MOBILEPASS');

    let eventType = EVENT_TYPES.ATHLETIC;
    if (isMobilePass) {
      eventType = EVENT_TYPES.MOBILE_PASS;
    } else if (EVENT_TYPES.CONFIG[EVENT_TYPES.PERFORMING_ART].ACTIVITIES.includes(activity?.label)) {
      eventType = EVENT_TYPES.PERFORMING_ART;
    } else if (EVENT_TYPES.CONFIG[EVENT_TYPES.REGISTRATION].ACTIVITIES.includes(activity?.label)) {
      eventType = EVENT_TYPES.REGISTRATION;
    } else if (EVENT_TYPES.CONFIG[EVENT_TYPES.SCHOOL_DANCE].ACTIVITIES.includes(activity?.label)) {
      eventType = EVENT_TYPES.SCHOOL_DANCE;
    } else if (!activity.athletic) {
      eventType = EVENT_TYPES.OTHER;
    }
    return eventType;
  };

  getGenders = (genders: string[] = []) => {
    if (isEmpty(genders)) return [GENDER_TYPES.COED];

    const genderTypes: string[] = [];
    genders.map((gender: string) => {
      const genderType = `${gender}`.toLowerCase();
      const foundGenderKey = Object.keys(GENDER_TYPES).find(
        (genderKey: string) => `${GENDER_TYPES[genderKey]}`.toLowerCase() === genderType
      );
      if (foundGenderKey) genderTypes.push(GENDER_TYPES[foundGenderKey]);
    });
    return genderTypes;
  };

  getGenderTypesBy = (levels: any[] = [], genders: any[] = []) => {
    if (isEmpty(levels)) return this.getGenders(genders);

    let newGenders: string[] = [];
    levels.some((level: any) => {
      if (isEmpty(level?.genders) && isEmpty(genders)) return false;

      const levelGenders = level.genders
        ? level.genders.map((gender: string) => `${gender}`.toLowerCase())
        : genders.map((gender: string) => `${gender}`.toLowerCase());
      newGenders = uniq([...newGenders, ...levelGenders]);
      return false;
    });
    return this.getGenders(newGenders);
  };

  getGenderLabelByActivity = (genders: string[] = [], activity: any) => {
    const eventType = this.getEventType({ activity });

    if (!EVENT_TYPES.CONFIG[eventType].ALLOW_LEVEL) return '';

    return genders.map((gender: string) => upperFirst(`${GENDER_LABELS[gender] ?? ''}`.toLowerCase())).join(' and ');
  };

  levelsToString = (levels: any[] = [], limit?: number, shortenAll?: boolean) => {
    if (levels.length === 1 && !shortenAll) {
      return levels?.[0]?.name ?? levels?.[0]?.levelName;
    }
    const reg = /\b[\w]/g;
    const levelsName: any = [];
    levels.forEach((level, index) => {
      const firstLetter = (level?.name ?? level?.levelName)?.match(reg)?.join('')?.toUpperCase();
      if (!limit || index + 1 <= limit) {
        levelsName.push(firstLetter);
      }

      if (limit && levels.length > limit && index + 2 === limit) {
        levelsName.push('...');
      }
    });

    if (isEmpty(levelsName)) return '';
    return levelsName.filter(name => `${name ?? ''}`.trim() !== '').join(' / ');
  };

  getLevelLetters = (levels: LevelDTO[] = []) => {
    if (levels.length === 1) {
      const reg = /\b[\w]/g;
      const firstLetter = levels[0]?.name?.match(reg).join('').toUpperCase();
      return firstLetter;
    }
    return this.levelsToString(levels).replaceAll(' ', '');
  };

  getGendersLevels = (levels: LevelDTO[] = [], eventLevels: any[] = []) => {
    const gendersLevel: LevelGenders[] = [];
    (eventLevels ?? []).forEach((level: any) => {
      const genders = this.getGenders(level?.genders);
      genders.forEach((gender: string) => {
        gendersLevel.push({ gender, levelName: levels.find(l => l.id === level.levelId)?.name ?? '' });
      });
    });
    return `${Object.values(groupBy(uniq(gendersLevel), 'gender'))
      .map(item => `${item[0].gender} ${item.map(i1 => i1.levelName).join('/')}`)
      .join(' & ')} `;
  };

  getContactName = (lastName: string, firstName: string) =>
    [lastName, firstName].filter(text => !isEmpty(text)).join(' ');

  getValueContactPhone = (value = '') => {
    const newValue = value.replace(/\D/g, '');
    return newValue.length > 10
      ? {
          phoneNumber: newValue.slice(0, 10),
          extNumber: newValue.slice(10)
        }
      : {
          phoneNumber: newValue,
          extNumber: ''
        };
  };

  getColor = (color: string | undefined) => (color && color?.toUpperCase() !== 'TRANSPARENT' ? `#${color}` : '#000000');

  convertImageToBase64PNG = (urlImg: string) =>
    new Promise<string>((resolve, reject) => {
      const img = new Image();
      img.src = `${urlImg}&ts=${new Date().getTime()}`;
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        ctx?.drawImage(img, 0, 0);

        const b64 = canvas.toDataURL('image/png');
        resolve(b64);
      };
      img.onerror = reject;
    });
}

export const getAccountScoreName = (name: string) => capitalize((name || '').trim().split(' ')[0]);

export default new AppUtils();
