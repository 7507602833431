import React, { useEffect, useState } from 'react';
import { isEmpty } from '@app/utils/objectUtils';
import defaultSchoolIcon from '@assets/images/gofan-icon.png';
import { Download16 } from '@carbon/icons-react';
import { pdf, Font } from '@react-pdf/renderer';
import { convertImageToBase64PNG } from '@common/utils/parseFileUpload';
import type { EventDTO } from '@app/modules/events/models/event.model';
import { formatDateTime } from '@app/utils/dateUtils';
import rubikRegular from '@assets/fonts/Rubik/Rubik-Regular.ttf';
import rubikBlack from '@assets/fonts/Rubik/Rubik-Black.ttf';
import EventsService from '@app/modules/events/services/events.service';
import { LANGUAGE_CODES, QR_DOWNLOAD_TYPE } from './constants';
import PDFTemplateEnComponent from './pdf-template-en.component';
import PDFTemplateEsComponent from './pdf-template-es.component';
import { generateQRCode, handleDownloadQRCode } from './qr-code.utils';

Font.register({
  family: 'Rubik',
  fonts: [
    { src: rubikRegular, fontStyle: 'normal', fontWeight: 700 },
    { src: rubikBlack, fontStyle: 'normal', fontWeight: 900 }
  ]
});

export type QRDownloadType = {
  type: string;
  name?: string;
  template?: string;
  schoolLogo?: string;
  fileName?: string;
  schoolName?: string;
  event?: EventDTO;
};

export type QROptions = {
  scale?: number;
  margin?: number;
  width?: number;
};

export type QRDownloadItems = {
  [key: string]: QRDownloadType;
};

export interface QRCodeComponentProps {
  qrCode: string;
  qrOptions?: QROptions;
  buttons: QRDownloadType[];
}

export const generatePdfDocument = async (
  button: QRDownloadType | undefined,
  lng: string,
  qrCode: string,
  qrOptions: QROptions
) => {
  const qrCodeImage = await generateQRCode(qrCode, qrOptions);
  const isFundraiser = EventsService.isFundraiser(button?.event);
  let schoolLogo = button?.schoolLogo || defaultSchoolIcon;
  if (schoolLogo !== defaultSchoolIcon) {
    try {
      schoolLogo = await convertImageToBase64PNG(schoolLogo);
    } catch {
      schoolLogo = defaultSchoolIcon;
    }
  }
  let startDateTime = '';
  if (!isEmpty(button?.event) && !isEmpty(button?.event?.startDateTime) && !isEmpty(button?.event?.timeZone)) {
    const formatStartDateTime = formatDateTime({
      date: button?.event?.startDateTime,
      parseZone: true,
      timeZone: button?.event?.timeZone
    });
    startDateTime =
      formatStartDateTime.toDate() !== '' && formatStartDateTime.toTime() !== ''
        ? `${formatStartDateTime.toDate()} | ${formatStartDateTime.toTime()}`
        : '';
  }
  let blob;
  switch (lng) {
    case LANGUAGE_CODES.ES: {
      blob = await pdf(
        <PDFTemplateEsComponent
          qrCode={qrCodeImage}
          schoolLogo={schoolLogo}
          schoolName={button?.schoolName}
          eventName={button?.event?.name}
          eventStartDateTime={startDateTime}
          isFundraiser={isFundraiser}
        />
      ).toBlob();
      break;
    }
    case LANGUAGE_CODES.EN:
    default: {
      blob = await pdf(
        <PDFTemplateEnComponent
          qrCode={qrCodeImage}
          schoolName={button?.schoolName}
          schoolLogo={schoolLogo}
          eventName={button?.event?.name}
          eventStartDateTime={startDateTime}
          isFundraiser={isFundraiser}
        />
      ).toBlob();
    }
  }
  return blob;
};

const QRCodeComponent = ({ qrCode = '', qrOptions = {}, buttons = [] }: QRCodeComponentProps) => {
  const [qrCodeBinary, setQRCodeBinary] = useState();
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  useEffect(() => {
    let mounted = true;
    generateQRCode(qrCode, qrOptions)
      .then((url: any) => {
        mounted && setQRCodeBinary(url);
      })
      .catch((err: any) => {
        console.error(err);
      });
    return () => {
      mounted = false;
    };
  }, [qrCode]);

  useEffect(() => {
    Promise.all([
      Font.load({ fontFamily: 'Rubik', fontWeight: 900 }),
      Font.load({ fontFamily: 'Rubik', fontWeight: 700 })
    ]).finally(() => {
      setIsFontLoaded(true);
    });
  }, []);

  return (
    !isEmpty(qrCodeBinary) &&
    isFontLoaded && (
      <div className='qr-code'>
        <img alt='QR code' src={qrCodeBinary} />
        <div className='qr-code--download gs--padding-left__sp1 gs--font-family-sf'>
          {buttons.map(item => (
            <div
              key={`${item.type}-${item.name}`}
              aria-hidden='true'
              className='qr-code--download--button'
              onClick={() => handleDownloadQRCode({ qrCode, qrOptions, button: item })}
            >
              <div className='gs--body-short-01 gs--text-01 gs--padding-right__sp2 gs--font-family-sf'>
                {isEmpty(item.name) ? QR_DOWNLOAD_TYPE[item.type]?.name : item.name}
              </div>
              <Download16 />
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default QRCodeComponent;
