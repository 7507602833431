import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { UnityRepository } from './unity.repository';
import { DateUtils } from '@gofan/utils';
import {
  BROADCAST_STATUS,
  CAMERA_STATUS,
  DATE_FORMAT_DEFAULT,
  DATE_FORMAT_DEFAULT_WITH_TIMEZONE,
  DATE_PICKER_FORMAT_DEFAULT,
  DATE_TIME_PICKER_FORMAT_DEFAULT,
  FULLTIME_FORMAT,
  FULL_DATE_TIME_FORMAT,
  GENDER,
  PARTNER_GENDER_MAPPING,
  PARTNER_LEVELS_MAPPING,
  TIME_FORMAT_DEFAULT,
  VIEW_GAME_STATUS
} from '@gofan/constants';

import type { UseLiveViewPerHourOptions } from './useLiveViewPerHour';
import type {
  GameDTO,
  LiveViewsPerHour,
  LiveViewsPerHourData,
  NFHSSchoolDTO,
  Pixellot,
  CamerasByAccount,
  PixellotTeamMapping,
  SearchGame,
  SearchLiveViewsPerHour,
  SearchNFHSSchool,
  SearchVideoImpressions,
  TotalViewsUniqueUsersData,
  EventIntegrationDetails
} from './unity.model';
import { StreamingEventDTO } from './streaming-event.model';
import type { EventDTO } from '../events';
import type { UseVideoImpressionsOptions } from './useVideoImpressions';
import type { StreamingSettingsFormValues } from './streaming-event.schema';

dayjs.extend(utc);
dayjs.extend(timezone);

export class UnityService {
  static searchNFHSSchool = (params: SearchNFHSSchool) => UnityRepository.searchNFHSSchool(params);

  static searchGame = (params: SearchGame) => UnityRepository.searchGame(params);

  static isStreamingSchool = (school: NFHSSchoolDTO) => school?.is_network;

  static getCamerasInfo = (school: NFHSSchoolDTO): CamerasByAccount => {
    const liveCameras: Pixellot[] = [];
    const sleepCameras: Pixellot[] = [];
    const onlineCameras: Pixellot[] = [];
    const offlineCameras: Pixellot[] = [];

    school?.pixellots?.forEach(pixellot => {
      if (pixellot?.last_status === CAMERA_STATUS.LIVE) {
        liveCameras.push(pixellot);
      } else if (pixellot?.last_status === CAMERA_STATUS.ONLINE) {
        onlineCameras.push(pixellot);
      } else if (pixellot?.last_status === CAMERA_STATUS.SLEEP) {
        sleepCameras.push(pixellot);
      } else {
        offlineCameras.push(pixellot);
      }
    });

    return {
      accountId: school?.gofan_id,
      liveCounter: liveCameras.length,
      sleepCounter: sleepCameras.length,
      onlineCounter: onlineCameras.length,
      offlineCounter: offlineCameras.length,
      liveCameras,
      sleepCameras,
      onlineCameras,
      offlineCameras
    };
  };

  static getPixellotTeamMapping = (
    pixellotTeamMappings: PixellotTeamMapping[],
    team: { sportName: string; gender: string; levelName: string }
  ) =>
    pixellotTeamMappings.find(mapping => {
      const levelKey = team.levelName.replace(/\s+/g, '_').toLowerCase();
      return mapping.sport === team.sportName && mapping.gender === team.gender && get(mapping, levelKey, false);
    });

  static getSelectedPixellot = ({
    event,
    games,
    nfhsSchool
  }: {
    event?: EventDTO;
    games: GameDTO[];
    nfhsSchool: NFHSSchoolDTO;
  }) => {
    const assignedPixellots = games?.map(game => game.publishers?.[0]?.broadcasts?.[0]?.pixellot_key);

    const mappedPixellots = uniqBy(
      nfhsSchool?.pixellots?.filter(item => assignedPixellots.includes(item.key)),
      'key'
    );

    return nfhsSchool?.pixellots?.find(item => item.key === event?.pixellotKey) ?? mappedPixellots?.[0];
  };

  static getTeamByGame = (game: GameDTO) => `${game.sport} ${game.gender} ${game.level}`;

  static getStreamingTeamName = (game: GameDTO) => `${game.gender}, ${game.level} ${game.sport}`;

  static searchLiveViewsPerHour = (params: SearchLiveViewsPerHour) => UnityRepository.searchLiveViewsPerHour(params);

  static searchVideoImpressions = (params: SearchVideoImpressions) => UnityRepository.searchVideoImpressions(params);

  static mappingLiveViewsPerHour = (data: LiveViewsPerHour[], timeZone: string): LiveViewsPerHour[] =>
    data.map(item => {
      const date = DateUtils.formatDateTime({
        date: dayjs(item.as_of).utc(true).format(),
        timeZone,
        parseZone: true
      }).toDateTime(DATE_FORMAT_DEFAULT, FULLTIME_FORMAT);
      return {
        ...item,
        as_of: date
      };
    });

  static getGameStartTimeFilter = (date: dayjs.Dayjs, startOfStartTime = false) => {
    if (date.isValid()) {
      if (startOfStartTime) {
        return date.utc().startOf('hours').format(FULL_DATE_TIME_FORMAT);
      }
      return date.utc().format(FULL_DATE_TIME_FORMAT);
    }
    return '';
  };

  static getLiveFilter = (game: GameDTO, startOfStartTime = false) => {
    const mLiveStartTime = dayjs(game?.publishers?.[0]?.broadcasts?.[0]?.start_time);
    const liveStartTime = this.getGameStartTimeFilter(mLiveStartTime, startOfStartTime);
    const liveDuration = game?.publishers?.[0]?.broadcasts?.[0]?.duration;
    const liveKey = game?.key;
    const enabled = !!liveKey && !isEmpty(liveStartTime) && liveDuration > 0;
    return { eventId: liveKey, eventStartTime: liveStartTime, eventDuration: liveDuration, enabled };
  };

  static getVodFilter = (game: GameDTO, startOfStartTime = false) => {
    const mLiveStartTime = dayjs(game?.publishers?.[0]?.broadcasts?.[0]?.start_time);
    const liveDuration = game?.publishers?.[0]?.broadcasts?.[0]?.duration;
    const mVodStartTime = mLiveStartTime.isValid()
      ? mLiveStartTime.add(liveDuration, 'hours').add(1, 'second')
      : mLiveStartTime;
    const vodStartTime = this.getGameStartTimeFilter(mVodStartTime, startOfStartTime);
    const vodKey = game?.key;
    const enabled = !!vodKey && !isEmpty(vodStartTime);
    return { eventId: vodKey, eventStartTime: vodStartTime, enabled };
  };

  static isLiveEnd = (game: GameDTO) => {
    const mLiveStartTime = dayjs(game?.publishers?.[0]?.broadcasts?.[0]?.start_time);
    const duration = game?.publishers?.[0]?.broadcasts?.[0]?.duration;
    const mLiveEndTime = mLiveStartTime.add(duration, 'hours');
    return mLiveEndTime.isBefore(dayjs());
  };

  static getLiveViewsPerHourChartOptions = ({
    games,
    timeZone,
    streamingViewerShipFlag = false
  }: {
    games: GameDTO[];
    timeZone: string;
    streamingViewerShipFlag?: boolean;
  }): UseLiveViewPerHourOptions<LiveViewsPerHourData>[] =>
    games.map(game => {
      const { eventId, eventStartTime, eventDuration, enabled } = this.getLiveFilter(game, true);
      return {
        search: { event_id: eventId, event_start_time: eventStartTime, event_duration: eventDuration },
        queryOptions: {
          enabled: streamingViewerShipFlag && enabled,
          refetchOnWindowFocus: false,
          initialData: { game, data: [] },
          select: res => ({ game, data: this.mappingLiveViewsPerHour(res?.data ?? [], timeZone) })
        }
      };
    });

  static getLiveTotalViewsUniqueUsersChartOptions = ({
    games,
    streamingViewerShipFlag = false
  }: {
    games: GameDTO[];
    streamingViewerShipFlag: boolean;
  }): UseVideoImpressionsOptions<TotalViewsUniqueUsersData>[] =>
    games.map(game => {
      const { eventId, eventStartTime, eventDuration, enabled } = UnityService.getLiveFilter(game);
      return {
        search: { event_id: eventId, event_start_time: eventStartTime, event_duration: eventDuration },
        queryOptions: {
          enabled: streamingViewerShipFlag && enabled,
          refetchOnWindowFocus: false,
          initialData: { game, videoImpressions: [] },
          select: res => ({
            game,
            videoImpressions: res?.data ?? []
          })
        }
      };
    });

  static getVodTotalViewsUniqueUsersChartOptions = ({
    games,
    streamingViewerShipFlag = false
  }: {
    games: GameDTO[];
    streamingViewerShipFlag: boolean;
  }): UseVideoImpressionsOptions<TotalViewsUniqueUsersData>[] =>
    games.map(game => {
      const { eventId, eventStartTime, enabled } = UnityService.getVodFilter(game);
      return {
        search: { event_id: eventId, event_start_time: eventStartTime },
        queryOptions: {
          enabled: streamingViewerShipFlag && enabled,
          refetchOnWindowFocus: false,
          initialData: { game, videoImpressions: [] },
          select: res => ({
            game,
            videoImpressions: res?.data ?? []
          })
        }
      };
    });

  static hasPixellotMapping = ({
    gender,
    levelName,
    pixellotTeamMapping
  }: {
    gender: string;
    levelName: string;
    pixellotTeamMapping: PixellotTeamMapping;
  }) => {
    if (gender !== pixellotTeamMapping.gender) return false;

    if (levelName === 'Varsity' && pixellotTeamMapping?.varsity) return true;
    if (levelName === 'Junior Varsity' && pixellotTeamMapping?.junior_varsity) return true;
    if (levelName === 'Freshman' && pixellotTeamMapping?.freshman) return true;
    if (levelName === 'Sophomore' && pixellotTeamMapping?.sophomore) return true;
    if (levelName === 'Middle School' && pixellotTeamMapping?.middle_school) return true;

    return false;
  };

  static getEditableGame = (game: GameDTO) => {
    const status = game?.publishers?.[0]?.broadcasts?.[0]?.status;
    const now = dayjs();
    const mGameStartTime = dayjs(game?.publishers?.[0]?.broadcasts?.[0]?.start_time);
    const isGameEnd = UnityService.isLiveEnd(game);

    return {
      editableStartTime:
        ![BROADCAST_STATUS.ON_AIR, BROADCAST_STATUS.OFF_AIR, BROADCAST_STATUS.COMPLETE].includes(status) &&
        now.clone().add(10, 'minutes').isBefore(mGameStartTime) &&
        !isGameEnd,
      editableEndTime: ![BROADCAST_STATUS.OFF_AIR, BROADCAST_STATUS.COMPLETE].includes(status) && !isGameEnd,
      editableVod:
        [BROADCAST_STATUS.OFF_AIR, BROADCAST_STATUS.COMPLETE].includes(status) ||
        now.clone().isBefore(mGameStartTime) ||
        isGameEnd,
      editable:
        ![BROADCAST_STATUS.ON_AIR, BROADCAST_STATUS.OFF_AIR, BROADCAST_STATUS.COMPLETE].includes(status) &&
        now.clone().isBefore(mGameStartTime) &&
        !isGameEnd
    };
  };

  static getPartnerGenderMapping = (gender: string) => {
    if (gender === GENDER.BOYS) return PARTNER_GENDER_MAPPING.BOYS;
    if (gender === GENDER.GIRLS) return PARTNER_GENDER_MAPPING.GIRLS;
    return PARTNER_GENDER_MAPPING.COED;
  };

  static getSearchGameParams = (event: EventDTO) => {
    let searchGameParams: SearchGame = {
      internal: true,
      is_pixellot: true
    };

    const eventIntegrationDetails = event?.eventIntegrationDetails ?? [];

    if (eventIntegrationDetails.length > 0) {
      const gameKeys: string[] = [];
      const partnerGameIds: string[] = [];

      eventIntegrationDetails.forEach((item: EventIntegrationDetails) => {
        if (item.gameKey) gameKeys.push(item.gameKey);
        else {
          const level = event?.levels?.find((level: any) => level.levelName === item.level);
          const levelId = level?.levelId ?? 0;
          const genderIndex = this.getPartnerGenderMapping(item.gender);
          partnerGameIds.push(`${event?.id}_${genderIndex}_${levelId}`);
        }
      });

      if (gameKeys.length > 0) searchGameParams.key = gameKeys;
      if (partnerGameIds.length > 0) searchGameParams.partner_game_id = partnerGameIds;
    } else if (event?.partnerId) {
      searchGameParams = { ...searchGameParams, partner_game_id: event.partnerId, partner_name: event?.partnerName };
    } else if ((event?.levels ?? []).length > 0) {
      const games: string[] = [];
      event?.levels?.forEach((level: any) => {
        if (isEmpty(level.genders)) {
          const genderIndex = this.getPartnerGenderMapping(GENDER.COED);
          games.push(`${event?.id}_${genderIndex}_${level?.levelId}`);
        } else {
          level.genders?.forEach((gender: string) => {
            const genderIndex = this.getPartnerGenderMapping(gender);
            games.push(`${event?.id}_${genderIndex}_${level?.levelId}`);
          });
        }
      });
      searchGameParams = { ...searchGameParams, partner_game_id: games };
    } else {
      searchGameParams = { ...searchGameParams, gofan_event_id: event?.id };
    }

    return searchGameParams;
  };

  static getStartNowDateTime = (game: GameDTO, timeZone: string) => {
    const streamingEvent = new StreamingEventDTO(game).toJSON();
    const tz = DateUtils.getTimeZone(timeZone);
    const startNow = dayjs().add(7, 'minutes').format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE);
    const formattedStartNowDateTime = DateUtils.formatDateTime({
      date: startNow,
      timeZone: tz,
      parseZone: true
    });
    const startNowDateTimeString = DateUtils.switchZone(
      formattedStartNowDateTime.toDate(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
      'localZone'
    );
    const mStartNowDateTime = dayjs(startNowDateTimeString);
    const startNowDateTime = mStartNowDateTime.format(DATE_TIME_PICKER_FORMAT_DEFAULT);
    const startNowDate = mStartNowDateTime.format(DATE_PICKER_FORMAT_DEFAULT);
    const startNowTime = mStartNowDateTime.format(TIME_FORMAT_DEFAULT);

    const mGameEndDateTime = mStartNowDateTime.clone().add(streamingEvent.broadcastDuration, 'hours');
    const gameEndDateTime = mGameEndDateTime.format(DATE_TIME_PICKER_FORMAT_DEFAULT);
    const gameEndDate = mGameEndDateTime.format(DATE_PICKER_FORMAT_DEFAULT);
    const gameEndTime = mGameEndDateTime.format(TIME_FORMAT_DEFAULT);

    return {
      startNowDateTime,
      startNowDate,
      startNowTime,
      gameEndDateTime,
      gameEndDate,
      gameEndTime
    };
  };

  static mappingStartNowStreamingEvent = ({
    values,
    event,
    game
  }: {
    values: StreamingSettingsFormValues;
    event: EventDTO;
    game: GameDTO;
  }): Partial<EventDTO> => {
    const timeZone = DateUtils.getTimeZone(event?.timeZone);
    const { startNowDateTime, startNowTime, gameEndTime } = this.getStartNowDateTime(game, timeZone);
    const mStartNow = dayjs(startNowDateTime);
    const additionalFields = {
      startDateTime: mStartNow.format(DATE_TIME_PICKER_FORMAT_DEFAULT),
      allDayEvent: event.allDayEvent
    };

    return {
      ...values,
      eventIntegrationDetails: values?.eventIntegrationDetails?.map(
        item =>
          (item.gender === game.gender && item.level === game.level
            ? { ...item, broadcastStartTime: startNowTime, broadcastEndTime: gameEndTime }
            : item) as EventIntegrationDetails
      ),
      ...additionalFields
    };
  };

  static filterGamesByEvent = (games: GameDTO[], event: EventDTO) => {
    const searchGameParams = this.getSearchGameParams(event);
    const keys = compact((searchGameParams?.key ?? []) as string[]);
    const partnerGameIds = compact((searchGameParams?.partner_game_id ?? []) as string[]);
    return games.filter(game => keys.includes(game.key) || partnerGameIds.includes(game.partner_game_id));
  };

  static getViewGameStatus = (game: GameDTO) => {
    const streamingEvent = new StreamingEventDTO(game).toJSON();
    const mGameStartTime = dayjs(streamingEvent.broadcastStartTime);
    const mGameEndTime = dayjs(streamingEvent.broadcastEndTime);
    const now = dayjs();

    if (now.isBefore(mGameStartTime)) {
      return VIEW_GAME_STATUS.PREV;
    }

    if (now.isAfter(mGameEndTime)) {
      return VIEW_GAME_STATUS.POST;
    }

    return VIEW_GAME_STATUS.DURING;
  };

  static completeGame = (key: string) => UnityRepository.completeGame(key);

  static getPartnerLevel = (level = ''): string => PARTNER_LEVELS_MAPPING[level.toLowerCase()] ?? level ?? '';

  static comparePartnerTeam = (eventIntegrationDetail: EventIntegrationDetails, game: GameDTO) =>
    `${eventIntegrationDetail.gender ?? ''}`.toLowerCase() === `${game.gender ?? ''}`.toLowerCase() &&
    `${this.getPartnerLevel(eventIntegrationDetail.level)}`.toLowerCase() ===
      `${this.getPartnerLevel(game.level)}`.toLowerCase();

  static searchPixellotsByPublisherKey = (publisherKey: string) =>
    UnityRepository.searchPixellotsByPublisherKey(publisherKey);

  static searchAndMappingNFHSSchool = async (params: SearchNFHSSchool) => {
    try {
      const response = await UnityService.searchNFHSSchool(params);
      const school = response.items?.[0] ?? ({} as NFHSSchoolDTO);
      if (isEmpty(school)) return Promise.resolve(school);

      const { pixellots = [] } = await UnityService.searchPixellotsByPublisherKey(school?.publisher_key ?? '').catch(
        () => ({ pixellots: [] })
      );

      return Promise.resolve({
        ...school,
        pixellots: (school?.pixellots ?? [])?.map(item => {
          const pixellot = pixellots.find(pixellot => pixellot.key === item.key);
          return { ...item, last_status: pixellot?.status ?? item.last_status };
        })
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
