import React from 'react';
import { TableRow, TableHead, TableHeader as CarbonTableHeader, TableSelectAll } from 'carbon-components-react';
import { getSortableProps, getNextSortDirection } from '../../sorting';

function TableHeader({
  sortBy,
  headers,
  hasSorting,
  getHeaderProps,
  hasOverflowMenu,
  onHeaderClick,
  onSelectAll,
  selectedRowIds,
  selectableRowCount,
  getSelectionProps,
  haveCheckbox
}) {
  const selectionProps = getSelectionProps();
  const customizedSelectionProps = {
    ...selectionProps,
    indeterminate: selectedRowIds.length > 0 && selectedRowIds.length < selectableRowCount,
    checked: selectedRowIds.length === selectableRowCount && selectableRowCount > 0,
    disabled: selectableRowCount === 0,
    onSelect: e => {
      e.stopPropagation();
      onSelectAll?.();
      selectionProps.onSelect(e);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {haveCheckbox ? <TableSelectAll {...customizedSelectionProps} /> : null}
        {headers.map(item => {
          const isSortable = hasSorting && Boolean(item.isSortable);
          const sortableProps = getSortableProps({
            header: item.key,
            sortBy,
            isSortable
          });
          const textAlign = `align-${item.textAlign || 'left'}`;
          return (
            <CarbonTableHeader
              key={item.key}
              {...getHeaderProps({
                header: item,
                isSortable,
                style: item.headerStyle,
                className: `gs--productive-heading-01 gs--text-01 gs--table-header ${textAlign}`,
                onClick: (e, { sortHeaderKey }) => {
                  const sortDirection = getNextSortDirection({
                    header: sortHeaderKey,
                    sortBy
                  });
                  onHeaderClick({ sortHeaderKey, sortDirection });
                }
              })}
              {...sortableProps}
            >
              {item.header}
            </CarbonTableHeader>
          );
        })}
        {hasOverflowMenu ? <CarbonTableHeader /> : null}
      </TableRow>
    </TableHead>
  );
}

TableHeader.defaultProps = {
  sortBy: {},
  headers: [],
  hasSorting: false,
  hasOverflowMenu: false,
  onHeaderClick: () => null,
  getSelectionProps: () => null,
  getHeaderProps: () => null
};

export default TableHeader;
