import BaseModel from '../BaseModel';

class UserHistoryRequest extends BaseModel {
  constructor({ email, accountId, dateRange }) {
    super({ email, accountId, dateRange });
  }

  get accountId() {
    return this.raw.accountId || undefined;
  }

  get email() {
    return this.raw.email || undefined;
  }

  get dateRange() {
    return this.raw.dateRange || undefined;
  }

  toJSON() {
    return {
      email: this.email,
      accountId: this.accountId,
      dateRange: this.dateRange
    };
  }
}

export default UserHistoryRequest;
