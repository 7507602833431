import EventScheduleRepository from '@event-integrations/repositories/event-schedule.repository';
import {
  EventScheduleSearchDTO,
  EventScheduleSeasonDTO,
  EventScheduleDTO,
  EventIgnoreDTO,
  EventSchedulePartnerInsightDTO,
  EventPartnerSalesInfoDTO
} from '@event-integrations/models/event-schedule.model';
import { GlobalEventDTO } from '@seasons/models/season.model';

class EventScheduleService {
  searchEventSchedulesByParams = (searchParams: EventScheduleSearchDTO): Promise<EventScheduleSeasonDTO[]> =>
    EventScheduleRepository.searchEventByParams(searchParams);

  getEventMonitorIndicator = (
    eventScheduleSearch: EventScheduleSearchDTO,
    manualErrorHandling = true
  ): Promise<boolean> => EventScheduleRepository.getEventMonitorIndicator(eventScheduleSearch, manualErrorHandling);

  getEventScheduleSeason = (
    eventScheduleSearch: Partial<EventScheduleSearchDTO>,
    manualErrorHandling = true
  ): Promise<EventScheduleDTO[]> =>
    EventScheduleRepository.getEventScheduleSeason(eventScheduleSearch, manualErrorHandling);

  updateGlobalEvents = (payload: Partial<GlobalEventDTO>[]) =>
    Promise.all(payload.map(event => EventScheduleRepository.updateGlobalEvent(event.id!, event)));

  updateGlobalEvent = (payload: GlobalEventStatus) => EventScheduleRepository.updateGlobalEvent(payload.id, payload);

  getGlobalEventPartnerInsight = (searchParams: EventScheduleSearchDTO, manualErrorHandling = true) =>
    searchParams?.schoolIds?.length === 0
      ? Promise.resolve([] as EventSchedulePartnerInsightDTO[])
      : EventScheduleRepository.getGlobalEventPartnerInsight(searchParams, manualErrorHandling);

  getEventPartnerSalesInfo = (searchParams: EventScheduleSearchDTO, manualErrorHandling = true) =>
    searchParams?.schoolIds?.length === 0
      ? Promise.resolve([] as EventPartnerSalesInfoDTO[])
      : EventScheduleRepository.getEventPartnerSalesInfo(searchParams, manualErrorHandling);

  ignoreSchedule = (payload: EventIgnoreDTO, manualErrorHandling = true) =>
    EventScheduleRepository.ignoreSchedule(payload, manualErrorHandling);
}

export default new EventScheduleService();
