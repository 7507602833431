import { SkeletonText, SkeletonPlaceholder } from 'carbon-components-react';

import headerLogo from '@assets/images/logo.svg';
import styled from 'styled-components';

export default function LoadingContent() {
  return (
    <>
      <Header>
        <Logo src={headerLogo} alt='GoFan HQ Logo' />
      </Header>

      <Main>
        <SkeletonText />
        <SkeletonPlaceholder style={{ width: '100%' }} />
      </Main>
    </>
  );
}

const Header = styled.header`
  display: flex;
  align-items: center;
  background-color: black;
  height: 50px;
  padding: 10px 0;
  justify-content: flex-end;
`;

const Logo = styled.img`
  margin-right: 184px;
  height: 30px;
  width: 135px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Main = styled.main`
  min-height: calc(100vh - 95px);
  padding: 0px 56px;
  padding-top: 3rem;
  width: 100%;
`;
