import { css } from 'styled-components';

export default {
  labelContainer: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    .bx--tooltip__label:focus {
      outline: none;
    }
  `
};
