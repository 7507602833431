import React, { useCallback, useRef, useEffect, useMemo, useReducer } from 'react';
import { Column, MultiSelect, Row, SkeletonPlaceholder, SkeletonText, Link } from 'carbon-components-react';
import { isFunction, xor } from 'lodash';
import { isEmpty } from '@utils/objectUtils';
import InsightTicketUsedByEvent from '@events/components/insight-ticket-used-by-event/insight-ticket-used-by-event';
import { useEventInsightContext } from '@events/components/event-insight/event-insight.context';
import { mappingSaleAllotment, mappingTicketUsedByEvent } from '@events/components/event-insight/event-insight.utils';
import type { TicketSalesType } from '@app/modules/events/components/ticket-sale-chart/useEventInsight';
import useEventInsight from '@app/modules/events/components/ticket-sale-chart/useEventInsight';
import TicketSaleChart from '@events/components/ticket-sale-chart/ticket-sale-chart';
import { MAX_CAPACITY } from '@events/constants';
import { Close16 } from '@carbon/icons-react';
import type EventDTO from '@app/api/dto/EventDTO';
import InsightSaleOfAllotments from '../insight-sale-of-allotments/insight-sale-of-allotments.component';
import '@carbon/charts/styles.css';
import EventsService from '@events/services/events.service';

type EventInsightProps = {
  event: EventDTO;
  onCloseForm: Function;
};

type MetricsType = {
  id: string;
  text: string;
};

const metrics: MetricsType[] = [
  {
    id: 'ticket-sales',
    text: ' Ticket sales by type'
  },
  {
    id: 'ticket-usued',
    text: ' Tickets used'
  },
  {
    id: 'ticket-remaining',
    text: ' Tickets remaining'
  }
];

const reducer = (state: any, action: any) => {
  const { items = [], saved = [] } = action.payload;
  switch (action.type) {
    case 'INIT': {
      return {
        ...state,
        metricsItems: items,
        loading: false,
        selectedItems: !isEmpty(saved) ? saved : items
      };
    }
    case 'UPDATE_METRICS_ITEMS': {
      return {
        ...state,
        selectedItems: items
      };
    }
    default: {
      return state;
    }
  }
};

const initEventInsight = () => ({
  metricsItems: metrics,
  selectedItems: metrics,
  loading: true
});

const EventInsight = ({ onCloseForm, event }: EventInsightProps) => {
  const { handleSaveMetrics, savedMetrics, reset } = useEventInsightContext();
  const { data, loading, fetchData } = useEventInsight(event);
  const initRef = useRef(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const loadingRef = useRef<any>();
  const chartTicketUsedConfig = useMemo(() => mappingTicketUsedByEvent(data?.totals), [data]);
  const isFundraiser = useMemo(() => EventsService.isFundraiser(event), [event]);
  const chartSaleAllotmentConfig = useMemo(
    () => mappingSaleAllotment(data?.totals, data?.totalTicketAvailableByTypes || 0),
    [data]
  );
  const shouldShowLoadingDonut = (chartName: string) => loadingRef?.current?.id === chartName;
  const [metricState, dispatch] = useReducer(reducer, {}, initEventInsight);
  const onChangeMultiSelect = (e: any) => {
    loadingRef.current = xor(metricState.selectedItems, e.selectedItems)[0] || {};
    fetchData();
    dispatch({ type: 'UPDATE_METRICS_ITEMS', payload: { items: e.selectedItems } });
    handleSaveMetrics({ saved: e.selectedItems, savedEventId: event.id });
  };

  // Handle click outSide
  const handleClickOutside = useCallback(evt => {
    if (!document) return;
    const multiSelectEle = document.getElementById('multiSelect-metrics');
    const insightEle = document.getElementById('event-insight');
    let targetElement = evt.target; // clicked element
    do {
      // clicked inside
      if (!document.contains(targetElement) || targetElement === multiSelectEle || targetElement === insightEle) return;
      targetElement = targetElement.parentNode;
    } while (targetElement);
    // clicked outside
    if (isFunction(onCloseForm)) onCloseForm();
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    const items: any = [];
    const ticketSale: TicketSalesType | undefined = data;
    let saved = [];

    if (!loading && !initRef.current) {
      const shouldShowTicketUsedByEventChart =
        data?.totals?.ticket_net_count > 0 || data?.totals?.ticket_redemption_count > 0;
      const shouldShowTicketRemainingChart =
        (data?.totalTicketAvailableByTypes || 0) > 0 && (data?.totalTicketAvailableByTypes || 0) < MAX_CAPACITY;
      const shouldShowTicketSalesByType =
        ticketSale && (!isEmpty(ticketSale.schoolTickets) || !isEmpty(ticketSale.eventTickets));

      if (shouldShowTicketSalesByType || data?.showEmptyData) {
        items.push(metrics.find(metric => `${metric.id}` === 'ticket-sales'));
      }
      if (!isFundraiser && (shouldShowTicketUsedByEventChart || data?.showEmptyData)) {
        items.push(metrics.find(metric => `${metric.id}` === 'ticket-usued'));
      }
      if (
        !isFundraiser &&
        (shouldShowTicketRemainingChart ||
          (data?.showEmptyData && event?.maxCapacity && event?.maxCapacity !== MAX_CAPACITY))
      ) {
        items.push(metrics.find(metric => `${metric.id}` === 'ticket-remaining'));
      }
      if (!isEmpty(savedMetrics)) {
        const { savedEventId = '' } = savedMetrics;
        if (`${savedEventId}` === `${event.id}`) {
          saved = savedMetrics?.saved;
        } else {
          reset();
        }
      }
      dispatch({
        type: 'INIT',
        payload: { items, saved }
      });
      initRef.current = true;
    }
  }, [loading, data, initRef.current]);

  return (
    <Row>
      <Column
        id='event-insight'
        className='event-insight__container bx--col-padding'
        style={{ top: 0, right: '-15px' }}
      >
        <div className='content-container' ref={wrapperRef}>
          <div className='event-filter__overlay' aria-hidden='true' onClick={onCloseForm} />
          <div className='container'>
            <div className='header-container gs--padding-bottom__sp3'>
              <div className='gs--text-01 header-text'>
                <div className='gs--productive-heading-03-semibold'>Event insight</div>

                <Link
                  href='https://gofanschool.zendesk.com/hc/en-us/articles/7428329792397'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Learn more
                </Link>
              </div>

              <Close16
                onClick={() => {
                  onCloseForm();
                }}
              />
            </div>

            <div className='event-insight__title-divider' />

            {metricState.loading && (
              <div className='gs--padding-top__sp4'>
                <SkeletonText />
                <SkeletonPlaceholder style={{ width: '100%' }} />
              </div>
            )}
            {!metricState.loading && (
              <>
                {!isFundraiser && (
                  <div className='gs--padding-top__sp4'>
                    <MultiSelect
                      id='multiSelect-metrics'
                      initialSelectedItems={metricState?.selectedItems}
                      itemToString={(item: MetricsType) => item?.text || ''}
                      items={metricState?.metricsItems}
                      label='metrics selected'
                      light={false}
                      onChange={onChangeMultiSelect}
                      sortItems={items => items}
                      selectionFeedback='fixed'
                      titleText='Choose metrics to show'
                    />
                  </div>
                )}
                {isEmpty(metricState?.selectedItems) && (
                  <div className='gs--padding-top__sp3 gs--body-short-01 gs--text-01 event-insight__no-data'>
                    No data
                  </div>
                )}
                <div className='insight__container'>
                  {metricState?.selectedItems?.some((metric: MetricsType) => metric?.id === 'ticket-sales') && (
                    <div>
                      <TicketSaleChart
                        isFundraiser={isFundraiser}
                        data={data}
                        loading={loading && shouldShowLoadingDonut('ticket-sales')}
                      />
                      {!isFundraiser && <div className='event-insight__divider' />}
                    </div>
                  )}
                  {metricState?.selectedItems?.some((metric: any) => metric?.id === 'ticket-usued') && (
                    <div>
                      <InsightTicketUsedByEvent
                        data={data}
                        chartConfig={chartTicketUsedConfig}
                        tickets={data?.totals}
                        loading={loading && shouldShowLoadingDonut('ticket-usued')}
                      />
                      <div className='event-insight__divider' />
                    </div>
                  )}
                  {metricState?.selectedItems?.some((metric: MetricsType) => metric?.id === 'ticket-remaining') && (
                    <div>
                      <InsightSaleOfAllotments
                        event={event}
                        data={data}
                        chartConfig={chartSaleAllotmentConfig}
                        tickets={data?.totals}
                        loading={loading && shouldShowLoadingDonut('ticket-remaining')}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Column>
    </Row>
  );
};

export default EventInsight;
