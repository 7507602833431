import type { ProgressDataType } from '@common/generic-components/custom-progress/custom-progress-indicator.component';

export const SEASON_TICKET_STEPS: ProgressDataType = {
  seasonTicketSettings: {
    index: 0,
    label: 'Step 1',
    description: 'Season ticket settings',
    secondaryLabel: 'Season ticket settings'
  },
  optionalTicketSettings: {
    index: 1,
    label: 'Step 2',
    description: 'Optional ticket settings',
    secondaryLabel: 'Optional ticket settings'
  }
};

export const EVENT_TICKET_STEPS: ProgressDataType = {
  seasonTicketSettings: {
    index: 0,
    label: 'Step 1',
    description: 'Ticket settings',
    secondaryLabel: 'Ticket settings'
  },
  optionalTicketSettings: {
    index: 1,
    label: 'Step 2',
    description: 'Optional ticket settings',
    secondaryLabel: 'Optional ticket settings'
  }
};

export const TRIGGER_FIELDS: any = {
  SEASON_TICKET: {
    [EVENT_TICKET_STEPS.seasonTicketSettings.index]: ['name', 'price'],
    [EVENT_TICKET_STEPS.optionalTicketSettings.index]: [
      'salesStartDate',
      'salesStartTime',
      'salesEndDate',
      'salesEndTime',
      'fee',
      'ticketLimitPerOrder'
    ]
  },
  TICKET_TYPE: {
    [EVENT_TICKET_STEPS.seasonTicketSettings.index]: ['ticketName', 'ticketPrice', 'limit', 'boxOfficeTicket'],
    [EVENT_TICKET_STEPS.optionalTicketSettings.index]: [
      'salesStartDate',
      'salesStartTime',
      'salesEndDate',
      'salesEndTime',
      'fee',
      'ticketLimitPerOrder'
    ]
  }
};
