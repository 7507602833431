import type { BulkPromotionInfoDTO } from './types';

export const ADD_BULK_PROMOTION_INFO = '@promotions/ADD_BULK_PROMOTION_INFO';
export const REMOVE_BULK_PROMOTION_INFO = '@promotions/REMOVE_BULK_PROMOTION_INFO';

interface AddBulkPromotionInfo {
  type: typeof ADD_BULK_PROMOTION_INFO;
  payload: {
    bulkPromotion: BulkPromotionInfoDTO;
  };
}

interface RemoveBulkPromotionInfo {
  type: typeof REMOVE_BULK_PROMOTION_INFO;
  payload: {
    eventId: string | number;
  };
}

export type PromotionsActionType = AddBulkPromotionInfo | RemoveBulkPromotionInfo;
