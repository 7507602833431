// @flow
import dayjs from 'dayjs';

import { DateUtils } from '@gofan/utils/date';
import {
  TIME_FORMAT_DEFAULT,
  DATE_PICKER_FORMAT_DEFAULT,
  DATE_FORMAT_DEFAULT_WITH_TIMEZONE
} from '@gofan/constants/date';

import { isEmpty } from '../../utils/objectUtils';

class Ticket {
  constructor(
    {
      restData,
      id,
      accountId,
      accountProductId,
      hiddenFees,
      distributionChannel,
      enabled,
      eventId,
      fee,
      formId,
      formFields,
      limit,
      name,
      packCount,
      price,
      productType,
      promotionLabel,
      promotionRequired,
      redemptionLimit,
      reservedSeating,
      salesEndDateTime,
      salesStartDateTime,
      ticketLimitPerOrder,
      seasonId,
      seatsIoCategory,
      generateLink,
      customColor,
      groupId,
      encodedString
    },
    productSalesInfor,
    timeZone
  ) {
    this.raw = {
      restData,
      id,
      accountId,
      accountProductId,
      hiddenFees,
      distributionChannel,
      enabled,
      eventId,
      fee,
      formId,
      formFields,
      limit,
      name,
      packCount,
      price,
      productType,
      promotionLabel,
      promotionRequired,
      redemptionLimit,
      reservedSeating,
      salesEndDateTime,
      salesStartDateTime,
      ticketLimitPerOrder,
      seasonId,
      seatsIoCategory,
      productSalesInfor,
      generateLink,
      customColor,
      groupId,
      encodedString,
      timeZone
    };
  }

  get restData() {
    return this.raw.restData;
  }

  get id() {
    return this.raw.id;
  }

  get accountId() {
    return this.raw.accountId;
  }

  get accountProductId() {
    return this.raw.accountProductId;
  }

  get hiddenFees() {
    return this.raw.hiddenFees;
  }

  get distributionChannel() {
    return this.raw.distributionChannel;
  }

  get enabled() {
    return this.raw.enabled;
  }

  get eventId() {
    return this.raw.eventId;
  }

  get fee() {
    return this.raw.fee;
  }

  get formId() {
    return this.raw.formId;
  }

  get formFields() {
    return this.raw.formFields;
  }

  get limit() {
    return this.raw.limit;
  }

  get name() {
    return this.raw.name;
  }

  get packCount() {
    return this.raw.packCount;
  }

  get price() {
    return this.raw.price;
  }

  get productType() {
    return this.raw.productType;
  }

  get promotionLabel() {
    return this.raw.promotionLabel;
  }

  get promotionRequired() {
    return this.raw.promotionRequired;
  }

  get redemptionLimit() {
    return this.raw.redemptionLimit;
  }

  get reservedSeating() {
    return this.raw.reservedSeating;
  }

  get salesEndDateTime() {
    return this.raw.salesEndDateTime;
  }

  get salesStartDateTime() {
    return this.raw.salesStartDateTime;
  }

  get ticketLimitPerOrder() {
    return this.raw.ticketLimitPerOrder;
  }

  get seasonId() {
    return this.raw.seasonId;
  }

  get seatsIoCategory() {
    return this.raw.seatsIoCategory;
  }

  get productSalesInfor() {
    return this.raw.productSalesInfor;
  }

  get isSold() {
    return this.productSalesInfor && this.productSalesInfor.saleQuantity > 0;
  }

  get saleQuantity() {
    return this.productSalesInfor ? this.productSalesInfor.saleQuantity : 0;
  }

  get generateLink() {
    return this.raw.generateLink;
  }

  get customColor() {
    return this.raw.customColor;
  }

  get groupId() {
    return this.raw.groupId;
  }

  get encodedString() {
    return this.raw.encodedString;
  }

  get timeZone() {
    return this.raw.timeZone;
  }

  toJSON() {
    let salesEndDate;
    let salesEndTime;

    let salesStartDate;
    let salesStartTime;

    if (this?.salesStartDateTime) {
      const mSalesStartDateTime = DateUtils.formatDateTime({
        date: this?.salesStartDateTime,
        timeZone: this?.timeZone,
        parseZone: true
      });
      const ticketSalesStartDateTime = DateUtils.switchZone(
        mSalesStartDateTime.toDate(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
        'localZone'
      );
      const mTicketSalesStartDateTime = dayjs(ticketSalesStartDateTime);

      salesStartTime = mTicketSalesStartDateTime.clone().format(TIME_FORMAT_DEFAULT);
      salesStartDate = mTicketSalesStartDateTime.clone().format(DATE_PICKER_FORMAT_DEFAULT);
    }

    if (this?.salesEndDateTime) {
      const mSalesEndDateTime = DateUtils.formatDateTime({
        date: this?.salesEndDateTime,
        timeZone: this?.timeZone,
        parseZone: true
      });
      const ticketSalesEndDateTime = DateUtils.switchZone(
        mSalesEndDateTime.toDate(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
        'localZone'
      );
      const mTicketSalesEndDateTime = dayjs(ticketSalesEndDateTime);

      salesEndTime = mTicketSalesEndDateTime.clone().format(TIME_FORMAT_DEFAULT);
      salesEndDate = mTicketSalesEndDateTime.clone().format(DATE_PICKER_FORMAT_DEFAULT);
    }

    return {
      restData: this.restData,
      id: this.id,
      accountId: this.accountId,
      accountProductId: this.accountProductId,
      hiddenFees: this.hiddenFees,
      distributionChannel: this.distributionChannel,
      enabled: this.enabled,
      eventId: this.eventId,
      fee: this.fee,
      formId: this.formId,
      formFields: this.formFields,
      limit: isEmpty(this.limit) ? undefined : this.limit,
      name: this.name,
      packCount: this.packCount,
      price: this.price,
      productType: this.productType,
      promotionLabel: this.promotionLabel,
      promotionRequired: this.promotionRequired,
      redemptionLimit: this.redemptionLimit,
      reservedSeating: this.reservedSeating,
      salesEndDate,
      salesEndTime,
      salesEndDateTime: this.salesEndDateTime,
      salesStartDate,
      salesStartTime,
      salesStartDateTime: this.salesStartDateTime,
      ticketLimitPerOrder: this.ticketLimitPerOrder,
      seasonId: this.seasonId,
      seatsIoCategory: this.seatsIoCategory,
      productSalesInfor: isEmpty(this.raw.productSalesInfor) ? null : this.raw.productSalesInfor.toJSON(),
      isSold: this.isSold,
      saleQuantity: this.saleQuantity,
      generateLink: this.generateLink,
      customColor: this.customColor,
      groupId: this.groupId,
      encodedString: isEmpty(this.encodedString) ? undefined : this.encodedString
    };
  }
}

export default Ticket;
