import React, { useContext, useEffect } from 'react';
import { FastFormContext } from './FastFormBase';
import FormSafeguard from './FormSafeguard';

export default ({ children, formKey, initFormValue, required = false }) => {
  const fastFormContext = useContext(FastFormContext);

  useEffect(() => {
    if (!fastFormContext.form[formKey]) {
      fastFormContext.updateField(formKey, {
        value: initFormValue,
        lastSavedValue: initFormValue,
        dirty: false,
        valid: true,
        touched: false,
        focused: false,
        inForm: false,
        errors: [],
        required
      });
    }
  }, []);

  const formValue = fastFormContext.form[formKey];

  return formValue && children ? (
    <FormSafeguard formKey={formKey}>{children}</FormSafeguard>
  ) : null;
};
