import { FanRepository } from './fan.repository';

import { DateUtils } from '@gofan/utils/date';
import { transformFieldValue, getContactName } from '@gofan/utils/fan';

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

import {
  DATE_FORMAT_DEFAULT_WITH_TIMEZONE,
  DATE_FORMAT_WITH_FULL_MONTH_OF_FAN,
  DATE_TIME_FORMAT,
  DATE_FORMAT
} from '@gofan/constants/date';
import { FIELD_IGNORE_LABEL, FIELD_LABEL_MAP, FIELD_ORDER } from '@gofan/constants/fan';
// eslint-disable-next-line import/no-extraneous-dependencies
import { sortStates } from 'carbon-components-react/lib/components/DataTable/state/sorting';

import type { AdditionalFormFields, AdditionalInfoField, FanTicketDTO, FanFormFieldDTO } from '..';
import type {
  PurchasedTicketRequestDTO,
  PurchasedTicketDTO,
  SearchPurchasedTicketParams,
  PurchasedTicketPageResponseDTO
} from '../purchased-ticket.model';
import type { AttendeeRowRecord } from './fan.model';

const sortToString = (sortBy: any) => {
  if (!isEmpty(sortBy)) {
    return encodeURI(`${sortBy.header},${sortBy.sortDirection === sortStates.DESC ? 'desc' : 'asc'}`);
  }
  return '';
};

class FanService {
  static fetchPurchasedTicket = (searchParams: SearchPurchasedTicketParams) => {
    const pageQuery = new URLSearchParams({
      ts: new Date().getTime().toString(),
      skipCache: 'true',
      page: searchParams.page.toString(),
      size: searchParams.pageSize.toString(),
      sort: sortToString(searchParams.sortBy)
    });

    return FanRepository.fetchPurchasedTicket(decodeURIComponent(pageQuery.toString()), searchParams.body);
  };

  static fetchPurchasedSeasonTicket = (searchParams: SearchPurchasedTicketParams) => {
    const pageQuery = new URLSearchParams({
      ts: new Date().getTime().toString(),
      skipCache: 'true',
      page: searchParams.page.toString(),
      size: searchParams.pageSize.toString(),
      sort: sortToString(searchParams.sortBy)
    });

    return FanRepository.fetchPurchasedSeasonTicket(decodeURIComponent(pageQuery.toString()), searchParams.body).then(
      res => {
        const content = (res.content ?? []).map(item => ({
          ...item,
          id: [item.seasonId, item.seasonPassId, item.orderId, item.createdBy].join('-')
        }));
        return { ...res, content } as PurchasedTicketPageResponseDTO;
      }
    );
  };

  static fetchAttendeeTickets = ({
    eventId,
    seasonId,
    purchasedTicket
  }: {
    eventId?: string | number;
    seasonId?: string | number;
    purchasedTicket: PurchasedTicketDTO | AttendeeRowRecord;
  }) => {
    let body: SearchPurchasedTicketParams['body'] = { seasonId, eventId };
    const { receiptEmail, userEmail } = purchasedTicket;
    if (receiptEmail) {
      body = {
        ...body,
        receiptEmail
      };
    } else {
      body = {
        ...body,
        userEmail
      };
    }

    const pageQuery = new URLSearchParams({
      ts: new Date().getTime().toString(),
      skipCache: 'true',
      page: '0',
      size: '200'
    });

    if (seasonId) {
      return FanRepository.fetchPurchasedSeasonTicket(decodeURIComponent(pageQuery.toString()), body);
    }

    return FanRepository.fetchPurchasedTicket(decodeURIComponent(pageQuery.toString()), body);
  };

  static updatePurchasedTicket = (
    request: PurchasedTicketRequestDTO,
    accessToken: string
  ): Promise<PurchasedTicketDTO> => FanRepository.updatePurchasedTicket(request, accessToken);

  static unredeemEvent = FanRepository.unredeemEvent;

  static getTicketTypesById = (eventId: string): Promise<string[]> => FanRepository.getTicketTypesById(eventId);

  static unUseTickets = (accessTokens: string[]) => FanRepository.unUseTickets(accessTokens);

  static useTicket = (accessToken: string, redeemedAt: string) => FanRepository.useTickets(accessToken, redeemedAt);

  static prepareFanTicketData = (
    fanTicket: PurchasedTicketDTO,
    timeZone: string,
    redemptionWindow: number
  ): FanTicketDTO => {
    const fields = (fanTicket?.forms ?? []).flatMap(form => form.fields);
    const dateTime = DateUtils.formatDateTime({
      parseZone: true,
      date: DateUtils.getDateTimeUTC(`${fanTicket.purchasedDate} ${fanTicket.purchasedTime}`),
      timeZone
    });

    const nextAvailableTime = moment(
      FanService.#getNextAvailableTime(fanTicket, redemptionWindow),
      DATE_FORMAT_DEFAULT_WITH_TIMEZONE
    );
    const isAvailableNextRedeem = nextAvailableTime.isValid()
      ? nextAvailableTime.isSameOrBefore(moment.now(), 'minutes')
      : false;

    return {
      firstName: FanService.getFormFieldValue(fields)('first-name'),
      lastName: FanService.getFormFieldValue(fields)('last-name'),
      phoneNumber: FanService.getFormFieldValue(fields)('phone-number'),
      userEmail: fanTicket.userEmail,
      receiptEmail: fanTicket.receiptEmail,
      orderType: fanTicket.orderType,
      email: fanTicket.receiptEmail || fanTicket.userEmail,
      purchasedDate: dateTime.toDate(DATE_FORMAT_WITH_FULL_MONTH_OF_FAN),
      purchasedTime: dateTime.toTime(),
      isRedeemed: fanTicket.redeemedAt !== null,
      redeemedAt: DateUtils.formatDateTime({
        parseZone: true,
        date: fanTicket.redeemedAt,
        timeZone
      }).toDateTimeWithAt(DATE_FORMAT_WITH_FULL_MONTH_OF_FAN),
      redemptionCount: fanTicket.redemptionCount,
      redemptionLimit: fanTicket.redemptionLimit,
      refundedAt: DateUtils.formatDateTime({
        parseZone: true,
        date: fanTicket.refundedAt,
        timeZone
      }).toDateTimeWithAt(DATE_FORMAT_WITH_FULL_MONTH_OF_FAN),
      productType: fanTicket.productType,
      price: fanTicket.price,
      customColor: fanTicket.customColor,
      promoCode: fanTicket.promoCode,
      id: fanTicket.id,
      orderId: fanTicket.orderId,
      seasonId: fanTicket.seasonId,
      accessToken: fanTicket.accessToken,
      notes: fanTicket.notes,
      forms: fanTicket.forms,
      isAvailableNextRedeem
    };
  };

  static getAdditionalFormFields = (fanTicket: FanTicketDTO): AdditionalFormFields => {
    let additionalFields: AdditionalInfoField[] = [];

    const { forms } = fanTicket;

    if (forms) {
      const transformField = (field: FanFormFieldDTO) => ({
        name: field.name,
        value: transformFieldValue(field.name, field.value),
        label: FanService.#getFormFieldLabel(field.name),
        text: `${FIELD_LABEL_MAP[field.name] || ''}${transformFieldValue(field.name, field.value)}`,
        order: FIELD_ORDER[field.name]
      });

      const omitFieldNames: string[] = ['first-name', 'last-name'];
      const omitField = (field: FanFormFieldDTO) => !omitFieldNames.includes(field.name);

      const sortFieldByOrder = (a: AdditionalInfoField, b: AdditionalInfoField) => (b.order || 0) - (a.order || 0);

      const normalizedForms: { fields: AdditionalInfoField[] }[] = forms.map(form => {
        const { fields } = form;
        const firstNameField = fields.find(field => field.name === 'first-name');
        const lastNameField = fields.find(field => field.name === 'last-name');
        const fullName = getContactName(firstNameField?.value || '', lastNameField?.value || '');
        const profileFields: AdditionalInfoField[] = fields.filter(omitField).map(transformField);
        if (fullName) {
          profileFields.push({
            name: 'full-name',
            value: fullName,
            label: FIELD_LABEL_MAP['full-name'] || '',
            text: `${FIELD_LABEL_MAP['full-name'] || ''}${fullName}`,
            order: FIELD_ORDER['full-name']
          });
        }

        return {
          fields: profileFields.sort(sortFieldByOrder)
        };
      });

      additionalFields = normalizedForms.flatMap(form => form.fields);
    }

    return {
      additionalFields
    };
  };

  static getFormFieldValue = (fields: FanFormFieldDTO[]) => (fieldName: string) =>
    fields
      .filter(field => field.name === fieldName)
      .sort((fieldA, fieldB) => {
        if (fieldA.displayOnTicket === fieldB.displayOnTicket) {
          return 0;
        }
        return fieldA.displayOnTicket === true ? -1 : 1;
      })?.[0]?.value || '';

  static #getNextAvailableTime = (fanTicket: PurchasedTicketDTO, redemptionWindow: number) => {
    if (!isNumber(redemptionWindow)) return '';
    const availableTime = moment(fanTicket.redeemedAt, DATE_FORMAT_DEFAULT_WITH_TIMEZONE).add(
      redemptionWindow,
      'minutes'
    );
    const haveNextAvailable = fanTicket.redemptionLimit ? fanTicket.redemptionCount < fanTicket.redemptionLimit : true;
    return haveNextAvailable && availableTime.isValid() ? availableTime.format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE) : '';
  };

  static #getFormFieldLabel = (fieldName: string) => {
    if (FIELD_IGNORE_LABEL.includes(fieldName)) {
      return '';
    }

    return FIELD_LABEL_MAP[fieldName] ?? `${fieldName}: `;
  };
}

export { FanService };
