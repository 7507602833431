import { PAYMENT_CYCLE_MODE } from './constant';

export default {
  SUCCESS_UPLOAD_FILE: 'Your seasons were uploaded.',
  SUCCESS_UPLOAD_SEASON_PER_SPORT: 'Your season was updated.',
  SUCCESS_UPLOAD_FILE_FOR_MULTI_SPORT: 'Your file was uploaded.',
  APPLIED_DIFFERENT_START_TIMES_TITLE: 'Your different start times were applied',
  APPLIED_DIFFERENT_START_TIMES_SUBTITLE: 'Fans will see each time on your GoFan page',
  SPORT_SELECTION: {
    TITLE: 'Setup your seasons',
    SUB_TITLE: 'Creating seasons for:'
  },
  UPLOAD_FILE_STATUS: {
    UPLOADING_TITLE: 'Uploading your file...',
    UPLOADED_FILE_TITLE: 'Uploaded your file',
    BUILDING_TITLE: 'Building your season...',
    BUILDING_SUB_TITLE: 'Creating each event',
    BUILDING_MULTI_SUB_TITLE: 'Creating events',
    OPENING_SEASON_TITLE: 'Opening your season...',
    OPENING_SEASON_SUB_TITLE: 'Good luck this year!',
    UPLOAD_ERROR_SUB_TITLE: 'Recheck your data and try again'
  },
  TICKET_TYPE_MODAL: {
    TITLE: 'Almost done, but you’re missing a ticket type for a sport(s) you selected:',
    CREATE_TICKET_TYPE_BUTTON: 'Create ticket types'
  },
  SEASON_GENERAL_SETUP: {
    SEASON_NAME: {
      LABEL_TEXT: 'Name this season'
    },
    CREATION_MODE: {
      TITLE: 'Event entrance',
      SUB_TITLE: 'To enter this season’s games:',
      ONE_PER_SPORT_LABEL: 'Fans can attend games with one ticket',
      SPERATE_EACH_TEAM_LABEL: 'Fans need a separate ticket for each game',
      EXAMPLE: 'Example:',
      EXAMPLE_TEXT: 'If boys and girls play on the same night:',
      EXAMPLE_TEXT_ONE:
        '<strong>Example</strong>: If boys and girls play on same night. Games are considered <strong>one</strong> event requiring <strong>one</strong> ticket.',
      EXAMPLE_TEXT_SEPARATE:
        '<strong>Example</strong>: If boys and girls play on same night. Games are considered <strong>separate</strong> events requiring <strong>separate</strong> tickets.'
    },
    SEASON_TEAM: {
      SECTION_TITLE: 'Participating teams'
    },
    SEASON_VENUE: {
      SECTION_TITLE: 'Sport venue',
      SET_UP_VENUE_BUTTON: 'Set up venue',
      UPDATE_VENUE_BUTTON: 'Update venue',
      SELECT_VENUE: 'Select a venue',
      SELECT_VENUE_LABEL: 'Where is this sport played?',
      MODAL_TITLE_ADD: 'Add a venue',
      MODAL_TITLE_UPDATE: 'Update venue',
      MODAL_TITLE_VIEW: 'Venue information',
      MODAL_CONFIRM_ADD: 'Add',
      MODAL_CONFIRM_UPDATE: 'Update',
      LOADING_VENUES: 'Loading account venues...',
      NO_VENUES_FOUND: 'No account venues found. Set up a new venue'
    },
    SEASON_PAYMENT_CYCLE: {
      SECTION_TITLE: 'Season payment cycle',
      UPDATE_DEFAULT_SETTING_LABEL: 'here',
      UPDATE_DEFAULT_SETTING_PATH: '',
      LEARN_MORE: 'Learn more',
      [PAYMENT_CYCLE_MODE.DAILY_SETTLEMENT]: {
        LABEL: 'Daily Settlement',
        DESCRIPTION: ''
      },
      [PAYMENT_CYCLE_MODE.CALENDAR_BASED]: {
        LABEL: 'Calendar-based',
        DESCRIPTION:
          'Calendar-based is a payment option that causes payments to be issued for all transactions that occurred in the previous week on your account (Monday through Sunday). You’ll get paid out the following Thursday and receive your Weekly Payment Statement via email by Friday.'
      }
    },
    ACCOUNT_TICKET_TYPES: {
      SECTION_TITLE: 'Event ticket prices',
      SECTION_SUB_TITLE: 'Set your season type and venue in order to see your default ticket prices for your season',
      SECTION_HELPER_TIP_1:
        'After creating your ticket prices, you’ll be able to create a season ticket package for this sport / activity',
      SECTION_HELPER_TIP_2: 'In order to publish your season, you must create ticket prices for your events',
      SECTION_SEASON_TICKET_TITLE: 'Create a season ticket package',
      SECTION_SEASON_TICKET_SUB_TITLE: 'Season Ticket Package',
      SECTION_SEASON_TICKET_DESCRIPTION:
        'Fans receive one ticket to every home game in the season, which translates to guaranteed revenue for your school and time and fee savings for your fans',
      YOUR_TICKET: 'Your tickets:',
      CREATE_TICKET_TYPE_BUTTON: 'Create default ticket prices',
      EDIT_TICKET_TYPE_BUTTON: 'Edit default ticket prices',
      SEASON_TICKET_PACKAGE: 'Season ticket package',
      CREATE_SEASON_TICKET_BUTTON: 'Create a season ticket package',
      HELPFUL_TIPS: 'Helpful Tips:',
      LEARN_MORE: 'Learn more >',
      LEARN_MORE_PATH: 'https://gofanschool.zendesk.com/hc/en-us/articles/10151759737229-Using-Season-Tickets-in-GoFan',
      TICKET_TYPES_TITLE: 'Ticket types',
      EVENT_SEASON_TICKETS_TITLE: 'Event Season Tickets',
      TICKETS_DESCRIPTION: 'The tickets below are individual ticket types in each event in your season'
    },
    EVENT_IMPORT_SECTION: {
      SECTION_TITLE: 'Adding events',
      SECTION_SUB_TITLE: 'How do you want to add your events?',
      IMPORT_TYPE_LABEL: {
        FILE_UPLOAD: 'File upload',
        MANUAL: 'Manual input',
        FROM_ARBITER: 'Import from Arbiter',
        COPY_FROM_SEASON: 'Copy from another season'
      },
      MANUAL: {
        LABEL: 'Number of events in this season',
        INVAILD_TEXT: 'You can enter a number between 1 and 100.'
      },
      UPLOAD_FILE: {
        LABEL: 'Use the import template',
        DOWNLOAD_BUTTON_TEXT: 'Download template',
        UPLOAD_BUTTON_TEXT: 'Upload template',
        UPLOAD_BUTTON_SUB_TEXT: 'Uploaded'
      }
    },
    EVENT_START_TIME: {
      SECTION_TITLE: 'Event start time',
      ALL_TEAMS: 'Same start time for all teams',
      ALL_TEAMS_SUBTITLE: 'Each team playing shows a specific start time:',
      EACH_TEAM: 'Different start time for each team',
      EACH_TEAM_SUBTITLE: 'A different start time will show for each team playing in this event',
      START_AT: 'Game start times'
    },
    BROADCAST_START_TIME: {
      START_AT: 'Broadcast start time',
      END_AT: 'Broadcast end time'
    },
    TICKET_TYPES_NOTIFICATION: {
      TITLE: 'Missing ticket types',
      SUBTITLE: 'You must create ticket types before you can continue'
    },
    UPLOAD_FILE_NOTIFICATION: {
      TITLE: 'Upload file',
      SUBTITLE: 'You must upload a file to continue'
    },
    VENUE_NOTIFICATION: {
      TITLE: 'Missing venue',
      SUBTITLE: 'You must specify a venue before you continue'
    },
    SEASONAL_PERIOD_NOTIFICATION: {
      TITLE: 'Missing season takes place',
      SUBTITLE: 'You must specify a season takes place to continue'
    },
    REMOVE_SPORT_NOTIFICATION: {
      TITLE: 'Information will be lost',
      SUBTITLE: 'By deselecting this sport, all information you entered for this sport season will be lost'
    },
    SEASON_VISIBILITY: {
      TITLE: 'Visibility',
      SET_VISIBILITY: 'Set the season visibility',
      LABEL: `Hide this season on the school's GoFan page`,
      PUBLISH_DATE_TIME_TITLE: 'Automatically publish this season to the school’s GoFan page on:',
      PUBLISH_DATE: 'Publish date',
      PUBLISH_DATE_PLACEHOLDER: 'mm/dd/yyy',
      PUBLISH_TIME: 'Publish time',
      PUBLISH_TIME_PLACEHOLDER: 'hh:mm'
    },
    EVENT_VISIBILITY: {
      TITLE: 'Event visibility',
      SET_VISIBILITY: 'Set the event visibility',
      SUBTITLE:
        'All events will be visible when you create a season. You can hide all events from visibility by turning ON the toggle below',
      LABEL: "Hide all events for this season on the school's GoFan page",
      TIP: '<strong>Tip:</strong> To show only specific events, publish the season, and then turn visibility ON for each event you want to display'
    },
    EVENT_ALERT: {
      TITLE: 'Event alert',
      SUBTITLE: 'Add an alert or special information to the top of your event page',
      PLACEHOLDER: 'Example: Important COVID-19 guidelines for this game'
    }
  },
  SEASON_EVENT_DETAILS: {
    UPDATE_SEASON_PER_SPORT: 'Update season'
  },
  SEASON_EVENT_SETUP: {
    CLEAR_EVENTS_BUTTON: 'Clear data',
    ADD_EVENTS: 'Add events',
    PUBLISH_SEASON: 'Publish season',
    TITLE: 'Enter event details',
    EVENT_ITEM: {
      SEARCH_PLACEHOLDER: 'Search for school',
      DATE: 'Date',
      TIME: 'Time',
      DATE_PICKER_PLACEHOLDER: 'mm/dd/yyyy',
      CREATE_WITHOUT_OPPONENT: 'Create without opponent',
      CREATE_STREAMING_EVENT: 'Create streaming event',
      VIEW_EVENT: 'View Event',
      EDIT_EVENT: 'Edit Event',
      TOOLTIP_TITLE: 'Don’t know the opponent?',
      TOOLTIP_CONTENT: `You can create an event with a date, time and an event name. Just enter the name you want in the field above
      or keep the placeholder event name provided “Your School vs TBD.”`
    }
  },
  ERRORS_MESSAGE: {
    ALPHANUMERIC_SPECIAL_CHARACTERS:
      'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (`()./_-#\'@&*+",:;?!) in between 3-100 characters long.',
    SEASON_NAME: {
      REQUIRED: 'Season name is required.'
    },
    TEAM: {
      GENDER_REQUIRED: 'Gender is required.',
      BOY_LEVEL_REQUIRED: 'Boys sport level is required.',
      GIRL_LEVEL_REQUIRED: 'Girls sport level is required.',
      COED_LEVEL_REQUIRED: 'Sport level is required.'
    },
    SCHOOL_OPPONENT: {
      REQUIRED: 'School opponent is required.'
    },
    EVENT_NAME: {
      REQUIRED: 'Event name is required.'
    },
    EVENT_START_DATE_TIME: {
      REQUIRED: 'Date is required.',
      INVALID: 'Enter a valid date.'
    },
    EVENT_START_TIME: {
      REQUIRED: 'Time is required.',
      INVALID: 'Enter a valid time.'
    },
    NUM_OF_EVENTS: {
      INVALID: 'Enter a valid number.',
      REQUIRED: 'Number of events is required.',
      MIN: 'You can enter a number between 1 and 100.',
      MAX: 'You can enter a number between 1 and 100.'
    },
    GENERAL_START_TIME: {
      REQUIRED: 'Events start at is required.',
      EACH_TEAM_REQUIRED: '{gender} {levelName} is required.',
      INVALID: 'Enter a valid time.'
    },
    GENERAL_FILE_UPLOAD_SETTING: {
      REQUIRED: 'File is required.',
      INVALID: 'File is invalid.'
    },
    EVENT_VISIBILITY: {
      ALPHANUMERIC_SPECIAL_CHARACTERS: 'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9).'
    },
    PUBLISH_DATE: {
      REQUIRED: 'Publish date is required.',
      INVALID: 'Publish date is invalid.'
    },
    PUBLISH_TIME: {
      REQUIRED: 'Publish time is required.',
      INVALID: 'Publish time is invalid.'
    }
  }
};
