// @flow
import ProductTypeDAO from '../dao/ProductTypeDAO';

const fetchAllProductTypes = () => ProductTypeDAO.fetchAllProductTypes();

const createProductType = productType =>
  ProductTypeDAO.createProductType(productType);

const updateProductType = productType =>
  ProductTypeDAO.updateProductType(productType);

const deleteProductType = id => ProductTypeDAO.deleteProductType(id);

export default {
  fetchAllProductTypes,
  createProductType,
  updateProductType,
  deleteProductType
};
