import { all, put, call, select, takeLatest } from 'redux-saga/effects';

import { DISTRICT_CONFERENCE_SCHOOL_TYPES } from '@gofan/constants';
import EventService from '@events/services/events.service';
import { AccountService } from '@gofan/api/accounts';
import PublishEditEventRequest from '@app/api/model/request/PublishEditEventRequest';
import { generateErrorMessage, generateSuccessMessage } from '@app/utils/alertUtils';
import { LevelService } from '@gofan/api/levels';
import EventScheduleService from '@app/modules/event-integrations/services/event-schedule.service';
import { DELETED_STATUS } from '@app/modules/event-integrations_V2/constants/constants';
import { canAccessEventByDistrictUser } from '@gofan/api/users';
import { SchoolConfigService } from '@gofan/api/school-config';
import {
  searchEvents as searchEventsAction,
  searchEventsCompleted,
  prepareDataCompleted,
  updateEventVisibilityCompleted,
  fetchActivitiesSuccessful,
  deleteEventCompleted,
  fetchEventSalesInfoCompleted,
  fetchAccountsByIdsCompleted,
  onRequestTicketDistributionForEventsCompleted,
  fetchAllLevelsCompleted
} from './actions';
import {
  SEARCH_EVENTS,
  PREPARE_DATA,
  UPDATE_EVENT_VISIBILITY,
  DELETE_EVENT,
  FETCH_EVENT_SALES_INFO,
  ON_REQUEST_TICKET_DISTRIBUTION_FOR_EVENTS
} from './actionTypes';
import { showAccountInfo, addNotification } from '../Root/actions';
import { REQUEST_FIELDS, EXPAND_FIELDS } from './constants';
import { PAGES } from '../../config/routes';

import { UNEXPECTED_ERROR } from '../../api/api/constants';
import EventV2 from '../../api/model/EventV2';
import Account from '../../api/model/Account';
import PaginationModel from '../../api/model/PaginationModel';
import ErrorDTO from '../../api/dto/ErrorDTO';
import AccountDTO from '../../api/dto/AccountDTO';
import PaginationDTO from '../../api/dto/PaginationDTO';
import { fetchAccountById } from '../../api/services/AccountService';
import { checkInternalUser, checkPermissionHandlingEvent } from '../../api/services/UserService';
import {
  updateEvent,
  getEventByParams,
  mappingPublishEventValues,
  deleteEvent,
  getEventSalesInfo,
  mapSalesInfoToEvents,
  executeEnableTicketDistribution
} from '../../api/services/EventService';

import { isEmpty } from '../../utils/objectUtils';
import ActivityService from '../../api/services/ActivityService';
import uniq from 'lodash/uniq';
import cloneDeep from 'lodash/cloneDeep';

export function* allowingAccessEventPage(action) {
  const { accountId, currentUser } = action.payload;
  const hasPermission = yield call(checkPermissionHandlingEvent, {
    viewMode: true,
    currentUser,
    accountId
  });
  let isAccountActive = false;
  if (hasPermission && accountId) {
    const response = yield call(fetchAccountById, accountId);
    isAccountActive = !(response instanceof ErrorDTO);
    if (response instanceof AccountDTO) {
      const account = new Account(response.toJSON()).toJSON();
      isAccountActive = account.isActive;
      yield put(showAccountInfo(account));
    }
  }
  return hasPermission && isAccountActive;
}

export function* searchEventByParams(action) {
  try {
    const { searchParams = {} } = action.payload;
    const sortByData = isEmpty(searchParams.sortBy)
      ? []
      : searchParams.sortBy.map(sortable => {
          const sortId = `${sortable.id}`;
          if (EventService.isClientSort(sortId)) return {};
          return sortable;
        });
    let sortBy = sortByData;
    const sortArchived = sortBy.find(sort => sort.id === 'archived');
    if (sortArchived) {
      sortBy = [
        ...sortBy,
        {
          id: 'canceled',
          desc: sortArchived.desc
        }
      ];
    }

    const { currentUser, isDistrictUser, schoolConfig, districtAccount } = yield select(state => state.eventLanding);
    let newSearchBody = cloneDeep(searchParams);

    if (isDistrictUser) {
      const newValues = AccountService.getSearchParamsByPermission({
        currentUser,
        districtAccount,
        schoolsConfig: [schoolConfig],
        accountIds: isEmpty(searchParams?.accountIds) ? [] : [...(searchParams?.accountIds ?? [])],
        financialAccountIds: isEmpty(searchParams?.financialAccountIds)
          ? []
          : [...(searchParams?.financialAccountIds ?? [])]
      });

      newSearchBody = { ...newSearchBody, ...newValues };
    }

    if (searchParams?.searchOption === 'Away') {
      newSearchBody = { ...newSearchBody, financialAccountIds: undefined };
    }

    let searchResult = new PaginationModel({}).toJSON();

    if (isEmpty(newSearchBody?.accountIds) && isEmpty(newSearchBody?.financialAccountIds)) {
      yield put(searchEventsCompleted({ data: [], pageIndex: 0, totalElements: 0 }));
      return;
    }

    const response = yield call(getEventByParams, {
      sortBy,
      expand: EXPAND_FIELDS,
      fields: REQUEST_FIELDS,
      page: {
        pageSize: searchParams.pageSize,
        pageIndex: searchParams.pageIndex
      },
      search: newSearchBody
    });

    let additionalInfoError;

    if (response instanceof PaginationDTO) {
      const { data, pageSize, pageNumber, totalPages, totalElements } = response.toJSON();
      const eventsToJson = data.map(event => new EventV2(event.toJSON()).toJSON());

      // get Daily Sales Info
      let dailySalesInfo = {};
      try {
        dailySalesInfo = yield call(EventService.getDailySalesInfo, data);
      } catch (error) {
        additionalInfoError =
          !isEmpty(error.data) && !isEmpty(error.data.message) ? error.data.message : UNEXPECTED_ERROR;
      }

      // get Away school info
      const awayIds = uniq(
        eventsToJson?.map(event => event?.opponentAccountId ?? event?.featuredAccountIds?.[0])?.filter(id => !!id)
      );
      let awaySchools = [];
      try {
        awaySchools = yield call(AccountService.getAccountsByIds, awayIds);
      } catch (error) {
        additionalInfoError =
          !isEmpty(error.data) && !isEmpty(error.data.message) ? error.data.message : UNEXPECTED_ERROR;
      }

      const content = yield call(EventService.mapAdditionalInfoToEvent, {
        events: eventsToJson,
        eventDailySalesInfo: dailySalesInfo,
        awaySchools
      });

      searchResult = new PaginationModel({
        pageSize,
        totalElements,
        pageIndex: pageNumber,
        pageCount: totalPages,
        data: content
      }).toJSON();
    }

    yield put(searchEventsCompleted({ searchResult, error: additionalInfoError }));

    const ids = searchResult.data.map(event => event.id);
    const salesInfoResponses = yield all(ids.map(id => call(getEventSalesInfo, id)));

    const { events } = yield select(state => state.eventLanding);
    const newEvents = yield call(mapSalesInfoToEvents, {
      events,
      salesInfos: salesInfoResponses
    });
    yield put(fetchEventSalesInfoCompleted({ events: newEvents }));
  } catch (error) {
    const errorMessage = !isEmpty(error.data) && !isEmpty(error.data.message) ? error.data.message : UNEXPECTED_ERROR;
    yield put(searchEventsCompleted({ error: errorMessage }));
  }
}

export function* updateEventVisibility(action) {
  try {
    const { event, archived } = action.payload;
    const updatedValues = yield call(mappingPublishEventValues, {
      event: { ...event, archived, publishDateTime: undefined }
    });

    if (updatedValues instanceof ErrorDTO) {
      yield put(
        updateEventVisibilityCompleted({
          error: updatedValues.getErrorMessage() || UNEXPECTED_ERROR
        })
      );
      return;
    }

    const eventRequest = new PublishEditEventRequest(updatedValues);

    const eventResponse = yield call(updateEvent, eventRequest);

    if (eventResponse instanceof ErrorDTO) {
      yield put(
        updateEventVisibilityCompleted({
          error: eventResponse.getErrorMessage() || UNEXPECTED_ERROR
        })
      );
      return;
    }

    const { events } = yield select(state => state.eventLanding);
    const newEvents = events.map(item => {
      if (`${item.id}` === `${event.id}`) {
        return { ...item, archived };
      }
      return { ...item };
    });

    yield put(updateEventVisibilityCompleted({ events: newEvents }));
  } catch (error) {
    const errorMessage = !isEmpty(error.data) && !isEmpty(error.data.message) ? error.data.message : UNEXPECTED_ERROR;
    yield put(updateEventVisibilityCompleted({ error: errorMessage }));
  }
}

export function* fetchAccountsByIdsInfo(accountIds) {
  try {
    const response = yield all(accountIds.map(id => call(fetchAccountById, id)));
    if (response instanceof Array) {
      return response;
    }
    return [];
  } catch (error) {
    return [];
  }
}

export function* prepareData(action) {
  try {
    const { history, currentUser, enableDistrictUnicorn, searchParams, accountId } = action.payload;
    let associatedAccountIds = [];
    let accountContext = {};
    const isInternalUser = checkInternalUser(currentUser?.role);

    if (isInternalUser) {
      const allowAccess = yield call(allowingAccessEventPage, action);
      associatedAccountIds = [...searchParams.accountIds];
      if (!allowAccess) {
        history.replace(PAGES.events.account.path);
        yield put(prepareDataCompleted('permission denied'));
        return;
      }
    } else {
      const { userAccountContexts = [] } = currentUser || {};
      associatedAccountIds = userAccountContexts.filter(uac => !uac.inactive).map(account => account.accountId);
      accountContext = associatedAccountIds.find(accId => accId === accountId);
    }

    const accIds = enableDistrictUnicorn ? associatedAccountIds : [...associatedAccountIds, ...searchParams.accountIds];

    const accountInfos = yield call(fetchAccountsByIdsInfo, uniq(accIds));

    const allowedToEditAccounts = [];
    const activeAccounts = [];
    const selectionAccounts = [];

    accountInfos.forEach(acc => {
      if (acc instanceof AccountDTO) {
        const account = new Account(acc.toJSON()).toJSON();
        selectionAccounts.push(account);

        if (account.isActive) {
          activeAccounts.push(acc);
          const canEditEvent = checkPermissionHandlingEvent({
            accountId: account.id,
            currentUser,
            viewMode: false
          });
          if (canEditEvent) {
            allowedToEditAccounts.push(acc);
          }
        }
      }
    });

    const gfDistrictAcc = selectionAccounts?.find(
      acc => acc.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT
    );

    if (enableDistrictUnicorn && !isInternalUser && isEmpty(gfDistrictAcc) && accountId && isEmpty(accountContext)) {
      history.replace(PAGES.events.account.path);
      yield put(prepareDataCompleted('permission denied'));
      return;
    }

    const isDistrictUser = enableDistrictUnicorn && !isEmpty(gfDistrictAcc);
    let schoolConfig = {};

    if (isDistrictUser) {
      schoolConfig = yield call(SchoolConfigService.getSchoolConfigBySchoolId, accountId);

      const allowAccess = canAccessEventByDistrictUser({
        accountId,
        currentUser,
        schoolsConfig: [schoolConfig]
      });

      if (!allowAccess) {
        history.replace(PAGES.events.account.path);
        yield put(prepareDataCompleted('permission denied'));
        return;
      }
    }

    yield put(
      fetchAccountsByIdsCompleted({
        schoolConfig,
        isDistrictUser,
        selectionAccounts,
        districtAccount: gfDistrictAcc,
        editableAccounts: allowedToEditAccounts,
        associatedAccounts: activeAccounts,
        haveAccountActiveContext: activeAccounts.length > 0
      })
    );

    if (activeAccounts.length === 1) {
      yield put(showAccountInfo(activeAccounts[0]));
    }

    if (!isEmpty(searchParams) && searchParams?.accountIds && searchParams?.accountIds?.length > 0) {
      yield put(searchEventsAction(searchParams));
    } else {
      yield put(searchEventsCompleted({ data: [], pageIndex: 0, totalElements: 0 }));
    }

    yield put(prepareDataCompleted());

    const activitiesResponse = yield call(ActivityService.fetchAllActivities);
    const { athleticActivities, nonAthleticActivities } = yield call(
      ActivityService.groupActivityByType,
      activitiesResponse
    );
    yield put(fetchActivitiesSuccessful(athleticActivities, nonAthleticActivities));
    const levelsResponse = yield call(LevelService.getAllLevels);
    const availableLevel = yield call(LevelService.filterAvailableLevels, levelsResponse);
    yield put(fetchAllLevelsCompleted(availableLevel));
  } catch (error) {
    const errorMessage = !isEmpty(error.data) && !isEmpty(error.data.message) ? error.data.message : UNEXPECTED_ERROR;
    yield put(prepareDataCompleted(errorMessage));
  }
}

export function* watchUpdateEventVisibility() {
  yield takeLatest(UPDATE_EVENT_VISIBILITY, updateEventVisibility);
}

export function* watchSearchEvent() {
  yield takeLatest(SEARCH_EVENTS, searchEventByParams);
}

export function* watchPrepareData() {
  yield takeLatest(PREPARE_DATA, prepareData);
}

export function* deleteEventById(action) {
  try {
    const { eventId, globalEventsId, searchParams } = action.payload;
    const response = yield call(deleteEvent, eventId);
    if (response instanceof ErrorDTO) {
      const errorMessage =
        !isEmpty(response.data) && !isEmpty(response.data.message) ? response.data.message : UNEXPECTED_ERROR;
      yield put(deleteEventCompleted({ error: errorMessage }));
    }
    if (globalEventsId) {
      const updateGlobalEvent = yield call(EventScheduleService.updateGlobalEvent, {
        id: globalEventsId,
        gofanStatusMsg: DELETED_STATUS
      });
    }
    if (!isEmpty(searchParams)) {
      yield put(searchEventsAction(searchParams));
    } else {
      yield put(deleteEventCompleted({ error: null }));
    }
  } catch (error) {
    const errorMessage = !isEmpty(error.data) && !isEmpty(error.data.message) ? error.data.message : UNEXPECTED_ERROR;
    yield put(deleteEventCompleted({ error: errorMessage }));
  }
}

export function* watchDeleteEventById() {
  yield takeLatest(DELETE_EVENT, deleteEventById);
}

export function* fetchEventSalesInfo(action) {
  try {
    const { eventId } = action.payload;
    const response = yield call(getEventSalesInfo, eventId);
    if (response instanceof ErrorDTO) {
      const errorMessage =
        !isEmpty(response.data) && !isEmpty(response.data.message) ? response.data.message : UNEXPECTED_ERROR;
      yield put(fetchEventSalesInfoCompleted({ error: errorMessage }));
    }
    const { events } = yield select(state => state.eventLanding);
    const newEvents = yield call(mapSalesInfoToEvents, {
      events,
      salesInfos: [response]
    });
    yield put(fetchEventSalesInfoCompleted({ events: newEvents }));
  } catch (error) {
    const errorMessage = !isEmpty(error.data) && !isEmpty(error.data.message) ? error.data.message : UNEXPECTED_ERROR;
    yield put(fetchEventSalesInfoCompleted({ error: errorMessage }));
  }
}

export function* watchFetchEventSalesInfo() {
  yield takeLatest(FETCH_EVENT_SALES_INFO, fetchEventSalesInfo);
}

export function* handleRequestTicketDistribution(action) {
  const { events, searchParams } = action.payload;
  try {
    const response = yield call(executeEnableTicketDistribution, events);
    yield put(onRequestTicketDistributionForEventsCompleted());
    if (response instanceof Array) {
      yield put(addNotification(generateSuccessMessage('Created home/away links for selected events successfully!')));
    } else {
      yield put(addNotification(generateErrorMessage(response.getErrorMessage())));
    }
  } catch (e) {
    yield put(onRequestTicketDistributionForEventsCompleted(e.message));
    yield put(addNotification(generateErrorMessage(e.message)));
  }
  if (!isEmpty(searchParams)) {
    yield put(searchEventsAction(searchParams));
  }
}

export function* watchTicketDistributionRequest() {
  yield takeLatest(ON_REQUEST_TICKET_DISTRIBUTION_FOR_EVENTS, handleRequestTicketDistribution);
}

export default [
  watchPrepareData(),
  watchSearchEvent(),
  watchUpdateEventVisibility(),
  watchDeleteEventById(),
  watchFetchEventSalesInfo(),
  watchTicketDistributionRequest()
];
