import { huddleAxiosInstanceProxy } from '../shared/axios';
import { GET_CRM_SCHOOL_BY_ID } from '@gofan/constants/endpoints';
import type { CRMSchoolDTO } from './crm-school.model';

class CRMSchoolRepository {
  static getCrmSchoolById = (accountId: string): Promise<CRMSchoolDTO> =>
    huddleAxiosInstanceProxy.get(GET_CRM_SCHOOL_BY_ID.replace('{id}', accountId), undefined, true);
}

export { CRMSchoolRepository };
