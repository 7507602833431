// @flow
import { LevelState } from './types';
import {
  LevelActionTypes,
  FETCH_LEVELS,
  FETCH_LEVELS_SUCCESSFUL,
  UPDATE_LEVEL,
  UPDATE_LEVEL_SUCCESSFUL,
  CREATE_LEVEL_SUCCESSFUL,
  CREATE_LEVEL,
  DELETE_LEVEL,
  DELETE_LEVEL_SUCCESSFUL,
  DELETE_LEVEL_COMPLETED,

  CREATE_LEVEL_COMPLETED,
  UPDATE_LEVEL_COMPLETED,
  FETCH_LEVELS_COMPLETED,

} from './actionTypes';

export const initialState: LevelState = {
  loading: false,
  levels: []
};

export default (state = initialState, action: LevelActionTypes) => {
  switch (action.type) {
    case CREATE_LEVEL:
    case UPDATE_LEVEL:
    case DELETE_LEVEL:
    case FETCH_LEVELS: {
      return {
        ...state,
        loading: true
      };
    }
    case CREATE_LEVEL_COMPLETED:
    case UPDATE_LEVEL_COMPLETED:
    case DELETE_LEVEL_COMPLETED:
    case FETCH_LEVELS_COMPLETED: {
      return {
        ...state,
        loading: false
      };
    }
    case CREATE_LEVEL_SUCCESSFUL:
    case UPDATE_LEVEL_SUCCESSFUL:
    case DELETE_LEVEL_SUCCESSFUL:
    case FETCH_LEVELS_SUCCESSFUL: {
      return {
        ...state,
        loading: false,
        levels: action.payload.levels
      }
    }
    default:
      return state;
  }
};
