import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'carbon-components-react';
import styles from './styles';

const AdminUserRoles = styled(Dropdown)`
  ${styles.adminUserRoles};
`;

export default ({ setCurrentUser, currentUser }) => (
  <AdminUserRoles
    id='dropdown-roles-dev'
    label=''
    items={[
      {
        key: 'SUPER_ADMIN',
        text: 'SUPER_ADMIN',
        value: 'SUPER_ADMIN'
      },
      {
        key: 'SYSTEM_FINANCE_ADMIN',
        text: 'SYSTEM_FINANCE_ADMIN',
        value: 'SYSTEM_FINANCE_ADMIN'
      },
      {
        key: 'SYSTEM_ADMIN',
        text: 'SYSTEM_ADMIN',
        value: 'SYSTEM_ADMIN'
      },
      {
        key: 'SYSTEM_USER',
        text: 'SYSTEM_USER',
        value: 'SYSTEM_USER'
      },
      {
        key: 'LIMITED_SYSTEM_USER',
        text: 'LIMITED_SYSTEM_USER',
        value: 'LIMITED_SYSTEM_USER'
      },
      {
        key: 'ACCOUNT_USER',
        text: 'ACCOUNT_USER',
        value: 'ACCOUNT_USER'
      }
    ]}
    itemToString={item => (item.text ? item.text : '')}
    onChange={({ selectedItem }) => setCurrentUser({ ...currentUser, role: selectedItem.value })
      }
    selectedItem={
        currentUser
          ? {
            key: currentUser.role,
            text: currentUser.role,
            value: currentUser.role
          }
          : null
      }
  />
);
