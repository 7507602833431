import type { ConfigType, Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DATE_FORMAT_DEFAULT, DATE_TIME_PICKER_FORMAT_DEFAULT } from '@gofan/constants/date';

dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

dayjs.extend(utc);
dayjs.extend(timezone); // ext timezone go with utc.

// checking utils
export const isInThisWeek = (date: ConfigType): boolean => dayjs().isSame(date, 'week');
export const isInThisMonth = (date: ConfigType): boolean => dayjs().isSame(date, 'month');
export const isInLastDays = (date: ConfigType, n: number) => {
  const now = dayjs();
  return now.isSameOrAfter(date) && now.diff(dayjs(date).startOf('day'), 'day') <= n;
};

// sort utils
export const sortLatestFirst = (a: ConfigType, b: ConfigType) => {
  if (dayjs(a).isSame(b)) return 0;
  return dayjs(a).isAfter(b) ? -1 : 1;
};

export const sortLatestLast = (a: Dayjs, b: Dayjs) => {
  if (dayjs(a).isSame(b)) return 0;
  return dayjs(a).isAfter(b) ? 1 : -1;
};

// format, convert utils
export const formatTz = (dateTime: ConfigType, timeZone: string) => dayjs(dateTime).tz(timeZone);

export const randomNumber = (qty = 9876) => Math.round(Math.random() + 1) * qty;

export const isPastJun = (): boolean => dayjs().isAfter(dayjs().month(5).endOf('month'));

export const getCurrentSchoolYearRange = (
  formatDate = 'MM/DD/YYYY',
  delimiter = '-'
): { periodStart: string; periodEnd: string; period: string } => {
  const startOfJuly = dayjs().month(6).startOf('month');
  const endOfJune = dayjs().month(5).endOf('month');

  const startDate = isPastJun() ? startOfJuly : startOfJuly.subtract(1, 'year');
  const endDate = isPastJun() ? endOfJune.add(1, 'year') : endOfJune;
  const periodStart = startDate.format(formatDate);
  const periodEnd = endDate.format(formatDate);
  const period = `${startDate.format('YYYY')}${delimiter}${endDate.format('YYYY')}`;

  return {
    periodStart,
    periodEnd,
    period
  };
};

export const getSchoolYearRange = (
  schoolYear?: string,
  formatDate = 'MM/DD/YYYY',
  delimiter = '-'
): { periodStart: string; periodEnd: string; period: string } => {
  if (schoolYear && schoolYear.includes(delimiter)) {
    const [yearStart, yearEnd] = schoolYear.split(delimiter);
    const periodStart = dayjs(yearStart).month(6).startOf('month');
    const periodEnd = dayjs(yearEnd).month(5).endOf('month');
    const period = `${periodStart.format('YYYY')}${delimiter}${periodEnd.format('YYYY')}`;

    return {
      periodStart: periodStart.format(formatDate),
      periodEnd: periodEnd.format(formatDate),
      period
    };
  }
  return getCurrentSchoolYearRange(formatDate, delimiter);
};

export const DEFAULT_SCHOOL_YEAR = getCurrentSchoolYearRange('YYYY', '-').period;

export const getSchoolYearList = (): { key: number; label: string }[] => [
  {
    key: 6,
    label: '2010-2011'
  },
  {
    key: 10,
    label: '2011-2012'
  },
  {
    key: 11,
    label: '2012-2013'
  },
  {
    key: 12,
    label: '2013-2014'
  },
  {
    key: 13,
    label: '2014-2015'
  },
  {
    key: 21,
    label: '2015-2016'
  },
  {
    key: 22,
    label: '2016-2017'
  },
  {
    key: 23,
    label: '2017-2018'
  },
  {
    key: 24,
    label: '2018-2019'
  },
  {
    key: 25,
    label: '2019-2020'
  },
  {
    key: 26,
    label: '2020-2021'
  },
  {
    key: 27,
    label: '2021-2022'
  },
  {
    key: 28,
    label: '2022-2023'
  },
  {
    key: 29,
    label: '2023-2024'
  },
  {
    key: 30,
    label: '2024-2025'
  }
];

export const getSchoolYearId = (schoolYear = DEFAULT_SCHOOL_YEAR) =>
  getSchoolYearList().filter(item => item.label === schoolYear)[0]?.key;

export const findEarliestTime = (times: { time: string; period: string }[]) => {
  // times must be formatted as such: { time: '12:00', period: 'AM' }

  if (times.length === 0) return null;

  // Parse times into Day.js unix timestamps, sort them, and return the earliest time
  const sortedTimes = times
    .map(t => {
      const now = dayjs().format(DATE_FORMAT_DEFAULT);
      const unixTime = dayjs(`${now} ${t.time} ${t.period}`, DATE_TIME_PICKER_FORMAT_DEFAULT).unix();

      return { time: t, unixTime };
    })
    .sort((a, b) => a.unixTime - b.unixTime)
    .map(t => t.time);

  return sortedTimes[0];
};
