import { getStorageItem } from '@gofan/utils/local-storage';
import type { UserDTO } from '@gofan/api/users';

export const ldContexts = () => {
  let currentUser: UserDTO | null = null;

  // Check if window is defined (we are on client side)
  if (typeof window !== 'undefined') {
    currentUser = getStorageItem('currentUser');
  }
  const { email, firstName, lastName, userAccountContexts } = currentUser || {};

  const accountIds = Array.isArray(userAccountContexts) ? userAccountContexts.map(item => item.accountId) : [];

  return {
    kind: 'multi',

    // all users
    user: {
      key: `user-${email}`,
      name: firstName === null || firstName === '' ? 'user' : `${firstName} ${lastName}`,
      email,
      accountIDs: accountIds
    }
  };
};
