import React, { useCallback } from 'react';
import { oc } from 'ts-optchain';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import {
  DataTableHeader,
  DataTableCustomRenderProps
} from 'carbon-components-react/lib/components/DataTable/DataTable';
import {
  TableRow,
  TableHead,
  TableHeader,
  TableSelectAll
} from 'carbon-components-react';
import { isEmpty } from '@utils/objectUtils';

import GridRowConfig from './basic-table-body/basic-table-body.component';
import {
  SortBy,
  getSortableProps,
  getNextSortDirection
} from '../sorting';

export type GridDataTableHeader = {
  isSortable?: boolean;
  textEllipsis?: boolean;
  style?: any;
  onHeaderClicked?: (evt: React.MouseEvent<HTMLElement>) => void;
} & DataTableHeader;

export type BasicTableHeaderProps = {
  headers: GridDataTableHeader[];
  rowConfig: GridRowConfig;
  sortConfig: { sortBy: SortBy };
  selectableRowCount: number;
  selectedRowIds: string[];
  onSortChange: (v: SortBy) => void;
  onSelectAll?: () => void;
} & DataTableCustomRenderProps;

const BasicTableHeader = ({
  headers,
  rowConfig,
  sortConfig,
  onSortChange,
  selectableRowCount,
  selectedRowIds = [],
  getHeaderProps,
  getSelectionProps,
  onSelectAll,
}: BasicTableHeaderProps) => {
  const onHeaderClick = useCallback(
    header => (e, value) => {
      const { sortHeaderKey } = value;
      const sortDirection = getNextSortDirection({
        header: sortHeaderKey,
        sortBy: oc(sortConfig).sortBy({}),
      });

      onSortChange({ header: sortHeaderKey, sortDirection });

      if (header.onHeaderClicked && typeof header.onHeaderClicked === 'function') {
        header.onHeaderClicked(e, value);
      }
    }, [sortConfig]
  );

  const selectionProps = getSelectionProps();
  const customizedSelectionProps = {
    ...selectionProps,
    indeterminate: selectedRowIds.length > 0 && selectedRowIds.length < selectableRowCount,
    checked: selectedRowIds.length === selectableRowCount && selectableRowCount > 0,
    disabled: selectableRowCount === 0,
    onSelect: (e: any) => {
      e.stopPropagation();
      onSelectAll?.();
      selectionProps.onSelect(e);
    }
  };

  return (
    <TableHead>
      <TableRow>
        { onSelectAll && <TableSelectAll {...customizedSelectionProps} /> }
        {headers.map((header) => {
          const isSortable = !isEmpty(sortConfig) && Boolean(header.isSortable);
          const sortableProps = getSortableProps({
            isSortable,
            header: header.key,
            sortBy: oc(sortConfig).sortBy({}),
          });
          const tableHeaderClass = classNames(
            'gs--productive-heading-01',
            'gs--text-01',
            'basic-table--header',
            {
              'text-ellipsis': Boolean(header.textEllipsis),
              sortable: isSortable
            }
          );
          return (
            <TableHeader
              {...getHeaderProps({
                header,
                isSortable,
                style: header.style,
                onClick: onHeaderClick(header),
                className: tableHeaderClass
              })}
              {...sortableProps}
              key={uniqueId('table_header_key')}
            >
              {header.header}
            </TableHeader>
          );
        })}
        {!isEmpty(oc(rowConfig).menuItems([])) && <TableHeader />}
      </TableRow>
    </TableHead>
  );
};

export default BasicTableHeader;
