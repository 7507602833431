import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import differenceBy from 'lodash/differenceBy';
import isEmpty from 'lodash/isEmpty';

import { DATE_FORMAT_DEFAULT, DATE_PICKER_FORMAT_DEFAULT } from '@gofan/constants/date';

import type { RatesConfigurationFormState } from './rates-configuration.schema';

export const getMinDate = (date: any) => {
  const mDate = dayjs(date, DATE_FORMAT_DEFAULT);
  const mCurDate = dayjs();
  if (mDate.isValid() && mDate.isBefore(mCurDate, 'days')) {
    return mDate.format(DATE_PICKER_FORMAT_DEFAULT);
  }
  return mCurDate.format(DATE_PICKER_FORMAT_DEFAULT);
};

export const isEqualDate = (date1: any, date2: any) => {
  if (isEmpty(date1) && isEmpty(date2)) {
    return true;
  }

  const mDate1 = dayjs(date1);
  const mDate2 = dayjs(date2);
  if (mDate1.isValid() && mDate2.isValid()) {
    return mDate1.isSame(mDate2, 'days');
  }
  return false;
};

export const isEqualArray = (arr1: any[], arr2: any[], compareKey: string) => {
  if (arr1.length !== arr2.length) return false;
  return differenceBy(arr1, arr2, compareKey).length === 0;
};

export const isEqualFormState = (
  curState: RatesConfigurationFormState,
  defaultState: RatesConfigurationFormState
): boolean =>
  Object.keys(curState).every((key: keyof RatesConfigurationFormState) => {
    switch (key) {
      case 'rateAmount':
      case 'ratePercent':
      case 'minPrice':
      case 'maxPrice': {
        const curValue = parseFloat(`${curState[key]}`);
        const defaultValue = parseFloat(`${defaultState[key]}`);
        return curValue === defaultValue;
      }

      case 'startDate':
      case 'endDate': {
        const curValue = curState[key] ?? '';
        const defaultValue = defaultState[key] ?? '';
        return isEqualDate(curValue, defaultValue);
      }

      case 'productTypes': {
        const curValue = curState[key] ?? [];
        const defaultValue = defaultState[key] ?? [];
        return isEqualArray(curValue, defaultValue, 'id');
      }

      case 'specificSchools':
      case 'exceptionAccounts': {
        const curValue = curState[key] ?? [];
        const defaultValue = defaultState[key] ?? [];
        return isEqualArray(curValue, defaultValue, 'id');
      }

      default: {
        const curValue = curState[key];
        const defaultValue = defaultState[key];
        return isEqual(curValue, defaultValue);
      }
    }
  });
