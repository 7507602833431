import React, { Fragment } from 'react';
import { isEmpty } from 'lodash';

import ActivityItem from '../ActivityItem';
import { ATHLETIC, NON_ATHLETIC } from '../../constants';

const getRootActivity = ({ athletic, activities, checkedIds }) => ({
  activity: athletic ? ATHLETIC : NON_ATHLETIC,
  indeterminate: activities.some(activity => checkedIds.includes(activity.id)),
  checked:
    !isEmpty(activities) &&
    activities.every(activity => checkedIds.includes(activity.id))
});

const ActivityList = ({
  athletic,
  activities,
  checkedIds,
  editable,
  onCheck,
  collapse,
  onCollapse
}) => {
  if (isEmpty(activities)) return null;

  const rootActivity = getRootActivity({
    athletic,
    activities,
    checkedIds
  });

  return (
    <Fragment>
      {(editable || rootActivity.indeterminate) && (
        <ActivityItem
          {...rootActivity}
          level={0}
          collapse={collapse}
          editable={editable}
          onCollapse={onCollapse}
          onCheck={onCheck}
        />
      )}
      {collapse &&
        activities.map(activity => {
          const checked = checkedIds.includes(activity.id);
          return (
            (editable || checked) && (
              <ActivityItem
                level={1}
                key={activity.id}
                checked={checked}
                editable={editable}
                activity={activity}
                onCheck={onCheck}
              />
            )
          );
        })}
    </Fragment>
  );
};

ActivityList.defaultProps = {
  checkedIds: [],
  activities: [],
  collapse: false,
  athletic: false,
  editable: false,
  onCheck: () => null,
  onCollapse: () => null
};

export default ActivityList;
