import { connect } from 'react-redux';

import { Switch, Route } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import { PAGES } from '@config/routes';

import { setDataToSetupMultiSeason, resetSeasonManagementState } from '@season-management/middleware/actions';

import type { ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';

const mapStateToProps = () => ({
  // map state to props
});

const mapDispatchToProps = {
  setDataToSetupMultiSeason,
  resetSeasonManagementState
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const CreateView = lazy(() =>
  import('./views/Create.view').then(module => ({
    default: module.CreateView
  }))
);

export type CreateContainerProps = ConnectedProps<typeof connector> & RouteComponentProps;

const CreateContainer = (props: CreateContainerProps) => (
  <Switch>
    <Route
      path={PAGES.create.root.path}
      render={() => (
        <Suspense fallback={<div />}>
          <CreateView {...props} />
        </Suspense>
      )}
    />
  </Switch>
);

export default connector(CreateContainer);
