/* eslint-disable import/no-unresolved */
import { call, put, takeEvery, delay, takeLatest } from 'redux-saga/effects';
import { ADD_MESSAGE, CHECK_SERVER_ON_MAINTENANCE } from './actionTypes';
import { removeMessage } from './actions';
import AppService from '../api/services/AppService';
import { PAGES } from '../config/routes';
import { config } from '@gofan/constants';
import ErrorDTO from '../api/dto/ErrorDTO';
import { isEmpty } from '../utils/objectUtils';
import { MAINTENANCE_MODE } from '../api/api/constants';

function* addMessage(action) {
  const { message } = action.payload;
  yield delay(5000);
  yield put(removeMessage(message.id));
}

export function* addMessageSaga() {
  yield takeEvery(ADD_MESSAGE, addMessage);
}

export function* checkServerOnMaintenance(action) {
  const { history } = action.payload;
  const response = yield call(AppService.checkHealth, true);

  if (
    isEmpty(history) ||
    (response instanceof ErrorDTO && !isEmpty(response.data) && response.data.mode === MAINTENANCE_MODE)
  ) {
    return;
  }

  const status = yield call(AppService.checkStatusOfHealthChecking, response, config.IGNORE_HEALTH_CHECK_FOR_REDIS);

  if (status) {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.replace(PAGES.home.root.path);
    }
  } else {
    history.replace(PAGES.error.error503.path);
  }
}

export function* onCheckServerOnMaintenanceSaga() {
  yield takeLatest(CHECK_SERVER_ON_MAINTENANCE, checkServerOnMaintenance);
}

export default [addMessageSaga(), onCheckServerOnMaintenanceSaga()];
