import moment from 'moment';
import PublishLevelRequest from './PublishLevelRequest';
import PublishTicketTypeRequest from './PublishTicketTypeRequest';
import EventAccountsTicket from '../EventAccountsTicket';

import { isEmpty } from '../../../utils/objectUtils';
import { parseString, parseNumber } from '../../../utils/parseUtils';
import { formatDateTimeZone, TIME_ZONE_ENUM } from '../../../utils/dateUtils';

export default class PublishEditEventRequest {
  constructor({
    // event id
    id,

    // required
    genders,
    allDayEvent,
    opponentAccountId,
    hostAccountId,
    financialAccountId,
    taggedAccountIds,
    levels,
    startDateTime,
    startTimeType,
    startTimeOptions,
    endDateTime,
    accountId,
    activityId,
    reportingLabel,
    name,
    venueCity,
    venueAddress,
    venueLocation,
    venueName,
    venueState,
    venueZip,
    venueId,
    eventTypeId,
    eventValidationStartsBefore,
    enableEventValidation,
    redemptionWindow,
    eventIntegrationDetails,

    // optional
    archived,
    publishDateTime,
    salesStartDateTime,
    salesEndDateTime,
    alert,
    theme,
    description,
    customSportName,
    featuredAccountIds,
    ticketLimitPerOrder,
    paymentCycle,
    glCode,
    pixellotKey,

    // ticketTypes
    activityPath,
    maxCapacity,
    products,
    accountsTicket,
    ticketDistribution,

    // additional
    featured,
    disableQr,
    postSeason,
    timeZone,
    specialEventDescription,
    disabledForIndividualSale,

    // Please don't take these fields
    // We declare to take them from rest
    restData,
    account,
    financialAccount,
    opponentAccount,
    hostAccount,
    taggedAccounts,
    distributedAccounts,
    activity,
    eventSalesInfo,
    activityType,
    eventName,
    isSellingNow,
    embedded,
    createdAt,
    _embedded,
    grossToAccount,
    ticketGrossCount,
    playOffEventPermission,
    ...rest
  }) {
    this.raw = {
      ...rest,
      id,
      // required
      allDayEvent,
      opponentAccountId: parseString(opponentAccountId),
      hostAccountId: parseString(hostAccountId),
      financialAccountId: parseString(financialAccountId),
      taggedAccountIds: isEmpty(taggedAccountIds) ? [] : taggedAccountIds,
      levels: isEmpty(levels) ? [] : levels,
      genders: isEmpty(genders) ? [] : genders,
      startDateTime: !startDateTime ? undefined : startDateTime,
      startTimeType,
      startTimeOptions,
      endDateTime: !endDateTime ? undefined : endDateTime,
      accountId: parseString(accountId),
      activityId: parseNumber(activityId),
      activityPath,
      createdAt: parseString(createdAt),
      reportingLabel: parseString(reportingLabel),
      name: parseString(name),
      venueCity: parseString(venueCity),
      venueAddress: parseString(venueAddress),
      venueLocation: parseString(venueLocation),
      venueName: parseString(venueName),
      venueState: parseString(venueState),
      venueZip: parseString(venueZip),
      venueId: parseNumber(venueId),
      eventTypeId: parseNumber(eventTypeId),
      eventValidationStartsBefore: parseNumber(eventValidationStartsBefore),
      enableEventValidation,
      redemptionWindow,
      eventIntegrationDetails: isEmpty(eventIntegrationDetails) ? [] : eventIntegrationDetails,

      // optional
      archived,
      publishDateTime: !publishDateTime ? undefined : publishDateTime,
      salesStartDateTime: !salesStartDateTime ? undefined : salesStartDateTime,
      salesEndDateTime: !salesEndDateTime ? undefined : salesEndDateTime,
      alert: parseString(alert),
      theme: parseString(theme),
      description: parseString(description),
      customSportName: parseString(customSportName),
      featuredAccountIds: isEmpty(featuredAccountIds) ? [] : featuredAccountIds,
      ticketLimitPerOrder: parseNumber(ticketLimitPerOrder),
      paymentCycle: parseString(paymentCycle),
      glCode: glCode || null,
      pixellotKey: pixellotKey || undefined,

      // ticketTypes
      maxCapacity: parseNumber(maxCapacity),
      products: isEmpty(products) ? [] : products,
      accountsTicket: isEmpty(accountsTicket) ? [] : accountsTicket,
      ticketDistribution,

      // additional
      featured,
      disableQr: disableQr ?? true,
      postSeason,
      timeZone: parseString(timeZone),
      specialEventDescription: parseString(specialEventDescription),
      disabledForIndividualSale
    };
  }

  // event id
  get id() {
    return this.raw.id || undefined;
  }

  // required
  get allDayEvent() {
    return this.raw.allDayEvent;
  }

  get opponentAccountId() {
    return isEmpty(this.raw.opponentAccountId) ? undefined : this.raw.opponentAccountId;
  }

  get hostAccountId() {
    return isEmpty(this.raw.hostAccountId) ? undefined : this.raw.hostAccountId;
  }

  get financialAccountId() {
    return isEmpty(this.raw.financialAccountId) ? undefined : this.raw.financialAccountId;
  }

  get taggedAccountIds() {
    return this.raw.taggedAccountIds;
  }

  get levels() {
    return this.raw.levels.map(level => new PublishLevelRequest(level));
  }

  get genders() {
    return this.raw.genders;
  }

  get startDateTime() {
    if (!this.raw.startDateTime) return undefined;
    if (moment.isMoment(this.raw.startDateTime))
      return formatDateTimeZone({
        date: this.raw.startDateTime,
        timeZone: this.timeZone
      });
    return this.raw.startDateTime;
  }

  get startTimeType() {
    return this.raw.startTimeType;
  }

  get startTimeOptions() {
    return this.raw.startTimeOptions;
  }

  get endDateTime() {
    if (!this.raw.endDateTime) return undefined;
    if (moment.isMoment(this.raw.endDateTime))
      return formatDateTimeZone({
        date: this.raw.endDateTime,
        timeZone: this.timeZone
      });
    return this.raw.endDateTime;
  }

  get accountId() {
    return isEmpty(this.raw.accountId) ? undefined : this.raw.accountId;
  }

  get activityId() {
    return this.raw.activityId;
  }

  get activityPath() {
    return this.raw.activityPath;
  }

  get createdAt() {
    return this.raw.createdAt;
  }

  get reportingLabel() {
    return isEmpty(this.raw.reportingLabel) ? undefined : this.raw.reportingLabel;
  }

  get name() {
    return this.raw.name;
  }

  get venueCity() {
    return this.raw.venueCity;
  }

  get venueAddress() {
    return this.raw.venueAddress;
  }

  get venueLocation() {
    return this.raw.venueLocation;
  }

  get venueName() {
    return this.raw.venueName;
  }

  get venueState() {
    return this.raw.venueState;
  }

  get venueZip() {
    return this.raw.venueZip;
  }

  get venueId() {
    return this.raw.venueId;
  }

  get eventTypeId() {
    return this.raw.eventTypeId;
  }

  get eventValidationStartsBefore() {
    return this.raw.eventValidationStartsBefore;
  }

  get enableEventValidation() {
    return this.raw.enableEventValidation;
  }

  get redemptionWindow() {
    return this.raw.redemptionWindow;
  }

  // optional
  get archived() {
    return this.raw.archived;
  }

  get publishDateTime() {
    if (!this.raw.publishDateTime) return undefined;
    if (moment.isMoment(this.raw.publishDateTime))
      return formatDateTimeZone({
        date: this.raw.publishDateTime,
        timeZone: this.timeZone
      });
    return this.raw.publishDateTime;
  }

  get salesStartDateTime() {
    if (!this.raw.salesStartDateTime) return undefined;
    if (moment.isMoment(this.raw.salesStartDateTime))
      return formatDateTimeZone({
        date: this.raw.salesStartDateTime,
        timeZone: this.timeZone
      });
    return this.raw.salesStartDateTime;
  }

  get salesEndDateTime() {
    if (!this.raw.salesEndDateTime) return undefined;
    if (moment.isMoment(this.raw.salesEndDateTime))
      return formatDateTimeZone({
        date: this.raw.salesEndDateTime,
        timeZone: this.timeZone
      });
    return this.raw.salesEndDateTime;
  }

  get alert() {
    return isEmpty(this.raw.alert) ? undefined : this.raw.alert;
  }

  get theme() {
    return isEmpty(this.raw.theme) ? undefined : this.raw.theme;
  }

  get description() {
    return isEmpty(this.raw.description) ? undefined : this.raw.description;
  }

  get customSportName() {
    return isEmpty(this.raw.customSportName) ? undefined : this.raw.customSportName;
  }

  get featuredAccountIds() {
    return this.raw.featuredAccountIds;
  }

  get ticketLimitPerOrder() {
    return this.raw.ticketLimitPerOrder;
  }

  get paymentCycle() {
    return this.raw.paymentCycle;
  }

  get glCode() {
    return this.raw.glCode;
  }

  get pixellotKey() {
    return this.raw.pixellotKey;
  }

  // ticketTypes
  get maxCapacity() {
    return this.raw.maxCapacity;
  }

  get products() {
    return this.raw.products.map(product => new PublishTicketTypeRequest(product));
  }

  set products(products) {
    this.raw.products = products;
  }

  get accountsTicket() {
    return this.raw.accountsTicket.map(item => new EventAccountsTicket(item));
  }

  get ticketDistribution() {
    return this.raw.ticketDistribution;
  }

  // additional
  get featured() {
    return this.raw.featured;
  }

  get disableQr() {
    return this.raw.disableQr;
  }

  get postSeason() {
    return this.raw.postSeason;
  }

  get timeZone() {
    return !this.raw.timeZone ? TIME_ZONE_ENUM.AMERICA_NEW_YORK : this.raw.timeZone;
  }

  get specialEventDescription() {
    return isEmpty(this.raw.specialEventDescription) ? undefined : this.raw.specialEventDescription;
  }

  get disabledForIndividualSale() {
    return this.raw.disabledForIndividualSale;
  }

  get eventIntegrationDetails() {
    return this.raw.eventIntegrationDetails.map(item => ({
        ...item,
      }));
  }

  toJSON(publish) {
    return {
      ...this.raw,
      id: this.id,
      // required
      genders: this.genders,
      allDayEvent: this.allDayEvent,
      opponentAccountId: this.opponentAccountId,
      hostAccountId: this.hostAccountId,
      financialAccountId: this.financialAccountId,
      taggedAccountIds: this.taggedAccountIds,
      levels: this.levels.map(level => level.toJSON()),
      startDateTime: this.startDateTime,
      startTimeType: this.startTimeType,
      startTimeOptions: this.startTimeOptions,
      endDateTime: this.endDateTime,
      accountId: this.accountId,
      activityId: this.activityId,
      activityPath: this.activityPath,
      createdAt: this.createdAt,
      reportingLabel: this.reportingLabel,
      name: this.name,
      venueCity: this.venueCity,
      venueAddress: this.venueAddress,
      venueLocation: this.venueLocation,
      venueName: this.venueName,
      venueState: this.venueState,
      venueZip: this.venueZip,
      venueId: this.venueId,
      eventTypeId: this.eventTypeId,
      eventValidationStartsBefore: this.eventValidationStartsBefore,
      enableEventValidation: this.enableEventValidation,
      redemptionWindow: this.redemptionWindow,
      eventIntegrationDetails: this.eventIntegrationDetails,

      // optional
      archived: this.archived,
      publishDateTime: this.publishDateTime,
      salesStartDateTime: this.salesStartDateTime,
      salesEndDateTime: this.salesEndDateTime,
      alert: this.alert,
      theme: this.theme,
      description: this.description,
      customSportName: this.customSportName,
      featuredAccountIds: this.featuredAccountIds,
      ticketLimitPerOrder: this.ticketLimitPerOrder,
      paymentCycle: this.paymentCycle,
      glCode: this.glCode,
      pixellotKey: this.pixellotKey,

      // ticketTypes
      maxCapacity: this.maxCapacity,
      products: this.products.map(product => product.toJSON(publish)),
      accountsTicket: this.accountsTicket.map(item => item.toJSON(publish)),
      ticketDistribution: this.ticketDistribution,

      // additional
      featured: this.featured,
      disableQr: this.disableQr,
      postSeason: this.postSeason,
      timeZone: this.timeZone,
      specialEventDescription: this.specialEventDescription,
      disabledForIndividualSale: this.disabledForIndividualSale
    };
  }
}
