import { useState, useEffect } from 'react';
import clx from 'classnames';
import { isEmpty } from 'lodash';
import { MultiSelect } from '@gofan/components';
import type { MultiSelectProps } from '@gofan/components';

import './multi-select.component.scss';

export type BasicMultiSelectConfig<ItemType = string> = MultiSelectProps & {
  items: ItemType[];
  itemToString?(items: any): string;
  label: string;
  titleText: string;
  hasValueInitial: boolean;
};

export type BasicMultiSelectProps<ItemType = string> = BasicMultiSelectConfig & {
  name: string;
  control: any;
  context: any;
  initialValue: ItemType;
  fieldValue: ItemType;
  fieldError?: string;
  getFieldRef?: () => void;
};

const BasicMultiSelect = ({
  className = '',
  name,
  titleText,
  label = '',
  items = [],
  itemToString,
  hasValueInitial,
  initialValue,
  fieldValue,
  fieldError,
  getFieldRef,
  control,
  context,
  ...other
}: BasicMultiSelectProps) => {
  const [key, setKey] = useState<number>();

  useEffect(() => {
    setKey(new Date().getTime());
  }, [hasValueInitial]);

  return (
    <MultiSelect
      {...other}
      {...control}
      id={`field-${name}`}
      className={clx('gf--multi-select-form-field', className)}
      key={key}
      ref={getFieldRef}
      initialSelectedItems={fieldValue}
      itemToString={itemToString}
      items={items}
      label={label}
      titleText={titleText}
      invalidText={fieldError}
      invalid={!isEmpty(fieldError)}
    />
  );
};

export default BasicMultiSelect;
