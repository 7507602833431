import React from 'react';
import { isEmpty } from 'lodash';
import ColorPilette, { ColorPiletteProps } from '@common/color-pilette/color-pilette.component';

export type BasicColorPickerConfig = ColorPiletteProps;

export type BasicColorPickerProps = BasicColorPickerConfig & {
  name: string;
  control: any;
  initialValue: string;
  fieldValue: string;
  fieldError?: string;
  disabled: boolean;
  getFieldRef?: () => void;
};

const BasicColorPicker = ({
  name,
  initialValue,
  fieldValue,
  fieldError,
  getFieldRef,
  control,
  disabled = false,
  colors,
  ...other
}: BasicColorPickerProps) => (
  <ColorPilette
    {...other}
    {...control}
    id={`field-${name}`}
    ref={getFieldRef}
    defaultColor={!isEmpty(initialValue) ? initialValue : fieldValue}
    colors={colors}
    disabled={disabled}
    invalidText={fieldError}
    invalid={!isEmpty(fieldError)}
  />
);

export default BasicColorPicker;
