import { useQuery } from '@tanstack/react-query';
import { AccountFinancialService } from './account-financial.service';
import { GET_ACCOUNT_FINANCIAL_BY_ACCOUNT_ID } from './account-financial.endpoints';

import type { AccountFinancialDTO } from './account-financial.model';
import type { UseAccountOptions } from '@gofan/api/accounts';

export const useAccountFinancial = ({ accountId, queryOptions = {} }: UseAccountOptions) => {
  const accountFinancialQuery = useQuery<AccountFinancialDTO>({
    queryKey: [GET_ACCOUNT_FINANCIAL_BY_ACCOUNT_ID, accountId],
    queryFn: () => AccountFinancialService.getAccountFinancialByAccountId(accountId),
    ...queryOptions
  });

  return { ...accountFinancialQuery };
};

export const useAccountFinancialWithErrors = ({ accountId, queryOptions = {} }: UseAccountOptions) => {
  const accountFinancialQuery = useQuery<AccountFinancialDTO>({
    queryKey: [GET_ACCOUNT_FINANCIAL_BY_ACCOUNT_ID, accountId],
    queryFn: () => AccountFinancialService.getAccountFinancialByAccountIdWithErrors(accountId),
    ...queryOptions
  });

  return { ...accountFinancialQuery };
};
