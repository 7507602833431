// @flow
import { isEmpty } from '../utils/objectUtils';
import * as validations from './validations';
import { topLevelDomains } from './topLevelDomains';
import {
  COMP_MOBILE_PASS_LIMIT_VALIDATION,
  COMP_TICKETS_LIMIT_VALIDATION,
  COMP_SEASON_TICKETS_LIMIT_VALIDATION
} from './constants';

export const RANGE_VALUES_VALIDATION = 'range.value.validation';
export const MAX_VALUE_VALIDATION = 'max.value.validation';
export const MIN_VALUE_VALIDATION = 'min.value.validation';
export const MAX_LENGTH_VALIDATION = 'max.length.validation';
export const MIN_LENGTH_VALIDATION = 'min.length.validation';
export const ALPHANUMERIC_CHARACTERS_VALIDATION =
  'alphanumeric.characters.validation';
export const ALPHANUMERIC_SPECIAL_CHARACTERS_VALIDATION =
  'alphanumeric.special_characters.validation';
export const EMAIL_VALIDATION = 'email.validation';
export const REGEX_VALIDATION = 'regex.validation';
export const NAME_VALIDATION = 'name.validation';
export const REQUIRE_VALIDATION = 'require.validation';
export const PHONE_VALIDATION = 'phone.validation';

export const MESSAGES_VALIDATION = {
  EVENT_CAPACITY_LIMIT_VALIDATION:
    'Event Capacity must be in range (1-9999999).',
  EVENT_CAPACITY_SOLD_VALIDATION:
    'The entered value should be greater or equal to the number of tickets has been sold.',
  TICKETS_CAPACITY_LIMIT_VALIDATION:
    'Ticket Limit must be in range (1-9999999).',
  TICKETS_CAPACITY_SOLD_VALIDATION:
    'The entered value should be greater or equal to the number of tickets has been sold.',
  COMP_SEASON_TICKETS_EXCEED_REQUEST_LIMIT:
    `Only ${COMP_SEASON_TICKETS_LIMIT_VALIDATION.MAX} tickets can be generated at a time.`,
  COMP_TICKETS_EXCEED_REQUEST_LIMIT:
    `Only ${COMP_TICKETS_LIMIT_VALIDATION.MAX} tickets can be generated at a time.`,
  COMP_TICKETS_EXCEED_AVAILABLE_TICKET:
    (availableTickets) => `Only ${availableTickets} tickets left. Please adjust number of tickets to continue.`,
  COMP_MOBILE_PASS_EXCEED_REQUEST_LIMIT:
    `Only ${COMP_MOBILE_PASS_LIMIT_VALIDATION.MAX} passes can be generated at a time.`,
  COMP_MOBILE_PASS_EXCEED_AVAILABLE_TICKET:
    (availableTickets) => `Only ${availableTickets} passes left. Please adjust number of passes to continue.`,
  COMP_QUANTITY_REQUIRED: 'Quantity is required.',
  COMP_QUANTITY_MINIMUM: (minimun) => `Quanity minimum is ${minimun}`,
  RATE_PERCENT_LIMIT_VALIDATION: 'You can enter number in range (0-100).',
  CUSTOM_EVENT_NAME_ALPHANUMEMRIC_CHARACTERS_VALIDATION: `This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (\`()./_-#'@&*+",:;?!) with a maximum of 35 characters long.`,
  EVENT_THEME_ALPHANUMERIC_CHARACTERS_VALIDATION:
    'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) with a maximum of 35 characters long.',
  ACCOUNT: {
    FIRST_NAME_REQUIRED: 'First name is required.',
    FIRST_NAME_INVALID:
      'First name should not contain special characters or numbers.',
    LAST_NAME_REQUIRED: 'Last name is required.',
    LAST_NAME_INVALID:
      'Last name should not contain special characters or numbers.',
    EMAIL_REQUIRED: 'Email address is required.',
    EMAIL_INVALID: 'Enter a valid email address.',
    EMAIL_INVALID_FORMAT: 'Enter a valid email address.',
    TITLE_AT_LEAST_TWO_CHARACTERS: 'Title must contain at least 2 characters.',
    PHONE_NUMBER_INVALID: 'Enter a valid phone number.',
    PHONE_NUMBER_TYPE_REQUIRED: 'Phone number type is required.'
  },
  NUMBER_OF_EVENTS: {
    NUMBER_OF_EVENTS_REQUIRED: 'Number of events is required.',
    NUMBER_OF_EVENTS_RANGE_VALUE:
      'The entered value should be greater or equal to the number of events has been added.'
  }
};

const validationMethods = {
  [REQUIRE_VALIDATION]: (value) => {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'string') {
      return value.trim() !== '';
    }

    if (typeof value === 'object') {
      return value.length > 0;
    }

    if (typeof value === 'number') {
      return true;
    }

    return false;
  },
  [RANGE_VALUES_VALIDATION]: (value, config) => {
    const { min, max } = config;
    if (isEmpty(value)) {
      return false;
    }
    if (isEmpty(max)) {
      return Number.parseFloat(value, 10) >= min;
    }
    if (isEmpty(min)) {
      return Number.parseFloat(value, 10) <= max;
    }
    return (
      Number.parseFloat(value, 10) >= min && Number.parseFloat(value, 10) <= max
    );
  },
  [MAX_LENGTH_VALIDATION]: (value, config) => {
    const { maxLength } = config;
    if (typeof value === 'string') {
      return value.trim().length <= maxLength;
    }
    return false;
  },
  [MIN_LENGTH_VALIDATION]: (value, config) => {
    const { minLength } = config;
    if (typeof value === 'string') {
      return value.trim().length >= minLength;
    }
    return false;
  },
  [ALPHANUMERIC_CHARACTERS_VALIDATION]: (value) => validations.alphanumericValidation(value),
  [ALPHANUMERIC_SPECIAL_CHARACTERS_VALIDATION]:
    (value) => validations.alphanummericSpecialCharactersValidation(value),
  [EMAIL_VALIDATION]: (email) => {
    const valid = validations.email(email);
    if (valid) {
      const topLevelDomain = email
        .split('.')
        .pop()
        .toUpperCase();
      return topLevelDomains.includes(topLevelDomain);
    }
    return valid;
  },

  [REGEX_VALIDATION]: (value, pattern) => {
    const regex = new RegExp(pattern);
    return regex.test(value);
  },
  [NAME_VALIDATION]: (value) => validations.name(value),
  [PHONE_VALIDATION]: (phone) => validations.phone(phone)
};

const validate = (value, type, config) => validationMethods[type](value, config);

export const executeValidationRules = (rules, value) => {
  for (let i = 0; i < rules.length; i += 1) {
    if (!validate(value, rules[i].validation, rules[i].config)) {
      return rules[i].message;
    }
  }

  return '';
};
