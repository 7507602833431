export const stringToHex = (input: string) => {
  let result = '';
  for (let i = 0; i < input.length; i += 1) {
    result += input.charCodeAt(i).toString(16);
  }
  return result;
};

export const isStringMatchKeyword = (value: string, keyword: string) => {
  const newString = value?.toLowerCase();
  const newKeyword = keyword?.toLowerCase();
  return newString?.indexOf(newKeyword) === 0;
};

export const isStringIncludeKeyword = (value: string | undefined | null, keyword: string | undefined | null) => {
  if (value === undefined || value === null) return false;
  if (keyword === undefined || keyword === null) return false;
  return String(value).toLowerCase().includes(String(keyword).toLowerCase());
};

export const isStringsIncludeKeyword = (values: Array<string | null | undefined>, keyword: string | undefined | null) =>
  values.some(value => isStringIncludeKeyword(value, keyword));

export const isEmptyString = str =>
  str === null || str === undefined || (typeof str !== 'string' && typeof str !== 'number') || str === '';

export const truncateString = (string = '', length = 30) => {
  if (string.length > length) {
    return `${string.slice(0, 36).trimEnd()}...`;
  }

  return string;
};

export const formatUSPhoneNumber = (value: string, international = false) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  if (phoneNumber.length < 4) return phoneNumber;

  const prefix = international ? `(${phoneNumber.slice(0, 3)}) ` : `${phoneNumber.slice(0, 3)}-`;
  if (phoneNumber.length < 7) {
    return `${prefix}${phoneNumber.slice(3)}`;
  }
  return `${prefix}${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const formatHeightUS = (value: string, addingZero?: boolean): string => {
  // Ex: `52` => `5' 2`
  if (!value) return '';
  const height = value.replace(/[\D\s]/g, '');
  if (height.length < 2) return `${height}'${addingZero ? ' 0' : ''}`;
  return `${height.slice(0, 1)}' ${height.slice(1, 2)}`;
};

export const feetStringToInches = (value: string | undefined): number => {
  // `5' 2"` => 62 (inches)
  if (!value) return 0;
  const tokens = value.replace(/\s+/g, '').split("'");
  const feet = Number(tokens[0]);
  const inches = tokens[1] ? Number(tokens[1].replace('"', '')) : 0;
  return Number(feet * 12 + inches);
};

export const inchesToFeetString = (heightInInches: number, withInchSymbol?: boolean): string =>
  // 62 (inches) => 52 (5' 2")
  `${Math.floor(heightInInches / 12)}' ${heightInInches % 12}${withInchSymbol ? '"' : ''}`;

export const normalizeName = (name: string): string => name.toLowerCase().replace(/\s+/g, '');

