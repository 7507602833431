import type { RefObject } from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import { get, omit, isEmpty, uniq, uniqBy, isEqual, isNil, differenceBy, upperFirst, union, capitalize } from 'lodash';

import { PAGES } from '@config/routes';
import {
  switchZone,
  getTimeZone,
  formatDateTime,
  EVENT_DATE_FORMAT,
  EVENT_DATE_FORMAT_WITH_TIMEZONE
} from '@app/utils/dateUtils';
import {
  generateEventName,
  generateDefaultEventName,
  getPartnerEventName,
  generateOpponentName
} from '@app/api/services/EventService';
import { AccountService } from '@gofan/api/accounts';
import type { GlobalEventDTO, SeasonProduct } from '@seasons/models/season.model';

import type RowEvent from '@api/model/RowEvent';
import type { LevelDTO } from '@gofan/api/levels';
import type { AccountDTO } from '@gofan/api/accounts';
import type { ActivityDTO } from '@gofan/api/activities';
import type { AccountProductDTO } from '@gofan/api/account-products';
import type {
  EventScheduleDTO,
  EventScheduleSeasonDTO
} from '@modules/event-integrations_V2/models/event-schedule.model';

import {
  GENDER_TYPE,
  GENDER_LABEL,
  CREATION_MODE,
  CREATION_PROCESS,
  DIRTY_EVENT_FIELDS,
  DIRTY_SEASON_FIELDS,
  DEFAULT_START_TIME,
  EVENT_START_TIME_TYPE,
  IMPORT_DATA_MODE,
  ENTERED_VALUE,
  PARTNER_IMPORT_MODE
} from '@season-management/constants/constant';
import { ARBITER_PARTNER_NAME, IGNORED_STATUS } from '@modules/event-integrations_V2/constants/constants';
import type { ActivedSport, GroupTicketType, GroupTicketTypeBySport } from '@season-management/middleware/types';
import type {
  LevelsByGender,
  TimeWithPeriod,
  StartTimeTeams,
  StartTimeOptions
} from '@season-management/middleware/models/model';
import RawProductModel from '@season-management/middleware/models/raw-product.model';
import type { RawEvent } from '@season-management/middleware/models/raw-event.model';
import RawEventModel from '@season-management/middleware/models/raw-event.model';
import type { RawSeason } from '@season-management/middleware/models/raw-season.model';
import RawSeasonModel from '@season-management/middleware/models/raw-season.model';
import type { CreatingSeason, FileUploadSetting } from '@season-management/middleware/models/creating-season.model';
import CreatingSeasonModel from '@season-management/middleware/models/creating-season.model';
import type { VenueInformationValue } from '@season-management/pages/season-general-setup/season-venue-section/season-venue-section.component';
import { DISTRIBUTION_CHANNEL } from '@app/api/model/request/ProductRequest';
import { DEFAULT_BROADCAST_EVENT_VALUES } from '@gofan/constants/unity';
import type { EventIntegrationDetails, SeasonalPeriodType } from '@gofan/api';
import { findEarliestTime } from '@gofan/utils/date';

class SeasonCreationUtil {
  filterOutDeletedRawEvent = (creatingSeasonList: CreatingSeason[]): CreatingSeason[] => {
    if (isEmpty(creatingSeasonList)) return [];
    return creatingSeasonList.map(item => ({
      ...item,
      rawSeasons: (item.rawSeasons ?? []).map(season => ({
        ...season,
        rawEvents: (season?.rawEvents ?? []).filter(event => !event.deleted)
      }))
    }));
  };

  getEnteredValue = (modeImportData: string) => {
    if (modeImportData === IMPORT_DATA_MODE.FILE_UPLOAD) {
      return ENTERED_VALUE.FILE_UPLOAD;
    }
    if (modeImportData === IMPORT_DATA_MODE.MANUAL) {
      return ENTERED_VALUE.MANUAL_INPUT;
    }
    if (modeImportData === IMPORT_DATA_MODE.FROM_ARBITER) {
      return ENTERED_VALUE.ARBITER_IMPORT;
    }
    if (modeImportData === PARTNER_IMPORT_MODE) {
      return ENTERED_VALUE.ARBITER_IMPORT;
    }
    return undefined;
  };

  getDefaultCreatingSeasonBy = ({ activedSport }: { activedSport: ActivedSport }) => {
    const { genders, levelsByGender } = this.generateDefaultGender({ activedSport });
    const seasonId = this.getSeasonId({ sportId: `${activedSport?.id}`, creationMode: CREATION_MODE.ONE_PER_SPORT });
    return {
      ...activedSport,
      creationProcess: CREATION_PROCESS.GENERAL_SETUP,

      // Data for Step 1
      name: '',
      creationMode: CREATION_MODE.ONE_PER_SPORT, // [1PerSport, SeperateEachTeam]
      genders, // [Boys, Girls, Coed]
      levelsByGender,
      genderSpecific: true,
      accountTicketTypes: [],
      seasonTickets: [],
      archived: false,
      publishDate: '',
      publishTime: '',
      eventsArchived: false,
      alert: '',
      venue: {},
      modeImportData: '', // [FileUpload, Manunal, FromArbiter, CopyFromSeason]
      eventStartTimeType: EVENT_START_TIME_TYPE.ALL_TEAMS,
      manualSetting: {
        numOfEvent: 1,
        startTime: {
          [seasonId]: {
            ...DEFAULT_START_TIME
          }
        }
      },

      // Data for Step 2
      rawSeasons: []
    };
  };

  initCreatingSeasonList = ({
    activedSport,
    storedCreatingSeasonList = []
  }: {
    activedSport: ActivedSport;
    storedCreatingSeasonList: CreatingSeason[];
  }): CreatingSeason[] => {
    const found =
      !isEmpty(activedSport) &&
      storedCreatingSeasonList.find((item: CreatingSeason) => `${item.id}` === `${activedSport?.id}`);

    if (isEmpty(activedSport) || found) return this.filterOutDeletedRawEvent(storedCreatingSeasonList);

    return this.filterOutDeletedRawEvent([
      ...storedCreatingSeasonList,
      this.getDefaultCreatingSeasonBy({ activedSport }) as any
    ]);
  };

  generateDefaultGender = ({ activedSport }: { activedSport: ActivedSport }) => {
    const levelsByGender = {
      [GENDER_TYPE.BOYS]: [],
      [GENDER_TYPE.GIRLS]: [],
      [GENDER_TYPE.COED]: []
    };
    const { sport } = activedSport ?? {};
    let genders: string[] = [];

    if (sport?.genderSpecific) {
      genders = [`${sport?.gender}`.toUpperCase()];
    }
    return {
      genders,
      levelsByGender
    };
  };

  initActivedSport = ({ sport, accountId, gender, levelId }: any): ActivedSport => ({
    id: sport?.id,
    sport,
    gender,
    levelId,
    accountId
  });

  getActivedSportBySeason = ({
    activedSport,
    creatingSeason
  }: {
    activedSport: ActivedSport;
    creatingSeason: CreatingSeason | null;
  }) => {
    if (isEmpty(creatingSeason) || creatingSeason?.creationMode === CREATION_MODE.ONE_PER_SPORT) {
      return activedSport;
    }
    if (
      creatingSeason?.creationProcess !== CREATION_PROCESS.GENERAL_SETUP &&
      creatingSeason?.creationMode === CREATION_MODE.SPERATE_EACH_TEAM
    ) {
      const foundCender = creatingSeason?.genders?.find(gender => `${gender}` === `${activedSport.gender}`);
      const gender = foundCender ?? creatingSeason?.genders?.[0];
      const foundLevelsByGender = creatingSeason?.levelsByGender?.[gender] ?? [];
      const foundLevel = foundLevelsByGender?.find(level => `${level.id}` === `${activedSport.levelId}`);
      const levelId = foundLevel?.id ?? foundLevelsByGender?.[0]?.id;
      return { ...activedSport, gender, levelId };
    }
    return activedSport;
  };

  getCreatingSeasonBySport = ({
    activedSport,
    creatingSeasonList
  }: {
    activedSport: ActivedSport | null;
    creatingSeasonList: CreatingSeason[];
  }) => creatingSeasonList.find((item: CreatingSeason) => `${item.id}` === `${activedSport?.id}`) ?? null;

  isActivedSeason = ({
    index,
    season,
    activedSport,
    creatingSeason
  }: {
    index: number;
    season: RawSeason;
    activedSport: ActivedSport;
    creatingSeason: CreatingSeason;
  }) => {
    if (`${activedSport?.id}` !== `${season.sportId}`) return false;
    if (creatingSeason?.creationMode === CREATION_MODE.ONE_PER_SPORT) return index === 0;
    if (creatingSeason?.creationMode === CREATION_MODE.SPERATE_EACH_TEAM) {
      return `${activedSport?.gender}` === `${season?.gender}` && `${activedSport?.levelId}` === `${season?.levelId}`;
    }
    return false;
  };

  getSeasonId = ({
    sportId,
    gender,
    levelId,
    creationMode
  }: {
    gender?: string;
    creationMode: string;
    sportId: string | number;
    levelId?: string | number;
  }) => {
    const seasonSportKey = `${sportId}`;
    if (creationMode === CREATION_MODE.ONE_PER_SPORT) return seasonSportKey;
    return `${seasonSportKey}${gender ? `-${gender}` : ''}${levelId ? `-${levelId}` : ''}`;
  };

  getTeamSeasonId = ({
    sportId,
    gender,
    levelId,
    eventStartTimeType
  }: {
    gender?: string;
    sportId: string | number;
    levelId?: string | number;
    eventStartTimeType?: string;
  }) => {
    const seasonSportKey = `${sportId}`;
    if (eventStartTimeType === EVENT_START_TIME_TYPE.ALL_TEAMS) return seasonSportKey;
    return `${seasonSportKey}${gender ? `-${gender}` : ''}${levelId ? `-${levelId}` : ''}`;
  };

  matchStartTimeId = ({
    startTimeId,
    sportId,
    eventStartTimeType,
    genders,
    levelsByGender
  }: {
    startTimeId: string;
    sportId: string | number;
    eventStartTimeType: string;
    genders: string[];
    levelsByGender: LevelsByGender;
  }) => {
    if (isEmpty(startTimeId)) return false;

    const splittedId = startTimeId.split('-');
    const testSportId = splittedId?.[0] ?? '';
    const testGender = splittedId?.[1] ?? '';
    const testLevelId = splittedId?.[2] ?? '';

    if (eventStartTimeType === EVENT_START_TIME_TYPE.ALL_TEAMS) {
      return `${testSportId}` === `${sportId}` && isEmpty(testGender) && isEmpty(testLevelId);
    }

    if (eventStartTimeType === EVENT_START_TIME_TYPE.EACH_TEAM) {
      if (`${testSportId}` !== `${sportId}` || !genders.includes(testGender) || isEmpty(testLevelId)) return false;

      const levels = get(levelsByGender, `${testGender}`) ?? [];
      return !!levels?.find((level: LevelDTO) => `${level.id}` === `${testLevelId}`);
    }

    return (
      `${testSportId}` === `${sportId}` && Object.values(GENDER_TYPE).includes(testGender) && !isEmpty(testLevelId)
    );
  };

  getNameByGenderLevel = (season: RawSeason, creatingSeason: CreatingSeason) => {
    if (creatingSeason?.creationMode === CREATION_MODE.ONE_PER_SPORT) return '';
    const gender = season?.gender ?? '';
    const levelId = season?.levelId ?? '';
    if (gender && creatingSeason?.creationMode === CREATION_MODE.SPERATE_EACH_TEAM) {
      const genderLabel = get(GENDER_LABEL, `${gender}`) ?? '';
      const foundLevel = season?.levelsByGender?.[gender]?.find(level => `${level.id}` === `${levelId}`);
      return `${genderLabel} ${foundLevel?.name ?? ''}`;
    }
    return '';
  };

  filterAccountTicketTypesBy = ({
    activityId,
    genders = [],
    levelsByGender = {},
    accountTicketTypes = [],
    isBoxOfficeAccount = true
  }: {
    genders: string[];
    activityId?: string | number;
    levelsByGender: LevelsByGender;
    accountTicketTypes: AccountProductDTO[];
    isBoxOfficeAccount?: boolean;
  }) => {
    const selectedLevels = uniqBy<LevelDTO>(
      genders.reduce((res: LevelDTO[], gender: string) => {
        const levels = levelsByGender?.[gender] ?? [];
        return [...res, ...levels];
      }, []),
      'id'
    );
    return accountTicketTypes
      .filter((ticket: AccountProductDTO) => {
        if (
          `${ticket.status}`.toLowerCase() === 'active' &&
          (activityId === undefined || `${activityId}` === `${ticket.activityId}`) &&
          ticket.levelIds?.some(levelId => selectedLevels.some(level => `${levelId}` === `${level.id}`)) &&
          (isBoxOfficeAccount ||
            !ticket.distributionChannel ||
            ticket.distributionChannel === DISTRIBUTION_CHANNEL.GOFAN)
        ) {
          return true;
        }
        return false;
      })
      .sort(
        (a: AccountProductDTO, b: AccountProductDTO) => parseFloat(`${b.ticketPrice}`) - parseFloat(`${a.ticketPrice}`)
      );
  };

  groupAccountTicketTypesBy = ({
    sportId,
    genders = [],
    ticketTypes = [],
    levelsByGender = {},
    creationMode = CREATION_MODE.ONE_PER_SPORT
  }: {
    genders: string[];
    creationMode: string;
    sportId: string | number;
    levelsByGender: LevelsByGender;
    ticketTypes: AccountProductDTO[];
  }) => {
    const results: GroupTicketType[] = [];

    if (creationMode === CREATION_MODE.ONE_PER_SPORT) {
      results.push({
        id: this.getSeasonId({ sportId, creationMode }),
        ticketTypes: ticketTypes
          .filter((ticket: any) =>
            ticket.levelIds.some((levelId: string) =>
              genders.some((gender: string) => {
                const levels = get(levelsByGender, gender) ?? [];
                return levels.map((level: LevelDTO) => `${levelId}` === `${level.id}`);
              })
            )
          )
          .sort(
            (a: AccountProductDTO, b: AccountProductDTO) =>
              parseFloat(`${b.ticketPrice}`) - parseFloat(`${a.ticketPrice}`)
          )
      });
    } else if (creationMode === CREATION_MODE.SPERATE_EACH_TEAM) {
      genders.forEach((gender: string) => {
        const levels = get(levelsByGender, gender) ?? [];
        levels.forEach((level: LevelDTO) => {
          results.push({
            id: this.getSeasonId({ sportId, gender, creationMode, levelId: level.id }),
            level,
            gender,
            ticketTypes: ticketTypes
              .filter((ticket: any) => ticket.levelIds.some((levelId: string) => `${levelId}` === `${level.id}`))
              .sort(
                (a: AccountProductDTO, b: AccountProductDTO) =>
                  parseFloat(`${b.ticketPrice}`) - parseFloat(`${a.ticketPrice}`)
              )
          });
        });
      });
    }
    return results;
  };

  groupTicketTypePerSport = ({
    selectedSports,
    multiSportConfig,
    accountTicketTypes
  }: {
    multiSportConfig: any;
    selectedSports: ActivityDTO[];
    accountTicketTypes: AccountProductDTO[];
  }) => {
    const results: GroupTicketTypeBySport[] = [];
    const creationMode = CREATION_MODE.ONE_PER_SPORT;
    const uploadedTeams = multiSportConfig?.fileUploadSetting?.uploadedTeams ?? [];

    selectedSports.forEach(sport => {
      const { id: sportId } = sport;
      const found = uploadedTeams.find((item: any) => `${item.sportId}` === `${sportId}`);
      const genders = found?.genders ?? [];
      const levelsByGender = found?.levelsByGender ?? {};

      const ticketTypes = this.filterAccountTicketTypesBy({
        activityId: sportId,
        genders,
        levelsByGender,
        accountTicketTypes
      });
      const groupedAccountTicketTypes = this.groupAccountTicketTypesBy({
        sportId,
        genders,
        ticketTypes,
        levelsByGender,
        creationMode
      });

      results.push({
        sportId,
        groupedAccountTicketTypes
      });
    });
    return results;
  };

  groupLevelGenderByUploadMultiSport = ({
    uploadedTeams = {},
    rowEvent
  }: {
    uploadedTeams: any;
    rowEvent: RowEvent;
  }) => {
    const { sport: sportId, genders: rowEventGenders, levels: rowEventLevelIds } = rowEvent ?? {};
    const curTeam = get(uploadedTeams, `${sportId}`, {}) ?? {};
    let genders: string[] = [...(curTeam?.genders ?? [])];
    let levelsByGender = { ...(curTeam?.levelsByGender ?? {}) } as LevelsByGender;

    const rowGenders = `${rowEventGenders ?? ''}`.split(',');
    const rowLevelIds = `${rowEventLevelIds ?? ''}`
      .split(',')
      .map(rowLevelId => `${rowLevelId ?? ''}`.trim())
      .filter(rowLevelId => !isEmpty(rowLevelId) && rowLevelId !== 'NULL');

    rowGenders.forEach(rowGender => {
      let uploadedGender = `${rowGender ?? ''}`.trim().toUpperCase();
      if (isEmpty(uploadedGender) || uploadedGender === 'NULL') {
        uploadedGender = GENDER_TYPE.COED;
      }

      genders = uniq([...genders, uploadedGender]);

      rowLevelIds.forEach(rowLevelId => {
        const rowLevel: LevelDTO = { id: rowLevelId, name: rowLevelId, disabled: false };
        levelsByGender = {
          ...levelsByGender,
          [uploadedGender]: uniqBy([...(levelsByGender?.[uploadedGender] ?? []), ...[rowLevel]], 'id')
        };
      });
    });

    return {
      ...uploadedTeams,
      [sportId]: {
        sportId,
        genders,
        levelsByGender
      }
    };
  };

  updateCreatingSeasonListBySports = ({
    selectedSports,
    storedCreatingSeasonList = []
  }: {
    selectedSports: ActivityDTO[];
    storedCreatingSeasonList: CreatingSeason[];
  }) => {
    if (isEmpty(selectedSports)) return [];
    return storedCreatingSeasonList.filter(
      (item: CreatingSeason) => item.created || selectedSports.some(sport => `${item.id}` === `${sport.id}`)
    );
  };

  updateCreatingSeasonList = ({
    updatedSeason,
    storedCreatingSeasonList = []
  }: {
    updatedSeason: CreatingSeason;
    storedCreatingSeasonList: CreatingSeason[];
  }) =>
    storedCreatingSeasonList.map((item: CreatingSeason) => {
      if (`${item.id}` === `${updatedSeason?.id}`) {
        return { ...item, ...updatedSeason };
      }
      return { ...item };
    });

  getCreatingSeason = (activedSport: ActivedSport, creatingSeasonList: CreatingSeason[] = []) =>
    creatingSeasonList.find((item: CreatingSeason) => `${item.id}` === `${activedSport?.id}`);

  checkDirtyCreatingSeason = ({
    creatingSeason,
    defaultSeasonList
  }: {
    creatingSeason?: CreatingSeason | null;
    defaultSeasonList: CreatingSeason[];
  }) => {
    if (isEmpty(creatingSeason)) return false;
    if (
      creatingSeason?.created ||
      !creatingSeason?.genderSpecific ||
      creatingSeason?.creationMode !== CREATION_MODE.ONE_PER_SPORT ||
      creatingSeason?.creationProcess !== CREATION_PROCESS.GENERAL_SETUP ||
      creatingSeason?.eventStartTimeType !== EVENT_START_TIME_TYPE.ALL_TEAMS ||
      !isEmpty(creatingSeason?.modeImportData) ||
      !isEmpty(creatingSeason?.rawSeasons)
    ) {
      return true;
    }

    const defaultValues = (defaultSeasonList ?? []).find(item => item.id === creatingSeason?.id);
    const genders = creatingSeason?.genders ?? [];

    if (!isEqual(genders, defaultValues?.genders ?? [])) {
      return true;
    }

    const isDiffLevel = genders.some((gender: string) => {
      const levels = get(creatingSeason?.levelsByGender, gender) ?? [];
      const defaultLevels = get(defaultValues?.levelsByGender, gender) ?? [];

      return !isEmpty(differenceBy(levels, defaultLevels, 'id'));
    });

    return isDiffLevel;
  };

  getDefaultCreatedSeason = ({
    creatingSeason,
    defaultSeasonList
  }: {
    creatingSeason?: CreatingSeason | null;
    defaultSeasonList: CreatingSeason[];
  }) => {
    if (!creatingSeason?.created || isEmpty(defaultSeasonList)) return creatingSeason;
    return (defaultSeasonList ?? []).find(item => item.id === creatingSeason?.id) ?? creatingSeason;
  };

  getDirtyCreatedSeason = ({
    creatingSeason,
    defaultSeasonList
  }: {
    creatingSeason?: CreatingSeason | null;
    defaultSeasonList: CreatingSeason[];
  }) => {
    let dirtyFields: any = {};

    if (!creatingSeason?.created) return dirtyFields;

    const defaultValues = this.getDefaultCreatedSeason({ creatingSeason, defaultSeasonList });

    (defaultValues?.rawSeasons ?? []).forEach((defaultRawSeason: RawSeason) => {
      const rawSeason = creatingSeason?.rawSeasons?.find(item => `${item.id}` === `${defaultRawSeason.id}`);
      let isDirty: boolean = isEmpty(rawSeason);

      if (!isDirty) {
        isDirty = DIRTY_SEASON_FIELDS.some(field => {
          const deafultValue = get(defaultRawSeason, field);
          const value = get(rawSeason, field);
          return deafultValue !== value;
        });
      }

      if (!isDirty) {
        isDirty =
          (isEmpty(defaultRawSeason?.rawEvents) && !isEmpty(rawSeason?.rawEvents)) ||
          (!isEmpty(defaultRawSeason?.rawEvents) && isEmpty(rawSeason?.rawEvents));
      }

      if (!isDirty) {
        isDirty = defaultRawSeason?.rawEvents?.some((defaultRawEvent: any) => {
          const rawEvent = rawSeason?.rawEvents?.find(item => `${item.id}` === `${defaultRawEvent.id}`);
          let isDirtyEventLevel = isEmpty(rawEvent);

          if (!isDirtyEventLevel) {
            isDirtyEventLevel = DIRTY_EVENT_FIELDS.some(field => {
              const deafultValue = get(defaultRawEvent, field);
              const value = get(rawEvent, field);
              return deafultValue !== value;
            });
          }
          return isDirtyEventLevel;
        });
      }

      if (!isDirty) {
        isDirty = (rawSeason?.rawEvents ?? []).some(
          (rawEvent: any) => !defaultRawSeason?.rawEvents?.find(item => `${item.id}` === `${rawEvent.id}`)
        );
      }

      dirtyFields = {
        ...dirtyFields,
        rawSeasons: {
          ...(dirtyFields?.rawSeasons ?? {}),
          [defaultRawSeason.id]: isDirty
        }
      };
    });
    return dirtyFields;
  };

  generateSeasonNameBy = ({ account, activity }: { account: AccountDTO; activity: ActivityDTO }) => {
    const accountName = account?.shortName ?? account?.name ?? '';
    const sportName = activity?.label ?? '';

    if (isEmpty(accountName) || isEmpty(sportName)) return '';
    return `${accountName} ${sportName}`.trim();
  };

  getPublishDateTime = ({ publishDate, publishTime }: { publishDate: string; publishTime: string }) => {
    let publishDateTime = null;
    if (!isEmpty(publishDate) && !isEmpty(publishTime)) {
      const mPublishDateTime = moment(`${publishDate} ${publishTime}`);
      publishDateTime = switchZone(mPublishDateTime.format(EVENT_DATE_FORMAT_WITH_TIMEZONE), 'localZone');
    }
    return publishDateTime;
  };

  buildRawEventByManualImport = ({
    account,
    activity,
    creatingSeason
  }: {
    account: AccountDTO;
    activity: ActivityDTO;
    creatingSeason: CreatingSeason;
  }): RawEvent[] => {
    const {
      manualSetting,
      eventStartTimeType,
      venue,
      eventsArchived: archived,
      alert,
      creationMode
    } = creatingSeason ?? {};
    const {
      eventIntegrationDetails: eventIntegrationDetailsFromSettings,
      allTeamsIsBroadcast,
      allTeamsBroadcastStartTime,
      allTeamsBroadcastEndTime,
      selectedLevels,
      startTime,
      numOfEvent
    } = manualSetting ?? {};

    let eventIntegrationDetails: EventIntegrationDetails[] = [];
    let overrideStartTime: TimeWithPeriod | undefined = startTime;

    if (creationMode === CREATION_MODE.ONE_PER_SPORT) {
      if (eventStartTimeType === EVENT_START_TIME_TYPE.ALL_TEAMS && allTeamsIsBroadcast === true) {
        eventIntegrationDetails = [];

        (selectedLevels ?? []).forEach(level => {
          eventIntegrationDetails.push({
            ...DEFAULT_BROADCAST_EVENT_VALUES,
            level: level.name,
            levelId: level.id,
            gender: level.gender,
            streamOnly: false,
            // ! TODO: Change this back to object values when we allow edit start/end time at the event level
            broadcastStartTime: allTeamsBroadcastStartTime
              ? `${allTeamsBroadcastStartTime?.time ?? ''} ${allTeamsBroadcastStartTime?.period ?? ''}`.trim()
              : '',
            broadcastEndTime: allTeamsBroadcastEndTime
              ? `${allTeamsBroadcastEndTime?.time ?? ''} ${allTeamsBroadcastEndTime?.period ?? ''}`.trim()
              : undefined,
            pixellotKey: level.pixellotKey
          });
        });

        overrideStartTime = allTeamsBroadcastStartTime;
      } else if (
        eventStartTimeType === EVENT_START_TIME_TYPE.EACH_TEAM &&
        !isEmpty(eventIntegrationDetailsFromSettings) &&
        Object.values(eventIntegrationDetailsFromSettings).some(item => item.isBroadcast)
      ) {
        const allStartTimes: { time: string; period: string }[] = [];

        eventIntegrationDetails = Object.keys(eventIntegrationDetailsFromSettings ?? {})
          .map((key: string) => {
            const eid = eventIntegrationDetailsFromSettings[key];

            if (!eid.isBroadcast) {
              allStartTimes.push(startTime?.[eid.teamId] ?? DEFAULT_START_TIME);
              return null;
            }

            const gender = key.split('-')[0];
            const level = key.split('-')[1];
            const levelId = (selectedLevels ?? []).find(lvl => lvl.name === level)?.id;
            const pixellotKey = (selectedLevels ?? []).find(lvl => lvl.name === level)?.pixellotKey;

            allStartTimes.push(eid.broadcastStartTime);

            // ! TODO: Change this back to object values when we allow edit start/end time at the event level
            const broadcastStartTime = `${eid.broadcastStartTime?.time ?? ''} ${
              eid.broadcastStartTime?.period ?? ''
            }`.trim();
            const broadcastEndTime = `${eid.broadcastEndTime?.time ?? ''} ${eid.broadcastEndTime?.period ?? ''}`.trim();

            return {
              ...DEFAULT_BROADCAST_EVENT_VALUES,
              level,
              levelId,
              gender,
              streamOnly: false,
              broadcastStartTime,
              broadcastEndTime,
              pixellotKey
            };
          })
          .filter(item => !isNil(item));

        if (allStartTimes.length > 0) overrideStartTime = findEarliestTime(allStartTimes);
      }
    }

    let startTimeOptions: StartTimeOptions | undefined;

    if ((eventIntegrationDetails ?? []).length > 0) {
      startTimeOptions = {};

      eventIntegrationDetails.forEach(eid => {
        if (eid.isBroadcast === true) startTimeOptions[`${eid.gender}-${eid.levelId}`] = eid.broadcastStartTime;
        else startTimeOptions[`${eid.gender}-${eid.levelId}`] = startTime?.[eid.teamId] ?? DEFAULT_START_TIME;
      });
    }

    return Array(parseFloat(`${numOfEvent}`))
      .fill(1)
      .map(() =>
        new RawEventModel({ eventStartTimeType } as RawEvent)
          .addDefaultStartTime()
          .setByAccount(account)
          .setByActivity(activity)
          .setVenue(venue)
          .setEventVisibility(archived)
          .setEventAlert(alert)
          .setSeasonStartTimeOptions({
            eventIntegrationDetails,
            startTimeOptions,
            startTime: overrideStartTime,
            eventStartTimeType,
            ignore: (eventIntegrationDetails ?? []).length === 0
          })
          .toJSON()
      );
  };

  buildRawEventByFileUpload = ({
    account,
    activity,
    creatingSeason
  }: {
    account: AccountDTO;
    activity?: ActivityDTO;
    creatingSeason: CreatingSeason;
  }): RawEvent[] => {
    const events: RawEvent[] = [];
    const { venue, eventsArchived: archived, alert } = creatingSeason ?? {};
    creatingSeason?.fileUploadSetting?.uploadedEvents?.forEach(event => {
      let name = '';
      let startDateTime = '';
      let opponentAccount = null;
      let opponentAccountId = null;
      let withoutOpponentAccount = false;
      let startTime = DEFAULT_START_TIME;
      const uploadedSport = event.sport ?? '';
      const uploadedlevel = event.levels ?? '';
      const uploadedGender = `${event.genders ?? ''}`.toUpperCase();

      if (isEmpty(event.opponentAccount)) {
        withoutOpponentAccount = true;
        name = generateDefaultEventName(account);
      } else {
        opponentAccount = {
          ...event.opponentAccount,
          city: event.city,
          state: event.state
        };
        opponentAccountId = event.opponentAccount?.id;
        name = generateEventName(account, opponentAccount);
      }

      if (!isEmpty(event.startDateTime)) {
        startDateTime = switchZone(event.startDateTime, 'localZone');
        const mStartDateTime = moment(startDateTime);
        startTime = {
          time: mStartDateTime.clone().format('hh:mm'),
          period: mStartDateTime.clone().format('A')
        };
      }

      const eventVenue = event.venueName
        ? {
            address: event.venueAddress,
            city: event.venueCity,
            location: event.venueLocation,
            name: event.venueName,
            state: event.venueState,
            zipCode: event.venueZip
          }
        : venue;

      events.push(
        new RawEventModel({
          name,
          uploadedSport,
          uploadedlevel,
          uploadedGender,
          startDateTime,
          opponentAccount,
          opponentAccountId,
          withoutOpponentAccount
        } as RawEvent)
          .addDefaultStartTime(startTime)
          .setByAccount(account)
          .setByActivity(activity)
          .setVenue(eventVenue)
          .setEventVisibility(archived)
          .setEventAlert(alert)
          .toJSON()
      );
    });

    return events;
  };

  buildRawEventByImportFromArbiter = ({
    account,
    activity,
    creatingSeason
  }: {
    account: AccountDTO;
    activity: ActivityDTO;
    creatingSeason: CreatingSeason;
  }): RawEvent[] => {
    const events: RawEvent[] = [];
    creatingSeason?.fromArbiterSetting?.arbiterEvents?.forEach(event => {
      let name = '';
      let startDateTime = '';
      let endDateTime = '';
      let opponentAccountId = '';
      const opponentAccount =
        (creatingSeason?.fromArbiterSetting?.opponentSchools || []).find(
          (opponentSchool: AccountDTO) => `${opponentSchool?.id}` === `${event?.awaySchoolId}`
        ) || null;
      let withoutOpponentAccount = false;
      let startTime = DEFAULT_START_TIME;
      if (!isEmpty(opponentAccount)) {
        name = getPartnerEventName(
          event?.eventName,
          generateDefaultEventName(account, generateOpponentName(opponentAccount, true))
        );
        opponentAccountId = opponentAccount?.id ?? '';
      } else {
        withoutOpponentAccount = true;
        name = getPartnerEventName(event?.eventName, generateDefaultEventName(account));
      }

      if (!isEmpty(event.startEventDt)) {
        startDateTime = switchZone(event.startEventDt, 'localZone');

        const mStartDateTime = moment(startDateTime);
        startTime = {
          time: mStartDateTime.clone().format('hh:mm'),
          period: mStartDateTime.clone().format('A')
        };
      }
      if (!isEmpty(event.endEventDt)) {
        endDateTime = switchZone(event.endEventDt, 'localZone');
      }
      events.push(
        new RawEventModel({
          name,
          startDateTime,
          endDateTime,
          opponentAccount,
          opponentAccountId,
          withoutOpponentAccount,
          importGender: event?.gender ?? '',
          importLevel: event?.eventLevel ?? '',
          globalEventsId: event?.id ?? '',
          importStartDateTime: startDateTime,
          partnerId: event?.sourceEventId,
          partnerName: ARBITER_PARTNER_NAME
        } as RawEvent)
          .addDefaultStartTime(startTime)
          .setByAccount(account)
          .setByActivity(activity)
          .toJSON()
      );
    });
    return events;
  };

  buildRawEventByPartner = ({
    account,
    activity,
    opponentSchools,
    creatingSeason,
    partnerEvents
  }: {
    account: AccountDTO;
    activity: ActivityDTO;
    opponentSchools: AccountDTO[];
    creatingSeason: CreatingSeason;
    partnerEvents: GlobalEventDTO[];
  }): RawEvent[] => {
    const rawEvents: RawEvent[] = [];

    (partnerEvents ?? [])
      .filter(event => `${event.gofanStatusMsg}`.toLowerCase() !== IGNORED_STATUS.toLowerCase())
      .forEach((event: any) => {
        let name = '';
        let startDateTime = '';
        let endDateTime = '';
        let dateTimeDuration;
        let opponentAccountId = null;
        let withoutOpponentAccount = false;
        let startTime = DEFAULT_START_TIME;
        const eventTimeZone = getTimeZone(account?.timeZone);

        const opponentAccount =
          (opponentSchools ?? []).find(
            (opponentSchool: AccountDTO) => `${opponentSchool?.id}` === `${event?.awaySchoolId}`
          ) ?? null;

        if (!isEmpty(opponentAccount)) {
          name = getPartnerEventName(
            event?.eventName,
            generateDefaultEventName(account, generateOpponentName(opponentAccount, true))
          );
          opponentAccountId = opponentAccount?.id ?? null;
        } else {
          withoutOpponentAccount = true;
          name = getPartnerEventName(event?.eventName, generateDefaultEventName(account));
        }

        if (!isEmpty(event.startEventDt)) {
          const formattedStartDateTime = formatDateTime({
            date: event.startEventDt,
            timeZone: eventTimeZone,
            parseZone: true
          });
          startDateTime = switchZone(formattedStartDateTime.toDate(EVENT_DATE_FORMAT_WITH_TIMEZONE), 'localZone');

          const mStartDateTime = moment(startDateTime, EVENT_DATE_FORMAT_WITH_TIMEZONE);
          startTime = {
            time: mStartDateTime.clone().format('hh:mm'),
            period: mStartDateTime.clone().format('A')
          };
        }

        if (!isEmpty(event.endEventDt)) {
          const formattedEndDateTime = formatDateTime({
            date: event.endEventDt,
            timeZone: eventTimeZone,
            parseZone: true
          });
          endDateTime = switchZone(formattedEndDateTime.toDate(EVENT_DATE_FORMAT_WITH_TIMEZONE), 'localZone');

          dateTimeDuration = moment(endDateTime).diff(moment(startDateTime));
        }

        let eventData: any = {
          created: event?.created,
          financialAccountId: event?.financialAccountId ?? account?.id,
          importGender: event?.gender ?? '',
          importLevel: event?.eventLevel ?? '',
          partnerId: event?.sourceEventId ?? '',
          partnerName: event?.sourceName ?? '',
          globalEventsId: event?.id ?? ''
        };

        if (event?.resolveType) {
          eventData = {
            ...eventData,
            resolveType: event?.resolveType,
            partnerId: event?.partnerId,
            partnerName: event?.partnerName,
            globalEventsId: event?.globalEventId ?? ''
          };
        }

        if (!isEmpty(event?.goFanEvent)) {
          const goFanEvent = event?.goFanEvent ?? {};
          const eventProducts = (goFanEvent?.products ?? []).map((product: any) =>
            new RawProductModel({ ...omit(product, ['_embedded', '_links']), created: true }).toJSON()
          );
          const eventStartTimeType = goFanEvent?.startTimeType ?? EVENT_START_TIME_TYPE.ALL_TEAMS;
          const eventStartTimeTeams: StartTimeTeams = this.convertStartTimeOptionsToTeams({
            sportId: goFanEvent?.activityId,
            genders: creatingSeason?.genders,
            levelsByGender: creatingSeason?.levelsByGender,
            startTimeType: eventStartTimeType,
            startTimeOptions: goFanEvent?.startTimeOptions
          });

          eventData = {
            ...eventData,
            ...omit(goFanEvent, ['_embedded', '_links', 'products']),
            account: omit(account, ['_embedded', '_links']),
            activity: omit(activity, ['_embedded', '_links']),
            products: eventProducts,
            created: true,
            eventStartTimeType,
            eventStartTimeTeams,
            resolveType: event?.resolveType,
            partnerId: event?.partnerId,
            partnerName: event?.partnerName,
            globalEventsId: event?.globalEventId ?? ''
          };
        }

        eventData = {
          ...eventData,
          name,
          endDateTime,
          startDateTime,
          importStartDateTime: startDateTime,
          dateTimeDuration,
          opponentAccount,
          opponentAccountId,
          withoutOpponentAccount
        };

        if (!isEmpty(event?.goFanEvent)) {
          rawEvents.push(new RawEventModel({ ...eventData } as RawEvent).addDefaultStartTime(startTime).toJSON());
        } else {
          rawEvents.push(
            new RawEventModel({ ...eventData } as RawEvent)
              .addDefaultStartTime(startTime)
              .setByAccount(account)
              .setByActivity(activity)
              .toJSON()
          );
        }
      });

    return rawEvents;
  };

  buildRawSeason = ({
    name,
    seasonId,
    rawEvents = [],
    groupedAccountTicketTypes = [],
    eventStartTimeType = EVENT_START_TIME_TYPE.ALL_TEAMS,
    startTimeTeams = {},
    startTime = DEFAULT_START_TIME,
    useSeasonStartTimeTeams = false,
    usingEventStartTime = false,
    accountId,
    sportId,
    gender,
    glCode,
    levelId,
    genders = [],
    levelsByGender,
    financialAccount,
    taggedAccounts = [],
    partnerName = '',
    venue,
    archived,
    publishDateTime,
    alert,
    entered,
    products = [],
    timeZone,
    seasonalPeriod
  }: {
    name: string;
    seasonId: string;
    rawEvents?: RawEvent[];
    startTime?: TimeWithPeriod;
    eventIntegrationDetails?: any[];
    eventStartTimeType?: string;
    startTimeTeams?: StartTimeTeams;
    useSeasonStartTimeTeams?: boolean;
    usingEventStartTime?: boolean;
    groupedAccountTicketTypes?: any[];
    accountId: string;
    sportId: string | number;
    gender?: string;
    glCode?: string;
    levelId?: string | number;
    genders: string[];
    levelsByGender: LevelsByGender;
    financialAccount: AccountDTO;
    taggedAccounts?: AccountDTO[];
    partnerName?: string;
    venue?: VenueInformationValue;
    archived?: boolean;
    publishDateTime?: string | null;
    alert?: string;
    entered?: string;
    products?: SeasonProduct[];
    timeZone?: string;
    seasonalPeriod?: SeasonalPeriodType;
  }): RawSeason =>
    new RawSeasonModel({
      id: seasonId,
      name,
      accountId,
      sportId,
      gender,
      levelId,
      genders,
      glCode,
      startTime,
      eventStartTimeType,
      startTimeTeams,
      levelsByGender,
      partnerName,
      venue,
      archived,
      publishDateTime,
      alert,
      entered,
      seasonalPeriod,
      financialAccountId: financialAccount?.id,
      taggedAccountIds: taggedAccounts?.map(account => account.id),
      products: products.map((product: SeasonProduct) => {
        let salesStartDateTime;
        let salesEndDateTime;
        if (!isEmpty(product?.salesStartDate) && !isEmpty(product?.salesStartTime)) {
          const mSalesStartDateTime = dayjs(`${product?.salesStartDate} ${product?.salesStartTime}`);
          salesStartDateTime = switchZone(
            mSalesStartDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE),
            timeZone
          );
        }

        if (!isEmpty(product?.salesEndDate) && !isEmpty(product?.salesEndTime)) {
          const mSalesEndDateTime = dayjs(`${product?.salesEndDate} ${product?.salesEndTime}`);
          salesEndDateTime = switchZone(mSalesEndDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE), timeZone);
        }

        return {
          ...omit(product, ['salesStartDate', 'salesStartTime', 'salesEndDate', 'salesEndTime']),
          salesStartDateTime,
          salesEndDateTime
        };
      }),
      rawEvents: rawEvents.map(event => {
        const found = groupedAccountTicketTypes?.find(({ id }) => `${id}` === seasonId);
        let eventStartTime: TimeWithPeriod = startTime;
        let startTimeOptions = {};

        if (usingEventStartTime && !isEmpty(event.startTime)) {
          eventStartTime = event.startTime as TimeWithPeriod;
        }

        if (useSeasonStartTimeTeams) {
          startTimeOptions = { eventStartTimeType, startTimeTeams };
        }

        return new RawEventModel({
          ...omit(event, ['id', 'uploadedlevel', 'uploadedGender', 'uploadedSport']),
          ...startTimeOptions,
          financialAccountId: financialAccount?.id,
          taggedAccountIds: taggedAccounts?.map(account => account.id),
          products:
            found?.ticketTypes?.map(ticket =>
              new RawProductModel({})
                .setByAccountTicketType(ticket)
                .setOptionalSetting(ticket)
                .setHiddenFees(AccountService.isDailyTransactionClose(financialAccount) ? false : !!ticket.accountPaid)
                .setPackCount(ticket)
                .toJSON()
            ) ?? []
        })
          .addDefaultStartTime(eventStartTime)
          .toJSON();
      })
    }).toJSON();

  convertStartTimeTeamsToOptions = ({
    startTimeType,
    startTimeTeams = {},
    isFormField = false
  }: {
    isFormField?: boolean;
    startTimeType?: string;
    startTimeTeams?: StartTimeTeams;
  }) => {
    let startTimeOptions: StartTimeOptions = {};

    if (startTimeType === EVENT_START_TIME_TYPE.EACH_TEAM) {
      Object.keys(startTimeTeams ?? {}).forEach(startTimeId => {
        const startTimeTeam = isFormField ? startTimeTeams?.[startTimeId]?.value : startTimeTeams?.[startTimeId];
        const gender = upperFirst(`${startTimeTeam?.gender}`.toLowerCase());
        const levelId = startTimeTeam?.levelId;
        const time = startTimeTeam?.time ?? DEFAULT_START_TIME.time;
        const period = startTimeTeam?.period ?? DEFAULT_START_TIME.period;
        startTimeOptions = {
          ...startTimeOptions,
          [`${gender}-${levelId}`]: `${time} ${period}`
        };
      });
    }
    return { ...startTimeOptions };
  };

  convertStartTimeOptionsToTeams = ({
    sportId,
    genders = [],
    levelsByGender,
    startTimeType,
    startTimeOptions = {},
    defaultStartTime = DEFAULT_START_TIME
  }: {
    sportId: string | number;
    genders?: string[];
    levelsByGender?: LevelsByGender;
    startTimeType?: string;
    startTimeOptions?: StartTimeOptions;
    defaultStartTime?: TimeWithPeriod;
  }) => {
    let startTimeTeams: StartTimeTeams = {};

    if (startTimeType === EVENT_START_TIME_TYPE.EACH_TEAM) {
      (genders ?? []).forEach(gender => {
        const genderName = upperFirst(`${gender}`.toLowerCase());
        (levelsByGender?.[gender] ?? []).forEach(level => {
          const teamId = this.getTeamSeasonId({
            gender,
            sportId,
            levelId: level.id,
            eventStartTimeType: startTimeType
          });

          const startTimeId = `${genderName}-${level.id}`;
          const startTime = `${startTimeOptions?.[startTimeId] ?? ''}`.split(' ');
          const time = isEmpty(startTime?.[0]) ? defaultStartTime?.time : startTime?.[0];
          const period = isEmpty(startTime?.[1]) ? defaultStartTime?.period : startTime?.[1];

          startTimeTeams = {
            ...startTimeTeams,
            [teamId]: { sportId, levelId: level.id, levelName: level.name ?? level?.label, gender, time, period }
          };
        });
      });
    }
    return { ...startTimeTeams };
  };

  getMinInStartTimeOptions = ({
    date,
    isFromField = false,
    startTimeOptions
  }: {
    date?: string;
    isFromField?: boolean;
    startTimeOptions?: any;
  }) => {
    const now = moment(date).format(EVENT_DATE_FORMAT);
    let minStartTime: any = {};

    Object.keys(startTimeOptions ?? {}).some(startTimeId => {
      const startTimeOption = isFromField
        ? `${startTimeOptions?.[startTimeId]?.value?.time} ${startTimeOptions?.[startTimeId]?.value?.period}`
        : startTimeOptions?.[startTimeId];
      const curTime = moment(`${now} ${startTimeOption}`);

      if (!curTime.isValid()) return false;

      const minTime = moment(`${now} ${minStartTime?.startTime}`);
      if (
        isEmpty(minStartTime) ||
        !minTime.isValid() ||
        minTime.isAfter(curTime, 'hour') ||
        minTime.isAfter(curTime, 'minute')
      ) {
        minStartTime = {
          startTimeId,
          startTime: startTimeOption
        };
      }
      return false;
    });
    return minStartTime;
  };

  getLessThanInStartTimeOptions = ({
    date,
    lessThanTime,
    isFromField = false,
    startTimeOptions
  }: {
    date?: string;
    isFromField?: boolean;
    startTimeOptions?: any;
    lessThanTime: TimeWithPeriod;
  }) => {
    const now = moment(date).format(EVENT_DATE_FORMAT);
    const minTime = moment(`${now} ${lessThanTime?.time} ${lessThanTime?.period}`);

    if (!minTime.isValid()) return [];

    const minStartTimes: any[] = [];
    Object.keys(startTimeOptions ?? {}).some(startTimeId => {
      const startTimeOption = isFromField
        ? `${startTimeOptions?.[startTimeId]?.value?.time} ${startTimeOptions?.[startTimeId]?.value?.period}`
        : startTimeOptions?.[startTimeId];
      const curTime = moment(`${now} ${startTimeOption}`);

      if (!curTime.isValid()) return false;
      if (minTime.isAfter(curTime, 'hour') || minTime.isAfter(curTime, 'minute')) {
        minStartTimes.push({
          startTimeId,
          startTime: startTimeOption
        });
      }
      return false;
    });
    return minStartTimes;
  };

  replaceMinInStartTimeOptions = ({
    date,
    replaceValue,
    isFromField = false,
    startTimeOptions
  }: {
    date?: string;
    isFromField?: boolean;
    startTimeOptions?: any;
    replaceValue: TimeWithPeriod;
  }): StartTimeOptions => {
    if (isEmpty(startTimeOptions)) return {};

    const now = moment().format(EVENT_DATE_FORMAT);
    const replaceStartTime = `${replaceValue?.time} ${replaceValue?.period}`;
    const replaceTime = moment(`${now} ${replaceStartTime}`);

    if (isEmpty(replaceValue) || !replaceTime.isValid()) return { ...startTimeOptions };

    const minStartTime = this.getMinInStartTimeOptions({
      date,
      isFromField,
      startTimeOptions
    });
    const lessThanTimes = this.getLessThanInStartTimeOptions({
      date,
      isFromField,
      startTimeOptions,
      lessThanTime: replaceValue
    });
    let newStartTimeOptions = {};

    Object.keys(startTimeOptions ?? {}).forEach(startTimeId => {
      let updateValue = startTimeOptions?.[startTimeId];
      const lessTime = lessThanTimes.find((item: any) => `${startTimeId}` === `${item?.startTimeId}`);

      if (!isEmpty(lessTime) || `${startTimeId}` === `${minStartTime?.startTimeId}`) {
        if (isFromField) {
          updateValue = {
            ...startTimeOptions?.[startTimeId],
            value: {
              ...startTimeOptions?.[startTimeId]?.value,
              time: replaceValue?.time,
              period: replaceValue?.period
            }
          };
        } else {
          updateValue = replaceStartTime;
        }
      }

      if (startTimeId.endsWith('-broadCastTime')) {
        updateValue = startTimeOptions?.[startTimeId];
      }

      newStartTimeOptions = {
        ...newStartTimeOptions,
        [startTimeId]: updateValue
      };
    });

    return { ...newStartTimeOptions };
  };

  getMinInManualStartTimes = ({
    sportId,
    startTimes,
    eventStartTimeType,
    isFormField = false
  }: {
    isFormField?: boolean;
    sportId: string | number;
    eventStartTimeType: string;
    startTimes?: StartTimeTeams;
  }): TimeWithPeriod => {
    if (eventStartTimeType === EVENT_START_TIME_TYPE.ALL_TEAMS) {
      return startTimes?.[sportId] ?? DEFAULT_START_TIME;
    }

    if (eventStartTimeType === EVENT_START_TIME_TYPE.EACH_TEAM) {
      let minStartTime: TimeWithPeriod | null = null;
      const now = moment().format(EVENT_DATE_FORMAT);

      Object.keys(startTimes ?? {}).some(startTimeId => {
        const curStartTime = isFormField ? startTimes?.[startTimeId]?.value : startTimes?.[startTimeId];
        const curTime = moment(`${now} ${curStartTime?.time} ${curStartTime?.period}`);

        if (`${startTimeId}` === `${sportId}` || !curTime.isValid()) return false;

        const minTime = moment(`${now} ${minStartTime?.time} ${minStartTime?.period}`);
        if (
          isEmpty(minStartTime) ||
          !minTime.isValid() ||
          minTime.isAfter(curTime, 'hour') ||
          minTime.isAfter(curTime, 'minute')
        ) {
          minStartTime = { ...curStartTime } as TimeWithPeriod;
        }
        return false;
      });
      return (isEmpty(minStartTime) ? DEFAULT_START_TIME : minStartTime) as TimeWithPeriod;
    }

    return DEFAULT_START_TIME;
  };

  getStartTimeTeamsByLevelsByGender = ({
    sportId,
    genders,
    startTimes,
    levelsByGender,
    eventStartTimeType
  }: {
    sportId: string | number;
    genders: string[];
    eventStartTimeType?: string;
    startTimes?: StartTimeTeams;
    levelsByGender: LevelsByGender;
  }) => {
    let startTimeTeams = {};
    if (eventStartTimeType === EVENT_START_TIME_TYPE.EACH_TEAM) {
      (genders ?? []).forEach((gender: string) => {
        (levelsByGender?.[gender] ?? []).forEach((level: LevelDTO) => {
          const teamId = this.getTeamSeasonId({
            gender,
            sportId,
            levelId: level.id,
            eventStartTimeType
          });
          const startTime = startTimes?.[teamId] ?? DEFAULT_START_TIME;
          startTimeTeams = {
            ...startTimeTeams,
            [teamId]: { ...startTime, sportId, gender, levelId: level.id, levelName: level.name }
          };
        });
      });
    }
    return { ...startTimeTeams };
  };

  getBroadcasatStartTimeTeamsByLevelsByGender = ({
    sportId,
    genders,
    startTimes,
    levelsByGender,
    eventStartTimeType
  }: {
    sportId: string | number;
    genders: string[];
    eventStartTimeType?: string;
    startTimes?: StartTimeTeams;
    levelsByGender: LevelsByGender;
  }) => {
    let startTimeTeams = {};
    if (eventStartTimeType === EVENT_START_TIME_TYPE.EACH_TEAM) {
      (genders ?? []).forEach((gender: string) => {
        (levelsByGender?.[gender] ?? []).forEach((level: LevelDTO) => {
          const keyName = `${sportId}-${gender}-${level.id}`;

          if (keyName in startTimes) {
            const teamId = this.getTeamSeasonId({
              gender,
              sportId,
              levelId: level.id,
              eventStartTimeType
            });
            const startTime = startTimes?.[teamId] ?? DEFAULT_START_TIME;
            startTimeTeams = {
              ...startTimeTeams,
              [teamId]: { ...startTime, sportId, gender, levelId: level.id, levelName: level.name }
            };
          }
        });
      });
    }

    return { ...startTimeTeams };
  };

  buildRawSeasonByManualImport = ({
    account,
    activity,
    rawEvents,
    creatingSeason,
    groupedAccountTicketTypes,
    financialAccount,
    taggedAccounts = [],
    products
  }: {
    account: AccountDTO;
    activity: ActivityDTO;
    rawEvents: RawEvent[];
    creatingSeason: CreatingSeason;
    groupedAccountTicketTypes: any[];
    financialAccount: AccountDTO;
    taggedAccounts?: AccountDTO[];
    products?: SeasonProduct[];
  }): RawSeason[] => {
    const { id: sportId } = activity ?? {};
    const { id: accountId } = account ?? {};
    const {
      genders,
      glCode,
      levelsByGender,
      creationMode,
      manualSetting,
      eventStartTimeType,
      venue,
      modeImportData,
      archived,
      publishDate = '',
      publishTime = '',
      alert,
      seasonalPeriod
    } = creatingSeason ?? {};
    const {
      eventIntegrationDetails: eventIntegrationDetailsFromSettings,
      allTeamsIsBroadcast,
      allTeamsBroadcastStartTime,
      allTeamsBroadcastEndTime,
      selectedLevels,
      startTime
    } = manualSetting ?? {};
    const results: RawSeason[] = [];
    const name = this.generateSeasonNameBy({ account, activity });
    const publishDateTime = this.getPublishDateTime({
      publishDate,
      publishTime
    });

    if (creationMode === CREATION_MODE.ONE_PER_SPORT) {
      const seasonId = this.getSeasonId({ sportId, creationMode });
      const startTimeTeams = this.getStartTimeTeamsByLevelsByGender({
        sportId,
        genders,
        startTimes: startTime,
        levelsByGender,
        eventStartTimeType
      });

      const minStartTime = this.getMinInManualStartTimes({
        sportId,
        eventStartTimeType,
        startTimes: startTime
      });

      results.push(
        this.buildRawSeason({
          name,
          seasonId,
          rawEvents,
          groupedAccountTicketTypes,
          useSeasonStartTimeTeams: true,
          eventStartTimeType,
          startTimeTeams,
          startTime: minStartTime,
          accountId,
          sportId,
          genders,
          glCode,
          levelsByGender,
          financialAccount,
          taggedAccounts,
          venue,
          archived,
          publishDateTime,
          alert,
          entered: this.getEnteredValue(modeImportData),
          products,
          timeZone: account?.timeZone,
          seasonalPeriod
        })
      );
    } else if (creationMode === CREATION_MODE.SPERATE_EACH_TEAM) {
      let eventIntegrationDetails: EventIntegrationDetails[] = [];
      let startTimeOptions = {};

      genders.forEach((gender: string) => {
        const levels = get(levelsByGender, gender) ?? [];
        levels.forEach((level: LevelDTO) => {
          const seasonId = this.getSeasonId({ sportId, gender, creationMode, levelId: level.id });
          let eventStartTime = startTime?.[sportId] ?? DEFAULT_START_TIME;

          if (eventStartTimeType === EVENT_START_TIME_TYPE.EACH_TEAM) {
            eventStartTime = startTime?.[seasonId] ?? DEFAULT_START_TIME;
          }

          if (eventStartTimeType === EVENT_START_TIME_TYPE.ALL_TEAMS && allTeamsIsBroadcast === true) {
            eventStartTime = allTeamsBroadcastStartTime;
            eventIntegrationDetails = [
              {
                ...DEFAULT_BROADCAST_EVENT_VALUES,
                level: level.name,
                levelId: level.id,
                gender: capitalize(gender),
                streamOnly: false,
                // ! TODO: Change this back to object values when we allow edit start/end time at the event level
                broadcastStartTime: allTeamsBroadcastStartTime
                  ? `${allTeamsBroadcastStartTime?.time ?? ''} ${allTeamsBroadcastStartTime?.period ?? ''}`.trim()
                  : '',
                broadcastEndTime: allTeamsBroadcastEndTime
                  ? `${allTeamsBroadcastEndTime?.time ?? ''} ${allTeamsBroadcastEndTime?.period ?? ''}`.trim()
                  : undefined,
                pixellotKey: (selectedLevels ?? [])[0]?.pixellotKey
              }
            ];

            startTimeOptions = { [`${capitalize(gender)}-${level.id}`]: allTeamsBroadcastStartTime };
          } else if (
            eventStartTimeType === EVENT_START_TIME_TYPE.EACH_TEAM &&
            !isEmpty(eventIntegrationDetailsFromSettings)
          ) {
            const found = Object.values(eventIntegrationDetailsFromSettings)?.find(item => item.teamId === seasonId);

            if (found?.isBroadcast) {
              const broadcastStartTime = found?.broadcastStartTime;
              eventStartTime = broadcastStartTime;

              eventIntegrationDetails = [
                {
                  ...DEFAULT_BROADCAST_EVENT_VALUES,
                  level: level.name,
                  levelId: level.id,
                  gender: capitalize(gender),
                  streamOnly: false,
                  // ! TODO: Change this back to object values when we allow edit start/end time at the event level
                  broadcastStartTime: eventStartTime
                    ? `${eventStartTime?.time ?? ''} ${eventStartTime?.period ?? ''}`.trim()
                    : '',
                  broadcastEndTime: found?.broadcastEndTime
                    ? `${found?.broadcastEndTime?.time ?? ''} ${found?.broadcastEndTime?.period ?? ''}`.trim()
                    : undefined,
                  pixellotKey: found?.pixellotKey ?? ''
                }
              ];
            }
            startTimeOptions = { [`${capitalize(gender)}-${level.id}`]: eventStartTime };
          }

          results.push(
            this.buildRawSeason({
              name,
              seasonId,
              rawEvents:
                eventIntegrationDetails.length > 0
                  ? rawEvents.map(ev => ({ ...ev, eventIntegrationDetails, startTimeOptions }))
                  : rawEvents,
              groupedAccountTicketTypes,
              startTime: eventStartTime,
              accountId,
              sportId,
              gender,
              glCode,
              levelId: level?.id,
              genders: [gender],
              levelsByGender: {
                [gender]: [level]
              },
              financialAccount,
              taggedAccounts,
              venue,
              archived,
              publishDateTime,
              alert,
              entered: this.getEnteredValue(modeImportData),
              products,
              timeZone: account?.timeZone,
              seasonalPeriod
            })
          );
        });
      });
    }
    return results;
  };

  getSameStartTimeInUploadEvents = (rawEvents: RawEvent[], defaultStartTime: TimeWithPeriod = DEFAULT_START_TIME) => {
    let sameStartTime: TimeWithPeriod | undefined = rawEvents?.[0]?.startTime;

    (rawEvents ?? []).some(rawEvent => {
      const isSameTime =
        rawEvent?.startTime?.time === sameStartTime?.time && rawEvent?.startTime?.period === sameStartTime?.period;

      sameStartTime = isSameTime ? rawEvent?.startTime : undefined;
      return !isSameTime;
    });

    if (!isEmpty(sameStartTime)) return sameStartTime;
    return defaultStartTime;
  };

  buildRawSeasonByFileUpload = ({
    account,
    activity,
    rawEvents,
    creatingSeason,
    groupedAccountTicketTypes,
    financialAccount,
    taggedAccounts = [],
    products
  }: {
    account: AccountDTO;
    activity: ActivityDTO;
    rawEvents: RawEvent[];
    creatingSeason: CreatingSeason;
    groupedAccountTicketTypes: any[];
    financialAccount: AccountDTO;
    taggedAccounts?: AccountDTO[];
    products?: SeasonProduct[];
  }): RawSeason[] => {
    const { id: sportId } = activity ?? {};
    const { id: accountId } = account ?? {};
    const {
      genders,
      levelsByGender,
      creationMode,
      venue,
      archived,
      publishDate = '',
      publishTime = '',
      alert,
      modeImportData
    } = creatingSeason ?? {};
    const results: RawSeason[] = [];
    const name = this.generateSeasonNameBy({ account, activity });
    const publishDateTime = this.getPublishDateTime({
      publishDate,
      publishTime
    });

    if (creationMode === CREATION_MODE.ONE_PER_SPORT) {
      const seasonId = this.getSeasonId({ sportId, creationMode });
      const sameStartTime = this.getSameStartTimeInUploadEvents(rawEvents, DEFAULT_START_TIME);
      results.push(
        this.buildRawSeason({
          name,
          seasonId,
          rawEvents,
          groupedAccountTicketTypes,
          startTime: sameStartTime,
          usingEventStartTime: true,
          accountId,
          sportId,
          genders,
          levelsByGender,
          financialAccount,
          taggedAccounts,
          venue,
          archived,
          publishDateTime,
          alert,
          entered: this.getEnteredValue(modeImportData),
          products,
          timeZone: account?.timeZone
        })
      );
    } else if (creationMode === CREATION_MODE.SPERATE_EACH_TEAM) {
      genders.forEach((gender: string) => {
        const levels = get(levelsByGender, gender) ?? [];
        levels.forEach((level: LevelDTO) => {
          const seasonId = this.getSeasonId({ sportId, gender, creationMode, levelId: level.id });
          const filteredEvents = rawEvents?.filter(event => {
            let uploadedGender = `${event.uploadedGender ?? ''}`.toUpperCase();
            if (isEmpty(uploadedGender)) {
              uploadedGender = GENDER_TYPE.COED;
            }
            return `${gender}` === `${uploadedGender}` && `${level.id}` === `${event.uploadedlevel}`;
          });
          const sameStartTime = this.getSameStartTimeInUploadEvents(filteredEvents, DEFAULT_START_TIME);
          results.push(
            this.buildRawSeason({
              name,
              seasonId,
              rawEvents: filteredEvents,
              groupedAccountTicketTypes,
              startTime: sameStartTime,
              usingEventStartTime: true,
              accountId,
              sportId,
              gender,
              levelId: level?.id,
              genders: [gender],
              levelsByGender: {
                [gender]: [level]
              },
              financialAccount,
              taggedAccounts,
              venue,
              archived,
              publishDateTime,
              alert,
              entered: this.getEnteredValue(modeImportData),
              products,
              timeZone: account?.timeZone
            })
          );
        });
      });
    }
    return results;
  };

  buildCreatingSeasonListByMultiSportUpload = ({
    account,
    rawEvents,
    selectedSports,
    multiSportConfig,
    financialAccount,
    taggedAccounts = [],
    groupTicketType
  }: {
    account: AccountDTO;
    rawEvents: RawEvent[];
    selectedSports: ActivityDTO[];
    multiSportConfig: any;
    financialAccount: AccountDTO;
    taggedAccounts?: AccountDTO[];
    groupTicketType: GroupTicketTypeBySport[];
  }): CreatingSeason[] => {
    const { id: accountId } = account ?? {};
    const results: CreatingSeason[] = [];
    const creationMode = CREATION_MODE.ONE_PER_SPORT;
    const uploadedTeams = multiSportConfig?.fileUploadSetting?.uploadedTeams ?? [];
    const { venue, archived, publishDate, publishTime, eventsArchived, alert } = multiSportConfig;

    selectedSports.forEach((sport: any) => {
      const { id: sportId } = sport ?? {};
      const rawSeasons: any[] = [];

      const foundUploadedTeam = uploadedTeams.find((item: any) => `${item.sportId}` === `${sportId}`);
      const genders = foundUploadedTeam?.genders ?? [GENDER_TYPE.COED];
      const levelsByGender = foundUploadedTeam?.levelsByGender ?? {
        [GENDER_TYPE.COED]: []
      };

      const foundGroupTicketType = groupTicketType.find(item => `${item.sportId}` === `${sportId}`);
      const groupedAccountTicketTypes = foundGroupTicketType?.groupedAccountTicketTypes ?? [];
      const accountTicketTypes = groupedAccountTicketTypes?.[0]?.ticketTypes ?? [];

      const filteredEvents = rawEvents?.filter(item => `${item.uploadedSport}` === `${sportId}`);
      const sameStartTime = this.getSameStartTimeInUploadEvents(filteredEvents, DEFAULT_START_TIME);

      const seasonId = this.getSeasonId({ sportId, creationMode });
      const name = this.generateSeasonNameBy({ account, activity: sport });
      const publishDateTime = this.getPublishDateTime({
        publishDate,
        publishTime
      });

      rawSeasons.push(
        this.buildRawSeason({
          name,
          seasonId,
          groupedAccountTicketTypes,
          rawEvents: filteredEvents,
          startTime: sameStartTime,
          usingEventStartTime: true,
          accountId,
          sportId,
          genders,
          levelsByGender,
          financialAccount,
          taggedAccounts,
          venue,
          archived,
          publishDateTime,
          alert,
          entered: this.getEnteredValue(IMPORT_DATA_MODE.FILE_UPLOAD)
        })
      );

      results.push(
        new CreatingSeasonModel({
          id: sportId,
          sport,
          accountId: account?.id,
          created: false,
          creationProcess: CREATION_PROCESS.EVENT_SETUP,
          name: '',
          creationMode,
          genders,
          levelsByGender,
          genderSpecific: false,
          accountTicketTypes,
          rawSeasons,
          venue,
          archived,
          publishDate,
          publishTime,
          eventsArchived,
          alert
        })
          .simplify()
          .toJSON()
      );
    });
    return results;
  };

  buildRawSeasonByImportFromArbiter = ({
    account,
    activity,
    rawEvents,
    creatingSeason,
    groupedAccountTicketTypes,
    financialAccount,
    taggedAccounts = []
  }: {
    account: AccountDTO;
    activity: ActivityDTO;
    rawEvents: RawEvent[];
    creatingSeason: CreatingSeason;
    groupedAccountTicketTypes: any[];
    financialAccount: AccountDTO;
    taggedAccounts?: AccountDTO[];
  }): RawSeason[] => {
    const { id: sportId } = activity ?? {};
    const { id: accountId } = account ?? {};
    const { genders, levelsByGender, creationMode, modeImportData } = creatingSeason ?? {};
    const results: RawSeason[] = [];
    const name = this.generateSeasonNameBy({ account, activity });
    genders.forEach((gender: string) => {
      const levels = get(levelsByGender, gender) ?? [];
      levels.forEach((level: LevelDTO) => {
        const seasonId = this.getSeasonId({ sportId, gender, creationMode, levelId: level.id });
        const filteredEvents = rawEvents?.filter(event => {
          let importGender = `${event.importGender ?? ''}`.toUpperCase();
          if (isEmpty(importGender)) {
            importGender = GENDER_TYPE.COED;
          }
          return `${gender}` === `${importGender}` && `${level.name}` === `${event.importLevel}`;
        });
        results.push(
          this.buildRawSeason({
            name,
            seasonId,
            rawEvents: filteredEvents,
            groupedAccountTicketTypes,
            startTime: DEFAULT_START_TIME,
            usingEventStartTime: true,
            accountId,
            sportId,
            gender,
            levelId: level?.id,
            genders: [gender],
            levelsByGender: {
              [gender]: [level]
            },
            financialAccount,
            taggedAccounts,
            partnerName: ARBITER_PARTNER_NAME,
            entered: this.getEnteredValue(modeImportData)
          })
        );
      });
    });

    return results;
  };

  buildRawSeasonByPartner = ({
    partnerName,
    activity,
    account,
    financialAccount,
    rawEvents,
    creatingSeason,
    groupedAccountTicketTypes
  }: {
    partnerName: string;
    activity: ActivityDTO;
    account: AccountDTO;
    financialAccount: AccountDTO;
    rawEvents: RawEvent[];
    creatingSeason: CreatingSeason;
    groupedAccountTicketTypes: any[];
  }): RawSeason[] => {
    const { name, venue, genders, levelsByGender, creationMode, modeImportData, fromPartnerSetting } =
      creatingSeason ?? {};
    const { id: sportId } = activity ?? {};
    const { id: accountId } = account ?? {};

    const rawSeasons: RawSeason[] = [];

    genders.forEach((gender: string) => {
      const levels = get(levelsByGender, gender) ?? [];

      levels.forEach((level: LevelDTO) => {
        const seasonId = this.getSeasonId({ sportId, gender, creationMode, levelId: level.id });

        const partnerSchedule = fromPartnerSetting?.schedules?.find(
          ({ gender: scheduleGender, eventLevel: scheduleLevel }) =>
            `${scheduleGender}`.toLowerCase() === `${gender}`.toLowerCase() &&
            `${scheduleLevel}`.toLowerCase() === `${level?.name}`.toLowerCase()
        );

        const seasonName = partnerSchedule?.scheduleName ?? name ?? '';

        const filteredEvents = rawEvents?.filter(event => {
          let importGender = `${event.importGender ?? ''}`.toUpperCase();

          if (isEmpty(importGender)) {
            importGender = GENDER_TYPE.COED;
          }
          return `${gender}` === `${importGender}` && `${level.name}` === `${event.importLevel}`;
        });

        const groupTickets = groupedAccountTicketTypes?.find(({ id }) => `${id}` === seasonId);

        rawSeasons.push(
          new RawSeasonModel({
            id: seasonId,
            sportId,
            partnerName,
            name: seasonName,
            accountId,
            financialAccountId: financialAccount?.id,
            venue,
            entered: this.getEnteredValue(modeImportData),
            gender,
            genders: [gender],
            levelId: level?.id,
            levelsByGender: {
              [gender]: [level]
            },
            startTimeTeams: {},
            startTime: DEFAULT_START_TIME,
            eventStartTimeType: EVENT_START_TIME_TYPE.ALL_TEAMS,
            rawEvents: filteredEvents.map(event => {
              const eventProducts = !isEmpty(event?.products)
                ? event?.products
                : (groupTickets?.ticketTypes ?? []).map((ticket: AccountProductDTO) =>
                    new RawProductModel({})
                      .setByAccountTicketType(ticket)
                      .setHiddenFees(
                        AccountService.isDailyTransactionClose(financialAccount) ? false : !!ticket.accountPaid
                      )
                      .toJSON()
                  );

              return {
                ...event,
                products: eventProducts,
                financialAccountId: event?.financialAccountId ?? financialAccount?.id
              };
            })
          }).toJSON()
        );
      });
    });

    return rawSeasons;
  };

  checkFileUploadSetting = (creatingSeason: CreatingSeason | null) => {
    const { creationMode, genders, levelsByGender, fileUploadSetting } = creatingSeason ?? {};

    if (
      isEmpty(fileUploadSetting) ||
      creationMode !== fileUploadSetting?.creationMode ||
      !isEqual(genders, fileUploadSetting?.genders ?? [])
    ) {
      return false;
    }

    const isDiffLevels = (genders ?? []).some((gender: string) => {
      const levels = (levelsByGender?.[gender] ?? []).map((level: LevelDTO) => `${level.id}`);
      const settingLevels = ((fileUploadSetting?.levelsByGender?.[gender] as LevelDTO[]) ?? []).map(
        (level: LevelDTO) => `${level.id}`
      );
      return !isEqual(levels, settingLevels);
    });
    return !isDiffLevels;
  };

  getFileUploadConfig = (creatingSeason: CreatingSeason | null, creationMethod = '') => {
    const fileUploadSetting = (creatingSeason?.fileUploadSetting ?? {}) as FileUploadSetting;
    const isUploaded =
      creationMethod === CREATION_PROCESS.MULTI_SPORT_SETUP
        ? !isEmpty(fileUploadSetting)
        : this.checkFileUploadSetting(creatingSeason);
    const uploadedFile = isEmpty(fileUploadSetting?.fileName) ? null : new File([], fileUploadSetting?.fileName);
    return {
      ...fileUploadSetting,
      isUploaded,
      uploadedFile: isUploaded ? uploadedFile : null
    };
  };

  checkChangeMainSetup = (creatingSeason: CreatingSeason | null, prevCreatingSeason: CreatingSeason | null) => {
    const { creationMode, genders, levelsByGender, venue } = creatingSeason ?? {};

    if (
      creationMode !== prevCreatingSeason?.creationMode ||
      !isEqual(venue, prevCreatingSeason?.venue) ||
      !isEqual(genders, prevCreatingSeason?.genders ?? [])
    ) {
      return true;
    }

    const isDiffLevels = (genders ?? []).some((gender: string) => {
      const levels = (levelsByGender?.[gender] ?? []).map((level: LevelDTO) => `${level.id}`);
      const settingLevels = ((prevCreatingSeason?.levelsByGender?.[gender] as LevelDTO[]) ?? []).map(
        (level: LevelDTO) => `${level.id}`
      );
      return !isEqual(levels, settingLevels);
    });

    return isDiffLevels;
  };

  getPathBySetupProcess = (creatingSeason: CreatingSeason, creationMethod: string) => {
    const { accountId, creationProcess } = creatingSeason ?? {};

    if (creationProcess === CREATION_PROCESS.EVENT_DETAILS) {
      return PAGES.seasonManagement.seasonEventDetails.calculatePath(accountId);
    }

    if (creationMethod === CREATION_PROCESS.MULTI_SPORT_SETUP) {
      return PAGES.seasonManagement.seasonMultiSportSetup.calculatePath(accountId);
    }

    if (creationProcess === CREATION_PROCESS.GENERAL_SETUP) {
      return PAGES.seasonManagement.seasonGeneralSetup.calculatePath(accountId);
    }

    if (creationProcess === CREATION_PROCESS.EVENT_SETUP) {
      return PAGES.seasonManagement.seasonEventSetup.calculatePath(accountId);
    }

    return PAGES.seasonManagement.create.calculatePath(accountId);
  };

  handleHeightSportSelection = (
    sportSelectionListRef: RefObject<HTMLElement>,
    headerElementRef: RefObject<HTMLElement>,
    seasonSetupSize: any
  ) => {
    const CONTAINER_MIN_HEIGHT = 500;

    if (
      !sportSelectionListRef ||
      !sportSelectionListRef.current ||
      !headerElementRef ||
      !headerElementRef.current ||
      !seasonSetupSize
    )
      return;

    const seasonSetupHeight = seasonSetupSize.height ?? 0;
    const headerElementHeight = headerElementRef.current?.clientHeight ?? 0;

    if (seasonSetupHeight > CONTAINER_MIN_HEIGHT) {
      const gap = seasonSetupHeight - headerElementHeight - 50;
      // eslint-disable-next-line no-param-reassign
      sportSelectionListRef.current.style.height = gap > 0 ? `${gap}px` : '';
    }
    const sportSelectionListHasScrollbar =
      sportSelectionListRef.current.scrollHeight > sportSelectionListRef.current.clientHeight;
    if (sportSelectionListHasScrollbar) {
      // eslint-disable-next-line no-param-reassign
      (sportSelectionListRef.current.firstElementChild as HTMLDivElement).style.paddingRight = '5px';
    }
  };

  getLevelGendersToCheckArbiter = ({
    genders,
    levelsByGender
  }: {
    genders: string[];
    levelsByGender: LevelsByGender;
  }) => {
    let levelGenders: {
      levelName: string;
      gender: string;
    }[] = [];

    genders?.forEach((gender: string) => {
      levelGenders = [
        ...levelGenders,
        ...levelsByGender[gender]?.map((level: LevelDTO) => ({
          levelName: level?.name,
          gender: upperFirst(`${gender}`.toLowerCase())
        }))
      ];
    });

    return levelGenders;
  };

  countNumOfArbiterSeasonByGender = ({
    arbiterSeasons,
    genders,
    levelsByGender
  }: {
    arbiterSeasons: EventScheduleDTO[];
    genders: string[];
    levelsByGender: LevelsByGender;
  }) => {
    if (isEmpty(arbiterSeasons)) return 0;
    // Calculate number of arbiter seasons for each team (by levels of gender)
    let numOfArbiter = 0;
    genders?.forEach((gender: string) => {
      const genderName = upperFirst(`${gender}`.toLowerCase());
      const levels = levelsByGender?.[gender] ?? [];
      const hasNoArbiterInOneTeam = levels.some((level: LevelDTO) => {
        const filteredArbiters = arbiterSeasons.filter(
          (arbiterSeason: EventScheduleDTO) =>
            `${arbiterSeason.gender}` === `${genderName}` && `${arbiterSeason.eventLevel}` === `${level.name}`
        );
        numOfArbiter += filteredArbiters.length;
        return filteredArbiters.length === 0;
      });

      if (hasNoArbiterInOneTeam) {
        numOfArbiter = 0;
      }
    });

    return numOfArbiter;
  };

  countSeparateTeams = ({ genders, levelsByGender }: { genders: string[]; levelsByGender: LevelsByGender }) => {
    let numOfSelectedTeam = 0;
    genders?.forEach((gender: string) => {
      numOfSelectedTeam += (levelsByGender?.[gender] ?? []).length;
    });

    return numOfSelectedTeam;
  };

  getGenderLevelValueForMultipleSchedule = ({
    levels = [],
    schedules = []
  }: {
    levels: LevelDTO[];
    schedules: EventScheduleSeasonDTO[];
  }) => {
    let genders: string[] = [];
    let levelsGenderValues: { key: string; value: LevelDTO }[] = [];
    schedules.forEach((schedule: EventScheduleSeasonDTO) => {
      const newGender = `${schedule?.gender}`.toUpperCase();
      genders = union([...genders, newGender]);

      if (isEmpty(schedule?.scheduleLevels)) {
        const level =
          levels?.find((level: any) => `${level?.name}`.toLowerCase() === `${schedule?.eventLevel}`.toLowerCase()) ||
          ({} as LevelDTO);
        levelsGenderValues = [...levelsGenderValues, { key: newGender, value: level }];
      } else {
        const levelsGender = (schedule?.scheduleLevels ?? []).map(level => ({
          key: newGender,
          value: level
        }));
        levelsGenderValues = [...levelsGenderValues, ...levelsGender];
      }
    });

    // Get Data LevelByGender
    const levelsByGender: LevelsByGender = {};
    genders.forEach(gender => {
      levelsByGender[gender] = union(levelsGenderValues.filter(({ key }) => key === gender).map(({ value }) => value));
    });

    return {
      genders,
      levelsByGender
    };
  };

  getSeasonsYearRange = (season: CreatingSeason) => {
    const { rawSeasons } = season;
    const rawEvents = rawSeasons.flatMap(rawSeason => rawSeason.rawEvents);

    const years = uniq(
      rawEvents
        .map(event => {
          const startDate = dayjs(event.startDateTime);
          return startDate.year();
        })
        .filter(year => Number.isInteger(year))
    );

    if (years.length === 0) return '';
    if (years.length === 1) return `${years[0]}`;
    return `${years[0]} - ${years[years.length - 1]}`;
  };

  generateTaggedAccountsByDistrict = (account: AccountDTO, districtAccount?: AccountDTO) =>
    !isEmpty(districtAccount) && `${districtAccount?.id}` !== `${account?.id}` ? [districtAccount] : [];
}

export default new SeasonCreationUtil();
