import React from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyLink20 } from '@carbon/icons-react';
import { Tooltip } from 'carbon-components-react';

import styles from './styles';

const Wrapper = styled.div`
  ${styles.wrapper}
`;

const CopyWrapper = styled.div`
  ${styles.copyWrapper}
`;

const TooltipTitle = styled.div`
  ${styles.tooltipTitle}
`;

const TooltipDescription = styled.div`
  ${styles.tooltipDescription}
`;

function CopyButton({
  link = '',
  feedback = 'Link copied!',
  feedbackTimeout = 2000,
  tooltipText = 'Copy link',
  tooltipAlign = 'center',
  tooltipDirection = 'top',
  disabled = false,
  renderIcon: Icon = props => <CopyLink20 {...props} />,
  // eslint-disable-next-line react/jsx-no-useless-fragment
  children = <></>
}) {
  const [isShown, setIsShown] = React.useState(false);
  const [isHover, setIsHover] = React.useState(false);
  const timerRef = React.useRef(null);

  React.useEffect(() => {
    if (isShown && feedbackTimeout && !timerRef.current) {
      timerRef.current = setTimeout(() => {
        clearTimeout(timerRef.current);
        timerRef.current = null;
        setIsShown(false);
      }, feedbackTimeout);
    }
  }, [isShown, feedbackTimeout, timerRef.current, setIsShown]);

  return (
    <Wrapper>
      <CopyToClipboard
        text={link}
        onCopy={() => {
          if (!disabled) {
            setIsShown(true);
          }
          if (tooltipText) {
            setIsHover(false);
          }
        }}
        onMouseEnter={() => tooltipText && !disabled && setIsHover(true)}
        onMouseLeave={() => tooltipText && setIsHover(false)}
      >
        <CopyWrapper>
          <Icon className={!disabled ? 'gs-icon-copy' : 'gs-icon-copy-disabled'} />
          {children}
        </CopyWrapper>
      </CopyToClipboard>
      {tooltipText ? (
        <Tooltip
          open={!isShown && isHover}
          showIcon={false}
          align={tooltipAlign}
          direction={tooltipDirection}
          triggerText='tooltip-hover'
          className='gs--body-short-01 gs--inverse-01 gs--tooltip-custom'
        >
          {tooltipText}
        </Tooltip>
      ) : null}
      <Tooltip
        open={isShown}
        showIcon={false}
        align='end'
        direction='top'
        triggerText='tooltip'
        className='gs-tooltip-copy-button'
      >
        <TooltipTitle className='gs--label-01 gs--text-02'>{feedback}</TooltipTitle>
        <TooltipDescription className='gs--body-short-01 gs--inverse-01'>{link}</TooltipDescription>
      </Tooltip>
    </Wrapper>
  );
}
/*
CopyButton.defaultProps = {
  link: '',
  feedback: 'Link copied!',
  feedbackTimeout: 2000,
  tooltipText: 'Copy link',
  tooltipAlign: 'center',
  tooltipDirection: 'top',
  renderIcon: () => {}
};
*/
export default CopyButton;
