// @flow
import { isEmpty } from 'lodash';
import BaseDTO from './BaseDTO';
import ProductSalesInfoDTO from './ProductSalesInfoDTO';

class EventSalesInfoDTO extends BaseDTO {
  constructor({
    eventCapacity,
    eventId,
    eventSoldOut,
    eventTotalSaleQuantity,
    productSalesMap
  }) {
    super({
      eventCapacity,
      eventId,
      eventSoldOut,
      eventTotalSaleQuantity,
      productSalesMap
    });
  }

  get eventCapacity() {
    return this.raw.eventCapacity;
  }

  get eventId() {
    return this.raw.eventId;
  }

  get eventSoldOut() {
    return this.raw.eventSoldOut;
  }

  get eventTotalSaleQuantity() {
    return this.raw.eventTotalSaleQuantity;
  }

  get productSalesMap() {
    if (isEmpty(this.raw.productSalesMap)) {
      return null;
    }
    const productSalesMap = {};
    Object.keys(this.raw.productSalesMap).forEach(productId => {
      productSalesMap[productId] = new ProductSalesInfoDTO(
        this.raw.productSalesMap[productId]
      );
    });
    return productSalesMap;
  }
}

export default EventSalesInfoDTO;
