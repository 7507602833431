import React, {
  useRef,
  ReactNode,
  useContext,
  useCallback,
  createContext
} from 'react';
import {
  get,
  find,
  isEmpty,
  isFunction
} from 'lodash';
import { oc } from 'ts-optchain';
import Form from 'rc-field-form';
import { FormProviderProps } from 'rc-field-form/es/FormContext';
import { FormInstance } from 'rc-field-form/es/interface';

import { FormFieldConfig } from './form-field/form-field.component';

export type FieldRefConfig = {
  [k: string]: HTMLInputElement;
};

export type FormConfig = {
  fields: FormFieldConfig[]
};

export type FormContextData = {
  name: string;
  initialValues: any;
  formConfig: FormConfig;
  setFieldRef: (name: string, ref: HTMLInputElement) => void;
  getFieldError: (name: string) => any;
  getFormFieldConfig: (fieldName: string) => FormFieldConfig | object;
};

export type BasicFormProps = FormProviderProps & {
  name: string;
  form: FormInstance<any>;
  initialValues: any;
  formConfig: FormConfig;
  children?: ReactNode;
  component?: false | string | React.FC<any> | React.ComponentClass<any>;
  onFinish?: (values: any) => void;
  onValuesChange?: (values: any) => void;
};

export const defaultFunc = (): any => null;

export const FormContext = createContext<FormContextData>({
  name: 'basic-form',
  initialValues: {},
  formConfig: { fields: [] },
  setFieldRef: defaultFunc,
  getFieldError: defaultFunc,
  getFormFieldConfig: defaultFunc
});

export const useFormContext: () => FormContextData = () => useContext<FormContextData>(FormContext);

export const triggerOnValuesChange = (changedValue: any, onChange: any) => {
  if (onChange) {
    onChange(changedValue);
  }
};

export const BasicForm = ({
  name,
  form,
  children,
  component,
  formConfig,
  initialValues,
  onFinish,
  onValuesChange,
  ...props
}: BasicFormProps) => {
  const formContext = useContext(FormContext);
  const fieldsRef = useRef<FieldRefConfig>({});

  const getFormFieldConfig = useCallback(
    (fieldName) => find(formConfig.fields, ['name', fieldName]) || {},
    [formConfig]
  );

  const setFieldRef = useCallback(
    (fieldName, ref) => {
      if (!isEmpty(ref)) {
        fieldsRef.current = { ...fieldsRef.current, [fieldName]: ref };
      }
    },
    [fieldsRef.current]
  );

  const getFieldRef = useCallback(
    (fieldName) => get(fieldsRef.current, fieldName),
    [fieldsRef.current]
  );

  const transformData = useCallback(
    (values) => Object.keys(values).reduce(
      (res, fieldName) => {
        const config = getFormFieldConfig(fieldName);
        const value = get(values, fieldName) || undefined;
        const { transform }: any = oc(config)({});
        return {
          ...res,
          [fieldName]: isFunction(transform) ? transform(value) : value
        };
      },
      {}
    ),
    []
  );

  const handeFinishFailed = useCallback(({ errorFields }) => {
    if (!isEmpty(errorFields)) {
      const firstErrorField = errorFields[0];
      const errorFieldRef = getFieldRef(firstErrorField.name[0]);

      if (!isEmpty(errorFieldRef)) {
        errorFieldRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
  }, [fieldsRef.current]);

  const handleFinish = (values: any) => {
    if (isFunction(onFinish)) {
      onFinish(transformData(values));
    }
  };

  const handleValuesChange = (_: any, values: any) => {
    if (isFunction(onValuesChange)) {
      onValuesChange(transformData(values));
    }
  };

  return (
    <FormContext.Provider
      value={{
        ...formContext,
        name,
        formConfig,
        initialValues,
        // =========================================================
        // =                  Form Control                         =
        // =========================================================
        setFieldRef,
        getFieldError: form.getFieldError,
        getFormFieldConfig
      }}
    >
      <Form
        name={name}
        form={form}
        component={component}
        className='basic-form'
        onFinish={handleFinish}
        onFinishFailed={handeFinishFailed}
        onValuesChange={handleValuesChange}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </Form>
    </FormContext.Provider>
  );
};

export default BasicForm;
