import type SeatInfo from '@app/api/model/SeatInfo';
import type EventReservedSeating from '@app/api/model/EventReservedSeating';
import type SeasonRenewalFan from '@app/api/model/SeasonRenwalFanModel';
import type { SeasonRenewalDTO, SeasonRenewalFanDTO, SeasonRenewalSeatsDTO } from '@gofan/api';
import type ReservedEvent from '@app/api/model/ReservedEvent';
import type ReservedSeason from '@app/api/model/ReservedSeason';
import type AccountDTO from '@app/api/dto/AccountDTO';

export const PREPARE_DATA = '@EventReservedSeating/PREPARE_DATA';
export const PREPARE_SEASON_DATA = '@EventReservedSeating/PREPARE_SEASON_DATA';
export const PREPARE_DATA_COMPLETED = '@EventReservedSeating/PREPARE_DATA_COMPLETED';
export const UPDATE_DATA = '@EventReservedSeating/UPDATE_DATA';
export const UPDATE_DATA_COMPLETED = '@EventReservedSeating/UPDATE_DATA_COMPLETED';
export const UPDATE_SEATS_INFO_COMPLETED = '@EventReservedSeating/UPDATE_SEATS_INFO_COMPLETED';
export const BLOCK_RESERVED_SEATING = '@EventReservedSeating/BLOCKED_RESERVED_SEATING';
export const UNBLOCK_RESERVED_SEATING = '@EventReservedSeating/UNBLOCKED_RESERVED_SEATING';
export const MOVE_BOOKED_RESERVED_SEAT = '@EventReservedSeating/MOVE_BOOKED_RESERVED_SEAT';
export const UNBOOKED_RESERVED_SEAT = '@EventReservedSeating/UNBOOKED_RESERVED_SEAT';
export const BOOK_RESERVED_SEATS = '@EventReservedSeating/BOOK_RESERVED_SEATS';
export const UPDATE_TICKET_NOTES = '@EventReservedSeating/UPDATE_TICKET_NOTES';
export const VIEW_EFFECTED_SEATS = '@EventReservedSeating/VIEW_EFFECTED_SEATS';
export const CLEAR_SELECTED_ORDER = '@EventReservedSeating/CLEAR_SELECTED_ORDER';
export const UPDATE_SEASON_RENEWAL = '@EventReservedSeating/UPDATE_SEASON_RENEWAL';
export const UPDATE_SEASON_RENEWAL_COMPLETED = '@EventReservedSeating/UPDATE_SEASON_RENEWAL_COMPLETED';
export const GET_SEASON_RENEWALS = '@EventReservedSeating/GET_SEASON_RENEWALS';
export const GET_SEASON_RENEWALS_COMPLETED = '@EventReservedSeating/GET_SEASON_RENEWALS_COMPLETED';
export const UPDATE_RENEWAL_OFFER = '@EventReservedSeating/UPDATE_RENEWAL_OFFER';
export const UPDATE_RENEWAL_OFFER_COMPLETED = '@EventReservedSeating/UPDATE_RENEWAL_OFFER_COMPLETED';
export const DELETE_RENEWAL_OFFER = '@EventReservedSeating/DELETE_RENEWAL_OFFER';
export const DELETE_RENEWAL_OFFER_COMPLETED = '@EventReservedSeating/DELETE_RENEWAL_OFFER_COMPLETED';
export const UPDATE_ACCOUNTS_COMPLETED = '@EventReservedSeating/UPDATE_ACCOUNTS_COMPLETED';

interface PrepareDataAction {
  type: typeof PREPARE_DATA;
  payload: {
    id: number | string;
    mode: string;
    history: any;
    currentUser: any;
    isSeason: boolean;
  };
}

interface PrepareDataCompletedAction {
  type: typeof PREPARE_DATA_COMPLETED;
  payload: {
    event: ReservedEvent;
    season: ReservedSeason;
    seatsInfo: EventReservedSeating;
    seasonTickets: Array<any> | null;
    seasonRenewals: Array<SeasonRenewalFan>;
    seasonRenewalSeats: SeasonRenewalSeatsDTO;
  };
  error: any;
}

interface UpdateDataAction {
  type: typeof UPDATE_DATA;
  payload: {
    eventId: number | string;
    updatedAt: number;
  };
}

interface UpdateDataCompletedAction {
  type: typeof UPDATE_DATA_COMPLETED;
  payload: {
    seatsInfo: EventReservedSeating;
  };
}

interface BlockAction {
  type: typeof BLOCK_RESERVED_SEATING;
  payload: {
    eventId: number | string;
    seatsIoLabel: string;
  };
}

interface UnblockAction {
  type: typeof BLOCK_RESERVED_SEATING;
  payload: {
    eventId: number | string;
    seatsIoLabel: string;
  };
  error: any;
}

interface UpdateSeatsInfoCompletedAction {
  type: typeof UPDATE_SEATS_INFO_COMPLETED;
  payload: {
    updatedSeatsInfo: EventReservedSeating;
    seasonTickets: any[] | null;
  };
  error: any;
}

interface UnbookSeatAction {
  type: typeof UNBOOKED_RESERVED_SEAT;
  payload: {
    seatIds: Array<string>;
    seasonTickets: any[] | null;
  };
}
interface MoveBookSeatAction {
  type: typeof MOVE_BOOKED_RESERVED_SEAT;
  payload: {
    currentSeat: SeatInfo;
    targetSeat: SeatInfo;
  };
}

interface UpdateTicketNotes {
  type: typeof UPDATE_TICKET_NOTES;
  payload: {
    notes: string;
    tickets: Array<object>;
  };
}

interface BookReservedSeatsAction {
  type: typeof BOOK_RESERVED_SEATS;
  payload: {
    id: number | string;
    ticketsInfo: Array<object>;
    userInfo: never;
    ticketCategory: never[];
    isSeason: boolean;
  };
}

interface ViewEffectedSeats {
  type: typeof VIEW_EFFECTED_SEATS;
  payload: {
    type: string;
    seats: Array<object>;
  };
}

interface ClearSelectedOrder {
  type: typeof CLEAR_SELECTED_ORDER;
  payload: {};
}

interface UpdateRenewalSeasonAction {
  type: typeof UPDATE_SEASON_RENEWAL;
  payload: {
    seasonRenewal: Partial<SeasonRenewalDTO>;
    doneFn: () => void;
  };
}

interface UpdateRenewalSeasonCompletedAction {
  type: typeof UPDATE_SEASON_RENEWAL_COMPLETED;
  payload: {
    seasonRenewal: SeasonRenewalDTO;
  };
}

interface GetSeasonRenewals {
  type: typeof GET_SEASON_RENEWALS;
  payload: {
    seasonId: string;
  };
}

interface GetSeasonRenewalsCompleted {
  type: typeof GET_SEASON_RENEWALS_COMPLETED;
  payload: {
    seasonRenewals: SeasonRenewalDTO[];
  };
}

interface UpdateRenewalOffer {
  type: typeof UPDATE_RENEWAL_OFFER;
  payload: {
    renewalOffer: SeasonRenewalFanDTO;
    seasonId: string;
    doneFn: () => void;
  };
}

interface UpdateRenewalOfferCompleted {
  type: typeof UPDATE_RENEWAL_OFFER_COMPLETED;
}

interface DeleteRenewalOffer {
  type: typeof DELETE_RENEWAL_OFFER;
  payload: {
    renewalOffer: SeasonRenewalFanDTO;
    seasonId: string;
    doneFn: () => void;
  };
}

interface DeleteRenewalOfferCompleted {
  type: typeof DELETE_RENEWAL_OFFER_COMPLETED;
}

interface UpdateAccountsCompleted {
  type: typeof UPDATE_ACCOUNTS_COMPLETED;
  payload: {
    account: AccountDTO;
    financialAccount: AccountDTO;
  };
}

export type EventReservedSeatingTypes =
  | PrepareDataAction
  | PrepareDataCompletedAction
  | UpdateDataAction
  | UpdateDataCompletedAction
  | BlockAction
  | UpdateSeatsInfoCompletedAction
  | UnblockAction
  | UnbookSeatAction
  | MoveBookSeatAction
  | BookReservedSeatsAction
  | UpdateTicketNotes
  | ViewEffectedSeats
  | ClearSelectedOrder
  | UpdateRenewalSeasonAction
  | UpdateRenewalSeasonCompletedAction
  | GetSeasonRenewals
  | GetSeasonRenewalsCompleted
  | UpdateRenewalOffer
  | UpdateRenewalOfferCompleted
  | DeleteRenewalOffer
  | DeleteRenewalOfferCompleted
  | UpdateAccountsCompleted;
