import { useEffect, useRef, useState } from 'react';
import {
  Row,
  Column,
  TextInput,
  Button,
  SearchDropdown,
  Toggle,
  NumberInput,
  Checkbox,
  CheckboxGroup,
  MultiSelect,
  DatePicker,
  RadioButton,
  RadioButtonGroup,
  InlineLoading
} from '@gofan/components';
import { Controller, Form, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import omit from 'lodash/omit';
import intersectionBy from 'lodash/intersectionBy';
import isEmpty from 'lodash/isEmpty';

import { SchoolDropdownItem } from '@components/SchoolDropdownItem';
import { SchoolItem } from '@components/SchoolItem';

import { useAccounts } from '@gofan/api/accounts';

import { generateFormState, getMinDate, isEqualFormState, RatesConfigurationSchema } from '@rates/utils';
import { stateAbbreviations } from '@app/commons/utils/allFiftyStatesOptions';
import { ERROR_MESSAGES, STRINGS } from '@app/modules/rates/strings';
import { DATE_FORMAT_DEFAULT, DATE_PICKER_FORMAT_DEFAULT } from '@gofan/constants/date';
import { DISTRIBUTION_CHANNEL } from '@gofan/constants/product';

import type { RateDTO } from '@gofan/api/rates';
import { ResourceType } from '@gofan/api/rates';
import type { ProductTypeDTO } from '@gofan/api/product-type';
import type { RatesConfigurationFormState } from '@rates/utils';
import type { AccountDTO } from '@gofan/api/accounts';

import './rates-configuration-form.scss';

interface RatesConfigurationFormProps {
  mode: 'add' | 'edit';
  rate?: RateDTO;
  productTypes: ProductTypeDTO[];
  selectedAccounts?: AccountDTO[];
  loading: boolean;
  onSave: (rate: Partial<RateDTO>) => void;
  onCancel: () => void;
}

export const RatesConfigurationForm = ({
  mode = 'add',
  rate = {} as RateDTO,
  productTypes = [],
  selectedAccounts = [],
  loading,
  onSave,
  onCancel
}: RatesConfigurationFormProps) => {
  const defaultFormValue = useRef(generateFormState({ mode, rate, productTypes, selectedAccounts }));
  const methods = useForm<RatesConfigurationFormState>({
    defaultValues: defaultFormValue.current,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(RatesConfigurationSchema)
  });
  const {
    control,
    trigger,
    setValue,
    watch,
    handleSubmit,
    setError,
    getValues,
    formState: { errors }
  } = methods;
  const { alwaysApplyRate, productTypes: selectedProductTypes } = watch();
  const hasTicketProductType = !isEmpty(selectedProductTypes?.find(item => item.name.toUpperCase() === 'TICKET'));

  const [schoolSearchTerm, setSchoolSearchTerm] = useState('');
  const { data: searchedSchools, isLoading: schoolLoading } = useAccounts({
    searchBy: 'keyword',
    keywordParams: { keyword: schoolSearchTerm ?? '' },
    queryOptions: { enabled: typeof schoolSearchTerm === 'string' && schoolSearchTerm.trim() !== '' }
  });

  const minStartDate = getMinDate(rate?.startDate);
  const minEndDate = getMinDate(rate?.endDate);

  const isValid = () =>
    isEmpty(errors) && (mode === 'edit' ? !isEqualFormState(getValues(), defaultFormValue.current) : true);

  useEffect(() => {
    if (!hasTicketProductType) {
      setValue('resourceTypes', []);
      setValue('ticketTypes', []);
    }
  }, [hasTicketProductType]);

  return (
    <>
      <FormProvider {...methods}>
        <Form className='rates-configuration-form gs--ui-background-01 gs--margin-top-sp5'>
          <div className='gs--productive-heading-05 gs--font-family-sf'>
            {mode === 'add' ? STRINGS.ADD_RATE : STRINGS.EDIT_RATE}
          </div>
          <section id='rate-name' className='gs--margin-top-sp5'>
            <div className='gs--productive-heading-02 gs--font-family-sf gs--margin-bottom-sp4'>
              {STRINGS.SPECIFY_RATE_NAME}
            </div>
            <Row>
              <Column lg={4}>
                <Controller
                  name='label'
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      id='label'
                      labelText={STRINGS.RATE_NAME}
                      placeholder={STRINGS.TEXT_INPUT_PLACEHOLDER}
                      invalid={!!fieldState.error}
                      invalidText={fieldState.error?.message}
                      onChange={ev => {
                        field.onChange(ev.target.value);
                      }}
                    />
                  )}
                />
              </Column>
            </Row>
          </section>
          <div className='rates-configuration-form__divider gs--margin-top-sp7 gs--margin-bottom-sp7' />
          <section id='rate-value'>
            <div className='gs--productive-heading-02 gs--font-family-sf gs--margin-bottom-sp4'>
              {STRINGS.RATE_VALUE}
            </div>
            <div className='gs--body-short-02 gs--font-family-sf gs--margin-bottom-sp5'>
              {STRINGS.RATE_VALUE_DESCRIPTION}
            </div>
            <Row>
              <Column lg={4}>
                <Controller
                  name='rateAmount'
                  control={control}
                  render={({ field, fieldState }) => (
                    <NumberInput
                      {...field}
                      id='rateAmount'
                      label={STRINGS.RATE_AMOUNT}
                      allowEmpty
                      placeholder='$0.00'
                      invalid={!!fieldState.error}
                      invalidText={fieldState.error?.message}
                      onChange={(_, { value }) => {
                        field.onChange(value || '');
                      }}
                      onClick={(_, data) => {
                        if (data) {
                          field.onBlur();
                        }
                      }}
                    />
                  )}
                />
              </Column>
              <Column lg={4}>
                <Controller
                  name='ratePercent'
                  control={control}
                  render={({ field, fieldState }) => (
                    <NumberInput
                      {...field}
                      id='ratePercent'
                      label={STRINGS.RATE_PERCENT}
                      allowEmpty
                      placeholder='0%'
                      min={0}
                      max={100}
                      invalid={!!fieldState.error}
                      invalidText={fieldState.error?.message}
                      onChange={(_, { value }) => {
                        field.onChange(value || '');
                      }}
                      onClick={(_, data) => {
                        if (data) {
                          field.onBlur();
                        }
                      }}
                    />
                  )}
                />
              </Column>
            </Row>
          </section>
          <div className='rates-configuration-form__divider gs--margin-top-sp7 gs--margin-bottom-sp7' />
          <section id='product-type'>
            <div className='gs--productive-heading-02 gs--font-family-sf gs--margin-bottom-sp4'>
              {STRINGS.PRODUCT_TYPE}
            </div>
            <div className='gs--body-short-02 gs--font-family-sf gs--margin-bottom-sp5'>
              {STRINGS.PRODUCT_TYPE_DESCRIPTION}
            </div>
            <Row>
              <Column lg={4}>
                <Controller
                  name='productTypes'
                  control={control}
                  render={({ field, fieldState }) => (
                    <MultiSelect
                      {...field}
                      id='productTypes'
                      titleText={STRINGS.PRODUCT_TYPE}
                      label={field.value?.length > 0 ? STRINGS.OPTIONS_SELECTED : STRINGS.MULTI_SELECT_PLACEHOLDER}
                      items={productTypes}
                      itemToString={item => item.name}
                      initialSelectedItems={intersectionBy(field.value, productTypes, 'id')}
                      invalid={!!fieldState.error}
                      invalidText={fieldState.error?.message}
                      onChange={({ selectedItems }: { selectedItems: ProductTypeDTO[] }) => {
                        field.onChange(selectedItems);
                        field.onBlur();
                      }}
                    />
                  )}
                />
              </Column>
            </Row>
            {hasTicketProductType && (
              <>
                <div className='gs--body-short-02 gs--font-family-sf gs--margin-top-sp5'>
                  {STRINGS.EVENT_TICKETS_AND_SEASON_PACKAGES_DESCRIPTION}
                </div>
                <Row className='gs--margin-bottom-sp5'>
                  <Column lg={5}>
                    <Controller
                      name='resourceTypes'
                      control={control}
                      render={({ field, fieldState }) => (
                        <CheckboxGroup
                          legendText=''
                          invalid={!!fieldState.error}
                          invalidText={fieldState.error?.message}
                        >
                          <div className='group-control'>
                            <Checkbox
                              id='resourceTypes-event'
                              labelText={
                                <div className='gs--body-short-02 gs--font-family-sf gs--text-01'>
                                  {STRINGS.EVENT_TICKETS}
                                </div>
                              }
                              checked={field.value?.includes(ResourceType.EVENT)}
                              onChange={(_, { checked }) => {
                                const resourceTypes = checked
                                  ? [ResourceType.EVENT, ...(field.value ?? [])]
                                  : (field.value ?? []).filter(item => item !== ResourceType.EVENT);
                                field.onChange(resourceTypes);
                                field.onBlur();
                              }}
                            />
                            <Checkbox
                              id='resourceTypes-season'
                              className='gs--margin-left-sp5'
                              labelText={
                                <div className='gs--body-short-02 gs--font-family-sf gs--text-01'>
                                  {STRINGS.SEASON_PACKAGES}
                                </div>
                              }
                              checked={field.value?.includes(ResourceType.SEASON)}
                              onChange={(_, { checked }) => {
                                const resourceTypes = checked
                                  ? [...(field.value ?? []), ResourceType.SEASON]
                                  : (field.value ?? []).filter(item => item !== ResourceType.SEASON);
                                field.onChange(resourceTypes);
                                field.onBlur();
                              }}
                            />
                          </div>
                        </CheckboxGroup>
                      )}
                    />
                  </Column>
                </Row>
                <div className='gs--body-short-02 gs--font-family-sf'>{STRINGS.TICKET_TYPE_DESCRIPTION}</div>
                <Row className='gs--margin-bottom-sp5'>
                  <Column lg={5}>
                    <Controller
                      name='ticketTypes'
                      control={control}
                      render={({ field, fieldState }) => (
                        <CheckboxGroup
                          legendText=''
                          invalid={!!fieldState.error}
                          invalidText={fieldState.error?.message}
                        >
                          <div className='group-control'>
                            <Checkbox
                              id='ticketTypes-generalAdmission'
                              labelText={
                                <div className='gs--body-short-02 gs--font-family-sf gs--text-01'>
                                  {STRINGS.GENERAL_ADMISSION}
                                </div>
                              }
                              checked={field.value?.includes(STRINGS.GENERAL_ADMISSION)}
                              onChange={(_, { checked }) => {
                                const ticketTypes = checked
                                  ? [STRINGS.GENERAL_ADMISSION, ...(field.value ?? [])]
                                  : (field.value ?? []).filter(item => item !== STRINGS.GENERAL_ADMISSION);
                                field.onChange(ticketTypes);
                                field.onBlur();
                              }}
                            />
                            <Checkbox
                              id='ticketTypes-reservedSeating'
                              className='gs--margin-left-sp5'
                              labelText={
                                <div className='gs--body-short-02 gs--font-family-sf gs--text-01'>
                                  {STRINGS.RESERVED_SEATING}
                                </div>
                              }
                              checked={field.value?.includes(STRINGS.RESERVED_SEATING)}
                              onChange={(_, { checked }) => {
                                const ticketTypes = checked
                                  ? [...(field.value ?? []), STRINGS.RESERVED_SEATING]
                                  : (field.value ?? []).filter(item => item !== STRINGS.RESERVED_SEATING);
                                field.onChange(ticketTypes);
                                field.onBlur();
                              }}
                            />
                          </div>
                        </CheckboxGroup>
                      )}
                    />
                  </Column>
                </Row>
              </>
            )}
          </section>
          <div className='rates-configuration-form__divider gs--margin-top-sp7 gs--margin-bottom-sp7' />
          <section id='price-product-type'>
            <div className='gs--productive-heading-02 gs--font-family-sf gs--margin-bottom-sp4'>
              {STRINGS.PRICE_PRODUCT_TYPE}
            </div>
            <div className='gs--body-short-02 gs--font-family-sf gs--margin-bottom-sp5'>
              {STRINGS.PRICE_PRODUCT_TYPE_DESCRIPTION}
            </div>
            <Row>
              <Column lg={4}>
                <Controller
                  name='minPrice'
                  control={control}
                  render={({ field, fieldState }) => (
                    <NumberInput
                      {...field}
                      id='minPrice'
                      label={STRINGS.MINIMUM_PRICE}
                      placeholder='$0.00'
                      allowEmpty
                      invalid={!!fieldState.error}
                      invalidText={fieldState.error?.message}
                      onChange={(_, { value }) => {
                        field.onChange(value || '');
                      }}
                      onClick={(_, data) => {
                        if (data) {
                          field.onBlur();
                          trigger('maxPrice');
                        }
                      }}
                      onBlur={() => {
                        field.onBlur();
                        trigger('maxPrice');
                      }}
                    />
                  )}
                />
              </Column>
              <Column lg={4}>
                <Controller
                  name='maxPrice'
                  control={control}
                  render={({ field, fieldState }) => (
                    <NumberInput
                      {...field}
                      id='maxPrice'
                      label={STRINGS.MAXIMUM_PRICE}
                      placeholder='$0.00'
                      allowEmpty
                      invalid={!!fieldState.error}
                      invalidText={fieldState.error?.message}
                      onChange={(_, { value }) => {
                        field.onChange(value || '');
                      }}
                      onClick={(_, data) => {
                        if (data) {
                          field.onBlur();
                          trigger('minPrice');
                        }
                      }}
                      onBlur={() => {
                        field.onBlur();
                        trigger('minPrice');
                      }}
                    />
                  )}
                />
              </Column>
            </Row>
          </section>
          <div className='rates-configuration-form__divider gs--margin-top-sp7 gs--margin-bottom-sp7' />
          <section id='date-range'>
            <div className='gs--productive-heading-02 gs--font-family-sf gs--margin-bottom-sp4'>
              {STRINGS.DATE_RANGE}
            </div>
            <div className='gs--body-short-02 gs--font-family-sf gs--margin-bottom-sp5'>
              {STRINGS.DATE_RANGE_DESCRIPTION}
            </div>
            <Row className='gs--margin-bottom-sp5'>
              <Column lg={4}>
                <Controller
                  name='startDate'
                  control={control}
                  render={({ field, fieldState }) => (
                    <DatePicker
                      id='startDate'
                      ref={field.ref}
                      labelText={STRINGS.START_DATE}
                      disabled={alwaysApplyRate}
                      minDate={minStartDate}
                      invalid={!!fieldState.error}
                      invalidText={fieldState.error?.message}
                      value={field.value as Date | string}
                      onChange={field.onChange}
                      onBlur={() => {
                        trigger('endDate');
                        field.onBlur();
                      }}
                    />
                  )}
                />
              </Column>
              <Column lg={4}>
                <Controller
                  name='endDate'
                  control={control}
                  render={({ field, fieldState }) => (
                    <DatePicker
                      id='endDate'
                      ref={field.ref}
                      labelText={STRINGS.END_DATE}
                      disabled={alwaysApplyRate}
                      minDate={minEndDate}
                      invalid={!!fieldState.error}
                      invalidText={fieldState.error?.message}
                      value={field.value as Date | string}
                      onChange={field.onChange}
                      onBlur={() => {
                        trigger('startDate');
                        field.onBlur();
                      }}
                    />
                  )}
                />
              </Column>
              <Column lg={4}>
                <Controller
                  name='alwaysApplyRate'
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      id='alwaysApplyRate'
                      className='gs--margin-top-sp7'
                      labelA={STRINGS.ALWAYS_APPLY_RATE}
                      labelB={STRINGS.ALWAYS_APPLY_RATE}
                      toggled={field.value}
                      onToggle={(value: boolean) => {
                        if (value) {
                          setValue('startDate', new Date());
                          setValue('endDate', '');
                        }
                        field.onChange(value);
                        field.onBlur();
                        trigger(['startDate', 'endDate']);
                      }}
                    />
                  )}
                />
              </Column>
            </Row>
            <Row className='gs--margin-bottom-sp6'>
              <Column lg={4}>
                <Controller
                  name='cashless'
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      id='cashless'
                      labelA={STRINGS.CASHLESS}
                      labelB={STRINGS.CASHLESS}
                      toggled={field.value}
                      onToggle={(value: boolean) => {
                        field.onChange(value);
                      }}
                    />
                  )}
                />
              </Column>
            </Row>
            <div className='gs--body-short-02 gs--font-family-sf gs--margin-bottom-sp5'>
              {STRINGS.APPLY_POST_SEASON_OR_REGULAR}
            </div>
            <Row className='gs--margin-bottom-sp5'>
              <Column>
                <Controller
                  name='postSeason'
                  control={control}
                  render={({ field, fieldState }) => (
                    <RadioButtonGroup
                      {...field}
                      name='postSeason'
                      value={`${field.value}`}
                      valueSelected={`${field.value}`}
                      invalid={!!fieldState.error}
                      invalidText={fieldState.error?.message}
                      onChange={value => {
                        field.onChange(value === 'true');
                        field.onBlur();
                      }}
                    >
                      <RadioButton
                        labelText={
                          <div className='gs--body-short-02 gs--font-family-sf gs--text-01'>{STRINGS.POST_SEASON}</div>
                        }
                        value='true'
                      />
                      <RadioButton
                        labelText={
                          <div className='gs--body-short-02 gs--font-family-sf gs--text-01'>
                            {STRINGS.REGULAR_EVENT}
                          </div>
                        }
                        value='false'
                      />
                    </RadioButtonGroup>
                  )}
                />
              </Column>
            </Row>
            <div className='gs--body-short-02 gs--font-family-sf'>{STRINGS.APPLY_FOR_DISTRIBUTION_CHANNEL}</div>
            <Row>
              <Column lg={3}>
                <Controller
                  name='distributionChannels'
                  control={control}
                  render={({ field, fieldState }) => (
                    <CheckboxGroup legendText='' invalid={!!fieldState.error} invalidText={fieldState.error?.message}>
                      <div className='group-control'>
                        <Checkbox
                          id='distributionChannels-gofan'
                          labelText={
                            <div className='gs--body-short-02 gs--font-family-sf gs--text-01'>
                              {STRINGS.GOFAN_TICKETS}
                            </div>
                          }
                          checked={field.value?.includes('GoFan')}
                          onChange={(_, { checked }) => {
                            const distributionChannels = checked
                              ? [DISTRIBUTION_CHANNEL.GOFAN, ...(field.value ?? [])]
                              : (field.value ?? []).filter(item => item !== DISTRIBUTION_CHANNEL.GOFAN);
                            field.onChange(distributionChannels);
                            field.onBlur();
                          }}
                        />
                        <Checkbox
                          id='distributionChannels-boxoffice'
                          className='gs--margin-left-sp5'
                          labelText={
                            <div className='gs--body-short-02 gs--font-family-sf gs--text-01'>
                              {STRINGS.BOXOFFICE_TICKETS}
                            </div>
                          }
                          checked={field.value?.includes('BoxOffice')}
                          onChange={(_, { checked }) => {
                            const distributionChannels = checked
                              ? [...(field.value ?? []), DISTRIBUTION_CHANNEL.BOXOFFICE]
                              : (field.value ?? []).filter(item => item !== DISTRIBUTION_CHANNEL.BOXOFFICE);
                            field.onChange(distributionChannels);
                            field.onBlur();
                          }}
                        />
                      </div>
                    </CheckboxGroup>
                  )}
                />
              </Column>
            </Row>
          </section>
          <div className='rates-configuration-form__divider gs--margin-top-sp7 gs--margin-bottom-sp7' />
          <section id='optional-settings'>
            <div className='gs--productive-heading-02 gs--font-family-sf gs--margin-bottom-sp5'>
              {STRINGS.OPTIONAL_SETTINGS}
            </div>
            <div className='gs--body-short-02 gs--font-family-sf gs--margin-bottom-sp4'>
              {STRINGS.STATES_DESCRIPTION}
            </div>
            <Row className='gs--margin-bottom-sp5'>
              <Column lg={4}>
                <Controller
                  name='states'
                  control={control}
                  render={({ field, fieldState }) => (
                    <MultiSelect
                      id='states'
                      titleText={STRINGS.STATES}
                      label={field.value?.length > 0 ? STRINGS.OPTIONS_SELECTED : STRINGS.MULTI_SELECT_PLACEHOLDER}
                      items={stateAbbreviations}
                      initialSelectedItems={stateAbbreviations.filter(item => field.value?.includes(item))}
                      invalid={!!fieldState.error}
                      invalidText={fieldState.error?.message}
                      onChange={({ selectedItems }: { selectedItems: string[] }) => {
                        field.onChange(selectedItems);
                      }}
                    />
                  )}
                />
              </Column>
            </Row>
            <div className='gs--body-short-02 gs--font-family-sf gs--margin-bottom-sp5'>
              {STRINGS.SPECIFIC_SCHOOLS_DESCRIPTION}
            </div>
            <Row className='gs--margin-bottom-sp5'>
              <Column lg={4}>
                <Controller
                  name='specificSchools'
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <input ref={field.ref} name={field.name} className='hidden' />
                      <SearchDropdown
                        id='specificSchools'
                        labelText=''
                        placeholder={STRINGS.SEARCH_PLACEHOLDER}
                        items={searchedSchools as AccountDTO[]}
                        itemToString={item => item.name}
                        itemToElement={SchoolDropdownItem}
                        loading={schoolLoading}
                        invalid={!!fieldState.error}
                        invalidText={fieldState.error?.message}
                        value={schoolSearchTerm}
                        onSearch={setSchoolSearchTerm}
                        onSelect={school => {
                          _onSelectSchool(school, field);
                          trigger('exceptionAccounts');
                        }}
                      />
                      <div className='selected-schools'>
                        {field.value?.map(school => (
                          <SchoolItem
                            key={school.id}
                            classNames='gs--margin-top-sp7'
                            school={school}
                            description={`Account Id: ${school.id}`}
                            onRemove={() => {
                              field.onChange(field.value?.filter(item => `${item.id}` !== `${school.id}`));
                              field.onBlur();
                              trigger(['specificSchools', 'exceptionAccounts']);
                            }}
                          />
                        ))}
                      </div>
                    </>
                  )}
                />
              </Column>
            </Row>
            <div className='gs--body-short-02 gs--font-family-sf gs--margin-bottom-sp5'>
              {STRINGS.EXCEPTION_SCHOOLS_DESCRIPTION}
            </div>
            <Row className='gs--margin-bottom-sp5'>
              <Column lg={4}>
                <Controller
                  name='exceptionAccounts'
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <input ref={field.ref} name={field.name} className='hidden' />
                      <SearchDropdown
                        id='exceptionAccounts'
                        labelText=''
                        placeholder={STRINGS.SEARCH_PLACEHOLDER}
                        items={searchedSchools as AccountDTO[]}
                        itemToString={item => item.name}
                        itemToElement={SchoolDropdownItem}
                        loading={schoolLoading}
                        invalid={!!fieldState.error}
                        invalidText={fieldState.error?.message}
                        value={schoolSearchTerm}
                        onSearch={setSchoolSearchTerm}
                        onSelect={school => {
                          _onSelectSchool(school, field);
                          trigger('specificSchools');
                        }}
                      />
                      <div className='selected-schools'>
                        {field.value?.map(school => (
                          <SchoolItem
                            key={school.id}
                            classNames='gs--margin-top-sp7'
                            school={school}
                            description={`Account Id: ${school.id}`}
                            onRemove={() => {
                              field.onChange(field.value?.filter(item => `${item.id}` !== `${school.id}`));
                              field.onBlur();
                              trigger(['specificSchools', 'exceptionAccounts']);
                            }}
                          />
                        ))}
                      </div>
                    </>
                  )}
                />
              </Column>
            </Row>
          </section>
        </Form>
      </FormProvider>
      <div className='rates-configuration-actions gs--margin-top-sp5'>
        <Button
          kind='secondary'
          className='cds--btn-centralize cds--btn-radius gs--margin-right__sp6'
          onClick={onCancel}
        >
          {STRINGS.CANCEL}
        </Button>
        <Button
          kind='primary'
          className='rates-configuration-actions__btn-save cds--btn-centralize cds--btn-radius'
          disabled={loading || !isValid()}
          onClick={handleSubmit(_onSave)}
        >
          {loading ? <InlineLoading status='active' description={STRINGS.SAVING} /> : STRINGS.SAVE}
        </Button>
      </div>
    </>
  );

  function _onSave(values: RatesConfigurationFormState) {
    const startDate = dayjs(values.startDate, DATE_PICKER_FORMAT_DEFAULT);
    const endDate = dayjs(values.endDate, DATE_PICKER_FORMAT_DEFAULT);
    const huddleIds = values.specificSchools?.map(item => item.id) ?? [];
    const exceptions = values.exceptionAccounts?.map(item => item.id) ?? [];
    const productTypeIds = values.productTypes?.map(item => item.id);
    const generalAdmission = values.ticketTypes?.includes(STRINGS.GENERAL_ADMISSION);
    const reservedSeating = values.ticketTypes?.includes(STRINGS.RESERVED_SEATING);
    onSave({
      ...omit(values, ['productTypes', 'ticketTypes', 'alwaysApplyRate', 'specificSchools', 'exceptionAccounts']),
      productTypeIds,
      generalAdmission,
      reservedSeating,
      startDate: startDate.isValid() ? startDate.format(DATE_FORMAT_DEFAULT) : null,
      endDate: endDate.isValid() ? endDate.format(DATE_FORMAT_DEFAULT) : null,
      huddleIds,
      exceptions
    });
  }

  function _onSelectSchool(school: AccountDTO, fieldControl: any) {
    const selectedSchools: AccountDTO[] = fieldControl.value ?? [];
    if (selectedSchools.some(item => `${item.id}` === school.id)) {
      const message =
        fieldControl.name === 'specificSchools'
          ? ERROR_MESSAGES.SPECIFIC_SCHOOLS.ADDED_IN_TABLE
          : ERROR_MESSAGES.EXCEPTION_ACCOUNTS.ADDED_IN_TABLE;
      setError(fieldControl.name, { type: 'custom', message });
    } else {
      fieldControl.onChange([...selectedSchools, school]);
      fieldControl.onBlur();
    }
  }
};
