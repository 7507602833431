import { ComboBox as CarbonComboBox } from '@carbon/react';
import classNames from 'classnames';

import type { ComboBoxProps as CarbonComboBoxProps } from '@carbon/react/lib/components/ComboBox/ComboBox';

import './ComboBox.scss';

export type ComboBoxProps = CarbonComboBoxProps<any>;

export const ComboBox = (props: ComboBoxProps) => (
  <CarbonComboBox {...props} className={classNames('gf--combo-box', props.className)} />
);
