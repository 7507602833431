import type {
  CalculatedSponsorsResponse,
  CreateDeleteSponsorResponse,
  CreateSponsorshipDealDTO,
  CreateSponsorshipPageDTO,
  SponsorDTO,
  SponsorshipDealDTO,
  SponsorshipPageDTO
} from './sponsorship.model';
import { SponsorshipRepository } from './sponsorship.repository';
import { addNotification } from '@gofan/utils';
import { config } from '@gofan/constants';

export class SponsorshipService {
  // current create logic:
  // 1. upload image
  // 2. create sponsor
  // 3. create a list of sponsorship deals based on the schoolIds
  static async createSponsorship(
    sponsorship: CreateSponsorshipPageDTO,
    tempSponsorSharedS3Bucket: boolean
  ): Promise<string> {
    const { sponsor, schoolIds, imageFile } = sponsorship;

    try {
      // step 1 - upload sponsor image
      if (imageFile) {
        let imageUrl = '';
        const encodedFileName = SponsorshipService.encodeFileName(imageFile?.name);
        if (tempSponsorSharedS3Bucket) {
          const result = await SponsorshipRepository.uploadSponsorAssetSharedS3(imageFile, encodedFileName);
          imageUrl = result.url;
        } else {
          await SponsorshipRepository.uploadSponsorAsset(imageFile, '');
          imageUrl = `https://${config.s3.BUCKET}.s3.amazonaws.com/${config.s3.BASE_SPONSOR_PATH}/${encodedFileName}`;
        }

        sponsor.asset = imageUrl || '';
        sponsor.logoUrl = imageUrl || '';
      }

      // step 2 - create sponsor
      const sponsorResponse = await SponsorshipService.createSponsor(sponsor);
      const sponsorId = sponsorResponse.id || sponsor.id;

      // step 3 - create a list of sponsorship deals
      if (!schoolIds || schoolIds.length === 0) {
        // change logic to not create a deal if it's no schoolIds
        // keep the commented code below just in case in the future we can create a deal without schoolIds (for example with only eventIds)
        // await SponsorshipService.createSponsorshipDeal(sponsorId, '');
        return sponsorId;
      }

      const dealPromises = schoolIds.map(schoolId =>
        SponsorshipService.createSponsorshipDeal(sponsorId, schoolId).then(() => {
          addNotification({
            type: 'info',
            message: `Sponsorship deal for school ID ${schoolId} created successfully.`,
            options: { duration: 7000 }
          });
        })
      );
      await Promise.all(dealPromises);

      return sponsorId;
    } catch (error) {
      throw new Error(
        error.response?.data?.error || error.message || 'An error occurred while creating the sponsorship.'
      );
    }
  }

  static async createSponsor(sponsor: SponsorDTO): Promise<CreateDeleteSponsorResponse> {
    if (sponsor.id) {
      // If the sponsor already has an ID, return it as a resolved promise
      return Promise.resolve({ id: sponsor.id, message: 'Sponsor already exists.', error: '', status: 409 });
    }
    return SponsorshipRepository.createSponsor(sponsor);
  }

  // eslint-disable-next-line consistent-return
  static deleteSponsor(sponsor: SponsorDTO): Promise<CreateDeleteSponsorResponse> {
    return SponsorshipRepository.deleteSponsor(sponsor?.sponsorId);
  }

  static async createSponsorshipDeal(sponsorId: string, schoolId: string): Promise<CreateDeleteSponsorResponse> {
    const sponsorshipDeal: CreateSponsorshipDealDTO = {
      sponsorId,
      sponsoredSchoolId: schoolId,
      inactiveReason: '',
      paymentDetails: {
        dealAmount: 0,
        fee: 0,
        hiddenFee: false,
        hiddenTax: false,
        paid: false,
        tax: 0
      },
      paymentPageLink: '',
      period: {
        startDate: '2024-08-01',
        endDate: '2025-07-31'
      },
      sponsoredEventsId: [],
      visibilityLocation: ['GoFanDigitalTickets']
    };
    return SponsorshipRepository.createSponsorshipDeal(sponsorshipDeal);
  }

  static async getSponsorships(schoolIds: string[]): Promise<any> {
    return SponsorshipRepository.getSponsorships(schoolIds);
  }

  static async getSponsorshipPage(sponsorId: string): Promise<SponsorshipPageDTO | null> {
    const deals = await SponsorshipRepository.getListSponsorshipDeals(sponsorId);
    if (deals && deals.length > 0) {
      return {
        sponsor: deals[0].sponsor,
        // Get unique school IDs from the deals
        // in the future this may change - duplicate school could be multiple school year or different visibility locations
        schoolIds: [...new Set(deals.map(deal => deal.sponsoredSchoolId))],
        sponsorshipDeals: deals
      };
    }
    // If no deals are found, return the sponsor without any deals
    // handle the case where the sponsor exists but has no deals associate with it
    const sponsor = await SponsorshipRepository.getSponsor(sponsorId);
    if (sponsor) {
      return {
        sponsor,
        schoolIds: [],
        sponsorshipDeals: []
      };
    }
    throw new Error('Sponsor not found.');
  }

  // current update logic:
  // 1. upload image if different
  // 2. update sponsor
  // 3. either create or delete sponsorship deals based on the schoolIds
  // so far no logic on update sponsorship deals, because no other fields can be updated
  static async updateSponsorship(
    sponsorship: CreateSponsorshipPageDTO,
    tempSponsorSharedS3Bucket: boolean
  ): Promise<void> {
    try {
      const previousSponsorship = await SponsorshipService.getSponsorshipPage(sponsorship.sponsor.id);
      const { sponsor, schoolIds, imageFile } = sponsorship;
      const previousSponsor = previousSponsorship?.sponsor;

      // step 1 - upload sponsor image if different
      if (imageFile) {
        const encodedFileName = SponsorshipService.encodeFileName(imageFile?.name);

        if (SponsorshipService.extractFileName(previousSponsor?.asset) !== encodedFileName) {
          let imageUrl = '';
          const previousAssetName = SponsorshipService.extractFileName(previousSponsor?.asset);

          if (tempSponsorSharedS3Bucket) {
            const result = await SponsorshipRepository.uploadSponsorAssetSharedS3(
              imageFile,
              encodedFileName,
              previousAssetName
            );
            imageUrl = result.url;
          } else {
            await SponsorshipRepository.uploadSponsorAsset(imageFile, previousAssetName);
            imageUrl = `https://${config.s3.BUCKET}.s3.amazonaws.com/${config.s3.BASE_SPONSOR_PATH}/${encodedFileName}`;
          }

          sponsor.asset = imageUrl || '';
          sponsor.logoUrl = imageUrl || '';
        } else {
          sponsor.asset = previousSponsor?.asset || '';
          sponsor.logoUrl = previousSponsor?.logoUrl || '';
        }
      } else {
        sponsor.asset = previousSponsor?.asset || '';
        sponsor.logoUrl = previousSponsor?.logoUrl || '';
      }

      // step 2 - update sponsor
      if (SponsorshipService.isUpdateSponsor(previousSponsor, sponsor)) {
        await SponsorshipRepository.updateSponsor(sponsor);
      }
      // step 3 - either create or delete sponsorship deals based on the schoolIds
      await SponsorshipService.updateSponsorshipDealsBySchoolIds(
        sponsor.id,
        schoolIds,
        previousSponsorship?.schoolIds || [],
        previousSponsorship?.sponsorshipDeals || []
      );
    } catch (error) {
      throw new Error(
        error.response?.data?.error || error.message || 'An error occurred while updating the sponsorship.'
      );
    }
  }

  // so far no update sponsorship deals, only create and delete based on the schoolIds
  static async updateSponsorshipDealsBySchoolIds(
    sponsorId: string,
    schoolIds: string[],
    previousSchoolIds: string[],
    sponsorshipDealsList: SponsorshipDealDTO[]
  ) {
    const addedSchoolIds = schoolIds?.filter(id => !previousSchoolIds.includes(id));
    const removedSchoolIds = previousSchoolIds?.filter(id => !schoolIds.includes(id));
    const createPromises = addedSchoolIds.map(schoolId =>
      SponsorshipService.createSponsorshipDeal(sponsorId, schoolId).then(() => {
        addNotification({
          type: 'info',
          message: `Sponsorship deal for school ID ${schoolId} created successfully.`,
          options: { duration: 7000 }
        });
      })
    );

    await Promise.all(createPromises);

    const deletePromises = removedSchoolIds.map(schoolId => {
      const deal = sponsorshipDealsList.find(deal => deal.sponsoredSchoolId === schoolId);
      if (deal) {
        return SponsorshipRepository.deleteSponsorshipDeal(deal.id).then(() => {
          addNotification({
            type: 'info',
            message: `Sponsorship deal for school ID ${schoolId} deleted successfully.`,
            options: { duration: 7000 }
          });
        });
      }
      return Promise.resolve();
    });
    try {
      await Promise.all([...createPromises, ...deletePromises]);
    } catch (error) {
      console.error('Error:', error);
      throw new Error(
        error.response?.data?.error || error.message || 'An error occurred while updating the sponsorship.'
      );
    }
  }

  static extractFileName(url) {
    if (!url) {
      return '';
    }
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  static isUpdateSponsor(previousSponsor: SponsorDTO | undefined, sponsor: SponsorDTO): boolean {
    if (!previousSponsor) {
      return true;
    }
    return (
      (sponsor?.logoUrl !== '' && previousSponsor?.logoUrl !== sponsor?.logoUrl) ||
      (sponsor?.asset !== '' && previousSponsor?.asset !== sponsor?.asset) ||
      previousSponsor?.name !== sponsor?.name ||
      previousSponsor?.type !== sponsor?.type ||
      previousSponsor?.contact?.email !== sponsor?.contact?.email ||
      previousSponsor?.contact?.phone !== sponsor?.contact?.phone ||
      previousSponsor?.tagline !== sponsor?.tagline ||
      previousSponsor?.clickThroughLink !== sponsor?.clickThroughLink ||
      previousSponsor?.customSchoolId !== sponsor?.customSchoolId
    );
  }

  static async getCalculatedSponsors(schoolId: string): Promise<CalculatedSponsorsResponse> {
    try {
      return await SponsorshipRepository.listSponsors(schoolId);
    } catch (error) {
      throw new Error(
        error.response?.data?.error || error.message || 'An error occurred while fetching the calculated sponsors.'
      );
    }
  }

  static encodeFileName = (fileName: string) => {
    // Extract the file extension
    const fileExtension = fileName.substring(fileName.lastIndexOf('.'));

    // Remove special characters from the base file name
    const baseFileName = fileName.substring(0, fileName.lastIndexOf('.')).replace(/[^a-zA-Z0-9\s]/g, '');

    // Replace spaces with underscores in the base file name
    const sanitizedBaseFileName = baseFileName.replace(/\s+/g, '_');

    // Add dateString at the end of the base file name
    const dateString = new Date().toISOString().slice(0, 10).replace(/-/g, ''); // YYYYMMDD format
    return `${sanitizedBaseFileName}_${dateString}${fileExtension}`;
  };
}
