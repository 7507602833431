// @flow

import { connect } from 'react-redux';

import SystemError from './components/SystemError';
import { checkServerOnMaintenance } from '../../root/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  checkServerOnMaintenance
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemError);
