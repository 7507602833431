import { ActivityRepository } from './activity.repository';

import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { ATHLETIC_ACTIVITIES_EXCLUDE, EVENT_TYPES } from '@gofan/constants';
import type { ActivityDTO, GroupedActivities } from './activity.model';

export const COMMON_SPORTS = [
  'Baseball',
  'Basketball',
  'Football',
  'Lacrosse',
  'Soccer',
  'Softball',
  'Track and Field',
  'Volleyball',
  'Wrestling'
];

export const COMMON_SPORTS_LOWERCASE = COMMON_SPORTS.map(name => name.toLocaleLowerCase());

export class ActivityService {
  static getAllActivities = (): Promise<ActivityDTO[]> => ActivityRepository.getAllActivities();

  static getActivityById = (activityId: string): Promise<ActivityDTO> => ActivityRepository.getActivityById(activityId);

  static bubbleUpCommonSports = (activities: ActivityDTO[]) => {
    const commonSports: ActivityDTO[] = [];
    COMMON_SPORTS_LOWERCASE.forEach(sport => {
      const foundSport = activities.find(activity => activity.label.toLocaleLowerCase() === sport);
      if (foundSport) commonSports.push(foundSport);
    });
    const excludeCommonSports = activities.filter(
      activity => !COMMON_SPORTS_LOWERCASE.includes(activity.label.toLocaleLowerCase())
    );
    return [...commonSports, ...excludeCommonSports];
  };

  static groupActivities = (allActivities: ActivityDTO[]): GroupedActivities => {
    let athleticActivities: ActivityDTO[] = [];
    let nonAthleticActivities: ActivityDTO[] = [];
    const totalList = { athleticActivities, nonAthleticActivities };
    const newActivities = allActivities.reduce((data, currentActivity) => {
      if (!currentActivity.disabled) {
        currentActivity.athletic
          ? data.athleticActivities.push(currentActivity)
          : data.nonAthleticActivities.push(currentActivity);
      }
      return data;
    }, totalList);

    athleticActivities = newActivities.athleticActivities.sort((a, b) => a.label.localeCompare(b.label));
    nonAthleticActivities = newActivities.nonAthleticActivities.sort((a, b) => a.label.localeCompare(b.label));

    return {
      athleticActivities: this.bubbleUpCommonSports(totalList.athleticActivities),
      nonAthleticActivities: totalList.nonAthleticActivities
    };
  };

  static mappingActivitiesByType = ({
    activities,
    activityType
  }: {
    activities: ActivityDTO[];
    activityType: string;
  }) => {
    const { athleticActivities = [], nonAthleticActivities = [] } = this.groupActivities(activities);
    const allowActivities = EVENT_TYPES.CONFIG[activityType].ACTIVITIES;
    const activitiesOutOfOtherType = [
      EVENT_TYPES.SCHOOL_DANCE,
      EVENT_TYPES.REGISTRATION,
      EVENT_TYPES.PERFORMING_ART,
      EVENT_TYPES.PROM,
      EVENT_TYPES.FUNDRAISER
    ];
    let activityList = [] as ActivityDTO[];
    if (activityType === EVENT_TYPES.ATHLETIC) {
      activityList = [...athleticActivities].filter(
        activity => activity?.label.toUpperCase() !== ATHLETIC_ACTIVITIES_EXCLUDE
      );
    } else if (
      activityType === EVENT_TYPES.SCHOOL_DANCE ||
      activityType === EVENT_TYPES.REGISTRATION ||
      activityType === EVENT_TYPES.PERFORMING_ART
    ) {
      activityList = [...athleticActivities, ...nonAthleticActivities].filter(({ label }) =>
        allowActivities.includes(label)
      );
    } else if (activityType === EVENT_TYPES.OTHER) {
      activityList = [...nonAthleticActivities].filter(({ label }) => !activitiesOutOfOtherType.includes(label));
    } else {
      activityList = [...athleticActivities, ...nonAthleticActivities];
    }

    // sort by [name, orderBy]
    activityList = isEmpty(activityList) ? [] : orderBy([...activityList], ['label'], 'asc');
    activityList = this.bubbleUpCommonSports(activityList);

    return activityList;
  };
}
