import uniq from 'lodash/uniq';
import upperFirst from 'lodash/upperFirst';

import { FlatfileRepository } from './flatfile.repository';

import type { SpaceConfig } from './flatfile.model';

import type { LevelDTO, TeamOption } from '@gofan/api/levels';
import { UPPERCASE_GENDER_TYPES } from '@gofan/constants/level';
import { WorkbookType, UPLOAD_SEASON_FLOW } from '@gofan/constants/flatfile';

import { DateUtils } from '@gofan/utils/date';

export class FlatfileService {
  static getFlatfileToken = (payload: any) => FlatfileRepository.getFlatfileToken(payload);

  static getFlatfileRecords = (sheetId: string) => FlatfileRepository.getFlatfileRecords(sheetId);

  static getTeamOptions = (
    spaceConfig: SpaceConfig
  ): {
    uploadFlow: string;
    teamOptions: TeamOption[];
  } => {
    const { creatingSeason, selectedSports } = spaceConfig?.importData ?? {};
    const { sport, genders, levelsByGender } = creatingSeason ?? {};
    const optionSports = sport?.label ? [sport?.label] : [];
    const teamOptions: TeamOption[] = [];
    let uploadFlow = '';

    if (spaceConfig?.workbookType === WorkbookType.SEASON_MULTIPLE_SPORTS) {
      uploadFlow = UPLOAD_SEASON_FLOW.MULTI_SPORTS;

      teamOptions.push({
        sports: (selectedSports ?? []).map(item => item.label) || [],
        genders: [],
        levels: []
      });
    } else if (spaceConfig?.workbookType === WorkbookType.SEASON_SEPARATE_EACH_TEAM) {
      uploadFlow = UPLOAD_SEASON_FLOW.SEPARATE_EACH_TEAM;

      (genders ?? []).forEach((gender: string) => {
        (levelsByGender?.[gender] ?? []).forEach((level: LevelDTO) => {
          teamOptions.push({
            sports: optionSports,
            genders: gender === UPPERCASE_GENDER_TYPES.COED ? [] : [upperFirst(`${gender}`.toLowerCase())],
            levels: [level.name]
          });
        });
      });
    } else if (spaceConfig?.workbookType === WorkbookType.SEASON_ONE_PER_SPORT) {
      const optionGenders: string[] = [];
      let optionLevels: string[] = [];

      uploadFlow = UPLOAD_SEASON_FLOW.ONE_PER_SPORT;

      (genders ?? []).forEach((gender: string) => {
        if (gender !== UPPERCASE_GENDER_TYPES.COED) {
          optionGenders.push(upperFirst(`${gender}`.toLowerCase()));
        }
        optionLevels = uniq([...optionLevels, ...(levelsByGender?.[gender] ?? []).map(level => level.name)]);
      });

      teamOptions.push({
        sports: sport?.label ? [sport?.label] : [],
        genders: optionGenders,
        levels: optionLevels
      });
    }

    return { uploadFlow, teamOptions };
  };

  static getSeasonEnvConfig = (spaceConfig: SpaceConfig) => {
    const { account } = spaceConfig?.importData ?? {};

    const timeZone = DateUtils.getTimeZone(account?.timeZone);

    const { uploadFlow, teamOptions } = FlatfileService.getTeamOptions(spaceConfig);

    return {
      importData: spaceConfig?.importData,
      metadata: {
        ...spaceConfig?.metadata,
        timeZone,
        uploadFlow,
        teamOptions
      }
    };
  };
}
