// @flow
import { sliceByKeys } from '../../utils/objectUtils';

class TicketDTO {
  constructor(props) {
    const { result, rest } = sliceByKeys({
      obj: props,
      keys: [
        'id',
        'accountId',
        'accountProductId',
        'hiddenFees',
        'distributionChannel',
        'enabled',
        'eventId',
        'fee',
        'formId',
        'formFields',
        'limit',
        'name',
        'packCount',
        'price',
        'productType',
        'promotionLabel',
        'promotionRequired',
        'redemptionLimit',
        'reservedSeating',
        'salesEndDateTime',
        'salesStartDateTime',
        'ticketLimitPerOrder',
        'seasonId',
        'seatsIoCategory',
        'generateLink',
        'customColor',
        'groupId',
        'encodedString'
      ],
      omit: ['_links']
    });
    this.raw = { ...result, restData: rest };
  }

  get restData() {
    return this.raw.restData;
  }

  get id() {
    return this.raw.id;
  }

  get accountId() {
    return this.raw.accountId;
  }

  get accountProductId() {
    return this.raw.accountProductId;
  }

  get hiddenFees() {
    return this.raw.hiddenFees;
  }

  get distributionChannel() {
    return this.raw.distributionChannel;
  }

  get enabled() {
    return this.raw.enabled;
  }

  get eventId() {
    return this.raw.eventId;
  }

  get fee() {
    return this.raw.fee;
  }

  get formId() {
    return this.raw.formId;
  }

  get formFields() {
    return this.raw.formFields;
  }

  get limit() {
    return this.raw.limit;
  }

  get name() {
    return this.raw.name;
  }

  get packCount() {
    return this.raw.packCount;
  }

  get price() {
    return this.raw.price;
  }

  get productType() {
    return this.raw.productType;
  }

  get promotionLabel() {
    return this.raw.promotionLabel;
  }

  get promotionRequired() {
    return this.raw.promotionRequired;
  }

  get redemptionLimit() {
    return this.raw.redemptionLimit;
  }

  get reservedSeating() {
    return this.raw.reservedSeating;
  }

  get salesEndDateTime() {
    return this.raw.salesEndDateTime;
  }

  get salesStartDateTime() {
    return this.raw.salesStartDateTime;
  }

  get ticketLimitPerOrder() {
    return this.raw.ticketLimitPerOrder;
  }

  get seasonId() {
    return this.raw.seasonId;
  }

  get seatsIoCategory() {
    return this.raw.seatsIoCategory;
  }

  get generateLink() {
    return this.raw.generateLink;
  }

  get customColor() {
    return this.raw.customColor;
  }

  get groupId() {
    return this.raw.groupId;
  }

  get encodedString() {
    return this.raw.encodedString;
  }

  toJSON() {
    return {
      restData: this.restData,
      id: this.id,
      accountId: this.accountId,
      accountProductId: this.accountProductId,
      hiddenFees: this.hiddenFees,
      distributionChannel: this.distributionChannel,
      enabled: this.enabled,
      eventId: this.eventId,
      fee: this.fee,
      formId: this.formId,
      formFields: this.formFields,
      limit: this.limit,
      name: this.name,
      packCount: this.packCount,
      price: this.price,
      productType: this.productType,
      promotionLabel: this.promotionLabel,
      promotionRequired: this.promotionRequired,
      redemptionLimit: this.redemptionLimit,
      reservedSeating: this.reservedSeating,
      salesEndDateTime: this.salesEndDateTime,
      salesStartDateTime: this.salesStartDateTime,
      ticketLimitPerOrder: this.ticketLimitPerOrder,
      seasonId: this.seasonId,
      seatsIoCategory: this.seatsIoCategory,
      generateLink: this.generateLink,
      customColor: this.customColor,
      groupId: this.groupId,
      encodedString: this.encodedString
    };
  }
}

export default TicketDTO;
