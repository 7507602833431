import React, { useMemo } from 'react';
import { Row, Column, ModalBody, ModalHeader, ComposedModal } from 'carbon-components-react';
import { find } from 'lodash';

import { config } from '@gofan/constants';
import BasicTable from '@old-components/basic-table/basic-table.component';

import type { AccountDTO } from '@gofan/api/accounts';
import CopyLink from '@accounts/components/copy-link/copy-link.component';
import CopyButton from '@common/carbon-ui/atoms/CopyButton';
import { Download16 } from '@carbon/icons-react';
import { QR_DOWNLOAD_TYPE } from '@app/commons/generic-components/qr-code/constants';
import { handleDownloadQRCode } from '@app/commons/generic-components/qr-code/qr-code.utils';
import defaultSchoolIcon from '@assets/images/gofan-icon.png';
import { isEmpty } from '@app/utils/objectUtils';

export interface GoFanPageLinksProps {
  open: boolean;
  onCloseModal: () => void;
  accounts: AccountDTO[];
}

const GoFanPagePopup = ({ open, accounts, onCloseModal }: GoFanPageLinksProps) => {
  const gridConfig = useMemo(() => ({ rowConfig: { id: 'id' } }), []);
  const columns = useMemo(
    () => [
      {
        index: 'name',
        name: 'School',
        textEllipsis: true
      },
      {
        index: 'gofanPageEnabled',
        name: 'Link to GoFan page',
        render: (value: boolean, data: any) =>
          value ? (
            <CopyLink
              hideButton
              title=''
              url={`${config.GOFAN_URL}app/school/${data.rowId}`}
              copyConfig={{
                feedback: 'Link copied!',
                tooltipText: 'Copy link',
                feedbackTimeout: 2000
              }}
            />
          ) : (
            'Not created'
          )
      },
      {
        key: 'copyLink',
        index: 'id',
        name: '',
        style: {
          width: '60px'
        },
        render: (_: void, data: any) => {
          const found = find(accounts, { id: data.rowId });
          return found && found.gofanPageEnabled && <CopyButton link={`${config.GOFAN_URL}app/school/${data.rowId}`} />;
        }
      },
      {
        key: 'QRCode',
        index: 'qaCode',
        name: 'School QR code',
        style: {
          textAlign: 'center'
        },
        render: (_: void, data: any) => {
          const found = find(accounts, { id: data.rowId });
          return (
            found &&
            found.gofanPageEnabled && (
              <div className='download-button'>
                <div
                  aria-hidden='true'
                  onClick={() =>
                    handleDownloadQRCode({
                      qrCode: `${config.GOFAN_URL}app/school/${data.rowId}?utm_source=GoFan&utm_medium=qrcode&utm_campaign=HQDashboardSchoolLink`,
                      button: {
                        type: QR_DOWNLOAD_TYPE.DOWNLOAD_IMAGE.type,
                        fileName: `QR code_${found.name}`
                      }
                    })
                  }
                >
                  <Download16 />
                </div>
              </div>
            )
          );
        }
      },
      {
        key: 'QRCodeFlyer',
        index: 'qaCodeFlyer',
        name: 'School QR code flyer',
        style: {
          textAlign: 'center'
        },
        render: (_: void, data: any) => {
          const found = find(accounts, { id: data.rowId });
          return (
            found &&
            found.gofanPageEnabled && (
              <div className='download-button'>
                <div
                  aria-hidden='true'
                  onClick={() =>
                    handleDownloadQRCode({
                      qrCode: `${config.GOFAN_URL}app/school/${data.rowId}?utm_source=GoFan&utm_medium=qrcode&utm_campaign=HQDashboardSchoolLink`,
                      button: {
                        type: QR_DOWNLOAD_TYPE.DOWNLOAD_FLYER.type,
                        schoolLogo: isEmpty(found.logo) ? defaultSchoolIcon : found.logo,
                        fileName: `QR code_${found.name}`
                      }
                    })
                  }
                >
                  <Download16 />
                </div>
              </div>
            )
          );
        }
      }
    ],
    [accounts]
  );

  return (
    <ComposedModal open={open} onClose={onCloseModal} className='dashboard--gofan-page-modal'>
      <ModalHeader>
        <div className='gs--section-description-large gs--font-family-san gs--text-01'>
          Links to selected school GoFan pages
        </div>
      </ModalHeader>
      <ModalBody hasForm>
        <Row>
          <Column>
            <div className='gs--padding-bottom__sp5 gs--font-family-san gs--iconic-text gs--text-01'>
              Here are the links to the GoFan pages for all the schools you selected
            </div>
          </Column>
        </Row>
        <Row>
          <Column md={8} lg={12}>
            <BasicTable columns={columns} grid={gridConfig} data={accounts} />
          </Column>
        </Row>
      </ModalBody>
    </ComposedModal>
  );
};

export default GoFanPagePopup;
