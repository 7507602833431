export const formatNumber = (num = 0) => new Intl.NumberFormat().format(num);

export const formatCurrency = (num = 0, maximumFractionDigits = 2) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits
  }).format(num);

let uniqueCounter = 0;
export const getUniqueId = () => {
  uniqueCounter += 1;
  return new Date().getTime() + uniqueCounter;
};

export const convertStripeCurrency = (amount: string) => formatCurrency(Number(amount) / 100);

export const formatMoney = (inputValue, decimalCount = 2, decimal = '.', thousands = ',') => {
  let amount = inputValue;
  try {
    if (!amount) {
      amount = 0;
    }
    let stdDecimalCount = Math.abs(decimalCount);
    stdDecimalCount = Number.isNaN(stdDecimalCount) ? 2 : stdDecimalCount;

    const negativeSign = amount < 0 ? '-' : '';
    const stdAmount = Math.abs(Number(amount) || 0).toFixed(stdDecimalCount);
    const i = Number.parseInt(stdAmount, 10);
    const iStr = i.toString();
    const j = iStr.length > 3 ? iStr.length % 3 : 0;
    const amountWithoutCurrency =
      negativeSign +
      (j ? iStr.substring(0, j) + thousands : '') +
      iStr.substring(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (stdDecimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(stdDecimalCount)
            .slice(2)
        : '');

    return `${amountWithoutCurrency}`;
  } catch (e) {
    return amount.toString();
  }
};

export const stringToNumber = (input: string, defaultValue: any = null) => {
  if (input === null || input === undefined || input === '') {
    return defaultValue;
  }
  const result = Number(input);
  return Number.isNaN(result) ? defaultValue : result;
};
