// @flow
import TicketDTO from './TicketDTO';
import EmbeddedDTO from './EmbeddedDTO';
import { isEmpty, sliceByKeys } from '../../utils/objectUtils';

class EventDTO {
  constructor(props) {
    const { result, rest } = sliceByKeys({
      obj: props,
      keys: [
        'id',
        '_embedded',
        'accountId',
        'activityId',
        'activityPath',
        'alert',
        'allDayEvent',
        'archived',
        'awayGame',
        'description',
        'disableQr',
        'disabledForIndividualSale',
        'emailMessageId',
        'enableEventValidation',
        'endDateTime',
        'eventNotes',
        'eventTypeId',
        'eventValidationStartsBefore',
        'eventIntegrationDetails',
        'featured',
        'featuredAccountIds',
        'financialAccountId',
        'formId',
        'formFields',
        'genders',
        'headerImage',
        'hostAccountId',
        'levelIds',
        'maxCapacity',
        'name',
        'opponentAccountId',
        'partnerId',
        'postSeason',
        'paymentCycle',
        'products',
        'redemptionWindow',
        'salesEndDateTime',
        'salesStartDateTime',
        'seasonId',
        'specialEventDescription',
        'startDateTime',
        'state',
        'taggedAccountIds',
        'termsAndConditions',
        'theme',
        'ticketDistribution',
        'ticketLimitPerOrder',
        'timeZone',
        'venueId',
        'customSportName',
        'hiddenFromBoxOffice',
        'levels',
        'publishDateTime',
        'reportingLabel',
        'venueAddress',
        'venueCity',
        'venueLocation',
        'venueName',
        'venueState',
        'venueZip'
      ],
      omit: ['_links'],
      mapping: { _embedded: 'embedded' }
    });
    this.raw = { ...result, restData: rest };
  }

  get restData() {
    return this.raw.restData;
  }

  get id() {
    return this.raw.id;
  }

  get embedded() {
    return isEmpty(this.raw.embedded) ? {} : new EmbeddedDTO(this.raw.embedded);
  }

  get accountId() {
    return this.raw.accountId;
  }

  get activityId() {
    return this.raw.activityId;
  }

  get activityPath() {
    return this.raw.activityPath;
  }

  get alert() {
    return this.raw.alert;
  }

  get allDayEvent() {
    return this.raw.allDayEvent;
  }

  get archived() {
    return this.raw.archived;
  }

  get description() {
    return this.raw.description;
  }

  get disableQr() {
    return this.raw.disableQr;
  }

  get disabledForIndividualSale() {
    return this.raw.disabledForIndividualSale;
  }

  get emailMessageId() {
    return this.raw.emailMessageId;
  }

  get enableEventValidation() {
    return this.raw.enableEventValidation;
  }

  get endDateTime() {
    return this.raw.endDateTime;
  }

  get eventNotes() {
    return this.raw.eventNotes;
  }

  get eventTypeId() {
    return this.raw.eventTypeId;
  }

  get eventValidationStartsBefore() {
    return this.raw.eventValidationStartsBefore;
  }

  get eventIntegrationDetails() {
    return this.raw.eventIntegrationDetails;
  }

  get featured() {
    return this.raw.featured;
  }

  get featuredAccountIds() {
    return this.raw.featuredAccountIds;
  }

  get financialAccountId() {
    return this.raw.financialAccountId;
  }

  get formId() {
    return this.raw.formId;
  }

  get formFields() {
    return this.raw.formFields;
  }

  get genders() {
    return this.raw.genders;
  }

  get headerImage() {
    return this.raw.headerImage;
  }

  get hostAccountId() {
    return this.raw.hostAccountId;
  }

  get levelIds() {
    return this.raw.levelIds;
  }

  get maxCapacity() {
    return this.raw.maxCapacity;
  }

  get name() {
    return this.raw.name;
  }

  get opponentAccountId() {
    return this.raw.opponentAccountId;
  }

  get partnerId() {
    return this.raw.partnerId;
  }

  get postSeason() {
    return this.raw.postSeason;
  }

  get paymentCycle() {
    return this.raw.paymentCycle;
  }

  get products() {
    return isEmpty(this.raw.products) ? [] : this.raw.products.map(product => new TicketDTO(product));
  }

  get redemptionWindow() {
    return this.raw.redemptionWindow;
  }

  get salesEndDateTime() {
    return this.raw.salesEndDateTime;
  }

  get salesStartDateTime() {
    return this.raw.salesStartDateTime;
  }

  get seasonId() {
    return this.raw.seasonId;
  }

  get specialEventDescription() {
    return this.raw.specialEventDescription;
  }

  get startDateTime() {
    return this.raw.startDateTime;
  }

  get taggedAccountIds() {
    return this.raw.taggedAccountIds;
  }

  get termsAndConditions() {
    return this.raw.termsAndConditions;
  }

  get theme() {
    return this.raw.theme;
  }

  get ticketDistribution() {
    return this.raw.ticketDistribution;
  }

  get ticketLimitPerOrder() {
    return this.raw.ticketLimitPerOrder;
  }

  get timeZone() {
    return this.raw.timeZone;
  }

  get venueId() {
    return this.raw.venueId;
  }

  get customSportName() {
    return this.raw.customSportName;
  }

  get hiddenFromBoxOffice() {
    return this.raw.hiddenFromBoxOffice;
  }

  get levels() {
    return this.raw.levels;
  }

  get publishDateTime() {
    return this.raw.publishDateTime;
  }

  get reportingLabel() {
    return this.raw.reportingLabel;
  }

  get venueAddress() {
    return this.raw.venueAddress;
  }

  get venueCity() {
    return this.raw.venueCity;
  }

  get venueLocation() {
    return this.raw.venueLocation;
  }

  get venueName() {
    return this.raw.venueName;
  }

  get venueState() {
    return this.raw.venueState;
  }

  get venueZip() {
    return this.raw.venueZip;
  }

  get awayGame() {
    return this.raw.awayGame;
  }

  get state() {
    return this.raw.state;
  }

  toJSON() {
    return {
      restData: this.restData,
      id: this.id,
      embedded: this.embedded,
      accountId: this.accountId,
      activityId: this.activityId,
      activityPath: this.activityPath,
      alert: this.alert,
      allDayEvent: this.allDayEvent,
      archived: this.archived,
      awayGame: this.awayGame,
      description: this.description,
      disableQr: this.disableQr,
      disabledForIndividualSale: this.disabledForIndividualSale,
      emailMessageId: this.emailMessageId,
      enableEventValidation: this.enableEventValidation,
      endDateTime: this.endDateTime,
      eventNotes: this.eventNotes,
      eventTypeId: this.eventTypeId,
      eventValidationStartsBefore: this.eventValidationStartsBefore,
      eventIntegrationDetails: this.eventIntegrationDetails,
      featured: this.featured,
      featuredAccountIds: this.featuredAccountIds,
      financialAccountId: this.financialAccountId,
      formId: this.formId,
      formFields: this.formFields,
      genders: this.genders,
      headerImage: this.headerImage,
      hostAccountId: this.hostAccountId,
      levelIds: this.levelIds,
      maxCapacity: this.maxCapacity,
      name: this.name,
      opponentAccountId: this.opponentAccountId,
      partnerId: this.partnerId,
      postSeason: this.postSeason,
      paymentCycle: this.paymentCycle,
      products: this.products,
      redemptionWindow: this.redemptionWindow,
      salesEndDateTime: this.salesEndDateTime,
      salesStartDateTime: this.salesStartDateTime,
      seasonId: this.seasonId,
      specialEventDescription: this.specialEventDescription,
      startDateTime: this.startDateTime,
      state: this.state,
      taggedAccountIds: this.taggedAccountIds,
      termsAndConditions: this.termsAndConditions,
      theme: this.theme,
      ticketDistribution: this.ticketDistribution,
      ticketLimitPerOrder: this.ticketLimitPerOrder,
      timeZone: this.timeZone,
      venueId: this.venueId,
      customSportName: this.customSportName,
      hiddenFromBoxOffice: this.hiddenFromBoxOffice,
      levels: this.levels,
      publishDateTime: this.publishDateTime,
      reportingLabel: this.reportingLabel,
      venueAddress: this.venueAddress,
      venueCity: this.venueCity,
      venueLocation: this.venueLocation,
      venueName: this.venueName,
      venueState: this.venueState,
      venueZip: this.venueZip
    };
  }
}

export default EventDTO;
