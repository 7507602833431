import { Dropdown, TextInput, Button, InlineLoading } from '@gofan/components';
import { useState } from 'react';
import EventService from '@events/services/events.service';
import { ReportsService } from '@gofan/api/reports';
import { DATE_FORMAT_DEFAULT } from '@gofan/constants';
import { formatDateTime } from '@app/utils/dateUtils';
import { saveAs } from 'file-saver';
import { addNotification } from '@gofan/utils/toast';
import { NOTIFICATION_TIME_OUT } from '@app/modules/seasons/constants/constants';
import type { AccountDTO } from '@gofan/api';
import { EventSeasonSummaryTypesEnum } from '@gofan/api/reports/reports.model';
import SeasonService from '@seasons/services/season.service';

import './summary-reports.component.scss';

export type DropdownItemType = {
  id: EventSeasonSummaryTypesEnum;
  label: EventSeasonSummaryTypesEnum;
};

const REPORT_TYPE: DropdownItemType[] = [
  {
    id: EventSeasonSummaryTypesEnum.EVENT,
    label: EventSeasonSummaryTypesEnum.EVENT
  },
  {
    id: EventSeasonSummaryTypesEnum.SEASON,
    label: EventSeasonSummaryTypesEnum.SEASON
  }
];

export type SummaryReportsProps = {
  selectedAccount: AccountDTO;
};

const SummaryReports = ({ selectedAccount }: SummaryReportsProps) => {
  const [selectedReportType, setSelectedReportType] = useState<DropdownItemType>(REPORT_TYPE[0]);
  const [loading, setLoading] = useState<boolean>(false);
  const [textInputValue, setTextInputValue] = useState<string>('');

  return (
    <div className='gf-summary-reports'>
      <Dropdown
        className='gs--margin-bottom-sp3 gs--margin-top-sp3'
        id='dropdown-report-type'
        label='lbl'
        titleText='Choose event/season'
        selectedItem={selectedReportType}
        itemToString={item => (item ? item.label : '')}
        items={REPORT_TYPE}
        onChange={e => {
          setSelectedReportType(e.selectedItem as DropdownItemType);
          setTextInputValue('');
        }}
      />
      <TextInput
        id='event-season-report-id'
        labelText={`Enter ${
          selectedReportType.label === EventSeasonSummaryTypesEnum.EVENT ? 'an' : 'a'
        } ${selectedReportType.label.toLowerCase()} ID`}
        value={textInputValue}
        onChange={e => setTextInputValue(e.target.value)}
      />
      <Button
        kind='primary'
        className='gs--margin-bottom-sp3 gs--margin-top-sp3'
        disabled={!textInputValue}
        onClick={_handleClickDownload}
      >
        {loading ? (
          <>
            <div className='loading-left'>
              <InlineLoading />
            </div>
            Downloading
          </>
        ) : (
          <>Download report(s)</>
        )}
      </Button>
    </div>
  );

  function _handleClickDownload() {
    setLoading(true);

    // set an array of event/season ids to retrieve
    // TODO implement allowing multiple ids on the UI, currently only 1 is supported
    const searchIds = [Number(textInputValue)];

    // call either the event or season api based on the dropdown type
    const getEventsSeasons =
      selectedReportType.id === EventSeasonSummaryTypesEnum.SEASON
        ? SeasonService.searchSeasonByIds(searchIds)
        : EventService.searchByIds(searchIds);

    getEventsSeasons
      .then(resp => {
        // check if a season/event was not found from the api search
        const apiErrorIds = searchIds.filter(id => !resp.some(eventSeason => eventSeason.id === id));

        if (apiErrorIds.length > 0) {
          const ids = apiErrorIds.join(' ');
          _handleApiError(
            new Error(`Error: Unable to retrieve ${selectedReportType.id.toLowerCase()} data for ${ids}`)
          );
          setLoading(false);
          return;
        }

        const body = resp.map(eventSeason => {
          const eventEndDate = formatDateTime({
            date: eventSeason.endDateTime,
            timeZone: eventSeason.timeZone,
            parseZone: true
          })
            .toDate(DATE_FORMAT_DEFAULT)
            .replaceAll('-', '');
          return { eventEndDate, eventSeasonId: eventSeason.id };
        });

        ReportsService.getEventSeasonSummaryReport(body, selectedAccount.id, selectedReportType.id)
          .then(({ data, contentType }: { data: Blob; contentType: string }) => {
            const fileExt = contentType.includes('pdf') ? 'pdf' : 'zip';
            const fileName = `${searchIds.join('_')}-${selectedReportType.id.toLowerCase()}-summary-report.${fileExt}`;
            saveAs(data, fileName);
          })
          .catch(e => {
            _handleApiError(e);
          })
          .finally(() => setLoading(false));
      })
      .catch(e => {
        _handleApiError(e);
        setLoading(false);
      });
  }

  function _handleApiError(e: Error) {
    addNotification({
      type: 'error',
      message: e.message,
      options: { duration: NOTIFICATION_TIME_OUT }
    });
  }
};

export { SummaryReports };
