import { Activity, Collapse } from './types';
import {
  ActivityActionTypes,
  COLLAPSE_ACTIVITY,
  FETCH_ACTIVITIES,
  FETCH_ACTIVITIES_SUCCESSFUL,
  FETCH_ACTIVITIES_COMPLETED,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_SUCCESSFUL,
  DELETE_ACTIVITY_COMPLETED,
  CREATE_ACTIVITY,
  CREATE_ACTIVITY_SUCCESSFUL,
  CREATE_ACTIVITY_COMPLETED,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_SUCCESSFUL,
  UPDATE_ACTIVITY_COMPLETED,
} from './actionTypes';

// TypeScript infers that this function is returning FetchActivitiesAction
export function fetchActivities(): ActivityActionTypes {
  return {
    type: FETCH_ACTIVITIES
  }
}

// TypeScript infers that this function is returning FetchActivitiesAction
export function fetchActivitiesCompleted(): ActivityActionTypes {
  return {
    type: FETCH_ACTIVITIES_COMPLETED
  }
}

// TypeScript infers that this function is returning FetchActivitiesSuccessfulAction
export function fetchActivitiesSuccessful(
  athleticActivities: Activity[],
  nonAthleticActivities: Activity[]
  ): ActivityActionTypes {
  return {
    type: FETCH_ACTIVITIES_SUCCESSFUL,
    payload: {
      athleticActivities,
      nonAthleticActivities
    }
  }
}

// TypeScript infers that this function is returning CreateActivityAction
export function createActivity(activity: Activity): ActivityActionTypes {
  return {
    type: CREATE_ACTIVITY,
    payload: {
      activity
    }
  }
}

// TypeScript infers that this function is returning createActivityCompletedAction
export function createActivityCompleted(): ActivityActionTypes {
  return {
    type: CREATE_ACTIVITY_COMPLETED
  }
}

// TypeScript infers that this function is returning CreateActivitySuccessfulAction
export function createActivitySuccessful(athleticActivities: [Activity], nonAthleticActivities:[Activity]): ActivityActionTypes {
  return {
    type: CREATE_ACTIVITY_SUCCESSFUL,
    payload: {
      athleticActivities,
      nonAthleticActivities
    }
  }
}

// TypeScript infers that this function is returning UpdateActivityAction
export function updateActivity(activity: Activity): ActivityActionTypes {
  return {
    type: UPDATE_ACTIVITY,
    payload: {
      activity
    }
  }
}

// TypeScript infers that this function is returning UpdateActivitySuccessAction
export function updateActivitySuccess(athleticActivities: [Activity], nonAthleticActivities:[Activity]): ActivityActionTypes {
  return {
    type: UPDATE_ACTIVITY_SUCCESSFUL,
    payload: {
      athleticActivities,
      nonAthleticActivities
    }
  }
}

// TypeScript infers that this function is returning createActivityCompletedAction
export function updateActivityCompleted(): ActivityActionTypes {
  return {
    type: UPDATE_ACTIVITY_COMPLETED
  }
}

// TypeScript infers that this function is returning DeleteActivityAction
export function deleteActivity(activity: Activity): ActivityActionTypes {
  return {
    type: DELETE_ACTIVITY,
    payload: {
      activity
    }
  }
}

export function deleteActivitySuccessful(athleticActivities: [Activity], nonAthleticActivities:[Activity]): ActivityActionTypes {
  return {
    type: DELETE_ACTIVITY_SUCCESSFUL,
    payload: {
      athleticActivities,
      nonAthleticActivities
    }
  }
}

export function deleteActivityCompleted(): ActivityActionTypes {
  return {
    type: DELETE_ACTIVITY_COMPLETED
  }
}

export function collapseActivity(collapseParams: Collapse): ActivityActionTypes {
  return {
    type: COLLAPSE_ACTIVITY,
    payload: { collapseParams }
  }
}
