import type { AxiosResponse, CancelTokenSource } from 'axios';
import axiosExt from 'axios';
import axios from '@api/api/axios';
import qs from 'query-string';
import {
  GET_EVENT_SALES_INFO,
  GET_EVENT_BY_PARAMS,
  GET_EVENT,
  DELETE_EVENT,
  UPDATE_EVENT,
  EVENT_DAILY_SALES_INFO,
  CHECK_DUPLICATE_EVENT,
  SEARCH_EVENTS_BY_IDS_V2,
  SALES_ALLOTMENT,
  GET_TICKET_SALE_REPORT,
  GET_TICKET_SALES_INSIGHT_DATA,
  GET_ACCOUNT_METRICS,
  CANCEL_EVENT,
  RESCHEDULE_EVENT,
  CREATE_EVENT,
  GET_FLATFILE_TOKEN,
  STATUS_BULK_UPLOAD_EVENTS,
  GET_UPLOAD_DATA,
  BULK_UPLOAD_EVENTS,
  GET_AWAY_EVENT_INSIGHT
} from '@api/api/api';
import { CANCELLED_REQUEST } from '@app/api/api/constants';
import type {
  EventSalesInfoDTO,
  EventSearchDTO,
  EventDTO,
  EventPageResponseDTO,
  EventDailySalesInfo,
  ImportEventDTO,
  EventConflictDTO,
  AccountMetricsQuery,
  SalesReportInfoSearch,
  SalesReportInfoDTO,
  EventTicketSale,
  EventRestoreRequest,
  EventCancelDTO,
  EventRescheduleDTO,
  EventBulkUpload,
  EventBulkUploadStatus
} from '../models/event.model';

import type {
  EventScheduleSearchDTO,
  EventSchedulePartnerInsightDTO
} from '@modules/event-integrations_V2/models/event-schedule.model';
import { config } from '@gofan/constants';
import { getQueryString } from '@gofan/utils/object';

class EventRepository {
  sourceAccountsMetrics: null | CancelTokenSource;

  constructor() {
    this.sourceAccountsMetrics = null;
  }

  searchEventByParams = (body: EventSearchDTO, queryStr: string): Promise<EventPageResponseDTO> =>
    axios.huddleAxiosInstanceProxy.post<EventPageResponseDTO>(`${GET_EVENT_BY_PARAMS}${queryStr}`, body, null, true);

  getInternalEventPartnerInsight = (searchParams: EventScheduleSearchDTO, manualErrorHandling?: boolean) =>
    axios.huddleAxiosInstanceProxy.get<EventSchedulePartnerInsightDTO>(
      GET_AWAY_EVENT_INSIGHT,
      {
        params: {
          schoolIds: searchParams.schoolIds?.join(',')
        }
      },
      manualErrorHandling
    );

  getDailySalesInfo = (eventIds: number[]): Promise<EventDailySalesInfo> =>
    axiosExt.post(`${EVENT_DAILY_SALES_INFO}`, { eventIds }).then(response => response.data);

  getEventSalesInfo = (eventId: number): Promise<EventSalesInfoDTO> =>
    axios.huddleAxiosInstanceProxy.get<EventSalesInfoDTO>(
      `${GET_EVENT_SALES_INFO.replace('{id}', `${eventId}`)}`,
      null,
      true
    );

  deleteEventById = (eventId: number): Promise<AxiosResponse> =>
    axios.huddleAxiosInstanceProxy.delete<any>(DELETE_EVENT.replace('{id}', `${eventId}`), null, true);

  createEvent = (event: EventDTO, options?: { checkDupes: boolean }): Promise<EventDTO> =>
    axios.huddleAxiosInstanceProxy.post(
      `${CREATE_EVENT}${options?.checkDupes ? '?checkDupes=true' : ''}`,
      event,
      null,
      true
    );

  updateEvent = (event: EventDTO, options?: { checkDupes: boolean }): Promise<EventDTO> =>
    axios.huddleAxiosInstanceProxy.put<EventDTO>(
      `${UPDATE_EVENT.replace('{id}', `${event.id}`)}${options?.checkDupes ? '?checkDupes=true' : ''}`,
      event,
      null,
      true
    );

  getEventById = (eventId: string | number, queryStr: string): Promise<EventDTO> =>
    axios.huddleAxiosInstanceProxy.get<any>(GET_EVENT.replace('{id}', `${eventId}${queryStr}`), null, true);

  checkDuplicateEvent = (importEvent: ImportEventDTO[]): Promise<EventConflictDTO[]> =>
    axios.huddleAxiosInstanceProxy.post<EventConflictDTO[]>(CHECK_DUPLICATE_EVENT, importEvent, null, true);

  getSalesReportInfo = (searchParams: SalesReportInfoSearch): Promise<SalesReportInfoDTO | BlobPart> =>
    axiosExt
      .get(
        `${GET_TICKET_SALE_REPORT.replace('{schoolId}', searchParams.schoolId)
          .replace('{periodStart}', searchParams.periodStart)
          .replace('{periodEnd}', searchParams.periodEnd)
          .replace('{format}', searchParams.format)
          .replace('{statementType}', searchParams.statementType)}`
      )
      .then(response => response.data);

  searchByIds = (ids: string[], queryStr: string) =>
    axios.huddleAxiosInstanceProxy.get<EventDTO[]>(
      `${SEARCH_EVENTS_BY_IDS_V2}${`${ids.length > 0 ? getQueryString({ id: ids }) : '?id'}${queryStr}`}`,
      null,
      true
    );

  getSalesAllotmentInfo = (eventId: number): Promise<any> =>
    axiosExt.get(`${SALES_ALLOTMENT.replace('{eventId}', `${eventId}`)}`);

  getTicketSaleInsightData = (eventId: number): Promise<EventTicketSale> =>
    axiosExt.get(`${GET_TICKET_SALES_INSIGHT_DATA.replace('{eventId}', `${eventId}`)}`).then(response => response.data);

  getAccountMetrics = (query: AccountMetricsQuery) => {
    this.sourceAccountsMetrics = axiosExt.CancelToken.source();

    return axiosExt.get(GET_ACCOUNT_METRICS, {
      params: query,
      paramsSerializer: params => qs.stringify(params),
      cancelToken: this.sourceAccountsMetrics.token
    });
  };

  cancelGetAccountMetrics = () => {
    if (this.sourceAccountsMetrics) {
      this.sourceAccountsMetrics.cancel(CANCELLED_REQUEST);
    }
  };

  cancelEvent = (cancelRequest: EventCancelDTO): Promise<EventDTO> =>
    axios.huddleAxiosInstanceProxy.post(CANCEL_EVENT, cancelRequest, undefined, true);

  rescheduleEvent = (payload: EventRescheduleDTO): Promise<EventDTO> =>
    axios.huddleAxiosInstanceProxy.post(RESCHEDULE_EVENT, payload, undefined, true);

  restoreEvent = (request: EventRestoreRequest): Promise<EventDTO> =>
    axios.huddleAxiosInstanceProxy.patch<EventDTO, EventRestoreRequest>(
      UPDATE_EVENT.replace('{id}', `${request?.id}`),
      request,
      undefined,
      true
    );

  updatePartialEvent = (event: Partial<EventDTO>): Promise<EventDTO> =>
    axios.huddleAxiosInstanceProxy.patch<EventDTO>(
      `${UPDATE_EVENT.replace('{id}', `${event.id}`)}`,
      event,
      undefined,
      true
    );

  getFlatfileToken = (payload: any): Promise<any> =>
    axiosExt.post(`${GET_FLATFILE_TOKEN}`, payload).then(response => response.data);

  bulkUploadEvents = (payload: EventBulkUpload): Promise<any> =>
    axiosExt
      .put(BULK_UPLOAD_EVENTS.replace('{bucket}', config.s3.BUCKET_BULK_UPLOAD_EVENT), payload)
      .then(response => response.data);

  getStatusBulkUploadEvents = (batchId: string): Promise<EventBulkUploadStatus> =>
    axiosExt.get(STATUS_BULK_UPLOAD_EVENTS.replace('{batchId}', batchId)).then(response => response.data);

  getUploadData = (payload: any): Promise<any> =>
    axiosExt.post(`${GET_UPLOAD_DATA}`, payload).then(response => response.data);
}

export default new EventRepository();
