import { DonutChart } from '@carbon/charts-react';
import { Dropdown, SkeletonPlaceholder } from 'carbon-components-react';
import { useState, useEffect, useRef, useCallback, useContext, useMemo } from 'react';
import { isEmpty, isEqual, range, uniqueId } from 'lodash';
import MultiSelectWithAllOpt from '@common/carbon-ui/customized/MultiSelectWithAllOpt';
import './ticket-sales-by-activity.scss';
import '@carbon/charts/styles.css';
import eventsRepository from '@app/modules/events/repositories/events.repository';
import { colorPalette2 } from '@app/modules/events/constants';
import { formatNumber, randomColor } from '@app/modules/events/components/ticket-sale-chart/helper';
import { formatMoney } from '@app/utils/objectUtils';
import { RootContext } from '@app/RootContext';
import { checkInternalUser } from '@app/api/services/UserService';

import moment from 'moment';
import type { AccountDTO } from '@gofan/api/accounts';
import useTimeoutEffect from '@app/utils/hooks/useTimeoutEffect';
import { isTestEnv } from '@app/utils/testUtils';
import BreakdownByActivity from '../breakdown-by-activity/breakdown-by-activity';
import type { SchoolYear } from './ByWeekMonth/ticket-sales-by-activity.utils';
import { bubbleUpCommonSports, years } from './ByWeekMonth/ticket-sales-by-activity.utils';
import NoData from '../no-data/no-data';
import { isArrayHasIdsEqual } from '../../utils/utils';
import { LIMIT_ACCOUNTS_DISPLAY } from '@app/modules/dashboard/constants/constants';
import EventService from '@app/modules/events/services/events.service';
import { AccountService } from '@gofan/api/accounts';

type DropdownItemType = {
  id: string;
  label: string;
};

interface Props {
  accounts: AccountDTO[];
}
const mapToChartData =
  (activityInput: string[] = []) =>
  ({ data }: any) => {
    const { byActivityName, totals } = data;
    const sortedByActivityNameKeys = isEmpty(byActivityName)
      ? activityInput
      : Object.keys(byActivityName).sort((a, b) => byActivityName[b].net_to_account - byActivityName[a].net_to_account);
    const getPercent = (key: string) =>
      Number(
        Number(
          byActivityName[key]?.net_to_account ? (byActivityName[key].net_to_account / totals.net_to_account) * 100 : 0
        ).toFixed()
      );
    const sortedByActivityNameKeysFilteredByZeroPercent = sortedByActivityNameKeys.filter(key => getPercent(key) >= 1);

    const result = {
      breakdownByActivityData: {
        items: sortedByActivityNameKeys.map((key, index) => {
          const percent = getPercent(key);
          return {
            name: key,
            color: percent >= 1 ? colorPalette2[index] || randomColor() : undefined,
            percent: `${percent}%`,
            ticketSales: `$${formatMoney(byActivityName[key]?.net_to_account || 0, 0)}`,
            ticketSold: formatNumber(byActivityName[key]?.ticket_net_count || 0)
          };
        }),
        totals: {
          ticketSales: `$${formatMoney(totals.net_to_account || 0, 0)}`,
          ticketSold: formatNumber(totals.ticket_net_count || 0)
        }
      },
      selectedActivitiesData: {
        data: sortedByActivityNameKeysFilteredByZeroPercent.map(key => ({
          group: key === 'Unlabeled' ? 'Other' : key, // Unlabeled = Other
          value: byActivityName[key]?.net_to_account || 0
        })),
        options: {
          title: 'Selected activities',
          resizable: true,
          color: {
            scale: sortedByActivityNameKeysFilteredByZeroPercent.reduce(
              (result, current, index) => ({ ...result, [current]: colorPalette2[index] || randomColor() }),
              {}
            )
          },
          pie: {
            labels: {
              formatter: (object: any) => `${Number((object.value / totals.net_to_account) * 100).toFixed()}%`
            }
          },
          legend: {
            position: 'right',
            orientation: 'vertical',
            alignment: 'center'
          },
          height: '340px'
        }
      }
    };
    return result;
  };

const initChartData = {
  breakdownByActivityData: null,
  selectedActivitiesData: null
};
export default function TicketSalesByActivity({ accounts }: Props) {
  const [year, setYear] = useState<SchoolYear>(years[0]);

  const { currentUser } = useContext(RootContext);
  const timerRef = useRef<any>(null);
  const timeGetActivitiesRef = useRef<any>(null);
  const accountsRef = useRef<AccountDTO[]>([]);

  // Activity
  const [activities, setActivities] = useState<any[]>([]);
  // selectedActivities is used for showNoData
  // When selection offs (handleOpenActivitiesSelect executes with params false)
  // or click icon x on select
  // or getChartData executes
  // we will execute setSelectedActivities
  const [selectedActivities, setSelectedActivities] = useState<any[]>([]);
  // setSelectedActivitiesTemp only executes when handleChangeActivity & getChartData execute
  const [selectedActivitiesTemp, setSelectedActivitiesTemp] = useState<any[]>([]);
  const [selectedActivitiesTempToCompare, setSelectedActivitiesTempToCompare] = useState<any[]>([]);
  const [isOpenActivitiesSelect, setIsOpenActivitiesSelect] = useState(false);

  // School
  const [selectedAccounts, setSelectedAccounts] = useState<AccountDTO[]>([]);
  const [selectedAccountsTemp, setSelectedAccountsTemp] = useState<AccountDTO[]>([]);
  const [isOpenAccountsSelect, setIsOpenAccountsSelect] = useState(false);
  const isUnmounted = useRef<boolean>(false);

  const [chartData, setChartData] = useState(initChartData);
  const [keyMultiselectActivity, setKeyMultiselectActivity] = useState(uniqueId());
  const [keyMultiselectSchools, setKeyMultiselectSchools] = useState(uniqueId());
  const [loading, setLoading] = useState(true);
  const chartRef = useRef();
  const showNoData = isEmpty(selectedActivities) || isEmpty(activities);
  const isHideAccountSelect = !checkInternalUser(currentUser.role) && accounts.length === 1;

  const optionAccounts = selectedAccountsTemp.length === LIMIT_ACCOUNTS_DISPLAY ? selectedAccountsTemp : accounts;

  const getChartData = useCallback(
    (params: { schoolId: string[]; year: SchoolYear; activity?: string[] }) => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setLoading(true);
        const periodStart = moment(year.yearStart.id).format('YYYYMMDD');
        const periodEnd = moment(year.yearEnd.id).format('YYYYMMDD');

        EventService.getAccountMetrics({
          periodStart,
          periodEnd,
          activity: params.activity,
          schoolId: params.schoolId
        })
          .then(mapToChartData(params.activity))
          .then(res => {
            if (isUnmounted.current) {
              return;
            }
            setChartData(res);
          })
          .catch(err => {
            console.error('YTD ticket sales by activity', err);
          })
          .finally(() => {
            setLoading(false);
          });
      }, 300);
    },
    [year]
  );

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  const getActivities = useCallback(
    (schoolId: string[], newYear = undefined) => {
      clearTimeout(timeGetActivitiesRef.current);
      timeGetActivitiesRef.current = setTimeout(() => {
        setLoading(true);
        let periodStart;
        let periodEnd;
        if (newYear === undefined) {
          periodStart = moment(year.yearStart.id).format('YYYYMMDD');
          periodEnd = moment(year.yearEnd.id).format('YYYYMMDD');
        } else {
          periodStart = moment(newYear.yearStart.id).format('YYYYMMDD');
          periodEnd = moment(newYear.yearEnd.id).format('YYYYMMDD');
        }

        return EventService.getAccountMetrics({
          periodStart,
          periodEnd,
          schoolId
        })
          .then(res => {
            if (isUnmounted.current) {
              return;
            }
            const activityName = Object.keys(res.data.byActivityName).map((activity, index) => ({
              id: index,
              name: activity,
              status: 'Active'
            }));
            const sortedActivityName = bubbleUpCommonSports(activityName);
            setActivities(sortedActivityName);
            setSelectedActivities(sortedActivityName);
            setSelectedActivitiesTemp(sortedActivityName);
            return sortedActivityName;
          })
          .catch(err => {
            console.error('YTD ticket sales by activity', err);
          })
          .finally(() => {
            if (newYear === undefined) {
              setLoading(false);
            } else {
              setYear(newYear);
            }
          });
      }, 300);
    },
    [year]
  );

  useEffect(() => {
    const [listChange, newSelectedAccounts] = AccountService.getListAccountChanges<AccountDTO>(
      accountsRef.current,
      accounts,
      selectedAccounts
    );
    if (listChange.length === 0) {
      return;
    }
    const schoolId = newSelectedAccounts.map(item => item.id);
    setSelectedAccounts(newSelectedAccounts);
    setSelectedAccountsTemp(newSelectedAccounts);
    setKeyMultiselectSchools(uniqueId());
    const getActivitiesAndChartData = async () => {
      const activitiesRes = await getActivities(schoolId);
      await getChartData({ year, schoolId, activity: activitiesRes?.map(activity => activity.name) });
    };
    getActivitiesAndChartData();

    accountsRef.current = accounts;
  }, [accounts, year]);

  useTimeoutEffect(
    () => {
      setKeyMultiselectActivity(uniqueId());
    },
    [activities],
    100
  );

  useTimeoutEffect(
    () => {
      const chartElement: HTMLDivElement | undefined = chartRef.current?.chartRef;
      if (chartElement) {
        const centerG: HTMLElement | null = chartElement.querySelector('g.center');
        const ticketSales = chartData.breakdownByActivityData?.totals?.ticketSales?.replace('$', '&#36;');
        const ticketSold = chartData.breakdownByActivityData?.totals?.ticketSold;
        if (centerG) {
          centerG.innerHTML = `
        <text class="donut-figure" text-anchor="middle" style="dominant-baseline: initial;font-size: 24px;color: rgb(22, 22, 22);" y="-24"></text>
        <text class="donut-figure" text-anchor="middle" style="dominant-baseline: initial;font-size: 24px;color: rgb(22, 22, 22); font-weight: bold;" y="-24">${ticketSales}</text>
        <text class="donut-title" text-anchor="middle" y="0" style="font-size: 15px;color: rgb(22, 22, 22); font-weight: bold;">Ticket sales</text>
        <line class="horizontal-line" x1="-50" y1="12" x2="50" y2="12" style="stroke-width: 1px;stroke: rgb(22, 22, 22); font-weight: bold;"></line>
        <text class="donut-figure" text-anchor="middle" style="dominant-baseline: initial;font-size: 24px;color: rgb(22, 22, 22); font-weight: bold;" y="40">${ticketSold}</text>
        <text class="donut-title" text-anchor="middle" y="60" style="font-size: 15px;color: rgb(22, 22, 22); font-weight: bold;">Tickets sold</text>
        `;
          const titleInCenterG: HTMLElement | null = centerG?.querySelector('.donut-figure');
          if (titleInCenterG) {
            titleInCenterG.style.display = 'none';
          }
        }
      }
    },
    [chartRef.current?.chartRef, chartData, loading],
    100
  );

  const renderItem = (item: any) => (
    <div className='item-select'>
      <span className='item-select-text'>{item.name}</span>
    </div>
  );

  const handleChangeActivity = (event: { selectedItems: any[] }) => {
    const { selectedItems } = event;
    setSelectedActivitiesTemp(selectedItems);
    // For case click x icon on select to remove all
    if (!isOpenActivitiesSelect) {
      setSelectedActivities(selectedItems);
      setChartData(initChartData);
    }
  };

  const handleOpenActivitiesSelect = (isOpen: boolean) => {
    setIsOpenActivitiesSelect(isOpen);
    if (isOpen) {
      setSelectedActivitiesTempToCompare(selectedActivitiesTemp);
    } else if (!isArrayHasIdsEqual(selectedActivitiesTempToCompare, selectedActivitiesTemp)) {
      setSelectedActivities(selectedActivitiesTemp);
      getChartData({
        year,
        schoolId: selectedAccounts.map(item => item.id),
        activity: selectedActivitiesTemp.map((activity: any) => activity.name)
      });
    }
  };

  useEffect(() => {
    getChartData({
      year,
      schoolId: selectedAccounts.map(item => item.id),
      activity: selectedActivitiesTemp.map((activity: any) => activity.name)
    });
  }, [year, getChartData, selectedAccounts, selectedActivitiesTemp]);

  const updateYear = yearItem => {
    getActivities(
      selectedAccounts.map(item => item.id),
      yearItem
    );
  };

  return (
    <div className='ticket-sales-by-activity'>
      <h3 className='ticket-sales-by-activity-title'>YTD ticket sales by activity</h3>
      {!isHideAccountSelect && (
        <div className='ticket-sales-by-activity-row'>
          <div className='ticket-sales-by-activity-col-8'>
            <MultiSelectWithAllOpt
              id='select-school-by-activity'
              itemToString={(item: any) => item?.name || ''}
              items={optionAccounts}
              initialSelectedItems={selectedAccountsTemp}
              label='schools selected'
              light={false}
              onChange={_onChangeAccounts}
              onMenuChange={_onMenuAccountsChange}
              open={isOpenAccountsSelect}
              key={keyMultiselectSchools}
              selectionFeedback='fixed'
              titleText='Schools'
              disabled={isEmpty(accounts) || loading}
            />
          </div>
        </div>
      )}

      <div className='ticket-sales-by-activity-row'>
        <div className='ticket-sales-by-activity-col'>
          <MultiSelectWithAllOpt
            items={activities}
            label='activities selected'
            itemToElement={renderItem}
            itemToString={(item: any) => item.name}
            initialSelectedItems={selectedActivitiesTemp}
            onChange={handleChangeActivity}
            onMenuChange={handleOpenActivitiesSelect}
            titleText='Activity'
            id='activity-selection'
            key={keyMultiselectActivity}
            disabled={isEmpty(activities) || loading}
          />
        </div>
        <div className='ticket-sales-by-activity-col'>
          <Dropdown
            light={false}
            id='year-selection'
            titleText='Year'
            itemToString={(item: any) => `${item.yearStart.label}-${item.yearEnd.label}` || ''}
            items={years}
            onChange={({ selectedItem }) => updateYear(selectedItem)}
            selectedItem={years.find(item => isEqual(year, item))}
            label=''
            disabled={isEmpty(activities) || loading}
          />
        </div>
      </div>

      {showNoData && <NoData />}
      {!showNoData && (
        <>
          {loading && <SkeletonPlaceholder style={{ width: '100%', height: 380 }} />}
          {!loading && (
            <div className='ticket-sales-by-activity-charts'>
              <div className='ticket-sales-by-activity-donut-chart'>
                {chartData.selectedActivitiesData && !isTestEnv && (
                  <DonutChart {...chartData.selectedActivitiesData} ref={chartRef} />
                )}
              </div>
              <div className='ticket-sales-by-activity-breakdown-by-sport'>
                {chartData.breakdownByActivityData && <BreakdownByActivity data={chartData.breakdownByActivityData} />}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );

  function _onChangeAccounts({ selectedItems }: { selectedItems: any[] }) {
    const selectedItemsSliced = selectedItems.slice(0, LIMIT_ACCOUNTS_DISPLAY);
    setSelectedAccountsTemp(selectedItemsSliced);
    // Force re-render MultiSelect when selected items length > limit
    if (selectedItems.length > LIMIT_ACCOUNTS_DISPLAY) {
      setKeyMultiselectSchools(uniqueId());
    }

    // For case click x icon on select to remove all
    if (!isOpenAccountsSelect) {
      _resetAllField();
    }
  }

  async function _onMenuAccountsChange(isOpen: boolean) {
    if (!isArrayHasIdsEqual(selectedAccounts, selectedAccountsTemp)) {
      setSelectedAccounts(selectedAccountsTemp);
      if (isEmpty(selectedAccountsTemp)) {
        _resetAllField();
        return;
      }
      const schoolId = selectedAccountsTemp.map(item => item.id);
      const activitiesRes = await getActivities(schoolId);
      await getChartData({
        year,
        schoolId,
        activity: activitiesRes?.map(activity => activity.name)
      });
    }
    setIsOpenAccountsSelect(isOpen);
  }

  function _resetAllField() {
    setActivities([]);
    setSelectedActivities([]);
    setSelectedActivitiesTemp([]);
    setSelectedAccounts([]);
    setSelectedAccountsTemp([]);
    setChartData(initChartData);
  }
}
