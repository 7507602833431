import React from 'react';
import styled from 'styled-components';
import StyledCheckBox from '../StyledCheckBox';
import styles from './styles';

const TitleWrapper = styled.div`
  ${styles.titleWrapper};
`;

const LabelOptional = styled.div`
  ${styles.labelOptional};
`;

const Description = styled.div`
  ${styles.description};
`;

export default ({ children, alignTitleDescription, ...props }) => (
  <React.Fragment>
    <TitleWrapper>
      <StyledCheckBox {...props} />
      <LabelOptional>&nbsp;(optional)</LabelOptional>
    </TitleWrapper>
    {props.description && (
      <Description alignTitleDescription={alignTitleDescription}>
        {props.description}
      </Description>
    )}
  </React.Fragment>
);
