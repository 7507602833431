import type { EventDTO } from '@gofan/api';

export const ON_RESET_BULK_EDIT_EVENTS_STATE = '@bulk-edit-events/ON_RESET_BULK_EDIT_EVENTS_STATE';
export const ON_SET_SELECTED_EVENTS = '@bulk-edit-events/ON_SET_SELECTED_EVENTS';

export interface OnResetBulkEditEventsState {
  type: typeof ON_RESET_BULK_EDIT_EVENTS_STATE;
}

export interface OnSetSelectedEvents {
  type: typeof ON_SET_SELECTED_EVENTS;
  payload: EventDTO[];
}

export type BulkEditEventsActionType = OnResetBulkEditEventsState | OnSetSelectedEvents;
