import { connect } from 'react-redux';

import Activities from './components/ReportingActivities';
import {
  fetchActivities,
  collapseActivity
} from '../../pages/Activities/actions.ts';

const mapStateToProps = state => ({
  loading: state.activity.loading,
  collapse: state.activity.collapse,
  athleticActivities: state.activity.athleticActivities,
  nonAthleticActivities: state.activity.nonAthleticActivities
});

export default connect(
  mapStateToProps,
  {
    fetchActivities,
    collapseActivity
  }
)(Activities);
