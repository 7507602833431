import { css } from 'styled-components';
import {
  WILD_SAND,
  SOURCESANPRO_REGULAR
} from '../../../../assets/styles/common';

export default {
  noResultsFound: css`
    ${SOURCESANPRO_REGULAR};
    width: 100%;
    padding: 16px;
    font-size: 16px;
    text-align: center;
    background-color: ${WILD_SAND};
  `
};
