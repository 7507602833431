class SearchRatesRequest {
  constructor({ keyword, productTypeIds, expands, exceptions, cashless }) {
    this.raw = { keyword, productTypeIds, expands, exceptions, cashless };
  }

  get keyword() {
    return this.raw.keyword;
  }

  get productTypeIds() {
    return this.raw.productTypeIds || [];
  }

  get expands() {
    return this.raw.expands || [];
  }

  get exceptions() {
    return this.raw.exceptions || [];
  }

  get cashless() {
    return this.raw.cashless;
  }

  toQueryString() {
    const { keyword, productTypeIds, expands, exceptions, cashless } = this;
    const name = keyword ? `name=${keyword}&` : '';
    const productTypeString = productTypeIds.reduce(
      (str, item) => `${str}productTypeIds=${item}&`,
      ''
    );
    const exceptionString = exceptions.reduce(
      (str, item) => `${str}exceptions=${item}&`,
      ''
    );
    const cashlessString =
      cashless === undefined ? '' : `cashless=${cashless}&`;
    const expand = expands.reduce((str, item) => `${str}expand=${item}&`, '');

    return `${expand}${name}${productTypeString}${exceptionString}${cashlessString}`;
  }
}

export default SearchRatesRequest;
