import { isEmpty } from 'lodash';
import {
  EventLandingActionTypes,
  RESET_PUBLISH_STATE,
  PREPARE_DATA,
  PREPARE_DATA_COMPLETED,
  SEARCH_EVENTS,
  SEARCH_EVENTS_COMPLETED,
  UPDATE_EVENT_VISIBILITY,
  UPDATE_EVENT_VISIBILITY_COMPLETED,
  DELETE_EVENT,
  DELETE_EVENT_COMPLETED,
  CLEAR_EVENT_TABLE,
  FETCH_EVENT_SALES_INFO,
  FETCH_EVENT_SALES_INFO_COMPLETED,
  FETCH_ACTIVITIES_SUCCESSFUL,
  FETCH_ACCOUNTS_BY_IDS_COMPLETED,
  ON_REQUEST_TICKET_DISTRIBUTION_FOR_EVENTS,
  ON_REQUEST_TICKET_DISTRIBUTION_FOR_EVENTS_COMPLETED,
  CLEAR_DATA_CREATED_LINKS,
  FETCH_ALL_LEVELS_COMPLETED
} from './actionTypes';
// @flow
import { EventState } from './types';

export const initialState: EventState = {
  pageError: null,
  pageLoading: false,
  isSearched: false,
  isSearching: false,
  isCreatedLinks: false,
  pageIndex: 0,
  totalElements: 1,
  currentUser: undefined,
  schoolConfig: undefined,
  districtAccount: undefined,
  selectionAccounts: [],
  isDistrictUser: false,
  events: [],
  athleticActivities: [],
  nonAthleticActivities: [],
  accountAssociateds: [],
  haveAccountActiveContext: false,
  levels: []
};

export default (state = initialState, action: EventLandingActionTypes) => {
  switch (action.type) {
    case RESET_PUBLISH_STATE: {
      return {
        ...state,
        pageError: null,
        isPublished: false,
        isPublishing: false
      };
    }
    case PREPARE_DATA: {
      return {
        ...state,
        currentUser: action.payload.currentUser,
        isDistrictUser: false,
        schoolConfig: undefined,
        districtAccount: undefined,
        pageError: null,
        pageLoading: true
      };
    }
    case PREPARE_DATA_COMPLETED: {
      const { error } = action;
      return {
        ...state,
        pageLoading: false,
        pageError: isEmpty(error) ? null : error
      };
    }
    case SEARCH_EVENTS: {
      const { shouldHideLoading } = action.payload.searchParams;
      return { ...state, isSearching: !shouldHideLoading };
    }
    case SEARCH_EVENTS_COMPLETED: {
      const { error } = action;
      const { searchResult } = action.payload;
      const { data, pageIndex, totalElements } = searchResult;
      return {
        ...state,
        events: data,
        pageIndex,
        totalElements,
        isSearching: false,
        pageError: isEmpty(error) ? error : null
      };
    }
    case UPDATE_EVENT_VISIBILITY: {
      return { ...state, isPublishing: true, isPublished: false };
    }
    case UPDATE_EVENT_VISIBILITY_COMPLETED: {
      const { error } = action;
      const { events } = action.payload;
      const hasError = !isEmpty(error);
      return {
        ...state,
        isPublished: true,
        isPublishing: false,
        pageError: hasError ? error : null,
        events: hasError ? state.events : events
      };
    }
    case FETCH_ACTIVITIES_SUCCESSFUL: {
      const { athleticActivities, nonAthleticActivities } = action.payload;
      return {
        ...state,
        activityLoading: false,
        athleticActivities,
        nonAthleticActivities
      };
    }

    case DELETE_EVENT:
    case FETCH_EVENT_SALES_INFO: {
      return {
        ...state,
        isSearching: true
      };
    }

    case CLEAR_EVENT_TABLE: {
      return {
        ...initialState
      };
    }

    case DELETE_EVENT_COMPLETED: {
      const { error } = action;
      return {
        ...state,
        isSearching: false,
        pageError: isEmpty(error) ? error : null
      };
    }

    case FETCH_EVENT_SALES_INFO_COMPLETED: {
      const { events } = action.payload;
      return {
        ...state,
        events,
        isSearching: false
      };
    }
    case FETCH_ACCOUNTS_BY_IDS_COMPLETED: {
      const {
        schoolConfig,
        isDistrictUser,
        districtAccount,
        selectionAccounts,
        associatedAccounts,
        editableAccounts,
        haveAccountActiveContext
      } = action.payload;
      return {
        ...state,
        schoolConfig,
        isDistrictUser,
        districtAccount,
        selectionAccounts,
        associatedAccounts,
        editableAccounts,
        haveAccountActiveContext
      };
    }
    case ON_REQUEST_TICKET_DISTRIBUTION_FOR_EVENTS: {
      return {
        ...state,
        isPublishing: true
      };
    }
    case ON_REQUEST_TICKET_DISTRIBUTION_FOR_EVENTS_COMPLETED: {
      return {
        ...state,
        isPublishing: false,
        isCreatedLinks: true
      };
    }
    case FETCH_ALL_LEVELS_COMPLETED: {
      const { levels } = action.payload;
      return {
        ...state,
        levels
      };
    }
    case CLEAR_DATA_CREATED_LINKS: {
      return {
        ...state,
        isCreatedLinks: false
      };
    }
    default:
      return state;
  }
};
