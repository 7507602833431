import { INITIAL_DATA } from '../RateInformation/actionTypes';
import type { Activity } from '../Activities/types';
import type { Account, Level, Event, Venue } from '../../api/types';
import type { TicketType, SearchAccountTicketsParams, SearchAccountsTicketsResult } from './types';

export const FETCH_ACTIVITIES = '@EVENT_INFORMATION_V2/FETCH_ACTIVITIES';
export const FETCH_ACTIVITIES_SUCCESSFUL = '@EVENT_INFORMATION_V2/FETCH_ACTIVITIES_SUCCESSFUL';
export const FETCH_ACTIVITIES_COMPLETED = '@EVENT_INFORMATION_V2/FETCH_ACTIVITIES_COMPLETED';

export const RESET_STATE = '@EVENT_INFORMATION_V2/RESET_STATE';
export const SEARCH_OPPONENT_ACCOUNTS = '@EVENT_INFORMATION_V2/SEARCH_OPPONENT_ACCOUNTS';
export const SEARCH_OPPONENT_ACCOUNTS_SUCCESS = '@EVENT_INFORMATION_V2/SEARCH_OPPONENT_ACCOUNTS_SUCCESS';

export const FETCH_LEVELS = '@EVENT_INFORMATION_V2/FETCH_LEVELS';
export const FETCH_LEVELS_SUCCESSFUL = '@EVENT_INFORMATION_V2/FETCH_LEVELS_SUCCESSFUL';
export const FETCH_LEVELS_COMPLETED = '@EVENT_INFORMATION_V2/FETCH_LEVELS_COMPLETED';

export const FETCH_ACCOUNT = '@EVENT_INFORMATION_V2/FETCH_ACCOUNT';
export const FETCH_ACCOUNT_SUCCESSFUL = '@EVENT_INFORMATION_V2/FETCH_ACCOUNT_SUCCESSFUL';
export const FETCH_ACCOUNT_COMPLETED = '@EVENT_INFORMATION_V2/FETCH_ACCOUNT_COMPLETED';
export const UPDATE_REQUIRED_FIELDS = '@EVENT_INFORMATION_V2/UPDATE_REQUIRED_FIELDS';
export const UPDATE_OPTIONAL_FIELDS = '@EVENT_INFORMATION_V2/UPDATE_OPTIONAL_FIELDS';
export const UPDATE_TICKETS_FIELDS = '@EVENT_INFORMATION_V2/UPDATE_TICKETS_FIELDS';

export const FETCH_EVENT_BY_ID = '@EVENT_INFORMATION_V2/FETCH_EVENT_BY_ID';
export const FETCH_EVENT_BY_ID_SUCCESSFULL = '@EVENT_INFORMATION_V2/FETCH_EVENT_BY_ID_SUCCESSFULL';
export const FETCH_EVENT_BY_ID_COMPLETED = '@EVENT_INFORMATION_V2/FETCH_EVENT_BY_ID_COMPLETED';

export const SEARCH_ACCOUNT_TICKETS = '@EVENT_INFORMATION_V2/SEARCH_ACCOUNT_TICKETS';
export const SEARCH_ACCOUNT_TICKETS_COMPLETED = '@EVENT_INFORMATION_V2/SEARCH_ACCOUNT_TICKETS_COMPLETED';

export const FETCH_VENUE_BY_ID = '@EVENT_INFORMATION_V2/FETCH_VENUE_BY_ID';
export const FETCH_VENUE_BY_ID_SUCCESSFULL = '@EVENT_INFORMATION_V2/FETCH_VENUE_BY_ID_SUCCESSFULL';
export const FETCH_VENUE_BY_ID_COMPLETED = '@EVENT_INFORMATION_V2/FETCH_VENUE_BY_ID_COMPLETED';

export const ON_PUBLISH_EVENT = '@EVENT_INFORMATION_V2/ON_PUBLISH_EVENT';
export const ON_PUBLISH_EVENT_SUCCESS = '@EVENT_INFORMATION_V2/ON_PUBLISH_EVENT_SUCCESS';
export const ON_PUBLISH_EVENT_ERROR = '@EVENT_INFORMATION_V2/ON_PUBLISH_EVENT_ERROR';

export const EXECUTE_INITIAL_EVENT_PROCESS = '@EVENT_INFORMATION_V2/EXECUTE_INITIAL_EVENT_PROCESS';
export const EXECUTE_INITIAL_EVENT_PROCESS_COMPLETED = '@EVENT_INFORMATION_V2/EXECUTE_INITIAL_EVENT_PROCESS_COMPLETED';

interface ResetStateAction {
  type: typeof RESET_STATE;
}

interface FetchActivitiesAction {
  type: typeof FETCH_ACTIVITIES;
}

interface FetchActivitiesSuccessfulAction {
  type: typeof FETCH_ACTIVITIES_SUCCESSFUL;
  payload: {
    athleticActivities: Activity[];
    nonAthleticActivities: Activity[];
  };
}

interface FetchActivitiesCompletedAction {
  type: typeof FETCH_ACTIVITIES_COMPLETED;
}

interface SearchOpponentAccountsAction {
  type: typeof SEARCH_OPPONENT_ACCOUNTS;
  payload: {
    keyword: string;
  };
}

interface SearchOpponentAccountsSuccessAction {
  type: typeof SEARCH_OPPONENT_ACCOUNTS_SUCCESS;
  payload: {
    accounts: Account[];
  };
}

interface FetchLevelsAction {
  type: typeof FETCH_LEVELS;
}

interface FetchLevelsSuccessfulAction {
  type: typeof FETCH_LEVELS_SUCCESSFUL;
  payload: {
    levels: Level[];
  };
}

interface FetchLevelCompletedAction {
  type: typeof FETCH_LEVELS_COMPLETED;
}

interface FetchAccountAction {
  type: typeof FETCH_ACCOUNT;
  payload: {
    accountId: string;
  };
}

interface FetchAccountSuccessfulAction {
  type: typeof FETCH_ACCOUNT_SUCCESSFUL;
  payload: {
    account: Account;
  };
}

interface FetchAccountCompletedAction {
  type: typeof FETCH_ACCOUNT_COMPLETED;
}

interface UpdateRequiredFieldsAction {
  type: typeof UPDATE_REQUIRED_FIELDS;
  payload: {
    requiredFields: any;
  };
}

interface UpdateOptionalFieldsAction {
  type: typeof UPDATE_OPTIONAL_FIELDS;
  payload: {
    optionalFields: any;
  };
}

interface UpdateTicketsFieldsAction {
  type: typeof UPDATE_TICKETS_FIELDS;
  payload: {
    maxCapacity: number | boolean;
    ticketTypes: Array<TicketType>;
    totalTicketCheck: boolean;
  };
}

interface FetchEventByIdAction {
  type: typeof FETCH_EVENT_BY_ID;
  payload: {
    eventId: string;
  };
}

interface FetchEventByIdSuccessfullAction {
  type: typeof FETCH_EVENT_BY_ID_SUCCESSFULL;
  payload: {
    event: Event;
  };
}

interface FetchEventByIdCompletedAction {
  type: typeof FETCH_EVENT_BY_ID_COMPLETED;
}
interface SearchAccountTicketsAction {
  type: typeof SEARCH_ACCOUNT_TICKETS;
  payload: {
    searchParams: SearchAccountTicketsParams;
  };
}
interface SearchAccountTicketsCompletedAction {
  type: typeof SEARCH_ACCOUNT_TICKETS_COMPLETED;
  payload: {
    searchResult: SearchAccountsTicketsResult;
  };
}

interface FetchVenueByIdAction {
  type: typeof FETCH_VENUE_BY_ID;
  payload: {
    venueId: string;
  };
}
interface FetchVenueByIdSuccessfullAction {
  type: typeof FETCH_VENUE_BY_ID_SUCCESSFULL;
  payload: {
    venue: Venue;
  };
}

interface FetchVenueByIdCompletedAction {
  type: typeof FETCH_VENUE_BY_ID_COMPLETED;
}

interface PublishEventAction {
  type: typeof ON_PUBLISH_EVENT;
  payload: {
    history: Object;
    event?: Object;
    activityType?: string;
    currentUser?: any;
    requiredData: Object;
    optionalData: Object;
    ticketTypeData: Object;
    streamingData?: Object;
    onCompleteFunction: () => any;
  };
}

interface PublishEventSuccessAction {
  type: typeof ON_PUBLISH_EVENT_SUCCESS;
}

interface PublishEventErrorAction {
  type: typeof ON_PUBLISH_EVENT_ERROR;
  error: any;
  response: any;
}
interface ExecuteInitialEventProcessAction {
  type: typeof EXECUTE_INITIAL_EVENT_PROCESS;
  payload: {
    history: Object;
    accountId: string;
  };
}
interface ExecuteInitialEventProcessActionCompletedAction {
  type: typeof EXECUTE_INITIAL_EVENT_PROCESS_COMPLETED;
}

export type EventInformationV2ActionTypes =
  | PublishEventAction
  | PublishEventSuccessAction
  | PublishEventErrorAction
  | FetchAccountAction
  | FetchAccountSuccessfulAction
  | FetchAccountCompletedAction
  | FetchLevelsAction
  | FetchLevelCompletedAction
  | FetchLevelsSuccessfulAction
  | FetchActivitiesCompletedAction
  | FetchActivitiesAction
  | FetchActivitiesSuccessfulAction
  | ResetStateAction
  | SearchOpponentAccountsAction
  | SearchOpponentAccountsSuccessAction
  | UpdateRequiredFieldsAction
  | UpdateOptionalFieldsAction
  | UpdateTicketsFieldsAction
  | FetchEventByIdAction
  | FetchEventByIdCompletedAction
  | FetchEventByIdSuccessfullAction
  | SearchAccountTicketsAction
  | SearchAccountTicketsCompletedAction
  | FetchVenueByIdAction
  | FetchVenueByIdCompletedAction
  | FetchVenueByIdSuccessfullAction
  | ExecuteInitialEventProcessAction
  | ExecuteInitialEventProcessActionCompletedAction;
