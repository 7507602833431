import SystemError from '@app/pages/SystemError';
import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Log the error to New Relic
    if (window.newrelic) window.newrelic.noticeError(error);

    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <SystemError />;
    }
    return <>{this.props.children}</>;
  }
}
