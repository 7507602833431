import BaseDTO from './BaseDTO';

// @flow
class HistoryDTO extends BaseDTO {
  constructor({ updatedAt, updatedBy, changes }) {
    super({
      updatedAt,
      updatedBy,
      changes
    });
  }

  get updatedAt() {
    return this.raw.updatedAt;
  }

  get updatedBy() {
    return this.raw.updatedBy;
  }

  get changes() {
    return this.raw.changes;
  }

  toJSON() {
    return {
      updatedAt: this.updatedAt,
      updatedBy: this.updatedBy,
      changes: this.changes
    };
  }
}

export default HistoryDTO;
