import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';

import { getTimeZone } from '@app/utils/dateUtils';
import { GENDER_TYPE, EVENT_START_TIME_TYPE } from '@season-management/constants/constant';
import { MAX_CAPACITY } from '@events/constants';
import { DISTRIBUTION_CHANNEL } from '@app/api/model/request/ProductRequest';
import { mappingAccountVenue } from '@app/pages/EventInformationV2/components/RequiredLayout/components/VenueSection/VenueSection';

import type { ActivityDTO } from '@gofan/api/activities';
import type { EventDTO } from '@events/models/event.model';
import type { AccountDTO } from '@gofan/api/accounts';
import type { GlobalEventDTO } from '@seasons/models/season.model';
import type { AccountProductDTO } from '@gofan/api/account-products';
import type { ScheduleData, PartnerSchedule } from '@modules/event-integrations_V2/hooks';
import RawProductModel from '@season-management/middleware/models/raw-product.model';

import {
  getPartnerEventName,
  generateOpponentName,
  generateDefaultEventName,
  generateSpecialEventDescription
} from '@app/api/services/EventService';
import { AccountService } from '@gofan/api/accounts';
import { getScheduleAccountsByHomeSchool } from '@modules/event-integrations_V2/utils';

export class PartnerEventRequest {
  content: EventDTO;

  partnerEvent: GlobalEventDTO;

  partnerSchedule: PartnerSchedule;

  constructor({ event, schedule }: { event: GlobalEventDTO; schedule: PartnerSchedule }) {
    this.content = {} as EventDTO;
    this.partnerEvent = { ...(event ?? {}) } as GlobalEventDTO;
    this.partnerSchedule = { ...(schedule ?? {}) } as PartnerSchedule;
  }

  populate({ scheduleData, isInternalUser }: { isInternalUser: boolean; scheduleData: ScheduleData }) {
    const { schedule, scheduleLevel, scheduleVenue, accountTickets, scheduleStartTimes } = this.partnerSchedule ?? {};
    const {
      id: partnerEventId,
      eventName,
      sourceName,
      gender,
      sourceEventId,
      awaySchoolId,
      endEventDt,
      startEventDt
    } = this.partnerEvent ?? {};
    const { sportName } = schedule ?? {};
    const { venue } = scheduleVenue ?? {};
    const { activities, awayAccounts } = scheduleData ?? {};

    const activity =
      activities?.find(({ label }: ActivityDTO) => `${label}`.toLowerCase() === `${sportName}`.toLowerCase()) ??
      ({} as ActivityDTO);

    const { account, financialAccount, taggedAccounts } = getScheduleAccountsByHomeSchool(
      schedule?.homeSchoolId,
      scheduleData
    );

    const eventTimeZone = getTimeZone(account?.timeZone);

    const defaultVenue = isEmpty(venue) ? mappingAccountVenue(account?.venues?.[0]) : { ...venue };

    const eventGender = upperFirst(`${gender ?? ''}`.toLowerCase());
    const eventGenders = eventGender?.toUpperCase() === GENDER_TYPE.COED || !eventGender ? [] : [eventGender];

    const fullInfoLevels = (scheduleLevel?.levels ?? []).map(item => ({
      levelId: item?.id,
      levelName: item?.name,
      genders: eventGenders
    }));

    const eventLevels = fullInfoLevels.map(level => ({
      genders: level.genders,
      levelId: level.levelId
    }));

    const genders = !isEmpty(eventLevels)
      ? []
      : (eventGenders ?? []).filter(item => item !== GENDER_TYPE.COED).map(item => upperFirst(`${item}`.toLowerCase()));

    const specialEventDescription = generateSpecialEventDescription({
      levels: fullInfoLevels,
      theme: ''
    });

    let name = '';
    let opponentAccountId = null;
    let withoutOpponentAccount = false;

    const opponentAccount: AccountDTO =
      (awayAccounts ?? []).find((account: AccountDTO) => `${account.id}` === `${awaySchoolId}`) ?? ({} as AccountDTO);

    if (!isEmpty(opponentAccount)) {
      name = getPartnerEventName(
        eventName,
        generateDefaultEventName(account, generateOpponentName(opponentAccount, true))
      );
      opponentAccountId = opponentAccount?.id ?? null;
    } else {
      withoutOpponentAccount = true;
      name = getPartnerEventName(eventName, generateDefaultEventName(account));
    }

    const isBoxOfficeAccount = isInternalUser || account?.boxOfficeCustomer;

    const accountTicketTypes = (accountTickets ?? []).filter(
      (ticket: any) =>
        isBoxOfficeAccount || !ticket.distributionChannel || ticket.distributionChannel === DISTRIBUTION_CHANNEL.GOFAN
    );

    const eventProducts = (accountTicketTypes ?? []).map((ticket: AccountProductDTO) => ({
      ...new RawProductModel({})
        .setByAccountTicketType(ticket)
        .setHiddenFees(AccountService.isDailyTransactionClose(financialAccount) ? false : !!ticket.accountPaid)
        .toJSON(),
      id: undefined
    }));

    const eventStartTimes = scheduleStartTimes?.find(({ eventId }) => `${eventId}` === `${partnerEventId}`);

    const startTimeOptions = {};

    if (Object.values(eventStartTimes?.transformedStartTimeOptions ?? []).length > 0) {
      Object.values(eventStartTimes.transformedStartTimeOptions).forEach((startTimeOption: string) => {
        // expected startTimeOption ex: "Boys-1: 9:00 PM"
        const genderLevel = startTimeOption.split(': ')[0];
        const startTime = startTimeOption.split(': ')[1];

        startTimeOptions[genderLevel] = startTime;
      });
    }

    const eventIntegrationDetails = eventStartTimes?.transformedEventIntegrationDetails
      ? eventStartTimes?.transformedEventIntegrationDetails
      : undefined;

    this.content = {
      accountId: account?.id,
      financialAccountId: financialAccount?.id,
      opponentAccountId: withoutOpponentAccount ? undefined : (opponentAccountId as any),
      taggedAccountIds: taggedAccounts?.map(account => account.id) ?? [],
      activityId: activity?.id,
      reportingLabel: activity?.label,
      customSportName: activity?.label,

      archived: false,
      disableQr: true,
      postSeason: false,
      allDayEvent: false,
      enableEventValidation: true,
      eventValidationStartsBefore: 4 * 60,
      maxCapacity: MAX_CAPACITY,
      ticketLimitPerOrder: 30,
      ticketDistribution: false,
      disabledForIndividualSale: false,
      paymentCycle: account?.paymentCycle,

      genders,
      levels: eventLevels,
      name,
      specialEventDescription,

      endDateTime: endEventDt,
      startDateTime: startEventDt,
      startTimeType: EVENT_START_TIME_TYPE.ALL_TEAMS,
      startTimeOptions,
      timeZone: eventTimeZone,

      // streaming
      eventIntegrationDetails,

      venueName: defaultVenue?.name ?? '',
      venueAddress: defaultVenue?.address ?? '',
      venueCity: defaultVenue?.city ?? '',
      venueState: defaultVenue?.state ?? '',
      venueZip: defaultVenue?.zipCode ?? '',
      venueLocation: defaultVenue?.location ?? '',

      partnerId: sourceEventId,
      partnerName: sourceName,
      globalEventsId: partnerEventId,

      products: eventProducts as any[]
    } as EventDTO;

    return this;
  }

  toJSON() {
    return { ...this.content } as EventDTO;
  }
}
