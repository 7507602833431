import { useState, useEffect } from 'react';
import { isEmpty, isNil, uniq, flatten } from 'lodash';

import {
  UserService,
  getActiveUserAccountContext,
  isInternalUser as checkInternalUser,
  checkSuperAdminUser,
  checkAdminRole
} from '@gofan/api/users';
import { signIn, signOut } from '@gofan/api/auth';
import {
  getStorageAuthenticated,
  getStorageAuthBody,
  getStorageCurrentUser,
  setStorageAuthenticated,
  setStorageAuthBody,
  setStorageCurrentUser,
  clearStorage
} from '@gofan/utils/local-storage';
import { clearPendoSession } from '@gofan/utils/pendo';

import { DEFAULT_ACCOUNT } from '@gofan/constants/accounts';

import type { UserDTO } from '@gofan/api/users';

export type AuthenticationType = {
  authenticated: boolean;
  authBody: any;
  currentUser: any;
};

export type AuthenticationErrorType = {
  hasError: boolean;
  message: string;
  id?: string;
};

export const INITIAL_AUTH_STATE = {
  authenticated: false,
  authBody: null,
  currentUser: null
};

export const DEFAULT_ERROR_MESSAGE =
  'We did not recognize your email address and password combination. Please try again or reset your password.';

const getUserAccountIds = (user: any, isInternalUser: boolean): string[] => {
  if (isInternalUser) return [DEFAULT_ACCOUNT];
  return uniq(getActiveUserAccountContext(user).map((context: any) => context.accountId));
};

export const useAuth = () => {
  const [auth, setAuth] = useState<AuthenticationType | undefined>(() => {
    if (typeof window !== 'undefined') {
      return {
        authenticated: getStorageAuthenticated(),
        authBody: getStorageAuthBody(),
        currentUser: getStorageCurrentUser()
      };
    }
  });
  const [error, setError] = useState<AuthenticationErrorType | null>(null);

  const { currentUser } = auth ?? {};
  const isInternalUser = !isEmpty(currentUser) && checkInternalUser(currentUser);
  const isSuperAdminUser = !isEmpty(currentUser) && checkSuperAdminUser(currentUser.role);
  const isAdminRole = !isEmpty(currentUser) && checkAdminRole(currentUser.role);
  const userAccountIds = getUserAccountIds(currentUser, isInternalUser);

  useEffect(() => {
    if (isNil(auth)) {
      setAuth({
        authenticated: getStorageAuthenticated(),
        authBody: getStorageAuthBody(),
        currentUser: getStorageCurrentUser()
      });
    }
  }, []);

  function onSignIn({ email, password }, callback: Function) {
    if (error?.hasError) setError(null);

    signIn(email, password)
      .then(({ res }) => {
        UserService.getUserByEmail(email)
          .then((user: UserDTO) => {
            if (!_canLogin(user)) {
              setError({
                hasError: true,
                message: 'Sorry, you don’t have permission to access this resource.'
              });
            } else {
              setStorageAuthenticated(true);
              setStorageAuthBody(res);
              setStorageCurrentUser(user);

              setAuth({
                authenticated: true,
                authBody: res,
                currentUser: user
              });
              callback();
            }
          })
          .catch(() => {
            setError({
              hasError: true,
              message: DEFAULT_ERROR_MESSAGE
            });
          });
      })
      .catch(error => {
        setError({
          hasError: true,
          message: DEFAULT_ERROR_MESSAGE
        });
      });
  }

  function onSignOut(callback: Function) {
    setAuth(INITIAL_AUTH_STATE);

    // setEmailDefault('');
    clearStorage();
    signOut();
    clearPendoSession();

    callback();
  }

  function _canLogin(user: UserDTO) {
    if (!user.role || user.role === 'FAN' || user.role === 'NONE' || user.status === 'DISABLED') {
      return false;
    }
    if (user.role === 'ACCOUNT_USER') {
      const accountRolesExceptValidator = uniq(flatten(user.userAccountContexts.map(ctx => ctx.accountRoles))).filter(
        role => role !== 'VALIDATOR'
      );
      return accountRolesExceptValidator.length > 0;
    }
    return true;
  }

  return {
    auth,
    isInternalUser,
    isSuperAdminUser,
    isAdminRole,
    userAccountIds,
    onSignIn,
    onSignOut,
    authFailed: error?.hasError,
    authErrorMessage: error?.message
  };
};
