import moment from 'moment';
import dayjs from 'dayjs';
import { isEmpty, values, findIndex, isEqual, uniq, sortBy, pick, omit, isNil, uniqBy, get } from 'lodash';
import { read, utils } from 'xlsx';

import { EventService } from '@gofan/api/events';

import { EVENT_TYPES } from '@app/pages/EventInformationV2/constants';
import { getEventType } from '@app/pages/EventDetail/helpers';
import { EVENT_START_TIME_TYPE } from '@season-management/constants/constant';
import {
  PLAYOFF_EVENT_EDITING_PERMISSION,
  STATE_MANDATED_CAN_NOT_EDIT_FIELDS,
  ORDER_PLAYOFF_EVENT_EDITING_PERMISSION,
  GOFAN_SCHOOL_TYPE_EDITABLE_TYPES
} from '@app/pages/EventDetail/constants';
import { promotionCodeValidation, promotionCodeLimitValidation } from '@common/validations';
import {
  ACCESS_LEVEL,
  ACCESS_CODES_HEADERS,
  PROMOTION_CODES_HEADERS,
  MIN_DISCOUNT_VALUES
} from '@pages/EventInformationV2/components/OptionalLayout/components/PromotionCodes/constants';
import { ACCESS_CODE_DATETIME_FORMAT } from '@utils/dateUtils';
import { DATE_FORMAT_DEFAULT_WITH_TIME, config, PRODUCT_TYPE } from '@gofan/constants';
import { AccessLevelEnum, DiscountTypeEnum, PromotionTypeEnum } from '@gofan/api/promotions';

import EventDAO from '../dao/EventDAO';
import ReservedSeatingDAO from '../dao/ReservedSeatingDAO';
import LevelDAO from '../dao/LevelDAO';
import { PAGES } from '../../config/routes';
import ErrorDTO from '../dto/ErrorDTO';
import EventTypeDTO from '../dto/EventTypeDTO';
import EventTypeRequest from '../model/request/EventTypeRequest';
import EventRequest from '../model/request/EventRequest';
import PageRequest from '../model/request/PageRequest';
import FieldsRequest from '../model/request/FieldsRequest';
import ExpandRequest from '../model/request/ExpandRequest';
import SortableRequest from '../model/request/SortableRequest';
import SearchEventsRequest from '../model/request/SearchEventsRequest';
import { DISTRIBUTION_CHANNEL, COMPLIMENTARY_TICKET } from '../model/request/ProductRequest';
import AccountDAO from '../dao/AccountDAO';
import { ACTIVITY_NONE_ID, ACTIVITY_NONE_LABEL } from '../../pages/Events/constants';
import { parseUrl } from '../../commons/utils/parseQueryString';
import * as UserRoles from '../model/user.model';
import { parseString, parseNumber } from '../../utils/parseUtils';
import {
  switchZone,
  formatDateTime,
  convertDateWithTimeZone,
  DATE_FORMAT_DEFAULT,
  EVENT_DATE_FORMAT_WITH_TIMEZONE
} from '../../utils/dateUtils';
import * as objectUtils from '../../utils/objectUtils';

export const OMIT_NEW_PRODUCT_FIELDS = [
  'idBoxOffice',
  'nameBoxOffice',
  'priceBoxOffice',
  'enabledTicketBoxOffice',
  'unlimitedPass',
  'enabledPackCount',
  'clonedFormProductId'
];

export const OMIT_PRODUCT_FIELDS = [
  'limit',
  'fee',
  'packCount',
  'redemptionLimit',
  'customColor',
  'generateLink',
  'unlimitedPass',
  'enabledPackCount'
];

const ALLOWED_FIELDS_FOR_SOLD_PRODUCT = [
  'enabled',
  'limit',
  'generateLink',
  'reservedSeating',
  'customColor',
  'groupId',
  'salesStartDateTime',
  'salesEndDateTime',
  'ticketLimitPerOrder',
  'redemptionLimit',
  'formId',
  'fee',
  'hiddenFeeBase'
];

const OMIT_BOXOFFICE_FIELDS = ['packCount'];

export const fetchEventById = (eventId, fieldsRequest) => EventDAO.fetchEventById(eventId, fieldsRequest);

export const getEvent = (eventId, params = {}) => {
  const fieldsRequest = new FieldsRequest(!isEmpty(params) && !isEmpty(params.fields) ? params.fields : {});
  const expandRequest = new ExpandRequest(!isEmpty(params) && !isEmpty(params.expand) ? params.expand : {});
  return EventDAO.getEvent(eventId, expandRequest, fieldsRequest);
};

export const getEventByParams = (params = {}) => {
  const {
    sortBy = [],
    page: pageParams = {},
    fields: fieldParams = {},
    expand: expandParams = [],
    search: searchParams = {}
  } = isEmpty(params) ? {} : params;
  const pageRequest = new PageRequest({
    pageSize: pageParams.pageSize,
    pageIndex: pageParams.pageIndex
  });
  const fieldsRequest = new FieldsRequest(fieldParams);
  const expandRequest = new ExpandRequest(expandParams);
  const sortableRequest = new SortableRequest({ sortBy });
  const searchRequest = new SearchEventsRequest(searchParams);
  return EventDAO.getEventByParams({
    searchParams: searchRequest.toJSON(),
    pageRequest,
    fieldsRequest,
    expandRequest,
    sortableRequest
  });
};

export const fetchEventsByAccountId = (searchRequest, pageRequest, fieldsRequest) =>
  EventDAO.fetchEventsByAccountId(searchRequest, pageRequest, fieldsRequest);

export const fetchEventRefund = (searchRequest, pageRequest, sortableRequest) =>
  EventDAO.fetchEventRefund(searchRequest, pageRequest, sortableRequest);

export const isFutureEvent = (event = {}) => {
  const { startDateTime, endDateTime } = event;
  const now = moment();
  const endDateMoment = moment(endDateTime, DATE_FORMAT_DEFAULT);
  const startDateMoment = moment(startDateTime, DATE_FORMAT_DEFAULT);
  return startDateMoment.isSameOrBefore(endDateMoment) && now.isSameOrBefore(endDateMoment, 'minute');
};

export const checkEventIsFeatured = (event = {}, accountId = '') =>
  !isEmpty(event.featuredAccountIds) && event.featuredAccountIds.indexOf(accountId) > -1;

export const sortEventByStartDate = (events = [], desc) =>
  events.sort((prevEvent, postEvent) => {
    const preStartDate = moment(prevEvent.startDateTime, DATE_FORMAT_DEFAULT);
    const postStartDate = moment(postEvent.startDateTime, DATE_FORMAT_DEFAULT);
    const sortValue = desc ? -1 : 1;
    return postStartDate.isBefore(preStartDate) ? 1 * sortValue : -1 * sortValue;
  });

export const sortEventByFeatured = (events = [], accountId = '') => {
  const sortedEvents = sortEventByStartDate(events);
  const featuredEvents = [];
  const nonFeaturedEvents = [];
  sortedEvents.forEach(event => {
    if (checkEventIsFeatured(event, accountId)) {
      featuredEvents.push(event);
    } else {
      nonFeaturedEvents.push(event);
    }
  });
  return [...featuredEvents, ...nonFeaturedEvents];
};

export const filterUpcommingEvents = ({ events = [], accountId = '', rolesMapping = ['accountId'] }) => {
  const filteredEvents = events.filter(
    event => rolesMapping.some(roleField => event[roleField] === accountId) && isFutureEvent(event)
  );
  return sortEventByFeatured(filteredEvents, accountId);
};

export const getEventTypeIds = (events = []) => {
  const eventTypeIds = [];
  events.forEach(event => {
    if (event.eventTypeId && eventTypeIds.indexOf(event.eventTypeId) === -1) {
      eventTypeIds.push(event.eventTypeId);
    }
  });
  return eventTypeIds;
};

export const getActivityList = (events = []) => {
  const activities = [];
  let haveEmptyActivity = false;

  events.forEach(({ activity, activityId }) => {
    if (!activityId) {
      haveEmptyActivity = true;
    } else if (findIndex(activities, { id: activityId }) === -1) {
      activities.push({ ...activity });
    }
  });

  if (haveEmptyActivity) {
    activities.push({ id: ACTIVITY_NONE_ID, label: ACTIVITY_NONE_LABEL });
  }
  return activities;
};

export const generateRemovedFields = ({ key = '', prefix = '', fields = [] }) =>
  fields.map(field => `${prefix}.${key}.${field}`);

export const createEventType = request => EventDAO.createEventType(request);

export const createEvent = (request, checkDupe = false) => EventDAO.createEvent(request, checkDupe);

export const updateEvent = (request, checkDupe = false) => EventDAO.updateEvent(request, checkDupe);

export const deleteEvent = eventId => EventDAO.deleteEvent(eventId);

export const getEventSalesInfo = eventId => EventDAO.getEventSalesInfo(eventId);

export const getPartnerEventName = (eventName, defaultEventName = '') => {
  const standardizeEventName = eventName?.toLowerCase() !== 'none' ? eventName : null;
  return standardizeEventName || defaultEventName;
};

export const generateDefaultEventName = (account, opponentAccountName = 'TBD') => {
  const accountName = isEmpty(account) ? '' : account.shortName || account.mascot || account.name || '';
  if (isEmpty(accountName) || isEmpty(opponentAccountName)) return '';
  return `${accountName} vs ${opponentAccountName}`.trim();
};

export const generateOpponentName = (awayAccount, isUseMascot = true) =>
  !isEmpty(awayAccount)
    ? awayAccount.shortName || (isUseMascot && awayAccount.mascot) || awayAccount.name || '' || 'empty'
    : 'TBD';

export const generateEventName = (account, opponentAccount) => {
  const accountName = isEmpty(account) ? '' : account.shortName || account.mascot || account.name || '';

  const opponentAccountName = isEmpty(opponentAccount)
    ? ''
    : opponentAccount.shortName || opponentAccount.mascot || opponentAccount.name || '';

  if (isEmpty(accountName) || isEmpty(opponentAccountName)) return '';

  return `${accountName} vs ${opponentAccountName}`.trim();
};

export const executeActionsEventProcess = async (eventRequest, editMode) => {
  const { eventType } = eventRequest;
  const eventTypeResponse = await createEventType(new EventTypeRequest({ name: eventType }));
  if (!(eventTypeResponse instanceof EventTypeDTO)) {
    return eventTypeResponse;
  }
  if (editMode) {
    return updateEvent(new EventRequest({ ...eventRequest, eventTypeId: eventTypeResponse.id }));
  }
  return createEvent(new EventRequest({ ...eventRequest, eventTypeId: eventTypeResponse.id }));
};

export const generateConfirmationEventInfo = ({ theme, levels, genders, activity }) => {
  const eventType = isEmpty(activity) ? '' : `${activity.label}`.trim();
  const genderInfo = isEmpty(genders) ? '' : genders.sort().join(' & ').trim();
  const levelsInfo = isEmpty(levels)
    ? ''
    : levels
        .map(level => level.name)
        .join('/')
        .trim();
  const midString = [genderInfo, levelsInfo].filter(item => !!item).join(' ');

  return [eventType, midString, theme].filter(item => !!item).join(' - ');
};

/**
 * TODO: Should implement Carrier later and move this function into it then.
 */
export const navigateToListEventPage = history => {
  const { query } = parseUrl(history.location.search || '');
  const url = PAGES.events.root.calculatePath(query.accountId);
  history.push(url);
};

export const checkPermissionToViewEvents = ({ accountId = '', isInternalUser = false, userAccountContexts = [] }) => {
  if (!accountId) return false;
  if (isInternalUser) return true;

  return userAccountContexts.some(
    context =>
      context.accountId === accountId &&
      !context.inactive &&
      (context.accountRoles.includes(UserRoles.USER_ROLE_EDITOR) ||
        context.accountRoles.includes(UserRoles.USER_ROLE_COACH)) &&
      !!context.accountRoles.length
  );
};

export const executeInitialEventProcess = accountId =>
  Promise.all([AccountDAO.fetchAccountById(accountId), LevelDAO.fetchAllLevels()]);

const getUpdatedProductsForEvent = ({ event, account, editMode, form = {} }) => {
  const eventId = editMode ? event.id : undefined;
  return values(
    Object.keys(form).reduce((res, cur) => {
      if (cur.includes('tickets.')) {
        const keys = cur.split('.');
        const id = keys[1];
        const field = keys[2];
        const isNewTicket =
          (!isEmpty(res[id]) && res[id].updatedMode === 'new') || (field === 'updatedMode' && form[cur] === 'new');
        const formValue = field === 'name' ? `${form[cur]}`.trim() : form[cur];
        const fieldValue = {
          eventId,
          [field]: formValue,
          accountId: account.id,
          id: editMode && !isNewTicket ? id : undefined
        };

        if (isEmpty(res[id])) {
          res[id] = fieldValue;
        } else {
          res[id] = { ...res[id], ...fieldValue };
        }
      }

      return res;
    }, {})
  );
};

export const getUpdatedValuesForEvent = ({ form, event, levels, account, editMode }) => {
  const products = getUpdatedProductsForEvent({
    form,
    event,
    account,
    editMode
  });
  let defaultAddedValues = {
    featured: false,
    disableQr: true,
    postSeason: false,
    disabledForIndividualSale: false
  };
  let endDateTimeValue = form.startDateTime;

  if (editMode) {
    const formStartDateTime = moment(form.startDateTime);
    const eventStartDateTime = moment(event.startDateTime);

    defaultAddedValues = {};

    if (formStartDateTime.isValid() && eventStartDateTime.isValid() && formStartDateTime.isSame(eventStartDateTime)) {
      endDateTimeValue = event.endDateTime;
    }
  }

  const levelIds = !isEmpty(form.activity) && form.activity.athletic ? form.levelIds : [];
  const specialEventDescription = generateConfirmationEventInfo({
    theme: form.theme,
    genders: form.genders,
    levels: levelIds.length ? levels.filter(level => levelIds.indexOf(level.id) !== -1) : []
  });

  const updatedValues = {
    theme: form.theme,
    alert: form.alert,
    genders: form.genders,
    archived: form.archived,
    maxCapacity: form.maxCapacity,
    allDayEvent: form.allDayEvent,
    endDateTime: endDateTimeValue,
    startDateTime: form.startDateTime,
    customEventName: form.customEventName,
    specialEventDescription,
    name: form.customEventName || generateEventName(account, form.opponent),
    opponentAccountId: isEmpty(form.opponent) ? undefined : form.opponent.id
  };

  if (!editMode || !event.isSold) {
    return {
      ...event,
      ...updatedValues,
      ...defaultAddedValues,
      products,
      accountId: account.id,
      hostAccountId: account.id,
      levelIds,
      activity: form.activity,
      activityId: isEmpty(form.activity) ? null : form.activity.id,
      venueId: !isEmpty(account) && !isEmpty(account.venueIds) ? account.venueIds[0] : undefined
    };
  }

  return {
    ...event,
    ...updatedValues,
    products: products.map(product => {
      const ticket = event.tickets.find(({ id }) => `${id}` === `${product.id}`);
      if (isEmpty(ticket) || !ticket.isSold) return product;
      return { ...ticket, limit: product.limit, enabled: product.enabled };
    })
  };
};

export const groupLevelById = (levels = []) => {
  let groupLevel = {};
  levels.forEach(level => {
    const found = groupLevel[level.levelId];
    if (found) {
      groupLevel = {
        ...groupLevel,
        [level.levelId]: {
          ...found,
          genders: [...found.genders, ...level.genders]
        }
      };
    } else {
      groupLevel = {
        ...groupLevel,
        [level.levelId]: { ...level }
      };
    }
  });
  return Object.keys(groupLevel).map(levelId => ({ ...groupLevel[levelId] }));
};

export const generateLevelStringByGender = (levels = []) => {
  // group level by genders
  const GENDERS = { BOYS: 'BOYS', GIRLS: 'GIRLS' };
  const boys = [];
  const girls = [];
  const none = [];

  levels.forEach(level => {
    if (!level.genders || !level.genders.length) {
      none.push(level.levelName);
    } else {
      const levelGenders = level.genders.map(gender => `${gender}`.toUpperCase());
      if (levelGenders.includes(GENDERS.BOYS)) {
        boys.push(level.levelName);
      }
      if (levelGenders.includes(GENDERS.GIRLS)) {
        girls.push(level.levelName);
      }
    }
  });
  return {
    boys: boys.length ? `Boys ${boys.join('/').trim()}` : '',
    girls: girls.length ? `Girls ${girls.join('/').trim()}` : '',
    none: none.length ? `${none.join('/').trim()}` : ''
  };
};

export const generateSpecialEventDescription = ({ theme, levels }) => {
  if (isEmpty(levels)) return theme;
  const levelString = generateLevelStringByGender(levels);
  let midString = '';

  if (levelString.boys && levelString.girls) {
    midString = `${levelString.boys} & ${levelString.girls}`.trim();
  } else if (levelString.boys) {
    midString = `${levelString.boys}`.trim();
  } else if (levelString.girls) {
    midString = `${levelString.girls}`.trim();
  } else if (levelString.none) {
    midString = `${levelString.none}`.trim();
  }
  return [midString, theme].filter(item => !!item).join(' - ');
};

export const getEmbeddedEventGoFanUrl = (event = {}) =>
  `${config.GOFAN_URL}event/${event.id}?schoolId=${event.accountId}`;

export const getEmbeddedSeasonGoFanUrl = (season = {}) => `${config.GOFAN_URL}app/events/season/${season.id}`;

export const getUniqueLinkProductTicket = encodedString => `${config.GOFAN_URL}p/${encodedString}`;

export const checkPermissionHandlingEvent = ({
  viewMode,
  accountId = '',
  isInternalUser = false,
  userAccountContexts = []
}) => {
  if (isInternalUser) return true;
  if (!accountId) return false;
  return userAccountContexts.some(context => {
    if (viewMode) {
      return context.accountId === accountId && !context.inactive;
    }
    return (
      context.accountId === accountId &&
      !context.inactive &&
      (context.accountRoles.indexOf(UserRoles.USER_ROLE_EDITOR) !== -1 ||
        context.accountRoles.indexOf(UserRoles.USER_ROLE_COMMISSIONER_VIEWER) !== -1 ||
        context.accountRoles.indexiOf(UserRoles.USER_ROLE_COMMISSIONER_EDITOR) !== -1 ||
        context.accountRoles.indexiOf(UserRoles.USER_ROLE_COACH) !== -1)
    );
  });
};

export const getAccountsInEvent = (event = {}) => {
  if (isEmpty(event)) return [];
  const { account, hostAccount, taggedAccounts, opponentAccount } = event;
  let accounts = [];
  if (!isEmpty(account)) accounts.push(account);
  if (!isEmpty(opponentAccount)) accounts.push(opponentAccount);
  if (!isEmpty(hostAccount)) accounts.push(hostAccount);
  if (!isEmpty(taggedAccounts)) {
    accounts = [...accounts, ...taggedAccounts];
  }
  return accounts.reduce((res, acc) => {
    const found = res.find(item => `${item.id}` === `${acc.id}`);
    if (!found) res.push(acc);
    return res;
  }, []);
};

export const getRemovedAccountsInEvent = ({ accountsInEvent = [], nextUpdatedEvent = {} }) => {
  if (isEmpty(accountsInEvent) || isEmpty(nextUpdatedEvent)) {
    return [];
  }
  const removedAccountsInEvent = [];
  const updatedAccountsInEvent = getAccountsInEvent(nextUpdatedEvent);
  accountsInEvent.forEach(account => {
    const foundAcc = updatedAccountsInEvent.find(item => `${item.id}` === `${account.id}`);
    if (!foundAcc) {
      removedAccountsInEvent.push(`${account.id}`);
    }
  });
  return removedAccountsInEvent;
};

export const mappingPublishEventValues = ({
  event = {},
  activityType = '',
  requiredData = {},
  optionalData = {},
  ticketTypeData = {},
  streamingData = {}
}) => {
  if (isEmpty(event)) {
    return new ErrorDTO({ data: { message: 'Invalid Data' } });
  }

  let { timeZone } = event;
  const { postSeason } = requiredData;
  const isFundraiser = EventService.isFundraiser(event);

  // Because API responses enableEventValidation=false and eventValidationStartsBefore=360
  // so we have to prevent to add eventValidationStartsBefore=360 into body request
  // to avoid API update eventValidationStartsBefore
  let allowRequiredData = {
    eventValidationStartsBefore: event.enableEventValidation ? event.eventValidationStartsBefore : undefined
  };

  let homeAccountId = event.accountId;
  if (!isEmpty(requiredData)) {
    if (
      !parseString(requiredData.accountId) ||
      isEmpty(requiredData.account) ||
      !parseString(requiredData.financialAccountId) ||
      isEmpty(requiredData.financialAccount) ||
      !parseNumber(requiredData.activityId) ||
      isEmpty(requiredData.activity) ||
      !parseString(requiredData.eventName) ||
      !requiredData.startDateTime ||
      !requiredData.endDateTime
    ) {
      return new ErrorDTO({ data: { message: 'Invalid Data' } });
    }

    if (
      !isFundraiser &&
      !postSeason &&
      (!parseString(requiredData.venueCity) ||
        !parseString(requiredData.venueAddress) ||
        !parseString(requiredData.venueName) ||
        !parseString(requiredData.venueState) ||
        !parseString(requiredData.venueZip))
    ) {
      return new ErrorDTO({ data: { message: 'Invalid Data' } });
    }

    if (!isFundraiser && postSeason && !parseString(requiredData.venueName)) {
      return new ErrorDTO({ data: { message: 'Invalid Data' } });
    }

    const venueData = {
      venueName: requiredData.venueName || null,
      venueAddress: requiredData.venueAddress || null,
      venueCity: requiredData.venueCity || null,
      venueState: requiredData.venueState || null,
      venueZip: requiredData.venueZip || null,
      venueLocation: requiredData.venueLocation || null
    };

    // Transform level data
    const { levels } = requiredData;
    const genders = [];
    const newLevels = [];

    timeZone = requiredData.account.timeZone;
    homeAccountId = requiredData.accountId;

    const startTimeType = (streamingData?.eventIntegrationDetails ?? []).some(item => item.isBroadcast)
      ? streamingData?.startTimeType
      : requiredData?.startTimeType ?? event?.startTimeType ?? EVENT_START_TIME_TYPE.ALL_TEAMS;
    const startTimeOptions = requiredData?.startTimeOptions ?? event?.startTimeOptions ?? {};

    if (!isEmpty(levels)) {
      levels.forEach(lv => {
        objectUtils.isEmpty(lv.levelId) ? genders.push(...lv.genders) : newLevels.push(lv);
      });
    }
    allowRequiredData = {
      ...requiredData,
      ...venueData,
      startTimeType,
      startTimeOptions,
      levels: !isEmpty(newLevels) ? groupLevelById(newLevels) : [],
      genders,
      eventValidationStartsBefore: requiredData.enableEventValidation
        ? requiredData.eventValidationStartsBefore * 60
        : undefined,
      reportingLabel: requiredData.activity.label,
      name: requiredData.eventName || generateEventName(requiredData.account, requiredData.opponentAccount),
      redemptionWindow: !isNil(requiredData.redemptionWindow) ? requiredData.redemptionWindow * 60 : null
    };
  }

  let featuredAccountIds = [];
  if (isEmpty(optionalData)) {
    featuredAccountIds =
      event.featuredAccountIds.length > 0 ? [isEmpty(requiredData) ? event.accountId : requiredData.accountId] : [];
  } else {
    featuredAccountIds =
      optionalData.featuredAccountIds.length > 0
        ? [isEmpty(requiredData) ? event.accountId : requiredData.accountId]
        : [];
  }

  const allowOptionalData = omit(
    {
      ...optionalData,
      featuredAccountIds
    },
    ['accessCodesTickets', 'accessCodes']
  );

  let allowTicketTypeData = {};
  const boxOfficeCreatedProducts = (event?.products || []).filter(
    product => product.distributionChannel === DISTRIBUTION_CHANNEL.BOXOFFICE
  );
  if (!isEmpty(ticketTypeData)) {
    const updatedProducts = isEmpty(ticketTypeData.products) ? [] : ticketTypeData.products;
    allowTicketTypeData = {
      ...ticketTypeData,
      isGeneratedTicketChanged: undefined,
      hasEventLevelAccessCode: undefined,
      products: updatedProducts.reduce((res, product) => {
        let ticketSalesStartDateTime;
        let ticketSalesEndDateTime;
        if (!isEmpty(product?.salesStartDate) && !isEmpty(product?.salesStartTime)) {
          const mSalesStartDateTime = dayjs(`${product?.salesStartDate} ${product?.salesStartTime}`);
          ticketSalesStartDateTime = switchZone(
            mSalesStartDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE),
            timeZone
          );
        }

        if (!isEmpty(product?.salesEndDate) && !isEmpty(product?.salesEndTime)) {
          const mSalesEndDateTime = dayjs(`${product?.salesEndDate} ${product?.salesEndTime}`);
          ticketSalesEndDateTime = switchZone(
            mSalesEndDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE),
            timeZone
          );
        }

        const isTicketSold = product.created && hasTicketSold(product);
        const eventProduct = event?.products?.find(item => `${item.id}` === `${product.id}`);
        const optionalSettings = {
          salesStartDateTime: ticketSalesStartDateTime,
          salesEndDateTime: ticketSalesEndDateTime,
          ticketLimitPerOrder: product?.ticketLimitPerOrder,
          redemptionLimit: product?.unlimitedPass ? undefined : product.redemptionLimit,
          ...(isTicketSold
            ? { fee: eventProduct?.fee }
            : {
                ...EventService.populateFeeAndRateId(product, event, 'Edit event'),
                packCount: product?.enabledPackCount ? product.packCount : null
              })
        };

        const isSetDefaultFormId = product.productType === PRODUCT_TYPE.MOBILEPASS && isEmpty(product.formFields);
        const boxOfficeCreatedProduct = boxOfficeCreatedProducts.find(item => product.idBoxOffice === item.id);
        // handle created GoFan or not created GoFan but created BoxOffice
        if (product.created || !isEmpty(boxOfficeCreatedProduct)) {
          const isHomeTicket =
            !ticketTypeData.ticketDistribution || (product.accountId && product.accountId === event.accountId);

          let newGoFanSettings = {
            ...optionalSettings
          };

          if (isTicketSold) {
            newGoFanSettings = pick(newGoFanSettings, ALLOWED_FIELDS_FOR_SOLD_PRODUCT);
          }

          // GOFAN
          if (product.created) {
            res.push(
              omit(
                {
                  ...product,
                  ...newGoFanSettings,
                  formId: isSetDefaultFormId ? config.MOBILE_PASS_FORM_ID : null
                },
                OMIT_NEW_PRODUCT_FIELDS
              )
            );
          } else if (!isEmpty(product.name) && !isNil(product.price)) {
            res.push(
              omit(
                {
                  ...product,
                  ...newGoFanSettings,
                  id: undefined,
                  formId: isSetDefaultFormId ? config.MOBILE_PASS_FORM_ID : null
                },
                OMIT_NEW_PRODUCT_FIELDS
              )
            );
          }

          // BOXOFFICE
          if (
            isHomeTicket &&
            !isNil(product.nameBoxOffice) &&
            !isNil(product.priceBoxOffice) &&
            (product.enabledTicketBoxOffice || boxOfficeCreatedProduct)
          ) {
            const productBOData = boxOfficeCreatedProduct || omit(product, OMIT_PRODUCT_FIELDS);

            const productBOFee = productBOData?.hiddenFees
              ? get(productBOData, 'restData.hiddenFeeBase')
              : productBOData?.fee;
            const isBOFeeChanged = productBOFee !== product?.fee;
            const isBOPriceChanged = productBOData?.price !== product?.priceBoxOffice;

            let newBOSettings = {
              ...optionalSettings,
              name: product.nameBoxOffice,
              price: product.priceBoxOffice,
              hiddenFees: product.hiddenFees,
              reservedSeating: product.reservedSeating,
              seatsIoCategory: product.seatsIoCategory
            };

            // When boxoffice ticket enabled and fee does not update set payload for fee and hidden fee trigger api re-calculate their values.
            if (product.enabledTicketBoxOffice && !isBOFeeChanged && isBOPriceChanged) {
              newBOSettings = {
                ...newBOSettings,
                fee: null
              };
            }

            const isBOTicketSold = hasTicketSold(productBOData);
            if (isBOTicketSold) {
              newBOSettings = pick(newBOSettings, ALLOWED_FIELDS_FOR_SOLD_PRODUCT);
              newBOSettings = {
                ...newBOSettings,
                fee: boxOfficeCreatedProduct?.fee
              };
            }

            res.push(
              omit(
                {
                  ...productBOData,
                  ...newBOSettings,
                  groupId: product.groupId,
                  enabled: product.enabledTicketBoxOffice,
                  id: product.idBoxOffice,
                  formFields: product.formFields,
                  formId: isSetDefaultFormId ? config.MOBILE_PASS_FORM_ID : null,
                  distributionChannel: DISTRIBUTION_CHANNEL.BOXOFFICE
                },
                [...OMIT_NEW_PRODUCT_FIELDS, ...OMIT_BOXOFFICE_FIELDS]
              )
            );
          }
        } else {
          // GOFAN
          res.push(
            omit(
              {
                ...product,
                ...optionalSettings,
                distributionChannel: DISTRIBUTION_CHANNEL.GOFAN,
                formId: isSetDefaultFormId ? config.MOBILE_PASS_FORM_ID : null,
                id: undefined
              },
              OMIT_NEW_PRODUCT_FIELDS
            )
          );
          // Mobile Pass
          if (activityType === EVENT_TYPES.MOBILE_PASS) {
            return res;
          }

          // BOXOFFICE
          if (product.enabledTicketBoxOffice && !product.reservedSeating) {
            res.push(
              omit(
                {
                  ...product,
                  ...optionalSettings,
                  id: undefined,
                  reservedSeating: undefined,
                  seatsIoCategory: undefined,
                  name: product.nameBoxOffice,
                  price: product.priceBoxOffice,
                  distributionChannel: DISTRIBUTION_CHANNEL.BOXOFFICE,
                  accountId:
                    ticketTypeData.ticketDistribution && product.accountId === homeAccountId
                      ? product.accountId
                      : undefined
                },
                [...OMIT_NEW_PRODUCT_FIELDS, ...OMIT_PRODUCT_FIELDS]
              )
            );
          }
        }
        return res;
      }, [])
    };
  }

  return {
    ...event,
    ...allowRequiredData,
    ...allowOptionalData,
    ...allowTicketTypeData,
    eventIntegrationDetails: streamingData?.eventIntegrationDetails,
    specialEventDescription: generateSpecialEventDescription({
      theme: isEmpty(allowOptionalData.theme) ? parseString(event.theme) : parseString(allowOptionalData.theme),
      levels: isEmpty(allowRequiredData.levels) ? event.levels : allowRequiredData.levels
    }),
    formFields: [],
    formId: null
  };
};

const compareEventDate = (a, b, timeZone) => {
  if (isEmpty(a) && isEmpty(b)) return true;
  if (isEmpty(a) || isEmpty(b)) return false;
  const eventDate = formatDateTime({
    date: a,
    timeZone,
    parseZone: true
  }).toDate(DATE_FORMAT_DEFAULT);
  const otherEventDate = formatDateTime({
    date: b,
    timeZone,
    parseZone: true
  }).toDate(DATE_FORMAT_DEFAULT);
  return eventDate === otherEventDate;
};

export const extractPuslishedEventFields = ({
  isFundraiser = false,
  fields = {},
  otherFields = {},
  compareSchema = {
    // required
    allDayEvent: {
      name: 'all day event',
      compare: (a, b) => Boolean(a) === Boolean(b)
    },
    opponentAccountId: {
      name: 'schools',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    hostAccountId: {
      name: 'schools',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    financialAccountId: {
      name: 'schools',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    taggedAccountIds: {
      name: 'schools',
      compare: (a, b) => isEqual(a, b)
    },
    levels: {
      name: 'levels',
      compare: (a = [], b = []) => {
        const res = [];
        const levelIds = [];
        const otherLevelIds = [];
        let genders = [];
        let otherGenders = [];
        if (!isEmpty(a)) {
          sortBy(a, ['levelId']).forEach(item => {
            levelIds.push(item.levelId);
            genders = uniq([...genders, ...item.genders]);
          });
        }
        if (!isEmpty(b)) {
          sortBy(b, ['levelId']).forEach(item => {
            otherLevelIds.push(item.levelId);
            otherGenders = uniq([...otherGenders, ...item.genders]);
          });
        }
        if (!isEqual(levelIds, otherLevelIds)) {
          res.push('sport level');
        }
        if (!isEqual(genders, otherGenders)) {
          res.push('gender');
        }
        return res;
      }
    },
    startDateTime: {
      name: 'event start date time',
      compare: (a, b, extraData = {}) => {
        const { timeZone } = extraData.fields;
        return compareEventDate(a, b, timeZone);
      }
    },
    endDateTime: {
      name: 'event end date time',
      extra: ['timeZone'],
      compare: (a, b, extraData = {}) => {
        const { timeZone } = extraData.fields;
        return compareEventDate(a, b, timeZone);
      }
    },
    accountId: {
      name: 'schools',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    activityId: {
      name: 'sport',
      compare: (a, b) => parseNumber(a) === parseNumber(b)
    },
    name: {
      name: 'event name',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    redemptionWindow: {
      name: 'redemption window',
      compare: (a, b) => parseNumber(a) === parseNumber(b)
    },
    venueCity: {
      name: 'venue city',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    venueAddress: {
      name: 'venue address',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    venueLocation: {
      name: 'venue location',
      compare: (a, b) => parseString(a ?? '') === parseString(b ?? '')
    },
    venueName: {
      name: 'venue name',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    venueState: {
      name: 'venue state',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    venueZip: {
      name: 'venue zip',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    eventValidationStartsBefore: {
      name: 'gate opening time',
      compare: (a, b) => parseNumber(a) === parseNumber(b)
    },
    archived: {
      name: 'Event visibility',
      compare: (a, b) => Boolean(a) === Boolean(b)
    },
    publishDateTime: {
      name: 'Publish date time',
      compare: (a, b, extraData = {}) => {
        const { timeZone } = extraData.fields;
        return compareEventDate(a, b, timeZone);
      }
    },
    salesStartDateTime: {
      name: 'Sales start date time',
      compare: (a, b, extraData = {}) => {
        const { timeZone } = extraData.fields;
        return compareEventDate(a, b, timeZone);
      }
    },
    salesEndDateTime: {
      name: 'Sales end date time',
      compare: (a, b, extraData = {}) => {
        const { timeZone } = extraData.fields;
        return compareEventDate(a, b, timeZone);
      }
    },
    alert: {
      name: 'Alert content',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    theme: {
      name: 'Event theme',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    customSportName: {
      name: 'Custom sport name',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    description: {
      name: 'Event description',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    formFields: {
      name: 'additional information',
      compare: (a, b) => !objectUtils.isDifference(a, b, item => item.name)
    },
    featuredAccountIds: {
      name: 'Pin to top',
      compare: (a = [], b = []) => a.length === b.length
    },
    ticketLimitPerOrder: {
      name: 'Buyer purchase limit',
      compare: (a, b) => parseNumber(a) === parseNumber(b)
    },
    paymentCycle: {
      name: 'Event payment cycle',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    enableEventValidation: {
      name: 'tickets valid anytime',
      compare: (a, b) => Boolean(a) === Boolean(b)
    },
    // optional
    disableQr: {
      name: 'scan using box office',
      compare: (a, b) => Boolean(a) === Boolean(b)
    },
    glCode: {
      name: 'GL code',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    // ticket types
    maxCapacity: {
      name: 'ticket availability',
      compare: (a, b) => parseNumber(a) === parseNumber(b)
    },
    goal: {
      name: 'goal',
      compare: (a, b) => parseString(a) === parseString(b)
    },
    products: {
      name: 'ticket types',
      child: {
        name: {
          name: '',
          compare: (a, b) => parseString(a) === parseString(b)
        },
        price: {
          name: 'price',
          compare: (a, b) => parseNumber(a) === parseNumber(b)
        },
        limit: {
          name: 'allotment',
          compare: (a, b) => parseNumber(a) === parseNumber(b)
        },
        enabled: {
          name: 'current state',
          compare: (a, b) => Boolean(a) === Boolean(b)
        },
        hiddenFees: {
          name: 'hidden fees',
          compare: (a, b) => Boolean(a) === Boolean(b)
        }
      },
      compare: (a = [], b = [], extraData = {}) => {
        if (isEmpty(a) && isEmpty(b)) {
          return [];
        }
        const productIds = a.map(item => item.id);
        const otherProductIds = b.map(item => item.id);
        const listProductIds = uniq([...productIds, ...otherProductIds]);
        const title = !isEmpty(extraData) && extraData.isFundraiser ? 'Donation level' : 'ticket types';
        let res = [];

        listProductIds.forEach(productId => {
          const foundA = a.find(product => `${product.id}` === `${productId}`);
          const foundB = b.find(product => `${product.id}` === `${productId}`);
          if (isEmpty(foundA) || isEmpty(foundB)) {
            res.push(title);
          } else {
            const changedRes = extractPuslishedEventFields({
              fields: foundA,
              otherFields: foundB,
              compareSchema: !isEmpty(extraData.schema) ? extraData.schema.child : []
            });
            res = [...res, ...changedRes.map(text => `${foundA.name} ${text}`)];
          }
        });
        return uniq(res);
      }
    }
  }
}) => {
  if (isEmpty(compareSchema) || isEmpty(fields) || isEmpty(otherFields)) {
    return [];
  }
  return Object.keys(compareSchema).reduce((res, key) => {
    const { name: updatedName, compare: compareValues } = compareSchema[key];
    const isValueEqual = compareValues(fields[key], otherFields[key], {
      isFundraiser,
      fields,
      otherFields,
      schema: compareSchema[key]
    });
    if (isValueEqual instanceof Array) {
      isValueEqual.forEach(item => res.push(item));
    } else if (!isValueEqual) {
      res.push(updatedName);
    }
    return res;
  }, []);
};

export const gendersToString = (levels = [], genders = [], activity = {}) => {
  const eventType = getEventType({ activity });
  if (!EVENT_TYPES.CONFIG[eventType].ALLOW_LEVEL) {
    return '';
  }

  let newGenders = [];
  const eventGenders = isEmpty(genders) ? [] : genders.map(gender => `${gender}`.toUpperCase());

  if (!isEmpty(levels)) {
    const levelGenders = [];

    levels.forEach(level => {
      if (!isEmpty(level.genders)) {
        level.genders.map(gender => {
          levelGenders.push(`${gender}`.toUpperCase());
        });
      }
    });

    newGenders = isEmpty(levelGenders) ? [] : uniq([...eventGenders, ...levelGenders]);
  } else {
    newGenders = uniq([...eventGenders]);
  }

  const GENDERS = { BOYS: 'BOYS', GIRLS: 'GIRLS' };

  if (newGenders.length > 1) return 'B / G';
  if (newGenders.includes(GENDERS.BOYS)) return 'Boys';
  if (newGenders.includes(GENDERS.GIRLS)) return 'Girls';
  return 'Coed';
};

export const levelsToString = (levels = []) => {
  const reg = /\b[\w]/g;
  const levelsName = [];
  levels.forEach(level => {
    if (!isEmpty(`${level.levelName ?? ''}`.trim())) {
      const firstLetter = level.levelName.match(reg).join('').toUpperCase();
      levelsName.push(firstLetter);
    }
  });
  if (isEmpty(levelsName)) return '';
  return levelsName.filter(name => name !== '').join(' / ');
};

export const isOverEventEndDate = (event = {}) => {
  if (isEmpty(event)) return true;
  const now = formatDateTime({
    date: new Date(),
    parseZone: true,
    timeZone: event.timeZone
  }).toDate(DATE_FORMAT_DEFAULT);
  const endDateTime = formatDateTime({
    date: event.endDateTime,
    parseZone: true,
    timeZone: event.timeZone
  }).toDate(DATE_FORMAT_DEFAULT);
  return now > endDateTime;
};

export const isOverCancelEventEndDate = (event = {}) => {
  if (isEmpty(event)) return true;
  const now = convertDateWithTimeZone(new Date(), event.timeZone);
  const endDateTime = convertDateWithTimeZone(event.endDateTime, event.timeZone);
  return endDateTime.add(90, 'days').isBefore(now);
};

export const mapSalesInfoToEvents = ({ events, salesInfos }) => {
  const newEvents = JSON.parse(JSON.stringify(events));
  newEvents.forEach((event, index) => {
    const sInfo = salesInfos.find(si => si.eventId === event.id);
    if (!isEmpty(sInfo)) {
      newEvents[index].eventSalesInfo = sInfo;
    }
  });

  return newEvents;
};

export const hasEventTicketSold = salesInfo => {
  if (!isEmpty(salesInfo)) {
    const { eventTotalSaleQuantity } = salesInfo;
    return eventTotalSaleQuantity > 0;
  }
  return false;
};

export const hasTicketSold = (product = {}) => {
  if (isEmpty(product) || isEmpty(product.productSalesInfor)) return false;
  const { saleQuantity } = product.productSalesInfor;
  return saleQuantity > 0;
};

export const getGoFanTickets = tickets => tickets.filter(ticket => ticket.distributionChannel === 'GoFan');

export const cloneNewHomeTickets = (homeAccountId, candidateTickets, isCloneBOTickets = true) => {
  const homeAccountTickets = [];
  getGoFanTickets(candidateTickets).forEach(ticket => {
    const { price, productType, name, accountProductId, distributionChannel, limit } = ticket;
    const clonedTicket = {
      accountId: homeAccountId,
      accountProductId: parseString(accountProductId, undefined),
      enabled: true,
      generateLink: false,
      price,
      name,
      productType,
      distributionChannel,
      limit
    };
    if (isCloneBOTickets) {
      homeAccountTickets.push({
        ...clonedTicket,
        distributionChannel: 'GoFan'
      });
      homeAccountTickets.push({
        ...clonedTicket,
        distributionChannel: 'BoxOffice'
      });
    } else {
      homeAccountTickets.push(clonedTicket);
    }
  });
  return homeAccountTickets;
};

export const cloneNewOpponentTickets = (opponentAccountId, candidateTickets) => {
  const opponentAccountTickets = [];
  getGoFanTickets(candidateTickets).forEach(ticket => {
    const { price, productType, name, accountProductId, distributionChannel, limit } = ticket;
    const clonedTicket = {
      accountId: opponentAccountId,
      accountProductId: parseString(accountProductId, undefined),
      enabled: true,
      generateLink: false,
      price,
      name,
      productType,
      distributionChannel,
      limit
    };
    opponentAccountTickets.push(clonedTicket);
  });
  return opponentAccountTickets;
};

export const handleLogicAllAccountIdNull = (accountId, opponentAccountId, originalTickets) => {
  const disabledOriginalTickets = originalTickets.map(ticket => ({ id: ticket.id, enabled: false }));
  const allGoFanTickets = getGoFanTickets(originalTickets);
  const homeAccountTickets = cloneNewHomeTickets(accountId, allGoFanTickets, true);
  const opponentAccountTickets = cloneNewOpponentTickets(opponentAccountId, allGoFanTickets);
  return [...disabledOriginalTickets, ...homeAccountTickets, ...opponentAccountTickets];
};

export const cloneExistingAccountTickets = candidateTickets =>
  candidateTickets.map(ticket => {
    const { distributionChannel, generateLink } = ticket;
    return {
      id: ticket.id,
      enabled: true,
      generateLink: distributionChannel === 'GoFan' ? false : generateLink
    };
  });

export const handleLogicAllAccountIdNotNull = (accountId, opponentAccountId, originalTickets) => {
  const ticketsOtherAccounts = [];
  const ticketsAssociatedHomeAccount = [];
  const ticketsAssociatedOpponentAccount = [];
  originalTickets.forEach(ticket => {
    if (ticket.accountId === accountId) {
      ticketsAssociatedHomeAccount.push(ticket);
    } else if (ticket.accountId === opponentAccountId) {
      ticketsAssociatedOpponentAccount.push(ticket);
    } else {
      ticketsOtherAccounts.push(ticket);
    }
  });
  const homeAccountTickets = !isEmpty(ticketsAssociatedHomeAccount)
    ? cloneExistingAccountTickets(ticketsAssociatedHomeAccount)
    : [];
  const opponentAccountTickets = isEmpty(ticketsAssociatedOpponentAccount)
    ? cloneNewOpponentTickets(
        opponentAccountId,
        isEmpty(getGoFanTickets(ticketsAssociatedHomeAccount)) ? [] : ticketsAssociatedHomeAccount
      )
    : cloneExistingAccountTickets(ticketsAssociatedOpponentAccount);
  return [...homeAccountTickets, ...opponentAccountTickets, ...ticketsOtherAccounts.map(ticket => ({ id: ticket.id }))];
};

export const handleLogicSomeAccountIdNull = (accountId, opponentAccountId, originalTickets) => {
  const ticketsAccountIdNull = [];
  const ticketsOtherAccount = [];
  const ticketsAssociatedHomeAccount = [];
  const ticketsAssociatedOpponentAccount = [];
  originalTickets.forEach(ticket => {
    if (ticket.accountId === accountId) {
      ticketsAssociatedHomeAccount.push(ticket);
    } else if (ticket.accountId === opponentAccountId) {
      ticketsAssociatedOpponentAccount.push(ticket);
    } else if (ticket.accountId) {
      ticketsOtherAccount.push(ticket);
    } else {
      ticketsAccountIdNull.push(ticket);
    }
  });
  const disabledTicketsAccountIdNull = ticketsAccountIdNull.map(ticket => ({ id: ticket.id, enabled: false }));
  const homeAccountTickets = isEmpty(ticketsAssociatedHomeAccount)
    ? cloneNewHomeTickets(accountId, ticketsAccountIdNull)
    : cloneExistingAccountTickets(ticketsAssociatedHomeAccount);
  let opponentAccountTickets = [];
  if (!isEmpty(ticketsAssociatedOpponentAccount)) {
    opponentAccountTickets = cloneExistingAccountTickets(ticketsAssociatedOpponentAccount);
  } else {
    opponentAccountTickets = cloneNewOpponentTickets(
      opponentAccountId,
      isEmpty(getGoFanTickets(ticketsAssociatedHomeAccount)) ? ticketsAccountIdNull : ticketsAssociatedHomeAccount
    );
  }
  return [
    ...disabledTicketsAccountIdNull,
    ...homeAccountTickets,
    ...opponentAccountTickets,
    ...ticketsOtherAccount.map(ticket => ({ id: ticket.id }))
  ];
};

export const prepareEventDataTicketDistribution = event => {
  const { products, accountId, opponentAccountId, accountsTicket } = event;
  const allAssociatedAccounts = [
    accountId,
    opponentAccountId,
    ...products.map(product => product.accountId).filter(id => Boolean(id))
  ].reduce((acc, cur) => {
    if (!acc.includes(cur)) {
      acc.push(cur);
    }
    return acc;
  }, []);
  const originalTickets = products.filter(ticket => ticket.name !== COMPLIMENTARY_TICKET);
  let updatedProducts = [];
  const isAllAccountIdNull = !isEmpty(originalTickets) && originalTickets.every(product => !product.accountId);
  const isAllAccountIdNotNull = !isEmpty(originalTickets) && originalTickets.every(product => product.accountId);
  if (isAllAccountIdNull) {
    updatedProducts = handleLogicAllAccountIdNull(accountId, opponentAccountId, originalTickets);
  } else if (isAllAccountIdNotNull) {
    updatedProducts = handleLogicAllAccountIdNotNull(accountId, opponentAccountId, originalTickets);
  } else {
    updatedProducts = handleLogicSomeAccountIdNull(accountId, opponentAccountId, originalTickets);
  }
  const complementaryTickets = products.filter(ticket => ticket.name === COMPLIMENTARY_TICKET);
  return {
    id: event.id,
    accountsTicket: allAssociatedAccounts.map(id => {
      const ticketLimit = accountsTicket.find(accountTicket => accountTicket.accountId === id)?.ticketLimit;
      return {
        accountId: id,
        ticketLimit
      };
    }),
    ticketDistribution: true,
    products: [...complementaryTickets.map(ticket => ({ id: ticket.id })), ...updatedProducts]
  };
};

export const executeEnableTicketDistribution = events => {
  const updatedEvents = [];
  events.forEach(event => {
    updatedEvents.push(prepareEventDataTicketDistribution(event));
  });
  const request = [...updatedEvents];

  return EventDAO.updateEvents(request);
};

export const searchPlayOffDataBySchoolId = schoolId => EventDAO.searchPlayOffDataBySchoolId(schoolId);

export const isSameLevelGenderWithPlayOff = (playOff, event) => {
  if (event.levels.length > 1) {
    return false;
  }
  const eventLevels = event.levels.map(({ levelId }) => levelId);
  const eventGenders = isEmpty(event.levels) ? event.genders : event.levels[0].genders;
  const playOffLevels = playOff.levelId ? [playOff.levelId] : [];
  const playOffGenders = playOff.gender ? [playOff.gender] : [];
  if (!isEqual(eventLevels, playOffLevels)) {
    return false;
  }
  return isEqual(eventGenders, playOffGenders);
};

export const filterPlayOffEvents = (playOffEvents, event) => {
  const filteredPlayOffEvents = playOffEvents.filter(playOffEvent => {
    if (
      new Date(playOffEvent?.startDateTime) > new Date(event.startDateTime) ||
      new Date(event.startDateTime) > new Date(playOffEvent?.endDateTime)
    ) {
      return false;
    }
    if (event?.activityId !== playOffEvent?.activityId) {
      return false;
    }

    return isSameLevelGenderWithPlayOff(playOffEvent, event);
  });

  return isEmpty(filteredPlayOffEvents) ? {} : filteredPlayOffEvents[0];
};

export const getTopPermissions = permissions => {
  for (let i = 0; i < ORDER_PLAYOFF_EVENT_EDITING_PERMISSION.length; i += 1) {
    if (permissions.includes(ORDER_PLAYOFF_EVENT_EDITING_PERMISSION[i])) {
      return ORDER_PLAYOFF_EVENT_EDITING_PERMISSION[i];
    }
  }
  return PLAYOFF_EVENT_EDITING_PERMISSION.STATE_MANDATED_RESTRICTED;
};

export const handlePickEditingPermission = async (allowedAccounts = [], event) => {
  const { postSeason } = event;
  if (!postSeason) {
    return null;
  }
  const permissions = [];

  for (let i = 0; i < allowedAccounts.length; i += 1) {
    const { id, gofanSchoolType = '', stateAssociationName = '' } = allowedAccounts[i];
    if (gofanSchoolType && GOFAN_SCHOOL_TYPE_EDITABLE_TYPES.includes(gofanSchoolType?.toLowerCase())) {
      return null;
    }
    if (!isEmpty(stateAssociationName)) {
      // eslint-disable-next-line no-await-in-loop
      const playOffEvents = await searchPlayOffDataBySchoolId(id);

      if (!isEmpty(playOffEvents)) {
        const { editingPermission } = filterPlayOffEvents(playOffEvents, event);
        editingPermission && permissions.push(editingPermission);
      }
    }
  }

  if (permissions.length === 0) {
    return null;
  }

  return getTopPermissions(permissions);
};

export const hasEditingPermissionForPlayOffEventOnField = (playOffEventPermission, field) => {
  if (!playOffEventPermission || playOffEventPermission === PLAYOFF_EVENT_EDITING_PERMISSION.SCHOOL_HOSTED) {
    return true;
  }

  if (playOffEventPermission === PLAYOFF_EVENT_EDITING_PERMISSION.STATE_MANDATED_RESTRICTED) {
    return false;
  }

  if (playOffEventPermission === PLAYOFF_EVENT_EDITING_PERMISSION.STATE_MANDATED_NOT_RESTRICTED) {
    return isEmpty(field) || !STATE_MANDATED_CAN_NOT_EDIT_FIELDS.includes(field);
  }

  return false;
};
export const hasReservedSeats = ({ products }) => products.find(product => product.reservedSeating === true);
export const getTicketsByEventId = ({ eventId, updatedAt = null, page = null }) =>
  ReservedSeatingDAO.getTicketsByEventId(eventId, updatedAt, page);
export const getEventSeatsByStatus = ({ eventId, seatStatus = null, updatedAt = null, decryptFields = false }) =>
  ReservedSeatingDAO.getEventSeatsByStatus(eventId, seatStatus, updatedAt, decryptFields);
export const getEventSeatStatusByLabel = ({ eventId, seatsIoLabel }) =>
  ReservedSeatingDAO.getEventSeatStatusByLabel(eventId, seatsIoLabel);
export const updateSeatStatus = reservedSeatingDTO => ReservedSeatingDAO.setReservedSeatStatus(reservedSeatingDTO);
export const setSeatToBlocked = ({ eventId, seasonId, seatsIoLabel }) =>
  ReservedSeatingDAO.blockReservedSeat(eventId, seasonId, seatsIoLabel);
export const setSeatToUnblocked = ({ eventId, seasonId, seatsIoLabel }) =>
  ReservedSeatingDAO.unblockReservedSeat(eventId, seasonId, seatsIoLabel);
// export const unbookSeats = ticketIds => ticketIds.forEach(ticketId => ReservedSeatingDAO.unbookSeat(ticketId));
export const unbookSeatById = async ticketId => {
  await ReservedSeatingDAO.unbookSeat(ticketId);
};
export const moveBookedSeat = ({ ticketId, seatsIoLabel, productId }) =>
  ReservedSeatingDAO.moveBookedSeat(ticketId, seatsIoLabel, productId);
export const bookCompSeats = order => ReservedSeatingDAO.bookCompSeats(order);
export const updateTicketNote = (notes, accessToken) => ReservedSeatingDAO.updateTicketNote(notes, accessToken);
export const getEventUnavailableSeats = eventId => ReservedSeatingDAO.getEventUnavailableSeats(eventId);

export const getAccessCodeTemplateUpload = accessLevel => {
  if (accessLevel === ACCESS_LEVEL.TICKET) {
    return `https://${config.s3.BUCKET}.${config.s3.URL}/${config.s3.TEMPLATE_ACCESS_CODE_TICKET_LEVEL}`;
  }

  return `https://${config.s3.BUCKET}.${config.s3.URL}/${config.s3.TEMPLATE_ACCESS_CODE_EVENT_LEVEL}`;
};

export const getPromotionCodeTemplateUpload = accessLevel => {
  if (accessLevel === ACCESS_LEVEL.TICKET) {
    return `https://${config.s3.BUCKET}.${config.s3.URL}/${config.s3.TEMPLATE_PROMOTION_CODE_TICKET_LEVEL}`;
  }

  return `https://${config.s3.BUCKET}.${config.s3.URL}/${config.s3.TEMPLATE_PROMOTION_CODE_EVENT_LEVEL}`;
};

export const processReadAccessCodeFile = dataFile => {
  const workbook = read(dataFile, { type: 'binary', cellDates: true });
  const firstSheet = workbook.SheetNames[0];

  const headers = utils.sheet_to_json(workbook.Sheets[firstSheet], { header: 1 })[0];
  const accessCodes = utils.sheet_to_json(workbook.Sheets[firstSheet], { defval: null });
  return {
    headers,
    accessCodes
  };
};

export const processReadPromotionCodeFile = dataFile => {
  const workbook = read(dataFile, { type: 'binary', cellDates: true });
  const firstSheet = workbook.SheetNames[0];

  const headers = utils.sheet_to_json(workbook.Sheets[firstSheet], { header: 1 })[0];
  const promotionCodes = utils.sheet_to_json(workbook.Sheets[firstSheet], { defval: null });
  return {
    headers,
    promotionCodes
  };
};

const checkFormatPromotionCodeDateTime = dateTime => {
  if (isEmpty(dateTime)) return true;
  return moment(dateTime, ACCESS_CODE_DATETIME_FORMAT, true).isValid();
};

export const validateAccessCodesFile = ({ data, codes = [], ticket = {} }) => {
  const { headers = [], accessCodes = [] } = data;
  const uploadedAccessCodes = [];
  const uploadedAccessCodeErrors = [];
  // Check valid header
  const invalidHeader = ACCESS_CODES_HEADERS.filter(accessCodeHeader => !headers.includes(accessCodeHeader));

  if (invalidHeader.length > 0) {
    uploadedAccessCodeErrors.push(`Your file failed to upload: Invalid header, missing [${invalidHeader.toString()}].`);
  } else {
    // Check valid row
    const fileAccessCodes = accessCodes.map(({ code }) => code);
    accessCodes.forEach((accessCode, index) => {
      const { code, limit, ends_at: endDateTime, starts_at: startDateTime, tickets_name: name } = accessCode;
      const line = index + 1;
      if (!code) {
        uploadedAccessCodeErrors.push({
          line,
          message: 'Code must not be blank.'
        });
      }
      if (!promotionCodeValidation(code)) {
        uploadedAccessCodeErrors.push({
          line,
          message: 'must in between 2-50 characters long.'
        });
      }
      if (
        code &&
        (codes.includes(`${code}`) || fileAccessCodes.filter(fileCode => `${fileCode}` === `${code}`).length > 1)
      ) {
        uploadedAccessCodeErrors.push({
          line,
          message: 'Code duplicates with the existing codes.'
        });
      }
      if (!limit) {
        uploadedAccessCodeErrors.push({
          line,
          message: 'Limit must not be blank.'
        });
      }
      if (!promotionCodeLimitValidation(limit)) {
        uploadedAccessCodeErrors.push({
          line,
          message: 'Limit must be in 1 and 150.'
        });
      }
      if (!checkFormatPromotionCodeDateTime(startDateTime)) {
        uploadedAccessCodeErrors.push({
          line,
          message: `Start date is invalid format. Please enter start date in format m/d/yyyy h:mm`
        });
      } else if (moment().isAfter(moment(startDateTime)) || moment(endDateTime).isBefore(moment(startDateTime))) {
        uploadedAccessCodeErrors.push({
          line,
          message: 'Start date must in the future.'
        });
      }

      if (!checkFormatPromotionCodeDateTime(endDateTime)) {
        uploadedAccessCodeErrors.push({
          line,
          message: `End date is invalid format. Please enter end date in format m/d/yyyy h:mm.`
        });
      } else if (moment().isAfter(moment(endDateTime)) || moment(endDateTime).isBefore(moment(startDateTime))) {
        uploadedAccessCodeErrors.push({
          line,
          message: 'End date must in the future.'
        });
      }
      if (!isEmpty(ticket) && !isEmpty(name) && `${ticket.name}`.toLowerCase() !== `${name}`.toLowerCase()) {
        uploadedAccessCodeErrors.push({
          line,
          message: 'Ticket name does not match with the selected ticket.'
        });
      }

      if (isEmpty(uploadedAccessCodeErrors)) {
        uploadedAccessCodes.push({
          limit,
          value: `${code}`,
          startDateTime: startDateTime ? dayjs(startDateTime).format(DATE_FORMAT_DEFAULT_WITH_TIME) : undefined,
          endDateTime: endDateTime ? dayjs(endDateTime).format(DATE_FORMAT_DEFAULT_WITH_TIME) : undefined
        });
      }
    });
  }

  let accessCodeErrorMsg = [];
  if (uploadedAccessCodeErrors.length === uploadedAccessCodeErrors.filter(({ line }) => line === 1).length) {
    accessCodeErrorMsg = uploadedAccessCodeErrors.map(({ message }) => `Your file failed to upload: ${message}`);
  } else {
    accessCodeErrorMsg = [
      'Your file failed to upload:',
      ...uploadedAccessCodeErrors.map(({ line, message }) => `Row ${line} ${message}`)
    ];
  }

  return {
    uploadedAccessCodes,
    uploadedAccessCodeErrors: accessCodeErrorMsg
  };
};

export const validatePromotionCodeFile = ({ data, codes = [], promotionType }) => {
  const { headers = [], promotionCodes = [] } = data;
  const uploadedPromotionCodes = [];
  const uploadedPromotionCodeErrors = [];
  // Check valid header
  const invalidHeader = PROMOTION_CODES_HEADERS[promotionType].filter(
    promotionCodeHeader => !headers.includes(promotionCodeHeader)
  );
  if (invalidHeader.length > 0) {
    return {
      uploadedPromotionCodes,
      uploadedPromotionCodeErrors: [
        `Your file failed to upload: Invalid header, missing [${invalidHeader.toString()}].`
      ]
    };
  }

  // Check valid row
  const fileAccessCodes = promotionCodes.map(({ code }) => code);
  promotionCodes.forEach((promotionCode, index) => {
    const {
      code,
      limit,
      ends_at: endDateTime,
      starts_at: startDateTime,
      discount_type,
      discount_value
    } = promotionCode;
    const discountType = discount_type?.toLowerCase()?.trim();
    const discountValue = parseNumber(discount_value);
    const line = index + 1;
    if (!code) {
      uploadedPromotionCodeErrors.push({
        line,
        message: 'Code must not be blank.'
      });
    }
    if (!promotionCodeValidation(code)) {
      uploadedPromotionCodeErrors.push({
        line,
        message: 'Code must in between 2-50 characters long.'
      });
    }
    if (
      code &&
      (codes.includes(`${code}`) || fileAccessCodes.filter(fileCode => `${fileCode}` === `${code}`).length > 1)
    ) {
      uploadedPromotionCodeErrors.push({
        line,
        message: 'Code duplicates with the existing codes.'
      });
    }
    if (!limit) {
      uploadedPromotionCodeErrors.push({
        line,
        message: 'Limit must not be blank.'
      });
    }
    if (!promotionCodeLimitValidation(limit)) {
      uploadedPromotionCodeErrors.push({
        line,
        message: 'Limit must be in 1 and 150.'
      });
    }
    if (!checkFormatPromotionCodeDateTime(startDateTime)) {
      uploadedPromotionCodeErrors.push({
        line,
        message: `Start date is invalid format. Please enter start date in format m/d/yyyy h:mm`
      });
    } else if (moment().isAfter(moment(startDateTime)) || moment(endDateTime).isBefore(moment(startDateTime))) {
      uploadedPromotionCodeErrors.push({
        line,
        message: 'Start date must in the future.'
      });
    }

    if (!checkFormatPromotionCodeDateTime(endDateTime)) {
      uploadedPromotionCodeErrors.push({
        line,
        message: `End date is invalid format. Please enter end date in format m/d/yyyy h:mm.`
      });
    } else if (moment().isAfter(moment(endDateTime)) || moment(endDateTime).isBefore(moment(startDateTime))) {
      uploadedPromotionCodeErrors.push({
        line,
        message: 'End date must in the future.'
      });
    }

    if (isEmpty(discountType) && (promotionType !== PromotionTypeEnum.ACCESS_CODE || discountValue)) {
      uploadedPromotionCodeErrors.push({
        line,
        message: 'Discount type must not be blank.'
      });
    }

    if (!isEmpty(discountType) && !Object.values(DiscountTypeEnum).includes(discountType)) {
      uploadedPromotionCodeErrors.push({
        line,
        message: 'Discount type should be Amount or Percent.'
      });
    }

    if (!discountValue && (promotionType !== PromotionTypeEnum.ACCESS_CODE || !isEmpty(discountType))) {
      uploadedPromotionCodeErrors.push({
        line,
        message: 'Discount amount must not be blank.'
      });
    }

    if (!isEmpty(discountType) && discountValue) {
      if (discountType === DiscountTypeEnum.AMOUNT) {
        if (discountValue < MIN_DISCOUNT_VALUES[promotionType] || discountValue > 250) {
          uploadedPromotionCodeErrors.push({
            line,
            message: `Discount value of Discount Amount must be between ${MIN_DISCOUNT_VALUES[promotionType]}.00 and 250.00.`
          });
        }
      }
      if (discountType === DiscountTypeEnum.PERCENT) {
        if (discountValue < MIN_DISCOUNT_VALUES[promotionType] || discountValue > 100) {
          uploadedPromotionCodeErrors.push({
            line,
            message: `Discount value of Discount Percent must be between ${MIN_DISCOUNT_VALUES[promotionType]} and 100.`
          });
        }
      }
    }

    if (isEmpty(uploadedPromotionCodeErrors)) {
      uploadedPromotionCodes.push({
        limit,
        value: `${code}`,
        startDateTime: startDateTime ? dayjs(startDateTime).format(DATE_FORMAT_DEFAULT_WITH_TIME) : undefined,
        endDateTime: endDateTime ? dayjs(endDateTime).format(DATE_FORMAT_DEFAULT_WITH_TIME) : undefined,
        discountType: discountType ?? DiscountTypeEnum.AMOUNT,
        discountValue: discountValue ?? 0
      });
    }
  });

  return {
    uploadedPromotionCodes,
    uploadedPromotionCodeErrors: !isEmpty(uploadedPromotionCodeErrors)
      ? [
          'Your file failed to upload:',
          ...uploadedPromotionCodeErrors.map(({ line, message }) => `Row ${line} ${message}`)
        ]
      : []
  };
};

export const filterCreatingPromotionCodes = ({ promotionCodes, eventResponse, ticketTypeData }) => {
  const promoCodes = [];
  (promotionCodes ?? []).forEach(promotionCode => {
    let products = [];

    if (promotionCode.accessLevel === AccessLevelEnum.EVENT) {
      products = eventResponse.products
        .filter(item => item.distributionChannel === DISTRIBUTION_CHANNEL.GOFAN)
        .map(item => ({ productId: item.id, productSpecificLimit: 0 }));
    } else if (promotionCode.accessLevel === AccessLevelEnum.TICKET) {
      products = (promotionCode?.productAssociations ?? []).map(product => {
        const foundRequestProduct = ticketTypeData.products.find(item => item.id === product.productId);

        if (isEmpty(foundRequestProduct)) return {};

        const foundCreatedProduct = eventResponse.products.find(item => {
          if (item.name !== foundRequestProduct?.name || item.distributionChannel !== DISTRIBUTION_CHANNEL.GOFAN)
            return false;

          if (eventResponse.ticketDistribution) {
            return !!item.accountId && `${item.accountId}` === `${foundRequestProduct?.accountId}`;
          }

          return !item.accountId;
        });

        return { ...product, productId: foundCreatedProduct?.id };
      });
    }

    const filteredProducts = uniqBy(
      products.filter(item => !!item.productId),
      'productId'
    );

    if (filteredProducts.length > 0) {
      promoCodes.push({
        ...promotionCode,
        id: undefined,
        productAssociations: filteredProducts
      });
    }
  });

  return promoCodes;
};

export default {
  isFutureEvent,
  getEventTypeIds,
  fetchEventById,
  fetchEventsByAccountId,
  filterUpcommingEvents,
  generateRemovedFields,
  checkPermissionToViewEvents,
  generateEventName,
  executeActionsEventProcess,
  generateConfirmationEventInfo,
  getUpdatedValuesForEvent,
  groupLevelById,
  generateLevelStringByGender,
  generateSpecialEventDescription,
  getEmbeddedEventGoFanUrl,
  getEmbeddedSeasonGoFanUrl,
  getEvent,
  updateEvent,
  deleteEvent,
  getEventSalesInfo,
  getEventByParams,
  gendersToString,
  levelsToString,
  isOverEventEndDate,
  mappingPublishEventValues,
  extractPuslishedEventFields,
  mapSalesInfoToEvents,
  getAccountsInEvent,
  getRemovedAccountsInEvent,
  hasTicketSold,
  handlePickEditingPermission,
  hasReservedSeats,
  getTicketsByEventId,
  getEventSeatsByStatus,
  getEventUnavailableSeats,
  getAccessCodeTemplateUpload,
  getPromotionCodeTemplateUpload,
  processReadAccessCodeFile,
  processReadPromotionCodeFile,
  validateAccessCodesFile,
  validatePromotionCodeFile,
  filterCreatingPromotionCodes
};
