import type React from 'react';
import { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { Field } from 'rc-field-form';
import type { Rule } from 'rc-field-form/es/interface';
import { get, isEmpty, isFunction } from 'lodash';

import type { Normalize, NormalizeConifg } from '../normalize';
import { getNormalize } from '../normalize';
import { useFormContext } from '../basic-form.component';

import type { BasicDropdownConfig } from './dropdown/dropdown.component';
import Dropdown from './dropdown/dropdown.component';
import type { BasicTextAreaConfig } from './text-area/text-area.component';
import TextArea from './text-area/text-area.component';
import type { BasicTextInputConfig } from './text-input/text-input.component';
import TextInput from './text-input/text-input.component';
import type { BasicPhoneInputConfig } from './phone-input/phone-input.component';
import PhoneInput from './phone-input/phone-input.component';
import type { BasicNumberTextInputConfig } from './number-text-input/number-text-input.component';
import NumberTextInput from './number-text-input/number-text-input.component';
import type { BasicCheckboxConfig } from './checkbox/checkbox.component';
import Checkbox from './checkbox/checkbox.component';
import type { BasicRadioButtonConfig } from './radio-button/radio-button.component';
import RadioButton from './radio-button/radio-button.component';
import type { BasicToggleConfig } from './toggle/toggle.component';
import Toggle from './toggle/toggle.component';
import type { BasicMultiSelectConfig } from './multi-select/multi-select.component';
import MultiSelect from './multi-select/multi-select.component';
import type { BasicPhotoUploadConfig } from './photo-upload/photo-upload.component';
import PhotoUpload from './photo-upload/photo-upload.component';
import type { BasicColorPickerConfig } from './color-picker/color-picker.component';
import ColorPicker from './color-picker/color-picker.component';
import type { BasicMultiSelectWithAllOptConfig } from './multi-select-with-all-option/multi-select-with-all-option.component';
import MultiSelectWithAppOpt from './multi-select-with-all-option/multi-select-with-all-option.component';
import type { BasicDatePickerConfig } from './date-picker/date-picker.component';
import DatePicker from './date-picker/date-picker.component';
import type { BasicTimePickerConfig } from './time-picker/time-picker.component';
import TimePicker from './time-picker/time-picker.component';

export type FieldConfig =
  | BasicTextInputConfig
  | BasicDropdownConfig
  | BasicTextAreaConfig
  | BasicPhoneInputConfig
  | BasicNumberTextInputConfig
  | BasicCheckboxConfig
  | BasicRadioButtonConfig
  | BasicToggleConfig
  | BasicMultiSelectConfig
  | BasicPhotoUploadConfig
  | BasicColorPickerConfig
  | BasicMultiSelectWithAllOptConfig
  | BasicDatePickerConfig
  | BasicTimePickerConfig;

export type FormFieldConfig = {
  name: string;
  rules?: Rule[];
  dependencies?: string[];
  trigger?: string;
  valuePropName?: string;
  normalize?: Normalize;
  transform?: (value: any) => any;
};

interface FormFieldProps {
  name: string;
  fieldConfig: FieldConfig;
  trigger?: string;
  className?: string;
  validateTrigger?: string | string[] | false;
  children?: React.ReactNode;
}

const withFormField =
  (fieldType: string) =>
  (WrappedComponent: React.ComponentType<any>) =>
  ({ name, className, fieldConfig, trigger, validateTrigger = ['onBlur', 'onChange', 'onSubmit'] }: FormFieldProps) => {
    const { initialValues, setFieldRef, getFormFieldConfig } = useFormContext();
    const [prepare, setPrepare] = useState<boolean>(false);
    const [initialValue, setInitialValue] = useState<any>();
    const [formFieldConfig, setFormFieldConfig] = useState<FormFieldConfig | object>();
    const [normalizeConfig, setNormalizeConfig] = useState<NormalizeConifg>();

    const getFieldRef = useCallback(ref => setFieldRef(name, ref), []);

    const getInitFieldValue = useCallback(
      (fieldName: string, normalize: NormalizeConifg) => {
        const value = get(initialValues, fieldName);
        if (!isEmpty(normalize)) {
          if (isFunction(normalize.initNormalize)) {
            return normalize.initNormalize(value);
          }

          if (isFunction(normalize.normalize)) {
            return normalize.normalize(value);
          }
        }
        return value;
      },
      [initialValues]
    );

    useEffect(() => {
      const config = getFormFieldConfig(name);
      const normalize = getNormalize(fieldType, config);
      const value = getInitFieldValue(name, normalize);
      setInitialValue(value);
      setFormFieldConfig(config);
      setNormalizeConfig(normalize);
      setPrepare(true);
    }, []);

    return prepare ? (
      <Field
        name={name}
        initialValue={initialValue}
        validateTrigger={validateTrigger}
        trigger={trigger}
        {...formFieldConfig}
        {...normalizeConfig}
      >
        {(control, meta, context) => {
          const fieldValue = context.getFieldValue(name);
          const fieldError = context.getFieldError(name);
          return (
            <div className={classNames('basic-form-field', className)}>
              <WrappedComponent
                name={name}
                initialValue={initialValue}
                fieldValue={fieldValue}
                fieldError={fieldError}
                getFieldRef={getFieldRef}
                context={context}
                control={control}
                {...fieldConfig}
              />
            </div>
          );
        }}
      </Field>
    ) : null;
  };

export default {
  Dropdown: withFormField('Dropdown')(Dropdown),
  TextArea: withFormField('TextArea')(TextArea),
  TextInput: withFormField('TextInput')(TextInput),
  PhoneInput: withFormField('PhoneInput')(PhoneInput),
  NumberTextInput: withFormField('NumberTextInput')(NumberTextInput),
  Checkbox: withFormField('Checkbox')(Checkbox),
  RadioButton: withFormField('RadioButton')(RadioButton),
  Toggle: withFormField('Toggle')(Toggle),
  MultiSelect: withFormField('MultiSelect')(MultiSelect),
  PhotoUpload: withFormField('PhotoUpload')(PhotoUpload),
  ColorPicker: withFormField('ColorPicker')(ColorPicker),
  MultiSelectWithAppOpt: withFormField('MultiSelectWithAppOpt')(MultiSelectWithAppOpt),
  DatePicker: withFormField('DatePicker')(DatePicker),
  TimePicker: withFormField('TimePicker')(TimePicker)
};
