import { forwardRef } from 'react';
import { Button as CarbonButton, ButtonSet as CarbonButtonSet } from '@carbon/react';
import classNames from 'classnames';

import type { ButtonProps as CarbonButtonProps } from '@carbon/react';

import './Buttons.scss';

export type ButtonProps = CarbonButtonProps<'button'>;
export type ButtonKind = CarbonButtonProps<'button'>['kind'];

export const Button = forwardRef((props: ButtonProps, ref: React.LegacyRef<HTMLButtonElement>) => (
  <CarbonButton {...props} ref={ref} className={classNames('gf--button', props.className)} />
));

export const ButtonSet = CarbonButtonSet;
