import { useQuery } from '@tanstack/react-query';
import { CALCULATED_SPONSORS_QUERY_KEY } from './sponsorship.endpoints';
import { SponsorshipService } from './sponsorship.service';
import type { CalculatedSponsorsResponse } from './sponsorship.model';

export const useCalculatedSponsors = (schoolId: string) => {
  const calculatedSponsorQuery = useQuery<CalculatedSponsorsResponse>({
    queryKey: [CALCULATED_SPONSORS_QUERY_KEY, schoolId],
    queryFn: () => SponsorshipService.getCalculatedSponsors(schoolId)
  });

  const sponsors =
    calculatedSponsorQuery.data?.sponsors.map(s => ({
      ...s,
      id: s.sponsorId,
      status: 'active'
    })) || [];

  const totalSponsors = calculatedSponsorQuery.data?.totalSponsors || 0;

  const { isLoading } = calculatedSponsorQuery;

  return {
    calculatedSponsorQuery,
    sponsors,
    totalSponsors,
    isLoading
  };
};
