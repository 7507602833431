import { css } from 'styled-components';
import { text01, text04 } from '@carbon/themes';

export default {
  wrapper: css`
    min-height: 100vh;
    background-image: ${({ urlString }) => `url(${urlString})`};
    background-size: cover;
    background-position: center;
  `,
  wrapperGrid: css`
    padding-left: 0;
    padding-right: 0;
    .bx--row {
      margin-left: 0;
      margin-right: 0;

      .left-content {
        padding-right: 0;
        padding-left: 0;
        background-color: rgba(0, 0, 0, 0.75);
        min-height: 100vh;
        @media (max-width: 42rem) {
          min-height: unset;
        }
      }
    }
  `,
  wrapperLeftContent: css`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    flex-direction: column;
    @media (max-width: 1056px) {
      padding: 34px;
    }
    @media (max-width: 42rem) {
      padding: 16px;
    }
    img {
      width: 75%;
    }
  `,
  wrapperContainer: css`
    flex: 1;
  `,
  wrapperRowVertical: css``,
  wrapperRowVerticalCenter: css`
    display: flex;
    justify-content: center;
    flex: 1;
  `,
  wrapperCopyRight: css`
    display: flex;
    justify-content: center;
  `,
  label: css`
    color: ${text04};
  `,
  description: css``
};
