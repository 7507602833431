import { huddleAxiosInstanceProxy } from '../shared/axios';
import { GET_ACCOUNT_TICKETS, SEARCH_ACCOUNTS_PRODUCT, UPDATE_ACCOUNT_TICKET } from './account-product.endpoints';

import type {
  AccountProductSearchDTO,
  AccountProductPageResponseDTO,
  AccountProductDTO
} from './account-product.model';
import type { CreateAccountType } from './account-product.service';
import type { AxiosResponse } from 'axios';

class AccountProductRepository {
  static searchAccountProduct = (
    body: AccountProductSearchDTO,
    queryStr: string
  ): Promise<AccountProductPageResponseDTO> =>
    huddleAxiosInstanceProxy.post(`${SEARCH_ACCOUNTS_PRODUCT}${queryStr}`, body);

  static createAccountTickets = (body: CreateAccountType) => huddleAxiosInstanceProxy.post(GET_ACCOUNT_TICKETS, body);

  static editAccountTicket = (id: string, body: AccountProductDTO) =>
    huddleAxiosInstanceProxy.put(UPDATE_ACCOUNT_TICKET.replace('{id}', id), body, undefined, true);

  static deleteAccountTicket = (accountTicketId: string): Promise<AxiosResponse> =>
    huddleAxiosInstanceProxy.delete(UPDATE_ACCOUNT_TICKET.replace('{id}', accountTicketId), undefined, true);
}

export { AccountProductRepository };
