import moment from 'moment';
import dayjs from 'dayjs';

import { DateUtils } from '@gofan/utils/date';
import {
  TIME_FORMAT_DEFAULT,
  DATE_PICKER_FORMAT_DEFAULT,
  DATE_FORMAT_DEFAULT_WITH_TIMEZONE
} from '@gofan/constants/date';

import { DISTRIBUTION_CHANNEL } from '@app/api/model/request/ProductRequest';
import { isEmpty } from '../../../../../../utils/objectUtils';
import { productSchema } from './formSchema';

let uniqueCounter = 0;
export const getUniqueId = () => {
  uniqueCounter += 1;
  return new Date().getTime() + uniqueCounter;
};

export const generateEndDateTimeEvent = (events, startDateTime) => {
  if (moment.isMoment(startDateTime)) return '';
  const { eventDateAndTime } = events ?? {};
  if (eventDateAndTime?.value?.allDayEvent?.value) {
    return startDateTime.clone().set('hours', 23).set('minutes', 59).set('seconds', 1);
  }
  return startDateTime.clone().add(4, 'hours');
};

const generateProducts = ({ formState, accountTickets, levels, activityId, timeZone }) => {
  const products = [];
  let generateFromAccountTickets = false;
  if (!isEmpty(formState)) {
    if (!isEmpty(formState.values.products)) {
      formState.values.products.forEach(product => {
        let { salesStartDate, salesStartTime, salesEndDate, salesEndTime } = product;
        if (product?.salesStartDateTime) {
          const mSalesStartDateTime = DateUtils.formatDateTime({
            date: product?.salesStartDateTime,
            timeZone,
            parseZone: true
          });
          const ticketSalesStartDateTime = DateUtils.switchZone(
            mSalesStartDateTime.toDate(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
            'localZone'
          );
          const mTicketSalesStartDateTime = dayjs(ticketSalesStartDateTime);
          salesStartTime = mTicketSalesStartDateTime.clone().format(TIME_FORMAT_DEFAULT);
          salesStartDate = mTicketSalesStartDateTime.clone().format(DATE_PICKER_FORMAT_DEFAULT);
        }
        if (product?.salesEndDateTime) {
          const mSalesEndDateTime = DateUtils.formatDateTime({
            date: product?.salesEndDateTime,
            timeZone,
            parseZone: true
          });
          const ticketSalesEndDateTime = DateUtils.switchZone(
            mSalesEndDateTime.toDate(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
            'localZone'
          );
          const mTicketSalesEndDateTime = dayjs(ticketSalesEndDateTime);
          salesEndTime = mTicketSalesEndDateTime.clone().format(TIME_FORMAT_DEFAULT);
          salesEndDate = mTicketSalesEndDateTime.clone().format(DATE_PICKER_FORMAT_DEFAULT);
        }
        products.push(
          productSchema({
            ...product,
            salesStartTime,
            salesStartDate,
            salesEndTime,
            salesEndDate,
            boxOfficeTicket: !isEmpty(product.boxOfficeTicket)
              ? productSchema({
                  ...product.boxOfficeTicket,
                  distributionChannel: DISTRIBUTION_CHANNEL.BOXOFFICE
                })
              : {}
          })
        );
      });
    }
  } else if (accountTickets) {
    accountTickets.forEach(ticket => {
      if (
        !isEmpty(ticket) &&
        !isEmpty(ticket.levelIds) &&
        !isEmpty(levels) &&
        ticket.productType.name.toUpperCase() === 'TICKET' &&
        `${ticket.status}`.toLowerCase() === 'active' &&
        levels.some(level => ticket.levelIds.includes(level.levelId)) &&
        ticket.activityId === activityId
      ) {
        const now = getUniqueId();
        products.push(
          productSchema({
            id: now,
            accountProductId: ticket.id,
            productCreatedAt: now,
            name: ticket.ticketName,
            price: ticket.ticketPrice,
            hiddenFees: ticket.accountPaid,
            customColor: ticket.customColor,
            reservedSeating: ticket.reservedSeating,
            seatsIoCategory: ticket.seatsIoCategory
          })
        );
        generateFromAccountTickets = true;
      }
    });
  }
  if (isEmpty(products) && !generateFromAccountTickets && isEmpty(formState)) {
    products.push(
      productSchema({
        id: new Date().getTime(),
        hiddenFees: false
      })
    );
  }
  return products;
};

export const generateFormData = ({ formState = {}, accountTickets, levels, activityId, timeZone = null }) => {
  let maxCapacity;
  let unlimitedTicket = true;
  if (!isEmpty(formState)) {
    const { maxCapacity: maxCapacityValue } = formState.values;
    maxCapacity = maxCapacityValue;
    unlimitedTicket = !maxCapacityValue;
  } else {
    unlimitedTicket = true;
  }
  const products = generateProducts({
    formState,
    accountTickets,
    levels,
    activityId,
    timeZone
  });
  const { seasonTickets: seasonTicketsValue = [] } = formState.values ?? {};
  const seasonTickets = seasonTicketsValue.map(item => {
    let { salesStartDate, salesStartTime, salesEndDate, salesEndTime } = item;
    if (item?.salesStartDateTime) {
      const mSalesStartDateTime = DateUtils.formatDateTime({
        date: item?.salesStartDateTime,
        timeZone,
        parseZone: true
      });
      const ticketSalesStartDateTime = DateUtils.switchZone(
        mSalesStartDateTime.toDate(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
        'localZone'
      );
      const mTicketSalesStartDateTime = dayjs(ticketSalesStartDateTime);

      salesStartTime = mTicketSalesStartDateTime.clone().format(TIME_FORMAT_DEFAULT);
      salesStartDate = mTicketSalesStartDateTime.clone().format(DATE_PICKER_FORMAT_DEFAULT);
    }

    if (item?.salesEndDateTime) {
      const mSalesEndDateTime = DateUtils.formatDateTime({
        date: item?.salesEndDateTime,
        timeZone,
        parseZone: true
      });
      const ticketSalesEndDateTime = DateUtils.switchZone(
        mSalesEndDateTime.toDate(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
        'localZone'
      );
      const mTicketSalesEndDateTime = dayjs(ticketSalesEndDateTime);

      salesEndTime = mTicketSalesEndDateTime.clone().format(TIME_FORMAT_DEFAULT);
      salesEndDate = mTicketSalesEndDateTime.clone().format(DATE_PICKER_FORMAT_DEFAULT);
    }
    return productSchema({ ...item, salesStartDate, salesStartTime, salesEndDate, salesEndTime });
  });

  return {
    unlimitedTicket,
    maxCapacity,
    products,
    seasonTickets
  };
};

export default {
  generateFormData,
  generateProducts
};
