import React, { useRef, useMemo, useEffect } from 'react';
import moment from 'moment-timezone';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Toggle } from 'carbon-components-react';
import isEmpty from 'lodash/isEmpty';

import { EVENT_TYPES } from '@app/pages/EventInformationV2/constants';
import {
  DATE_PICKER_FORMAT,
  DATE_PICKER_VALUE_FORMAT,
  TIME_FORMAT,
  DATE_TIME_PICKER_FORMAT_DEFAULT,
  TIME_ZONE_ENUM
} from '@app/utils/dateUtils';
import { DateUtils } from '@gofan/utils';
import { DatePicker } from '../date-picker';
import { TimePicker } from '../time-picker';
import { FUNDRAISER } from '@gofan/constants';

import styles from './date-time-form.module.scss';

const ALL_DAY_START_TIME_DEFAULT = '12:01 AM';

const ALL_DAY_END_TIME_DEFAULT = '11:59 PM';

interface EventDateTimeFormProps {
  id: string | number;
  activityType: string;
  timezone?: string;
}

export const EventDateTimeForm = ({ id, activityType, timezone }: EventDateTimeFormProps) => {
  const {
    control,
    setValue,
    trigger,
    formState: { errors }
  } = useFormContext();

  const isFundraiser = activityType === FUNDRAISER;
  const startDate = useWatch({ control, name: 'startDate' });
  const startTime = useWatch({ control, name: 'startTime' });
  const endDate = useWatch({ control, name: 'endDate' });
  const endTime = useWatch({ control, name: 'endTime' });
  const allDayEvent = useWatch({ control, name: 'allDayEvent' });
  const eventStartDateTime = useWatch({ control, name: 'eventStartDateTime' });
  const eventEndDateTime = useWatch({ control, name: 'eventEndDateTime' });
  const mMinDateTime = moment(new Date());
  const mMaxStartDateTime = DateUtils.getMaxDateTime(eventStartDateTime, timezone || DateUtils.getTimeZone());
  const mMaxEndDateTime = DateUtils.getMaxDateTime(eventEndDateTime, timezone || DateUtils.getTimeZone());
  const minDate = mMinDateTime.clone().format(DATE_PICKER_FORMAT);
  const maxStartDateTime = mMaxStartDateTime.clone().format(DATE_PICKER_FORMAT);
  const maxEndDateTime = mMaxEndDateTime.clone().format(DATE_PICKER_FORMAT);
  const disabledStartDateTime = mMaxStartDateTime.isBefore(moment(new Date()));
  const disabledEndDateTime = mMaxEndDateTime.isBefore(moment(new Date()));

  const prevStartDate = useRef<any>('');
  const prevStartTime = useRef<any>('');

  const isSameDate = useMemo(() => {
    if (isEmpty(endDate) && isEmpty(startDate)) return true;
    const mStartDate = moment(startDate, DATE_PICKER_VALUE_FORMAT);
    const mEndDate = moment(endDate, DATE_PICKER_VALUE_FORMAT);
    return mStartDate.isValid() && mEndDate.isValid() && mStartDate.isSame(mEndDate, 'days');
  }, [startDate, endDate]);

  const setPrevStartDateTime = (startDateValue: any, startTimeValue: any) => {
    const mStartDateTime = moment(`${startDateValue} ${startTimeValue}`, DATE_TIME_PICKER_FORMAT_DEFAULT, true);
    if (mStartDateTime.isValid()) {
      prevStartDate.current = startDateValue;
      prevStartTime.current = startTimeValue;
    }
  };

  const setEventEndDateTime = (startDateValue: any, startTimeValue: any) => {
    const mPreStartDateTime = moment(
      `${prevStartDate.current} ${prevStartTime.current}`,
      DATE_TIME_PICKER_FORMAT_DEFAULT,
      true
    );

    const mStartDateTime = moment(`${startDateValue} ${startTimeValue}`, DATE_TIME_PICKER_FORMAT_DEFAULT, true);

    if (mPreStartDateTime.isValid() && mStartDateTime.isValid()) {
      const unitsAdd = mStartDateTime.diff(mPreStartDateTime, 'seconds');
      const mCurEndDateTime = moment(`${endDate} ${endTime}`, DATE_TIME_PICKER_FORMAT_DEFAULT, true);

      if (mCurEndDateTime.isValid()) {
        const addedEndDateTime = mCurEndDateTime.clone().add(unitsAdd, 'seconds');

        if (addedEndDateTime.isBetween(mMinDateTime, mMaxEndDateTime, 'seconds', '[]')) {
          setValue('endTime', addedEndDateTime.format(TIME_FORMAT));
          setValue('endDate', addedEndDateTime.format(DATE_PICKER_FORMAT));
        }
      }
    }
  };

  const onChangeEventDate = (date: any, fieldControl: any) => {
    setValue('startDate', date);
    setValue('endDate', date);
    fieldControl?.onChange(date);
    setPrevStartDateTime(date, startTime);
  };

  const onChangeStartDate = (date: any, fieldControl: any) => {
    fieldControl?.onChange(date);
    setEventEndDateTime(date, startTime);
    setPrevStartDateTime(date, startTime);
  };

  const onChangeStartDateTime = (time: any, fieldControl: any) => {
    fieldControl?.onChange(time);
    setEventEndDateTime(startDate, time);
    setPrevStartDateTime(startDate, time);
  };

  const onToggleAllDayEvent = (toggle: boolean, fieldControl: any) => {
    if (toggle) {
      setValue('startTime', ALL_DAY_START_TIME_DEFAULT);
      setValue('endTime', ALL_DAY_END_TIME_DEFAULT);
      setPrevStartDateTime(startDate, ALL_DAY_START_TIME_DEFAULT);
    }
    fieldControl?.onChange(toggle);
    fieldControl?.onBlur();
    trigger(['eventDate', 'startDate', 'startTime', 'endDate', 'endTime']);
  };

  const onBlurDateTime = (fieldControl: any) => {
    fieldControl?.onBlur();
    trigger(['eventDate', 'startDate', 'startTime', 'endDate', 'endTime']);
  };

  useEffect(() => {
    setPrevStartDateTime(startDate, startTime);
  }, [startDate, startTime]);

  return (
    <>
      <div className='gs--padding-bottom__sp5' hidden={!isSameDate || allDayEvent}>
        <Controller
          name='eventDate'
          control={control}
          render={({ field }) => (
            <>
              <input ref={field.ref} className={styles['hidden-item']} />
              <DatePicker
                id={`event-${id}-eventDate`}
                labelText={(EVENT_TYPES.CONFIG as any)[activityType].EVENT_DATE_LABEL}
                minDate={minDate}
                maxDate={maxStartDateTime}
                value={field.value}
                disabled={disabledStartDateTime}
                invalid={!!errors?.eventDate}
                invalidText={errors?.eventDate?.message}
                onBlur={() => onBlurDateTime(field)}
                onChange={date => onChangeEventDate(date, field)}
              />
            </>
          )}
        />
      </div>
      <div className='gs--padding-bottom__sp5' hidden={isSameDate && !allDayEvent}>
        <Controller
          name='startDate'
          control={control}
          render={({ field }) => (
            <>
              <input ref={field.ref} className={styles['hidden-item']} />
              <DatePicker
                id={`event-${id}-startDate`}
                labelText={(EVENT_TYPES.CONFIG as any)[activityType].START_DATE_LABEL}
                minDate={minDate}
                maxDate={maxStartDateTime}
                value={field.value}
                disabled={disabledStartDateTime}
                invalid={!!errors?.startDate}
                invalidText={errors?.startDate?.message}
                onBlur={() => onBlurDateTime(field)}
                onChange={date => onChangeStartDate(date, field)}
              />
            </>
          )}
        />
      </div>
      <div className='gs--padding-bottom__sp5' hidden={allDayEvent || isFundraiser}>
        <Controller
          name='startTime'
          control={control}
          render={({ field }) => (
            <>
              <input ref={field.ref} className={styles['hidden-item']} />
              <TimePicker
                id={`event-${id}-startTime`}
                labelText={(EVENT_TYPES.CONFIG as any)[activityType].START_TIME_LABEL}
                value={field.value}
                disabled={disabledStartDateTime}
                invalid={!!errors?.startTime}
                invalidText={errors?.startTime?.message}
                onBlur={() => onBlurDateTime(field)}
                onChange={date => onChangeStartDateTime(date, field)}
              />
            </>
          )}
        />
      </div>
      <div className='gs--padding-bottom__sp5' hidden={isSameDate && !allDayEvent}>
        <Controller
          name='endDate'
          control={control}
          render={({ field }) => (
            <>
              <input ref={field.ref} className={styles['hidden-item']} />
              <DatePicker
                id={`event-${id}-endDate`}
                labelText={(EVENT_TYPES.CONFIG as any)[activityType].END_DATE_LABEL}
                minDate={minDate}
                maxDate={maxEndDateTime}
                value={field.value}
                disabled={disabledEndDateTime}
                invalid={!!errors?.endDate}
                invalidText={errors?.endDate?.message}
                onBlur={() => onBlurDateTime(field)}
                onChange={field.onChange}
              />
            </>
          )}
        />
      </div>
      <div className='gs--padding-bottom__sp5' hidden={allDayEvent || isFundraiser}>
        <Controller
          name='endTime'
          control={control}
          render={({ field }) => (
            <>
              <input ref={field.ref} className={styles['hidden-item']} />
              <TimePicker
                id={`event-${id}-endTime`}
                labelText={(EVENT_TYPES.CONFIG as any)[activityType].END_TIME_LABEL}
                value={field.value}
                disabled={disabledEndDateTime}
                invalid={!!errors?.endTime}
                invalidText={errors?.endTime?.message}
                onBlur={() => onBlurDateTime(field)}
                onChange={field.onChange}
              />
            </>
          )}
        />
      </div>
      <div className='gs--padding-bottom__sp5' hidden={isFundraiser}>
        <Controller
          name='allDayEvent'
          control={control}
          render={({ field }) => (
            <Toggle
              ref={field.ref}
              id={`event-${id}-allDayEvent`}
              labelA='All day event'
              labelB='All day event'
              value={`${field.value}`}
              disabled={disabledStartDateTime}
              toggled={field.value}
              onToggle={value => onToggleAllDayEvent(value, field)}
            />
          )}
        />
      </div>
    </>
  );
};
