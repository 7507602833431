// @flow
import { ProductState } from './types';
import {
  ProductTypeActionTypes,
  FETCH_PRODUCT_TYPES,
  CREATE_PRODUCT_TYPE,
  UPDATE_PRODUCT_TYPE,
  DELETE_PRODUCT_TYPE,
  FETCH_PRODUCT_TYPES_COMPLETED,
  DELETE_PRODUCT_TYPE_COMPLETED,
  CREATE_PRODUCT_TYPE_COMPLETED,
  UPDATE_PRODUCT_TYPE_COMPLETED
} from './actionTypes';

export const initialState: ProductState = {
  loading: false,
  productTypes: []
};

export default (state = initialState, action: ProductTypeActionTypes) => {
  switch (action.type) {
    case CREATE_PRODUCT_TYPE:
    case UPDATE_PRODUCT_TYPE:
    case DELETE_PRODUCT_TYPE:
    case FETCH_PRODUCT_TYPES: {
      return {
        ...state,
        loading: true
      };
    }

    case FETCH_PRODUCT_TYPES_COMPLETED:
    case CREATE_PRODUCT_TYPE_COMPLETED:
    case UPDATE_PRODUCT_TYPE_COMPLETED:
    case DELETE_PRODUCT_TYPE_COMPLETED: {
      return {
        ...state,
        loading: false,
        productTypes: action.payload.productTypes
      };
    }

    default:
      return state;
  }
};
