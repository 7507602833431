import { TextInput as CarbonTextInput } from '@carbon/react';
import classNames from 'classnames';

import type { TextInputProps as CarbonTextInputProps } from '@carbon/react/lib/components/TextInput/TextInput';

import './TextInput.scss';
import { forwardRef } from 'react';

export type TextInputProps = CarbonTextInputProps;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => (
  <CarbonTextInput {...props} className={classNames('gf--text-input', props.className)} ref={ref} />
));
