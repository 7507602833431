export const GET_PAYMENT_STATEMENT = '/api/financial/{financialSchoolId}/payment-statements';
export const GET_PAYMENT_STATEMENT_FILES_BY_ID = '/api/financial/payment-statements/{id}/files';
export const GET_PAYMENT_EVENTS = '/api/financial/payment-events/';
export const GET_PAYMENT_STATEMENT_FILES_BY_IDS = '/api/financial/payment-statements/files';
export const SEND_REFUND_REQUEST = '/api/financial/fan-refund';
export const GET_PAYMENT_EVENTS_FILES_BY_ID = '/api/financial/payment-events/{id}/files';
export const GET_PAYMENT_SEASONS = '/api/financial/payment-seasons/';
export const GET_PAYMENT_SEASONS_FILES_BY_ID = '/api/financial/payment-seasons/{id}/files';
export const GET_STATEMENT_PDF_BASE64 = '/api/payment-statement/base64/{uuid}';
export const GET_STATEMENT_AUDIT_ZIP = '/api/statement/audit';
export const GET_STATEMENT_AUDIT_CONSOLIDATED_CSV_ZIP = '/api/statement/audit/consolidated-csv';
export const GET_RECENT_PAYMENT_STATEMENTS = '/api/payment-statements/recent';
export const GET_PAST_PROJECTED_PAYMENT_STATEMENTS = '/api/payment-statements/past-projected';

// query keys
export const FINANCIAL = {
  GET_RECENT_PAYMENTS: 'get_recent_payments',
  GET_PAST_FUTURE_PAYMENTS: 'get_past_future_payments'
};
