import { useEffect, useState } from 'react';
import { Button, ComposedModal, ModalBody, ModalFooter, ModalHeader, RadioButton, RadioButtonGroup } from '../..';

export const BULK_UPLOAD_TYPE = {
  BULK_CREATE_EVENTS: 1,
  BULK_EDIT_EVENTS: 2
};

interface BulkUploadModalProps {
  open: boolean;
  onBulkCreateEvents: () => void;
  onBulkEditEvents: () => void;
  onCancel: () => void;
}

export const BulkUploadModal = ({ open, onBulkCreateEvents, onBulkEditEvents, onCancel }: BulkUploadModalProps) => {
  const [bulkType, setBulkType] = useState<number | undefined>();

  useEffect(() => {
    if (open) {
      setBulkType(undefined);
    }
  }, [open]);

  return (
    <ComposedModal size='sm' open={open} preventCloseOnClickOutside onClose={() => _onClose()}>
      <ModalHeader className='gs--productive-heading-03 gs--font-family-sf'>Bulk Upload</ModalHeader>
      <ModalBody>
        <div className='gs--body-short-02 gs--font-family-sf'>Select an option for bulk upload for your events</div>
        <RadioButtonGroup
          className='gs--margin-top-sp4'
          name='bulkType'
          legendText=''
          valueSelected={bulkType}
          onChange={(value: any) => {
            setBulkType(value);
          }}
        >
          <RadioButton labelText='Bulk upload for event creation' value={BULK_UPLOAD_TYPE.BULK_CREATE_EVENTS} />
          <RadioButton labelText='Bulk upload for event editing' value={BULK_UPLOAD_TYPE.BULK_EDIT_EVENTS} />
        </RadioButtonGroup>
      </ModalBody>
      <ModalFooter>
        <Button kind='secondary' onClick={() => _onClose()}>
          Cancel
        </Button>
        <Button kind='primary' onClick={() => _onSubmit()}>
          Upload file
        </Button>
      </ModalFooter>
    </ComposedModal>
  );

  function _onClose() {
    if (typeof onCancel === 'function') onCancel();
  }

  function _onSubmit() {
    switch (bulkType) {
      case BULK_UPLOAD_TYPE.BULK_CREATE_EVENTS: {
        if (typeof onBulkCreateEvents === 'function') onBulkCreateEvents();
        break;
      }

      case BULK_UPLOAD_TYPE.BULK_EDIT_EVENTS: {
        if (typeof onBulkEditEvents === 'function') onBulkEditEvents();
        break;
      }

      default:
        break;
    }
    _onClose();
  }
};
