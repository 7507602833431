import './breakdown-by-activity.scss';

export default function BreakdownByActivity({ data }) {
  return (
    <div className='breakdown-by-sport'>
      <h4 className='breakdown-by-sport-title'>Breakdown by activity</h4>
      <div className='breakdown-by-sport-chart'>
        {data.items.map(item => {
          const itemName = item.name === 'Unlabeled' ? 'Other' : item.name;

          return (
            <div className='breakdown-by-sport-chart-row' key={item.name}>
              <div className='breakdown-by-sport-chart-col-name'>
                <div className='breakdown-by-sport-chart-bg' style={{ backgroundColor: item.color }} />
                <div className='breakdown-by-sport-chart-name'>
                  {itemName}
                  <br /> {item.percent}
                </div>
              </div>
              <div className='breakdown-by-sport-chart-col-value'>
                <div>{item.ticketSales}</div>
                <div>Ticket sales</div>
              </div>
              <div className='breakdown-by-sport-chart-col-value'>
                <div>{item.ticketSold}</div>
                <div>Tickets sold</div>
              </div>
            </div>
          );
        })}
        <div className='breakdown-by-sport-chart-row'>
          <div className='breakdown-by-sport-chart-col-name'>
            <strong>Totals</strong>
          </div>
          <div className='breakdown-by-sport-chart-col-value breakdown-by-sport-chart-col-value--blue-bordered'>
            <div style={{ fontWeight: 600 }}>{data.totals.ticketSales}</div>
            <div>Total ticket sales</div>
          </div>
          <div className='breakdown-by-sport-chart-col-value breakdown-by-sport-chart-col-value--blue-bordered'>
            <div style={{ fontWeight: 600 }}>{data.totals.ticketSold}</div>
            <div>Total tickets sold</div>
          </div>
        </div>
      </div>
    </div>
  );
}
