import classNames from 'classnames';

import styles from './Section.module.scss';

interface SectionProps {
  id: string;
  className?: string;
  title?: React.ReactNode;
  divider?: boolean;
  children: React.ReactNode;
  onFocusSection?: (section: string) => void;
}

export const Section = ({ id, className = '', title = '', divider = true, children, onFocusSection }: SectionProps) => (
  <div
    id={id}
    className={classNames(styles.section, className)}
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex={0}
    onFocus={() => typeof onFocusSection === 'function' && onFocusSection(id)}
  >
    <div className={classNames(styles.title, 'gs--productive-heading-05-semibold gs--font-family-sf')}>{title}</div>
    {children}
    {divider && <div className={styles.divider} />}
  </div>
);
