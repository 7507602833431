import moment from 'moment';
import dayjs from 'dayjs';
import { omit, isEmpty } from 'lodash';

import { getUniqueId } from '@utils/objectUtils';
import { switchZone, TIME_ZONE_ENUM, EVENT_DATE_FORMAT, EVENT_DATE_FORMAT_WITH_TIMEZONE } from '@app/utils/dateUtils';

import type { AccountDTO } from '@gofan/api/accounts';
import type { ActivityDTO } from '@gofan/api/activities';
import type { EventIntegrationDetails } from '@gofan/api/unity';
import { DEFAULT_START_TIME, EVENT_START_TIME_TYPE } from '@season-management/constants/constant';

import SeasonCreationUtil from '@season-management/utils/season-creation.utils';
import type { RawProduct } from '@season-management/middleware/models/raw-product.model';
import type { VenueInformationValue } from '@season-management/pages/season-general-setup/season-venue-section/season-venue-section.component';
import type {
  FormField,
  TimeWithPeriod,
  StartTimeTeams,
  StartTimeOptions,
  SeasonStartTime,
  GroupLevelGender,
  EventTicketAccount
} from '@season-management/middleware/models/model';

export const START_TIME_FORMAT = 'YYYY-MM-DD hh:mm A';

export interface RawEvent {
  accountId: string;
  accountsTicket?: EventTicketAccount[];
  activityId?: number;
  activityPath?: string;
  alert?: string;
  allDayEvent?: boolean;
  archived?: boolean;
  canceled?: boolean;
  createdAt?: string;
  customSportName?: string;
  description?: string;
  disableQr?: boolean;
  disabledForIndividualSale?: boolean;
  emailMessageId?: number;
  enableEventValidation?: boolean;
  endDateTime?: string;
  eventNotes?: string;
  eventTypeId?: number;
  eventValidationStartsBefore?: number;
  eventIntegrationDetails?: EventIntegrationDetails[];
  featured?: boolean;
  featuredAccountIds?: string[];
  financialAccountId: string;
  formFields?: FormField[];
  formId?: number;
  genders?: string[];
  globalEventsId?: string;
  headerImage?: string;
  hiddenFromBoxOffice?: boolean;
  hostAccountId?: string;
  id: number | string;
  levels?: GroupLevelGender[];
  maxCapacity?: number;
  name?: string;
  opponentAccountId?: string | null;
  partnerId?: string;
  partnerName?: string;
  paymentCycle?: string;
  postSeason?: boolean;
  products?: RawProduct[];
  publishDateTime?: string;
  redemptionWindow?: number;
  reportingLabel?: string;
  salesEndDateTime?: string;
  salesStartDateTime?: string;
  seasonId?: number;
  sendReminder?: boolean;
  specialEventDescription?: string;
  startDateTime?: string;
  startTimeType?: string; // [DIFFERENCE_TIME,  SAME_TIME]
  eventStartTime?: string;
  startTimeOptions?: StartTimeOptions;
  taggedAccountIds?: string[];
  termsAndConditions?: string;
  theme?: string;
  ticketDistribution?: boolean;
  ticketLimitPerOrder?: number;
  timeZone?: string;
  updatedBy?: string;
  venueAddress?: string;
  venueCity?: string;
  venueId?: number;
  venueLocation?: string;
  venueName?: string;
  venueState?: string;
  venueZip?: string;

  // additional fields
  uploadedSport?: string;
  uploadedlevel?: string;
  importGender?: string;
  importLevel?: string;
  importStartDateTime?: string;
  uploadedGender?: string;
  created?: boolean;
  deleted?: boolean;
  startTime?: TimeWithPeriod;
  seasonStartTime?: SeasonStartTime;
  activity?: ActivityDTO | null;
  account?: AccountDTO | null;
  opponentAccount?: AccountDTO | null;
  eventStartTimeType?: string;
  startTimeTeams?: StartTimeTeams;
  withoutOpponentAccount?: boolean;
  gofanEventIdExist?: string;
  dateTimeDuration?: number;
  startTimes?: any[];
}

export default class RawEventModel {
  contents: RawEvent;

  defaultValues = {
    id: getUniqueId(),
    name: '',
    allDayEvent: false,
    archived: false,
    enableEventValidation: true,
    eventValidationStartsBefore: 4 * 60,
    ticketLimitPerOrder: 30,
    timeZone: TIME_ZONE_ENUM.AMERICA_NEW_YORK,
    startDateTime: '',
    endDateTime: '',
    disabledForIndividualSale: false,

    // addtional fields
    startTime: { ...DEFAULT_START_TIME },
    eventStartTimeType: EVENT_START_TIME_TYPE.ALL_TEAMS,
    startTimeTeams: {},
    created: false,
    deleted: false,
    withoutOpponentAccount: false
  };

  constructor(contents: any) {
    this.contents = { ...contents } as RawEvent;
  }

  toJSON() {
    return { ...this.defaultValues, ...this.contents };
  }

  resetToDefault(values?: any) {
    this.contents = { ...this.contents, ...this.defaultValues, ...(values ?? {}), id: this.contents.id };
    return this;
  }

  addDefaultStartTime(startTime?: TimeWithPeriod) {
    const now = moment().format(EVENT_DATE_FORMAT);
    const startDateTime = moment(
      `${now} ${startTime?.time ?? DEFAULT_START_TIME.time} ${startTime?.period ?? DEFAULT_START_TIME.period}`,
      START_TIME_FORMAT
    );
    if (startDateTime.isValid()) {
      this.contents = {
        ...this.contents,
        startTime: {
          period: startDateTime.clone().format('A'),
          time: startDateTime.clone().format('hh:mm')
        }
      };
    }
    return this;
  }

  setVenue(venue?: VenueInformationValue) {
    if (!isEmpty(venue)) {
      this.contents = {
        ...this.contents,
        venueName: venue?.name ?? '',
        venueAddress: venue?.address ?? '',
        venueCity: venue?.city ?? '',
        venueState: venue?.state ?? '',
        venueZip: venue?.zipCode ?? '',
        venueLocation: venue?.location ?? ''
      };
    }
    return this;
  }

  setSeasonStartTimeOptions({
    startTime,
    startTimeTeams = {},
    eventStartTimeType,
    startTimeOptions,
    eventIntegrationDetails,
    ignore = false
  }: {
    startTime?: TimeWithPeriod;
    startTimeTeams?: StartTimeTeams;
    eventStartTimeType?: string;
    startTimeOptions?: StartTimeOptions;
    eventIntegrationDetails?: EventIntegrationDetails[];
    ignore?: boolean;
  }) {
    if (ignore) return this;

    const now = moment().format(EVENT_DATE_FORMAT);
    const startDateTime = moment(
      `${now} ${startTime?.time ?? DEFAULT_START_TIME.time} ${startTime?.period ?? DEFAULT_START_TIME.period}`,
      START_TIME_FORMAT
    );

    if (startDateTime.isValid()) {
      const startTimeType = eventStartTimeType ?? EVENT_START_TIME_TYPE.ALL_TEAMS;
      const newStartTimeOptions = SeasonCreationUtil.convertStartTimeTeamsToOptions({
        startTimeType,
        startTimeTeams
      });

      this.contents = {
        ...this.contents,
        startTimeType,
        startTimeOptions: !isEmpty(startTimeOptions) ? startTimeOptions : newStartTimeOptions,
        eventIntegrationDetails,
        seasonStartTime: {
          second: 0,
          hour: startDateTime.clone().hour(),
          minute: startDateTime.clone().minute(),
          type: startDateTime.clone().format('A'),
          value: startDateTime.clone().format('hh:mm'),
          // [TODO][NEW_STRUCTURE_FOR_SEASON_START_TIME]
          period: startDateTime.clone().format('A'),
          time: startDateTime.clone().format('hh:mm')
        }
      };
    }
    return this;
  }

  setByAccount(account: AccountDTO) {
    if (!isEmpty(account)) {
      const { name, city, zip, state, location, streetAddress, streetAddressTwo } = account?.venues?.[0] ?? {};

      this.contents = {
        ...this.contents,
        account: omit(account, ['_embedded', '_links']) as AccountDTO,
        accountId: account?.id,
        venueAddress: [streetAddress, streetAddressTwo].filter(value => !isEmpty(value)).join(' '),
        venueCity: city ?? '',
        venueLocation: location ?? '',
        venueName: name ?? '',
        venueState: state ?? '',
        venueZip: zip ?? '',
        timeZone: account?.timeZone ?? TIME_ZONE_ENUM.AMERICA_NEW_YORK
      };
    }
    return this;
  }

  setByActivity(activity?: ActivityDTO) {
    if (!isEmpty(activity)) {
      this.contents = {
        ...this.contents,
        activity: omit(activity, ['_embedded', '_links']) as ActivityDTO,
        activityId: activity?.id
      };
    }
    return this;
  }

  setEventVisibility(archived?: boolean) {
    this.contents = {
      ...this.contents,
      archived
    };
    return this;
  }

  setEventAlert(alert?: string) {
    if (!isEmpty(alert)) {
      this.contents = {
        ...this.contents,
        alert
      };
    }
    return this;
  }

  setOptionalTicketSetting(timeZone: string) {
    this.contents = {
      ...this.contents,
      products: (this.contents.products ?? []).map(product => {
        let salesStartDateTime;
        let salesEndDateTime;
        if (!isEmpty(product?.salesStartDate) && !isEmpty(product?.salesStartTime)) {
          const mSalesStartDateTime = dayjs(`${product?.salesStartDate} ${product?.salesStartTime}`);
          salesStartDateTime = switchZone(
            mSalesStartDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE),
            timeZone
          );
        }
        if (!isEmpty(product?.salesEndDate) && !isEmpty(product?.salesEndTime)) {
          const mSalesEndDateTime = dayjs(`${product?.salesEndDate} ${product?.salesEndTime}`);
          salesEndDateTime = switchZone(mSalesEndDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE), timeZone);
        }
        return {
          ...omit(product, ['salesStartDate', 'salesStartTime', 'salesEndDate', 'salesEndTime']),
          salesStartDateTime,
          salesEndDateTime
        };
      })
    };
    return this;
  }
}
