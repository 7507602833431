import { huddleAxiosInstanceProxy } from '../shared/axios';

import { TEAM_URI, GET_TEAM_SEARCH } from './teams.endpoints';

import { getQueryString, mockTeams } from '@gofan/utils';

import type { AxiosResponse } from 'axios';
import type {
  TeamSearchResponseDTO,
  TeamDTO,
  TeamResponseDTO,
  TeamCreateRequestDTO,
  TeamUpdateRequestDTO,
  TeamPageResponseDTO,
  TeamSearchParams
} from './teams.model';
import type { UserDTO } from '../users';
import { UserService } from '../users';
import { uniq } from 'lodash';

class TeamRepository {
  static getTeamById = (teamId: string): Promise<TeamDTO> => {
    const team = mockTeams.find(item => item.id === teamId);
    return Promise.resolve(team || ({} as TeamDTO));
  };

  static findTeamById = (teamId: string): Promise<TeamResponseDTO> =>
    huddleAxiosInstanceProxy.get<TeamResponseDTO>(`${TEAM_URI}${teamId}`, undefined, true);

  static createTeam = (team: TeamCreateRequestDTO): Promise<TeamResponseDTO> =>
    huddleAxiosInstanceProxy.post<TeamResponseDTO, TeamCreateRequestDTO>(TEAM_URI, team, undefined, true);

  static updateTeam = (team: TeamUpdateRequestDTO): Promise<TeamResponseDTO> =>
    huddleAxiosInstanceProxy.put<TeamUpdateRequestDTO, TeamResponseDTO>(`${TEAM_URI}${team.id}`, team, undefined, true);

  static deleteTeam = (teamId: string): Promise<AxiosResponse> =>
    huddleAxiosInstanceProxy.delete(`${TEAM_URI}${teamId}`, undefined, true);

  static searchTeam = (query: TeamSearchParams): Promise<TeamPageResponseDTO> =>
    huddleAxiosInstanceProxy.get<TeamPageResponseDTO>(GET_TEAM_SEARCH, {
      params: query
    });

  static getAccountsTeams = (params: TeamSearchParams): Promise<TeamSearchResponseDTO> =>
    huddleAxiosInstanceProxy.get<TeamSearchResponseDTO>(
      GET_TEAM_SEARCH.concat(
        getQueryString({
          ...params,
          size: params.size || 20
        })
      )
    );

  static getTeamsForCoach = async (user: UserDTO, accountId: string) => {
    const userWithCoachInfo = await UserService.getCoachInfo(user, accountId);
    if (!userWithCoachInfo.coachInfo) {
      return [];
    }

    try {
      const accountTeams = userWithCoachInfo.coachInfo.find(team => team.accountId === accountId);
      return uniq(accountTeams?.teams.map(team => team.teamId)) || [];
    } catch {
      return [];
    }
  };
}

export { TeamRepository };
