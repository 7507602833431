import { useRef, useMemo, useState, useEffect, useContext, useCallback } from 'react';
import classNames from 'classnames';
import { get, isEmpty, isEqual, differenceBy, uniqueId, uniq } from 'lodash';
import { Row, Column, SkeletonText, SkeletonPlaceholder } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { CopyLink16 } from '@carbon/icons-react';

import { EVENT_DATE_FORMAT_WITH_TIMEZONE } from '@utils/dateUtils';

import { PAGES } from '@config/routes';
import { RootContext } from '@app/RootContext';
import { getEmbeddedEventGoFanUrl, gendersToString, levelsToString } from '@api/services/EventService';
import { generateError } from '@utils/alertUtils';
import { formatDateTime } from '@app/utils/dateUtils';
import { formatNumber, formatCurrency } from '@app/utils/numberUtils';

import type { EventDTO as GFEventDTO } from '@gofan/api/events';
import type { SchoolConfig } from '@gofan/api/school-config';
import type { AccountDTO } from '@gofan/api/accounts';
import type { ActivityDTO } from '@gofan/api/activities';
import type { LevelDTO } from '@gofan/api/levels';
import { AccountService } from '@gofan/api/accounts';
import { getActiveUserAccountContext } from '@gofan/api/users';
import type { EventDTO, EventSearchDTO, EventPageResponseDTO } from '@events/models/event.model';
import type { EventSearchParams } from '@events/services/events.service';
import EventService from '@events/services/events.service';
import {
  getSortBy,
  mappingPills,
  getFilterParamsByPills,
  getFilterParamsByAccounts,
  getSearchParamsByFilterValues,
  getEventName,
  getDefaultSearchParams
} from '@events/utils/event-table.utils';

import CopyButton from '@common/carbon-ui/atoms/CopyButton';
import BasicTable from '@old-components/basic-table/basic-table.component';
import type { Pill } from '@old-components/filter-pills/filter-pills.component';

import styled from 'styled-components';
import styles from '@app/pages/EventLanding/components/EventTable/styles';
import { scrollToTop } from '@app/utils/behaviorUtils';
import EventInsight from '@events/components/event-insight/event-insight.component';
import { QUICK_FILTER_TYPE } from '@events/constants';
import { USE_EVENT_NAME_GOFAN_SCHOOL_TYPES } from '@gofan/constants/school';
import EventOverflowMenu from '../event-overflow-menu/event-overflow-menu.component';
import EventVisibilityTag from '../event-visibility-tag/event-visibility-tag.component';
import type { FormValues } from '../event-filter-form/event-filter-form.component';
import FilterForm from '../event-filter-form/event-filter-form.component';
import { useGamesByEvents } from '@events/hooks/useGamesByEvents';
import { Icons } from '@gofan/components';
import moment from 'moment';
import { EventService as NewEventService } from '@gofan/api/events';
import { UnityService } from '@gofan/api/unity';
import { FEATURE_FLAGS, useFeatureFlags } from '@gofan/hooks/useFeatureFlags';
import type { VenueDTO } from '@gofan/api/venues';
import type { EventTable as EventTableKind } from '@dashboard/hooks';

export const DATE_FORMAT = 'MMM DD YYYY';
export const EVENT_INSIGHTS_OFFSET = 49;

export type DispatchedEvent = {
  id: number | null;
  action: 'UPDATE' | 'DELETE' | 'RELOAD';
  event: GFEventDTO | EventDTO | null;
};

export type EventTableProps = {
  kind: EventTableKind;
  triggerOnChange: boolean;
  history: any;
  filteredPills: Pill[];
  accounts: AccountDTO[];
  schoolsConfig?: SchoolConfig[];
  districtAccount?: AccountDTO;
  activities: ActivityDTO[];
  levels: LevelDTO[];
  venues: VenueDTO[];
  addNotification: Function;
  onAddPills: Function;
  initialEventTableFilters: FormValues;
  updateEventTableFilters: Function;
  dispatchedEvent: DispatchedEvent | undefined;
  setDispatchedEvent: (data: DispatchedEvent) => void;
};

const InsightWrapper = styled.div`
  ${styles.filterFormWrapper};
`;

const InsightContainer = styled.div`
  ${styles.insightContainer};
`;

const EventTable = ({
  kind,
  triggerOnChange,
  history,
  filteredPills,
  accounts,
  schoolsConfig,
  districtAccount,
  activities,
  levels,
  venues,
  addNotification,
  onAddPills,
  initialEventTableFilters,
  updateEventTableFilters,
  dispatchedEvent,
  setDispatchedEvent
}: EventTableProps) => {
  const { currentUser } = useContext(RootContext);
  const { [FEATURE_FLAGS.tempDistrictConferenceUnicorn]: enableDistrictUnicorn } = useFeatureFlags();
  const timerRef = useRef<any>();
  const isUnmounted = useRef<boolean>(false);
  const searchRequestKey = useRef<string>('');
  const initialDataRef = useRef<boolean>(false);
  const pillsRef = useRef<Pill[]>([]);
  const accountsRef = useRef<AccountDTO[]>(accounts);
  const filterValuesRef = useRef<FormValues>({
    timeRange: QUICK_FILTER_TYPE.ALL_EVENTS
  });
  const [prepare, setPrepare] = useState<boolean>(false);
  const [forceKey, setForceKey] = useState<number>(1);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [toolbarOpen, setToolbarOpen] = useState<boolean>(false);
  const searchParamsRef = useRef<EventSearchParams>(getDefaultSearchParams(kind));
  const [isInsightModalShown, setIsInsightModalShown] = useState<boolean>(false);
  const [eventInsight, setEventInsight] = useState<EventDTO>();
  const [eventResponse, setEventResponse] = useState<EventPageResponseDTO>({
    content: [],
    totalElements: 0
  });
  const [awayAccounts, setAwayAccounts] = useState<AccountDTO[]>([]);
  const shouldUseEventNameFormat = useMemo(() => {
    const initialAccounts = uniq(
      getActiveUserAccountContext(currentUser).map((context: any) => ({
        id: context.accountId,
        name: context.accountName
      }))
    );
    const foundHomeAccount = accounts?.find(account => `${account?.id}` === `${initialAccounts?.[0]?.id}`);
    return (
      initialAccounts.length === 1 &&
      !isEmpty(foundHomeAccount) &&
      !USE_EVENT_NAME_GOFAN_SCHOOL_TYPES?.includes(foundHomeAccount?.gofanSchoolType?.toLowerCase())
    );
  }, [currentUser]);

  const { games, canCheckPlayOnSiteIcon } = useGamesByEvents({ events: (eventResponse.content ?? []) as GFEventDTO[] });

  useEffect(() => {
    pillsRef.current = filteredPills || [];
  }, []);

  const getSearchParams = () => {
    const values = { body: {} };

    if (searchParamsRef.current?.body?.specificDate) {
      const userTimeZone = moment.tz.guess();
      const startDate = moment(searchParamsRef.current?.body?.specificDate)
        .tz(userTimeZone)
        .startOf('day')
        .utc()
        .format(EVENT_DATE_FORMAT_WITH_TIMEZONE);
      const endDate = moment(searchParamsRef.current?.body?.specificDate)
        .tz(userTimeZone)
        .endOf('day')
        .utc()
        .format(EVENT_DATE_FORMAT_WITH_TIMEZONE);

      values.body = {
        startDate,
        endDate,
        specificDate: undefined
      };
    }

    const isDistrictUser = enableDistrictUnicorn && !isEmpty(districtAccount);
    if (isDistrictUser) {
      const newValues = AccountService.getSearchParamsByPermission({
        currentUser,
        schoolsConfig,
        districtAccount,
        accountIds: [...(searchParamsRef.current?.body?.accountIds ?? [])],
        financialAccountIds: [...(searchParamsRef.current?.body?.financialAccountIds ?? [])]
      });

      values.body = { ...values.body, ...newValues };
    }

    if (searchParamsRef.current?.body?.searchOption === 'Away') {
      values.body = { ...values.body, financialAccountIds: undefined };
    }

    return {
      ...searchParamsRef.current,
      body: {
        ...searchParamsRef.current.body,
        ...values.body
      }
    } as EventSearchParams;
  };

  const handleSearchEvent = useCallback(
    (requestKey: string) => {
      const params = getSearchParams();

      if (
        isEmpty(searchParamsRef.current.body.accountIds) ||
        (isEmpty(params?.body?.accountIds) && isEmpty(params?.body?.financialAccountIds))
      ) {
        initialDataRef.current = true;
        setPrepare(false);
        setEventResponse({
          content: [],
          totalElements: 0
        });
        return;
      }

      EventService.searchEventByParams(params)
        .then(async (res: EventPageResponseDTO) => {
          if (isUnmounted.current || searchRequestKey.current !== requestKey) return;

          const content = res?.content ?? [];
          const pageNumber = res?.number ?? 0;
          const totalElements = res?.totalElements ?? 0;

          // daily sale info and awaySchool data
          let dailySalesInfo = {};
          try {
            dailySalesInfo = await EventService.getDailySalesInfo(content);
          } catch (error) {
            if (!isUnmounted.current && searchRequestKey.current === requestKey) {
              addNotification(generateError(error));
            }
          }

          // AwaySchool data
          const awayIds = uniq(
            content
              ?.map(event => event?.opponentAccountId ?? (event?.featuredAccountIds?.[0] as string))
              ?.filter(id => !!id)
          );
          let awaySchools: AccountDTO[] = [];
          try {
            awaySchools = await AccountService.getAccountsByIds(awayIds);
          } catch (error) {
            if (!isUnmounted.current && searchRequestKey.current === requestKey) {
              addNotification(generateError(error));
            }
          }

          setAwayAccounts(awaySchools);
          setEventResponse({
            ...res,
            number: pageNumber,
            totalElements,
            content: EventService.mapAdditionalInfoToEvent({
              events: content,
              eventDailySalesInfo: dailySalesInfo,
              awaySchools
            })
          });
        })
        .catch(
          (err: any) =>
            !isUnmounted.current && searchRequestKey.current === requestKey && addNotification(generateError(err))
        )
        .finally(() => {
          if (isUnmounted.current) return;

          if (!initialDataRef.current) {
            initialDataRef.current = true;
          }

          setPrepare(false);
        });
    },
    [searchRequestKey.current, searchParamsRef.current, isUnmounted.current, initialDataRef.current]
  );

  const onSearchEvent = (timer = 0) => {
    const requestKey = uniqueId('search_request');
    searchRequestKey.current = requestKey;
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => handleSearchEvent(requestKey), timer);
  };

  const handleGridChanged = useCallback(
    ({ sortBy, pageSize, currentPage }) => {
      const nextSortBy = getSortBy(sortBy);
      const isClientSort = EventService.isClientSort(nextSortBy.header!);
      searchParamsRef.current = {
        ...searchParamsRef.current,
        pageSize,
        page: currentPage,
        sortBy: nextSortBy
      };
      if (!isClientSort) onSearchEvent();
      setForceKey(new Date().getTime());
    },
    [searchParamsRef.current, forceKey]
  );

  const handleSearchChanged = useCallback(
    (e: { target: HTMLInputElement }) => {
      const { value } = e.target;
      const keyword = `${value}`.trim();
      searchParamsRef.current = {
        ...searchParamsRef.current,
        page: 0,
        body: {
          ...searchParamsRef.current.body,
          searchTerm: keyword
        }
      };
      onSearchEvent(2000);
    },
    [timerRef.current, searchParamsRef.current]
  );

  const handleFilterApplied = useCallback(
    (filterParams: EventSearchDTO, filterValues: FormValues, needToResetForm?: boolean, timer = 2000) => {
      searchParamsRef.current = {
        ...searchParamsRef.current,
        page: 0,
        body: { ...searchParamsRef.current.body, ...filterParams }
      };
      filterValuesRef.current = { ...filterValues, timeRange: filterValues?.timeRange ?? QUICK_FILTER_TYPE.ALL_EVENTS };

      if (typeof updateEventTableFilters === 'function') {
        updateEventTableFilters({ ...filterValues });
      }

      pillsRef.current = mappingPills(filterValues, true);
      scrollToTop();
      onAddPills(pillsRef.current);
      setIsFilterApplied(!needToResetForm);
      onSearchEvent(timer);
    },
    [timerRef.current, searchParamsRef.current]
  );

  const handleShowEventInsight = (event: GFEventDTO | EventDTO) => {
    setEventInsight(event as EventDTO);
    setIsInsightModalShown(true);
  };

  const onUpdatedEvent = useCallback(
    (updatedEvent: GFEventDTO | EventDTO) => {
      const { content = [] } = eventResponse;
      setEventResponse({
        ...eventResponse,
        content: content.map((item: EventDTO) =>
          `${item.id}` === `${updatedEvent.id}` ? { ...(updatedEvent as EventDTO) } : { ...item }
        )
      });
    },
    [eventResponse]
  );

  const onDeletedEvent = useCallback(
    (deletedEvent: GFEventDTO | EventDTO) => {
      const { content = [], totalElements = 1 } = eventResponse;
      setEventResponse({
        ...eventResponse,
        totalElements: totalElements - 1,
        content: content.filter((item: EventDTO) => `${item.id}` !== `${deletedEvent.id}`)
      });
    },
    [eventResponse]
  );

  const customSortRow = useCallback((cellA, cellB, { sortDirection, sortStates, key: sortKey }) => {
    if (sortDirection && sortDirection !== sortStates.NONE && EventService.isClientSort(sortKey)) {
      return sortDirection === sortStates.DESC ? cellB - cellA : cellA - cellB;
    }
    return 0;
  }, []);

  useEffect(() => {
    if (!isEmpty(dispatchedEvent)) {
      if (dispatchedEvent.action === 'UPDATE' && !isEmpty(dispatchedEvent.event)) {
        onUpdatedEvent(dispatchedEvent.event);
      } else if (dispatchedEvent.action === 'DELETE' && !isEmpty(dispatchedEvent.event)) {
        onDeletedEvent(dispatchedEvent.event);
      } else if (dispatchedEvent.action === 'RELOAD') {
        onSearchEvent();
      }
    }
  }, [dispatchedEvent]);

  // handle pills changed
  useEffect(() => {
    const diffPills = differenceBy(pillsRef.current, filteredPills, 'id');
    if (!isEmpty(diffPills) && triggerOnChange) {
      pillsRef.current = [...filteredPills];
      const { searchParams, filterValues } = getFilterParamsByPills({
        isDashboard: true,
        diffPills,
        accounts: accountsRef.current,
        filterValues: filterValuesRef.current
      });
      handleFilterApplied(searchParams, filterValues);
    }
  }, [filteredPills, triggerOnChange, accountsRef.current, searchParamsRef.current, filterValuesRef.current]);

  // handle school changed
  useEffect(() => {
    if (!isEqual(accountsRef.current, accounts) && triggerOnChange) {
      if (!isEmpty(filterValuesRef.current?.accounts)) {
        const accountIds = accounts.map(({ id }) => id);
        filterValuesRef.current.accounts = filterValuesRef.current.accounts!.filter(({ id }) =>
          accountIds.includes(id)
        );
      }

      const { searchParams, filterValues, needToResetForm } = getFilterParamsByAccounts({
        accounts: accountsRef.current,
        nextAccounts: accounts,
        isFilterApplied,
        filterValues: filterValuesRef.current
      });
      accountsRef.current = [...accounts];
      const nextParams = { ...searchParamsRef.current.body, ...searchParams };
      if (isEqual(searchParamsRef.current.body, nextParams)) {
        setIsFilterApplied(!needToResetForm);
      } else {
        if (!isEmpty(accounts)) {
          initialDataRef.current = false;
        }
        setPrepare(true);
        handleFilterApplied(searchParams, filterValues, needToResetForm, 1000);
      }
    }
  }, [triggerOnChange, accounts, filterValuesRef.current, initialDataRef.current, isFilterApplied]);

  // Search events on initial
  useEffect(() => {
    const accountIds = isEmpty(accounts) ? [] : accounts.map(acc => acc.id);
    let eventSearchParams = {
      accountIds,
      financialAccountIds: accountIds,
      ...searchParamsRef.current.body
    };
    if (!isEmpty(initialEventTableFilters)) {
      pillsRef.current = mappingPills(initialEventTableFilters, true);
      onAddPills(pillsRef.current);
      setIsFilterApplied(true);
      eventSearchParams = getSearchParamsByFilterValues(
        initialEventTableFilters,
        isEmpty(initialEventTableFilters.accounts) ? accounts : initialEventTableFilters.accounts
      ) as any;
    }

    accountsRef.current = [...accounts];
    searchParamsRef.current = {
      ...searchParamsRef.current,
      body: {
        ...searchParamsRef.current.body,
        ...eventSearchParams
      }
    };
    filterValuesRef.current = {
      ...filterValuesRef.current,
      ...initialEventTableFilters
    };
    setPrepare(true);
    onSearchEvent();
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  // We're using server side sorting on all but these two problematic columns that cannot sort across our pagination.
  // Conditionally disabling the feature to sort on these table headers until a permanent fix implemented.
  const disableProblematicColumnsSort = () =>
    eventResponse.totalElements && eventResponse.totalElements > searchParamsRef.current.pageSize;

  const columns = useMemo(
    () => [
      {
        index: 'name',
        name: 'Event name',
        isSortable: true,
        textEllipsis: true,
        style: { maxWidth: '15rem' },
        render: (name: string, row: any) => {
          const { content = [] } = eventResponse;
          const foundEvent = content.find((item: EventDTO) => `${item.id}` === `${row.rowId}`);
          const fountAwayAccount = awayAccounts?.find(
            (item: AccountDTO) => `${item.id}` === `${foundEvent?.opponentAccountId}`
          );
          const eventName = getEventName({
            accounts,
            event: foundEvent as EventDTO,
            awayAccount: fountAwayAccount as AccountDTO,
            shouldUseEventNameFormat
          });

          const isConcession = (foundEvent?.products ?? []).findIndex(p => p.productType === 'CONCESSION') >= 0;

          return (
            <Link
              title={eventName}
              to={
                !isConcession
                  ? PAGES.eventsV2.view.calculatePath(row.rowId)
                  : PAGES.concessions.view.calculatePath(row.rowId)
              }
              className='bx--link gs--body-short-01 gs--link-01'
            >
              {eventName}
            </Link>
          );
        }
      },
      {
        index: 'startDateTime',
        name: 'Date/Time',
        isSortable: true,
        textEllipsis: true,
        render: (startDateTime: string, row: any) => {
          const { content = [] } = eventResponse;
          const found = content.find((item: EventDTO) => `${item.id}` === `${row.rowId}`);
          return formatDateTime({
            parseZone: true,
            date: startDateTime,
            timeZone: found ? found.timeZone : undefined
          }).toDateTime(DATE_FORMAT);
        }
      },
      {
        index: 'activity.label',
        name: 'Sport',
        isSortable: true,
        textEllipsis: true,
        style: { maxWidth: '10rem' },
        render: (_: any, data: any) => {
          const { content = [] } = eventResponse;
          const found = content.find((item: EventDTO) => `${item.id}` === `${data.rowId}`);
          if (!found) return '';
          const activity = get(found, '_embedded.activity', {});
          return !isEmpty(activity) ? activity.label : '';
        }
      },
      {
        index: 'displayedOn',
        name: 'Event Format',
        textEllipsis: true,
        style: { minWidth: '8rem' },
        render: (_: any, row: any) => {
          const { content = [] } = eventResponse;
          const foundEvent = content.find((item: EventDTO) => `${item.id}` === `${row.rowId}`);
          const hasProduct = !isEmpty(foundEvent?.products);
          const streamingGames = UnityService.filterGamesByEvent(games ?? [], foundEvent as GFEventDTO);
          const hasStreamingGame = !isEmpty(streamingGames);
          const isStreamingEvent = NewEventService.isStreamingEvent(foundEvent as GFEventDTO);
          const isPlayOnSiteEvent =
            !isStreamingEvent &&
            canCheckPlayOnSiteIcon &&
            NewEventService.isPlayOnSiteEvent(foundEvent, streamingGames);
          let eventFormatText;
          if (hasProduct && isStreamingEvent) {
            eventFormatText = 'Both';
          } else if (hasProduct) {
            eventFormatText = 'In Person';
          } else if (isStreamingEvent) {
            eventFormatText = 'Streaming';
          } else if (isPlayOnSiteEvent) {
            eventFormatText = 'Playon Site';
          }

          return (
            <div className='gf--event_format'>
              {eventFormatText && <div>{eventFormatText}</div>}
              {hasProduct && <Icons.Ticket className='gs--margin-left__sp5' size={35} />}
              {(hasStreamingGame || isStreamingEvent) && <Icons.Video className='gs--margin-left__sp5' size={35} />}
              {isPlayOnSiteEvent && <Icons.Screen className='gs--margin-left__sp5' size={35} />}
            </div>
          );
        }
      },
      {
        index: 'genders',
        name: 'Gender',
        textEllipsis: true,
        render: (genders: any, row: any) => {
          const { content = [] } = eventResponse;
          const found = content.find((item: EventDTO) => `${item.id}` === `${row.rowId}`);
          return gendersToString(get(found, 'levels', []), genders || [], get(found, '_embedded.activity', {}));
        }
      },
      {
        index: 'levels',
        name: 'Level',
        textEllipsis: true,
        render: (_: any, row: any) => {
          const { content = [] } = eventResponse;
          const found = content.find((item: EventDTO) => `${item.id}` === `${row.rowId}`);
          return levelsToString(get(found, 'levels', []));
        }
      },
      {
        index: 'archived',
        name: 'Visibility',
        isSortable: true,
        render: (_: any, row: any) => {
          const { content = [] } = eventResponse;
          const found = content.find((item: EventDTO) => `${item.id}` === `${row.rowId}`);
          return (
            found && <EventVisibilityTag event={found} currentUser={currentUser} onSuccess={_onDispatchUpdatedEvent} />
          );
        }
      },
      searchParamsRef.current.body.searchOption !== 'Away' && {
        index: 'ticketGrossCount',
        name: 'Tickets sold',
        isSortable: !disableProblematicColumnsSort(),
        textEllipsis: true,
        style: { textAlign: 'center' },
        render: (value: any, row: any) => {
          const { content = [] } = eventResponse;
          const foundEvent = content.find((item: EventDTO) => `${item.id}` === `${row.rowId}`);
          const isStreamingOnlyEvent = NewEventService.isStreamingOnlyEvent(foundEvent as GFEventDTO);

          if (isStreamingOnlyEvent)
            return (
              <Link
                to={`${PAGES.eventsV2.view.calculatePath(row.rowId)}?default=tickets`}
                className='bx--link gs--body-short-01 gs--link-01'
              >
                Add tickets
              </Link>
            );

          return formatNumber(value ?? 0);
        }
      },
      searchParamsRef.current.body.searchOption !== 'Away' && {
        index: 'grossToAccount',
        name: 'Ticket sales',
        isSortable: !disableProblematicColumnsSort(),
        textEllipsis: true,
        style: { textAlign: 'center' },
        render: (value: any, row: any) => {
          const { content = [] } = eventResponse;
          const foundEvent = content.find((item: EventDTO) => `${item.id}` === `${row.rowId}`);
          const isStreamingOnlyEvent = NewEventService.isStreamingOnlyEvent(foundEvent as GFEventDTO);
          if (isStreamingOnlyEvent) return '';
          return formatCurrency(value ?? 0);
        }
      },
      {
        key: 'copyLink',
        index: 'id',
        name: '',
        render: (_: void, row: any) => {
          const { content = [] } = eventResponse;
          const found = content.find((item: EventDTO) => `${item.id}` === `${row.rowId}`);
          const eventLink = getEmbeddedEventGoFanUrl(found);
          return (
            <CopyButton
              link={eventLink}
              tooltipText='Copy event link'
              renderIcon={props => <CopyLink16 {...props} />}
            />
          );
        }
      },
      {
        key: 'overflowMenu',
        index: 'accountId',
        name: '',
        render: (_: void, row: any) => {
          const { content = [] } = eventResponse;
          const found = content.find((item: EventDTO) => `${item.id}` === `${row.rowId}`);
          const isConcession = (found?.products ?? []).findIndex(p => p.productType === 'CONCESSION') >= 0;
          const isStreamingEvent = NewEventService.isStreamingEvent(found as GFEventDTO);
          return (
            found && (
              <EventOverflowMenu
                id={`${kind}_`}
                event={found as any}
                history={history}
                accountIds={(accounts || []).map(({ id }) => id)}
                currentUser={currentUser}
                schoolsConfig={schoolsConfig}
                districtAccount={districtAccount}
                onDeletedEvent={_onDispatchDeletedEvent}
                onUpdatedEvent={_onDispatchUpdatedEvent}
                addNotification={addNotification}
                onShowEventInsight={handleShowEventInsight}
                onSearchEvent={_onDispatchReloadEvent}
                isConcession={isConcession}
                games={games}
                isStreamingEvent={isStreamingEvent}
              />
            )
          );
        }
      }
    ],
    [
      eventResponse,
      searchParamsRef.current,
      eventResponse.totalElements,
      games,
      canCheckPlayOnSiteIcon,
      searchParamsRef.current.body.searchOption
    ]
  );

  const gridConfig = useMemo(
    () => ({
      // stickyHeader: true,
      rowConfig: { id: 'id' },
      toolbar: {
        hasSearch: true,
        onSearchChanged: handleSearchChanged,
        onClickedToolbar: () => setToolbarOpen(true)
      },
      sorting: {
        sortBy: searchParamsRef.current.sortBy,
        sortRow: customSortRow
      },
      pagination: {
        currentPage: searchParamsRef.current.page,
        pageSize: searchParamsRef.current.pageSize,
        totalElements: eventResponse.totalElements
      },
      onGridChanged: handleGridChanged
    }),
    [currentUser, accounts, activities, eventResponse, searchParamsRef.current, toolbarOpen, forceKey]
  );

  useEffect(() => {
    const handleResize = () => {
      const height = document.getElementById('dashboard-event-table')?.getBoundingClientRect()?.height ?? 0;
      document.body.style.setProperty('--heightEventInsights', `${height - EVENT_INSIGHTS_OFFSET}px`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [prepare, accounts]);

  return prepare ? (
    <Row>
      <Column className='page-loading'>
        <SkeletonText />
        <SkeletonPlaceholder />
      </Column>
    </Row>
  ) : (
    <Row className={classNames('gs-table', 'event-table-component')}>
      <Column className='event-table-component--container'>
        {toolbarOpen && (
          <FilterForm
            kind={kind}
            accounts={accounts}
            activities={activities}
            levels={levels}
            venues={venues}
            isFilterApplied={isFilterApplied}
            hasVenueFilter={enableDistrictUnicorn}
            filterValues={filterValuesRef.current}
            onFilterApply={handleFilterApplied}
            onCloseForm={() => setToolbarOpen(false)}
          />
        )}
        {isInsightModalShown && (
          <InsightWrapper>
            <InsightContainer>
              <EventInsight event={eventInsight as any} onCloseForm={() => setIsInsightModalShown(false)} />
            </InsightContainer>
          </InsightWrapper>
        )}
        <div id='dashboard-event-table'>
          <BasicTable columns={columns as any} grid={gridConfig as any} data={eventResponse.content as any} />
        </div>
      </Column>
    </Row>
  );

  function _onDispatchReloadEvent() {
    setDispatchedEvent({ id: null, action: 'RELOAD', event: null });
  }

  function _onDispatchUpdatedEvent(event: GFEventDTO | EventDTO) {
    setDispatchedEvent({ id: event.id, action: 'UPDATE', event });
  }

  function _onDispatchDeletedEvent(event: GFEventDTO | EventDTO) {
    setDispatchedEvent({ id: event.id, action: 'DELETE', event });
  }
};

export default EventTable;
