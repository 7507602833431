import { useRef, useEffect, useMemo, useState } from 'react';
import { Information16 } from '@carbon/icons-react';
import { Column, Row, Tag, SkeletonText, SkeletonPlaceholder } from 'carbon-components-react';
import classNames from 'classnames';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';

import { useFeatureFlags, FEATURE_FLAGS } from '@gofan/hooks';
import { PAGES } from '@app/config/routes';
import { generateError } from '@utils/alertUtils';
import { getPartnerIconImage } from '@dashboard/utils/utils';
import { usePartnerInsight } from '@modules/event-integrations_V2/hooks';
import {
  INTEGRATION_PARTNERS,
  STATES_OF_HIGH_RISK_MARKET,
  INTERNAL_PARTNERS
} from '@modules/event-integrations_V2/constants/constants';

import type { UserDTO } from '@gofan/api/users';
import type { AccountDTO } from '@gofan/api/accounts';
import type {
  EventSchedulePartnerInsightDTO,
  PartnerInsight
} from '@modules/event-integrations_V2/models/event-schedule.model';
import type { SchoolConfig } from '@gofan/api';

import { canEdit, canEditAccountByDistrictUser } from '@gofan/api/users';

import SectionInfo from '@accounts/components/section-info/section-info.component';

import './event-monitor.component.scss';

type EventMonitorProps = {
  history: any;
  currentUser: UserDTO;
  addNotification: Function;
  accountIds: string[];
  accounts?: AccountDTO[];
  districtAccount?: AccountDTO;
  schoolsConfig?: SchoolConfig[];
};

const EventMonitor = ({
  history,
  currentUser,
  addNotification,
  accountIds = [],
  accounts = [],
  districtAccount,
  schoolsConfig
}: EventMonitorProps) => {
  const {
    [FEATURE_FLAGS.tempAwayGamesxMeg]: awayGameEnabled,
    [FEATURE_FLAGS.tempDistrictConferenceUnicorn]: enableDistrictUnicorn
  } = useFeatureFlags();
  const aiaAccountIds = accounts
    ?.filter(acc => !STATES_OF_HIGH_RISK_MARKET.includes(acc?.state) || acc?.state === 'AZ') // this was already filtered out before getting here. leaving here just in case.
    .map(account => account.id);

  const editAccountIds = useMemo(
    () =>
      uniq([...accountIds, ...aiaAccountIds]).filter(
        accountId =>
          canEdit(accountId, currentUser) ||
          (enableDistrictUnicorn &&
            canEditAccountByDistrictUser({ currentUser, accountId, districtAccount, schoolsConfig }))
      ),
    [accountIds, aiaAccountIds, enableDistrictUnicorn, currentUser, districtAccount, schoolsConfig]
  );

  const isUnmounted = useRef<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPrepared, setIsPrepared] = useState<boolean>(false);
  const [partnerInsight, setPartnerInsight] = useState<PartnerInsight>({
    insightData: [],
    salesInfoData: []
  });

  const { getPartnerInsight } = usePartnerInsight();

  useEffect(() => {
    const fetchData = async () =>
      Promise.all([
        isEmpty(accountIds)
          ? ({ insightData: [], salesInfoData: [] } as PartnerInsight)
          : getPartnerInsight({
              schoolIds: accountIds,
              partnerNames: [
                INTEGRATION_PARTNERS.arbiter,
                INTEGRATION_PARTNERS.nfhs,
                INTEGRATION_PARTNERS.vnn,
                INTEGRATION_PARTNERS.dragonfly,
                INTEGRATION_PARTNERS.activity_scheduler,
                INTEGRATION_PARTNERS.rankone
              ]
            }).catch(() => ({ insightData: [], salesInfoData: [] } as PartnerInsight)),
        isEmpty(aiaAccountIds)
          ? ({ insightData: [], salesInfoData: [] } as PartnerInsight)
          : getPartnerInsight({
              schoolIds: aiaAccountIds,
              partnerNames: [INTEGRATION_PARTNERS.aia]
            }).catch(() => ({ insightData: [], salesInfoData: [] } as PartnerInsight)),
        isEmpty(accountIds) || !awayGameEnabled
          ? ({ insightData: [], salesInfoData: [] } as PartnerInsight)
          : getPartnerInsight({
              schoolIds: accountIds,
              type: 'Away'
            }).catch(() => ({ insightData: [], salesInfoData: [] } as PartnerInsight))
      ]);

    setIsLoading(true);

    fetchData()
      .then(([partnerInsightData, aiaInsightData, hqInsightResult]: any) => {
        if (isUnmounted.current) return;
        const hqInsightData = {
          insightData: [
            {
              partnerName: INTERNAL_PARTNERS.hq,
              ...hqInsightResult?.insightData?.[0]
            }
          ],
          salesInfoData: [
            {
              partnerName: INTERNAL_PARTNERS.hq,
              ...hqInsightResult?.salesInfoData?.[0]
            }
          ]
        };
        let newInsightData = uniqBy(
          [
            ...(partnerInsightData?.insightData ?? []),
            ...(aiaInsightData?.insightData ?? []),
            ...(hqInsightData?.insightData ?? [])
          ],
          'partnerName'
        );

        if (!awayGameEnabled) {
          const filteredNewInsightData = newInsightData.filter(value => value.partnerName !== INTERNAL_PARTNERS.hq);
          newInsightData = filteredNewInsightData;
        }

        setPartnerInsight({
          insightData: newInsightData,
          salesInfoData: []
        });
      })
      .catch(error => {
        !isUnmounted.current && addNotification(generateError(error));
        console.error(error);
      })
      .finally(() => {
        if (isUnmounted.current) return;
        setIsPrepared(true);
        setIsLoading(false);
      });
  }, [accountIds]);

  const partnerInsightData = useMemo(() => {
    if (isEmpty(partnerInsight?.insightData)) return [];

    return partnerInsight.insightData.map((partner: EventSchedulePartnerInsightDTO) => {
      if (partner.partnerName === INTERNAL_PARTNERS.hq) {
        return {
          ...partner,
          totalUpcomingUpdatedEvents:
            partner.totalUpcomingUpdatedEvents +
            partner.totalUpcomingPublishedEvents +
            partner.totalUpcomingIgnoredEvents +
            partner.totalUpcomingDeclinedEvents
        };
      }
      return partner;
    });
  }, [partnerInsight?.insightData]);

  return isPrepared && _isShowIndicator() ? (
    <div>
      <Row>
        <Column className='gs--padding-top__sp5'>
          <div onClick={_handleClickEventMonitor} aria-hidden='true'>
            <SectionInfo
              title='Event monitor'
              className={classNames('event-monitor', {
                clickable: _isClickableIndicator()
              })}
              titleClassName='gs--body-short-02-semibold gs--text-01'
            >
              {isLoading ? (
                <Column className='event-monitor__column'>
                  <SkeletonText />
                  <SkeletonPlaceholder />
                </Column>
              ) : (
                <>
                  <p className='gs--body-short-01 gs--text-01 gs--padding-top__sp2'>
                    You have events ready to publish
                    <Information16 className='btn-info' />
                  </p>
                  <Column className='event-monitor__column'>
                    {partnerInsightData?.map(
                      partner =>
                        _isShowPartner(partner) && (
                          <Row className='event-monitor__partner'>
                            <img
                              src={getPartnerIconImage(partner.partnerName.toLowerCase())}
                              alt={`${partner.partnerName}-logo`}
                              title={`${partner.partnerName} logo`}
                              width='35px'
                              height='35px'
                            />
                            {partner.totalUpcomingNewEvents > 0 ? (
                              <div>
                                <Tag type='cyan' className='gs--label-01 event-monitor__tag'>
                                  New
                                </Tag>
                                <span className='number-of-event'>{partner.totalUpcomingNewEvents}</span>
                              </div>
                            ) : null}
                            {partner.totalUpcomingUpdatedEvents > 0 &&
                              partner.partnerName !== INTEGRATION_PARTNERS.aia && (
                                <div>
                                  <Tag type='teal' className='gs--label-01 event-monitor__tag'>
                                    Updates
                                  </Tag>
                                  <span className='number-of-event'>{partner.totalUpcomingUpdatedEvents}</span>
                                </div>
                              )}
                            <div className='break' />
                          </Row>
                        )
                    )}
                  </Column>
                </>
              )}
            </SectionInfo>
          </div>
        </Column>
      </Row>
    </div>
  ) : null;

  function _isShowPartner(partnerInsightData: any) {
    if (
      partnerInsightData?.partnerName === INTEGRATION_PARTNERS.aia ||
      partnerInsightData?.partnerName === INTEGRATION_PARTNERS.vnn
    ) {
      return partnerInsightData?.totalUpcomingNewEvents > 0;
    }
    if (partnerInsightData?.partnerName === INTERNAL_PARTNERS.hq) {
      return (
        partnerInsightData?.totalUpcomingNewEvents > 0 ||
        partnerInsightData?.totalUpcomingUpdatedEvents > 0 ||
        partnerInsightData?.totalUpcomingIgnoredEvents > 0 ||
        partnerInsightData?.totalUpcomingDeclinedEvents > 0
      );
    }
    return partnerInsightData?.totalUpcomingNewEvents > 0 || partnerInsightData?.totalUpcomingUpdatedEvents > 0;
  }

  function _isShowIndicator() {
    return partnerInsight?.insightData?.some(item => _isShowPartner(item));
  }

  function _isClickableIndicator() {
    if (isLoading) return false;
    if (!_isShowIndicator()) return false;

    return (
      accountIds?.some(id => editAccountIds?.includes(id)) || aiaAccountIds?.some(id => editAccountIds?.includes(id))
    );
  }

  function _handleClickEventMonitor() {
    if (!_isClickableIndicator()) return;

    const availableInsightData = (partnerInsight?.insightData ?? []).filter((partner: any) => {
      if (
        partner?.partnerName === INTEGRATION_PARTNERS.aia ||
        partner?.partnerName === INTEGRATION_PARTNERS.dragonfly
      ) {
        return partner?.totalUpcomingNewEvents > 0;
      }
      return partner?.totalUpcomingNewEvents > 0 || partner?.totalUpcomingUpdatedEvents > 0;
    });

    const availablePartners = availableInsightData?.map((partner: any) => partner.partnerName);

    const selectedAccounts = accounts?.filter(
      acc =>
        editAccountIds?.includes(acc.id) && (!STATES_OF_HIGH_RISK_MARKET.includes(acc?.state) || acc?.state === 'AZ')
    );

    history.push(PAGES.eventIntegrations.root.path, {
      arbiterFlowPath: history.location.pathname,
      partners: availablePartners,
      accounts: selectedAccounts,
      selectedAccounts
    });
  }
};

export default EventMonitor;
