import React, { useContext } from 'react';
import { FastFormContext } from './FastFormBase';
import { StyledFormButton } from '../../components/styledSemantic';

export default ({ text = 'Cancel', cancelAction = () => null }) => {
  const formContext = useContext(FastFormContext);
  return (
    <StyledFormButton
      secondary
      className='medium-size'
      onClick={() => cancelAction(formContext)}
    >
      {text}
    </StyledFormButton>
  );
};
