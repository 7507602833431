import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { EventService } from './event.service';
import { EVENTS_QUERY_KEY } from './event.endpoints';

import type { EventDTO } from './event.model';

export interface UseEventOptions {
  eventId: string;
  queryParams?: any;
  expand?: string[];
  queryOptions?: {
    cacheTime?: number;
    staleTime?: number;
    enabled?: boolean;
    refetchOnWindowFocus?: boolean;
    initialData?: () => EventDTO;
  };
}

export const useEvent = ({ eventId, queryParams = {}, expand = [], queryOptions }: UseEventOptions) => {
  const queryClient = useQueryClient();

  const query = useQuery<EventDTO>({
    queryKey: [EVENTS_QUERY_KEY, eventId],
    queryFn: () => EventService.getExpandEventById(eventId, queryParams, expand),
    ...queryOptions
  });

  const partialUpdateEvent = useMutation({
    mutationFn: ({
      event,
      originEvent,
      options
    }: {
      event: Partial<EventDTO>;
      originEvent: EventDTO;
      options?: { checkDupes: boolean };
    }) => EventService.partialUpdateEvent(EventService.mappingPatchEventValues(event, originEvent), options),
    onSuccess: updatedEvent => {
      queryClient.invalidateQueries({ queryKey: [EVENTS_QUERY_KEY, `${updatedEvent.id}`] });
    }
  });

  const createEvent = useMutation({
    mutationFn: (event: Partial<EventDTO>) =>
      EventService.createEvent(EventService.mappingCreateEventValues(event) as EventDTO),
    onSuccess: createdEvent => {
      queryClient.invalidateQueries({ queryKey: [EVENTS_QUERY_KEY, `${createdEvent.id}`] });
    }
  });

  const invalidateEvent = (id: number) => {
    queryClient.invalidateQueries({ queryKey: [EVENTS_QUERY_KEY, `${id}`] });
  };

  return { ...query, partialUpdateEvent, createEvent, invalidateEvent };
};
