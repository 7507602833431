// @flow
import { AccountListState } from './types';
import {
  AccountListActionTypes,
  RESET_STATE,
  SEARCH_ACCOUNTS,
  SEARCH_ACCOUNTS_SUCCESS
} from './actionTypes';

export const initialState: AccountListState = {
  pageIndex: 0,
  pageCount: 1,
  accounts: [],
  isSearched: false,
  isSearching: false
};

export default (state = initialState, action: AccountListActionTypes) => {
  switch (action.type) {
    case RESET_STATE: {
      return { ...initialState };
    }

    case SEARCH_ACCOUNTS: {
      return {
        ...state,
        isSearching: true
      };
    }

    case SEARCH_ACCOUNTS_SUCCESS: {
      const { searchResult } = action.payload;
      const { pageIndex, pageCount, data } = searchResult;
      return {
        ...state,
        pageIndex,
        pageCount,
        accounts: data,
        isSearched: true,
        isSearching: false
      };
    }

    default:
      return state;
  }
};
