import { useQuery } from '@tanstack/react-query';
import { RATES_QUERY_KEY } from './rate.endpoints';
import { RateService } from './rate.service';

import type { AccountRateSearchParams, RatesPageResponseDTO } from './rate.model';

export interface UseRatesOptions {
  searchParams: AccountRateSearchParams;
  queryOptions?: {
    cacheTime?: number;
    staleTime?: number;
    keepPreviousData?: boolean;
    refetchOnWindowFocus?: boolean;
    enabled?: boolean;
    select?: (data: RatesPageResponseDTO) => RatesPageResponseDTO;
  };
}

export const useRates = ({ searchParams, queryOptions = {} }: UseRatesOptions) => {
  const query = useQuery({
    queryKey: [
      RATES_QUERY_KEY,
      searchParams.keyword,
      searchParams.pageIndex,
      searchParams.pageSize,
      searchParams.sortBy[0],
      searchParams.sortBy[1]
    ],
    queryFn: () => RateService.fetchRates({ ...searchParams, status: ['ENABLED'] }),
    ...queryOptions
  });
  return query;
};
