import { ExpandRequest, PageRequest, SortingRequest } from '../shared/model/request';
import { RateRepository } from './rate.repository';
import type { RateDTO, AccountRateSearchParams } from './rate.model';

class RateService {
  static fetchRates = ({
    pageSize,
    pageIndex,
    sortBy,
    keyword,
    expands,
    productTypeIds,
    exceptions,
    cashless,
    status
  }: AccountRateSearchParams) => {
    const pageQuery = new PageRequest({
      pageSize,
      pageIndex
    }).toQueryString();
    const sortQuery = new SortingRequest({
      sortBy
    }).toQueryString();

    const expandQuery = new ExpandRequest(expands).toQueryString();

    const queryStr = `?productTypeIds=${productTypeIds}&exceptions=${exceptions}&cashless=${cashless}&${pageQuery}&${sortQuery}&${expandQuery}&name=${keyword.trim()}&status=${status?.join()}`;
    return RateRepository.fetchRates(queryStr);
  };

  static updateRateById = (rateId: string, rate: Partial<RateDTO>) => RateRepository.updateRateById(rateId, rate);

  static getRateById = (rateId: number, expands?: string[]): Promise<RateDTO> => {
    const expandQuery = new ExpandRequest(expands).toQueryString();
    const queryStr = expandQuery ? `?${expandQuery}` : '';
    return RateRepository.getRateById(rateId, queryStr);
  };

  static createRate = (body: Partial<RateDTO>) => RateRepository.createRate(body);
}

export { RateService };
