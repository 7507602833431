import { uniq, isEmpty, unionBy } from 'lodash';
import type { EventIntegrationsState } from '@modules/event-integrations_V2/middleware/types';
import {
  ON_REFRESH_PARTNER_EVENT,
  ON_REFRESH_PARTNER_EVENT_COMPLETED,
  ON_REFRESH_PARTNER_SEASON,
  ON_REFRESH_PARTNER_SEASON_COMPLETED,
  ON_SET_EVENT_ERROR,
  ON_SET_PREV_PARTNER_EVENT,

  ON_IGNORE_PARTNER_EVENT,
  ON_IGNORE_PARTNER_EVENT_COMPLETED,
  ON_CANCELLED_TASK_IGNORE_PARTNER_EVENT,
  ON_RESTORE_PARTNER_EVENT,
  ON_RESTORE_PARTNER_EVENT_COMPLETED,
  ON_CANCELLED_TASK_RESTORE_PARTNER_EVENT,
  ON_DECLINE_PARTNER_EVENT,
  ON_DECLINE_PARTNER_EVENT_COMPLETED,
  ON_CANCELLED_TASK_DECLINE_PARTNER_EVENT,
  ON_PUBLISH_ALL_EVENTS,
  ON_PUBLISH_ALL_EVENTS_COMPLETED,
  ON_PUBLISH_PARTNER_EVENT,
  ON_PUBLISH_PARTNER_EVENT_COMPLETED,
  ON_CANCELLED_TASK_PUBLISH_PARTNER_EVENT,

  ON_IGNORE_PARTNER_SEASON,
  ON_IGNORE_PARTNER_SEASON_COMPLETED,
  ON_CANCELLED_TASK_IGNORE_PARTNER_SEASON,
  ON_RESTORE_PARTNER_SEASON,
  ON_RESTORE_PARTNER_SEASON_COMPLETED,
  ON_CANCELLED_TASK_RESTORE_PARTNER_SEASON,
  ON_PUBLISH_ALL_SEASONS,
  ON_PUBLISH_ALL_SEASONS_COMPLETED,
  ON_PUBLISH_PARTNER_SEASON,
  ON_PUBLISH_PARTNER_SEASON_COMPLETED,
  ON_CANCELLED_TASK_PUBLISH_PARTNER_SEASON,
} from '@modules/event-integrations_V2/middleware/actionTypes';

export const mergeIgnoredGlobalSeasons = ({
  scheduleId,
  eventIds,
  ignoredGlobalSeasons
}: any) => {
  let newIgnoredGlobalSeasons: any[] = [];

  const newEventIds = (eventIds ?? [])?.filter((id: any) => !!id) ?? [];

  if (isEmpty(ignoredGlobalSeasons)) {
    newIgnoredGlobalSeasons = [{
      scheduleId,
      eventIds,
    }];
  } else {
    (ignoredGlobalSeasons ?? [])?.some((item: any) => {
      if (item?.scheduleId !== scheduleId) {
        newIgnoredGlobalSeasons.push(item);
        return false;
      }

      newIgnoredGlobalSeasons.push({
        ...item,
        eventIds: uniq([...newEventIds, ...(item?.eventIds ?? [])])
      });
      return false;
    });
  }

  return newIgnoredGlobalSeasons;
}

export const unionIgnoredGlobalSeasons = ({
  scheduleId,
  eventIds,
  ignoredGlobalSeasons
}: any) => {
  let newIgnoredGlobalSeasons: any[] = [];

  const newEventIds = (eventIds ?? [])?.filter((id: any) => !!id) ?? [];

  if (!isEmpty(ignoredGlobalSeasons)) {
    (ignoredGlobalSeasons ?? [])?.some((item: any) => {
      if (item?.scheduleId !== scheduleId) {
        newIgnoredGlobalSeasons.push(item);
        return false;
      }

      const finalEventIds = (item?.eventIds ?? [])?.filter((id: any) => !!id && !newEventIds?.includes(id));

      if (!isEmpty(finalEventIds)) {
        newIgnoredGlobalSeasons.push({
          ...item,
          eventIds: finalEventIds
        });
      }

      return false;
    });
  }

  return newIgnoredGlobalSeasons;
};

export const initialState: EventIntegrationsState = {
  isPublishingAllEvent: false,
  isPublishingAllSchedule: false,
  publishingScheduleEventIds: [],

  prevGlobalEvents: [],
  refreshingEvents: [],
  ignoredGlobalEvents: [],
  ignoredGlobalSeasons: [],

  changedEventId: undefined,
  errorEventIds: [],
  ignoringEventIds: [],
  restoringEventIds: [],
  publishingEventIds: [],

  ignoringScheduleIds: [],
  restoringScheduleIds: [],
  publishingScheduleIds: [],
  refreshingScheduleIds: []
};

// eslint-disable-next-line default-param-last
const eventIntegrations = (state = initialState, action: any) => {
  switch (action.type) {
    case ON_REFRESH_PARTNER_EVENT: {
      const {
        eventId,
        scheduleId,
        newGoFanEvents,
        newGlobalEvents,
      } = action.payload;

      if (isEmpty(newGlobalEvents)) return { ...state };

      const refreshingEvents = (newGlobalEvents ?? []).map((item: any) => ({
        eventId,
        scheduleId,
        newGoFanEvents,
        globalEvent: item
      }));

      return {
        ...state,
        refreshingEvents: unionBy(
          [...refreshingEvents, ...state.refreshingEvents],
          item => `${scheduleId}-${item?.eventId}`
        ),
      };
    }

    case ON_REFRESH_PARTNER_EVENT_COMPLETED: {
      const { refreshEvents } = action.payload;

      if (isEmpty(refreshEvents)) return { ...state };
      return {
        ...state,
        refreshingEvents: (state.refreshingEvents ?? []).filter((item: any) => {
          const found = (refreshEvents ?? []).find((event: any) =>
            event?.scheduleId === item?.scheduleId &&  
            event?.eventId === item?.eventId  
          );
          return isEmpty(found);
        }),
      };
    }

    case ON_REFRESH_PARTNER_SEASON: {
      const { scheduleIds } = action.payload;

      if (isEmpty(scheduleIds)) return { ...state };

      const refreshIds = uniq(scheduleIds);

      const refreshingScheduleIds = isEmpty(state.refreshingScheduleIds)
        ? refreshIds
        : uniq([...state.refreshingScheduleIds, ...refreshIds]);
  
      return {
        ...state,
        refreshingScheduleIds
      };
    }

    case ON_REFRESH_PARTNER_SEASON_COMPLETED: {
      const { refreshIds } = action.payload;

      if (isEmpty(refreshIds)) return { ...state };

      return {
        ...state,
        refreshingScheduleIds: (state.refreshingScheduleIds ?? []).filter(
          id => !(refreshIds ?? []).includes(`${id}`)
        ),
      };
    }

    case ON_SET_EVENT_ERROR: {
      const { errorEventIds, isClear } = action.payload;

      let newErrorEventIds = [];

      if (isClear) {
        newErrorEventIds = (state.errorEventIds ?? []).filter(
          id => !(errorEventIds ?? []).includes(`${id}`)
        );
      } else {
        newErrorEventIds = isEmpty(state.errorEventIds)
          ? errorEventIds
          : uniq([...state.errorEventIds, ...errorEventIds]);
      }

      return {
        ...state,
        errorEventIds: newErrorEventIds,
      };
    }

    case ON_SET_PREV_PARTNER_EVENT: {
      const { prevGlobalEvents, isClear } = action.payload;

      let newPrevGlobalEvents = [];

      if (isClear) {
        const eventIds = (prevGlobalEvents ?? [])?.map((item: any) => item?.id);
        newPrevGlobalEvents = (state.errorEventIds ?? []).filter(
          id => !(eventIds ?? []).includes(`${id}`)
        );
      } else {
        newPrevGlobalEvents = unionBy(
          [...(prevGlobalEvents ?? []), ...(state.prevGlobalEvents ?? [])],
          'id'
        );
      }

      return {
        ...state,
        prevGlobalEvents: newPrevGlobalEvents,
      };
    }

    ////////////////////////////////////////////////

    case ON_IGNORE_PARTNER_EVENT: {
      const { eventId } = action.payload;

      const ignoringEventIds = isEmpty(state.ignoringEventIds)
        ? [`${eventId}`]
        : [...state.ignoringEventIds, `${eventId}`];

      return {
        ...state,
        ignoringEventIds: uniq(ignoringEventIds),
      };
    }

    case ON_CANCELLED_TASK_IGNORE_PARTNER_EVENT: {
      const { eventId } = action.payload;
      return {
        ...state,
        ignoringEventIds: (state.ignoringEventIds ?? []).filter(id => `${id}` !== `${eventId}`),
      };
    }

    case ON_IGNORE_PARTNER_EVENT_COMPLETED: {
      const {
        eventId,
        scheduleId,
        prevGlobalEvents,
        ignoredGlobalEvent,
      } = action.payload;

      const newIgnoredGlobalSeasons = mergeIgnoredGlobalSeasons({
        scheduleId,
        eventIds: [eventId],
        ignoredGlobalSeasons: state.ignoredGlobalSeasons
      });

      return {
        ...state,
        changedEventId: eventId,
        ignoredGlobalSeasons: newIgnoredGlobalSeasons,
        ignoringEventIds: (state.ignoringEventIds ?? []).filter(id => `${id}` !== `${eventId}`),
        prevGlobalEvents: unionBy(
          [...(prevGlobalEvents ?? []), ...(state.prevGlobalEvents ?? [])],
          'id'
        ),
        ignoredGlobalEvents: unionBy(
          [ignoredGlobalEvent, ...state.ignoredGlobalEvents],
          'id'
        ),
      };
    }

    case ON_RESTORE_PARTNER_EVENT: {
      const { eventId } = action.payload;

      const restoringEventIds = isEmpty(state.restoringEventIds)
        ? [`${eventId}`]
        : [...state.restoringEventIds, `${eventId}`];

      return {
        ...state,
        restoringEventIds: uniq(restoringEventIds),
      };
    }

    case ON_CANCELLED_TASK_RESTORE_PARTNER_EVENT: {
      const { eventId } = action.payload;
      return {
        ...state,
        restoringEventIds: (state.restoringEventIds ?? []).filter(id => `${id}` !== `${eventId}`),
      };
    }

    case ON_RESTORE_PARTNER_EVENT_COMPLETED: {
      const {
        eventId,
        scheduleId,
      } = action.payload;

      const newIgnoredGlobalSeasons = unionIgnoredGlobalSeasons({
        scheduleId,
        eventIds: [eventId],
        ignoredGlobalSeasons: state.ignoredGlobalSeasons
      });

      return {
        ...state,
        ignoredGlobalSeasons: newIgnoredGlobalSeasons,
        restoringEventIds: (state.restoringEventIds ?? []).filter(id => `${id}` !== `${eventId}`),
        prevGlobalEvents: (state.prevGlobalEvents ?? []).filter(({ id }) => `${id}` !== `${eventId}`),
        ignoredGlobalEvents: (state.ignoredGlobalEvents ?? []).filter(({ id }) => `${id}` !== `${eventId}`)
      };
    }

    case ON_DECLINE_PARTNER_EVENT: {
      return {
        ...state,
        changedEventId: undefined
      };
    }

    case ON_DECLINE_PARTNER_EVENT_COMPLETED:
    case ON_CANCELLED_TASK_DECLINE_PARTNER_EVENT: {
      const { eventId } = action.payload;
      return {
        ...state,
        changedEventId: eventId
      };
    }

    case ON_PUBLISH_ALL_EVENTS: {
      const { isPublishAll, scheduleId } = action.payload;

      const publishingScheduleEventIds = isEmpty(state.publishingScheduleEventIds)
        ? [`${scheduleId}`]
        : [...state.publishingScheduleEventIds, `${scheduleId}`];

      return {
        ...state,
        publishingScheduleEventIds,
        isPublishingAllEvent: isPublishAll
      };
    }

    case ON_PUBLISH_ALL_EVENTS_COMPLETED: {
      const { scheduleId } = action.payload;

      return {
        ...state,
        isPublishingAllEvent: false,
        publishingScheduleEventIds: (state.publishingScheduleEventIds ?? []).filter(
          id => `${id}` !== `${scheduleId}`
        ),
      };
    }

    case ON_PUBLISH_PARTNER_EVENT: {
      const { eventId } = action.payload;

      const publishingEventIds = isEmpty(state.publishingEventIds)
        ? [`${eventId}`]
        : [...state.publishingEventIds, `${eventId}`];

      return {
        ...state,
        changedEventId: undefined,
        publishingEventIds: uniq(publishingEventIds)
      };
    }

    case ON_PUBLISH_PARTNER_EVENT_COMPLETED:
    case ON_CANCELLED_TASK_PUBLISH_PARTNER_EVENT: {
      const { eventId } = action.payload;
      return {
        ...state,
        changedEventId: eventId,
        publishingEventIds: (state.publishingEventIds ?? []).filter(id => `${id}` !== `${eventId}`)
      };
    }

    ////////////////////////////////////////////////
    case ON_IGNORE_PARTNER_SEASON: {
      const { scheduleId } = action.payload;

      const ignoringScheduleIds = isEmpty(state.ignoringScheduleIds)
        ? [`${scheduleId}`]
        : [...state.ignoringScheduleIds, `${scheduleId}`];

      return {
        ...state,
        errorEventIds: [],
        ignoringScheduleIds: uniq(ignoringScheduleIds),
      };
    }

    case ON_CANCELLED_TASK_IGNORE_PARTNER_SEASON: {
      const { scheduleId } = action.payload;
      return {
        ...state,
        ignoringScheduleIds: (state.ignoringScheduleIds ?? []).filter(id => `${id}` !== `${scheduleId}`),
      };
    }

    case ON_IGNORE_PARTNER_SEASON_COMPLETED: {
      const {
        scheduleId,
        eventIds,
      } = action.payload;

      const newIgnoredGlobalSeasons = mergeIgnoredGlobalSeasons({
        scheduleId,
        eventIds,
        ignoredGlobalSeasons: state.ignoredGlobalSeasons
      });

      return {
        ...state,
        ignoredGlobalSeasons: newIgnoredGlobalSeasons,
        ignoringScheduleIds: (state.ignoringScheduleIds ?? []).filter(id => `${id}` !== `${scheduleId}`),
      };
    }

    case ON_RESTORE_PARTNER_SEASON: {
      const { scheduleId } = action.payload;

      const restoringScheduleIds = isEmpty(state.restoringScheduleIds)
        ? [`${scheduleId}`]
        : [...state.restoringScheduleIds, `${scheduleId}`];

      return {
        ...state,
        errorEventIds: [],
        restoringScheduleIds: uniq(restoringScheduleIds),
      };
    }

    case ON_CANCELLED_TASK_RESTORE_PARTNER_SEASON: {
      const { scheduleId } = action.payload;
      return {
        ...state,
        restoringScheduleIds: (state.restoringScheduleIds ?? []).filter(id => `${id}` !== `${scheduleId}`),
      };
    }

    case ON_RESTORE_PARTNER_SEASON_COMPLETED: {
      const {
        scheduleId,
        eventIds,
      } = action.payload;

      const newIgnoredGlobalSeasons = unionIgnoredGlobalSeasons({
        scheduleId,
        eventIds,
        ignoredGlobalSeasons: state.ignoredGlobalSeasons
      });

      return {
        ...state,
        ignoredGlobalSeasons: newIgnoredGlobalSeasons,
        restoringScheduleIds: (state.restoringScheduleIds ?? []).filter(id => `${id}` !== `${scheduleId}`),
      };
    }

    case ON_PUBLISH_ALL_SEASONS: {
      const { isPublishAll } = action.payload;
      return {
        ...state,
        errorEventIds: [],
        isPublishingAllSchedule: isPublishAll
      };
    }

    case ON_PUBLISH_ALL_SEASONS_COMPLETED: {
      return {
        ...state,
        isPublishingAllSchedule: false
      };
    }

    case ON_PUBLISH_PARTNER_SEASON: {
      const { scheduleId } = action.payload;
      const publishingScheduleIds = isEmpty(state.publishingScheduleIds)
        ? [`${scheduleId}`]
        : [...state.publishingScheduleIds, `${scheduleId}`];

      return {
        ...state,
        errorEventIds: [],
        publishingScheduleIds: uniq(publishingScheduleIds)
      };
    }

    case ON_PUBLISH_PARTNER_SEASON_COMPLETED:
    case ON_CANCELLED_TASK_PUBLISH_PARTNER_SEASON: {
      const { scheduleId } = action.payload;
      return {
        ...state,
        publishingScheduleIds: state.publishingScheduleIds?.filter(id => `${id}` !== `${scheduleId}`)
      };
    }

    default:
      return { ...state };
  }
};

export default eventIntegrations;
