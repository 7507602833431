import moment from 'moment';
import dayjs from 'dayjs';
import { get, isEmpty, omit, pick, uniq, uniqBy } from 'lodash';

import { getUniqueId } from '@utils/objectUtils';
import { switchZone, formatDateTime, EVENT_DATE_FORMAT_WITH_TIMEZONE } from '@app/utils/dateUtils';
import type RowEvent from '@api/model/RowEvent';

import type { LevelDTO } from '@gofan/api/levels';
import type { GlobalEventDTO, SeasonDTO, SeasonProduct } from '@seasons/models/season.model';
import type { AccountDTO } from '@gofan/api/accounts';
import type { ActivityDTO } from '@gofan/api/activities';
// import { EventSalesInfoDTO } from '@events/models/event.model';
import type { AccountProductDTO } from '@gofan/api/account-products';
import type { EventScheduleSeasonDTO } from '@modules/event-integrations_V2/models/event-schedule.model';

import SeasonCreationUtil from '@season-management/utils/season-creation.utils';
import {
  GENDER_TYPE,
  CREATION_MODE,
  IMPORT_DATA_MODE,
  DEFAULT_START_TIME,
  EVENT_START_TIME_TYPE
} from '@season-management/constants/constant';
import { DATE_FORMAT_DEFAULT_WITH_TIMEZONE, DATE_PICKER_FORMAT_DEFAULT, TIME_FORMAT_DEFAULT } from '@gofan/constants';
import type {
  TimeWithPeriod,
  StartTimeTeams,
  LevelsByGender,
  GroupLevelGender
} from '@season-management/middleware/models/model';
import type { RawProduct } from '@season-management/middleware/models/raw-product.model';
import RawProductModel from '@season-management/middleware/models/raw-product.model';
import type { RawEvent } from '@season-management/middleware/models/raw-event.model';
import RawEventModel from '@season-management/middleware/models/raw-event.model';
import type { RawSeason } from '@season-management/middleware/models/raw-season.model';
import RawSeasonModel from '@season-management/middleware/models/raw-season.model';
import type { VenueInformationValue } from '@season-management/pages/season-general-setup/season-venue-section/season-venue-section.component';
import type { SeasonalPeriodType } from '@gofan/api';

export interface ManualSetting {
  numOfEvent?: number;
  startTime?: StartTimeTeams;
  allTeamsIsBroadcast?: boolean;
  allTeamsBroadcastStartTime?: {
    time: string;
    period: string;
  };
  allTeamsBroadcastEndTime?: {
    time: string;
    period: string;
  };
  eventIntegrationDetails?: any[]; // altered UI version of EventIntegrationDetails[]
  selectedLevels?: any[];
}

export interface FileUploadSetting {
  fileName: string;
  genders?: string[];
  creationMode?: string;
  levelsByGender?: LevelsByGender;
  uploadedEvents?: RowEvent[];
}

export interface FromArbiterSetting {
  opponentSchools?: AccountDTO[];
  arbiterEvents?: GlobalEventDTO[];
}

export interface FromPartnerSetting {
  partnerName: string;
  partnerEvents?: GlobalEventDTO[];
  schedules?: EventScheduleSeasonDTO[];
}

export interface CreatingSeason {
  id: number | string; // sportId
  order?: number;

  sport: ActivityDTO;
  accountId: string;

  created?: boolean;
  creationProcess: string;
  // publishingStatus: string; // [PUBLISHED, UN_PUBLISHED]

  // Data for General setup
  name: string;
  creationMode: string; // [1PerSport, SeperateEachTeam]
  genders: string[]; // [Boys, Girls, Coed]
  glCode?: string;
  levelsByGender: LevelsByGender;
  genderSpecific: boolean;
  accountTicketTypes: AccountProductDTO[];
  seasonTickets?: SeasonProduct[];
  archived?: boolean;
  publishDate?: string;
  publishTime?: string;
  eventsArchived?: boolean;
  alert?: string;
  eventStartTime?: string;
  venue: VenueInformationValue;
  modeImportData: string; // [FileUpload, Manunal, FromArbiter, CopyFromSeason]
  eventStartTimeType: string;
  seasonStartTimeSettings?: string[];
  manualSetting?: ManualSetting;
  fileUploadSetting?: FileUploadSetting;
  fromArbiterSetting?: FromArbiterSetting;
  fromPartnerSetting?: FromPartnerSetting;
  seasonalPeriod?: SeasonalPeriodType;

  // Data for Event setup or Data for View step - after published
  // using modeImportData + importDataSetting to generate
  rawSeasons: RawSeason[];
}

export default class CreatingSeasonModel {
  contents: CreatingSeason;

  defaultValues = {
    id: getUniqueId(),
    name: '',
    created: false,
    genders: []
  };

  constructor(contents: any) {
    this.contents = { ...contents } as CreatingSeason;
  }

  toJSON() {
    return { ...this.defaultValues, ...this.contents };
  }

  setBySeasons(seasons: SeasonDTO[]) {
    if (!isEmpty(seasons)) {
      const { creationMode } = this.contents;

      const rawSeasons: RawSeason[] = seasons.map(season => {
        const {
          _embedded,
          id: seasonId,
          name,
          accountId,
          activityId: sportId,
          levels: seasonLevels,
          genders: seasonGenders,
          events: seasonEvents = [],
          archived: seasonArchived,
          alert: seasonAlert,
          products
        } = season ?? {};
        const levels = (get(_embedded, 'levels', []) ?? []) as LevelDTO[];
        const account = (get(_embedded, 'account', {}) ?? {}) as AccountDTO;
        const activity = (get(_embedded, 'activity', {}) ?? {}) as ActivityDTO;
        const seasonSettings = season?.config?.seasonSettings ?? '';
        const seasonConfig = isEmpty(seasonSettings) ? {} : JSON.parse(seasonSettings);
        let rawSeasonId = '';
        let startTime: TimeWithPeriod = DEFAULT_START_TIME;
        let genders: string[] = [];
        let levelsByGender: LevelsByGender = {};
        let gender = '';
        let levelId: string | number = '';

        if (isEmpty(seasonLevels)) {
          const genderTypes = isEmpty(seasonGenders) ? [GENDER_TYPE.COED] : [...(seasonGenders ?? [])];
          genderTypes.forEach(seasonGender => {
            genders.push(`${seasonGender}`.toUpperCase());
          });
          levelsByGender = { [GENDER_TYPE.COED]: [] };
        } else {
          seasonLevels.forEach((groupLevelGender: GroupLevelGender) => {
            const genderTypes = isEmpty(groupLevelGender?.genders)
              ? [GENDER_TYPE.COED]
              : [...(groupLevelGender?.genders ?? [])];

            genderTypes.forEach(levelGender => {
              const genderType = `${levelGender}`.toUpperCase();
              const foundLevel = levels.find(level => `${level.id}` === `${groupLevelGender.levelId}`);
              const level: LevelDTO = isEmpty(foundLevel)
                ? {
                    id: groupLevelGender.levelId,
                    name: '',
                    disabled: false
                  }
                : (foundLevel as LevelDTO);

              genders.push(genderType);
              levelsByGender = {
                ...levelsByGender,
                [genderType]: uniqBy([...(levelsByGender?.[genderType] ?? []), level], 'id')
              };
            });
          });
        }

        genders = uniq(genders);

        const seasonStartTimeType = seasonConfig?.startTimeType ?? EVENT_START_TIME_TYPE.ALL_TEAMS;
        const seasonStartTimeTeams: StartTimeTeams = SeasonCreationUtil.convertStartTimeOptionsToTeams({
          sportId,
          genders,
          levelsByGender,
          startTimeType: seasonStartTimeType,
          startTimeOptions: seasonConfig?.startTimeOptions
        });

        if (creationMode === CREATION_MODE.ONE_PER_SPORT) {
          rawSeasonId = SeasonCreationUtil.getSeasonId({ sportId, creationMode });
        } else if (creationMode === CREATION_MODE.SPERATE_EACH_TEAM) {
          gender = genders?.[0];
          levelId = levelsByGender?.[gender]?.[0]?.id;
          rawSeasonId = SeasonCreationUtil.getSeasonId({ sportId, creationMode, gender, levelId });
        }

        let seasonPublishDateTime = null;
        if (!isEmpty(season.publishDateTime)) {
          const formattedPublishDateTime = formatDateTime({
            date: season.publishDateTime,
            timeZone: season.timeZone,
            parseZone: true
          });
          seasonPublishDateTime = switchZone(
            formattedPublishDateTime.toDate(EVENT_DATE_FORMAT_WITH_TIMEZONE),
            'localZone'
          );
        }

        if (seasonStartTimeType === EVENT_START_TIME_TYPE.EACH_TEAM) {
          const minStartTime = SeasonCreationUtil.getMinInManualStartTimes({
            sportId,
            startTimes: seasonStartTimeTeams,
            eventStartTimeType: seasonStartTimeType
          });
          startTime = {
            time: minStartTime?.time ?? DEFAULT_START_TIME.time,
            period: minStartTime?.period ?? DEFAULT_START_TIME.period
          };
        } else {
          startTime = {
            time: seasonConfig?.seasonStartTime?.time ?? DEFAULT_START_TIME.time,
            period: seasonConfig?.seasonStartTime?.period ?? DEFAULT_START_TIME.period
          };
        }

        const seasonProducts: RawProduct[] = (products ?? []).map((product: any) =>
          new RawProductModel({
            ...omit(product, ['_embedded', '_links']),
            created: true,
            fee: product?.hiddenFees ? product?.hiddenFeeBase : product?.fee
          }).toJSON()
        );

        const configVenue = {
          name: seasonConfig?.venueName ?? '',
          address: seasonConfig?.venueAddress ?? '',
          city: seasonConfig?.venueCity ?? '',
          state: seasonConfig?.venueState ?? '',
          zipCode: seasonConfig?.venueZip ?? '',
          location: seasonConfig?.venueLocation ?? '',
          id: seasonConfig?.venueId ?? undefined
        };

        const rawEvents: RawEvent[] = seasonEvents?.map(seasonEvent => {
          let eventStartDateTime = '';
          let eventOpponentAccount = null;
          let withoutOpponentAccount = false;
          let eventStartTime: TimeWithPeriod = DEFAULT_START_TIME;
          let dateTimeDuration;

          if (!isEmpty(seasonEvent.endDateTime) && !isEmpty(seasonEvent.startDateTime)) {
            dateTimeDuration = moment(seasonEvent.endDateTime).diff(moment(seasonEvent.startDateTime));
          }

          if (isEmpty(seasonEvent.opponentAccountId)) {
            withoutOpponentAccount = true;
          } else {
            eventOpponentAccount = (get(seasonEvent, '_embedded.account-opponent', {}) ?? {}) as AccountDTO;
          }

          if (!isEmpty(seasonEvent.startDateTime)) {
            const formattedStartDateTime = formatDateTime({
              date: seasonEvent.startDateTime,
              timeZone: seasonEvent.timeZone,
              parseZone: true
            });
            eventStartDateTime = switchZone(
              formattedStartDateTime.toDate(EVENT_DATE_FORMAT_WITH_TIMEZONE),
              'localZone'
            );
            const mEventStartDateTime = moment(eventStartDateTime);
            eventStartTime = {
              time: mEventStartDateTime.clone().format('hh:mm'),
              period: mEventStartDateTime.clone().format('A')
            };
          }

          const eventProducts: RawProduct[] = (seasonEvent?.products ?? []).map((product: any) => {
            let salesStartDate;
            let salesStartTime;
            let salesEndDate;
            let salesEndTime;
            if (product?.salesStartDateTime) {
              const mSalesStartDateTime = formatDateTime({
                date: product?.salesStartDateTime,
                timeZone: seasonEvent.timeZone,
                parseZone: true
              });
              const ticketSalesStartDateTime = switchZone(
                mSalesStartDateTime.toDate(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
                'localZone'
              );
              const mTicketSalesStartDateTime = dayjs(ticketSalesStartDateTime);
              salesStartTime = mTicketSalesStartDateTime.clone().format(TIME_FORMAT_DEFAULT);
              salesStartDate = mTicketSalesStartDateTime.clone().format(DATE_PICKER_FORMAT_DEFAULT);
            }
            if (product?.salesEndDateTime) {
              const mSalesEndDateTime = formatDateTime({
                date: product?.salesEndDateTime,
                timeZone: seasonEvent.timeZone,
                parseZone: true
              });
              const ticketSalesEndDateTime = switchZone(
                mSalesEndDateTime.toDate(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
                'localZone'
              );
              const mTicketSalesEndDateTime = dayjs(ticketSalesEndDateTime);
              salesEndTime = mTicketSalesEndDateTime.clone().format(TIME_FORMAT_DEFAULT);
              salesEndDate = mTicketSalesEndDateTime.clone().format(DATE_PICKER_FORMAT_DEFAULT);
            }
            return new RawProductModel({
              ...omit(product, ['_embedded', '_links', 'salesEndDateTime', 'salesStartDateTime']),
              created: true,
              fee: product?.hiddenFees ? product?.hiddenFeeBase : product?.fee,
              salesStartDate,
              salesStartTime,
              salesEndTime,
              salesEndDate
            }).toJSON();
          });

          const eventStartTimeType = seasonEvent?.startTimeType ?? EVENT_START_TIME_TYPE.ALL_TEAMS;
          const eventStartTimeTeams: StartTimeTeams = SeasonCreationUtil.convertStartTimeOptionsToTeams({
            sportId,
            genders,
            levelsByGender,
            startTimeType: eventStartTimeType,
            startTimeOptions: seasonEvent?.startTimeOptions
          });

          return new RawEventModel({
            ...omit(seasonEvent, ['_embedded', '_links', 'products']),
            account: omit(account, ['_embedded', '_links']),
            activity: omit(activity, ['_embedded', '_links']),
            created: true,
            withoutOpponentAccount,
            startTime: eventStartTime,
            eventStartTimeType,
            startTimeTeams: eventStartTimeTeams,
            startDateTime: eventStartDateTime,
            opponentAccount: eventOpponentAccount,
            products: eventProducts,
            dateTimeDuration
          })
            .addDefaultStartTime(eventStartTime)
            .toJSON();
        });

        return new RawSeasonModel({
          created: true,
          id: rawSeasonId,
          seasonId,
          name,
          accountId,
          sportId,
          gender,
          levelId,
          genders,
          startTime,
          eventStartTimeType: seasonStartTimeType,
          startTimeTeams: seasonStartTimeTeams,
          levelsByGender,
          venue: configVenue,
          archived: seasonArchived,
          publishDateTime: seasonPublishDateTime,
          alert: seasonAlert,
          products: seasonProducts,
          rawEvents
        }).toJSON();
      });

      this.contents = {
        ...this.contents,
        manualSetting: undefined,
        fileUploadSetting: undefined,
        fromArbiterSetting: undefined,
        created: true,
        rawSeasons
      };
    }
    return this;
  }

  simplify() {
    const {
      id: sportId,
      sport,
      modeImportData,
      manualSetting,
      fileUploadSetting,
      eventStartTimeType,
      genders,
      levelsByGender
    } = this.contents;
    let simplifiedManualSetting;
    let simplifiedFileUploadSetting;
    let simplifiedLevelsByGender: LevelsByGender = {};

    genders.forEach((gender: string) => {
      simplifiedLevelsByGender = {
        ...simplifiedLevelsByGender,
        [gender]: [...(levelsByGender?.[gender] ?? [])]
      };
    });

    if (modeImportData === IMPORT_DATA_MODE.MANUAL) {
      let startTime = {};
      let broadcastStartTime = {};
      Object.keys(manualSetting?.startTime ?? {}).forEach(startTimeId => {
        if (
          SeasonCreationUtil.matchStartTimeId({
            eventStartTimeType,
            startTimeId,
            sportId,
            genders,
            levelsByGender: simplifiedLevelsByGender
          })
        ) {
          startTime = {
            ...startTime,
            [startTimeId]: manualSetting?.startTime?.[startTimeId] ?? DEFAULT_START_TIME
          };
        }
      });

      Object.keys(manualSetting?.broadcastStartTime ?? {}).forEach(startTimeId => {
        if (
          SeasonCreationUtil.matchStartTimeId({
            eventStartTimeType,
            startTimeId,
            sportId,
            genders,
            levelsByGender: simplifiedLevelsByGender
          })
        ) {
          broadcastStartTime = {
            ...broadcastStartTime,
            [startTimeId]: manualSetting?.broadcastStartTime?.[startTimeId] ?? DEFAULT_START_TIME
          };
        }
      });

      if (!isEmpty(startTime)) {
        simplifiedManualSetting = { startTime };
      }
      if (!isEmpty(broadcastStartTime)) {
        simplifiedManualSetting = { ...simplifiedManualSetting, broadcastStartTime };
      }
    } else if (modeImportData === IMPORT_DATA_MODE.MANUAL) {
      simplifiedFileUploadSetting = {
        fileName: fileUploadSetting?.fileName ?? ''
      };
    }

    this.contents = {
      ...this.contents,
      sport: pick(sport, ['id', 'disabled', 'label', 'athletic', 'genderSpecific', 'gender']),
      manualSetting: simplifiedManualSetting,
      fileUploadSetting: simplifiedFileUploadSetting,
      levelsByGender: { ...simplifiedLevelsByGender }
    };

    return this;
  }
}
