// -------------------------------------------------------------//
// Roles
// -------------------------------------------------------------//
export const USER_ROLE_SUPER_ADMIN = 'SUPER_ADMIN';
export const USER_ROLE_SYSTEM_ADMIN = 'SYSTEM_ADMIN';
export const USER_ROLE_SYSTEM_USER = 'SYSTEM_USER';
export const USER_ROLE_SYSTEM_FINANCE_ADMIN = 'SYSTEM_FINANCE_ADMIN';
export const USER_ROLE_LIMITED_SYSTEM_USER = 'LIMITED_SYSTEM_USER';
export const USER_ROLE_ACCOUNT_USER = 'ACCOUNT_USER';
export const USER_ROLE_FAN = 'FAN';
export const USER_ROLE_NONE = 'NONE';

/* Order of permissions:
Super Administrator
System Finance Admin
System Admin
System User
Limited System User
 */
export const USER_ROLES = [
  USER_ROLE_SUPER_ADMIN,
  USER_ROLE_SYSTEM_FINANCE_ADMIN,
  USER_ROLE_SYSTEM_ADMIN,
  USER_ROLE_SYSTEM_USER,
  USER_ROLE_LIMITED_SYSTEM_USER,
  USER_ROLE_ACCOUNT_USER,
  USER_ROLE_FAN,
  USER_ROLE_NONE
];

export const INTERNAL_USER_ROLES = [
  USER_ROLE_LIMITED_SYSTEM_USER,
  USER_ROLE_SYSTEM_USER,
  USER_ROLE_SYSTEM_ADMIN,
  USER_ROLE_SYSTEM_FINANCE_ADMIN,
  USER_ROLE_SUPER_ADMIN
];

export const USER_ROLES_AS_OPTIONS = USER_ROLES.map(role => {
  const capfirst = w => w.charAt(0).toUpperCase() + w.slice(1);
  const roleText = role
    .toLowerCase()
    .split('_')
    .map(x => capfirst(x))
    .join(' ');

  return {
    key: role,
    value: role,
    text: roleText
  };
});

// -------------------------------------------------------------//
// States
// -------------------------------------------------------------//
export const USER_STATE_ENABLED = 'ENABLED';
export const USER_STATE_DISABLED = 'DISABLED';
export const USER_STATE_DELETED = 'DELETED';
export const USER_STATE_PENDING = 'PENDING';
export const ACCOUNT_STATUS_ACTIVE = 'Active';

export const USER_STATES = [USER_STATE_ENABLED, USER_STATE_DISABLED, USER_STATE_DELETED, USER_STATE_PENDING];

// -------------------------------------------------------------//
// Phone Number Types
// -------------------------------------------------------------//
export const PHONE_TYPES = [
  { key: 'Mobile', value: 'Mobile', text: 'Mobile' },
  { key: 'Office', value: 'Office', text: 'Office' }
];

// -------------------------------------------------------------//
// Empty User Template
// -------------------------------------------------------------//
export const USER_TEMPLATE = () => ({
  email: '',
  firstName: '',
  lastName: '',
  title: null,
  zipCode: null,
  dateOfBirth: null,
  role: USER_ROLE_FAN,
  status: USER_STATE_PENDING,
  optOutPiiSharing: null,
  acceptedTerms: null,
  optOutCommercialCorrespondence: null
});

// JR asks: what does this do? the account contacts table view uses this before submitting.
export const USER_ROLE_ADMIN = 'ADMIN';
export const USER_ROLE_BOXOFFICE = 'BOXOFFICE';
export const USER_ROLE_FINANCE = 'FINANCE';
export const USER_ROLE_REPORTS = 'REPORTS';
export const USER_ROLE_VALIDATOR = 'VALIDATOR';
export const USER_ROLE_INSIDER = 'INSIDER';
export const USER_ROLE_EDITOR = 'EDITOR';
export const USER_ROLE_COACH = 'COACH';
export const USER_ROLE_COMMISSIONER_EDITOR = 'COMMISSIONER_EDITOR';
export const USER_ROLE_COMMISSIONER_VIEWER = 'COMMISSIONER_VIEWER';

export const USER_ACCOUNT_PERMISSIONS = [
  USER_ROLE_ADMIN,
  USER_ROLE_FINANCE,
  USER_ROLE_INSIDER,
  USER_ROLE_REPORTS,
  USER_ROLE_BOXOFFICE,
  USER_ROLE_VALIDATOR,
  USER_ROLE_EDITOR
];

export const LOWER_LEVEL_ROLES = [USER_ROLE_ADMIN, USER_ROLE_FINANCE];
