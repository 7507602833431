import { isEmpty, trim } from 'lodash';
import ErrorDTO from '../dto/ErrorDTO';
import AccountDTO from '../dto/AccountDTO';
import PaginationDTO from '../dto/PaginationDTO';
import axios from '../api/axios';
import { FETCH_ACCOUNTS, FETCH_ACCOUNT_BY_ID, FETCH_ACCOUNTS_BY_KEYWORD } from '../api/api';
import { CANCELLED_REQUEST, EXCLUDE_SCHOOL_TYPES, ACCOUNT_TYPES } from '../api/constants';

let sourceAccountsInSeason = null;
let sourceAccountsByKeyword = null;

const fetchAccountById = (accountId = '') => {
  const uri = `${FETCH_ACCOUNT_BY_ID.replace('{id}', accountId)}?ts=${new Date().getTime()}&skipCache=true`;
  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new AccountDTO(response);
    })
    .catch(e => new ErrorDTO(e));
};

const cancelFetchAccountsByKeyword = () => {
  if (sourceAccountsByKeyword) {
    sourceAccountsByKeyword.cancel(CANCELLED_REQUEST);
  }
};

const getUriFetchAccountsByKeyword = (
  keyword = '',
  coordinate = {},
  allowAccountType = false,
  notAllowElementarySchool = false
) => {
  const keywordQuery = FETCH_ACCOUNTS_BY_KEYWORD.replace('{keyword}', trim(keyword));
  const accountTypesQuery = allowAccountType ? `&accountTypes=${ACCOUNT_TYPES.map(encodeURIComponent).join(',')}` : '';
  const excludeSchoolTypesQuery = notAllowElementarySchool
    ? `&excludeSchoolTypes=${EXCLUDE_SCHOOL_TYPES.map(encodeURIComponent).join(',')}`
    : '';
  const { latitude, longitude } = coordinate;
  const coordinateQuery = !isEmpty(coordinate) ? `&latitude=${latitude}&longitude=${longitude}` : '';
  return `${keywordQuery}${accountTypesQuery}${excludeSchoolTypesQuery}${coordinateQuery}`;
};

const fetchAccountsByKeyword = (keyword = '', coordinate, notAllowElementarySchool = false) => {
  sourceAccountsByKeyword = axios.huddleAxiosInstanceProxy.CancelToken.source();
  const uri = getUriFetchAccountsByKeyword(encodeURIComponent(keyword), coordinate, false, notAllowElementarySchool);
  return axios.huddleAxiosInstanceProxy
    .get(uri, { cancelToken: sourceAccountsByKeyword.token }, true)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response.map(account => new AccountDTO(account));
    })
    .catch(e => {
      if (e.message === CANCELLED_REQUEST) {
        throw e;
      }
      return new ErrorDTO(e);
    })
    .finally(() => {
      sourceAccountsByKeyword = null;
    });
};

const cancelSearchAccountsInSeason = () => {
  if (sourceAccountsInSeason) {
    sourceAccountsInSeason.cancel(CANCELLED_REQUEST);
  }
};

const searchAccountsInSeason = (
  keyword = '',
  coordinate,
  allowAccountType = false,
  notAllowElementarySchool = false
) => {
  sourceAccountsInSeason = axios.huddleAxiosInstanceProxy.CancelToken.source();
  const uri = getUriFetchAccountsByKeyword(
    encodeURIComponent(keyword),
    coordinate,
    allowAccountType,
    notAllowElementarySchool
  );
  return axios.huddleAxiosInstanceProxy
    .get(uri, { cancelToken: sourceAccountsInSeason.token }, true)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response.map(account => new AccountDTO(account));
    })
    .catch(e => {
      if (e.message === CANCELLED_REQUEST) {
        throw e;
      }
      return new ErrorDTO(e);
    })
    .finally(() => {
      sourceAccountsInSeason = null;
    });
};

const fetchAccounts = (searchRequest, pageRequest, sortableRequest) => {
  const uri = `${FETCH_ACCOUNTS}?${pageRequest.toQueryString()}${sortableRequest.toQueryString()}`;
  return axios.huddleAxiosInstanceProxy
    .post(uri, searchRequest.toJSON())
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }

      const { content, pageable, totalPages } = response;
      const { pageSize, pageNumber } = isEmpty(pageable) ? {} : pageable;

      return new PaginationDTO({
        pageSize,
        pageNumber,
        totalPages,
        data: content.map(account => new AccountDTO(account))
      });
    })
    .catch(e => new ErrorDTO(e));
};

export default {
  fetchAccounts,
  fetchAccountById,
  searchAccountsInSeason,
  fetchAccountsByKeyword,
  cancelSearchAccountsInSeason,
  cancelFetchAccountsByKeyword
};
