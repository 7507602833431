import type { AccountProductDTO } from '@gofan/api/account-products';
import type { AccountDTO } from '@gofan/api/accounts';
import type {
  EventScheduleSearchDTO,
  EventScheduleSeasonDTO as EventScheduleSeasonType,
  LevelGenders
} from '@modules/event-integrations_V2/models/event-schedule.model';
import type { EventConflictDTO, ImportEventDTO } from '@app/modules/events/models/event.model';
import type { RateDTO } from '@gofan/api/rates';
import type { EventScheduleSeasonDTO, GlobalEventDTO } from '@app/modules/seasons/models/season.model';
import type RowEvent from '../../api/model/RowEvent';
import type { Activity, Level, Account } from '../../api/types';
import type { SearchAccountsTicketsResult, SearchAccountTicketsParams } from './types';

export const PREPARE_DATA = '@SeasonSetup/PREPARE_DATA';
export const PREPARE_DATA_COMPLETED = '@SeasonSetup/PREPARE_DATA_COMPLETED';

export const ON_PUBLISH_SEASON = '@SeasonSetup/ON_PUBLISH_SEASON';
export const ON_PUBLISH_SEASON_COMPLETED = '@SeasonSetup/ON_PUBLISH_SEASON_COMPLETED';
export const RESET_STATE = '@SeasonSetup/RESET_STATE';
export const SEARCH_OPPONENT_ACCOUNTS = '@SeasonSetup/SEARCH_OPPONENT_ACCOUNTS';
export const SEARCH_OPPONENT_ACCOUNTS_SUCCESS = '@SeasonSetup/SEARCH_OPPONENT_ACCOUNTS_SUCCESS';
export const RESET_PUBLISH_STATE = '@SeasonSetup/RESET_PUBLISH_STATE';

export const EXECUTE_PROCESS_GET_DATA_TEMPLATE = '@SeasonSetup/EXECUTE_PROCESS_GET_DATA_TEMPLATE';
export const EXECUTE_PROCESS_GET_DATA_TEMPLATE_COMPLETED = '@SeasonSetup/EXECUTE_PROCESS_GET_DATA_TEMPLATE_COMPLETED';
export const UPDATE_PROCESS_GET_DATA_TEMPLATE_STATUS = '@SeasonSetup/UPDATE_PROCESS_GET_DATA_TEMPLATE_STATUS';

export const UPLOAD_FILE_EVENT = '@SeasonSetup/UPLOAD_FILE_EVENT';
export const UPLOAD_FILE_EVENT_FAIL = '@SeasonSetup/UPLOAD_FILE_EVENT_FAIL';
export const UPLOAD_FILE_EVENT_SUCCESS = '@SeasonSetup/UPLOAD_FILE_EVENT_SUCCESS';

export const BUILD_EVENT_CREATED_DATA = '@SeasonSetup/BUILD_EVENT_CREATED_DATA';
export const BUILD_EVENT_CREATED_DATA_COMPLETED = '@SeasonSetup/BUILD_EVENT_CREATED_DATA_COMPLETED';

export const CLEAR_UPLOAD_FILE_ERROR = '@SeasonSetup/CLEAR_UPLOAD_FILE_ERROR';
export const SEARCH_ACCOUNT_TICKETS = '@SeasonSetup/SEARCH_ACCOUNT_TICKETS';
export const SEARCH_ACCOUNT_TICKETS_COMPLETED = '@SeasonSetup/SEARCH_ACCOUNT_TICKETS_COMPLETED';
export const CLEAR_SEARCH_OPPONENT_ACCOUNTS = '@SeasonSetup/CLEAR_SEARCH_OPPONENT_ACCOUNTS';

export const CREATE_SEASON_FROM_ARBITER_EVENTS = '@SeasonSetup/CREATE_SEASON_FROM_ARBITER_EVENTS';
export const CLEAR_ARBITER_EVENTS = '@SeasonSetup/CLEAR_ARBITER_EVENTS';
export const CHECK_SCHEDULES_ARBITER_EVENTS_AVAILABLE = '@SeasonSetup/CHECK_SCHEDULES_ARBITER_EVENTS_AVAILABLE';
export const CHECK_SCHEDULES_ARBITER_EVENTS_AVAILABLE_COMPLETED =
  '@SeasonSetup/CHECK_SCHEDULES_ARBITER_EVENTS_AVAILABLE_COMPLETED';
export const SEARCH_EVENT_SCHEDULES = '@SeasonSetup/SEARCH_EVENT_SCHEDULES';
export const SEARCH_EVENT_SCHEDULES_COMPLETED = '@SeasonSetup/SEARCH_EVENT_SCHEDULES_COMPLETED';
export const CLEAR_EVENT_SCHEDULES = '@SeasonSetup/CLEAR_EVENT_SCHEDULES';
export const CHECK_DUPLICATE_EVENTS = '@SeasonSetup/CHECK_DUPLICATE_EVENTS';
export const CHECK_DUPLICATE_EVENTS_COMPLETED = '@SeasonSetup/CHECK_DUPLICATE_EVENTS_COMPLETED';
export const UPDATE_EVENT_CONFLICTS = '@SeasonSetup/UPDATE_EVENT_CONFLICTS';
export const UPDATE_IS_FROM_ARBITER = '@SeasonSetup/UPDATE_IS_FROM_ARBITER';

interface PrepareDataAction {
  type: typeof PREPARE_DATA;
  payload: {
    mode: string;
    history: any;
    currentUser: any;
    eventId: number | string;
  };
}

interface PrepareDataCompletedAction {
  type: typeof PREPARE_DATA_COMPLETED;
  payload: {
    levels: Level[];
    athleticActivities: Activity[];
    nonAthleticActivities: Activity[];
    account: Account;
    rates: RateDTO[];
  };
  error: any;
}

interface PublishSeasonAction {
  type: typeof ON_PUBLISH_SEASON;
  payload: {
    currentUser: Object;
    history: Object;
    seasonData: Object;
    isArbiterSeason: boolean;
    accountProducts: AccountProductDTO[];
    deletedIds: string[];
    importType: string;
  };
}

interface PublishSeasonCompletedAction {
  type: typeof ON_PUBLISH_SEASON_COMPLETED;
  error: any;
}

interface SearchOpponentAccountsAction {
  type: typeof SEARCH_OPPONENT_ACCOUNTS;
  payload: {
    keyword: string;
    name?: string;
  };
}

interface SearchOpponentAccountsSuccessAction {
  type: typeof SEARCH_OPPONENT_ACCOUNTS_SUCCESS;
  payload: {
    accounts: Account[];
    name?: string;
  };
}

interface ResetStateAction {
  type: typeof RESET_STATE;
}

interface ResetPublishStateAction {
  type: typeof RESET_PUBLISH_STATE;
}

interface ExecuteProcessGetDataTemplateAction {
  type: typeof EXECUTE_PROCESS_GET_DATA_TEMPLATE;
}

interface ExcecuteProcessGetDataTemplateCompletedAction {
  type: typeof EXECUTE_PROCESS_GET_DATA_TEMPLATE_COMPLETED;
}

interface UpdateProcessGetDataTemplateStatusAction {
  type: typeof UPDATE_PROCESS_GET_DATA_TEMPLATE_STATUS;
}

interface UploadFileEventAction {
  type: typeof UPLOAD_FILE_EVENT;
  payload: {
    file: File;
  };
}

interface UploadFileEventFailAction {
  type: typeof UPLOAD_FILE_EVENT_FAIL;
  payload: {
    error: string;
  };
}

interface UploadFileEventSuccessAction {
  type: typeof UPLOAD_FILE_EVENT_SUCCESS;
  payload: {
    events: Array<RowEvent>;
  };
}

interface BuildEventCreatedDataAction {
  type: typeof BUILD_EVENT_CREATED_DATA;
  payload: {
    events: Array<RowEvent>;
    isManually: boolean;
    existingEventCreatedData: any;
  };
}

interface BuildEventCreatedDataCompletedAction {
  type: typeof BUILD_EVENT_CREATED_DATA_COMPLETED;
  payload: {
    eventsData: any;
  };
}

interface ClearUploadFileErrorAction {
  type: typeof CLEAR_UPLOAD_FILE_ERROR;
}

interface SearchAccountTicketsAction {
  type: typeof SEARCH_ACCOUNT_TICKETS;
  payload: {
    searchParams: SearchAccountTicketsParams;
  };
}
interface SearchAccountTicketsCompletedAction {
  type: typeof SEARCH_ACCOUNT_TICKETS_COMPLETED;
  payload: {
    searchResult: SearchAccountsTicketsResult;
  };
}
interface ClearSearchOpponentAccountsAction {
  type: typeof CLEAR_SEARCH_OPPONENT_ACCOUNTS;
}

interface CreateSeasonFromArbiterEventsAction {
  type: typeof CREATE_SEASON_FROM_ARBITER_EVENTS;
  payload: {
    arbiterEvents: GlobalEventDTO[];
    eventScheduleSeason: EventScheduleSeasonDTO;
    arbiterSchools: AccountDTO[];
    needToResetEventConflict?: boolean;
  };
}

interface UpdateIsFromArbiterAction {
  type: typeof UPDATE_IS_FROM_ARBITER;
  payload: {
    isFromArbiter: boolean;
  };
}

interface ClearArbiterEventsAction {
  type: typeof CLEAR_ARBITER_EVENTS;
}

interface CheckSchedulesArbiterEventsAvailableAction {
  type: typeof CHECK_SCHEDULES_ARBITER_EVENTS_AVAILABLE;
  payload: {
    partnerName: string;
    genders: string[];
    levelGenders: LevelGenders[];
    schoolIds: string[];
    sportNames: string[];
    status: string[];
  };
}

interface CheckSchedulesArbiterEventsAvailableCompletedAction {
  type: typeof CHECK_SCHEDULES_ARBITER_EVENTS_AVAILABLE_COMPLETED;
  payload: boolean;
}

interface SearchEventSchedulesAction {
  type: typeof SEARCH_EVENT_SCHEDULES;
  payload: {
    searchParams: EventScheduleSearchDTO;
  };
}
interface SearchEventSchedulesCompletedAction {
  type: typeof SEARCH_EVENT_SCHEDULES_COMPLETED;
  payload: {
    searchResult: EventScheduleSeasonType[];
  };
}
interface ClearEventSchedulesAction {
  type: typeof CLEAR_EVENT_SCHEDULES;
}
interface CheckDuplicateEventsAction {
  type: typeof CHECK_DUPLICATE_EVENTS;
  payload: {
    importEvents: Array<ImportEventDTO>;
    importEventsType: string;
    opponentSchools: AccountDTO[];
    eventScheduleSeason?: EventScheduleSeasonDTO;
  };
}

interface CheckDuplicateEventsCompletedAction {
  type: typeof CHECK_DUPLICATE_EVENTS_COMPLETED;
  payload: {
    eventConflicts: Array<EventConflictDTO>;
    importEventsType: string;
    eventScheduleSeason?: EventScheduleSeasonDTO;
    arbiterEvents: GlobalEventDTO[];
  };
}

interface UpdateEventConflictsAction {
  type: typeof UPDATE_EVENT_CONFLICTS;
}

export type SeasonSetupActionTypes =
  | PrepareDataAction
  | PrepareDataCompletedAction
  | PublishSeasonAction
  | PublishSeasonCompletedAction
  | SearchOpponentAccountsAction
  | SearchOpponentAccountsSuccessAction
  | ResetStateAction
  | ResetPublishStateAction
  | ExecuteProcessGetDataTemplateAction
  | ExcecuteProcessGetDataTemplateCompletedAction
  | UpdateProcessGetDataTemplateStatusAction
  | UploadFileEventAction
  | UploadFileEventFailAction
  | UploadFileEventSuccessAction
  | BuildEventCreatedDataAction
  | BuildEventCreatedDataCompletedAction
  | ClearUploadFileErrorAction
  | SearchAccountTicketsAction
  | SearchAccountTicketsCompletedAction
  | ClearSearchOpponentAccountsAction
  | CreateSeasonFromArbiterEventsAction
  | ClearArbiterEventsAction
  | CheckSchedulesArbiterEventsAvailableAction
  | CheckSchedulesArbiterEventsAvailableCompletedAction
  | SearchEventSchedulesAction
  | SearchEventSchedulesCompletedAction
  | ClearEventSchedulesAction
  | CheckDuplicateEventsAction
  | CheckDuplicateEventsCompletedAction
  | UpdateIsFromArbiterAction
  | UpdateEventConflictsAction;
