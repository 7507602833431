// import { REHYDRATE } from 'redux-persist';

import {
  ON_RESET_SEASON_MANAGEMENT_STATE,
  ON_RESET_STATE_WHEN_UN_MOUNT,
  ON_UPDATE_SEASON,
  ON_UPDATE_CREATING_SEASON_LIST,
  ON_SELECT_SPORT,
  ON_SET_ACTIVED_SPORT,
  ON_UPDATE_BUILD_PROCESS,
  ON_UPDATE_PUBLISH_PROCESS,
  ON_SET_DATA_TO_SETUP_MULTI_SEASON,
  ON_UPDATE_TICKET_TYPES
} from '@season-management/middleware/actionTypes';
import type { SeasonManagementState } from '@season-management/middleware/types';
import SeasonCreationUtil from '@season-management/utils/season-creation.utils';

export const initialState: SeasonManagementState = {
  activedSport: null,
  selectedSports: [],
  creatingSeason: null,
  creatingSeasonList: [],
  buildingProcess: null,
  publishProcess: null,
  creationMethod: '',
  financialAccountId: null,
  financialAccount: null,
  multiSportConfig: null
};

const seasonManagement = (state = initialState, action: any) => {
  switch (action.type) {
    // case REHYDRATE:
    //   return { ...state };

    case ON_RESET_SEASON_MANAGEMENT_STATE: {
      return { ...state, ...initialState };
    }

    case ON_RESET_STATE_WHEN_UN_MOUNT: {
      return {
        ...state,
        buildingProcess: null,
        publishProcess: null
      };
    }

    case ON_SET_DATA_TO_SETUP_MULTI_SEASON: {
      const { dataSetup } = action.payload;
      return { ...state, ...dataSetup };
    }

    case ON_UPDATE_SEASON: {
      const { updatedSeason } = action.payload;
      const updatedList = SeasonCreationUtil.updateCreatingSeasonList({
        updatedSeason,
        storedCreatingSeasonList: [...state.creatingSeasonList]
      });
      const updatedCreatingSeason = SeasonCreationUtil.getCreatingSeasonBySport({
        activedSport: state.activedSport,
        creatingSeasonList: updatedList
      });
      return {
        ...state,
        creatingSeasonList: updatedList,
        creatingSeason: updatedCreatingSeason
      };
    }

    case ON_UPDATE_CREATING_SEASON_LIST: {
      const { creatingSeasonList } = action.payload;
      return {
        ...state,
        creatingSeasonList
      };
    }

    case ON_SELECT_SPORT: {
      const { selectedSports } = action.payload;
      const updatedList = SeasonCreationUtil.updateCreatingSeasonListBySports({
        selectedSports,
        storedCreatingSeasonList: [...state.creatingSeasonList]
      });
      return {
        ...state,
        selectedSports,
        creatingSeasonList: updatedList
      };
    }

    case ON_UPDATE_TICKET_TYPES: {
      const { ticketTypes } = action.payload;

      return {
        ...state,
        creatingSeason: { ...state.creatingSeason, accountTicketTypes: ticketTypes }
      };
    }

    case ON_SET_ACTIVED_SPORT: {
      const { activedSport } = action.payload;
      const creatingSeasonList = SeasonCreationUtil.initCreatingSeasonList({
        activedSport,
        storedCreatingSeasonList: [...state.creatingSeasonList]
      });
      const updatedCreatingSeason = SeasonCreationUtil.getCreatingSeasonBySport({ activedSport, creatingSeasonList });
      const newActivedSport = SeasonCreationUtil.getActivedSportBySeason({
        activedSport,
        creatingSeason: updatedCreatingSeason
      });
      return {
        ...state,
        creatingSeasonList,
        activedSport: newActivedSport,
        creatingSeason: updatedCreatingSeason
      };
    }

    case ON_UPDATE_BUILD_PROCESS: {
      const { buildingProcess } = action.payload;
      return {
        ...state,
        buildingProcess
      };
    }

    case ON_UPDATE_PUBLISH_PROCESS: {
      const { publishProcess } = action.payload;
      return {
        ...state,
        publishProcess
      };
    }

    default:
      return { ...state };
  }
};

export default seasonManagement;
