import { oc } from 'ts-optchain';
import { isEmpty } from 'lodash';
import { TextArea, TextAreaProps } from 'carbon-components-react';

export type BasicTextAreaConfig = {
  label: string;
  hasCounter: boolean;
};

export type BasicTextAreaProps = TextAreaProps &
  BasicTextAreaConfig & {
    name: string;
    control: any;
    initialValue: string;
    fieldValue: string;
    fieldError?: string;
    getFieldRef?: () => void;
  };

const BasicTextArea = ({
  name,
  label = '',
  className,
  hasCounter,
  rows = 1,
  maxLength,
  initialValue,
  fieldValue,
  fieldError,
  getFieldRef,
  control,
  ...other
}: BasicTextAreaProps) => (
  <div className='text-area'>
    <div className='text-area--heading'>
      <div className='bx--label text-area--label'>{label}</div>
      {hasCounter && <div className='bx--label text-area--counter'>{`${oc(fieldValue)('').length}/${maxLength}`}</div>}
    </div>
    <TextArea
      {...other}
      {...control}
      id={`field-${name}`}
      ref={getFieldRef}
      className={className}
      rows={rows}
      hideLabel
      labelText={label}
      defaultValue={fieldValue}
      maxLength={maxLength}
      invalidText={fieldError}
      invalid={!isEmpty(fieldError)}
    />
  </div>
);

export default BasicTextArea;
