import React from 'react';
import { ProgressIndicator, ProgressStep } from 'carbon-components-react';

const noop = () => {};

export type ProgressDataType = {
  [step: string]: {
    index: number;
    label: string;
    description?: string;
    secondaryLabel?: string;
  };
};

interface CustomProgressIndicatorProps {
  progressData: ProgressDataType;
  currentIndex: number;
  // eslint-disable-next-line react/require-default-props
  onChange?: (index: number) => void;
}

const CustomProgressIndicator = ({ progressData, currentIndex = 0, onChange = noop }: CustomProgressIndicatorProps) => (
  <ProgressIndicator className='custom-progress' currentIndex={currentIndex} onChange={onChange}>
    {Object.values(progressData ?? {}).map((progress, index) => (
      <ProgressStep
        key={progress.label}
        label={progress.label}
        description={progress.description}
        secondaryLabel={progress.secondaryLabel}
        current={index === currentIndex}
      />
    ))}
  </ProgressIndicator>
);

export default CustomProgressIndicator;
