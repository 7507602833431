class ErrorEvent {
  constructor({ description, field, rejectedValue }) {
    this.raw = {
      description,
      field,
      rejectedValue
    };
  }

  get description() {
    return this.raw.description;
  }

  get field() {
    return this.raw.field;
  }

  get rejectedValue() {
    return this.raw.rejectedValue;
  }

  toJSON() {
    return {
      description: this.description,
      field: this.field,
      rejectedValue: this.rejectedValue
    };
  }
}

export default ErrorEvent;
