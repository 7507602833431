/* eslint-disable import/no-named-default */
import archery from './archery.svg';
import academics from './academics.svg';
import band from './band.svg';
import banquet from './banquet.svg';
import badminton from './badminton.svg';
import baseball from './baseball.svg';
import basketball from './basketball.svg';
import bowling from './bowling.svg';
import camps from './camps.svg';
import chess from './chess.svg';
import chorus from './chorus.svg';
import { default as club, default as eventschool, default as eventnonschool } from './school-event.svg';
import { default as crosscountry, default as trackandfield } from './cross-country.svg';
import { default as cheerleading, default as cheer, default as danceteam } from './cheerleading.svg';
import { default as dance, default as prom, default as schooldance } from './school-dance.svg';
import drillteam from './drill-team.svg';
import esports from './esports.svg';
import digitalseasonpass from './digital-season-pass.svg';
import fencing from './fencing.svg';
import fieldhockey from './field-hockey.svg';
import fieldday from './field-day.svg';
import fieldtrip from './field-trip.svg';
import { default as flagfootball, default as powderpuff } from './flag-football.svg';
import fundraiser from './fundraiser.svg';
import football from './football.svg';
import golf from './golf.svg';
import gymnastics from './gymnastics.svg';
import graduation from './graduation.svg';
import hockey from './hockey.svg';
import merchandise from './merchandise.svg';
import lacrosse from './lacrosse.svg';
import orchestra from './orchestra.svg';
import parking from './parking.svg';
import passcard from './pass-card.svg';
import performingarts from './performing-arts.svg';
import physicals from './physicals.svg';
import race5k from './race-5k.svg';
import registration from './registration.svg';
import rugby from './rugby.svg';
import sheetticket from './ticket-sheets.svg';
import soccer from './soccer.svg';
import softball from './softball.svg';
import swimminganddiving from './swimming.svg';
import sponsorship from './sponsorship.svg';
import summerschool from './summer-school.svg';
import tailgate from './tailgate.svg';
import tennis from './tennis.svg';
import test from './test.svg';
import unlabeled from './unlabeled.svg';
import volleyball from './volleyball.svg';
import waterpolo from './water-polo.svg';
import weightlifting from './weightlifting.svg';
import wrestling from './wrestling.svg';
import yearbook from './yearbook.svg';
import bankstatement from './noun-bank-statement.svg';
import taskmanage from './noun-task-manage.svg';
import analytics from './noun-analytics.svg';

export interface IconsType {
  [key: string]: string | undefined;
}

export default {
  archery,
  academics,
  band,
  banquet,
  badminton,
  baseball,
  basketball,
  bowling,
  camps,
  cheer,
  chess,
  cheerleading,
  chorus,
  club,
  crosscountry,
  dance,
  danceteam,
  drillteam,
  digitalseasonpass,
  esports,
  eventnonschool,
  eventschool,
  fencing,
  fieldhockey,
  fieldday,
  fieldtrip,
  flagfootball,
  fundraiser,
  football,
  golf,
  gymnastics,
  graduation,
  hockey,
  merchandise,
  lacrosse,
  orchestra,
  parking,
  passcard,
  performingarts,
  physicals,
  powderpuff,
  prom,
  race5k,
  registration,
  rugby,
  sheetticket,
  soccer,
  softball,
  schooldance,
  swimminganddiving,
  sponsorship,
  summerschool,
  tailgate,
  tennis,
  test,
  trackandfield,
  unlabeled,
  volleyball,
  waterpolo,
  weightlifting,
  wrestling,
  yearbook,
  bankstatement,
  taskmanage,
  analytics
};
