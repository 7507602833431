import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type { SchoolConfig } from './school-config.model';
import { SCHOOL_CONFIG_QUERY_KEY } from '@gofan/constants';
import { SchoolConfigService } from './school-config.service';

export interface UseSchoolConfigOptions {
  schoolId: string;
  queryOptions?: {
    cacheTime?: number;
    staleTime?: number;
    enabled?: boolean;
    refetchOnWindowFocus?: boolean;
  };
}

export const useSchoolConfig = ({ schoolId, queryOptions }: UseSchoolConfigOptions) => {
  const queryClient = useQueryClient();

  const query = useQuery<SchoolConfig>({
    queryKey: [SCHOOL_CONFIG_QUERY_KEY, schoolId],
    queryFn: () => SchoolConfigService.getSchoolConfigBySchoolId(schoolId),
    ...queryOptions
  });

  const createSchoolConfig = useMutation({
    mutationFn: (schoolConfig: SchoolConfig) => SchoolConfigService.createSchoolConfig(schoolConfig),
    onSuccess: schoolConfig => {
      queryClient.invalidateQueries({ queryKey: [SCHOOL_CONFIG_QUERY_KEY, `${schoolConfig.schoolId}`] });
    }
  });

  const updateSchoolConfig = useMutation({
    mutationFn: (schoolConfig: SchoolConfig) => SchoolConfigService.updateSchoolConfig(schoolConfig),
    onSuccess: schoolConfig => {
      queryClient.invalidateQueries({ queryKey: [SCHOOL_CONFIG_QUERY_KEY, `${schoolConfig.schoolId}`] });
    }
  });

  const bulkUpdateSchoolConfig = useMutation({
    mutationFn: (schoolConfig: SchoolConfig) => SchoolConfigService.bulkUpdateSchoolConfig(schoolConfig),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SCHOOL_CONFIG_QUERY_KEY] });
    }
  });

  return { ...query, createSchoolConfig, updateSchoolConfig, bulkUpdateSchoolConfig };
};
