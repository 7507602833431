import React, { createRef } from 'react';
import styled from 'styled-components';
import styles from './styles';
import { isEmpty } from '../../../../../../utils/objectUtils';

import SearchResultsListItem from '../SearchResultsListItem';

const SearchResultsWrapper = styled.div`
  ${styles.wrapper};
  ${styles.searchResults};
`;

const NoResultWrapper = styled.div`
  ${styles.wrapper};
  ${styles.noResultWrapper};
`;

const NoResultTitle = styled.div`
  ${styles.noResultTitle};
  ${styles.textWrapper};
`;

const NoResultSubTitle = styled.div`
  ${styles.noResultSubTitle};
  ${styles.textWrapper};
`;

const ResultGroupTitle = styled.div`
  ${styles.resultGroupTitle};
`;

export default ({
  searching,
  focused,
  results,
  defaultIcon,
  onSelectItem,
  width,
  messageNotFound = null,
  useCustomMessageNotFound = false,
  direction = 'bottom',
  showIcon = true,
  groupConditions = []
}) => {
  const searchResultsRef = createRef();

  if (!focused || (isEmpty(results) && isEmpty(messageNotFound))) {
    return null;
  }

  return (
    <div ref={searchResultsRef}>
      {!isEmpty(results) ? (
        <SearchResultsWrapper id='lstResult' width={width} direction={direction}>
          {!isEmpty(groupConditions) &&
            groupConditions.map(
              group =>
                results.filter(group.mapping).length > 0 && (
                  <div key={group.title}>
                    <ResultGroupTitle>{group.title}</ResultGroupTitle>
                    {results.filter(group.mapping).map(item => (
                      <SearchResultsListItem
                        key={item.id}
                        item={item}
                        defaultIcon={defaultIcon}
                        onSelectItem={onSelectItem}
                        showIcon={showIcon}
                      />
                    ))}
                  </div>
                )
            )}
          {isEmpty(groupConditions) &&
            results.map(item => (
              <SearchResultsListItem
                key={item.id}
                item={item}
                defaultIcon={defaultIcon}
                onSelectItem={onSelectItem}
                showIcon={showIcon}
              />
            ))}
        </SearchResultsWrapper>
      ) : (
        <>
          {searching ? (
            <NoResultWrapper id='boxNoResult' width={width}>
              <NoResultSubTitle>Finding...</NoResultSubTitle>
            </NoResultWrapper>
          ) : (
            <NoResultWrapper id='boxNoResult' width={width}>
              {useCustomMessageNotFound ? (
                <NoResultSubTitle>{isEmpty(messageNotFound) ? '' : `${messageNotFound} not found.`}</NoResultSubTitle>
              ) : (
                <>
                  <NoResultTitle>No results found.</NoResultTitle>
                  <NoResultSubTitle>Try again using a different term.</NoResultSubTitle>
                </>
              )}
            </NoResultWrapper>
          )}
        </>
      )}
    </div>
  );
};
