import * as yup from 'yup';
import { DATE_PICKER_FORMAT_DEFAULT, DATE_TIME_PICKER_FORMAT_DEFAULT } from '@gofan/constants/date';
import {
  ALPHANUMERIC_REGEX,
  SPECIAL_CHARACTERS_REGEX,
  TIME_PICKER_PATTERN,
  GL_CODE_REGEX
} from '@gofan/utils/validate';

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
// import get from 'lodash/get';
import {
  // MAX_CAPACITY,
  MAX_EVENT_TICKET_PRICE,
  MIN_CONCESSION_ITEM_PRICE,
  MAX_ADMIN_TICKET_FEE
} from '@gofan/constants/product';
import type { InferType } from 'yup';

const EVENT_NAME_VALIDATION_MESSAGE =
  'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (`()./_-#\'@&*+",:;?!) in between 3-100 characters long.';
const PRODUCT_NAME_VALIDATION_MESSAGE =
  'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (`()./_-#\'@&*+",:;?!) in between 2-40 characters long.';

const ConcessionEventSchema = yup.object({
  accountId: yup.string().required(),
  allDayEvent: yup.boolean().nullable(),
  activityId: yup.number().required(),
  endDateTime: yup.string(),
  financialAccountId: yup.string().required(),
  genders: yup.array(yup.string()),
  levels: yup.array(
    yup.object({
      levelId: yup.number().required(),
      genders: yup.array(yup.string()).required()
    })
  ),
  name: yup
    .string()
    .required('Concession name is required.')
    .min(3, EVENT_NAME_VALIDATION_MESSAGE)
    .max(100, EVENT_NAME_VALIDATION_MESSAGE)
    .matches(SPECIAL_CHARACTERS_REGEX, {
      message: EVENT_NAME_VALIDATION_MESSAGE,
      excludeEmptyStrings: true
    }),
  opponentAccountId: yup.string().nullable(),
  startDateTime: yup.string().required('Please enter a valid start date and time.'),
  taggedAccountIds: yup.array(yup.string()),
  timeZone: yup.string(),
  venueAddress: yup.string().min(1, 'Venue address is required.').required(),
  venueCity: yup.string().min(1, 'Venue city is required.').required(),
  venueId: yup.number().nullable(),
  venueLocation: yup.string().nullable(),
  venueName: yup.string().min(1, 'Venue name is required.').required(),
  venueState: yup.string().min(2, 'A valid venue state is required.').required(),
  venueZip: yup.string().min(1, 'Venue zip code is required.').required(),
  glCode: yup.string().nullable().matches(GL_CODE_REGEX, {
    message: 'Only alphanumeric, period(.), dash(-), underscore(_), hash(#) are allowed.',
    excludeEmptyStrings: true
  }),

  // temporary properties held for date and time validation
  startDate: _startDateSchemaBuilder(),
  startTime: _startTimeSchemaBuilder(),
  endDate: _endDateSchemaBuilder(),
  endTime: _endTimeSchemaBuilder()
});

const ConcessionProductSchema = yup.object({
  products: yup
    .array(
      yup.object({
        created: yup.boolean(),
        customColor: yup.string().nullable(),
        distributionChannel: yup.string().oneOf(['BoxOffice']).required(),
        enabled: yup.boolean().required(),
        hiddenFees: yup.boolean().required(),
        id: yup.number(),
        // limit: _ticketLimitSchemaBuilder(),     May be supported in the future
        name: yup
          .string()
          .min(2, PRODUCT_NAME_VALIDATION_MESSAGE)
          .max(40, PRODUCT_NAME_VALIDATION_MESSAGE)
          .matches(SPECIAL_CHARACTERS_REGEX, {
            message: PRODUCT_NAME_VALIDATION_MESSAGE,
            excludeEmptyStrings: true
          }),
        price: _ticketPriceSchemaBuilder(),
        productType: yup.string().oneOf(['CONCESSION']).required(),
        productCategory: yup.string().required()
      })
    )
    .min(1, 'At least one product is required before publishing.')
});

export const initProduct = (attendeePaysFee: boolean) => ({
  customColor: undefined,
  distributionChannel: 'BoxOffice',
  enabled: true,
  hiddenFees: !attendeePaysFee,
  name: undefined,
  price: undefined,
  productType: 'CONCESSION'
});

export type ConcessionEvent = InferType<typeof ConcessionEventSchema>;
export type ConcessionProducts = InferType<typeof ConcessionProductSchema>;
export type CreateConcession = ConcessionEvent & ConcessionProducts;
export { ConcessionEventSchema, ConcessionProductSchema };

function _startDateSchemaBuilder() {
  return yup.string().test('invalidStartDate', '', (startDate, testContext) => {
    const { startTime, endTime, endDate } = testContext?.parent ?? {};

    let errorMessage = _validateDate({
      date: startDate,
      invalidMessage: 'Enter a valid start date.',
      requiredMessage: 'Start date is required.'
    });

    if (
      isEmpty(errorMessage) &&
      !_validateTime({ time: startTime }) &&
      !_validateTime({ time: endTime }) &&
      _isStartAfterEndDateTime({
        startDate,
        startTime,
        endDate,
        endTime,
        unit: 'days',
        allowSame: true
      })
    ) {
      errorMessage = 'Start date must come before End date.';
    }

    return isEmpty(errorMessage)
      ? true
      : testContext.createError({
          path: testContext.path,
          message: errorMessage
        });
  });
}

function _startTimeSchemaBuilder() {
  return yup.string().test('invalidStartTime', '', (startTime, testContext) => {
    const { startDate, endTime, endDate, allDayEvent, eventStartDateTime } = testContext?.parent ?? {};

    let errorMessage = _validateTime({
      time: startTime,
      invalidMessage: 'Enter a valid start time.',
      requiredMessage: 'Start time is required.'
    });

    if (
      isEmpty(errorMessage) &&
      !_validateDate({ date: startDate }) &&
      !_validateDate({ date: endDate }) &&
      !_isStartAfterEndDateTime({
        startDate,
        startTime,
        endDate,
        endTime,
        unit: 'days',
        allowSame: true
      }) &&
      _isStartAfterEndDateTime({
        startDate,
        startTime,
        endDate,
        endTime,
        unit: 'seconds'
      })
    ) {
      errorMessage = 'Start time must come before End time';
    }

    return isEmpty(errorMessage)
      ? true
      : testContext.createError({
          path: testContext.path,
          message: errorMessage
        });
  });
}

function _endDateSchemaBuilder() {
  return yup.string().test('invalidEndDate', '', (endDate, testContext) => {
    const { startTime, endTime, startDate } = testContext?.parent ?? {};

    let errorMessage = _validateDate({
      date: endDate,
      invalidMessage: 'Enter a valid end date.',
      requiredMessage: 'End date is required.'
    });

    if (
      isEmpty(errorMessage) &&
      !_validateTime({ time: startTime }) &&
      !_validateTime({ time: endTime }) &&
      _isStartAfterEndDateTime({
        startDate,
        startTime,
        endDate,
        endTime,
        unit: 'days',
        allowSame: true
      })
    ) {
      errorMessage = 'End date must come after Start date.';
    }

    return isEmpty(errorMessage)
      ? true
      : testContext.createError({
          path: testContext.path,
          message: errorMessage
        });
  });
}

function _endTimeSchemaBuilder() {
  return yup.string().test('invalidEndTime', '', (endTime, testContext) => {
    const { startDate, startTime, endDate } = testContext?.parent ?? {};

    let errorMessage = _validateTime({
      time: endTime,
      invalidMessage: 'Enter a valid end time.',
      requiredMessage: 'End time is required.'
    });

    if (
      isEmpty(errorMessage) &&
      !_validateDate({ date: startDate }) &&
      !_validateDate({ date: endDate }) &&
      !_isStartAfterEndDateTime({
        startDate,
        startTime,
        endDate,
        endTime,
        unit: 'days',
        allowSame: true
      }) &&
      _isStartAfterEndDateTime({
        startDate,
        startTime,
        endDate,
        endTime,
        unit: 'seconds'
      })
    ) {
      errorMessage = 'End time must come after Start time.';
    }

    return isEmpty(errorMessage)
      ? true
      : testContext.createError({
          path: testContext.path,
          message: errorMessage
        });
  });
}

function _validateDate({ date, invalidMessage = 'invalid.', requiredMessage = 'required.' }) {
  if (isEmpty(date)) return requiredMessage;

  const mDate = moment(date, DATE_TIME_PICKER_FORMAT_DEFAULT);
  if (!mDate.isValid()) return invalidMessage;
  return '';
}

function _validateTime({
  time,
  pattern = TIME_PICKER_PATTERN,
  invalidMessage = 'invalid.',
  requiredMessage = 'required.'
}) {
  if (isEmpty(time)) return requiredMessage;

  const date = moment().format(DATE_PICKER_FORMAT_DEFAULT);
  if (!pattern.test(time) || !moment(`${date} ${time}`, DATE_TIME_PICKER_FORMAT_DEFAULT).isValid()) {
    return invalidMessage;
  }
  return '';
}

function _isStartAfterEndDateTime({ startDate, startTime, endDate, endTime, unit, allowSame }: any) {
  const mStartDateTime = moment(`${startDate} ${startTime}`, DATE_TIME_PICKER_FORMAT_DEFAULT, true);
  const mEndDateTime = moment(`${endDate} ${endTime}`, DATE_TIME_PICKER_FORMAT_DEFAULT, true);

  if (mStartDateTime.isValid() && mEndDateTime.isValid()) {
    if (allowSame) {
      return mStartDateTime.isAfter(mEndDateTime, unit);
    }
    return mStartDateTime.isSameOrAfter(mEndDateTime, unit);
  }
  return false;
}

function _isStartBeforeMinDate({ startDate, startTime, allDayEvent, eventStartDateTime, errorMessage = 'error' }: any) {
  if (!allDayEvent) {
    const mStartDateTime = moment(`${startDate} ${startTime}`, DATE_TIME_PICKER_FORMAT_DEFAULT, true);
    const mMinDate = _getMinDateTime(eventStartDateTime);

    if (mStartDateTime.isBefore(mMinDate, 'minutes')) {
      return errorMessage;
    }
  }
  return '';
}

function _getMinDateTime(date: any) {
  const currentDateTime = moment(new Date());
  return moment.min(moment(date), currentDateTime);
}

function _ticketPriceSchemaBuilder() {
  return yup.number().when('created', (type: any) => {
    if (type === false) return yup.number().notRequired();
    return yup.number().test('invalidTicketPrice', '', (ticketPrice, testContext) => {
      const maxTicketValue = testContext.parent.isAdminRole ? MAX_ADMIN_TICKET_FEE : MAX_EVENT_TICKET_PRICE;
      let errorMessage = '';
      if (isNil(ticketPrice)) errorMessage = 'Price is required.';
      else if (isEmpty(errorMessage) && (ticketPrice < MIN_CONCESSION_ITEM_PRICE || ticketPrice > maxTicketValue)) {
        errorMessage = testContext.parent.isAdminRole
          ? 'Price must be between 0.00 and 10,000.00.'
          : 'Price must be between 0.00 and 250.00.';
      }

      return isEmpty(errorMessage)
        ? true
        : testContext.createError({
            path: testContext.path,
            message: errorMessage
          });
    });
  });
}

// ----- May be supported in the future -----
//
// function _ticketLimitSchemaBuilder() {
//   return yup
//     .number()
//     .nullable()
//     .test('invalidTicketLimit', '', (ticketLimit, testContext) => {
//       const { defaultValue } = testContext?.options?.context ?? {};
//       const { id: ticketId } = testContext?.parent ?? {};

//       let errorMessage = '';

//       if (!isNil(ticketLimit)) {
//         if (ticketLimit < 1 || ticketLimit > MAX_CAPACITY) {
//           errorMessage = 'Inventory must be between 1 and 9999999.';
//         } else {
//           const eventSalesInfo = get(defaultValue, `_embedded.event-sales-info`, {}) ?? {};
//           const productSalesMap = get(eventSalesInfo, `productSalesMap.${ticketId}`, {});

//           if (
//             !isEmpty(productSalesMap) &&
//             productSalesMap?.saleQuantity > 0 &&
//             ticketLimit < productSalesMap?.saleQuantity
//           ) {
//             errorMessage = 'The entered value should be greater or equal to the number of tickets has been sold.';
//           }
//         }
//       }

//       return isEmpty(errorMessage)
//         ? true
//         : testContext.createError({
//             path: testContext.path,
//             message: errorMessage
//           });
//     });
// }
