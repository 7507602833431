import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { Row, Column, TextInput, Toggle, RadioButtonGroup, RadioButton } from 'carbon-components-react';
import NumberFormat from 'react-number-format';
import NotificationTooltip from '@app/commons/carbon-ui/atoms/NotificationTooltip';
import { ReservedSeatingCategorySelect } from '@events/components/reserved-seating-category-select';
import { SeatsIoChart } from '@season-management/components/seats-io-chart';

import get from 'lodash/get';
import isNil from 'lodash/isNil';

import Logo from '@assets/images/logo.png';
import GoFanBoxOffice from '@assets/images/gofan-boxoffice.svg';

import { DISTRIBUTION_CHANNEL } from '@api/model/request/ProductRequest';
import ColorPilette from '@app/commons/color-pilette/color-pilette.component';

import { TICKET_TYPE_STRINGS } from '@account-products/constants';
import type { RateDTO } from '@gofan/api/rates';
import type { ReservedSeatingChartDTO } from '@gofan/api/reserved-seating';
import { ReservedSeatingService } from '@gofan/api/reserved-seating';
import { calculateFee } from '@gofan/api/account-products';

import './add-ticket-type-form.scss';

export interface TicketTypeFormConfig {
  unlimitedTicket: boolean;
  disabled: boolean;
  disabledAccountPaid: boolean;
  disabledCreateBoxOffice: boolean;
  hideEnableBoxOffice?: boolean;
  isInternalUser: boolean;
  isSeasonSetting: boolean;
  isEndEvent: boolean;
  hasSold: (productId: string) => boolean;
  getProductSalesInfo: (productId: string) => any;
}

interface TicketTypeFormProps {
  type: 'default' | 'ticket';
  config?: Partial<TicketTypeFormConfig>;
  boxOfficeCustomer?: boolean;
  rates: RateDTO[];
  onShowNotification?: Function;
  seatsIoChartKey?: string;
  isSeasonFlow?: boolean;
}

export const AddTicketTypeForm = ({
  type = 'default',
  config,
  boxOfficeCustomer,
  rates,
  onShowNotification,
  seatsIoChartKey,
  isSeasonFlow = false
}: TicketTypeFormProps) => {
  const {
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    trigger
  } = useFormContext<any>();
  const [seatsIoChart, setSeatsIoChart] = useState<ReservedSeatingChartDTO>();
  const customColor = watch('customColor');
  const reservedSeating = watch('reservedSeating');
  const seatsIoCategory = watch('seatsIoCategory');
  const goFanTicketName = useRef(watch('ticketName'));
  const ticketIds = watch('ticketIds');
  const isBoxOfficeTicketEnabled = watch('boxOfficeTicket.enabled');
  const groupDistributionChannel = watch('groupDistributionChannel');
  const isCreateWithBoxOffice = groupDistributionChannel?.includes(DISTRIBUTION_CHANNEL.BOXOFFICE);

  const disabled = !!config?.disabled;

  const disabledByHasSoldGoFan = type === 'ticket' && !config?.isSeasonSetting && config?.hasSold?.(watch('id'));

  const disabledByHasSoldBoxOffice =
    type === 'ticket' && !config?.isSeasonSetting && config?.hasSold?.(watch('boxOfficeTicket.id'));
  const disableAttendeeFeeToggle = !!config?.disabledAccountPaid; // disable attendee pays fee toggle if account has daily payment cycle, attendee must pay fee for daily payment cycle

  useEffect(() => {
    if (typeof seatsIoChartKey === 'string') _fetchReservedSeatingChart(seatsIoChartKey);

    async function _fetchReservedSeatingChart(chartKey: string) {
      await ReservedSeatingService.getReservedSeatingChart(chartKey).then(res => {
        setSeatsIoChart(res.data);
      });
    }
  }, [seatsIoChartKey]);

  const Divider = ({ hidden }: { hidden: boolean }) => (!hidden ? <div className='section-divider' /> : <div />);

  return (
    <Row className='add-ticket-type-form'>
      <Column lg={6} className={classNames({ 'add-ticket-type-form__divider': boxOfficeCustomer })}>
        <div className='container'>
          <div className='gs--productive-heading-02-semibold gs--margin-bottom-sp3'>
            {isSeasonFlow ? TICKET_TYPE_STRINGS.GOFAN_TICKET_TYPES : TICKET_TYPE_STRINGS.GOFAN_TICKETS}
          </div>
          <img className='gofan-logo gs--margin-bottom-sp5' alt='GoFan Logo' src={Logo} />

          {!isNil(seatsIoChartKey) && (
            <Controller
              name='reservedSeating'
              control={control}
              render={({ field }) => (
                <RadioButtonGroup
                  defaultSelected={!field.value ? 'general-admission' : 'reserved-seating'}
                  name='radio-button-group'
                  valueSelected={!field.value ? 'general-admission' : 'reserved-seating'}
                  onChange={(value: string) => {
                    field.onChange(value === 'reserved-seating');
                    if (value !== 'reserved-seating') setValue('seatsIoCategory', undefined);
                    if (value === 'reserved-seating') {
                      setValue('groupDistributionChannel', ['GoFan']);
                      if (ticketIds.length > 1) setValue('ticketIds', [watch('ticketIds')[0]]);
                    }
                  }}
                  className='gs--margin-bottom-sp6'
                >
                  <RadioButton id='radio-ga' labelText='General Admission' value='general-admission' />
                  <RadioButton id='radio-rs' labelText='Reserved Seating' value='reserved-seating' />
                </RadioButtonGroup>
              )}
            />
          )}

          <div className='gs--margin-bottom-sp5'>
            <div
              className={classNames('gs--padding-bottom__sp3', {
                'gs--body-short-01-semibold': !isSeasonFlow,
                'gs--text-01': !isSeasonFlow,
                'gs--label-01': isSeasonFlow,
                'gs--text-02': isSeasonFlow
              })}
            >
              {TICKET_TYPE_STRINGS.TICKET_NAME}
            </div>

            <Row>
              <Column lg={8}>
                <Controller
                  name='ticketName'
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      hideLabel
                      id='ticketName'
                      style={
                        {
                          borderLeft: `${customColor ? `solid 6px #${customColor}` : 'none'}`
                        } as React.CSSProperties
                      }
                      ref={field.ref}
                      labelText={TICKET_TYPE_STRINGS.TICKET_NAME}
                      placeholder={TICKET_TYPE_STRINGS.TICKET_NAME_PLACEHOLDER}
                      value={field.value}
                      disabled={disabled || disabledByHasSoldGoFan}
                      invalid={!!get(errors, 'ticketName')}
                      invalidText={get(errors, 'ticketName.message')}
                      onChange={({ target: { value } }) => field.onChange(value)}
                      onBlur={() => {
                        const value = `${field.value ?? ''}`.trim();
                        if (goFanTicketName.current !== value && !disabledByHasSoldBoxOffice) {
                          setValue('boxOfficeTicket.ticketName', value);
                          trigger('boxOfficeTicket.ticketName');
                        }
                        goFanTicketName.current = value;
                        field.onChange(value);
                        field.onBlur();
                      }}
                    />
                  )}
                />
              </Column>
            </Row>
          </div>

          {!isNil(seatsIoChartKey) && reservedSeating === true && (
            <Controller
              name='seatsIoCategory'
              control={control}
              render={({ field }) => (
                <ReservedSeatingCategorySelect
                  chartKey={seatsIoChartKey}
                  value={field.value}
                  onChange={(val: string) => {
                    field.onChange(val);
                  }}
                  initialSelectedItem={seatsIoCategory}
                  // invalid={!isEmpty(formControl.getError(`${path}.seatsIoCategory`))}
                  // invalidText={formControl.getError(`${path}.seatsIoCategory`)}
                />
              )}
            />
          )}

          <Divider hidden={isSeasonFlow} />
          <div className='gs--margin-bottom-sp5'>
            <div
              className={classNames('gs--margin-top-sp5', 'gs--padding-bottom__sp3', 'gs--text-01', {
                'gs--body-short-01-semibold': !isSeasonFlow,
                'gs--productive-heading-02': isSeasonFlow
              })}
            >
              {TICKET_TYPE_STRINGS.SETTINGS}
            </div>
            <Row>
              <Column lg={4}>
                <Controller
                  name='accountPaid'
                  control={control}
                  render={({ field }) => (
                    <>
                      <div className='gs--label-01 gs--text-02 gf-attendee-title'>
                        {TICKET_TYPE_STRINGS.ATTENDEE_PAYS_FEE}
                      </div>
                      <NotificationTooltip
                        lowContrast
                        title=''
                        subtitle={TICKET_TYPE_STRINGS.ATTENDEE_PAYS_FEE_WA_SCHOOL}
                        triggerOn={disableAttendeeFeeToggle}
                      >
                        <Toggle
                          id='accountPaid'
                          ref={field.ref}
                          labelText=''
                          labelA={TICKET_TYPE_STRINGS.TOGGLE_OFF}
                          labelB={TICKET_TYPE_STRINGS.TOGGLE_ON}
                          toggled={!field.value}
                          disabled={disabled || disabledByHasSoldGoFan || disableAttendeeFeeToggle}
                          onToggle={value => {
                            field.onChange(!value);
                            _onCalculateFee({
                              accountPaid: !value,
                              types: [DISTRIBUTION_CHANNEL.GOFAN, DISTRIBUTION_CHANNEL.BOXOFFICE]
                            });
                            _onShowNotification(!value);
                          }}
                        />
                      </NotificationTooltip>
                    </>
                  )}
                />
              </Column>
              {type === 'ticket' && (
                <Column lg={4}>
                  <Controller
                    name='generateLink'
                    control={control}
                    render={({ field }) => (
                      <>
                        <div className='gs--label-01 gs--text-02'>{TICKET_TYPE_STRINGS.GENERATE_LINK}</div>
                        <Toggle
                          id='generateLink'
                          ref={field.ref}
                          labelText=''
                          labelA={TICKET_TYPE_STRINGS.TOGGLE_OFF}
                          labelB={TICKET_TYPE_STRINGS.TOGGLE_ON}
                          toggled={field.value}
                          disabled={disabled}
                          onToggle={value => {
                            field.onChange(value);
                          }}
                        />
                      </>
                    )}
                  />
                </Column>
              )}
            </Row>
          </div>
          <div className='gs--margin-bottom-sp5'>
            <Row>
              <Column lg={4}>
                <Controller
                  name='ticketPrice'
                  control={control}
                  render={({ field }) => (
                    <NumberFormat
                      id='ticketPrice'
                      getInputRef={field.ref}
                      customInput={TextInput}
                      labelText={TICKET_TYPE_STRINGS.TICKET_PRICE}
                      placeholder={TICKET_TYPE_STRINGS.CURRENCY_PLACEHOLDER}
                      prefix='$'
                      decimalScale={2}
                      thousandSeparator
                      fixedDecimalScale
                      allowNegative={false}
                      value={field.value}
                      disabled={disabled || disabledByHasSoldGoFan}
                      invalid={!!get(errors, 'ticketPrice')}
                      invalidText={get(errors, 'ticketPrice.message')}
                      onValueChange={({ value }) => field.onChange(value)}
                      onBlur={() => {
                        if (!disabledByHasSoldBoxOffice) {
                          setValue('boxOfficeTicket.ticketPrice', field.value);
                          trigger('boxOfficeTicket.ticketPrice');
                        }
                        _onCalculateFee({
                          accountPaid: getValues('accountPaid'),
                          types: [DISTRIBUTION_CHANNEL.GOFAN, DISTRIBUTION_CHANNEL.BOXOFFICE]
                        });
                        _onShowNotification(watch('accountPaid'));
                        field.onBlur();
                      }}
                    />
                  )}
                />
              </Column>
              {type === 'default' && (
                <>
                  <Column lg={4} className='gs--padding-left__sp0 gs--padding-right__sp0'>
                    <Controller
                      name='payToSchool'
                      control={control}
                      render={({ field }) => (
                        <NumberFormat
                          id='payToSchool'
                          getInputRef={field.ref}
                          customInput={TextInput}
                          labelText={TICKET_TYPE_STRINGS.PAY_TO_SCHOOL}
                          placeholder={TICKET_TYPE_STRINGS.CURRENCY_PLACEHOLDER}
                          prefix='$'
                          decimalScale={2}
                          thousandSeparator
                          fixedDecimalScale
                          disabled
                          value={field.value}
                        />
                      )}
                    />
                  </Column>
                  <Column lg={4}>
                    <Controller
                      name='fanPrice'
                      control={control}
                      render={({ field }) => (
                        <NumberFormat
                          id='fanPrice'
                          getInputRef={field.ref}
                          customInput={TextInput}
                          labelText={TICKET_TYPE_STRINGS.FAN_PRICE}
                          placeholder={TICKET_TYPE_STRINGS.CURRENCY_PLACEHOLDER}
                          prefix='$'
                          decimalScale={2}
                          thousandSeparator
                          fixedDecimalScale
                          disabled
                          value={field.value}
                        />
                      )}
                    />
                  </Column>
                </>
              )}
              {type === 'ticket' && !config?.unlimitedTicket && (
                <Column lg={4}>
                  <Controller
                    name='limit'
                    control={control}
                    render={({ field }) => (
                      <NumberFormat
                        id='limit'
                        getInputRef={field.ref}
                        customInput={TextInput}
                        labelText={TICKET_TYPE_STRINGS.ALLOTMENT}
                        placeholder='0'
                        maxLength={7}
                        decimalScale={0}
                        fixedDecimalScale
                        allowNegative={false}
                        value={field.value}
                        disabled={disabled}
                        invalid={!!get(errors, 'limit')}
                        invalidText={get(errors, 'limit.message')}
                        onValueChange={({ value }) => field.onChange(value)}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                </Column>
              )}
            </Row>
          </div>

          {reservedSeating !== true && (
            <>
              <Divider hidden={isSeasonFlow} />
              <div className='gs--margin-top-sp5'>
                <div
                  className={classNames('gs--padding-bottom__sp3', 'gs--text-01', {
                    'gs--body-short-01-semibold': !isSeasonFlow,
                    'gs--productive-heading-02': isSeasonFlow
                  })}
                >
                  {TICKET_TYPE_STRINGS.ASSIGN_COLOR}
                </div>
                <Controller
                  name='customColor'
                  control={control}
                  render={({ field }) => (
                    <>
                      <div className='gs--label-01 gs--text-02 gs--margin-bottom-sp5'>
                        {isSeasonFlow
                          ? TICKET_TYPE_STRINGS.ASSIGN_COLOR_DESCRIPTION_SEASON
                          : TICKET_TYPE_STRINGS.ASSIGN_COLOR_DESCRIPTION}
                      </div>
                      <ColorPilette
                        key='customColor'
                        visible
                        disabled={disabled}
                        defaultColor=''
                        color={field.value}
                        onChange={color => field.onChange(color)}
                      />
                      <input ref={field.ref} className='invisibility-input' />
                    </>
                  )}
                />
              </div>
            </>
          )}
        </div>
      </Column>

      {!isNil(seatsIoChart) && reservedSeating === true && (
        <Column lg={6}>
          <div className='seats-io-chart-wrapper'>
            <SeatsIoChart seatsIoChart={seatsIoChart} />
          </div>

          <Divider hidden={isSeasonFlow} />

          <div className='custom-ticker-color-picker'>
            <div className='gs--body-short-01-semibold gs--text-01 gs--padding-bottom__sp3'>
              {TICKET_TYPE_STRINGS.ASSIGN_COLOR}
            </div>
            <Controller
              name='customColor'
              control={control}
              render={({ field }) => (
                <>
                  <p className='gs--label-01 gs--text-02 gs--margin-bottom-sp5'>
                    {TICKET_TYPE_STRINGS.ASSIGN_COLOR_DESCRIPTION}
                  </p>
                  <ColorPilette
                    key='customColor'
                    visible
                    disabled={disabled}
                    defaultColor=''
                    color={field.value}
                    onChange={color => field.onChange(color)}
                  />
                  <input ref={field.ref} className='invisibility-input' />
                </>
              )}
            />
          </div>
        </Column>
      )}

      {boxOfficeCustomer && reservedSeating !== true && (
        <Column lg={6}>
          <div className='box-office-container'>
            <div className='gs--productive-heading-02-semibold gs--margin-bottom-sp3'>
              {isSeasonFlow ? TICKET_TYPE_STRINGS.ADD_BOX_OFFICE_TICKET_TYPE : TICKET_TYPE_STRINGS.BOX_OFFICE_TICKETS}
            </div>
            <img
              className={classNames('boxoffice-logo', {
                'gs--padding-bottom__sp5': isSeasonFlow,
                'gs--padding-top__sp6': isSeasonFlow
              })}
              alt='Box Office Logo'
              src={GoFanBoxOffice}
            />
            <Divider hidden={isSeasonFlow} />
            <div className='add-ticket-type-form__boxoffice-desc gs--body-short-01 gs--text-01 gs--margin-bottom-sp6'>
              {isSeasonFlow
                ? TICKET_TYPE_STRINGS.BOX_OFFICE_DESCRIPTION_SEASON
                : TICKET_TYPE_STRINGS.BOX_OFFICE_DESCRIPTION}
            </div>
            <div className='gs--margin-bottom-sp5'>
              <Controller
                name='groupDistributionChannel'
                control={control}
                render={({ field }) => {
                  const toggle = field.value?.includes(DISTRIBUTION_CHANNEL.BOXOFFICE) && !!isBoxOfficeTicketEnabled;
                  return (
                    <>
                      <div className='gs--label-01 gs--text-02'>
                        {!config?.disabledCreateBoxOffice
                          ? TICKET_TYPE_STRINGS.BOX_OFFICE_TOGGLE
                          : TICKET_TYPE_STRINGS.BOX_OFFICE_DISABLED}
                      </div>
                      <Toggle
                        id='groupDistributionChannel'
                        ref={field.ref}
                        labelText=''
                        labelA={TICKET_TYPE_STRINGS.TOGGLE_OFF}
                        labelB={TICKET_TYPE_STRINGS.TOGGLE_ON}
                        value={toggle}
                        toggled={toggle}
                        onToggle={value => {
                          if (value) {
                            field.onChange([DISTRIBUTION_CHANNEL.GOFAN, DISTRIBUTION_CHANNEL.BOXOFFICE]);

                            if (watch('ticketIds').length === 1 && watch('id').includes('-')) {
                              setValue('ticketIds', [...watch('ticketIds'), watch('id').split('-')[1]]);
                            }
                          } else {
                            field.onChange([DISTRIBUTION_CHANNEL.GOFAN]);
                          }
                          setValue('boxOfficeTicket.enabled', value);
                          setValue('boxOfficeTicket.ticketName', watch('ticketName'));
                          setValue('boxOfficeTicket.ticketPrice', watch('ticketPrice'));
                          trigger(['boxOfficeTicket.ticketName', 'boxOfficeTicket.ticketPrice']);
                        }}
                        disabled={config?.disabledCreateBoxOffice}
                      />
                    </>
                  );
                }}
              />
            </div>
            <div className='gs--margin-bottom-sp5'>
              <Row>
                <Column lg={8}>
                  <Controller
                    name='boxOfficeTicket.ticketName'
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        id='boTicketName'
                        ref={field.ref}
                        labelText={TICKET_TYPE_STRINGS.BO_TICKET_NAME}
                        placeholder={TICKET_TYPE_STRINGS.TICKET_NAME_PLACEHOLDER}
                        value={field.value}
                        invalid={!!get(errors, 'boxOfficeTicket.ticketName')}
                        invalidText={get(errors, 'boxOfficeTicket.ticketName.message')}
                        disabled={!isCreateWithBoxOffice || disabledByHasSoldBoxOffice}
                        onChange={({ target: { value } }) => field.onChange(value)}
                        onBlur={() => {
                          const value = `${field.value ?? ''}`.trim();
                          field.onChange(value);
                          field.onBlur();
                        }}
                      />
                    )}
                  />
                </Column>
              </Row>
            </div>
            <div className='gs--margin-bottom-sp5'>
              <Row>
                {type === 'ticket' && (
                  <Column lg={5}>
                    <Controller
                      name='boxOfficeTicket.ticketPrice'
                      control={control}
                      render={({ field }) => (
                        <NumberFormat
                          id='boxOfficeTicket.ticketPrice'
                          getInputRef={field.ref}
                          customInput={TextInput}
                          labelText={TICKET_TYPE_STRINGS.TICKET_PRICE}
                          placeholder={TICKET_TYPE_STRINGS.CURRENCY_PLACEHOLDER}
                          prefix='$'
                          decimalScale={2}
                          thousandSeparator
                          fixedDecimalScale
                          allowNegative={false}
                          value={field.value}
                          invalid={!!get(errors, 'boxOfficeTicket.ticketPrice')}
                          invalidText={get(errors, 'boxOfficeTicket.ticketPrice.message')}
                          disabled={!isCreateWithBoxOffice || disabledByHasSoldBoxOffice}
                          onValueChange={({ value }) => field.onChange(value)}
                          onBlur={() => {
                            field.onBlur();
                          }}
                        />
                      )}
                    />
                  </Column>
                )}
                {type === 'default' && (
                  <Column lg={5}>
                    <Controller
                      name='boxOfficeTicket.fanPrice'
                      control={control}
                      render={({ field }) => (
                        <NumberFormat
                          getInputRef={field.ref}
                          id='boxOfficeTicket.fanPrice'
                          customInput={TextInput}
                          labelText={TICKET_TYPE_STRINGS.FAN_PRICE}
                          placeholder={TICKET_TYPE_STRINGS.CURRENCY_PLACEHOLDER}
                          prefix='$'
                          decimalScale={2}
                          thousandSeparator
                          fixedDecimalScale
                          disabled
                          value={field.value}
                        />
                      )}
                    />
                  </Column>
                )}
              </Row>
            </div>
          </div>
        </Column>
      )}
    </Row>
  );

  function _setFee({ accountPaid, ticketPrice, name }: { accountPaid: boolean; ticketPrice: any; name?: string }) {
    if (type === 'ticket') return;

    const result = calculateFee({
      rates,
      ticketPrice,
      accountPaid
    });

    const fee = (result?.fee ?? 0).toFixed(2);
    const fanPrice = parseFloat(`${result?.fanPrice ?? 0}`);
    const payToSchool = parseFloat(`${result?.payToSchool ?? 0}`);

    const path = name ? `${name}.` : '';
    // apply for season flow or others flow which has optional settings in the future
    if (!isSeasonFlow) {
      setValue(`${path}fee`, +fee);
    }
    setValue(`${path}fanPrice`, +fanPrice);
    setValue(`${path}payToSchool`, +payToSchool);
  }

  function _onCalculateFee({ accountPaid, types }: { accountPaid: boolean; types: any[] }) {
    if (type === 'ticket') return;
    const ticketPrice = watch('ticketPrice');

    if (types?.includes(DISTRIBUTION_CHANNEL.GOFAN)) {
      _setFee({ ticketPrice, accountPaid });
    }

    if (types?.includes(DISTRIBUTION_CHANNEL.BOXOFFICE)) {
      const boxOfficeTicketPrice = watch('boxOfficeTicket.ticketPrice');
      _setFee({ ticketPrice: boxOfficeTicketPrice, accountPaid, name: 'boxOfficeTicket' });
    }
  }

  function _onShowNotification(accountPaid?: boolean) {
    if (typeof onShowNotification === 'function') {
      onShowNotification(accountPaid);
    }
  }

  function _getLabelBoxOfficeToggle() {
    if (!config?.disabledCreateBoxOffice) {
      return isSeasonFlow ? TICKET_TYPE_STRINGS.BOX_OFFICE_TOGGLE_SEASON : TICKET_TYPE_STRINGS.BOX_OFFICE_TOGGLE;
    }

    return TICKET_TYPE_STRINGS.BOX_OFFICE_DISABLED;
  }
};
