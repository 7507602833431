import BaseModel from './BaseModel';

class PurchasedTicket extends BaseModel {
  constructor({
    accessToken,
    chargeId,
    purchasedTicketId,
    createdAt,
    email,
    eventId,
    last4,
    orderId,
    refundedAt,
    refundedAmount,
    ticketName,
    total,
    orderAccessToken,
    price,
    chargeTotalAmount
  }) {
    super({
      accessToken,
      chargeId,
      purchasedTicketId,
      createdAt,
      email,
      eventId,
      last4,
      orderId,
      refundedAt,
      refundedAmount,
      ticketName,
      total,
      orderAccessToken,
      price,
      chargeTotalAmount
    });
  }

  get chargeId() {
    return this.raw.chargeId;
  }

  get purchasedTicketId() {
    return this.raw.purchasedTicketId;
  }

  get createdAt() {
    return this.raw.createdAt;
  }

  get email() {
    return this.raw.email;
  }

  get eventId() {
    return this.raw.eventId;
  }

  get last4() {
    return this.raw.last4;
  }

  get orderId() {
    return this.raw.orderId;
  }

  get refundedAt() {
    return this.raw.refundedAt;
  }

  get refundedAmount() {
    return this.raw.refundedAmount;
  }

  get ticketName() {
    return this.raw.ticketName;
  }

  get total() {
    return this.raw.total;
  }

  get accessToken() {
    return this.raw.accessToken;
  }

  get orderAccessToken() {
    return this.raw.orderAccessToken;
  }

  get price() {
    return this.raw.price;
  }

  get chargeTotalAmount() {
    return this.raw.chargeTotalAmount;
  }
}
export default PurchasedTicket;
