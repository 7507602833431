import { css } from 'styled-components';
import commonStyles, {
  SOURCESANPRO_REGULAR
} from '../../../../assets/styles/common';

export default {
  styledLabel: css`
    ${commonStyles.fieldLabel};
  `,
  description: css`
    ${SOURCESANPRO_REGULAR}
    font-size: 14px;
    margin-top: 10px;
  `,
  optionalLabel: css`
    ${commonStyles.body};
  `,
  boundingBox: css`
    height: 210px;
    padding: 16px;
    margin-top: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 2px solid #ccc;
  `
};
