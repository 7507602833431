import ErrorDTO from '../dto/ErrorDTO';
import VenueDTO from '../dto/VenueDTO';
import VenueSeatsDTO from '../dto/VenueSeatsDTO';
import axios from '../api/axios';
import { VENUE_SEATS_URI, VENUE_URI } from '../api/api';
import SeatInfo from '../model/SeatInfo';

const getVenueInformation = (id = '') => {
  const uri = VENUE_URI.replace('{id}', id);
  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new VenueDTO(response);
    })
    .catch(e => new ErrorDTO(e));
};

const getVenueSeatsInformation = (venueId = '', eventId = null, seasonId = null) => {
  const uri = `${VENUE_SEATS_URI.replace('{id}', venueId)}`;
  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      var dictionary = {};
      response.forEach((seatInfo) => dictionary[seatInfo.seat.seatsIoLabel] = new SeatInfo({ seatInfo, eventId, seasonId, seatStatus: "AVAILABLE" }));
      return dictionary;
    })
    .catch(e => new ErrorDTO(e));
}

export default { getVenueInformation, getVenueSeatsInformation };
