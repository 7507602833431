import type { PageResponseDTO } from '../shared';

export enum PromotionTypeEnum {
  ACCESS_CODE = 'ACCESS_CODE',
  PROMOTION_CODE = 'PROMOTION_CODE'
}

export enum PromotionLevelEnum {
  EVENT_LEVEL = 'EVENT_LEVEL',
  TICKET_LEVEL = 'TICKET_LEVEL'
}

export enum AccessLevelEnum {
  TICKET = 'TICKET',
  EVENT = 'EVENT',
  SEASON = 'SEASON'
}
export enum DiscountTypeEnum {
  PERCENT = 'percent',
  AMOUNT = 'amount'
}

export enum PromotionLockStatus {
  USED = 'USED',
  HOLD = 'HOLD',
  NONE = 'NONE'
}

export type AccessLevelType = 'TICKET' | 'EVENT' | 'SEASON';

export interface PromotionDTO {
  accessLevel: AccessLevelEnum;
  code: string;
  deleted?: boolean;
  discountType?: DiscountTypeEnum;
  endDateTime?: string;
  id?: number | string;
  label?: string;
  limit?: number;
  name?: string;
  productAssociations?: ProductAssociations[];
  required?: boolean;
  startDateTime?: string;
  value?: number;
  createdAt?: string;
}

export type ProductAssociations = {
  productId: number;
  productSpecificLimit: number;
};

export type ProductAssociationsInsight = {
  productId: number;
  productName: string;
  numberOfTimesUsed: number;
};

export interface PromotionsSearchParams {
  accessLevels?: AccessLevelEnum[];
  code?: string;
  keyword?: string;
  eventId?: any;
  productIds?: any[];
  required?: boolean;

  sortBy?: any;
  page: number;
  pageSize: number;
  productId?: number;
  expands?: any[];
}

export interface PromotionInsightDTO {
  promoCodeId?: string | number;
  promotionLimit: number;
  numberOfTimesUsed: number;
  productAssociations: ProductAssociationsInsight[];
}

export interface PromotionsUpdateDTO {
  required?: boolean;
  discountType?: DiscountTypeEnum | '';
  value?: number;
  endDateTime?: string;
  eventId?: number | string;
  limit?: number;
  productIds?: string[];
  startDateTime?: string;
}

export interface PromotionPageResponseDTO extends PageResponseDTO {
  content?: PromotionDTO[];
}

export interface PromotionDuplicateRequest {
  eventId: number;
  promoCodes: string[];
  required?: boolean;
}

export interface BulkPromotionRequest {
  id: string;
  authToken: string;
  codes: PromotionDTO[];
}

export interface BulkPromotionResponse {
  status: string;
  actual: number;
  expectedCodeCount: number;
}

export interface BulkDeletePromotions {
  accessLevel?: AccessLevelEnum;
  eventId?: number;
  flatfileBatchId?: string;
  required?: boolean;
  seasonId?: number;
  seasonRenewalIds?: number[];
  ticketIds?: string[];
}

export interface PromotionLockDTO {
  createAt: string;
  createdBy: string;
  eventId: number;
  id: string;
  promoCode: string;
  promoId: number;
  quantityLocked: number;
  quantityRequested: number;
  seasonId: number;
  state: string;
  updateAt: string;
  updatedBy: string;
}

export interface PromotionLockSearch {
  productIds: number[];
  required: boolean;
  status?: PromotionLockStatus;
}

export interface JobPromotionInfoDTO {
  endTime: string;
  errors: string[];
  jobId: number;
  name: string;
  params: any;
  startTime: string;
  status: 'COMPLETED' | 'STARTING' | 'STARTED' | 'STOPPING' | 'STOPPED' | 'FAILED' | 'ABANDONED' | 'UNKNOWN';
}

export interface PromotionCount {
  totalAccessCode: number;
  hasAccessCode: boolean;
  latestAccessCode: PromotionDTO;
  totalPromotionCode: number;
  hasPromotionCode: boolean;
  latestPromotionCode: PromotionDTO;
}
