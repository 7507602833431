import { TextInput } from 'carbon-components-react';
import NumberFormat from 'react-number-format';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

const NumberFormatInput = ({
  id,
  labelText,
  light,
  placeholder,
  size,
  value,
  decimalScale,
  currency,
  onChange,
  invalid,
  invalidText,
  onBlur,
  maxDigits,
  disabled,
  allowNegative,
  getInputRef,
  ...props
}) => (
  <NumberFormat
    getInputRef={getInputRef}
    isAllowed={values => {
      const { formattedValue, floatValue } = values;
      return formattedValue === '' || floatValue < 10 ** maxDigits;
    }}
    id={id}
    labelText={labelText}
    light={light}
    placeholder={placeholder}
    size={size}
    customInput={TextInput}
    value={value}
    prefix={currency ? '$' : ''}
    decimalScale={currency ? decimalScale : 0}
    thousandSeparator={currency}
    onValueChange={onChange}
    invalid={invalid}
    fixedDecimalScale
    invalidText={invalidText}
    onBlur={onBlur}
    disabled={disabled}
    allowNegative={allowNegative}
    {...props}
  />
);

NumberFormatInput.defaultProps = {
  value: '',
  decimalScale: 2,
  currency: true,
  onChange: () => null,
  id: 'id',
  labelText: '',
  light: true,
  placeholder: '',
  size: undefined,
  invalid: false,
  invalidText: 'invalid text',
  onBlur: () => null,
  getInputRef: () => null,
  maxDigits: 7,
  disabled: false,
  allowNegative: false
};

NumberFormatInput.propTypes = {
  currency: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelText: PropTypes.string,
  light: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  decimalScale: PropTypes.number,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  invalidText: PropTypes.string,
  onBlur: PropTypes.func,
  getInputRef: PropTypes.func,
  maxDigits: PropTypes.number,
  disabled: PropTypes.bool,
  allowNegative: PropTypes.bool
};

export default NumberFormatInput;
