// @flow
class VenueDTO {
  constructor({ id, name, label, streetAddress, state, zip, city, location }) {
    this.raw = {
      id,
      name,
      label,
      streetAddress,
      state,
      zip,
      city,
      location,
    };
  }

  get id() {
    return this.raw.id;
  }

  get name() {
    return this.raw.name;
  }

  get label() {
    return this.raw.label;
  }

  get streetAddress() {
    return this.raw.streetAddress;
  }

  get state() {
    return this.raw.state;
  }

  get zip() {
    return this.raw.zip;
  }

  get city() {
    return this.raw.city;
  }

  get location() {
    return this.raw.location;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      label: this.label,
      streetAddress: this.streetAddress,
      state: this.state,
      zip: this.zip,
      city: this.city,
      location: this.location,
    };
  }
}

export default VenueDTO;
