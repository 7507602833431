export const MARGIN_LEFT_SUBMIT_ROW = 16;
export const MAXIMUM_RATE_NAME_CHARACTERS = 32;
export const LABEL_SIZE_CASHLESS_CHECKBOXES = 14;
export const FONT_WEIGHT_DESCRIPTION_CHECK_BOX = 600;
export const FONT_SIZE_DESCRIPTION_CHECK_BOX = 14;
export const YES = 'Yes';
export const NO = 'No';
export const DISABLE = 'Disable';
export const ENABLE = 'Enable';
export const RATE_DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_FORMAT = 'YYYYMMDD';
export const RATE_MODE = {
  EDIT: 'edit',
  NEW: 'new'
};
export const RATE_EXPANDS = ['exceptions'];
export const RATE_INITIAL = {
  label: '',
  cashless: false,
  minPrice: '',
  maxPrice: '',
  postSeason: false,
  startDate: new Date(),
  endDate: new Date(),
  rateAmount: '',
  ratePercent: '',
  productTypeIds: [],
  exceptions: [],
  status: 'ENABLED',
  states: [],
  huddleIds: [],
  distributionChannels: []
};
export const RATE_FIELDS_FORM = {
  label: 'label',
  cashless: 'cashless',
  minPrice: 'minPrice',
  maxPrice: 'maxPrice',
  postSeason: 'postSeason',
  startDate: 'startDate',
  endDate: 'endDate',
  rateAmount: 'rateAmount',
  ratePercent: 'ratePercent',
  productTypeIds: 'productTypeIds',
  exceptions: 'exceptions',
  schools: 'huddleIds',
  status: 'status',
  states: 'states',
  distributionChannels: 'distributionChannels'
};
export const RATE_STRINGS = {
  MESSAGES: {
    ERRORS: {
      RATE_NAME_REQUIRED: 'Rate name is required.',
      RATE_NAME_VALIDATION:
        'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) with a maximum of 32 characters long.',
      RATE_EXIST: 'Name already exists.',
      RATE_AMOUNT_REQUIRED: 'Rate ($) is required.',
      RATE_PERCENT_REQUIRED: 'Rate (%) is required.',
      PRODUCT_TYPE_REQUIRED: 'Product Type is required.',
      MIN_PRICE_REQUIRED: 'Min Price ($) is required.',
      MIN_PRICE_GREATER_THAN_MAX_PRICE: 'The entered value should be lower than the value of Max Price ($) field.',
      MAX_PRICE_REQUIRED: 'Max Price ($) is required.',
      MAX_PRICE_LOWER_THAN_MIN_PRICE: 'The entered value should be greater than the value of Min Price ($) field.',
      STARTS_AT_REQUIRED: 'Starts At is required.',
      STARTS_AT_OLDER_ENDS_AT: 'Starts At must come before Ends At.',
      ENDS_AT_EARLIER_STARTS_AT: 'Ends At must come after Starts At.',
      ACCOUNT_SELECTED: 'Account already selected.',
      RATE_DISTRIBUTION_CHANNEL_REQUIRED: 'Distribution channel is required'
    },
    ADD_SUCCESS: ' is added.',
    UPDATE_SUCCESS: ' is updated.',
    NOT_HAVE_PERMISSION_ADD_RATE: 'You do not have the permission to add rate.'
  },
  PLACE_HOLDERS: {
    SEARCH_ACCOUNT_EXCEPTION: 'Search by Account Huddle ID, Account Name',
    HEADER_TITLE: 'All fields are required, unless otherwise noted.'
  },
  TITLES: {
    RATE_LABEL: 'Label',
    RATE_AMOUNT: 'Rate ($)',
    RATE_PERCENT: 'Rate (%)',
    RATE_PRODUCT: 'Product Type',
    RATE_MIN_PRICE: 'Min Price ($)',
    RATE_MAX_PRICE: 'Max Price ($)',
    RATE_STARTS_AT: 'Starts At',
    RATE_ENDS_AT: 'Ends At',
    RATE_CASHLESS: 'Cashless',
    RATE_POSTSEASON: 'Postseason',
    RATE_ACCOUNT_EXCEPTION: 'Accounts Exception',
    RATE_SCHOOLS: 'Schools',
    RATES_BREADCUM: 'Rates',
    RATES_STATUS: 'Status',
    RATES_STATES: 'States',
    RATES_DISTRIBUTION_CHANNEL: 'Distribution channel'
  },
  PERMISSIONS: {
    ADD: 'create.rate',
    EDIT: 'edit.rate'
  }
};
export const RATE_DISTRIBUTION_CHANNELS = {
  gofan: 'GoFan',
  box_office: 'BoxOffice'
};
export const DEFAULT_SORT_OPTION = {
  desc: true,
  id: 'updatedAt',
  key: 'updatedAt',
  value: 'updatedAt',
  text: 'Recently Updated'
};
export const SEARCH_PARAMS = {
  keyword: '',
  status: 'Active',
  pageSize: 20,
  pageIndex: 0,
  sortBy: [DEFAULT_SORT_OPTION]
};
