import { call, put, takeLatest } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import SeasonService from '@seasons/services/season.service';
import { SeasonRenewalService } from '@gofan/api';

import { addNotification, showAccountInfo } from '@app/pages/Root/actions';
import { onHandleError, onPrepareDataCompleted, onCreateSeasonRenewalCampaignCompleted } from './actions';
import { generateError, generateFailContent } from '@app/utils/alertUtils';

import { ON_CREATE_SEASON_RENEWAL_CAMPAIGN, ON_PREPARE_DATA } from './actionTypes';
import { UNEXPECTED_ERROR } from '@app/api/api/constants';

import type { SagaIterator } from 'redux-saga';
import type { SeasonDTO } from '@seasons/models/season.model';
import type { OnCreateSeasonRenewalCampaign, OnPrepareData } from './actionTypes';

const commonError = generateFailContent(UNEXPECTED_ERROR);

export function* onPrepareData(action: OnPrepareData): SagaIterator {
  try {
    const { seasonId } = action?.payload ?? {};
    if (isEmpty(seasonId)) return {};

    const season: SeasonDTO = yield call(SeasonService.getSeasonById, `${seasonId}`, ['account', 'activity'], true);
    const account = get(season, '_embedded.account');
    yield put(showAccountInfo(account));
    yield put(onPrepareDataCompleted({ season }));
    return { season };
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
    yield put(onHandleError());
    return error;
  }
}

export function* onCreateSeasonRenewalCampaign(action: OnCreateSeasonRenewalCampaign): SagaIterator {
  try {
    const seasonRenewal = yield call(SeasonRenewalService.createSeasonRenewal, action.payload);
    yield put(onCreateSeasonRenewalCampaignCompleted(seasonRenewal));
    return seasonRenewal;
  } catch (error) {
    yield put(addNotification(generateError(error) ?? commonError));
    yield put(onHandleError());
    return error;
  }
}

export function* watchPreparedData(): SagaIterator<void> {
  yield takeLatest(ON_PREPARE_DATA, onPrepareData);
}

export function* watchCreateSeasonRenewalCampaign(): SagaIterator<void> {
  yield takeLatest(ON_CREATE_SEASON_RENEWAL_CAMPAIGN, onCreateSeasonRenewalCampaign);
}

export default [watchPreparedData(), watchCreateSeasonRenewalCampaign()];
