import { get, chain, find } from 'lodash';

import { TeamRepository } from './teams.repository';
import { AccountMetricsServices, METRIC_TICKET_SALES, METRIC_TICKET_SOLD } from '@gofan/api/account-metrics';

import { getSchoolYearRange } from '@gofan/utils';
import { mapTeamResponseToTeamDto } from './teams.utils';

import type { AxiosResponse } from 'axios';
import type {
  TeamDTO,
  TeamRowData,
  TeamYearlyStats,
  TeamResponseDTO,
  TeamCreateRequestDTO,
  TeamUpdateRequestDTO,
  TeamPageResponseDTO,
  TeamSearchParams
} from './teams.model';
import type { UserDTO } from '../users';
import type { EventDTO } from '../events';

class TeamService {
  static getTeamById = (teamId: string): Promise<TeamDTO> =>
    TeamRepository.findTeamById(teamId).then(team => mapTeamResponseToTeamDto(team));

  static findTeamById = (teamId: string): Promise<TeamResponseDTO> => TeamRepository.findTeamById(teamId);

  static createTeam = (team: TeamCreateRequestDTO): Promise<TeamResponseDTO> => TeamRepository.createTeam(team);

  static updateTeam = (team: TeamUpdateRequestDTO): Promise<TeamResponseDTO> => TeamRepository.updateTeam(team);

  static deleteTeam = (teamId: string): Promise<AxiosResponse> => TeamRepository.deleteTeam(teamId);

  static searchTeam = (query: TeamSearchParams): Promise<TeamPageResponseDTO> => TeamRepository.searchTeam(query);

  static getTeamsByActivityId = (accountId: string, activityId: string, startYear?: number): Promise<TeamDTO[]> =>
    TeamRepository.searchTeam({ organizationId: accountId, activityId, startYear, size: 250 }).then(
      ({ content }) => content?.map(item => mapTeamResponseToTeamDto(item)) || []
    );

  static getAccountTeams = (organizationId: string, startYear: number): Promise<TeamResponseDTO[]> =>
    TeamRepository.searchTeam({ organizationId, startYear, size: 250 }).then(({ content }) => content || []);

  static getAccountsTeamList = ({
    accountId,
    schoolYear,
    schoolYearId,
    teamIds
  }: {
    accountId: string;
    activitiesIds?: string[];
    schoolYear?: string;
    schoolYearId?: number;
    teamIds?: string[];
  }): Promise<TeamRowData[]> =>
    new Promise((resolve, reject) => {
      try {
        const { periodStart, periodEnd } = getSchoolYearRange(schoolYear, 'YYYYMMDD', '/');
        TeamRepository.getAccountsTeams({ organizationId: accountId, startYear: schoolYearId, size: 999 })
          .then(({ content: teams }) => {
            const dataSource = teamIds ? teams.filter(team => teamIds.indexOf(team.id) >= 0) : teams;
            const teamListData: TeamRowData[] = chain(dataSource)
              .groupBy(({ activityId, activityName }) => [activityId, activityName])
              .map((groupedTeams, key) => ({
                id: key.split(',')[0],
                teams: key.split(',')[1],
                detail: {
                  teams: groupedTeams.map(team => ({
                    ...team,
                    name: team.gender.concat(' ', team.levelName)
                  }))
                }
              }))
              .value();

            AccountMetricsServices.getAccountMetrics({
              dataset: 'account_activities',
              schoolId: [accountId],
              activities: teamListData.map(({ id }) => String(id)),
              periodStart,
              periodEnd
            })
              .then(({ data }) => {
                if (data && data.byActivityName) {
                  const { byActivityName } = data;
                  const newTeams: TeamRowData[] = teamListData.map(team => ({
                    ...team,
                    detail: {
                      ...team.detail,
                      ticketSales: Number(get(byActivityName, [team.teams, METRIC_TICKET_SALES], 0)),
                      ticketSold: Number(get(byActivityName, [team.teams, METRIC_TICKET_SOLD], 0))
                    }
                  }));
                  resolve(newTeams);
                }
              })
              .finally(() => {
                resolve(teamListData);
              });
          })
          .catch(err => reject(err));
      } catch (err) {
        reject(err);
      }
    });

  static getTeamsYearlyStats = ({
    accountId,
    activityLabel,
    teamId,
    schoolYear
  }: {
    accountId: string;
    activityLabel?: string;
    teamId?: string;
    schoolYear?: string;
  }): Promise<TeamYearlyStats[]> =>
    new Promise(resolve => {
      try {
        const { periodStart, periodEnd } = getSchoolYearRange(schoolYear, 'YYYYMMDD');
        const defaultStats = [
          {
            ticketSales: 0.0,
            ticketSold: 0
          }
        ];
        if (teamId) {
          // TODO - Get Account Metrics by specific team (AGL)
          resolve(defaultStats);
        } else if (accountId && activityLabel) {
          AccountMetricsServices.getAccountMetrics({
            dataset: 'account_activities',
            schoolId: [accountId],
            activities: [activityLabel],
            periodStart,
            periodEnd
          })
            .then(({ data }) => {
              if (data) {
                const { byActivityName } = data;
                resolve([
                  {
                    ticketSales: get(byActivityName, [activityLabel, 'net_to_account'], 0) as number,
                    ticketSold: get(byActivityName, [activityLabel, 'ticket_net_count'], 0) as number
                  }
                ]);
              } else resolve(defaultStats);
            })
            .catch(() => {
              resolve(defaultStats);
            });
        } else resolve(defaultStats);
      } catch (err) {
        throw new Error(err);
      }
    });

  static getEventTeamsUnderCoach = ({
    event,
    user
  }: {
    event?: EventDTO;
    user: UserDTO;
  }): Promise<{ isEventTeamLedByCoach: boolean; eventTeams: TeamResponseDTO[] }> =>
    new Promise(resolve => {
      if (!event) {
        resolve({ isEventTeamLedByCoach: false, eventTeams: [] });
        return;
      }
      const accountContext = find(user.userAccountContexts, { accountId: event.accountId });
      if (accountContext?.teamIds?.length) {
        Promise.allSettled(accountContext.teamIds.map(teamId => TeamRepository.findTeamById(teamId))).then(results => {
          const teams: TeamResponseDTO[] = [];
          results.forEach(result => {
            if (result.status === 'fulfilled') {
              const { activityId, levelId, gender } = result.value;
              if (
                activityId === event.activityId &&
                event.levels.some(
                  ({ genders, levelId: eventLevelId }) => genders.includes(gender) && eventLevelId === levelId
                )
                // TODO: should check with team by season (seasonId)
              ) {
                teams.push(result.value);
              }
            }
          });
          resolve({
            isEventTeamLedByCoach: !!teams.length,
            eventTeams: teams
          });
        });
      } else resolve({ isEventTeamLedByCoach: false, eventTeams: [] });
    });
}

export { TeamService };
