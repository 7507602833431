import React from 'react';
import { Button } from 'carbon-components-react';

const StyledButton = ({ children, ...props }) => (
  <Button {...props}>
    {children}
  </Button>
);

export default StyledButton;
