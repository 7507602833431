import moment from 'moment';
import { get, isEmpty, reduce, omit } from 'lodash';
import type { SortBy } from '@old-components/basic-table/sorting';
import { sortStates } from '@old-components/basic-table/sorting';
import PageRequest from '@api/model/request/PageRequest';
import SortableRequest from '@api/model/request/SortableRequest';
import ExpandRequest from '@app/api/model/request/ExpandRequest';
import type { DropdownItemType } from '@app/modules/dashboard/views/sections/sales-reports/sales-reports.component';
import { formatMoney } from '@app/utils/objectUtils';
import type { AccountDTO } from '@gofan/api/accounts';
import defaultSchoolIcon from '@assets/images/gofan-icon.png';
import { getSchoolImage } from '@app/pages/EventInformationV2/helpers';
import type {
  EventSalesInfoDTO,
  EventSearchDTO,
  EventPageResponseDTO,
  EventDailySalesInfo,
  EventDTO,
  ImportEventDTO,
  EventConflictDTO,
  SalesReportInfoDTO,
  SalesReportInfoSearch,
  EventTicketSale,
  AccountMetricsQuery,
  SalesReportInfoAndStandardizeDataType,
  EventCancelDTO,
  EventRescheduleDTO,
  EventBulkUpload
} from '../models/event.model';
import EventRepository from '../repositories/events.repository';
import { FinancialService } from '@gofan/api/financial';
import { DATE_DUPLICATE_EVENT, formatDateTime } from '@app/utils/dateUtils';
import { FUNDRAISER } from '@gofan/constants';
import type { ActivityDTO } from '@gofan/api';

export type EventSearchParams = {
  body: EventSearchDTO;
  sortBy: SortBy;
  page: number;
  pageSize: number;
};

export interface Report {
  file: BlobPart;
  name: string;
}

export interface EventNameProps {
  event: EventDTO;
  accountIds: string[];
}

export interface AdditionalInfoParams {
  events: EventDTO[];
  eventDailySalesInfo: EventDailySalesInfo;
  awaySchools: AccountDTO[];
}

class EventService {
  searchEventByParams = (
    searchParams: EventSearchParams,
    expands = ['activity', 'levels', 'account', 'account-opponent']
  ): Promise<EventPageResponseDTO> => {
    const pageQuery = new PageRequest({
      pageIndex: searchParams.page,
      pageSize: searchParams.pageSize
    }).toQueryString();
    let sortBy = [
      {
        id: searchParams.sortBy.header,
        desc: searchParams.sortBy.sortDirection === sortStates.DESC
              || searchParams.sortBy.direction?.toUpperCase() === sortStates.DESC
      }
    ];
    if (searchParams.sortBy.header === 'archived') {
      sortBy = [
        {
          id: searchParams.sortBy.header,
          desc: searchParams.sortBy.sortDirection === sortStates.DESC
                || searchParams.sortBy.direction?.toUpperCase() === sortStates.DESC
        },
        {
          id: 'canceled',
          desc: searchParams.sortBy.sortDirection === sortStates.DESC
                || searchParams.sortBy.direction?.toUpperCase() === sortStates.DESC
        }
      ];
    }
    const sortableQuery =
      isEmpty(searchParams.sortBy) || this.isClientSort(searchParams.sortBy.header!)
        ? ''
        : new SortableRequest({
            sortBy
          }).toQueryString();
    const bodyRequest =
      searchParams.body.flatFileBatchId || searchParams.body.flatFileEditBatchIds
        ? omit(searchParams.body, ['accountIds', 'financialAccountIds'])
        : searchParams.body;
    const expandQuery = new ExpandRequest(expands).toQueryString();
    const queryStr = `?ts=${new Date().getTime()}&skipCache=true&${pageQuery}${sortableQuery}${expandQuery}`;
    return EventRepository.searchEventByParams(bodyRequest, queryStr);
  };

  getDailySalesInfo = (events: EventDTO[]): Promise<EventDailySalesInfo> => {
    if (isEmpty(events)) return Promise.resolve({});
    const eventIds = events.map(item => item.id);
    return EventRepository.getDailySalesInfo(eventIds).then(dailySalesInfo => {
      const salesInfo = reduce(
        dailySalesInfo,
        (res, item, key) => {
          const itemKeys = key.split('Event ');
          if (isEmpty(itemKeys) || itemKeys.length < 2) return res;
          const eventId = itemKeys[1];
          return {
            ...res,
            [eventId]: { ...item, eventId }
          };
        },
        {}
      );
      return salesInfo;
    });
  };

  isClientSort = (id: string) => ['grossToAccount', 'ticketGrossCount'].indexOf(id) !== -1;

  mapAdditionalInfoToEvent = ({ events, eventDailySalesInfo, awaySchools }: AdditionalInfoParams): EventDTO[] =>
    events.map((event: EventDTO) => {
      const dailySalesInfo = eventDailySalesInfo[event.id];
      const awaySchool = awaySchools.find(awaySchool => `${awaySchool.id}` === `${event.opponentAccountId}`) ?? {};
      return {
        ...event,
        grossToAccount: get(dailySalesInfo, 'totals.net_to_account', ''),
        ticketGrossCount: get(dailySalesInfo, 'totals.ticket_net_count', ''),
        _embedded: {
          ...get(event, '_embedded', {}),
          dailySalesInfo,
          awaySchool
        }
      };
    });

  getEventSalesInfo = (eventId: number): Promise<EventSalesInfoDTO> => EventRepository.getEventSalesInfo(eventId);

  deleteEventById = (eventId: number | string): Promise<any> => EventRepository.deleteEventById(eventId);

  omitEventForUpdating = (event: EventDTO): EventDTO =>
    omit(event, ['_embedded', 'grossToAccount', 'ticketGrossCount']) as EventDTO;

  createEvent = (event: EventDTO, options?: { checkDupes: boolean }): Promise<EventDTO> =>
    EventRepository.createEvent(event, options);

  updateEvent = (event: EventDTO, options?: { checkDupes: boolean }): Promise<EventDTO> =>
    EventRepository.updateEvent(event, options);

  updatePartialEvent = (event: Partial<EventDTO>): Promise<EventDTO> => EventRepository.updatePartialEvent(event);

  getEventById = (eventId: string | number, expands: string[] = []): Promise<EventDTO> => {
    const expandQuery = new ExpandRequest(expands).toQueryString();
    return EventRepository.getEventById(eventId, `?ts=${new Date().getTime()}${expandQuery}`);
  };

  getSalesReportInfo = (searchParams: SalesReportInfoSearch): Promise<SalesReportInfoDTO | BlobPart> =>
    EventRepository.getSalesReportInfo(searchParams);

  checkDuplicateEvent = (importEvent: ImportEventDTO[]): Promise<EventConflictDTO[]> =>
    EventRepository.checkDuplicateEvent(importEvent).then(response =>
      response.some(item => !isEmpty(item.events)) ? response : []
    );

  getListMondayOfYear = (
    dateObj: { year: number; month: number; day: number },
    currentWeek: boolean
  ): DropdownItemType[] => {
    const startDate = new Date(dateObj.year, dateObj.month - 1, dateObj.day);
    if (startDate.getDay() !== 1) {
      startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
    }
    const date = new Date();
    const listMonday = [];
    const isSunday = date.getDay() === 0;
    const subVariable = isSunday ? 7 : 0;
    if (currentWeek) {
      date.setDate(date.getDate() - date.getDay() - subVariable + 1);
    } else {
      date.setDate(date.getDate() - date.getDay() - subVariable - 6);
    }

    while (date > startDate) {
      listMonday.push({
        id: moment(date).format('MM/DD/YYYY'),
        label: moment(date).format('ddd-MMM-DD-YYYY')
      });
      date.setDate(date.getDate() - 7);
    }
    return listMonday;
  };

  searchByIds = (ids: number[] | string[], expands: string[] = [], fields: string[] = []) => {
    const expandQuery = new ExpandRequest(expands).toQueryString();
    const fieldQuery = (fields ?? []).join(',');

    return EventRepository.searchByIds(
      ids.map(id => `${id}`),
      `&ts=${new Date().getTime()}${expandQuery}${fieldQuery ? `&fields=${fieldQuery}` : ''}`
    );
  };

  getSalesAllotmentInfo = (eventId: number): Promise<any> => EventRepository.getSalesAllotmentInfo(eventId);

  getTicketSaleInsightData = (eventId: number): Promise<EventTicketSale> =>
    EventRepository.getTicketSaleInsightData(eventId);

  getAccountMetrics = (query: AccountMetricsQuery) => EventRepository.getAccountMetrics(query);

  getSalesReportInfoAndStandardizeData = async ({
    schoolId,
    startDate,
    endDate
  }: SalesReportInfoAndStandardizeDataType) => {
    const DATE_FORMAT = 'YYYYMMDD';
    let ticketSales = '';
    let reportId = '';

    const periodStart = moment(startDate).format(DATE_FORMAT);
    const periodEnd = moment(endDate).format(DATE_FORMAT);
    const transactionPeriod = [periodStart, periodEnd].join('-');
    const searchParamsRef = {
      page: 0,
      pageSize: 25,
      body: {
        financialSchoolId: schoolId,
        paymentPeriod: transactionPeriod
      },
      sortBy: {
        header: 'adviceNumber',
        sortDirection: sortStates.DESC
      }
    };
    const weeklyReports = await FinancialService.getPaymentStatementV1(searchParamsRef);

    reportId = weeklyReports.find(item => item.transactionPeriod === transactionPeriod)?.id ?? '';
    const netSales = weeklyReports.find(item => item.transactionPeriod === transactionPeriod)?.netSales ?? 0;

    if (netSales > 0) {
      ticketSales = `$${formatMoney(netSales)}`;
    }

    return {
      ticketSales,
      reportId
    };
  };

  getEventAwaySchoolInfo = ({ event, accountIds }: EventNameProps) => {
    const awaySchool = get(event, 'opponentAccount') ?? get(event, '_embedded.awaySchool') ?? {};

    if (!isEmpty(awaySchool)) {
      const homeSchool: string | undefined = accountIds?.find(
        (id: string) => `${id}` === `${event?.accountId}` || `${id}` === `${event?.financialAccountId}`
      );

      return {
        prefix: !isEmpty(homeSchool) ? 'vs' : 'at',
        shortName: awaySchool?.shortName ?? awaySchool?.name ?? '',
        schoolIcon: isEmpty(awaySchool?.logo)
          ? defaultSchoolIcon
          : getSchoolImage(awaySchool?.id, 'logo', awaySchool?.logo)
      };
    }

    return {
      prefix: '',
      schoolIcon: null,
      shortName: event.name
    };
  };

  getEventDate = (event: EventDTO) => {
    if (event.allDayEvent) {
      return `${formatDateTime({
        parseZone: true,
        date: event.startDateTime,
        timeZone: event?.timeZone
      }).toDate(DATE_DUPLICATE_EVENT)} - ${formatDateTime({
        parseZone: true,
        date: event.endDateTime,
        timeZone: event?.timeZone
      }).toDate(DATE_DUPLICATE_EVENT)}`;
    }
    return formatDateTime({
      parseZone: true,
      date: event.startDateTime,
      timeZone: event?.timeZone
    }).toDateTime(DATE_DUPLICATE_EVENT);
  };

  cancelEvent = (cancelRequest: EventCancelDTO): Promise<any> => EventRepository.cancelEvent(cancelRequest);

  isFundraiser = (event?: EventDTO) => {
    const activity: ActivityDTO = !isEmpty(event?.activity) ? event?.activity : get(event, '_embedded.activity', {});
    return activity?.label?.toLowerCase() === FUNDRAISER.toLowerCase();
  };

  rescheduleEvent = (payload: EventRescheduleDTO) => EventRepository.rescheduleEvent(payload);

  restoreEvent = (eventId: string | number) => EventRepository.restoreEvent({ id: eventId, canceled: false });

  getFlatfileToken = (payload: any) => EventRepository.getFlatfileToken(payload);

  bulkUploadEvents = (payload: EventBulkUpload) => EventRepository.bulkUploadEvents(payload);

  getUploadData = (payload: any) =>
    EventRepository.getUploadData({
      ...payload,
      searchBy: payload.searchBy === 'uploadAt' ? 'dateTime' : payload.searchBy
    });

  getStatusBulkUploadEvents = (batchId: string) => EventRepository.getStatusBulkUploadEvents(batchId);
}

export default new EventService();
