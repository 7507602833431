import PublishLevelRequest from './PublishLevelRequest';
import PublishTicketTypeRequest from './PublishTicketTypeRequest';
import EventAccountsTicket from '../EventAccountsTicket';
import { isEmpty } from '../../../utils/objectUtils';
import { parseString, parseNumber } from '../../../utils/parseUtils';
import { switchZone, formatDateTimeZone, TIME_ZONE_ENUM } from '../../../utils/dateUtils';

class PublishEventRequest {
  constructor(
    {
      // event id
      id,

      // required
      genders,
      allDayEvent,
      opponentAccountId,
      hostAccountId,
      financialAccountId,
      taggedAccountIds,
      levels,
      seasonStartTime,
      startDateTime,
      startTimeType,
      startTimeOptions,
      endDateTime,
      accountId,
      activityId,
      reportingLabel,
      name,
      venueCity,
      venueAddress,
      venueLocation,
      venueName,
      venueId,
      venueState,
      venueZip,
      eventTypeId,
      eventValidationStartsBefore,
      enableEventValidation,
      redemptionWindow,
      goal,

      // optional
      archived,
      publishDateTime,
      salesStartDateTime,
      salesEndDateTime,
      alert,
      theme,
      description,
      customSportName,
      featuredAccountIds,
      ticketLimitPerOrder,
      formFields,
      glCode,
      pixellotKey,

      // ticketTypes
      maxCapacity,
      products,
      accountsTicket,
      ticketDistribution,
      paymentCycle,

      // streaming
      eventIntegrationDetails,

      // away event
      awayGame,

      // additional
      featured,
      disableQr,
      postSeason,
      timeZone,
      specialEventDescription,
      disabledForIndividualSale,
      formId,
      globalEventId,
      partnerId,
      partnerName,

      // For resolve duplicate if you choose "use this event"
      // we will have this
      originalExistingEvent = {}
    },
    needSwitchZone
  ) {
    this.config = {
      needSwitchZone
    };
    this.raw = {
      id,
      // required
      allDayEvent: Boolean(allDayEvent),
      opponentAccountId: parseString(opponentAccountId),
      hostAccountId: parseString(hostAccountId),
      financialAccountId: parseString(financialAccountId),
      taggedAccountIds: isEmpty(taggedAccountIds) ? [] : taggedAccountIds,
      levels: isEmpty(levels) ? [] : levels,
      genders: isEmpty(genders) ? [] : genders,
      seasonStartTime: seasonStartTime || undefined,
      startDateTime: startDateTime || undefined,
      startTimeType: parseString(startTimeType),
      startTimeOptions: isEmpty(startTimeOptions) ? {} : startTimeOptions,
      endDateTime: endDateTime || undefined,
      accountId: parseString(accountId),
      activityId: parseNumber(activityId),
      reportingLabel: parseString(reportingLabel),
      name: parseString(name),
      venueCity: parseString(venueCity),
      venueAddress: parseString(venueAddress),
      venueLocation: parseString(venueLocation),
      venueName: parseString(venueName),
      venueId: parseNumber(venueId),
      venueState: parseString(venueState),
      venueZip: parseString(venueZip),
      eventTypeId: parseNumber(eventTypeId),
      eventValidationStartsBefore: parseNumber(eventValidationStartsBefore),
      enableEventValidation: Boolean(enableEventValidation),
      redemptionWindow,
      goal: parseString(goal),

      // optional
      archived: Boolean(archived),
      publishDateTime: publishDateTime || undefined,
      salesStartDateTime: salesStartDateTime || undefined,
      salesEndDateTime: salesEndDateTime || undefined,
      alert: parseString(alert),
      theme: parseString(theme),
      description: parseString(description),
      customSportName: parseString(customSportName),
      featuredAccountIds: isEmpty(featuredAccountIds) ? [] : featuredAccountIds,
      ticketLimitPerOrder: parseNumber(ticketLimitPerOrder),
      paymentCycle: parseString(paymentCycle),
      formFields: formFields || undefined,
      glCode: glCode || null,
      pixellotKey: pixellotKey || undefined,

      // ticketTypes
      maxCapacity: parseNumber(maxCapacity),
      products: isEmpty(products) ? [] : products,
      accountsTicket: isEmpty(accountsTicket) ? [] : accountsTicket,
      ticketDistribution: Boolean(ticketDistribution),

      // streaming
      eventIntegrationDetails,

      // away event
      awayGame: awayGame || undefined,

      // additional
      featured: Boolean(featured),
      disableQr: Boolean(disableQr),
      postSeason: Boolean(postSeason),
      timeZone: parseString(timeZone),
      specialEventDescription: parseString(specialEventDescription),
      disabledForIndividualSale: Boolean(disabledForIndividualSale),
      formId: parseNumber(formId),
      globalEventId,
      partnerId,
      partnerName
    };
    this.originalExistingEvent = originalExistingEvent;
  }

  // event id
  get id() {
    return this.raw.id || undefined;
  }

  // required
  get allDayEvent() {
    return this.raw.allDayEvent;
  }

  get opponentAccountId() {
    return isEmpty(this.raw.opponentAccountId) ? undefined : this.raw.opponentAccountId;
  }

  get hostAccountId() {
    return isEmpty(this.raw.hostAccountId) ? undefined : this.raw.hostAccountId;
  }

  get financialAccountId() {
    return isEmpty(this.raw.financialAccountId) ? undefined : this.raw.financialAccountId;
  }

  get taggedAccountIds() {
    return this.raw.taggedAccountIds;
  }

  get levels() {
    return this.raw.levels.map(level => new PublishLevelRequest(level));
  }

  get genders() {
    return this.raw.genders;
  }

  get startDateTime() {
    if (!this.raw.startDateTime) return undefined;
    return this.config.needSwitchZone
      ? switchZone(this.raw.startDateTime, this.timeZone)
      : formatDateTimeZone({ date: this.raw.startDateTime, timeZone: this.timeZone });
  }

  get startTimeType() {
    return this.raw.startTimeType;
  }

  get startTimeOptions() {
    return this.raw.startTimeOptions;
  }

  get seasonStartTime() {
    return this.raw.seasonStartTime;
  }

  get endDateTime() {
    if (!this.raw.endDateTime) return undefined;
    return this.config.needSwitchZone
      ? switchZone(this.raw.endDateTime, this.timeZone)
      : formatDateTimeZone({ date: this.raw.endDateTime, timeZone: this.timeZone });
  }

  get accountId() {
    return isEmpty(this.raw.accountId) ? undefined : this.raw.accountId;
  }

  get activityId() {
    return this.raw.activityId;
  }

  get reportingLabel() {
    return isEmpty(this.raw.reportingLabel) ? undefined : this.raw.reportingLabel;
  }

  get name() {
    return this.raw.name;
  }

  get venueCity() {
    return this.raw.venueCity;
  }

  get venueAddress() {
    return this.raw.venueAddress;
  }

  get venueLocation() {
    return this.raw.venueLocation;
  }

  get venueName() {
    return this.raw.venueName;
  }

  get venueId() {
    return this.raw.venueId;
  }

  get venueState() {
    return this.raw.venueState;
  }

  get venueZip() {
    return this.raw.venueZip;
  }

  get eventTypeId() {
    return this.raw.eventTypeId;
  }

  get eventValidationStartsBefore() {
    return this.raw.eventValidationStartsBefore;
  }

  get enableEventValidation() {
    return this.raw.enableEventValidation;
  }

  get redemptionWindow() {
    return this.raw.redemptionWindow;
  }

  get goal() {
    return this.raw.goal;
  }

  // optional
  get archived() {
    return this.raw.archived;
  }

  get publishDateTime() {
    if (!this.raw.publishDateTime) return undefined;
    return this.config.needSwitchZone
      ? switchZone(this.raw.publishDateTime, this.timeZone)
      : formatDateTimeZone({ date: this.raw.publishDateTime, timeZone: this.timeZone });
  }

  get salesStartDateTime() {
    if (!this.raw.salesStartDateTime) return undefined;
    return this.config.needSwitchZone
      ? switchZone(this.raw.salesStartDateTime, this.timeZone)
      : formatDateTimeZone({ date: this.raw.salesStartDateTime, timeZone: this.timeZone });
  }

  get salesEndDateTime() {
    if (!this.raw.salesEndDateTime) return undefined;
    return this.config.needSwitchZone
      ? switchZone(this.raw.salesEndDateTime, this.timeZone)
      : formatDateTimeZone({ date: this.raw.salesEndDateTime, timeZone: this.timeZone });
  }

  get alert() {
    return isEmpty(this.raw.alert) ? undefined : this.raw.alert;
  }

  get theme() {
    return isEmpty(this.raw.theme) ? undefined : this.raw.theme;
  }

  get description() {
    return isEmpty(this.raw.description) ? undefined : this.raw.description;
  }

  get customSportName() {
    return isEmpty(this.raw.customSportName) ? undefined : this.raw.customSportName;
  }

  get featuredAccountIds() {
    return this.raw.featuredAccountIds;
  }

  get ticketLimitPerOrder() {
    return this.raw.ticketLimitPerOrder;
  }

  get paymentCycle() {
    return this.raw.paymentCycle;
  }

  get formFields() {
    return this.raw.formFields;
  }

  get glCode() {
    return this.raw.glCode;
  }

  get pixellotKey() {
    return this.raw.pixellotKey;
  }

  // ticketTypes
  get maxCapacity() {
    return this.raw.maxCapacity;
  }

  get products() {
    return this.raw.products.map(product => new PublishTicketTypeRequest(product));
  }

  set products(products) {
    this.raw.products = products;
  }

  get accountsTicket() {
    return this.raw.accountsTicket.map(item => new EventAccountsTicket(item));
  }

  get ticketDistribution() {
    return this.raw.ticketDistribution;
  }

  // away event
  get awayGame() {
    return this.raw.awayGame;
  }

  // additional
  get featured() {
    return this.raw.featured;
  }

  get disableQr() {
    return this.raw.disableQr;
  }

  get postSeason() {
    return this.raw.postSeason;
  }

  get timeZone() {
    return !this.raw.timeZone ? TIME_ZONE_ENUM.AMERICA_NEW_YORK : this.raw.timeZone;
  }

  get specialEventDescription() {
    return isEmpty(this.raw.specialEventDescription) ? undefined : this.raw.specialEventDescription;
  }

  get disabledForIndividualSale() {
    return this.raw.disabledForIndividualSale;
  }

  get formId() {
    return this.raw.formId;
  }

  get globalEventId() {
    return this.raw.globalEventId;
  }

  get partnerName() {
    return this.raw.partnerName;
  }

  get partnerId() {
    return this.raw.partnerId;
  }

  get eventIntegrationDetails() {
    return this.raw.eventIntegrationDetails;
  }

  getObjectToCreate(publish) {
    return {
      id: this.id,
      // required
      genders: this.genders,
      allDayEvent: this.allDayEvent,
      opponentAccountId: this.opponentAccountId,
      hostAccountId: this.hostAccountId,
      financialAccountId: this.financialAccountId,
      taggedAccountIds: this.taggedAccountIds,
      levels: this.levels.map(level => level.toJSON()),
      seasonStartTime: this.seasonStartTime,
      startDateTime: this.startDateTime,
      startTimeType: this.startTimeType,
      startTimeOptions: this.startTimeOptions,
      endDateTime: this.endDateTime,
      accountId: this.accountId,
      activityId: this.activityId,
      reportingLabel: this.reportingLabel,
      name: this.name,
      venueCity: this.venueCity,
      venueAddress: this.venueAddress,
      venueLocation: this.venueLocation,
      venueName: this.venueName,
      venueId: this.venueId,
      venueState: this.venueState,
      venueZip: this.venueZip,
      eventTypeId: this.eventTypeId,
      eventValidationStartsBefore: this.eventValidationStartsBefore,
      enableEventValidation: this.enableEventValidation,
      redemptionWindow: this.redemptionWindow,
      goal: this.goal,

      // optional
      archived: this.archived,
      publishDateTime: this.publishDateTime,
      salesStartDateTime: this.salesStartDateTime,
      salesEndDateTime: this.salesEndDateTime,
      alert: this.alert,
      theme: this.theme,
      description: this.description,
      customSportName: this.customSportName,
      featuredAccountIds: this.featuredAccountIds,
      ticketLimitPerOrder: this.ticketLimitPerOrder,
      paymentCycle: this.paymentCycle,
      formFields: this.formFields,
      glCode: this.glCode,
      pixellotKey: this.pixellotKey,

      // ticketTypes
      maxCapacity: this.maxCapacity,
      products: this.products.map(product => product.toJSON(publish)),
      accountsTicket: this.accountsTicket.map(item => item.toJSON(publish)),
      ticketDistribution: this.ticketDistribution,

      // streaming
      eventIntegrationDetails: this.eventIntegrationDetails,

      // away event
      awayGame: this.awayGame,

      // additional
      featured: this.featured,
      disableQr: this.disableQr,
      postSeason: this.postSeason,
      timeZone: this.timeZone,
      specialEventDescription: this.specialEventDescription,
      disabledForIndividualSale: this.disabledForIndividualSale,
      formId: this.formId,
      globalEventsId: this.globalEventId,
      partnerName: this.partnerName,
      partnerId: this.partnerId
    };
  }

  getObjectToCreateInResolveEventCase(publish) {
    const objectToCreate = { ...this.originalExistingEvent, ...this.getObjectToCreate(publish) };
    return Object.keys(objectToCreate).reduce((result, currentKey) => {
      if (objectToCreate[currentKey] === null || objectToCreate[currentKey] === undefined) {
        // eslint-disable-next-line no-param-reassign
        result[currentKey] = this.originalExistingEvent[currentKey];
      } else {
        // eslint-disable-next-line no-param-reassign
        result[currentKey] = objectToCreate[currentKey];
      }
      return result;
    }, {});
  }

  toJSON(publish) {
    if (isEmpty(this.originalExistingEvent)) {
      return this.getObjectToCreate(publish);
    }
    return this.getObjectToCreateInResolveEventCase(publish);
  }
}

export default PublishEventRequest;
