import classNames from 'classnames';
import { InlineNotification, ActionableNotification as CarbonActionableNotification } from '@carbon/react';
import { useState, useRef, useEffect } from 'react';
import { config } from '@gofan/constants';
import type {
  InlineNotificationProps as CarbonInlineNotificationProps,
  ActionableNotificationProps
} from '@carbon/react';

import './Notification.scss';

interface InlineNotificationProps extends CarbonInlineNotificationProps {
  shouldAutoClose?: boolean;
  timeout?: number;
  cbFunction?: () => void;
}

export const Notification = ({
  shouldAutoClose,
  timeout = config.TIMEOUT_NOTIFICATION,
  cbFunction,
  ...props
}: InlineNotificationProps) => {
  const [isShown, setIsShown] = useState<boolean>(true);
  const timerRef = useRef<any>(null);

  useEffect(() => {
    if (shouldAutoClose && isShown && timeout && !timerRef.current) {
      timerRef.current = setTimeout(() => {
        clearTimeout(timerRef.current);
        timerRef.current = null;
        setIsShown(false);
        if (typeof cbFunction === 'function') {
          cbFunction();
        }
      }, timeout);
    }
    return () => {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    };
  }, [isShown, timeout, setIsShown]);

  useEffect(
    () => () => {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    },
    []
  );

  return isShown ? (
    <InlineNotification
      {...props}
      style={{ margin: 0 }}
      onCloseButtonClick={event => {
        setIsShown(false);
        if (typeof props?.onCloseButtonClick === 'function') props?.onCloseButtonClick(event);
        if (typeof cbFunction === 'function') cbFunction();
      }}
    />
  ) : null;
};

export const ActionableNotification = (props: ActionableNotificationProps) => (
  <CarbonActionableNotification
    {...props}
    hasFocus={false}
    className={classNames('gf--actionable-notification', props.className)}
  >
    {props.children}
  </CarbonActionableNotification>
);
