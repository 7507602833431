import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import styles from './styles';
import { PAGES } from '../../../../config/routes';
import { StyledFormButton } from '../../../../commons/components/styledSemantic';

const Wrapper = styled.div`
  ${styles.wrapper};
`;

const ButtonWrapper = styled.div`
  ${styles.buttonWrapper};
`;

const Headline = styled.div`
  ${styles.headline};
`;

const Paragraph = styled.div`
  ${styles.paragraph};
`;

const OrderList = styled.ul`
  ${styles.orderList};
`;

const Item = styled.li`
  ${styles.item};
`;

const Navigation = styled(Link)`
  ${styles.link};
`;

export const PageForbidden = () => (
  <Wrapper>
    <Headline>Forbidden</Headline>
    <Paragraph>
      GoFan is only available to users located in the United States to comply with international privacy laws.
    </Paragraph>
    <Paragraph>We apologize for the inconvenience.</Paragraph>
  </Wrapper>
);

export const PageNotFound = () => (
  <Wrapper>
    <Headline>Page Not Found</Headline>
    <Paragraph>You have tried to reach a page that does not exist.</Paragraph>
    <Paragraph>
      {`Go to the `}
      <Navigation to={PAGES.dashboard.root.path} id='lnkHomePage'>
        homepage
      </Navigation>
      .
    </Paragraph>
  </Wrapper>
);

export const PageInternalServerError = () => (
  <Wrapper>
    <Headline>Internal Server Error</Headline>
    <Paragraph>
      The server encountered an internal error or misconfiguration and was unable to complete your request.
    </Paragraph>
    <Paragraph>What you can try:</Paragraph>
    <OrderList>
      <Item>Refresh the screen.</Item>
      <Item>Go back to the previous screen.</Item>
      <Item>
        <>Go to the </>
        <Navigation to={PAGES.dashboard.root.path}>
          <>homepage</>
        </Navigation>
        .
      </Item>
    </OrderList>
    <Paragraph>Thank you!</Paragraph>
  </Wrapper>
);

export const PageServiceUnavailable = () => (
  <Wrapper>
    <Headline>Well this is awkward</Headline>
    <Paragraph>Looks like our site called a time-out. Sorry for the inconvenience.</Paragraph>
    <Paragraph>We’ll be back in the game shortly.</Paragraph>
  </Wrapper>
);

export const PageUnderMaintenance = ({ onTryAgain }) => (
  <Wrapper>
    <Headline>Under Maintenance</Headline>
    <Paragraph>GoFan is currently undergoing scheduled maintenance. We’ll be back shortly.</Paragraph>
    <Paragraph>We apologize for the inconvenience.</Paragraph>
    <ButtonWrapper>
      <StyledFormButton fluid primary size='medium' customHeight={40} onClick={onTryAgain}>
        TRY AGAIN
      </StyledFormButton>
    </ButtonWrapper>
  </Wrapper>
);

export const PageUnauthorized = () => (
  <Wrapper>
    <Headline>Unauthorized</Headline>
    <Paragraph>You do not have permission to view this page.</Paragraph>
    <Paragraph>
      {`Go to the `}
      <Navigation to={PAGES.dashboard.root.path} id='lnkHomePage'>
        homepage
      </Navigation>
      .
    </Paragraph>
  </Wrapper>
);

export const PageBadGateway = () => (
  <Wrapper>
    <Headline>Bad Gateway</Headline>
    <Paragraph>The server encountered a temporary error and could not complete your request.</Paragraph>
    <Paragraph>Please try again later.</Paragraph>
  </Wrapper>
);
