import { omit } from 'lodash';
import { PRODUCT_TYPE } from './ProductRequest';
import { parseNumber, parseString } from '../../../utils/parseUtils';
import { sliceByKeys } from '../../../utils/objectUtils';

class PublishTicketTypeRequest {
  constructor(props) {
    const { result, rest } = sliceByKeys({
      obj: props,
      keys: [
        'accountId',
        'accountProductId',
        'hiddenFees',
        'groupId',
        'distributionChannel',
        'enabled',
        'eventId',
        'id',
        'limit',
        'name',
        'price',
        'fee',
        'ticketLimitPerOrder',
        'redemptionLimit',
        'salesEndDateTime',
        'salesStartDateTime',
        'packCount',
        'productType',
        'promotionRequired',
        'reservedSeating',
        'generateLink',
        'formFields',
        'restData'
      ],
      omit: ['productSalesInfor', 'productCreatedAt', 'isSold', 'saleQuantity']
    });
    this.raw = {
      accountId: parseString(result.accountId),
      accountProductId: parseString(result.accountProductId),
      hiddenFees: Boolean(result.hiddenFees),
      groupId: result.groupId,
      distributionChannel: parseString(result.distributionChannel),
      enabled: Boolean(result.enabled),
      eventId: parseNumber(result.eventId),
      id: parseNumber(result.id),
      limit: parseNumber(result.limit),
      name: parseString(result.name, true),
      price: parseNumber(result.price),
      fee: parseNumber(result.fee),
      ticketLimitPerOrder: parseNumber(result.ticketLimitPerOrder),
      redemptionLimit: parseNumber(result.redemptionLimit),
      packCount: parseNumber(result.packCount),
      salesEndDateTime: result.salesEndDateTime,
      salesStartDateTime: result.salesStartDateTime,
      productType: parseString(result.productType),
      promotionRequired: Boolean(result.promotionRequired),
      reservedSeating: result.reservedSeating,
      generateLink: Boolean(result.generateLink),
      formFields: result.formFields,
      created: Boolean(rest.created),
      restData: { ...result.restData, ...rest }
    };
  }

  get accountId() {
    return this.raw.accountId;
  }

  get distributionChannel() {
    return this.raw.distributionChannel;
  }

  get enabled() {
    return this.raw.enabled;
  }

  get eventId() {
    return this.raw.eventId;
  }

  get id() {
    return this.raw.id;
  }

  get limit() {
    return this.raw.limit;
  }

  get name() {
    return this.raw.name;
  }

  get price() {
    return this.raw.price;
  }

  get fee() {
    return this.raw.fee;
  }

  get ticketLimitPerOrder() {
    return this.raw.ticketLimitPerOrder;
  }

  get redemptionLimit() {
    return this.raw.redemptionLimit;
  }

  get packCount() {
    return this.raw.packCount;
  }

  get salesEndDateTime() {
    return this.raw.salesEndDateTime;
  }

  get salesStartDateTime() {
    return this.raw.salesStartDateTime;
  }

  get productType() {
    return !this.raw.productType ? PRODUCT_TYPE.GOFAN : this.raw.productType;
  }

  get promotionRequired() {
    return this.raw.promotionRequired;
  }

  get reservedSeating() {
    return this.raw.reservedSeating;
  }

  get generateLink() {
    return this.raw.generateLink;
  }

  get formFields() {
    return (this.raw.formFields ?? []).map(formField => ({
      ...formField,
      id: null,
      description: formField.description || null,
      options:
        formField.fieldType === 'DROPDOWN' && (formField?.options ?? []).length > 0
          ? formField.options?.map(option => option?.value ?? option)
          : null
    }));
  }

  get restData() {
    return this.raw.restData;
  }

  get accountProductId() {
    return this.raw.accountProductId;
  }

  get created() {
    return this.raw.created;
  }

  get hiddenFees() {
    return this.raw.hiddenFees;
  }

  get groupId() {
    return this.raw.groupId;
  }

  toJSON(publish) {
    return {
      ...omit(this.restData, [
        'created',
        'enabledOptionSetting',
        'enabledPackCount',
        'unlimitedPass',
        'salesEndTime',
        'salesEndDate',
        'salesStartTime',
        'salesStartDate'
      ]),
      accountId: this.accountId,
      accountProductId: this.accountProductId,
      hiddenFees: this.hiddenFees,
      groupId: this.groupId,
      distributionChannel: this.distributionChannel,
      enabled: this.enabled,
      eventId: publish ? undefined : this.eventId,
      id: publish && !this.created ? undefined : this.id,
      limit: this.limit,
      name: this.name,
      price: this.price,
      fee: this.fee,
      ticketLimitPerOrder: this.ticketLimitPerOrder,
      redemptionLimit: this.redemptionLimit,
      packCount: this.packCount,
      salesEndDateTime: this.salesEndDateTime,
      salesStartDateTime: this.salesStartDateTime,
      productType: this.productType,
      promotionRequired: this.promotionRequired,
      reservedSeating: this.reservedSeating,
      generateLink: this.generateLink,
      formFields: this.formFields
    };
  }
}

export default PublishTicketTypeRequest;
