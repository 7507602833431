import type { SeasonRenewalDTO } from '@gofan/api';
import type { EventReservedSeatingState } from './types';
import type { EventReservedSeatingTypes } from './actionTypes';
import {
  DELETE_RENEWAL_OFFER_COMPLETED,
  DELETE_RENEWAL_OFFER,
  GET_SEASON_RENEWALS_COMPLETED,
  UPDATE_RENEWAL_OFFER,
  UPDATE_RENEWAL_OFFER_COMPLETED,
  PREPARE_DATA,
  PREPARE_DATA_COMPLETED,
  UPDATE_SEATS_INFO_COMPLETED,
  VIEW_EFFECTED_SEATS,
  CLEAR_SELECTED_ORDER,
  UPDATE_SEASON_RENEWAL,
  UPDATE_SEASON_RENEWAL_COMPLETED,
  UPDATE_ACCOUNTS_COMPLETED
} from './actionTypes';
import { isEmpty } from '../../utils/objectUtils';

export const initialState: EventReservedSeatingState = {
  pageLoading: true,
  pageError: undefined,
  event: {},
  season: {},
  seatsInfo: {},
  opponentAccounts: [],
  opponentSearchLoading: false,
  accountProductTypes: [],
  accountProductsSearching: false,
  isEventCreated: false,
  processing: false,
  selectedOrder: {},
  showSelectedOrder: false,
  seasonRenewals: {
    campaigns: [],
    seasonRenewalFans: []
  },
  seasonRenewalSeats: {},
  renewalLoading: false,
  account: {},
  financialAccount: {}
};

export default (state = initialState, action: EventReservedSeatingTypes) => {
  switch (action.type) {
    case PREPARE_DATA: {
      return {
        ...state,
        pageLoading: true,
        pageError: undefined
      };
    }

    case PREPARE_DATA_COMPLETED: {
      const { event, season, seatsInfo, seasonTickets, seasonRenewals, seasonRenewalSeats } = action.payload;

      return {
        ...state,
        pageLoading: false,
        pageError: isEmpty(action.error) ? undefined : action.error,
        event: isEmpty(event) ? {} : event,
        season: isEmpty(season) ? {} : season,
        seatsInfo: isEmpty(seatsInfo) ? {} : seatsInfo,
        seasonTickets,
        seasonRenewals: isEmpty(seasonRenewals) ? [] : seasonRenewals,
        seasonRenewalSeats: isEmpty(seasonRenewalSeats) ? {} : seasonRenewalSeats
      };
    }

    case UPDATE_SEATS_INFO_COMPLETED: {
      const { updatedSeatsInfo, seasonTickets } = action.payload;
      return {
        ...state,
        seatsInfo: updatedSeatsInfo.toJSON(),
        seasonTickets
      };
    }

    case VIEW_EFFECTED_SEATS: {
      const { type, seats } = action.payload;
      return {
        ...state,
        showSelectedOrder: true,
        selectedOrder: {
          type,
          seats
        }
      };
    }

    case UPDATE_SEASON_RENEWAL: {
      return {
        ...state,
        renewalLoading: true
      };
    }

    case UPDATE_SEASON_RENEWAL_COMPLETED: {
      const { id, renewalEnd, emailReminder } = action.payload.seasonRenewal;
      const index = (state.seasonRenewals.campaigns ?? []).findIndex(campaign => `${campaign.id}` === `${id}`);
      const updatedCampaign: SeasonRenewalDTO[] = [...state.seasonRenewals.campaigns];

      if (index !== -1) {
        updatedCampaign[index] = {
          ...updatedCampaign[index],
          renewalEnd,
          emailReminder
        };
      }

      return {
        ...state,
        renewalLoading: false,
        seasonRenewals: {
          campaigns: updatedCampaign,
          seasonRenewalFans: state.seasonRenewals.seasonRenewalFans
        }
      };
    }

    case CLEAR_SELECTED_ORDER: {
      return {
        ...state,
        showSelectedOrder: false,
        selectedOrder: {}
      };
    }

    case GET_SEASON_RENEWALS_COMPLETED: {
      const { seasonRenewals } = action.payload;
      return {
        ...state,
        seasonRenewals
      };
    }

    case UPDATE_RENEWAL_OFFER:
    case DELETE_RENEWAL_OFFER: {
      return {
        ...state,
        renewalLoading: true
      };
    }

    case UPDATE_RENEWAL_OFFER_COMPLETED:
    case DELETE_RENEWAL_OFFER_COMPLETED: {
      return {
        ...state,
        renewalLoading: false
      };
    }

    case UPDATE_ACCOUNTS_COMPLETED: {
      const { account, financialAccount } = action.payload;
      return {
        ...state,
        account,
        financialAccount
      };
    }

    default: {
      return state;
    }
  }
};
