import { TICKET_ACCOUNT } from '@common/constants';

export default {
  TIMEOUT_SESSION_MESSAGE:
    'To protect your privacy and security, your session has timed out due to inactivity.',
  PERMISSION_MESSAGE:
    'Sorry, you don’t have permission to access this resource.',
  DISABLED_DELETED:
    'This account has been disabled or deleted. Please contact support for help.',
  ACCOUNT_NOT_FOUND_MESSAGE:
    'Sorry, we can’t find an account with that email address.',
  NOT_RECOGNIZE_EMAIL_OR_PASSWORD_MESSAGE:
    'We did not recognize your email address and password combination. Please try again or you can reset your password.',
  NOT_CREATE_PASSWORD:
    'You have not created a password for this account. Please click Create Password to activate your account.',
  PASSWORD_REQUIRED_MESSAGE: 'Password is required.',
  PASSWORD_LENGTH_MESSAGE: 'Password must be 8 to 20 characters long.',
  PASSWORD_PATTERN_MESSAGE: 'Password does not meet complexity requirements.',
  PASSWORDS_NOT_MATCH_MESSAGE: "Passwords don't match.",
  CONFIRM_PASSWORD_REQUIRED_MESSAGE: 'Confirm password is required.',
  COLOR_ERROR_MESSAGE: 'Enter a valid HEX color.',
  URL_ERROR_MESSAGE: 'Enter a valid URL.',
  CUSTOM_NAME_EXISTS_MESSAGE:
    'Custom account name already exists. Please try again.',
  CUSTOM_NAME_MIN_LENGTH_MESSAGE:
    'Custom account name most contain at least 3 characters.',
  TICKET_PRICE_LENGTH_MESSAGE: TICKET_ACCOUNT.ERRORS.MIN_MAX_TICKET_PRICE,
  LEVEL_NAME_IS_REQUIRED: 'Level Name is required.',
  PRODUCT_TYPE_NAME_IS_REQUIRED: 'Product type name is required.',
  ALPHANUMERIC_REQUIRED_MESSAGE:
    'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) with a maximum of 32 characters long.',
  NAME_ALREADY_EXISTS_MESSAGE: 'Name already exists.',
  ASSOCIATING_UPCOMING_EVENT_MESSAGE:
    'is associating with the upcoming events/seasons.',
  EDIT_PAST_EVENT_ERROR: 'You can not update the event has passed.',
  INCORRECT_PASSWORD: 'Incorrect password'
};
