import { useState, useEffect } from 'react';
import StyledDropdown from '@app/commons/carbon-ui/atoms/StyledDropdown';
import StyledTextInput from '@app/commons/carbon-ui/atoms/StyledTextInput';
import { Button, Icons, Link } from '@gofan/components';
import { useMounted } from '@gofan/hooks';
import { ReservedSeatingService } from '@gofan/api/reserved-seating';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import { addNotification } from '@gofan/utils';

import './reserved-seating-category-select.scss';

export interface ReservedSeatingCategorySelectProps {
  chartKey: string;
  initialSelectedItem?: string;
  value?: string;
  onChange: Function;
  invalid?: boolean;
  invalidText?: string;
  required?: boolean;
  style?: any;
  defaultOptions?: string[];
}

const ReservedSeatingCategorySelect = (props: ReservedSeatingCategorySelectProps) => {
  const [options, setOptions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const { isMounted, onMounted } = useMounted({ manual: true });

  useEffect(() => {
    if (props?.defaultOptions) {
      setOptions(props?.defaultOptions);
    } else if (!isMounted || retryCount > 0) {
      setIsLoading(true);
      _fetchChartCategories();

      if (!isMounted) onMounted();
    }

    async function _fetchChartCategories() {
      try {
        const { data: categories } = await ReservedSeatingService.getReservedSeatingChartCategories(props.chartKey);

        if (Array.isArray(categories) && categories.length > 0) {
          setOptions(categories.map(cat => cat.label));
          if (isNil(props.initialSelectedItem)) props.onChange(categories[0].label);
        }

        setIsLoading(false);
      } catch (error) {
        addNotification({
          type: 'error',
          message: 'Error fetching chart categories. Please try again later or contact support for help.'
        });
        setIsLoading(false);
      }
    }
  }, [retryCount, isMounted]);

  if (isLoading)
    return (
      <StyledTextInput
        labelText='Category'
        value='Loading...'
        light={false}
        disabled
        id='gf-reserved-seating-category-loading'
      />
    );

  if (!isLoading && !isNil(options) && options?.length === 0) {
    return (
      <div className='gf-reserved-seating-category-failure'>
        <StyledTextInput
          labelText='Category'
          value={props.value}
          onChangeText={ev => {
            props.onChange(ev.target.value);
          }}
          light={false}
          placeholder='Failed to load categories'
          disabled
          id='gf-reserved-seating-category-input'
        />

        {retryCount < 3 ? (
          <Button
            renderIcon={Icons.Reset}
            iconDescription='Retry loading categories'
            onClick={() => {
              setRetryCount((prev: number) => prev + 1);
            }}
            kind='ghost'
          />
        ) : (
          <Link href='https://schoolsupport.gofan.co/hc/en-us/requests/new' target='_blank' rel='noopener noreferrer'>
            <Button renderIcon={Icons.Help} iconDescription='Contact support' kind='ghost' />
          </Link>
        )}
      </div>
    );
  }

  return (
    <StyledDropdown
      {...omit(props, ['venueId'])}
      titleText='Category'
      label=''
      initialSelectedItem={props.initialSelectedItem}
      selectedItem={!isNil(props.value) ? props.value : undefined}
      items={options}
      onChange={props.onChange}
      invalid={props.invalid}
      invalidText={props.invalidText}
      id='gf-reserved-seating-category-select'
    />
  );
};

export { ReservedSeatingCategorySelect };
