import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { Close16, Search16 } from '@carbon/icons-react';
import type { UserDTO } from '@gofan/api/users';
import SectionInfo from '@accounts/components/section-info/section-info.component';
import BasicButton from '@app/commons/generic-components/basic-button/basic-button.component';
import { DatePicker, DatePickerInput, ComboBox } from 'carbon-components-react';
import FormField from '@events/components/form-field/form-field.component';

import './upload-filters.component.scss';

export type FilterType = {
  uploadedBy?: string;
  uploadedDate?: Date;
};

export const DEFAULT_FILTER: FilterType = {
  uploadedBy: undefined,
  uploadedDate: undefined
};

type UploadFiltersProps = {
  open: boolean;
  users: UserDTO[];
  filterValues: FilterType;
  onClose: () => void;
  onApply: ({ uploadedBy, uploadedDate }: FilterType) => void;
};

const UploadFilters = ({ open, users, filterValues, onClose, onApply }: UploadFiltersProps) => {
  const [selectedUser, setSelectedUser] = useState<Record<string, any>>();
  const [uploadedDate, setUploadedDate] = useState<Date>();

  const listUsers = useMemo(
    () =>
      users.map(({ email }) => ({
        id: email,
        label: email
      })),
    [users]
  );

  useEffect(() => {
    setSelectedUser(listUsers.find(({ label }) => `${label}` === `${filterValues.uploadedBy}`) ?? {});
    setUploadedDate(filterValues.uploadedDate);
  }, [filterValues, open, listUsers]);

  return (
    <div className='upload-filter' id='upload-filter' hidden={!open}>
      <div className='overlay' aria-hidden='true' onClick={onClose} />
      <SectionInfo
        title='Filters'
        className='container'
        buttons={[
          {
            text: '',
            kind: 'ghost',
            renderIcon: Close16,
            className: 'upload-filter--close-btn',
            onClick: onClose
          }
        ]}
      >
        <div className='body'>
          <div className='group-filter'>
            <FormField className='uploaded-by' title='Uploaded by'>
              <ComboBox id='uploaded-by' items={listUsers} selectedItem={selectedUser} onChange={_onSelectedUser} />
              <Search16 className='search-btn' />
            </FormField>
          </div>
          <div className='group-filter'>
            <FormField title='Date'>
              <DatePicker
                value={uploadedDate}
                onChange={date => {
                  if (date) setUploadedDate(date[0]);
                }}
                id='gf-access-codes-end-date'
                datePickerType='single'
              >
                <DatePickerInput placeholder='mm/dd/yyyy' labelText='Uploaded on' id='date-picker-input-end' />
              </DatePicker>
            </FormField>
          </div>
        </div>
        <div className='footer'>
          <div className='button-action'>
            <BasicButton
              size='field'
              kind='secondary'
              textAlign='center'
              text='Reset all filters'
              disabled={isEqual(DEFAULT_FILTER, {
                uploadedBy: selectedUser?.label,
                uploadedDate
              })}
              onClick={_onResetFilters}
            />
          </div>
          <div className='button-action'>
            <BasicButton
              size='field'
              kind='tertiary'
              textAlign='center'
              text='Apply'
              disabled={isEqual(filterValues, {
                uploadedBy: selectedUser?.label,
                uploadedDate
              })}
              onClick={_onApplyFilters}
            />
          </div>
        </div>
      </SectionInfo>
    </div>
  );

  function _onSelectedUser({ selectedItem }: any) {
    setSelectedUser(selectedItem);
  }

  function _onApplyFilters() {
    onApply({
      uploadedBy: selectedUser?.label,
      uploadedDate
    });
    onClose();
  }
  function _onResetFilters() {
    setSelectedUser({});
    setUploadedDate(DEFAULT_FILTER.uploadedDate);
  }
};

export default UploadFilters;
