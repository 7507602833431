import axios from 'axios';
import get from 'lodash/get';
import { getQueryString } from '@gofan/utils/object';

import {
  GET_PAYMENT_STATEMENT,
  GET_PAYMENT_STATEMENT_FILES_BY_ID,
  GET_PAYMENT_EVENTS,
  GET_PAYMENT_STATEMENT_FILES_BY_IDS,
  GET_PAYMENT_EVENTS_FILES_BY_ID,
  GET_PAYMENT_SEASONS,
  GET_PAYMENT_SEASONS_FILES_BY_ID,
  GET_STATEMENT_PDF_BASE64
} from './financial.endpoints';

import type { PaymentSearchDTO, PaymentEventQuery, PaymentSeasonQuery } from './financial.model';

class FinancialRepository {
  static getPaymentStatement = (params: PaymentSearchDTO): Promise<any> =>
    axios
      .get(
        `${GET_PAYMENT_STATEMENT.replace('{financialSchoolId}', `${params.financialSchoolId}`)}${getQueryString({
          paymentCycle: params.paymentCycle,
          paymentPeriod: params.paymentPeriod,
          eventId: params.eventId
        })}`
      )
      .then(resp => {
        const result = get(resp, 'data.payment_statements', []);
        return result;
      });

  static downloadPaymentStatementById = (id: string): Promise<any> =>
    axios.get(GET_PAYMENT_STATEMENT_FILES_BY_ID.replace('{id}', `${id}`), {
      headers: {
        Accept: 'application/zip'
      },
      responseType: 'blob'
    });

  static getPaymentEvents = (params: PaymentEventQuery): Promise<any> =>
    axios.get(`${GET_PAYMENT_EVENTS}${getQueryString({ ...params })}`).then((resp: any) => {
      const result = get(resp, 'data.payment_events', []);
      return result;
    });

  static downloadPaymentStatementByIds = (ids: string): Promise<any> =>
    axios.get(`${GET_PAYMENT_STATEMENT_FILES_BY_IDS}?ids=${ids}`, {
      headers: {
        Accept: 'application/zip'
      },
      responseType: 'blob'
    });

  static downloadPaymentEventsById = (id: string): Promise<any> =>
    axios.get(GET_PAYMENT_EVENTS_FILES_BY_ID.replace('{id}', `${id}`), {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    });

  static getPaymentSeasons = (params: PaymentSeasonQuery): Promise<any> =>
    axios.get(`${GET_PAYMENT_SEASONS}${getQueryString({ ...params })}`).then((resp: any) => {
      const result = get(resp, 'data.payment_seasons', []);

      return result;
    });

  static downloadPaymentSeasonsById = (id: string): Promise<any> =>
    axios.get<any>(GET_PAYMENT_SEASONS_FILES_BY_ID.replace('{id}', `${id}`), {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    });

  static getStatementPdfBase64 = (uuid: string): Promise<any> =>
    axios.get<any>(GET_STATEMENT_PDF_BASE64.replace('{uuid}', `${uuid}`)).then((resp: any) => resp.data);
}

export { FinancialRepository };
