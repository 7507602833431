import React from 'react';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';

const StyledEditPageStaticField = styled(Form.Field)``;

const Label = styled.label``;

export default ({ label, value, ...props }) => (
  <StyledEditPageStaticField {...props}>
    <Label>{label}</Label>
    <p style={{ height: '48px', fontSize: '18px' }}>{value}</p>
  </StyledEditPageStaticField>
);
