export const EVENT_PROCESS_STATUS = {
  REQUIRED_PROCESS: 0,
  STREAMING_PROCESS: 1,
  OPTIONAL_PROCESS: 2,
  TICKETS_PROCESS: 3
};

export const VIEW_EVENT_SWITCHER = {
  DETAILS: 'details',
  VIEW_GOFAN_PAGE: 'view-gofan-page'
};

export const PLAYOFF_EVENT_EDITING_PERMISSION = {
  SCHOOL_HOSTED: 'school hosted',
  STATE_MANDATED_RESTRICTED: 'state mandated - restricted',
  STATE_MANDATED_NOT_RESTRICTED: 'state mandated - not restricted'
};

export const ORDER_PLAYOFF_EVENT_EDITING_PERMISSION = [
  PLAYOFF_EVENT_EDITING_PERMISSION.SCHOOL_HOSTED,
  PLAYOFF_EVENT_EDITING_PERMISSION.STATE_MANDATED_NOT_RESTRICTED,
  PLAYOFF_EVENT_EDITING_PERMISSION.STATE_MANDATED_RESTRICTED
];

export const STATE_MANDATED_CAN_NOT_EDIT_FIELDS = ['price', 'financialAccount'];
export const GOFAN_SCHOOL_TYPE = {
  SECTION: 'section',
  STATE_ASSOCIATION: 'state association'
};
export const GOFAN_SCHOOL_TYPE_EDITABLE_TYPES = [GOFAN_SCHOOL_TYPE.STATE_ASSOCIATION, GOFAN_SCHOOL_TYPE.SECTION];

export default {
  EVENT_PROCESS_STATUS,
  VIEW_EVENT_SWITCHER
};
