import { SchoolConfigRepository } from './school-config.repository';

import { STATUS_CODE_404 } from '@gofan/constants';
import type { SchoolConfig } from './school-config.model';

export class SchoolConfigService {
  static getSchoolConfigBySchoolId = (schoolId: string) =>
    SchoolConfigRepository.getSchoolConfigBySchoolId(schoolId).catch(error => {
      if (error.response.status === STATUS_CODE_404) {
        return {} as SchoolConfig;
      }
      return error;
    });

  static createSchoolConfig = (body: SchoolConfig) => SchoolConfigRepository.createSchoolConfig(body);

  static updateSchoolConfig = (body: SchoolConfig) => SchoolConfigRepository.updateSchoolConfig(body);

  static bulkUpdateSchoolConfig = (body: SchoolConfig) => SchoolConfigRepository.bulkUpdateSchoolConfig(body);

  static searchSchoolConfigBySchoolIds = (schoolIds: string[]) =>
    SchoolConfigRepository.searchSchoolConfigBySchoolIds(schoolIds);
}
