// @flow

class SeasonRenewalDTO {
  constructor(props) {
    this.raw = {
      campaigns: props,
      seasonRenewalFans: props.map(renewal => renewal.seasonRenewalFans).reduce((prev, next) => [...prev, ...next])
    };
  }

  get campaigns() {
    return this.raw.campaigns;
  }

  get seasonRenewalFans() {
    return this.raw.seasonRenewalFans;
  }

  toJSON() {
    return {
      ...this.raw,
      campaigns: this.campaigns,
      seasonRenewalFans: this.seasonRenewalFans
    };
  }
}

export default SeasonRenewalDTO;
