import {
  RESET_STATE,
  SEARCH_ACCOUNTS_COMPLETED,
  SEARCH_ACCOUNTS,
  RateInformationActionTypes,
  GET_RATE_BY_ID,
  GET_RATE_BY_ID_COMPLETED,
  GET_RATE_BY_ID_SUCCESSFUL,
  GET_RATE_BY_ID_FAIL,
  FETCH_PRODUCT_TYPES,
  FETCH_PRODUCT_TYPES_COMPLETED,
  UPDATE_RATE,
  UPDATE_RATE_COMPLETED,
  CREATE_RATE,
  CREATE_RATE_COMPLETED,
  CREATE_RATE_FAIL,
  CREATE_RATE_SUCCESSFUL,
  INITIAL_DATA_COMPLETED,
  INITIAL_DATA,
  UPDATE_RATE_FAIL
} from './actionTypes';
import { RateInformationState } from './types';
import { RATE_INITIAL } from './constants';

export const initialState: RateInformationState = {
  loading: false,
  accounts: [],
  rate: RATE_INITIAL,
  initiated: false,
  productTypes: [],
  loadingSearchAccounts: false
};
export default (state = initialState, action: RateInformationActionTypes) => {
  switch (action.type) {
    case RESET_STATE: {
      return { ...initialState };
    }
    case SEARCH_ACCOUNTS: {
      return {
        ...state,
        keyword: action.payload.keyword,
        loadingSearchAccounts: true
      };
    }
    case SEARCH_ACCOUNTS_COMPLETED: {
      return {
        ...state,
        accounts: [...action.payload.accounts],
        loadingSearchAccounts: false
      };
    }
    case GET_RATE_BY_ID: {
      return {
        ...state,
        loading: true,
        initiated: false
      };
    }
    case GET_RATE_BY_ID_COMPLETED: {
      return {
        ...state,
        loading: false,
        rate: { ...action.payload.rate },
        initiated: true
      };
    }
    case GET_RATE_BY_ID_SUCCESSFUL: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_RATE_BY_ID_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case FETCH_PRODUCT_TYPES: {
      return {
        ...state,
        loading: true
      };
    }
    case FETCH_PRODUCT_TYPES_COMPLETED: {
      return {
        ...state,
        productTypes: [...action.payload.productTypes],
        loading: false
      };
    }
    case UPDATE_RATE: {
      return {
        ...state,
        loading: true
      };
    }
    case UPDATE_RATE_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case UPDATE_RATE_COMPLETED: {
      return {
        ...state,
        loading: false,
        rate: action.payload.rate
      };
    }
    case CREATE_RATE: {
      return {
        ...state,
        loading: true
      };
    }
    case CREATE_RATE_COMPLETED: {
      const { rate } = action.payload;
      return {
        ...state,
        loading: false,
        rate
      };
    }
    case CREATE_RATE_FAIL: {
      return {
        ...state
      };
    }
    case CREATE_RATE_SUCCESSFUL: {
      return {
        ...state
      };
    }
    case INITIAL_DATA: {
      return {
        ...state,
        loading: true,
        initiated: false
      };
    }
    case INITIAL_DATA_COMPLETED: {
      const { productTypes, rate } = action.payload;
      return {
        ...state,
        initiated: true,
        loading: false,
        productTypes,
        rate: { ...state.rate, ...rate }
      };
    }
    default:
      return state;
  }
};
