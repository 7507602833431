import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { MultiSelect } from '@gofan/components';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import StyledSearch from '@common/carbon-ui/atoms/StyledSearch';
import MultiSelectWithAllOpt from '@common/carbon-ui/customized/MultiSelectWithAllOpt';

import './search-selection.scss';

type SearchSelectionProps = {
  id: string;
  searching?: boolean;
  hasSearchInput?: boolean;
  hasGroupSelection?: boolean;
  items?: any[];
  selectedItems?: any[];
  defaultItemIcon?: string;
  multiSelectTitle?: string;
  searchLabel?: string;
  searchValue?: string;
  searchResults?: any[];
  searchPlaceHolderText?: string;
  onSelectItem?: (item: any) => void;
  onMenuChange?: (toggle: boolean) => void;
  onTextChange?: (searchText: string) => void;
  onChangeSelectedItems?: ({ selectedItems }: { selectedItems: any[] }) => void;
};

const noop = () => {};

const SearchSelection = ({
  id,
  searching,
  hasSearchInput,
  hasGroupSelection,
  multiSelectTitle = '',
  searchValue = '',
  searchLabel = '',
  defaultItemIcon,
  items = [],
  searchResults = [],
  selectedItems = [],
  onMenuChange = noop,
  onSelectItem = noop,
  onTextChange = noop,
  onChangeSelectedItems = noop,
  searchPlaceHolderText = ''
}: SearchSelectionProps) => {
  const [key, setKey] = useState<number>(1);
  const [selectionItems, setSelectionItems] = useState<any[]>([]);
  const [initialSelectedItems, setInitialSelectedItems] = useState<any[]>([]);
  const [multiSelectLabel, setMultiSelectLabel] = useState<string>(_getMultipSelectLabel(selectedItems ?? []));

  const MultiSelectComponent = hasGroupSelection ? MultiSelect : MultiSelectWithAllOpt;

  useEffect(() => {
    if (!isEqual(items, selectionItems) || !isEqual(selectedItems, initialSelectedItems)) {
      setSelectionItems(items);
      setInitialSelectedItems(selectedItems);
      setKey(key * -1);
    }
  }, [key, items, selectedItems, selectionItems, initialSelectedItems]);

  return (
    <div className={classNames('search-selection', { ['has-group-selection']: hasGroupSelection })}>
      {hasSearchInput && (
        <>
          <StyledSearch
            light={false}
            size='lg'
            searching={searching}
            defaultIcon={defaultItemIcon}
            value={searchValue}
            labelText={searchLabel}
            results={searchResults}
            onTextChange={onTextChange}
            onSelectItem={onSelectItem}
            placeHolderText={searchPlaceHolderText}
          />
          <div className='gs--padding-bottom__sp5' />
        </>
      )}

      <MultiSelectComponent
        id={id}
        key={key}
        label={multiSelectLabel}
        titleText={multiSelectTitle}
        items={selectionItems}
        disabled={isEmpty(selectionItems)}
        initialSelectedItems={initialSelectedItems}
        sortItems={hasGroupSelection ? _sortItems : undefined}
        itemToString={_itemToString}
        onChange={_onChangeSelectedItems}
        onMenuChange={_onMenuChange}
      />
    </div>
  );

  // The following functions are private to the component - MultiSelectWithAllOpt only
  function _itemToString(item: any) {
    const label = item?.name ?? '';

    if (!item.isGroupLabel) return label;
    return (
      <div
        key={item?.id}
        className={classNames('group-selection-school', {
          'label-only': item.isGroupLabel
        })}
      >
        {label}
      </div>
    );
  }

  function _sortItems(itemsToSort: any[]) {
    return itemsToSort?.toSorted((a, b) => {
      if (a.name === 'All' || b.name === 'All') return 1;
      return 0;
    });
  }

  function _getMultipSelectLabel(selectedItems: any[]) {
    if (selectedItems?.length === 1) {
      return 'Schools selected';
    }

    if (selectedItems?.length > 1) {
      return 'Schools selected';
    }

    return 'Choose schools';
  }

  function _onChangeSelectedItems(value: any) {
    const newSelecteditems = value?.selectedItems ?? [];
    const label = _getMultipSelectLabel(newSelecteditems);
    setMultiSelectLabel(label);

    if (typeof onChangeSelectedItems === 'function') {
      onChangeSelectedItems({ selectedItems: newSelecteditems });
    }
  }

  function _onMenuChange(toggle: boolean) {
    if (typeof onMenuChange === 'function') {
      onMenuChange(toggle);
    }
  }
};

export default SearchSelection;
