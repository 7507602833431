import { useRef, useState, useEffect } from 'react';
import isNil from 'lodash/isNil';
import xor from 'lodash/xor';

import { UnityService, useGames } from '@gofan/api';
import { FEATURE_FLAGS, useFeatureFlags } from '@gofan/hooks';

import type { EventDTO, GameDTO, SearchGame } from '@gofan/api';

interface UseGamesByEventsProps {
  events: EventDTO[];
}

export const useGamesByEvents = ({ events = [] }: UseGamesByEventsProps) => {
  const eventIdsRef = useRef<number[]>([]);
  const partnerGameIdsRef = useRef<string[]>([]);
  const gameKeysRef = useRef<string[]>([]);
  const [shouldFetchGames, setShouldFetchGames] = useState(false);
  const [games, setGames] = useState<GameDTO[]>([]);

  const { [FEATURE_FLAGS.tempPlayonSiteOnlyEventUnicorn]: renderPlayOnSiteOnlyEvent } = useFeatureFlags();

  const params: SearchGame = {
    internal: true,
    is_pixellot: true,
    size: gameKeysRef.current.length + partnerGameIdsRef.current.length
  };

  if (gameKeysRef.current.length > 0) params.key = gameKeysRef.current;
  if (partnerGameIdsRef.current.length > 0) params.partner_game_id = partnerGameIdsRef.current;

  const { data: nfhsGames, isLoading: isGamesLoading } = useGames({
    params,
    queryOptions: { enabled: shouldFetchGames }
  });

  const canCheckPlayOnSiteIcon = renderPlayOnSiteOnlyEvent && !isGamesLoading;

  useEffect(() => {
    if (!isNil(nfhsGames)) {
      setGames(nfhsGames);
      setShouldFetchGames(false);
    }
  }, [nfhsGames]);

  useEffect(() => {
    const curEventIds = events.map(e => e.id);
    if (xor(eventIdsRef.current, curEventIds).length > 0) {
      eventIdsRef.current = curEventIds;

      const gameKeys: string[] = [];
      const partnerGameIds: string[] = [];

      events.forEach(event => {
        const searchParams = UnityService.getSearchGameParams(event);

        if (Array.isArray(searchParams.key)) {
          searchParams.key.forEach(key => {
            gameKeys.push(key as string);
          });
        } else if (typeof searchParams.key === 'string') gameKeys.push(searchParams.key);
        else if (Array.isArray(searchParams.partner_game_id)) {
          searchParams.partner_game_id.forEach(partnerGameId => {
            partnerGameIds.push(partnerGameId as string);
          });
        } else if (typeof searchParams.partner_game_id === 'string') partnerGameIds.push(searchParams.partner_game_id);
      });

      gameKeysRef.current = gameKeys;
      partnerGameIdsRef.current = partnerGameIds;
      setShouldFetchGames(true);
    }
  }, [events]);

  return { games, canCheckPlayOnSiteIcon };
};
