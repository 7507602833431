import ErrorDTO from '../dto/ErrorDTO';
import HistoryDTO from '../dto/HistoryDTO';
import axios from '../api/axios';
import {
  USER_HISTORY_URI,
  ACCOUNT_HISTORY_URI,
  EVENT_HISTORY_URI
} from '../api/api';

const fetchUserHistory = searchRequest =>
  axios.huddleAxiosInstanceProxy
    .post(USER_HISTORY_URI, searchRequest.toJSON())
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response.map(history => new HistoryDTO(history));
    })
    .catch(e => new ErrorDTO(e));

const fetchAccountHistory = searchRequest => {
  const { dateRange, accountId } = searchRequest;
  return axios.huddleAxiosInstanceProxy
    .get(
      `${ACCOUNT_HISTORY_URI.replace('{id}', accountId).replace(
        '{dateRange}',
        dateRange
      )}`
    )
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response.map(history => new HistoryDTO(history));
    })
    .catch(e => new ErrorDTO(e));
};

const fetchEventHistory = searchRequest => {
  const { dateRange, id } = searchRequest;
  return axios.huddleAxiosInstanceProxy
    .get(
      `${EVENT_HISTORY_URI.replace('{id}', id).replace(
        '{dateRange}',
        dateRange
      )}`
    )
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response.map(history => new HistoryDTO(history));
    })
    .catch(e => new ErrorDTO(e));
};

export default {
  fetchUserHistory,
  fetchAccountHistory,
  fetchEventHistory
};
