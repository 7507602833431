import { useCallback, useEffect } from 'react';

import { Close16 } from '@carbon/icons-react';
import { Column, Row } from 'carbon-components-react';

import type { SeasonInsightDTO } from '@seasons/models/season.model';

import { isEmpty } from '@app/utils/objectUtils';

import { SeasonTicketSaleNumericalChart } from '../season-ticket-sale-numerical-chart/season-ticket-sale-numerical-chart';
import TicketSaleChart from '@app/modules/events/components/ticket-sale-chart/ticket-sale-chart';

import './season-insights-panel.scss';

interface SeasonInsightsPanelProps {
  seasonInsights?: SeasonInsightDTO;
  sportIcon?: string;
  seasonName?: string;
  style?: React.CSSProperties;
  onClose?: () => void;
}

const SeasonInsightsPanel = ({ sportIcon, seasonName, seasonInsights, style, onClose }: SeasonInsightsPanelProps) => {
  // Handle click outSide
  const handleClickOutside = useCallback(
    evt => {
      if (!document) return;
      const insightEle = document.getElementById('season-insights-panel');
      let targetElement = evt.target; // clicked element
      do {
        // clicked inside
        if (!document.contains(targetElement) || targetElement === insightEle) return;
        targetElement = targetElement.parentNode;
      } while (targetElement);
      // clicked outside
      if (typeof onClose === 'function') onClose();
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return (
    <div className='season-insights-panel-wrapper' style={style}>
      <Column id='season-insights-panel' className='season-insights-panel__container bx--col-padding'>
        <Close16 onClick={onClose} className='close-button' />
        <div className='season-insights-panel__header gs--font-family-san gs--productive-heading-03-semibold'>
          Season insight
        </div>

        <div className='season-insights-panel__divider' />

        {isEmpty(seasonInsights) ? (
          <div className='insight__container'>
            <div>
              <TicketSaleChart data={{ showEmptyData: true }} loading={false} />
              <div className='event-insight__divider' />
            </div>
          </div>
        ) : (
          <>
            <Row as='section' id='season-information'>
              <img src={sportIcon} alt='Activity Icon' />

              <div className='season-name gs--margin-right__sp5'>
                <div className='gs--font-family-san gs--productive-heading-03-semibold gs--text-01 gs--margin-bottom-sp2'>
                  {seasonName ?? 'TBD'}
                </div>
              </div>
            </Row>
            <div className='season-reporting'>
              <p className='gs--font-family-san gs--productive-heading-02-semibold'>Season ticket package sales</p>
              <dl className='gs--font-family-san gs--productive-heading-01-semibold'>
                <dt>To date:</dt>
                <dd>
                  <SeasonTicketSaleNumericalChart seasonInsights={seasonInsights} hasSeasonTicketPackage />
                </dd>
              </dl>
            </div>
          </>
        )}
      </Column>
    </div>
  );
};

export { SeasonInsightsPanel };
