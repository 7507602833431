import * as React from 'react';
import { Grid, Button, Header } from 'semantic-ui-react';

interface Props {
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  editing: boolean;
}

const ProfileHeader: React.SFC<Props> = ({ setEditing, editing }: Props) => {
  const handleClick = (): void => setEditing(!editing);
  return (
    <React.Fragment>
      <Grid.Column width={14}>
        <Header as='h1'>My Profile</Header>
      </Grid.Column>
      <Grid.Column width={2}>
        <Button
          onClick={handleClick}
          fluid
          color='blue'
          size='large'
          className='medium-size'
          disabled={editing}
        >
          Edit
        </Button>
      </Grid.Column>
    </React.Fragment>
  );
};

export default ProfileHeader;
