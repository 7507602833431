import React, { useRef, useMemo, useState, useEffect, createRef, useCallback } from 'react';
import classNames from 'classnames';
import { omit, isEmpty, isFunction } from 'lodash';
import type { TagTypeName } from 'carbon-components-react';
import { Tag } from 'carbon-components-react';

import type { Notification } from '@app/pages/Root/types';
import { generateSuccessMessage, generateError } from '@utils/alertUtils';
import { isOverEventEndDate } from '@api/services/EventService';
import { canEdit } from '@gofan/api/users';
import type { EventDTO } from '@events/models/event.model';
import EventService from '@events/services/events.service';

import Tooltip from '@common/carbon-ui/atoms/Tooltip';
import NotificationComponent from '@common//carbon-ui/atoms/Notification';

const getCoordinates = (tagRef?: any, isSuccess?: boolean) => {
  const xPos = -320;
  let yPos = isSuccess ? -64 : -32;
  if (!isEmpty(tagRef) && isFunction(tagRef.getBoundingClientRect)) {
    const bounds = tagRef.getBoundingClientRect();
    yPos = -(isSuccess ? 2 : 1) * bounds.height;
  }
  return { xPos, yPos };
};

interface EventVisibilityTagProps {
  event: EventDTO;
  currentUser?: any;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: any) => void;
  // eslint-disable-next-line no-unused-vars
  onSuccess?: (event: EventDTO) => void;
}

const EventVisibilityTag = ({ event, currentUser, onError, onSuccess }: EventVisibilityTagProps) => {
  const tagRef = createRef<HTMLDivElement>();
  const isUnmounted = useRef<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState(getCoordinates());
  const [notification, setNotification] = useState<Notification>();
  const { archived, canceled } = event;

  const clickable = useMemo(
    () =>
      !canceled &&
      !isOverEventEndDate(event) &&
      (canEdit(event.accountId, currentUser) || canEdit(event.financialAccountId || '', currentUser)) &&
      isEmpty(notification),
    [event, currentUser, notification]
  );

  const tagInfo = useMemo(() => {
    if (canceled) {
      return {
        type: 'red',
        label: 'Canceled'
      };
    }
    if (!archived) {
      return {
        type: 'blue',
        label: 'Visible'
      };
    }

    return {
      type: 'cool-gray',
      label: 'Hidden'
    };
  }, [archived, canceled]);

  const isTooltipHidden = !clickable || !archived || updating;

  const onClickedTag = useCallback(() => {
    if (!clickable || updating) return;
    setUpdating(true);

    EventService.updateEvent(
      EventService.omitEventForUpdating({
        ...event,
        archived: !event.archived,
        publishDateTime: undefined
      })
    )
      .then((res: EventDTO) => {
        if (isUnmounted.current) return;
        setCoordinates(getCoordinates(tagRef.current, true));
        if (event.archived) {
          setNotification(
            generateSuccessMessage(
              'You just made this event visible on your GoFan page. If this was in error, click Visible to hide the event again.',
              'Event visibility changed'
            )
          );
        }
        if (isFunction(onSuccess)) onSuccess({ ...event, ...res });
      })
      .catch((err: any) => {
        if (isUnmounted.current) return;
        setCoordinates(getCoordinates(tagRef.current));
        setNotification(generateError(err));
        if (isFunction(onError)) onError(err);
      })
      .finally(() => !isUnmounted.current && setUpdating(false));
  }, [event, clickable, updating, isUnmounted.current, tagRef.current, onSuccess]);

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );
  return (
    <div ref={tagRef} className='event-visibility-tag'>
      <Tooltip
        align='center'
        direction='top'
        tooltipText={isTooltipHidden ? '' : 'Click to make this event visible'}
        onClick={onClickedTag}
      >
        <Tag
          type={tagInfo?.type as TagTypeName}
          className={classNames({
            'gs--text-01': tagInfo.label === 'Hidden',
            clickable: clickable && !updating
          })}
        >
          {tagInfo.label}
        </Tag>
      </Tooltip>
      {!isEmpty(notification) && (
        <div className='event-visibility-tag--notification' style={{ top: coordinates.yPos, left: coordinates.xPos }}>
          <NotificationComponent
            lowContrast
            timeout={2000}
            caption=''
            kind={notification.isSuccess ? 'success' : 'error'}
            title={notification.title || ''}
            subtitle={notification.subtitle}
            onCloseButtonClick={() => setNotification(undefined)}
          />
        </div>
      )}
    </div>
  );
};

export default EventVisibilityTag;
