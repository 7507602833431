import moment from 'moment';

import { EVENT_DATE_FORMAT_WITH_TIMEZONE } from '@utils/dateUtils';

import { UPDATED_STATUS, INVALID_ACCOUNT_IDS } from '@modules/event-integrations_V2/constants/constants';

import type { EventDTO } from '@events/models/event.model';
import type { Change, GlobalEventDTO } from '@seasons/models/season.model';

export class EventUpdateRequest {
  globalEvent: GlobalEventDTO;

  goFanEvent: EventDTO;

  constructor({ globalEvent, goFanEvent }: { globalEvent?: GlobalEventDTO; goFanEvent?: EventDTO }) {
    this.globalEvent = { ...(globalEvent ?? {}) } as GlobalEventDTO;
    this.goFanEvent = { ...(goFanEvent ?? {}) } as EventDTO;
  }

  toJSON() {
    const { gofanEventId, changes = [] } = this.globalEvent;
    const endDateTime = (changes ?? [])?.find(({ field }) => field === 'endDateTime')?.value;
    const startDateTime = (changes ?? [])?.find(({ field }) => field === 'startDateTime')?.value;
    let dataChanges = [...(changes ?? [])] as Change[];
    if (startDateTime && !endDateTime) {
      const dateTimeDuration = moment(this.goFanEvent?.endDateTime).diff(moment(this.goFanEvent?.startDateTime));
      dataChanges = [
        ...dataChanges,
        {
          action: 'UPDATED',
          field: 'endDateTime',
          updatedAt: null,
          value: moment.utc(startDateTime).add(dateTimeDuration).format(EVENT_DATE_FORMAT_WITH_TIMEZONE)
        }
      ];
    }
    let updatedDataRequest: Partial<EventDTO> = {};

    dataChanges
      ?.filter(change => change.action === `${UPDATED_STATUS}`.toUpperCase())
      ?.forEach(change => {
        let changeValue = change?.value;

        if (change?.field === 'opponentAccountId') {
          changeValue = `${change?.value ?? ''}`;

          if (!changeValue || INVALID_ACCOUNT_IDS.includes(`${changeValue}`.toLowerCase())) {
            changeValue = '';
          }
        }

        updatedDataRequest = {
          ...updatedDataRequest,
          [`${change?.field}`]: changeValue
        };
      });

    return {
      ...updatedDataRequest,
      id: gofanEventId
    } as Partial<EventDTO>;
  }
}
