import { put, takeEvery, call, select } from 'redux-saga/effects';
import {
  CREATE_ACTIVITY,
  FETCH_ACTIVITIES,
  UPDATE_ACTIVITY,
  DELETE_ACTIVITY
} from './actionTypes';
import {
  fetchActivities,
  createActivityCompleted,
  createActivitySuccessful,
  fetchActivitiesSuccessful,
  fetchActivitiesCompleted,
  updateActivityCompleted,
  updateActivitySuccess,
  deleteActivitySuccessful,
  deleteActivityCompleted
} from './actions';
import { addMessage } from '../../root/actions';
import ActivityService from '../../api/services/ActivityService';
import ErrorDTO from '../../api/dto/ErrorDTO';
import ActivityDTO from '../../api/dto/ActivityDTO';
import Activity from '../../api/model/Activity';
import {
  generateFailContent,
  generateSuccessContent
} from '../../utils/alertUtils';
import { ACTIVITIES_STRING } from '../../config/strings';
import { UNEXPECTED_ERROR } from '../../api/api/constants';
import { errorMessages } from '../../commons';

export function* handleFetchAllActivities() {
  try {
    const response = yield call(ActivityService.fetchAllActivities);

    const { athleticActivities, nonAthleticActivities } = yield call(
      ActivityService.groupActivityByType,
      response
    );

    yield put(
      fetchActivitiesSuccessful(athleticActivities, nonAthleticActivities)
    );
    if (response instanceof ErrorDTO) {
      const message = generateFailContent(response.getErrorMessage());
      yield put(addMessage(message));
    }
    yield put(fetchActivitiesCompleted());
  } catch (error) {
    const message = generateFailContent(error.message);
    yield put(addMessage(message));
    yield put(fetchActivitiesCompleted());
  }
}

export function* handleCreateActivity(action) {
  try {
    const { activity } = action.payload;
    const response = yield call(ActivityService.createActivity, { ...activity, label: activity?.label?.trim() });
    if (response instanceof ActivityDTO) {
      const states = yield select();
      const newActivity = new Activity(response).toJSON();

      const athleticActivities = [...states.activity.athleticActivities];
      const nonAthleticActivities = [...states.activity.nonAthleticActivities];

      if (newActivity.athletic) {
        athleticActivities.push(newActivity);
        athleticActivities.sort((a, b) => a.label.localeCompare(b.label));
      } else {
        nonAthleticActivities.push(newActivity);
        nonAthleticActivities.sort((a, b) => a.label.localeCompare(b.label));
      }

      yield put(
        createActivitySuccessful(athleticActivities, nonAthleticActivities)
      );
      const message = generateSuccessContent(
        `${newActivity.label}${
          ACTIVITIES_STRING.MESSAGES.SUCCESSFUL_ACTIVITY_ADDED
        }`
      );
      yield put(addMessage(message));
    }
    if (response instanceof ErrorDTO) {
      const message = generateFailContent(response.getErrorMessage());
      yield put(addMessage(message));
    }
    yield put(createActivityCompleted());
  } catch (error) {
    const message = generateFailContent(error.message);
    yield put(addMessage(message));
    yield put(createActivityCompleted());
  }
}

export function* handleUpdateActivity(action) {
  try {
    const { activity } = action.payload;
    const response = yield call(ActivityService.updateActivity, { ...activity, label: activity?.label?.trim() });
    if (response instanceof ActivityDTO) {
      const states = yield select();
      const newActivity = new Activity(response).toJSON();
      const activities = [
        ...states.activity.athleticActivities,
        ...states.activity.nonAthleticActivities
      ];
      let athleticActivities = [];
      let nonAthleticActivities = [];
      const fIndex = activities.findIndex(ac => ac.id === newActivity.id);
      if (fIndex !== -1) {
        activities.splice(fIndex, 1);
      }
      activities.push(newActivity);
      athleticActivities = activities
        .filter(ac => ac.athletic)
        .sort((a, b) => a.label.localeCompare(b.label));

      nonAthleticActivities = activities
        .filter(ac => !ac.athletic)
        .sort((a, b) => a.label.localeCompare(b.label));

      yield put(
        updateActivitySuccess(athleticActivities, nonAthleticActivities)
      );
      yield put(
        addMessage(generateSuccessContent(`${newActivity.label} is updated.`))
      );
    }
    if (response instanceof ErrorDTO) {
      const message = generateFailContent(response.getErrorMessage());
      yield put(addMessage(message));
    }
    yield put(updateActivityCompleted());
  } catch (error) {
    const message = generateFailContent(error.message);
    yield put(addMessage(message));
    yield put(updateActivityCompleted());
  }
}

export function* handleDeleteActivity(action) {
  try {
    const { activity } = action.payload;
    const response = yield call(ActivityService.deleteActivity, activity.id);
    if (response === true) {
      const { nonAthleticActivities, athleticActivities } = yield select(
        state => state.activity
      );

      if (activity.athletic) {
        athleticActivities.splice(
          athleticActivities.findIndex(item => item.id === activity.id),
          1
        );
      } else {
        nonAthleticActivities.splice(
          nonAthleticActivities.findIndex(item => item.id === activity.id),
          1
        );
      }

      yield put(
        deleteActivitySuccessful(athleticActivities, nonAthleticActivities)
      );
      yield put(
        addMessage(generateSuccessContent(`${activity.label} is deleted.`))
      );
    } else {
      yield put(fetchActivities());

      const errorMessage =
        response instanceof ErrorDTO && response.data
          ? response.data.message
          : UNEXPECTED_ERROR;
      if (errorMessage.includes('associated')) {
        const message = `${activity.label} ${
          errorMessages.ASSOCIATING_UPCOMING_EVENT_MESSAGE
        }`;
        yield put(addMessage(generateFailContent(message)));
      } else {
        yield put(addMessage(generateFailContent(errorMessage)));
      }
    }
  } catch (error) {
    const message = generateFailContent(error.message);
    yield put(addMessage(message));
    yield put(deleteActivityCompleted());
  }
}

export function* watchFetchActivities() {
  yield takeEvery(FETCH_ACTIVITIES, handleFetchAllActivities);
}

export function* watchCreateActivity() {
  yield takeEvery(CREATE_ACTIVITY, handleCreateActivity);
}

export function* watchUpdateActivity() {
  yield takeEvery(UPDATE_ACTIVITY, handleUpdateActivity);
}

export function* watchDeleteActivity() {
  yield takeEvery(DELETE_ACTIVITY, handleDeleteActivity);
}

export default [
  watchFetchActivities(),
  watchCreateActivity(),
  watchUpdateActivity(),
  watchDeleteActivity()
];
