import { QueryClientProvider as TanQueryClientProvider, QueryClient, QueryCache } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { useState } from 'react';
import { addNotification } from '@gofan/utils/toast';

interface RQueryClientProviderProps {
  // external for desktop HQ, internal for mobile HQ
  clientType?: 'external' | 'internal';
  children: React.ReactNode;
}

const queryClient = _returnQueryClient();

const RQueryClientProvider = ({ clientType = 'external', children }: RQueryClientProviderProps) => {
  const [internalQueryClient] = useState(() => _returnQueryClient());

  return (
    <TanQueryClientProvider client={clientType === 'external' ? queryClient : internalQueryClient}>
      {children}
      <ReactQueryDevtools />
    </TanQueryClientProvider>
  );
};

function _returnQueryClient() {
  return new QueryClient({
    queryCache: new QueryCache({
      onError: (error: Error, query) => {
        // disableProviderErrorNotification can be used to override the default error notification
        // add disableProviderErrorNotification to you query hook as part of the query options
        if ((query.options as { disableProviderErrorNotification?: boolean }).disableProviderErrorNotification) return;

        if (query.state.status === 'error') {
          addNotification({ type: 'error', message: `Something went wrong: ${error?.message}` });
        }
      }
    })
  });
}

export { RQueryClientProvider };
