import React from 'react';
import {
  DatePicker, DatePickerInput, DatePickerProps, DatePickerInputProps
} from 'carbon-components-react';
import { isEmpty } from 'lodash';

export type BasicDatePickerConfig = {
  label: string | JSX.Element;
  placeholder?: string;
  dateFormat?: string;
  type?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
};

export type BasicDatePickerProps = DatePickerProps
& DatePickerInputProps
& BasicDatePickerConfig
& {
  name: string;
  control: any;
  initialValue: string;
  fieldValue: string;
  fieldError?: string;
  getFieldRef?: () => void;
};

const BasicDatePicker = ({
  name,
  label = '',
  dateFormat = 'm/d/Y',
  placeholder = 'mm/dd/yyyy',
  type = 'single',
  disabled,
  minDate,
  maxDate,
  className,
  maxLength,
  initialValue,
  fieldValue,
  fieldError,
  getFieldRef,
  control,
  ...other
}: BasicDatePickerProps) => (
  <DatePicker
    {...other}
    {...control}
    id={`field-${name}`}
    ref={getFieldRef}
    dateFormat={dateFormat}
    datePickerType={type}
    minDate={minDate}
    maxDate={maxDate}
  >
    <DatePickerInput
      id={`${name}-date-picker-single`}
      placeholder={placeholder}
      labelText={label}
      invalidText={fieldError}
      invalid={!isEmpty(fieldError)}
      disabled={disabled}
    />
  </DatePicker>
);

export default BasicDatePicker;
