import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { AccountService } from './account.service';
import { ACCOUNT_QUERY_KEY } from './account.endpoints';

import { addNotification } from '@gofan/utils';

import type { AccountDTO } from './account.model';
import type { QueryOptions } from '@gofan/api/shared';
import { UNEXPECTED_ERROR } from '@gofan/constants';

export interface UseAccountOptions {
  accountId: string;
  queryOptions?: QueryOptions;
}

export const useAccount = ({ accountId, queryOptions = {} }: UseAccountOptions) => {
  const queryClient = useQueryClient();

  const accountQuery = useQuery<AccountDTO>({
    queryKey: [ACCOUNT_QUERY_KEY, accountId],
    queryFn: () => AccountService.getAccountById(accountId),
    ...queryOptions
  });

  function _updateAccount(account: AccountDTO, ignoreEditLogo?: boolean, file?: File) {
    return useMutation({
      mutationFn: () => AccountService.updateAccountById(account, ignoreEditLogo, file),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [ACCOUNT_QUERY_KEY, account.id] });
      }
    });
  }

  const partialUpdateAccount = useMutation({
    mutationFn: ({
      account,
      ignoreEditLogo,
      file
    }: {
      account: Partial<AccountDTO>;
      ignoreEditLogo?: boolean;
      file?: File;
      cbFunction?: () => void;
    }) => AccountService.partialUpdateAccountById(accountId, account, ignoreEditLogo, file),
    onSuccess: async updatedAccount => {
      if (updatedAccount?.id) {
        await queryClient.invalidateQueries({ queryKey: [ACCOUNT_QUERY_KEY, updatedAccount.id] });
      }
    },
    onError: (error: any) => {
      if (error) {
        const errorMessage = error?.response?.data?.message ?? UNEXPECTED_ERROR;
        addNotification({
          type: 'error',
          message: errorMessage
        });
      }
    },
    onSettled: (_data, _error, { cbFunction }) => {
      if (typeof cbFunction === 'function') {
        cbFunction();
      }
    }
  });

  return { ...accountQuery, updateAccount: _updateAccount, partialUpdateAccount };
};
