import axios from 'axios';
import { AxiosProxy, huddleAxiosInstanceProxy } from '../axios';
import { DELETE_ASSETS_FROM_SHARED_AWS, UPLOAD_ASSETS_TO_SHARED_AWS } from '../../sponsorship';

export const putObject = (body, cb?) => {
  const formData = new FormData();
  formData.append('avatar', body.Body);
  formData.append('bucketName', body.Bucket);
  formData.append('bucketKey', body.Key);

  if (!cb) {
    return axios.post('/api/putObject', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  axios
    .post('/api/putObject', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      cb(response);
      return response;
    });
};

export const listObjects = (body, cb?) => {
  const formData = new FormData();
  formData.append('bucketName', body.Bucket);
  formData.append('prefix', body.Prefix);

  axios.post(`/api/listObjects`, formData).then(response => cb(response));
};

export const deleteObject = (body, cb?) => {
  const formData = new FormData();
  formData.append('bucketName', body.Bucket);
  formData.append('bucketKey', body.Key);

  if (!cb) {
    return axios.post('/api/deleteObject', formData);
  }

  axios.post('/api/deleteObject', formData).then(response => {
    cb(response);
    return response;
  });
};

// upload file to shared AWS S3 bucket
export const uploadFile = body => {
  const formData = new FormData();
  formData.append('file', body.body);
  formData.append('path', body.path);

  return huddleAxiosInstanceProxy.post(UPLOAD_ASSETS_TO_SHARED_AWS, formData, AxiosProxy.AXIOS_SHARED_S3_CONFIG, true);
};

// delete file to shared AWS S3 bucket
export const deleteFile = body =>
  huddleAxiosInstanceProxy.delete(
    DELETE_ASSETS_FROM_SHARED_AWS.replace('{filePath}', body.path),
    AxiosProxy.AXIOS_SHARED_S3_CONFIG,
    false
  );
