import { Analytics32 } from '@carbon/icons-react';
import './no-data.scss';

export default function NoData() {
  return (
    <div className='no-data'>
      <div className='no-data-title'>No data to display yet</div>
      <Analytics32 />
    </div>
  );
}
