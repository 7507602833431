import type { SettingSection } from '@app/modules/events/components/editor/EditorSettings';
import type { ProgressSection } from '@events/components/editor/EditorProgress';

export const SEASON_RENEWAL = 'SEASON_RENEWAL';

export const SECTIONS = {
  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  DATE: 'DATE',
  CUSTOMIZE_EMAIL: 'CUSTOMIZE_EMAIL',
  EMAIL_REMINDERS: 'EMAIL_REMINDERS'
};

export const RENEWAL_CAMPAIGN_PROGRESS: ProgressSection = {
  title: 'Campaign Settings',
  settingIndex: 0,
  settingLabel: '',
  progresses: [
    { id: SECTIONS.CREATE_CAMPAIGN, label: 'Create campaign' },
    { id: SECTIONS.DATE, label: 'Date' },
    { id: SECTIONS.CUSTOMIZE_EMAIL, label: 'Customize email' },
    { id: SECTIONS.EMAIL_REMINDERS, label: 'Email reminders' }
  ]
};

export const RENEWAL_CAMPAIGN_UPDATES: SettingSection[] = [
  {
    title: 'Your season renewal settings',
    editorSettings: [
      {
        id: '',
        label: '',
        render: () => null
      }
    ]
  }
];
