import type { VFC } from 'react';
import { useMemo } from 'react';
import type { MeterChartProps } from '@app/commons/generic-components/meter-chart/meter-chart';
import MeterChart from '@app/commons/generic-components/meter-chart/meter-chart';
import TicketSaleNumericalChart from '../ticket-sale-numerical-chart/ticket-sale-numerical-chart';

type TicketSaleByTypeData = Required<
  Pick<MeterChartProps, 'value' | 'peak' | 'label' | 'fill' | 'description' | 'suffix'>
> & { ticketSale: number; ticketSold: number; customColor?: string };

export type TicketSaleByTypeChartData = Record<string, TicketSaleByTypeData>;

interface TicketSaleByTypeChartProps {
  data: TicketSaleByTypeChartData;
  legend?: Array<{ label: string; color: string }>;
  isFundraiser?: boolean;
}

const TicketSaleByTypeChart: VFC<TicketSaleByTypeChartProps> = ({ data, legend, isFundraiser = false }) => {
  const totalTicketsSale: number = useMemo(
    () => Object.entries(data).reduce((acc, [_, ticketData]) => acc + ticketData.ticketSale, 0),
    [data]
  );
  const totalTicketsSold: number = useMemo(
    () => Object.entries(data).reduce((acc, [_, ticketData]) => acc + ticketData.ticketSold, 0),
    [data]
  );

  return (
    <div>
      <TicketSaleNumericalChart
        isTextAbove
        isFundraiser={isFundraiser}
        totalTicketsSold={totalTicketsSold}
        totalTicketsSale={totalTicketsSale}
      />
      {Object.entries(data).map(([key, chartData]) => (
        <MeterChart
          animate
          key={key}
          className='gs--margin-top-sp2 gs--padding-left__sp8'
          {...chartData}
          fill={isFundraiser && chartData.customColor ? `#${chartData.customColor}` : '#42be65'}
        />
      ))}

      {!isFundraiser && !!legend && (
        <div className='legend gs--margin-top-sp4 gs--padding-left__sp5 gs--padding-right__sp5' role='list'>
          {legend.map(item => (
            <div key={item.label} className='legend-item'>
              <div className='legend-item__color' style={{ backgroundColor: item.color }} />
              <span className='legend-item__label gs--label-01'>{item.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TicketSaleByTypeChart;
