import { FC, ReactNode, useEffect, useRef } from 'react';
import clx from 'classnames';
import { ChevronDown16 } from '@carbon/icons-react';

export interface MeterChartProps {
  animate?: boolean;
  label: ReactNode;
  value: number;
  peak?: number;
  description?: ReactNode;
  suffix?: string;
  pseudoSuffix?: boolean;
  extraSuffix?: string;
  className?: string;
  fill?: string;
  expandable?: boolean;
  isExpanded?: boolean;
  onExpand?: (isExpanding: boolean) => void;
  style?: any;
  key?: any;
}

const handleUIPeak = (peak: number) => {
  if (peak < 98) {
    return peak;
  }
  return 98;
};

const MeterChart: FC<MeterChartProps> = ({
  animate,
  value,
  suffix,
  pseudoSuffix,
  extraSuffix,
  peak,
  description,
  label,
  className,
  fill,
  expandable,
  isExpanded,
  children,
  onExpand,
  style,
  ...rest
}) => {
  const uiPeak = peak && handleUIPeak(peak);
  const meterContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (meterContainerRef.current) {
      meterContainerRef.current.style.setProperty('--filler-width', `${value}%`);
      if (fill) {
        meterContainerRef.current.style.setProperty('--filler-color', fill);
      }
    }
  }, [value, fill]);

  const handleExpand = () => {
    onExpand?.(!!isExpanded);
  };

  return (
    <div className={clx('meter-container', className)} ref={meterContainerRef} {...rest}>
      <div className='meter-wrapper'>
        <div className='meter-label'>{label}</div>
        <div className='meter-chart'>
          <div style={{ width: '75%', height: '100%' }}>
            <svg className='meter-bar' xmlns='http://www.w3.org/2000/svg' style={style}>
              <rect className='meter-filler' fill={fill} width={`${value}%`} x='0' y='0' height='100%' />
              {uiPeak && <line className='meter-peak' x1={`${uiPeak}%`} y1='0' x2={`${uiPeak}%`} y2='100%' />}
            </svg>
          </div>
          <div className='meter-chart__suffix-wrapper'>
            {extraSuffix && <div className='meter-chart__suffix-extra gs--label-01'>{extraSuffix}</div>}
            {(suffix || pseudoSuffix) && <div className='meter-chart__suffix gs--productive-heading-01'>
              {suffix}
            </div>}
            {expandable && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <div
                onClick={handleExpand}
                className={clx('meter-chart__icon', { 'meter-chart__icon--expanded': isExpanded })}
                role='button'
                tabIndex={0}
              >
                <ChevronDown16 />
              </div>
            )}
          </div>
        </div>
        <div className='meter-description gs--body-short-01'>{description}</div>
      </div>

      {isExpanded && expandable && (
        <div className='meter-expansion-panel'>
          <div className='meter-expansion-panel__content'>{children}</div>
        </div>
      )}
    </div>
  );
};

export default MeterChart;
