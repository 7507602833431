import React from 'react';
import { FastFormContext } from './FastFormBase';
import Static from './Static';

export default ({ children, ...props }) => {
  const { viewOnly } = React.useContext(FastFormContext);
  return (
    <React.Fragment>
      {viewOnly && <Static {...props} />}
      {!viewOnly && children}
    </React.Fragment>
  );
};
