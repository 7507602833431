import { isEmpty } from 'lodash';
import ErrorDTO from '../dto/ErrorDTO';
import AccountTicketDTO from '../dto/AccountTicketDTO';
import PaginationDTO from '../dto/PaginationDTO';
import axios from '../api/axios';
import {
  FETCH_ACCOUNTS_PRODUCT,
  ACCOUNT_TICKETS_URI,
  ACCOUNT_TICKET_URI
} from '../api/api';

const fetchTickets = (
  searchTicketsRequest,
  expands,
  pageRequest,
  sortableRequest
) => {
  const expand = expands.reduce((str, item) => `${str}expand=${item}&`, '');
  const uri = `${FETCH_ACCOUNTS_PRODUCT}?${expand}${pageRequest.toQueryString()}${sortableRequest.toQueryString()}`;

  return axios.huddleAxiosInstanceProxy
    .post(uri, searchTicketsRequest.toJSON())
    .then(response => {
      const { content, pageable, totalPages } = response;
      const { pageSize, pageNumber } = isEmpty(pageable) ? {} : pageable;
      return new PaginationDTO({
        pageSize,
        pageNumber,
        totalPages,
        data: content.map(ticket => new AccountTicketDTO(ticket))
      });
    })
    .catch(e => new ErrorDTO(e));
};

const createAccountTickets = body =>
  axios.huddleAxiosInstanceProxy
    .post(ACCOUNT_TICKETS_URI, body)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response.map(item => new AccountTicketDTO(item));
    })
    .catch(e => new ErrorDTO(e));

const editAccountTicket = (id, body) =>
  axios.huddleAxiosInstanceProxy
    .put(`${ACCOUNT_TICKET_URI}${id}`, body)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new AccountTicketDTO(response);
    })
    .catch(e => new ErrorDTO(e));

const getAccountTicket = id =>
  axios.huddleAxiosInstanceProxy
    .get(`${ACCOUNT_TICKET_URI}${id}`)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new AccountTicketDTO(response);
    })
    .catch(e => new ErrorDTO(e));

export default {
  fetchTickets,
  getAccountTicket,
  createAccountTickets,
  editAccountTicket
};
