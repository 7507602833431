import styled, { css } from 'styled-components';
import { bodyLong01, productiveHeading03 } from '@carbon/themes';
import { OverflowMenu as CarbonOverflowMenu } from 'carbon-components-react';

export default {
  globalStyle: css`
    body {
      .bx--overflow-menu-options::after {
        background-color: ${({ hideWhitePart }) => (hideWhitePart ? 'transparent' : '#f4f4f4')};
      }
    }
  `,
  wrapper: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #fff;
    padding: 10px 0;
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    height: 50px;
    background-color: #323232;
    z-index: 100;
  `,
  leftSection: css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `,
  menuIconWrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;

    &:hover {
      background-color: #2c2c2c;
      cursor: pointer;
    }

    svg {
      max-width: 20px;
    }
  `,
  closeIconWrapper: css`
    margin-left: -5px;
    margin-bottom: -5px;
  `,
  leftLogo: css`
    margin-top: 8px;
    margin-right: 16px;
    margin-left: 8px;
    height: 20px;
    width: 225px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `,
  rightLogo: css`
    margin-right: 90px;
    height: 30px;
    width: 135px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `,
  leftTitle: css`
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `,
  adminUserRoles: css`
    width: 200px;
    margin-right: 16px;
    background-color: #fff;
  `,
  centerSection: css`
    flex-grow: 1;
  `,
  rightSection: css`
    display: flex;
    align-items: center;
    margin-right: 16px;
  `,
  rightTitle: css`
    margin-left: 16px;
    margin-right: 16px;
    font-size: 14px;
  `,
  devider: css`
    height: 30px;
    border-left: 1px solid #fff;
    border-right: none;
  `,
  gofanLogo: css`
    height: 75px;
    width: 75px;
  `,
  useraccount: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  overflowWrapper: css`
    .bx--overflow-menu.bx--overflow-menu--open,
    .bx--overflow-menu.bx--overflow-menu--open:hover,
    .bx--overflow-menu:hover,
    .bx--overflow-menu.bx--overflow-menu--open .bx--overflow-menu__trigger {
      background-color: transparent;
    }
  `,
  userName: css`
    ${bodyLong01};
  `,
  schoolWrapper: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    //max-width: 220px;
  `,
  schoolName: css`
    ${productiveHeading03};
    padding-left: 8px;
    padding-right: 8px;
    font-weight: 700;
    font-size: 1.438rem;
    line-height: 27px;
    color: #f4f4f4;
  `,
  schoolLogo: css`
    height: 35px;
    width: 35px;
  `,
  notifiWrapper: css`
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    .bx--inline-notification {
      margin: 0;
    }
  `,
  accountName: css`
    font-size: 1rem;
    font-weight: 400;
    line-height: 22px;
  `
};

export const OverflowMenu = styled(CarbonOverflowMenu)`
  svg {
    fill: #00c9d2;
  }
  &.bx--overflow-menu--open,
  &:hover {
    svg {
      fill: #00c9d2;
    }
  }

  .bx--overflow-menu__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
