// @noflow
class UserAccountContextDTO {
  constructor({ accountActivities, accountId, accountRoles, contactInfoEmail, contactType, inactive, teamIds }) {
    this.raw = {
      accountActivities,
      accountId,
      accountRoles,
      contactInfoEmail,
      contactType,
      inactive,
      teamIds
    };
  }

  get accountActivities() {
    return this.raw.accountActivities;
  }

  get accountId() {
    return this.raw.accountId;
  }

  get accountRoles() {
    return this.raw.accountRoles;
  }

  get contactInfoEmail() {
    return this.raw.contactInfoEmail;
  }

  get contactType() {
    return this.raw.contactType;
  }

  get inactive() {
    return this.raw.inactive;
  }

  get teamIds() {
    return this.raw.teamIds;
  }

  toJSON() {
    return {
      accountActivities: this.accountActivities,
      accountId: this.accountId,
      accountRoles: this.accountRoles,
      contactInfoEmail: this.contactInfoEmail,
      contactType: this.contactType,
      inactive: this.inactive,
      teamIds: this.teamIds
    };
  }
}

export default UserAccountContextDTO;
