import { memo } from 'react';
import classNames from 'classnames';
import { getColor } from '@gofan/utils/colors/colors';
import { getSchoolImage } from '@app/pages/EventInformationV2/helpers';
import defaultSchoolIcon from '@assets/images/gofan-icon.png';

import './GameSchoolLogo.scss';

export type GameSchoolLogoProps = {
  id: string;
  name: string;
  winning: boolean;
  tie: boolean;
  logo?: string;
  primaryColor?: string;
};

export const GameSchoolLogo = memo(({ id, logo, name, winning, tie, primaryColor }: GameSchoolLogoProps) => {
  const schoolIcon = getSchoolImage(id, 'logo', logo) || defaultSchoolIcon;
  return (
    <div className='gf-game-school-logo'>
      {winning && <div data-testid='win-arrow-test' className='gf-arrow-left' />}
      <div
        className={classNames('school-logo', { 'logo-default': !logo })}
        style={{
          backgroundColor: logo ? getColor(primaryColor, '#f0f0f0') : '#000'
        }}
      >
        <img data-testid='school-logo-test' alt='Icon' src={schoolIcon} />
      </div>
      {name && <p className={`gf-school-name gf-school-name__${winning || tie ? 'primary' : 'secondary'}`}>{name}</p>}
    </div>
  );
});
