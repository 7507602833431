import { useEffect, useRef } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Dropdown, RadioButton, RadioButtonGroup, TextInput, Toggle, Button, Icons } from '../../..';
import { useAccountContacts } from '@gofan/api/accounts';
import type { AccountContact, AccountDTO } from '@gofan/api/accounts';
import type { PendoSupportFormProgress } from '../../PendoSupportForm';
import { addNotification, formatPhoneNumber } from '@gofan/utils';
import type { UserDTO } from '@gofan/api';
import { REQUEST_CONTACT_TYPES, SUPPORT_DIVISION_TYPES, ZENDESK_SUPPORT_FORM_FIELDS } from '@gofan/api';
import { PendoSchoolSearch } from '../PendoSchoolSearch';
import '../../PendoSupportForm.scss';

interface PendoSupportFormDefaultProps {
  onContinue: (nextProgress: PendoSupportFormProgress) => void;
  currentUser: UserDTO;
  isReturnFromBackBtn?: boolean;
}

// this form is shared between HQ desktop and HQ Mobile, the margin classes are different for each
const PendoSupportFormDefault = ({
  onContinue,
  currentUser,
  isReturnFromBackBtn = false
}: PendoSupportFormDefaultProps) => {
  const {
    control,
    setValue,
    formState: { errors }
  } = useFormContext();
  const supportDivision = useWatch({ control, name: ZENDESK_SUPPORT_FORM_FIELDS.SUPPORT_DIVISION }); // ticketing or streaming
  const selectedSchool = useWatch({ control, name: ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_NAME });
  const contactType = useWatch({ control, name: ZENDESK_SUPPORT_FORM_FIELDS.REQUEST_CONTACT_TYPE }); // playon or school
  const selectedSchoolContact = useWatch({ control, name: ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_REQUESTOR_EMAIL });
  const isInitialMount = useRef(true);
  const { data: schoolContacts, error: schoolContactsError } = useAccountContacts({
    schoolId: selectedSchool?.id,
    queryOptions: { enabled: !!selectedSchool?.id }
  });
  const isSchoolContactUnknown = selectedSchoolContact.email === 'Unknown';

  if (schoolContactsError) {
    addNotification({ type: 'error', message: 'Error getting contacts, try again later' });
  }

  useEffect(() => {
    // when loading new school data, wipe any old contact selection
    // if selected contact is in the schoolContacts list, keep it
    // if selected contact is 'Unknown', keep it
    // else new school was selected, wipe the contact selection
    const isSelectedContactInSchool = schoolContacts?.find(contact => contact.email === selectedSchoolContact.email);
    if (!isSelectedContactInSchool && selectedSchoolContact.email !== 'Unknown') {
      setValue('schoolRequestorEmail', { email: '' });
    }
  }, [schoolContacts]);

  useEffect(() => {
    if (selectedSchoolContact.email && !isInitialMount.current)
      _handleSelectedSchoolContactChange(selectedSchoolContact);
  }, [selectedSchoolContact]);

  useEffect(() => {
    if (currentUser) {
      _handleContactTypeChange(contactType);
    }
  }, [contactType, currentUser]);

  return (
    <>
      <div className='gf-sub-title'>Who is the contact for this request?</div>
      <Controller
        name={ZENDESK_SUPPORT_FORM_FIELDS.REQUEST_CONTACT_TYPE}
        control={control}
        render={({ field, fieldState }) => (
          <RadioButtonGroup
            {...field}
            name={ZENDESK_SUPPORT_FORM_FIELDS.REQUEST_CONTACT_TYPE}
            value={field.value}
            invalid={!!fieldState.error}
            invalidText={fieldState.error?.message}
            onChange={value => field.onChange(value)}
            className='gs--margin-bottom-sp6 gs--margin-top-sp5 gs--margin-bottom__sp6 gs--margin-top__sp5'
          >
            <RadioButton
              id='radio-playon-contact'
              labelText='PlayOn Employee'
              value={REQUEST_CONTACT_TYPES.PLAYON}
              checked={field.value === REQUEST_CONTACT_TYPES.PLAYON}
            />
            <RadioButton
              id='radio-school-contact'
              labelText='School contact'
              value={REQUEST_CONTACT_TYPES.SCHOOL}
              checked={field.value === REQUEST_CONTACT_TYPES.SCHOOL}
            />
          </RadioButtonGroup>
        )}
      />
      <div className='gf-sub-title'>What is your issue related to?</div>
      <Controller
        name={ZENDESK_SUPPORT_FORM_FIELDS.SUPPORT_DIVISION}
        control={control}
        render={({ field, fieldState }) => (
          <RadioButtonGroup
            {...field}
            name={ZENDESK_SUPPORT_FORM_FIELDS.SUPPORT_DIVISION}
            value={field.value}
            invalid={!!fieldState.error}
            invalidText={fieldState.error?.message}
            onChange={value => field.onChange(value)}
            className='gs--margin-bottom-sp6 gs--margin-top-sp5 gs--margin-bottom__sp6 gs--margin-top__sp5'
          >
            <RadioButton
              id='radio-ticketing'
              labelText='Ticketing'
              value={SUPPORT_DIVISION_TYPES.TICKETING}
              checked={field.value === SUPPORT_DIVISION_TYPES.TICKETING}
            />
            <RadioButton
              id='radio-streaming'
              labelText='Streaming'
              value={SUPPORT_DIVISION_TYPES.STREAMING}
              checked={field.value === SUPPORT_DIVISION_TYPES.STREAMING}
            />
          </RadioButtonGroup>
        )}
      />

      <div className='gf-sub-title gs--margin-bottom-sp4 gs--margin-bottom__sp4'>
        Enter Information For Your Request
      </div>
      <div className='cds--label'>School search</div>
      <div
        className={`gs--margin-bottom-sp5 gs--margin-bottom__sp5 ${
          errors[ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_NAME] ? 'gf-input-error' : ''
        }`}
      >
        <PendoSchoolSearch onSchoolSelect={_handleSelectedAccount} />

        {errors[ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_NAME] && (
          <div className='gf-error-message'>
            {(errors[ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_NAME] as any).name.message}
          </div>
        )}
      </div>

      <div className={`gf-school-name ${selectedSchool?.id ? 'show' : ''}`}>
        <Icons.CheckmarkFilled />
        <Controller
          name={ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_NAME}
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              value={field.value.name || ''}
              labelText=''
              id={ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_NAME}
              className='gs--margin-bottom-sp5 gs--margin-bottom__sp5'
              onChange={({ target: { value } }) => {
                field.onChange({ ...field.value, name: value });
              }}
              disabled
            />
          )}
        />
      </div>

      {contactType === REQUEST_CONTACT_TYPES.SCHOOL && (
        <Controller
          name={ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_REQUESTOR_EMAIL}
          control={control}
          render={({ field, fieldState }) => (
            <Dropdown
              {...field}
              titleText='Submit a ticket on behalf of'
              className='gs--margin-bottom-sp5 gs--margin-bottom__sp5'
              id={ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_REQUESTOR_EMAIL}
              items={[...(schoolContacts?.filter(contact => !contact.inactive) ?? []), { email: 'Unknown' }]}
              label='Choose an option'
              itemToString={(contact: AccountContact) => contact.email}
              invalid={!!fieldState.error}
              invalidText={fieldState.error?.message}
              selectedItem={field.value || null}
              onChange={({ selectedItem }: { selectedItem: AccountContact | { email: string } }) => {
                field.onChange(selectedItem);
              }}
            />
          )}
        />
      )}
      {selectedSchoolContact.email === 'Unknown' && contactType === REQUEST_CONTACT_TYPES.SCHOOL && (
        <div className='gf-warning-msg'>
          <Icons.Warning className='gf-icon-yellow' width='20px' height='20px' />
          By selecting &quot;Unknown&quot;, you are now set as the main requester on this support ticket.
        </div>
      )}

      <Controller
        name={ZENDESK_SUPPORT_FORM_FIELDS.NAME}
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            id={ZENDESK_SUPPORT_FORM_FIELDS.NAME}
            labelText={
              contactType === REQUEST_CONTACT_TYPES.PLAYON || isSchoolContactUnknown
                ? "CSM's Name"
                : "School Contact's Name"
            }
            placeholder={
              contactType === REQUEST_CONTACT_TYPES.PLAYON || isSchoolContactUnknown
                ? "Enter CSM's name"
                : "Enter school contact's name"
            }
            className='gs--margin-bottom-sp5 gs--margin-bottom__sp5'
            value={field.value}
            invalid={!!fieldState.error}
            invalidText={fieldState.error?.message}
            onChange={({ target: { value } }) => field.onChange(value)}
            onBlur={() => {
              field.onChange(field.value);
              field.onBlur();
            }}
          />
        )}
      />

      <Controller
        name={ZENDESK_SUPPORT_FORM_FIELDS.REQUESTOR_EMAIL}
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            id={ZENDESK_SUPPORT_FORM_FIELDS.REQUESTOR_EMAIL}
            labelText={
              contactType === REQUEST_CONTACT_TYPES.PLAYON || isSchoolContactUnknown
                ? "CSM's Email"
                : "School Contact's Email"
            }
            placeholder={
              contactType === REQUEST_CONTACT_TYPES.PLAYON || isSchoolContactUnknown
                ? "Enter CSM's email"
                : "Enter school contact's email"
            }
            className='gs--margin-bottom-sp5 gs--margin-bottom__sp5'
            value={field.value}
            invalid={!!fieldState.error}
            invalidText={fieldState.error?.message}
            onChange={({ target: { value } }) => field.onChange(value)}
            onBlur={() => {
              field.onChange(field.value);
              field.onBlur();
            }}
          />
        )}
      />

      <Controller
        name={ZENDESK_SUPPORT_FORM_FIELDS.PHONE_NUMBER}
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            id={ZENDESK_SUPPORT_FORM_FIELDS.PHONE_NUMBER}
            labelText={
              contactType === 'playon' || isSchoolContactUnknown
                ? "CSM's phone number"
                : "School Contact's Phone Number"
            }
            placeholder={
              contactType === 'playon' || isSchoolContactUnknown
                ? "Enter CSM's phone number"
                : "Enter school contact's phone Number"
            }
            className='gs--margin-bottom-sp5 gs--margin-bottom__sp5'
            value={field.value}
            invalid={!!fieldState.error}
            invalidText={fieldState.error?.message}
            onChange={({ target: { value } }) => field.onChange(formatPhoneNumber(value))}
            onInput={({ target }) => {
              const input = target as HTMLInputElement;
              field.onChange(formatPhoneNumber(input.value));
            }}
            onBlur={() => {
              field.onChange(field.value);
              field.onBlur();
            }}
          />
        )}
      />

      {contactType === REQUEST_CONTACT_TYPES.SCHOOL && !isSchoolContactUnknown && (
        <Controller
          name={ZENDESK_SUPPORT_FORM_FIELDS.PREFERRED_CONTACT_METHOD}
          control={control}
          render={({ field, fieldState }) => (
            <Dropdown
              {...field}
              titleText="School Contact's Preferred Contact Method"
              className='gs--margin-bottom-sp5 gs--margin-bottom__sp5'
              id={ZENDESK_SUPPORT_FORM_FIELDS.PREFERRED_CONTACT_METHOD}
              items={['Email', 'Phone Call', 'SMS']}
              label='Choose an option'
              invalid={!!fieldState.error}
              invalidText={fieldState.error?.message}
              selectedItem={field.value || ''}
              onChange={({ selectedItem }: { selectedItem: AccountContact }) => {
                field.onChange(selectedItem);
              }}
            />
          )}
        />
      )}

      {contactType === REQUEST_CONTACT_TYPES.SCHOOL && !isSchoolContactUnknown && (
        <Controller
          name={ZENDESK_SUPPORT_FORM_FIELDS.UPDATE_REQUEST}
          control={control}
          render={({ field }) => (
            <Toggle
              {...field}
              id={ZENDESK_SUPPORT_FORM_FIELDS.UPDATE_REQUEST}
              labelA='No'
              labelB='Yes'
              labelText='Would you like to receive updates from this request?'
              toggled={field.value}
              onToggle={toggled => field.onChange(toggled)}
            />
          )}
        />
      )}

      <div className='gf-button-wrapper gs--margin-top-sp5 gs--margin-top__sp5'>
        <Button
          type='button'
          className='gs--margin-top-sp5 gs--margin-top__sp5'
          onClick={() => onContinue(supportDivision)}
        >
          Continue
        </Button>
      </div>
    </>
  );

  function _handleSelectedAccount(school: AccountDTO) {
    // updated the selected school in the form when one is selected from the school picker
    setValue(ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_NAME, school, { shouldValidate: true });
  }

  function _handleSelectedSchoolContactChange(selectedContact: AccountContact) {
    // when contact type is 'school' and selecting a school contact from the ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_REQUESTOR_EMAIL dropdown,
    // populate the email, name, and phone number fields
    // if the selected contact is 'Unknown', use the current user's info
    let email = '';
    let name = '';
    let phone = '';

    // we need to check if the user has come from the back button, if so, we don't want to reset the form fields
    // isInitialMount.current is used to check the first render of the component on back button click
    if (isInitialMount.current && isReturnFromBackBtn) {
      isInitialMount.current = false;
      return;
    }

    isInitialMount.current = false;

    if (contactType === REQUEST_CONTACT_TYPES.PLAYON || isSchoolContactUnknown) {
      email = currentUser?.email;
      name = `${currentUser.firstName}${currentUser.firstName ? ' ' : ''}${currentUser.lastName}`;
      phone = currentUser?.phoneNumber ? formatPhoneNumber(currentUser.phoneNumber) : '';
      // if contact type is unknown, we will use the CSM's as the requester, contact method can only be email
      setValue(ZENDESK_SUPPORT_FORM_FIELDS.PREFERRED_CONTACT_METHOD, 'Email', { shouldValidate: true });
    }
    if (contactType === REQUEST_CONTACT_TYPES.SCHOOL && !isSchoolContactUnknown) {
      email = selectedContact?.email || '';
      name = `${selectedContact?.firstName || ''}${selectedContact?.firstName ? ' ' : ''}${
        selectedContact?.lastName || ''
      }`;
      phone = ''; // phone is not available in the school contact data
    }

    setValue(ZENDESK_SUPPORT_FORM_FIELDS.REQUESTOR_EMAIL, email, { shouldValidate: true });
    setValue(ZENDESK_SUPPORT_FORM_FIELDS.NAME, name, { shouldValidate: true });
    setValue(ZENDESK_SUPPORT_FORM_FIELDS.PHONE_NUMBER, phone, {
      shouldValidate: true
    });
  }

  function _handleContactTypeChange(type: REQUEST_CONTACT_TYPES) {
    // set/clear the default values for the contact fields when toggling between playon and school contact types
    // for playon, use the current user's info
    // for school, clear the fields, these will be populated via the ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_REQUESTOR_EMAIL dropdown change after selecting a school
    const formattedPhone = currentUser?.phoneNumber ? formatPhoneNumber(currentUser?.phoneNumber) : '';
    const email = type === REQUEST_CONTACT_TYPES.PLAYON ? currentUser?.email : selectedSchoolContact?.email || '';
    const name =
      type === REQUEST_CONTACT_TYPES.PLAYON
        ? `${currentUser.firstName || ''}${currentUser.firstName ? ' ' : ''}${currentUser.lastName || ''}`
        : `${selectedSchoolContact?.firstName || ''} ${selectedSchoolContact?.lastName || ''}`;
    const phone = type === REQUEST_CONTACT_TYPES.PLAYON ? formattedPhone : '';

    // if the contact type is playon, clear the school contact dropdown selection
    if (contactType === REQUEST_CONTACT_TYPES.PLAYON) {
      setValue(
        ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_REQUESTOR_EMAIL,
        { selectedSchoolContact: { email: '' } },
        { shouldValidate: false }
      );
    }

    // we need to check if the user has come from the back button, if so, we don't want to reset the form fields
    // isInitialMount.current is used to check the first render of the component on back button click
    if (isInitialMount.current && isReturnFromBackBtn) {
      isInitialMount.current = false;
      return;
    }

    isInitialMount.current = false;

    setValue(ZENDESK_SUPPORT_FORM_FIELDS.REQUESTOR_EMAIL, email, { shouldValidate: email !== '' });
    setValue(ZENDESK_SUPPORT_FORM_FIELDS.NAME, name, { shouldValidate: name !== '' });
    setValue(ZENDESK_SUPPORT_FORM_FIELDS.PHONE_NUMBER, phone, { shouldValidate: phone !== '' });
    if (type === REQUEST_CONTACT_TYPES.PLAYON) {
      setValue(ZENDESK_SUPPORT_FORM_FIELDS.PREFERRED_CONTACT_METHOD, 'Email', { shouldValidate: true });
    }
  }
};

export { PendoSupportFormDefault };
