import { AccountFinancialRepository } from './account-financial.repository';

import type { AccountHistorySearchDTO } from '../accounts/account.model';
import type { AccountFinancialDTO } from './account-financial.model';
import type { HistoryDTO } from '../history/history.model';
import { STATUS_CODE_404 } from '@gofan/constants';

class AccountFinancialService {
  static getAccountFinancialByAccountId = (
    accountId: string,
    manualErrorHandling?: boolean
  ): Promise<AccountFinancialDTO> =>
    new Promise<AccountFinancialDTO>((resolve, reject) => {
      AccountFinancialRepository.getAccountFinancialByAccountId(accountId, manualErrorHandling)
        .then(res => resolve(res))
        .catch((err: any) => {
          if (err.response && err.response.status === STATUS_CODE_404) {
            return resolve({} as AccountFinancialDTO);
          }
          return reject(err);
        });
    });

  static getAccountFinancialByAccountIdWithErrors = (
    accountId: string,
    manualErrorHandling?: boolean
  ): Promise<AccountFinancialDTO> =>
    new Promise<AccountFinancialDTO>((resolve, reject) => {
      AccountFinancialRepository.getAccountFinancialByAccountId(accountId, manualErrorHandling)
        .then(res => resolve(res))
        .catch((err: any) => reject(err));
    });

  static getAccountFinancialHistory = (
    params: AccountHistorySearchDTO,
    manualErrorHandling: boolean
  ): Promise<HistoryDTO[]> =>
    new Promise<HistoryDTO[]>((resolve, reject) => {
      AccountFinancialRepository.getAccountFinancialHistory(params, manualErrorHandling)
        .then(res => resolve(res))
        .catch((err: any) => {
          if (err.response && err.response.status === STATUS_CODE_404) {
            return resolve([]);
          }
          return reject(err);
        });
    });

  static updateAccountFinancialById = (
    accountFinancial: AccountFinancialDTO,
    achResultReviewed?: boolean
    // achResultReviewed is used to force save the bank routing and account number, on TRUE, even a failed Accelitas verification will save the info to the DB
  ): Promise<AccountFinancialDTO> =>
    AccountFinancialRepository.updateAccountFinancialById(accountFinancial, achResultReviewed);

  // patch is the new endpoint for handling the new Financial Settings UI, included paymentCycle
  // there are 2 versions of this endpoint, the V2 is used for the new ACH Giact verification
  static patchAccountFinancialById = (
    accountFinancial: AccountFinancialDTO,
    achResultReviewed?: boolean,
    // achResultReviewed is used to force save the bank routing and account number, on TRUE, even a failed Accelitas verification will save the info to the DB
    version: 1 | 2 = 1 // 2 is the new version, 1 is the old version
  ): Promise<AccountFinancialDTO> =>
    AccountFinancialRepository.patchAccountFinancialById(accountFinancial, achResultReviewed, version);

  // there are 2 versions of this endpoint, the V2 is used for the new ACH Giact verification
  static createAccountFinancial = (
    accountFinancial: AccountFinancialDTO,
    achResultReviewed?: boolean,
    version: 1 | 2 = 1 // 2 is the new version, 1 is the old version
  ): Promise<AccountFinancialDTO> =>
    AccountFinancialRepository.createAccountFinancial(accountFinancial, achResultReviewed, version);
}

export { AccountFinancialService };
