import React from 'react';
import ReactDOM from 'react-dom';
import omit from 'lodash/omit';

import './Overlay.scss';

interface OverlayProps extends React.HTMLProps<HTMLDivElement> {
  open: boolean;
  containerStyles?: { [key: string]: string | number };
  children?: React.ReactNode;
}

const Overlay = (props: OverlayProps) => {
  const root = React.useRef(document.createElement('div'));
  const htmlProps = omit(props, ['open', 'containerStyles', 'children']);

  React.useEffect(() => {
    if (props.open) {
      window.scrollTo(0, 0);

      document.body.appendChild(root.current);
      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.removeChild(root.current);
        document.body.style.height = 'auto';
        document.body.style.overflow = 'auto';
      };
    }
  }, [props.open]);

  return ReactDOM.createPortal(
    <div className='gf-overlay-container' style={props.containerStyles ?? {}}>
      <div {...htmlProps} className={`gf-overlay-content ${htmlProps?.className ? htmlProps.className : ''}`}>
        {props.children}
      </div>
    </div>,
    root.current
  );
};

export { Overlay };
