export const ATHLETIC = {
  id: 'ATHLETIC',
  label: 'Athletic'
};

export const NON_ATHLETIC = {
  id: 'NON_ATHLETIC',
  label: 'Non Athletic'
};

export const MODE = {
  ADD: 'add',
  EDIT: 'edit',
  VIEW: 'view'
};
