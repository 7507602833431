import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import type { AccountDTO } from '@gofan/api';
import { getQueryString } from '@gofan/utils';
import { config, SCHOOL_TYPES } from '@gofan/constants';

import defaultSchoolIcon from '@assets/images/gofan-icon.png';
import { PAGES } from '@app/config/routes';

export function generateAddressInfo({
  streetAddress,
  streetAddressTwo,
  state,
  city,
  zip
}: {
  streetAddress?: AccountDTO['streetAddress'];
  streetAddressTwo?: AccountDTO['streetAddressTwo'];
  state: AccountDTO['state'];
  city: AccountDTO['city'];
  zip: AccountDTO['zip'];
}) {
  const stateAndZipCode = [state, zip].filter(text => !isEmpty(text)).join(' ');
  return [streetAddress, streetAddressTwo, city, stateAndZipCode].filter(text => !isEmpty(text)).join(', ');
}

export function generateStreetAddress({
  streetAddress,
  streetAddressTwo
}: {
  streetAddress?: AccountDTO['streetAddress'];
  streetAddressTwo?: AccountDTO['streetAddressTwo'];
}) {
  return [streetAddress, streetAddressTwo].filter(text => !isEmpty(text)).join(', ');
}

export function getAccountLogo(accountId: string, imageName: string | undefined | null) {
  if (isEmpty(imageName)) return defaultSchoolIcon;
  return `https://${config.s3.BUCKET}.${config.s3.URL}/${config.s3.BASE_PATH}/logo/${accountId}/${imageName}?cacheBlock=true`;
}

export function getAccountAssociationsLogo(
  account: {
    id: AccountDTO['id'];
    stateAssociationHuddleId: AccountDTO['stateAssociationHuddleId'];
    districtHuddleId: AccountDTO['districtHuddleId'];
  },
  associations: {
    id: AccountDTO['id'];
    logo: AccountDTO['logo'];
  }[]
): { stateAssociationLogo: string | undefined; districtLogo: string | undefined } {
  const { stateAssociationHuddleId, districtHuddleId } = account;
  const state = associations.find(item => item.id === stateAssociationHuddleId);
  const district = associations.find(item => item.id === districtHuddleId);
  return {
    stateAssociationLogo: state ? getAccountLogo(stateAssociationHuddleId, state.logo) : undefined,
    districtLogo: district ? getAccountLogo(districtHuddleId, district.logo) : undefined
  };
}

export function getAccountPrimaryColor(primaryColor?: string | null) {
  const defaultColor = '#323232';

  const REG_HEX_COLOR = /^#?([a-f0-9]{6}|[a-f0-9]{3})$/;
  if (!primaryColor) return defaultColor;
  const normColor = primaryColor.includes('#', 0) ? primaryColor : `#${primaryColor}`;
  if (REG_HEX_COLOR.test(normColor.toLowerCase())) return normColor;
  return defaultColor;
}

export function getAccountSlogan(shortName: AccountDTO['shortName'] | undefined, name: AccountDTO['name'] | undefined) {
  const text = shortName || name || 'GoFan';
  return `Go ${text}!`;
}

export function isAccountActive(status: AccountDTO['status']) {
  if (status) return status.toLowerCase() === 'active';
  return false;
}

export function getAccountPageUrl(id: string) {
  return `${config.GOFAN_URL}app/school/${id}`;
}
export const getAccountDetailsUrl = (accountId: string) => PAGES.accountsV2.details.calculatedPath(accountId);
export const getAccountGoFanPageUrl = (accountId: string) => PAGES.accountsV2.gofanPage.calculatedPath(accountId);
export const getAccountFinancialUrl = (accountId: string) => PAGES.accountsV2.financial.calculatedPath(accountId);
export const getAccountLandingUrl = (accountId: string, params?: { mode: string }) =>
  PAGES.accountsV2.landing.calculatedPath(`${accountId}${params ? getQueryString(params) : ''}#account`);

export function checkIsAccountInConference(account: AccountDTO, conferenceId: string) {
  if (isEmpty(account) || isEmpty(conferenceId)) {
    return false;
  }
  if (
    (account.sfId === conferenceId && account.gofanSchoolType === SCHOOL_TYPES.ATHLETIC_CONFERENCE) ||
    account.conferenceLeagueC === conferenceId
  ) {
    return true;
  }
  return false;
}

export function searchSchools(schools: AccountDTO[], keyword: string) {
  if (isEmpty(schools) || !keyword || keyword.trim() === '') {
    return [];
  }

  const lowerCaseKeyword = keyword.toLowerCase();

  return schools.filter(
    school =>
      school.customPageName?.toLowerCase()?.includes(lowerCaseKeyword) ||
      school.state?.toLowerCase()?.includes(lowerCaseKeyword) ||
      school.name?.toLowerCase()?.includes(lowerCaseKeyword) ||
      school.city?.toLowerCase()?.includes(lowerCaseKeyword) ||
      school.mascot?.toLowerCase()?.includes(lowerCaseKeyword) ||
      school.shortName?.toLowerCase()?.includes(lowerCaseKeyword) ||
      school.sfId?.toLowerCase()?.includes(lowerCaseKeyword)
  );
}

export function mergeSchools(array1: AccountDTO[], array2: AccountDTO[]) {
  const combinedArray = [...array1, ...array2];
  const uniqueSchools = new Map();

  // Iterate through the combined array and filter out duplicates by id
  combinedArray.forEach(school => {
    if (!uniqueSchools.has(school.id)) {
      uniqueSchools.set(school.id, school);
    }
  });

  return Array.from(uniqueSchools.values());
}

export const getAccountScoreName = (name: string) => capitalize((name || '').trim().split(' ')[0]);
