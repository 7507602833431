import { useQuery } from '@tanstack/react-query';
import { SponsorshipService } from './sponsorship.service';
import { SPONSORSHIP_KEY } from './sponsorship.endpoints';

export const useSponsorships = (schoolIds: string[]) =>
  useQuery({
    queryKey: [SPONSORSHIP_KEY, schoolIds],
    queryFn: async () => {
      const res = await SponsorshipService.getSponsorships(schoolIds);
      return res ? Object.keys(res) : [];
    }
  });
