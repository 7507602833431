import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import type { MultiSelectProps } from 'carbon-components-react';
// import MultiSelectWithAllOpt from '@common/carbon-ui/customized/MultiSelectWithAllOpt';
import MultiSelectWithAllOpt from '../../../../carbon-ui/customized/MultiSelectWithAllOpt';

export type BasicMultiSelectWithAllOptConfig<ItemType = string> = MultiSelectProps & {
  items: ItemType[];
  itemToString?(items: any): string;
  label: string;
  titleText: string;
  hasValueInitial: boolean;
};

export type BasicMultiSelectWithAllOptProps<ItemType = string> = BasicMultiSelectWithAllOptConfig & {
  name: string;
  control: any;
  context: any;
  initialValue: ItemType;
  fieldValue: ItemType;
  fieldError?: string;
  getFieldRef?: () => void;
};

const BasicMultiSelectWithAllOpt = ({
  name,
  titleText,
  label = '',
  items = [],
  itemToString,
  hasValueInitial,
  initialValue,
  fieldValue,
  fieldError,
  getFieldRef,
  control,
  context,
  ...other
}: BasicMultiSelectWithAllOptProps) => {
  const [key, setKey] = useState<number>();

  useEffect(() => {
    setKey(new Date().getTime());
  }, [hasValueInitial]);

  return (
    <MultiSelectWithAllOpt
      initialSelectedItems={fieldValue ?? []}
      {...other}
      {...control}
      id={`field-${name}`}
      key={key}
      ref={getFieldRef}
      itemToString={itemToString}
      items={items}
      label={label}
      titleText={titleText}
      invalidText={fieldError}
      invalid={!isEmpty(fieldError)}
    />
  );
};

export default BasicMultiSelectWithAllOpt;
