import { useEffect, useMemo, useRef, useState } from 'react';
import { debounce, isEmpty, uniqBy, isEqual, differenceBy, chunk } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Column, Row, SkeletonPlaceholder, SkeletonText, Tag } from 'carbon-components-react';
import BasicTable from '@old-components/basic-table/basic-table.component';
import UploadFilters, { DEFAULT_FILTER } from '../upload-filters/upload-filters.component';

import EventService from '@events/services/events.service';
import { UserService, INTERNAL_USER_ROLES } from '@gofan/api/users';
import { scrollToTop } from '@app/utils/behaviorUtils';
import { generateError } from '@utils/alertUtils';
import { DATE_FORMAT, formatDateTime } from '@app/utils/dateUtils';
import { EVENT_DATE_FORMAT } from '@utils/dateUtils';
import { mappingPills, SEARCH_PARAMS, getSortBy } from '@events/utils/upload-table.utils';
import { PAGES } from '@app/config/routes';
import { sortStates } from '@old-components/basic-table/sorting';
import strings from '@events/strings';

import type { UserDTO } from '@gofan/api/users';
import type { TagTypeName } from 'carbon-components-react';
import type { Pill } from '@old-components/filter-pills/filter-pills.component';
import type { GridChangedValue } from '@old-components/basic-table/basic-table.component';
import type {
  ItemUpload,
  LastEvaluatedKeyType,
  UploadResponses,
  UploadSearchParams
} from '@events/utils/upload-table.utils';
import type { FilterType } from '../upload-filters/upload-filters.component';

import './upload-table.component.scss';

const UPLOAD_TAG: {
  [k: string]: TagTypeName;
} = {
  [strings.UPLOAD_STATUS.IN_PROGRESS]: 'blue',
  [strings.UPLOAD_STATUS.UPLOADED]: 'green'
};

type UploadTableProps = {
  triggerOnChange: boolean;
  filteredPills: Pill[];
  onAddPills: Function;
  addNotification: Function;
};

const UploadTable = ({ triggerOnChange, filteredPills, onAddPills, addNotification }: UploadTableProps) => {
  const searchParamsRef = useRef<UploadSearchParams>(SEARCH_PARAMS);
  const isUnmounted = useRef<boolean>(false);
  const pillsRef = useRef<Pill[]>([]);
  const searchTimer = useRef<any>(null);
  const [prepare, setPrepare] = useState<boolean>(false);
  const [toolbarOpen, setToolbarOpen] = useState<boolean>(false);
  const [internalUsers, setInternalUsers] = useState<UserDTO[]>([]);
  const [uploadResponse, setUploadResponse] = useState<ItemUpload[]>([]);
  const [uploadTableData, setUploadTableData] = useState<ItemUpload[]>([]);
  const [filterValues, setFilterValues] = useState<FilterType>(DEFAULT_FILTER);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<LastEvaluatedKeyType>();

  const columns = useMemo(
    () => [
      {
        index: 'fileName',
        name: 'File name',
        isSortable: true,
        textEllipsis: true,
        style: { maxWidth: '15rem' },
        render: (name: string, row: any) => {
          const dataRow = (uploadResponse ?? []).find(item => item.batchId === row.rowId);

          return (
            <Link
              title={name}
              to={{
                pathname: PAGES.eventsV2.bulkUploadEvents.calculatePath(row.rowId),
                state: { action: dataRow?.action }
              }}
              className='gs--body-short-01 bx--link'
            >
              {name}
            </Link>
          );
        }
      },
      {
        index: 'uploadAt',
        name: 'Date/Time uploaded',
        isSortable: true,
        textEllipsis: true,
        render: (dateTimeUploaded: string) => (
          <span>
            {formatDateTime({
              parseZone: false,
              date: dateTimeUploaded,
              timeZone: undefined
            }).toDateTime(DATE_FORMAT)}
          </span>
        )
      },
      {
        index: 'eventAdded',
        name: 'Event added',
        style: { maxWidth: '5rem' },
        render: (_: string, row: any) => {
          const dataRow = (uploadResponse ?? []).find(item => item.batchId === row.rowId);
          return dataRow?.action === 'update' ? '' : dataRow?.eventsCount;
        }
      },
      {
        index: 'eventEdited',
        name: 'Event edited',
        style: { maxWidth: '5rem' },
        render: (_: string, row: any) => {
          const dataRow = (uploadResponse ?? []).find(item => item.batchId === row.rowId);
          return dataRow?.action === 'update' ? dataRow?.eventsCount : '';
        }
      },
      {
        index: 'status',
        name: 'Status',
        style: { maxWidth: '15rem' },
        render: (_: string, row: any) => {
          const dataRow = (uploadResponse ?? []).find(item => item.batchId === row.rowId);
          if (dataRow) {
            const status =
              dataRow.expectedEventCount === dataRow.eventsCount
                ? strings.UPLOAD_STATUS.UPLOADED
                : strings.UPLOAD_STATUS.IN_PROGRESS;

            return <Tag type={UPLOAD_TAG[status]}>{status}</Tag>;
          }

          return <div />;
        }
      },
      {
        index: 'email',
        name: 'Uploaded by',
        isSortable: true,
        textEllipsis: true
      }
    ],
    [uploadResponse]
  );

  const gridConfig = useMemo(
    () => ({
      rowConfig: { id: 'batchId' },
      toolbar: {
        hasSearch: true,
        onSearchChanged: _onSearchChanged,
        onClickedToolbar: () => setToolbarOpen(true)
      },
      sorting: {
        sortBy: searchParamsRef.current.sortBy
      },
      pagination: {
        currentPage: searchParamsRef.current.page,
        pageSize: searchParamsRef.current.pageSizeTable,
        totalElements: lastEvaluatedKey ? uploadResponse.length + 1 : uploadResponse.length
      },
      onGridChanged: _onGridChanged
    }),
    [uploadResponse, searchParamsRef.current, lastEvaluatedKey]
  );

  const debounceSearchFilter = useMemo(
    () =>
      debounce(() => {
        _onSearchDataUpload({ shouldLoadInitialPage: true });
      }, 2000),
    [searchParamsRef.current, isUnmounted]
  );

  useEffect(() => {
    if (isUnmounted.current || !triggerOnChange) return;
    setPrepare(true);
    _onSearchDataUpload();
    _getInternalUsers();
  }, [isUnmounted, triggerOnChange]);

  useEffect(() => {
    const diffPills = differenceBy(pillsRef.current, filteredPills, 'id');
    if (!isEmpty(diffPills) && triggerOnChange) {
      const updatedFilterValues = { ...filterValues };
      diffPills.forEach((pill: Pill) => {
        if (pill.type === 'uploadedBy') {
          updatedFilterValues.uploadedBy = undefined;
        } else if (pill.type === 'uploadedDate') {
          updatedFilterValues.uploadedDate = undefined;
        }
      });
      setFilterValues(updatedFilterValues);
      _onApplyFilter(updatedFilterValues);
    }
  }, [filteredPills]);

  useEffect(() => {
    pillsRef.current = filteredPills || [];
    return () => {
      debounceSearchFilter.cancel();
      isUnmounted.current = true;
    };
  }, []);

  return prepare ? (
    <Row>
      <Column className='page-loading'>
        <SkeletonText />
        <SkeletonPlaceholder />
      </Column>
    </Row>
  ) : (
    <Row className={classNames('gs-table', 'upload-table-component')}>
      <Column>
        <UploadFilters
          open={toolbarOpen}
          onClose={() => setToolbarOpen(false)}
          onApply={_onApplyFilter}
          filterValues={filterValues}
          users={internalUsers}
        />

        <div id='dashboard-upload-table'>
          <BasicTable columns={columns} grid={gridConfig} data={uploadTableData} />
        </div>
      </Column>
    </Row>
  );
  function _onSearchChanged(e: { target: HTMLInputElement }) {
    const { value } = e.target;
    const searchTerm = `${value}`.trim();
    searchParamsRef.current = {
      ...searchParamsRef.current,
      body: {
        ...searchParamsRef.current.body,
        searchBy: 'fileName',
        searchTerm
      },
      page: 0,
      sortBy: {
        header: !isEmpty(searchTerm) ? 'fileName' : SEARCH_PARAMS.sortBy.header,
        sortDirection: sortStates.DESC
      }
    };
    // Reset filter and paging
    setFilterValues(DEFAULT_FILTER);
    debounceSearchFilter();
  }

  function _onApplyFilter({ uploadedBy, uploadedDate }: FilterType) {
    setFilterValues({
      uploadedBy,
      uploadedDate
    });
    searchParamsRef.current = {
      ...searchParamsRef.current,
      body: {
        ...searchParamsRef.current.body,
        ..._onGetFilterData({ uploadedBy, uploadedDate })
      },
      page: 0,
      sortBy: uploadedBy
        ? {
            header: 'email',
            sortDirection: sortStates.DESC
          }
        : SEARCH_PARAMS.sortBy
    };
    pillsRef.current = mappingPills({
      uploadedBy,
      uploadedDate: uploadedDate ? moment(uploadedDate).format(EVENT_DATE_FORMAT) : ''
    });
    scrollToTop();
    onAddPills(pillsRef.current);
    debounceSearchFilter();
  }

  function _onGetFilterData({ uploadedBy, uploadedDate }: FilterType) {
    const dateTime = uploadedDate ? moment(uploadedDate).format(EVENT_DATE_FORMAT) : '';
    if (!uploadedBy) {
      if (dateTime) {
        return {
          searchBy: 'dateTime',
          searchTerm: dateTime
        };
      }
      return {
        searchBy: '',
        searchTerm: '',
        dateTime: ''
      };
    }

    return {
      searchBy: 'email',
      searchTerm: uploadedBy,
      dateTime
    };
  }

  function _onGridChanged({ sortBy, pageSize, currentPage }: GridChangedValue) {
    const nextSortBy = getSortBy(sortBy);
    const isSort = !isEqual(searchParamsRef.current.sortBy, sortBy);
    searchParamsRef.current = {
      ...searchParamsRef.current,
      pageSizeTable: pageSize,
      page: !isSort ? currentPage : 0,
      sortBy: searchParamsRef.current.body.searchTerm ? searchParamsRef.current.sortBy : nextSortBy
    };
    const currentUploadTableData = chunk(uploadResponse, pageSize)[currentPage] ?? [];
    if (isSort || (currentUploadTableData.length < pageSize && lastEvaluatedKey)) {
      _onSearchDataUpload({ lastEvaluatedKey: !isSort ? lastEvaluatedKey : undefined });
    } else {
      setUploadTableData(currentUploadTableData);
    }
  }

  function _onSearchDataUpload(requestData?: {
    lastEvaluatedKey?: LastEvaluatedKeyType;
    shouldLoadInitialPage?: boolean;
  }) {
    clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      if (isUnmounted.current) return;
      EventService.getUploadData({
        pageSize: searchParamsRef.current.pageSize,
        sortOrder:
          searchParamsRef.current.sortBy.sortDirection &&
          `${searchParamsRef.current.sortBy.sortDirection}`.toLowerCase(),
        searchBy: searchParamsRef.current.sortBy.header ?? SEARCH_PARAMS.body.searchBy,
        searchTerm: searchParamsRef.current.body.searchTerm,
        dateTime: searchParamsRef.current.body.dateTime,
        LastEvaluatedKey: requestData?.lastEvaluatedKey
      })
        .then((response: UploadResponses) => {
          if (isUnmounted.current) return;
          const { LastEvaluatedKey, Items } = response;
          if (LastEvaluatedKey && Items.length < searchParamsRef.current.pageSizeTable) {
            setUploadResponse(uniqBy([...uploadResponse, ...Items], 'batchId'));
            _onSearchDataUpload({ lastEvaluatedKey: LastEvaluatedKey });
          } else {
            setLastEvaluatedKey(LastEvaluatedKey);
            const uploadData = requestData?.shouldLoadInitialPage
              ? Items
              : uniqBy([...Items, ...uploadResponse], 'batchId');
            setUploadResponse(uploadData);
            setUploadTableData(
              chunk(uploadData, searchParamsRef.current.pageSizeTable)[searchParamsRef.current.page] ?? []
            );
          }
        })
        .catch(error => {
          if (isUnmounted.current) return;
          addNotification(generateError(error));
        })
        .finally(() => {
          if (isUnmounted.current) return;
          setPrepare(false);
        });
    }, 500);
  }

  function _getInternalUsers() {
    UserService.searchUser({
      body: {
        roles: INTERNAL_USER_ROLES
      },
      page: 0,
      pageSize: 1000
    }).then(res => {
      if (isUnmounted.current) return;
      setInternalUsers(res.content);
    });
  }
};

export default UploadTable;
