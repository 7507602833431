// @flow

import AppDAO from '../dao/AppDAO';
import ErrorDTO from '../dto/ErrorDTO';
import HealthCheckDTO from '../dto/HealthCheckDTO';
import * as objectUtils from '../../utils/objectUtils';

const checkHealth = manualErrorHandling =>
  AppDAO.checkHealth(manualErrorHandling);

const checkStatusOfHealthChecking = (response, ignoreRedis) => {
  let healthDetails = {};
  let healthStatus = 'DOWN';

  if (response instanceof HealthCheckDTO) {
    healthDetails = response.details;
    healthStatus = response.status;
  } else if (
    response instanceof ErrorDTO &&
    !objectUtils.isEmpty(response.data) &&
    !objectUtils.isEmpty(response.data.details)
  ) {
    healthStatus = response.data.status;
    healthDetails = response.data.details;
  }

  if (`${healthStatus}`.toUpperCase() === 'UP') {
    return true;
  }

  return (
    ignoreRedis &&
    !objectUtils.isEmpty(healthDetails) &&
    Object.keys(healthDetails).every(key => {
      const { status } = healthDetails[key];
      return (
        `${status}`.toUpperCase() === 'UP' || `${key}`.toUpperCase() === 'REDIS'
      );
    })
  );
};

export default { checkHealth, checkStatusOfHealthChecking };
