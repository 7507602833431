import axios from 'axios';
import qs from 'querystring';

import type { AccountMetricsQuery } from './account-metrics.model';

// Account Metrics endpoints:
export const GET_ACCOUNT_METRICS = '/api/account-metrics';

export class AccountMetricsRepository {
  static getAccountMetrics = (query: AccountMetricsQuery) => {
    const sourceAccountsMetrics = axios.CancelToken.source();

    return axios.get(GET_ACCOUNT_METRICS, {
      params: query,
      paramsSerializer: params => qs.stringify(params),
      cancelToken: sourceAccountsMetrics.token
    });
  };
}
