// @flow
import { isEmpty } from '../../../utils/objectUtils';

export const PRODUCT_TYPE = {
  TICKET: 'TICKET',
  DONATION: 'DONATION',
  MOBILEPASS: 'MOBILEPASS'
};

export const DISTRIBUTION_CHANNEL = {
  GOFAN: 'GoFan',
  BOXOFFICE: 'BoxOffice'
};

export const COMPLIMENTARY_TICKET = 'Complimentary Ticket';
export const COMPLIMENTARY_PASS = 'Complimentary Pass';

class Ticket {
  constructor({
    restData,
    id,
    accountId,
    distributionChannel,
    enabled,
    eventId,
    fee,
    formId,
    limit,
    name,
    packCount,
    price,
    productType,
    promotionLabel,
    promotionRequired,
    redemptionLimit,
    reservedSeating,
    salesEndDateTime,
    salesStartDateTime,
    seasonId
  }) {
    this.raw = {
      restData,
      id,
      accountId,
      distributionChannel,
      enabled,
      eventId,
      fee,
      formId,
      limit,
      name,
      packCount,
      price,
      productType,
      promotionLabel,
      promotionRequired,
      redemptionLimit,
      reservedSeating,
      salesEndDateTime,
      salesStartDateTime,
      seasonId
    };
  }

  get restData() {
    return isEmpty(this.raw.restData) ? {} : this.raw.restData;
  }

  get id() {
    return this.raw.id;
  }

  get accountId() {
    return this.raw.accountId;
  }

  get distributionChannel() {
    return this.raw.distributionChannel || DISTRIBUTION_CHANNEL.GOFAN;
  }

  get enabled() {
    return this.raw.enabled;
  }

  get eventId() {
    return this.raw.eventId;
  }

  get fee() {
    return this.raw.fee || undefined;
  }

  get formId() {
    return this.raw.formId;
  }

  get limit() {
    return this.raw.limit ? Number.parseInt(this.raw.limit, 10) : null;
  }

  get name() {
    return this.raw.name;
  }

  get packCount() {
    return this.raw.packCount;
  }

  get price() {
    return this.raw.price;
  }

  get productType() {
    return this.raw.productType || PRODUCT_TYPE.TICKET;
  }

  get promotionLabel() {
    return this.raw.promotionLabel;
  }

  get promotionRequired() {
    return this.raw.promotionRequired;
  }

  get redemptionLimit() {
    return this.raw.redemptionLimit;
  }

  get reservedSeating() {
    return this.raw.reservedSeating;
  }

  get salesEndDateTime() {
    return this.raw.salesEndDateTime;
  }

  get salesStartDateTime() {
    return this.raw.salesStartDateTime;
  }

  get seasonId() {
    return this.raw.seasonId;
  }

  toJSON() {
    return {
      ...this.restData,
      id: this.id,
      accountId: this.accountId,
      distributionChannel: this.distributionChannel,
      enabled: this.enabled,
      eventId: this.eventId,
      fee: this.fee,
      formId: this.formId,
      limit: this.limit,
      name: this.name,
      packCount: this.packCount,
      price: this.price,
      productType: this.productType,
      promotionLabel: this.promotionLabel,
      promotionRequired: this.promotionRequired,
      redemptionLimit: this.redemptionLimit,
      reservedSeating: this.reservedSeating,
      salesEndDateTime: this.salesEndDateTime,
      salesStartDateTime: this.salesStartDateTime,
      seasonId: this.seasonId
    };
  }
}

export default Ticket;
