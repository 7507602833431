import { Checkbox as CarbonCheckbox } from '@carbon/react';
import classNames from 'classnames';

import type { CheckboxProps as CarbonCheckboxProps } from '@carbon/react';

import './Checkbox.scss';

export type CheckboxProps = CarbonCheckboxProps;

export const Checkbox = (props: CheckboxProps) => (
  <CarbonCheckbox {...props} data-checked={props.checked} className={classNames('gf--checkbox', props.className)} />
);
