import { connect } from 'react-redux';
import AuthGuardedRoute from './AuthGuardedRoute';

const mapStateToProps = state => ({
  notifications: state.rootV2.notifications,
  account: state.rootV2.account,
  selectedSchools: state.rootV2.selectedSchools
});

export default connect(mapStateToProps)(AuthGuardedRoute);
