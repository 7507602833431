import { useEffect, useState } from 'react';
import { SelectItem, TimePicker as CarbonTimePicker, TimePickerSelect as CarbonTimePickerSelect } from '@carbon/react';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isFinite from 'lodash/isFinite';
import classNames from 'classnames';

import './TimePicker.scss';

export const TIME_PICKER_PLACEHOLDER = 'hh:mm';
export const TIME_PICKER_SELECT = {
  AM: 'AM',
  PM: 'PM'
};
export const TIME_PICKER_PATTERN = '(1[012]|0?[1-9]):[0-5][0-9](\\s)?';

export const checkNumber = (num: any) => isNumber(num) && isFinite(num);

export const someIsNotNumber = (num: any[]) => num?.some(n => !checkNumber(parseFloat(n ?? '0')));

interface TimePickerProps {
  id: string;
  className?: string;
  ref?: any;
  labelText?: string;
  value: string;
  disabled?: boolean;
  invalid?: boolean;
  invalidText?: string;
  size?: 'sm' | 'md' | 'lg' | undefined;
  onChange?: (value: string) => void;
  onBlur?: () => void;
}

export const TimePicker = ({
  id,
  className,
  ref,
  labelText = '',
  value,
  disabled,
  invalid,
  invalidText,
  size = 'md',
  onChange,
  onBlur
}: TimePickerProps) => {
  const [timeVal, setTime] = useState('');
  const [periodVal, setPeriod] = useState(TIME_PICKER_SELECT.AM);

  useEffect(() => {
    if (!isEmpty(value)) {
      const [time, period] = value.split(/\s/).map(item => item?.trim());
      setTime(time);
      setPeriod(period);
    } else {
      setTime('');
    }
  }, [value]);

  const onChangeTimePeriod = (time: string, period: string) => {
    let val = time;
    if (!isEmpty(val)) {
      const formattedTime = castTimeTo12h({ time, period });
      setTime(formattedTime.time);
      val = `${formattedTime.time} ${formattedTime.period}`;
    }
    _onChange(val);
    _onBlur();
  };

  const castTimeTo12h = (timeWithPeriod: { time: string; period: string }): { time: string; period: string } => {
    const time = timeWithPeriod?.time?.split(':') ?? [];
    const colonSymbolCounter = time.length - 1;

    if (isEmpty(timeWithPeriod?.time) || colonSymbolCounter > 1) {
      return { time: timeWithPeriod?.time, period: timeWithPeriod?.period };
    }

    if (colonSymbolCounter === 1) {
      const hours = time?.[0]?.split('') ?? ['0'];
      const minutes = time?.[1]?.split('') ?? ['0'];

      if (someIsNotNumber(hours) || someIsNotNumber(minutes)) {
        return { time: timeWithPeriod.time, period: timeWithPeriod.period };
      }

      const firstNumber = minutes?.[0] ?? '0';
      const secondNumber = minutes?.[1] ?? '0';
      const thirdNumber = minutes?.[2] ?? '0';

      if (minutes?.length === 1) {
        time[1] = `${firstNumber}0`;
      } else if (minutes?.length >= 3 && firstNumber === '0' && secondNumber === '0') {
        time[1] = `0${thirdNumber}`;
      } else {
        time[1] = `${firstNumber}${secondNumber}`;
      }
    } else if (colonSymbolCounter === 0) {
      const splitTime = timeWithPeriod.time.split('') ?? ['0'];

      if (someIsNotNumber(splitTime)) {
        return { time: timeWithPeriod.time, period: timeWithPeriod.period };
      }

      const firstNumber = splitTime?.[0] ?? '0';
      const secondNumber = splitTime?.[1] ?? '0';
      const thirdNumber = splitTime?.[2] ?? '0';
      const fourthNumber = splitTime?.[3] ?? '0';
      const fifthNumber = splitTime?.[4] ?? '0';

      if (splitTime?.length === 1) {
        time[0] = `0${firstNumber}`;
        time[1] = '00';
      } else if (splitTime?.length === 2) {
        if (firstNumber === '0') {
          time[0] = `0${secondNumber}`;
          time[1] = '00';
        } else {
          time[0] = `${firstNumber}${secondNumber}`;
          time[1] = '00';
        }
      } else if (splitTime?.length === 3) {
        if (firstNumber === '0' && secondNumber === '0') {
          time[0] = `0${thirdNumber}`;
          time[1] = '00';
        } else if (firstNumber === '0') {
          time[0] = `0${secondNumber}`;
          time[1] = `${thirdNumber}0`;
        } else {
          time[0] = `0${firstNumber}`;
          time[1] = `${secondNumber}${thirdNumber}`;
        }
      } else if (splitTime?.length >= 4) {
        if (firstNumber === '0' && secondNumber === '0' && thirdNumber === '0' && fourthNumber === '0') {
          time[0] = `0${fifthNumber}`;
          time[1] = '00';
        } else if (firstNumber === '0' && secondNumber === '0' && thirdNumber === '0') {
          time[0] = `0${fourthNumber}`;
          time[1] = '00';
        } else {
          time[0] = `${firstNumber}${secondNumber}`;
          time[1] = `${thirdNumber}${fourthNumber}`;
        }
      }
    }

    let hour = isEmpty(time[0]) ? 0 : parseFloat(time[0]);
    let minute = isEmpty(time[1]) ? 0 : parseFloat(time[1]);

    if (someIsNotNumber([hour, minute]) || hour < 0 || minute < 0) {
      return { time: timeWithPeriod.time, period: timeWithPeriod.period };
    }

    if (hour <= 0 || hour > 12) {
      hour = 12;
    }

    if (minute < 0) {
      minute = 0;
    } else if (minute > 59) {
      minute = 59;
    }

    const formattedHour = hour >= 0 && hour <= 9 ? `0${hour}` : `${hour}`;
    const formattedMinute = minute >= 0 && minute <= 9 ? `0${minute}` : `${minute}`;
    return { time: `${formattedHour}:${formattedMinute}`, period: timeWithPeriod.period };
  };

  return (
    <CarbonTimePicker
      id={id}
      className={classNames('gf--time-picker', className)}
      ref={ref}
      inputMode='decimal'
      size={size}
      labelText={labelText}
      placeholder={TIME_PICKER_PLACEHOLDER}
      pattern={TIME_PICKER_PATTERN}
      value={timeVal}
      disabled={disabled}
      invalid={invalid}
      invalidText={invalidText}
      onChange={event => {
        setTime(event?.target?.value?.trim());
      }}
      onBlur={() => {
        onChangeTimePeriod(timeVal, periodVal);
      }}
    >
      <CarbonTimePickerSelect
        className='time-picker-select__gap'
        id={`${id}-select`}
        value={periodVal}
        disabled={disabled}
        onChange={event => {
          setPeriod(event?.target?.value);
          onChangeTimePeriod(timeVal, event?.target?.value?.trim());
        }}
      >
        <SelectItem text={TIME_PICKER_SELECT.AM} value={TIME_PICKER_SELECT.AM} />
        <SelectItem text={TIME_PICKER_SELECT.PM} value={TIME_PICKER_SELECT.PM} />
      </CarbonTimePickerSelect>
    </CarbonTimePicker>
  );

  function _onChange(date: string) {
    if (typeof onChange === 'function') onChange(date);
  }

  function _onBlur() {
    if (typeof onBlur === 'function') onBlur();
  }
};
