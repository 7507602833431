import { DonutChart as CarbonDonutChart } from '@carbon/charts-react';
import type { DonutChartOptions } from '@carbon/charts/interfaces';
import { useEffect, useMemo, useState } from 'react';
import { SkeletonPlaceholder, SkeletonText } from 'carbon-components-react';
import { formatNumber } from '@events/components/ticket-sale-chart/helper';

interface Props {
  data: { group: string | number; value: string | number }[] | [];
  // eslint-disable-next-line react/require-default-props
  loading?: boolean;
  // eslint-disable-next-line react/require-default-props
  customDonutTitle?: boolean;
  options: any;
  id: string;
  shouldLimitLegendWidth?: boolean;
}

const DonutChart = ({ loading, data, options, customDonutTitle, id, shouldLimitLegendWidth }: Props) => {
  const [prepared, setPrepared] = useState(false);
  const chartId = `chartId-${id}`;

  useEffect(() => {
    const chartElement = document.getElementById(chartId);
    if (chartElement === null) return;
    const pieLabelElements = chartElement.getElementsByClassName('pie-label') as HTMLCollectionOf<HTMLElement>;
    const labelContainer: HTMLElement | null = chartElement.querySelector('.labels');
    const donutTitle: HTMLElement | null = chartElement.querySelector('.donut-title');
    const donutFigure: HTMLElement | null = chartElement.querySelector('.donut-figure');
    const calloutContainer: HTMLElement | null = chartElement.querySelector('.callouts');
    const center = chartElement.getElementsByClassName('center');
    const legendContainer: HTMLElement | null = chartElement.querySelector('.legend');
    const legendItems: HTMLElement | null = legendContainer && legendContainer.querySelector('.bx--cc--legend');
    if (shouldLimitLegendWidth && legendContainer && legendItems) {
      legendItems.style.maxWidth = '310px';
      legendItems.style.height = 'inherit';
      legendItems.style.display = 'flex';
      legendItems.style.justifyContent = 'space-between';
    }
    if (calloutContainer) {
      calloutContainer.style.visibility = 'hidden';
    }
    if (center && donutTitle) {
      const titleLines = donutTitle.textContent?.split('<br>');
      if (!customDonutTitle) {
        titleLines?.forEach((text: string, index: number) => {
          const fontFamily = `font-family: 'IBM Plex Sans';`;
          center[0].insertAdjacentHTML(
            'beforeend',
            `<text name='donut-title' class="donut-title" text-anchor="middle" y="${
              20 * (index + 1)
            }"  style="font-size: 18px; font-weight: bold; ${fontFamily}">${text}</text>`
          );
        });
        donutTitle.style.visibility = 'hidden';
        if (donutFigure) donutFigure.style.fontWeight = 'bold';
      } else {
        titleLines?.forEach((text: string, index: number) => {
          const fontSize = index === 0 ? '24px' : '18px';
          const fontWeight = 'bold';
          const fontFamily = `font-family: 'IBM Plex Sans';`;
          center[0].insertAdjacentHTML(
            'beforeend',
            `<text name='custom-donut-title' class="donut-title" text-anchor="middle" y="${
              -40 + 20 * (index + 1)
            }"  style="font-size: ${fontSize}; font-weight: ${fontWeight}; ${fontFamily}">${text}</text>`
          );
        });

        donutTitle.style.visibility = 'hidden';
        if (donutFigure) donutFigure.style.visibility = 'hidden';
      }
    }

    if (!labelContainer || !pieLabelElements) return;
    for (let i = 0; i < pieLabelElements.length; i++) {
      const labelPosition = pieLabelElements[i].getAttribute('transform');
      const [xPos, yPos] = labelPosition?.slice(10, labelPosition.length - 1).split(',') || [];
      const lines = pieLabelElements[i]?.textContent?.split('<br>');
      pieLabelElements[i].style.visibility = 'hidden';
      if (lines && !lines.some(text => text.trim() === '100%' || text.trim() === '0%')) {
        lines?.forEach((text: string, index: number) => {
          if (text === '' || text === '%') return;
          let x = Number(xPos) > 0 ? Number(xPos) - 20 : Number(xPos);
          let y = Number(yPos) > 0 ? parseFloat(yPos) + index * 20 : parseFloat(yPos) - 20 + index * 20;
          if (Number(lines[0].replace('%', '').trim()) >= 80) {
            x = 140;
            y = 0 + index * 20;
          }
          const fontWeight = text.includes('%') ? 'font-weight : 500;' : 'font-weight : bold;';
          const label01 = `
          font-size: 0.75rem;
          line-height: 1em;
          letter-spacing: 0.32px;
          font-family: IBM Plex Sans;
        `;
          labelContainer.insertAdjacentHTML(
            'beforeend',
            `<text name="pie-text-label-sale-allotments-${chartId}"  x="${x}" y="${y}" style="${label01} ${fontWeight}">` +
              `${text.includes('%') ? text : formatNumber(text)}` +
              `</text>`
          );
        });
      }
    }
    if (!loading) {
      setPrepared(true);
    }
  }, [loading]);

  const chartOptions = useMemo<DonutChartOptions>(
    () => ({
      title: '',
      resizable: false,
      legend: {
        enabled: true,
        clickable: false
      },
      height: '300px',
      ...options
    }),
    [loading, options]
  );

  return loading ? (
    <>
      <SkeletonText />
      <SkeletonPlaceholder style={{ width: '100%' }} />
    </>
  ) : (
    <div id={chartId}>
      <CarbonDonutChart data={data} options={chartOptions} />
    </div>
  );
};

export { DonutChart };
