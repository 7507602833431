// endpoints
export const GET_EVENT = '/events/{id}';
export const GET_EVENT_HISTORY = '/events/{id}/history';

// query keys
export const EVENTS_QUERY_KEY = 'events';
export const EVENTS_INSIGHTS_QUERY_KEY = 'event-insights';
export const SEARCH_EVENT_INSIGHTS_QUERY_KEY = 'search-event-insights';
export const SEARCH_EVENTS_QUERY_KEY = 'search-events';
export const EVENTS_COUNTER_QUERY_KEY = 'events-counter';
