import type React from 'react';
import { useState } from 'react';
import { Tooltip } from 'carbon-components-react';
import classNames from 'classnames';
import type { CarbonIconType } from '@carbon/icons-react';
import type { Direction, TooltipAlignment } from 'carbon-components-react/typings/shared';

export type ButtonToolTipProps = {
  id?: string;
  isActive?: boolean;
  className?: string;
  tooltipText?: string;
  tooltipAlign?: TooltipAlignment;
  tooltipDirection?: Direction;
  renderIcon: CarbonIconType;
  onClick?: () => void;
};

const ButtonToolTip: React.FC<ButtonToolTipProps> = ({
  id,
  isActive,
  className,
  tooltipText,
  tooltipAlign = 'center',
  tooltipDirection = 'top',
  renderIcon: Icon,
  onClick
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      id={id}
      className={classNames('button-tooltip', className)}
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div
        className={classNames('button-tooltip__icon-wrapper', {
          unActive: isHover && !isActive,
          isActive: isHover && isActive
        })}
      >
        <Icon className={classNames(isActive && 'button-tooltip__icon-collor')} />
      </div>
      {tooltipText ? (
        <Tooltip
          open={isHover}
          showIcon={false}
          align={tooltipAlign}
          direction={tooltipDirection}
          triggerText='tooltip-hover'
          className='gs--body-short-01 gs--inverse-01 gs--tooltip-custom'
        >
          {tooltipText}
        </Tooltip>
      ) : null}
    </div>
  );
};

export default ButtonToolTip;
