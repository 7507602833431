import ErrorEventDTO from './ErrorEventDTO';
import RowEventDTO from './RowEventDTO';
import { isEmpty } from '../../utils/objectUtils';

class UploadedEventDTO {
  constructor({ errors, event, rowNumber }) {
    this.raw = {
      errors,
      event,
      rowNumber
    };
  }

  get errors() {
    return isEmpty(this.raw.errors)
      ? []
      : this.raw.errors.map(error => new ErrorEventDTO(error));
  }

  get event() {
    return new RowEventDTO(this.raw.event);
  }

  get rowNumber() {
    return this.raw.rowNumber;
  }

  toJSON() {
    return {
      errors: this.errors,
      event: this.event,
      rowNumber: this.rowNumber
    };
  }
}

export default UploadedEventDTO;
