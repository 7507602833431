import BaseModel from '../BaseModel';

class SearchAccountTicketsRequest extends BaseModel {
  constructor({ accountId, ticketName }) {
    super({ accountId, ticketName });
  }

  get accountId() {
    return this.raw.accountId || undefined;
  }

  get ticketName() {
    return this.raw.ticketName || undefined;
  }

  toJSON() {
    return {
      accountId: this.accountId,
      ticketName: this.ticketName
    };
  }
}

export default SearchAccountTicketsRequest;
