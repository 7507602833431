import * as yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { calculateFee } from '@gofan/api/account-products';
import { formatCurrency } from '@gofan/utils';

import { TICKET_ACCOUNT } from '@gofan/constants';
import { STRINGS } from '../constants';
import { ALPHANUMERIC_SPECIAL_CHARACTERS_REGEX } from '@app/commons/validations';
import { DISTRIBUTION_CHANNEL } from '@api/model/request/ProductRequest';
import { validateTime, validateDate, isStartAfterEndDateTime } from '@old-components/date-time-form/utils';
import { parseNumber } from '@utils/parseUtils';
import {
  boxOfficeTicketSchemaBuilder,
  limitSchemaBuilder,
  ticketNameSchemaBuilder,
  ticketPriceSchemaBuilder
} from '@account-products/components/add-ticket-type-modal/yup';

import { SeasonTicketTypeEnum, type SeasonTicketData } from '../SeasonTicketModal';

export const getDefaultSeasonTicketValues = (seasonTicket: any, data: SeasonTicketData) => {
  const result = calculateFee({
    rates: data.rates,
    ticketPrice: seasonTicket?.price,
    accountPaid: !!seasonTicket?.hiddenFees
  });
  const fanPrice = parseFloat(`${result?.fanPrice ?? 0}`);
  const payToSchool = parseFloat(`${result?.payToSchool ?? 0}`);

  return {
    ...seasonTicket,
    id: seasonTicket?.id ?? new Date().getTime(),
    accountId: seasonTicket?.accountId ?? data.account?.id,
    distributionChannel: seasonTicket?.distributionChannel ?? DISTRIBUTION_CHANNEL.GOFAN,
    groupDistributionChannel: seasonTicket?.groupDistributionChannel ?? [],
    name: seasonTicket?.name ?? '',
    hiddenFees: !!seasonTicket?.hiddenFees,
    price: seasonTicket?.price ?? '',
    customColor: seasonTicket?.customColor ?? '',
    created: seasonTicket?.created ?? false,
    fanPrice,
    payToSchool,
    productType: 'TICKET',
    rateId: seasonTicket?.rateId ?? null,
    enabled: seasonTicket?.enabled ?? true,
    reservedSeating: seasonTicket?.reservedSeating ?? false,
    seatsIoCategory: seasonTicket?.seatsIoCategory ?? undefined,
    salesStartDate: seasonTicket?.salesStartDate ?? undefined,
    salesStartTime: seasonTicket?.salesStartTime ?? undefined,
    salesEndDate: seasonTicket?.salesEndDate ?? undefined,
    salesEndTime: seasonTicket?.salesEndTime ?? undefined,
    fee: seasonTicket?.fee ?? null,
    ticketLimitPerOrder: seasonTicket?.ticketLimitPerOrder ?? null
  };
};

export const getDefaultTicketTypeValues = (ticketTypeValues: any) => ({
  ...ticketTypeValues,
  created: !!ticketTypeValues?.created,
  deleted: !!ticketTypeValues?.deleted,
  levelIds: ticketTypeValues?.levelIds ?? [],
  ticketIds: ticketTypeValues?.ticketIds ?? [],
  activityIds: ticketTypeValues?.activityIds ?? [],
  groupDistributionChannel: ticketTypeValues?.groupDistributionChannel ?? [],
  ticketName: ticketTypeValues?.ticketName ?? '',
  customColor: ticketTypeValues?.customColor ?? '',
  accountPaid: !!ticketTypeValues?.accountPaid,
  fee: ticketTypeValues?.fee ?? null,
  fanPrice: ticketTypeValues?.fanPrice ?? '',
  payToSchool: ticketTypeValues?.payToSchool ?? '',
  ticketPrice: ticketTypeValues?.ticketPrice ?? '',
  generateLink: ticketTypeValues?.generateLink ?? false,
  limit: ticketTypeValues?.limit ?? undefined,
  reservedSeating: ticketTypeValues?.reservedSeating ?? false,
  seatsIoCategory: ticketTypeValues?.seatsIoCategory ?? undefined,
  boxOfficeTicket: {
    ...ticketTypeValues?.boxOfficeTicket,
    enabled: ticketTypeValues?.groupDistributionChannel?.includes(DISTRIBUTION_CHANNEL.BOXOFFICE),
    ticketName: ticketTypeValues?.boxOfficeTicket?.ticketName ?? '',
    fee: ticketTypeValues?.boxOfficeTicket?.fee ?? null,
    fanPrice: ticketTypeValues?.boxOfficeTicket?.fanPrice ?? '',
    ticketPrice: ticketTypeValues?.boxOfficeTicket?.ticketPrice ?? ''
  },
  salesStartDate: ticketTypeValues?.salesStartDate ?? undefined,
  salesStartTime: ticketTypeValues?.salesStartTime ?? undefined,
  salesEndDate: ticketTypeValues?.salesEndDate ?? undefined,
  salesEndTime: ticketTypeValues?.salesEndTime ?? undefined,
  ticketLimitPerOrder: ticketTypeValues?.ticketLimitPerOrder ?? null,
  packCount: ticketTypeValues?.packCount ?? null,
  enabledPackCount: !!ticketTypeValues?.packCount
});

export const seasonTicketNameSchemaBuilder = () =>
  yup
    .string()
    .required(STRINGS.ERROR_MESSAGES.SEASON_TICKET_NAME.REQUIRED)
    .min(2, STRINGS.ERROR_MESSAGES.SEASON_TICKET_NAME.INVALID)
    .max(40, STRINGS.ERROR_MESSAGES.SEASON_TICKET_NAME.INVALID)
    .matches(ALPHANUMERIC_SPECIAL_CHARACTERS_REGEX, {
      message: STRINGS.ERROR_MESSAGES.SEASON_TICKET_NAME.INVALID,
      excludeEmptyString: true
    });

export const seasonTicketPriceSchemaBuilder = () =>
  yup
    .string()
    .required(STRINGS.ERROR_MESSAGES.SEASON_TICKET_PRICE.REQUIRED)
    .test('invalid', '', (price, testContext) => {
      const { isInternalUser, isAdminRole } = testContext.options?.context ?? {};
      let maxTicketValue = TICKET_ACCOUNT.MAX_PRICE_ACCOUNT_TICKET;
      if (isAdminRole) {
        maxTicketValue = TICKET_ACCOUNT.MAX_PRICE_SUPER_ADMIN;
      } else if (isInternalUser) {
        maxTicketValue = TICKET_ACCOUNT.MAX_PRICE_INTERNAL_ADMIN;
      }
      const errorMessage = STRINGS.ERROR_MESSAGES.SEASON_TICKET_PRICE.INVALID.replace(
        '{min}',
        formatCurrency(TICKET_ACCOUNT.MIN_PRICE_ACCOUNT_TICKET)
      ).replace('{max}', formatCurrency(maxTicketValue));
      const ticketPriceValue = parseFloat(price ?? '0');
      if (ticketPriceValue < TICKET_ACCOUNT.MIN_PRICE_ACCOUNT_TICKET || ticketPriceValue > maxTicketValue) {
        return testContext.createError({
          path: testContext.path,
          message: errorMessage
        });
      }

      return true;
    });

export const ticketSalesStartDateBuilder = () =>
  yup.string().test('invalidSalesStartDate', (salesStartDate, testContext) => {
    const { salesStartTime, salesEndDate, salesEndTime } = testContext.parent ?? {};
    if (isEmpty(salesStartDate) && isEmpty(salesStartTime)) return true;

    let errorMessage = validateDate({
      date: salesStartDate,
      invalidMessage: 'Enter a valid Sale start date.',
      requiredMessage: 'Sale start date is required.'
    });

    if (
      isEmpty(errorMessage) &&
      !validateTime({ time: salesStartTime }) &&
      !validateDate({ date: salesEndDate }) &&
      !validateTime({ time: salesEndTime }) &&
      isStartAfterEndDateTime({
        startDate: salesStartDate,
        startTime: salesStartTime,
        endDate: salesEndDate,
        endTime: salesEndTime,
        unit: 'days',
        allowSame: true
      })
    ) {
      errorMessage = 'Sale start date must come before Sale end date.';
    }

    if (!isEmpty(errorMessage)) {
      return testContext.createError({
        path: testContext.path,
        message: errorMessage
      });
    }

    return true;
  });

export const ticketSalesStartTimeBuilder = () =>
  yup.string().test('invalidSalesStartTime', (salesStartTime, testContext) => {
    const { salesStartDate, salesEndDate, salesEndTime } = testContext.parent ?? {};
    if (isEmpty(salesStartTime) && isEmpty(salesStartDate)) return true;

    let errorMessage = validateTime({
      time: salesStartTime,
      invalidMessage: 'Enter a valid Sale start time.',
      requiredMessage: 'Sale start time is required.'
    });

    if (
      isEmpty(errorMessage) &&
      !validateDate({ date: salesStartDate }) &&
      !validateDate({ date: salesEndDate }) &&
      !validateTime({ time: salesEndTime }) &&
      !isStartAfterEndDateTime({
        startDate: salesStartDate,
        startTime: salesStartTime,
        endDate: salesEndDate,
        endTime: salesEndTime,
        unit: 'days',
        allowSame: true
      }) &&
      isStartAfterEndDateTime({
        startDate: salesStartDate,
        startTime: salesStartTime,
        endDate: salesEndDate,
        endTime: salesEndTime,
        unit: 'seconds'
      })
    ) {
      errorMessage = 'Sale start time must come before Sale end time.';
    }

    if (!isEmpty(errorMessage)) {
      return testContext.createError({
        path: testContext.path,
        message: errorMessage
      });
    }

    return true;
  });

export const ticketSalesEndDateBuilder = () =>
  yup.string().test('invalidEndDateTime', (saleEndDate, testContext) => {
    const { salesStartDate, salesStartTime, salesEndTime } = testContext.parent ?? {};
    if (isEmpty(saleEndDate) && isEmpty(salesEndTime)) return true;

    let errorMessage = validateDate({
      date: saleEndDate,
      invalidMessage: 'Enter a valid Sale end date.',
      requiredMessage: 'Sale end date is required.'
    });

    if (
      isEmpty(errorMessage) &&
      !validateDate({ date: salesStartDate }) &&
      !validateTime({ time: salesStartTime }) &&
      !validateTime({ time: salesEndTime }) &&
      isStartAfterEndDateTime({
        startDate: salesStartDate,
        startTime: salesStartTime,
        endDate: saleEndDate,
        endTime: salesEndTime,
        unit: 'days',
        allowSame: true
      })
    ) {
      errorMessage = 'Sale end date must come after Sale start date.';
    }

    if (!isEmpty(errorMessage)) {
      return testContext.createError({
        path: testContext.path,
        message: errorMessage
      });
    }

    return true;
  });

export const ticketSalesEndTimeBuilder = () =>
  yup.string().test('invalidSalesEndTime', (salesEndTime, testContext) => {
    const { salesStartDate, salesStartTime, salesEndDate } = testContext.parent ?? {};
    if (isEmpty(salesEndTime) && isEmpty(salesEndDate)) return true;

    let errorMessage = validateTime({
      time: salesEndTime,
      invalidMessage: 'Enter a valid Sale end time.',
      requiredMessage: 'Sale end time is required.'
    });

    if (
      isEmpty(errorMessage) &&
      !validateDate({ date: salesStartDate }) &&
      !validateTime({ time: salesStartTime }) &&
      !validateDate({ date: salesEndDate }) &&
      !isStartAfterEndDateTime({
        startDate: salesStartDate,
        startTime: salesStartTime,
        endDate: salesEndDate,
        endTime: salesEndTime,
        unit: 'days',
        allowSame: true
      }) &&
      isStartAfterEndDateTime({
        startDate: salesStartDate,
        startTime: salesStartTime,
        endDate: salesEndDate,
        endTime: salesEndTime,
        unit: 'seconds'
      })
    ) {
      errorMessage = 'Sale end time must come after Sale start time.';
    }

    if (!isEmpty(errorMessage)) {
      return testContext.createError({
        path: testContext.path,
        message: errorMessage
      });
    }

    return true;
  });

export const ticketFeeBuilder = () =>
  yup
    .number()
    .nullable()
    .test('invalidFee', (fee, testContext) => {
      const { isAdminRole } = testContext.options?.context ?? {};
      let maxTicketFee = TICKET_ACCOUNT.MAX_TICKET_FEE;
      if (isAdminRole) {
        maxTicketFee = TICKET_ACCOUNT.MAX_ADMIN_TICKET_FEE;
      }
      const errorMessage = STRINGS.ERROR_MESSAGES.TICKET_FEE.INVALID.replace(
        '{min}',
        formatCurrency(TICKET_ACCOUNT.MIN_TICKET_FEE)
      ).replace('{max}', formatCurrency(maxTicketFee));
      const feeValue = parseFloat(`${fee}` ?? '0');
      if (feeValue < TICKET_ACCOUNT.MIN_TICKET_FEE || feeValue > maxTicketFee) {
        return testContext.createError({
          path: testContext.path,
          message: errorMessage
        });
      }

      return true;
    });

export const ticketLimitPerOrderBuilder = () =>
  yup
    .number()
    .nullable()
    .test('invalidTicketLimitPerOrder', (ticketLimitPerOrder, testContext) => {
      const { type, ticketLimitPerOrderSeasonEvent } = testContext.options?.context ?? {};

      if (isNil(ticketLimitPerOrder)) return true;

      const typeTicket = type === SeasonTicketTypeEnum.SEASON_TICKET ? 'season' : 'event';

      if (
        ticketLimitPerOrder < TICKET_ACCOUNT.MIN_TICKET_LIMIT_PER_ORDER ||
        ticketLimitPerOrder > TICKET_ACCOUNT.MAX_TICKET_LIMIT_PER_ORDER
      ) {
        return testContext.createError({
          path: testContext.path,
          message: 'You can enter a number between 1 and 150.'
        });
      }

      if (
        !!ticketLimitPerOrderSeasonEvent &&
        parseNumber(ticketLimitPerOrder) > parseNumber(ticketLimitPerOrderSeasonEvent)
      ) {
        return testContext.createError({
          path: testContext.path,
          message: `Ticket limit per order should be equal or smaller than the ${typeTicket}'s ticket limit per order`
        });
      }

      return true;
    });

export const packCountBuilder = () =>
  yup
    .number()
    .nullable()
    .test('invalidPackCount', (packCount, testContext) => {
      const { enabledPackCount } = testContext?.parent ?? {};
      const errorMsg = TICKET_ACCOUNT.ERRORS.MIN_MAX_PACK_COUNT.replace('Pass', 'ticket');
      if (
        enabledPackCount &&
        packCount != null &&
        (packCount < TICKET_ACCOUNT.MIN_PACK_COUNT || packCount > TICKET_ACCOUNT.MAX_PACK_COUNT)
      ) {
        return testContext.createError({
          path: testContext.path,
          message: errorMsg
        });
      }

      return true;
    });

export const SeasonTicketSchema = yup.object().shape({
  name: seasonTicketNameSchemaBuilder(),
  price: seasonTicketPriceSchemaBuilder(),
  salesStartDate: ticketSalesStartDateBuilder(),
  salesStartTime: ticketSalesStartTimeBuilder(),
  salesEndDate: ticketSalesEndDateBuilder(),
  salesEndTime: ticketSalesEndTimeBuilder(),
  fee: ticketFeeBuilder(),
  ticketLimitPerOrder: ticketLimitPerOrderBuilder()
});

export const TicketTypeSchema = yup.object().shape({
  ticketName: ticketNameSchemaBuilder(),
  ticketPrice: ticketPriceSchemaBuilder(),
  limit: limitSchemaBuilder(),
  boxOfficeTicket: boxOfficeTicketSchemaBuilder(),
  salesStartDate: ticketSalesStartDateBuilder(),
  salesStartTime: ticketSalesStartTimeBuilder(),
  salesEndDate: ticketSalesEndDateBuilder(),
  salesEndTime: ticketSalesEndTimeBuilder(),
  fee: ticketFeeBuilder(),
  ticketLimitPerOrder: ticketLimitPerOrderBuilder(),
  packCount: packCountBuilder()
});
