import SUPER_ADMIN from './superAdmin';
import SYSTEM_ADMIN from './systemAdmin';
import SYSTEM_FINANCE_ADMIN from './systemFinanceAdmin';
import SYSTEM_USER from './systemUser';
import ACCOUNT_USER from './accountUser';
import LIMITED_SYSTEM_USER from './limitedSystemUser';
/* Order of permissions:
Super Administrator
System Finance Admin
System Admin
System User
Limited System User
 */
export default {
  ACCOUNT_USER,
  LIMITED_SYSTEM_USER,
  SUPER_ADMIN,
  SYSTEM_ADMIN,
  SYSTEM_FINANCE_ADMIN,
  SYSTEM_USER
};
