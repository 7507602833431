/* eslint-disable @typescript-eslint/no-explicit-any */
import type { TeamCreateRequestDTO, AccountDTO, TeamSeasonEnum, TeamDTO } from '@gofan/api';
import { TeamGenderEnum, TeamService } from '@gofan/api';
import type { GendersLevelsState, LevelsGenders, CreateTeamFormValues, SchoolYearItem } from '@accounts/v2/types';
import { get, uniq } from 'lodash';

export const createTeamFormDefaultValues: CreateTeamFormValues = {
  activity: null,
  genderSpecific: true,
  genders: [],
  levels: [],
  season: undefined,
  schoolYear: null
};

export const groupLevelsByGenders = (genders: TeamGenderEnum[], levelsState: GendersLevelsState): LevelsGenders[] => {
  let levelsGenders: any[] = [];
  if (genders.length === 2) {
    const levelsMap: Record<string, TeamGenderEnum[]> = {};
    levelsState[TeamGenderEnum.BOYS].value.forEach(item => {
      levelsMap[item.id] = [TeamGenderEnum.BOYS];
    });
    levelsState[TeamGenderEnum.GIRLS].value.forEach(item => {
      const levelId = item.id;
      if (levelsMap[levelId]) {
        levelsMap[levelId].push(TeamGenderEnum.GIRLS);
      } else {
        levelsMap[levelId] = [TeamGenderEnum.GIRLS];
      }
    });
    return Object.keys(levelsMap).map(levelId => ({
      levelId: +levelId,
      genders: levelsMap[levelId] as TeamGenderEnum[]
    }));
  }
  if (genders.length === 1) {
    levelsGenders = levelsState[genders[0] as TeamGenderEnum.BOYS | TeamGenderEnum.GIRLS].value;
  }
  if (genders.length === 0) {
    levelsGenders = levelsState[TeamGenderEnum.COED].value;
  }
  return levelsGenders.map(item => ({ levelId: item.id, genders }));
};

export const getSchoolYearList = (): SchoolYearItem[] => [
  {
    key: 6,
    label: '2010-2011'
  },
  {
    key: 10,
    label: '2011-2012'
  },
  {
    key: 11,
    label: '2012-2013'
  },
  {
    key: 12,
    label: '2013-2014'
  },
  {
    key: 13,
    label: '2014-2015'
  },
  {
    key: 21,
    label: '2015-2016'
  },
  {
    key: 22,
    label: '2016-2017'
  },
  {
    key: 23,
    label: '2017-2018'
  },
  {
    key: 24,
    label: '2018-2019'
  },
  {
    key: 25,
    label: '2019-2020'
  },
  {
    key: 26,
    label: '2020-2021'
  },
  {
    key: 27,
    label: '2021-2022'
  },
  {
    key: 28,
    label: '2022-2023'
  },
  {
    key: 29,
    label: '2023-2024'
  },
  {
    key: 30,
    label: '2024-2025'
  }
];

export const getSchoolYearId = (schoolYear: string) =>
  getSchoolYearList().filter(item => item.label === schoolYear)[0]?.key;

export const prepareTeamCreateRequestData = (data: CreateTeamFormValues, account?: AccountDTO) => {
  const teams: TeamCreateRequestDTO[] = [];

  if (!account) return teams;

  data.levels.forEach(level => {
    const genders = level?.genders?.length === 0 ? [TeamGenderEnum.COED] : level?.genders;
    const team = {
      activityId: data.activity?.id as number,
      levelId: level?.levelId as number,
      organizationId: account.id as string,
      startYear: data.schoolYear?.key as number,
      seasonName: data.season as TeamSeasonEnum
    };

    genders?.forEach(gender => {
      teams.push({ ...team, gender: gender as TeamGenderEnum });
    });
  });

  return teams;
};

export const getTeamsByActivityId = async (accountId: string, activityId: string) => {
  const results = await TeamService.searchTeam({
    organizationId: accountId,
    activityId: Number.parseInt(activityId, 10),
    size: 999
  });

  const data: TeamDTO[] = get(results, 'content', []).map(item => ({
    activity: item.activityName,
    id: item.id,
    name: `${item.gender} ${item.levelName}`,
    activityId: item.activityId.toString(),
    gender: item.gender,
    level: item.levelName,
    levelId: item.levelId,
    season: item.seasonName,
    ticketTypesValue: '',
    mappedToCameraValue: '',
    department: item.department
  }));

  return data;
};

export const mapTeamsToSearchParams = (teams: TeamDTO[]) => {
  const filter: { [key: string]: string[] } = {};

  teams.forEach(item => {
    if (!item.levelId) return;

    if (!filter[item.levelId]) {
      filter[item.levelId] = [];
    }

    filter[item.levelId] = [...filter[item.levelId], item.gender];
  });

  return Object.keys(filter).map(key => ({
    levelId: Number.parseInt(key, 10),
    genders: uniq(filter[key])
  }));
};
