import React, { useRef, useState, useEffect, useCallback } from 'react';
import { omit, uniqBy, isEmpty, uniqueId } from 'lodash';
import { Tag, TextInput } from 'carbon-components-react';

import defaultAccountIcon from '@assets/images/gofan-icon.png';
import { generateError } from '@utils/alertUtils';
import { getSchoolImage } from '@app/pages/EventInformationV2/helpers';
import { config } from '@gofan/constants';

import { ACCOUNT_STATUS } from '@app/modules/accounts/constants/constants';
import { AccountService } from '@gofan/api/accounts';
import type { AccountDTO, AccountPageResponseDTO, AccountSearchParams } from '@gofan/api/accounts';
import { sortStates } from '@old-components/basic-table/sorting';

import SectionInfo from '@accounts/components/section-info/section-info.component';
import SearchSelection from '@old-components/search-selection/search-selection.component';
import { CANCELLED_REQUEST } from '@app/api/api/constants';

import './school-selection.component.scss';

type SchoolSelectionProps = {
  accounts: AccountDTO[];
  selectionAccounts: AccountDTO[];
  isInternalUser: boolean;
  isAccountUser?: boolean;
  isDistrictUser?: boolean;
  setAccounts: Function;
  setSeletionAccounts: Function;
  addNotification: Function;
};

export type SearchAccount = AccountDTO & {
  subTitleid: string;
  title: string;
  subTitle: string;
  icon: string;
  gofanUrl?: string;
  nfhsUrl?: string;
};

export const mergeAccount = (accounts: AccountDTO[], items: SearchAccount[]): AccountDTO[] => {
  const selectedItems = items.map(item => omit(item, ['subTitleid', 'title', 'subTitle']));
  return uniqBy([...accounts, ...selectedItems], 'id').sort((a, b) => a.name.localeCompare(b.name));
};

export const searchAccountsMapping = (accounts: AccountDTO[]): SearchAccount[] =>
  accounts
    .filter(item => item.status === ACCOUNT_STATUS.ACTIVE)
    .map(
      (item: AccountDTO): SearchAccount => ({
        ...item,
        subTitleid: uniqueId(),
        icon: isEmpty(item.logo) ? '' : getSchoolImage(item.id, 'logo', item.logo),
        title: item.name,
        subTitle: `${item.city}${!isEmpty(item.city) && !isEmpty(item.state) ? ',' : ''} ${item.state}`
      })
    )
    .sort((a, b) => a.name.localeCompare(b.name));

const SEARCH_PARAMS: AccountSearchParams = {
  page: 0,
  pageSize: 25,
  body: {
    keyword: ''
  },
  sortBy: {
    header: 'name',
    sortDirection: sortStates.ASC
  }
};

const SchoolSelection = ({
  accounts,
  selectionAccounts,
  isInternalUser,
  isAccountUser,
  isDistrictUser,
  setAccounts,
  setSeletionAccounts,
  addNotification
}: SchoolSelectionProps) => {
  const searchTimer = useRef<any>(null);
  const isUnmounted = useRef<boolean>(false);
  const accountsRef = useRef<AccountDTO[]>(accounts);
  const [open, setOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<SearchAccount[]>([]);
  const isOnlyAccountUserContext = isAccountUser && selectionAccounts?.length === 1;

  const handleSearchAccounts = useCallback(
    value => {
      clearTimeout(searchTimer.current);
      setSearching(true);
      searchTimer.current = setTimeout(() => {
        AccountService.searchAccountByParams({ ...SEARCH_PARAMS, body: { ...SEARCH_PARAMS.body, keyword: value } })
          .then((res: AccountPageResponseDTO) => {
            if (isUnmounted.current) return;
            setSearchResults(searchAccountsMapping(res.content));
            setSearching(false);
          })
          .catch((err: any) => {
            if (err.message !== CANCELLED_REQUEST) {
              if (!isUnmounted.current) {
                setSearching(false);
                addNotification(generateError(err));
              }
            }
          });
      }, config.DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST);
    },
    [searchTimer.current]
  );

  const handleTextChange = (searchText: string) => {
    setSearchValue(searchText);
    if (searchText.trim() === '') {
      return setSearchResults([]);
    }
    handleSearchAccounts(searchText);
  };

  const handleSelectItem = useCallback(
    item => {
      const found = selectionAccounts.find(acc => acc.id === item.id);
      if (!found) {
        setSeletionAccounts([...mergeAccount(selectionAccounts, [item])]);
        accountsRef.current = [...mergeAccount(accountsRef.current, [item])];
        setAccounts(accountsRef.current);
      }
    },
    [accounts, selectionAccounts, accountsRef.current]
  );

  const handleChangeSelectedItems = useCallback(
    ({ selectedItems }) => {
      accountsRef.current = uniqBy([...selectedItems], 'id');
      if (!open && isEmpty(selectedItems)) setAccounts(accountsRef.current);
    },
    [accountsRef.current, open]
  );

  const handleMenuChange = useCallback(
    (toggle: boolean) => {
      setOpen(toggle);
      if (!toggle) setAccounts(accountsRef.current);
    },
    [accounts, open]
  );

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  const computedSearchResults = React.useMemo(
    () => (searchValue !== '' && !searching ? searchResults : []),
    [searchValue, searching, searchResults]
  );

  return (
    <SectionInfo title='' className='school-selection-wrapper'>
      <p className='bx--label gs--body-short-02-semibold gs--text'>{_getLabel()}</p>
      {isOnlyAccountUserContext ? (
        <TextInput id='dashboard-school-selection' disabled labelText='' defaultValue={selectionAccounts?.[0]?.name} />
      ) : (
        <SearchSelection
          id='dashboard-school-selection'
          defaultItemIcon={defaultAccountIcon}
          searching={searching}
          hasSearchInput={isInternalUser}
          hasGroupSelection={isDistrictUser}
          multiSelectTitle={_getTitleSearchSelection()}
          searchPlaceHolderText='Find a school'
          searchValue={searchValue}
          searchResults={computedSearchResults}
          items={selectionAccounts}
          selectedItems={accounts}
          onTextChange={handleTextChange}
          onSelectItem={handleSelectItem}
          onMenuChange={handleMenuChange}
          onChangeSelectedItems={handleChangeSelectedItems}
        />
      )}
      {isDistrictUser && !isEmpty(accounts) && (
        <div className='selected-schools-wrapper'>
          {accounts?.map(item => (
            <Tag key={item.id} type='purple' title={item.name}>
              {item.name ?? ''}
            </Tag>
          ))}
        </div>
      )}
    </SectionInfo>
  );

  function _getLabel() {
    if (isOnlyAccountUserContext) return 'Your school';
    return 'Select Your Schools';
  }

  function _getTitleSearchSelection() {
    return isDistrictUser ? 'School Selected in Your District' : '';
  }
};

export default SchoolSelection;
