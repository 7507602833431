import { useRef, useState, useEffect, forwardRef, useCallback } from 'react';
import type { Moment } from 'moment';
import moment from 'moment';
import { Close16 } from '@carbon/icons-react';
import { isEmpty, isEqual, isFunction } from 'lodash';
import { Checkbox, Column, MultiSelect, Row, RadioButtonGroup, RadioButton } from 'carbon-components-react';

import type { AccountDTO } from '@gofan/api/accounts';
import type { ActivityDTO } from '@gofan/api/activities';
import type { EventSearchDTO } from '@events/models/event.model';

import SectionInfo from '@accounts/components/section-info/section-info.component';

import BasicButton from '@old-components/basic-button/basic-button.component';
import StyledDatePicker from '@common/carbon-ui/atoms/StyledDatePicker';
import FormField from '@events/components/form-field/form-field.component';

import { getSearchParamsByFilterValues } from '@events/utils/event-table.utils';
import { EVENT_TABLE } from '@dashboard/hooks';

import type { VenueDTO } from '@gofan/api/venues';
import type { LevelDTO } from '@gofan/api/levels';
import type { EventTable as EventTableKind } from '@dashboard/hooks';

import { QUICK_FILTER_TYPE, EVENT_CATEGORIES_FILTERS, FILTER_CATEGORIES } from '@events/constants';

type DatePickerEvent = {
  date: Moment;
};

export type FormValues = {
  endDate?: string | Moment;
  startDate?: string | Moment;
  specificDate?: string | Moment;
  accounts?: AccountDTO[] | AccountSelection[];
  activities?: ActivityDTO[];
  visibility?: {
    hidden?: boolean;
    visible?: boolean;
    canceled?: boolean;
  };
  eventTypes?: {
    postSeason?: boolean;
    regularSeason?: boolean;
    nonAthletic?: boolean;
  };
  genders?: {
    boys?: boolean;
    girls?: boolean;
    coed?: boolean;
  };
  levels?: LevelDTO[];
  searchOption?: {
    home?: boolean;
    away?: boolean;
  };
  timeRange?: string;
  categories?: string[];
  teamLevels?: string[];
  venues?: VenueDTO[];
};

export type AccountSelection = {
  id: string;
  name: string;
};

export const FORM_DEFAULT_VALUES: FormValues = {
  accounts: [],
  activities: [],
  visibility: {
    hidden: false,
    visible: false,
    canceled: false
  },
  eventTypes: {
    regularSeason: false,
    postSeason: false,
    nonAthletic: false
  },
  genders: {
    boys: false,
    girls: false,
    coed: false
  },
  levels: [],
  searchOption: {
    home: false,
    away: false
  },
  teamLevels: [],
  timeRange: QUICK_FILTER_TYPE.ALL_EVENTS,
  specificDate: undefined,
  startDate: undefined,
  endDate: undefined,
  categories: [],
  venues: []
};

interface EventFilterFormProps {
  kind: EventTableKind;
  accounts: AccountDTO[];
  activities: ActivityDTO[];
  levels: LevelDTO[];
  venues: VenueDTO[];
  hasVenueFilter?: boolean;
  filterValues?: FormValues;
  onCloseForm?: () => void;
  // eslint-disable-next-line no-unused-vars
  onFilterApply?: (params: EventSearchDTO, formValues: FormValues) => void;
}

const EventFilterForm = forwardRef<HTMLButtonElement, EventFilterFormProps>(
  (
    {
      kind,
      accounts = [],
      activities = [],
      levels = [],
      venues = [],
      filterValues = {},
      hasVenueFilter,
      onCloseForm,
      onFilterApply
    }: EventFilterFormProps,
    ref
  ) => {
    const [prepare, setPrepare] = useState<boolean>(false);
    const [forceKey, setForceKey] = useState<number>(1);
    const [resetKey, setResetKey] = useState<number>(1);
    const filterValuesRef = useRef<FormValues>(FORM_DEFAULT_VALUES);
    const defaultValues = useRef<FormValues>(FORM_DEFAULT_VALUES);
    const isInitialQuickFiltered = useRef<boolean>(true);
    const isShowCategories = [EVENT_TABLE.ALL_EVENTS, EVENT_TABLE.TICKETING].includes(kind);

    const onChangeDatePicker = useCallback(
      (datePickerName: string) =>
        ({ date }: DatePickerEvent) => {
          const updatedDate = date && date.isValid() ? date : undefined;
          filterValuesRef.current = { ...filterValuesRef.current, [datePickerName]: updatedDate };
          filterValuesRef.current.timeRange = QUICK_FILTER_TYPE.ALL_EVENTS;

          if (datePickerName === 'specificDate') {
            filterValuesRef.current.startDate = undefined;
            filterValuesRef.current.endDate = undefined;
          } else if (datePickerName === 'startDate' || datePickerName === 'endDate') {
            filterValuesRef.current.specificDate = undefined;
          }

          setForceKey(new Date().getTime());
        },
      [filterValuesRef.current, forceKey]
    );

    const onChangedMultipSelect = useCallback(
      (multipSelectName: string) =>
        ({ selectedItems }: { selectedItems: any }) => {
          filterValuesRef.current = { ...filterValuesRef.current, [multipSelectName]: selectedItems };
          setForceKey(new Date().getTime());
        },
      [filterValuesRef.current, forceKey]
    );

    const onCheckedVisibility = useCallback(
      (visibility: 'visible' | 'hidden' | 'canceled') => (checked: boolean) => {
        filterValuesRef.current = {
          ...filterValuesRef.current,
          visibility: {
            ...filterValuesRef.current.visibility,
            [visibility]: checked
          }
        };
        setForceKey(new Date().getTime());
      },
      [filterValuesRef.current, forceKey]
    );

    const onCheckedEventTypes = useCallback(
      (eventType: 'regularSeason' | 'postSeason' | 'nonAthletic') => (checked: boolean) => {
        filterValuesRef.current = {
          ...filterValuesRef.current,
          eventTypes: {
            ...filterValuesRef.current.eventTypes,
            [eventType]: checked
          }
        };
        setForceKey(new Date().getTime());
      },
      [filterValuesRef.current, forceKey]
    );

    const onChangeCategories = useCallback(
      (category: string[]) => {
        filterValuesRef.current = {
          ...filterValuesRef.current,
          categories: category
        };
        setForceKey(new Date().getTime());
      },

      [filterValuesRef.current, forceKey]
    );

    const onCheckedGenders = useCallback(
      (gender: 'boys' | 'girls' | 'coed') => (checked: boolean) => {
        const genders = filterValuesRef.current.genders || {};
        filterValuesRef.current = {
          ...filterValuesRef.current,
          genders: {
            ...(gender === 'coed' || Object.keys(genders).includes('coed') ? {} : genders),
            [gender]: checked
          }
        };
        setForceKey(new Date().getTime());
      },
      [filterValuesRef.current, forceKey]
    );

    const onCheckedSearchOptions = useCallback(
      (searchOption: 'home' | 'away') => (checked: boolean) => {
        filterValuesRef.current = {
          ...filterValuesRef.current,
          searchOption: {
            ...filterValuesRef.current.searchOption,
            [searchOption]: checked
          }
        };
        setForceKey(new Date().getTime());
      },
      [filterValuesRef.current, forceKey]
    );

    const onSelectTimeRage = useCallback(
      (timeRange: string) => {
        filterValuesRef.current = {
          ...filterValuesRef.current,
          specificDate: undefined,
          startDate: undefined,
          endDate: undefined,
          timeRange
        };
        setForceKey(new Date().getTime());
      },
      [filterValuesRef.current, forceKey]
    );

    const onFilterApplied = useCallback(() => {
      if (isFunction(onFilterApply)) {
        const searchParams = getSearchParamsByFilterValues(filterValuesRef.current, accounts);
        onFilterApply(searchParams, filterValuesRef.current);
        if (isFunction(onCloseForm)) onCloseForm();
      }
    }, [filterValuesRef.current, accounts]);

    // Handle click outSide
    const handleClickOutside = useCallback(evt => {
      if (!document) return;
      const schoolEle = document.getElementById('event-filter-school');
      const filterFormEle = document.getElementById('event-filter-form');
      const calendarEle = document.getElementsByClassName('flatpickr-calendar bx--date-picker__calendar open');
      let targetElement = evt.target; // clicked element
      do {
        // clicked inside
        if (
          !document.contains(targetElement) ||
          targetElement === schoolEle ||
          targetElement === filterFormEle ||
          !isEmpty(calendarEle)
        )
          return;
        targetElement = targetElement.parentNode;
      } while (targetElement);
      // clicked outside
      if (isFunction(onCloseForm)) onCloseForm();
    }, []);

    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, []);

    useEffect(() => {
      filterValuesRef.current = {
        endDate: filterValues.endDate || undefined,
        startDate: filterValues.startDate || undefined,
        specificDate: filterValues.specificDate || undefined,
        visibility: {
          hidden: Boolean(filterValues.visibility?.hidden),
          visible: Boolean(filterValues.visibility?.visible),
          canceled: Boolean(filterValues.visibility?.canceled)
        },
        accounts: isEmpty(filterValues.accounts) ? [] : filterValues.accounts,
        activities: isEmpty(filterValues.activities) ? [] : filterValues.activities,
        venues: isEmpty(filterValues.venues) ? [] : filterValues.venues,
        eventTypes: {
          regularSeason: !!filterValues.eventTypes?.regularSeason,
          postSeason: !!filterValues.eventTypes?.postSeason,
          nonAthletic: !!filterValues.eventTypes?.nonAthletic
        },
        genders: {
          boys: Boolean(filterValues.genders?.boys),
          girls: Boolean(filterValues.genders?.girls),
          coed: Boolean(filterValues.genders?.coed)
        },
        levels: isEmpty(filterValues.levels) ? [] : filterValues.levels,
        searchOption: {
          home: Boolean(filterValues.searchOption?.home),
          away: Boolean(filterValues.searchOption?.away)
        },
        timeRange: filterValues?.timeRange ?? undefined,
        categories: filterValues?.categories || []
      };
      defaultValues.current = { ...filterValuesRef.current };
      if (filterValues.endDate || filterValues.startDate || filterValues.specificDate) {
        isInitialQuickFiltered.current = false;
      } else {
        isInitialQuickFiltered.current = true;
      }
      setForceKey(new Date().getTime());
      setPrepare(true);
    }, []);

    if (!prepare) return null;

    return (
      <div className='event-filter-form' id='event-filter-form'>
        <div className='event-filter-form--overlay' aria-hidden='true' onClick={onCloseForm} />
        <SectionInfo
          title='Filters'
          className='event-filter-form--container'
          buttons={[
            {
              text: '',
              kind: 'ghost',
              renderIcon: Close16,
              className: 'event-filter-form--close-btn',
              onClick: onCloseForm
            }
          ]}
        >
          <div className='event-filter-form--body'>
            <div className='group-filter'>
              <FormField
                title='Quick filters'
                onClick={() => {
                  filterValuesRef.current.startDate = undefined;
                  filterValuesRef.current.endDate = undefined;
                  filterValuesRef.current.specificDate = undefined;
                  setForceKey(new Date().getTime());
                }}
              >
                <Row>
                  <Column lg={6}>
                    <RadioButtonGroup
                      name='timeRange'
                      className='time-range'
                      valueSelected={filterValuesRef.current.timeRange}
                      onChange={onSelectTimeRage}
                    >
                      <RadioButton
                        className='gs--padding-bottom__sp2'
                        labelText="Today's events"
                        value={QUICK_FILTER_TYPE.TODAY_EVENTS}
                      />
                      <RadioButton
                        className='gs--padding-bottom__sp2'
                        labelText='Current events'
                        value={QUICK_FILTER_TYPE.ALL_EVENTS}
                      />
                      <RadioButton
                        className='gs--padding-bottom__sp2'
                        labelText='Upcoming events'
                        value={QUICK_FILTER_TYPE.UPCOMING_EVENTS}
                      />
                      <RadioButton labelText='Last 7 days' value={QUICK_FILTER_TYPE.LAST_7_DAYS} />
                    </RadioButtonGroup>
                  </Column>
                  <Column className='left-divider' lg={6}>
                    <Checkbox
                      id='home'
                      labelText='Home events'
                      checked={filterValuesRef.current.searchOption?.home || false}
                      onChange={onCheckedSearchOptions('home')}
                    />
                    <Checkbox
                      id='away'
                      labelText='Away events'
                      checked={filterValuesRef.current.searchOption?.away || false}
                      onChange={onCheckedSearchOptions('away')}
                    />
                  </Column>
                </Row>
              </FormField>
            </div>
            {!isEmpty(accounts) && accounts.length > 1 && (
              <div className='group-filter'>
                <FormField title='Our schools'>
                  <MultiSelect
                    id='event-filter-school'
                    key={resetKey}
                    light={false}
                    items={accounts.map(({ id, name }: AccountSelection) => ({ id, name }))}
                    initialSelectedItems={filterValuesRef.current.accounts}
                    itemToString={(item: AccountSelection) => item.name || ''}
                    label='Schools selected'
                    onChange={onChangedMultipSelect('accounts')}
                  />
                </FormField>
              </div>
            )}
            <div className='group-filter'>
              <FormField title='Gender / Level'>
                <Row>
                  <Column lg={3} className='gs--padding-top__sp5'>
                    <Checkbox
                      id='boys'
                      labelText='Boys'
                      checked={filterValuesRef.current.genders?.boys || false}
                      onChange={onCheckedGenders('boys')}
                    />
                    <Checkbox
                      id='girls'
                      labelText='Girls'
                      checked={filterValuesRef.current.genders?.girls || false}
                      onChange={onCheckedGenders('girls')}
                    />
                  </Column>
                  <Column lg={9}>
                    <MultiSelect
                      id='event-filter-levels'
                      light={false}
                      items={levels}
                      key={resetKey}
                      initialSelectedItems={filterValuesRef.current.levels}
                      itemToString={(item: LevelDTO) => item.name || ''}
                      label='levels selected'
                      titleText='Levels'
                      onChange={onChangedMultipSelect('levels')}
                    />
                  </Column>
                </Row>
                <Row className='event-filter-divider'>
                  <Column className='divider' />
                </Row>
                <Row>
                  <Column>
                    <Checkbox
                      id='coed'
                      labelText='Coed'
                      checked={filterValuesRef.current.genders?.coed || false}
                      onChange={onCheckedGenders('coed')}
                    />
                  </Column>
                </Row>
              </FormField>
            </div>
            <div className='group-filter'>
              <FormField title='Activities'>
                <MultiSelect
                  id='event-filter-sport'
                  light={false}
                  key={resetKey}
                  items={activities}
                  initialSelectedItems={filterValuesRef.current.activities}
                  itemToString={(item: ActivityDTO) => item.label || ''}
                  sortItems={(items: any) => [...items]}
                  label='Activities selected'
                  onChange={onChangedMultipSelect('activities')}
                />
              </FormField>
            </div>
            {hasVenueFilter && (
              <div className='group-filter'>
                <FormField title='Venues'>
                  <MultiSelect
                    id='event-filter-venues'
                    light={false}
                    key={resetKey}
                    items={venues?.map(({ id, name }: VenueDTO) => ({ id, name }))}
                    initialSelectedItems={filterValuesRef.current?.venues}
                    itemToString={(item: VenueDTO) => item.name || ''}
                    label='Venues selected'
                    onChange={onChangedMultipSelect('venues')}
                  />
                </FormField>
              </div>
            )}
            <div className='group-filter'>
              <FormField title='Visibility'>
                <Checkbox
                  id='event-filter-visible'
                  labelText='Visible events'
                  checked={filterValuesRef.current.visibility?.visible || false}
                  onChange={onCheckedVisibility('visible')}
                />
                <Checkbox
                  id='event-filter-hidden'
                  labelText='Hidden events'
                  checked={filterValuesRef.current.visibility?.hidden || false}
                  onChange={onCheckedVisibility('hidden')}
                />
                <Checkbox
                  id='event-filter-canceled'
                  labelText='Canceled events'
                  checked={filterValuesRef.current.visibility?.canceled || false}
                  onChange={onCheckedVisibility('canceled')}
                />
              </FormField>
            </div>
            <div className='group-filter'>
              <FormField title='Event types'>
                <Checkbox
                  id='event-filter-regular-season'
                  labelText='Regular season'
                  checked={filterValuesRef.current.eventTypes?.regularSeason || false}
                  onChange={onCheckedEventTypes('regularSeason')}
                />
                <Checkbox
                  id='event-filter-post-season'
                  labelText='Post season'
                  checked={filterValuesRef.current.eventTypes?.postSeason || false}
                  onChange={onCheckedEventTypes('postSeason')}
                />
                <Checkbox
                  id='event-filter-non-athletic'
                  labelText='Non-athletic'
                  checked={filterValuesRef.current.eventTypes?.nonAthletic || false}
                  onChange={onCheckedEventTypes('nonAthletic')}
                />
              </FormField>
            </div>
            {isShowCategories && (
              <div className='group-filter'>
                <FormField title='Categories'>
                  <RadioButtonGroup
                    name='event-categories'
                    valueSelected={filterValuesRef.current.categories?.[0] ?? EVENT_CATEGORIES_FILTERS.all.name}
                    onChange={val => {
                      let filterKey: keyof typeof EVENT_CATEGORIES_FILTERS;

                      switch (val) {
                        case FILTER_CATEGORIES.ALL:
                          filterKey = 'all';
                          break;
                        case FILTER_CATEGORIES.MOBILEPASS:
                          filterKey = 'mobilePass';
                          break;
                        case FILTER_CATEGORIES.CONCESSION:
                          filterKey = 'concession';
                          break;
                        case FILTER_CATEGORIES.DONATION:
                          filterKey = 'fundraiser';
                          break;

                        default:
                          filterKey = 'all';
                      }

                      onChangeCategories(EVENT_CATEGORIES_FILTERS[filterKey].value);
                    }}
                    className='gf-event-categories-group'
                  >
                    <RadioButton
                      id='event-filter-event-ticket'
                      labelText='All'
                      value={FILTER_CATEGORIES.ALL}
                      className='gs--padding-bottom__sp2'
                    />
                    <RadioButton
                      id='event-filter-mobile-pass'
                      labelText='Mobile Pass'
                      value={FILTER_CATEGORIES.MOBILEPASS}
                      className='gs--padding-bottom__sp2'
                    />
                    <RadioButton
                      id='event-filter-concession'
                      labelText='Concession'
                      value={FILTER_CATEGORIES.CONCESSION}
                      className='gs--padding-bottom__sp2'
                    />
                    <RadioButton
                      id='event-filter-fundraiser'
                      labelText='Fundraiser'
                      value={FILTER_CATEGORIES.DONATION}
                      className='gs--padding-bottom__sp2'
                    />
                  </RadioButtonGroup>
                </FormField>
              </div>
            )}
            <div className='group-filter'>
              <FormField title='Date' aria-hidden='true'>
                <div className='date-single-picker'>
                  <StyledDatePicker
                    id='event-filter-specific-date'
                    showTime={false}
                    labelDateText='Events on'
                    date={filterValuesRef.current.specificDate as any}
                    onClose={onChangeDatePicker('specificDate')}
                    onChange={onChangeDatePicker('specificDate')}
                  />
                </div>
              </FormField>
              <FormField title='' aria-hidden='true'>
                <div className='bx--label'>Events between:</div>
                <div className='date-range-wrapper'>
                  <div className='date-range-wrapper--date-picker'>
                    <StyledDatePicker
                      id='event-filter-start-date'
                      labelDateText='Start date'
                      showTime={false}
                      date={filterValuesRef.current.startDate as any}
                      onClose={onChangeDatePicker('startDate')}
                      onChange={onChangeDatePicker('startDate')}
                      maxDate={
                        filterValuesRef.current.endDate
                          ? moment(filterValuesRef.current.endDate).format('MM/DD/YYYY')
                          : undefined
                      }
                    />
                  </div>
                  <div className='date-range-wrapper--date-picker'>
                    <StyledDatePicker
                      id='event-filter-end-date'
                      labelDateText='End date'
                      showTime={false}
                      date={filterValuesRef.current.endDate as any}
                      onClose={onChangeDatePicker('endDate')}
                      onChange={onChangeDatePicker('endDate')}
                      minDate={
                        filterValuesRef.current.startDate
                          ? moment(filterValuesRef.current.startDate).format('MM/DD/YYYY')
                          : undefined
                      }
                    />
                  </div>
                </div>
              </FormField>
            </div>
          </div>
          <div className='event-filter-form--footer'>
            <div className='event-filter-form--button-action'>
              <BasicButton
                size='field'
                kind='secondary'
                textAlign='center'
                text='Reset all filters'
                disabled={false}
                onClick={() => {
                  filterValuesRef.current = FORM_DEFAULT_VALUES;

                  setResetKey(new Date().getTime());
                }}
              />
            </div>
            <div className='event-filter-form--button-action'>
              <BasicButton
                size='field'
                kind='tertiary'
                textAlign='center'
                text='Apply'
                disabled={isEmpty(accounts) || isEqual(filterValuesRef.current, defaultValues.current)}
                onClick={onFilterApplied}
              />
            </div>
          </div>
        </SectionInfo>
      </div>
    );
  }
);

export default EventFilterForm;
