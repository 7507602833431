import type { AccountDTO, AccountVenue, SchoolData } from './account.model';

export const getValueContactPhone = (value = ''): { phoneNumber: string; extNumber: string } => {
  /* Please prefer this doc if you un-familiar with US format phone number :
     https://developers.google.com/style/phone-numbers
   */
  const newValue = value.replace(/\D/g, '');
  return newValue.length > 10
    ? {
        phoneNumber: newValue.slice(0, 10),
        extNumber: newValue.slice(10)
      }
    : {
        phoneNumber: newValue,
        extNumber: ''
      };
};

export const getLast4DigitsNumber = (value = ''): string => {
  const newValue = value.replace(/\D/g, '');
  return newValue.slice(newValue.length - 4) || newValue;
};

export const getAccountVenues = (account: AccountDTO): AccountVenue[] =>
  (account.venues || [])
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(venue => ({ ...venue, accountId: account.id }));

export const sortSchoolsByName = (schools: AccountDTO[] | SchoolData[]) =>
  schools.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }));
