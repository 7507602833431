import type { TeamEventTicketSale } from '@gofan/api';

export const mockFundraiserInfo: TeamEventTicketSale = {
  id: 'fundraiser',
  title: 'Baseball Fundraiser - Ongoing',
  date: '01/01/2023 - 31/12/2024',
  ticketSold: [
    { group: 'Bronze Donation', value: 38 },
    { group: 'Gold Donation', value: 48 },
    { group: 'Silver Donation', value: 18 },
    { group: 'VIP Donation', value: 28 }
  ]
};

export const mockConcessionInfo: TeamEventTicketSale = {
  id: 'concession',
  title: 'Gym A - Concession sales',
  date: '',
  ticketSold: [
    { group: 'Hotdogs', value: 30 },
    { group: 'Fries', value: 30 },
    { group: 'Candy', value: 12 },
    { group: 'Soda', value: 20 },
    { group: 'Sandwich', value: 40 }
  ]
};
