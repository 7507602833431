import styled from 'styled-components';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const BodyWrapper = styled.div`
  padding: 0 24px;
  padding-top: 75px;
  min-height: 100vh;
`;

export default ({ children, ...props }) => (
  <>
    <BodyWrapper style={{ marginLeft: 'auto', width: props.isSideMenuExpanded ? 'calc(100% - 350px)' : '100%' }}>
      {children}
    </BodyWrapper>
    <NotificationContainer />
  </>
);
