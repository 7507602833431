import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { UnityService } from './unity.service';

import { GAMES_QUERY_KEY } from './unity.endpoints';

import type { SearchGame } from './unity.model';

export interface UseGamesOptions {
  params: SearchGame;
  queryOptions?: {
    cacheTime?: number;
    staleTime?: number;
    enabled?: boolean;
  };
}

export const useGames = ({ params, queryOptions }: UseGamesOptions) => {
  const queryClient = useQueryClient();

  let key = '';

  if (typeof params.key === 'string') key = params.key;
  else if (typeof params.partner_game_id === 'string') key = params.partner_game_id;
  else if (Array.isArray(params.key) && Array.isArray(params.partner_game_id))
    key = [...params.key, ...params.partner_game_id].join(',');
  else if (Array.isArray(params.key)) key = params.key.join(',');
  else if (Array.isArray(params.partner_game_id)) key = params.partner_game_id.join(',');

  const query = useQuery({
    queryKey: [GAMES_QUERY_KEY, params.internal, params.is_pixellot, key],
    queryFn: () => UnityService.searchGame(params).then(res => res.items),
    refetchOnWindowFocus: false,
    ...queryOptions
  });

  const completeGame = useMutation({
    mutationFn: (key: string) => UnityService.completeGame(key),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GAMES_QUERY_KEY, params.internal, params.is_pixellot, params.partner_game_id]
      });
    }
  });

  return { ...query, completeGame };
};
