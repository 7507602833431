import React from 'react';
import BaseInput from './BaseInput';
import FormSafeguard from './FormSafeguard';
import ViewOnlyGuard from './ViewOnlyGuard';

import { DEFAULT_HEIGHT_INPUT } from '../../../assets/styles/common';

export default ({
  label,
  formKey,
  overwritable,
  required,
  min,
  max,
  validation,
  errorMessage,
  hideLabel,
  placeHolder,
  customHeight = DEFAULT_HEIGHT_INPUT
}) => (
  <FormSafeguard formKey={formKey}>
    <ViewOnlyGuard label={label} formKey={formKey}>
      <BaseInput
        type='number'
        formKey={formKey}
        label={label}
        overwritable={overwritable}
        required={required}
        min={min}
        max={max}
        validation={validation}
        errorMessage={errorMessage}
        customHeight={customHeight}
        placeHolder={placeHolder}
        hideLabel={hideLabel}
      />
    </ViewOnlyGuard>
  </FormSafeguard>
);
