import type { AxiosRequestConfig, Method } from 'axios';
import axios from 'axios';
/* eslint-disable no-param-reassign */

import { MAINTENANCE_MODE } from './constants';
import { config } from '@gofan/constants';
import { getToken } from '@gofan/api';
import LocalStorage from '../../LocalStorage';
import { isEmpty } from '../../utils/objectUtils';
import ErrorHandler from '../../utils/ErrorHandler';
import APIErrorResolver from '../../utils/ErrorHandler/resolvers/APIErrorResolver';

const handleError = (error: any, manualErrorHandling: boolean) => {
  if (error.mode === MAINTENANCE_MODE || !manualErrorHandling) {
    const context = {
      error,
      token: LocalStorage.getToken(),
      type: APIErrorResolver.NAME
    };
    ErrorHandler.getInstance().resolve(context);
  }
};

export class AxiosProxy {
  static AXIOS_CONFIG = {
    baseURL: config.apiGateway.URL,
    headers: {
      Accept: 'application/json, text/html',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  };

  static AXIOS_MS_CONFIG = {
    baseURL: config.apiGatewayMicroservices.URL,
    headers: {
      Accept: 'application/json, text/html',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  };

  static AXIOS_ES_CONFIG = {
    baseURL: config.apiGatewayEventService.URL,
    // baseURL: config.api,
    headers: {
      Accept: 'application/json, text/html',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  };

  static AXIOS_PARTNER_CONFIG = {
    baseURL: config.apiPartnerEvent.URL,
    headers: {
      Accept: 'application/json, text/html',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  };

  static FLAT_FILE_CONFIG = {
    baseURL: config.apiFlatFile.URL,
    headers: {
      Accept: 'application/json, text/html',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  };

  constructor() {
    axios(AxiosProxy.AXIOS_CONFIG);
    axios.interceptors.request.use(
      async axiosConfig => {
        const token = await getToken();
        if (!isEmpty(token)) {
          axiosConfig.headers.Authorization = `Bearer ${token}`;
        } else if (axiosConfig && axiosConfig.baseURL && axiosConfig.baseURL.includes('apigateway')) {
          axiosConfig.headers['x-api-key'] = null;
        }
        return axiosConfig;
      },
      error => {
        throw error;
      }
    );

    axios.interceptors.response.use(
      response => response,
      error => {
        if (
          !isEmpty(error.response) &&
          !isEmpty(error.response.data) &&
          error.response.status === 503 &&
          error.response.data.code === 503
        ) {
          // eslint-disable-next-line no-throw-literal
          throw {
            ...error.response.data,
            mode: MAINTENANCE_MODE
          };
        }
        throw error;
      }
    );
  }

  msConfig = AxiosProxy.AXIOS_MS_CONFIG;

  partnerConfig = AxiosProxy.AXIOS_PARTNER_CONFIG;

  eventConfig = AxiosProxy.AXIOS_EVENT_CONFIG;

  flatFileConfig = AxiosProxy.FLAT_FILE_CONFIG;

  request = (configuration: AxiosRequestConfig) => axios.request(configuration);

  get = async <T = any>(url: string, configuration?: AxiosRequestConfig, manualErrorHandling = false) => {
    const axiosConfig = this.buildAxiosConfig('get', url, configuration);
    return axios
      .get<T>(url, axiosConfig)
      .then(response => response.data)
      .catch(e => {
        handleError(e, manualErrorHandling);
        throw e;
      });
  };

  delete = <T = any>(url: string, configuration: AxiosRequestConfig, manualErrorHandling = false) => {
    const axiosConfig = this.buildAxiosConfig('post', url, configuration);
    return axios
      .delete<T>(url, axiosConfig)
      .then(response => response.data)
      .catch(e => {
        handleError(e, manualErrorHandling);
        throw e;
      });
  };

  head = <T = any>(url: string, configuration: AxiosRequestConfig) => axios.head<T>(url, configuration);

  post = async <T = any, U = T>(
    url: string,
    data: U,
    configuration?: AxiosRequestConfig,
    manualErrorHandling = false
  ) => {
    const axiosConfig: AxiosRequestConfig = this.buildAxiosConfig('post', url, configuration);

    return axios
      .post<T>(url, data, axiosConfig)
      .then(response => response.data)
      .catch(e => {
        handleError(e, manualErrorHandling);
        throw e;
      });
  };

  put = <T = any>(url: string, data: T, configuration?: AxiosRequestConfig, manualErrorHandling = false) => {
    const axiosConfig = this.buildAxiosConfig('put', url, configuration);
    return axios
      .put<T>(url, data, axiosConfig)
      .then(res => res.data)
      .catch(e => {
        handleError(e, manualErrorHandling);
        throw e;
      });
  };

  patch = async <T = any, U = T>(
    url: string,
    data: U,
    configuration?: AxiosRequestConfig,
    manualErrorHandling = false
  ) => {
    const axiosConfig = this.buildAxiosConfig('patch', url, {
      ...configuration,
      headers: { ...configuration?.headers }
    });
    return axios
      .patch<T>(url, data, axiosConfig)
      .then(response => response.data)
      .catch(e => {
        handleError(e, manualErrorHandling);
        throw e;
      });
  };

  buildAxiosConfig = (method: Method, url: string, axiosConfig?: AxiosRequestConfig): AxiosRequestConfig => ({
    ...AxiosProxy.AXIOS_CONFIG,
    ...axiosConfig,
    method,
    url: axiosConfig && axiosConfig.baseURL ? axiosConfig.baseURL + url : AxiosProxy.AXIOS_CONFIG.baseURL + url
  });

  CancelToken = axios.CancelToken;
}

const huddleAxiosInstanceProxy = new AxiosProxy();
export default { huddleAxiosInstanceProxy };
