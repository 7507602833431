import type { ActivityDTO } from '@gofan/api/activities';

import type {
  ActivedSport,
  BuildSingleSportConfig,
  BuildRawSeasonProcess,
  PublishProcess,
  PublishSingleSeasonConfig,
  DataToSetupMultiSeason,
  DataPartnerEventsStatus,
  BuildAllPartnerSeasonsConfig,
  BuildMultiSportConfig
} from '@season-management/middleware/types';
import type { CreatingSeason } from '@season-management/middleware/models/creating-season.model';

export const ON_SET_DATA_TO_SETUP_MULTI_SEASON = '@season-management/ON_SET_DATA_TO_SETUP_MULTI_SEASON';
export const ON_RESET_SEASON_MANAGEMENT_STATE = '@season-management/ON_RESET_SEASON_MANAGEMENT_STATE';
export const ON_RESET_STATE_WHEN_UN_MOUNT = '@season-management/ON_RESET_STATE_WHEN_UN_MOUNT';

export const ON_SELECT_SPORT = '@season-management/ON_SELECT_SPORT';
export const ON_UPDATE_SEASON = '@season-management/ON_UPDATE_SEASON';
export const ON_SET_ACTIVED_SPORT = '@season-management/ON_SET_ACTIVED_SPORT';
export const ON_UPDATE_CREATING_SEASON_LIST = '@season-management/ON_UPDATE_CREATING_SEASON_LIST';

export const ON_UPDATE_BUILD_PROCESS = '@season-management/ON_UPDATE_BUILD_PROCESS';
export const ON_BUILD_SINGLE_SPORT = '@season-management/ON_BUILD_SINGLE_SPORT';
export const ON_CANCELLED_TASK_BUILD_SINGLE_SPORT = '@season-management/ON_CANCELLED_TASK_BUILD_SINGLE_SPORT';
export const ON_BUILD_MULTI_SPORT = '@season-management/ON_BUILD_MULTI_SPORT';
export const ON_CANCELLED_TASK_BUILD_MULTI_SPORT = '@season-management/ON_CANCELLED_TASK_BUILD_MULTI_SPORT';
export const ON_CANCELLED_TASK_BUILD_PARTNER_SEASONS = '@season-management/ON_CANCELLED_TASK_BUILD_PARTNER_SEASONS';
export const ON_BUILD_ALL_PARTNER_SEASONS = '@season-management/ON_BUILD_ALL_PARTNER_SEASONS';
export const ON_CANCELLED_TASK_BUILD_ALL_PARTNER_SEASONS =
  '@season-management/ON_CANCELLED_TASK_BUILD_ALL_PARTNER_SEASONS';

export const ON_UPDATE_PUBLISH_PROCESS = '@season-management/ON_UPDATE_PUBLISH_PROCESS';
export const ON_PUBLISH_SINGLE_SEASON = '@season-management/ON_PUBLISH_SINGLE_SEASON';
export const ON_UPDATE_PARTNER_EVENTS_STATUS = '@season-management/ON_UPDATE_PARTNER_EVENTS_STATUS';
export const ON_CANCELLED_TASK_PUBLISH_SINGLE_SEASON = '@season-management/ON_CANCELLED_TASK_PUBLISH_SINGLE_SEASON';

export const ON_UPDATE_TICKET_TYPES = '@season-management/ON_UPDATE_TICKET_TYPES';

export interface OnResetSeasonManagementStateAction {
  type: typeof ON_RESET_SEASON_MANAGEMENT_STATE;
}

export interface OnResetStateWhenUnMountAction {
  type: typeof ON_RESET_STATE_WHEN_UN_MOUNT;
}

export interface OnSetDataToSetupMultiSeason {
  type: typeof ON_SET_DATA_TO_SETUP_MULTI_SEASON;
  payload: {
    dataSetup: DataToSetupMultiSeason;
  };
}

export interface OnUpdateSeasonAction {
  type: typeof ON_UPDATE_SEASON;
  payload: {
    updatedSeason: any;
  };
}

export interface OnUpdateCreatingSeasonListAction {
  type: typeof ON_UPDATE_CREATING_SEASON_LIST;
  payload: {
    creatingSeasonList: CreatingSeason[];
  };
}

export interface OnSelectSportAction {
  type: typeof ON_SELECT_SPORT;
  payload: {
    selectedSports: ActivityDTO[];
  };
}

export interface OnSetActivedSportAction {
  type: typeof ON_SET_ACTIVED_SPORT;
  payload: {
    activedSport: ActivedSport;
  };
}

export interface OnUpdateBuildProcessAction {
  type: typeof ON_UPDATE_BUILD_PROCESS;
  payload: {
    buildingProcess: BuildRawSeasonProcess | null;
  };
}

export interface OnBuildSingleSportAction {
  type: typeof ON_BUILD_SINGLE_SPORT;
  payload: BuildSingleSportConfig;
}

export interface OnCancelledTaskBuildSingleSportAction {
  type: typeof ON_CANCELLED_TASK_BUILD_SINGLE_SPORT;
}

export interface OnBuildMultiSportAction {
  type: typeof ON_BUILD_MULTI_SPORT;
  payload: BuildMultiSportConfig;
}

export interface OnCancelledTaskBuildMultiSportAction {
  type: typeof ON_CANCELLED_TASK_BUILD_MULTI_SPORT;
}

export interface OnCancelledTaskBuildPartnerSeasonsAction {
  type: typeof ON_CANCELLED_TASK_BUILD_PARTNER_SEASONS;
}

export interface OnBuildAllPartnerSeasonsAction {
  type: typeof ON_BUILD_ALL_PARTNER_SEASONS;
  payload: BuildAllPartnerSeasonsConfig;
}

export interface OnCancelledTaskBuildAllPartnerSeasons {
  type: typeof ON_CANCELLED_TASK_BUILD_ALL_PARTNER_SEASONS;
}

export interface OnUpdatePublishProcessAction {
  type: typeof ON_UPDATE_PUBLISH_PROCESS;
  payload: {
    publishProcess: PublishProcess | null;
  };
}

export interface OnPublishSingleSeasonAction {
  type: typeof ON_PUBLISH_SINGLE_SEASON;
  payload: PublishSingleSeasonConfig;
}

export interface OnUpdatePartnerEventsStatusAction {
  type: typeof ON_UPDATE_PARTNER_EVENTS_STATUS;
  payload: {
    partnerEventsStatusData: DataPartnerEventsStatus;
  };
}

export interface OnCancelledTaskPublishSingleSeasonAction {
  type: typeof ON_CANCELLED_TASK_PUBLISH_SINGLE_SEASON;
}

export interface OnUpdateAccountTicketTypes {
  type: typeof ON_UPDATE_TICKET_TYPES;
  payload: {
    ticketTypes: any;
  };
}

export type SeasonManagementActionType =
  | OnResetSeasonManagementStateAction
  | OnResetStateWhenUnMountAction
  | OnUpdateSeasonAction
  | OnUpdateCreatingSeasonListAction
  | OnSelectSportAction
  | OnSetActivedSportAction
  | OnUpdateBuildProcessAction
  | OnBuildSingleSportAction
  | OnCancelledTaskBuildSingleSportAction
  | OnBuildMultiSportAction
  | OnCancelledTaskBuildMultiSportAction
  | OnBuildAllPartnerSeasonsAction
  | OnCancelledTaskBuildPartnerSeasonsAction
  | OnCancelledTaskBuildAllPartnerSeasons
  | OnUpdatePublishProcessAction
  | OnPublishSingleSeasonAction
  | OnUpdatePartnerEventsStatusAction
  | OnCancelledTaskPublishSingleSeasonAction
  | OnSetDataToSetupMultiSeason
  | OnUpdateAccountTicketTypes;
