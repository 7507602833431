export default {
  SEASON_SETUP_MODAL: {
    TITLE: 'Season Setup',
    SELECT_SPORTS: 'Select sports',
    ASSIGN_PAYMENT_SCHOOL: 'Assign payment school',
    CHOOSE_CREATION_METHOD: 'Choose creation method',
    STEPS: [
      `Select the teams you would like to add to the season`,
      'Next, specify which school these seasons belong to and the financial school who gets paid for the events.',
      'Lastly, choose the way you want to create your season(s). You can use the predefined template to easily bulk-upload multiple seasons at one time, or you can create your seasons manually.'
    ],
    SCHOOL_SEASON_FOR: 'Which school is the season for?',
    SCHOOL_GETS_PAID: 'Which school gets paid?',
    SPORTS: 'Sports',
    CREATION_METHODS_TITLE: 'Creation method',
    CREATION_METHODS: {
      MULTI_SPORT: 'I have a completed spreadsheet template with all required season information',
      SINGLE_SPORT: 'I’ll create the seasons individually, by manual input or by file upload'
    },
    DOWNLOAD_TEMPLATE: 'Download template',
    CANCEL: 'Cancel',
    PREVIOUS: 'Previous',
    CONTINUE: 'Continue',
    FIND_SCHOOL_PLACEHOLDER: 'Find a school',
    SCHOOLS_SELECTED: 'Schools selected',
    SPORTS_SELECTED: 'sports selected',
    ERROR_MESSAGES: {
      SCHOOL: {
        REQUIRED: 'Select the school is required'
      },
      SPORT: {
        REQUIRED: 'Sports is required'
      },
      SCHOOL_GET_PAID: {
        REQUIRED: 'Select the school gets paid is required'
      },
      CREATION_METHOD: {
        REQUIRED: 'Select the creation method is required'
      }
    }
  }
};
