import { Toggle as CarbonToggle } from '@carbon/react';
import classNames from 'classnames';

import type { ToggleProps as CarbonToggleProps } from '@carbon/react';

import './Toggle.scss';

export type ToggleProps = CarbonToggleProps;

export const Toggle = (props: ToggleProps) => (
  <CarbonToggle {...props} className={classNames('gf--toggle', props.className)} />
);
