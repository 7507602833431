/* eslint-disable import/no-unresolved */
import { put, takeLatest, call } from 'redux-saga/effects';
import { addMessage } from '../../root/actions';
import { generateFailContent } from '../../utils/alertUtils';

import * as types from './actionTypes';
import * as actionCreator from './actions';
import UserHistoryRequest from '../../api/model/request/UserHistoryRequest';
import History from '../../api/model/History';
import HistoryService from '../../api/services/HistoryService';

export function* fetchUserHistoryRequest(action) {
  try {
    const { email, dateRange } = action.payload.searchParams;
    const userHistoryRequest = new UserHistoryRequest({
      email,
      dateRange
    });
    const response = yield call(HistoryService.fetchUserHistory, userHistoryRequest);

    if (response instanceof Array) {
      const histories = response
        .map(history => new History(history.toJSON()).toJSON())
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

      yield put(actionCreator.fetchUserHistoryCompleted(histories));
    } else {
      yield put(actionCreator.fetchUserHistoryCompleted([]));
    }
  } catch (e) {
    yield put(actionCreator.fetchUserHistoryCompleted([]));
    const message = generateFailContent(e.message);
    yield put(addMessage(message));
  }
}

export function* watchFetchUserHistoryRequest() {
  yield takeLatest(types.FETCH_USER_HISTORY, fetchUserHistoryRequest);
}

export default [watchFetchUserHistoryRequest()];
