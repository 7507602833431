import axios from 'axios';
import { huddleAxiosInstanceProxy } from '../../shared/axios';
import {
  FETCH_PURCHASED_TICKET,
  UPDATE_PURCHASED_TICKET,
  UNREDEEM_EVENT,
  GET_TICKET_TYPES_BY_ID,
  SEND_REFUND_REQUEST,
  REDEEM_TICKET,
  PATCH_UNREDEEM_TICKET,
  FETCH_PURCHASED_SEASON_TICKET
} from '@gofan/constants/endpoints';
import { config } from '@gofan/constants/config';
import type { PurchasedTicketPageResponseDTO, PurchasedTicketDTO } from '../purchased-ticket.model';
import type { JobDTO } from '../job/job.model';
import type { RefundRequestBody } from './fan.model';

class FanRepository {
  static fetchPurchasedTicket = (queryStr: string, body: any): Promise<PurchasedTicketPageResponseDTO> =>
    huddleAxiosInstanceProxy.post<PurchasedTicketPageResponseDTO>(
      `${FETCH_PURCHASED_TICKET}?${queryStr}`,
      body,
      config.HUDDLE_MICROSERVICE_API_MODE ? huddleAxiosInstanceProxy.msConfig : undefined,
      true
    );

  static fetchPurchasedSeasonTicket = (queryStr: string, body: any): Promise<PurchasedTicketPageResponseDTO> =>
    huddleAxiosInstanceProxy.post<PurchasedTicketPageResponseDTO>(
      `${FETCH_PURCHASED_SEASON_TICKET}?${queryStr}`,
      body,
      config.HUDDLE_MICROSERVICE_API_MODE ? huddleAxiosInstanceProxy.msConfig : undefined,
      true
    );

  static updatePurchasedTicket = (request: any, accessToken: string): Promise<PurchasedTicketDTO> =>
    huddleAxiosInstanceProxy.put<PurchasedTicketDTO>(
      `${UPDATE_PURCHASED_TICKET.replace('{accessToken}', accessToken)}`,
      request,
      config.HUDDLE_MICROSERVICE_API_MODE ? huddleAxiosInstanceProxy.msConfig : undefined,
      true
    );

  static unredeemEvent = (eventId: number) =>
    huddleAxiosInstanceProxy.post<JobDTO, { eventId: number }>(UNREDEEM_EVENT, { eventId }, undefined, false);

  static getTicketTypesById = (eventId: string): Promise<string[]> =>
    huddleAxiosInstanceProxy.get<any>(
      GET_TICKET_TYPES_BY_ID.replace('{id}', `${eventId}`),
      config.HUDDLE_MICROSERVICE_API_MODE ? huddleAxiosInstanceProxy.msConfig : undefined,
      true
    );

  static sendRefundRequest = (body: RefundRequestBody): Promise<any> =>
    axios.post<any>(SEND_REFUND_REQUEST, { ...body });

  static useTickets = (accessToken: string, redeemedAt: string) =>
    huddleAxiosInstanceProxy.patch<PurchasedTicketDTO, { accessToken: string; redeemedAt: string }>(
      `${REDEEM_TICKET.replace('{accessToken}', `${accessToken}`)}`,
      {
        accessToken,
        redeemedAt
      },
      config.HUDDLE_MICROSERVICE_API_MODE ? huddleAxiosInstanceProxy.msConfig : undefined
    );

  static unUseTickets = (accessTokens: string[]) =>
    huddleAxiosInstanceProxy.patch<PurchasedTicketDTO[], { accessTokens: string[] }>(
      PATCH_UNREDEEM_TICKET,
      {
        accessTokens
      },
      config.HUDDLE_MICROSERVICE_API_MODE ? huddleAxiosInstanceProxy.msConfig : undefined
    );
}

export { FanRepository };
