import SponsorshipCreation from './views/SponsorshipCreation/SponsorshipCreation';
import { Switch, Route } from 'react-router-dom';
import { PAGES } from '@app/config/routes';
import type { RouteComponentProps } from 'react-router-dom';
import SponsorshipListing from './views/SponsorshipListing/SponsorshipListing';

const Sponsorships = (props: RouteComponentProps) => {
  const sponsorId = props.location.pathname.split('/').pop();
  return (
    <Switch>
      <Route
        exact
        path={PAGES.sponsorships.create.path}
        key={PAGES.sponsorships.create.title}
        render={routeProps => <SponsorshipCreation {...routeProps} />}
      />

      <Route
        exact
        path={PAGES.sponsorships.edit.path}
        key={PAGES.sponsorships.edit.title}
        render={routeProps => <SponsorshipCreation sponsorId={sponsorId} {...routeProps} />}
      />

      <Route
        exact
        path={PAGES.sponsorships.list.path}
        key={PAGES.sponsorships.list.title}
        render={routeProps => <SponsorshipListing {...routeProps} />}
      />

      <Route
        exact
        path={PAGES.sponsorships.listAllSponsors.path}
        key={PAGES.sponsorships.list.title}
        render={routeProps => <SponsorshipListing {...routeProps} />}
      />
    </Switch>
  );
};

export { Sponsorships };
