import { css } from 'styled-components';

export default {
  content: css`
    height: 80px;
    padding: 10px 2px;
  `,
  header: {
    marginTop: '3px'
  }
};
