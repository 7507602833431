import { createContext, useMemo } from 'react';

import type { ProgressSection } from '@events/components/editor/EditorProgress';
import type { SettingSection } from '@events/components/editor/EditorSettings';

export interface EventEditorContextType {
  progressSections: ProgressSection[];
  setProgressSections: React.Dispatch<React.SetStateAction<ProgressSection[]>>;
  selectedProgressSection: string;
  setSelectedProgressSection: React.Dispatch<React.SetStateAction<string>>;
  onFocusSection: (section: string) => void;
  onGenerateSettings: (settings: SettingSection[]) => void;
}

export const EventEditorContext = createContext<EventEditorContextType>({} as EventEditorContextType);

export const EventEditorProvider = ({
  progressSections,
  setProgressSections,
  selectedProgressSection,
  setSelectedProgressSection,
  onFocusSection,
  onGenerateSettings,
  children
}: React.PropsWithChildren<EventEditorContextType>) => {
  const value = useMemo(
    () => ({
      progressSections,
      setProgressSections,
      selectedProgressSection,
      setSelectedProgressSection,
      onFocusSection,
      onGenerateSettings
    }),
    [
      progressSections,
      setProgressSections,
      selectedProgressSection,
      setSelectedProgressSection,
      onFocusSection,
      onGenerateSettings
    ]
  );

  return <EventEditorContext.Provider value={value}>{children}</EventEditorContext.Provider>;
};
