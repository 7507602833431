import { RootState } from './types';
import {
  RootActionTypes,
  REMOVE_ACCOUNT_INFO,
  SHOW_ACCOUNT_INFO,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  ADD_ARBITER_FLOW_PATH,
  REMOVE_ARBITER_FLOW_PATH,
  UPDATE_SELECTED_SCHOOLS
} from './actionTypes';

export const initialState: RootState = {
  notifications: [],
  account: undefined,
  selectedSchools: [],
  arbiterFlowPath: ''
};

export default (state = initialState, action: RootActionTypes): RootState => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const { notification } = action.payload;
      const exist = state.notifications.find(msg => msg.id === notification.id);
      return {
        ...state,
        notifications: exist ? state.notifications : [...state.notifications, notification]
      };
    }
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.id !== action.payload.id)
      };
    case SHOW_ACCOUNT_INFO: {
      const { account } = action.payload;
      return {
        ...state,
        account
      };
    }
    case REMOVE_ACCOUNT_INFO: {
      return {
        ...state,
        account: {}
      };
    }
    case UPDATE_SELECTED_SCHOOLS: {
      const { accounts } = action.payload;
      return {
        ...state,
        selectedSchools: accounts
      };
    }
    case ADD_ARBITER_FLOW_PATH: {
      const { arbiterFlowPath } = action.payload;
      return {
        ...state,
        arbiterFlowPath
      };
    }
    case REMOVE_ARBITER_FLOW_PATH: {
      return {
        ...state,
        arbiterFlowPath: ''
      };
    }
    default:
      return state;
  }
};
