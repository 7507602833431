import { useCallback, useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { gendersToString } from '@app/api/services/EventService';
import { formatCurrency } from '@utils/numberUtils';
import { generateError, generateSuccessMessage } from '@utils/alertUtils';
import type { LevelDTO } from '@gofan/api/levels';
import type { ActivityDTO } from '@gofan/api/activities';
import type { EventDTO, EventSalesTotal } from '@events/models/event.model';
import EventService from '@events/services/events.service';

const EMPTY_VALUE = '---';

type EventInfoDTO = {
  activityLabel: string;
  genderLabel: string;
  prefix: string;
  shortName: string;
  schoolIcon: string | null;
  dateTime: string;
};

export type EventInsightDTO = {
  [key: string]: string;
};

interface UseEventCancellationsProps {
  event: EventDTO;
  accountIds: string[];
  open: boolean;
  addNotification: Function;
  onCloseModal: () => void;
  onReloadEvent: () => void;
}

interface EventCancellationsDataDTO {
  eventInfo: EventInfoDTO;
  ticketSaleInsight: EventInsightDTO;
  errorInsight: any;
  sendFansNotification: boolean;
  loading: boolean;
  canceling: boolean;
  setSendFansNotification: Function;
  isConfirmed: boolean;
  setConfirm: Function;
  onClickCancelEvent: () => void;
}

const useEventCancellations = ({
  open,
  event,
  accountIds,
  addNotification,
  onCloseModal,
  onReloadEvent
}: UseEventCancellationsProps): EventCancellationsDataDTO => {
  const isUnmounted = useRef<boolean>(false);
  const [eventInfo, setEventInfo] = useState<EventInfoDTO>({} as EventInfoDTO);
  const [ticketSaleInsight, setTicketSaleInsight] = useState<EventInsightDTO>({
    ticketSold: EMPTY_VALUE,
    ticketSales: EMPTY_VALUE,
    ticketUsed: EMPTY_VALUE,
    percentTicketUsed: EMPTY_VALUE
  });
  const [sendFansNotification, setSendFansNotification] = useState<boolean>(false);
  const [isConfirmed, setConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [canceling, setCanceling] = useState<boolean>(false);
  const [errorInsight, setErrorInsight] = useState<any>();

  const getTicketSaleInsightData = async () => {
    if (isEmpty(event)) return;
    const { id } = event || {};
    try {
      setLoading(true);
      setErrorInsight(null);
      const response = await EventService.getTicketSaleInsightData(id);
      if (isUnmounted.current) return;
      if (!isEmpty(response)) {
        const totals = get(Object.values(response)[0], 'totals', {}) as EventSalesTotal;
        const ticketSold = totals?.ticket_net_count ?? 0;
        const ticketSales = totals?.net_to_account ?? 0;
        const ticketUsed = totals?.ticket_redemption_count ?? 0;
        const percentTicketUsed = ticketSold && ticketUsed ? (ticketUsed / ticketSold) * 100 : 0;
        setTicketSaleInsight({
          ticketSold: `${ticketSold}`,
          ticketSales: formatCurrency(ticketSales, 0),
          ticketUsed: `${ticketUsed}`,
          percentTicketUsed: `${percentTicketUsed.toFixed(0)}%`
        });
      }
    } catch (error) {
      if (isUnmounted.current) return;
      setErrorInsight(error);
      addNotification(generateError(error));
    } finally {
      if (isUnmounted.current) return;
      setLoading(false);
    }
  };

  const onClickCancelEvent = useCallback(() => {
    if (canceling) return;
    setCanceling(true);

    // add ticketSaleInsight to the cancelEvent
    EventService.cancelEvent({
      eventId: `${event.id}`,
      sendFansNotification,
      percentageTicketsUsed: ticketSaleInsight?.percentTicketUsed,
      ticketSales: ticketSaleInsight?.ticketSales,
      ticketSold: ticketSaleInsight?.ticketSold,
      ticketUsed: ticketSaleInsight?.ticketUsed
    })
      .then(event => {
        addNotification(generateSuccessMessage(`${event.name} is successfully canceled.`));
        onReloadEvent();
        onCloseModal();
      })
      .catch(error => {
        if (isUnmounted.current) return;
        addNotification(generateError(error));
      })
      .finally(() => {
        if (isUnmounted.current) return;
        setCanceling(false);
      });
  }, [event, sendFansNotification, onReloadEvent, ticketSaleInsight]);

  useEffect(() => {
    if (open) {
      const levels: LevelDTO[] = event.levels ?? get(event, '_embedded.levels', []);
      const activity: ActivityDTO = event.activity ?? get(event, '_embedded.activity', {});
      const { prefix, shortName, schoolIcon } = EventService.getEventAwaySchoolInfo({ event, accountIds });
      const dateTime = EventService.getEventDate(event);

      setEventInfo({
        activityLabel: activity?.label || '',
        genderLabel: gendersToString(levels, event?.genders || [], activity),
        prefix,
        shortName,
        schoolIcon,
        dateTime
      });
      getTicketSaleInsightData();
      setConfirm(false);
      setSendFansNotification(false);
    }
  }, [open, event, accountIds]);

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  return {
    eventInfo,
    ticketSaleInsight,
    errorInsight,
    sendFansNotification,
    loading,
    canceling,
    setSendFansNotification,
    isConfirmed,
    setConfirm,
    onClickCancelEvent
  };
};

export type { EventCancellationsDataDTO };
export { useEventCancellations };
