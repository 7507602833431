import { call, takeLatest, put, all, select, take } from 'redux-saga/effects';
import {
  getSeasonById,
  getSeasonSeatsByStatus,
  getTicketsBySeasonId,
  updateSeasonSeatStatus,
  getSeasonSeatStatusByLabel,
  getSeasonUnavailableSeats
} from '@app/api/services/SeasonService';
import { channel } from 'redux-saga';
import { SeasonRenewalService } from '@gofan/api/season-renewal';
import { SEASON_RENEWAL_CAMPAIGN } from '@pages/EventReservedSeating/constants';
import {
  getEvent,
  getTicketsByEventId,
  getEventSeatsByStatus,
  unbookSeatById,
  setSeatToBlocked,
  setSeatToUnblocked,
  updateSeatStatus,
  moveBookedSeat,
  getEventSeatStatusByLabel,
  bookCompSeats,
  updateTicketNote,
  getEventUnavailableSeats
} from '../../api/services/EventService';
import { getVenueSeatsById, getVenueById } from '../../api/services/VenueService';
import { fetchAccountById } from '../../api/services/AccountService';
import { getSeasonRenewalsBySeasonId } from '../../api/services/SeasonRenewalService';
import {
  prepareDataCompleted,
  updateRenewalOfferCompleted,
  updateSeatsInfoCompleted,
  viewSeatsInOrder,
  getSeasonRenewals as refreshSeasonRenewals,
  getSeasonRenewalsCompleted,
  deleteRenewalOfferCompleted,
  updateSeasonRenewalCompleted,
  updateAccountsCompleted
} from './actions';
import { showAccountInfo } from '../Root/actions';
import { UNEXPECTED_ERROR } from '../../api/api/constants';
import {
  PREPARE_DATA,
  BLOCK_RESERVED_SEATING,
  UNBLOCK_RESERVED_SEATING,
  MOVE_BOOKED_RESERVED_SEAT,
  UNBOOKED_RESERVED_SEAT,
  BOOK_RESERVED_SEATS,
  UPDATE_TICKET_NOTES,
  UPDATE_SEASON_RENEWAL,
  UPDATE_RENEWAL_OFFER,
  GET_SEASON_RENEWALS,
  DELETE_RENEWAL_OFFER
} from './actionTypes';
import ReservedEvent from '../../api/model/ReservedEvent';
import EventReservedSeating from '../../api/model/EventReservedSeating';
import ErrorDTO from '../../api/dto/ErrorDTO';
import { isEmpty } from '../../utils/objectUtils';
import OrderTicket from '../../api/model/OrderTicket';
import CreateOrderRequest from '../../api/model/CreateOrderRequest';
import CreateSeasonOrderRequest from '../../api/model/CreateSeasonOrderRequest';
import { getWorkspace } from '../../api';
import { addNotification } from '@app/pages/Root/actions';
import { ReservedSeatingService } from '@gofan/api/reserved-seating';
import { generateFailContent, generateSuccessMessage, generateSuccessMessageWithAction } from '../../utils/alertUtils';
import isNil from 'lodash/isNil';
import { FanService } from '@gofan/api/fan-management';

const notificationChannel = channel();

export function* prepareData(action) {
  try {
    const { id, isSeason } = action.payload;
    let dataResponse;
    if (isSeason) {
      dataResponse = yield call(getSeasonById, id, { expand: ['levels', 'activity'] });
    } else {
      dataResponse = yield call(getEvent, id, {
        expand: ['event-type', 'event-sales-info']
      });
    }

    if (dataResponse instanceof ErrorDTO) {
      return dataResponse;
    }

    const accountResponse = yield call(fetchAccountById, dataResponse.accountId);
    if (accountResponse instanceof ErrorDTO) {
      return accountResponse;
    }
    const financialAccountResponse = yield call(fetchAccountById, dataResponse.financialAccountId);
    if (financialAccountResponse instanceof ErrorDTO) {
      return financialAccountResponse;
    }
    yield put(
      updateAccountsCompleted({
        account: accountResponse.toJSON(),
        financialAccount: financialAccountResponse.toJSON()
      })
    );

    yield put(showAccountInfo(accountResponse));

    const venueResponse = yield call(getVenueById, dataResponse.venueId);
    if (venueResponse instanceof ErrorDTO) {
      return venueResponse;
    }

    const workspaceResponse = yield call(getWorkspace, dataResponse.accountId);
    if (workspaceResponse instanceof ErrorDTO) {
      return workspaceResponse;
    }

    return new ReservedEvent({
      ...accountResponse.toJSON(),
      ...dataResponse.toJSON(),
      ...workspaceResponse
    });
  } catch (error) {
    return ErrorDTO.convertToError(error);
  }
}

export function* prepareSeatsInfo(dataResponse, isSeason) {
  try {
    const { venueId, id } = dataResponse;
    let venueSeats;
    let allSeatStatuses;
    let unavailableSeats;
    let ticketsResponse;
    if (isSeason) {
      venueSeats = yield call(getVenueSeatsById, { venueId, seasonId: id });
      allSeatStatuses = yield call(getSeasonSeatsByStatus, {
        seasonId: id,
        seatStatus: ['BLOCKED', 'BOOKED', 'HOLD']
      });
      unavailableSeats = yield call(getSeasonUnavailableSeats, id);
      ticketsResponse = yield call(getTicketsBySeasonId, { seasonId: id });
    } else {
      venueSeats = yield call(getVenueSeatsById, { venueId, eventId: id });
      allSeatStatuses = yield call(getEventSeatsByStatus, {
        eventId: id,
        seatStatus: ['BLOCKED', 'BOOKED', 'HOLD', 'UNAVAILABLE']
      });
      unavailableSeats = yield call(getEventUnavailableSeats, id);
      ticketsResponse = yield call(getTicketsByEventId, { eventId: id });
    }

    if (venueSeats instanceof ErrorDTO) return venueSeats;
    if (allSeatStatuses instanceof ErrorDTO) return allSeatStatuses;
    if (unavailableSeats instanceof ErrorDTO) return unavailableSeats;
    if (ticketsResponse instanceof ErrorDTO) return ticketsResponse;

    const tickets = [];
    ticketsResponse.reservedTickets.map(ticket => tickets.push(ticket));

    if (ticketsResponse.totalPages > 1) {
      for (let i = ticketsResponse.number + 1; i < ticketsResponse.totalPages; i++) {
        let paginatedTickets;
        if (isSeason) {
          paginatedTickets = yield call(getTicketsBySeasonId, { seasonId: id, updatedAt: null, page: i });
        } else {
          paginatedTickets = yield call(getTicketsByEventId, { eventId: id, updatedAt: null, page: i });
        }
        paginatedTickets.reservedTickets.map(ticket => tickets.push(ticket));
      }
    }

    const seatsInfo = venueSeats;
    const bookedIds = [];
    const blockedIds = [];
    const unavailableIds = [];
    tickets.filter(s => s.seatsIoLabel != null).forEach(seat => (seatsInfo[seat.seatsIoLabel] = seat));
    unavailableSeats.map(seat => (allSeatStatuses[seat.seatsIoLabel] = seat)); // Add unavailable seats to seat statuses

    Object.values(allSeatStatuses).forEach(seat => {
      if (seatsInfo[seat.seatsIoLabel] == undefined) {
        console.warn(`Status for seat ${seat.seatsIoLabel} 
          was received with a status of ${seat.reservationState}, 
          but does not exist in seats.io for venue ${venueId}`);
      } else {
        seatsInfo[seat.seatsIoLabel].seatStatus = seat.reservationState;

        if (seat.reservationState == 'BOOKED' || seat.reservationState == 'HOLD') {
          bookedIds.push(seat.seatsIoLabel);
        } else if (seat.reservationState == 'BLOCKED') {
          blockedIds.push(seat.seatsIoLabel);
        } else if (seat.reservationState == 'UNAVAILABLE') {
          unavailableIds.push(seat.seatsIoLabel);
        } else if (seat.reservationState == 'NONE') {
          seatsInfo[seat.seatsIoLabel].seatStatus = 'AVAILABLE';
        }
      }

      if (Array.isArray(seat.forms) && seat.forms.length > 0) {
        seatsInfo[seat.seatsIoLabel].raw.forms = seat.forms;
        const fields = (seat?.forms ?? []).flatMap(form => form.fields ?? []);
        seatsInfo[seat.seatsIoLabel].raw.firstName = FanService.getFormFieldValue(fields)('first-name');
        seatsInfo[seat.seatsIoLabel].raw.lastName = FanService.getFormFieldValue(fields)('last-name');
      }
    });

    const seats = Object.values(seatsInfo);
    const updateDate = new Date();
    updateDate.setHours(updateDate.getHours() - 2);

    return new EventReservedSeating({
      seats,
      updatedAt: updateDate.getTime(),
      seatsInfo,
      blockedIds,
      bookedIds,
      unavailableIds
    });
  } catch (error) {
    return ErrorDTO.convertToError(error);
  }
}

export function* prepareDataAction(action) {
  try {
    const { id, isSeason } = action.payload;
    if (isEmpty(id)) {
      if (isSeason) {
        yield put(
          prepareDataCompleted({
            error: SEASON_DETAIL.NOT_FOUND.replace('{seasonId}', id)
          })
        );
      } else {
        yield put(
          prepareDataCompleted({
            error: EVENT_DETAIL.NOT_FOUND.replace('{eventId}', id)
          })
        );
      }
      return;
    }

    const dataResponse = yield call(prepareData, action);

    if (dataResponse instanceof ErrorDTO) {
      const errorMessage =
        !isEmpty(dataResponse.data) && !isEmpty(dataResponse.data.message)
          ? dataResponse.data.message
          : UNEXPECTED_ERROR;
      yield put(prepareDataCompleted({ error: errorMessage }));
      return;
    }

    const seatsInfoResponse = yield call(prepareSeatsInfo, dataResponse, isSeason);

    const seasonTickets = [];

    if (isSeason) {
      const seasonTicketsResponse = yield call(getTicketsBySeasonId, { seasonId: id });
      if (seasonTicketsResponse instanceof ErrorDTO) {
        yield put(prepareDataCompleted(seasonTicketsResponse));
      }

      seasonTicketsResponse.reservedTickets.map(ticket => seasonTickets.push(ticket));

      if (seasonTicketsResponse.totalPages > 1) {
        for (let i = seasonTicketsResponse.number + 1; i < seasonTicketsResponse.totalPages; i++) {
          const paginatedTickets = yield call(getTicketsBySeasonId, { seasonId: id, updatedAt: null, page: i });

          if (paginatedTickets instanceof ErrorDTO) {
            yield put(prepareDataCompleted(paginatedTickets));
          }

          paginatedTickets.reservedTickets.map(ticket => seasonTickets.push(ticket));
        }
      }

      const seasonRenewalsResponse = yield call(getSeasonRenewalsBySeasonId, id);
      if (seasonRenewalsResponse instanceof ErrorDTO) {
        yield put(prepareDataCompleted(seasonRenewalsResponse));
      }

      const seasonRenewalSeats = yield call(SeasonRenewalService.getSeasonRenewalSeats, id);

      yield put(
        prepareDataCompleted({
          season: dataResponse.toJSON(),
          seatsInfo: seatsInfoResponse.toJSON(),
          seasonTickets: isEmpty(seasonTickets) ? null : seasonTickets,
          seasonRenewals: isEmpty(seasonRenewalsResponse) ? [] : seasonRenewalsResponse.toJSON(),
          seasonRenewalSeats
        })
      );
    } else {
      yield put(prepareDataCompleted({ event: dataResponse.toJSON(), seatsInfo: seatsInfoResponse.toJSON() }));
    }
  } catch (error) {
    const errorMessage = !isEmpty(error.data) && !isEmpty(error.data.message) ? error.data.message : 'Unknown Error';

    yield put(prepareDataCompleted({ error: errorMessage }));
  }
}

export function* updateSeatsInfo() {
  try {
    const { event, season, seatsInfo } = yield select(state => state.eventReservedSeating);
    const updatedSeatsInfo = { ...seatsInfo };
    let updatedBlockedSeatStatuses;
    let updatedSeatStatuses;
    let updatedTickets;

    if (isEmpty(event)) {
      // If no event data, use season data
      updatedBlockedSeatStatuses = yield call(getSeasonSeatsByStatus, {
        seasonId: season.id,
        seatStatus: ['BLOCKED']
      });
      updatedSeatStatuses = yield call(getSeasonSeatsByStatus, {
        seasonId: season.id,
        updatedAt: seatsInfo.updatedAt
      });
      updatedTickets = yield call(getTicketsBySeasonId, { seasonId: season.id });
    } else {
      // Else use event data
      updatedBlockedSeatStatuses = yield call(getEventSeatsByStatus, {
        eventId: event.id,
        seatStatus: ['BLOCKED']
      });
      updatedSeatStatuses = yield call(getEventSeatsByStatus, {
        eventId: event.id,
        updatedAt: seatsInfo.updatedAt
      });
      updatedTickets = yield call(getTicketsByEventId, { eventId: event.id, updatedAt: seatsInfo.updatedAt });
    }

    if (updatedBlockedSeatStatuses instanceof ErrorDTO) return updatedBlockedSeatStatuses;
    if (updatedSeatStatuses instanceof ErrorDTO) return updatedSeatStatuses;
    if (updatedTickets instanceof ErrorDTO) return updatedTickets;

    const eventTickets = {};

    updatedTickets.reservedTickets.map(ticket => {
      eventTickets[ticket.seatsIoLabel] = ticket;
      if (!isNil(updatedSeatsInfo.seatsInfo[ticket.seatsIoLabel]))
        updatedSeatsInfo.seatsInfo[ticket.seatsIoLabel].notes = ticket.notes;
    });

    if (updatedTickets.totalPages > 1) {
      for (let i = updatedTickets.number + 1; i < updatedTickets.totalPages; i++) {
        if (isEmpty(event)) {
          const paginatedTickets = yield call(getTicketsBySeasonId, {
            seasonId: season.id,
            updatedAt: seatsInfo.updatedAt,
            page: i
          });
          paginatedTickets.reservedTickets.forEach(ticket => {
            eventTickets[ticket.seatsIoLabel] = ticket;
          });
        } else {
          const paginatedTickets = yield call(getTicketsByEventId, {
            eventId: id,
            updatedAt: seatsInfo.updatedAt,
            page: i
          });
          paginatedTickets.reservedTickets.forEach(ticket => {
            eventTickets[ticket.seatsIoLabel] = ticket;
          });
        }
      }
    }

    updatedSeatsInfo.blockedIds = Object.keys(updatedBlockedSeatStatuses);
    updatedSeatsInfo.bookedIds = [...seatsInfo.bookedIds];
    seatsInfo.blockedIds.forEach(seatsIoLabel => {
      if (!updatedSeatsInfo.blockedIds.includes(seatsIoLabel)) {
        updatedSeatsInfo.seatsInfo[seatsIoLabel].seatStatus = 'AVAILABLE';
      }
    });

    Object.values(updatedSeatStatuses).forEach(seat => {
      updatedSeatsInfo.seatsInfo[seat.seatsIoLabel].seatStatus =
        seat.reservationState === 'NONE' ? 'AVAILABLE' : seat.reservationState;

      if (seat.reservationState == 'BOOKED') {
        updatedSeatsInfo.bookedIds.push(seat.seatsIoLabel);
        if (
          eventTickets[seat.seatsIoLabel] &&
          eventTickets[seat.seatsIoLabel] != null &&
          eventTickets[seat.seatsIoLabel] !== undefined
        ) {
          const eventTicket = Object.values(eventTickets[seat.seatsIoLabel]);
          updatedSeatsInfo.seatsInfo[seat.seatsIoLabel].updateSeat(eventTicket[0]);
        }
      } else if (updatedSeatsInfo.bookedIds.includes(seat.seatsIoLabel)) {
        const index = updatedSeatsInfo.bookedIds.indexOf(seat.seatsIoLabel);
        updatedSeatsInfo.bookedIds.slice(index, 1);
        if (updatedSeatsInfo.seatsInfo[seat.seatsIoLabel].seatStatus == 'AVAILABLE') {
          updatedSeatsInfo.seatsInfo[seat.seatsIoLabel].clearSeat();
        }
      }

      updatedSeatsInfo.seatsInfo[seat.seatsIoLabel].seatReservationId = seat.id;
    });

    updatedSeatsInfo.updatedAt = new Date().getTime() + 1;
    updatedSeatsInfo.seats = Object.values(updatedSeatsInfo.seatsInfo);
    yield put(
      updateSeatsInfoCompleted({
        updatedSeatsInfo: new EventReservedSeating(updatedSeatsInfo),
        seasonTickets: updatedTickets?.reservedTickets
      })
    );
  } catch (error) {
    const errorMessage = !isEmpty(error.data) && !isEmpty(error.data.message) ? error.data.message : 'Unknown Error';
    yield put(prepareDataCompleted({ error: errorMessage }));
  }
}

export function* blockSeat(action) {
  const { ticketsInfo, eventId, seasonId } = action.payload;
  let hadError = false;

  for (let index = 0; index < ticketsInfo.length; index++) {
    const { seatsIoLabel } = ticketsInfo[index];
    let seatStatus;
    if (eventId != undefined) {
      seatStatus = yield call(getEventSeatStatusByLabel, { eventId, seatsIoLabel });
    } else {
      seatStatus = yield call(getSeasonSeatStatusByLabel, { seasonId, seatsIoLabel });
    }
    if (seatStatus.length == 0 || seatStatus[0].reservationState == 'NONE') {
      const blockResponse = yield call(setSeatToBlocked, { eventId, seasonId, seatsIoLabel });
      if (!hadError) hadError = blockResponse instanceof ErrorDTO;
    } else {
      console.error(`SEAT STATUS IS: ${seatStatus[0].reservationState}`);
    }
  }

  if (hadError) {
    yield put(addNotification(generateFailContent('Failed to block seats')));
  } else {
    const moreThanOneSeat = ticketsInfo.length > 1;
    yield put(
      addNotification(
        generateSuccessMessageWithAction(
          '',
          `${ticketsInfo.length} ${moreThanOneSeat ? 'seats were' : 'seat was'} blocked`,
          `View seat${moreThanOneSeat ? 's' : ''} in List View`,
          () => notificationChannel.put(viewSeatsInOrder('Blocked', [...ticketsInfo]))
        )
      )
    );
  }

  yield call(updateSeatsInfo);
}

export function* unblockSeat(action) {
  const { ticketsInfo, eventId, seasonId } = action.payload;
  let hadError = false;

  for (let index = 0; index < ticketsInfo.length; index++) {
    const { seatsIoLabel } = ticketsInfo[index];
    let blockedSeats;
    if (eventId != null) {
      blockedSeats = yield call(getEventSeatsByStatus, { eventId, seatStatus: ['BLOCKED'] });
    } else {
      blockedSeats = yield call(getSeasonSeatsByStatus, { seasonId, seatStatus: ['BLOCKED'] });
    }

    if (blockedSeats[seatsIoLabel] != null) {
      const unblockResponse = yield call(setSeatToUnblocked, { eventId, seasonId, seatsIoLabel });

      if (!hadError) hadError = unblockResponse instanceof ErrorDTO;
    } else {
      console.error(`SEAT STATUS IS NOT BLOCKED`);
    }
  }

  if (hadError) {
    yield put(addNotification(generateFailContent('Failed to unblock seats')));
  } else {
    const moreThanOneSeat = ticketsInfo.length > 1;
    yield put(
      addNotification(
        generateSuccessMessageWithAction(
          '',
          `${ticketsInfo.length} ${moreThanOneSeat ? 'seats were' : 'seat was'} unblocked`,
          `View seat${moreThanOneSeat ? 's' : ''} in List View`,
          () => notificationChannel.put(viewSeatsInOrder('Unblocked', [...ticketsInfo]))
        )
      )
    );
  }
  yield call(updateSeatsInfo);
}

export function* bookSeats(action) {
  const { id, ticketsInfo, userInfo, ticketCategory, isSeason } = action.payload;
  const ticketSeat = [];
  let seats = [...ticketsInfo.booked1];
  let hadError = false;

  for (let index = 0; index < ticketsInfo.booked1.length; index++) {
    const product = ticketCategory.find(
      product => product.category.toLowerCase() === ticketsInfo.booked1[index].category.toLocaleLowerCase()
    );
    const seat = {
      seatsIoLabel: ticketsInfo.booked1[index].seatsIoLabel,
      productId: product.id,
      userInfo: {
        email: userInfo.email,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        phoneNumber: userInfo.contactNumber
      }
    };

    ticketSeat.push(seat);
  }

  const order = yield call(CreateOrder, { id, ticketSeat, isSeason });
  let bookSeatsResponse = yield call(bookCompSeats, order);
  hadError = bookSeatsResponse instanceof ErrorDTO;

  if (ticketsInfo.booked2 && ticketsInfo.booked2.length > 0 && !hadError) {
    seats = [...seats, ...ticketsInfo.booked2];
    bookSeatsResponse = yield call(bookSeatsSecondary, { id, ticketsInfo, userInfo, ticketCategory, isSeason });
    hadError = bookSeatsResponse instanceof ErrorDTO;
  } else {
    yield call(updateSeatsInfo);
  }

  if (hadError) {
    yield put(addNotification(generateFailContent('Failed to book seats')));
  } else {
    const moreThanOneSeat = seats.length > 1;
    yield put(
      addNotification(
        generateSuccessMessageWithAction(
          '',
          `${seats.length} ${moreThanOneSeat ? 'seats were' : 'seat was'} booked`,
          `View seat${moreThanOneSeat ? 's' : ''} in List View`,
          () => notificationChannel.put(viewSeatsInOrder('Booked', seats))
        )
      )
    );
  }
}

function* bookSeatsSecondary(action) {
  const { id, ticketsInfo, userInfo, ticketCategory, isSeason } = action;
  const ticketSeat = [];
  for (let index = 0; index < ticketsInfo.booked2.length; index++) {
    const seat = {
      seatsIoLabel: ticketsInfo.booked2[index].seatsIoLabel,
      productId: ticketCategory[1].id,
      userInfo: {
        email: userInfo.email,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        phoneNumber: userInfo.contactNumber
      }
    };

    ticketSeat.push(seat);
  }
  const order = yield call(CreateOrder, { id, ticketSeat, isSeason });
  yield call(bookCompSeats, order);
  yield call(updateSeatsInfo);
}

export function* unbookSeats(action) {
  const { ticketsInfo, seasonTickets } = action.payload;
  let hadError = false;

  if (!isNil(seasonTickets)) {
    const seasonId = seasonTickets.length > 0 ? seasonTickets[0].seasonId : null;
    const ticketGroups = {};

    const ticketsToMove = seasonTickets.filter(ticket => {
      const foundSeatIndex = ticketsInfo.findIndex(seat => seat.seatsIoLabel === ticket.seatsIoLabel);
      if (foundSeatIndex >= 0 && ticket.seatsIoLabel in ticketGroups === false)
        ticketGroups[ticket.seatsIoLabel] = {
          ticketIds: new Set(),
          seatsIoLabel: ticketsInfo[foundSeatIndex].seatsIoLabel,
          productId: ticketsInfo[foundSeatIndex].productId
        };
      return foundSeatIndex >= 0;
    });

    ticketsToMove.forEach(ticket => {
      if (ticket.seatsIoLabel in ticketGroups) ticketGroups[ticket.seatsIoLabel].ticketIds.add(ticket.ticketId);
    });

    const ticketGroupsArr = Object.values(ticketGroups);

    for (let index = 0; index < ticketGroupsArr.length; index++) {
      const patchTicket = yield call(ReservedSeatingService.unbookReservedSeatingTickets, {
        purchasedTicketIds: Array.from(ticketGroupsArr[index].ticketIds),
        seatsIoLabel: ticketGroupsArr[index].seatsIoLabel,
        productId: ticketGroupsArr[index].productId,
        seasonId
      });
      if (!hadError) hadError = patchTicket instanceof ErrorDTO;
    }
  } else {
    for (let index = 0; index < ticketsInfo.length; index++) {
      if (ticketsInfo[index].seatStatus == 'BOOKED' && ticketsInfo[index].ticketId) {
        const unbookResponse = yield call(unbookSeatById, ticketsInfo[index].ticketId);

        if (!hadError) hadError = unbookResponse instanceof ErrorDTO;

        yield call(updateSeatsInfo);
      } else {
        console.error(`SEAT STATUS IS: ${ticketsInfo[index].seatStatus}`);
      }
    }
  }

  if (hadError) {
    yield put(addNotification(generateFailContent('Failed to book seats')));
  } else {
    const moreThanOneSeat = ticketsInfo.length > 1;
    yield put(
      addNotification(
        generateSuccessMessageWithAction(
          '',
          `${ticketsInfo.length} ${moreThanOneSeat ? 'seats were' : 'seat was'} unbooked`,
          `View seat${moreThanOneSeat ? 's' : ''} in List View`,
          () => notificationChannel.put(viewSeatsInOrder('Unbooked', [...ticketsInfo]))
        )
      )
    );

    yield call(updateSeatsInfo);
  }
}

export function* moveSeat(action) {
  const { currentSeats, targetSeats, productId, seasonTickets } = action.payload;
  let hadError = false;

  if (!isNil(seasonTickets)) {
    const seasonId = seasonTickets.length > 0 ? seasonTickets[0].seasonId : null;
    const ticketGroups = {};

    const ticketsToMove = seasonTickets.filter(ticket => {
      const foundSeatIndex = currentSeats.findIndex(seat => seat.seatsIoLabel === ticket.seatsIoLabel);
      if (foundSeatIndex >= 0 && ticket.seatsIoLabel in ticketGroups === false)
        ticketGroups[ticket.seatsIoLabel] = {
          ticketIds: new Set(),
          seatsIoLabel: targetSeats[foundSeatIndex].seatsIoLabel,
          productId
        };
      return foundSeatIndex >= 0;
    });

    ticketsToMove.forEach(ticket => {
      if (ticket.seatsIoLabel in ticketGroups) ticketGroups[ticket.seatsIoLabel].ticketIds.add(ticket.ticketId);
    });

    const ticketGroupsArr = Object.values(ticketGroups);

    for (let index = 0; index < ticketGroupsArr.length; index++) {
      const patchTicket = yield call(ReservedSeatingService.moveReservedSeatingTickets, {
        purchasedTicketIds: Array.from(ticketGroupsArr[index].ticketIds),
        seatsIoLabel: ticketGroupsArr[index].seatsIoLabel,
        productId: ticketGroupsArr[index].productId,
        seasonId
      });
      if (!hadError) hadError = patchTicket instanceof ErrorDTO;
    }
  } else {
    for (let index = 0; index < currentSeats.length; index++) {
      const patchSeat = yield call(moveBookedSeat, {
        ticketId: currentSeats[index].ticketId,
        seatsIoLabel: targetSeats[index].seatsIoLabel,
        productId
      });
      if (!hadError) hadError = patchSeat instanceof ErrorDTO;
    }
  }

  if (hadError) {
    yield put(addNotification(generateFailContent('Failed to move seats')));
  } else {
    const moreThanOneSeat = targetSeats.length > 1;
    yield put(
      addNotification(
        generateSuccessMessageWithAction(
          '',
          `${targetSeats.length} ${moreThanOneSeat ? 'seats were' : 'seat was'} moved`,
          `View seat${moreThanOneSeat ? 's' : ''} in List View`,
          () => notificationChannel.put(viewSeatsInOrder('Moved', [...targetSeats]))
        )
      )
    );

    yield call(updateSeatsInfo);
  }
}

export function* updateTicketNotes(action) {
  const { notes, tickets } = action.payload;
  yield all(tickets.map(ticket => call(updateTicketNote, notes, ticket.accessToken)));
  yield call(updateSeatsInfo);
}

function* CreateOrder(action) {
  const { id, ticketSeat, isSeason } = action;
  const event = { eventId: id, tickets: [] };
  const season = { seasonId: id, tickets: [] };
  let email;
  let phoneNumber;
  let user;
  const { seatsInfo } = yield select(state => state.eventReservedSeating);
  const updatedSeatsInfo = { ...seatsInfo };

  for (let index = 0; index < ticketSeat.length; index++) {
    const { seatsIoLabel, productId, userInfo } = ticketSeat[index];
    let reservationSeatId;

    if (isSeason) {
      reservationSeatId = yield call(updateSeasonSeatStatus, {
        seasonId: id,
        seatsIoLabel,
        ticketId: productId,
        seatStatus: 'HOLD'
      });
    } else {
      reservationSeatId = yield call(updateSeatStatus, {
        eventId: id,
        seatsIoLabel,
        ticketId: productId,
        seatStatus: 'HOLD'
      });
    }

    if (reservationSeatId instanceof ErrorDTO) return reservationSeatId;
    const seatId = reservationSeatId.id;
    const forms = [
      {
        formId: 0,
        fields: [
          {
            formFieldId: 0,
            name: 'first-name',
            value: userInfo.firstName
          },
          {
            formFieldId: 0,
            name: 'last-name',
            value: userInfo.lastName
          }
        ]
      }
    ];

    updatedSeatsInfo.seatsInfo[seatsIoLabel].book({
      email: userInfo.email,
      seatReservationId: seatId,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName
    });

    const ticket = new OrderTicket({ forms, productId, seatId });

    if (index == 0) {
      email = userInfo.email;
      phoneNumber = userInfo.phoneNumber;
      user = userInfo;
    }

    if (isSeason) {
      delete ticket.raw.quantity;
      season.tickets.push(ticket);
    } else {
      event.tickets.push(ticket);
    }
  }

  yield put(updateSeatsInfoCompleted({ updatedSeatsInfo: new EventReservedSeating(updatedSeatsInfo) }));

  if (isSeason) {
    return new CreateSeasonOrderRequest({
      chargeToken: {
        id: null
      },
      seasons: [season],
      grandTotal: 0,
      guestType: 'GuestCheckout',
      orderType: 'CompTicket',
      receiptChannel: 'EMAIL',
      purchaserEmail: email,
      purchaserPhoneNumber: phoneNumber
    });
  }
  return new CreateOrderRequest({
    chargeToken: {
      id: null
    },
    events: [event],
    grandTotal: 0,
    guestType: 'GuestCheckout',
    orderType: 'CompTicket',
    receiptChannel: 'EMAIL',
    purchaserEmail: email,
    purchaserPhoneNumber: phoneNumber
  });
}

export function* updateSeasonRenewal(action) {
  try {
    const { seasonRenewal, doneFn } = action.payload ?? {};
    const seasonRenewalResponse = yield call(SeasonRenewalService.updateSeasonRenewal, seasonRenewal);
    yield put(updateSeasonRenewalCompleted(seasonRenewalResponse));

    yield put(
      addNotification(generateSuccessMessage(SEASON_RENEWAL_CAMPAIGN.EDIT_CAMPAIGN_MODAL.UPDATE_MESSAGE_SUCCESS))
    );

    if (typeof doneFn === 'function') {
      doneFn();
    }
  } catch {
    yield put(addNotification(generateFailContent(SEASON_RENEWAL_CAMPAIGN.EDIT_CAMPAIGN_MODAL.UPDATE_MESSAGE_FAILURE)));
  }
}

export function* getSeasonRenewals(action) {
  try {
    const { seasonId } = action.payload ?? {};
    const seasonRenewalsResponse = yield call(getSeasonRenewalsBySeasonId, seasonId);

    yield put(
      getSeasonRenewalsCompleted({
        seasonRenewals: isEmpty(seasonRenewalsResponse) ? [] : seasonRenewalsResponse.toJSON()
      })
    );
  } catch {
    yield put(addNotification(generateFailContent('Failed to get season renewals')));
  }
}

export function* updateRenewalOffer(action) {
  try {
    const { renewalOffer, seasonId, doneFn } = action.payload ?? {};
    yield call(SeasonRenewalService.updateSeasonRenewalFan, renewalOffer);

    yield put(refreshSeasonRenewals({ seasonId }));

    yield put(updateRenewalOfferCompleted());

    yield put(
      addNotification(
        generateSuccessMessage('You have successfully made edits to the renewal offer', 'Success notification')
      )
    );

    if (typeof doneFn === 'function') {
      doneFn();
    }
  } catch {
    yield put(addNotification(generateFailContent('Failed to update renewal offer')));
  }
}

export function* deleteRenewalOffer(action) {
  try {
    const { renewalOffer, seasonId, doneFn } = action.payload ?? {};
    yield call(SeasonRenewalService.deleteSeasonRenewalFan, renewalOffer);

    yield put(refreshSeasonRenewals({ seasonId }));

    yield put(deleteRenewalOfferCompleted());

    yield put(
      addNotification(generateSuccessMessage('You have successfully deleted the renewal offer', 'Success notification'))
    );

    if (typeof doneFn === 'function') {
      doneFn();
    }
  } catch {
    yield put(addNotification(generateFailContent('Failed to delete renewal offer')));
  }
}

export function* watchBookData() {
  yield takeLatest(BOOK_RESERVED_SEATS, bookSeats);
}

export function* watchPrepareData() {
  yield takeLatest(PREPARE_DATA, prepareDataAction);
}

export function* watchBlockSeat() {
  yield takeLatest(BLOCK_RESERVED_SEATING, blockSeat);
}

export function* watchUnblockSeat() {
  yield takeLatest(UNBLOCK_RESERVED_SEATING, unblockSeat);
}

export function* watchMoveSeat() {
  yield takeLatest(MOVE_BOOKED_RESERVED_SEAT, moveSeat);
}

export function* watchUnbookSeat() {
  yield takeLatest(UNBOOKED_RESERVED_SEAT, unbookSeats);
}

export function* watchNotificationChannel() {
  while (true) {
    const action = yield take(notificationChannel);
    yield put(action);
  }
}

export function* watchUpdateTicketNotes() {
  yield takeLatest(UPDATE_TICKET_NOTES, updateTicketNotes);
}

export function* watchUpdateSeasonRenewal() {
  yield takeLatest(UPDATE_SEASON_RENEWAL, updateSeasonRenewal);
}

export function* watchGetSeasonRenewals() {
  yield takeLatest(GET_SEASON_RENEWALS, getSeasonRenewals);
}

export function* watchUpdateRenewalOffer() {
  yield takeLatest(UPDATE_RENEWAL_OFFER, updateRenewalOffer);
}

export function* watchDeleteRenewalOffer() {
  yield takeLatest(DELETE_RENEWAL_OFFER, deleteRenewalOffer);
}

export default [
  watchPrepareData(),
  watchBlockSeat(),
  watchUnblockSeat(),
  watchMoveSeat(),
  watchUnbookSeat(),
  watchBookData(),
  watchNotificationChannel(),
  watchUpdateTicketNotes(),
  watchUpdateSeasonRenewal(),
  watchGetSeasonRenewals(),
  watchUpdateRenewalOffer(),
  watchDeleteRenewalOffer()
];
