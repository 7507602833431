import { RootContext } from '@app/RootContext';

import { createContext, useCallback, useContext, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ActivityService, useActivities, useLevels, canAccessAccount, canEdit, TeamService } from '@gofan/api';
import { getAccountLogo, prepareTeamCreateRequestData } from '@accounts/v2/utils';

import { PAGES } from '@config/routes';

import type { AccountDTO, ActivityDTO, LevelDTO, TeamCreateRequestDTO } from '@gofan/api';
import type { CreateTeamFormValues } from '@accounts/v2/types';

export type AccountTeamContextProps = {
  isLoading: boolean;
  isError: boolean;
  errorMsg: unknown;
  getAccountTeamProps: () => AccountTeamProps;
};

export type AccountTeamProps = {
  accountLogo?: string;
  accountName?: string;
  athleticActivities: ActivityDTO[];
  levels: LevelDTO[];
  onCreateCanceled: () => void;
  onCreateTeam: (data: CreateTeamFormValues) => void;
};

export const AccountTeamContext = createContext<AccountTeamContextProps>({} as AccountTeamContextProps);

export type AccountTeamProviderProps = {
  account: AccountDTO | null;
  isAccountLoading: boolean;
  children: React.ReactNode;
  isEditableCommissioner: boolean;
  isCommissioner: boolean;
};

export function AccountTeamProvider({
  children,
  account,
  isAccountLoading,
  isEditableCommissioner,
  isCommissioner
}: AccountTeamProviderProps) {
  const { accountId } = useParams<{ accountId: string }>();
  const { currentUser } = useContext(RootContext);
  const allowedAccess = useMemo(
    () => canAccessAccount(accountId, currentUser) || isCommissioner || isEditableCommissioner,
    [accountId, currentUser, isAccountLoading]
  );
  const allowedEdit = useMemo(
    () => canEdit(accountId, currentUser) || isEditableCommissioner,
    [accountId, currentUser, isAccountLoading]
  );
  const history = useHistory();

  if (!isAccountLoading && !(allowedAccess || allowedEdit)) {
    history.push(PAGES.accountsV2.root.path);
  }

  const { data: activities, isLoading: isActivitiesLoading } = useActivities();
  const { data: levels, isLoading: isLevelsLoading } = useLevels();

  const getAccountTeamProps = useCallback(
    () =>
      ({
        accountLogo: getAccountLogo(accountId, account?.logo || ''),
        accountName: account?.name,
        athleticActivities: activities
          ? ActivityService.bubbleUpCommonSports(
              activities
                .filter((item: ActivityDTO) => !item.disabled && item.athletic)
                .sort((a: ActivityDTO, b: ActivityDTO) => a.label.localeCompare(b.label))
            )
          : [],
        levels: levels ? levels.filter((item: LevelDTO) => !item.disabled) : [],
        onCreateCanceled: () => {
          history.goBack();
        },
        onCreateTeam: data => {
          const teams: TeamCreateRequestDTO[] = prepareTeamCreateRequestData(data, account || undefined);
          return Promise.all(teams.map(team => TeamService.createTeam(team)));
        }
      } as AccountTeamProps),
    [accountId, account, activities, levels]
  );

  const defaultContext = useMemo(
    () =>
      ({
        isLoading: isActivitiesLoading || isLevelsLoading || isAccountLoading,
        getAccountTeamProps
      } as AccountTeamContextProps),
    [isActivitiesLoading, isLevelsLoading, isAccountLoading, getAccountTeamProps]
  );

  return <AccountTeamContext.Provider value={defaultContext}>{children}</AccountTeamContext.Provider>;
}
