import { Accordion as CarbonAccordion } from '@carbon/react';

import classNames from 'classnames';

import './Accordion.scss';

interface AccordionProps {
  align?: 'start' | 'end';
  className?: string;
  disabled?: boolean;
  isFlush?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

export const Accordion = (props: React.PropsWithChildren<AccordionProps>) => (
  <CarbonAccordion {...props} className={classNames('gf--accordion', props.className)} />
);
