import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AccountService } from './account.service';
import { ACCOUNTS_QUERY_KEY } from './account.endpoints';
import isNil from 'lodash/isNil';
import type { QueryOptions } from '@gofan/api/shared';
import type { AccountDTO } from './account.model';
import { useEffect } from 'react';

interface UseSearchAccountsOptions {
  accountIds: string[];
  queryKey?: string;
  queryOptions?: QueryOptions & { initialData?: () => AccountDTO[] };
}

export const useSearchAccounts = ({ accountIds, queryKey = '', queryOptions = {} }: UseSearchAccountsOptions) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: [`${ACCOUNTS_QUERY_KEY}${queryKey}`, { accountIds }],
    queryFn: () =>
      AccountService.searchAccountByIds(accountIds).then(accounts => accounts.filter(item => !isNil(item))),
    cacheTime: 15 * 60 * 1000, // 15 minutes
    staleTime: 15 * 60 * 1000,
    ...queryOptions
  });

  const removeCache = () =>
    queryClient.invalidateQueries(
      {
        queryKey: [`${ACCOUNTS_QUERY_KEY}${queryKey}`, { accountIds }],
        exact: true,
        refetchType: 'inactive'
      },
      { cancelRefetch: true }
    );

  return { ...query, removeCache };
};
