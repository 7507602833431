import { Toggle, ToggleProps } from 'carbon-components-react';

export type BasicToggleConfig = ToggleProps & {
  label: string;
  labelOn: string;
  labelOff: string;
};

export type BasicToggleProps = BasicToggleConfig & {
  name: string;
  control: any;
  initialValue: boolean;
  fieldValue: boolean;
  getFieldRef?: () => void;
};

const BasicToggle = ({
  name,
  label,
  labelOn,
  labelOff,
  className,
  initialValue,
  fieldValue,
  getFieldRef,
  control,
  ...other
}: BasicToggleProps) => (
  <Toggle
    {...other}
    {...control}
    id={`field-${name}`}
    ref={getFieldRef}
    className={className}
    labelText={label}
    labelA={labelOff}
    labelB={labelOn}
    defaultToggled={!!initialValue}
    toggled={!!fieldValue}
  />
);

export default BasicToggle;
