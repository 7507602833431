import React from 'react';
import { InlineNotification, NotificationActionButton } from 'carbon-components-react';
import { config } from '@gofan/constants';
import styled from 'styled-components';
import styles from './styles';

const Wrapper = styled.div`
  ${styles.wrapper}
`;

function Notification({
  kind,
  title,
  subtitle,
  caption,
  timeout = config.TIMEOUT_NOTIFICATION,
  lowContrast,
  hasAction,
  action,
  onClickAction,
  onCloseButtonClick,
  id,
  ...other
}) {
  const [isShown, setIsShown] = React.useState(true);
  const timerRef = React.useRef(null);

  React.useEffect(() => {
    if (isShown && timeout && !timerRef.current) {
      timerRef.current = setTimeout(() => {
        clearTimeout(timerRef.current);
        timerRef.current = null;
        setIsShown(false);
        onCloseButtonClick(id, false);
      }, timeout);
    }
    return () => {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    };
  }, [isShown, timeout, setIsShown, onCloseButtonClick]);

  React.useEffect(
    () => () => {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    },
    []
  );
  return isShown ? (
    <Wrapper hasAction={hasAction}>
      <InlineNotification
        kind={kind}
        title={title}
        caption={caption}
        subtitle={subtitle}
        lowContrast={lowContrast}
        onCloseButtonClick={() => {
          setIsShown(false);
          onCloseButtonClick(id, true);
        }}
        {...other}
      >
        {hasAction && <NotificationActionButton onClick={onClickAction}>{action}</NotificationActionButton>}
      </InlineNotification>
    </Wrapper>
  ) : null;
}

Notification.defaultProps = {
  kind: 'info',
  title: '',
  caption: '',
  subtitle: '',
  timeout: 0,
  lowContrast: false,
  action: '',
  hasAction: false,
  onClickAction: () => null,
  // eslint-disable-next-line no-unused-vars
  onCloseButtonClick: (id, removeInlineMessge) => null
};

export default Notification;
