import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { get, isEmpty } from 'lodash';
import { SkeletonText, OverflowMenu, OverflowMenuItem } from 'carbon-components-react';
import { FEATURE_FLAGS, useFeatureFlags } from '@gofan/hooks';
import { config, EVENT_TYPES } from '@gofan/constants';
import { PAGES } from '@config/routes';
import { isOverCancelEventEndDate, hasReservedSeats, hasEventTicketSold } from '@api/services/EventService';
import { generateSuccessMessage, generateError } from '@utils/alertUtils';
import { EventService as GofanEventService } from '@gofan/api';

import type { EventSalesInfoDTO } from '@events/models/event.model';
import type { AccountDTO, EventDTO, GameDTO } from '@gofan/api';
import type { UserDTO } from '@gofan/api/users';
import type { SchoolConfig } from '@gofan/api/school-config';

import {
  canEdit,
  canEditEventByDistrictUser,
  hasEditorEventPermission,
  isCoach,
  USER_ROLE_SUPER_ADMIN,
  USER_ROLE_SYSTEM_FINANCE_ADMIN
} from '@gofan/api/users';
import EventService from '@events/services/events.service';
import { EventCancellationsModal } from '@events/components/event-cancellations-modal';
import StyledModal from '@common/carbon-ui/atoms/StyledModal';
import { getEventType } from '@app/pages/EventDetail/helpers';
import { scrollNewElementIntoView } from '@app/utils/behaviorUtils';
import { EVENT_INSIGHT_OVERFLOW_LABEL } from '@app/modules/events/constants';
import { EventRescheduleModal } from '@events/components/event-reschedule-modal';
import { EventDuplicationModal } from '@events/components/event-duplication-modal';
import EventScheduleService from '@event-integrations/services/event-schedule.service';
import { DELETED_STATUS } from '@app/modules/event-integrations_V2/constants/constants';
import { EventRestoreModal } from '../event-restore-modal';

interface EventOverflowMenuProps {
  id?: string;
  history: any;
  event: EventDTO;
  accountIds: string[];
  currentUser: UserDTO;
  schoolsConfig?: SchoolConfig[];
  districtAccount?: AccountDTO;
  addNotification: Function;
  // eslint-disable-next-line no-unused-vars
  onDeletedEvent: (event: EventDTO) => void;
  // eslint-disable-next-line no-unused-vars
  onUpdatedEvent: (event: EventDTO) => void;
  // eslint-disable-next-line no-unused-vars
  onShowEventInsight: (id: EventDTO) => void;
  onSearchEvent: Function;
  isConcession?: boolean;
  isStreamingEvent?: boolean;
  direction?: 'top' | 'bottom';
  isCommissioner?: boolean;
  isEditableCommissioner?: boolean;
  athleticConference?: string;
  disableEditEvent?: boolean;
  games?: GameDTO[];
}

const EventOverflowMenu = ({
  id,
  history,
  event,
  accountIds,
  currentUser,
  schoolsConfig,
  districtAccount,
  onDeletedEvent,
  onUpdatedEvent,
  onShowEventInsight,
  addNotification,
  onSearchEvent,
  isConcession = false,
  isStreamingEvent = false,
  direction = 'bottom',
  isCommissioner = false,
  isEditableCommissioner = false,
  athleticConference = '',
  disableEditEvent = false,
  games = []
}: EventOverflowMenuProps) => {
  const {
    [FEATURE_FLAGS.tempDistrictConferenceUnicorn]: enableDistrictUnicorn,
    [FEATURE_FLAGS.enableConferenceCommissionerFeatures]: enableConferenceCommissionerFeatures
  } = useFeatureFlags();

  const timerRef = useRef<any>();
  const isDeleted = useRef<boolean>(false);
  const isUnmounted = useRef<boolean>(false);
  const isPreparing = useRef<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [prepare, setPrepare] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isSkeletonShown, setIsSkeletonShown] = useState<boolean>(false);
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [salesInfo, setSalesInfo] = useState<EventSalesInfoDTO>();
  const [activityType, setActivityType] = useState('');
  const [eventCancelModal, setEventCancelModal] = useState<boolean>(false);
  const [eventRescheduleModal, setEventRescheduleModal] = useState<boolean>(false);
  const [eventDuplicationModal, setEventDuplicationModal] = useState<boolean>(false);
  const [eventRestoreModal, setEventRestoreModal] = React.useState<boolean>(false);
  const isStreamingOnly = isStreamingEvent && (event?.products ?? []).length === 0;

  const hasSold = hasEventTicketSold(salesInfo!);
  const isOverCancelEvent = isOverCancelEventEndDate(event);

  const canEditEvent = useMemo(() => {
    if (disableEditEvent) return false;
    if (enableDistrictUnicorn) {
      return canEditEventByDistrictUser({
        event,
        currentUser,
        accountId: event?.financialAccountId,
        schoolsConfig,
        districtAccount
      });
    }
    return (
      canEdit(event?.accountId, currentUser) ||
      canEdit(event?.financialAccountId ?? '', currentUser) ||
      (enableConferenceCommissionerFeatures && isEditableCommissioner)
    );
  }, [
    disableEditEvent,
    event,
    currentUser,
    schoolsConfig,
    districtAccount,
    enableDistrictUnicorn,
    isEditableCommissioner,
    enableConferenceCommissionerFeatures
  ]);

  const canAccessEvent = (enableConferenceCommissionerFeatures && isCommissioner) || canEditEvent;

  const canRestoreEvent =
    currentUser.role === USER_ROLE_SUPER_ADMIN || currentUser.role === USER_ROLE_SYSTEM_FINANCE_ADMIN;
  const isCancel = !event.canceled && hasSold && !isOverCancelEvent && !EventService.isFundraiser(event as any);
  const isReschedule = !event.canceled && !isOverCancelEvent;
  const reservedSeating = hasReservedSeats(event as any);
  const canDuplicateEvent = canEditEvent && !event?.awayGame;

  const onToggleModal = useCallback(() => setIsModalShown(!isModalShown), [isModalShown]);

  const onOpened = useCallback(() => {
    if (prepare || loading) return;
    setLoading(true);
    isPreparing.current = true;

    // show loading if wait more than 1 seconds
    clearTimeout(timerRef.current);
    setTimeout(() => {
      if (isUnmounted.current || !isPreparing.current) return;
      clearTimeout(timerRef.current);
      timerRef.current = null;
      isPreparing.current = false;
      setIsSkeletonShown(true);
    }, 1000);

    EventService.getEventSalesInfo(event.id)
      .then((eventSalesInfo: EventSalesInfoDTO) => {
        if (isUnmounted.current) return;
        setSalesInfo(eventSalesInfo);
        onUpdatedEvent({
          ...event,
          _embedded: {
            ...get(event, '_embedded', {}),
            salesInfo: eventSalesInfo
          }
        });
        setPrepare(true);
      })
      .catch(() => !isUnmounted.current && setPrepare(false))
      .finally(() => {
        if (isUnmounted.current) return;
        isPreparing.current = false;
        clearTimeout(timerRef.current);
        setLoading(false);
        setIsSkeletonShown(false);
      });
  }, [event, salesInfo, prepare, loading, isPreparing.current, isSkeletonShown, onUpdatedEvent]);

  const onDeleteEvent = useCallback(() => {
    if (isDeleting) return;
    setIsModalShown(false);
    setIsDeleting(true);
    EventService.deleteEventById(event.id)
      .then(() => {
        if (isUnmounted.current) return;
        isDeleted.current = true;
        addNotification(generateSuccessMessage(`${event.name} is deleted.`));
        if (event?.globalEventsId) {
          EventScheduleService.updateGlobalEvent({
            id: event?.globalEventsId,
            gofanStatusMsg: DELETED_STATUS
          }).catch(error => {
            if (isUnmounted.current) return;
            console.log(error);
          });
        }
      })
      .catch((err: any) => {
        if (isUnmounted.current) return;
        addNotification(generateError(err));
        setPrepare(false);
        setSalesInfo(undefined);
      })
      .finally(() => {
        if (isUnmounted.current) return;
        setIsDeleting(false);
        if (isDeleted.current) onDeletedEvent(event);
      });
  }, [event, isDeleting, onDeletedEvent]);

  const isPlayOnSiteEvent = !isStreamingOnly && GofanEventService.isPlayOnSiteEvent(event, games);

  useEffect(() => {
    const eventSalesInfo = get(event, '_embedded.salesInfo', {});
    if (!isEmpty(salesInfo)) {
      setSalesInfo(eventSalesInfo);
      setPrepare(true);
    }
  }, []);

  useEffect(
    () => () => {
      isUnmounted.current = true;
      clearTimeout(timerRef.current);
    },
    []
  );

  useEffect(() => {
    if (!isEmpty(event)) {
      setActivityType(getEventType(event));
    }
  }, [event]);

  return (
    <div aria-hidden='true' onClick={onOpened}>
      <OverflowMenu
        size='sm'
        flipped
        menuOptionsClass='event-overflow-menu'
        direction={direction}
        data-testid='overflow-menu-items'
      >
        {!isConcession ? (
          <>
            <OverflowMenuItem
              itemText='View event'
              onClick={() => history.push(PAGES.eventsV2.view.calculatePath(event.id))}
            />
            {(canEditEvent || isCoach(currentUser, event.accountId) || hasEditorEventPermission(currentUser, event)) &&
            !event.canceled ? (
              <OverflowMenuItem
                itemText='Edit event'
                onClick={() => {
                  if (isStreamingOnly) {
                    history.push(PAGES.streaming.editEvent.calculatePath(event.id, event.accountId));
                    return;
                  }
                  history.push(PAGES.eventsV2.edit.calculatePath(event.id, 0, '', athleticConference));
                }}
              />
            ) : null}
            {canDuplicateEvent && (
              <OverflowMenuItem itemText='Duplicate event' onClick={() => setEventDuplicationModal(true)} />
            )}
            {canEditEvent && isReschedule && (
              <OverflowMenuItem itemText='Reschedule event' onClick={() => setEventRescheduleModal(true)} />
            )}
            {canRestoreEvent && !!event.canceled && (
              <OverflowMenuItem itemText='Restore event' onClick={() => setEventRestoreModal(true)} />
            )}
            {prepare && isSkeletonShown && <OverflowMenuItem itemText={<SkeletonText />} />}
            {canAccessEvent && !isStreamingOnly && !isPlayOnSiteEvent && (
              <OverflowMenuItem
                itemText='Manage attendees'
                onClick={() => history.push(PAGES.fans.root.calculatePath(event.id, 'event', athleticConference))}
              />
            )}
            {canEditEvent && reservedSeating ? (
              <OverflowMenuItem
                itemText='Reserved seating'
                onClick={() => history.push(PAGES.eventsV2.reservedSeating.calculatePath(event.id, 0))}
              />
            ) : null}
            {canAccessEvent && !isStreamingOnly && !config.TEMP_HIDE_EVENT_INSIGHT_FEATURE && !isPlayOnSiteEvent && (
              <OverflowMenuItem
                itemText={EVENT_INSIGHT_OVERFLOW_LABEL}
                onClick={() => {
                  _closeOverflowMenu();
                  onShowEventInsight(event);
                  scrollNewElementIntoView({ id: 'event-insight', newTop: 180 });
                }}
              />
            )}
            {prepare && canEditEvent && isCancel ? (
              <OverflowMenuItem isDelete itemText='Cancel event' onClick={() => setEventCancelModal(true)} />
            ) : null}
            {prepare && canEditEvent && !hasSold ? (
              <OverflowMenuItem
                isDelete
                disabled={isDeleting}
                itemText='Delete event'
                onClick={() => !isModalShown && setIsModalShown(true)}
              />
            ) : null}
          </>
        ) : (
          _renderConcessionOverflowMenuItems()
        )}
      </OverflowMenu>
      <StyledModal
        open={isModalShown}
        closeLabel='Cancel'
        submitLabel=''
        deleteLabel={!isConcession ? 'Delete event' : 'Delete concession'}
        modalHeaderLabel=''
        modalHeaderTitle={!isConcession ? 'Delete this event' : 'Delete this concession'}
        description={
          <div>
            <p>
              By deleting this {!isConcession ? 'event' : 'concession'}, it will be permanently removed from your
              school’s account. This {!isConcession ? 'event' : 'concession'} cannot be recovered after deletion.
            </p>
            <p>Click Delete {!isConcession ? 'event' : 'concession'} to continue</p>
          </div>
        }
        onRequestClose={onToggleModal}
        onRequestSubmit={onDeleteEvent}
      />
      <EventCancellationsModal
        id={id}
        event={event}
        accountIds={accountIds}
        open={eventCancelModal}
        onCloseModal={() => setEventCancelModal(false)}
        onReloadEvent={() => onSearchEvent()}
        addNotification={addNotification}
        isConcession={isConcession}
      />
      <EventRescheduleModal
        id={id}
        event={event}
        accountIds={accountIds}
        open={eventRescheduleModal}
        hasSold={hasSold}
        onCloseModal={() => setEventRescheduleModal(false)}
        onReloadEvent={() => onSearchEvent()}
        addNotification={addNotification}
        isConcession={isConcession}
      />
      <EventRestoreModal
        event={event as any}
        open={eventRestoreModal}
        onReloadEvent={() => onSearchEvent()}
        addNotification={addNotification}
        onCloseModal={() => setEventRestoreModal(false)}
        isConcession={isConcession}
      />
      {eventDuplicationModal && (
        <EventDuplicationModal
          id={id}
          event={event}
          open={eventDuplicationModal}
          data={{
            currentUser,
            account: get(event, '_embedded.account', {}),
            opponentAccount: get(event, '_embedded.account-opponent', {})
          }}
          onClose={() => setEventDuplicationModal(false)}
          onSubmit={_onDuplicateEvent}
        />
      )}
    </div>
  );

  function _onDuplicateEvent(duplicatedEvent: Partial<EventDTO>) {
    setEventDuplicationModal(false);
    if (isPlayOnSiteEvent) {
      history.push(PAGES.eventsV2.add.calculatePath(duplicatedEvent.accountId), {
        type: activityType,
        duplicatedEvent,
        customType: EVENT_TYPES.PLAYON_SITE
      });
    } else if (!isConcession) {
      history.push(PAGES.eventsV2.add.calculatePath(duplicatedEvent.accountId), {
        type: activityType,
        duplicatedEvent
      });
    } else {
      history.push(PAGES.concessions.eventEditor.create.calculatePath(duplicatedEvent.accountId), {
        type: activityType,
        duplicatedEvent
      });
    }
  }

  function _closeOverflowMenu() {
    document.body.click();
  }

  function _renderConcessionOverflowMenuItems() {
    return (
      <>
        <OverflowMenuItem
          itemText='View concession'
          onClick={() => history.push(PAGES.concessions.view.calculatePath(event.id))}
        />
        {canEditEvent && !event.canceled ? (
          <OverflowMenuItem
            itemText='Edit concession'
            onClick={() => history.push(PAGES.concessions.eventEditor.edit.calculatePath(event.id, event.accountId))}
          />
        ) : null}
        {canEditEvent && (
          <OverflowMenuItem itemText='Duplicate concession' onClick={() => setEventDuplicationModal(true)} />
        )}
        {canEditEvent && isReschedule && (
          <OverflowMenuItem itemText='Reschedule concession' onClick={() => setEventRescheduleModal(true)} />
        )}
        {canRestoreEvent && !!event.canceled && (
          <OverflowMenuItem itemText='Restore concession' onClick={() => setEventRestoreModal(true)} />
        )}
        {prepare && canEditEvent && isCancel ? (
          <OverflowMenuItem isDelete itemText='Cancel concession' onClick={() => setEventCancelModal(true)} />
        ) : null}
        {prepare && canEditEvent && !hasSold ? (
          <OverflowMenuItem
            isDelete
            disabled={isDeleting}
            itemText='Delete concession'
            onClick={() => !isModalShown && setIsModalShown(true)}
          />
        ) : null}
      </>
    );
  }
};

export default EventOverflowMenu;
