// endpoints
export const RESERVED_SEAT_STATUS_URI = '/reserved-seating/';
export const SEASON_RESERVED_SEAT_STATUS_URI = '/reserved-seating/season';
export const RESERVED_SEAT_BLOCK_URI = '/reserved-seating/block';
export const RESERVED_SEAT_UNBLOCK_URI = '/reserved-seating/unblock';
export const RESERVED_SEAT_DATA_SYNC_URI = '/reserved-seating/dataSync';
export const RESERVED_SEATING_CHART_URI = '/api/seatsio/chart/{chartKey}';
export const RESERVED_SEATING_CHART_CATEGORIES_URI = '/api/seatsio/chart-categories/{chartKey}';
export const MOVE_RESERVED_SEATING_TICKETS_URI = '/tickets/season/seats/';
export const UNBOOK_RESERVED_SEATING_TICKETS_URI = '/tickets/season/unbook/';

export const SEASON_STATUS_SEATS_URI = '/reserved-seating/?seasonId={seasonId}';
export const SEASON_UNAVAILABLE_SEATS_URI = '/reserved-seating/season/unavailable/?seasonId={seasonId}';

// query keys
export const RESERVED_SEAT_QUERY_KEY = 'reserved-seating';
export const RESERVED_SEAT_CHART_QUERY_KEY = 'reserved-seating-chart';
