import { createContext, FC, useContext, useRef, useState } from 'react';

interface ConfirmContextValue {
  // eslint-disable-next-line no-unused-vars
  setVisibility: (open: boolean, okFn?: () => void) => void;
  isOpen: boolean;
  okFn?: () => void;
}

const ConfirmModalContext = createContext<ConfirmContextValue | undefined>(undefined);

export const ConfirmModalProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const okFnRef = useRef<() => void>();

  const setVisibility: ConfirmContextValue['setVisibility'] = (open = false, onOk?) => {
    okFnRef.current = onOk;
    setIsOpen(open);
  };

  return (
    <ConfirmModalContext.Provider
      value={{
        setVisibility,
        isOpen,
        okFn: okFnRef.current
      }}
    >
      {children}
    </ConfirmModalContext.Provider>
  );
};

export const useConfirmModal = () => {
  const context = useContext(ConfirmModalContext);

  if (!context) {
    throw new Error('Can not found ConfirmModalProvider in the component tree');
  }

  return context;
};
