import type { PageableDTO, PageResponseDTO } from '../shared';

/* eslint-disable no-shadow */
export enum TeamGenderEnum {
  SPECIFIC = 'Specific',
  BOYS = 'Boys',
  GIRLS = 'Girls',
  COED = 'Coed'
}

export enum TeamSeasonEnum {
  ALL = 'All',
  SPRING = 'Spring',
  SUMMER = 'Summer',
  AUTUMN = 'Autumn',
  WINTER = 'Winter'
}
/**
 * Deprecated with API contract. Temporary using TeamResponseDTO
 * it would be checked and update.
 * */

export interface TeamDTO {
  id: string;
  name: string;
  ticketTypesValue: string;
  mappedToCameraValue: string;
  activityId: string;
  activity: string;
  gender: string;
  level: string;
  levelId?: number;
  season: string;
}

export interface TeamSearchParams {
  activityId?: number | string;
  organizationId: string;
  programId?: string;
  startYear?: number;
  page?: number;
  size?: number;
  sort?: string[];
}

export interface TeamRequestDTO {
  id?: string;
  activityId: number;
  department?: string;
  gender: string;
  invalid?: boolean;
  levelId: number;
  organizationId: string;
  programId?: string;
  startYear: number;
  seasonName: TeamSeasonEnum;
}

export interface TeamResponseDTO {
  id: string;
  activityId: number;
  activityName: string;
  department: string;
  gender: string;
  invalid: boolean;
  levelId: number;
  levelName: string;
  organizationId: string;
  programId: string;
  schoolYear: number;
  seasonName: string;
  startYear: number;
  teamLabel?: string;
}

export type TeamDetail = TeamResponseDTO & {
  name: string;
  ticketTypesValue?: string;
  mappedToCameraValue?: string;
};

export interface TeamPageResponseDTO extends PageResponseDTO {
  content?: TeamResponseDTO[];
}

export type TeamCreateRequestDTO = Omit<TeamRequestDTO, 'id' | 'invalid'>;

export type TeamUpdateRequestDTO = TeamResponseDTO;

export type TeamDetailProps = {
  teams: TeamDTO[];
  ticketSales: number;
  ticketSold: number;
};

export type TeamRowDetail = {
  teams: TeamDetail[];
  ticketSales?: number;
  ticketSold?: number;
};
export type TeamRowData = {
  id: string;
  logo?: string;
  teams: string;
  ticketTypes?: boolean;
  mappedToCamera?: boolean;
  detail: TeamRowDetail;
};

export type TeamYearlyStats = {
  ticketSold: number;
  ticketSales: number;
};

export type TeamSearchResponseDTO = {
  content: TeamResponseDTO[]; // Will be reverted to TeamDTO
  first: boolean;
  last: boolean;
  number: number;
  pageable: PageableDTO;
  size: number;
  totalElements: number;
  totalPages: number;
};
