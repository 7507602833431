import { AccountProductRepository } from './account-product.repository';
import { PageRequest } from '../shared/model/request/page-request.model';

import type { AccountProductDTO, AccountProductSearchDTO } from './account-product.model';
import type { ActivityDTO } from '@gofan/api/activities';

export type AccountProductSearchParams = {
  page: number;
  pageSize: number;
};

export type CreateAccountType = {
  content: AccountProductDTO[];
  athleticActivities: ActivityDTO[];
};

const searchProductParams: AccountProductSearchParams = {
  page: 0,
  pageSize: 1000 // total product max = 100 the same like others
};

class AccountProductService {
  static searchProducts = (accountProductRequest: AccountProductSearchDTO) => {
    const pageQuery = new PageRequest({
      pageIndex: searchProductParams.page,
      pageSize: searchProductParams.pageSize
    }).toQueryString();
    const queryStr = `?ts=${new Date().getTime()}&skipCache=true&expand=levels&expand=activity&${pageQuery}`;
    return AccountProductRepository.searchAccountProduct(accountProductRequest, queryStr);
  };

  static createAccountTickets = (body: CreateAccountType) => {
    const { content, athleticActivities } = body;
    const bodyRequest = content.map(item => {
      if (!athleticActivities?.some(activity => activity.athletic && `${activity.id}` === `${item.activityId}`)) {
        return { ...item, levelIds: [] };
      }
      return item;
    });
    return AccountProductRepository.createAccountTickets({ content: bodyRequest } as any);
  };

  static editAccountTicket = (body: AccountProductDTO) => AccountProductRepository.editAccountTicket(body.id, body);

  static deleteAccountTicket = (accountTicketId: string) =>
    AccountProductRepository.deleteAccountTicket(accountTicketId);
}

export { AccountProductService };
