import {
  RateListActionTypes,
  RESET_STATE,
  SEARCH_RATES,
  SEARCH_RATES_SUCCESS,
  INSERT_RATE_RECENTLY
} from './actionTypes';

import { SearchRatesParams, SearchRatesResult } from './types';
import { Rate } from '../../api/types';

export const resetState = (): RateListActionTypes => ({
  type: RESET_STATE
});

export const searchRates = (searchParams: SearchRatesParams): RateListActionTypes => ({
  type: SEARCH_RATES,
  payload: {
    searchParams
  }
});

export const searchRatesSuccess = (searchResult: SearchRatesResult): RateListActionTypes => {
  return {
    type: SEARCH_RATES_SUCCESS,
    payload: {
      searchResult
    }
  };
};

export const insertRateRecently = (rateRecently:Rate):RateListActionTypes =>{
  return {
    type: INSERT_RATE_RECENTLY,
    payload: {
      rateRecently
    }
  }
}
