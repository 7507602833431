import {
  Toggletip as CarbonToggleTip,
  ToggletipLabel,
  ToggletipButton,
  ToggletipContent,
  ToggletipActions
} from '@carbon/react';
import { Information } from '@carbon/react/icons';

import './ToggleTip.scss';

interface ToggleTipProps {
  align:
    | 'top'
    | 'top-left'
    | 'top-right'
    | 'bottom'
    | 'bottom-left'
    | 'bottom-right'
    | 'left'
    | 'left-bottom'
    | 'left-top'
    | 'right'
    | 'right-bottom'
    | 'right-top';

  classNameContent?: string;
  label?: string;
  message?: string | string[] | React.ReactNode;
  actions?: React.ReactNode;
  defaultOpen?: boolean;
  children?: React.ReactNode;
}

const ToggleTip = ({ classNameContent, align, label, message, actions, defaultOpen, children }: ToggleTipProps) => {
  return (
    <div className='gf--toggle-tooltip'>
      {typeof label === 'string' && <ToggletipLabel>{label}</ToggletipLabel>}
      <CarbonToggleTip align={align} defaultOpen={defaultOpen ?? false}>
        <ToggletipButton>{children ?? <Information />}</ToggletipButton>
        <ToggletipContent className={classNameContent}>
          {typeof message !== 'undefined' && _renderToggleTipMessage()}
          <ToggletipActions>{typeof actions !== 'undefined' && actions}</ToggletipActions>
        </ToggletipContent>
      </CarbonToggleTip>
    </div>
  );

  function _renderToggleTipMessage() {
    if (Array.isArray(message)) {
      return message.map(p => <p>{p}</p>);
    }
    if (typeof message === 'string') {
      return <p>{message}</p>;
    }
    return message;
  }
};

export { ToggleTip };
export type { ToggleTipProps };
