import toast from 'react-hot-toast';
import type { ToastType, ToastOptions } from 'react-hot-toast';

export type NotificationType = ToastType | 'warning' | 'warning-alt' | 'info';
export type NotificationAction = { action?: { text: string; onClick: () => unknown } };
export type NotificationOptions = ToastOptions & NotificationAction;

export const NOTIFICATION_DURATION_SHORT = 5000;
export const NOTIFICATION_DURATION_MEDIUM = 7500;
export const NOTIFICATION_DURATION_LONG = 12500;

export const addNotification = ({
  type,
  message,
  options
}: {
  type: NotificationType;
  message: string;
  options?: NotificationOptions;
}) => {
  switch (type) {
    case 'success':
      toast.success(message, { ...options, className: type });
      break;

    case 'info':
      toast(message, { ...options, className: type });
      break;

    case 'error':
      toast.error(message, { ...options, className: type });
      break;

    case 'loading':
      toast.loading(message, { ...options, className: type });
      break;

    default:
      toast(message, { ...options, className: type });
  }
};

export const removeNotifications = () => {
  toast.remove();
};
