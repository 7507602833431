import {
  Button,
  Checkbox,
  ComposedModal,
  InlineLoading,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'carbon-components-react';
import classNames from 'classnames';
import { useEventCancellations } from '@events/components/event-cancellations-modal/use-event-cancellations';
import type { EventCancellationsDataDTO } from '@events/components/event-cancellations-modal/use-event-cancellations';
import { EventService } from '@gofan/api';
import type { EventDTO } from '@gofan/api';
import strings from '@events/strings';
import { EventInfo } from '@events/components/event-info';
import { EventSalesInfo } from '@events/components/event-sales-info';

interface EventCancellationsModalProps {
  id?: string;
  event: EventDTO;
  accountIds: string[];
  open: boolean;
  onCloseModal: () => void;
  onReloadEvent: () => void;
  addNotification: Function;
  isConcession?: boolean;
}
const EventCancellationsModal = ({
  id = '',
  event,
  accountIds,
  open,
  addNotification,
  onCloseModal,
  onReloadEvent,
  isConcession = false
}: EventCancellationsModalProps) => {
  const key = `${id}${event.id}`;
  const eventLabel = EventService.getEventLabelByType(event);
  const {
    eventInfo,
    ticketSaleInsight,
    errorInsight,
    sendFansNotification,
    loading,
    canceling,
    setSendFansNotification,
    isConfirmed,
    setConfirm,
    onClickCancelEvent
  }: EventCancellationsDataDTO = useEventCancellations({
    open,
    event,
    accountIds,
    addNotification,
    onCloseModal,
    onReloadEvent
  });

  const handleCloseModal = () => !canceling && onCloseModal();

  return (
    <ComposedModal size='lg' open={open} onClose={handleCloseModal} className='event-cancellations-modal'>
      <ModalHeader className='event-cancellations-modal__header gs--ui-01'>
        <div className={classNames('gs--productive-heading-03', 'gs--text-01')}>
          {strings.EVENT_CANCELLATIONS.TITLE.replace('{eventType}', eventLabel)}
        </div>
        <div className={classNames('gs--body-long-01', 'gs--text-01', 'gs--padding-top__sp5')}>
          {strings.EVENT_CANCELLATIONS.DESCRIPTION.replace('{eventType}', eventLabel)}
        </div>
      </ModalHeader>
      <ModalBody hasForm className='event-cancellations-modal__body'>
        <EventInfo eventInfo={eventInfo} />
        {!isConcession && (
          <>
            <div className='event-cancellations-modal__body__divider' />
            <EventSalesInfo ticketSaleInsight={ticketSaleInsight} loading={loading} error={errorInsight} />
            <div className='event-cancellations-modal__body__send-mail'>
              <Checkbox
                checked={sendFansNotification}
                onChange={() => setSendFansNotification(!sendFansNotification)}
                id={`${key}-email-notify`}
                labelText={strings.EVENT_CANCELLATIONS.NOTIFICATION_EMAIL}
              />
            </div>
            <div className='event-cancellations-modal__body__refund-language '>
              {strings.EVENT_CANCELLATIONS.REFUND_LANGUAGE}
            </div>
          </>
        )}

        <div className='event-cancellations-modal__body__confirm'>
          <Checkbox
            checked={isConfirmed}
            onChange={() => setConfirm(!isConfirmed)}
            id={`${key}-cancel-confirm`}
            labelText={strings.EVENT_CANCELLATIONS.CONFIRM_LABEL.replace('{eventType}', eventLabel)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button kind='secondary' onClick={handleCloseModal}>
          {strings.EVENT_RESCHEDULE.BTN_CLOSE}
        </Button>
        <Button kind='danger' onClick={onClickCancelEvent} disabled={!isConfirmed || canceling}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {canceling ? (
            <InlineLoading
              status='active'
              description={strings.EVENT_CANCELLATIONS.BTN_CANCEL.replace('{eventType}', eventLabel)}
            />
          ) : (
            strings.EVENT_CANCELLATIONS.BTN_CANCEL.replace('{eventType}', eventLabel)
          )}
        </Button>
      </ModalFooter>
    </ComposedModal>
  );
};

export type { EventCancellationsModalProps };
export { EventCancellationsModal };
