import { forwardRef } from 'react';
import { NumberInput as CarbonNumberInput } from '@carbon/react';
import classNames from 'classnames';

import type { NumberInputProps as CarbonNumberInputProps } from '@carbon/react/lib/components/NumberInput/NumberInput';

import './NumberInput.scss';

interface NumberInputProps extends CarbonNumberInputProps {
  allowNegative?: boolean;
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>((props: NumberInputProps, ref) => {
  return (
    <CarbonNumberInput
      {...props}
      className={classNames('gf--number-input', props.className)}
      onKeyDown={_onPreventNumberInput}
      ref={ref}
    />
  );

  function _onPreventNumberInput(e: React.KeyboardEvent<HTMLInputElement>) {
    const disallowKeys = ['e'];
    if (!props.allowNegative) {
      disallowKeys.push('-');
    }
    const key = `${e.key}`.toLowerCase();
    if (disallowKeys.includes(key)) {
      e.preventDefault();
    }
  }
});
