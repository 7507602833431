import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { Row, Column, Dropdown, Button, RadioButton, FormLabel, RadioButtonGroup } from 'carbon-components-react';
import { Download16 } from '@carbon/icons-react';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import groupBy from 'lodash/groupBy';

import { ComboBox } from '@gofan/components';
import SearchSchool from '@season-management/components/search-school/search-school.component';
import SportsSelection from '@dashboard/components/SportsSelection/SportsSelection';

import { getNewTemplateSeasonUploadUrl } from '@app/api/services/SeasonService';
import { sortAccounts } from '../season-setup-modal/season-setup-modal.component';
import strings from '@dashboard/constants/strings';
import { SEASON_CREATION_METHODS } from '@dashboard/constants/constants';
import { DISTRICT_CONFERENCE_SCHOOL_TYPES, GROUP_SCHOOL_TITLE } from '@gofan/constants/accounts';
import { FEATURE_FLAGS, useFeatureFlags } from '@gofan/hooks';

import type { AccountDTO } from '@gofan/api/accounts';
import type { ActivityDTO } from '@gofan/api/activities';
import type { ProgressDataType } from '@common/generic-components/custom-progress/custom-progress-indicator.component';

const handleDownloadTemplate = () => {
  window.open(getNewTemplateSeasonUploadUrl(), '_blank', 'noopener,noreferrer');
};

export interface SeasonSetupStepsProps {
  sportSelectionKey: string | number;
  currentStep: number;
  progressData: ProgressDataType;
  activities: ActivityDTO[];
  accounts: AccountDTO[];
  selectionAccounts: AccountDTO[];
  isInternalUser: boolean;
  districtAccount?: AccountDTO;
  underDistrictSchools?: AccountDTO[];
}

const SeasonSetupSteps = ({
  sportSelectionKey,
  currentStep,
  progressData,
  activities,
  accounts,
  selectionAccounts,
  isInternalUser,
  districtAccount,
  underDistrictSchools = []
}: SeasonSetupStepsProps) => {
  const { [FEATURE_FLAGS.tempDistrictConferenceUnicorn]: enableDistrictUnicorn } = useFeatureFlags();
  const {
    control,
    getValues,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext();
  const accountValue = getValues('account');
  const financialAccountValue = getValues('financialAccount');
  const [searchValue, setSearchValue] = useState('');

  const homeSchoolOptions = useMemo(() => {
    const sortedAccounts = sortAccounts(uniqBy([...accounts, ...underDistrictSchools], 'id') as AccountDTO[]);
    const groupedSchools = groupBy(sortedAccounts, (account: AccountDTO) => {
      if (account.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT)
        return GROUP_SCHOOL_TITLE.DISTRICT;
      if (!isEmpty(account.districtHuddleId)) return GROUP_SCHOOL_TITLE.SCHOOLS_IN_YOUR_DISTRICT;
      return GROUP_SCHOOL_TITLE.OTHERS;
    });

    const {
      [GROUP_SCHOOL_TITLE.DISTRICT]: districtSchools = [],
      [GROUP_SCHOOL_TITLE.SCHOOLS_IN_YOUR_DISTRICT]: underSchools = [],
      [GROUP_SCHOOL_TITLE.OTHERS]: otherSchools = []
    } = groupedSchools ?? {};

    if (enableDistrictUnicorn && !isEmpty(districtSchools) && !isEmpty(underSchools)) {
      return [
        { name: GROUP_SCHOOL_TITLE.DISTRICT, disabled: true },
        ...districtSchools,
        { name: GROUP_SCHOOL_TITLE.SCHOOLS_IN_YOUR_DISTRICT, disabled: true },
        ...underSchools,
        { name: GROUP_SCHOOL_TITLE.OTHERS, disabled: true },
        ...otherSchools
      ];
    }
    return [...sortedAccounts];
  }, [accounts, underDistrictSchools]);

  const financialSchoolOptions = useMemo(() => {
    let result = [...selectionAccounts];
    if (accountValue) {
      result = [...result, accountValue];
    }
    if (districtAccount) {
      result = [...result, districtAccount];
    }
    return sortAccounts(uniqBy(result, 'id'));
  }, [selectionAccounts, accountValue, districtAccount]);

  useEffect(() => {
    setSearchValue(financialAccountValue?.name ?? '');
  }, [financialAccountValue]);

  return (
    <>
      {currentStep === progressData?.SELECT_SPORTS_STEP?.index && (
        <Column className='gs--padding-top__sp6'>
          <SportsSelection
            activities={activities}
            selectedActivities={watch('sports')}
            onUpdateActivities={updatedActivities => {
              setValue('sports', updatedActivities);
            }}
          />
        </Column>
      )}
      {currentStep === progressData?.ASSIGN_PAYMENT_SCHOOL_STEP?.index && (
        <Row className='gs--padding-top__sp5'>
          <Column className={classNames('season-setup-modal__school', 'gs--padding-bottom__sp5')} lg={6}>
            <Controller
              control={control}
              name='account'
              render={({ field }) => (
                <ComboBox
                  id='school_season_for'
                  className='season-setup-modal__school'
                  titleText={strings.SEASON_SETUP_MODAL.SCHOOL_SEASON_FOR}
                  items={homeSchoolOptions}
                  itemToString={item => item?.name || ''}
                  itemToElement={_renderSchoolOption}
                  selectedItem={field.value}
                  invalid={!!errors?.account}
                  invalidText={errors?.account?.message}
                  onChange={({ selectedItem }: { selectedItem: AccountDTO }) => {
                    field.onChange(selectedItem);
                    field.onBlur();
                  }}
                />
              )}
            />
          </Column>
          {isInternalUser ? (
            <Column className='season-setup-modal__school' lg={6}>
              <Row>
                <Column>
                  <FormLabel>{strings.SEASON_SETUP_MODAL.SCHOOL_GETS_PAID}</FormLabel>
                </Column>
              </Row>
              <Row>
                <Column className='season-setup-modal__school__dropdown'>
                  <Controller
                    control={control}
                    name='financialAccount'
                    render={({ field }) => (
                      <SearchSchool
                        invalid={!!errors?.financialAccount}
                        invalidText={errors?.financialAccount?.message}
                        searchValue={searchValue}
                        onSearchChange={(value: string) => {
                          setSearchValue(value);
                          if (isEmpty(value)) {
                            field.onChange({});
                            field.onBlur();
                          }
                        }}
                        onSelectItem={(financialAccount: AccountDTO) => {
                          field.onChange(financialAccount);
                          field.onBlur();
                        }}
                      />
                    )}
                  />
                </Column>
              </Row>
            </Column>
          ) : (
            <Column className={classNames('season-setup-modal__school', 'gs--padding-bottom__sp5')} lg={6}>
              <Controller
                control={control}
                name='financialAccount'
                render={({ field }) => (
                  <Dropdown
                    ref={field.ref}
                    light={false}
                    className='season-setup-modal__school'
                    id='school_gets_paid'
                    label=''
                    titleText={strings.SEASON_SETUP_MODAL.SCHOOL_GETS_PAID}
                    items={financialSchoolOptions}
                    invalid={!!errors?.financialAccount}
                    invalidText={errors?.financialAccount?.message}
                    selectedItem={field.value}
                    itemToString={item => item.name || ''}
                    onChange={({ selectedItem }: { selectedItem: AccountDTO }) => {
                      field.onChange(selectedItem);
                      field.onBlur();
                    }}
                  />
                )}
              />
            </Column>
          )}
        </Row>
      )}
      {currentStep === progressData?.CHOOSE_CREATION_METHOD_STEP?.index && (
        <Row className='gs--padding-top__sp5'>
          <Column lg={8}>
            <FormLabel>{strings.SEASON_SETUP_MODAL.CREATION_METHODS_TITLE}</FormLabel>
            <Controller
              control={control}
              name='creationMethod'
              render={({ field }) => (
                <RadioButtonGroup
                  ref={field.ref}
                  name={field.name}
                  orientation='vertical'
                  onChange={field.onChange}
                  valueSelected={field.value}
                >
                  {Object.values(SEASON_CREATION_METHODS).map((value: string) => (
                    <RadioButton key={value} name={value} value={value} labelText={value} />
                  ))}
                </RadioButtonGroup>
              )}
            />
          </Column>
          <Column className='season-setup-modal__link-download' lg={3}>
            <Button kind='ghost' renderIcon={Download16} onClick={handleDownloadTemplate}>
              {strings.SEASON_SETUP_MODAL.DOWNLOAD_TEMPLATE}
            </Button>
          </Column>
        </Row>
      )}
    </>
  );

  function _renderSchoolOption(item: AccountDTO) {
    if (
      [GROUP_SCHOOL_TITLE.DISTRICT, GROUP_SCHOOL_TITLE.SCHOOLS_IN_YOUR_DISTRICT, GROUP_SCHOOL_TITLE.OTHERS].includes(
        item.name
      )
    ) {
      return <div className='school-group'>{item.name}</div>;
    }
    return <div>{item.name}</div>;
  }
};

export default SeasonSetupSteps;
