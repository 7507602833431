import type { ZendeskSupportForm } from './zendesk.schema';
import { config } from '@gofan/constants/config';
import { DateUtils } from '@gofan/utils';
import { REQUEST_CONTACT_TYPES, SUPPORT_DIVISION_TYPES, ZENDESK_SUPPORT_FORM_FIELDS } from './zendesk.constant';
import type { UserDTO } from '../users/user.model';

// we use these list to filter out fields we do not want to send to zendesk
// there are certain fields that are only used for ticketing and streaming, some fields are shared
// we filter out the unused items based on support division ticketing/steaming
const TICKETING_SPECIFIC_FIELDS = [
  ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_TICKETING,
  ZENDESK_SUPPORT_FORM_FIELDS.EVENT_IDS,
  ZENDESK_SUPPORT_FORM_FIELDS.PLAYOFF_EVENT_TICKETING
];
const STREAMING_SPECIFIC_FIELDS = [
  ZENDESK_SUPPORT_FORM_FIELDS.GAME_ID,
  ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_STREAMING,
  ZENDESK_SUPPORT_FORM_FIELDS.PIXELLOT_ISSUES,
  ZENDESK_SUPPORT_FORM_FIELDS.PIXELLOT_LOCATION,
  ZENDESK_SUPPORT_FORM_FIELDS.ENCODING_BROADCAST_SOFTWARE
];

const generateCustomFields = (customFields, ticketDetails: ZendeskSupportForm) => {
  const additionalFields: { id: number; value: string }[] = [];
  const newCustomFields = Object.entries(customFields)
    .filter(([key]) => {
      if (ticketDetails.supportDivision === SUPPORT_DIVISION_TYPES.TICKETING) {
        return !STREAMING_SPECIFIC_FIELDS.flat().includes(key as ZENDESK_SUPPORT_FORM_FIELDS);
      }
      return !TICKETING_SPECIFIC_FIELDS.flat().includes(key as ZENDESK_SUPPORT_FORM_FIELDS);
    })
    .filter(([key, value]) => value !== null && key !== 'otherProductionIssue')
    .map(([key, id]) => {
      let value = ticketDetails[key];
      // otherProductionIssue is an additional field map for typeOfIssue equaling VOD Issue, Scheduling Issue, and Other
      // it does not exist as a field on the form, so we have to check it manually
      if (key === ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_STREAMING && value.text === 'VOD issue') {
        additionalFields.push({ id: customFields.otherProductionIssue, value: 'other_root_cause_vod' });
      }
      if (key === ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_STREAMING && value.text === 'Scheduling issue') {
        additionalFields.push({ id: customFields.otherProductionIssue, value: 'other_root_cause_schedule' });
      }
      if (key === ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_STREAMING && value.text === 'Other') {
        additionalFields.push({ id: customFields.otherProductionIssue, value: 'other_root_cause-question' });
      }
      if (value instanceof Date) {
        value = DateUtils.formatDate({ isUtc: false, date: value });
      }
      if (key === ZENDESK_SUPPORT_FORM_FIELDS.URGENT_REQUEST) {
        value = value ? 'urgent' : 'normal';
      }
      if (key === ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_REQUESTOR_EMAIL) {
        value = value.email;
      }
      if (key === ZENDESK_SUPPORT_FORM_FIELDS.PREFERRED_CONTACT_METHOD && value === 'Phone Call') {
        value = 'phone_call';
      }
      if (key === ZENDESK_SUPPORT_FORM_FIELDS.PLAYOFF_EVENT_TICKETING) {
        value = value ? 'playoff_yes' : 'playoff_no';
      }
      // here we grab any value from the dropdowns that have a {text:value} property
      if (value.value && value.text) {
        value = value.value;
      }
      // finally we return and handle if there is just a value or not
      return { id, value: value ?? '' };
    });

  return [...newCustomFields, ...additionalFields];
};

const buildSchoolEmailCC = (ticketDetails: ZendeskSupportForm, currentUser: UserDTO) => {
  // the main ticket requester could be a playon CSM or a school contact
  // if the requestor is a school contact we provide an option for the playon CSM to receive updates
  // to make that happen, we CC the playon CSM on the ticket in addition to the school contact as the main requestor
  if (!ticketDetails.updateRequest) {
    return [];
  }

  return [
    {
      user_email: currentUser.email,
      user_name: `${currentUser.firstName ?? ''} ${currentUser.lastName ?? ''}`,
      action: 'put'
    }
  ];
};

const getSchoolContactInfo = (ticketDetails: ZendeskSupportForm) => {
  const contactDetails = `${ticketDetails.name ?? ''}  ${ticketDetails.requestorEmail}  ${
    ticketDetails.phoneNumber ?? ''
  }`;

  if (ticketDetails.requestContactType === REQUEST_CONTACT_TYPES.PLAYON) {
    return 'Internal Playon Support';
  }

  return ticketDetails.schoolRequestorEmail.email === 'Unknown' ? 'Unknown' : contactDetails;
};

const getPlayonContactInfo = (currentUser, ticketDetails) => {
  // if the school is the requester, we will have to fetch the playon info from currentUser
  // if the school is NOT the requester, we can use the form fields
  // form fields are more valid than the currentUser info, sometimes the currentUser name and phone are blank
  if (
    ticketDetails.requestContactType === REQUEST_CONTACT_TYPES.SCHOOL &&
    ticketDetails.schoolRequestorEmail.email !== 'unknown'
  ) {
    return `${currentUser.firstName ?? ''} ${currentUser.lastName ?? ''} - ${currentUser.email} ${
      currentUser.phoneNumber
    }`;
  }
  return `${ticketDetails.name} ${ticketDetails.requestorEmail} ${ticketDetails.phoneNumber}`;
};

export const convertToZendeskTicketMapping = (
  ticketDetails: ZendeskSupportForm,
  currentUser: UserDTO,
  attachmentTokens: string[] = []
) => {
  const customFields = generateCustomFields(config.ZENDESK_TICKET.CUSTOM_FIELDS, ticketDetails);
  const ccEmails = buildSchoolEmailCC(ticketDetails, currentUser);

  return {
    ticket: {
      subject: `Internal Support Request - ${ticketDetails.supportDivision}`,
      comment: {
        html_body: `<div>Description: ${ticketDetails.issueDescription}</div><br /><br />
          <div>School: ${ticketDetails.schoolName.name} - ${ticketDetails.schoolName.id} - ${
          ticketDetails.schoolName.city
        }, ${ticketDetails.schoolName.state}</div><br /><br />
          <div>School Contact Info: ${getSchoolContactInfo(ticketDetails)} </div><br /><br />
          <div>PlayOn Requester Info: ${getPlayonContactInfo(currentUser, ticketDetails)}</div>`,
        uploads: attachmentTokens
      },
      description: ticketDetails.issueDescription,
      priority: ticketDetails.urgentRequest ? 'urgent' : 'normal',
      type: 'incident',
      status: 'new',
      requester: { name: ticketDetails.name, email: ticketDetails.requestorEmail },
      group_id:
        config.ZENDESK_TICKET[ticketDetails.supportDivision === 'streaming' ? 'STREAMING' : 'TICKETING'].group_id,
      brand_id:
        config.ZENDESK_TICKET[ticketDetails.supportDivision === 'streaming' ? 'STREAMING' : 'TICKETING'].brand_id,
      ticket_form_id:
        config.ZENDESK_TICKET[ticketDetails.supportDivision === 'streaming' ? 'STREAMING' : 'TICKETING'].ticket_form_id,
      email_ccs: ccEmails,
      custom_fields: customFields
    }
  };
};
