import React from 'react';
import NumberFormat from 'react-number-format';

import BaseInput from './BaseInput';
import FormSafeguard from './FormSafeguard';
import ViewOnlyGuard from './ViewOnlyGuard';
import { DEFAULT_HEIGHT_INPUT } from '../../../assets/styles/common';
import { StyledFormInput } from '../../components/styledSemantic';

const DecimalNumber = ({
  className,
  label,
  formKey,
  validation,
  errorMessage,
  nullText = '',
  placeHolder = '',
  required = false,
  hideLabel = false,
  overwritable = false,
  displayType = 'input',
  decimalScale = 2,
  customHeight = DEFAULT_HEIGHT_INPUT,
  readOnly = false,
  validationRules = null,
  min,
  max,
  allowNegative = true
}) => (
  <FormSafeguard formKey={formKey}>
    <ViewOnlyGuard
      nullText={nullText}
      label={label}
      formKey={formKey}
      renderView={value => (
        <NumberFormat
          value={value}
          displayType='text'
          decimalScale={decimalScale}
        />
      )}
    >
      <BaseInput
        className={className}
        readOnly={readOnly}
        label={label}
        formKey={formKey}
        required={required}
        hideLabel={hideLabel}
        placeHolder={placeHolder}
        overwritable={overwritable}
        customHeight={customHeight}
        validation={validation}
        errorMessage={errorMessage}
        validationRules={validationRules}
        min={min}
        max={max}
        renderInput={({ inputValue, onHandleChange, ...inputProps }) => (
          <NumberFormat
            {...inputProps}
            displayType={displayType}
            defaultValue={inputValue}
            decimalScale={decimalScale}
            customInput={StyledFormInput}
            onValueChange={({ value }) =>
              value !== inputValue && onHandleChange(value)
            }
            allowNegative={allowNegative}
          />
        )}
      />
    </ViewOnlyGuard>
  </FormSafeguard>
);

export default DecimalNumber;
