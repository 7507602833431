import { useQueries } from '@tanstack/react-query';
import { AccountService } from './account.service';
import { ACCOUNT_QUERY_KEY } from './account.endpoints';
import isNil from 'lodash/isNil';

import type { AccountDTO } from './account.model';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { QueryOptions } from '@gofan/api/shared';

export interface UseMultipleAccountsOptions {
  accountIds: string[];
  queryOptions?: QueryOptions;
}

export const useMultipleAccounts = ({ accountIds, queryOptions = {} }: UseMultipleAccountsOptions) => {
  const queries = useQueries({
    queries: accountIds.map<UseQueryOptions<AccountDTO>>(id => ({
      queryKey: [ACCOUNT_QUERY_KEY, id],
      queryFn: () => AccountService.getAccountById(id),
      refetchOnWindowFocus: false,
      cacheTime: 15 * 60 * 1000, // 15 minutes
      ...queryOptions
    }))
  });

  return {
    queries,
    data: queries.map(query => query.data).filter((acc): acc is AccountDTO => !isNil(acc)),
    isFetching: queries.some(query => query.isFetching),
    isLoading: queries.some(query => query.isLoading),
    isAllSuccess: queries.every(query => query.isSuccess)
  };
};
