import React from 'react';
import classnames from 'classnames';
import { Link } from 'carbon-components-react';

import CopyButton from '@app/commons/carbon-ui/atoms/CopyButton';

const POSITION_CONSTANT = {
  LEFT: 'left',
  RIGHT: 'right'
};

export interface CopyLinkProps {
  position?: string;
  url: string;
  title?: string;
  className?: string;
  hideButton?: boolean;
  copyConfig: {
    feedback: string;
    tooltipText: string;
    feedbackTimeout: number;
  };
}

const CopyLink = ({
  position = POSITION_CONSTANT.RIGHT,
  url,
  title,
  copyConfig,
  hideButton,
  className
}: CopyLinkProps) => (
  <div className={classnames('copy-link', className)}>
    {position === POSITION_CONSTANT.LEFT && (
      <div
        hidden={hideButton}
        className='gs--body-short-01-semibold gs--text-01 copy-link--icon gs--padding-right__sp3'
      >
        <CopyButton
          link={url}
          feedback={copyConfig.feedback}
          tooltipText={copyConfig.tooltipText}
          feedbackTimeout={copyConfig.feedbackTimeout}
        />
      </div>
    )}
    {title && <div className='gs--body-short-01-semibold gs--text-01 copy-link--title'>{title}</div>}
    <Link href={url} target='_blank' className='gs--body-short-01 gs--text-link copy-link--url'>
      {url}
    </Link>
    {position === POSITION_CONSTANT.RIGHT && (
      <div hidden={hideButton} className='gs--body-short-01-semibold gs--text-01 copy-link--icon'>
        <CopyButton
          link={url}
          feedback={copyConfig.feedback}
          tooltipText={copyConfig.tooltipText}
          feedbackTimeout={copyConfig.feedbackTimeout}
        />
      </div>
    )}
  </div>
);

export default CopyLink;
