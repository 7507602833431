import { Route, Switch } from 'react-router-dom';

import { RatesTable } from '@rates/views/rates-table';
import { RatesConfiguration } from '@rates/views/rates-configuration';

import { PAGES } from '@app/config/routes';

import type { RouteChildrenProps } from 'react-router-dom';

const Rates = (props: RouteChildrenProps) => (
  <Switch>
    <Route
      exact
      key={PAGES.rates.root.path}
      path={PAGES.rates.root.path}
      render={routeProps => <RatesTable {...props} {...routeProps} />}
    />

    <Route
      key={PAGES.rates.add.path}
      path={PAGES.rates.add.path}
      render={routeProps => <RatesConfiguration mode='add' {...props} {...routeProps} />}
    />

    <Route
      key={PAGES.rates.edit.path}
      path={PAGES.rates.edit.path}
      render={routeProps => <RatesConfiguration mode='edit' {...props} {...routeProps} />}
    />
  </Switch>
);

export { Rates };
