import { useState, useMemo } from 'react';
import classNames from 'classnames';
import { isEmpty, values } from 'lodash';
import { Button } from 'carbon-components-react';
import type { CarbonIconType } from '@carbon/icons-react';
import { CloseFilled16 } from '@carbon/icons-react';
import createSeasonIcon from '@assets/images/create-season-icon.svg';
import createEventIcon from '@assets/images/create-event-icon.svg';
import bulkUploadButton from '@assets/images/bulk-upload-button.svg';
import fundraiserIcon from '@assets/images/fundraiser-icon.svg';
import concessionsIcon from '@assets/images/concessions.svg';
import manageSponsorshipIcon from '@assets/images/manage-sponsorship.svg';
import mobilePassIcon from '@assets/images/mobilepass-icon.svg';
import { PAGES } from '@app/config/routes';
import Tooltip from '@app/commons/carbon-ui/atoms/Tooltip';
import CreateEventModal, {
  MODAL_TYPE,
  onSubmitCreateEventModal
} from '@app/modules/dashboard/components/create-event-modal/create-event-modal.component';

import { useFlatFile } from '@events/hooks';
import { WorkbookType } from '@gofan/constants/flatfile';
import { useFlatFilePlatform } from '@gofan/hooks/useFlatFilePlatform';
import { FEATURE_FLAGS, useFeatureFlags } from '@gofan/hooks';

import type { AccountDTO } from '@gofan/api/accounts';
import type { UserDTO } from '@gofan/api/users';
import SectionInfo from '@accounts/components/section-info/section-info.component';
import type { ActivityDTO } from '@gofan/api/activities';
import type { LevelDTO } from '@gofan/api/levels';
import type { SeasonsSetup } from '@dashboard/components/season-setup-modal/season-setup-modal.component';
import SeasonSetupModal from '@dashboard/components/season-setup-modal/season-setup-modal.component';
import { SEASON_CREATION_METHODS } from '@dashboard/constants/constants';
import { CREATION_PROCESS } from '@app/modules/season-management/constants/constant';
import { BulkUploadModal } from '@gofan/components';
import type { EventType } from '@app/modules/dashboard/components/single-event-type/single-event-type.component';
import type { EventCreationTypes } from '@app/modules/dashboard/components/create-event-modal/create-event-modal.component';
import { DISTRICT_CONFERENCE_SCHOOL_TYPES } from '@gofan/constants';

export type QuickStartItem = {
  id: string;
  text: string;
  icon?: CarbonIconType;
};

type QuickStartItems = {
  [k: string]: QuickStartItem;
};

type QuickStartProps = {
  history: any;
  accounts: AccountDTO[];
  isInternalUser: boolean;
  currentUser: UserDTO;
  // eslint-disable-next-line react/require-default-props
  activities?: ActivityDTO[];
  levels?: LevelDTO[];
  setDataToSetupMultiSeason: Function;
  resetSeasonManagementState: Function;
  selectionAccounts: AccountDTO[];
  setBulkUploadData: any;
  addNotification: (data: any) => void;
};

const QUICK_START = {
  CREATE_SEASON: 'CREATE_SEASON',
  CREATE_EVENT: 'CREATE_EVENT',
  UPLOAD_BULK_EVENTS: 'UPLOAD_BULK_EVENTS',
  CREATE_MOBILE_PASS: 'CREATE_MOBILE_PASS',
  MANAGE_ATTENDEES: 'MANAGE_ATTENDEES',
  CREATE_FUNDRAISER: 'CREATE_FUNDRAISER',
  CREATE_CONCESSION: 'CREATE_CONCESSION',
  CREATE_SPONSORSHIP: 'CREATE_SPONSORSHIP'
};

const createIcon =
  (src: string, alt: string): React.FC =>
  () =>
    <img className='quick-start-icon' src={src} alt={alt} />;

const quickStartItems: QuickStartItems = {
  [QUICK_START.CREATE_SEASON]: {
    id: QUICK_START.CREATE_SEASON,
    text: 'Create a Season',
    icon: createIcon(createSeasonIcon, 'create season icon')
  },
  [QUICK_START.CREATE_EVENT]: {
    id: QUICK_START.CREATE_EVENT,
    text: 'Create an Event',
    icon: createIcon(createEventIcon, 'create event icon')
  },
  [QUICK_START.UPLOAD_BULK_EVENTS]: {
    id: QUICK_START.UPLOAD_BULK_EVENTS,
    text: 'Upload Bulk Events',
    icon: createIcon(bulkUploadButton, 'bulk upload button')
  },
  [QUICK_START.CREATE_MOBILE_PASS]: {
    id: QUICK_START.CREATE_MOBILE_PASS,
    text: 'Create a Mobile Pass',
    icon: createIcon(mobilePassIcon, 'mobile pass icon')
  },
  [QUICK_START.CREATE_FUNDRAISER]: {
    id: QUICK_START.CREATE_FUNDRAISER,
    text: 'Create a Fundraiser',
    icon: createIcon(fundraiserIcon, 'fundraiser icon')
  },

  [QUICK_START.CREATE_CONCESSION]: {
    id: QUICK_START.CREATE_CONCESSION,
    text: 'Create a Concession',
    icon: createIcon(concessionsIcon, 'concessions icon')
  },
  [QUICK_START.CREATE_SPONSORSHIP]: {
    id: QUICK_START.CREATE_SPONSORSHIP,
    text: 'Manage Sponsors',
    icon: createIcon(manageSponsorshipIcon, 'sponsors icon')
  }
};

const QuickStart = ({
  history,
  accounts,
  activities,
  levels,
  setDataToSetupMultiSeason,
  resetSeasonManagementState,
  selectionAccounts,
  isInternalUser,
  currentUser,
  setBulkUploadData,
  addNotification
}: QuickStartProps) => {
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [seasonModalOpen, setSeasonModalOpen] = useState(false);
  const [mobilePassModalOpen, setMobilePassModalOpen] = useState(false);
  const [fundraiserModalOpen, setFundraiserModalOpen] = useState(false);
  const [concessionModalOpen, setConcessionModalOpen] = useState(false);
  const [sponsorshipModalOpen, setSponsorshipModelOpen] = useState(false);
  const [openBulkUploadModal, setOpenBulkUploadModal] = useState(false);
  const keyModal = useMemo(() => new Date().getTime(), [seasonModalOpen]);

  const {
    [FEATURE_FLAGS.newFlatfileUnicorn]: isNewFlatfileUnicorn,
    [FEATURE_FLAGS.enableSponsorship]: enableSponsorship,
    [FEATURE_FLAGS.tempDistrictConferenceUnicorn]: enableDistrictUnicorn
  } = useFeatureFlags();

  const { importEventCreationDataFromUser, importEventEditingDataFromUser } = useFlatFile({
    currentUser,
    addNotification
  });

  const { onOpenSpace, renderFlatfilePortal } = useFlatFilePlatform({ currentUser });

  const boxOfficeCustomerAccounts = useMemo(
    () => accounts.filter(account => account.boxOfficeCustomer === true),
    [accounts]
  );

  const quickStartActions = useMemo(() => {
    if (!enableSponsorship) {
      delete quickStartItems[QUICK_START.CREATE_SPONSORSHIP];
    }

    if (!isInternalUser) {
      return values(quickStartItems).filter(
        item => item.id !== QUICK_START.UPLOAD_BULK_EVENTS && item.id !== QUICK_START.CREATE_SPONSORSHIP
      );
    }

    return values(quickStartItems);
  }, [isInternalUser]);

  const onClickedItem = (item: QuickStartItem) => {
    const openSingleAccountPage = (page, accountId, type) => {
      history.push(page.calculatePath(accountId), { type });
    };

    switch (item.id) {
      case QUICK_START.CREATE_EVENT:
        setEventModalOpen(true);
        break;

      case QUICK_START.CREATE_SEASON:
        setSeasonModalOpen(true);
        break;

      case QUICK_START.UPLOAD_BULK_EVENTS:
        setOpenBulkUploadModal(true);
        break;

      case QUICK_START.CREATE_MOBILE_PASS:
        if (
          accounts.length === 1 &&
          !accounts.some(school => school.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT)
        ) {
          openSingleAccountPage(PAGES.eventsV2.add, accounts[0].id, 'Mobile Pass');
        } else {
          setMobilePassModalOpen(true);
        }
        break;

      case QUICK_START.CREATE_FUNDRAISER:
        if (accounts.length === 1) {
          openSingleAccountPage(PAGES.eventsV2.add, accounts[0].id, 'Fundraiser');
        } else {
          setFundraiserModalOpen(true);
        }
        break;

      case QUICK_START.CREATE_CONCESSION:
        if (boxOfficeCustomerAccounts.length === 1) {
          history.push(PAGES.concessions.eventEditor.create.calculatePath(boxOfficeCustomerAccounts[0].id));
        } else {
          setConcessionModalOpen(true);
        }
        break;
      case QUICK_START.CREATE_SPONSORSHIP:
        history.push(PAGES.sponsorships.listAllSponsors.calculatePath);
        break;

      default:
        break;
    }
  };

  const onClickedContinueForEventModal = (
    selectedAccount: AccountDTO,
    selectedType: EventType,
    creationTypes: EventCreationTypes[]
  ) => {
    setEventModalOpen(false);

    onSubmitCreateEventModal({ selectedAccount, selectedType, history, creationTypes });
  };

  const onClickedContinueForSeasonModal = ({ account, sports, financialAccount, creationMethod }: SeasonsSetup) => {
    resetSeasonManagementState();

    setDataToSetupMultiSeason({
      selectedSports: sports,
      financialAccountId: financialAccount?.id,
      financialAccount,
      creationMethod:
        creationMethod === SEASON_CREATION_METHODS.MULTI_SPORT
          ? CREATION_PROCESS.MULTI_SPORT_SETUP
          : CREATION_PROCESS.GENERAL_SETUP
    });

    if (creationMethod === SEASON_CREATION_METHODS.MULTI_SPORT) {
      history.push(PAGES.seasonManagement.seasonMultiSportSetup.calculatePath(account?.id));
    } else {
      history.push(PAGES.seasonManagement.create.calculatePath(account?.id));
    }
  };

  const onClickedContinueForMobilePassModal = selectAccount => {
    setSeasonModalOpen(false);
    history.push(PAGES.eventsV2.add.calculatePath(selectAccount.id), { type: 'Mobile Pass' });
  };

  const onClickedContinueForFundraiserModal = selectAccount => {
    setFundraiserModalOpen(false);
    history.push(PAGES.eventsV2.add.calculatePath(selectAccount.id), { type: 'Fundraiser' });
  };

  const onClickedContinueForConcessionModal = selectAccount => {
    setConcessionModalOpen(false);
    history.push(PAGES.concessions.eventEditor.create.calculatePath(selectAccount.id));
  };

  const onClickedContinueForSponsorshipModal = selectAccount => {
    setSponsorshipModelOpen(false);
    history.push(PAGES.sponsorships.create.calculatePath(selectAccount.id));
  };

  return (
    <SectionInfo divider title='Quick start' className='quick-start' titleClassName='gs--body-short-02-semibold'>
      <div className='quick-start--container'>
        {quickStartActions.map(item => {
          if (item.id === 'CREATE_CONCESSION' && accounts.every(account => account.boxOfficeCustomer === false)) {
            return null;
          }
          const disabled = isEmpty(accounts);
          return (
            <div key={item.id} className='quick-start--item'>
              {disabled && (
                <div className='close-button'>
                  <Tooltip align='end' direction='top' tooltipText="You don't have permission to do this">
                    <CloseFilled16 />
                  </Tooltip>
                </div>
              )}
              <Button
                kind='tertiary'
                className={classNames('btn--icon', 'menu-item', { disabled })}
                renderIcon={item.icon}
                disabled={disabled}
                onClick={() => onClickedItem(item)}
              >
                {item.text}
              </Button>
            </div>
          );
        })}
      </div>
      <CreateEventModal
        type={MODAL_TYPE.EVENT}
        open={eventModalOpen}
        content={{
          title: 'Create a Single Event',
          labelSchool: 'You have multiple schools selected. Which school is having this event?',
          labelType:
            "Select the type of single event you want to create. We'll guide you through customizing everything you need."
        }}
        accounts={accounts}
        onCloseModal={() => setEventModalOpen(false)}
        onClickedContinue={onClickedContinueForEventModal}
      />
      <SeasonSetupModal
        key={keyModal}
        open={seasonModalOpen}
        currentUser={currentUser}
        isInternalUser={isInternalUser}
        activities={activities}
        accounts={accounts}
        onCloseModal={() => {
          setSeasonModalOpen(false);
        }}
        onClickedContinue={onClickedContinueForSeasonModal}
        selectionAccounts={selectionAccounts}
      />
      <CreateEventModal
        type={MODAL_TYPE.MOBILE_PASS}
        open={mobilePassModalOpen}
        content={{
          title: 'Create a mobile pass',
          labelSchool: 'You have multiple schools selected. Which school’s mobile pass is this?',
          labelType: ''
        }}
        accounts={accounts}
        onCloseModal={() => setMobilePassModalOpen(false)}
        onClickedContinue={onClickedContinueForMobilePassModal}
      />
      <CreateEventModal
        type={MODAL_TYPE.FUNDRAISER}
        open={fundraiserModalOpen}
        content={{
          title: 'Create a fundraiser',
          labelSchool: 'You have multiple schools selected. Which school’s fundraiser is this?',
          labelType: ''
        }}
        accounts={accounts}
        onCloseModal={() => setFundraiserModalOpen(false)}
        onClickedContinue={onClickedContinueForFundraiserModal}
      />
      <CreateEventModal
        type={MODAL_TYPE.CONCESSION}
        open={concessionModalOpen}
        content={{
          title: 'Create a concession',
          labelSchool: 'You have multiple schools selected. Which school’s concession is this?',
          labelType: ''
        }}
        accounts={boxOfficeCustomerAccounts}
        onCloseModal={() => setConcessionModalOpen(false)}
        onClickedContinue={onClickedContinueForConcessionModal}
      />
      <CreateEventModal
        type={MODAL_TYPE.SPONSORSHIP}
        open={sponsorshipModalOpen}
        content={{
          title: 'Create a sponsorship',
          labelSchool: 'You have multiple schools selected. Which school’s is this?',
          labelType: ''
        }}
        accounts={accounts}
        onCloseModal={() => setSponsorshipModelOpen(false)}
        onClickedContinue={onClickedContinueForSponsorshipModal}
      />
      {isNewFlatfileUnicorn && renderFlatfilePortal()}
      <BulkUploadModal
        open={openBulkUploadModal}
        onBulkCreateEvents={_onBulkCreateEvents}
        onBulkEditEvents={_onBulkEditEvents}
        onCancel={() => setOpenBulkUploadModal(false)}
      />
    </SectionInfo>
  );

  function _onBulkCreateEvents() {
    if (isNewFlatfileUnicorn) {
      onOpenSpace(
        {
          workbookType: WorkbookType.EVENTS_CREATION,
          metadata: { activities, levels, featureFlags: { enableDistrictUnicorn } }
        },
        setBulkUploadData
      );
    } else {
      importEventCreationDataFromUser(setBulkUploadData);
    }
  }

  function _onBulkEditEvents() {
    if (isNewFlatfileUnicorn) {
      onOpenSpace({ workbookType: WorkbookType.EVENTS_EDITING, metadata: { activities, levels } }, setBulkUploadData);
    } else {
      importEventEditingDataFromUser(setBulkUploadData);
    }
  }
};

export default QuickStart;
