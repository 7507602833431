import decodeComponent from './decodeUriComponent';

const strictUriEncode = str =>
  encodeURIComponent(str).replace(
    /[!'()*]/g,
    x =>
      `%${x
        .charCodeAt(0)
        .toString(16)
        .toUpperCase()}`
  );

const encode = (value, options) => {
  if (options.encode) {
    return options.strict ? strictUriEncode(value) : encodeURIComponent(value);
  }

  return value;
};

const encoderForArrayFormat = options => {
  switch (options.arrayFormat) {
    case 'index':
      return (key, value, index) =>
        value === null
          ? [encode(key, options), '[', index, ']'].join('')
          : [
              encode(key, options),
              '[',
              encode(index, options),
              ']=',
              encode(value, options)
            ].join('');
    case 'bracket':
      return (key, value) =>
        value === null
          ? [encode(key, options), '[]'].join('')
          : [encode(key, options), '[]=', encode(value, options)].join('');
    default:
      return (key, value) =>
        value === null
          ? encode(key, options)
          : [encode(key, options), '=', encode(value, options)].join('');
  }
};

const parserForArrayFormat = options => {
  let result;

  switch (options.arrayFormat) {
    case 'index':
      return (key, value, accumulator) => {
        result = /\[(\d*)\]$/.exec(key);

        // eslint-disable-next-line no-param-reassign
        key = key.replace(/\[\d*\]$/, '');

        if (!result) {
          accumulator[key] = value;
          return;
        }

        if (accumulator[key] === undefined) {
          accumulator[key] = {};
        }

        accumulator[key][result[1]] = value;
      };
    case 'bracket':
      return (key, value, accumulator) => {
        result = /(\[\])$/.exec(key);
        // eslint-disable-next-line no-param-reassign
        key = key.replace(/\[\]$/, '');

        if (!result) {
          accumulator[key] = value;
          return;
        }

        if (accumulator[key] === undefined) {
          accumulator[key] = [value];
          return;
        }

        accumulator[key] = [].concat(accumulator[key], value);
      };
    default:
      return (key, value, accumulator) => {
        if (accumulator[key] === undefined) {
          accumulator[key] = value;
          return;
        }

        accumulator[key] = [].concat(accumulator[key], value);
      };
  }
};

export const decode = (value, options) => {
  if (options.decode) {
    return decodeComponent(value);
  }

  return value;
};

const keysSorter = input => {
  if (Array.isArray(input)) {
    return input.sort();
  }

  if (typeof input === 'object') {
    return keysSorter(Object.keys(input))
      .sort((a, b) => Number(a) - Number(b))
      .map(key => input[key]);
  }

  return input;
};

export const extract = input => {
  const queryStart = input.indexOf('?');
  if (queryStart === -1) {
    return '';
  }

  return input.slice(queryStart + 1);
};

export const parse = (input, options) => {
  // eslint-disable-next-line no-param-reassign
  options = Object.assign({ decode: true, arrayFormat: 'none' }, options);

  const formatter = parserForArrayFormat(options);

  // Create an object with no prototype
  const ret = Object.create(null);

  if (typeof input !== 'string') {
    return ret;
  }

  // eslint-disable-next-line no-param-reassign
  input = input.trim().replace(/^[?#&]/, '');

  if (!input) {
    return ret;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const param of input.split('&')) {
    // eslint-disable-next-line prefer-const
    let [key, value] = param.replace(/\+/g, ' ').split('=');

    // Missing `=` should be `null`:
    // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
    value = value === undefined ? null : decode(value, options);
    formatter(decode(key, options), value, ret);
  }

  return Object.keys(ret)
    .sort()
    .reduce((result, key) => {
      const value = ret[key];
      if (
        Boolean(value) &&
        typeof value === 'object' &&
        !Array.isArray(value)
      ) {
        // Sort object keys, not values
        // eslint-disable-next-line no-param-reassign
        result[key] = keysSorter(value);
      } else {
        // eslint-disable-next-line no-param-reassign
        result[key] = value;
      }

      return result;
    }, Object.create(null));
};

export const stringify = (obj, options) => {
  if (!obj) {
    return '';
  }

  // eslint-disable-next-line no-param-reassign
  options = Object.assign(
    {
      encode: true,
      strict: true,
      arrayFormat: 'none'
    },
    options
  );

  const formatter = encoderForArrayFormat(options);
  const keys = Object.keys(obj);

  if (options.sort !== false) {
    keys.sort(options.sort);
  }

  return keys
    .map(key => {
      const value = obj[key];

      if (value === undefined) {
        return '';
      }

      if (value === null) {
        return encode(key, options);
      }

      if (Array.isArray(value)) {
        const result = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const value2 of value.slice()) {
          if (value2 === undefined) {
            // eslint-disable-next-line no-continue
            continue;
          }

          result.push(formatter(key, value2, result.length));
        }

        return result.join('&');
      }

      return `${encode(key, options)}=${encode(value, options)}`;
    })
    .filter(x => x.length > 0)
    .join('&');
};

export const parseUrl = (input, options) => {
  const hashStart = input.indexOf('#');
  if (hashStart !== -1) {
    // eslint-disable-next-line no-param-reassign
    input = input.slice(0, hashStart);
  }

  return {
    url: input.split('?')[0] || '',
    query: parse(extract(input), options)
  };
};
