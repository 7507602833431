import { Button, ComposedModal, ModalBody, ModalFooter, ModalHeader, InlineLoading } from '@gofan/components';

import { addNotification } from '@gofan/utils/toast';

import { useRate } from '@gofan/api';

import './DeleteRateModal.scss';
import ErrorDTO from '@app/api/dto/ErrorDTO';

type DeleteRateModalProps = {
  open: boolean;
  rate: any;
  onCloseModal: () => void;
};

const DeleteRateModal = ({ open, onCloseModal, rate }: DeleteRateModalProps) => {
  const {
    updateRate: { mutate, isLoading }
  } = useRate({
    rateId: rate?.id,
    queryOptions: {
      enabled: false
    }
  });
  return (
    <ComposedModal
      size='md'
      open={open}
      onClose={onCloseModal}
      className='delete-rate-container'
      preventCloseOnClickOutside
    >
      <ModalHeader>
        <div className='gs--productive-heading-03 gs--font-family-sf'>Delete rate</div>
      </ModalHeader>
      <ModalBody hasForm>
        <div className='gs--padding-top__sp3 gs--padding-bottom__sp3 gs--body-short-02 gs--font-family-sf'>
          Please confirm if you would like to delete this rate
        </div>
        <div className='gs--padding-top__sp5 gs--body-short-01 gs--font-family-sf'>
          <b>Rate - </b>
          {rate?.label}
        </div>
      </ModalBody>
      <ModalFooter secondaryButtonText='Cancel'>
        <Button disabled={isLoading} onClick={_onDeleteRate} kind='danger'>
          {isLoading ? <InlineLoading status='active' description='Deleting' /> : 'Delete'}
        </Button>
      </ModalFooter>
    </ComposedModal>
  );
  function _onDeleteRate() {
    mutate(
      { ...rate, status: 'DISABLED' },
      {
        onSuccess: data => {
          addNotification({
            type: 'success',
            message: `${rate.label} successfully deleted.`,
            options: { duration: 7000 }
          });
          onCloseModal();
        },
        onError: (error: any) => {
          addNotification({
            type: 'error',
            message: new ErrorDTO(error).getErrorMessage(),
            options: { duration: 7000 }
          });
        }
      }
    );
  }
};

export default DeleteRateModal;
