// list of user status
export const USER_STATE_ENABLED = 'ENABLED';
export const USER_STATE_PENDING = 'PENDING';

// list roles
export const USER_ROLE_SUPER_ADMIN = 'SUPER_ADMIN';
export const USER_ROLE_SYSTEM_ADMIN = 'SYSTEM_ADMIN';
export const USER_ROLE_SYSTEM_USER = 'SYSTEM_USER';
export const USER_ROLE_SYSTEM_FINANCE_ADMIN = 'SYSTEM_FINANCE_ADMIN';
export const USER_ROLE_LIMITED_SYSTEM_USER = 'LIMITED_SYSTEM_USER';
export const USER_ROLE_ACCOUNT_USER = 'ACCOUNT_USER';
export const USER_ROLE_FAN = 'FAN';
export const USER_ROLE_NONE = 'NONE';

/**
 * List permissions Of ACCOUNT_USER
 */
export const USER_ROLE_EDITOR = 'EDITOR';
export const USER_ROLE_REPORTS = 'REPORTS';
export const USER_ROLE_BOXOFFICE = 'BOXOFFICE';
export const USER_ROLE_FINANCE = 'FINANCE';
export const USER_ROLE_VALIDATOR = 'VALIDATOR';
export const USER_ROLE_INSIDER = 'INSIDER';
export const USER_ROLE_COMMISSIONER_VIEWER = 'COMMISSIONER_VIEWER';
export const USER_ROLE_COMMISSIONER_EDITOR = 'COMMISSIONER_EDITOR';
export const USER_ROLE_COACH = 'COACH';
export const EDIT_SCHOOL_FINANCIAL = 'EDIT_SCHOOL_FINANCIAL';

/**
 * List roles of GofanHQ by desending order.
 */
export const USER_ROLES = [
  USER_ROLE_SUPER_ADMIN,
  USER_ROLE_SYSTEM_FINANCE_ADMIN,
  USER_ROLE_SYSTEM_ADMIN,
  USER_ROLE_SYSTEM_USER,
  USER_ROLE_LIMITED_SYSTEM_USER,
  USER_ROLE_ACCOUNT_USER,
  USER_ROLE_FAN,
  USER_ROLE_NONE
];

export const CONTACT_PRIMARY = 'CONTACT_PRIMARY';
export const CONTACT_FINANCE = 'CONTACT_FINANCE';
export const CONTACT_OTHER = 'CONTACT_OTHER';
export const CONTACT_COACH = 'CONTACT_COACH';

export const CONTACT_TYPE = {
  MAIN: CONTACT_PRIMARY,
  FINANCE: CONTACT_FINANCE,
  OTHER: CONTACT_OTHER,
  COACH: CONTACT_COACH
};

export const CONTACT_TYPE_OPTIONS = {
  CONTACT_PRIMARY: 'Main contact',
  CONTACT_FINANCE: 'Financial contact',
  CONTACT_OTHER: 'Other',
  CONTACT_COACH: 'Coach'
};

export const ACCOUNT_ROLE_OPTIONS: Record<string, string> = {
  BOXOFFICE: 'Box Office',
  REPORTS: 'Event Reporting',
  FINANCE: 'Payment statement',
  EDITOR: 'Editor',
  COACH: 'Coach',
  [USER_ROLE_COMMISSIONER_VIEWER]: 'Commissioner Viewer',
  [USER_ROLE_COMMISSIONER_EDITOR]: 'Commissioner Editor',
  EDIT_SCHOOL_FINANCIAL: 'Managing District Schools'
};

export const INTERNAL_USER_ROLES = [
  USER_ROLE_SUPER_ADMIN,
  USER_ROLE_SYSTEM_FINANCE_ADMIN,
  USER_ROLE_SYSTEM_ADMIN,
  USER_ROLE_SYSTEM_USER,
  USER_ROLE_LIMITED_SYSTEM_USER
];

export const ADMIN_ROLES = [USER_ROLE_SYSTEM_ADMIN, USER_ROLE_SYSTEM_FINANCE_ADMIN, USER_ROLE_SUPER_ADMIN];
