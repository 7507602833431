import { huddleAxiosInstanceProxy } from '../shared/axios';
import { UPDATE_RATE_BY_ID_URI, CREATE_RATE, GET_RATE_BY_ID_URI, RATE_URI } from './rate.endpoints';
import type { RateDTO, RatesPageResponseDTO } from './rate.model';

class RateRepository {
  static fetchRates = (queryStr: string): Promise<RatesPageResponseDTO> =>
    huddleAxiosInstanceProxy.get(`${RATE_URI}${queryStr}`);

  static updateRateById = (rateId: string, rate: Partial<RateDTO>): Promise<RateDTO> =>
    huddleAxiosInstanceProxy.put<any>(UPDATE_RATE_BY_ID_URI.replace('{id}', `${rateId}`), rate);

  static getRateById = (rateId: number, queryStr?: string): Promise<RateDTO> =>
    huddleAxiosInstanceProxy.get<any>(`${GET_RATE_BY_ID_URI.replace('{id}', `${rateId}`)}${queryStr}`);

  static createRate = (body: Partial<RateDTO>) =>
    huddleAxiosInstanceProxy.post<RateDTO, Partial<RateDTO>>(CREATE_RATE, body, undefined, true);
}

export { RateRepository };
