import { Switch, Route } from 'react-router-dom';
import { Suspense, lazy } from 'react';

import { PAGES } from '@config/routes';

const LogInPage = lazy(() =>
  import('./views/LogIn').then(module => ({
    default: module.LogIn
  }))
);

const ForgotPasswordPage = lazy(() =>
  import('./views/ForgotPassword').then(module => ({
    default: module.ForgotPassword
  }))
);

// TODO: these pages / components need to be migrated into the new @auth module
const SignIn = lazy(() => import('../../pages/Root/components/Auth/components/SignIn'));
const CreatePasswordV2 = lazy(() => import('../../pages/Root/components/Auth/components/CreatePasswordV2'));
const CreatePasswordSuccessfully = lazy(
  () => import('../../pages/Root/components/Auth/components/CreatePasswordSucessfully')
);

const Auth = () => (
  <Switch>
    <Route
      exact
      key={PAGES.auth.login.title}
      path={PAGES.auth.login.path}
      render={routeProps => (
        <Suspense fallback={<div />}>
          <LogInPage {...routeProps} />
        </Suspense>
      )}
    />

    <Route
      exact
      key={PAGES.login.forgotPassword.path}
      path={PAGES.login.forgotPassword.path}
      render={routeProps => (
        <Suspense fallback={<div />}>
          <ForgotPasswordPage {...routeProps} />
        </Suspense>
      )}
    />

    <Route
      exact
      key={PAGES.login.successResetPassword.path}
      path={PAGES.login.successResetPassword.path}
      render={routeProps => (
        <Suspense fallback={<div />}>
          <SignIn {...routeProps} mode='successResetPassword' />
        </Suspense>
      )}
    />

    <Route
      exact
      key={PAGES.login.createPassword.path}
      path={PAGES.login.createPassword.path}
      render={routeProps => (
        <Suspense fallback={<div />}>
          <CreatePasswordV2 {...routeProps} />
        </Suspense>
      )}
    />

    <Route
      exact
      key={PAGES.login.createPasswordSuccesfully.path}
      path={PAGES.login.createPasswordSuccesfully.path}
      render={routeProps => (
        <Suspense fallback={<div />}>
          <CreatePasswordSuccessfully {...routeProps} />
        </Suspense>
      )}
    />
  </Switch>
);

export { Auth };
