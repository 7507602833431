import React from 'react';
import { TableToolbarContent, TableToolbar as CarbonTableToolbar } from 'carbon-components-react';

function TableToolbar({ toolbar, rowData, getToolbarProps }) {
  return (
    <CarbonTableToolbar {...getToolbarProps()} aria-label='toolbar'>
      <TableToolbarContent>{toolbar({ rowData })}</TableToolbarContent>
    </CarbonTableToolbar>
  );
}

TableToolbar.defaultProps = {
  rowData: {},
  toolbar: () => null,
  getToolbarProps: () => null
};

export default TableToolbar;
