import React from 'react';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

import styles from './styles';

const StyledFieldLabel = styled.label`
  ${styles.fieldLabel};
`;

const StyledFieldValue = styled.p`
  ${styles.fieldValue};
`;

export default ({ label, value = '', style = null }) => (
  <Form.Field>
    <StyledFieldLabel>{label}</StyledFieldLabel>
    <StyledFieldValue style={style}>{value}</StyledFieldValue>
  </Form.Field>
);
