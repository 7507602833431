import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import root from './root/reducer.ts';
import fanSupport from './pages/FanSupport/reducer.ts';
import events from './pages/Events/reducer.ts';
import accountList from './pages/AccountList/reducer.ts';
import activity from './pages/Activities/reducer.ts';
import level from './pages/Levels/reducer.ts';
import rateInformation from './pages/RateInformation/reducer.ts';
import rate from './pages/Rates/reducer.ts';
import products from './pages/Products/reducer.ts';
import userHistory from './pages/users/reducer.ts';
import authV2 from './pages/Root/components/Auth/reducer.ts';
import eventInformationV2 from './pages/EventInformationV2/reducer.ts';
import eventDetail from './pages/EventDetail/reducer.ts';
import eventLanding from './pages/EventLanding/reducer.ts';
import eventReservedSeating from './pages/EventReservedSeating/reducer.ts';
import rootV2 from './pages/Root/reducer.ts';
import seasonSetup from './pages/SeasonSetup/reducer.ts';
import seasonManagement from './modules/season-management/middleware/reducer.ts';
import promotions from './modules/promotions/middleware/reducer.ts';
import eventIntegrations_V2 from './modules/event-integrations_V2/middleware/reducer.ts';
import seasonRenewal from './modules/season-renewal/middleware/reducer.ts';
import bulkEditEvents from './modules/bulk-edit-events/middleware/reducer.ts';

const reducer = combineReducers({
  root,
  events,
  fanSupport,
  accountList,
  activity,
  level,
  rateInformation,
  rate,
  products,
  userHistory,
  authV2,
  eventInformationV2,
  eventDetail,
  eventLanding,
  eventReservedSeating,
  rootV2,
  seasonSetup,
  seasonManagement: persistReducer({ key: 'seasonManagement', storage }, seasonManagement),
  promotions: persistReducer({ key: 'promotions', storage }, promotions),
  eventIntegrations_V2,
  seasonRenewal: persistReducer({ key: 'seasonRenewal', storage }, seasonRenewal),
  bulkEditEvents: persistReducer({ key: 'bulkEditEvents', storage }, bulkEditEvents)
});

export default reducer;
