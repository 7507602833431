export default (target, ...keys) => {
  let dug = target;
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    if (typeof dug === 'undefined' || dug === null) {
      return undefined;
    }
    dug = dug[key];
  }
  return dug;
};
