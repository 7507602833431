import { css } from 'styled-components';

export default {
  wrapper: css``,
  bodyWrapper: css`
    padding-top: 75px;
    min-height: 100vh;
    z-index: 700;
    padding-left: 16px;
    padding-right: 16px;
  `
};
