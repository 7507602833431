import defaultSchoolIcon from '@assets/images/gofan-icon.png';
import { getAccountLogo } from '@accounts/v2/utils';
import { useCallback } from 'react';

export type SchoolLogoProps = {
  id: string;
  logo: string;
  width: number;
  height?: number;
};

export function SchoolLogo({ id, logo, width, height }: SchoolLogoProps) {
  const onError = useCallback(e => {
    e.currentTarget.src = defaultSchoolIcon;
    e.currentTarget.onerror = null;
  }, []);

  return (
    <img src={getAccountLogo(id, logo)} alt='School logo' onError={onError} width={width} height={height || width} />
  );
}
