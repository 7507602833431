import BaseDTO from './BaseDTO';

// @flow
class ProductTypeDTO extends BaseDTO {
  constructor({ id, name }) {
    super({
      id,
      name
    });
  }

  get id() {
    return this.raw.id;
  }

  get name() {
    return this.raw.name;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name
    };
  }
}

export default ProductTypeDTO;
