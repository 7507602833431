export const CREATE_SEASON_RENEWAL = '/seasonRenewal/upload';
export const UPDATE_SEASON_RENEWAL_BY_ID = '/seasonRenewal/{id}';
export const GET_SEASON_RENEWAL_SEATS = '/seasonRenewal/seats';
export const UPDATE_SEASON_RENEWAL_FAN = '/season-renewal-fans/{id}';
export const DELETE_SEASON_RENEWAL_FAN = '/season-renewal-fans/{id}';
export const SEASON_RENEWAL_FAN_EXPORT = '/jobs/seasonRenewalFan/export/';
export const GET_SEASON_RENEWALS = '/seasonRenewal';

// query keys
export const SEASON_RENEWALS_QUERY_KEY = 'season-renewals';
export const SEASON_RENEWAL_SEATS_QUERY_KEY = 'season-renewal-seats';
