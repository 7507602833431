import React from 'react';
import classNames from 'classnames';
import { Row, Column } from 'carbon-components-react';

type FieldProps = {
  title: string;
  htmlFor?: string;
  className?: string;
  children?: any;
  onClick?: () => void;
}

const FormField = ({
  title,
  htmlFor,
  children,
  className,
  ...rest
}: FieldProps) => (
  <Row {...rest} className={classNames('field-form', className)}>
    <Column>
      <Row>
        <Column>
          {htmlFor ? (
            <label htmlFor={htmlFor} className='field-label'>
              {title}
            </label>
          ) : (
            <div className='field-label'>
              {title}
            </div>
          )}
        </Column>
      </Row>
      <Row>
        <Column>{children}</Column>
      </Row>
    </Column>
  </Row>
);

export default FormField;
