import * as yup from 'yup';

import { US_PHONE_NUMBER_REGEX } from '@gofan/utils/validate';

export const nameSchema = yup
  .string()
  .required('Please fill out name')
  .max(50, 'Name is too long. Consider shortening it.');
export const emailSchema = yup.string().email('Email must be a valid email').required('Please fill out email');
export const emailSchemaOptional = yup.string().email('Email must be a valid email');
export const phoneNumberSchema = yup
  .string()
  .matches(US_PHONE_NUMBER_REGEX, 'Please add phone number following the format')
  .required('Please add phone number');

export const jerseyNumberSchema = yup
  .number()
  .transform((value, originalValue) => (originalValue === '' ? undefined : Number(originalValue)))
  .max(100, '')
  .required('Please fill out number');
