import React, { useEffect, useCallback, useState, FC, createContext, useContext } from 'react';
import EventService from '@events/services/events.service';
import { isEmpty } from '@utils/objectUtils';
import { get } from 'lodash';
import { MAX_CAPACITY } from '../../constants';

interface EventInsightContextType {
  handleSaveMetrics: Function;
  reset: Function;
  savedMetrics: { saved: any; savedEventId: number };
}

const useEventInsight = (eventId: number, maxCapacity: number) => {
  const [salesAllotment, setSalesAllotment] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState();

  const getSalesInfor = useCallback(async () => {
    try {
      setLoading(true);
      const response = await EventService.getTicketSaleInsightData(eventId);
      let info = get(Object.values(response), '[0].totals', {});
      if (isEmpty(info) && !isEmpty(maxCapacity) && maxCapacity !== MAX_CAPACITY) {
        info = {
          event_tkt_limit: maxCapacity,
          ticket_net_count: 0
        };
      }
      setSalesAllotment(info);
    } catch (e) {
      setErrors(e);
    } finally {
      setLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    if (eventId) {
      getSalesInfor();
    }
  }, [getSalesInfor, eventId]);

  return {
    salesAllotment,
    loading,
    setSalesAllotment,
    getSalesInfor
  };
};

export const EventInsightContext = createContext<EventInsightContextType | undefined>(undefined);

export const EventInsightProvider: FC = ({ children }) => {
  const [savedMetrics, setSavedMetrics] = useState<{ saved: any; savedEventId: number } | Object>({});

  const handleSaveMetrics = (data: any) => {
    setSavedMetrics(prevState => ({ ...prevState, ...data }));
  };

  const reset = () => {
    setSavedMetrics({});
  };

  return (
    <EventInsightContext.Provider
      value={{
        handleSaveMetrics,
        reset,
        savedMetrics
      }}
    >
      {children}
    </EventInsightContext.Provider>
  );
};

export const useEventInsightContext = () => {
  const context = useContext(EventInsightContext);
  if (!context) {
    throw new Error('useEventInsightContext must be set up');
  }

  return context;
};

export default useEventInsight;
