import { formatCurrency } from '../../utils/number';

export const MAX_CAPACITY = 9999999;

export const MIN_EVENT_TICKET_PRICE = 0;

export const MIN_CONCESSION_ITEM_PRICE = 0;

export const MAX_EVENT_TICKET_PRICE = 250;

export const MAX_ADMIN_TICKET_FEE = 9999;

export const MAX_EVENT_DONATION_PRICE = 1000;

export const DISTRIBUTION_CHANNEL = {
  GOFAN: 'GoFan',
  BOXOFFICE: 'BoxOffice'
};

export const COMPLIMENTARY_TICKET = 'Complimentary Ticket';

export const COMPLIMENTARY_PASS = 'Complimentary Pass';

export const TICKET_FEE = {
  MIN: 0,
  MAX: 250
};

export const TICKET_LIMIT_PER_ORDER = {
  MIN: 1,
  MAX: 150
};

export const PACK_COUNT = {
  MIN: 2,
  MAX: 200
};

export const REDEMPTION_LIMIT = {
  MIN: 2,
  MAX: 99999
};

export const PRODUCT_TYPE = {
  TICKET: 'TICKET',
  DONATION: 'DONATION',
  DONATION_ONGOING: 'DONATION_ONGOING',
  MOBILEPASS: 'MOBILEPASS',
  CONCESSION: 'CONCESSION'
};

export const EVENT_TICKET_TYPE = {
  GENERAL_ADMISSION: 'General admission',
  RESERVED_SEATING: 'Reserved'
};

export const OMIT_BOX_OFFICE_PRODUCT_FIELDS = [
  'id',
  'customColor',
  'limit',
  'generateLink',
  'encodedString',
  'fee',
  'packCount',
  'redemptionLimit',
  'unlimitedPass',
  'enabledPackCount',
  '_links'
];

export const REQUIRED_FIELDS_OF_PRODUCT = [
  'id',
  'name',
  'enabled',
  'eventId',
  'seasonId',
  'accountId',
  'groupId',
  'distributionChannel'
];

export const ALLOWED_FIELDS_FOR_SOLD_PRODUCT = [
  'id',
  'eventId',
  'seasonId',
  'accountId',
  'groupId',
  'distributionChannel',
  'enabled',
  'limit',
  'generateLink',
  'reservedSeating',
  'customColor',
  'salesStartDateTime',
  'salesEndDateTime',
  'ticketLimitPerOrder',
  'redemptionLimit',
  'formId',
  'formFields'
];

export const OPTIONAL_TICKET_FIELDS = [
  'salesStartDate',
  'salesStartTime',
  'salesEndDate',
  'salesEndTime',
  'fee',
  'ticketLimitPerOrder',
  'unlimitedPass',
  'redemptionLimit',
  'enabledPackCount',
  'packCount'
];

export const MIN_EVENT_LIMIT = 1;
export const MAX_EVENT_LIMIT = 9999999;
export const MIN_PRICE_ACCOUNT_TICKET = 0;
export const MAX_PRICE_ACCOUNT_TICKET = 250;
export const MAX_PRICE_INTERNAL_ADMIN = 9999999;
export const MAX_PRICE_SUPER_ADMIN = 9999999;
export const MIN_TICKET_FEE = 0;
export const MAX_TICKET_FEE = 250;
export const MIN_TICKET_LIMIT_PER_ORDER = 1;
export const MAX_TICKET_LIMIT_PER_ORDER = 150;
export const MIN_PACK_COUNT = 2;
export const MAX_PACK_COUNT = 200;
export const MIN_REDEMPTION_LIMIT = 2;
export const MAX_REDEMPTION_LIMIT = 99999;
export const MAXIMUM_TICKET_NAME_CHARACTERS = 35;
export const TICKET_ACCOUNT = {
  MIN_PRICE_ACCOUNT_TICKET,
  MAX_PRICE_ACCOUNT_TICKET,
  MAX_PRICE_INTERNAL_ADMIN,
  MAX_PRICE_SUPER_ADMIN,
  MIN_EVENT_LIMIT,
  MAX_EVENT_LIMIT,
  MIN_TICKET_FEE,
  MAX_TICKET_FEE,
  MAX_ADMIN_TICKET_FEE,
  MIN_TICKET_LIMIT_PER_ORDER,
  MAX_TICKET_LIMIT_PER_ORDER,
  MIN_PACK_COUNT,
  MAX_PACK_COUNT,
  MIN_REDEMPTION_LIMIT,
  MAX_REDEMPTION_LIMIT,
  ERRORS: {
    MIN_MAX_TICKET_PRICE: `Price must be from ${formatCurrency(MIN_PRICE_ACCOUNT_TICKET)} to ${formatCurrency(
      MAX_ADMIN_TICKET_FEE
    )}.`,
    MIN_MAX_TICKET_FEE: `Fee must be from ${formatCurrency(MIN_TICKET_FEE)} to ${formatCurrency(MAX_TICKET_FEE)}.`,
    MIN_MAX_ADMIN_ROLE_TICKET_FEE: `Fee must be from ${formatCurrency(MIN_TICKET_FEE)} to ${formatCurrency(
      MAX_ADMIN_TICKET_FEE
    )}.`,
    MIN_MAX_PACK_COUNT: `Family Pass should be between ${MIN_PACK_COUNT} and ${MAX_PACK_COUNT}.`,
    MIN_MAX_REDEMPTION_LIMIT: `You can enter a number between ${MIN_REDEMPTION_LIMIT} and ${MAX_REDEMPTION_LIMIT}.`,
    TICKET_NAME_ERROR_MSG:
      'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (`()./_-#\'@&*+",:;?!) in between 2-40 characters long.'
  }
};

export const BOX_OFFICE_PRODUCT_TYPES = [
  PRODUCT_TYPE.TICKET,
  PRODUCT_TYPE.DONATION,
  PRODUCT_TYPE.DONATION_ONGOING,
  PRODUCT_TYPE.MOBILEPASS
];

export const OPTIONAL_SETTINGS_TYPES = [PRODUCT_TYPE.TICKET, PRODUCT_TYPE.MOBILEPASS];

export const COMP_TICKETS_LIMIT = {
  MIN: 1,
  MAX: 150
};
