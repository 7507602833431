import { useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { useRate } from './useRate';
import { useRates } from './useRates';

const initialSearchParams = {
  pageIndex: 0,
  pageSize: 10,
  exceptions: [],
  cashless: '',
  keyword: '',
  productTypeIds: '',
  sortBy: [{ id: 'startDate', desc: false }],
  status: ['ENABLED'],
  expands: ['product-types']
};

interface UseRateTableConfigurationOptions {
  newRateId: number;
}

export const useRateTableConfiguration = ({ newRateId }: UseRateTableConfigurationOptions) => {
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [selectedRate, setSelectedRate] = useState(null);
  const [toggleDeleteModal, setToggleDeleteModal] = useState<boolean>(false);
  const shouldMoveNewRateOnTop = useRef(!!newRateId);

  const { data: newRate } = useRate({
    rateId: newRateId,
    expands: ['product-types'],
    queryOptions: { enabled: !!newRateId }
  });

  const { data: ratesData, isLoading: isLoadingRatesData } = useRates({
    searchParams,
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: newRateId ? !isEmpty(newRate) : true,
      select: data => {
        let ratesContent = [...data.content];
        if (shouldMoveNewRateOnTop.current && !isEmpty(newRate)) {
          const found = ratesContent.find(item => item.id === newRate.id);
          const filteredRatesContent = !isEmpty(found)
            ? ratesContent.filter(item => item.id !== found.id)
            : ratesContent.splice(0, ratesContent.length - 1);
          ratesContent = [newRate, ...filteredRatesContent];
        }
        return {
          ...data,
          content: ratesContent
        };
      }
    }
  });

  useEffect(() => {
    if (!isEqual(searchParams, initialSearchParams)) {
      shouldMoveNewRateOnTop.current = false;
    }
  }, [searchParams]);

  const resetSearchParams = () => {
    setSearchParams(initialSearchParams);
  };

  return {
    searchParams,
    setSearchParams,
    selectedRate,
    setSelectedRate,
    toggleDeleteModal,
    setToggleDeleteModal,
    ratesData,
    isLoadingRatesData,
    resetSearchParams
  };
};
