import axios from 'axios';
import qs from 'querystring';
import { huddleAxiosInstanceProxy } from '../shared/axios';
import {
  COMPLETE_GAME,
  SEARCH_GAME,
  SEARCH_NFHS_SCHOOL,
  SEARCH_PIXELLOTS_BY_PUBLISHER,
  VIDEO_IMPRESSIONS,
  VIEWS_PER_HOUR
} from './unity.endpoints';
import type {
  GameDTO,
  LiveViewsPerHourResponse,
  NFHSSchoolDTO,
  PixellotDTO,
  SearchGame,
  SearchLiveViewsPerHour,
  SearchNFHSSchool,
  SearchVideoImpressions,
  UnityPageResponse,
  VideoImpressionsResponse
} from './unity.model';

export class UnityRepository {
  static searchNFHSSchool = (params: SearchNFHSSchool) =>
    huddleAxiosInstanceProxy.get<UnityPageResponse<NFHSSchoolDTO>>(
      SEARCH_NFHS_SCHOOL,
      {
        ...huddleAxiosInstanceProxy.unityConfig,
        params
      },
      true
    );

  static searchGame = (params: SearchGame) =>
    huddleAxiosInstanceProxy.get<UnityPageResponse<GameDTO>>(
      SEARCH_GAME,
      {
        ...huddleAxiosInstanceProxy.unityConfig,
        params,
        paramsSerializer: query => {
          const stringifiedQuery =
            (params.key ?? []).length > 1 ? qs.stringify(query).replaceAll('key', 'key[]') : qs.stringify(query);
          return stringifiedQuery;
        }
      },
      true
    );

  static searchLiveViewsPerHour = (params: SearchLiveViewsPerHour) =>
    axios
      .get<LiveViewsPerHourResponse>(VIEWS_PER_HOUR.replace('{event_id}', params.event_id), {
        params
      })
      .then(response => response.data);

  static searchVideoImpressions = (params: SearchVideoImpressions) =>
    axios
      .get<VideoImpressionsResponse>(VIDEO_IMPRESSIONS.replace('{event_id}', params.event_id), {
        params
      })
      .then(response => response.data);

  static completeGame = (key: string) =>
    huddleAxiosInstanceProxy.get<void>(COMPLETE_GAME.replace('{key}', key), undefined, true);

  static searchPixellotsByPublisherKey = (publisherKey: string) =>
    huddleAxiosInstanceProxy.get<{ pixellots: PixellotDTO[] }>(
      SEARCH_PIXELLOTS_BY_PUBLISHER.replace('{publisherKey}', publisherKey),
      undefined,
      true
    );
}
