import { isEmpty, omit, lowerCase } from 'lodash';

import { getUniqueId } from '@utils/objectUtils';

import type { AccountDTO } from '@gofan/api/accounts';
import type { ActivityDTO } from '@gofan/api/activities';
import { TIME_ZONE_ENUM, TIME_ZONE_MAPPING } from '@utils/dateUtils';
import type { CreatingSeason } from '@season-management/middleware/models/creating-season.model';
import type { RawEvent } from '@season-management/middleware/models/raw-event.model';
import RawEventModel from '@season-management/middleware/models/raw-event.model';
import type { RawProduct } from '@season-management/middleware/models/raw-product.model';
import RawProductModel from '@season-management/middleware/models/raw-product.model';
import type { SeasonWithEvent } from '@season-management/middleware/models/season-with-event.model';
import SeasonWithEventModel from '@season-management/middleware/models/season-with-event.model';
import SeasonCreationUtils from '../../utils/season-creation.utils';

export default class MultiSeasonCreateRequest {
  contents: SeasonWithEventModel[];

  account: AccountDTO;

  activity: ActivityDTO;

  creatingSeason: CreatingSeason;

  seasonSettings: RawEvent[] = [];

  requestId = getUniqueId();

  constructor(contents?: any, config?: any) {
    this.account = { ...(config?.account ?? {}) } as AccountDTO;
    this.activity = { ...(config?.activity ?? {}) } as ActivityDTO;
    this.creatingSeason = { ...(config?.creatingSeason ?? {}) } as CreatingSeason;
    this.contents = [...(contents ?? [])] as SeasonWithEventModel[];
  }

  toJSON() {
    return [...this.contents].map(content => {
      const season = content.toJSON();
      const seasonSetting = (this.seasonSettings ?? []).find(item => `${item.id}` === `${season.id}`);

      return {
        ...season,
        id: undefined,
        config: {
          seasonSettings: JSON.stringify(
            omit(seasonSetting ?? {}, ['id', 'events', 'account', 'activity', 'startDateTime', 'endDateTime'])
          )
        },
        seasonalPeriod: this?.creatingSeason?.seasonalPeriod,
        events: season?.events?.map(event => {
          const defaultNullOppAccountId = event.created ? '' : null;
          const opponentAccountId = isEmpty(event.opponentAccountId)
            ? defaultNullOppAccountId
            : event.opponentAccountId;
          return {
            ...omit(event, ['created', 'gofanEventIdExist', 'dateTimeDuration']),
            id: event.created ? event.id : undefined,
            glCode: season.glCode, // populate all events with season glcode at season creation
            venueAddress: event.venueAddress || season.venueAddress,
            venueCity: event.venueCity || season.venueCity,
            venueLocation: event.venueLocation || season.venueLocation,
            venueName: event.venueName || season.venueName,
            venueState: event.venueState || season.venueState,
            venueId: event.venueId || season.venueId,
            venueZip: event.venueZip || season.venueZip,
            eventIntegrationDetails: event.eventIntegrationDetails,
            opponentAccountId,
            products: event?.products?.map(product => ({
              ...omit(product, ['created']),
              id: product.created ? product.id : undefined,
              accountId: product.created ? product.accountId : null
            }))
          };
        })
      } as SeasonWithEvent;
    });
  }

  populate() {
    const { rawSeasons, accountTicketTypes, eventsArchived } = this.creatingSeason ?? {};
    const timeZone = TIME_ZONE_MAPPING[lowerCase(this.account?.timeZone)] ?? TIME_ZONE_ENUM.AMERICA_NEW_YORK;
    (rawSeasons ?? []).forEach(rawSeason => {
      const season = new SeasonWithEventModel({}).populate({
        account: this.account,
        activity: this.activity,
        rawSeason
      });
      let settingProducts: RawProduct[] = [];

      const rawSeasonDefaultTickets = SeasonCreationUtils.filterAccountTicketTypesBy({
        accountTicketTypes,
        genders: rawSeason.genders,
        levelsByGender: rawSeason.levelsByGender,
        activityId: rawSeason.sportId
      });

      if (rawSeasonDefaultTickets?.length > 0) {
        rawSeasonDefaultTickets.forEach(ticket => {
          settingProducts.push(
            new RawProductModel({}).setByAccountTicketType(ticket).setOptionalSetting(ticket).toJSON()
          );
        });
      } else {
        settingProducts = rawSeason?.rawEvents?.[0]?.products ?? [];
      }

      const seasonSetting = new RawEventModel({
        ...season.toJSON(),
        events: undefined,
        products: settingProducts,
        startTime: rawSeason.startTime,
        archived: !!eventsArchived,
        publishDateTime: null,
        alert: rawSeason.alert
      })
        .setSeasonStartTimeOptions({
          startTime: rawSeason.startTime,
          startTimeTeams: rawSeason.startTimeTeams,
          eventStartTimeType: rawSeason.eventStartTimeType,
          startTimeOptions: rawSeason.startTimeOptions
        })
        .setByActivity(this.activity)
        .setOptionalTicketSetting(timeZone)
        .toJSON();
      this.seasonSettings.push(seasonSetting);
      this.contents.push(season);
    });

    return this;
  }
}
