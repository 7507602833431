import { useQuery } from '@tanstack/react-query';

import { UnityService } from './unity.service';

import { NFHS_SCHOOL_QUERY_KEY } from './unity.endpoints';

import type { SearchNFHSSchool, NFHSSchoolDTO } from './unity.model';

export interface UseNFHSSchoolOptions {
  params: SearchNFHSSchool;
  queryOptions?: {
    cacheTime?: number;
    staleTime?: number;
    enabled?: boolean;
  };
}

export const useNFHSSchool = ({ params, queryOptions }: UseNFHSSchoolOptions) => {
  const query = useQuery({
    queryKey: [NFHS_SCHOOL_QUERY_KEY, params.internal, params.search_term],
    queryFn: () => UnityService.searchAndMappingNFHSSchool(params),
    cacheTime: 24 * 60 * 60 * 1000, // = 1 day
    staleTime: 24 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    ...queryOptions
  });

  return { ...query, data: query.data as NFHSSchoolDTO };
};
