// @flow
import { isEmpty } from 'lodash';

import * as fanSupportDAO from '../dao/FanSupportDAO';
import RefundedTicketsRequest from '../model/request/RefundedTicketsRequest';
import { getStorageItem } from '@gofan/utils/local-storage';

export const searchPurchasedTickets = (searchRequest, pageRequest) =>
  fanSupportDAO.searchPurchasedTickets(searchRequest, pageRequest);

export const groupedTicketsByOrderAccessToken = (purchasedTickets, asArray = false) => {
  const obj = purchasedTickets.reduce((acc, cur) => {
    const { orderAccessToken } = cur;
    if (!acc[orderAccessToken]) {
      acc[orderAccessToken] = [];
    }
    acc[orderAccessToken].push(cur);
    return acc;
  }, {});

  const arr = Object.entries(obj).map(([key, val]) => ({
    orderAccessToken: key,
    tickets: val
  }));

  return asArray ? arr : obj;
};

export const refundPurchasedTickets = groupedTickets => {
  const refundTickets = groupedTickets.tickets;
  const amount = refundTickets.reduce((acc, cur) => acc + Number.parseInt(Math.round(cur.total * 100), 10), 0);
  const charge = isEmpty(refundTickets) ? '' : refundTickets[0].chargeId;
  const reason = RefundedTicketsRequest.REQUESTED_BY_CUSTOMER;
  const purchasedTicketsAccessTokens = refundTickets.map(purchasedTicket => purchasedTicket.accessToken);

  return fanSupportDAO.refundPurchasedTickets(
    new RefundedTicketsRequest({
      accessToken: groupedTickets.orderAccessToken,
      purchasedTicketsAccessTokens,
      amount,
      charge,
      reason
    })
  );
};

export const getRefundedTickets = (refundTransaction, purchasedTickets) => {
  const allRefundedAccessTokens = refundTransaction.reduce((acc, cur) => {
    const {
      metadata: { purchasedTicketsAccessTokens }
    } = cur;
    acc.push(...purchasedTicketsAccessTokens.split(','));
    return acc;
  }, []);
  return purchasedTickets.filter(ticket => allRefundedAccessTokens.includes(ticket.accessToken));
};

export const refundByEvent = event => fanSupportDAO.refundByEvent(event);

export const searchSeasonPassPurchasedTickets = (searchRequest, pageRequest) =>
  fanSupportDAO.searchSeasonPassPurchasedTickets(searchRequest, pageRequest);

export const refundSeasonPassPurchasedTickets = (groupedTicket, selectedSeasonPass) => {
  const refundTickets = groupedTicket.tickets;
  const amount = selectedSeasonPass
    .filter(sp => sp.orderAccessToken === groupedTicket.orderAccessToken)
    .reduce((total, sp1) => total + Number.parseInt(Math.round((sp1?.chargeTotalAmount ?? 0) * 100), 10), 0);
  const charge = isEmpty(refundTickets) ? '' : refundTickets[0].chargeId;
  const reason = RefundedTicketsRequest.REQUESTED_BY_CUSTOMER;
  const purchasedTicketsAccessTokens = refundTickets.map(purchasedTicket => purchasedTicket.accessToken);
  /** Currently amount = first ticket charge total amount.
   * for future reference: if partial season pass refunds need to be enabled in future,
   * amount should be calculated as price + fee - discount for each individual
   * ticket that is being refunded, and the values aggregated to compute the
   * amount that needs to be passed to the Stripe refund API.
   */
  return fanSupportDAO.refundPurchasedTickets(
    new RefundedTicketsRequest({
      accessToken: groupedTicket.orderAccessToken,
      purchasedTicketsAccessTokens,
      amount,
      charge,
      reason
    })
  );
};

// pre call to the Order Service
export const refundRequestByTicket = (groupedTickets, additionalRefundInfo) => {
  const purchasedTicketAccessTokens = groupedTickets.tickets.map(purchasedTicket => purchasedTicket.accessToken);
  const user = getStorageItem('currentUser');

  return fanSupportDAO.refundRequestOrderService({
    purchasedTicketAccessTokens,
    orderAccessToken: groupedTickets.orderAccessToken,
    createdBy: user.email,
    requestorType: additionalRefundInfo.requestorType ?? '',
    refundReason: additionalRefundInfo.refundReason ?? ''
  });
};

// pre call to the Order Service
export const refundRequestByEvent = (event, additionalRefundInfo) => {
  const user = getStorageItem('currentUser');

  return fanSupportDAO.refundRequestOrderService({
    eventId: event.id,
    createdBy: user.email,
    requestorType: additionalRefundInfo.requestorType ?? '',
    refundReason: additionalRefundInfo.refundReason ?? ''
  });
};

export const refundRequestDeleteById = refundRequest => fanSupportDAO.refundRequestDeleteOrderService(refundRequest.id);

export default {
  searchPurchasedTickets,
  searchSeasonPassPurchasedTickets,
  refundPurchasedTickets,
  getRefundedTickets,
  refundSeasonPassPurchasedTickets,
  refundRequestByTicket,
  refundRequestByEvent,
  refundRequestDeleteById,
  groupedTicketsByOrderAccessToken
};
