import React from 'react';
import { Button, Menu, Popup, Table, Grid, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import capitalizePhrase from '../utils/capitalizePhrase';
import { ThrottledSearch } from '../throttled-search';

const Actions = id => (
  <Menu text vertical inverted>
    <Menu.Item name='view' as={Link} to={id} />
    <Menu.Item name='edit' as={Link} to={id} />
  </Menu>
);

export default class FastTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderedData: props.data || [],
      sortColumn: '',
      searchQuery: ''
    };

    this.handleSearchResult = this.handleSearchResult.bind(this);
    this.handleSearchQueryChange = this.handleSearchQueryChange.bind(this);
    this.searchFunction = props.searchFunction;
    this.columns = props.columns || [];
    this.data = props.data || [];
    this.throttledSearch = new ThrottledSearch(
      query => this.searchFunction(query),
      this.handleSearchResult
    );
    this.handleSort = this.handleSort.bind(this);
  }

  //  handleSort(col) {
  //   console.log('setup?');
  //   if (col === sortColumn) {
  //     sortDirection === 'ASC'
  //       ? setSortDirection('DESC')
  //       : setSortDirection('ASC');
  //   } else {
  //     setSortColumn(col);
  //     setSortDirection('DESC');
  //   }
  // };

  handleSearchQueryChange = ({ target: { value } }) => {
    this.setState({ searchQuery: value }, () =>
      this.throttledSearch.setQuery(value)
    );
  };

  handleSearchResult(results) {
    results !== undefined && this.setState({ orderedData: results });
  }

  render() {
    const { searchQuery, sortColumn, orderedData } = this.state;
    return (
      <div>
        <Grid stackable>
          <Grid.Column width={5}>
            <Input
              focus
              fluid
              icon='search'
              iconPosition='left'
              placeholder='Search by Name or Mascot...'
              value={searchQuery}
              onChange={this.handleSearchQueryChange}
            />
          </Grid.Column>
          <Grid.Column width={9} />
          <Grid.Column width={2}>
            <Input fluid placeholder='Sort Column' value={sortColumn} />
          </Grid.Column>
        </Grid>
        <Table sortable celled compact definition>
          <Table.Header fullWidth>
            <Table.Row>
              {this.columns.map(col => (
                <Table.HeaderCell
                  key={col}
                  onClick={() => this.handleSort(col)}
                >
                  {capitalizePhrase(col)}
                </Table.HeaderCell>
              ))}
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {orderedData.map(row => (
              <Table.Row key={row.id}>
                {this.columns.map(col => (
                  <Table.Cell key={col}>{row[col]}</Table.Cell>
                ))}
                <Table.Cell>
                  <Popup
                    inverted
                    trigger={<Button circular icon='ellipsis horizontal' />}
                    content={() => <Actions id={row.id} />}
                    on='click'
                    position='top left'
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan={this.columns.length + 1} />
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
