import { useState, useRef, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Icons,
  Button,
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  DataTableWrapper,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectAll,
  TableSelectRow,
  Pagination,
  Tag,
  Link
} from '@gofan/components';
import { Overlay } from '@app/components/Overlay';

import { type AccountDTO, AccountService, useMultipleAccounts } from '@gofan/api/accounts';
import { CANCELLED_REQUEST } from '@app/api/api/constants';
import CacheService from '@app/api/services/CacheService';
import { config } from '@gofan/constants';

import noContent from '@assets/images/sponsorships/sponsorshipAssignmentNoContent.png';

import { searchAccountsMapping } from '@app/modules/dashboard/views/sections/school-selection/school-selection.component';
import type { SearchAccount } from '@app/modules/dashboard/views/sections/school-selection/school-selection.component';
import { SchoolLogo } from '@app/modules/accounts/v2/components';

import './SponsorshipAssignment.scss';
import { CheckmarkFilled16 } from '@carbon/icons-react';
import { useSponsorships } from '@gofan/api/sponsorship/useSponsorships';

const SponsorshipAssignment = () => {
  const { setValue, getValues } = useFormContext();
  const [openOverlay, setOpenOverlay] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchAccount[]>([]);
  const [searching, setSearching] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [searchResultIds, setSearchResultIds] = useState<string[]>([]);
  const [selectedRowsIds, setSelectedRowsIds] = useState<string[]>(getValues('schoolIds') || []);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { data: hasSponsorship } = useSponsorships(searchResultIds) || [];
  const { data: schools } = useMultipleAccounts({ accountIds: getValues('schoolIds') });

  const accountToSearchType = (schools: AccountDTO[]): SearchAccount[] => {
    const results = searchAccountsMapping(schools);
    results.map(result => ({
      ...result,
      gofanUrl: `https://www.gofan.co/app/school/${result.id}`
    }));
    return results;
  };
  const currentSchools = accountToSearchType(schools);

  useEffect(() => {
    if (searchResults?.length === 0 && currentSchools?.length !== 0) {
      setSearchResults(currentSchools);
      setSearchResultIds(currentSchools.map(school => school.id));
    }
  }, [searchResults, currentSchools]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setItemsPerPage(size);
    setCurrentPage(1);
  };

  const searchTimer = useRef<any>(null);
  const isUnmounted = useRef<boolean>(false);

  const headers = [
    {
      key: 'logo',
      header: 'Logo'
    },
    {
      key: 'name',
      header: 'School'
    },
    {
      key: 'city',
      header: 'City'
    },
    {
      key: 'state',
      header: 'State'
    },
    {
      key: 'status',
      header: 'Status'
    },
    {
      key: 'url',
      header: 'URL'
    }
  ];

  function handleTextChange(e: { target: HTMLInputElement }) {
    const searchText = e.target.value;
    if (openOverlay) {
      if (searchText === '') {
        setSearchResults(accountToSearchType(schools));
        setSearchResultIds(schools.map(school => school.id));
        return;
      }
      return handleSearchAccounts(searchText);
    }
    return null;
  }

  const handleButtonClick = () => {
    setValue('schoolIds', selectedRowsIds);
    setOpenOverlay(false);
  };

  const handleSearchAccounts = (value: string) => {
    AccountService.cancelGetAccountsByKeyword();
    clearTimeout(searchTimer.current);
    setSearching(true);
    searchTimer.current = setTimeout(() => {
      const coordinates = CacheService.getCachedData(
        CacheService.USER_COORDINATE_CACHE_NAME,
        CacheService.CURRENT_COORDINATE_CACHE_NAME
      );
      AccountService.getAccountsByKeyword({ keyword: value, coordinates, manualErrorHandling: true })
        .then((res: AccountDTO[]) => {
          const results = accountToSearchType(res);
          // current assigned schools on the top
          const sortedSearchResult = sortSearchResult(results, selectedRowsIds);
          setSearchResults(sortedSearchResult);
          setSearchResultIds(sortedSearchResult.map(school => school.id));
          setSearching(false);
        })
        .catch((err: any) => {
          if (err.message !== CANCELLED_REQUEST) {
            if (!isUnmounted.current) {
              setSearching(false);
            }
          }
        });
    }, config.DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST);
  };

  const sortSearchResult = (searchResults: SearchAccount[], schoolIds: string[]) => {
    // put current selected schools at the top
    const checkedSchools = searchResults.filter(result => schoolIds.includes(result.id));
    const uncheckedSchools = searchResults.filter(result => !schoolIds.includes(result.id));
    return [...checkedSchools, ...uncheckedSchools];
  };

  const handleSearchAll = () => {
    setSearching(true);
    AccountService.getAllAccounts()
      .then((res: AccountDTO[]) => {
        const results = accountToSearchType(res);
        const combineCurrentSchoolsAndSearchAll = [
          ...currentSchools,
          ...results.filter(result => !currentSchools.some(school => school.id === result.id))
        ];
        setSearchResults(combineCurrentSchoolsAndSearchAll);
        setSearchResultIds(combineCurrentSchoolsAndSearchAll.map(school => school.id));
        setSearching(false);
      })
      .catch((err: any) => {
        if (err.message !== CANCELLED_REQUEST) {
          if (!isUnmounted.current) {
            setSearching(false);
          }
        }
      });
  };

  useEffect(() => {
    if (searchResults.length > 0) {
      const searchResultsWithoutSponsorship = searchResults.filter(result => !hasSponsorship?.includes(result.id));

      if (
        selectedRowsIds.length === searchResultsWithoutSponsorship.length &&
        searchResultsWithoutSponsorship.length !== 0
      ) {
        setSelectAll(true);
      }
    } else {
      setSelectAll(false);
    }
  }, [searchResults, selectedRowsIds]);

  return (
    <div className='gf--sponsorship-assignment'>
      <Overlay
        open={openOverlay}
        className='gf--sponsorship-assignment__overlay'
        style={{ backgroundColor: '#fff', top: 0 }}
        containerStyles={{ paddingTop: 0, top: '50px', height: 'calc(100vh - 50px)' }}
      >
        <div className='gf--sponsorship-assignment__overlay-header'>
          <Button
            kind='ghost'
            onClick={() => {
              setOpenOverlay(false);
              setSearchResults(currentSchools);
              setSearchResultIds(currentSchools.map(school => school.id));
            }}
            className='gf-overlay-back-button'
          >
            <Icons.ChevronLeft />
            Back
          </Button>
          <div className='gf-event-title'>
            <h1 className='gf-page-header__title'>Sponsorship Assignment</h1>
          </div>
        </div>
        <div className='gf--sponsorship-assignment__overlay-content'>
          <div className='gf--sponsorship-assignment-table-title'>Assign your sponsorship to school</div>
          <DataTableWrapper>
            <DataTable stickyHeader rows={searchResults} headers={headers}>
              {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => {
                const startIndex = (currentPage - 1) * itemsPerPage;
                const endIndex = startIndex + itemsPerPage;
                const currentRows = rows.slice(startIndex, endIndex);
                return (
                  <TableContainer className='gf--sponsorship-assignment-table-container'>
                    <TableToolbar>
                      <TableToolbarContent>
                        <TableToolbarSearch placeholder='Search by school name' onChange={e => handleTextChange(e)} />
                        <Button
                          kind='primary'
                          onClick={() => handleSearchAll()}
                          className='gf--sponsorship-assignment-table-button cds--btn-centralize'
                        >
                          View All
                        </Button>
                      </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()} className='gf--sponsorship-assignment-table'>
                      <TableHead>
                        <TableRow>
                          <TableSelectAll
                            name='selectAll'
                            id='selectAll'
                            onSelect={() => {
                              setSelectAll(!selectAll);
                              if (selectAll) {
                                setSelectedRowsIds([]);
                              } else {
                                const searchResultsWithoutSponsorship = searchResults.filter(
                                  result => !hasSponsorship?.includes(result.id)
                                );
                                setSelectedRowsIds(searchResultsWithoutSponsorship.map(result => result.id));
                              }
                            }}
                            checked={selectAll}
                            ariaLabel='Select All'
                            disabled={searchResults.length === hasSponsorship?.length}
                          />
                          {headers.map(header => (
                            <TableHeader
                              {...getHeaderProps({ header })}
                              onClick={(event: any) => getHeaderProps({ header }).onClick(event)}
                            >
                              <div className='gf--sponsorship-assignment-table-header'>{header.header}</div>
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows &&
                          currentRows.map((row, i) => (
                            <TableRow
                              {...getRowProps({
                                row
                              })}
                            >
                              {hasSponsorship?.includes(row.id) && !selectedRowsIds?.includes(row.id) ? (
                                <TableSelectRow
                                  disabled
                                  name=''
                                  id=''
                                  checked={false}
                                  onSelect={() => null}
                                  ariaLabel=''
                                />
                              ) : (
                                <TableSelectRow
                                  onSelect={() => {
                                    if (selectedRowsIds.includes(row.id)) {
                                      setSelectedRowsIds(selectedRowsIds.filter(id => id !== row.id));
                                    } else {
                                      setSelectedRowsIds([...selectedRowsIds, row.id]);
                                    }
                                  }}
                                  name=''
                                  id={row.id}
                                  checked={selectedRowsIds.includes(row.id)}
                                  ariaLabel=''
                                />
                              )}

                              {row.cells.map(cell => {
                                const placeholder = '';

                                const cellValue =
                                  cell.value !== undefined && cell.value !== null ? cell.value : placeholder;

                                if (cell.info.header === 'logo') {
                                  return (
                                    <TableCell
                                      key={cell.id}
                                      className=' gf--sponsorship-assignment-table-cell gf--sponsorship-school-logo'
                                    >
                                      <SchoolLogo id={cell.id} logo={cellValue} width={28} height={28} />
                                    </TableCell>
                                  );
                                }

                                if (cell.info.header === 'city') {
                                  return (
                                    <TableCell key={cell.id} className='gf--sponsorship-assignment-table-cell'>
                                      {cellValue
                                        .split(' ')
                                        .map(
                                          (word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                        )
                                        .join(' ')}
                                    </TableCell>
                                  );
                                }

                                if (cell.info.header === 'status') {
                                  if (hasSponsorship?.includes(row.id)) {
                                    return (
                                      <TableCell key={cell.id} className='gf--sponsorship-assignment-table-cell'>
                                        <Tag type='blue'>Sponsorship Assigned</Tag>
                                      </TableCell>
                                    );
                                  }
                                  return (
                                    <TableCell key={cell.id} className='gf--sponsorship-assignment-table-cell'>
                                      <Tag type='gray'>Sponsorship Unassigned</Tag>
                                    </TableCell>
                                  );
                                }

                                if (cell.info.header === 'url') {
                                  const link = searchResults.find(result => result.id === row.id)?.gofanUrl;
                                  const nfhsLink = searchResults.find(result => result.id === row.id)?.nfhsUrl;

                                  return (
                                    <TableCell key={cell.id} className='gf--sponsorship-assignment-table-cell'>
                                      {link ? (
                                        <Link href={link} target='_blank'>
                                          GoFan Link
                                        </Link>
                                      ) : (
                                        placeholder
                                      )}
                                      {nfhsLink && (
                                        <>
                                          ,
                                          <Link href={nfhsLink} target='_blank'>
                                            NFHS Link
                                          </Link>
                                        </>
                                      )}
                                    </TableCell>
                                  );
                                }

                                return (
                                  <TableCell key={cell.id} className='gf--sponsorship-assignment-table-cell'>
                                    {cellValue}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          ))}
                        {rows.length === 0 && currentRows.length === 0 && !searching && (
                          <div className='gf--sponsorship-assignment__overlay-no-content'>
                            <img src={noContent} alt='no content' />
                          </div>
                        )}
                      </TableBody>
                    </Table>
                    {searchResults.length !== 0 && (
                      <Pagination
                        totalItems={searchResults.length}
                        pageSize={itemsPerPage}
                        currentPage={currentPage}
                        pageSizes={[10, 25, 50, 100]}
                        onChange={({ page, pageSize }: { page: number; pageSize: number }) => {
                          handlePageChange(page);
                          if (pageSize !== itemsPerPage) {
                            handlePageSizeChange(pageSize);
                          }
                        }}
                        className='gf--sponsorship-assignment-table-pagination'
                      />
                    )}
                  </TableContainer>
                );
              }}
            </DataTable>
          </DataTableWrapper>
        </div>
        <div className='gf--sponsorship-assignment-table-button-container'>
          <Button className='gf--sponsorship-assignment-table-button cds--btn-centralize' onClick={handleButtonClick}>
            Assign
          </Button>
        </div>
      </Overlay>
      <p className='gs--body-short-02 gs--font-family-sf gs--margin-top-sp5'>
        Assign your sponsor to schools and events
      </p>
      {selectedRowsIds.length > 0 && (
        <div className='gf--sponsorship-assignment__selected-schools'>
          <CheckmarkFilled16 />
          <p>{selectedRowsIds.length} schools assigned</p>
        </div>
      )}

      <Button className='cds--btn-centralize' onClick={() => setOpenOverlay(true)}>
        Add schools +
      </Button>
    </div>
  );
};

export { SponsorshipAssignment };
