import type { SeasonSearchParams } from '@seasons/services/season.service';
import { sortStates } from '@old-components/basic-table/sorting';
import type { SeasonalPeriodType } from '@gofan/api';

export const SEASON_STRING = {
  SEASON_NAME: 'Season name',
  SEASON_CREATED_DATE: 'Season created date',
  SEASON_DATE: 'Season date',
  GENDER: 'Gender',
  SPORT: 'Sport',
  SEASON_ID: 'Season ID',
  LEVEL: 'Level',
  CLOSE: 'Close',
  NO_ASSOCIATED_ACCOUNTS:
    "This is where you will add or edit seasons for your school's GoFan page. Contact your school's GoFan administrator to be added to your account.",
  ADD_SEASON: 'Add season',
  FILTER_BY: 'Filter by',
  EVENTS_UPDATED_MESSAGE: 'Your events are successfully updated',
  EVENTS_PUBLISHED_SUB_TITLE: 'Select an event to the left to view or edit its settings',
  TICKETS_SOLD: 'Tickets Sold',
  TICKET_SALE: 'Ticket Sale'
};

export const ACCOUNT_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export const SEASON_PILL_OPTION = {
  ACCOUNTS: 'accounts',
  ACTIVITIES: 'activities',
  SPECIFIC_DATE: 'specDate',
  START_DATE: 'startDate',
  END_DATE: 'endDate'
};

export const TYPE_FILTER_DATE = {
  START: 'start',
  END: 'end'
};

export const SEARCH_PARAMS: SeasonSearchParams = {
  body: {
    accountIds: [],
    activitiesIds: undefined,
    endingAfterNow: true,
    searchTerm: undefined,
    seasonId: undefined,
    specificDate: undefined,
    startDate: undefined,
    endDate: undefined
  },
  page: 0,
  pageSize: 25,
  sortBy: { sortDirection: sortStates.ASC, header: 'createdAt' }
};

export const SEASON_IMPORT_CONCERN_FIELDS = [
  'id',
  'accountId',
  'activityId',
  'name',
  'genders',
  'levels',
  'createdAt',
  'createdBy',
  'updatedAt',
  'updatedBy',
  'reportingLabel',
  '_embedded.levels.*',
  '_embedded.account.id',
  '_embedded.account.name',
  'totalElements',
  'totalPages'
];

export const EXPAND_SEASON_FIELDS = [
  'levels',
  'account',
  'activity',
  'event-type',
  'season-sales-info',
  'season-events',
  'season-events.levels',
  'season-events.activity',
  'season-events.event-type',
  'season-events.account-opponent',
  'season-events.event-sales-info'
];

export const EXPAND_EVENT_FIELDS = ['account-opponent', 'activity', 'event-sales-info', 'event-type', 'levels'];

export const DISABLE_SETTINGS_FIELDS = {
  TICKET_TYPE: {
    addTicketType: false,
    maxCapacity: false,
    unlimitedTicket: false,
    products: {
      created: true,
      notCreated: false
    },
    seasonTickets: {
      created: false,
      notCreated: false
    }
  },
  EVENT_TYPE: {
    eventType: true,
    activity: true,
    specificGender: true,
    boyGender: true,
    girlGender: true,
    eitherGender: true,
    eitherLevel: true,
    girlLevel: true,
    boyLevel: true
  },
  EVENT_START_TIME: {
    startTime: false,
    allDayEvent: false
  },
  VENUE: {},
  EVENT_VISIBILITY: {},
  EVENT_NAME: {},
  EVENT_DATE_TIME: {},
  GATE_OPENING_TIME: {},
  BUYER_PURCHASE_LIMIT: {},
  EVENT_ALERT: {},
  ADDITIONAL_FORM: {}
};

export const NOTIFICATION_TIME_OUT = 10000;
export const WINDOW_SCROLL_DELAYS = 1000;
export const MAX_CAPACITY = 9999999;

export default {
  SEASON_STRING,
  ACCOUNT_STATUS,
  SEASON_PILL_OPTION,
  SEARCH_PARAMS,
  EXPAND_SEASON_FIELDS,
  DISABLE_SETTINGS_FIELDS,
  TYPE_FILTER_DATE
};

export const ORDER_SEASONAL_PERIODS: SeasonalPeriodType[] = ['Spring', 'Summer', 'Fall', 'Winter'];

export const SEASON_STATUS = {
  ACTIVE: 1,
  FUTURE: 2,
  PAST: 3
};

export const VIEW_SEASON_TAB_KEYS = {
  SEASON_SUMMARY: 'page=SEASON_SUMMARY',
  SEASON_STATISTICS: 'page=SEASON_STATISTICS',
  SEASON_MANAGEMENT: 'page=SEASON_MANAGEMENT',
  SEASON_TICKETS: 'page=SEASON_TICKETS',
  SEASON_TICKETS_OVERLAY_MODAL: 'modal=SEASON_TICKETS_OVERLAY_MODAL',
  ADD_NEW_EVENT_MODAL: 'modal=ADD_NEW_EVENT_MODAL',
  ADDITIONAL_INFORMATION: 'modal=ADDITIONAL_INFORMATION'
};

export const SUBTRACT_YEAR_START = 5; // Defined subtract year start, 4 mean 4 years ago in dayjs
export const SCHOOL_YEAR_START_MONTH = 5; // Defined school year start month, 5 mean June in dayjs
