import { isString, isEmpty } from 'lodash';
import React, { useContext, useEffect, useCallback } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { FastFormContext } from './FastFormBase';
import { maxLengthValidation } from '../../validations';
import { executeValidationRules } from '../../validator';
import { StyledFormInput } from '../../components/styledSemantic';
import styles from './styles';

const StyledFieldLabel = styled.label`
  ${styles.baseInput.fieldLabel};
`;

const StyledErrorMessage = styled.p`
  ${styles.baseInput.errorMessage};
`;

const StyledOptionalLabel = styled.span`
  ${styles.baseInput.optionalLabel};
`;

const StyledDescription = styled.p`
  ${styles.baseInput.optionalLabel};
`;

const getValue = (value, formVal) => {
  if (formVal instanceof Function) {
    return formVal(value);
  }

  return value;
};

export default ({
  className,
  label = 'PUT A LABEL HERE',
  type = 'text',
  formKey,
  formVal,
  readOnly,
  validation = null,
  errorMessage = '',
  required,
  overwritable,
  maxLength = null,
  renderInput = false,
  description = '',
  hideLabel = false,
  optionalLabel = '(optional)',
  hideOptionalLabel = false,
  showClearTextIcon = false,
  customHeight,
  min,
  max,
  placeHolder = '',
  validationRules = null,
  showErrorOnChange = true // only show error message on change
}) => {
  const fastFormContext = useContext(FastFormContext);
  const formValue = fastFormContext.form[formKey] || {};

  const initialInputChange = useCallback(value => {
    const valueIsEmpty =
      value === null || (isString(value) && value.trim() === '');
    let valid = true;
    let errors = [];

    if (required && valueIsEmpty) {
      valid = false;
      errors = errors.concat(`${label} is required.`);
    }

    if (valid && !valueIsEmpty && validation && !validation(value)) {
      valid = false;
      if (errorMessage) {
        errors = [...errors, errorMessage];
      }
    }

    if (valid && !valueIsEmpty && validationRules) {
      if (typeof validationRules !== 'function') {
        const error = executeValidationRules(validationRules, value);
        if (error) {
          valid = false;
          errors = [...errors, error];
        }
      }
    }

    fastFormContext.updateField(formKey, {
      ...formValue,
      inForm: true,
      value: getValue(value, formVal),
      errors,
      valid,
      required
    });
  }, []);

  const handleInputChange = value => {
    const valueIsEmpty =
      value === null || (isString(value) && value.trim() === '');
    let valid = true;
    let errors = [];

    if (required && valueIsEmpty) {
      valid = false;
      errors = errors.concat(`${label} is required.`);
    }

    if (valid && !valueIsEmpty && validation && !validation(value)) {
      valid = false;
      if (errorMessage) {
        errors = [...errors, errorMessage];
      }
    }

    if (validationRules) {
      if (typeof validationRules === 'function') {
        const error = validationRules({
          fastFormContext,
          value,
          onChange: true
        });
        if (error && isEmpty(errors)) {
          valid = false;
          errors = [error];
        }
      } else if (valid && !valueIsEmpty) {
        const error = executeValidationRules(validationRules, value);
        if (error) {
          valid = false;
          errors = [...errors, error];
        }
      }
    }

    fastFormContext.updateField(formKey, {
      ...formValue,
      touched: showErrorOnChange,
      inForm: true,
      value: getValue(value, formVal),
      errors,
      valid,
      required
    });
  };

  useEffect(() => {
    initialInputChange(getValue(formValue.value, formVal));
  }, []);

  const inputProps = {
    type,
    disabled: readOnly,
    className: `${overwritable ? 'highlighted' : ''} ${className || ''}`,
    onBlur: () => {
      fastFormContext.blurField(formKey, formValue);
      if (typeof validationRules === 'function') {
        const { errors } = formValue;
        const error = validationRules({
          fastFormContext,
          value: formValue.value
        });
        if (error && isEmpty(errors)) {
          fastFormContext.updateField(formKey, {
            ...formValue,
            touched: true,
            errors: [error],
            valid: false
          });
        }
      }
    },
    onFocus: () => fastFormContext.focusField(formKey, formValue),
    min,
    max,
    placeholder: placeHolder,
    customheight: customHeight
  };
  const customInputProps = {
    inputValue: formValue.value,
    onHandleChange: handleInputChange
  };

  return (
    <Form.Field>
      {!hideLabel && (
        <StyledFieldLabel>
          {label}
          {!required && !readOnly && !hideOptionalLabel ? (
            <StyledOptionalLabel>{` ${optionalLabel}`}</StyledOptionalLabel>
          ) : (
            ''
          )}
        </StyledFieldLabel>
      )}
      {description && <StyledDescription>{description}</StyledDescription>}
      {renderInput ? (
        renderInput({ ...inputProps, ...customInputProps })
      ) : (
        <StyledFormInput
          {...inputProps}
          value={formValue.value}
          onChange={({ target: { value } }) => {
            if (maxLength != null) {
              if (formValue.value.length < maxLength) {
                handleInputChange(value);
              } else if (value.length < maxLength) {
                handleInputChange(value);
              }
            } else {
              handleInputChange(value);
            }
          }}
          icon={
            showClearTextIcon && formValue.value ? (
              <Icon name='delete' link onClick={() => handleInputChange('')} />
            ) : null
          }
        />
      )}

      {formValue.errors.length > 0 &&
        formValue.touched &&
        formValue.errors.map(e => (
          <StyledErrorMessage key={e}>{e}</StyledErrorMessage>
        ))}
    </Form.Field>
  );
};
