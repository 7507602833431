import { ComposedModal as CarbonComposedModal } from '@carbon/react';
import type { ComposedModalProps as CarbonComposedModalProps } from '@carbon/react';
import classNames from 'classnames';
import './ComposedModal.scss';

export { ModalHeader, ModalBody, ModalFooter } from '@carbon/react';

export type ComposedModalProps = CarbonComposedModalProps;

export const ComposedModal = (props: ComposedModalProps) => (
  <CarbonComposedModal containerClassName={classNames('gf--modal', props.className)} {...props} />
);
