import { Button, InlineLoading } from 'carbon-components-react';
import styled, { css } from 'styled-components';

export const StyledInlineLoading = styled(InlineLoading)`
  .bx--inline-loading__text {
    color: #ffffff;
    font-size: 0.875rem;
  }
`;

export const StyledSubmitButton = styled(Button)`
  ${props =>
    !props.closeLabel &&
    css`
      justify-content: center;
      flex-grow: 1 !important;
    `}
`;
