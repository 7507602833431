class CreateOrderRequest {
  constructor({
    chargeToken,
    events,
    grandTotal,
    guestType,
    orderType = "CompTicket",
    purchaserEmail,
    purchaserPhoneNumber,
    readerInfo,
    receiptChannel,
    statementDescriptor,
    userInfo
  }) {
    this.raw = {
      chargeToken,
      events,
      grandTotal,
      guestType,
      orderType,
      purchaserEmail,
      purchaserPhoneNumber,
      readerInfo,
      receiptChannel,
      statementDescriptor,
      userInfo
    }
  }

  get chargeToken() {
    return this.raw.chargeToken;
  }

  get events() {
    return this.raw.events;
  }

  get grandTotal() {
    return this.raw.grandTotal;
  }

  get guestType() {
    return this.raw.guestType;
  }

  get orderType() {
    return this.raw.orderType;
  }

  get purchaserEmail() {
    return this.raw.purchaserEmail;
  }

  get purchaserPhoneNumber() {
    return this.raw.purchaserPhoneNumber;
  }

  get readerInfo() {
    return this.raw.readerInfo;
  }

  get receiptChannel() {
    return this.raw.receiptChannel;
  }

  get statementDescriptor() {
    return this.raw.statementDescriptor;
  }

  get userInfo() {
    return this.raw.userInfo;
  }

  toJSON() {
    return {
      chargeToken: this.chargeToken,
      events: this.events,
      grandTotal: this.grandTotal,
      guestType: this.guestType,
      orderType: this.orderType,
      purchaserEmail: this.purchaserEmail,
      purchaserPhoneNumber: this.purchaserPhoneNumber,
      readerInfo: this.readerInfo,
      receiptChannel: this.receiptChannel,
      statementDescriptor: this.statementDescriptor,
      userInfo: this.userInfo
    }
  }
}

export default CreateOrderRequest;