import { useQuery } from '@tanstack/react-query';
import { UserService } from './user.service';

interface BaseQueryOptions {
  cacheTime?: number;
  staleTime?: number;
  enabled?: boolean;
}
export interface GetUserByEmailsProps {
  accountId: string;
  usersEmails: string[];
  queryOptions?: BaseQueryOptions;
}

export const UsersQuery = {
  getUserByEmails: ({ accountId, usersEmails, queryOptions }: GetUserByEmailsProps) =>
    useQuery({
      queryKey: [accountId, { usersEmails }],
      queryFn: () => UserService.getUserByEmails(usersEmails),
      ...queryOptions
    })
};
