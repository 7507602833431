import React from 'react';
import { Select } from 'semantic-ui-react';
import styled from 'styled-components';

import styles from './styles';

const StyledSelection = styled(Select)`
  ${styles.selection};
`;

export default ({ children, ...props }) => (
  <StyledSelection {...props}>{children}</StyledSelection>
);
