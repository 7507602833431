export const SPECIFIC_COLUMN = {
  ACTION_BUTTON: { id: 'actionButton' },
  ROW_SELECTION: { id: 'tableSelection' }
};

export const PAGINATION_OPTIONS = [
  { key: 10, value: 10, text: 'Show 10' },
  { key: 25, value: 25, text: 'Show 25' },
  { key: 50, value: 50, text: 'Show 50' }
];
