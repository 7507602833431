import { QROptions, QRDownloadItems } from './qr-code.component';

export const QR_DOWNLOAD_TYPE: QRDownloadItems = {
  DOWNLOAD_FLYER: {
    type: 'DOWNLOAD_FLYER',
    name: 'Download QR code flyer'
  },
  DOWNLOAD_IMAGE: {
    type: 'DOWNLOAD_IMAGE',
    name: 'Download QR code'
  },
  DOWNLOAD_SCHOOL_QR_CODE: {
    type: 'DOWNLOAD_SCHOOL_QR_CODE',
    name: 'School QR code'
  },
  TICKET_FLYER: {
    type: 'TICKET_FLYER',
    name: 'Ticket QR flyer'
  },
  TICKET_QR_CODE: {
    type: 'TICKET_QR_CODE',
    name: 'Ticket QR code'
  }
};

export const QR_CODE_CONFIG: QROptions = {
  scale: 2,
  margin: 0
};

export const QR_CODE_IMAGE_CONFIG: QROptions = {
  width: 250
};

export const QR_CODE_FILE_NAME = 'QRCode.png';

export const LANGUAGE_CODES = {
  EN: 'en',
  ES: 'es'
};

export const FLYER_ZIP_NAME = 'QR code';

export const FLYER_PDF_FILE_NAME = {
  EN: '_english.pdf',
  ES: '_spanish.pdf'
};

export default {
  QR_DOWNLOAD_TYPE,
  QR_CODE_CONFIG,
  QR_CODE_FILE_NAME,
  LANGUAGE_CODES,
  FLYER_ZIP_NAME,
  FLYER_PDF_FILE_NAME
};
