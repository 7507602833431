import { isEmpty, isBoolean } from 'lodash';
import ErrorDTO from '../dto/ErrorDTO';
import RateDTO from '../dto/RateDTO';
import PaginationDTO from '../dto/PaginationDTO';
import axios from '../api/axios';
import { RATE_URI, GET_RATE_BY_ID_URI } from '../api/api';

const fetchRates = ({ searchRatesRequest, pageRequest, sortableRequest }) => {
  const uri = `${RATE_URI}?${searchRatesRequest.toQueryString()}${pageRequest.toQueryString()}${sortableRequest.toQueryString()}`;

  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }

      const { content, pageable, totalPages } = response;
      const { pageSize, pageNumber } = isEmpty(pageable) ? {} : pageable;

      return new PaginationDTO({
        pageSize,
        pageNumber,
        totalPages,
        data: content.map(rate => new RateDTO(rate))
      });
    })
    .catch(e => new ErrorDTO(e));
};

const fetchRateById = (id, expands) => {
  const uri = isEmpty(expands)
    ? `${GET_RATE_BY_ID_URI.replace('{id}', id)}`
    : `${GET_RATE_BY_ID_URI.replace('{id}', id)}?${expands
        .map(e => `expand=${e}`)
        .join('&')}`;
  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new RateDTO(response);
    })
    .catch(e => new ErrorDTO(e));
};
const createRate = rateRequest =>
  axios.huddleAxiosInstanceProxy
    .post(RATE_URI, rateRequest.toJSON())
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new RateDTO(response);
    })
    .catch(e => new ErrorDTO(e));

const updateRate = rateRequest =>
  axios.huddleAxiosInstanceProxy
    .put(`${RATE_URI}${rateRequest.id}`, rateRequest.toJSON())
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new RateDTO(response);
    })
    .catch(e => new ErrorDTO(e));

export default {
  fetchRateById,
  fetchRates,
  createRate,
  updateRate
};
