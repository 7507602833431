import { put, takeEvery, call, select } from 'redux-saga/effects';
import {
  FETCH_PRODUCT_TYPES,
  UPDATE_PRODUCT_TYPE,
  CREATE_PRODUCT_TYPE,
  DELETE_PRODUCT_TYPE
} from './actionTypes';
import {
  fetchAllProductTypes,
  fetchProductTypesCompleted,
  updateProductTypeCompleted,
  createProductTypeCompleted,
  deleteProductTypeCompleted
} from './actions';
import { addMessage } from '../../root/actions';
import ProductsService from '../../api/services/ProductsService';
import ErrorDTO from '../../api/dto/ErrorDTO';
import ProductType from '../../api/model/ProductType';
import ProductTypeDTO from '../../api/dto/ProductTypeDTO';
import {
  generateFailContent,
  generateSuccessContent
} from '../../utils/alertUtils';
import { UNEXPECTED_ERROR } from '../../api/api/constants';
import { errorMessages } from '../../commons';

export function* handleFetchAllProductType() {
  try {
    const response = yield call(ProductsService.fetchAllProductTypes);
    let productTypes = [];
    if (response instanceof Array) {
      productTypes = response
        .map(productType => new ProductType(productType).toJSON())
        .sort((a, b) => a.name.localeCompare(b.name));
    }
    yield put(fetchProductTypesCompleted(productTypes));
    if (response instanceof ErrorDTO) {
      const message = generateFailContent(response.getErrorMessage());
      yield put(addMessage(message));
    }
  } catch (error) {
    yield put(fetchProductTypesCompleted([]));
    const message = generateFailContent(error.message);
    yield put(addMessage(message));
  }
}

export function* handleUpdateProductType(action) {
  try {
    const { productType } = action.payload;
    const response = yield call(ProductsService.updateProductType, productType);
    if (response instanceof ProductTypeDTO) {
      const productTypes = yield select(state => state.products.productTypes);
      const newProductTypes = [...productTypes];
      const newProductType = new ProductType(response).toJSON();
      newProductTypes[
        newProductTypes.findIndex(item => item.id === newProductType.id)
      ] = newProductType;
      newProductTypes.sort((a, b) => a.name.localeCompare(b.name));
      yield put(updateProductTypeCompleted(newProductTypes));
      yield put(
        addMessage(generateSuccessContent(`${productType.name} is updated.`))
      );
    } else {
      yield put(fetchAllProductTypes());

      const errorMessage =
        response instanceof ErrorDTO && response.data
          ? response.data.message
          : UNEXPECTED_ERROR;
      yield put(addMessage(generateFailContent(errorMessage)));
    }
  } catch (error) {
    const message = generateFailContent(error.message);
    yield put(addMessage(message));
    yield put(updateProductTypeCompleted(state => state.products.productTypes));
  }
}

export function* handleCreateProductType(action) {
  try {
    const { productType } = action.payload;
    const response = yield call(ProductsService.createProductType, productType);

    if (response instanceof ProductTypeDTO) {
      const productTypes = yield select(state => state.products.productTypes);

      yield put(
        createProductTypeCompleted(
          [...productTypes, new ProductType(response).toJSON()].sort((a, b) =>
            a.name.localeCompare(b.name)
          )
        )
      );
      yield put(
        addMessage(generateSuccessContent(`${productType.name} is added.`))
      );
    } else {
      yield put(fetchAllProductTypes());

      const errorMessage =
        response instanceof ErrorDTO && response.data
          ? response.data.message
          : UNEXPECTED_ERROR;
      yield put(addMessage(generateFailContent(errorMessage)));
    }
  } catch (error) {
    const message = generateFailContent(error.message);
    yield put(addMessage(message));
    yield put(createProductTypeCompleted(state => state.products.productTypes));
  }
}

export function* handleDeleteProductType(action) {
  try {
    const { productType } = action.payload;
    const response = yield call(
      ProductsService.deleteProductType,
      productType.id
    );
    if (response === true) {
      const productTypes = yield select(state => state.products.productTypes);
      const newProductTypes = [...productTypes];
      newProductTypes.splice(
        newProductTypes.findIndex(item => item.id === productType.id),
        1
      );
      yield put(deleteProductTypeCompleted(newProductTypes));
      yield put(
        addMessage(generateSuccessContent(`${productType.name} is deleted.`))
      );
    } else {
      yield put(fetchAllProductTypes());

      const errorMessage =
        response instanceof ErrorDTO && response.data
          ? response.data.message
          : UNEXPECTED_ERROR;
      if (errorMessage.includes('associated')) {
        const message = `${productType.name} ${
          errorMessages.ASSOCIATING_UPCOMING_EVENT_MESSAGE
        }`;
        yield put(addMessage(generateFailContent(message)));
      } else {
        yield put(addMessage(generateFailContent(errorMessage)));
      }
    }
  } catch (error) {
    const message = generateFailContent(error.message);
    yield put(addMessage(message));
    yield put(deleteProductTypeCompleted(state => state.products.productTypes));
  }
}

export function* watchFetchAllProductTypesSaga() {
  yield takeEvery(FETCH_PRODUCT_TYPES, handleFetchAllProductType);
}

export function* watchUpdateProductTypeSaga() {
  yield takeEvery(UPDATE_PRODUCT_TYPE, handleUpdateProductType);
}

export function* watchCreateProductTypeSaga() {
  yield takeEvery(CREATE_PRODUCT_TYPE, handleCreateProductType);
}

export function* watchDeleteProductTypeSaga() {
  yield takeEvery(DELETE_PRODUCT_TYPE, handleDeleteProductType);
}

export default [
  watchFetchAllProductTypesSaga(),
  watchUpdateProductTypeSaga(),
  watchCreateProductTypeSaga(),
  watchDeleteProductTypeSaga()
];
