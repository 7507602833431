import React from 'react';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

import styles from './styles';

const StyledFormInput = styled(Input)`
  ${styles.input};
`;

export default ({ children, ...props }) => (
  <StyledFormInput {...props}>{children}</StyledFormInput>
);
