import React from 'react';
import styled from 'styled-components';
import { NotificationContainer } from 'react-notifications';
import SideMenu from '../SideMenu';
import 'react-notifications/lib/notifications.css';

import styles from './styles';

const Wrapper = styled.div`
  ${styles.wrapper};
`;
const BodyWrapper = styled.div`
  ${styles.bodyWrapper};
`;

export default ({ children, ...props }) => {
  let bodyWidth = '100%';

  if (props.history?.location?.pathname?.includes('dashboard')) {
    bodyWidth = props.isSideMenuExpanded ? 'calc(100% - 350px)' : 'calc(100% - 100px)';
  } else if (props.isSideMenuExpanded) {
    bodyWidth = 'calc(100% - 350px)';
  }

  return (
    <Wrapper hasWhiteBackground={!!props.location?.pathname?.includes('event-integrations')}>
      <SideMenu hasIconMenu={!!props.history?.location?.pathname?.includes('dashboard')} {...props} />
      <BodyWrapper style={{ marginLeft: 'auto', width: bodyWidth }}>{children}</BodyWrapper>
      <NotificationContainer />
    </Wrapper>
  );
};
