import { useQuery } from '@tanstack/react-query';
import { AccountService } from './account.service';
import type { AccountDTO } from './account.model';
import type { QueryOptions } from '@gofan/api/shared';

export interface GetAccountAssociationsParams {
  account?: AccountDTO;
  queryOptions?: QueryOptions;
}

export interface GetAccountSchoolsByConferenceParams {
  conferenceId?: string;
  conferenceLeagueC?: string;
  queryOptions?: QueryOptions;
}

export interface GetAccountSchoolsByDistrictParams {
  districtHuddleId?: string;
  queryOptions?: QueryOptions;
}

export const AccountQuery = {
  getAccountAssociations: ({ account, queryOptions }: GetAccountAssociationsParams) =>
    useQuery({
      queryKey: ['accounts', ASSOCIATIONS_QUERY_KEY],
      queryFn: () => {
        if (account)
          return AccountService.getAccountsByIds([account.stateAssociationHuddleId, account.districtHuddleId]);
        return Promise.resolve([]);
      },
      ...queryOptions
    }),

  getAccountSchoolsByConference: ({
    conferenceId,
    conferenceLeagueC,
    queryOptions
  }: GetAccountSchoolsByConferenceParams) =>
    useQuery({
      queryKey: [
        'accounts',
        {
          conferenceId,
          conferenceLeagueC
        }
      ],
      queryFn: () => AccountService.getAccountSchoolsByConference({ conferenceId, conferenceLeagueC }),
      ...queryOptions
    }),

  getAccountSchoolsByDistrict: ({ districtHuddleId, queryOptions }: GetAccountSchoolsByDistrictParams) =>
    useQuery({
      queryKey: ['district', districtHuddleId],
      queryFn: () => AccountService.getAccountSchoolsByDistrict({ districtHuddleId }),
      ...queryOptions
    })
};

export const ASSOCIATIONS_QUERY_KEY = 'associations';
