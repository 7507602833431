import { CRMSchoolRepository } from './crm-school.repository';
import type { CRMSchoolDTO } from './crm-school.model';

class CRMSchoolService {
  static getCrmSchoolById = (accountId: string): Promise<CRMSchoolDTO> =>
    CRMSchoolRepository.getCrmSchoolById(accountId)
      .then(res => res)
      .catch(error => error);
}

export { CRMSchoolService };
