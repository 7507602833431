/* eslint-disable import/no-unresolved */
// @flow
import { call, put, takeLatest } from 'redux-saga/effects';

import * as types from './actionTypes';
import * as actionCreator from './actions';
import { ROLES_MAPPING, SEARCH_ACCOUNTS_PARAMS } from './constants';
import { addMessage } from '../../root/actions';
import { PAGES } from '../../config/routes';
import { generateFailContent } from '../../utils/alertUtils';
import { parseUrl } from '../../commons/utils/parseQueryString';

import Event from '../../api/model/Event';
import Account from '../../api/model/Account';
import ErrorDTO from '../../api/dto/ErrorDTO';
import AccountDTO from '../../api/dto/AccountDTO';
import { UNEXPECTED_ERROR } from '../../api/api/constants';
import PageRequest from '../../api/model/request/PageRequest';
import FieldsRequest from '../../api/model/request/FieldsRequest';
import SearchEventsRequest, { SEARCH_EVENT_DEFAULT_DATE } from '../../api/model/request/SearchEventsRequest';
import { filterUpcommingEvents, fetchEventsByAccountId } from '../../api/services/EventService';
import { fetchAccountById } from '../../api/services/AccountService';

import { checkInternalUser, getActiveUserAccountContexts } from '../../api/services/UserService';
import { searchAccounts } from '../AccountList/sagas';

export function* searchEvents(action) {
  try {
    const { searchParams } = action.payload;
    const pageRequest = new PageRequest({
      pageSize: searchParams.pageSize,
      pageIndex: searchParams.pageIndex
    });
    const fieldsRequest = new FieldsRequest({
      fields: searchParams.fields,
      embeddedFields: searchParams.embeddedFields
    });
    const searchRequest = new SearchEventsRequest({
      accountId: searchParams.accountId,
      startDate: SEARCH_EVENT_DEFAULT_DATE
    });
    const events = yield call(fetchEventsByAccountId, searchRequest, pageRequest, fieldsRequest);
    let searchResult = [];

    if (events instanceof Array) {
      searchResult = filterUpcommingEvents({
        accountId: searchParams.accountId,
        events: events.map(event => new Event(event).toJSON()),
        rolesMapping: ROLES_MAPPING
      });
    }
    yield put(actionCreator.searchEventsSuccess(searchResult));
  } catch (e) {
    yield put(actionCreator.searchEventsSuccess([]));
  }
}

export function* watchSearchEventsRequest() {
  yield takeLatest(types.SEARCH_EVENTS, searchEvents);
}

export function* routingEventPage(action) {
  try {
    const { routingParams } = action.payload;
    const { history, userRole, userAccountContexts } = routingParams;
    const { search = {} } = history.location;
    const { query } = parseUrl(search);
    const isInternalUser = checkInternalUser(userRole);
    const activeContexts = getActiveUserAccountContexts(userAccountContexts);

    if (query.accountId && (isInternalUser || activeContexts.some(context => context.accountId === query.accountId))) {
      const response = yield call(fetchAccountById, query.accountId);
      if (response instanceof AccountDTO) {
        const account = new Account(response.toJSON()).toJSON();
        if (account.isActive) {
          yield put(actionCreator.routingEventPageCompleted(account));
          return;
        }
      } else if (response instanceof ErrorDTO) {
        const message = generateFailContent(response.getErrorMessage());
        yield put(addMessage(message));
        return;
      }
    }

    if (isInternalUser) {
      history.replace(PAGES.events.account.path);
      return;
    }

    if (activeContexts.length) {
      const { data: accounts } = yield call(searchAccounts, SEARCH_ACCOUNTS_PARAMS);

      if (accounts.length > 1) {
        history.replace(PAGES.events.account.path);
        return;
      }

      if (accounts.length === 1) {
        const accountId = accounts[0].id;
        history.replace(PAGES.eventsV2.root.calculatePath(accountId));
        return;
      }
    }

    yield put(actionCreator.routingEventPageCompleted());
  } catch (e) {
    yield put(addMessage(generateFailContent(UNEXPECTED_ERROR)));
  }
}

export function* watchRoutingEventPage() {
  yield takeLatest(types.ROUTING_EVENT_PAGE, routingEventPage);
}

export default [watchRoutingEventPage(), watchSearchEventsRequest()];
