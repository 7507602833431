import type { PurchasedTicket, EventRefund } from '../../api/types';
import type {
  SearchTicketResult,
  SearchTicketParams,
  SearchEventRefundResult,
  SearchEventRefundParams,
  AdditionalRefundInfo
} from './types';

export const SET_CURRENT_TAB = '@fanSupport/SET_CURRENT_TAB';
export const RESET_STATE = '@fanSupport/RESET_STATE';
export const SEARCH_TICKETS = '@fanSupport/SEARCH_TICKETS';
export const SEARCH_TICKETS_SUCCESS = '@fanSupport/SEARCH_TICKETS_SUCCESS';
export const REFUND_TICKETS = '@fanSupport/REFUND_TICKETS';
export const REFUND_TICKETS_COMPLETED = '@fanSupport/REFUND_TICKETS_COMPLETED';

export const SEARCH_EVENT_REFUND = '@fanSupport/SEARCH_EVENT_REFUND';
export const SEARCH_EVENT_REFUND_COMPLETED = '@fanSupport/SEARCH_EVENT_REFUND_COMPLETED';
export const SET_EVENT_REFUND = '@fanSupport/SET_EVENT_REFUND';
export const REFUND_BY_EVENT = '@fanSupport/REFUND_BY_EVENT';
export const REFUND_BY_EVENT_COMPLETED = '@fanSupport/REFUND_BY_EVENT_COMPLETED';
export const REFUND_BY_EVENT_IN_PROCCESS = '@fanSupport/REFUND_BY_EVENT_IN_PROCCESS';
export const SEARCH_SEASON_PASS_TICKETS = '@fanSupport/SEARCH_SEASON_PASS_TICKETS';
export const SEARCH_SEASON_PASS_TICKETS_SUCCESS = '@fanSupport/SEARCH_SEASON_PASS_TICKETS_SUCCESS';

interface SetCurrentTabAction {
  type: typeof SET_CURRENT_TAB;
  payload: {
    tab: string;
  };
}

interface ResetStateAction {
  type: typeof RESET_STATE;
}

interface SearchTicketsAction {
  type: typeof SEARCH_TICKETS;
  payload: {
    searchParams: SearchTicketParams;
  };
}

interface SearchTicketSuccess {
  type: typeof SEARCH_TICKETS_SUCCESS;
  payload: {
    searchResult: SearchTicketResult;
  };
}

interface RefundTickets {
  type: typeof REFUND_TICKETS;
  payload: {
    purchasedTickets: PurchasedTicket[];
    searchParams: SearchTicketParams;
    additionalRefundInfo: AdditionalRefundInfo;
  };
}

interface RefundTicketCompleted {
  type: typeof REFUND_TICKETS_COMPLETED;
}

interface SearchEventRefundAction {
  type: typeof SEARCH_EVENT_REFUND;
  payload: {
    searchParams: SearchEventRefundParams;
  };
}

interface SearchEventRefundCompletedAction {
  type: typeof SEARCH_EVENT_REFUND_COMPLETED;
  payload: {
    searchResult: SearchEventRefundResult;
  };
}

interface SetEventRefundAction {
  type: typeof SET_EVENT_REFUND;
  payload: {
    events: Array<EventRefund>;
  };
}

interface RefundByEventAction {
  type: typeof REFUND_BY_EVENT;
  payload: {
    event: EventRefund;
    additionalRefundInfo: AdditionalRefundInfo;
  };
}

interface RefundByEventCompletedAction {
  type: typeof REFUND_BY_EVENT_COMPLETED;
}

interface RefundByEventInProccessAction {
  type: typeof REFUND_BY_EVENT_IN_PROCCESS;
  payload: {
    eventId: string;
  };
}

export type FanSupportActionTypes =
  | SetCurrentTabAction
  | ResetStateAction
  | SearchTicketsAction
  | SearchTicketSuccess
  | RefundTickets
  | RefundTicketCompleted
  | SearchEventRefundAction
  | SearchEventRefundCompletedAction
  | SetEventRefundAction
  | RefundByEventAction
  | RefundByEventCompletedAction
  | RefundByEventInProccessAction;
