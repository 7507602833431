import { get, isEmpty, omit } from 'lodash';

import { getUniqueId } from '@utils/objectUtils';

import type { AccountDTO } from '@gofan/api/accounts';
import type { ActivityDTO } from '@gofan/api/activities';

import type { CreatingSeason } from '@season-management/middleware/models/creating-season.model';
import type { SeasonWithEvent } from '@season-management/middleware/models/season-with-event.model';
import SeasonWithEventModel from '@season-management/middleware/models/season-with-event.model';

export default class MultiSeasonUpdateRequest {
  contents: SeasonWithEventModel[];

  requestId = getUniqueId();

  dirtyFields = {};

  defaultValues = [];

  constructor(contents?: any) {
    this.contents = [...(contents ?? [])] as SeasonWithEventModel[];
  }

  toJSON() {
    return [...this.defaultValues, ...this.contents].map(item => {
      const season = item.toJSON();
      return {
        ...omit(season, ['created']),
        id: season.created ? season.id : undefined,
        events: season?.events?.map(event => {
          const defaultNullOppAccountId = event.created ? '' : null;
          const opponentAccountId = isEmpty(event.opponentAccountId)
            ? defaultNullOppAccountId
            : event.opponentAccountId;
          return {
            ...omit(event, ['created']),
            id: event.created ? event.id : undefined,
            allDayEvent: false, // [TODO][Should handle start/end if allDayEvent = true]
            opponentAccountId,
            products: event?.products?.map(product => ({
              ...omit(product, ['created']),
              id: product.created ? product.id : undefined,
              accountId: product.created ? product.accountId : null
            }))
          };
        })
      } as SeasonWithEvent;
    });
  }

  setDirtyFields(dirtyFields = {}) {
    this.dirtyFields = { ...dirtyFields };
    return this;
  }

  populate({
    account,
    activity,
    creatingSeason
  }: {
    account: AccountDTO;
    activity: ActivityDTO;
    creatingSeason: CreatingSeason;
  }) {
    const { rawSeasons } = creatingSeason;

    rawSeasons?.forEach(rawSeason => {
      const isDirty = get(this.dirtyFields, `rawSeasons.${rawSeason.id}`);
      if (isDirty) {
        this.contents.push(new SeasonWithEventModel({}).populate({ account, activity, rawSeason }));
      }
    });

    return this;
  }
}
