// @flow

class ExceptionDTO {
  constructor({ id, name }) {
    this.raw = {
      id,
      name
    };
  }

  get id() {
    return this.raw.id;
  }

  get name() {
    return this.raw.name;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name
    };
  }
}

export default ExceptionDTO;
