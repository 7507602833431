import React, { memo } from 'react';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'react-fast-compare';

import type { CardHeaderProps } from './Card.types';
import { Button } from '@gofan/components';
import { getTestId } from '@gofan/utils/testing';

export function CardHeader({
  label,
  title,
  description,
  actions,
  counter,
  primaryButtonProps,
  secondaryButtonProps,
  buttonSize,
  titleSize,
  backgroundColor,
  textColor,
  className
}: CardHeaderProps) {
  const hasActions = actions?.length > 0 || !isEmpty(primaryButtonProps) || !isEmpty(secondaryButtonProps);
  const hasLargeButton = !isEmpty(primaryButtonProps) || (!isEmpty(secondaryButtonProps) && buttonSize === 'md');
  const hasLargeTitle = titleSize === 'lg';

  const textStyle = textColor ? { color: textColor } : {};
  return (
    <div className={cx('card__header', className)} {...getTestId(TestIds.name)}>
      <div
        className={cx('card__header-container', {
          'title--lg': hasLargeTitle,
          'button--lg': hasLargeButton
        })}
        style={backgroundColor ? { backgroundColor } : {}}
      >
        <div className='card__title-container gs--font-family-sf' style={textStyle}>
          {label && (
            <p className='card__label' {...getTestId(TestIds.label)}>
              {label}
            </p>
          )}
          {title && (
            <div className='card__tittle-counter' {...getTestId(TestIds.titleContainer)}>
              <h6 className={cx('card__title', { 'card__title--lg': hasLargeTitle })} {...getTestId(TestIds.title)}>
                {title}
              </h6>
              {counter !== undefined ? (
                <div className='card__counter' {...getTestId(TestIds.counter)}>
                  {counter}
                </div>
              ) : null}
            </div>
          )}
          {description && (
            <p className='card__description' style={textStyle} {...getTestId(TestIds.description)}>
              {description}
            </p>
          )}
        </div>
        {hasActions && (
          <div className='card__actions--header' {...getTestId(TestIds.actions)}>
            {actions?.length > 0 ? (
              <div className='card__actions-icons' {...getTestId(TestIds.actionIcons)}>
                {actions.map((action, id) => (
                  <React.Fragment key={`action-${id.toString()}`}>{action}</React.Fragment>
                ))}
              </div>
            ) : null}
            {secondaryButtonProps?.text && (
              <Button
                kind={secondaryButtonProps.kind || 'ghost'}
                size={buttonSize}
                onClick={secondaryButtonProps?.onClick}
                renderIcon={secondaryButtonProps?.icon}
                disabled={secondaryButtonProps?.disabled}
                className='card__actions-btn btn-secondary'
                {...getTestId(TestIds.secondaryButton)}
              >
                {secondaryButtonProps?.text}
              </Button>
            )}
            {primaryButtonProps?.text && (
              <Button
                kind={primaryButtonProps.kind || 'ghost'}
                size={buttonSize}
                onClick={primaryButtonProps?.onClick}
                renderIcon={primaryButtonProps?.icon}
                disabled={primaryButtonProps?.disabled}
                className='card__actions-btn btn-primary'
                {...getTestId(TestIds.primaryButton)}
              >
                {primaryButtonProps?.text}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export const TestIds = {
  name: 'card-header',
  label: 'card-label',
  titleContainer: 'card-title-container',
  title: 'card-title',
  counter: 'card-counter',
  description: 'card-description',
  actions: 'card-actions',
  actionIcons: 'card-actions-icons',
  primaryButton: 'card-primary-button',
  secondaryButton: 'card-secondary-button'
};

export default memo(CardHeader, (oldProps: CardHeaderProps, newProps: CardHeaderProps) => isEqual(oldProps, newProps));
