import axios from 'axios';
import type { AxiosResponse } from 'axios';
import { REPORTS_ENDPOINTS } from './reports.endpoints';
import type { EventSeasonSummaryBody, EventSeasonSummaryTypesEnum } from './reports.model';

class ReportsService {
  static getEventSeasonSummaryReport = (
    events: EventSeasonSummaryBody[],
    schoolId: string,
    reportType: EventSeasonSummaryTypesEnum // Event/Season
  ): Promise<{ data: Blob; contentType: string }> =>
    axios
      .post(`${REPORTS_ENDPOINTS.GET_EVENT_SEASON_SUMMARY}?schoolId=${schoolId}&type=${reportType}`, events, {
        headers: {
          Accept: events.length === 1 ? 'application/pdf' : 'application/zip'
        },
        responseType: 'blob'
      })
      .then((resp: AxiosResponse<Blob>) => ({ data: resp.data, contentType: resp.headers['content-type'] }))
      .catch(e => e)
      .then(async result => {
        if (axios.isAxiosError(result)) {
          const errorMsg = await result?.response?.data.text();
          throw new Error(`Error ${result?.response?.status}: ${errorMsg}`);
        }
        return result;
      });
}

export { ReportsService };
