import { Message } from './types';
import {
  RootActionTypes,
  ADD_MESSAGE,
  REMOVE_MESSAGE,
  CHECK_SERVER_ON_MAINTENANCE
} from './actionTypes';

// TypeScript infers that this function is returning AddMessageAction
export function addMessage(newMessage: Message): RootActionTypes {
  return {
    type: ADD_MESSAGE,
    payload: {
      message: newMessage
    }
  };
}

// TypeScript infers that this function is returning RemoveMessageAction
export function removeMessage(id: number): RootActionTypes {
  return {
    type: REMOVE_MESSAGE,
    payload: {
      id
    }
  };
}

export const checkServerOnMaintenance = (history: Object): RootActionTypes => ({
  type: CHECK_SERVER_ON_MAINTENANCE,
  payload: { history }
});
