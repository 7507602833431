import React, { useContext, useEffect } from 'react';
import { FastFormContext } from './FastFormBase';
import FormSafeguard from './FormSafeguard';

export default ({ children, formKey, required = false }) => {
  const fastFormContext = useContext(FastFormContext);
  const formValue = fastFormContext.form[formKey];
  useEffect(
    () => {
      fastFormContext.updateField(formKey, {
        ...formValue,
        required,
        inForm: true
      });
    },
    [required]
  );
  const handleChange = value =>
    fastFormContext.updateField(formKey, { ...formValue, required, value });
  const ffProps = { value: formValue.value, onChange: handleChange };
  return <FormSafeguard formKey={formKey}>{children(ffProps)}</FormSafeguard>;
};
