import _ from 'lodash';

class LocalStorage {
  static setStorage(name, data) {
    if (!_.isEmpty(data) || typeof data === 'boolean') {
      window.localStorage.setItem(name, JSON.stringify(data));
    } else {
      LocalStorage.removeStorage(name);
    }
  }

  static removeStorage(name) {
    window.localStorage.removeItem(name);
  }

  static setStorageAuthenticated(authenticated) {
    LocalStorage.setStorage('authenticated', authenticated);
  }

  static getStorageAuthenticated() {
    const authenticated = window.localStorage.getItem('authenticated');
    return authenticated === 'true';
  }

  static getStorageAuthBody() {
    const authBody = window.localStorage.getItem('authBody');
    return JSON.parse(authBody);
  }

  static setStorageAuthBody(authBody) {
    LocalStorage.setStorage('authBody', authBody);
  }

  static removeStorageAuthBody() {
    LocalStorage.removeStorage('authBody');
  }

  static clearStorage() {
    this.messageBroadcast({ command: 'clear' });
    window.localStorage.clear();
  }

  static messageBroadcast(message) {
    window.localStorage.setItem('message', JSON.stringify(message));
    window.localStorage.removeItem('message');
  }

  static getStorageCurrentUser() {
    const currentUser = window.localStorage.getItem('currentUser');

    return JSON.parse(currentUser);
  }

  static setStorageCurrentUser(currentUser) {
    LocalStorage.setStorage('currentUser', currentUser);
  }

  static removeStorageCurrentUser() {
    LocalStorage.removeStorage('currentUser');
  }

  static getToken() {
    const authBody = LocalStorage.getStorageAuthBody();
    if (!authBody || !authBody.user || !authBody.user.stsTokenManager) {
      return null;
    }
    return authBody.user.stsTokenManager.accessToken;
  }

  static getExpirationTime() {
    const authBody = LocalStorage.getStorageAuthBody();
    if (!authBody || !authBody.user || !authBody.user.stsTokenManager) {
      return null;
    }
    return authBody.user.stsTokenManager.expirationTime;
  }

  static getStorage(name) {
    return JSON.parse(window.localStorage.getItem(name));
  }
}

export default LocalStorage;
