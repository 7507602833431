import React, { useContext } from 'react';
import { Popup } from 'semantic-ui-react';
import { StyledFormButton } from '../../components/styledSemantic';
import FormSafeguard from './FormSafeguard';
import { FastFormContext } from './FastFormBase';
import ViewOnlyGuard from './ViewOnlyGuard';

export default function EnableButton({
  nullText,
  formKey,
  label,
  enableTitle,
  disableTitle,
  popup,
  ...data
}) {
  const fastFormContext = useContext(FastFormContext);
  const formValue = fastFormContext.form[formKey] || {};

  const button = (
    <StyledFormButton
      {...data}
      onClick={() =>
        fastFormContext.updateField(formKey, {
          ...formValue,
          value: !formValue.value
        })
      }
    >
      {formValue.value ? disableTitle : enableTitle}
    </StyledFormButton>
  );

  return (
    <FormSafeguard formKey={formKey}>
      <ViewOnlyGuard nullText={nullText} label={label} formKey={formKey} />
      {popup ? (
        <Popup
          {...popup}
          content={formValue.value ? popup.disbleContent : popup.enableContent}
          trigger={button}
        />
      ) : (
        button
      )}
    </FormSafeguard>
  );
}
