import { VFC } from 'react';
import { formatNumber } from '@events/components/ticket-sale-chart/helper';
import groupBy from 'lodash/fp/groupBy';
import { TicketSaleData } from '@events/models/event.model';
import { uniqueId } from 'lodash';
import { isEmptyString } from '@app/utils/stringUtils';
import MeterChart from '@app/commons/generic-components/meter-chart/meter-chart';

interface TicketSaleBySchoolNumericalProps {
  data: TicketSaleData[];
}

const TicketSaleBySchoolNumerical: VFC<TicketSaleBySchoolNumericalProps> = ({ data }) => {
  const groupBySchool = Object.entries(groupBy(ticketData => ticketData.accountId, data));

  const ticketSaleSchools = groupBySchool.reduce((acc: any, [key, datas]) => {
    const sum = (v1: number, v2: number) => v1 + v2;
    const totalTicketSold: number = datas.map(item => item.ticketSold).reduce(sum) ?? 0;
    const totalTicketSale: number = datas.map(item => item.ticketSale).reduce(sum) ?? 0;
    return {
      ...acc,
      [uniqueId(key)]: {
        schoolName: datas[0]?.school?.name || '',
        totalTicketSold,
        totalTicketSale,
        schoolTickets: datas
      }
    };
  }, {});

  return (
    <>
      {Object.entries(ticketSaleSchools).map(([_, ticket]: any, index) => (
        <div key={index}>
          {index !== 0 && (
            <div className='gs--padding-bottom__sp5'>
              <div className='event-insight__divider' />
            </div>
          )}
          <div className='ticket-sale-label'>{ticket?.schoolName}</div>
          {data.map((schoolTicket, index) => {
            return (
              <MeterChart
                animate
                key={schoolTicket.type + index}
                className='gs--margin-top-sp2'
                label={schoolTicket.type}
                value={100}
                fill={
                  !isEmptyString(schoolTicket.school?.primaryColor)
                    ? `#${schoolTicket.school?.primaryColor}`
                    : '#000000'
                }
                extraSuffix={formatNumber(schoolTicket.ticketSold)}
                suffix={`$${formatNumber(schoolTicket.ticketSale || 0)}`}
              />
            );
          })}
        </div>
      ))}
    </>
  );
};

export default TicketSaleBySchoolNumerical;
