import React from 'react';
import styled from 'styled-components';
import StyledLabel from '../../../../../../commons/carbon-ui/atoms/StyledLabel';

const BorderedContainer = styled.section`
  background-color: #ffffff;
  display: flex;
  height: auto;
  flex-direction: column;
  margin-top: 1rem;
  padding: 16px;
  border: 1px solid rgba(168, 168, 168, 0.3);
  // width: 100%;
`;

const EventContainer = styled.section`
  background-color: #ffffff;
  display: flex;
  height: auto;
  flex-direction: column;
  margin-top: 1rem;
`;

function Sections({ title, children, id, withBorder = false }) {
  return withBorder ? (
    <BorderedContainer id={id}>
      <div className='gs--font-family-san gs--section-title gs--text'>{title}</div>
      {children}
    </BorderedContainer>
  ) : (
    <EventContainer id={id}>
      <StyledLabel className='gs--font-family-san gs--section-title gs--text'>{title}</StyledLabel>
      {children}
    </EventContainer>
  );
}

export default Sections;
