import * as yup from 'yup';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import { ALPHANUMERIC_REGEX } from '@gofan/utils';
import { ERROR_MESSAGES, STRINGS } from '@rates/strings';
import { DATE_FORMAT_DEFAULT, DATE_PICKER_FORMAT_DEFAULT } from '@gofan/constants/date';

import { RateStatus } from '@gofan/api/rates';
import type { RateDTO } from '@gofan/api/rates';
import type { AccountDTO } from '@gofan/api/accounts';
import type { ProductTypeDTO } from '@gofan/api/product-type';

export interface RatesConfigurationFormState extends Partial<RateDTO> {
  alwaysApplyRate?: boolean;
  productTypes?: ProductTypeDTO[];
  ticketTypes?: string[];
  specificSchools?: AccountDTO[];
  exceptionAccounts?: AccountDTO[];
}

export const generateFormState = ({
  mode,
  rate,
  productTypes,
  selectedAccounts
}: {
  mode: 'add' | 'edit';
  rate: RateDTO;
  productTypes: ProductTypeDTO[];
  selectedAccounts: AccountDTO[];
}): RatesConfigurationFormState => {
  let rateProductTypes: ProductTypeDTO[] = [];
  let rateSpecificSchools: AccountDTO[] = [];
  let rateExceptionAccounts: AccountDTO[] = [];
  const rateTicketTypes: string[] = [];
  let startDate: string | Date = '';
  let endDate: string | Date = '';
  let alwaysApplyRate = false;
  if (!isEmpty(rate)) {
    rateProductTypes = productTypes.filter(item => rate.productTypeIds?.includes(item.id));
    rateSpecificSchools = selectedAccounts.filter(item => rate.huddleIds?.includes(item.id));
    rateExceptionAccounts = selectedAccounts.filter(item => rate.exceptions?.includes(item.id));
    if (rate.generalAdmission) {
      rateTicketTypes.push(STRINGS.GENERAL_ADMISSION);
    }
    if (rate.reservedSeating) {
      rateTicketTypes.push(STRINGS.RESERVED_SEATING);
    }
    const mStartDate = dayjs(rate.startDate, DATE_FORMAT_DEFAULT);
    startDate = mStartDate.isValid() ? mStartDate.toDate() : '';
    const mEndDate = dayjs(rate.endDate, DATE_FORMAT_DEFAULT);
    endDate = mEndDate.isValid() ? mEndDate.toDate() : '';
    alwaysApplyRate = isEmpty(rate.endDate);
  }

  return {
    id: rate?.id ?? new Date().getTime(),
    label: rate?.label ?? '',
    rateAmount: rate?.rateAmount ?? '',
    ratePercent: rate?.ratePercent ?? '',
    cashless: rate?.cashless ?? false,
    productTypeIds: rate?.productTypeIds ?? [],
    generalAdmission: rate?.generalAdmission ?? false,
    reservedSeating: rate?.reservedSeating ?? false,
    resourceTypes: rate?.resourceTypes ?? [],
    minPrice: rate?.minPrice ?? '',
    maxPrice: rate?.maxPrice ?? '',
    startDate,
    endDate,
    postSeason: rate?.postSeason ?? '',
    distributionChannels: rate?.distributionChannels ?? [],
    states: rate?.states ?? [],
    huddleIds: rate?.huddleIds ?? [],
    exceptions: rate?.exceptions ?? [],
    status: rate?.status ?? RateStatus.ENABLED,
    // additional
    alwaysApplyRate,
    productTypes: rateProductTypes,
    ticketTypes: rateTicketTypes,
    specificSchools: rateSpecificSchools,
    exceptionAccounts: rateExceptionAccounts
  };
};

const RatesConfigurationSchema = yup.object({
  label: _labelSchemaBuilder(),
  rateAmount: _rateAmountSchemaBuilder(),
  ratePercent: _ratePercentSchemaBuilder(),
  productTypes: _productTypesSchemaBuilder(),
  resourceTypes: _resourceTypesSchemaBuilder(),
  ticketTypes: _ticketTypesSchemaBuilder(),
  minPrice: _minPriceSchemaBuilder(),
  maxPrice: _maxPriceSchemaBuilder(),
  startDate: _startDateSchemaBuilder(),
  endDate: _endDateSchemaBuilder(),
  postSeason: _postSeasonSchemaBuilder(),
  distributionChannels: _distributionChannelsSchemaBuilder(),
  specificSchools: _specificSchoolsSchemaBuilder(),
  exceptionAccounts: _exceptionAccountsSchemaBuilder()
});
export { RatesConfigurationSchema };

function _labelSchemaBuilder() {
  return yup
    .string()
    .required(ERROR_MESSAGES.LABEL.REQUIRED)
    .max(32, ERROR_MESSAGES.LABEL.INVALID)
    .matches(ALPHANUMERIC_REGEX, {
      message: ERROR_MESSAGES.LABEL.INVALID,
      excludeEmptyStrings: true
    });
}

function _rateAmountSchemaBuilder() {
  return yup.string().test('invalidRateAmount', (rateAmount, testContext) => {
    if (!rateAmount) {
      return testContext.createError({
        path: testContext.path,
        message: ERROR_MESSAGES.RATE_AMOUNT.REQUIRED
      });
    }

    return true;
  });
}

function _ratePercentSchemaBuilder() {
  return yup.string().test('invalidRatePercent', (ratePercent, testContext) => {
    if (!ratePercent) {
      return testContext.createError({
        path: testContext.path,
        message: ERROR_MESSAGES.RATE_PERCENT.REQUIRED
      });
    }
    const ratePercentValue = Number(ratePercent);
    if (ratePercentValue < 0 || ratePercentValue > 100) {
      return testContext.createError({
        path: testContext.path,
        message: ERROR_MESSAGES.RATE_PERCENT.INVALID
      });
    }

    return true;
  });
}

function _productTypesSchemaBuilder() {
  return yup.array().test('invalidProductTypes', (productTypes, testContext) => {
    if (!productTypes?.length) {
      return testContext.createError({
        path: testContext.path,
        message: ERROR_MESSAGES.PRODUCT_TYPES.REQUIRED
      });
    }
    return true;
  });
}

function _resourceTypesSchemaBuilder() {
  return yup.array().when('productTypes', productTypes => {
    const hasTicketProductType = !isEmpty(
      productTypes?.find((item: ProductTypeDTO) => item.name.toUpperCase() === 'TICKET')
    );

    if (!hasTicketProductType) {
      return yup.array();
    }

    return yup.array().test('invalidResourceTypes', (resourceTYpes, testContext) => {
      if (!resourceTYpes?.length) {
        return testContext.createError({
          path: testContext.path,
          message: ERROR_MESSAGES.RESOURCE_TYPES.REQUIRED
        });
      }
      return true;
    });
  });
}

function _ticketTypesSchemaBuilder() {
  return yup.array().when('productTypes', productTypes => {
    const hasTicketProductType = !isEmpty(
      productTypes?.find((item: ProductTypeDTO) => item.name.toUpperCase() === 'TICKET')
    );

    if (!hasTicketProductType) {
      return yup.array();
    }

    return yup.array().test('invalidTicketTypes', (ticketTypes, testContext) => {
      if (!ticketTypes?.length) {
        return testContext.createError({
          path: testContext.path,
          message: ERROR_MESSAGES.TICKET_TYPES.REQUIRED
        });
      }
      return true;
    });
  });
}

function _minPriceSchemaBuilder() {
  return yup.string().test('invalidMinPrice', (minPrice, testContext) => {
    if (!minPrice) {
      return testContext.createError({
        path: testContext.path,
        message: ERROR_MESSAGES.MIN_PRICE.REQUIRED
      });
    }
    const { maxPrice } = testContext.parent ?? {};
    if (maxPrice) {
      const minPriceValue = Number(minPrice);
      const maxPriceValue = Number(maxPrice);
      if (minPriceValue >= maxPriceValue) {
        return testContext.createError({
          path: testContext.path,
          message: ERROR_MESSAGES.MIN_PRICE.GREATER_MAX_PRICE
        });
      }
    }

    return true;
  });
}

function _maxPriceSchemaBuilder() {
  return yup.string().test('invalidMaxPrice', (maxPrice, testContext) => {
    if (!maxPrice) {
      return testContext.createError({
        path: testContext.path,
        message: ERROR_MESSAGES.MAX_PRICE.REQUIRED
      });
    }
    const { minPrice } = testContext.parent ?? {};
    if (minPrice) {
      const minPriceValue = Number(minPrice);
      const maxPriceValue = Number(maxPrice);
      if (maxPriceValue <= minPriceValue) {
        return testContext.createError({
          path: testContext.path,
          message: ERROR_MESSAGES.MAX_PRICE.LOWER_MIN_PRICE
        });
      }
    }

    return true;
  });
}

function _startDateSchemaBuilder() {
  return yup.string().when('alwaysApplyRate', alwaysApplyRate => {
    if (alwaysApplyRate) return yup.string();

    return yup.string().test('invalidStartDate', (startDate, testContext) => {
      if (!startDate) {
        return testContext.createError({
          path: testContext.path,
          message: ERROR_MESSAGES.START_DATE.REQUIRED
        });
      }
      const startDateValue = dayjs(startDate, DATE_PICKER_FORMAT_DEFAULT);
      if (!startDateValue.isValid()) {
        return testContext.createError({
          path: testContext.path,
          message: ERROR_MESSAGES.START_DATE.INVALID
        });
      }
      const { endDate } = testContext.parent ?? {};
      const endDateValue = dayjs(endDate, DATE_PICKER_FORMAT_DEFAULT);
      if (endDateValue.isValid() && startDateValue.isAfter(endDateValue)) {
        return testContext.createError({
          path: testContext.path,
          message: ERROR_MESSAGES.START_DATE.AFTER_END_DATE
        });
      }

      return true;
    });
  });
}

function _endDateSchemaBuilder() {
  return yup.string().when('alwaysApplyRate', alwaysApplyRate => {
    if (alwaysApplyRate) return yup.string();

    return yup.string().test('invalidEndDate', (endDate, testContext) => {
      if (!endDate) {
        return testContext.createError({
          path: testContext.path,
          message: ERROR_MESSAGES.END_DATE.REQUIRED
        });
      }
      const endDateValue = dayjs(endDate, DATE_PICKER_FORMAT_DEFAULT);
      if (!endDateValue.isValid()) {
        return testContext.createError({
          path: testContext.path,
          message: ERROR_MESSAGES.END_DATE.INVALID
        });
      }
      const { startDate } = testContext.parent ?? {};
      const startDateValue = dayjs(startDate, DATE_PICKER_FORMAT_DEFAULT);
      if (startDateValue.isValid() && endDateValue.isBefore(startDateValue)) {
        return testContext.createError({
          path: testContext.path,
          message: ERROR_MESSAGES.END_DATE.BEFORE_START_DATE
        });
      }

      return true;
    });
  });
}

function _postSeasonSchemaBuilder() {
  return yup.string().test('invalidPostSeason', (postSeason, testContext) => {
    if (!postSeason) {
      return testContext.createError({
        path: testContext.path,
        message: ERROR_MESSAGES.POST_SEASON.REQUIRED
      });
    }

    return true;
  });
}

function _distributionChannelsSchemaBuilder() {
  return yup.array().test('invalidDistributionChannels', (distributionChannels, testContext) => {
    if (!distributionChannels?.length) {
      return testContext.createError({
        path: testContext.path,
        message: ERROR_MESSAGES.DISTRIBUTION_CHANNELS.REQUIRED
      });
    }
    return true;
  });
}

function _specificSchoolsSchemaBuilder() {
  return yup.array().test('invalidSpecificSchools', (specificSchools, testContext) => {
    const { exceptionAccounts = [] } = testContext.parent ?? {};
    const exceptionAccountIds = exceptionAccounts.map((item: AccountDTO) => item.id);
    if (specificSchools?.some(item => exceptionAccountIds.includes(item.id))) {
      return testContext.createError({
        path: testContext.path,
        message: ERROR_MESSAGES.SPECIFIC_SCHOOLS.ADDED_IN_EXCEPTION_ACCOUNTS
      });
    }
    return true;
  });
}

function _exceptionAccountsSchemaBuilder() {
  return yup.array().test('invalidExceptionAccounts', (exceptionAccounts, testContext) => {
    const { specificSchools = [] } = testContext.parent ?? {};
    const specificSchoolIds = specificSchools.map((item: AccountDTO) => item.id);
    if (exceptionAccounts?.some(item => specificSchoolIds.includes(item.id))) {
      return testContext.createError({
        path: testContext.path,
        message: ERROR_MESSAGES.EXCEPTION_ACCOUNTS.ADDED_IN_SPECIFIC_SCHOOLS
      });
    }
    return true;
  });
}
