import styled, { css } from 'styled-components';

export default {
  container: css`
    flex-direction: row;
    display: flex;
  `,
  dateContainer: css`
    text-align: left;
  `,
  dateInputContainer: css`
    padding-right: 10px;
  `,
  timeContainer: css`
    padding-left: 10px;
  `,
  timePickerContainer: css`
    text-align: left;
    flex-direction: row;
    display: flex;
  `,
  timePickerSelectContainer: css`
    display: flex;
    padding-left: 2px;
  `
};

export const StyledContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: ${(props) => (props.isCompact ? 'space-between' : 'flex-start')};
  ${(props) => props.isCompact && css`
    .bx--date-picker__input {
      width: 100% !important;
    }
  `};
`;
