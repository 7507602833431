import { isEmpty } from 'lodash';
import { sortStates } from '@old-components/basic-table/sorting';
import type { SortBy } from '@old-components/basic-table/sorting';

export type LastEvaluatedKeyType = {
  batchId: string;
  eventId: string;
  fileName: string;
  metaData: string;
};

export type UploadSearchParams = {
  body: {
    LastEvaluatedKey?: LastEvaluatedKeyType;
    searchBy?: string;
    searchTerm?: string;
    dateTime?: string;
  };
  sortBy: SortBy;
  page: number;
  pageSize: number;
  pageSizeTable: number;
};

export type ItemUpload = {
  batchId: string;
  email: string;
  eventId: string;
  eventsCount: number;
  expectedEventCount: number;
  fileName: string;
  metaData: string;
  uploadAt: string;
  action: 'create' | 'update';
};

export type UploadResponses = {
  Count: number;
  Items: ItemUpload[];
  LastEvaluatedKey: LastEvaluatedKeyType;
  ScannedCount: number;
};

export const SEARCH_PARAMS: UploadSearchParams = {
  page: 0,
  pageSize: 500,
  pageSizeTable: 25,
  body: {
    searchBy: 'uploadAt',
    searchTerm: '',
    dateTime: ''
  },
  sortBy: { header: 'uploadAt', sortDirection: sortStates.DESC }
};

export const getSortBy = ({ header, sortDirection }: SortBy) => {
  if (!sortDirection || sortDirection === sortStates.NONE) {
    if (isEmpty(SEARCH_PARAMS.sortBy) || `${SEARCH_PARAMS.sortBy.header}` === `${header}`) {
      return {};
    }
    return SEARCH_PARAMS.sortBy;
  }
  return { header, sortDirection };
};

export const mixedSort = (a: any, b: any, sortOrder: string): number => {
  if (typeof a === 'string' && typeof b === 'string') {
    return sortOrder === sortStates.ASC ? a.localeCompare(b) : b.localeCompare(a);
  }
  if (typeof a === 'number' && typeof b === 'number') {
    return sortOrder === sortStates.ASC ? a - b : b - a;
  }
  if (a instanceof Date && b instanceof Date) {
    return sortOrder === sortStates.ASC ? a.getTime() - b.getTime() : b.getTime() - a.getTime();
  }

  const aStr = a.toString();
  const bStr = b.toString();
  if (aStr < bStr) return sortOrder === 'ASC' ? -1 : 1;
  if (aStr > bStr) return sortOrder === 'ASC' ? 1 : -1;
  return 0;
};

export const mappingPills = (filterValue: { uploadedBy?: string; uploadedDate?: string }) => {
  if (isEmpty(filterValue)) return [];
  const pills = [];
  const { uploadedBy, uploadedDate } = filterValue;
  if (uploadedBy) pills.push({ id: uploadedBy, text: uploadedBy, type: 'uploadedBy' });
  if (uploadedDate) pills.push({ id: uploadedDate, text: uploadedDate, type: 'uploadedDate' });

  return pills;
};
