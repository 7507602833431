/* eslint-disable camelcase */
import BaseDTO from './BaseDTO';
import MetaDataDTO from './MetaDataDTO';

class RefundedTransactionDTO extends BaseDTO {
  constructor({
    id,
    object,
    amount,
    balance_transaction,
    charge,
    created,
    currency,
    metadata,
    payment_intent,
    reason,
    receipt_number,
    source_transfer_reversal,
    status,
    transfer_reversal
  }) {
    super({
      id,
      object,
      amount,
      balance_transaction,
      charge,
      created,
      currency,
      metadata,
      payment_intent,
      reason,
      receipt_number,
      source_transfer_reversal,
      status,
      transfer_reversal
    });
  }

  get id() {
    return this.raw.id;
  }

  get object() {
    return this.raw.object;
  }

  get amount() {
    return this.raw.amount;
  }

  get balance_transaction() {
    return this.raw.balance_transaction;
  }

  get charge() {
    return this.raw.charge;
  }

  get created() {
    return this.raw.created;
  }

  get currency() {
    return this.raw.currency;
  }

  get metadata() {
    return new MetaDataDTO(this.raw.metadata);
  }

  get payment_intent() {
    return this.raw.payment_intent;
  }

  get reason() {
    return this.raw.reason;
  }

  get receipt_number() {
    return this.raw.receipt_number;
  }

  get source_transfer_reversal() {
    return this.raw.source_transfer_reversal;
  }

  get status() {
    return this.raw.status;
  }

  get transfer_reversal() {
    return this.raw.transfer_reversal;
  }
}
export default RefundedTransactionDTO;
