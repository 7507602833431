// @flow
import AccountDAO from '../dao/AccountDAO';
import { formatDate } from '../../utils/dateUtils';
import { isEmpty } from '../../utils/objectUtils';
import { ACCOUNTS_CACHE_NAME, putDataToCache } from './CacheService';

export const fetchAccounts = (searchRequest, pageRequest, sortableRequest) =>
  AccountDAO.fetchAccounts(searchRequest, pageRequest, sortableRequest);

export const fetchAccountById = async accountId => {
  const response = await AccountDAO.fetchAccountById(accountId);
  if (!isEmpty(response.id)) {
    putDataToCache(ACCOUNTS_CACHE_NAME, response.id, response);
  }
  return response;
};

export const fetchAccountsByKeyword = (keyword, coordinate, notAllowElementarySchool) =>
  AccountDAO.fetchAccountsByKeyword(keyword, coordinate, notAllowElementarySchool);

export const cancelFetchAccountsByKeyword = () => AccountDAO.cancelFetchAccountsByKeyword();

export const searchAccountsInSeason = (
  keyword,
  coordinate,
  allowAccountType = false,
  notAllowElementarySchool = false
) => AccountDAO.searchAccountsInSeason(keyword, coordinate, allowAccountType, notAllowElementarySchool);

export const cancelSearchAccountsInSeason = () => AccountDAO.cancelSearchAccountsInSeason();

export const getCalculatedValues = (key, form, rates) => {
  const ticket = (form.tickets && form.tickets.value).find(item => (item.id || item.key) === key) || {};
  const ticketPrice = form[`tickets.${key}.ticketPrice`] || {};
  const accountPaid = form[`tickets.${key}.accountPaid`] || {};
  const productTypeId = form.productTypeId || {};
  const notTouched = !ticketPrice.touched && !productTypeId.touched && !accountPaid.touched;

  if (notTouched) {
    return {
      fee: ticket.fee,
      fanPrice: ticket.fanPrice,
      payToSchool: ticket.payToSchool
    };
  }

  const currentDate = new Date(formatDate({ date: new Date(), format: 'MM/DD/YYYY' }));
  const value = parseFloat(ticketPrice.value || 0);
  const rate = rates.find(item => {
    const startDate = new Date(formatDate({ date: item.startDate, format: 'MM/DD/YYYY' }));
    const endDate = new Date(formatDate({ date: item.endDate, format: 'MM/DD/YYYY' }));

    return item.minPrice <= value && value <= item.maxPrice && startDate <= currentDate && currentDate <= endDate;
  });

  if (!ticketPrice.value || !rate) {
    return {
      fee: '',
      fanPrice: '',
      payToSchool: ''
    };
  }

  const fee = rate.rateAmount + (value * rate.ratePercent) / 100;
  return {
    fee: fee.toFixed(2),
    fanPrice: (accountPaid.value ? value : value + fee).toFixed(2),
    payToSchool: (accountPaid.value ? value - fee : value).toFixed(2)
  };
};

export default {
  fetchAccounts,
  fetchAccountById,
  searchAccountsInSeason,
  fetchAccountsByKeyword,
  getCalculatedValues
};
