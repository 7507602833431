// @flow

import BaseDTO from './BaseDTO';

class MetaDataDTO extends BaseDTO {
  constructor({ orderAccessToken, purchasedTicketsAccessTokens }) {
    super({ orderAccessToken, purchasedTicketsAccessTokens });
  }

  get orderAccessToken() {
    return this.raw.orderAccessToken;
  }

  get purchasedTicketsAccessTokens() {
    return this.raw.purchasedTicketsAccessTokens;
  }
}

export default MetaDataDTO;
