import { ReservedSeatingRepository } from './reserved-seating.repository';
import type { ReservedSeatingDTO, BlockedReservedSeatingDTO } from './reserved-seating.model';
import type { UpdateReservedSeatingTicketsParams } from './reserved-seating.repository';

import { SEAT_STATUS } from '@gofan/constants/reserved-seating';

class ReservedSeatingService {
  static blockReservedSeating = (
    eventId: number,
    seasonId: number,
    seatsIoLabel: string
  ): Promise<BlockedReservedSeatingDTO> =>
    ReservedSeatingRepository.blockReservedSeating(eventId, seasonId, seatsIoLabel);

  static syncReservedSeating = (): Promise<void> => ReservedSeatingRepository.syncReservedSeating();

  static getReservedSeatingChart = (chartKey: string) => ReservedSeatingRepository.getReservedSeatingChart(chartKey);

  static getReservedSeatingChartCategories = (chartKey: string) =>
    ReservedSeatingRepository.getReservedSeatingChartCategories(chartKey);

  static moveReservedSeatingTickets = (params: UpdateReservedSeatingTicketsParams) =>
    ReservedSeatingRepository.moveReservedSeatingTickets(params);

  static unbookReservedSeatingTickets = (params: UpdateReservedSeatingTicketsParams) =>
    ReservedSeatingRepository.unbookReservedSeatingTickets(params);

  static getSeasonReservedSeatsByStatus = async (seasonId: string | number) => {
    const [seatStatuses, unavailableSeats] = await Promise.all([
      ReservedSeatingRepository.getSeasonSeatsByStatus(`${seasonId}`, Object.values(SEAT_STATUS)),
      ReservedSeatingRepository.getSeasonUnavailableSeats(`${seasonId}`)
    ]);

    let allSeatStatuses = {};

    (seatStatuses ?? [])?.forEach(seat => {
      allSeatStatuses = {
        ...allSeatStatuses,
        [seat?.seatsIoLabel]: { ...seat }
      };
    });

    (unavailableSeats ?? [])?.forEach(seat => {
      allSeatStatuses = {
        ...allSeatStatuses,
        [seat?.seatsIoLabel]: { ...seat, reservationState: SEAT_STATUS.UNAVAILABLE }
      };
    });

    return allSeatStatuses;
  };
}

export { ReservedSeatingService };
