import type { ActivityDTO } from '@gofan/api/activities';
import type { SeasonManagementActionType } from '@season-management/middleware/actionTypes';
import {
  ON_RESET_SEASON_MANAGEMENT_STATE,
  ON_RESET_STATE_WHEN_UN_MOUNT,
  ON_SET_DATA_TO_SETUP_MULTI_SEASON,
  ON_UPDATE_SEASON,
  ON_UPDATE_CREATING_SEASON_LIST,
  ON_SELECT_SPORT,
  ON_SET_ACTIVED_SPORT,
  ON_BUILD_SINGLE_SPORT,
  ON_UPDATE_BUILD_PROCESS,
  ON_CANCELLED_TASK_BUILD_SINGLE_SPORT,
  ON_BUILD_MULTI_SPORT,
  ON_CANCELLED_TASK_BUILD_MULTI_SPORT,
  ON_PUBLISH_SINGLE_SEASON,
  ON_UPDATE_PUBLISH_PROCESS,
  ON_CANCELLED_TASK_PUBLISH_SINGLE_SEASON,
  ON_UPDATE_PARTNER_EVENTS_STATUS,
  ON_CANCELLED_TASK_BUILD_PARTNER_SEASONS,
  ON_BUILD_ALL_PARTNER_SEASONS,
  ON_CANCELLED_TASK_BUILD_ALL_PARTNER_SEASONS,
  ON_UPDATE_TICKET_TYPES
} from '@season-management/middleware/actionTypes';

import type {
  ActivedSport,
  BuildSingleSportConfig,
  BuildRawSeasonProcess,
  PublishProcess,
  PublishSingleSeasonConfig,
  DataToSetupMultiSeason,
  DataPartnerEventsStatus,
  BuildAllPartnerSeasonsConfig,
  BuildMultiSportConfig
} from '@season-management/middleware/types';
import type { CreatingSeason } from '@season-management/middleware/models/creating-season.model';

export const resetSeasonManagementState = (): SeasonManagementActionType => ({
  type: ON_RESET_SEASON_MANAGEMENT_STATE
});

export const reseStateWhenUnmount = (): SeasonManagementActionType => ({
  type: ON_RESET_STATE_WHEN_UN_MOUNT
});

export const setDataToSetupMultiSeason = (dataSetup: DataToSetupMultiSeason): SeasonManagementActionType => ({
  type: ON_SET_DATA_TO_SETUP_MULTI_SEASON,
  payload: {
    dataSetup
  }
});

export const onUpdateSeason = (updatedSeason: any): SeasonManagementActionType => ({
  type: ON_UPDATE_SEASON,
  payload: {
    updatedSeason
  }
});

export const onUpdateCreatingSeasonList = (creatingSeasonList: CreatingSeason[]): SeasonManagementActionType => ({
  type: ON_UPDATE_CREATING_SEASON_LIST,
  payload: {
    creatingSeasonList
  }
});

export const setSelectedSports = (selectedSports: ActivityDTO[]): SeasonManagementActionType => ({
  type: ON_SELECT_SPORT,
  payload: {
    selectedSports
  }
});

export const setActivedSport = (activedSport: ActivedSport): SeasonManagementActionType => ({
  type: ON_SET_ACTIVED_SPORT,
  payload: {
    activedSport
  }
});

export const onUpdateBuildProcess = (buildingProcess: BuildRawSeasonProcess | null): SeasonManagementActionType => ({
  type: ON_UPDATE_BUILD_PROCESS,
  payload: { buildingProcess }
});

export const onBuildSeasonForSingleSport = (buildConfig: BuildSingleSportConfig): SeasonManagementActionType => ({
  type: ON_BUILD_SINGLE_SPORT,
  payload: {
    ...buildConfig
  }
});

export const onCancelledTaskBuildSingleSport = (): SeasonManagementActionType => ({
  type: ON_CANCELLED_TASK_BUILD_SINGLE_SPORT
});

export const onBuildSeasonForMultiSport = (buildConfig: BuildMultiSportConfig): SeasonManagementActionType => ({
  type: ON_BUILD_MULTI_SPORT,
  payload: {
    ...buildConfig
  }
});

export const onCancelledTaskBuildMultiSport = (): SeasonManagementActionType => ({
  type: ON_CANCELLED_TASK_BUILD_MULTI_SPORT
});

export const onCancelledTaskBuildPartnerSeasons = (): SeasonManagementActionType => ({
  type: ON_CANCELLED_TASK_BUILD_PARTNER_SEASONS
});

export const onBuildAllPartnerSeasons = (buildConfig: BuildAllPartnerSeasonsConfig): SeasonManagementActionType => ({
  type: ON_BUILD_ALL_PARTNER_SEASONS,
  payload: {
    ...buildConfig
  }
});

export const onUpdateTicketTypes = (updatedTicketTypes: any): SeasonManagementActionType => ({
  type: ON_UPDATE_TICKET_TYPES,
  payload: {
    ticketTypes: updatedTicketTypes
  }
});

export const onCancelledTaskBuildAllPartnerSeasons = (): SeasonManagementActionType => ({
  type: ON_CANCELLED_TASK_BUILD_ALL_PARTNER_SEASONS
});

export const onUpdatePublishProcess = (publishProcess: PublishProcess | null): SeasonManagementActionType => ({
  type: ON_UPDATE_PUBLISH_PROCESS,
  payload: { publishProcess }
});

export const onPublishSingleSeason = (publishConfig: PublishSingleSeasonConfig): SeasonManagementActionType => ({
  type: ON_PUBLISH_SINGLE_SEASON,
  payload: { ...publishConfig }
});

export const onUpdatePartnerEventsStatus = (
  partnerEventsStatusData: DataPartnerEventsStatus
): SeasonManagementActionType => ({
  type: ON_UPDATE_PARTNER_EVENTS_STATUS,
  payload: { partnerEventsStatusData }
});

export const onCancelledTaskPublishSingleSeason = (): SeasonManagementActionType => ({
  type: ON_CANCELLED_TASK_PUBLISH_SINGLE_SEASON
});
