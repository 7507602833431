import moment from 'moment';
import { get, omit, uniq, uniqBy, isEmpty, isNil, uniqueId } from 'lodash';

import {
  switchZone,
  getTimeZone,
  formatDateTime,
  convertDateTimeZone,
  EVENT_DATE_FORMAT_WITH_TIMEZONE
} from '@utils/dateUtils';

import EventScheduleRepository from '@modules/event-integrations_V2/repositories/event-schedule.repository';
import EventRepository from '@modules/events/repositories/events.repository';

import {
  PUBLISH_TYPE,
  UPDATED_STATUS,
  IGNORED_STATUS,
  DELETED_STATUS,
  CANCELED_STATUS,
  PUBLISHED_STATUS,
  COMPLETED_STATUSES,
  INVALID_ACCOUNT_IDS,
  INTEGRATION_PARTNERS,
  INTERNAL_PARTNERS
} from '@modules/event-integrations_V2/constants/constants';
import { GENDER_TYPE, DEFAULT_START_TIME, EVENT_START_TIME_TYPE } from '@season-management/constants/constant';
import { EXPAND_EVENT_FIELDS } from '@seasons/constants/constants';
import { RESOLVE_DUPLICATED_TYPES } from '@app/pages/SeasonSetup/constants';

import type { ActivityDTO } from '@gofan/api/activities';
import type { LevelDTO } from '@gofan/api/levels';
import type { EventDTO, EventPageResponseDTO, EventSearchDTO, ImportEventDTO } from '@events/models/event.model';
import type { AccountDTO, AccountVenue } from '@gofan/api/accounts';
import type { ActivityLevelsDTO, AccountProductDTO, AccountProductLandingPageDTO } from '@gofan/api/account-products';

import type { SeasonDTO, GlobalEventDTO, GlobalEventStatus } from '@seasons/models/season.model';
import type { LevelsByGender, StartTimeTeams } from '@season-management/middleware/models/model';
import RawProductModel from '@season-management/middleware/models/raw-product.model';
import type { RawEvent } from '@season-management/middleware/models/raw-event.model';
import RawEventModel from '@season-management/middleware/models/raw-event.model';
import type { RawSeason } from '@season-management/middleware/models/raw-season.model';
import RawSeasonModel from '@season-management/middleware/models/raw-season.model';
import type { CreatingSeason } from '@season-management/middleware/models/creating-season.model';
import type { VenueInformationValue } from '@season-management/pages/season-general-setup/season-venue-section/season-venue-section.component';

import type { ScheduleData, ScheduleVenue, ScheduleLevel, PartnerSchedule } from '@modules/event-integrations_V2/hooks';
import type {
  PartnerInsight,
  EventIgnoreDTO,
  EventRestoreDTO,
  EventScheduleDTO,
  EventScheduleSearchDTO,
  EventScheduleSeasonDTO,
  EventPartnerSalesInfoDTO,
  EventSchedulePartnerInsightDTO
} from '@modules/event-integrations_V2/models/event-schedule.model';
import { EventUpdateRequest } from '@modules/event-integrations_V2/models/event-update-request.model';

import EventService from '@events/services/events.service';
import SeasonService from '@seasons/services/season.service';
import { AccountService } from '@gofan/api/accounts';
import {
  hasEventTicketSold,
  getPartnerEventName,
  generateOpponentName,
  generateDefaultEventName
} from '@app/api/services/EventService';
import { mappingDataDuplicateEvents } from '@app/pages/SeasonSetup/helpers';
import SeasonCreationUtil from '@season-management/utils/season-creation.utils';
import { DATE_TIME_PICKER_FORMAT_DEFAULT } from '@gofan/constants';
import { getScheduleAccountsByHomeSchool } from '../utils';
import { EventConfigService } from './event-config.service';

class EventScheduleService {
  searchEventSchedulesByParams = (searchParams: EventScheduleSearchDTO): Promise<EventScheduleSeasonDTO[]> =>
    EventScheduleRepository.searchEventByParams(searchParams);

  getEventMonitorIndicator = (
    eventScheduleSearch: EventScheduleSearchDTO,
    manualErrorHandling = true
  ): Promise<boolean> => EventScheduleRepository.getEventMonitorIndicator(eventScheduleSearch, manualErrorHandling);

  getEventScheduleSeason = (
    eventScheduleSearch: Partial<EventScheduleSearchDTO>,
    manualErrorHandling = true
  ): Promise<EventScheduleDTO[]> =>
    EventScheduleRepository.getEventScheduleSeason(eventScheduleSearch, manualErrorHandling);

  updateGlobalEvents = (payload: Partial<GlobalEventDTO>[]) =>
    Promise.all(payload.map(event => EventScheduleRepository.updateGlobalEvent(event.id!, event as any)));

  updateGlobalEvent = (payload: GlobalEventStatus) => EventScheduleRepository.updateGlobalEvent(payload.id, payload);

  getGlobalEventPartnerInsight = (searchParams: EventScheduleSearchDTO, manualErrorHandling = true) =>
    searchParams?.schoolIds?.length === 0
      ? Promise.resolve([] as EventSchedulePartnerInsightDTO[])
      : EventScheduleRepository.getGlobalEventPartnerInsight(searchParams, manualErrorHandling);

  getAwayEventInsight = (searchParams: EventScheduleSearchDTO, manualErrorHandling = true) =>
    searchParams?.schoolIds?.length === 0
      ? Promise.resolve({} as EventSchedulePartnerInsightDTO)
      : EventRepository.getInternalEventPartnerInsight(searchParams, manualErrorHandling);

  searchAwayEventSchedulesByParams = (body: EventSearchDTO, queryStr: string, manualErrorHandling = true) => {
    let queryString = '?skipCache=true';
    if (queryStr) {
      queryString = queryStr;
    }
    return body?.accountIds?.length === 0
      ? Promise.resolve({} as EventPageResponseDTO)
      : EventRepository.searchEventByParams(body, queryString);
  };

  getEventPartnerSalesInfo = (searchParams: EventScheduleSearchDTO, manualErrorHandling = true) =>
    searchParams?.schoolIds?.length === 0
      ? Promise.resolve([] as EventPartnerSalesInfoDTO[])
      : EventScheduleRepository.getEventPartnerSalesInfo(searchParams, manualErrorHandling);

  getPartnerInsightAndSalesInfo = async (searchParams: EventScheduleSearchDTO) =>
    Promise.all([this.getGlobalEventPartnerInsight(searchParams), this.getEventPartnerSalesInfo(searchParams)])
      .then(([insightData, salesInfoData]) => ({ insightData, salesInfoData } as PartnerInsight))
      .catch(() => ({ insightData: [], salesInfoData: [] } as PartnerInsight));

  ignoreSchedule = (payload: EventIgnoreDTO, manualErrorHandling = true) =>
    EventScheduleRepository.ignoreSchedule(payload, manualErrorHandling);

  restoreSchedule = (payload: EventRestoreDTO, manualErrorHandling = true) =>
    EventScheduleRepository.restoreSchedule(payload, manualErrorHandling);

  isPartnerActivity = (partnerEventSchedules: EventScheduleDTO[], activity: ActivityLevelsDTO) =>
    (partnerEventSchedules ?? [])?.some(
      eventSchedule =>
        activity?.label === eventSchedule?.sportName &&
        (activity?.levels ?? [])?.some(level => level?.name === eventSchedule?.eventLevel)
    );

  indexOfFirstArbiterActivity = (partnerEventSchedules: EventScheduleDTO[], activities: ActivityLevelsDTO[]) =>
    (activities ?? [])?.findIndex(activity => this.isPartnerActivity(partnerEventSchedules, activity));

  hasPartnerGameInTicketType = (partnerEventSchedules: EventScheduleDTO[], ticketType: AccountProductLandingPageDTO) =>
    (ticketType?.activities ?? [])?.some(activity => this.isPartnerActivity(partnerEventSchedules, activity));

  getParamToCheckEventDuplicate = (events: GlobalEventDTO[], accounts: AccountDTO[]) =>
    events?.reduce(
      (params: ImportEventDTO[], event: GlobalEventDTO) => [
        ...params,
        {
          eventStartDate: convertDateTimeZone({
            date: event.startEventDt,
            format: EVENT_DATE_FORMAT_WITH_TIMEZONE as any,
            timeZone: getTimeZone(accounts?.find(acc => `${acc.id}` === `${event.homeSchoolId}`)?.timeZone) as any
          }),
          genders: event?.gender?.toUpperCase() === GENDER_TYPE.COED ? [] : [event.gender],
          homeSchoolId: event.homeSchoolId,
          opponentSchoolId: event.awaySchoolId,
          sportName: event.eventReportingType,
          globalEventId: event.id,
          globalEventRef: event,
          partnerName: event.sourceName
        } as ImportEventDTO
      ],
      []
    );

  getGlobalEventChangedType = (globalEvent: GlobalEventDTO, goFanEvent: any) => {
    const saleInfo = get(goFanEvent, `_embedded.event-sales-info`, {});

    if (!globalEvent.gofanStatusMsg) {
      return PUBLISH_TYPE.CREATE;
    }

    if (globalEvent.sourceStatus === CANCELED_STATUS) {
      if (!hasEventTicketSold(saleInfo)) {
        return PUBLISH_TYPE.DELETE;
      }
      return PUBLISH_TYPE.CANCEL;
    }

    if (globalEvent.sourceIsDeleted) {
      if (hasEventTicketSold(saleInfo)) {
        return PUBLISH_TYPE.CANCEL;
      }

      return PUBLISH_TYPE.DELETE;
    }

    if (globalEvent.sourceHasPendingRequest) {
      const endDateTime = globalEvent.changes?.find(({ field }) => field === 'endDateTime')?.value;
      if (endDateTime) {
        const isAfterGFEventDate = moment(endDateTime).isAfter(goFanEvent?.endDateTime);
        if (hasEventTicketSold(saleInfo)) {
          return PUBLISH_TYPE.HAS_SOLD_WITH_UPDATED_END_DATE_TIME;
        }
        if (isAfterGFEventDate) {
          return PUBLISH_TYPE.AFTER_END_DATE_TIME;
        }
      }
      return PUBLISH_TYPE.UPDATE;
    }

    return '';
  };

  getDuplicateEvents = async ({
    scheduleData,
    partnerSchedule
  }: {
    scheduleData?: ScheduleData;
    partnerSchedule: PartnerSchedule;
  }) => {
    const { schedule } = partnerSchedule ?? {};
    const { accounts } = scheduleData ?? {};

    const isUpdatedSchedule = `${schedule?.status}`.toLowerCase() === UPDATED_STATUS.toLowerCase();
    const filteredEvents =
      schedule?.events?.filter(
        ({ gofanStatusMsg, gofanEventId }) =>
          gofanStatusMsg?.toUpperCase() !== DELETED_STATUS.toUpperCase() &&
          !(gofanStatusMsg?.toUpperCase() === CANCELED_STATUS.toUpperCase() && !gofanEventId)
      ) ?? [];
    const publishedScheduleEvents =
      filteredEvents.filter(
        ({ gofanEventId, gofanStatusMsg, changes }) =>
          !!gofanEventId && `${gofanStatusMsg}`.toLowerCase() === PUBLISHED_STATUS.toLowerCase() && isEmpty(changes)
      ) ?? [];

    const updatedScheduleEvents =
      filteredEvents.filter(
        ({ gofanEventId, gofanStatusMsg, changes }) => !!gofanEventId && !!gofanStatusMsg && !isEmpty(changes)
      ) ?? [];

    const waitingEvents = filteredEvents.filter(event => !event.gofanStatusMsg) ?? [];

    const duplicateCheckParams = this.getParamToCheckEventDuplicate(waitingEvents, accounts as AccountDTO[]);

    const opponentSchoolIds = uniq(
      (duplicateCheckParams ?? [])
        ?.filter(
          (event: any) =>
            !!event?.opponentSchoolId && !INVALID_ACCOUNT_IDS.includes(`${event?.opponentSchoolId}`.toLowerCase())
        )
        ?.map((event: any) => event.opponentSchoolId)
    );

    const [duplicateEvents, awaySchools] = await Promise.all([
      isEmpty(duplicateCheckParams) ? [] : EventService.checkDuplicateEvent(duplicateCheckParams),
      isEmpty(opponentSchoolIds) ? [] : SeasonService.fetchArbiterSchools(opponentSchoolIds)
    ]);

    const publishedEvents = await Promise.all(
      publishedScheduleEvents?.map(event =>
        EventService.getEventById(event.gofanEventId as string, EXPAND_EVENT_FIELDS)
          .catch(() => ({} as EventDTO))
          .then(goFanEvent => ({
            ...event,
            goFanEvent,
            partnerId: event?.sourceEventId ?? '',
            partnerName: event?.sourceName ?? '',
            globalEventId: event?.id ?? '',
            eventName: goFanEvent?.name ?? '',
            startEventDt: goFanEvent?.startDateTime,
            endEventDt: goFanEvent?.endDateTime,
            awaySchoolId: goFanEvent?.opponentAccountId,
            financialAccountId: goFanEvent?.financialAccountId
          }))
      )
    );

    const updatedEvents = await Promise.all(
      updatedScheduleEvents?.map(globalEvent =>
        EventService.getEventById(globalEvent.gofanEventId as string, EXPAND_EVENT_FIELDS)
          .catch(() => ({} as EventDTO))
          .then(goFanEvent => {
            const changedType = this.getGlobalEventChangedType(globalEvent, goFanEvent);
            let changedData = {};

            if (
              changedType === PUBLISH_TYPE.UPDATE ||
              changedType === PUBLISH_TYPE.AFTER_END_DATE_TIME ||
              changedType === PUBLISH_TYPE.HAS_SOLD_WITH_UPDATED_END_DATE_TIME
            ) {
              changedData = new EventUpdateRequest({ globalEvent, goFanEvent }).toJSON();
            } else if (!changedType) {
              changedData = {};
            }

            return {
              ...globalEvent,
              created: true,
              goFanEvent,
              changedType,
              changedData,
              partnerId: globalEvent?.sourceEventId ?? '',
              partnerName: globalEvent?.sourceName ?? '',
              globalEventId: globalEvent?.id ?? '',
              eventName: goFanEvent?.name ?? '',
              startEventDt: goFanEvent?.startDateTime,
              endEventDt: goFanEvent?.endDateTime,
              awaySchoolId: goFanEvent?.opponentAccountId,
              financialAccountId: goFanEvent?.financialAccountId
            };
          })
      )
    );

    const newSchedule = {
      ...schedule,
      created: isUpdatedSchedule,
      duplicatedEvents: mappingDataDuplicateEvents(duplicateCheckParams, duplicateEvents, awaySchools),
      events: filteredEvents.map(scheduleEvent => {
        let foundEvent = publishedEvents?.find(event => `${event.id}` === `${scheduleEvent.id}`);

        if (!isEmpty(foundEvent)) return { ...foundEvent };

        foundEvent = updatedEvents?.find(event => `${event.id}` === `${scheduleEvent.id}`);

        return isEmpty(foundEvent) ? { ...scheduleEvent } : { ...foundEvent };
      })
    };

    return {
      ...partnerSchedule,
      schedule: newSchedule
    };
  };

  getDuplicateSchedules = async ({
    scheduleData,
    partnerSchedules
  }: {
    scheduleData?: ScheduleData;
    partnerSchedules: PartnerSchedule[];
  }) => {
    const availableSchedules = (partnerSchedules ?? []).filter(
      ({ schedule }) =>
        !COMPLETED_STATUSES.includes(schedule?.status) &&
        (`${schedule?.status}`.toLowerCase() !== PUBLISHED_STATUS.toLowerCase() ||
          `${schedule?.status}`.toLowerCase() === UPDATED_STATUS.toLowerCase())
    );

    const duplicatedSchedules = await Promise.all(
      availableSchedules.map(partnerSchedule => this.getDuplicateEvents({ scheduleData, partnerSchedule }))
    );

    return {
      schedules: duplicatedSchedules as PartnerSchedule[],
      duplicatedEvents: (duplicatedSchedules ?? []).reduce((results: ImportEventDTO[], { schedule }: any) => {
        const filteredList = (schedule?.duplicatedEvents ?? []).filter((item: any) => !isEmpty(item?.events));
        return [...results, ...(filteredList ?? [])];
      }, [])
    };
  };

  resolveDuplicateSchedules = ({
    resolvedEvents,
    partnerSchedules,
    scheduleData
  }: {
    resolvedEvents: any[];
    partnerSchedules: PartnerSchedule[];
    scheduleData: ScheduleData;
  }) => {
    const newSchedules = (partnerSchedules ?? []).map(item => {
      const { schedule } = item ?? {};
      const { financialAccount, taggedAccounts } = getScheduleAccountsByHomeSchool(
        schedule?.homeSchoolId,
        scheduleData
      );

      const newEvents = (schedule?.events ?? []).reduce((results: any[], scheduleEvent: any) => {
        const resolvedEvent = (resolvedEvents ?? []).find(
          event => `${event?.importEvent?.id}` === `${scheduleEvent?.id}`
        );

        // Non-duplicate
        if (isEmpty(resolvedEvent) || isEmpty(resolvedEvent?.events)) {
          results?.push({ ...scheduleEvent });
          return results;
        }

        const globalEventId = resolvedEvent?.importEvent?.globalEventId ?? null;
        const sourceName = resolvedEvent?.importEvent?.globalEventRef?.sourceName;
        const sourceEventId = resolvedEvent?.importEvent?.globalEventRef?.sourceEventId;

        const keptEvents = resolvedEvent?.events?.filter((event: any) => event?.keep);
        const isKeptEvent = !isEmpty(keptEvents);
        const isUsedEvent = resolvedEvent?.importEvent?.keep;

        // Only Used Partner Event
        if (isUsedEvent && !isKeptEvent) {
          const firstGoFanEvent = resolvedEvent?.events?.[0];
          results?.push({
            ...scheduleEvent,
            created: true,
            resolveType: RESOLVE_DUPLICATED_TYPES.ONLY_USED_PARTNER_EVENT,
            resolveData: {
              name: scheduleEvent?.eventName ?? '',
              endDateTime: scheduleEvent?.endEventDt,
              startDateTime: scheduleEvent?.startEventDt,
              opponentAccountId: scheduleEvent?.awaySchoolId,
              financialAccountId: financialAccount?.id,
              taggedAccountIds: taggedAccounts?.map(account => account.id) ?? []
            },
            goFanEvent: firstGoFanEvent,
            gofanEventId: firstGoFanEvent?.id,
            partnerId: sourceEventId,
            partnerName: sourceName,
            globalEventId
          });
          return results;
        }

        // Kept both for Partner Event
        if (isUsedEvent) {
          results?.push({
            ...scheduleEvent,
            created: false,
            resolveType: RESOLVE_DUPLICATED_TYPES.KEPT_BOTH_PARTNER_EVENT,
            resolveData: {},
            partnerId: sourceEventId,
            partnerName: sourceName,
            globalEventId
          });
        }

        // Only Kept GoFan Event or Kept both for GoFan Event
        keptEvents?.forEach((event: any, index: number) => {
          const resolveType = isUsedEvent
            ? RESOLVE_DUPLICATED_TYPES.KEPT_BOTH_GF_EVENT
            : RESOLVE_DUPLICATED_TYPES.ONLY_KEPT_GF_EVENT;
          const resolveData = {
            // use GoFan Data
            name: event?.name ?? '',
            endDateTime: event?.endDateTime,
            startDateTime: event?.startDateTime,
            opponentAccountId: event?.opponentAccountId ?? null,
            financialAccountId: event?.financialAccountId ?? null,
            taggedAccountIds: event?.taggedAccountIds ?? []
          };

          // Kept both for GoFan Event Or Only Kept GoFan Event (not first item)
          if (isUsedEvent || index !== 0) {
            results?.push({
              ...omit(scheduleEvent, ['id']),
              id: uniqueId('resolve-event-'),
              created: true,
              resolveType,
              resolveData: {
                ...resolveData,
                isOnlyGoFanEvent: true
              },
              goFanEvent: event,
              gofanEventId: event?.id,
              partnerId: event?.partnerId ?? null,
              partnerName: event?.partnerName ?? null,
              globalEventId: event?.globalEventsId ?? null
            });
          }

          // Only Kept GoFan Event (first item)
          if (!isUsedEvent && index === 0) {
            results?.push({
              ...scheduleEvent,
              created: true,
              resolveType,
              resolveData,
              goFanEvent: event,
              gofanEventId: event?.id,
              partnerId: sourceEventId,
              partnerName: sourceName,
              globalEventId
            });
          }
        });

        return results;
      }, []);

      const newSchedule = {
        ...schedule,
        events: newEvents
      };

      return {
        ...item,
        schedule: newSchedule
      };
    });

    return newSchedules;
  };

  buildRawEventByPartner = ({
    venue,
    genders,
    account,
    financialAccount,
    taggedAccounts = [],
    activity,
    levelsByGender,
    opponentSchools,
    partnerEvents,
    archived,
    alert,
    startTimes,
    startTimeType
  }: {
    venue: any;
    genders: string[];
    account: AccountDTO;
    financialAccount: AccountDTO;
    taggedAccounts?: AccountDTO[];
    activity: ActivityDTO;
    opponentSchools: AccountDTO[];
    levelsByGender: LevelsByGender;
    partnerEvents: GlobalEventDTO[];
    archived?: boolean;
    alert?: string;
    startTimes?: any[];
    startTimeType?: string;
  }): RawEvent[] => {
    const rawEvents: RawEvent[] = [];

    (partnerEvents ?? [])
      .filter(event => `${event.gofanStatusMsg ?? ''}`.toLowerCase() !== IGNORED_STATUS.toLowerCase())
      .forEach((event: any) => {
        const eventTimeZone = getTimeZone(event?.goFanEvent?.timeZone ?? account?.timeZone);

        let name = '';
        let eventName = event?.eventName ?? '';
        let opponentAccountId = null;
        let withoutOpponentAccount = false;
        let awayAccountId = event?.awaySchoolId;
        let financialAccountId = financialAccount?.id;
        let taggedAccountIds = taggedAccounts?.map(account => account.id);
        let dateTimeDuration;
        let partnerId = event?.partnerId ?? event?.sourceEventId ?? '';
        let partnerName = event?.partnerName ?? event?.sourceName ?? '';
        let globalEventsId = event?.globalEventId ?? event?.id ?? '';
        const eventStartTimes = Array.isArray(startTimes)
          ? startTimes?.find(({ eventId }) => `${eventId}` === `${globalEventsId}`)
          : undefined;
        let startTime = !isEmpty(eventStartTimes?.startTime) ? eventStartTimes?.startTime : DEFAULT_START_TIME;
        let endDateTime = !isEmpty(eventStartTimes?.eventEndDateTime)
          ? eventStartTimes?.eventEndDateTime
          : event?.endEventDt;
        let startDateTime = !isEmpty(eventStartTimes?.eventStartDateTime)
          ? eventStartTimes?.eventStartDateTime
          : event?.startEventDt;
        let eventEndDateTime = '';
        let eventStartDateTime = '';
        const eventIntegrationDetails = eventStartTimes?.transformedEventIntegrationDetails;

        let eventData: any = {
          ...venue,
          created: !!event?.created,
          importGender: event?.gender ?? '',
          importLevel: event?.eventLevel ?? ''
        };

        if (!isEmpty(event?.goFanEvent)) {
          awayAccountId = event?.goFanEvent?.opponentAccountId as string;
          financialAccountId = event?.goFanEvent?.financialAccountId as string;
          taggedAccountIds = event?.goFanEvent?.taggedAccountIds as string[];

          endDateTime = event?.goFanEvent?.endDateTime;
          startDateTime = event?.goFanEvent?.startDateTime;

          const eventStartTimeType = event?.goFanEvent?.startTimeType ?? EVENT_START_TIME_TYPE.ALL_TEAMS;
          const eventStartTimeTeams: StartTimeTeams = SeasonCreationUtil.convertStartTimeOptionsToTeams({
            sportId: activity?.id,
            genders,
            levelsByGender
          });

          const eventProducts = (event?.goFanEvent?.products ?? []).map((product: any) =>
            new RawProductModel({
              ...omit(product, ['_embedded', '_links']),
              created: true
            }).toJSON()
          );

          eventData = {
            ...eventData,
            ...omit(event?.goFanEvent ?? {}, ['_embedded', '_links', 'products']),
            account: omit(account, ['_embedded', '_links']),
            activity: omit(activity, ['_embedded', '_links']),
            products: eventProducts,
            created: true,
            eventStartTimeType,
            eventStartTimeTeams
          };
        }

        if (!!event?.resolveType && !isEmpty(event?.resolveData)) {
          eventName = event?.resolveData?.name ?? '';
          endDateTime = event?.resolveData?.endDateTime;
          startDateTime = event?.resolveData?.startDateTime;
          awayAccountId = event?.resolveData?.opponentAccountId;
          financialAccountId = event?.resolveData?.financialAccountId;
          taggedAccountIds = event?.resolveData?.taggedAccountIds;

          if (event?.resolveData?.isOnlyGoFanEvent) {
            partnerId = event?.partnerId ?? '';
            partnerName = event?.partnerName ?? '';
            globalEventsId = event?.globalEventId ?? '';
          }
        }

        const opponentAccount =
          (opponentSchools ?? []).find(
            (opponentSchool: AccountDTO) => `${opponentSchool?.id}` === `${awayAccountId}`
          ) ?? null;

        if (!isEmpty(opponentAccount)) {
          name = getPartnerEventName(
            eventName,
            generateDefaultEventName(account, generateOpponentName(opponentAccount, true))
          );
          opponentAccountId = opponentAccount?.id ?? null;
        } else {
          withoutOpponentAccount = true;
          name = getPartnerEventName(eventName, generateDefaultEventName(account));
        }

        if (!isEmpty(startDateTime)) {
          const formattedStartDateTime = formatDateTime({
            date: startDateTime,
            timeZone: eventTimeZone,
            parseZone: true
          });

          eventStartDateTime = switchZone(formattedStartDateTime.toDate(EVENT_DATE_FORMAT_WITH_TIMEZONE), 'localZone');

          const mStartDateTime = moment(eventStartDateTime, EVENT_DATE_FORMAT_WITH_TIMEZONE);
          startTime = {
            time: mStartDateTime.clone().format('hh:mm'),
            period: mStartDateTime.clone().format('A')
          };
        }

        if (!isEmpty(endDateTime)) {
          const formattedEndDateTime = formatDateTime({
            date: endDateTime,
            timeZone: eventTimeZone,
            parseZone: true
          });

          eventEndDateTime = switchZone(formattedEndDateTime.toDate(EVENT_DATE_FORMAT_WITH_TIMEZONE), 'localZone');

          dateTimeDuration = moment(eventEndDateTime).diff(moment(eventStartDateTime));
        }

        const startTimeOptions = {};

        if (Object.values(eventStartTimes?.transformedStartTimeOptions ?? []).length > 0) {
          Object.values(eventStartTimes.transformedStartTimeOptions).forEach((startTimeOption: string) => {
            // expected startTimeOption ex: "Boys-1: 9:00 PM"
            const genderLevel = startTimeOption.split(': ')[0];
            const startTime = startTimeOption.split(': ')[1];

            startTimeOptions[genderLevel] = startTime;
          });
        }

        eventData = {
          ...eventData,
          archived: archived ?? false,
          alert,
          timeZone: eventTimeZone,
          partnerId,
          partnerName,
          globalEventsId,
          name,
          opponentAccount,
          opponentAccountId,
          withoutOpponentAccount,
          financialAccountId,
          taggedAccountIds,
          dateTimeDuration,
          endDateTime: eventEndDateTime,
          startDateTime: eventStartDateTime,
          importStartDateTime: eventStartDateTime,
          startTimeType: startTimeType ?? 'SAME_TIME',
          startTimeOptions,
          eventIntegrationDetails
        };

        if (!isEmpty(event?.goFanEvent)) {
          rawEvents.push(new RawEventModel({ ...eventData } as RawEvent).addDefaultStartTime(startTime).toJSON());
        } else {
          rawEvents.push(
            new RawEventModel({ ...eventData } as RawEvent)
              .addDefaultStartTime(startTime)
              .setByAccount(account)
              .setByActivity(activity)
              .setVenue({
                name: venue?.venueName,
                address: venue?.venueAddress,
                city: venue?.venueCity,
                state: venue?.venueState,
                zipCode: venue?.venueZip,
                location: venue?.venueLocationName
              })
              .toJSON()
          );
        }
      });

    return rawEvents;
  };

  buildRawSeasonByPartner = ({
    partnerName,
    account,
    activity,
    rawEvents,
    creatingSeason,
    partnerSchedule,
    financialAccount,
    taggedAccounts = [],
    groupedAccountTicketTypes
  }: {
    partnerName: string;
    activity: ActivityDTO;
    account: AccountDTO;
    financialAccount: AccountDTO;
    taggedAccounts?: AccountDTO[];
    rawEvents: RawEvent[];
    partnerSchedule: EventScheduleSeasonDTO;
    creatingSeason: CreatingSeason;
    groupedAccountTicketTypes: any[];
  }): RawSeason => {
    const {
      archived,
      alert,
      name,
      venue,
      genders,
      levelsByGender,
      creationMode,
      modeImportData,
      publishDate,
      publishTime
    } = creatingSeason ?? {};
    const { id: sportId } = activity ?? {};
    const { id: accountId } = account ?? {};

    const isCreated = creatingSeason?.created;

    const seasonId = SeasonCreationUtil.getSeasonId({ sportId, creationMode });

    const seasonName = partnerSchedule?.scheduleName ?? name ?? '';

    const groupTickets = groupedAccountTicketTypes?.find(({ id }) => `${id}` === seasonId);

    let seasonStartTimeOptions = {};

    if (Array.isArray(creatingSeason?.seasonStartTimeSettings) && creatingSeason?.seasonStartTimeSettings.length > 0) {
      creatingSeason?.seasonStartTimeSettings.forEach((item: string) => {
        const key = item.split(': ')[0];
        const value = item.split(': ')[1];

        seasonStartTimeOptions[key] = value;
      });
    } else seasonStartTimeOptions = undefined;

    const rawSeasons: RawSeason = new RawSeasonModel({
      id: seasonId,
      created: isCreated,
      sportId,
      partnerName,
      archived,
      publishDateTime:
        !isNil(publishDate) && !isNil(publishTime)
          ? moment(`${publishDate} ${publishTime}`, DATE_TIME_PICKER_FORMAT_DEFAULT)
          : undefined,
      alert,
      name: seasonName,
      accountId,
      financialAccountId: financialAccount?.id,
      taggedAccountIds: taggedAccounts?.map(account => account.id),
      venue,
      entered: SeasonCreationUtil.getEnteredValue(modeImportData),
      genders,
      levelsByGender,
      startTimeTeams: {},
      startTime: DEFAULT_START_TIME,
      startTimeOptions: seasonStartTimeOptions,
      eventStartTimeType: !isEmpty(creatingSeason?.eventStartTimeType)
        ? creatingSeason?.eventStartTimeType
        : EVENT_START_TIME_TYPE.ALL_TEAMS,
      rawEvents: rawEvents.map(event => {
        const eventProducts = !isEmpty(event?.products)
          ? event?.products
          : (groupTickets?.ticketTypes ?? []).map((ticket: AccountProductDTO) =>
              new RawProductModel({})
                .setByAccountTicketType(ticket)
                .setHiddenFees(AccountService.isDailyTransactionClose(financialAccount) ? false : !!ticket.accountPaid)
                .toJSON()
            );

        return {
          ...event,
          products: eventProducts,
          genders,
          levelsByGender
        };
      })
    }).toJSON();

    return rawSeasons;
  };

  getScheduleLevels = ({
    levels,
    schedules,
    goFanSeasons
  }: {
    levels: LevelDTO[];
    goFanSeasons: SeasonDTO[];
    schedules: EventScheduleSeasonDTO[];
  }) => {
    const scheduleLevels: ScheduleLevel[] = [];

    (schedules ?? []).forEach(schedule => {
      const accountId = schedule.homeSchoolId;
      const season = (goFanSeasons ?? []).find(season => `${season.id}` === `${schedule.gofanSeasonId}`);
      const defaultLevel = levels?.find(({ name }) => `${name}` === `${schedule.eventLevel}`);

      let seasonLevels: LevelDTO[] = (season?.levels ?? []).reduce((res, item) => {
        const found = levels?.find(({ id }) => `${id}` === `${item.levelId}`);
        if (!isEmpty(found)) res.push(found);
        return res;
      }, [] as LevelDTO[]);

      if (!isEmpty(defaultLevel)) {
        seasonLevels = isEmpty(seasonLevels) ? [defaultLevel] : seasonLevels;
      }

      scheduleLevels.push({
        levels: seasonLevels,
        accountId,
        scheduleId: schedule.id,
        goFanSeasonId: schedule.gofanSeasonId
      });
    });

    return scheduleLevels;
  };

  getScheduleVenues = ({
    accounts,
    schedules,
    goFanSeasons
  }: {
    accounts: AccountDTO[];
    goFanSeasons: SeasonDTO[];
    schedules: EventScheduleSeasonDTO[];
  }) => {
    const venues: ScheduleVenue[] = [];

    (schedules ?? []).forEach(schedule => {
      const accountId = schedule.homeSchoolId;
      const account = (accounts ?? []).find(acc => acc.id === accountId);
      const season = (goFanSeasons ?? []).find(season => `${season.id}` === `${schedule.gofanSeasonId}`);

      const accountVenue = account?.venues?.[0] ?? ({} as AccountVenue);
      const venue =
        !isEmpty(season) || !isEmpty(accountVenue)
          ? ({
              name: season?.venueName ?? accountVenue?.name ?? '',
              address: season?.venueAddress ?? accountVenue?.streetAddress ?? '',
              city: season?.venueCity ?? accountVenue?.city ?? '',
              state: season?.venueState ?? accountVenue?.state ?? '',
              zipCode: season?.venueZip ?? accountVenue?.zip ?? ''
            } as VenueInformationValue)
          : ({} as VenueInformationValue);

      venues.push({
        venue,
        accountId,
        scheduleId: schedule.id,
        goFanSeasonId: schedule.gofanSeasonId
      });
    });

    return venues;
  };

  getScheduleId = (schedule: EventScheduleSeasonDTO) =>
    [
      schedule?.homeSchoolId ?? 'homeSchoolId',
      schedule?.gofanSeasonId ?? 'gofanSeasonId',
      `${schedule?.gender ?? 'gender'}`.toLowerCase(),
      `${schedule?.eventLevel ?? 'eventLevel'}`.toLowerCase(),
      `${schedule?.sportName ?? 'sportName'}`.toLowerCase(),
      `${schedule?.sourceName ?? 'sourceName'}`.toLowerCase()
    ].join('_');

  getScheduleName = (accounts: AccountDTO[], schedule: EventScheduleSeasonDTO) => {
    const foundAccount = (accounts ?? []).find(account => `${account.id}` === `${schedule?.homeSchoolId}`);
    return `${foundAccount?.name ?? ''} ${schedule?.gender ?? ''} ${schedule?.eventLevel ?? ''} ${
      schedule?.sportName ?? ''
    }`;
  };

  getChangedDataOfGlobalEvent = ({
    globalEvent,
    goFanEvents
  }: {
    globalEvent: GlobalEventDTO;
    goFanEvents?: EventDTO[];
  }) => {
    const goFanEvent = (goFanEvents ?? []).find(item => `${item?.id}` === `${globalEvent?.gofanEventId}`);
    const changedType = this.getGlobalEventChangedType(globalEvent, goFanEvent);
    let changedData = {};

    if (
      changedType === PUBLISH_TYPE.UPDATE ||
      changedType === PUBLISH_TYPE.AFTER_END_DATE_TIME ||
      changedType === PUBLISH_TYPE.HAS_SOLD_WITH_UPDATED_END_DATE_TIME
    ) {
      changedData = new EventUpdateRequest({ globalEvent, goFanEvent }).toJSON();
    } else if (!changedType) {
      changedData = {};
    }

    return {
      goFanEvent,
      changedData,
      changedType
    };
  };

  fetchEventScheduleData = async ({
    staticData,
    searchParams
  }: {
    staticData: ScheduleData;
    searchParams: EventScheduleSearchDTO;
  }) => {
    const { partnerName } = searchParams ?? {};

    const eventScheduleResponse = await this.searchEventSchedulesByParams(searchParams).catch(
      () => [] as EventScheduleSeasonDTO[]
    );

    const awayAccounts = uniqBy([...(staticData?.accounts ?? []), ...(staticData?.awayAccounts ?? [])], 'id');

    const awaySchoolIds: any[] = [];
    const goFanEventIds: any[] = [];
    const goFanSeasonIds: any[] = [];
    const notFoundGoFanSeasonIds: string[] = [];

    (eventScheduleResponse ?? [])?.forEach(eventSchedule => {
      if (!!eventSchedule.gofanSeasonId && !goFanSeasonIds.includes(eventSchedule.gofanSeasonId)) {
        goFanSeasonIds.push(eventSchedule.gofanSeasonId);
      }

      (eventSchedule.events ?? [])?.forEach(event => {
        const eventAwaySchoolId = `${event.awaySchoolId ?? ''}`;

        if (
          !!eventAwaySchoolId &&
          !INVALID_ACCOUNT_IDS.includes(`${eventAwaySchoolId}`.toLowerCase()) &&
          !awaySchoolIds.includes(eventAwaySchoolId) &&
          !(awayAccounts ?? []).find(acc => `${acc.id}` === eventAwaySchoolId)
        ) {
          awaySchoolIds.push(eventAwaySchoolId);
        }

        if (!!event.gofanEventId && !goFanEventIds.includes(event.gofanEventId)) {
          goFanEventIds.push(event.gofanEventId);
        }

        (event.changes ?? []).forEach(change => {
          const changeValue = `${change?.value ?? ''}`;

          if (
            change?.field === 'opponentAccountId' &&
            !!changeValue &&
            !INVALID_ACCOUNT_IDS.includes(`${changeValue}`.toLowerCase()) &&
            !awaySchoolIds.includes(changeValue) &&
            !(awayAccounts ?? []).find(acc => `${acc.id}` === changeValue)
          ) {
            awaySchoolIds.push(changeValue);
          }
        });
      });
    });

    let [goFanEventResponse, goFanSeasonResponse, awayAccountResponse] = await Promise.all([
      Promise.all(
        goFanEventIds.map(goFanEventId =>
          EventService.getEventById(goFanEventId, ['event-sales-info']).catch(() => ({} as EventDTO))
        )
      ),
      (async () =>
        await Promise.all(
          goFanSeasonIds.map(goFanSeasonId =>
            SeasonService.getSeasonById(goFanSeasonId, [], true).catch(error => {
              if (error?.response?.data?.statusCode === 404 && !!goFanSeasonId) {
                notFoundGoFanSeasonIds.push(`${goFanSeasonId}`);
              }
              return {} as SeasonDTO;
            })
          )
        ))(),
      Promise.all(
        awaySchoolIds.map(accountId => {
          const foundAccount = (awayAccounts ?? []).find(acc => `${acc.id}` === `${accountId}`);

          return isEmpty(foundAccount)
            ? AccountService.getAccountById(accountId, true).catch(() => ({} as AccountDTO))
            : ({ ...foundAccount } as AccountDTO);
        })
      )
    ]);

    const goFanAwaySchools = await Promise.all(
      uniqBy(goFanEventResponse ?? [], 'opponentAccountId')
        .filter(
          event =>
            !!event?.opponentAccountId &&
            !INVALID_ACCOUNT_IDS.includes(`${event?.opponentAccountId}`.toLowerCase()) &&
            isEmpty((awayAccountResponse ?? []).find(acc => `${acc.id}` === `${event?.opponentAccountId}`)) &&
            isEmpty((awayAccounts ?? []).find(acc => `${acc.id}` === `${event?.opponentAccountId}`))
        )
        .map(event =>
          AccountService.getAccountById(`${event?.opponentAccountId}` as string, true).catch(() => ({} as AccountDTO))
        )
    );

    awayAccountResponse = uniqBy([...awayAccountResponse, ...goFanAwaySchools], 'id');

    const newGoFanEvents = [
      ...(staticData?.goFanEvents ?? []).filter(event => {
        if (goFanEventIds.includes(`${event.id}`)) {
          return isEmpty(goFanEventResponse?.find(item => `${item.id}` === `${event.id}`));
        }
        return true;
      }),
      ...(goFanEventResponse ?? []).filter(event => !isEmpty(event))
    ];

    const newGoFanSeasons = [
      ...(staticData?.goFanSeasons ?? []).filter(season => {
        if (goFanSeasonIds.includes(`${season.id}`)) {
          return isEmpty(goFanSeasonResponse?.find(item => `${item.id}` === `${season.id}`));
        }
        return true;
      }),
      ...(goFanSeasonResponse ?? []).filter(season => !isEmpty(season))
    ];

    const newAwayAccounts = [
      ...(awayAccounts ?? []).filter(acc => isEmpty(awayAccountResponse?.find(item => `${item.id}` === `${acc.id}`))),
      ...(awayAccountResponse ?? []).filter(acc => !isEmpty(acc))
    ];

    const partnerSchedules: EventScheduleSeasonDTO[] = [];

    (eventScheduleResponse ?? []).forEach(eventSchedule => {
      const isUpdatedSchedule = `${eventSchedule?.status}`.toLowerCase() === UPDATED_STATUS.toLowerCase();
      const newGlobalEvents: GlobalEventDTO[] = [];

      (eventSchedule.events ?? []).forEach(globalEvent => {
        const changedEventData: any = this.getChangedDataOfGlobalEvent({
          globalEvent,
          goFanEvents: newGoFanEvents
        });

        let newChangedData = { ...(changedEventData?.changedData ?? {}) };

        if (!isEmpty(changedEventData?.changedData) && !!changedEventData?.changedData?.opponentAccountId) {
          const changedOpponentAcc = (newAwayAccounts ?? []).find(
            (item: AccountDTO) => `${item.id}` === `${changedEventData?.changedData?.opponentAccountId}`
          );

          if (isEmpty(changedOpponentAcc)) {
            newChangedData = { ...newChangedData, opponentAccountId: '' };
          }
        }

        newGlobalEvents.push({
          ...globalEvent,
          created: !isEmpty(changedEventData?.goFanEvent),
          ...changedEventData,
          changedData: newChangedData,
          partnerId: globalEvent?.sourceEventId ?? '',
          partnerName: globalEvent?.sourceName ?? '',
          globalEventId: globalEvent?.id ?? ''
        });
      });

      partnerSchedules.push({
        ...eventSchedule,
        id: this.getScheduleId(eventSchedule),
        created: isUpdatedSchedule || !!eventSchedule?.gofanSeasonId,
        events: newGlobalEvents ?? [],
        scheduleName: this.getScheduleName(staticData?.accounts ?? [], eventSchedule)
      });
    });

    return {
      partnerName,
      goFanEvents: newGoFanEvents ?? [],
      goFanSeasons: newGoFanSeasons ?? [],
      awayAccounts: newAwayAccounts ?? [],
      partnerSchedules: (partnerSchedules ?? []).filter(
        item => !notFoundGoFanSeasonIds.includes(`${item.gofanSeasonId}`)
      )
    };
  };

  isAvailablePartnerData(partnerInsightData: any) {
    if (partnerInsightData?.partnerName === INTEGRATION_PARTNERS.aia) {
      return partnerInsightData?.totalUpcomingNewEvents > 0;
    }

    return partnerInsightData?.totalUpcomingEvents > 0;
  }

  getAvailableFetchPartnerData = ({
    partnerNames,
    schoolIds,
    selectedSchoolIds,
    aiaSchoolIds,
    aiaSelectedSchoolIds,
    dragonflySchoolIds,
    dragonflySelectedSchoolIds
  }: {
    partnerNames: any[];
    schoolIds?: any[];
    selectedSchoolIds?: any[];
    aiaSchoolIds?: any[];
    aiaSelectedSchoolIds?: any[];
    dragonflySchoolIds?: any[];
    dragonflySelectedSchoolIds?: any[];
  }) => {
    const hasAIA = !!(partnerNames ?? [])?.find(name => name === INTEGRATION_PARTNERS.aia) ?? [];
    const hasDragonfly = !!(partnerNames ?? [])?.find(name => name === INTEGRATION_PARTNERS.dragonfly) ?? [];
    const newPartnerNames = (partnerNames ?? [])?.filter(name => name !== INTEGRATION_PARTNERS.aia) ?? [];

    const newSelectedSchoolIds = (schoolIds ?? [])?.filter(accId => (selectedSchoolIds ?? [])?.includes(accId)) ?? [];
    const aiaNewSelectedSchoolIds =
      (aiaSchoolIds ?? [])?.filter(accId => (aiaSelectedSchoolIds ?? [])?.includes(accId)) ?? [];
    const dragonflyNewSelectedSchoolIds =
      (dragonflySchoolIds ?? [])?.filter(accId => (dragonflySelectedSchoolIds ?? [])?.includes(accId)) ?? [];

    return {
      partnerNames: newPartnerNames,
      hasAIA,
      hasDragonfly,
      selectedSchoolIds: newSelectedSchoolIds,
      aiaSelectedSchoolIds: aiaNewSelectedSchoolIds,
      dragonflySelectedSchoolIds: dragonflyNewSelectedSchoolIds
    };
  };

  fetchPartnerInsightWithAiaAndDragonfly = async ({
    partnerNames,
    schoolIds,
    selectedSchoolIds,
    aiaSchoolIds,
    aiaSelectedSchoolIds,
    dragonflySchoolIds,
    dragonflySelectedSchoolIds,
    tempEventMonitorSettingXMeg
  }: {
    partnerNames: any[];
    schoolIds?: any[];
    selectedSchoolIds?: any[];
    aiaSchoolIds?: any[];
    aiaSelectedSchoolIds?: any[];
    dragonflySchoolIds: any[];
    dragonflySelectedSchoolIds: any[];
    tempEventMonitorSettingXMeg?: boolean;
  }) => {
    const {
      partnerNames: newPartnerNames,
      hasAIA,
      hasDragonfly,
      selectedSchoolIds: newSelectedSchoolIds,
      aiaSelectedSchoolIds: aiaNewSelectedSchoolIds,
      dragonflySelectedSchoolIds: dragonflyNewSelectedSchoolIds
    } = this.getAvailableFetchPartnerData({
      partnerNames,
      schoolIds,
      selectedSchoolIds,
      aiaSchoolIds,
      aiaSelectedSchoolIds,
      dragonflySchoolIds,
      dragonflySelectedSchoolIds
    });

    let selectedSchoolIdsHqAway = [...newSelectedSchoolIds];
    if (tempEventMonitorSettingXMeg && selectedSchoolIdsHqAway.length) {
      selectedSchoolIdsHqAway = await EventConfigService.getAccountIdsEnabledReceivedAwayGames(selectedSchoolIdsHqAway);
    }

    const [
      partnerInsightData,
      aiaPartnerInsightData,
      dragonflyPartnerInsightData,
      hqAwayInsightData
    ]: PartnerInsight[] = await Promise.all([
      isEmpty(newSelectedSchoolIds) || isEmpty(newPartnerNames)
        ? ({ insightData: [], salesInfoData: [] } as PartnerInsight)
        : this.getPartnerInsightAndSalesInfo({
            schoolIds: newSelectedSchoolIds,
            partnerNames: newPartnerNames
          }).catch(() => ({ insightData: [], salesInfoData: [] } as PartnerInsight)),
      isEmpty(aiaNewSelectedSchoolIds) || !hasAIA
        ? ({ insightData: [], salesInfoData: [] } as PartnerInsight)
        : this.getPartnerInsightAndSalesInfo({
            schoolIds: aiaNewSelectedSchoolIds,
            partnerNames: [INTEGRATION_PARTNERS.aia]
          }).catch(() => ({ insightData: [], salesInfoData: [] } as PartnerInsight)),
      isEmpty(dragonflyNewSelectedSchoolIds) || !hasDragonfly
        ? ({ insightData: [], salesInfoData: [] } as PartnerInsight)
        : this.getPartnerInsightAndSalesInfo({
            schoolIds: dragonflyNewSelectedSchoolIds,
            partnerNames: [INTEGRATION_PARTNERS.dragonfly]
          }).catch(() => ({ insightData: [], salesInfoData: [] } as PartnerInsight)),
      isEmpty(selectedSchoolIdsHqAway) || isEmpty(newPartnerNames) || !newPartnerNames.includes(INTERNAL_PARTNERS.hq)
        ? ({ insightData: [], salesInfoData: [] } as PartnerInsight)
        : this.getAwayEventInsight({
            schoolIds: selectedSchoolIdsHqAway
          })
            .then(hqAwayInsightResult => ({
              insightData: [
                {
                  partnerName: INTERNAL_PARTNERS.hq,
                  totalUpcomingEvents: hqAwayInsightResult.totalUpcomingEvents,
                  totalUpcomingIgnoredEvents: hqAwayInsightResult.totalUpcomingIgnoredEvents,
                  totalUpcomingNewEvents: hqAwayInsightResult.totalUpcomingNewEvents,
                  totalUpcomingPublishedEvents: hqAwayInsightResult.totalUpcomingPublishedEvents,
                  totalUpcomingUpdatedEvents: hqAwayInsightResult.totalUpcomingUpdatedEvents,
                  totalUpcomingDeclinedEvents: hqAwayInsightResult.totalUpcomingDeclinedEvents
                }
              ] as EventSchedulePartnerInsightDTO[],
              salesInfoData: [] as EventPartnerSalesInfoDTO[]
            }))
            .catch(() => ({ insightData: [], salesInfoData: [] } as PartnerInsight))
    ]);

    const newInsightData = uniqBy(
      [
        ...(partnerInsightData?.insightData ?? []),
        ...(aiaPartnerInsightData?.insightData ?? []),
        ...(dragonflyPartnerInsightData?.insightData ?? []),
        ...(hqAwayInsightData?.insightData ?? [])
      ],
      'partnerName'
    );

    const newSalesInfoData = uniqBy(
      [
        ...(partnerInsightData?.salesInfoData ?? []),
        ...(aiaPartnerInsightData?.salesInfoData ?? []),
        ...(dragonflyPartnerInsightData?.salesInfoData ?? [])
      ],
      'partnerName'
    );

    const availableInsightData = (newInsightData ?? []).filter((partner: any) => this.isAvailablePartnerData(partner));

    const availablePartners = availableInsightData?.map((partner: any) => partner.partnerName);

    const availableSalesInfoData = (newSalesInfoData ?? []).filter((partner: any) =>
      availablePartners?.includes(partner.partnerName)
    );

    const newPartnerInsightData: PartnerInsight = {
      insightData: availableInsightData,
      salesInfoData: availableSalesInfoData
    };

    return {
      partnerNames: availablePartners,
      partnerInsightData: newPartnerInsightData,
      hasAIA,
      hasDragonfly,
      selectedSchoolIds: newSelectedSchoolIds,
      aiaSelectedSchoolIds: aiaNewSelectedSchoolIds,
      dragonflySelectedSchoolIds: dragonflyNewSelectedSchoolIds
    };
  };

  updateGoFanSeasonByEvents = async ({ season }: { season: SeasonDTO }) => {
    let updatedSeason = season;
    try {
      const goFanEvents: EventDTO[] = await EventService.searchByIds(season.eventIds);
      const earliestEvent = (goFanEvents ?? [])?.reduce((pre, cur) =>
        moment(pre?.startDateTime).isAfter(cur?.startDateTime) ? cur : pre
      );
      const latestEvent = (goFanEvents ?? [])?.reduce((pre, cur) =>
        moment(pre?.endDateTime).isBefore(cur?.endDateTime) ? cur : pre
      );

      updatedSeason = await SeasonService.partialUpdateSeason({
        id: season?.id,
        startDateTime: earliestEvent?.startDateTime,
        endDateTime: latestEvent?.endDateTime,
        eventIds: goFanEvents.map(event => event.id),
        events: goFanEvents.map(event => ({ id: event.id } as EventDTO))
      });
    } catch (error) {}
    return updatedSeason;
  };
}

export default new EventScheduleService();
