import React from 'react';
import classNames from 'classnames';

import { Button, InlineLoading } from 'carbon-components-react';
import { ButtonProps, ButtonKindProps } from 'carbon-components-react/lib/components/Button/Button';

export type ButtonTextAlign = 'left' | 'center';
export type LoadingAlign = 'left' | 'right';

export type BasicButtonProps = ButtonKindProps &
  ButtonProps & {
    text: string;
    className?: string;
    loading?: boolean;
    hasLoading?: boolean;
    loadingAlign?: LoadingAlign;
    textAlign?: ButtonTextAlign;
    children?: React.ReactNode;
    ref?: React.RefObject<any> | React.RefCallback<any>;
  };

const BasicButton = ({
  text,
  loading,
  textAlign,
  className,
  hasLoading,
  loadingAlign = 'left',
  children,
  ...other
}: BasicButtonProps) => {
  const buttonClassName = classNames(
    'basic-button',
    {
      'bx--btn__centralized': textAlign === 'center'
    },
    className
  );

  return (
    <Button {...other} className={buttonClassName}>
      {hasLoading && loading && (
        <div
          className={classNames({
            'basic-button--loading-right': loadingAlign === 'right',
            'basic-button--loading-left': loadingAlign === 'left'
          })}
        >
          <InlineLoading />
        </div>
      )}
      {text}
      {children}
    </Button>
  );
};

export default BasicButton;
