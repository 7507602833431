// @flow
import { call, put, takeLatest } from 'redux-saga/effects';

import * as types from './actionTypes.ts';
import * as actionCreator from './actions.ts';
import Account from '../../api/model/Account';
import PaginationDTO from '../../api/dto/PaginationDTO';
import PageRequest from '../../api/model/request/PageRequest';
import PaginationModel from '../../api/model/PaginationModel';
import SortableRequest from '../../api/model/request/SortableRequest';
import SearchAccountsRequest from '../../api/model/request/SearchAccountsRequest';
import { fetchAccounts } from '../../api/services/AccountService';

export function* searchAccounts(searchParams = {}) {
  let searchResult = new PaginationModel({}).toJSON();
  const searchRequest = new SearchAccountsRequest({
    keyword: searchParams.keyword,
    status: searchParams.status,
    accountTypes: searchParams.accountTypes
  });
  const pageRequest = new PageRequest({
    pageSize: searchParams.pageSize,
    pageIndex: searchParams.pageIndex
  });
  const sortableRequest = new SortableRequest({
    sortBy: searchParams.sortBy
  });
  const response = yield call(fetchAccounts, searchRequest, pageRequest, sortableRequest);

  if (response instanceof PaginationDTO) {
    const { data, pageSize, pageNumber, totalPages } = response.toJSON();
    searchResult = new PaginationModel({
      pageSize,
      pageIndex: pageNumber,
      pageCount: totalPages,
      data: data.map(account => new Account(account.toJSON()).toJSON())
    }).toJSON();
  }

  return searchResult;
}

function* handleSearchAccounts(action) {
  let searchResult = new PaginationModel({}).toJSON();
  try {
    const { searchParams } = action.payload;
    searchResult = yield call(searchAccounts, searchParams);
    yield put(actionCreator.searchAccountsSuccess(searchResult));
  } catch (error) {
    yield put(actionCreator.searchAccountsSuccess(searchResult));
  }
}

export function* watchSearchAccountsRequest() {
  yield takeLatest(types.SEARCH_ACCOUNTS, handleSearchAccounts);
}

export default [watchSearchAccountsRequest()];
