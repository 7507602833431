// @flow
import { sliceByKeys } from '../../utils/objectUtils';

class EventAccountsTicketDTO {
  constructor(props) {
    const { result, rest } = sliceByKeys({
      obj: props,
      keys: ['accountId', 'encodedString', 'ticketLimit'],
      omit: ['_links']
    });
    this.raw = { ...result, restData: rest };
  }

  get restData() {
    return this.raw.restData;
  }

  get accountId() {
    return this.raw.accountId;
  }

  get encodedString() {
    return this.raw.encodedString;
  }

  get ticketLimit() {
    return this.raw.ticketLimit;
  }

  toJSON() {
    return {
      restData: this.restData,
      accountId: this.accountId,
      encodedString: this.encodedString,
      ticketLimit: this.ticketLimit
    };
  }
}

export default EventAccountsTicketDTO;
