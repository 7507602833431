import eventIntegrations from '@app/modules/event-integrations_V2/middleware/reducer';
import type { Account } from '../../api/types';
import type { EventDetailActionTypes } from './actionTypes';
import {
  PREPARE_DATA,
  PREPARE_DATA_COMPLETED,
  ON_PUBLISH_EVENT,
  ON_PUBLISH_EVENT_COMPLETED,
  RESET_STATE,
  SEARCH_OPPONENT_ACCOUNTS,
  SEARCH_OPPONENT_ACCOUNTS_SUCCESS,
  SEARCH_ACCOUNT_PRODUCT_TYPES,
  SEARCH_ACCOUNT_PRODUCT_TYPES_COMPLETED,
  FETCH_ACCOUNT_SUCCESSFUL,
  FETCH_ACCOUNT_COMPLETED,
  FETCH_ACCOUNT,
  UPDATE_REQUIRED_FIELDS,
  ON_CREATE_EVENT_SUCCESS,
  UPDATE_OPTIONAL_FIELDS,
  RESET_PUBLISH_STATE,
  ON_SET_PERMISSION
} from './actionTypes';

import type { SearchAccountProductTypesParams, SearchAccountProductTypesResult } from './types';

export const prepareData = (
  eventId: number | string,
  mode: string,
  history: any,
  currentUser: any,
  compareEvent?: any
): EventDetailActionTypes => ({
  type: PREPARE_DATA,
  payload: {
    mode,
    eventId,
    history,
    currentUser,
    compareEvent
  }
});

export const prepareDataCompleted = ({
  event = {},
  levels = [],
  athleticActivities = [],
  nonAthleticActivities = [],
  error
}): EventDetailActionTypes => ({
  type: PREPARE_DATA_COMPLETED,
  payload: {
    event,
    levels,
    athleticActivities,
    nonAthleticActivities
  },
  error
});

export const onPublishEvent = ({
  mode = 'edit',
  event = {},
  activityType = '',
  history = {},
  currentUser = {},
  requiredData = {},
  optionalData = {},
  ticketTypeData = {},
  streamingData = {}
}): EventDetailActionTypes => ({
  type: ON_PUBLISH_EVENT,
  payload: {
    mode,
    event,
    activityType,
    history,
    currentUser,
    requiredData,
    optionalData,
    ticketTypeData,
    streamingData
  }
});

export const onPublishEventCompleted = ({ error, response, updatedFields = [] }): EventDetailActionTypes => ({
  type: ON_PUBLISH_EVENT_COMPLETED,
  payload: { updatedFields },
  error,
  response
});

export function updateRequiredFields(requiredFields: any): EventDetailActionTypes {
  return {
    type: UPDATE_REQUIRED_FIELDS,
    payload: {
      requiredFields
    }
  };
}

export function updateOptionalFields(optionalFields: any): EventDetailActionTypes {
  return {
    type: UPDATE_OPTIONAL_FIELDS,
    payload: {
      optionalFields
    }
  };
}

// TypeScript infers that this function is returning FetchLevelsSuccessfulAction
export function fetchAccountSuccessful(account: Account): EventDetailActionTypes {
  return {
    type: FETCH_ACCOUNT_SUCCESSFUL,
    payload: {
      account
    }
  };
}

export function fetchAccountCompleted(): EventDetailActionTypes {
  return {
    type: FETCH_ACCOUNT_COMPLETED
  };
}

export function fetchAccount(accountId: string): EventDetailActionTypes {
  return {
    type: FETCH_ACCOUNT,
    payload: {
      accountId
    }
  };
}

export const searchOpponentAccounts = (keyword: string): EventDetailActionTypes => ({
  type: SEARCH_OPPONENT_ACCOUNTS,
  payload: {
    keyword
  }
});

export const searchOpponentAccountsSuccess = (accounts: Array<Account>): EventDetailActionTypes => ({
  type: SEARCH_OPPONENT_ACCOUNTS_SUCCESS,
  payload: {
    accounts
  }
});

export const searchAccountProductTypes = (searchParams: SearchAccountProductTypesParams): EventDetailActionTypes => ({
  type: SEARCH_ACCOUNT_PRODUCT_TYPES,
  payload: {
    searchParams
  }
});

export const searchAccountProductTypesCompleted = (
  searchResult: SearchAccountProductTypesResult
): EventDetailActionTypes => ({
  type: SEARCH_ACCOUNT_PRODUCT_TYPES_COMPLETED,
  payload: {
    searchResult
  }
});

export function resetState(): EventDetailActionTypes {
  return {
    type: RESET_STATE
  };
}

export const resetPublishState = (): EventDetailActionTypes => ({
  type: RESET_PUBLISH_STATE
});

export const createEventSuccess = (): EventDetailActionTypes => ({
  type: ON_CREATE_EVENT_SUCCESS
});

export const onSetPermission = (hasPermission: boolean): EventDetailActionTypes => ({
  type: ON_SET_PERMISSION,
  payload: {
    hasPermission
  }
});

export default {
  prepareData,
  prepareDataCompleted,
  onPublishEvent,
  onPublishEventCompleted,
  searchOpponentAccountsSuccess,
  searchOpponentAccounts,
  fetchAccount,
  fetchAccountCompleted,
  fetchAccountSuccessful,
  resetState,
  resetPublishState,
  createEventSuccess,
  onSetPermission
};
