import * as yup from 'yup';

export const SponsorshipSetupSchema = yup.object().shape({
  sponsorshipName: yup
    .string()
    .required('Sponsorship name is required')
    .min(2, 'Sponsorship name must be at least 2 characters'),
  sponsorshipType: yup.string().min(2, 'Sponsorship type must be at least 2 characters'),
  emailAddress: yup.string().email('Invalid email address format').nullable(),
  phoneNumber: yup.string().matches(/^\+?[0-9]{8,}$/, 'Invalid phone number format'), // assuming phone number has at least 8 digits
  tagline: yup.string().nullable(),
  clickthroughLink: yup
    .string()
    .matches(/^(http|https):\/\/[^ "]+$/, 'Invalid URL format. Must start with http:// or https://'),
  duration: yup.string().min(2, 'Duration must be at least 2 characters')
});
