// @flow
import { isEmpty } from 'lodash';
import BaseModel from './BaseModel';
import ProductSalesInfor from './ProductSalesInfor';

class EventSalesInfo extends BaseModel {
  constructor({
    eventCapacity,
    eventId,
    eventSoldOut,
    eventTotalSaleQuantity,
    eventTotalRefundedQuantity,
    productSalesMap,
    schoolSalesMap
  }) {
    super({
      eventCapacity,
      eventId,
      eventSoldOut,
      eventTotalSaleQuantity,
      eventTotalRefundedQuantity,
      productSalesMap,
      schoolSalesMap
    });
  }

  get eventCapacity() {
    return this.raw.eventCapacity;
  }

  get eventId() {
    return this.raw.eventId;
  }

  get eventSoldOut() {
    return this.raw.eventSoldOut;
  }

  get eventTotalSaleQuantity() {
    return this.raw.eventTotalSaleQuantity;
  }

  get eventTotalRefundedQuantity() {
    return this.raw.eventTotalRefundedQuantity;
  }

  get eventSaleAvailable() {
    return this.eventCapacity - this.eventTotalSaleQuantity;
  }

  get productSalesMap() {
    if (isEmpty(this.raw.productSalesMap)) {
      return null;
    }
    const productSalesMap = {};
    Object.keys(this.raw.productSalesMap).forEach(productId => {
      productSalesMap[productId] = new ProductSalesInfor(this.raw.productSalesMap[productId]);
    });
    return productSalesMap;
  }

  get schoolSalesMap() {
    return this.raw.schoolSalesMap;
  }

  toJSON() {
    return {
      eventId: this.eventId,
      eventCapacity: this.eventCapacity,
      eventSoldOut: this.eventSoldOut,
      eventTotalSaleQuantity: this.eventTotalSaleQuantity,
      eventTotalRefundedQuantity: this.eventTotalRefundedQuantity,
      eventSaleAvailable: this.eventSaleAvailable,
      productSalesMap: this.productSalesMap,
      schoolSalesMap: this.schoolSalesMap
    };
  }
}

export default EventSalesInfo;
