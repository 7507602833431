import { PAGES } from '@config/routes';

export const EXPAND_SEASON_FIELDS = [
  'levels',
  'account',
  'activity',
  'season-events.levels',
  'season-events.account-opponent'
];

export const UPLOAD_SEASON_FLOW = {
  SINGLE_SEASON: null,
  MULTI_SPORTS: 'MultipleSports',
  ONE_PER_SPORT: 'OneSeasonPerSport',
  SPERATE_EACH_TEAM: 'SeparateSeasons'
};

export const CREATION_PROCESS = {
  MULTI_SPORT_SETUP: 'MULTI_SPORT_SETUP',
  GENERAL_SETUP: 'GENERAL_SETUP',
  RESOLVE_DUPLICATE: 'RESOLVE_DUPLICATE',
  EVENT_SETUP: 'EVENT_SETUP',
  EVENT_DETAILS: 'EVENT_DETAILS'
};

export const BUILD_SEASON_PROCESS = {
  START_BUILD: 'START_BUILD_RAW_SEASON',
  UPLOADING_FILE: 'UPLOADING_FILE_RAW_SEASON',
  UPLOAD_ERROR: 'UPLOAD_ERROR',
  UPLOADED_FILE: 'UPLOADED_FILE',
  BUILDING: 'BUILDING_RAW_SEASON',
  OPENING: 'OPENING_RAW_SEASON',
  BUILT: 'BUILT_RAW_SEASON',
  CANCEL_BUILD: 'CANCEL_BUILD_RAW_SEASON'
};

export const TAB_CONTENT = {
  SEASON_CREATION: {
    index: 0,
    id: 'SEASON_CREATION',
    text: 'Create seasons',
    pageConfig: PAGES.seasonManagement.create
  },
  ALL_SEASONS: {
    index: 1,
    id: 'ALL_SEASONS',
    text: 'All seasons',
    pageConfig: PAGES.seasonManagement.allSeasons
  }
};

export const CREATION_MODE = {
  ONE_PER_SPORT: 'ONE_PER_SPORT',
  SPERATE_EACH_TEAM: 'SPERATE_EACH_TEAM'
};

export const GENDER_TYPE = {
  BOYS: 'BOYS',
  GIRLS: 'GIRLS',
  COED: 'COED'
};

export const GENDER_LABEL = {
  BOYS: 'Boys',
  GIRLS: 'Girls',
  COED: 'Coed'
};

export enum VENUE_MODAL_MODE {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  VIEW = 'VIEW'
}

export enum PAYMENT_CYCLE_MODE {
  CALENDAR_BASED = 'CALENDAR_BASED',
  DAILY_SETTLEMENT = 'DAILY_SETTLEMENT'
}

export const VIEW_SEASON_MODAL_TAB_CONTENT = {
  BOYS: {
    index: 0,
    id: GENDER_TYPE.BOYS,
    text: GENDER_LABEL.BOYS
  },
  GIRLS: {
    index: 1,
    id: GENDER_TYPE.GIRLS,
    text: GENDER_LABEL.GIRLS
  },
  COED: {
    index: 2,
    hidden: true,
    id: GENDER_TYPE.COED,
    text: GENDER_LABEL.COED
  }
};

export interface ImportDataModeType {
  [type: string]: string;
}

export const IMPORT_DATA_MODE: ImportDataModeType = {
  FILE_UPLOAD: 'FILE_UPLOAD',
  MANUAL: 'MANUAL'
  // FROM_ARBITER: 'FROM_ARBITER'
  // COPY_FROM_SEASON: 'COPY_FROM_SEASON'
};

export const PARTNER_IMPORT_MODE = 'PARTNER_IMPORT';

export const MULTI_SPORT_IMPORT_MODE = {
  FILE_UPLOAD: 'FILE_UPLOAD'
};

export const ENTERED_VALUE = {
  FILE_UPLOAD: 'FILE_UPLOAD',
  MANUAL_INPUT: 'MANUAL_INPUT',
  ARBITER_IMPORT: 'ARBITER_IMPORT'
};

export const ENTERED_LABEL = {
  FILE_UPLOAD: 'File upload',
  MANUAL_INPUT: 'Manual input',
  ARBITER_IMPORT: 'Import from {partnerName}'
};

export const FILE_EXTENSIONS = ['csv', 'xls', 'xlsx'];

export const EVENT_START_TIME_TYPE = {
  ALL_TEAMS: 'SAME_TIME',
  EACH_TEAM: 'DIFFERENCE_TIME'
};

export const DEFAULT_START_TIME = {
  time: '07:00',
  period: 'PM'
};

export const PUBLISH_PROCESS = {
  PUBLISHING: 'PUBLISHING',
  PUBLISHED: 'PUBLISHED',
  PUBLISH_ERROR: 'PUBLISH_ERROR'
};

export const DIRTY_SEASON_FIELDS = ['name'];

export const DIRTY_EVENT_FIELDS = [
  'id',
  'name',
  'opponentAccountId',
  'withoutOpponentAccount',
  'startTime.time',
  'startTime.period',
  'startDateTime'
];
