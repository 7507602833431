import { ProductType } from './types';

export const FETCH_PRODUCT_TYPES = 'products/FETCH_PRODUCT_TYPES';
export const FETCH_PRODUCT_TYPES_COMPLETED =
  'products/FETCH_PRODUCT_TYPES_COMPLETED';

export const CREATE_PRODUCT_TYPE = 'products/CREATE_PRODUCT_TYPE';
export const CREATE_PRODUCT_TYPE_COMPLETED =
  'products/CREATE_PRODUCT_TYPE_COMPLETED';

export const UPDATE_PRODUCT_TYPE = 'products/UPDATE_PRODUCT_TYPE';
export const UPDATE_PRODUCT_TYPE_COMPLETED =
  'products/UPDATE_PRODUCT_TYPE_COMPLETED';

export const DELETE_PRODUCT_TYPE = 'products/DELETE_PRODUCT_TYPE';
export const DELETE_PRODUCT_TYPE_COMPLETED =
  'products/DELETE_PRODUCT_TYPE_COMPLETED';

interface FetchProductTypesAction {
  type: typeof FETCH_PRODUCT_TYPES;
}

interface CreateProductTypeAction {
  type: typeof CREATE_PRODUCT_TYPE;
  payload: {
    productType: ProductType;
  };
}
interface UpdateProductTypeAction {
  type: typeof UPDATE_PRODUCT_TYPE;
  payload: {
    productType: ProductType;
  };
}

interface DeleteProductTypeAction {
  type: typeof DELETE_PRODUCT_TYPE;
  payload: {
    productType: ProductType;
  };
}

interface CreateProductTypeCompletedAction {
  type: typeof CREATE_PRODUCT_TYPE_COMPLETED;
  payload: {
    productTypes: ProductType[];
  };
}

interface UpdateProductTypeCompletedAction {
  type: typeof UPDATE_PRODUCT_TYPE_COMPLETED;
  payload: {
    productTypes: ProductType[];
  };
}

interface FetchProductTypeCompletedAction {
  type: typeof FETCH_PRODUCT_TYPES_COMPLETED;
  payload: {
    productTypes: ProductType[];
  };
}

interface DeleteProductTypeCompletedlAction {
  type: typeof DELETE_PRODUCT_TYPE_COMPLETED;
  payload: {
    productTypes: ProductType[];
  };
}

export type ProductTypeActionTypes =
  | CreateProductTypeAction
  | CreateProductTypeCompletedAction
  | FetchProductTypesAction
  | FetchProductTypeCompletedAction
  | UpdateProductTypeAction
  | UpdateProductTypeCompletedAction
  | DeleteProductTypeAction
  | DeleteProductTypeCompletedlAction;
