import { EventDTO } from '@events/models/event.model';
import { Alignments } from '@carbon/charts/interfaces';
import { formatNumber } from '@events/components/ticket-sale-chart/helper';
import useEventInsight from '@app/modules/events/components/ticket-sale-chart/useEventInsight';
import { isEmpty } from '@utils/objectUtils';

export const canViewInsight = (event: EventDTO) => {
  const { data: ticketSale, loading } = useEventInsight(event);
  const tickets = ticketSale?.totals;
  return (
    !loading &&
    (tickets?.ticket_net_count > 0 ||
      tickets?.ticket_redemption_count > 0 ||
      tickets?.event_tkt_limit > 0 ||
      (ticketSale && (!isEmpty(ticketSale.schoolTickets) || !isEmpty(ticketSale.eventTickets))))
  );
};

export const mappingTicketUsedByEvent = (tickets: any) => {
  if (isEmpty(tickets)) {
    return {};
  }
  const totalUnused = tickets.ticket_net_count - tickets.ticket_redemption_count || 0;
  const totalUsed = tickets.ticket_redemption_count || 0;
  return {
    data: [
      {
        group: 'Total unused',
        value: totalUnused
      },
      {
        group: 'Total used',
        value: totalUsed
      }
    ],
    options: {
      title: '',
      resizable: true,
      legend: {
        enabled: true,
        clickable: false,
        order: ['Total used', 'Total unused']
      },
      donut: {
        center: {
          label: 'tickets used',
          numberFormatter: () => `${formatNumber(totalUsed)} of ${formatNumber(tickets.ticket_net_count)}`
        },
        alignment: 'center'
      },
      pie: {
        sortFunction: (a: any, b: any) => a.value - b.value,
        labels: {
          enabled: true,
          formatter: () => ''
        },
        alignment: 'center'
      },
      color: {
        scale: {
          'Total unused': '#08bdba',
          'Total used': '#0f63fe'
        }
      },
      height: '320px'
    }
  };
};

export const mappingSaleAllotment = (tickets: any, totalTicketAvailableByTypes: number) => {
  if (isEmpty(tickets)) {
    return {};
  }
  const availableTicket = totalTicketAvailableByTypes;
  const totalRemaining = totalTicketAvailableByTypes - tickets.ticket_net_count;
  const totalSold = tickets.ticket_net_count;
  return {
    data: [
      {
        group: 'Total remaining',
        value: totalRemaining
      },
      {
        group: 'Total sold',
        value: totalSold
      }
    ],
    options: {
      title: '',
      resizable: true,
      legend: {
        enabled: true,
        clickable: false,
        alignment: Alignments.LEFT,
        order: ['Total sold', 'Total remaining']
      },
      donut: {
        center: {
          label: `${formatNumber(totalRemaining)} <br> tickets remaining <br> for this event`,
          numberFormatter: () => `${Math.sign(totalRemaining) === 1 ? formatNumber(totalRemaining) : 0}`
        },
        alignment: 'center'
      },

      pie: {
        labels: {
          enabled: true,
          formatter: (num: any) => `${((num.data.value * 100) / availableTicket).toFixed(0)}% <br> ${num.data.value}`
        },
        alignment: 'center'
      },
      color: {
        scale: {
          'Total remaining': '#ff832b',
          'Total sold': '#1192e8'
        }
      },
      height: '320px'
    }
  };
};

export default { mappingSaleAllotment, mappingTicketUsedByEvent, canViewInsight };
