import React from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

import styles from './styles';

const StyledFormButton = styled(Button)`
  ${styles.button};
`;

export default ({
  children,
  className,
  customWidth,
  customHeight,
  ...props
}) => (
  <StyledFormButton
    {...props}
    width={customWidth}
    height={customHeight}
    className={className}
  >
    {children}
  </StyledFormButton>
);
