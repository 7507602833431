import { isEmpty, uniqueId, capitalize } from 'lodash';
import ErrorDTO from '@api/dto/ErrorDTO';

export const generateSuccessContent = (content, icon = 'icon') => ({
  id: new Date().getTime(),
  icon,
  type: 'positive',
  header: 'Success',
  content
});

export const generateWarningContent = (content = '', icon = 'frown outline') => ({
  id: new Date().getTime(),
  content,
  header: 'Warning',
  icon,
  type: 'negative'
});

export const generateFailContent = (content = '', icon = 'frown outline') => ({
  id: new Date().getTime(),
  content,
  subtitle: content,
  header: 'Error',
  icon,
  type: 'warning'
});

export const generateSuccessMessage = (content, title) => ({
  id: new Date().getTime(),
  title: title || 'Success!',
  subtitle: content,
  isSuccess: true
});

export const generateSuccessMessageWithAction = (content, title, action, clickAction = () => {}) => ({
  id: new Date().getTime(),
  title: title || 'Success!',
  subtitle: content,
  isSuccess: true,
  hasAction: true,
  action: action || 'Action',
  onClickAction: clickAction
});

export const generateErrorMessage = (error, title) => ({
  id: new Date().getTime(),
  title: title || 'Error!',
  subtitle: `${error}`,
  isSuccess: false
});

export const generateError = (error, message = '') => {
  const dto = new ErrorDTO(error);
  return generateFailContent(dto.getErrorMessage() || message);
};

export const updatedFieldsSuccessMessage = (updatedFields = [], created, prefix = '') => {
  const title = 'Success!';
  let subtitle = '';
  if (!isEmpty(updatedFields)) {
    const unigUpdatedFields = new Set([...updatedFields]);
    const plural = unigUpdatedFields.size > 1 ? 'were' : 'was';
    const name = prefix ? `${prefix} ` : '';
    subtitle = `${name}${[...unigUpdatedFields].join(', ')} ${plural} successfully ${created ? 'created' : 'updated'}`;
    subtitle = capitalize(subtitle);
  }
  return {
    id: uniqueId('updeaded_field_'),
    title,
    subtitle,
    isSuccess: true
  };
};

export const updatedFieldsSuccessMessageByBothVenueAndAccount = (
  updatedAccountFields,
  updatedVenueFields,
  venueName
) => {
  let subtitle = `${[...new Set(updatedAccountFields)].join(', ')}, ${venueName} ${[
    ...new Set(updatedVenueFields)
  ].join(', ')} were successfully updated`;
  subtitle = capitalize(subtitle);
  return {
    id: uniqueId('updeaded_field_'),
    title: 'Success!',
    subtitle,
    isSuccess: true
  };
};

export const generateWarning = (content = '') => ({
  id: new Date().getTime(),
  content,
  subtitle: content,
  header: 'Warning',
  kind: 'warning'
});
