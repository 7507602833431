import { LevelRepository } from './level.repository';
import type { LevelDTO } from './level.model';

class LevelService {
  static getAllLevels = (): Promise<LevelDTO[]> => LevelRepository.getAllLevels();

  static filterAvailableLevels = (levels: LevelDTO[] = []) =>
    (levels ?? []).filter(level => !level.disabled).sort((a, b) => `${a.name ?? ''}`.localeCompare(`${b.name ?? ''}`));
}

export { LevelService };
