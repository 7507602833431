// endpoints
export const GET_ACCOUNTS = '/accounts/search';
export const GET_ACCOUNT_BY_ID = '/accounts/{id}';
export const GET_ACCOUNTS_BY_IDS_V2 = '/v2/accounts/searchByIds';
export const GET_ACCOUNTS_PRODUCT = '/account-product/search';
export const GET_ACCOUNTS_BY_KEYWORD = '/accounts/search?keyword={keyword}';
export const GET_ACCOUNT_HISTORY = '/accounts/{id}/history?dateRange={dateRange}';
export const UPDATE_ACCOUNT_BY_ID = '/accounts/{accountId}';
export const GET_ACCOUNT_VENUES = 'accounts/{id}/venues';
export const GET_ACCOUNT_CONTACTS = '/accounts/contact/{id}';
export const GET_ACCOUNT_SALESFORCE = '/accounts/salesforce/{sfId}';

// query keys
export const ACCOUNT_QUERY_KEY = 'account';
export const ACCOUNT_HISTORY_QUERY_KEY = 'account-history';
export const ACCOUNT_FINANCIAL_HISTORY_QUERY_KEY = 'account-financial-history';
export const ACCOUNTS_QUERY_KEY = 'accounts';
export const ACCOUNT_CONTACTS_QUERY_KEY = 'accounts-contacts';
export const ACCOUNT_SALESFORCE_QUERY_KEY = 'account-salesforce';
