import isEmpty from 'lodash/isEmpty';

export const setPendoUrl = (suffix: string[]) => {
  const { pendo }: any = window;
  if (!isEmpty(pendo?.location) && !isEmpty(suffix)) {
    const href = pendo.location.getHref();
    const suffixQuery = suffix.join('&');
    pendo.location.setUrl(`${href}&${suffixQuery}`);
    return () => {
      pendo.location.useBrowserUrl();
    };
  }
  return () => {
    // do nothing
  };
};

export const clearPendoSession = () => {
  if (typeof window !== 'undefined') {
    const { pendo }: any = window;
    if (pendo && typeof pendo?.clearSession === 'function') {
      pendo?.clearSession();
    }
  }
};

export const closePendoResourceCenter = () => {
  if (typeof window !== 'undefined') {
    const { pendo }: any = window;
    if (pendo && typeof pendo?.BuildingBlocks?.BuildingBlockResourceCenter?.dismissResourceCenter === 'function') {
      pendo?.BuildingBlocks.BuildingBlockResourceCenter.dismissResourceCenter();
    }
  }
};

export const clearPendoUrl = () => {
  const { pendo }: any = window;
  if (!isEmpty(pendo?.location)) pendo.location.useBrowserUrl();
};
