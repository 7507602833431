import { all, call, put, takeLatest, delay, debounce, select } from 'redux-saga/effects';
import { lowerCase, omit } from 'lodash';
import { RateService } from '@gofan/api/rates';
import { AccountProductService } from '@gofan/api/account-products';
import EventScheduleService from '@modules/event-integrations_V2/services/event-schedule.service';
import EventsService from '@app/modules/events/services/events.service';
import { ARBITER_PARTNER_NAME } from '@modules/event-integrations_V2/constants/constants';
import moment from 'moment';
import { EVENT_DATE_FORMAT_WITH_TIMEZONE } from '@app/utils/dateUtils';
import { config } from '@gofan/constants';
import CacheService from '@app/api/services/CacheService';
import { PREPARE_DATA, SEARCH_OPPONENT_ACCOUNTS } from './actionTypes.ts';
import {
  prepareDataCompleted,
  onPublishSeasonCompleted,
  searchOpponentAccountsSuccess,
  onUploadFileEventFail,
  onUploadFileEventSuccess,
  buildEventCreatedData,
  buildEventCreatedDataCompleted,
  searchAccountTicketsCompleted,
  checkSchedulesArbiterEventsAvailableCompleted,
  searchEventSchedulesCompleted,
  checkDuplicateEventsCompleted,
  updateIsFromArbiter
} from './actions';

import { PAGES } from '../../config/routes';
import { isEmpty } from '../../utils/objectUtils';
import { CANCELLED_REQUEST, UNEXPECTED_ERROR } from '../../api/api/constants';
import LevelService from '../../api/services/LevelService';
import { checkPermissionHandlingEvent } from '../../api/services/UserService';
import {
  fetchAccountById,
  searchAccountsInSeason,
  cancelSearchAccountsInSeason
} from '../../api/services/AccountService';
import ActivityService from '../../api/services/ActivityService';
import ErrorDTO from '../../api/dto/ErrorDTO';
import PublishSeasonRequest from '../../api/model/request/PublishSeasonRequest';
import Account from '../../api/model/Account';
import { showAccountInfo, addNotification } from '../Root/actions';
import {
  BUILD_EVENT_CREATED_DATA,
  ON_PUBLISH_SEASON,
  UPLOAD_FILE_EVENT,
  SEARCH_ACCOUNT_TICKETS,
  CHECK_SCHEDULES_ARBITER_EVENTS_AVAILABLE,
  SEARCH_EVENT_SCHEDULES,
  CHECK_DUPLICATE_EVENTS
} from './actionTypes';

import {
  buildEventCreatedSection,
  uploadSeasonTemplate,
  buildCreateSeasonRequest,
  createSeason
} from '../../api/services/SeasonService';
import UploadedEvent from '../../api/model/UploadedEvent';
import PaginationModel from '../../api/model/PaginationModel';
import PageRequest from '../../api/model/request/PageRequest';
import SortableRequest from '../../api/model/request/SortableRequest';
import SearchAccountTicketsRequest from '../../api/model/request/SearchAccountTicketsRequest';
import { fetchTickets } from '../../api/services/TicketService';
import PaginationDTO from '../../api/dto/PaginationDTO';
import AccountTicket from '../../api/model/AccountTicket';
import { generateErrorMessage } from '../../utils/alertUtils';
import { TIME_ZONE_ENUM, TIME_ZONE_MAPPING } from '../../utils/dateUtils';
import { IMPORT_EVENTS_TYPES, RESOLVE_DUPLICATED_TYPES } from './constants';
import { mappingDataDuplicateEvents, seasonPublishedReturnToPage } from './helpers';
import * as seasonManagementSelectors from '@season-management/middleware/selectors';

export const DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST = 1000;

export function* prepareData(action) {
  try {
    const { accountId, history, currentUser } = action.payload;

    const hasPermission = yield call(checkPermissionHandlingEvent, {
      mode: 'add',
      currentUser,
      accountId
    });

    if (!hasPermission) {
      history.replace(PAGES.dashboard.root.path);
      return new ErrorDTO({ code: 403 });
    }

    const [accountResponse, levelsResponse, activitiesResponse] = yield all([
      call(fetchAccountById, accountId),
      call(LevelService.fetchAllLevels),
      call(ActivityService.fetchAllActivities)
    ]);

    if (
      accountResponse instanceof ErrorDTO ||
      levelsResponse instanceof ErrorDTO ||
      activitiesResponse instanceof ErrorDTO
    ) {
      const error = [accountResponse, levelsResponse, activitiesResponse].find(item => item instanceof ErrorDTO);
      const errorMessage = !isEmpty(error.data) && !isEmpty(error.data.message) ? error.data.message : UNEXPECTED_ERROR;
      yield put(prepareDataCompleted({ error: errorMessage }));
    }

    if (!isEmpty(accountResponse)) {
      yield put(showAccountInfo(accountResponse));
    }

    const { athleticActivities, nonAthleticActivities } = yield call(
      ActivityService.groupActivityByType,
      activitiesResponse
    );
    const levels = yield call(LevelService.filterAvailableLevels, levelsResponse);
    const ratesRespone = yield all([
      call(RateService.getRateById, config.RATE_ID1),
      call(RateService.getRateById, config.RATE_ID5),
      call(RateService.getRateById, config.RATE_ID8)
    ]);

    yield put(
      prepareDataCompleted({
        levels,
        athleticActivities,
        nonAthleticActivities,
        account: new Account(accountResponse.toJSON()).toJSON(),
        rates: ratesRespone
      })
    );
    return null;
  } catch (error) {
    const errorMessage = !isEmpty(error.data) && !isEmpty(error.data.message) ? error.data.message : UNEXPECTED_ERROR;
    yield put(prepareDataCompleted({ error: errorMessage }));
    return ErrorDTO.convertToError(error);
  }
}

export function* watchPrepareData() {
  yield takeLatest(PREPARE_DATA, prepareData);
}

export function* publishSeason(action) {
  try {
    const { account } = yield select(state => state.rootV2);
    const { arbiterEvents } = yield select(state => state.seasonSetup);
    const financialAccountId = yield select(seasonManagementSelectors.financialAccountIdSelector);
    const { seasonData, history, isArbiterSeason, importType, accountProducts, deletedIds } = action.payload;
    // await for loading
    yield delay(100);
    if (isEmpty(seasonData)) {
      yield put(onPublishSeasonCompleted(UNEXPECTED_ERROR));
      return;
    }

    const seasonRequest = new PublishSeasonRequest(
      buildCreateSeasonRequest(seasonData, account, isArbiterSeason ? 'arbiter' : null, importType, financialAccountId)
    );
    const seasonResponse = yield call(createSeason, seasonRequest);
    if (seasonResponse instanceof ErrorDTO) {
      const errorMessage = !isEmpty(seasonResponse.data) ? seasonResponse.data.message : UNEXPECTED_ERROR;
      yield put(onPublishSeasonCompleted(errorMessage));
      yield put(addNotification(generateErrorMessage(errorMessage)));
      return;
    }

    if (accountProducts.length > 0) {
      yield call(AccountProductService.createAccountTickets, {
        content: accountProducts
      });
    }

    const events = yield call(EventsService.searchByIds, seasonResponse.eventIds);

    const currentDateTime = moment().format(EVENT_DATE_FORMAT_WITH_TIMEZONE);

    const publishedEvents = seasonData.events
      .filter(
        e =>
          (e.type === RESOLVE_DUPLICATED_TYPES.USED_EVENT || e.type !== RESOLVE_DUPLICATED_TYPES.KEEP_EVENT) &&
          e.globalEventId
      )
      .map(e => {
        const globalEvent = arbiterEvents.find(item => item.id === e.globalEventId);
        const event = events.find(item => item.globalEventsId === e.globalEventId);
        return {
          id: globalEvent.id,
          gofanEventId: event.id,
          gofanSeasonId: seasonResponse.id,
          gofanStatusMsg: 'published',
          gofanLastPublished: currentDateTime,
          gofanStatusUpdatedDt: currentDateTime
        };
      });

    const duplicatedEvents = seasonData.events
      .filter(
        e =>
          e.type === RESOLVE_DUPLICATED_TYPES.KEEP_EVENT &&
          e.globalImportEventId &&
          !publishedEvents.find(event => event.id === e.globalImportEventId)
      )
      .map(e => {
        const globalEvent = arbiterEvents.find(item => item.id === e.globalImportEventId);
        return {
          id: globalEvent.id,
          gofanStatusMsg: 'duplicate',
          gofanLastPublished: currentDateTime,
          gofanStatusUpdatedDt: currentDateTime,
          gofanEventId: e.id,
          gofanSeasonId: seasonResponse.id
        };
      });

    const deletedEvents = deletedIds.map(id => {
      const globalEvent = arbiterEvents.find(item => item.id === id);

      return {
        id: globalEvent.id,
        gofanStatusMsg: 'ignore',
        gofanStatusUpdatedDt: currentDateTime
      };
    });

    yield call(
      EventScheduleService.updateGlobalEvents,
      Array.of(...publishedEvents, ...duplicatedEvents, ...deletedEvents)
    );

    yield put(onPublishSeasonCompleted(undefined));
    yield delay(2000);
    // Go to dashboard page after season published.
    seasonPublishedReturnToPage({
      history,
      pagePath: PAGES.dashboard.root.path,
      data: {
        isArbiterSeason,
        seasonData,
        seasonResponse
      }
    });
  } catch (error) {
    yield put(onPublishSeasonCompleted(error.message));
    yield put(addNotification(generateErrorMessage(error.message)));
  }
}

export function* watchPublishSeason() {
  yield takeLatest(ON_PUBLISH_SEASON, publishSeason);
}

export function* searchAccounts(action) {
  const { keyword, name } = action.payload;

  try {
    const coordinate = CacheService.getCachedData(
      CacheService.USER_COORDINATE_CACHE_NAME,
      CacheService.CURRENT_COORDINATE_CACHE_NAME
    );
    const response = yield call(searchAccountsInSeason, keyword, coordinate, true, false);
    if (response instanceof Array) {
      yield put(
        searchOpponentAccountsSuccess(
          response.map(account => new Account(account).toJSON()),
          name
        )
      );
    } else {
      yield put(searchOpponentAccountsSuccess([], name));
    }
  } catch (e) {
    if (e.message !== CANCELLED_REQUEST) yield put(searchOpponentAccountsSuccess([], name));
  }
}

export function* cancelSearchAccounts() {
  cancelSearchAccountsInSeason();
}

export function* watchSearchOpponentAccounts() {
  yield takeLatest(SEARCH_OPPONENT_ACCOUNTS, cancelSearchAccounts);
  yield debounce(DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST, SEARCH_OPPONENT_ACCOUNTS, searchAccounts);
}

export function* uploadFileEvent(action) {
  try {
    const { account } = yield select(state => state.rootV2);
    const { file } = action.payload;
    const formData = new FormData();
    formData.append('file', file);
    const response = yield call(
      uploadSeasonTemplate,
      formData,
      TIME_ZONE_MAPPING[lowerCase(account.timeZone)] || TIME_ZONE_ENUM.AMERICA_NEW_YORK
    );

    if (response instanceof ErrorDTO) {
      const errorMessage = !isEmpty(response.data) ? 'Your file failed to upload' : UNEXPECTED_ERROR;
      yield put(onUploadFileEventFail(errorMessage));
      return;
    }

    if (response instanceof Array) {
      const uploadedEvents = response.map(item => new UploadedEvent(item.toJSON()).toJSON());
      const uploadedEventError = uploadedEvents.find(
        item =>
          item.errors.length > 1 ||
          (item.errors.length === 1 && !item.errors[0].description.includes('Opponent school is not found'))
      );
      if (isEmpty(uploadedEventError)) {
        yield put(onUploadFileEventSuccess(uploadedEvents));
        yield put(
          buildEventCreatedData({
            events: uploadedEvents,
            isManually: false,
            eventCreatedData: undefined
          })
        );
      } else {
        const { rowNumber, errors } = uploadedEventError;
        yield put(onUploadFileEventFail(`Your file failed to upload: Row ${rowNumber + 1} ${errors[0].description}`));
      }
      return;
    }
    yield put(onUploadFileEventSuccess([]));
  } catch (error) {
    yield put(onUploadFileEventFail(UNEXPECTED_ERROR));
  }
}

export function* watchUploadFileEvent() {
  yield takeLatest(UPLOAD_FILE_EVENT, uploadFileEvent);
}

export function* handleBuildEventCreatedData(action) {
  try {
    const { account } = yield select(state => state.seasonSetup);
    const { events, isManually, existingEventCreatedData } = action.payload;
    const eventCreatedData = buildEventCreatedSection(events, account, isManually, existingEventCreatedData);
    yield put(buildEventCreatedDataCompleted(eventCreatedData));
  } catch (error) {
    yield put(buildEventCreatedDataCompleted());
  }
}

export function* searchAccountTickets({ pageSize, pageIndex, accountId, ticketName, expands, sortBy }) {
  const pageRequest = new PageRequest({
    pageSize,
    pageIndex
  });
  const sortableRequest = new SortableRequest({
    sortBy
  });
  const searchTicketsRequest = new SearchAccountTicketsRequest({
    accountId,
    ticketName
  });
  const response = yield call(fetchTickets, searchTicketsRequest, expands, pageRequest, sortableRequest);
  let searchResult = new PaginationModel({}).toJSON();

  if (response instanceof PaginationDTO) {
    const accountTickets = response.data.map(ticket => new AccountTicket(ticket.toJSON()).toJSON());

    searchResult = new PaginationModel({
      pageSize: response.pageSize,
      pageIndex: response.pageNumber,
      pageCount: response.totalPages,
      data: [...accountTickets]
    }).toJSON();
  }
  return searchResult;
}

export function* handleSearchAccountTickets(action) {
  let searchResult = new PaginationModel({}).toJSON();
  try {
    const { searchParams } = action.payload;
    searchResult = yield call(searchAccountTickets, searchParams);

    yield put(searchAccountTicketsCompleted(searchResult));
  } catch (error) {
    yield put(searchAccountTicketsCompleted(searchResult));
  }
}

export function* handleCheckSchedulesArbiterAvailable(action) {
  try {
    const { partnerName, genders, levelGenders, schoolIds, sportNames, status } = action.payload;
    const isSchedulesArbiter = yield call(EventScheduleService.getEventMonitorIndicator, {
      partnerName,
      genders,
      levelGenders,
      schoolIds,
      sportNames,
      status
    });
    yield put(checkSchedulesArbiterEventsAvailableCompleted(isSchedulesArbiter));
  } catch (error) {
    yield put(checkSchedulesArbiterEventsAvailableCompleted(false));
  }
}

export function* handleSearchEventSchedules(action) {
  try {
    const { searchParams } = action.payload;
    const searchResult = yield call(EventScheduleService.searchEventSchedulesByParams, searchParams);

    yield put(searchEventSchedulesCompleted(searchResult));
  } catch (error) {
    yield put(searchEventSchedulesCompleted([]));
  }
}

export function* checkDuplicateEvents(importEvents) {
  try {
    const newImportEvents = [];
    importEvents.forEach(importEvent => {
      newImportEvents.push(omit({ ...importEvent }, 'opponentSchool'));
    });
    const eventConflicts = isEmpty(newImportEvents)
      ? []
      : yield call(EventsService.checkDuplicateEvent, newImportEvents);
    return !isEmpty(eventConflicts) ? [...eventConflicts] : [];
  } catch (error) {
    return [];
  }
}

export function* handleCheckDuplicateEvents(action) {
  const { arbiterEvents } = yield select(state => state.seasonSetup);
  const { importEvents, importEventsType, opponentSchools, eventScheduleSeason = {} } = action.payload;
  try {
    if (isEmpty(importEvents)) {
      yield put(checkDuplicateEventsCompleted([], importEventsType));
      return;
    }
    const partnerName = importEventsType === IMPORT_EVENTS_TYPES.ARBITER ? ARBITER_PARTNER_NAME : '';
    const eventConflicts = yield call(
      importEventsType === IMPORT_EVENTS_TYPES.ARBITER ? EventsService.checkDuplicateEvent : checkDuplicateEvents,
      importEvents.map(event => ({ ...event, partnerName }))
    );
    if (eventConflicts instanceof Array) {
      if (importEventsType === IMPORT_EVENTS_TYPES.ARBITER) {
        yield put(
          checkDuplicateEventsCompleted(
            arbiterEvents,
            mappingDataDuplicateEvents(importEvents, eventConflicts, opponentSchools),
            importEventsType,
            eventScheduleSeason
          )
        );
        yield put(updateIsFromArbiter(true));
      } else {
        yield put(
          checkDuplicateEventsCompleted(
            [],
            mappingDataDuplicateEvents(importEvents, eventConflicts, opponentSchools),
            importEventsType
          )
        );
      }
    } else {
      yield put(checkDuplicateEventsCompleted([], [], importEventsType));
    }
  } catch (error) {
    yield put(checkDuplicateEventsCompleted([], [], importEventsType));
  }
}

export function* watchSearchAccountTicketsRequest() {
  yield takeLatest(SEARCH_ACCOUNT_TICKETS, handleSearchAccountTickets);
}

export function* watchBuildEventCreatedData() {
  yield takeLatest(BUILD_EVENT_CREATED_DATA, handleBuildEventCreatedData);
}

export function* watchCheckSchedulesArbiter() {
  yield takeLatest(CHECK_SCHEDULES_ARBITER_EVENTS_AVAILABLE, handleCheckSchedulesArbiterAvailable);
}

export function* watchSearchEventSchedules() {
  yield takeLatest(SEARCH_EVENT_SCHEDULES, handleSearchEventSchedules);
}

export function* watchCheckDuplicateEvents() {
  yield takeLatest(CHECK_DUPLICATE_EVENTS, handleCheckDuplicateEvents);
}

export default [
  watchPrepareData(),
  watchSearchOpponentAccounts(),
  watchPublishSeason(),
  watchUploadFileEvent(),
  watchBuildEventCreatedData(),
  watchSearchAccountTicketsRequest(),
  watchCheckSchedulesArbiter(),
  watchSearchEventSchedules(),
  watchCheckDuplicateEvents()
];
