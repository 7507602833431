import type { MouseEvent } from 'react';
import React, { useCallback } from 'react';
import { oc } from 'ts-optchain';
import classNames from 'classnames';
import { isFunction } from 'lodash';
import type {
  DataTableCustomRenderProps,
  DenormalizedRow
} from 'carbon-components-react/lib/components/DataTable/DataTable';
import {
  TableRow,
  TableBody,
  TableCell,
  OverflowMenu,
  OverflowMenuItem,
  TableSelectRow
} from 'carbon-components-react';
import { isEmpty } from '@utils/objectUtils';

export type GridRowMenuItemConfig = {
  label: string;
  hidden?: (rowId: any) => void;
  onClick?: (rowId: any) => void;
};

export type GridRowConfig = {
  id: string;
  menuItems?: GridRowMenuItemConfig[];
};

export type GridCellConfig = {
  render?: (v: any, cell: DataTableCell) => React.ReactElement;
};

export type GridDataTableHeader = {
  style?: any;
  textEllipsis?: boolean;
};

export type BasicTableBodyProps = {
  rowConfig: GridRowConfig;
  headers: GridDataTableHeader[];
  cellConfig: {
    [k: string]: GridCellConfig;
  };
  selectedRowIds: string[];
  excludedSelectRowIds: string[];
  highlightOnClick?: boolean;
  emptyDataRow?: React.FC;
  onRowClick?: (id: string) => {};
  onSelectRow?: (id: string) => void;
} & DataTableCustomRenderProps;

const BasicTableBody = ({
  rows,
  headers,
  rowConfig,
  cellConfig,
  onRowClick,
  selectedRowIds = [],
  excludedSelectRowIds,
  highlightOnClick = true,
  emptyDataRow: NoDataRow,
  getSelectionProps,
  onSelectRow
}: BasicTableBodyProps) => {
  const selectedRowIdRef = React.useRef('');
  const updateSelectedRowIdRef = (rowId: string) => {
    selectedRowIdRef.current = rowId;
  };
  const noop = useCallback(() => {}, []);
  const onTableRowClick = (id: string) => () => onRowClick && onRowClick(id);

  const isSelected = useCallback((row: DenormalizedRow) => selectedRowIds.includes(row.id), [selectedRowIds]);

  const isHighlighted = (row: DenormalizedRow) => row && row.id === selectedRowIdRef.current;

  return (
    <TableBody>
      {rows.map(row => {
        const selectionProps = getSelectionProps({ row });
        const customizedSelectionProps = {
          ...selectionProps,
          onSelect: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.stopPropagation();
            onSelectRow?.(row.id);
            selectionProps.onSelect(e);
          }
        };

        return (
          <TableRow
            key={row.id}
            isSelected={isSelected(row)}
            onClick={onTableRowClick(row.id)}
            id={row.id}
            isHighlighted={isHighlighted(row)}
            updateSelectedRowRef={updateSelectedRowIdRef}
            highlightOnClick={highlightOnClick}
            isExpanded
          >
            {onSelectRow && (
              <TableSelectRow
                {...customizedSelectionProps}
                checked={selectedRowIds.includes(row.id)}
                disabled={excludedSelectRowIds.includes(row.id)}
              />
            )}
            {row.cells.map(cell => {
              const { render } = oc(cellConfig)[cell.info.header]({});
              const { style, textEllipsis } = headers.find(header => header.key === cell.info.header) || {};

              const tableCellClass = classNames('gs--body-short-01', 'gs--text-02', 'basic-table--cell', {
                'text-ellipsis': Boolean(textEllipsis)
              });
              return (
                <TableCell key={cell.id} style={style} className={tableCellClass}>
                  {render ? render.call(null, cell.value, { ...cell, rowId: row.id }) : cell.value}
                </TableCell>
              );
            })}
            {!isEmpty(oc(rowConfig).menuItems([])) && (
              <TableCell className='basic-table--context-menu'>
                <OverflowMenu size='sm' light flipped>
                  {oc(rowConfig)
                    .menuItems([])
                    .map(menuItem => {
                      const { label, hidden, onClick } = menuItem;
                      const onClickedItem = onClick || noop;
                      return isFunction(hidden) && hidden(row.id) ? null : (
                        <OverflowMenuItem key={label} itemText={label} onClick={() => onClickedItem(row.id)} />
                      );
                    })}
                </OverflowMenu>
              </TableCell>
            )}
          </TableRow>
        );
      })}
      {NoDataRow && rows.length === 0 && <NoDataRow />}
    </TableBody>
  );
};

export default BasicTableBody;
