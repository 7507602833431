import axiosExt from 'axios';

import { huddleAxiosInstanceProxy } from '../shared/axios';
import {
  CREATE_PROMOTION,
  GET_PROMOTION,
  GET_INSIGHT_PROMOTION,
  UPDATE_PROMOTION,
  DELETE_PROMOTION,
  UPDATE_PROMOTIONS_OF_EVENT,
  CHECK_DUPLICATE_PROMOTION,
  CREATE_BULK_PROMOTION_CODES,
  GET_STATUS_PROMOTION_CODES,
  BULK_DELETE_PROMOTIONS,
  SEARCH_PROMOTION_LOCK,
  EVICT_PROMOTIONS_SEARCH_CACHE,
  GET_JOBS_PROMOTIONS,
  DELETE_PROMOTIONS_CACHE
} from '@gofan/constants/endpoints';
import { config } from '@gofan/constants/config';
import type {
  PromotionDTO,
  PromotionDuplicateRequest,
  PromotionInsightDTO,
  PromotionsUpdateDTO,
  PromotionPageResponseDTO,
  BulkPromotionRequest,
  BulkPromotionResponse,
  BulkDeletePromotions,
  PromotionLockSearch,
  PromotionLockDTO,
  JobPromotionInfoDTO
} from './promotion.model';
import type { JobDTO } from '../fan-management';

class PromotionRepository {
  static getPromotionsByProductId = (id: number, queryString?: string) =>
    huddleAxiosInstanceProxy.get<PromotionDTO>(
      `${GET_PROMOTION}?product=${id}${queryString ? `&${queryString}` : ''}`,
      config.HUDDLE_MICROSERVICE_API_MODE ? huddleAxiosInstanceProxy.msConfig : undefined
    );

  static getPromotionsByCode = (code: string, queryString?: string) =>
    huddleAxiosInstanceProxy.get<PromotionDTO>(
      `${GET_PROMOTION}?code=${code}${queryString ? `&${queryString}` : ''}`,
      config.HUDDLE_MICROSERVICE_API_MODE ? huddleAxiosInstanceProxy.msConfig : undefined
    );

  static searchPromotionCodes = (queryString?: string) =>
    huddleAxiosInstanceProxy.get<PromotionPageResponseDTO>(
      `${GET_PROMOTION}${queryString ? `${queryString}` : ''}`,
      config.HUDDLE_MICROSERVICE_API_MODE ? huddleAxiosInstanceProxy.msConfig : undefined
    );

  static getInsightOfPromotion = (promoCodeId: any): Promise<PromotionInsightDTO> =>
    axiosExt.get<PromotionInsightDTO>(GET_INSIGHT_PROMOTION.replace('{id}', promoCodeId)).then(response => ({
      promoCodeId,
      ...response.data
    }));

  static createPromotion = (promo: PromotionDTO) =>
    huddleAxiosInstanceProxy.post<PromotionDTO>(
      CREATE_PROMOTION,
      promo,
      config.HUDDLE_MICROSERVICE_API_MODE ? huddleAxiosInstanceProxy.msConfig : undefined
    );

  static updatePromotionsOfEvent = (promo: PromotionsUpdateDTO) =>
    huddleAxiosInstanceProxy.post<JobPromotionInfoDTO, PromotionsUpdateDTO>(UPDATE_PROMOTIONS_OF_EVENT, promo);

  static deletePromotion = (promoId: string | number) =>
    axiosExt.delete<PromotionDTO>(DELETE_PROMOTION.replace('{id}', `${promoId}`)).then(response => response.data);

  static deletePromotionCache = (promoId: string | number) =>
    axiosExt
      .delete<PromotionDTO>(DELETE_PROMOTIONS_CACHE.replace('{id}', `${promoId}`))
      .then(response => response.data);

  static updatePromotion = (promo: PromotionDTO) =>
    axiosExt.put<PromotionDTO>(UPDATE_PROMOTION.replace('{id}', `${promo.id}`), promo).then(response => response.data);

  static checkDuplicatePromotion = (payload: PromotionDuplicateRequest) =>
    axiosExt
      .post<PromotionDuplicateRequest, { data: string[] }>(CHECK_DUPLICATE_PROMOTION, payload)
      .then(response => response.data);

  static createBulkPromotions = (bulkPromotionRequest: BulkPromotionRequest) =>
    axiosExt
      .put<BulkPromotionRequest>(
        CREATE_BULK_PROMOTION_CODES.replace('{bucket}', config.s3.BUCKET_PROMO_CODE),
        bulkPromotionRequest
      )
      .then(response => response.data);

  static getStatusPromotion = (batchId: string) =>
    axiosExt
      .get<BulkPromotionResponse>(GET_STATUS_PROMOTION_CODES.replace('{id}', batchId))
      .then(response => response.data);

  static bulkDeletePromotions = (params: BulkDeletePromotions) =>
    huddleAxiosInstanceProxy.post<JobDTO, BulkDeletePromotions>(BULK_DELETE_PROMOTIONS, params);

  static searchPromotionLock = (payload: PromotionLockSearch): Promise<PromotionLockDTO[]> =>
    axiosExt.post<PromotionLockDTO[]>(SEARCH_PROMOTION_LOCK, payload).then(response => response.data);

  static evictPromotionsSearchCache = () =>
    axiosExt.post(EVICT_PROMOTIONS_SEARCH_CACHE).then(response => response.data);

  static getJobPromotionInfo = (jobId: number | string) =>
    huddleAxiosInstanceProxy.get<JobPromotionInfoDTO>(GET_JOBS_PROMOTIONS.replace('{id}', `${jobId}`));
}

export { PromotionRepository };
