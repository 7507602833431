// @flow
import { ActivityState } from './types';
import {
  ActivityActionTypes,
  COLLAPSE_ACTIVITY,
  FETCH_ACTIVITIES,
  FETCH_ACTIVITIES_SUCCESSFUL,
  CREATE_ACTIVITY_SUCCESSFUL,
  CREATE_ACTIVITY,
  FETCH_ACTIVITIES_COMPLETED,
  CREATE_ACTIVITY_COMPLETED,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_COMPLETED,
  UPDATE_ACTIVITY_SUCCESSFUL,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_COMPLETED,
  DELETE_ACTIVITY_SUCCESSFUL
} from './actionTypes';

export const initialState: ActivityState = {
  loading: false,
  athleticActivities: [],
  nonAthleticActivities: [],
  collapse: {
    accountId: '',
    athletic: {},
    nonAthletic: {}
  }
};

export default (state = initialState, action: ActivityActionTypes) => {
  switch (action.type) {
    case FETCH_ACTIVITIES: {
      return {
        ...state,
        loading: true
      };
    }
    case CREATE_ACTIVITY_SUCCESSFUL:
    case UPDATE_ACTIVITY_SUCCESSFUL:
    case DELETE_ACTIVITY_SUCCESSFUL:
    case FETCH_ACTIVITIES_SUCCESSFUL: {
      return {
        ...state,
        athleticActivities: action.payload.athleticActivities,
        nonAthleticActivities: action.payload.nonAthleticActivities,
        loading: false
      };
    }
    case FETCH_ACTIVITIES_COMPLETED: {
      return {
        ...state,
        loading: false
      };
    }
    case CREATE_ACTIVITY: {
      return {
        ...state,
        loading: true
      };
    }
    case CREATE_ACTIVITY_COMPLETED: {
      return {
        ...state,
        loading: false
      };
    }
    case COLLAPSE_ACTIVITY: {
      const { collapseParams } = action.payload;
      const { contactEmail, accountId, athletic, nonAthletic } = collapseParams;

      if (
        state.collapse.accountId &&
        `${accountId}` !== `${state.collapse.accountId}`
      ) {
        return { ...state, collapse: initialState.collapse };
      }

      return {
        ...state,
        collapse: {
          ...state.collapse,
          accountId,
          athletic: {
            ...state.collapse.athletic,
            [contactEmail]: athletic
          },
          nonAthletic: {
            ...state.collapse.nonAthletic,
            [contactEmail]: nonAthletic
          }
        }
      };
    }
    case DELETE_ACTIVITY:
    case UPDATE_ACTIVITY: {
      return {
        ...state,
        loading: true
      };
    }
    case DELETE_ACTIVITY_COMPLETED:
    case UPDATE_ACTIVITY_COMPLETED: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
};
