import { useQuery } from '@tanstack/react-query';
import { AccountService } from './account.service';
import { ACCOUNTS_QUERY_KEY } from './account.endpoints';

import type { AccountSearchParams, AccountKeywordSearchParams } from './account.model';
import type { QueryOptions } from '@gofan/api/shared';

export interface UseAccountsOptions {
  searchBy: 'keyword' | 'params';

  searchParams?: AccountSearchParams;
  keywordParams?: AccountKeywordSearchParams;
  queryOptions?: QueryOptions;
}

const DEFAULT_SEARCH_PARAMS: AccountSearchParams = {
  page: 0,
  pageSize: 25,
  body: {
    keyword: ''
  },
  sortBy: {
    header: 'name',
    sortDirection: 'ASC'
  }
};

export const useAccounts = ({
  searchBy,
  searchParams = DEFAULT_SEARCH_PARAMS,
  keywordParams = { keyword: '' },
  queryOptions = { enabled: true }
}: UseAccountsOptions) => {
  const pageQuery = useQuery({
    queryKey: [ACCOUNTS_QUERY_KEY, searchParams.page, { filter: searchParams.body?.keyword }],
    queryFn: () => AccountService.searchAccountByParams(searchParams),
    keepPreviousData: true,
    ...queryOptions,
    enabled: queryOptions.enabled && searchBy === 'params'
  });

  const searchQuery = useQuery({
    queryKey: [ACCOUNTS_QUERY_KEY, keywordParams.keyword],
    queryFn: () => AccountService.getAccountsByKeyword(keywordParams),
    ...queryOptions,
    enabled: queryOptions.enabled && searchBy === 'keyword'
  });

  return searchBy === 'params' ? pageQuery : searchQuery;
};
