// @flow

class EventAccountsTicket {
  constructor({ accountId, encodedString, ticketLimit }) {
    this.raw = {
      accountId,
      encodedString,
      ticketLimit
    };
  }

  get accountId() {
    return this.raw.accountId;
  }

  get encodedString() {
    return this.raw.encodedString;
  }

  get ticketLimit() {
    return this.raw.ticketLimit || undefined;
  }

  toJSON() {
    return {
      accountId: this.accountId,
      encodedString: this.encodedString,
      ticketLimit: this.ticketLimit
    };
  }
}

export default EventAccountsTicket;
