import React from 'react';
import { Checkbox, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import styles from './styles';

const ActivityWrapper = styled.div`
  ${styles.activityWrapper}
`;

const ActivityIcon = styled(Icon)`
  ${styles.activityIcon}
`;

const ActivityLabel = styled.div`
  ${styles.activityLabel}
`;

const CheckboxWrapper = styled.div`
  ${styles.checkBoxWrapper}
`;

const getIcon = (level, collapse) => {
  if (level === 0) {
    return `caret ${collapse ? 'down' : 'right'}`;
  }
  return 'circle';
};

const ActivityItem = ({
  level,
  checked,
  onCheck,
  activity,
  collapse,
  editable,
  onCollapse,
  indeterminate
}) => (
  <ActivityWrapper level={level}>
    <ActivityIcon name={getIcon(level, collapse)} />
    <CheckboxWrapper>
      <ActivityLabel level={level} onClick={onCollapse}>
        {activity.label}
      </ActivityLabel>
      {editable && (
        <Checkbox
          checked={checked}
          indeterminate={!checked && indeterminate}
          onClick={() => onCheck(activity.id, !checked)}
        />
      )}
    </CheckboxWrapper>
  </ActivityWrapper>
);

ActivityItem.defaultProps = {
  level: 0,
  activity: {},
  checked: false,
  collapse: false,
  editable: false,
  indeterminate: false,
  onCheck: () => null,
  onCollapse: () => null
};

export default ActivityItem;
