import { Event, Account } from '../../api/types';
import { SearchEventParams, RoutingEventParams } from './types';

export const RESET_STATE = '@events/RESET_STATE';
export const SEARCH_EVENTS = '@events/SEARCH_EVENTS';
export const SEARCH_EVENTS_SUCCESS = '@events/SEARCH_EVENTS_SUCCESS';
export const GET_ACCOUNT = '@events/GET_ACCOUNT';
export const GET_ACCOUNT_SUCCESS = '@events/GET_ACCOUNT_SUCCESS';
export const ROUTING_EVENT_PAGE = '@events/ROUTING_EVENT_PAGE';
export const ROUTING_EVENT_PAGE_COMPLETED = '@events/ROUTING_EVENT_PAGE_COMPLETED';

interface ResetStateAction {
  type: typeof RESET_STATE
}

interface SearchEventsAction {
  type: typeof SEARCH_EVENTS;
  payload: {
    searchParams: SearchEventParams
  }
}

interface SearchEventsSuccessAction {
  type: typeof SEARCH_EVENTS_SUCCESS;
  payload: {
    searchResult: Array<Event>
  }
}

interface RoutingEventPageAction {
  type: typeof ROUTING_EVENT_PAGE;
  payload: {
    routingParams: RoutingEventParams
  }
}

interface RoutingEventPageCompletedAction {
  type: typeof ROUTING_EVENT_PAGE_COMPLETED;
  payload: {
    account: Account
  }
}

export type EventActionTypes =
  | ResetStateAction
  | SearchEventsAction
  | SearchEventsSuccessAction
  | RoutingEventPageAction
  | RoutingEventPageCompletedAction;
