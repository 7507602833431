import { useQuery } from '@tanstack/react-query';

import type { SchoolConfig } from './school-config.model';
import { SCHOOL_CONFIG_QUERY_KEY } from '@gofan/constants';
import { SchoolConfigService } from './school-config.service';

export interface UseSearchSchoolConfigsOptions {
  schoolIds: string[];
  queryOptions?: {
    cacheTime?: number;
    staleTime?: number;
    enabled?: boolean;
    refetchOnWindowFocus?: boolean;
  };
}

export const useSearchSchoolConfig = ({ schoolIds, queryOptions }: UseSearchSchoolConfigsOptions) => {
  const query = useQuery<SchoolConfig[]>({
    queryKey: [SCHOOL_CONFIG_QUERY_KEY, schoolIds],
    queryFn: () => SchoolConfigService.searchSchoolConfigBySchoolIds(schoolIds),
    ...queryOptions
  });

  return { ...query };
};
