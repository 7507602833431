import { huddleAxiosInstanceProxy } from '../shared/axios';
import {
  SEARCH_USER,
  GET_USER,
  CREATE_USER,
  UPDATE_USER,
  GET_USER_BY_EMAIL_TOKEN,
  GET_USER_HISTORY,
  INVITE_NEW_USER
} from './user.endpoints';

import type { UserPageResponseDTO, UserDTO, UserSearchDTO, UserHistorySearchDTO, CreateUserDTO } from '../users';
import type { HistoryDTO } from '../history';

class UserRepository {
  static createUser = (createUserRequest: CreateUserDTO): Promise<UserDTO> =>
    huddleAxiosInstanceProxy.post(CREATE_USER, createUserRequest);

  static getUserByEmail = (email: string): Promise<UserDTO> =>
    huddleAxiosInstanceProxy.get(GET_USER.replace('{email}', email));

  static getUserByEmailToken = (emailToken: string): Promise<UserDTO> =>
    huddleAxiosInstanceProxy.get(GET_USER_BY_EMAIL_TOKEN.replace('{email_token}', emailToken));

  static getUserHistory = (params: UserHistorySearchDTO): Promise<HistoryDTO[]> =>
    huddleAxiosInstanceProxy.post(GET_USER_HISTORY, params);

  static searchUser = (body: UserSearchDTO, queryStr: string): Promise<UserPageResponseDTO> =>
    huddleAxiosInstanceProxy.post(`${SEARCH_USER}${queryStr}`, body);

  static updateUser = (updateUserRequest: UserDTO): Promise<UserDTO> =>
    huddleAxiosInstanceProxy.put(UPDATE_USER.replace('{email}', updateUserRequest.email), updateUserRequest);

  static partiallyUpdateUser = (updateUserRequest: Partial<UserDTO>) =>
    huddleAxiosInstanceProxy.patch(
      UPDATE_USER.replace('{email}', encodeURIComponent(updateUserRequest.email ?? '')),
      updateUserRequest
    );

  static inviteNewUser = (email: string): Promise<{ messageId: string }> =>
    huddleAxiosInstanceProxy.post(INVITE_NEW_USER.replace('{email}', email), { email });
}

export { UserRepository };
