import { isEmpty } from 'lodash';

export const secureEmail = str => {
  const arr = isEmpty(str) ? [] : str.split('@');
  const privateEmail = arr.length !== 2 ? '' : `${arr[0][0]}***@${arr[1]}`;
  return privateEmail;
};

export const randomString = ({
  length = 0,
  characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
}) => {
  let result = '';
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const truncateString = (str, length) => {
  if (str.length > length) {
    const pattern = '...';
    return str.substring(0, length).concat(pattern);
  }
  return str;
};

export const isEmptyString = str =>
  str === null || str === undefined || (typeof str !== 'string' && typeof str !== 'number') || str === '';

export const convertToFormatNumber = phoneNumberString => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}.${match[2]}.${match[3]}`;
  }
  return null;
};

export const stringToHex = input => {
  let result = '';
  for (let i = 0; i < input.length; i += 1) {
    result += input.charCodeAt(i).toString(16);
  }
  return result;
};

export const pluralizeNoun = (noun, count, suffix = 's', withCount) =>
  `${withCount ? `${count} ` : ''}${noun}${count !== 1 ? suffix : ''}`;

export const pluralizeVerb = (count, singularVerb, pluralVerb) => (count !== 1 ? pluralVerb : singularVerb);

export default {
  secureEmail,
  randomString,
  truncateString,
  isEmptyString,
  convertToFormatNumber,
  stringToHex
};
