import isNil from 'lodash/isNil';

class PageRequest {
  pageSize?: number;

  pageIndex?: number;

  constructor({ pageSize, pageIndex }) {
    this.pageSize = pageSize;
    this.pageIndex = pageIndex;
  }

  toQueryString() {
    const pageSizeQuery = isNil(this.pageSize) ? '' : `size=${this.pageSize}`;
    const pageIndexQuery = isNil(this.pageIndex) ? '' : `page=${this.pageIndex}`;
    return `${pageIndexQuery}${!isNil(this.pageSize) && !isNil(this.pageIndex) ? '&' : ''}${pageSizeQuery}`;
  }
}

export { PageRequest };
