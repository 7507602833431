// @flow

export default class BaseDTO {
  constructor(json) {
    this.raw = json;
  }

  toJSON() {
    return this.raw;
  }
}
