import { getUniqueId } from '@utils/objectUtils';

import { EVENT_START_TIME_TYPE } from '@season-management/constants/constant';
import type { RawEvent } from '@season-management/middleware/models/raw-event.model';
import type { TimeWithPeriod, LevelsByGender, StartTimeTeams } from '@season-management/middleware/models/model';
import type { VenueInformationValue } from '@season-management/pages/season-general-setup/season-venue-section/season-venue-section.component';
import type { SeasonProduct } from '@seasons/models/season.model';
import type { SeasonalPeriodType } from '@gofan/api';

export interface RawSeason {
  // creationMode === 1PerSport => id == sportId
  // creationMode === SeperateEachTeam => id == combined sportId + genderId + levelId
  id: string;
  seasonId?: string;
  name: string;
  created: boolean;
  accountId: string;
  sportId: string | number;
  gender?: string;
  levelId?: string | number;
  partnerName?: string;
  startTime: TimeWithPeriod;
  genders: string[];
  glCode: string;
  levelsByGender: LevelsByGender;
  eventStartTimeType?: string;
  startTimeTeams?: StartTimeTeams;
  startTimeOptions?: any;
  rawEvents: RawEvent[];
  venue?: VenueInformationValue;
  archived?: boolean;
  publishDateTime?: string | null;
  alert?: string;
  entered?: string;
  financialAccountId?: string;
  taggedAccountIds?: string[];
  products?: SeasonProduct[];
  ticketLimitPerOrder?: number;
  seasonalPeriod?: SeasonalPeriodType;
}

export default class RawSeasonModel {
  contents: RawSeason;

  defaultValues = {
    id: getUniqueId(),
    name: '',
    created: false,
    eventStartTimeType: EVENT_START_TIME_TYPE.ALL_TEAMS,
    startTimeTeams: {},
    genders: [],
    glCode: null,
    ticketLimitPerOrder: 30,
    rawEvents: []
  };

  constructor(contents: any) {
    this.contents = { ...contents } as RawSeason;
  }

  toJSON() {
    return { ...this.defaultValues, ...this.contents };
  }
}
