import { useEffect, useState, useCallback, useContext, useMemo, useRef } from 'react';
import { Column, Dropdown, Row, SkeletonPlaceholder, SkeletonText } from 'carbon-components-react';
import { SimpleBarChart } from '@carbon/charts-react';
import type {
  SchoolYear,
  TicketSalesDate
} from '@dashboard/components/ticket-sales-by-activity/ByWeekMonth/ticket-sales-by-activity.utils';
import {
  bubbleUpCommonSports,
  getCurrentYearRange,
  getMonths,
  getPeriodDate,
  getWeeks,
  mapToChartData,
  years
} from '@dashboard/components/ticket-sales-by-activity/ByWeekMonth/ticket-sales-by-activity.utils';
import MultiSelectWithAllOpt from '@common/carbon-ui/customized/MultiSelectWithAllOpt';
import { isEmpty } from '@utils/objectUtils';
import { uniqueId, isEqual } from 'lodash';
import { formatNumber } from '@events/components/ticket-sale-chart/helper';
import '@carbon/charts/styles.css';
import type { AccountDTO } from '@gofan/api/accounts';
import eventsRepository from '@app/modules/events/repositories/events.repository';
import { isArrayHasIdsEqual } from '@app/modules/dashboard/utils/utils';
import { checkInternalUser } from '@app/api/services/UserService';
import { RootContext } from '@app/RootContext';
import useTimeoutEffect from '@app/utils/hooks/useTimeoutEffect';
import cs from 'classnames';
import { isTestEnv } from '@app/utils/testUtils';
import moment from 'moment';
import NoData from '../../no-data/no-data';
import { LIMIT_ACCOUNTS_DISPLAY } from '@app/modules/dashboard/constants/constants';
import EventService from '@app/modules/events/services/events.service';
import { AccountService } from '@gofan/api/accounts';

interface Props {
  accounts: AccountDTO[];
}

const initTimeFrame = [
  { text: 'Month', isWeek: false },
  { text: 'Week', isWeek: true }
];

const TicketSalesByActivityComponent = ({ accounts }: Props) => {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(RootContext);
  const timerRef = useRef<any>(null);
  const timeGetActivitiesRef = useRef<any>(null);
  const accountsRef = useRef<AccountDTO[]>([]);

  // Activity
  const [activities, setActivities] = useState<any[]>([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [selectedActivitiesTemp, setSelectedActivitiesTemp] = useState([]);
  const [selectedActivitiesTempToCompare, setSelectedActivitiesTempToCompare] = useState<any[]>([]);
  const [isOpenActivitiesSelect, setIsOpenActivitiesSelect] = useState(false);

  // School
  const [selectedAccounts, setSelectedAccounts] = useState<AccountDTO[]>([]);
  const [selectedAccountsTemp, setSelectedAccountsTemp] = useState<AccountDTO[]>([]);
  const [isOpenAccountsSelect, setIsOpenAccountsSelect] = useState(false);
  const isUnmounted = useRef<boolean>(false);

  const [timeFrame, setTimeFrame] = useState(initTimeFrame[0]);
  const [chartData, setChartData] = useState(null);
  const [keyMultiselectActivity, setKeyMultiselectActivity] = useState(uniqueId());
  const [keyMultiselectSchools, setKeyMultiselectSchools] = useState('');

  const showNoData = isEmpty(selectedActivities) || isEmpty(selectedAccounts) || isEmpty(activities);
  const isHideAccountSelect = !checkInternalUser(currentUser.role) && accounts.length === 1;

  const [months, setMonths] = useState<TicketSalesDate[]>(getMonths(getCurrentYearRange()));
  const [weeks, setWeeks] = useState<TicketSalesDate[]>(getWeeks(getCurrentYearRange()));
  const [selectedYear, setSelectedYear] = useState<SchoolYear>(getCurrentYearRange());
  const weekOrMonthItems = timeFrame.isWeek ? weeks : months;
  const [selectedDate, setSelectedDate] = useState<TicketSalesDate>(months[0]);
  const optionAccounts = selectedAccountsTemp.length === LIMIT_ACCOUNTS_DISPLAY ? selectedAccountsTemp : accounts;

  const getActivities = useCallback(
    (schoolId: string[]) => {
      setLoading(true);

      clearTimeout(timeGetActivitiesRef.current);
      timeGetActivitiesRef.current = setTimeout(
        () =>
          EventService.getAccountMetrics({
            periodStart: moment(selectedYear.yearStart.id).format('YYYYMMDD'),
            periodEnd: moment(selectedYear.yearEnd.id).format('YYYYMMDD'),
            schoolId
          })
            .then(res => {
              if (isUnmounted.current) {
                return;
              }
              const activityName = Object.keys(res.data.byActivityName).map((activity, index) => ({
                id: index,
                name: activity
              }));
              const sortedActivityName = bubbleUpCommonSports(activityName);
              setActivities(sortedActivityName);
              setSelectedActivities(sortedActivityName);
              setSelectedActivitiesTemp(sortedActivityName);
              return sortedActivityName;
            })
            .catch(err => {
              console.error('Ticket sales by activity', err);
            }),
        500
      );
      setLoading(false);
    },
    [selectedYear]
  );

  const getChartData = useCallback(({ periodStart, periodEnd, schoolId, activity = [] }) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setLoading(true);
      return EventService.getAccountMetrics({
        periodStart,
        periodEnd,
        schoolId,
        activity
      })
        .then(mapToChartData(activity))
        .then(res => {
          if (isUnmounted.current) {
            return;
          }
          setChartData(res);
        })
        .catch(err => {
          console.error('Ticket sales by activity', err);
        })
        .finally(() => setLoading(false));
    }, 500);
  }, []);

  const handleChangeActivity = ({ selectedItems }: { selectedItems: any[] }) => {
    setSelectedActivitiesTemp(selectedItems);
    // For case click x icon on select to remove all
    if (!isOpenActivitiesSelect) {
      setSelectedActivities(selectedItems);
      setChartData(null);
    }
  };

  const handleOpenActivitiesSelect = (isOpen: boolean) => {
    setIsOpenActivitiesSelect(isOpen);
    if (isOpen) {
      setSelectedActivitiesTempToCompare(selectedActivitiesTemp);
    } else if (!isArrayHasIdsEqual(selectedActivitiesTempToCompare, selectedActivitiesTemp)) {
      setSelectedActivities(selectedActivitiesTemp);
      const { periodStart, periodEnd } = getPeriodDate(selectedDate);
      getChartData({
        periodStart,
        periodEnd,
        activity: selectedActivitiesTemp.map(activity => activity.name),
        schoolId: selectedAccounts.map(item => item.id)
      });
    }
  };

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );
  useEffect(() => {
    timeFrame.isWeek ? setWeeks(getWeeks(selectedYear)) : setMonths(getMonths(selectedYear));
  }, [timeFrame.isWeek]);

  useEffect(() => {
    timeFrame.isWeek ? setWeeks(getWeeks(selectedYear)) : setMonths(getMonths(selectedYear));
    // getActivities(selectedAccounts.map(item => item.id));
  }, [selectedYear]);

  useEffect(() => {
    setSelectedDate(weekOrMonthItems[0]);
  }, [weeks, months]);

  useEffect(() => {
    const [, newSelectedAccounts] = AccountService.getListAccountChanges<AccountDTO>(
      accountsRef.current,
      accounts,
      selectedAccounts
    );
    const { periodStart, periodEnd } = getPeriodDate(selectedDate);
    const schoolId = newSelectedAccounts.map(item => item.id);
    const getActivitiesAndChartData = async () => {
      const activitiesRes = await getActivities(schoolId);
      await getChartData({
        periodStart,
        periodEnd,
        schoolId,
        activity: activitiesRes?.map(activity => activity.name)
      });
    };
    getActivitiesAndChartData();
  }, [selectedDate]);

  // Init
  useEffect(() => {
    const [listChange, newSelectedAccounts] = AccountService.getListAccountChanges<AccountDTO>(
      accountsRef.current,
      accounts,
      selectedAccounts
    );
    if (listChange.length === 0) {
      return;
    }
    const schoolId = newSelectedAccounts.map(item => item.id);
    const { periodStart, periodEnd } = getPeriodDate(months[0]);
    setSelectedAccounts(newSelectedAccounts);
    setSelectedAccountsTemp(newSelectedAccounts);
    setKeyMultiselectSchools(uniqueId());
    setTimeFrame(initTimeFrame[0]);
    setSelectedDate(months[0]);
    const getActivitiesAndChartData = async () => {
      const activitiesRes = await getActivities(schoolId);
      await getChartData({
        periodStart,
        periodEnd,
        schoolId,
        activity: activitiesRes?.map(activity => activity.name)
      });
    };
    getActivitiesAndChartData();

    accountsRef.current = accounts;
  }, [accounts]);

  useTimeoutEffect(
    () => {
      setKeyMultiselectActivity(uniqueId());
    },
    [activities],
    100
  );

  return (
    <div className='ticket-sales-by-activity-by-week-month'>
      <div className='ticket-sales-by-activity-by-week-month-title'>Ticket sales by activity</div>
      <Row style={{ marginTop: isHideAccountSelect && !showNoData ? '-1.5rem' : undefined }}>
        <Column lg={6}>
          {!isHideAccountSelect && (
            <MultiSelectWithAllOpt
              id='select-school-by-week-month'
              itemToString={(item: any) => item?.name || ''}
              items={optionAccounts}
              initialSelectedItems={selectedAccountsTemp}
              label='schools selected'
              light={false}
              onChange={_onChangeAccounts}
              onMenuChange={_onMenuAccountsChange}
              open={isOpenAccountsSelect}
              key={keyMultiselectSchools}
              selectionFeedback='fixed'
              titleText='Schools'
              disabled={isEmpty(accounts) || loading}
            />
          )}
        </Column>
        {!showNoData && (
          <Column lg={6} className='ticket-sales-by-activity-by-week-month__column-contaner'>
            <div>
              <div className='ticket-sales-by-activity-by-week-month__total-tickets-sold-container ticket-sold-title'>
                <div className='ticket-sales-by-activity-by-week-month__total-tickets-sold'>
                  <div className='ticket-sold--title'>{formatNumber(chartData?.ticketSold || 0)} </div>
                  <div className='ticket-sales-by-activity-by-week-month-title'>Total tickets sold</div>
                </div>
              </div>
            </div>
            <div>
              <div className='ticket-sales-by-activity-by-week-month__total-tickets-sold-container'>
                <div className='ticket-sales-by-activity-by-week-month__total-tickets-sold'>
                  <div className='ticket-sold--title'>${formatNumber(chartData?.ticketSales || 0)}</div>
                  <div className='ticket-sales-by-activity-by-week-month-title'>Total ticket sales</div>
                </div>
              </div>
            </div>
          </Column>
        )}
      </Row>

      <Row
        className={cs('drop-down-row-container', {
          'drop-down-row-container--hide-account-select': isHideAccountSelect && !showNoData
        })}
      >
        <Column lg={isHideAccountSelect ? 8 : 9}>
          <Row>
            <Column lg={4}>
              <MultiSelectWithAllOpt
                id='activities'
                itemToString={(item: any) => item?.name || ''}
                items={activities || []}
                initialSelectedItems={selectedActivitiesTemp}
                label='activities selected'
                light={false}
                onChange={handleChangeActivity}
                onMenuChange={handleOpenActivitiesSelect}
                selectionFeedback='fixed'
                titleText='Activity'
                key={keyMultiselectActivity}
                disabled={isEmpty(activities) || loading}
                sortItems={(items: any) => [...items]}
              />
            </Column>
            <Column lg={1.5} className='column-padding'>
              <Dropdown
                disabled={isEmpty(activities) || loading}
                id='time-frame'
                itemToString={(item: any) => item.text || ''}
                items={initTimeFrame}
                label=''
                light={false}
                onChange={({ selectedItem }) => {
                  setTimeFrame(selectedItem);
                }}
                selectedItem={timeFrame}
                titleText='Time frame'
              />
            </Column>
            <Column lg={4}>
              <Dropdown
                disabled={isEmpty(activities) || loading}
                id='time-frame-week-month'
                itemToString={(item: any) => item.label || ''}
                items={weekOrMonthItems}
                label=''
                light={false}
                onChange={({ selectedItem }) => setSelectedDate(selectedItem)}
                selectedItem={weekOrMonthItems.find(weekOrMonth => isEqual(weekOrMonth, selectedDate))}
                titleText={timeFrame.isWeek ? 'For week of' : 'For month of'}
              />
            </Column>
            <Column lg={3.5}>
              <Dropdown
                disabled={loading}
                id='year'
                items={years}
                itemToString={(item: any) => `${item.yearStart.label}-${item.yearEnd.label}` || ''}
                label=''
                light={false}
                onChange={({ selectedItem }) => setSelectedYear(selectedItem)}
                selectedItem={years.find(year => isEqual(year, selectedYear))}
                titleText='Year'
              />
            </Column>
          </Row>
        </Column>
      </Row>
      {showNoData && <NoData />}
      {!showNoData && (
        <>
          {loading && (
            <>
              <SkeletonText style={{ marginTop: 20 }} />
              <SkeletonPlaceholder style={{ width: '100%', height: 400 }} />
            </>
          )}
          {!loading && (
            <Row>
              <Column>
                {!isEmpty(chartData) && !isTestEnv && (
                  <div id='hq-bar-chart' className='ticket-sales-by-activity-by-week-month'>
                    <SimpleBarChart data={chartData?.data} options={chartData?.options} />
                  </div>
                )}
              </Column>
            </Row>
          )}
        </>
      )}
    </div>
  );

  async function _onChangeAccounts({ selectedItems }: { selectedItems: any[] }) {
    const selectedItemsSliced = selectedItems.slice(0, LIMIT_ACCOUNTS_DISPLAY);
    setSelectedAccountsTemp(selectedItemsSliced);

    // Force re-render MultiSelect when selected items length > limit
    if (selectedItems.length > LIMIT_ACCOUNTS_DISPLAY) {
      setKeyMultiselectSchools(uniqueId());
    }

    // For case click x icon on select to remove all
    if (!isOpenAccountsSelect) {
      _resetAllField();
    }
  }

  async function _onMenuAccountsChange(isOpen: boolean) {
    setIsOpenAccountsSelect(isOpen);
    if (!isArrayHasIdsEqual(selectedAccounts, selectedAccountsTemp)) {
      setSelectedAccounts(selectedAccountsTemp);
      if (isEmpty(selectedAccountsTemp)) {
        _resetAllField();
        return;
      }
      const { periodStart, periodEnd } = getPeriodDate(selectedDate);

      const schoolId = selectedAccountsTemp.map(item => item.id);
      const activitiesRes = await getActivities(schoolId);
      await getChartData({
        periodStart,
        periodEnd,
        schoolId,
        activity: activitiesRes?.map(activity => activity.name)
      });
    }
  }

  function _resetAllField() {
    setActivities([]);
    setSelectedActivities([]);
    setSelectedActivitiesTemp([]);
    setSelectedAccounts([]);
    setSelectedAccountsTemp([]);
    setChartData(null);
  }
};

export default TicketSalesByActivityComponent;
