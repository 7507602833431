import { ATHLETIC_SITE } from '@accounts/constants';
import { PLAYON_SITE } from '@gofan/constants/event';
import { SEASON_RENEWAL } from '@season-renewal/constants';

export const DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST = 1000;

export const ORIGINAL_ACCOUNT = {
  accountId: 'ORIGINAL_ACCOUNT',
  value: undefined
};

export const DRAFT_ORIGINAL_ACCOUNT = {
  accountId: 'DRAFT_ORIGINAL_ACCOUNT',
  value: 'DRAFT_ORIGINAL_ACCOUNT'
};

// event type
const ATHLETIC = 'Athletic';
const PERFORMING_ART = 'Performing Arts';
const REGISTRATION = 'Registration';
const SCHOOL_DANCE = 'School Dance';
const PROM = 'Prom';
const OTHER = 'Other';
const FUNDRAISER = 'Fundraiser';
const CONCESSION = 'Concession';

export const MOBILE_PASS = 'Mobile Pass';

const DEFAULT_ROLES = [
  { id: 'financialAccountId', label: 'Financial' },
  { id: 'accountId', label: 'Home' },
  { id: 'opponentAccountId', label: 'Opponent' },
  { id: 'hostAccountId', label: 'Host' },
  { id: 'taggedAccountIds', label: 'Participant' },
  { id: 'accountAndFinancialId', label: 'Home school (Financial)' }
];

const AWAY_GAME_ROLES = [{ id: 'awayAccountId', label: 'Away' }];

const LIMITED_ROLES = [
  { id: 'financialAccountId', label: 'Financial' },
  { id: 'accountId', label: 'Home' },
  { id: 'taggedAccountIds', label: 'Participant' },
  { id: 'accountAndFinancialId', label: 'Home school (Financial)' }
];

const MOBILEPASS_ROLES = [
  { id: 'financialAccountId', label: 'Financial' },
  { id: 'accountId', label: 'Home' },
  { id: 'accountAndFinancialId', label: 'Home school (Financial)' }
];

const FUNDRAISER_ROLES = [
  { id: 'financialAccountId', label: 'Financial' },
  { id: 'accountId', label: 'Home' },
  { id: 'accountAndFinancialId', label: 'Home school (Financial)' }
];

const PERFORMING_ART_ROLES = [
  { id: 'financialAccountId', label: 'Financial' },
  { id: 'accountAndFinancialId', label: 'Home school (Financial)' }
];

const EVENT_SECTIONS = {
  SECTION_EVENT_TYPE: {
    SECTION_TITLE: 'Event type',
    LABELS: {
      DEFINE_THIS_EVENT: 'Define this event',
      SELECT_THE_TYPE_OF_EVENT: 'Type of event',
      SELECT: 'Select',
      SELECT_SPORT_LEVEL: 'Select sport level',
      GENDER: 'Gender',
      LEVEL: 'Sport level',
      BOYS: 'Boys',
      GIRLS: 'Girls',
      EITHER: 'Coed',
      SPORT: 'Sport',
      SPECIFIC: 'Specific'
    },
    GENDERS: ['boy', 'girl', 'either', 'event']
  },
  SECTION_SCHOOLS: {
    SECTION_TITLE: 'Schools',
    LABELS: {
      SELECT_THE_PARTICIPATING_SCHOOLS_AND_THEIR_ROLES:
        'Choose the schools that will participate in your event and assign specific roles for each of them',
      SEARCH_SCHOOLS_TO_ADD: 'Search for a school',
      ADD_ANOTHER_SCHOOL: 'Add another school',
      ROLE: 'Role',
      SELECT_FINANCIAL_SCHOOL: 'Select the financial school for this event'
    },
    ROLES: [...DEFAULT_ROLES, ...AWAY_GAME_ROLES]
  },
  SECTION_EVENT_DATE_AND_TIME: 'Event date and time',
  SECTION_EVENT_NAME: 'Event name',
  SECTION_GATE_OPENING_TIME: 'Gate opening time',
  SECTION_VENUE_INFORMATION: 'Venue information'
};

const PASS_MESSAGES = {
  REQUIRED_FIELDS: {
    SPORT: {
      EMPTY: 'Select the activity is required.'
    },
    GENDER: {
      EMPTY: 'Gender is required.'
    },
    LEVEL: {
      EMPTY: 'Level is required.'
    },
    ROLE: {
      EMPTY: 'Role is required.'
    },
    EVENT_DATE: {
      EMPTY: 'Pass start date is required.',
      INVALID: 'Enter a valid pass date.',
      AFTER_END_DATE: 'Pass start date must come before pass end date.'
    },
    EVENT_TIME: {
      EMPTY: 'Pass start time is required.',
      INVALID: 'Enter a valid pass start time.',
      AFTER_END_TIME: 'Pass start time must come before pass end time.'
    },
    END_DATE: {
      EMPTY: 'Pass end date is required.',
      INVALID: 'Enter a valid end date.',
      BEFORE_EVENT_DATE: 'Pass end date must come after pass start date.'
    },
    END_TIME: {
      EMPTY: 'Pass end time is required.',
      INVALID: 'Enter a valid pass end time.',
      BEFORE_EVENT_TIME: 'Pass end time must come after pass start time.'
    },
    EVENT_NAME: {
      EMPTY: 'Pass name is required.',
      SPECIAL_CHARACTERS: `This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (\`()./_-#'@&*+",:;?!) in between 3-100 characters long.`
    },
    HOURS: {
      EMPTY: 'Hours before pass starts is required.',
      INVALID: 'Enter a valid number.'
    },
    VENUE: {
      NAME: {
        EMPTY: 'Name is required.'
      },
      CITY: {
        EMPTY: 'City is required.'
      },
      ADDRESS: {
        EMPTY: 'Address is required.'
      },
      STATE: {
        EMPTY: 'State is required.'
      },
      ZIP_CODE: {
        EMPTY: 'ZipCode is required.',
        INVALID: 'Enter a valid zip code.'
      }
    },
    SCHOOLS: {
      HOME_AND_FINANCIAL_MISSING: 'Home school and financial school are required to continue.'
    }
  },
  TICKET_FIELDS: {
    MAX_CAPACITY: 'Total passes available for this event is required.',
    RANGE_MAX_CAPACITY: 'Pass capacity must be between 1 and 9999999.',
    TICKET_NAME_REQUIRED: 'Pass name is required.'
  }
};

export const EVENT_MESSAGES = {
  REQUIRED_FIELDS: {
    SPORT: {
      EMPTY: 'Select the sport is required.'
    },
    GENDER: {
      EMPTY: 'Gender is required.'
    },
    LEVEL: {
      EMPTY: 'Level is required.'
    },
    ROLE: {
      EMPTY: 'Role is required.'
    },
    EVENT_DATE: {
      EMPTY: 'Event date is required.',
      INVALID: 'Enter a valid event date.',
      AFTER_END_DATE: 'Event date must come before End date.'
    },
    EVENT_TIME: {
      EMPTY: 'Event time is required.',
      INVALID: 'Enter a valid event time.',
      AFTER_END_TIME: 'Event time must come before End time.'
    },
    END_DATE: {
      EMPTY: 'End date is required.',
      INVALID: 'Enter a valid end date.',
      BEFORE_EVENT_DATE: 'End date must come after Event date.'
    },
    END_TIME: {
      EMPTY: 'End time is required.',
      INVALID: 'Enter a valid end time.',
      BEFORE_EVENT_TIME: 'End time must come after Event time.'
    },
    EVENT_NAME: {
      EMPTY: 'Event name is required.',
      SPECIAL_CHARACTERS: `This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (\`()./_-#'@&*+",:;?!) in between 3-100 characters long.`
    },
    HOURS: {
      EMPTY: 'Hours before event starts is required.',
      INVALID: 'Enter a valid number.'
    },
    VENUE: {
      NAME: {
        EMPTY: 'Name is required.'
      },
      CITY: {
        EMPTY: 'City is required.'
      },
      ADDRESS: {
        EMPTY: 'Address is required.'
      },
      STATE: {
        EMPTY: 'State is required.'
      },
      ZIP_CODE: {
        EMPTY: 'ZipCode is required.',
        INVALID: 'Enter a valid zip code.'
      },
      REQUIRED: {
        TITLE: 'Missing Venue',
        CONTINUE_SUBTITLE: 'You must specify a venue before you continue',
        PUBLISH_SUBTITLE: 'You must specify a venue before you publish'
      }
    },
    SCHOOLS: {
      HOME_AND_FINANCIAL_MISSING: 'Home school and financial school are required to continue.',
      AWAY_AND_OPPONENT_CONFLICT: 'Away school and Opponent school cannot be selected at the same time.'
    }
  },
  ADDITIONAL_INFO_TOOLTIP: 'There is no additional information.',
  TICKET_FIELDS: {
    MAX_CAPACITY: 'Total tickets available for this event is required.',
    RANGE_MAX_CAPACITY: 'Event capacity must be between 1 and 9999999.',
    TICKET_NAME_REQUIRED: 'Ticket name is required.'
  }
};

export const PERFORMING_ARTS_MESSAGES = {
  ...EVENT_MESSAGES,
  REQUIRED_FIELDS: {
    ...EVENT_MESSAGES.REQUIRED_FIELDS,
    SCHOOLS: {
      ...EVENT_MESSAGES.REQUIRED_FIELDS.SCHOOLS,
      HOME_AND_FINANCIAL_MISSING: 'Financial school are required to continue.'
    }
  }
};

export const FUNDRAISER_MESSAGES = {
  REQUIRED_FIELDS: {
    SPORT: {
      EMPTY: 'Select the sport is required.'
    },
    GENDER: {
      EMPTY: 'Gender is required.'
    },
    LEVEL: {
      EMPTY: 'Level is required.'
    },
    ROLE: {
      EMPTY: 'Role is required.'
    },
    EVENT_DATE: {
      EMPTY: 'Event date is required.',
      INVALID: 'Enter a valid event date.',
      AFTER_END_DATE: 'Event date must come before End date.'
    },
    EVENT_TIME: {
      EMPTY: 'Event time is required.',
      INVALID: 'Enter a valid event time.',
      AFTER_END_TIME: 'Event time must come before End time.'
    },
    END_DATE: {
      EMPTY: 'End date is required.',
      INVALID: 'Enter a valid end date.',
      BEFORE_EVENT_DATE: 'End date must come after Event date.'
    },
    END_TIME: {
      EMPTY: 'End time is required.',
      INVALID: 'Enter a valid end time.',
      BEFORE_EVENT_TIME: 'End time must come after Event time.'
    },
    EVENT_NAME: {
      EMPTY: 'Event name is required.',
      SPECIAL_CHARACTERS: `This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (\`()./_-#'@&*+",:;?!) in between 3-100 characters long.`
    },
    HOURS: {
      EMPTY: 'Hours before event starts is required.',
      INVALID: 'Enter a valid number.'
    },
    VENUE: {
      NAME: {
        EMPTY: 'Name is required.'
      },
      CITY: {
        EMPTY: 'City is required.'
      },
      ADDRESS: {
        EMPTY: 'Address is required.'
      },
      STATE: {
        EMPTY: 'State is required.'
      },
      ZIP_CODE: {
        EMPTY: 'ZipCode is required.',
        INVALID: 'Enter a valid zip code.'
      },
      REQUIRED: {
        TITLE: 'Missing Venue',
        CONTINUE_SUBTITLE: 'You must specify a venue before you continue',
        PUBLISH_SUBTITLE: 'You must specify a venue before you publish'
      }
    },
    SCHOOLS: {
      HOME_AND_FINANCIAL_MISSING: 'Home school and financial school are required to continue.'
    },
    GOAL: {
      EMPTY: 'Goal is required.',
      INVALID: 'Goal must be between 1 and 1,000,000'
    },
    START_DURATION: {
      EMPTY: 'Fundraiser start date is required.',
      INVALID: 'Enter a valid Fundraiser start date.',
      AFTER_END_DURATION: 'Fundraiser start date must come before Fundraiser end date.'
    },
    END_DURATION: {
      EMPTY: 'Fundraiser end date is required.',
      INVALID: 'Enter a valid Fundraiser end date.',
      BEFORE_START_DURATION: 'Fundraiser end date must come after Fundraiser start date.'
    }
  },
  TICKET_FIELDS: {
    MAX_CAPACITY: 'Total tickets available for this event is required.',
    RANGE_MAX_CAPACITY: 'Event capacity must be between 1 and 9999999.',
    TICKET_NAME_REQUIRED: 'Donation level is required.',
    TICKET_NAME_INVALID:
      'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (`()./_-#\'@&*+",:;?!) in between 2-40 characters long.',
    TICKET_PRICE_REQUIRED: 'Price is required.',
    TICKET_PRICE_INVALID: 'Price must be between 0.00 and 50,000.00',
    TICKET_PRICE_ADMIN_INVALID: 'Price must be between 0.00 and 50,000.00',
    TICKET_LIMIT_INVALID: 'Allotment must be between 1 and 9999999.',
    UNDER_TICKET_SALES: 'The entered value should be greater or equal to the number of tickets has been sold.'
  }
};

const EVENT_ELEMENT_STATES = {
  valid: false,
  value: '',
  savedValue: '',
  error: '',
  dirty: false,
  touched: false,
  focused: false,
  editable: true,
  showError: false
};

const TIME_TYPES = {
  AM: 'AM',
  PM: 'PM'
};

const TIME_ELEMENTS = {
  value: '',
  touched: false,
  valid: false,
  error: '',
  hour: 0,
  minute: 0,
  type: 'AM',
  focused: false,
  showError: false
};

const TIME_ERRORS_STRINGS = {
  EMPTY: 'EMPTY',
  INCORRET_FORMAT: 'INCORRET_FORMAT',
  OUT_OF_RANGE: 'OUT_OF_RANGE'
};

const EVENT_ROLE_IDS = {
  FINANCIAL_ACCOUNT_ID: 'financialAccountId',
  ACCOUNT_ID: 'accountId',
  OPPONENT_ACCOUNT_ID: 'opponentAccountId',
  HOST_ACCOUNT_ID: 'hostAccountId',
  TAGGED_ACCOUNT_IDS: 'taggedAccountIds',
  ACCOUNT_AND_FINANCIAL_ID: 'accountAndFinancialId',
  AWAY_ACCOUNT_ID: 'awayAccountId'
};

const EVENT_DATE_TIME_POPUP = {
  NONE: 0,
  NO_TICKET_SOLD_AND_BEFORE_END_DATE: 1,
  HAS_TICKETS_SOLD_AND_BEFORE_END_DATE: 2,
  NO_TICKET_SOLD_AND_AFTER_END_DATE: 3,
  HAS_TICKETS_SOLD_AND_AFTER_END_DATE: 4
};

export const MIN_CHARACTERS = 3;
export const MAX_CHARACTERS = 100;

export const MIN_TICKET_NAME = 2;
export const MAX_TICKET_NAME = 40;

export const MAX_ALLOTMENT = 7;

const GOAL_VALIDATION = {
  MIN: 1,
  MAX: 1000000
};

const BUYER_PURCHASE_LIMIT_VALIDATION = {
  MIN: 1,
  MAX: 150
};

const SCHOOL_ROLES = {
  FINANCIAL_ACCOUNT: 'Financial',
  ACCOUNT: 'Home',
  OPPONENT_ACCOUNT: 'Opponent',
  HOST_ACCOUNT: 'Host',
  TAGGED_ACCOUNTS: 'Participant',
  ACCOUNT_AND_FINANCIAL: 'Home school (Financial)'
};

export const EVENT_TYPES = {
  // Event Type Configuration
  CONFIG: {
    Athletic: {
      ACTIVITY_TITLE: 'Sport',
      ALLOW_LEVEL: true,
      EVENT_PLACEHOLDER: 'Example: The Blue River Rivalry',
      ALLOW_EVENT_DESCRIPTION: true,
      EVENT_GENERATE_NAME: true,
      SCHOOL_ROLES: [...DEFAULT_ROLES, ...AWAY_GAME_ROLES],
      ALLOW_THEME: true,
      ALLOW_CUSTOM_SPORT_NAME: true,
      TICKET_DISTRIBUTION: true,
      IGNORE_FIELDS: [],
      PAGE_TITLE: 'Add Event',
      ALLOW_SEARCH_SCHOOL: true,
      DATE_TIME_TITLE: 'Event date and time',
      EVENT_DATE_LABEL: 'Event date',
      START_DATE_LABEL: 'Event start date',
      START_TIME_LABEL: 'Event start time',
      END_DATE_LABEL: 'Event end date',
      END_TIME_LABEL: 'Event end time',
      DATE_TIME_INFO: 'Fans can purchase tickets and use them until this date and time:',
      EVENT_NAME_TITLE: 'Event name',
      ALLOW_GATE_OPENING_TIME: true,
      ALLOW_REDEMTION_WINDOW: false,
      ALLOW_EVENT_PAYMENT_CYCLE: false,
      EVENT_VISIBILITY_TITLE: 'Event visibility',
      EVENT_VISIBILITY_TOGGLE_TEXT: "Hide this event on a school's GoFan page",
      EVENT_VISIBILITY_UNTOGGLE_TEXT: "Show this event on a school's GoFan page",
      EVENT_VISIBILITY_INFO_TEXT: "Automatically publish this event to the school's GoFan page",
      EVENT_ON_SALE_TITLE: 'Tickets on sale',
      EVENT_ON_SALE_TOGGLE_TEXT: 'Tickets go on sale immediately when this event is added',
      EVENT_ALERT_TITLE: 'Event alert',
      EVENT_ALERT_TEXT:
        'To ensure important event details are noticed, incorporate an alert or special information at the top of your event page',
      EVENT_ALERT_INFO_TEXT: 'Add an alert or special information to the top of your event page',
      ALLOW_DESCRIPTION: true,
      PIN_TO_TOP_LABEL: 'Pin this event to the top of your GoFan page',
      BUYER_PURCHASE_LIMIT_INFO: 'Set the maximum number of tickets a customer can purchase',
      TICKET_AVAILABILITY_LABEL: 'Total tickets available for this event',
      TICKET_AVAILABILITY_UNLIMITED_TEXT: 'Unlimited tickets',
      TICKET_TYPE_TITLE: 'Ticket Setup',
      TICKET_CREATION_HEADING: 'Event Tickets',
      ADD_TICKET_TYPE_LABEL: 'Add ticket',
      DUPLICATE_TICKET_LABEL: 'Duplicate ticket',
      TICKET_CREATION_TITLE: 'Select a ticket',
      TICKET_CREATION_FORM_TITLE: 'Create a ticket',
      TICKET_CREATION_SUB_TITLE: '* Fees are not included in ticket prices',
      TICKET_CREATION_PLACEHOLDER: 'Create a ticket type to get started',
      TICKET_TYPE_ITEM_NAME_LABEL: 'Ticket name',
      TICKET_BOX_OFFICE_LABEL: 'Box office name',
      TICKET_TYPE_ITEM_NAME_PLACEHOLDER: 'Example: General Admission',
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the ticket name?',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the ticket price?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this ticket to “Not on sale” and then create a new ticket type.',
      REVIEW_EVENT_START: 'Event start',
      REVIEW_EVENT_END: 'Event end',
      REVIEW_TIME_ZONE: 'Time zone',
      REVIEW_TICKET_TYPE: 'Ticket type',
      REVIEW_TICKET_PRICE: 'Ticket price',
      REVIEW_UNIQUE_TICKET_LINK: 'Hidden link',
      REVIEW_HIDDEN_FEES: 'Attendee pays fee',
      REVIEW_TICKET_SALES_START: 'Ticket sales begin',
      REVIEW_TICKET_SALES_END: 'Ticket sales end',
      REVIEW_TICKET_FEE: 'Ticket fee',
      REVIEW_TICKET_LIMIT_PER_ORDER: 'Ticket limit per order',
      REVIEW_TICKET_REDEMPTION_LIMIT: 'Ticket redemption limit',
      REVIEW_TICKET_PACK_COUNT: 'Family ticket',
      REVIEW_TICKET_FEE_INFOR: 'Fees are not included in the ticket prices',
      USE_MOBILE_PASS_UI: false,
      ALLOW_REVIEW_REDEMPTION_WINDOW: false,
      ALLOW_REMOVE_SCHOOL: true,
      TICKET_AVAILABILITY_TITLE: 'Ticket availability',
      ALLOW_REVIEW_GATE_OPEN: false,
      CREATE_TICKET_TYPE: 'Create Tickets',
      RETURN_EVENT_CREATION: 'Return to event creation',
      SELECT_TICKET_TYPE: 'Select the ticket type',
      NO_TICKET_TYPE: 'No ticket types yet',
      ADDITIONAL_INFORMATION: 'Additional info',
      COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
      COLLECT_ADDITIONAL_INFO_SUBTITLE:
        'Collect additional information that attendees must complete when purchasing a ticket for this event',
      COLLECT_ADDITIONAL_INFO_TYPE_TITLE: 'Information types',
      COLLECT_ADDITIONAL_INFO_SELECTED_TEXT: 'info types selected',
      COLLECT_ADDITIONAL_INFO_SELECT_TEXT: 'Select',
      CUSTOM_FORM_DESCRIPTION:
        'Collect additional information from attendees that must be completed during the ticket purchase process for this event',
      FORM_TEMPLATE: [
        {
          id: 'first-name',
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        {
          id: 'last-name',
          name: 'last-name',
          text: 'Last Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 2
        },
        {
          id: 'email',
          name: 'email',
          text: 'Email Address',
          fieldType: 'EMAIL',
          displayOnTicket: true,
          required: true,
          order: 3
        },
        {
          id: 'phone-number',
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        {
          id: 'student-id',
          name: 'student-id',
          text: 'Student Id',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 5
        }
      ],
      CUSTOM_DESCRIPTION_TITLE: 'Custom descriptions',
      CUSTOM_DESCRIPTION_SUBTITLE: 'Add a description for this event',
      ALLOW_EXAMPLE_DESCRIPTION: false,
      COLLECT_ADDITIONAL_INFO_DIRECTION: 'bottom',
      CREATE_TICKET_MODAL: {
        ADD: {
          MODAL_TITLE: 'Create ticket',
          SECONDARY_BTN: 'Cancel'
        },
        UPDATE: {
          MODAL_TITLE: 'Edit ticket',
          SECONDARY_BTN: 'Cancel'
        },
        SAVE_AND_CLOSE: 'Save and close',
        SAVE_AND_ADD_ANOTHER_TICKET: 'Save and add another ticket',
        TICKET_UPDATE_SUCCESS: 'Ticket successfully updated!!',
        TICKET_NAME: 'Ticket name',
        TICKET_NAME_PLACEHOLDER: 'Example: Student ticket',
        SETTINGS: 'Settings and price',
        ATTENDEE_PAYS_FEE: 'Attendee pays the fee',
        ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
        TOGGLE_ON: 'Yes',
        TOGGLE_OFF: 'No',
        GENERATE_LINK: 'Create unique URL',
        TICKET_PRICE: 'Ticket price',
        CURRENCY_PLACEHOLDER: '$0.00',
        ASSIGN_COLOR: 'Assign ticket color',
        ASSIGN_COLOR_DESCRIPTION: 'Ticket color displays on the attendee’s mobile device'
      },
      PREVIEW_FIELD_DESCRIPTION:
        'Preview additional information that attendees must complete when purchasing a ticket for this event.',
      CREATE_LABEL: 'Create Event',
      EDIT_LABEL: 'Edit Event',
      YOUR_SETTINGS: 'Your Event Settings',
      SCAN_ENABLED_LABEL: 'Enable Box Office',
      GATE_MANAGEMENT: 'Gate management'
    },
    'Performing Arts': {
      ADDITIONAL_INFORMATION: 'Additional info',
      ACTIVITIES: [PERFORMING_ART],
      ACTIVITY_TITLE: 'Activity',
      ALLOW_LEVEL: false,
      EVENT_PLACEHOLDER: 'Example: Beauty and the Beast - Night 1',
      ALLOW_EVENT_DESCRIPTION: true,
      EVENT_GENERATE_NAME: false,
      SCHOOL_ROLES: PERFORMING_ART_ROLES,
      ALLOW_THEME: false,
      ALLOW_CUSTOM_SPORT_NAME: false,
      TICKET_DISTRIBUTION: false,
      IGNORE_FIELDS: [
        'levels',
        'opponentAccount',
        'opponentAccountId',
        'hostAccount',
        'hostAccountId',
        'theme',
        'customSportName'
      ],
      PAGE_TITLE: 'Create Event',
      ALLOW_SEARCH_SCHOOL: true,
      DATE_TIME_TITLE: 'Event date and time',
      EVENT_DATE_LABEL: 'Event date',
      START_DATE_LABEL: 'Event start date',
      START_TIME_LABEL: 'Event start time',
      END_DATE_LABEL: 'Event end date',
      END_TIME_LABEL: 'Event end time',
      DATE_TIME_INFO: 'Fans can purchase tickets and use them until this date and time:',
      EVENT_NAME_TITLE: 'Event Name',
      ALLOW_GATE_OPENING_TIME: true,
      ALLOW_REDEMTION_WINDOW: false,
      ALLOW_EVENT_PAYMENT_CYCLE: false,
      EVENT_VISIBILITY_TITLE: 'Event visibility',
      EVENT_VISIBILITY_TOGGLE_TEXT: "Hide this event from school's GoFan page",
      EVENT_VISIBILITY_UNTOGGLE_TEXT: "Hide this event from school's GoFan page",
      EVENT_VISIBILITY_INFO_TEXT: "Automatically publish this event to the school's GoFan page on:",
      EVENT_ON_SALE_TITLE: 'Tickets on sale',
      EVENT_ON_SALE_TOGGLE_TEXT: 'Tickets go on sale immediately when this event is added',
      EVENT_ALERT_TITLE: 'Event alert',
      EVENT_ALERT_INFO_TEXT: 'Add an alert or special information to the top of your event page',
      ALLOW_DESCRIPTION: true,
      PIN_TO_TOP_LABEL: 'Pin this event to the top of your GoFan page',
      BUYER_PURCHASE_LIMIT_INFO: 'Set the maximum number of tickets a customer can purchase per order',
      TICKET_AVAILABILITY_LABEL: 'Total tickets available for this event',
      TICKET_AVAILABILITY_UNLIMITED_TEXT: 'Unlimited tickets',
      TICKET_TYPE_TITLE: 'Ticket Setup',
      TICKET_CREATION_HEADING: 'Event Tickets',
      ADD_TICKET_TYPE_LABEL: 'Add ticket',
      DUPLICATE_TICKET_LABEL: 'Duplicate ticket',
      TICKET_CREATION_TITLE: 'Select a ticket',
      TICKET_CREATION_FORM_TITLE: 'Create a ticket',
      TICKET_CREATION_SUB_TITLE: '* Fees are not included in ticket prices',
      TICKET_CREATION_PLACEHOLDER: 'Create a ticket type to get started',
      TICKET_TYPE_ITEM_NAME_LABEL: 'Ticket name',
      TICKET_TYPE_ITEM_NAME_PLACEHOLDER: 'Example: General Admission',
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the ticket name?',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the ticket price?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this ticket to “Not on sale” and then create a new ticket type.',
      REVIEW_EVENT_START: 'Event start',
      REVIEW_EVENT_END: 'Event end',
      REVIEW_TIME_ZONE: 'Time zone',
      REVIEW_TICKET_TYPE: 'Ticket type',
      REVIEW_TICKET_PRICE: 'Ticket price',
      REVIEW_UNIQUE_TICKET_LINK: 'Hidden link',
      REVIEW_HIDDEN_FEES: 'Attendee pays fee',
      REVIEW_TICKET_SALES_START: 'Ticket sales begin',
      REVIEW_TICKET_SALES_END: 'Ticket sales end',
      REVIEW_TICKET_FEE: 'Ticket fee',
      REVIEW_TICKET_LIMIT_PER_ORDER: 'Ticket limit per order',
      REVIEW_TICKET_REDEMPTION_LIMIT: 'Ticket redemption limit',
      REVIEW_TICKET_PACK_COUNT: 'Family ticket',

      REVIEW_TICKET_FEE_INFOR: 'Fees are not included in the ticket prices',
      USE_MOBILE_PASS_UI: false,
      ALLOW_REVIEW_REDEMPTION_WINDOW: false,
      ALLOW_REMOVE_SCHOOL: true,
      TICKET_AVAILABILITY_TITLE: 'Ticket availability',
      ALLOW_REVIEW_GATE_OPEN: false,
      CREATE_TICKET_TYPE: 'Create Tickets',
      RETURN_EVENT_CREATION: 'Return to event creation',
      SELECT_TICKET_TYPE: 'Select the ticket type',
      NO_TICKET_TYPE: 'No ticket types yet',
      COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
      COLLECT_ADDITIONAL_INFO_SUBTITLE:
        'Collect additional information that attendees must complete when purchasing a ticket for this event',
      COLLECT_ADDITIONAL_INFO_TYPE_TITLE: 'Information types',
      COLLECT_ADDITIONAL_INFO_SELECTED_TEXT: 'info types selected',
      COLLECT_ADDITIONAL_INFO_SELECT_TEXT: 'Select',
      CUSTOM_FORM_DESCRIPTION:
        'Collect additional information that patrons must complete when purchasing a ticket for this event',
      FORM_TEMPLATE: [
        {
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        { name: 'last-name', text: 'Last Name', fieldType: 'STRING', displayOnTicket: true, required: true, order: 2 },
        { name: 'email', text: 'Email Address', fieldType: 'EMAIL', displayOnTicket: true, required: true, order: 3 },
        {
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        { name: 'student-id', text: 'Student Id', fieldType: 'STRING', displayOnTicket: true, required: true, order: 5 }
      ],
      CUSTOM_DESCRIPTION_TITLE: 'Custom descriptions',
      CUSTOM_DESCRIPTION_SUBTITLE: 'Add a description for this performance',
      ALLOW_EXAMPLE_DESCRIPTION: false,
      COLLECT_ADDITIONAL_INFO_DIRECTION: 'bottom',
      CREATE_TICKET_MODAL: {
        ADD: {
          MODAL_TITLE: 'Create ticket',
          SECONDARY_BTN: 'Cancel'
        },
        UPDATE: {
          MODAL_TITLE: 'Edit ticket',
          SECONDARY_BTN: 'Cancel'
        },
        SAVE_AND_CLOSE: 'Save and close',
        SAVE_AND_ADD_ANOTHER_TICKET: 'Save and add another ticket',
        TICKET_UPDATE_SUCCESS: 'Ticket successfully updated!!',
        TICKET_NAME: 'Ticket name',
        TICKET_NAME_PLACEHOLDER: 'Example: Student ticket',
        SETTINGS: 'Settings and price',
        ATTENDEE_PAYS_FEE: 'Attendee pays the fee',
        ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
        TOGGLE_ON: 'Yes',
        TOGGLE_OFF: 'No',
        GENERATE_LINK: 'Create unique URL',
        TICKET_PRICE: 'Ticket price',
        CURRENCY_PLACEHOLDER: '$0.00',
        ASSIGN_COLOR: 'Assign ticket color',
        ASSIGN_COLOR_DESCRIPTION: 'Ticket color displays on the attendee’s mobile device'
      },
      PREVIEW_FIELD_DESCRIPTION:
        'Preview additional information that attendees must complete when purchasing a ticket for this event.',
      CREATE_LABEL: 'Create Event',
      EDIT_LABEL: 'Edit Event',
      YOUR_SETTINGS: 'Your Event Settings',
      SCAN_ENABLED_LABEL: 'Enable Box Office',
      GATE_MANAGEMENT: 'Door Management',
      EVENT_ALERT_TEXT: 'Communicate important details to your patrons by adding an alert to the top of your event page'
    },
    Registration: {
      ACTIVITIES: [REGISTRATION],
      ACTIVITY_TITLE: 'Activity',
      ALLOW_LEVEL: false,
      EVENT_PLACEHOLDER: 'Example: Booster Club Registration',
      ALLOW_EVENT_DESCRIPTION: false,
      EVENT_GENERATE_NAME: false,
      SCHOOL_ROLES: LIMITED_ROLES,
      ALLOW_THEME: false,
      ALLOW_CUSTOM_SPORT_NAME: false,
      ADDITIONAL_INFORMATION: 'Additional info',
      TICKET_DISTRIBUTION: false,
      IGNORE_FIELDS: [
        'levels',
        'opponentAccount',
        'opponentAccountId',
        'hostAccount',
        'hostAccountId',
        'theme',
        'customSportName'
      ],
      PAGE_TITLE: 'Add Event',
      ALLOW_SEARCH_SCHOOL: true,
      DATE_TIME_TITLE: 'Event date and time',
      EVENT_DATE_LABEL: 'Event date',
      START_DATE_LABEL: 'Event start date',
      START_TIME_LABEL: 'Event start time',
      END_DATE_LABEL: 'Event end date',
      END_TIME_LABEL: 'Event end time',
      DATE_TIME_INFO: 'Fans can purchase tickets and use them until this date and time:',
      EVENT_NAME_TITLE: 'Event name',
      ALLOW_GATE_OPENING_TIME: true,
      ALLOW_REDEMTION_WINDOW: false,
      ALLOW_EVENT_PAYMENT_CYCLE: true,
      EVENT_VISIBILITY_TITLE: 'Event visibility',
      EVENT_VISIBILITY_TOGGLE_TEXT: "Hide this event from school's GoFan page",
      EVENT_VISIBILITY_UNTOGGLE_TEXT: "Hide this event from school's GoFan page",
      EVENT_VISIBILITY_INFO_TEXT: "Automatically publish this event to the school's GoFan page on:",
      EVENT_ON_SALE_TITLE: 'Tickets on sale',
      EVENT_ON_SALE_TOGGLE_TEXT: 'Tickets go on sale immediately when this event is added',
      EVENT_ALERT_TITLE: 'Event alert',
      EVENT_ALERT_INFO_TEXT: 'Add an alert or special information to the top of your event page',
      ALLOW_DESCRIPTION: true,
      PIN_TO_TOP_LABEL: 'Pin this event to the top of your GoFan page',
      BUYER_PURCHASE_LIMIT_INFO: 'Set the maximum number of tickets a customer can purchase per order',
      TICKET_AVAILABILITY_LABEL: 'Total tickets available for this event',
      TICKET_AVAILABILITY_UNLIMITED_TEXT: 'Unlimited tickets',
      TICKET_TYPE_TITLE: 'Ticket Setup',
      TICKET_CREATION_HEADING: 'Event Tickets',
      ADD_TICKET_TYPE_LABEL: 'Add ticket',
      DUPLICATE_TICKET_LABEL: 'Duplicate ticket',
      TICKET_CREATION_TITLE: 'Select a ticket',
      TICKET_CREATION_FORM_TITLE: 'Create a ticket',
      TICKET_CREATION_SUB_TITLE: '* Fees are not included in ticket prices',
      TICKET_CREATION_PLACEHOLDER: 'Create a ticket type to get started',
      TICKET_TYPE_ITEM_NAME_LABEL: 'Ticket name',
      TICKET_TYPE_ITEM_NAME_PLACEHOLDER: 'Example: General Admission',
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the ticket name?',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the ticket price?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this ticket to “Not on sale” and then create a new ticket type.',
      REVIEW_EVENT_START: 'Event start',
      REVIEW_EVENT_END: 'Event end',
      REVIEW_TIME_ZONE: 'Time zone',
      REVIEW_TICKET_TYPE: 'Ticket type',
      REVIEW_TICKET_PRICE: 'Ticket price',
      REVIEW_UNIQUE_TICKET_LINK: 'Hidden link',
      REVIEW_HIDDEN_FEES: 'Attendee pays fee',
      REVIEW_TICKET_SALES_START: 'Ticket sales begin',
      REVIEW_TICKET_SALES_END: 'Ticket sales end',
      REVIEW_TICKET_FEE: 'Ticket fee',
      REVIEW_TICKET_LIMIT_PER_ORDER: 'Ticket limit per order',
      REVIEW_TICKET_REDEMPTION_LIMIT: 'Ticket redemption limit',
      REVIEW_TICKET_PACK_COUNT: 'Family ticket',

      REVIEW_TICKET_FEE_INFOR: 'Fees are not included in the ticket prices',
      USE_MOBILE_PASS_UI: false,
      ALLOW_REVIEW_REDEMPTION_WINDOW: false,
      ALLOW_REMOVE_SCHOOL: true,
      TICKET_AVAILABILITY_TITLE: 'Ticket availability',
      ALLOW_REVIEW_GATE_OPEN: false,
      CREATE_TICKET_TYPE: 'Create Tickets',
      RETURN_EVENT_CREATION: 'Return to event creation',
      SELECT_TICKET_TYPE: 'Select the ticket type',
      NO_TICKET_TYPE: 'No ticket types yet',
      COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
      COLLECT_ADDITIONAL_INFO_SUBTITLE:
        'Collect additional information that attendees must complete when purchasing a ticket for this event',
      COLLECT_ADDITIONAL_INFO_TYPE_TITLE: 'Information types',
      COLLECT_ADDITIONAL_INFO_SELECTED_TEXT: 'info types selected',
      COLLECT_ADDITIONAL_INFO_SELECT_TEXT: 'Select',
      ADDITIONAL_INFORMATION: 'Additional info',
      CUSTOM_FORM_DESCRIPTION:
        'Collect additional information that attendees must complete when purchasing a ticket for this event',
      FORM_TEMPLATE: [
        {
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        { name: 'last-name', text: 'Last Name', fieldType: 'STRING', displayOnTicket: true, required: true, order: 2 },
        { name: 'email', text: 'Email Address', fieldType: 'EMAIL', displayOnTicket: true, required: true, order: 3 },
        {
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        { name: 'student-id', text: 'Student Id', fieldType: 'STRING', displayOnTicket: true, required: true, order: 5 }
      ],
      CUSTOM_DESCRIPTION_TITLE: 'Custom descriptions',
      CUSTOM_DESCRIPTION_SUBTITLE: 'Add a description for this event',
      ALLOW_EXAMPLE_DESCRIPTION: false,
      COLLECT_ADDITIONAL_INFO_DIRECTION: 'bottom',
      CREATE_TICKET_MODAL: {
        ADD: {
          MODAL_TITLE: 'Create ticket',
          SECONDARY_BTN: 'Cancel'
        },
        UPDATE: {
          MODAL_TITLE: 'Edit ticket',
          SECONDARY_BTN: 'Cancel'
        },
        SAVE_AND_CLOSE: 'Save and close',
        SAVE_AND_ADD_ANOTHER_TICKET: 'Save and add another ticket',
        TICKET_UPDATE_SUCCESS: 'Ticket successfully updated!!',
        TICKET_NAME: 'Ticket name',
        TICKET_NAME_PLACEHOLDER: 'Example: Student ticket',
        SETTINGS: 'Settings and price',
        ATTENDEE_PAYS_FEE: 'Attendee pays the fee',
        ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
        TOGGLE_ON: 'Yes',
        TOGGLE_OFF: 'No',
        GENERATE_LINK: 'Create unique URL',
        TICKET_PRICE: 'Ticket price',
        CURRENCY_PLACEHOLDER: '$0.00',
        ASSIGN_COLOR: 'Assign ticket color',
        ASSIGN_COLOR_DESCRIPTION: 'Ticket color displays on the attendee’s mobile device'
      },
      PREVIEW_FIELD_DESCRIPTION:
        'Preview additional information that attendees must complete when purchasing a ticket for this event.',
      CREATE_LABEL: 'Create Event',
      EDIT_LABEL: 'Edit Event',
      YOUR_SETTINGS: 'Your Event Settings',
      SCAN_ENABLED_LABEL: 'Enable Box Office',
      GATE_MANAGEMENT: 'Gate management'
    },
    'School Dance': {
      ADDITIONAL_INFORMATION: 'Additional info',
      ACTIVITIES: [SCHOOL_DANCE, PROM],
      ACTIVITY_TITLE: 'Activity',
      ALLOW_LEVEL: false,
      EVENT_PLACEHOLDER: 'Example: Homecoming Dance - A Night in Paris',
      ALLOW_EVENT_DESCRIPTION: false,
      EVENT_GENERATE_NAME: false,
      SCHOOL_ROLES: LIMITED_ROLES,
      ALLOW_THEME: false,
      ALLOW_CUSTOM_SPORT_NAME: false,
      TICKET_DISTRIBUTION: false,
      IGNORE_FIELDS: [
        'levels',
        'opponentAccount',
        'opponentAccountId',
        'hostAccount',
        'hostAccountId',
        'theme',
        'customSportName'
      ],
      PAGE_TITLE: 'Add Event',
      ALLOW_SEARCH_SCHOOL: true,
      DATE_TIME_TITLE: 'Event date and time',
      EVENT_DATE_LABEL: 'Event date',
      START_DATE_LABEL: 'Event start date',
      START_TIME_LABEL: 'Event start time',
      END_DATE_LABEL: 'Event end date',
      END_TIME_LABEL: 'Event end time',
      DATE_TIME_INFO: 'Fans can purchase tickets and use them until this date and time:',
      EVENT_NAME_TITLE: 'Event name',
      ALLOW_GATE_OPENING_TIME: true,
      ALLOW_REDEMTION_WINDOW: false,
      ALLOW_EVENT_PAYMENT_CYCLE: true,
      EVENT_VISIBILITY_TITLE: 'Event visibility',
      EVENT_VISIBILITY_TOGGLE_TEXT: "Hide this event from school's GoFan page",
      EVENT_VISIBILITY_UNTOGGLE_TEXT: "Hide this event from school's GoFan page",
      EVENT_VISIBILITY_INFO_TEXT: "Automatically publish this event to the school's GoFan page on:",
      EVENT_ON_SALE_TITLE: 'Tickets on sale',
      EVENT_ON_SALE_TOGGLE_TEXT: 'Tickets go on sale immediately when this event is added',
      EVENT_ALERT_TITLE: 'Event alert',
      EVENT_ALERT_INFO_TEXT: 'Add an alert or special information to the top of your event page',
      ALLOW_DESCRIPTION: true,
      PIN_TO_TOP_LABEL: 'Pin this event to the top of your GoFan page',
      BUYER_PURCHASE_LIMIT_INFO: 'Set the maximum number of tickets a customer can purchase per order',
      TICKET_AVAILABILITY_LABEL: 'Total tickets available for this event',
      TICKET_AVAILABILITY_UNLIMITED_TEXT: 'Unlimited tickets',
      TICKET_TYPE_TITLE: 'Ticket Setup',
      TICKET_CREATION_HEADING: 'Event Tickets',
      ADD_TICKET_TYPE_LABEL: 'Add ticket',
      DUPLICATE_TICKET_LABEL: 'Duplicate ticket',
      TICKET_CREATION_TITLE: 'Select a ticket',
      TICKET_CREATION_FORM_TITLE: 'Create a ticket',
      TICKET_CREATION_SUB_TITLE: '* Fees are not included in ticket prices',
      TICKET_CREATION_PLACEHOLDER: 'Create a ticket type to get started',
      TICKET_TYPE_ITEM_NAME_LABEL: 'Ticket name',
      TICKET_TYPE_ITEM_NAME_PLACEHOLDER: 'Example: General Admission',
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the ticket name?',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the ticket price?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this ticket to “Not on sale” and then create a new ticket type.',
      REVIEW_EVENT_START: 'Event start',
      REVIEW_EVENT_END: 'Event end',
      REVIEW_TIME_ZONE: 'Time zone',
      REVIEW_TICKET_TYPE: 'Ticket type',
      REVIEW_TICKET_PRICE: 'Ticket price',
      REVIEW_UNIQUE_TICKET_LINK: 'Hidden link',
      REVIEW_HIDDEN_FEES: 'Attendee pays fee',
      REVIEW_TICKET_SALES_START: 'Ticket sales begin',
      REVIEW_TICKET_SALES_END: 'Ticket sales end',
      REVIEW_TICKET_FEE: 'Ticket fee',
      REVIEW_TICKET_LIMIT_PER_ORDER: 'Ticket limit per order',
      REVIEW_TICKET_REDEMPTION_LIMIT: 'Ticket redemption limit',
      REVIEW_TICKET_PACK_COUNT: 'Family ticket',
      REVIEW_TICKET_FEE_INFOR: 'Fees are not included in the ticket prices',
      USE_MOBILE_PASS_UI: false,
      ALLOW_REVIEW_REDEMPTION_WINDOW: false,
      ALLOW_REMOVE_SCHOOL: true,
      TICKET_AVAILABILITY_TITLE: 'Ticket availability',
      ALLOW_REVIEW_GATE_OPEN: false,
      CREATE_TICKET_TYPE: 'Create Tickets',
      RETURN_EVENT_CREATION: 'Return to event creation',
      SELECT_TICKET_TYPE: 'Select the ticket type',
      NO_TICKET_TYPE: 'No ticket types yet',
      COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
      COLLECT_ADDITIONAL_INFO_SUBTITLE:
        'Collect additional information that attendees must complete when purchasing a ticket for this event',
      COLLECT_ADDITIONAL_INFO_TYPE_TITLE: 'Information types',
      COLLECT_ADDITIONAL_INFO_SELECTED_TEXT: 'info types selected',
      COLLECT_ADDITIONAL_INFO_SELECT_TEXT: 'Select',
      CUSTOM_FORM_DESCRIPTION:
        'Collect additional information that attendees must complete when purchasing a ticket for this event',
      FORM_TEMPLATE: [
        {
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        { name: 'last-name', text: 'Last Name', fieldType: 'STRING', displayOnTicket: true, required: true, order: 2 },
        {
          name: 'date-first-name',
          text: 'Date First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 3
        },
        {
          name: 'date-last-name',
          text: 'Date Last Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        { name: 'email', text: 'Email Address', fieldType: 'EMAIL', displayOnTicket: true, required: true, order: 5 },
        {
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 6
        },
        {
          name: 'student-id',
          text: 'Student Id',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 7
        },
        {
          name: 'date-student-id',
          text: 'Date Student Id',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 8
        }
      ],
      CUSTOM_DESCRIPTION_TITLE: 'Custom descriptions',
      CUSTOM_DESCRIPTION_SUBTITLE: 'Add a description for this event',
      ALLOW_EXAMPLE_DESCRIPTION: false,
      COLLECT_ADDITIONAL_INFO_DIRECTION: 'bottom',
      CREATE_TICKET_MODAL: {
        ADD: {
          MODAL_TITLE: 'Create ticket',
          SECONDARY_BTN: 'Cancel'
        },
        UPDATE: {
          MODAL_TITLE: 'Edit ticket',
          SECONDARY_BTN: 'Cancel'
        },
        SAVE_AND_CLOSE: 'Save and close',
        SAVE_AND_ADD_ANOTHER_TICKET: 'Save and add another ticket',
        TICKET_UPDATE_SUCCESS: 'Ticket successfully updated!!',
        TICKET_NAME: 'Ticket name',
        TICKET_NAME_PLACEHOLDER: 'Example: Student ticket',
        SETTINGS: 'Settings and price',
        ATTENDEE_PAYS_FEE: 'Attendee pays the fee',
        ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
        TOGGLE_ON: 'Yes',
        TOGGLE_OFF: 'No',
        GENERATE_LINK: 'Create unique URL',
        TICKET_PRICE: 'Ticket price',
        CURRENCY_PLACEHOLDER: '$0.00',
        ASSIGN_COLOR: 'Assign ticket color',
        ASSIGN_COLOR_DESCRIPTION: 'Ticket color displays on the attendee’s mobile device'
      },
      PREVIEW_FIELD_DESCRIPTION:
        'Preview additional information that attendees must complete when purchasing a ticket for this event.',
      CREATE_LABEL: 'Create Event',
      EDIT_LABEL: 'Edit Event',
      YOUR_SETTINGS: 'Your Event Settings',
      SCAN_ENABLED_LABEL: 'Enable Box Office',
      GATE_MANAGEMENT: 'Gate management'
    },
    'Mobile Pass': {
      ADDITIONAL_INFORMATION: 'Additional info',
      ACTIVITY_TITLE: 'Activity',
      ALLOW_LEVEL: true,
      EVENT_PLACEHOLDER: 'Example: All-sports pass',
      ALLOW_EVENT_DESCRIPTION: false,
      EVENT_GENERATE_NAME: true,
      SCHOOL_ROLES: DEFAULT_ROLES,
      ALLOW_THEME: false,
      ALLOW_CUSTOM_SPORT_NAME: true,
      TICKET_DISTRIBUTION: false,
      IGNORE_FIELDS: [],
      PAGE_TITLE: 'Add Mobile Pass',
      ALLOW_SEARCH_SCHOOL: true,
      DATE_TIME_TITLE: 'Pass date and time',
      EVENT_DATE_LABEL: 'Pass date',
      START_DATE_LABEL: 'Pass start date',
      START_TIME_LABEL: 'Pass start time',
      END_DATE_LABEL: 'Pass end date',
      END_TIME_LABEL: 'Pass end time',
      DATE_TIME_INFO: 'Fans can purchase and use passes until this date and time:',
      EVENT_NAME_TITLE: 'Pass name',
      ALLOW_GATE_OPENING_TIME: false,
      ALLOW_REDEMTION_WINDOW: true,
      ALLOW_EVENT_PAYMENT_CYCLE: true,
      EVENT_VISIBILITY_TITLE: 'Pass visibility',
      EVENT_VISIBILITY_TOGGLE_TEXT: "Hide this pass from school's GoFan page",
      EVENT_VISIBILITY_UNTOGGLE_TEXT: "Hide this pass from school's GoFan page",
      EVENT_VISIBILITY_INFO_TEXT: "Automatically publish this pass to the school's GoFan page on:",
      EVENT_ON_SALE_TITLE: 'Passes on sale',
      EVENT_ON_SALE_TOGGLE_TEXT: 'Passes go on sale immediately when this event is added',
      EVENT_ALERT_TITLE: 'Alert',
      EVENT_ALERT_INFO_TEXT: 'Add an alert or special information to the top of your mobile pass page',
      ALLOW_DESCRIPTION: true,
      PIN_TO_TOP_LABEL: 'Pin this pass to the top of your GoFan page',
      BUYER_PURCHASE_LIMIT_INFO: 'Set the maximum number of passes a customer can purchase per order',
      TICKET_AVAILABILITY_LABEL: 'Total passes available for this event',
      TICKET_AVAILABILITY_UNLIMITED_TEXT: 'Unlimited passes',
      TICKET_TYPE_TITLE: 'Pass Setup',
      TICKET_CREATION_HEADING: 'Event Passes',
      ADD_TICKET_TYPE_LABEL: 'Add pass',
      DUPLICATE_TICKET_LABEL: 'Duplicate pass',
      TICKET_CREATION_TITLE: 'Select a pass',
      TICKET_CREATION_FORM_TITLE: 'Create a pass',
      TICKET_CREATION_SUB_TITLE: '* Fees are not included in pass prices',
      TICKET_CREATION_PLACEHOLDER: 'Create a pass type to get started',
      TICKET_TYPE_ITEM_NAME_LABEL: 'Pass name',
      TICKET_TYPE_ITEM_NAME_PLACEHOLDER: 'Create a pass type',
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the pass name?',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the pass price?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this pass to “Not on sale” and then create a new pass type.',
      REVIEW_EVENT_START: 'Pass start',
      REVIEW_EVENT_END: 'Pass end',
      REVIEW_TIME_ZONE: 'Time zone',
      REVIEW_TICKET_TYPE: 'Pass type',
      REVIEW_TICKET_PRICE: 'Pass price',
      REVIEW_UNIQUE_TICKET_LINK: 'Hidden link',
      REVIEW_HIDDEN_FEES: 'Attendee pays fee',
      REVIEW_TICKET_SALES_START: 'Pass sales begin',
      REVIEW_TICKET_SALES_END: 'Pass sales end',
      REVIEW_TICKET_FEE: 'Pass fee',
      REVIEW_TICKET_LIMIT_PER_ORDER: 'Pass limit per order',
      REVIEW_TICKET_REDEMPTION_LIMIT: 'Pass redemption limit',
      REVIEW_TICKET_PACK_COUNT: 'Family pass',
      REVIEW_TICKET_FEE_INFOR: 'Fees are not included in the pass prices',
      USE_MOBILE_PASS_UI: true,
      ALLOW_REVIEW_REDEMPTION_WINDOW: true,
      ALLOW_REMOVE_SCHOOL: true,
      TICKET_AVAILABILITY_TITLE: 'Pass availability',
      ALLOW_REVIEW_GATE_OPEN: false,
      CREATE_TICKET_TYPE: 'Create Passes',
      RETURN_EVENT_CREATION: 'Return to pass creation',
      SELECT_TICKET_TYPE: 'Select the pass type',
      NO_TICKET_TYPE: 'No pass types yet',
      COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
      COLLECT_ADDITIONAL_INFO_SUBTITLE:
        'Collect additional information that attendees must complete when purchasing a ticket for this event',
      COLLECT_ADDITIONAL_INFO_TYPE_TITLE: 'Information types',
      COLLECT_ADDITIONAL_INFO_SELECTED_TEXT: 'info types selected',
      COLLECT_ADDITIONAL_INFO_SELECT_TEXT: 'Select',
      CUSTOM_FORM_DESCRIPTION:
        'Collect additional information that attendees must complete when purchasing a pass for this event',
      FORM_TEMPLATE: [
        {
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        { name: 'last-name', text: 'Last Name', fieldType: 'STRING', displayOnTicket: true, required: true, order: 2 },
        { name: 'email', text: 'Email Address', fieldType: 'EMAIL', displayOnTicket: true, required: true, order: 3 },
        {
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        { name: 'student-id', text: 'Student Id', fieldType: 'STRING', displayOnTicket: true, required: true, order: 5 }
      ],
      CUSTOM_DESCRIPTION_TITLE: 'Custom descriptions',
      CUSTOM_DESCRIPTION_SUBTITLE: 'Add a description for this event',
      ALLOW_EXAMPLE_DESCRIPTION: false,
      COLLECT_ADDITIONAL_INFO_DIRECTION: 'bottom',
      CREATE_TICKET_MODAL: {
        ADD: {
          MODAL_TITLE: 'Create pass',
          SECONDARY_BTN: 'Cancel'
        },
        UPDATE: {
          MODAL_TITLE: 'Edit pass',
          SECONDARY_BTN: 'Cancel'
        },
        SAVE_AND_CLOSE: 'Save and close',
        SAVE_AND_ADD_ANOTHER_TICKET: 'Save and add another pass',
        TICKET_UPDATE_SUCCESS: 'Pass successfully updated!!',
        TICKET_NAME: 'Pass name',
        TICKET_NAME_PLACEHOLDER: 'Example: Student Pass',
        SETTINGS: 'Settings and price',
        ATTENDEE_PAYS_FEE: 'Attendee pays the fee',
        ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
        TOGGLE_ON: 'Yes',
        TOGGLE_OFF: 'No',
        GENERATE_LINK: 'Create unique URL',
        TICKET_PRICE: 'Price',
        CURRENCY_PLACEHOLDER: '$0.00',
        ASSIGN_COLOR: 'Assign pass color',
        ASSIGN_COLOR_DESCRIPTION: 'Pass color displays on the attendee’s mobile device'
      },
      PREVIEW_FIELD_DESCRIPTION:
        'Preview additional information that attendees must complete when purchasing a pass for this event.',
      CREATE_LABEL: 'Create Mobile Pass',
      EDIT_LABEL: 'Edit Mobile Pass',
      YOUR_SETTINGS: 'Your Mobile Pass Settings',
      SCAN_ENABLED_LABEL: 'Enable Box Office',
      GATE_MANAGEMENT: 'Gate management'
    },
    Fundraiser: {
      ACTIVITY_TITLE: 'Sport',
      ALLOW_LEVEL: true,
      ADDITIONAL_INFORMATION: 'Additional info',
      EVENT_PLACEHOLDER: 'Example: Food Drive Night',
      ALLOW_EVENT_DESCRIPTION: false,
      EVENT_GENERATE_NAME: true,
      SCHOOL_ROLES: FUNDRAISER_ROLES,
      ALLOW_THEME: false,
      ALLOW_CUSTOM_SPORT_NAME: false,
      TICKET_DISTRIBUTION: false,
      IGNORE_FIELDS: [],
      PAGE_TITLE: 'Create fundraiser',
      ALLOW_SEARCH_SCHOOL: true,
      DATE_TIME_TITLE: 'Fundraiser date and time',
      EVENT_DATE_LABEL: 'Fundraiser date',
      START_DATE_LABEL: 'Fundraiser start date',
      START_TIME_LABEL: 'Fundraiser start time',
      END_DATE_LABEL: 'Fundraiser end date',
      END_TIME_LABEL: 'Fundraiser end time',
      DATE_TIME_INFO: 'Fans can purchase tickets and use them until this date and time:',
      EVENT_NAME_TITLE: 'Name',
      ALLOW_GATE_OPENING_TIME: true,
      ALLOW_REDEMTION_WINDOW: false,
      ALLOW_EVENT_PAYMENT_CYCLE: false,
      EVENT_VISIBILITY_TITLE: 'Fundraiser visibility',
      EVENT_VISIBILITY_TOGGLE_TEXT: 'Hide this fundraiser on your GoFan page',
      EVENT_VISIBILITY_UNTOGGLE_TEXT: 'Hide this fundraiser on your GoFan page',
      EVENT_VISIBILITY_INFO_TEXT: "Automatically publish this fundraiser to the school's GoFan page on:",
      EVENT_SCAN_ENABLED_TITLE: 'Scan using Box Office',
      EVENT_SCAN_ENABLED_TOGGLE_TEXT:
        'Enabling this option confirms that you will be able to scan fan’s tickets at the gate of this event',
      EVENT_SCAN_ENABLED_UNTOGGLE_TEXT:
        'Enabling this option confirms that you will be able to scan fan’s tickets at the gate of this event',
      EVENT_ON_SALE_TITLE: 'Tickets on sale',
      EVENT_ON_SALE_TOGGLE_TEXT: 'Tickets go on sale immediately when this event is added',
      EVENT_ALERT_TITLE: 'Event alert',
      EVENT_ALERT_INFO_TEXT: 'Add an alert or special information to the top of your event page',
      ALLOW_DESCRIPTION: true,
      PIN_TO_TOP_LABEL: 'Pin this fundraiser to the top of your GoFan page',
      BUYER_PURCHASE_LIMIT_INFO: 'Set the maximum number of tickets a customer can purchase per order',
      TICKET_AVAILABILITY_LABEL: 'Total tickets available for this event',
      TICKET_AVAILABILITY_UNLIMITED_TEXT: 'Unlimited tickets',
      TICKET_TYPE_TITLE: 'Donation Setup',
      TICKET_TYPE_DESCRIPTION: 'The donation level is the set amount a donor wishes to contribute',
      TICKET_CREATION_HEADING: 'Event Tickets',
      ADD_TICKET_TYPE_LABEL: 'Add donation',
      TICKET_TYPE_GF_COUNT: 'GoFan Donations',
      TICKET_TYPE_BO_COUNT: 'Box Office Donations',
      TICKET_TYPE_NOTE: '* Fees are not included in donation prices',
      TICKET_TYPE_ITEM_NAME_LABEL: 'Donation level',
      TICKET_TYPE_ITEM_NAME_PLACEHOLDER: 'Bronze Donation',
      TICKET_TYPE_COLOR_TOOLTIP: 'Assign color to donation level',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the donation price',
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the donation name?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this donation level to “Not on sale” and then create a new donation level.',
      REVIEW_EVENT_START: 'Event start',
      REVIEW_EVENT_END: 'Event end',
      REVIEW_TIME_ZONE: 'Time zone',
      REVIEW_TICKET_TYPE: 'Ticket type',
      REVIEW_TICKET_PRICE: 'Ticket price',
      REVIEW_UNIQUE_TICKET_LINK: 'Hidden link',
      REVIEW_HIDDEN_FEES: 'Donor pays fee',
      REVIEW_TICKET_SALES_START: 'Ticket sales begin',
      REVIEW_TICKET_SALES_END: 'Ticket sales end',
      REVIEW_TICKET_FEE: 'Ticket fee',
      REVIEW_TICKET_LIMIT_PER_ORDER: 'Ticket limit per order',
      REVIEW_TICKET_REDEMPTION_LIMIT: 'Ticket redemption limit',
      REVIEW_TICKET_PACK_COUNT: 'Family ticket',
      REVIEW_TICKET_FEE_INFOR: 'Fees are not included in the ticket prices',
      USE_MOBILE_PASS_UI: false,
      ALLOW_REVIEW_REDEMPTION_WINDOW: false,
      ALLOW_REMOVE_SCHOOL: true,
      TICKET_AVAILABILITY_TITLE: 'Ticket availability',
      ALLOW_REVIEW_GATE_OPEN: false,
      CREATE_TICKET_TYPE: 'Create Donation Levels',
      RETURN_EVENT_CREATION: 'Return to fundraiser creation',
      SELECT_TICKET_TYPE: 'Select the donation level',
      NO_TICKET_TYPE: 'No donation levels yet',
      COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
      COLLECT_ADDITIONAL_INFO_SUBTITLE: 'Collect information that donors must complete when donating',
      COLLECT_ADDITIONAL_INFO_TYPE_TITLE: 'Information types (Optional)',
      COLLECT_ADDITIONAL_INFO_SELECTED_TEXT: 'info types selected',
      COLLECT_ADDITIONAL_INFO_SELECT_TEXT: 'Select',
      CUSTOM_FORM_DESCRIPTION:
        'Collect additional information that attendees must complete when purchasing a donation level for this fundraiser',
      FORM_TEMPLATE: [
        {
          id: 'first-name',
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        {
          id: 'last-name',
          name: 'last-name',
          text: 'Last Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 2
        },
        {
          id: 'email',
          name: 'email',
          text: 'Email Address',
          fieldType: 'EMAIL',
          displayOnTicket: true,
          required: true,
          order: 3
        },
        {
          id: 'phone-number',
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        {
          id: 'student-id',
          name: 'student-id',
          text: 'Student Id',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 5
        }
      ],
      CUSTOM_DESCRIPTION_TITLE: 'Descriptions',
      CUSTOM_DESCRIPTION_SUBTITLE: 'Create a meaningful description and visual for your campaign',
      ALLOW_EXAMPLE_DESCRIPTION: true,
      COLLECT_ADDITIONAL_INFO_DIRECTION: 'top',
      FUNDRAISER_GOAL_TITLE: 'Goal',
      FUNDRAISER_GOAL_DESCRIPTION:
        'Set a goal amount that you want to achieve. You will still receive the full amount you have raised.',
      FUNDRAISER_GOAL_PLACEHOLDER: 'Example: $5,000',
      FUNDRAISER_DURATION_TITLE: 'Fundraiser duration',
      CREATE_TICKET_MODAL: {
        ADD: {
          MODAL_TITLE: 'Create donation level',
          SECONDARY_BTN: 'Cancel'
        },
        UPDATE: {
          MODAL_TITLE: 'Edit donation level',
          SECONDARY_BTN: 'Cancel'
        },
        SAVE_AND_CLOSE: 'Save and close',
        SAVE_AND_ADD_ANOTHER_TICKET: 'Save and add another donation level',
        TICKET_UPDATE_SUCCESS: 'Donation level successfully updated!!',
        TICKET_NAME: 'Donation level',
        TICKET_NAME_PLACEHOLDER: 'Example: Bronze Donation',
        SETTINGS: 'Settings and price',
        ATTENDEE_PAYS_FEE: 'Donor pays the fee',
        ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
        TOGGLE_ON: 'Yes',
        TOGGLE_OFF: 'No',
        GENERATE_LINK: 'Create unique URL',
        TICKET_PRICE: 'Price',
        CURRENCY_PLACEHOLDER: '$0.00',
        ASSIGN_COLOR: 'Assign donation level color',
        ASSIGN_COLOR_DESCRIPTION: 'Donation level color displays on the attendee’s mobile device'
      },
      PREVIEW_FIELD_DESCRIPTION:
        'Preview additional information that attendees must complete when purchasing a donation level for this fundraiser.',
      CREATE_LABEL: 'Create Fundraiser',
      EDIT_LABEL: 'Edit Fundraiser',
      YOUR_SETTINGS: 'Your Fundraiser Settings',
      SCAN_ENABLED_LABEL: 'Enable Box Office',
      GATE_MANAGEMENT: 'Gate management'
    },
    [CONCESSION]: {
      ACTIVITY_TITLE: 'Sport',
      ALLOW_LEVEL: true,
      ADDITIONAL_INFORMATION: 'Additional info',
      EVENT_PLACEHOLDER: 'Example: Football Spring Concession',
      ALLOW_EVENT_DESCRIPTION: false,
      EVENT_GENERATE_NAME: true,
      SCHOOL_ROLES: DEFAULT_ROLES,
      ALLOW_THEME: false,
      ALLOW_CUSTOM_SPORT_NAME: false,
      TICKET_DISTRIBUTION: false,
      IGNORE_FIELDS: [],
      PAGE_TITLE: 'Create concession',
      ALLOW_SEARCH_SCHOOL: true,
      DATE_TIME_TITLE: 'Concession date and time',
      EVENT_DATE_LABEL: 'Concession date',
      START_DATE_LABEL: 'Concession start date',
      START_TIME_LABEL: 'Concession start time',
      END_DATE_LABEL: 'Concession end date',
      END_TIME_LABEL: 'Concession end time',
      DATE_TIME_INFO: 'Fans can purchase tickets and use them until this date and time:',
      EVENT_NAME_TITLE: 'Add a name for your concession',
      ALLOW_GATE_OPENING_TIME: true,
      ALLOW_REDEMTION_WINDOW: false,
      ALLOW_EVENT_PAYMENT_CYCLE: false,
      EVENT_VISIBILITY_TITLE: 'Concession visibility',
      EVENT_VISIBILITY_TOGGLE_TEXT: 'Hide this concession on your GoFan page',
      EVENT_VISIBILITY_UNTOGGLE_TEXT: 'Hide this concession on your GoFan page',
      EVENT_VISIBILITY_INFO_TEXT: "Automatically publish this concession to the school's GoFan page on:",
      EVENT_SCAN_ENABLED_TITLE: 'Scan using Box Office',
      EVENT_SCAN_ENABLED_TOGGLE_TEXT:
        'Enabling this option confirms that you will be able to scan fan’s tickets at the gate of this event',
      EVENT_SCAN_ENABLED_UNTOGGLE_TEXT:
        'Enabling this option confirms that you will be able to scan fan’s tickets at the gate of this event',
      EVENT_ON_SALE_TITLE: 'Tickets on sale',
      EVENT_ON_SALE_TOGGLE_TEXT: 'Tickets go on sale immediately when this event is added',
      EVENT_ALERT_TITLE: 'Event alert',
      EVENT_ALERT_INFO_TEXT: 'Add an alert or special information to the top of your event page',
      ALLOW_DESCRIPTION: true,
      PIN_TO_TOP_LABEL: 'Pin this concession to the top of your GoFan page',
      BUYER_PURCHASE_LIMIT_INFO: 'Set the maximum number of tickets a customer can purchase per order',
      TICKET_AVAILABILITY_LABEL: 'Total tickets available for this event',
      TICKET_AVAILABILITY_UNLIMITED_TEXT: 'Unlimited tickets',
      TICKET_TYPE_TITLE: 'Concession item',
      TICKET_TYPE_DESCRIPTION: 'The concession item is the set amount a donor wishes to contribute',
      TICKET_CREATION_HEADING: 'Ticket creation',
      ADD_TICKET_TYPE_LABEL: 'Add concession item',
      TICKET_TYPE_GF_COUNT: 'GoFan Items',
      TICKET_TYPE_BO_COUNT: 'Box Office Items',
      TICKET_TYPE_NOTE: '* Fees are not included in concession prices',
      TICKET_TYPE_ITEM_NAME_LABEL: 'Concession item',
      TICKET_TYPE_ITEM_NAME_PLACEHOLDER: 'Popcorn',
      TICKET_TYPE_COLOR_TOOLTIP: 'Assign color to concession item',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the concession price',
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the concession name?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this concession item to “Not on sale” and then create a new concession item.',
      REVIEW_EVENT_START: 'Event start',
      REVIEW_EVENT_END: 'Event end',
      REVIEW_TIME_ZONE: 'Time zone',
      REVIEW_TICKET_TYPE: 'Ticket type',
      REVIEW_TICKET_PRICE: 'Ticket price',
      REVIEW_UNIQUE_TICKET_LINK: 'Hidden link',
      REVIEW_HIDDEN_FEES: 'Donor pays fee',
      REVIEW_TICKET_SALES_START: 'Ticket sales begin',
      REVIEW_TICKET_SALES_END: 'Ticket sales end',
      REVIEW_TICKET_FEE: 'Ticket fee',
      REVIEW_TICKET_LIMIT_PER_ORDER: 'Ticket limit per order',
      REVIEW_TICKET_REDEMPTION_LIMIT: 'Ticket redemption limit',
      REVIEW_TICKET_PACK_COUNT: 'Family pass',
      REVIEW_TICKET_FEE_INFOR: 'Fees are not included in the ticket prices',
      USE_MOBILE_PASS_UI: false,
      ALLOW_REVIEW_REDEMPTION_WINDOW: false,
      ALLOW_REMOVE_SCHOOL: true,
      TICKET_AVAILABILITY_TITLE: 'Ticket availability',
      ALLOW_REVIEW_GATE_OPEN: false,
      CREATE_TICKET_TYPE: 'Create Items',
      RETURN_EVENT_CREATION: 'Return to concession creation',
      SELECT_TICKET_TYPE: 'Select the concession item',
      NO_TICKET_TYPE: 'No concession items yet',
      COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
      COLLECT_ADDITIONAL_INFO_SUBTITLE:
        'Collect information that attendees must complete when purchasing a concession item for this concession',
      COLLECT_ADDITIONAL_INFO_TYPE_TITLE: 'Information types (Optional)',
      COLLECT_ADDITIONAL_INFO_SELECTED_TEXT: 'info types selected',
      COLLECT_ADDITIONAL_INFO_SELECT_TEXT: 'Select',
      CUSTOM_FORM_DESCRIPTION:
        'Collect additional information that attendees must complete when purchasing a concession item for this concession',
      FORM_TEMPLATE: [
        {
          id: 'first-name',
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        {
          id: 'last-name',
          name: 'last-name',
          text: 'Last Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 2
        },
        {
          id: 'email',
          name: 'email',
          text: 'Email Address',
          fieldType: 'EMAIL',
          displayOnTicket: true,
          required: true,
          order: 3
        },
        {
          id: 'phone-number',
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        {
          id: 'student-id',
          name: 'student-id',
          text: 'Student Id',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 5
        }
      ],
      CUSTOM_DESCRIPTION_TITLE: 'Descriptions',
      CUSTOM_DESCRIPTION_SUBTITLE: 'Create a meaningful description and visual for your campaign',
      ALLOW_EXAMPLE_DESCRIPTION: true,
      COLLECT_ADDITIONAL_INFO_DIRECTION: 'top',
      CREATE_TICKET_MODAL: {
        ADD: {
          MODAL_TITLE: 'Create concession item',
          SECONDARY_BTN: 'Cancel'
        },
        UPDATE: {
          MODAL_TITLE: 'Edit concession item',
          SECONDARY_BTN: 'Cancel'
        },
        SAVE_AND_CLOSE: 'Save and close',
        SAVE_AND_ADD_ANOTHER_TICKET: 'Save and add another concession item',
        TICKET_UPDATE_SUCCESS: 'Concession item successfully updated!!',
        TICKET_NAME: 'Concession item',
        TICKET_NAME_PLACEHOLDER: 'Example: Popcorn',
        SETTINGS: 'Settings and price',
        ATTENDEE_PAYS_FEE: 'Attendee pays the fee',
        ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
        TOGGLE_ON: 'Yes',
        TOGGLE_OFF: 'No',
        GENERATE_LINK: 'Create unique URL',
        TICKET_PRICE: 'Price',
        CURRENCY_PLACEHOLDER: '$0.00',
        ASSIGN_COLOR: 'Assign concession item color',
        ASSIGN_COLOR_DESCRIPTION: 'Concession item color displays on the attendee’s mobile device'
      },
      PREVIEW_FIELD_DESCRIPTION:
        'Preview additional information that attendees must complete when purchasing a concession item for this concession.',
      CREATE_LABEL: 'Create Concession',
      EDIT_LABEL: 'Edit Concession',
      YOUR_SETTINGS: 'Your Concession Settings',
      SCAN_ENABLED_LABEL: 'Enable Box Office',
      GATE_MANAGEMENT: 'Gate management'
    },
    SEASON: {
      ACTIVITY_TITLE: 'Sport',
      ALLOW_LEVEL: true,
      ADDITIONAL_INFORMATION: 'Additional info',
      EVENT_PLACEHOLDER: 'Example: Football Spring Season',
      ALLOW_EVENT_DESCRIPTION: false,
      EVENT_GENERATE_NAME: true,
      SCHOOL_ROLES: DEFAULT_ROLES,
      ALLOW_THEME: false,
      ALLOW_CUSTOM_SPORT_NAME: false,
      TICKET_DISTRIBUTION: false,
      IGNORE_FIELDS: [],
      PAGE_TITLE: 'Create season',
      ALLOW_SEARCH_SCHOOL: true,
      DATE_TIME_TITLE: 'Season date and time',
      EVENT_DATE_LABEL: 'Season date',
      START_DATE_LABEL: 'Season start date',
      START_TIME_LABEL: 'Season start time',
      END_DATE_LABEL: 'Season end date',
      END_TIME_LABEL: 'Season end time',
      DATE_TIME_INFO: 'Fans can purchase tickets and use them until this date and time:',
      EVENT_NAME_TITLE: 'Add a name for your season',
      ALLOW_GATE_OPENING_TIME: true,
      ALLOW_REDEMTION_WINDOW: false,
      ALLOW_EVENT_PAYMENT_CYCLE: false,
      EVENT_VISIBILITY_TITLE: 'Season visibility',
      EVENT_VISIBILITY_TOGGLE_TEXT: 'Hide this season on your GoFan page',
      EVENT_VISIBILITY_UNTOGGLE_TEXT: 'Hide this season on your GoFan page',
      EVENT_VISIBILITY_INFO_TEXT: "Automatically publish this season to the school's GoFan page on:",
      EVENT_SCAN_ENABLED_TITLE: 'Scan using Box Office',
      EVENT_SCAN_ENABLED_TOGGLE_TEXT:
        'Enabling this option confirms that you will be able to scan fan’s tickets at the gate of this event',
      EVENT_SCAN_ENABLED_UNTOGGLE_TEXT:
        'Enabling this option confirms that you will be able to scan fan’s tickets at the gate of this event',
      EVENT_ON_SALE_TITLE: 'Tickets on sale',
      EVENT_ON_SALE_TOGGLE_TEXT: 'Tickets go on sale immediately when this event is added',
      EVENT_ALERT_TITLE: 'Event alert',
      EVENT_ALERT_INFO_TEXT: 'Add an alert or special information to the top of your event page',
      ALLOW_DESCRIPTION: true,
      PIN_TO_TOP_LABEL: 'Pin this season to the top of your GoFan page',
      BUYER_PURCHASE_LIMIT_INFO: 'Set the maximum number of tickets a customer can purchase per order',
      TICKET_AVAILABILITY_LABEL: 'Total tickets available for this event',
      TICKET_AVAILABILITY_UNLIMITED_TEXT: 'Unlimited tickets',
      TICKET_TYPE_TITLE: 'Season ticket',
      TICKET_TYPE_DESCRIPTION: 'The season ticket is the set amount a donor wishes to contribute',
      TICKET_CREATION_HEADING: 'Ticket creation',
      ADD_TICKET_TYPE_LABEL: 'Add season ticket',
      TICKET_TYPE_GF_COUNT: 'GoFan Items',
      TICKET_TYPE_BO_COUNT: 'Box Office Items',
      TICKET_TYPE_NOTE: '* Fees are not included in season ticket prices',
      TICKET_TYPE_ITEM_NAME_LABEL: 'Season ticket',
      TICKET_TYPE_ITEM_NAME_PLACEHOLDER: 'Popcorn',
      TICKET_TYPE_COLOR_TOOLTIP: 'Assign color to season ticket',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the season ticket price',
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the season name?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this season ticket to “Not on sale” and then create a new season ticket.',
      REVIEW_EVENT_START: 'Event start',
      REVIEW_EVENT_END: 'Event end',
      REVIEW_TIME_ZONE: 'Time zone',
      REVIEW_TICKET_TYPE: 'Ticket type',
      REVIEW_TICKET_PRICE: 'Ticket price',
      REVIEW_UNIQUE_TICKET_LINK: 'Hidden link',
      REVIEW_HIDDEN_FEES: 'Donor pays fee',
      REVIEW_TICKET_SALES_START: 'Ticket sales begin',
      REVIEW_TICKET_SALES_END: 'Ticket sales end',
      REVIEW_TICKET_FEE: 'Ticket fee',
      REVIEW_TICKET_LIMIT_PER_ORDER: 'Ticket limit per order',
      REVIEW_TICKET_REDEMPTION_LIMIT: 'Ticket redemption limit',
      REVIEW_TICKET_PACK_COUNT: 'Family pass',
      REVIEW_TICKET_FEE_INFOR: 'Fees are not included in the ticket prices',
      USE_MOBILE_PASS_UI: false,
      ALLOW_REVIEW_REDEMPTION_WINDOW: false,
      ALLOW_REMOVE_SCHOOL: true,
      TICKET_AVAILABILITY_TITLE: 'Ticket availability',
      ALLOW_REVIEW_GATE_OPEN: false,
      CREATE_TICKET_TYPE: 'Create Items',
      RETURN_EVENT_CREATION: 'Return to season creation',
      SELECT_TICKET_TYPE: 'Select the season ticket',
      NO_TICKET_TYPE: 'No season tickets yet',
      COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
      COLLECT_ADDITIONAL_INFO_SUBTITLE:
        'Collect information that attendees must complete when purchasing a season ticket for this season',
      COLLECT_ADDITIONAL_INFO_TYPE_TITLE: 'Information types (Optional)',
      COLLECT_ADDITIONAL_INFO_SELECTED_TEXT: 'info types selected',
      COLLECT_ADDITIONAL_INFO_SELECT_TEXT: 'Select',
      CUSTOM_FORM_DESCRIPTION:
        'Collect additional information that attendees must complete when purchasing a season ticket for this season',
      FORM_TEMPLATE: [
        {
          id: 'first-name',
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        {
          id: 'last-name',
          name: 'last-name',
          text: 'Last Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 2
        },
        {
          id: 'email',
          name: 'email',
          text: 'Email Address',
          fieldType: 'EMAIL',
          displayOnTicket: true,
          required: true,
          order: 3
        },
        {
          id: 'phone-number',
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        {
          id: 'student-id',
          name: 'student-id',
          text: 'Student Id',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 5
        }
      ],
      CUSTOM_DESCRIPTION_TITLE: 'Descriptions',
      CUSTOM_DESCRIPTION_SUBTITLE: 'Create a meaningful description and visual for your campaign',
      ALLOW_EXAMPLE_DESCRIPTION: true,
      COLLECT_ADDITIONAL_INFO_DIRECTION: 'top',
      CREATE_TICKET_MODAL: {
        ADD: {
          MODAL_TITLE: 'Create season ticket',
          SECONDARY_BTN: 'Cancel'
        },
        UPDATE: {
          MODAL_TITLE: 'Edit season ticket',
          SECONDARY_BTN: 'Cancel'
        },
        SAVE_AND_CLOSE: 'Save and close',
        SAVE_AND_ADD_ANOTHER_TICKET: 'Save and add another season item',
        TICKET_UPDATE_SUCCESS: 'Season ticket successfully updated!!',
        TICKET_NAME: 'Season ticket',
        TICKET_NAME_PLACEHOLDER: 'Example: Popcorn',
        SETTINGS: 'Settings and price',
        ATTENDEE_PAYS_FEE: 'Attendee pays the fee',
        ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
        TOGGLE_ON: 'Yes',
        TOGGLE_OFF: 'No',
        GENERATE_LINK: 'Create unique URL',
        TICKET_PRICE: 'Price',
        CURRENCY_PLACEHOLDER: '$0.00',
        ASSIGN_COLOR: 'Assign season ticket color',
        ASSIGN_COLOR_DESCRIPTION: 'Season ticket color displays on the attendee’s mobile device'
      },
      PREVIEW_FIELD_DESCRIPTION:
        'Preview additional information that attendees must complete when purchasing a season ticket for this season.',
      CREATE_LABEL: 'Create Season',
      EDIT_LABEL: 'Edit Season',
      YOUR_SETTINGS: 'Your Season Settings',
      SCAN_ENABLED_LABEL: 'Enable Box Office'
    },
    [ATHLETIC_SITE]: {
      EDIT_LABEL: 'Customize School Sites',
      CREATE_LABEL: 'Customize School Sites',
      YOUR_SETTINGS: 'Your site settings'
    },
    [SEASON_RENEWAL]: {
      CREATE_LABEL: 'Create Renewal Campaign',
      YOUR_SETTINGS: 'Your season renewal settings'
    },
    [PLAYON_SITE]: {
      EDIT_LABEL: 'Edit PlayOn Site Event',
      CREATE_LABEL: 'Create PlayOn Site Event',
      YOUR_SETTINGS: 'Your Event Settings'
    },
    Other: {
      ADDITIONAL_INFORMATION: 'Additional info',
      ACTIVITY_TITLE: 'Activity',
      ALLOW_LEVEL: true,
      EVENT_PLACEHOLDER: 'Example: The Blue River Rivalry',
      ALLOW_EVENT_DESCRIPTION: true,
      EVENT_GENERATE_NAME: true,
      SCHOOL_ROLES: DEFAULT_ROLES,
      ALLOW_THEME: false,
      ALLOW_CUSTOM_SPORT_NAME: true,
      TICKET_DISTRIBUTION: false,
      IGNORE_FIELDS: ['customSportName'],
      PAGE_TITLE: 'Add Event',
      ALLOW_SEARCH_SCHOOL: true,
      DATE_TIME_TITLE: 'Event date and time',
      EVENT_DATE_LABEL: 'Event date',
      START_DATE_LABEL: 'Event start date',
      START_TIME_LABEL: 'Event start time',
      END_DATE_LABEL: 'Event end date',
      END_TIME_LABEL: 'Event end time',
      DATE_TIME_INFO: 'Fans can purchase tickets and use them until this date and time:',
      EVENT_NAME_TITLE: 'Event name',
      ALLOW_GATE_OPENING_TIME: true,
      ALLOW_REDEMTION_WINDOW: false,
      ALLOW_EVENT_PAYMENT_CYCLE: true,
      EVENT_VISIBILITY_TITLE: 'Event visibility',
      EVENT_VISIBILITY_TOGGLE_TEXT: "Hide this event from school's GoFan page",
      EVENT_VISIBILITY_UNTOGGLE_TEXT: "Hide this event from school's GoFan page",
      EVENT_VISIBILITY_INFO_TEXT: "Automatically publish this event to the school's GoFan page on:",
      EVENT_ON_SALE_TITLE: 'Tickets on sale',
      EVENT_ON_SALE_TOGGLE_TEXT: 'Tickets go on sale immediately when this event is added',
      EVENT_ALERT_TITLE: 'Event alert',
      EVENT_ALERT_INFO_TEXT: 'Add an alert or special information to the top of your event page',
      ALLOW_DESCRIPTION: true,
      PIN_TO_TOP_LABEL: 'Pin this event to the top of your GoFan page',
      BUYER_PURCHASE_LIMIT_INFO: 'Set the maximum number of tickets a customer can purchase per order',
      TICKET_AVAILABILITY_LABEL: 'Total tickets available for this event',
      TICKET_AVAILABILITY_UNLIMITED_TEXT: 'Unlimited tickets',
      TICKET_TYPE_TITLE: 'Ticket Setup',
      TICKET_CREATION_HEADING: 'Event Tickets',
      ADD_TICKET_TYPE_LABEL: 'Add ticket',
      DUPLICATE_TICKET_LABEL: 'Duplicate ticket',
      TICKET_CREATION_TITLE: 'Select a ticket',
      TICKET_CREATION_FORM_TITLE: 'Create a ticket',
      TICKET_CREATION_SUB_TITLE: '* Fees are not included in ticket prices',
      TICKET_CREATION_PLACEHOLDER: 'Create a ticket type to get started',
      TICKET_TYPE_ITEM_NAME_LABEL: 'Ticket name',
      TICKET_TYPE_ITEM_NAME_PLACEHOLDER: 'Example: General Admission',
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the ticket name?',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the ticket price?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this ticket to “Not on sale” and then create a new ticket type.',
      REVIEW_EVENT_START: 'Event start',
      REVIEW_EVENT_END: 'Event end',
      REVIEW_TIME_ZONE: 'Time zone',
      REVIEW_TICKET_TYPE: 'Ticket type',
      REVIEW_TICKET_PRICE: 'Ticket price',
      REVIEW_UNIQUE_TICKET_LINK: 'Hidden link',
      REVIEW_HIDDEN_FEES: 'Attendee pays fee',
      REVIEW_TICKET_SALES_START: 'Ticket sales begin',
      REVIEW_TICKET_SALES_END: 'Ticket sales end',
      REVIEW_TICKET_FEE: 'Ticket fee',
      REVIEW_TICKET_LIMIT_PER_ORDER: 'Ticket limit per order',
      REVIEW_TICKET_REDEMPTION_LIMIT: 'Ticket redemption limit',
      REVIEW_TICKET_PACK_COUNT: 'Family ticket',

      REVIEW_TICKET_FEE_INFOR: 'Fees are not included in the ticket prices',
      USE_MOBILE_PASS_UI: false,
      ALLOW_REVIEW_REDEMPTION_WINDOW: false,
      ALLOW_REMOVE_SCHOOL: true,
      TICKET_AVAILABILITY_TITLE: 'Ticket availability',
      ALLOW_REVIEW_GATE_OPEN: false,
      CREATE_TICKET_TYPE: 'Create Tickets',
      RETURN_EVENT_CREATION: 'Return to event creation',
      SELECT_TICKET_TYPE: 'Select the ticket type',
      NO_TICKET_TYPE: 'No ticket types yet',
      COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
      COLLECT_ADDITIONAL_INFO_SUBTITLE:
        'Collect additional information that attendees must complete when purchasing a ticket for this event',
      COLLECT_ADDITIONAL_INFO_TYPE_TITLE: 'Information types',
      COLLECT_ADDITIONAL_INFO_SELECTED_TEXT: 'info types selected',
      COLLECT_ADDITIONAL_INFO_SELECT_TEXT: 'Select',
      CUSTOM_FORM_DESCRIPTION:
        'Collect additional information that attendees must complete when purchasing a ticket for this event',
      FORM_TEMPLATE: [
        {
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        { name: 'last-name', text: 'Last Name', fieldType: 'STRING', displayOnTicket: true, required: true, order: 2 },
        { name: 'email', text: 'Email Address', fieldType: 'EMAIL', displayOnTicket: true, required: true, order: 3 },
        {
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        { name: 'student-id', text: 'Student Id', fieldType: 'STRING', displayOnTicket: true, required: true, order: 5 }
      ],
      CUSTOM_DESCRIPTION_TITLE: 'Custom descriptions',
      CUSTOM_DESCRIPTION_SUBTITLE: 'Add a description for this event',
      ALLOW_EXAMPLE_DESCRIPTION: false,
      COLLECT_ADDITIONAL_INFO_DIRECTION: 'bottom',
      CREATE_TICKET_MODAL: {
        ADD: {
          MODAL_TITLE: 'Create ticket',
          SECONDARY_BTN: 'Cancel'
        },
        UPDATE: {
          MODAL_TITLE: 'Edit ticket',
          SECONDARY_BTN: 'Cancel'
        },
        SAVE_AND_CLOSE: 'Save and close',
        SAVE_AND_ADD_ANOTHER_TICKET: 'Save and add another ticket',
        TICKET_UPDATE_SUCCESS: 'Ticket successfully updated!!',
        TICKET_NAME: 'Ticket name',
        TICKET_NAME_PLACEHOLDER: 'Example: Student ticket',
        SETTINGS: 'Settings and price',
        ATTENDEE_PAYS_FEE: 'Attendee pays the fee',
        ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
        TOGGLE_ON: 'Yes',
        TOGGLE_OFF: 'No',
        GENERATE_LINK: 'Create unique URL',
        TICKET_PRICE: 'Ticket price',
        CURRENCY_PLACEHOLDER: '$0.00',
        ASSIGN_COLOR: 'Assign ticket color',
        ASSIGN_COLOR_DESCRIPTION: 'Ticket color displays on the attendee’s mobile device'
      },
      PREVIEW_FIELD_DESCRIPTION:
        'Preview additional information that attendees must complete when purchasing a ticket for this event.',
      CREATE_LABEL: 'Create Event',
      EDIT_LABEL: 'Edit Event',
      YOUR_SETTINGS: 'Your Event Settings',
      SCAN_ENABLED_LABEL: 'Enable Box Office',
      GATE_MANAGEMENT: 'Gate management'
    },
    SPONSORSHIP: {
      YOUR_SETTINGS: 'Your Sponsorship Settings',
      CREATE_LABEL: 'Create Sponsor',
      EDIT_LABEL: 'Edit Sponsor'
    }
  },
  // Event Type Dropdown
  VALUES: [ATHLETIC, PERFORMING_ART, REGISTRATION, SCHOOL_DANCE, OTHER],
  // Event Type Constant
  ATHLETIC,
  PERFORMING_ART,
  REGISTRATION,
  SCHOOL_DANCE,
  MOBILE_PASS,
  PROM,
  OTHER,
  FUNDRAISER,
  CONCESSION
};

export const PENDO_EVENT_PAGE_NOTES = {
  REQUIRED_FIELDS_PAGE: 'page=required-fields',
  OPTIONAL_FIELDS_PAGE: 'page=optional-fields',
  TICKETS_PAGE: 'page=tickets',
  REVIEW_PAGE: 'page=review'
};

export const ATHLETIC_ACTIVITIES_EXCLUDE = 'DIGITAL SEASON PASS';

export const CLONE_TICKET_OMIT_FIELDS = ['hiddenFeeBase', 'rateId'];

export default {
  EVENT_SECTIONS,
  EVENT_MESSAGES,
  EVENT_ELEMENT_STATES,
  EVENT_ROLE_IDS,
  TIME_TYPES,
  TIME_ELEMENTS,
  TIME_ERRORS_STRINGS,
  EVENT_DATE_TIME_POPUP,
  GOAL_VALIDATION,
  BUYER_PURCHASE_LIMIT_VALIDATION,
  SCHOOL_ROLES,
  PASS_MESSAGES,
  ERROR_MESSAGES: {
    [MOBILE_PASS]: PASS_MESSAGES,
    [ATHLETIC]: EVENT_MESSAGES,
    [PERFORMING_ART]: PERFORMING_ARTS_MESSAGES,
    [REGISTRATION]: EVENT_MESSAGES,
    [SCHOOL_DANCE]: EVENT_MESSAGES,
    [FUNDRAISER]: FUNDRAISER_MESSAGES,
    [OTHER]: EVENT_MESSAGES
  }
};
