import React, { useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Menu32, Close32, Help32 } from '@carbon/icons-react';
import { Icons } from '@gofan/components';
import { OverflowMenuItem } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import Notification from '../../../../commons/carbon-ui/atoms/Notification';
import AdminUserRoles from '../AdminUserRoles';
import { config } from '@gofan/constants';
import styles, { OverflowMenu } from './styles';
import { RootContext } from '../../../../RootContext';
import headerLogo from '../../../../assets/images/gofan-hq-logo.png';
import { isEmpty } from '../../../../utils/objectUtils';
import { getSchoolImage } from '../../../EventInformationV2/helpers';
// import defaultSchoolIcon from '../../../../assets/images/gofan-icon.png';
import logoSource from '../../../../assets/images/logo.svg';
import { PAGES } from '../../../../config/routes';
import { useFeatureFlags, FEATURE_FLAGS } from '@gofan/hooks/useFeatureFlags';

const Wrapper = styled.header`
  ${styles.wrapper};
`;

const NotificationWrapper = styled.div`
  ${styles.notifiWrapper};
`;

const MenuIconWrapper = styled.div`
  ${styles.menuIconWrapper};
`;

const LeftSection = styled.div`
  ${styles.leftSection};
`;

const LeftLogo = styled.img`
  ${styles.leftLogo};
`;

const RightLogo = styled.img`
  ${styles.rightLogo};
`;

const LeftTitle = styled.div`
  ${styles.leftTitle};
`;

const CenterSection = styled.div`
  ${styles.centerSection};
`;

const GlobalStyle = createGlobalStyle`
  ${styles.globalStyle};
`;
const OverflowContainer = styled.div`
  ${styles.overflowWrapper};
`;

const RightSection = styled.div`
  ${styles.rightSection};
`;

const UserAccount = styled.div`
  ${styles.useraccount};
`;

const UserName = styled.p`
  ${styles.userName};
`;

const GoFanLogo = styled.img`
  ${styles.gofanLogo};
`;

const School = styled.div`
  ${styles.schoolWrapper};
`;
const SchoolLogo = styled.img`
  ${styles.schoolLogo};
`;
const SchoolName = styled.p`
  ${styles.schoolName};
`;

const AccountName = styled.div`
  ${styles.accountName};
`;

export default ({ account, notifications, removeNotification, history, ...props }) => {
  const { currentUser, setCurrentUser, clearAuthorization } = useContext(RootContext);
  const { toggleSideMenu, isSideMenuExpanded } = props;
  const accountName = !isEmpty(account) && !isEmpty(account.name) ? account.name.toUpperCase() : 'HUDDLE HIGH SCHOOL';
  const [isClicked, setIsClicked] = React.useState(false);
  const [, setHoverIcon] = React.useState(false);
  const handleLogout = () => clearAuthorization();
  const handleScroll = () => {
    if (isClicked) {
      setIsClicked(false);
      setHoverIcon(false);
    }
  };
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isClicked]);
  const { [FEATURE_FLAGS.tempNavBarPendoLauncherDashbirds]: navBarPendoLauncherEnabled } = useFeatureFlags() || {};
  const renderBetaReadinessHeader = () => (
    <>
      <GlobalStyle hideWhitePart />
      <Wrapper>
        <LeftSection>
          <MenuIconWrapper onClick={() => toggleSideMenu(!isSideMenuExpanded)}>
            {isSideMenuExpanded ? <Close32 /> : <Menu32 />}
          </MenuIconWrapper>
          {isEmpty(account) ? null : (
            <School>
              {/* <SchoolLogo
                src={
                  isEmpty(account) || isEmpty(account.logo)
                    ? defaultSchoolIcon
                    : getSchoolImage(account.id, 'logo', account.logo)
                }
              /> */}
              <Link to={PAGES.dashboard.root.path} style={{ display: 'flex', alignItems: 'center', color: '#fff' }}>
                <SchoolName className='gs--font-family-sf'>{account === undefined ? '' : accountName}</SchoolName>
              </Link>
            </School>
          )}
        </LeftSection>
        <CenterSection />
        <RightSection>
          <Link to={PAGES.dashboard.root.path} style={{ display: 'flex', alignItems: 'center' }}>
            <RightLogo src={logoSource} alt='GoFan HQ Logo' />
          </Link>
          <UserAccount>
            <AccountName className='gs--font-family-sf gs--iconic-color'>
              {!isEmpty(currentUser) && currentUser.firstName ? `Hi ${currentUser.firstName}` : ''}
            </AccountName>
            <OverflowContainer>
              <OverflowMenu
                renderIcon={Icons.UserAvatarFilled}
                flipped
                open={isClicked}
                onClick={() => setIsClicked(true)}
              >
                <OverflowMenuItem itemText='Logout' onClick={handleLogout} />
              </OverflowMenu>
            </OverflowContainer>
          </UserAccount>
          {import.meta.env.MODE === 'development' && (
            <AdminUserRoles setCurrentUser={setCurrentUser} currentUser={currentUser} />
          )}
          {navBarPendoLauncherEnabled && (
            <MenuIconWrapper id='PendoResourceCenterLauncher'>
              <Help32 />
            </MenuIconWrapper>
          )}
        </RightSection>
        {notifications && notifications.length > 0 && (
          <NotificationWrapper>
            {notifications.map(notification => (
              <Notification
                key={notification.id}
                lowContrast
                timeout={notification.timeout || config.TIMEOUT_NOTIFICATION}
                title={notification.title}
                subtitle={notification.subtitle}
                kind={notification.kind || (notification.isSuccess ? 'success' : 'error')}
                hasAction={notification.hasAction}
                action={notification.action}
                onClickAction={notification.onClickAction}
                onCloseButtonClick={() => removeNotification(notification.id)}
              />
            ))}
          </NotificationWrapper>
        )}
      </Wrapper>
    </>
  );

  const renderHeader = () => (
    <Wrapper>
      <LeftSection>
        <MenuIconWrapper onClick={() => toggleSideMenu(!isSideMenuExpanded)}>
          {isSideMenuExpanded ? <Close32 /> : <Menu32 />}
        </MenuIconWrapper>
        <LeftLogo
          src={
            isEmpty(account) || isEmpty(account.logo) ? headerLogo : getSchoolImage(account.id, 'logo', account.logo)
          }
        />
        <LeftTitle className='gs--font-family-san gs--header-title'>
          {account === undefined ? '' : accountName}
        </LeftTitle>
      </LeftSection>
      <CenterSection />
      <RightSection>
        {import.meta.env.MODE === 'development' && (
          <AdminUserRoles setCurrentUser={setCurrentUser} currentUser={currentUser} />
        )}
        {/* <UserName>{displayName.toUpperCase()}</UserName>
        <RightTitle>EVENT MANAGER</RightTitle> */}
        {/* <Devider /> */}
        <GoFanLogo src={logoSource} />
      </RightSection>
    </Wrapper>
  );
  return config.BETA_READINESS_MODE ? renderBetaReadinessHeader() : renderHeader();
};
