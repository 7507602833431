import React from 'react';
import styled from 'styled-components';
import styles from './styles';
import { isEmptyString } from '../../../../../../utils/stringUtils';

const Wrapper = styled.div``;

export const Item = styled.div`
  ${styles.item};
`;

const ItemWrapper = styled.div`
  ${styles.itemWrapper};
`;

const LogoSection = styled.div`
  ${styles.logoSection};
`;

const DescriptionSection = styled.div`
  ${styles.descriptionSection};
`;

const Logo = styled.img`
  ${styles.logo};
`;

const Title = styled.div`
  ${styles.textWrapper}
  ${styles.title}
  ${styles.font}
`;

const SubTitle = styled.div`
  ${styles.textWrapper}
  ${styles.subTitle}
  ${styles.font}
`;

const getLogoPath = (icon, defaultIcon) => (isEmptyString(icon) ? defaultIcon : icon);

const generateItemTitleId = itemId => `lbl_${itemId}`;

export default ({ item, defaultIcon, onSelectItem = () => null, showIcon = true }) => (
  <Wrapper onClick={() => onSelectItem(item)}>
    {/* The code block below is used for handling click an item */}
    <ItemWrapper id={item.id}>
      <Item>
        {showIcon && (
          <LogoSection>
            <Logo src={getLogoPath(item.icon, defaultIcon)} />
          </LogoSection>
        )}

        <DescriptionSection style={{ marginLeft: showIcon ? 0 : 16 }}>
          <Title id={generateItemTitleId(item.subTitleid)}>{item.title}</Title>
          <SubTitle>{item.subTitle}</SubTitle>
        </DescriptionSection>
      </Item>
    </ItemWrapper>
  </Wrapper>
);
