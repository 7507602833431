import { isEmpty, get } from 'lodash';
import memoizeOne from 'memoize-one';
import type { FIELD_NAME } from '@gofan/constants/fan';
import type { SeasonRenewalSeatsDTO } from '@gofan/api';

export const SCHOOL_DANCER_FORM_FIELDS = ['date-first-name', 'date-last-name', 'date-student-id'];

export const normalizePhoneNumber = (phoneNumber: string): string => {
  const phone = `${phoneNumber}`.replace(/\D/g, '');
  return phone ? [phone.slice(0, 3), phone.slice(3, 6), phone.slice(6, 11)].join('.') : '';
};

export const normalizeFieldValue = (value: string): string => {
  switch (value) {
    case 'true':
      return 'Yes';
    case 'false':
      return 'No';
    default:
      return value;
  }
};

export const transformFieldValue = (fieldName: FIELD_NAME, value: string) => {
  switch (fieldName) {
    case 'phone-number':
      return normalizePhoneNumber(value);
    default:
      return normalizeFieldValue(value);
  }
};

export const getContactName = memoizeOne((lastName: string, firstName: string) =>
  [lastName, firstName].filter(text => !isEmpty(text)).join(' ')
);

export const getSeatInfoByLabel = (seatLabel: string | null) => {
  if (!seatLabel) return null;
  const splitStr = seatLabel.split('-').reverse();
  const [seat, row] = splitStr.slice(0, 2);
  const section = splitStr.slice(2).reverse().join('-');
  return { section, row, seat };
};

export const getSeatInfoBySeasonRenewalSeats = ({
  seatLabel,
  seasonRenewalSeats,
  ticketType = ''
}: {
  seatLabel: string | null;
  seasonRenewalSeats: SeasonRenewalSeatsDTO;
  ticketType?: string;
}) => {
  if (!seatLabel) return null;
  const seatInfos = ticketType ? get(seasonRenewalSeats, ticketType, []) : Object.values(seasonRenewalSeats).flat();
  const found = seatInfos?.find(seatInfo => seatInfo?.seatsIoLabel === seatLabel);
  return found ?? null;
};
