export const STRINGS = {
  ADD: {
    SEASON_TICKET: {
      TITLE: 'Create season ticket package',
      DESCRIPTION:
        'Season ticket packages help generate more money for schools than single event sales. They’re also a great way for fans to save money compared to purchasing individual event tickets and more convenient.'
    },
    TICKET_TYPE: {
      TITLE: 'Create ticket type',
      DESCRIPTION: ''
    }
  },
  UPDATE: {
    SEASON_TICKET: {
      TITLE: 'Update season ticket package',
      DESCRIPTION: ''
    },
    TICKET_TYPE: {
      TITLE: 'Edit ticket type',
      DESCRIPTION: ''
    }
  },
  TICKET_TITLE: {
    SEASON_TICKET: 'GoFan season ticket',
    TICKET_TYPE: 'GoFan ticket types'
  },
  SEASON_TICKET_TITLE: 'GoFan season ticket',
  SEASON_TICKET_NAME: 'Season ticket name',
  SEASON_TICKET_NAME_PLACEHOLDER: 'Example: General Admission',
  SETTINGS_AND_PRICES: 'Settings and prices',
  CURRENT_STATUS: 'Current Status',
  ON_SALE: 'On sale',
  ATTENDEE_PAYS_FEE: 'Attendee pays the fee',
  TOGGLE_ON: 'Yes',
  TOGGLE_OFF: 'No',
  TICKET_PRICE: 'Ticket price',
  FEE: 'Fee amount',
  PAY_TO_SCHOOL: 'Amount paid to school',
  FAN_PRICE: 'Fan price',
  ASSIGN_COLOR: 'Assign ticket color',
  ASSIGN_COLOR_DESCRIPTION: 'Ticket color displays on the attendee’s mobile device',
  CURRENCY_PLACEHOLDER: '$0.00',
  OPTIONAL_SEASON_TICKET_SETTINGS: {
    TITLE: {
      SEASON_TICKET: 'Optional season ticket settings',
      TICKET_TYPE: 'Optional ticket settings'
    },
    SUBTITLE: 'You may configure additional ticket settings for your ticket'
  },
  SALE_START_DATE: 'Sale start date',
  SALE_START_TIME: 'Sale start time',
  SALE_END_DATE: 'Sale end date',
  SALE_END_TIME: 'Sale end time',
  SET_THE_TICKET_FEE: 'Set the ticket fee for each ticket created',
  TICKET_FEE: 'Ticket fee',
  SET_THE_MAXIMUM_NUMBER_TICKETS: 'Set the maximum number of tickets a customer can purchase for this ticket per order',
  TICKET_LIMIT_PER_ORDER: 'Ticket limit per order',
  TICKET_CREATED_SUCCESS: 'Ticket successfully added!',
  TICKET_UPDATE_SUCCESS: 'Ticket successfully updated!',
  TICKET_PACK_COUNT_CAPTION: 'Set the number of tickets that are included in the family ticket',
  TICKET_PACK_COUNT_TOGGLE: 'Make this a family ticket',
  TICKET_PACK_COUNT_LABEL: 'Family ticket',
  SAVE_AND_ADD_ANOTHER_TICKET_BTN: 'Save and add another ticket',
  SAVE_AND_CLOSE_BTN: 'Save and close',
  CANCEL_BTN: 'Cancel',
  NEXT_BTN: 'Next',
  ERROR_MESSAGES: {
    SEASON_TICKET_NAME: {
      REQUIRED: 'Season ticket name is required.',
      INVALID:
        'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (`()./_-#\'@&*+",:;?!) in between 2-40 characters long.'
    },
    SEASON_TICKET_PRICE: {
      REQUIRED: 'Season ticket price is required.',
      INVALID: 'Season ticket price must be between {min} and {max}.'
    },
    TICKET_FEE: {
      INVALID: 'Ticket fee must be be between {min} and {max}.'
    }
  }
};
