import { isEmpty } from 'lodash';
import { USER_ROLE_ADMIN, USER_ROLE_FINANCE } from '../../../api/model';
import contactPermisson from './contactPermisson';

export const checkAccountUserAdminRole = onObject =>
  contactPermisson.checkAccountUserLowerRoles(onObject, [USER_ROLE_ADMIN]);

export const checkAccountUserEditorRole = ({ currentUser, event }) => {
  const allAssociatedAccounts = currentUser.userAccountContexts.filter(item => item.accountId === event.accountId);

  if (isEmpty(allAssociatedAccounts)) {
    return false;
  }

  return allAssociatedAccounts[0].accountRoles.includes('EDITOR');
};

export default {
  checkAccountUserAdminRole,
  checkAccountUserEditorRole
};
