import { AuthState } from './types';
import {
  AuthActionTypes,
  CHECK_INVITATION_EMAIL_STATUS,
  CHECK_STATUS,
  GET_USER_INFORMATION,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  SIGN_IN,
  SIGN_IN_ERRORS,
  SIGN_IN_SUCCESS
} from './actionTypes';

export const initialState: AuthState = {
  loading: false,
  pending: false,
  sendInvitation: false,
  user: {}
};

export default (state = initialState, action: AuthActionTypes) => {
  switch (action.type) {
    case SIGN_IN: {
      return {
        ...state,
        loading: true
      };
    }

    case SIGN_IN_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case RESET_PASSWORD: {
      return {
        ...state,
        loading: true
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case CHECK_STATUS: {
      return {
        ...state,
        loading: false,
        pending: action.payload.status
      };
    }
    case CHECK_INVITATION_EMAIL_STATUS: {
      return {
        ...state,
        loading: false,
        sendInvitation: action.payload.status
      };
    }
    case GET_USER_INFORMATION: {
      return {
        ...state,
        user: action.payload.user
      };
    }
    case SIGN_IN_ERRORS: {
      return {
        ...state,
        error: action.payload.error
      };
    }
    default:
      return state;
  }
};
