// @flow
import {
  MAINTENANCE_MODE,
  END_DATE_ERROR,
  END_DATE_CONSTRAINT,
  END_DATE_DAY_RANGE_FOR_SCHOOL_USER,
  END_DATE_DAY_RANGE_FOR_ADMIN_USER
} from '../api/constants';
import { USER_ROLE_ACCOUNT_USER } from '@gofan/api/users';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

class ErrorDTO {
  statusCode;

  data;

  constructor(response) {
    if (response.response) {
      response = response.response; // eslint-disable-line
    }

    if (response === null || response === undefined || response.code === 'ECONNABORTED') {
      this.statusCode = 5555;
      this.data = 'Cannot reach server';
    } else if (response.code === 503 && response.mode === MAINTENANCE_MODE) {
      this.statusCode = 503;
      this.data = { ...response };
    } else {
      this.statusCode = response.status;
      this.data = response.data;
    }

    if (this.data === undefined || this.data === null) {
      this.data = 'Unexpected Error';
    }

    if (this.statusCode === 504) {
      this.data = 'Unexpected Error: 504 Gateway Timeout';
    }

    if (this.statusCode === undefined || this.statusCode === null) {
      this.statusCode = 500;
    }
  }

  getData() {
    return this.data;
  }

  getErrorMessage() {
    return typeof this.data === 'string' ? this.data : this.data.message || '';
  }

  getErrorMessageByRole(userRole) {
    if (isNil(this.data)) {
      return '';
    }

    if (typeof this.data === 'object' && !isEmpty(this.data.errors)) {
      let errorMessage = '';
      if (this.data.errors.filter(e => e.code === END_DATE_CONSTRAINT).length > 0) {
        const dayRange =
          userRole === USER_ROLE_ACCOUNT_USER ? END_DATE_DAY_RANGE_FOR_SCHOOL_USER : END_DATE_DAY_RANGE_FOR_ADMIN_USER;
        errorMessage += END_DATE_ERROR.replace('%d', dayRange);
      }
      return errorMessage;
    }
    if (typeof this.data === 'string') {
      return this.data;
    }

    return this.data.message;
  }

  getStatusCode() {
    return this.statusCode || 500;
  }

  static convertToError = response => {
    if (response instanceof Error) {
      return new ErrorDTO(response);
    }

    if (response instanceof ErrorDTO) {
      return response;
    }

    return null;
  };
}

export default ErrorDTO;
