import { EventMonitorConfigService } from '@gofan/api';

export class EventConfigService {
  static readonly getAccountIdsEnabledReceivedAwayGames = async (accountIds: string[]): Promise<string[]> => {
    if (!accountIds?.length) {
      return [];
    }
    try {
      const monitorConfig = await EventMonitorConfigService.getEventMonitorConfigByAccountIds(accountIds);
      const results = monitorConfig.filter(c => c.eventMonitorReceiveAwayGames).map(c => c.id);
      return results;
    } catch (e) {
      return [];
    }
  };
}
