import React, { useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { Column, Row } from 'carbon-components-react';

import { config } from '@gofan/constants';
import type { AccountDTO } from '@gofan/api/accounts';
import SectionInfo from '@accounts/components/section-info/section-info.component';
import BasicButton from '@old-components/basic-button/basic-button.component';

import GoFanPageModal from '@dashboard/components/gofan-page-modal/gofan-page-modal.component';
import QRCodeComponent from '@app/commons/generic-components/qr-code/qr-code.component';
import { QR_DOWNLOAD_TYPE } from '@app/commons/generic-components/qr-code/constants';
import Logo from '@assets/images/logo.png';
import CopyLink from '@app/modules/accounts/components/copy-link/copy-link.component';
import defaultSchoolIcon from '@assets/images/gofan-icon.png';

type GoFanPageCardProps = {
  accounts: AccountDTO[];
};

const GoFanPageCard = ({ accounts }: GoFanPageCardProps) => {
  const [toggleGoFanModal, setToggleGoFanModal] = useState<boolean>(false);
  const schoolLink = !isEmpty(accounts) ? `${config.GOFAN_URL}app/school/${accounts[0].id}` : '';

  const schoolLogo = useMemo(
    () => (!isEmpty(accounts) && !isEmpty(accounts[0].logo) ? accounts[0].logo : defaultSchoolIcon),
    [accounts]
  );
  const fileName = !isEmpty(accounts) ? `QR code_${accounts[0].name}` : '';

  const QRCodeButtons = [
    { type: QR_DOWNLOAD_TYPE.DOWNLOAD_IMAGE.type, fileName },
    { type: QR_DOWNLOAD_TYPE.DOWNLOAD_FLYER.type, schoolLogo, fileName, schoolName: accounts?.[0]?.name ?? '' }
  ];
  return (
    <>
      <SectionInfo title='' className='dashboard--gofan-page--card'>
        {isEmpty(accounts) || accounts.length > 1 ? (
          <>
            <GoFanPageModal
              open={toggleGoFanModal}
              accounts={accounts}
              onCloseModal={() => setToggleGoFanModal(false)}
            />
            <div className='gs--padding-bottom__sp5 gs--body-short-01 gs--text-01'>
              GoFan pages (All selected schools)
            </div>
            <BasicButton
              size='sm'
              kind='primary'
              text='View all links'
              disabled={isEmpty(accounts)}
              onClick={() => setToggleGoFanModal(true)}
            />
          </>
        ) : (
          <>
            <Row>
              <Column lg={10}>
                <div className='gs--padding-bottom__sp4 gs--body-short-01 gs--text-01'>
                  <img className='logo-image' alt='Logo' src={Logo} />
                  Page
                </div>
              </Column>
            </Row>
            {accounts[0].gofanPageEnabled ? (
              <CopyLink
                url={schoolLink}
                copyConfig={{
                  feedback: 'Link copied!',
                  tooltipText: 'Copy link',
                  feedbackTimeout: 2000
                }}
              />
            ) : (
              <div className='gs--body-short-01 gs--text-01'>Not created</div>
            )}
          </>
        )}
      </SectionInfo>
      {accounts?.length === 1 && accounts[0].gofanPageEnabled && (
        <SectionInfo title='' className='dashboard--gofan-page--qr-code'>
          {accounts[0].gofanPageEnabled && (
            <QRCodeComponent
              qrCode={`${schoolLink}?utm_source=GoFan&utm_medium=qrcode&utm_campaign=HQDashboardSchoolLink`}
              buttons={QRCodeButtons}
            />
          )}
        </SectionInfo>
      )}
    </>
  );
};

export default GoFanPageCard;
