import type { SeasonInsightDTO } from '@seasons/models/season.model';

import { Button } from 'carbon-components-react';

import TicketSaleNumericalChart from '@app/modules/events/components/ticket-sale-numerical-chart/ticket-sale-numerical-chart';
import NoSalesDollarIcon from '@assets/images/dollar-sign-icon.png';

interface SeasonTicketSaleNumericalChartProps {
  seasonInsights?: SeasonInsightDTO;
  hasSeasonTicketPackage: boolean;
  canCreateSeasonTicket?: boolean;
  createSeasonTicketClassname?: string;
  handleCreateSeasonTicketPackage?: () => void;
}

const SeasonTicketSaleNumericalChart = ({
  seasonInsights,
  hasSeasonTicketPackage,
  canCreateSeasonTicket,
  createSeasonTicketClassname,
  handleCreateSeasonTicketPackage
}: SeasonTicketSaleNumericalChartProps) => {
  const createSeasonTicketPackageText = 'Create a season ticket package to see data';
  const totalTicketsSold = (seasonInsights?.metrics.tickets ?? 0) - (seasonInsights?.metrics.refunded_tickets ?? 0);
  const totalTicketsSale =
    parseFloat(seasonInsights?.metrics.passes_purchased ?? '0') -
    parseFloat(seasonInsights?.metrics.passes_refunded ?? '0');

  if (!hasSeasonTicketPackage) {
    return (
      <div className='season-ticket-sale-numerical-chart gs--font-family-san'>
        <img
          src={NoSalesDollarIcon}
          alt='dollar sign icon'
          className='season-ticket-sale-numerical-chart__no-sales-icon'
        />
        <p>No sales</p>
        {canCreateSeasonTicket ? (
          <div className={createSeasonTicketClassname}>
            <Button onClick={_handleCreateSeasonTicketPackage} kind='ghost'>
              {createSeasonTicketPackageText}
            </Button>
          </div>
        ) : (
          <p>{createSeasonTicketPackageText}</p>
        )}
      </div>
    );
  }

  return (
    <TicketSaleNumericalChart totalTicketsSale={totalTicketsSale} totalTicketsSold={totalTicketsSold} isTextAbove />
  );

  function _handleCreateSeasonTicketPackage(ev: React.MouseEvent<HTMLElement>) {
    if (typeof handleCreateSeasonTicketPackage !== 'function') return;

    ev.preventDefault();
    handleCreateSeasonTicketPackage();
  }
};

export { SeasonTicketSaleNumericalChart };
