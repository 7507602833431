export const GENDER_KEYS = {
  BOYS: { key: 'Boys', name: 'boys' },
  GIRLS: { key: 'Girls', name: 'girls' }
};

export const ALL_GENDER_KEYS = {
  ...GENDER_KEYS,
  EITHER: { key: 'either', name: 'either' },
  EVENT: { key: 'event', name: 'event' }
};

export default {
  GENDER_KEYS,
  ALL_GENDER_KEYS
};
