export const SEARCH_RATE_PARAMS = {
  pageSize: 100,
  pageIndex: 0
};

export const HEADERS_RATE_TABLE = [
  {
    key: 'label',
    header: 'Rate Name',
    isSortable: true
  },
  {
    key: 'rateAmount',
    header: 'Rate ($)',
    isSortable: true
  },
  {
    key: 'ratePercent',
    header: 'Rate (%)',
    isSortable: true
  },
  {
    key: 'productTypeNames',
    header: 'Product Type Name',
    isSortable: false
  },
  {
    key: 'maxPrice',
    header: 'Price range ($)',
    isSortable: true
  },
  {
    key: 'startDate',
    header: 'Start Date',
    isSortable: true
  },
  {
    key: 'endDate',
    header: 'End Date',
    isSortable: true
  },
  {
    key: 'menu',
    header: '',
    isSortable: false
  }
];
