import { get, reduce, isEqual, cloneDeep } from 'lodash';

export const isEmpty = obj =>
  obj === null ||
  obj === undefined ||
  obj === '' ||
  (obj instanceof Array && obj.length === 0) ||
  (obj instanceof Object && Object.keys(obj).length === 0);

export const isDifference = (objA = [], objB = [], fn) => {
  if (isEmpty(objA) && isEmpty(objB)) {
    return false;
  }
  if ((!isEmpty(objA) && isEmpty(objB)) || (isEmpty(objA) && !isEmpty(objB)) || objA.length !== objB.length) {
    return true;
  }

  const mappingFunc = typeof fn === 'function' ? fn : () => null;
  const setB = new Set(objB.map(mappingFunc));
  return objA.some(item => !setB.has(mappingFunc(item)));
};

export const formatMoney = (amount, decimalCount = 2, decimal = '.', thousands = ',') => {
  try {
    if (!amount) {
      amount = 0; // eslint-disable-line
    }
    let stdDecimalCount = Math.abs(decimalCount);
    stdDecimalCount = Number.isNaN(stdDecimalCount) ? 2 : stdDecimalCount;

    const negativeSign = amount < 0 ? '-' : '';
    const stdAmount = Math.abs(Number(amount) || 0).toFixed(stdDecimalCount);
    const i = Number.parseInt(stdAmount, 10).toString();
    const j = i.length > 3 ? i.length % 3 : 0;
    const amountWithoutCurrency =
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (stdDecimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(stdDecimalCount)
            .slice(2)
        : '');

    return `${amountWithoutCurrency}`;
  } catch (e) {
    return amount.toString();
  }
};

export const sliceByKeys = ({ obj, keys = [], omit = [], mapping = {} }) => {
  if (isEmpty(obj)) return { result: {}, rest: {} };

  const clonedObj = cloneDeep(obj);
  if (isEmpty(keys)) return { result: clonedObj, rest: {} };

  let rest = {};
  let result = {};
  Object.keys(clonedObj).forEach(key => {
    if (!omit.includes(key)) {
      const mapKey = mapping[key] || key;
      if (keys.includes(key)) {
        result = { ...result, [mapKey]: clonedObj[key] };
      } else {
        rest = { ...rest, [mapKey]: clonedObj[key] };
      }
    }
  });
  return { result, rest };
};

export const resetValue = (object, fields = []) => {
  const updatedObj = { ...object };
  fields.forEach(field => {
    if (field in object) {
      updatedObj[field] = null;
    }
  });
  return updatedObj;
};

export const getDiffKeys = (objA = {}, objB = {}, defaultValues = {}) =>
  reduce(
    objA,
    (result, value, key) => {
      const defaultValue = get(defaultValues, key) || '';
      const valueA = value || defaultValue;
      const valueB = get(objB, key) || defaultValue;
      if (!isEqual(valueA, valueB)) {
        result.push(key);
      }
      return result;
    },
    []
  );

let uniqueCounter = 0;
export const getUniqueId = () => {
  uniqueCounter += 1;
  return new Date().getTime() + uniqueCounter;
};

export const getJson = str => {
  try {
    return JSON.parse(str);
  } catch {
    return {};
  }
};
