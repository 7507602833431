import React from 'react';
import BaseInput from './BaseInput';
import FormSafeguard from './FormSafeguard';
import { colorValidation } from '../../validations';
import errorMessage from '../../errorMessages';

export default ({ formKey, label, overwritable, required }) => (
  <FormSafeguard formKey={formKey}>
    <BaseInput
      formKey={formKey}
      label={label}
      overwritable={overwritable}
      required={required}
      validation={colorValidation}
      errorMessage={errorMessage.COLOR_ERROR_MESSAGE}
    />
  </FormSafeguard>
);
