export const phoneTypeOptions = [
  ['Mobile', 'Mobile'],
  ['Home', 'Home'],
  ['Office', 'Office']
];

export const phoneTypeOptionsForSelect = phoneTypeOptions.map(type => ({
  text: type[0],
  value: type[1]
}));
