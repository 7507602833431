import { useContext, useEffect } from 'react';
import { FlexGrid, SkeletonPlaceholder, SkeletonText } from '@gofan/components';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';

import { RatesConfigurationForm } from './sections/rates-configuration-form';

import { useRate } from '@gofan/api/rates';
import { useProductTypes } from '@gofan/api/product-type';
import { useSearchAccounts } from '@gofan/api/accounts';

import { RootContext } from '@app/RootContext';
import { checkPermission } from '@app/commons';
import { addNotification } from '@gofan/utils/toast';
import { STRINGS } from '@rates/strings';
import { PAGES } from '@app/config/routes';
import ErrorDTO from '@app/api/dto/ErrorDTO';

import type { RateDTO } from '@gofan/api/rates';
import type { RouteChildrenProps } from 'react-router-dom';

import './rates-configuration.scss';

interface RatesConfigurationProps extends RouteChildrenProps {
  mode: 'add' | 'edit';
}

export const RatesConfiguration = ({ mode, match, history }: RatesConfigurationProps) => {
  const { currentUser } = useContext(RootContext);
  const role = {
    add: checkPermission({
      ifAllowedTo: 'create.rate',
      on: currentUser
    }),
    edit: checkPermission({
      ifAllowedTo: 'edit.rate',
      on: currentUser
    })
  };

  const { id: rateId } = (match?.params ?? {}) as any;
  const {
    data: rate,
    isInitialLoading: isRateInitialLoading,
    createRate: { mutate: createRate, isLoading: creating },
    updateRate: { mutate: updateRate, isLoading: updating }
  } = useRate({ rateId, expands: ['product-types'], queryOptions: { staleTime: 0, enabled: !!rateId } });
  const { data: productTypes = [], isInitialLoading: isProductTypesInitialLoading } = useProductTypes({});
  const { data: selectedAccounts, isInitialLoading: isSelectedAccountsInitialLoading } = useSearchAccounts({
    accountIds: uniq([...(rate?.huddleIds ?? []), ...(rate?.exceptions ?? [])]),
    queryOptions: { enabled: !isEmpty(rate) }
  });
  const initiated = !isRateInitialLoading && !isProductTypesInitialLoading && !isSelectedAccountsInitialLoading;
  const isLoading = mode === 'add' ? creating : updating;

  useEffect(() => {
    const hasPermission = role[mode];
    if (!hasPermission) {
      history.replace(PAGES.dashboard.root.path);
    }
  }, []);

  return (
    <FlexGrid className='rates-configuration-container'>
      <div className='gs--productive-heading-05-semibold gs--font-family-sf'>{STRINGS.RATE_CONFIGURATION}</div>

      {initiated ? (
        <RatesConfigurationForm
          mode={mode}
          rate={rate}
          productTypes={productTypes}
          selectedAccounts={selectedAccounts}
          loading={isLoading}
          onSave={_onSave}
          onCancel={_onCancel}
        />
      ) : (
        <div className='gs--margin-top-sp5'>
          <SkeletonText />
          <SkeletonPlaceholder />
        </div>
      )}
    </FlexGrid>
  );

  function _onSave(values: Partial<RateDTO>) {
    if (mode === 'add') {
      createRate(omit(values, 'id'), {
        onSuccess: data => {
          addNotification({
            type: 'success',
            message: `${data.label} is added.`,
            options: { duration: 7000 }
          });
          history.push(PAGES.rates.root.path, { newRateId: data.id });
        },
        onError: (error: any) => {
          addNotification({
            type: 'error',
            message: new ErrorDTO(error).getErrorMessage(),
            options: { duration: 7000 }
          });
        }
      });
    } else {
      updateRate(values, {
        onSuccess: data => {
          addNotification({
            type: 'success',
            message: `${data.label} is updated.`,
            options: { duration: 7000 }
          });
          history.push(PAGES.rates.root.path, { newRateId: data.id });
        },
        onError: (error: any) => {
          addNotification({
            type: 'error',
            message: new ErrorDTO(error).getErrorMessage(),
            options: { duration: 7000 }
          });
        }
      });
    }
  }

  function _onCancel() {
    history.push(PAGES.rates.root.path);
  }
};
