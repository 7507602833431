import { Message } from './types';

export const ADD_MESSAGE = 'root/SEND_MESSAGE';
export const REMOVE_MESSAGE = 'root/REMOVE_MESSAGE';
export const CHECK_SERVER_ON_MAINTENANCE = 'root/CHECK_SERVER_ON_MAINTENANCE';

interface AddMessageAction {
  type: typeof ADD_MESSAGE;
  payload: {
    message: Message;
  };
}

interface RemoveMessageAction {
  type: typeof REMOVE_MESSAGE;
  payload: {
    id: number;
  };
}

interface checkServerOnMaintenanceAction {
  type: typeof CHECK_SERVER_ON_MAINTENANCE;
  payload: {
    history: Object;
  };
}

export type RootActionTypes = AddMessageAction | RemoveMessageAction | checkServerOnMaintenanceAction;
