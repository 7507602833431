import { useRef, useState, useEffect } from 'react';
import { Search } from 'carbon-components-react';
import styled from 'styled-components';
import styles from './styles';
import { isEmpty } from '../../../../../../utils/objectUtils';

import SearchResultsList from '../SearchResultsList';

const StyledContainer = styled.div`
  ${styles.container};
`;

const StyledErrorLabel = styled.div`
  ${styles.errorLabel};
`;

export default ({
  light = true,
  disabled = false,
  searching = false,
  labelText = '',
  placeHolderText = 'Search',
  results = [],
  defaultIcon = '',
  onTextChange = value => {},
  onSelectItem = value => {},
  invalid = false,
  invalidText = '',
  useCustomMessageNotFound = false,
  direction = 'bottom',
  showIcon = true,
  groupConditions = [],
  ...props
}) => {
  const searchRefs = useRef(null);
  const searchTextRef = useRef('');
  const [width, setWidth] = useState(0);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    const searchInput = searchRefs.current.querySelector('.bx--search-input');

    const handleFocus = () => setFocused(true);

    const handleBlur = () => {
      setTimeout(() => {
        setFocused(false);
      }, 200);
    };

    if (searchInput) {
      searchInput.addEventListener('focus', handleFocus);
      searchInput.addEventListener('blur', handleBlur);
    }

    // Cleanup function to remove event listeners
    return () => {
      if (searchInput) {
        searchInput.removeEventListener('focus', handleFocus);
        searchInput.removeEventListener('blur', handleBlur);
      }
    };
  }, []);

  useEffect(() => {
    setWidth(searchRefs.current.offsetWidth);
  });

  return (
    <StyledContainer ref={searchRefs} direction={direction}>
      <Search
        {...props}
        light={light}
        disabled={disabled}
        labelText={labelText}
        placeHolderText={placeHolderText}
        onChange={e => {
          if (e.type === 'change') {
            if (!isEmpty(e.currentTarget)) {
              onTextChange(e.currentTarget.value);
              searchTextRef.current = e.currentTarget.value;
            } else {
              onTextChange('');
              searchTextRef.current = '';
            }
          } else {
            onTextChange('');
            searchTextRef.current = '';
          }
        }}
      />
      <SearchResultsList
        useCustomMessageNotFound={useCustomMessageNotFound}
        messageNotFound={searchTextRef.current}
        width={width}
        defaultIcon={defaultIcon}
        showIcon={showIcon}
        focused={focused}
        results={results}
        searching={searching}
        onSelectItem={seletedItem => {
          searchTextRef.current = '';
          onSelectItem(seletedItem);
        }}
        direction={direction}
        groupConditions={groupConditions}
      />
      {invalid && <StyledErrorLabel>{invalidText}</StyledErrorLabel>}
    </StyledContainer>
  );
};
