// @flow

class PageRequest {
  constructor({ pageSize, pageIndex }) {
    this.raw = {
      pageSize,
      pageIndex
    };
  }

  get pageIndex() {
    return this.raw.pageIndex || 0;
  }

  get pageSize() {
    return this.raw.pageSize || 10;
  }

  toQueryString() {
    return `page=${this.pageIndex}&size=${this.pageSize}`;
  }
}

export default PageRequest;
