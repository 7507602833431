import {
  ON_CREATE_SEASON_RENEWAL_CAMPAIGN,
  ON_CREATE_SEASON_RENEWAL_CAMPAIGN_COMPLETED,
  ON_HANDLE_ERROR,
  ON_PREPARE_DATA,
  ON_PREPARE_DATA_COMPLETED,
  ON_RESET_STATE,
  ON_STORE_SEASON_RENEWAL_CAMPAIGN,
  ON_STORE_SEASON_RENEWAL_ATTENDEES
} from './actionTypes';

import type { SeasonRenewalActionType } from './actionTypes';
import type { SeasonRenewalState } from './types';

export const initialState: SeasonRenewalState = {
  loading: false,
  initiated: false,
  season: undefined,
  seasonRenewal: undefined,
  importedSeasonRenewals: [],
  seasonRenewalAttendees: []
};

const seasonRenewalReducer = (
  // eslint-disable-next-line default-param-last
  state: SeasonRenewalState = initialState,
  action: SeasonRenewalActionType
): SeasonRenewalState => {
  switch (action.type) {
    case ON_PREPARE_DATA: {
      return {
        ...state,
        initiated: false
      };
    }

    case ON_PREPARE_DATA_COMPLETED: {
      const { season } = action.payload;
      return {
        ...state,
        season,
        initiated: true
      };
    }

    case ON_CREATE_SEASON_RENEWAL_CAMPAIGN: {
      return { ...state, loading: true, seasonRenewal: undefined };
    }

    case ON_CREATE_SEASON_RENEWAL_CAMPAIGN_COMPLETED: {
      return { ...state, loading: false, seasonRenewal: action.payload };
    }

    case ON_STORE_SEASON_RENEWAL_ATTENDEES: {
      return { ...state, seasonRenewalAttendees: action.payload };
    }

    case ON_STORE_SEASON_RENEWAL_CAMPAIGN: {
      return {
        ...state,
        seasonRenewal: action.payload?.seasonRenewal,
        importedSeasonRenewals: action.payload?.importedSeasonRenewals
      };
    }

    case ON_HANDLE_ERROR: {
      return { ...state, loading: false, initiated: true };
    }

    case ON_RESET_STATE: {
      return { ...initialState };
    }

    default:
      return { ...state };
  }
};

export default seasonRenewalReducer;
