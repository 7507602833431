import { Modal as CarbonModal } from '@carbon/react';

import type { ModalProps } from '@carbon/react/lib/components/Modal/Modal';

import './Modal.styles.scss';
import { forwardRef } from 'react';

export const Modal = forwardRef<HTMLDivElement, ModalProps>((props, ref) => (
  <CarbonModal className='gf--modal' {...props} ref={ref} />
));
