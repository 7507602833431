import { all, call, put, takeLatest, delay, debounce } from 'redux-saga/effects';
import PublishEditEventRequest from '@app/api/model/request/PublishEditEventRequest';
import {
  getEvent,
  updateEvent,
  mappingPublishEventValues,
  extractPuslishedEventFields,
  handlePickEditingPermission
} from '@api/services/EventService';
import CacheService from '@app/api/services/CacheService';
import {
  PREPARE_DATA,
  ON_PUBLISH_EVENT,
  SEARCH_ACCOUNT_PRODUCT_TYPES,
  SEARCH_OPPONENT_ACCOUNTS
} from './actionTypes.ts';
import {
  prepareData as prepareDataAction,
  prepareDataCompleted,
  onPublishEventCompleted,
  searchAccountProductTypesCompleted,
  searchOpponentAccountsSuccess,
  onSetPermission
} from './actions.ts';

import { isEmpty } from '../../utils/objectUtils';
import { EVENT_DETAIL } from '../../config/strings';
import { CANCELLED_REQUEST, UNEXPECTED_ERROR } from '../../api/api/constants';
import LevelService from '../../api/services/LevelService';
import { checkInternalUser, checkPermissionHandlingEvent } from '../../api/services/UserService';
import { VenueService } from '@gofan/api/venues';
import { getVenueById } from '../../api/services/VenueService';
import {
  cancelFetchAccountsByKeyword,
  fetchAccountById,
  fetchAccountsByKeyword
} from '../../api/services/AccountService';
import ActivityService from '../../api/services/ActivityService';
import { PromotionService } from '@gofan/api/promotions';
import EventV2 from '../../api/model/EventV2';
import ErrorDTO from '../../api/dto/ErrorDTO';
import PaginationModel from '../../api/model/PaginationModel';
import Account from '../../api/model/Account';
import { showAccountInfo } from '../Root/actions';
import { createPromoCodes, searchAccountTickets } from '../EventInformationV2/sagas';
import { EVENT_TYPES } from '../EventInformationV2/constants';
import { getEventType, preserveComplimentaryTickets } from './helpers';
import { hasEditorEventPermission, isCoach } from '@gofan/api';

import { AccountService } from '@gofan/api/accounts';

export const DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST = 1000;
export function* prepareEventData(action) {
  try {
    const { eventId, mode, currentUser } = action.payload;

    const eventResponse = yield call(getEvent, eventId, {
      expand: ['levels', 'activity', 'event-type', 'event-sales-info']
    });
    if (eventResponse instanceof ErrorDTO) {
      return eventResponse;
    }

    let hasPermission =
      (yield call(checkPermissionHandlingEvent, {
        viewMode: mode === 'view',
        currentUser,
        accountId: eventResponse.accountId
      })) ||
      (yield call(checkPermissionHandlingEvent, {
        viewMode: mode === 'view',
        currentUser,
        accountId: eventResponse.financialAccountId
      })) ||
      (yield call(() => isCoach(currentUser, eventResponse.accountId), {})) ||
      (yield call(() => hasEditorEventPermission(currentUser, eventResponse), {}));

    if (!hasPermission) {
      const conferenceLeagueAccount = yield call(AccountService.getConferenceLeagueAccount, eventResponse.accountId);

      hasPermission = yield call(checkPermissionHandlingEvent, {
        viewMode: mode === 'view',
        currentUser,
        accountId: conferenceLeagueAccount?.id
      });
    }

    yield put(onSetPermission(hasPermission));

    // Accounts
    const taggedAccountIds = isEmpty(eventResponse.taggedAccountIds) ? [] : eventResponse.taggedAccountIds;

    // Get Account Info for ticket distribution
    const distributedAccountIds = [];
    if (!isEmpty(eventResponse.accountsTicket)) {
      eventResponse.accountsTicket.forEach(accountTicket => {
        const accId = `${accountTicket.accountId}`;
        if (distributedAccountIds.indexOf(accId) === -1) {
          distributedAccountIds.push(accId);
        }
      });
    }

    const accountIds = [
      eventResponse.accountId,
      eventResponse.hostAccountId,
      eventResponse.opponentAccountId,
      eventResponse.financialAccountId,
      ...taggedAccountIds,
      ...distributedAccountIds
    ].reduce((res, id) => {
      if (id && res.indexOf(id) === -1) {
        res.push(id);
      }
      return res;
    }, []);

    const accountResponses = yield all(accountIds.map(id => call(fetchAccountById, id)));

    let error;
    let account;
    let hostAccount;
    let opponentAccount;
    let financialAccount;
    const taggedAccounts = [];
    const distributedAccounts = [];

    accountResponses.some(item => {
      if (item instanceof ErrorDTO) {
        error = item;
        return true;
      }
      if (item.id === eventResponse.accountId) {
        account = item;
      }
      if (item.id === eventResponse.hostAccountId) {
        hostAccount = item;
      }
      if (item.id === eventResponse.opponentAccountId) {
        opponentAccount = item;
      }
      if (item.id === eventResponse.financialAccountId) {
        financialAccount = item;
      }
      if (taggedAccountIds.indexOf(item.id) !== -1) {
        taggedAccounts.push(item);
      }
      if (distributedAccountIds.indexOf(item.id) !== -1) {
        distributedAccounts.push(item);
      }
      return false;
    });

    if (!isEmpty(account)) {
      yield put(showAccountInfo(account));
    }

    if (error instanceof ErrorDTO) {
      return error;
    }

    // Venue
    let venue = {};
    if (
      !eventResponse.venueAddress &&
      !eventResponse.venueCity &&
      !eventResponse.venueName &&
      !eventResponse.venueState &&
      !eventResponse.venueZip &&
      eventResponse.venueId
    ) {
      const venueResponse = yield call(getVenueById, eventResponse.venueId);
      if (venueResponse instanceof ErrorDTO) {
        return venueResponse;
      }
      venue = {
        venueAddress: venueResponse.streetAddress,
        venueCity: venueResponse.city,
        venueName: venueResponse.name,
        venueState: venueResponse.state,
        venueZip: venueResponse.zip
      };
    }
    let playOffEventPermission;
    if (!checkInternalUser(currentUser.role)) {
      const allowedAccounts = [];
      if (checkPermissionHandlingEvent({ viewMode: false, currentUser, accountId: eventResponse.accountId })) {
        allowedAccounts.push(account);
      }
      if (
        eventResponse.accountId !== eventResponse.financialAccountId &&
        financialAccount &&
        checkPermissionHandlingEvent({
          viewMode: false,
          currentUser,
          accountId: eventResponse.financialAccountId
        })
      ) {
        allowedAccounts.push(financialAccount);
      }

      playOffEventPermission = yield call(handlePickEditingPermission, allowedAccounts, eventResponse);
    }

    const { hasAccessCode, hasPromotionCode } = yield call(PromotionService.hasPromotionCode, eventId);

    return new EventV2({
      ...eventResponse.toJSON(),
      ...venue,
      account,
      hostAccount,
      opponentAccount,
      financialAccount,
      taggedAccounts,
      distributedAccounts,
      playOffEventPermission,
      hasAccessCode,
      hasPromotionCode
    });
  } catch (error) {
    return ErrorDTO.convertToError(error);
  }
}

export function* prepareData(action) {
  try {
    const { eventId, compareEvent } = action.payload;

    if (isEmpty(eventId)) {
      yield put(
        prepareDataCompleted({
          error: EVENT_DETAIL.NOT_FOUND.replace('{eventId}', eventId)
        })
      );
      return;
    }

    const [eventResponse, levelsResponse, activitiesResponse] = yield all([
      call(prepareEventData, action),
      call(LevelService.fetchAllLevels),
      call(ActivityService.fetchAllActivities)
    ]);

    if (
      eventResponse instanceof ErrorDTO ||
      levelsResponse instanceof ErrorDTO ||
      activitiesResponse instanceof ErrorDTO
    ) {
      const error = [eventResponse, levelsResponse, activitiesResponse].find(item => item instanceof ErrorDTO);
      const errorMessage = !isEmpty(error.data) && !isEmpty(error.data.message) ? error.data.message : UNEXPECTED_ERROR;
      yield put(prepareDataCompleted({ error: errorMessage }));
      return;
    }

    if (!isEmpty(compareEvent)) {
      const updatedFields = yield call(extractPuslishedEventFields, {
        isFundraiser: compareEvent.isFundraiser,
        fields: compareEvent,
        otherFields: eventResponse.toJSON()
      });
      yield put(onPublishEventCompleted({ updatedFields }));
    }

    const { athleticActivities, nonAthleticActivities } = yield call(
      ActivityService.groupActivityByType,
      activitiesResponse
    );
    const levels = yield call(LevelService.filterAvailableLevels, levelsResponse);

    yield put(
      prepareDataCompleted({
        levels,
        athleticActivities,
        nonAthleticActivities,
        event: eventResponse.toJSON()
      })
    );
  } catch (error) {
    const errorMessage = !isEmpty(error.data) && !isEmpty(error.data.message) ? error.data.message : UNEXPECTED_ERROR;
    yield put(prepareDataCompleted({ error: errorMessage }));
  }
}

export function* watchPrepareData() {
  yield takeLatest(PREPARE_DATA, prepareData);
}

export function* publishChangesEvent(action) {
  try {
    const {
      mode,
      event,
      history,
      activityType,
      currentUser,
      requiredData,
      optionalData,
      ticketTypeData,
      streamingData
    } = action.payload;
    // await for loading
    yield delay(100);
    const updatedValues = yield call(mappingPublishEventValues, {
      event,
      activityType,
      requiredData,
      optionalData,
      ticketTypeData,
      streamingData
    });

    if (updatedValues instanceof ErrorDTO) {
      const errorMessage =
        !isEmpty(updatedValues.data) && updatedValues.data.message ? updatedValues.data.message : UNEXPECTED_ERROR;
      yield put(onPublishEventCompleted({ error: errorMessage }));
      return;
    }

    const eventType = getEventType(!isEmpty(requiredData) ? requiredData : event);
    if (requiredData.activity && !EVENT_TYPES.CONFIG[eventType].ALLOW_LEVEL) {
      updatedValues.specialEventDescription = undefined;
      updatedValues.customSportName = null;
      updatedValues.levels = [];
      updatedValues.genders = [];
    }

    const schoolRoles = EVENT_TYPES.CONFIG[eventType].SCHOOL_ROLES || [];
    if (schoolRoles.findIndex(role => role.id === 'opponentAccountId') === -1) {
      updatedValues.opponentAccountId = null;
    }
    if (schoolRoles.findIndex(role => role.id === 'hostAccountId') === -1) {
      updatedValues.hostAccountId = null;
    }
    if (schoolRoles.findIndex(role => role.id === 'taggedAccountIds') === -1) {
      updatedValues.taggedAccountIds = [];
    }
    const preservedCompTicketsEvent = preserveComplimentaryTickets(event, updatedValues);
    const eventRequest = new PublishEditEventRequest(preservedCompTicketsEvent);
    // in the future this criteria might be different
    const checkDupes = !requiredData.postSeason;
    const eventResponse = yield call(updateEvent, eventRequest, checkDupes);
    if (eventResponse instanceof ErrorDTO) {
      const errorMessage = !isEmpty(eventResponse.data) ? eventResponse.data.message : UNEXPECTED_ERROR;
      const responseData = eventResponse.getData();
      yield put(onPublishEventCompleted({ error: errorMessage, response: responseData }));
      return;
    }

    yield delay(1000);
    yield put(prepareDataAction(event.id, mode, history, currentUser, event));

    try {
      yield call(createPromoCodes, {
        payload: {
          eventResponse,
          ticketTypeData,
          promotionCodes: isEmpty(optionalData) ? [] : optionalData.promotionCodes
        }
      });
    } catch (e) {
      const error = new ErrorDTO(e);
      yield put(
        onPublishEventCompleted({
          updatedFields,
          error: error.getErrorMessage() || UNEXPECTED_ERROR
        })
      );
    }

    let isReservedSeating = false;
    const { products = [] } = ticketTypeData;

    products.forEach(product => {
      if (product.reservedSeating === true && !isReservedSeating) isReservedSeating = true;
    });

    if (isReservedSeating) yield call(VenueService.syncVenueChart, eventRequest.venueId);
  } catch (error) {
    yield put(onPublishEventCompleted({ error: UNEXPECTED_ERROR }));
  }
}

export function* watchPublishChangesEvent() {
  yield takeLatest(ON_PUBLISH_EVENT, publishChangesEvent);
}

export function* searchAccounts(action) {
  try {
    const coordinate = CacheService.getCachedData(
      CacheService.USER_COORDINATE_CACHE_NAME,
      CacheService.CURRENT_COORDINATE_CACHE_NAME
    );
    const { keyword } = action.payload;
    const response = yield call(fetchAccountsByKeyword, keyword, coordinate, true);
    if (response instanceof Array) {
      yield put(searchOpponentAccountsSuccess(response.map(account => new Account(account).toJSON())));
    } else {
      yield put(searchOpponentAccountsSuccess([]));
    }
  } catch (e) {
    if (e.message !== CANCELLED_REQUEST) yield put(searchOpponentAccountsSuccess([]));
  }
}

export function* cancelSearchAccounts() {
  cancelFetchAccountsByKeyword();
}

export function* watchSearchOpponentAccounts() {
  yield takeLatest(SEARCH_OPPONENT_ACCOUNTS, cancelSearchAccounts);
  yield debounce(DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST, SEARCH_OPPONENT_ACCOUNTS, searchAccounts);
}

export function* handleSearchAccountProductTypes(action) {
  let searchResult = new PaginationModel({}).toJSON();
  try {
    const { searchParams } = action.payload;
    searchResult = yield call(searchAccountTickets, searchParams);

    yield put(searchAccountProductTypesCompleted(searchResult));
  } catch (error) {
    yield put(searchAccountProductTypesCompleted(searchResult));
  }
}

export function* watchSearchAccountProductTypesRequest() {
  yield takeLatest(SEARCH_ACCOUNT_PRODUCT_TYPES, handleSearchAccountProductTypes);
}

export default [
  watchPrepareData(),
  watchSearchOpponentAccounts(),
  watchSearchAccountProductTypesRequest(),
  watchPublishChangesEvent()
];
