import ErrorDTO from '../dto/ErrorDTO';
import axios from '../api/axios';
import axiosExt from 'axios';
import {
  GET_TICKETS_BY_EVENT_ID,
  VENUE_SEATS_URI,
  GET_EVENT_SEATS_BY_STATUS,
  RESERVED_SEAT_STATUS,
  RESERVED_SEAT_BLOCK,
  RESERVED_SEAT_UNBLOCK,
  TICKETS_PATCH_BOOKED_SEAT,
  TICKETS_DELETE_BOOKED_SEAT,
  ORDERS_COMP_TICKETS,
  UPDATE_PURCHASED_TICKET,
  GET_SEASON_SEATS_BY_STATUS,
  GET_TICKETS_BY_SEASON_ID,
  SEASON_RESERVED_SEAT_STATUS,
  GET_EVENT_UNAVAILABLE_SEATS,
  GET_SEASON_UNAVAILABLE_SEATS
} from '../api/api';
import SeatInfo from '../model/SeatInfo';
import { convertDateTimeZone, UPDATED_AFTER_FORMAT, UPDATE_AFTER_FORMAT_TIMEZONE } from '../../utils/dateUtils';
import ReservedSeatingDTO from '../dto/ReservedSeatingDTO';

const getTicketsByEventId = (id, updatedAt = null, page = null) => {
  let uri = `${GET_TICKETS_BY_EVENT_ID.replace('{id}', id)}`;
  uri += `?size=1000`;

  if (page != null) uri += `&page=${page}`;

  if (updatedAt != null)
    uri += `&onlyReservedSeats=true&updatedAfter=${convertDateTimeZone({
      date: updatedAt,
      format: UPDATE_AFTER_FORMAT_TIMEZONE
    })}`;

  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      const reservedTickets = [];
      response.content.map(ticket => {
        if (ticket.seatInfo != null && ticket.seatId != null && ticket.refundId == null)
          reservedTickets.push(new SeatInfo({ ...ticket, seatStatus: 'BOOKED' }));
      });

      const reservedReturn = {
        number: response.number,
        totalPages: response.totalPages,
        reservedTickets
      };

      return reservedReturn;
    })
    .catch(e => new ErrorDTO(e));
};

const getTicketsBySeasonId = (id, updatedAt = null, page = null) => {
  let uri = `${GET_TICKETS_BY_SEASON_ID.replace('{id}', id)}?size=1000&allSeasonTickets=true&onlyReservedSeats=true`;

  if (page != null) uri += `&page=${page}`;

  if (updatedAt != null)
    uri += `&updatedAfter=${convertDateTimeZone({
      date: updatedAt,
      format: UPDATE_AFTER_FORMAT_TIMEZONE
    })}`;

  return axiosExt
    .get(uri)
    .then(({ data }) => {
      const error = ErrorDTO.convertToError(data);
      if (error !== null) {
        return error;
      }
      const reservedTickets = [];

      data.content.map(ticket => {
        if (ticket.seatInfo != null && ticket.seatId != null)
          reservedTickets.push(new SeatInfo({ ...ticket, seatStatus: 'BOOKED' }));
      });

      const reservedReturn = {
        number: data.number,
        totalPages: data.totalPages,
        reservedTickets
      };

      return reservedReturn;
    })
    .catch(e => new ErrorDTO(e));
};

const getEventSeatsByStatus = (eventId, seatStatus, updatedAt = null, decryptFields = true) => {
  let uri = `${GET_EVENT_SEATS_BY_STATUS.replace('{eventId}', eventId)}`;

  if (seatStatus != null) {
    seatStatus.forEach(status => (uri += `&reservationState=${status}`));
  }

  if (updatedAt != null) {
    uri += `&lastUpdatedAfter=${convertDateTimeZone({ date: updatedAt, format: UPDATED_AFTER_FORMAT })}`;
  }

  if (decryptFields === false) {
    uri += `&decryptFields=false`;
  }

  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }

      const seatStatusDictionary = {};
      response.forEach(seat => (seatStatusDictionary[seat.seatsIoLabel] = seat));

      return seatStatusDictionary;
    })
    .catch(e => new ErrorDTO(e));
};

const getSeasonSeatsByStatus = (seasonId, seatStatus, updatedAt = null) => {
  let uri = `${GET_SEASON_SEATS_BY_STATUS.replace('{seasonId}', seasonId)}`;

  if (seatStatus != null) {
    seatStatus.forEach(status => (uri += `&reservationState=${status}`));
  }

  if (updatedAt != null) {
    uri += `&lastUpdatedAfter=${convertDateTimeZone({ date: updatedAt, format: UPDATED_AFTER_FORMAT })}`;
  }

  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }

      const seatStatusDictionary = {};
      response.forEach(seat => (seatStatusDictionary[seat.seatsIoLabel] = seat));

      return seatStatusDictionary;
    })
    .catch(e => new ErrorDTO(e));
};

const getEventSeatStatusByLabel = (eventId, seatsIoLabel) => {
  const uri = `${GET_EVENT_SEATS_BY_STATUS.replace('{eventId}', eventId)}&seatsIoLabel=${seatsIoLabel}`;

  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }

      return response;
    })
    .catch(e => new ErrorDTO(e));
};

const getSeasonSeatStatusByLabel = (seasonId, seatsIoLabel) => {
  const uri = `${GET_SEASON_SEATS_BY_STATUS.replace('{seasonId}', seasonId)}&seatsIoLabel=${seatsIoLabel}`;

  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }

      return response;
    })
    .catch(e => new ErrorDTO(e));
};

const getVenueSeatsInformation = (id = '') => {
  const uri = `${VENUE_SEATS_URI.replace('{id}', id)}`;
  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response.map(seat => new SeatInfo({ seatInfo: seat }));
    })
    .catch(e => new ErrorDTO(e));
};

const getEventUnavailableSeats = eventId => {
  const uri = `${GET_EVENT_UNAVAILABLE_SEATS.replace('{eventId}', eventId)}`;
  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response;
    })
    .catch(e => new ErrorDTO(e));
};

const getSeasonUnavailableSeats = seasonId => {
  const uri = `${GET_SEASON_UNAVAILABLE_SEATS.replace('{seasonId}', seasonId)}`;
  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response;
    })
    .catch(e => new ErrorDTO(e));
};

const setReservedSeatStatus = ({ seatsIoLabel, seatStatus, ticketId, reservationId, eventId }) => {
  const uri = `${RESERVED_SEAT_STATUS}`;
  const params = {
    seatsIoLabel,
    reservationState: seatStatus
  };

  if (eventId !== null) params.eventId = eventId;
  else params.seasonId = seasonId;

  if (ticketId != null) params.ticketId = ticketId;

  if (reservationId != null) params.id = reservationId;

  return axios.huddleAxiosInstanceProxy
    .post(uri, params)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new ReservedSeatingDTO(response);
    })
    .catch(e => new ErrorDTO(e));
};

const setSeasonReservedSeatStatus = ({ seatsIoLabel, seatStatus, ticketId, reservationId, seasonId }) => {
  const uri = `${SEASON_RESERVED_SEAT_STATUS}`;
  const params = {
    seatsIoLabel,
    reservationState: seatStatus,
    seasonId
  };

  if (ticketId != null) params.ticketId = ticketId;

  if (reservationId != null) params.id = reservationId;

  return axios.huddleAxiosInstanceProxy
    .post(uri, params)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new ReservedSeatingDTO(response);
    })
    .catch(e => new ErrorDTO(e));
};

const bookCompSeats = order => {
  const uri = `${ORDERS_COMP_TICKETS}`;
  return axios.huddleAxiosInstanceProxy
    .post(uri, order, axios.huddleAxiosInstanceProxy.msConfig)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response;
    })
    .catch(e => new ErrorDTO(e));
};

const moveBookedSeat = (ticketId, seatsIoLabel, productId) => {
  const uri = `${TICKETS_PATCH_BOOKED_SEAT}`.replace('{ticketId}', ticketId);
  const params = { seatsIoLabel, ticketId: productId };
  return axios.huddleAxiosInstanceProxy
    .patch(uri, params)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response;
    })
    .catch(e => new ErrorDTO(e));
};

const unbookSeat = ticketId => {
  const uri = `${TICKETS_DELETE_BOOKED_SEAT}`.replace('{ticketId}', ticketId);
  return axios.huddleAxiosInstanceProxy
    .delete(uri, {})
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response;
    })
    .catch(e => new ErrorDTO(e));
};

const blockReservedSeat = (eventId, seasonId, seatsIoLabel) => {
  const uri = `${RESERVED_SEAT_BLOCK}`;
  return axios.huddleAxiosInstanceProxy
    .post(uri, { eventId, seasonId, seatsIoLabel })
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }

      return response;
    })
    .catch(e => new ErrorDTO(e));
};

const unblockReservedSeat = (eventId, seasonId, seatsIoLabel) => {
  const uri = `${RESERVED_SEAT_UNBLOCK}`;
  return axios.huddleAxiosInstanceProxy
    .post(uri, { eventId, seasonId, seatsIoLabel })
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response;
    })
    .catch(e => new ErrorDTO(e));
};

const orderCompTickets = order => {
  const uri = `${ORDERS_COMP_TICKETS}`;
  return axios.huddleAxiosInstanceProxy
    .post(uri, { body: order })
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }

      return response;
    })
    .catch(e => new ErrorDTO(e));
};

const updateTicketNote = (notes, accessToken) => {
  const uri = `${UPDATE_PURCHASED_TICKET}`.replace('{accessToken}', accessToken);

  return axios.huddleAxiosInstanceProxy
    .put(uri, { notes })
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response;
    })
    .catch(e => new ErrorDTO(e));
};

export default {
  getTicketsByEventId,
  getTicketsBySeasonId,
  getEventSeatsByStatus,
  getSeasonSeatsByStatus,
  getEventSeatStatusByLabel,
  getSeasonSeatStatusByLabel,
  getVenueSeatsInformation,
  getEventUnavailableSeats,
  getSeasonUnavailableSeats,
  blockReservedSeat,
  unblockReservedSeat,
  orderCompTickets,
  setReservedSeatStatus,
  setSeasonReservedSeatStatus,
  bookCompSeats,
  moveBookedSeat,
  unbookSeat,
  updateTicketNote
};
