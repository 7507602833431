import { FinancialRepository } from './financial.repository';
import {
  transformPaymentRow,
  formatSearchParams,
  formatPaymentEventQuery,
  transformToEventPaymentHistoryRow,
  transformToSeasonPaymentHistoryRow,
  formatPaymentSeasonQuery,
  transformPastProjectedPayments
} from './financial.utils';

import type {
  PaymentHistoryDTO,
  EventPaymentHistoryDTO,
  SeasonPaymentHistoryDTO,
  PaymentSearchDTO,
  PaymentEventQuery,
  PaymentSeasonQuery,
  StatementAuditDownloadQuery,
  RecentPaymentsDTO,
  PastProjectedPaymentsDTO,
  PastProjectedPaymentsFilter,
  PastProjectedPaymentsCarbonData
} from './financial.model';
import type { AxiosError, AxiosResponse } from 'axios';
import {
  GET_PAST_PROJECTED_PAYMENT_STATEMENTS,
  GET_RECENT_PAYMENT_STATEMENTS,
  GET_STATEMENT_AUDIT_CONSOLIDATED_CSV_ZIP,
  GET_STATEMENT_AUDIT_ZIP
} from './financial.endpoints';
import { getQueryString } from '@gofan/utils';
import axios from 'axios';

type SortBy = {
  header?: string;
  sortDirection?: 'ASC' | 'DESC' | 'NONE';
};

class FinancialService {
  static getPaymentStatementV1 = (searchParams: PaymentSearchParams) =>
    FinancialRepository.getPaymentStatement(formatSearchParams(searchParams.body)).then((resp: PaymentHistoryDTO[]) => {
      const response = transformPaymentRow(resp);
      return response;
    });

  static downloadPaymentStatementById = (id: string) => FinancialRepository.downloadPaymentStatementById(id);

  static getPaymentEvents = (searchParams: EventSearchParams) =>
    FinancialRepository.getPaymentEvents(formatPaymentEventQuery(searchParams.body)).then(
      (resp: EventPaymentHistoryDTO[]) => transformToEventPaymentHistoryRow(resp)
    );

  static downloadPaymentStatementByIds = (ids: string[]) =>
    FinancialRepository.downloadPaymentStatementByIds(ids.join(','));

  static downloadPaymentEventsById = (id: string) => FinancialRepository.downloadPaymentEventsById(id);

  static getPaymentSeasons = (searchParams: SeasonSearchParams) =>
    FinancialRepository.getPaymentSeasons(formatPaymentSeasonQuery(searchParams.body)).then(
      (resp: SeasonPaymentHistoryDTO[]) => transformToSeasonPaymentHistoryRow(resp)
    );

  static downloadPaymentSeasonsById = (id: string) => FinancialRepository.downloadPaymentSeasonsById(id);

  static getStatementPdfBase64 = (uuid: string) => FinancialRepository.getStatementPdfBase64(uuid);

  static downloadPaymentStatementAuditZip = (params: StatementAuditDownloadQuery): Promise<Blob> =>
    axios
      .get(`${GET_STATEMENT_AUDIT_ZIP}${getQueryString({ ...params })}`, {
        headers: {
          Accept: 'application/zip'
        },
        responseType: 'blob'
      })
      .then((resp: AxiosResponse<Blob>) => resp.data)
      .catch(async (error: AxiosError) => {
        // error message comes through as a blob
        // we need to convert it to json to pass the server error message to our component
        try {
          const errorMessage = await error.response?.data.text().then(text => JSON.parse(text));
          return Promise.reject(errorMessage);
        } catch {
          return Promise.reject(
            new Error('An Unknown error occurred while downloading the audit data, please try again later.')
          );
        }
      });

  static downloadPaymentStatementConsolidatedCsv = (params: StatementAuditDownloadQuery): Promise<Blob> =>
    axios
      .get(`${GET_STATEMENT_AUDIT_CONSOLIDATED_CSV_ZIP}${getQueryString({ ...params })}`, {
        headers: {
          Accept: 'application/zip'
        },
        responseType: 'blob'
      })
      .then((resp: AxiosResponse<Blob>) => resp.data)
      .catch(async (error: AxiosError) => {
        // error message comes through as a blob
        // we need to convert it to json to pass the server error message to our component
        try {
          const errorMessage = await error.response?.data.text().then(text => JSON.parse(text));
          return Promise.reject(errorMessage);
        } catch {
          return Promise.reject(
            new Error(
              'An Unknown error occurred while downloading the consolidated audit csv file, please try again later.'
            )
          );
        }
      });

  static getRecentPayments = (schoolId: string): Promise<RecentPaymentsDTO[]> =>
    axios
      .get<RecentPaymentsDTO[]>(`${GET_RECENT_PAYMENT_STATEMENTS}?school_id=${schoolId}`)
      .then((resp: AxiosResponse<RecentPaymentsDTO[]>) => resp.data)
      .catch(async (error: AxiosError) => Promise.reject(error));

  static getPastProjectedPayments = (
    schoolId: string,
    filterValue: PastProjectedPaymentsFilter
  ): Promise<PastProjectedPaymentsCarbonData> =>
    axios
      .get<PastProjectedPaymentsDTO[]>(
        `${GET_PAST_PROJECTED_PAYMENT_STATEMENTS}?school_id=${schoolId}&filter_type=${filterValue}`
      )
      .then((resp: AxiosResponse<PastProjectedPaymentsDTO[]>) => transformPastProjectedPayments(resp.data))
      .catch(async (error: AxiosError) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          const errorMessage = data.error || data.Message || data.message || 'Unknown error occurred';
          return Promise.reject(new Error(`Server Error: ${errorMessage}`));
        }
        // Handle errors without a response
        if (error.request) {
          return Promise.reject(new Error('No response received from the server.'));
        }
        // Handle setup or other errors
        return Promise.reject(new Error(`Error setting up the request: ${error.message}`));
      });
}

export type PaymentSearchParams = {
  body: PaymentSearchDTO;
  sortBy: SortBy;
  page: number;
  pageSize: number;
};

export type EventSearchParams = {
  body: PaymentEventQuery;
  sortBy: SortBy;
  page: number;
  pageSize: number;
};

export type SeasonSearchParams = {
  body: PaymentSeasonQuery;
  sortBy: SortBy;
  page: number;
  pageSize: number;
};

export { FinancialService };
