import camelCase from 'lodash/camelCase';
import isNil from 'lodash/isNil';

// recursively convert object props from snake case -> camel case
export const convertToCamelProps = (obj: any) => {
  if (Array.isArray(obj)) {
    return obj.map(v => convertToCamelProps(v));
  }
  if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: convertToCamelProps(obj[key])
      }),
      {}
    );
  }
  return obj;
};

// helper util to remove empty properties from an object
// removes undefined, null and empty strings
export const removeEmptyObjProperties = (obj: any) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => !isNil(v) && v !== ''));

export const getQueryString = (params: any) => {
  const qs: string[] = [];
  Object.keys(params).forEach(param => {
    let vals = params[param];

    if (vals) {
      if (!Array.isArray(vals)) {
        vals = [vals];
      }

      vals.forEach((val: any) => {
        qs.push(`${param}=${encodeURIComponent(val)}`);
      });
    }
  });
  if (qs.length > 0) return `?${qs.join('&')}`;
  return '';
};

// the above func will remove properties with a false value, this allows you to keep that value
export const getQueryStringWithBooleans = (params: any) => {
  const qs: string[] = [];
  Object.keys(params).forEach(param => {
    let vals = params[param];

    if (!isNil(vals)) {
      if (!Array.isArray(vals)) {
        vals = [vals];
      }

      vals.forEach((val: any) => {
        qs.push(`${param}=${encodeURIComponent(val)}`);
      });
    }
  });
  if (qs.length > 0) return `?${qs.join('&')}`;
  return '';
};

export const objectToQueryString = <T extends Record<string, any>>(params: T): string => {
  const queryString = Object.entries(params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  return queryString;
};
