// @flow
import { css } from 'styled-components';

export default {
  wrapper: css`
    position: absolute;
    margin: 0 auto;
    border: 1px solid #cccccc;
    width: ${({ width }) => width}px;
    top: auto;
    bottom: ${({ direction }) => (direction === 'top' ? '100%' : 'auto')};
    z-index: 98;
  `,
  searchResults: css`
    max-height: 300px;
    overflow-y: auto;
  `,
  noResultWrapper: css`
    padding: 15px;
    font-size: 14px;
    font-family: SourceSansPro-Semibold;
    background-color: #ffffff;
  `,
  noResultTitle: css`
    color: #000000;
  `,
  noResultSubTitle: css`
    color: #999999;
  `,
  textWrapper: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'IBM Plex Sans';
  `,
  resultGroupTitle: css`
    padding: 16px;
    font-size: 16px;
    font-weight: 590;
    color: #323232;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    text-align: left;
    background-color: #ffffff;
    border-bottom: 1px solid #cccccc;
  `
};
