import { hasTicketSold } from '../services/EventService';

class SeatInfo {
  constructor({
    accessToken,
    receiptEmail,
    userFirstName,
    userLastName,
    createdAt,
    redeemedAt,
    seatId,
    seatInfo,
    chargeTotalAmount,
    fee,
    id,
    name,
    seatReservationId,
    productId,
    seatStatus,
    eventId,
    seasonId,
    notes
  }) {
    this.raw = {
      accessToken,
      seatReservationId,
      eventId,
      seasonId,
      email: receiptEmail,
      firstName: userFirstName,
      lastName: userLastName,
      bookedDate: createdAt,
      bookedAt: createdAt,
      pricePaid: chargeTotalAmount,
      seatStatus,
      redeemed: redeemedAt,
      category: seatInfo && seatInfo.category,
      section: seatInfo && seatInfo.section,
      row: seatInfo && seatInfo.row,
      seat: seatInfo && seatInfo.seat && seatInfo.seat.label,
      seatsIoLabel: seatInfo && seatInfo.seat && seatInfo.seat.seatsIoLabel,
      id: seatInfo && seatInfo.seat && seatInfo.seat.seatsIoLabel,
      ticketName: name,
      seatId,
      productId,
      ticketId: id,
      notes
    };
  }

  get accessToken() {
    return this.raw.accessToken;
  }

  get seatReservationId() {
    return this.raw.seatReservationId ? this.raw.seatReservationId : null;
  }

  get eventId() {
    return this.raw.eventId ? this.raw.eventId : null;
  }

  get seasonId() {
    return this.raw.seasonId ? this.raw.seasonId : null;
  }

  get email() {
    return this.raw.email ? this.raw.email : '';
  }

  get firstName() {
    return this.raw.firstName ? this.raw.firstName : '';
  }

  get lastName() {
    return this.raw.lastName ? this.raw.lastName : '';
  }

  get bookedDate() {
    return this.raw.bookedDate ? this.raw.bookedDate : '';
  }

  get bookedAt() {
    return this.raw.bookedAt ? this.raw.bookedAt : '';
  }

  get pricePaid() {
    return this.raw.pricePaid ? this.raw.pricePaid : '';
  }

  get seatStatus() {
    return this.raw.seatStatus ? this.raw.seatStatus : '';
  }

  get redeemed() {
    return this.raw.redeemed != null;
  }

  get category() {
    return this.raw.category;
  }

  get section() {
    return this.raw.section;
  }

  get row() {
    return this.raw.row;
  }

  get seat() {
    return this.raw.seat;
  }

  get seatsIoLabel() {
    return this.raw.seatsIoLabel;
  }

  get ticketName() {
    return this.raw.ticketName ? this.raw.ticketName : '';
  }

  get seatId() {
    return this.raw.seatId;
  }

  get productId() {
    return this.raw.productId;
  }

  get notes() {
    return this.raw.notes;
  }

  get ticketId () {
    return this.raw.ticketId;
  }

  set email(email) {
    this.raw.userEmail = email;
  }

  set seatReservationId(seatReservationId) {
    this.raw.seatReservationId = seatReservationId;
  }

  set firstName(firstName) {
    this.raw.userFirstName = firstName;
  }

  set lastName(lastName) {
    this.raw.userLastName = lastName;
  }

  set bookedDate(bookedDate) {
    this.raw.createdAt = bookedDate;
  }

  set bookedAt(bookedAt) {
    this.raw.createdAt = bookedAt;
  }

  set pricePaid(pricePaid) {
    this.raw.chargeTotalAmout = pricePaid;
  }

  set seatStatus(seatStatus) {
    this.raw.seatStatus = seatStatus;
  }

  set redeemed(redeemed) {
    this.raw.redeemedAt = redeemed;
  }

  set ticketName(ticketName) {
    this.raw.name = ticketName;
  }

  set productId(productId) {
    this.raw.productId = productId;
  }

  set notes(notes) {
    this.raw.notes = notes;
  }

  clearSeat() {
    this.raw.accessToken = null;
    this.raw.email = null;
    this.raw.seatReservationId = null;
    this.raw.firstName = null;
    this.raw.lastName = null;
    this.raw.bookedDate = null;
    this.raw.bookedAt = null;
    this.raw.pricePaid = null;
    this.raw.seatStatus = 'AVAILABLE';
    this.raw.redeemed = null;
    this.raw.notes = null;
    this.raw.ticketId = null;
    this.raw.ticketName = null
  }

  book({ email, seatReservationId, firstName, lastName, createdAt, ticketId, ticketName }) {
    this.raw.email = email;
    this.raw.seatReservationId = seatReservationId;
    this.raw.firstName = firstName;
    this.raw.lastName = lastName;
    this.raw.seatStatus = 'BOOKED';
    this.raw.bookedAt = createdAt;
    this.raw.bookedDate = createdAt;
    this.raw.ticketId = ticketId;
    this.raw.ticketName = ticketName;
  }

  updateSeat({
      accessToken,
      seatReservationId,
      eventId,
      seasonId,
      email,
      firstName,
      lastName,
      bookedDate,
      bookedAt,
      pricePaid,
      seatStatus,
      redeemed,
      category,
      section,
      row,
      seat,
      seatsIoLabel,
      id,
      ticketName,
      seatId,
      productId,
      ticketId,
      notes
  }) {
    this.raw = {
      accessToken,
      seatReservationId,
      eventId,
      seasonId,
      email,
      firstName,
      lastName,
      bookedDate,
      bookedAt,
      pricePaid,
      seatStatus,
      redeemed,
      category,
      section,
      row,
      seat,
      seatsIoLabel,
      id,
      ticketName,
      seatId,
      productId,
      ticketId,
      notes
    };
  }

  toJSON() {
    return {
      accessToken: this.accessToken,
      eventId: this.eventId,
      seasonId: this.seasonId,
      id: this.seatsIoLabel,
      seatReservationId: this.seatReservationId,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      bookedDate: this.bookedDate,
      bookedAt: this.bookedAt,
      pricePaid: this.pricePaid,
      seatStatus: this.seatStatus,
      redeemed: this.redeemed,
      category: this.category,
      section: this.section,
      row: this.row,
      seat: this.seat,
      seatsIoLabel: this.seatsIoLabel,
      ticketName: this.ticketName,
      ticketId: this.ticketId,
      seatId: this.seatId,
      productId: this.productId,
      notes: this.notes,
      clearSeat: this.clearSeat,
      book: this.book,
      updateSeat: this.updateSeat
    };
  }
}

export default SeatInfo;
