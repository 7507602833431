import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import Account from '../../api/model/Account';
import {
  SEARCH_ACCOUNTS,
  GET_RATE_BY_ID,
  FETCH_PRODUCT_TYPES,
  CREATE_RATE,
  INITIAL_DATA,
  UPDATE_RATE
} from './actionTypes';
import * as actionCreator from './actions.ts';
import * as AccountService from '../../api/services/AccountService';
import * as RateService from '../../api/services/RateService';
import Rate from '../../api/model/Rate';
import RateDTO from '../../api/dto/RateDTO';
import ProductService from '../../api/services/ProductsService';
import ProductType from '../../api/model/ProductType';
import ErrorDTO from '../../api/dto/ErrorDTO';
import PaginationDTO from '../../api/dto/PaginationDTO';
import PageRequest from '../../api/model/request/PageRequest';
import SortableRequest from '../../api/model/request/SortableRequest';
import RateRequest from '../../api/model/request/RateRequest';
import SearchAccountsRequest from '../../api/model/request/SearchAccountsRequest';
import { generateFailContent, generateSuccessContent } from '../../utils/alertUtils';
import { addMessage } from '../../root/actions';
import { RATE_STRINGS, SEARCH_PARAMS } from './constants';
import * as rateActionCreator from '../Rates/actions.ts';
import { UNEXPECTED_ERROR } from '../../api/api/constants';

export function* searchAccounts(action) {
  try {
    const { keyword } = action.payload;
    const searchRequest = new SearchAccountsRequest({
      keyword
    });
    const pageRequest = new PageRequest({
      pageSize: SEARCH_PARAMS.pageSize,
      pageIndex: SEARCH_PARAMS.pageIndex
    });
    const sortableRequest = new SortableRequest({
      sortBy: SEARCH_PARAMS.sortBy
    });
    const response = yield call(AccountService.fetchAccounts, searchRequest, pageRequest, sortableRequest);

    if (response instanceof PaginationDTO) {
      const { data } = response.toJSON();
      yield put(actionCreator.searchAccountsCompleted(data.map(account => new Account(account.toJSON()).toJSON())));
    } else {
      yield put(actionCreator.searchAccountsCompleted([]));
    }
  } catch (error) {
    const message = generateFailContent(error.message);
    yield put(addMessage(message));
    yield put(actionCreator.searchAccountsCompleted([]));
  }
}

export function* getRateById(action) {
  const { id, expands, history } = action.payload;
  try {
    let response = yield call(ProductService.fetchAllProductTypes);
    const products = [];
    if (response instanceof Array) {
      products.push([...response.map(product => new ProductType(product).toJSON())]);
    } else {
      const errorMessage = response instanceof ErrorDTO && response.data ? response.data.message : UNEXPECTED_ERROR;
      RateService.navigateToRatesPage(history);
      yield put(addMessage(generateFailContent(errorMessage)));
      return;
    }

    response = yield call(RateService.fetchRateById, id, expands);
    if (response instanceof RateDTO) {
      yield put(actionCreator.fetchRateByIdCompleted(new Rate(response.toJSON()).toJSON()));
      yield put(actionCreator.fetchProductTypesCompleted(products));
    } else {
      const errorMessage = response instanceof ErrorDTO && response.data ? response.data.message : UNEXPECTED_ERROR;
      RateService.navigateToRatesPage(history);
      yield put(addMessage(generateFailContent(errorMessage)));
    }
  } catch (error) {
    const message = generateFailContent(error.message);
    RateService.navigateToRatesPage(history);
    yield put(addMessage(message));
    yield put(actionCreator.fetchRateByIdCompleted({}));
  }
}

export function* fetchProductTypes() {
  try {
    const response = yield call(ProductService.fetchAllProductTypes);
    if (response instanceof Array) {
      yield put(actionCreator.fetchProductTypesCompleted(response.map(product => new ProductType(product).toJSON())));
    } else {
      yield put(actionCreator.fetchProductTypesCompleted([]));
    }
  } catch (e) {
    yield put(actionCreator.fetchProductTypesCompleted([]));
  }
}

export function* updateRate(action) {
  const { rate, history } = action.payload;
  const { productTypeNames } = rate;
  try {
    const response = yield call(RateService.updateRate, new RateRequest(rate));

    if (response instanceof RateDTO) {
      const rateRecently = new Rate(response.toJSON());
      const message = generateSuccessContent(`${rateRecently.label}${RATE_STRINGS.MESSAGES.UPDATE_SUCCESS}`);
      yield put(
        rateActionCreator.insertRateRecently({
          ...rateRecently.toJSON(),
          productTypeNames
        })
      );
      RateService.navigateToRatesPage(history);
      yield put(addMessage(message));
      yield put(actionCreator.updateRateCompleted(rateRecently.toJSON()));
    }
    if (response instanceof ErrorDTO) {
      const message = generateFailContent(response.getErrorMessage());
      yield put(addMessage(message));
      yield put(actionCreator.updateRateCompleted({}));
    }
  } catch (error) {
    const message = generateFailContent(error.message);
    yield put(addMessage(message));
    yield put(actionCreator.updateRateCompleted({}));
  }
}

export function* createRate(action) {
  try {
    const { rate, history } = action.payload;
    const { productTypeNames } = rate;
    const response = yield call(RateService.createRate, new RateRequest(rate));
    if (response instanceof RateDTO) {
      const rateRecently = new Rate(response.toJSON());
      const message = generateSuccessContent(`${rateRecently.label}${RATE_STRINGS.MESSAGES.ADD_SUCCESS}`);
      yield put(
        rateActionCreator.insertRateRecently({
          ...rateRecently.toJSON(),
          productTypeNames
        })
      );
      RateService.navigateToRatesPage(history);
      yield put(addMessage(message));
      yield put(actionCreator.createRateCompleted(rateRecently.toJSON()));
    }
    if (response instanceof ErrorDTO) {
      const message = generateFailContent(response.getErrorMessage());
      yield put(addMessage(message));
      yield put(actionCreator.createRateCompleted({}));
    }
  } catch (error) {
    const message = generateFailContent(error.message);
    yield put(addMessage(message));
    yield put(actionCreator.createRateCompleted({}));
  }
}

export function* initData(action) {
  const { id, expands, history, editMode } = action.payload;
  try {
    let response = yield call(ProductService.fetchAllProductTypes);
    const products = [];
    if (response instanceof Array) {
      products.push(...response.map(product => new ProductType(product).toJSON()));
    } else {
      const errorMessage = response instanceof ErrorDTO && response.data ? response.data.message : UNEXPECTED_ERROR;
      RateService.navigateToRatesPage(history);
      yield put(addMessage(generateFailContent(errorMessage)));
      return;
    }

    if (!editMode) {
      yield put(actionCreator.initDataCompleted(products, {}));
      return;
    }
    response = yield call(RateService.fetchRateById, id, expands);
    if (response instanceof RateDTO) {
      yield put(actionCreator.initDataCompleted(products, new Rate(response.toJSON()).toJSON()));
    } else {
      const errorMessage = response instanceof ErrorDTO && response.data ? response.data.message : UNEXPECTED_ERROR;
      RateService.navigateToRatesPage(history);
      yield put(addMessage(generateFailContent(errorMessage)));
    }
  } catch (error) {
    yield put(actionCreator.fetchRateByIdCompleted({}));
    const message = generateFailContent(error.message);
    RateService.navigateToRatesPage(history);
    yield put(addMessage(message));
  }
}

export function* searchAccountsSaga() {
  yield takeLatest(SEARCH_ACCOUNTS, searchAccounts);
}

export function* getRateByIdSaga() {
  yield takeEvery(GET_RATE_BY_ID, getRateById);
}

export function* fetchProductTypesSaga() {
  yield takeEvery(FETCH_PRODUCT_TYPES, fetchProductTypes);
}

export function* createRateSaga() {
  yield takeEvery(CREATE_RATE, createRate);
}

export function* updateRateSaga() {
  yield takeEvery(UPDATE_RATE, updateRate);
}

export function* initDataSaga() {
  yield takeEvery(INITIAL_DATA, initData);
}
export default [
  searchAccountsSaga(),
  getRateByIdSaga(),
  fetchProductTypesSaga(),
  createRateSaga(),
  initDataSaga(),
  updateRateSaga()
];
