import { useState, useMemo, useRef } from 'react';
import { MultiSelect } from 'carbon-components-react';

// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import * as sorting from 'carbon-components-react/lib/components/MultiSelect/tools/sorting';
import * as itemToString from 'carbon-components-react/lib/components/MultiSelect/tools/itemToString';
import isNil from 'lodash/isNil';

const MultiSelectWithAllOpt = props => {
  const defaultOption = { id: '*', name: 'All', label: 'All' };
  const [items] = useState([defaultOption, ...props.items]);
  const [selectedItems, setSelectedItems] = useState(() => {
    if (props.items.length === props.initialSelectedItems.length) {
      return [defaultOption, ...props.initialSelectedItems];
    }

    return [...props.initialSelectedItems];
  });
  const selectedItemsNames = useMemo(() => selectedItems.map(item => item.name), [selectedItems]);

  const prevSelectedItemsRef = useRef(selectedItems);
  const allSelectedRef = useRef(props.items.length === props.initialSelectedItems.length);

  return (
    <MultiSelect
      {...props}
      items={items}
      initialSelectedItems={selectedItems}
      onChange={_onChange}
      sortItems={props.sortItems ?? _sortItems}
      id={props.id ?? 'gf-multi-select-with-all'}
    />
  );

  function _onChange(ev) {
    const { selectedItems: newItems } = ev;

    if (!Array.isArray(newItems)) return;

    // Check if the all option is selected
    if (
      !allSelectedRef.current &&
      !prevSelectedItemsRef.current.some(item => item.id === '*') &&
      newItems.some(item => item.id === '*')
    ) {
      const optionNodes = document.querySelectorAll('.bx--list-box__menu-item');
      optionNodes.forEach(node => {
        if (!(Object.values(node.classList) ?? []).includes('bx--list-box__menu-item--active')) {
          node.click();
        }
      });

      setSelectedItems([defaultOption, ...props.items]);
      prevSelectedItemsRef.current = [defaultOption, ...props.items];
      props.onChange({ selectedItems: props.items });

      allSelectedRef.current = true;
      return;
    }

    // Check if the all option is deselected
    if (allSelectedRef.current && !newItems.some(item => item.id === '*')) {
      const node = document.querySelector('.bx--list-box--expanded .bx--list-box__selection--multi');

      if (!isNil(node)) node.click();

      setSelectedItems([]);
      prevSelectedItemsRef.current = [];
      props.onChange({ selectedItems: [] });
      allSelectedRef.current = false;

      return;
    }

    // Check if all items except for the "All" option are selected, if true set the "All" option is selected
    if (allSelectedRef.current === false && newItems.length === props.items.length) {
      setSelectedItems([defaultOption, ...props.items]);
      prevSelectedItemsRef.current = [defaultOption, ...props.items];
      props.onChange({ selectedItems: props.items });
      allSelectedRef.current = true;

      return;
    }

    const newSelectedItems = newItems.filter(item => item.id !== '*');
    setSelectedItems([newSelectedItems]);
    prevSelectedItemsRef.current = [newSelectedItems];
    props.onChange({ selectedItems: newSelectedItems });
  }

  function _sortItems(itemsToSort) {
    return itemsToSort
      ?.sort((a, b) => {
        if (a.name === 'All' || b.name === 'All') return 1;
        return `${a.name}`.localeCompare(`${b.name}`);
      })
      ?.sort((a, b) => {
        if (a.name === 'All' || b.name === 'All') return 0;
        if (selectedItemsNames.includes(a.name) && !selectedItemsNames.includes(b.name)) return -1;
        return 1;
      });
  }
};

MultiSelect.propTypes = {
  /**
   * Specify the direction of the multiselect dropdown. Can be either top or bottom.
   */
  direction: PropTypes.oneOf(['top', 'bottom']),

  /**
   * Disable the control
   */
  disabled: PropTypes.bool,

  /**
   * Additional props passed to Downshift
   */
  downshiftProps: PropTypes.shape({}), // downshift.default.propTypes

  /**
   * Specify a custom `id`
   */
  id: PropTypes.string.isRequired,

  /**
   * Allow users to pass in arbitrary items from their collection that are
   * pre-selected
   */
  initialSelectedItems: PropTypes.array,

  /**
   * Is the current selection invalid?
   */
  invalid: PropTypes.bool,

  /**
   * If invalid, what is the error?
   */
  invalidText: PropTypes.node,

  /**
   * Helper function passed to downshift that allows the library to render a
   * given item to a string label. By default, it extracts the `label` field
   * from a given item to serve as the item label in the list.
   */
  itemToString: PropTypes.func,

  /**
   * Function to render items as custom components instead of strings.
   * Defaults to null and is overridden by a getter
   */
  itemToElement: PropTypes.func,

  /**
   * We try to stay as generic as possible here to allow individuals to pass
   * in a collection of whatever kind of data structure they prefer
   */
  items: PropTypes.array.isRequired,

  /**
   * Generic `label` that will be used as the textual representation of what
   * this field is for
   */
  label: PropTypes.node.isRequired,

  /**
   * `true` to use the light version.
   */
  light: PropTypes.bool,

  /**
   * Specify the locale of the control. Used for the default `compareItems`
   * used for sorting the list of items in the control.
   */
  locale: PropTypes.string,

  /**
   * `onChange` is a utility for this controlled component to communicate to a
   * consuming component what kind of internal state changes are occuring.
   */
  onChange: PropTypes.func,

  /**
   * `onMenuChange` is a utility for this controlled component to communicate to a
   * consuming component that the menu was opend(`true`)/closed(`false`).
   */
  onMenuChange: PropTypes.func,

  /**
   * Initialize the component with an open(`true`)/closed(`false`) menu.
   */
  open: PropTypes.bool,

  /**
   * Specify feedback (mode) of the selection.
   * `top`: selected item jumps to top
   * `fixed`: selected item stays at it's position
   * `top-after-reopen`: selected item jump to top after reopen dropdown
   */
  selectionFeedback: PropTypes.oneOf(['top', 'fixed', 'top-after-reopen']),

  /**
   * Specify the size of the ListBox. Currently supports either `sm`, `lg` or `xl` as an option.
   */
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),

  /**
   * Provide text to be used in a `<label>` element that is tied to the
   * multiselect via ARIA attributes.
   */
  titleText: PropTypes.string,

  /**
   * Callback function for translating ListBoxMenuIcon SVG title
   */
  translateWithId: PropTypes.func,

  /**
   * Specify 'inline' to create an inline multi-select.
   */
  type: PropTypes.oneOf(['default', 'inline']),

  /**
   * Specify title to show title on hover
   */
  useTitleInItem: PropTypes.bool,

  /**
   * Specify whether the control is currently in warning state
   */
  warn: PropTypes.bool,

  /**
   * Provide the text that is displayed when the control is in warning state
   */
  warnText: PropTypes.node
};

MultiSelect.defaultProps = {
  compareItems: sorting.defaultCompareItems,
  disabled: false,
  locale: 'en',
  itemToString: itemToString.defaultItemToString,
  items: [],
  initialSelectedItems: [],
  sortItems: sorting.defaultSortItems,
  type: 'default',
  light: false,
  title: false,
  open: false,
  selectionFeedback: 'top-after-reopen',
  direction: 'bottom'
};

export default MultiSelectWithAllOpt;
