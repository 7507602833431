/* eslint-disable react/require-default-props */
import { useRef, useState, useEffect, useCallback } from 'react';

import { config } from '@gofan/constants';
import schoolIcon from '@assets/images/gofan-icon.png';
import { CANCELLED_REQUEST } from '@app/api/api/constants';
import type { AccountDTO } from '@gofan/api/accounts';
import { AccountService } from '@gofan/api/accounts';
import type { SearchAccount } from '@dashboard/views/sections/school-selection/school-selection.component';
import { searchAccountsMapping } from '@dashboard/views/sections/school-selection/school-selection.component';

import StyledSearch from '@common/carbon-ui/atoms/StyledSearch/components/StyledSearch/StyledSearch';

const SearchSchool = ({
  invalid,
  invalidText,
  searchValue,
  onSelectItem,
  onSearchError,
  onSearchChange
}: {
  invalid?: boolean;
  invalidText?: string;
  searchValue: string;
  onSelectItem?: Function;
  onSearchError?: Function;
  onSearchChange?: Function;
}) => {
  const searchTimer = useRef<any>(null);
  const isUnmounted = useRef<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<SearchAccount[]>([]);

  const handleSearchAccounts = useCallback(
    (value: string) => {
      AccountService.cancelGetAccountsByKeyword();
      clearTimeout(searchTimer.current);
      setSearching(true);
      searchTimer.current = setTimeout(() => {
        if (isUnmounted.current) return;
        AccountService.getAccountsByKeyword({ keyword: value, manualErrorHandling: true })
          .then((res: AccountDTO[]) => {
            if (isUnmounted.current) return;
            setSearchResult(searchAccountsMapping(res));
            setSearching(false);
          })
          .catch((err: any) => {
            if (isUnmounted.current) return;
            if (err.message !== CANCELLED_REQUEST) {
              setSearching(false);
              if (typeof onSearchError === 'function') onSearchError(err);
            }
          });
      }, config.DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST);
    },
    [searchTimer.current]
  );

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  return (
    <StyledSearch
      size='lg'
      light={false}
      searching={searching}
      results={searchResult}
      defaultIcon={schoolIcon}
      value={searchValue}
      invalid={invalid}
      invalidText={invalidText}
      onSelectItem={(school: any) => {
        if (typeof onSelectItem === 'function') onSelectItem(school);
        if (typeof onSearchChange === 'function') onSearchChange(school?.name ?? '');
      }}
      onTextChange={searchText => {
        if (`${searchText}`.trim() === '') {
          setSearchResult([]);
        } else {
          handleSearchAccounts(searchText);
        }
        if (typeof onSearchChange === 'function') onSearchChange(searchText);
      }}
    />
  );
};

export default SearchSchool;
