import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'carbon-components-react';
import styles from './styles';

const Dropdownmenu = styled(Dropdown)`
  ${styles.dropdownmenu};
`;

export default ({ items = [], onChange, titleText, disabled, light, warn, warnText, selectedItem, ...props }) => {
  const [selected, setSelectedItem] = React.useState(selectedItem);

  React.useEffect(() => {
    setSelectedItem(selectedItem);
  }, [selectedItem]);

  return (
    <Dropdownmenu
      ariaLabel='Dropdown'
      id='carbon-dropdown-example'
      titleText={titleText}
      items={items}
      onChange={e => {
        setSelectedItem(e.selectedItem);
        onChange(e.selectedItem);
      }}
      disabled={disabled}
      light={light}
      warn={warn}
      warnText={warnText}
      selectedItem={selected}
      {...props}
    />
  );
};
