import { SCHOOL_CONFIG_QUERY_KEY } from '@gofan/constants';
import type { SchoolConfig } from './school-config.model';

export class EventMonitorConfigService {
  static getEventMonitorConfig = async (accountId): Promise<SchoolConfig | undefined> => {
    const storage = localStorage.getItem(SCHOOL_CONFIG_QUERY_KEY);
    const storageData = storage ? JSON.parse(storage) : {};
    return storageData[accountId];
  };

  static getEventMonitorConfigByAccountIds = async (accountIds: string[]): Promise<SchoolConfig[]> => {
    const configs: SchoolConfig[] = [];
    await new Promise(resolve => {
      setTimeout(() => {
        const storage = localStorage.getItem(SCHOOL_CONFIG_QUERY_KEY);
        const storageData = storage ? JSON.parse(storage) : {};
        accountIds.forEach(accountId => {
          if (storageData[accountId]) {
            configs.push(storageData[accountId]);
          }
        });
        resolve(true);
      }, 500);
    });
    return configs;
  };

  static updateEventMonitorConfig = async (accountId: string, config: SchoolConfig) => {
    await new Promise(resolve => {
      setTimeout(() => {
        const storage = localStorage.getItem(SCHOOL_CONFIG_QUERY_KEY);
        const storageData = storage ? JSON.parse(storage) : {};
        storageData[accountId] = config;
        localStorage.setItem(SCHOOL_CONFIG_QUERY_KEY, JSON.stringify(storageData));
        resolve(true);
      }, 500);
    });
  };
}
