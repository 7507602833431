import { huddleAxiosInstanceProxy } from '../shared/axios';
import type {
  CreateRosterRequestDTO,
  CreateRosterResponseDTO,
  RosterDTO,
  UpdateRosterRequestDTO,
  UpdateRosterResponseDTO
} from './rosters.model';

export const TEAM_INSTANCE_URI = 'teamInstance';

const correctNullValues = (roster: RosterDTO): RosterDTO => ({
  ...roster,
  coachStaff: roster.coachStaff ?? [],
  players: roster.players ?? []
});

export class RostersRepository {
  static getRoster(params: { teamId: string; schoolYearId: number; schoolId?: string }): Promise<RosterDTO[]> {
    return huddleAxiosInstanceProxy
      .get<RosterDTO[]>(TEAM_INSTANCE_URI, {
        ...huddleAxiosInstanceProxy.programConfig,
        params
      })
      .then(rosters => (rosters || []).map(correctNullValues));
  }

  static getRosterById(rosterId: string): Promise<RosterDTO> {
    return huddleAxiosInstanceProxy
      .get<RosterDTO>(`${TEAM_INSTANCE_URI}/${rosterId}`, {
        ...huddleAxiosInstanceProxy.programConfig
      })
      .then(correctNullValues);
  }

  static getRosterByAccountId(accountId: string): Promise<RosterDTO[]> {
    return new Promise(resolve => {
      const roster = localStorage.getItem('roster');
      const rosterData = roster ? JSON.parse(roster) : {};
      const rostersAccount = Object.keys(rosterData).reduce((result, teamId) => {
        if (rosterData[teamId].accountId === accountId) {
          return result.concat({ ...rosterData[teamId], teamId });
        }
        return result;
      }, []);
      resolve(rostersAccount || []);
    });
  }

  static createRoster(
    roster: CreateRosterRequestDTO,
    params?: { schoolId?: string; teamId?: string }
  ): Promise<CreateRosterResponseDTO> {
    return huddleAxiosInstanceProxy.post<CreateRosterResponseDTO, CreateRosterRequestDTO>(TEAM_INSTANCE_URI, roster, {
      ...huddleAxiosInstanceProxy.programConfig,
      params
    });
  }

  static updateRoster(
    roster: UpdateRosterRequestDTO,
    params?: { schoolId?: string; teamId?: string }
  ): Promise<UpdateRosterResponseDTO> {
    return huddleAxiosInstanceProxy.put<UpdateRosterRequestDTO, UpdateRosterResponseDTO>(
      `${TEAM_INSTANCE_URI}/${roster.id}`,
      roster,
      {
        ...huddleAxiosInstanceProxy.programConfig,
        params
      }
    );
  }
}
