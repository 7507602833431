// @flow
import { isEmpty } from 'lodash';

import VenueDTO from './VenueDTO';
import AccountDTO from './AccountDTO';
import EventTypeDTO from './EventTypeDTO';
import LevelDTO from './LevelDTO';
import ExceptionDTO from './ExceptionDTO';
import ProductTypeDTO from './ProductTypeDTO';
import ActivityDTO from './ActivityDTO';

class EmbeddedDTO {
  constructor({
    venue,
    account,
    activity,
    'event-type': eventType,
    'account-host': hostAccount,
    'account-opponent': opponentAccount,
    'event-sales-info': eventSalesInfo,
    exceptions,
    'product-types': productTypes,
    'product-type': productType,
    levels
  }) {
    this.raw = {
      venue,
      eventType,
      exceptions,
      activity,
      account,
      hostAccount,
      opponentAccount,
      levels,
      eventSalesInfo,
      productTypes,
      productType
    };
  }

  get venue() {
    return isEmpty(this.raw.venue) ? null : new VenueDTO(this.raw.venue);
  }

  get eventType() {
    return isEmpty(this.raw.eventType) ? null : new EventTypeDTO(this.raw.eventType);
  }

  get activity() {
    return isEmpty(this.raw.activity) ? null : new ActivityDTO(this.raw.activity);
  }

  get account() {
    return isEmpty(this.raw.account) ? null : new AccountDTO(this.raw.account);
  }

  get hostAccount() {
    return isEmpty(this.raw.hostAccount) ? null : new AccountDTO(this.raw.hostAccount);
  }

  get opponentAccount() {
    return isEmpty(this.raw.opponentAccount) ? null : new AccountDTO(this.raw.opponentAccount);
  }

  get levels() {
    return isEmpty(this.raw.levels) ? null : this.raw.levels.map(level => new LevelDTO(level));
  }

  get eventSalesInfo() {
    return isEmpty(this.raw.eventSalesInfo) ? null : this.raw.eventSalesInfo;
  }

  get exceptions() {
    return isEmpty(this.raw.exceptions) ? null : this.raw.exceptions.map(ex => new ExceptionDTO(ex));
  }

  get productTypes() {
    return isEmpty(this.raw.productTypes) ? null : this.raw.productTypes.map(item => new ProductTypeDTO(item));
  }

  get productType() {
    return isEmpty(this.raw.productType) ? null : new ProductTypeDTO(this.raw.productType);
  }

  toJSON() {
    return {
      venue: this.venue ? this.venue.toJSON() : null,
      eventType: this.eventType ? this.eventType.toJSON() : null,
      activity: this.activity ? this.activity.toJSON() : null,
      account: this.account ? this.account.toJSON() : null,
      hostAccount: this.hostAccount ? this.hostAccount.toJSON() : null,
      opponentAccount: this.opponentAccount ? this.opponentAccount.toJSON() : null,
      levels: this.levels ? this.levels.map(level => level.toJSON()) : null,
      eventSalesInfo: this.eventSalesInfo,
      exceptions: this.exceptions ? this.exceptions.map(ex => ex.toJSON()) : null,
      productTypes: this.productTypes ? this.productTypes.map(item => item.toJSON()) : null,
      productType: this.productType ? this.productType.toJSON() : null
    };
  }
}

export default EmbeddedDTO;
