/* Order of permissions:
Super Administrator
System Finance Admin
System Admin
System User
Limited System User
 */

import { isMainContact, USER_ROLE_ACCOUNT_USER, USER_ROLE_FAN } from '@gofan/api/users';
import { contactPermisson, accountPermissions } from '../helpers';
import { USER_STATE_DISABLED } from '../../../api/model/user.model';

export default {
  view: {
    beta: {
      dashboard: false,
      sideMenu: false
    },
    rate: false,
    level: false,
    tickets: false,
    activity: false,
    products: false,
    fanSupport: false,
    activityTree: false,
    venues: false,
    tournaments: ({ tournaments }) =>
      tournaments.accounts.some(account => ['State Association', 'Section'].includes(account.gofanSchoolType)),
    account: {
      contact: {
        removable: ({ currentUser, account }) => isMainContact(account?.accountId, currentUser),
        permissions: {
          report: contactPermisson.checkAccountEditorRole,
          boxoffice: contactPermisson.checkAccountEditorRole,
          insider: false,
          financial: {
            isCurrentUserAsEditor: ({ currentUser, account }) =>
              contactPermisson.checkAccountEditorRole({ currentUser, account }),
            checkUserRole: ({ account: { user } }) => {
              if (!user || user.role === USER_ROLE_FAN) {
                return true;
              }
              return [USER_ROLE_ACCOUNT_USER].includes(user.role);
            }
          },
          editor: {
            isCurrentUserAsEditor: ({ currentUser, account }) =>
              contactPermisson.checkAccountEditorRole({
                currentUser,
                account
              }),
            checkUserRole: ({ account: { user } }) => {
              if (!user || user.role === USER_ROLE_FAN) {
                return true;
              }
              return [USER_ROLE_ACCOUNT_USER].includes(user.role);
            }
          }
        }
      }
    }
  },
  create: {
    account: {
      contact: contactPermisson.checkAccountEditorRole,
      tickets: contactPermisson.checkAccountEditorRole
    },
    activity: false,
    level: false,
    rate: false,
    products: false
  },
  edit: {
    user: {
      editable: {
        checkUserRole: ({ currentUser, user }) =>
          currentUser.email === user.email ||
          !['SUPER_ADMIN', 'SYSTEM_FINANCE_ADMIN', 'SYSTEM_ADMIN', 'SYSTEM_USER', 'LIMITED_SYSTEM_USER'].includes(
            user.role
          )
      },
      communicationReporting: false
    },
    account: {
      tickets: contactPermisson.checkAccountEditorRole,
      accountItem: {
        editable: false
      },
      contact: {
        account_role: {
          // if there are multiple functions inside of this, ALL must evaluate to true for this to be true
          // if you can't edit a user, you cant do this either
          checkUserRole: ({ account }) =>
            !['SUPER_ADMIN', 'SYSTEM_FINANCE_ADMIN', 'SYSTEM_ADMIN', 'SYSTEM_USER', 'LIMITED_SYSTEM_USER'].includes(
              account.user.role
            ),
          makeSureActive: ({ account }) => {
            if (account.account.inactive) {
              return false;
            }
            return true;
          },
          makeSureAccountRolesFit: ({ currentUser, account }) => {
            // i need to check the users account contexts for this account
            const { type } = account;
            if (type === 'FINANCE') {
              const myExistingAccountData = currentUser.userAccountContexts.find(x => x.accountId === account.id);
              if (!myExistingAccountData) {
                return false; // you aren't even associated with this account
              }
              // then check my accountRoles for ADMIN or FINANCE
              if (
                myExistingAccountData.accountRoles.includes('ADMIN') ||
                myExistingAccountData.accountRoles.includes('FINANCE')
              ) {
                return true;
              } // else
              return false; // you don't have the right account roles
            }
            // theres nothing stoping you
            return true;
          },
          canAssignAdminPermission: contactPermisson.canAssignAdmin
        },
        editable: {
          canEditActiveStatus: ({ currentUser, account }) => currentUser.email !== account.email,
          canEdit: data => {
            const { contactUserStatus } = data.account || {};

            if (`${contactUserStatus}`.toUpperCase() !== USER_STATE_DISABLED) {
              return contactPermisson.checkAccountEditorRole(data);
            }

            return false;
          }
        }
      },
      goFanPage: {
        editable: {
          canEditCustomName: accountPermissions.checkAccountUserAdminRole,
          canEditPageStatus: accountPermissions.checkAccountUserAdminRole,
          canEditSearchable: accountPermissions.checkAccountUserAdminRole
        }
      },
      gofanPage: {
        editable: contactPermisson.checkAccountEditorRole
      }
    },
    fanSupport: {
      refundable: false
    },
    activity: {
      editable: false
    },
    level: false,
    rate: false,
    products: false,
    event: {
      editable: accountPermissions.checkAccountUserEditorRole
    }
  },
  delete: {
    products: false,
    account: {
      tickets: false
    }
  },
  accounting: {
    manage: {
      all: false // used to hide/show the side menu item
    }
  }
};
