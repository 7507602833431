/* Order of permissions:
Super Administrator
System Finance Admin
System Admin
System User
Limited System User
 */

import { oc } from 'ts-optchain';
import { USER_ROLE_ACCOUNT_USER, USER_ROLE_FAN, USER_ROLE_INSIDER, USER_ROLE_SUPER_ADMIN } from '@gofan/api/users';
import { USER_STATE_DISABLED } from '../../../api/model/user.model';
import { contactPermisson } from '../helpers';

export default {
  manage: {
    all: true
  },
  view: {
    beta: {
      dashboard: true,
      sideMenu: true
    },
    products: true,
    fanSupport: true,
    venues: true,
    account: {
      contact: {
        removable: true,
        permissions: {
          report: true,
          boxoffice: true,
          insider: {
            withoutInsiderBefore: ({ account: { user, accountId } }) => {
              // user is not existing in system can receive INSIDER permission
              if (!user || user.role === USER_ROLE_FAN) {
                return true;
              }
              // only role ACCOUNT_USER can receive INSIDER permission
              if (user.role !== USER_ROLE_ACCOUNT_USER) {
                return false;
              }

              const userAccountContexts = oc(user).userAccountContexts([]);

              return (
                userAccountContexts.every(context => !context.accountRoles.includes(USER_ROLE_INSIDER)) ||
                userAccountContexts.find(
                  context => context.accountId === accountId && context.accountRoles.includes(USER_ROLE_INSIDER)
                )
              );
            }
          },
          financial: {
            checkUserRole: ({ account: { user } }) => {
              // user is not existing in system can receive FINANCIAL permission
              if (!user || user.role === USER_ROLE_FAN) {
                return true;
              }
              // SUPER_ADMIN can self assign FIANCIAL permission
              // user as SUPER_ADMIN OR ACCOUNT_USER with EDITOR role can receive FIANCIAL permission
              return [USER_ROLE_SUPER_ADMIN, USER_ROLE_ACCOUNT_USER].includes(user.role);
            }
          },
          editor: {
            checkUserRole: ({ account: { user } }) => {
              if (!user || user.role === USER_ROLE_FAN) {
                return true;
              }
              return [USER_ROLE_ACCOUNT_USER].includes(user.role);
            }
          }
        }
      }
    }
  },
  create: {
    activity: true,
    level: true,
    account: {
      contact: true,
      tickets: true
    },
    rate: true,
    products: true
  },
  edit: {
    user: {
      editable: true,
      communicationReporting: true
    },
    account: {
      all: true,
      tickets: true,
      accountItem: {
        editable: true,
        accountStatus: {
          editable: true
        }
      },
      contact: {
        account_role: {
          // if there are multiple functions inside of this, ALL must evaluate to true for this to be true
          makeSureActive: ({ account }) => {
            if (account.account.inactive) {
              return false;
            }
            return true;
          },
          makeSureAccountRolesFit: ({ account }) => {
            // super admins can do what they want, but a deactive contact can't be edited.
            if (account.inactive) {
              return false;
            }
            return true;
          },
          canAssignAdminPermission: contactPermisson.canAssignAdmin,
          canEditEditor: ({ account }) => {
            if (account.user.role === 'SUPER_ADMIN' && account.type === 'EDITOR') {
              return false;
            }
            return true;
          }
        },
        editable: {
          canEditActiveStatus: ({ currentUser, account }) => currentUser.email !== account.email,
          canEdit: ({ account }) => {
            const { contactUserStatus } = account || {};

            return `${contactUserStatus}`.toUpperCase() !== USER_STATE_DISABLED;
          }
        }
      },
      goFanPage: {
        editable: {
          canEditCustomName: true,
          canEditPageStatus: true,
          canEditSearchable: true
        }
      },
      gofanPage: {
        editable: true
      }
    },
    fanSupport: {
      refundable: true
    },
    level: true,
    products: true,
    activity: {
      editable: true
    },
    rate: true,
    event: {
      editable: true
    }
  },
  delete: {
    products: true
  },
  accounting: {
    manage: {
      all: true, // used to hide/show the side menu item
      auditOnly: false // used to only show O&R and Disputes
    }
  }
};
