import type { SeasonRenewalDTO, SeasonRenewalFanDTO } from '@gofan/api/season-renewal';
import type SeatInfo from '../../api/model/SeatInfo';
import type { EventReservedSeatingTypes } from './actionTypes';
import type AccountDTO from '@app/api/dto/AccountDTO';
import {
  GET_SEASON_RENEWALS,
  GET_SEASON_RENEWALS_COMPLETED,
  UPDATE_RENEWAL_OFFER,
  UPDATE_RENEWAL_OFFER_COMPLETED,
  DELETE_RENEWAL_OFFER,
  DELETE_RENEWAL_OFFER_COMPLETED,
  PREPARE_DATA,
  UPDATE_DATA,
  PREPARE_DATA_COMPLETED,
  UPDATE_DATA_COMPLETED,
  BLOCK_RESERVED_SEATING,
  UNBLOCK_RESERVED_SEATING,
  UPDATE_SEATS_INFO_COMPLETED,
  UNBOOKED_RESERVED_SEAT,
  MOVE_BOOKED_RESERVED_SEAT,
  BOOK_RESERVED_SEATS,
  UPDATE_TICKET_NOTES,
  VIEW_EFFECTED_SEATS,
  CLEAR_SELECTED_ORDER,
  UPDATE_SEASON_RENEWAL,
  UPDATE_SEASON_RENEWAL_COMPLETED,
  UPDATE_ACCOUNTS_COMPLETED
} from './actionTypes';

export const prepareData = (
  id: number | string, // Can be either season Id or event Id, use isSeason to determine which is which
  mode: string,
  history: any,
  currentUser: any,
  isSeason: boolean
): EventReservedSeatingTypes => ({
  type: PREPARE_DATA,
  payload: {
    id,
    mode,
    history,
    currentUser,
    isSeason
  }
});

export const prepareDataCompleted = ({
  event = {},
  season = {},
  seatsInfo = {},
  seasonTickets,
  seasonRenewals = [],
  seasonRenewalSeats = {},
  error
}): EventReservedSeatingTypes => ({
  type: PREPARE_DATA_COMPLETED,
  payload: {
    event,
    season,
    seatsInfo,
    seasonTickets,
    seasonRenewals,
    seasonRenewalSeats
  },
  error
});

export const updateData = (eventId: number | string, updatedAt: number, error): EventReservedSeatingTypes => ({
  type: UPDATE_DATA,
  payload: {
    eventId,
    updatedAt
  },
  error
});

export const updateDataCompleted = (seatsInfo = {}, error): EventReservedSeatingTypes => ({
  type: UPDATE_DATA_COMPLETED,
  payload: {
    seatsInfo
  },
  error
});

export const blockReservedSeat = (
  eventId: number | string,
  seasonId: number | string,
  ticketsInfo: Array<object>,
  error
): EventReservedSeatingTypes => ({
  type: BLOCK_RESERVED_SEATING,
  payload: {
    eventId,
    seasonId,
    ticketsInfo
  },
  error
});
export const unblockReservedSeat = (
  eventId: number | string,
  seasonId: number | string,
  ticketsInfo: Array<object>,
  error
): EventReservedSeatingTypes => ({
  type: UNBLOCK_RESERVED_SEATING,
  payload: {
    eventId,
    seasonId,
    ticketsInfo
  },
  error
});

export const updateSeatsInfoCompleted = ({
  updatedSeatsInfo = {},
  seasonTickets,
  error
}): EventReservedSeatingTypes => ({
  type: UPDATE_SEATS_INFO_COMPLETED,
  payload: {
    updatedSeatsInfo,
    seasonTickets
  },
  error
});

export const viewSeatsInOrder = (actionType: string, seats: Array<object>): EventReservedSeatingTypes => ({
  type: VIEW_EFFECTED_SEATS,
  payload: {
    seats,
    type: actionType
  }
});

export const clearSelectedOrder = (): EventReservedSeatingTypes => ({
  type: CLEAR_SELECTED_ORDER,
  payload: {}
});

export const unbookSeats = (ticketsInfo: Array<object>, seasonTickets: any[] | null): EventReservedSeatingTypes => ({
  type: UNBOOKED_RESERVED_SEAT,
  payload: {
    ticketsInfo,
    seasonTickets
  }
});

export const moveBookedSeat = ({
  currentSeats,
  targetSeats,
  productId,
  seasonTickets
}: {
  currentSeat: SeatInfo;
  targetSeat: SeatInfo;
  productId: number | string;
  seasonTickets: any[] | null;
}): EventReservedSeatingTypes => ({
  type: MOVE_BOOKED_RESERVED_SEAT,
  payload: {
    currentSeats,
    targetSeats,
    productId,
    seasonTickets
  }
});

export const bookReservedSeats = (
  id: string | number, // The Id can be either an event Id OR a season Id. Use the isSeason boolean to distinguish between the two
  ticketsInfo: Array<object>,
  userInfo: never,
  isSeason: boolean,
  ticketCategory = []
): EventReservedSeatingTypes => ({
  type: BOOK_RESERVED_SEATS,
  payload: {
    id,
    ticketsInfo,
    userInfo,
    ticketCategory,
    isSeason
  }
});

export const updateTicketNotes = (notes: string, tickets: Array<object>): EventReservedSeatingTypes => ({
  type: UPDATE_TICKET_NOTES,
  payload: { notes, tickets }
});

export const updateSeasonRenewal = (
  seasonRenewal: Partial<SeasonRenewalDTO>,
  doneFn: () => void
): EventReservedSeatingTypes => ({
  type: UPDATE_SEASON_RENEWAL,
  payload: { seasonRenewal, doneFn }
});

export const updateSeasonRenewalCompleted = (seasonRenewal: SeasonRenewalDTO): EventReservedSeatingTypes => ({
  type: UPDATE_SEASON_RENEWAL_COMPLETED,
  payload: { seasonRenewal }
});

export const getSeasonRenewals = (payload: { seasonId: string }): EventReservedSeatingTypes => ({
  type: GET_SEASON_RENEWALS,
  payload
});

export const getSeasonRenewalsCompleted = (payload: {
  seasonRenewals: SeasonRenewalDTO[];
}): EventReservedSeatingTypes => ({
  type: GET_SEASON_RENEWALS_COMPLETED,
  payload
});

export const updateRenewalOffer = (payload: {
  renewalOffer: SeasonRenewalFanDTO;
  seasonId: string;
  doneFn: () => void;
}): EventReservedSeatingTypes => ({
  type: UPDATE_RENEWAL_OFFER,
  payload
});

export const updateRenewalOfferCompleted = (): EventReservedSeatingTypes => ({
  type: UPDATE_RENEWAL_OFFER_COMPLETED
});

export const deleteRenewalOffer = (payload: {
  renewalOffer: SeasonRenewalFanDTO;
  seasonId: string;
  doneFn: () => void;
}): EventReservedSeatingTypes => ({
  type: DELETE_RENEWAL_OFFER,
  payload
});

export const deleteRenewalOfferCompleted = (): EventReservedSeatingTypes => ({
  type: DELETE_RENEWAL_OFFER_COMPLETED
});

export const updateAccountsCompleted = (payload: {
  account: AccountDTO;
  financialAccount: AccountDTO;
}): EventReservedSeatingTypes => ({
  type: UPDATE_ACCOUNTS_COMPLETED,
  payload
});
