import type { VFC } from 'react';
import React, { useMemo } from 'react';
import { SkeletonPlaceholder, SkeletonText } from 'carbon-components-react';
import TicketSaleBySchoolChart from '@events/components/ticket-sale-by-school-chart/ticket-sale-by-school-chart';
import TicketSaleByTypeChart from '@events/components/ticket-sale-by-type-chart/ticket-sale-by-type-chart';
import { isEmpty } from '@utils/objectUtils';
import { useFeatureFlags, FEATURE_FLAGS } from '@gofan/hooks';
import classNames from 'classnames';
import TicketSaleByTypeNumerical from '@events/components/ticket-sale-by-type-numerical/ticket-sale-by-type-numerical';
import { DonationSaleEmpty } from '@app/modules/fundraiser/components/Differentiation/DonationSaleEmpty/DonationSaleEmpty';
import {
  composeTicketSaleDataBySchool,
  formatNumber,
  toTicketSaleDataByType
} from '@events/components/ticket-sale-chart/helper';
import TicketSaleBySchoolNumerical from '@app/modules/events/components/ticket-sale-by-school-numerical/ticket-sale-by-school-numerical';
import NoData from '@dashboard/components/no-data/no-data';

interface TicketSaleChartProps {
  loading: boolean;
  isEventLanding?: boolean;
  isFundraiser?: boolean;
  data: any;
}

const TicketSaleChart: VFC<TicketSaleChartProps> = ({
  data,
  loading,
  isEventLanding = false,
  isFundraiser = false
}) => {
  const {
    eventTickets = [],
    schoolTickets = [],
    totalTicketAvailableBySchools,
    totalTicketAvailableByTypes,
    ticketUnlimited,
    showEmptyData,
    ticketDistribution
  } = data || {};

  const { [FEATURE_FLAGS.fundraiserDifferentiation]: renderFundraiserDifferentiation } = useFeatureFlags();

  const ticketSaleByTypeData = useMemo(
    () => (eventTickets.length !== 0 ? eventTickets.reduce(toTicketSaleDataByType, {}) : undefined),
    [eventTickets]
  );
  const ticketSaleBySchoolData = useMemo(
    () => (schoolTickets.length !== 0 ? composeTicketSaleDataBySchool(schoolTickets) : undefined),
    [schoolTickets]
  );

  const legend = [
    { label: 'Total type', color: '#e0e0e0' },
    { label: 'Sales by type', color: '#42be65' }
  ];

  if (loading) {
    return (
      <>
        <SkeletonText />
        <SkeletonPlaceholder style={{ width: '100%' }} />
      </>
    );
  }

  if (showEmptyData) {
    return (
      <div className='ticket-sale-chart'>
        <div className='ticket-sales-header gs--text-center'>
          <div className='gs--margin-bottom-sp4'>
            <div className='gs--productive-heading-02-semibold gs--text-01 gs--padding-top__sp5'>
              {`${isFundraiser ? 'Fundraiser' : 'Tickets'} sales by type`}
            </div>
            {renderFundraiserDifferentiation && isFundraiser ? <DonationSaleEmpty /> : <NoData />}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='ticket-sale-chart'>
      <div className='ticket-sales-header gs--text-center'>
        {ticketDistribution && (
          <div className='gs--margin-bottom-sp4'>
            <div className='gs--productive-heading-02-semibold gs--text-01 gs--padding-top__sp5'>
              {`${isFundraiser ? 'Fundraiser' : 'Tickets'} sales by school`}
            </div>
            {!isFundraiser && (
              <div className='gs--label-01-semibold gs--text-01 gs--padding-top__sp3'>
                Total tickets available -{' '}
                {!ticketUnlimited && !isEmpty(ticketSaleBySchoolData) && totalTicketAvailableBySchools > 0
                  ? formatNumber(totalTicketAvailableBySchools)
                  : 'Unlimited'}
              </div>
            )}
          </div>
        )}
        {!ticketDistribution && (
          <div className='gs--margin-bottom-sp4'>
            <div className='gs--productive-heading-02-semibold gs--text-01 gs--padding-top__sp5'>
              {`${isFundraiser ? 'Fundraiser' : 'Tickets'} sales by type`}
            </div>
            {!isFundraiser && (
              <div className='gs--label-01-semibold gs--text-01 gs--padding-top__sp3'>
                Total tickets available -{' '}
                {!ticketUnlimited && totalTicketAvailableByTypes > 0
                  ? formatNumber(totalTicketAvailableByTypes)
                  : 'Unlimited'}
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className={classNames('ticket-sales-body', {
          'insight-modal': isEventLanding
        })}
      >
        {ticketDistribution && (
          <div>
            {!isEmpty(ticketSaleBySchoolData) && !ticketUnlimited ? (
              <TicketSaleBySchoolChart data={ticketSaleBySchoolData} />
            ) : (
              <TicketSaleBySchoolNumerical data={schoolTickets} />
            )}
            {!isEmpty(ticketSaleByTypeData) && !ticketUnlimited ? (
              <TicketSaleByTypeChart data={ticketSaleByTypeData} />
            ) : (
              <>
                {!isEmpty(eventTickets) ? (
                  <>
                    <div className='gs--padding-bottom__sp5'>
                      <div className='event-insight__divider' />
                    </div>
                    <TicketSaleByTypeNumerical data={eventTickets} isFundraiser={isFundraiser} />
                  </>
                ) : null}
              </>
            )}
          </div>
        )}
        {!ticketDistribution && (
          <div>
            {!isEmpty(ticketSaleByTypeData) && !ticketUnlimited ? (
              <TicketSaleByTypeChart data={ticketSaleByTypeData} isFundraiser={isFundraiser} />
            ) : (
              <TicketSaleByTypeNumerical data={eventTickets} isFundraiser={isFundraiser} />
            )}
            {!isEmpty(ticketSaleBySchoolData) && !ticketUnlimited ? (
              <TicketSaleBySchoolChart data={ticketSaleBySchoolData} />
            ) : (
              <>
                {!isEmpty(schoolTickets) ? (
                  <>
                    <div className='gs--padding-bottom__sp5'>
                      <div className='event-insight__divider' />
                    </div>
                    <TicketSaleBySchoolNumerical data={schoolTickets} />
                  </>
                ) : null}
              </>
            )}
          </div>
        )}
      </div>
      {!isFundraiser &&
        !ticketDistribution &&
        !isEmpty(ticketSaleByTypeData) &&
        isEmpty(ticketSaleBySchoolData) &&
        !ticketUnlimited && (
          <div className='ticket-sales-footer'>
            <div className='legend gs--margin-top-sp4 gs--padding-left__sp5 gs--padding-right__sp5' role='list'>
              {legend.map(item => (
                <div key={item.label} className='legend-item'>
                  <div className='legend-item__color' style={{ backgroundColor: item.color }} />
                  <span className='legend-item__label gs--label-01'>{item.label}</span>
                </div>
              ))}
            </div>
          </div>
        )}
    </div>
  );
};

export default TicketSaleChart;
