import React, { useContext } from 'react';
import { Form, Radio } from 'semantic-ui-react';
import styled from 'styled-components';
import FormSafeguard from './FormSafeguard';
import { FastFormContext } from './FastFormBase';
import styles from './styles';

const FormWrapper = styled(Form)`
  ${styles.formWrapper};
`;

const RadioWrapper = styled(Radio)`
  ${styles.radioWrapper};
`;

export default function Radios({
  formKey,
  label,
  vertical,
  name = 'name',
  radios = []
}) {
  const fastFormContext = useContext(FastFormContext);
  const formValue = fastFormContext.form[formKey] || {};
  const onChange = value =>
    fastFormContext.updateField(formKey, {
      ...formValue,
      value
    });

  return (
    <FormSafeguard formKey={formKey}>
      <FormWrapper vertical={vertical}>
        <Form.Field>{label}</Form.Field>
        {radios.map(radio => (
          <Form.Field key={radio.key}>
            <RadioWrapper
              label={radio.label || radio.text}
              name={name}
              value={radio.value}
              checked={radio.value === formValue.value}
              onChange={() => onChange(radio.value)}
            />
          </Form.Field>
        ))}
      </FormWrapper>
    </FormSafeguard>
  );
}
