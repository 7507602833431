// @flow
class PaginationDTO {
  constructor({ data, pageSize, pageNumber, totalPages, totalElements }) {
    this.raw = {
      data,
      pageSize,
      pageNumber,
      totalPages,
      totalElements
    };
  }

  get data() {
    return this.raw.data || [];
  }

  get pageNumber() {
    return this.raw.pageNumber;
  }

  get pageSize() {
    return this.raw.pageSize;
  }

  get totalPages() {
    return this.raw.totalPages;
  }

  get totalElements() {
    return this.raw.totalElements;
  }

  toJSON() {
    return {
      data: this.data,
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
      totalPages: this.totalPages,
      totalElements: this.totalElements
    };
  }
}

export default PaginationDTO;
