import type { Dispatch, SetStateAction } from 'react';
import cs from 'classnames';
import {
  ProgressIndicator,
  ProgressStep,
  Button,
  InlineLoading,
  SkeletonText,
  SkeletonPlaceholder,
  Row,
  Column,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ComposedModal
} from 'carbon-components-react';
import isEmpty from 'lodash/isEmpty';

import type { EventDTO } from '@events/models/event.model';
import MultiSelectWithAllOpt from '@common/carbon-ui/customized/MultiSelectWithAllOpt';

import './delete-season-modal.scss';

interface DeleteSeasonModalProps {
  events: EventDTO[];
  selectedEvents?: EventDTO[];
  loading?: boolean;
  deleting?: boolean;
  step?: number;
  open?: boolean;
  heading?: string;
  modalKey?: number;
  onGoToStep?: Dispatch<SetStateAction<number>>;
  onToggleModal?: (open?: boolean) => void;
  onSelectDeleteEvents?: (events: EventDTO[]) => void;
  onDelete?: () => Promise<void>;
}

export const DeleteSeasonModal = ({
  events = [],
  selectedEvents = [],
  loading = false,
  deleting = false,
  open = false,
  step = 0,
  heading = 'Delete season',
  modalKey = new Date().getTime(),
  onGoToStep,
  onToggleModal,
  onSelectDeleteEvents,
  onDelete
}: DeleteSeasonModalProps) => {
  return (
    <ComposedModal preventCloseOnClickOutside open={open} onClose={_onRequestClose} className='delete-season-modal'>
      <ModalHeader className='delete-season-modal__header'>
        <Row>
          <Column className='gs--productive-heading-03 gs--text-01'>{heading}</Column>
        </Row>
      </ModalHeader>

      <ModalBody hasForm className='delete-season-modal__body'>
        {loading ? (
          <div style={{ marginBottom: '3rem' }}>
            <SkeletonText style={{ marginBottom: '0.5rem' }} />
            <SkeletonPlaceholder style={{ width: '100%' }} />
          </div>
        ) : (
          <>
            <div className='indicator-wrapper'>
              <ProgressIndicator spaceEqually currentIndex={step}>
                <ProgressStep label='Choose events' />
                <ProgressStep label='Delete season' />
              </ProgressIndicator>
            </div>

            {step === 0 && (
              <>
                <Row>
                  <Column className='gs--padding-top__sp7'>
                    <div className='delete-season-desc gs--body-long-01 gs--text-01'>
                      <p>
                        {`Which season event(s), if any, do you want to delete? By deleting these events, they will be permanently
                        removed from your school's account. These events cannot be recovered after deletion.`}
                      </p>
                    </div>
                  </Column>
                </Row>

                <Row>
                  <Column className='gs--padding-top__sp5'>
                    <MultiSelectWithAllOpt
                      className='delete-season-modal__multi-select'
                      id='multi-select-delete-event'
                      key={modalKey}
                      titleText='Events'
                      label={isEmpty(selectedEvents) ? 'Choose an option' : 'event(s) selected'}
                      itemToString={(event: EventDTO) => event?.name ?? ''}
                      items={events}
                      initialSelectedItems={selectedEvents}
                      onChange={_onSelectEvents}
                    />
                  </Column>
                </Row>
              </>
            )}

            {step === 1 && (
              <Row>
                <Column className='gs--padding-top__sp7'>
                  <div className='delete-season-desc gs--body-long-01 gs--text-01'>
                    <p>
                      {`By deleting this season, it will permanently be removed from your school's account. This season cannot be recovered after deletion.`}
                    </p>
                    <p>Click Delete season to continue.</p>
                  </div>
                </Column>
              </Row>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter className={cs('delete-season-modal__footer', { 'multiple-btn-group': step === 1, hidden: loading })}>
        {step === 0 && (
          <>
            <Button kind='secondary' onClick={_onRequestClose} disabled={deleting}>
              Cancel
            </Button>
            <Button kind='primary' onClick={_onNext} disabled={deleting}>
              Continue
            </Button>
          </>
        )}
        {step === 1 && (
          <>
            <Button kind='ghost' onClick={_onRequestClose} disabled={deleting}>
              Cancel
            </Button>
            <Button kind='secondary' onClick={_onBack} disabled={deleting}>
              Previous
            </Button>
            <Button kind='danger' onClick={_onDelete} disabled={deleting}>
              {!deleting && 'Delete season'}
              {deleting && <InlineLoading style={{ marginLeft: '1rem' }} description='Deleting...' status='active' />}
            </Button>
          </>
        )}
      </ModalFooter>
    </ComposedModal>
  );

  function _onSelectEvents({ selectedItems }: { selectedItems: EventDTO[] }) {
    if (typeof onSelectDeleteEvents === 'function') onSelectDeleteEvents(selectedItems);
  }

  function _onRequestClose() {
    if (typeof onToggleModal === 'function') onToggleModal(false);
  }

  function _onNext() {
    if (typeof onGoToStep === 'function') onGoToStep(step + 1);
  }

  function _onBack() {
    if (typeof onGoToStep === 'function') onGoToStep(step - 1);
  }

  function _onDelete() {
    if (typeof onDelete === 'function' && !deleting) onDelete();
  }
};
