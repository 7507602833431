import React from 'react';
import { Popup, Menu, Button } from 'semantic-ui-react';

const ActionButton = ({ items, onClick, position, triggerOn }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <Popup
      inverted
      on={triggerOn}
      trigger={<Button circular className='action-btn' icon='ellipsis horizontal' />}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      position={position}
      content={() => (
        <Menu text vertical inverted>
          {items.map(item => (
            <Menu.Item
              key={item.id}
              name={item.name}
              onClick={() => {
                setOpen(false);
                onClick(item);
              }}
            >
              {item.name}
            </Menu.Item>
          ))}
        </Menu>
      )}
    />
  );
};

ActionButton.defaultProps = {
  items: [],
  triggerOn: 'click',
  position: 'top left',
  onClick: () => null
};

export default ActionButton;
