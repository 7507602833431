import React from 'react';
import { Message } from 'semantic-ui-react';
import styled from 'styled-components';

import styles from './styles';

const StyledMessageContent = styled(Message.Content)`
  ${styles.messageContent};
`;

export default ({ children, ...props }) => (
  <StyledMessageContent {...props}>{children}</StyledMessageContent>
);
