// Used in Tickets sales by type and Tickets sales by school chart
import { purple70, cyan50, green50, magenta70, orange40, blue80, yellow30 } from '@carbon/colors';
import type { QROptions } from '@gofan/utils/qr-code';

export const MAX_CAPACITY = 9999999;

export const colorPalette = ['#da1e27', '#002c9c'];
export const colorPalette2 = [
  purple70,
  cyan50,
  green50,
  magenta70,
  orange40,
  blue80,
  yellow30,
  '#fa4d56',
  '#1192e7',
  '#005d5c',
  '#e92cd1',
  '#100bf5',
  '#00f1c6',
  '#00f157',
  '#7df100',
  '#7c8325',
  '#ffbe00',
  '#ff8f00',
  '#ff0000'
];

export const EVENT_INSIGHT_OVERFLOW_LABEL = 'Event insights';

export const QUICK_FILTER_TYPE = {
  ALL_EVENTS: 'allEvent',
  LAST_7_DAYS: 'last7Days',
  UPCOMING_EVENTS: 'upcomingEvent',
  TODAY_EVENTS: 'todayEvent',
  CURRENT_EVENTS: 'currentEvent'
};

export const FILTER_CATEGORIES = {
  ALL: 'ALL',
  CONCESSION: 'CONCESSION',
  MOBILEPASS: 'MOBILEPASS',
  DONATION: 'DONATION',
  DONATION_ONGOING: 'DONATION_ONGOING'
};

export const EVENT_CATEGORIES_FILTERS = {
  all: {
    id: FILTER_CATEGORIES.ALL,
    name: FILTER_CATEGORIES.ALL,
    value: [],
    text: 'All',
    type: 'categories'
  },
  mobilePass: {
    id: FILTER_CATEGORIES.MOBILEPASS,
    name: FILTER_CATEGORIES.MOBILEPASS,
    value: [FILTER_CATEGORIES.MOBILEPASS],
    text: 'Mobile Pass',
    type: 'categories'
  },
  concession: {
    id: FILTER_CATEGORIES.CONCESSION,
    name: FILTER_CATEGORIES.CONCESSION,
    value: [FILTER_CATEGORIES.CONCESSION],
    text: 'Concession',
    type: 'categories'
  },
  fundraiser: {
    id: FILTER_CATEGORIES.DONATION,
    name: FILTER_CATEGORIES.DONATION,
    value: [FILTER_CATEGORIES.DONATION, FILTER_CATEGORIES.DONATION_ONGOING],
    text: 'Fundraiser',
    type: 'categories'
  }
};

export default {
  MAX_CAPACITY,
  colorPalette,
  colorPalette2
};

export const MANAGE_FUNDRAISER_OPTIONS = {
  MANAGE_DONORS: 'Manage donors',
  EDIT_DONATION_LEVEL: 'Edit donation level',
  DUPLICATE: 'Duplicate',
  RESCHEDULE: 'Reschedule',
  DELETE: 'Delete',
  CANCEL: 'Cancel'
};

export const VENUE_FIELDS = [
  'venueId',
  'venueCity',
  'venueAddress',
  'venueLocation',
  'venueName',
  'venueState',
  'venueZip'
];

export const QR_CODE_IMAGE_CONFIG: QROptions = {
  width: 250
};

export const EVENT_HISTORY_TABLE_HEADERS = [
  {
    key: 'updatedAt',
    header: 'Date Updated'
  },
  {
    key: 'updatedBy',
    header: 'Edited By'
  }
];
