import moment from 'moment';
import { ACCOUNT_PAYMENT_CYCLE } from '../accounts';

import { DATE_FORMAT_DEFAULT_WITH_TIMEZONE } from '../date';
import { PRODUCT_TYPE } from '../product';
import type { EventHistoryDateRange } from '@gofan/api';

export const QUICK_FILTER_TYPE = {
  ALL_EVENTS: 'allEvent',
  LAST_7_DAYS: 'last7Days',
  UPCOMING_EVENTS: 'upcomingEvent',
  TODAY_EVENTS: 'todayEvent',
  FAN_MANAGEMENT: 'fanManagement'
};

export const QUICK_FILTER_LABEL = {
  [QUICK_FILTER_TYPE.TODAY_EVENTS]: "Today's Events",
  [QUICK_FILTER_TYPE.ALL_EVENTS]: 'All Events',
  [QUICK_FILTER_TYPE.UPCOMING_EVENTS]: 'Upcoming Events',
  [QUICK_FILTER_TYPE.LAST_7_DAYS]: 'Last 7 days Events',
  [QUICK_FILTER_TYPE.FAN_MANAGEMENT]: 'Fan Management'
};

export const NO_ALLOCATION_ACC_ID = 'NO_ALLOCATION_ACC_ID';

export const COLLAPSE_EVENT_MODE = {
  EDIT: 'edit',
  VIEW: 'view'
};

export const EVENT_START_TIME_TYPE = {
  ALL_TEAMS: 'SAME_TIME',
  EACH_TEAM: 'DIFFERENCE_TIME'
};

export const EVENT_PRIMARY_COLOR_DEFAULT = '6f6f6f';

export const defaultEndDate = moment(new Date())
  .subtract(5, 'days')
  .set('hour', 0)
  .set('minutes', 0)
  .set('seconds', 1)
  .format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE);

export const EVENT_FILTER_STATUS_OPTIONS = {
  visible: 'Visible',
  hidden: 'Hidden',
  canceled: 'Canceled'
};

export const SEARCH_PARAMS = {
  page: 0,
  pageSize: 1,
  // body: { endingAfterEndDate: defaultEndDate },
  body: {},
  expands: [],
  sortBy: { id: 'startDateTime', desc: false }
};

export const DASHBOARD_SEARCH_PARAMS = {
  page: 0,
  pageSize: 10,
  body: {},
  expands: ['account', 'account-opponent', 'activity', 'levels', 'event-sales-info'],
  sortBy: { id: 'startDateTime', desc: false }
};

export const EVENT_SEARCH_PARAMS = {
  page: 0,
  pageSize: 10,
  body: {},
  expands: ['activity', 'levels', 'event-sales-info'],
  sortBy: { id: 'startDateTime', desc: false }
};

export const EVENT_FILTER_FORM_DATA = {
  schools: [],
  homeEvent: false,
  awayEvent: false,

  isQuickFiltered: true,
  timeRange: QUICK_FILTER_TYPE.TODAY_EVENTS,

  coed: false,
  boys: false,
  girls: false,
  levels: [],
  activities: [],

  hidden: false,
  visible: false,
  canceled: false,

  regularSeason: false,
  postSeason: false,
  nonAthletic: false,

  isFilterBySpecificDate: false,
  specificDate: '',
  startDate: '',
  endDate: ''
};

export const EVENT_COUNT_BY_TYPE = {
  totalElements: 0,
  mobilePassesQuantity: 0,
  ticketQuantity: 0,
  fundraiserQuantity: 0,
  concessionQuantity: 0
};

export const DEFAULT_EVENTS = {
  content: [],
  totalElements: 0
};

const DEFAULT_ROLES = [
  { id: 'financialAccountId', label: 'Financial' },
  { id: 'accountId', label: 'Home' },
  { id: 'opponentAccountId', label: 'Opponent' },
  { id: 'hostAccountId', label: 'Host' },
  { id: 'taggedAccountIds', label: 'Participant' },
  { id: 'accountAndFinancialId', label: 'Home school (Financial)' }
];

export const ATHLETIC = 'Athletic';
export const PERFORMING_ART = 'Performing Arts';
export const REGISTRATION = 'Registration';
export const SCHOOL_DANCE = 'School Dance';
export const PROM = 'Prom';
export const OTHER = 'Other';
export const MOBILE_PASS = 'Mobile Pass';
export const FUNDRAISER = 'Fundraiser';
export const CONCESSION = 'Concession';
export const PLAYON_SITE = 'PlayOn Site';

export const EVENT_TYPES = {
  // Event Type Configuration
  CONFIG: {
    Athletic: {
      ALLOW_LEVEL: true,
      EVENT_GENERATE_NAME: true,
      USE_MOBILE_PASS_UI: false,
      ALLOW_GATE_OPENING_TIME: true,
      ALLOW_EVENT_PAYMENT_CYCLE: false,
      VIEW_EVENT_START_TIME: 'Event starts:',
      VIEW_EVENT_END_TIME: 'Event ends:',
      VIEW_EVENT_TIME_ZONE: 'Time zone:',
      VIEW_EVENT_TICKET_HEADER: 'Ticket prices / status',
      VIEW_TICKET_CAPACITY: 'Total tickets available:',
      EDIT_TICKET: 'Edit ticket',
      EDIT_TICKET_GENERAL_DESCRIPTION: 'Edit ticket for your event',
      EDIT_TICKET_OPTIONAL_DESCRIPTION: 'Enter optional ticket settings',
      EDIT_EVENT_DATE: 'Event date',
      EDIT_EVENT_START_DATE: 'Event start date',
      EDIT_EVENT_START_TIME: 'Event start time',
      EDIT_EVENT_END_DATE: 'Event end date',
      EDIT_EVENT_END_TIME: 'Event end time',
      EDIT_PRODUCT_NAME: 'Ticket name',
      EDIT_PRODUCT_NAME_PLACEHOLDER: 'Example: General Admission',
      EDIT_PRODUCT_PRICE: 'Ticket price',
      EDIT_EVENT_NAME: 'Event name',
      EDIT_EVENT_MAX_CAPACITY: 'Total tickets for this event',
      EDIT_EVENT_INFORMATION_SECTION: 'Event information',
      EDIT_EVENT_CANCEL_SECTION: 'Cancel this event',
      EDIT_EVENT_TICKETS_SECTION: 'Tickets for this event',
      TICKET_ON_SALE: 'Ticket on sale',
      VIEW_OPTION_SETTINGS: 'View optional ticket settings',
      SALES_START_DATE: 'Sale start date',
      SALES_START_TIME: 'Sale start time',
      SALES_END_DATE: 'Sale end date',
      SALES_END_TIME: 'Sale end time',
      TICKET_LIMIT_PER_ORDER: 'Ticket limit per order',
      TICKET_LIMIT_PER_ORDER_DESCRIPTION:
        'Set the maximum number of tickets customer can purchase for this ticket per order',
      TICKET_FEE: 'Ticket fee',
      TICKET_FEE_DESCRIPTION: 'Set the ticket fee for each ticket created',
      REDEMPTION_LIMIT: 'Ticket redemption limit',
      REDEMPTION_LIMIT_DESCRIPTION: 'Set the maximum number of uses a customer can use this ticket',
      UNLIMITED_USE: 'Unlimited use',
      PACK_COUNT: 'Family ticket',
      PACK_COUNT_DESCRIPTION: 'Set the number of tickets that are included in the family ticket',
      USING_PACK_COUNT: 'Make this a family ticket',
      REVIEW_HIDDEN_FEES: 'Attendee pays fee',
      ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the ticket name?',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the ticket price?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this ticket to “Not on sale” and then create a new ticket type.',
      FORM_TEMPLATE: [
        {
          id: 'first-name',
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        {
          id: 'last-name',
          name: 'last-name',
          text: 'Last Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 2
        },
        {
          id: 'email',
          name: 'email',
          text: 'Email Address',
          fieldType: 'EMAIL',
          displayOnTicket: true,
          required: true,
          order: 3
        },
        {
          id: 'phone-number',
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        {
          id: 'student-id',
          name: 'student-id',
          text: 'Student Id',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 5
        }
      ],
      COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
      INFORMATION_TYPES_TITLE: 'Information types',
      INFORMATION_TYPES_SELECT_TEXT: 'Select',
      INFORMATION_TYPES_SELECTED_TEXT: 'fields selected',
      FIELD_ON_FANS_TICKET_TITLE: 'Display these fields on fans ticket',
      CUSTOM_FIELDS_TITLE: 'Custom fields',
      CUSTOM_FIELDS_ON_TEXT: 'On',
      CUSTOM_FIELDS_OFF_TEXT: 'Off',
      FIELD_NAME_TEXT: 'Field name',
      FIELD_TYPE_TEXT: 'Field types',
      DROPDOWN_OPTION_TEXT: 'Drop-down options',
      ADD_ADDITIONAL_FIELD_TITLE_BTN: 'Add additional field',
      ADD_DROPDOWN_OPTIONS_TITLE_BTN: 'Add drop-down options',
      EDIT_DROPDOWN_OPTIONS_TITLE_BTN: 'Edit drop-down options',
      MAX_ADDITIONAL_FIELD_NUMBER: 25,
      ADD_ADDITIONAL_FIELD_WARNING_TEXT: 'Only 25 fields can be added',
      CUSTOM_FIELD_TYPE_OPTIONS: [
        {
          name: 'textInput',
          label: 'Text input',
          value: 'STRING'
        },
        {
          name: 'numberInput',
          label: 'Number input',
          value: 'NUMBER'
        },
        {
          name: 'dropdown',
          label: 'Dropdown',
          value: 'DROPDOWN'
        },
        {
          name: 'checkbox',
          label: 'Checkbox',
          value: 'CHECKBOX'
        },
        {
          name: 'email',
          label: 'Email',
          value: 'EMAIL'
        },
        {
          name: 'phoneNumber',
          label: 'Phone number',
          value: 'PHONE'
        },
        {
          name: 'textArea',
          label: 'Text area',
          value: 'TEXTBOX'
        }
      ],
      EDIT_LABEL: 'Edit Ticket',
      TICKETS_TITLE: 'Tickets',
      EVENT_TICKETS: 'Event Tickets',
      MODAL_EDIT_EVENT_NAME: 'Edit Event Name',
      TICKETS_ON_SALE: 'Tickets on Sale',
      TICKET_SALES_START: 'Ticket sales start',
      TICKET_SALES_END: 'Ticket sales end',
      TICKET_SETUP: 'Ticket Setup',
      SELECT_TICKET_TYPE: 'Select ticket type',
      ADD_TICKET: 'Add ticket',
      ADD_ACCOUNT: 'Add Opponent',
      TICKET_AVAILABILITY: 'Ticket availability',
      TOTAL_TICKET_AVAILABILITY: 'Total tickets available for this event',
      UNLIMITED_TICKETS: 'Unlimited tickets',
      SPLIT_TICKET_BETWEEN_SCHOOLS: 'Split ticket between schools',
      EVENT_TICKETS_DESCRIPTION: 'Your default tickets appear below, you may edit or add new tickets for your event',
      EVENT_NAME_TITLE: 'Event name',
      EVENT_PLACEHOLDER: 'Example: The Blue River Rivalry',
      SEARCH_PLACEHOLDER: 'Search for a Ticket',
      DOWNLOAD_TICKET_FLYER: 'Download Ticket Flyer',
      TICKET_LINK: 'Ticket Link',
      TICKETS_SOLD: 'Tickets sold',
      RESCHEDULE_THIS_EVENT: 'Reschedule this event',
      YOU_CHOOSE_CANCEL_EVENT: 'You are choosing to cancel this event',
      GOFAN_REFUND_TICKETS: 'GoFan will process refunds for all event tickets',
      CANCEL_EVENT: 'Cancel event',
      CONFIRM_CANCEL_EVENT: 'Confirmation: I want to cancel this event',
      TICKET_SALES: 'Ticket sales',
      TICKET_SOLD: 'Ticket Sold',
      TYPE_OF_EVENT: 'Type of event',
      TICKET_USED: 'Ticket used',
      PERCENT_TICKET_USED: '% of tickets used',
      SEND_EMAIL_NOTIFY: 'Send notification email to ticket buyers',
      DELETE_TICKET: 'Delete ticket',
      VIEW_EVENT_TABS: ['Summary', 'Management', 'Tickets', 'Streaming', 'Statistics', 'Comp Ticket'],
      COMP_TICKETS_TAB: {
        TITLE: 'Comp Ticket',
        DESCRIPTION: 'Search for fan profile who has purchased a ticket for this event',
        RECENT_COMP_TICKETS: 'Recent Comp Tickets',
        TICKET_HOLDER_EMAIL: 'Ticket Holder Email',
        COMP_TICKETS: 'Comp tickets',
        TICKET: 'ticket',
        TICKETS: 'tickets',
        CREATE_COMP_TICKET_BTN: 'Create Comp Ticket',
        SUCCESS_NOTIFICATION: 'Comp ticket created successfully'
      },
      COMP_TICKETS_MODAL: {
        TITLE: 'Send Complimentary Tickets',
        SUBTITLE: 'Enter the email address and quantity of tickets you would like to send'
      }
    },
    [PERFORMING_ART]: {
      ACTIVITIES: [PERFORMING_ART],
      ALLOW_LEVEL: false,
      EVENT_GENERATE_NAME: false,
      USE_MOBILE_PASS_UI: false,
      ALLOW_GATE_OPENING_TIME: true,
      ALLOW_EVENT_PAYMENT_CYCLE: false,
      VIEW_EVENT_START_TIME: 'Event starts:',
      VIEW_EVENT_END_TIME: 'Event ends:',
      VIEW_EVENT_TIME_ZONE: 'Time zone:',
      VIEW_EVENT_TICKET_HEADER: 'Ticket prices / status',
      VIEW_TICKET_CAPACITY: 'Total tickets available:',
      EDIT_TICKET: 'Edit ticket',
      EDIT_TICKET_GENERAL_DESCRIPTION: 'Edit ticket for your event',
      EDIT_TICKET_OPTIONAL_DESCRIPTION: 'Enter optional ticket settings',
      EDIT_EVENT_DATE: 'Event date',
      EDIT_EVENT_START_DATE: 'Event start date',
      EDIT_EVENT_START_TIME: 'Event start time',
      EDIT_EVENT_END_DATE: 'Event end date',
      EDIT_EVENT_END_TIME: 'Event end time',
      EDIT_PRODUCT_NAME: 'Ticket name',
      EDIT_PRODUCT_NAME_PLACEHOLDER: 'Example: General Admission',
      EDIT_PRODUCT_PRICE: 'Ticket price',
      EDIT_EVENT_NAME: 'Event name',
      EDIT_EVENT_MAX_CAPACITY: 'Total tickets for this event',
      EDIT_EVENT_INFORMATION_SECTION: 'Event information',
      EDIT_EVENT_CANCEL_SECTION: 'Cancel this event',
      EDIT_EVENT_TICKETS_SECTION: 'Tickets for this event',
      TICKET_ON_SALE: 'Ticket on sale',
      VIEW_OPTION_SETTINGS: 'View optional ticket settings',
      SALES_START_DATE: 'Sale start date',
      SALES_START_TIME: 'Sale start time',
      SALES_END_DATE: 'Sale end date',
      SALES_END_TIME: 'Sale end time',
      TICKET_LIMIT_PER_ORDER: 'Ticket limit per order',
      TICKET_LIMIT_PER_ORDER_DESCRIPTION:
        'Set the maximum number of tickets customer can purchase for this ticket per order',
      TICKET_FEE: 'Ticket fee',
      TICKET_FEE_DESCRIPTION: 'Set the ticket fee for each ticket created',
      REDEMPTION_LIMIT: 'Ticket redemption limit',
      REDEMPTION_LIMIT_DESCRIPTION: 'Set the maximum number of uses a customer can use this ticket',
      UNLIMITED_USE: 'Unlimited use',
      PACK_COUNT: 'Family ticket',
      PACK_COUNT_DESCRIPTION: 'Set the number of tickets that are included in the family ticket',
      USING_PACK_COUNT: 'Make this a family ticket',
      REVIEW_HIDDEN_FEES: 'Attendee pays fee',
      ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the ticket name?',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the ticket price?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this ticket to “Not on sale” and then create a new ticket type.',
      FORM_TEMPLATE: [
        {
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        { name: 'last-name', text: 'Last Name', fieldType: 'STRING', displayOnTicket: true, required: true, order: 2 },
        { name: 'email', text: 'Email Address', fieldType: 'EMAIL', displayOnTicket: true, required: true, order: 3 },
        {
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        { name: 'student-id', text: 'Student Id', fieldType: 'STRING', displayOnTicket: true, required: true, order: 5 }
      ],
      COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
      INFORMATION_TYPES_TITLE: 'Information types',
      INFORMATION_TYPES_SELECT_TEXT: 'Select',
      INFORMATION_TYPES_SELECTED_TEXT: 'fields selected',
      FIELD_ON_FANS_TICKET_TITLE: 'Display these fields on fans ticket',
      CUSTOM_FIELDS_TITLE: 'Custom fields',
      CUSTOM_FIELDS_ON_TEXT: 'On',
      CUSTOM_FIELDS_OFF_TEXT: 'Off',
      FIELD_NAME_TEXT: 'Field name',
      FIELD_TYPE_TEXT: 'Field types',
      DROPDOWN_OPTION_TEXT: 'Drop-down options',
      ADD_ADDITIONAL_FIELD_TITLE_BTN: 'Add additional field',
      ADD_DROPDOWN_OPTIONS_TITLE_BTN: 'Add drop-down options',
      EDIT_DROPDOWN_OPTIONS_TITLE_BTN: 'Edit drop-down options',
      MAX_ADDITIONAL_FIELD_NUMBER: 25,
      ADD_ADDITIONAL_FIELD_WARNING_TEXT: 'Only 25 fields can be added',
      CUSTOM_FIELD_TYPE_OPTIONS: [
        {
          name: 'textInput',
          label: 'Text input',
          value: 'STRING'
        },
        {
          name: 'numberInput',
          label: 'Number input',
          value: 'NUMBER'
        },
        {
          name: 'dropdown',
          label: 'Dropdown',
          value: 'DROPDOWN'
        },
        {
          name: 'checkbox',
          label: 'Checkbox',
          value: 'CHECKBOX'
        },
        {
          name: 'email',
          label: 'Email',
          value: 'EMAIL'
        },
        {
          name: 'phoneNumber',
          label: 'Phone number',
          value: 'PHONE'
        },
        {
          name: 'textArea',
          label: 'Text area',
          value: 'TEXTBOX'
        }
      ],
      EDIT_LABEL: 'Edit Ticket',
      TICKETS_TITLE: 'Tickets',
      EVENT_TICKETS: 'Event Tickets',
      MODAL_EDIT_EVENT_NAME: 'Edit Event Name',
      TICKETS_ON_SALE: 'Tickets on Sale',
      TICKET_SALES_START: 'Ticket sales start',
      TICKET_SALES_END: 'Ticket sales end',
      TICKET_SETUP: 'Ticket Setup',
      SELECT_TICKET_TYPE: 'Select ticket type',
      ADD_TICKET: 'Add ticket',
      ADD_ACCOUNT: 'Add Opponent',
      TICKET_AVAILABILITY: 'Ticket availability',
      TOTAL_TICKET_AVAILABILITY: 'Total tickets available for this event',
      UNLIMITED_TICKETS: 'Unlimited tickets',
      SPLIT_TICKET_BETWEEN_SCHOOLS: 'Split ticket between schools',
      EVENT_TICKETS_DESCRIPTION: 'Your default tickets appear below, you may edit or add new tickets for your event',
      EVENT_NAME_TITLE: 'Event name',
      EVENT_PLACEHOLDER: 'Example: Beauty and the Beast - Night 1',
      SEARCH_PLACEHOLDER: 'Search for a Ticket',
      DOWNLOAD_TICKET_FLYER: 'Download Ticket Flyer',
      TICKET_LINK: 'Ticket Link',
      TICKETS_SOLD: 'Tickets sold',
      RESCHEDULE_THIS_EVENT: 'Reschedule this event',
      YOU_CHOOSE_CANCEL_EVENT: 'You are choosing to cancel this event',
      GOFAN_REFUND_TICKETS: 'GoFan will process refunds for all event tickets',
      CANCEL_EVENT: 'Cancel event',
      CONFIRM_CANCEL_EVENT: 'Confirmation: I want to cancel this event',
      TICKET_SALES: 'Ticket sales',
      TICKET_SOLD: 'Ticket Sold',
      TYPE_OF_EVENT: 'Type of event',
      TICKET_USED: 'Ticket used',
      PERCENT_TICKET_USED: '% of tickets used',
      SEND_EMAIL_NOTIFY: 'Send notification email to ticket buyers',
      DELETE_TICKET: 'Delete ticket',
      VIEW_EVENT_TABS: ['Summary', 'Management', 'Tickets', 'Streaming', 'Statistics', 'Comp Ticket'],
      COMP_TICKETS_TAB: {
        TITLE: 'Comp Ticket',
        DESCRIPTION: 'Search for fan profile who has purchased a ticket for this event',
        RECENT_COMP_TICKETS: 'Recent Comp Tickets',
        TICKET_HOLDER_EMAIL: 'Ticket Holder Email',
        COMP_TICKETS: 'Comp tickets',
        TICKET: 'ticket',
        TICKETS: 'tickets',
        CREATE_COMP_TICKET_BTN: 'Create Comp Ticket',
        SUCCESS_NOTIFICATION: 'Comp ticket created successfully'
      },
      COMP_TICKETS_MODAL: {
        TITLE: 'Send Complimentary Tickets',
        SUBTITLE: 'Enter the email address and quantity of tickets you would like to send'
      }
    },
    Registration: {
      ACTIVITIES: [REGISTRATION],
      ALLOW_LEVEL: false,
      EVENT_GENERATE_NAME: false,
      USE_MOBILE_PASS_UI: false,
      ALLOW_GATE_OPENING_TIME: true,
      ALLOW_EVENT_PAYMENT_CYCLE: true,
      VIEW_EVENT_START_TIME: 'Event starts:',
      VIEW_EVENT_END_TIME: 'Event ends:',
      VIEW_EVENT_TIME_ZONE: 'Time zone:',
      VIEW_EVENT_TICKET_HEADER: 'Ticket prices / status',
      VIEW_TICKET_CAPACITY: 'Total tickets available:',
      EDIT_TICKET: 'Edit ticket',
      EDIT_TICKET_GENERAL_DESCRIPTION: 'Edit ticket for your event',
      EDIT_TICKET_OPTIONAL_DESCRIPTION: 'Enter optional ticket settings',
      EDIT_EVENT_DATE: 'Event date',
      EDIT_EVENT_START_DATE: 'Event start date',
      EDIT_EVENT_START_TIME: 'Event start time',
      EDIT_EVENT_END_DATE: 'Event end date',
      EDIT_EVENT_END_TIME: 'Event end time',
      EDIT_PRODUCT_NAME: 'Ticket name',
      EDIT_PRODUCT_NAME_PLACEHOLDER: 'Example: General Admission',
      EDIT_PRODUCT_PRICE: 'Ticket price',
      EDIT_EVENT_NAME: 'Event name',
      EDIT_EVENT_MAX_CAPACITY: 'Total tickets for this event',
      EDIT_EVENT_INFORMATION_SECTION: 'Event information',
      EDIT_EVENT_CANCEL_SECTION: 'Cancel this event',
      EDIT_EVENT_TICKETS_SECTION: 'Tickets for this event',
      TICKET_ON_SALE: 'Ticket on sale',
      VIEW_OPTION_SETTINGS: 'View optional ticket settings',
      SALES_START_DATE: 'Sale start date',
      SALES_START_TIME: 'Sale start time',
      SALES_END_DATE: 'Sale end date',
      SALES_END_TIME: 'Sale end time',
      TICKET_LIMIT_PER_ORDER: 'Ticket limit per order',
      TICKET_LIMIT_PER_ORDER_DESCRIPTION:
        'Set the maximum number of tickets customer can purchase for this ticket per order',
      TICKET_FEE: 'Ticket fee',
      TICKET_FEE_DESCRIPTION: 'Set the ticket fee for each ticket created',
      REDEMPTION_LIMIT: 'Ticket redemption limit',
      REDEMPTION_LIMIT_DESCRIPTION: 'Set the maximum number of uses a customer can use this ticket',
      UNLIMITED_USE: 'Unlimited use',
      PACK_COUNT: 'Family ticket',
      PACK_COUNT_DESCRIPTION: 'Set the number of tickets that are included in the family ticket',
      USING_PACK_COUNT: 'Make this a family ticket',
      REVIEW_HIDDEN_FEES: 'Attendee pays fee',
      ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the ticket name?',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the ticket price?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this ticket to “Not on sale” and then create a new ticket type.',
      FORM_TEMPLATE: [
        {
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        { name: 'last-name', text: 'Last Name', fieldType: 'STRING', displayOnTicket: true, required: true, order: 2 },
        { name: 'email', text: 'Email Address', fieldType: 'EMAIL', displayOnTicket: true, required: true, order: 3 },
        {
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        { name: 'student-id', text: 'Student Id', fieldType: 'STRING', displayOnTicket: true, required: true, order: 5 }
      ],
      COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
      INFORMATION_TYPES_TITLE: 'Information types',
      INFORMATION_TYPES_SELECT_TEXT: 'Select',
      INFORMATION_TYPES_SELECTED_TEXT: 'fields selected',
      FIELD_ON_FANS_TICKET_TITLE: 'Display these fields on fans ticket',
      CUSTOM_FIELDS_TITLE: 'Custom fields',
      CUSTOM_FIELDS_ON_TEXT: 'On',
      CUSTOM_FIELDS_OFF_TEXT: 'Off',
      FIELD_NAME_TEXT: 'Field name',
      FIELD_TYPE_TEXT: 'Field types',
      DROPDOWN_OPTION_TEXT: 'Drop-down options',
      ADD_ADDITIONAL_FIELD_TITLE_BTN: 'Add additional field',
      ADD_DROPDOWN_OPTIONS_TITLE_BTN: 'Add drop-down options',
      EDIT_DROPDOWN_OPTIONS_TITLE_BTN: 'Edit drop-down options',
      MAX_ADDITIONAL_FIELD_NUMBER: 25,
      ADD_ADDITIONAL_FIELD_WARNING_TEXT: 'Only 25 fields can be added',
      CUSTOM_FIELD_TYPE_OPTIONS: [
        {
          name: 'textInput',
          label: 'Text input',
          value: 'STRING'
        },
        {
          name: 'numberInput',
          label: 'Number input',
          value: 'NUMBER'
        },
        {
          name: 'dropdown',
          label: 'Dropdown',
          value: 'DROPDOWN'
        },
        {
          name: 'checkbox',
          label: 'Checkbox',
          value: 'CHECKBOX'
        },
        {
          name: 'email',
          label: 'Email',
          value: 'EMAIL'
        },
        {
          name: 'phoneNumber',
          label: 'Phone number',
          value: 'PHONE'
        },
        {
          name: 'textArea',
          label: 'Text area',
          value: 'TEXTBOX'
        }
      ],
      EDIT_LABEL: 'Edit Ticket',
      TICKETS_TITLE: 'Tickets',
      EVENT_TICKETS: 'Event Tickets',
      MODAL_EDIT_EVENT_NAME: 'Edit Event Name',
      TICKETS_ON_SALE: 'Tickets on Sale',
      TICKET_SALES_START: 'Ticket sales start',
      TICKET_SALES_END: 'Ticket sales end',
      TICKET_SETUP: 'Ticket Setup',
      SELECT_TICKET_TYPE: 'Select ticket type',
      ADD_TICKET: 'Add ticket',
      ADD_ACCOUNT: 'Add Opponent',
      TICKET_AVAILABILITY: 'Ticket availability',
      TOTAL_TICKET_AVAILABILITY: 'Total tickets available for this event',
      UNLIMITED_TICKETS: 'Unlimited tickets',
      SPLIT_TICKET_BETWEEN_SCHOOLS: 'Split ticket between schools',
      EVENT_TICKETS_DESCRIPTION: 'Your default tickets appear below, you may edit or add new tickets for your event',
      EVENT_NAME_TITLE: 'Event name',
      EVENT_PLACEHOLDER: 'Example: Booster Club Registration',
      SEARCH_PLACEHOLDER: 'Search for a Ticket',
      DOWNLOAD_TICKET_FLYER: 'Download Ticket Flyer',
      TICKET_LINK: 'Ticket Link',
      TICKETS_SOLD: 'Tickets sold',
      RESCHEDULE_THIS_EVENT: 'Reschedule this event',
      YOU_CHOOSE_CANCEL_EVENT: 'You are choosing to cancel this event',
      GOFAN_REFUND_TICKETS: 'GoFan will process refunds for all event tickets',
      CANCEL_EVENT: 'Cancel event',
      CONFIRM_CANCEL_EVENT: 'Confirmation: I want to cancel this event',
      TICKET_SALES: 'Ticket sales',
      TICKET_SOLD: 'Ticket Sold',
      TYPE_OF_EVENT: 'Type of event',
      TICKET_USED: 'Ticket used',
      PERCENT_TICKET_USED: '% of tickets used',
      SEND_EMAIL_NOTIFY: 'Send notification email to ticket buyers',
      DELETE_TICKET: 'Delete ticket',
      VIEW_EVENT_TABS: ['Summary', 'Management', 'Tickets', 'Streaming', 'Statistics', 'Comp Ticket'],
      COMP_TICKETS_TAB: {
        TITLE: 'Comp Ticket',
        DESCRIPTION: 'Search for fan profile who has purchased a ticket for this event',
        RECENT_COMP_TICKETS: 'Recent Comp Tickets',
        TICKET_HOLDER_EMAIL: 'Ticket Holder Email',
        COMP_TICKETS: 'Comp tickets',
        TICKET: 'ticket',
        TICKETS: 'tickets',
        CREATE_COMP_TICKET_BTN: 'Create Comp Ticket',
        SUCCESS_NOTIFICATION: 'Comp ticket created successfully'
      },
      COMP_TICKETS_MODAL: {
        TITLE: 'Send Complimentary Tickets',
        SUBTITLE: 'Enter the email address and quantity of tickets you would like to send'
      }
    },
    [SCHOOL_DANCE]: {
      ACTIVITIES: [SCHOOL_DANCE, PROM],
      ALLOW_LEVEL: false,
      EVENT_GENERATE_NAME: false,
      USE_MOBILE_PASS_UI: false,
      ALLOW_GATE_OPENING_TIME: true,
      ALLOW_EVENT_PAYMENT_CYCLE: true,
      VIEW_EVENT_START_TIME: 'Event starts:',
      VIEW_EVENT_END_TIME: 'Event ends:',
      VIEW_EVENT_TIME_ZONE: 'Time zone:',
      VIEW_EVENT_TICKET_HEADER: 'Ticket prices / status',
      VIEW_TICKET_CAPACITY: 'Total tickets available:',
      EDIT_TICKET: 'Edit ticket',
      EDIT_TICKET_GENERAL_DESCRIPTION: 'Edit ticket for your event',
      EDIT_TICKET_OPTIONAL_DESCRIPTION: 'Enter optional ticket settings',
      EDIT_EVENT_DATE: 'Event date',
      EDIT_EVENT_START_DATE: 'Event start date',
      EDIT_EVENT_START_TIME: 'Event start time',
      EDIT_EVENT_END_DATE: 'Event end date',
      EDIT_EVENT_END_TIME: 'Event end time',
      EDIT_PRODUCT_NAME: 'Ticket name',
      EDIT_PRODUCT_NAME_PLACEHOLDER: 'Example: General Admission',
      EDIT_PRODUCT_PRICE: 'Ticket price',
      EDIT_EVENT_NAME: 'Event name',
      EDIT_EVENT_MAX_CAPACITY: 'Total tickets for this event',
      EDIT_EVENT_INFORMATION_SECTION: 'Event information',
      EDIT_EVENT_CANCEL_SECTION: 'Cancel this event',
      EDIT_EVENT_TICKETS_SECTION: 'Tickets for this event',
      TICKET_ON_SALE: 'Ticket on sale',
      VIEW_OPTION_SETTINGS: 'View optional ticket settings',
      SALES_START_DATE: 'Sale start date',
      SALES_START_TIME: 'Sale start time',
      SALES_END_DATE: 'Sale end date',
      SALES_END_TIME: 'Sale end time',
      TICKET_LIMIT_PER_ORDER: 'Ticket limit per order',
      TICKET_LIMIT_PER_ORDER_DESCRIPTION:
        'Set the maximum number of tickets customer can purchase for this ticket per order',
      TICKET_FEE: 'Ticket fee',
      TICKET_FEE_DESCRIPTION: 'Set the ticket fee for each ticket created',
      REDEMPTION_LIMIT: 'Ticket redemption limit',
      REDEMPTION_LIMIT_DESCRIPTION: 'Set the maximum number of uses a customer can use this ticket',
      UNLIMITED_USE: 'Unlimited use',
      PACK_COUNT: 'Family ticket',
      PACK_COUNT_DESCRIPTION: 'Set the number of tickets that are included in the family ticket',
      USING_PACK_COUNT: 'Make this a family ticket',
      REVIEW_HIDDEN_FEES: 'Attendee pays fee',
      ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the ticket name?',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the ticket price?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this ticket to “Not on sale” and then create a new ticket type.',
      FORM_TEMPLATE: [
        {
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        { name: 'last-name', text: 'Last Name', fieldType: 'STRING', displayOnTicket: true, required: true, order: 2 },
        {
          name: 'date-first-name',
          text: 'Date First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 3
        },
        {
          name: 'date-last-name',
          text: 'Date Last Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        { name: 'email', text: 'Email Address', fieldType: 'EMAIL', displayOnTicket: true, required: true, order: 5 },
        {
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 6
        },
        {
          name: 'student-id',
          text: 'Student Id',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 7
        },
        {
          name: 'date-student-id',
          text: 'Date Student Id',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 8
        }
      ],
      COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
      INFORMATION_TYPES_TITLE: 'Information types',
      INFORMATION_TYPES_SELECT_TEXT: 'Select',
      INFORMATION_TYPES_SELECTED_TEXT: 'fields selected',
      FIELD_ON_FANS_TICKET_TITLE: 'Display these fields on fans ticket',
      CUSTOM_FIELDS_TITLE: 'Custom fields',
      CUSTOM_FIELDS_ON_TEXT: 'On',
      CUSTOM_FIELDS_OFF_TEXT: 'Off',
      FIELD_NAME_TEXT: 'Field name',
      FIELD_TYPE_TEXT: 'Field types',
      DROPDOWN_OPTION_TEXT: 'Drop-down options',
      ADD_ADDITIONAL_FIELD_TITLE_BTN: 'Add additional field',
      ADD_DROPDOWN_OPTIONS_TITLE_BTN: 'Add drop-down options',
      EDIT_DROPDOWN_OPTIONS_TITLE_BTN: 'Edit drop-down options',
      MAX_ADDITIONAL_FIELD_NUMBER: 25,
      ADD_ADDITIONAL_FIELD_WARNING_TEXT: 'Only 25 fields can be added',
      CUSTOM_FIELD_TYPE_OPTIONS: [
        {
          name: 'textInput',
          label: 'Text input',
          value: 'STRING'
        },
        {
          name: 'numberInput',
          label: 'Number input',
          value: 'NUMBER'
        },
        {
          name: 'dropdown',
          label: 'Dropdown',
          value: 'DROPDOWN'
        },
        {
          name: 'checkbox',
          label: 'Checkbox',
          value: 'CHECKBOX'
        },
        {
          name: 'email',
          label: 'Email',
          value: 'EMAIL'
        },
        {
          name: 'phoneNumber',
          label: 'Phone number',
          value: 'PHONE'
        },
        {
          name: 'textArea',
          label: 'Text area',
          value: 'TEXTBOX'
        }
      ],
      EDIT_LABEL: 'Edit Ticket',
      TICKETS_TITLE: 'Tickets',
      EVENT_TICKETS: 'Event Tickets',
      MODAL_EDIT_EVENT_NAME: 'Edit Event Name',
      TICKETS_ON_SALE: 'Tickets on Sale',
      TICKET_SALES_START: 'Ticket sales start',
      TICKET_SALES_END: 'Ticket sales end',
      TICKET_SETUP: 'Ticket Setup',
      SELECT_TICKET_TYPE: 'Select ticket type',
      ADD_TICKET: 'Add ticket',
      ADD_ACCOUNT: 'Add Opponent',
      TICKET_AVAILABILITY: 'Ticket availability',
      TOTAL_TICKET_AVAILABILITY: 'Total tickets available for this event',
      UNLIMITED_TICKETS: 'Unlimited tickets',
      SPLIT_TICKET_BETWEEN_SCHOOLS: 'Split ticket between schools',
      EVENT_TICKETS_DESCRIPTION: 'Your default tickets appear below, you may edit or add new tickets for your event',
      EVENT_NAME_TITLE: 'Event name',
      EVENT_PLACEHOLDER: 'Example: Homecoming Dance - A Night in Paris',
      SEARCH_PLACEHOLDER: 'Search for a Ticket',
      DOWNLOAD_TICKET_FLYER: 'Download Ticket Flyer',
      TICKET_LINK: 'Ticket Link',
      TICKETS_SOLD: 'Tickets sold',
      RESCHEDULE_THIS_EVENT: 'Reschedule this event',
      YOU_CHOOSE_CANCEL_EVENT: 'You are choosing to cancel this event',
      GOFAN_REFUND_TICKETS: 'GoFan will process refunds for all event tickets',
      CANCEL_EVENT: 'Cancel event',
      CONFIRM_CANCEL_EVENT: 'Confirmation: I want to cancel this event',
      TICKET_SALES: 'Ticket sales',
      TICKET_SOLD: 'Ticket Sold',
      TYPE_OF_EVENT: 'Type of event',
      TICKET_USED: 'Ticket used',
      PERCENT_TICKET_USED: '% of tickets used',
      SEND_EMAIL_NOTIFY: 'Send notification email to ticket buyers',
      DELETE_TICKET: 'Delete ticket',
      VIEW_EVENT_TABS: ['Summary', 'Management', 'Tickets', 'Streaming', 'Statistics', 'Comp Ticket'],
      COMP_TICKETS_TAB: {
        TITLE: 'Comp Ticket',
        DESCRIPTION: 'Search for fan profile who has purchased a ticket for this event',
        RECENT_COMP_TICKETS: 'Recent Comp Tickets',
        TICKET_HOLDER_EMAIL: 'Ticket Holder Email',
        COMP_TICKETS: 'Comp tickets',
        TICKET: 'ticket',
        TICKETS: 'tickets',
        CREATE_COMP_TICKET_BTN: 'Create Comp Ticket',
        SUCCESS_NOTIFICATION: 'Comp ticket created successfully'
      },
      COMP_TICKETS_MODAL: {
        TITLE: 'Send Complimentary Tickets',
        SUBTITLE: 'Enter the email address and quantity of tickets you would like to send'
      }
    },
    [MOBILE_PASS]: {
      ALLOW_LEVEL: true,
      EVENT_GENERATE_NAME: true,
      USE_MOBILE_PASS_UI: true,
      ALLOW_GATE_OPENING_TIME: false,
      ALLOW_EVENT_PAYMENT_CYCLE: true,
      VIEW_EVENT_START_TIME: 'Pass starts:',
      VIEW_EVENT_END_TIME: 'Pass ends:',
      VIEW_EVENT_TIME_ZONE: 'Time zone:',
      VIEW_EVENT_TICKET_HEADER: 'Pass prices / status',
      VIEW_TICKET_CAPACITY: 'Total passes available:',
      EDIT_TICKET: 'Edit pass',
      EDIT_TICKET_GENERAL_DESCRIPTION: 'Edit pass for your event',
      EDIT_TICKET_OPTIONAL_DESCRIPTION: 'Enter optional pass settings',
      EDIT_EVENT_DATE: 'Pass date',
      EDIT_EVENT_START_DATE: 'Pass start date',
      EDIT_EVENT_START_TIME: 'Pass start time',
      EDIT_EVENT_END_DATE: 'Pass end date',
      EDIT_EVENT_END_TIME: 'Pass end time',
      EDIT_PRODUCT_NAME: 'Pass name',
      EDIT_PRODUCT_NAME_PLACEHOLDER: 'Example: General Admission',
      EDIT_PRODUCT_PRICE: 'Pass price',
      EDIT_EVENT_NAME: 'Pass name',
      EDIT_EVENT_MAX_CAPACITY: 'Total passes for this event',
      EDIT_EVENT_INFORMATION_SECTION: 'Pass information',
      EDIT_EVENT_CANCEL_SECTION: 'Cancel this pass',
      EDIT_EVENT_TICKETS_SECTION: 'Passes for this event',
      TICKET_ON_SALE: 'Pass on sale',
      VIEW_OPTION_SETTINGS: 'View optional pass settings',
      SALES_START_DATE: 'Sale start date',
      SALES_START_TIME: 'Sale start time',
      SALES_END_DATE: 'Sale end date',
      SALES_END_TIME: 'Sale end time',
      TICKET_LIMIT_PER_ORDER: 'Pass limit per order',
      TICKET_LIMIT_PER_ORDER_DESCRIPTION:
        'Set the maximum number of passes customer can purchase for this pass per order',
      TICKET_FEE: 'Pass fee',
      TICKET_FEE_DESCRIPTION: 'Set the pass fee for each pass created',
      REDEMPTION_LIMIT: 'Pass redemption limit',
      REDEMPTION_LIMIT_DESCRIPTION: 'Set the maximum number of uses a customer can use this pass',
      UNLIMITED_USE: 'Unlimited use',
      PACK_COUNT: 'Family pass',
      PACK_COUNT_DESCRIPTION: 'Set the number of passes that are included in the family pass',
      USING_PACK_COUNT: 'Make this a family pass',
      REVIEW_HIDDEN_FEES: 'Attendee pays fee',
      ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the pass name?',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the pass price?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this pass to “Not on sale” and then create a new pass type.',
      FORM_TEMPLATE: [
        {
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        { name: 'last-name', text: 'Last Name', fieldType: 'STRING', displayOnTicket: true, required: true, order: 2 },
        { name: 'email', text: 'Email Address', fieldType: 'EMAIL', displayOnTicket: true, required: true, order: 3 },
        {
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        { name: 'student-id', text: 'Student Id', fieldType: 'STRING', displayOnTicket: true, required: true, order: 5 }
      ],
      EDIT_LABEL: 'Edit Passes',
      COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
      INFORMATION_TYPES_TITLE: 'Information types',
      INFORMATION_TYPES_SELECT_TEXT: 'Select',
      INFORMATION_TYPES_SELECTED_TEXT: 'fields selected',
      FIELD_ON_FANS_TICKET_TITLE: 'Display these fields on fans ticket',
      CUSTOM_FIELDS_TITLE: 'Custom fields',
      CUSTOM_FIELDS_ON_TEXT: 'On',
      CUSTOM_FIELDS_OFF_TEXT: 'Off',
      FIELD_NAME_TEXT: 'Field name',
      FIELD_TYPE_TEXT: 'Field types',
      DROPDOWN_OPTION_TEXT: 'Drop-down options',
      ADD_ADDITIONAL_FIELD_TITLE_BTN: 'Add additional field',
      ADD_DROPDOWN_OPTIONS_TITLE_BTN: 'Add drop-down options',
      EDIT_DROPDOWN_OPTIONS_TITLE_BTN: 'Edit drop-down options',
      MAX_ADDITIONAL_FIELD_NUMBER: 25,
      ADD_ADDITIONAL_FIELD_WARNING_TEXT: 'Only 25 fields can be added',
      CUSTOM_FIELD_TYPE_OPTIONS: [
        {
          name: 'textInput',
          label: 'Text input',
          value: 'STRING'
        },
        {
          name: 'numberInput',
          label: 'Number input',
          value: 'NUMBER'
        },
        {
          name: 'dropdown',
          label: 'Dropdown',
          value: 'DROPDOWN'
        },
        {
          name: 'checkbox',
          label: 'Checkbox',
          value: 'CHECKBOX'
        },
        {
          name: 'email',
          label: 'Email',
          value: 'EMAIL'
        },
        {
          name: 'phoneNumber',
          label: 'Phone number',
          value: 'PHONE'
        },
        {
          name: 'textArea',
          label: 'Text area',
          value: 'TEXTBOX'
        }
      ],
      TICKETS_TITLE: 'Mobile Passes',
      EVENT_TICKETS: 'Mobile Passes',
      MODAL_EDIT_EVENT_NAME: 'Edit pass name',
      TICKETS_ON_SALE: 'Pass on Sale',
      TICKET_SALES_START: 'Pass sales start',
      TICKET_SALES_END: 'Pass sales end',
      TICKET_SETUP: 'Pass Setup',
      SELECT_TICKET_TYPE: 'Select pass type',
      ADD_TICKET: 'Add pass',
      ADD_ACCOUNT: 'Add Opponent',
      TICKET_AVAILABILITY: 'Pass availability',
      TOTAL_TICKET_AVAILABILITY: 'Total passes available for this event',
      UNLIMITED_TICKETS: 'Unlimited passes',
      SPLIT_TICKET_BETWEEN_SCHOOLS: 'Split pass between schools',
      EVENT_TICKETS_DESCRIPTION: 'Your default passes appear below, you may edit or add new passes for your event',
      EVENT_NAME_TITLE: 'Pass name',
      EVENT_PLACEHOLDER: 'Example: All-sports pass',
      SEARCH_PLACEHOLDER: 'Search for a Pass',
      DOWNLOAD_TICKET_FLYER: 'Download Pass Flyer',
      TICKET_LINK: 'Pass Link',
      TICKETS_SOLD: 'Passes sold',
      RESCHEDULE_THIS_EVENT: 'Reschedule this event',
      YOU_CHOOSE_CANCEL_EVENT: 'You are choosing to cancel this event',
      GOFAN_REFUND_TICKETS: 'GoFan will process refunds for all event passes',
      CANCEL_EVENT: 'Cancel event',
      CONFIRM_CANCEL_EVENT: 'Confirmation: I want to cancel this event',
      TICKET_SALES: 'Pass sales',
      TICKET_SOLD: 'Pass Sold',
      TYPE_OF_EVENT: 'Pass of event',
      TICKET_USED: 'Pass used',
      PERCENT_TICKET_USED: '% of passes used',
      SEND_EMAIL_NOTIFY: 'Send notification email to pass buyers',
      DELETE_TICKET: 'Delete pass',
      VIEW_EVENT_TABS: ['Summary', 'Management', 'Passes', 'Streaming', 'Statistics', 'Comp Pass'],
      COMP_TICKETS_TAB: {
        TITLE: 'Comp Pass',
        DESCRIPTION: 'Search for fan profile who has purchased a pass for this mobile pass',
        RECENT_COMP_TICKETS: 'Recent Comp Passes',
        TICKET_HOLDER_EMAIL: 'Pass Holder Email',
        COMP_TICKETS: 'Comp passes',
        TICKET: 'pass',
        TICKETS: 'passes',
        CREATE_COMP_TICKET_BTN: 'Create Comp Pass',
        SUCCESS_NOTIFICATION: 'Comp pass created successfully'
      },
      COMP_TICKETS_MODAL: {
        TITLE: 'Send Complimentary Passes',
        SUBTITLE:
          'Enter the email address, first name, last name and quantity of complimentary passes you’d like to send'
      }
    },
    [FUNDRAISER]: {
      ALLOW_LEVEL: true,
      EVENT_GENERATE_NAME: true,
      USE_MOBILE_PASS_UI: false,
      ALLOW_GATE_OPENING_TIME: true,
      ALLOW_EVENT_PAYMENT_CYCLE: false,
      VIEW_EVENT_START_TIME: 'Fundraiser starts:',
      VIEW_EVENT_END_TIME: 'Fundraiser ends:',
      VIEW_EVENT_TIME_ZONE: 'Time zone:',
      VIEW_EVENT_TICKET_HEADER: 'Donation level / status',
      VIEW_TICKET_CAPACITY: 'Total tickets available:',
      EDIT_TICKET: 'Edit donation',
      EDIT_TICKET_GENERAL_DESCRIPTION: 'Edit donation level for your fundraiser',
      EDIT_TICKET_OPTIONAL_DESCRIPTION: 'Enter optional donation level settings',
      EDIT_EVENT_DATE: 'Event date',
      EDIT_EVENT_START_DATE: 'Start date',
      EDIT_EVENT_START_TIME: 'Start time',
      EDIT_EVENT_END_DATE: 'End date',
      EDIT_EVENT_END_TIME: 'End time',
      EDIT_PRODUCT_NAME: 'Donation level',
      EDIT_PRODUCT_NAME_PLACEHOLDER: 'Bronze Donation',
      EDIT_PRODUCT_PRICE: 'Donation price',
      EDIT_EVENT_NAME: 'Fundraiser name',
      EDIT_EVENT_MAX_CAPACITY: 'Total tickets for this event',
      EDIT_EVENT_INFORMATION_SECTION: 'Event information',
      EDIT_EVENT_CANCEL_SECTION: 'Cancel this fundraiser',
      EDIT_EVENT_TICKETS_SECTION: 'Donation levels for this fundraiser',
      TICKET_ON_SALE: 'Donation level on sale',
      VIEW_OPTION_SETTINGS: 'View optional donation level settings',
      SALES_START_DATE: 'Sale start date',
      SALES_START_TIME: 'Sale start time',
      SALES_END_DATE: 'Sale end date',
      SALES_END_TIME: 'Sale end time',
      TICKET_LIMIT_PER_ORDER: 'Ticket limit per order',
      TICKET_LIMIT_PER_ORDER_DESCRIPTION:
        'Set the maximum number of donation levels customer can purchase for this donation level per order',
      TICKET_FEE: 'Donation level fee',
      TICKET_FEE_DESCRIPTION: 'Set the donation level fee for each donation level created',
      REDEMPTION_LIMIT: 'Donation level redemption limit',
      REDEMPTION_LIMIT_DESCRIPTION: 'Set the maximum number of uses a customer can use this donation level',
      UNLIMITED_USE: 'Unlimited use',
      PACK_COUNT: 'Family donation level',
      PACK_COUNT_DESCRIPTION: 'Set the number of donation level that are included in the family donation level',
      USING_PACK_COUNT: 'Make this a family donation level',
      REVIEW_HIDDEN_FEES: 'Donor pays fee',
      ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the donation name?',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the donation price?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this donation level to “Not on sale” and then create a new donation level.',
      FORM_TEMPLATE: [
        {
          id: 'first-name',
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        {
          id: 'last-name',
          name: 'last-name',
          text: 'Last Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 2
        },
        {
          id: 'email',
          name: 'email',
          text: 'Email Address',
          fieldType: 'EMAIL',
          displayOnTicket: true,
          required: true,
          order: 3
        },
        {
          id: 'phone-number',
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        {
          id: 'student-id',
          name: 'student-id',
          text: 'Student Id',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 5
        }
      ],
      EDIT_EVENT_GOAL: 'Fundraiser goal',
      EDIT_EVENT_OPTIONAL_INFORMATION_SECTION: 'Fundraiser information',
      EVENT_VISIBILITY_TITLE: 'Fundraiser Visibility',
      EVENT_VISIBILITY_TOGGLE_TEXT: `Hide this event from school's GoFan page`,
      EVENT_VISIBILITY_UNTOGGLE_TEXT: `Hide this event from school's GoFan page`,
      EVENT_VISIBILITY_INFO_TEXT: `Automatically publish this event to the school's GoFan page on:`,
      COLLECT_ADDITIONAL_INFO_TYPE_TITLE: 'Collect addition information types (Optional)',
      COLLECT_ADDITIONAL_INFO_SELECTED_TEXT: 'info types selected',
      COLLECT_ADDITIONAL_INFO_SELECT_TEXT: 'Select',
      COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
      INFORMATION_TYPES_TITLE: 'Information types',
      INFORMATION_TYPES_SELECT_TEXT: 'Select',
      INFORMATION_TYPES_SELECTED_TEXT: 'fields selected',
      FIELD_ON_FANS_TICKET_TITLE: 'Display these fields on fans ticket',
      CUSTOM_FIELDS_TITLE: 'Custom fields',
      CUSTOM_FIELDS_ON_TEXT: 'On',
      CUSTOM_FIELDS_OFF_TEXT: 'Off',
      FIELD_NAME_TEXT: 'Field name',
      FIELD_TYPE_TEXT: 'Field types',
      DROPDOWN_OPTION_TEXT: 'Drop-down options',
      ADD_ADDITIONAL_FIELD_TITLE_BTN: 'Add additional field',
      ADD_DROPDOWN_OPTIONS_TITLE_BTN: 'Add drop-down options',
      EDIT_DROPDOWN_OPTIONS_TITLE_BTN: 'Edit drop-down options',
      MAX_ADDITIONAL_FIELD_NUMBER: 25,
      ADD_ADDITIONAL_FIELD_WARNING_TEXT: 'Only 25 fields can be added',
      CUSTOM_FIELD_TYPE_OPTIONS: [
        {
          name: 'textInput',
          label: 'Text input',
          value: 'STRING'
        },
        {
          name: 'numberInput',
          label: 'Number input',
          value: 'NUMBER'
        },
        {
          name: 'dropdown',
          label: 'Dropdown',
          value: 'DROPDOWN'
        },
        {
          name: 'checkbox',
          label: 'Checkbox',
          value: 'CHECKBOX'
        },
        {
          name: 'email',
          label: 'Email',
          value: 'EMAIL'
        },
        {
          name: 'phoneNumber',
          label: 'Phone number',
          value: 'PHONE'
        },
        {
          name: 'textArea',
          label: 'Text area',
          value: 'TEXTBOX'
        }
      ],
      EDIT_LABEL: 'Edit Donation level',
      TICKETS_TITLE: 'Tickets',
      EVENT_TICKETS: 'Event Tickets',
      MODAL_EDIT_EVENT_NAME: 'Edit Event Name',
      TICKETS_ON_SALE: 'Tickets on Sale',
      TICKET_SALES_START: 'Ticket sales start',
      TICKET_SALES_END: 'Ticket sales end',
      TICKET_SETUP: 'Ticket Setup',
      SELECT_TICKET_TYPE: 'Select ticket type',
      ADD_TICKET: 'Add ticket',
      ADD_ACCOUNT: 'Add Opponent',
      TICKET_AVAILABILITY: 'Ticket availability',
      TOTAL_TICKET_AVAILABILITY: 'Total tickets available for this event',
      UNLIMITED_TICKETS: 'Unlimited tickets',
      SPLIT_TICKET_BETWEEN_SCHOOLS: 'Split ticket between schools',
      EVENT_TICKETS_DESCRIPTION: 'Your default tickets appear below, you may edit or add new tickets for your event',
      EVENT_NAME_TITLE: 'Name',
      EVENT_PLACEHOLDER: 'Example: Food Drive Night',
      VIEW_EVENT_TABS: ['Summary', 'Management', 'Donations', 'Statistics'],
      SEARCH_PLACEHOLDER: 'Search for a Donation level',
      DOWNLOAD_TICKET_FLYER: 'Download Flyer',
      TICKET_LINK: 'Fundraiser Link',
      TICKETS_SOLD: 'Donations sold',
      RESCHEDULE_THIS_EVENT: 'Reschedule this fundraiser',
      YOU_CHOOSE_CANCEL_EVENT: 'You are choosing to cancel this fundraiser',
      GOFAN_REFUND_TICKETS: 'GoFan will process refunds for all fundraiser tickets',
      CANCEL_EVENT: 'Cancel fundraiser',
      CONFIRM_CANCEL_EVENT: 'Confirmation: I want to cancel this fundraiser',
      TICKET_SALES: 'Donation sales',
      TICKET_SOLD: 'Donation Sold',
      TYPE_OF_EVENT: 'Type of fundraiser',
      TICKET_USED: 'Donation used',
      PERCENT_TICKET_USED: '% of donations used',
      SEND_EMAIL_NOTIFY: 'Send notification email to donation buyers',
      DELETE_TICKET: 'Delete donation'
    },
    [CONCESSION]: {
      ACTIVITY_TITLE: 'Sport',
      ALLOW_LEVEL: true,
      ADDITIONAL_INFORMATION: 'Additional info',
      EVENT_PLACEHOLDER: 'Example: Football Spring Concession',
      ALLOW_EVENT_DESCRIPTION: false,
      EVENT_GENERATE_NAME: true,
      SCHOOL_ROLES: DEFAULT_ROLES,
      ALLOW_THEME: false,
      ALLOW_CUSTOM_SPORT_NAME: false,
      TICKET_DISTRIBUTION: false,
      IGNORE_FIELDS: [],
      PAGE_TITLE: 'Create concession',
      ALLOW_SEARCH_SCHOOL: true,
      EDIT_TICKET: 'Edit item',
      EDIT_TICKET_GENERAL_DESCRIPTION: 'Edit concession for your event',
      EDIT_TICKET_OPTIONAL_DESCRIPTION: 'Enter concession settings',
      EDIT_PRODUCT_NAME: 'Concession item',
      EDIT_PRODUCT_NAME_PLACEHOLDER: 'Popcorn',
      EDIT_EVENT_CANCEL_SECTION: 'Cancel this concession',
      DATE_TIME_TITLE: 'Concession date and time',
      EVENT_DATE_LABEL: 'Concession date',
      START_DATE_LABEL: 'Concession start date',
      START_TIME_LABEL: 'Concession start time',
      END_DATE_LABEL: 'Concession end date',
      END_TIME_LABEL: 'Concession end time',
      DATE_TIME_INFO: 'Fans can purchase tickets and use them until this date and time:',
      EVENT_NAME_TITLE: 'Add a name for your concession',
      ALLOW_GATE_OPENING_TIME: true,
      ALLOW_REDEMTION_WINDOW: false,
      ALLOW_EVENT_PAYMENT_CYCLE: false,
      EVENT_VISIBILITY_TITLE: 'Concession visibility',
      EVENT_VISIBILITY_TOGGLE_TEXT: 'Hide this concession on your GoFan page',
      EVENT_VISIBILITY_UNTOGGLE_TEXT: 'Hide this concession on your GoFan page',
      EVENT_VISIBILITY_INFO_TEXT: "Automatically publish this concession to the school's GoFan page on:",
      EVENT_SCAN_ENABLED_TITLE: 'Scan using Box Office',
      EVENT_SCAN_ENABLED_TOGGLE_TEXT:
        'Enabling this option confirms that you will be able to scan fan’s tickets at the gate of this event',
      EVENT_SCAN_ENABLED_UNTOGGLE_TEXT:
        'Enabling this option confirms that you will be able to scan fan’s tickets at the gate of this event',
      EVENT_ON_SALE_TITLE: 'Tickets on sale',
      EVENT_ON_SALE_TOGGLE_TEXT: 'Tickets go on sale immediately when this event is added',
      EVENT_ALERT_TITLE: 'Event alert',
      EVENT_ALERT_INFO_TEXT: 'Add an alert or special information to the top of your event page',
      ALLOW_DESCRIPTION: true,
      PIN_TO_TOP_LABEL: 'Pin this concession to the top of your GoFan page',
      BUYER_PURCHASE_LIMIT_INFO: 'Set the maximum number of tickets a customer can purchase per order',
      TICKET_AVAILABILITY_LABEL: 'Total tickets available for this event',
      TICKET_AVAILABILITY_UNLIMITED_TEXT: 'Unlimited tickets',
      TICKET_TYPE_TITLE: 'Concession item',
      TICKET_TYPE_DESCRIPTION: 'The concession item is the set amount a donor wishes to contribute',
      TICKET_CREATION_HEADING: 'Ticket creation',
      ADD_TICKET_TYPE_LABEL: 'Add concession item',
      TICKET_TYPE_GF_COUNT: 'GoFan Items',
      TICKET_TYPE_BO_COUNT: 'Box Office Items',
      TICKET_TYPE_NOTE: '* Fees are not included in concession prices',
      TICKET_TYPE_ITEM_NAME_LABEL: 'Concession item',
      TICKET_TYPE_ITEM_NAME_PLACEHOLDER: 'Popcorn',
      TICKET_TYPE_COLOR_TOOLTIP: 'Assign color to concession item',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the concession price',
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the concession name?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this concession item to “Not on sale” and then create a new concession item.',
      REVIEW_EVENT_START: 'Event start',
      REVIEW_EVENT_END: 'Event end',
      REVIEW_TIME_ZONE: 'Time zone',
      REVIEW_TICKET_TYPE: 'Ticket type',
      REVIEW_TICKET_PRICE: 'Ticket price',
      REVIEW_UNIQUE_TICKET_LINK: 'Hidden link',
      REVIEW_HIDDEN_FEES: 'Donor pays fee',
      ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
      REVIEW_TICKET_SALES_START: 'Ticket sales begin',
      REVIEW_TICKET_SALES_END: 'Ticket sales end',
      REVIEW_TICKET_FEE: 'Ticket fee',
      REVIEW_TICKET_LIMIT_PER_ORDER: 'Ticket limit per order',
      REVIEW_TICKET_REDEMPTION_LIMIT: 'Ticket redemption limit',
      REVIEW_TICKET_PACK_COUNT: 'Family pass',
      REVIEW_TICKET_FEE_INFOR: 'Fees are not included in the ticket prices',
      USE_MOBILE_PASS_UI: false,
      ALLOW_REVIEW_REDEMPTION_WINDOW: false,
      ALLOW_REMOVE_SCHOOL: true,
      TICKET_AVAILABILITY_TITLE: 'Ticket availability',
      ALLOW_REVIEW_GATE_OPEN: false,
      CREATE_TICKET_TYPE: 'Create Items',
      RETURN_EVENT_CREATION: 'Return to concession creation',
      SELECT_TICKET_TYPE: 'Select the concession item',
      NO_TICKET_TYPE: 'No concession items yet',
      COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
      COLLECT_ADDITIONAL_INFO_SUBTITLE:
        'Collect information that attendees must complete when purchasing a concession item for this concession',
      COLLECT_ADDITIONAL_INFO_TYPE_TITLE: 'Information types (Optional)',
      COLLECT_ADDITIONAL_INFO_SELECTED_TEXT: 'info types selected',
      COLLECT_ADDITIONAL_INFO_SELECT_TEXT: 'Select',
      CUSTOM_FORM_DESCRIPTION:
        'Collect additional information that attendees must complete when purchasing a concession item for this concession',
      FORM_TEMPLATE: [
        {
          id: 'first-name',
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        {
          id: 'last-name',
          name: 'last-name',
          text: 'Last Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 2
        },
        {
          id: 'email',
          name: 'email',
          text: 'Email Address',
          fieldType: 'EMAIL',
          displayOnTicket: true,
          required: true,
          order: 3
        },
        {
          id: 'phone-number',
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        {
          id: 'student-id',
          name: 'student-id',
          text: 'Student Id',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 5
        }
      ],
      CUSTOM_DESCRIPTION_TITLE: 'Descriptions',
      CUSTOM_DESCRIPTION_SUBTITLE: 'Create a meaningful description and visual for your campaign',
      ALLOW_EXAMPLE_DESCRIPTION: true,
      COLLECT_ADDITIONAL_INFO_DIRECTION: 'top',
      CREATE_TICKET_MODAL: {
        ADD: {
          MODAL_TITLE: 'Create concession item',
          SECONDARY_BTN: 'Cancel'
        },
        UPDATE: {
          MODAL_TITLE: 'Edit concession item',
          SECONDARY_BTN: 'Cancel'
        },
        SAVE_AND_CLOSE: 'Save and close',
        SAVE_AND_ADD_ANOTHER_TICKET: 'Save and add another concession item',
        TICKET_UPDATE_SUCCESS: 'Concession item successfully updated!!',
        TICKET_NAME: 'Concession item',
        TICKET_NAME_PLACEHOLDER: 'Example: Popcorn',
        SETTINGS: 'Settings and price',
        ATTENDEE_PAYS_FEE: 'Attendee pays the fee',
        ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
        TOGGLE_ON: 'Yes',
        TOGGLE_OFF: 'No',
        GENERATE_LINK: 'Create unique URL',
        TICKET_PRICE: 'Price',
        CURRENCY_PLACEHOLDER: '$0.00',
        ASSIGN_COLOR: 'Assign concession item color',
        ASSIGN_COLOR_DESCRIPTION: 'Concession item color displays on the attendee’s mobile device'
      },
      PREVIEW_FIELD_DESCRIPTION:
        'Preview additional information that attendees must complete when purchasing a concession item for this concession.',
      CREATE_LABEL: 'Create Concession',
      EDIT_LABEL: 'Edit Concession',
      YOUR_SETTINGS: 'Your Concession Settings',
      SCAN_ENABLED_LABEL: 'Enable Box Office',
      EDIT_EVENT_DATE: 'Concession date',
      EDIT_EVENT_START_DATE: 'Concession start date',
      EDIT_EVENT_START_TIME: 'Concession start time',
      EDIT_EVENT_END_DATE: 'Concession end date',
      EDIT_EVENT_END_TIME: 'Concession end time',
      VIEW_EVENT_TABS: ['Summary', 'Management', 'Items', 'Statistics'],
      SEARCH_PLACEHOLDER: 'Search for a Item',
      DOWNLOAD_TICKET_FLYER: 'Download Item Flyer',
      TICKET_LINK: 'Item Link',
      TICKETS_SOLD: 'Items sold',
      RESCHEDULE_THIS_EVENT: 'Reschedule this concession',
      YOU_CHOOSE_CANCEL_EVENT: 'You are choosing to cancel this concession',
      GOFAN_REFUND_TICKETS: 'GoFan will process refunds for all concession items',
      CANCEL_EVENT: 'Cancel concession',
      CONFIRM_CANCEL_EVENT: 'Confirmation: I want to cancel this concession',
      TICKET_SALES: 'Concession sales',
      TICKET_SOLD: 'Items Sold',
      TYPE_OF_EVENT: 'Type of concession',
      TICKET_USED: 'Item used',
      PERCENT_TICKET_USED: '% of items used',
      SEND_EMAIL_NOTIFY: 'Send notification email to item buyers',
      DELETE_TICKET: 'Delete item'
    },
    Other: {
      ALLOW_LEVEL: true,
      EVENT_GENERATE_NAME: true,
      USE_MOBILE_PASS_UI: false,
      ALLOW_GATE_OPENING_TIME: true,
      ALLOW_EVENT_PAYMENT_CYCLE: true,
      VIEW_EVENT_START_TIME: 'Event starts:',
      VIEW_EVENT_END_TIME: 'Event ends:',
      VIEW_EVENT_TIME_ZONE: 'Time zone:',
      VIEW_EVENT_TICKET_HEADER: 'Ticket prices / status',
      VIEW_TICKET_CAPACITY: 'Total tickets available:',
      EDIT_TICKET: 'Edit ticket',
      EDIT_TICKET_GENERAL_DESCRIPTION: 'Edit ticket for your event',
      EDIT_TICKET_OPTIONAL_DESCRIPTION: 'Enter optional ticket settings',
      EDIT_EVENT_DATE: 'Event date',
      EDIT_EVENT_START_DATE: 'Event start date',
      EDIT_EVENT_START_TIME: 'Event start time',
      EDIT_EVENT_END_DATE: 'Event end date',
      EDIT_EVENT_END_TIME: 'Event end time',
      EDIT_PRODUCT_NAME: 'Ticket name',
      EDIT_PRODUCT_NAME_PLACEHOLDER: 'Example: General Admission',
      EDIT_PRODUCT_PRICE: 'Ticket price',
      EDIT_EVENT_NAME: 'Event name',
      EDIT_EVENT_MAX_CAPACITY: 'Total tickets for this event',
      EDIT_EVENT_INFORMATION_SECTION: 'Event information',
      EDIT_EVENT_CANCEL_SECTION: 'Cancel this event',
      EDIT_EVENT_TICKETS_SECTION: 'Tickets for this event',
      TICKET_ON_SALE: 'Ticket on sale',
      VIEW_OPTION_SETTINGS: 'View optional ticket settings',
      SALES_START_DATE: 'Sale start date',
      SALES_START_TIME: 'Sale start time',
      SALES_END_DATE: 'Sale end date',
      SALES_END_TIME: 'Sale end time',
      TICKET_LIMIT_PER_ORDER: 'Ticket limit per order',
      TICKET_LIMIT_PER_ORDER_DESCRIPTION:
        'Set the maximum number of tickets customer can purchase for this ticket per order',
      TICKET_FEE: 'Ticket fee',
      TICKET_FEE_DESCRIPTION: 'Set the ticket fee for each ticket created',
      REDEMPTION_LIMIT: 'Ticket redemption limit',
      REDEMPTION_LIMIT_DESCRIPTION: 'Set the maximum number of uses a customer can use this ticket',
      UNLIMITED_USE: 'Unlimited use',
      PACK_COUNT: 'Family ticket',
      PACK_COUNT_DESCRIPTION: 'Set the number of tickets that are included in the family ticket',
      USING_PACK_COUNT: 'Make this a family ticket',
      REVIEW_HIDDEN_FEES: 'Attendee pays fee',
      ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
      TICKET_TYPE_ITEM_NOTIFICATION_TITLE: 'Need to change the ticket name?',
      TICKET_PRICE_NOTIFICATION_TITLE: 'Need to change the ticket price?',
      TICKET_TYPE_ITEM_NOTIFICATION_SUBTITLE:
        'You’ll need to change the status of this ticket to “Not on sale” and then create a new ticket type.',
      FORM_TEMPLATE: [
        {
          name: 'first-name',
          text: 'First Name',
          fieldType: 'STRING',
          displayOnTicket: true,
          required: true,
          order: 1
        },
        { name: 'last-name', text: 'Last Name', fieldType: 'STRING', displayOnTicket: true, required: true, order: 2 },
        { name: 'email', text: 'Email Address', fieldType: 'EMAIL', displayOnTicket: true, required: true, order: 3 },
        {
          name: 'phone-number',
          text: 'Phone Number',
          fieldType: 'PHONE',
          displayOnTicket: true,
          required: true,
          order: 4
        },
        { name: 'student-id', text: 'Student Id', fieldType: 'STRING', displayOnTicket: true, required: true, order: 5 }
      ],
      EDIT_LABEL: 'Edit Ticket',
      TICKETS_TITLE: 'Tickets',
      EVENT_TICKETS: 'Event Tickets',
      MODAL_EDIT_EVENT_NAME: 'Edit Event Name',
      TICKETS_ON_SALE: 'Tickets on Sale',
      TICKET_SALES_START: 'Ticket sales start',
      TICKET_SALES_END: 'Ticket sales end',
      TICKET_SETUP: 'Ticket Setup',
      SELECT_TICKET_TYPE: 'Select ticket type',
      ADD_TICKET: 'Add ticket',
      ADD_ACCOUNT: 'Add Opponent',
      TICKET_AVAILABILITY: 'Ticket availability',
      TOTAL_TICKET_AVAILABILITY: 'Total tickets available for this event',
      UNLIMITED_TICKETS: 'Unlimited tickets',
      SPLIT_TICKET_BETWEEN_SCHOOLS: 'Split ticket between schools',
      EVENT_TICKETS_DESCRIPTION: 'Your default tickets appear below, you may edit or add new tickets for your event',
      EVENT_NAME_TITLE: 'Event name',
      EVENT_PLACEHOLDER: 'Example: The Blue River Rivalry',
      SEARCH_PLACEHOLDER: 'Search for a Ticket',
      DOWNLOAD_TICKET_FLYER: 'Download Ticket Flyer',
      TICKET_LINK: 'Ticket Link',
      TICKETS_SOLD: 'Tickets sold',
      RESCHEDULE_THIS_EVENT: 'Reschedule this event',
      YOU_CHOOSE_CANCEL_EVENT: 'You are choosing to cancel this event',
      GOFAN_REFUND_TICKETS: 'GoFan will process refunds for all event tickets',
      CANCEL_EVENT: 'Cancel event',
      CONFIRM_CANCEL_EVENT: 'Confirmation: I want to cancel this event',
      TICKET_SALES: 'Ticket sales',
      TICKET_SOLD: 'Ticket Sold',
      TYPE_OF_EVENT: 'Type of event',
      TICKET_USED: 'Ticket used',
      PERCENT_TICKET_USED: '% of tickets used',
      SEND_EMAIL_NOTIFY: 'Send notification email to ticket buyers',
      DELETE_TICKET: 'Delete ticket',
      VIEW_EVENT_TABS: ['Summary', 'Management', 'Tickets', 'Streaming', 'Statistics', 'Comp Ticket'],
      COMP_TICKETS_TAB: {
        TITLE: 'Comp Ticket',
        DESCRIPTION: 'Search for fan profile who has purchased a ticket for this event',
        RECENT_COMP_TICKETS: 'Recent Comp Tickets',
        TICKET_HOLDER_EMAIL: 'Ticket Holder Email',
        COMP_TICKETS: 'Comp tickets',
        TICKET: 'ticket',
        TICKETS: 'tickets',
        CREATE_COMP_TICKET_BTN: 'Create Comp Ticket',
        SUCCESS_NOTIFICATION: 'Comp ticket created successfully'
      },
      COMP_TICKETS_MODAL: {
        TITLE: 'Send Complimentary Tickets',
        SUBTITLE: 'Enter the email address and quantity of tickets you would like to send'
      }
    },
    COLLECT_ADDITIONAL_INFO_TITLE: 'Collect additional information',
    INFORMATION_TYPES_TITLE: 'Information types',
    INFORMATION_TYPES_SELECT_TEXT: 'Select',
    INFORMATION_TYPES_SELECTED_TEXT: 'fields selected',
    FIELD_ON_FANS_TICKET_TITLE: 'Display these fields on fans ticket',
    CUSTOM_FIELDS_TITLE: 'Custom fields',
    CUSTOM_FIELDS_ON_TEXT: 'On',
    CUSTOM_FIELDS_OFF_TEXT: 'Off',
    FIELD_NAME_TEXT: 'Field name',
    FIELD_TYPE_TEXT: 'Field types',
    DROPDOWN_OPTION_TEXT: 'Drop-down options',
    ADD_ADDITIONAL_FIELD_TITLE_BTN: 'Add additional field',
    ADD_DROPDOWN_OPTIONS_TITLE_BTN: 'Add drop-down options',
    EDIT_DROPDOWN_OPTIONS_TITLE_BTN: 'Edit drop-down options',
    MAX_ADDITIONAL_FIELD_NUMBER: 25,
    ADD_ADDITIONAL_FIELD_WARNING_TEXT: 'Only 25 fields can be added',
    CUSTOM_FIELD_TYPE_OPTIONS: [
      {
        name: 'textInput',
        label: 'Text input',
        value: 'STRING'
      },
      {
        name: 'numberInput',
        label: 'Number input',
        value: 'NUMBER'
      },
      {
        name: 'dropdown',
        label: 'Dropdown',
        value: 'DROPDOWN'
      },
      {
        name: 'checkbox',
        label: 'Checkbox',
        value: 'CHECKBOX'
      },
      {
        name: 'email',
        label: 'Email',
        value: 'EMAIL'
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        value: 'PHONE'
      },
      {
        name: 'textArea',
        label: 'Text area',
        value: 'TEXTBOX'
      }
    ]
  },
  VALUES: [ATHLETIC, PERFORMING_ART, REGISTRATION, SCHOOL_DANCE, OTHER],
  ATHLETIC,
  PERFORMING_ART,
  REGISTRATION,
  SCHOOL_DANCE,
  MOBILE_PASS,
  PROM,
  FUNDRAISER,
  CONCESSION,
  PLAYON_SITE,
  OTHER
};

export const NON_ATHLETIC_SPORT = [PERFORMING_ART, REGISTRATION, SCHOOL_DANCE, PROM];

export const ARBITER_PARTNER_NAME = 'arbiter';
export const DELETED_STATUS = 'Deleted';

export const DEFAULT_EVENT_MONITOR_START_DATE = '2021-08-01T00:00:00+0000';

export const DEFAULT_MOBILE_PASS_COLOR = 'ffffff';

export const EVENT_INFO_PREFIX = {
  VS: 'vs',
  AT: 'at'
};

export const EVENT_STATUS = {
  VISIBLE: 'Visible',
  HIDDEN: 'Hidden',
  CANCELED: 'Canceled'
};

export const EMPTY_VALUE = '---';

export const EVENT_INSIGHT_ERROR = 'Unable to load data';

export const CREATE_EVENT_TICKET_NAME_PLACEHOLDER = 'Example: General Admission, Student, Parking';

export const GOAL_VALIDATION = {
  MIN: 1,
  MAX: 1000000
};

export const GENDER_SPECIFIC = {
  SPECIFIC: 'Specific',
  COED: 'Coed'
};

export const GENDER = {
  BOYS: 'Boys',
  GIRLS: 'Girls',
  COED: 'Coed'
};

export const EVENT_ROLE_IDS = {
  ACCOUNT_ID: 'accountId',
  FINANCIAL_ACCOUNT_ID: 'financialAccountId',
  OPPONENT_ACCOUNT_ID: 'opponentAccountId',
  HOST_ACCOUNT_ID: 'hostAccountId',
  TAGGED_ACCOUNT_IDS: 'taggedAccountIds'
};

export const NO_SELECTED_ROLE_ACCOUNTS = 'noSelectedRoleAccounts';

export const EVENT_ROLE_LABELS = {
  [EVENT_ROLE_IDS.ACCOUNT_ID]: 'Home',
  [EVENT_ROLE_IDS.FINANCIAL_ACCOUNT_ID]: 'Financial',
  [EVENT_ROLE_IDS.OPPONENT_ACCOUNT_ID]: 'Opponent',
  [EVENT_ROLE_IDS.HOST_ACCOUNT_ID]: 'Host',
  [EVENT_ROLE_IDS.TAGGED_ACCOUNT_IDS]: 'Participant'
};

export const DONATION_TYPE_LABEL = {
  VIEW: {
    [PRODUCT_TYPE.DONATION]: 'Event Based',
    [PRODUCT_TYPE.DONATION_ONGOING]: 'On Going'
  },
  DETAIL: {
    [PRODUCT_TYPE.DONATION]: 'Event based fundraiser',
    [PRODUCT_TYPE.DONATION_ONGOING]: 'An Ongoing fundraiser'
  }
};

export const ONGOING_FUNDRAISER_DURATION_OPTIONS = [
  {
    label: '1 month',
    value: 30
  },
  {
    label: '3 months',
    value: 90
  },
  {
    label: '6 months',
    value: 180
  },
  {
    label: '1 year',
    value: 365
  },
  {
    label: 'Custom',
    value: 0
  }
];

export const EVENT_ROLES = {
  [EVENT_ROLE_IDS.ACCOUNT_ID]: 'account',
  [EVENT_ROLE_IDS.FINANCIAL_ACCOUNT_ID]: 'financialAccount',
  [EVENT_ROLE_IDS.OPPONENT_ACCOUNT_ID]: 'opponentAccount',
  [EVENT_ROLE_IDS.HOST_ACCOUNT_ID]: 'hostAccount',
  [EVENT_ROLE_IDS.TAGGED_ACCOUNT_IDS]: 'taggedAccounts'
};

// Request from API to PATCH event with empty field
export const EMPTY_STRING = '';
export const EMPTY_NUMBER = -1;

export const EXPAND_EVENT_FIELDS = ['account-opponent', 'activity', 'event-sales-info', 'event-type', 'levels'];

export const VIEW_EVENT_STATUS = {
  PREV: 'PREV',
  DURING: 'DURING',
  POST: 'POST'
};

export const EVENT_PAYMENT_CYCLE_RADIOS = [
  {
    labelText: 'Calendar-based',
    value: ACCOUNT_PAYMENT_CYCLE.WEEKLY,
    id: ACCOUNT_PAYMENT_CYCLE.WEEKLY
  },
  {
    labelText: 'Event-based',
    value: ACCOUNT_PAYMENT_CYCLE.EVENT_BASED,
    id: ACCOUNT_PAYMENT_CYCLE.EVENT_BASED
  }
];

export const EVENT_PAYMENT_CYCLE_RADIOS_WITH_DAILY = [
  ...EVENT_PAYMENT_CYCLE_RADIOS,
  {
    labelText: 'Daily Settlement',
    value: ACCOUNT_PAYMENT_CYCLE.DAILY,
    id: ACCOUNT_PAYMENT_CYCLE.DAILY
  }
];

export const EVENT_PAYMENT_CYCLE_HELPER_TEXT = {
  CALENDAR_BASED_MSG: `Calendar-based is a payment option that causes payments to be issued for all transactions that occurred in the previous week on your account (Monday through Sunday). You’ll get paid out the following Thursday and receive your Weekly Payment Statement via email by Friday.`,
  EVENT_BASED_MSG: `Event-based is a payment option that causes payments to be issued for all transactions pertaining to events with an end date in the previous week (Monday through Sunday). You’ll get paid out the following Thursday and receive your Weekly Payment Statement via email by Friday.`,
  DAILY_BASED_MSG: `For schools in Washington state, GoFan is required to remit all funds within one business day of receipt. The payment cycle is mapped to daily settlement, and the payment source is mapped to ACH. For questions, please contact us at `
};

export const START_DATE_LIMIT_DAYS = 90;

export const ATHLETIC_ACTIVITIES_EXCLUDE = 'DIGITAL SEASON PASS';

export const BROADCAST_START_TIME_OPTION = 'broadCastTime';

export const VIEW_EVENT_TAB_KEYS = {
  EVENT_SUMMARY: 'EVENT_SUMMARY',
  EVENT_STATISTICS: 'EVENT_STATISTICS',
  EVENT_MANAGEMENT: 'EVENT_MANAGEMENT',
  EVENT_TICKETS: 'EVENT_TICKETS',
  EVENT_STREAMING: 'EVENT_STREAMING',
  GOFAN_PAGE: 'GOFAN_PAGE',
  COMP_TICKET: 'COMP_TICKET',
  FAN_PROFILE: 'COMP_TICKET_FAN_PROFILE'
};

export const PLATFORM = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile'
};

export const FAN_SUPPORT_KEYS = {
  FAN_PROFILE: 'FAN_SUPPORT_FAN_PROFILE'
};

export const EVENT_TABS = {
  SUMMARY: {
    index: 0,
    id: 0,
    key: 'summary',
    slug: 'summary'
  },
  MANAGEMENT: {
    index: 1,
    id: 1,
    key: 'management',
    slug: 'management'
  },
  TICKETS: {
    index: 2,
    id: 2,
    key: 'tickets',
    slug: 'tickets'
  },
  STREAMING: {
    index: 3,
    id: 3,
    key: 'streaming',
    slug: 'streaming'
  },
  STATISTICS: {
    index: 4,
    id: 4,
    key: 'statistics',
    slug: 'statistics'
  },
  COMP_TICKET: {
    index: 5,
    id: 5,
    key: 'comp-ticket',
    slug: 'comp-ticket'
  },
  GOFAN_PAGE: {
    index: 6,
    id: 6,
    key: 'gofan-page',
    slug: 'gofan-page'
  },
  EDIT_DATE: {
    index: 99,
    id: 99,
    key: 'edit-date',
    slug: 'edit-date'
  }
};

export const EVENT_HISTORY_DATE_RANGE: EventHistoryDateRange[] = [
  {
    value: 'lastWeek',
    label: 'Last Week'
  },
  {
    value: 'lastMonth',
    label: 'Last Month'
  },
  {
    value: 'all',
    label: 'All'
  }
];

export const EVENT_QR_CODE_UTMS = 'utm_source=GoFan&utm_medium=qrcode&utm_campaign=HQEventLink';

export const STREAMING_QR_CODE_UTMS = 'utm_source=NFHS&utm_medium=qrcode&utm_campaign=HQStreamingLink';

export const BUYER_PURCHASE_LIMIT = {
  MIN: 1,
  MAX: 150
};

export const UPDATE_DATE_TIME_CONFIRMATION = {
  [FUNDRAISER]: {
    EVENT_HAS_SOLD: {
      TITLE: 'Confirm change of fundraiser date',
      CONTENT:
        'Changing your fundraiser’s date will update all donations already purchased for the fundraiser. Additionally, any reports you have already received will reflect the old date. Are you sure you want to change this fundraiser’s date?'
    },
    EVENT_IS_END_DATE: {
      TITLE: 'Confirm change of fundraiser date',
      CONTENT:
        'By changing your fundraiser’s date, any report you have already received will reflect the old date. Are you sure you want to change this fundraiser’s date?'
    }
  },
  [OTHER]: {
    EVENT_HAS_SOLD: {
      TITLE: 'Confirm change of event date/time',
      CONTENT:
        'Changing your event’s date/time will update all tickets already purchased for the event. Additionally, any reports you have already received will reflect the old date. Are you sure you want to change this event’s date/time?'
    },
    EVENT_IS_END_DATE: {
      TITLE: 'Confirm change of event date/time',
      CONTENT:
        'By changing your event’s date/time, any report you have already received will reflect the old date. Are you sure you want to change this event’s date/time?'
    }
  }
};

export const ALLOWED_COMP_TICKET_ACTIVITY_TYPES = [
  ATHLETIC,
  PERFORMING_ART,
  REGISTRATION,
  SCHOOL_DANCE,
  OTHER,
  MOBILE_PASS
];

export const CAMERA_STATUS = {
  OFFLINE: 'Offline',
  SLEEP: 'Sleep',
  ONLINE: 'Online',
  LIVE: 'Live'
};

export const CAMERA_STATUS_COLORS = {
  [CAMERA_STATUS.OFFLINE]: '#DA1E28',
  [CAMERA_STATUS.ONLINE]: '#198038',
  [CAMERA_STATUS.SLEEP]: '#f1c21b',
  [CAMERA_STATUS.LIVE]: '#198038'
};

export const EDITABLE_FIELDS_IN_EDIT_EVENT_MODAL = [
  'id',
  'timeZone',
  'eventName',
  'theme',
  'customSportName',
  'description',
  'alert',
  'salesStartDateTime',
  'salesStartDate',
  'salesStartTime',
  'salesEndDateTime',
  'salesEndDate',
  'salesEndTime',
  'ticketLimitPerOrder',
  'financialAccountId',
  'financialAccount',
  'paymentCycle',
  'glCode',
  'startDateTime',
  'startDate',
  'startTime',
  'endDateTime',
  'endDate',
  'endTime',
  'allDayEvent',
  'venue',
  'eventValidationStartsBefore',
  'enableEventValidation',
  'startTimeType',
  'startTimeOptions',
  'activityType',
  'activity',
  'genders',
  'levels',
  'redemptionWindow',
  'maxCapacity',
  'unlimitedTicket',
  'goal'
];

export const EDITABLE_FIELDS_IN_MOBILE_EDIT_EVENT_MODAL = [
  'id',
  'timeZone',
  'eventName',
  'alert',
  'salesStartDateTime',
  'salesStartDate',
  'salesStartTime',
  'salesEndDateTime',
  'salesEndDate',
  'salesEndTime',
  'ticketLimitPerOrder',
  'startDateTime',
  'startDate',
  'startTime',
  'endDateTime',
  'endDate',
  'endTime',
  'allDayEvent',
  'venue',
  'eventValidationStartsBefore',
  'enableEventValidation',
  'startTimeType',
  'startTimeOptions',
  'activityType',
  'activity',
  'genders',
  'levels',
  'redemptionWindow',
  'maxCapacity',
  'unlimitedTicket',
  'goal'
];

export const EDITABLE_FIELDS_IN_PLAYON_SITE_FORM = [
  'id',
  'timeZone',
  'eventName',
  'accountId',
  'account',
  'financialAccountId',
  'financialAccount',
  'opponentAccountId',
  'opponentAccount',
  'taggedAccountIds',
  'taggedAccounts',
  'startDateTime',
  'startDate',
  'startTime',
  'endDateTime',
  'endDate',
  'endTime',
  'allDayEvent',
  'venue',
  'activityType',
  'activity',
  'genders',
  'levels',
  'archived',
  'publishDateTime',
  'featuredAccountIds'
];
