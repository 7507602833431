import React from 'react';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

const Label = styled.label``;

export default ({
  label,
  overwritable,
  type,
  value,
  validation,
  setErrors,
  ...props
}) => {
  const hasError = validation ? !validation(value) : false;
  return (
    <Form.Field {...props}>
      <Label>{label}</Label>
      <input
        style={{
          border: `2px solid ${hasError ? '#ff0000' : '#ccc'}`,
          borderRadius: 0,
          backgroundColor: overwritable ? '#fdfcea' : '',
          height: type === 'text' ? '128px' : '48px'
        }}
        hasError={hasError}
        type={type}
        value={value}
      />
      {value && hasError && <p>Invalid value</p>}
    </Form.Field>
  );
};
