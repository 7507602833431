import { useQueries } from '@tanstack/react-query';
import { RateService } from './rate.service';
import { RATE_QUERY_KEY } from './rate.endpoints';
import isNil from 'lodash/isNil';

import type { RateDTO } from './rate.model';
import type { UseQueryOptions } from '@tanstack/react-query';

export interface UseMultipleRatesOptions {
  rateIds: number[];
  queryOptions?: {
    cacheTime?: number;
    staleTime?: number;
  };
}

export const useMultipleRates = ({ rateIds, queryOptions = {} }: UseMultipleRatesOptions) => {
  const queries = useQueries({
    queries: rateIds.map<UseQueryOptions<RateDTO, Error>>(rate => ({
      queryKey: [RATE_QUERY_KEY, rate],
      queryFn: () => RateService.getRateById(rate),
      cacheTime: 24 * 60 * 60 * 1000, // = 1 day
      staleTime: 24 * 60 * 60 * 1000,
      ...queryOptions
    }))
  });

  return {
    queries,
    data: queries.filter(acc => !isNil(acc.data)).map(acc => acc.data),
    isFetching: queries.some(query => query.isFetching),
    isLoading: queries.some(query => query.isLoading),
    isAllSuccess: queries.every(query => query.isSuccess)
  };
};
