import type { Dispatch, SetStateAction, ChangeEvent } from 'react';
import { useRef, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { isEmpty } from '@app/utils/objectUtils';
import { Row, Column, ModalBody, ModalHeader, ModalFooter, ComposedModal, Button } from 'carbon-components-react';
import { ProgressIndicator, ProgressStep, Checkbox, CheckboxGroup, SchoolComboBox } from '@gofan/components';

import otherImage from '@assets/images/other-image.jpg';
import athleticImage from '@assets/images/athletic-image.jpg';
import graduationImage from '@assets/images/graduation-image.jpg';
import schoolDanceImage from '@assets/images/schooldance-image.jpg';
import registrationImage from '@assets/images/registration-image.jpg';
import campRegistrationImage from '@assets/images/camp-registration.png';
import performingArtsImage from '@assets/images/performingarts-image.jpg';

import { useFeatureFlags, FEATURE_FLAGS, useAuth } from '@gofan/hooks';
import { useNFHSSchool, UnityService, isInternalUser } from '@gofan/api';

import isEqual from 'lodash/isEqual';
import values from 'lodash/values';

import SingleEventType from '@dashboard/components/single-event-type/single-event-type.component';
import MultiSelectWithAllOpt from '@app/commons/carbon-ui/customized/MultiSelectWithAllOpt';

import { ActivityService } from '@gofan/api/activities';
import { PAGES } from '@app/config/routes';
import { PLAYON_SITE } from '@gofan/constants/event';
import { checkIsEditableDistrictUser } from '@accounts/v2/utils';

import type { AccountDTO } from '@gofan/api/accounts';
import type { ActivityDTO } from '@gofan/api/activities';
import type { EventType, EventTypes } from '@dashboard/components/single-event-type/single-event-type.component';
import { DISTRICT_CONFERENCE_SCHOOL_TYPES } from '@gofan/constants';
import OldAccountDTO from '../../../../api/dto/AccountDTO';
import AccountModel from '../../../../api/model/Account';

export const MODAL_TYPE = {
  EVENT: 'EVENT',
  SEASON: 'SEASON',
  MOBILE_PASS: 'MOBILE_PASS',
  FUNDRAISER: 'FUNDRAISER',
  CONCESSION: 'CONCESSION',
  SPONSORSHIP: 'SPONSORSHIP'
};

export type ContentProps = {
  title: string;
  labelSchool: string;
  labelType: string;
};

interface CheckboxProps {
  checked: boolean;
  id: string;
}
export interface CreateSingleEventModalProps {
  type: string;
  open: boolean;
  content: ContentProps;
  accounts: AccountDTO[];
  activities?: ActivityDTO[];
  onCloseModal: () => void;
  onClickedContinue: (
    selectedAccount: AccountDTO,
    selectedType: EventType,
    creationTypes: EventCreationTypes[]
  ) => void;
  selectedActivities?: ActivityDTO[];
  setSelectedActivities?: Dispatch<SetStateAction<ActivityDTO[]>>;
  setSeasonModalSportError?: Dispatch<SetStateAction<boolean>>;
  seasonModalSportError?: boolean;
}

export const EVENT_TYPE = {
  ATHLETIC: 'ATHLETIC',
  PERFROMING_ARTS: 'PERFROMING_ARTS',
  REGISTRATION: 'REGISTRATION',
  CAMP_REGISTRATION: 'CAMP_REGISTRATION',
  SCHOOL_DANCE: 'SCHOOL_DANCE',
  OTHER: 'OTHER',
  GRADUATION: 'GRADUATION',
  MOBILE_PASS: 'MOBILE_PASS'
};

export const eventTypes: EventTypes = {
  [EVENT_TYPE.ATHLETIC]: {
    id: EVENT_TYPE.ATHLETIC,
    src: athleticImage,
    name: 'Athletic'
  },
  [EVENT_TYPE.PERFROMING_ARTS]: {
    id: EVENT_TYPE.PERFROMING_ARTS,
    src: performingArtsImage,
    name: 'Performing Arts'
  },
  [EVENT_TYPE.REGISTRATION]: {
    id: EVENT_TYPE.REGISTRATION,
    src: registrationImage,
    name: 'Registration'
  },
  [EVENT_TYPE.SCHOOL_DANCE]: {
    id: EVENT_TYPE.SCHOOL_DANCE,
    src: schoolDanceImage,
    name: 'School Dance'
  },
  [EVENT_TYPE.OTHER]: {
    id: EVENT_TYPE.OTHER,
    src: otherImage,
    name: 'Other'
  },
  [EVENT_TYPE.GRADUATION]: {
    id: EVENT_TYPE.GRADUATION,
    src: graduationImage,
    name: 'Graduation'
  }
};

export const onSubmitCreateEventModal = ({
  selectedAccount,
  selectedType,
  history,
  creationTypes = []
}: {
  selectedAccount: AccountDTO;
  selectedType: EventType;
  creationTypes: EventCreationTypes[];
  history: any;
}) => {
  if (creationTypes.includes(EventCreationTypes.TicketingAndStreaming)) {
    history.push(
      PAGES.eventsV2.add.calculatePath(selectedAccount.id, null, creationTypes.includes(EventCreationTypes.Streaming)),
      {
        type: selectedType.name,
        activityId: selectedType.activityId
      }
    );
    return;
  }

  if (creationTypes.includes(EventCreationTypes.Streaming)) {
    history.push(PAGES.streaming.createEvent.calculatePath(selectedAccount.id), {
      type: selectedType.name,
      activityId: selectedType.activityId
    });
    return;
  }

  if (creationTypes.includes(EventCreationTypes.PlayOnSite)) {
    history.push(PAGES.eventsV2.add.calculatePath(selectedAccount.id, null, true), {
      customType: PLAYON_SITE,
      type: selectedType.name,
      activityId: selectedType.activityId
    });
  }
};

export enum EventCreationSteps {
  Selection = 'selection',
  Type = 'type'
}

export enum EventCreationTypes {
  TicketingAndStreaming = 'ticketing-and-streaming',
  Streaming = 'streaming',
  PlayOnSite = 'playon-site'
}

const CreateEventModal = ({
  type,
  open,
  content,
  accounts,
  activities,
  onCloseModal,
  onClickedContinue,
  selectedActivities,
  setSelectedActivities,
  seasonModalSportError,
  setSeasonModalSportError
}: CreateSingleEventModalProps) => {
  const { auth } = useAuth();
  const { currentUser } = auth ?? {};
  const accountRef = useRef<AccountDTO[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [account, setAccount] = useState<AccountDTO>();
  const [selectedType, setSelectedType] = useState<EventType>(eventTypes[EVENT_TYPE.ATHLETIC]);
  const [creationTypeStep, setCreationTypeStep] = useState<EventCreationSteps>(EventCreationSteps.Selection);
  const [creationTypes, setCreationTypes] = useState<EventCreationTypes[]>([EventCreationTypes.TicketingAndStreaming]);
  const [ticketingCheckboxDisabled, setTicketingCheckboxDisabled] = useState(false);
  const [streamingCheckboxDisabled, setStreamingCheckboxDisabled] = useState(false);
  const [invalidCheckboxGroup, setInvalidCheckboxGroup] = useState(false);
  const {
    [FEATURE_FLAGS.campRegistration]: enableCampRegistration,
    [FEATURE_FLAGS.tempPlayonSiteOnlyEventUnicorn]: enablePlayOnSiteOnlyEvent,
    [FEATURE_FLAGS.tempDistrictConferenceUnicorn]: enableDistrictUnicorn
  } = useFeatureFlags();
  const { data: nfhsSchool } = useNFHSSchool({
    params: { internal: true, search_term: account?.id },
    queryOptions: {
      enabled: !!account?.id
    }
  });
  const isUnmounted = useRef(false);

  const { enableStreamingType, enablePlayOnSiteType } = _getEnableStreamingPlayOnTypes();
  const multiStep = type === MODAL_TYPE.EVENT && (enableStreamingType || enablePlayOnSiteType);

  const listActivities = useMemo(() => {
    if (!activities) return [];
    const { athleticActivities = [] } = ActivityService.groupActivities(activities);
    return athleticActivities;
  }, [activities]);

  const eventModalTypes = useMemo(() => {
    if (type === MODAL_TYPE.EVENT) {
      return eventTypes;
    }
    if (type === MODAL_TYPE.SEASON) {
      return {
        [EVENT_TYPE.ATHLETIC]: {
          id: EVENT_TYPE.ATHLETIC,
          src: athleticImage,
          name: 'Athletic'
        }
      };
    }
    if (type === MODAL_TYPE.MOBILE_PASS || type === MODAL_TYPE.FUNDRAISER) {
      return {};
    }

    return {};
  }, [type]);

  const handleClickedContinue = () => {
    if (isEmpty(account)) {
      setError(true);
    } else {
      if (multiStep && creationTypeStep === EventCreationSteps.Selection) {
        setCreationTypeStep(EventCreationSteps.Type);
        return;
      }

      onClickedContinue(
        account!,
        selectedType.id === EVENT_TYPE.CAMP_REGISTRATION
          ? { ...eventTypes[EVENT_TYPE.OTHER], activityId: 38 }
          : selectedType,
        creationTypes
      );
    }
  };

  const handleClickedPrevious = () => {
    if (multiStep && creationTypeStep === EventCreationSteps.Type) {
      setCreationTypeStep(EventCreationSteps.Selection);
    }
  };

  const onChangeSelectedItems = ({ selectedItems }) => {
    if (setSelectedActivities) {
      setSelectedActivities(selectedItems);
    }
    if (setSeasonModalSportError && selectedItems.length > 0) {
      setSeasonModalSportError(false);
    }
  };

  useEffect(() => {
    if (!isEqual(accounts, accountRef.current)) {
      accountRef.current = accounts;
      setError(false);
      let selectedAccount;
      if (!isEmpty(accounts)) {
        const districtAccount = accounts.find(
          account => account.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT
        );
        selectedAccount = districtAccount || accounts[0];
      }
      setAccount(isEmpty(selectedAccount) ? undefined : _parseAccountDTO(selectedAccount as AccountDTO));
    }
  }, [accounts, accountRef.current]);

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  useEffect(() => {
    const isPlayOnSiteSelected = creationTypes.includes(EventCreationTypes.PlayOnSite);
    setTicketingCheckboxDisabled(isPlayOnSiteSelected);
    setStreamingCheckboxDisabled(isPlayOnSiteSelected);
    setInvalidCheckboxGroup(!creationTypes.length);
  }, [creationTypes]);

  return (
    <ComposedModal
      size={type === MODAL_TYPE.SEASON ? 'md' : 'lg'}
      open={open}
      onClose={_onCloseModal}
      className='dashboard--create-event-modal btn-center'
    >
      <ModalHeader>
        <div className='gs--section-description-large gs--font-family-san gs--text-01'>{content.title}</div>
      </ModalHeader>

      <ModalBody hasForm className='dashboard--create-event-modal__body'>
        {((!isEmpty(accounts) && accounts.length > 1) ||
          !!accounts.find(school => school.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT)) && (
          <>
            <Row>
              <Column>
                <div className='gs--body-short-01 gs--text-01'>{content.labelSchool}</div>
              </Column>
            </Row>
            <Row className='gs--margin-bottom-sp5'>
              <Column
                md={4}
                lg={4}
                className={classNames('gs--padding-top__sp5', {
                  'dashboard--create-event-modal__body--padding': type === MODAL_TYPE.MOBILE_PASS
                })}
              >
                <SchoolComboBox
                  initialSchools={accounts.map(_parseAccountDTO)}
                  selectedItem={account}
                  allowFetchAssociatedSchools={
                    enableDistrictUnicorn &&
                    (isInternalUser(currentUser) ||
                      checkIsEditableDistrictUser(
                        accounts.find(
                          school => school.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT
                        ),
                        currentUser
                      ))
                  }
                  defaultDistrictHuddleId={
                    accounts.find(school => school.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT)
                      ?.id
                  }
                  hasGroupLabel={!isInternalUser(currentUser)}
                  onChange={selectedItem => {
                    setError(!selectedItem);
                    setAccount(_parseAccountDTO(selectedItem));
                    setCreationTypes([EventCreationTypes.TicketingAndStreaming]);
                  }}
                  invalid={!!error}
                  invalidText='Select the school is required'
                />
              </Column>
            </Row>
          </>
        )}

        {multiStep && (
          <ProgressIndicator className='gf-multi-step-container'>
            <ProgressStep
              current={creationTypeStep === EventCreationSteps.Selection}
              complete={creationTypeStep === EventCreationSteps.Type}
              label='Choose an event'
            />
            <ProgressStep current={creationTypeStep === EventCreationSteps.Type} label='Select an event type' />
          </ProgressIndicator>
        )}

        <Row>
          {creationTypeStep === EventCreationSteps.Selection ? (
            <>
              <Column lg={type === MODAL_TYPE.SEASON ? 6 : 12}>
                <Row>
                  <Column className={classNames({ 'gs--padding-top__sp5': !isEmpty(accounts) || error })}>
                    <div className='gs--body-short-01 gs--text-01'>{content.labelType}</div>
                  </Column>
                </Row>
                <Row>
                  {values(eventModalTypes).map(item => {
                    const currentItem =
                      item.id === eventTypes[EVENT_TYPE.GRADUATION].id && enableCampRegistration
                        ? {
                            ...item,
                            id: EVENT_TYPE.CAMP_REGISTRATION,
                            src: campRegistrationImage,
                            name: 'Camp Registration'
                          }
                        : { ...item };

                    return (
                      <Column
                        md={type === MODAL_TYPE.SEASON ? 12 : 4}
                        lg={type === MODAL_TYPE.SEASON ? 12 : 4}
                        key={item.id}
                      >
                        <SingleEventType
                          eventType={currentItem}
                          selected={currentItem.id === selectedType.id}
                          disabled={currentItem.id === eventTypes[EVENT_TYPE.GRADUATION].id}
                          onSelectedItem={setSelectedType}
                        />
                      </Column>
                    );
                  })}
                </Row>
              </Column>
              {type === MODAL_TYPE.SEASON && (
                <Column
                  lg={6}
                  className={classNames('create-season-modal-activities', {
                    'gs--padding-top__sp5': !isEmpty(accounts) || error
                  })}
                >
                  <MultiSelectWithAllOpt
                    id='create-season-modal-activities'
                    label='sports selected'
                    titleText='Select sports'
                    items={listActivities}
                    invalid={seasonModalSportError}
                    selectedItems={selectedActivities}
                    key={open}
                    invalidText='Sport is required.'
                    onChange={onChangeSelectedItems}
                    itemToString={item => item.label || ''}
                  />
                </Column>
              )}
            </>
          ) : (
            <Column lg={12}>
              <Row>
                <Column
                  className={classNames({
                    'gs--padding-top__sp6 gs--padding-bottom__sp3': !isEmpty(accounts) || error
                  })}
                >
                  <div className='gs--body-short-01 gs--text-01'>Select the type of event you would like to create</div>
                </Column>
              </Row>

              <Row>
                <CheckboxGroup
                  orientation='horizontal'
                  legendText='Select an option'
                  className='gf--creation-type-checkbox-group'
                  invalid={invalidCheckboxGroup}
                  invalidText='You must select an option'
                >
                  <Checkbox
                    labelText='Ticketing event'
                    id={EventCreationTypes.TicketingAndStreaming}
                    onChange={_onChangeEventTypeCheckbox}
                    checked={creationTypes.includes(EventCreationTypes.TicketingAndStreaming)}
                    disabled={ticketingCheckboxDisabled}
                  />
                  <Checkbox
                    labelText='Streaming event'
                    id={EventCreationTypes.Streaming}
                    onChange={_onChangeEventTypeCheckbox}
                    checked={creationTypes.includes(EventCreationTypes.Streaming)}
                    disabled={streamingCheckboxDisabled || !enableStreamingType}
                    className={classNames({ 'hidden-checkbox': !enableStreamingType })}
                  />
                  <Checkbox
                    labelText='PlayOn Site event'
                    id={EventCreationTypes.PlayOnSite}
                    onChange={_onChangeEventTypeCheckbox}
                    checked={creationTypes.includes(EventCreationTypes.PlayOnSite)}
                    disabled={
                      !enablePlayOnSiteType ||
                      (creationTypes.includes(EventCreationTypes.TicketingAndStreaming) &&
                        creationTypes.includes(EventCreationTypes.Streaming))
                    }
                    className={classNames({ 'hidden-checkbox': !enablePlayOnSiteType })}
                  />
                </CheckboxGroup>
              </Row>
            </Column>
          )}
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button kind='secondary' className='bx--btn__centralized' onClick={_onCloseModal}>
          Cancel
        </Button>
        {multiStep && creationTypeStep === EventCreationSteps.Type && (
          <Button kind='tertiary' className='bx--btn__centralized' onClick={handleClickedPrevious}>
            Previous
          </Button>
        )}
        <Button className='bx--btn__centralized' onClick={handleClickedContinue}>
          Continue
        </Button>
      </ModalFooter>
    </ComposedModal>
  );

  function _onCloseModal() {
    onCloseModal();

    setTimeout(() => {
      if (isUnmounted.current) return;
      setCreationTypeStep(EventCreationSteps.Selection);
      setCreationTypes([EventCreationTypes.TicketingAndStreaming]);
      setError(false);
    }, 500);
  }

  function _parseAccountDTO(account: AccountDTO | OldAccountDTO): AccountDTO {
    if (account instanceof OldAccountDTO) {
      return new AccountModel(account.toJSON()).toJSON() as unknown as AccountDTO;
    }
    return account;
  }

  function _getEnableStreamingPlayOnTypes() {
    const enableStreamingType = UnityService.isStreamingSchool(nfhsSchool);
    const enablePlayOnSiteType = enablePlayOnSiteOnlyEvent && !isEmpty(account?.siteDomain);

    return {
      enableStreamingType,
      enablePlayOnSiteType
    };
  }

  function _onChangeEventTypeCheckbox(_e: ChangeEvent<HTMLInputElement>, { checked, id }: CheckboxProps) {
    setCreationTypes((prevTypes: EventCreationTypes[]): EventCreationTypes[] => {
      if (checked && id === EventCreationTypes.PlayOnSite) {
        return [id];
      }

      return checked ? prevTypes.concat(id as EventCreationTypes) : prevTypes.filter(type => type !== id);
    });
  }
};

export default CreateEventModal;
