import { useState, useEffect } from 'react';

import SeasonService from '@seasons/services/season.service';

import isNil from 'lodash/isNil';

import type { SeasonDTO, SeasonInsightDTO } from '@seasons/models/season.model';

const useSeasonInsights = ({ season, handleError }: { season?: SeasonDTO; handleError?: (error: any) => void }) => {
  const [seasonInsights, setSeasonInsights] = useState<SeasonInsightDTO>();

  useEffect(() => {
    if (!isNil(season) && !!season?.products?.length) _getSeasonInsights(season);
  }, [season]);

  const getSeasonTicketsSold = (insight?: SeasonInsightDTO) => {
    const totalTicketsSold = insight
      ? parseInt(`${insight.metrics.tickets}`, 10) - parseInt(`${insight.metrics.refunded_tickets}`, 10)
      : 0;
    return totalTicketsSold;
  };

  const getSeasonTicketSale = (insight?: SeasonInsightDTO) => {
    const totalTicketSale = insight
      ? parseFloat(insight?.metrics.passes_purchased) - parseFloat(insight?.metrics.passes_refunded)
      : 0;
    return totalTicketSale;
  };

  function _getSeasonInsights(currentSeason: SeasonDTO) {
    SeasonService.getSeasonInsights(currentSeason.id)
      .then(res => {
        setSeasonInsights(res.data);
      })
      .catch((error = { message: '' }) => {
        const { message } = error;
        if (typeof message === 'string' && !message.includes('404')) _handleError(error);
      });
  }

  function _handleError(e: Error) {
    if (handleError) handleError(e);
  }

  return { seasonInsights, getSeasonTicketsSold, getSeasonTicketSale };
};

export { useSeasonInsights };
