import React from 'react';
import BaseInput from './BaseInput';
import FormSafeguard from './FormSafeguard';
import ViewOnlyGuard from './ViewOnlyGuard';

export default ({
  label,
  formKey,
  validation = null,
  required = false,
  overwritable = false,
  error = null
}) => (
  <FormSafeguard formKey={formKey}>
    <ViewOnlyGuard label={label} formKey={formKey}>
      <BaseInput
        type='password'
        required={required}
        label={label}
        formKey={formKey}
        overwritable={overwritable}
        validation={validation}
        error={error}
      />
    </ViewOnlyGuard>
  </FormSafeguard>
);
