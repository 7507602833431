import { Grid, Header, Container, Image } from 'semantic-ui-react';

import styled from 'styled-components';
import styles from './styles';

import adminLogo from '../../../../../assets/images/gofan-admin-logo.png';

const StyledTitle = styled(Header)`
  ${styles.title};
`;

const StyledFooter = styled.div`
  ${styles.footer};
`;
const HeaderComponent = () => (
  <div style={styles.headerStyle}>
    <div className='child-spacer-div' />
    <div className='child-aligned padded'>
      <Image src={adminLogo} size='medium' />
    </div>
  </div>
);

export const Wrapper = ({ children, title }) => (
  <>
    <Grid centered>
      <Grid.Row>
        <HeaderComponent />
      </Grid.Row>
      <Grid.Row style={{ marginTop: '107px' }}>
        <Grid.Column computer={4} tablet={8} mobile={14}>
          <StyledTitle textAlign='center' size='huge'>
            {title}
          </StyledTitle>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>{children}</Grid.Row>
    </Grid>
    <StyledFooter>
      <Container textAlign='center'>{'\u00A9 2015-2025 Huddle Tickets, LLC. All Rights Reserved.'}</Container>
    </StyledFooter>
  </>
);
