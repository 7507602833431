import { useEffect } from 'react';
import moment from 'moment-timezone';
import { split, get, omit, uniq } from 'lodash';
import { generateSchoolInfo, generateVenueInformation } from '@app/api/services/SeasonService';
import {
  getCachedDashboardSelectedAccounts,
  getCachedDashboardActiveAccounts
} from '@events/utils/local-storage-cache.utils';
import { switchZone, convertDateWithTimeZone, EVENT_DATE_FORMAT_WITH_TIMEZONE } from '@utils/dateUtils';
import {
  DEFAULT_BUYER_PURCHASE_LIMIT,
  EVENT_NAME_NONE,
  IMPORT_EVENTS_TYPES,
  SEASON_ELEMENT_STATES,
  TIME_ELEMENTS
} from '@app/pages/SeasonSetup/constants';
import { generateEventName } from '@app/api/services/EventService';
import { getUniqueId } from '@app/pages/SeasonSetup/components/EventDetailLayout/components/TicketAvailabilitySection/helper';
import { MAX_CAPACITY } from '@app/modules/seasons/constants/constants';
import { PAGES } from '@app/config/routes';
import { navigateToDashBoard } from '@app/modules/seasons/utils/navigation.utils';
import { getGroupLevelsByGender, buildDefaultStartTimeTeams } from '@seasons/utils/season.utils';
import { DEFAULT_START_TIME, EVENT_START_TIME_TYPE } from '@season-management/constants/constant';
import SeasonCreationUtil from '@season-management/utils/season-creation.utils';

import {
  REGX_TIME_WITHOUT_COLON,
  REGX_TIME_WITH_COLON,
  DATE_PICKER_ERRORS_STRINGS,
  SCHOOL_CREATED_TYPES,
  EVENT_TEMPLATE,
  RESOLVE_DUPLICATED_TYPES
} from './constants';
import { isEmpty } from '../../utils/objectUtils';
import { timeAMPM } from '../../commons/validations';

export const genderKeyMap = new Map([
  ['Boys', 'boy'],
  ['Girls', 'girl'],
  ['Coed', 'coed'],
  ['Coed', 'either']
]);

export const generateSuccessMessage = () => {
  const title = 'Success!';
  const subtitle = 'Your season is published';
  return {
    title,
    subtitle,
    isSuccess: true
  };
};

export const generateErrorMessage = error => {
  const title = 'Error!';
  if (!error) {
    return null;
  }

  return {
    title,
    subtitle: `${error}`,
    isSuccess: false
  };
};

export const generateInitActionsData = (onBackClick, onCancleClick, onReviewClick, onPublishClick, onBuildClick) => ({
  0: {
    label: 'Back',
    onClick: onBackClick,
    disabled: false,
    kind: 'tertiary',
    visible: false,
    isPublishButton: false,
    className: 'custom-btn'
  },
  1: {
    label: 'Cancel',
    onClick: onCancleClick,
    disabled: false,
    kind: 'secondary',
    visible: true,
    textAlign: 'center',
    isPublishButton: false,
    className: 'custom-btn'
  },
  2: {
    label: 'Review',
    onClick: onReviewClick,
    disabled: false,
    kind: 'tertiary',
    visible: true,
    isPublishButton: false,
    className: undefined
  },
  3: {
    label: 'Publish',
    onClick: onPublishClick,
    disabled: false,
    kind: 'primary',
    visible: true,
    isPublishButton: true,
    className: undefined
  },
  4: {
    label: 'Build',
    onClick: onBuildClick,
    disabled: false,
    kind: 'primary',
    visible: true,
    isPublishButton: false,
    className: undefined
  },
  5: {
    label: 'Continue',
    disabled: false,
    kind: 'primary',
    visible: true,
    isPublishButton: false,
    className: undefined
  }
});

export const sortDate = (dateA, dateB, direction = 'asc') =>
  // eslint-disable-next-line no-nested-ternary
  (moment(dateA).isBefore(moment(dateB)) ? -1 : moment(dateA).isAfter(moment(dateB)) ? 1 : 0) *
  (direction === 'asc' ? 1 : -1);

export const sortEvents = (events, direction) =>
  events.sort((a, b) => sortDate(a.eventDate.value, b.eventDate.value, direction), direction);

export const applyEventKeywordFilter = keyword => event => {
  const query = keyword.toLowerCase();
  const eventSearchFields = ['school.value.name', 'school.value.id', 'eventName.value'];
  return eventSearchFields.some(field => get(event, field, '').toLowerCase().includes(query));
};

export const applyEventDateFilter = fitlerData => event => {
  const eventDate = get(event, 'eventDate.value', undefined);
  if (eventDate === undefined) return true;
  const { startDate, endDate } = fitlerData;

  const isAfter = startDate ? moment(eventDate).isSameOrAfter(moment(startDate)) : true;
  const isBefore = endDate ? moment(eventDate).isSameOrBefore(moment(endDate).endOf('day')) : true;
  return isAfter && isBefore;
};

export const applyEventDateSort = direction => (a, b) => sortDate(a.eventDate.value, b.eventDate.value, direction);

export const timeInRange = ({ hour, minute, time24hr }) =>
  time24hr
    ? hour > 0 && hour <= 23 && minute >= 0 && minute < 60
    : hour > 0 && hour <= 12 && minute >= 0 && minute < 60;

export const autoFormatTime = (time, time24hr = false) => {
  let valid = false;
  let error = '';
  const { value } = time;

  let result = {};
  if (!isEmpty(value)) {
    valid = timeAMPM(value) || REGX_TIME_WITHOUT_COLON.test(value) || REGX_TIME_WITH_COLON.test(value);
    if (valid) {
      if (timeAMPM(value)) {
        const timeElements = split(value, ':');
        result = {
          ...time,
          value: `${timeElements[0].length === 1 ? `0${timeElements[0]}` : timeElements[0]}:${
            timeElements[1].length === 1 ? `${timeElements[1]}0` : timeElements[1]
          }`,
          hour: parseInt(timeElements[0], 10),
          minute: parseInt(timeElements[1], 10)
        };
      } else if (REGX_TIME_WITH_COLON.test(value)) {
        const timeElements = split(value, ':');
        result = {
          ...time,
          value: `${timeElements[0].length === 1 ? `0${timeElements[0]}` : timeElements[0]}:${
            timeElements[1].length === 1 ? `0${timeElements[1]}` : timeElements[1]
          }`,
          hour: parseInt(timeElements[0], 10),
          minute: parseInt(timeElements[1].length === 1 ? `0${timeElements[1]}` : timeElements[1], 10)
        };
      } else if (REGX_TIME_WITHOUT_COLON.test(value)) {
        result = {
          ...time,
          value: `${value.length === 3 ? `0${value.substr(0, 1)}` : value.substr(0, 2)}:${
            value.length === 3 ? `${value.substr(1, 2)}` : value.substr(2, 2)
          }`,
          hour: parseInt(value.length === 3 ? value.substr(0, 1) : value.substr(0, 2), 10),
          minute: parseInt(value.length === 3 ? value.substr(1, 2) : value.substr(2, 2), 10)
        };
      }
      if (
        timeInRange({
          hour: result.hour,
          minute: result.minute,
          time24hr
        })
      ) {
        valid = true;
      } else {
        valid = false;
        error = DATE_PICKER_ERRORS_STRINGS.OUT_OF_RANGE;
      }
    } else {
      error = DATE_PICKER_ERRORS_STRINGS.INCORRET_FORMAT;
    }
  } else {
    error = DATE_PICKER_ERRORS_STRINGS.EMPTY;
  }
  return { result, valid, error };
};

export const checkSchoolValid = event => {
  const { school, eventDate, startTime, venueCity, venueName, venueState, showSearchSchool, schoolType } = event;
  if (schoolType === SCHOOL_CREATED_TYPES.IN_SYSTEM && showSearchSchool) {
    return school.valid && eventDate.valid && startTime.valid;
  }
  return venueCity.valid && venueName.valid && venueState.valid && eventDate.valid && startTime.valid;
};

export const genderLevels = (eventLevel, gender, levels) => {
  const foundedLevel = levels.find(level => level.name === eventLevel || level.id === eventLevel);
  const result = {
    [genderKeyMap.get(gender)]: foundedLevel
      ? {
          id: foundedLevel.id,
          text: foundedLevel.name,
          label: foundedLevel.name
        }
      : {}
  };

  return result;
};

export const mappingEventsArbiter = ({ account, event, levels, activities, arbiterSchools, venue }) => {
  const level = genderLevels(event.eventLevel, event.gender, levels);
  const activity = activities.find(item => item.label === event.eventReportingType);
  const schoolInfo = arbiterSchools.find(item => item.id === event.awaySchoolId);
  const mStartDateTime = convertDateWithTimeZone(event.startEventDt, account?.timeZone);
  const timeElements = split(mStartDateTime.clone().format('HH:mm'), ':');
  const venueInformation = generateVenueInformation(venue);
  const customEventName = generateEventName(account, schoolInfo);

  const mEventStartDateTime = moment(
    switchZone(mStartDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE), 'localZone')
  );
  const startTimeTeams = buildDefaultStartTimeTeams({
    allDayEvent: false,
    startDateTime: mEventStartDateTime.clone(),
    startTimeType: EVENT_START_TIME_TYPE.ALL_TEAMS,
    startTimeTeams: {},
    sameStartTime: {
      time: mEventStartDateTime.isValid() ? mEventStartDateTime.clone().format('hh:mm') : DEFAULT_START_TIME.time,
      period: mEventStartDateTime.isValid() ? mEventStartDateTime.clone().format('A') : DEFAULT_START_TIME.period
    }
  });

  return {
    ...EVENT_TEMPLATE,
    ...venueInformation,
    isArbiter: true,
    startTimeTeams,
    endDateTime: convertDateWithTimeZone(event.endEventDt, account?.timeZone),
    eventDuration: moment(event.endEventDt).diff(moment(event.startEventDt)),
    id: event.id,
    globalEventId: event.id,
    partnerId: event.sourceEventId,
    partnerName: event.sourceName,
    buyerPurchaseLimit: {
      section: {
        value: {}
      },
      valid: true,
      value: DEFAULT_BUYER_PURCHASE_LIMIT,
      enableDatePicker: false
    },
    eventAlert: {
      section: { value: {} },
      valid: true,
      value: ''
    },
    eventDate: {
      dirty: false,
      editable: true,
      error: '',
      focused: false,
      savedValue: '',
      touched: true,
      valid: true,
      value: mStartDateTime.clone()
    },
    eventName: {
      section: {
        dirty: false,
        error: '',
        eventId: event.id,
        showError: false,
        valid: true,
        value:
          event.eventName && `${event.eventName}`.toLowerCase() !== EVENT_NAME_NONE ? event.eventName : customEventName
      },
      valid: true,
      value:
        event.eventName && `${event.eventName}`.toLowerCase() !== EVENT_NAME_NONE ? event.eventName : customEventName
    },
    eventDateAndTime: {
      section: {
        allDayEvent: { value: false, valid: true },
        eventDate: {
          dirty: false,
          editable: true,
          error: '',
          focused: false,
          savedValue: '',
          touched: true,
          valid: true,
          value: mStartDateTime.clone()
        },
        eventTime: {
          error: '',
          errorType: '',
          focused: false,
          hour: parseInt(timeElements[0], 10),
          minute: parseInt(timeElements[1].length === 1 ? `0${timeElements[1]}` : timeElements[1], 10),
          second: 1,
          touched: false,
          type: 'PM',
          valid: true,
          value: mStartDateTime.clone().format('hh:mm')
        }
      },
      valid: true,
      value: {
        allDayEvent: { value: false, valid: true },
        eventDate: {
          dirty: false,
          editable: true,
          error: '',
          focused: false,
          savedValue: '',
          touched: true,
          valid: true,
          value: mStartDateTime.clone()
        },
        eventTime: {
          error: '',
          errorType: '',
          focused: false,
          hour: parseInt(timeElements[0], 10),
          minute: parseInt(timeElements[1].length === 1 ? `0${timeElements[1]}` : timeElements[1], 10),
          second: 1,
          touched: false,
          type: 'PM',
          valid: true,
          value: mStartDateTime.clone().format('hh:mm')
        }
      }
    },
    school: generateSchoolInfo(schoolInfo),
    eventVisibility: {
      section: {
        archived: { value: false, valid: true },
        publishDate: {
          dirty: false,
          editable: true,
          error: '',
          focused: false,
          savedValue: '',
          touched: false,
          valid: true,
          value: ''
        },
        publishTime: {
          error: '',
          focused: false,
          hour: 0,
          minute: 0,
          touched: false,
          type: 'AM',
          valid: true,
          value: ''
        }
      },
      valid: true,
      value: {
        archived: { value: false, valid: true },
        publishDate: { value: '', valid: true },
        publishTime: { value: '', valid: true }
      }
    },
    eventType: {
      value: {
        activity: {
          valid: true,
          value: activity
        },
        eventType: {
          valid: true,
          value: ''
        },
        levels: {
          boy: { value: !isEmpty(level.boy) ? [level.boy] : '', valid: !isEmpty(level.boy) },
          either: {
            value: !isEmpty(level.either) ? [level.either] : '',
            valid: !isEmpty(level.either)
          },
          girl: {
            value: !isEmpty(level.girl) ? [level.girl] : '',
            valid: !isEmpty(level.girl)
          }
        },
        genders: [genderKeyMap.get(event.gender)]
      },
      section: {
        activity: {
          dirty: false,
          editable: true,
          error: '',
          focused: false,
          hidden: false,
          savedValue: '',
          touched: false,
          valid: true,
          value: activity
        },
        eventType: {
          dirty: false,
          editable: true,
          error: '',
          focused: false,
          hidden: false,
          savedValue: '',
          touched: false,
          valid: true,
          value: ''
        },
        levels: {
          boy: {
            dirty: false,
            editable: true,
            error: '',
            focused: false,
            hidden: isEmpty(level.boy),
            savedValue: '',
            touched: false,
            valid: true,
            value: [level.boy]
          },
          either: {
            dirty: false,
            editable: true,
            error: '',
            focused: false,
            hidden: isEmpty(level.either),
            savedValue: '',
            touched: false,
            valid: true,
            value: [level.either]
          },
          girl: {
            dirty: false,
            editable: true,
            error: '',
            focused: false,
            hidden: isEmpty(level.girl),
            savedValue: '',
            touched: false,
            valid: true,
            value: [level.girl]
          }
        },
        genders: {
          boy: {
            editable: !isEmpty(level.boy),
            value: { selected: !isEmpty(level.boy) },
            hidden: isEmpty(level.boy)
          },
          dirty: false,
          editable: true,
          either: {
            editable: true,
            value: { selected: !isEmpty(level.either) },
            hidden: isEmpty(level.either)
          },
          error: '',
          event: { editable: true, value: { selected: !isEmpty(level.boy) || !isEmpty(level.girl) } },
          focused: false,
          girl: {
            editable: !isEmpty(level.girl),
            value: { selected: !isEmpty(level.girl) },
            hidden: isEmpty(level.girl)
          },
          hidden: false,
          savedValue: '',
          touched: false,
          valid: true,
          value: ''
        }
      },
      valid: true
    },
    gateOpeningTime: {
      section: {
        ticketValidAnytime: { value: false, valid: true },
        ticketValidBefore: { value: 4, valid: true }
      },
      valid: true,
      value: {
        ticketValidAnytime: { value: false, valid: true },
        ticketValidBefore: { value: 4, valid: true }
      }
    },
    ticketAvailability: {
      section: {},
      valid: false,
      value: {}
    },
    valid: true,
    manual: false
  };
};

export const mappingGenderEventType = (levels = []) => {
  const genders = ['event'];
  if (isEmpty(levels.boy.levels) && isEmpty(levels.girl.levels) && isEmpty(levels.either.levels)) {
    return genders;
  }
  if (!isEmpty(levels.boy.levels) && levels.boy.selected) {
    genders.push('boy');
  }

  if (!isEmpty(levels.girl.levels) && levels.girl.selected) {
    genders.push('girl');
  }

  if (!isEmpty(levels.either.levels) && levels.either.selected) {
    genders.push('either');
  }
  return genders;
};

export const generateSeasonStartTime = seasonStartTime => {
  if (seasonStartTime) {
    const time = split(seasonStartTime.format('HH:mm'), ':');
    const [value, type] = seasonStartTime.format('hh:mm A').split(' ');
    const hour = parseInt(time[0], 10);
    const minute = parseInt(time[1].length === 1 ? `0${time[1]}` : time[1], 10);

    return {
      ...EVENT_TEMPLATE.startTime,
      hour,
      minute,
      value,
      type
    };
  }
  return {};
};

export const regenerateSeasonSettingStartTime = generatedEvents => {
  const seasonStartTime =
    generatedEvents.length > 0 &&
    generatedEvents.every(
      event =>
        generatedEvents[0].eventDateAndTime?.value?.eventDate?.value &&
        event.eventDateAndTime.value.eventDate.value.format('HH:mm') ===
          generatedEvents[0].eventDateAndTime?.value?.eventDate?.value.format('HH:mm')
    )
      ? generatedEvents[0].eventDateAndTime?.value?.eventDate?.value
      : null;
  const startTime = generateSeasonStartTime(seasonStartTime);
  return {
    value: !isEmpty(startTime)
      ? {
          allDayEvent: {
            valid: true,
            value: false
          },
          startTime
        }
      : {},
    valid: true,
    section: !isEmpty(startTime)
      ? {
          allDayEvent: {
            valid: true,
            value: false
          },
          startTime
        }
      : {}
  };
};

export const mappingSettingsArbiter = ({ thirdParty, venue, activities, levels, arbiterEvents, account }) => {
  const venueInformation = generateVenueInformation(venue);
  const activity = activities.find(item => item.label === thirdParty.sportName);
  const level = genderLevels(thirdParty.eventLevel, thirdParty.gender, levels);
  const seasonStartTime =
    arbiterEvents.length > 0 &&
    arbiterEvents.every(
      event => moment(event.startEventDt).format('HH:mm') === moment(arbiterEvents[0].startEventDt).format('HH:mm')
    )
      ? convertDateWithTimeZone(arbiterEvents[0].startEventDt, account?.timeZone)
      : null;

  const startTime = generateSeasonStartTime(seasonStartTime);

  const startTimeTeams = buildDefaultStartTimeTeams({
    allDayEvent: false,
    startTimeType: EVENT_START_TIME_TYPE.ALL_TEAMS,
    startTimeTeams: {},
    sameStartTime: {
      time: isEmpty(startTime?.value) ? DEFAULT_START_TIME.time : startTime?.value,
      period: isEmpty(startTime?.type) ? DEFAULT_START_TIME.period : startTime?.type
    }
  });

  return {
    ...EVENT_TEMPLATE,
    startTimeTeams,
    eventType: {
      value: {
        activity: {
          valid: true,
          value: activity
        },
        eventType: {
          valid: true,
          value: ''
        },
        levels: {
          boy: { value: !isEmpty(level.boy) ? [level.boy] : '', valid: !isEmpty(level.boy) },
          either: {
            value: !isEmpty(level.either) ? [level.either] : '',
            valid: !isEmpty(level.either)
          },
          girl: {
            value: !isEmpty(level.girl) ? [level.girl] : '',
            valid: !isEmpty(level.girl)
          }
        },
        genders: [genderKeyMap.get(thirdParty.gender)]
      },
      section: {
        activity: {
          dirty: false,
          editable: true,
          error: '',
          focused: false,
          hidden: false,
          savedValue: '',
          touched: false,
          valid: true,
          value: activity
        },
        eventType: {
          dirty: false,
          editable: true,
          error: '',
          focused: false,
          hidden: false,
          savedValue: '',
          touched: false,
          valid: true,
          value: ''
        },
        levels: {
          boy: {
            dirty: false,
            editable: true,
            error: '',
            focused: false,
            hidden: isEmpty(level.boy),
            savedValue: '',
            touched: false,
            valid: !isEmpty(level.boy),
            value: !isEmpty(level.boy) ? [level.boy] : ''
          },
          either: {
            dirty: false,
            editable: true,
            error: '',
            focused: false,
            hidden: isEmpty(level.either),
            savedValue: '',
            touched: false,
            valid: !isEmpty(level.either),
            value: !isEmpty(level.either) ? [level.either] : ''
          },
          girl: {
            dirty: false,
            editable: true,
            error: '',
            focused: false,
            hidden: isEmpty(level.girl),
            savedValue: '',
            touched: false,
            valid: !isEmpty(level.girl),
            value: !isEmpty(level.girl) ? [level.girl] : ''
          }
        },
        genders: {
          boy: {
            editable: !isEmpty(level.boy),
            value: { selected: thirdParty.gender === 'Boys' },
            hidden: isEmpty(level.boy)
          },
          dirty: false,
          editable: true,
          either: {
            editable: true,
            value: { selected: thirdParty.gender === 'Coed' },
            hidden: isEmpty(level.either)
          },
          error: '',
          event: {
            editable: true,
            value: { selected: !isEmpty(level.boy) || !isEmpty(level.girl) }
          },
          focused: false,
          girl: {
            editable: !isEmpty(level.girl),
            value: { selected: thirdParty.gender === 'Girls' },
            hidden: isEmpty(level.girl)
          },
          hidden: false,
          savedValue: '',
          touched: false,
          valid: false,
          value: ''
        }
      },
      valid: undefined
    },
    seasonName: {
      section: {
        error: '',
        event: {},
        section: {},
        showError: false,
        value: thirdParty.scheduleName,
        valid: true
      },
      valid: true,
      value: {
        error: '',
        event: {},
        section: {},
        showError: false,
        value: thirdParty.scheduleName,
        valid: true
      }
    },
    startTime: {
      value: !isEmpty(startTime)
        ? {
            allDayEvent: {
              valid: true,
              value: false
            },
            startTime
          }
        : {},
      valid: true,
      section: !isEmpty(startTime)
        ? {
            allDayEvent: {
              valid: true,
              value: false
            },
            startTime
          }
        : {}
    },
    ticketAvailability: {
      section: {},
      valid: true
    },
    ...venueInformation
  };
};

export const mappingArbiterData = ({
  thirdParty,
  venue,
  activities,
  levels,
  arbiterEvents,
  arbiterSchools,
  account
}) => {
  const events = arbiterEvents.map(event =>
    mappingEventsArbiter({ event, levels, activities, arbiterSchools, account, venue })
  );
  const settings = mappingSettingsArbiter({ thirdParty, venue, activities, levels, arbiterEvents, account });
  return {
    events,
    settings
  };
};

export const generateSeasonData = ({
  thirdParty,
  venue,
  activities,
  levels,
  arbiterEvents,
  arbiterSchools,
  account
}) => {
  const { sourceName } = thirdParty;
  if (sourceName === 'arbiter') {
    return mappingArbiterData({ thirdParty, venue, activities, levels, arbiterEvents, arbiterSchools, account });
  }
  return {};
};

export const mappingDataDuplicateEvents = (importEvent, eventDuplicates, arbiterSchools) => {
  if (isEmpty(eventDuplicates)) {
    return [];
  }

  return importEvent.map((event, index) => ({
    importEvent: {
      ...event,
      opponentSchool: arbiterSchools.find(school => school.id === event?.opponentSchoolId) || {}
    },
    events: eventDuplicates[index]?.events || []
  }));
};

export const toEventData =
  ({ venue, activities, schools, settings, account }) =>
  event => {
    if (isEmpty(event)) return {};
    const activity = activities.find(item => item.label === event.sportName);
    const venueInformation = generateVenueInformation(venue);
    const school = schools.find(item => item.id === event.opponentSchoolId);
    const mStartDateTime = convertDateWithTimeZone(event.eventStartDate, account?.timeZone);
    const time = event.eventStartDate ? split(mStartDateTime.clone().format('HH:mm'), ':') : [0, 0];
    let genders;
    if (!isEmpty(event.genders)) {
      genders = event.genders;
    } else if (!isEmpty(event.levels)) {
      genders = event.levels.flatMap(item => (!isEmpty(item.genders) ? item.genders : ['Coed']));
    } else {
      genders = ['Coed'];
    }
    const endDateTime = event.globalEventRef
      ? convertDateWithTimeZone(event.globalEventRef.endEventDt, account?.timeZone)
      : null;
    const initStartDate = mStartDateTime.clone();

    const mEventStartDateTime = moment(
      switchZone(mStartDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE), 'localZone')
    );
    const startTimeTeams = buildDefaultStartTimeTeams({
      allDayEvent: false,
      startDateTime: mEventStartDateTime.clone(),
      startTimeType: EVENT_START_TIME_TYPE.ALL_TEAMS,
      startTimeTeams: {},
      sameStartTime: {
        time: mEventStartDateTime.isValid() ? mEventStartDateTime.clone().format('hh:mm') : DEFAULT_START_TIME.time,
        period: mEventStartDateTime.isValid() ? mEventStartDateTime.clone().format('A') : DEFAULT_START_TIME.period
      }
    });

    return {
      ...EVENT_TEMPLATE,
      ...venueInformation,
      startTimeTeams,
      isArbiter: !!event.globalEventId,
      globalEventId: event.globalEventId,
      partnerId: event?.globalEventRef?.sourceEventId ?? null,
      partnerName: event?.globalEventRef?.sourceName ?? null,
      endDateTime,
      eventDuration: endDateTime ? endDateTime.diff(initStartDate) : 0,
      id: event.globalEventId || event.id || getUniqueId(),
      valid: true,
      allDayEvent: { value: false, valid: true },
      buyerPurchaseLimit: {
        value: DEFAULT_BUYER_PURCHASE_LIMIT,
        valid: true,
        section: {
          value: {}
        }
      },
      enableDatePicker: false,
      eventAlert: {
        value: '',
        valid: true,
        section: {}
      },
      eventDate: {
        ...EVENT_TEMPLATE.eventDate,
        valid: true,
        value: mStartDateTime.clone()
      },
      eventDateAndTime: {
        valid: true,
        value: {
          allDayEvent: { value: false, valid: true },
          eventDate: {
            dirty: false,
            editable: true,
            error: '',
            focused: false,
            savedValue: '',
            touched: true,
            valid: true,
            value: mStartDateTime.clone()
          },
          eventTime: {
            error: '',
            errorType: '',
            focused: false,
            hour: parseInt(time[0], 10),
            minute: parseInt(time[1].length === 1 ? `0${time[1]}` : time[1], 10),
            second: 1,
            touched: false,
            type: 'PM',
            valid: true,
            value: mStartDateTime.clone().format('hh:mm')
          }
        },
        section: {
          allDayEvent: { value: false, valid: true },
          eventDate: {
            dirty: false,
            editable: true,
            error: '',
            focused: false,
            savedValue: '',
            touched: true,
            valid: true,
            value: mStartDateTime.clone()
          },
          eventTime: {
            error: '',
            errorType: '',
            focused: false,
            hour: parseInt(time[0], 10),
            minute: parseInt(time[1].length === 1 ? `0${time[1]}` : time[1], 10),
            second: 1,
            touched: false,
            type: 'PM',
            valid: true,
            value: mStartDateTime.clone().format('hh:mm')
          }
        }
      },
      eventName: {
        value: event.name ? event.name : event.eventName,
        valid: true,
        section: {
          value: event.name ? event.name : event.eventName,
          error: '',
          valid: true
        }
      },
      eventType: {
        valid: true,
        value: {
          activity: {
            valid: true,
            value: activity
          },
          eventType: {
            value: '',
            valid: true
          },
          genders: uniq(genders).map(gender => genderKeyMap.get(gender)),
          levels: settings.eventType.value.levels
        },
        section: settings.eventType.section
      },
      eventVisibility: {
        valid: true,
        value: {
          archived: { value: false, valid: true },
          publishDate: { value: '', valid: true },
          publishTime: { value: '', valid: true }
        },
        section: {
          archived: { value: false, valid: true },
          publishDate: {
            ...SEASON_ELEMENT_STATES,
            valid: true
          },
          publishTime: {
            ...TIME_ELEMENTS,
            valid: true
          }
        },
        publishDate: {
          ...SEASON_ELEMENT_STATES,
          valid: true
        },
        publishTime: {
          ...TIME_ELEMENTS,
          valid: true
        }
      },
      gateOpeningTime: {
        section: {
          ticketValidAnytime: { value: false, valid: true },
          ticketValidBefore: { value: 4, valid: true }
        },
        valid: true,
        value: {
          ticketValidAnytime: { value: false, valid: true },
          ticketValidBefore: { value: 4, valid: true }
        }
      },
      ticketAvailability: {
        valid: false,
        values: {}
      },
      school: isEmpty(event.opponentSchoolId)
        ? {
            dirty: false,
            editable: true,
            error: '',
            focused: false,
            savedValue: '',
            touched: false,
            valid: true,
            value: {
              icon: '',
              name: event?.opponentSchool?.name || '',
              city: event?.opponentSchool?.city || '',
              state: event?.opponentSchool?.state || ''
            }
          }
        : generateSchoolInfo(school)
    };
  };

export const partitionBySelectionType = events => {
  const usedEvents = events.filter(item => item.importEvent.keep && item.events.every(e => !e.keep));
  const keptEvents = events.reduce((res, item) => {
    if (!item.importEvent.keep && item.events.some(e => e.keep)) {
      res.push({
        ...item,
        events: item.events.map(event => ({ ...event, isOnlyKeepGF: true }))
      });
    }
    return res;
  }, []);
  const bothEvents = events.filter(item => item.importEvent.keep && item.events.some(e => e.keep));
  const notDuplicatedEvents = events.filter(item => item.events.length === 0);

  return { usedEvents, keptEvents, bothEvents, notDuplicatedEvents };
};

export const toEventDataLevelsFromGoFanEventLevels = (eventLevels, levels) => {
  const eventGenderLevels = (eventLevels || []).flatMap(eventLevel => {
    if (isEmpty(eventLevel.genders)) {
      return genderLevels(eventLevel.levelId, 'Coed', levels);
    }
    return eventLevel.genders.map(gender => genderLevels(eventLevel.levelId, gender, levels));
  });

  const eventGenderLevelsGroupedByGender = eventGenderLevels.reduce((p, current) => {
    const gender = Object.keys(current)[0];
    const value = current[gender];
    if (!p[gender]) {
      p[gender] = [value];
    } else p[gender].push(value);
    return p;
  }, {});

  return eventGenderLevelsGroupedByGender;
};

export const toEventDataFromGofanEvent =
  ({ activities, levels, eventScheduleSeason, schools, settings, importEvent, type, isUseImportEvent }) =>
  event => {
    if (isEmpty(event)) return {};
    const activity = activities.find(item => item.label === event?.reportingLabel);
    const globalImportEventId = importEvent?.globalEventId ?? null;
    let globalEventId = event?.globalEventId ?? null;
    let partnerName;
    let level = null;

    if (event.isOnlyKeepGF) {
      globalEventId = globalImportEventId;
      partnerName = importEvent?.globalEventRef?.sourceName;
    }

    if (type === RESOLVE_DUPLICATED_TYPES.KEEP_EVENT) {
      level = toEventDataLevelsFromGoFanEventLevels(event.levels, levels);
    } else if (eventScheduleSeason) {
      const genderLevel = genderLevels(eventScheduleSeason.eventLevel, eventScheduleSeason.gender, levels);
      const gender = Object.keys(genderLevel)[0];
      level = {
        [gender]: Array.of(Object.values(genderLevel)[0])
      };
    }

    const mStartDateTime = convertDateWithTimeZone(event.startDateTime, event.timeZone);
    const time = event.startDateTime ? split(mStartDateTime.clone().format('HH:mm'), ':') : [0, 0];
    const publishTime = event.publishDateTime
      ? split(convertDateWithTimeZone(event.publishDateTime, event.timeZone).format('HH:mm'), ':')
      : [0, 0];
    const school = schools.find(item => item.id === event.opponentAccountId);
    const products = event.products.map(product => ({
      accountProductId: product.accountProduct,
      created: true,
      distributionChannel: product.distributionChannel,
      enabled: product.enable,
      generateLink: product.generateLink,
      id: product.id,
      limit: product.limit,
      name: product.name,
      price: product.price,
      productType: product.productType,
      hiddenFees: product.hiddenFees,
      customColor: product.customColor
    }));

    let genders;
    if (!isEmpty(event.genders)) {
      genders = event.genders;
    } else if (!isEmpty(event.levels)) {
      genders = event.levels.flatMap(item => (!isEmpty(item.genders) ? item.genders : ['Coed']));
    } else {
      genders = ['Coed'];
    }
    const endDateTime = convertDateWithTimeZone(event.endDateTime, event.timeZone);
    const initStartDate = mStartDateTime.clone();

    const momentPublishDateTime = convertDateWithTimeZone(event.publishDateTime, event.timeZone);
    const publishDateValue = momentPublishDateTime.isValid() ? momentPublishDateTime.clone() : '';
    const publishTimeValue = momentPublishDateTime.isValid() ? momentPublishDateTime.clone().format('hh:mm') : '';

    const formFields = event.formFields ?? [];

    const { genders: genderTypes, levelsByGender } = getGroupLevelsByGender({
      selectedGenders: genders,
      levels: {
        boy: level?.boy,
        girl: level?.girl,
        either: isEmpty(level?.coed) ? level?.either : level?.coed
      }
    });

    const mImportEventStartDateTime = convertDateWithTimeZone(
      isUseImportEvent ? importEvent?.eventStartDate : event?.startDateTime,
      event?.timeZone
    );
    const mEventStartDateTime = moment(
      switchZone(mImportEventStartDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE), 'localZone')
    );
    const eventStartTimeType = event?.startTimeType ?? EVENT_START_TIME_TYPE.ALL_TEAMS;
    const eventStartTimeTeams = SeasonCreationUtil.convertStartTimeOptionsToTeams({
      sportId: event?.activityId,
      genders: genderTypes,
      levelsByGender,
      startTimeType: eventStartTimeType,
      startTimeOptions: event?.startTimeOptions
    });
    const startTimeTeams = buildDefaultStartTimeTeams({
      allDayEvent: !!event.allDayEvent,
      startDateTime: mEventStartDateTime.clone(),
      startTimeType: eventStartTimeType,
      startTimeTeams: eventStartTimeTeams,
      sameStartTime: {
        time: mEventStartDateTime.isValid() ? mEventStartDateTime.clone().format('hh:mm') : DEFAULT_START_TIME.time,
        period: mEventStartDateTime.isValid() ? mEventStartDateTime.clone().format('A') : DEFAULT_START_TIME.period
      }
    });

    return {
      ...EVENT_TEMPLATE,
      startTimeTeams,
      accountsTicket: event.accountsTicket,
      originalExistingEvent: omit(event, ['keep', 'isOnlyKeepGF']),
      type: RESOLVE_DUPLICATED_TYPES.KEEP_EVENT,
      partnerName,
      globalEventId,
      globalImportEventId,
      created: true,
      id: event.id,
      endDateTime,
      eventDuration: endDateTime.diff(initStartDate),
      shouldKeepEventDurationWhenEdit: true,
      initAllDayEvent: event.allDayEvent,
      valid: true,
      additionalForm: {
        savedValue: formFields,
        value: formFields
      },
      allDayEvent: {
        value: event.allDayEvent,
        valid: true
      },
      buyerPurchaseLimit: {
        value: event.ticketLimitPerOrder,
        valid: true,
        section: {
          ticketLimitPerOrder: {
            value: event.ticketLimitPerOrder,
            valid: true
          }
        }
      },
      eventAlert: {
        value: event.alert ?? '',
        valid: true,
        section: {
          alert: {
            value: event.alert ?? '',
            valid: true
          }
        }
      },
      eventDate: {
        ...EVENT_TEMPLATE.eventDate,
        valid: true,
        value: mStartDateTime.clone()
      },
      eventDateAndTime: {
        valid: true,
        value: {
          allDayEvent: { value: event.allDayEvent, valid: true },
          eventDate: {
            dirty: false,
            editable: true,
            error: '',
            focused: false,
            savedValue: '',
            touched: true,
            valid: true,
            value: mStartDateTime.clone()
          },
          eventTime: {
            error: '',
            errorType: '',
            focused: false,
            hour: parseInt(time[0], 10),
            minute: parseInt(time[1].length === 1 ? `0${time[1]}` : time[1], 10),
            second: 1,
            touched: false,
            type: 'PM',
            valid: true,
            value: mStartDateTime.clone().format('hh:mm')
          }
        },
        section: {
          allDayEvent: { value: event.allDayEvent, valid: true },
          eventDate: {
            dirty: false,
            editable: true,
            error: '',
            focused: false,
            savedValue: '',
            touched: true,
            valid: true,
            value: mStartDateTime.clone()
          },
          eventTime: {
            error: '',
            errorType: '',
            focused: false,
            hour: parseInt(time[0], 10),
            minute: parseInt(time[1].length === 1 ? `0${time[1]}` : time[1], 10),
            second: 1,
            touched: false,
            type: 'PM',
            valid: true,
            value: mStartDateTime.clone().format('hh:mm')
          }
        }
      },
      eventName: {
        value: event.name ? event.name : event.eventName,
        valid: true,
        section: {
          dirty: true,
          editable: true,
          error: '',
          focused: false,
          savedValue: '',
          touched: false,
          value: event.name ? event.name : event.eventName,
          valid: true
        }
      },
      eventType: level
        ? {
            valid: true,
            value: {
              activity: {
                valid: true,
                value: activity
              },
              eventType: {
                value: '',
                valid: true
              },
              levels: {
                boy: {
                  valid: !!level.boy,
                  value: level.boy || ''
                },
                girl: {
                  valid: !!level.girl,
                  value: level.girl || ''
                },
                either: {
                  valid: !!level.either,
                  value: level.either || ''
                }
              },
              genders: uniq(genders).map(gender => genderKeyMap.get(gender))
            },
            section: {
              activity: {
                dirty: false,
                editable: true,
                error: '',
                focused: false,
                hidden: false,
                savedValue: '',
                touched: false,
                valid: true,
                value: activity
              },
              eventType: {
                dirty: false,
                editable: true,
                error: '',
                focused: false,
                hidden: false,
                savedValue: '',
                touched: false,
                valid: true,
                value: ''
              },
              levels: {
                boy: {
                  dirty: false,
                  editable: true,
                  error: '',
                  focused: false,
                  hidden: isEmpty(level.boy),
                  savedValue: '',
                  touched: false,
                  valid: !!level.boy,
                  value: level.boy || ''
                },
                girl: {
                  dirty: false,
                  editable: true,
                  error: '',
                  focused: false,
                  hidden: isEmpty(level.girl),
                  savedValue: '',
                  touched: false,
                  valid: !!level.girl,
                  value: level.girl || ''
                },
                either: {
                  dirty: false,
                  editable: true,
                  error: '',
                  focused: false,
                  hidden: isEmpty(level.either),
                  savedValue: '',
                  touched: false,
                  valid: !!level.either,
                  value: level.either || ''
                }
              },
              genders: {
                boy: {
                  editable: !!level.boy,
                  value: {
                    selected: genders.includes('Boys')
                  },
                  hidden: !level.boy
                },
                girl: {
                  editable: !!level.girl,
                  value: {
                    selected: genders.includes('Girls')
                  },
                  hidden: !level.girl
                },
                event: {
                  editable: true,
                  value: { selected: !isEmpty(level.boy) || !isEmpty(level.girl) }
                },
                either: {
                  editable: true,
                  value: { selected: genders.includes('Coed') },
                  hidden: !level.either
                },
                dirty: false,
                editable: true,
                error: '',
                hidden: false,
                savedValue: '',
                touched: false,
                valid: false,
                value: ''
              }
            }
          }
        : {
            valid: true,
            value: {
              activity: {
                valid: true,
                value: activity
              },
              eventType: {
                value: '',
                valid: true
              },
              genders: uniq(genders).map(gender => genderKeyMap.get(gender)),
              levels: settings.eventType.value.levels
            },
            section: settings.eventType.section
          },
      eventVisibility: {
        valid: true,
        value: {
          archived: { value: event.archived, valid: true },
          publishDate: { value: '', valid: true },
          publishTime: { value: '', valid: true }
        },
        section: {
          archived: { value: event.archived, valid: true },
          publishDate: {
            ...SEASON_ELEMENT_STATES,
            valid: true,
            value: publishDateValue
          },
          publishTime: {
            ...TIME_ELEMENTS,
            valid: true,
            hour: parseInt(publishTime[0], 10),
            minute: parseInt(publishTime[1].length === 1 ? `0${publishTime[1]}` : publishTime[1], 10),
            value: publishTimeValue
          }
        }
      },
      gateOpeningTime: {
        valid: true,
        value: {
          ticketValidAnytime: {
            value: !event.enableEventValidation,
            valid: true
          },
          ticketValidBefore: {
            valid: true,
            value: event.eventValidationStartsBefore ? event.eventValidationStartsBefore / 60 : 0
          }
        },
        section: {
          ticketValidAnytime: {
            value: !event.enableEventValidation,
            valid: true
          },
          ticketValidBefore: {
            valid: true,
            value: event.eventValidationStartsBefore ? event.eventValidationStartsBefore / 60 : 0
          }
        }
      },
      school: generateSchoolInfo(school),
      venueAddress: {
        valid: true,
        value: event.venueAddress
      },
      venueCity: {
        valid: true,
        value: event.venueCity
      },
      venueLocation: {
        valid: true,
        value: event.venueLocation
      },
      venueName: {
        valid: true,
        value: event.venueName
      },
      venueState: {
        valid: true,
        value: event.venueState
      },
      venueZip: {
        valid: true,
        value: event.venueZip
      },
      ticketAvailability: {
        valid: true,
        value: {
          maxCapacity: event.maxCapacity,
          unlimitedTicket: !event.maxCapacity || event.maxCapacity === MAX_CAPACITY,
          products
        },
        section: {
          values: {
            maxCapacity: event.maxCapacity,
            unlimitedTicket: !event.maxCapacity || event.maxCapacity === MAX_CAPACITY,
            products
          }
        }
      }
    };
  };

export const toEventDataFromDuplicatedEvents =
  ({ levels, activities, schools, eventScheduleSeason, settings }) =>
  data => {
    if (isEmpty(data)) return [];
    const event = data.events[0];
    const time = data.importEvent.eventStartDate
      ? split(convertDateWithTimeZone(data.importEvent.eventStartDate, event.timeZone).format('HH:mm'), ':')
      : [0, 0];
    const eventData = toEventDataFromGofanEvent({
      activities,
      levels,
      schools,
      eventScheduleSeason,
      settings,
      isUseImportEvent: true,
      importEvent: data.importEvent
    })(event);

    const endDateTime = data.importEvent.globalEventRef
      ? convertDateWithTimeZone(data.importEvent.globalEventRef.endEventDt, event.timeZone)
      : null;
    const initStartDate = convertDateWithTimeZone(data.importEvent.eventStartDate, event.timeZone);
    const isArbiter = !!data.importEvent.globalEventRef;
    const homeSchool = schools.find(school => school.id === data.importEvent.homeSchoolId);
    const eventName =
      data.importEvent?.eventName && `${event.eventName}`.toLowerCase() !== EVENT_NAME_NONE
        ? data.importEvent.eventName
        : generateEventName(homeSchool, data.importEvent.opponentSchool);
    return Array.of({
      ...eventData,
      type: RESOLVE_DUPLICATED_TYPES.USED_EVENT,
      isArbiter,
      partnerId: data?.importEvent?.globalEventRef?.sourceEventId,
      partnerName: data?.importEvent?.globalEventRef?.sourceName,
      globalEventId: isArbiter ? data.importEvent.globalEventId : eventData.globalEventId,
      eventName: {
        valid: true,
        value: eventName,
        section: {
          valid: true,
          error: '',
          value: eventName
        }
      },
      eventDate: {
        ...EVENT_TEMPLATE.eventDate,
        valid: true,
        value: convertDateWithTimeZone(data.importEvent.eventStartDate, event.timeZone)
      },
      endDateTime,
      eventDuration: endDateTime ? endDateTime.diff(initStartDate) : 0,
      eventDateAndTime: {
        valid: true,
        value: {
          allDayEvent: {
            value: false, // Because of adding opponent schools in add season step 2 always has specific time
            valid: true
          },
          eventDate: {
            dirty: false,
            editable: true,
            error: '',
            focused: false,
            savedValue: '',
            touched: true,
            valid: true,
            value: convertDateWithTimeZone(data.importEvent.eventStartDate, event.timeZone)
          },
          eventTime: {
            error: '',
            errorType: '',
            focused: false,
            hour: parseInt(time[0], 10),
            minute: parseInt(time[1].length === 1 ? `0${time[1]}` : time[1], 10),
            second: 1,
            touched: false,
            type: 'PM',
            valid: true,
            value: convertDateWithTimeZone(data.importEvent.eventStartDate, event.timeZone).format('hh:mm')
          }
        },
        section: {
          allDayEvent: {
            value: false, // Because of adding opponent schools in add season step 2 always has specific time
            valid: true
          },
          eventDate: {
            dirty: false,
            editable: true,
            error: '',
            focused: false,
            savedValue: '',
            touched: true,
            valid: true,
            value: convertDateWithTimeZone(data.importEvent.eventStartDate, event.timeZone)
          },
          eventTime: {
            error: '',
            errorType: '',
            focused: false,
            hour: parseInt(time[0], 10),
            minute: parseInt(time[1].length === 1 ? `0${time[1]}` : time[1], 10),
            second: 1,
            touched: false,
            type: 'PM',
            valid: true,
            value: convertDateWithTimeZone(data.importEvent.eventStartDate, event.timeZone).format('hh:mm')
          }
        }
      }
    });
  };

export const getSeasonEventNames = ({ event, account, importType }) => {
  let eventName = '';
  if (importType) {
    eventName = event?.importEvent?.globalEventRef?.eventName?.trim();
  }
  if (isEmpty(eventName) || `${eventName}`.toLowerCase() === EVENT_NAME_NONE) {
    eventName = generateEventName(account, event?.importEvent?.opponentSchool);
  }

  return eventName;
};

export const usePendoSetUrl = (
  rules = [],
  allowUseEffect = true,
  options = {
    separator: '&',
    useBrowserUrlAfterLeaving: true
  }
) =>
  useEffect(() => {
    const { pendo } = window;
    if (allowUseEffect && pendo && pendo.location) {
      const href = pendo.location.getHref();
      const { separator, useBrowserUrlAfterLeaving } = options;
      const appendedRules = rules.join(separator);
      pendo.location.setUrl(`${href}${separator}${appendedRules}`);
      return () => {
        if (useBrowserUrlAfterLeaving) {
          pendo.location.useBrowserUrl();
        }
      };
    }
    return () => {};
  }, [allowUseEffect]);
export const getSelectedAccountsDashboard = () => {
  let selectedAccountIds = (getCachedDashboardSelectedAccounts() || []).map(({ id }) => id);
  if (selectedAccountIds.length === 0) {
    selectedAccountIds = (getCachedDashboardActiveAccounts() || []).map(({ id }) => id);
  }
  return selectedAccountIds;
};

const seasonPublishedReturnToSeasonEditPage = (history, data) => {
  const { isArbiterSeason, seasonData, seasonResponse } = data;
  const publishedSeasonInfo = {
    isArbiterSeason,
    events: seasonData.events.map(event => ({
      id: event.id,
      isArbiter: !!event.isArbiter,
      isCreated: !!event.created
    }))
  };

  history.push(
    PAGES.season.edit.calculatePath(seasonResponse.id, seasonResponse.accountId, seasonResponse.financialAccountId),
    {
      fromPublishSeason: true,
      publishedSeasonInfo
    }
  );
};

export const seasonPublishedReturnToPage = ({ history, pagePath, data }) => {
  switch (pagePath) {
    // Previous requirement navigate to season edit page was replaced by navigate to dashboard page.
    // This case was kept in case user want to back with navigate to season edit page again. It could be removed if no longer used in the future.
    case PAGES.season.edit.path: {
      return seasonPublishedReturnToSeasonEditPage(history, data);
    }
    case PAGES.dashboard.root.path: {
      return navigateToDashBoard(history);
    }
    default: {
      return navigateToDashBoard(history);
    }
  }
};

export default {
  generateSuccessMessage,
  generateErrorMessage,
  generateInitActionsData,
  generateSeasonData,
  sortEvents,
  sortDate,
  timeInRange,
  autoFormatTime,
  checkSchoolValid,
  mappingDataDuplicateEvents,
  toEventData,
  getSeasonEventNames,
  usePendoSetUrl,
  getSelectedAccountsDashboard,
  seasonPublishedReturnToPage
};
