import BaseDTO from './BaseDTO';

class SeasonPassPurchasedTicketDTO extends BaseDTO {
  constructor({
    chargeId,
    chargeTotalAmount,
    createdAt,
    email,
    last4,
    orderAccessToken,
    orderId,
    refundedAt,
    seasonId,
    seasonPassId,
    ticketCount,
    tickets
  }) {
    super({
      chargeId,
      chargeTotalAmount,
      createdAt,
      email,
      last4,
      orderAccessToken,
      orderId,
      refundedAt,
      seasonId,
      seasonPassId,
      ticketCount,
      tickets
    });
  }

  get chargeId() {
    return this.raw.chargeId;
  }

  get chargeTotalAmount() {
    return this.raw.chargeTotalAmount;
  }

  get createdAt() {
    return this.raw.createdAt;
  }

  get email() {
    return this.raw.email;
  }

  get last4() {
    return this.raw.last4;
  }

  get orderAccessToken() {
    return this.raw.orderAccessToken;
  }

  get orderId() {
    return this.raw.orderId;
  }

  get refundedAt() {
    return this.raw.refundedAt;
  }

  get seasonId() {
    return this.raw.seasonId;
  }

  get seasonPassId() {
    return this.raw.seasonPassId;
  }

  get ticketCount() {
    return this.raw.ticketCount;
  }

  get tickets() {
    return this.raw.tickets;
  }
}
export default SeasonPassPurchasedTicketDTO;
