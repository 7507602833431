import { SAVING_STATUS } from './constants';

export const TICKET_TYPE_STRINGS = {
  ACCESS_CODE: {
    ADD: {
      MODAL_TITLE: 'Create ticket',
      SECONDARY_BTN: 'Cancel'
    },
    UPDATE: {
      MODAL_TITLE: 'Edit ticket',
      SECONDARY_BTN: 'Cancel'
    }
  },
  ADD: {
    MODAL_TITLE: 'Create ticket type',
    PRIMARY_BTN: 'Create',
    SECONDARY_BTN: 'Cancel'
  },
  UPDATE: {
    MODAL_TITLE: 'Edit ticket type',
    PRIMARY_BTN: 'Update',
    SECONDARY_BTN: 'Cancel'
  },
  GF_TICKET_PRICE: 'Fan ticket price',
  BO_TICKET_PRICE: 'Box Office ticket price',

  GOFAN_TICKETS: 'GoFan tickets',
  GOFAN_TICKET_TYPES: 'GoFan ticket types',
  TICKET_NAME: 'Ticket name',
  TICKET_NAME_PLACEHOLDER: 'Example: Student ticket',
  ATTENDEE_PAYS_FEE: 'Attendee pays the fee',
  ATTENDEE_PAYS_FEE_WA_SCHOOL: `The service fee must be paid by the fan for GoFan to comply with your state's legal requirements`,
  TOGGLE_ON: 'Yes',
  TOGGLE_OFF: 'No',
  TICKET_PRICE: 'Ticket price',
  FEE: 'Fee amount',
  PAY_TO_SCHOOL: 'Amount paid to school',
  FAN_PRICE: 'Fan price',
  SETTINGS: 'Settings and price',
  ASSIGN_COLOR: 'Assign ticket color',
  ASSIGN_COLOR_DESCRIPTION: 'Ticket color displays on the attendee’s mobile device',
  ASSIGN_COLOR_DESCRIPTION_SEASON: 'Assign a unique color',
  CURRENCY_PLACEHOLDER: '$0.00',
  ADD_BOX_OFFICE_TICKET_TYPE: 'Add Box Office ticket type',
  BOX_OFFICE_TICKETS: 'Box Office tickets',
  BOX_OFFICE_DESCRIPTION: 'If you purchased Box Office devices you can create a Box Office ticket for this ticket type',
  BOX_OFFICE_DESCRIPTION_SEASON:
    'If you purchased Box Office devices you can set a Box Office ticket for this ticket type',
  BOX_OFFICE_TOGGLE: 'Create a Box Office ticket for this ticket type',
  BOX_OFFICE_TOGGLE_SEASON: 'Create box office for this ticket type',
  BOX_OFFICE_DISABLED:
    'Adding or removing box office tickets not available, please configure this setting in your account page',
  BO_TICKET_NAME: 'Box Office ticket name',
  GENERATE_LINK: 'Create unique URL',
  ALLOTMENT: 'Allotment',
  SAVE_AND_CLOSE: 'Save and close',
  SAVE_AND_ADD_ANOTHER_TICKET: 'Save and add another ticket',
  TICKET_UPDATE_SUCCESS: 'Ticket successfully updated!!',
  SAVE_BTN: 'Save',
  SAVING_BTN: 'Saving',
  SAVE_AND_CONTINUE_BTN: 'Save and continue',
  SAVING_STATUS: {
    [SAVING_STATUS?.ADDED]: 'Ticket added',
    [SAVING_STATUS?.UPDATED]: 'Ticket updated'
  }
};
