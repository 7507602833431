// @flow
import { isEmpty } from '../../../utils/objectUtils';

class ExpandRequest {
  constructor(expand = []) {
    this.raw = {
      expand
    };
  }

  get expand() {
    return isEmpty(this.raw.expand) ? [] : this.raw.expand;
  }

  toQueryString() {
    if (isEmpty(this.expand)) return '';
    return `&expand=${this.expand.join(',')}`;
  }
}

export default ExpandRequest;
