import { useCallback, useEffect, useMemo, useState } from 'react';
import { FileUploaderButton, FileUploaderDropContainer } from '@gofan/components/FileUploader';
import { Tag } from '@gofan/components/Tag';
import { Icons } from '@gofan/components/Icons';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { getUniqueId } from '@gofan/utils';

import './ImageUploader.scss';

export interface FileInfo {
  uuid: number;
  name: string;
  invalid: boolean;
  errorBody: string;
  data: File;
}

export interface ImageUploaderProps {
  accept?: string[];
  disabled?: boolean;
  warningText?: string;
  labelTitle: string;
  labelDescription: string;
  buttonLabel: string;
  fileUploaderName: string;
  dragAndDropLabel: string;
  value: File[];
  url?: File | string;
  removable?: boolean;
  onChange: (addedFiles: File[]) => void;
}

export const ImageUploader = (props: ImageUploaderProps) => {
  const { url, removable } = props;
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [imgUrl, setImgUrl] = useState<string>(props.url instanceof File ? URL.createObjectURL(props.url) : '');

  const imageUploadedSuccess = useMemo(() => {
    if (!files[0]) {
      return !!props.url;
    }
    return !files[0].invalid;
  }, [files, props.url]);

  const fileName = useMemo(() => {
    if (url instanceof File) return url.name;
    if (typeof url === 'string') return url.split('/').pop()?.split('?')[0];
    return '';
  }, [url]);

  const handleAddFiles = useCallback(
    addedFiles => {
      const newFiles = addedFiles?.map(file => {
        let errorBody = '';
        let invalid = false;
        if (file.invalidFileType) {
          errorBody = `"${file.name}" does not have a valid file type.`;
          invalid = true;
        } else if (file.size > 512000) {
          errorBody = `500kb max file size. Select a new file and try again.`;
          invalid = true;
        }

        const newFile: FileInfo = {
          uuid: getUniqueId(),
          name: file.name,
          invalid,
          errorBody,
          data: file
        };

        return newFile;
      });

      if (newFiles?.[0]) {
        setFiles([newFiles?.[0]]);
      }

      const isBlobFile = addedFiles?.[0] instanceof Blob;
      setImgUrl(url => {
        if (url) {
          URL.revokeObjectURL(url);
        }
        return isBlobFile ? URL.createObjectURL(addedFiles?.[0]) : '';
      });
    },
    [files, props.url]
  );

  const onRemoveImage = () => {
    props.onChange([]);
  };

  useEffect(() => {
    if (props.url) {
      setFiles([]);
    }
  }, [props?.url]);

  useEffect(() => {
    if (!files[0]?.invalid) {
      const filesData = files.map(file => file.data);
      const mapFile = (file: File) => ({ name: file.name, size: file.size, type: file.type });
      const mappedFilesData = filesData?.map(mapFile);
      const mappedValues = props?.value?.map(mapFile);
      if (props.onChange && !isEqual(mappedFilesData, mappedValues)) {
        props.onChange(filesData);
      }
    }
  }, [files]);

  useEffect(() => {
    if (props.url !== undefined) {
      if (typeof props.url === 'string') {
        setImgUrl(props.url);
      } else setImgUrl(props.url instanceof File ? URL.createObjectURL(props.url) : '');
    }
  }, [props.url]);
  return (
    <div className='image-uploader'>
      <div className='upload-button-section cds--btn-centralize'>
        <div className='upload-button-section__label'>{props.labelTitle}</div>
        <div className='upload-button-section__description'>{props.labelDescription}</div>
        <FileUploaderButton
          className='cds--btn-centralize'
          labelText={props.buttonLabel}
          buttonKind='primary'
          size='md'
          accept={props.accept || ['.jpg', '.jpeg', '.png']}
          name={props.fileUploaderName}
          disableLabelChanges
          multiple={false}
          disabled={props.disabled}
          onChange={e => handleAddFiles([e.target.files?.[0]])}
        />
      </div>

      <div className='drag-and-drop-section'>
        {!imageUploadedSuccess && <Icons.Upload className='upload-icon' />}
        <FileUploaderDropContainer
          accept={props.accept}
          labelText={imageUploadedSuccess ? '' : props.dragAndDropLabel}
          multiple={false}
          name={props.fileUploaderName}
          onAddFiles={(evt, { addedFiles }) => handleAddFiles(addedFiles)}
          disabled={props.disabled}
        />
        {!isEmpty(imgUrl) && imageUploadedSuccess && (
          <>
            <img src={imgUrl} alt='preview img' />
            <div className='file-status'>
              <Icons.CheckmarkFilled color='#198038' />
              <Tag filter onClose={onRemoveImage} disabled={!removable}>
                {fileName}
              </Tag>
            </div>
          </>
        )}
        {!isEmpty(imgUrl) && !imageUploadedSuccess && (
          <div className='file-status error'>
            <Icons.WarningFilled color='#da1e28' />
            <div className='gs--padding-left__sp3 gs--font-family-sf'>{files?.[0]?.errorBody}</div>
          </div>
        )}
        {isEmpty(imgUrl) && props.warningText && (
          <div className='file-status'>
            <Icons.WarningFilled color='#f1c21b' />
            <div className='gs--padding-left__sp3 gs--font-family-sf'>{props.warningText}</div>
          </div>
        )}
      </div>
    </div>
  );
};
