import React from 'react';
import styled from 'styled-components';
import { TableCell as CarbonTableCell } from 'carbon-components-react';

import styles from './styles';
import { isEmpty } from '../../../../../../utils/objectUtils';

const Cell = styled(CarbonTableCell)`
  ${styles.tableCell}
`;

const getRowInfo = ({ cell = {}, data = [], extraData = {}, tableSchema = {} }) => {
  const { value: cellValue, info = {} } = cell;
  const { header } = info;
  const schema = tableSchema[header];
  let value = cellValue;
  let customCell = null;
  let isCustomCell = false;

  if (!isEmpty(data) && !isEmpty(schema)) {
    if (typeof schema.accessor === 'function') {
      value = schema.accessor(data);
    }

    if (typeof schema.cell === 'function') {
      isCustomCell = true;
      customCell = schema.cell({ data, value, extraData });
    }
  }
  return {
    header,
    value,
    data,
    schema,
    renderCell: isCustomCell ? customCell : value
  };
};

function TableCell({ row, rowData, extraData, tableSchema, overflowMenu, hasOverflowMenu, onCellItemClick }) {
  const { id } = row;
  const data = rowData.find(item => `${item.id}` === `${id}`);
  const cellRef = React.createRef();

  return (
    <>
      {row.cells.map(cell => {
        const { renderCell, schema, ...other } = getRowInfo({
          cell,
          data,
          rowData,
          extraData,
          tableSchema
        });
        const textAlign = `align-${schema.textAlign || 'left'}`;
        return (
          <Cell
            key={cell.id}
            style={schema.style}
            ellipsis={schema.textEllipsis ? 'true' : ''}
            onClick={() => {
              onCellItemClick({
                ...other,
                id,
                schema,
                cellRef
              });
            }}
            className={`gs--body-short-01 gs--text-02 gs--table-cell ${textAlign}`}
          >
            <div ref={cellRef}>{renderCell}</div>
          </Cell>
        );
      })}
      {hasOverflowMenu ? <Cell className='bx--table-column-menu'>{overflowMenu({ row, data })}</Cell> : null}
    </>
  );
}

TableCell.defaultProps = {
  row: {},
  rowData: [],
  extraData: {},
  tableSchema: {},
  hasOverflowMenu: false,
  overflowMenu: () => null,
  onCellItemClick: () => null
};

export default TableCell;
