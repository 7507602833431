import BaseModel from './BaseModel';
import Exception from './Exception';
import { isEmpty } from '../../utils/objectUtils';

class Rate extends BaseModel {
  constructor({
    id,
    label,
    cashless,
    minPrice,
    maxPrice,
    postSeason,
    startDate,
    productTypeIds,
    productTypeNames,
    rateAmount,
    ratePercent,
    endDate,
    exceptions,
    status,
    huddleIds,
    states,
    distributionChannels,
    embedded
  }) {
    super({
      id,
      label,
      cashless,
      minPrice,
      maxPrice,
      postSeason,
      startDate,
      productTypeIds,
      productTypeNames,
      rateAmount,
      ratePercent,
      endDate,
      exceptions,
      status,
      huddleIds,
      states,
      distributionChannels,
      embedded
    });
  }

  get id() {
    return this.raw.id;
  }

  get label() {
    return this.raw.label;
  }

  get cashless() {
    return this.raw.cashless;
  }

  get minPrice() {
    return this.raw.minPrice;
  }

  get maxPrice() {
    return this.raw.maxPrice;
  }

  get postSeason() {
    return this.raw.postSeason;
  }

  get startDate() {
    return this.raw.startDate;
  }

  get productTypeIds() {
    return this.raw.productTypeIds;
  }

  get productTypeNames() {
    const { productTypes } = isEmpty(this.raw.embedded) ? {} : this.raw.embedded;

    return (productTypes || []).map(item => item.name).join('\n');
  }

  get rateAmount() {
    return this.raw.rateAmount;
  }

  get ratePercent() {
    return this.raw.ratePercent;
  }

  get endDate() {
    return this.raw.endDate;
  }

  get exceptions() {
    return this.raw.exceptions;
  }

  get status() {
    return this.raw.status;
  }

  get embedded() {
    return this.raw.embedded;
  }

  get accountExceptions() {
    return isEmpty(this.raw.embedded.exceptions)
      ? []
      : this.raw.embedded.exceptions.map(ex => new Exception(ex).toJSON());
  }

  get huddleIds() {
    return this.raw.huddleIds || [];
  }

  get states() {
    return this.raw.states || [];
  }

  get distributionChannels() {
    return this.raw.distributionChannels || [];
  }

  toJSON() {
    return {
      id: this.id,
      label: this.label,
      accountExceptions: this.accountExceptions,
      cashless: this.cashless,
      minPrice: this.minPrice,
      maxPrice: this.maxPrice,
      postSeason: this.postSeason,
      startDate: this.startDate,
      productTypeIds: this.productTypeIds,
      productTypeNames: this.productTypeNames,
      rateAmount: this.rateAmount,
      ratePercent: this.ratePercent,
      endDate: this.endDate,
      exceptions: this.exceptions,
      status: this.status,
      huddleIds: this.huddleIds,
      states: this.states,
      distributionChannels: this.distributionChannels
    };
  }
}
export default Rate;
