import { isEmpty } from 'lodash';
import { TextInput, TextInputProps } from 'carbon-components-react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

export type BasicPhoneInputConfig = TextInputProps &
  NumberFormatProps & {
    label: string;
  };

export type BasicPhoneInputProps = BasicPhoneInputConfig & {
  name: string;
  control: any;
  initialValue: string;
  fieldValue: string;
  fieldError?: string;
  getFieldRef?: () => void;
};

const BasicPhoneInput = ({
  name,
  label = '',
  initialValue,
  fieldValue,
  fieldError,
  format = '###.###.####',
  maxLength,
  getFieldRef,
  control,
  ...other
}: BasicPhoneInputProps) => (
  <NumberFormat
    {...other}
    {...control}
    id={`field-${name}`}
    getInputRef={getFieldRef}
    customInput={TextInput}
    labelText={label}
    defaultValue={initialValue}
    maxLength={maxLength}
    format={format}
    invalidText={fieldError}
    invalid={!isEmpty(fieldError)}
  />
);

export default BasicPhoneInput;
