import React from 'react';
import { Form, Search } from 'semantic-ui-react';
import styled from 'styled-components';

import FormSafeguard from './FormSafeguard';
import { FastFormContext } from './FastFormBase';
import ViewOnlyGuard from './ViewOnlyGuard';
import styles from './styles';
import { isEmpty } from '../../../utils/objectUtils';

const StyledFieldLabel = styled.label`
  ${styles.dropdown.fieldLabel};
`;

const StyledOptionalLabel = styled.span`
  ${styles.baseInput.optionalLabel};
`;

const StyledErrorMessage = styled.p`
  ${styles.search.errorMessage};
`;

const labelProps = ({ label, required, optionalLabel }) => ({
  children: (
    <StyledFieldLabel>
      {label}
      {!required && (
        <StyledOptionalLabel>{` ${optionalLabel}`}</StyledOptionalLabel>
      )}
    </StyledFieldLabel>
  )
});

const validateFormValue = (value, label, required) => {
  const errors = [];
  if (required && isEmpty(value)) {
    errors.push(`${label} is required.`);
  }
  return errors;
};

const FormSearch = ({
  label,
  formKey,
  nullText,
  searchValue,
  placeHolder,
  optionalLabel,
  loadingMessage,
  loading,
  required,
  clearable,
  overwritable,
  showPlaceHolder,
  options,
  onSearchInputChange,
  onSearchResultChange,
  ...props
}) => {
  const fastFormContext = React.useContext(FastFormContext);
  const [searching, setSearching] = React.useState(loading);
  const searchingTimerRef = React.useRef(null);
  const formValue = fastFormContext.form[formKey];
  const showClearIcon = clearable && !!searchValue;
  const placeHolderText = placeHolder || label || '';
  const pending = loading || searching;
  const classes = [
    overwritable ? 'highlighted' : '',
    showClearIcon ? 'search-clearable' : ''
  ].join(' ');

  React.useEffect(() => {
    fastFormContext.updateField(formKey, {
      ...formValue,
      required,
      inForm: true
    });
    if (!searchValue && !isEmpty(formValue) && !isEmpty(formValue.value)) {
      onSearchInputChange(formValue.value.title);
    }
  }, []);

  React.useEffect(
    () => {
      clearTimeout(searchingTimerRef.current);
      searchingTimerRef.current = setTimeout(() => setSearching(loading), 300);
      return () => {
        clearTimeout(searchingTimerRef.current);
        searchingTimerRef.current = null;
      };
    },
    [loading]
  );

  const handleClearSearchInput = React.useCallback(
    () => {
      onSearchInputChange('');
    },
    [onSearchInputChange]
  );

  const hanldeSearchInputChange = React.useCallback(
    (e, { value }) => {
      onSearchInputChange(value);
    },
    [onSearchInputChange]
  );

  const handleSearchInputBlur = React.useCallback(
    () => {
      const needClearResult =
        !isEmpty(formValue.value) &&
        `${searchValue}` !== `${formValue.value.title}`;
      const selection = needClearResult ? null : formValue.value;
      const selectionValue = isEmpty(selection) ? '' : selection.title;
      const errors = validateFormValue(selectionValue, label, required);

      fastFormContext.updateField(formKey, {
        ...formValue,
        errors,
        value: selection,
        valid: isEmpty(errors)
      });

      if (needClearResult) {
        onSearchResultChange(selection);
      }
    },
    [
      formKey,
      formValue,
      searchValue,
      onSearchResultChange,
      fastFormContext.updateField
    ]
  );

  const handleResultSelect = React.useCallback(
    (e, { result: selection }) => {
      const errors = validateFormValue(selection.title, label, required);

      fastFormContext.updateField(formKey, {
        ...formValue,
        errors,
        value: selection,
        valid: isEmpty(errors)
      });

      onSearchResultChange(selection);

      if (`${searchValue}` !== `${selection.title}`) {
        onSearchInputChange(selection.title);
      }
    },
    [
      formKey,
      searchValue,
      onSearchInputChange,
      onSearchResultChange,
      fastFormContext.updateField
    ]
  );

  return (
    <FormSafeguard formKey={formKey}>
      <ViewOnlyGuard nullText={nullText} label={label} formKey={formKey}>
        <Form.Field
          {...props}
          fluid
          control={Search}
          required={required}
          value={searchValue}
          className={classes}
          loading={pending}
          results={pending ? [] : options}
          noResultsMessage={pending ? loadingMessage : undefined}
          placeholder={showPlaceHolder ? placeHolderText : ''}
          label={labelProps({ label, required, optionalLabel })}
          input={{
            icon: 'search',
            iconPosition: 'left',
            action: showClearIcon && {
              icon: 'remove',
              className: 'clear-btn',
              onClick: handleClearSearchInput
            }
          }}
          onBlur={handleSearchInputBlur}
          onResultSelect={handleResultSelect}
          onSearchChange={hanldeSearchInputChange}
        />
        {!isEmpty(formValue.errors) &&
          formValue.errors.map(error => (
            <StyledErrorMessage key={error}>{error}</StyledErrorMessage>
          ))}
      </ViewOnlyGuard>
    </FormSafeguard>
  );
};

FormSearch.defaultProps = {
  label: '',
  formKey: '',
  nullText: '',
  searchValue: '',
  placeHolder: '',
  loadingMessage: 'Loading...',
  optionalLabel: '(optional)',
  loading: false,
  required: false,
  clearable: false,
  overwritable: false,
  showPlaceHolder: false,
  options: [],
  onSearchInputChange: () => null,
  onSearchResultChange: () => null
};

export default FormSearch;
