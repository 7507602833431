import { TableRow, TableBody as CarbonTableBody, TableSelectRow } from 'carbon-components-react';
import { isEmpty } from '@utils/objectUtils';
import TableCell from './TableCell';

function TableBody({
  rows,
  rowData,
  extraData,
  selectingRows,
  tableSchema,
  getRowProps,
  overflowMenu,
  hasOverflowMenu,
  onCellItemClick,
  onSelectRow,
  selectedRowIds,
  getSelectionProps,
  allDisableSelectedRowIds,
  haveCheckbox
}) {
  return (
    <CarbonTableBody>
      {rows.map(row => {
        const selectingRow = isEmpty(selectingRows) ? {} : selectingRows.find(item => `${item.id}` === `${row.id}`);
        const rowClassName = isEmpty(selectingRow) ? '' : `bx--data-table--selected ${selectingRow.rowClassName || ''}`;

        const selectionProps = getSelectionProps({ row });
        const customizedSelectionProps = {
          ...selectionProps,
          onSelect: e => {
            e.stopPropagation();
            onSelectRow?.(row.id);
            selectionProps.onSelect(e);
          }
        };

        return (
          <TableRow
            key={row.id}
            {...getRowProps({
              row,
              className: rowClassName
            })}
          >
            {haveCheckbox && (
              <TableSelectRow
                {...customizedSelectionProps}
                checked={selectedRowIds.includes(row.id)}
                disabled={allDisableSelectedRowIds.includes(row.id)}
              />
            )}

            <TableCell
              row={row}
              rowData={rowData}
              extraData={extraData}
              tableSchema={tableSchema}
              overflowMenu={overflowMenu}
              hasOverflowMenu={hasOverflowMenu}
              onCellItemClick={onCellItemClick}
            />
            {!isEmpty(selectingRow) && typeof selectingRow.render === 'function' ? selectingRow.render() : null}
          </TableRow>
        );
      })}
    </CarbonTableBody>
  );
}

TableBody.defaultProps = {
  rows: [],
  rowData: [],
  extraData: {},
  tableSchema: {},
  selectingRows: [],
  hasOverflowMenu: false,
  getRowProps: () => null,
  overflowMenu: () => null,
  onCellItemClick: () => null,
  getSelectionProps: () => null
};

export default TableBody;
