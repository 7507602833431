import * as React from 'react';
import { Modal, Grid, Loader, Dimmer, Header } from 'semantic-ui-react';

import FastForm from '../../../commons/FastForm';
import { changeMyPassword } from '@gofan/api';

const formInitializer = () =>
  Promise.resolve({
    password: '',
    newPassword: '',
    newPasswordConfirmation: ''
  });

const newPasswordValidation = (value: string): boolean =>
  value.length >= 8 &&
  value.length <= 20 &&
  /[A-Z]{1}/.test(value) &&
  /\d{1}/.test(value);

interface Props {
  whoami: string;
  open: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  history: any;
}

export const PasswordChangeModal: React.SFC<Props> = ({
  whoami,
  open,
  setModalOpen,
  history
}) => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [passwordValid, setPasswordValid] = React.useState<boolean>(true);
  const [newPasswordValid, setNewPasswordValid] = React.useState<boolean>(true);
  const [
    newPasswordConfirmationValid,
    setNewPasswordConfirmationValid
  ] = React.useState<boolean>(true);
  React.useEffect(
    () => () => {
      setError(null);
      setPasswordValid(true);
      setNewPasswordValid(true);
      setNewPasswordConfirmationValid(true);
    },
    []
  );
  const submitAction = (values: any): void => {
    if (values.newPassword !== values.newPasswordConfirmation) {
      setError('New Password and New Password Confirmation do not match.');
      setNewPasswordValid(false);
      setNewPasswordConfirmationValid(false);
      return;
    }
    setSubmitting(true);
    changeMyPassword(whoami, values.password, values.newPassword)
      .then(() => {
        setSubmitting(false);
        setModalOpen(false);
        history.push('/');
      })
      .catch(err => {
        setSubmitting(false);
        console.log(err);
        switch (err.code) {
          case 'auth/wrong-password':
            setError('Unable to verify your current password.');
            setPasswordValid(false);
            break;
          case 'auth/too-many-requests':
            setError(
              "You've tried this too many times. Please wait and try again later, or contact support."
            );
            setPasswordValid(false);
            break;
          default:
            setError('An unknown error occurred');
        }
      });
  };
  const cancelAction = () => {
    setModalOpen(false);
  };
  return (
    <Modal open={open} size='tiny'>
      <Dimmer active={submitting}>
        <Loader />
      </Dimmer>
      <Header content='Change Password'/>
      <Modal.Content>
        <FastForm
          cancelAction={cancelAction}
          formInitializer={formInitializer}
          submitAction={submitAction}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <p>Confirm your current password.</p>
                <FastForm.Password
                  formKey='password'
                  label='Current Password'
                  required
                  error={!passwordValid}
                />
                <FastForm.Password
                  required
                  formKey='newPassword'
                  label='New Password'
                  validation={newPasswordValidation}
                  error={!newPasswordValid}
                />
                <ul>
                  <li>Must be 8 to 20 characters long</li>
                  <li>At least 1 capital letter</li>
                  <li>At least 1 number</li>
                </ul>
                <FastForm.Password
                  required
                  formKey='newPasswordConfirmation'
                  label='Confirm New Password'
                  validation={newPasswordValidation}
                  error={!newPasswordConfirmationValid}
                />
                {error && (
                  <Header as='h5' color='red'>
                    {error}
                  </Header>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </FastForm>
      </Modal.Content>
    </Modal>
  );
};

export default PasswordChangeModal;
