import {
  UserHistoryActionTypes,
  RESET_STATE,
  FETCH_USER_HISTORY,
  FETCH_USER_HISTORY_COMPLETED
} from './actionTypes';

import { UserHistoryParams, UserHistoryResult } from './types';

export const resetState = (): UserHistoryActionTypes => ({
  type: RESET_STATE
});

export const fetchUserHistory = (
  searchParams: UserHistoryParams
): UserHistoryActionTypes => ({
  type: FETCH_USER_HISTORY,
  payload: {
    searchParams
  }
});

export const fetchUserHistoryCompleted = (
  histories: UserHistoryResult
): UserHistoryActionTypes => ({
  type: FETCH_USER_HISTORY_COMPLETED,
  payload: {
    histories
  }
});
