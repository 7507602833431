import ErrorBoundary from '@app/commons/ErrorBoundary';
import { PAGES } from '@app/config/routes';
import { RootContext } from '@app/RootContext';
import { useContext, useEffect } from 'react';
import { useHistory, Route } from 'react-router-dom';

export default function UnauthGuardedRoute({ render, ...routeProps }) {
  const history = useHistory();
  const { authBody } = useContext(RootContext);

  useEffect(() => {
    if (authBody) {
      history.replace(PAGES.dashboard.root.path);
    }
  }, [history, authBody]);

  return (
    <Route key='unauthGuardedRoute' {...routeProps} render={props => <ErrorBoundary>{render(props)}</ErrorBoundary>} />
  );
}
