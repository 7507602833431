import { PromotionTypeEnum } from '@gofan/api/promotions';
import type { FormState } from './PromotionCodes';

export const PROMOTION_CODES_TITLE = 'Access and promo codes';
export const RESERVED_SEATING_EVENT_PROMOTION_CODES_TITLE = 'Promo Codes';

export const ACCESS_LEVEL = {
  EVENT: 'EVENT',
  TICKET: 'TICKET'
};

export const ACCESS_CODE_UPLOAD_STATUS = {
  NONE: 'NONE',
  UPLOADING: 'UPLOADING',
  ERROR: 'ERROR',
  COMPLETED: 'COMPLETED'
};

export const PROMOTION_CODE_UPLOAD_STATUS = {
  NONE: 'NONE',
  UPLOADING: 'UPLOADING',
  ERROR: 'ERROR',
  COMPLETED: 'COMPLETED'
};

export const EDIT_PROMOTION_CODES_MESSAGES = {
  EDIT_ALL_CODES_SUCCESSFUL: 'All promotionType were successfully updated',
  EDIT_ONE_CODE_SUCCESSFUL: 'Your promotionType was successfully updated',
  EDIT_CODES_SUCCESSFUL: 'promotionType were successfully updated',
  DELETE_CODES_SUCCESSFUL: 'was successfully deleted.',
  DELETE_ALL_CODES_SUCCESSFUL: 'All promotionType were successfully deleted.'
};

export const ACCESS_CODES_HEADERS = ['code', 'limit', 'starts_at', 'ends_at'];

export const PROMOTION_CODES_HEADERS = {
  [PromotionTypeEnum.ACCESS_CODE]: ['code', 'limit', 'starts_at', 'ends_at'],
  [PromotionTypeEnum.PROMOTION_CODE]: ['code', 'limit', 'starts_at', 'ends_at', 'discount_type', 'discount_value']
};

export const MIN_DISCOUNT_VALUES: {
  [k: string]: number;
} = {
  ACCESS_CODE: 0,
  PROMOTION_CODE: 1
};

export const EDITABLE_FORM_FIELDS: (keyof FormState)[] = [
  'isSettingDiscount',
  'discountType',
  'discountValue',
  'ticketsPerCode',
  'unlimitedTickets',
  'startDateTimeInput',
  'endDateTimeInput',
  'uniqueCodesInput',
  'randomCodes',
  'tickets'
];
