import { DataTableSortState } from 'carbon-components-react/lib/components/DataTable/state/sorting';
import { isEmpty } from '@utils/objectUtils';

export type SortBy = {
  header?: string;
  sortDirection?: DataTableSortState;
  direction?: string;
}

export const sortStates: DataTableSortStates = {
  ASC: 'ASC',
  NONE: 'NONE',
  DESC: 'DESC'
};

export const getSortableProps = ({ header, sortBy = {}, isSortable }) => {
  const unSortProps = { isSortHeader: false, sortDirection: sortStates.NONE };
  if (!isSortable || isEmpty(sortBy) || `${header}` !== `${sortBy.header}`) {
    return unSortProps;
  }
  return {
    isSortHeader: true,
    sortDirection:
      `${sortBy.sortDirection}`.toUpperCase() === sortStates.DESC
        ? sortStates.DESC
        : sortStates.ASC
  };
};

export const getNextSortDirection = ({ header, sortBy = {} }) => {
  if (isEmpty(sortBy)) return sortStates.ASC;

  const prevSortable = getSortableProps({ header, sortBy, isSortable: true });
  if (prevSortable.sortDirection === sortStates.NONE) {
    return sortStates.ASC;
  }
  if (prevSortable.sortDirection === sortStates.ASC) {
    return sortStates.DESC;
  }
  return sortStates.NONE;
};
