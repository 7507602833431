import React from 'react';
import { Accordion, Menu, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import styles from './styles';

const AccordionWrapper = styled(Accordion)`
  ${styles.accordionWrapper};
`;

const AccordionHeaderWrapper = styled.div`
  ${styles.accordionHeader};
`;
const AccordionTitleWrapper = styled.div`
  ${styles.accordionTitle};
`;
const AccordionIConWrapper = styled(Icon)`
  ${styles.accordionIcon};
`;

const AccordionMenuItemWrapper = styled(Menu.Item)`
  ${styles.accordionMenuItem};
`;

const AccordionContentWrapper = styled(Accordion.Content)`
  ${styles.accordionContent};
`;

export default ({ title, onClick, index, active, content }) => (
  <AccordionWrapper as={Menu} vertical>
    <AccordionMenuItemWrapper>
      <Accordion.Title active={active} index={index} onClick={onClick}>
        <AccordionHeaderWrapper>
          <AccordionTitleWrapper>{title}</AccordionTitleWrapper>
          <AccordionIConWrapper name={active ? 'caret up' : 'caret down'} />
        </AccordionHeaderWrapper>
      </Accordion.Title>
      <AccordionContentWrapper
        active={active}
        content={active ? content : null}
      />
    </AccordionMenuItemWrapper>
  </AccordionWrapper>
);
