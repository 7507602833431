import { useQuery } from '@tanstack/react-query';
import { VenueService } from './venue.service';

interface BaseQueryOptions {
  cacheTime?: number;
  staleTime?: number;
  enabled?: boolean;
}

export interface GetVenueByIdsProps {
  ids: string[];
  queryOptions: BaseQueryOptions;
}

export const VenuesQuery = {
  getVenuesByIds: ({ ids, queryOptions }: GetVenueByIdsProps) =>
    useQuery({
      queryKey: [VENUES_QUERY_KEY, { ids }],
      queryFn: () => VenueService.getVenuesById(ids),
      ...queryOptions
    })
};

export const VENUES_QUERY_KEY = 'venues';
