/* eslint-disable no-template-curly-in-string */

const breadcrumbs = {
  user: [
    { title: 'Users' },
    {
      title: '<span style="font-weight:bold;">${params.email}</span>'
    }
  ],
  account: [
    { title: 'Accounts' },
    {
      title: '<span style="font-weight:bold;">${params.accountName}</span> (${params.id})'
    }
  ],
  profile: [
    { title: 'Accounts' },
    {
      title: '<span style="font-weight:bold;">${params.accountName}</span> (${params.id})'
    },
    { title: 'Profiles', path: '/accounts/${params.id}/financial' }
  ]
};

export const PAGES = {
  error: {
    root: {
      path: '/error'
    },
    error401: {
      path: '/error/401'
    },
    error403: {
      path: '/error/403'
    },
    error404: {
      path: '/error/404'
    },
    error500: {
      path: '/error/500'
    },
    error502: {
      path: '/error/502'
    },
    error503: {
      path: '/error/503'
    }
  },
  home: {
    root: {
      path: '/',
      title: 'Home'
    }
  },
  dashboard: {
    root: {
      path: '/dashboard',
      title: 'Dashboard',
      menuTitle: 'Dashboard'
    }
  },
  profile: {
    root: {
      path: '/profile',
      title: 'Profile'
    }
  },
  seasons: {
    root: {
      path: '/seasons',
      title: 'Seasons',
      menuTitle: 'Seasons'
    }
  },
  reports: {
    root: {
      path: '/reports',
      title: 'Reports'
    }
  },
  offers: {
    root: {
      path: '/offers',
      title: 'Offers'
    }
  },
  auth: {
    login: {
      path: '/login',
      title: 'Login'
    }
  },

  // TODO: eventually move all routes from login: { } to auth: { }
  login: {
    root: {
      path: '/login',
      title: 'Login'
    },
    forgotPassword: {
      path: '/login/forgot-password'
    },
    forgotPasswordV2: {
      path: '/login/forgot-password/v2'
    },
    createPassword: {
      path: '/login/create-password'
    },
    createPasswordSuccesfully: {
      path: '/login/create-password-successfully'
    },
    successResetPassword: {
      path: '/login/success-reset'
    },
    resetPassword: {
      path: '/login/reset-password'
    }
  },
  checkEmail: {
    root: {
      path: '/check-email/:email',
      title: 'Check Mail'
    }
  },
  accountsV2: {
    root: {
      path: '/accounts',
      title: 'Accounts',
      menuTitle: 'Accounts'
    },
    landing: {
      path: '/accounts/:accountId',
      title: 'Accounts',
      calculatedPath: accountId => PAGES.accountsV2.landing.path.replace(':accountId', accountId)
    },
    details: {
      path: '/accounts/:accountId/details',
      title: 'Accounts',
      calculatedPath: accountId => PAGES.accountsV2.details.path.replace(':accountId', accountId)
    },
    contacts: {
      path: '/accounts/:accountId/contacts',
      title: 'View contacts',
      calculatedPath: accountId => PAGES.accountsV2.contacts.path.replace(':accountId', accountId)
    },
    contactsAdd: {
      path: '/accounts/:accountId/contacts/new',
      title: 'Create contact',
      calculatedPath: accountId => PAGES.accountsV2.contactsAdd.path.replace(':accountId', accountId)
    },
    contactEdit: {
      path: '/accounts/:accountId/contacts/:email/edit',
      title: 'Edit contact',
      calculatedPath: (account, email) => {
        const emailEncoded = encodeURIComponent(email);
        return PAGES.accountsV2.contactEdit.path.replace(':accountId', account).replace(':email', emailEncoded);
      }
    },
    tickets: {
      path: '/accounts/:accountId/tickets',
      title: 'Default ticket prices',
      calculatedPath: accountId => PAGES.accountsV2.tickets.path.replace(':accountId', accountId)
    },
    gofanPage: {
      path: '/accounts/:accountId/gofan-page',
      title: 'GoFan Page',
      breadcrumbs: breadcrumbs.account,
      calculatedPath: accountId => PAGES.accountsV2.gofanPage.path.replace(':accountId', accountId)
    },
    financial: {
      path: '/accounts/:accountId/financial',
      title: 'Financial settings',
      calculatedPath: accountId => PAGES.accountsV2.financial.path.replace(':accountId', accountId),
      getBreadcrumbs: () => [
        {
          path: PAGES.financial.root.path,
          name: PAGES.financial.root.title
        },
        { path: PAGES.accountsV2.financial.path, name: PAGES.accountsV2.financial.title }
      ]
    },
    teamAdd: {
      path: '/accounts/:accountId/teams/new',
      title: 'Create Team',
      calculatedPath: accountId => PAGES.accountsV2.teamAdd.path.replace(':accountId', accountId)
    },
    teamDashboard: {
      path: '/accounts/:accountId/teams/dashboard',
      title: 'Team Dashboard',
      calculatedPath: ({ accountId, ...queries }) =>
        PAGES.accountsV2.teamDashboard.path
          .replace(':accountId', accountId)
          .concat('?')
          .concat(new URLSearchParams(queries).toString())
    },
    rosters: {
      activity: {
        path: '/accounts/:accountId/teams/rosters',
        title: 'Rosters',
        calculatedPath: ({ accountId, ...queries }) =>
          PAGES.accountsV2.rosters.activity.path
            .replace(':accountId', accountId)
            .concat('?')
            .concat(new URLSearchParams(queries).toString())
      },
      root: {
        path: '/accounts/:accountId/teams/:teamId/rosters',
        title: 'Rosters',
        calculatedPath: ({ accountId, teamId, ...queries }) =>
          PAGES.accountsV2.rosters.root.path
            .replace(':accountId', accountId)
            .replace(':teamId', teamId)
            .concat('?')
            .concat(new URLSearchParams(queries).toString())
      },
      create: {
        path: '/accounts/:accountId/teams/:teamId/rosters/:viewMode',
        title: 'Rosters',
        calculatedPath: ({ accountId, teamId, ...queries }) =>
          PAGES.accountsV2.rosters.create.path
            .replace(':viewMode', 'create')
            .replace(':accountId', accountId)
            .replace(':teamId', teamId)
            .concat('?')
            .concat(new URLSearchParams(queries).toString())
      },
      edit: {
        path: '/accounts/:accountId/teams/:teamId/rosters/:viewMode',
        title: 'Edit Rosters',
        calculatedPath: ({ accountId, teamId, ...queries }) =>
          PAGES.accountsV2.rosters.edit.path
            .replace(':viewMode', 'edit')
            .replace(':accountId', accountId)
            .replace(':teamId', teamId)
            .concat('?')
            .concat(new URLSearchParams(queries).toString())
      }
    },
    athleticSiteEdit: {
      path: '/accounts/:accountId/athletic-site/edit',
      title: 'Customize School Site',
      calculatedPath: accountId => PAGES.accountsV2.athleticSiteEdit.path.replace(':accountId', accountId)
    },
    districtSettings: {
      path: '/accounts/:accountId/district-settings',
      title: 'District Settings',
      calculatedPath: accountId => PAGES.accountsV2.districtSettings.path.replace(':accountId', accountId)
    },
    eventMonitorSettings: {
      path: '/accounts/:accountId/event-monitor-settings',
      title: 'Event Monitor Settings',
      calculatedPath: accountId => PAGES.accountsV2.eventMonitorSettings.path.replace(':accountId', accountId)
    }
  },
  accounts: {
    root: {
      path: '/accounts',
      title: 'Accounts',
      menuTitle: 'Accounts'
    },
    view: {
      path: '/accounts/:id',
      title: 'View Account',
      breadcrumbs: breadcrumbs.account,
      calculatedPath: account => PAGES.accounts.view.path.replace(':id', account)
    },
    create: {
      path: '/accounts/new',
      title: 'New Account',
      breadcrumbs: breadcrumbs.account
    },
    edit: {
      path: '/accounts/:id/edit',
      title: 'Edit Account',
      breadcrumbs: breadcrumbs.account,
      calculatedPath: account => PAGES.accounts.edit.path.replace(':id', account)
    },
    contact: {
      path: '/accounts/:id/contacts',
      title: 'Account Contacts',
      breadcrumbs: breadcrumbs.account,
      calculatedPath: account => PAGES.accounts.contact.path.replace(':id', account)
    },
    contactCreate: {
      path: '/accounts/:id/contacts/new',
      title: 'Add Account Contact',
      breadcrumbs: breadcrumbs.account,
      calculatedPath: account => PAGES.accounts.contactCreate.path.replace(':id', account)
    },
    contactEdit: {
      path: '/accounts/:id/contacts/:email/edit',
      title: 'Edit Account Contact',
      breadcrumbs: breadcrumbs.account,
      calculatedPath: (account, email, createMode = false) => {
        const emailEncoded = encodeURIComponent(email);
        return PAGES.accounts.contactEdit.path
          .replace(':id', account)
          .replace(':email', emailEncoded)
          .concat(`?createMode=${createMode}`);
      }
    },
    contactView: {
      path: '/accounts/:id/contacts/:email/view',
      title: 'View Account Contact',
      breadcrumbs: breadcrumbs.account,
      calculatedPath: (account, email, createMode = false) => {
        const emailEncoded = encodeURIComponent(email);
        return PAGES.accounts.contactView.path
          .replace(':id', account)
          .replace(':email', emailEncoded)
          .concat(`?createMode=${createMode}`);
      }
    },
    relationship: {
      path: '/accounts/:id/relationships',
      title: 'Account Relationships',
      breadcrumbs: breadcrumbs.account,
      calculatedPath: account => PAGES.accounts.relationship.path.replace(':id', account)
    },
    financial: {
      path: '/accounts/:id/financial',
      title: 'Account Financial Profile',
      breadcrumbs: breadcrumbs.account,
      calculatedPath: account => PAGES.accounts.financial.path.replace(':id', account)
    },
    ticket: {
      path: '/accounts/:id/tickets',
      title: 'Account Tickets',
      breadcrumbs: breadcrumbs.account,
      calculatedPath: account => PAGES.accounts.ticket.path.replace(':id', account)
    },
    ticketAdd: {
      path: '/accounts/:id/tickets/add',
      title: 'Add Account Tickets',
      breadcrumbs: breadcrumbs.account,
      calculatedPath: accountId => PAGES.accounts.ticketAdd.path.replace(':id', accountId)
    },
    ticketEdit: {
      path: '/accounts/:id/tickets/:ticketId/edit',
      title: 'Edit Account Ticket',
      breadcrumbs: breadcrumbs.account,
      calculatedPath: (accountId, ticketId) =>
        PAGES.accounts.ticketEdit.path.replace(':id', accountId).replace(':ticketId', ticketId)
    },

    profileView: {
      path: '',
      title: 'View Check Financial Profile',
      breadcrumbs: breadcrumbs.profile
    },
    profileEdit: {
      path: '',
      title: 'Edit Check Financial Profile',
      breadcrumbs: breadcrumbs.profile
    },
    aCHProfileView: {
      path: '',
      title: 'View ACH Financial Profile',
      breadcrumbs: breadcrumbs.profile
    },
    aCHProfileEdit: {
      path: '',
      title: 'Edit ACH Financial Profile',
      breadcrumbs: breadcrumbs.profile
    },
    profileAdd: {
      path: '',
      title: 'Add Check Financial Profile',
      breadcrumbs: breadcrumbs.profile
    },
    gofanPage: {
      path: '/accounts/:id/gofanPage',
      title: 'GoFan Page',
      breadcrumbs: breadcrumbs.account,
      calculatedPath: account => PAGES.accounts.gofanPage.path.replace(':id', account)
    }
  },
  fanSupport: {
    root: {
      path: '/refunds',
      title: 'Refunds',
      menuTitle: 'Refunds'
    },
    purchasedTickets: {
      path: '/refunds/tickets',
      title: 'Refunds'
    },
    events: {
      path: '/refunds/events',
      title: 'Refunds'
    },
    seasonPasses: {
      path: '/refunds/season-pass',
      title: 'Refunds'
    }
  },
  events: {
    root: {
      path: '/events',
      title: 'Events',
      menuTitle: 'Events',
      breadcrumbs: {
        account: [],
        accounts: breadcrumbs.account
      },
      calculatePath: accountId => `${PAGES.events.root.path}?accountId=${accountId}`
    },
    add: {
      path: '/events/new',
      title: {
        event: 'Add Event',
        ticket: 'Add Tickets'
      },
      breadcrumbs: {
        account: [{ title: 'Events' }],
        accounts: breadcrumbs.account
      },
      calculatePath: accountId => `${PAGES.events.add.path}?accountId=${accountId}`
    },
    addV2: {
      path: '/events/new-v2',
      title: {
        event: 'Add Event',
        ticket: 'Add Tickets'
      },
      breadcrumbs: {
        account: [{ title: 'Events' }],
        accounts: breadcrumbs.account
      },
      calculatePath: accountId => `${PAGES.events.add.path}?accountId=${accountId}`
    },
    edit: {
      path: '/events/edit',
      title: {
        event: 'Edit Event',
        ticket: 'Edit Tickets'
      },
      breadcrumbs: {
        account: [
          { title: 'Events' },
          {
            title: '<span style="font-weight:bold;">${params.eventName}</span>'
          }
        ],
        accounts: [
          { title: 'Accounts' },
          {
            title: '<span style="font-weight:bold;">${params.accountName}</span> (${params.id})',
            path: '/events?accountId=${params.id}'
          },
          {
            title: '<span style="font-weight:bold;">${params.eventName}</span>'
          }
        ]
      },
      calculatePath: (accountId = '', eventId = '') =>
        `${PAGES.events.edit.path}?accountId=${accountId}&eventId=${eventId}`
    },
    account: {
      path: '/events/accounts',
      title: ' Accounts'
    }
  },
  eventsV2: {
    root: {
      path: '/events/account/:accountId',
      title: 'Events',
      menuTitle: 'Events',
      breadcrumbs: {
        account: [],
        accounts: breadcrumbs.account
      },
      calculatePath: accountId => `${PAGES.eventsV2.root.path}`.replace(':accountId', accountId)
    },
    add: {
      path: '/events/new',
      title: {
        event: 'Add Event',
        ticket: 'Add Tickets'
      },
      breadcrumbs: {
        account: [{ title: 'Events' }],
        accounts: breadcrumbs.account
      },
      calculatePath: (accountId, type, streaming) => {
        const eventType = type ? `&type=${encodeURIComponent(type)}` : '';
        const streamingEnabled = streaming ? `&streaming=true` : '';
        return `${PAGES.eventsV2.add.path}?accountId=${accountId}${eventType}${streamingEnabled}`;
      }
    },
    view: {
      path: '/events/:eventId/view',
      title: {
        event: 'View Event'
      },
      breadcrumbs: {
        account: [{ title: 'Events' }],
        accounts: breadcrumbs.account
      },
      calculatePath: (eventId, params) =>
        `${PAGES.eventsV2.view.path}${params?.withStreaming === true ? '?createStreaming=true' : ''}`.replace(
          ':eventId',
          eventId
        )
    },
    edit: {
      path: '/events/:eventId/edit',
      title: {
        event: 'Edit event'
      },
      breadcrumbs: {
        account: [{ title: 'Events' }],
        accounts: breadcrumbs.account
      },
      calculatePath: (eventId, step, hash = '', conferenceAccount = '') => {
        const urlSearchParam = new URLSearchParams({ conferenceAccount, step });
        if (!step) urlSearchParam.delete('step');
        if (!conferenceAccount) urlSearchParam.delete('conferenceAccount');
        const query = urlSearchParam.toString() ? `?${urlSearchParam.toString()}` : '';
        return `${PAGES.eventsV2.edit.path}${query}${hash}`.replace(':eventId', eventId);
      }
    },
    reservedSeating: {
      path: '/events/:eventId/reservedSeating',
      title: {
        event: 'Reserved Seating'
      },
      breadcrumbs: {
        account: [{ title: 'Events' }],
        accounts: breadcrumbs.account
      },
      calculatePath: (eventId, step) =>
        `${PAGES.eventsV2.reservedSeating.path}?step=${step}`.replace(':eventId', eventId)
    },
    viewAllEvents: {
      path: '/events',
      title: 'User events',
      menuTitle: 'Events'
    },
    accounts: {
      path: '/events/accounts',
      title: 'Accounts'
    },
    bulkUploadEvents: {
      path: '/events/bulk-upload-events/:batchId',
      title: 'Bulk upload events',
      calculatePath: batchId => `${PAGES.eventsV2.bulkUploadEvents.path}`.replace(':batchId', batchId)
    },
    bulkEditEvents: {
      path: '/events/bulk-edit-events/:accountId/:eventType',
      title: 'Bulk edit events',
      calculatePath: (accountId, eventType) =>
        `${PAGES.eventsV2.bulkEditEvents.path}`.replace(':accountId', accountId).replace(':eventType', eventType)
    }
  },
  streaming: {
    root: {
      path: '/streaming',
      title: 'Streaming Event'
    },
    createEvent: {
      path: '/streaming/events/new',
      title: 'Create Streaming Event',
      calculatePath: (accountId, type) => {
        const eventType = type ? `&type=${encodeURIComponent(type)}` : '';
        return `${PAGES.streaming.createEvent.path}?accountId=${accountId}${eventType}`;
      }
    },
    editEvent: {
      path: '/streaming/events/edit/:eventId',
      title: 'Edit Streaming Event',
      calculatePath: (eventId, accountId) => `/streaming/events/edit/${eventId}?accountId=${accountId}`
    }
  },
  concessions: {
    root: {
      path: '/concessions',
      title: 'Concessions'
    },
    eventEditor: {
      create: {
        path: '/concessions/event/create',
        title: 'Add concession',
        calculatePath: accountId => `/concessions/event/create?accountId=${accountId}`
      },
      edit: {
        path: '/concessions/event/:concessionId',
        title: 'Edit concession',
        calculatePath: (concessionId, accountId) => `/concessions/event/${concessionId}?accountId=${accountId}`
      }
    },
    productsEditor: {
      create: { path: '/concessions/products/create', title: 'Add concession products' },
      edit: {
        path: '/concessions/products/:concessionId',
        title: 'Edit concession products',
        calculatePath: concessionId => `/concessions/products/${concessionId}`
      }
    },
    view: {
      path: '/concessions/view/:concessionId',
      title: 'View concession',
      calculatePath: concessionId => `/concessions/view/${concessionId}`
    }
  },
  playOnSite: {
    root: {
      path: '/play-on',
      title: 'Play On Site'
    },
    management: {
      path: '/play-on/:accountId/site-content',
      title: 'Manage Site Content',
      calculatePath: accountId => `/play-on/${accountId}/site-content`
    }
  },
  sponsorships: {
    root: {
      path: '/sponsorships',
      title: 'Sponsorships'
    },
    create: {
      path: '/sponsorships/create',
      title: 'Create Sponsorship',
      calculatePath: `/sponsorships/create`
    },
    edit: {
      path: '/sponsorships/:sponsorId/edit',
      title: 'Edit Sponsorship',
      calculatePath: sponsorId => `/sponsorships/${sponsorId}/edit`
    },
    list: {
      path: '/sponsorships/:accountId/list',
      title: 'Sponsors List',
      calculatePath: accountId => `/sponsorships/${accountId}/list`
    },
    listAllSponsors: {
      path: '/sponsorships/list',
      title: 'Sponsors List',
      calculatePath: `/sponsorships/list`
    }
  },
  practiceSchedule: {
    root: {
      path: '/practice-schedule',
      title: 'Practice Schedule'
    },
    create: {
      path: '/practice-schedule/:accountId/create',
      title: 'Create Practice Schedule',
      calculatePath: (accountId, teamId, activityId, schoolYear, practiceId) =>
        PAGES.practiceSchedule.create.path
          .replace(':accountId', accountId)
          .concat(
            `?${teamId ? `teamId=${teamId}` : ''}&activityId=${activityId}&schoolYear=${schoolYear}${
              practiceId ? `&practiceId=${practiceId}` : ''
            }`
          )
    }
  },
  seasonManagement: {
    root: {
      path: '/season-management',
      menuTitle: 'Seasons',
      title: 'Seasons',
      calculatePath: () => PAGES.seasonManagement.root.path
    },
    allSeasons: {
      path: '/season-management/:accountId/all',
      menuTitle: 'Seasons',
      title: 'View seasons',
      calculatePath: accountId => `${PAGES.seasonManagement.allSeasons.path}`.replace(':accountId', accountId)
    },
    create: {
      path: '/season-management/:accountId/create',
      menuTitle: 'Seasons',
      title: 'Create seasons',
      calculatePath: accountId => `${PAGES.seasonManagement.create.path}`.replace(':accountId', accountId)
    },
    seasonMultiSportSetup: {
      path: '/season-management/:accountId/create/multip-sport-setup',
      menuTitle: 'Seasons',
      title: 'Create seasons',
      calculatePath: accountId =>
        `${PAGES.seasonManagement.seasonMultiSportSetup.path}`.replace(':accountId', accountId)
    },
    seasonGeneralSetup: {
      path: '/season-management/:accountId/create/general-setup',
      menuTitle: 'Seasons',
      title: 'Create seasons',
      calculatePath: accountId => `${PAGES.seasonManagement.seasonGeneralSetup.path}`.replace(':accountId', accountId)
    },
    seasonEventSetup: {
      path: '/season-management/:accountId/create/event-setup',
      menuTitle: 'Seasons',
      title: 'Create seasons',
      calculatePath: accountId => `${PAGES.seasonManagement.seasonEventSetup.path}`.replace(':accountId', accountId)
    },
    seasonEventDetails: {
      path: '/season-management/:accountId/create/event-details',
      menuTitle: 'Seasons',
      title: 'Create seasons',
      calculatePath: accountId => `${PAGES.seasonManagement.seasonEventDetails.path}`.replace(':accountId', accountId)
    }
  },
  season: {
    root: {
      path: '/seasons/account/:accountId',
      title: 'Seasons',
      menuTitle: 'Seasons',
      breadcrumbs: {
        account: [],
        accounts: breadcrumbs.account
      },
      calculatePath: accountId => `${PAGES.season.root.path}`.replace(':accountId', accountId)
    },
    add: {
      path: '/seasons/new',
      title: {
        event: 'Add Season',
        ticket: 'Add Season'
      },
      breadcrumbs: {
        account: [{ title: 'Seasons' }],
        accounts: breadcrumbs.account
      },
      calculatePath: accountId => `${PAGES.season.add.path}?accountId=${accountId}`
    },
    edit: {
      path: '/seasons/:seasonId',
      title: {
        event: 'View season events'
      },
      breadcrumbs: {
        account: [{ title: 'Seasons' }],
        accounts: breadcrumbs.account
      },
      calculatePath: (seasonId, accountId = '', financialAccountId = '') =>
        `${PAGES.season.edit.path.replace(
          ':seasonId',
          seasonId
        )}?accountId=${accountId}&financialAccountId=${financialAccountId}`
    },
    view: {
      path: '/seasons/:seasonId/view',
      title: 'View Season',
      calculatePath: seasonId => `${PAGES.season.view.path.replace(':seasonId', seasonId)}`
    },
    viewAllSeasons: {
      path: '/seasons',
      title: 'Our seasons',
      menuTitle: 'Seasons'
    },
    account: {
      path: '/seasons/accounts',
      title: ' Accounts'
    },
    // Seasons reserved seating route
    reservedSeating: {
      path: '/seasons/:seasonId/reservedSeating',
      title: {
        event: 'Reserved Seating'
      },
      breadcrumbs: {
        account: [{ title: 'Seasons' }],
        accounts: breadcrumbs.account
      },
      calculatePath: (seasonId, step) =>
        `${PAGES.season.reservedSeating.path}?step=${step}`.replace(':seasonId', seasonId)
    }
  },
  // Tournaments ============================
  tournaments: {
    root: {
      path: '/tournaments',
      title: 'Tournaments',
      menuTitle: 'Tournaments'
    },
    add: {
      root: {
        path: '/tournaments/create',
        title: 'Create tournament form'
      },
      divisionsConfig: {
        path: '/tournaments/create/divisions-configuration',
        title: 'Configure tournament divisions'
      },
      manageRounds: {
        path: '/tournaments/create/manage-rounds',
        title: 'Manage division rounds'
      },
      additionalOptions: {
        path: '/tournaments/create/additional-options',
        title: 'Additional options'
      },
      tournamentReview: {
        path: '/tournaments/create/review',
        title: 'Review tournament'
      },
      complete: {
        path: '/tournaments/create/complete',
        title: 'Tournament complete'
      }
    },
    manage: {
      root: {
        path: '/tournaments/:tournamentId/configuration',
        title: 'Edit tournament configuration',
        calculatePath: tournamentId => `${PAGES.tournaments.manage.root.path}`.replace(':tournamentId', tournamentId)
      },
      manageRounds: {
        path: '/tournaments/:tournamentId/divisions/:divisionId/manage-rounds',
        title: 'Manage division',
        calculatePath: (tournamentId, divisionId) =>
          `${PAGES.tournaments.manage.manageRounds.path}`
            .replace(':tournamentId', tournamentId)
            .replace(':divisionId', divisionId)
      },
      divisionsConfig: {
        path: '/tournaments/:tournamentId/divisions-configuration',
        title: 'Configure tournament divisions',
        calculatePath: tournamentId =>
          `${PAGES.tournaments.manage.divisionsConfig.path}`.replace(':tournamentId', tournamentId)
      },
      additionalOptions: {
        path: '/tournaments/:tournamentId/additional-options',
        title: 'Additional options',
        calculatePath: tournamentId =>
          `${PAGES.tournaments.manage.additionalOptions.path}`.replace(':tournamentId', tournamentId)
      },
      tournamentReview: {
        path: '/tournaments/:tournamentId/review',
        title: 'Review tournament',
        calculatePath: tournamentId =>
          `${PAGES.tournaments.manage.tournamentReview.path}`.replace(':tournamentId', tournamentId)
      }
    },
    accountTournaments: {
      root: {
        path: '/tournaments/account/:accountId',
        title: 'Tournaments',
        menuTitle: 'Tournaments',
        calculatePath: accountId => `${PAGES.tournaments.accountTournaments.root.path}`.replace(':accountId', accountId)
      },
      add: {
        root: {
          path: '/tournaments/account/:accountId/create',
          title: 'Create tournament form',
          calculatePath: accountId =>
            `${PAGES.tournaments.accountTournaments.add.root.path}`.replace(':accountId', accountId)
        },
        divisionsConfig: {
          path: '/tournaments/account/:accountId/create/divisions-configuration',
          title: 'Configure tournament divisions',
          calculatePath: accountId =>
            `${PAGES.tournaments.accountTournaments.add.divisionsConfig.path}`.replace(':accountId', accountId)
        },
        manageRounds: {
          path: '/tournaments/account/:accountId/create/manage-rounds',
          title: 'Manage division rounds',
          calculatePath: accountId =>
            `${PAGES.tournaments.accountTournaments.add.manageRounds.path}`.replace(':accountId', accountId)
        },
        additionalOptions: {
          path: '/tournaments/account/:accountId/create/additional-options',
          title: 'Additional options',
          calculatePath: accountId =>
            `${PAGES.tournaments.accountTournaments.add.additionalOptions.path}`.replace(':accountId', accountId)
        },
        tournamentReview: {
          path: '/tournaments/account/:accountId/create/review',
          title: 'Review tournament',
          calculatePath: accountId =>
            `${PAGES.tournaments.accountTournaments.add.tournamentReview.path}`.replace(':accountId', accountId)
        },
        complete: {
          path: '/tournaments/account/:accountId/create/complete',
          title: 'Tournament complete',
          calculatePath: accountId =>
            `${PAGES.tournaments.accountTournaments.add.complete.path}`.replace(':accountId', accountId)
        }
      },
      manage: {
        root: {
          path: '/tournaments/account/:accountId/:tournamentId/configuration',
          title: 'Edit tournament configuration',
          calculatePath: (accountId, tournamentId) =>
            `${PAGES.tournaments.accountTournaments.manage.root.path}`
              .replace(':accountId', accountId)
              .replace(':tournamentId', tournamentId)
        },
        manageRounds: {
          path: '/tournaments/account/:accountId/:tournamentId/divisions/:divisionId/manage-rounds',
          title: 'Manage division',
          calculatePath: (accountId, tournamentId, divisionId) =>
            `${PAGES.tournaments.accountTournaments.manage.manageRounds.path}`
              .replace(':accountId', accountId)
              .replace(':tournamentId', tournamentId)
              .replace(':divisionId', divisionId)
        },
        divisionsConfig: {
          path: '/tournaments/account/:accountId/:tournamentId/divisions-configuration',
          title: 'Configure tournament divisions',
          calculatePath: (accountId, tournamentId) =>
            `${PAGES.tournaments.accountTournaments.manage.divisionsConfig.path}`
              .replace(':accountId', accountId)
              .replace(':tournamentId', tournamentId)
        },
        additionalOptions: {
          path: '/tournaments/account/:accountId/:tournamentId/additional-options',
          title: 'Additional options',
          calculatePath: (accountId, tournamentId) =>
            `${PAGES.tournaments.accountTournaments.manage.additionalOptions.path}`
              .replace(':accountId', accountId)
              .replace(':tournamentId', tournamentId)
        },
        tournamentReview: {
          path: '/tournaments/account/:accountId/:tournamentId/review',
          title: 'Review tournament',
          calculatePath: (accountId, tournamentId) =>
            `${PAGES.tournaments.accountTournaments.manage.tournamentReview.path}`
              .replace(':accountId', accountId)
              .replace(':tournamentId', tournamentId)
        }
      }
    },
    account: {
      path: '/tournament-accounts',
      title: 'Accounts'
    }
  },
  // ========================================
  fans: {
    root: {
      path: '/attendees/:id',
      title: 'Manage attendees',
      menuTitle: 'Attendees',
      breadcrumbs: {
        account: [],
        accounts: breadcrumbs.account
      },
      calculatePath: (id, type, athleticAccountId) =>
        `${PAGES.fans.root.path}?type=${
          typeof type === 'undefined' || (typeof type === 'string' && type.toLowerCase() !== 'season')
            ? 'event'
            : 'season'
        }${athleticAccountId ? `&conferenceAccount=${athleticAccountId}` : ''}`.replace(':id', id)
    }
  },
  activities: {
    root: {
      path: '/activities',
      title: 'Activities',
      menuTitle: 'Activities'
    }
  },
  levels: {
    root: {
      path: '/levels',
      title: 'Levels',
      menuTitle: 'Levels'
    }
  },
  products: {
    root: {
      path: '/products',
      title: 'Product Types',
      menuTitle: 'Products'
    }
  },
  rates: {
    root: {
      path: '/rates',
      title: 'Rates',
      menuTitle: 'Rates'
    },
    add: {
      path: '/rates/new',
      title: 'Add Rate',
      breadcrumbs: [{ title: 'Rates' }]
    },
    edit: {
      path: '/rates/:id/edit',
      title: 'Edit Rate',
      breadcrumbs: [
        { title: 'Rates' },
        {
          title: '<span style="font-weight:bold;">${params.name}</span>'
        }
      ],
      calculatePath: rateId => PAGES.rates.edit.path.replace(':id', rateId)
    }
  },
  users: {
    root: {
      path: '/users',
      title: 'Users',
      menuTitle: 'Users'
    },
    add: {
      path: '/users/create',
      title: 'Add User',
      breadcrumbs: [{ title: 'Users' }]
    },
    edit: {
      path: '/users/:id/edit',
      title: 'Edit User',
      breadcrumbs: breadcrumbs.user,
      calculatedPath: email => PAGES.users.edit.path.replace(':id', encodeURIComponent(email))
    },
    view: {
      path: '/users/:id/details',
      title: 'View User',
      breadcrumbs: breadcrumbs.user,
      calculatedPath: email => PAGES.users.view.path.replace(':id', encodeURIComponent(email))
    }
  },
  eventIntegrations: {
    root: {
      path: '/event-integrations',
      title: 'Ready to Publish Events',
      menuTitle: 'Event Integrations',
      breadcrumbs: {
        account: [],
        accounts: breadcrumbs.account
      }
    },
    view: {
      path: '/event-integrations?accountId=:accountId&partnerName=:partnerName',
      calculatedPath: routeObject =>
        PAGES.eventIntegrations.view.path
          .replace(':accountId', routeObject?.accountId)
          .replace(':partnerName', routeObject?.partnerName)
    }
  },
  financial: {
    root: {
      path: '/financial',
      title: 'Financial Hub',
      menuTitle: 'Financial'
    },
    eventHistory: {
      path: '/financial/event-history',
      title: 'Event Payment History'
    },
    seasonHistory: {
      path: '/financial/season-history',
      title: 'Season Payment History'
    }
  },
  accountingHub: {
    root: {
      path: '/accounting-hub',
      title: 'Accounting Hub',
      menuTitle: 'Accounting',
      getBreadcrumbs: () => []
    },
    adjustment: {
      path: '/accounting-hub/adjustment',
      title: 'Adjustment',
      menuTitle: 'Adjustment',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.adjustment.path, name: PAGES.accountingHub.adjustment.title }
      ]
    },
    pendingApprovalNumber: {
      path: '/accounting-hub/adjustment/pending-approval-number',
      title: 'Pending Approval (#)',
      menuTitle: 'Pending Approval (#)',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.adjustment.path, name: PAGES.accountingHub.adjustment.title },
        { path: PAGES.accountingHub.pendingApprovalNumber.path, name: PAGES.accountingHub.pendingApprovalNumber.title }
      ]
    },
    pendingApprovalAmount: {
      path: '/accounting-hub/adjustment/pending-approval-amount',
      title: 'Pending Approval ($s)',
      menuTitle: 'Pending Approval ($s)',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.adjustment.path, name: PAGES.accountingHub.adjustment.title },
        { path: PAGES.accountingHub.pendingApprovalAmount.path, name: PAGES.accountingHub.pendingApprovalAmount.title }
      ]
    },
    adjustmentDetail: {
      path: '/accounting-hub/:id/adjustment',
      title: 'Adjustment Details',
      menuTitle: 'Adjustment Details',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.adjustment.path, name: PAGES.accountingHub.adjustment.title },
        { path: PAGES.accountingHub.adjustmentDetail.path, name: PAGES.accountingHub.adjustmentDetail.title }
      ]
    },
    cancelationReissuance: {
      path: '/accounting-hub/cancelation-reissuance',
      title: 'Cancelation/reissuance',
      menuTitle: 'Cancelation/reissuance',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.cancelationReissuance.path, name: PAGES.accountingHub.cancelationReissuance.title }
      ]
    },
    negativeCarryforwards: {
      path: '/accounting-hub/negative-carryforward',
      title: 'Negative Carryforwards',
      menuTitle: 'Negative Carryforwards',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.negativeCarryforwards.path, name: PAGES.accountingHub.negativeCarryforwards.title }
      ]
    },
    pendingPayouts: {
      path: '/accounting-hub/settlements-reissuances-summary',
      title: 'Settlements and reissuances - summary',
      menuTitle: 'Settlements and reissuances - summary',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.pendingPayouts.path, name: 'Settlements and reissuances summary' }
      ]
    },
    completedPayouts: {
      path: '/accounting-hub/settlements-reissuances-summary/completed',
      title: 'Completed Payouts',
      menuTitle: 'Completed Payouts',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.pendingPayouts.path, name: 'Settlements and reissuances summary' },
        { path: PAGES.accountingHub.completedPayouts.path, name: PAGES.accountingHub.completedPayouts.title }
      ]
    },
    accountingMonitor: {
      path: '/accounting-hub/monitor',
      title: 'Accounting Monitor',
      menuTitle: 'Accounting Monitor'
    },
    missingCharges: {
      path: '/accounting-hub/monitor/order-refund-audit/missing-charges',
      title: 'Missing charges',
      menuTitle: 'Order & Refund Audit - Missing charges',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: '', name: 'Order & refund audit', inactive: true },
        { path: PAGES.accountingHub.processRejections.path, name: PAGES.accountingHub.missingCharges.title }
      ]
    },
    missingRefunds: {
      path: '/accounting-hub/monitor/order-refund-audit/missing-refunds',
      title: 'Missing refunds',
      menuTitle: 'Order & Refund Audit - Missing refunds',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: '', name: 'Order & refund audit', inactive: true },
        { path: PAGES.accountingHub.processRejections.path, name: PAGES.accountingHub.missingRefunds.title }
      ]
    },
    mismatchedCharges: {
      path: '/accounting-hub/monitor/order-refund-audit/mismatched-charges',
      title: 'Mismatched charges',
      menuTitle: 'Order & Refund Audit - Mismatched charges',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: '', name: 'Order & refund audit', inactive: true },
        { path: PAGES.accountingHub.processRejections.path, name: PAGES.accountingHub.mismatchedCharges.title }
      ]
    },
    mismatchedRefunds: {
      path: '/accounting-hub/monitor/order-refund-audit/mismatched-refunds',
      title: 'Mismatched refunds',
      menuTitle: 'Order & Refund Audit - Mismatched refunds',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: '', name: 'Order & refund audit', inactive: true },
        { path: PAGES.accountingHub.processRejections.path, name: PAGES.accountingHub.mismatchedRefunds.title }
      ]
    },
    achRequests: {
      path: '/accounting-hub/monitor/ach-requests',
      title: 'Process ACH Requests',
      menuTitle: 'Process ACH Requests',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: '', name: 'Process ACH requests', inactive: true }
      ]
    },
    processRejections: {
      path: '/accounting-hub/process-rejections',
      title: 'Process Rejections',
      menuTitle: 'Process Rejections',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.processRejections.path, name: PAGES.accountingHub.processRejections.title }
      ]
    },
    pendingPayoutDetail: {
      path: '/accounting-hub/settlements-details/:batchId',
      title: 'Settlements - details',
      menuTitle: 'Settlements - details',
      calculatePath: batchId => PAGES.accountingHub.pendingPayoutDetail.path.replace(':batchId', batchId),
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.pendingPayouts.path, name: 'Settlements and reissuances summary' },
        { path: PAGES.accountingHub.pendingPayoutDetail.path, name: PAGES.accountingHub.pendingPayoutDetail.title }
      ]
    },
    pendingReissuanceDetail: {
      path: '/accounting-hub/pending-reissuance-detail/:batchId',
      title: 'Pending reissuance details',
      menuTitle: 'Pending reissuance details',
      calculatePath: batchId => PAGES.accountingHub.pendingReissuanceDetail.path.replace(':batchId', batchId),
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.pendingPayouts.path, name: 'Settlements and reissuances summary' },
        {
          path: PAGES.accountingHub.pendingReissuanceDetail.path,
          name: PAGES.accountingHub.pendingReissuanceDetail.title
        }
      ]
    },
    processCancelations: {
      path: '/accounting-hub/process-cancelations',
      title: 'Process Cancelations',
      menuTitle: 'Process Cancelations',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.processCancelations.path, name: PAGES.accountingHub.processCancelations.title }
      ]
    },
    disputes: {
      path: '/accounting-hub/disputes',
      title: 'Disputes',
      menuTitle: 'Disputes',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.processCancelations.path, name: PAGES.accountingHub.disputes.title }
      ]
    },
    disputesOpen: {
      path: '/accounting-hub/disputes/open',
      title: 'Disputes open',
      menuTitle: 'Disputes open',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.processCancelations.path, name: PAGES.accountingHub.disputes.title }
      ]
    },
    disputesReview: {
      path: '/accounting-hub/disputes/review',
      title: 'Disputes review',
      menuTitle: 'Disputes review',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.processCancelations.path, name: PAGES.accountingHub.disputes.title }
      ]
    },
    disputesWon: {
      path: '/accounting-hub/disputes/won',
      title: 'Disputes won',
      menuTitle: 'Disputes won',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.processCancelations.path, name: PAGES.accountingHub.disputes.title }
      ]
    },
    disputesLost: {
      path: '/accounting-hub/disputes/lost',
      title: 'Disputes lost',
      menuTitle: 'Disputes lost',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.processCancelations.path, name: PAGES.accountingHub.disputes.title }
      ]
    },
    disputeInsights: {
      path: '/accounting-hub/monitor/dispute-insights',
      title: 'Dispute insights',
      menuTitle: 'Dispute insights',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.disputeInsights.path, name: PAGES.accountingHub.disputeInsights.title }
      ]
    },
    eventSeasonConfig: {
      path: '/accounting-hub/event-season-config',
      title: 'Configure events and seasons',
      menuTitle: 'Configure events and seasons',
      getBreadcrumbs: () => [
        { path: PAGES.accountingHub.root.path, name: PAGES.accountingHub.root.title },
        { path: PAGES.accountingHub.eventSeasonConfig.path, name: PAGES.accountingHub.eventSeasonConfig.title }
      ]
    }
  },
  seasonRenewal: {
    root: {
      path: '/season-renewal/:seasonId',
      title: 'Season Renewal',
      calculatePath: seasonId => `${PAGES.seasonRenewal.root.path}`.replace(':seasonId', seasonId)
    },
    createSeasonRenewalCampaign: {
      path: '/season-renewal/:seasonId/campaign/create',
      title: 'Create Season Renewal Campaign',
      calculatePath: seasonId =>
        `${PAGES.seasonRenewal.createSeasonRenewalCampaign.path}`.replace(':seasonId', seasonId)
    },
    lastSeasonAttendees: {
      path: '/season-renewal/:seasonId/last-season-attendees/:lastSeasonId',
      title: 'Last Season Attendees',
      calculatePath: (seasonId, lastSeasonId) =>
        `${PAGES.seasonRenewal.lastSeasonAttendees.path}`
          .replace(':seasonId', seasonId)
          .replace(':lastSeasonId', lastSeasonId)
    }
  },
  balances: {
    root: {
      path: '/finperf',
      title: 'Explore financial performance',
      menuTitle: 'Explore financial performance'
    }
  },
  create: {
    root: {
      path: '/create/:accountId',
      title: 'Create',
      calculatePath: accountId => `${PAGES.create.root.path}`.replace(':accountId', accountId)
    }
  },
  connect: {
    root: {
      path: '/connect'
    }
  }
};

export default { PAGES };
