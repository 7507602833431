// @flow
import { isEmpty } from 'lodash';

class FieldsRequest {
  constructor({ fields, embeddedFields }) {
    this.raw = {
      fields,
      embeddedFields
    };
  }

  get fields() {
    return isEmpty(this.raw.fields) ? [] : this.raw.fields;
  }

  get embeddedFields() {
    if (isEmpty(this.raw.embeddedFields)) return [];

    const {
      venue,
      eventType,
      account,
      hostAccount,
      opponentAccount,
      levels,
      eventSalesInfo
    } = this.raw.embeddedFields;

    const prefix = '_embedded';
    const embeddedFields = [
      ...(isEmpty(venue) ? [] : venue.map(field => `${prefix}.venue.${field}`)),
      ...(isEmpty(eventType)
        ? []
        : eventType.map(field => `${prefix}.event-type.${field}`)),
      ...(isEmpty(account)
        ? []
        : account.map(field => `${prefix}.account.${field}`)),
      ...(isEmpty(hostAccount)
        ? []
        : hostAccount.map(field => `${prefix}.account-host.${field}`)),
      ...(isEmpty(opponentAccount)
        ? []
        : opponentAccount.map(field => `${prefix}.account-opponent.${field}`)),
      ...(isEmpty(levels)
        ? []
        : levels.map(field => `${prefix}.levels.${field}`)),
      ...(isEmpty(eventSalesInfo)
        ? []
        : eventSalesInfo.map(field => `${prefix}.event-sales-info.${field}`))
    ];

    return embeddedFields;
  }

  toQueryString() {
    if (isEmpty(this.fields) && isEmpty(this.embeddedFields)) return '';
    return `&fields=${[...this.fields, ...this.embeddedFields].join(',')}`;
  }
}

export default FieldsRequest;
