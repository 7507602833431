import { ON_RESET_BULK_EDIT_EVENTS_STATE, ON_SET_SELECTED_EVENTS } from '@bulk-edit-events/middleware/actionTypes';

import type { BulkEditEventsState } from '@bulk-edit-events/middleware/types';

export const initialState: BulkEditEventsState = {
  selectedEvents: []
};

// eslint-disable-next-line default-param-last
const bulkEditEvents = (state = initialState, action: any) => {
  switch (action.type) {
    case ON_RESET_BULK_EDIT_EVENTS_STATE: {
      return { ...state, ...initialState };
    }

    case ON_SET_SELECTED_EVENTS: {
      const selectedEvents = action.payload;

      return {
        ...state,
        selectedEvents
      };
    }

    default:
      return { ...state };
  }
};

export default bulkEditEvents;
