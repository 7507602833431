import axios from '../api/axios';
import { HEALTH_CHECK } from '../api/api';
import ErrorDTO from '../dto/ErrorDTO';
import HealthCheckDTO from '../dto/HealthCheckDTO';

const checkHealth = manualErrorHandling =>
  axios.huddleAxiosInstanceProxy
    .get(HEALTH_CHECK, null, manualErrorHandling)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new HealthCheckDTO(response);
    })
    .catch(e => new ErrorDTO(e));

export default { checkHealth };
