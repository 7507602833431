// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import React from 'react';
import { useState, useEffect, useLayoutEffect } from 'react';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';

import { usePortal } from '@flatfile/react';
import type { FlatfileEvent } from '@flatfile/listener';
import type { IUserInfo, SimpleOnboarding } from '@flatfile/embedded-utils';
import type { UserDTO } from '@gofan/api/users';
import type { SpaceConfig } from '@gofan/api/flatfile';

import { config as gofanConfig } from '@gofan/constants/config';
import { getRefreshToken, getToken } from '@gofan/api/auth';
import { WorkbookType } from '@gofan/constants/flatfile';
import { EventService } from '@gofan/api/events';
import { FlatfileService } from '@gofan/api/flatfile';

export type ISubmitPortal = {
  operation: string;
  onSubmit?: (data: any) => void;
};

export type ICloseSpace = {
  operation: string;
  onClose: (data: any) => void;
};

export type IReactSimpleOnboarding = SimpleOnboarding & {
  submitPortal?: ISubmitPortal;
  error?: (error: Error | string) => React.ReactElement;
  loading?: React.ReactElement;
};

export const useFlatFilePlatform = ({
  currentUser,
  portalProps
}: {
  currentUser: UserDTO;
  portalProps?: IReactSimpleOnboarding;
}) => {
  const userInfo: IUserInfo = {
    userId: currentUser?.email,
    name: `${currentUser?.firstName} ${currentUser?.lastName}`
  };

  const [showSpace, setShowSpace] = useState(false);
  const [portalConfig, setPortalConfig] = useState<IReactSimpleOnboarding>({
    ...portalProps,
    userInfo: portalProps?.userInfo ?? userInfo,
    name: portalProps?.name ?? 'HQ Space',
    publishableKey: gofanConfig.apiFlatFile.FLATFILE_API_KEY,
    environmentId: gofanConfig.apiFlatFile.FLATFILE_ENVIRONMENT_ID,
    sidebarConfig: {
      showSidebar: false,
      ...portalProps?.sidebarConfig
    },
    closeSpace: {
      operation: 'submitActionFg',
      onClose: _onClosePortal
    }
  });

  useEffect(() => {
    window.addEventListener('message', _handlePostMessage, false);
    return () => {
      window.removeEventListener('message', _handlePostMessage);
    };
  }, [showSpace, portalConfig]);

  return {
    showSpace,
    onOpenSpace: _onOpenSpace,
    renderFlatfilePortal: _renderFlatfilePortal
  };

  function _renderFlatfilePortal() {
    if (!showSpace) return null;

    return <FlatFilePortal portalConfig={portalConfig as IReactSimpleOnboarding} />;
  }

  function _handlePostMessage(event: { data: { flatfileEvent: any } }) {
    if (
      !showSpace ||
      isEmpty(event?.data?.flatfileEvent) ||
      isEmpty(portalConfig?.submitPortal) ||
      typeof portalConfig?.submitPortal?.onSubmit !== 'function'
    ) {
      return;
    }

    const { flatfileEvent } = event.data ?? {};
    if (
      flatfileEvent?.topic === 'job:outcome-acknowledged' &&
      flatfileEvent?.payload?.status === 'complete' &&
      flatfileEvent?.payload?.operation === portalConfig?.submitPortal?.operation
    ) {
      portalConfig?.submitPortal?.onSubmit({ flatfileEvent, config: portalConfig });
    }
  }

  function _getSpaceConfig(
    spaceConfig: SpaceConfig,
    config?: any
  ): {
    metadata?: any;
    namespace?: string;
    closeSpace?: ICloseSpace;
    submitPortal?: ISubmitPortal;
  } {
    switch (spaceConfig?.workbookType) {
      case WorkbookType.EVENTS_CREATION:
        return {
          namespace: 'events-creation',
          metadata: { bulkAction: 'create' },
          submitPortal: {
            operation: 'submitActionFg',
            onSubmit: _onSubmitEventPortal(config?.onCompleted)
          }
        };

      case WorkbookType.EVENTS_EDITING:
        return {
          namespace: 'events-editing',
          metadata: { bulkAction: 'update' },
          submitPortal: {
            operation: 'submitActionFg',
            onSubmit: _onSubmitEventPortal(config?.onCompleted)
          }
        };

      case WorkbookType.TOURNAMENTS:
        return {
          namespace: 'tournaments',
          submitPortal: {
            operation: 'submitActionFg',
            onSubmit: _onSimpleSubmitPortal(config?.onCompleted)
          }
        };

      case WorkbookType.SEASON_RENEWAL:
        return {
          namespace: 'season-renewal',
          submitPortal: {
            operation: 'submitActionFg',
            onSubmit: _onSimpleSubmitPortal(config?.onCompleted)
          }
        };

      case WorkbookType.SEASON_MULTIPLE_SPORTS:
      case WorkbookType.SEASON_ONE_PER_SPORT:
      case WorkbookType.SEASON_SEPARATE_EACH_TEAM: {
        const seasonEnvConfig = FlatfileService.getSeasonEnvConfig(spaceConfig);
        return {
          ...seasonEnvConfig,
          namespace: 'season-events-creation',
          submitPortal: {
            operation: 'submitActionFg',
            onSubmit: _onSubmitPortal(config?.onCompleted)
          }
        };
      }
      default:
        return {};
    }
  }

  async function _onOpenSpace(config: SpaceConfig, onCompleted?: (data: any) => void, onError?: (data: any) => void) {
    const authToken = await getToken();
    setPortalConfig(prevState => {
      const spaceConfig = _getSpaceConfig(config, {
        onCompleted,
        onError
      });
      const newConfigs = {
        ...prevState,
        ...spaceConfig,
        metadata: {
          ...prevState?.metadata,
          workbookType: config?.workbookType,
          baseURL: gofanConfig.apiGateway.URL,
          timeZone: moment.tz.guess(),
          token: authToken,
          ...config?.metadata,
          ...spaceConfig?.metadata
        }
      };
      return newConfigs;
    });
    setShowSpace(true);
  }

  async function _onClosePortal() {
    setShowSpace(false);
  }

  async function _getRecordsBySheetId(sheetId: string) {
    if (!sheetId) return {};

    const response: any = await FlatfileService.getFlatfileRecords(sheetId).catch(() => ({}));

    const records = (response?.data?.records ?? []).map(record => {
      const recordValues = Object.keys(record?.values ?? {})?.reduce(
        (res, recordValueKey) => ({ ...res, [recordValueKey]: record?.values?.[recordValueKey]?.value }),
        {}
      );
      return { id: record?.id, values: recordValues };
    });

    return { sheetId, records, values: (records ?? [])?.map(record => record?.values) };
  }

  function _onSimpleSubmitPortal(onCompleted?: (data: any) => void) {
    return async ({ flatfileEvent }: { flatfileEvent?: FlatfileEvent; config?: IReactSimpleOnboarding }) => {
      const { payload } = flatfileEvent ?? {};
      const data = JSON.parse(payload?.info ?? '{}');
      const batchId = data?.sheets?.[0]?.id ?? '';

      if (typeof onCompleted === 'function') {
        const { values } = await _getRecordsBySheetId(batchId);
        onCompleted(values);
      }
    };
  }

  function _onSubmitPortal(onCompleted?: (data: any) => void) {
    return async ({ flatfileEvent, config }: { flatfileEvent?: FlatfileEvent; config?: IReactSimpleOnboarding }) => {
      const { payload } = flatfileEvent ?? {};
      const data = JSON.parse(payload?.info ?? '{}');
      const batchId = data?.sheets?.[0]?.id ?? '';

      if (typeof onCompleted === 'function') {
        const { values } = await _getRecordsBySheetId(batchId);
        onCompleted({
          flatfileEvent,
          config,
          batchId,
          records: values ?? []
        });
      }
    };
  }

  function _onSubmitEventPortal(onCompleted?: (data: any) => void) {
    return async ({ flatfileEvent, config }: { flatfileEvent?: FlatfileEvent; config?: IReactSimpleOnboarding }) => {
      const { payload, context } = flatfileEvent ?? {};
      const { spaceId } = context ?? {};

      const data = JSON.parse(payload?.info ?? '{}');
      const batchId = data?.sheets?.[0]?.id ?? '';

      const refreshToken = await getRefreshToken();

      await EventService.bulkUploadEvents({
        useFlatFileV2: true,
        batchId,
        spaceId,
        email: currentUser?.email,
        uploadedAt: new Date().toISOString(),
        refreshToken: refreshToken ?? '',
        action: config?.metadata?.bulkAction,
        authToken: config?.metadata?.token ?? ''
      });

      if (typeof onCompleted === 'function') {
        onCompleted({
          loading: false,
          batchId,
          action: config?.metadata?.bulkAction
        });
      }
    };
  }
};

export const FlatFilePortal = (props: { portalConfig: IReactSimpleOnboarding }) => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document?.body).overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  const portal = usePortal({
    ...props?.portalConfig
  });
  return portal;
};
