import isEmpty from 'lodash/isEmpty';
import { UPLOAD_EVENT, CREATE_SEASON, GET_SEASON_BY_ID } from '../api/api';
import axios from '../api/axios';
import ErrorDTO from '../dto/ErrorDTO';
import UploadedEventDTO from '../dto/UploadedEventDTO';
import SeasonDTO from '../dto/SeasonDTO';
import { config } from '@gofan/constants';

const uploadSeasonTemplate = (formData, timeZone) =>
  axios.huddleAxiosInstanceProxy
    .post(`${UPLOAD_EVENT}?timeZone=${timeZone}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout: config.TIMEOUT_UPLOAD_FILE
    })
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response.map(event => new UploadedEventDTO(event));
    })
    .catch(e => new ErrorDTO(e));

const uploadNewSeasonTemplate = (formData, timeZone, uploadFlow, teamOptions) =>
  axios.huddleAxiosInstanceProxy
    .post(
      `${UPLOAD_EVENT}?flow=${uploadFlow}&teamOptions=${encodeURIComponent(
        JSON.stringify(teamOptions)
      )}&timeZone=${timeZone}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: config.TIMEOUT_UPLOAD_FILE
      }
    )
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response.map(event => new UploadedEventDTO(event));
    })
    .catch(e => new ErrorDTO(e));

const createSeason = request =>
  axios.huddleAxiosInstanceProxy
    .post(CREATE_SEASON, request.toJSON(), {
      timeout: config.TIMEOUT_UPLOAD_FILE
    })
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new SeasonDTO(response);
    })
    .catch(e => new ErrorDTO(e));

const getSeasonById = (seasonId, expand = '') => {
  let uri = GET_SEASON_BY_ID.replace('{id}', seasonId);
  if (!isEmpty(expand)) {
    uri = `${uri}?ts=${new Date().getTime()}${expand.toQueryString()}`;
  }

  return axios.huddleAxiosInstanceProxy
    .get(uri)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new SeasonDTO(response);
    })
    .catch(e => new ErrorDTO(e));
};

export default {
  uploadSeasonTemplate,
  uploadNewSeasonTemplate,
  createSeason,
  getSeasonById
};
