import { huddleAxiosInstanceProxy } from '../shared/axios';

import { GET_PRODUCT_TYPES } from './product-type.endpoints';

import type { ProductTypeDTO } from './product-type.model';

class ProductTypeRepository {
  static getProductTypes = () => huddleAxiosInstanceProxy.get<ProductTypeDTO[]>(GET_PRODUCT_TYPES);
}

export { ProductTypeRepository };
