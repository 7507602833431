import * as React from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';

import ProfileHeader from './components/ProfileHeader';
import Content from './components/Content';
import { parseUrl } from '../../commons/utils/parseQueryString';
import { RootContext } from '../../RootContext';
import { isEmpty } from '../../utils/objectUtils';

const Wrapper = styled.div`
  margin-top: 34px;
  height: auto;
  margin: 16px;
`;

const Profile: React.SFC<RouteComponentProps> = ({
  location: { search },
  history
}) => {
  const { authBody } = React.useContext(RootContext);
  const [editing, setEditing] = React.useState<boolean>(false);
  const {
    query: { changePassword }
  } = parseUrl(search);

  const email =
    !isEmpty(authBody) && !isEmpty(authBody.user) ? authBody.user.email : '';

  return (
    <Wrapper>
      <Grid>
        <Grid.Row>
          <ProfileHeader editing={editing} setEditing={setEditing} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Content
              editing={editing}
              setEditing={setEditing}
              changePassword={changePassword}
              history={history}
              whoami={email}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Wrapper>
  );
};

export default Profile;
