import { SearchRatesParams, SearchRatesResult } from './types';
import Rate from '../../api/model/Rate';
export const RESET_STATE = 'rate/RESET_STATE';
export const SEARCH_RATES = 'rate/SEARCH_RATES';
export const SEARCH_RATES_SUCCESS = 'rate/SEARCH_RATES_SUCCESS';
export const INSERT_RATE_RECENTLY = 'rate/INSERT_RATE_RECENTLY';

interface ResetStateAction {
  type: typeof RESET_STATE
}

interface SearchRatesAction {
  type: typeof SEARCH_RATES;
  payload: {
    searchParams: SearchRatesParams
  }
}

interface SearchRatesSuccessAction {
  type: typeof SEARCH_RATES_SUCCESS;
  payload: {
    searchResult: SearchRatesResult
  }
}

interface InsertRateRecentlyAction {
  type: typeof INSERT_RATE_RECENTLY;
  payload: {
    rateRecently: Rate
  }
}

export type RateListActionTypes =
  ResetStateAction |
  SearchRatesAction |
  SearchRatesSuccessAction|
  InsertRateRecentlyAction
