import axios from 'axios';
import { huddleAxiosInstanceProxy } from '../shared/axios';

import {
  GET_EVENT,
  UPDATE_EVENT,
  GET_EVENT_BY_PARAMS,
  GET_EVENT_COUNT_BY_TYPE,
  EVENT_MONITOR_INDICATOR,
  GET_TICKET_SALES_INSIGHT_DATA,
  GET_EVENT_SALES_INFO,
  CANCEL_EVENT,
  BULK_UPLOAD_EVENTS,
  STATUS_BULK_UPLOAD_EVENTS,
  TEST_CAMERA,
  RESCHEDULE_EVENT,
  DELETE_EVENT,
  PATCH_GLOBAL_EVENT,
  CREATE_EVENT,
  PUBLISH_AWAY_EVENT,
  IGNORE_AWAY_EVENT,
  DECLINE_AWAY_EVENT
} from '@gofan/constants/endpoints';
import { GET_EVENT_HISTORY } from './event.endpoints';
import { config } from '@gofan/constants';
import { mockFundraiserInfo, mockConcessionInfo } from '@gofan/utils/testing/accounts/mockTeamSaleInfo';

import type {
  EventDTO,
  EventSearchDTO,
  EventCountByType,
  EventTicketSale,
  EventSalesInfoDTO,
  EventPageResponseDTO,
  EventScheduleSearchDTO,
  EventCancelDTO,
  EventBulkUpload,
  EventBulkUploadStatus,
  TeamEventTicketSale,
  EventRescheduleDTO,
  GlobalEventStatus,
  GlobalEventDTO
} from './event.model';

export class EventRepository {
  static cancelCameraSource = huddleAxiosInstanceProxy.CancelToken.source();

  static getEventById = (eventId: string | number, queryStr?: string): Promise<EventDTO> =>
    huddleAxiosInstanceProxy.get<EventDTO>(
      `${GET_EVENT.replace('{id}', `${eventId}`)}${queryStr ?? ''}`,
      undefined,
      true
    );

  static getEventCountByType = (body: EventSearchDTO, queryStr?: string): Promise<EventCountByType> =>
    huddleAxiosInstanceProxy.post<EventCountByType>(
      `${GET_EVENT_COUNT_BY_TYPE}${queryStr ?? ''}`,
      body as EventCountByType,
      undefined,
      true
    );

  static searchEventByParams = (body: EventSearchDTO, queryStr?: string): Promise<EventPageResponseDTO> =>
    huddleAxiosInstanceProxy.post<EventPageResponseDTO>(
      `${GET_EVENT_BY_PARAMS}${queryStr ?? ''}`,
      body as EventPageResponseDTO,
      undefined,
      true
    );

  static partialUpdateEvent = (event: EventDTO, options?: { checkDupes?: boolean }): Promise<EventDTO> =>
    huddleAxiosInstanceProxy.patch<EventDTO>(
      UPDATE_EVENT.replace('{id}', `${event?.id}${options?.checkDupes ? '?checkDupes=true' : ''}`),
      event,
      undefined,
      true
    );

  static getEventMonitorIndicator = (
    eventScheduleSearch: EventScheduleSearchDTO,
    manualErrorHandling?: boolean
  ): Promise<boolean> =>
    huddleAxiosInstanceProxy.post<boolean, Partial<EventScheduleSearchDTO>>(
      EVENT_MONITOR_INDICATOR,
      eventScheduleSearch,
      huddleAxiosInstanceProxy.partnerConfig,
      manualErrorHandling
    );

  static getTicketSaleInsightData = (eventId: string | number, queryStr = ''): Promise<EventTicketSale> =>
    axios
      .get(`${GET_TICKET_SALES_INSIGHT_DATA.replace('{eventId}', `${eventId}`)}${queryStr}`)
      .then(response => response.data);

  static getTeamTicketSaleInsightData = (eventType: string): Promise<TeamEventTicketSale> =>
    new Promise((resolve, reject) => {
      const mockDataList: TeamEventTicketSale[] = [mockFundraiserInfo, mockConcessionInfo];

      const foundData = mockDataList.find(data => data.id === eventType);
      if (foundData) {
        resolve(foundData);
      } else {
        reject(new Error(`No data found for event type: ${eventType}`));
      }
    });

  static getEventSalesInfo = (eventId: number): Promise<EventSalesInfoDTO> =>
    huddleAxiosInstanceProxy.get<EventSalesInfoDTO>(
      `${GET_EVENT_SALES_INFO.replace('{id}', `${eventId}`)}`,
      undefined,
      true
    );

  static getEventHistory = (id: string, dateRange?: 'lastWeek' | 'lastMonth' | 'all') =>
    huddleAxiosInstanceProxy.get(GET_EVENT_HISTORY.replace('{id}', id), {
      params: { dateRange }
    });

  static createEvent = (event: EventDTO) =>
    huddleAxiosInstanceProxy.post<EventDTO>(CREATE_EVENT, event, undefined, true);

  static updateEvent = (event: EventDTO): Promise<EventDTO> =>
    huddleAxiosInstanceProxy.put<EventDTO>(UPDATE_EVENT.replace('{id}', `${event?.id}`), event, undefined, true);

  static cancelEvent = (body: EventCancelDTO) =>
    huddleAxiosInstanceProxy.post<EventDTO, EventCancelDTO>(CANCEL_EVENT, body, undefined, true);

  static bulkUploadEvents = (payload: EventBulkUpload): Promise<any> =>
    axios
      .put(BULK_UPLOAD_EVENTS.replace('{bucket}', config.s3.BUCKET_BULK_UPLOAD_EVENT), payload)
      .then(response => response.data);

  static getStatusBulkUploadEvents = (batchId: string): Promise<EventBulkUploadStatus> =>
    axios.get(STATUS_BULK_UPLOAD_EVENTS.replace('{batchId}', batchId)).then(response => response.data);

  static testCamera = (body: any): Promise<any> =>
    huddleAxiosInstanceProxy.post(TEST_CAMERA, body, {
      cancelToken: this.cancelCameraSource.token
    });

  static cancelTestCamera = () => {
    this.cancelCameraSource.cancel('Test camera canceled');
    this.cancelCameraSource = huddleAxiosInstanceProxy.CancelToken.source();
  };

  static rescheduleEvent = (payload: EventRescheduleDTO): Promise<EventDTO> =>
    huddleAxiosInstanceProxy.post(RESCHEDULE_EVENT, payload, undefined, true);

  static deleteEventById = (eventId: string): Promise<any> =>
    huddleAxiosInstanceProxy.delete<any>(DELETE_EVENT.replace('{id}', `${eventId}`), undefined, true);

  static updateGlobalEvent = (id: string, payload: Partial<GlobalEventStatus>) =>
    huddleAxiosInstanceProxy.patch<GlobalEventDTO, Partial<GlobalEventStatus>>(
      PATCH_GLOBAL_EVENT.replace('{id}', id),
      payload,
      huddleAxiosInstanceProxy.partnerConfig
    );

  static publishAwayEvent = (eventId: string | number): Promise<any> =>
    huddleAxiosInstanceProxy.post(PUBLISH_AWAY_EVENT.replace('{id}', `${eventId}`), undefined, undefined, true);

  static ignoreAwayEvent = (eventId: string | number): Promise<any> =>
    huddleAxiosInstanceProxy.post(IGNORE_AWAY_EVENT.replace('{id}', `${eventId}`), undefined, undefined, true);

  static declineAwayEvent = (eventId: string | number): Promise<any> =>
    huddleAxiosInstanceProxy.post(DECLINE_AWAY_EVENT.replace('{id}', `${eventId}`), undefined, undefined, true);
}
