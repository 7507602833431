// @flow
import BaseModel from './BaseModel';

class Level extends BaseModel {
  constructor({ id, name, disabled }) {
    super({
      id,
      name,
      disabled
    });
  }

  get id() {
    return this.raw.id;
  }

  get name() {
    return this.raw.name;
  }

  get disabled() {
    return this.raw.disabled;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      disabled: this.disabled
    };
  }
}

export default Level;
