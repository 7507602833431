import { isEmpty } from '../../utils/objectUtils';

class EventLevel {
  constructor({ genders, levelId, levelName }) {
    this.raw = {
      genders,
      levelId,
      levelName
    };
  }

  get genders() {
    return isEmpty(this.raw.genders) ? [] : this.raw.genders;
  }

  get levelId() {
    return this.raw.levelId;
  }

  get levelName() {
    return this.raw.levelName;
  }

  toJSON() {
    return {
      genders: this.genders,
      levelId: this.levelId,
      levelName: this.levelName
    };
  }
}

export default EventLevel;
