import React, { Fragment } from 'react';
import styled from 'styled-components';
import FastPermissions from '../../../FastPermissions';
import { PageFrame } from '../../..';
import styles from './styles';

const account = {
  id: 1,
  balance: 12345,
  open: true
};

const account2 = {
  id: 2,
  balance: 12345
};

const Content = styled.div`
  ${styles.content}
`;

const MarginBottom = styled.div`
  ${styles.marginBottom}
`;

const FontWeight = styled.p`
  ${styles.fontWeight}
`;

export default ({ match }) => (
  <>
    <PageFrame match={match} />
    <Content>
      <p>
        <b>Welcome,</b>
      </p>
      <p>
        {'Now you can download the '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://apps.apple.com/us/app/gofan-box-office-for-schools/id1472963502'
        >
          {'GoFan Box Office '}
        </a>
        mobile app and start accepting all major forms of payment for your high school events.
      </p>
      <MarginBottom>
        <FontWeight>Got questions or need help? </FontWeight>
        Browse the
        <a rel='noopener noreferrer' target='_blank' href='https://www.huddletickets.com/gofan-digitalplaybook/'>
          {' Digital Playbook '}
        </a>
        for additional resources, tools and insights. If you need further assistance contact GoFan Support at
        <a href='tel:678-679-7677'> 678-679-7677 </a>
        or
        <a href='mailto:support@gofan.co' rel='noopener noreferrer' target='_blank'>
          {' support@gofan.co.'}
        </a>
      </MarginBottom>
      {import.meta.env.MODE === 'development' && (
        <>
          <FastPermissions
            ifAllowedTo='change_contact_permissions_to_finance.account'
            on={account}
            otherwiseShow={() => <p>Not Allowed to view account name</p>}
          >
            <p>can view account name</p>
          </FastPermissions>
          <FastPermissions
            ifAllowedTo='view.account.balance'
            on={account}
            otherwiseShow={() => <p>Not Allowed to view account 1 balance</p>}
          >
            <p>can view account 1 balance</p>
          </FastPermissions>
          <FastPermissions
            ifAllowedTo='view.account.balance'
            on={account2}
            otherwiseShow={() => <p>Not Allowed to view account 2 balance</p>}
          >
            <p>can view account 2 balance</p>
          </FastPermissions>
          <FastPermissions
            ifAllowedTo='create.account'
            on={account}
            otherwiseShow={() => <p>Not Allowed to create account</p>}
          >
            <p>can create account</p>
          </FastPermissions>
          <FastPermissions ifAllowedTo='view.user' otherwiseShow={() => <p>Not Allowed to view user</p>}>
            <p>can view user</p>
          </FastPermissions>

          <FastPermissions
            ifAllowedTo='edit.account.contact.activities'
            otherwiseShow={() => <p>Not Allowed to edit account contact activities</p>}
          >
            <p>can edit account contact activities</p>
          </FastPermissions>
        </>
      )}
    </Content>
  </>
);
