// @flow
class ReservedSeatingDTO {
  constructor({ eventId, seasonId, id, reservationState, seatsIoLabel, ticketId }) {
    this.raw = {
      eventId,
      seasonId,
      id,
      reservationState,
      seatsIoLabel,
      ticketId,
    };
  }

  get eventId() {
    return this.raw.eventId;
  }

  get seasonId() {
    return this.raw.seasonId;
  }

  get id() {
    return this.raw.id;
  }
  
  get reservationState() {
    return this.raw.reservationState;
  }
  
  get seatsIoLabel() {
    return this.raw.seatsIoLabel;
  }
  
  get ticketId() {
    return this.raw.ticketId;
  }

  toJSON() {
    return {
      eventId: this.eventId,
      seasonId: this.seasonId,
      id: this.id,
      reservationState: this.reservationState,
      seatsIoLabel: this.seatsIoLabel,
      ticketId: this.ticketId,
    };
  }
}

export default ReservedSeatingDTO;