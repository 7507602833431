import {
  FormLabel,
  RadioButtonGroup,
  RadioButton,
  RadioButtonGroupProps,
  RadioButtonProps
} from 'carbon-components-react';
import { isEmpty, uniqueId } from 'lodash';
// eslint-disable-next-line import/extensions
import { EVENT_REPORT } from '@app/modules/accounts/views/account-landing/account-contact-details/account-contact-details.config';

export type BasicRadioButtonConfig<ItemType = { label: string; value: any }> = RadioButtonProps &
  RadioButtonGroupProps & {
    items: ItemType[];
    label: string;
    fieldValue: string;
  };

export type BasicRadioButtonProps<ItemType = string> = BasicRadioButtonConfig & {
  name: string;
  control: any;
  initialValue: ItemType;
  fieldValue: ItemType;
  fieldError?: string;
  getFieldRef?: () => void;
};

const transformBooleanValue = (v: boolean) => (v ? EVENT_REPORT.YES : EVENT_REPORT.NO);

const BasicRadioButton = ({
  name,
  className,
  label = '',
  items = [],
  initialValue,
  fieldValue,
  getFieldRef,
  fieldError,
  control,
  ...other
}: BasicRadioButtonProps) => (
  <div className='basic-radio-button' ref={getFieldRef}>
    <FormLabel>{label}</FormLabel>
    <RadioButtonGroup
      {...other}
      {...control}
      id={`field-${name}`}
      className={className}
      valueSelected={typeof fieldValue === 'boolean' ? transformBooleanValue(fieldValue) : fieldValue}
      name={`field-${name}`}
    >
      {!isEmpty(items) &&
        items.map((item, key) => (
          <RadioButton
            disabled={other.disabled}
            labelText={item.label}
            value={item.value}
            id={`radio-${name}-${key}`}
            key={`radio-${name}-${uniqueId()}`}
          />
        ))}
    </RadioButtonGroup>
    {!isEmpty(fieldError) && <div className='gs--caption-01 gs--text-error gs--padding-top__sp3'>{fieldError}</div>}
  </div>
);

export default BasicRadioButton;
