import type { DataTableHeader, DataTableCell } from '@carbon/react';
import type { DataTableSortState } from '@carbon/react/lib/components/DataTable/state/sortStates';
import {
  DataTable as CarbonDataTable,
  Table as CarbonTable,
  TableHead as CarbonTableHead,
  TableRow as CarbonTableRow,
  TableHeader as CarbonTableHeader,
  TableBody as CarbonTableBody,
  TableCell as CarbonTableCell,
  TableToolbarContent as CarbonTableToolbarContent,
  TableToolbarSearch as CarbonTableToolbarSearch,
  TableContainer as CarbonTableContainer,
  TableExpandHeader as CarbonTableExpandHeader,
  TableExpandRow as CarbonTableExpandRow,
  TableExpandedRow as CarbonTableExpandedRow,
  TableToolbar as CarbonTableToolbar,
  TableSelectAll as CarbonTableSelectAll,
  TableSelectRow as CarbonTableSelectRow,
  TableBatchActions as CarbonTableBatchActions,
  TableBatchAction as CarbonTableBatchAction,
  DataTableSkeleton as CarbonDataTableSkeleton,
  TableToolbarMenu as TableToolbarMenuCarbon
} from '@carbon/react';
import classnames from 'classnames';

import type { PropsWithChildren } from 'react';

import './DataTable.scss';

export const DataTable = CarbonDataTable;
export const Table = CarbonTable;
export const TableHead = CarbonTableHead;
export const TableRow = CarbonTableRow;
export const TableHeader = CarbonTableHeader;
export const TableBody = CarbonTableBody;
export const TableCell = CarbonTableCell;
export const TableToolbarContent = CarbonTableToolbarContent;
export const TableToolbarSearch = CarbonTableToolbarSearch;
export const TableContainer = CarbonTableContainer;
export const TableExpandHeader = CarbonTableExpandHeader;
export const TableExpandRow = CarbonTableExpandRow;
export const TableExpandedRow = CarbonTableExpandedRow;
export const TableSelectAll = CarbonTableSelectAll;
export const TableSelectRow = CarbonTableSelectRow;
export const TableToolbar = CarbonTableToolbar;
export const TableBatchActions = CarbonTableBatchActions;
export const TableBatchAction = CarbonTableBatchAction;
export const DataTableSkeleton = CarbonDataTableSkeleton;
export const TableToolbarMenu = TableToolbarMenuCarbon;

export enum DATA_TABLE_SORT_DIRECTION {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE'
}

export const DataTableWrapper = (props: PropsWithChildren<{ className?: string }>) => (
  <div className={classnames('gf--data-table-wrapper', props.className)}>{props.children}</div>
);

// type
export type { DataTableHeader, DataTableCell, DataTableSortState };
