import { isEmpty } from 'lodash';

const parseFileFromUrl = async (url: string) => {
  if (isEmpty(url)) return null;
  const fileName = `${url.split('/').slice(-1)}`.split('?ts')[0];
  const date = new Date();
  try {
    const res = await fetch(url);
    if (res && res.status === 200) {
      const blob = await res.blob();
      return new File([blob], fileName, { type: 'image/png', lastModified: date.getTime() });
    }
  } catch (err) {
    return null;
  }
  return null;
};

export const convertImageToBase64PNG = (urlImg: string) =>
  new Promise<string>((resolve, reject) => {
    const img = new Image();
    img.src = `${urlImg}&ts=${new Date().getTime()}`;
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx?.drawImage(img, 0, 0);

      const b64 = canvas.toDataURL('image/png');
      resolve(b64);
    };
    img.onerror = reject;
  });

export default parseFileFromUrl;
