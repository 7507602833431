import React, { useState } from 'react';
import moment from 'moment';

import BasicButton from '@app/commons/generic-components/basic-button/basic-button.component';
import { Row, Column, Button, Icons, VenueDropdown, VenueFilter, NONE_SELECTED_VENUE } from '@gofan/components';
import { Edit16 } from '@carbon/icons-react';
import { VenueLocation } from '@events/components/editor/VenueLocation';

import { zip } from '@common/validations';
import { generateErrorMessage } from '@app/utils/alertUtils';
import { isEmpty } from '@utils/objectUtils';
import { useVenues } from '@app/hooks/useVenues';
import type { AccountDTO, AccountVenue } from '@gofan/api/accounts';
import { hasEditingPermissionForPlayOffEventOnField } from '@app/api/services/EventService';
import { config } from '@gofan/constants/config';

import Sections from '@app/pages/EventInformationV2/components/RequiredLayout/components/Sections/Sections';

import strings from '@app/modules/season-management/constants/strings';
import { VENUE_MODAL_MODE } from '@app/modules/season-management/constants/constant';
import SeasonVenueModal from '@app/modules/season-management/components/season-venue-modal/season-venue-modal.component';

import './VenueSection.scss';

interface VenueSectionProps {
  event: any;
  editMode: boolean;
  section: any;
  onVenueInformationChange: (params: any) => void;
  setNotification?: Function;
  id: string;
  needValidationDataAtMoment: any;
  accountId: string;
  actionButtonType: string;
  phoneNumber: string;
  postSeason?: boolean;
  districtAccount?: AccountDTO;
  activityType?: string;
}

const VENUE_DATA = {
  SET_UP_VENUE_BUTTON: 'Set up venue',
  UPDATE_VENUE_BUTTON: 'Edit venue',
  CREATE_VENUE_BUTTON: 'Create venue+',
  VIEW_SEATING_BUTTON: 'View seating map'
};

export const mappingAccountVenue = (venue?: AccountVenue) => ({
  accountId: venue?.accountId ?? '',
  name: venue?.name || '',
  address: venue?.streetAddress || '',
  city: venue?.city || '',
  state: venue?.state || '',
  zipCode: venue?.zip || '',
  location: venue?.location || '',
  id: venue?.id || null,
  seatsIoChartKey: venue?.seatsIoChartKey || ''
});

const mappingEventVenue = (event: any, districtAccount?: AccountDTO) => {
  const {
    accountId: homeSchoolId,
    venueAddress,
    venueCity,
    venueLocation,
    venueName,
    venueState,
    venueZip,
    venueId
  } = event || {};
  const accountId = districtAccount?.venues?.some(v => v.id === venueId) ? districtAccount?.id : homeSchoolId;
  return {
    accountId: accountId || '',
    name: venueName || '',
    address: venueAddress || '',
    city: venueCity || '',
    state: venueState || '',
    zipCode: venueZip || '',
    location: venueLocation || '',
    id: venueId || null
  };
};

const isEditable = (event: any) => {
  const nDate = moment(new Date());
  const evDate = moment.isMoment(event.endDateTime) ? event.endDateTime : moment(event.endDateTime);

  moment.isMoment(event.endDateTime);
  if (nDate.isAfter(evDate)) {
    return false;
  }

  return true;
};
const initCurrentVenue = ({
  event,
  editMode,
  section,
  venues,
  districtAccount
}: {
  event: any;
  editMode: boolean;
  section: any;
  venues: AccountVenue[];
  districtAccount?: AccountDTO;
}) => {
  if (!isEmpty(section)) {
    return { ...section };
  }
  if (!isEmpty(event)) {
    return {
      ...mappingEventVenue(event, districtAccount),
      hasChanged: false,
      valid: true
    };
  }
  return {
    ...mappingAccountVenue(venues?.[0]),
    hasChanged: false,
    valid: true
  };
};

function formatPhoneNumber(number = '') {
  // Use a regular expression to insert hyphens at specific positions
  return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
}

export const VenueSection = ({
  event,
  editMode,
  section,
  onVenueInformationChange = () => null,
  setNotification,
  id,
  needValidationDataAtMoment,
  accountId,
  phoneNumber,
  actionButtonType,
  postSeason,
  districtAccount,
  activityType
}: VenueSectionProps) => {
  const { venues, updateOrCreateVenue, loadingVenues, venuesError, isCreatingVenue, venueTypes, setVenueTypes } =
    useVenues({
      accountId: editMode ? event?.account?.id : accountId,
      setNotification,
      defaultDistrictVenues: districtAccount?.venues,
      eventVenueId: event?.venueId
    });

  const [openVenueFilter, setOpenVenueFilter] = useState<boolean>(false);

  const [currentVenue, setCurrentVenue] = useState(
    initCurrentVenue({ section, venues, editMode, event, districtAccount })
  );
  const [venueModalIsOpen, setVenueModalIsOpen] = useState(false);
  const [isShowError, setIsShowError] = useState(false);

  const restrictedByPlayOffEditingRule = !hasEditingPermissionForPlayOffEventOnField(event.playOffEventPermission);
  const seatsIoPreviewUrl = `https://app.seats.io/preview/eu/${config.SEATS_IO_PUBLIC_WORKSPACE_KEY}`;
  const hasVenueTypes = districtAccount && districtAccount.id !== accountId;

  React.useEffect(() => {
    let newVenue;
    if (venues.length > 0 && currentVenue?.name === '') {
      newVenue = !isEmpty(section)
        ? { ...section }
        : { ...mappingAccountVenue(venues?.[0]), hasChanged: false, valid: true };
    }

    if ((section?.awayGame && section?.valid) || event?.awayGame) {
      newVenue = { ...NONE_SELECTED_VENUE };
    }

    if (!section?.awayGame && !newVenue?.name) {
      newVenue = isEmpty(event) ? mappingAccountVenue(venues?.[0]) : mappingEventVenue(event, districtAccount);
    }

    if (!isEmpty(districtAccount?.venues) && Object.values(venueTypes).filter(v => !!v).length > 1) {
      newVenue = mappingAccountVenue(venues?.[1]);
    }

    if (newVenue) {
      setCurrentVenue({
        ...newVenue,
        hasChanged: false,
        valid: true
      });
    }
  }, [venues, event, section?.awayGame, venueTypes, districtAccount]);

  React.useEffect(() => {
    if (venuesError && setNotification) setNotification(generateErrorMessage(venuesError));
  }, [venuesError]);

  React.useEffect(() => {
    let hasChanged = false;
    if (!isEmpty(event) && editMode) {
      const { venueAddress, venueCity, venueLocation, venueName, venueState, venueZip, venueId } = event;
      hasChanged =
        currentVenue?.name !== venueName ||
        currentVenue?.address !== venueAddress ||
        currentVenue?.city !== venueCity ||
        (currentVenue?.location ?? '') !== (venueLocation ?? '') ||
        currentVenue?.state !== venueState ||
        currentVenue?.zipCode !== venueZip ||
        currentVenue?.id !== venueId;
    }
    onVenueInformationChange({
      ...currentVenue,
      valid: _checkValid(),
      hasChanged
    });
  }, [currentVenue, event, postSeason]);
  React.useEffect(() => {
    if (!isEmpty(section) && !isEmpty(needValidationDataAtMoment) && !section?.valid) {
      setIsShowError(true);
    }
  }, [needValidationDataAtMoment]);

  const venueOptions = React.useMemo(() => {
    const nonReservedSeatingVenues = venues.filter(venue => isEmpty(venue?.seatsIoChartKey));
    const options = event.hasAccessCode ? nonReservedSeatingVenues : [...venues];

    if (section?.awayGame && section?.valid) {
      options.unshift(NONE_SELECTED_VENUE);
    }

    return options;
  }, [venues, event.hasAccessCode, section?.awayGame]);

  return (
    <Sections title='Venue information' id={id}>
      <div className='venue-container'>
        <BasicButton
          className='venue-button'
          text={venues?.length !== 0 ? VENUE_DATA.UPDATE_VENUE_BUTTON : VENUE_DATA.CREATE_VENUE_BUTTON}
          kind='tertiary'
          textAlign='center'
          disabled={editMode && (!isEditable(event) || restrictedByPlayOffEditingRule)}
          onClick={() => {
            setVenueModalIsOpen(true);
          }}
          renderIcon={Edit16}
        />
        <Row className='gs--padding-top__sp3 gs--padding-bottom__sp10'>
          <Column className='gs--font-family-san gs--section-description gs--text' lg={8}>
            {' '}
            This event takes place in the following venue
          </Column>
        </Row>
        <Row className='gs--padding-top__sp3 gs--padding-bottom__sp10'>
          <Column lg={7}>
            <VenueDropdown
              value={currentVenue}
              options={venueOptions}
              onChangeVenue={({ selectedItem }) => {
                const newVenue = venueOptions.find(v => selectedItem?.id === v.id);

                if (newVenue) {
                  setCurrentVenue({
                    ...mappingAccountVenue(newVenue)
                  });
                }
              }}
              label={_getVenueSelectionDropdownLabel()}
              titleText='Select a venue'
              disabled={venues?.length < 1 || (editMode && (!isEditable(event) || restrictedByPlayOffEditingRule))}
            />
          </Column>
          {hasVenueTypes && (!editMode || (isEditable(event) && !restrictedByPlayOffEditingRule)) && (
            <Column lg={9} className='filter-venue'>
              <Icons.FilterEdit onClick={() => setOpenVenueFilter(!openVenueFilter)} />
              {openVenueFilter && (
                <div className='filter-modal'>
                  <VenueFilter
                    venueTypes={venueTypes}
                    onClose={() => setOpenVenueFilter(false)}
                    onChange={venueTypes => setVenueTypes(venueTypes)}
                  />
                </div>
              )}
            </Column>
          )}
        </Row>

        {!isEmpty(currentVenue.name) && (
          <div className='gs--margin-top-sp7'>
            <div style={{ display: 'inline-flex' }}>
              <Icons.LocationFilled />
              <div
                className='gs--productive-heading-02-semibold gs--font-family-sf gs--padding-left__sp3'
                style={{ lineHeight: '1rem' }}
              >
                {currentVenue.name}
              </div>
            </div>
            {/* <div className='gs--productive-heading-02-semibold gs--font-family-sf'>{selectedVenue.name}</div> */}
            <div className='gs--body-short-02 gs--font-family-sf'>{currentVenue.address}</div>
            <div className='gs--body-short-02 gs--font-family-sf'>
              {currentVenue.city}, {currentVenue.state}
            </div>
            <div className='gs--body-short-02 gs--font-family-sf'>{currentVenue.zipCode}</div>
            <div className='gs--body-short-02 gs--font-family-sf'>{formatPhoneNumber(phoneNumber)}</div>
          </div>
        )}

        <Row className='gs--padding-top__sp3 gs--padding-bottom__sp10'>
          {currentVenue?.seatsIoChartKey && (
            <Button
              className='gf-reserved-venue-btn gs--margin-top-sp4 cds--btn-centralize cds--btn-radius'
              kind='tertiary'
              size='sm'
              onClick={() => {
                window.open(`${seatsIoPreviewUrl}/${currentVenue?.seatsIoChartKey}`, '_blank');
              }}
            >
              View seating map
            </Button>
          )}
        </Row>
        {!isEmpty(currentVenue) && <VenueLocation activityType={activityType} venue={currentVenue} />}

        {venueModalIsOpen && (
          <SeasonVenueModal
            postSeason={postSeason}
            venue={currentVenue}
            open={venueModalIsOpen}
            mode={venues?.length !== 0 ? VENUE_MODAL_MODE.UPDATE : VENUE_MODAL_MODE.ADD}
            updateOrCreateVenue={updateOrCreateVenue}
            isCreatingVenue={isCreatingVenue}
            onSubmit={values => {
              setCurrentVenue(values);
            }}
            onClose={() => setVenueModalIsOpen(false)}
          />
        )}
      </div>
    </Sections>
  );

  function _getVenueSelectionDropdownLabel() {
    if (!isEmpty(currentVenue) && currentVenue?.name) return currentVenue?.name;
    if (loadingVenues) return strings.SEASON_GENERAL_SETUP.SEASON_VENUE.LOADING_VENUES;
    if (!loadingVenues && venues.length < 1) return strings.SEASON_GENERAL_SETUP.SEASON_VENUE.NO_VENUES_FOUND;
    return strings.SEASON_GENERAL_SETUP.SEASON_VENUE.SELECT_VENUE;
  }

  function _checkValid() {
    if (section?.awayGame) return true;
    return postSeason
      ? !isEmpty(currentVenue?.name)
      : !isEmpty(currentVenue?.name) &&
          !isEmpty(currentVenue?.address) &&
          !isEmpty(currentVenue?.city) &&
          !isEmpty(currentVenue?.state) &&
          !isEmpty(currentVenue?.zipCode) &&
          zip(currentVenue?.zipCode);
  }
};
