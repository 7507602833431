// flow

import {
  EventActionTypes,
  RESET_STATE,
  ROUTING_EVENT_PAGE,
  ROUTING_EVENT_PAGE_COMPLETED,
  SEARCH_EVENTS,
  SEARCH_EVENTS_SUCCESS
} from './actionTypes';
import { Event, Account } from '../../api/types';
import { SearchEventParams, RoutingEventParams } from './types';

export const resetState = (): EventActionTypes => ({
  type: RESET_STATE
});

export const searchEvents = (searchParams: SearchEventParams): EventActionTypes => ({
  type: SEARCH_EVENTS,
  payload: {
    searchParams
  }
});

export const searchEventsSuccess = (searchResult: Array<Event>): EventActionTypes => ({
  type: SEARCH_EVENTS_SUCCESS,
  payload: {
    searchResult
  }
});

export const routingEventPage = (routingParams: RoutingEventParams): EventActionTypes => ({
  type: ROUTING_EVENT_PAGE,
  payload: {
    routingParams
  }
});

export const routingEventPageCompleted = (account: Account): EventActionTypes => ({
  type: ROUTING_EVENT_PAGE_COMPLETED,
  payload: {
    account
  }
});
