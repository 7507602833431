export default {
  EVENT_CANCELLATIONS: {
    TITLE: 'Cancel this {eventType}',
    DESCRIPTION: 'You are choosing to cancel this {eventType}:',
    VS: 'VS',
    TICKETS_SOLD: 'Tickets sold',
    TICKET_SALES: 'Ticket sales',
    TICKETS_USED: 'Tickets used',
    PERCENT_OF_TICKETS_USED: '% of tickets used',
    ERROR_INSIGHT: 'Unable to load data',
    NOTIFICATION_EMAIL: 'Send notification email to ticket buyers',
    CONFIRM_LABEL: 'Confirm: I want to cancel this {eventType}',
    BTN_CANCEL: 'Cancel {eventType}',
    BTN_CLOSE: 'Close',
    REFUND_LANGUAGE: 'GoFan will process refunds for all {eventType} tickets'
  },
  EVENT_RESCHEDULE: {
    TITLE: 'Reschedule this {eventType}',
    DESCRIPTION: 'You are choosing to change the date/time of this {eventType}:',
    NOTIFICATION_EMAIL: 'Send notification email to ticket buyers',
    BTN_RESCHEDULE: 'Reschedule {eventType}',
    BTN_CLOSE: 'Close',
    RESCHEDULING: 'Rescheduling',
    RESCHEDULE_HEADER: 'Reschedule this {eventType} for:'
  },
  EVENT_RESTORE: {
    TITLE: 'Restore this event',
    DESCRIPTION:
      'You are restoring a previously cancelled event. Once restored, you can edit the event and fans can purchase tickets for this event. Are you sure you want to restore the event?',
    CANCEL_BUTTON: 'Cancel',
    RESTORE_BUTTON: 'Yes, restore this event'
  },
  EVENT_DUPLICATION: {
    TITLE: 'Duplicate this {eventType}',
    DESCRIPTION: `Duplicate creates an identical copy of this {eventType} and retains all of the {eventType} settings. To make sure you update your {eventType} {dateTimeLabel} enter those here and it will carry over to your copied {eventType}. You’ll have full control of all settings after you click `,
    NEW_START_DATE_TIME: 'New {eventType} start {dateTimeLabel}',
    NEW_EVENT_NAME: 'New {eventType} name',
    EVENT_NAME_PLACEHOLDER: 'Huddle Bulldogs vs. Western Tigers',
    BTN_CANCEL: 'Cancel',
    BTN_DUPLICATE: 'Duplicate'
  },
  ERRORS_MESSAGE: {
    ALPHANUMERIC_SPECIAL_CHARACTERS:
      'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (`()./_-#\'@&*+",:;?!) in between 3-100 characters long.',
    ALPHANUMERIC_SPECIAL_CHARACTERS_1:
      'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (`()./_-#\'@&*+",:;?!) in between 2-40 characters long.',
    EVENT_NAME: {
      REQUIRED: 'Event name is required.'
    }
  },
  UPLOAD_STATUS: {
    IN_PROGRESS: 'In Progress',
    UPLOADED: 'Uploaded'
  },
  EVENT_STATIC: {
    EMPTY_DESCRIPTION: 'Your event will start soon and insights will be available.'
  },
  FUNDRAISER: {
    GOAL_PROGRESS_SECTION: {
      TITLE: 'Fundraiser goal'
    },
    MANAGE_SECTION: {
      TITLE: 'Manage your fundraiser',
      DROPDOWN_LABEL: 'What do you need to do?',
      GO_BTN: 'Go'
    },
    BOOST_SECTION: {
      TITLE: 'Boost fundraiser',
      FUNDRAISER_LINK_: 'Fundraiser link',
      DOWNLOAD_QR_CODE: 'Download QR code',
      GET_EVENT_QR_CODE_POSTER: 'Get event QR code poster'
    },
    SETTINGS_SECTION: {
      TITLE: 'Fundraiser settings',
      EDIT_BTN: 'Edit'
    },
    DONATION_LEVEL: {
      DONATION_PRICE: 'Donation price',
      TOTAL_AVAILABLE: 'Total available',
      DONOR_PAYS_FEE: 'Donor pays fee',
      BOX_OFFICE: 'Box Office',
      ADDITIONAL_INFO: 'Additional info'
    }
  },
  GAME_DAY_SECTION: {
    TITLE: 'Game Day',
    NON_ATHLETIC_TITLE: 'Event Day',
    PERFORMANCE_ARTS_TITLE: 'Event Countdown',
    SUBTITLE: 'Get ready for your event as the countdown begins'
  }
};
