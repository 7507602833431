import { delay, put, takeEvery, call, takeLatest, debounce, all } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import omit from 'lodash/omit';
import flatten from 'lodash/flatten';
import isNil from 'lodash/isNil';

import { config, EVENT_START_TIME_TYPE, PRODUCT_TYPE } from '@gofan/constants';
import LocalStorage from '@app/LocalStorage';
import CacheService from '@app/api/services/CacheService';

import { DateUtils } from '@gofan/utils/date';
import { DATE_FORMAT_DEFAULT_WITH_TIMEZONE } from '@gofan/constants/date';
import { AccessLevelEnum, PromotionService } from '@gofan/api/promotions';

import {
  ON_PUBLISH_EVENT,
  SEARCH_OPPONENT_ACCOUNTS,
  SEARCH_ACCOUNT_TICKETS,
  EXECUTE_INITIAL_EVENT_PROCESS
} from './actionTypes';
import {
  onPublishEventSuccess,
  onPublishEventError,
  fetchActivitiesSuccessful,
  searchOpponentAccountsSuccess,
  fetchLevelsSuccessful,
  fetchAccountSuccessful,
  fetchAccountCompleted,
  fetchVenueByIdSuccessfull,
  fetchEventByIdSuccessfull,
  executeInitialEventProcessCompleted
} from './actions';
import { createEventSuccess } from '../EventDetail/actions';
import { fetchTickets } from '../../api/services/TicketService';
import Account from '../../api/model/Account';
import { showAccountInfo } from '../Root/actions';
import { addBulkPromotionInfo } from '@promotions/middleware/actions';
import ActivityService from '../../api/services/ActivityService';
import * as AccountService from '../../api/services/AccountService';
import LevelService from '../../api/services/LevelService';
import * as VenueService from '../../api/services/VenueService';
import { VenueService as VService } from '@gofan/api/venues';
import EventV2 from '../../api/model/EventV2';
import ErrorDTO from '../../api/dto/ErrorDTO';
import AccountDTO from '../../api/dto/AccountDTO';
import Level from '../../api/model/Level';
import Activity from '../../api/model/Activity';
import Venue from '../../api/model/Venue';
import PageRequest from '../../api/model/request/PageRequest';
import SortableRequest from '../../api/model/request/SortableRequest';
import SearchAccountTicketsRequest from '../../api/model/request/SearchAccountTicketsRequest';
import PaginationModel from '../../api/model/PaginationModel';
import AccountTicket from '../../api/model/AccountTicket';
import TicketDTO from '../../api/dto/TicketDTO';
import PaginationDTO from '../../api/dto/PaginationDTO';
import * as actionCreator from './actions.ts';
import { DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST } from './constants';

import { PAGES } from '../../config/routes';
import { isEmpty } from '../../utils/objectUtils';
// import EventTypeDTO from '../dto/EventTypeDTO';
import { CANCELLED_REQUEST, UNEXPECTED_ERROR } from '../../api/api/constants';
import PublishEventRequest from '../../api/model/request/PublishEventRequest';
import { DISTRIBUTION_CHANNEL } from '../../api/model/request/ProductRequest';
import {
  OMIT_NEW_PRODUCT_FIELDS,
  createEvent,
  groupLevelById,
  generateEventName,
  generateSpecialEventDescription,
  getEvent,
  filterCreatingPromotionCodes
} from '../../api/services/EventService';
import { parseString } from '../../utils/parseUtils';
import { arrangePromoCodesToPublish } from './helpers';
import { EventService } from '@gofan/api/events';
import { getToken, getRefreshToken, normalizeAccountProduct } from '@gofan/api';
import { getTimeZone } from '@app/utils';

dayjs.extend(utc);
dayjs.extend(timezone);

export function* searchAccounts(action) {
  try {
    const coordinate = CacheService.getCachedData(
      CacheService.USER_COORDINATE_CACHE_NAME,
      CacheService.CURRENT_COORDINATE_CACHE_NAME
    );
    const { keyword } = action.payload;
    const response = yield call(AccountService.fetchAccountsByKeyword, keyword, coordinate, true);
    if (response instanceof Array) {
      yield put(searchOpponentAccountsSuccess(response.map(account => new Account(account).toJSON())));
    } else {
      yield put(searchOpponentAccountsSuccess([]));
    }
  } catch (e) {
    if (e.message !== CANCELLED_REQUEST) yield put(searchOpponentAccountsSuccess([]));
  }
}

export function* fetchAccountById(action) {
  try {
    const { accountId } = action.payload;
    const response = yield call(AccountService.fetchAccountById, accountId);
    if (response instanceof AccountDTO) {
      const account = new Account(response.toJSON()).toJSON();
      yield put(fetchAccountSuccessful(account));
      yield put(showAccountInfo(account));
    }
    yield put(fetchAccountCompleted());
  } catch (e) {
    // do nothing
    yield put(fetchAccountCompleted());
  }
}

export function* searchAccountTickets({ pageSize, pageIndex, accountId, ticketName, expands, sortBy }) {
  const pageRequest = new PageRequest({
    pageSize,
    pageIndex
  });
  const sortableRequest = new SortableRequest({
    sortBy
  });
  const searchTicketsRequest = new SearchAccountTicketsRequest({
    accountId,
    ticketName
  });
  const response = yield call(fetchTickets, searchTicketsRequest, expands, pageRequest, sortableRequest);
  let searchResult = new PaginationModel({}).toJSON();

  if (response instanceof PaginationDTO) {
    const accountTickets = normalizeAccountProduct(
      response.data.map(ticket => new AccountTicket(ticket.toJSON()).toJSON())
    );
    searchResult = new PaginationModel({
      pageSize: response.pageSize,
      pageIndex: response.pageNumber,
      pageCount: response.totalPages,
      data: [...accountTickets]
    }).toJSON();
  }
  return searchResult;
}

export function* handleSearchAccountTickets(action) {
  let searchResult = new PaginationModel({}).toJSON();
  try {
    const { searchParams } = action.payload;
    searchResult = yield call(searchAccountTickets, searchParams);
    yield put(actionCreator.searchAccountTicketsCompleted(searchResult));
  } catch (error) {
    yield put(actionCreator.searchAccountTicketsCompleted(searchResult));
  }
}

export function* cancelSearchAccounts() {
  AccountService.cancelFetchAccountsByKeyword();
}

export function* watchSearchOpponentAccounts() {
  yield takeLatest(SEARCH_OPPONENT_ACCOUNTS, cancelSearchAccounts);
  yield debounce(DEBOUNCE_TIME_TO_POST_SEARCH_REQUEST, SEARCH_OPPONENT_ACCOUNTS, searchAccounts);
}

export function* watchSearchAccountTicketsRequest() {
  yield takeLatest(SEARCH_ACCOUNT_TICKETS, handleSearchAccountTickets);
}

export function* createPromoCodes(action) {
  const { promotionCodes, eventResponse, ticketTypeData } = action.payload;

  const promoCodes = isEmpty(promotionCodes)
    ? []
    : filterCreatingPromotionCodes({ promotionCodes, eventResponse, ticketTypeData });

  if (!isEmpty(promoCodes)) {
    const promoCodesToPublish = arrangePromoCodesToPublish(promoCodes);
    const authToken = yield call(getToken, true);
    const refreshToken = yield call(getRefreshToken);
    const bulkPromotionRequest = {
      id: uuidv4(),
      authToken,
      refreshToken,
      codes: promoCodesToPublish
    };
    yield call(PromotionService.createBulkPromotions, bulkPromotionRequest);
    yield put(
      addBulkPromotionInfo({
        batchId: bulkPromotionRequest.id,
        eventId: eventResponse?.id,
        hasAccessCode: promoCodesToPublish.some(promoCode => promoCode.required),
        hasPromotionCode: promoCodesToPublish.some(promoCode => !promoCode.required)
      })
    );
  }
}

export function* onHandlePublishEvent(action) {
  try {
    const { history, requiredData, optionalData, ticketTypeData, streamingData, onCompleteFunction } = action.payload;
    // await for loading
    yield delay(200);
    if (isEmpty(requiredData) || isEmpty(optionalData) || isEmpty(ticketTypeData)) {
      yield put(onPublishEventError(UNEXPECTED_ERROR));
      return;
    }

    // Create Event_Type
    const reportingLabel = isEmpty(requiredData.activity) ? undefined : requiredData.activity.label;

    // Transform level data
    const { levels } = requiredData;
    const genders = [];
    const newLevels = [];
    if (!isEmpty(levels)) {
      levels.forEach(lv => {
        isEmpty(lv.levelId) ? genders.push(...lv.genders) : newLevels.push(lv);
      });
    }
    const accessCodes = (optionalData?.promotionCodes ?? []).filter(promotionCode => promotionCode.required);
    const hasEventLevelAccessCode = accessCodes.some(code => code.accessLevel === AccessLevelEnum.EVENT);
    const associatedProductIds = flatten(
      accessCodes.map(code => (code?.productAssociations ?? []).map(({ productId }) => productId))
    );

    let eventIntegrationDetails;

    if ((streamingData?.eventIntegrationDetails ?? []).length > 0) {
      eventIntegrationDetails = [...streamingData.eventIntegrationDetails];

      if (streamingData?.enableBroadCastSettings && streamingData?.startTimeType === EVENT_START_TIME_TYPE.ALL_TEAMS) {
        eventIntegrationDetails = eventIntegrationDetails.map(event => ({
          ...event,
          isBroadcast: streamingData?.allTeamsIsBroadcast,
          broadcastStartTime: streamingData?.allTeamsIsBroadcast ? streamingData?.allTeamsStartTime : undefined,
          broadcastEndTime: streamingData?.allTeamsIsBroadcast ? streamingData?.allTeamsEndTime : undefined
        }));
      }

      eventIntegrationDetails = eventIntegrationDetails.map(item => {
        if (item.vodBlackoutStartDate && item.vodBlackoutEndDate) {
          const timeZone = requiredData.account.timeZone || dayjs.tz.guess();

          const vodBlackoutStartDate = dayjs(item?.vodBlackoutStartDate)
            .set('hour', 1)
            .set('minute', 1)
            .tz(getTimeZone(timeZone))
            .format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE);

          const vodBlackoutEndDate = dayjs(item?.vodBlackoutEndDate)
            .set('hour', 23)
            .set('minute', 59)
            .tz(getTimeZone(timeZone))
            .format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE);

          return {
            ...item,
            vodBlackoutStartDate,
            vodBlackoutEndDate
          };
        }

        return item;
      });
    }

    // Create Event
    const eventRequest = new PublishEventRequest({
      ...requiredData,
      ...optionalData,
      ...ticketTypeData,
      startTimeType: streamingData?.enableBroadCastSettings
        ? streamingData?.startTimeType
        : requiredData.startTimeType ?? EVENT_START_TIME_TYPE.ALL_TEAMS,
      redemptionWindow: !isNil(requiredData.redemptionWindow) ? requiredData.redemptionWindow * 60 : null,
      postSeason: false,
      disabledForIndividualSale: false,
      timeZone: requiredData.account.timeZone,
      featured: !isEmpty(optionalData.featuredAccountIds),
      eventValidationStartsBefore: requiredData.enableEventValidation
        ? requiredData.eventValidationStartsBefore * 60
        : undefined,
      reportingLabel,
      name: requiredData.eventName || generateEventName(requiredData.account, requiredData.opponentAccount),
      specialEventDescription: generateSpecialEventDescription({
        theme: parseString(optionalData.theme),
        levels: requiredData.levels
      }),
      genders,
      levels: !isEmpty(newLevels) ? groupLevelById(newLevels) : [],
      eventIntegrationDetails,
      venueZip: requiredData.venueZip || undefined,
      products: ticketTypeData.products.reduce((res, product) => {
        let ticketSalesStartDateTime;
        let ticketSalesEndDateTime;

        if (requiredData?.awayGame && (!product?.name || !product?.price)) {
          return res;
        }

        if (!isEmpty(product?.salesStartDate) && !isEmpty(product?.salesStartTime)) {
          const mSalesStartDateTime = dayjs(`${product?.salesStartDate} ${product?.salesStartTime}`);
          ticketSalesStartDateTime = DateUtils.switchZone(
            mSalesStartDateTime.clone().format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
            requiredData.account.timeZone
          );
        }

        if (!isEmpty(product?.salesEndDate) && !isEmpty(product?.salesEndTime)) {
          const mSalesEndDateTime = dayjs(`${product?.salesEndDate} ${product?.salesEndTime}`);
          ticketSalesEndDateTime = DateUtils.switchZone(
            mSalesEndDateTime.clone().format(DATE_FORMAT_DEFAULT_WITH_TIMEZONE),
            requiredData.account.timeZone
          );
        }

        const feeRateId = EventService.populateFeeAndRateId(product, null, 'Create event');
        const optionalSettings = {
          salesStartDateTime: ticketSalesStartDateTime,
          salesEndDateTime: ticketSalesEndDateTime,
          ticketLimitPerOrder: product?.ticketLimitPerOrder,
          redemptionLimit: product?.unlimitedPass ? undefined : product.redemptionLimit,
          packCount: product?.enabledPackCount ? product.packCount : undefined,
          ...feeRateId
        };

        const isSetDefaultFormId = product.productType === PRODUCT_TYPE.MOBILEPASS && isEmpty(product.formFields);
        // GOFAN
        res.push(
          omit(
            {
              ...product,
              ...optionalSettings,
              id: undefined,
              enabled: product?.enabled ?? true,
              distributionChannel: DISTRIBUTION_CHANNEL.GOFAN,
              accountId: ticketTypeData.ticketDistribution ? product.accountId : undefined,
              groupId:
                !ticketTypeData.ticketDistribution || product.accountId === requiredData.accountId
                  ? product.groupId
                  : undefined,
              promotionRequired: hasEventLevelAccessCode || associatedProductIds.includes(product.id),
              formId: isSetDefaultFormId ? config.MOBILE_PASS_FORM_ID : product.formId
            },
            OMIT_NEW_PRODUCT_FIELDS
          )
        );

        // BOXOFFICE
        if (product.enabledTicketBoxOffice) {
          if (ticketTypeData.ticketDistribution) {
            if (product.accountId === requiredData.accountId) {
              res.push({
                ...optionalSettings,
                id: undefined,
                reservedSeating: product.reservedSeating,
                seatsIoCategory: product.seatsIoCategory,
                accountProductId: product.accountProductId,
                enabled: product?.enabledTicketBoxOffice ?? true,
                distributionChannel: DISTRIBUTION_CHANNEL.BOXOFFICE,
                accountId: product.accountId,
                groupId: product.groupId,
                name: product.nameBoxOffice,
                price: product.priceBoxOffice,
                hiddenFees: product.hiddenFees,
                productType: product.productType,
                formFields: product.formFields,
                packCount: null,
                formId: isSetDefaultFormId ? config.MOBILE_PASS_FORM_ID : product.formId
              });
            }
          } else {
            res.push({
              ...optionalSettings,
              id: undefined,
              reservedSeating: product.reservedSeating,
              seatsIoCategory: product.seatsIoCategory,
              accountProductId: product.accountProductId,
              enabled: product?.enabledTicketBoxOffice ?? true,
              distributionChannel: DISTRIBUTION_CHANNEL.BOXOFFICE,
              accountId: undefined,
              groupId: product.groupId,
              name: product.nameBoxOffice,
              price: product.priceBoxOffice,
              hiddenFees: product.hiddenFees,
              productType: product.productType,
              formFields: product.formFields,
              packCount: null,
              formId: isSetDefaultFormId ? config.MOBILE_PASS_FORM_ID : product.formId
            });
          }
        }
        return res;
      }, [])
    });

    const eventResponse = yield call(createEvent, eventRequest, true);
    if (eventResponse && eventResponse instanceof ErrorDTO) {
      const currentUser = LocalStorage.getStorageCurrentUser();
      const errorMessage = eventResponse.getErrorMessageByRole(currentUser.role) || UNEXPECTED_ERROR;
      const responseBody = eventResponse.getData();
      yield put(onPublishEventError(errorMessage, responseBody));
      return;
    }

    try {
      yield call(createPromoCodes, {
        payload: {
          eventResponse,
          ticketTypeData,
          promotionCodes: isEmpty(optionalData) ? [] : optionalData.promotionCodes
        }
      });
    } catch (e) {
      const error = new ErrorDTO(e);
      yield put(onPublishEventError(error.getErrorMessage() || UNEXPECTED_ERROR));
    }

    yield put(onPublishEventSuccess());

    let isReservedSeating = false;
    const { products = [] } = ticketTypeData;

    products.forEach(product => {
      if (product.reservedSeating === true && !isReservedSeating) isReservedSeating = true;
    });

    if (isReservedSeating) yield call(VService.syncVenueChart, eventRequest.venueId);

    yield put(createEventSuccess());
    if (typeof onCompleteFunction === 'function') onCompleteFunction();
    yield delay(2000);

    const isStreamingEvent =
      (eventResponse.eventIntegrationDetails ?? []).length > 0 &&
      (eventResponse.eventIntegrationDetails ?? []).some(item => item.isBroadcast);

    yield call(
      history.push,
      isStreamingEvent
        ? `${PAGES.eventsV2.view.calculatePath(eventResponse.id)}?createStreaming=true`
        : PAGES.eventsV2.view.calculatePath(eventResponse.id)
    );
  } catch (error) {
    yield put(onPublishEventError(UNEXPECTED_ERROR));
  }
}

export function* watchPublishEvent() {
  yield takeEvery(ON_PUBLISH_EVENT, onHandlePublishEvent);
}

export function* onHandleExecuteInitialEventProcess(action) {
  const { history, accountId } = action.payload;

  const [eventResponse, accountResponse, levelsResponse, activitiesResponse] = yield all([
    call(prepareEventData, action),
    call(AccountService.fetchAccountById, accountId),
    call(LevelService.fetchAllLevels),
    call(ActivityService.fetchAllActivities)
  ]);

  if (eventResponse instanceof EventV2) {
    yield put(fetchEventByIdSuccessfull(eventResponse.toJSON()));
  }

  if (accountResponse instanceof AccountDTO) {
    const account = new Account(accountResponse.toJSON()).toJSON();
    if (!account.isActive) {
      history.replace(PAGES.dashboard.root.path);
      return;
    }

    yield put(fetchAccountSuccessful(account));
    yield put(showAccountInfo(account));

    // filter levels section
    let levels = [];
    if (levelsResponse instanceof Array) {
      const newLevels = levelsResponse.reduce((levelList, currentLevel) => {
        const level = new Level(currentLevel).toJSON();
        if (!level.disabled) levelList.push(level);
        return levelList;
      }, []);
      levels = newLevels.sort((a, b) => a.name.localeCompare(b.name));
    }
    yield put(fetchLevelsSuccessful(levels));

    // fetch all activities section
    let athleticActivities = [];
    let nonAthleticActivities = [];
    const totalList = { athleticActivities, nonAthleticActivities };
    if (activitiesResponse instanceof Array) {
      const newActivities = activitiesResponse.reduce((data, currentActivity) => {
        const activity = new Activity(currentActivity).toJSON();
        if (!activity.disabled) {
          activity.athletic ? data.athleticActivities.push(activity) : data.nonAthleticActivities.push(activity);
        }
        return data;
      }, totalList);

      athleticActivities = newActivities.athleticActivities.sort((a, b) => a.label.localeCompare(b.label));
      nonAthleticActivities = newActivities.nonAthleticActivities.sort((a, b) => a.label.localeCompare(b.label));
    }
    yield put(fetchActivitiesSuccessful(athleticActivities, nonAthleticActivities));

    if (!isEmpty(accountResponse.venueIds)) {
      const venueResponse = yield call(VenueService.getVenueById, accountResponse.venueIds[0]);
      const venue = new Venue(venueResponse?.toJSON()).toJSON();
      yield put(fetchVenueByIdSuccessfull(venue));
    }

    yield put(executeInitialEventProcessCompleted());
  } else {
    yield put(executeInitialEventProcessCompleted());
    const errorMessage =
      (accountResponse instanceof ErrorDTO && accountResponse.data ? accountResponse.data.message : UNEXPECTED_ERROR) ||
      (levelsResponse instanceof ErrorDTO && levelsResponse.data ? levelsResponse.data.message : UNEXPECTED_ERROR) ||
      (activitiesResponse instanceof ErrorDTO && activitiesResponse.data
        ? activitiesResponse.data.message
        : UNEXPECTED_ERROR);
    yield put(onPublishEventError(errorMessage));
    yield delay(2000);
    history.replace(PAGES.dashboard.root.path);
  }
}

export function* prepareEventData(action) {
  try {
    const { history } = action.payload;
    const {
      location: { state: locationState = {} }
    } = history;
    const { duplicatedEvent } = locationState ?? {};
    const { id: eventId } = duplicatedEvent ?? {};

    if (isEmpty(eventId)) return {};

    const eventResponse = yield call(getEvent, eventId, {
      expand: ['levels', 'activity', 'event-type']
    });
    if (eventResponse instanceof ErrorDTO) {
      return eventResponse;
    }

    // Accounts
    const taggedAccountIds = isEmpty(eventResponse.taggedAccountIds) ? [] : eventResponse.taggedAccountIds;

    // Get Account Info for ticket distribution
    const distributedAccountIds = [];
    if (!isEmpty(eventResponse.accountsTicket)) {
      eventResponse.accountsTicket.forEach(accountTicket => {
        const accId = `${accountTicket.accountId}`;
        if (distributedAccountIds.indexOf(accId) === -1) {
          distributedAccountIds.push(accId);
        }
      });
    }

    const accountIds = [
      eventResponse.accountId,
      eventResponse.hostAccountId,
      eventResponse.opponentAccountId,
      eventResponse.financialAccountId,
      ...taggedAccountIds,
      ...distributedAccountIds
    ].reduce((res, id) => {
      if (id && res.indexOf(id) === -1) {
        res.push(id);
      }
      return res;
    }, []);

    const accountResponses = yield all(accountIds.map(id => call(AccountService.fetchAccountById, id)));

    let error;
    let account;
    let hostAccount;
    let opponentAccount;
    let financialAccount;
    const taggedAccounts = [];
    const distributedAccounts = [];

    accountResponses.some(item => {
      if (item instanceof ErrorDTO) {
        error = item;
        return true;
      }
      if (item.id === eventResponse.accountId) {
        account = item;
      }
      if (item.id === eventResponse.hostAccountId) {
        hostAccount = item;
      }
      if (item.id === eventResponse.opponentAccountId) {
        opponentAccount = item;
      }
      if (item.id === eventResponse.financialAccountId) {
        financialAccount = item;
      }
      if (taggedAccountIds.indexOf(item.id) !== -1) {
        taggedAccounts.push(item);
      }
      if (distributedAccountIds.indexOf(item.id) !== -1) {
        distributedAccounts.push(item);
      }
      return false;
    });

    if (!isEmpty(account)) {
      yield put(showAccountInfo(account));
    }

    if (error instanceof ErrorDTO) {
      return error;
    }

    // Venue
    let venue = {};
    if (
      !eventResponse.venueAddress &&
      !eventResponse.venueCity &&
      !eventResponse.venueName &&
      !eventResponse.venueState &&
      !eventResponse.venueZip &&
      eventResponse.venueId
    ) {
      const venueResponse = yield call(getVenueById, eventResponse.venueId);
      if (venueResponse instanceof ErrorDTO) {
        return venueResponse;
      }
      venue = {
        venueAddress: venueResponse.streetAddress,
        venueCity: venueResponse.city,
        venueName: venueResponse.name,
        venueState: venueResponse.state,
        venueZip: venueResponse.zip
      };
    }

    // re-assign duplicated event ticket fee = null for api re-calculate fee
    let newProducts = eventResponse.raw.products;
    if (duplicatedEvent && duplicatedEvent.id) {
      newProducts = newProducts.map(product => {
        const newProduct = new TicketDTO({ ...product, fee: null, hiddenFeeBase: null });
        return newProduct;
      });
    }

    return new EventV2({
      ...eventResponse.toJSON(),
      seasonId: null,
      ...venue,
      account,
      hostAccount,
      opponentAccount,
      financialAccount,
      taggedAccounts,
      distributedAccounts,
      products: newProducts,
      ...duplicatedEvent
    });
  } catch (error) {
    return ErrorDTO.convertToError(error);
  }
}

export function* watchExecuteInitialEventProcess() {
  yield takeLatest(EXECUTE_INITIAL_EVENT_PROCESS, onHandleExecuteInitialEventProcess);
}

export default [
  watchPublishEvent(),
  watchSearchOpponentAccounts(),
  watchSearchAccountTicketsRequest(),
  watchExecuteInitialEventProcess()
];
