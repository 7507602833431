import { FEATURE_FLAGS, useFeatureFlags } from '@gofan/hooks/useFeatureFlags';

import { AccountQuery, useSalesforceAccount, useSchoolConfig } from '@gofan/api';
import isEmpty from 'lodash/isEmpty';

import { DISTRICT_CONFERENCE_SCHOOL_TYPES } from '@gofan/constants';

import type { AccountDTO } from '@gofan/api';

interface UseDistrictDataProps {
  account: AccountDTO;
  needFetchDistrictAccount?: boolean;
  needFetchUnderDistrictSchools?: boolean;
  needFetchSchoolConfig?: boolean;
  keepPreviousUnderDistrictSchoolsData?: boolean;
  forceEnableFeatureFlag?: boolean;
}

const QUERY_OPTIONS = {
  refetchOnWindowFocus: false,
  cacheTime: 24 * 60 * 60 * 1000, // = 1 day
  staleTime: 24 * 60 * 60 * 1000
};

export const useDistrictData = ({
  account,
  needFetchDistrictAccount = false,
  needFetchUnderDistrictSchools = false,
  needFetchSchoolConfig = false,
  keepPreviousUnderDistrictSchoolsData = false,
  forceEnableFeatureFlag = false
}: UseDistrictDataProps) => {
  const { [FEATURE_FLAGS.tempDistrictConferenceUnicorn]: tempDistrictConferenceUnicorn } = useFeatureFlags();
  const enableDistrictUnicorn = tempDistrictConferenceUnicorn || forceEnableFeatureFlag;

  const { data: districtAccount, isInitialLoading: isInitialDistrictAccountLoading } = useSalesforceAccount({
    salesforceId: account?.districtHuddleId,
    queryOptions: {
      ...QUERY_OPTIONS,
      enabled: enableDistrictUnicorn && needFetchDistrictAccount && !isEmpty(account?.districtHuddleId),
      placeholderData: () => {
        if (
          enableDistrictUnicorn &&
          needFetchDistrictAccount &&
          account?.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT
        ) {
          return account;
        }

        return undefined;
      }
    }
  });

  const { data: underDistrictSchools, isInitialLoading: isInitialUnderDistrictSchoolsLoading } =
    AccountQuery.getAccountSchoolsByDistrict({
      districtHuddleId: districtAccount?.id,
      queryOptions: {
        ...QUERY_OPTIONS,
        enabled: enableDistrictUnicorn && needFetchUnderDistrictSchools && !isEmpty(districtAccount),
        keepPreviousData: keepPreviousUnderDistrictSchoolsData
      }
    });

  const { data: schoolConfig, isInitialLoading: isInitialSchoolConfigLoading } = useSchoolConfig({
    schoolId: account?.id,
    queryOptions: {
      ...QUERY_OPTIONS,
      enabled: enableDistrictUnicorn && needFetchSchoolConfig && !isEmpty(account?.districtHuddleId)
    }
  });

  return {
    districtAccount,
    underDistrictSchools,
    schoolConfig,
    isInitialDistrictDataLoading:
      isInitialUnderDistrictSchoolsLoading || isInitialSchoolConfigLoading || isInitialDistrictAccountLoading
  };
};
