// @flow
import LevelDAO from '../dao/LevelDAO';
import Level from '../model/Level';
import { isEmpty } from '../../utils/objectUtils';

const fetchAllLevels = () => LevelDAO.fetchAllLevels();

const createLevel = level => LevelDAO.createLevel(level);

const updateLevel = level => LevelDAO.updateLevel(level);

const deleteLevel = id => LevelDAO.deleteLevel(id);

const filterAvailableLevels = (levels = []) => {
  let availableLevels = [];
  if (levels instanceof Array && !isEmpty(levels)) {
    const newLevels = levels.reduce((levelList, currentLevel) => {
      const level = new Level(currentLevel).toJSON();
      if (!level.disabled && level.name && `${level.name ?? ''}`.trim() !== '') levelList.push(level);
      return levelList;
    }, []);
    availableLevels = newLevels.sort((a, b) => a.name.localeCompare(b.name));
  }
  return availableLevels;
};

export default {
  fetchAllLevels,
  filterAvailableLevels,
  createLevel,
  updateLevel,
  deleteLevel
};
