import type { LevelDTO } from '@gofan/api/levels';
import type { SchoolConfig } from '@gofan/api/school-config';
import type { AccountDTO } from '@gofan/api/accounts';
import type { Event } from '../../api/types';
import type { SearchEventParams, SearchEventResult } from './types';
import type { Activity } from '../Activities/types';

export const RESET_PUBLISH_STATE = '@eventLanding/RESET_PUBLISH_STATE';
export const SEARCH_EVENTS = '@eventLanding/SEARCH_EVENTS';
export const SEARCH_EVENTS_COMPLETED = '@eventLanding/SEARCH_EVENTS_COMPLETED';
export const FETCH_ACTIVITIES = '@eventLanding/FETCH_ACTIVITIES';
export const FETCH_ACTIVITIES_SUCCESSFUL = '@eventLanding/FETCH_ACTIVITIES_SUCCESSFUL';
export const FETCH_ACTIVITIES_COMPLETED = '@eventLanding/FETCH_ACTIVITIES_COMPLETED';
export const PREPARE_DATA = '@eventLanding/PREPARE_DATA';
export const PREPARE_DATA_COMPLETED = '@eventLanding/PREPARE_DATA_COMPLETED';

export const UPDATE_EVENT_VISIBILITY = '@eventLanding/UPDATE_EVENT_VISIBILITY';
export const UPDATE_EVENT_VISIBILITY_COMPLETED = '@eventLanding/UPDATE_EVENT_VISIBILITY_COMPLETED';

export const DELETE_EVENT = '@eventLanding/DELETE_EVENT';
export const DELETE_EVENT_COMPLETED = '@eventLanding/DELETE_EVENT_COMPLETED';

export const CLEAR_EVENT_TABLE = '@eventLanding/CLEAR_EVENT_TABLE';

export const FETCH_EVENT_SALES_INFO = '@eventLanding/FETCH_EVENT_SALES_INFO';
export const FETCH_EVENT_SALES_INFO_COMPLETED = '@eventLanding/FETCH_EVENT_SALES_INFO_COMPLETED';

export const FETCH_ALL_ACTIVITIES = '@eventLanding/FETCH_ALL_ACTIVITIES';

export const FETCH_ALL_LEVELS_COMPLETED = '@eventLanding/FETCH_ALL_LEVELS_COMPLETED';

export const FETCH_ACCOUNTS_BY_IDS_COMPLETED = '@eventLanding/FETCH_ACCOUNTS_BY_IDS_COMPLETED';

export const ON_REQUEST_TICKET_DISTRIBUTION_FOR_EVENTS = '@eventLanding/ON_REQUEST_TICKET_DISTRIBUTION_FOR_EVENTS';
export const ON_REQUEST_TICKET_DISTRIBUTION_FOR_EVENTS_COMPLETED =
  '@eventLanding/ON_REQUEST_TICKET_DISTRIBUTION_FOR_EVENTS_COMPLETED';

export const CLEAR_DATA_CREATED_LINKS = '@eventLanding/CLEAR_DATA_CREATED_LINKS';

interface ResetPublishStateAction {
  type: typeof RESET_PUBLISH_STATE;
}

interface PrepareDataAction {
  type: typeof PREPARE_DATA;
  payload: {
    history: Object;
    accountId: string;
    currentUser: Object;
    searchParams: Object;
    enableDistrictUnicorn?: boolean;
  };
}

interface PrepareDataCompletedAction {
  type: typeof PREPARE_DATA_COMPLETED;
  error: any;
}

interface SearchEventsAction {
  type: typeof SEARCH_EVENTS;
  payload: {
    accountId: string;
    searchParams: SearchEventParams;
  };
}

interface SearchEventsCompletedAction {
  type: typeof SEARCH_EVENTS_COMPLETED;
  payload: {
    searchResult: SearchEventResult;
  };
  error: any;
}

interface UpdateEventVisibilityAction {
  type: typeof UPDATE_EVENT_VISIBILITY;
  payload: {
    event: Object;
    archived: boolean;
  };
}

interface UpdateEventVisibilityCompletedAction {
  type: typeof UPDATE_EVENT_VISIBILITY_COMPLETED;
  payload: {
    events: Array<Event>;
  };
  error: any;
}

interface DeleteEventAction {
  type: typeof DELETE_EVENT;
  payload: {
    eventId: string;
    globalEventsId: string;
    searchParams: SearchEventParams;
  };
}

interface ClearEventTable {
  type: typeof CLEAR_EVENT_TABLE;
}

interface DeleteEventCompletedAction {
  type: typeof DELETE_EVENT_COMPLETED;
  payload: {};
  error: any;
}

interface FetchEventSalesInfoAction {
  type: typeof FETCH_EVENT_SALES_INFO;
  payload: {
    eventId: string;
  };
}

interface FetchEventSalesInfoCompletedAction {
  type: typeof FETCH_EVENT_SALES_INFO_COMPLETED;
  payload: {
    events: Array<Event>;
  };
  error: any;
}
interface FetchAllLevelsCompleted {
  type: typeof FETCH_ALL_LEVELS_COMPLETED;
  payload: {
    levels: LevelDTO[];
  };
}

interface FetchAccountsByIdsCompletedAction {
  type: typeof FETCH_ACCOUNTS_BY_IDS_COMPLETED;
  payload: {
    schoolConfig?: SchoolConfig;
    isDistrictUser: boolean;
    districtAccount?: AccountDTO;
    selectionAccounts: AccountDTO[];
    associatedAccounts: Array<Object>;
    editableAccounts: Array<Object>;
    haveAccountActiveContext: boolean;
  };
}

interface OnRequestTicketDistributionForEvents {
  type: typeof ON_REQUEST_TICKET_DISTRIBUTION_FOR_EVENTS;
  payload: {
    events: Event[];
  };
}

interface OnRequestTicketDistributionForEventsCompleted {
  type: typeof ON_REQUEST_TICKET_DISTRIBUTION_FOR_EVENTS_COMPLETED;
  payload: {
    events: Event[];
    searchParams: SearchEventParams;
  };
}

interface FetchActivitiesSuccessfulAction {
  type: typeof FETCH_ACTIVITIES_SUCCESSFUL;
  payload: {
    athleticActivities: Activity[];
    nonAthleticActivities: Activity[];
  };
}

interface ClearDataCreatedLinks {
  type: typeof CLEAR_DATA_CREATED_LINKS;
}

export type EventLandingActionTypes =
  | ResetPublishStateAction
  | PrepareDataAction
  | PrepareDataCompletedAction
  | SearchEventsAction
  | SearchEventsCompletedAction
  | UpdateEventVisibilityAction
  | FetchActivitiesSuccessfulAction
  | UpdateEventVisibilityCompletedAction
  | DeleteEventAction
  | ClearEventTable
  | DeleteEventCompletedAction
  | FetchEventSalesInfoAction
  | FetchEventSalesInfoCompletedAction
  | FetchAccountsByIdsCompletedAction
  | OnRequestTicketDistributionForEvents
  | OnRequestTicketDistributionForEventsCompleted
  | FetchAllLevelsCompleted
  | ClearDataCreatedLinks;
