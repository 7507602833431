import { useQuery } from '@tanstack/react-query';
import { ActivityService } from './activity.service';
import { ACTIVITIES_QUERY_KEY } from './activity.endpoints';

import type { ActivityDTO } from './activity.model';

export interface UseActivitiesQueryOptions {
  cacheTime?: number;
  staleTime?: number;
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
  select?: (data: ActivityDTO[]) => ActivityDTO[];
}

export const useActivities = (queryOptions?: UseActivitiesQueryOptions) => {
  const query = useQuery<ActivityDTO[]>({
    queryKey: [ACTIVITIES_QUERY_KEY],
    queryFn: ActivityService.getAllActivities,
    cacheTime: 24 * 60 * 60 * 1000, // = 1 day
    staleTime: 24 * 60 * 60 * 1000,
    ...queryOptions
  });

  return { ...query };
};
