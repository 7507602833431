/* eslint-disable import/prefer-default-export */
import nfhsIconLogo from '@assets/images/nfhs/nfhs.png';
import arbiterLogo from '@assets/images/arbiter/arbiter-logo.png';
import arbiterIconLogo from '@assets/images/arbiter/as-logo.svg';
import dragonflyLogo from '@assets/images/dragonfly/dragonfly-logo-for-event.png';
import dragonflyIconLogo from '@assets/images/dragonfly/dragonfly-logo-image.png';
import rankoneLogo from '@assets/images/rankone/rankone-logo.png';
import rankoneIconLogo from '@assets/images/rankone/rankone-icon.png';
import vnnLogo from '@assets/images/vnn/vnn-logo.svg';
import vnnLogoIconLogo from '@assets/images/vnn/vnn-logo-icon.svg';
import activitySchedulerLogo from '@assets/images/activity-scheduler/activity-scheduler-logo-icon.png';
import activitySchedulerIconLogo from '@assets/images/activity-scheduler/activity-scheduler-logo-image.png';
import aiaLogo from '@assets/images/aia/aia.svg';
import hqLogo from '@assets/images/hq/hq-logo-black.svg';
import hqIconLogo from '@assets/images/hq/hq-icon.svg';

import {
  AIA_PARTNER_NAME,
  ARBITER_PARTNER_NAME,
  DRAGONFLY_PARTNER_NAME,
  VNN_PARTNER_NAME,
  HQ_PARTNER_NAME,
  ACTIVITY_SCHEDULER_PARTNER_NAME,
  RANKONE_PARTNER_NAME
} from '@modules/event-integrations_V2/constants/constants';

import isEqual from 'lodash/isEqual';

export const isArrayHasIdsEqual = (array1: any[], array2: any[]) => {
  const temp1 = array1.map(item => item.id).sort();
  const temp2 = array2.map(item => item.id).sort();
  return isEqual(temp1, temp2);
};

export const getPartnerImage = (key: string) => {
  const partnerImages = {
    [ARBITER_PARTNER_NAME]: arbiterLogo,
    [AIA_PARTNER_NAME]: aiaLogo,
    [DRAGONFLY_PARTNER_NAME]: dragonflyIconLogo,
    [HQ_PARTNER_NAME]: hqLogo,
    [VNN_PARTNER_NAME.toLowerCase()]: vnnLogoIconLogo,
    [ACTIVITY_SCHEDULER_PARTNER_NAME.toLowerCase()]: activitySchedulerIconLogo,
    [RANKONE_PARTNER_NAME]: rankoneIconLogo
  };

  type PartnerImage = keyof typeof partnerImages;

  function isValidKey(value: string): value is PartnerImage {
    return value in partnerImages;
  }

  return isValidKey(key) ? partnerImages[key] : nfhsIconLogo;
};

export const getPartnerIconImage = (key: string) => {
  const partnerImages = {
    [ARBITER_PARTNER_NAME]: arbiterIconLogo,
    [AIA_PARTNER_NAME]: aiaLogo,
    [DRAGONFLY_PARTNER_NAME]: dragonflyLogo,
    [VNN_PARTNER_NAME.toLowerCase()]: vnnLogo,
    [HQ_PARTNER_NAME]: hqIconLogo,
    [ACTIVITY_SCHEDULER_PARTNER_NAME.toLowerCase()]: activitySchedulerLogo,
    [RANKONE_PARTNER_NAME]: rankoneLogo
  };

  type PartnerImage = keyof typeof partnerImages;

  function isValidKey(value: string): value is PartnerImage {
    return value in partnerImages;
  }

  return isValidKey(key) ? partnerImages[key] : nfhsIconLogo;
};
