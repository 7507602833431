import { UserHistoryParams, UserHistoryResult } from './types';

export const RESET_STATE = 'userHistory/RESET_STATE';
export const FETCH_USER_HISTORY = 'userHistory/FETCH_USER_HISTORY';
export const FETCH_USER_HISTORY_COMPLETED =
  'userHistory/FETCH_USER_HISTORY_COMPLETED';

interface ResetStateAction {
  type: typeof RESET_STATE;
}

interface FetchUserHistoryAction {
  type: typeof FETCH_USER_HISTORY;
  payload: {
    searchParams: UserHistoryParams;
  };
}

interface FetchUserHistoryActionCompletedAction {
  type: typeof FETCH_USER_HISTORY_COMPLETED;
  payload: {
    histories: UserHistoryResult;
  };
}

export type UserHistoryActionTypes =
  | ResetStateAction
  | FetchUserHistoryAction
  | FetchUserHistoryActionCompletedAction;
