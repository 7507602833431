import type { ProgressSection } from '@events/components/editor/EditorProgress';
import type { StreamingEvent } from '@gofan/api';

export const SECTIONS = {
  EVENT_TYPE_SECTION: 'EVENT_TYPE_SECTION',
  EVENT_NAME_SECTION: 'EVENT_NAME_SECTION',
  BROADCAST_DATE_TIME_SECTION: 'BROADCAST_DATE_TIME_SECTION',
  BROADCAST_SETTINGS_SECTION: 'BROADCAST_SETTINGS_SECTION',
  CAMERA_SETUP_SECTION: 'CAMERA_SETUP_SECTION',
  SCHOOLS_SECTION: 'SCHOOLS_SECTION',
  DATE_TIME_SECTION: 'DATE_TIME_SECTION'
};

export const STREAMING_EVENT_PROGRESS: ProgressSection = {
  title: 'Streaming Settings',
  settingIndex: 0,
  settingLabel: '',
  progresses: [
    { id: SECTIONS.EVENT_TYPE_SECTION, label: 'Event type' },
    { id: SECTIONS.EVENT_NAME_SECTION, label: 'Event name' },
    { id: SECTIONS.BROADCAST_DATE_TIME_SECTION, label: 'Date and times' },
    { id: SECTIONS.BROADCAST_SETTINGS_SECTION, label: 'Broadcast settings' },
    { id: SECTIONS.CAMERA_SETUP_SECTION, label: 'Camera setup', complete: true },
    { id: SECTIONS.SCHOOLS_SECTION, label: 'Opponent' },
  ]
};

export const FIELDS_TO_SECTIONS: { [key in keyof StreamingEvent]: string } = {
  activity: SECTIONS.EVENT_TYPE_SECTION,
  genders: SECTIONS.EVENT_TYPE_SECTION,
  levels: SECTIONS.EVENT_TYPE_SECTION,
  opponentAccount: SECTIONS.SCHOOLS_SECTION,
  taggedAccounts: SECTIONS.SCHOOLS_SECTION,
  eventName: SECTIONS.EVENT_NAME_SECTION,
  startDate: SECTIONS.DATE_TIME_SECTION,
  startTime: SECTIONS.DATE_TIME_SECTION,
  endDate: SECTIONS.DATE_TIME_SECTION,
  endTime: SECTIONS.DATE_TIME_SECTION,
  startTimeType: SECTIONS.DATE_TIME_SECTION,
  account: SECTIONS.SCHOOLS_SECTION,
  financialAccount: SECTIONS.SCHOOLS_SECTION,
  eventIntegrationDetails: SECTIONS.BROADCAST_SETTINGS_SECTION,

  accountId: SECTIONS.SCHOOLS_SECTION,
  eventName: SECTIONS.EVENT_NAME_SECTION,

  // temporary properties held for date and time validation
  startDate: SECTIONS.BROADCAST_DATE_TIME_SECTION,
  startTime: SECTIONS.BROADCAST_DATE_TIME_SECTION,
  endDate: SECTIONS.BROADCAST_DATE_TIME_SECTION,
  endTime: SECTIONS.BROADCAST_DATE_TIME_SECTION
};
