import { reverse } from 'lodash';
import moment from 'moment/moment';
import { colorPalette2 } from '@events/constants';
import { randomColor } from '@events/components/ticket-sale-chart/helper';
import { COMMON_SPORTS_LOWERCASE } from '@gofan/api/activities';

export interface SchoolYear {
  yearStart: TicketSalesDate;
  yearEnd: TicketSalesDate;
  isCurrentYearRange: boolean;
}

export interface TicketSalesDate {
  id: string;
  label: string;
  type: 'month' | 'week' | 'year';
}

export interface TicketSalesActivity {
  id: number;
  name: string;
}

const isPastJun: boolean = moment() > moment().month('JUN').endOf('month');

export const bubbleUpCommonSports = (activities: TicketSalesActivity[]) => {
  const commonSports: any[] = [];
  COMMON_SPORTS_LOWERCASE.forEach(sport => {
    const foundSport = activities.find(activity => activity.name.toLocaleLowerCase() === sport);
    if (foundSport) commonSports.push(foundSport);
  });
  const excludeCommonSports = activities.filter(
    activity => !COMMON_SPORTS_LOWERCASE.includes(activity.name.toLocaleLowerCase())
  );
  return [...commonSports, ...excludeCommonSports];
};

export const getCurrentYearRange = (): SchoolYear => {
  const startDate = isPastJun
    ? moment().month('JUL').startOf('month')
    : moment().month('JUL').startOf('month').subtract(1, 'year');
  const endDate = isPastJun ? moment() : moment().month('JUN').endOf('month');
  const yearStart: TicketSalesDate = {
    id: startDate.format('MM/DD/YYYY'),
    label: startDate.format('YYYY'),
    type: 'year'
  };
  const yearEnd: TicketSalesDate = {
    id: endDate.format('MM/DD/YYYY'),
    label: isPastJun ? startDate.add(1, 'year').format('YYYY') : endDate.format('YYYY'),
    type: 'year'
  };

  return {
    yearStart,
    yearEnd,
    isCurrentYearRange: true
  };
};

export const getPreviousYearRange = (): SchoolYear => {
  const startDate = isPastJun
    ? moment().month('JUL').startOf('month').subtract(1, 'year')
    : moment().month('JUL').startOf('month').subtract(2, 'year');
  const endDate = isPastJun
    ? moment().month('JUN').endOf('month')
    : moment().month('JUN').endOf('month').subtract(1, 'year');
  const yearStart: TicketSalesDate = {
    id: startDate.format('MM/DD/YYYY'),
    label: startDate.format('YYYY'),
    type: 'year'
  };
  const yearEnd: TicketSalesDate = {
    id: endDate.format('MM/DD/YYYY'),
    label: endDate.format('YYYY'),
    type: 'year'
  };

  return {
    yearStart,
    yearEnd,
    isCurrentYearRange: false
  };
};

export const years: SchoolYear[] = [getCurrentYearRange(), getPreviousYearRange()];

export const getMonths = (yearRange: SchoolYear) => {
  const start = moment(yearRange.yearStart.id);
  const end = yearRange.isCurrentYearRange ? moment() : moment(yearRange.yearEnd.id);
  const months: TicketSalesDate[] = [];

  while (start.isSameOrBefore(end)) {
    months.push({
      id: end.startOf('month').format('MM/DD/YYYY'),
      label: end.format('MMMM'),
      type: 'month'
    } as TicketSalesDate);
    end.subtract(1, 'month');
  }
  return months;
};

export const getWeeks = (yearRange: SchoolYear) => {
  const start = moment(yearRange.yearStart.id).startOf('isoWeek');
  const end = yearRange.isCurrentYearRange
    ? moment().startOf('isoWeek')
    : moment(yearRange.yearEnd.id).startOf('isoWeek');
  const weeks: TicketSalesDate[] = [];

  while (start.isSameOrBefore(end)) {
    weeks.push({
      id: end.format('MM/DD/YYYY'),
      label: end.format('ddd-MMM-DD-YYYY'),
      type: 'week'
    } as TicketSalesDate);
    end.subtract(1, 'week');
  }
  return weeks;
};

export const getPeriodDate = date => {
  const periodStart = moment(date.id, 'MM/DD/YYYY').format('YYYYMMDD');
  let periodEnd;
  if (date.isWeek) {
    periodEnd = moment(date.id, 'MM/DD/YYYY').add(6, 'day').format('YYYYMMDD');
  } else {
    periodEnd = moment(date.id, 'MM/DD/YYYY').endOf('month').format('YYYYMMDD');
  }
  return {
    periodStart,
    periodEnd
  };
};

export const mapToChartData = () => (res: any) => {
  const { byActivityName, totals } = res.data;
  const data = Object.entries(byActivityName)
    .map(([key, datas]) => ({
      group: key,
      value: datas.ticket_net_count
    }))
    .sort((a, b) => b.value - a.value);
  let i = 0;
  const scale = data.reduce((acc, current) => {
    if (current.value !== 0) {
      i += 1;
    }
    return {
      ...acc,
      [current.group]: `${current.value}` !== '0' ? colorPalette2[i - 1] ?? `${randomColor()}` : `${randomColor()}`
    };
  }, {});

  return {
    data: reverse(data),
    options: {
      title: ' ',
      axes: {
        left: {
          title: 'Activity',
          mapsTo: 'group',
          scaleType: 'labels'
        },
        bottom: {
          title: 'Tickets sold',
          scaleType: 'linear',
          mapsTo: 'value',
          ticks: {
            formatter: (value: any) => (Number.isInteger(value) ? value : '')
          }
        }
      },
      legend: {
        enabled: false,
        clickable: false
      },
      toolbar: {
        enabled: false
      },
      color: {
        scale
      },
      height: '500px',
      width: '90%'
    },
    ticketSales: totals?.net_to_account || 0,
    ticketSold: totals?.ticket_net_count || 0
  };
};
