import { isEmpty } from 'lodash';
import axiosExt from 'axios';
import { config } from '@gofan/constants';
import ErrorDTO from '../dto/ErrorDTO';
import PaginationDTO from '../dto/PaginationDTO';
import PurchasedTicketDTO from '../dto/PurchasedTicketDTO';
import SeasonPassPurchasedTicketDTO from '../dto/SeasonPassPurchasedTicketDTO';
import {
  REFUND_BY_EVENT,
  SEARCH_PURCHASED_TICKETS_ENDPOINT,
  REFUND_PURCHASED_TICKETS_ENDPOINT,
  SEARCH_PURCHASED_SEASON_PASS_TICKETS,
  REFUND_REQUEST_ORDER_SERVICE,
  REFUND_REQUEST_DELETE_ORDER_SERVICE
} from '../api/api';
import axios from '../api/axios';
import RefundedTransactionDTO from '../dto/RefundedTransactionDTO';

export const searchPurchasedTickets = (searchRequest, pageRequest) => {
  const uri = `${SEARCH_PURCHASED_TICKETS_ENDPOINT}?${pageRequest.toQueryString()}`;
  return axios.huddleAxiosInstanceProxy
    .post(
      uri,
      searchRequest.toJSON(),
      config.HUDDLE_MICROSERVICE_API_MODE ? axios.huddleAxiosInstanceProxy.msConfig : undefined
    )
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      const { content, pageable, totalPages } = response;
      const { pageSize, pageNumber } = isEmpty(pageable) ? {} : pageable;

      return new PaginationDTO({
        pageSize,
        pageNumber,
        totalPages,
        data: content.map(ticket => new PurchasedTicketDTO(ticket))
      });
    })
    .catch(e => new ErrorDTO(e));
};

export const refundPurchasedTickets = refundRequest => {
  const uri = REFUND_PURCHASED_TICKETS_ENDPOINT;
  return axiosExt
    .post(uri, refundRequest.toJSON())
    .then(response => {
      const error = ErrorDTO.convertToError(response.data);
      if (error !== null) {
        return error;
      }
      if (typeof response.data.body === 'string') {
        return new ErrorDTO({
          status: 200,
          data: response.data.body
        });
      }
      const { metadata = {} } = response.data.refund;
      const { orderAccessToken } = metadata;
      const purchasedTicketsAccessTokens = Object.keys(metadata).reduce((acc, cur) => {
        if (cur !== 'orderAccessToken') {
          acc.push(...metadata[cur].split(','));
        }
        return acc;
      }, []);
      const updateMetadata = {
        orderAccessToken,
        purchasedTicketsAccessTokens: isEmpty(purchasedTicketsAccessTokens) ? '' : purchasedTicketsAccessTokens.join()
      };
      return new RefundedTransactionDTO({
        ...response.refund,
        metadata: updateMetadata
      });
    })
    .catch(e => new ErrorDTO(e));
};

export const refundByEvent = (event = {}) => {
  const uri = REFUND_BY_EVENT.replace('{eventId}', event.id);
  return axiosExt
    .post(uri, null)
    .then(response => {
      const error = ErrorDTO.convertToError(response.data);
      if (error !== null) {
        return error;
      }
      return response;
    })
    .catch(e => new ErrorDTO(e));
};

export const searchSeasonPassPurchasedTickets = (searchRequest, pageRequest) => {
  const uri = `${SEARCH_PURCHASED_SEASON_PASS_TICKETS}?${pageRequest.toQueryString()}`;
  return axios.huddleAxiosInstanceProxy
    .post(uri, searchRequest.toJSON())
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      const { content, pageable, totalPages } = response;
      const { pageSize, pageNumber } = isEmpty(pageable) ? {} : pageable;
      return new PaginationDTO({
        pageSize,
        pageNumber,
        totalPages,
        data: content.map(order => new SeasonPassPurchasedTicketDTO(order))
      });
    })
    .catch(e => new ErrorDTO(e));
};

// refund requests are called on the order service before API Gateway refund requests (refundPurchasedTickets and refundByEvent)
// the BE needs to collect some data before hand and refundPurchasedTickets resolves the request synchronously
// https://huddleinc.atlassian.net/browse/GBO-1528
export const refundRequestOrderService = body => {
  const uri = REFUND_REQUEST_ORDER_SERVICE;
  return axiosExt
    .post(uri, body)
    .then(response => response.data)
    .catch(e => new ErrorDTO(e));
};

// if the first call to refundRequestOrderService is successful and the next call to refundPurchasedTickets fails
// we use this to delete our refundRequestOrderService POST and clean up our data
// https://huddleinc.atlassian.net/browse/GBO-1528
export const refundRequestDeleteOrderService = refundRequestId => {
  const uri = `${REFUND_REQUEST_DELETE_ORDER_SERVICE.replace('{refundRequestId}', refundRequestId)}`;
  return axiosExt
    .delete(uri)
    .then(response => response.data)
    .catch(e => new ErrorDTO(e));
};

export default {
  refundByEvent,
  searchPurchasedTickets,
  refundPurchasedTickets,
  searchSeasonPassPurchasedTickets,
  refundRequestOrderService,
  refundRequestDeleteOrderService
};
