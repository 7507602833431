export const SIDE_BAR_MENU = {
  NO_PERMISSION: 'You do not have the permission to view venues.'
};

export const FAN_SUPPORT = {
  NO_PURCHASED_TICKETS_FOUND: 'No purchased tickets found.',
  APPLY_BUTTON: 'Apply',
  UNEXPECTED_ERROR: 'Unexpected Error',
  REFUND_BUTTON: 'Issue Refund',
  CANCEL: 'Cancel',
  FILTER_LABEL: 'Filter',
  NO_EVENTS_RESULTS_FOUND: 'No events found.',
  SEARCH_EVENT_BAR_INPUT_PLACEHOLDER: 'Search by Event ID, Account ID',
  EVENT_REFUND_IN_SCHEDULED: '{eventName} has been scheduled to be refunded.',
  EVENT_REFUND_IN_PROGRESS:
    'A refund is already in process for this event. Please check back later for the status of the refund.',
  NO_PERMISSION_TO_VIEW: 'You do not have the permission to view Refunds.'
};

export const CHECK_EMAIL_STRING = {
  DESCRIPTION: [
    `We've sent you an email to {email}. Follow the link to create / reset your password.`,
    "If you don't see your email, please check your spam or junk folders. If you sent the request multiple times, only the most recent email will be valid."
  ]
};

export const EVENTS_STRING = {
  EDIT: 'Edit',
  FEATURED: 'FEATURED',
  ADD_EVENT: 'Add Event',
  UPCOMING_EVENTS: 'Upcoming Events',
  NO_EVENTS_FOUND: 'There are no upcoming school events at this time.',
  WARNING_HIDDEN: "This event will be hidden from your school's GoFan page.",
  DESCRIPTION:
    "To add an event, you will need to know the sport, opponent, and date of the event, as well as ticket names and prices. To edit an event, simply select 'Edit' next to an existing event. Only certain fields will be able to be edited.",
  NO_ASSOCIATED_ACCOUNTS:
    "This is where you will add or edit events for your school's GoFan page. Contact your school's GoFan administrator to be added to your account.",
  FILTER_SELECT_ALL: 'Select All',
  FILTER_SELECT_NONE: 'Select None',
  EVENT_TITLE: 'EVENT',
  FILTER_APPLY: 'APPLY',
  FILTER_TITLE: 'FILTERS'
};

export const EVENT_INFORMATION = {
  EVENT_HEADER_KEY: 'event',
  TICKET_HEADER_KEY: 'ticket',
  EVENT_TAB: 'Event Information',
  TICKET_TAB: 'Tickets',
  CREATE_EVENT: 'Create Event',
  CREATE_TICKET: 'Create Tickets',
  SAVE_BUTTON_TITLE: 'Save',
  SAVE_CHANGES_BUTTON_TITLE: 'Save Changes and Exit',
  CANCLE: 'Cancel',
  HIDDEN_SCHOOL_PAGE_DESCRIPTION: `An event that is hidden from your school's GoFan page can still be accessed with a direct link.`,
  CUSTOM_EVENT_NAME_DESCRIPTION: `If no custom event name is entered, the event will appear as 'Home Team vs Away Team' on GoFan. Ex: North Eagles vs Central Wildcats.`,
  ALL_DAY_EVENT_DESCRIPTION:
    'An all-day event allows tickets to be purchased and redeemed until 11:59 PM on the date selected.',
  EVENT_ALERT_DESCRIPTION: `An event alert will appear as banner text across the top of the event page on GoFan.`,
  ADD_TICKETS: 'Add Tickets',
  GENDERS_TITLE: 'Genders',
  PLACE_HOLDER_EVENT_THEME: 'Ex: Homecoming, Senior Night',
  PLACE_HOLDER_CUSTOM_EVENT_NAME: 'Ex: 2020 Homecoming Football Game',
  ALL_FIELDS_REQUIRED: 'All fields are required, unless otherwise noted.',
  ADD_TICKET: 'Add Ticket',
  REMOVE_TICKET: 'Remove',
  FEE_TOOLTIP_DESCRIPTION: 'This fee will be added to the Ticket Price and is paid by the fan.',
  ENABLE_TICKET: 'Enable',
  DISABLE_TICKET: 'Disable',
  ENANBLE_TOOLTIP_DESCRIPTION: 'Enabling will list this ticket for sale on GoFan/BoxOffice.',
  DISABLE_TOOLTIP_DESCRIPTION:
    'This will hide this ticket from the event page. No users will be able to purchase a disabled ticket on GoFan/BoxOffice.'
};

export const EVENT_CONFIRMATION_MODAL = {
  MODAL_TITLE: 'You Are About To Save And Publish The Following Event To GoFan',
  MODAL_SUB_TITLE: '(If any of the below information is incorrect, select Cancel and continue editing)',
  HIDDEN_INFO: `This event will be hidden from your school's GoFan page.`,
  TICKET_AND_PRICES_TITLE: 'Ticket and Prices'
};

export const ACCOUNTS_STRING = {
  SORT_LABEL: 'Sort By',
  INPUT_PLACE_HOLDER: 'Search by Huddle ID, account'
};

export const ACCOUNT_TICKETS_STRING = {
  ADD_TICKET: 'Add Ticket',
  INPUT_SEARCH_TICKET_PLACE_HOLDER: 'Search by Ticket Name',
  NO_PERMISSION_TO_VIEW: 'You do not have the permission to view the list of tickets.'
};

export const ACTIVITIES_STRING = {
  ADD_ACTIVITY_BUTTON: 'Add',
  SAVE_ACTIVITY_BUTTON: 'Save',
  DELETE_ACTIVITY_BUTTON: 'Delete',
  CANCEL_ACTIVITY_BUTTON: 'Cancel',
  ADD_ACTIVITY_TITLE: 'Add Activity',
  EDIT_ACTIVITY_TITLE: 'Edit Activity',
  DELETE_ACTIVITY_TITLE: 'Delete Activity',
  ATHLETIC: 'Athletic',
  NON_ATHLETIC: 'Non Athletic',
  GENDER_SPECIFIC: 'Gender Specific',
  NO_PERMISSION_VIEW_ACTIVITIES: 'You do not have the permission to view activities.',
  MESSAGES: {
    ERROR_ACTIVITY_NAME_REQUIRED: 'Activity name is required.',
    ERROR_ACTIVITY_NAME_VALIDATION:
      'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) with a maximum of 32 characters long.',
    ERROR_ACTIVITY_NAME_EXISTS: 'Name already exists.',
    SUCCESSFUL_ACTIVITY_ADDED: ' is added.',
    ERROR_GENDER_REQUIRED: 'Gender is required.'
  },
  ACTIVITY_NAME: 'Activity Name',
  DELETE_ACTIVITY_CONTENT_MESSAGE: 'Are you sure you want to delete this activity?'
};

export const LEVEL = {
  NO_PERMISSION_CONTENT: 'You do not have the permission to view the list of levels.',
  EDIT_TITLE: 'Edit Level',
  ADD_TITLE: 'Add Level',
  DELETE_TITLE: 'Delete Level',
  NAME_LABEL: 'Level Name',
  SAVE_BUTTON: 'Save',
  DELETE_BUTTON: 'Delete',
  CANCEL_BUTTON: 'Cancel',
  DELETE_LEVEL_CONTENT_MESSAGE: 'Are you sure you want to delete this level?'
};

export const PRODUCTS = {
  NO_PERMISSION_CONTENT: 'You do not have the permission to view the list of products.',
  EDIT_TITLE: 'Edit Product Type',
  ADD_TITLE: 'Add Product Type',
  DELETE_TITLE: 'Delete Product Type',
  NAME_LABEL: 'Product Type Name',
  SAVE_BUTTON: 'Save',
  DELETE_BUTTON: 'Delete',
  CANCEL_BUTTON: 'Cancel',
  DELETE_PRODUCT_TYPE_CONTENT_MESSAGE: 'Are you sure you want to delete this product type?'
};

export const REPORTING_ACTIVITIES = {
  LABEL: 'Reporting Activities',
  OPTIONAL_LABEL: '(optional)',
  DESCRIPTION: 'A contact with the Reports permission will receive event reports for the selected activities.'
};

export const CONTACT = {
  ALL_FIELDS_REQUIRED: 'All fields are required, unless otherwise noted.'
};
export const RATE_INFORMATION_STRINGS = {
  SAVE_BUTTON_TITLE: 'Save',
  SAVE_CHANGES_BUTTON_TITLE: 'Save Changes and Exit'
};

export const EDIT_LEVEL_CONFIRMATION_MODAL = {
  MODAL_TITLE: 'Edit Level',
  MODAL_SUB_TITLE: 'Level Name'
};

export const RATE = {
  NO_PERMISSION_CONTENT: 'You do not have the permission to view the list of rates.',
  ADD_RATE: 'Add Rate',
  SEARCH_PLACE_HOLDER: 'Search by Rate name'
};

export const ACCOUNT_TICKET = {
  MODAL_CONTENT: 'Are you sure you want to continue without saving ?',
  MODAL_TITLE: 'Confirmation',
  CONTINUE: 'Continue',
  CANCEL: 'Cancel'
};

export const EVENT_DETAIL = {
  NOT_FOUND: 'Not found the event with id: {eventId}'
};

export const INLINE_MESSAGE = {
  ACCOUNT_UPDATED: 'Account updated',
  CONTACT_UPDATED: 'Contact updated',
  CONTACT_ADDED: 'Contact added',
  TICKET_UPDATED: 'Ticket updated',
  TICKET_ADDED: 'Ticket added',
  EVENT_UPDATED: 'Event updated',
  EVENT_ADDED: 'Event added',
  GOFAN_PAGE_UPDATED: 'GoFan page updated',
  FUNDRAISER_ADDED: 'Fundraiser added',
  FUNDRAISER_UPDATED: 'Fundraiser updated'
};

export const ATTENDEE_FEE_MESSAGE = {
  TITLE: 'Fee information',
  SUBTITLE: {
    FEE_INFO: 'This fee may not apply to post-season, mobile passes, or reserved seats.',
    FEE_CONFIRM:
      'You are now covering the cost of the fee. If you want the attendee to pay the fee, set the toggle to Yes.'
  },
  ACTION_BUTTON_TITLE: 'Learn more',
  CLOSE_TOOLTIP_DESCRIPTION: 'Close notification'
};

export const EVENT_PAYMENT_CYCLE_SECTION = {
  CALENDAR_BASED_MSG:
    'Calendar-based is a payment option that causes payments to be issued for all transactions that occurred in the previous week on your account (Monday through Sunday). You’ll get paid out the following Thursday and receive your Weekly Payment Statement via email by Friday.',
  EVENT_BASED_MSG:
    'Event-based is a payment option that causes payments to be issued for all transactions pertaining to events with an end date in the previous week (Monday through Sunday). You’ll get paid out the following Thursday and receive your Weekly Payment Statement via email by Friday.',
  DAILY_BASED_MSG:
    'For schools in Washington state, GoFan is required to remit all funds within one business day of receipt. The payment cycle is mapped to daily settlement, and the payment source is mapped to ACH. For questions, please contact us at ',
  EVENT_BASED_NOTIFICATION: {
    TITLE: 'Warning',
    SUB_TITLE: `Payment for this event may be delayed until the end of the event if "event-based payment" is selected. Payment is submitted a week after the event end date is reached.`
  }
};

export const TICKET_COLOR_MESSAGE = {
  TITLE: 'Assign a unique ticket color',
  PASS_TITLE: 'Assign a unique pass color',
  TICKET_NAME: 'Ticket name',
  PASS_NAME: 'Pass name',
  CHOOSE_COLOR_LABEL: 'Assign ticket color',
  CHOOSE_COLOR_DESCRIPTION: 'Ticket color displays on the attendee’s mobile device',
  TICKET_LOOK_LABEL: 'How the ticket will look',
  PASS_LOOK_LABEL: 'How the pass will look',
  TICKET_LOOK_LABEL_DESCRIPTION: 'Once published, your ticket will display your event details',
  PASS_LOOK_LABEL_DESCRIPTION: 'Once published, your pass will display your event details',
  SAVE: 'Save',
  CLOSE: 'Close',
  ERROR:
    'This field is alphanumeric, you can enter letters (a-z) and numbers (0-9) and (`()./_-#\'@&*+",:;?!) in between 2-40 characters long.'
};

export const GENERAL_ERRORS_MSG = {
  INVALID_REQUIRED_SETTINGS: 'Invalid required settings. Please fill or correct all required fields'
};

export default {
  EVENT_DETAIL,
  FAN_SUPPORT,
  CHECK_EMAIL_STRING,
  EVENTS_STRING,
  EVENT_INFORMATION,
  ACCOUNTS_STRING,
  ACTIVITIES_STRING,
  LEVEL,
  CONTACT,
  REPORTING_ACTIVITIES,
  RATE,
  INLINE_MESSAGE,
  ATTENDEE_FEE_MESSAGE,
  TICKET_COLOR_MESSAGE
};
