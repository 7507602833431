import type { VFC } from 'react';
import React from 'react';
import schoolIcon from '@assets/images/gofan-icon.png';
import { getSchoolImage } from '@app/pages/EventInformationV2/helpers';
import groupBy from 'lodash/fp/groupBy';
import { isEmptyString } from '@utils/stringUtils';
import { uniqueId } from 'lodash';
import { isEmpty } from '@utils/objectUtils';
import type { TicketSaleByTypeChartData } from '../ticket-sale-by-type-chart/ticket-sale-by-type-chart';
import type { TicketSaleData } from '../../models/event.model';

const ChartLabel: VFC<{ text: string; src: string }> = ({ text, src }) => (
  <div className='ticket-sale-by-school-label'>
    <div className='school-logo'>
      <img src={src} alt='' className='school-logo-img' />
    </div>
    {text}
  </div>
);

export const formatNumber = num => new Intl.NumberFormat('en-US').format(num);

export const randomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

export const toTicketSaleDataByType = (acc: TicketSaleByTypeChartData, cur: TicketSaleData) => {
  const peak = (Number(cur?.peak) / cur?.ticketSoldTotal) * 100;
  const ticketSold = cur?.ticketSold || 0;
  const ticketSale = cur?.ticketSale || 0;
  const description = cur.hasSchoolTicket
    ? `${formatNumber(ticketSold)} of ${formatNumber(cur.sales?.ticket_tkt_limit)} sold`
    : `${formatNumber(ticketSold)} sold`;
  const value = cur.ticketSoldTotal > 0 ? (Number(ticketSold) / cur.ticketSoldTotal) * 100 : 100;
  return {
    ...acc,
    [uniqueId(cur.type)]: {
      label: cur.type,
      value: value > 100 ? 100 : value,
      peak: peak > 100 ? 100 : peak,
      description,
      suffix: `$${formatNumber(cur.ticketSale ?? 0)}`,
      fill: !isEmptyString(cur?.school?.primaryColor) ? `#${cur?.school?.primaryColor}` : '#000000',
      ticketSold,
      ticketSale,
      customColor: cur.customColor
    }
  };
};

const generateSchoolPeak = school => {
  if (school?.sales?.ticket_school_limit) {
    return school?.sales?.ticket_school_limit;
  }
  if (school?.sales?.event_tkt_limit) {
    return school?.sales?.event_tkt_limit;
  }
  return 5000;
};

export const composeTicketSaleDataBySchool = (schoolTickets: TicketSaleData[]) => {
  const groupBySchool = Object.entries(groupBy(ticketData => ticketData.accountId, schoolTickets));
  return groupBySchool.reduce((acc: any, [key, datas], currentIndex: number) => {
    const sum = (v1: number, v2: number) => v1 + v2;

    const { school }: any = datas[0];
    const schoolSales = datas.find(item => !isEmpty(item.sales)) || {};
    const shoolTicketTotal = datas[0].ticketSoldTotal;
    const value = Number((datas.map(item => item.ticketSold).reduce(sum) / shoolTicketTotal).toFixed(2)) * 100;
    const ticketSold = datas.map(item => item.ticketSold).reduce(sum);
    const ticketSale = formatNumber(datas.map(item => item.ticketSale).reduce(sum) ?? 0);
    const schoolPeak = generateSchoolPeak(schoolSales);
    const peak = (schoolPeak / shoolTicketTotal) * 100;
    const ticketLimit = schoolSales?.sales?.ticket_school_limit || 0;
    const hasSchoolLimit = ticketLimit > 0;
    const fill = !isEmptyString(school?.primaryColor) ? `#${school?.primaryColor}` : '#000000';
    return {
      ...acc,
      [uniqueId(key)]: {
        label: (
          <ChartLabel
            src={school?.logo ? getSchoolImage(school.id, 'logo', school.logo) : schoolIcon}
            text={school?.name ?? ''}
          />
        ),
        value: value > 100 ? 100 : value,
        peak: peak > 100 ? 100 : peak,
        description: hasSchoolLimit
          ? `${formatNumber(ticketSold)} sold of ${formatNumber(ticketLimit)}`
          : `${formatNumber(ticketSold)} sold `,
        suffix: `$${ticketSale}`,
        fill,
        sub: datas.reduce(toTicketSaleDataByType, {})
      }
    };
  }, {});
};
// Subject to change
// TODO: update with real insight API response structure
export const toTicketAvailable = (acc: number, cur: TicketSaleData) => acc + cur.ticketSoldTotal;
