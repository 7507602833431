import { css } from 'styled-components';
import common from '../../../../assets/styles/common';
import styles from '../styles';

export default {
  titleWrapper: css`
    display: flex;
  `,
  labelOptional: css`
    ${styles.fieldLabel};
    margin-top: 3px;
  `,
  styledCheckBox: css`
    ${common.fieldLabel};
    & label {
      font-size: 14px !important;
    }
  `,
  description: css`
    ${styles.fieldLabel};
    padding-left: ${props => (props.alignTitleDescription ? '35px' : 0)};
  `
};
