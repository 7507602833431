import { huddleAxiosInstanceProxy } from '../shared/axios';
import { VENUE_URI, VENUE_CHART_SYNC_URI, VENUE_ASSOCIATION_URI } from './venue.endpoints';

import type { VenueDTO, VenueAssociation } from './venue.model';
import type { AccountVenue } from '../accounts/account.model';
import type { AxiosResponse } from 'axios';

class VenueRepository {
  static getVenueById = (venueId: number | string): Promise<VenueDTO> =>
    huddleAxiosInstanceProxy.get(VENUE_URI.replace('{id}', `${venueId}`));

  static createVenue = (venue: AccountVenue): Promise<AccountVenue> =>
    huddleAxiosInstanceProxy.post<AccountVenue>(VENUE_URI.replace('{id}', ''), venue, undefined, true);

  static updateVenue = (venue: AccountVenue): Promise<AccountVenue> =>
    huddleAxiosInstanceProxy.put<AccountVenue>(VENUE_URI.replace('{id}', venue.id ?? ''), venue, undefined, true);

  static removeVenueById = (venueId: number | string): Promise<AxiosResponse> =>
    huddleAxiosInstanceProxy.delete(VENUE_URI.replace('{id}', `${venueId}`), undefined, true);

  static syncVenueChart = (venueId: number | string): Promise<VenueDTO> =>
    huddleAxiosInstanceProxy.post(VENUE_CHART_SYNC_URI.replace('{id}', `${venueId}`), undefined);

  static getVenueAssociation = (venueId: number | string): Promise<VenueAssociation> =>
    huddleAxiosInstanceProxy.get(VENUE_ASSOCIATION_URI.replace('{id}', `${venueId}`), undefined);
}

export { VenueRepository };
