// @flow
import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import {
  PageForbidden,
  PageInternalServerError,
  PageNotFound,
  PageServiceUnavailable,
  PageUnderMaintenance,
  PageUnauthorized,
  PageBadGateway
} from '../ErrorContent';
import { PAGES } from '../../../../config/routes';
import { parseUrl } from '../../../../commons/utils/parseQueryString';
import { Wrapper } from '../../../Root/components/Auth/components/Wrapper';

export default ({ checkServerOnMaintenance }) => {
  const history = useHistory();
  const location = useLocation();
  const { query } = parseUrl(location.search || '');
  let component = null;
  switch (location.pathname) {
    case PAGES.error.error401.path: {
      component = <PageUnauthorized />;
      break;
    }
    case PAGES.error.error403.path: {
      component = <PageForbidden />;
      break;
    }
    case PAGES.error.error404.path: {
      component = <PageNotFound />;
      break;
    }
    case PAGES.error.error503.path: {
      component = <PageServiceUnavailable />;

      if (query.maintenance === 'true') {
        component = <PageUnderMaintenance onTryAgain={() => checkServerOnMaintenance(history)} />;
      }
      break;
    }
    case PAGES.error.error502.path: {
      component = <PageBadGateway />;
      break;
    }
    case PAGES.error.error500.path:
    default:
      component = <PageInternalServerError />;
  }

  return <Wrapper>{component}</Wrapper>;
};
