// @flow
import { call, put, takeLatest } from 'redux-saga/effects';
import errorMessages from '../../../../commons/errorMessages';

import { signIn, sendPasswordResetEmail } from '@gofan/api';
import {
  canLogin,
  checkPendingEmail,
  getUserByEmailToken,
  resendInviation
} from '../../../../api/services/UserService';

import * as types from './actionTypes.ts';
import * as actionCreator from './actions.ts';

function* handleSignIn(action) {
  const { email, password, signInCallBack } = action.payload;
  try {
    const response = yield call(signIn, email, password);
    if (response instanceof Object) {
      const { res, user } = response;
      let errorMessage = '';
      if (user.status === 'DISABLED' || user.status === 'DELETED') {
        errorMessage = errorMessages.DISABLED_DELETED;
      } else if (!canLogin(user)) {
        errorMessage = errorMessages.PERMISSION_MESSAGE;
      }

      !errorMessage ? signInCallBack(true, { res, user }) : signInCallBack(false, { res, errorMessage });
    } else {
      signInCallBack(false, { res: {}, errorMessage: response.message });
    }
    yield put(actionCreator.signInSuccess());
  } catch (error) {
    let errorMessage = '';
    if (error.code === 'auth/wrong-password') {
      errorMessage = errorMessages.NOT_RECOGNIZE_EMAIL_OR_PASSWORD_MESSAGE;
    } else if (error.code === 'auth/user-not-found') {
      errorMessage = errorMessages.ACCOUNT_NOT_FOUND_MESSAGE;
    }
    signInCallBack(false, { res: {}, errorMessage: errorMessage || error.message });
    yield put(actionCreator.signInSuccess());
  }
}

function* handleSubmit(action) {
  const { email, forGotCallback } = action.payload;
  try {
    const response = yield call(sendPasswordResetEmail, email);
    forGotCallback(true);
    yield put(actionCreator.resetPasswordSuccess());
  } catch (error) {
    forGotCallback(false, error.message);
    yield put(actionCreator.resetPasswordSuccess());
  }
}

function* checkEmailStatus(action) {
  const { email, configuration, manualErrorHandling } = action.payload;
  try {
    if (email) {
      const response = yield call(checkPendingEmail, email, configuration, manualErrorHandling);
      yield put(actionCreator.checkStatus(response));
    } else {
      throw new Error('Email not found');
    }
  } catch (e) {
    yield put(actionCreator.signInErrors(e));
  }
}

function* resendInviationEmail(action) {
  const { email } = action.payload;
  try {
    if (email) {
      const response = yield call(resendInviation, email);
      yield put(actionCreator.checkInvitationEmailStatus(response));
    } else {
      throw new Error('Email not found');
    }
  } catch (e) {
    yield put(actionCreator.signInErrors(e));
  }
}

function* getUserbyEmailTokenFunc(action) {
  const { emailToken } = action.payload;
  try {
    if (emailToken) {
      const response = yield call(getUserByEmailToken, emailToken);
      if (response.status === 400) {
        yield put(actionCreator.signInErrors(response));
        return;
      }

      yield put(actionCreator.getUserInform(response.data));
    } else {
      throw new Error('Email token not found');
    }
  } catch (e) {
    yield put(actionCreator.signInErrors(e));
  }
}

export function* watchSignInRequest() {
  yield takeLatest(types.SIGN_IN, handleSignIn);
  yield takeLatest(types.RESET_PASSWORD, handleSubmit);
  yield takeLatest(types.PENING_EMAIL, checkEmailStatus);
  yield takeLatest(types.CHECK_INVITATION_EMAIL, resendInviationEmail);
  yield takeLatest(types.GET_USER_BY_EMAIL_TOKEN, getUserbyEmailTokenFunc);
}

export default [watchSignInRequest()];
