import { css } from 'styled-components';

export default {
  tabsWrapper: css`
    > .ui.segment {
      border-radius: 0;
      border-bottom: 1px solid #ccc;
    }
  `
};
