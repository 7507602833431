import { SponsorshipService } from '@gofan/api';
import type { SponsorDTO } from '@gofan/api';
import Table from '../../../../commons/carbon-ui/atoms/Table';
import StyledButton from '../../../../commons/carbon-ui/atoms/StyledButton';
import React, { useCallback, useEffect, useState } from 'react';
import LoadingContent from '../../../../commons/LoadingContent';
import { Notification } from '@app/../../../packages/components/Notification';
import { Grid, OverflowMenu, OverflowMenuItem, Row } from 'carbon-components-react';
import getTableSchema from './tableSchema';
import { RootContext } from '@app/RootContext';
import { checkInternalUser } from '@api/services/UserService';
import './SponsorshipListing.scss';
import { TableToolbarSearch } from '@gofan/components';
import { useCalculatedSponsors } from '@gofan/api/sponsorship/useCalculatedSponsors';
import { addNotification } from '@gofan/utils';

const SponsorOverflowMenu = ({ sponsor, onClickEdit, onClickDelete }) => (
  <OverflowMenu>
    <OverflowMenuItem
      itemText='Edit'
      onClick={() => {
        onClickEdit(sponsor.sponsorId);
      }}
    />
    <OverflowMenuItem
      itemText='Delete'
      style={{ color: 'red' }}
      onClick={() => {
        onClickDelete(sponsor);
      }}
    />
  </OverflowMenu>
);

const SponsorshipListing = ({ match, history }) => {
  const { accountId } = match.params || '';

  const { currentUser } = React.useContext(RootContext);
  const isInternalUser = checkInternalUser(currentUser.role);
  const [error, setError] = useState(null);
  const { sponsors, totalSponsors, isLoading } = useCalculatedSponsors(accountId);

  const [selected, setSelected] = useState([]);
  const [filteredSponsors, setFilteredSponsors] = useState(() => sponsors);

  useEffect(() => {
    if (!isLoading && filteredSponsors.length === 0 && sponsors.length > 0) {
      setFilteredSponsors(sponsors);
    }
  }, [sponsors, filteredSponsors]);

  const onClickEdit = (id: string) => {
    history.push(`/sponsorships/${id}/edit`);
  };

  const onClickCreate = () => {
    history.push(`/sponsorships/create`);
  };

  const onClickDelete = async (sponsor: SponsorDTO) => {
    try {
      const response = await SponsorshipService.deleteSponsor(sponsor);
      if (response?.status === 200 || response?.status === 204) {
        setFilteredSponsors(filteredSponsors.filter(s => s.id !== sponsor.id));
        addNotification({
          type: 'success',
          message: `${sponsor.name} has been deleted successfully.`,
          options: { duration: 7000 }
        });
      } else {
        let messageCopy = response?.message;
        if (response?.message === 'sponsor is associated with deals') {
          messageCopy = `Unable to delete this sponsor. Please unassign the sponsor from any associated schools before trying again.`;
        }
        addNotification({
          type: 'error',
          message: messageCopy,
          options: { duration: 7000 }
        });
      }
    } catch (e) {
      setError(e);
    }
  };

  const onSelectRow = r => {
    if (selected.includes(r)) {
      setSelected(selected.filter(s => s !== r));
      return;
    }
    setSelected([...selected, r]);
  };

  const onSelectAll = () => {
    if (selected.length === sponsors.length) {
      setSelected([]);
      return;
    }
    setSelected(sponsors.map(({ sponsorId }) => sponsorId));
  };

  const handleTextChange = useCallback(
    event => {
      const searchText = event.target.value.toLowerCase();
      if (searchText.length > 2) {
        const filtered = sponsors.filter(s => s.name.toLowerCase().includes(searchText));
        setFilteredSponsors(filtered);
      } else {
        setFilteredSponsors(sponsors);
      }
    },
    [sponsors]
  );

  const renderToolbar = useCallback(
    () => (
      <TableToolbarSearch className='toolbar-search' placeholder='Search by sponsor name' onChange={handleTextChange} />
    ),
    [handleTextChange]
  );

  return (
    <Grid className='gf-sponsorship-listing'>
      {error && (
        <Notification kind='warning' title='Warning' cbFunction={() => setError(undefined)} subtitle={error.message} />
      )}
      <Row>
        <h1>Sponsors</h1>
      </Row>
      <Row>
        <div
          className='dashboard-container dashboard-chart'
          style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
        >
          <div>
            <h3>{sponsors.filter(f => f.status === 'active').length} active sponsors</h3>
          </div>
          <StyledButton className='custom-btn bx--btn__centralized' onClick={onClickCreate}>
            Create Sponsor
          </StyledButton>
        </div>
      </Row>
      {!isLoading ? (
        <Row>
          <div className='table-container'>
            <Table
              hasSorting
              hasToolbar
              toolbar={renderToolbar}
              hasPagination={filteredSponsors.length > 10}
              hasOverflowMenu
              haveCheckbox
              schema={getTableSchema(isInternalUser)}
              rowData={filteredSponsors}
              selectedRowIds={selected}
              onSelectRow={onSelectRow}
              onSelectAll={onSelectAll}
              allDisableSelectedRowIds={[]}
              selectableRowCount={totalSponsors}
              // eslint-disable-next-line react/no-unstable-nested-components
              overflowMenu={({ data }) => (
                <SponsorOverflowMenu sponsor={data} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
              )}
            />
          </div>
        </Row>
      ) : (
        <LoadingContent />
      )}
      <Row>
        <StyledButton
          kind='secondary'
          onClick={() => {
            history.goBack();
          }}
          className='custom-btn bx--btn__centralized'
        >
          Close
        </StyledButton>
      </Row>
    </Grid>
  );
};

export default SponsorshipListing;
