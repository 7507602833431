import { useFormContext } from 'react-hook-form';
import digitalTicketPhone from '@assets/images/digitalTicketPhone.png';

import './SponsorshipPreview.scss';
import { useMemo } from 'react';

const SponsorshipPreview = ({ previousSponsorshipUrl }: { previousSponsorshipUrl: string }) => {
  const { watch } = useFormContext();
  const sponsorshipAsset = watch('sponsorshipAsset');
  const tagline = watch('tagline');

  const imageUrl = useMemo(() => {
    if (sponsorshipAsset) {
      return URL.createObjectURL(sponsorshipAsset);
    }

    if (previousSponsorshipUrl) {
      return previousSponsorshipUrl;
    }
    return '';
  }, [sponsorshipAsset, previousSponsorshipUrl]);

  const sponsorshipAssetWithTagline = (
    <>
      <img src={imageUrl} alt='sponsorshipAsset' />
      <p>{tagline}</p>
    </>
  );

  return (
    <div className='gf-sponsorship-preview'>
      <p>Preview what your sponsors branding would look like in the ticket</p>
      {imageUrl ? (
        <div className='gf-sponsorship-preview-content'>
          <div className='gf-digital-ticketing-sponsorship'>
            <p className='gs--body-short-01 gs--font-family-sf gs--margin-top-sp5'>
              Digital Ticketing Sponsorship example
            </p>
            <div className='gf-digital-ticketing-sponsorship-asset-with-tagline'>{sponsorshipAssetWithTagline}</div>
            <img src={digitalTicketPhone} alt='Digital Ticket Phone Example' />
          </div>
          <div className='gf-sponsorship-asset'>
            <p className='gs--body-short-01 gs--font-family-sf gs--margin-top-sp5'>Sponsorship Image with Tagline</p>
            {sponsorshipAssetWithTagline}
          </div>
        </div>
      ) : (
        <div className='gf-sponsorship-empty-state'>
          Fill out the sponsorship information and your preview will appear here
        </div>
      )}
    </div>
  );
};

export { SponsorshipPreview };
