import { useQuery } from '@tanstack/react-query';

import { ProductTypeService } from './product-type.service';
import { PRODUCT_TYPES_QUERY_KEY } from './product-type.endpoints';

import type { ProductTypeDTO } from './product-type.model';

export interface UseProductTypesProps {
  queryOptions?: {
    cacheTime?: number;
    staleTime?: number;
    enabled?: boolean;
  };
}

export const useProductTypes = ({ queryOptions = {} }: UseProductTypesProps) => {
  const queries = useQuery<ProductTypeDTO[], Error>({
    queryKey: [PRODUCT_TYPES_QUERY_KEY],
    queryFn: () => ProductTypeService.getProductTypes(),
    cacheTime: 24 * 60 * 60 * 1000, // = 1 day
    staleTime: 24 * 60 * 60 * 1000,
    ...queryOptions
  });

  return { ...queries };
};
