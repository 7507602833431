import { Tag } from '@carbon/react';
import { Dropdown } from '..';

import { truncateString } from '@gofan/utils/strings';

import type { AccountVenue } from '@gofan/api/accounts';

import './VenueDropdown.scss';

export const DEFAULT_VENUE: AccountVenue = {
  id: 'DEFAULT_VENUE',
  accountId: '',
  label: '',
  name: '',
  city: '',
  state: '',
  zip: '',
  location: '',
  streetAddress: '',
  seatsIoChartKey: ''
};

export const NONE_CREATED_VENUE: AccountVenue = {
  ...DEFAULT_VENUE,
  id: 'NONE_CREATED_VENUE',
  label: 'None created'
};

export const NONE_SELECTED_VENUE: AccountVenue = {
  ...DEFAULT_VENUE,
  id: 'NONE_SELECTED_VENUE',
  label: 'None selected'
};

interface VenueDropdownProps {
  key?: string;
  value?: AccountVenue;
  options: AccountVenue[];
  onChangeVenue: ({ selectedItem }: { selectedItem: AccountVenue }) => void;
  defaultSelected?: AccountVenue;
  label?: string;
  titleText?: string;
  disabled?: boolean;
  invalid?: boolean;
  invalidText?: string;
}

const VenueDropdown = ({
  key,
  value,
  options,
  onChangeVenue,
  defaultSelected,
  label,
  titleText,
  disabled,
  invalid,
  invalidText
}: VenueDropdownProps) => {
  return (
    <Dropdown
      items={options}
      selectedItem={value ?? defaultSelected}
      onChange={onChangeVenue}
      itemToString={_venueToString}
      itemToElement={_venueToElement}
      label={label ?? ''}
      titleText={titleText ?? 'Our venues'}
      disabled={disabled}
      invalid={invalid}
      invalidText={invalidText}
      key={key}
      id='gf--venue-selected'
      className='gf--venue-dropdown'
    />
  );

  function _venueToString(venue: AccountVenue) {
    if (venue.id === NONE_CREATED_VENUE.id || venue.id === NONE_SELECTED_VENUE.id) {
      return venue.label || '';
    }
    return venue.name;
  }

  function _venueToElement(venue: AccountVenue) {
    return venue?.isGroupLabel ? (
      <div className='group-venue-label'>{venue.label}</div>
    ) : (
      <div className='gf--venue-dropdown-item gs--font-family-sf'>
        {truncateString(_venueToString(venue), 36)}{' '}
        {options.find((v: AccountVenue) => `${v.id}` === `${venue.id}`)?.seatsIoChartKey && (
          <Tag className='gf--venue-dropdown-item__tag' color='rgb(18, 22, 25)'>
            Reserved
          </Tag>
        )}
      </div>
    );
  }
};

export { VenueDropdown };
