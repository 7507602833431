import type { EventInformationV2ActionTypes } from './actionTypes';
import {
  RESET_STATE,
  SEARCH_OPPONENT_ACCOUNTS,
  SEARCH_OPPONENT_ACCOUNTS_SUCCESS,
  FETCH_ACTIVITIES,
  FETCH_ACTIVITIES_COMPLETED,
  FETCH_ACTIVITIES_SUCCESSFUL,
  FETCH_LEVELS_SUCCESSFUL,
  FETCH_LEVELS_COMPLETED,
  FETCH_LEVELS,
  ON_PUBLISH_EVENT,
  ON_PUBLISH_EVENT_SUCCESS,
  ON_PUBLISH_EVENT_ERROR,
  UPDATE_REQUIRED_FIELDS,
  UPDATE_OPTIONAL_FIELDS,
  UPDATE_TICKETS_FIELDS,
  FETCH_ACCOUNT_SUCCESSFUL,
  SEARCH_ACCOUNT_TICKETS,
  SEARCH_ACCOUNT_TICKETS_COMPLETED,
  FETCH_VENUE_BY_ID,
  FETCH_VENUE_BY_ID_SUCCESSFULL,
  FETCH_VENUE_BY_ID_COMPLETED,
  EXECUTE_INITIAL_EVENT_PROCESS,
  EXECUTE_INITIAL_EVENT_PROCESS_COMPLETED,
  FETCH_EVENT_BY_ID_SUCCESSFULL
} from './actionTypes';
import type { EventInformationState } from './types';

export const initialState: EventInformationState = {
  event: {},
  initiated: false,
  initialLoading: false,
  loading: false,
  isSearchEvent: false,
  error: null,
  isPublishing: false,
  opponentAccounts: [],
  opponentSearchLoading: false,
  account: null,
  histories: [],
  athleticActivities: [],
  noAthleticActivities: [],
  activityLoading: false,
  levels: [],
  accountTickets: [],
  requiredFields: {},
  optionalFields: {
    archived: false,
    publishDateTime: new Date(),
    salesStartDateTime: undefined,
    salesEndDateTime: undefined,
    alert: '',
    formFields: null,
    theme: '',
    description: '',
    customSportName: '',
    featuredAccountIds: [],
    ticketLimitPerOrder: '30'
  },
  ticketsFields: {
    maxCapacity: null,
    totalTicketCheck: false,
    products: []
  }
};

export default (state = initialState, action: EventInformationV2ActionTypes) => {
  switch (action.type) {
    case RESET_STATE: {
      return { ...initialState };
    }

    case FETCH_EVENT_BY_ID_SUCCESSFULL: {
      const { event } = action.payload;
      return {
        ...state,
        event
      };
    }

    case SEARCH_OPPONENT_ACCOUNTS: {
      return {
        ...state,
        opponentSearchLoading: true
      };
    }

    case SEARCH_OPPONENT_ACCOUNTS_SUCCESS: {
      const { accounts } = action.payload;
      return {
        ...state,
        opponentAccounts: accounts,
        opponentSearchLoading: false
      };
    }

    case FETCH_ACTIVITIES: {
      return {
        ...state,
        activityLoading: true
      };
    }

    case FETCH_ACTIVITIES_COMPLETED: {
      return {
        ...state,
        activityLoading: false
      };
    }

    case FETCH_ACTIVITIES_SUCCESSFUL: {
      return {
        ...state,
        activityLoading: false,
        athleticActivities: action.payload.athleticActivities,
        nonAthleticActivities: action.payload.nonAthleticActivities
      };
    }
    case FETCH_LEVELS_SUCCESSFUL: {
      return {
        ...state,
        levels: action.payload.levels
      };
    }

    case FETCH_LEVELS_COMPLETED: {
      return {
        ...state
      };
    }

    case FETCH_LEVELS: {
      return {
        ...state
      };
    }

    case UPDATE_REQUIRED_FIELDS: {
      const { requiredFields } = action.payload;
      return {
        ...state,
        requiredFields
      };
    }

    case UPDATE_OPTIONAL_FIELDS: {
      const { optionalFields } = action.payload;
      return {
        ...state,
        optionalFields
      };
    }

    case UPDATE_TICKETS_FIELDS: {
      return {
        ...state,
        ticketsFields: action.payload.ticketTypes
      };
    }
    case FETCH_ACCOUNT_SUCCESSFUL: {
      return {
        ...state,
        account: action.payload.account
      };
    }

    case SEARCH_ACCOUNT_TICKETS: {
      return {
        ...state,
        loading: true
      };
    }

    case SEARCH_ACCOUNT_TICKETS_COMPLETED: {
      const { searchResult } = action.payload;
      const { pageIndex, pageCount, data } = searchResult;
      return {
        ...state,
        pageIndex,
        pageCount,
        accountTickets: data,
        loading: false
      };
    }

    case FETCH_VENUE_BY_ID: {
      return {
        ...state
      };
    }

    case FETCH_VENUE_BY_ID_SUCCESSFULL: {
      return {
        ...state,
        venue: action.payload.venue
      };
    }

    case FETCH_VENUE_BY_ID_COMPLETED: {
      return {
        ...state
      };
    }

    case ON_PUBLISH_EVENT: {
      return {
        ...state,
        error: null,
        isPublishing: true
      };
    }

    case ON_PUBLISH_EVENT_SUCCESS: {
      return {
        ...state,
        isPublishing: false
      };
    }

    case ON_PUBLISH_EVENT_ERROR: {
      return {
        ...state,
        isPublishing: false,
        error: action.error,
        responseBody: action.response
      };
    }

    case EXECUTE_INITIAL_EVENT_PROCESS:
      return {
        ...state,
        initiated: false,
        initialLoading: true
      };

    case EXECUTE_INITIAL_EVENT_PROCESS_COMPLETED: {
      return {
        ...state,
        initiated: true,
        initialLoading: false
      };
    }

    default:
      return state;
  }
};
