import EventService from '@events/services/events.service';
import { AccountService } from '@gofan/api/accounts';
import SeasonService from '@seasons/services/season.service';
import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from '@app/utils/objectUtils';
import { get, partition, max, isNil } from 'lodash';
import type { EventDTO } from '@events/models/event.model';
import { MAX_CAPACITY } from '@events/constants';
import type { EventProduct } from '@app/api/types';

const generatePeak = (ticket: any, isSchoolTicket = false, byTicketSchool = {}) => {
  if (ticket?.sales?.ticket_tkt_limit) {
    return ticket?.sales?.ticket_tkt_limit;
  }
  const ticket_school_limit = isSchoolTicket
    ? ticket?.sales?.ticket_school_limit ?? get(byTicketSchool, `${ticket.accountId}.ticket_school_limit`, 0)
    : 0;
  if (ticket_school_limit) {
    return ticket_school_limit;
  }
  if (ticket?.sales?.event_tkt_limit) {
    return ticket?.sales?.event_tkt_limit;
  }

  return 5000;
};

const mapping = (event: EventDTO, schools = [], ticketSoldTotal: number, isSchoolTicket = false, byTicketSchool = {}) =>
  [...schools].map((ticket: any) => ({
    accountId: ticket.accountId,
    distributionChannel: ticket.distributionChannel,
    peak: generatePeak(ticket, isSchoolTicket, byTicketSchool),
    sales: ticket?.sales,
    customColor: ticket?.customColor,
    school: ticket?.school || event?.account,
    ticketAvailable: ticket.sales?.event_tkt_limit || event?.maxCapacity,
    ticketSale: ticket.sales?.net_to_account || 0,
    ticketSold: ticket.sales?.ticket_net_count || 0,
    ticketSoldTotal,
    type: ticket.name,
    hasSchoolTicket: ticket?.sales?.ticket_tkt_limit > 0,
    id: ticket?.id
  }));

const maxTicketSoldInEvent = ({ products, schoolTickets, eventTickets }) => {
  const priority = ['ticket_tkt_limit', 'ticket_school_limit', 'event_tkt_limit'];
  const limit = [];
  let maxLimit = 0;
  for (let i = 0; i < priority.length; i++) {
    limit[i] = max(products.map((ticket: any) => ticket.sales?.[priority[i]] || 0));
  }
  const [ticketLimit, schoolLimit, eventLimit] = limit;
  const numberOfChart = [!isEmpty(schoolTickets), !isEmpty(eventTickets)].filter(Boolean).length;
  if (numberOfChart === 1) {
    if (!isEmpty(schoolTickets)) {
      maxLimit = Math.max(ticketLimit, schoolLimit) || eventLimit;
    } else {
      const allTicketsLimitValid =
        eventTickets.every((ticket: any) => ticket.sales?.ticket_tkt_limit > 0) && eventLimit > 0;
      maxLimit = allTicketsLimitValid ? ticketLimit : Math.max(ticketLimit, eventLimit);
    }
  }
  if (numberOfChart === 2) {
    maxLimit = Math.max(ticketLimit, schoolLimit, eventLimit);
  }
  if (maxLimit > 0) {
    return maxLimit;
  }
  return 5000;
};

const checkTicketUnlimited = ({ products, schoolTickets, eventTickets }) => {
  const priorities = ['ticket_tkt_limit', 'ticket_school_limit', 'event_tkt_limit'];
  const numberOfChart = [!isEmpty(schoolTickets), !isEmpty(eventTickets)].filter(Boolean).length;
  if (numberOfChart === 1 && isEmpty(schoolTickets)) {
    priorities.splice(1, 1);
  }
  for (let i = 0; i < products.length; i++) {
    if (priorities.filter(priority => products[i].sales && products[i].sales[priority] !== 0).length > 0) return false;
  }

  return true;
};

const checkTicketUnlimitedFromMaxCapacity = (event: EventDTO) => {
  const eventMaxCapacity =
    event?.maxCapacity === MAX_CAPACITY || isEmpty(event?.maxCapacity) ? undefined : event?.maxCapacity;
  return isEmpty(eventMaxCapacity);
};

const shouldShowEmptyData = (event, response) => {
  const totals = get(Object.values(response), '[0].totals', {});
  return !!isEmpty(totals);
};

const isShowNotSoldTickets = (event: EventDTO, ticket: EventProduct): boolean =>
  ticket.distributionChannel === 'GoFan' &&
  (event.ticketDistribution ? ticket.accountId !== null : ticket.accountId === null);

export type TicketSalesType = {
  showEmptyData: boolean;
  eventTickets: any[];
  schoolTickets: any[];
  totalTicketAvailableBySchools: number;
  totalTicketAvailableByTypes: number;
  totals: any;
  ticketDistribution: boolean;
  ticketUnlimited: boolean;
};

const useEventInsight = (event: EventDTO) => {
  const [data, setData] = useState<TicketSalesType>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const { products = [], id = 0 } = event || {};
      let groupByTicketId: any;
      let byTicketSchool: any;

      const response = await EventService.getTicketSaleInsightData(id);
      let seasonTicketsInsights = null;

      if (!isNil(event.seasonId)) {
        const season = await SeasonService.getSeasonById(event.seasonId);

        seasonTicketsInsights = await SeasonService.getSeasonTicketInsightData(
          season?.products.map(seasonTicket => ({
            eventId: event.id,
            financialId: event.financialAccountId ?? '',
            ticketId: Number(seasonTicket.id)
          }))
        ).catch(() => null);
      }

      if (!isEmpty(response)) {
        groupByTicketId = get(Object.values(response)[0], 'byTicketId');
        byTicketSchool = get(Object.values(response)[0], 'byTicketSchool');
      } else {
        groupByTicketId = {};
        byTicketSchool = {};
      }

      const insightProducts = products.filter((ticket: any) => {
        if (
          !isEmpty(groupByTicketId[ticket.id]) ||
          (isShowNotSoldTickets(event, ticket) && !isEmpty(groupByTicketId))
        ) {
          return true;
        }
        return false;
      });

      await Promise.all(
        insightProducts.map(async (ticket: any) => {
          if (!isEmpty(groupByTicketId[ticket.id])) {
            ticket.sales = groupByTicketId[ticket.id];
          }
          if (ticket.accountId) {
            ticket.school = await AccountService.getAccountById(ticket.accountId);
          }
        })
      );

      const [$1, $2] = partition(insightProducts, 'accountId');

      const ticketUnlimited: boolean = !isEmpty(groupByTicketId) && checkTicketUnlimitedFromMaxCapacity(event);
      const ticketSoldTotal: number = maxTicketSoldInEvent({
        products: insightProducts,
        schoolTickets: $1,
        eventTickets: $2
      });

      const schoolTickets = mapping(event, $1, ticketSoldTotal, true, byTicketSchool);
      const eventTickets = mapping(event, $2, ticketSoldTotal, false);
      const defaultValueBySchools =
        (schoolTickets && schoolTickets.find(ticket => ticket.ticketAvailable)?.ticketAvailable) ?? 0;
      const defaultValueByTypes =
        (eventTickets && eventTickets.find(ticket => ticket.ticketAvailable)?.ticketAvailable) ?? 0;
      const totals = get(Object.values(response), '[0].totals', {});
      const seasonTicketTotals = get(seasonTicketsInsights, 'totals');

      if (!isNil(seasonTicketTotals)) {
        if (Number.isInteger(totals.ticket_gross_count))
          totals.ticket_gross_count += seasonTicketTotals.gross_count ?? 0;

        if (Number.isInteger(totals.ticket_net_count))
          totals.ticket_net_count += seasonTicketTotals.gross_count - seasonTicketTotals.refunded_count ?? 0;

        if (Number.isInteger(totals.ticket_redemption_count))
          totals.ticket_redemption_count += seasonTicketTotals.used_count ?? 0;
      }

      setData({
        schoolTickets,
        eventTickets,
        totalTicketAvailableBySchools: defaultValueBySchools,
        totalTicketAvailableByTypes: defaultValueByTypes,
        ticketDistribution: event.ticketDistribution,
        ticketUnlimited,
        totals,
        showEmptyData: shouldShowEmptyData(event, response)
      });
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(event)) {
      fetchData();
    }
  }, [fetchData, event]);

  return {
    fetchData,
    loading,
    data
  };
};

export default useEventInsight;
