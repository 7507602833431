import type { SettingSection } from '@app/modules/events/components/editor/EditorSettings';
import type { AthleticSiteFormData } from '@gofan/api';
import type { ProgressSection } from '@events/components/editor/EditorProgress';

export const ATHLETIC_SITE = 'Athletic Site';
export const ATHLETIC_SITE_UPDATES: SettingSection[] = [
  {
    title: 'Your site settings',
    editorSettings: []
  }
];

export const SECTIONS = {
  GOFAN_TICKETING_DETAILS_SECTION: 'GOFAN_TICKETING_DETAILS_SECTION',
  PLAYON_SITE_DETAILS: 'PLAYON_SITE_DETAILS',
  SCHOOL_NAME_SECTION: 'SCHOOL_NAME_SECTION',
  SCHOOL_BRANDING_SECTION: 'SCHOOL_BRANDING_SECTION',
  SOCIAL_MEDIA_SECTION: 'SOCIAL_MEDIA_SECTION',
  PREVIEW_SITE_SECTION: 'PREVIEW_SITE_SECTION'
};

export const ATHLETIC_SITE_PROGRESS: ProgressSection = {
  title: 'Site Settings',
  settingIndex: 0,
  settingLabel: '',
  progresses: [
    { id: SECTIONS.GOFAN_TICKETING_DETAILS_SECTION, label: 'GoFan Ticketing details' },
    { id: SECTIONS.PLAYON_SITE_DETAILS, label: 'PlayOn Site details' },
    { id: SECTIONS.SCHOOL_NAME_SECTION, label: 'School name' },
    { id: SECTIONS.SCHOOL_BRANDING_SECTION, label: 'School branding' },
    { id: SECTIONS.SOCIAL_MEDIA_SECTION, label: 'Social media' }
  ]
};

export const FIELDS_TO_SECTIONS: { [key in keyof AthleticSiteFormData]: string } = {
  gofanPageEnabled: SECTIONS.GOFAN_TICKETING_DETAILS_SECTION,
  customPageName: SECTIONS.GOFAN_TICKETING_DETAILS_SECTION,
  searchEnabled: SECTIONS.GOFAN_TICKETING_DETAILS_SECTION,
  siteDomain: SECTIONS.PLAYON_SITE_DETAILS,
  siteVisibility: SECTIONS.PLAYON_SITE_DETAILS,
  name: SECTIONS.SCHOOL_NAME_SECTION,
  shortName: SECTIONS.SCHOOL_NAME_SECTION,
  siteSchoolSlogan: SECTIONS.SCHOOL_NAME_SECTION,
  mascot: SECTIONS.SCHOOL_NAME_SECTION,
  logo: SECTIONS.SCHOOL_BRANDING_SECTION,
  logoFile: SECTIONS.SCHOOL_BRANDING_SECTION,
  primaryColor: SECTIONS.SCHOOL_BRANDING_SECTION,
  secondaryColor: SECTIONS.SCHOOL_BRANDING_SECTION,
  facebookUrl: SECTIONS.SOCIAL_MEDIA_SECTION,
  instagramUrl: SECTIONS.SOCIAL_MEDIA_SECTION,
  twitterUrl: SECTIONS.SOCIAL_MEDIA_SECTION,
  youtubeUrl: SECTIONS.SOCIAL_MEDIA_SECTION,
  tiktokUrl: SECTIONS.SOCIAL_MEDIA_SECTION
};

export const ATHLETIC_FIELDS_TO_UPDATE_MESSAGES: { [key in keyof AthleticSiteFormData]: string } = {
  gofanPageEnabled: 'GoFan ticket link',
  customPageName: 'Custom account name',
  searchEnabled: 'Page searchability',
  siteDomain: 'PlayOn Site domain',
  siteVisibility: 'Site visibility',
  name: 'School name',
  shortName: 'Shortened name',
  siteSchoolSlogan: 'School slogan',
  mascot: 'Mascot',
  logoFile: 'School logo',
  primaryColor: 'Primary color',
  secondaryColor: 'Secondary color',
  facebookUrl: 'Facebook url',
  instagramUrl: 'Instagram url',
  twitterUrl: 'Twitter url',
  youtubeUrl: 'YouTube url',
  tiktokUrl: 'TikTok url'
};
