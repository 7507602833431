import { UserRepository } from './user.repository';

import { putObject, deleteObject } from '../shared/aws';
import { stringToHex } from '@gofan/utils/strings';
import isEmpty from 'lodash/isEmpty';
import { getStaffRoleLabel, RosterService } from '@gofan/api/rosters';
import { getSchoolYearId, DEFAULT_SCHOOL_YEAR } from '@gofan/utils';
import { config } from '@gofan/constants/config';

import type {
  UserDTO,
  UserSearchDTO,
  UserPageResponseDTO,
  ContactDTO,
  UserHistorySearchDTO,
  CreateUserDTO,
  CoachInfo
} from './user.model';
import type { HistoryDTO } from '../history';

class UserService {
  static searchUser = (searchParams: UserSearchParams): Promise<UserPageResponseDTO> => {
    const pageIndex = searchParams.page ?? 0;
    const pageSize = searchParams.pageSize ?? 10;
    const queryStr = `?page=${pageIndex}&size=${pageSize}`;
    return UserRepository.searchUser(searchParams.body, queryStr);
  };

  static searchUserByEmail = (email: string): Promise<UserPageResponseDTO> => {
    const searchParams = {
      body: {
        email
      },
      page: 0,
      pageSize: 10
    };
    return UserService.searchUser(searchParams);
  };

  static searchUserByUserAccountContext = (
    userAccountContexts: any[],
    searchParams: UserSearchParams
  ): Promise<UserPageResponseDTO[]> => {
    if (isEmpty(userAccountContexts)) return Promise.resolve([]);
    return Promise.all(
      userAccountContexts.map(context =>
        UserService.searchUser({
          ...searchParams,
          body: {
            email: context.email
          }
        })
      )
    );
  };

  static getUserByEmail = (email: string): Promise<UserDTO> => UserRepository.getUserByEmail(email);

  static getCoachInfo = (user: UserDTO, schoolId: string, schoolYearId?: number): Promise<UserDTO> =>
    new Promise((resolve, reject) => {
      const accountContext = user.userAccountContexts.find(context => context.accountId === schoolId);
      if (accountContext) {
        const { accountRoles, teamIds } = accountContext;
        if (accountRoles.includes('COACH') && teamIds && teamIds.length > 0) {
          Promise.all(
            teamIds.map(teamId =>
              RosterService.getRoster({
                teamId,
                schoolId,
                schoolYearId: schoolYearId || getSchoolYearId(DEFAULT_SCHOOL_YEAR)
              })
            )
          )
            .then(rosters => {
              const workingOnRosters = rosters.filter(item =>
                item?.[0]?.coachStaff.find(staff => staff.email === user.email)
              );
              const teams = workingOnRosters.flat().map(({ id: teamInstanceId, teamId, coachStaff }) => ({
                teamId,
                teamInstanceId,
                coachStaff,
                staffRole: getStaffRoleLabel(coachStaff.find(item => item.email === user.email)?.staffRole || '')
              }));
              return resolve({ ...user, coachInfo: [{ email: user.email, accountId: schoolId, teams }] });
            })
            .catch(reject);
        } else {
          resolve(user);
        }
      } else resolve(user);
    });

  static setCoachInfo = async ({
    user,
    coachInfo,
    schoolId
  }: {
    user: UserDTO;
    coachInfo: CoachInfo;
    schoolId: string;
  }) => {
    const userWithCoachInfo = await UserService.getCoachInfo(user, schoolId);
    let newCoachInfo: CoachInfo[] = [];
    if (userWithCoachInfo?.coachInfo?.length) {
      newCoachInfo = userWithCoachInfo.coachInfo.filter(item => item.accountId !== coachInfo.accountId);
    }
    newCoachInfo.push(coachInfo);
    localStorage.setItem(`coach-${user.email}`, JSON.stringify(newCoachInfo));
  };

  static getUserByEmails = (emails: string[]): Promise<UserDTO[]> => {
    if (isEmpty(emails)) return Promise.resolve([]);
    return Promise.all(emails.map(email => UserRepository.getUserByEmail(email)));
  };

  static getUserByEmailToken = (emailToken: string): Promise<UserDTO> => UserRepository.getUserByEmailToken(emailToken);

  static getUserHistory = (params: UserHistorySearchDTO): Promise<HistoryDTO[]> =>
    UserRepository.getUserHistory(params);

  static getContactsByEmail = (userAccountContexts: any[]): Promise<ContactDTO[]> => {
    if (isEmpty(userAccountContexts)) return Promise.resolve([]);
    return Promise.all(
      userAccountContexts.map(context =>
        UserRepository.getUserByEmail(encodeURIComponent(context.email)).then(res => ({
          ...res,
          contactType: context.contactType
        }))
      )
    );
  };

  static createUser = (createUserRequest: CreateUserDTO): Promise<UserDTO> =>
    UserRepository.createUser(createUserRequest);

  static updateUser = (updateUserRequest: UserDTO): Promise<UserDTO> => UserRepository.updateUser(updateUserRequest);

  static partiallyUpdateUser = (updateUserRequest: Partial<UserDTO>) =>
    UserRepository.partiallyUpdateUser(updateUserRequest);

  static uploadAvatar = (email: string, file?: File, oldAvatar?: string) => {
    const keyBase = `${config.s3.BASE_AVATAR_PATH}/${stringToHex(email).toUpperCase()}`;
    if (!isEmpty(oldAvatar)) {
      if (!file) {
        deleteObject({
          Bucket: config.s3.BUCKET,
          Key: `${keyBase}/${oldAvatar}`
        });
        return;
      }
      if (file.name !== oldAvatar) {
        deleteObject({
          Bucket: config.s3.BUCKET,
          Key: `${keyBase}/${oldAvatar}`
        });
      }
    }

    if (file && file.name) {
      return putObject({
        Body: file,
        Bucket: config.s3.BUCKET,
        Key: `${keyBase}/${file.name}`
      });
    }
  };

  static inviteNewUser = (email: string): Promise<{ messageId: string }> => UserRepository.inviteNewUser(email);
}

export type UserSearchParams = {
  body: UserSearchDTO;
  page: number;
  pageSize: number;
};

export { UserService };
