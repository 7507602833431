import React from 'react';
import styled from 'styled-components';
import { Header } from 'semantic-ui-react';
import { isObject, isString } from 'lodash';
import { PAGES } from '../../../../config/routes';
import Breadcrumb from '../../../Breadcrumb';
import styles from './styles';

const Content = styled.div`
  ${styles.content};
`;

const getPage = path => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(PAGES)) {
    const page = PAGES[key];
    const childKey = Object.keys(page).find(ele => page[ele].path === path);
    if (childKey) {
      return page[childKey];
    }
  }
  return {};
};

export default function PageFrame({ match }) {
  const { title, breadcrumbs } = getPage(match.path);
  // eslint-disable-next-line no-nested-ternary
  const header = isString(title)
    ? title
    : isObject(title)
    ? title[match.headerKey]
    : '';

  return (
    <Content className='container-row-div'>
      <div className='child-spacer-div' />
      <div>
        <Breadcrumb breadcrumbs={breadcrumbs} match={match} />
        <Header as='h1' style={styles.header}>
          {header || ''}
        </Header>
      </div>
      <div className='child-filler-div' />
    </Content>
  );
}
