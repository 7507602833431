import BaseModel from '../BaseModel';

class RefundedTicketsRequest extends BaseModel {
  static REQUESTED_BY_CUSTOMER = 'requested_by_customer';

  constructor({
    accessToken,
    amount,
    charge,
    reason,
    purchasedTicketsAccessTokens
  }) {
    super({
      accessToken,
      amount,
      charge,
      reason,
      purchasedTicketsAccessTokens
    });
  }

  get accessToken() {
    return this.raw.accessToken;
  }

  get amount() {
    return Number.parseInt(this.raw.amount, 10);
  }

  get charge() {
    return this.raw.charge;
  }

  get reason() {
    return this.raw.reason;
  }

  get purchasedTicketsAccessTokens() {
    return this.raw.purchasedTicketsAccessTokens;
  }

  toJSON() {
    return {
      accessToken: this.accessToken,
      amount: this.amount,
      charge: this.charge,
      reason: this.reason,
      purchasedTicketsAccessTokens: this.purchasedTicketsAccessTokens
    };
  }
}

export default RefundedTicketsRequest;
