import type { TeamRowData, TeamDTO, TeamResponseDTO } from '@gofan/api';

export const mockBadmintonTeams: TeamDTO[] = [
  {
    id: '13',
    name: 'Girls Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Badminton',
    activityId: '9',
    gender: 'Girls',
    level: 'Varsity',
    season: 'Spring'
  },
  {
    id: '14',
    name: 'Girls Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Badminton',
    activityId: '9',
    gender: 'Girls',
    level: 'Junior Varsity',
    season: 'Summer'
  },
  {
    id: '15',
    name: 'Girls Freshman',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Badminton',
    activityId: '9',
    gender: 'Girls',
    level: 'Freshman',
    season: 'Summer'
  }
];

export const mockWeightliftingTeams: TeamDTO[] = [
  {
    id: '1',
    name: 'Boys Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Weightlifting',
    activityId: '69',
    gender: 'Boys',
    level: 'Varsity',
    season: 'Spring'
  },
  {
    id: '2',
    name: 'Boys Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Weightlifting',
    activityId: '69',
    gender: 'Boys',
    level: 'Junior Varsity',
    season: 'Summer'
  },
  {
    id: '3',
    name: 'Boys Freshman',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Weightlifting',
    activityId: '69',
    gender: 'Boys',
    level: 'Freshman',
    season: 'Summer'
  },
  {
    id: '31',
    name: 'Girls Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Weightlifting',
    activityId: '69',
    gender: 'Girls',
    level: 'Junior Varsity',
    season: 'Summer'
  },
  {
    id: '32',
    name: 'Girls Freshman',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Weightlifting',
    activityId: '69',
    gender: 'Girls',
    level: 'Freshman',
    season: 'Summer'
  }
];

export const mockTeamResponse: TeamResponseDTO = {
  id: '3',
  activityName: 'Weightlifting',
  activityId: 69,
  gender: 'Boys',
  levelName: 'Freshman',
  levelId: 2,
  seasonName: 'Summer',
  department: 'Athletic',
  organizationId: 'W3C',
  programId: '2',
  schoolYear: 27,
  startYear: 2021,
  invalid: false
};

export const mockTeam = mockBadmintonTeams[0];

export const mockTeams: TeamDTO[] = [
  {
    id: '1',
    name: 'Boys Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Weightlifting',
    activityId: '69',
    gender: 'Boys',
    level: 'Varsity',
    season: 'Spring'
  },
  {
    id: '2',
    name: 'Boys Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Weightlifting',
    activityId: '69',
    gender: 'Boys',
    level: 'Junior Varsity',
    season: 'Summer'
  },
  {
    id: '3',
    name: 'Boys Freshman',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Weightlifting',
    activityId: '69',
    gender: 'Boys',
    level: 'Freshman',
    season: 'Summer'
  },
  {
    id: '4',
    name: 'Girls Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Softball',
    activityId: '11',
    gender: 'Girls',
    level: 'Varsity',
    season: 'Spring'
  },
  {
    id: '5',
    name: 'Girls Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Softball',
    activityId: '11',
    gender: 'Girls',
    level: 'Junior Varsity',
    season: 'Summer'
  },
  {
    id: '6',
    name: 'Girls Freshman',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Softball',
    activityId: '11',
    gender: 'Girls',
    level: 'Freshman',
    season: 'Summer'
  },
  {
    id: '995',
    name: 'Coed Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Softball',
    activityId: '11',
    gender: 'Coed',
    level: 'Junior Varsity',
    season: 'Summer'
  },
  {
    id: '996',
    name: 'Coed Sophomore',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Softball',
    activityId: '11',
    gender: 'Coed',
    level: 'Sophomore',
    season: 'Summer'
  },
  {
    id: '7',
    name: 'Boys Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Basketball',
    activityId: '13',
    gender: 'Boys',
    level: 'Varsity',
    season: 'Spring'
  },
  {
    id: '8',
    name: 'Boys Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Basketball',
    activityId: '13',
    gender: 'Boys',
    level: 'Junior Varsity',
    season: 'Summer'
  },
  {
    id: '9',
    name: 'Boys Freshman',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Basketball',
    activityId: '13',
    gender: 'Boys',
    level: 'Freshman',
    season: 'Summer'
  },
  {
    id: '10',
    name: 'Boys Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Football',
    activityId: '21',
    gender: 'Boys',
    level: 'Varsity',
    season: 'Spring'
  },
  {
    id: '11',
    name: 'Boys Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Football',
    activityId: '21',
    gender: 'Boys',
    level: 'Junior Varsity',
    season: 'Summer'
  },
  {
    id: '12',
    name: 'Boys Freshman',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Football',
    activityId: '21',
    gender: 'Boys',
    level: 'Freshman',
    season: 'Summer'
  },
  {
    id: '13',
    name: 'Girls Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Badminton',
    activityId: '9',
    gender: 'Girls',
    level: 'Varsity',
    season: 'Spring'
  },
  {
    id: '14',
    name: 'Girls Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Badminton',
    activityId: '9',
    gender: 'Girls',
    level: 'Junior Varsity',
    season: 'Summer'
  },
  {
    id: '15',
    name: 'Girls Freshman',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Badminton',
    activityId: '9',
    gender: 'Girls',
    level: 'Freshman',
    season: 'Summer'
  },
  {
    id: '16',
    name: 'Boys Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Soccer',
    activityId: '27',
    gender: 'Boys',
    level: 'Varsity',
    season: 'Spring'
  },
  {
    id: '17',
    name: 'Boys Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Soccer',
    activityId: '27',
    gender: 'Boys',
    level: 'Junior Varsity',
    season: 'Summer'
  },
  {
    id: '18',
    name: 'Boys Freshman',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Soccer',
    activityId: '27',
    gender: 'Boys',
    level: 'Freshman',
    season: 'Summer'
  },
  {
    id: '19',
    name: 'Coed Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Volleyball',
    activityId: '67',
    gender: 'Coed',
    level: 'Varsity',
    season: 'Spring'
  },
  {
    id: '20',
    name: 'Coed Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Volleyball',
    activityId: '67',
    gender: 'Coed',
    level: 'Junior Varsity',
    season: 'Summer'
  },
  {
    id: '21',
    name: 'Coed Freshman',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Volleyball',
    activityId: '67',
    gender: 'Coed',
    level: 'Freshman',
    season: 'Summer'
  },
  {
    id: '22',
    name: 'Boys Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Wrestling',
    activityId: '70',
    gender: 'Boys',
    level: 'Varsity',
    season: 'Spring'
  },
  {
    id: '23',
    name: 'Boys Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Wrestling',
    activityId: '70',
    gender: 'Boys',
    level: 'Junior Varsity',
    season: 'Summer'
  },
  {
    id: '24',
    name: 'Boys Freshman',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Wrestling',
    activityId: '70',
    gender: 'Boys',
    level: 'Freshman',
    season: 'Summer'
  },
  {
    id: '25',
    name: 'Boys Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Archery',
    activityId: '6',
    gender: 'Boys',
    level: 'Varsity',
    season: 'Spring'
  },
  {
    id: '26',
    name: 'Boys Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Archery',
    activityId: '6',
    gender: 'Boys',
    level: 'Junior Varsity',
    season: 'Summer'
  },
  {
    id: '27',
    name: 'Boys Freshman',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Archery',
    activityId: '6',
    gender: 'Boys',
    level: 'Freshman',
    season: 'Summer'
  },
  {
    id: '28',
    name: 'Boys Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Fencing',
    activityId: '19',
    gender: 'Boys',
    level: 'Varsity',
    season: 'Spring'
  },
  {
    id: '29',
    name: 'Boys Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Fencing',
    activityId: '19',
    gender: 'Boys',
    level: 'Junior Varsity',
    season: 'Summer'
  },
  {
    id: '30',
    name: 'Boys Freshman',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Fencing',
    activityId: '19',
    gender: 'Boys',
    level: 'Freshman',
    season: 'Summer'
  },
  {
    id: '31',
    name: 'Girls Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Weightlifting',
    activityId: '69',
    gender: 'Girls',
    level: 'Junior Varsity',
    season: 'Summer'
  },
  {
    id: '32',
    name: 'Girls Freshman',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Weightlifting',
    activityId: '69',
    gender: 'Girls',
    level: 'Freshman',
    season: 'Summer'
  },
  {
    id: '33',
    name: 'Girls Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Fencing',
    activityId: '19',
    gender: 'Girls',
    level: 'Junior Varsity',
    season: 'Summer'
  },
  {
    id: '34',
    name: 'Girls Froshmore',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Fencing',
    activityId: '19',
    gender: 'Girls',
    level: 'Froshmore',
    season: 'Summer'
  },
  {
    id: '35',
    name: 'Coed Middle School',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Fencing',
    activityId: '19',
    gender: 'Coed',
    level: 'Middle School',
    season: 'Summer'
  },
  {
    id: '36',
    name: 'Coed Froshmore',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Fencing',
    activityId: '19',
    gender: 'Coed',
    level: 'Froshmore',
    season: 'Summer'
  },
  {
    id: '37',
    name: 'Coed Sophomore',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Fencing',
    activityId: '19',
    gender: 'Coed',
    level: 'Sophomore',
    season: 'Summer'
  },
  {
    id: '112',
    name: 'Boys Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Baseball',
    activityId: '12',
    gender: 'Boys',
    level: 'Varsity',
    levelId: 1,
    season: 'Spring'
  },

  {
    id: '113',
    name: 'Boys Junior Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Baseball',
    activityId: '12',
    gender: 'Boys',
    level: 'Junior Varsity',
    levelId: 2,
    season: 'Summer'
  },
  {
    id: '114',
    name: 'Boys Freshman',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Baseball',
    activityId: '12',
    gender: 'Boys',
    level: 'Freshman',
    levelId: 3,
    season: 'Summer'
  },
  {
    id: '115',
    name: 'Coed Froshmore',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Camera 2',
    activity: 'Baseball',
    activityId: '12',
    gender: 'Coed',
    level: 'Froshmore',
    levelId: 2,
    season: 'Summer'
  },
  {
    id: '116',
    name: 'Coed Freshman',
    ticketTypesValue: '2 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Baseball',
    activityId: '12',
    gender: 'Coed',
    level: 'Freshman',
    levelId: 3,
    season: 'Summer'
  },
  {
    id: '117',
    name: 'Coed Varsity',
    ticketTypesValue: '3 ticket types',
    mappedToCameraValue: 'Default camera',
    activity: 'Baseball',
    activityId: '12',
    gender: 'Coed',
    level: 'Varsity',
    levelId: 1,
    season: 'Spring'
  }
];

export const mockAccountsTeams: TeamRowData[] = [
  {
    id: '69',
    teams: 'Weightlifting',
    ticketTypes: true,
    mappedToCamera: true,
    detail: {
      teams: [
        {
          id: '1',
          name: 'Boys Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Weightlifting',
          activityId: 69,
          gender: 'Boys',
          levelName: 'Varsity',
          levelId: 1,
          seasonName: 'Spring',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '2',
          name: 'Boys Junior Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Camera 2',
          activityName: 'Weightlifting',
          activityId: 69,
          gender: 'Boys',
          levelName: 'Junior Varsity',
          levelId: 2,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '2',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '3',
          name: 'Boys Freshman',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Weightlifting',
          activityId: 69,
          gender: 'Boys',
          levelName: 'Freshman',
          levelId: 3,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '2',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '31',
          name: 'Girls Junior Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Camera 2',
          activityName: 'Weightlifting',
          activityId: 69,
          gender: 'Girls',
          levelName: 'Junior Varsity',
          levelId: 2,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '32',
          name: 'Girls Freshman',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Weightlifting',
          activityId: 69,
          gender: 'Girls',
          levelName: 'Freshman',
          levelId: 3,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        }
      ],
      ticketSold: 42234,
      ticketSales: 9969
    }
  },
  {
    id: '12',
    teams: 'Baseball',
    ticketTypes: true,
    mappedToCamera: true,
    detail: {
      teams: [
        {
          id: '112',
          name: 'Boys Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Baseball',
          activityId: 12,
          gender: 'Boys',
          levelName: 'Varsity',
          levelId: 1,
          seasonName: 'Spring',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },

        {
          id: '113',
          name: 'Boys Junior Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Camera 2',
          activityName: 'Baseball',
          activityId: 12,
          gender: 'Boys',
          levelName: 'Junior Varsity',
          levelId: 2,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '114',
          name: 'Boys Freshman',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Baseball',
          activityId: 12,
          gender: 'Boys',
          levelName: 'Freshman',
          levelId: 3,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '115',
          name: 'Coed Froshmore',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Camera 2',
          activityName: 'Baseball',
          activityId: 12,
          gender: 'Coed',
          levelName: 'Froshmore',
          levelId: 2,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '116',
          name: 'Coed Freshman',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Baseball',
          activityId: 12,
          gender: 'Coed',
          levelName: 'Freshman',
          levelId: 3,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '117',
          name: 'Coed Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Baseball',
          activityId: 12,
          gender: 'Coed',
          levelName: 'Varsity',
          levelId: 1,
          seasonName: 'Spring',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        }
      ],
      ticketSold: 42234,
      ticketSales: 9912
    }
  },
  {
    id: '11',
    teams: 'Softball',
    ticketTypes: false,
    mappedToCamera: false,
    detail: {
      teams: [
        {
          id: '4',
          name: 'Girls Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Softball',
          activityId: 11,
          gender: 'Girls',
          levelName: 'Varsity',
          levelId: 1,
          seasonName: 'Spring',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '5',
          name: 'Girls Junior Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Camera 2',
          activityName: 'Softball',
          activityId: 11,
          gender: 'Girls',
          levelName: 'Junior Varsity',
          levelId: 2,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '6',
          name: 'Girls Freshman',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Softball',
          activityId: 11,
          gender: 'Girls',
          levelName: 'Freshman',
          levelId: 3,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '995',
          name: 'Coed Junior Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Camera 2',
          activityName: 'Softball',
          activityId: 11,
          gender: 'Coed',
          levelName: 'Junior Varsity',
          levelId: 2,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '996',
          name: 'Coed Sophomore',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Softball',
          activityId: 11,
          gender: 'Coed',
          levelName: 'Sophomore',
          levelId: 886,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        }
      ],
      ticketSold: 42234,
      ticketSales: 9911
    }
  },
  {
    id: '13',
    teams: 'Basketball',
    ticketTypes: false,
    mappedToCamera: false,
    detail: {
      teams: [
        {
          id: '7',
          name: 'Boys Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Basketball',
          activityId: 13,
          gender: 'Boys',
          levelName: 'Varsity',
          levelId: 1,
          seasonName: 'Spring',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '8',
          name: 'Boys Junior Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Camera 2',
          activityName: 'Basketball',
          activityId: 13,
          gender: 'Boys',
          levelName: 'Junior Varsity',
          levelId: 2,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '9',
          name: 'Boys Freshman',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Basketball',
          activityId: 13,
          gender: 'Boys',
          levelName: 'Freshman',
          levelId: 3,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        }
      ],
      ticketSold: 42234,
      ticketSales: 9913
    }
  },
  {
    id: '21',
    teams: 'Football',
    ticketTypes: false,
    mappedToCamera: false,
    detail: {
      teams: [
        {
          id: '10',
          name: 'Boys Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Football',
          activityId: 21,
          gender: 'Boys',
          levelName: 'Varsity',
          levelId: 1,
          seasonName: 'Spring',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '11',
          name: 'Boys Junior Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Camera 2',
          activityName: 'Football',
          activityId: 21,
          gender: 'Boys',
          levelName: 'Junior Varsity',
          levelId: 2,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '12',
          name: 'Boys Freshman',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Football',
          activityId: 21,
          gender: 'Boys',
          levelName: 'Freshman',
          levelId: 3,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        }
      ],
      ticketSold: 42234,
      ticketSales: 9921
    }
  },
  {
    id: '9',
    teams: 'Badminton',
    ticketTypes: false,
    mappedToCamera: false,
    detail: {
      teams: [
        {
          id: '13',
          name: 'Girls Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Badminton',
          activityId: 9,
          gender: 'Girls',
          levelName: 'Varsity',
          levelId: 1,
          seasonName: 'Spring',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '14',
          name: 'Girls Junior Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Camera 2',
          activityName: 'Badminton',
          activityId: 9,
          gender: 'Girls',
          levelName: 'Junior Varsity',
          levelId: 2,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '15',
          name: 'Girls Freshman',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Badminton',
          activityId: 9,
          gender: 'Girls',
          levelName: 'Freshman',
          levelId: 3,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        }
      ],
      ticketSold: 42234,
      ticketSales: 999
    }
  },
  {
    id: '27',
    teams: 'Soccer',
    ticketTypes: false,
    mappedToCamera: false,
    detail: {
      teams: [
        {
          id: '16',
          name: 'Boys Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Soccer',
          activityId: 27,
          gender: 'Boys',
          levelName: 'Varsity',
          levelId: 1,
          seasonName: 'Spring',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '17',
          name: 'Boys Junior Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Camera 2',
          activityName: 'Soccer',
          activityId: 27,
          gender: 'Boys',
          levelName: 'Junior Varsity',
          levelId: 2,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '18',
          name: 'Boys Freshman',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Soccer',
          activityId: 27,
          gender: 'Boys',
          levelName: 'Freshman',
          levelId: 3,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        }
      ],
      ticketSold: 42234,
      ticketSales: 9927
    }
  },
  {
    id: '67',
    teams: 'Volleyball',
    ticketTypes: false,
    mappedToCamera: false,
    detail: {
      teams: [
        {
          id: '19',
          name: 'Coed Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Volleyball',
          activityId: 67,
          gender: 'Coed',
          levelName: 'Varsity',
          levelId: 1,
          seasonName: 'Spring',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '20',
          name: 'Coed Junior Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Camera 2',
          activityName: 'Volleyball',
          activityId: 67,
          gender: 'Coed',
          levelName: 'Junior Varsity',
          levelId: 2,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '21',
          name: 'Coed Freshman',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Volleyball',
          activityId: 67,
          gender: 'Coed',
          levelName: 'Freshman',
          levelId: 3,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        }
      ],
      ticketSold: 42234,
      ticketSales: 9967
    }
  },
  {
    id: '70',
    teams: 'Wrestling',
    ticketTypes: false,
    mappedToCamera: false,
    detail: {
      teams: [
        {
          id: '22',
          name: 'Boys Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Wrestling',
          activityId: 70,
          gender: 'Boys',
          levelName: 'Varsity',
          levelId: 1,
          seasonName: 'Spring',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '23',
          name: 'Boys Junior Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Camera 2',
          activityName: 'Wrestling',
          activityId: 70,
          gender: 'Boys',
          levelName: 'Junior Varsity',
          levelId: 2,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '24',
          name: 'Boys Freshman',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Wrestling',
          activityId: 70,
          gender: 'Boys',
          levelName: 'Freshman',
          levelId: 3,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        }
      ],
      ticketSold: 42234,
      ticketSales: 9970
    }
  },
  {
    id: '6',
    teams: 'Archery',
    ticketTypes: false,
    mappedToCamera: true,
    detail: {
      teams: [
        {
          id: '25',
          name: 'Boys Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Archery',
          activityId: 6,
          gender: 'Boys',
          levelName: 'Varsity',
          levelId: 1,
          seasonName: 'Spring',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '26',
          name: 'Boys Junior Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Camera 2',
          activityName: 'Archery',
          activityId: 6,
          gender: 'Boys',
          levelName: 'Junior Varsity',
          levelId: 2,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '27',
          name: 'Boys Freshman',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Archery',
          activityId: 6,
          gender: 'Boys',
          levelName: 'Freshman',
          levelId: 3,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        }
      ],
      ticketSold: 42234,
      ticketSales: 996
    }
  },
  {
    id: '19',
    teams: 'Fencing',
    ticketTypes: true,
    mappedToCamera: false,
    detail: {
      teams: [
        {
          id: '28',
          name: 'Boys Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Fencing',
          activityId: 19,
          gender: 'Boys',
          levelName: 'Varsity',
          levelId: 1,
          seasonName: 'Spring',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '29',
          name: 'Boys Junior Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Camera 2',
          activityName: 'Fencing',
          activityId: 19,
          gender: 'Boys',
          levelName: 'Junior Varsity',
          levelId: 2,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '30',
          name: 'Boys Freshman',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Fencing',
          activityId: 19,
          gender: 'Boys',
          levelName: 'Freshman',
          levelId: 3,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '33',
          name: 'Girls Junior Varsity',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Camera 2',
          activityName: 'Fencing',
          activityId: 19,
          gender: 'Girls',
          levelName: 'Junior Varsity',
          levelId: 2,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '34',
          name: 'Girls Froshmore',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Fencing',
          activityId: 19,
          gender: 'Girls',
          levelName: 'Froshmore',
          levelId: 895,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '35',
          name: 'Coed Middle School',
          ticketTypesValue: '3 ticket types',
          mappedToCameraValue: 'Camera 2',
          activityName: 'Fencing',
          activityId: 19,
          gender: 'Coed',
          levelName: 'Middle School',
          levelId: 4,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '36',
          name: 'Coed Froshmore',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Fencing',
          activityId: 19,
          gender: 'Coed',
          levelName: 'Froshmore',
          levelId: 895,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        },
        {
          id: '37',
          name: 'Coed Sophomore',
          ticketTypesValue: '2 ticket types',
          mappedToCameraValue: 'Default camera',
          activityName: 'Fencing',
          activityId: 19,
          gender: 'Coed',
          levelName: 'Sophomore',
          levelId: 886,
          seasonName: 'Summer',
          schoolYear: 27,
          startYear: 2021,
          programId: '1',
          department: 'Athletic',
          organizationId: 'MYLEHAI',
          invalid: false
        }
      ],
      ticketSold: 42234,
      ticketSales: 9919
    }
  }
];
