import React from 'react';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledLink = styled(Header)`
  font-weight: ${({ regular }) => regular && `400`};
`;

export default ({ children, ...props }) => (
  <StyledLink {...props}>{children}</StyledLink>
);
