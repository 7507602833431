import { Controller, useFormContext } from 'react-hook-form';
import { Icons, FileUploaderDropContainer, FileUploaderItem } from '../../..';

interface AttachmentsFieldProps {
  name: string;
  label?: string;
  id?: string;
}

const AttachmentsField = ({ name, label, id }: AttachmentsFieldProps) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const attachments = watch(name) || [];

  return (
    <div className='gf-file_upload-container gs--margin-bottom-sp6 gs--margin-bottom__sp6'>
      <Icons.Upload className='gf-upload-icon' />
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FileUploaderDropContainer
            {...field}
            id={id || name}
            accept={['image/jpeg', 'image/jpg', 'image/png']}
            labelText={label || 'Drag and drop files here or click to upload'}
            multiple
            className='gf-file-uploader-container'
            onAddFiles={(_, { addedFiles }) => {
              field.onChange(addedFiles);
              _handleAttachment(addedFiles);
            }}
            disabled={false}
          />
        )}
      />
      {errors[name] && <div className='gf-error-msg'>{errors[name].message}</div>}

      {attachments?.length > 0 && (
        <div className='file-uploader-list gs--margin-top-sp3 gs--margin-top__sp3'>
          {attachments.map((file: File) => (
            <FileUploaderItem
              key={`${file.name}}`}
              name={file.name}
              status='edit'
              size='md'
              iconDescription='Delete file'
              onDelete={() => _handleAttachmentDelete(file.name)}
            />
          ))}
        </div>
      )}
    </div>
  );

  function _handleAttachment(files: File[]) {
    const newFiles = [...(attachments ?? []), ...files];
    setValue(name, newFiles);
  }

  function _handleAttachmentDelete(fileName: string) {
    const index = attachments.findIndex((file: File) => file.name === fileName);
    if (index !== -1) {
      const newFiles = [...attachments];
      newFiles.splice(index, 1);
      setValue(name, newFiles);
    }
  }
};

export { AttachmentsField };
