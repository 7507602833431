import { useEffect, useRef, useState } from 'react';

import { FEATURE_FLAGS, useFeatureFlags } from '@gofan/hooks';
import EventScheduleService from '@modules/event-integrations_V2/services/event-schedule.service';
import { EventConfigService } from '../../services/event-config.service';

import type {
  PartnerInsight,
  EventScheduleSearchDTO,
  EventPartnerSalesInfoDTO,
  EventSchedulePartnerInsightDTO
} from '@modules/event-integrations_V2/models/event-schedule.model';

export const defaultPartnerInsight: {
  insightData: EventSchedulePartnerInsightDTO;
  salesInfoData: EventPartnerSalesInfoDTO;
} = {
  insightData: {
    partnerName: '',
    totalUpcomingNewEvents: 0,
    totalUpcomingEvents: 0,
    totalUpcomingPublishedEvents: 0,
    totalUpcomingUpdatedEvents: 0,
    totalUpcomingIgnoredEvents: 0,

    totalChangedEvents: 0,
    totalDeclinedEvents: 0,
    totalIgnoredEvents: 0,
    totalNewEvents: 0
  },
  salesInfoData: {
    partnerName: '',
    ticketSales: 0,
    totalPublishedEvents: 0
  }
};

export const usePartnerInsight = () => {
  const { [FEATURE_FLAGS.tempEventMonitorSettingXMeg]: tempEventMonitorSettingXMeg } = useFeatureFlags();
  const [data, setData] = useState<PartnerInsight>({ insightData: [], salesInfoData: [] });
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const isUnmounted = useRef<boolean>(false);

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  useEffect(() => {
    setLoading(true);
  }, []);

  const getPartnerInsightAndSalesInfo = (searchParams: EventScheduleSearchDTO) => {
    setLoading(true);
    return Promise.all([
      EventScheduleService.getGlobalEventPartnerInsight(searchParams),
      EventScheduleService.getEventPartnerSalesInfo(searchParams)
    ])
      .then(([insightData, salesInfoData]) => {
        if (isUnmounted.current) return;
        setData({ insightData, salesInfoData });
        return { insightData, salesInfoData } as PartnerInsight;
      })
      .catch(error => {
        if (isUnmounted.current) return;
        setError(error);
      })
      .finally(() => {
        if (isUnmounted.current) return;
        setLoading(false);
      });
  };

  const getPartnerInsight = async (searchParams: EventScheduleSearchDTO) => {
    setLoading(true);
    if (searchParams.type === 'Away') {
      const newSearchParams = { ...searchParams };
      if (tempEventMonitorSettingXMeg && searchParams?.schoolIds?.length) {
        newSearchParams.schoolIds = await EventConfigService.getAccountIdsEnabledReceivedAwayGames(
          searchParams.schoolIds
        );
      }

      return EventScheduleService.getAwayEventInsight(newSearchParams)
        .then(insightDataResult => {
          if (isUnmounted.current) return;
          setData({ insightData: [insightDataResult], salesInfoData: [] });
          return { insightData: [insightDataResult], salesInfoData: [] } as PartnerInsight;
        })
        .catch(error => {
          if (isUnmounted.current) return;
          setError(error);
        })
        .finally(() => {
          if (isUnmounted.current) return;
          setLoading(false);
        });
    }
    return EventScheduleService.getGlobalEventPartnerInsight(searchParams)
      .then(insightData => {
        if (isUnmounted.current) return;
        setData({ insightData, salesInfoData: [] });
        return { insightData, salesInfoData: [] } as PartnerInsight;
      })
      .catch(error => {
        if (isUnmounted.current) return;
        setError(error);
      })
      .finally(() => {
        if (isUnmounted.current) return;
        setLoading(false);
      });
  };

  const getTotalUpcomingNewEvents = (insightData: EventSchedulePartnerInsightDTO[]) => {
    let count = 0;
    if (insightData && insightData?.length > 0) {
      insightData.forEach((partner: EventSchedulePartnerInsightDTO) => {
        count += partner.totalUpcomingNewEvents;
      });
    }
    return count;
  };

  const checkHavingNewUpcomingEvents = async (searchParams: EventScheduleSearchDTO) => {
    setLoading(true);
    let count = 0;
    const insightData = await EventScheduleService.getGlobalEventPartnerInsight(searchParams);
    if (insightData) {
      count = getTotalUpcomingNewEvents(insightData);
    }
    return count > 0;
  };

  const resetPartnerInsight = () => {
    setData({ insightData: [], salesInfoData: [] });
  };

  return {
    loading,
    error,
    data,
    getPartnerInsight,
    getPartnerInsightAndSalesInfo,
    resetPartnerInsight,
    checkHavingNewUpcomingEvents,
    getTotalUpcomingNewEvents
  };
};
