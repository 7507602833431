import moment from 'moment';
import dayjs from 'dayjs';
import { isEmpty, lowerCase, omit, uniq, upperFirst } from 'lodash';

import { getUniqueId } from '@utils/objectUtils';
import { switchZone, TIME_ZONE_ENUM, TIME_ZONE_MAPPING, EVENT_DATE_FORMAT_WITH_TIMEZONE } from '@utils/dateUtils';

import type { AccountDTO } from '@gofan/api/accounts';
import type { ActivityDTO } from '@gofan/api/activities';
import { generateSpecialEventDescription } from '@app/api/services/EventService';
import { getEndDateSeason, getStartDateSeason } from '@app/api/services/SeasonService';

import { EVENT_START_TIME_TYPE, GENDER_TYPE } from '@season-management/constants/constant';
import SeasonCreationUtil from '@season-management/utils/season-creation.utils';
import type { RawEvent } from '@season-management/middleware/models/raw-event.model';
import type { RawSeason } from '@season-management/middleware/models/raw-season.model';
import type { RawProduct } from '@season-management/middleware/models/raw-product.model';
import type { FormField, GroupLevelGender, StartTimeOptions } from '@season-management/middleware/models/model';
import type { SeasonProduct } from '@seasons/models/season.model';

export interface Config {}

export interface SeasonWithEvent {
  accountId: string;
  activityId: number;
  activityPath?: string;
  alert?: string;
  archived?: boolean;
  config?: Config;
  createdAt?: Date;
  createdBy?: string;
  delayedReservedSeatReleaseDate?: string;
  description?: string;
  disableDigitalTicket?: boolean;
  disableQr?: boolean;
  emailMessageId?: number;
  endDateTime?: Date;
  eventIds?: number[];
  eventTypeId?: number;
  events?: RawEvent[];
  entered?: string;
  featured?: boolean;
  formFields?: FormField[];
  formId?: number;
  genders?: string[];
  glCode?: string;
  hidden?: boolean;
  id?: number | string;
  levels?: GroupLevelGender[];
  markdownDescription?: string;
  name?: string;
  partnerName?: string;
  passType?: string;
  paymentCycle?: string;
  products?: SeasonProduct[];
  publishDateTime?: string | null;
  reportingLabel?: string;
  reservedSeatingAvailable?: boolean;
  seasonInfoUrl?: string;
  seasonRenewalTemplateUrl?: string;
  startDateTime?: Date;
  theme?: string;
  ticketLimitPerOrder?: number;
  timeZone?: string;
  updatedAt?: Date;
  updatedBy?: string;
  venueAddress?: string;
  venueCity?: string;
  venueId?: number;
  venueLocation?: string;
  venueName?: string;
  venueState?: string;
  venueZip?: string;
  financialAccountId?: string;

  // additional fields
  created?: boolean;

  // gofan event exist
  gofanEventIdExist?: string;
  dateTimeDuration?: number;
}

// interface CreatedProduct extends Omit<RawProduct, 'id'> {
//   id?: string | number;
// }

// interface CreatedEvent extends Omit<RawEvent, 'id' | 'products'> {
//   id?: string | number;
//   products?: CreatedProduct[];
// }

export default class SeasonWithEventModel {
  contents: SeasonWithEvent;

  defaultValues = {
    id: getUniqueId()
  };

  constructor(contents: any) {
    this.contents = { ...contents } as SeasonWithEvent;
  }

  toJSON() {
    return { ...this.defaultValues, ...this.contents };
  }

  populate({ account, activity, rawSeason }: { account: AccountDTO; activity: ActivityDTO; rawSeason: RawSeason }) {
    const timeZone = TIME_ZONE_MAPPING[lowerCase(account?.timeZone)] ?? TIME_ZONE_ENUM.AMERICA_NEW_YORK;
    const { id: accountId, paymentCycle } = account;
    const { id: activityId, label: reportingLabel } = activity;

    let fullInfoLevels: GroupLevelGender[] = [];
    rawSeason?.genders?.forEach(gender => {
      const genderName = upperFirst(`${gender}`.toLowerCase());
      const levelsByGender = rawSeason?.levelsByGender?.[gender] ?? [];
      levelsByGender?.forEach(level => {
        let foundLevel = false;
        fullInfoLevels = fullInfoLevels.map(item => {
          if (`${item.levelId}` === `${level.id}`) {
            foundLevel = true;
            return {
              ...item,
              genders: gender === GENDER_TYPE.COED ? [...item.genders] : uniq([...item.genders, genderName])
            };
          }
          return { ...item };
        });

        if (!foundLevel) {
          fullInfoLevels.push({
            levelId: level.id,
            levelName: level.name,
            genders: gender === GENDER_TYPE.COED ? [] : [genderName]
          });
        }
      });
    });

    const levels: GroupLevelGender[] = fullInfoLevels.map(level => ({
      genders: level.genders,
      levelId: level.levelId
    }));
    const genders = !isEmpty(levels)
      ? []
      : rawSeason?.genders
          ?.filter(gender => gender !== GENDER_TYPE.COED)
          ?.map(gender => upperFirst(`${gender}`.toLowerCase()));

    const seasonStartTimeType = rawSeason?.eventStartTimeType ?? EVENT_START_TIME_TYPE.ALL_TEAMS;
    const seasonStartTimeOptions: StartTimeOptions = SeasonCreationUtil.convertStartTimeTeamsToOptions({
      startTimeType: seasonStartTimeType,
      startTimeTeams: rawSeason?.startTimeTeams
    });

    const events: RawEvent[] = (rawSeason?.rawEvents ?? []).map(rawEvent => {
      const mStartDateTime = moment(rawEvent.startDateTime);
      let mEndDateTime = mStartDateTime.clone().add(4, 'h');

      if (!isEmpty(rawEvent.endDateTime) && rawEvent.dateTimeDuration) {
        const duration = moment(rawEvent.endDateTime).diff(mStartDateTime);
        if (duration === rawEvent.dateTimeDuration) {
          mEndDateTime = moment(rawEvent.endDateTime);
        } else {
          mEndDateTime = mStartDateTime.clone().add(rawEvent.dateTimeDuration);
        }
      }

      const eventStartDateTime = switchZone(mStartDateTime.format(EVENT_DATE_FORMAT_WITH_TIMEZONE), timeZone);
      const eventEndDateTime = switchZone(mEndDateTime.format(EVENT_DATE_FORMAT_WITH_TIMEZONE), timeZone);
      const specialEventDescription = generateSpecialEventDescription({
        levels: fullInfoLevels,
        theme: rawEvent?.theme ?? ''
      });

      const products: RawProduct[] = [];
      (rawEvent.products ?? []).forEach(rawProduct => {
        let salesStartDateTime;
        let salesEndDateTime;
        if (!isEmpty(rawProduct?.salesStartDate) && !isEmpty(rawProduct?.salesStartTime)) {
          const mSalesStartDateTime = dayjs(`${rawProduct?.salesStartDate} ${rawProduct?.salesStartTime}`);
          salesStartDateTime = switchZone(
            mSalesStartDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE),
            timeZone
          );
        }
        if (!isEmpty(rawProduct?.salesEndDate) && !isEmpty(rawProduct?.salesEndTime)) {
          const mSalesEndDateTime = dayjs(`${rawProduct?.salesEndDate} ${rawProduct?.salesEndTime}`);
          salesEndDateTime = switchZone(mSalesEndDateTime.clone().format(EVENT_DATE_FORMAT_WITH_TIMEZONE), timeZone);
        }

        const product = {
          accountId: rawProduct.accountId,
          customColor: rawProduct.customColor,
          distributionChannel: rawProduct.distributionChannel,
          groupId: rawProduct.groupId,
          enabled: rawProduct.enabled,
          // encodedString: null,
          // eventId: null,
          fee: rawProduct.fee,
          // formId: null,
          // generateLink: null,
          // hiddenFeeBase: null,
          hiddenFees: rawProduct.hiddenFees,
          id: rawProduct.id,
          // limit: null,
          name: rawProduct.name,
          packCount: rawProduct.packCount,
          price: rawProduct.price,
          productType: rawProduct.productType,
          // promotionLabel: null,
          // promotionRequired: null,
          rateId: rawProduct.rateId,
          // redemptionLimit: null,
          reservedSeating: rawProduct.reservedSeating,
          // seasonId: null,
          seatsIoCategory: rawProduct.seatsIoCategory,
          salesStartDateTime,
          salesEndDateTime,
          ticketLimitPerOrder: rawProduct.ticketLimitPerOrder
        };

        products.push({
          ...product,
          created: !!rawProduct.created
        });
      });

      const eventStartTime = {
        time: mStartDateTime.clone().format('hh:mm'),
        period: mStartDateTime.clone().format('A')
      };
      const eventStartTimeType = rawEvent?.eventStartTimeType ?? EVENT_START_TIME_TYPE.ALL_TEAMS;
      const startTimeType = rawEvent?.created ? eventStartTimeType : seasonStartTimeType;

      const eventStartTimeOptions: StartTimeOptions = rawEvent?.created
        ? SeasonCreationUtil.convertStartTimeTeamsToOptions({
            startTimeType,
            startTimeTeams: rawEvent?.startTimeTeams
          })
        : seasonStartTimeOptions;

      let startTimeOptions;

      if (isEmpty(rawEvent.eventIntegrationDetails)) {
        startTimeOptions = SeasonCreationUtil.replaceMinInStartTimeOptions({
          startTimeOptions: eventStartTimeOptions,
          replaceValue: eventStartTime
        });
      }

      const disableQr = rawEvent.created ? rawEvent.disableQr ?? true : true;

      return {
        created: rawEvent.created,
        accountId,
        // accountsTicket: null,
        activityId,
        // activityPath: null,
        alert: rawEvent.alert,
        allDayEvent: rawEvent.allDayEvent,
        archived: rawEvent.archived,
        // canceled: null,
        // createdAt: null,
        customSportName: reportingLabel,
        // description: null,
        disableQr,
        disabledForIndividualSale: rawEvent.disabledForIndividualSale,
        // emailMessageId: null,
        enableEventValidation: rawEvent.enableEventValidation,
        endDateTime: eventEndDateTime,
        // eventNotes: null,
        // eventTypeId: null,
        eventValidationStartsBefore: rawEvent.eventValidationStartsBefore,
        eventIntegrationDetails: rawEvent.eventIntegrationDetails,
        // featured: null,
        // featuredAccountIds: null,
        financialAccountId: rawEvent.financialAccountId ?? accountId,
        // formFields: null,
        // formId: null,
        genders,
        globalEventsId: rawEvent.globalEventsId,
        // headerImage: null,
        // hiddenFromBoxOffice: null,
        // hostAccountId: null,
        id: rawEvent.id,
        levels,
        maxCapacity: rawEvent.maxCapacity,
        name: rawEvent.name,
        opponentAccountId: rawEvent.opponentAccountId,
        partnerId: rawEvent?.partnerId,
        partnerName: rawEvent?.partnerName,
        paymentCycle: rawEvent.paymentCycle ?? paymentCycle,
        postSeason: rawEvent.postSeason,
        products,
        // publishDateTime: null,
        // redemptionWindow: null,
        reportingLabel,
        // salesEndDateTime: null,
        // salesStartDateTime: null,
        // seasonId: null,
        // sendReminder: null,
        specialEventDescription,
        startDateTime: eventStartDateTime,
        startTimeType: !isEmpty(rawEvent.startTimeType) ? rawEvent.startTimeType : startTimeType,
        startTimeOptions: !isEmpty(rawEvent.startTimeOptions) ? rawEvent.startTimeOptions : startTimeOptions,
        taggedAccountIds: rawEvent.taggedAccountIds,
        // termsAndConditions: null,
        // theme: null,
        // ticketDistribution: null,
        ticketLimitPerOrder: rawEvent.ticketLimitPerOrder,
        timeZone,
        // updatedBy: null,
        venueAddress: rawEvent.venueAddress,
        venueCity: rawEvent.venueCity,
        venueId: rawEvent.venueId,
        venueLocation: rawEvent.venueLocation,
        venueName: rawEvent.venueName,
        venueState: rawEvent.venueState,
        venueZip: rawEvent.venueZip,
        gofanEventIdExist: rawEvent?.gofanEventIdExist,
        dateTimeDuration: rawEvent?.dateTimeDuration
      };
    });

    const seasonStartDateTime = getStartDateSeason(events);
    const seasonEndDateTime = getEndDateSeason(events);

    let seasonPublishDateTime = null;
    if (!isEmpty(rawSeason.publishDateTime)) {
      const mPublishDateTime = moment(rawSeason.publishDateTime);
      seasonPublishDateTime = switchZone(mPublishDateTime.format(EVENT_DATE_FORMAT_WITH_TIMEZONE), timeZone);
    }

    const populateValues = rawSeason?.created
      ? {
          id: rawSeason?.seasonId,
          created: rawSeason?.created
        }
      : {
          disableQr: true
        };

    this.contents = {
      ...this.contents,
      ...populateValues,
      accountId,
      activityId,
      // activityPath: null,
      alert: rawSeason?.alert,
      archived: rawSeason?.archived ?? false,
      // config: null,
      // createdAt: null,
      // createdBy: null,
      // delayedReservedSeatReleaseDate: null,
      // description: null,
      // disableDigitalTicket: null,
      // disableQr: null,
      // emailMessageId: null,
      endDateTime: seasonEndDateTime,
      // eventIds: null,
      // eventTypeId: null,
      events,
      // featured: null,
      // formFields: null,
      // formId: null,
      genders,
      glCode: rawSeason?.glCode,
      // hidden: null,
      // id: null,
      levels,
      // markdownDescription: null,
      name: rawSeason?.name,
      partnerName: rawSeason?.partnerName,
      // passType: null,
      paymentCycle,
      products: rawSeason?.products?.map((product: SeasonProduct) => {
        if (product.created) {
          return omit(product, ['created']);
        }
        return omit(product, ['id', 'created']);
      }),
      publishDateTime: seasonPublishDateTime,
      reportingLabel,
      // seasonInfoUrl: null,
      // seasonRenewalTemplateUrl: null,
      startDateTime: seasonStartDateTime,
      // theme: null,
      ticketLimitPerOrder: rawSeason.ticketLimitPerOrder,
      timeZone,
      // updatedAt: null,
      // updatedBy: null,
      venueAddress: rawSeason?.venue?.address,
      venueCity: rawSeason?.venue?.city,
      venueId: rawSeason?.venue?.id,
      venueLocation: rawSeason?.venue?.location,
      venueName: rawSeason?.venue?.name,
      venueState: rawSeason?.venue?.state,
      venueZip: rawSeason?.venue?.zipCode,
      entered: rawSeason.entered,
      financialAccountId: rawSeason.financialAccountId ?? accountId,
      taggedAccountIds: rawSeason.taggedAccountIds ?? []
    };

    return this;
  }
}
