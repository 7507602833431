class EventReservedSeating {
  constructor({ seats, updatedAt, seatsInfo, blockedIds, bookedIds }) {
    this.raw = {
      seats,
      updatedAt,
      seatsInfo,
      blockedIds,
      bookedIds
    };
  }

  get updatedAt() {
    return this.raw.updatedAt;
  }

  get seats() {
    return Object.values(this.raw.seatsInfo);
  }

  get blockedIds() {
    return this.raw.blockedIds;
  }

  get bookedIds() {
    return this.raw.bookedIds;
  }

  get seatsInfo() {
    return this.raw.seatsInfo;
  }

  set updatedAt(updatedAt) {
    this.raw.updatedAt = updatedAt;
  }

  set seatsInfo(seatsInfo) {
    this.raw.seatsInfo = seatsInfo;
  }

  toJSON() {
    return {
      seats: this.seats,
      updatedAt: this.updatedAt,
      seatsInfo: this.seatsInfo,
      blockedIds: this.blockedIds,
      bookedIds: this.bookedIds
    };
  }
}

export default EventReservedSeating;
