import axios from 'axios';
import { SUBMIT_TICKET, SUBMIT_TICKET_MOBILE, UPLOAD_ATTACHMENT, UPLOAD_ATTACHMENT_MOBILE } from './zendesk.endpoints';
import type { ZendeskSupportForm } from './zendesk.schema';
import { convertToZendeskTicketMapping } from './zendesk.utils';
import type { UserDTO } from '../users/user.model';

class ZendeskService {
  static createTicket = (
    ticketDetails: ZendeskSupportForm,
    currentUser: UserDTO,
    attachmentTokens: string[] = [],
    isMobileApp = false
  ): Promise<any> => {
    const body = convertToZendeskTicketMapping(ticketDetails, currentUser, attachmentTokens);
    return axios.post<any>(`${isMobileApp ? SUBMIT_TICKET_MOBILE : SUBMIT_TICKET}`, body);
  };

  static uploadAttachment = (file: File, isMobileApp = false): Promise<any> => {
    const formData = new FormData();
    formData.append('file', file);

    return axios
      .post<any>(`${isMobileApp ? UPLOAD_ATTACHMENT_MOBILE : UPLOAD_ATTACHMENT}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => response.data);
  };
}

export { ZendeskService };
