import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { PAGES } from '@app/config/routes';
import { setStoragePathToRedirect } from '@gofan/utils/local-storage';
import { getContactName } from '@app/modules/accounts/utils/contact.utils';
import { DEFAULT_ACCOUNT } from '@app/modules/dashboard/views/dashboard.view';
import EventScheduleService from '@modules/event-integrations_V2/services/event-schedule.service';
import LoadingContent, { LOADING_CONTENT_TYPES } from '@app/commons/LoadingContent';
import { isBoolean, some } from 'lodash';
import { INTERNAL_USER_ROLES, getCoachInfo, hasOtherRoleExcept, USER_ROLE_COACH } from '@gofan/api/users';
import { ARBITER_PARTNER_NAME } from '@modules/event-integrations_V2/constants/constants';
import { CRMSchoolService } from '@gofan/api/crm-schools';
import { STATUS_CODE_401, STATUS_CODE_403 } from '@app/api/api/constants';
import ErrorBoundary from '@app/commons/ErrorBoundary';
import { RootContext } from '../../../../RootContext';
import LocalStorage from '../../../../LocalStorage';
import { getUser } from '../../../../api/api/api';
import { isEmpty } from '../../../../utils/objectUtils';

const COACH_ALLOWED_PATHS = ['/accounts', '/practice-schedule', '/events'];

export default ({ render, account, selectedSchools, ...routeProps }) => {
  const { authBody, clearAuthorization, setCurrentUser, setEmailDefault, currentUser, setAuthBody, isLoggedIn } =
    React.useContext(RootContext);
  const [initiated, setInitiated] = React.useState(false);
  const unmountRef = React.useRef(false);

  React.useEffect(() => {
    const messageReceive = event => {
      const { storageArea, key } = event;
      let { newValue } = event;
      if (storageArea !== localStorage) return;
      if (key === 'message') {
        newValue = JSON.parse(newValue);
        if (newValue?.command === 'clear') {
          setAuthBody(null);
        }
      }
    };
    if (authBody) {
      window.addEventListener('storage', messageReceive);
      getUser(authBody.user.email, true)
        .then(res => {
          if (unmountRef.current) return;
          LocalStorage.setStorageCurrentUser(res);
          setCurrentUser(res);
          setEmailDefault(authBody.user.email);
        })
        .catch(error => {
          if (unmountRef.current) return;
          if (error.response && [STATUS_CODE_401, STATUS_CODE_403].includes(error.response.status)) {
            clearAuthorization();
          }
        })
        .finally(() => {
          setInitiated(true);
        });
    }

    return () => {
      unmountRef.current = true;
      window.removeEventListener('storage', messageReceive);
    };
  }, [authBody, clearAuthorization]);

  React.useEffect(() => {
    const setupPendoSnippets = () => {
      const { pendo } = window;
      if (isEmpty(currentUser) || isEmpty(pendo)) return;

      const permissions = [];
      const { email, firstName, lastName, role, userAccountContexts } = currentUser;
      const activeAccounts = userAccountContexts.filter(uac => !uac.inactive);

      activeAccounts.forEach(activeAccount => permissions.push(...activeAccount.accountRoles));

      const distinctPermissions = [...new Set(permissions)].join();
      const fullName = getContactName(firstName, lastName).trim();
      const visitorInfo = {
        id: email,
        email,
        full_name: fullName || undefined,
        role,
        permission: isEmpty(distinctPermissions) ? undefined : distinctPermissions
      };

      let pendoConfigs = {
        visitor: visitorInfo
      };

      const shouldInitialize = pendo.visitorId !== visitorInfo.id && pendo.isReady && !pendo.isReady();
      if (shouldInitialize) {
        pendo.initialize(pendoConfigs);
      }

      const activeAccount = account || selectedSchools[0];
      if (isEmpty(activeAccount)) return;

      const activeAccountId = activeAccount.id || DEFAULT_ACCOUNT;
      if (activeAccountId !== pendo.accountId) {
        const getArbiterCustomer = INTERNAL_USER_ROLES.includes(role)
          ? undefined
          : EventScheduleService.getEventMonitorIndicator(
              {
                schoolIds: [activeAccountId],
                eventStartDate: '2021-08-01T00:00:00+0000',
                partnerName: ARBITER_PARTNER_NAME
              },
              true
            );

        const getCrmSchool = INTERNAL_USER_ROLES.includes(role)
          ? undefined
          : CRMSchoolService.getCrmSchoolById(activeAccountId);

        Promise.all([getCrmSchool, getArbiterCustomer]).then(([crmSchoolResponse, hasArbiterGames]) => {
          const { id, name, cashless, gofanSchoolType, boxOfficeCustomer } = activeAccount;
          const accountConfig = {
            id,
            name,
            planLevel: cashless || undefined,
            type: gofanSchoolType || undefined,
            arbiterCustomer: isBoolean(hasArbiterGames) ? hasArbiterGames : undefined,
            schoolAgreementStatus: crmSchoolResponse?.validatorLogin || undefined,
            boxOfficeCustomer
          };
          pendoConfigs = {
            ...pendoConfigs,
            account: accountConfig
          };
          if (pendo.visitorId !== visitorInfo.id) {
            pendo.identify(pendoConfigs);
          }
        });
      }
    };

    setupPendoSnippets();
  }, [currentUser, account, selectedSchools]);

  if (isEmpty(authBody)) {
    const prevPathName = routeProps?.location?.pathname;
    const prevPathParams = routeProps?.location?.search;
    if (prevPathName) {
      if (!isLoggedIn) {
        setStoragePathToRedirect(prevPathName + prevPathParams);
      }

      if (prevPathName === PAGES.eventIntegrations.root.path) {
        const queryParams = new URLSearchParams(routeProps?.location?.search);
        const partnerName = queryParams.get('partnerName') ?? '';
        const accountId = queryParams.get('accountId') ?? '';

        if (!!partnerName && !!accountId) {
          setStoragePathToRedirect(PAGES.eventIntegrations.view.calculatedPath({ partnerName, accountId }));
        }
      }
    }

    return <Redirect to='/login' />;
  }

  if (initiated && !isEmpty(currentUser) && !some(COACH_ALLOWED_PATHS, item => routeProps.path.startsWith(item))) {
    const coachInfo = getCoachInfo(currentUser);
    const hasOtherRoles = hasOtherRoleExcept(currentUser, [USER_ROLE_COACH]);

    if (coachInfo && !hasOtherRoles) {
      return <Redirect to={PAGES.accountsV2.root.path} />;
    }
  }

  return (
    <Route
      {...routeProps}
      key='authGuardedRoute'
      render={props => {
        if (!initiated) return <LoadingContent type={LOADING_CONTENT_TYPES.PAGE} />;
        return <ErrorBoundary>{render(props)}</ErrorBoundary>;
      }}
    />
  );
};
