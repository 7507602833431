import React, { useContext, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { capitalize, isEmpty } from 'lodash';

import FormSafeguard from './FormSafeguard';
import { FastFormContext } from './FastFormBase';
import styles from './styles';

const StyledFieldLabelSafeGuard = styled.label`
  ${styles.static.fieldLabelSafeGuard};
`;

const StyledFieldValue = styled.p`
  ${styles.static.fieldValue};
  ${props => props.isNullValue && `color: #999;`}
`;

const getValue = (value, formVal) => {
  if (formVal instanceof Function) {
    return formVal(value);
  }

  return value;
};

export default ({
  label,
  formKey,
  value = null,
  formVal = false,
  titleCase = false,
  nullText = '(N/A)',
  option = null,
  inline = false,
  renderView = false,
  style = null
}) => {
  const fastFormContext = useContext(FastFormContext);
  const formValue = fastFormContext.form[formKey];
  useEffect(() => {
    fastFormContext.updateField(formKey, {
      ...formValue,
      inForm: true
    });
    if (value) {
      fastFormContext.updateField(formKey, {
        ...formValue,
        value,
        inForm: true
      });
    }
  }, []);
  const stringValue = titleCase ? capitalize(formValue.value) : formValue.value;
  const val = getValue(stringValue || nullText, formVal);

  return (
    <FormSafeguard formKey={formKey}>
      <Form.Field inline={inline}>
        <StyledFieldLabelSafeGuard>{label}</StyledFieldLabelSafeGuard>
        <StyledFieldValue isNullValue={isEmpty(stringValue)} style={style}>
          {renderView ? renderView(val) : val}
        </StyledFieldValue>
        {option}
      </Form.Field>
    </FormSafeguard>
  );
};
