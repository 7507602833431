import { useEffect, useRef } from 'react';

export default function useTimeoutEffect(
  callback: Function,
  dependency: any[] | undefined,
  timeout: number | undefined
) {
  const isCancelled = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      if (!isCancelled.current) {
        callback();
      }
    }, timeout);
  }, dependency);

  useEffect(
    () => () => {
      isCancelled.current = true;
    },
    []
  );
}
