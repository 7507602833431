/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import styles from './styles';

const StyledLink = styled(Link)`
  ${styles.styledLink};
`;

export default ({ to = '', width, name, active, setCurrentTab, disable }) => {
  const deativeClass = disable ? 'tab-content-disable' : 'tab-content';
  return (
    <Grid.Column
      width={width}
      textAlign='center'
      verticalAlign='bottom'
      className={active ? 'tab-active' : ''}
    >
      <StyledLink
        to={to}
        onClick={e => {
          if (!to) e.preventDefault();
          if (!disable) {
            setCurrentTab(name);
          }
        }}
        className={active ? 'tab-content-active' : deativeClass}
      >
        {name}
      </StyledLink>
    </Grid.Column>
  );
};
