import React from 'react';
import styled from 'styled-components';
import { Dropdown, Form, Message } from 'semantic-ui-react';
import * as val from './validations';
import PhoneInput from './PhoneInput';
import { StyledFormInput } from './components/styledSemantic';
import common from '../assets/styles/common';

const StyledFieldLabel = styled.label`
  ${common.fieldLabel};
`;

// chris wrote this as a wrapper for inputs, it inspired nick's
// fast forms (and used to be called that)
// we are moving away from using this one.
// ---------------------------------------------//
// Validations
// ---------------------------------------------//
export const fastFormFieldValidation = (instruction, model) => {
  const { label, field } = instruction;
  const readonly = instruction.readOnly || false;
  const curr = model[field];

  if (readonly) return null;

  if (instruction.required) {
    if (!curr) return `${label} - field is required`;
  }

  const validations = instruction.validations || [];
  for (let i = 0; i < validations.length; i += 1) {
    const thisV = validations[i];
    if (thisV === 'alpha') {
      if (!val.alpha(curr)) {
        return `${label} - should only contain alpha characters`;
      }
    } else if (thisV === 'name') {
      if (!val.name(curr)) {
        return `${label} - should only contain alpha and space characters`;
      }
    } else if (thisV === 'email') {
      if (!val.email(curr)) return `${label} - should be a valid email address`;
    } else if (thisV === 'phone') {
      if (!val.phone(curr)) {
        return `${label} - should be a valid phone - (555) 555-5555`;
      }
    }
  }

  return '';
};
export const fastFormValidation = (instructions, model) => {
  const errors = [];
  instructions.forEach(i => {
    const err = fastFormFieldValidation(i, model);
    if (err) errors.push(err);
  });
  if (errors.length === 0) return null;
  return errors;
};

// ---------------------------------------------//
// Generate a single form field
// ---------------------------------------------//
export const FastFormField = ({ instruction, model, callBack }) => {
  /* ---------------------------------------
   * Initialization
   * step 1. make sure we got good data
   * step 2. set some vars
   *-------------------------------------------*/
  if (!instruction) {
    return null;
  }
  if (!instruction.type || instruction.type.length <= 0) {
    return null;
  }

  /* ---------------------------------------
   * The Input Part of the HTML
   * job 1. display a component html
   * job 2. onChange dispatch value update to model
   *-------------------------------------------*/
  const { type, label, field } = instruction;
  const err = fastFormFieldValidation(instruction, model);
  const isError = err && err.length > 0;

  // ---display only---//
  if (type === 'displayonly') {
    return (
      <Form.Field>
        <StyledFieldLabel>{label}</StyledFieldLabel>
        <span id={field} style={{ marginLeft: '12px' }}>
          {model[field]}
        </span>
      </Form.Field>
    );
  }

  // ---number text input---//
  if (type === 'number') {
    const onChangeNumH = event => {
      const valNum = parseInt(event.target.value, 10);
      // eslint-disable-next-line no-param-reassign
      model[field] = valNum;
      callBack(model);
    };
    return (
      <Form.Field>
        <StyledFieldLabel>{label}</StyledFieldLabel>
        <input
          type='number'
          name={field}
          step={instruction.step || 10}
          readOnly={instruction.readOnly}
          value={model[field] ? model[field] : ''}
          placeholder={instruction.placeholder}
          onChange={onChangeNumH}
        />
      </Form.Field>
    );
  }

  // ---text input---//
  if (type === 'text') {
    const onChangeTextH = event => {
      // eslint-disable-next-line no-param-reassign
      model[field] = event.target.value;
      callBack(model);
    };
    return (
      <Form.Field>
        <StyledFieldLabel>{label}</StyledFieldLabel>
        <StyledFormInput
          type='text'
          error={!!isError}
          readOnly={instruction.readOnly}
          value={model[field] ? model[field] : ''}
          placeholder={instruction.placeholder}
          onChange={onChangeTextH}
          required={instruction.required}
        />
      </Form.Field>
    );
  }

  // --- phone input ---//
  if (type === 'phone') {
    const onChangePhoneH = value => {
      // eslint-disable-next-line no-param-reassign
      model[field] = value;
      callBack(model);
    };
    return (
      <PhoneInput
        label={label}
        name={field}
        error={!!isError}
        readOnly={instruction.readOnly}
        value={model[field]}
        placeholder={instruction.placeholder}
        onChange={onChangePhoneH}
        required={instruction.required}
      />
    );
  }

  // ---dropdown input---//
  if (type === 'dropdown') {
    const onChangeDropH = (event, { value }) => {
      if (!value || value.length === 0) {
        // eslint-disable-next-line no-param-reassign
        model[field] = undefined;
      } else {
        // eslint-disable-next-line no-param-reassign
        model[field] = value;
      }
      callBack(model);
    };
    return (
      <Form.Field>
        <StyledFieldLabel>{label}</StyledFieldLabel>
        <Dropdown
          fluid
          search
          selection
          clearable
          placeholder={instruction.placeholder || 'Select'}
          options={instruction.choices}
          onChange={onChangeDropH}
          value={model[field]}
        />
      </Form.Field>
    );
  }

  // ---checkbox---//
  /* todo: implemnt
    if(type === 'checkbox') {
        const onChangeCheckH = (event) =>{
            model[field] = event.target.checked;
            callBack(model);
        };
        //todo: return html
    } */

  return null;
};

// ---------------------------------------------//
// The Fast Field Component
// ---------------------------------------------//
export const FastForms = ({ instructions, model, onChangeH }) => {
  const formFields = instructions.map(instruction => (
    <FastFormField
      key={instruction.field}
      instruction={instruction}
      model={model}
      callBack={() => onChangeH(model)}
    />
  ));
  return <React.Fragment>{formFields}</React.Fragment>;
};
export const FastFormValidationMessage = ({ instructions, model }) => {
  const formErrors = fastFormValidation(instructions, model);
  if (!formErrors || formErrors.length === 0) return null;
  return <Message error header='Data Error' content={formErrors[0]} />;
};
