import PublishLevelRequest from './PublishLevelRequest';

import { isEmpty } from '../../../utils/objectUtils';
import { parseString, parseNumber } from '../../../utils/parseUtils';
import { switchZone, TIME_ZONE_ENUM } from '../../../utils/dateUtils';

class PublishSeasonRequest {
  constructor({
    events,
    config,
    genders,
    levels,
    startDateTime,
    endDateTime,
    accountId,
    activityId,
    reportingLabel,
    name,
    timeZone,
    disableQr,
    delayedReservedSeatReleaseDate,
    seasonInfoUrl,
    seasonRenewalTemplateUrl,
    archived,
    partnerName,
    entered,
    financialAccountId
  }) {
    this.raw = {
      events,
      config,
      levels: isEmpty(levels) ? [] : levels,
      genders: isEmpty(genders) ? [] : genders,
      startDateTime: !startDateTime ? undefined : startDateTime,
      endDateTime: !endDateTime ? undefined : endDateTime,
      accountId: parseString(accountId),
      activityId: parseNumber(activityId),
      reportingLabel: parseString(reportingLabel),
      name: parseString(name),
      disableQr: Boolean(disableQr),
      timeZone: parseString(timeZone),
      delayedReservedSeatReleaseDate: parseString(delayedReservedSeatReleaseDate),
      seasonInfoUrl: parseString(seasonInfoUrl),
      seasonRenewalTemplateUrl: parseString(seasonRenewalTemplateUrl),
      archived,
      partnerName,
      entered,
      financialAccountId
    };
  }

  get events() {
    return this.raw.events;
  }

  get config() {
    return this.raw.config;
  }

  get levels() {
    return this.raw.levels.map(level => new PublishLevelRequest(level));
  }

  get genders() {
    return this.raw.genders;
  }

  get startDateTime() {
    if (!this.raw.startDateTime) return undefined;
    return switchZone(this.raw.startDateTime, this.timeZone);
  }

  get endDateTime() {
    if (!this.raw.endDateTime) return undefined;
    return switchZone(this.raw.endDateTime, this.timeZone);
  }

  get accountId() {
    return isEmpty(this.raw.accountId) ? undefined : this.raw.accountId;
  }

  get activityId() {
    return this.raw.activityId;
  }

  get reportingLabel() {
    return isEmpty(this.raw.reportingLabel) ? undefined : this.raw.reportingLabel;
  }

  get name() {
    return this.raw.name;
  }

  get disableQr() {
    return this.raw.disableQr;
  }

  get timeZone() {
    return !this.raw.timeZone ? TIME_ZONE_ENUM.AMERICA_NEW_YORK : this.raw.timeZone;
  }

  get seasonInfoUrl() {
    return this.raw.seasonInfoUrl;
  }

  get seasonRenewalTemplateUrl() {
    return this.raw.seasonRenewalTemplateUrl;
  }

  get archived() {
    return this.raw.archived;
  }

  get partnerName() {
    return this.raw.partnerName;
  }

  get entered() {
    return this.raw.entered;
  }

  get financialAccountId() {
    return this.raw.financialAccountId;
  }

  toJSON() {
    return {
      config: this.config,
      events: this.events,
      genders: this.genders,
      allDayEvent: this.allDayEvent,
      levels: this.levels.map(level => level.toJSON()),
      startDateTime: this.startDateTime,
      endDateTime: this.endDateTime,
      accountId: this.accountId,
      activityId: this.activityId,
      reportingLabel: this.reportingLabel,
      name: this.name,
      disableQr: this.disableQr,
      timeZone: this.timeZone,
      seasonInfoUrl: this.seasonInfoUrl,
      seasonRenewalTemplateUrl: this.seasonRenewalTemplateUrl,
      archived: this.archived,
      partnerName: this.partnerName,
      entered: this.entered,
      financialAccountId: this.financialAccountId
    };
  }
}

export default PublishSeasonRequest;
