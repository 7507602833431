import { useRef, useState } from 'react';

import { cacheEventFilters, getCachedDashboardEventFilters } from '@events/utils/local-storage-cache.utils';
import { mappingPills } from '@events/utils/event-table.utils';

import type { FormValues } from '@events/components/event-filter-form';
import type { Pill } from '@app/commons/generic-components/filter-pills/filter-pills.component';

export type EventTable = 'ALL_EVENTS' | 'TICKETING' | 'STREAMING';

type EventTableFilters = { [key: string]: FormValues };

type EventTablePills = { [key: string]: Pill[] };

export const EVENT_TABLE: { [key in EventTable]: EventTable } = {
  ALL_EVENTS: 'ALL_EVENTS',
  TICKETING: 'TICKETING',
  STREAMING: 'STREAMING'
};

export const CACHE_EVENT_FILTER_TOKENS: { [key in EventTable]: string } = {
  ALL_EVENTS: 'eventFilters',
  TICKETING: 'ticketingFilters',
  STREAMING: 'streamingFilters'
};

export const EVENT_TABLE_TABS = [
  {
    id: EVENT_TABLE.ALL_EVENTS,
    label: 'All Events'
  },
  {
    id: EVENT_TABLE.TICKETING,
    label: 'Ticketing'
  },
  {
    id: EVENT_TABLE.STREAMING,
    label: 'Streaming'
  }
];

interface UseEventFilterProps {
  isInternalUser: boolean;
  setForceKey: (key: number) => void;
}

export const useEventTableFilter = ({ isInternalUser, setForceKey }: UseEventFilterProps) => {
  const eventTableFilters = useRef<EventTableFilters>({
    [EVENT_TABLE.ALL_EVENTS]: getCachedDashboardEventFilters(isInternalUser, CACHE_EVENT_FILTER_TOKENS.ALL_EVENTS),
    [EVENT_TABLE.TICKETING]: getCachedDashboardEventFilters(isInternalUser, CACHE_EVENT_FILTER_TOKENS.TICKETING),
    [EVENT_TABLE.STREAMING]: getCachedDashboardEventFilters(isInternalUser, CACHE_EVENT_FILTER_TOKENS.STREAMING)
  });
  const eventTablePills = useRef<EventTablePills>({
    [EVENT_TABLE.ALL_EVENTS]: mappingPills(
      getCachedDashboardEventFilters(isInternalUser, CACHE_EVENT_FILTER_TOKENS.ALL_EVENTS),
      true
    ),
    [EVENT_TABLE.TICKETING]: mappingPills(
      getCachedDashboardEventFilters(isInternalUser, CACHE_EVENT_FILTER_TOKENS.TICKETING),
      true
    ),
    [EVENT_TABLE.STREAMING]: mappingPills(
      getCachedDashboardEventFilters(isInternalUser, CACHE_EVENT_FILTER_TOKENS.STREAMING),
      true
    )
  });
  const [eventTableIndex, setEventTableIndex] = useState<number>(0);
  const [eventTable, setEventTable] = useState<EventTable>(EVENT_TABLE.ALL_EVENTS);

  function _onChangeEventTable(index: number) {
    const selected = EVENT_TABLE_TABS[index].id;
    setEventTableIndex(index);
    setEventTable(selected);
  }

  function _onUpdateEventTableFilters(filters: FormValues, kind: EventTable) {
    eventTableFilters.current = { ...eventTableFilters.current, [kind]: filters };
    cacheEventFilters(
      eventTableFilters.current[kind],
      isInternalUser ? 'dashboard' : 'shared',
      CACHE_EVENT_FILTER_TOKENS[kind]
    );
  }

  function _onAddEventPills(pills: Pill[], kind: EventTable) {
    eventTablePills.current = {
      ...eventTablePills.current,
      [kind]: pills
    };
    setForceKey(new Date().getTime());
  }

  function _onClosePill(pill: Pill, kind: EventTable) {
    eventTablePills.current = {
      ...eventTablePills.current,
      [kind]: eventTablePills.current[kind].filter(({ id }) => id !== pill.id)
    };
    setForceKey(new Date().getTime());
  }

  return {
    eventTableIndex,
    eventTable,
    onChangeEventTable: _onChangeEventTable,
    eventTableFilters: eventTableFilters.current,
    eventTablePills: eventTablePills.current,
    onUpdateEventTableFilters: _onUpdateEventTableFilters,
    onAddEventPills: _onAddEventPills,
    onCloseEventPill: _onClosePill
  };
};
