import { SearchAccountsParams, SearchAccountsResult } from './types';

export const RESET_STATE = '@events/RESET_STATE';
export const SEARCH_ACCOUNTS = '@events/SEARCH_ACCOUNTS';
export const SEARCH_ACCOUNTS_SUCCESS = '@events/SEARCH_ACCOUNTS_SUCCESS';

interface ResetStateAction {
  type: typeof RESET_STATE
}

interface SearchAccountsAction {
  type: typeof SEARCH_ACCOUNTS;
  payload: {
    searchParams: SearchAccountsParams
  }
}

interface SearchAccountsSuccessAction {
  type: typeof SEARCH_ACCOUNTS_SUCCESS;
  payload: {
    searchResult: SearchAccountsResult
  }
}

export type AccountListActionTypes =
  ResetStateAction |
  SearchAccountsAction |
  SearchAccountsSuccessAction;
