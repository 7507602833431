// @flow
import UserDTO from '../dto/UserDTO';
import errorDTO from '../dto/ErrorDTO';
import UserDAO from '../dao/UserDAO';
import {
  USER_STATE_PENDING,
  USER_ROLE_FAN,
  USER_ROLE_NONE,
  USER_ROLE_INSIDER,
  INTERNAL_USER_ROLES,
  USER_ROLE_ACCOUNT_USER
} from '../model/user.model';
import { PAGES } from '../../config/routes';
import { isEmpty } from '../../utils/objectUtils';

import { DISTRICT_CONFERENCE_SCHOOL_TYPES } from '@gofan/constants';
import { AccountService } from '@gofan/api';

export const getBasicUserByEmail = (email, configuration, manualErrorHandling) =>
  UserDAO.getBasicUserByEmail(email, configuration, manualErrorHandling);

export const checkPendingEmail = async (email, configuration, manualErrorHandling) => {
  const basicUser = await UserDAO.getBasicUserByEmail(email, configuration, manualErrorHandling);
  if (basicUser instanceof UserDTO && basicUser.role !== USER_ROLE_FAN && basicUser.role !== USER_ROLE_NONE) {
    return basicUser.status === USER_STATE_PENDING;
  }

  return null;
};

export const resendInviation = async email => {
  const response = await UserDAO.resendInviation(email);
  return response && !(response instanceof errorDTO);
};

export const getUserByEmailToken = async emailToken => {
  const response = await UserDAO.getUserByEmailToken(emailToken);

  if (response instanceof UserDTO && response.status === USER_STATE_PENDING) {
    return { status: 200, data: response.toJSON() };
  }

  return { status: 400 };
};

export const checkInternalUser = (role = '') => INTERNAL_USER_ROLES.includes(role);

export const getActiveUserAccountContexts = (userAccountContexts = []) =>
  userAccountContexts.filter(context => !context.inactive);

export const getInsiderUserAccountContext = (role = '', userAccountContexts = []) => {
  if (role !== USER_ROLE_ACCOUNT_USER) return false;
  return userAccountContexts.find(context => !context.inactive && context.accountRoles.includes(USER_ROLE_INSIDER));
};

export const canLogin = user => {
  if (!user.role || user.role === 'FAN' || user.role === 'NONE' || user.status === 'DISABLED') {
    return false;
  }
  if (user.role === 'ACCOUNT_USER') {
    return user.userAccountContexts?.length > 0;
  }
  return true;
};

export const checkPermissionHandlingEvent = ({ viewMode = false, accountId = '', currentUser = {} }) => {
  const isInternalUser = checkInternalUser(currentUser.role);
  const userAccountContexts = currentUser.userAccountContexts || [];
  if (isInternalUser) return true;
  if (!accountId) return false;

  return userAccountContexts.some(context => {
    if (viewMode) {
      return context.accountId === accountId && !context.inactive;
    }
    return (
      context.accountId === accountId &&
      !context.inactive &&
      (context.accountRoles.indexOf('EDITOR') !== -1 ||
        context.accountRoles.indexOf('COMMISSIONER_VIEWER') !== -1 ||
        context.accountRoles.indexOf('COMMISSIONER_EDITOR') !== -1)
    );
  });
};

export const getRootEventPath = ({ currentUser = {}, accounts = [], enableDistrictUnicorn = false }) => {
  if (checkInternalUser(currentUser?.role)) return PAGES.events.account.path;

  if (enableDistrictUnicorn && accounts?.some(acc => AccountService.isDistrictSchool(acc)))
    return PAGES.events.account.path;

  return PAGES.eventsV2.viewAllEvents.path;
};

export const getRootSeasonPath = ({ currentUser = {}, accounts = [], enableDistrictUnicorn = false }) => {
  const isInternalUser = !isEmpty(currentUser) && checkInternalUser(currentUser?.role);

  if (isInternalUser) return PAGES.season.account.path;

  if (enableDistrictUnicorn && !isEmpty(accounts)) {
    const districtAccount = accounts?.find(
      acc => acc?.gofanSchoolType === DISTRICT_CONFERENCE_SCHOOL_TYPES.SCHOOL_DISTRICT
    );

    if (!isEmpty(districtAccount)) return PAGES.season.account.path;
  }

  return PAGES.season.viewAllSeasons.path;
};

export const getRootTournamentPath = (user = {}) => {
  const isInternalUser = !isEmpty(user) && checkInternalUser(user.role);
  return isInternalUser ? PAGES.tournaments.account.path : PAGES.tournaments.root.path;
};

export default {
  getRootEventPath,
  getRootSeasonPath,
  resendInviation,
  checkPendingEmail,
  checkInternalUser,
  getUserByEmailToken,
  getInsiderUserAccountContext,
  getActiveUserAccountContexts,
  checkPermissionHandlingEvent,
  canLogin
};
