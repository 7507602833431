import type { Activity, Level, Account } from '../../api/types';
import type { EventDetail, SearchAccountProductTypesResult, SearchAccountProductTypesParams } from './types';

export const PREPARE_DATA = '@EventDetail/PREPARE_DATA';
export const PREPARE_DATA_COMPLETED = '@EventDetail/PREPARE_DATA_COMPLETED';

export const ON_PUBLISH_EVENT = '@EventDetail/ON_PUBLISH_EVENT';
export const ON_PUBLISH_EVENT_COMPLETED = '@EventDetail/ON_PUBLISH_EVENT_COMPLETED';
export const RESET_STATE = '@EventDetail/RESET_STATE';
export const SEARCH_OPPONENT_ACCOUNTS = '@EventDetail/SEARCH_OPPONENT_ACCOUNTS';
export const SEARCH_OPPONENT_ACCOUNTS_SUCCESS = '@EventDetail/SEARCH_OPPONENT_ACCOUNTS_SUCCESS';
export const SEARCH_ACCOUNT_PRODUCT_TYPES = '@EventDetail/SEARCH_ACCOUNT_PRODUCT_TYPES';
export const SEARCH_ACCOUNT_PRODUCT_TYPES_COMPLETED = '@EventDetail/SEARCH_ACCOUNT_PRODUCT_TYPES_COMPLETED';

export const FETCH_ACCOUNT_SUCCESSFUL = '@EventDetail/FETCH_ACCOUNT_SUCCESSFUL';
export const FETCH_ACCOUNT_COMPLETED = '@EventDetail/FETCH_ACCOUNT_COMPLETED';
export const FETCH_ACCOUNT = '@EventDetail/FETCH_ACCOUNT';
export const UPDATE_REQUIRED_FIELDS = '@EventDetail/UPDATE_REQUIRED_FIELDS';
export const UPDATE_OPTIONAL_FIELDS = '@EventDetail/UPDATE_OPTIONAL_FIELDS';
export const RESET_PUBLISH_STATE = '@EventDetail/RESET_PUBLISH_STATE';
export const ON_CREATE_EVENT_SUCCESS = '@EventDetail/ON_CREATE_EVENT_SUCCESS';
export const TOGGLE_NOTIFICATION = '@EventDetail/TOGGLE_NOTIFICATION';
export const ON_SET_PERMISSION = '@EventDetail/ON_SET_PERMISSION';
interface PrepareDataAction {
  type: typeof PREPARE_DATA;
  payload: {
    mode: string;
    history: any;
    currentUser: any;
    eventId: number | string;
    compareEvent?: any;
  };
}
interface PrepareDataCompletedAction {
  type: typeof PREPARE_DATA_COMPLETED;
  payload: {
    event: EventDetail;
    levels: Level[];
    athleticActivities: Activity[];
    nonAthleticActivities: Activity[];
  };
  error: any;
}

interface PublishEventAction {
  type: typeof ON_PUBLISH_EVENT;
  payload: {
    activityType: string;
    mode: string;
    history: Object;
    event: Object;
    currentUser: Object;
    requiredData: Object;
    optionalData: Object;
    ticketTypeData: Object;
    streamingData?: Object;
  };
}

interface PublishEventCompletedAction {
  type: typeof ON_PUBLISH_EVENT_COMPLETED;
  payload: {
    updatedFields: any;
  };
  error: any;
  response: any;
}

interface SearchOpponentAccountsAction {
  type: typeof SEARCH_OPPONENT_ACCOUNTS;
  payload: {
    keyword: string;
  };
}

interface SearchOpponentAccountsSuccessAction {
  type: typeof SEARCH_OPPONENT_ACCOUNTS_SUCCESS;
  payload: {
    accounts: Account[];
  };
}

interface SearchAccountProductTypesAction {
  type: typeof SEARCH_ACCOUNT_PRODUCT_TYPES;
  payload: {
    searchParams: SearchAccountProductTypesParams;
  };
}
interface SearchAccountProductTypesCompletedAction {
  type: typeof SEARCH_ACCOUNT_PRODUCT_TYPES_COMPLETED;
  payload: {
    searchResult: SearchAccountProductTypesResult;
  };
}

interface FetchAccountAction {
  type: typeof FETCH_ACCOUNT;
  payload: {
    accountId: string;
  };
}

interface FetchAccountSuccessfulAction {
  type: typeof FETCH_ACCOUNT_SUCCESSFUL;
  payload: {
    account: Account;
  };
}

interface FetchAccountCompletedAction {
  type: typeof FETCH_ACCOUNT_COMPLETED;
}

interface UpdateRequiredFieldsAction {
  type: typeof UPDATE_REQUIRED_FIELDS;
  payload: {
    requiredFields: any;
  };
}

interface UpdateOptionalFieldsAction {
  type: typeof UPDATE_OPTIONAL_FIELDS;
  payload: {
    optionalFields: any;
  };
}

interface ResetStateAction {
  type: typeof RESET_STATE;
}

interface resetPublishStateAction {
  type: typeof RESET_PUBLISH_STATE;
}

interface CreateEventSuccessAction {
  type: typeof ON_CREATE_EVENT_SUCCESS;
}

interface SetHasPermissionAction {
  type: typeof ON_SET_PERMISSION;
  payload: {
    hasPermission: boolean;
  };
}

export type EventDetailActionTypes =
  | PrepareDataAction
  | PrepareDataCompletedAction
  | PublishEventAction
  | PublishEventCompletedAction
  | SearchOpponentAccountsAction
  | FetchAccountAction
  | UpdateRequiredFieldsAction
  | UpdateOptionalFieldsAction
  | FetchAccountCompletedAction
  | FetchAccountSuccessfulAction
  | SearchOpponentAccountsSuccessAction
  | SearchAccountProductTypesAction
  | SearchAccountProductTypesCompletedAction
  | ResetStateAction
  | resetPublishStateAction
  | CreateEventSuccessAction
  | SetHasPermissionAction;
