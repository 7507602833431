import { useCallback } from 'react';
import { Tag } from 'carbon-components-react';
import { isFunction, isNil, uniqueId } from 'lodash';

export type Pill = {
  id: string | number;
  text?: string;
  filter?: boolean;
  type: string;
  value?: string[];
  disabled?: boolean;
};

interface FilterPillsProps {
  pills: Pill[];
  // eslint-disable-next-line no-unused-vars
  onClosePill?: (item: Pill) => void;
  isDisabledAll?: boolean;
}

const FilterPills = ({ pills = [], onClosePill, isDisabledAll }: FilterPillsProps) => {
  const onClosedPill = useCallback(
    (item: Pill) => () => isFunction(onClosePill) && onClosePill(item),
    [pills, onClosePill]
  );

  return (
    <div className='filter-pills'>
      {pills.map(
        item =>
          !isNil(item) && (
            <Tag
              key={item.id || uniqueId('filter-pills')}
              type='cool-gray'
              title='Clear Filter'
              filter={item.filter || true}
              disabled={item.disabled || isDisabledAll}
              onClose={onClosedPill(item)}
            >
              {item.text && <p className='filter-pills--text gs--font-family-san gs--label-01 gs--text'>{item.text}</p>}
            </Tag>
          )
      )}
    </div>
  );
};

export default FilterPills;
