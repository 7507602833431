import { EMAIL_REGEX, US_PHONE_NUMBER_REGEX2 } from '@gofan/utils';
import * as yup from 'yup';
import {
  REQUEST_CONTACT_TYPES,
  STEAMING_TYPE_OF_ISSUE,
  TICKETING_TYPE_OF_ISSUE,
  ZENDESK_SUPPORT_FORM_FIELDS
} from './zendesk.constant';

export const ZendeskSupportDefaultSchema = yup.object().shape({
  [ZENDESK_SUPPORT_FORM_FIELDS.REQUEST_CONTACT_TYPE]: yup.string().required('Please select a related issue type'),
  [ZENDESK_SUPPORT_FORM_FIELDS.SUPPORT_DIVISION]: yup.string().required('Please select a request contact type'),
  [ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_REQUESTOR_EMAIL]: yup
    .object()
    .shape({
      email: yup.string().required('Required'),
      firstName: yup.string(),
      lastName: yup.string()
    })
    .when(ZENDESK_SUPPORT_FORM_FIELDS.REQUEST_CONTACT_TYPE, ([requestContactType]) =>
      requestContactType === REQUEST_CONTACT_TYPES.SCHOOL
        ? yup.object().shape({
            email: yup.string().required('Required')
          })
        : yup.object().shape({
            email: yup.string()
          })
    )
    .required('Required'),
  [ZENDESK_SUPPORT_FORM_FIELDS.NAME]: yup.string().required('Required'),
  [ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_NAME]: yup.object().shape({
    id: yup.string().nullable(),
    city: yup.string().nullable(),
    state: yup.string().nullable(),
    name: yup.string().required('Required')
  }),
  [ZENDESK_SUPPORT_FORM_FIELDS.REQUESTOR_EMAIL]: yup
    .string()
    .matches(EMAIL_REGEX, 'Invalid email address')
    .required('Required'),
  [ZENDESK_SUPPORT_FORM_FIELDS.PHONE_NUMBER]: yup
    .string()
    .matches(US_PHONE_NUMBER_REGEX2, 'Phone number is not valid. Format: (xxx) xxx-xxxx')
    .required('Required'),
  [ZENDESK_SUPPORT_FORM_FIELDS.PREFERRED_CONTACT_METHOD]: yup
    .string()
    .when(ZENDESK_SUPPORT_FORM_FIELDS.REQUEST_CONTACT_TYPE, ([requestContactType]) =>
      requestContactType === REQUEST_CONTACT_TYPES.SCHOOL ? yup.string().required('Required') : yup.string()
    ),
  [ZENDESK_SUPPORT_FORM_FIELDS.UPDATE_REQUEST]: yup.boolean(),
  [ZENDESK_SUPPORT_FORM_FIELDS.GAME_ID]: yup.string()
});

export const ZendeskSupportStreamingSchema = yup.object().shape({
  [ZENDESK_SUPPORT_FORM_FIELDS.NEXT_EVENT_DATE]: yup.date().nullable().required('Required'),
  [ZENDESK_SUPPORT_FORM_FIELDS.ISSUE_DESCRIPTION]: yup.string().required('Required'),
  [ZENDESK_SUPPORT_FORM_FIELDS.ATTACHMENTS]: yup
    .mixed<File[]>()
    .test('file-validation', 'Invalid file type, only image/jpeg, image/png are supported.', files => {
      if (files && files.length > 0) {
        const hasInvalidFile = files.some((file: File) => {
          if (!['image/jpeg', 'image/png'].includes(file.type)) {
            return true;
          }
          return false;
        });
        return !hasInvalidFile;
      }
      return true;
    })
    .test('file-size-validation', 'One or more files exceed the 500KB size limit.', files => {
      if (files && files.length > 0) {
        return files.every((file: File) => file.size <= 500 * 1024);
      }
      return true;
    }),
  [ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_STREAMING]: yup
    .object({
      text: yup.string().required(),
      value: yup
        .string()
        .oneOf(STEAMING_TYPE_OF_ISSUE.map(option => option.value))
        .required()
    })
    .nullable()
    .transform(value => value || null)
    .required(),
  [ZENDESK_SUPPORT_FORM_FIELDS.URGENT_REQUEST]: yup.boolean(),
  [ZENDESK_SUPPORT_FORM_FIELDS.ENCODING_BROADCAST_SOFTWARE]: yup
    .object()
    .when(ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_STREAMING, ([streamingIssue]) =>
      streamingIssue.text === 'Manual broadcast'
        ? yup.object().shape({
            text: yup.string().required('Required'),
            value: yup.string().required('Required')
          })
        : yup.object().shape({
            text: yup.string(),
            value: yup.string()
          })
    ),
  [ZENDESK_SUPPORT_FORM_FIELDS.PIXELLOT_LOCATION]: yup
    .string()
    .when(ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_STREAMING, ([streamingIssue]) =>
      streamingIssue.text === 'Pixellot issue' ? yup.string().required('Required') : yup.string()
    ),
  [ZENDESK_SUPPORT_FORM_FIELDS.PIXELLOT_ISSUES]: yup
    .object()
    .when(ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_STREAMING, ([streamingIssue]) =>
      streamingIssue.text === 'Pixellot issue'
        ? yup.object().shape({
            text: yup.string().required('Required'),
            value: yup.string().required('Required')
          })
        : yup.object().shape({
            text: yup.string(),
            value: yup.string()
          })
    )
});

export const ZendeskSupportTicketingSchema = yup.object().shape({
  [ZENDESK_SUPPORT_FORM_FIELDS.NEXT_EVENT_DATE]: yup.date().nullable().required('Required'),
  [ZENDESK_SUPPORT_FORM_FIELDS.ISSUE_DESCRIPTION]: yup.string().required('Required'),
  [ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_TICKETING]: yup
    .object({
      text: yup.string().required(),
      value: yup
        .string()
        .oneOf(TICKETING_TYPE_OF_ISSUE.map(option => option.value))
        .required()
    })
    .nullable()
    .transform(value => value || null)
    .required(),
  [ZENDESK_SUPPORT_FORM_FIELDS.PLAYOFF_EVENT_TICKETING]: yup.boolean()
});

export type ZendeskSupportDefault = yup.InferType<typeof ZendeskSupportDefaultSchema>;
export type ZendeskSupportStreaming = yup.InferType<typeof ZendeskSupportStreamingSchema>;
export type ZendeskSupportTicketing = yup.InferType<typeof ZendeskSupportTicketingSchema>;
export type ZendeskSupportForm = Omit<
  ZendeskSupportDefault & ZendeskSupportStreaming & ZendeskSupportTicketing,
  ZENDESK_SUPPORT_FORM_FIELDS.NEXT_EVENT_DATE
> & {
  [ZENDESK_SUPPORT_FORM_FIELDS.NEXT_EVENT_DATE]: Date | null;
};
