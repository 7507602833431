import { DatePicker as CarbonDatePicker, DatePickerInput as CarbonDatePickerInput } from '@carbon/react';
import classNames from 'classnames';

import './DatePicker.scss';

export const DATE_PICKER_PLACEHOLDER = 'mm/dd/yyyy';

interface DatePickerProps {
  id: string;
  className?: string;
  ref?: any;
  dateFormat?: string;
  labelText?: string;
  value: Date | string;
  minDate?: string;
  maxDate?: string;
  disabled?: boolean;
  invalid?: boolean;
  invalidText?: string;
  onChange?: (value: Date | string) => void;
  onBlur?: () => void;
  onBlurInput?: (evt?: React.FocusEvent<HTMLInputElement, Element>) => void;
}

export const DatePicker = ({
  id,
  className,
  ref,
  dateFormat,
  labelText = '',
  value,
  minDate = '',
  maxDate = '',
  disabled,
  invalid,
  invalidText,
  onChange,
  onBlur,
  onBlurInput
}: DatePickerProps) => {
  return (
    <CarbonDatePicker
      id={id}
      allowInput
      dateFormat={dateFormat}
      className={classNames('gf--date-picker', className)}
      datePickerType='single'
      minDate={minDate}
      maxDate={maxDate}
      value={value}
      onChange={(date: Date[]) => {
        if (date) _onChange(date[0]);
      }}
      onBlur={_onBlur}
    >
      <CarbonDatePickerInput
        id={`${id}Input`}
        ref={ref}
        labelText={labelText}
        placeholder={DATE_PICKER_PLACEHOLDER}
        disabled={disabled}
        invalid={invalid}
        invalidText={invalidText}
        onBlur={_onBlurInput}
      />
    </CarbonDatePicker>
  );

  function _onChange(date: Date | string) {
    if (typeof onChange === 'function') onChange(date);
  }

  function _onBlur() {
    if (typeof onBlur === 'function') onBlur();
  }

  function _onBlurInput(evt?: React.FocusEvent<HTMLInputElement, Element>) {
    if (typeof onBlurInput === 'function') onBlurInput(evt);
  }
};
