import React, { useCallback, useState, useEffect } from 'react';
import * as colorUtil from 'react-color/lib/helpers';

import { EditableInput } from 'react-color/lib/components/common';
import { oc } from 'ts-optchain';
import { TextInput } from 'carbon-components-react';
import BlockSwatches from './block-watches/block-swatches.component';

export interface ColorPiletteProps {
  onChange: (e: any) => void;
  onSwatchHover?: () => void;
  defaultColor: string;
  colors?: string[];
  disabled: boolean;
  visible?: boolean;
  color?: string;
}

const DEFAULT_COLORS = [
  '#000000',
  '#ffffff',
  '#da1e28',
  '#0f55da',
  '#ff6910',
  '#24a148',
  '#feb822',
  '#736f6f',
  '#002d9c',
  '#0095e6',
  '#cac5c4',
  '#6929c4',
  '#044317',
  '#a2191f',
  '#653d1b'
];

const ColorPilette = ({
  onChange,
  onSwatchHover,
  defaultColor,
  colors,
  disabled = false,
  visible = false,
  color
}: ColorPiletteProps) => {
  const [value, setColor] = useState('');

  useEffect(() => {
    if (defaultColor && colorUtil.isValidHex(`#${defaultColor}`)) {
      setColor(defaultColor);
    }
  }, [defaultColor]);

  useEffect(() => {
    if (visible) setColor(color ?? '');
  }, [color, visible]);

  const handleSwatchSelectionChange = useCallback((hexCode: any, e: any) => {
    setColor(hexCode);
    onChange({
      ...e,
      target: {
        ...e.target,
        value: hexCode.replace('#', '')
      }
    });
  }, []);

  const handleInputChange = useCallback((hexCode: any, e: any) => {
    if (hexCode.trim() === '') {
      handleSwatchSelectionChange('', e);
      return;
    }

    const { hex } = colorUtil.toState(hexCode);

    if (!hex.startsWith('#')) {
      handleSwatchSelectionChange('#ffffff', e);
      return;
    }

    if (!colorUtil.isValidHex(hexCode) && defaultColor) {
      handleSwatchSelectionChange(defaultColor, e);
      return;
    }

    handleSwatchSelectionChange(hex, e);
  }, []);

  const styles = {
    input: {
      width: '100%',
      border: '0px',
      outline: 'none',
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.29,
      letterSpacing: '0.16px',
      color: '#161616'
    }
  };

  return (
    <div className='color-picker'>
      <div className='body'>
        <BlockSwatches
          colors={oc(colors)(DEFAULT_COLORS)}
          onClick={handleSwatchSelectionChange}
          onSwatchHover={onSwatchHover}
          value={value.replace('#', '')}
          disabled={disabled}
        />
        <div className='gs--label-01 gs--text-02 gs--padding-top__sp3 gs--padding-bottom__sp3'>Hex value</div>
        {disabled ? (
          <TextInput id='color-input' labelText='' light disabled defaultValue={value.replace('#', '').toUpperCase()} />
        ) : (
          <div className='input-wapper'>
            <EditableInput
              value={value.replace('#', '')}
              onChange={handleInputChange}
              style={{ input: styles.input }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorPilette;
