import { isEmpty } from '../../../../utils/objectUtils';

export const sortStates = {
  NONE: 'NONE',
  DESC: 'DESC',
  ASC: 'ASC'
};

export const getSortBy = (sortHeader, sortDirection) => {
  if (sortDirection === sortStates.NONE) return {};
  return {
    id: sortHeader,
    desc: sortDirection === sortStates.DESC
  };
};

export const getSortableProps = ({ header, sortBy = {}, isSortable }) => {
  const unSortProps = { isSortHeader: false, sortDirection: sortStates.NONE };
  if (!isSortable || isEmpty(sortBy) || `${header}` !== `${sortBy.id}`) {
    return unSortProps;
  }
  return {
    isSortHeader: true,
    sortDirection: sortBy.desc ? sortStates.DESC : sortStates.ASC
  };
};

export const getNextSortDirection = ({ header, sortBy = {} }) => {
  if (isEmpty(sortBy)) return sortStates.ASC;

  const prevSortable = getSortableProps({ header, sortBy, isSortable: true });
  if (prevSortable.sortDirection === sortStates.NONE) {
    return sortStates.ASC;
  }
  if (prevSortable.sortDirection === sortStates.ASC) {
    return sortStates.DESC;
  }
  return sortStates.NONE;
};

export default {
  sortStates,
  getSortBy,
  getSortableProps,
  getNextSortDirection
};
