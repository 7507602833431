// @flow
import { EVENT_START_TIME_TYPE } from '@app/modules/season-management/constants/constant';

import Ticket from './Ticket';
import Account from './Account';
import Activity from './Activity';
import EventLevel from './EventLevel';
import EventSalesInfo from './EventSalesInfo';
import EventAccountsTicket from './EventAccountsTicket';

import AccountDTO from '../dto/AccountDTO';
import ActivityDTO from '../dto/ActivityDTO';
import EventAccountsTicketDTO from '../dto/EventAccountsTicketDTO';

import { isEmpty } from '../../utils/objectUtils';
import { generateEventName } from '../services/EventService';

class Event {
  constructor(props) {
    this.raw = props;
  }

  get restData() {
    return this.raw.restData;
  }

  get id() {
    return this.raw.id;
  }

  get embedded() {
    return this.raw.embedded;
  }

  get accountId() {
    return this.raw.accountId;
  }

  get account() {
    if (isEmpty(this.raw.account) && isEmpty(this.raw.embedded.account)) {
      return undefined;
    }
    if (!isEmpty(this.raw.account)) {
      return this.raw.account instanceof AccountDTO
        ? new Account(this.raw.account.toJSON()).toJSON()
        : new Account(this.raw.account).toJSON();
    }
    return this.raw.embedded.account instanceof AccountDTO
      ? new Account(this.raw.embedded.account.toJSON()).toJSON()
      : new Account(this.raw.embedded.account).toJSON();
  }

  set account(account) {
    if (!isEmpty(account)) {
      this.raw.account = account instanceof AccountDTO ? account : new AccountDTO(account);
    }
  }

  get accountsTicket() {
    if (!isEmpty(this.raw.accountsTicket)) {
      return this.raw.accountsTicket.map(item =>
        item instanceof EventAccountsTicketDTO
          ? new EventAccountsTicket(item.toJSON()).toJSON()
          : new EventAccountsTicket(item).toJSON()
      );
    }
    return [];
  }

  get activityId() {
    return this.raw.activityId;
  }

  get activityPath() {
    return this.raw.activityPath;
  }

  get activity() {
    if (!isEmpty(this.raw.embedded.activity)) {
      return this.raw.embedded.activity instanceof ActivityDTO
        ? new Activity(this.raw.embedded.activity.toJSON()).toJSON()
        : new Activity(this.raw.embedded.activity).toJSON();
    }
    return undefined;
  }

  get alert() {
    return this.raw.alert;
  }

  get allDayEvent() {
    return this.raw.allDayEvent;
  }

  get archived() {
    return this.raw.archived;
  }

  get customSportName() {
    return this.raw.customSportName;
  }

  get description() {
    return this.raw.description;
  }

  get disableQr() {
    return this.raw.disableQr;
  }

  get disabledForIndividualSale() {
    return this.raw.disabledForIndividualSale;
  }

  get emailMessageId() {
    return this.raw.emailMessageId;
  }

  get enableEventValidation() {
    return this.raw.enableEventValidation;
  }

  get endDateTime() {
    return this.raw.endDateTime;
  }

  get eventNotes() {
    return this.raw.eventNotes;
  }

  get eventTypeId() {
    return this.raw.eventTypeId;
  }

  get eventType() {
    return this.raw.embedded.eventType;
  }

  get eventValidationStartsBefore() {
    return this.raw.eventValidationStartsBefore;
  }

  get featured() {
    return this.raw.featured;
  }

  get featuredAccountIds() {
    return this.raw.featuredAccountIds;
  }

  get financialAccountId() {
    return this.raw.financialAccountId;
  }

  get financialAccount() {
    if (isEmpty(this.raw.financialAccount) && isEmpty(this.raw.embedded.financialAccount)) {
      return undefined;
    }
    if (!isEmpty(this.raw.financialAccount)) {
      return this.raw.financialAccount instanceof AccountDTO
        ? new Account(this.raw.financialAccount.toJSON()).toJSON()
        : new Account(this.raw.financialAccount).toJSON();
    }
    return this.raw.embedded.financialAccount instanceof AccountDTO
      ? new Account(this.raw.embedded.financialAccount.toJSON()).toJSON()
      : new Account(this.raw.embedded.financialAccount).toJSON();
  }

  set financialAccount(financialAccount) {
    if (!isEmpty(financialAccount)) {
      this.raw.financialAccount =
        financialAccount instanceof AccountDTO ? financialAccount : new AccountDTO(financialAccount);
    }
  }

  get formId() {
    return this.raw.formId;
  }

  get headerImage() {
    return this.raw.headerImage;
  }

  get hostAccountId() {
    return this.raw.hostAccountId;
  }

  get hostAccount() {
    if (isEmpty(this.raw.hostAccount) && isEmpty(this.raw.embedded.hostAccount)) {
      return undefined;
    }
    if (!isEmpty(this.raw.hostAccount)) {
      return this.raw.hostAccount instanceof AccountDTO
        ? new Account(this.raw.hostAccount.toJSON()).toJSON()
        : new Account(this.raw.hostAccount).toJSON();
    }
    return this.raw.embedded.hostAccount instanceof AccountDTO
      ? new Account(this.raw.embedded.hostAccount.toJSON()).toJSON()
      : new Account(this.raw.embedded.hostAccount).toJSON();
  }

  set hostAccount(hostAccount) {
    if (!isEmpty(hostAccount)) {
      this.raw.hostAccount = hostAccount instanceof AccountDTO ? hostAccount : new AccountDTO(hostAccount);
    }
  }

  get levels() {
    if (isEmpty(this.raw.levels)) {
      return [];
    }

    const embeddedLevels = isEmpty(this.raw.embedded.levels) ? [] : this.raw.embedded.levels;

    return this.raw.levels.reduce((res, level) => {
      const found = embeddedLevels.find(item => item.id === level.levelId);
      const levelName = isEmpty(found) ? '' : found.name;
      res.push(new EventLevel({ ...level, levelName }).toJSON());
      return res;
    }, []);
  }

  get genders() {
    return this.raw.genders;
  }

  get maxCapacity() {
    return this.raw.maxCapacity;
  }

  get name() {
    if (!isEmpty(this.raw.name)) {
      return this.raw.name;
    }
    return generateEventName(this.account, this.opponentAccount);
  }

  get opponentAccountId() {
    return this.raw.opponentAccountId;
  }

  get opponentAccount() {
    if (isEmpty(this.raw.opponentAccount) && isEmpty(this.raw.embedded.opponentAccount)) {
      return undefined;
    }
    if (!isEmpty(this.raw.opponentAccount)) {
      return this.raw.opponentAccount instanceof AccountDTO
        ? new Account(this.raw.opponentAccount.toJSON()).toJSON()
        : new Account(this.raw.opponentAccount).toJSON();
    }
    return this.raw.embedded.opponentAccount instanceof AccountDTO
      ? new Account(this.raw.embedded.opponentAccount.toJSON()).toJSON()
      : new Account(this.raw.embedded.opponentAccount).toJSON();
  }

  set opponentAccount(opponentAccount) {
    if (!isEmpty(opponentAccount)) {
      this.raw.opponentAccount =
        opponentAccount instanceof AccountDTO ? opponentAccount : new AccountDTO(opponentAccount);
    }
  }

  get partnerId() {
    return this.raw.partnerId;
  }

  get paymentCycle() {
    return this.raw.paymentCycle;
  }

  get postSeason() {
    return this.raw.postSeason;
  }

  get products() {
    if (isEmpty(this.raw.products)) return [];
    return this.raw.products.map(product =>
      new Ticket(
        product,
        isEmpty(this.eventSalesInfo) || isEmpty(this.eventSalesInfo.productSalesMap)
          ? {}
          : this.eventSalesInfo.productSalesMap[product.id],
        this.timeZone
      ).toJSON()
    );
  }

  get publishDateTime() {
    return this.raw.publishDateTime;
  }

  get redemptionWindow() {
    return this.raw.redemptionWindow;
  }

  get reportingLabel() {
    return this.raw.reportingLabel;
  }

  get salesEndDateTime() {
    return this.raw.salesEndDateTime;
  }

  get salesStartDateTime() {
    return this.raw.salesStartDateTime;
  }

  get seasonId() {
    return this.raw.seasonId;
  }

  get specialEventDescription() {
    return this.raw.specialEventDescription;
  }

  get startDateTime() {
    return this.raw.startDateTime;
  }

  get startTimeType() {
    if (isEmpty(this.raw.startTimeType)) return EVENT_START_TIME_TYPE.ALL_TEAMS;
    return this.raw.startTimeType;
  }

  get startTimeOptions() {
    if (isEmpty(this.raw.startTimeOptions)) return {};
    return this.raw.startTimeOptions;
  }

  get taggedAccountIds() {
    return this.raw.taggedAccountIds;
  }

  get taggedAccounts() {
    if (!isEmpty(this.raw.taggedAccounts)) {
      return this.raw.taggedAccounts.map(item =>
        item instanceof AccountDTO ? new Account(item.toJSON()).toJSON() : new Account(item).toJSON()
      );
    }
    return [];
  }

  set taggedAccounts(taggedAccounts) {
    if (!isEmpty(taggedAccounts)) {
      this.raw.taggedAccounts = taggedAccounts.map(item => (item instanceof AccountDTO ? item : new AccountDTO(item)));
    }
  }

  get distributedAccounts() {
    if (!isEmpty(this.raw.distributedAccounts)) {
      return this.raw.distributedAccounts.map(item =>
        item instanceof AccountDTO ? new Account(item.toJSON()).toJSON() : new Account(item).toJSON()
      );
    }
    return [];
  }

  set distributedAccounts(distributedAccounts) {
    if (!isEmpty(distributedAccounts)) {
      this.raw.distributedAccounts = distributedAccounts.map(item =>
        item instanceof AccountDTO ? item : new AccountDTO(item)
      );
    }
  }

  get termsAndConditions() {
    return this.raw.termsAndConditions;
  }

  get theme() {
    return this.raw.theme;
  }

  get ticketDistribution() {
    return this.raw.ticketDistribution;
  }

  get ticketLimitPerOrder() {
    return this.raw.ticketLimitPerOrder;
  }

  get timeZone() {
    return this.raw.timeZone;
  }

  get venueAddress() {
    return this.raw.venueAddress;
  }

  get venueCity() {
    return this.raw.venueCity;
  }

  get venueId() {
    return this.raw.venueId;
  }

  get venueLocation() {
    return this.raw.venueLocation;
  }

  get venueName() {
    return this.raw.venueName;
  }

  get venueState() {
    return this.raw.venueState;
  }

  get venueZip() {
    return this.raw.venueZip;
  }

  get eventSalesInfo() {
    if (!isEmpty(this.raw.embedded.eventSalesInfo)) {
      return this.raw.embedded.eventSalesInfo instanceof EventSalesInfo
        ? this.raw.embedded.eventSalesInfo.toJSON()
        : new EventSalesInfo(this.raw.embedded.eventSalesInfo).toJSON();
    }
    return {};
  }

  toJSON() {
    return {
      ...this.raw,
      restData: this.restData,
      id: this.id,
      embedded: this.embedded,
      accountId: this.accountId,
      account: this.account,
      accountsTicket: this.accountsTicket,
      activityId: this.activityId,
      activityPath: this.activityPath,
      activity: this.activity,
      alert: this.alert,
      allDayEvent: this.allDayEvent,
      archived: this.archived,
      customSportName: this.customSportName,
      description: this.description,
      disableQr: this.disableQr,
      disabledForIndividualSale: this.disabledForIndividualSale,
      emailMessageId: this.emailMessageId,
      enableEventValidation: this.enableEventValidation,
      endDateTime: this.endDateTime,
      eventNotes: this.eventNotes,
      eventTypeId: this.eventTypeId,
      eventValidationStartsBefore: this.eventValidationStartsBefore,
      featured: this.featured,
      featuredAccountIds: this.featuredAccountIds,
      financialAccountId: this.financialAccountId,
      financialAccount: this.financialAccount,
      formId: this.formId,
      headerImage: this.headerImage,
      hostAccountId: this.hostAccountId,
      hostAccount: this.hostAccount,
      genders: this.genders,
      levels: this.levels,
      maxCapacity: this.maxCapacity,
      name: this.name,
      opponentAccountId: this.opponentAccountId,
      opponentAccount: this.opponentAccount,
      partnerId: this.partnerId,
      paymentCycle: this.paymentCycle,
      postSeason: this.postSeason,
      products: this.products,
      publishDateTime: this.publishDateTime,
      redemptionWindow: this.redemptionWindow,
      reportingLabel: this.reportingLabel,
      salesEndDateTime: this.salesEndDateTime,
      salesStartDateTime: this.salesStartDateTime,
      seasonId: this.seasonId,
      specialEventDescription: this.specialEventDescription,
      startDateTime: this.startDateTime,
      startTimeType: this.startTimeType,
      startTimeOptions: this.startTimeOptions,
      taggedAccountIds: this.taggedAccountIds,
      taggedAccounts: this.taggedAccounts,
      distributedAccounts: this.distributedAccounts,
      termsAndConditions: this.termsAndConditions,
      theme: this.theme,
      ticketDistribution: this.ticketDistribution,
      ticketLimitPerOrder: this.ticketLimitPerOrder,
      timeZone: this.timeZone,
      venueAddress: this.venueAddress,
      venueCity: this.venueCity,
      venueId: this.venueId,
      venueLocation: this.venueLocation,
      venueName: this.venueName,
      venueState: this.venueState,
      venueZip: this.venueZip,
      eventSalesInfo: this.eventSalesInfo
    };
  }
}

export default Event;
