import { Level } from './types';
import {
  LevelActionTypes,

  FETCH_LEVELS,
  FETCH_LEVELS_SUCCESSFUL,

  CREATE_LEVEL,
  CREATE_LEVEL_SUCCESSFUL,

  UPDATE_LEVEL,
  UPDATE_LEVEL_SUCCESSFUL,

  DELETE_LEVEL,
  DELETE_LEVEL_SUCCESSFUL,

  DELETE_LEVEL_COMPLETED,
  CREATE_LEVEL_COMPLETED,
  UPDATE_LEVEL_COMPLETED,
  FETCH_LEVELS_COMPLETED
} from './actionTypes';

export function createLevelCompleted(): LevelActionTypes {
  return {
    type: CREATE_LEVEL_COMPLETED
  }
}

export function updateLevelCompleted(): LevelActionTypes {
  return {
    type: UPDATE_LEVEL_COMPLETED
  }
}

export function deleteLevelCompleted(): LevelActionTypes {
  return {
    type: DELETE_LEVEL_COMPLETED
  }
}

export function fetchLevelsCompleted(): LevelActionTypes {
  return {
    type: FETCH_LEVELS_COMPLETED
  }
}

export function fetchLevels(): LevelActionTypes {
  return {
    type: FETCH_LEVELS
  }
}

// TypeScript infers that this function is returning FetchLevelsSuccessfulAction
export function fetchLevelsSuccessful(levels: Level[]
  ): LevelActionTypes {
  return {
    type: FETCH_LEVELS_SUCCESSFUL,
    payload: {
      levels
    }
  }
}

// TypeScript infers that this function is returning CreateLevelAction
export function createLevel(level: Level): LevelActionTypes {
  return {
    type: CREATE_LEVEL,
    payload: {
      level
    }
  }
}

// TypeScript infers that this function is returning CreateLevelSuccessful
export function createLevelSuccessful(levels: Level[]
  ): LevelActionTypes {
  return {
    type: CREATE_LEVEL_SUCCESSFUL,
    payload: {
      levels
    }
  }
}

// TypeScript infers that this function is returning UpdateLevelAction
export function updateLevel(level: Level): LevelActionTypes {
  return {
    type: UPDATE_LEVEL,
    payload: {
      level
    }
  }
}

// TypeScript infers that this function is returning UpdateLevelsSuccessfulAction
export function updateLevelSuccessful(levels: Level[]): LevelActionTypes {
  return {
    type: UPDATE_LEVEL_SUCCESSFUL,
    payload: {
      levels
    }
  }
}

// TypeScript infers that this function is returning DeleteLevelAction
export function deleteLevel(level: Level): LevelActionTypes {
  return {
    type: DELETE_LEVEL,
    payload: {
      level
    }
  }
}

// TypeScript infers that this function is returning DeleteLevelSuccessfulAction
export function deleteLevelSuccessful(levels: Level[]): LevelActionTypes {
  return {
    type: DELETE_LEVEL_SUCCESSFUL,
    payload: {
      levels
    }
  }
}