import { huddleAxiosInstanceProxy } from '../shared/axios';
import { ACTIVITY_URI } from './activity.endpoints';

import type { ActivityDTO } from './activity.model';

export class ActivityRepository {
  static getAllActivities = (): Promise<ActivityDTO[]> => huddleAxiosInstanceProxy.get(ACTIVITY_URI);

  static getActivityById = (activityId: string): Promise<ActivityDTO> =>
    huddleAxiosInstanceProxy.get(`${ACTIVITY_URI}${activityId}`);
}
