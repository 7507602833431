import { useMemo, VFC } from 'react';

import { DISTRIBUTION_CHANNEL } from '@app/api/model/request/ProductRequest';
import { TicketSaleData } from '@events/models/event.model';
import { formatNumber } from '@events/components/ticket-sale-chart/helper';
import { isEmptyString } from '@app/utils/stringUtils';

import MeterChart from '@app/commons/generic-components/meter-chart/meter-chart';
import TicketSaleNumericalChart from '../ticket-sale-numerical-chart/ticket-sale-numerical-chart';

interface TicketSaleByTypeNumericalProps {
  data: TicketSaleData[];
  isFundraiser?: boolean;
}

const TicketSaleByTypeNumerical: VFC<TicketSaleByTypeNumericalProps> = ({ data, isFundraiser = false }) => {
  const totalTicketsSold: number = useMemo(() => data.reduce((acc, cur) => acc + cur.ticketSold, 0), [data]);
  const totalTicketsSale: number = useMemo(() => data.reduce((acc, cur) => acc + cur.ticketSale, 0), [data]);
  const displayDistributionChannel: boolean = useMemo(
    () =>
      Object.values(DISTRIBUTION_CHANNEL).every(d =>
        data
          .filter(t => t.ticketSold > 0)
          .map(t => t.distributionChannel)
          .includes(d)
      ),
    [data]
  );

  return (
    <>
      <TicketSaleNumericalChart
        isTextAbove
        isFundraiser={isFundraiser}
        totalTicketsSold={totalTicketsSold}
        totalTicketsSale={totalTicketsSale}
      />
      {data.map((ticket, index) => {
        return (
          <MeterChart
            animate
            key={ticket.type + index}
            className='gs--margin-top-sp2'
            label={ticket.type + (displayDistributionChannel ? ` (${ticket.distributionChannel})` : '')}
            value={100}
            fill={_getBarColor(ticket)}
            suffix={`$${formatNumber(ticket.ticketSale || 0)}`}
            extraSuffix={formatNumber(ticket.ticketSold || 0)}
          />
        );
      })}
    </>
  );

  function _getBarColor(ticket: TicketSaleData) {
    if (isFundraiser) {
      return ticket?.customColor ? `#${ticket.customColor}` : '#000000';
    }
    return !isEmptyString(ticket.school?.primaryColor) ? `#${ticket.school?.primaryColor}` : '#000000';
  }
};

export default TicketSaleByTypeNumerical;
