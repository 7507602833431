import {
  FanSupportActionTypes,
  SET_CURRENT_TAB,
  RESET_STATE,
  SEARCH_TICKETS,
  SEARCH_TICKETS_SUCCESS,
  REFUND_TICKETS,
  REFUND_TICKETS_COMPLETED,
  SEARCH_EVENT_REFUND,
  SEARCH_EVENT_REFUND_COMPLETED,
  SET_EVENT_REFUND,
  REFUND_BY_EVENT,
  REFUND_BY_EVENT_COMPLETED,
  REFUND_BY_EVENT_IN_PROCCESS
} from './actionTypes';
import { FanSupportState } from './types';
import { PAGES } from '../../config/routes';
import { FAN_SUPPORT_TABS, FAN_SUPPORT_TAB_KEYS } from './constants';

export const initialState: FanSupportState = {
  pageIndex: 0,
  pageCount: 1,
  events: [],
  tickets: [],
  isSearching: false,
  isSearched: false,
  isRefunding: false,
  tab: FAN_SUPPORT_TABS[0].name,
  eventIdInProccess: ''
};

export default (state = initialState, action: FanSupportActionTypes) => {
  switch (action.type) {
    case RESET_STATE: {
      return { ...initialState };
    }

    case SET_CURRENT_TAB: {
      return { ...state, tab: action.payload.tab };
    }

    case SEARCH_TICKETS:
    case SEARCH_EVENT_REFUND: {
      return {
        ...state,
        isSearching: true
      };
    }

    case SEARCH_TICKETS_SUCCESS: {
      if (
        state.tab ===
        FAN_SUPPORT_TAB_KEYS[PAGES.fanSupport.purchasedTickets.path]
      ) {
        const { searchResult } = action.payload;
        const { pageIndex, pageCount, data } = searchResult;
        return {
          ...state,
          isSearching: false,
          isSearched: true,
          pageIndex,
          pageCount,
          tickets: data
        };
      }
      return { ...state };
    }

    case SEARCH_EVENT_REFUND_COMPLETED: {
      if (state.tab === FAN_SUPPORT_TAB_KEYS[PAGES.fanSupport.events.path]) {
        const { searchResult } = action.payload;
        const { pageIndex, pageCount, data } = searchResult;
        return {
          ...state,
          isSearching: false,
          isSearched: true,
          pageIndex,
          pageCount,
          events: data
        };
      }
      return { ...state };
    }

    case SET_EVENT_REFUND: {
      const { events } = action.payload;
      return {
        ...state,
        events
      };
    }

    case REFUND_TICKETS:
    case REFUND_BY_EVENT: {
      return {
        ...state,
        isRefunding: true
      };
    }

    case REFUND_TICKETS_COMPLETED:
    case REFUND_BY_EVENT_COMPLETED: {
      return {
        ...state,
        isRefunding: false
      };
    }
    case REFUND_BY_EVENT_IN_PROCCESS: {
      const { eventId } = action.payload;
      return {
        ...state,
        eventIdInProccess: eventId
      };
    }
    default:
      return state;
  }
};
