// @flow
import { ActivityService } from '@gofan/api/activities';
import ActivityDAO from '../dao/ActivityDAO';
import Activity from '../model/Activity';

import { isEmpty } from '../../utils/objectUtils';

const fetchAllActivities = () => ActivityDAO.fetchAllActivities();

const createActivity = activity => ActivityDAO.createActivity(activity);

const updateActivity = activity => ActivityDAO.updateActivity(activity);

const deleteActivity = id => ActivityDAO.deleteActivity(id);

const ACTIVITY_ITEMS = {
  ATHLETIC: {
    key: 'ATHLETIC',
    text: 'Athletic',
    disabled: true,
    className: 'dropdown-header-item'
  },
  NON_ATHLETIC: {
    key: 'NON_ATHLETIC',
    text: 'Non Athletic',
    disabled: true,
    className: 'dropdown-header-item'
  }
};

const getActivityOptions = ({
  queryStr = '',
  athleticActivities = [],
  nonAthleticActivities = [],
  activityItems = ACTIVITY_ITEMS
}) => {
  const athleticOptions = athleticActivities.reduce((res, activity) => {
    if (!activity.disabled && activity.label.toLowerCase().includes(queryStr)) {
      res.push({
        value: activity,
        key: activity.id,
        text: activity.label,
        className: 'dropdown-sub-item'
      });
    }
    return res;
  }, []);
  const nonAthleticOptions = nonAthleticActivities.reduce((res, activity) => {
    if (!activity.disabled && activity.label.toLowerCase().includes(queryStr)) {
      res.push({
        value: activity,
        key: activity.id,
        text: activity.label,
        className: 'dropdown-sub-item'
      });
    }
    return res;
  }, []);
  const athleticHeader = athleticOptions.length ? [activityItems.ATHLETIC] : [];
  const nonAthleticHeader = nonAthleticOptions.length ? [activityItems.NON_ATHLETIC] : [];

  return [...athleticHeader, ...athleticOptions, ...nonAthleticHeader, ...nonAthleticOptions];
};

const groupActivityByType = (activities = []) => {
  let athleticActivities = [];
  let nonAthleticActivities = [];
  if (activities instanceof Array && !isEmpty(activities)) {
    const totalList = { athleticActivities, nonAthleticActivities };
    const newActivities = activities.reduce((data, currentActivity) => {
      const activity = new Activity(currentActivity).toJSON();
      if (!activity.disabled) {
        activity.athletic ? data.athleticActivities.push(activity) : data.nonAthleticActivities.push(activity);
      }
      return data;
    }, totalList);

    athleticActivities = newActivities.athleticActivities.sort((a, b) => a.label.localeCompare(b.label));
    nonAthleticActivities = newActivities.nonAthleticActivities.sort((a, b) => a.label.localeCompare(b.label));
  }
  return { athleticActivities: ActivityService.bubbleUpCommonSports(athleticActivities), nonAthleticActivities };
};

export default {
  ACTIVITY_ITEMS,
  getActivityOptions,
  fetchAllActivities,
  groupActivityByType,
  createActivity,
  updateActivity,
  deleteActivity
};
