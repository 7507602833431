import { Controller, useFormContext } from 'react-hook-form';
import { Button, Dropdown, Icons, TextArea, TextInput, Toggle, InlineLoading, DatePicker } from '../../..';
import { PendoSupportFormProgress } from '../../PendoSupportForm';
import { TICKETING_TYPE_OF_ISSUE, ZENDESK_SUPPORT_FORM_FIELDS } from '@gofan/api';
import { AttachmentsField } from '../shared-fields/AttachmentsField';

import '../../PendoSupportForm.scss';

interface PendoSupportFormTicketingProps {
  onBack: (nextProgress: PendoSupportFormProgress) => void;
  isSubmitting: boolean;
}

// this form is shared between HQ desktop and HQ Mobile, the margin classes are different for each
const PendoSupportFormTicketing = ({ onBack, isSubmitting = false }: PendoSupportFormTicketingProps) => {
  const { control } = useFormContext();

  return (
    <>
      <div className='gf-sub-title'>Tell us more about your issue</div>
      <Controller
        name={ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_TICKETING}
        control={control}
        render={({ field, fieldState }) => (
          <Dropdown
            {...field}
            titleText='Select type of issue'
            className='gs--margin-bottom-sp5 gs--margin-top-sp5 gs--margin-bottom__sp5 gs--margin-top__sp5 '
            id={ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_TICKETING}
            items={TICKETING_TYPE_OF_ISSUE}
            itemToString={(item: { text: string; value: string }) => (item ? item.text : '')}
            label='Choose an option'
            invalid={!!fieldState.error}
            invalidText='Required'
            selectedItem={field.value || ''}
            onChange={({ selectedItem }: { selectedItem: { text: string; value: string } }) => {
              field.onChange(selectedItem);
            }}
          />
        )}
      />

      <Controller
        name={ZENDESK_SUPPORT_FORM_FIELDS.EVENT_IDS}
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            {...field}
            id={ZENDESK_SUPPORT_FORM_FIELDS.EVENT_IDS}
            labelText='Enter Event ID(s) if applicable'
            placeholder='Event ID(s)'
            invalid={!!fieldState.error}
            invalidText={fieldState.error?.message}
            className='gs--margin-bottom-sp5 gs--margin-bottom__sp5'
          />
        )}
      />

      <div className='gf-sub-title2 gs--margin-bottom-sp5 gs--margin-bottom__sp5'>
        When is your next event that you need this issue resolved by?
      </div>

      <Controller
        name={ZENDESK_SUPPORT_FORM_FIELDS.NEXT_EVENT_DATE}
        control={control}
        render={({ field, fieldState }) => (
          <DatePicker
            {...field}
            id={ZENDESK_SUPPORT_FORM_FIELDS.NEXT_EVENT_DATE}
            labelText='Enter Date'
            invalid={!!fieldState.error}
            invalidText={fieldState.error?.message}
            className='gs--margin-bottom-sp5 gs--margin-bottom__sp5'
            onChange={date => {
              field.onChange(date);
              document.getElementById(ZENDESK_SUPPORT_FORM_FIELDS.NEXT_EVENT_DATE)?.blur();
            }}
          />
        )}
      />

      <Controller
        name={ZENDESK_SUPPORT_FORM_FIELDS.ISSUE_DESCRIPTION}
        control={control}
        render={({ field, fieldState }) => (
          <TextArea
            {...field}
            id={ZENDESK_SUPPORT_FORM_FIELDS.ISSUE_DESCRIPTION}
            labelText='Description of Issue'
            placeholder='Please add description of issue'
            invalid={!!fieldState.error}
            invalidText={fieldState.error?.message}
            className='gs--margin-bottom-sp5 gs--margin-bottom__sp5'
          />
        )}
      />

      <div className='gf-sub-title2 gs--margin-bottom-sp1 gs--margin-bottom__sp1'>Attach any supporting files</div>
      <div className='cds--label gs--margin-bottom-sp5 gs--margin-bottom__sp5'>
        Max file size is 500kb. Supported file types are .jpg and .png.
      </div>

      <div className='gf-file_upload-container gs--margin-bottom-sp4 gs--margin-bottom__sp4'>
        <Icons.Upload className='gf-upload-icon' />
        <AttachmentsField name={ZENDESK_SUPPORT_FORM_FIELDS.ATTACHMENTS} />
      </div>

      <Controller
        name={ZENDESK_SUPPORT_FORM_FIELDS.PLAYOFF_EVENT_TICKETING}
        control={control}
        render={({ field }) => (
          <Toggle
            {...field}
            id={ZENDESK_SUPPORT_FORM_FIELDS.PLAYOFF_EVENT_TICKETING}
            labelA='No'
            labelB='Yes'
            labelText='This ticket is related to playoffs or a post-season event'
            toggled={field.value}
            onToggle={toggled => field.onChange(toggled)}
          />
        )}
      />

      <div className='gf-button-wrapper gs--margin-bottom-sp5 gs--margin-top-sp6 gs--margin-bottom__sp5 gs--margin-top__sp6'>
        <Button
          type='button'
          kind='secondary'
          className='gs--margin-top-sp5 gs--margin-top__sp5'
          onClick={() => onBack(PendoSupportFormProgress.DEFAULT)}
        >
          Back
        </Button>
        <Button type='submit' className='gs--margin-top-sp5 gs--margin-top__sp5' disabled={isSubmitting}>
          {isSubmitting ? (
            <InlineLoading />
          ) : (
            <>
              Submit Request{' '}
              <Icons.SendAlt className='gs--margin-left-sp3 gs--margin-left__sp3' height='16' width='16' />
            </>
          )}
        </Button>
      </div>
    </>
  );
};

export { PendoSupportFormTicketing };
