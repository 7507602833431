import { useEffect, useState, useCallback } from 'react';

// if you want to manually specify when a component should be mounted, pass { manual: true }
export const useMounted = (params?: { manual?: boolean }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!params?.manual) setIsMounted(true);
  }, []);

  const onMounted = useCallback(() => {
    setIsMounted(true);
  }, []);

  const onUnmounted = useCallback(() => {
    setIsMounted(false);
  }, []);

  return { isMounted, onMounted, onUnmounted };
};
