import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Button,
  Checkbox,
  ComposedModal,
  InlineLoading,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'carbon-components-react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { yupResolver } from '@hookform/resolvers/yup';

import { EventInfo } from '@events/components/event-info';
import { EventSalesInfo } from '@events/components/event-sales-info';
import { EventDateTimeForm as DateTimeForm } from '@app/commons/generic-components/date-time-form';

import { getEventType } from '@app/pages/EventDetail/helpers';
import {
  generateDateTimeValues,
  initDateTimeValues,
  validateDateTime
} from '@app/commons/generic-components/date-time-form/utils';
import { TIME_ZONE_ENUM } from '@utils/dateUtils';

import { EventService } from '@gofan/api';
import type { EventDTO } from '@gofan/api';

import strings from '@events/strings';
import { useEventReschedule } from './use-event-reschedule';
import { useEventCancellations } from '../event-cancellations-modal/use-event-cancellations';

interface EventRescheduleModalProps {
  id?: string;
  event: EventDTO;
  accountIds: string[];
  open: boolean;
  hasSold: boolean;
  onCloseModal: () => void;
  onReloadEvent: () => void;
  addNotification: Function;
  isConcession?: boolean;
}

export const EventRescheduleModal = ({
  id = '',
  event,
  accountIds,
  open,
  hasSold,
  addNotification,
  onCloseModal,
  onReloadEvent,
  isConcession = false
}: EventRescheduleModalProps) => {
  const key = `${id}${event.id}`;
  const activityType = getEventType(event);
  const initFormValues = initDateTimeValues(event);
  const eventLabel = EventService.getEventLabelByType(event);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: initFormValues,
    resolver: yupResolver(validateDateTime)
  });

  const { eventInfo, ticketSaleInsight, errorInsight, sendFansNotification, loading, setSendFansNotification } =
    useEventCancellations({
      open,
      event,
      accountIds,
      addNotification,
      onCloseModal,
      onReloadEvent
    });

  const { rescheduling, rescheduleEvent } = useEventReschedule({ addNotification, onCloseModal, onReloadEvent });

  useEffect(() => {
    if (open) {
      methods.reset(initFormValues);
    }
  }, [open]);

  const isDisabled = () => rescheduling || isEqual(initFormValues, methods.getValues());

  const onSubmitted = () => {
    rescheduleEvent({
      ...generateDateTimeValues(event, methods.getValues()),
      eventId: event.id,
      sendFansNotification
    });
  };

  const handleCloseModal = () => !rescheduling && onCloseModal();

  return (
    <ComposedModal size='lg' open={open} onClose={handleCloseModal} className='event-reschedule-modal'>
      <ModalHeader className='event-reschedule-modal__header gs--ui-01'>
        <div className={classNames('gs--productive-heading-03', 'gs--text-01')}>
          {strings.EVENT_RESCHEDULE.TITLE.replace('{eventType}', eventLabel)}
        </div>
        <div className={classNames('gs--body-long-01', 'gs--text-01', 'gs--padding-top__sp5')}>
          {strings.EVENT_RESCHEDULE.DESCRIPTION.replace('{eventType}', eventLabel)}
        </div>
      </ModalHeader>
      <ModalBody hasForm className='event-reschedule-modal__body'>
        <EventInfo eventInfo={eventInfo} />
        <div className='gs--padding-top__sp5' />
        {!isConcession && (
          <EventSalesInfo ticketSaleInsight={ticketSaleInsight} loading={loading} error={errorInsight} />
        )}
        <div className='event-reschedule-modal__body__divider' />
        <div className='gs--padding-top__sp5 gs--padding-left__sp5 event-reschedule-form'>
          <div className='gs--productive-heading-02 gs--padding-bottom__sp5'>
            {strings.EVENT_RESCHEDULE.RESCHEDULE_HEADER.replace('{eventType}', eventLabel)}
          </div>
          <FormProvider {...methods}>
            <DateTimeForm
              id={key}
              activityType={activityType}
              timezone={event?.timeZone || TIME_ZONE_ENUM.AMERICA_NEW_YORK}
            />
          </FormProvider>
        </div>
        {hasSold && !isConcession && (
          <div className='gs--padding-left__sp5'>
            <Checkbox
              checked={sendFansNotification}
              onChange={() => setSendFansNotification(!sendFansNotification)}
              id={`reschedule-${key}-email-notify`}
              labelText={strings.EVENT_RESCHEDULE.NOTIFICATION_EMAIL}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button kind='secondary' onClick={handleCloseModal}>
          {strings.EVENT_RESCHEDULE.BTN_CLOSE}
        </Button>

        <Button kind='primary' onClick={methods.handleSubmit(onSubmitted)} disabled={isDisabled()}>
          {rescheduling ? (
            <InlineLoading
              className='calling-status'
              status='active'
              description={strings.EVENT_RESCHEDULE.BTN_RESCHEDULE.replace('{eventType}', eventLabel)}
            />
          ) : (
            <>{strings.EVENT_RESCHEDULE.BTN_RESCHEDULE.replace('{eventType}', eventLabel)}</>
          )}
        </Button>
      </ModalFooter>
    </ComposedModal>
  );
};
