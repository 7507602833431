import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Column, Row, SkeletonPlaceholder, SkeletonText } from 'carbon-components-react';
import { isEmpty, isEqual } from 'lodash';

import { RootContext } from '@app/RootContext';
import { generateError } from '@utils/alertUtils';
import { AccountService } from '@gofan/api/accounts';
import { ActivityService } from '@gofan/api/activities';
import { FEATURE_FLAGS, useFeatureFlags } from '@gofan/hooks/useFeatureFlags';

import { SEARCH_PARAMS } from '@seasons/constants/constants';
import SeasonService from '@seasons/services/season.service';

import SeasonGrid from '@seasons/components/season-grid/season-grid.component';

import type { RateDTO } from '@gofan/api/rates';
import type { AccountDTO } from '@gofan/api/accounts';
import type { ActivityDTO } from '@gofan/api/activities';
import type { SchoolConfig } from '@gofan/api/school-config';
import type { SeasonSearchParams } from '@seasons/services/season.service';
import type { SeasonDTO } from '@seasons/models/season.model';

export interface DashboardSeasonsProps {
  history: any;
  rates: RateDTO[];
  accounts: AccountDTO[];
  schoolsConfig?: SchoolConfig[];
  districtAccount?: AccountDTO;
  addNotification: Function;
}

const DashboardSeasons = ({
  history,
  rates,
  accounts,
  schoolsConfig,
  districtAccount,
  addNotification
}: DashboardSeasonsProps) => {
  const { [FEATURE_FLAGS.tempDistrictConferenceUnicorn]: enableDistrictUnicorn } = useFeatureFlags();
  const { currentUser } = useContext(RootContext);

  const accountsRef = useRef<AccountDTO[]>();
  const searchParamsRef = useRef<SeasonSearchParams>(SEARCH_PARAMS);
  const isUnmounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [prepared, setPrepared] = useState(false);
  const [activities, setActivities] = useState<ActivityDTO[]>([]);
  const [seasonsResponse, setSeasonsResponse] = useState<SeasonDTO[]>([]);

  const handleSearchSeasons = useCallback(async () => {
    try {
      const params = _getSearchParams();

      if (isEmpty(params?.body?.accountIds) && isEmpty(params?.body?.financialAccountIds)) {
        setSeasonsResponse([]);
        return;
      }

      const seasonsRes = await SeasonService.searchSeasonByParams(params);

      if (isUnmounted.current) return;
      setSeasonsResponse(seasonsRes?.content ?? []);
    } catch (e) {
      if (isUnmounted.current) return;
      addNotification(generateError(e));
    } finally {
      if (!isUnmounted.current) {
        setLoading(false);
      }
    }
  }, [searchParamsRef.current]);

  useEffect(() => {
    if (!prepared) {
      _onPrepareData();
    }

    return () => {
      isUnmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (!prepared || loading) return;

    if (isEmpty(accounts) || isEqual(accounts, accountsRef.current)) return;

    setLoading(true);

    accountsRef.current = accounts;
    const accountIds = isEmpty(accounts) ? [] : accounts.map(acc => acc.id);
    const financialAccountIds = [...accountIds];
    searchParamsRef.current = {
      ...searchParamsRef.current,
      page: 0,
      pageSize: 2000,
      body: {
        ...searchParamsRef.current.body,
        accountIds,
        financialAccountIds
      },
      expand: ['account', 'season-sales-info']
    };

    handleSearchSeasons();
  }, [accounts, prepared, loading]);

  useEffect(() => {
    if (!loading && isEmpty(accounts) && !isEmpty(seasonsResponse)) {
      setSeasonsResponse([]);
    }
  }, [accounts, loading, seasonsResponse]);

  return loading ? (
    <Row>
      <Column className='page-loading'>
        <SkeletonText />
        <SkeletonPlaceholder />
      </Column>
    </Row>
  ) : (
    <SeasonGrid
      isFromDashboard
      history={history}
      seasons={seasonsResponse}
      activities={activities}
      rates={rates}
      onSeasonsChanged={seasons => setSeasonsResponse(seasons)}
    />
  );

  async function _onPrepareData() {
    try {
      const activitiesRes = await ActivityService.getAllActivities();

      if (isUnmounted.current) return;
      setActivities(activitiesRes);
    } catch (e) {
      if (isUnmounted.current) return;
      addNotification(generateError(e));
    } finally {
      if (!isUnmounted.current) {
        setPrepared(true);
      }
    }
  }

  function _getSearchParams() {
    let newValues = {};

    if (enableDistrictUnicorn) {
      newValues = AccountService.getSearchParamsByPermission({
        currentUser,
        schoolsConfig,
        districtAccount,
        accountIds: [...(searchParamsRef.current?.body?.accountIds ?? [])],
        financialAccountIds: [...(searchParamsRef.current?.body?.financialAccountIds ?? [])]
      });
    }

    return {
      ...searchParamsRef.current,
      body: {
        ...(searchParamsRef.current.body ?? {}),
        ...newValues
      }
    };
  }
};

export default DashboardSeasons;
