// @ts-expect-error: index.d.ts for OverflowMenu is not exported in node_modules
import { OverflowMenu as CarbonOverflowMenu, OverflowMenuItem as CarbonOverflowMenuItem } from '@carbon/react';
import classNames from 'classnames';

import './OverflowMenu.scss';

interface OverflowMenuProps {
  children?: React.ReactNode;
  className?: string;
  direction?: 'top' | 'bottom';
  flipped?: boolean;
  focusTrap?: boolean;
  iconClass?: string;
  iconDescription?: string;
  id?: string;
  menuOffset?: number;
  menuOffsetFlip?: number;
  menuOptionsClass?: string;
  onClick?: () => void;
  onClose?: () => void;
  onFocus?: () => void;
  onKeyDown?: () => void;
  onOpen?: () => void;
  open?: boolean;
  renderIcon?: any;
  selectorPrimaryFocus?: string;
  size?: 'sm' | 'md' | 'lg';
}

export const OverflowMenu = (props: OverflowMenuProps) => (
  <CarbonOverflowMenu
    {...props}
    id='gf--overflow-menu'
    menuOptionsClass={classNames('gf--overflow-menu-options', props.menuOptionsClass)}
  >
    {props.children}
  </CarbonOverflowMenu>
);

export const OverflowMenuItem = CarbonOverflowMenuItem;
