import React, { useMemo, useState } from 'react';

import LocalStorage from './LocalStorage';
import { signOut } from '@gofan/api/auth';

export const RootContext = React.createContext();

export default ({ children = {} }) => {
  const prevAuthenticated = LocalStorage.getStorageAuthenticated();
  const prevAuthBody = LocalStorage.getStorageAuthBody();
  const prevCurrentUser = LocalStorage.getStorageCurrentUser();
  const [authenticated, setAuthenticated] = useState(prevAuthenticated);
  const [authBody, setAuthBody] = useState(prevAuthBody);
  const [currentUser, setCurrentUser] = useState(prevCurrentUser);
  const [sessionState, setSessionState] = useState();
  const [emailDefault, setEmailDefault] = useState('');
  const [sideNavIsExpanded, setSideNavIsExpanded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const removeSessionState = () => {
    setSessionState(null);
  };

  // TODO: This function is only for mocking purposes. Remove it after integration with Roster API.
  const backupCoachStorage = () => {
    const coachData = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith('coach-') || key.startsWith('roster') || key.startsWith('school-config')) {
        coachData[key] = localStorage.getItem(key);
      }
    }

    return coachData;
  };

  const clearAuthorization = () => {
    const coachData = backupCoachStorage();

    LocalStorage.clearStorage();
    setAuthenticated(false);
    setEmailDefault('');
    setAuthBody(null);
    setCurrentUser(null);
    setIsLoggedIn(true);
    signOut();
    const { pendo } = window;
    if (pendo && typeof pendo.clearSession === 'function') {
      pendo.clearSession();
    }

    Object.keys(coachData).forEach(key => {
      localStorage.setItem(key, coachData[key]);
    });
  };

  const defaultContext = useMemo(
    () => ({
      authenticated,
      setAuthenticated,
      clearAuthorization,
      authBody,
      setAuthBody,
      currentUser,
      setCurrentUser,
      sessionState,
      setSessionState,
      removeSessionState,
      emailDefault,
      setEmailDefault,
      sideNavIsExpanded,
      setSideNavIsExpanded,
      isLoggedIn
    }),
    [authBody, authenticated, currentUser, emailDefault, sessionState, sideNavIsExpanded, isLoggedIn]
  );

  return <RootContext.Provider value={defaultContext}>{children}</RootContext.Provider>;
};
