import type { AccountDTO } from '@gofan/api/accounts';
import type { Notification } from './types';
import type Account from '../../api/model/Account';
import type { RootActionTypes } from './actionTypes';
import {
  SHOW_ACCOUNT_INFO,
  REMOVE_ACCOUNT_INFO,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  ADD_ARBITER_FLOW_PATH,
  REMOVE_ARBITER_FLOW_PATH,
  UPDATE_SELECTED_SCHOOLS
} from './actionTypes';

// TypeScript infers that this function is returning AddMessageAction
export function addNotification(newNotification: Notification, timeout?: number): RootActionTypes {
  return {
    type: ADD_NOTIFICATION,
    payload: {
      notification: newNotification,
      timeout
    }
  };
}

// TypeScript infers that this function is returning RemoveMessageAction
export function removeNotification(id: number): RootActionTypes {
  return {
    type: REMOVE_NOTIFICATION,
    payload: {
      id
    }
  };
}

export function showAccountInfo(account: Account | AccountDTO | undefined): RootActionTypes {
  return {
    type: SHOW_ACCOUNT_INFO,
    payload: {
      account
    }
  };
}

export function removeAccountInfo(): RootActionTypes {
  return {
    type: REMOVE_ACCOUNT_INFO
  };
}

export function updateSelectedSchools(accounts: Account[] | AccountDTO[] | undefined): RootActionTypes {
  return {
    type: UPDATE_SELECTED_SCHOOLS,
    payload: {
      accounts
    }
  };
}
export function addArbiterFlowPath(arbiterFlowPath: string): RootActionTypes {
  return {
    type: ADD_ARBITER_FLOW_PATH,
    payload: {
      arbiterFlowPath
    }
  };
}

export function removeArbiterFlowPath(): RootActionTypes {
  return {
    type: REMOVE_ARBITER_FLOW_PATH
  };
}
