import { AccordionItem as CarbonAccordionItem } from '@carbon/react';

interface AccordionToggleProps {
  'aria-controls'?: React.AriaAttributes['aria-controls'];
  'aria-expanded'?: React.AriaAttributes['aria-expanded'];
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
  type?: 'button';
}

interface AccordionProps {
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
  onHeadingClick?: ({
    isOpen,
    event
  }: {
    isOpen: boolean;
    event: Parameters<React.MouseEventHandler<HTMLButtonElement>>[0];
  }) => void;
  open?: boolean;
  renderToggle?: (props: React.PropsWithChildren<AccordionToggleProps>) => React.ReactElement;
  title?: React.ReactNode;
  handleAnimationEnd?: React.AnimationEventHandler<HTMLLIElement>;
}

export const AccordionItem = (props: React.PropsWithChildren<AccordionProps>) => <CarbonAccordionItem {...props} />;
