import React from 'react';
import BaseInput from './BaseInput';

export default ({ label, formKey, formVal, description, maxLength = null }) => (
  <BaseInput
    readOnly
    label={label}
    formVal={formVal}
    formKey={formKey}
    description={description}
    maxLength={maxLength}
  />
);
