import { css } from 'styled-components';

export default {
  tooltipWrapper: css`
    position: relative;
    display: inline-block;

    .bx--tooltip__label {
      z-index: -1;
      position: absolute;
      opacity: 0;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 16px;
      height: 16px;
      transform: translateX(-50%);
    }
  `
};
