import { useQuery } from '@tanstack/react-query';
import { AccountService } from './account.service';
import { ACCOUNT_SALESFORCE_QUERY_KEY } from './account.endpoints';

import type { QueryOptions } from '@gofan/api/shared';
import type { AccountDTO } from './account.model';

interface UseSalesforceAccountOptions {
  salesforceId: string;
  queryOptions?: QueryOptions;
}

export const useSalesforceAccount = ({ salesforceId, queryOptions = {} }: UseSalesforceAccountOptions) => {
  const query = useQuery<AccountDTO>({
    queryKey: [ACCOUNT_SALESFORCE_QUERY_KEY, salesforceId],
    queryFn: () => AccountService.getAccountBySfId(salesforceId),
    ...queryOptions
  });

  return { ...query };
};
