import { UserHistoryState } from './types';
// @flow
import {
  UserHistoryActionTypes,
  RESET_STATE,
  FETCH_USER_HISTORY,
  FETCH_USER_HISTORY_COMPLETED
} from './actionTypes';

export const initialState: UserHistoryState = {
  loading: false,
  histories: []
};

export default (state = initialState, action: UserHistoryActionTypes) => {
  switch (action.type) {
    case RESET_STATE: {
      return { ...initialState };
    }

    case FETCH_USER_HISTORY: {
      return {
        ...state,
        loading: true,
        histories: []
      };
    }

    case FETCH_USER_HISTORY_COMPLETED: {
      const { histories } = action.payload;
      return {
        ...state,
        histories,
        loading: false
      };
    }

    default:
      return state;
  }
};
