const ARBITER_SPORT_STRING = {
  DONT_HAVE_PERMISSION: 'You don’t have permission to view Event schedules page.',
  CLOSE: 'Close',
  REVIEW: 'Review',
  VIEW: 'View',
  HEADER_DESCRIPTION: 'GoFan and ArbiterSports simplify your activity scheduling with one-click importing. ',
  EVENT_SCHEDULE_TITLE: 'New event schedules',
  EVENT_SCHEDULE_DESCRIPTION:
    'The following schedules were received from ArbiterSports. Click Review to proceed to publishing.',
  IMPORT_HISTORY: 'Import history',
  REVIEW_ALL: 'Review all'
};

export const ARBITER_PARTNER_NAME = 'arbiter';
export const AIA_PARTNER_NAME = 'aia';
export const DRAGONFLY_PARTNER_NAME = 'dragonfly';
export const NFHS_PARTNER_NAME = 'nfhs';
export const VNN_PARTNER_NAME = 'VNN';
export const HQ_PARTNER_NAME = 'hq';
export const RANKONE_PARTNER_NAME = 'rankone';
export const ACTIVITY_SCHEDULER_PARTNER_NAME = 'Activity Scheduler';
export const RSCHOOLTODAY_PARTNER_NAME = 'rSchoolsToday';
export const LIST_GENDER = ['Boys', 'Girls', 'Coed'];
export const NEW_STATUS = 'New';
export const CHANGED_STATUS = 'Changed';
export const DECLINED_STATUS = 'Declined';
export const WAITING_STATUS = 'Waiting';
export const PUBLISHED_STATUS = 'Published';
export const DUPLICATED_STATUS = 'Duplicated';
export const IGNORED_STATUS = 'Ignored';
export const DELETED_STATUS = 'Deleted';
export const CANCELED_STATUS = 'Canceled';
export const UPDATED_STATUS = 'Updated';
export const NORMAL_STATUS = 'Normal';
export const ARBITER_LEARN_MORE_URL = 'https://www.arbitersports.com/gofan-partnership';

export const ALL_SCHEDULE_FILTER_STATUS = [WAITING_STATUS, PUBLISHED_STATUS, IGNORED_STATUS];
export const AWAY_SCHEDULE_FILTER_STATUS = [
  NEW_STATUS,
  CHANGED_STATUS,
  IGNORED_STATUS,
  DECLINED_STATUS,
  PUBLISHED_STATUS
];

export const INTEGRATION_PARTNERS = {
  arbiter: ARBITER_PARTNER_NAME,
  aia: AIA_PARTNER_NAME,
  dragonfly: DRAGONFLY_PARTNER_NAME,
  nfhs: NFHS_PARTNER_NAME,
  vnn: VNN_PARTNER_NAME,
  activity_scheduler: ACTIVITY_SCHEDULER_PARTNER_NAME,
  rankone: RANKONE_PARTNER_NAME
};

export const INTERNAL_PARTNERS = {
  hq: 'hq'
};

export const ARBITER_IMPORT_PROGRESS_DESC = {
  RETRIEVING_EVENTS: 'Retrieving events from ArbiterSports...',
  IMPORTING_EVENTS: 'Importing events to GoFan HQ...',
  CHECKING_DUPLICATE_EVENTS: 'Checking for duplicate events...'
};

export const ARBITER_PARTNER_FULL_NAME = 'Arbiter';
export const AIA_PARTNER_FULL_NAME = 'AIA';
export const DRAGONFLY_PARTNER_FULL_NAME = 'DragonFly';
export const NFHS_PARTNER_FULL_NAME = 'NFHS';
export const DEFAULT_PARTNER_FULL_NAME = 'Partner';
export const VNN_PARTNER_FULL_NAME = 'VNN';
export const ACTIVITY_SCHEDULER_PARTNER_FULL_NAME = 'Activity Scheduler';
export const RANKONE_PARTNER_FULL_NAME = 'RankOne';
export const FULL_NAME_INTEGRATION_PARTNERS = {
  [ARBITER_PARTNER_NAME]: ARBITER_PARTNER_FULL_NAME,
  [AIA_PARTNER_NAME]: AIA_PARTNER_FULL_NAME,
  [DRAGONFLY_PARTNER_NAME]: DRAGONFLY_PARTNER_FULL_NAME,
  [NFHS_PARTNER_NAME]: NFHS_PARTNER_FULL_NAME,
  [VNN_PARTNER_NAME]: VNN_PARTNER_FULL_NAME,
  [ACTIVITY_SCHEDULER_PARTNER_NAME]: ACTIVITY_SCHEDULER_PARTNER_FULL_NAME,
  [RANKONE_PARTNER_NAME]: RANKONE_PARTNER_FULL_NAME
};

export const DRAGONFLY_STATES = ['AL', 'MS', 'AR'];
export const STATES_OF_HIGH_RISK_MARKET = ['AL', 'AR', 'AZ', 'MS', 'MT', 'NM', 'TX'];

export const COMPLETED_STATUSES = [PUBLISHED_STATUS, DUPLICATED_STATUS, IGNORED_STATUS];

export default ARBITER_SPORT_STRING;
export enum UPDATE_STATUS {
  NONE = 'NONE',
  UPDATED = 'UPDATED',
  CANCELED = 'CANCELED',
  DELETED = 'DELETED'
}

export const PUBLISH_TYPE = {
  CANCEL: 'CANCEL',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
  CREATE: 'CREATE',
  HAS_SOLD_WITH_UPDATED_END_DATE_TIME: 'HAS_SOLD_WITH_UPDATED_END_DATE_TIME',
  AFTER_END_DATE_TIME: 'AFTER_END_DATE_TIME'
};

export const UPDATE_ITEM_TEXT = {
  SECTION_TITLE: 'What changed:',
  EVENT_CANCELED: 'Event Canceled',
  EVENT_DELETED: 'Event Deleted',
  OPPONENT: 'Opponent',
  FROM: 'From:',
  TO: 'To:',
  ON: 'On:'
};

export const CONFIRM_MODAL_END_DATE_TIME = {
  [PUBLISH_TYPE.HAS_SOLD_WITH_UPDATED_END_DATE_TIME]: {
    title: 'Confirm change of event date/time',
    description:
      'Changing your event’s date/time will update all tickets already purchased for the event. Additionally, any reports you have already received will reflect the old date. Are you sure you want to change this event’s date/time?'
  },
  [PUBLISH_TYPE.AFTER_END_DATE_TIME]: {
    title: 'Confirm change of event date/time',
    description:
      'By changing your event’s date/time, any report you have already received will reflect the old date. Are you sure you want to change this event’s date/time?'
  }
};

export const SCHEDULE_SORT_INDEX = {
  [WAITING_STATUS.toLowerCase()]: 1,
  [UPDATED_STATUS.toLowerCase()]: 2,
  [PUBLISHED_STATUS.toLowerCase()]: 3,
  [IGNORED_STATUS.toLowerCase()]: 4,
  default: 10
};

export const INVALID_ACCOUNT_IDS = ['none', 'null', 'undefined'];
