import React from 'react';
import styled from 'styled-components';
import { Tooltip as CarbonTooltip } from 'carbon-components-react';

import styles from './styles';

const TooltipWrapper = styled.div`
  ${styles.tooltipWrapper}
`;

const TriggerHover = styled.div``;

function Tooltip({ children, align, direction, tooltipText, onClick, ...other }) {
  const [isShown, setIsShown] = React.useState(false);
  return (
    <TooltipWrapper>
      <TriggerHover
        {...other}
        onPointerEnter={() => tooltipText && setIsShown(true)}
        onPointerLeave={() => tooltipText && setIsShown(false)}
        onClick={() => {
          if (tooltipText) {
            setIsShown(false);
          }
          onClick();
        }}
      >
        {children}
      </TriggerHover>
      <CarbonTooltip
        open={isShown}
        showIcon={false}
        align={align}
        direction={direction}
        triggerText='tooltip-hover'
        className='gs--body-short-01 gs--inverse-01 gs--tooltip-custom'
      >
        {tooltipText}
      </CarbonTooltip>
    </TooltipWrapper>
  );
}

Tooltip.defaultProps = {
  children: null,
  align: 'center',
  direction: 'top',
  tooltipText: '',
  onClick: () => null
};

export default Tooltip;
