import { Row, Column, TextArea } from '@gofan/components';
import { EVENT_TYPES } from '@app/pages/EventInformationV2/constants';

import type { AccountVenue } from '@gofan/api';

interface VenueLocationProps {
  activityType?: string;
  venue?: AccountVenue | null;
}

export const VenueLocation = ({ activityType, venue }: VenueLocationProps) => {
  const isPerformingArts = activityType === EVENT_TYPES.PERFORMING_ART;

  if (!isPerformingArts) return null;

  return (
    <>
      <Row className='gs--padding-top__sp7 gs--padding-bottom__sp10'>
        <Column className='gs--font-family-san gs--section-description gs--text' lg={8}>
          Add details to guide patrons to the venue
        </Column>
      </Row>
      <Row className='gs--padding-top__sp5 gs--padding-bottom__sp10'>
        <Column lg={8}>
          <TextArea
            id='location-notes'
            labelText=''
            hideLabel
            value={venue?.location ?? ''}
            enableCounter
            maxCount={100}
            disabled
          />
        </Column>
      </Row>
    </>
  );
};
