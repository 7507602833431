import { Tag } from '@app/../../../packages/components';
import styled from 'styled-components';

const LogoImg = styled.img`
  max-height: 3rem;
`;

const EmailParagraph = styled.p`
  word-wrap: break-word;
`;

export const tableSchema = [
  {
    key: 'logo',
    header: 'Logo',
    isSortable: false,
    accessor: data => (data.logoUrl ? <LogoImg src={data.logoUrl} alt='logo' /> : <div />)
  },
  {
    key: 'name',
    header: 'Sponsor Name',
    // isSortable: true, // in the future when this becomes a necessity we can add it back and implement sorting
    isSortable: false,
    accessor: data => data.name
  },
  {
    key: 'type',
    header: 'Sponsor Type',
    isSortable: false,
    // isSortable: true,
    accessor: data => data.sponsorType
  },
  {
    key: 'email',
    header: 'Contact Email',
    isSortable: false,
    accessor: data => <EmailParagraph>{data?.contact?.email}</EmailParagraph>,
    internal: true
  },
  {
    key: 'phone',
    header: 'Contact Phone',
    isSortable: false,
    accessor: data => data?.contact?.phone,
    internal: true
  },
  {
    key: 'status',
    header: 'Sponsor Status',
    isSortable: false,
    accessor: data => (
      <Tag size='sm' type={data.status === 'active' ? 'blue' : 'red'}>
        {data.status}
      </Tag>
    )
  },
  {
    key: 'assigned',
    header: 'Schools Assigned',
    isSortable: false,
    // isSortable: true,
    accessor: data => data.assignedSchools,
    internal: true
  }
];

/**
 * Returns the table schema for sponsorships. If the 'internal' parameter is true,
 * it returns the complete table schema. Otherwise, it filters out the internal elements
 * from the table schema.
 *
 * @param {boolean} internal - A flag indicating whether to include internal elements in the schema.
 * @returns {Array} The table schema for sponsorships.
 */
const getTableSchema = (internal = false) => (internal ? tableSchema : tableSchema.filter(i => !i.internal));

export default getTableSchema;
