import { css } from 'styled-components';

export default {
  container: css`
    flex-direction: column;
    display: flex;
    position: relative;
    svg {
      z-index: 1;
    }
  `,
  errorLabel: css`
    color: #da1e28;
    font-size: 12px;
    margin-top: 4px;
    font-family: 'IBM Plex Sans';
    font-weight: 400;
  `
};
