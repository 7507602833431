import { email } from '../../../../commons/validations';

export const SIGNIN = {
  signIn: 'Sign in',
  forgotPassword: 'Forgot password',
  submit: 'Submit',
  emailAddress: 'Email address',
  email: 'Email',
  resetContent:
    "Enter the email address you use to sign in and we'll send you a link to reset your password.",
  password: 'Password',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  testSignIn: 'TEST SIGN IN',
  resetEmail: 'Reset Email Sent.',
  contentResetEmail: 'Check your email for a link to reset your password.',
  backToSign: 'Back to Sign In',
  coppyRights: '© 2015-2021 Huddle Tickets, LLC. All Rights Reserved',
  emailForgotPassword: 'Email address',
  forGotPasswordTitle: 'Forgot password?',
  regsetPassword: 'Reset password',
  setPassword: 'Set password',
  resetEmailTitle: 'Your reset email was sent',
  checkEmail: 'Check your email for the reset password link.',
  createPassword: 'Create password',
  createPasswordAlert: 'You need to create a password for this account.',
  createPasswordTitle: 'Create a password to access your account',
  createPasswordDescription:
    'To protect your account, make sure your password :',
  createPasswordDescriptionDetail: [
    'Is 8 to 20 characters long',
    'Contains at least 1 capital letter',
    'Contains at least 1 number'
  ],
  checkEmailTitle: 'Check your email',
  checkEmailDescription: [
    'We sent you an email to {email}. Follow the link to create/reset your password.',
    'If you don’t see the email, check your spam or junk folders. If you sent a request multiple times, only the most recent email will be valid.'
  ],
  backToSignIn: '< Back to sign in',
  somethingWentWrong: 'Something went wrong',
  wrongDescription:
    'Please make sure that the link in your activation email has not expired. If the error persists, contact us support@gofan.co',
  createGoFanAccount: 'Create GoFan.co account',
  viewMobileHQ: 'On a mobile device? View HQ mobile'
};

export const SIGNIN_ERRORS = {
  email: [
    {
      check: value => !value,
      message: 'Email address is required.'
    },
    {
      check: value => !email(value),
      message: 'Enter a valid email address.'
    }
  ],
  password: [
    {
      check: value => !value,
      message: 'Password is required.'
    }
  ]
};
