import moment from 'moment-timezone';
import LocalStorage from '../../LocalStorage';

export const ACCOUNTS_CACHE_NAME = 'accounts';
export const SELECT_ACCOUNT_CONTEXT_CACHE = 'account associate';
export const USER_COORDINATE_CACHE_NAME = 'USER_COORDINATE';
export const CURRENT_COORDINATE_CACHE_NAME = 'currentCoordinate';

export const wasCacheExpired = (cachedAt, duration = 300) => {
  if (cachedAt === 0) {
    return true;
  }
  return cachedAt + duration <= moment().unix();
};

export const getCachedData = (name, key, duration) => {
  const cache = LocalStorage.getStorage(name);
  const { cachedAt, value } = cache && cache[key] ? cache[key] : { cachedAt: 0 };
  if (duration && wasCacheExpired(cachedAt, duration)) {
    return null;
  }
  return value;
};

export const invalidateCache = (cache, duration) => {
  if (!cache) {
    return {};
  }
  return Object.keys(cache).reduce((acc, cur) => {
    const { cachedAt } = cache[cur];
    if (cachedAt + duration < moment().unix()) {
      acc[cur] = cache[cur];
    }
    return acc;
  }, {});
};

export const putDataToCache = (name, key, cacheData) => {
  const cache = LocalStorage.getStorage(name);
  LocalStorage.setStorage(name, {
    ...cache,
    [key]:
      cacheData === undefined
        ? undefined
        : {
            cachedAt: moment().unix(),
            value: cacheData
          }
  });
};

export const deleteCachedData = name => {
  LocalStorage.removeStorage(name);
};

export default {
  ACCOUNTS_CACHE_NAME,
  SELECT_ACCOUNT_CONTEXT_CACHE,
  USER_COORDINATE_CACHE_NAME,
  CURRENT_COORDINATE_CACHE_NAME,
  getCachedData,
  putDataToCache,
  invalidateCache,
  deleteCachedData
};
