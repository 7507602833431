import { css } from 'styled-components';

export default {
  wrapper: css`
    position: relative;

    ${props =>
      props.hasAction &&
      css`
        .bx--inline-notification__icon {
          margin-top: 0;
        }
        
        .bx--inline-notification__details {
          align-items: center;
        }

        .bx--inline-notification__text-wrapper {
          align-items: center;
          justify-content: space-between;
          padding: 0px;
        }

        .bx--inline-notification__close-button {
          align-self: center;
        }

        .bx--inline-notification--low-contrast .bx--inline-notification__action-button.bx--btn--ghost {
          margin-left: 106px;
        }
      `}
  `,
};