import bg0 from '../../../../assets/images/signin/GoFan-SignIn-0.jpg';
import bg1 from '../../../../assets/images/signin/GoFan-SignIn-1.jpg';
import bg2 from '../../../../assets/images/signin/GoFan-SignIn-2.jpg';
import bg3 from '../../../../assets/images/signin/GoFan-SignIn-3.jpg';
import bg4 from '../../../../assets/images/signin/GoFan-SignIn-4.jpg';
import bg5 from '../../../../assets/images/signin/GoFan-SignIn-5.jpg';
import bg6 from '../../../../assets/images/signin/GoFan-SignIn-6.jpg';

const signBackgrounds = [bg0, bg1, bg2, bg3, bg4, bg5, bg6];

export const randomBackgroundSignIn = () =>
  signBackgrounds[Math.floor(Math.random() * signBackgrounds.length)];

export default { randomBackgroundSignIn };
