// @flow

class BaseModel {
  constructor(json) {
    this.raw = json;
  }

  toJSON() {
    return this.raw;
  }
}

export default BaseModel;
