// flow

import {
  AccountListActionTypes,
  RESET_STATE,
  SEARCH_ACCOUNTS,
  SEARCH_ACCOUNTS_SUCCESS
} from './actionTypes';
import { SearchAccountsParams, SearchAccountsResult } from './types';

export const resetState = (): AccountListActionTypes => ({
  type: RESET_STATE
});

export const searchAccounts = (searchParams: SearchAccountsParams): AccountListActionTypes => ({
  type: SEARCH_ACCOUNTS,
  payload: {
    searchParams
  }
});

export const searchAccountsSuccess = (searchResult: SearchAccountsResult): AccountListActionTypes => {
  return {
    type: SEARCH_ACCOUNTS_SUCCESS,
    payload: {
      searchResult
    }
  };
};
