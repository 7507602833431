import isEmpty from 'lodash/isEmpty';
import axios, { AxiosProxy } from '@api/api/axios';
import axiosExt from 'axios';
import {
  GET_SEASON_BY_ID,
  SEARCH_SEASON_BY_IDS_V2,
  SEARCH_SEASONS,
  FETCH_GLOBAL_EVENTS,
  UPDATE_SEASON_WITH_EVENT,
  UPDATE_SEASON,
  DELETE_SEASON,
  CREATE_LIST_SEASON_WITH_EVENTS,
  GET_SEASON_TICKET_INSIGHT
} from '@api/api/api';
import type {
  SeasonDTO,
  GlobalEventDTO,
  GlobalEventsSearchDTO,
  SeasonImportResponseDTO,
  SeasonPageResponseDTO,
  SeasonSearchDTO,
  SeasonInsightDTO
} from '@seasons/models/season.model';
import { GET_SEASON_INSIGHT_DATA } from '@gofan/constants/endpoints';
import { getQueryString } from '@gofan/utils/object';

export type SeasonTicketUnionIds = {
  eventId: number;
  financialId: string;
  ticketId: number;
};

class SeasonRepository {
  searchSeasonByParams = (body: SeasonSearchDTO, queryStr: string) =>
    axios.huddleAxiosInstanceProxy.post<SeasonPageResponseDTO, SeasonSearchDTO>(`${SEARCH_SEASONS}${queryStr}`, body);

  fetchGlobalEvents = (body: GlobalEventsSearchDTO) =>
    axios.huddleAxiosInstanceProxy.post<GlobalEventDTO[], GlobalEventsSearchDTO>(
      FETCH_GLOBAL_EVENTS,
      body,
      AxiosProxy.AXIOS_PARTNER_CONFIG
    );

  fetchArbiterSeasonImport = (body: SeasonSearchDTO, queryStr: string): Promise<SeasonImportResponseDTO> =>
    axios.huddleAxiosInstanceProxy.post<SeasonImportResponseDTO, SeasonSearchDTO>(`${SEARCH_SEASONS}${queryStr}`, body);

  getSeasonById = (seasonId: string, queryStr: string): Promise<SeasonDTO> =>
    axios.huddleAxiosInstanceProxy.get<SeasonDTO>(`${GET_SEASON_BY_ID.replace('{id}', `${seasonId}`)}${queryStr}`);

  searchSeasonByIds = (seasonIds: number[], queryStr: string, axiosConfig?: any): Promise<SeasonDTO[]> =>
    axios.huddleAxiosInstanceProxy.get<SeasonDTO[]>(
      `${SEARCH_SEASON_BY_IDS_V2}${`${seasonIds.length > 0 ? getQueryString({ id: seasonIds }) : '?id'}${queryStr}`}`,
      axiosConfig ?? {}
    );

  updateSeasonWithEvent = (season: any): Promise<SeasonDTO> =>
    axios.huddleAxiosInstanceProxy.put(UPDATE_SEASON_WITH_EVENT.replace('{id}', `${season.id}`), season);

  updateSeason = (season: SeasonDTO): Promise<SeasonDTO> =>
    axios.huddleAxiosInstanceProxy.put(UPDATE_SEASON.replace('{id}', `${season.id}`), season);

  partialUpdateSeason = (season: Partial<SeasonDTO>) =>
    axios.huddleAxiosInstanceProxy
      .patch<SeasonDTO[], Partial<SeasonDTO>[]>(CREATE_LIST_SEASON_WITH_EVENTS, [season])
      .then(seasons => seasons?.[0]);

  deleteSeason = (season: SeasonDTO, deleteEventIds?: number[]): Promise<SeasonDTO> => {
    const path = UPDATE_SEASON.replace('{id}', `${season.id}`);
    const deleteEventPath = `?deleteEventIds=${(deleteEventIds ?? [])?.join(',')}`;

    return axios.huddleAxiosInstanceProxy.delete(`${path}${deleteEventPath}`, undefined);
  };

  getSeasonInsights = (seasonId: number): Promise<SeasonInsightDTO> =>
    axiosExt.get(GET_SEASON_INSIGHT_DATA.replace('{id}', `${seasonId}`));

  getSeasonTicketInsightData = (unionIds: SeasonTicketUnionIds[]) => {
    const queryStringArr: string[] = [];

    unionIds.forEach((union, index) => {
      const unionString = `${union.eventId}${union.financialId}${union.ticketId}${
        index !== unionIds.length - 1 ? ',' : ''
      }`;
      queryStringArr.push(unionString);
    });

    const queryString = queryStringArr.join('');

    return axiosExt
      .get(`${GET_SEASON_TICKET_INSIGHT}/?union_codes=[${queryString}]`, undefined)
      .then(response => response.data);
  };
}

export default new SeasonRepository();
