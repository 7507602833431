import { useEffect, useRef, useState } from 'react';

import EventService from '@events/services/events.service';

import { generateError, generateSuccessMessage } from '@app/utils/alertUtils';

import type { EventRescheduleDTO } from '@events/models/event.model';

interface UseEventRescheduleProps {
  addNotification: Function;
  onCloseModal: () => void;
  onReloadEvent?: () => void;
}

export const useEventReschedule = ({ addNotification, onCloseModal, onReloadEvent }: UseEventRescheduleProps) => {
  const [rescheduling, setRescheduling] = useState(false);

  const isUnmounted = useRef<boolean>(false);

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  const rescheduleEvent = (body: EventRescheduleDTO) => {
    setRescheduling(true);
    EventService.rescheduleEvent(body)
      .then(event => {
        if (isUnmounted.current) return;
        onCloseModal();
        addNotification(generateSuccessMessage(`${event.name} is successfully updated.`));
        if (typeof onReloadEvent === 'function') onReloadEvent();
      })
      .catch(error => {
        if (isUnmounted.current) return;
        addNotification(generateError(error));
      })
      .finally(() => {
        if (isUnmounted.current) return;
        setRescheduling(false);
      });
  };

  return {
    rescheduling,
    rescheduleEvent
  };
};
