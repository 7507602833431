import { get, omit, chain, isEmpty, isNil, uniq, uniqBy, uniqueId, isEqual } from 'lodash';
import { config } from '@gofan/constants/config';
import { STATES_OF_HIGH_RISK_MARKET } from '@gofan/constants/accounts';
import { DISTRIBUTION_CHANNEL } from '@gofan/constants';

import type {
  AccountProductDTO,
  ActivityLevelsDTO,
  AccountProductLandingPageDTO,
  AccountProductGroup
} from './account-product.model';
import type { ActivityDTO } from '../activities/activity.model';
import type { RateDTO } from '../rates/rate.model';

export type ProductsByActivity = ActivityDTO & {
  tickets: AccountProductDTO[];
  schedule: boolean;
};

export const groupAccountProductByActivity = (
  activities: ActivityDTO[],
  products: AccountProductDTO[],
  // ! TODO - replace this with EventScheduleSeasonDTO when event-integrations is moved to packages/api
  eventSchedules: any[],
  state: string
): ProductsByActivity[] => {
  const results: ProductsByActivity[] = [];
  activities.forEach(activity => {
    const tickets = products.filter(product => product.activityId === activity.id);
    const haveSchedules =
      !STATES_OF_HIGH_RISK_MARKET.includes(state) && eventSchedules.some(event => event.sportName === activity.label);
    results.push({ ...activity, tickets, schedule: haveSchedules });
  });
  return results;
};

export const groupAccountProduct = (accountTickets: AccountProductLandingPageDTO[]): AccountProductLandingPageDTO[] =>
  chain(accountTickets)
    .groupBy(({ ticketName, ticketPrice, accountPaid, levelIds, customColor }) => {
      const levelKeys = ['levelIds_', ...(levelIds.sort() || uniqueId())].join('_');
      return [`${ticketName}`.toUpperCase(), ticketPrice, accountPaid, levelKeys, customColor].join('-');
    })
    .map(value => {
      const activities: ActivityLevelsDTO[] = [];
      const ids: string[] = [];
      const tickets = uniqBy(value, 'activityId');
      tickets.forEach(ticket => {
        const levels = get(ticket, '_embedded.levels', []);
        const activity: ActivityDTO | undefined = get(ticket, '_embedded.activity');
        if (!isNil(activity)) {
          const assumedActivity = activity as ActivityDTO;
          activities.push({ ...assumedActivity, levels: assumedActivity.athletic ? levels : [] });
        }
        ids.push(ticket.id);
      });
      return {
        ...tickets[0],
        id: ids.join('-'),
        activities: activities.sort((a, b) => a.label.localeCompare(b.label))
      };
    })
    .filter(product => !isEmpty(product.activities))
    .sort((a, b) => b.ticketPrice - a.ticketPrice)
    .value();

export const calculateFee = ({
  ticketPrice,
  accountPaid,
  rates
}: {
  ticketPrice: any;
  accountPaid: boolean;
  rates?: RateDTO[];
}) => {
  const standardizeTicketPrice = parseFloat(`${ticketPrice ?? 0}`);
  const rate = rates?.find(item => {
    if (standardizeTicketPrice === 0) {
      return `${item.id}` === `${config.RATE_ID8}`;
    }
    return standardizeTicketPrice <= 10 ? `${item.id}` === `${config.RATE_ID1}` : `${item.id}` === `${config.RATE_ID5}`;
  });

  const rateAmount = !!rate?.rateAmount || rate?.rateAmount === 0 ? rate?.rateAmount : 1;
  const ratePercent = !!rate?.ratePercent || rate?.ratePercent === 0 ? rate?.ratePercent : 5;
  const fee = rateAmount + (standardizeTicketPrice * ratePercent) / 100;
  const fanPrice = +(accountPaid ? standardizeTicketPrice : standardizeTicketPrice + fee).toFixed(2);
  const payToSchool = +(accountPaid ? standardizeTicketPrice - fee : standardizeTicketPrice).toFixed(2);

  return {
    fee,
    fanPrice,
    payToSchool,
    ticketPrice: standardizeTicketPrice
  };
};

export const getGroupAccountTicketKey = (accountTicket: AccountProductDTO) =>
  [
    `${accountTicket?.ticketName ?? ''}`.toUpperCase(),
    accountTicket?.ticketPrice ?? '',
    accountTicket?.accountPaid ?? '',
    accountTicket?.customColor ?? '',
    ['levelIds_', ...(accountTicket?.levelIds ?? []).sort()].join('_')
  ].join('-');

export const convertToAccountProductGroup = (accountTickets: AccountProductDTO[]): AccountProductGroup => {
  let ticketIds: string[] = [];
  let levelIds: any[] = [];
  let activityIds: any[] = [];
  let groupDistributionChannel: any[] = [];
  let boxOfficeFee: any;
  let boxOfficeFanPrice: any;
  let boxOfficeTicketName: any;
  let boxOfficeTicketPrice: any;

  uniqBy(accountTickets, 'id').forEach(ticket => {
    const distributionChannel = ticket.distributionChannel ?? DISTRIBUTION_CHANNEL.GOFAN;

    ticketIds.push(ticket.id);
    levelIds = [...levelIds, ...(ticket.levelIds ?? [])];
    activityIds.push(ticket.activityId);
    groupDistributionChannel.push(distributionChannel);

    if (distributionChannel === 'BoxOffice') {
      boxOfficeFee = ticket.fee;
      boxOfficeFanPrice = ticket.fanPrice;
      boxOfficeTicketName = ticket.ticketName;
      boxOfficeTicketPrice = ticket.ticketPrice;
    }
  });

  ticketIds = uniq(ticketIds);
  levelIds = uniq(levelIds);
  activityIds = uniq(activityIds);
  groupDistributionChannel = uniq(groupDistributionChannel);

  const ticket: any = omit(accountTickets?.[0], ['activityId', 'distributionChannel', '_links', '_embedded']);
  const hasBoxOffice = groupDistributionChannel?.includes('BoxOffice');
  const boxOfficeTicket = {
    enabled: hasBoxOffice,
    fee: hasBoxOffice ? boxOfficeFee : ticket?.fee,
    fanPrice: hasBoxOffice ? boxOfficeFanPrice : ticket?.fanPrice,
    ticketName: hasBoxOffice ? boxOfficeTicketName : ticket?.ticketName,
    ticketPrice: hasBoxOffice ? boxOfficeTicketPrice : ticket?.ticketPrice
  };

  return {
    ...ticket,
    created: true,
    deleted: false,
    id: ticketIds.join('-'),
    ticketIds,
    levelIds,
    activityIds,
    groupDistributionChannel,
    boxOfficeTicket
  };
};

export const groupAccountTicket = (accountProducts: AccountProductDTO[]): AccountProductGroup[] => {
  let nonIdGroups: any[] = [];
  const hasIdGroups: any[] = [];

  chain(accountProducts ?? [])
    .groupBy(({ groupId }) => groupId)
    .forEach((items, groupId) => {
      if (groupId === 'null') {
        const groups = chain(items).groupBy(getGroupAccountTicketKey).map(convertToAccountProductGroup).value();
        nonIdGroups = uniqBy([...nonIdGroups, ...groups], 'id');
      } else {
        hasIdGroups.push(convertToAccountProductGroup(items));
      }
    })
    .value();

  return [...nonIdGroups, ...hasIdGroups]
    .filter(ticket => !isEmpty(ticket.activityIds))
    .sort((a, b) => a.ticketPrice - b.ticketPrice);
};

export const getAccountTicketsByGroup = (group: AccountProductGroup) => {
  const tickets: { [ticketId: string]: AccountProductDTO } = {};

  group.groupDistributionChannel?.forEach((channel, index) => {
    const isBoxOffice = channel.toLowerCase() === 'boxoffice';

    tickets[(group.ticketIds ?? [])[index]] = {
      accountId: group.accountId,
      accountPaid: group.accountPaid,
      activityId: (group.activityIds ?? [])[0],
      customColor: group.customColor,
      fanPrice: !isBoxOffice ? group.fanPrice : group.boxOfficeTicket?.fanPrice,
      fee: !isBoxOffice ? group.fee : group.boxOfficeTicket?.fee,
      id: (group.ticketIds ?? [])[index],
      levelIds: group.levelIds,
      payToSchool: group.payToSchool,
      productTypeId: group.productTypeId,
      status: group.status,
      ticketName: !isBoxOffice ? group.ticketName : group.boxOfficeTicket?.ticketName,
      ticketPrice: !isBoxOffice ? group.ticketPrice : group.boxOfficeTicket?.ticketPrice,
      attendeePaid: group.attendeePaid,
      groupId: group.groupId,
      distributionChannel: channel
    };
  });

  return tickets;
};

export const diffAccountTicketGroup = (group: AccountProductGroup, defaultGroup: AccountProductGroup) => {
  const result: any[] = [];

  const name = `${group?.ticketName ?? ''}`.trim();
  const defaultName = `${defaultGroup?.ticketName ?? ''}`.trim();
  if (name !== defaultName) {
    result.push('ticketName');
  }

  if (group?.accountPaid !== defaultGroup?.accountPaid) {
    result.push('accountPaid');
  }

  if (group?.customColor !== defaultGroup?.customColor) {
    result.push('customColor');
  }

  const price = parseFloat(`${group?.ticketPrice}`);
  const defaultPrice = parseFloat(`${defaultGroup?.ticketPrice}`);
  if (price !== defaultPrice) {
    result.push('ticketPrice');
  }

  if (!isEqual(group?.levelIds ?? [], defaultGroup?.levelIds ?? [])) {
    result.push('levelIds');
  }

  if (!isEqual(group?.activityIds ?? [], defaultGroup?.activityIds ?? [])) {
    result.push('activityIds');
  }

  if (!isEqual(group?.groupDistributionChannel ?? [], defaultGroup?.groupDistributionChannel ?? [])) {
    result.push('groupDistributionChannel');
  }

  if (group?.groupDistributionChannel?.includes('BoxOffice')) {
    const boName = `${group?.boxOfficeTicket?.ticketName ?? ''}`.trim();
    const boDefaultName = `${defaultGroup?.boxOfficeTicket?.ticketName ?? ''}`.trim();
    if (boName !== boDefaultName) {
      result.push('boxOfficeTicket.ticketName');
    }

    const boPrice = parseFloat(`${group?.boxOfficeTicket?.ticketPrice}`);
    const boDefaultPrice = parseFloat(`${defaultGroup?.boxOfficeTicket?.ticketPrice}`);
    if (boPrice !== boDefaultPrice) {
      result.push('boxOfficeTicket.ticketPrice');
    }
  }

  return result;
};

export const filterAccountProductPair = (accountProducts: AccountProductDTO[]) => {
  const result: AccountProductDTO[] = [];
  const goFanProduct = accountProducts.find(item => item.distributionChannel === DISTRIBUTION_CHANNEL.GOFAN);
  if (!isEmpty(goFanProduct)) {
    result.push(goFanProduct);
  }
  const boxOfficeProduct = accountProducts.find(item => item.distributionChannel === DISTRIBUTION_CHANNEL.BOXOFFICE);
  if (!isEmpty(goFanProduct) && !isEmpty(boxOfficeProduct)) {
    result.push(boxOfficeProduct);
  }
  return result;
};

export const normalizeAccountProduct = (accountProducts: AccountProductDTO[]) =>
  chain(accountProducts)
    .groupBy(({ groupId }) => groupId)
    .map((items, groupId) => {
      if (groupId === 'null') {
        const groups = chain(items).groupBy(getGroupAccountTicketKey).map(filterAccountProductPair).flatten().value();
        return [...groups];
      }
      return filterAccountProductPair(items);
    })
    .flatten()
    .value();
