import React from 'react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { Table as SRTable, Icon } from 'semantic-ui-react';
import { useTable, useSortBy, useRowSelect, usePagination, useExpanded } from 'react-table';

import styles from './styles';
import TableBody from '../TableBody';
import TableHeader from '../TableHeader';
import Pagination from '../Pagination';
import { SPECIFIC_COLUMN } from '../../constants';

import Radio from '../../../Radio';
import ActionButton from '../../../ActionButton';
import { StyledCheckBox } from '../../../components/styledSemantic';

import useIsMount from '../../../../utils/hooks/useIsMount';
import { isDifference } from '../../../../utils/objectUtils';

const StyledTable = styled(SRTable)`
  ${styles.table};
`;

const generateTableProps = ({
  sortBy = [],
  pageCount = 1,
  hasSorting = false,
  manualSortBy = false,
  hasPagination = false,
  autoResetPage = false,
  manualPagination = false
}) => {
  const paginationProps =
    hasPagination && manualPagination
      ? {
          pageCount,
          autoResetPage,
          manualPagination: true,
          initialState: { pageIndex: 0, pageSize: 10 }
        }
      : {};
  const sortingProps =
    hasSorting && manualSortBy
      ? {
          manualSortBy: true,
          initialState: { sortBy }
        }
      : {};

  return {
    ...sortingProps,
    ...paginationProps,
    initialState: {
      ...sortingProps.initialState,
      ...paginationProps.initialState
    }
  };
};

function Table({
  data,
  columns,
  noResults,
  pageIndex,
  pageCount,
  sortBy: sortByValue,
  onSortBy,
  onSelectRows,
  onPageChange,
  hasSorting,
  manualSortBy,
  hasRowSelect,
  hasPagination,
  hasGroupSelection,
  autoResetPage,
  actionButtons,
  manualPagination,
  onActionItemClick,
  determineHideCell,
  disabledRowSelection,
  determineDisabledRowSelector,
  determineBackgroundColorRow,
  hasExpand,
  renderSubComponent
}) {
  const sortByRef = React.useRef(sortByValue);
  const disabledRowSelectionRef = React.useRef(disabledRowSelection);
  const hasActionButton = !isEmpty(actionButtons);
  const rowSelectProps = hasRowSelect
    ? [
        {
          id: SPECIFIC_COLUMN.ROW_SELECTION.id,
          width: 56,
          Header: ({ getToggleAllRowsSelectedProps, getToggleAllPageRowsSelectedProps }) =>
            hasRowSelect === 'radio' ? null : (
              <StyledCheckBox
                {...(hasPagination ? getToggleAllPageRowsSelectedProps() : getToggleAllRowsSelectedProps())}
                disabled={disabledRowSelectionRef.current}
              />
            ),
          Cell: ({ row, toggleAllRowsSelected = () => null, toggleAllPageRowsSelected = () => null }) => {
            const disableSelector = determineDisabledRowSelector(row);
            let RowSelector = StyledCheckBox;
            let rowSelectorProps = {};

            if (hasRowSelect === 'radio') {
              RowSelector = Radio;
              rowSelectorProps = {
                onClick: checked => {
                  if (!row.isSelected) {
                    if (hasPagination) toggleAllPageRowsSelected(false);
                    else toggleAllRowsSelected(false);
                    row.toggleRowSelected(checked);
                  }
                }
              };
            }

            return disableSelector ? (
              <RowSelector disabled={disabledRowSelectionRef.current || disableSelector} />
            ) : (
              <RowSelector
                {...row.getToggleRowSelectedProps()}
                {...rowSelectorProps}
                disabled={disabledRowSelectionRef.current || disableSelector}
              />
            );
          },
          SubCell: () => null
        }
      ]
    : [];
  const actionButtonProps = hasActionButton
    ? [
        {
          id: SPECIFIC_COLUMN.ACTION_BUTTON.id,
          Header: () => null,
          preventClick: true,
          Cell: ({ row }) => (
            <ActionButton onClick={onActionItemClick} items={actionButtons.map(item => ({ ...item, data: row }))} />
          )
        }
      ]
    : [];
  const expandProps = hasExpand
    ? [
        {
          Header: () => null,
          id: 'expander',
          width: 56,
          Cell: ({ row }) => (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? <Icon name='angle down' size='large' /> : <Icon name='angle right' size='large' />}
            </span>
          ),
          SubCell: () => null
        }
      ]
    : [];
  const tableProps = generateTableProps({
    pageCount,
    hasSorting,
    manualSortBy,
    hasPagination,
    autoResetPage,
    manualPagination,
    sortBy: sortByValue
  });

  const tableInstance = useTable(
    { columns, data, ...tableProps },
    hasSorting ? useSortBy : () => null,
    useExpanded,
    hasPagination ? usePagination : () => null,
    hasRowSelect ? useRowSelect : () => null,
    hooks => {
      (hasRowSelect || hasActionButton || hasExpand) &&
        hooks.visibleColumns.push(visibleColumns => [
          ...expandProps,
          ...rowSelectProps,
          ...visibleColumns,
          ...actionButtonProps
        ]);
    }
  );

  const {
    setSortBy,
    selectedFlatRows,
    toggleAllPageRowsSelected = () => null,
    state: { sortBy }
  } = tableInstance;
  const selectedRowsRef = React.useRef([]);
  const isMount = useIsMount();

  React.useEffect(
    () => {
      if (isMount) {
        if (!isEmpty(selectedRowsRef) && isDifference(selectedFlatRows, selectedRowsRef.current, item => item.id)) {
          selectedRowsRef.current = selectedFlatRows;
          onSelectRows({
            tableInstance,
            selectedRows: selectedFlatRows.map(d => d.original)
          });
        }

        if (disabledRowSelection !== disabledRowSelectionRef.current) {
          disabledRowSelectionRef.current = disabledRowSelection;
          toggleAllPageRowsSelected(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedFlatRows, disabledRowSelection]
  );

  React.useEffect(
    () => {
      if (isMount) {
        const { current } = sortByRef;
        if (isDifference(sortBy, current, item => `${item.id}${item.desc}`)) {
          sortByRef.current = sortBy;
          onSortBy(sortBy);
        } else if (isDifference(sortByValue, current, item => `${item.id}${item.desc}`)) {
          sortByRef.current = sortByValue;

          if (typeof setSortBy === 'function') {
            setSortBy(sortByValue);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortBy, sortByValue]
  );

  return (
    <StyledTable {...tableInstance.getTableProps()} sortable={hasSorting}>
      <TableHeader tableInstance={tableInstance} hasSorting={hasSorting} />
      <TableBody
        noResults={noResults}
        tableInstance={tableInstance}
        hasPagination={hasPagination}
        hasActionButton={hasActionButton}
        hasGroupSelection={hasGroupSelection}
        determineHideCell={determineHideCell}
        determineBackgroundColorRow={determineBackgroundColorRow}
        renderSubComponent={renderSubComponent}
      />
      {hasPagination && (
        <Pagination
          tableInstance={tableInstance}
          pageIndex={pageIndex}
          pageCount={pageCount}
          onPageChange={onPageChange}
        />
      )}
    </StyledTable>
  );
}

Table.defaultProps = {
  data: [],
  sortBy: [],
  columns: [],
  pageCount: 1,
  actionButtons: [],
  noResults: false,
  hasSorting: false,
  manualSortBy: false,
  hasRowSelect: false,
  hasPagination: false,
  hasGroupSelection: false,
  autoResetPage: false,
  manualPagination: false,
  disabledRowSelection: false,
  hasExpand: false,
  onSortBy: () => null,
  onSelectRows: () => null,
  onPageChange: () => null,
  onActionItemClick: () => null,
  determineHideCell: () => false,
  determineBackgroundColorRow: () => null,
  determineDisabledRowSelector: () => null,
  renderSubComponent: () => null
};

export default Table;
