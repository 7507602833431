import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import type { GameDTO } from './unity.model';

dayjs.extend(utc);

export class StreamingEventDTO {
  game: GameDTO;

  constructor(game: GameDTO) {
    this.game = game;
  }

  get key() {
    return this.game.key;
  }

  get id() {
    return this.game.id;
  }

  get headline() {
    return this.game.publishers?.[0]?.broadcasts?.[0]?.headline;
  }

  get subHeadline() {
    return this.game?.publishers?.[0]?.broadcasts?.[0]?.subheadline;
  }

  get sport() {
    return this.game.sport;
  }

  get gender() {
    return this.game.gender;
  }

  get level() {
    return this.game.level;
  }

  get siteUrl() {
    return this.game.site_url;
  }

  get startTime() {
    return this.game.start_time;
  }

  get status() {
    return this.game.status;
  }

  get timeZone() {
    return this.game.time_zone;
  }

  get broadcastKey() {
    return this.game?.publishers?.[0]?.broadcasts?.[0]?.key;
  }

  get broadcastStartTime() {
    return this.game?.publishers?.[0]?.broadcasts?.[0]?.start_time;
  }

  get broadcastDuration() {
    return this.game?.publishers?.[0]?.broadcasts?.[0]?.duration;
  }

  get broadcastEndTime() {
    return dayjs.utc(this.broadcastStartTime).add(this.broadcastDuration, 'hours').format();
  }

  get broadcastViewUrl() {
    return this.game?.publishers?.[0]?.broadcasts?.[0]?.view_url;
  }

  get broadcastPixellotKey() {
    return this.game?.publishers?.[0]?.broadcasts?.[0]?.pixellot_key;
  }

  get broadcastStatus() {
    return this.game?.publishers?.[0]?.broadcasts?.[0]?.status;
  }

  get broadcastUnlisted() {
    return this.game?.publishers?.[0]?.broadcasts?.[0]?.unlisted;
  }

  get vodKey() {
    return this.game?.publishers?.[0]?.vods?.[0]?.key;
  }

  get vodStatus() {
    return this.game?.publishers?.[0]?.vods?.[0]?.status;
  }

  get vodBlackoutStartDate() {
    return this.game?.publishers?.[0]?.vods?.[0]?.blackout_start_date;
  }

  get vodBlackoutEndDate() {
    return this.game?.publishers?.[0]?.vods?.[0]?.blackout_end_date;
  }

  get vodUnlisted() {
    return this.game?.publishers?.[0]?.vods?.[0]?.unlisted;
  }

  get vodThumbnail() {
    return this.game?.publishers?.[0]?.vods?.[0]?.thumbnail;
  }

  get vodDefaultThumbnail() {
    return this.game?.publishers?.[0]?.vods?.[0]?.default_thumbnail;
  }

  toJSON() {
    return {
      key: this.key,
      id: this.id,
      headline: this.headline,
      subHeadline: this.subHeadline,
      sport: this.sport,
      gender: this.gender,
      level: this.level,
      siteUrl: this.siteUrl,
      startTime: this.startTime,
      status: this.status,
      timeZone: this.timeZone,
      broadcastKey: this.broadcastKey,
      broadcastStartTime: this.broadcastStartTime,
      broadcastDuration: this.broadcastDuration,
      broadcastEndTime: this.broadcastEndTime,
      broadcastViewUrl: this.broadcastViewUrl,
      broadcastPixellotKey: this.broadcastPixellotKey,
      broadcastStatus: this.broadcastStatus,
      broadcastUnlisted: this.broadcastUnlisted,
      vodKey: this.vodKey,
      vodStatus: this.vodStatus,
      vodBlackoutStartDate: this.vodBlackoutStartDate,
      vodBlackoutEndDate: this.vodBlackoutEndDate,
      vodUnlisted: this.vodUnlisted,
      vodThumbnail: this.vodThumbnail,
      vodDefaultThumbnail: this.vodDefaultThumbnail
    };
  }
}
