import { all, take, put, call, fork, delay, select, cancel, cancelled } from 'redux-saga/effects';
import type { SagaIterator } from 'redux-saga';
import { cloneDeep, flatten, groupBy, isEmpty, unionBy, uniq, uniqBy, isNil } from 'lodash';
import moment from 'moment';

import { EVENT_DATE_FORMAT_WITH_TIMEZONE } from '@utils/dateUtils';
import { generateError, generateSuccessMessage } from '@utils/alertUtils';
import { UNEXPECTED_ERROR } from '@api/api/constants';
import { addNotification } from '@app/pages/Root/actions';

import {
  IGNORED_STATUS,
  WAITING_STATUS,
  PUBLISHED_STATUS,
  DUPLICATED_STATUS,
  ARBITER_PARTNER_NAME
} from '@modules/event-integrations_V2/constants/constants';
import EventScheduleService from '@modules/event-integrations_V2/services/event-schedule.service';
import type { AccountDTO } from '@gofan/api/accounts';
import type { EventDTO } from '@events/models/event.model';
import EventsService from '@events/services/events.service';
import type { GlobalEventDTO, SeasonDTO } from '@seasons/models/season.model';
import SeasonService from '@seasons/services/season.service';

import * as actions from '@season-management/middleware/actions';
import * as selectors from '@season-management/middleware/selectors';
import * as actionTypes from '@season-management/middleware/actionTypes';
import strings from '@season-management/constants/strings';
import {
  CREATION_MODE,
  PUBLISH_PROCESS,
  CREATION_PROCESS,
  IMPORT_DATA_MODE,
  BUILD_SEASON_PROCESS,
  EVENT_START_TIME_TYPE,
  PARTNER_IMPORT_MODE
} from '@season-management/constants/constant';
import SeasonCreationUtil from '@season-management/utils/season-creation.utils';
import { VenueService } from '@gofan/api/venues';
import SeasonCreationService from '@season-management/services/season-creation.service';
import type { ActivedSport } from '@season-management/middleware/types';
import type { RawEvent } from '@season-management/middleware/models/raw-event.model';
import type { RawSeason } from '@season-management/middleware/models/raw-season.model';
import RawSeasonModel from '@season-management/middleware/models/raw-season.model';
import type { CreatingSeason } from '@season-management/middleware/models/creating-season.model';
import CreatingSeasonModel from '@season-management/middleware/models/creating-season.model';
import MultiSeasonCreateRequest from '@season-management/middleware/models/season-create-request.model';
import MultiSeasonUpdateRequest from '@season-management/middleware/models/season-update-request.model';
import type { EventScheduleSeasonDTO } from '@modules/event-integrations_V2/models/event-schedule.model';
import type { ActivityDTO } from '@gofan/api/activities';
import ErrorDTO from '@app/api/dto/ErrorDTO';
import LocalStorage from '@app/LocalStorage';
import { mappingAccountVenue } from '@app/pages/EventInformationV2/components/RequiredLayout/components/VenueSection/VenueSection';
import { RESOLVE_DUPLICATED_TYPES } from '@app/pages/SeasonSetup/constants';
import { DISTRIBUTION_CHANNEL } from '@app/api/model/request/ProductRequest';

export function* buildSeasonForMultiSport(action: actionTypes.OnBuildMultiSportAction): SagaIterator<void> {
  try {
    const { account, districtAccount, groupTicketType, isShowBuildingModal } = action.payload;
    const multiSportConfig: any = yield select(selectors.multiSportConfigSelector);
    const selectedSports: any[] = yield select(selectors.selectedSportSelector);
    const financialAccount: AccountDTO = yield select(selectors.financialAccountSelector);
    const taggedAccounts = SeasonCreationUtil.generateTaggedAccountsByDistrict(account, districtAccount);

    if (isEmpty(multiSportConfig) || isEmpty(account) || isEmpty(groupTicketType) || isEmpty(selectedSports)) {
      yield put(
        actions.onUpdateBuildProcess({
          error: { message: UNEXPECTED_ERROR },
          process: BUILD_SEASON_PROCESS.CANCEL_BUILD
        })
      );
      return;
    }

    // [STEP_1][GET_RAW_EVENT_BY_MODE_IMPORT]
    yield put(actions.onUpdateBuildProcess({ process: BUILD_SEASON_PROCESS.START_BUILD }));
    let rawEvents: RawEvent[] = [];

    if (multiSportConfig?.modeImportData === IMPORT_DATA_MODE.FILE_UPLOAD) {
      rawEvents = SeasonCreationUtil.buildRawEventByFileUpload({
        account,
        creatingSeason: multiSportConfig
      });
    }
    // [STEP_2][BUILDING_RAW_SEASON]
    yield put(actions.onUpdateBuildProcess({ process: BUILD_SEASON_PROCESS.BUILDING }));
    yield delay(isShowBuildingModal ? 1000 : 0);
    let creatingSeasonList: CreatingSeason[] = [];

    if (multiSportConfig?.modeImportData === IMPORT_DATA_MODE.FILE_UPLOAD) {
      creatingSeasonList = SeasonCreationUtil.buildCreatingSeasonListByMultiSportUpload({
        account,
        rawEvents,
        selectedSports,
        multiSportConfig,
        financialAccount,
        taggedAccounts,
        groupTicketType
      });
    }

    // [STEP_3][OPENING_RAW_SEASON]
    yield put(actions.onUpdateBuildProcess({ process: BUILD_SEASON_PROCESS.BUILT }));
    yield delay(isShowBuildingModal ? 1000 : 0);
    if (multiSportConfig?.modeImportData === IMPORT_DATA_MODE.FILE_UPLOAD) {
      yield put(addNotification(generateSuccessMessage(strings.SUCCESS_UPLOAD_FILE_FOR_MULTI_SPORT)));
    }

    yield put(
      actions.setDataToSetupMultiSeason({
        creationMethod: '',
        multiSportConfig: null,
        creatingSeasonList
      })
    );
    yield put(
      actions.setActivedSport(
        SeasonCreationUtil.initActivedSport({
          accountId: account?.id,
          sport: selectedSports?.[0]
        })
      )
    );
  } catch (error) {
    yield put(
      actions.onUpdateBuildProcess({
        error: { message: UNEXPECTED_ERROR },
        process: BUILD_SEASON_PROCESS.CANCEL_BUILD
      })
    );
  } finally {
    if (yield cancelled()) {
      yield put(actions.onUpdateBuildProcess(null));
    } else {
      yield put(actions.onUpdateBuildProcess(null));
      yield put(actions.onCancelledTaskBuildMultiSport());
    }
  }
}

export function* buildSeasonForSingleSport(action: actionTypes.OnBuildSingleSportAction): SagaIterator<void> {
  try {
    const { account, districtAccount, activity } = action.payload;
    const storeCreatingSeason: CreatingSeason = yield select(selectors.creatingSeasonSelector);
    const financialAccount: AccountDTO = yield select(selectors.financialAccountSelector);
    const taggedAccounts = SeasonCreationUtil.generateTaggedAccountsByDistrict(account, districtAccount);
    let creatingSeason: CreatingSeason = cloneDeep(storeCreatingSeason);
    if (isEmpty(creatingSeason) || isEmpty(account) || isEmpty(activity)) {
      yield put(
        actions.onUpdateBuildProcess({
          error: { message: UNEXPECTED_ERROR },
          process: BUILD_SEASON_PROCESS.CANCEL_BUILD
        })
      );
      return;
    }

    // [STEP_1][GET_RAW_EVENT_BY_MODE_IMPORT]
    yield put(actions.onUpdateBuildProcess({ process: BUILD_SEASON_PROCESS.START_BUILD }));
    let rawEvents: RawEvent[] = [];
    if (creatingSeason?.modeImportData === IMPORT_DATA_MODE.MANUAL) {
      rawEvents = SeasonCreationUtil.buildRawEventByManualImport({
        account,
        activity,
        creatingSeason
      });
    } else if (creatingSeason?.modeImportData === IMPORT_DATA_MODE.FILE_UPLOAD) {
      rawEvents = SeasonCreationUtil.buildRawEventByFileUpload({
        account,
        activity,
        creatingSeason
      });
    } else if (creatingSeason?.modeImportData === IMPORT_DATA_MODE.FROM_ARBITER) {
      let arbiterEvents: GlobalEventDTO[] = [];
      arbiterEvents = yield call(SeasonService.fetchGlobalEvents, {
        partnerName: ARBITER_PARTNER_NAME,
        schoolId: creatingSeason?.accountId,
        sportName: creatingSeason?.sport?.label,
        status: WAITING_STATUS,
        levelGenders: SeasonCreationUtil.getLevelGendersToCheckArbiter({
          genders: creatingSeason?.genders,
          levelsByGender: creatingSeason?.levelsByGender
        })
      });
      const opponentSchools: AccountDTO[] = yield call(
        SeasonService.fetchArbiterSchools,
        unionBy(arbiterEvents, (event: GlobalEventDTO) => event?.awaySchoolId).map(
          (arbiterEvent: GlobalEventDTO) => arbiterEvent.awaySchoolId
        ) as string[]
      );
      creatingSeason = {
        ...creatingSeason,
        fromArbiterSetting: {
          arbiterEvents,
          opponentSchools
        }
      };
      rawEvents = SeasonCreationUtil.buildRawEventByImportFromArbiter({
        account,
        activity,
        creatingSeason
      });
    }

    // [STEP_2][BUILDING_RAW_SEASON]
    yield put(actions.onUpdateBuildProcess({ process: BUILD_SEASON_PROCESS.BUILDING }));
    let rawSeasons: RawSeason[] = [];

    const ticketTypes = SeasonCreationUtil.filterAccountTicketTypesBy(creatingSeason);

    const groupedAccountTicketTypes = SeasonCreationUtil.groupAccountTicketTypesBy({
      ...creatingSeason,
      sportId: creatingSeason.id,
      ticketTypes
    });

    if (creatingSeason?.modeImportData === IMPORT_DATA_MODE.MANUAL) {
      rawSeasons = SeasonCreationUtil.buildRawSeasonByManualImport({
        account,
        activity,
        rawEvents,
        creatingSeason,
        groupedAccountTicketTypes,
        financialAccount,
        taggedAccounts,
        products: creatingSeason.seasonTickets
      });
    } else if (creatingSeason?.modeImportData === IMPORT_DATA_MODE.FILE_UPLOAD) {
      rawSeasons = SeasonCreationUtil.buildRawSeasonByFileUpload({
        account,
        activity,
        rawEvents,
        creatingSeason,
        groupedAccountTicketTypes,
        financialAccount,
        taggedAccounts,
        products: creatingSeason.seasonTickets
      });
    } else if (creatingSeason?.modeImportData === IMPORT_DATA_MODE.FROM_ARBITER) {
      rawSeasons = SeasonCreationUtil.buildRawSeasonByImportFromArbiter({
        account,
        activity,
        rawEvents,
        creatingSeason,
        groupedAccountTicketTypes,
        financialAccount,
        taggedAccounts
      });
    }

    // [STEP_3][OPENING_RAW_SEASON]
    yield put(actions.onUpdateBuildProcess({ process: BUILD_SEASON_PROCESS.BUILT }));

    const activedSport: ActivedSport = yield select(selectors.activedSportSelector);
    const newActivedSport = SeasonCreationUtil.getActivedSportBySeason({
      activedSport,
      creatingSeason: {
        ...creatingSeason,
        creationProcess: CREATION_PROCESS.EVENT_SETUP
      }
    });

    yield put(
      actions.onUpdateSeason(
        new CreatingSeasonModel({
          ...creatingSeason,
          rawSeasons,
          creationProcess: CREATION_PROCESS.EVENT_SETUP
        })
          .simplify()
          .toJSON()
      )
    );

    yield put(actions.setActivedSport(newActivedSport));

    if (creatingSeason?.modeImportData === IMPORT_DATA_MODE.FILE_UPLOAD) {
      yield put(addNotification(generateSuccessMessage(strings.SUCCESS_UPLOAD_FILE)));
    } else if (
      creatingSeason?.modeImportData === IMPORT_DATA_MODE.MANUAL &&
      creatingSeason?.creationMode === CREATION_MODE.ONE_PER_SPORT &&
      creatingSeason?.eventStartTimeType === EVENT_START_TIME_TYPE.EACH_TEAM
    ) {
      yield put(
        addNotification(
          generateSuccessMessage(
            strings.APPLIED_DIFFERENT_START_TIMES_SUBTITLE,
            strings.APPLIED_DIFFERENT_START_TIMES_TITLE
          )
        )
      );
    }
  } catch (error) {
    yield put(
      actions.onUpdateBuildProcess({
        error: { message: UNEXPECTED_ERROR },
        process: BUILD_SEASON_PROCESS.CANCEL_BUILD
      })
    );
  } finally {
    if (yield cancelled()) {
      yield put(actions.onUpdateBuildProcess(null));
    } else {
      yield put(actions.onUpdateBuildProcess(null));
      yield put(actions.onCancelledTaskBuildSingleSport());
    }
  }
}

export function* updatePartnerEventStatus(action: actionTypes.OnUpdatePartnerEventsStatusAction): SagaIterator<void> {
  try {
    const { partnerEvents = [], seasonsPublished = [] } = action.payload?.partnerEventsStatusData;

    const events: EventDTO[] = yield call(
      EventsService.searchByIds,
      flatten(seasonsPublished.map(season => season.eventIds))
    );
    const currentDateTime = moment().format(EVENT_DATE_FORMAT_WITH_TIMEZONE);
    const globalEventSeasonInfo: any[] = [];
    const publishedEventsInfo: GlobalEventDTO[] = [];
    const duplicatedEventsInfo: GlobalEventDTO[] = [];

    seasonsPublished.forEach((season: SeasonDTO) =>
      season?.eventIds?.forEach((eventId: number) => {
        const eventPublished = events.find((event: EventDTO) => event.id === eventId);

        if (eventPublished && eventPublished.globalEventsId) {
          publishedEventsInfo.push({
            id: eventPublished.globalEventsId,
            gofanEventId: `${eventId}`,
            gofanSeasonId: `${season.id}`,
            gofanStatusMsg: PUBLISHED_STATUS,
            startEventDt: eventPublished.startDateTime,
            endEventDt: eventPublished.endDateTime,
            gofanLastPublished: currentDateTime,
            gofanStatusUpdatedDt: currentDateTime
          });

          const partnerEvent = partnerEvents.find(event => event.id === eventPublished.globalEventsId);

          if (partnerEvent) {
            if (partnerEvent?.resolveType === RESOLVE_DUPLICATED_TYPES.ONLY_KEPT_GF_EVENT) {
              publishedEventsInfo.pop();
              duplicatedEventsInfo.push({
                id: eventPublished.globalEventsId,
                gofanEventId: `${eventId}`,
                gofanSeasonId: `${season.id}`,
                gofanStatusMsg: DUPLICATED_STATUS,
                startEventDt: eventPublished.startDateTime,
                endEventDt: eventPublished.endDateTime,
                gofanLastPublished: currentDateTime,
                gofanStatusUpdatedDt: currentDateTime
              });
            }

            globalEventSeasonInfo.push({
              eventLevel: partnerEvent.eventLevel,
              gender: partnerEvent.gender,
              homeSchoolId: partnerEvent.homeSchoolId,
              eventReportingType: partnerEvent.eventReportingType,
              gofanSeasonId: `${season.id}`
            });
          }
        }
      })
    );

    const partnerEventsIgnore = partnerEvents?.filter(
      (arbiterEvent: GlobalEventDTO) =>
        !events.map((event: EventDTO) => event.globalEventsId).includes(arbiterEvent?.id)
    );

    const ignoredEventsInfo: GlobalEventDTO[] = [];
    (partnerEventsIgnore ?? []).forEach((ignoredEvent: GlobalEventDTO) => {
      const seasonId = globalEventSeasonInfo.find(
        value =>
          value.homeSchoolId === ignoredEvent.homeSchoolId &&
          value.gender === ignoredEvent.gender &&
          value.eventLevel === ignoredEvent.eventLevel &&
          value.eventReportingType === ignoredEvent.eventReportingType
      )?.gofanSeasonId;
      ignoredEventsInfo.push({
        id: ignoredEvent.id,
        gofanSeasonId: seasonId,
        gofanStatusMsg: IGNORED_STATUS,
        gofanStatusUpdatedDt: currentDateTime
      });
    });

    yield all([
      call(EventScheduleService.updateGlobalEvents, publishedEventsInfo),
      call(EventScheduleService.updateGlobalEvents, duplicatedEventsInfo),
      call(EventScheduleService.updateGlobalEvents, ignoredEventsInfo)
    ]);
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message ?? UNEXPECTED_ERROR;
    addNotification(generateError(errorMessage));
  }
}

export function* onBuildPartnerSeason(action: actionTypes.OnBuildAllPartnerSeasonsAction): SagaIterator<void> {
  try {
    const { account, levels, accounts, activities, schedules, ticketTypesByAccount, isBoxOfficeAccount, seasonVenue } =
      action.payload;

    if (
      isEmpty(account) ||
      isEmpty(levels) ||
      isEmpty(accounts) ||
      isEmpty(activities) ||
      isEmpty(schedules) ||
      isEmpty(ticketTypesByAccount)
    ) {
      yield put(
        actions.onUpdateBuildProcess({
          error: { message: UNEXPECTED_ERROR },
          process: BUILD_SEASON_PROCESS.CANCEL_BUILD
        })
      );
      return;
    }

    yield put(actions.onUpdateBuildProcess({ process: BUILD_SEASON_PROCESS.START_BUILD }));

    const opponentSchoolIds = uniq(
      schedules.reduce(
        (awaySchoolIds: string[], schedule: EventScheduleSeasonDTO) => [
          ...awaySchoolIds,
          ...schedule?.events?.map(event => event?.awaySchoolId ?? '')
        ],
        []
      )
    );
    const opponentSchools: AccountDTO[] = !isEmpty(opponentSchoolIds)
      ? yield call(SeasonService.fetchArbiterSchools, opponentSchoolIds)
      : [];

    yield put(actions.onUpdateBuildProcess({ process: BUILD_SEASON_PROCESS.BUILDING }));

    const homeSchoolId = account?.id;
    const financialAccount = { ...account } as AccountDTO;
    const creatingSeasonList: CreatingSeason[] = [];

    const groupSchedules: any = groupBy(schedules, 'sportName');

    Object.keys(groupSchedules).forEach(sportName => {
      const creationMode =
        groupSchedules[sportName]?.length > 1 ? CREATION_MODE.SPERATE_EACH_TEAM : CREATION_MODE.ONE_PER_SPORT;

      const homeSchool =
        accounts?.find((account: AccountDTO) => `${account.id}` === `${homeSchoolId}`) ?? ({} as AccountDTO);

      const foundAccountTickets =
        ticketTypesByAccount?.find((accountTickets: any) => `${accountTickets?.schoolId}` === `${homeSchoolId}`)
          ?.tickets ?? [];

      const accountTicketTypes = foundAccountTickets?.filter(
        ticket =>
          isBoxOfficeAccount || !ticket.distributionChannel || ticket.distributionChannel === DISTRIBUTION_CHANNEL.GOFAN
      );

      const homeVenue = seasonVenue || mappingAccountVenue(homeSchool?.venues?.[0]);

      const activity =
        activities?.find(({ label }: ActivityDTO) => `${label}`.toLowerCase() === `${sportName}`.toLowerCase()) ??
        ({} as ActivityDTO);

      const { genders, levelsByGender } = SeasonCreationUtil.getGenderLevelValueForMultipleSchedule({
        levels,
        schedules: groupSchedules[sportName]
      });

      const partnerName = groupSchedules[sportName]?.[0]?.sourceName ?? '';

      let creatingSeason: CreatingSeason = {
        id: activity?.id,
        name: sportName,
        sport: activity,
        accountId: homeSchool?.id,
        venue: homeVenue ?? {},
        genders,
        levelsByGender,
        genderSpecific: true,
        creationMode,
        creationProcess: CREATION_PROCESS.EVENT_SETUP,
        eventStartTimeType: EVENT_START_TIME_TYPE.ALL_TEAMS,
        accountTicketTypes,
        rawSeasons: [],
        modeImportData: PARTNER_IMPORT_MODE,
        fromPartnerSetting: {
          partnerName,
          partnerEvents: [],
          schedules: groupSchedules[sportName] ?? []
        }
      };

      // [STEP_1][GET_RAW_EVENT_BY_MODE_IMPORT]
      let rawEvents: RawEvent[] = [];

      (groupSchedules[sportName] ?? []).forEach((schedule: EventScheduleSeasonDTO) => {
        const eventsWithUpdatedVenue = seasonVenue
          ? schedule?.events?.map(event => ({
              ...event,
              venueAddress: seasonVenue?.address,
              venueCity: seasonVenue?.city,
              venueLocationName: seasonVenue?.location,
              venueName: seasonVenue?.name,
              venueState: seasonVenue?.state,
              venueZip: seasonVenue?.zipCode
            }))
          : schedule?.events;
        const partnerEvents: GlobalEventDTO[] = [...(eventsWithUpdatedVenue ?? [])];

        creatingSeason = {
          ...creatingSeason,
          fromPartnerSetting: {
            ...creatingSeason.fromPartnerSetting,
            partnerName,
            partnerEvents: [...(creatingSeason.fromPartnerSetting?.partnerEvents ?? []), ...partnerEvents]
          }
        };

        const rawEventsValue = SeasonCreationUtil.buildRawEventByPartner({
          account: homeSchool,
          activity,
          partnerEvents,
          creatingSeason,
          opponentSchools: opponentSchools?.filter(item =>
            partnerEvents?.find(event => `${event.awaySchoolId}` === `${item.id}`)
          )
        });

        rawEvents = [...rawEvents, ...rawEventsValue];
      });

      // [STEP_2][BUILDING_RAW_SEASON]
      let rawSeasons: RawSeason[] = [];

      const ticketTypes = SeasonCreationUtil.filterAccountTicketTypesBy({
        ...creatingSeason,
        activityId: activity?.id,
        isBoxOfficeAccount
      });
      const groupedAccountTicketTypes = SeasonCreationUtil.groupAccountTicketTypesBy({
        ...creatingSeason,
        sportId: creatingSeason.id,
        ticketTypes
      });

      rawSeasons = SeasonCreationUtil.buildRawSeasonByPartner({
        partnerName,
        activity,
        account: homeSchool,
        financialAccount,
        rawEvents,
        creatingSeason,
        groupedAccountTicketTypes
      });

      const newCreatingSeason = new CreatingSeasonModel({
        ...creatingSeason,
        rawSeasons
      })
        .simplify()
        .toJSON();

      creatingSeasonList.push(newCreatingSeason);
    });

    yield put(actions.onUpdateBuildProcess({ process: BUILD_SEASON_PROCESS.BUILT }));
    yield put(
      actions.setDataToSetupMultiSeason({
        financialAccount,
        financialAccountId: financialAccount?.id,
        selectedSports: creatingSeasonList.map(({ sport }) => sport),
        creatingSeasonList
      })
    );
  } catch (error) {
    yield put(
      actions.onUpdateBuildProcess({
        error: { message: UNEXPECTED_ERROR },
        process: BUILD_SEASON_PROCESS.CANCEL_BUILD
      })
    );
  } finally {
    if (yield cancelled()) {
      yield put(actions.onUpdateBuildProcess(null));
    } else {
      yield put(actions.onUpdateBuildProcess(null));
      yield put(actions.onCancelledTaskBuildAllPartnerSeasons());
    }
  }
}

export function* publishSingleSeason(action: actionTypes.OnPublishSingleSeasonAction): SagaIterator<void> {
  try {
    yield put(actions.onUpdatePublishProcess({ process: PUBLISH_PROCESS.PUBLISHING }));
    const { account, activity, dirtyFields } = action.payload;
    const storeCreatingSeason: CreatingSeason = yield select(selectors.creatingSeasonSelector);

    if (isEmpty(storeCreatingSeason) || isEmpty(account) || isEmpty(activity)) {
      yield put(
        actions.onUpdatePublishProcess({
          error: { message: UNEXPECTED_ERROR },
          process: PUBLISH_PROCESS.PUBLISH_ERROR
        })
      );
      return;
    }

    const creatingSeason = SeasonCreationUtil.filterOutDeletedRawEvent([storeCreatingSeason])[0];

    let response: SeasonDTO[] = [];
    let refreshedRequest: CreatingSeason = { ...creatingSeason };

    if (creatingSeason?.created) {
      const updateRequest = new MultiSeasonUpdateRequest()
        .setDirtyFields(dirtyFields)
        .populate({ account, activity, creatingSeason })
        .toJSON();

      response = yield call(SeasonCreationService.updateMultiSeasons, updateRequest);
      refreshedRequest = {
        ...creatingSeason,
        rawSeasons: uniqBy(
          [
            ...creatingSeason?.rawSeasons.map(season => new RawSeasonModel({ seasonId: season.seasonId }).toJSON()),
            ...response.map((season: SeasonDTO) => new RawSeasonModel({ seasonId: season.id }).toJSON())
          ],
          'seasonId'
        )
      };
    } else {
      const createRequest = new MultiSeasonCreateRequest(undefined, { account, activity, creatingSeason })
        .populate()
        .toJSON();

      response = yield call(SeasonCreationService.createMultiSeasons, createRequest);
      refreshedRequest = {
        ...creatingSeason,
        rawSeasons: response.map((season: SeasonDTO) => new RawSeasonModel({ seasonId: season.id }).toJSON())
      };
    }

    // Update partner event status
    if (creatingSeason?.modeImportData === PARTNER_IMPORT_MODE) {
      yield call(
        updatePartnerEventStatus,
        actions.onUpdatePartnerEventsStatus({
          partnerEvents: creatingSeason?.fromPartnerSetting?.partnerEvents ?? [],
          seasonsPublished: response
        }) as actionTypes.OnUpdatePartnerEventsStatusAction
      );
    }

    // Sync Seats Io for reserved seating seasons
    const venuesToSync = new Set<number>();

    (creatingSeason?.seasonTickets ?? []).forEach(ticket => {
      if (!isNil(creatingSeason?.venue?.id) && ticket.reservedSeating === true)
        venuesToSync.add(creatingSeason?.venue?.id);
    });

    (creatingSeason?.accountTicketTypes ?? []).forEach(ticket => {
      if (!isNil(creatingSeason?.venue?.id) && ticket.reservedSeating === true)
        venuesToSync.add(creatingSeason?.venue?.id);
    });

    const venuesToSyncArray = Array.from(venuesToSync);

    if (venuesToSyncArray.length > 0) {
      Promise.all(venuesToSyncArray.map(venueId => VenueService.syncVenueChart(venueId)));
    }

    const updatedCreatingSeason = yield call(SeasonCreationService.refreshSeasonBySport, {
      creatingSeason: refreshedRequest
    });

    yield put(
      actions.onUpdateSeason(
        new CreatingSeasonModel({
          ...updatedCreatingSeason,
          created: true
        })
          .simplify()
          .toJSON()
      )
    );

    yield put(
      actions.onUpdatePublishProcess({
        process: PUBLISH_PROCESS.PUBLISHED,
        data: { updatedCreatingSeason }
      })
    );
    yield delay(creatingSeason?.created ? 200 : 2000);
    yield put(actions.onUpdatePublishProcess(null));

    const nextCreatingSeason: CreatingSeason = yield select(selectors.creatingSeasonSelector);

    if (nextCreatingSeason?.created && nextCreatingSeason?.creationProcess !== CREATION_PROCESS.EVENT_DETAILS) {
      yield put(
        actions.onUpdateSeason({
          id: nextCreatingSeason?.id,
          creationProcess: CREATION_PROCESS.EVENT_DETAILS
        })
      );
    }

    if (creatingSeason?.created) {
      yield put(addNotification(generateSuccessMessage(strings.SUCCESS_UPLOAD_SEASON_PER_SPORT)));
    }
  } catch (error) {
    const currentUser = LocalStorage.getStorageCurrentUser();
    const errorMessage = new ErrorDTO(error).getErrorMessageByRole(currentUser.role) || UNEXPECTED_ERROR;
    yield put(
      actions.onUpdatePublishProcess({
        error: { message: errorMessage },
        process: PUBLISH_PROCESS.PUBLISH_ERROR
      })
    );
    yield delay(2000);
  } finally {
    if (yield cancelled()) {
      yield put(actions.onUpdatePublishProcess(null));
    } else {
      yield put(actions.onUpdatePublishProcess(null));
      yield put(actions.onCancelledTaskPublishSingleSeason());
    }
  }
}

// [WATCH_TASKs]

export function* watchBuildSeasonForMultiSport(): SagaIterator<void> {
  let action;
  // eslint-disable-next-line no-cond-assign
  while ((action = yield take(actionTypes.ON_BUILD_MULTI_SPORT))) {
    const bgSyncTask = yield fork(buildSeasonForMultiSport, action);
    yield take(actionTypes.ON_CANCELLED_TASK_BUILD_MULTI_SPORT);
    yield cancel(bgSyncTask);
  }
}

export function* watchBuildSeasonForSingleSport(): SagaIterator<void> {
  let action;
  // eslint-disable-next-line no-cond-assign
  while ((action = yield take(actionTypes.ON_BUILD_SINGLE_SPORT))) {
    const bgSyncTask = yield fork(buildSeasonForSingleSport, action);
    yield take(actionTypes.ON_CANCELLED_TASK_BUILD_SINGLE_SPORT);
    yield cancel(bgSyncTask);
  }
}

export function* watchBuildAllPartnerSeasons(): SagaIterator<void> {
  let action;
  // eslint-disable-next-line no-cond-assign
  while ((action = yield take(actionTypes.ON_BUILD_ALL_PARTNER_SEASONS))) {
    const bgSyncTask = yield fork(onBuildPartnerSeason, action);
    yield take(actionTypes.ON_CANCELLED_TASK_BUILD_ALL_PARTNER_SEASONS);
    yield cancel(bgSyncTask);
  }
}

export function* watchPublishSingleSeason(): SagaIterator<void> {
  let action;
  // eslint-disable-next-line no-cond-assign
  while ((action = yield take(actionTypes.ON_PUBLISH_SINGLE_SEASON))) {
    const bgSyncTask = yield fork(publishSingleSeason, action);
    yield take(actionTypes.ON_CANCELLED_TASK_PUBLISH_SINGLE_SEASON);
    yield cancel(bgSyncTask);
  }
}

export default [
  watchBuildAllPartnerSeasons(),
  watchBuildSeasonForMultiSport(),
  watchBuildSeasonForSingleSport(),
  watchPublishSingleSeason()
];
