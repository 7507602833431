import { useEffect, useContext } from 'react';
import { FastFormContext } from './FastFormBase';

export default ({ formKey }) => {
  const fastFormContext = useContext(FastFormContext);
  const formValue = fastFormContext.form[formKey];
  useEffect(() => {
    fastFormContext.updateField(formKey, { ...formValue, inForm: true });
  }, []);
  return null;
};
