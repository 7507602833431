import { TextArea as CarbonTextArea, TextAreaSkeleton as CarbonTextAreaSkeleton } from '@carbon/react';
import classNames from 'classnames';

import './TextArea.scss';

interface TextAreaProps {
  className?: string;
  cols?: number;
  defaultValue?: string | number;
  disabled?: boolean;
  enableCounter?: boolean;
  helperText?: string;
  hideLabel?: boolean;
  id?: string;
  invalid?: boolean;
  invalidText?: string;
  labelText: string;
  light?: boolean;
  maxCount?: number;
  onChange?: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (evt: React.FocusEvent<HTMLTextAreaElement>) => void;
  onClick?: (evt: React.MouseEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  rows?: number;
  value?: string | number;
  warn?: boolean;
  warnText?: string;
  style?: React.CSSProperties;
}

export const TextArea = (props: TextAreaProps) => (
  <CarbonTextArea {...props} className={classNames('gf--text-area', props.className)} />
);

export const TextAreaSkeleton = CarbonTextAreaSkeleton;
