import { PromotionRepository } from './promotion.repository';

import { AccessLevelEnum } from './promotion.model';
import type {
  PromotionDTO,
  PromotionsSearchParams,
  PromotionInsightDTO,
  PromotionsUpdateDTO,
  PromotionDuplicateRequest,
  BulkPromotionRequest,
  BulkDeletePromotions,
  PromotionLockSearch,
  PromotionCount
} from './promotion.model';
import type { EventProduct } from '../products';

const defaultSearchParams = {
  page: 0,
  pageSize: 25
};

class PromotionService {
  static getPromotionsByProductId = (id: number, params?: PromotionsSearchParams) => {
    const queryParams = { ...defaultSearchParams, ...params };
    const queryString = `size=${queryParams.pageSize}&page=${queryParams.page}`;
    return PromotionRepository.getPromotionsByProductId(id, queryString);
  };

  static getPromotionsByCodes = (code: string, params?: PromotionsSearchParams) => {
    const queryParams = { ...defaultSearchParams, ...params };
    const queryString = `size=${queryParams.pageSize}&page=${queryParams.page}`;
    return PromotionRepository.getPromotionsByCode(code, queryString);
  };

  static searchPromotionCodes = (params?: PromotionsSearchParams) => {
    const queryParams = [
      { key: 'keyword', value: params?.keyword },
      { key: 'accessLevels', value: params?.accessLevels },
      { key: 'eventId', value: params?.eventId },
      { key: 'required', value: params?.required },
      { key: 'page', value: params?.page ?? defaultSearchParams?.page },
      { key: 'size', value: params?.pageSize ?? defaultSearchParams?.pageSize },
      { key: 'productIds', value: params?.productIds?.join(',') },
      { key: 'sort', value: params?.sortBy }
    ].filter(item => {
      if (item.key === 'page') {
        return item.value > -1;
      }
      if (item.key !== 'required') {
        return !!item.value;
      }

      return item;
    });

    const queryString = queryParams?.reduce((res, cur) => {
      const prefix = res === '' ? '?' : '&';
      return `${res}${prefix}${cur?.key}=${cur?.value}`;
    }, '');

    return PromotionRepository.searchPromotionCodes(queryString);
  };

  static getInsightOfPromotion = async (promoCodeId: any): Promise<PromotionInsightDTO> => {
    await PromotionRepository.deletePromotionCache(promoCodeId);

    return PromotionRepository.getInsightOfPromotion(promoCodeId);
  };

  static getInsightOfPromotions = async (promoCodeIds: any) => {
    const allInsightPromos = await Promise.all(
      (promoCodeIds || []).map((promoCodeId: any) => PromotionService.getInsightOfPromotion(promoCodeId))
    );

    return allInsightPromos as PromotionInsightDTO[];
  };

  static createPromotion = (promo: PromotionDTO) => PromotionRepository.createPromotion(promo);

  static createPromotions = async (promotions: PromotionDTO[]) => {
    const allPromos = await Promise.all(
      (promotions || []).map((promo: PromotionDTO) => PromotionService.createPromotion(promo))
    );

    return { allPromos };
  };

  static createBulkPromotions = (bulkPromotionRequest: BulkPromotionRequest) =>
    PromotionRepository.createBulkPromotions(bulkPromotionRequest);

  static getStatusPromotions = (batchId: string) => PromotionRepository.getStatusPromotion(batchId);

  // Code[] type can be found in AccessCodes.tsx in apps/hq
  static addProductAssociationsToPromotionCodes = ({ codes, products }: { codes: any[]; products: EventProduct[] }) =>
    (codes ?? []).map(code => ({
      ...code,
      productAssociations: (products ?? []).map(product => ({
        productId: product.id,
        productSpecificLimit: 0
      }))
    }));

  static deletePromotion = (promoId: string | number) => PromotionRepository.deletePromotion(promoId);

  static updatePromotion = (promo: PromotionDTO) => PromotionRepository.updatePromotion(promo);

  static updatePromotions = async (promotions: PromotionDTO[]) => {
    const allPromos = await Promise.all(
      (promotions || []).map((promo: PromotionDTO) => PromotionService.updatePromotion(promo))
    );

    return allPromos as PromotionDTO[];
  };

  static updatePromotionsOfEvent = (promo: PromotionsUpdateDTO) => PromotionRepository.updatePromotionsOfEvent(promo);

  static hasEventLevelAccessCode(eventId: number) {
    const params = { eventId, accessLevels: [AccessLevelEnum.EVENT], required: true, page: 0, pageSize: 1 };
    return PromotionService.searchPromotionCodes(params).then(res => (res?.totalElements ?? 0) > 0);
  }

  static async hasPromotionCode(eventId: number | string): Promise<PromotionCount> {
    const params = {
      eventId,
      required: true,
      page: 0,
      pageSize: 1,
      sortBy: ['createdAt', 'desc']
    };

    let latestAccessCode = {} as PromotionDTO;
    const totalAccessCode = await PromotionService.searchPromotionCodes(params)
      .then(res => {
        latestAccessCode = res?.content?.[0] as PromotionDTO;
        return res?.totalElements ?? 0;
      })
      .catch(() => 0);

    let latestPromotionCode = {} as PromotionDTO;
    const totalPromotionCode = await PromotionService.searchPromotionCodes({
      ...params,
      required: false
    })
      .then(res => {
        latestPromotionCode = res?.content?.[0] as PromotionDTO;
        return res?.totalElements ?? 0;
      })
      .catch(() => 0);

    return {
      totalAccessCode,
      hasAccessCode: totalAccessCode > 0,
      latestAccessCode,
      totalPromotionCode,
      hasPromotionCode: totalPromotionCode > 0,
      latestPromotionCode
    };
  }

  static checkDuplicatePromotion = (payload: PromotionDuplicateRequest) =>
    PromotionRepository.checkDuplicatePromotion(payload);

  static bulkDeletePromotions = (params: BulkDeletePromotions) => PromotionRepository.bulkDeletePromotions(params);

  static searchPromotionLock = (params: PromotionLockSearch) => PromotionRepository.searchPromotionLock(params);

  static evictPromotionsSearchCache = () => PromotionRepository.evictPromotionsSearchCache();

  static getJobPromotionInfo = (jobId: number | string) => PromotionRepository.getJobPromotionInfo(jobId);
}

export { PromotionService };
