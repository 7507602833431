// @flow
import { RateListState } from './types';
import {
  RateListActionTypes,
  RESET_STATE,
  SEARCH_RATES,
  SEARCH_RATES_SUCCESS,
  INSERT_RATE_RECENTLY
} from './actionTypes';

export const initialState: RateListState = {
  pageIndex: 0,
  pageCount: 1,
  rates: [],
  isSearching: false,
  isSearched: false,
  rateRecently: {} 
};

export default (state = initialState, action: RateListActionTypes) => {
  switch (action.type) {
    case RESET_STATE: {
      return { ...initialState };
    }

    case SEARCH_RATES: {
      return {
        ...state,
        isSearching: true
      };
    }

    case SEARCH_RATES_SUCCESS: {
      const { searchResult } = action.payload;
      const { pageIndex, pageCount, data } = searchResult;
      return {
        ...state,
        pageIndex,
        pageCount,
        rates: data,
        isSearching: false,
        isSearched: true,
        rateRecently: {}
      };
    }

    case INSERT_RATE_RECENTLY: {
      return {
        ...state,
        rateRecently:action.payload.rateRecently
      };
    }
    default:
      return state;
  }
};
