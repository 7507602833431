import { useQuery } from '@tanstack/react-query';
import { LevelService } from './level.service';
import { LEVELS_QUERY_KEY } from './level.endpoints';

import type { LevelDTO } from './level.model';

export interface UseLevelsQueryOptions {
  cacheTime?: number;
  staleTime?: number;
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
  select?: (data: LevelDTO[]) => LevelDTO[];
}

export const useLevels = (queryOptions?: UseLevelsQueryOptions) => {
  const query = useQuery<LevelDTO[]>({
    queryKey: [LEVELS_QUERY_KEY],
    queryFn: LevelService.getAllLevels,
    cacheTime: 24 * 60 * 60 * 1000, // = 1 day
    staleTime: 24 * 60 * 60 * 1000,
    ...queryOptions
  });

  return { ...query };
};
