import type { AccountDTO } from '@gofan/api/accounts';
import type {
  EventScheduleSearchDTO,
  EventScheduleSeasonDTO as EventScheduleSeasonType
} from '@modules/event-integrations_V2/models/event-schedule.model';
import type { ImportEventDTO, EventConflictDTO } from '@app/modules/events/models/event.model';
import type { EventScheduleSeasonDTO, GlobalEventDTO } from '@app/modules/seasons/models/season.model';
import { ARBITER_PARTNER_NAME } from '@modules/event-integrations_V2/constants/constants';
import type RowEvent from '../../api/model/RowEvent';
import type { Account } from '../../api/types';

import type { SeasonSetupActionTypes } from './actionTypes';
import {
  PREPARE_DATA,
  PREPARE_DATA_COMPLETED,
  ON_PUBLISH_SEASON,
  ON_PUBLISH_SEASON_COMPLETED,
  RESET_STATE,
  SEARCH_OPPONENT_ACCOUNTS,
  SEARCH_OPPONENT_ACCOUNTS_SUCCESS,
  RESET_PUBLISH_STATE,
  UPLOAD_FILE_EVENT,
  UPLOAD_FILE_EVENT_FAIL,
  UPLOAD_FILE_EVENT_SUCCESS,
  BUILD_EVENT_CREATED_DATA,
  BUILD_EVENT_CREATED_DATA_COMPLETED,
  CLEAR_UPLOAD_FILE_ERROR,
  SEARCH_ACCOUNT_TICKETS,
  SEARCH_ACCOUNT_TICKETS_COMPLETED,
  CLEAR_SEARCH_OPPONENT_ACCOUNTS,
  CREATE_SEASON_FROM_ARBITER_EVENTS,
  CLEAR_ARBITER_EVENTS,
  CHECK_SCHEDULES_ARBITER_EVENTS_AVAILABLE,
  CHECK_SCHEDULES_ARBITER_EVENTS_AVAILABLE_COMPLETED,
  SEARCH_EVENT_SCHEDULES,
  SEARCH_EVENT_SCHEDULES_COMPLETED,
  CHECK_DUPLICATE_EVENTS,
  CHECK_DUPLICATE_EVENTS_COMPLETED,
  UPDATE_EVENT_CONFLICTS,
  UPDATE_IS_FROM_ARBITER,
  CLEAR_EVENT_SCHEDULES
} from './actionTypes';
import type {
  SearchAccountTicketsParams,
  SearchAccountsTicketsResult,
  CheckSchedulesArbiterEventsAvailableParams
} from './types';

export const prepareData = (accountId: number | string, history: any, currentUser: any): SeasonSetupActionTypes => ({
  type: PREPARE_DATA,
  payload: {
    accountId,
    history,
    currentUser
  }
});

export const prepareDataCompleted = ({
  account = {},
  levels = [],
  athleticActivities = [],
  nonAthleticActivities = [],
  rates = [],
  error
}): SeasonSetupActionTypes => ({
  type: PREPARE_DATA_COMPLETED,
  payload: {
    account,
    levels,
    athleticActivities,
    nonAthleticActivities,
    rates
  },
  error
});

export const onPublishSeason = ({
  currentUser,
  history = {},
  seasonData = {},
  accountProducts = [],
  isArbiterSeason = false,
  deletedIds,
  importType
}): SeasonSetupActionTypes => ({
  type: ON_PUBLISH_SEASON,
  payload: {
    currentUser,
    history,
    seasonData,
    isArbiterSeason,
    accountProducts,
    deletedIds,
    importType
  }
});

export const onPublishSeasonCompleted = (error: any): SeasonSetupActionTypes => ({
  type: ON_PUBLISH_SEASON_COMPLETED,
  error
});

export const searchOpponentAccounts = (keyword: string, name?: string): SeasonSetupActionTypes => ({
  type: SEARCH_OPPONENT_ACCOUNTS,
  payload: {
    keyword,
    name
  }
});

export const searchOpponentAccountsSuccess = (accounts: Array<Account>, name?: string): SeasonSetupActionTypes => ({
  type: SEARCH_OPPONENT_ACCOUNTS_SUCCESS,
  payload: {
    accounts,
    name
  }
});

export function resetState(): SeasonSetupActionTypes {
  return {
    type: RESET_STATE
  };
}

export const resetPublishState = (): SeasonSetupActionTypes => ({
  type: RESET_PUBLISH_STATE
});

export const onUploadFileEvent = (file: File): SeasonSetupActionTypes => ({
  type: UPLOAD_FILE_EVENT,
  payload: {
    file
  }
});

export const onUploadFileEventFail = (error: string): SeasonSetupActionTypes => ({
  type: UPLOAD_FILE_EVENT_FAIL,
  payload: {
    error
  }
});

export const onUploadFileEventSuccess = (events: Array<RowEvent>): SeasonSetupActionTypes => ({
  type: UPLOAD_FILE_EVENT_SUCCESS,
  payload: {
    events
  }
});

export const buildEventCreatedData = ({
  events = [],
  isManually = false,
  existingEventCreatedData = {}
}): SeasonSetupActionTypes => ({
  type: BUILD_EVENT_CREATED_DATA,
  payload: { events, isManually, existingEventCreatedData }
});

export const buildEventCreatedDataCompleted = (eventsData: any) => ({
  type: BUILD_EVENT_CREATED_DATA_COMPLETED,
  payload: {
    eventsData
  }
});

export const clearUploadFileError = () => ({
  type: CLEAR_UPLOAD_FILE_ERROR
});

export const searchAccountTickets = (searchParams: SearchAccountTicketsParams): SeasonSetupActionTypes => ({
  type: SEARCH_ACCOUNT_TICKETS,
  payload: {
    searchParams
  }
});

export const searchAccountTicketsCompleted = (searchResult: SearchAccountsTicketsResult): SeasonSetupActionTypes => ({
  type: SEARCH_ACCOUNT_TICKETS_COMPLETED,
  payload: {
    searchResult
  }
});

export const clearOpponentAccounts = () => ({
  type: CLEAR_SEARCH_OPPONENT_ACCOUNTS
});

export const doCreateSeasonFromArbiterEvents = (
  arbiterEvents: GlobalEventDTO[],
  eventScheduleSeason: EventScheduleSeasonDTO,
  arbiterSchools: AccountDTO[],
  needToResetEventConflict = false
): SeasonSetupActionTypes => ({
  type: CREATE_SEASON_FROM_ARBITER_EVENTS,
  payload: {
    arbiterEvents,
    eventScheduleSeason,
    arbiterSchools,
    needToResetEventConflict
  }
});

export const updateIsFromArbiter = (isFromArbiter: boolean): SeasonSetupActionTypes => ({
  type: UPDATE_IS_FROM_ARBITER,
  payload: {
    isFromArbiter
  }
});

export const doClearArbiterEvents = () => ({
  type: CLEAR_ARBITER_EVENTS
});

export const checkSchedulesArbiterEventsAvailable = (
  paramsCheck: CheckSchedulesArbiterEventsAvailableParams
): SeasonSetupActionTypes => ({
  type: CHECK_SCHEDULES_ARBITER_EVENTS_AVAILABLE,
  payload: {
    partnerName: ARBITER_PARTNER_NAME,
    genders: paramsCheck.genders,
    levelGenders: paramsCheck.levelGenders,
    schoolIds: paramsCheck.schoolIds,
    sportNames: paramsCheck.sportNames,
    status: paramsCheck.status
  }
});

export const checkSchedulesArbiterEventsAvailableCompleted = (result: boolean): SeasonSetupActionTypes => ({
  type: CHECK_SCHEDULES_ARBITER_EVENTS_AVAILABLE_COMPLETED,
  payload: result
});

export const searchEventSchedules = (searchParams: EventScheduleSearchDTO): SeasonSetupActionTypes => ({
  type: SEARCH_EVENT_SCHEDULES,
  payload: {
    searchParams
  }
});

export const searchEventSchedulesCompleted = (searchResult: EventScheduleSeasonType[]): SeasonSetupActionTypes => ({
  type: SEARCH_EVENT_SCHEDULES_COMPLETED,
  payload: {
    searchResult
  }
});

export const clearEventSchedules = () => ({
  type: CLEAR_EVENT_SCHEDULES
});

export const checkDuplicateEvents = (
  importEvents: Array<ImportEventDTO>,
  importEventsType: string,
  opponentSchools: AccountDTO[],
  eventScheduleSeason: EventScheduleSeasonDTO
): SeasonSetupActionTypes => ({
  type: CHECK_DUPLICATE_EVENTS,
  payload: {
    importEvents,
    importEventsType,
    opponentSchools,
    eventScheduleSeason
  }
});

export const checkDuplicateEventsCompleted = (
  arbiterEvents: GlobalEventDTO[],
  eventConflicts: Array<EventConflictDTO>,
  importEventsType: string,
  eventScheduleSeason?: EventScheduleSeasonDTO
): SeasonSetupActionTypes => ({
  type: CHECK_DUPLICATE_EVENTS_COMPLETED,
  payload: {
    eventConflicts,
    importEventsType,
    eventScheduleSeason,
    arbiterEvents
  }
});

export const updateEventConflicts = () => ({
  type: UPDATE_EVENT_CONFLICTS
});

export default {
  prepareData,
  prepareDataCompleted,
  onPublishSeason,
  onPublishSeasonCompleted,
  searchOpponentAccountsSuccess,
  searchOpponentAccounts,
  resetState,
  resetPublishState,
  onUploadFileEvent,
  onUploadFileEventFail,
  onUploadFileEventSuccess,
  clearUploadFileError,
  clearOpponentAccounts,
  doCreateSeasonFromArbiterEvents,
  doClearArbiterEvents,
  checkSchedulesArbiterEventsAvailable,
  checkSchedulesArbiterEventsAvailableCompleted,
  searchEventSchedules,
  searchEventSchedulesCompleted,
  clearEventSchedules,
  checkDuplicateEvents,
  checkDuplicateEventsCompleted,
  updateEventConflicts
};
