import { RadioButtonGroup as CarbonRadioButtonGroup } from '@carbon/react';
import { forwardRef } from 'react';
import classNames from 'classnames';

import type { RadioButtonGroupProps as CarbonRadioButtonGroupProps } from '@carbon/react/lib/components/RadioButtonGroup/RadioButtonGroup';

import './RadioButtonGroup.scss';

type RadioButtonGroupProps = CarbonRadioButtonGroupProps;

export const RadioButtonGroup = forwardRef<HTMLElement, RadioButtonGroupProps>((props, ref) => (
  <CarbonRadioButtonGroup {...props} ref={ref} className={classNames('gf--radio-button-group', props.className)} />
));
