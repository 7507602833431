import { isEmpty, trim } from 'lodash';
import memoizeOne from 'memoize-one';
import { EMAIL_REGEX, NAME_REGEX } from '@common/validations';
import { COACH_INFO } from '@app/modules/accounts/constants';
import type { Activity } from './sections/credentials-section/credentials-section.component';

const FILE_SIZE_UPLOAD = 500000;
const FILE_ACCEPTED = ['jpg', 'jpeg', 'png'];
export const CREDENTIAL_PERMISSION = {
  EDITOR: 'EDITOR',
  BOX_OFFICE: 'BOXOFFICE',
  INSIDER: 'INSIDER',
  FINANCE: 'FINANCE',
  REPORTS: 'REPORTS',
  EDIT_SCHOOL_FINANCIAL: 'EDIT_SCHOOL_FINANCIAL'
};
export const EVENT_REPORT = {
  YES: 1,
  NO: 2
};

const validationPhotoUpload = (_: any, value: any, callback: any) => {
  if (value instanceof Blob) {
    const type = value.type.split('/')[1];
    if (!FILE_ACCEPTED.includes(type)) {
      callback('Use a valid file format.');
    }
    if (value.size > FILE_SIZE_UPLOAD) {
      callback('File exceeds file limit.');
    }
  }
  callback();
};

const validationName = (_: any, value: any, callback: any, fieldName: string) => {
  if (!isEmpty(trim(value))) {
    if (!NAME_REGEX.test(value)) {
      callback(`${fieldName} should not contain special characters or numbers.`);
    }
  }
  callback();
};

const validationReportType = (context: any) => ({
  validateTrigger: 'onChange',
  validator(_: any, __: any, callback: any) {
    const { nonAthleticReport, athleticReport } = context.getFieldsValue(true);
    if (isEmpty(nonAthleticReport) && isEmpty(athleticReport)) {
      callback('Report type is required.');
    }
    callback();
  }
});

export const transformReportType = memoizeOne((report: number) => {
  if (report === EVENT_REPORT.YES) {
    return true;
  }
  if (report === EVENT_REPORT.NO) {
    return false;
  }

  return null;
});

const transformActivity = memoizeOne((activities: Activity[]) => {
  if (!isEmpty(activities)) {
    return activities.map(activity => activity.id);
  }

  return [];
});

const formConfig = (customValidation: any) => {
  const { validationEmail } = customValidation;
  const config = {
    fields: [
      {
        name: 'firstName',
        transform: trim,
        rules: [
          {
            required: true,
            validateTrigger: 'onSubmit',
            message: 'First name is required.'
          },
          {
            whitespace: true,
            validateTrigger: 'onSubmit',
            message: 'First name is required.'
          },
          {
            validator: (rules: any, value: any, callback: any) => validationName(rules, value, callback, 'First name'),
            validateTrigger: 'onBlur'
          }
        ]
      },
      {
        name: 'lastName',
        transform: trim,
        rules: [
          {
            required: true,
            validateTrigger: 'onSubmit',
            message: 'Last name is required.'
          },
          {
            whitespace: true,
            validateTrigger: 'onSubmit',
            message: 'Last name is required.'
          },
          {
            validator: (rules: any, value: any, callback: any) => validationName(rules, value, callback, 'Last name'),
            validateTrigger: 'onBlur'
          }
        ]
      },
      {
        name: 'email',
        transform: trim,
        rules: [
          {
            required: true,
            validateTrigger: 'onSubmit',
            message: 'Email address is required.'
          },
          {
            whitespace: true,
            validateTrigger: 'onSubmit',
            message: 'Email address is required.'
          },
          {
            pattern: EMAIL_REGEX,
            message: 'Enter a valid email address.',
            validateTrigger: 'onBlur'
          },
          {
            validator: validationEmail,
            validateTrigger: 'onBlur'
          }
        ]
      },
      {
        name: 'phoneNumber',
        transform: (value: any) => (value ? trim(`${value}`.split('.').join('')) : undefined),
        rules: [
          {
            whitespace: true,
            validateTrigger: 'onSubmit',
            message: 'Phone number is required.'
          },
          {
            pattern: /^(\d{3}).(\d{3}).(\d{4})$/,
            validateTrigger: 'onBlur',
            message: 'Phone number is invalid.'
          }
        ]
      },
      {
        name: 'title',
        transform: trim,
        rules: [
          {
            required: true,
            validateTrigger: 'onSubmit',
            message: 'Title is required.'
          },
          {
            whitespace: true,
            validateTrigger: 'onSubmit',
            message: 'Title is required.'
          },
          {
            min: 2,
            validateTrigger: 'onBlur',
            message: 'Title must contain at least 2 characters.'
          }
        ]
      },
      {
        name: 'contactType',
        rules: [
          {
            required: true,
            validateTrigger: 'onBlur',
            message: 'GoFan role is required.'
          }
        ]
      },
      {
        name: 'avatar',
        rules: [
          {
            validator: validationPhotoUpload
          }
        ]
      },
      {
        name: 'hasEditorPermission',
        transform: (value: any) => (!value ? false : value)
      },
      {
        name: 'hasBoxOfficePermission',
        transform: (value: any) => (!value ? false : value)
      },
      {
        name: 'hasFinancialPermission',
        transform: (value: any) => (!value ? false : value)
      },
      {
        name: 'hasInsiderPermission',
        transform: (value: any) => (!value ? false : value)
      },
      {
        name: 'hasReportPermission',
        transform: (value: number) => transformReportType(value)
      },
      {
        name: 'athleticReport',
        transform: (activity: Activity[]) => transformActivity(activity),
        rules: [(context: any) => validationReportType(context)]
      },
      {
        name: 'nonAthleticReport',
        transform: (activity: Activity[]) => transformActivity(activity),
        rules: [(context: any) => validationReportType(context)]
      }
    ]
  };

  return config;
};

export const getContactRolesFormConfig = (names: string[]) => ({
  fields: names.map(name => ({
    name,
    rules: [
      {
        required: true,
        validateTrigger: 'onSubmit',
        message: `${name?.includes(COACH_INFO.STAFF_ROLE) ? 'Staff role' : 'Sport team'} is required.`
      }
    ]
  }))
});

type FieldLabels = {
  [key: string]: string;
};

export const FIELD_LABELS_MAPPING: FieldLabels = {
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email address',
  phoneNumber: 'Phone number',
  title: 'Title',
  contactType: 'GoFan role',
  avatar: 'Photo',
  hasEditorPermission: 'General permissions',
  hasBoxOfficePermission: 'General permissions',
  hasFinancialPermission: 'General permissions',
  hasInsiderPermission: 'General permissions',
  hasReportPermission: 'Reporting permissions',
  schoolPermission: 'School permissions',
  athleticReport: 'Reporting permissions',
  nonAthleticReport: 'Reporting permissions'
};

export default formConfig;
