import { AxiosRequestConfig } from "axios";

export const SIGN_IN = '@auth_V2/SIGN_IN';
export const SIGN_IN_SUCCESS = '@auth_V2/SIGN_IN_SUCCESS';
export const RESET_PASSWORD = '@auth_V2/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = '@auth_V2/RESET_PASSWORD_SUCCESS';
export const PENING_EMAIL = '@auth_V2/PENDING_EMAIL';
export const CHECK_STATUS = '@auth_V2/CHECK_STATUS';
export const SIGN_IN_ERRORS = '@auth_V2/ERRORS';
export const CHECK_INVITATION_EMAIL = '@auth_V2/CHECK_INVITATION_EMAIL';
export const CHECK_INVITATION_EMAIL_STATUS =
  '@auth_V2/CHECK_INVITATION_EMAIL_STATUS';
export const GET_USER_BY_EMAIL_TOKEN = '@auth_V2/GET_USER_BY_EMAIL_TOKEN';
export const GET_USER_INFORMATION = '@auth_V2/GET_USER_INFORMATION';

interface signIn {
  type: typeof SIGN_IN;
  payload: {
    email: String;
    password: String;
    signInCallBack: Function;
  };
}

interface signInSuccess {
  type: typeof SIGN_IN_SUCCESS;
}

interface resetPassword {
  type: typeof RESET_PASSWORD;
  payload: {
    email: String;
    forGotCallback: Function;
  };
}

interface resetPasswordSuccess {
  type: typeof RESET_PASSWORD_SUCCESS;
}

interface checkPendingEmail {
  type: typeof PENING_EMAIL;
  payload: {
    email: String;
    configuration?: AxiosRequestConfig;
    manualErrorHandling?: boolean;
  }
}

interface checkStatus {
  type: typeof CHECK_STATUS;
  payload: any;
}

interface signInErrors {
  type: typeof SIGN_IN_ERRORS;
  payload: any;
}

interface checkInvitationEmail {
  type: typeof CHECK_INVITATION_EMAIL;
  payload: any;
}

interface checkInvitationEmailStatus {
  type: typeof CHECK_INVITATION_EMAIL_STATUS;
  payload: any;
}

interface getUserByEmailToken {
  type: typeof GET_USER_BY_EMAIL_TOKEN;
  payload: any;
}

interface getUserInformation {
  type: typeof GET_USER_INFORMATION;
  payload: any;
}

export type AuthActionTypes =
  | signIn
  | signInSuccess
  | resetPassword
  | resetPasswordSuccess
  | checkPendingEmail
  | signInErrors
  | checkStatus
  | checkInvitationEmail
  | checkInvitationEmailStatus
  | getUserByEmailToken
  | getUserInformation;
