import ErrorDTO from '../dto/ErrorDTO';
import ActivityDTO from '../dto/ActivityDTO';
import axios from '../api/axios';
import { ACTIVITY_URI } from '../api/api';

const fetchAllActivities = () =>
  axios.huddleAxiosInstanceProxy
    .get(ACTIVITY_URI)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response.map(activity => new ActivityDTO(activity));
    })
    .catch(e => new ErrorDTO(e));

const createActivity = activity =>
  axios.huddleAxiosInstanceProxy
    .post(ACTIVITY_URI, activity)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new ActivityDTO(response);
    })
    .catch(e => new ErrorDTO(e));

const updateActivity = activity =>
  axios.huddleAxiosInstanceProxy
    .put(`${ACTIVITY_URI}${activity.id}`, activity)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new ActivityDTO(response);
    })
    .catch(e => new ErrorDTO(e));

const deleteActivity = id =>
  axios.huddleAxiosInstanceProxy
    .delete(`${ACTIVITY_URI}${id}`)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return true;
    })
    .catch(e => new ErrorDTO(e));

export default {
  fetchAllActivities,
  createActivity,
  updateActivity,
  deleteActivity
};
