import { css } from 'styled-components';

// color
export const SILVER = '#cccccc';
export const DARK_GRAY = '#999999';
export const LIGHT_GRAY = '#ececec';
export const DOVE_GRAY = '#666666';
export const CRIMSON = '#e31736';
export const DARK_RED = '#ca0000';
export const WILD_SAND = '#f4f4f4';
export const WHITE = '#FFFFFF';
export const LASER_LEMON = '#fff963';
export const YELLOW = '#ffff00';
export const LIGHT_BLUE = '#3B86FF';
export const GREEN = '#00A651';

// font-family
export const FONT_BASE = css`
  line-height: 1.5;
  color: black;
`;

export const SOURCESANPRO_REGULAR = css`
  ${FONT_BASE}
  font-weight: 400 !important;
`;

export const SOURCESANPRO_BOLD = css`
  ${FONT_BASE}
  font-weight: 700 !important;
`;

export const SOURCESANPRO_SEMIBOLD = css`
  ${FONT_BASE}
  font-weight: 600 !important;
`;

export default {
  h1: css`
    ${SOURCESANPRO_SEMIBOLD}
    font-size: 32px;
  `,

  h2: css`
    ${SOURCESANPRO_SEMIBOLD}
    font-size: 24px;
  `,

  h3: css`
    ${SOURCESANPRO_SEMIBOLD}
    font-size: 18px;
  `,

  h4: css`
    ${SOURCESANPRO_SEMIBOLD}
    font-size: 16px;
  `,

  largeBody: css`
    ${SOURCESANPRO_REGULAR}
    font-size: 18px;
  `,

  button: css`
    ${SOURCESANPRO_SEMIBOLD}
    font-size: 16px;
  `,

  body: css`
    ${SOURCESANPRO_REGULAR}
    font-size: 14px;
  `,

  bodyBold: css`
    ${SOURCESANPRO_BOLD}
    font-size: 16px;
  `,

  label: css`
    ${SOURCESANPRO_REGULAR}
    font-size: 18px;
    color: #666666;
  `,

  bodyLink: css`
    ${SOURCESANPRO_SEMIBOLD}
    font-size: 16px;
    color: #3b86ff;
    text-decoration: none;
    &:active {
      color: black;
    }
    &:visited {
      color: #3b86ff;
    }
    &:hover {
      color: #666;
      text-decoration: underline;
    }
  `,

  link: css`
    ${SOURCESANPRO_REGULAR}
    font-size: 14px;
    color: #3b86ff;
    text-decoration: none;
    &:active {
      color: black;
    }
    &:visited {
      color: #3b86ff;
    }
    &:hover {
      color: #666;
      text-decoration: underline;
    }
  `,

  fieldLabel: css`
    ${SOURCESANPRO_SEMIBOLD}
    font-size: 14px !important;
    color: black;
  `,

  fieldValueRegular: css`
    ${SOURCESANPRO_REGULAR}
    font-size: 16px;
  `,

  fieldValueSemiBold: css`
    ${SOURCESANPRO_SEMIBOLD}
    font-size: 14px;
  `,

  errorMessage: css`
    ${SOURCESANPRO_SEMIBOLD}
    font-size: 14px;
    color: ${DARK_RED};
  `
};

export const SEARCH_TICKETS = {
  CUSTOM_HEIGHT_INPUT: 36
};

export const DEFAULT_HEIGHT_INPUT = 48;
export const HEIGHT_INPUT = 36;
