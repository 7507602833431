import ErrorDTO from '../dto/ErrorDTO';
import ProductTypeDTO from '../dto/ProductTypeDTO';
import axios from '../api/axios';
import { PRODUCT_TYPES_URI } from '../api/api';

const fetchAllProductTypes = () =>
  axios.huddleAxiosInstanceProxy
    .get(PRODUCT_TYPES_URI)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return response.map(prodcutType => new ProductTypeDTO(prodcutType));
    })
    .catch(e => new ErrorDTO(e));

const createProductType = productType =>
  axios.huddleAxiosInstanceProxy
    .post(PRODUCT_TYPES_URI, productType)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new ProductTypeDTO(response);
    })
    .catch(e => new ErrorDTO(e));

const updateProductType = productType =>
  axios.huddleAxiosInstanceProxy
    .put(`${PRODUCT_TYPES_URI}${productType.id}`, productType)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return new ProductTypeDTO(response);
    })
    .catch(e => new ErrorDTO(e));

const deleteProductType = id =>
  axios.huddleAxiosInstanceProxy
    .delete(`${PRODUCT_TYPES_URI}${id}`)
    .then(response => {
      const error = ErrorDTO.convertToError(response);
      if (error !== null) {
        return error;
      }
      return true;
    })
    .catch(e => new ErrorDTO(e));

export default {
  fetchAllProductTypes,
  createProductType,
  updateProductType,
  deleteProductType
};
