import { Checkbox, CheckboxProps, FormLabel } from 'carbon-components-react';
import { isEmpty } from 'lodash';

export type BasicCheckboxConfig = CheckboxProps & {
  label: string;
  titleText: string;
};

export type BasicCheckboxProps<ItemType = boolean> = BasicCheckboxConfig & {
  name: string;
  control: any;
  initialValue: ItemType;
  fieldValue: ItemType;
  getFieldRef?: () => void;
};

const BasicCheckbox = ({
  name,
  className,
  label = '',
  titleText,
  initialValue,
  fieldValue,
  getFieldRef,
  control,
  ...other
}: BasicCheckboxProps) => (
  <>
    {!isEmpty(titleText) && <FormLabel>{titleText}</FormLabel>}
    <Checkbox
      {...other}
      {...control}
      id={`field-${name}`}
      ref={getFieldRef}
      className={className}
      checked={fieldValue}
      labelText={label}
    />
  </>
);

export default BasicCheckbox;
