import React, { useEffect } from 'react';
import { Table, Dropdown, Pagination as SRPagination } from 'semantic-ui-react';

import useIsMount from '../../../../utils/hooks/useIsMount';

import { PAGINATION_OPTIONS } from '../../constants';

function Pagination({ tableInstance, pageCount, onPageChange, pageSize: size, pageIndex: page }) {
  const {
    gotoPage,
    setPageSize,
    toggleAllPageRowsSelected = () => null,
    state: { pageIndex, pageSize }
  } = tableInstance;
  const pageSizeRef = React.useRef(size);
  const pageIndexRef = React.useRef(page);
  const isMount = useIsMount();

  useEffect(
    () => {
      if (isMount) {
        if (pageIndexRef.current !== page) {
          pageIndexRef.current = page;
          toggleAllPageRowsSelected(false);
          gotoPage(page);
        } else if (pageSizeRef.current !== pageSize || pageIndexRef.current !== pageIndex) {
          pageSizeRef.current = pageSize;
          pageIndexRef.current = pageIndex;
          toggleAllPageRowsSelected(false);
          onPageChange({ pageIndex, pageSize });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageIndex, pageSize, page]
  );

  return (
    <Table.Footer fullWidth className='table-pagination'>
      <Table.Row>
        <Table.HeaderCell colSpan='2'>
          <Dropdown
            upward
            search
            selection
            className='icon'
            value={pageSize}
            text={`show ${pageSize}`}
            options={PAGINATION_OPTIONS}
            onChange={(e, { value }) => {
              setPageSize(value);
            }}
          />
        </Table.HeaderCell>
        <Table.HeaderCell colSpan='16' textAlign='right'>
          <SRPagination
            totalPages={pageCount}
            activePage={pageIndex + 1}
            lastItem={null}
            firstItem={null}
            prevItem={null}
            nextItem={{ content: String.fromCharCode(0x276f) }}
            onPageChange={(e, { activePage }) => {
              gotoPage(activePage ? Number(activePage) - 1 : 0);
            }}
          />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );
}

Pagination.defaultProps = {
  pageSize: 10,
  pageIndex: 0,
  pageCount: 1,
  onPageChange: () => null,
  tableInstance: {
    rows: [],
    prepareRow: () => null,
    getTableBodyProps: () => null
  }
};

export default Pagination;
