// endpoints
export const SEARCH_NFHS_SCHOOL = '/v2/search/schools';
export const SEARCH_GAME = '/v2/search/events';

export const COMPLETE_GAME = '/events/games/{key}/complete';
export const SEARCH_PIXELLOTS_BY_PUBLISHER = '/events/pixellots/publisher/{publisherKey}';

// tinybird
export const VIEWS_PER_HOUR = '/api/views_per_hour/{event_id}';
export const VIDEO_IMPRESSIONS = '/api/video_impressions/{event_id}';

// query keys
export const NFHS_SCHOOL_QUERY_KEY = 'nfhs-school';
export const GAMES_QUERY_KEY = 'games';
export const LIVE_VIEWS_PER_HOUR_QUERY_KEY = 'live-views-per-hour';
export const VIDEO_IMPRESSIONS_QUERY_KEY = 'video-impressions';
