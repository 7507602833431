import { closePendoResourceCenter, addNotification } from '@gofan/utils';
import { useDisclosure } from '@gofan/hooks';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from '../modals/Modal';
import { PendoSupportFormDefault } from './components/PendoSupportFormDefault';
import { PendoSupportFormStreaming } from './components/PendoSupportFormStreaming';
import { PendoSupportFormTicketing } from './components/PendoSupportFormTicketing';
import type { UserDTO, ZendeskSupportForm } from '@gofan/api';
import {
  REQUEST_CONTACT_TYPES,
  ZENDESK_SUPPORT_FORM_FIELDS,
  ZendeskService,
  ZendeskSupportDefaultSchema,
  ZendeskSupportStreamingSchema,
  ZendeskSupportTicketingSchema
} from '@gofan/api/zendesk';

import './PendoSupportForm.scss';

interface PendoSupportFormProps {
  currentUser: UserDTO;
  isMobile?: boolean; // this component is shared between HQ Desktop and HQ Mobile
}

export enum PendoSupportFormProgress {
  DEFAULT = 'default',
  STREAMING = 'streaming',
  TICKETING = 'ticketing'
}

const defaultValues = {
  [ZENDESK_SUPPORT_FORM_FIELDS.REQUEST_CONTACT_TYPE]: REQUEST_CONTACT_TYPES.PLAYON,
  [ZENDESK_SUPPORT_FORM_FIELDS.SUPPORT_DIVISION]: '',
  [ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_REQUESTOR_EMAIL]: { email: '' },
  [ZENDESK_SUPPORT_FORM_FIELDS.NAME]: '',
  [ZENDESK_SUPPORT_FORM_FIELDS.SCHOOL_NAME]: { name: '' },
  [ZENDESK_SUPPORT_FORM_FIELDS.REQUESTOR_EMAIL]: '',
  [ZENDESK_SUPPORT_FORM_FIELDS.PHONE_NUMBER]: '',
  [ZENDESK_SUPPORT_FORM_FIELDS.PREFERRED_CONTACT_METHOD]: 'Email',
  [ZENDESK_SUPPORT_FORM_FIELDS.NEXT_EVENT_DATE]: null,
  [ZENDESK_SUPPORT_FORM_FIELDS.ISSUE_DESCRIPTION]: '',
  [ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_STREAMING]: { text: '', value: '' },
  [ZENDESK_SUPPORT_FORM_FIELDS.ENCODING_BROADCAST_SOFTWARE]: { text: '', value: '' },
  [ZENDESK_SUPPORT_FORM_FIELDS.PIXELLOT_LOCATION]: '',
  [ZENDESK_SUPPORT_FORM_FIELDS.PIXELLOT_ISSUES]: { text: '', value: '' },
  [ZENDESK_SUPPORT_FORM_FIELDS.GAME_ID]: '',
  [ZENDESK_SUPPORT_FORM_FIELDS.EVENT_IDS]: '',
  [ZENDESK_SUPPORT_FORM_FIELDS.PLAYOFF_EVENT_TICKETING]: false,
  [ZENDESK_SUPPORT_FORM_FIELDS.TYPE_OF_ISSUE_TICKETING]: { text: '', value: '' },
  [ZENDESK_SUPPORT_FORM_FIELDS.URGENT_REQUEST]: false,
  [ZENDESK_SUPPORT_FORM_FIELDS.UPDATE_REQUEST]: false,
  [ZENDESK_SUPPORT_FORM_FIELDS.ATTACHMENTS]: []
};

// this form is shared between HQ desktop and HQ Mobile, the margin classes are different for each
// this form listens to a custom event from the Resource Center and opens a modal with a form
// the form submits a ticket to zendesk for internal PlayOn support
// this exists because Pendo cannot house the API keys for the Zendesk API
const PendoSupportForm = ({ currentUser, isMobile = false }: PendoSupportFormProps) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const { isOpen, open, close } = useDisclosure({ initialValue: false });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isReturnFromBackBtn, setIsReturnFromBackBtn] = useState<boolean>(false);
  const [formProgress, setFormProgress] = useState<PendoSupportFormProgress>(PendoSupportFormProgress.DEFAULT);
  const schemas: any = {
    [PendoSupportFormProgress.DEFAULT]: ZendeskSupportDefaultSchema,
    [PendoSupportFormProgress.STREAMING]: ZendeskSupportStreamingSchema,
    [PendoSupportFormProgress.TICKETING]: ZendeskSupportTicketingSchema
  };
  const methods = useForm({
    mode: 'all',
    defaultValues,
    shouldFocusError: false,
    resolver: yupResolver(schemas[formProgress]) // change schema based on from progress
  });

  // listen for the custom event from the Pendo Resource Center
  // open the modal and close the Resource Center
  useEffect(() => {
    window.addEventListener('PENDO_RESOURCE_CENTER_INTERNAL_ZENDESK_SUPPORT', _handleCustomPendoEvent);
    return () => {
      window.removeEventListener('PENDO_RESOURCE_CENTER_INTERNAL_ZENDESK_SUPPORT', _handleCustomPendoEvent);
    };
  }, []);

  return (
    <div className='gf-pendo-support-form'>
      <Modal open={isOpen} onRequestClose={() => close()} passiveModal ref={modalRef}>
        <div className='gf-title'>
          {formProgress === PendoSupportFormProgress.DEFAULT ? 'Submit a Request' : 'Support Form'}
        </div>
        <div className='gf-divider' />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(_onSubmit)}>
            {formProgress === PendoSupportFormProgress.DEFAULT && (
              <PendoSupportFormDefault
                onContinue={(nextProgress: PendoSupportFormProgress) => _handleFormProgress(nextProgress, false)}
                currentUser={currentUser}
                isReturnFromBackBtn={isReturnFromBackBtn}
              />
            )}
            {formProgress === PendoSupportFormProgress.STREAMING && (
              <PendoSupportFormStreaming
                onBack={(nextProgress: PendoSupportFormProgress) => _handleFormProgress(nextProgress, true)}
                isSubmitting={isSubmitting}
              />
            )}
            {formProgress === PendoSupportFormProgress.TICKETING && (
              <PendoSupportFormTicketing
                onBack={(nextProgress: PendoSupportFormProgress) => _handleFormProgress(nextProgress, true)}
                isSubmitting={isSubmitting}
              />
            )}
          </form>
        </FormProvider>
      </Modal>
    </div>
  );

  async function _onSubmit(data: ZendeskSupportForm) {
    let uploadedFileResp: any[] | undefined | void;
    let attachmentTokens: string[] = [];
    const attachments = data[ZENDESK_SUPPORT_FORM_FIELDS.ATTACHMENTS] || [];
    setIsSubmitting(true);

    // Upload attachments if they exist, we then append the attachment tokens to the ticket
    if (attachments.length > 0) {
      uploadedFileResp = await _uploadAttachments(attachments);

      const errorIndexes = uploadedFileResp
        ? uploadedFileResp.map((item, index) => (item === undefined ? index : -1)).filter(index => index !== -1)
        : [];

      if (errorIndexes.length > 0) {
        const fileNames = attachments.map((item, index) => (errorIndexes.includes(index) ? item.name : '')).join(', ');

        addNotification({
          type: 'error',
          message: `These attachments failed to upload, please try again. ( ${fileNames} )`,
          options: { duration: 7500 }
        });

        return;
      }
    }

    if (uploadedFileResp) {
      attachmentTokens = uploadedFileResp.map(item => item.upload.token);
    }

    ZendeskService.createTicket(data, currentUser, attachmentTokens, isMobile)
      // isMobile is a boolean that determines which endpoint to use based on HQ Desktop/Mobile
      .then(() => {
        addNotification({
          type: 'success',
          message: 'Ticket successfully submitted to Zendesk',
          options: { duration: 7500 }
        });
        methods.reset();
        setFormProgress(PendoSupportFormProgress.DEFAULT);

        if (modalRef.current) {
          modalRef.current.querySelector('.cds--modal-content')?.scrollTo(0, 0);
        }
      })
      .catch(e => {
        addNotification({ type: 'error', message: e.message, options: { duration: 7500 } });
      })
      .finally(() => setIsSubmitting(false));
  }

  async function _uploadAttachments(files: File[]) {
    const uploadPromises = files.map(file => ZendeskService.uploadAttachment(file, isMobile));

    return Promise.all(uploadPromises);
  }

  async function _handleFormProgress(nextProgress: PendoSupportFormProgress, isBack: boolean) {
    const isValid = await methods.trigger();
    setIsReturnFromBackBtn(isBack);
    if (nextProgress === PendoSupportFormProgress.DEFAULT) setFormProgress(nextProgress);
    if (isValid) setFormProgress(nextProgress);
  }

  function _handleCustomPendoEvent() {
    open();
    closePendoResourceCenter();
  }
};

export { PendoSupportForm };
