import moment from 'moment';
import { DATE_PICKER_FORMAT_DEFAULT } from '../date';

export const USER_MAX_DATE = moment().add(500, 'days').format(DATE_PICKER_FORMAT_DEFAULT);
export const ADMIN_MAX_DATE = moment().add(730, 'days').format(DATE_PICKER_FORMAT_DEFAULT);
export const DEFAULT_START_TIME = {
  time: '07:00',
  period: 'PM'
};
export const ALL_DAY_START_TIME_DEFAULT = '12:01 AM';

export enum CAMPAIGN_STATUS {
  LIVE = 'success',
  FUTURE = 'info',
  ENDED = 'ended'
}
