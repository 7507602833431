import type { SortBy } from '@old-components/basic-table/sorting';
import { sortStates } from '@old-components/basic-table/sorting';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import PageRequest from '@api/model/request/PageRequest';
import ExpandRequest from '@app/api/model/request/ExpandRequest';
import SortableRequest from '@api/model/request/SortableRequest';
import type {
  SeasonDTO,
  SeasonSearchDTO,
  SeasonImportResponseDTO,
  SeasonPageResponseDTO,
  GlobalEventsSearchDTO,
  GlobalEventDTO
} from '@seasons/models/season.model';
import SeasonRepository from '@seasons/repositories/season.repository';
import { AccountService } from '@gofan/api/accounts';
import EventService from '@events/services/events.service';
import type { AccountDTO } from '@gofan/api/accounts';
import type { EventDTO, ImportEventDTO } from '@app/modules/events/models/event.model';
import type { EventScheduleSeasonDTO } from '@modules/event-integrations_V2/models/event-schedule.model';
import type { SeasonTicketUnionIds } from '../repositories/season.repository';
import { DELETED_STATUS, PUBLISHED_STATUS } from '@modules/event-integrations_V2/constants/constants';
import { getTimeZone, convertDateTimeZone, EVENT_DATE_FORMAT_WITH_TIMEZONE } from '@utils/dateUtils';
import { GENDER_TYPE } from '@season-management/constants/constant';
import { RESOLVE_DUPLICATED_TYPES } from '@app/pages/SeasonSetup/constants';
import { mappingDataDuplicateEvents } from '@app/pages/SeasonSetup/helpers';

import { calculateSeasonDurationOfCurrentDate } from '../utils/season.utils';
import { EXPAND_EVENT_FIELDS, SEASON_IMPORT_CONCERN_FIELDS } from '../constants/constants';

export type SeasonSearchParams = {
  body: SeasonSearchDTO;
  sortBy: SortBy;
  page?: number;
  pageSize: number;
  fields?: string[];
  expand?: string[];
};

class SeasonService {
  searchSeasonByParams = (searchParams: SeasonSearchParams): Promise<SeasonPageResponseDTO> => {
    const pageQuery = new PageRequest({
      pageIndex: searchParams.page,
      pageSize: searchParams.pageSize
    }).toQueryString();
    const sortableQuery = isEmpty(searchParams.sortBy)
      ? ''
      : new SortableRequest({
          sortBy: [
            {
              id: searchParams.sortBy.header,
              desc: searchParams.sortBy.sortDirection === sortStates.DESC
            }
          ]
        }).toQueryString();
    const expandQueryString = searchParams.expand
      ? `${searchParams.expand.map(field => `expand=${field}`).join('&')}&`
      : '';
    const fieldQueryString = searchParams.fields
      ? `${searchParams.fields.map(field => `fields=${field}`).join('&')}&`
      : '';

    const queryStr = `?${expandQueryString}${fieldQueryString}ts=${new Date().getTime()}&skipCache=true&${pageQuery}${sortableQuery}`;

    return SeasonRepository.searchSeasonByParams(searchParams.body, queryStr);
  };

  fetchGlobalEvents = (body: GlobalEventsSearchDTO) => SeasonRepository.fetchGlobalEvents(body);

  fetchArbiterSchools = (body: string[]) => AccountService.getAccountsByIds(body);

  fetchArbiterSeasonImport = (searchBody: SeasonSearchDTO): Promise<SeasonImportResponseDTO> => {
    const searchParams: SeasonSearchParams = {
      body: {
        ...searchBody,
        createdAtStart: searchBody.createdAtStart
          ? searchBody.createdAtStart
          : calculateSeasonDurationOfCurrentDate().seasonStartTime.toISOString().replace('.000Z', '+0000')
      },
      fields: SEASON_IMPORT_CONCERN_FIELDS,
      expand: ['levels'],
      pageSize: 1000,
      sortBy: {
        header: 'createdAt',
        sortDirection: 'DESC'
      }
    };

    const expandQueryString = searchParams.expand
      ? `${searchParams.expand.map(field => `expand=${field}`).join('&')}&`
      : '';
    const fieldQueryString = searchParams.fields
      ? `${searchParams.fields.map(field => `fields=${field}`).join('&')}&`
      : '';

    const sortableQuery = isEmpty(searchParams.sortBy)
      ? ''
      : new SortableRequest({
          sortBy: [
            {
              id: searchParams.sortBy.header,
              desc: searchParams.sortBy.sortDirection === sortStates.DESC
            }
          ]
        }).toQueryString();
    const queryStr = `?${expandQueryString}${fieldQueryString}ts=${new Date().getTime()}&skipCache=true&size=${
      searchParams.pageSize
    }${sortableQuery}`;
    return SeasonRepository.fetchArbiterSeasonImport(searchParams.body, queryStr);
  };

  getSeasonById = async (seasonId: string, expands: string[] = [], isSkipFetchEvent?: boolean): Promise<SeasonDTO> => {
    const expandQuery = new ExpandRequest(expands).toQueryString();
    const resSeason = await SeasonRepository.getSeasonById(seasonId, `?ts=${new Date().getTime()}${expandQuery}`);

    const events: EventDTO[] = isSkipFetchEvent
      ? []
      : await Promise.all(
          (resSeason?.eventIds || []).map((id: number) => EventService.getEventById(id, EXPAND_EVENT_FIELDS))
        );

    return {
      ...resSeason,
      events
    };
  };

  searchSeasonByIds = (seasonIds: number[], expands: string[] = [], axiosConfig?: any): Promise<SeasonDTO[]> => {
    const expandQuery = new ExpandRequest(expands).toQueryString();
    return SeasonRepository.searchSeasonByIds(seasonIds, `&ts=${new Date().getTime()}${expandQuery}`, axiosConfig);
  };

  updateSeasonWithEvent = (season: any): Promise<SeasonDTO> => SeasonRepository.updateSeasonWithEvent(season);

  getParamToCheckEventDuplicate = (events: GlobalEventDTO[], accounts: AccountDTO[]) =>
    events?.reduce(
      (params: ImportEventDTO[], event: GlobalEventDTO) => [
        ...params,
        {
          eventStartDate: convertDateTimeZone({
            date: event.startEventDt,
            format: EVENT_DATE_FORMAT_WITH_TIMEZONE as any,
            timeZone: getTimeZone(accounts?.find(acc => `${acc.id}` === `${event.homeSchoolId}`)?.timeZone) as any
          }),
          genders: event?.gender?.toUpperCase() === GENDER_TYPE.COED ? [] : [event.gender],
          homeSchoolId: event.homeSchoolId,
          opponentSchoolId: event.awaySchoolId,
          sportName: event.eventReportingType,
          globalEventId: event.id,
          globalEventRef: event,
          partnerName: event.sourceName
        } as ImportEventDTO
      ],
      []
    );

  getDuplicateEvents = async ({ schedule, accounts }: { schedule: EventScheduleSeasonDTO; accounts: AccountDTO[] }) => {
    const filteredEvents =
      schedule?.events?.filter(
        ({ gofanStatusMsg }) => gofanStatusMsg?.toUpperCase() !== DELETED_STATUS.toUpperCase()
      ) ?? [];
    const publishedScheduleEvents =
      filteredEvents.filter(
        ({ gofanEventId, gofanStatusMsg }) =>
          !!gofanEventId && `${gofanStatusMsg}`.toLowerCase() === PUBLISHED_STATUS.toLowerCase()
      ) ?? [];
    const waitingEvents = filteredEvents.filter(event => !event.gofanStatusMsg) ?? [];

    const duplicateCheckParams = this.getParamToCheckEventDuplicate(waitingEvents, accounts);

    const [duplicateEvents, awaySchools] = await Promise.all([
      isEmpty(duplicateCheckParams) ? [] : EventService.checkDuplicateEvent(duplicateCheckParams),
      this.fetchArbiterSchools(uniq(duplicateCheckParams?.map(event => event.opponentSchoolId)))
    ]);

    const publishedEvents = await Promise.all(
      publishedScheduleEvents?.map(event =>
        EventService.getEventById(event.gofanEventId as string, EXPAND_EVENT_FIELDS).then(goFanEvent => ({
          ...event,
          goFanEvent,
          partnerId: event?.sourceEventId ?? '',
          partnerName: event?.sourceName ?? '',
          globalEventId: event?.id ?? '',
          eventName: goFanEvent?.name ?? '',
          startEventDt: goFanEvent?.startDateTime,
          endEventDt: goFanEvent?.endDateTime,
          awaySchoolId: goFanEvent?.opponentAccountId,
          financialAccountId: goFanEvent?.financialAccountId
        }))
      )
    );

    return {
      ...schedule,
      duplicatedEvents: mappingDataDuplicateEvents(duplicateCheckParams, duplicateEvents, awaySchools),
      events: filteredEvents.map(scheduleEvent => {
        const foundEvent = publishedEvents?.find(event => `${event.id}` === `${scheduleEvent.id}`);
        return isEmpty(foundEvent) ? { ...scheduleEvent } : { ...foundEvent };
      })
    };
  };

  getDuplicateSchedules = async ({
    accounts,
    schedules
  }: {
    schedules: EventScheduleSeasonDTO[];
    accounts: AccountDTO[];
  }) => {
    const duplicatedSchedules = await Promise.all(
      (schedules ?? [])
        .filter(({ status }) => `${status}`.toLowerCase() !== PUBLISHED_STATUS.toLowerCase())
        .map(schedule => this.getDuplicateEvents({ schedule, accounts }))
    );

    return {
      duplicatedSchedules,
      duplicatedEvents: duplicatedSchedules?.reduce((results: ImportEventDTO[], { duplicatedEvents }: any) => {
        const filteredList = duplicatedEvents?.filter((item: any) => !isEmpty(item?.events));
        return [...results, ...(filteredList ?? [])];
      }, [])
    };
  };

  resolveDuplicateSchedules = ({
    accounts,
    schedules,
    resolvedEvents
  }: {
    accounts: AccountDTO[];
    schedules: EventScheduleSeasonDTO[];
    resolvedEvents: any[];
  }) => {
    const newSchedules = schedules?.map(schedule => {
      const homeSchool = accounts?.find(item => `${schedule?.homeSchoolId}` === `${item?.id}`);

      const newEvents = schedule?.events?.reduce((results: any[], scheduleEvent: any) => {
        const resolvedEvent = resolvedEvents?.find(item => `${item?.importEvent?.id}` === `${scheduleEvent?.id}`);

        // Non-duplicate
        if (isEmpty(resolvedEvent) || isEmpty(resolvedEvent?.events)) {
          results?.push({ ...scheduleEvent });
          return results;
        }

        const globalEventId = resolvedEvent?.importEvent?.globalEventId ?? null;
        const sourceName = resolvedEvent?.importEvent?.globalEventRef?.sourceName;
        const sourceEventId = resolvedEvent?.importEvent?.globalEventRef?.sourceEventId;

        const keptEvents = resolvedEvent?.events?.filter((event: any) => event?.keep);
        const isKeptEvent = !isEmpty(keptEvents);
        const isUsedEvent = resolvedEvent?.importEvent?.keep;

        // Only Used Partner Event
        if (isUsedEvent && !isKeptEvent) {
          const firstGoFanEvent = resolvedEvent?.events?.[0];
          results?.push({
            ...scheduleEvent,
            created: true,
            goFanEvent: firstGoFanEvent,
            resolveType: RESOLVE_DUPLICATED_TYPES.ONLY_USED_PARTNER_EVENT,
            partnerId: sourceEventId,
            partnerName: sourceName,
            globalEventId,
            financialAccountId: homeSchool?.id
          });
          return results;
        }

        // Kept both for Partner Event
        if (isUsedEvent) {
          results?.push({
            ...scheduleEvent,
            partnerId: sourceEventId,
            partnerName: sourceName,
            globalEventId,
            financialAccountId: homeSchool?.id,
            resolveType: RESOLVE_DUPLICATED_TYPES.KEPT_BOTH
          });
        }

        // Only Kept GoFan Event or Kept both for GoFan Event
        keptEvents?.forEach((event: any) => {
          let partnerId;
          let partnerName;
          let globalEventIdForGoFan = event?.globalEventId ?? null;
          let resolveType = RESOLVE_DUPLICATED_TYPES.KEPT_BOTH;

          if (!isUsedEvent) {
            partnerId = sourceEventId;
            partnerName = sourceName;
            globalEventIdForGoFan = globalEventId;
            resolveType = RESOLVE_DUPLICATED_TYPES.ONLY_KEPT_GF_EVENT;
          }

          results?.push({
            ...scheduleEvent,
            goFanEvent: event,
            created: true,
            resolveType,
            partnerId,
            partnerName,
            globalEventId: globalEventIdForGoFan,
            eventName: event?.name ?? '',
            startEventDt: event?.startDateTime,
            endEventDt: event?.endDateTime,
            awaySchoolId: event?.opponentAccountId,
            financialAccountId: event?.financialAccountId
          });
        });

        return results;
      }, []);

      return {
        ...schedule,
        events: newEvents
      };
    });

    return newSchedules;
  };

  deleteSeason = (season: SeasonDTO, deleteEventIds?: number[]): Promise<SeasonDTO> =>
    SeasonRepository.deleteSeason(season, deleteEventIds);

  updateSeason = (season: SeasonDTO): Promise<SeasonDTO> => SeasonRepository.updateSeason(season);

  getSeasonInsights = (seasonId: number): Promise<any> => SeasonRepository.getSeasonInsights(seasonId);

  partialUpdateSeason = (season: Partial<SeasonDTO>): Promise<SeasonDTO> =>
    SeasonRepository.partialUpdateSeason(season);

  getSeasonTicketInsightData = (unionIds: SeasonTicketUnionIds[]) => {
    if (unionIds.length < 1) return Promise.resolve(null);
    return SeasonRepository.getSeasonTicketInsightData(unionIds);
  };
}

export default new SeasonService();
