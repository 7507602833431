import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { PAGES } from '@config/routes';

import type { RouteComponentProps } from 'react-router-dom';

const StreamingEditor = lazy(() =>
  import('./views/StreamingEditor').then(module => ({
    default: module.StreamingEditor
  }))
);

const StreamingContainer = (props: RouteComponentProps) => {
  const eventId = props.location.pathname.split('/').pop();

  return (
    <Switch>
      <Route
        exact
        key={PAGES.streaming.createEvent.title}
        path={PAGES.streaming.createEvent.path}
        render={(routeProps: RouteComponentProps) => (
          <Suspense fallback={<div />}>
            <StreamingEditor {...routeProps} />
          </Suspense>
        )}
      />

      <Route
        exact
        key={PAGES.streaming.editEvent.title}
        path={PAGES.streaming.editEvent.path}
        render={(routeProps: RouteComponentProps) => (
          <Suspense fallback={<div />}>
            <StreamingEditor eventId={eventId} {...routeProps} />
          </Suspense>
        )}
      />
    </Switch>
  );
};

export { StreamingContainer as Streaming };
