export const ON_REFRESH_PARTNER_EVENT = '@event-integrations_V2/ON_REFRESH_PARTNER_EVENT';
export const ON_REFRESH_PARTNER_EVENT_COMPLETED = '@event-integrations_V2/ON_REFRESH_PARTNER_EVENT_COMPLETED';
export const ON_UPDATE_PARTNER_EVENT_STATUS = '@event-integrations_V2/ON_UPDATE_PARTNER_EVENT_STATUS';

export const ON_REFRESH_PARTNER_SEASON = '@event-integrations_V2/ON_REFRESH_PARTNER_SEASON';
export const ON_REFRESH_PARTNER_SEASON_COMPLETED = '@event-integrations_V2/ON_REFRESH_PARTNER_SEASON_COMPLETED';

export const ON_SET_EVENT_ERROR = '@event-integrations_V2/ON_SET_EVENT_ERROR';
export const ON_SET_PREV_PARTNER_EVENT = '@event-integrations_V2/ON_SET_PREV_PARTNER_EVENT';
export const ON_CREATE_PARTNER_EVENT = '@event-integrations_V2/ON_CREATE_PARTNER_EVENT';
export const ON_UPDATE_PARTNER_EVENT = '@event-integrations_V2/ON_UPDATE_PARTNER_EVENT';
export const ON_CANCEL_PARTNER_EVENT = '@event-integrations_V2/ON_CANCEL_PARTNER_EVENT';
export const ON_DELETE_PARTNER_EVENT = '@event-integrations_V2/ON_DELETE_PARTNER_EVENT';
export const ON_UPDATE_PARTNER_EVENTS = '@event-integrations_V2/ON_UPDATE_PARTNER_EVENTS';

export const ON_IGNORE_PARTNER_EVENT = '@event-integrations_V2/ON_IGNORE_PARTNER_EVENT';
export const ON_IGNORE_PARTNER_EVENT_COMPLETED = '@event-integrations_V2/ON_IGNORE_PARTNER_EVENT_COMPLETED';
export const ON_CANCELLED_TASK_IGNORE_PARTNER_EVENT = '@event-integrations_V2/ON_CANCELLED_TASK_IGNORE_PARTNER_EVENT';

export const ON_RESTORE_PARTNER_EVENT = '@event-integrations_V2/ON_RESTORE_PARTNER_EVENT';
export const ON_RESTORE_PARTNER_EVENT_COMPLETED = '@event-integrations_V2/ON_RESTORE_PARTNER_EVENT_COMPLETED';
export const ON_CANCELLED_TASK_RESTORE_PARTNER_EVENT = '@event-integrations_V2/ON_CANCELLED_TASK_RESTORE_PARTNER_EVENT';

export const ON_DECLINE_PARTNER_EVENT = '@event-integrations_V2/ON_DECLINE_PARTNER_EVENT';
export const ON_DECLINE_PARTNER_EVENT_COMPLETED = '@event-integrations_V2/ON_DECLINE_PARTNER_EVENT_COMPLETED';
export const ON_CANCELLED_TASK_DECLINE_PARTNER_EVENT = '@event-integrations_V2/ON_CANCELLED_TASK_DECLINE_PARTNER_EVENT';

export const ON_PUBLISH_ALL_EVENTS = '@event-integrations_V2/ON_PUBLISH_ALL_EVENTS';
export const ON_PUBLISH_ALL_EVENTS_COMPLETED = '@event-integrations_V2/ON_PUBLISH_ALL_EVENTS_COMPLETED';
export const ON_PUBLISH_PARTNER_EVENT = '@event-integrations_V2/ON_PUBLISH_PARTNER_EVENT';
export const ON_PUBLISH_PARTNER_EVENT_COMPLETED = '@event-integrations_V2/ON_PUBLISH_PARTNER_EVENT_COMPLETED';
export const ON_CANCELLED_TASK_PUBLISH_PARTNER_EVENT = '@event-integrations_V2/ON_CANCELLED_TASK_PUBLISH_PARTNER_EVENT';

export const ON_IGNORE_PARTNER_SEASON = '@event-integrations_V2/ON_IGNORE_PARTNER_SEASON';
export const ON_IGNORE_PARTNER_SEASON_COMPLETED = '@event-integrations_V2/ON_IGNORE_PARTNER_SEASON_COMPLETED';
export const ON_CANCELLED_TASK_IGNORE_PARTNER_SEASON = '@event-integrations_V2/ON_CANCELLED_TASK_IGNORE_PARTNER_SEASON';

export const ON_RESTORE_PARTNER_SEASON = '@event-integrations_V2/ON_RESTORE_PARTNER_SEASON';
export const ON_RESTORE_PARTNER_SEASON_COMPLETED = '@event-integrations_V2/ON_RESTORE_PARTNER_SEASON_COMPLETED';
export const ON_CANCELLED_TASK_RESTORE_PARTNER_SEASON = '@event-integrations_V2/ON_CANCELLED_TASK_RESTORE_PARTNER_SEASON';

export const ON_PUBLISH_ALL_SEASONS = '@event-integrations_V2/ON_PUBLISH_ALL_SEASONS';
export const ON_PUBLISH_ALL_SEASONS_COMPLETED = '@event-integrations_V2/ON_PUBLISH_ALL_SEASONS_COMPLETED';
export const ON_PUBLISH_PARTNER_SEASON = '@event-integrations_V2/ON_PUBLISH_PARTNER_SEASON';
export const ON_PUBLISH_PARTNER_SEASON_COMPLETED = '@event-integrations_V2/ON_PUBLISH_PARTNER_SEASON_COMPLETED';
export const ON_CANCELLED_TASK_PUBLISH_PARTNER_SEASON = '@event-integrations_V2/ON_CANCELLED_TASK_PUBLISH_PARTNER_SEASON';

////////////////////////////////////////////////

export interface OnRefreshPartnerEventAction {
  type: typeof ON_REFRESH_PARTNER_EVENT;
  payload: any;
}

export interface OnRefreshPartnerEventCompletedAction {
  type: typeof ON_REFRESH_PARTNER_EVENT_COMPLETED;
  payload: any;
}

export interface OnRefreshPartnerSeasonAction {
  type: typeof ON_REFRESH_PARTNER_SEASON;
  payload: any;
}

export interface OnRefreshPartnerSeasonCompletedAction {
  type: typeof ON_REFRESH_PARTNER_SEASON_COMPLETED;
  payload: any;
}

export interface OnSetEventErrorAction {
  type: typeof ON_SET_EVENT_ERROR;
  payload: any;
}

export interface OnSetPrevPartnerEventAction {
  type: typeof ON_SET_PREV_PARTNER_EVENT;
  payload: any;
}

export interface OnCreatePartnerEventAction {
  type: typeof ON_CREATE_PARTNER_EVENT;
  payload: any;
}

export interface OnUpdatePartnerEventAction {
  type: typeof ON_UPDATE_PARTNER_EVENT;
  payload: any;
}

export interface OnCancelPartnerEventAction {
  type: typeof ON_CANCEL_PARTNER_EVENT;
  payload: any;
}

export interface OnDeletePartnerEventAction {
  type: typeof ON_DELETE_PARTNER_EVENT;
  payload: any;
}

export interface OnUpdatePartnerEventsAction {
  type: typeof ON_UPDATE_PARTNER_EVENTS;
  payload: any;
}

export interface OnUpdatePartnerEventStatusAction {
  type: typeof ON_UPDATE_PARTNER_EVENT_STATUS;
  payload: any;
}

////////////////////////////////////////////////

export interface OnIgnorePartnerEventAction {
  type: typeof ON_IGNORE_PARTNER_EVENT;
  payload: any;
}

export interface OnIgnorePartnerEventCompletedAction {
  type: typeof ON_IGNORE_PARTNER_EVENT_COMPLETED;
  payload: any;
}

export interface OnCancelledTaskIgnorePartnerEventAction {
  type: typeof ON_CANCELLED_TASK_IGNORE_PARTNER_EVENT;
  payload: any;
}

export interface OnRestorePartnerEventAction {
  type: typeof ON_RESTORE_PARTNER_EVENT;
  payload: any;
}

export interface OnRestorePartnerEventCompletedAction {
  type: typeof ON_RESTORE_PARTNER_EVENT_COMPLETED;
  payload: any;
}

export interface OnCancelledTaskRestorePartnerEventAction {
  type: typeof ON_CANCELLED_TASK_RESTORE_PARTNER_EVENT;
  payload: any;
}

export interface OnDeclinePartnerEventAction {
  type: typeof ON_DECLINE_PARTNER_EVENT;
  payload: any;
}

export interface OnDeclinePartnerEventCompletedAction {
  type: typeof ON_DECLINE_PARTNER_EVENT_COMPLETED;
  payload: any;
}

export interface OnCancelledTaskDeclinePartnerEventAction {
  type: typeof ON_CANCELLED_TASK_DECLINE_PARTNER_EVENT;
  payload: any;
}

export interface OnPublishPartnerEventAction {
  type: typeof ON_PUBLISH_PARTNER_EVENT;
  payload: any;
}

export interface OnPublishPartnerEventCompletedAction {
  type: typeof ON_PUBLISH_PARTNER_EVENT_COMPLETED;
  payload: any;
}

export interface OnCancelledTaskPublishPartnerEventAction {
  type: typeof ON_CANCELLED_TASK_PUBLISH_PARTNER_EVENT;
  payload: any;
}

export interface OnPublishAllEventsAction {
  type: typeof ON_PUBLISH_ALL_EVENTS;
  payload: any;
}

export interface OnPublishAllEventsCompletedAction {
  type: typeof ON_PUBLISH_ALL_EVENTS_COMPLETED;
  payload: any;
}

////////////////////////////////////////////////

export interface OnIgnorePartnerSeasonAction {
  type: typeof ON_IGNORE_PARTNER_SEASON;
  payload: any;
}

export interface OnIgnorePartnerSeasonCompletedAction {
  type: typeof ON_IGNORE_PARTNER_SEASON_COMPLETED;
  payload: any;
}

export interface OnCancelledTaskIgnorePartnerSeasonAction {
  type: typeof ON_CANCELLED_TASK_IGNORE_PARTNER_SEASON;
  payload: any;
}

export interface OnRestorePartnerSeasonAction {
  type: typeof ON_RESTORE_PARTNER_SEASON;
  payload: any;
}

export interface OnRestorePartnerSeasonCompletedAction {
  type: typeof ON_RESTORE_PARTNER_SEASON_COMPLETED;
  payload: any;
}

export interface OnCancelledTaskRestorePartnerSeasonAction {
  type: typeof ON_CANCELLED_TASK_RESTORE_PARTNER_SEASON;
  payload: any;
}

export interface OnPublishPartnerSeasonAction {
  type: typeof ON_PUBLISH_PARTNER_SEASON;
  payload: any;
}

export interface OnPublishPartnerSeasonCompletedAction {
  type: typeof ON_PUBLISH_PARTNER_SEASON_COMPLETED;
  payload: any;
}

export interface OnCancelledTaskPublishPartnerSeasonAction {
  type: typeof ON_CANCELLED_TASK_PUBLISH_PARTNER_SEASON;
  payload: any;
}

export interface OnPublishAllSeasonsAction {
  type: typeof ON_PUBLISH_ALL_SEASONS;
  payload: any;
}

export interface OnPublishAllSeasonsCompletedAction {
  type: typeof ON_PUBLISH_ALL_SEASONS_COMPLETED;
  payload: any;
}

export type EventIntegrationActionType =
  | OnRefreshPartnerEventAction
  | OnRefreshPartnerEventCompletedAction
  | OnUpdatePartnerEventStatusAction
  | OnRefreshPartnerSeasonAction
  | OnRefreshPartnerSeasonCompletedAction

  | OnSetEventErrorAction
  | OnSetPrevPartnerEventAction
  | OnCreatePartnerEventAction
  | OnUpdatePartnerEventAction
  | OnIgnorePartnerEventAction
  | OnCancelPartnerEventAction
  | OnDeletePartnerEventAction
  | OnUpdatePartnerEventsAction

  | OnIgnorePartnerEventAction
  | OnIgnorePartnerEventCompletedAction
  | OnCancelledTaskIgnorePartnerEventAction
  | OnRestorePartnerEventAction
  | OnRestorePartnerEventCompletedAction
  | OnCancelledTaskRestorePartnerEventAction
  | OnDeclinePartnerEventAction
  | OnDeclinePartnerEventCompletedAction
  | OnCancelledTaskDeclinePartnerEventAction
  | OnPublishPartnerEventAction
  | OnPublishPartnerEventCompletedAction
  | OnPublishAllEventsAction
  | OnPublishAllEventsCompletedAction
  | OnCancelledTaskPublishPartnerEventAction

  | OnIgnorePartnerSeasonAction
  | OnIgnorePartnerSeasonCompletedAction
  | OnCancelledTaskIgnorePartnerSeasonAction
  | OnRestorePartnerSeasonAction
  | OnRestorePartnerSeasonCompletedAction
  | OnCancelledTaskRestorePartnerSeasonAction
  | OnPublishPartnerSeasonAction
  | OnPublishPartnerSeasonCompletedAction
  | OnPublishAllSeasonsAction
  | OnPublishAllSeasonsCompletedAction
  | OnCancelledTaskPublishPartnerSeasonAction;
