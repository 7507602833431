import React from 'react';
import styled from 'styled-components';
import { Column, Grid, Link, Row } from 'carbon-components-react';
import styles from './styles';
import { secureEmail } from '../../../../../../utils/stringUtils';

import { SIGNIN } from '../../constants';
import { PAGES } from '../../../../../../config/routes';
import logo from '../../../../../../assets/images/gofan-hq-lo.png';

import { randomBackgroundSignIn } from '../../helpers';

const Wrapper = styled.div`
  ${styles.wrapper};
`;

const WrapperGrid = styled(Grid)`
  ${styles.wrapperGrid};
`;

const WrapperLeftContent = styled.div`
  ${styles.wrapperLeftContent};
`;

const WrapperContainer = styled.div`
  ${styles.wrapperContainer};
`;

const WrapperRowVertical = styled.div`
  ${styles.wrapperRowVertical};
`;

const WrapperRowVerticalCenter = styled.div`
  ${styles.wrapperRowVerticalCenter};
`;

const WrapperCopyRights = styled.div`
  ${styles.wrapperCopyRight}
`;

const WrapperDescription = styled.div`
  ${styles.description}
`;

const CheckEmailV2 = ({ match }) => {
  const { email } = match.params;
  const currentBackground = React.useRef(randomBackgroundSignIn());

  return (
    <Wrapper urlString={currentBackground.current}>
      <WrapperGrid fullWidth className='gs--grid--full-width'>
        <Row>
          <Column className='left-content' md={3} sm={3} lg={4}>
            <WrapperLeftContent className='gs--padding__sp7'>
              <div style={{ flex: 1, paddingTop: 128 }}>
                <WrapperRowVertical>
                  <img src={logo} alt='' style={{ width: '100%' }} />
                </WrapperRowVertical>
                <WrapperContainer className='gs--padding-left__sp7 gs--padding-right__sp7'>
                  <>
                    <WrapperRowVertical className='gs--padding-top__sp5'>
                      <div className='gs--font-family-san gs--section-title-normal gs--inverse-01'>
                        {SIGNIN.checkEmailTitle}
                      </div>
                    </WrapperRowVertical>

                    <WrapperDescription className='gs--padding-right__sp5'>
                      <WrapperRowVertical className='gs--padding-top__sp5'>
                        <div className='gs--font-family-san gs--list-item gs--text-03'>
                          {SIGNIN.checkEmailDescription[0].replace('{email}', secureEmail(email))}
                        </div>
                      </WrapperRowVertical>

                      <WrapperRowVertical className='gs--padding-top__sp5'>
                        <div className='gs--font-family-san gs--list-item gs--text-03'>
                          {SIGNIN.checkEmailDescription[1]}
                        </div>
                      </WrapperRowVertical>
                    </WrapperDescription>

                    <WrapperRowVerticalCenter className='gs--padding-top-sp7'>
                      <div className='gs--link-wrapper'>
                        <Link
                          href={PAGES.login.root.path}
                          className='gs--font-family-san gs--body-short-01 gs--link-01'
                        >
                          {SIGNIN.backToSignIn}
                        </Link>
                      </div>
                    </WrapperRowVerticalCenter>
                  </>
                </WrapperContainer>
              </div>
              <WrapperCopyRights>
                <div className='gs--font-family-san gs--section-description gs--inverse-01'>{SIGNIN.coppyRights}</div>
              </WrapperCopyRights>
            </WrapperLeftContent>
          </Column>
        </Row>
      </WrapperGrid>
    </Wrapper>
  );
};

export default CheckEmailV2;
