import { css } from 'styled-components';

export default {
  wrapper: css`
    position: relative;

    .bx--tooltip__label {
      z-index: -1;
      position: absolute;
      opacity: 0;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 16px;
      height: 16px;
      transform: translateX(-50%);
    }
  `,
  copyWrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,
  tooltipTitle: css`
    background-color: white;
    padding: 8px;
  `,
  tooltipDescription: css`
    padding-left: 25px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 25px;
  `
};
