import { USER_ROLE_COMMISSIONER_VIEWER, USER_ROLE_COMMISSIONER_EDITOR } from '@gofan/api';

export const CONTACT_STRINGS = {
  EDIT: 'Edit',
  ADD_NEW_CONTACT: 'Add new contact',
  CLOSE: 'Close',
  OTHER: 'Other',
  ACTIVE: 'Active',
  IN_ACTIVE: 'Inactive',
  DONT_HAVE_PERMISSON: 'You don’t have permission to view contacts.',
  DONT_HAVE_ADD_PERMISSION: 'You don’t have permission to add contacts.',
  DONT_HAVE_EDIT_PERMISSION: 'You don’t have permission to edit contacts.',
  CREATE_CONTACT: 'Create Contact',
  DESCRIPTION_UPLOAD_PHOTO: 'Max file size is 500kb. Supported file types are .jpg, .jpeg and .png.',
  SAVE: 'Save',
  DELETE: 'Delete contact'
};

export const CONTACT_PRIMARY = 'CONTACT_PRIMARY';
export const CONTACT_FINANCE = 'CONTACT_FINANCE';
export const CONTACT_OTHER = 'CONTACT_OTHER';
export const CONTACT_COACH = 'CONTACT_COACH';

export const GOFAN_ROLE_OPTIONS = [
  {
    id: CONTACT_PRIMARY,
    text: 'Main contact'
  },
  {
    id: CONTACT_FINANCE,
    text: 'Financial contact'
  },
  {
    id: CONTACT_OTHER,
    text: 'Other'
  },
  {
    id: CONTACT_COACH,
    text: 'Coach'
  }
];

export const CONTACT_PILL_OPTION = {
  GOFAN_ROLE: 'GOFAN_ROLE',
  ACCOUNT_STATUS_ENABLE: 'ACCOUNT_STATUS_ENABLE',
  ACCOUNT_STATUS_PENDING: 'ACCOUNT_STATUS_PENDING'
};

export const FILTER_PARAMS = {
  gofanRoles: GOFAN_ROLE_OPTIONS,
  accountStatusEnable: true,
  accountStatusPending: true
};

export const GOFAN_SCHOOL_TYPES = [
  {
    id: 'high-school',
    text: 'High School'
  },
  {
    id: 'school-district',
    text: 'School District'
  },
  {
    id: 'booster-club',
    text: 'Booster Club'
  },
  {
    id: 'athletic-department',
    text: 'Athletic Department'
  },
  {
    id: 'company',
    text: 'Company'
  },
  {
    id: 'college',
    text: 'College'
  },
  {
    id: 'middle-school',
    text: 'Middle School'
  },
  {
    id: 'elementary-school',
    text: 'Elementary School'
  },
  {
    id: 'atheletic-conference',
    text: 'Athletic Conference'
  },
  {
    id: 'performing-arts',
    text: 'Performing Arts'
  },
  {
    id: 'state-association',
    text: 'State Association'
  },
  {
    id: 'special-event',
    text: 'Special Event'
  },
  {
    id: 'section',
    text: 'Section'
  },
  {
    id: 'k-12',
    text: 'K - 12'
  }
  // Exists in prod db, but isn't needed as a filter
  // {
  //   id: 'subaccount',
  //   text: 'Subaccount'
  // }
];

export const ACCOUNT_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export const ACCOUNT_TICKETS_STRINGS = {
  DONT_HAVE_PERMISSON: 'You don’t have permission to view account tickets.'
};

export const FILE_SIZE_UPLOAD = 500000;
export const FILES_IMG_ACCEPT = ['.jpg', '.jpeg', '.png'];

export const PHOTO_UPLOAD_TYPE = {
  ADD_CONTACT: 'ADD_CONTACT',
  GOFAN_PAGE: 'GOFAN_PAGE'
};

export const EVENT_REPORT = {
  YES: 1,
  NO: 2
};

export enum SCHOOL_PERMISSIONS {
  VIEWER = USER_ROLE_COMMISSIONER_VIEWER,
  EDITOR = USER_ROLE_COMMISSIONER_EDITOR,
  NONE = 'NONE'
}

export const SCHOOL_PERMISSIONS_OPTIONS = [
  { id: SCHOOL_PERMISSIONS.NONE, label: 'None' },
  { id: SCHOOL_PERMISSIONS.VIEWER, label: 'School view access' },
  { id: SCHOOL_PERMISSIONS.EDITOR, label: 'School edit access' }
];

export const INITIAL_CONTACT_DETAILS_TEMPLATE = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  title: '',
  contactType: '',
  avatar: '',
  hasEditorPermission: false,
  hasBoxOfficePermission: false,
  schoolPermission: SCHOOL_PERMISSIONS.NONE,
  hasFinancialPermission: false,
  hasInsiderPermission: false,
  hasReportPermission: false,
  athleticReport: [],
  nonAthleticReport: []
};

export const MODAL_ACTION = {
  DELETE: 'DELETE',
  SAVE_CHANGES: 'SAVE_CHANGES',
  CONFIRM_ADD_NEW_CONTACT: 'CONFIRM_ADD_CONTACT',
  NO_PERMISSION_ADD_NEW_CONTACT: 'NO_PERMISSION_ADD_NEW_CONTACT',
  CONFIRM_ADD_EXISTING_CONTACT: 'CONFIRM_ADD_EXISTING_CONTACT',
  NO_PERMISSION_ADD_EXISTING_CONTACT: 'NO_PERMISSION_ADD_EXISTING_CONTACT'
};

export const NOTIFICATION_STATUS = {
  DISPLAY: 'DISPLAY',
  NONE: 'NONE',
  HIDDEN: 'HIDDEN'
};

export const US_STATES = {
  WASHINGTON: 'WA'
};

export const ACCOUNT_PAYMENT_CYCLE = {
  DAILY: 'daily_transaction_close',
  WEEKLY: 'weekly_transaction_close',
  EVENT_BASED: 'weekly_event_close'
};

export const ACCOUNT_FINANCIAL_STATUS = {
  PENDING: 'pending',
  VERIFIED: 'verified',
  DEACTIVATED: 'deactivated'
};

export const ALLOW_ATHLETIC_SITE_SCHOOL_TYPE = 'High School';

export const SITE_EMPTY_DESCRIPTION: {
  [k: string]: string;
} = {
  GF_TICKET_SITE: "Unlock your team's full potential by creating your school sites today",
  GF_SITE: "Unlock your team's full potential by creating your athletic site today"
};

export const COACH_INFO = {
  STAFF_ROLE: 'staffRole',
  SPORT_TEAM: 'sportTeam'
};

export default {
  CONTACT_STRINGS,
  FILTER_PARAMS,
  CONTACT_PILL_OPTION,
  GOFAN_ROLE_OPTIONS,
  GOFAN_SCHOOL_TYPES,
  ACCOUNT_TICKETS_STRINGS,
  INITIAL_CONTACT_DETAILS_TEMPLATE,
  MODAL_ACTION,
  NOTIFICATION_STATUS
};
