import { css } from 'styled-components';

export default {
  sideMenuV2: css`
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    width: ${props => (props.isSideMenuExpanded ? '350px' : '100px')};
    height: calc(100vh - 50px);
    background-color: #000;
    color: #fff;
    margin-top: 50px;
    z-index: 9000;
    font-weight: normal;
  `,

  sideMenuIcon: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    border: none;
    padding: 8px;
    width: 100%;
    height: 75px;

    &:hover {
      background-color: #2c2c2c;
      cursor: pointer;
      color: #fff;
    }

    svg {
      width: 24px;
      height: 24px;
      margin-bottom: 4px;
    }

    span {
      margin: 0;
      font-size: 12px;
    }
  `,

  sideMenuItem: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #000;
    color: #fff;
    border: none;
    padding: 8px;
    width: 100%;
    height: 56px;

    &:hover {
      background-color: #2c2c2c;
      cursor: pointer;
      color: #fff;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    span {
      margin: 0 8px;
      font-size: 16px;
    }
  `,

  svgContainer: css`
    display: flex;
    justify-content: center;
    width: 85px;
  `
};
