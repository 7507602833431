import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { Add16 } from '@carbon/icons-react';
import { Accordion, AccordionItem, ProgressIndicator, ProgressStep, Button } from '@gofan/components';

import './EditorProgress.scss';

export interface Progress {
  id: string;
  label: string;
  current?: boolean;
  invalid?: boolean;
  complete?: boolean;
  disabled?: boolean;
}

export interface ProgressSection {
  title: string;
  settingIndex: number;
  settingLabel: string;
  progresses: Progress[];
}

interface EditorProgressProps {
  sections: ProgressSection[];
  selectedSection: string;
  isPracticeSchedule: boolean;
  onOpenSection: (section: string) => void;
  onClickProgress: (progress: string) => void;
  setSeasonModalOpen?: (value: boolean) => void;
}

export const EditorProgress = ({
  sections,
  selectedSection,
  isPracticeSchedule,
  onOpenSection,
  onClickProgress,
  setSeasonModalOpen
}: EditorProgressProps) => {
  return (
    <div className='editor-progress'>
      <Accordion>
        {sections.map(section => (
          <AccordionItem
            key={section.title}
            className={`editor-progress__section ${section.title}`}
            title={<div className='gs--productive-heading-03-semibold gs--font-family-sf'>{section.title}</div>}
            open={section.title === selectedSection}
            onHeadingClick={() => {
              onOpenSection(section.title);
            }}
          >
            {selectedSection === 'Sports' && setSeasonModalOpen && (
              <Button className='gf-add-sports-button' onClick={() => setSeasonModalOpen(true)}>
                Add Sport
                <Add16 />
              </Button>
            )}
            <ProgressIndicator
              className='editor-progress__step'
              vertical
              onChange={index => _onScrollIntoProgress(section.progresses[index - 1]?.id)}
            >
              <ProgressStep className='editor-progress__step--hidden' label='' />
              {section.progresses.map(progress => (
                <ProgressStep
                  key={progress.id}
                  className={classNames({ 'editor-progress__step--selected': progress.current })}
                  label={progress.label}
                  current={isPracticeSchedule ? progress.current : progress.current && !progress.complete}
                  invalid={isPracticeSchedule ? false : progress.invalid}
                  complete={progress.complete}
                />
              ))}
            </ProgressIndicator>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );

  function _onScrollIntoProgress(progress: string) {
    const sectionElement = document.getElementById(progress);
    if (!isEmpty(sectionElement)) {
      sectionElement.scrollIntoView({
        // Because Chrome bug smooth scroll, need to defined scroll-behavior: smooth css instead of
        behavior: 'auto',
        block: 'center',
        inline: 'nearest'
      });
    }
    onClickProgress(progress);
  }
};
