export const EXCLUDE_SCHOOL_TYPES = ['Elementary School', 'Elementary School Board', 'Elementary Member School'];
export const GOFAN_SCHOOL_TYPE = {
  SECTION: 'section',
  STATE_ASSOCIATION: 'state association',
  SCHOOL_DISTRICT: 'school district',
  HIGH_SCHOOL: 'high school'
};
export const USE_EVENT_NAME_GOFAN_SCHOOL_TYPES = [
  GOFAN_SCHOOL_TYPE.STATE_ASSOCIATION,
  GOFAN_SCHOOL_TYPE.SECTION,
  GOFAN_SCHOOL_TYPE.SCHOOL_DISTRICT
];

export const GOFAN_SCHOOL_TYPE_EDITABLE_TYPES = [GOFAN_SCHOOL_TYPE.STATE_ASSOCIATION, GOFAN_SCHOOL_TYPE.SECTION];
