import lowerCase from 'lodash/lowerCase';

export const TIME_PICKER_PATTERN = /(0[1-9]|1[012]):[0-5][0-9](\s)(AM|PM)/;

export const DATE_PICKER_FORMAT_DEFAULT = 'MM/DD/YYYY';

export const DATE_TIME_PICKER_FORMAT_DEFAULT = 'MM/DD/YYYY hh:mm A';

export const TIME_FORMAT_DEFAULT = 'hh:mm A';

export const DATE_FORMAT_DEFAULT = 'YYYY-MM-DD';

export const DATE_FORMAT_DEFAULT_WITH_TIME = 'YYYY-MM-DDTHH:mm';

export const DATE_FORMAT_DEFAULT_WITH_FULL_TIME = 'YYYY-MM-DDTHH:mm:ss';

export const DATE_FORMAT_DEFAULT_WITH_TIMEZONE = 'YYYY-MM-DDTHH:mm:ssZZ';

export const DATE_FORMAT_DEFAULT_FULL_MONTH = 'MMMM DD, YYYY';

export const DATE_FORMAT_WITH_FULL_MONTH_OF_FAN = 'MMM-DD-YYYY';

export const DATE_TIME_FORMAT_WITH_FULL_MONTH_OF_FAN = 'MMM-DD-YYYY hh:mm A';

export const DATE_FORMAT = 'MM-DD-YYYY';

export const DATE_TIME_FORMAT = 'MM-DD-YYYY hh:mm A';

export const DATE_FORMAT_WITH_DAY_OF_WEEK = 'ddd MM-DD-YYYY';

export const DATE_FORMAT_WITH_DAY_OF_WEEK_MONTH_ABBREVIATED = 'ddd-MMM-DD-YYYY';

export const DATE_FORMAT_WITH_LONG_DAY_OF_WEEK = 'dddd MM-DD-YY';

export const FULLDATE_FORMAT = 'ddd, MMM DD, YYYY';

export const FULLDATE_FORMAT_WITH_TIME = 'ddd, MMM DD, YYYY hh:mm A';

export const DATE_LONG_FORMAT = 'MMMM DD, YYYY';

export const EVENT_DATE_FORMAT = 'YYYY-MM-DD';

export const ARRAY_DATE_TIME_FORMAT = ['MM/DD/YYYY h:mmA', 'MM/DD/YYYY h:mm A', 'MM/DD/YYYY hh:mm a'];

export const DATE_FORMAT_DEFAULT_SHORT_MONTH = 'MMM DD, YYYY';

export const FULLTIME_FORMAT = 'HH:mm:ss';

export const FULL_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const MATCH_SCORE_CORRECTED_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss.ssssss';

export const DATE_TIME_FORMAT_WITH_FULL_MONTH = 'MMMM D, YYYY h:mm A';

export const TIME_ZONE_ENUM = {
  AMERICA_CHICAGO: 'America/Chicago',
  AMERICA_DENVER: 'America/Denver',
  AMERICA_LOS_ANGELES: 'America/Los_Angeles',
  AMERICA_NEW_YORK: 'America/New_York',
  AMERICA_PHOENIX: 'America/Phoenix',
  AMERICA_INDIANA: 'America/Indiana/Indianapolis',
  ALASKA: 'America/Juneau',
  HAWAII: 'Pacific/Honolulu',
  EST: 'EST'
};

export const TIME_ZONE_MAPPING = {
  [lowerCase(TIME_ZONE_ENUM.AMERICA_NEW_YORK)]: TIME_ZONE_ENUM.AMERICA_NEW_YORK,
  [lowerCase('AmericaNew_York')]: TIME_ZONE_ENUM.AMERICA_NEW_YORK,
  [lowerCase('Eastern Time (US & Canada)')]: TIME_ZONE_ENUM.AMERICA_NEW_YORK,
  [lowerCase('EST')]: TIME_ZONE_ENUM.AMERICA_NEW_YORK,
  [lowerCase('Eastern')]: TIME_ZONE_ENUM.AMERICA_NEW_YORK,

  [lowerCase(TIME_ZONE_ENUM.AMERICA_CHICAGO)]: TIME_ZONE_ENUM.AMERICA_CHICAGO,
  [lowerCase('AmericaChicago')]: TIME_ZONE_ENUM.AMERICA_CHICAGO,
  [lowerCase('Central Time (US & Canada)')]: TIME_ZONE_ENUM.AMERICA_CHICAGO,
  [lowerCase('Central')]: TIME_ZONE_ENUM.AMERICA_CHICAGO,

  [lowerCase(TIME_ZONE_ENUM.AMERICA_INDIANA)]: TIME_ZONE_ENUM.AMERICA_INDIANA,
  [lowerCase('Indiana (East)')]: TIME_ZONE_ENUM.AMERICA_INDIANA,

  [lowerCase(TIME_ZONE_ENUM.AMERICA_DENVER)]: TIME_ZONE_ENUM.AMERICA_DENVER,
  [lowerCase('AmericaDenver')]: TIME_ZONE_ENUM.AMERICA_DENVER,
  [lowerCase('Mountain Time (US & Canada)')]: TIME_ZONE_ENUM.AMERICA_DENVER,
  [lowerCase('Mountain')]: TIME_ZONE_ENUM.AMERICA_DENVER,

  [lowerCase(TIME_ZONE_ENUM.AMERICA_LOS_ANGELES)]: TIME_ZONE_ENUM.AMERICA_LOS_ANGELES,
  [lowerCase('AmericaLos_Angeles')]: TIME_ZONE_ENUM.AMERICA_LOS_ANGELES,
  [lowerCase('Pacific Time (US & Canada)')]: TIME_ZONE_ENUM.AMERICA_LOS_ANGELES,
  [lowerCase('Pacific')]: TIME_ZONE_ENUM.AMERICA_LOS_ANGELES,

  [lowerCase(TIME_ZONE_ENUM.AMERICA_PHOENIX)]: TIME_ZONE_ENUM.AMERICA_PHOENIX,
  [lowerCase('Arizona')]: TIME_ZONE_ENUM.AMERICA_PHOENIX,

  [lowerCase(TIME_ZONE_ENUM.ALASKA)]: TIME_ZONE_ENUM.ALASKA,
  [lowerCase('Alaska')]: TIME_ZONE_ENUM.ALASKA,

  [lowerCase(TIME_ZONE_ENUM.HAWAII)]: TIME_ZONE_ENUM.HAWAII,
  [lowerCase('Hawaii')]: TIME_ZONE_ENUM.HAWAII
};
