import { css } from 'styled-components';
import common from '../../../assets/styles/common';

export default {
  fieldLabel: css`
    font-size: 14px !important;
    color: black;
  `,
  fieldValue: css`
    font-size: 14px;
    margin: 0px 0px 20px 0px;
    ${props => props.style};
  `,
  textFieldWrapper: css`
    margin-bottom: 1rem;
  `,
  inputValidation: css`
    margin: 0 !important;
    & input {
      height: ${({ customheight }) =>
        customheight ? `${customheight}px;` : '48px;'};
    }
  `,
  errorMessage: css`
    ${common.errorMessage};
    margin-top: 5px;
  `,
  messageContent: css`
    font-weight: 600;
    font-size: 14px !important;
  `,
  checkBox: css`
    & label {
      font-size: ${({ labelsize }) =>
        labelsize ? `${labelsize}px !important` : '16px'};
      font-weight: ${({ fontWeight }) => fontWeight || 400};
    }

    & label:before {
      background: ${({ disabled }) =>
        disabled ? '#ececec !important' : 'white'};
    }

    & label:after {
      color: ${({ disabled }) => (disabled ? '#cccccc !important' : 'black')};
    }
  `,
  button: css`
    ${({ width }) => width && `width: ${width}px !important;`}
    ${({ height }) => height && `height: ${height}px;`}
  `,
  input: css`
    height: ${({ customheight }) =>
      customheight ? `${customheight}px` : '48px'} !important;
  `,
  selection: css`
    height: ${({ customHeight }) =>
      customHeight ? `${customHeight}px;` : '48px;'};
  `,
  accordionWrapper: css`
    width: 100% !important;
    margin-bottom: 20px;
  `,
  accordionHeader: css`
    display: flex;
    flex-direction: row;
    cursor: pointer;
  `,
  accordionIcon: css`
    position: absolute;
    right: 30px;
  `,
  accordionTitle: css`
    ${common.h2}
    text-align: center;
    background: transparent;
    padding-left: 30px;
    padding-bottom: 20px;
  `,
  accordionMenuItem: css`
    padding-bottom: 20px;
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    user-select: text !important;
  `,
  accordionContent: css`
    margin-bottom: -18px;
  `
};
